const listData = {
  listHeaders: [
    {
      name: 'Suppl SKU RTL',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Product core',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    { name: 'EAN', width: 200, dropdown: false, reservedSorting: false },
    {
      name: 'Man.nr',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    { name: 'Btch', width: 200, dropdown: false, reservedSorting: false },
    {
      name: 'Btch act',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Batch linked',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Name search',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Product name',
      width: 200,
      dropdown: true,
      reservedSorting: false
    },
    {
      name: 'Supplier size code',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Range identifier',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Range description',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Plant group',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    { name: 'Stck.act', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Stck.tot', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Ord.m', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Ord.i', width: 200, dropdown: false, reservedSorting: false },
    { name: 'PRCP', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Curr', width: 200, dropdown: false, reservedSorting: false },
    { name: 'Tax', width: 200, dropdown: false, reservedSorting: false },
    {
      name: '{product, coreImage}',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    {
      name: 'Range image',
      width: 200,
      dropdown: false,
      reservedSorting: false
    },
    { name: 'Batch image', width: 200, dropdown: false, reservedSorting: false }
  ],
  listRecords: [
    {
      status: 910,
      sku: 'PM0044966',
      core_name: 'Garden',
      sppl_ean: '8720626332041',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700944',
      rtl_batch_array: '4700944',
      name_search: 'WOSGSHEA',
      name: 'Worth Swivel Grass Shear 1324',
      sppl_size_code: '035KSST6I4D',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 2.44,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'W7EVPRZ2',
      statusContent: 'dot',
      hash: '58V5SGK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '58V5SGK3'
    },
    {
      status: 210,
      sku: 'PM0045181',
      core_name: 'Plant',
      sppl_ean: '8720349417636',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592149',
      rtl_batch_array: '5592149',
      name_search: 'HEONASSA',
      name: "Hemerocallis 'Orange Nassau'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ENKY2DPT',
      statusContent: 'dot',
      hash: 'B1RBYXKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1RBYXKK'
    },
    {
      status: 210,
      sku: 'PM0080981',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338382',
      rtl_batch_array: '6338382',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2102,
      chnn_stock_retail: 2102,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      statusContent: 'dot',
      hash: '9ADDV5T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ADDV5T7'
    },
    {
      status: 210,
      sku: 'PM0068453',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348283',
      rtl_batch_array: '6037883, 6348283',
      name_search: 'ARITALIC',
      name: 'Arum italicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 609,
      chnn_stock_retail: 707,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1SESN8V1;JZEZXGL6;RAPSL1GK;XVHGLYGN;SS1E7VYK;XNBJW6NH;E19B459G;LX21C156;K1SNLYES;RG7H8EN7;LJ1G4CSP;XB31K765;VTH3G7VJ;DAEYT2XA;NGDG34SR;18ZC7A5T;ZAV41AZH;5SY9729T;R88CW27D;LSEH4TKR;6G7KY3E7;2V11GVRJ;7TCSSCSG',
      statusContent: 'dot',
      hash: '1994HTZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1994HTZC'
    },
    {
      status: 810,
      sku: 'PM0044962',
      core_name: 'Garden',
      sppl_ean: '8720626332089',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700928',
      rtl_batch_array: '4700928',
      name_search: 'WO135PPS',
      name: 'Worth, snoeizaag 35cm blad 1403',
      sppl_size_code: '050STKS134I',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 3.51,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageCore: 'A1APAP44;SKSK4T35;CVTA6PP8',
      imageBatch: 'R7EX2HLG',
      statusContent: 'dot',
      hash: 'TV83CCE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'TV83CCE6'
    },
    {
      status: 210,
      sku: 'PM0066218',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 3,
      btch_active_retail: '6356682',
      rtl_batch_array: '6184089, 6356682, 6338439',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 410,
      chnn_stock_retail: 1925,
      sppl_prcp: 3.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'XYY5J8ZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYY5J8ZV'
    },
    {
      status: 210,
      sku: 'PM0057932',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244221',
      rtl_batch_array: '6244221',
      name_search: 'RUTRICOL',
      name: 'Rubus tricolor',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1794,
      chnn_stock_retail: 1794,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9Z754P4K',
      statusContent: 'dot',
      hash: '9C7K8JXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9C7K8JXW'
    },
    {
      status: 210,
      sku: 'PM0080982',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338383',
      rtl_batch_array: '6338383, 6309067',
      name_search: 'PIJPASSI',
      name: "Pieris japonica 'Passion'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 301,
      chnn_stock_retail: 1301,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ALXRATH;R666VY63;V44EW5A5;LPXRZVCH;546NDJ9C;8TESAHAG;YAKSWRCE;6ZCXYLK6;T2GKESYC;62EKEJDT;WTKKCPT4;BS457Y7P;VHEV4JRY;4HKHN85H;S8SDZG3T;PW6LHC45',
      statusContent: 'dot',
      hash: 'Z5KCKVYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5KCKVYN'
    },
    {
      status: 810,
      sku: 'PM0077334',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286688',
      rtl_batch_array: '6286688',
      name_search: 'PRLVARIE',
      name: "Prunus lusitanica 'Variegata'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TH8JWJWG',
      statusContent: 'dot',
      hash: 'K4K6R7RN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4K6R7RN'
    },
    {
      status: 910,
      sku: 'PM0070777',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'Z5XL7ZPC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z5XL7ZPC'
    },
    {
      status: 210,
      sku: 'PM0057444',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330117',
      rtl_batch_array: '6330117',
      name_search: 'SECROSEN',
      name: "Sedum cyaneum 'Rosenteppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZV5ASXHB;EW1Y2G58;E1SA1T6K;J5A1Z4BK;VRPYNHBW;WNE9L2NC;V337LD2C;VEVJAX3S;7BGN3DAG;ZWHTG1ZY;NZES8E3K;SVXKLBW2;EWAR8XL1;RRVGPH54;DXELEV5P;YPL3GTSR;6XYZPWNZ;V42RYADP;S9KG2NW6;62PRWSN6;A276KCCN;LVTXZTD3;7HG3G3R4;HAXK7TLE',
      statusContent: 'dot',
      hash: 'Y59BCDHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y59BCDHK'
    },
    {
      status: 210,
      sku: 'PM0060025',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244254',
      rtl_batch_array: '6244254, 6252670',
      name_search: 'LUNIVEA',
      name: 'Luzula nivea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 169,
      chnn_stock_retail: 4369,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8X37PK5;V4S9TZRA;D1KLWY84;N4WKJNVV;KPS5LCCE;118SAGCL;7RTJ566S;YPV81KSE;7D6S6SVV;KSAL5JWT;2V5VNV1A',
      statusContent: 'dot',
      hash: '95C84AZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95C84AZV'
    },
    {
      status: 910,
      sku: 'PM0044958',
      core_name: 'Garden',
      sppl_ean: '8720664812543',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4603809',
      rtl_batch_array: '4603809',
      name_search: 'WOWE3GR',
      name: 'Worth Verticale Tuin element 3vaks Groen',
      sppl_size_code: 'DOOS12D',
      rng_range_identifier: 'BOX12PCS',
      rng_range_description: 'Box 12 Pieces',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 55.2,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'EWSXC6HT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'EWSXC6HT'
    },
    {
      status: 210,
      sku: 'PM0045202',
      core_name: 'Plant',
      sppl_ean: '8720349418275',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592208',
      rtl_batch_array: '5592208',
      name_search: 'HOLLTUFT',
      name: "Hosta 'Lakeside Little Tuft'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'LPRZPSR4',
      statusContent: 'dot',
      hash: 'S31LK7CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S31LK7CD'
    },
    {
      status: 210,
      sku: 'PM0080983',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338384',
      rtl_batch_array: '6338384',
      name_search: 'PIJPPASS',
      name: 'Pieris japonica Pink Passion',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1928,
      chnn_stock_retail: 1928,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PH1LRHBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH1LRHBJ'
    },
    {
      status: 210,
      sku: 'PM0047073',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6181400',
      rtl_batch_array: '6112690, 6181400, 6333271',
      name_search: 'LEALASKA',
      name: "Leucanthemum (S) 'Alaska'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1265,
      chnn_stock_retail: 3667,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J879SRGC;NDZSVC5Z;2GP7KAHH;1DXSBXYC;DRPNZ3AE',
      statusContent: 'dot',
      hash: 'SJ26XZD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJ26XZD3'
    },
    {
      status: 210,
      sku: 'PM0044952',
      core_name: 'Garden',
      sppl_ean: '8720664812628',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4589766',
      rtl_batch_array: '4589766',
      name_search: 'WOWE3BR',
      name: 'Worth Verticale Tuin element 3vaks Terra',
      sppl_size_code: 'ST14PL3T',
      rng_range_identifier: 'SET42',
      rng_range_description: 'Set 42',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_prcp: 65,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'W5D3N5WT',
      statusContent: 'dot',
      hash: 'V1L1Y146',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'V1L1Y146'
    },
    {
      status: 910,
      sku: 'PM0077352',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: '4RGN1ECK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4RGN1ECK'
    },
    {
      status: 810,
      sku: 'PM0044955',
      core_name: 'Garden',
      sppl_ean: '8720664812550',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4603659',
      rtl_batch_array: '4603659',
      name_search: 'WOWE3GR',
      name: 'Worth Verticale Tuin element 3vaks Groen',
      sppl_size_code: 'DOOS14D',
      rng_range_identifier: 'BOX14PCS',
      rng_range_description: 'Box 14 Pieces',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 65,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'HL583CKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'HL583CKK'
    },
    {
      status: 210,
      sku: 'PM0077338',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301082',
      rtl_batch_array: '6301082, 6333387',
      name_search: 'DRDILATA',
      name: 'Dryopteris dilatata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 546,
      chnn_stock_retail: 658,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K2G6Y9DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2G6Y9DP'
    },
    {
      status: 210,
      sku: 'PM0077339',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301083',
      rtl_batch_array: '6301083, 6386110, 6348309',
      name_search: 'DRFMCCRI',
      name: "Dryopteris filix-mas 'Crispa Cristata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 958,
      chnn_stock_retail: 1298,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3L84T47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3L84T47'
    },
    {
      status: 210,
      sku: 'PM0021210',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350336',
      rtl_batch_array: '6350336, 5495362',
      name_search: 'HEODEPIC',
      name: "Helleborus orientalis 'Double Ellen Picotee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 227,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D4257R7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4257R7G'
    },
    {
      status: 210,
      sku: 'PM0080984',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338385',
      rtl_batch_array: '6338385',
      name_search: 'PIJPRELU',
      name: "Pieris japonica 'Prelude'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1509,
      chnn_stock_retail: 1509,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEEN2RS6;GBABYAVJ;PWVN3L65;54XX6NT9;WGSB78LB;TPL6GSY7;X6S5R4LP;8EJ76ZPE;ZZLHYR3B;JG8DX381',
      statusContent: 'dot',
      hash: 'E66D55JZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E66D55JZ'
    },
    {
      status: 810,
      sku: 'PM0080985',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338386',
      rtl_batch_array: '6338386',
      name_search: 'PIJRALTO',
      name: "Pieris japonica 'Ralto'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DV912VVC;JKX1YSWK;S13XH7R4;KPB51T9C;NX21Z3GN;Y3ZWHYTD;42EJTA6D;DK9LZ9SH;1ZV7JBDG;LN3V2WSZ;K19X7SZW;PEYL6PGC;DA57BYJN;4P6CSB6L;6WV6NAH5;6R265BVR;2JKA5P57;V245DE8X;GG324LNH',
      statusContent: 'dot',
      hash: 'VTPE22XT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTPE22XT'
    },
    {
      status: 210,
      sku: 'PM0045173',
      core_name: 'Plant',
      sppl_ean: '8720349416578',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5953621',
      rtl_batch_array: '5592131, 5953621',
      name_search: 'GESALBUM',
      name: "Geranium sanguineum 'Album'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3203,
      chnn_stock_retail: 4203,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWW894AG;H339Y9JL;BJAD93HB;8N3G6J8Y;BN3YAHX6;K3D4B4Z8;5JT3ZVH6',
      statusContent: 'dot',
      hash: 'YELJG3V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YELJG3V1'
    },
    {
      status: 210,
      sku: 'PM0068047',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6324689',
      rtl_batch_array: '6260109, 6275767, 6307972, 6287666, 6324689',
      name_search: 'ANHPHEIN',
      name: "Anemone hupehensis 'Prinz Heinrich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 6577,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P1GVRP8X;EGHZT32P;N1VKLVVP;YE462XS7;KNASX3TE;Y28S5YDE;SYKT6K52;8HT5EW56;4DJ6PXL4;HH9W31LX;T3S61YPK;14RXYEXY;PRDTNTAK;9VKR37SB;A2KXA4TT',
      statusContent: 'dot',
      hash: 'E1H9GSAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1H9GSAW'
    },
    {
      status: 210,
      sku: 'PM0077343',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 7,
      btch_active_retail: '6382313',
      rtl_batch_array:
        '6369255, 6315070, 6301095, 6382313, 6308752, 6356276, 6287698',
      name_search: 'SEAUTUMN',
      name: 'Sesleria autumnalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12000,
      chnn_stock_retail: 67477,
      sppl_order_minimum: 3,
      sppl_prcp: 0.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VB9R7NX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VB9R7NX3'
    },
    {
      status: 210,
      sku: 'PM0045175',
      core_name: 'Plant',
      sppl_ean: '8720349405398',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592133',
      rtl_batch_array: '5592133',
      name_search: 'HOFAUREO',
      name: "Hosta 'Fortunei Aureomarginata'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A34T7ERG;TY3C8ZR9;SSK3W62L;X96RPE39;T8Y5GYKH;5EW82J7P;AYLD8HA7;PVK9X9Z1;ZZ7J62W4;XE3CRXRP;XD37KXVK;V5XY122S;Y8J689KS;4L4R6XWS;HKDT7VJ3;G4P5CE5E;ZBYNEH34;9AZX9K5S;GBXCWW3X;YL53WNLB',
      imageBatch: '5B1VL2S6',
      statusContent: 'dot',
      hash: '8E6EGJ6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8E6EGJ6Y'
    },
    {
      status: 210,
      sku: 'PM0034022',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363182',
      rtl_batch_array: '6176459, 5866424, 6363182',
      name_search: 'IBSSNOWF',
      name: "Iberis semp. 'Snowflake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15118,
      chnn_stock_retail: 16449,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '149H9R81;5Z3WP6HG;YXNTHBLR;9DADVVKR',
      statusContent: 'dot',
      hash: 'JW2347DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JW2347DX'
    },
    {
      status: 210,
      sku: 'PM0060024',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6373599',
      rtl_batch_array: '5263162, 6309339, 6373599',
      name_search: 'STGIGANT',
      name: 'Stipa gigantea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1880,
      chnn_stock_retail: 7286,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RWT5S8TE;ESBDT8HW',
      statusContent: 'dot',
      hash: 'BE5EXXJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE5EXXJC'
    },
    {
      status: 910,
      sku: 'PM0045177',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6187762',
      rtl_batch_array: '6187762, 6226868',
      name_search: 'IRSIOPAL',
      name: "Iris sibirica 'Imperial Opal'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6,
      chnn_stock_retail: 1006,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G85VDX36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G85VDX36'
    },
    {
      status: 910,
      sku: 'PM0044956',
      core_name: 'Garden',
      sppl_ean: '8720664812581',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4603662',
      rtl_batch_array: '4603662',
      name_search: 'WOWE3BR',
      name: 'Worth Verticale Tuin element 3vaks Terra',
      sppl_size_code: 'DOOS14D',
      rng_range_identifier: 'BOX14PCS',
      rng_range_description: 'Box 14 Pieces',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 65,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'BWCXBP1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'BWCXBP1K'
    },
    {
      status: 810,
      sku: 'PM0080986',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338387',
      rtl_batch_array: '6338387',
      name_search: 'PIJRMILL',
      name: "Pieris japonica 'Red Mill'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P27S4W9Y;RB5ADJ46;7BSHB85V;61NVEJRW;P8SP8G7S;ED2SD6AS;12VNEV7G;Z6SR8JSR;154SPS99;ESRE6CGA;83TBHDWZ;E79V1Z79',
      statusContent: 'dot',
      hash: 'DGSNA7TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGSNA7TZ'
    },
    {
      status: 910,
      sku: 'PM0085052',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAVHHERM',
      name: "Panicum virgatum 'H\u00e4nse Herms'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'D8R9S5BK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D8R9S5BK'
    },
    {
      status: 210,
      sku: 'PM0077346',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302265',
      rtl_batch_array: '6302265',
      name_search: 'ARMHUGIN',
      name: "Aronia melanocarpa 'Hugin'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5TCDL68E;YGCTNB2R;1SRW387V;BPDCYZ86;NLP83ELZ;VTCT3H1S;KYDLXEAY;ELDY56HC;57S12SZ6',
      statusContent: 'dot',
      hash: 'HWT8VTWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWT8VTWW'
    },
    {
      status: 210,
      sku: 'PM0070780',
      core_name: 'Plant',
      sppl_ean: '8720349445523',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5430087',
      rtl_batch_array: '5430087',
      name_search: 'DEGNIKKO',
      name: "Deutzia gracilis 'Nikko'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4XTBD8Y',
      statusContent: 'dot',
      hash: 'KTN12YE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTN12YE6'
    },
    {
      status: 210,
      sku: 'PM0077335',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301070',
      rtl_batch_array: '6301070',
      name_search: 'ALEALMA',
      name: "Alchemilla erythropoda 'Alma'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10152,
      chnn_stock_retail: 10152,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E6DYK4KP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6DYK4KP'
    },
    {
      status: 810,
      sku: 'PM0044963',
      core_name: 'Garden',
      sppl_ean: '8720626332027',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700932',
      rtl_batch_array: '4700932',
      name_search: 'WO7BPRUN',
      name: 'Worth snoeischaar, zijsnijder 22cm 1066',
      sppl_size_code: '018ALST7I16',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'DPSS5LPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'DPSS5LPZ'
    },
    {
      status: 210,
      sku: 'PM0044971',
      core_name: 'Plant',
      sppl_ean: '8720664689435',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '4710474',
      rtl_batch_array: '4710474',
      name_search: 'EUJPIERR',
      name: 'Euonymus japonicus Pierrolino',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 968,
      chnn_stock_retail: 968,
      sppl_order_minimum: 3,
      sppl_prcp: 2.703,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'K4RNWTAK',
      statusContent: 'dot',
      hash: 'TNRZPRKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNRZPRKB'
    },
    {
      status: 210,
      sku: 'PM0077344',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301301',
      rtl_batch_array: '6301301',
      name_search: 'SAMPLIPS',
      name: 'Salvia microphylla Pink Lips',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 704,
      chnn_stock_retail: 704,
      sppl_prcp: 2.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z56WVDZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z56WVDZS'
    },
    {
      status: 210,
      sku: 'PM0077347',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302266',
      rtl_batch_array: '6302266',
      name_search: 'ARPNERO',
      name: "Aronia prunifolia 'Nero'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9TLC939N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TLC939N'
    },
    {
      status: 910,
      sku: 'PM0044968',
      core_name: 'Garden',
      sppl_ean: '8720626332034',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700946',
      rtl_batch_array: '4700946',
      name_search: 'WO8BPRUN',
      name: 'Worth snoeischaar, zijsnijder 25cm 1055',
      sppl_size_code: '020KSST4I16',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageCore: 'X4WNKRWW;K9ATL8DX;98ZL1T61',
      statusContent: 'dot',
      hash: 'T22W37D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'T22W37D9'
    },
    {
      status: 210,
      sku: 'PM0077348',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302267',
      rtl_batch_array: '6302267',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: 'CK24X33J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CK24X33J'
    },
    {
      status: 210,
      sku: 'PM0045184',
      core_name: 'Plant',
      sppl_ean: '8720349405510',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592156',
      rtl_batch_array: '5592156',
      name_search: 'HOSSPICE',
      name: "Hosta 'Sugar and Spice'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'V7ZLTNZT',
      statusContent: 'dot',
      hash: 'NK2STD75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NK2STD75'
    },
    {
      status: 210,
      sku: 'PM0054409',
      core_name: 'Plant',
      sppl_ean: '8720349452798',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420705',
      rtl_batch_array: '5420705',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2078,
      chnn_stock_retail: 2078,
      sppl_order_minimum: 3,
      sppl_prcp: 2.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: 'V3SP85R6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3SP85R6'
    },
    {
      status: 910,
      sku: 'PM0044967',
      core_name: 'Garden',
      sppl_ean: '8720626332010',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700945',
      rtl_batch_array: '4700945',
      name_search: 'WO8SHSHE',
      name: 'Worth 8 Inch Standard Hedge Shear 1125',
      sppl_size_code: '055ALST8I10',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 3.67,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'DZZER95T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'DZZER95T'
    },
    {
      status: 210,
      sku: 'PM0077349',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302268',
      rtl_batch_array: '6302268',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'ZRCKXV2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRCKXV2Y'
    },
    {
      status: 210,
      sku: 'PM0077350',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302270',
      rtl_batch_array: '6302270',
      name_search: 'COASPAET',
      name: "Cornus alba 'Spaethii'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LW8LKW6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LW8LKW6P'
    },
    {
      status: 210,
      sku: 'PM0068449',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 4,
      btch_active_retail: '5382021',
      rtl_batch_array: '5382021, 6305227, 6252721, 6336254',
      name_search: 'PEARUBRU',
      name: "Pennisetum advena 'Rubrum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 465,
      chnn_stock_retail: 2054,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6C25DWW;VJT3ZRAP;ZACZP7TZ;EW59DX9G;N2WYZ1C9;NRLDVYX5;LGD4ZEEG;S4EEAAZR;WVHDRZ99;PBZLX9EG;RTEYHY9B;J9PWY479;98V4VGKK;LWEZ8D7E;TZBW9958;KGX25VCY;KD69RY4A;C9NPZ1N9;738Z415A;SP67698E;LZKLAEVE;RCNZHP1N;TP5Z8GWT;K3Z66JDE;5HN3S3YJ;YPYWCGB2;K3TBB8R4;H42WWAWD',
      imageBatch: 'RCHC3VKE',
      statusContent: 'dot',
      hash: 'R4S5DZ67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4S5DZ67'
    },
    {
      status: 210,
      sku: 'PM0080987',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338388',
      rtl_batch_array: '6338388',
      name_search: 'PIJSELEC',
      name: "Pieris japonica 'Select'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 710,
      chnn_stock_retail: 710,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2PLWR5VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PLWR5VN'
    },
    {
      status: 910,
      sku: 'PM0044965',
      core_name: 'Garden',
      sppl_ean: '8720626332058',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700939',
      rtl_batch_array: '4700939',
      name_search: 'WO4BLOPP',
      name: 'Worth Takkenschaar 15cm 1214',
      sppl_size_code: '060ALST4I15',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 3.85,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'EGV3A4TE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'EGV3A4TE'
    },
    {
      status: 210,
      sku: 'PM0077351',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302271',
      rtl_batch_array: '6302271',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'NHSZJJ2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHSZJJ2B'
    },
    {
      status: 210,
      sku: 'PM0085053',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386064',
      rtl_batch_array: '6386064',
      name_search: 'ARPNEUSC',
      name: "Arabis procurrens 'Neuschnee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BNR6634T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNR6634T'
    },
    {
      status: 210,
      sku: 'PM0085054',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6248981',
      rtl_batch_array: '6248981',
      name_search: 'LAIPHENO',
      name: 'Lavandula intermedia Phenomenal',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 1.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DX6CYSH;CBRN3JBS;J1HS51LW;SY7AS57S;DB5JE4VK;8G1BRDBV;CG4KNZJJ',
      statusContent: 'dot',
      hash: 'RX42N4TC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX42N4TC'
    },
    {
      status: 210,
      sku: 'PM0010490',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6363212',
      rtl_batch_array: '6133764, 5495517, 6353371, 6363212',
      name_search: 'TEGRANDI',
      name: 'Tellima grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2962,
      chnn_stock_retail: 8898,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T91WD47P;PSK8CTY4;PYA8RY86;S9LS4YWX;VB2T7YRK',
      statusContent: 'dot',
      hash: 'H5J7Y497',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5J7Y497'
    },
    {
      status: 910,
      sku: 'PM0044969',
      core_name: 'Garden',
      sppl_ean: '8720626332065',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700947',
      rtl_batch_array: '4700947',
      name_search: 'WO4TBLOP',
      name: 'Worth Telescopische takkenschaar 1213',
      sppl_size_code: '080ALST4I35',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '496SKLBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '496SKLBW'
    },
    {
      status: 210,
      sku: 'PM0077355',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302275',
      rtl_batch_array: '6302275',
      name_search: 'PHCAUREU',
      name: "Philadelphus coronarius 'Aureus'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WGN7W2GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGN7W2GW'
    },
    {
      status: 810,
      sku: 'PM0066219',
      core_name: 'Plant',
      sppl_ean: '8720664683730',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207242',
      rtl_batch_array: '5207242',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      imageBatch: '6P8T4S88',
      statusContent: 'dot',
      hash: '8L1AT86B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8L1AT86B'
    },
    {
      status: 810,
      sku: 'PM0052335',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339912',
      rtl_batch_array: '6339912, 6217076',
      name_search: 'SAOALBA',
      name: "Sanguisorba obtusa 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 319,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69GEP4RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69GEP4RJ'
    },
    {
      status: 210,
      sku: 'PM0066224',
      core_name: 'Plant',
      sppl_ean: '8720626369610',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5400069',
      rtl_batch_array: '5400069',
      name_search: 'SEMJRUBY',
      name: "Sedum 'Mojave Jewels Ruby'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RT6G8DA5',
      statusContent: 'dot',
      hash: 'ERHJB3BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERHJB3BR'
    },
    {
      status: 210,
      sku: 'PM0066221',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5304249',
      rtl_batch_array: '5304249',
      name_search: 'EUJSUSAN',
      name: "Euonymus japonicus 'Susan'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YRYJCSDB;G2ENXP6V;582DNDNN;XNBTS5Y9;SKVS2VKD;S5K81W5C;6J6DNDEH;1DXEJT81;BKKNA9NR;XHP9R2WC;H1S8WV1E;61TL7CXY;RSADHCJP',
      imageBatch: 'X9NBBJTH',
      statusContent: 'dot',
      hash: 'RNHV58RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNHV58RW'
    },
    {
      status: 210,
      sku: 'PM0063207',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208070',
      rtl_batch_array: '6208070, 6348406',
      name_search: 'PEAKABOU',
      name: "Persicaria affinis 'Kabouter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 135,
      chnn_stock_retail: 771,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CWP7P5H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWP7P5H7'
    },
    {
      status: 210,
      sku: 'PM0077360',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310429',
      rtl_batch_array: '6302280, 6310429',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 177,
      chnn_stock_retail: 277,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'BZVXTWD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZVXTWD1'
    },
    {
      status: 910,
      sku: 'PM0044957',
      core_name: 'Garden',
      sppl_ean: '8720664812598',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4603664',
      rtl_batch_array: '4603664',
      name_search: 'WOWE3BR',
      name: 'Worth Verticale Tuin element 3vaks Terra',
      sppl_size_code: 'DOOS3D',
      rng_range_identifier: 'BOX3PCS',
      rng_range_description: 'Box 3 Pieces',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 14.38,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '9A1Z538R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '9A1Z538R'
    },
    {
      status: 210,
      sku: 'PM0077340',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301090',
      rtl_batch_array: '6301090',
      name_search: 'POBRAUNI',
      name: 'Polystichum braunii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KDB7ZDLD',
      statusContent: 'dot',
      hash: '1K687KYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K687KYK'
    },
    {
      status: 810,
      sku: 'PM0077361',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302281',
      rtl_batch_array: '6302281',
      name_search: 'WEPROSA',
      name: 'Weigela Picobella Rosa',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YZC3GG9;WAAKJ7BH;WEYPSCBD;YVZ9HV79',
      statusContent: 'dot',
      hash: 'LC1RRR7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC1RRR7H'
    },
    {
      status: 210,
      sku: 'PM0077362',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302301',
      rtl_batch_array: '6302301',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: 'G8E5YH23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8E5YH23'
    },
    {
      status: 210,
      sku: 'PM0045180',
      core_name: 'Plant',
      sppl_ean: '8720664695412',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592146',
      rtl_batch_array: '5592146',
      name_search: 'IRSKABOO',
      name: "Iris sibirica 'Kaboom'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '56EV13N1',
      statusContent: 'dot',
      hash: 'GYTB2SA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYTB2SA2'
    },
    {
      status: 210,
      sku: 'PM0044954',
      core_name: 'Garden',
      sppl_ean: '8720664812611',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4589768',
      rtl_batch_array: '4589768',
      name_search: 'WOWE3BR',
      name: 'Worth Verticale Tuin element 3vaks Terra',
      sppl_size_code: 'ST3PL3T',
      rng_range_identifier: 'SET9',
      rng_range_description: 'Set 9',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_prcp: 13.9,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'HE9C94KV',
      statusContent: 'dot',
      hash: 'S44CCZE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'S44CCZE2'
    },
    {
      status: 210,
      sku: 'PM0066226',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084923',
      rtl_batch_array: '6084923',
      name_search: 'GAMRED',
      name: "Gaillardia 'Mesa Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 16998,
      chnn_stock_retail: 16998,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4G25LW9',
      statusContent: 'dot',
      hash: 'SXK6E75K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXK6E75K'
    },
    {
      status: 910,
      sku: 'PM0077364',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302303',
      rtl_batch_array: '6302303',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 15.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'TY6H3WPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TY6H3WPR'
    },
    {
      status: 210,
      sku: 'PM0045209',
      core_name: 'Plant',
      sppl_ean: '8720349405541',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592221',
      rtl_batch_array: '5592221',
      name_search: 'HOUMEDIO',
      name: "Hosta 'Undulata Mediovariegata'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6V28XX9;GSZJ8TPR;SVE1W1E1;6GPBXBL7;JWYRK432;BGSV9N2K;8ECZYC8Y;4P2496TH;PPKVRNRP;ETBZA2Z5',
      imageBatch: '7NKWDVNP',
      statusContent: 'dot',
      hash: 'LNKVKH1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNKVKH1V'
    },
    {
      status: 210,
      sku: 'PM0077366',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302306',
      rtl_batch_array: '6302306',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: 'S5T8Z45L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5T8Z45L'
    },
    {
      status: 210,
      sku: 'PM0077367',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302307',
      rtl_batch_array: '6302307',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'D2RBXYH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2RBXYH9'
    },
    {
      status: 210,
      sku: 'PM0077368',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302308',
      rtl_batch_array: '6302308',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'TYJN9PRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYJN9PRD'
    },
    {
      status: 810,
      sku: 'PM0066220',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207273',
      rtl_batch_array: '5207273',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '030040C10',
      rng_range_identifier: 'H030040C10',
      rng_range_description: 'H30 cm 40 cm C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'X1KJXTN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1KJXTN8'
    },
    {
      status: 210,
      sku: 'PM0052309',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217164',
      rtl_batch_array: '6161735, 6217164',
      name_search: 'HECPIRAT',
      name: "Hemerocallis 'Crimson Pirate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4893,
      chnn_stock_retail: 5936,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7CVZ4RX9;JCV6JZPN;7WG3NTG2;PE2NEJYV;2X9GKSS7;G1XEV8R9;S4STJG33',
      statusContent: 'dot',
      hash: '9X4CW9Y9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X4CW9Y9'
    },
    {
      status: 210,
      sku: 'PM0077332',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272029',
      rtl_batch_array: '6272029',
      name_search: 'LISFVIOL',
      name: "Liatris spicata 'Floristan Violett'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1244,
      chnn_stock_retail: 1244,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DB24JYT5;PDB5YG8N',
      statusContent: 'dot',
      hash: 'SVH9P2KG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVH9P2KG'
    },
    {
      status: 210,
      sku: 'PM0066215',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6305226',
      rtl_batch_array: '6346713, 3744742, 6234381, 6305226',
      name_search: 'MIGIGANT',
      name: 'Miscanthus giganteus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 481,
      chnn_stock_retail: 2409,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XX3H731W;WDGZWWW7;T9CGN2Z4;X7N65LYX;S9DNA3Y7;PXRA3199;GWVVS1KD;77KRS8YS',
      statusContent: 'dot',
      hash: '4Z7PS1P9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z7PS1P9'
    },
    {
      status: 210,
      sku: 'PM0077337',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301081',
      rtl_batch_array: '6301081',
      name_search: 'COLBGOLD',
      name: "Coreopsis lanceolata 'Baby Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 736,
      chnn_stock_retail: 736,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T3DYHPNR',
      statusContent: 'dot',
      hash: 'ZLZ6BBDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLZ6BBDP'
    },
    {
      status: 810,
      sku: 'PM0044964',
      core_name: 'Garden',
      sppl_ean: '8720626332072',
      btch_manufacturer: 14143435,
      rtl_batch_array_total: 1,
      btch_active_retail: '4700937',
      rtl_batch_array: '4700937',
      name_search: 'WO9THSHE',
      name: 'Worth, getande heggeschaar 1107',
      sppl_size_code: '060ALST9ID4',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 4.63,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'XPZP5GR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'XPZP5GR1'
    },
    {
      status: 210,
      sku: 'PM0046434',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353212',
      rtl_batch_array: '6133617, 6353212',
      name_search: 'SALKISS',
      name: "Salvia 'Little Kiss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 446,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E7ZY367P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7ZY367P'
    },
    {
      status: 210,
      sku: 'PM0077336',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301079',
      rtl_batch_array: '6349585, 6301079',
      name_search: 'ATNMETAL',
      name: "Athyrium niponicum 'Metalicum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1934,
      chnn_stock_retail: 2334,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4XTWBTY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XTWBTY5'
    },
    {
      status: 210,
      sku: 'PM0077342',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6386230',
      rtl_batch_array: '6301094, 6386230, 6242978',
      name_search: 'POTSUSSI',
      name: 'Polystichum tsussimense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 2871,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D5V341W8;ZLKS9L84;1RC9JT6J',
      statusContent: 'dot',
      hash: 'Z3EVHVXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3EVHVXT'
    },
    {
      status: 210,
      sku: 'PM0077345',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333296',
      rtl_batch_array: '6333296',
      name_search: 'PHSTYLOS',
      name: 'Phuopsis stylosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WTES492J;VVKBT5X4;LR9A8YNS;J8C27AYN;DW7K1HKW',
      statusContent: 'dot',
      hash: 'G957LYVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G957LYVZ'
    },
    {
      status: 210,
      sku: 'PM0077365',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302305',
      rtl_batch_array: '6302305',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 2.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: 'WLJNVCYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLJNVCYT'
    },
    {
      status: 210,
      sku: 'PM0068468',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200390',
      rtl_batch_array: '6200390',
      name_search: 'JUDLENIN',
      name: "Juniperus davurica 'Leningrad'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CWT6132A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWT6132A'
    },
    {
      status: 210,
      sku: 'PM0077369',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385798',
      rtl_batch_array: '6252640, 6385798',
      name_search: 'IBSEMPER',
      name: 'Iberis sempervirens',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 967,
      chnn_stock_retail: 1307,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N84Y8S3K;LR8TJ651;75KE5S7A;X2CESCCC;Y9SZYR69;NN75Z5JR;B387YK5D;5SBY24D5;E62V8PYZ;G19L4687;767Y9LTD;C8VYC8JE;SST1ZJXY;8ZSKAD5W;JDCXECTW;NBNGWXYT;1APKV74D;6GD2ZAY6;9WHCSW79',
      statusContent: 'dot',
      hash: 'EY5JSEZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY5JSEZ8'
    },
    {
      status: 210,
      sku: 'PM0044953',
      core_name: 'Garden',
      sppl_ean: '8720664812604',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4589767',
      rtl_batch_array: '4589767',
      name_search: 'WOWE3BR',
      name: 'Worth Verticale Tuin element 3vaks Terra',
      sppl_size_code: 'ST12PL3T',
      rng_range_identifier: 'SET36PCS',
      rng_range_description: 'Set 36 Pieces',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_prcp: 53,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'SXWKSXGB',
      statusContent: 'dot',
      hash: 'ZXKZAAZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'ZXKZAAZH'
    },
    {
      status: 210,
      sku: 'PM0077341',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308460',
      rtl_batch_array: '6301093, 6353216, 6308460, 6348447, 6348967',
      name_search: 'POSETIFE',
      name: 'Polystichum setiferum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 24011,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HC19TGYC;ELKBAT9Z;24CT8HN2;VGAVGVLV;E77T1ANN',
      statusContent: 'dot',
      hash: 'Y244BB86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y244BB86'
    },
    {
      status: 210,
      sku: 'PM0077356',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302276',
      rtl_batch_array: '6302276',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: '6WB1EKKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WB1EKKB'
    },
    {
      status: 210,
      sku: 'PM0077357',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302277',
      rtl_batch_array: '6302277',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'LCLVWG5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCLVWG5X'
    },
    {
      status: 210,
      sku: 'PM0068472',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272021',
      rtl_batch_array: '6272021',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2557,
      chnn_stock_retail: 2557,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: 'H4ES4BPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4ES4BPZ'
    },
    {
      status: 210,
      sku: 'PM0068467',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272019',
      rtl_batch_array: '6272019',
      name_search: 'JUCGCARP',
      name: "Juniperus communis 'Green Carpet'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 4511,
      chnn_stock_retail: 4511,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8JCWSDK;ZR2S66Y8;8LENK36Y;PCPJKSAW;AR8SW9TC;R52VR1LR;PHC1CE39;TKSV3RR5;Z65N4W5A;SHYT7DT2;DEB813ZV;X12G2J1L;Y8AGGRKY;2GX8TKBR;E2GBBLY7;SXP5663N;1BR9CVW8;7N3R494Y;E1BY7K57',
      statusContent: 'dot',
      hash: '4R95YP4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4R95YP4L'
    },
    {
      status: 210,
      sku: 'PM0057939',
      core_name: 'Plant',
      sppl_ean: '8720626312562',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871923',
      rtl_batch_array: '5871923',
      name_search: 'MESPICAT',
      name: 'Mentha spicata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NTKT5TJD;NX8RANEK;PD7Z6DA3;GC73HDW2;8PL8PSL3;DCZSZCZK;RT78RYEB;Y5CPSLSL;2J5WL4S5;JT574TK7;4AVL82CG;12DNC19H;AXRNEJ36;7V4986ZA;J4D8CBKV;5RCVYBEY;9ZV5GAHT;51PJB8BR;2HT2R117;Z21TRVV9;1CKH42VW',
      statusContent: 'dot',
      hash: '1GN449VS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GN449VS'
    },
    {
      status: 210,
      sku: 'PM0077358',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302278',
      rtl_batch_array: '6302278',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'LK7PKXJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LK7PKXJS'
    },
    {
      status: 210,
      sku: 'PM0045193',
      core_name: 'Plant',
      sppl_ean: '8720664692572',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592176',
      rtl_batch_array: '5592176',
      name_search: 'HOGSTAND',
      name: "Hosta 'Gold Standard'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13WAVW7C;P541X5CG;5HJPLD7P;DTT6WYG9',
      imageBatch: 'P4CHGYHL',
      statusContent: 'dot',
      hash: '961V9WBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '961V9WBJ'
    },
    {
      status: 810,
      sku: 'PM0052318',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210037',
      rtl_batch_array: '6210037',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 5.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: '67WEE22N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67WEE22N'
    },
    {
      status: 210,
      sku: 'PM0068473',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200395',
      rtl_batch_array: '6200395',
      name_search: 'PIGZUCKE',
      name: "Picea glauca 'Zuckerhut'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 389,
      chnn_stock_retail: 389,
      sppl_prcp: 5.339,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4D465T7Y',
      statusContent: 'dot',
      hash: '9AX261CG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AX261CG'
    },
    {
      status: 810,
      sku: 'PM0056953',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301393',
      rtl_batch_array: '6301393',
      name_search: 'AMBREVIL',
      name: 'Ammophila breviligulata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XBPBL146',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBPBL146'
    },
    {
      status: 210,
      sku: 'PM0068451',
      core_name: 'Plant',
      sppl_ean: '8720664502895',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495237',
      rtl_batch_array: '5495237',
      name_search: 'CACMAJOR',
      name: "Catananche caerulea 'Major'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YJE715BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJE715BN'
    },
    {
      status: 210,
      sku: 'PM0069907',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219470',
      rtl_batch_array: '6219470',
      name_search: 'AGBFORTU',
      name: "Agastache 'Blue Fortune'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 33210,
      chnn_stock_retail: 33210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B2R11S8Z;W48S9DTS;5DSE5BTA;HTWDBNAP;24XTTG9S;SNL33P1P',
      statusContent: 'dot',
      hash: '85J4G5Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85J4G5Z1'
    },
    {
      status: 210,
      sku: 'PM0068448',
      core_name: 'Plant',
      sppl_ean: '8720349490820',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364376',
      rtl_batch_array: '6315061, 5364376',
      name_search: 'PAVROTST',
      name: "Panicum virgatum 'Rotstrahlbusch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 221,
      chnn_stock_retail: 2246,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '465RJY8V;GLTPRJWC;5PDC2D6E;EX449DD1;Y91VH24K;B2E85B5G;E2Z6YKLP;S8ZTES9R;R7XVP3LX;4CPBG92G;9PR892J8;YJC76RY8;CG53XZ3S',
      statusContent: 'dot',
      hash: 'TTPR3EG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTPR3EG4'
    },
    {
      status: 210,
      sku: 'PM0070781',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271325',
      rtl_batch_array: '6271325',
      name_search: 'BUMRUBY',
      name: "Buddleja 'Miss Ruby'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1424,
      chnn_stock_retail: 1424,
      sppl_prcp: 3.381,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4LS1XKJ',
      statusContent: 'dot',
      hash: 'HGZZL6DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGZZL6DB'
    },
    {
      status: 210,
      sku: 'PM0057931',
      core_name: 'Plant',
      sppl_ean: '8720349435265',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5368857',
      rtl_batch_array: '5368857',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1446,
      chnn_stock_retail: 1446,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      imageBatch: '6PG7XKHB',
      statusContent: 'dot',
      hash: '6C1TZ14N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6C1TZ14N'
    },
    {
      status: 210,
      sku: 'PM0077354',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302274',
      rtl_batch_array: '6302274',
      name_search: 'FOIMINIG',
      name: 'Forsythia intermedia Minigold',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TVELCCX3;Y5GD8V13;NEKX537A;D69Y7KC6',
      statusContent: 'dot',
      hash: 'AG6EA4PZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AG6EA4PZ'
    },
    {
      status: 210,
      sku: 'PM0068514',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223371',
      rtl_batch_array: '6223371',
      name_search: 'SESFULDA',
      name: "Sedum spurium 'Fuldaglut'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 651,
      chnn_stock_retail: 651,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X31E5GNB;5G89913K;DYVTWPPL;9CJEZN57;JNJ7XJKE;CKA346SD;8P61PHPY',
      statusContent: 'dot',
      hash: 'Y2SWV533',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2SWV533'
    },
    {
      status: 210,
      sku: 'PM0077363',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302302',
      rtl_batch_array: '6302302',
      name_search: 'OSHVARIE',
      name: "Osmanthus heterophyllus 'Variegatus'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6V75CZTX;N5SYK8RA;6CGNG5PV;VLAC8KDB;WZ4S93AG;2PK5D4WZ;BWGS5H5R;NZ7EHYNW;S5VJKTYA;N29GETPD',
      statusContent: 'dot',
      hash: '1DVSAYWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DVSAYWA'
    },
    {
      status: 210,
      sku: 'PM0059616',
      core_name: 'Plant',
      sppl_ean: '8720664523401',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592008',
      rtl_batch_array: '5592008',
      name_search: 'DEADPURP',
      name: "Delphinium (E) 'Aurora Deep Purple'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'BS2ELA2Z',
      statusContent: 'dot',
      hash: 'DZWSGS15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZWSGS15'
    },
    {
      status: 210,
      sku: 'PM0077359',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302279',
      rtl_batch_array: '6302279',
      name_search: 'POFMWHIT',
      name: "Potentilla fruticosa 'McKay's White'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TYSCWZ9E;2CGSLNEZ',
      statusContent: 'dot',
      hash: 'WPAW89G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPAW89G6'
    },
    {
      status: 210,
      sku: 'PM0052324',
      core_name: 'Plant',
      sppl_ean: '8720664680982',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037882',
      rtl_batch_array: '6037882',
      name_search: 'ARLUDOVI',
      name: 'Artemisia ludoviciana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 883,
      chnn_stock_retail: 883,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7VCDV96V',
      statusContent: 'dot',
      hash: 'GNBJLSX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNBJLSX6'
    },
    {
      status: 210,
      sku: 'PM0052310',
      core_name: 'Plant',
      sppl_ean: '8720353027722',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495460',
      rtl_batch_array: '5495460, 6161879, 6301792',
      name_search: 'PHDAVID',
      name: "Phlox (P) 'David'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 1337,
      sppl_order_minimum: 3,
      sppl_prcp: 0.819,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2DKVDL4Z;WLCDZC3X;N856B7LN;JKHYT3H6;C5L9Z5PJ;HXRLYLJ7',
      statusContent: 'dot',
      hash: 'P9E68GN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9E68GN8'
    },
    {
      status: 210,
      sku: 'PM0056937',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6284529',
      rtl_batch_array: '4459402, 6252671, 6370492, 6284529',
      name_search: 'LUSYLVAT',
      name: 'Luzula sylvatica',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1989,
      chnn_stock_retail: 4202,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YW67DVWP',
      statusContent: 'dot',
      hash: 'XTDRL7P1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTDRL7P1'
    },
    {
      status: 210,
      sku: 'PM0057957',
      core_name: 'Plant',
      sppl_ean: '8720664878303',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084647',
      rtl_batch_array: '6084647, 6308728, 6352700',
      name_search: 'PHECBLUE',
      name: "Phlox (S) 'Emerald Cushion Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1756,
      chnn_stock_retail: 4534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1R1S915;2ETE28TH;WW2WD4RA;2B3CRWDB;T2X7GAKT;L9TWE6LN;L7HRCYNH;G1LY5XBD;9TKP9KTP;C25JSX29',
      statusContent: 'dot',
      hash: 'WXDYAN11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXDYAN11'
    },
    {
      status: 210,
      sku: 'PM0080988',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338390',
      rtl_batch_array: '6338390',
      name_search: 'PIVIBURN',
      name: 'Pileostegia viburnoides',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1910,
      chnn_stock_retail: 1910,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CTXCRZAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTXCRZAG'
    },
    {
      status: 910,
      sku: 'PM0054228',
      core_name: 'Plant',
      sppl_ean: '8720664802216',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6066824',
      rtl_batch_array: '6066824',
      name_search: 'PIALOREL',
      name: "Picea abies 'Loreley'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APE2ELEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APE2ELEP'
    },
    {
      status: 210,
      sku: 'PM0070807',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280166',
      rtl_batch_array: '6280166',
      name_search: 'CHLCOLUM',
      name: "Chamaecyparis lawsoniana 'Columnaris'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 986,
      chnn_stock_retail: 986,
      sppl_prcp: 3.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZ65YEBT;NABYNC2K;1AGEPTPC;9VL4TXHX;949C1DA4;L676NZGD;233NPP8C;54BG9RKD;NXE71H79;832DA6RK;C8CWKS7S;D2ZK24VK;G1W87X9S;AAZ6HGPP',
      statusContent: 'dot',
      hash: 'KRXVB85D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRXVB85D'
    },
    {
      status: 210,
      sku: 'PM0068476',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200400',
      rtl_batch_array: '6200400',
      name_search: 'SCVERTIC',
      name: 'Sciadopitys verticillata',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1523,
      chnn_stock_retail: 1523,
      sppl_prcp: 11.196,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CBN1HY6;N6RBRE96;A3AAXPA6;9J7NWD3V;9X3DVLJP;D7NER187',
      statusContent: 'dot',
      hash: '7Z25TCC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z25TCC2'
    },
    {
      status: 210,
      sku: 'PM0057953',
      core_name: 'Plant',
      sppl_ean: '8720664801783',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084642',
      rtl_batch_array: '6084642, 6330036',
      name_search: 'PHFBLUE',
      name: 'Phlox Flame Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9799,
      chnn_stock_retail: 10171,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '54DV1P9W',
      statusContent: 'dot',
      hash: 'A5RGP4X9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5RGP4X9'
    },
    {
      status: 210,
      sku: 'PM0068482',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287036',
      rtl_batch_array: '6287036',
      name_search: 'THOMIRJA',
      name: "Thuja occidentalis 'Mirjam'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2882,
      chnn_stock_retail: 2882,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XA4ZXEDC;P272LPK6;PS419EA1;S2RDPHYW',
      statusContent: 'dot',
      hash: 'XCWE76LL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCWE76LL'
    },
    {
      status: 210,
      sku: 'PM0080989',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338391',
      rtl_batch_array: '6338391',
      name_search: 'PITENUIF',
      name: 'Pittosporum tenuifolium',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAK5T4WN',
      statusContent: 'dot',
      hash: 'KGYS4K8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGYS4K8J'
    },
    {
      status: 210,
      sku: 'PM0068485',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246586',
      rtl_batch_array: '6246586',
      name_search: 'THPWHIPC',
      name: "Thuja plicata 'Whipcord'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 3168,
      chnn_stock_retail: 3168,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PTZZ7EXJ;BHA97TVL;T6ADDNB5;CBSAJZHC;A6EHTCB8;8DBK8RE8;D6VNRE6D;AZKRBE9J;5ARLXDVH;83G54CZ8',
      statusContent: 'dot',
      hash: 'GC76GWT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GC76GWT5'
    },
    {
      status: 210,
      sku: 'PM0045197',
      core_name: 'Plant',
      sppl_ean: '8720349410156',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592185',
      rtl_batch_array: '5592185',
      name_search: 'ASCVRED',
      name: "Astilbe chinensis 'Vision in Red'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '4AVC1T6L',
      statusContent: 'dot',
      hash: 'H41EJLP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H41EJLP6'
    },
    {
      status: 210,
      sku: 'PM0080990',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338392',
      rtl_batch_array: '6338392',
      name_search: 'PITAGOLD',
      name: "Pittosporum tenuifolium 'Abbotsbury Gold'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EHBVRD4X;N9EBY2G8',
      statusContent: 'dot',
      hash: 'T9LS5JHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9LS5JHJ'
    },
    {
      status: 210,
      sku: 'PM0052344',
      core_name: 'Plant',
      sppl_ean: '8720353017792',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037952',
      rtl_batch_array: '6037952',
      name_search: 'CHLMAURE',
      name: "Chamaecyparis lawsoniana 'Minima Aurea'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJBX3HJC;1CX8CRND;K4LWYX99;5WX83KLL',
      statusContent: 'dot',
      hash: 'H8VLPPC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8VLPPC8'
    },
    {
      status: 210,
      sku: 'PM0044977',
      core_name: 'Plant',
      sppl_ean: '8720664808546',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '5265399',
      rtl_batch_array: '5265399',
      name_search: 'SECALCAR',
      name: 'Sempervivum calcaratum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2CXP73L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2CXP73L'
    },
    {
      status: 210,
      sku: 'PM0045191',
      core_name: 'Plant',
      sppl_ean: '8720349417759',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592171',
      rtl_batch_array: '5592171',
      name_search: 'HEUWATER',
      name: "Hemerocallis 'Unchartered Waters'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'DY91PV29',
      statusContent: 'dot',
      hash: '1DWJV7SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DWJV7SW'
    },
    {
      status: 210,
      sku: 'PM0080991',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338393',
      rtl_batch_array: '6338393',
      name_search: 'PITELIZA',
      name: "Pittosporum tenuifolium 'Elizabeth'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2750,
      chnn_stock_retail: 2750,
      sppl_order_minimum: 3,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DHD518HR;WE4D3751;4K382RX9;GABEE1AB;VR9WKP36;KJL32777;KKLZN7JN;S4T991A7;BEDAVS3H',
      statusContent: 'dot',
      hash: 'ZY495Z3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY495Z3P'
    },
    {
      status: 210,
      sku: 'PM0045198',
      core_name: 'Plant',
      sppl_ean: '8720349410019',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592195',
      rtl_batch_array: '5592195',
      name_search: 'ASELLIE',
      name: "Astilbe (J) 'Ellie'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NRAWA4Z2;BLVBLR9H;B78367NN;6S579SV9',
      imageBatch: 'P2B7VK55',
      statusContent: 'dot',
      hash: 'TCNTE55V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCNTE55V'
    },
    {
      status: 210,
      sku: 'PM0077375',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222163',
      rtl_batch_array: '6222163',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: '8YCD4K5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YCD4K5T'
    },
    {
      status: 810,
      sku: 'PM0077378',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164889',
      rtl_batch_array: '6164889',
      name_search: 'PHODOR',
      name: "Physocarpus opulifolius Diable d' Or",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S5ASBAKV;N75B8AS7;AWX8P2XJ;KZTVG5R1',
      statusContent: 'dot',
      hash: 'EE3APZNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE3APZNE'
    },
    {
      status: 210,
      sku: 'PM0077379',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339790',
      rtl_batch_array: '6217106, 6339790',
      name_search: 'DECWALDS',
      name: "Deschampsia cespitosa 'Waldschratt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 660,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '15D52AN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15D52AN1'
    },
    {
      status: 210,
      sku: 'PM0080992',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338394',
      rtl_batch_array: '6338394',
      name_search: 'PITGBALL',
      name: "Pittosporum tenuifolium 'Golf Ball'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2408,
      chnn_stock_retail: 2408,
      sppl_order_minimum: 5,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AW1D3AJX;J1D23SX7;ZYJBZSXC;4XACHRAP;XT79HNNT;KD3ZRJ1V',
      statusContent: 'dot',
      hash: '7TPN2L9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TPN2L9G'
    },
    {
      status: 210,
      sku: 'PM0070821',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307347',
      rtl_batch_array: '6307347',
      name_search: 'CLSHIRAY',
      name: "Clematis 'Shirayukihime'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 624,
      chnn_stock_retail: 624,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z986YCZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z986YCZ8'
    },
    {
      status: 210,
      sku: 'PM0045203',
      core_name: 'Plant',
      sppl_ean: '8720626392113',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592209',
      rtl_batch_array: '5592209',
      name_search: 'ASHGRAAF',
      name: "Astilbe (S) 'Hennie Graafland'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7GNB51J8;9GCHK9CJ;Z7SVKJAH;81Z3BNGV;ATLWZWWK;ER7YHR57;ZWWS97J2;6WR3PN3X;57EG465A',
      imageBatch: 'Z2YN9D6H',
      statusContent: 'dot',
      hash: 'E8TBXVL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8TBXVL8'
    },
    {
      status: 910,
      sku: 'PM0077381',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      rng_range_identifier: 'H080100C7.5',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'EECDY5D8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EECDY5D8'
    },
    {
      status: 910,
      sku: 'PM0077380',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226097',
      rtl_batch_array: '6226097',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 4.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: 'ACLB5ZCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACLB5ZCW'
    },
    {
      status: 210,
      sku: 'PM0044986',
      core_name: 'Plant',
      sppl_ean: '8720349425280',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354829',
      rtl_batch_array: '5354829',
      name_search: 'PHGSCHWE',
      name: "Phlox 'Grafin von Schwerin'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J7H6NYCZ',
      imageBatch: '6X1PS69D',
      statusContent: 'dot',
      hash: 'KZLYPEA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZLYPEA8'
    },
    {
      status: 210,
      sku: 'PM0077372',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254823',
      rtl_batch_array: '6254823',
      name_search: 'RUDFGOLD',
      name: "Rudbeckia fulgida 'Goldsturm'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWBYZAT4;RW4PBGXP;4382951C;4T7BH8T8;7YJ5DGT6;AV5XNPYH;WD72SLRX;P5SH553W;82JWC1YN;E7ELP1EL;VSZE85ZT;C5R22YYD;284BERXS;5V3TTLV8;G56E54EX;JA14SGC8;SEH17VVR;WGYGPZR7;V6XVHHAC;7X81JZHJ',
      statusContent: 'dot',
      hash: '66X3E1TK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66X3E1TK'
    },
    {
      status: 210,
      sku: 'PM0077373',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308793',
      rtl_batch_array: '6254880, 6308793',
      name_search: 'ARDIOICU',
      name: 'Aruncus dioicus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1252,
      chnn_stock_retail: 2198,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N43BAD8S;4NSCXXCJ;GGT35123;HVD1GX1T;WEWG9AX4;2GKJYJHX;D2R8D63S;P8H2L5B6;V16SSWK2;1D6EV6HT;P1KXKHTS',
      statusContent: 'dot',
      hash: 'P3NNDZLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3NNDZLS'
    },
    {
      status: 910,
      sku: 'PM0077374',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SCCPMIST',
      name: "Scabiosa columbaria 'Pink Mist'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        'N2PKNNLY;Y9VCJZSB;DAPC8EPD;G8L1XJP9;P8SCRJH3;9DCWCDVV;76X5ZJZR',
      statusContent: 'dot',
      hash: 'P5J4RKRT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P5J4RKRT'
    },
    {
      status: 210,
      sku: 'PM0060047',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370796',
      rtl_batch_array: '6174730, 6370796, 6307630',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2458,
      sppl_prcp: 5.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'GZWS9PC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZWS9PC2'
    },
    {
      status: 210,
      sku: 'PM0080993',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338395',
      rtl_batch_array: '6338395',
      name_search: 'PITIPATE',
      name: "Pittosporum tenuifolium 'Irene Paterson'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3045,
      chnn_stock_retail: 3045,
      sppl_order_minimum: 3,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PCNW95P;GVWRT6BK;RPVETT1N;KWVWP6Z4',
      statusContent: 'dot',
      hash: 'JRJCDRV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRJCDRV3'
    },
    {
      status: 210,
      sku: 'PM0044949',
      core_name: 'Garden',
      sppl_ean: '8720664812567',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4589763',
      rtl_batch_array: '4589763',
      name_search: 'WOWE3GR',
      name: 'Worth Verticale Tuin element 3vaks Groen',
      sppl_size_code: 'ST14PL3T',
      rng_range_identifier: 'SET42',
      rng_range_description: 'Set 42',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 65,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'TNSTX38T',
      statusContent: 'dot',
      hash: '4XY1NZPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '4XY1NZPB'
    },
    {
      status: 210,
      sku: 'PM0044951',
      core_name: 'Garden',
      sppl_ean: '8720664812536',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4589765',
      rtl_batch_array: '4589765',
      name_search: 'WOWE3GR',
      name: 'Worth Verticale Tuin element 3vaks Groen',
      sppl_size_code: 'ST3PL3T',
      rng_range_identifier: 'SET9',
      rng_range_description: 'Set 9',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 13.417,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '5KH7T84Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '5KH7T84Y'
    },
    {
      status: 210,
      sku: 'PM0044950',
      core_name: 'Garden',
      sppl_ean: '8720664812529',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4589764',
      rtl_batch_array: '4589764',
      name_search: 'WOWE3GR',
      name: 'Worth Verticale Tuin element 3vaks Groen',
      sppl_size_code: 'ST12PL3T',
      rng_range_identifier: 'SET36PCS',
      rng_range_description: 'Set 36 Pieces',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_prcp: 57.2,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'NTPA6S8X',
      statusContent: 'dot',
      hash: '91D927A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '91D927A5'
    },
    {
      status: 210,
      sku: 'PM0057956',
      core_name: 'Plant',
      sppl_ean: '8720664878273',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6136852',
      rtl_batch_array: '6136852',
      name_search: 'PHATROPU',
      name: "Phlox (S) 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRCPXTR',
      statusContent: 'dot',
      hash: 'L78299VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L78299VY'
    },
    {
      status: 810,
      sku: 'PM0068483',
      core_name: 'Plant',
      sppl_ean: '8720349488353',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4391498',
      rtl_batch_array: '4391498',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '51EN5A63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51EN5A63'
    },
    {
      status: 210,
      sku: 'PM0080994',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338396',
      rtl_batch_array: '6338396',
      name_search: 'PITILUCK',
      name: "Pittosporum t. 'Irish Luck'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1570,
      chnn_stock_retail: 1570,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YT5YVAKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT5YVAKJ'
    },
    {
      status: 910,
      sku: 'PM0054430',
      core_name: 'Plant',
      sppl_ean: '8720664871137',
      btch_manufacturer: 14143510,
      rtl_batch_array_total: 1,
      btch_active_retail: '6095265',
      rtl_batch_array: '6095265',
      name_search: 'LACTUCA',
      name: 'Lactuca mix',
      sppl_size_code: '6SP9T',
      rng_range_identifier: 'SET6POT',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_order_minimum: 1,
      sppl_prcp: 0.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ESNYYXRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESNYYXRH'
    },
    {
      status: 910,
      sku: 'PM0044959',
      core_name: 'Garden',
      sppl_ean: '8720664812574',
      btch_manufacturer: 14143433,
      rtl_batch_array_total: 1,
      btch_active_retail: '4603812',
      rtl_batch_array: '4603812',
      name_search: 'WOWE3BR',
      name: 'Worth Verticale Tuin element 3vaks Terra',
      sppl_size_code: 'DOOS12D',
      rng_range_identifier: 'BOX12PCS',
      rng_range_description: 'Box 12 Pieces',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 61,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'TX5CW6X7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'TX5CW6X7'
    },
    {
      status: 210,
      sku: 'PM0085055',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503633',
      rtl_batch_array: '5503633',
      name_search: 'LOCBPEAR',
      name: 'Loropetalum chinense Black Pearl',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 4.377,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '9EBWWAJH',
      statusContent: 'dot',
      hash: 'WL7R7YD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL7R7YD9'
    },
    {
      status: 210,
      sku: 'PM0045031',
      core_name: 'Plant',
      sppl_ean: '8720664803046',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5466985',
      rtl_batch_array: '5466985',
      name_search: 'PLGALBUS',
      name: "Platycodon grandiflorus 'Albus'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y1EZGCVG;BTK9VK4Y;HJH376YA;9T9J19YP;C4RK87YT;A8TVTSGS;PHYXAPGE',
      imageBatch: '5YK9HCE3',
      statusContent: 'dot',
      hash: 'K1846C3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1846C3J'
    },
    {
      status: 210,
      sku: 'PM0044993',
      core_name: 'Plant',
      sppl_ean: '8720349424962',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354872',
      rtl_batch_array: '5354872',
      name_search: 'PHDUESTE',
      name: "Phlox (P) 'D\u00fcsterlohe'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'Y6CC2WZS',
      statusContent: 'dot',
      hash: 'NZAC8AY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZAC8AY4'
    },
    {
      status: 210,
      sku: 'PM0080995',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338397',
      rtl_batch_array: '6338397',
      name_search: 'PITPOMPO',
      name: "Pittosporum tenuifolium 'Pompom'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1590,
      chnn_stock_retail: 1590,
      sppl_order_minimum: 3,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TLA44N6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLA44N6R'
    },
    {
      status: 210,
      sku: 'PM0068480',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284564',
      rtl_batch_array: '6284564',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '060080C4',
      rng_range_identifier: 'H060080C4',
      sppl_stock_available: 17279,
      chnn_stock_retail: 17279,
      sppl_prcp: 5.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: '4X82HJCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4X82HJCC'
    },
    {
      status: 210,
      sku: 'PM0043387',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 5,
      btch_active_retail: '6276351',
      rtl_batch_array: '6169439, 6276351, 6307631, 6276611, 6283847',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 2196,
      sppl_prcp: 8.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'S8Y1WLZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8Y1WLZZ'
    },
    {
      status: 210,
      sku: 'PM0068502',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272022',
      rtl_batch_array: '6272022',
      name_search: 'JUVGOWL',
      name: "Juniperus virginiana 'Grey Owl'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 542,
      chnn_stock_retail: 542,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8W9NGN24;YRX1E3HJ;K97SWD7A;6NZW1JK1;ZJ5BXD1C;133EVXWW;YNBP11B2;Y737EY2W;K7J69DA5;PLH824L1;8TLVBPA6;NRBR87HX;WN6T2ADL',
      statusContent: 'dot',
      hash: 'AH9D8J8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AH9D8J8E'
    },
    {
      status: 210,
      sku: 'PM0070806',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280163',
      rtl_batch_array: '6280163',
      name_search: 'CEDFBLUE',
      name: "Cedrus deodara 'Feelin' Blue'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_prcp: 7.446,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRP5DSG3;S12VJSSR',
      statusContent: 'dot',
      hash: '8DYHXZBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DYHXZBK'
    },
    {
      status: 210,
      sku: 'PM0068487',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349072',
      rtl_batch_array: '6349072, 6350064, 6217113',
      name_search: 'ECMYELLO',
      name: "Echinacea 'Mellow Yellows'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7466,
      chnn_stock_retail: 8657,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDXX2J5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDXX2J5D'
    },
    {
      status: 810,
      sku: 'PM0080996',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338398',
      rtl_batch_array: '6338398',
      name_search: 'PITSBALL',
      name: "Pittosporum tenuifolium 'Silver Ball'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 31,
      chnn_stock_retail: 31,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2N7VZAB;WKKRLHPD;YDRND7VE;XEARRG51;PTJKR3JD;1JNL47NK;53RYTGZP',
      statusContent: 'dot',
      hash: 'B1WY8DAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1WY8DAE'
    },
    {
      status: 210,
      sku: 'PM0077371',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252947',
      rtl_batch_array: '6252947',
      name_search: 'SAVHBLUE',
      name: "Salvia verticillata 'Hannay's Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26YCGNEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26YCGNEE'
    },
    {
      status: 210,
      sku: 'PM0077370',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252650',
      rtl_batch_array: '6252650',
      name_search: 'KNUGRAND',
      name: "Kniphofia uvaria 'Grandiflora'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1482,
      chnn_stock_retail: 1482,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N8JC1NW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8JC1NW7'
    },
    {
      status: 210,
      sku: 'PM0068479',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200403',
      rtl_batch_array: '6200403',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 5422,
      chnn_stock_retail: 5422,
      sppl_prcp: 4.506,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'XBY1R2Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBY1R2Z6'
    },
    {
      status: 810,
      sku: 'PM0077376',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222195',
      rtl_batch_array: '6222195',
      name_search: 'WEFLORID',
      name: 'Weigela florida',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S8A1SXR7',
      statusContent: 'dot',
      hash: 'VRJ55RXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRJ55RXZ'
    },
    {
      status: 210,
      sku: 'PM0070820',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307346',
      rtl_batch_array: '6307346',
      name_search: 'CLRCARDI',
      name: "Clematis 'Rouge Cardinal'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1289,
      chnn_stock_retail: 1289,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XDHVGW4P;2CKLB57S;AJ93LLV7;V13JH5A2;NS4PS41C;2NDZNNNN;K37S1CKS;AWX73LGR;254JWPWA',
      statusContent: 'dot',
      hash: '95TXXDL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95TXXDL2'
    },
    {
      status: 210,
      sku: 'PM0070817',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307342',
      rtl_batch_array: '6307342',
      name_search: 'CLPIILU',
      name: "Clematis 'Piilu'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JZY7BCXR;XKZWE98J;KVRZWAVV;5BGY4E1D;4J9K5SB7;5YRPX7AK;622DRS4Y;73W7J3D3;JSK4CG2L;NZCZKJCA;T88KLRBA;B1VG1DDS;5STSJS1E;PJBY3ZYN;DJ9S6RDV;BYJSWREG;CD3ESA41;97YB3TEN',
      statusContent: 'dot',
      hash: '4B5B113L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B5B113L'
    },
    {
      status: 910,
      sku: 'PM0070814',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLNMOSER',
      name: "Clematis 'Nelly Moser'",
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore:
        'A6CZ53WR;29H99JN3;ETSXW4Z2;2GNSNHJL;5TR7H29A;T989ZCX6;6V45XPLH;NHNCH7HJ;K2P5144Z;9HB8GARL',
      statusContent: 'dot',
      hash: '4XDB8YSG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4XDB8YSG'
    },
    {
      status: 210,
      sku: 'PM0068486',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264782',
      rtl_batch_array: '6264782',
      name_search: 'THPWHIPC',
      name: "Thuja plicata 'Whipcord'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 5914,
      chnn_stock_retail: 5914,
      sppl_prcp: 7.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PTZZ7EXJ;BHA97TVL;T6ADDNB5;CBSAJZHC;A6EHTCB8;8DBK8RE8;D6VNRE6D;AZKRBE9J;5ARLXDVH;83G54CZ8',
      statusContent: 'dot',
      hash: 'XYWDB255',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYWDB255'
    },
    {
      status: 210,
      sku: 'PM0080997',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338399',
      rtl_batch_array: '6338399',
      name_search: 'PITSMAGI',
      name: "Pittosporum tenuifolium 'Silver Magic'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1025,
      chnn_stock_retail: 1025,
      sppl_order_minimum: 3,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HDJHYEAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDJHYEAB'
    },
    {
      status: 210,
      sku: 'PM0067701',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185802',
      rtl_batch_array: '6185802',
      name_search: 'ASDUMOSU',
      name: 'Aster dumosus',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '29ZN1DCB;X4TAA9W3;EE7HSLVL;K4N4TDH9',
      statusContent: 'dot',
      hash: '7RJ3HRSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RJ3HRSP'
    },
    {
      status: 210,
      sku: 'PM0080998',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338400',
      rtl_batch_array: '6338400',
      name_search: 'PITSSHEE',
      name: "Pittosporum tenuifolium 'Silver Sheen'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1475,
      chnn_stock_retail: 1475,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2B1BR42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2B1BR42'
    },
    {
      status: 210,
      sku: 'PM0080999',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338401',
      rtl_batch_array: '6338401',
      name_search: 'PITTTHUM',
      name: "Pittosporum tenuifolium 'Tom Thumb'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2352,
      chnn_stock_retail: 2352,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2BDH197W;7T5CH87V;G7LCAJXG;4SH28THX;SS11E7NY;ZNPBXKW2;RKNH3XWL',
      statusContent: 'dot',
      hash: 'WY896127',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WY896127'
    },
    {
      status: 210,
      sku: 'PM0081000',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338402',
      rtl_batch_array: '6338402',
      name_search: 'PITVARIE',
      name: "Pittosporum tenuifolium 'Variegatum'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 3104,
      chnn_stock_retail: 3104,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G9H248DG;YA7TG416;1XB79289;HXHYDEX4;T6W99A54;21DN5SCL;GDTKTWXZ;5H1H6LBZ;LR8JAEAG;JEPJ94B2',
      statusContent: 'dot',
      hash: 'ZGS2PTBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGS2PTBX'
    },
    {
      status: 210,
      sku: 'PM0068491',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386165',
      rtl_batch_array: '6386165',
      name_search: 'PHSNOWFL',
      name: "Phlox (S) 'Snowflake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5VA2SRJS',
      statusContent: 'dot',
      hash: 'GRHSHEDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRHSHEDJ'
    },
    {
      status: 210,
      sku: 'PM0045185',
      core_name: 'Plant',
      sppl_ean: '8720664546080',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592157',
      rtl_batch_array: '5592157',
      name_search: 'HECJACK',
      name: "Hemerocallis 'Calico Jack'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'R8ECK98L',
      statusContent: 'dot',
      hash: 'TR63DNRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR63DNRX'
    },
    {
      status: 810,
      sku: 'PM0081001',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338403',
      rtl_batch_array: '6338403',
      name_search: 'PITVARIE',
      name: "Pittosporum tenuifolium 'Variegatum'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G9H248DG;YA7TG416;1XB79289;HXHYDEX4;T6W99A54;21DN5SCL;GDTKTWXZ;5H1H6LBZ;LR8JAEAG;JEPJ94B2',
      statusContent: 'dot',
      hash: 'SGEWJ2ND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGEWJ2ND'
    },
    {
      status: 210,
      sku: 'PM0081002',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338404',
      rtl_batch_array: '6338404',
      name_search: 'PITOBIRA',
      name: 'Pittosporum tobira',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 645,
      chnn_stock_retail: 645,
      sppl_order_minimum: 3,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RX46XZBE;N2VG2K6V;JLZ4LRDH;STV8GSZL;NXXBGE33;PNGWBP6D;AZE1N32Z;EBNW7JGD',
      statusContent: 'dot',
      hash: '9L8LJH31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L8LJH31'
    },
    {
      status: 210,
      sku: 'PM0081003',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338405',
      rtl_batch_array: '6338405',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1570,
      chnn_stock_retail: 1570,
      sppl_prcp: 1.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: '5K7RNHVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K7RNHVP'
    },
    {
      status: 210,
      sku: 'PM0045196',
      core_name: 'Plant',
      sppl_ean: '8720349405565',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592184',
      rtl_batch_array: '5592184',
      name_search: 'HOWBRIM',
      name: "Hosta 'Wide Brim'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W124CX91;D8NJKA3N;77X215VX;H1PESLVT;VRTKZ3VW;D7CN85W7;9YA6BWD4;2HK6C5AR;WSCTS22K;SVEK1872;XX9JLLWJ;ESSVBVHC;25LWTEKL;4V4D9XHW;SPE83D3Z',
      imageBatch: 'XR13K9A9',
      statusContent: 'dot',
      hash: 'E2DW516P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2DW516P'
    },
    {
      status: 210,
      sku: 'PM0044989',
      core_name: 'Plant',
      sppl_ean: '8720349425013',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354850',
      rtl_batch_array: '5354850',
      name_search: 'PHJADE',
      name: "Phlox (P) 'Jade'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'XG99CP3W',
      statusContent: 'dot',
      hash: 'PHKSV8H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHKSV8H8'
    },
    {
      status: 210,
      sku: 'PM0081004',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338406',
      rtl_batch_array: '6338406',
      name_search: 'POFBAPPL',
      name: 'Potentilla fruticosa Bella Apple',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1023,
      chnn_stock_retail: 1023,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4DKHR2Y7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DKHR2Y7'
    },
    {
      status: 210,
      sku: 'PM0070809',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221750',
      rtl_batch_array: '6221750',
      name_search: 'CRJCRIST',
      name: "Cryptomeria japonica 'Cristata'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1024,
      chnn_stock_retail: 1024,
      sppl_prcp: 7.446,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JGA1LNGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGA1LNGH'
    },
    {
      status: 210,
      sku: 'PM0054416',
      core_name: 'Plant',
      sppl_ean: '8720664863736',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034260',
      rtl_batch_array: '6034260',
      name_search: 'FRARRUBY',
      name: 'Fragaria ananassa Red Ruby',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WBJH85R6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBJH85R6'
    },
    {
      status: 210,
      sku: 'PM0081005',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338407',
      rtl_batch_array: '6338407',
      name_search: 'POFBBIAN',
      name: 'Potentilla fruticosa Bella Bianca',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1388,
      chnn_stock_retail: 1388,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JX1VV823',
      statusContent: 'dot',
      hash: '7D18DWG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D18DWG1'
    },
    {
      status: 210,
      sku: 'PM0081006',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338408',
      rtl_batch_array: '6338408',
      name_search: 'POFBLIND',
      name: 'Potentilla fruticosa Bella Lindsey',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1432,
      chnn_stock_retail: 1432,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7N5RL144',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N5RL144'
    },
    {
      status: 210,
      sku: 'PM0081007',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338409',
      rtl_batch_array: '6338409',
      name_search: 'POFBROSA',
      name: 'Potentilla f. Bella Rosa',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8YW2YC85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YW2YC85'
    },
    {
      status: 210,
      sku: 'PM0081008',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338410',
      rtl_batch_array: '6338410',
      name_search: 'POFBSOL',
      name: 'Potentilla fruticosa Bella Sol',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1198,
      chnn_stock_retail: 1198,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WADGBPC4;VTZ1CYAX;YDJGPTW4;DWRL1YNG;RJCZZ681',
      statusContent: 'dot',
      hash: 'L34TJ8HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L34TJ8HZ'
    },
    {
      status: 910,
      sku: 'PM0074207',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'CJL4LGDT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CJL4LGDT'
    },
    {
      status: 210,
      sku: 'PM0068501',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204082',
      rtl_batch_array: '6204082',
      name_search: 'JUCSAXAT',
      name: 'Juniperus communis saxatilis',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 829,
      chnn_stock_retail: 829,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AY636ARL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AY636ARL'
    },
    {
      status: 810,
      sku: 'PM0068489',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217169',
      rtl_batch_array: '6217169',
      name_search: 'HEBCOLOU',
      name: "Heuchera 'Beauty Colour'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8E8YBYT5;GACRX57W',
      statusContent: 'dot',
      hash: 'B3526JWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3526JWN'
    },
    {
      status: 210,
      sku: 'PM0081009',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338411',
      rtl_batch_array: '6338411',
      name_search: 'POFBELLI',
      name: 'Potentilla fruticosa Bellissima',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2081,
      chnn_stock_retail: 2081,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R967RN6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R967RN6V'
    },
    {
      status: 210,
      sku: 'PM0054410',
      core_name: 'Plant',
      sppl_ean: '8720349463046',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420753',
      rtl_batch_array: '5420753',
      name_search: 'CLCOLUME',
      name: "Clematis 'Columella'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 920,
      chnn_stock_retail: 920,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NNX8G6AX;WW1A8HS1;BXV3D7RY',
      statusContent: 'dot',
      hash: 'VRBYRNDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRBYRNDC'
    },
    {
      status: 210,
      sku: 'PM0068499',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6202872',
      rtl_batch_array: '6202872',
      name_search: 'TECALBUM',
      name: "Teucrium chamaedrys 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z4PC5NB7;7L7GJX2Z;VX57G4V9;BSN48HRL;51HCJ7VC;LVXEPHG4',
      statusContent: 'dot',
      hash: 'AR62Z1SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR62Z1SE'
    },
    {
      status: 210,
      sku: 'PM0081010',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338412',
      rtl_batch_array: '6338412',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2308,
      chnn_stock_retail: 2308,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: 'A8T5PERS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8T5PERS'
    },
    {
      status: 210,
      sku: 'PM0070819',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307345',
      rtl_batch_array: '6307345',
      name_search: 'CLPROPER',
      name: "Clematis 'Propertius'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJTBNX4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJTBNX4S'
    },
    {
      status: 910,
      sku: 'PM0081011',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFRJOKE',
      name: "Potentilla fruticosa 'Red Joker'",
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore:
        '6B18TBA9;A5TAA819;838E3KJZ;EXN7GJ7E;SHDSL4LW;LJCNZ43E;YDXSA6L2;E29GH3N8;43LXLHNS',
      statusContent: 'dot',
      hash: 'CD1WH8G8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CD1WH8G8'
    },
    {
      status: 210,
      sku: 'PM0081012',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338414',
      rtl_batch_array: '6338414',
      name_search: 'POTNUUK',
      name: "Potentilla tridentata 'Nuuk'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1260,
      chnn_stock_retail: 1260,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5VGJ8ZB;K4K9K2E3',
      statusContent: 'dot',
      hash: '6ACT7XRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ACT7XRT'
    },
    {
      status: 910,
      sku: 'PM0070822',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLSCTHUI',
      name: "Clematis 'Souvenir du Capitaine Thuilleaux'",
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      statusContent: 'dot',
      hash: '4HBZ5B8T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4HBZ5B8T'
    },
    {
      status: 210,
      sku: 'PM0081013',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338415',
      rtl_batch_array: '6338415',
      name_search: 'PRCUNEAT',
      name: 'Prostanthera cuneata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_order_minimum: 5,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DN1JDC8L;TWR2AGL2;LEYYX3DL;CB41Z9N2;94ED8D3P;99TVW9BA;JC8W3LLB;G32B49NJ;NCXKV3NZ;5ZHVSY42',
      statusContent: 'dot',
      hash: 'X6C7LZXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6C7LZXE'
    },
    {
      status: 810,
      sku: 'PM0070824',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307350',
      rtl_batch_array: '6307350',
      name_search: 'CLTTANGU',
      name: 'Clematis tibetana tangutica',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V78AZ85R;C43CDZND;9D5CNZTY;YG4155K1;B2H6WENR',
      statusContent: 'dot',
      hash: 'YALSD6WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YALSD6WH'
    },
    {
      status: 210,
      sku: 'PM0081014',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338416',
      rtl_batch_array: '6338416',
      name_search: 'PRLMICE',
      name: "Prostanthera lasianthos 'Mint-ice'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N13TRETA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N13TRETA'
    },
    {
      status: 210,
      sku: 'PM0070829',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307356',
      rtl_batch_array: '6307356',
      name_search: 'CLWNIKE',
      name: "Clematis 'Warszawska Nike'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 814,
      chnn_stock_retail: 814,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L5EH2EX1;YLZZCW2Y;P8G3H1SZ;N4RRR2G3;53RBE44D;2H7B571C;N1LDWVJ6',
      statusContent: 'dot',
      hash: 'AAWP6TEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAWP6TEX'
    },
    {
      status: 210,
      sku: 'PM0081015',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338417',
      rtl_batch_array: '6338417',
      name_search: 'PRPBALLE',
      name: "Prostanthera 'Poorinda Ballerina'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 145,
      chnn_stock_retail: 145,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '81Y2RVRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81Y2RVRL'
    },
    {
      status: 210,
      sku: 'PM0070830',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307398',
      rtl_batch_array: '6307398',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 418,
      chnn_stock_retail: 418,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'YDKWBR7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDKWBR7G'
    },
    {
      status: 210,
      sku: 'PM0070831',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307403',
      rtl_batch_array: '6307403',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 18.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'ZD6RCTHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD6RCTHJ'
    },
    {
      status: 210,
      sku: 'PM0070812',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307337',
      rtl_batch_array: '6307337',
      name_search: 'CLMGJACK',
      name: "Clematis 'Mrs George Jackman'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 799,
      chnn_stock_retail: 799,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TS2YWHY3;LRP9D7TV;C5Z3Z8YN;4N47YAZ3;1BCRWKX1',
      statusContent: 'dot',
      hash: 'X9WWBW69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9WWBW69'
    },
    {
      status: 210,
      sku: 'PM0070813',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307338',
      rtl_batch_array: '6307338',
      name_search: 'CLMNTHOM',
      name: "Clematis 'Mrs N. Thompson'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GT4B646A;KRTPKWA3;B3TGHKSV',
      statusContent: 'dot',
      hash: '9LX6CXYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LX6CXYB'
    },
    {
      status: 210,
      sku: 'PM0070815',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307341',
      rtl_batch_array: '6307341',
      name_search: 'CLNIOBE',
      name: "Clematis 'Niobe'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TY4WEE7E;B9HZLG9A;PA64YX54;4HPXHYDG;AS4DCDBJ;CRA5AEN6;73KWREH6;AW2ANCTL;ZADZJXPH;APYB72E2',
      statusContent: 'dot',
      hash: 'BA19W2K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA19W2K8'
    },
    {
      status: 210,
      sku: 'PM0070818',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307344',
      rtl_batch_array: '6307344',
      name_search: 'CLPSPIRI',
      name: "Clematis 'Polish Spirit'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 222,
      chnn_stock_retail: 222,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W5AP2RLT',
      statusContent: 'dot',
      hash: 'W5D1BYR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5D1BYR3'
    },
    {
      status: 210,
      sku: 'PM0070823',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307349',
      rtl_batch_array: '6307349',
      name_search: 'CLSINDIA',
      name: "Clematis 'Star of India'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VRY1KR7T;PD2L7XXS;HETWTN7G;Y4LW7KES;CS847KD9;SHZVRBEA;H358T23T;7Y3W9T3P;K41G9R63;LRY87G58;7DH1ARPZ;8WZD6BT9;XYP8GNG3;2EEW53E8',
      statusContent: 'dot',
      hash: 'T4LGK1VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4LGK1VX'
    },
    {
      status: 210,
      sku: 'PM0070825',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307352',
      rtl_batch_array: '6307352',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: 'BGYKXSPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGYKXSPV'
    },
    {
      status: 210,
      sku: 'PM0070826',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307353',
      rtl_batch_array: '6307353',
      name_search: 'CLPRESID',
      name: "Clematis 'The President'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 672,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YR3KEPKC;4JVA8PTW;R3GG1153;4D58PSSR;4LR125VB;96GRL96A;5XKKJ857;6B69S44Y;VXEB2J1D;WEEJAYEN;4JEEN1VE;TLG6L6L4;EZ26GL1V;P2TL8ACB;13XTST2S;LDD4D9SJ;X5293165;ZHJGC1ZY;L1K6EWTV;D4BTVTX3;H1L1DV6P;W9VL8A9C;EBHDJJP8;CELXZ74C;2PKE94RY;Z88GR8L9;XPZ7XHHC;CGZYVWZP',
      statusContent: 'dot',
      hash: '53SW1SS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53SW1SS2'
    },
    {
      status: 210,
      sku: 'PM0070827',
      core_name: 'Plant',
      sppl_ean: '8720664808256',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5741779',
      rtl_batch_array: '5741779',
      name_search: 'SECARL',
      name: "Sedum 'Carl'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3343,
      chnn_stock_retail: 3343,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1AAZNB;6CL18H27;4J9PWWYY;DJTJLNYJ;8G15Z36J;KTA6KJ11',
      imageBatch: '4389KNEP',
      statusContent: 'dot',
      hash: 'W4NEB25Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4NEB25Y'
    },
    {
      status: 210,
      sku: 'PM0081016',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338418',
      rtl_batch_array: '6338418',
      name_search: 'PRROTUND',
      name: 'Prostanthera rotundifolia',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJKTGLZL;6T5J3W61;9VLGWCYP',
      statusContent: 'dot',
      hash: 'XHCD77PN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHCD77PN'
    },
    {
      status: 210,
      sku: 'PM0070828',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307354',
      rtl_batch_array: '6307354',
      name_search: 'CLVLYON',
      name: "Clematis 'Ville de Lyon'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1095,
      chnn_stock_retail: 1095,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWNV29KS;918ABGA1;GLHCXVK1;7LA48278;TRRL5B7P;W6RL2AW3;CH5BCNKG;XPN2391D;RB1BNNJE;WL9EX33H;XJH26RHD;YVY3RYKE;9S1N3Z1A;H1LS6LCL;J27E55DS;SDGAY5CZ;L795T6NB;A3GK9SW4;7XTC9EZT;V5EBLPAB;NXDGGTKJ;ZXJR3DPH;T3C5VKAC;AZZE81NA',
      statusContent: 'dot',
      hash: 'KTR2J9A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTR2J9A4'
    },
    {
      status: 810,
      sku: 'PM0081017',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338419',
      rtl_batch_array: '6338419',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: '7LX9DSN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LX9DSN5'
    },
    {
      status: 210,
      sku: 'PM0057950',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6217037',
      rtl_batch_array: '6084631, 6353128, 6217037',
      name_search: 'PEABSTEE',
      name: "Perovskia atriplicifolia 'Blue Steel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 2978,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DSYZ9VDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSYZ9VDW'
    },
    {
      status: 210,
      sku: 'PM0085056',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6296688',
      rtl_batch_array: '6296688',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '040045C12BO',
      rng_range_identifier: 'BALL\u00d8045H040045C12',
      rng_range_description: 'Ball \u00d8 45 cm H40 cm 45 cm C12',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 18.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'DKA5K3SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKA5K3SY'
    },
    {
      status: 210,
      sku: 'PM0081018',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338420',
      rtl_batch_array: '6338420',
      name_search: 'PRGALBA',
      name: "Prunella grandiflora 'Alba'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9VT4CLLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VT4CLLS'
    },
    {
      status: 210,
      sku: 'PM0081019',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338421',
      rtl_batch_array: '6338421',
      name_search: 'PRGRPLEN',
      name: "Prunus glandulosa 'Rosea Plena'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCVVJV8X',
      statusContent: 'dot',
      hash: 'PN97RHE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PN97RHE2'
    },
    {
      status: 210,
      sku: 'PM0068503',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204085',
      rtl_batch_array: '6204085',
      name_search: 'PIGECHIN',
      name: "Picea glauca 'Echiniformis'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 273,
      chnn_stock_retail: 273,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJC562T8;YBDXH7SH',
      statusContent: 'dot',
      hash: 'Z1YJBWXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1YJBWXB'
    },
    {
      status: 210,
      sku: 'PM0045210',
      core_name: 'Plant',
      sppl_ean: '8720349418336',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592222',
      rtl_batch_array: '5592222',
      name_search: 'HOREVOLU',
      name: "Hosta 'Revolution'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '186L436X',
      imageBatch: 'D1P76P2N',
      statusContent: 'dot',
      hash: '7Z4KNRLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z4KNRLL'
    },
    {
      status: 210,
      sku: 'PM0020453',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345214',
      rtl_batch_array: '5364216, 6345214',
      name_search: 'VEOBAMPT',
      name: "Verbena officinalis 'Bampton'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 1725,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ASZYSN93;T36DBR8W;V56BGA28;DA45BC32;REPNNX7R',
      statusContent: 'dot',
      hash: '5JD4ZKHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JD4ZKHE'
    },
    {
      status: 210,
      sku: 'PM0045032',
      core_name: 'Plant',
      sppl_ean: '8720349425990',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '5467844',
      rtl_batch_array: '5467844, 6117705',
      name_search: 'PLGRANDI',
      name: 'Platycodon grandiflorus',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4R7ZSSJ;NTLYD82Z;PTD1YLH4;PGSXERHG',
      imageBatch: '8J3LLV5V',
      statusContent: 'dot',
      hash: 'C2283615',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2283615'
    },
    {
      status: 210,
      sku: 'PM0020102',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 3,
      btch_active_retail: '6083721',
      rtl_batch_array: '6345274, 2788563, 6083721',
      name_search: 'HAMAUREO',
      name: "Hakonechloa macra 'Aureola'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3940,
      chnn_stock_retail: 5908,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LGPSH6BS;HAWZ7TB3;Y2VX4LTC;69ALZ6C1;61XAZ3JS;7V1LCS31;LD6HALZH;7XDBZ8J9;1WXLHHXT;YX55G1N1;A1P59SYS;ZS3J9J5L;KSWTBCH3;8LWBDWX8;9AX5RWL5;YTD95PRE;8N73A5TY;2DNH4NX9;7DGLA1NW;N65D24CL;DPGAS8SL;GJC55GAR;S1GN9PG1;5V7J2AGS',
      statusContent: 'dot',
      hash: 'VHTYSJ3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHTYSJ3Y'
    },
    {
      status: 210,
      sku: 'PM0045104',
      core_name: 'Plant',
      sppl_ean: '8720664688452',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591959',
      rtl_batch_array: '5591959',
      name_search: 'ECPPWHIT',
      name: 'Echinacea purpurea Powwow White',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SE947XBZ',
      imageBatch: 'CH9YDKC5',
      statusContent: 'dot',
      hash: 'B11W59BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B11W59BR'
    },
    {
      status: 210,
      sku: 'PM0045356',
      core_name: 'Plant',
      sppl_ean: '8720664680111',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054347',
      rtl_batch_array: '6054347',
      name_search: 'ALPRAIN',
      name: "Allium 'Purple Rain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 747,
      chnn_stock_retail: 747,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '494H2WJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '494H2WJ3'
    },
    {
      status: 210,
      sku: 'PM0045078',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375771',
      rtl_batch_array: '6375771',
      name_search: 'LEBCREAM',
      name: "Leucanthemum (S) 'Banana Cream'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GABB7N8T',
      statusContent: 'dot',
      hash: '7GVZ4WPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GVZ4WPJ'
    },
    {
      status: 210,
      sku: 'PM0045113',
      core_name: 'Plant',
      sppl_ean: '8720664546486',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591974',
      rtl_batch_array: '5591974',
      name_search: 'HEVVANIL',
      name: "Hemerocallis 'Villa Vanilla'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ADP7XZYT',
      statusContent: 'dot',
      hash: 'AHXY2S35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHXY2S35'
    },
    {
      status: 210,
      sku: 'PM0045171',
      core_name: 'Plant',
      sppl_ean: '8720349410217',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592128',
      rtl_batch_array: '5592128',
      name_search: 'ASVINFER',
      name: "Astilbe 'Vision Inferno'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'J1LAS8BZ',
      statusContent: 'dot',
      hash: 'LVTCEGRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVTCEGRN'
    },
    {
      status: 210,
      sku: 'PM0045159',
      core_name: 'Plant',
      sppl_ean: '8720349417476',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592099',
      rtl_batch_array: '5592099',
      name_search: 'HEDRWYE',
      name: "Hemerocallis 'Double River Wye'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S2DEBYVC;WCSE4G4B;9LZX2B8E;ZLZNL9AY;GTJS88Y3;5AXAKYDY;KWT59D34',
      imageBatch: 'X3JNJSKA',
      statusContent: 'dot',
      hash: 'B25LCR3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B25LCR3K'
    },
    {
      status: 210,
      sku: 'PM0077383',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382505',
      rtl_batch_array: '6382505',
      name_search: 'DEHPINK',
      name: "Delosperma 'Hot Pink'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2891,
      chnn_stock_retail: 2891,
      sppl_order_minimum: 3,
      sppl_prcp: 1.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBHGS8NG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBHGS8NG'
    },
    {
      status: 910,
      sku: 'PM0077576',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: '5R31WZV2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5R31WZV2'
    },
    {
      status: 210,
      sku: 'PM0063435',
      core_name: 'Plant',
      sppl_ean: '8720353096445',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5263150',
      rtl_batch_array: '5263150',
      name_search: 'DECGOLDT',
      name: "Deschampsia cespitosa 'Goldtau'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 345,
      chnn_stock_retail: 345,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7ZNEJDA;S4JYADT8',
      imageBatch: 'DJ47N6G5',
      statusContent: 'dot',
      hash: 'HHBSCD7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHBSCD7S'
    },
    {
      status: 910,
      sku: 'PM0067704',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHTLICH',
      name: "Choisya ternata 'Lich'",
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      imageCore: '8CVZ3GKT;KKH3BXHZ',
      statusContent: 'dot',
      hash: 'W9S78YEN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W9S78YEN'
    },
    {
      status: 210,
      sku: 'PM0077386',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304441',
      rtl_batch_array: '6304441',
      name_search: 'ULEFPLEN',
      name: "Ulex europaeus 'Flore Pleno'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NNPPG82P;L5GTRHK7;XW6WTG63;TSSACY64',
      statusContent: 'dot',
      hash: 'RXHLLE82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXHLLE82'
    },
    {
      status: 210,
      sku: 'PM0077387',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304728',
      rtl_batch_array: '6304728',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '060STC74',
      rng_range_identifier: 'STE060C7.5',
      rng_range_description: 'Stem 60 cm C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 19.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'RG75A5H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG75A5H1'
    },
    {
      status: 910,
      sku: 'PM0057964',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHSCHEUC',
      name: 'Phyteuma scheuchzeri',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'LYW3VKAD;JKJ18T4T',
      statusContent: 'dot',
      hash: '6CAPJRJB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6CAPJRJB'
    },
    {
      status: 210,
      sku: 'PM0077388',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304729',
      rtl_batch_array: '6304729',
      name_search: 'HYPPDIAM',
      name: "Hydrangea paniculata 'Pink Diamond'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 3.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WWBLZD3E;7CCBP3NH;68STTESS',
      statusContent: 'dot',
      hash: 'N1RL27RR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1RL27RR'
    },
    {
      status: 210,
      sku: 'PM0045111',
      core_name: 'Plant',
      sppl_ean: '8720349417742',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591972',
      rtl_batch_array: '5591972',
      name_search: 'HESSCAND',
      name: "Hemerocallis 'Sweet Sugar Candy'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '8AC9ZL51',
      statusContent: 'dot',
      hash: '1L4AGB1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L4AGB1C'
    },
    {
      status: 210,
      sku: 'PM0077579',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304958',
      rtl_batch_array: '6304958',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_prcp: 1.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: '2P4ACCC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2P4ACCC7'
    },
    {
      status: 210,
      sku: 'PM0077389',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304730',
      rtl_batch_array: '6304730',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 995,
      chnn_stock_retail: 995,
      sppl_prcp: 7.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: '2JXSDYRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JXSDYRH'
    },
    {
      status: 810,
      sku: 'PM0081020',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338422',
      rtl_batch_array: '6338422',
      name_search: 'PRIMIKIN',
      name: "Prunus incisa 'Mikinori'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4T4KXDZ6',
      statusContent: 'dot',
      hash: '2DN9S8WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DN9S8WC'
    },
    {
      status: 210,
      sku: 'PM0045099',
      core_name: 'Plant',
      sppl_ean: '8720664852952',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6137760',
      rtl_batch_array: '6137760, 6375779',
      name_search: 'ASFANAL',
      name: "Astilbe (A) 'Fanal'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1248,
      chnn_stock_retail: 2248,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C7S2Y1R;2ZA6BV4X;NKJPNGXX;GVT9WCBE;4LZB2BZB',
      statusContent: 'dot',
      hash: 'K8X5DBJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8X5DBJ2'
    },
    {
      status: 210,
      sku: 'PM0077390',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304731',
      rtl_batch_array: '6304731',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '060STC74',
      rng_range_identifier: 'STE060C7.5',
      rng_range_description: 'Stem 60 cm C7.5',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 19.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: '8LJAN26E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LJAN26E'
    },
    {
      status: 210,
      sku: 'PM0045133',
      core_name: 'Plant',
      sppl_ean: '8720664681279',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592022',
      rtl_batch_array: '5592022',
      name_search: 'ASNBCBRO',
      name: "Aster novi-belgii 'Crimson Brocade'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'S3A551P7',
      statusContent: 'dot',
      hash: 'H7ZWXA8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7ZWXA8G'
    },
    {
      status: 910,
      sku: 'PM0067707',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMCHARI',
      name: "Mahonia media 'Charity'",
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore: '19P3CCES',
      statusContent: 'dot',
      hash: 'C5DVDYG8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C5DVDYG8'
    },
    {
      status: 210,
      sku: 'PM0068898',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5231050',
      rtl_batch_array: '5231050',
      name_search: 'EUJGOLDM',
      name: 'Euonymus japonicus Goldmine',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 489,
      chnn_stock_retail: 489,
      sppl_prcp: 7.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WE55TSTK',
      imageBatch: '2GH668PV',
      statusContent: 'dot',
      hash: 'NN216W1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN216W1T'
    },
    {
      status: 210,
      sku: 'PM0045105',
      core_name: 'Plant',
      sppl_ean: '8720664688476',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591960',
      rtl_batch_array: '5591960',
      name_search: 'ECPPWBER',
      name: 'Echinacea purpurea Powwow Wild Berry',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X33VL8JL;WYNH1H77;XER6JWCE;52BALRDB;RCNVALD7;AZH3VCLA;XBRK6TSV;8D3JRN2W;NBY732ZS;TCV61R4W;PY43TD7L',
      imageBatch: 'VYDA58Z4',
      statusContent: 'dot',
      hash: 'VJBYYG4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJBYYG4T'
    },
    {
      status: 210,
      sku: 'PM0077391',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304732',
      rtl_batch_array: '6304732',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 364,
      chnn_stock_retail: 364,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: '888GX3K4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '888GX3K4'
    },
    {
      status: 210,
      sku: 'PM0081021',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338423',
      rtl_batch_array: '6338423',
      name_search: 'PRLMANO',
      name: "Prunus laurocerasus 'Mano'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CBV58A24',
      statusContent: 'dot',
      hash: 'GGBZWY15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGBZWY15'
    },
    {
      status: 210,
      sku: 'PM0081022',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338424',
      rtl_batch_array: '6338424',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '060070C3',
      rng_range_identifier: 'H060070C3',
      rng_range_description: 'H60 cm 70 cm C3',
      sppl_stock_available: 301,
      chnn_stock_retail: 301,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'P8PH3GCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8PH3GCA'
    },
    {
      status: 210,
      sku: 'PM0045149',
      core_name: 'Plant',
      sppl_ean: '8720349405428',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592067',
      rtl_batch_array: '5592067',
      name_search: 'HOFMARGA',
      name: "Hosta 'Frozen Margarita'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'XAPGZ2DR',
      statusContent: 'dot',
      hash: 'BPT18L43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPT18L43'
    },
    {
      status: 210,
      sku: 'PM0081023',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338426',
      rtl_batch_array: '6338426',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1017,
      chnn_stock_retail: 1017,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'CBP1HS8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBP1HS8V'
    },
    {
      status: 210,
      sku: 'PM0081024',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338427',
      rtl_batch_array: '6338427',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2460,
      chnn_stock_retail: 2460,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'ST61G6T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST61G6T7'
    },
    {
      status: 210,
      sku: 'PM0081025',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338428',
      rtl_batch_array: '6338428',
      name_search: 'PRLGTEPP',
      name: "Prunus laurocerasus 'Gr\u00fcner Teppich'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1460,
      chnn_stock_retail: 1460,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRSBL2C2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRSBL2C2'
    },
    {
      status: 210,
      sku: 'PM0067720',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244384',
      rtl_batch_array: '6244384',
      name_search: 'TABDAVID',
      name: "Taxus baccata 'David'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JGV8D2V;61VZV35Y;K2SB5P8G',
      statusContent: 'dot',
      hash: 'XX7X1WAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX7X1WAG'
    },
    {
      status: 910,
      sku: 'PM0060074',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PIPGGLOB',
      name: "Picea pungens 'Glauca Globosa'",
      rng_range_identifier: 'H060070C35',
      rng_range_description: 'H60 cm 70 cm C35',
      imageCore:
        'ZAPZ2Y19;RT7R225Y;8RCT13BE;52EEBAJA;E4DGYS6J;V1WH2GHE;JBX7K4ED;4Z9G6PDC;X9X2P3DC;6RGP7D1N;REYXKK9E;71L73LNC;XZPEVCZG',
      statusContent: 'dot',
      hash: 'GCP1Z3KC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GCP1Z3KC'
    },
    {
      status: 210,
      sku: 'PM0081026',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338429',
      rtl_batch_array: '6338429',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1970,
      chnn_stock_retail: 1970,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'AZHYT57D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZHYT57D'
    },
    {
      status: 210,
      sku: 'PM0045086',
      core_name: 'Plant',
      sppl_ean: '8720664593701',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5581395',
      rtl_batch_array: '5581395',
      name_search: 'PHBBOY',
      name: "Phlox (P) 'Blue Boy'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RX5B45NV;GZDEBBKX',
      imageBatch: '9HP3R2W3',
      statusContent: 'dot',
      hash: 'AGDNJJD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGDNJJD3'
    },
    {
      status: 910,
      sku: 'PM0060077',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307741',
      rtl_batch_array: '6307741',
      name_search: 'PIHCGEM',
      name: "Pinus heldreichii 'Compact Gem'",
      sppl_size_code: '040050C12',
      rng_range_identifier: 'H040050C12',
      rng_range_description: 'H40 cm 50 cm C12',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 27.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWARNB6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWARNB6A'
    },
    {
      status: 210,
      sku: 'PM0077382',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6303422',
      rtl_batch_array: '6303422',
      name_search: 'ACSWPEAR',
      name: "Actaea simplex 'White Pearl'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1615,
      chnn_stock_retail: 1615,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BV6BHR4A;JPB3NWGK',
      statusContent: 'dot',
      hash: 'K3KWTSTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3KWTSTY'
    },
    {
      status: 210,
      sku: 'PM0081027',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338430',
      rtl_batch_array: '6338430',
      name_search: 'PRLMWHIT',
      name: "Prunus laurocerasus 'Marbled White'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9C42VK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9C42VK9'
    },
    {
      status: 210,
      sku: 'PM0066255',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276618',
      rtl_batch_array: '6276618, 6338973',
      name_search: 'COTMICRO',
      name: 'Cotoneaster microphyllus',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1550,
      chnn_stock_retail: 3650,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2GBGDZD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GBGDZD1'
    },
    {
      status: 210,
      sku: 'PM0081028',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338431',
      rtl_batch_array: '6338431',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2508,
      chnn_stock_retail: 2508,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'VR2XL89T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VR2XL89T'
    },
    {
      status: 210,
      sku: 'PM0085057',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6296689',
      rtl_batch_array: '6296689',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '050C15BOL',
      rng_range_identifier: 'BALL\u00d8050C15',
      rng_range_description: 'Ball \u00d8 50 cm C15',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 30.511,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '555JPBHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '555JPBHC'
    },
    {
      status: 210,
      sku: 'PM0081029',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338433',
      rtl_batch_array: '6338433',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 796,
      chnn_stock_retail: 796,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'RDS2JDSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDS2JDSX'
    },
    {
      status: 210,
      sku: 'PM0063438',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228367',
      rtl_batch_array: '6228367',
      name_search: 'CHPBBLUE',
      name: "Chamaecyparis pisifera 'Baby Blue'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 2.456,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R6YKKLGE;J58NSP5J;BNLZ2YK3;TA433EW5;BDHVHWX8;LZLAWW74;WEKKWWEW',
      statusContent: 'dot',
      hash: 'VWSWNDXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWSWNDXZ'
    },
    {
      status: 210,
      sku: 'PM0081030',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338434',
      rtl_batch_array: '6338434',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 5211,
      chnn_stock_retail: 5211,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'LZS6DWTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZS6DWTS'
    },
    {
      status: 210,
      sku: 'PM0085058',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254709',
      rtl_batch_array: '6254709',
      name_search: 'DRFMAS',
      name: 'Dryopteris filix-mas',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4464,
      chnn_stock_retail: 4464,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P8PA1NH9',
      statusContent: 'dot',
      hash: 'SKA7Y6B9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKA7Y6B9'
    },
    {
      status: 210,
      sku: 'PM0081031',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6376243',
      rtl_batch_array: '6376243, 6338436',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 996,
      sppl_prcp: 2.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P4J6BGED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4J6BGED'
    },
    {
      status: 910,
      sku: 'PM0067705',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EXBRIDE',
      name: "Exochorda 'The Bride'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore:
        'V8PN9B5S;TCEC1LGX;H5DCBSA8;NJB8R34P;45T8J833;S9K5D3BJ;JD4LAR76',
      statusContent: 'dot',
      hash: 'H8KRR5TL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H8KRR5TL'
    },
    {
      status: 210,
      sku: 'PM0045158',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229118',
      rtl_batch_array: '5592098, 6229118',
      name_search: 'HEOON',
      name: "Hemerocallis 'On and On'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 637,
      chnn_stock_retail: 1637,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LWWPPVP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWWPPVP1'
    },
    {
      status: 210,
      sku: 'PM0081032',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338437',
      rtl_batch_array: '6338437',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_prcp: 3.279,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: 'LHXZ1P5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHXZ1P5L'
    },
    {
      status: 210,
      sku: 'PM0067698',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185799',
      rtl_batch_array: '6185799',
      name_search: 'HOFRANCE',
      name: "Hosta 'Francee'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6L39X4TT;Z754WEC7;Y2T6XDTY;BZBYGL5S;PL98W7D4;HKKD7H8P;2LHRSZVD;XXJ99PE5;TVE71W4V;975DX8X9;PLN4TXN8',
      statusContent: 'dot',
      hash: 'NNZWKD4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NNZWKD4G'
    },
    {
      status: 210,
      sku: 'PM0045148',
      core_name: 'Plant',
      sppl_ean: '8720349418114',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592064',
      rtl_batch_array: '5592064',
      name_search: 'HOADGOUR',
      name: "Hosta 'Abiqua Drinking Gourd'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3XVBPDS',
      imageBatch: '11Z4KXX7',
      statusContent: 'dot',
      hash: 'YZ7LVD1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ7LVD1G'
    },
    {
      status: 210,
      sku: 'PM0045124',
      core_name: 'Plant',
      sppl_ean: '8720349405473',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592006',
      rtl_batch_array: '5592006',
      name_search: 'HOLGUINE',
      name: "Hosta 'Lady Guineverre'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HL2GLX8Y',
      imageBatch: 'G2RC2NLR',
      statusContent: 'dot',
      hash: 'XGHLP6R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGHLP6R5'
    },
    {
      status: 210,
      sku: 'PM0077571',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302401',
      rtl_batch_array: '6302401',
      name_search: 'ASETNA',
      name: "Astilbe (A) 'Etna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C8SXZ7W;7D3YNRGV',
      statusContent: 'dot',
      hash: '6Z76VSRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z76VSRW'
    },
    {
      status: 910,
      sku: 'PM0060078',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307742',
      rtl_batch_array: '6307742',
      name_search: 'PIHCGEM',
      name: "Pinus heldreichii 'Compact Gem'",
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 32.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XEN4GKWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEN4GKWJ'
    },
    {
      status: 210,
      sku: 'PM0045150',
      core_name: 'Plant',
      sppl_ean: '8720349405374',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592071',
      rtl_batch_array: '5592071',
      name_search: 'HODDAWN',
      name: "Hosta 'Delta Dawn'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ZJVB6KHS',
      statusContent: 'dot',
      hash: 'XVTZ2XN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVTZ2XN1'
    },
    {
      status: 210,
      sku: 'PM0068899',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220389',
      rtl_batch_array: '6220389, 6304794',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3940,
      chnn_stock_retail: 7734,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: '2JZNCNDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JZNCNDV'
    },
    {
      status: 210,
      sku: 'PM0045129',
      core_name: 'Plant',
      sppl_ean: '8720349410149',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592012',
      rtl_batch_array: '5592012',
      name_search: 'ASCVPINK',
      name: "Astilbe chinensis 'Vision in Pink'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NGYE189K',
      imageBatch: 'CDK2PHRT',
      statusContent: 'dot',
      hash: 'PBG3GTL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PBG3GTL6'
    },
    {
      status: 210,
      sku: 'PM0069910',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219474',
      rtl_batch_array: '6219474',
      name_search: 'COFMOON',
      name: "Coreopsis 'Full Moon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 668,
      chnn_stock_retail: 668,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JBG8CPVH;A2S97PE8;8JX19AZN;C1D49CD6;A7YGZW8N;4VJ5339B;GBND7KJK',
      statusContent: 'dot',
      hash: '7HWEXVEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HWEXVEY'
    },
    {
      status: 210,
      sku: 'PM0045083',
      core_name: 'Plant',
      sppl_ean: '8720664614918',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5562327',
      rtl_batch_array: '5562327',
      name_search: 'RHGAYANU',
      name: 'Rhodanthemum gayanum',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZYLW3S3',
      imageBatch: 'REBG6C79',
      statusContent: 'dot',
      hash: 'ACNNBW4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACNNBW4K'
    },
    {
      status: 210,
      sku: 'PM0077577',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304956',
      rtl_batch_array: '6304956',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'AWWS7ZW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWWS7ZW2'
    },
    {
      status: 210,
      sku: 'PM0045160',
      core_name: 'Plant',
      sppl_ean: '8720664695467',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592100',
      rtl_batch_array: '5592100',
      name_search: 'IRSYTAIL',
      name: "Iris sibirica 'Yellow Tail'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'VH7NWC9Y',
      statusContent: 'dot',
      hash: 'D1X5YXY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1X5YXY5'
    },
    {
      status: 210,
      sku: 'PM0045153',
      core_name: 'Plant',
      sppl_ean: '8720664695405',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592079',
      rtl_batch_array: '5592079',
      name_search: 'IRSKABLU',
      name: "Iris sibirica 'Kabluey'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RJW9DY1K',
      statusContent: 'dot',
      hash: 'R7CPL2Y5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7CPL2Y5'
    },
    {
      status: 210,
      sku: 'PM0077578',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304957',
      rtl_batch_array: '6304957',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 1.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'RSEW7ERB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSEW7ERB'
    },
    {
      status: 210,
      sku: 'PM0067696',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185797',
      rtl_batch_array: '6185797',
      name_search: 'DEMFWDBE',
      name: "Delphinium (P) 'Magic Fountains White Dark Bee'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7NX7Z8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7NX7Z8L'
    },
    {
      status: 210,
      sku: 'PM0081033',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338438',
      rtl_batch_array: '6338438',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2860,
      chnn_stock_retail: 2860,
      sppl_order_minimum: 3,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: '943K5GBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '943K5GBB'
    },
    {
      status: 910,
      sku: 'PM0067708',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMWSUN',
      name: "Mahonia media 'Winter Sun'",
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        '2BBW8GXV;RPZR1885;B2S9GWN2;ZPCDY8JT;ZSSYKGDS;GLBBNLPJ;LZXLZRXN',
      statusContent: 'dot',
      hash: 'XVA13JYB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XVA13JYB'
    },
    {
      status: 210,
      sku: 'PM0022158',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6220243',
      rtl_batch_array: '6220243, 5364499, 6330108, 6340047',
      name_search: 'SCCBBLUE',
      name: "Scabiosa columbaria 'Butterfly Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2228,
      chnn_stock_retail: 6392,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RCX7ZER6;XS84HEJG;RCS8ERW9;248SSX9Y;VKEA4ZLE;1B1S1NY2;G434PYGR;XY35VGB6;AEWGA53Z;9NY4N5D2;LZTB1HYD;V1HA1LRE;RX2A6GTS;194VANWB;75ESPGRC;XBY3287P',
      statusContent: 'dot',
      hash: 'XS76ANWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS76ANWC'
    },
    {
      status: 210,
      sku: 'PM0070838',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346743',
      rtl_batch_array: '6346743',
      name_search: 'TRJSTOSC',
      name: 'Trachelospermum jasminoides Star of Toscane',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2314,
      chnn_stock_retail: 2314,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C2GTL49W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2GTL49W'
    },
    {
      status: 210,
      sku: 'PM0077581',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304961',
      rtl_batch_array: '6304961, 6310216',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 760,
      sppl_prcp: 1.274,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'K2NSW2A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2NSW2A4'
    },
    {
      status: 210,
      sku: 'PM0067711',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186642',
      rtl_batch_array: '6186642',
      name_search: 'JUCCOMPR',
      name: "Juniperus communis 'Compressa'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 898,
      chnn_stock_retail: 898,
      sppl_prcp: 3.786,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WX1GRV22;H2G3GS33',
      statusContent: 'dot',
      hash: '5B4K8Z32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B4K8Z32'
    },
    {
      status: 210,
      sku: 'PM0068901',
      core_name: 'Plant',
      sppl_ean: '8720349429042',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5370832',
      rtl_batch_array: '5370832',
      name_search: 'STOUKKIE',
      name: "Stachys officinalis 'Ukkie'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6424,
      chnn_stock_retail: 6424,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '5ESA5NX7',
      statusContent: 'dot',
      hash: '98V9LAR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98V9LAR8'
    },
    {
      status: 210,
      sku: 'PM0068902',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348758',
      rtl_batch_array: '5479981, 6348758',
      name_search: 'CHTBRICA',
      name: "Choisya ternata 'Brica'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2000,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BLNN7V9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLNN7V9N'
    },
    {
      status: 210,
      sku: 'PM0077582',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304964',
      rtl_batch_array: '6304964',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3600,
      chnn_stock_retail: 3600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: 'RA7DD37G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA7DD37G'
    },
    {
      status: 210,
      sku: 'PM0068904',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191454',
      rtl_batch_array: '6191454',
      name_search: 'PEGLETSJ',
      name: "Penstemon 'Gletsjer'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NXYHE2RH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXYHE2RH'
    },
    {
      status: 910,
      sku: 'PM0077583',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '21HWADB4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '21HWADB4'
    },
    {
      status: 210,
      sku: 'PM0068906',
      core_name: 'Plant',
      sppl_ean: '8720664854529',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137762',
      rtl_batch_array: '6137762',
      name_search: 'CANMWHIT',
      name: "Calamintha nepeta 'Marvelette White'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 791,
      chnn_stock_retail: 791,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2WT11LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2WT11LD'
    },
    {
      status: 210,
      sku: 'PM0077584',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304967',
      rtl_batch_array: '6304967',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '46WZBZHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46WZBZHP'
    },
    {
      status: 210,
      sku: 'PM0081034',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338441',
      rtl_batch_array: '6338441',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 780,
      chnn_stock_retail: 780,
      sppl_prcp: 3.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'CYCRRZ3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYCRRZ3E'
    },
    {
      status: 210,
      sku: 'PM0077586',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305153',
      rtl_batch_array: '6305153',
      name_search: 'ASSPINEL',
      name: "Astilbe (A) 'Spinell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 696,
      chnn_stock_retail: 696,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJ39ANBH',
      statusContent: 'dot',
      hash: 'LGJTD93G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGJTD93G'
    },
    {
      status: 210,
      sku: 'PM0077587',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305156',
      rtl_batch_array: '6305156',
      name_search: 'HEGPRIZE',
      name: "Hemerocallis 'Golden Prize'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E4W16J82;DBCC7AWP;ED529S4N',
      statusContent: 'dot',
      hash: 'LT91W98B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT91W98B'
    },
    {
      status: 210,
      sku: 'PM0067689',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185787',
      rtl_batch_array: '6185787',
      name_search: 'HOSCLIGH',
      name: "Hosta 'Chain Lightning'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '86CD4X4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86CD4X4R'
    },
    {
      status: 210,
      sku: 'PM0067686',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183182',
      rtl_batch_array: '6183182',
      name_search: 'UNRUBRA',
      name: 'Uncinia rubra',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 753,
      chnn_stock_retail: 753,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LDPD2Z6L',
      statusContent: 'dot',
      hash: 'RGV8L1H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGV8L1H6'
    },
    {
      status: 210,
      sku: 'PM0077384',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6303426',
      rtl_batch_array: '6303426',
      name_search: 'PUAAZURE',
      name: "Pulmonaria angustifolia 'Azurea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3858,
      chnn_stock_retail: 3858,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WLC1AXCS',
      statusContent: 'dot',
      hash: 'PRWXP64R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRWXP64R'
    },
    {
      status: 210,
      sku: 'PM0070842',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307304',
      rtl_batch_array: '6307304',
      name_search: 'CLASHVA',
      name: "Clematis 'Ashva'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W7SHN1LA;YBP8G5TG',
      statusContent: 'dot',
      hash: 'CYP1TWYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYP1TWYP'
    },
    {
      status: 210,
      sku: 'PM0045144',
      core_name: 'Plant',
      sppl_ean: '8720664546103',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592059',
      rtl_batch_array: '5592059',
      name_search: 'HECBRETO',
      name: "Hemerocallis 'Cape Breton'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'JEYJ25B8',
      statusContent: 'dot',
      hash: 'A47C3AL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A47C3AL7'
    },
    {
      status: 210,
      sku: 'PM0017094',
      core_name: 'Plant',
      sppl_ean: '8720626395084',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495212',
      rtl_batch_array: '6220689, 6301462, 5495212',
      name_search: 'BERABEND',
      name: "Bergenia 'Abendglut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1473,
      chnn_stock_retail: 2095,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJRB6XT3',
      statusContent: 'dot',
      hash: 'X57N5A9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X57N5A9D'
    },
    {
      status: 210,
      sku: 'PM0069909',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219473',
      rtl_batch_array: '6219473',
      name_search: 'ASESNOWF',
      name: "Aster ericoides 'Snowflurry'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6BLTJKJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BLTJKJ2'
    },
    {
      status: 210,
      sku: 'PM0077566',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301661',
      rtl_batch_array: '6350510, 6301661',
      name_search: 'HOHONEYB',
      name: "Hosta 'Honeybells'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 470,
      chnn_stock_retail: 944,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJCKT7V6',
      statusContent: 'dot',
      hash: '4WGW1G1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WGW1G1A'
    },
    {
      status: 210,
      sku: 'PM0077568',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301691',
      rtl_batch_array: '6301691',
      name_search: 'LIASALBA',
      name: "Liatris spicata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1354,
      chnn_stock_retail: 1354,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S2BV65NK;LAVXBN3X',
      statusContent: 'dot',
      hash: 'H1THC4BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1THC4BW'
    },
    {
      status: 210,
      sku: 'PM0045088',
      core_name: 'Plant',
      sppl_ean: '8720349424870',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5581401',
      rtl_batch_array: '5581401',
      name_search: 'PHBPARAD',
      name: "Phlox (P) 'Blue Paradise'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6XEH3B6;DS1L57VH;NVBBLZND;97XABELR;SZCE85KR',
      imageBatch: 'X5B56A3J',
      statusContent: 'dot',
      hash: '1G61SSWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G61SSWJ'
    },
    {
      status: 210,
      sku: 'PM0067718',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186652',
      rtl_batch_array: '6186652',
      name_search: 'PIGECHIN',
      name: "Picea glauca 'Echiniformis'",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 959,
      chnn_stock_retail: 959,
      sppl_prcp: 5.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJC562T8;YBDXH7SH',
      statusContent: 'dot',
      hash: '8KGW56SH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KGW56SH'
    },
    {
      status: 210,
      sku: 'PM0067684',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274544',
      rtl_batch_array: '6274544',
      name_search: 'NENBOKRA',
      name: "Nepeta nervosa 'Bokratune'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4362,
      chnn_stock_retail: 4362,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9CRXN73Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CRXN73Y'
    },
    {
      status: 210,
      sku: 'PM0045112',
      core_name: 'Plant',
      sppl_ean: '8720349417766',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591973',
      rtl_batch_array: '5591973',
      name_search: 'HEVTRUTH',
      name: "Hemerocallis 'Veins of Truth'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'GK4ZTPKH',
      statusContent: 'dot',
      hash: 'JGHEHXJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGHEHXJR'
    },
    {
      status: 210,
      sku: 'PM0077572',
      core_name: 'Plant',
      sppl_ean: '8720626335615',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054448',
      rtl_batch_array: '6302404, 6054448',
      name_search: 'ASPBLOSS',
      name: "Astilbe (J) 'Peach Blossom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1871,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1VELE5R1;JV6RCRBR',
      statusContent: 'dot',
      hash: 'W6V1Z14W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6V1Z14W'
    },
    {
      status: 910,
      sku: 'PM0060084',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307747',
      rtl_batch_array: '6307747',
      name_search: 'PIMOPHIR',
      name: "Pinus mugo 'Ophir'",
      sppl_size_code: '025030C12',
      rng_range_identifier: 'H025030C12',
      rng_range_description: 'H25 cm 30 cm C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BZCGVL16',
      statusContent: 'dot',
      hash: 'NYE84EL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYE84EL5'
    },
    {
      status: 210,
      sku: 'PM0077575',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6309004',
      rtl_batch_array: '6302615, 6309004, 6386138',
      name_search: 'HOUMEDIO',
      name: "Hosta 'Undulata Mediovariegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1545,
      chnn_stock_retail: 3310,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6V28XX9;GSZJ8TPR;SVE1W1E1;6GPBXBL7;JWYRK432;BGSV9N2K;8ECZYC8Y;4P2496TH;PPKVRNRP;ETBZA2Z5',
      statusContent: 'dot',
      hash: '7HCK5WNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HCK5WNC'
    },
    {
      status: 210,
      sku: 'PM0069908',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288312',
      rtl_batch_array: '6288312',
      name_search: 'AQCSTAR',
      name: "Aquilegia 'Crimson Star'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2980,
      chnn_stock_retail: 2980,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B98EC523;9EALZYKC;ZR4NVZ6L;6KDN28ER;V3GT2XAR;WLX3DGZN;8CPBZ9JP;HXXTHXTC;DCDJLYS1',
      statusContent: 'dot',
      hash: 'T3DNG8SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3DNG8SL'
    },
    {
      status: 210,
      sku: 'PM0077585',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305152',
      rtl_batch_array: '6305152',
      name_search: 'ASRSENTI',
      name: "Astilbe (J) 'Red Sentinel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 453,
      chnn_stock_retail: 453,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2KSKH4PB;KRCHJ7CP',
      statusContent: 'dot',
      hash: 'R3ZNL4BG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3ZNL4BG'
    },
    {
      status: 810,
      sku: 'PM0060082',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307746',
      rtl_batch_array: '6307746',
      name_search: 'PIMKGRU',
      name: "Pinus mugo 'Klosters Grun'",
      sppl_size_code: '030040C12',
      rng_range_identifier: 'H030040C12',
      rng_range_description: 'H30 cm 40 cm C12',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 23.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WT55ELEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WT55ELEX'
    },
    {
      status: 210,
      sku: 'PM0067694',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185795',
      rtl_batch_array: '6185795',
      name_search: 'HOFPATRI',
      name: 'gebruik HOPATRIO',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BWHJDP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BWHJDP1'
    },
    {
      status: 910,
      sku: 'PM0067709',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'CZZTYTRY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CZZTYTRY'
    },
    {
      status: 210,
      sku: 'PM0077580',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304959',
      rtl_batch_array: '6304959',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_prcp: 1.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: '5V5LBYNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V5LBYNL'
    },
    {
      status: 210,
      sku: 'PM0074208',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300173',
      rtl_batch_array: '6300173, 6310184',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 6649,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: 'Y71X5HCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y71X5HCV'
    },
    {
      status: 210,
      sku: 'PM0070834',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307526',
      rtl_batch_array: '6307526',
      name_search: 'LOHENRYI',
      name: 'Lonicera henryi',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN37L23T;JG217ECD;8H823RVC;REDLBRVV;CRZV8V5A;TPDWP3EY;7XRTK71Y;EENTKELG;E63ER6TJ;NWD4J7CW;GLSRDX75;J6K74GCC;W8A35BAE;CK844ZSW',
      statusContent: 'dot',
      hash: 'GNP7X2E6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNP7X2E6'
    },
    {
      status: 210,
      sku: 'PM0070841',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307357',
      rtl_batch_array: '6307357',
      name_search: 'CLWILLY',
      name: "Clematis 'Willy'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ54666J',
      statusContent: 'dot',
      hash: '2R3SGAZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2R3SGAZB'
    },
    {
      status: 210,
      sku: 'PM0077569',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350027',
      rtl_batch_array: '6350027',
      name_search: 'ECPBKAHU',
      name: "Echinacea purpurea 'Big Kahuna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 581,
      chnn_stock_retail: 581,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6W19S5T4',
      statusContent: 'dot',
      hash: 'ZVCAEXGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVCAEXGP'
    },
    {
      status: 210,
      sku: 'PM0069016',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220740',
      rtl_batch_array: '6220740',
      name_search: 'GAVERUM',
      name: 'Galium verum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18R8PKVY',
      statusContent: 'dot',
      hash: 'A5XH829T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5XH829T'
    },
    {
      status: 910,
      sku: 'PM0045084',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      rng_range_identifier: 'CONEH110120C20',
      rng_range_description: 'Cone H110 cm 120 cm C20',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '2GXZ4XDN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2GXZ4XDN'
    },
    {
      status: 210,
      sku: 'PM0077573',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302575',
      rtl_batch_array: '6302575',
      name_search: 'HEAMADEU',
      name: "Hemerocallis 'Amadeus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1D2NRXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1D2NRXK'
    },
    {
      status: 210,
      sku: 'PM0068910',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252430',
      rtl_batch_array: '6252430',
      name_search: 'ACMNVWHI',
      name: 'Achillea millefolium New Vintage White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_prcp: 2.215,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XTYNYDBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTYNYDBS'
    },
    {
      status: 810,
      sku: 'PM0060076',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307740',
      rtl_batch_array: '6307740',
      name_search: 'PIHCGEM',
      name: "Pinus heldreichii 'Compact Gem'",
      sppl_size_code: '030040C12',
      rng_range_identifier: 'H030040C12',
      rng_range_description: 'H30 cm 40 cm C12',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXDKKLN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXDKKLN1'
    },
    {
      status: 210,
      sku: 'PM0077574',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6309001',
      rtl_batch_array: '6350505, 6302610, 6309001',
      name_search: 'HOFHYACI',
      name: "Hosta 'Fortunei Hyacinthina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1005,
      chnn_stock_retail: 2099,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARZGTA6W;78K1SLA5;ZBSDLNDW;B1D56NX6;W48L9Y9R;7KDAG7AR;C58N91BA;WXK1P9DV;V8VXKPCK;256A5GBH;5KCA22R4;TNY7A5L1;CTZ289SL;E56H19X3;79DJNPBD;T9GZ88J9;9RVVJGV7;BPH5SEDV;HNSAL168',
      statusContent: 'dot',
      hash: 'DXR1LYZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXR1LYZV'
    },
    {
      status: 210,
      sku: 'PM0069917',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219488',
      rtl_batch_array: '6219488',
      name_search: 'LABBABY',
      name: "Lavatera 'Barnsley Baby'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1964,
      chnn_stock_retail: 1964,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VESE5VJZ;8CB89BAK;PC1BGKHB;1BERNVZD;1R8Y1YBA;6R84G5WG;V8E44GEJ;C6VVN257;ETXCWT94;BA185XAL;CAH53L87;EP15BJ8K;B9PZ9TKL',
      statusContent: 'dot',
      hash: 'WVZRBPRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVZRBPRP'
    },
    {
      status: 210,
      sku: 'PM0081035',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338442',
      rtl_batch_array: '6338442',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1740,
      chnn_stock_retail: 1740,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: '9LB4PAGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LB4PAGA'
    },
    {
      status: 210,
      sku: 'PM0085059',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263454',
      rtl_batch_array: '6263454',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '08010020',
      rng_range_identifier: 'H080100BRA2+0',
      rng_range_description: 'H80 cm 100 cm Bare root 2+0',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'LB9WY215',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB9WY215'
    },
    {
      status: 210,
      sku: 'PM0068914',
      core_name: 'Plant',
      sppl_ean: '8720664557727',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '5992739',
      rtl_batch_array: '5992739, 6276621, 6334775',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 25000,
      chnn_stock_retail: 40079,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'B9WJPZKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9WJPZKA'
    },
    {
      status: 910,
      sku: 'PM0060087',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345516',
      rtl_batch_array: '6345516',
      name_search: 'PINHORNI',
      name: "Pinus nigra 'Hornibrookiana'",
      sppl_size_code: '050060C20',
      rng_range_identifier: 'H050060C20',
      rng_range_description: 'H50 cm 60 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 37.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6A33GRH1;7JZRVN5X',
      statusContent: 'dot',
      hash: '1D1X1AJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1D1X1AJB'
    },
    {
      status: 210,
      sku: 'PM0081036',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338443',
      rtl_batch_array: '6338443',
      name_search: 'PRLVARIE',
      name: "Prunus lusitanica 'Variegata'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TH8JWJWG',
      statusContent: 'dot',
      hash: 'T56D4EBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T56D4EBB'
    },
    {
      status: 210,
      sku: 'PM0081037',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338444',
      rtl_batch_array: '6338444',
      name_search: 'PRPDEPRE',
      name: 'Prunus pumila depressa',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBG9SBG9',
      statusContent: 'dot',
      hash: 'BSTZ333W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSTZ333W'
    },
    {
      status: 210,
      sku: 'PM0077393',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304734',
      rtl_batch_array: '6304734',
      name_search: 'LECDAISY',
      name: "Leucanthemum 'Crazy Daisy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 8726,
      chnn_stock_retail: 8726,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TLXR4TD',
      statusContent: 'dot',
      hash: 'C9W27HPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9W27HPJ'
    },
    {
      status: 210,
      sku: 'PM0045907',
      core_name: 'Plant',
      sppl_ean: '8720664691094',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015157',
      rtl_batch_array: '6015157',
      name_search: 'GELISANN',
      name: "Geum 'Lisanne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DW7ZT1B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DW7ZT1B3'
    },
    {
      status: 210,
      sku: 'PM0070856',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307321',
      rtl_batch_array: '6307321',
      name_search: 'CLHHYBRI',
      name: "Clematis 'Hagley Hybrid'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 261,
      chnn_stock_retail: 261,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8NYBL6C;ERG8894J;ZY93V8JZ;6GLHTTNW;G5J9Y5WP;8215KY9J;PNL7KLG1;544TE7CA;XEDVEPCD;1E31WSNX;W1AXAN6R;Z54DL677;9YS7N277;THK9J8YW;A9JGZ764;51C7X6KL;69AK7ABP;RN7VGG1H',
      statusContent: 'dot',
      hash: 'P9L8L623',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9L8L623'
    },
    {
      status: 810,
      sku: 'PM0081038',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338445',
      rtl_batch_array: '6338445',
      name_search: 'PRSPURPU',
      name: "Prunus spinosa 'Purpurea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P4V5VWWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4V5VWWL'
    },
    {
      status: 210,
      sku: 'PM0045227',
      core_name: 'Plant',
      sppl_ean: '8720664853003',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6118550',
      rtl_batch_array: '5592260, 6118550',
      name_search: 'ASTEUROP',
      name: "Astilbe (J) 'Europa'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5549,
      chnn_stock_retail: 6549,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRD7E9PK;25VW25XY',
      statusContent: 'dot',
      hash: 'PKYSY1KK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKYSY1KK'
    },
    {
      status: 210,
      sku: 'PM0045192',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368790',
      rtl_batch_array: '6368790',
      name_search: 'ASPAKIPP',
      name: "Aster (D) 'Prof. Anton Kippenberg'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8R4P2H6;YCRCK5ZY;2RYWKLH8;KR6LX94K;WKDHV3J6',
      statusContent: 'dot',
      hash: '6PDL6GLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PDL6GLX'
    },
    {
      status: 210,
      sku: 'PM0070845',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307307',
      rtl_batch_array: '6307307',
      name_search: 'CLBANIOL',
      name: "Clematis 'Blekitny Aniol'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HXGKZJVR;6EYR26SS',
      statusContent: 'dot',
      hash: '8PLGYP4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PLGYP4Z'
    },
    {
      status: 210,
      sku: 'PM0045223',
      core_name: 'Plant',
      sppl_ean: '8720626305304',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592250',
      rtl_batch_array: '5592250',
      name_search: 'HOSORBET',
      name: "Hosta 'Sorbet'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'BHR7R6WB',
      statusContent: 'dot',
      hash: '68ZZ1RZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68ZZ1RZ9'
    },
    {
      status: 210,
      sku: 'PM0077394',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304735',
      rtl_batch_array: '6304735',
      name_search: 'LECDAISY',
      name: "Leucanthemum 'Crazy Daisy'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 4416,
      chnn_stock_retail: 4416,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TLXR4TD',
      statusContent: 'dot',
      hash: '41TWRGL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41TWRGL3'
    },
    {
      status: 210,
      sku: 'PM0077395',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304736',
      rtl_batch_array: '6304736',
      name_search: 'LEFWHITE',
      name: 'Leucothoe fontanesiana Whitewater',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1011,
      chnn_stock_retail: 1011,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BEG16Z76;7YW9P954;2SKRN5LR;93KS3TET;VNT9JXPG;AG2LXBTB;H3NZ469E;1K7XBG8W;2GWR2EDN;6B6JAJZN;SHN4CDTG;S5X8DLX2;K254BS4V;BECPBJ9J;8JPAK7K4;T3C1SRSH;WBC2LS57',
      statusContent: 'dot',
      hash: 'Y2TL9YWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2TL9YWG'
    },
    {
      status: 210,
      sku: 'PM0077396',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304737',
      rtl_batch_array: '6304737',
      name_search: 'LEKBLOVE',
      name: 'Leucothoe keiskei Burning Love',
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 3084,
      chnn_stock_retail: 3084,
      sppl_prcp: 15.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VN99V1NP;59ZL4XTE;D6BPBVX3;GTVS8BRA;2PA6H948;N8S34GVV;65PZJBPK;7VPW67LX;D3XVR27D;6R7L31CZ',
      statusContent: 'dot',
      hash: 'AZWNTL35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZWNTL35'
    },
    {
      status: 210,
      sku: 'PM0045200',
      core_name: 'Plant',
      sppl_ean: '8720349419678',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '5592205',
      rtl_batch_array: '5592205, 6187765',
      name_search: 'IRSPPARF',
      name: "Iris sibirica 'Pink Parfait'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1010,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'NA2RXK2X',
      statusContent: 'dot',
      hash: '57NNKC2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57NNKC2H'
    },
    {
      status: 810,
      sku: 'PM0074209',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310205',
      rtl_batch_array: '6310205, 6268492',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 37,
      chnn_stock_retail: 537,
      sppl_prcp: 1.546,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'Z1YR3DPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1YR3DPR'
    },
    {
      status: 210,
      sku: 'PM0045243',
      core_name: 'Plant',
      sppl_ean: '8720349479009',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054281',
      rtl_batch_array: '6054281, 6319675',
      name_search: 'ACHUNGAR',
      name: 'Acanthus hungaricus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1446,
      chnn_stock_retail: 1502,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9SVW1NSZ;BKKLW29C;V7HLGNTL;TPWPKZ3H',
      statusContent: 'dot',
      hash: '871Y4R1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '871Y4R1R'
    },
    {
      status: 810,
      sku: 'PM0056992',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301917',
      rtl_batch_array: '6301917, 6353153',
      name_search: 'PHGLOBOS',
      name: 'Phaenosperma globosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 262,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C53TBDZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C53TBDZT'
    },
    {
      status: 210,
      sku: 'PM0069918',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259158',
      rtl_batch_array: '6259158',
      name_search: 'LEPOTENT',
      name: 'Leptinella potentillina',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 732,
      chnn_stock_retail: 732,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SY1G5N37',
      statusContent: 'dot',
      hash: 'J9ZZV96S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9ZZV96S'
    },
    {
      status: 910,
      sku: 'PM0047657',
      core_name: 'Plant',
      sppl_ean: '8720349447428',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '5942859',
      rtl_batch_array: '6350688, 5942859',
      name_search: 'MISVARIE',
      name: "Miscanthus sinensis 'Variegatus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 11,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 0.765,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNC1GJLZ;8HJEBAB8;WKT41DLJ;2C2PCKGB;C6A7HCL3;VGV2Y757;2GDEE6DR;T7KY5CGV;S9W5PJHJ;EG7SXJ33;CKPC6BGS;86WLLTJY;TPRW3A84;S8HTLDAJ;2B8VX3EK;4AZX7HRE',
      statusContent: 'dot',
      hash: 'RHEZ4ABH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHEZ4ABH'
    },
    {
      status: 210,
      sku: 'PM0068490',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220883',
      rtl_batch_array: '6220883, 6319728, 6333290',
      name_search: 'PHBBOY',
      name: "Phlox (P) 'Blue Boy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 375,
      chnn_stock_retail: 2251,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RX5B45NV;GZDEBBKX',
      statusContent: 'dot',
      hash: 'CCS69P7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCS69P7Y'
    },
    {
      status: 810,
      sku: 'PM0081039',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338446',
      rtl_batch_array: '6338446',
      name_search: 'PRTROSEN',
      name: "Prunus triloba 'Rosenmund'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HL7YYYNV;G8G1V88J;PPJY7YLD;7L4N7YV6;7ATNXVKJ;PNSGSSSJ;DJZSG1RH',
      statusContent: 'dot',
      hash: 'R5Z3CRAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5Z3CRAY'
    },
    {
      status: 210,
      sku: 'PM0081040',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338447',
      rtl_batch_array: '6338447',
      name_search: 'PYSOR',
      name: "Pyracantha 'Soleil d'Or'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 929,
      chnn_stock_retail: 929,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V7SYWATP;67HWYKH5;GNRYZY8E;2CLD6RED;ESH89THA;8KSP5GWY;V5D1LT7N;9JHPT4PG;ELJVPHGC',
      statusContent: 'dot',
      hash: 'KGBEAR6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGBEAR6W'
    },
    {
      status: 210,
      sku: 'PM0077398',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304739',
      rtl_batch_array: '6304739, 6309064',
      name_search: 'LELFLAME',
      name: "Leucothoe 'Little Flames'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 16739,
      chnn_stock_retail: 17739,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B6NXLXP4;864VW4XL;B9ACRHHR;RBLSNCXL;9H7X1R68;REYN6BZG;VD65W7SY;12LJYH88',
      statusContent: 'dot',
      hash: '4831XDCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4831XDCW'
    },
    {
      status: 210,
      sku: 'PM0022408',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242026',
      rtl_batch_array: '6370349, 6350141, 6242026',
      name_search: 'ERRALBUM',
      name: "Erodium reichardii 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 612,
      chnn_stock_retail: 1240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YE2KGY92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YE2KGY92'
    },
    {
      status: 210,
      sku: 'PM0045179',
      core_name: 'Plant',
      sppl_ean: '8720626305182',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592144',
      rtl_batch_array: '5592144',
      name_search: 'HOINVINC',
      name: "Hosta 'Invincible'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2VLNJBH1;51ZKTE5H;PV7VBGPY;YPJHRZRT;5KEC94ZX;GVB2ACT4;EVGSCESZ;97DZBCHK;PATBCAHZ',
      imageBatch: 'YHEH6JL5',
      statusContent: 'dot',
      hash: 'ZK5NPL5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK5NPL5S'
    },
    {
      status: 910,
      sku: 'PM0045908',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEMHYBR',
      name: "Geum 'Morning Hybrids'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'R951RNRL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R951RNRL'
    },
    {
      status: 210,
      sku: 'PM0077399',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304740',
      rtl_batch_array: '6304740',
      name_search: 'LELFLAME',
      name: "Leucothoe 'Little Flames'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1676,
      chnn_stock_retail: 1676,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B6NXLXP4;864VW4XL;B9ACRHHR;RBLSNCXL;9H7X1R68;REYN6BZG;VD65W7SY;12LJYH88',
      statusContent: 'dot',
      hash: 'Z6125Y1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6125Y1P'
    },
    {
      status: 210,
      sku: 'PM0077400',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304741',
      rtl_batch_array: '6304741',
      name_search: 'LELOVITA',
      name: 'Leucothoe Lovita',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1490,
      chnn_stock_retail: 1490,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JKC5TBCS',
      statusContent: 'dot',
      hash: 'XKA7V614',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKA7V614'
    },
    {
      status: 210,
      sku: 'PM0077402',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304743',
      rtl_batch_array: '6304743',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 5116,
      chnn_stock_retail: 5116,
      sppl_prcp: 15.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: 'E4216SJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4216SJN'
    },
    {
      status: 210,
      sku: 'PM0077403',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304744',
      rtl_batch_array: '6304744',
      name_search: 'LONBGOLD',
      name: "Lonicera nitida 'Baggesen's Gold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '91E43ANW;7PZGK1L1;961E9EN3;AXBR7TBG;GB2LA3V4',
      statusContent: 'dot',
      hash: 'TGHLCBGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGHLCBGB'
    },
    {
      status: 210,
      sku: 'PM0046017',
      core_name: 'Plant',
      sppl_ean: '8720349488377',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015263',
      rtl_batch_array: '6015263',
      name_search: 'HESSTARB',
      name: "Hemerocallis 'Spacecoast Starburst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R3GPS9XN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3GPS9XN'
    },
    {
      status: 210,
      sku: 'PM0045220',
      core_name: 'Plant',
      sppl_ean: '8720626392038',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592245',
      rtl_batch_array: '5592245',
      name_search: 'ASERIKA',
      name: "Astilbe (A) 'Erika'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S793C898;NPYDYK5N;6SDCPAC8;XSAPTGZA;YLEA7E7T;WDBEGLE1;Z1KZZHNA;87CBS8Z2',
      imageBatch: 'TK6N1DDG',
      statusContent: 'dot',
      hash: 'BS67C2WX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS67C2WX'
    },
    {
      status: 210,
      sku: 'PM0077588',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305157',
      rtl_batch_array: '6305157',
      name_search: 'HEMBOY',
      name: "Hemerocallis 'Minstrel Boy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CCC9PSWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCC9PSWL'
    },
    {
      status: 210,
      sku: 'PM0077590',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305159',
      rtl_batch_array: '6305159',
      name_search: 'HERAJAH',
      name: "Hemerocallis 'Rajah'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NC7386JR;C58C3CB1;WCY96X4N',
      statusContent: 'dot',
      hash: 'EAEG2LW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAEG2LW7'
    },
    {
      status: 210,
      sku: 'PM0057960',
      core_name: 'Plant',
      sppl_ean: '8720664801745',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084650',
      rtl_batch_array: '6084650',
      name_search: 'PHFWHITE',
      name: 'Phlox (S) Fabulous White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 572,
      chnn_stock_retail: 572,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D6XHYETL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6XHYETL'
    },
    {
      status: 210,
      sku: 'PM0070848',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307312',
      rtl_batch_array: '6307312',
      name_search: 'CLEMARKH',
      name: "Clematis 'Ernest Markham'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1480,
      chnn_stock_retail: 1480,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H911WLZN;YKWJRTHC;VP7VBEJY;NP6G7DBX;TJY1HE3W;6JYKVED4;VVSJ3C7G',
      statusContent: 'dot',
      hash: '6C3S18VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6C3S18VY'
    },
    {
      status: 810,
      sku: 'PM0081041',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338448',
      rtl_batch_array: '6338448',
      name_search: 'PYSPARKL',
      name: "Pyracantha 'Sparkler'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '23RVHBV1',
      statusContent: 'dot',
      hash: 'CRDPAPA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRDPAPA2'
    },
    {
      status: 910,
      sku: 'PM0060102',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PIWALLIC',
      name: 'Pinus wallichiana',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      imageCore:
        'JJ4DPHTK;S7YWKNVX;G7LYA11R;KAK23EZX;GEWXWSKZ;V798ZY64;KAXJPGSX;6B61XV4W',
      statusContent: 'dot',
      hash: '8696ZJSC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8696ZJSC'
    },
    {
      status: 210,
      sku: 'PM0077591',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305160',
      rtl_batch_array: '6305160',
      name_search: 'HETSUNLI',
      name: "Hemerocallis 'Texas Sunlight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ZXV41BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZXV41BD'
    },
    {
      status: 210,
      sku: 'PM0045229',
      core_name: 'Plant',
      sppl_ean: '8720664691957',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592263',
      rtl_batch_array: '5592263',
      name_search: 'HELEYE',
      name: "Hemerocallis 'London Eye'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'L3TN5TWL',
      statusContent: 'dot',
      hash: 'BYA1CNB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYA1CNB4'
    },
    {
      status: 210,
      sku: 'PM0045899',
      core_name: 'Plant',
      sppl_ean: '8720353011349',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034345',
      rtl_batch_array: '6034345',
      name_search: 'GESINVER',
      name: "Geranium sanguineum 'Inverness'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AKD997NX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKD997NX'
    },
    {
      status: 210,
      sku: 'PM0081042',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338449',
      rtl_batch_array: '6338449',
      name_search: 'PYTETON',
      name: "Pyracantha 'Teton'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 671,
      chnn_stock_retail: 671,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3PJ197K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3PJ197K'
    },
    {
      status: 210,
      sku: 'PM0081043',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338450',
      rtl_batch_array: '6338450',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 544,
      chnn_stock_retail: 544,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'J85K25AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J85K25AW'
    },
    {
      status: 210,
      sku: 'PM0063290',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6353509',
      rtl_batch_array: '6037935, 6217227, 6353509',
      name_search: 'TRORIENT',
      name: 'Trachystemon orientalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 569,
      chnn_stock_retail: 4429,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '49XAZXBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49XAZXBG'
    },
    {
      status: 210,
      sku: 'PM0081044',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338451',
      rtl_batch_array: '6338451',
      name_search: 'RHALATER',
      name: 'Rhamnus alaternus',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 525,
      chnn_stock_retail: 525,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7LCJACNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LCJACNH'
    },
    {
      status: 210,
      sku: 'PM0081045',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338452',
      rtl_batch_array: '6338452',
      name_search: 'RHAARGEN',
      name: "Rhamnus alaternus 'Argenteovariegata'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T9WTCZKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9WTCZKR'
    },
    {
      status: 210,
      sku: 'PM0047667',
      core_name: 'Plant',
      sppl_ean: '8720349481538',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420600',
      rtl_batch_array: '5420600',
      name_search: 'CLPROPER',
      name: "Clematis 'Propertius'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 940,
      chnn_stock_retail: 940,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GXVKRKDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXVKRKDG'
    },
    {
      status: 210,
      sku: 'PM0070851',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307316',
      rtl_batch_array: '6307316',
      name_search: 'CLFAPLEN',
      name: "Clematis florida 'Alba Plena'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_order_minimum: 3,
      sppl_prcp: 5.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '59APJYSL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59APJYSL'
    },
    {
      status: 210,
      sku: 'PM0058004',
      core_name: 'Plant',
      sppl_ean: '8720664699762',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084791',
      rtl_batch_array: '6084791, 6301733',
      name_search: 'MONAPRAE',
      name: "Monarda 'Pr\u00e4rienacht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 345,
      chnn_stock_retail: 550,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XZ2GPJCH',
      statusContent: 'dot',
      hash: 'YEVY51AV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEVY51AV'
    },
    {
      status: 210,
      sku: 'PM0070852',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307317',
      rtl_batch_array: '6307317',
      name_search: 'CLFSIEBO',
      name: 'Clematis florida sieboldiana',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_order_minimum: 3,
      sppl_prcp: 5.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B89NWBXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B89NWBXV'
    },
    {
      status: 210,
      sku: 'PM0081046',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338453',
      rtl_batch_array: '6338453',
      name_search: 'RHCCRIMS',
      name: "Rhaphiolepis 'Coat's Crimson'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_prcp: 3.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9VDZKE7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VDZKE7X'
    },
    {
      status: 210,
      sku: 'PM0081047',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338454',
      rtl_batch_array: '6338454',
      name_search: 'RHUMBELL',
      name: 'Rhaphiolepis umbellata',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 564,
      chnn_stock_retail: 564,
      sppl_prcp: 3.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BY5ASL17',
      statusContent: 'dot',
      hash: 'X6EZ2EN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6EZ2EN9'
    },
    {
      status: 210,
      sku: 'PM0085060',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263543',
      rtl_batch_array: '6263543',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '04006010',
      rng_range_identifier: 'H040060BRSeedA1+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+0',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'XAWK8ZDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAWK8ZDC'
    },
    {
      status: 210,
      sku: 'PM0081048',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338455',
      rtl_batch_array: '6338455',
      name_search: 'RHUMBELL',
      name: 'Rhaphiolepis umbellata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BY5ASL17',
      statusContent: 'dot',
      hash: 'KGV98SNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGV98SNB'
    },
    {
      status: 210,
      sku: 'PM0081049',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338456',
      rtl_batch_array: '6338456',
      name_search: 'RHUAGLOW',
      name: "Rhus aromatica 'Grow-Low'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 4.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4YHHBP1;LTZYX82X',
      statusContent: 'dot',
      hash: 'VZGE6AZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZGE6AZ5'
    },
    {
      status: 210,
      sku: 'PM0081050',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338457',
      rtl_batch_array: '6338457',
      name_search: 'RHSCANDE',
      name: 'Rhodotypos scandens',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 452,
      chnn_stock_retail: 452,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5KXESB3',
      statusContent: 'dot',
      hash: 'TY3NE7BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TY3NE7BW'
    },
    {
      status: 210,
      sku: 'PM0081051',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338458',
      rtl_batch_array: '6338458',
      name_search: 'RHGLABRA',
      name: 'Rhus glabra',
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 669,
      chnn_stock_retail: 669,
      sppl_prcp: 4.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8E4XNNPW;PGATZ5B2;ZZXJTKYK;H53EKC1J;X9J3XHZJ;GEP7DXHS;2TND71EB;NVVB8PR4;HNG4X732;RS49H6CS',
      statusContent: 'dot',
      hash: 'SB4PH584',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SB4PH584'
    },
    {
      status: 210,
      sku: 'PM0081052',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338459',
      rtl_batch_array: '6338459',
      name_search: 'RHGLACIN',
      name: "Rhus glabra 'Laciniata'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 4.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9D42WC6B',
      statusContent: 'dot',
      hash: '8KEB96LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KEB96LE'
    },
    {
      status: 210,
      sku: 'PM0081053',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338460',
      rtl_batch_array: '6338460',
      name_search: 'RIASCHMI',
      name: "Ribes alpinum 'Schmidt'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVALV4JT',
      statusContent: 'dot',
      hash: 'XSBNW9V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSBNW9V2'
    },
    {
      status: 210,
      sku: 'PM0081054',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338461',
      rtl_batch_array: '6338461',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 3750,
      chnn_stock_retail: 3750,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: 'KV9BGH2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KV9BGH2N'
    },
    {
      status: 210,
      sku: 'PM0077401',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304742',
      rtl_batch_array: '6304742',
      name_search: 'LEFMAKIJ',
      name: "Leucothoe fontanesiana 'Makijaz'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 243,
      chnn_stock_retail: 243,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GHT1129G;KHGLN5HW;2ELJX1HN;9ZHY36XL;KA2RPDVB;6SHANJLW;ETN9N56R;RSR1VWG5;19S8GE49;27NTL3BW;8H7RXYKK;2DSPB39B;RVZNNN1H;YAN7HAWV',
      statusContent: 'dot',
      hash: 'LARA57WP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LARA57WP'
    },
    {
      status: 910,
      sku: 'PM0063495',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      statusContent: 'dot',
      hash: 'PXZVRZSZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PXZVRZSZ'
    },
    {
      status: 210,
      sku: 'PM0045174',
      core_name: 'Plant',
      sppl_ean: '8720349405572',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592132',
      rtl_batch_array: '5592132',
      name_search: 'HOYRIVER',
      name: "Hosta 'Yellow River'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'YYGL2D33',
      statusContent: 'dot',
      hash: 'VSKT1LLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSKT1LLT'
    },
    {
      status: 210,
      sku: 'PM0077397',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304738',
      rtl_batch_array: '6304738',
      name_search: 'LEKBLOVE',
      name: 'Leucothoe keiskei Burning Love',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 7523,
      chnn_stock_retail: 7523,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VN99V1NP;59ZL4XTE;D6BPBVX3;GTVS8BRA;2PA6H948;N8S34GVV;65PZJBPK;7VPW67LX;D3XVR27D;6R7L31CZ',
      statusContent: 'dot',
      hash: '4ZXG1B18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZXG1B18'
    },
    {
      status: 810,
      sku: 'PM0045918',
      core_name: 'Plant',
      sppl_ean: '8720349416820',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6015166',
      rtl_batch_array: '6015166, 6193611',
      name_search: 'GLMVARIE',
      name: "Glyceria maxima 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 1096,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C22955S3;CZ8KVRPY;YWH6T87A;4L7W7TSC;EXL774CL',
      statusContent: 'dot',
      hash: 'XP5K45R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP5K45R7'
    },
    {
      status: 210,
      sku: 'PM0070854',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307319',
      rtl_batch_array: '6307319',
      name_search: 'CLGQUEEN',
      name: "Clematis 'Gipsy Queen'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTZ5CDLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTZ5CDLP'
    },
    {
      status: 210,
      sku: 'PM0077592',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305161',
      rtl_batch_array: '6350486, 6305161',
      name_search: 'HOADGOUR',
      name: "Hosta 'Abiqua Drinking Gourd'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 436,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3XVBPDS',
      statusContent: 'dot',
      hash: '1EVV4SGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1EVV4SGD'
    },
    {
      status: 210,
      sku: 'PM0081055',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338462',
      rtl_batch_array: '6338462',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2858,
      chnn_stock_retail: 2858,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'BSGAXGKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSGAXGKL'
    },
    {
      status: 210,
      sku: 'PM0077595',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305171',
      rtl_batch_array: '6350507, 6305171',
      name_search: 'HOGSTAND',
      name: "Hosta 'Gold Standard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 182,
      chnn_stock_retail: 683,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13WAVW7C;P541X5CG;5HJPLD7P;DTT6WYG9',
      statusContent: 'dot',
      hash: '9ZHYKJ4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZHYKJ4R'
    },
    {
      status: 210,
      sku: 'PM0057981',
      core_name: 'Plant',
      sppl_ean: '8720664885929',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084714',
      rtl_batch_array: '6084714, 6220922, 6301836',
      name_search: 'SEREFLEX',
      name: 'Sedum reflexum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1987,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NS43XG7Y;A9GTL63V;E26NHLG1;WC4AY2T7;YX4ACW2S;NPEGPAXP;5394Y3TL;ZEYZ183A;BCXPHW4T;DPEHXSPE;B9K9222V;8EP92THX;9ETDHTGX;JRVJ5DGC;6WCPHWHB;R79W24DP;8SZTJEAB;XL1DN7W1;D6B6WBAC;DY3H7ZV4;7244TCBH',
      statusContent: 'dot',
      hash: 'HZEX2584',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZEX2584'
    },
    {
      status: 210,
      sku: 'PM0077596',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305173',
      rtl_batch_array: '6305173, 6386135',
      name_search: 'HOINVINC',
      name: "Hosta 'Invincible'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2VLNJBH1;51ZKTE5H;PV7VBGPY;YPJHRZRT;5KEC94ZX;GVB2ACT4;EVGSCESZ;97DZBCHK;PATBCAHZ',
      statusContent: 'dot',
      hash: '8H2PWPY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H2PWPY5'
    },
    {
      status: 210,
      sku: 'PM0077597',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350515',
      rtl_batch_array: '6350515, 6305174',
      name_search: 'HONBCHRI',
      name: "Hosta 'Night Before Christmas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HCD2DVHL;9YX75SVG;GZDVBNPZ;CZSR3XAW;5HYZ7BDD;TJW5TC79;HGZ5YGTW;ZR4C31KP',
      statusContent: 'dot',
      hash: 'KXC97SN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXC97SN7'
    },
    {
      status: 210,
      sku: 'PM0077605',
      core_name: 'Plant',
      sppl_ean: '8720349472307',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871930',
      rtl_batch_array: '5871930',
      name_search: 'PHMAISCH',
      name: "Phlox (S) 'Maischnee'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EHJTZJ31;L7BP2D9G;CPJRRCAX;AH934LSA;YHA7SELJ;TJJ1WAE4;WKSLJZDL;9A3T2WZY',
      statusContent: 'dot',
      hash: '16YJE69T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16YJE69T'
    },
    {
      status: 210,
      sku: 'PM0070864',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307329',
      rtl_batch_array: '6307329',
      name_search: 'CLMAYLEE',
      name: "Clematis 'Mayleen'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1714,
      chnn_stock_retail: 1714,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '49BSDVK6;JBZH1GCG;4HS1SL2Z;ZC47WV66;PCNXCJJ4;XTG8D194;9PS6X6S3;745RCDAH;9XX3PK1X;LHL95ZG4;YYD2BT52;44EYZZ61;KEC99YA6;YPDPT9Y7',
      statusContent: 'dot',
      hash: 'D8R2B8K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8R2B8K1'
    },
    {
      status: 210,
      sku: 'PM0023950',
      core_name: 'Plant',
      sppl_ean: '8720626347847',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364076',
      rtl_batch_array: '6350190, 5364076, 6295586',
      name_search: 'GEATHOMS',
      name: "Geranium 'Anne Thomson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1993,
      chnn_stock_retail: 2427,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G1961Z1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1961Z1C'
    },
    {
      status: 210,
      sku: 'PM0011374',
      core_name: 'Plant',
      sppl_ean: '8720626309838',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495409',
      rtl_batch_array: '6112564, 5495409',
      name_search: 'LEVSECRE',
      name: "Leucanthemum (S) 'Victorian Secret'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 1.195,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8X59PD7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8X59PD7A'
    },
    {
      status: 210,
      sku: 'PM0077603',
      core_name: 'Plant',
      sppl_ean: '8720349404971',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849724',
      rtl_batch_array: '5849724',
      name_search: 'GEARUSH',
      name: "Geranium 'Azure Rush'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_prcp: 3.497,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVSG4AS8',
      statusContent: 'dot',
      hash: 'XD9J6GVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD9J6GVL'
    },
    {
      status: 210,
      sku: 'PM0081056',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338464',
      rtl_batch_array: '6338464',
      name_search: 'ROOMJUPR',
      name: "Rosmarinus officinalis 'Miss Jessopp's Upright'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 365,
      chnn_stock_retail: 365,
      sppl_order_minimum: 3,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH2H6229;JR97TD2N',
      statusContent: 'dot',
      hash: '14CD84RS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14CD84RS'
    },
    {
      status: 210,
      sku: 'PM0070858',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307323',
      rtl_batch_array: '6307323',
      name_search: 'CLJPAWEL',
      name: "Clematis 'Jan Pawel II'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W9L9V8AS;RS2DARV1;VBY8TKHE;8PJ5ZSZN',
      statusContent: 'dot',
      hash: 'NV2RPY7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV2RPY7B'
    },
    {
      status: 210,
      sku: 'PM0081057',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338465',
      rtl_batch_array: '6338465',
      name_search: 'ROOPRAZ',
      name: "Rosmarinus officinalis 'Pointe du Raz'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 795,
      chnn_stock_retail: 795,
      sppl_order_minimum: 3,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WN672189',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WN672189'
    },
    {
      status: 210,
      sku: 'PM0070862',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307327',
      rtl_batch_array: '6307327',
      name_search: 'CLMHUNT',
      name: "Clematis 'Margaret Hunt'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 169,
      chnn_stock_retail: 169,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A61XV38J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A61XV38J'
    },
    {
      status: 210,
      sku: 'PM0070868',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307332',
      rtl_batch_array: '6307332',
      name_search: 'CLMBATEM',
      name: "Clematis 'Miss Bateman'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1673,
      chnn_stock_retail: 1673,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AGB8V3L4;ZB2RH1RT;J11CKA42;VSJEJEZ8',
      statusContent: 'dot',
      hash: 'EJS3DEPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJS3DEPJ'
    },
    {
      status: 210,
      sku: 'PM0070850',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307314',
      rtl_batch_array: '6307314',
      name_search: 'CLEVIOLE',
      name: "Clematis 'Etoile Violette'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N3TB5C9D;8KDRSYJK;86X81T46;13ZPS5VD;VTSCTAGE',
      statusContent: 'dot',
      hash: 'S8X96A7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8X96A7X'
    },
    {
      status: 210,
      sku: 'PM0070853',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307318',
      rtl_batch_array: '6307318',
      name_search: 'CLFSPRIN',
      name: "Clematis 'Fragrant Spring'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 5800,
      chnn_stock_retail: 5800,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XV5Z9LLB;YZ768L7E;NX2DDYK9',
      statusContent: 'dot',
      hash: 'YXTC528X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXTC528X'
    },
    {
      status: 210,
      sku: 'PM0081058',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338466',
      rtl_batch_array: '6338466',
      name_search: 'ROOPROST',
      name: "Rosmarinus officinalis 'Prostratus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1755,
      chnn_stock_retail: 1755,
      sppl_order_minimum: 3,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L7YDPZTP;A85BYZ6Z;VJHZ1AR1;6TTX2LKX;G96LGV7Y;HCBJPJW1;BXJ152H4;5E3N1XWA;TBNBCP3X;6GNN8W72;N73VWDDZ;JVL7D2KX;X9RXRWGJ;49G7JVWW;K1VRX8YG;H3G5X318;23EASCDH;T2YZ7KD7;LSJWJRRY;THA8RX26;TE1BVWHJ;ZPKT3PGD;YH251HAN;CHC4R2ZH;TD9KH3WJ;RD1L9K97',
      statusContent: 'dot',
      hash: '6WJXD7SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WJXD7SS'
    },
    {
      status: 210,
      sku: 'PM0081059',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338467',
      rtl_batch_array: '6338467',
      name_search: 'RODEPEND',
      name: 'Rostrinucula dependens',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_prcp: 3.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SKVR2XA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKVR2XA1'
    },
    {
      status: 810,
      sku: 'PM0077604',
      core_name: 'Plant',
      sppl_ean: '8720664807631',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849857',
      rtl_batch_array: '5849857',
      name_search: 'SAPSDANC',
      name: "Salvia pratensis 'Sky Dance'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SKX7VZ64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKX7VZ64'
    },
    {
      status: 210,
      sku: 'PM0081060',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338468',
      rtl_batch_array: '6338468',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_prcp: 1.666,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: 'H4PGENT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4PGENT2'
    },
    {
      status: 210,
      sku: 'PM0057974',
      core_name: 'Plant',
      sppl_ean: '8720664882898',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084681',
      rtl_batch_array: '6084681, 6353248',
      name_search: 'PUSDBIEL',
      name: "Pulmonaria saccharata 'Dora Bielefeld'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 737,
      chnn_stock_retail: 1457,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6JS8NL7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JS8NL7E'
    },
    {
      status: 210,
      sku: 'PM0070861',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307326',
      rtl_batch_array: '6307326',
      name_search: 'CLMJCORR',
      name: "Clematis 'Madame Julia Correvon'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V7T66HTH;PKAE469S;S4D5YD6B;R28B73TD;XBLTG9DX;WTH1E3N5',
      statusContent: 'dot',
      hash: 'R57GN7ZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R57GN7ZB'
    },
    {
      status: 210,
      sku: 'PM0070859',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307324',
      rtl_batch_array: '6307324',
      name_search: 'CLJUSTA',
      name: "Clematis 'Justa'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V11YYCVS;DLJS287K;B1PELXR2;NAYRGNAE;RT2Y6D2S;G8JKZJKC',
      statusContent: 'dot',
      hash: '453NXHR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '453NXHR7'
    },
    {
      status: 210,
      sku: 'PM0077589',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305158',
      rtl_batch_array: '6305158',
      name_search: 'HEMMASQU',
      name: "Hemerocallis 'Moonlit Masquerade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAVXE18C;PVR2RB78',
      statusContent: 'dot',
      hash: 'Y3L37DPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3L37DPJ'
    },
    {
      status: 210,
      sku: 'PM0077594',
      core_name: 'Plant',
      sppl_ean: '8720664549708',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6037901',
      rtl_batch_array: '6305167, 6037901',
      name_search: 'HOEWU',
      name: "Hosta 'Empress Wu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 409,
      chnn_stock_retail: 484,
      sppl_order_minimum: 3,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZ2VCYXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZ2VCYXW'
    },
    {
      status: 810,
      sku: 'PM0077610',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266266',
      rtl_batch_array: '6266266',
      name_search: 'SANRIANN',
      name: "Salvia nemorosa 'Rianne'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H73VXYA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H73VXYA1'
    },
    {
      status: 210,
      sku: 'PM0081061',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338469',
      rtl_batch_array: '6338469',
      name_search: 'RUBENEND',
      name: "Rubus 'Benenden'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EJ5P9VRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ5P9VRS'
    },
    {
      status: 210,
      sku: 'PM0081062',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338470',
      rtl_batch_array: '6338470',
      name_search: 'SABTORTU',
      name: "Salix babylonica 'Tortuosa'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 631,
      chnn_stock_retail: 631,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1SRL4T5S;R9WYZ3WS;4ECXE7RL;KDT5B48Z;9VL7NALX;ETZ9X84C;YXPAS58Y',
      statusContent: 'dot',
      hash: 'VSYRZ19N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSYRZ19N'
    },
    {
      status: 210,
      sku: 'PM0081063',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338471',
      rtl_batch_array: '6338471',
      name_search: 'SAEANGUS',
      name: "Salix elaeagnos 'Angustifolia'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2927,
      chnn_stock_retail: 2927,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DPP72JBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPP72JBL'
    },
    {
      status: 210,
      sku: 'PM0081064',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338472',
      rtl_batch_array: '6338472',
      name_search: 'SAGMASO',
      name: "Salix gracilistyla 'Mt. Aso'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6D3VW1V;WJRLV8YG;KZ72ZX5P;ZZS2JPXZ;617P4E5X;APZ9WDVE;5K7WYWES;6ZZBLXD9;7G5YGATB;TR2TTVG7;EK7K84DH;1PN7GRH8;D5PN6A8J',
      statusContent: 'dot',
      hash: 'C4WA3H5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4WA3H5W'
    },
    {
      status: 210,
      sku: 'PM0070866',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307330',
      rtl_batch_array: '6307330',
      name_search: 'CLMCOULT',
      name: "Clematis 'Mevrouw Le Coultre'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 834,
      chnn_stock_retail: 834,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RBG2WSD7;96EKLNXV;K1LKALN3;XKJ1Z373;BC1JNS6C',
      statusContent: 'dot',
      hash: 'EVYJARP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVYJARP4'
    },
    {
      status: 210,
      sku: 'PM0070847',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307311',
      rtl_batch_array: '6307311',
      name_search: 'CLDRUPPE',
      name: "Clematis 'Dr Ruppel'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PTLPD2WE;6TKPRDR1;61XG5KRB;AXG1XVLB;JVE91VYS;PXP74GKN;HYG5JXTG;1ZN1GCJ2;H8J8W2D6',
      statusContent: 'dot',
      hash: '4T9RA4Z3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T9RA4Z3'
    },
    {
      status: 210,
      sku: 'PM0070857',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307322',
      rtl_batch_array: '6307322',
      name_search: 'CLJACKMA',
      name: "Clematis 'Jackmanii'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1472,
      chnn_stock_retail: 1472,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WSPR3JDC;NDP77J4G;YCEDW2JB;PKKAAEB1;GTS3ZW5N;79GNYJYW;5WNHYCL8;H2YRSR9E',
      statusContent: 'dot',
      hash: 'SABAKB5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SABAKB5Z'
    },
    {
      status: 910,
      sku: 'PM0070863',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLMARJOR',
      name: "Clematis 'Marjorie'",
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore:
        'YZ86EHE1;HKGSYLJJ;7HHJ82RY;EA166CHR;KTS41VV8;N52LCCP8;T5JVVHDC;LB2NG52Z;4PXXHD4Z;ZHXC8LJ8;95XXYYPD;BWJJGWSC',
      statusContent: 'dot',
      hash: 'N8BPZLSL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N8BPZLSL'
    },
    {
      status: 210,
      sku: 'PM0070867',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307331',
      rtl_batch_array: '6307331',
      name_search: 'CLMIKELI',
      name: "Clematis 'Mikelite'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXWA7L34;GGKBDAC8',
      statusContent: 'dot',
      hash: 'KYY3T5C6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYY3T5C6'
    },
    {
      status: 210,
      sku: 'PM0045900',
      core_name: 'Plant',
      sppl_ean: '8720349490394',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015144',
      rtl_batch_array: '6015144',
      name_search: 'GESSSTAR',
      name: "Geranium sanguineum 'Shooting Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 391,
      chnn_stock_retail: 391,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4BRCXGC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BRCXGC6'
    },
    {
      status: 910,
      sku: 'PM0045212',
      core_name: 'Plant',
      sppl_ean: '8720664870192',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164761',
      rtl_batch_array: '5592225, 6164761',
      name_search: 'IRSSFLIG',
      name: "Iris sibirica 'Swan in Flight'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 10,
      chnn_stock_retail: 1010,
      sppl_prcp: 1.716,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A2SS5SZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2SS5SZD'
    },
    {
      status: 210,
      sku: 'PM0045225',
      core_name: 'Plant',
      sppl_ean: '8720353085968',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592255',
      rtl_batch_array: '5592255',
      name_search: 'ALMILLEN',
      name: "Allium 'Millennium'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VBDJ63T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBDJ63T7'
    },
    {
      status: 210,
      sku: 'PM0045231',
      core_name: 'Plant',
      sppl_ean: '8720626303294',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592267',
      rtl_batch_array: '5592267',
      name_search: 'HETOM',
      name: "Helenium 'Tom'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'CLHR7V3Y',
      statusContent: 'dot',
      hash: 'W85REB47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W85REB47'
    },
    {
      status: 210,
      sku: 'PM0070855',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307320',
      rtl_batch_array: '6307320',
      name_search: 'CLGPICAR',
      name: "Clematis 'Gladys Picard'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 769,
      chnn_stock_retail: 769,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4GDNN1AH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GDNN1AH'
    },
    {
      status: 910,
      sku: 'PM0060090',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307755',
      rtl_batch_array: '6307755',
      name_search: 'PINOGREE',
      name: "Pinus nigra 'Oregon Green'",
      sppl_size_code: '040050C12',
      rng_range_identifier: 'H040050C12',
      rng_range_description: 'H40 cm 50 cm C12',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBNK7RAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBNK7RAH'
    },
    {
      status: 210,
      sku: 'PM0070869',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307333',
      rtl_batch_array: '6307333',
      name_search: 'CLEMGRAN',
      name: 'Clematis montana grandiflora',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 3826,
      chnn_stock_retail: 3826,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZLV5DGE;ZLNSJBK3;P24P7D41;853J6TVR;SD2APLBW;7EW5TT7W;6S3SRHL5;HWJH3XH5;E5GDPDKP;Z64T2K5J;D7BL4YEB;W5LT3RLW;453BNKST;PE4Y2V2R;G346D7NA;RCVTTXX3;HGHGXHVP;S482G2LY;S715HA8J;L7HHS4ER;RHHPLD5A',
      statusContent: 'dot',
      hash: 'B5XH59AY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5XH59AY'
    },
    {
      status: 210,
      sku: 'PM0077404',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304747',
      rtl_batch_array: '6304747',
      name_search: 'MABTULIP',
      name: 'Magnolia Black Tulip',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 7677,
      chnn_stock_retail: 7677,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1XKGNX9',
      statusContent: 'dot',
      hash: 'C1X1KTE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1X1KTE6'
    },
    {
      status: 210,
      sku: 'PM0045139',
      core_name: 'Plant',
      sppl_ean: '8720664695450',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592043',
      rtl_batch_array: '5592043',
      name_search: 'IRSUNCOR',
      name: "Iris sibirica 'Uncorked'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'Y8TP8A3N',
      statusContent: 'dot',
      hash: '5K55ZZX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K55ZZX7'
    },
    {
      status: 910,
      sku: 'PM0070871',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLMWILSO',
      name: 'Clematis montana wilsonii',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore: 'RDCHEWDY',
      statusContent: 'dot',
      hash: 'EHBPPJDE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EHBPPJDE'
    },
    {
      status: 210,
      sku: 'PM0077405',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304748',
      rtl_batch_array: '6304748',
      name_search: 'MACLEOPA',
      name: "Magnolia 'Cleopatra'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1888,
      chnn_stock_retail: 1888,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRYJPW92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRYJPW92'
    },
    {
      status: 210,
      sku: 'PM0070872',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307800',
      rtl_batch_array: '6307800',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: 'GCSYH2J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCSYH2J1'
    },
    {
      status: 810,
      sku: 'PM0045306',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6230977',
      rtl_batch_array: '6230977, 6284294, 6348280',
      name_search: 'AQVRBARL',
      name: "Aquilegia vulgaris 'Rose Barlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 915,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A4RSTSLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4RSTSLN'
    },
    {
      status: 210,
      sku: 'PM0081065',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338473',
      rtl_batch_array: '6338473',
      name_search: 'SAHWEHRH',
      name: "Salix hastata 'Wehrhahnii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1652,
      chnn_stock_retail: 1652,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBBDRAW7;2BP21DZT',
      statusContent: 'dot',
      hash: 'ZH5PTDB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZH5PTDB5'
    },
    {
      status: 810,
      sku: 'PM0077406',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304749',
      rtl_batch_array: '6304749',
      name_search: 'MAEMPERO',
      name: 'Magnolia Emperor',
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 45.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P42S8RCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P42S8RCD'
    },
    {
      status: 210,
      sku: 'PM0077407',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304750',
      rtl_batch_array: '6304750',
      name_search: 'MAGALAXY',
      name: "Magnolia 'Galaxy'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1582,
      chnn_stock_retail: 1582,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CGT3NW7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGT3NW7T'
    },
    {
      status: 810,
      sku: 'PM0077408',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304751',
      rtl_batch_array: '6304751',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 45.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'XD3GGZHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD3GGZHB'
    },
    {
      status: 210,
      sku: 'PM0058003',
      core_name: 'Plant',
      sppl_ean: '8720664874855',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084790',
      rtl_batch_array: '6084790, 6301725',
      name_search: 'MOBLILAC',
      name: 'Monarda Balmy Lilac',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3279,
      chnn_stock_retail: 3333,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NHWPN326',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHWPN326'
    },
    {
      status: 210,
      sku: 'PM0074210',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370803',
      rtl_batch_array: '6370803, 6268493',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1315,
      chnn_stock_retail: 1815,
      sppl_order_minimum: 3,
      sppl_prcp: 2.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '8DR54ZTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DR54ZTS'
    },
    {
      status: 810,
      sku: 'PM0052450',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353581',
      rtl_batch_array: '6353581',
      name_search: 'VIMHVARI',
      name: "Vinca major 'Highland Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AWJGA41N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWJGA41N'
    },
    {
      status: 210,
      sku: 'PM0077409',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304752',
      rtl_batch_array: '6304752',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3636,
      chnn_stock_retail: 3636,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'SV15ED48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SV15ED48'
    },
    {
      status: 210,
      sku: 'PM0077410',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304753',
      rtl_batch_array: '6304753',
      name_search: 'MAGHKERN',
      name: "Magnolia 'George Henry Kern'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1543,
      chnn_stock_retail: 1543,
      sppl_prcp: 7.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDX3ZPEN;PDB2YP8N;E9GRPBCC',
      statusContent: 'dot',
      hash: 'DYC9C3BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYC9C3BC'
    },
    {
      status: 210,
      sku: 'PM0044985',
      core_name: 'Plant',
      sppl_ean: '8720349425235',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354826',
      rtl_batch_array: '5354826',
      name_search: 'PHTENOR',
      name: "Phlox (P) 'Tenor'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YNS9L4NH;YWZXG4A2;6XA6HBE5',
      imageBatch: 'T432H32X',
      statusContent: 'dot',
      hash: '6NT37A5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NT37A5V'
    },
    {
      status: 210,
      sku: 'PM0068505',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280177',
      rtl_batch_array: '6280177',
      name_search: 'THODANIC',
      name: "Thuja occidentalis 'Danica'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 3006,
      chnn_stock_retail: 3006,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8BKY3T6;XJHVS4RW;X1HJK86R;ALA6PH9S;RNK6445D;P4VJXZ64;CLH1AKYW;ND2ZDWH4;D2CKJAEB;P29431BK;CLPLYN3C;8XDHEV62;9RJ5XTBB;A1BBTE4T',
      statusContent: 'dot',
      hash: 'TWYLAWYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWYLAWYR'
    },
    {
      status: 910,
      sku: 'PM0081066',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAHELVET',
      name: 'Salix helvetica',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      imageCore:
        'TJ5JBW24;BT5S7K2P;2B9BCLRS;5CRLGZXN;WWGB29ZX;NVT3JN53;PJYPXYBG;TGP5D71N;YB5G5D43;1WN858EJ;YRHGJGSD;1TX6HN1V;WA9B981N;2XX2SB77;2C5WLHP1;2V8LGDC7;1BKAGWEZ;PLBXP6RJ;W7JWHBYW;TB7P1B8P;CXDPAJAT;YNGHH93W;WAPVE2B9',
      statusContent: 'dot',
      hash: 'R8NWJD7B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R8NWJD7B'
    },
    {
      status: 910,
      sku: 'PM0077414',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304757',
      rtl_batch_array: '6304757',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 45.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'HW61566Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HW61566Z'
    },
    {
      status: 210,
      sku: 'PM0045154',
      core_name: 'Plant',
      sppl_ean: '8720349419593',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592081',
      rtl_batch_array: '5592081',
      name_search: 'IRSDSTAN',
      name: "Iris sibirica 'Double Standard'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'APCLJDYG',
      statusContent: 'dot',
      hash: 'GTVW1YXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTVW1YXJ'
    },
    {
      status: 910,
      sku: 'PM0069920',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAULIGIN',
      name: 'Salvia uliginosa',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'E14AHAS2;96A69AGP;YCAEW9LR;TRGD9CGZ;Y2GVNPH3',
      statusContent: 'dot',
      hash: '7PKHCL8Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7PKHCL8Y'
    },
    {
      status: 210,
      sku: 'PM0060104',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307790',
      rtl_batch_array: '6307790',
      name_search: 'VACDUKE',
      name: "Vaccinium corymbosum 'Duke'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1ALCRK2',
      statusContent: 'dot',
      hash: 'RHSDTN3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHSDTN3G'
    },
    {
      status: 210,
      sku: 'PM0069919',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219490',
      rtl_batch_array: '6219490',
      name_search: 'PHOCHSEN',
      name: "Phlox (D) 'Ochsenblut'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2420,
      chnn_stock_retail: 2420,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6X4RZTX',
      statusContent: 'dot',
      hash: 'CDXZK3DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDXZK3DA'
    },
    {
      status: 210,
      sku: 'PM0077415',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304758',
      rtl_batch_array: '6304758',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1977,
      chnn_stock_retail: 1977,
      sppl_prcp: 7.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: '5YEELN9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YEELN9D'
    },
    {
      status: 810,
      sku: 'PM0070877',
      core_name: 'Plant',
      sppl_ean: '8720626314917',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837479',
      rtl_batch_array: '5837479',
      name_search: 'PERRUBY',
      name: "Penstemon 'Rich Ruby'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PY9BCG2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY9BCG2G'
    },
    {
      status: 210,
      sku: 'PM0045186',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226869',
      rtl_batch_array: '6226869',
      name_search: 'HOBMEARS',
      name: "Hosta 'Blue Mouse Ears'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2E1G5LHE;ND8B2WD1;7HK8YPC8;YHV8ZW21;SJ223CCC;1Z9LVYDX;YKWX35TB;4L29LAZP;1T89L37W;DG8HH4DJ;L231S4X5;SRYZBLRV;JRLPYE44;59GZS2S1;CJGLX6BL;7JXT42S1;H8N2X89E;955W7G92',
      statusContent: 'dot',
      hash: 'T3G5GYRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3G5GYRK'
    },
    {
      status: 210,
      sku: 'PM0063379',
      core_name: 'Plant',
      sppl_ean: '8720664888418',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6138184',
      rtl_batch_array: '6138184',
      name_search: 'THOGSMAR',
      name: 'Thuja occidentalis Golden Smaragd',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 556,
      chnn_stock_retail: 556,
      sppl_prcp: 7.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YXRWAKD;AS33KGY6;5JZEB95L;28KYJJCV;TCZ8BWKR',
      statusContent: 'dot',
      hash: '9PSX1D63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PSX1D63'
    },
    {
      status: 210,
      sku: 'PM0085061',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353130',
      rtl_batch_array: '6353130',
      name_search: 'PEAPTIME',
      name: "Perovskia atripl. 'Prime Time'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LL64KNV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL64KNV5'
    },
    {
      status: 910,
      sku: 'PM0077416',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304759',
      rtl_batch_array: '6304759',
      name_search: 'MAWATERM',
      name: 'Magnolia Watermelon',
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 45.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1KVAPP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1KVAPP2'
    },
    {
      status: 210,
      sku: 'PM0077417',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304760',
      rtl_batch_array: '6304760',
      name_search: 'MAYLANTE',
      name: "Magnolia 'Yellow Lantern'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2855,
      chnn_stock_retail: 2855,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WKDHXD6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKDHXD6W'
    },
    {
      status: 210,
      sku: 'PM0077418',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304761',
      rtl_batch_array: '6304761',
      name_search: 'MAJHIVER',
      name: "Mahonia japonica 'Hivernant'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 928,
      chnn_stock_retail: 928,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XCYSLX2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCYSLX2N'
    },
    {
      status: 210,
      sku: 'PM0077419',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304766',
      rtl_batch_array: '6304766',
      name_search: 'NADMLLIM',
      name: 'Nandina domestica Magical Lemon & Lime',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 16929,
      chnn_stock_retail: 16929,
      sppl_prcp: 4.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVY8RD8G;G99N52BD;447JW7Z1;ZJBGXJ9W;K5R4AZL3;6HEXD3D3',
      statusContent: 'dot',
      hash: 'TZN53EA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZN53EA4'
    },
    {
      status: 210,
      sku: 'PM0077422',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304769',
      rtl_batch_array: '6304769',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1321,
      chnn_stock_retail: 1321,
      sppl_prcp: 12.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '6YSNA93B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YSNA93B'
    },
    {
      status: 210,
      sku: 'PM0077423',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304771',
      rtl_batch_array: '6304771',
      name_search: 'PAVREHBR',
      name: "Panicum virgatum 'Rehbraun'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_prcp: 6.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTPBCVG1;BDLXK19Y;AVX56D5S;7RH9XSJ5;1XCWN1HP;2D2J95VP;DHYCCDXV;2YJN9CAT;2BR3BZTA;D1K77WWG;7RDRGPE4;KLY9228P',
      statusContent: 'dot',
      hash: '57H6RAR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57H6RAR7'
    },
    {
      status: 210,
      sku: 'PM0077424',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304773',
      rtl_batch_array: '6304773',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 3.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: '9SY7DW6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SY7DW6K'
    },
    {
      status: 210,
      sku: 'PM0057985',
      core_name: 'Plant',
      sppl_ean: '8720664869837',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084729',
      rtl_batch_array: '6084729',
      name_search: 'IRHBLUE',
      name: "Iris (G) 'Harbor Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 1.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '63KKASTA;TYDETJPG',
      statusContent: 'dot',
      hash: 'KXP9D1J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXP9D1J1'
    },
    {
      status: 210,
      sku: 'PM0081067',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338475',
      rtl_batch_array: '6338475',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 798,
      chnn_stock_retail: 798,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: '8EVNZWPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EVNZWPR'
    },
    {
      status: 210,
      sku: 'PM0077425',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304774',
      rtl_batch_array: '6304774',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'JBPTNLLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBPTNLLD'
    },
    {
      status: 210,
      sku: 'PM0045128',
      core_name: 'Plant',
      sppl_ean: '8720626305366',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592011',
      rtl_batch_array: '5592011',
      name_search: 'HOTWILIG',
      name: "Hosta 'Twilight'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5X1DPZL;VT8KWPHT;G4A5XS4Z',
      imageBatch: 'T238SEZL',
      statusContent: 'dot',
      hash: 'Z7Z4PG5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7Z4PG5J'
    },
    {
      status: 210,
      sku: 'PM0077426',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304775',
      rtl_batch_array: '6304775, 5214473',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3064,
      chnn_stock_retail: 3564,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'CRCZ64TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRCZ64TN'
    },
    {
      status: 210,
      sku: 'PM0077612',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305203',
      rtl_batch_array: '6305203',
      name_search: 'ACCVARIE',
      name: "Acorus calamus 'Variegatus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '99J326KZ;86G111GC;2VJA8ANN;ZJ4P5S33;CL7A4G54;KLN9S8SE',
      statusContent: 'dot',
      hash: 'X68EETRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X68EETRS'
    },
    {
      status: 210,
      sku: 'PM0045000',
      core_name: 'Plant',
      sppl_ean: '8720349423286',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5387848',
      rtl_batch_array: '5387848',
      name_search: 'MOBSPICE',
      name: "Monarda 'Baby Spice'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'VGXZBVLT',
      statusContent: 'dot',
      hash: '6V4AC83V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6V4AC83V'
    },
    {
      status: 210,
      sku: 'PM0060039',
      core_name: 'Plant',
      sppl_ean: '8720664694934',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112478',
      rtl_batch_array: '6112478',
      name_search: 'IRIBUTTE',
      name: "Iris (G) 'Butterfly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ELRV5D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ELRV5D9'
    },
    {
      status: 210,
      sku: 'PM0045634',
      core_name: 'Plant',
      sppl_ean: '8720664688049',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014939',
      rtl_batch_array: '6014939',
      name_search: 'ECLRED',
      name: 'Echinacea Lakota Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1849,
      chnn_stock_retail: 1849,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J2WER9V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2WER9V5'
    },
    {
      status: 210,
      sku: 'PM0085062',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354188',
      rtl_batch_array: '6354188',
      name_search: 'RHBOLLYW',
      name: 'Rhododendron (AJ) Bollywood',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 8.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJDHAZW4;9PKTYB3S;5V1SWKA1;J845K9HV;2NZW7XLL;DYEH624P',
      statusContent: 'dot',
      hash: 'PR1BZ84N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR1BZ84N'
    },
    {
      status: 210,
      sku: 'PM0077614',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305205',
      rtl_batch_array: '6305205',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: '2XKT5S9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XKT5S9L'
    },
    {
      status: 210,
      sku: 'PM0045100',
      core_name: 'Plant',
      sppl_ean: '8720349410040',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591949',
      rtl_batch_array: '5591949',
      name_search: 'ASPBLOSS',
      name: "Astilbe (J) 'Peach Blossom'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1VELE5R1;JV6RCRBR',
      imageBatch: '1XC4P89S',
      statusContent: 'dot',
      hash: '5TGLCWLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TGLCWLZ'
    },
    {
      status: 210,
      sku: 'PM0045521',
      core_name: 'Plant',
      sppl_ean: '8720664856738',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147711',
      rtl_batch_array: '6147711, 6301512',
      name_search: 'CHVPIERR',
      name: "Chrysogonum virginianum 'Pierre'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 178,
      chnn_stock_retail: 385,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NED9H8PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NED9H8PE'
    },
    {
      status: 210,
      sku: 'PM0045299',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266375',
      rtl_batch_array: '6266375',
      name_search: 'AQSMYELL',
      name: "Aquilegia 'Spring Magic Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 569,
      chnn_stock_retail: 569,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DGK6TTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DGK6TTG'
    },
    {
      status: 210,
      sku: 'PM0077615',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305206',
      rtl_batch_array: '6305206, 6300853',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 151,
      chnn_stock_retail: 912,
      sppl_prcp: 4.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '7Z3BNBX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z3BNBX5'
    },
    {
      status: 210,
      sku: 'PM0081068',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338476',
      rtl_batch_array: '6338476',
      name_search: 'SAUGSUNS',
      name: "Salix udensis 'Golden Sunshine'",
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YCHWAVWZ;NS8XN3LN;4D1E55KS;CYWWRVJ4;17AC9NLA;ZVXXXTNS;VXK4814N',
      statusContent: 'dot',
      hash: 'PVRAAD8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVRAAD8A'
    },
    {
      status: 210,
      sku: 'PM0070876',
      core_name: 'Plant',
      sppl_ean: '8720626334939',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837394',
      rtl_batch_array: '5837394',
      name_search: 'ARSNANA',
      name: "Artemisia schmidtiana 'Nana'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1231,
      chnn_stock_retail: 1231,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JL7PP2R9;9YKBT6BG;GG617GS7;8EY4KJ81;DSCHZ4G2',
      statusContent: 'dot',
      hash: 'N2VT7WWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2VT7WWL'
    },
    {
      status: 210,
      sku: 'PM0045138',
      core_name: 'Plant',
      sppl_ean: '8720664695429',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592042',
      rtl_batch_array: '5592042',
      name_search: 'IRSPAPRI',
      name: "Iris sibirica 'Paprikash'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'P5AR85SH',
      statusContent: 'dot',
      hash: 'AE9ZSPWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AE9ZSPWT'
    },
    {
      status: 210,
      sku: 'PM0077617',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305208',
      rtl_batch_array: '6305208',
      name_search: 'EUFHBLAZ',
      name: "Euonymus fortunei 'Hort's Blaze'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3573,
      chnn_stock_retail: 3573,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BWLY721',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BWLY721'
    },
    {
      status: 910,
      sku: 'PM0045163',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECPDDECK',
      name: "Echinacea purpurea 'Double-Decker'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: '5NS4B1HS;BXRA4R48;43BSBDWD',
      statusContent: 'dot',
      hash: 'W4YY1L5L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W4YY1L5L'
    },
    {
      status: 210,
      sku: 'PM0077618',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305209',
      rtl_batch_array: '6305209, 6338999',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3733,
      chnn_stock_retail: 9253,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: '649W3HYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '649W3HYN'
    },
    {
      status: 210,
      sku: 'PM0077619',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305211',
      rtl_batch_array: '6305211',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 9.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'SENVGSZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SENVGSZH'
    },
    {
      status: 210,
      sku: 'PM0045115',
      core_name: 'Plant',
      sppl_ean: '8720626305342',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591976',
      rtl_batch_array: '5591976',
      name_search: 'HOTCHIP',
      name: "Hosta 'Tortilla Chip'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '87BP1EYC',
      statusContent: 'dot',
      hash: 'Y7K4CZ5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7K4CZ5R'
    },
    {
      status: 210,
      sku: 'PM0077421',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304768',
      rtl_batch_array: '6304768',
      name_search: 'NADTWILI',
      name: "Nandina domestica 'Twilight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_prcp: 4.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WGYXCN6V;SSRVJ5RR;YXZAXPN4;XBXRSJSX;DTK851ES',
      statusContent: 'dot',
      hash: '1LTHGKV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LTHGKV7'
    },
    {
      status: 210,
      sku: 'PM0077420',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304767',
      rtl_batch_array: '6304767',
      name_search: 'NADBPINK',
      name: 'Nandina domestica Blush Pink',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1429,
      chnn_stock_retail: 1429,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D9ZWNXGA;T5XJZBGR;E9VZGB8B;LHJ4JWYD;A2E84DZB;GRAGJBYL;7VVEAEHA;RYNYRYLN',
      statusContent: 'dot',
      hash: 'BYXCP49L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYXCP49L'
    },
    {
      status: 210,
      sku: 'PM0077613',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305204',
      rtl_batch_array: '6305204',
      name_search: 'ACGOBORO',
      name: "Acorus gramineus 'Oborozuki'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1004,
      chnn_stock_retail: 1004,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '67T9BA9G',
      statusContent: 'dot',
      hash: 'LJWJL8C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJWJL8C3'
    },
    {
      status: 210,
      sku: 'PM0081069',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338477',
      rtl_batch_array: '6338477',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 3493,
      chnn_stock_retail: 3493,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'LDTDRAAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDTDRAAP'
    },
    {
      status: 210,
      sku: 'PM0077620',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305213',
      rtl_batch_array: '6305213',
      name_search: 'ANTSYLVE',
      name: 'Anthriscus sylvestris',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1220,
      chnn_stock_retail: 1220,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7C198JP',
      statusContent: 'dot',
      hash: '1HAJPLCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HAJPLCZ'
    },
    {
      status: 210,
      sku: 'PM0070870',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307334',
      rtl_batch_array: '6307334',
      name_search: 'CLMRUBEN',
      name: 'Clematis montana rubens',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 5976,
      chnn_stock_retail: 5976,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '653ELA66;EZRBR1C5;W2DN157C;BRD5WXZB;KL6DCDYP;YWG2DA1G;LECN6WJV;DYYJ2CKK;NYJX7SLJ;GZN2P7EL;YNS3XDXT;6PWPLRYJ;9TGGSAX3',
      statusContent: 'dot',
      hash: 'Y2A62K9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2A62K9V'
    },
    {
      status: 210,
      sku: 'PM0068484',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266354',
      rtl_batch_array: '6266354',
      name_search: 'THOTTIM',
      name: "Thuja occidentalis 'Tiny Tim'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2299,
      chnn_stock_retail: 2299,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2GN55EXS;BB4L3EGC;HCJCETDG;LDJZZC8X;L98DJ7YD;PEJG8Z2C;D1DCNRPN;7KREZ931;WAWALLCV;CJGE14G4;JEG7769A;BRAXLW3L;1DEDGW4T;S979L11H;CVLWZC74;S115657X;BWPZ1LLA;PKPG18XS',
      statusContent: 'dot',
      hash: 'PRJN9XPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRJN9XPB'
    },
    {
      status: 210,
      sku: 'PM0056994',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386092',
      rtl_batch_array: '6301360, 6386092',
      name_search: 'ACCLYPEO',
      name: 'Achillea clypeolata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 228,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J56V3BS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J56V3BS2'
    },
    {
      status: 210,
      sku: 'PM0045103',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267082',
      rtl_batch_array: '5591958, 6267082',
      name_search: 'ECPARADO',
      name: 'Echinacea paradoxa',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4979,
      chnn_stock_retail: 5979,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7T48G7T1;RWZV59SR;9Y94W7JD;22R919KW',
      statusContent: 'dot',
      hash: '2EB4EHRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EB4EHRC'
    },
    {
      status: 210,
      sku: 'PM0077621',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305214',
      rtl_batch_array: '6305214',
      name_search: 'ANSRAVEN',
      name: "Anthriscus sylvestris 'Ravenswing'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1014,
      chnn_stock_retail: 1014,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX6V55EW',
      statusContent: 'dot',
      hash: 'XGVDE5KT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGVDE5KT'
    },
    {
      status: 210,
      sku: 'PM0045167',
      core_name: 'Plant',
      sppl_ean: '8720626303799',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592117',
      rtl_batch_array: '5592117',
      name_search: 'HEBFERRI',
      name: "Hemerocallis 'Bertie Ferris'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'NAGSJBVX',
      statusContent: 'dot',
      hash: 'PWYZZAJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWYZZAJP'
    },
    {
      status: 910,
      sku: 'PM0077616',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      rng_range_identifier: 'H060100C7.5',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'NXV5CZ8N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NXV5CZ8N'
    },
    {
      status: 210,
      sku: 'PM0057961',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202329',
      rtl_batch_array: '6202329, 6295796',
      name_search: 'PHMOERHE',
      name: "Phlox (S) 'Moerheimii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 543,
      chnn_stock_retail: 650,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KWK5D2PG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWK5D2PG'
    },
    {
      status: 210,
      sku: 'PM0057992',
      core_name: 'Plant',
      sppl_ean: '8720664872059',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084758',
      rtl_batch_array: '6084758',
      name_search: 'LELASPID',
      name: "Leucanthemum (S) 'Laspider'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1260,
      chnn_stock_retail: 1260,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JGC115T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGC115T6'
    },
    {
      status: 210,
      sku: 'PM0077412',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304755',
      rtl_batch_array: '6304755',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2373,
      chnn_stock_retail: 2373,
      sppl_prcp: 7.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: 'C1HZ42GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1HZ42GL'
    },
    {
      status: 210,
      sku: 'PM0077411',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304754',
      rtl_batch_array: '6304754',
      name_search: 'MASSATIS',
      name: "Magnolia soulangeana 'Satisfaction'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '25V2EEDW;CLSP67PN',
      statusContent: 'dot',
      hash: 'PLCK235N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLCK235N'
    },
    {
      status: 210,
      sku: 'PM0077413',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304756',
      rtl_batch_array: '6304756',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1886,
      chnn_stock_retail: 1886,
      sppl_prcp: 7.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'K51VRY5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K51VRY5S'
    },
    {
      status: 210,
      sku: 'PM0081070',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338478',
      rtl_batch_array: '6338478',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2188,
      chnn_stock_retail: 2188,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'AEBT5H7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEBT5H7D'
    },
    {
      status: 210,
      sku: 'PM0069921',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219494',
      rtl_batch_array: '6219494',
      name_search: 'SEHERBST',
      name: "Sedum 'Herbstfreude'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 19817,
      chnn_stock_retail: 19817,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P1SA8853;SP34B88L;KDVZDRVP;TSXB4ZGP;PL3Z54Y6;6R64C6KZ;WTZ1ALZ3;NCR16GGC;BT3LJ6TC;XACL25K2;LNBHRD3P;A53L33R4;R8BANN8D;97LVH7GN;4WAX5TZP;E1NNJE6Z;4VZ2LD1K;48YCYHLX;BALG54TJ',
      statusContent: 'dot',
      hash: 'Y6JJ1W7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6JJ1W7G'
    },
    {
      status: 210,
      sku: 'PM0068896',
      core_name: 'Plant',
      sppl_ean: '8720353028002',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5214420',
      rtl_batch_array: '5214420, 6336132',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 494,
      chnn_stock_retail: 1493,
      sppl_prcp: 5.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      imageBatch: '1S195VGB',
      statusContent: 'dot',
      hash: 'PDW2RP9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDW2RP9S'
    },
    {
      status: 210,
      sku: 'PM0057999',
      core_name: 'Plant',
      sppl_ean: '8720664873544',
      btch_manufacturer: 158,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164875',
      rtl_batch_array: '6176527, 6242040, 6164875, 6164331',
      name_search: 'LUSYLVAT',
      name: 'Luzula sylvatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 3832,
      sppl_order_minimum: 3,
      sppl_prcp: 0.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YW67DVWP',
      statusContent: 'dot',
      hash: 'GV9TB4J6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GV9TB4J6'
    },
    {
      status: 210,
      sku: 'PM0045101',
      core_name: 'Plant',
      sppl_ean: '8720349410132',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591950',
      rtl_batch_array: '5591950',
      name_search: 'ASCSPOTL',
      name: "Astilbe chinensis 'Spotlight'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'GY6WXXWG',
      statusContent: 'dot',
      hash: '6Y9E3X9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Y9E3X9V'
    },
    {
      status: 210,
      sku: 'PM0045108',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6191447',
      rtl_batch_array: '5591965, 6191447',
      name_search: 'GEPSAMOB',
      name: "Geranium phaeum 'Samobor'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1672,
      chnn_stock_retail: 2672,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTYASSVB;PYXVCNTK',
      statusContent: 'dot',
      hash: 'BBEN65CA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBEN65CA'
    },
    {
      status: 210,
      sku: 'PM0045257',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164350',
      rtl_batch_array: '6084985, 6053965, 6301370, 6164350',
      name_search: 'ACHSVARI',
      name: "Aconitum henryi 'Spark's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 761,
      chnn_stock_retail: 3976,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G3CJ4XJ1',
      statusContent: 'dot',
      hash: '83JGVDWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83JGVDWK'
    },
    {
      status: 210,
      sku: 'PM0045236',
      core_name: 'Plant',
      sppl_ean: '8720664681347',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5673365',
      rtl_batch_array: '5673365',
      name_search: 'ASDSSPRI',
      name: "Astilbe 'Darwin's Snow Sprite'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'VE1XNW8E',
      statusContent: 'dot',
      hash: 'AARE3AXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AARE3AXE'
    },
    {
      status: 810,
      sku: 'PM0045906',
      core_name: 'Plant',
      sppl_ean: '8720664691087',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6015156',
      rtl_batch_array: '6301620, 6015156',
      name_search: 'GEGIMLET',
      name: "Geum 'Gimlet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 117,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SSYS4LTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSYS4LTE'
    },
    {
      status: 210,
      sku: 'PM0068897',
      core_name: 'Plant',
      sppl_ean: '8720349485246',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5214423',
      rtl_batch_array: '5214423, 6336133',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 488,
      chnn_stock_retail: 1487,
      sppl_prcp: 5.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      imageBatch: 'BWEWZ581',
      statusContent: 'dot',
      hash: '84ETVL92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84ETVL92'
    },
    {
      status: 210,
      sku: 'PM0081071',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338479',
      rtl_batch_array: '6338479',
      name_search: 'SANBTOWE',
      name: 'Sambucus nigra Black Tower',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 6411,
      chnn_stock_retail: 6411,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '443DVLWA;CYXXJ6E8;KV2A5HYK;BCV14524;D5EY9XN9;G34YXXSK;D6398STY;HL5TN4T1;1B1446B7;1B5DH2ZJ;TYG49Z6A;XGVKGY5V;ZPZYXXCE',
      statusContent: 'dot',
      hash: '79DEP661',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79DEP661'
    },
    {
      status: 210,
      sku: 'PM0081072',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338480',
      rtl_batch_array: '6338480',
      name_search: 'SANGTOWE',
      name: 'Sambucus nigra Golden Tower',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2075,
      chnn_stock_retail: 2075,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VTP8L3DB',
      statusContent: 'dot',
      hash: 'C6VDZELY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6VDZELY'
    },
    {
      status: 210,
      sku: 'PM0081073',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338481',
      rtl_batch_array: '6338481',
      name_search: 'SANTHUND',
      name: "Sambucus nigra 'Thundercloud'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 621,
      chnn_stock_retail: 621,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SYH7NYZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYH7NYZ8'
    },
    {
      status: 810,
      sku: 'PM0081074',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338482',
      rtl_batch_array: '6338482',
      name_search: 'SARPAURE',
      name: "Sambucus racemosa 'Plumosa Aurea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4CNKBACZ',
      statusContent: 'dot',
      hash: 'AXS8DAYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXS8DAYW'
    },
    {
      status: 210,
      sku: 'PM0081075',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338483',
      rtl_batch_array: '6338483',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1317,
      chnn_stock_retail: 1317,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      statusContent: 'dot',
      hash: 'A17WZXJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A17WZXJP'
    },
    {
      status: 210,
      sku: 'PM0081076',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338485',
      rtl_batch_array: '6338485',
      name_search: 'SAHUMILI',
      name: 'Salix humilis',
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 1390,
      chnn_stock_retail: 1390,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '76RY16SP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76RY16SP'
    },
    {
      status: 210,
      sku: 'PM0081077',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338487',
      rtl_batch_array: '6338487',
      name_search: 'SARUSCIF',
      name: 'Sarcococca ruscifolia',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3435,
      chnn_stock_retail: 3435,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R9JTP1R2;84KEP91T;AXX8GSH7;YP6DSTPT',
      statusContent: 'dot',
      hash: 'Y9C4NLB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9C4NLB8'
    },
    {
      status: 210,
      sku: 'PM0081078',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338488',
      rtl_batch_array: '6338488',
      name_search: 'SASALIGN',
      name: 'Sarcococca saligna',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 618,
      chnn_stock_retail: 618,
      sppl_order_minimum: 3,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B55P3E9B;4CVD4RZJ;9351GWL3',
      statusContent: 'dot',
      hash: '12XCRWLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12XCRWLC'
    },
    {
      status: 210,
      sku: 'PM0081079',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338489',
      rtl_batch_array: '6338489',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2223,
      chnn_stock_retail: 2223,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'V4TDG83C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4TDG83C'
    },
    {
      status: 210,
      sku: 'PM0081080',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338490',
      rtl_batch_array: '6338490',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1898,
      chnn_stock_retail: 1898,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'HHDXACYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHDXACYZ'
    },
    {
      status: 210,
      sku: 'PM0081081',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338491',
      rtl_batch_array: '6338491',
      name_search: 'SPJCRISP',
      name: "Spiraea japonica 'Crispa'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 584,
      chnn_stock_retail: 584,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XRBTK1XK;6639Z47Y',
      statusContent: 'dot',
      hash: 'T8SSX5SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8SSX5SS'
    },
    {
      status: 210,
      sku: 'PM0069923',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219496',
      rtl_batch_array: '6219496',
      name_search: 'SECLIDAK',
      name: "Sedum cauticola 'Lidakense'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 846,
      chnn_stock_retail: 846,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GZBRLZHE;CYPYEWYK;XPZHS35V;6D7BBJTB;Z1LA6DHC;DJVC4YNT;11TDXX2L;7P1V9C96;S7LK3DJL;D22LJS4E;GXD7SGPD',
      statusContent: 'dot',
      hash: '2B9TJW3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B9TJW3V'
    },
    {
      status: 210,
      sku: 'PM0081082',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338492',
      rtl_batch_array: '6338492',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2362,
      chnn_stock_retail: 2362,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'P1T4T8ST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1T4T8ST'
    },
    {
      status: 210,
      sku: 'PM0081083',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338493',
      rtl_batch_array: '6338493',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1571,
      chnn_stock_retail: 1571,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'TSJ86BHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSJ86BHV'
    },
    {
      status: 210,
      sku: 'PM0081084',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338494',
      rtl_batch_array: '6338494',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1106,
      chnn_stock_retail: 1106,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'L86ZH7HE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L86ZH7HE'
    },
    {
      status: 810,
      sku: 'PM0069924',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219497',
      rtl_batch_array: '6219497',
      name_search: 'SEKVARIE',
      name: "Sedum kamtschaticum 'Variegatum'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7VABV22X;JPPAYZG4;44EXWADG;TZZRWAS6;1KBY2DPT;911585LG;C1ZBKLZY;647DLRKP;V3WW7RBB;NB3EZR3R',
      statusContent: 'dot',
      hash: '56GXVKGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56GXVKGR'
    },
    {
      status: 210,
      sku: 'PM0069925',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219498',
      rtl_batch_array: '6219498',
      name_search: 'SEMAKINO',
      name: 'Sedum makinoi',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3024,
      chnn_stock_retail: 3024,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YNGV16LR;WSC6HHE2;XWZ5AZ96;HL47ZXLC;V1N2S2E5;B64HAD3P;551A22Z2;AARSCN21;WNTYNRW5;EBNCB6XP;1G9Y4H7R;LY1EYLTG;6578GW72;LT932GV1;C54EP77Y',
      statusContent: 'dot',
      hash: '7SKE6X45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SKE6X45'
    },
    {
      status: 210,
      sku: 'PM0045276',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242088',
      rtl_batch_array: '6242088',
      name_search: 'AGVSARTH',
      name: "Agapanthus 'Vall\u00e9e de Sarthe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N4EKTHDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4EKTHDT'
    },
    {
      status: 210,
      sku: 'PM0045188',
      core_name: 'Plant',
      sppl_ean: '8720349409839',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592166',
      rtl_batch_array: '5592166',
      name_search: 'ASBARBAD',
      name: "Aster 'Barbados'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NNP6HDXJ;9EG9K186;1SHK7XGJ;P4229LRY;C73NER94',
      imageBatch: 'S8P519X1',
      statusContent: 'dot',
      hash: 'D6SS4W5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6SS4W5S'
    },
    {
      status: 210,
      sku: 'PM0068509',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205389',
      rtl_batch_array: '6205389',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 796,
      chnn_stock_retail: 796,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      statusContent: 'dot',
      hash: 'LZC1PN1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZC1PN1B'
    },
    {
      status: 210,
      sku: 'PM0060056',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284933',
      rtl_batch_array: '6284933',
      name_search: 'CLFSPRIN',
      name: "Clematis 'Fragrant Spring'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XV5Z9LLB;YZ768L7E;NX2DDYK9',
      statusContent: 'dot',
      hash: 'DYCCN72K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYCCN72K'
    },
    {
      status: 210,
      sku: 'PM0046637',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267599',
      rtl_batch_array: '6267599',
      name_search: 'ASBAHAMA',
      name: "Aster 'Bahamas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZGGAB39;CYHG69K5;TPDLJB9Y;YHERR7RY;NVWK4PZ1;2ZLJ8TH1;9R9HSLW5;X2DNY861;XLYXP7GH',
      statusContent: 'dot',
      hash: '1Y9AGV9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y9AGV9D'
    },
    {
      status: 210,
      sku: 'PM0057987',
      core_name: 'Plant',
      sppl_ean: '8720664695177',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084732',
      rtl_batch_array: '6084732',
      name_search: 'IRREDITI',
      name: "Iris (G) 'Rare Edition'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1220,
      chnn_stock_retail: 1220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K6B791A6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6B791A6'
    },
    {
      status: 210,
      sku: 'PM0044982',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153769',
      rtl_batch_array: '6153769',
      name_search: 'PHBEYES',
      name: "Phlox (P) 'Bright Eyes'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8VVS9HNH;6SCP614V;TZBJD7R3;KWXNT1DW;LDX4KEBV;G6D6AV6R;B8C5KYBG;ARW4WT54;L8JC3TTB;WDE57CL1;D2L7J888',
      statusContent: 'dot',
      hash: 'T3LT2AB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3LT2AB2'
    },
    {
      status: 210,
      sku: 'PM0063437',
      core_name: 'Plant',
      sppl_ean: '8720626313064',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5358147',
      rtl_batch_array: '5358147',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      imageBatch: 'EPYZJ89S',
      statusContent: 'dot',
      hash: 'V8LRTTC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8LRTTC8'
    },
    {
      status: 210,
      sku: 'PM0045102',
      core_name: 'Plant',
      sppl_ean: '8720349410002',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591951',
      rtl_batch_array: '5591951',
      name_search: 'ASEVEEN',
      name: "Astilbe (J) 'Elisabeth van Veen'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GXPZPEV;28G8HB6K;2YLLNAE1;96T6RWSL;CWZBH4ZR',
      imageBatch: '6GGHGST4',
      statusContent: 'dot',
      hash: 'XK1H6BND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XK1H6BND'
    },
    {
      status: 210,
      sku: 'PM0058035',
      core_name: 'Plant',
      sppl_ean: '8720664687448',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084888',
      rtl_batch_array: '6084888',
      name_search: 'DIPDPINK',
      name: "Dianthus p. 'Dinetta Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9476,
      chnn_stock_retail: 9476,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W35P6795',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W35P6795'
    },
    {
      status: 210,
      sku: 'PM0068518',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260678',
      rtl_batch_array: '6260678',
      name_search: 'CREMCKEN',
      name: "Crocosmia 'Emily McKenzie'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 308,
      chnn_stock_retail: 308,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DC69V24L;YH7EWXYY;7T621Y78',
      statusContent: 'dot',
      hash: 'T3GCT1S4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3GCT1S4'
    },
    {
      status: 810,
      sku: 'PM0052762',
      core_name: 'Plant',
      sppl_ean: '8720664802186',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6041216',
      rtl_batch_array: '6041216',
      name_search: 'PIAFROHB',
      name: "Picea abies 'Frohburg'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9S3D8KK',
      statusContent: 'dot',
      hash: 'KS4N1EA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS4N1EA3'
    },
    {
      status: 210,
      sku: 'PM0081085',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338495',
      rtl_batch_array: '6338495',
      name_search: 'SPJGOLDM',
      name: "Spiraea japonica 'Goldmound'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1097,
      chnn_stock_retail: 1097,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PWTVET5X',
      statusContent: 'dot',
      hash: 'T5XX2PJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5XX2PJ9'
    },
    {
      status: 210,
      sku: 'PM0077427',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6310328',
      rtl_batch_array: '6304776, 6310328, 6341911',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2300,
      chnn_stock_retail: 7398,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: '8T4HXZY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8T4HXZY8'
    },
    {
      status: 210,
      sku: 'PM0085063',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354769',
      rtl_batch_array: '6354769',
      name_search: 'ABGTCHAR',
      name: 'Abelia grandifl. Tricolor Charm',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 2.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8S4EXN86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S4EXN86'
    },
    {
      status: 210,
      sku: 'PM0081086',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338496',
      rtl_batch_array: '6338496',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1436,
      chnn_stock_retail: 1436,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '8G73BKNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G73BKNN'
    },
    {
      status: 210,
      sku: 'PM0077430',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304779',
      rtl_batch_array: '6304779',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 10422,
      chnn_stock_retail: 10422,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: 'E6EL13XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6EL13XB'
    },
    {
      status: 210,
      sku: 'PM0077432',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304781',
      rtl_batch_array: '6304781',
      name_search: 'PIJBONFI',
      name: "Pieris japonica 'Bonfire'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1292,
      chnn_stock_retail: 1292,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DEBYHSP6;NE42JZBH;PTYAZLJE;TJYRW5GJ;PAAPTBDT;GWHTY7X7;GJPL62LD;SNNC9XGJ;YXT4SCR5;SERY8H6V;4T61H7VG',
      statusContent: 'dot',
      hash: '8NS68BSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NS68BSN'
    },
    {
      status: 210,
      sku: 'PM0008253',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6244275',
      rtl_batch_array: '6220372, 6244275, 6252512, 6336101',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5670,
      chnn_stock_retail: 8069,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      statusContent: 'dot',
      hash: 'PA77LJ83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA77LJ83'
    },
    {
      status: 210,
      sku: 'PM0046685',
      core_name: 'Plant',
      sppl_ean: '8720664695337',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875119',
      rtl_batch_array: '5875119',
      name_search: 'IRRHEART',
      name: "Iris (P) 'Red Heart'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'LSPRWPKB',
      statusContent: 'dot',
      hash: 'D42KPP8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D42KPP8G'
    },
    {
      status: 910,
      sku: 'PM0074211',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '7A7TDNWB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7A7TDNWB'
    },
    {
      status: 210,
      sku: 'PM0045489',
      core_name: 'Plant',
      sppl_ean: '8720664691940',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5741217',
      rtl_batch_array: '5741217',
      name_search: 'HEBONANZ',
      name: "Hemerocallis 'Bonanza'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CSAWDCLZ',
      imageBatch: 'HGJWH1WD',
      statusContent: 'dot',
      hash: 'R13BRHN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R13BRHN2'
    },
    {
      status: 210,
      sku: 'PM0077434',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304783',
      rtl_batch_array: '6304783',
      name_search: 'PIJLFROS',
      name: "Pieris japonica 'Little Frosty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3053,
      chnn_stock_retail: 3053,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '64WTNZ2G;E9CVBWHE;PCBBBVRX;YWH1HAZS;VE3J7ZRA;87Y9KCKS;YRNNKEXB;BGZR791C;A15BKH5C;LVZCCKJN;C93BJ7PK;C6WLC65R',
      statusContent: 'dot',
      hash: 'GXXG2GCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXXG2GCJ'
    },
    {
      status: 810,
      sku: 'PM0016293',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 9,
      btch_active_retail: '6126494',
      rtl_batch_array:
        '5866425, 6161788, 6286661, 6382279, 6193618, 6287681, 6356617, 6385663, 6126494',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 24,
      chnn_stock_retail: 62557,
      sppl_order_minimum: 3,
      sppl_prcp: 0.561,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: '84K2NA57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84K2NA57'
    },
    {
      status: 210,
      sku: 'PM0077435',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304784',
      rtl_batch_array: '6304784',
      name_search: 'PIJLGOLD',
      name: "Pieris japonica 'Little Goldy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4505,
      chnn_stock_retail: 4505,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DN8A3WLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DN8A3WLX'
    },
    {
      status: 210,
      sku: 'PM0077437',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304786',
      rtl_batch_array: '6304786',
      name_search: 'PIJLHGRE',
      name: "Pieris japonica 'Little Heath Green'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 638,
      chnn_stock_retail: 638,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHX9T1CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHX9T1CX'
    },
    {
      status: 210,
      sku: 'PM0077440',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304790',
      rtl_batch_array: '6304790',
      name_search: 'CASDPINK',
      name: "Calliandra surinamensis 'Dixie Pink'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 15011,
      chnn_stock_retail: 15011,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VVWADAJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVWADAJ2'
    },
    {
      status: 210,
      sku: 'PM0081087',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338497',
      rtl_batch_array: '6338497',
      name_search: 'SPJMCARP',
      name: 'Spiraea japonica Magic Carpet',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3334,
      chnn_stock_retail: 3334,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PKC93V3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKC93V3V'
    },
    {
      status: 810,
      sku: 'PM0045474',
      core_name: 'Plant',
      sppl_ean: '8720626392144',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014408',
      rtl_batch_array: '6014408',
      name_search: 'ASPLIGHT',
      name: "Astilbe (S) 'Pink Lightning'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P5YA1412',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5YA1412'
    },
    {
      status: 210,
      sku: 'PM0077441',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304791',
      rtl_batch_array: '6304791',
      name_search: 'CASDPINK',
      name: "Calliandra surinamensis 'Dixie Pink'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 873,
      chnn_stock_retail: 873,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '27Z71JB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27Z71JB6'
    },
    {
      status: 210,
      sku: 'PM0045335',
      core_name: 'Plant',
      sppl_ean: '8720664681064',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014517',
      rtl_batch_array: '6014517, 6187510',
      name_search: 'ASRZWERG',
      name: "Aster (D) 'Rosa Zwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 160,
      chnn_stock_retail: 229,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'STHHAALE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STHHAALE'
    },
    {
      status: 210,
      sku: 'PM0077442',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304792',
      rtl_batch_array: '6304792',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 588,
      chnn_stock_retail: 588,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '7T4YBGNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7T4YBGNA'
    },
    {
      status: 210,
      sku: 'PM0077443',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304793',
      rtl_batch_array: '6304793',
      name_search: 'CACSTEPH',
      name: 'Caryopteris clandonensis Stephi',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1498,
      chnn_stock_retail: 1498,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9P2K76A;ALSTERGD;TBHKD8CG;W6K4VCCS;CL17AVXG;L5DTJNEW',
      statusContent: 'dot',
      hash: 'AJJL9EPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJJL9EPZ'
    },
    {
      status: 210,
      sku: 'PM0077444',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311260',
      rtl_batch_array: '6311260, 6304797',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 416,
      chnn_stock_retail: 1415,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '234Z2HEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '234Z2HEX'
    },
    {
      status: 210,
      sku: 'PM0045491',
      core_name: 'Plant',
      sppl_ean: '8720664691971',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5741219',
      rtl_batch_array: '5741219',
      name_search: 'HERRUM',
      name: "Hemerocallis 'Red Rum'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61CLG6GH;ABXGKSJJ;PELGWZPB;PP6G85NE',
      imageBatch: 'KWPTRVJ4',
      statusContent: 'dot',
      hash: '2A2CNVLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A2CNVLB'
    },
    {
      status: 210,
      sku: 'PM0077445',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311261',
      rtl_batch_array: '6311261, 6304799',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 390,
      chnn_stock_retail: 1389,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: '8S3EVYSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S3EVYSB'
    },
    {
      status: 210,
      sku: 'PM0045423',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324728',
      rtl_batch_array: '6301467, 6324728, 6329875',
      name_search: 'BRMAGREA',
      name: "Brunnera macrophylla 'Alexanders Great'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 406,
      chnn_stock_retail: 866,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CS18PZZ1',
      statusContent: 'dot',
      hash: '9AL3EV9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AL3EV9X'
    },
    {
      status: 210,
      sku: 'PM0045344',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274822',
      rtl_batch_array: '6274822, 6307920',
      name_search: 'ALALPINA',
      name: 'Alchemilla alpina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 607,
      chnn_stock_retail: 961,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWDV6WLD;N6WX5PXB;EA87P57E;PEVYNHGP',
      statusContent: 'dot',
      hash: 'KSCAT6GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSCAT6GW'
    },
    {
      status: 210,
      sku: 'PM0077624',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305221',
      rtl_batch_array: '6305221',
      name_search: 'ECLORANG',
      name: 'Echinacea Lakota Orange',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 633,
      chnn_stock_retail: 633,
      sppl_prcp: 2.221,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TC66JGE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TC66JGE6'
    },
    {
      status: 210,
      sku: 'PM0077446',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311262',
      rtl_batch_array: '6311262, 6304800',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 1449,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'NE8VGPRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NE8VGPRV'
    },
    {
      status: 210,
      sku: 'PM0077447',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311264',
      rtl_batch_array: '6311264, 6304801',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1734,
      chnn_stock_retail: 2733,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'X5CK1CA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5CK1CA1'
    },
    {
      status: 210,
      sku: 'PM0058024',
      core_name: 'Plant',
      sppl_ean: '8720664686038',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084867',
      rtl_batch_array: '6084867',
      name_search: 'COSWPLUM',
      name: "Cortaderia selloana 'White Plume'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5064,
      chnn_stock_retail: 5064,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y5JJNKRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5JJNKRE'
    },
    {
      status: 210,
      sku: 'PM0068567',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307523',
      rtl_batch_array: '6307523',
      name_search: 'LOHABEAU',
      name: "Lonicera heckrottii 'American Beauty'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YBZR4H14;ZE7A617B;K48637SS;CS33DV79;STBKRB2S;SPVEL8W9;83VR4644;Y3DA9VWE;EDXZYAK4;W17ZCDHZ',
      statusContent: 'dot',
      hash: '22E8PBRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22E8PBRS'
    },
    {
      status: 210,
      sku: 'PM0077448',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311270',
      rtl_batch_array: '6311270, 6304802',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 482,
      chnn_stock_retail: 1481,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'Y1KHV1BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1KHV1BT'
    },
    {
      status: 210,
      sku: 'PM0077622',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305218',
      rtl_batch_array: '6305218',
      name_search: 'BEROTBLU',
      name: "Bergenia 'Rotblum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 858,
      chnn_stock_retail: 858,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SNRLVVHG;C6NC8YKP;R722JEH6;PB9ZJ8A4;LKJ3VA9T;ANAJ21B7;2ZE7VXCX;CNXL3LNC',
      statusContent: 'dot',
      hash: 'YXV5PLZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXV5PLZT'
    },
    {
      status: 210,
      sku: 'PM0047180',
      core_name: 'Plant',
      sppl_ean: '8720664800991',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014483',
      rtl_batch_array: '6014483',
      name_search: 'PEABBUNN',
      name: "Pennisetum alopecuroides 'Burgundy Bunny'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1XJEPSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1XJEPSD'
    },
    {
      status: 210,
      sku: 'PM0045471',
      core_name: 'Plant',
      sppl_ean: '8720626392106',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014407',
      rtl_batch_array: '6014407',
      name_search: 'ASDUNKEL',
      name: "Astilbe (S) 'Dunkellachs'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DWBARKWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWBARKWW'
    },
    {
      status: 210,
      sku: 'PM0077626',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305223',
      rtl_batch_array: '6305223',
      name_search: 'ERSPECTA',
      name: 'Eragrostis spectabilis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1392,
      chnn_stock_retail: 1392,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JTGT1V3P;GLNZCGB7;P4XBJ7YW;D2VPKWKA;T3WW1HD6;B2X1A5W4;XX9ZWDHX;41SJ5XAH;H6XAYHPS',
      statusContent: 'dot',
      hash: 'H81HTB4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H81HTB4D'
    },
    {
      status: 210,
      sku: 'PM0045345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300410',
      rtl_batch_array: '6300410',
      name_search: 'ALSGSTRI',
      name: "Alchemilla sericata 'Gold Strike'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D32PBC3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D32PBC3S'
    },
    {
      status: 210,
      sku: 'PM0068516',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6205781',
      rtl_batch_array: '6205781, 6319698',
      name_search: 'GERSALBU',
      name: "Geranium sylvaticum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1561,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BP5W91EW;H4YG98BW;Y7L97ELD;BXERAYR4',
      statusContent: 'dot',
      hash: 'BEKRZ5WN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEKRZ5WN'
    },
    {
      status: 210,
      sku: 'PM0077627',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305228',
      rtl_batch_array: '6305228',
      name_search: 'PEAFOXTR',
      name: "Pennisetum alopecuroides 'Foxtrot'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1950,
      chnn_stock_retail: 1950,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRCZYAPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRCZYAPV'
    },
    {
      status: 210,
      sku: 'PM0085064',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354800',
      rtl_batch_array: '6354800',
      name_search: 'PITDDIVA',
      name: 'Pittosporum t. Dark Diva',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2549,
      chnn_stock_retail: 2549,
      sppl_order_minimum: 3,
      sppl_prcp: 2.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2J1LT135',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2J1LT135'
    },
    {
      status: 210,
      sku: 'PM0077431',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304780',
      rtl_batch_array: '6304780',
      name_search: 'PIJKATSU',
      name: "Pieris japonica 'Katsura'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5541,
      chnn_stock_retail: 5541,
      sppl_prcp: 4.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZC1P451N;R3YXDDZ5;89KK2VAZ;VEEHB33X;LNALWVY8;72RVVABR;TDGCXWLX;V2NZBT71;ZWXYX7HW;7A5PSARH;7ZZ66V5L;89HZ92DK;8VCLVYDN;DJ8Z73WD;RS5JEC4P',
      statusContent: 'dot',
      hash: 'ZKAT9KPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKAT9KPG'
    },
    {
      status: 210,
      sku: 'PM0077625',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305222',
      rtl_batch_array: '6305222',
      name_search: 'ECLRED',
      name: 'Echinacea Lakota Red',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 702,
      chnn_stock_retail: 702,
      sppl_prcp: 2.221,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1H6DHAW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H6DHAW8'
    },
    {
      status: 210,
      sku: 'PM0068519',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266260',
      rtl_batch_array: '6266260',
      name_search: 'SANACHTV',
      name: "Salvia 'Nachtvlinder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '88BKRCAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88BKRCAL'
    },
    {
      status: 210,
      sku: 'PM0068575',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307533',
      rtl_batch_array: '6307533',
      name_search: 'LOPSEROT',
      name: "Lonicera periclymenum 'Serotina'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2016,
      chnn_stock_retail: 2016,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJPNZYE2;ZN4CAHN1;Z2YKXPSL;16P279GS;E6LWJWGT;48YZ64JZ;75J4REZ7;R6YWKD37;WAVNK8D2',
      statusContent: 'dot',
      hash: '5VZ95JCE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VZ95JCE'
    },
    {
      status: 810,
      sku: 'PM0045518',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301510',
      rtl_batch_array: '6301510',
      name_search: 'CHRBRONZ',
      name: "Chrysanthemum (I) 'Bronzekrone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69CAA9T3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69CAA9T3'
    },
    {
      status: 210,
      sku: 'PM0081088',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338499',
      rtl_batch_array: '6338499',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2187,
      chnn_stock_retail: 2187,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'GDEG1Z7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDEG1Z7W'
    },
    {
      status: 210,
      sku: 'PM0081089',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338500',
      rtl_batch_array: '6338500',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1667,
      chnn_stock_retail: 1667,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'D4X9WZNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4X9WZNV'
    },
    {
      status: 210,
      sku: 'PM0077438',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304788',
      rtl_batch_array: '6304788',
      name_search: 'PIJPASSI',
      name: "Pieris japonica 'Passion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2797,
      chnn_stock_retail: 2797,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ALXRATH;R666VY63;V44EW5A5;LPXRZVCH;546NDJ9C;8TESAHAG;YAKSWRCE;6ZCXYLK6;T2GKESYC;62EKEJDT;WTKKCPT4;BS457Y7P;VHEV4JRY;4HKHN85H;S8SDZG3T;PW6LHC45',
      statusContent: 'dot',
      hash: 'D5TH6ZAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5TH6ZAR'
    },
    {
      status: 210,
      sku: 'PM0045407',
      core_name: 'Plant',
      sppl_ean: '8720626395121',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014600',
      rtl_batch_array: '6014600',
      name_search: 'BEBARTOK',
      name: "Bergenia 'Bartok'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KE3P2SL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE3P2SL4'
    },
    {
      status: 210,
      sku: 'PM0068566',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307522',
      rtl_batch_array: '6307522',
      name_search: 'LOCWOJTE',
      name: "Lonicera caerulea 'Wojtek'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ZCZ45J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZCZ45J7'
    },
    {
      status: 210,
      sku: 'PM0077433',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304782',
      rtl_batch_array: '6304782',
      name_search: 'PIJDEBUT',
      name: "Pieris japonica 'Debutante'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1409,
      chnn_stock_retail: 1409,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '28C4G9Z7;TJBDT4GA;E736XHBT;1SD1LN4B;GYBD11SY;T6AKPDLP;938DW86L;Z6ALPBB5;ET5TKR37;HCJY8V13;N52Z4R56;83YVXS5Z',
      statusContent: 'dot',
      hash: '612KZHEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '612KZHEP'
    },
    {
      status: 210,
      sku: 'PM0058039',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370352',
      rtl_batch_array: '6370352, 6350148',
      name_search: 'ERRJEP',
      name: "Erysimum 'Red Jep'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 366,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CHEZKA6N;6GTDDAV5;N5CHKHV3;6VCEZ6N4;LG6W61LZ;JZ2X2SXN;VEVDP2T9;P3E29DYK;P9XN9YTL',
      statusContent: 'dot',
      hash: 'TEPHSGKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEPHSGKB'
    },
    {
      status: 210,
      sku: 'PM0068572',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307530',
      rtl_batch_array: '6307530',
      name_search: 'LOPERICL',
      name: 'Lonicera periclymenum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 784,
      chnn_stock_retail: 784,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYGLHEPG;61EXCRG3;XS5W43AY;VC6L2AA1;HARWJ729',
      statusContent: 'dot',
      hash: 'SW79AHPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW79AHPC'
    },
    {
      status: 210,
      sku: 'PM0068570',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307528',
      rtl_batch_array: '6307528',
      name_search: 'LOJHALLI',
      name: "Lonicera japonica 'Halliana'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 3071,
      chnn_stock_retail: 3071,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VCL9KE5;6JKL2B7Y;B1KTTATS;WE2LSD3H;XG939NGS;4V9YCXNK',
      statusContent: 'dot',
      hash: '4PBTS4KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PBTS4KS'
    },
    {
      status: 210,
      sku: 'PM0077439',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304789',
      rtl_batch_array: '6304789',
      name_search: 'PIJPRELU',
      name: "Pieris japonica 'Prelude'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1693,
      chnn_stock_retail: 1693,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEEN2RS6;GBABYAVJ;PWVN3L65;54XX6NT9;WGSB78LB;TPL6GSY7;X6S5R4LP;8EJ76ZPE;ZZLHYR3B;JG8DX381',
      statusContent: 'dot',
      hash: '6L1JXYW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L1JXYW3'
    },
    {
      status: 210,
      sku: 'PM0068571',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307529',
      rtl_batch_array: '6307529',
      name_search: 'LOJRWORL',
      name: "Lonicera japonica 'Red World'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2495,
      chnn_stock_retail: 2495,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2PENSX3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PENSX3K'
    },
    {
      status: 210,
      sku: 'PM0068573',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307531',
      rtl_batch_array: '6307531',
      name_search: 'LOPBELGI',
      name: "Lonicera periclymenum 'Belgica'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 529,
      chnn_stock_retail: 529,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPRANN1H;GA61NY68',
      statusContent: 'dot',
      hash: 'JHD9ZDAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHD9ZDAL'
    },
    {
      status: 210,
      sku: 'PM0068574',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307532',
      rtl_batch_array: '6307532',
      name_search: 'LOPBSELE',
      name: "Lonicera periclymenum 'Belgica Select'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 581,
      chnn_stock_retail: 581,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A3C9GH6E',
      statusContent: 'dot',
      hash: '4PCND7VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PCND7VX'
    },
    {
      status: 210,
      sku: 'PM0068576',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307535',
      rtl_batch_array: '6307535',
      name_search: 'LOTELLMA',
      name: 'Lonicera tellmanniana',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2510,
      chnn_stock_retail: 2510,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ECJ3G;JGY84VDN;2JNBTDGT;WGHTB9ZK;LXY88137;9487HK3K',
      statusContent: 'dot',
      hash: 'CSDY7ZJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSDY7ZJD'
    },
    {
      status: 210,
      sku: 'PM0045408',
      core_name: 'Plant',
      sppl_ean: '8720626395152',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014602',
      rtl_batch_array: '6014602',
      name_search: 'BEBSALMO',
      name: "Bergenia 'Bressingham Salmon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KVY594WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVY594WL'
    },
    {
      status: 210,
      sku: 'PM0058036',
      core_name: 'Plant',
      sppl_ean: '8720664860209',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084889',
      rtl_batch_array: '6084889',
      name_search: 'DIPDROSE',
      name: "Digitalis purpurea 'Dalmatian Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4545,
      chnn_stock_retail: 4545,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W3LH5SB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3LH5SB8'
    },
    {
      status: 210,
      sku: 'PM0081090',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338501',
      rtl_batch_array: '6338501',
      name_search: 'SPVGFOUN',
      name: "Spiraea vanhouttei 'Gold Fountain'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4BLG4W4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4BLG4W4'
    },
    {
      status: 210,
      sku: 'PM0068568',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307524',
      rtl_batch_array: '6307524',
      name_search: 'LOHGOLDF',
      name: "Lonicera heckrottii 'Goldflame'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4492,
      chnn_stock_retail: 4492,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K9GGGXCS;SSAH4CPD;RKWJ8RJ2;VCE5YNRG;KVCKEWN4;T522KXAW;X71CA61Y;JJLYGBH9;94Y7V92R;WV2C8H19;LLLHK457;4BWWAL4V;WNA4XLCP',
      statusContent: 'dot',
      hash: 'XHY4Z2AJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHY4Z2AJ'
    },
    {
      status: 210,
      sku: 'PM0081091',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338502',
      rtl_batch_array: '6338502',
      name_search: 'SPVPICE',
      name: "Spiraea vanhouttei 'Pink Ice'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 549,
      chnn_stock_retail: 549,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRWVHDTK;C2X75VKX;HJCC2N7P;TAL8P5YP;DTX4B1HG;K2ASV3J5;R7KB3JKR;JS93W3BW;EL7CRP8L;79YVZS9P;NZDG9PPR',
      statusContent: 'dot',
      hash: 'HY1GP7C9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HY1GP7C9'
    },
    {
      status: 210,
      sku: 'PM0081092',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338503',
      rtl_batch_array: '6338503',
      name_search: 'STPRAECO',
      name: 'Stachyurus praecox',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 365,
      chnn_stock_retail: 365,
      sppl_prcp: 3.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VP5HDBLG',
      statusContent: 'dot',
      hash: 'TYLPEKKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYLPEKKY'
    },
    {
      status: 210,
      sku: 'PM0068577',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307599',
      rtl_batch_array: '6307599',
      name_search: 'PALLADY',
      name: "Passiflora 'Lavender Lady'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVGNRDSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVGNRDSP'
    },
    {
      status: 210,
      sku: 'PM0077436',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304785',
      rtl_batch_array: '6304785',
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 13606,
      chnn_stock_retail: 13606,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV7A8VKK;2D7HYSVP;5E11VCT7;WEDN342H;6CBCVY9E;DRD2X2DT;5R8EASNG;TT28BTWY;2189SZS3;YZGT72RE;1BG4Z21C;RSHDGCEX;S1V6CXEZ;PW1TVV1A;27N88R7Y',
      statusContent: 'dot',
      hash: 'PEJDVNYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEJDVNYN'
    },
    {
      status: 210,
      sku: 'PM0081093',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338504',
      rtl_batch_array: '6338504',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1859,
      chnn_stock_retail: 1859,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'P7NCLK3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7NCLK3S'
    },
    {
      status: 210,
      sku: 'PM0045458',
      core_name: 'Plant',
      sppl_ean: '8720664852440',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6112325',
      rtl_batch_array: '6112325, 6164364, 6302393',
      name_search: 'ASPSTAR',
      name: "Aster 'Pink Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1041,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VSVL7KTA',
      statusContent: 'dot',
      hash: '74TXJ6LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74TXJ6LC'
    },
    {
      status: 210,
      sku: 'PM0058011',
      core_name: 'Plant',
      sppl_ean: '8720664852907',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084809',
      rtl_batch_array: '6084809',
      name_search: 'ASTWARTB',
      name: "Aster tongolensis 'Wartburgstern'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 661,
      chnn_stock_retail: 661,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6WAGBVJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WAGBVJZ'
    },
    {
      status: 210,
      sku: 'PM0057031',
      core_name: 'Plant',
      sppl_ean: '8720664864498',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147625',
      rtl_batch_array: '6090702, 6147625',
      name_search: 'GECHARZ',
      name: "Geranium cantabrigiense 'Harz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2070,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V9LHVNBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9LHVNBZ'
    },
    {
      status: 810,
      sku: 'PM0081094',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338505',
      rtl_batch_array: '6338505',
      name_search: 'STPSEUDO',
      name: 'Stewartia pseudocamellia',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 4.999,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KT4TTS4X',
      statusContent: 'dot',
      hash: '1YPJB8GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YPJB8GX'
    },
    {
      status: 210,
      sku: 'PM0068578',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307601',
      rtl_batch_array: '6307601',
      name_search: 'PACAERUL',
      name: 'Passiflora caerulea',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1974,
      chnn_stock_retail: 1974,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YEW1WTTX;NRVPZWNE;YC1BYG32;L3616X6A;TJX3SWET;AA13ZRNS;2RB6V6J4;D575SXJB;66NZYEE8;ZAG5S851;P1CE6NH3;J6CJ1CX1;4XTJ6WHC;46NDAPV2',
      statusContent: 'dot',
      hash: 'ESE9XYEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESE9XYEY'
    },
    {
      status: 210,
      sku: 'PM0057034',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350198',
      rtl_batch_array: '6350198',
      name_search: 'GECKPINK',
      name: "Geranium clarkei 'Kashmir Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9H4EY5PL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9H4EY5PL'
    },
    {
      status: 210,
      sku: 'PM0032835',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6146931',
      rtl_batch_array: '6146931, 6221461, 6254793, 6305775',
      name_search: 'NESHGIAN',
      name: "Nepeta 'Six Hills Giant'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2711,
      chnn_stock_retail: 7017,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P5A3KB96;G9JH2P1K;B9TSLTR6;HHSZEGKL;K9D8H9CT;SS7TWGDZ;BDXC99PY;PPG4YWKG;A9DV867W;PPTNE2DP;L2HEWPPV;6H8W59KV;ZK55T26J;2926Y1WV;4B4DB35R;CPD69W2P;6G4G1W64;KV1WXYS1;ZBTHA3DP;XS3NB11N;WSDAA929;CTLZ3671;9B2HKLC5',
      statusContent: 'dot',
      hash: '1BH281LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BH281LC'
    },
    {
      status: 210,
      sku: 'PM0068579',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307602',
      rtl_batch_array: '6307602',
      name_search: 'PACCELLI',
      name: "Passiflora caerulea 'Constance Elliott'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 794,
      chnn_stock_retail: 794,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZSBCVPX;HTR5YBAV;GD1RL3BP;6A8AW88J;R6T4CPRJ;C5538E53',
      statusContent: 'dot',
      hash: 'HATTWC18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HATTWC18'
    },
    {
      status: 210,
      sku: 'PM0058052',
      core_name: 'Plant',
      sppl_ean: '8720664867093',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084967',
      rtl_batch_array: '6084967',
      name_search: 'HEWLEMON',
      name: "Hemerocallis 'White Lemonade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5108,
      chnn_stock_retail: 5108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1EG5VWDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1EG5VWDV'
    },
    {
      status: 210,
      sku: 'PM0069036',
      core_name: 'Plant',
      sppl_ean: '8720664887183',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6077599',
      rtl_batch_array: '6077599, 6353463',
      name_search: 'STMSUPER',
      name: "Stachys macrantha 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1207,
      chnn_stock_retail: 2949,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4NSWAX2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NSWAX2Z'
    },
    {
      status: 210,
      sku: 'PM0058021',
      core_name: 'Plant',
      sppl_ean: '8720664855335',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084843',
      rtl_batch_array: '6084843, 6302433',
      name_search: 'CARACUTI',
      name: 'Carex acutiformis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4308,
      chnn_stock_retail: 4480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SEG4DWL5',
      statusContent: 'dot',
      hash: 'BJGKRV9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJGKRV9Z'
    },
    {
      status: 810,
      sku: 'PM0069041',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353139',
      rtl_batch_array: '6353139',
      name_search: 'PEAHEUTI',
      name: "Persicaria a. 'Heutinck'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSCG6PDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSCG6PDA'
    },
    {
      status: 210,
      sku: 'PM0081095',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338506',
      rtl_batch_array: '6338506',
      name_search: 'STJAPONI',
      name: 'Styrax japonicus',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 3.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EZBVJR1;X3XYR3PE;9E15RLSY;7XRW32L6;L426ZDT7;9YKY81H7;G3TYZW69;ECW693YK;81BNHSCZ;NWG2SEBX;YWPS8S1R;24Y6BJWJ',
      statusContent: 'dot',
      hash: 'K53GYEY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K53GYEY7'
    },
    {
      status: 210,
      sku: 'PM0047168',
      core_name: 'Plant',
      sppl_ean: '8720353028354',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014466',
      rtl_batch_array: '6014466',
      name_search: 'PAVKBLUE',
      name: "Panicum virgatum 'Kurt Bluemel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KP5ZK37X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP5ZK37X'
    },
    {
      status: 210,
      sku: 'PM0045469',
      core_name: 'Plant',
      sppl_ean: '8720353087849',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014586',
      rtl_batch_array: '6014586',
      name_search: 'ASRBLUES',
      name: "Astilbe (A) 'Rhythm and Blues'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8DK1VJG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DK1VJG2'
    },
    {
      status: 210,
      sku: 'PM0069042',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220884',
      rtl_batch_array: '6220884',
      name_search: 'PHDWPERF',
      name: "Phlox divaricata 'White Perfume'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '29S8XCAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29S8XCAZ'
    },
    {
      status: 210,
      sku: 'PM0058029',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302456',
      rtl_batch_array: '6302456',
      name_search: 'DEKARTHU',
      name: "Delphinium (P) 'King Arthur'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 481,
      chnn_stock_retail: 481,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRJ9HDW9;G4N4GG9B;VWJHNR5S;X8ELWDTH;GXV11T5G;BZGZ6XEY;PDY7VPB8;7TD71EL4;BX68WHHB',
      statusContent: 'dot',
      hash: 'LZH6ZHTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZH6ZHTV'
    },
    {
      status: 210,
      sku: 'PM0077623',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305219',
      rtl_batch_array: '6305219',
      name_search: 'CRGDAVID',
      name: "Crocosmia 'George Davidson'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 822,
      chnn_stock_retail: 822,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EZRRYV6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZRRYV6R'
    },
    {
      status: 810,
      sku: 'PM0081096',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338507',
      rtl_batch_array: '6338507',
      name_search: 'STJPCHIM',
      name: "Styrax japonicus 'Pink Chimes'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 3.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RW8GXRZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW8GXRZJ'
    },
    {
      status: 210,
      sku: 'PM0081097',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338508',
      rtl_batch_array: '6338508',
      name_search: 'SYSPHAZE',
      name: "Sycoparrotia semidecidua 'Purple Haze'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 939,
      chnn_stock_retail: 939,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7X2LZHYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7X2LZHYK'
    },
    {
      status: 210,
      sku: 'PM0045475',
      core_name: 'Plant',
      sppl_ean: '8720353087962',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014409',
      rtl_batch_array: '6014409',
      name_search: 'ASBCUPER',
      name: "Astilbe (T) 'Betsy Cuperus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NAHV3HP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NAHV3HP'
    },
    {
      status: 210,
      sku: 'PM0081098',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338509',
      rtl_batch_array: '6338509',
      name_search: 'SYDMBERR',
      name: "Symphoricarpos doorenbosii 'Magic Berry'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1368,
      chnn_stock_retail: 1368,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BJTZXBBP;VYBCZ542;PEK5E6TW;H19Y2CY9;XN9BJ7KT;RVN3833W;J1Y7CTH4;HDW16A1V;NJ8DR65E;W519LKGS',
      statusContent: 'dot',
      hash: 'S5C3B3CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5C3B3CT'
    },
    {
      status: 210,
      sku: 'PM0081099',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338510',
      rtl_batch_array: '6338510',
      name_search: 'SYMGALAX',
      name: 'Symphoricarpos Magical Galaxy',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 825,
      chnn_stock_retail: 825,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RT645DGD;NJ4KXSDW;GNTTZ66X;RCH61A3S;ZXKP1L7X;6DB72G4C;9LYGJ58B;Z7ZH1SP1;186SXXEP;JNCY3PWB',
      statusContent: 'dot',
      hash: 'BAP12ZNK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAP12ZNK'
    },
    {
      status: 810,
      sku: 'PM0081100',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338511',
      rtl_batch_array: '6338511',
      name_search: 'SYMSWEET',
      name: 'Symphoricarpos Magical Sweet',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SPB119BP;GR5ECNHG;RJV7XGYT;A573T2J2;KADW27PR;Z62YE2PT;5ETG3LWL;SK4LTXCZ;8J5WGDNX;K849PGSY;KW8EY2VN;P2PHGXAY;L63DWKWC;ASNKDBCB',
      statusContent: 'dot',
      hash: '7DJDL3TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DJDL3TB'
    },
    {
      status: 210,
      sku: 'PM0058045',
      core_name: 'Plant',
      sppl_ean: '8720664865068',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084942',
      rtl_batch_array: '6084942',
      name_search: 'GESVPINK',
      name: "Geranium sanguineum 'Vision Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 20736,
      chnn_stock_retail: 20736,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXZ4W1ZJ;S3P5J6P6;LCRJ79E4;P8DT7H34;6LKH2APA;VT7NRR1B;8262Z526;J7CPH7L4;XXWV6XDS',
      statusContent: 'dot',
      hash: 'T8AXLNRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8AXLNRW'
    },
    {
      status: 210,
      sku: 'PM0081101',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338512',
      rtl_batch_array: '6338512',
      name_search: 'SYMTEMPT',
      name: 'Symphoricarpos Magical Temptation',
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SBPVRPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SBPVRPH'
    },
    {
      status: 210,
      sku: 'PM0081102',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338514',
      rtl_batch_array: '6338514',
      name_search: 'SYJOSEE',
      name: "Syringa 'Jos\u00e9e'",
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 1607,
      chnn_stock_retail: 1607,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TKHJ7PT7',
      statusContent: 'dot',
      hash: '5TXS1THC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TXS1THC'
    },
    {
      status: 210,
      sku: 'PM0081103',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338515',
      rtl_batch_array: '6338515',
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ESVCLKJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESVCLKJ7'
    },
    {
      status: 210,
      sku: 'PM0045391',
      core_name: 'Plant',
      sppl_ean: '8720626392120',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014580',
      rtl_batch_array: '6014580',
      name_search: 'ASKLARGO',
      name: "Astilbe (S) 'Key Largo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELXSE7P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELXSE7P4'
    },
    {
      status: 810,
      sku: 'PM0058010',
      core_name: 'Plant',
      sppl_ean: '8720664852556',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084805',
      rtl_batch_array: '6084805, 6317834',
      name_search: 'ASSCHNEE',
      name: "Aster (D) 'Schneekissen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 1253,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CNBYY43P;DTG661N8;RVCCBBHJ;9NHDAXB2;4J4ZCGC1',
      statusContent: 'dot',
      hash: 'KXZ4PNTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXZ4PNTA'
    },
    {
      status: 210,
      sku: 'PM0069926',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219499',
      rtl_batch_array: '6219499',
      name_search: 'SERYCUSH',
      name: "Sedum reflexum 'Yellow Cushion'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1384,
      chnn_stock_retail: 1384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T2N37YN5;KYVLZBCR;2E1ZEPR3;26EPVY6S;4TNT374S;RRH8XSBV;C81G1ZL1;54WS8C5E;AWTLKD4D;YAD44YY4;Z6WS3KAS',
      statusContent: 'dot',
      hash: 'AYPR6568',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYPR6568'
    },
    {
      status: 210,
      sku: 'PM0081104',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338516',
      rtl_batch_array: '6338516',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'W4D5DZ5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4D5DZ5Y'
    },
    {
      status: 210,
      sku: 'PM0045340',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6315115',
      rtl_batch_array: '6034198, 6171168, 6315115, 6308646',
      name_search: 'ASAASHVI',
      name: "Aster ageratoides 'Ashvi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 635,
      chnn_stock_retail: 6429,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B59LA86J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B59LA86J'
    },
    {
      status: 210,
      sku: 'PM0081105',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338517',
      rtl_batch_array: '6338517',
      name_search: 'TAPARVIF',
      name: 'Tamarix parviflora',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 511,
      chnn_stock_retail: 511,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VN2VC6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VN2VC6K'
    },
    {
      status: 210,
      sku: 'PM0081106',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338518',
      rtl_batch_array: '6338518',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1138,
      chnn_stock_retail: 1138,
      sppl_prcp: 4.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: '1PBL132Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PBL132Z'
    },
    {
      status: 210,
      sku: 'PM0081107',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338519',
      rtl_batch_array: '6338519',
      name_search: 'TEACKERM',
      name: 'Teucrium ackermerum',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 3,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '173PB637',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '173PB637'
    },
    {
      status: 210,
      sku: 'PM0081108',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338520',
      rtl_batch_array: '6338520',
      name_search: 'TEFAZURE',
      name: "Teucrium fruticans 'Azureum'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2189,
      chnn_stock_retail: 2189,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZDCWKJ87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDCWKJ87'
    },
    {
      status: 210,
      sku: 'PM0069043',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186648',
      rtl_batch_array: '6186648',
      name_search: 'JUPGSTAR',
      name: "Juniperus pfitzeriana 'Gold Star'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 548,
      chnn_stock_retail: 548,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PL8238PW;NL1SPXV8;SJS2EE6W;S73PXD3G;J24CLA6Z;VSNXR8ZX;7KDD6YGX;HZERSDKW;E1B9C9LX;C6R4GV65;CJP93ZXL',
      statusContent: 'dot',
      hash: 'ZPJP3XTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPJP3XTX'
    },
    {
      status: 810,
      sku: 'PM0045349',
      core_name: 'Plant',
      sppl_ean: '8720626387058',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014390',
      rtl_batch_array: '6014390',
      name_search: 'EDI85454',
      name: 'Allium decipiens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5PV9JAG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PV9JAG1'
    },
    {
      status: 810,
      sku: 'PM0045409',
      core_name: 'Plant',
      sppl_ean: '8720349463633',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054477',
      rtl_batch_array: '6054477, 6374011',
      name_search: 'BEEDMARG',
      name: "Bergenia 'Eden's Dark Margin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4ZKNNLZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZKNNLZL'
    },
    {
      status: 210,
      sku: 'PM0068569',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307527',
      rtl_batch_array: '6307527',
      name_search: 'LOJHPROL',
      name: "Lonicera japonica 'Hall's Prolific'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1302,
      chnn_stock_retail: 1302,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHKL1DZY;PEANXCLV;CK5DDGCZ;2BXS6WAH;D3X52NJ1;5J46GSBA;5H8AKRL2;V11VCCSK;XE1KT76N;YV37XS54;KJAY3KGK',
      statusContent: 'dot',
      hash: 'J7PWGGRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7PWGGRC'
    },
    {
      status: 810,
      sku: 'PM0045514',
      core_name: 'Plant',
      sppl_ean: '8720664684843',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014753',
      rtl_batch_array: '6014753',
      name_search: 'CHCORINN',
      name: "Chrysanthemum (I) 'Corinna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7HPPN4BS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HPPN4BS'
    },
    {
      status: 210,
      sku: 'PM0045110',
      core_name: 'Plant',
      sppl_ean: '8720664691988',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591970',
      rtl_batch_array: '5591970',
      name_search: 'HESORO',
      name: "Hemerocallis 'Stella de Oro'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J55KVYTA;C4TAZAYH;9RGZJJVV;K14SP74B;RJRGE5L7;YELRS667;DHGJXWPA;JP32E8G5;7V324DPR;NCGXDYRX;HY33XH71;L138KD8S;YVLC6WC9;ZVAN65VZ;TS1ZVWWG;RH9CLK3A',
      imageBatch: 'HENLH3WT',
      statusContent: 'dot',
      hash: 'KNGJNK3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNGJNK3S'
    },
    {
      status: 210,
      sku: 'PM0068921',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205312',
      rtl_batch_array: '6205312',
      name_search: 'SANBLAUK',
      name: "Salvia nemorosa 'Blauk\u00f6nigin'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.359,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XLB9HYYP;GX5WZWPZ;GGTLJNCR;KG43N8DW;E2Z14LPE;9VVCPLNG;TBN7KPWK;KP23H1LJ;TJCPSPPC;5LCAGGW4;RZ183BET;6PZ3L3D3',
      statusContent: 'dot',
      hash: 'AGZ9S334',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGZ9S334'
    },
    {
      status: 210,
      sku: 'PM0081109',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338521',
      rtl_batch_array: '6338521',
      name_search: 'TRAMANDA',
      name: "Trachelospermum asiaticum 'Mandanianum'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BNELXYVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNELXYVC'
    },
    {
      status: 210,
      sku: 'PM0077449',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304803',
      rtl_batch_array: '6304803',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 975,
      chnn_stock_retail: 975,
      sppl_prcp: 17.693,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: '46K3PHVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46K3PHVR'
    },
    {
      status: 210,
      sku: 'PM0045481',
      core_name: 'Plant',
      sppl_ean: '8720626335370',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054460',
      rtl_batch_array: '6229874, 6054460',
      name_search: 'ASVINFER',
      name: "Astilbe 'Vision Inferno'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5491,
      chnn_stock_retail: 5705,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WPDVC7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WPDVC7W'
    },
    {
      status: 210,
      sku: 'PM0081110',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338523',
      rtl_batch_array: '6338523',
      name_search: 'TRJSTOSC',
      name: 'Trachelospermum jasminoides Star of Toscane',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 666,
      chnn_stock_retail: 666,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ED39JT9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED39JT9X'
    },
    {
      status: 210,
      sku: 'PM0077450',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304804',
      rtl_batch_array: '6304804',
      name_search: 'CYPALBUS',
      name: "Cytisus praecox 'Albus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1395,
      chnn_stock_retail: 1395,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KP66JVK;9YE8BDDL;538S5R5T;VJTEPC4S;2WJBWWEC;JH5A989V;Y6RBARWZ;1N6YCH3W;8H75LRV2;BBH911P9;SBYJWRDP;2NV3NWBC;ESN2XNAW;HKXR2953;4NE8EYVS',
      statusContent: 'dot',
      hash: 'KBYHVAHN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBYHVAHN'
    },
    {
      status: 210,
      sku: 'PM0057071',
      core_name: 'Plant',
      sppl_ean: '8720664859524',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133295',
      rtl_batch_array: '6133295',
      name_search: 'DECSCHOT',
      name: "Deschampsia cespitosa 'Schottland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R3Y9773X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3Y9773X'
    },
    {
      status: 210,
      sku: 'PM0045461',
      core_name: 'Plant',
      sppl_ean: '8720353011752',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014521',
      rtl_batch_array: '6014521',
      name_search: 'ASTONGA',
      name: 'Aster Tonga',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11XW8VZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11XW8VZG'
    },
    {
      status: 210,
      sku: 'PM0077452',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304806',
      rtl_batch_array: '6304806',
      name_search: 'CYSVANES',
      name: "Cytisus scoparius 'Vanesse'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1428,
      chnn_stock_retail: 1428,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GW54SN5P;2SR1CSKP;72GSKG6A;H9WVES2A;DRBX2GNV;ZS264Z16;SRYNKC99;P2TCLT86;K88X1KX8',
      statusContent: 'dot',
      hash: 'KG671TSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG671TSH'
    },
    {
      status: 210,
      sku: 'PM0077453',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304807',
      rtl_batch_array: '6304807',
      name_search: 'DECGOLDS',
      name: "Deschampsia cespitosa 'Goldschleier'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_prcp: 7.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NA1K4X9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NA1K4X9D'
    },
    {
      status: 210,
      sku: 'PM0077454',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304808',
      rtl_batch_array: '6304808',
      name_search: 'DIHPFOUN',
      name: "Diosma hirsuta 'Pink Fountain'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2945,
      chnn_stock_retail: 2945,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '53CB1VKE',
      statusContent: 'dot',
      hash: 'TNDACREJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNDACREJ'
    },
    {
      status: 210,
      sku: 'PM0077455',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304809',
      rtl_batch_array: '6304809',
      name_search: 'DIHSGOLD',
      name: "Diosma hirsuta 'Sunset Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2882,
      chnn_stock_retail: 2882,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AB2Y7NHH;JS3HR3VA;4G69VRG9;L1Y22RH6;BZ21TH48;E6YB3GC7',
      statusContent: 'dot',
      hash: 'R22RLPJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R22RLPJG'
    },
    {
      status: 210,
      sku: 'PM0077456',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304810',
      rtl_batch_array: '6304810',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '461VYBNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '461VYBNC'
    },
    {
      status: 210,
      sku: 'PM0026839',
      core_name: 'Plant',
      sppl_ean: '8720664878822',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084657',
      rtl_batch_array: '6084657, 6339893, 6344097',
      name_search: 'PHAFRANC',
      name: 'Physalis alkekengi franchetii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6051,
      chnn_stock_retail: 6539,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RESENKHD;B5G3CBYY',
      statusContent: 'dot',
      hash: 'JTXRG4S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTXRG4S2'
    },
    {
      status: 210,
      sku: 'PM0057994',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6275774',
      rtl_batch_array: '6176251, 6275774, 6339832',
      name_search: 'LISFWEIS',
      name: "Liatris spicata 'Floristan Weiss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 226,
      chnn_stock_retail: 9901,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYRGVWJH;T7CHRTG6',
      statusContent: 'dot',
      hash: 'Z7TLXC2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7TLXC2V'
    },
    {
      status: 210,
      sku: 'PM0068526',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266231',
      rtl_batch_array: '6266231',
      name_search: 'GALBDPIN',
      name: 'Gaura lindheimeri Belleza Dark Pink',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 784,
      chnn_stock_retail: 784,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1TRL7LYW',
      statusContent: 'dot',
      hash: 'J6SL8Z1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6SL8Z1A'
    },
    {
      status: 210,
      sku: 'PM0069045',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304795',
      rtl_batch_array: '6304795',
      name_search: 'CHTBRICA',
      name: "Choisya ternata 'Brica'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3760,
      chnn_stock_retail: 3760,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BYPD3W49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYPD3W49'
    },
    {
      status: 210,
      sku: 'PM0077457',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304811',
      rtl_batch_array: '6304811',
      name_search: 'ENCPAGOD',
      name: "Enkianthus campanulatus 'Pagode'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K48X1G57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K48X1G57'
    },
    {
      status: 810,
      sku: 'PM0070907',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348965',
      rtl_batch_array: '6348965',
      name_search: 'KNARVENS',
      name: 'Knautia arvensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A29EX5CB;HLVG7VP2;T2A8G659',
      statusContent: 'dot',
      hash: 'R96VC9DL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R96VC9DL'
    },
    {
      status: 210,
      sku: 'PM0077458',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304812',
      rtl_batch_array: '6304812',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3709,
      chnn_stock_retail: 3709,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: 'BG8ADHRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BG8ADHRS'
    },
    {
      status: 210,
      sku: 'PM0077459',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304813',
      rtl_batch_array: '6304813',
      name_search: 'ENCPRETT',
      name: 'Enkianthus campanulatus Prettycoat',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C5KB9WVX;84S76966;75ACKGWX;TXBS75RR;LV8G4ADK;A1G2W8X6;TE19ZA3C',
      statusContent: 'dot',
      hash: '6RAEHNYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RAEHNYC'
    },
    {
      status: 210,
      sku: 'PM0068923',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205314',
      rtl_batch_array: '6205314',
      name_search: 'SASBMARV',
      name: 'Salvia sylvestris Blue Marvel',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA48BHV5;HB9Y54KW;5SGALECD',
      statusContent: 'dot',
      hash: 'T6JWX5RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6JWX5RA'
    },
    {
      status: 910,
      sku: 'PM0045465',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASAROSE',
      name: 'Astilbe (A) Astary Rose',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'LHSTAG5H;Y9HE5HT5;JXW2HTD7;YL62HD34;5JWLBX4D',
      statusContent: 'dot',
      hash: 'GD91KCJW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GD91KCJW'
    },
    {
      status: 210,
      sku: 'PM0081111',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338524',
      rtl_batch_array: '6338524',
      name_search: 'TRFORTUN',
      name: 'Trachycarpus fortunei',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2054,
      chnn_stock_retail: 2054,
      sppl_order_minimum: 3,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X74TZTVW;HNW81PGZ;TR1RRV3C;Y2STVG6V;XLPR87S9;6WJPY8RX;72YCZRV6;HAW8S38G;L4TW44ZG;5A55S1YR;74GYT1ZB;2X49JZ5B;PH6RHEYR;BSPXTXZA;49CE93ZP;VT2CBK24;YPHTD5K8;ZD9GDRJT;HRVSZC68;RGZPWRXK;RDZNV767;CXXZL4XL;BRSKWHWY',
      statusContent: 'dot',
      hash: 'GAGKW5YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAGKW5YX'
    },
    {
      status: 210,
      sku: 'PM0045126',
      core_name: 'Plant',
      sppl_ean: '8720349418312',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592009',
      rtl_batch_array: '5592009',
      name_search: 'HOPIZZAZ',
      name: "Hosta 'Pizzazz'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'D696G8R6',
      statusContent: 'dot',
      hash: 'ESSSP57E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESSSP57E'
    },
    {
      status: 210,
      sku: 'PM0077461',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304815',
      rtl_batch_array: '6304815',
      name_search: 'ERDGHILL',
      name: "Erica darleyensis 'Ghost Hills'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 819,
      chnn_stock_retail: 819,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y7XNXBKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7XNXBKW'
    },
    {
      status: 910,
      sku: 'PM0085065',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEOAWHIT',
      name: "Geranium ox. 'Ankum's White'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '8BVR2S5J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8BVR2S5J'
    },
    {
      status: 210,
      sku: 'PM0081112',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338525',
      rtl_batch_array: '6338525',
      name_search: 'TUVIOLAC',
      name: 'Tulbaghia violacea',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 592,
      chnn_stock_retail: 592,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7A8X4BB3;574HWW78;NY2XERWD;SP5YWY94;PXGC3JRX;WDR86CAG;23H6959R;X7J5HSCZ;VRTW6C4S',
      statusContent: 'dot',
      hash: 'DG3J4HS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG3J4HS5'
    },
    {
      status: 210,
      sku: 'PM0020007',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244263',
      rtl_batch_array: '2733854, 6244263, 6252694',
      name_search: 'MOCEDUDS',
      name: "Molinia caerulea 'Edith Dudszus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2503,
      chnn_stock_retail: 4503,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9KXNAK1H;YDJDPZC6;CNTVT778;4HRRSPED;2CXKBNHA;GJGGTLN4;51S5V8X9;SP827C96;TL2PL2A8;WTYG3AK3;27PY65GE;AET1GN3Z;9WS14VP9;Z1CA99EH;C8D7YR8Y',
      statusContent: 'dot',
      hash: '78R39YE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78R39YE4'
    },
    {
      status: 210,
      sku: 'PM0077462',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207249',
      rtl_batch_array: '5207249, 6304816',
      name_search: 'ERDGHILL',
      name: "Erica darleyensis 'Ghost Hills'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 187,
      chnn_stock_retail: 1186,
      sppl_order_minimum: 3,
      sppl_prcp: 1.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'NHJ5N8BC',
      statusContent: 'dot',
      hash: 'N5399TZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5399TZK'
    },
    {
      status: 210,
      sku: 'PM0045449',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301442',
      rtl_batch_array: '6301442, 6386057',
      name_search: 'ASNBFELL',
      name: "Aster novi-belgii 'Fellowship'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 289,
      chnn_stock_retail: 585,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEXAT2R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEXAT2R3'
    },
    {
      status: 210,
      sku: 'PM0058002',
      core_name: 'Plant',
      sppl_ean: '8720664698604',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084781',
      rtl_batch_array: '6084781',
      name_search: 'LYSROBIN',
      name: "Lythrum sal. 'Robin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7476,
      chnn_stock_retail: 7476,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6H3BRAR7;CRYD8RGY;CYZE5C8R;YV2SZK1D;VLTH1ENY;ATVAJ9EB;C87AKT38;BDCT1PHL;WC2CTVC5;SC66XJ8T;JY48PRT8;XZ49W3AD;Y2D5ZL11;8AJZGPAK;JJ716DJL',
      statusContent: 'dot',
      hash: 'KR84XEW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KR84XEW8'
    },
    {
      status: 210,
      sku: 'PM0077464',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304818',
      rtl_batch_array: '6304818',
      name_search: 'ERDGPERF',
      name: "Erica darleyensis 'Golden Perfect'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZTPKGPE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTPKGPE1'
    },
    {
      status: 910,
      sku: 'PM0045520',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHOURY',
      name: "Chrysanthemum 'Oury'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'LL8LB4YT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LL8LB4YT'
    },
    {
      status: 210,
      sku: 'PM0045114',
      core_name: 'Plant',
      sppl_ean: '8720626304260',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591975',
      rtl_batch_array: '5591975',
      name_search: 'HEWHORSE',
      name: "Hemerocallis 'Wild Horses'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ATR9D7Z1',
      statusContent: 'dot',
      hash: 'N6YG75ZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6YG75ZC'
    },
    {
      status: 210,
      sku: 'PM0081113',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338526',
      rtl_batch_array: '6338526',
      name_search: 'TUVSLACE',
      name: "Tulbaghia violacea 'Silver Lace'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 610,
      chnn_stock_retail: 610,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5LS5GDDC;XKBE6E7V;G6SYLP3P;YDSW6T15;DCVL21D7;LZ456DDG;YK6TA9GT;8EWPTHDT;XTHYKNW5',
      statusContent: 'dot',
      hash: 'EKT59PTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKT59PTG'
    },
    {
      status: 210,
      sku: 'PM0077465',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304819',
      rtl_batch_array: '6304819',
      name_search: 'ERDJPORT',
      name: "Erica darleyensis 'J.W. Porter'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6BXXN87T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BXXN87T'
    },
    {
      status: 210,
      sku: 'PM0058062',
      core_name: 'Plant',
      sppl_ean: '8720664850873',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084991',
      rtl_batch_array: '6084991, 6301385, 6339732',
      name_search: 'AGRGJUBI',
      name: "Agastache rugosa 'Golden Jubilee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 529,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GXJAR78;148VXZX5;ZZK1328T;2V76AKLD;9Z4VJGYW',
      statusContent: 'dot',
      hash: '9RS63XJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RS63XJC'
    },
    {
      status: 210,
      sku: 'PM0077466',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304820',
      rtl_batch_array: '6304820',
      name_search: 'ERDJPORT',
      name: "Erica darleyensis 'J.W. Porter'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DGN72YX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGN72YX7'
    },
    {
      status: 210,
      sku: 'PM0081114',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338528',
      rtl_batch_array: '6338528',
      name_search: 'VAVIFIRE',
      name: 'Vaccinium vitis-idaea Fireballs',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G13CT6TW;Y3NL3NLK;GXGDY6ER;KSCSCWL2;REH5SCCW;19EREVBK;Z6K289TP;4ZDTLDSN;PJH32HBK;DK5SLYWD;SS5ZHJKH;6YEDXS5K;Z6NBYJXL',
      statusContent: 'dot',
      hash: 'NA7EC1X5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NA7EC1X5'
    },
    {
      status: 210,
      sku: 'PM0074212',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300187',
      rtl_batch_array: '6300187, 6310248',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2186,
      chnn_stock_retail: 7765,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'B2X9VJYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2X9VJYJ'
    },
    {
      status: 810,
      sku: 'PM0081115',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338529',
      rtl_batch_array: '6338529',
      name_search: 'VAVIMCHE',
      name: 'Vaccinium vitis-idaea Miss Cherry',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C5NBADTZ;4HAE79RV;TSV3S85J;N3SJTX3L;W2Y12E43;JT9W3LDH;V6APPHX1;PGJDAPLV;28LT54G1;PDXKPVAK;GV4ZJEG3;Y5GT11E8;146E4RJZ;XK1KSAGN;JV5R576T',
      statusContent: 'dot',
      hash: 'RR6CDBB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR6CDBB2'
    },
    {
      status: 210,
      sku: 'PM0081116',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338530',
      rtl_batch_array: '6338530',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1439,
      chnn_stock_retail: 1439,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: '4T1CZ995',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T1CZ995'
    },
    {
      status: 210,
      sku: 'PM0081117',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338531',
      rtl_batch_array: '6338531',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 3196,
      chnn_stock_retail: 3196,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'C89N93RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C89N93RC'
    },
    {
      status: 210,
      sku: 'PM0077629',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305233',
      rtl_batch_array: '6305233',
      name_search: 'THELIN',
      name: "Thalictrum 'Elin'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 830,
      chnn_stock_retail: 830,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PGNTR1L3',
      statusContent: 'dot',
      hash: '54ZXV76G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54ZXV76G'
    },
    {
      status: 210,
      sku: 'PM0058059',
      core_name: 'Plant',
      sppl_ean: '8720664679702',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084984',
      rtl_batch_array: '6084984',
      name_search: 'ACARENAR',
      name: 'Actinostrobus arenarius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1055,
      chnn_stock_retail: 1055,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G63YZPKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G63YZPKE'
    },
    {
      status: 210,
      sku: 'PM0024840',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353071',
      rtl_batch_array: '6353071',
      name_search: 'SECHAY',
      name: "Sempervivum 'Commander Hay'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKPE835Z;V3RLD65P;TPSSAJL7;JSB17NE3;9KV54ZEV;22RGWG7B;NHAEVPT2;5XR5P2T8;9TZG72A2;1E6DDPRZ;9RPTKD2K;HCVN57EL;EZTBZWPE;WBSG2SC5;SLS6RWJ2;LG9ELWB7',
      statusContent: 'dot',
      hash: 'C4DGHDP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4DGHDP1'
    },
    {
      status: 210,
      sku: 'PM0063044',
      core_name: 'Plant',
      sppl_ean: '8720626371774',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364528',
      rtl_batch_array: '6242993, 6147912, 5364528',
      name_search: 'STBBEARS',
      name: "Stachys byzantina 'Big Ears'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 157,
      chnn_stock_retail: 2992,
      sppl_order_minimum: 3,
      sppl_prcp: 0.819,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4JCV69K8;SZZJEA95;ZCLZRH88;KE63733B',
      statusContent: 'dot',
      hash: 'BZ8TP1S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ8TP1S9'
    },
    {
      status: 910,
      sku: 'PM0045515',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHHEBE',
      name: "Chrysanthemum (K) 'Hebe'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1E3PKYCR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1E3PKYCR'
    },
    {
      status: 210,
      sku: 'PM0058038',
      core_name: 'Plant',
      sppl_ean: '8720664861671',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084903',
      rtl_batch_array: '6084903',
      name_search: 'ERABEAUT',
      name: "Erigeron 'Azure Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4790,
      chnn_stock_retail: 4790,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGDKKZ8T',
      statusContent: 'dot',
      hash: 'PSKS3DLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSKS3DLA'
    },
    {
      status: 810,
      sku: 'PM0081118',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338532',
      rtl_batch_array: '6338532',
      name_search: 'VICARLES',
      name: 'Viburnum carlesii',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 4.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6Z2V4TWK;AJX6342C;WXC85EJK',
      statusContent: 'dot',
      hash: '6YTH9638',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YTH9638'
    },
    {
      status: 210,
      sku: 'PM0077451',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304805',
      rtl_batch_array: '6304805',
      name_search: 'CYBRUBY',
      name: "Cytisus 'Boskoop Ruby'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1404,
      chnn_stock_retail: 1404,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6XDLNP54;S7N89E4J;5ZVLRWGH;25AGBBWD;X8427L61;JHDNVA1C;BWADA7DX;27NXV3C8;K1WNNNY2;TAD2NXBN;5XSLW295;LTLEYA2J;X6NSPA47;ZAV9Z5E5;47XCXRJD',
      statusContent: 'dot',
      hash: 'GY7YYTZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GY7YYTZJ'
    },
    {
      status: 210,
      sku: 'PM0068922',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205313',
      rtl_batch_array: '6205313',
      name_search: 'PHSBLUE',
      name: "Phlox (P) 'Swizzle Blue'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1B2PHPT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1B2PHPT1'
    },
    {
      status: 210,
      sku: 'PM0077628',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305230',
      rtl_batch_array: '6254821, 6305230',
      name_search: 'ROAESCUL',
      name: 'Rodgersia aesculifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1068,
      chnn_stock_retail: 2010,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XW9JNYXW;YDHR7WLJ;ZKREDZ92;9Y1VRZ5W;N1LJS7HT',
      statusContent: 'dot',
      hash: 'NK58AZVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NK58AZVJ'
    },
    {
      status: 210,
      sku: 'PM0068530',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205814',
      rtl_batch_array: '6205814',
      name_search: 'RHFAIRYT',
      name: 'Rhodoxis Fairytale',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_prcp: 2.438,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X92YGTWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X92YGTWH'
    },
    {
      status: 210,
      sku: 'PM0070903',
      core_name: 'Plant',
      sppl_ean: '8720349413973',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5860542',
      rtl_batch_array: '5860542',
      name_search: 'CORSSSTA',
      name: "Cortaderia selloana 'Silver Star'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 218,
      chnn_stock_retail: 218,
      sppl_prcp: 5.502,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75KSV51Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75KSV51Y'
    },
    {
      status: 210,
      sku: 'PM0077631',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305235',
      rtl_batch_array: '6305235',
      name_search: 'CYFALCAT',
      name: 'Cyrtomium falcatum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1762,
      chnn_stock_retail: 1762,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B9YDTA6X;CJ2LTKX5;BCZCYHHZ;D59BS6VC;Z2GNVJ33;S9B3G2WC;52VXY6D6;2A72VB8J;8LWP9845;1VJ4SRY4;8KN5W59K;2X8SX8H2;6612BKB3;6Y1H9LAC;YSH6LWC4;4VLSVYC4;B3V927PJ;44A9WXTD;Y4SL5973;PD6E2YCT;8GXRP556;5VZLG2CN;HZD95NHC;DKT3PY1P;G9DJAEBT',
      statusContent: 'dot',
      hash: 'H1XYAV3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1XYAV3J'
    },
    {
      status: 810,
      sku: 'PM0065808',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281014',
      rtl_batch_array: '6281014',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'BCLVPP9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCLVPP9L'
    },
    {
      status: 210,
      sku: 'PM0045464',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307964',
      rtl_batch_array: '6238735, 6307964',
      name_search: 'ASWPINK',
      name: "Aster 'Wood's Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 227,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X11DLRPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X11DLRPC'
    },
    {
      status: 210,
      sku: 'PM0068926',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 3,
      btch_active_retail: '6205322',
      rtl_batch_array: '6205322, 6205323, 6205324',
      name_search: 'SANEMORO',
      name: 'Salvia nemorosa',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SDAT1NPL;HJ5581G9;N3R1LP5L;1LKBD2DS;5E3NERRG;7AE6ND5L;ZVJ7SSR7;85XKZYPR;6Y96JXG6;CKLYSL79;NASB73P2;J2JNXNV8;HDCEGY71;DPTTKE51;C8SLAG3A;AWXGN1N7',
      statusContent: 'dot',
      hash: '1PABD2G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PABD2G6'
    },
    {
      status: 810,
      sku: 'PM0045504',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349931',
      rtl_batch_array: '6349931, 6301507',
      name_search: 'CHMOSCHA',
      name: 'Chelonopsis moschata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 124,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ND9NNJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ND9NNJH'
    },
    {
      status: 210,
      sku: 'PM0045457',
      core_name: 'Plant',
      sppl_ean: '8720353087511',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014400',
      rtl_batch_array: '6014400',
      name_search: 'ASPDEMOT',
      name: 'Aster pilosus demotus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 269,
      chnn_stock_retail: 269,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRV11SKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRV11SKG'
    },
    {
      status: 210,
      sku: 'PM0068590',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353490',
      rtl_batch_array: '6353490',
      name_search: 'VIMWPOWE',
      name: "Vinca minor 'White Power'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L4KRXRP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4KRXRP5'
    },
    {
      status: 210,
      sku: 'PM0068924',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205315',
      rtl_batch_array: '6205315',
      name_search: 'SASRMARV',
      name: "Salvia sylvestris 'Rose Marvel'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6WGNWTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6WGNWTT'
    },
    {
      status: 210,
      sku: 'PM0077630',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305234',
      rtl_batch_array: '6305234',
      name_search: 'TIPSKYRO',
      name: "Tiarella 'Pink Skyrocket'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 514,
      chnn_stock_retail: 514,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JY1GCRDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY1GCRDN'
    },
    {
      status: 210,
      sku: 'PM0077460',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304814',
      rtl_batch_array: '6304814',
      name_search: 'ERAEGOLD',
      name: "Erica arborea 'Estrella Gold'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K38Y7GRJ',
      statusContent: 'dot',
      hash: 'R6WJXC9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6WJXC9R'
    },
    {
      status: 210,
      sku: 'PM0081119',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338533',
      rtl_batch_array: '6338533, 6348852',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 6457,
      chnn_stock_retail: 7207,
      sppl_prcp: 3.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'KXLKR7ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXLKR7ED'
    },
    {
      status: 210,
      sku: 'PM0045482',
      core_name: 'Plant',
      sppl_ean: '8720349491834',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014712',
      rtl_batch_array: '6014712',
      name_search: 'CAPSPLUG',
      name: "Carex phyllocephala 'Spark Plug'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCNW83NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCNW83NS'
    },
    {
      status: 210,
      sku: 'PM0045662',
      core_name: 'Plant',
      sppl_ean: '8720664681354',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5764884',
      rtl_batch_array: '5764884',
      name_search: 'ASVVULCA',
      name: "Astilbe 'Vision Vulcano'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CG9S46ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CG9S46ED'
    },
    {
      status: 210,
      sku: 'PM0081120',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338535',
      rtl_batch_array: '6338535',
      name_search: 'VIDANGUS',
      name: "Viburnum davidii 'Angustifolium'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 3,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '68W7SP2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68W7SP2L'
    },
    {
      status: 210,
      sku: 'PM0081121',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338536',
      rtl_batch_array: '6338536',
      name_search: 'VIFCANDI',
      name: "Viburnum farreri 'Candidissimum'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2WACSB2',
      statusContent: 'dot',
      hash: 'CBNTEE9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBNTEE9W'
    },
    {
      status: 210,
      sku: 'PM0081122',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338537',
      rtl_batch_array: '6338537',
      name_search: 'VIHENRYI',
      name: 'Viburnum henryi',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_prcp: 3.386,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATDSED11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATDSED11'
    },
    {
      status: 210,
      sku: 'PM0081123',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338538',
      rtl_batch_array: '6338538',
      name_search: 'VIHWINTO',
      name: "Viburnum hillieri 'Winton'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 3.386,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1CVRYRWV',
      statusContent: 'dot',
      hash: '9E43BXD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E43BXD3'
    },
    {
      status: 210,
      sku: 'PM0068925',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205316',
      rtl_batch_array: '6205316',
      name_search: 'SANBBOUQ',
      name: 'Salvia nemorosa Blue Bouquetta',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RSCB9VV9;XRAVE9DX;C58DRTV3;CYGNHX75;ZYK4R2WH;1YJ2TD4C',
      statusContent: 'dot',
      hash: 'VY3TYDW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY3TYDW5'
    },
    {
      status: 210,
      sku: 'PM0081124',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338539',
      rtl_batch_array: '6338539',
      name_search: 'VIJAPONI',
      name: 'Viburnum japonicum',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8S3114SZ;8Z6A2SA3;N679K79G;CSJ3C7BC',
      statusContent: 'dot',
      hash: 'P5P935C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5P935C8'
    },
    {
      status: 210,
      sku: 'PM0068927',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205319',
      rtl_batch_array: '6205319',
      name_search: 'SALNSWHI',
      name: 'Salvia nemorosa Sensation White',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JNT1SLXV;C9VG95GX;4DY5WHDT;9K3C4B1B;KD8C4YP8',
      statusContent: 'dot',
      hash: 'AJJPPSD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJJPPSD9'
    },
    {
      status: 210,
      sku: 'PM0068594',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197006',
      rtl_batch_array: '6197006, 6254873',
      name_search: 'AJRATROP',
      name: "Ajuga reptans 'Atropurpurea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1548,
      chnn_stock_retail: 2548,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NYXH8SZ5;RYZVDHRL;43164DTS;4CRTNCEC;JJBC7P65;8XEL25RD;A8HBGSG2',
      statusContent: 'dot',
      hash: 'DAPKPT76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAPKPT76'
    },
    {
      status: 210,
      sku: 'PM0068533',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373577',
      rtl_batch_array: '6373577, 6254868',
      name_search: 'ACSBRUNE',
      name: "Actaea simplex 'Brunette'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 326,
      chnn_stock_retail: 1756,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V227LA75;ZSNVBLB1;HT3ALK3A;KALLKRJ4;HYP2PWZ9',
      statusContent: 'dot',
      hash: 'H7K3SX5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7K3SX5X'
    },
    {
      status: 210,
      sku: 'PM0045274',
      core_name: 'Plant',
      sppl_ean: '8720349448043',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054304',
      rtl_batch_array: '6054304',
      name_search: 'AGSLININ',
      name: "Agapanthus 'Silver Lining'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XW716SNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW716SNL'
    },
    {
      status: 210,
      sku: 'PM0081125',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338540',
      rtl_batch_array: '6338540',
      name_search: 'VIGJGLOB',
      name: "Viburnum globosum 'Jermyns Globe'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LAL4C8SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAL4C8SS'
    },
    {
      status: 210,
      sku: 'PM0081126',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338541',
      rtl_batch_array: '6338541',
      name_search: 'VIOAWABU',
      name: 'Viburnum odoratissimum awabuki',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5N6VBG7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N6VBG7B'
    },
    {
      status: 210,
      sku: 'PM0045494',
      core_name: 'Plant',
      sppl_ean: '8720664692619',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5741223',
      rtl_batch_array: '5741223',
      name_search: 'HOSTING',
      name: "Hosta 'Sting'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SBCGH2P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBCGH2P6'
    },
    {
      status: 210,
      sku: 'PM0045479',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229863',
      rtl_batch_array: '6229863, 6054454',
      name_search: 'ASCFINAL',
      name: "Astilbe chinensis 'Finale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 507,
      chnn_stock_retail: 603,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZPA8TYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZPA8TYR'
    },
    {
      status: 210,
      sku: 'PM0054475',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278467',
      rtl_batch_array: '6278467',
      name_search: 'ANHSCHAR',
      name: "Anemone hupehensis 'September Charm'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6337,
      chnn_stock_retail: 6337,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYA7S356;K4GEXNBW;RX7Z4A7B;5RVJXA14;8J2D3GSL;TEGRHXT8;N4XSZ13K;KXCRH8ZS;XHEGBS6C;G53YNSNJ',
      statusContent: 'dot',
      hash: 'GELCHK1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GELCHK1Z'
    },
    {
      status: 210,
      sku: 'PM0063434',
      core_name: 'Plant',
      sppl_ean: '8720353082516',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5263149',
      rtl_batch_array: '5263149',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: '015020C1',
      rng_range_identifier: 'H015020C1',
      rng_range_description: 'H15 cm 20 cm C1',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      imageBatch: '64WNLLVL',
      statusContent: 'dot',
      hash: 'Y5T8BWR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5T8BWR8'
    },
    {
      status: 210,
      sku: 'PM0081127',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338542',
      rtl_batch_array: '6338542',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 441,
      chnn_stock_retail: 441,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: 'L7J88RDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7J88RDY'
    },
    {
      status: 210,
      sku: 'PM0069046',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271356',
      rtl_batch_array: '6271356',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1936,
      chnn_stock_retail: 1936,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'G7BKZ168',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7BKZ168'
    },
    {
      status: 210,
      sku: 'PM0070917',
      core_name: 'Plant',
      sppl_ean: '8720664886834',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149373',
      rtl_batch_array: '6149373',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: '5BJJNSRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BJJNSRV'
    },
    {
      status: 210,
      sku: 'PM0081128',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338543',
      rtl_batch_array: '6338543',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1203,
      chnn_stock_retail: 1203,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'NCZLP8XN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCZLP8XN'
    },
    {
      status: 810,
      sku: 'PM0053477',
      core_name: 'Plant',
      sppl_ean: '8720664687004',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054587',
      rtl_batch_array: '6054587',
      name_search: 'DEJDCAND',
      name: 'Delosperma Jewel of Desert Candystone',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ES48NW3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ES48NW3V'
    },
    {
      status: 210,
      sku: 'PM0068581',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271331',
      rtl_batch_array: '6271331, 6378461',
      name_search: 'BUDWFPOW',
      name: "Buddleja weyeriana 'Flower Power'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 906,
      chnn_stock_retail: 2396,
      sppl_prcp: 1.681,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V6LS63EP;BLAL1XLT;ZH17R6WH;DHJZ9BX4;D38JR32Y;YZ9SDLYE;AWNPBXSK;6KZSHGC3;9XR7P9S1;L7BEDJL8;HL2CGBTW;RBSBP6HV;DWYY3NXE;DPZZJDPJ;K93KCZR7;ZLJHA95N',
      statusContent: 'dot',
      hash: 'BVNZBZGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVNZBZGL'
    },
    {
      status: 210,
      sku: 'PM0081129',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338544',
      rtl_batch_array: '6338544',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: '8JSJLJAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8JSJLJAD'
    },
    {
      status: 810,
      sku: 'PM0081130',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338545',
      rtl_batch_array: '6338545',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: 'TJ386BDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJ386BDR'
    },
    {
      status: 210,
      sku: 'PM0081131',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338546',
      rtl_batch_array: '6338546',
      name_search: 'VIPPBEAU',
      name: "Viburnum plicatum 'Pink Beauty'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1403,
      chnn_stock_retail: 1403,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J53K7XR',
      statusContent: 'dot',
      hash: '5SD3R95X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SD3R95X'
    },
    {
      status: 210,
      sku: 'PM0063506',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253094',
      rtl_batch_array: '6253094',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 351,
      chnn_stock_retail: 351,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: 'H8XBP7WW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8XBP7WW'
    },
    {
      status: 210,
      sku: 'PM0081132',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338547',
      rtl_batch_array: '6338547',
      name_search: 'VIPSHAST',
      name: "Viburnum plicatum 'Shasta'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1741,
      chnn_stock_retail: 1741,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XRE1NK9C;7J2ZVNKA;J63DRY3V;PH3G8GJ1',
      statusContent: 'dot',
      hash: '51P25SP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51P25SP5'
    },
    {
      status: 210,
      sku: 'PM0081133',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338549',
      rtl_batch_array: '6338549',
      name_search: 'VIPRAGEN',
      name: "Viburnum 'Pragense'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YCWLP74;4P3G8PK3',
      statusContent: 'dot',
      hash: '7K2Y2GET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7K2Y2GET'
    },
    {
      status: 210,
      sku: 'PM0045450',
      core_name: 'Plant',
      sppl_ean: '8720664852433',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6077478',
      rtl_batch_array: '6077478',
      name_search: 'ASNERON',
      name: "Aster 'Neron'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6XB11GYZ;ZBRSV9RG;8X53KKXC;Z93VGCX4;68R9PLLW;K2BD21TK;41RNERKK',
      statusContent: 'dot',
      hash: 'HE1ABE1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HE1ABE1Z'
    },
    {
      status: 210,
      sku: 'PM0081134',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338550',
      rtl_batch_array: '6369364, 6338550',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4885,
      chnn_stock_retail: 5385,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '94ZP2BC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94ZP2BC8'
    },
    {
      status: 210,
      sku: 'PM0081135',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338551',
      rtl_batch_array: '6338551',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4558,
      chnn_stock_retail: 4558,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'NLCGJJ1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLCGJJ1V'
    },
    {
      status: 210,
      sku: 'PM0026472',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 5,
      btch_active_retail: '6284541',
      rtl_batch_array: '6298722, 6254801, 6284541, 4040488, 5837471',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4327,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      statusContent: 'dot',
      hash: '4JJNA6GN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JJNA6GN'
    },
    {
      status: 210,
      sku: 'PM0045487',
      core_name: 'Plant',
      sppl_ean: '8720664684010',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054524',
      rtl_batch_array: '6054524',
      name_search: 'CADPILLU',
      name: "Caryopteris divaricata 'Pink Illumination'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '65W5EY34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65W5EY34'
    },
    {
      status: 210,
      sku: 'PM0070910',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278306',
      rtl_batch_array: '6278306',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: '63XHX8W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63XHX8W9'
    },
    {
      status: 910,
      sku: 'PM0074213',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'Z7RJ7Z1R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z7RJ7Z1R'
    },
    {
      status: 210,
      sku: 'PM0068523',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205807',
      rtl_batch_array: '6205807',
      name_search: 'ALMOLLIS',
      name: 'Alchemilla mollis',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 508,
      chnn_stock_retail: 508,
      sppl_order_minimum: 3,
      sppl_prcp: 1.448,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EHLKNB2V;CK9A2PG8;R2SRHX1J;J9RBBHEJ;HCWV9H57;NJDA177T;REXB3W18;N1JS3HZA;4VV1D59B;5RL49APS;JXHWCWSD;JZ5XW2K7;4K3P7PLL;E31H5L6W;KK56BZ8H;J1PS1T6J;YJ9B9V15;7H4ANG7G;ES93584N;C5Z4N6KL;VPHGPC3X;CTZCKHSA;1C75XRN8;1856E8BZ',
      statusContent: 'dot',
      hash: 'T3KV6TS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3KV6TS9'
    },
    {
      status: 210,
      sku: 'PM0069930',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219505',
      rtl_batch_array: '6219505',
      name_search: 'ORROSENK',
      name: "Origanum 'Rosenkuppel'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G2EEVJAP;XY7774Z3;HB8211SP',
      statusContent: 'dot',
      hash: 'WY5YTHB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WY5YTHB6'
    },
    {
      status: 210,
      sku: 'PM0069932',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219507',
      rtl_batch_array: '6219507',
      name_search: 'PESCHOEN',
      name: "Penstemon 'Schoenholzeri'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 211,
      chnn_stock_retail: 211,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5T2A8LY7;V3YSGXHP;W2GDEZHR;DARV6B3G;ELZ7SATW;GS8LXKP2;RH6VYSGV;HCXW7BVN;73HGHGE1;WBPGGZKZ;5AJEJKB4;CSVBB9JJ;L9TS84ZC;RHLHXKKH;ASLR29YC;NKHDRKYH;6GNS8N2S;YPN4R6JR;EYG1W6VA;ZLXVRTY3;9A4SAVLR;LGJW5C45;HGAAE936',
      statusContent: 'dot',
      hash: 'AZB75Z6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZB75Z6R'
    },
    {
      status: 210,
      sku: 'PM0063032',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6363207',
      rtl_batch_array: '6220916, 5539729, 6370401, 6363207',
      name_search: 'SECLIDAK',
      name: "Sedum cauticola 'Lidakense'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2664,
      chnn_stock_retail: 5741,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GZBRLZHE;CYPYEWYK;XPZHS35V;6D7BBJTB;Z1LA6DHC;DJVC4YNT;11TDXX2L;7P1V9C96;S7LK3DJL;D22LJS4E;GXD7SGPD',
      statusContent: 'dot',
      hash: 'DP2GTH5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP2GTH5S'
    },
    {
      status: 210,
      sku: 'PM0045135',
      core_name: 'Plant',
      sppl_ean: '8720349409921',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592024',
      rtl_batch_array: '5592024',
      name_search: 'ASTONGA',
      name: 'Aster Tonga',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RYJRVNN4',
      statusContent: 'dot',
      hash: 'WNBWVYDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNBWVYDE'
    },
    {
      status: 910,
      sku: 'PM0033644',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASSPRITE',
      name: "Astilbe (S) 'Sprite'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'TNKKXZC5;VCD2ZEYW',
      statusContent: 'dot',
      hash: 'JYE5TSCH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JYE5TSCH'
    },
    {
      status: 210,
      sku: 'PM0068539',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205980',
      rtl_batch_array: '6205980',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      statusContent: 'dot',
      hash: '8ATDR88Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ATDR88Z'
    },
    {
      status: 210,
      sku: 'PM0045267',
      core_name: 'Plant',
      sppl_ean: '8720626333543',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054277',
      rtl_batch_array: '6054277',
      name_search: 'AGENIGMA',
      name: "Agapanthus 'Enigma'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 442,
      chnn_stock_retail: 442,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NYJKJW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NYJKJW7'
    },
    {
      status: 210,
      sku: 'PM0047072',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295925',
      rtl_batch_array: '6057393, 6295925',
      name_search: 'LESPBLAC',
      name: "Leptinella squalida 'Platt's Black'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 421,
      chnn_stock_retail: 751,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXBVNCK8;GP7E1314;V2JA1GJA;9GKC161Y;8HX1X8Y2;86493HK2;285TPDXC;8CERPCBL',
      statusContent: 'dot',
      hash: '5RCLJ2W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RCLJ2W5'
    },
    {
      status: 210,
      sku: 'PM0085066',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369744',
      rtl_batch_array: '6369744',
      name_search: 'HEPPANTH',
      name: "Heuchera 'Pink Panther'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 392,
      chnn_stock_retail: 392,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2715P2RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2715P2RX'
    },
    {
      status: 210,
      sku: 'PM0081136',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338552',
      rtl_batch_array: '6338552',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 860,
      chnn_stock_retail: 860,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'PEDHAJDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEDHAJDX'
    },
    {
      status: 210,
      sku: 'PM0045557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329855',
      rtl_batch_array: '6329855, 6317836',
      name_search: 'CANWCLOU',
      name: "Calamintha nepeta 'White Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2038,
      chnn_stock_retail: 2572,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ACW557L7',
      statusContent: 'dot',
      hash: 'RY5SB4ZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RY5SB4ZN'
    },
    {
      status: 210,
      sku: 'PM0077467',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304821',
      rtl_batch_array: '6304821',
      name_search: 'ERDKROTE',
      name: "Erica darleyensis 'Kramer's Rote'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 509,
      chnn_stock_retail: 509,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SLBL71PR',
      statusContent: 'dot',
      hash: 'AXTYJXR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXTYJXR8'
    },
    {
      status: 210,
      sku: 'PM0045262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300409',
      rtl_batch_array: '6300409',
      name_search: 'ADCHEMEL',
      name: "Adenophora confusa 'Hemelstraling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 392,
      chnn_stock_retail: 392,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEZ3B33R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEZ3B33R'
    },
    {
      status: 210,
      sku: 'PM0070921',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222168',
      rtl_batch_array: '6222168',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'PNETD59E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNETD59E'
    },
    {
      status: 210,
      sku: 'PM0077469',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304823',
      rtl_batch_array: '6304823',
      name_search: 'ERDMHELE',
      name: "Erica darleyensis 'Mary Helen'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 939,
      chnn_stock_retail: 939,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHZ3HYL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHZ3HYL6'
    },
    {
      status: 210,
      sku: 'PM0077470',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304824',
      rtl_batch_array: '6304824',
      name_search: 'ERDWPERF',
      name: "Erica darleyensis 'White Perfection'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 674,
      chnn_stock_retail: 674,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZV6Y1E8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV6Y1E8A'
    },
    {
      status: 210,
      sku: 'PM0077471',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207251',
      rtl_batch_array: '5207251, 6304825',
      name_search: 'ERDWPERF',
      name: "Erica darleyensis 'White Perfection'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 497,
      chnn_stock_retail: 1466,
      sppl_order_minimum: 3,
      sppl_prcp: 1.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '67GZNGK7',
      statusContent: 'dot',
      hash: 'X8WJZS2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8WJZS2A'
    },
    {
      status: 210,
      sku: 'PM0077472',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304826',
      rtl_batch_array: '6304826',
      name_search: 'ERICA',
      name: "Erica in cv's",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 789,
      chnn_stock_retail: 789,
      sppl_order_minimum: 3,
      sppl_prcp: 0.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WA81G8AR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WA81G8AR'
    },
    {
      status: 210,
      sku: 'PM0081137',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338553',
      rtl_batch_array: '6338553',
      name_search: 'VITLADYB',
      name: "Viburnum tinus 'Ladybird'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2070,
      chnn_stock_retail: 2070,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DSHJWHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DSHJWHE'
    },
    {
      status: 210,
      sku: 'PM0077473',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304827',
      rtl_batch_array: '6304827',
      name_search: 'ERICA',
      name: "Erica in cv's",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7WAC5KHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WAC5KHW'
    },
    {
      status: 210,
      sku: 'PM0057997',
      core_name: 'Plant',
      sppl_ean: '8720664698178',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084767',
      rtl_batch_array: '6084767',
      name_search: 'LOSSDROS',
      name: "Lobelia speciosa 'Starship Deep Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 770,
      chnn_stock_retail: 770,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6DJ2V46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6DJ2V46'
    },
    {
      status: 210,
      sku: 'PM0077474',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304828',
      rtl_batch_array: '6304828',
      name_search: 'ERMYOPOR',
      name: 'Eriostemon myoporoides',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2838,
      chnn_stock_retail: 2838,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CVGPZS2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVGPZS2D'
    },
    {
      status: 210,
      sku: 'PM0077475',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304829',
      rtl_batch_array: '6304829',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1894,
      chnn_stock_retail: 1894,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'HLPVEVLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLPVEVLW'
    },
    {
      status: 210,
      sku: 'PM0057104',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217122',
      rtl_batch_array: '6217122',
      name_search: 'ECSCORAL',
      name: 'Echinacea Sensation Coral',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANS4781S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANS4781S'
    },
    {
      status: 210,
      sku: 'PM0045535',
      core_name: 'Plant',
      sppl_ean: '8720664685482',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054534',
      rtl_batch_array: '6260115, 6054534',
      name_search: 'COVLADYB',
      name: "Coreopsis vert. 'Ladybird'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 424,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H5CES1Y7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5CES1Y7'
    },
    {
      status: 210,
      sku: 'PM0077632',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305761',
      rtl_batch_array: '6305761',
      name_search: 'SAGREGGI',
      name: 'Salvia greggii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DCHWBAWK;R88KKAB7;ECBSHP9N;SWTWZKKK;L2G5SAN7;X931VTWB;CHBD4HWR;6KE58T7A',
      statusContent: 'dot',
      hash: 'XRXCRXGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRXCRXGN'
    },
    {
      status: 910,
      sku: 'PM0074214',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      statusContent: 'dot',
      hash: 'XDBAYLX2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XDBAYLX2'
    },
    {
      status: 210,
      sku: 'PM0077476',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304830',
      rtl_batch_array: '6304830',
      name_search: 'ESGCARPE',
      name: 'Escallonia Golden Carpet',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 939,
      chnn_stock_retail: 939,
      sppl_prcp: 3.121,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '15AZJ829;YYY6LX5W;XYWEVKY8;SRWSX27E;KR5A47DG;GH7WZ2HR;TJYX1NDB;XY8JD1JG;2J6PEATL;PV1ER54E;D6TG7V5L;ZHW9XSN7;BDSLY64T;929RDH1D',
      statusContent: 'dot',
      hash: 'X1TTBNVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1TTBNVA'
    },
    {
      status: 210,
      sku: 'PM0077477',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6341902',
      rtl_batch_array: '6304831, 6341902',
      name_search: 'ESIVEYI',
      name: "Escallonia 'Iveyi'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1853,
      chnn_stock_retail: 3098,
      sppl_prcp: 2.09,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBWCL9PR;BHV9S7WZ;PZSG7SKR;LGJVDGY9;T7A7Y6GN',
      statusContent: 'dot',
      hash: 'A84YD6EX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A84YD6EX'
    },
    {
      status: 210,
      sku: 'PM0069047',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6270598',
      rtl_batch_array: '5932383, 6270598',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 980,
      chnn_stock_retail: 14462,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'CBBN5E1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBBN5E1D'
    },
    {
      status: 210,
      sku: 'PM0077478',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304832',
      rtl_batch_array: '6304832',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 5606,
      chnn_stock_retail: 5606,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'ZW67YG5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW67YG5D'
    },
    {
      status: 210,
      sku: 'PM0045553',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301475',
      rtl_batch_array: '6300454, 6301475',
      name_search: 'CAEPIGEJ',
      name: 'Calamagrostis epigejos',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 670,
      chnn_stock_retail: 1663,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YSTSRHWD',
      statusContent: 'dot',
      hash: 'SN3ARWR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN3ARWR4'
    },
    {
      status: 210,
      sku: 'PM0077479',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304833',
      rtl_batch_array: '6304833',
      name_search: 'ESRELF',
      name: "Escallonia 'Red Elf'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1173,
      chnn_stock_retail: 1173,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXGYT46J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXGYT46J'
    },
    {
      status: 210,
      sku: 'PM0077480',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304834',
      rtl_batch_array: '6304834',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'AALGGW5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AALGGW5B'
    },
    {
      status: 210,
      sku: 'PM0077481',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304835',
      rtl_batch_array: '6304835',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 963,
      chnn_stock_retail: 963,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'BBY7C87Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBY7C87Z'
    },
    {
      status: 210,
      sku: 'PM0077482',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304836',
      rtl_batch_array: '6304836',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 2785,
      chnn_stock_retail: 2785,
      sppl_prcp: 3.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'TR9G3RAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR9G3RAW'
    },
    {
      status: 210,
      sku: 'PM0045610',
      core_name: 'Plant',
      sppl_ean: '8720664860421',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147745',
      rtl_batch_array: '6147745',
      name_search: 'ECHCGREE',
      name: "Echinacea 'Choco Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 596,
      chnn_stock_retail: 596,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XE9Z2W7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE9Z2W7B'
    },
    {
      status: 810,
      sku: 'PM0052489',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350725',
      rtl_batch_array: '6350725',
      name_search: 'OPBENOIS',
      name: 'Ophiopogon benoistii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HLZXW5WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLZXW5WL'
    },
    {
      status: 210,
      sku: 'PM0069942',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193567',
      rtl_batch_array: '6193567',
      name_search: 'NYCOLORA',
      name: "Nymphaea 'Colorado'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KTJYLG7Z;D7KL67L2;8GXWW3NJ;WPP6KRRK;EWC4E5XK;TWXSAYV4;ATGNBB8D;AJV4GVLN;6K61WBBT;AHXLTN9A;CKZCEDPH;RE41E1KY;RTT4AENA;ABWW5PWT',
      statusContent: 'dot',
      hash: '5V581Y6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V581Y6A'
    },
    {
      status: 810,
      sku: 'PM0058384',
      core_name: 'Plant',
      sppl_ean: '8720664697225',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112680',
      rtl_batch_array: '6112680',
      name_search: 'LABSPRIN',
      name: "Lavatera 'Bredon Springs'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NP9221YV;BRS6TWXV;9GYGT59C',
      statusContent: 'dot',
      hash: 'WG7XS12Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WG7XS12Y'
    },
    {
      status: 210,
      sku: 'PM0077484',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304839',
      rtl_batch_array: '6304839',
      name_search: 'GAJDDIAM',
      name: 'Gardenia jasm. Double Diamonds',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2851,
      chnn_stock_retail: 2851,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KW1V734T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW1V734T'
    },
    {
      status: 810,
      sku: 'PM0057105',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217123',
      rtl_batch_array: '6217123',
      name_search: 'ECSHONEY',
      name: "Echinacea 'Sensation Honeygold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N6BTR2N3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6BTR2N3'
    },
    {
      status: 210,
      sku: 'PM0069943',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193568',
      rtl_batch_array: '6193568',
      name_search: 'NYESCARB',
      name: "Nymphaea 'Escarboucle'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 8.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PW4JKVDB;WY6LCHA4',
      statusContent: 'dot',
      hash: 'TSRR1NKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSRR1NKP'
    },
    {
      status: 210,
      sku: 'PM0031776',
      core_name: 'Plant',
      sppl_ean: '8720349412129',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4340675',
      rtl_batch_array: '4340675',
      name_search: 'CAOEVERL',
      name: "Carex oshimensis 'Everlime'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZX98YK1S;H2NY3RBY;4KAZZC91;83DYE284;5REY1D6C;KCJR1S1R;EBNLS9CG;42XWE7YH;LZ8ZLXEG;CHAW489E',
      imageBatch: '112S2LZ7',
      statusContent: 'dot',
      hash: 'NZLA6GVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZLA6GVB'
    },
    {
      status: 210,
      sku: 'PM0045334',
      core_name: 'Plant',
      sppl_ean: '8720349454372',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014515',
      rtl_batch_array: '6187509, 6014515',
      name_search: 'ASPHARRI',
      name: "Aster (D) 'Peter Harrison'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 523,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWN589DE;WYNZC35D;LTLVAK7Y;ZTRC9XCA;Y9RRAV7K',
      statusContent: 'dot',
      hash: 'JN2SR8BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN2SR8BV'
    },
    {
      status: 210,
      sku: 'PM0058082',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252962',
      rtl_batch_array: '6252962',
      name_search: 'SIUWEISS',
      name: "Silene uniflora 'Weisskehlchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H73CNLAT',
      statusContent: 'dot',
      hash: 'HT7NVX3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT7NVX3R'
    },
    {
      status: 210,
      sku: 'PM0068918',
      core_name: 'Plant',
      sppl_ean: '8720626340732',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953543',
      rtl_batch_array: '5953543',
      name_search: 'COREDSHI',
      name: "Coreopsis 'Redshift'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1748,
      chnn_stock_retail: 1748,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6A9ZGAB',
      statusContent: 'dot',
      hash: 'KTN23V25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTN23V25'
    },
    {
      status: 210,
      sku: 'PM0058070',
      core_name: 'Plant',
      sppl_ean: '8720664852082',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6085023',
      rtl_batch_array: '6085023',
      name_search: 'ARBLEPHA',
      name: 'Arabis blepharophylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '58KV9TJL;SNRKAKRN;4PRER7N1;G9HB78SK;7Z38B6DW;BHSH122E;LD96EV3D;9Y52RR97;HWLDBSTD;AE1S4CG8;8CS3N89Y;W3RTA96T;LN9WCECC;6HPRYGYB;24VJN375;2JSAY6EV',
      statusContent: 'dot',
      hash: 'WSSTHZZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSSTHZZY'
    },
    {
      status: 810,
      sku: 'PM0045341',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339746',
      rtl_batch_array: '6295713, 6339746',
      name_search: 'ASAASMO',
      name: "Aster ageratoides 'Asmo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 814,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZL3HYVZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZL3HYVZ3'
    },
    {
      status: 210,
      sku: 'PM0077638',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305767',
      rtl_batch_array: '6305767',
      name_search: 'KNPRED',
      name: 'Kniphofia Poco Red',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_prcp: 3.299,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8TRZTVA3',
      statusContent: 'dot',
      hash: 'SN7HSJSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN7HSJSD'
    },
    {
      status: 210,
      sku: 'PM0069938',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193559',
      rtl_batch_array: '6193559',
      name_search: 'VASPIRAL',
      name: 'Vallisneria spiralis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7KWD6PR;JLJB5C1N;CG6DNHDC',
      statusContent: 'dot',
      hash: 'X1TLPSTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1TLPSTY'
    },
    {
      status: 210,
      sku: 'PM0081138',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338554',
      rtl_batch_array: '6338554',
      name_search: 'VITMACRO',
      name: "Viburnum tinus 'Macrophyllum'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 895,
      chnn_stock_retail: 895,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JGBKC9LV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGBKC9LV'
    },
    {
      status: 210,
      sku: 'PM0081139',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338555',
      rtl_batch_array: '6338555',
      name_search: 'VITPURPU',
      name: "Viburnum tinus 'Purpureum'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1078,
      chnn_stock_retail: 1078,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S5RS1R72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5RS1R72'
    },
    {
      status: 210,
      sku: 'PM0057990',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6231153',
      rtl_batch_array: '6231153, 6164323, 6348352',
      name_search: 'LAMWNANC',
      name: "Lamium maculatum 'White Nancy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2196,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DP1KYYLN;6DXKXJ2K;DG1G149H;4PWEXTXA;TRKTCS31',
      statusContent: 'dot',
      hash: '46GGYH94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46GGYH94'
    },
    {
      status: 210,
      sku: 'PM0081140',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338556',
      rtl_batch_array: '6338556',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 920,
      chnn_stock_retail: 920,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: '785DCGBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '785DCGBK'
    },
    {
      status: 210,
      sku: 'PM0057995',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176252',
      rtl_batch_array: '6176252, 6112574',
      name_search: 'LISKOBOL',
      name: "Liatris spicata 'Kobold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8598,
      chnn_stock_retail: 10990,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5B5GENNE;CL16HNJ3;ZTDASLE6;BC94D4EC;GV5NX2PY;XEBRDZEH;VPTL1N53;212TTE5R;AT4XWSS9;T3SBT4DS;ZDWEE1DS;8T6C2S56;6GW9XA89',
      statusContent: 'dot',
      hash: 'T9HVDBSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9HVDBSR'
    },
    {
      status: 210,
      sku: 'PM0077645',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305776',
      rtl_batch_array: '6305776',
      name_search: 'NEVBLAUW',
      name: "Nepeta 'Veluw's Blauwtje'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '96D9SH69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96D9SH69'
    },
    {
      status: 210,
      sku: 'PM0069940',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193563',
      rtl_batch_array: '6193563',
      name_search: 'NYALBATR',
      name: "Nymphaea 'Albatross'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5Z5ECDY6;87WYADYS',
      statusContent: 'dot',
      hash: 'TRVCHE7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRVCHE7C'
    },
    {
      status: 210,
      sku: 'PM0069941',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193564',
      rtl_batch_array: '6193564',
      name_search: 'NYASTAR',
      name: "Nymphaea 'American Star'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RTTT41WC',
      statusContent: 'dot',
      hash: 'Z4SW4B2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4SW4B2D'
    },
    {
      status: 210,
      sku: 'PM0077636',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305765',
      rtl_batch_array: '6305765',
      name_search: 'GERMTAI',
      name: "Geum rivale 'Mai Tai'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 3.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PZLDWJGG;W2TEKS9J;RXX2X1YP;PBA2NDL9;X4JW76T7;5EZKTHEA;KDHA4PX5;DJTNP9AS;7W1SB9ZX;CG35143W;HJ1GPV37;Y7HYV9B4;2BW2XB14;C97BG347',
      statusContent: 'dot',
      hash: '4P3EC76D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P3EC76D'
    },
    {
      status: 910,
      sku: 'PM0069051',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204217',
      rtl_batch_array: '6204217',
      name_search: 'LOJRWORL',
      name: "Lonicera japonica 'Red World'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 6,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N7N6Z4JG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7N6Z4JG'
    },
    {
      status: 810,
      sku: 'PM0077637',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305766',
      rtl_batch_array: '6305766',
      name_search: 'KNARVENS',
      name: 'Knautia arvensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A29EX5CB;HLVG7VP2;T2A8G659',
      statusContent: 'dot',
      hash: 'GVK5K51V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVK5K51V'
    },
    {
      status: 210,
      sku: 'PM0081141',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338557',
      rtl_batch_array: '6338557',
      name_search: 'VITVARIE',
      name: "Viburnum tinus 'Variegatum'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 919,
      chnn_stock_retail: 919,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JAEC6CE7;7ZH6XBAK',
      statusContent: 'dot',
      hash: 'TYBAC7AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYBAC7AB'
    },
    {
      status: 210,
      sku: 'PM0081142',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338558',
      rtl_batch_array: '6338558',
      name_search: 'VIMAJOR',
      name: 'Vinca major',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3920,
      chnn_stock_retail: 3920,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9DVAL8E9;LKGVHAZJ;7V854YDB',
      statusContent: 'dot',
      hash: '6YASC7Y2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YASC7Y2'
    },
    {
      status: 810,
      sku: 'PM0077643',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305772',
      rtl_batch_array: '6305772',
      name_search: 'LYSPBLUS',
      name: "Lythrum salicaria 'Pink Blush'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5W585H5V',
      statusContent: 'dot',
      hash: 'ZXABWH8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXABWH8P'
    },
    {
      status: 210,
      sku: 'PM0077644',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305774',
      rtl_batch_array: '6305774',
      name_search: 'LYSLSACK',
      name: "Lythrum salicaria 'Lady Sackville'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 218,
      chnn_stock_retail: 218,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A3V4WT8N;RZ3HTA8W;6WYY2TEC;8BK6YA71;JE34ZA2C;PW8SXRX8;9SX1R9TL;H5W1WHST',
      statusContent: 'dot',
      hash: '7WENEGVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WENEGVW'
    },
    {
      status: 210,
      sku: 'PM0069937',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193558',
      rtl_batch_array: '6193558',
      name_search: 'PONATANS',
      name: 'Potamogeton natans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T6JB9LVV;C3XG3KDA',
      statusContent: 'dot',
      hash: 'JJVVZAK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJVVZAK6'
    },
    {
      status: 210,
      sku: 'PM0045291',
      core_name: 'Plant',
      sppl_ean: '8720349444335',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014368',
      rtl_batch_array: '6014368',
      name_search: 'ALHUMILI',
      name: 'Albuca humilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPNG2SET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPNG2SET'
    },
    {
      status: 910,
      sku: 'PM0069054',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204220',
      rtl_batch_array: '6204220',
      name_search: 'LOTELLMA',
      name: 'Lonicera tellmanniana',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 6,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ECJ3G;JGY84VDN;2JNBTDGT;WGHTB9ZK;LXY88137;9487HK3K',
      statusContent: 'dot',
      hash: '5G5JHTTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G5JHTTC'
    },
    {
      status: 210,
      sku: 'PM0081143',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338559',
      rtl_batch_array: '6338559',
      name_search: 'VIMVARIE',
      name: "Vinca major 'Variegata'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3120,
      chnn_stock_retail: 3120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZBSSC1Y;GXX15NG3;2BRXA81Y;8DX5YJZH;Y6RE5CYA',
      statusContent: 'dot',
      hash: 'YXH2P8H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXH2P8H9'
    },
    {
      status: 210,
      sku: 'PM0045613',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308550',
      rtl_batch_array: '6350035, 6302476, 6308550',
      name_search: 'ECDNOUGA',
      name: 'Echinacea Delicious Nougat',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 310,
      chnn_stock_retail: 722,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G1ATCZYZ;8ECTZ715',
      statusContent: 'dot',
      hash: '93DS7ED4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93DS7ED4'
    },
    {
      status: 210,
      sku: 'PM0077633',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305762',
      rtl_batch_array: '6305762',
      name_search: 'SARWTEXA',
      name: "Salvia reptans 'West Texas'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6DY1ZXYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DY1ZXYP'
    },
    {
      status: 210,
      sku: 'PM0045554',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349602',
      rtl_batch_array: '6349602, 6301476',
      name_search: 'CAVARIA',
      name: 'Calamagrostis varia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 652,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANN6X7VV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANN6X7VV'
    },
    {
      status: 210,
      sku: 'PM0057106',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187559',
      rtl_batch_array: '6187559',
      name_search: 'ECHSORAN',
      name: "Echinacea 'Sensation Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BGDELWTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGDELWTJ'
    },
    {
      status: 210,
      sku: 'PM0045545',
      core_name: 'Plant',
      sppl_ean: '8720626336827',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054496',
      rtl_batch_array: '6054496',
      name_search: 'BRSIBIRI',
      name: 'Brunnera sibirica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 811,
      chnn_stock_retail: 811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6PLR626',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6PLR626'
    },
    {
      status: 210,
      sku: 'PM0077646',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305778',
      rtl_batch_array: '6305778',
      name_search: 'ERKPROFU',
      name: "Erigeron karvinskianus 'Profusion'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.547,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DTWPJ4C5;PTKP3Y5P;G27GBAJV',
      statusContent: 'dot',
      hash: 'R56N3E7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R56N3E7K'
    },
    {
      status: 910,
      sku: 'PM0069053',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204219',
      rtl_batch_array: '6204219',
      name_search: 'LOPSEROT',
      name: "Lonicera periclymenum 'Serotina'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_order_minimum: 6,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJPNZYE2;ZN4CAHN1;Z2YKXPSL;16P279GS;E6LWJWGT;48YZ64JZ;75J4REZ7;R6YWKD37;WAVNK8D2',
      statusContent: 'dot',
      hash: 'H2GJAPJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2GJAPJ7'
    },
    {
      status: 210,
      sku: 'PM0081144',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338560',
      rtl_batch_array: '6338560',
      name_search: 'VIMINOR',
      name: 'Vinca minor',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 6090,
      chnn_stock_retail: 6090,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EG281Z1T;7PHP8SX2;A9ZBL78N;VZ4C57B6;YEEWSG3H;YZL8SB43;VWHK82DS;AG2GCWHL;HXBZK6K5;D5EP2G8A;41VVEG6A;2X2V95LJ;BZCBAD26;N6895BNJ;HKDSTCGY;ABE1R6VC',
      statusContent: 'dot',
      hash: 'W2YZ8JZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2YZ8JZR'
    },
    {
      status: 210,
      sku: 'PM0045617',
      core_name: 'Plant',
      sppl_ean: '8720664860445',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147738',
      rtl_batch_array: '6147738',
      name_search: 'ECFMMAMA',
      name: "Echinacea 'Fiery Meadow Mama'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 808,
      chnn_stock_retail: 808,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K24LGGPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K24LGGPP'
    },
    {
      status: 210,
      sku: 'PM0060127',
      core_name: 'Plant',
      sppl_ean: '8720664873476',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161823',
      rtl_batch_array: '6161823',
      name_search: 'LUCHATEL',
      name: "Lupinus 'The Chatelaine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CP9VN61;1VRAV4BN',
      statusContent: 'dot',
      hash: '2SXXY2GV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SXXY2GV'
    },
    {
      status: 210,
      sku: 'PM0077634',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305763',
      rtl_batch_array: '6305763',
      name_search: 'SOGDWARF',
      name: "Solidago 'Golden Dwarf'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XRK73KXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRK73KXA'
    },
    {
      status: 210,
      sku: 'PM0069936',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193557',
      rtl_batch_array: '6193557',
      name_search: 'POLUCENS',
      name: 'Potamogeton lucens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PE5S1TEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PE5S1TEB'
    },
    {
      status: 210,
      sku: 'PM0045601',
      core_name: 'Plant',
      sppl_ean: '8720349453979',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6034228',
      rtl_batch_array: '6034228, 6301492, 6193720',
      name_search: 'CAPANICE',
      name: 'Carex panicea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1254,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRPWECXW;T43NLNL7',
      statusContent: 'dot',
      hash: '1GWLVKC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GWLVKC3'
    },
    {
      status: 210,
      sku: 'PM0069933',
      core_name: 'Plant',
      sppl_ean: '8720349433032',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '5332873',
      rtl_batch_array: '5332873',
      name_search: 'SEFUNCKI',
      name: 'Sempervivum funckii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AT7T21H4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AT7T21H4'
    },
    {
      status: 210,
      sku: 'PM0016453',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349130',
      rtl_batch_array: '6349130, 6220834, 5866429, 6301686',
      name_search: 'LESILBER',
      name: "Leucanthemum (S) 'Silberprinzesschen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 388,
      chnn_stock_retail: 1771,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JZY8DZZL;CBWGTBAK;TN12CRND;9T79KN6Z;96Y5DYT5;E7JTT62V;1575B33T;7TKL2K3Z;RWJSLBYW;E8ADL5KL;J5WBXLL4;7SZX657K;964NV6GK;24HENG7B;2WXPTRKW;6PVDYSRL;8GV1217D;A78TNT6P;AX4T4JP8;JEDVHPCT;KSAVBWXC;J1GGN4LD',
      statusContent: 'dot',
      hash: 'PWCSVKRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWCSVKRT'
    },
    {
      status: 210,
      sku: 'PM0077635',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305764',
      rtl_batch_array: '6305764',
      name_search: 'GETTANGE',
      name: "Geum 'Totally Tangerine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_prcp: 3.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBEZAANX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBEZAANX'
    },
    {
      status: 210,
      sku: 'PM0077639',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305768',
      rtl_batch_array: '6305768',
      name_search: 'KNRPOPSI',
      name: "Kniphofia 'Redhot Popsicle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_prcp: 3.299,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JYAV77JW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYAV77JW'
    },
    {
      status: 210,
      sku: 'PM0045415',
      core_name: 'Plant',
      sppl_ean: '8720626395213',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014610',
      rtl_batch_array: '6014610',
      name_search: 'BERZEITE',
      name: "Bergenia 'Rosa Zeiten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 417,
      chnn_stock_retail: 417,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H9EPVSCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9EPVSCG'
    },
    {
      status: 810,
      sku: 'PM0077641',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305770',
      rtl_batch_array: '6305770',
      name_search: 'LIDMLADY',
      name: "Ligularia dentata 'Midnight Lady'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RR2VZYC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR2VZYC2'
    },
    {
      status: 210,
      sku: 'PM0077647',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305780',
      rtl_batch_array: '6305780',
      name_search: 'ACMSCHNE',
      name: "Achillea millefolium 'Schneetaler'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EB2CLR57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB2CLR57'
    },
    {
      status: 210,
      sku: 'PM0077468',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207250',
      rtl_batch_array: '5207250, 6304822',
      name_search: 'ERDKROTE',
      name: "Erica darleyensis 'Kramer's Rote'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 597,
      chnn_stock_retail: 1560,
      sppl_order_minimum: 3,
      sppl_prcp: 1.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SLBL71PR',
      imageBatch: '87W8PJZX',
      statusContent: 'dot',
      hash: 'W92V5REG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W92V5REG'
    },
    {
      status: 210,
      sku: 'PM0077483',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304837',
      rtl_batch_array: '6304837, 4710472',
      name_search: 'EUJPBLAN',
      name: 'Euonymus japonicus Paloma Blanca',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 12085,
      chnn_stock_retail: 12585,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H3R51T6;B7E9D968;L2K9DYN2;RE2CWTBT;RCPWNR7V;TCAXJVBK;HBBA57GJ;S2VJP2XN;CLH92YZR;L4C927H1;9948PKVC;65LE7L44;ATD8AAJC',
      statusContent: 'dot',
      hash: 'CVHXLT36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVHXLT36'
    },
    {
      status: 210,
      sku: 'PM0070920',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291754',
      rtl_batch_array: '6291754',
      name_search: 'PHMHERMI',
      name: "Philadelphus 'Manteau d'Hermine'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59LL5L5C',
      statusContent: 'dot',
      hash: 'X5BRTWZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5BRTWZE'
    },
    {
      status: 910,
      sku: 'PM0068917',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'A4R3G7YP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A4R3G7YP'
    },
    {
      status: 210,
      sku: 'PM0069951',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193577',
      rtl_batch_array: '6193577',
      name_search: 'NYLMIST',
      name: "Nymphaea 'Lemon Mist'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GPAEBKJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPAEBKJV'
    },
    {
      status: 810,
      sku: 'PM0065814',
      core_name: 'Plant',
      sppl_ean: '8720664851634',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169430',
      rtl_batch_array: '6169430',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6Z194RS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z194RS6'
    },
    {
      status: 210,
      sku: 'PM0069952',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193578',
      rtl_batch_array: '6193578',
      name_search: 'NYMUBOL',
      name: "Nymphaea 'Mangkala Ubol'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 8.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YVZD12KG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVZD12KG'
    },
    {
      status: 210,
      sku: 'PM0068538',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205977',
      rtl_batch_array: '6205977',
      name_search: 'CARBVARI',
      name: "Carex brunnea 'Variegata'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 3,
      sppl_prcp: 1.524,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZENS97HV;ZX5A3N4W',
      statusContent: 'dot',
      hash: 'NEX3R7CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NEX3R7CT'
    },
    {
      status: 910,
      sku: 'PM0069050',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204216',
      rtl_batch_array: '6204216',
      name_search: 'LOJHALLI',
      name: "Lonicera japonica 'Halliana'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_order_minimum: 6,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VCL9KE5;6JKL2B7Y;B1KTTATS;WE2LSD3H;XG939NGS;4V9YCXNK',
      statusContent: 'dot',
      hash: 'X8G6LZPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8G6LZPZ'
    },
    {
      status: 210,
      sku: 'PM0052496',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353198',
      rtl_batch_array: '6353198',
      name_search: 'RUSKCHIN',
      name: "Rudbeckia 'Sun Ka Ching'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2BGNL2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2BGNL2D'
    },
    {
      status: 910,
      sku: 'PM0069049',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204213',
      rtl_batch_array: '6204213',
      name_search: 'CLASNOWD',
      name: "Clematis armandii 'Snowdrift'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_order_minimum: 6,
      sppl_prcp: 6.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZNE11WG3;5B31DTYY;X2W3573T;VGLB2C7D;RY7LN3JK;BKPGX95G;CYWZJ4P3;GWKD4LLP;GE7J3XPY;6RJPLJ6P;L1YDD9H8;RR485X6V;CWKZ29GY;SA2T1KCC',
      statusContent: 'dot',
      hash: 'CV3SK989',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV3SK989'
    },
    {
      status: 910,
      sku: 'PM0069052',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204218',
      rtl_batch_array: '6204218',
      name_search: 'LOPBELGI',
      name: "Lonicera periclymenum 'Belgica'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_order_minimum: 6,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPRANN1H;GA61NY68',
      statusContent: 'dot',
      hash: 'XST82STH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XST82STH'
    },
    {
      status: 210,
      sku: 'PM0069955',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193585',
      rtl_batch_array: '6193585',
      name_search: 'NYPABLAC',
      name: "Nymphaea 'Perry's Almost Black'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VDD87SB2',
      statusContent: 'dot',
      hash: 'YCVBY7AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCVBY7AS'
    },
    {
      status: 810,
      sku: 'PM0045339',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6192660',
      rtl_batch_array: '6192660, 6077509',
      name_search: 'ASZWERGE',
      name: "Aster (D) 'Zwergenhimmel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9T3WP5A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T3WP5A5'
    },
    {
      status: 210,
      sku: 'PM0069944',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193570',
      rtl_batch_array: '6193570',
      name_search: 'NYFOPAL',
      name: "Nymphaea 'Fire Opal'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 8.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4CKCGW16;YCRYN4RN;JG2A52AB;VYWECECJ;C8RH3ELY;GP6DYJ26',
      statusContent: 'dot',
      hash: 'V7B122T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7B122T9'
    },
    {
      status: 210,
      sku: 'PM0069945',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193571',
      rtl_batch_array: '6193571',
      name_search: 'NYGLADST',
      name: "Nymphaea 'Gladstoniana'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LBZSJWK7;1YTTHDG4;T827W9VL;HE993YP5;D511PEPW;HRRYESGH',
      statusContent: 'dot',
      hash: 'GXV3Z1VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXV3Z1VT'
    },
    {
      status: 210,
      sku: 'PM0069946',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193572',
      rtl_batch_array: '6193572',
      name_search: 'NYGONNER',
      name: "Nymphaea 'Gonn\u00e8re'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W7DCDR6;VHBLVT49;A453ZKXE;V5TJ5CDG',
      statusContent: 'dot',
      hash: 'JLH2Y4YN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLH2Y4YN'
    },
    {
      status: 210,
      sku: 'PM0069947',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193573',
      rtl_batch_array: '6193573',
      name_search: 'NYHOLLAN',
      name: "Nymphaea 'Hollandia'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7XZW4K8E',
      statusContent: 'dot',
      hash: '72T3GTW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72T3GTW4'
    },
    {
      status: 210,
      sku: 'PM0069948',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193574',
      rtl_batch_array: '6193574',
      name_search: 'NYJBRYDO',
      name: "Nymphaea 'James Brydon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 8.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4NCTSAV;EB25YXRV',
      statusContent: 'dot',
      hash: 'ETAVR8XH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETAVR8XH'
    },
    {
      status: 210,
      sku: 'PM0069949',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193575',
      rtl_batch_array: '6193575',
      name_search: 'NYJTOMOC',
      name: "Nymphaea 'Joey Tomocik'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA5ENAGB;LEE38PYV;EVRZCKJS;HXBKLRWG;X38737TV;1LC38ZRW',
      statusContent: 'dot',
      hash: 'HEKNWSWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEKNWSWA'
    },
    {
      status: 210,
      sku: 'PM0069950',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193576',
      rtl_batch_array: '6193576',
      name_search: 'NYLLILAC',
      name: "Nymphaea 'Laydekeri Lilacea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '27S8KBPW;BE3A69TR;BN15SBHD',
      statusContent: 'dot',
      hash: 'A6YRH8B1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6YRH8B1'
    },
    {
      status: 210,
      sku: 'PM0069953',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193579',
      rtl_batch_array: '6193579',
      name_search: 'NYMALBID',
      name: "Nymphaea 'Marliacea Albida'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ZYJ2RX7;EK1XSW16',
      statusContent: 'dot',
      hash: 'E1VSHXHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1VSHXHC'
    },
    {
      status: 210,
      sku: 'PM0069954',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193580',
      rtl_batch_array: '6193580',
      name_search: 'NYMCARNE',
      name: "Nymphaea 'Marliacea Carnea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1LSTD89R;47JKG5PW;AAWJAGA3;7WZ3JVYP;GPR46HTG',
      statusContent: 'dot',
      hash: '673XZSGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '673XZSGR'
    },
    {
      status: 210,
      sku: 'PM0057998',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242039',
      rtl_batch_array: '6099751, 6164330, 6242039, 6192822',
      name_search: 'LUNIVEA',
      name: 'Luzula nivea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 20688,
      chnn_stock_retail: 26809,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8X37PK5;V4S9TZRA;D1KLWY84;N4WKJNVV;KPS5LCCE;118SAGCL;7RTJ566S;YPV81KSE;7D6S6SVV;KSAL5JWT;2V5VNV1A',
      statusContent: 'dot',
      hash: '4LZ1ACBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LZ1ACBP'
    },
    {
      status: 210,
      sku: 'PM0077485',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304841',
      rtl_batch_array: '6304841',
      name_search: 'HIMCGRED',
      name: 'Hibiscus moscheutos Carousel Geant Red',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1745,
      chnn_stock_retail: 1745,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S6XSLA32;RRLG59C6',
      statusContent: 'dot',
      hash: '24R4X2PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24R4X2PA'
    },
    {
      status: 210,
      sku: 'PM0064396',
      core_name: 'Plant',
      sppl_ean: '8720664853324',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151504',
      rtl_batch_array: '6151504',
      name_search: 'AUJCROTO',
      name: "Aucuba japonica 'Crotonifolia'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_prcp: 3.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PSDCYARP;6S6TGEKA;WW32JBP9;K12KTR8A;LDAXEP4N;B7JHYEN5;V3ZKDYBG;1772LZKR;2D2DECH5',
      statusContent: 'dot',
      hash: 'TYH3LGKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYH3LGKR'
    },
    {
      status: 210,
      sku: 'PM0077488',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304844',
      rtl_batch_array: '6304844',
      name_search: 'HIMCPPAS',
      name: 'Hibiscus moscheutos Carousel Pink Passion',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2091,
      chnn_stock_retail: 2091,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP5V2D92;XLEX7A22',
      statusContent: 'dot',
      hash: 'ZYPPHVZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYPPHVZ5'
    },
    {
      status: 210,
      sku: 'PM0081145',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338561',
      rtl_batch_array: '6338561',
      name_search: 'VIMATROP',
      name: "Vinca minor 'Atropurpurea'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 6010,
      chnn_stock_retail: 6010,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9RN6Z5R2;K9WE8974;SNN5WREA;VB7TSAX8;D86DG5HP;7DR4L4KK;Y3TE5BB4;EKSPVVN3;52KHDXEY',
      statusContent: 'dot',
      hash: 'CTT14Z5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTT14Z5G'
    },
    {
      status: 210,
      sku: 'PM0077489',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304845',
      rtl_batch_array: '6304845',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 303,
      chnn_stock_retail: 303,
      sppl_prcp: 38.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'JGS9SJ4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGS9SJ4L'
    },
    {
      status: 210,
      sku: 'PM0081146',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338562',
      rtl_batch_array: '6338562',
      name_search: 'VIMAUREO',
      name: "Vinca minor 'Aureovariegata'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1340,
      chnn_stock_retail: 1340,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SA2TVNPH;HWT225EE;XA3RW4E1;ZD24ZXRA;9A4G1X46;2G74PVNE;7JRSXH8E;CEVL5BY4',
      statusContent: 'dot',
      hash: 'ZV1W5TSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV1W5TSH'
    },
    {
      status: 210,
      sku: 'PM0077490',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304846',
      rtl_batch_array: '6304846',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 3284,
      chnn_stock_retail: 3284,
      sppl_prcp: 4.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'LRGG71CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRGG71CX'
    },
    {
      status: 210,
      sku: 'PM0081147',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338563',
      rtl_batch_array: '6338563',
      name_search: 'VIMGRAVE',
      name: "Vinca minor 'La Grave'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 12205,
      chnn_stock_retail: 12205,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7Z8DXKDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z8DXKDP'
    },
    {
      status: 210,
      sku: 'PM0081148',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338564',
      rtl_batch_array: '6338564',
      name_search: 'VIMGJEKY',
      name: "Vinca minor 'Gertrude Jekyll'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 7765,
      chnn_stock_retail: 7765,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y335HZAG;H5PLAKG9;6LH12BCA;XN619H8A;4CDRRTEZ;7N7KRB5C;Z5B295Z3;4HAWSZY7',
      statusContent: 'dot',
      hash: 'C6DEC4BJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6DEC4BJ'
    },
    {
      status: 210,
      sku: 'PM0085067',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363209',
      rtl_batch_array: '6363209',
      name_search: 'SIMARIAN',
      name: 'Silybum marianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7704,
      chnn_stock_retail: 7704,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VA1LAYSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VA1LAYSG'
    },
    {
      status: 210,
      sku: 'PM0069956',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193586',
      rtl_batch_array: '6193586',
      name_search: 'NYPALBA',
      name: "Nymphaea 'Pygmaea Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19LT1WZK',
      statusContent: 'dot',
      hash: 'TT6PE76D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TT6PE76D'
    },
    {
      status: 210,
      sku: 'PM0077493',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304849',
      rtl_batch_array: '6304849',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '060STC74',
      rng_range_identifier: 'STE060C7.5',
      rng_range_description: 'Stem 60 cm C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 19.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: '79DV8TJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79DV8TJW'
    },
    {
      status: 810,
      sku: 'PM0081149',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338565',
      rtl_batch_array: '6338565',
      name_search: 'VIMILLUM',
      name: 'Vinca minor Illumination',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HBJ4J7S;LZ93WVDK;CYKHBWP1',
      statusContent: 'dot',
      hash: '9VRTBT55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VRTBT55'
    },
    {
      status: 210,
      sku: 'PM0077494',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304850',
      rtl_batch_array: '6304850',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 948,
      chnn_stock_retail: 948,
      sppl_prcp: 7.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'KBVHZYH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBVHZYH9'
    },
    {
      status: 210,
      sku: 'PM0045625',
      core_name: 'Plant',
      sppl_ean: '8720664860513',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147756',
      rtl_batch_array: '6147756',
      name_search: 'ECIMMAMA',
      name: "Echinacea 'Innocent Meadow Mama'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HWAKTRS6;XR8NGCDC;NWBJW9KN;ZDPTYZJG;VKJDNS6B;WJNB3CBX;Z7PHCYRS;CLV14C7L;9EVVLZYK',
      statusContent: 'dot',
      hash: 'SG1EL7Y4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG1EL7Y4'
    },
    {
      status: 210,
      sku: 'PM0081150',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338566',
      rtl_batch_array: '6338566',
      name_search: 'VIMRSHUG',
      name: "Vinca minor 'Ralph Shugert'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2100,
      chnn_stock_retail: 2100,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3CB44HX;9TB6869P',
      statusContent: 'dot',
      hash: 'CT1H834Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT1H834Y'
    },
    {
      status: 210,
      sku: 'PM0081151',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338568',
      rtl_batch_array: '6338568',
      name_search: 'VIACSSPI',
      name: "Vitex agnus-castus 'Silver Spire'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ECPG2Z3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECPG2Z3G'
    },
    {
      status: 210,
      sku: 'PM0077648',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305783',
      rtl_batch_array: '6305783',
      name_search: 'AGCFORTU',
      name: 'Agastache Crazy Fortune',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XDEKY9ET;YWCRT37Z;4932V3E5;GR9V6Z7R;S44ECTYX;EX5CZKZG',
      statusContent: 'dot',
      hash: 'K19VT1SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K19VT1SX'
    },
    {
      status: 210,
      sku: 'PM0081152',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338569',
      rtl_batch_array: '6338569',
      name_search: 'WEASPEAC',
      name: 'Weigela All Summer Peach',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHEARA81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHEARA81'
    },
    {
      status: 210,
      sku: 'PM0081153',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338570',
      rtl_batch_array: '6338570',
      name_search: 'WEASRED',
      name: 'Weigela All Summer Red',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1169,
      chnn_stock_retail: 1169,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V5452ASS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5452ASS'
    },
    {
      status: 210,
      sku: 'PM0077495',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304852',
      rtl_batch_array: '6304852',
      name_search: 'ABPPONG',
      name: 'Abelia Ping Pong',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1212,
      chnn_stock_retail: 1212,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E5RLXYZZ',
      statusContent: 'dot',
      hash: 'H2P5Z8HJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2P5Z8HJ'
    },
    {
      status: 210,
      sku: 'PM0077487',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304843',
      rtl_batch_array: '6304843',
      name_search: 'HIMCPCAN',
      name: 'Hibiscus moscheutos Carousel Pink Candy',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1490,
      chnn_stock_retail: 1490,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V7WZXKX4',
      statusContent: 'dot',
      hash: '2SSD7XWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SSD7XWT'
    },
    {
      status: 210,
      sku: 'PM0068547',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6206068',
      rtl_batch_array: '6206068',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: 'HDT2JBTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDT2JBTY'
    },
    {
      status: 210,
      sku: 'PM0063388',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320194',
      rtl_batch_array: '6170359, 6320194',
      name_search: 'SYVULGAR',
      name: 'Syringa vulgaris',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 3230,
      chnn_stock_retail: 4456,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2HRB4X5;YKB45ZLV;15KSWZR1;S1LRAANK;A6PWADE1;E1HJATGN;AYDVATA4;WJAJARZR;4361DXJ2;Y4G96EP2;8ZJ1PJ7D',
      statusContent: 'dot',
      hash: '4XE1D6TG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XE1D6TG'
    },
    {
      status: 810,
      sku: 'PM0065826',
      core_name: 'Plant',
      sppl_ean: '8720664882713',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169443',
      rtl_batch_array: '6169443',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: '54DKEZ2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54DKEZ2R'
    },
    {
      status: 210,
      sku: 'PM0065819',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373394',
      rtl_batch_array: '6169435, 6373394',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1050,
      sppl_prcp: 9.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'ACTKK6H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACTKK6H5'
    },
    {
      status: 210,
      sku: 'PM0045706',
      core_name: 'Plant',
      sppl_ean: '8720664859845',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133303',
      rtl_batch_array: '6133303',
      name_search: 'DIDALBIF',
      name: "Dianthus deltoides 'Albiflorus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 433,
      chnn_stock_retail: 433,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A153KH4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A153KH4D'
    },
    {
      status: 210,
      sku: 'PM0045620',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187544',
      rtl_batch_array: '6187544',
      name_search: 'ECFWHITE',
      name: "Echinacea 'Funky White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LDKCLJ9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDKCLJ9B'
    },
    {
      status: 210,
      sku: 'PM0069058',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210349',
      rtl_batch_array: '6210349',
      name_search: 'CIOBTUSI',
      name: 'Cistus obtusifolius',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5195,
      chnn_stock_retail: 5195,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X5TCVZEC;YZ8G2AX1',
      statusContent: 'dot',
      hash: 'SRE33CHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SRE33CHP'
    },
    {
      status: 210,
      sku: 'PM0057098',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252855',
      rtl_batch_array: '6187548, 6252855',
      name_search: 'ECPARROT',
      name: "Echinacea 'Parrot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 159,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5X72XYJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X72XYJD'
    },
    {
      status: 210,
      sku: 'PM0081154',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338571',
      rtl_batch_array: '6338571',
      name_search: 'WEBWHITE',
      name: 'Weigela Black and White',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 651,
      chnn_stock_retail: 651,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z8S4PTXR',
      statusContent: 'dot',
      hash: 'ZK3RRKP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK3RRKP7'
    },
    {
      status: 210,
      sku: 'PM0069059',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210351',
      rtl_batch_array: '6210351',
      name_search: 'DIBARBAT',
      name: 'Dianthus barbatus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1881,
      chnn_stock_retail: 1881,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P6JVY2DR',
      statusContent: 'dot',
      hash: 'S382XS1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S382XS1H'
    },
    {
      status: 210,
      sku: 'PM0045664',
      core_name: 'Plant',
      sppl_ean: '8720664860605',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147595',
      rtl_batch_array: '6147595, 6217114',
      name_search: 'ECPEMPER',
      name: "Echinacea 'Purple Emperor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1986,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RT89E5EN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RT89E5EN'
    },
    {
      status: 210,
      sku: 'PM0081155',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338573',
      rtl_batch_array: '6352694, 6338573',
      name_search: 'WECANDID',
      name: "Weigela 'Candida'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 647,
      chnn_stock_retail: 747,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4GXH9E8;7BS3ASET',
      statusContent: 'dot',
      hash: 'KRYCX47L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRYCX47L'
    },
    {
      status: 210,
      sku: 'PM0045633',
      core_name: 'Plant',
      sppl_ean: '8720664860735',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147758',
      rtl_batch_array: '6147758',
      name_search: 'ECKWHITE',
      name: 'Echinacea Kismet White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 321,
      chnn_stock_retail: 321,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K72J171N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K72J171N'
    },
    {
      status: 210,
      sku: 'PM0081156',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338574',
      rtl_batch_array: '6338574',
      name_search: 'WEEIVORY',
      name: 'Weigela Ebony and Ivory',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X7B98G55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7B98G55'
    },
    {
      status: 210,
      sku: 'PM0045666',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350077',
      rtl_batch_array: '6350077',
      name_search: 'ECRPEARL',
      name: 'Echinacea Red Pearl',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CS65DPKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CS65DPKV'
    },
    {
      status: 210,
      sku: 'PM0057138',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217144',
      rtl_batch_array: '6217144',
      name_search: 'FRVIRUEG',
      name: "Fragaria vesca 'Improved R\u00fcgen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1022,
      chnn_stock_retail: 1022,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LP46LERR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LP46LERR'
    },
    {
      status: 210,
      sku: 'PM0081157',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338575',
      rtl_batch_array: '6338575',
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP1W33TG;HBYJJCC4',
      statusContent: 'dot',
      hash: 'NAGWTHL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAGWTHL4'
    },
    {
      status: 210,
      sku: 'PM0068609',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302498',
      rtl_batch_array: '6302498, 6193608',
      name_search: 'ERANGUST',
      name: 'Eriophorum angustifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 1155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GR2ZB1EP;VPEXBN1R',
      statusContent: 'dot',
      hash: 'WZ4WNRZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZ4WNRZR'
    },
    {
      status: 210,
      sku: 'PM0045825',
      core_name: 'Plant',
      sppl_ean: '8720626300439',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015045',
      rtl_batch_array: '6015045',
      name_search: 'FRVAYELL',
      name: "Fragaria vesca 'Alpine Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NLRET961',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLRET961'
    },
    {
      status: 210,
      sku: 'PM0057212',
      core_name: 'Plant',
      sppl_ean: '8720664866829',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091305',
      rtl_batch_array: '6091305, 6242159',
      name_search: 'HELPEARL',
      name: "Hemerocallis 'Longfields Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 422,
      chnn_stock_retail: 782,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AS9A163K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AS9A163K'
    },
    {
      status: 210,
      sku: 'PM0077486',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304842',
      rtl_batch_array: '6304842',
      name_search: 'HIMCJHEA',
      name: 'Hibiscus moscheutos Carousel Jolly Heart',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2349,
      chnn_stock_retail: 2349,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5N44P61L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N44P61L'
    },
    {
      status: 210,
      sku: 'PM0081158',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338576',
      rtl_batch_array: '6338576',
      name_search: 'WEKVARIE',
      name: "Weigela 'Kosteriana Variegata'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 448,
      chnn_stock_retail: 448,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7S23N9W;AG93HPHY;NJP2S4BE;2PS96SB7;9EV3HL6T;WN74DS47;LK19EV4A;B6AXBTHS;E72JPWYY;ET5GJH94;WA9HCHZZ;YNKVC8ZS;TWX9VX52;2KRYX35E',
      statusContent: 'dot',
      hash: 'K5RP8TDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5RP8TDS'
    },
    {
      status: 810,
      sku: 'PM0077649',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305786',
      rtl_batch_array: '6305786, 6334344',
      name_search: 'ANHDSWAN',
      name: "Anemone hybrida 'Dainty Swan'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 24,
      chnn_stock_retail: 554,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1VZTRZHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VZTRZHP'
    },
    {
      status: 910,
      sku: 'PM0077650',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASLCARLO',
      name: "Aster 'Little Carlow'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'VT2NBKXN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VT2NBKXN'
    },
    {
      status: 810,
      sku: 'PM0077651',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305792',
      rtl_batch_array: '6305792',
      name_search: 'ASAEPURP',
      name: "Aster ageratoides 'Eleven Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HDC41VT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDC41VT6'
    },
    {
      status: 210,
      sku: 'PM0081159',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338577',
      rtl_batch_array: '6338577',
      name_search: 'WELAUREA',
      name: "Weigela 'Looymansii Aurea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 580,
      chnn_stock_retail: 580,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HT3JRT5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT3JRT5K'
    },
    {
      status: 810,
      sku: 'PM0068613',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307404',
      rtl_batch_array: '6307404',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_prcp: 24.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '19TVBTT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19TVBTT8'
    },
    {
      status: 210,
      sku: 'PM0081160',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338578',
      rtl_batch_array: '6338578',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 919,
      chnn_stock_retail: 919,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: '5H6SZ3DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H6SZ3DP'
    },
    {
      status: 210,
      sku: 'PM0081161',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338579',
      rtl_batch_array: '6338579',
      name_search: 'WEFMBLAC',
      name: 'Weigela florida Minor Black',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXXKE7GL;GPY1SLLJ;LYJCCVVT;CZBRLRZV;6G96SY1D;VAE53HB1;SB9ZDSBJ;73TK1E7D',
      statusContent: 'dot',
      hash: 'W3TT9V1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3TT9V1J'
    },
    {
      status: 210,
      sku: 'PM0081162',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338580',
      rtl_batch_array: '6338580',
      name_search: 'WEFMONET',
      name: 'Weigela florida Monet',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1532,
      chnn_stock_retail: 1532,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '945P1HEP;SRZ69G98;9LJ3D77Z;TV22RBNY;YEL53WD7;84WXKKZ3;L6RTSJP4;6K6K6PZ2;VKP2DKZ8;XD2YGCSE;2GWBPRK3;HV2A5TED;5N2HP3YY;WCE43XBC;RATTLHZX',
      statusContent: 'dot',
      hash: '2A4DSL49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A4DSL49'
    },
    {
      status: 810,
      sku: 'PM0081163',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338582',
      rtl_batch_array: '6338582',
      name_search: 'WEFMROUG',
      name: 'Weigela florida Moulin Rouge',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1T4RXH5J;8Z15YBWS;J3TANJVH;66WL9GHZ;GDHKASXL;Z37Y99H2;SHL8STTT;K6SE99G2;2BXECCEY;TXTS79NP;CA15LVEX;4WD937PH;86DVTZB7',
      statusContent: 'dot',
      hash: 'H9V2PP6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9V2PP6D'
    },
    {
      status: 810,
      sku: 'PM0081164',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338584',
      rtl_batch_array: '6338584',
      name_search: 'XASORBIF',
      name: 'Xanthoceras sorbifolium',
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YKKDPKG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKKDPKG6'
    },
    {
      status: 210,
      sku: 'PM0081165',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338585',
      rtl_batch_array: '6338585',
      name_search: 'YUFCGUAR',
      name: "Yucca filamentosa 'Color Guard'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XH8AP9EZ',
      statusContent: 'dot',
      hash: 'TRXJS46Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRXJS46Z'
    },
    {
      status: 210,
      sku: 'PM0081166',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338586',
      rtl_batch_array: '6338586',
      name_search: 'YUGVARIE',
      name: "Yucca gloriosa 'Variegata'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 127,
      chnn_stock_retail: 127,
      sppl_order_minimum: 3,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HHZH2NVA;9S2VC58B;ZH5WN9XV;SNWAEH99;VRBXALYY',
      statusContent: 'dot',
      hash: 'P7LPKZ62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7LPKZ62'
    },
    {
      status: 210,
      sku: 'PM0081167',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338829',
      rtl_batch_array: '6338829',
      name_search: 'ERDARLEY',
      name: 'Erica darleyensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 976,
      chnn_stock_retail: 976,
      sppl_prcp: 2.221,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YT2ZCBEN;R7A8H8D1;4RKLDJ7V;ZLS8ZHHE;CRJYS2ZD;BPWPLY4T;RRKS79L4;J6YPHLZC;XKDD8V93;NC5EA822;YYLACC8P;TTZATZDD;6AX7LKAK;CWEHP6DB',
      statusContent: 'dot',
      hash: '1SC1JBN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SC1JBN5'
    },
    {
      status: 210,
      sku: 'PM0084896',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369378',
      rtl_batch_array: '6369378',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '9L8GNC5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L8GNC5B'
    },
    {
      status: 910,
      sku: 'PM0060134',
      core_name: 'Plant',
      sppl_ean: '8720664857506',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6126312',
      rtl_batch_array: '6126312',
      name_search: 'CLVLYON',
      name: "Clematis 'Ville de Lyon'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_order_minimum: 6,
      sppl_prcp: 3.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWNV29KS;918ABGA1;GLHCXVK1;7LA48278;TRRL5B7P;W6RL2AW3;CH5BCNKG;XPN2391D;RB1BNNJE;WL9EX33H;XJH26RHD;YVY3RYKE;9S1N3Z1A;H1LS6LCL;J27E55DS;SDGAY5CZ;L795T6NB;A3GK9SW4;7XTC9EZT;V5EBLPAB;NXDGGTKJ;ZXJR3DPH;T3C5VKAC;AZZE81NA',
      statusContent: 'dot',
      hash: 'TEPG2NP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEPG2NP9'
    },
    {
      status: 210,
      sku: 'PM0068645',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307789',
      rtl_batch_array: '6307789',
      name_search: 'VACBLUEJ',
      name: "Vaccinium corymbosum 'Bluejay'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BYRCWLET',
      statusContent: 'dot',
      hash: 'GWVGZYRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWVGZYRP'
    },
    {
      status: 210,
      sku: 'PM0069965',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193600',
      rtl_batch_array: '6193600',
      name_search: 'CARIPARI',
      name: 'Carex riparia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCLLYR5L;EL2K96EY',
      statusContent: 'dot',
      hash: 'RDGSXD7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDGSXD7V'
    },
    {
      status: 210,
      sku: 'PM0074215',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268500',
      rtl_batch_array: '6310308, 6268500',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 750,
      chnn_stock_retail: 3050,
      sppl_prcp: 3.176,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NAV8Y5NL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAV8Y5NL'
    },
    {
      status: 210,
      sku: 'PM0058012',
      core_name: 'Plant',
      sppl_ean: '8720664852921',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084810',
      rtl_batch_array: '6084810',
      name_search: 'ASAWHITE',
      name: "Astilbe (A) 'Astary White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10480,
      chnn_stock_retail: 10480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J75AN429;ZCPNLNYH;NL718N62;S2ZEVAY6',
      statusContent: 'dot',
      hash: 'J6JKB7JL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6JKB7JL'
    },
    {
      status: 810,
      sku: 'PM0065823',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 3,
      btch_active_retail: '6384815',
      rtl_batch_array: '6244919, 6307632, 6384815',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 90,
      chnn_stock_retail: 447,
      sppl_prcp: 16.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'J99NHJ5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J99NHJ5X'
    },
    {
      status: 810,
      sku: 'PM0065817',
      core_name: 'Plant',
      sppl_ean: '8720664858213',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169433',
      rtl_batch_array: '6169433',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 54.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '91BNCESG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '91BNCESG'
    },
    {
      status: 210,
      sku: 'PM0069067',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210361',
      rtl_batch_array: '6210361',
      name_search: 'POCAERUL',
      name: 'Polemonium caeruleum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4320,
      chnn_stock_retail: 4320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1EBYDGBY;ZAS3B3ZN;WX5K7YN5;1R5ZJSA4;JPHX777V;6P51G5J5;LVSC5XG4;N3PHV9ET;73Y237DL;GTHW94E3;B1A3EY79;N21J89W5',
      statusContent: 'dot',
      hash: 'C2WN1VVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2WN1VVL'
    },
    {
      status: 210,
      sku: 'PM0058108',
      core_name: 'Plant',
      sppl_ean: '8720353088815',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054483',
      rtl_batch_array: '6054483',
      name_search: 'BERSCHMI',
      name: 'Bergenia schmidtii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8SBJBTSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SBJBTSW'
    },
    {
      status: 210,
      sku: 'PM0063443',
      core_name: 'Plant',
      sppl_ean: '8720664850446',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084982',
      rtl_batch_array: '6084982, 6339718, 6352601',
      name_search: 'ACPPEARL',
      name: "Achillea ptarmica 'The Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 18189,
      chnn_stock_retail: 19349,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X49A7BAW;725YY5HK;CR85CHAT;BTYBJDB8',
      statusContent: 'dot',
      hash: 'ZXPY1X8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXPY1X8T'
    },
    {
      status: 810,
      sku: 'PM0065818',
      core_name: 'Plant',
      sppl_ean: '8720664858220',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169434',
      rtl_batch_array: '6169434',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 74.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '71NZX4EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71NZX4EV'
    },
    {
      status: 810,
      sku: 'PM0058106',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229805',
      rtl_batch_array: '6229805',
      name_search: 'SESSPLEN',
      name: "Sedum spurium 'Splendens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DBVAJGGB;HAK2LV27;1CE9BK3C;NVHPJBSZ;VKEY5L5N;SZ894HAZ',
      statusContent: 'dot',
      hash: 'AD77CXX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AD77CXX6'
    },
    {
      status: 210,
      sku: 'PM0063430',
      core_name: 'Plant',
      sppl_ean: '8720349434121',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4648294',
      rtl_batch_array: '4648294',
      name_search: 'DIRCOOLV',
      name: 'Dianella revoluta Coolvista',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.089,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'AE1BLSH5',
      statusContent: 'dot',
      hash: 'TYBT2GZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYBT2GZZ'
    },
    {
      status: 210,
      sku: 'PM0069065',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210358',
      rtl_batch_array: '6210358',
      name_search: 'KAIALBA',
      name: "Kalimeris incisa 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4V1HBG19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V1HBG19'
    },
    {
      status: 210,
      sku: 'PM0069063',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 3,
      btch_active_retail: '6210355',
      rtl_batch_array: '6210355, 6226867, 6248899',
      name_search: 'HOBDADDY',
      name: "Hosta 'Big Daddy'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 847,
      chnn_stock_retail: 2847,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1VLZHV76;ZLB4Y6KK;V7DNZA9J;X3PWJB2T',
      statusContent: 'dot',
      hash: 'K8ACX45J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8ACX45J'
    },
    {
      status: 810,
      sku: 'PM0057140',
      core_name: 'Plant',
      sppl_ean: '8720664863880',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091089',
      rtl_batch_array: '6091089',
      name_search: 'GABREMEN',
      name: "Gaillardia 'Bremen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X32L2KYZ',
      statusContent: 'dot',
      hash: '9ZT3377D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZT3377D'
    },
    {
      status: 210,
      sku: 'PM0069957',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193587',
      rtl_batch_array: '6193587',
      name_search: 'NYPHELVO',
      name: "Nymphaea 'Pygmaea Helvola'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ5VS21Y;Z5EC9BSX;PGE1C81R;AL1REJ2A;8VLHKRNR',
      statusContent: 'dot',
      hash: '415HVS6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '415HVS6T'
    },
    {
      status: 210,
      sku: 'PM0058111',
      core_name: 'Plant',
      sppl_ean: '8720626325920',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6028380',
      rtl_batch_array: '6028380, 6287695',
      name_search: 'SESPURPU',
      name: "Sedum spathulifolium 'Purpureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 768,
      chnn_stock_retail: 1008,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8B6XEXZ1;SRJD26RK;4JB3VT1V;9V2GKCCW;YG1E71T1;1YVYSV2H;T33BDE22;XZ4ETH4J;JTSGJ7AR;AGZCS6PC;Y47V3DDB;NPAYRNVR;AAKDYYC6;HE4KTL8E;YTA5ZG37;YYTLG5C4;SW9556AX;C3JYXSH2;LDHZSAT4;AG2J292C;X4X47S6N;NGTV2A57',
      statusContent: 'dot',
      hash: 'XVJHN72A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVJHN72A'
    },
    {
      status: 210,
      sku: 'PM0069958',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193588',
      rtl_batch_array: '6193588',
      name_search: 'NYPRUBRA',
      name: "Nymphaea 'Pygmaea Rubra'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4TGDHEWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TGDHEWY'
    },
    {
      status: 210,
      sku: 'PM0069959',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193589',
      rtl_batch_array: '6193589',
      name_search: 'NYRSPIDE',
      name: "Nymphaea 'Red Spider'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 8.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEGLN8X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEGLN8X1'
    },
    {
      status: 210,
      sku: 'PM0069961',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193591',
      rtl_batch_array: '6193591',
      name_search: 'NYSIOUX',
      name: "Nymphaea 'Sioux'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8S57VD7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S57VD7J'
    },
    {
      status: 210,
      sku: 'PM0069963',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193594',
      rtl_batch_array: '6193594',
      name_search: 'NYWANVIS',
      name: "Nymphaea 'Wanvisa'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D529D1PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D529D1PV'
    },
    {
      status: 210,
      sku: 'PM0069964',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193595',
      rtl_batch_array: '6193595',
      name_search: 'NYYSENSA',
      name: "Nymphaea 'Yellow Sensation'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 8.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZJGPJCK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJGPJCK9'
    },
    {
      status: 210,
      sku: 'PM0045690',
      core_name: 'Plant',
      sppl_ean: '8720664523227',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014821',
      rtl_batch_array: '6014821',
      name_search: 'DESLAVEN',
      name: "Delosperma 'Sundella Lavender'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 266,
      chnn_stock_retail: 266,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TTDZ193W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTDZ193W'
    },
    {
      status: 210,
      sku: 'PM0069064',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210357',
      rtl_batch_array: '6210357',
      name_search: 'IREINDIA',
      name: "Iris (G) 'Empress of India'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1151,
      chnn_stock_retail: 1151,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JL575WCY;NC18T2DD;7Z93DR2D',
      statusContent: 'dot',
      hash: '4RC3VDKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RC3VDKJ'
    },
    {
      status: 910,
      sku: 'PM0060133',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204214',
      rtl_batch_array: '6204214',
      name_search: 'CLFSPRIN',
      name: "Clematis 'Fragrant Spring'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_order_minimum: 6,
      sppl_prcp: 2.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XV5Z9LLB;YZ768L7E;NX2DDYK9',
      statusContent: 'dot',
      hash: 'TBC27S4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBC27S4N'
    },
    {
      status: 210,
      sku: 'PM0069960',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193590',
      rtl_batch_array: '6193590',
      name_search: 'NYRAREY',
      name: "Nymphaea 'Rose Arey'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72JGPWB5;TT134EZJ;PCJWJRV2;CCAPKNL2;2TJBKXXK;H8LVHPL3;43ZSBHE7',
      statusContent: 'dot',
      hash: 'X5TELT7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5TELT7K'
    },
    {
      status: 210,
      sku: 'PM0069962',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193592',
      rtl_batch_array: '6193592',
      name_search: 'NYSULPHU',
      name: "Nymphaea 'Sulphurea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YSA9H57N;ZG9NR8CY;SD5RNHJL;XNHCKLPL',
      statusContent: 'dot',
      hash: 'NRHDEALV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRHDEALV'
    },
    {
      status: 210,
      sku: 'PM0052506',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353368',
      rtl_batch_array: '6353368',
      name_search: 'TAOBLUEH',
      name: "Taraxacum off. 'Bluehfreude'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2LGNXXDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LGNXXDG'
    },
    {
      status: 210,
      sku: 'PM0068641',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307690',
      rtl_batch_array: '6307690',
      name_search: 'TRJVARIE',
      name: "Trachelospermum jasminoides 'Variegatum'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 362,
      chnn_stock_retail: 362,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58CWV59E;JKHER225;RNV8L6T3',
      statusContent: 'dot',
      hash: '6XV2GY9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XV2GY9L'
    },
    {
      status: 210,
      sku: 'PM0064399',
      core_name: 'Plant',
      sppl_ean: '8720664855304',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151508',
      rtl_batch_array: '6151508',
      name_search: 'CAFFALLS',
      name: "Carex 'Feather Falls'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9710,
      chnn_stock_retail: 9710,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SWTEBKB2;L2483766;SP761YTY;76N9NLAJ;CY3EZYXS;GN3N9S8T;PTBBRL6A;4WP8RTZK;PY2L3NWL;56GRS1YN;VVVTBCA6;4S3RAXZ6;54D1RL41;SKA1G2BS',
      statusContent: 'dot',
      hash: '6G4G4NAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6G4G4NAJ'
    },
    {
      status: 810,
      sku: 'PM0052499',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353251',
      rtl_batch_array: '6353251',
      name_search: 'PUSON',
      name: "Pulmonaria 'Spot On'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '77JRPBL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77JRPBL9'
    },
    {
      status: 210,
      sku: 'PM0058066',
      core_name: 'Plant',
      sppl_ean: '8720664680173',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6085007',
      rtl_batch_array: '6085007',
      name_search: 'ALAFLATU',
      name: 'Allium aflatunense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 656,
      chnn_stock_retail: 656,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R5LKRYCG;7XV8VNYE;1ZDCG3KT;VZ2BZD15;252G186W;BLTD3XG5;8ZDG84RB;V2XGHRD8',
      statusContent: 'dot',
      hash: '9RR8R7HV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RR8R7HV'
    },
    {
      status: 210,
      sku: 'PM0065822',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236770',
      rtl_batch_array: '6236770',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 7.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'CNXTTR5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNXTTR5L'
    },
    {
      status: 810,
      sku: 'PM0068650',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307797',
      rtl_batch_array: '6307797',
      name_search: 'VACSBLUE',
      name: "Vaccinium corymbosum 'Sunshine Blue'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPGZCPZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPGZCPZY'
    },
    {
      status: 210,
      sku: 'PM0077496',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304853',
      rtl_batch_array: '6304853',
      name_search: 'ACPAOYAG',
      name: "Acer palmatum 'Aoyagi'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 646,
      chnn_stock_retail: 646,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHW6DRPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHW6DRPP'
    },
    {
      status: 210,
      sku: 'PM0023496',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308441',
      rtl_batch_array: '6196845, 6301711, 5364323, 6329995, 6308441',
      name_search: 'LYSROBER',
      name: "Lythrum salicaria 'Robert'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 19178,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y3X8VBSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3X8VBSD'
    },
    {
      status: 210,
      sku: 'PM0077498',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304855',
      rtl_batch_array: '6304855',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4242,
      chnn_stock_retail: 4242,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'EVWZVLW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVWZVLW6'
    },
    {
      status: 210,
      sku: 'PM0045715',
      core_name: 'Plant',
      sppl_ean: '8720664687462',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014867',
      rtl_batch_array: '6014867',
      name_search: 'DISPICUL',
      name: 'Dianthus spiculifolius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JT7ZK514',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT7ZK514'
    },
    {
      status: 210,
      sku: 'PM0069072',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210368',
      rtl_batch_array: '6210368',
      name_search: 'VIMALBA',
      name: "Vinca minor 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5894,
      chnn_stock_retail: 5894,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XHY7T4KR;J9VCL9CP;YL2JYJ16',
      statusContent: 'dot',
      hash: 'LC6HJP6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC6HJP6G'
    },
    {
      status: 210,
      sku: 'PM0064418',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317470',
      rtl_batch_array: '6317470, 6234650',
      name_search: 'COSPFEAT',
      name: "Cortaderia selloana 'Pink Feather'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1688,
      chnn_stock_retail: 1728,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTX3KL29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTX3KL29'
    },
    {
      status: 210,
      sku: 'PM0085068',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363459',
      rtl_batch_array: '6363459',
      name_search: 'EDI88617',
      name: "Camassia leichtlinii 'Silk River'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_order_minimum: 3,
      sppl_prcp: 1.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '13AVNV8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13AVNV8T'
    },
    {
      status: 210,
      sku: 'PM0045416',
      core_name: 'Plant',
      sppl_ean: '8720349483785',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014612',
      rtl_batch_array: '6187516, 6014612',
      name_search: 'BEROTBLU',
      name: "Bergenia 'Rotblum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7500,
      chnn_stock_retail: 11743,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SNRLVVHG;C6NC8YKP;R722JEH6;PB9ZJ8A4;LKJ3VA9T;ANAJ21B7;2ZE7VXCX;CNXL3LNC',
      statusContent: 'dot',
      hash: 'RRW5GWJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRW5GWJY'
    },
    {
      status: 810,
      sku: 'PM0085069',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363463',
      rtl_batch_array: '6363463',
      name_search: 'FRIGSTAR',
      name: "Fritillaria imperalis 'Garland Star' \u00ae",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WDPKTH9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDPKTH9E'
    },
    {
      status: 810,
      sku: 'PM0052760',
      core_name: 'Plant',
      sppl_ean: '8720664802162',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6041214',
      rtl_batch_array: '6041214',
      name_search: 'PIAFARNS',
      name: "Picea abies 'Farnsburg'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CSHD9A6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSHD9A6A'
    },
    {
      status: 910,
      sku: 'PM0045798',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ERPMBLAG',
      name: 'Eryngium planum Magical Blue Lagoon',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'PDTLX1SE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PDTLX1SE'
    },
    {
      status: 210,
      sku: 'PM0045338',
      core_name: 'Plant',
      sppl_ean: '8720353087214',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014522',
      rtl_batch_array: '6014522',
      name_search: 'ASWACHSE',
      name: "Aster (D) 'Wachsenburg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 413,
      chnn_stock_retail: 413,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S3CLZ1TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3CLZ1TP'
    },
    {
      status: 210,
      sku: 'PM0070926',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222173',
      rtl_batch_array: '6222173',
      name_search: 'POFELIZA',
      name: "Potentilla fruticosa 'Elizabeth'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XY5JR1T3;BWWTSRRY;6SR67SWX;79CS1C7X',
      statusContent: 'dot',
      hash: '9A3KJJJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9A3KJJJX'
    },
    {
      status: 210,
      sku: 'PM0068653',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307855',
      rtl_batch_array: '6307855',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_prcp: 24.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: '458L2XGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '458L2XGH'
    },
    {
      status: 910,
      sku: 'PM0081169',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367967',
      rtl_batch_array: '6367967',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 22.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '2Y9GSAG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Y9GSAG3'
    },
    {
      status: 210,
      sku: 'PM0007275',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6219841',
      rtl_batch_array: '6219841, 6350179',
      name_search: 'GALGRED',
      name: 'Gaura lindheimeri Gaudi Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 20086,
      chnn_stock_retail: 21274,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PACTT7YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PACTT7YA'
    },
    {
      status: 210,
      sku: 'PM0077503',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304860',
      rtl_batch_array: '6304860',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 624,
      chnn_stock_retail: 624,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: '29XXVR4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29XXVR4P'
    },
    {
      status: 210,
      sku: 'PM0063456',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336134',
      rtl_batch_array: '6141832, 6336134',
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 1499,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW2EVKB5;SP7Y5ZWK;SX1DDW1R;JCCEY4LT;A3VB9EVA;HY6AJV5K;CGP52GK8;YKBYTZKC;C9PLV17X;8YNL22WX;Z2CG7A8J;RJD8YAXS;A9KC643C;5V2J3N1E;5AGVBE54;D21HDP9S',
      statusContent: 'dot',
      hash: 'XC7EJP8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC7EJP8B'
    },
    {
      status: 210,
      sku: 'PM0068648',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307795',
      rtl_batch_array: '6307795',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1437,
      chnn_stock_retail: 1437,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: 'NYXSYZ8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYXSYZ8D'
    },
    {
      status: 210,
      sku: 'PM0085070',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363470',
      rtl_batch_array: '6363470',
      name_search: 'AGEALBA',
      name: 'Agrimonia eupatoria alba',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRS3VLG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRS3VLG2'
    },
    {
      status: 210,
      sku: 'PM0077504',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304861',
      rtl_batch_array: '6304861',
      name_search: 'ACPJSCHW',
      name: "Acer palmatum 'Jerre Schwartz'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 5529,
      chnn_stock_retail: 5529,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T9T8KB7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9T8KB7E'
    },
    {
      status: 210,
      sku: 'PM0077497',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304854',
      rtl_batch_array: '6304854',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 69604,
      chnn_stock_retail: 69604,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'GP4E5W6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GP4E5W6H'
    },
    {
      status: 910,
      sku: 'PM0081170',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: '6WSA5AE1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6WSA5AE1'
    },
    {
      status: 210,
      sku: 'PM0077506',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304863',
      rtl_batch_array: '6304863',
      name_search: 'ACPLPRIN',
      name: "Acer palmatum 'Little Princess'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 5488,
      chnn_stock_retail: 5488,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VXJ9CKKW;59STYAKN',
      statusContent: 'dot',
      hash: 'NH2BAJ7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH2BAJ7J'
    },
    {
      status: 210,
      sku: 'PM0070931',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222179',
      rtl_batch_array: '6222179',
      name_search: 'SPJFIREL',
      name: "Spiraea japonica 'Firelight'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6RV2C77;KKHHCA3V;STSC46D8;Y6JNBEPZ;GRNCJ8L4;5X39CDEG;RHCNZLBG;85R683CC;T4W9EBZP',
      statusContent: 'dot',
      hash: '592ZHPRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '592ZHPRS'
    },
    {
      status: 810,
      sku: 'PM0081171',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367981',
      rtl_batch_array: '6367981',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_prcp: 7.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: 'T9C56XNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9C56XNA'
    },
    {
      status: 210,
      sku: 'PM0068659',
      core_name: 'Plant',
      sppl_ean: '8720664863774',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080657',
      rtl_batch_array: '6080657',
      name_search: 'FRVVESCA',
      name: 'Fragaria vesca vesca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1148,
      chnn_stock_retail: 1148,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HN29PB3T;H9WP3Y6A',
      statusContent: 'dot',
      hash: 'SBY8NN8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBY8NN8E'
    },
    {
      status: 210,
      sku: 'PM0070932',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222180',
      rtl_batch_array: '6222180',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'JL3XTGWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL3XTGWS'
    },
    {
      status: 210,
      sku: 'PM0064420',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267549',
      rtl_batch_array: '6267549',
      name_search: 'COSWFEAT',
      name: "Cortaderia selloana 'White Feather'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 982,
      chnn_stock_retail: 982,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P3SBA418',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3SBA418'
    },
    {
      status: 210,
      sku: 'PM0064419',
      core_name: 'Plant',
      sppl_ean: '8720664858480',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151530',
      rtl_batch_array: '6151530',
      name_search: 'COSPFEAT',
      name: "Cortaderia selloana 'Pink Feather'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 253,
      chnn_stock_retail: 253,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EX8K5WYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX8K5WYW'
    },
    {
      status: 210,
      sku: 'PM0063460',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291698',
      rtl_batch_array: '6291698',
      name_search: 'HEFPURPL',
      name: "Heuchera 'Forever Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JV8PZDDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV8PZDDD'
    },
    {
      status: 210,
      sku: 'PM0081172',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252786',
      rtl_batch_array: '6252786',
      name_search: 'THPCOCCI',
      name: "Thymus praecox 'Coccineus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 563,
      chnn_stock_retail: 563,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V56H41N3;HDKXZNCJ;81ER9S3T;9ZR13NZZ;9GTHBHVG;DHB5ALLX',
      statusContent: 'dot',
      hash: '2AZACL38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AZACL38'
    },
    {
      status: 210,
      sku: 'PM0063474',
      core_name: 'Plant',
      sppl_ean: '8720664884830',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6147185',
      rtl_batch_array: '6147185, 6217073, 6363203',
      name_search: 'SAOBERGG',
      name: "Salvia officinalis 'Berggarten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 2661,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '65BGXGPZ;5S57G5K4;7TWX5XHP;565A68YZ;7CCBH7Y7;9E16GD9H;JZKXA1EZ;WPX4H9LT;68ZNWXYD;A71ZS2N1;RT8C4Y77;Y9CR2P52',
      statusContent: 'dot',
      hash: 'J8HT4SD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8HT4SD1'
    },
    {
      status: 210,
      sku: 'PM0058020',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333197',
      rtl_batch_array: '6124307, 6147725, 6333197',
      name_search: 'BUSALICI',
      name: 'Buphthalmum salicifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1626,
      chnn_stock_retail: 3525,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E2AEYA8H;BGA58ASS;RJVJBZ7B;977HKRR3;7VDGVR44;Z66WVDEB;LS4PNPCN',
      statusContent: 'dot',
      hash: 'KVSPBDHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVSPBDHP'
    },
    {
      status: 210,
      sku: 'PM0045763',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350195',
      rtl_batch_array: '6187583, 6350195',
      name_search: 'GECDENEU',
      name: "Geranium 'Catherine Deneuve'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 554,
      chnn_stock_retail: 622,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6EA6S2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6EA6S2N'
    },
    {
      status: 210,
      sku: 'PM0063458',
      core_name: 'Plant',
      sppl_ean: '8720664690967',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837960',
      rtl_batch_array: '6142037, 5837960',
      name_search: 'GEROZANN',
      name: 'Geranium Rozanne',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 11044,
      chnn_stock_retail: 11045,
      sppl_order_minimum: 3,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZPJ4SN3;GT4DVXXL;C24JN3BT;XBYX425W;RNV4HWHL;JJ79CY8L;Z3PSHXBT;RRSTZT5E;L3WB9GNP;RB8ELNE5;XDL56ESV;BVLK6EXK;J1XBHEEE',
      statusContent: 'dot',
      hash: 'H4Z895G2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4Z895G2'
    },
    {
      status: 210,
      sku: 'PM0081173',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252787',
      rtl_batch_array: '6252787',
      name_search: 'THSERPYL',
      name: 'Thymus serpyllum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 518,
      chnn_stock_retail: 518,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7JGVWDD;GJJHC5TD;427WX8WA;8NWKD8ZG;JJH957KL;YHK72S3X;J64X8Z1H;45PVT992;BNHKGTBH;45SB2AZG;KPVNARKG;CKYCWAW3;Z4EZJVA2',
      statusContent: 'dot',
      hash: 'K89JWG92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K89JWG92'
    },
    {
      status: 210,
      sku: 'PM0070928',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222176',
      rtl_batch_array: '6222176',
      name_search: 'SPBTGOLD',
      name: "Spiraea betulifolia 'Tor Gold'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2611T54B',
      statusContent: 'dot',
      hash: 'XDDEE21E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDDEE21E'
    },
    {
      status: 210,
      sku: 'PM0058025',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301523',
      rtl_batch_array: '6084868, 6349967, 6301523',
      name_search: 'CRGDAVID',
      name: "Crocosmia 'George Davidson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 715,
      chnn_stock_retail: 1753,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7127ATTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7127ATTS'
    },
    {
      status: 910,
      sku: 'PM0045770',
      core_name: 'Plant',
      sppl_ean: '8720664864252',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6079890',
      rtl_batch_array: '6079890, 6350199',
      name_search: 'GECWHITE',
      name: "Geranium 'Coombland White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4,
      chnn_stock_retail: 569,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L4RKE7RE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4RKE7RE'
    },
    {
      status: 210,
      sku: 'PM0081174',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252789',
      rtl_batch_array: '6252789',
      name_search: 'THVCOMPA',
      name: "Thymus vulgaris 'Compactus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1075,
      chnn_stock_retail: 1075,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AS8EGAE8;E642L37J;DWHSSN46;ZSSG494G;JYSZ11W7;52TAYLKG;R4JTAD12;GY6NTNKR;4TLVP15B;ESLYKN8G;7CP55G5X;D7829T12;AEDJJDH1;JZSNZACG;PV153EBP',
      statusContent: 'dot',
      hash: '6V1RR38A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6V1RR38A'
    },
    {
      status: 210,
      sku: 'PM0070935',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222185',
      rtl_batch_array: '6222185',
      name_search: 'SPJGOLDM',
      name: "Spiraea japonica 'Goldmound'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PWTVET5X',
      statusContent: 'dot',
      hash: 'PY5E9LPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY5E9LPS'
    },
    {
      status: 210,
      sku: 'PM0070936',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222186',
      rtl_batch_array: '6222186',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: '23X454PT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23X454PT'
    },
    {
      status: 210,
      sku: 'PM0081175',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254661',
      rtl_batch_array: '6254661',
      name_search: 'AUCBLUE',
      name: "Aubrieta 'Cascade Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PYED5EL2',
      statusContent: 'dot',
      hash: '5XRSTSNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XRSTSNX'
    },
    {
      status: 210,
      sku: 'PM0045726',
      core_name: 'Plant',
      sppl_ean: '8720349477432',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014885',
      rtl_batch_array: '6014885',
      name_search: 'DIPERNYI',
      name: 'Disporopsis pernyi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 236,
      chnn_stock_retail: 236,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5W89R9WZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W89R9WZ'
    },
    {
      status: 910,
      sku: 'PM0077656',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSCLUST',
      name: "Coreopsis 'Star Cluster'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'XSX8561J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XSX8561J'
    },
    {
      status: 210,
      sku: 'PM0081176',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254662',
      rtl_batch_array: '6254662',
      name_search: 'AUCRED',
      name: "Aubrieta 'Cascade Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 463,
      chnn_stock_retail: 463,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X3JNEN9S;HY49JK9Y',
      statusContent: 'dot',
      hash: '4WA2VEYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WA2VEYZ'
    },
    {
      status: 210,
      sku: 'PM0070939',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222190',
      rtl_batch_array: '6222190',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'DBW63YRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBW63YRY'
    },
    {
      status: 210,
      sku: 'PM0070940',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253077',
      rtl_batch_array: '6253077, 6255514',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2050,
      sppl_prcp: 13.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: 'VH7V3KNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VH7V3KNS'
    },
    {
      status: 210,
      sku: 'PM0069968',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193621',
      rtl_batch_array: '6193621',
      name_search: 'IREDPCCA',
      name: 'Iris ensata Dinner Plate Cheese Cake',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.877,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z383V7NG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z383V7NG'
    },
    {
      status: 210,
      sku: 'PM0081177',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254676',
      rtl_batch_array: '6254676',
      name_search: 'CACFABLU',
      name: 'Campanula carpatica Florentes Avida Blue',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 484,
      chnn_stock_retail: 484,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P3ZLE2CB;5P235TS9',
      statusContent: 'dot',
      hash: 'GLPJNN6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLPJNN6W'
    },
    {
      status: 210,
      sku: 'PM0077659',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305801',
      rtl_batch_array: '6305801',
      name_search: 'ECDNOUGA',
      name: 'Echinacea Delicious Nougat',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 374,
      chnn_stock_retail: 374,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G1ATCZYZ;8ECTZ715',
      statusContent: 'dot',
      hash: 'BXRAZK7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXRAZK7B'
    },
    {
      status: 210,
      sku: 'PM0068515',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6205778',
      rtl_batch_array: '6205778, 6319694',
      name_search: 'GEMESPRE',
      name: "Geranium maculatum 'Espresso'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 747,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RKPRW9ZV;Y2TBVL67',
      statusContent: 'dot',
      hash: 'AW5WXLGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AW5WXLGH'
    },
    {
      status: 210,
      sku: 'PM0081178',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254677',
      rtl_batch_array: '6254677',
      name_search: 'CACFAWHI',
      name: 'Campanula carpatica Florentes Avida White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLN75157',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLN75157'
    },
    {
      status: 210,
      sku: 'PM0069069',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259162',
      rtl_batch_array: '6259162',
      name_search: 'SAMHLIPS',
      name: "Salvia microphylla 'Hot Lips'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 753,
      chnn_stock_retail: 753,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1Z23AJEH;AD1PCVB1;BAKA6Y3N;132W86X5;12KNZZ51;YSZKHDCR;DL23CSAX;NZ2W7SZZ;JVRJY9G3;9TVVH5DN;NEVVDG32;R2VCAAVS;6EVREY43;S5ZLPZZJ;J195DWNG;HP554TCE;2E5VSSZH;L3LDB5YX;HKTN4BZB;YTBDHANV;J8SCBA95;5J5DJWW9;TBHTCSX4;TGK6PH6S;JZS8B232;C7ACSS26;DP52WTHW;E19CE7PZ;KW5K1PV4;8ZR9VXZ6',
      statusContent: 'dot',
      hash: 'VKTWR2VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKTWR2VJ'
    },
    {
      status: 210,
      sku: 'PM0081179',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254678',
      rtl_batch_array: '6254678',
      name_search: 'CALLANNA',
      name: "Campanula lactiflora 'Loddon Anna'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 596,
      chnn_stock_retail: 596,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLGR2TLV;TXSRV1Y8;E4ETRD3J;5YPPKE9L',
      statusContent: 'dot',
      hash: '8JT36JYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8JT36JYS'
    },
    {
      status: 210,
      sku: 'PM0081180',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254679',
      rtl_batch_array: '6254679',
      name_search: 'CALPVARI',
      name: "Campanula lactiflora 'Prichard's Variety'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 481,
      chnn_stock_retail: 481,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHN19HHR;P8GLVY47',
      statusContent: 'dot',
      hash: 'H811EJXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H811EJXG'
    },
    {
      status: 210,
      sku: 'PM0081181',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284519',
      rtl_batch_array: '6284519, 6254680',
      name_search: 'CAPORTEN',
      name: 'Campanula portenschlagiana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 636,
      chnn_stock_retail: 1725,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NL6C3ZYC;R6133292;4AKC91CE;D1K357CG;ZLLVB5JJ;67G5Y3EH;X9STW145;18G244RN;X1P6DSJT;C3VV56AJ;R5WYL6E2;83ZSLVD6;CJ8CAS98;DRDLPA9X;2J5XY8R2;71LGVZNX;EDJV3GZJ;T88XVX92;9HPE78E6;WSYHAE15;2SH3DLNT;GWLV1WKE;EHW9KPBS;CZ9WELA2;JS9AETAL;RAZBDLCH;KGD4359H;Z4ZVNYCN;LLS5VVHH;JC2A5AHC',
      statusContent: 'dot',
      hash: 'ATYN66W3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATYN66W3'
    },
    {
      status: 910,
      sku: 'PM0077662',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECRTRUFF',
      name: "Echinacea 'Raspberry Truffle'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '4DBTZX8E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4DBTZX8E'
    },
    {
      status: 210,
      sku: 'PM0081182',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254681',
      rtl_batch_array: '6254681',
      name_search: 'CAPCATHA',
      name: "Campanula portenschlagiana 'Catharina'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 406,
      chnn_stock_retail: 406,
      sppl_prcp: 2.554,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2DCC1PA;DDADH2NT;R4TATVDJ;X6295G5D',
      statusContent: 'dot',
      hash: 'JV4PZ1BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV4PZ1BE'
    },
    {
      status: 210,
      sku: 'PM0063574',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329937',
      rtl_batch_array: '6202814, 6329937',
      name_search: 'GEHGRAVE',
      name: "Geranium himalayense 'Gravetye'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1018,
      chnn_stock_retail: 3842,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3YGXC7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3YGXC7J'
    },
    {
      status: 210,
      sku: 'PM0068652',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307841',
      rtl_batch_array: '6307841',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 3778,
      chnn_stock_retail: 3778,
      sppl_prcp: 9.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: '23C36NVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23C36NVC'
    },
    {
      status: 210,
      sku: 'PM0070937',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222187',
      rtl_batch_array: '6222187',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'LKBX6L35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKBX6L35'
    },
    {
      status: 210,
      sku: 'PM0045419',
      core_name: 'Plant',
      sppl_ean: '8720664681965',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054489',
      rtl_batch_array: '6301465, 6054489',
      name_search: 'BOSIEBOL',
      name: 'Boehmeria sieboldiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 371,
      chnn_stock_retail: 427,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BYRAPBVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYRAPBVS'
    },
    {
      status: 210,
      sku: 'PM0077499',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304856',
      rtl_batch_array: '6304856',
      name_search: 'ACPBMAIK',
      name: "Acer palmatum 'Beni-maiko'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3164,
      chnn_stock_retail: 3164,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JAA1EAER;XC6GY87T;CZEL4767;YSHNBSTS;KJYV9PJG;G27ZBKTG;1Z8RWKED;XW2Y7YY8;8VNCA163;Y8DGZH4W;SGZZVX1Z;ZYL7BGNZ;Y64VNZE1',
      statusContent: 'dot',
      hash: 'W1X3KYAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1X3KYAL'
    },
    {
      status: 210,
      sku: 'PM0045354',
      core_name: 'Plant',
      sppl_ean: '8720349484287',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054337',
      rtl_batch_array: '6054337',
      name_search: 'ALNEAPOL',
      name: 'Allium neapolitanum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1ATLBV73;DBBYWVEZ',
      statusContent: 'dot',
      hash: '4K1RCAXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K1RCAXE'
    },
    {
      status: 210,
      sku: 'PM0068649',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307796',
      rtl_batch_array: '6307796',
      name_search: 'VACJERSE',
      name: "Vaccinium corymbosum 'Jersey'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9BT27BA8',
      statusContent: 'dot',
      hash: 'C8T8GWHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8T8GWHZ'
    },
    {
      status: 210,
      sku: 'PM0077501',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304858',
      rtl_batch_array: '6304858',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'DX8HHDSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX8HHDSX'
    },
    {
      status: 810,
      sku: 'PM0068936',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209804',
      rtl_batch_array: '6209804',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'BS2VDYEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS2VDYEN'
    },
    {
      status: 210,
      sku: 'PM0077505',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304862',
      rtl_batch_array: '6304862',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 388,
      chnn_stock_retail: 388,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: 'X6K57J5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6K57J5Y'
    },
    {
      status: 210,
      sku: 'PM0070938',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222188',
      rtl_batch_array: '6222188',
      name_search: 'SPJMCARP',
      name: 'Spiraea japonica Magic Carpet',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5Z2HJSWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Z2HJSWV'
    },
    {
      status: 210,
      sku: 'PM0077653',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305794',
      rtl_batch_array: '6305794',
      name_search: 'ASNAPDOM',
      name: "Aster novae-angliae 'Purple Dome'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7Z4SBCS;DR8AV5DN;11KTR8YZ;L4ZD9XX5;TCEV9KKA;N4KLGN9J;23CATXL3;VL2PN92C;2S8B425H;24BBLEWE',
      statusContent: 'dot',
      hash: 'DP2VNPVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP2VNPVW'
    },
    {
      status: 910,
      sku: 'PM0077660',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECECCENT',
      name: "Echinacea 'Eccentric'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'S4PPW5HR',
      statusContent: 'dot',
      hash: 'LRSVVCAT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LRSVVCAT'
    },
    {
      status: 810,
      sku: 'PM0068935',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209803',
      rtl_batch_array: '6209803',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'NDVW5XW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDVW5XW1'
    },
    {
      status: 210,
      sku: 'PM0064409',
      core_name: 'Plant',
      sppl_ean: '8720664855656',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151520',
      rtl_batch_array: '6151520',
      name_search: 'CARFALLS',
      name: 'Carex Ribbon Falls',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9630,
      chnn_stock_retail: 9630,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1AGT6454;EBRZ94TE;D1JKT933;BHBLYR23;PNVB286B;J5XWYE65;9LVK19AC;89Y7DPVH;2HKGHD6L;YKELS7XA;S8KK36Y9;RXS2TWY6;P1N41PP6;7VJXERXR;79VXNCWR;ES3GG9YY;RA1GP1K1;K523XY2D;66DHTSBS;LTYABY8P;CVETVTEK;8ZJ2KYE5;KEG2T3CR;Y1AGPGZY;6V9PHSX9',
      statusContent: 'dot',
      hash: 'H75VYV26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H75VYV26'
    },
    {
      status: 210,
      sku: 'PM0077502',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304859',
      rtl_batch_array: '6304859',
      name_search: 'ACPBUTTE',
      name: "Acer palmatum 'Butterfly'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z8LTJ8S9;AG3R7G4L;W113J7X5;9W94Y6HL;4D9B3KT2;L1R2L9G9;81S3BYXS;EZE7H3GR;RD4HPXTL;55Z5SL7X',
      statusContent: 'dot',
      hash: 'RACBW6L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RACBW6L5'
    },
    {
      status: 210,
      sku: 'PM0070929',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222177',
      rtl_batch_array: '6222177',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: '7LZ9G7H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LZ9G7H1'
    },
    {
      status: 210,
      sku: 'PM0064406',
      core_name: 'Plant',
      sppl_ean: '8720664855595',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151517',
      rtl_batch_array: '6151517',
      name_search: 'CAOEVERO',
      name: "Carex oshimensis 'Everoro'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9905,
      chnn_stock_retail: 9905,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6WVZSH5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WVZSH5P'
    },
    {
      status: 210,
      sku: 'PM0070933',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222182',
      rtl_batch_array: '6222182',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'SN2TVNZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN2TVNZT'
    },
    {
      status: 210,
      sku: 'PM0068658',
      core_name: 'Plant',
      sppl_ean: '8720353087733',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054443',
      rtl_batch_array: '6077531, 6054443',
      name_search: 'ASAPFEIF',
      name: "Astilbe (A) 'Anita Pfeifer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 316,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZCR7WLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZCR7WLL'
    },
    {
      status: 210,
      sku: 'PM0070941',
      core_name: 'Plant',
      sppl_ean: '8720626315549',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5964884',
      rtl_batch_array: '5964884',
      name_search: 'PHBPARAD',
      name: "Phlox (P) 'Blue Paradise'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1201,
      chnn_stock_retail: 1201,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6XEH3B6;DS1L57VH;NVBBLZND;97XABELR;SZCE85KR',
      statusContent: 'dot',
      hash: 'KSKJBR55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSKJBR55'
    },
    {
      status: 210,
      sku: 'PM0068654',
      core_name: 'Plant',
      sppl_ean: '8720664869387',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6105476',
      rtl_batch_array: '6105476',
      name_search: 'HYOFFICI',
      name: 'Hyssopus officinalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1457,
      chnn_stock_retail: 1457,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LD35SZ49;GK2E2RS7;H6DNLBXY;136LHVLL;599V1L3G;NCSTW2E3;2DS23PPT;7X7CH55V;7KZETV4G;VGBADH1E;ERBV6EPN;PE6CB7Y1;91HRG21A;8N5H4JJP;4JLLRS8C;BD9B2BEP;7KC3EHSV;KXVCWK8E;KYV2GRA4;JGYVNNZC',
      statusContent: 'dot',
      hash: 'EZJ759Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZJ759Y8'
    },
    {
      status: 210,
      sku: 'PM0045721',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242143',
      rtl_batch_array: '6242143',
      name_search: 'DIAALBIF',
      name: "Dictamnus albus 'Albiflorus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2963,
      chnn_stock_retail: 2963,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '73V991BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73V991BT'
    },
    {
      status: 210,
      sku: 'PM0045247',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6276033',
      rtl_batch_array: '6207774, 6090583, 6276033',
      name_search: 'ACFCGOLD',
      name: "Achillea filipendulina 'Cloth of Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4158,
      chnn_stock_retail: 6572,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JZPNA69A;Z73TXG24;L9JAL6CT;JCH6YLB4;GA3L3B3E;9LTH5DBB;C22RLHXX;LTY8BPE8;VPDA68YK;GYHJ9A7L;YPGZJ4XT',
      statusContent: 'dot',
      hash: 'R9CSZPNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9CSZPNB'
    },
    {
      status: 810,
      sku: 'PM0064856',
      core_name: 'Plant',
      sppl_ean: '8720349437528',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953538',
      rtl_batch_array: '5953538',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      statusContent: 'dot',
      hash: 'SDECTZCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDECTZCS'
    },
    {
      status: 210,
      sku: 'PM0057058',
      core_name: 'Plant',
      sppl_ean: '8720664864375',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6090792',
      rtl_batch_array: '6090792',
      name_search: 'GERADMIR',
      name: "Geranium 'Red Admiral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VT6VXJ6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VT6VXJ6K'
    },
    {
      status: 210,
      sku: 'PM0069971',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193624',
      rtl_batch_array: '6193624',
      name_search: 'IREDPJO',
      name: 'Iris ensata Dinner Plate Jell O',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.877,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ND1TGHE9',
      statusContent: 'dot',
      hash: 'ZVGVNVYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVGVNVYV'
    },
    {
      status: 210,
      sku: 'PM0045420',
      core_name: 'Plant',
      sppl_ean: '8720664681972',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054490',
      rtl_batch_array: '6054490',
      name_search: 'BOSPICAT',
      name: 'Boehmeria spicata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8677LTCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8677LTCJ'
    },
    {
      status: 210,
      sku: 'PM0045758',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295592',
      rtl_batch_array: '6350193, 6295592',
      name_search: 'GECBERGG',
      name: "Geranium cantabrigiense 'Berggarten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3649,
      chnn_stock_retail: 4213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKC6782S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKC6782S'
    },
    {
      status: 210,
      sku: 'PM0068673',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176420',
      rtl_batch_array: '6176420',
      name_search: 'HEKUGELS',
      name: "Helenium 'Kugelsonne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1049,
      chnn_stock_retail: 1049,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRE37S7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRE37S7L'
    },
    {
      status: 210,
      sku: 'PM0069969',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193622',
      rtl_batch_array: '6193622',
      name_search: 'IREDPICR',
      name: 'Iris ensata Dinner Plate Ice Cream',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.877,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7D2D2BN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D2D2BN6'
    },
    {
      status: 210,
      sku: 'PM0068675',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284528',
      rtl_batch_array: '6284528, 5997284, 6254732',
      name_search: 'GEJBLUE',
      name: "Geranium 'Johnson's Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1377,
      chnn_stock_retail: 3256,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8Y833LL;8KYS292B;5Y865CZD;SNTR5ZAT;H9JAND4T;NAJCXDPP;JBC8W97N;4W67GJP2;JWYSAJEN;CS5G2HB9;B66LCXLN;P8H3XAAB;HZ7K52ZS;TRGRSEXB;HN27NEPC;PJG195K9',
      statusContent: 'dot',
      hash: '2CETV48G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CETV48G'
    },
    {
      status: 210,
      sku: 'PM0045759',
      core_name: 'Plant',
      sppl_ean: '8720664690745',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015093',
      rtl_batch_array: '6015093',
      name_search: 'GECCROSE',
      name: "Geranium cantabrigiense 'Crystal Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 711,
      chnn_stock_retail: 711,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AK2B4W3C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AK2B4W3C'
    },
    {
      status: 210,
      sku: 'PM0069972',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193625',
      rtl_batch_array: '6193625',
      name_search: 'IREDPTIR',
      name: 'Iris ensata Dinner Plate Tiramisu',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.877,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGEJEDV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGEJEDV9'
    },
    {
      status: 210,
      sku: 'PM0054457',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350723',
      rtl_batch_array: '6350723',
      name_search: 'ONNATRIX',
      name: 'Ononis natrix',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J5BRZ2WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5BRZ2WH'
    },
    {
      status: 210,
      sku: 'PM0019171',
      core_name: 'Plant',
      sppl_ean: '8720349415472',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582247',
      rtl_batch_array: '5582247',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.374,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '18CYG39T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18CYG39T'
    },
    {
      status: 210,
      sku: 'PM0069970',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193623',
      rtl_batch_array: '6193623',
      name_search: 'IREDPCUP',
      name: 'Iris ensata Dinner Plate Cupcake',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.877,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BHGP1WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BHGP1WA'
    },
    {
      status: 210,
      sku: 'PM0058022',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333210',
      rtl_batch_array: '6164387, 6333210',
      name_search: 'CEMGRAND',
      name: "Centaurea montana 'Grandiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7257,
      chnn_stock_retail: 7933,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29RSBGTJ;RYDLWBET;PJ1JCWC4;XSZ87KZT;X3CDDK9T;NHHWLGW3;BYB6ADSE;B1L2DB6L;PCVP5S68;P4RNXZ9H;H8LY2LJL;X5NVKB8A;Y6VSR6PK;8PTDCSLY;LDW1J19N;CY8636ER;4C7DP43D;PVVWJX12;NEJV2CX8;ZHA7NTDC;LR2ZWWAJ;ZNXS8L51;DSA218GE',
      statusContent: 'dot',
      hash: '97A157P7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97A157P7'
    },
    {
      status: 210,
      sku: 'PM0064408',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259718',
      rtl_batch_array: '6259718',
      name_search: 'CAOJSGRE',
      name: "Carex oshimensis 'J. S. Greenwell'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YVG8SGY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVG8SGY5'
    },
    {
      status: 210,
      sku: 'PM0057154',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353134',
      rtl_batch_array: '6353134, 6334772',
      name_search: 'PEABONFI',
      name: 'Persicaria amplexicaulis Bonfire',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 2012,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P25ARLDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P25ARLDX'
    },
    {
      status: 810,
      sku: 'PM0057155',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301776',
      rtl_batch_array: '6301776',
      name_search: 'PEAHSOCI',
      name: "Persicaria amplexicaulis 'High Society'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AV1CJKEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AV1CJKEX'
    },
    {
      status: 210,
      sku: 'PM0064735',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274203',
      rtl_batch_array: '6274203',
      name_search: 'HEPDAMAS',
      name: "Hemerocallis 'Pink Damask'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJYECKB7',
      statusContent: 'dot',
      hash: '1JW1T3RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JW1T3RB'
    },
    {
      status: 210,
      sku: 'PM0064727',
      core_name: 'Plant',
      sppl_ean: '8720664859449',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140810',
      rtl_batch_array: '6140810',
      name_search: 'DEMFDBLU',
      name: "Delphinium (P) 'Magic Fountains Dark Blue'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PT4N1N37',
      statusContent: 'dot',
      hash: '6GLL2AS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GLL2AS5'
    },
    {
      status: 210,
      sku: 'PM0046686',
      core_name: 'Plant',
      sppl_ean: '8720664695207',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875120',
      rtl_batch_array: '5875120',
      name_search: 'IRSALONI',
      name: "Iris (G) 'Salonique'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'YZ87JD7H',
      statusContent: 'dot',
      hash: 'L9T526T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9T526T8'
    },
    {
      status: 210,
      sku: 'PM0077652',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305793',
      rtl_batch_array: '6305793',
      name_search: 'ASDESTAR',
      name: "Aster divaricatus 'Eastern Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RAZBKS1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAZBKS1H'
    },
    {
      status: 210,
      sku: 'PM0077654',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305795',
      rtl_batch_array: '6305795',
      name_search: 'ASNBDAUE',
      name: "Aster novi-belgii 'Dauerblau'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YAHTBWDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAHTBWDZ'
    },
    {
      status: 210,
      sku: 'PM0077655',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305797',
      rtl_batch_array: '6305797',
      name_search: 'ASMSBILL',
      name: "Astrantia major 'Star of Billion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_prcp: 3.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GHPXV3WT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHPXV3WT'
    },
    {
      status: 910,
      sku: 'PM0077658',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECCPURPL',
      name: "Echinacea 'Chunky Purple'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'DKLNRZ29',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DKLNRZ29'
    },
    {
      status: 210,
      sku: 'PM0070930',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222178',
      rtl_batch_array: '6222178',
      name_search: 'SPJDRED',
      name: "Spiraea japonica 'Dart's Red'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V48RLPL3',
      statusContent: 'dot',
      hash: 'KCA8DDB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCA8DDB7'
    },
    {
      status: 210,
      sku: 'PM0070934',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222184',
      rtl_batch_array: '6222184',
      name_search: 'SPJGOLDM',
      name: "Spiraea japonica 'Goldmound'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PWTVET5X',
      statusContent: 'dot',
      hash: 'PZ4Z1SZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ4Z1SZV'
    },
    {
      status: 210,
      sku: 'PM0069068',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210362',
      rtl_batch_array: '6210362',
      name_search: 'PRATREAD',
      name: "Pratia angulata 'Treadwellii'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1123,
      chnn_stock_retail: 1123,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ7KV16T;5DAX2AHB;VVZGWEP5;Z3BBJ44V',
      statusContent: 'dot',
      hash: 'LG9TX27B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LG9TX27B'
    },
    {
      status: 210,
      sku: 'PM0064962',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229427',
      rtl_batch_array: '6229427',
      name_search: 'SALFRUTI',
      name: 'Salvia fruticosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZJEV616',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZJEV616'
    },
    {
      status: 910,
      sku: 'PM0074217',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: '7J7C27S1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7J7C27S1'
    },
    {
      status: 210,
      sku: 'PM0045591',
      core_name: 'Plant',
      sppl_ean: '8720349492800',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014566',
      rtl_batch_array: '6014566',
      name_search: 'CACTISLA',
      name: "Carex ciliatomarginata 'Treasure Island'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W8G5B8GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8G5B8GS'
    },
    {
      status: 210,
      sku: 'PM0081183',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254682',
      rtl_batch_array: '6254682',
      name_search: 'CAPOSCHA',
      name: 'Campanula poscharskyana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 323,
      chnn_stock_retail: 323,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8SYZHHW;59GLBEPR;DG4KPLWV;63E2PWS7;7AE8N8Y9;24CES13Y;VKJBG9PX;B7L11J7T;WW7LPKAB;B8L52A8S;8XW789ZC;WTZSZKD2;VJAKT7ZN;RNDSWTCK;1L84PETH;ZJ2Y7LD7',
      statusContent: 'dot',
      hash: 'KLP3T27N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLP3T27N'
    },
    {
      status: 210,
      sku: 'PM0077507',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304864',
      rtl_batch_array: '6304864',
      name_search: 'ACPPIXIE',
      name: "Acer palmatum 'Pixie'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCB37C6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCB37C6L'
    },
    {
      status: 210,
      sku: 'PM0045807',
      core_name: 'Plant',
      sppl_ean: '8720664862487',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112365',
      rtl_batch_array: '6112365',
      name_search: 'EUCERATO',
      name: 'Euphorbia ceratocarpa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1341YTCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1341YTCD'
    },
    {
      status: 210,
      sku: 'PM0045523',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301513',
      rtl_batch_array: '6349936, 6301513',
      name_search: 'CIINTYBU',
      name: 'Cichorium intybus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 703,
      chnn_stock_retail: 3055,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GG5C5ENN;K1PKH5Y4;K5ND1B5S;WAHZT227;V8P3D9AA;NAT5RJ52;HD171676;WJEHDHLC;RWTTG898;LDYYSSBV;VKVZRDJT;ETPRSH7J;4HRETNKE;XDE17E36;A1RDAJ16;RJ19PJYN;BPH91EB1',
      statusContent: 'dot',
      hash: 'BXTS4E77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXTS4E77'
    },
    {
      status: 810,
      sku: 'PM0045802',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301332',
      rtl_batch_array: '6301332',
      name_search: 'EUCEPLUM',
      name: "Eupatorium capillifolium 'Elegant Plume'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GXT1GT8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXT1GT8K'
    },
    {
      status: 210,
      sku: 'PM0068676',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 3,
      btch_active_retail: '6252614',
      rtl_batch_array: '5918369, 6252614, 6385797',
      name_search: 'HENIGER',
      name: 'Helleborus niger',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3831,
      chnn_stock_retail: 9959,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RVB32ABL;V9DJV4SD;CN7K6X19;8KN3XASX;9CNXKXGV;PRPXG6Y6;2AJHJESG;VZ6AAYNJ;VAGGYRV6;DA9PW75W;WSCPV35X;TLC3AHWL;ZY1YR8PZ;96HDHYKL;88HR175N;K5GREL5X;A79DX11H;EZY1R7D7;PYA1HN3P;JLZ4635X;RH58GJ33;GNVGCH1H;271YB5R9',
      statusContent: 'dot',
      hash: 'HYT5P42Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYT5P42Y'
    },
    {
      status: 210,
      sku: 'PM0077510',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304867',
      rtl_batch_array: '6304867',
      name_search: 'ACEPSGOL',
      name: "Acer palmatum 'Summer Gold'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 158,
      chnn_stock_retail: 158,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJRX1T2W',
      statusContent: 'dot',
      hash: '4T42J2X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T42J2X3'
    },
    {
      status: 210,
      sku: 'PM0045887',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6205779',
      rtl_batch_array: '6220785, 6221382, 6205779',
      name_search: 'GEPSILOS',
      name: 'Geranium psilostemon',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2354,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V5C3E66X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5C3E66X'
    },
    {
      status: 210,
      sku: 'PM0077512',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304869',
      rtl_batch_array: '6304869',
      name_search: 'ALJOMBRE',
      name: 'Albizia julibrissin Ombrella',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 14119,
      chnn_stock_retail: 14119,
      sppl_prcp: 7.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JHBCXJ;ZNPHTD5N;9NZJN8NP',
      statusContent: 'dot',
      hash: 'K52SVEHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K52SVEHE'
    },
    {
      status: 210,
      sku: 'PM0077513',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304870',
      rtl_batch_array: '6304870',
      name_search: 'ALJSCHOC',
      name: "Albizia julibrissin 'Summer Chocolate'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 8588,
      chnn_stock_retail: 8588,
      sppl_prcp: 7.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '81KLXWEX;8R6LRWVR;2YBT1KW5;XLB7TLLD;K3ZYB766;Z4A9VVJ3',
      statusContent: 'dot',
      hash: 'WL8NVDNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL8NVDNG'
    },
    {
      status: 210,
      sku: 'PM0077514',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304871',
      rtl_batch_array: '6304871',
      name_search: 'PIJPURIT',
      name: "Pieris japonica 'Purity'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1076,
      chnn_stock_retail: 1076,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPAT2XJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPAT2XJH'
    },
    {
      status: 210,
      sku: 'PM0074218',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268503',
      rtl_batch_array: '6268503',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: 'G33GXN7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G33GXN7H'
    },
    {
      status: 210,
      sku: 'PM0081184',
      core_name: 'Plant',
      sppl_ean: '8720349482559',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5964247',
      rtl_batch_array: '5964247, 6254851',
      name_search: 'THVULGAR',
      name: 'Thymus vulgaris',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 114,
      chnn_stock_retail: 1097,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DCYCA5XG;74AT3YZX;KAXXC9WT;XRJ7PVV7;SJE2ZLGC;ZZYKV7XY;5VLRRV8Z;AHRXKSST;SZGY7AXC;9Y2YSBPX;B1W4JS7C',
      statusContent: 'dot',
      hash: 'XTN72LSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTN72LSW'
    },
    {
      status: 210,
      sku: 'PM0045894',
      core_name: 'Plant',
      sppl_ean: '8720353011134',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6015009',
      rtl_batch_array: '6301608, 6015009',
      name_search: 'GESELSBE',
      name: "Geranium sanguineum 'Elsbeth'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 2284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2JC77E6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JC77E6W'
    },
    {
      status: 210,
      sku: 'PM0077518',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304880',
      rtl_batch_array: '6304880',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 3.371,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'CT1N68EE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT1N68EE'
    },
    {
      status: 210,
      sku: 'PM0077519',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304881',
      rtl_batch_array: '6304881',
      name_search: 'PRLSOFIA',
      name: 'Prunus l. Sofia',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 6128,
      chnn_stock_retail: 6128,
      sppl_prcp: 4.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55C22JJD;KVAR9T4R;W61381ZV;BWE9DJRE;489A9SSG;BC68HSEG;VSECYCP2;EBERS9E5;9PP3TLZ8;SB4GEBPG;GDH58JP7',
      statusContent: 'dot',
      hash: '2GBEJXH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GBEJXH5'
    },
    {
      status: 210,
      sku: 'PM0057216',
      core_name: 'Plant',
      sppl_ean: '8720664867000',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091310',
      rtl_batch_array: '6091310',
      name_search: 'HEMSSEAS',
      name: "Hemerocallis 'South Seas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 172,
      chnn_stock_retail: 172,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '92CGG137;JHSV67E9;7Y2Z7ZV8;8ZLTA3A6;9W9EX2VJ;L69TZW4G;H4AYWELC',
      statusContent: 'dot',
      hash: 'R3V9HD7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3V9HD7X'
    },
    {
      status: 210,
      sku: 'PM0045600',
      core_name: 'Plant',
      sppl_ean: '8720353091051',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034226',
      rtl_batch_array: '6034226',
      name_search: 'CAOGWOND',
      name: "Carex oshimensis 'Green Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VE9EE2VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE9EE2VW'
    },
    {
      status: 210,
      sku: 'PM0077520',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304883',
      rtl_batch_array: '6304883',
      name_search: 'RHGPURPL',
      name: 'Rhododendron (AJ) Geisha Purple',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1091,
      chnn_stock_retail: 1091,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XS1TGP2E;V4VAAV8Z;YXYH1KSW;WH69BCWB;9XS458DT;N37WCPLJ;A4S2J8EK;EG5NGT2S;ZHA6TTND;PPXGHGJT;W4HWEP29;927SG43G;E6BBHWGR;77843Y5Y;93Z7KE2S;ZN2JGZ3Y;2XSB4Z7R;PLL5CGAR;TWDC3WZ3;4B7WE1TW;K5D6CEHT;YG4SVVL7',
      statusContent: 'dot',
      hash: '9APK1J4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9APK1J4B'
    },
    {
      status: 210,
      sku: 'PM0077521',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304884',
      rtl_batch_array: '6304884',
      name_search: 'RHGRED',
      name: 'Rhododendron (AJ) Geisha Red',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1548,
      chnn_stock_retail: 1548,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YGYW4B4L;CPR39X75;HHP2LCBG',
      statusContent: 'dot',
      hash: '8DYZR7LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DYZR7LC'
    },
    {
      status: 210,
      sku: 'PM0077522',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304885',
      rtl_batch_array: '6304885',
      name_search: 'RHKERMES',
      name: "Rhododendron (AJ) 'Kermesina'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1509,
      chnn_stock_retail: 1509,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZA3WYTSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZA3WYTSR'
    },
    {
      status: 210,
      sku: 'PM0057251',
      core_name: 'Plant',
      sppl_ean: '8720664868403',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091459',
      rtl_batch_array: '6091459',
      name_search: 'HOXANADU',
      name: "Hosta 'Xanadu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PDW2KTL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDW2KTL3'
    },
    {
      status: 210,
      sku: 'PM0077523',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304887',
      rtl_batch_array: '6304887',
      name_search: 'RHIMPEDI',
      name: 'Rhododendron impeditum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1502,
      chnn_stock_retail: 1502,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VW4EYZSV;N73SYG97;9K4NJ8AC;95T95VB3;L7S2BD3N;9BWHA6BB;AYJSVW4G;6X45EG52;VLAT1PWG;CB9PSCLY;PW9K416V;S4G1JVWL;A3P14RYL;C4XLRT1T;CE7RC991;HXT3D2LL',
      statusContent: 'dot',
      hash: 'NZX3ZYWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZX3ZYWZ'
    },
    {
      status: 210,
      sku: 'PM0077524',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304888',
      rtl_batch_array: '6304888',
      name_search: 'RHIALBUM',
      name: "Rhododendron impeditum 'Album'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1288,
      chnn_stock_retail: 1288,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5LWHZ5R9;4V5EZGKA;LGB8EV8A;RSDDP2ET;AEZ1SSP3',
      statusContent: 'dot',
      hash: 'JBJ95YS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBJ95YS8'
    },
    {
      status: 210,
      sku: 'PM0058460',
      core_name: 'Plant',
      sppl_ean: '8720664874664',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112785',
      rtl_batch_array: '6112785',
      name_search: 'MOCJAERL',
      name: "Molinia caerulea 'JS Aha Erlebnis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 647,
      chnn_stock_retail: 647,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XY8SPEW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XY8SPEW2'
    },
    {
      status: 210,
      sku: 'PM0077525',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304889',
      rtl_batch_array: '6304889',
      name_search: 'RHAZURIK',
      name: "Rhododendron 'Azurika'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1524,
      chnn_stock_retail: 1524,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8TZCH3JW;S8NXT1PZ;NYBX71G8;RT4B8PSL;7X5A9NP1;7G1XVR97;STE5ESP5;K7P6SYVX;9BGN8ZHZ;WLN1CEE5;Y633TPDA;HHD5NL9C;KZXTRWY7',
      statusContent: 'dot',
      hash: '9R5PJAG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9R5PJAG1'
    },
    {
      status: 210,
      sku: 'PM0077526',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304890',
      rtl_batch_array: '6304890',
      name_search: 'RHSWONDE',
      name: "Rhododendron (F) 'Scarlet Wonder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1124,
      chnn_stock_retail: 1124,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EBLK1297;7PAYA2T9;5YJTW2TG;DVTVVZ35;L192SN6L;GTPT8DR3;AR556LSE;YVL4SKHZ;DTP1NDTW;L2EAV92A;8BYVD37Y;NYA8SEX2',
      statusContent: 'dot',
      hash: 'JWJS6YA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWJS6YA8'
    },
    {
      status: 210,
      sku: 'PM0077664',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305806',
      rtl_batch_array: '6305806',
      name_search: 'ECPSCLOU',
      name: "Echinacea purpurea 'Summer Cloud'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G1PT13JR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1PT13JR'
    },
    {
      status: 810,
      sku: 'PM0077666',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305809',
      rtl_batch_array: '6305809',
      name_search: 'ECPVIRGI',
      name: "Echinacea purpurea 'Virgin'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RSHBNT95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSHBNT95'
    },
    {
      status: 910,
      sku: 'PM0044480',
      core_name: 'Plant',
      sppl_ean: '8720349421077',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '5646082',
      rtl_batch_array: '5646082',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '070080C10PY',
      rng_range_identifier: 'PYRAH070080C10',
      rng_range_description: 'Pyramid H70 cm 80 cm C10',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 26.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'EX8YH33Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX8YH33Y'
    },
    {
      status: 210,
      sku: 'PM0081185',
      core_name: 'Plant',
      sppl_ean: '8720626302792',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '5861501',
      rtl_batch_array: '5861501',
      name_search: 'HEPIMELE',
      name: 'Hebe pimeleoides',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBGCE1GB;T317JGSA;JNYZS1HK;PSTACL85;LWV1PEGG;YW85LL3K;XJG6ZTH5;JNE5DE7B;BKHKPWZK;GDBB2BV8;J7884SCS;H824C7XA;E1GNXSWJ;89BX6RRX;1X5ZYRLJ;RHA6XL49',
      statusContent: 'dot',
      hash: '22BX4TB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22BX4TB5'
    },
    {
      status: 810,
      sku: 'PM0057261',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254393',
      rtl_batch_array: '6196789, 6254393',
      name_search: 'IRSPALAC',
      name: "Iris (G) 'Sultan's Palace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 1117,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5K19V1A5;LXD53DL3',
      statusContent: 'dot',
      hash: 'JKGE1EPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKGE1EPH'
    },
    {
      status: 910,
      sku: 'PM0068928',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHFPSPIN',
      name: 'Phlox (P) Flame Pro Soft Pink',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'W8SNWSK1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W8SNWSK1'
    },
    {
      status: 810,
      sku: 'PM0068951',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209820',
      rtl_batch_array: '6209820',
      name_search: 'JAOFFICI',
      name: 'Jasminum officinale',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 2.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCHN4RN1;H5LBGN69;TXTCZZXG;WA2L7Z4C;WRJ6CX3E;6HZEXZSW;6GBCX9YX',
      statusContent: 'dot',
      hash: 'G585YLLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G585YLLC'
    },
    {
      status: 910,
      sku: 'PM0077665',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECPBKISS',
      name: "Echinacea purpurea 'Butterfly Kissis'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'PNS1RX5K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PNS1RX5K'
    },
    {
      status: 210,
      sku: 'PM0077508',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304865',
      rtl_batch_array: '6304865',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3367,
      chnn_stock_retail: 3367,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'TSNAB2XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSNAB2XG'
    },
    {
      status: 210,
      sku: 'PM0077509',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304866',
      rtl_batch_array: '6304866',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4638,
      chnn_stock_retail: 4638,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: '1LW1ZS73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LW1ZS73'
    },
    {
      status: 210,
      sku: 'PM0068606',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324688',
      rtl_batch_array: '6206130, 6324688',
      name_search: 'AJRALBA',
      name: "Ajuga reptans 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2133,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RJ72WHWV;9D74PJA1;19CVC5W8;5R7TX91A;VZLVLHEX;7GPBR17Z;BSA9XXEX',
      statusContent: 'dot',
      hash: 'N459XHP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N459XHP9'
    },
    {
      status: 210,
      sku: 'PM0070942',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222197',
      rtl_batch_array: '6222197',
      name_search: 'PIDJKLUI',
      name: "Pinus densiflora 'Jane Kluis'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WN1X4L83;J4BDZ2DV;6XA3T14A;ER8X3B9N;8DS1ZE1G;V7R6H51R',
      statusContent: 'dot',
      hash: '2KAA7R7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KAA7R7B'
    },
    {
      status: 210,
      sku: 'PM0077511',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304868',
      rtl_batch_array: '6304868',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 5828,
      chnn_stock_retail: 5828,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'LLWR3NNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLWR3NNH'
    },
    {
      status: 210,
      sku: 'PM0077515',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304872',
      rtl_batch_array: '6304872',
      name_search: 'PIJRMILL',
      name: "Pieris japonica 'Red Mill'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1515,
      chnn_stock_retail: 1515,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P27S4W9Y;RB5ADJ46;7BSHB85V;61NVEJRW;P8SP8G7S;ED2SD6AS;12VNEV7G;Z6SR8JSR;154SPS99;ESRE6CGA;83TBHDWZ;E79V1Z79',
      statusContent: 'dot',
      hash: 'LZTZ49NR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZTZ49NR'
    },
    {
      status: 210,
      sku: 'PM0068537',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205976',
      rtl_batch_array: '6205976',
      name_search: 'CACBFORM',
      name: "Carex comans 'Bronze Form'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DETA2LLY;SLTRBH5C;GKB8WGT4;WYSS85WR;1TCTS9E8;Z7J2NP88;L5BBAJX3;V4XGJG8R;JVTX6T7A;9G91XD8D;H5GRDS1K;VBW63X7B;71XCG96S;D4SK9DZV;CYR4YT14;GSSBA74Y;HWW9956Z',
      statusContent: 'dot',
      hash: '2LWHRPSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LWHRPSH'
    },
    {
      status: 210,
      sku: 'PM0057208',
      core_name: 'Plant',
      sppl_ean: '8720664866690',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091301',
      rtl_batch_array: '6091301',
      name_search: 'HEGCHIME',
      name: "Hemerocallis 'Golden Chimes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S987WX5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S987WX5S'
    },
    {
      status: 210,
      sku: 'PM0057211',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229838',
      rtl_batch_array: '6229838, 6161739',
      name_search: 'HELBELLS',
      name: "Hemerocallis 'Lemon Bells'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 810,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CA356C7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA356C7K'
    },
    {
      status: 210,
      sku: 'PM0077667',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305810',
      rtl_batch_array: '6305810',
      name_search: 'ERKPROFU',
      name: "Erigeron karvinskianus 'Profusion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 2.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DTWPJ4C5;PTKP3Y5P;G27GBAJV',
      statusContent: 'dot',
      hash: 'P2LS3C3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2LS3C3T'
    },
    {
      status: 210,
      sku: 'PM0045587',
      core_name: 'Plant',
      sppl_ean: '8720349466788',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014562',
      rtl_batch_array: '6014562',
      name_search: 'CABAUREO',
      name: "Carex brunnea 'Aureomarginata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDKL82R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDKL82R3'
    },
    {
      status: 810,
      sku: 'PM0068954',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210030',
      rtl_batch_array: '6210030',
      name_search: 'CLAHUMMI',
      name: "Clethra alnifolia 'Hummingbird'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LELV52NG;HA9RX2KC;HEHB17GC;DJK235R9',
      statusContent: 'dot',
      hash: 'DT5BVG1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT5BVG1K'
    },
    {
      status: 210,
      sku: 'PM0060144',
      core_name: 'Plant',
      sppl_ean: '8720664851863',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6132957',
      rtl_batch_array: '6132957',
      name_search: 'ANHEBUXT',
      name: "Anthemis hybrida 'E.C. Buxton'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2662,
      chnn_stock_retail: 2662,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W692W8H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W692W8H6'
    },
    {
      status: 910,
      sku: 'PM0064872',
      core_name: 'Plant',
      sppl_ean: '8720664876811',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6150464',
      rtl_batch_array: '6150464',
      name_search: 'PACAERUL',
      name: 'Passiflora caerulea',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_order_minimum: 6,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YEW1WTTX;NRVPZWNE;YC1BYG32;L3616X6A;TJX3SWET;AA13ZRNS;2RB6V6J4;D575SXJB;66NZYEE8;ZAG5S851;P1CE6NH3;J6CJ1CX1;4XTJ6WHC;46NDAPV2',
      statusContent: 'dot',
      hash: 'ZKG6CJNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKG6CJNL'
    },
    {
      status: 210,
      sku: 'PM0081186',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234619',
      rtl_batch_array: '6234619',
      name_search: 'CACWCLIP',
      name: "Campanula carpatica 'Weisse Clips'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 316,
      chnn_stock_retail: 316,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7DS7GTC7;NKBP72RK;58K3N8J7;1ZEJA8V4;ZG7RT8YA',
      statusContent: 'dot',
      hash: '45CGTC48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45CGTC48'
    },
    {
      status: 210,
      sku: 'PM0070943',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222198',
      rtl_batch_array: '6222198',
      name_search: 'PISYLVES',
      name: 'Pinus sylvestris',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 1054,
      chnn_stock_retail: 1054,
      sppl_prcp: 10.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7L3J9VNA;KX78HJLD;N732DC1A;GGJG12CS;HKS1N5EE;C1SG5VDN;2BV6TH7P',
      statusContent: 'dot',
      hash: '75VVYG6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75VVYG6V'
    },
    {
      status: 210,
      sku: 'PM0069083',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299741',
      rtl_batch_array: '6299741',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1600,
      sppl_prcp: 4.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'X6DS6H9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6DS6H9Z'
    },
    {
      status: 810,
      sku: 'PM0057226',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301646',
      rtl_batch_array: '6230999, 6301646',
      name_search: 'HEKASSAN',
      name: "Heuchera 'Kassandra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9ED3DV23',
      statusContent: 'dot',
      hash: 'Z423EVNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z423EVNH'
    },
    {
      status: 210,
      sku: 'PM0069085',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6376240',
      rtl_batch_array: '6273392, 6376240',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 8373,
      sppl_prcp: 3.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '1KKEEAJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KKEEAJD'
    },
    {
      status: 210,
      sku: 'PM0081187',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234620',
      rtl_batch_array: '6234620',
      name_search: 'CACBCLIP',
      name: "Campanula carpatica 'Blaue Clips'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBV9DCZK;PS936ENH;PR66ZWHT;NZ5C231L;6JJK223J',
      statusContent: 'dot',
      hash: 'RV9ATRT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV9ATRT7'
    },
    {
      status: 210,
      sku: 'PM0068960',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210039',
      rtl_batch_array: '6210039',
      name_search: 'WEBSNOWF',
      name: "Weigela 'Bristol Snowflake'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '99H5CJED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99H5CJED'
    },
    {
      status: 810,
      sku: 'PM0068938',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274726',
      rtl_batch_array: '6274726',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: '9NWAXYL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NWAXYL4'
    },
    {
      status: 210,
      sku: 'PM0068961',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338581',
      rtl_batch_array: '6210041, 6338581',
      name_search: 'WEFNVARI',
      name: "Weigela florida 'Nana Variegata'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 850,
      chnn_stock_retail: 950,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHYC95D1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHYC95D1'
    },
    {
      status: 210,
      sku: 'PM0060143',
      core_name: 'Plant',
      sppl_ean: '8720664850286',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117845',
      rtl_batch_array: '6117845',
      name_search: 'ACMILLEF',
      name: 'Achillea millefolium',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 572,
      chnn_stock_retail: 572,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1212VPG;V6GYCJYZ;ZRNH51HD;D3BKV6HX;G7TV1N5P;YRW1RKZJ;JVAJ17P9;6CDHRJ5V',
      statusContent: 'dot',
      hash: 'XP4DP3RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP4DP3RB'
    },
    {
      status: 210,
      sku: 'PM0068962',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300182',
      rtl_batch_array: '6300182',
      name_search: 'COALBA',
      name: 'Cornus alba',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZH452HE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZH452HE6'
    },
    {
      status: 910,
      sku: 'PM0069076',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '7S7KY9KZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7S7KY9KZ'
    },
    {
      status: 210,
      sku: 'PM0068952',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274768',
      rtl_batch_array: '6274768',
      name_search: 'LOPSEROT',
      name: "Lonicera periclymenum 'Serotina'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_prcp: 2.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJPNZYE2;ZN4CAHN1;Z2YKXPSL;16P279GS;E6LWJWGT;48YZ64JZ;75J4REZ7;R6YWKD37;WAVNK8D2',
      statusContent: 'dot',
      hash: '6YZHYSYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YZHYSYB'
    },
    {
      status: 210,
      sku: 'PM0068959',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210036',
      rtl_batch_array: '6210036',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 9.911,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'XPTRL2Y7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPTRL2Y7'
    },
    {
      status: 210,
      sku: 'PM0068966',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174823',
      rtl_batch_array: '6174823',
      name_search: 'LYSROBER',
      name: "Lythrum salicaria 'Robert'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 728,
      chnn_stock_retail: 728,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VY5LS2R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY5LS2R3'
    },
    {
      status: 210,
      sku: 'PM0077516',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304873',
      rtl_batch_array: '6304873',
      name_search: 'PIJVARIE',
      name: "Pieris japonica 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 873,
      chnn_stock_retail: 873,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XK5JHNLD',
      statusContent: 'dot',
      hash: 'DP6Y56HP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP6Y56HP'
    },
    {
      status: 210,
      sku: 'PM0045477',
      core_name: 'Plant',
      sppl_ean: '8720664853072',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6077551',
      rtl_batch_array: '6077551',
      name_search: 'ASSTRAUS',
      name: "Astilbe (T) 'Straussenfeder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1249,
      chnn_stock_retail: 1249,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ETPBG3NT;JT9RHJ5W',
      statusContent: 'dot',
      hash: 'Z5Z7XSDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5Z7XSDD'
    },
    {
      status: 210,
      sku: 'PM0077517',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304879',
      rtl_batch_array: '6304879',
      name_search: 'PRIKNMAI',
      name: "Prunus incisa 'Kojou-no-mai'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3346,
      chnn_stock_retail: 3346,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9B8SLJH;LJKEV9AE;PRLZCKB2;H674SENK;NTL778JL;XAC44826;1ZJ5JHY8;RJWAELSD;P9SAD49D;DHSLTBC4;BEB8XJ5E;T62GK6HN;KY53KRSX;RY5BV2N8;X555ZXSE;HG5X55DZ;85S5ZK5K;T4KZ886G;RY8KPRB4;LR4GA7VW',
      statusContent: 'dot',
      hash: 'CGG7Y2K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGG7Y2K2'
    },
    {
      status: 210,
      sku: 'PM0081188',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234621',
      rtl_batch_array: '6234621',
      name_search: 'CAGACAUL',
      name: "Campanula glomerata 'Acaulis'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXH6T462',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXH6T462'
    },
    {
      status: 210,
      sku: 'PM0081189',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234622',
      rtl_batch_array: '6234622',
      name_search: 'CAGSPECI',
      name: "Campanula glomerata 'Speciosa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EDREB64S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDREB64S'
    },
    {
      status: 210,
      sku: 'PM0068605',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6216969',
      rtl_batch_array: '6216969',
      name_search: 'ACMADELI',
      name: "Achillea millefolium 'Apricot Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 184,
      chnn_stock_retail: 184,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4PHLYHR;5HJ9TB7W',
      statusContent: 'dot',
      hash: '9JLJ7KVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JLJ7KVN'
    },
    {
      status: 210,
      sku: 'PM0060147',
      core_name: 'Plant',
      sppl_ean: '8720664859388',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6132964',
      rtl_batch_array: '6175110, 6132964',
      name_search: 'DEBBIRD',
      name: "Delphinium (P) 'Blue Bird'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1304,
      chnn_stock_retail: 1354,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A36HXYV9;7BD2XYH4;ZTZ1S77K;GSYHSP8X;4EKW6A9J;94BBKBEV;LT517R74;ZY8L4ES3;ZZXSEPJ2;LSRVG41Z;HK23STXR;NAN431VZ;GCR28HWJ',
      statusContent: 'dot',
      hash: '1SBBKZJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SBBKZJG'
    },
    {
      status: 210,
      sku: 'PM0045544',
      core_name: 'Plant',
      sppl_ean: '8720664858534',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147716',
      rtl_batch_array: '6147716',
      name_search: 'COESPINN',
      name: "Corydalis elata 'Spinners'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N96EASZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N96EASZV'
    },
    {
      status: 210,
      sku: 'PM0057091',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350030',
      rtl_batch_array: '6090925, 6350030',
      name_search: 'ECPCFLUF',
      name: 'Echinacea p. Cherry Fluff',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 294,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWXN4ZSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWXN4ZSJ'
    },
    {
      status: 210,
      sku: 'PM0081190',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234623',
      rtl_batch_array: '6234623',
      name_search: 'CAGSUPER',
      name: "Campanula glomerata 'Superba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 544,
      chnn_stock_retail: 544,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S46RH1BR;9AHWRPK5;GY9BJ4LY;BRYLHWB8;NCDBJG65;DLABNLV7;WK9957V1;65KEL9J6;7V2ST19C',
      statusContent: 'dot',
      hash: 'DDY3XSN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDY3XSN2'
    },
    {
      status: 210,
      sku: 'PM0081191',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234626',
      rtl_batch_array: '6234626',
      name_search: 'CAMPPALB',
      name: "Campanula persicifolia 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 307,
      chnn_stock_retail: 307,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BGLXHX8E;B61SXRC3;HXD7CVDA;2Z4LJT96;67P1AYA3;82Z5HYG8;29EDRC8W;BCY6ST18;2PTJEH17;4GNNL8KC;YRX7WRBE;SDGBPH87',
      statusContent: 'dot',
      hash: 'LYV7EREN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYV7EREN'
    },
    {
      status: 210,
      sku: 'PM0069078',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299792',
      rtl_batch_array: '6299792',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 1170,
      chnn_stock_retail: 1170,
      sppl_prcp: 2.399,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'YVD4N5XJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVD4N5XJ'
    },
    {
      status: 210,
      sku: 'PM0081192',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234627',
      rtl_batch_array: '6234627',
      name_search: 'CAPCOERU',
      name: "Campanula persicifolia 'Coerulea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJ4T3DJD;4GHSBTTK',
      statusContent: 'dot',
      hash: 'TBJJXN5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBJJXN5V'
    },
    {
      status: 210,
      sku: 'PM0084897',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369630',
      rtl_batch_array: '6369630',
      name_search: 'PRIJAPON',
      name: 'Primula japonica',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RGGWXSDE;EGEA2RDY;YETX38VV',
      statusContent: 'dot',
      hash: '72CJ323G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72CJ323G'
    },
    {
      status: 210,
      sku: 'PM0058377',
      core_name: 'Plant',
      sppl_ean: '8720664871045',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112670',
      rtl_batch_array: '6112670',
      name_search: 'KNUEROJO',
      name: "Kniphofia uvaria 'Echo Rojo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TSYKLHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TSYKLHA'
    },
    {
      status: 210,
      sku: 'PM0057260',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6371177',
      rtl_batch_array: '6091489, 6371177',
      name_search: 'IRSANGRE',
      name: "Iris (G) 'Sangreal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 187,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K76J5G3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K76J5G3T'
    },
    {
      status: 210,
      sku: 'PM0045827',
      core_name: 'Plant',
      sppl_ean: '8720664690332',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015048',
      rtl_batch_array: '6015048',
      name_search: 'FUDAVID',
      name: "Fuchsia 'David'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HNHH4YK',
      statusContent: 'dot',
      hash: '1TDZNSC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TDZNSC6'
    },
    {
      status: 210,
      sku: 'PM0084898',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369631',
      rtl_batch_array: '6369631',
      name_search: 'CORSTARL',
      name: "Coreopsis 'Starlight'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 0.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L79W8WJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L79W8WJA'
    },
    {
      status: 210,
      sku: 'PM0085071',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370797',
      rtl_batch_array: '6370797',
      name_search: 'IRSETOSA',
      name: 'Iris setosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1360,
      chnn_stock_retail: 1360,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JE3B6HWW',
      statusContent: 'dot',
      hash: '6ECR3CGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ECR3CGW'
    },
    {
      status: 210,
      sku: 'PM0085072',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370798',
      rtl_batch_array: '6370798',
      name_search: 'IRSIBIRI',
      name: 'Iris sibirica',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2484,
      chnn_stock_retail: 2484,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TTV16Y17;DK6D6335;69HA5PVW;219XTGRG;W2KK4WEK;NYBGK1RS;57RZ1R7D;TLRBPJ3X;74JGVW4S;23KCY9EV;J1P4J357;SGGT7CC6;1Z7B2AY6',
      statusContent: 'dot',
      hash: '54TBL9B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54TBL9B7'
    },
    {
      status: 810,
      sku: 'PM0068939',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274727',
      rtl_batch_array: '6274727',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: 'C5B7CD17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5B7CD17'
    },
    {
      status: 210,
      sku: 'PM0085073',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370799',
      rtl_batch_array: '6370799',
      name_search: 'IRVERSIC',
      name: 'Iris versicolor',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1450,
      chnn_stock_retail: 1450,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZV18S1AX;2P6XSZ4W;T4GKYG5Z;W5H3K7KB;A4RGBV1R',
      statusContent: 'dot',
      hash: 'HWY6B1XX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWY6B1XX'
    },
    {
      status: 210,
      sku: 'PM0060146',
      core_name: 'Plant',
      sppl_ean: '8720664853751',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6132959',
      rtl_batch_array: '6132959, 6252487',
      name_search: 'BRMEDIA',
      name: 'Briza media',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1650,
      chnn_stock_retail: 3301,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V71BEV4S;RHLKG923;5B396BZK;C2L1NTHD',
      statusContent: 'dot',
      hash: 'AZ3EN3VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ3EN3VY'
    },
    {
      status: 210,
      sku: 'PM0085074',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370800',
      rtl_batch_array: '6370800',
      name_search: 'JUEFFUSU',
      name: 'Juncus effusus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1768,
      chnn_stock_retail: 1768,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6JYZBCA4;YRKGPRPA;GR1BKBCT;YELBYZZL;KSZDYWJ4;4KPBDRTN;PKK2D51X',
      statusContent: 'dot',
      hash: 'LX4EE75T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LX4EE75T'
    },
    {
      status: 910,
      sku: 'PM0085075',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MUCAPILL',
      name: 'Muhlenbergia capillaris',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore: 'CXGXBXG8;TD6C4NGA',
      statusContent: 'dot',
      hash: 'HDGSNG1K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HDGSNG1K'
    },
    {
      status: 210,
      sku: 'PM0085076',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370804',
      rtl_batch_array: '6370804',
      name_search: 'CIRBRUNE',
      name: "Cimicifuga ramosa 'Brunette'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJLGXZNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJLGXZNS'
    },
    {
      status: 210,
      sku: 'PM0085077',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370807',
      rtl_batch_array: '6370807',
      name_search: 'HYDMSIBI',
      name: "Hydrangea macrophylla 'Sibilla'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 907,
      chnn_stock_retail: 907,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '899XL4ZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '899XL4ZB'
    },
    {
      status: 210,
      sku: 'PM0085078',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370809',
      rtl_batch_array: '6370809',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '1H1H86YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H1H86YT'
    },
    {
      status: 210,
      sku: 'PM0058091',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184078',
      rtl_batch_array: '6184078',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'J4S2546D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4S2546D'
    },
    {
      status: 210,
      sku: 'PM0058077',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6192618',
      rtl_batch_array: '6192618, 6291705',
      name_search: 'ASANNEKE',
      name: "Aster (D) 'Anneke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 951,
      chnn_stock_retail: 1519,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T9V3J9AN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9V3J9AN'
    },
    {
      status: 210,
      sku: 'PM0085079',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370810',
      rtl_batch_array: '6370810',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'VCV7TX8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCV7TX8C'
    },
    {
      status: 810,
      sku: 'PM0057262',
      core_name: 'Plant',
      sppl_ean: '8720664869936',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091491',
      rtl_batch_array: '6091491',
      name_search: 'IRSUPERS',
      name: "Iris (G) 'Superstition'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V2CWNYCR;CAZWP478',
      statusContent: 'dot',
      hash: '63TX9ZSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63TX9ZSX'
    },
    {
      status: 210,
      sku: 'PM0085080',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370812',
      rtl_batch_array: '6370812',
      name_search: 'ABGFMASO',
      name: "Abelia grandiflora 'Francis Mason'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JNVB76SS;V4BC2T9D;X8KYN839;HZ31P18E',
      statusContent: 'dot',
      hash: 'REDT3HYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REDT3HYJ'
    },
    {
      status: 210,
      sku: 'PM0085081',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370814',
      rtl_batch_array: '6370814',
      name_search: 'DRACRISP',
      name: "Dryopteris affinis 'Crispa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2040,
      chnn_stock_retail: 2040,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5RW1LRSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RW1LRSW'
    },
    {
      status: 210,
      sku: 'PM0045832',
      core_name: 'Plant',
      sppl_ean: '8720664863903',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6107074',
      rtl_batch_array: '6350176, 6107074, 6348320',
      name_search: 'GAKOBOLD',
      name: "Gaillardia 'Kobold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6627,
      chnn_stock_retail: 8948,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NABW2JS9;EPX37XYC;88DYYJND;7JS5CELT;RWL19N3H;1A6DBN4J;R37WXGWY;BZZJZVK5;ADJ9XR8S',
      statusContent: 'dot',
      hash: '9A1DELA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9A1DELA7'
    },
    {
      status: 210,
      sku: 'PM0058131',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267719',
      rtl_batch_array: '6267719',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'CECZJLL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CECZJLL8'
    },
    {
      status: 210,
      sku: 'PM0068833',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209147',
      rtl_batch_array: '6209147',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 8.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '13KKWHJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13KKWHJ4'
    },
    {
      status: 210,
      sku: 'PM0068830',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209142',
      rtl_batch_array: '6209142',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C34',
      rng_range_identifier: 'H100125C3.5',
      rng_range_description: 'H100 cm 125 cm C3.5',
      sppl_stock_available: 2278,
      chnn_stock_retail: 2278,
      sppl_prcp: 3.796,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'WJ9EYE4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJ9EYE4N'
    },
    {
      status: 210,
      sku: 'PM0068831',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209145',
      rtl_batch_array: '6209145',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 8.087,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'A7BJX4RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7BJX4RW'
    },
    {
      status: 810,
      sku: 'PM0068834',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370587',
      rtl_batch_array: '6209148, 6370587',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 2550,
      sppl_prcp: 7.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'BWKCAP16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWKCAP16'
    },
    {
      status: 210,
      sku: 'PM0057969',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220893',
      rtl_batch_array: '6220893',
      name_search: 'PRPUBESC',
      name: 'Primula pubescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TY3DY1NS;KVXJWB1C',
      statusContent: 'dot',
      hash: 'ET74JP36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET74JP36'
    },
    {
      status: 910,
      sku: 'PM0060095',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307760',
      rtl_batch_array: '6307760',
      name_search: 'PIPNEGIS',
      name: "Pinus parviflora 'Negishi'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 21.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYJPKZV2;2NP81299;SA4VVH8E;H52BXA65',
      statusContent: 'dot',
      hash: 'HTW3CZVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTW3CZVS'
    },
    {
      status: 210,
      sku: 'PM0069986',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193692',
      rtl_batch_array: '6193692',
      name_search: 'EDI73427',
      name: 'Tulbaghia violacae violacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SKZYVVDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKZYVVDB'
    },
    {
      status: 210,
      sku: 'PM0085082',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371033',
      rtl_batch_array: '6371033',
      name_search: 'HEGQUEEN',
      name: "Helianthemum 'Golden Queen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1964,
      chnn_stock_retail: 1964,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEK5XSJL;YG5ZATCZ;4NW864P1;A8S8GJ84;XB9RA5T6;AZ1C1RL5;BK55X3HV;BH53YGEZ',
      statusContent: 'dot',
      hash: 'DY9A83P7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY9A83P7'
    },
    {
      status: 210,
      sku: 'PM0068839',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 3,
      btch_active_retail: '6356672',
      rtl_batch_array: '6211956, 6333580, 6356672',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 460,
      chnn_stock_retail: 3897,
      sppl_prcp: 3.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'XYZ2DXNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYZ2DXNJ'
    },
    {
      status: 210,
      sku: 'PM0068540',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205981',
      rtl_batch_array: '6205981',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: 'CDC2516W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDC2516W'
    },
    {
      status: 210,
      sku: 'PM0081193',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339000',
      rtl_batch_array: '6339000',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: '125150C2',
      rng_range_identifier: 'H125150C2',
      rng_range_description: 'H125 cm 150 cm C2',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 2.817,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: '49E5X626',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49E5X626'
    },
    {
      status: 210,
      sku: 'PM0077527',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304891',
      rtl_batch_array: '6304891',
      name_search: 'RHMOERHE',
      name: "Rhododendron 'Moerheim'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1456,
      chnn_stock_retail: 1456,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7TDDSGT2;EVHACD71;DHBWVA8Y;X973ZWB4;RTSRWE41;EGCG9617;WSL7APVW;Z64TD315;H9P3TNRD;668VS6VZ;2H17H71K;AS159YEZ;6587ZGCL;D27H3PEG;GLE7YR3P',
      statusContent: 'dot',
      hash: '62YCS2GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62YCS2GD'
    },
    {
      status: 210,
      sku: 'PM0077528',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304892',
      rtl_batch_array: '6304892',
      name_search: 'RHSHAMRO',
      name: "Rhododendron 'Shamrock'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1049,
      chnn_stock_retail: 1049,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BK52SNXB;GJ79DS63;NPXEL62N;J1LD9N89;4ED8ESDS;YNS53ALW;57A28CJK;8AV9E4SZ;ZAA7ZK3C;NP1JS37A;YKDHRGAW;ABX7WJ21;WBHG111D',
      statusContent: 'dot',
      hash: 'HBVNB9HS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBVNB9HS'
    },
    {
      status: 210,
      sku: 'PM0077529',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304893',
      rtl_batch_array: '6304893',
      name_search: 'RHSNIPE',
      name: "Rhododendron 'Snipe'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 564,
      chnn_stock_retail: 564,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C5SR2PJL;JE3CZSRS;XNX9P7VP;ARJDCBDV;SJVNVPHK;VRRA4JAY;28V19BSJ;TWRXJWL3;NL5XCRBS;4726E1HZ;6JTA2ZP7;SCXWDJ1E',
      statusContent: 'dot',
      hash: 'N7LYNADP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7LYNADP'
    },
    {
      status: 810,
      sku: 'PM0081194',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339006',
      rtl_batch_array: '6339006',
      name_search: 'ASNAPDOM',
      name: "Aster novae-angliae 'Purple Dome'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7Z4SBCS;DR8AV5DN;11KTR8YZ;L4ZD9XX5;TCEV9KKA;N4KLGN9J;23CATXL3;VL2PN92C;2S8B425H;24BBLEWE',
      statusContent: 'dot',
      hash: 'LWG7XEXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWG7XEXZ'
    },
    {
      status: 810,
      sku: 'PM0077531',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304895',
      rtl_batch_array: '6304895',
      name_search: 'RUIBYUMM',
      name: 'Rubus idaeus Bonbonberry Yummy',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J44JXKWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J44JXKWX'
    },
    {
      status: 210,
      sku: 'PM0081195',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339007',
      rtl_batch_array: '6339007',
      name_search: 'COVMOONB',
      name: "Coreopsis verticillata 'Moonbeam'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KRVEBRG4;RY4RYHPK;2T2Z98KY;HDSTL4WP;77VR7AD6;939C72DP;XZB2LXGS',
      statusContent: 'dot',
      hash: '6J2G2LEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J2G2LEN'
    },
    {
      status: 210,
      sku: 'PM0077532',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304896',
      rtl_batch_array: '6304896',
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2642,
      chnn_stock_retail: 2642,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9251DGD;4AK589AB;GT6C1BJL;77RP3J8Y;4ZPJNXK5;RHJJ77R5;1E1112G6;A3KPK1CH;DCNZEK7T;6CZ7HE6H;5EEDE6GG;A535J46V;VERWST57;13ZDCST5;8S57K3RX;TPNXG1TC;LS2CKYLV;REENVR6J',
      statusContent: 'dot',
      hash: 'W4NV6YES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4NV6YES'
    },
    {
      status: 210,
      sku: 'PM0060149',
      core_name: 'Plant',
      sppl_ean: '8720664859500',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6132970',
      rtl_batch_array: '6346709, 6132970, 6252529',
      name_search: 'DECGOLDT',
      name: "Deschampsia cespitosa 'Goldtau'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 10386,
      chnn_stock_retail: 12265,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7ZNEJDA;S4JYADT8',
      statusContent: 'dot',
      hash: '6BYA21VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BYA21VY'
    },
    {
      status: 210,
      sku: 'PM0081196',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339009',
      rtl_batch_array: '6339009',
      name_search: 'DIDSPLEN',
      name: "Dianthus deltoides 'Splendens'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRHJ27S5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRHJ27S5'
    },
    {
      status: 910,
      sku: 'PM0069976',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MIRINGEN',
      name: 'Mimulus ringens',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'AGT6YHDK;T9B24J6J;KN25WBAB;LHNSCXCE;K27K92R6;PSN94Y5W;5VE88TS5',
      statusContent: 'dot',
      hash: 'SNPT911H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SNPT911H'
    },
    {
      status: 210,
      sku: 'PM0045536',
      core_name: 'Plant',
      sppl_ean: '8720664685437',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014779',
      rtl_batch_array: '6014779',
      name_search: 'COLBUG',
      name: "Coreopsis 'Lightning Bug'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JEJBXZZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEJBXZZZ'
    },
    {
      status: 210,
      sku: 'PM0063502',
      core_name: 'Plant',
      sppl_ean: '8720664883192',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099398',
      rtl_batch_array: '6099398',
      name_search: 'RHMORGEN',
      name: "Rhododendron (Y) 'Morgenrot'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWRP545G;NLN92A91;6PNZ9PY7;KLPYNL5P;D7HBL865;RH7TGVWY;A68SVN5T;PW1S9SHX;Z5A7SZEW',
      statusContent: 'dot',
      hash: '4EJSAX88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EJSAX88'
    },
    {
      status: 210,
      sku: 'PM0077536',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304901',
      rtl_batch_array: '6304901',
      name_search: 'SKJRUBES',
      name: 'Skimmia japonica Rubesta',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4182,
      chnn_stock_retail: 4182,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1CLXL4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1CLXL4P'
    },
    {
      status: 210,
      sku: 'PM0069982',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193671',
      rtl_batch_array: '6193671',
      name_search: 'MECALBA',
      name: "Mentha cervina 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J28B92AL;8TDLGV74;HH9KVVS4;5KRPDWZN;9NDEXB51;ALLC55H6;4S57ZLEK;HDC1TTVB;PZ855GTX;AH75KYL8',
      statusContent: 'dot',
      hash: 'K9NHZEKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9NHZEKL'
    },
    {
      status: 210,
      sku: 'PM0077537',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304902',
      rtl_batch_array: '6304902',
      name_search: 'SKJRGPIN',
      name: 'Skimmia japonica Rubesta Giant Pink',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1709,
      chnn_stock_retail: 1709,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VXRKHAR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXRKHAR2'
    },
    {
      status: 210,
      sku: 'PM0085083',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371035',
      rtl_batch_array: '6371035',
      name_search: 'OPPLANIS',
      name: 'Ophiopogon planiscapus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B2A9RS5T;XEV37LSJ;G1EKGKHP;JEHH771T;1AHBL1R3;EGTPEE2V;WXKW1BG7;VYP62YB6;4X5C2J3Y',
      statusContent: 'dot',
      hash: 'C81H32G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C81H32G5'
    },
    {
      status: 210,
      sku: 'PM0077538',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304903',
      rtl_batch_array: '6304903',
      name_search: 'SKJRJOS',
      name: 'Skimmia japonica Rubesta Jos',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2298,
      chnn_stock_retail: 2298,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JAT5C76R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAT5C76R'
    },
    {
      status: 210,
      sku: 'PM0045183',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6233451',
      rtl_batch_array: '5592153, 6233451',
      name_search: 'ECPALLID',
      name: 'Echinacea pallida',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6665,
      chnn_stock_retail: 7665,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZWH8WSY9;XGB5ZJYB;CZN9VC1Y;KX7W866N;Y6LN3ZDW;YBYCG21Z',
      statusContent: 'dot',
      hash: 'HWH27STT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWH27STT'
    },
    {
      status: 210,
      sku: 'PM0081198',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339011',
      rtl_batch_array: '6339011',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1602,
      chnn_stock_retail: 1602,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'CYWLZ8N3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYWLZ8N3'
    },
    {
      status: 210,
      sku: 'PM0057272',
      core_name: 'Plant',
      sppl_ean: '8720664871298',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091539',
      rtl_batch_array: '6091539',
      name_search: 'LAMCHEQU',
      name: "Lamium maculatum 'Chequers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '23AV878X;DKE7KBHC;K7WNW83V;5XC15WC6;WYAYKVRW;DVGREBAV',
      statusContent: 'dot',
      hash: '56RLLHPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56RLLHPW'
    },
    {
      status: 210,
      sku: 'PM0045172',
      core_name: 'Plant',
      sppl_ean: '8720626391994',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592130',
      rtl_batch_array: '5592130',
      name_search: 'ASLVPINK',
      name: "Astilbe 'Little Vision in Pink'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'KV5YCJT7',
      statusContent: 'dot',
      hash: 'A4HK7C3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4HK7C3H'
    },
    {
      status: 210,
      sku: 'PM0077539',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304904',
      rtl_batch_array: '6304904',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2526,
      chnn_stock_retail: 2526,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: '6EL7K67Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EL7K67Z'
    },
    {
      status: 210,
      sku: 'PM0077668',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305855',
      rtl_batch_array: '6305855',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'RWJWBLKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWJWBLKH'
    },
    {
      status: 910,
      sku: 'PM0045685',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6175973',
      rtl_batch_array: '6196750, 6181384, 6349970, 6164393, 6175973',
      name_search: 'CRLUCIFE',
      name: "Crocosmia 'Lucifer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1,
      chnn_stock_retail: 11878,
      sppl_order_minimum: 3,
      sppl_prcp: 0.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '16DN948R;2ZP5X5CE;Z1RVPG1Y;H2YAA3WG;K4WH8SY1;HY4GS1SR;5C4CPT29',
      statusContent: 'dot',
      hash: 'SYCN3EYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYCN3EYY'
    },
    {
      status: 210,
      sku: 'PM0081199',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339012',
      rtl_batch_array: '6339012',
      name_search: 'ROPINNAT',
      name: 'Rodgersia pinnata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3226,
      chnn_stock_retail: 3226,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9PVA5J9;56YXT1TA;26HNV5KA;996VNZ53;4HXCRPX4;T2YNWSPY;LSN79VYZ;PZN9ERJ8;JG39XVWW',
      statusContent: 'dot',
      hash: '6KR9AK8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KR9AK8P'
    },
    {
      status: 210,
      sku: 'PM0085084',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371037',
      rtl_batch_array: '6371037',
      name_search: 'PHSSOCEA',
      name: 'Phlox (P) Sweet Summer Ocean',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9328,
      chnn_stock_retail: 9328,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NLZ18B72;6L89CXTN',
      statusContent: 'dot',
      hash: '9WL8T8ZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WL8T8ZT'
    },
    {
      status: 210,
      sku: 'PM0077533',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304897',
      rtl_batch_array: '6304897',
      name_search: 'SAHPGEM',
      name: 'Sarcococca h. Purple Gem',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1398,
      chnn_stock_retail: 1398,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5E9DP9WE;A3B2YTYH;VCP629VD;ZGSCWYZB;K8YSG6PB',
      statusContent: 'dot',
      hash: 'CNT5ZS4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNT5ZS4X'
    },
    {
      status: 210,
      sku: 'PM0045966',
      core_name: 'Plant',
      sppl_ean: '8720664549593',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015317',
      rtl_batch_array: '6015317',
      name_search: 'HOBEDGER',
      name: "Hosta 'Bold Edger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2GHK2G1;XD55PLE5;8HEXBWNN;P2S7V75B;WJKRB5WX',
      statusContent: 'dot',
      hash: 'JAP787E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAP787E4'
    },
    {
      status: 210,
      sku: 'PM0045920',
      core_name: 'Plant',
      sppl_ean: '8720664541634',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015170',
      rtl_batch_array: '6015170',
      name_search: 'GYJOLIEN',
      name: "Gypsophila 'Jolien'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 259,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9ZHVSWTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZHVSWTJ'
    },
    {
      status: 210,
      sku: 'PM0077534',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304898',
      rtl_batch_array: '6304898',
      name_search: 'SAHPSTEM',
      name: "Sarcococca hookeriana 'Purple Stem'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4001,
      chnn_stock_retail: 4001,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TYDJ9AHA;22CTEEXG;77EP83CD;6CCRGJCC',
      statusContent: 'dot',
      hash: '5LC6XTJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LC6XTJ2'
    },
    {
      status: 210,
      sku: 'PM0081201',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339014',
      rtl_batch_array: '6339014',
      name_search: 'SEAUTUMN',
      name: 'Sesleria autumnalis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HLPRP1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HLPRP1P'
    },
    {
      status: 210,
      sku: 'PM0081202',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367972',
      rtl_batch_array: '6367972',
      name_search: 'BUDBTOWE',
      name: 'Buddleja davidii Butterfly Tower',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 701,
      chnn_stock_retail: 701,
      sppl_prcp: 3.843,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9ABKZR6;EZSGR297;VXSHTZNK;KXR16RH7;ZSZC45W4;S57Z36AG',
      statusContent: 'dot',
      hash: 'NKSC6LAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKSC6LAA'
    },
    {
      status: 210,
      sku: 'PM0077535',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304900',
      rtl_batch_array: '6304900',
      name_search: 'SKJFINCH',
      name: "Skimmia japonica 'Finchy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2370,
      chnn_stock_retail: 2370,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KAW412EE;DHTGKR8E;T4J17KR3;11LYS298;2RRR3349;AAYCJPHG;2GYB73AT;L4NHR2YK',
      statusContent: 'dot',
      hash: 'TEK2CEN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEK2CEN1'
    },
    {
      status: 210,
      sku: 'PM0081203',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317999',
      rtl_batch_array: '6317999',
      name_search: 'CODMIRAN',
      name: "Cotoneaster dammeri 'Miranda'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7350,
      chnn_stock_retail: 7350,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LA6RGXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LA6RGXV'
    },
    {
      status: 810,
      sku: 'PM0068836',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370589',
      rtl_batch_array: '6209150, 6370589',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 796,
      sppl_prcp: 9.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'N6919TZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6919TZY'
    },
    {
      status: 210,
      sku: 'PM0085085',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371038',
      rtl_batch_array: '6371038',
      name_search: 'PHSSSNOW',
      name: 'Phlox (P) Sweet Summer Snow',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2920,
      chnn_stock_retail: 2920,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '26X8EPC4;7X371879;6RBPJWVA',
      statusContent: 'dot',
      hash: 'A4W2GBN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4W2GBN8'
    },
    {
      status: 810,
      sku: 'PM0063492',
      core_name: 'Plant',
      sppl_ean: '8720664870871',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142269',
      rtl_batch_array: '6142269',
      name_search: 'KALNANI',
      name: "Kalmia latifolia 'Nani'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJZ14WSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJZ14WSA'
    },
    {
      status: 210,
      sku: 'PM0048759',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184080',
      rtl_batch_array: '6184080',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'S2BW18SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2BW18SW'
    },
    {
      status: 210,
      sku: 'PM0069979',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353150',
      rtl_batch_array: '6353150, 6291733',
      name_search: 'PEBISTOR',
      name: 'Persicaria bistorta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1816,
      chnn_stock_retail: 3911,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ED5WP5R9;LH3X3YWV;E9D53TLJ;V4R1WD9R;N3523AWL',
      statusContent: 'dot',
      hash: 'WYL6KWGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYL6KWGS'
    },
    {
      status: 810,
      sku: 'PM0070945',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151143',
      rtl_batch_array: '6151143',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 9.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'TGDE8PB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGDE8PB1'
    },
    {
      status: 210,
      sku: 'PM0058064',
      core_name: 'Plant',
      sppl_ean: '8720664851191',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6085001',
      rtl_batch_array: '6085001',
      name_search: 'ALRLEMON',
      name: "Alcea rosea 'Lemon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1005,
      chnn_stock_retail: 1005,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1L3VN2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1L3VN2R'
    },
    {
      status: 910,
      sku: 'PM0074219',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASCANADE',
      name: 'Asarum canadense',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'ZD7WTRVR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZD7WTRVR'
    },
    {
      status: 810,
      sku: 'PM0045460',
      core_name: 'Plant',
      sppl_ean: '8720664852884',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6077485',
      rtl_batch_array: '6077485',
      name_search: 'ASSCHREB',
      name: 'Aster schreberi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VY2EZBLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY2EZBLJ'
    },
    {
      status: 210,
      sku: 'PM0057108',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187564',
      rtl_batch_array: '6187564',
      name_search: 'ECSYELLO',
      name: 'Echinacea Sunseekers Yellow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 127,
      chnn_stock_retail: 127,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G1NP832S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1NP832S'
    },
    {
      status: 910,
      sku: 'PM0081204',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: 'CN5RVT9N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CN5RVT9N'
    },
    {
      status: 210,
      sku: 'PM0081205',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338964',
      rtl_batch_array: '6338964',
      name_search: 'AJREPTAN',
      name: 'Ajuga reptans',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1677,
      chnn_stock_retail: 1677,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9TYY7NSC;6HNAC18P;TNHTW3RR;G2K2S9B3;2PN6WTKB;JWZZJZWY;7XZSTCJ9;52XE24L9;VESGJRR7;G7SWKYAA;E6546H5T;25JATYLH;1REAGRVZ;X655RS1W;VKPNLCDC;725JXKH4;LTZZHZV5;XEYXYGZ6;ET1T1235;NAJC85VE;94T4R859',
      statusContent: 'dot',
      hash: '5YLGD232',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YLGD232'
    },
    {
      status: 210,
      sku: 'PM0081206',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338965',
      rtl_batch_array: '6338965',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3386,
      chnn_stock_retail: 3386,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: 'S8N1PZJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8N1PZJS'
    },
    {
      status: 210,
      sku: 'PM0045952',
      core_name: 'Plant',
      sppl_ean: '8720664866201',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161727',
      rtl_batch_array: '6161727',
      name_search: 'HESOMBRE',
      name: "Helenium 'Sombrero'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RC8N6EE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC8N6EE2'
    },
    {
      status: 910,
      sku: 'PM0057301',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LUNMAIDE',
      name: "Lupinus 'Noble Maiden'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'E6PDYS87;935YDA1T',
      statusContent: 'dot',
      hash: 'T46ZS1YA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T46ZS1YA'
    },
    {
      status: 210,
      sku: 'PM0081207',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338966',
      rtl_batch_array: '6338966',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3146,
      chnn_stock_retail: 3146,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      statusContent: 'dot',
      hash: 'RG59WVJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG59WVJB'
    },
    {
      status: 210,
      sku: 'PM0057206',
      core_name: 'Plant',
      sppl_ean: '8720664866591',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091296',
      rtl_batch_array: '6091296',
      name_search: 'HECJOHNS',
      name: "Hemerocallis 'Charles Johnston'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EB7RJ9JX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB7RJ9JX'
    },
    {
      status: 210,
      sku: 'PM0081208',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338967',
      rtl_batch_array: '6338967',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3455,
      chnn_stock_retail: 3455,
      sppl_order_minimum: 3,
      sppl_prcp: 1.336,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      statusContent: 'dot',
      hash: 'XDB5XCDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDB5XCDC'
    },
    {
      status: 210,
      sku: 'PM0045968',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301657',
      rtl_batch_array: '6112443, 6301657',
      name_search: 'HOBCUP',
      name: "Hosta 'Brim Cup'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 222,
      chnn_stock_retail: 298,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T2LEY3DJ;J9E41DWE;6G3ECZVP;L1A7G7WZ;EETT6RBV;1LYTA6Y3;BV78LNDX;1TZPEYP5;XJDDZLSK;A9PKNVJL;L3BK3HJW;DGCVH5GS;WN51Y8EA;LAG56458;8PW68GYZ',
      statusContent: 'dot',
      hash: 'HHV1255H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHV1255H'
    },
    {
      status: 210,
      sku: 'PM0060153',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 5,
      btch_active_retail: '6382304',
      rtl_batch_array: '5364374, 6284536, 6350746, 6274852, 6382304',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 250,
      chnn_stock_retail: 6473,
      sppl_order_minimum: 3,
      sppl_prcp: 0.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'J6S3J22B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6S3J22B'
    },
    {
      status: 910,
      sku: 'PM0058434',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PENBTAFF',
      name: "Penstemon 'Boysenberry Taffy'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CVB49SLY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CVB49SLY'
    },
    {
      status: 210,
      sku: 'PM0081209',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338969',
      rtl_batch_array: '6338969',
      name_search: 'COAVARIE',
      name: "Cotoneaster atropurpureus 'Variegatus'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42GXGL1X',
      statusContent: 'dot',
      hash: 'VXEXPZRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXEXPZRW'
    },
    {
      status: 910,
      sku: 'PM0058117',
      core_name: 'Plant',
      sppl_ean: '8720626367265',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '5998699',
      rtl_batch_array: '5998699, 6307647',
      name_search: 'RHSCANDE',
      name: 'Rhodotypos scandens',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 8,
      chnn_stock_retail: 505,
      sppl_prcp: 6.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5KXESB3',
      statusContent: 'dot',
      hash: '71DPK7LG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71DPK7LG'
    },
    {
      status: 210,
      sku: 'PM0057205',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324694',
      rtl_batch_array: '6091294, 6208009, 6324694',
      name_search: 'HEBONANZ',
      name: "Hemerocallis 'Bonanza'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4700,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CSAWDCLZ',
      statusContent: 'dot',
      hash: 'L59W2SZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L59W2SZ6'
    },
    {
      status: 210,
      sku: 'PM0081210',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338970',
      rtl_batch_array: '6338970',
      name_search: 'CODMOONC',
      name: "Cotoneaster dammeri 'Mooncreeper'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5244,
      chnn_stock_retail: 5244,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GPJGSS9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPJGSS9K'
    },
    {
      status: 210,
      sku: 'PM0081211',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338972',
      rtl_batch_array: '6338972',
      name_search: 'COLUCIDU',
      name: 'Cotoneaster lucidus',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1670,
      chnn_stock_retail: 1670,
      sppl_order_minimum: 3,
      sppl_prcp: 0.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPJW8JTN',
      statusContent: 'dot',
      hash: 'YVR73HHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVR73HHG'
    },
    {
      status: 210,
      sku: 'PM0081212',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338974',
      rtl_batch_array: '6338974',
      name_search: 'COMTANJA',
      name: "Cotoneaster microphyllus 'Tanja'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2100,
      chnn_stock_retail: 2100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84S9ZXZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84S9ZXZG'
    },
    {
      status: 210,
      sku: 'PM0081213',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338975',
      rtl_batch_array: '6338975',
      name_search: 'CONBOER',
      name: "Cotoneaster nanshan 'Boer'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 327,
      chnn_stock_retail: 327,
      sppl_order_minimum: 3,
      sppl_prcp: 0.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1CNV9ZKJ',
      statusContent: 'dot',
      hash: 'D2YL169A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2YL169A'
    },
    {
      status: 210,
      sku: 'PM0045222',
      core_name: 'Plant',
      sppl_ean: '8720664546332',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592249',
      rtl_batch_array: '5592249',
      name_search: 'HEOMEYES',
      name: "Hemerocallis 'Open My Eyes'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'LJJ968HE',
      statusContent: 'dot',
      hash: 'Z15NZ9HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z15NZ9HA'
    },
    {
      status: 210,
      sku: 'PM0081214',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338979',
      rtl_batch_array: '6338979',
      name_search: 'CORRAMI',
      name: "Cotoneaster radicans 'Rami'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4710,
      chnn_stock_retail: 4710,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HHE7YLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HHE7YLN'
    },
    {
      status: 210,
      sku: 'PM0081215',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338980',
      rtl_batch_array: '6338980',
      name_search: 'COSGNOM',
      name: "Cotoneaster salicifolius 'Gnom'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1270,
      chnn_stock_retail: 1270,
      sppl_order_minimum: 3,
      sppl_prcp: 0.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HS917GY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HS917GY'
    },
    {
      status: 210,
      sku: 'PM0081216',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338981',
      rtl_batch_array: '6338981',
      name_search: 'COSGCARP',
      name: "Cotoneaster salicifolius 'Green Carpet'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8545,
      chnn_stock_retail: 8545,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ESK84K8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESK84K8P'
    },
    {
      status: 210,
      sku: 'PM0081217',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338982',
      rtl_batch_array: '6338982',
      name_search: 'COSHERBS',
      name: "Cotoneaster salicifolius 'Herbstfeuer'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_order_minimum: 3,
      sppl_prcp: 0.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J438L5PR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J438L5PR'
    },
    {
      status: 210,
      sku: 'PM0081218',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338983',
      rtl_batch_array: '6338983',
      name_search: 'COSPARKT',
      name: "Cotoneaster salicifolius 'Parkteppich'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1270,
      chnn_stock_retail: 1270,
      sppl_order_minimum: 3,
      sppl_prcp: 0.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9HPDKDTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HPDKDTB'
    },
    {
      status: 210,
      sku: 'PM0081219',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338984',
      rtl_batch_array: '6338984',
      name_search: 'FUMCORNE',
      name: "Fuchsia 'Madame Cornelissen'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 890,
      chnn_stock_retail: 890,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7V1GVRRN;VW4HZ6JG;NDHD7Y8X;H1RB9BTV;BJE6PN6C;79APZY56;ERKSB6PT;PSXVLKBJ',
      statusContent: 'dot',
      hash: 'D1AEJ3R4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1AEJ3R4'
    },
    {
      status: 210,
      sku: 'PM0081220',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338985',
      rtl_batch_array: '6338985',
      name_search: 'FUMTRICO',
      name: "Fuchsia magellanica 'Tricolor'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 898,
      chnn_stock_retail: 898,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1YGE3EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1YGE3EZ'
    },
    {
      status: 210,
      sku: 'PM0081221',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338986',
      rtl_batch_array: '6338986',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 893,
      chnn_stock_retail: 893,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: 'E8J438VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8J438VT'
    },
    {
      status: 210,
      sku: 'PM0066380',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269807',
      rtl_batch_array: '6269807, 6310241, 6338971',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7414,
      chnn_stock_retail: 23114,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'ZV1828SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV1828SE'
    },
    {
      status: 210,
      sku: 'PM0081222',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338987',
      rtl_batch_array: '6338987',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1708,
      chnn_stock_retail: 1708,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: 'B16TVA54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B16TVA54'
    },
    {
      status: 210,
      sku: 'PM0069999',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 6,
      btch_active_retail: '6254745',
      rtl_batch_array: '6345273, 6210027, 6254745, 6305523, 4673008, 6300867',
      name_search: 'HAMACRA',
      name: 'Hakonechloa macra',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1765,
      chnn_stock_retail: 16796,
      sppl_prcp: 2.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKX1PER7;26R9HE2P',
      statusContent: 'dot',
      hash: 'J62T8D9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J62T8D9J'
    },
    {
      status: 210,
      sku: 'PM0081223',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338988',
      rtl_batch_array: '6338988',
      name_search: 'GELYDIA',
      name: 'Genista lydia',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E1Y6EK5D;9XA5PG72;RJRHXYXZ;G6LBWK4V;8C94837E;6X3D2HRK',
      statusContent: 'dot',
      hash: 'C29DGL6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C29DGL6G'
    },
    {
      status: 210,
      sku: 'PM0081224',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338989',
      rtl_batch_array: '6338989',
      name_search: 'HYANDROS',
      name: 'Hypericum androsaemum',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1923,
      chnn_stock_retail: 1923,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WNHWLXG',
      statusContent: 'dot',
      hash: 'YXLW838G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXLW838G'
    },
    {
      status: 910,
      sku: 'PM0045237',
      core_name: 'Plant',
      sppl_ean: '8720626324848',
      btch_manufacturer: 295,
      rtl_batch_array_total: 2,
      btch_active_retail: '5683003',
      rtl_batch_array: '5683003, 6298788',
      name_search: 'SANHASCH',
      name: "Sambucus nigra 'Haschberg'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 2533,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NG59WL4G',
      statusContent: 'dot',
      hash: '5TJA8KNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TJA8KNX'
    },
    {
      status: 210,
      sku: 'PM0081225',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338992',
      rtl_batch_array: '6300868, 6338992',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2915,
      chnn_stock_retail: 3211,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'LH1A7BY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH1A7BY2'
    },
    {
      status: 910,
      sku: 'PM0081226',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'LER1R7SC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LER1R7SC'
    },
    {
      status: 210,
      sku: 'PM0081227',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338995',
      rtl_batch_array: '6338995',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'GV7XCRX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GV7XCRX2'
    },
    {
      status: 210,
      sku: 'PM0081228',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338996',
      rtl_batch_array: '6338996',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1346,
      chnn_stock_retail: 1346,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      statusContent: 'dot',
      hash: 'TH7EZP7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH7EZP7R'
    },
    {
      status: 210,
      sku: 'PM0069993',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837973',
      rtl_batch_array: '5837973',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '648Z539S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '648Z539S'
    },
    {
      status: 910,
      sku: 'PM0081229',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'PEANJVAY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PEANJVAY'
    },
    {
      status: 210,
      sku: 'PM0081230',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338998',
      rtl_batch_array: '6338998',
      name_search: 'PAVHMETA',
      name: "Panicum virgatum 'Heavy Metal'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2383,
      chnn_stock_retail: 2383,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V5XSXSYX;C8S1WKJ1;BTG42V3L;4RPES78Z;97Z52BYT;CWPCSTAN;B27L9XH1;E71GYABT;NGTG2JP9;9YE7Z337;R1NWHNC8;K9DC6BKX;XENCB779;LT5N1A2T;H8ABNY7W;1E6ZHVGW;YAD39A58;XKLGYA4D;VCKC5T7W;XXBCD7VY;1P73SDWB;WRKX9E81;BH52Y7WX;6L6ASRXS;2D21YBST;JHS4125Z;8S2SCRWD',
      statusContent: 'dot',
      hash: 'ZTAT1J2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTAT1J2X'
    },
    {
      status: 210,
      sku: 'PM0069996',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5932534',
      rtl_batch_array: '5932534',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100125C44',
      rng_range_identifier: 'H100125C4.5',
      rng_range_description: 'H100 cm 125 cm C4.5',
      sppl_stock_available: 1121,
      chnn_stock_retail: 1121,
      sppl_prcp: 4.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'NVBH63ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVBH63ZH'
    },
    {
      status: 210,
      sku: 'PM0081231',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5932359',
      rtl_batch_array: '5932359',
      name_search: 'COCANADE',
      name: 'Cornus canadensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6447,
      chnn_stock_retail: 6447,
      sppl_prcp: 2.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DA1NWV7N;P9NKX3RS',
      statusContent: 'dot',
      hash: 'ZD3G1AG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD3G1AG8'
    },
    {
      status: 210,
      sku: 'PM0081232',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339713',
      rtl_batch_array: '6339713',
      name_search: 'ACMNVRED',
      name: 'Achillea millefolium New Vintage Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N66H86RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N66H86RC'
    },
    {
      status: 210,
      sku: 'PM0081233',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339714',
      rtl_batch_array: '6339714',
      name_search: 'ACMNVROS',
      name: 'Achillea millefolium New Vintage Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 294,
      chnn_stock_retail: 294,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '21WA6645',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21WA6645'
    },
    {
      status: 210,
      sku: 'PM0081234',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339715',
      rtl_batch_array: '6339715',
      name_search: 'ACMNVVIO',
      name: 'Achillea millefolium New Vintage Violet',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78BA451V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78BA451V'
    },
    {
      status: 210,
      sku: 'PM0081235',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339716',
      rtl_batch_array: '6339716',
      name_search: 'ACMNVWHI',
      name: 'Achillea millefolium New Vintage White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V199TRDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V199TRDP'
    },
    {
      status: 910,
      sku: 'PM0063493',
      core_name: 'Plant',
      sppl_ean: '8720664873896',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142270',
      rtl_batch_array: '6142270',
      name_search: 'MAGGSTAR',
      name: "Magnolia 'Gold Star'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 32.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B441EWAE;9PG3ZRWC;Z6DABA7D;D6KL6LHX;H12SW25C;PABEP9C9;XABLB3AK;L1C3KG62',
      statusContent: 'dot',
      hash: 'PYGH818L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYGH818L'
    },
    {
      status: 210,
      sku: 'PM0081236',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339717',
      rtl_batch_array: '6339717',
      name_search: 'ACPPCOTT',
      name: "Achillea ptarmica 'Peter Cottontail'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2Z6G9REP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Z6G9REP'
    },
    {
      status: 210,
      sku: 'PM0081237',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339719',
      rtl_batch_array: '6339719',
      name_search: 'ACTAUREA',
      name: "Achillea tomentosa 'Aurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KG4GG98E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG4GG98E'
    },
    {
      status: 210,
      sku: 'PM0081238',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339720',
      rtl_batch_array: '6339720',
      name_search: 'ACGHNISH',
      name: "Acorus gramineus 'Hakuro-nishiki'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VH5PNTP;K5BHC9CH;8745RRJP',
      statusContent: 'dot',
      hash: 'EX3SK764',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX3SK764'
    },
    {
      status: 810,
      sku: 'PM0081239',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339722',
      rtl_batch_array: '6339722',
      name_search: 'AGAMBLUE',
      name: 'Agapanthus Midknight Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TZRZ1VH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZRZ1VH3'
    },
    {
      status: 210,
      sku: 'PM0081240',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339726',
      rtl_batch_array: '6339726',
      name_search: 'AGDDELIG',
      name: 'Agastache Danish Delight',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRP8N2WT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRP8N2WT'
    },
    {
      status: 210,
      sku: 'PM0081241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339727',
      rtl_batch_array: '6339727, 6321863',
      name_search: 'AGFIREBI',
      name: "Agastache 'Firebird'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1344,
      chnn_stock_retail: 1494,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YRBKJABA;PN4W3477',
      statusContent: 'dot',
      hash: 'SVPZRTA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVPZRTA7'
    },
    {
      status: 210,
      sku: 'PM0064964',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293869',
      rtl_batch_array: '6293869',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '060070C74PY',
      rng_range_identifier: 'PYRAH060070C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.776,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'VBH522TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBH522TN'
    },
    {
      status: 810,
      sku: 'PM0081242',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339728',
      rtl_batch_array: '6339728',
      name_search: 'AGKOLIBR',
      name: "Agastache 'Kolibri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KL2HBYAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KL2HBYAW'
    },
    {
      status: 210,
      sku: 'PM0081243',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339729',
      rtl_batch_array: '6339729',
      name_search: 'AGMTANGO',
      name: "Agastache 'Mango Tango'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DGLRLCG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGLRLCG5'
    },
    {
      status: 210,
      sku: 'PM0070004',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6220352',
      rtl_batch_array: '6349166, 6220352, 6268808, 6291731',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 12603,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'T2CKZX84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2CKZX84'
    },
    {
      status: 210,
      sku: 'PM0069989',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193702',
      rtl_batch_array: '6193702',
      name_search: 'MYSPICAT',
      name: 'Myriophyllum spicatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JR5GW9YC;484BHE28',
      statusContent: 'dot',
      hash: 'C5SN4CH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5SN4CH7'
    },
    {
      status: 210,
      sku: 'PM0069981',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193670',
      rtl_batch_array: '6193670',
      name_search: 'MECERVIN',
      name: 'Mentha cervina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CR8SJRR5;XXEW13BS;TVXXS735;YLG42471;5XHLYWAR;XGCPN5V3;4APT67EE;L1LCA8ZB;91RR5LEN;B2VZZR37;JVW36AKL;2EXRKDAY;RH8SJLYN;VLXVL6PZ;6WNS2E33;PAW8191Y;JBJRBHLL',
      statusContent: 'dot',
      hash: 'ES1V13DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ES1V13DY'
    },
    {
      status: 210,
      sku: 'PM0069988',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193701',
      rtl_batch_array: '6193701',
      name_search: 'ELCANADE',
      name: 'Elodea canadensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RADJ6CNZ',
      statusContent: 'dot',
      hash: 'PDTRH6WD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDTRH6WD'
    },
    {
      status: 210,
      sku: 'PM0045946',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350303',
      rtl_batch_array: '6350303',
      name_search: 'HEPWHEEL',
      name: "Helenium 'Potter's Wheel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1R28BA8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R28BA8R'
    },
    {
      status: 210,
      sku: 'PM0066385',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260726',
      rtl_batch_array: '6279848, 6260726',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1403,
      chnn_stock_retail: 2853,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: 'PA7D8CKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA7D8CKC'
    },
    {
      status: 210,
      sku: 'PM0077540',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304911',
      rtl_batch_array: '6304911',
      name_search: 'STABRUNE',
      name: 'Strobilanthes anisophylla Brunetthy',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2697,
      chnn_stock_retail: 2697,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '221A42EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '221A42EZ'
    },
    {
      status: 210,
      sku: 'PM0077541',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310435',
      rtl_batch_array: '6310435',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1600,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: '83A54NHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83A54NHW'
    },
    {
      status: 210,
      sku: 'PM0081244',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339730',
      rtl_batch_array: '6339730',
      name_search: 'AGNEPETO',
      name: 'Agastache nepetoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 357,
      chnn_stock_retail: 357,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A2JJ1S2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2JJ1S2G'
    },
    {
      status: 210,
      sku: 'PM0077543',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6373838',
      rtl_batch_array: '6304917, 6373838, 5414826',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 5850,
      sppl_prcp: 2.205,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '8PD9KZ2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PD9KZ2R'
    },
    {
      status: 210,
      sku: 'PM0077544',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304918',
      rtl_batch_array: '6304918',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2736,
      chnn_stock_retail: 2736,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: '6ZLB1348',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZLB1348'
    },
    {
      status: 210,
      sku: 'PM0077545',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304919',
      rtl_batch_array: '6304919',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3909,
      chnn_stock_retail: 3909,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'J6TSTZ25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6TSTZ25'
    },
    {
      status: 210,
      sku: 'PM0077546',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304920',
      rtl_batch_array: '6304920',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1864,
      chnn_stock_retail: 1864,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: 'KA1WXDJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KA1WXDJN'
    },
    {
      status: 210,
      sku: 'PM0077669',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348482',
      rtl_batch_array: '4348482',
      name_search: 'EDI62461',
      name: "Narcissus (trt) 'Dutch Master'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 99992,
      chnn_stock_retail: 99992,
      sppl_prcp: 15.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADPKH85V;HT8DDRB5;HVX7NAWJ;6ASWN1JR;7WK3YLHT;H576JPPN;JZ3GR1HT;JA1G79RP;ZKS4DD6J;9K73Z2GP;R92L81N7;N9B7YTVV;ZXBBJYKB;G9S3XGYE;E5DJW778;J57TSW75;V1STJZJD;WP2XHRPP;EP2J7Z85;7PG3BDT6;LACBJ2V2;NCB36XE1;GGNB4B1N;ZE2JPCNJ',
      statusContent: 'dot',
      hash: 'NAVL6219',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAVL6219'
    },
    {
      status: 210,
      sku: 'PM0070956',
      core_name: 'Plant',
      sppl_ean: '8720664856394',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6114072',
      rtl_batch_array: '6114072',
      name_search: 'CHODRATH',
      name: "Chamaecyparis obtusa 'Drath'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1093,
      chnn_stock_retail: 1093,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SC3S8TEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SC3S8TEJ'
    },
    {
      status: 910,
      sku: 'PM0052517',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEOQAPRI',
      name: "Helleborus o. 'Queens Apricot'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CV21B49H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CV21B49H'
    },
    {
      status: 210,
      sku: 'PM0081245',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339733',
      rtl_batch_array: '6339733',
      name_search: 'AGRRPOS',
      name: "Agastache rugosa 'Rosie Posie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '16RDLED3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16RDLED3'
    },
    {
      status: 210,
      sku: 'PM0077670',
      core_name: 'Plant',
      sppl_ean: '8719223009838',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348486',
      rtl_batch_array: '4348486',
      name_search: 'NAGHARVE',
      name: 'Narcissus Golden Harvest',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_order_minimum: 1,
      sppl_prcp: 13.604,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WC6LLGL4;18T5YT5G;NT2VZG4P;C2VR1TJ9;RY839ERA;ZH5XZK94',
      statusContent: 'dot',
      hash: 'ECE5YYYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECE5YYYE'
    },
    {
      status: 210,
      sku: 'PM0081246',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339734',
      rtl_batch_array: '6339734',
      name_search: 'AGSERPEN',
      name: 'Agapetes serpens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 564,
      chnn_stock_retail: 564,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZHPPJ63',
      statusContent: 'dot',
      hash: 'D26ZAPV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D26ZAPV3'
    },
    {
      status: 210,
      sku: 'PM0077671',
      core_name: 'Plant',
      sppl_ean: '8719223021021',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348488',
      rtl_batch_array: '4348488',
      name_search: 'NALVEGAS',
      name: 'Narcissus Las Vegas',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 21.604,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYVAT4DT;J5DVCJLZ;JD43212K;LYE2DY67;17XB28PX;J77A9A3C',
      statusContent: 'dot',
      hash: '6PY3G6BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PY3G6BD'
    },
    {
      status: 910,
      sku: 'PM0077672',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'NAMHOOD',
      name: 'Narcissus Mount Hood (LC)',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      imageCore:
        '7XR2G8X3;DHYLK6EG;5RKPJL15;S8XLNSA1;RCYNPRE2;YDKAR7B1;VAZHPWEY;E8P6XKRY;8GRDKH6J;2YTNLWCC;9G9EJJL6;6EPP2S2N;WK55E5NB;HHBSKW1W;HWWXVCYG;9HC53XB9;ES2REV5G;KHX4VB7W;BLC8VPD1;BJWR8AS8;DYWNJDH3;GDE7E48S;JEEJER7W',
      statusContent: 'dot',
      hash: '93WTZ4T5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '93WTZ4T5'
    },
    {
      status: 210,
      sku: 'PM0077673',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348492',
      rtl_batch_array: '4348492',
      name_search: 'EDI62781',
      name: "Narcissus (trt) 'Rijnveld's Early Sensation'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 21.604,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CW458L2Z;2CBACAH7;YZCLLAPN;E49T7NVH;SJB5TENY;52EEGTS4;C349JDHW;7KR9VN7V;H4KD7DDB;EDHPX598',
      statusContent: 'dot',
      hash: 'WPBWDTDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPBWDTDB'
    },
    {
      status: 210,
      sku: 'PM0045639',
      core_name: 'Plant',
      sppl_ean: '8720664860988',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147766',
      rtl_batch_array: '6147766',
      name_search: 'ECPMILKS',
      name: "Echinacea purpurea 'Milkshake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PTBYDLLS;HLHCYJ54',
      statusContent: 'dot',
      hash: 'EY799YZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY799YZ2'
    },
    {
      status: 210,
      sku: 'PM0077674',
      core_name: 'Plant',
      sppl_ean: '8719223009975',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348494',
      rtl_batch_array: '4348494',
      name_search: 'NASVALUE',
      name: 'Narcissus Standard value',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 14.404,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZVAY6WV;4456ZSJP;SRP9ZHD3;XCGVEXVX;4VDE81J6',
      statusContent: 'dot',
      hash: 'TJG81WAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJG81WAR'
    },
    {
      status: 210,
      sku: 'PM0077675',
      core_name: 'Plant',
      sppl_ean: '8719223010018',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348496',
      rtl_batch_array: '4348496',
      name_search: 'NAACCENT',
      name: 'Narcissus Accent',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 18.004,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B124LBV4;KXER5CH1;NDYWVZ89;PB34HE7A;BDB94KL3;T3RAK9ZJ;N6YGAN8B;CTYXVX5X;6RVV5HTW;D56PKL3A;DWT35RXN;B6CAXRRT;J7ZSW2AK',
      statusContent: 'dot',
      hash: 'ZWAJLGS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWAJLGS7'
    },
    {
      status: 210,
      sku: 'PM0077676',
      core_name: 'Plant',
      sppl_ean: '8719223010513',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348502',
      rtl_batch_array: '4348502',
      name_search: 'NASTAINL',
      name: 'Narcissus Stainless',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 22.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN5A47LX;2RWV7733;Z787VHLD;JWN2YWTB;H69YWYC1',
      statusContent: 'dot',
      hash: 'X7ALAEZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7ALAEZC'
    },
    {
      status: 210,
      sku: 'PM0052509',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353468',
      rtl_batch_array: '6353468',
      name_search: 'BESROMAN',
      name: "Betonica 'Summer Romance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8GNASTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8GNASTP'
    },
    {
      status: 210,
      sku: 'PM0077677',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348506',
      rtl_batch_array: '4348506',
      name_search: 'NALCMI',
      name: 'Narcissus Large Cub mix',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 15.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8YWNW2VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YWNW2VX'
    },
    {
      status: 210,
      sku: 'PM0077678',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348512',
      rtl_batch_array: '4348512',
      name_search: 'EDI62350',
      name: "Narcissus (smc) 'Barrett Browning'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE363B3C;VEVA9PRH;HS4GES1N;K23R8VTH;R1TKK1E7;6VV3GXCB;RVVD4L9Y;HCG2N28S;DWJCLAEY;2734N8PZ;WK32WS79;S5VZHT8S;Y2GNSPLR;DSRASR88;6RJSW62G',
      statusContent: 'dot',
      hash: 'DT6P7N88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT6P7N88'
    },
    {
      status: 210,
      sku: 'PM0058153',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6307993',
      rtl_batch_array: '6164382, 6383343, 6307993',
      name_search: 'BRMBBOWR',
      name: "Brunnera macrophylla 'Betty Bowring'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 710,
      chnn_stock_retail: 3299,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD7111WT;P6698YX4;BX3BSRV4;743VBYA9',
      statusContent: 'dot',
      hash: 'VE94YHBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE94YHBV'
    },
    {
      status: 210,
      sku: 'PM0081247',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339735',
      rtl_batch_array: '6339735, 6321862',
      name_search: 'AGBTDREA',
      name: "Agastache barberi 'Tangerine Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 572,
      chnn_stock_retail: 647,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EW7Y7778',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW7Y7778'
    },
    {
      status: 210,
      sku: 'PM0077680',
      core_name: 'Plant',
      sppl_ean: '8719223010858',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348524',
      rtl_batch_array: '4348524',
      name_search: 'NAGDUCAT',
      name: 'Narcissus Golden Ducat',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 15.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C63HX6GP;H8B8J3NP;DH8KC1XN;HNDA1TJZ;61XKYV4X;YRGRTHEA;GHY7GEE5;PT2GD71T;PSC6727Z',
      statusContent: 'dot',
      hash: 'DX23TPZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX23TPZ3'
    },
    {
      status: 210,
      sku: 'PM0077681',
      core_name: 'Plant',
      sppl_ean: '8719223010896',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348526',
      rtl_batch_array: '4348526',
      name_search: 'NAIKING',
      name: 'Narcissus Ice King (DO)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 16.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '75BWT4V8;LHVBR5AH;R9JPZKK4;14YGXSAS;DPV7W6E3;LDA5NTX8;REYA7292;GEE34E3A;ST8367XJ;ZGDP67D2;TKWG8WH4;XBLAGYW5;L42AEEY9;4SZXNYBT',
      statusContent: 'dot',
      hash: '6BV5CHBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BV5CHBJ'
    },
    {
      status: 210,
      sku: 'PM0077682',
      core_name: 'Plant',
      sppl_ean: '8719223024909',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348530',
      rtl_batch_array: '4348530',
      name_search: 'NAOBDAM',
      name: 'Narcissus Obdam',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 17.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '44JNT9DR;J7G6KVW5;66L8KBLS;ZDA6NW86',
      imageBatch: 'NWY2T4ED',
      statusContent: 'dot',
      hash: 'J6BWRSY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6BWRSY5'
    },
    {
      status: 910,
      sku: 'PM0081248',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALHOPPEA',
      name: 'Alchemilla hoppeana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '64YKXJXV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '64YKXJXV'
    },
    {
      status: 210,
      sku: 'PM0077683',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348548',
      rtl_batch_array: '4348548',
      name_search: 'EDI62518',
      name: "Narcissus (taz) 'Geranium'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S4L73ZCX;8RRCGE8L;X627PZPY;NSV51G44;ZTHTYNP7;V7DWHA41;JABKD91D;7996ZGVD;6TYNKV2S;LSRV52L8;D8HWJANB;PSCACDJD;HB5KNRW2;NJ56H16L;XY5GJC2D',
      statusContent: 'dot',
      hash: 'LKAHJK31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKAHJK31'
    },
    {
      status: 810,
      sku: 'PM0046021',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301640',
      rtl_batch_array: '6301640',
      name_search: 'HEWISLAN',
      name: "Hemerocallis 'Wassaw Island'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 2.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BV6RBLJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BV6RBLJ9'
    },
    {
      status: 210,
      sku: 'PM0047641',
      core_name: 'Plant',
      sppl_ean: '8720664539518',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953601',
      rtl_batch_array: '5953601',
      name_search: 'GEARUSH',
      name: "Geranium 'Azure Rush'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3552,
      chnn_stock_retail: 3552,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVSG4AS8',
      statusContent: 'dot',
      hash: 'YRDRP4R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRDRP4R7'
    },
    {
      status: 210,
      sku: 'PM0077684',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348550',
      rtl_batch_array: '4348550',
      name_search: 'EDI62828',
      name: "Narcissus (dou) 'Sir Winston Churchill'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JT3SPYPS;Y6LWY79B;K919ZBCT;BR8PXR37;6AS26D9D;793KCJ6H;RY4ZPK2R;R3JAP9HR;25LEB6YH;A423EZ6Y',
      statusContent: 'dot',
      hash: 'XEL37H91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEL37H91'
    },
    {
      status: 210,
      sku: 'PM0077685',
      core_name: 'Plant',
      sppl_ean: '8719223025999',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348554',
      rtl_batch_array: '4348554',
      name_search: 'NABSTART',
      name: 'Narcissus Blazing Starlet',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 20.704,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LK7XGNKV;85VPBE4C;PEDHSPZL;ZV4Z2571;W21VL7KZ',
      statusContent: 'dot',
      hash: 'GD2J7DD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GD2J7DD7'
    },
    {
      status: 210,
      sku: 'PM0077686',
      core_name: 'Plant',
      sppl_ean: '8719223011350',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348562',
      rtl_batch_array: '4348562',
      name_search: 'NAORANGE',
      name: 'Narcissus Orangery',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 19.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6L6KR2A;GP44ET1H;91YXJKRX;K8VB8CTC;HVHAJ82Y;NJVDSBZC;AARBBZYT;PJPV3645;V6PWZHPR;BHA219PR;28N553AJ;Z82V5C24',
      statusContent: 'dot',
      hash: 'DTJWCYNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTJWCYNZ'
    },
    {
      status: 210,
      sku: 'PM0058164',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308464',
      rtl_batch_array: '6349065, 6349948, 6164391, 6308464, 6301517',
      name_search: 'COMAJALI',
      name: 'Convallaria majalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 19698,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZEN1E4R2;KD9CWC1V;SBTX9DL1;67TYNDTB;HCKP5KHL;YD5K7A2B;DC3DGC6P;SA4HHCXH;LYPNEL1C;LA8C9VV9;62RNL84R;NKYVAYKW;LA4J4WHV;D56BSBWH;EWKAL5XE;EZ533RDT;G9SHZX3Z;A9CGDA6W;E5YGEJLX;Z4W3E137;XXZ1A6SJ;VAAAX6E5;GYV3RKEX;55J5EBEA;VXDEWS95;B84LJL4S;NBRAY3GY;GV8XSCLE;6LDVB9TE',
      statusContent: 'dot',
      hash: '92Z7B866',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92Z7B866'
    },
    {
      status: 210,
      sku: 'PM0077687',
      core_name: 'Plant',
      sppl_ean: '8719223011770',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348582',
      rtl_batch_array: '4348582',
      name_search: 'NAFGOL',
      name: 'Narcissus February Gold (CY)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 17.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WV52XLB;R3YCEWW9;HC9AY3LD;2LXYD89B;5GYC5AZ5;2VETSERR;54Z5Z74V;1K441XLE;ZHT7475P;TW85X5Z9;6H4W39CA;V4EKD699;TWETTCVX;L6CHRCTP;ZKK3YWVN;288NKCNY;N7HEVK2J;X1TXA45D;NPRE3VSE;JC4AD159;AHX4W2BC;C7NCSDGL;6T6BLYN3;1PTH14WV;PSE14A4Y;5PJGS5RJ',
      statusContent: 'dot',
      hash: 'WTLGGLNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTLGGLNN'
    },
    {
      status: 210,
      sku: 'PM0077688',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348588',
      rtl_batch_array: '4348588',
      name_search: 'EDI62584',
      name: "Narcissus (cyc) 'Jetfire'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.454,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C6XZGA4W;5TA6PWB9;8P6YZ9YA;WLJR1BX2;Z1ED4GP9;7YRXVDR9;B2S1R7KP;KZSAR2XG;VEWJ3Y2C;CJKE9RCJ;D5B3CRRP;K2ZVWKYE;4HCNHTX9;79LDDKBR;R5VD66DP;XY5A181A;1JYBG5GL;1B3ZRHT1;XJ47ZYED;G6NK5T4L;T6SHY7HL;CZKSBH13;1VKHJJHD;CPGL32KY;LJ22C3PB;2WRWNGET;B93VWCSZ;69HSTWSL;TGZZXPZ2',
      statusContent: 'dot',
      hash: 'L31TNRN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L31TNRN8'
    },
    {
      status: 210,
      sku: 'PM0077689',
      core_name: 'Plant',
      sppl_ean: '8719223012111',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348598',
      rtl_batch_array: '4348598',
      name_search: 'NAPIPIT',
      name: 'Narcissus Pipit (JQ)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 16.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1N672S3K;WWDX1JL4',
      statusContent: 'dot',
      hash: 'K12KX7HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K12KX7HH'
    },
    {
      status: 210,
      sku: 'PM0077690',
      core_name: 'Plant',
      sppl_ean: '8719223012159',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348604',
      rtl_batch_array: '4348604',
      name_search: 'NAQUAIL',
      name: 'Narcissus Quail (JQ)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRXYVT8V;R81WJCH8',
      statusContent: 'dot',
      hash: 'KTJ4VCXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTJ4VCXA'
    },
    {
      status: 210,
      sku: 'PM0077691',
      core_name: 'Plant',
      sppl_ean: '8719223012272',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348610',
      rtl_batch_array: '4348610',
      name_search: 'NATTETE',
      name: 'Narcissus T\u00eate \u00e0 T\u00eate',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 14.404,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S1BC5RW2;G186EXDB;GDZ64TZH;X7L96J13',
      statusContent: 'dot',
      hash: 'PTAWY94X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTAWY94X'
    },
    {
      status: 210,
      sku: 'PM0068512',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205395',
      rtl_batch_array: '6205395',
      name_search: 'PULDCLAR',
      name: "Pulmonaria longifolia 'Diana Clare'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2379,
      chnn_stock_retail: 2379,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VD9WXK78',
      statusContent: 'dot',
      hash: 'V987RSHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V987RSHY'
    },
    {
      status: 210,
      sku: 'PM0045988',
      core_name: 'Plant',
      sppl_ean: '8720664549913',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015345',
      rtl_batch_array: '6015345',
      name_search: 'HOPSKY',
      name: "Hosta 'Prairie Sky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K36PTR5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K36PTR5E'
    },
    {
      status: 910,
      sku: 'PM0066400',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJMANON',
      name: "Spiraea japonica 'Manon'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      imageCore: 'VZAWNADB',
      statusContent: 'dot',
      hash: 'GS2RG6ZA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GS2RG6ZA'
    },
    {
      status: 210,
      sku: 'PM0045991',
      core_name: 'Plant',
      sppl_ean: '8720349450299',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015350',
      rtl_batch_array: '6015350',
      name_search: 'HORSTEPP',
      name: "Hosta 'Red Stepper'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 876,
      chnn_stock_retail: 876,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GB1BRL4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB1BRL4X'
    },
    {
      status: 210,
      sku: 'PM0077693',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348623',
      rtl_batch_array: '4348623',
      name_search: 'ALPRAIN',
      name: "Allium 'Purple Rain'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 25.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'A9CCXDB6',
      statusContent: 'dot',
      hash: '8ZGD4AS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZGD4AS2'
    },
    {
      status: 810,
      sku: 'PM0045687',
      core_name: 'Plant',
      sppl_ean: '8720353095998',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014814',
      rtl_batch_array: '6014814',
      name_search: 'DEABERDE',
      name: 'Delosperma aberdeenense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J559HE52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J559HE52'
    },
    {
      status: 210,
      sku: 'PM0077700',
      core_name: 'Plant',
      sppl_ean: '8719223015600',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348648',
      rtl_batch_array: '4348648',
      name_search: 'IRRETICU',
      name: 'Iris reticulata',
      sppl_size_code: '005006BOP10',
      rng_range_identifier: 'BU\u00d85006100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm 6 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 9.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'KJ4E1TCR',
      statusContent: 'dot',
      hash: 'LAD4SR24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAD4SR24'
    },
    {
      status: 910,
      sku: 'PM0045277',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGVOLEND',
      name: "Agapanthus 'Volendam'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HPG5ZTSS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HPG5ZTSS'
    },
    {
      status: 210,
      sku: 'PM0054527',
      core_name: 'Plant',
      sppl_ean: '8720664862593',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6076596',
      rtl_batch_array: '6076596, 6003999',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 880,
      chnn_stock_retail: 900,
      sppl_prcp: 4.566,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'Z3S64RTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3S64RTV'
    },
    {
      status: 210,
      sku: 'PM0032957',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317996',
      rtl_batch_array: '6284480, 6317996',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 21977,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'J3K2T5SJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3K2T5SJ'
    },
    {
      status: 210,
      sku: 'PM0081249',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321871',
      rtl_batch_array: '6339737, 6321871',
      name_search: 'ALFISTUL',
      name: 'Allium fistulosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 612,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VRL3KY36;49LRWPY5;ZSPE2L4K;WG31H7GV;YJP9KEZ1;CS9NZVAP;SST55GAN;KYSS1P7P;8C4VEYX3;H3CL2PEH;R47BJ5HA;BXCAR8YP;D8AWR599;JRNVYZB2;G2GK6JY9;NRHZRP4C;KA1XJ5T5;9P4XPDVG;STA7LN1E;YY1D2NBA',
      statusContent: 'dot',
      hash: 'K479XGSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K479XGSH'
    },
    {
      status: 210,
      sku: 'PM0052800',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370328',
      rtl_batch_array: '6370328, 6011432, 6339753',
      name_search: 'ASAVEILC',
      name: "Aster amellus 'Veilchenk\u00f6nigin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 227,
      chnn_stock_retail: 620,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y3VSSEGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3VSSEGW'
    },
    {
      status: 810,
      sku: 'PM0045689',
      core_name: 'Plant',
      sppl_ean: '8720664686908',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054589',
      rtl_batch_array: '6054589',
      name_search: 'DECJDMOO',
      name: "Delosperma cooperi 'Jewel of Desert Moonstone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KRYG81KV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRYG81KV'
    },
    {
      status: 210,
      sku: 'PM0077704',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348694',
      rtl_batch_array: '4348694',
      name_search: 'IRHPSENS',
      name: 'Iris hollandica Purple Sensation',
      sppl_size_code: '007008BOP10',
      rng_range_identifier: 'BU\u00d87008100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm 8 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 5.401,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NDGT37DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDGT37DX'
    },
    {
      status: 210,
      sku: 'PM0077705',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348697',
      rtl_batch_array: '4348697',
      name_search: 'IRHSBEAU',
      name: 'Iris hollandica Silver Beauty',
      sppl_size_code: '007008BOP10',
      rng_range_identifier: 'BU\u00d87008100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm 8 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 10.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HLDSLSJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLDSLSJ5'
    },
    {
      status: 210,
      sku: 'PM0047354',
      core_name: 'Plant',
      sppl_ean: '8720349482535',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5997968',
      rtl_batch_array: '5997968',
      name_search: 'WEFVICTO',
      name: "Weigela florida 'Victoria'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '77L8S29W;VD34HGKR',
      statusContent: 'dot',
      hash: '7DCKLGLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DCKLGLL'
    },
    {
      status: 210,
      sku: 'PM0077706',
      core_name: 'Plant',
      sppl_ean: '8719223016300',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348702',
      rtl_batch_array: '4348702',
      name_search: 'SCSIBERI',
      name: 'Scilla siberica',
      sppl_size_code: '007008BOP10',
      rng_range_identifier: 'BU\u00d87008100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm 8 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 7.651,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEHYTTRA;RGK998D5;J6E3VPHW;EG3513GB;4DB7Y2AJ;PP7G4YGZ;A1J58DZ1;XLXVSYJC;APW67YBJ;7293R81G;DN9Y6BWJ;ASHTP12B;PL3EJ6BA;T57TTV4R;Z6Z12N3A;PJLYE2LX;BDT79WNT;1LSV5S8J',
      imageBatch: 'VRL5PDNS',
      statusContent: 'dot',
      hash: 'PAK7DLTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAK7DLTG'
    },
    {
      status: 810,
      sku: 'PM0045705',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6204236',
      rtl_batch_array: '6204236, 6202284',
      name_search: 'DICARTHU',
      name: 'Dianthus carthusianorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 42,
      chnn_stock_retail: 198,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YJYWSP23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJYWSP23'
    },
    {
      status: 210,
      sku: 'PM0058367',
      core_name: 'Plant',
      sppl_ean: '8720664871014',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112659',
      rtl_batch_array: '6112659',
      name_search: 'KNFGLOW',
      name: 'Kniphofia Fire Glow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 842,
      chnn_stock_retail: 842,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6CVL4E3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6CVL4E3'
    },
    {
      status: 210,
      sku: 'PM0077708',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348708',
      rtl_batch_array: '4348708',
      name_search: 'CRGYELLO',
      name: 'Crocus Golden Yellow',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NZCHR8D;HKP3XNJK',
      statusContent: 'dot',
      hash: 'NCP5V3AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCP5V3AK'
    },
    {
      status: 210,
      sku: 'PM0077709',
      core_name: 'Plant',
      sppl_ean: '8719223010261',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348716',
      rtl_batch_array: '4348716',
      name_search: 'NAIFOLLI',
      name: 'Narcissus Ice Follies (LC)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 20.704,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R2TEAAAB;TX85RS9T;7A1CG24R;2B92986G;LX8X9KKR;65C54R1A;W8YLRK63;5WG2VZR1;J33RBSPG;HWREX23B;JZP5TR8X;C994E326;ZB69E745;BRAYE2SD;Z2JTLW5H;1DS8824X;JV4GC3Z6;2W5E83RK',
      statusContent: 'dot',
      hash: 'PPEBY8LR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPEBY8LR'
    },
    {
      status: 210,
      sku: 'PM0011866',
      core_name: 'Plant',
      sppl_ean: '8720664885776',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084705',
      rtl_batch_array: '6084705, 6133675, 6353286',
      name_search: 'SEAAUREU',
      name: "Sedum acre 'Aureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5650,
      chnn_stock_retail: 6613,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y95N71CC;ZCA6W7HS;TE5L7RTH;JR8ACWV4;491JEJNY;WDZ9PB2N;1GEVELDG;N1TZRL6E;9X95KZSR',
      statusContent: 'dot',
      hash: 'P47LWJPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P47LWJPW'
    },
    {
      status: 210,
      sku: 'PM0085086',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371043',
      rtl_batch_array: '6371043',
      name_search: 'GEOCDRUC',
      name: "Geranium oxonianum 'Claridge Druce'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1988,
      chnn_stock_retail: 1988,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWS753XC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWS753XC'
    },
    {
      status: 210,
      sku: 'PM0077542',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304915',
      rtl_batch_array: '6304915',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '060STC5',
      rng_range_identifier: 'STE060C5',
      rng_range_description: 'Stem 60 cm C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 9.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'P8SANTZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8SANTZA'
    },
    {
      status: 210,
      sku: 'PM0081250',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339738',
      rtl_batch_array: '6339738',
      name_search: 'ALPLUMME',
      name: 'Allium plummerae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '63WTTYNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63WTTYNS'
    },
    {
      status: 210,
      sku: 'PM0077710',
      core_name: 'Plant',
      sppl_ean: '8719223010315',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348718',
      rtl_batch_array: '4348718',
      name_search: 'NAPCHARM',
      name: 'Narcissus Pink Charm',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 18.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JG775ADR;J21GTPA5;5S9WB73K;BZYBN48V;XXW57716;4N3N6BAV;ZVXJK7D8;W97EBAB9;8TB2ZRTR',
      statusContent: 'dot',
      hash: '8Y1BKKKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Y1BKKKV'
    },
    {
      status: 210,
      sku: 'PM0046039',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350393',
      rtl_batch_array: '6301644, 6350393',
      name_search: 'HEDMAGIC',
      name: "Heuchera 'Dark Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 411,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KP35VAWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP35VAWN'
    },
    {
      status: 210,
      sku: 'PM0077711',
      core_name: 'Plant',
      sppl_ean: '8719223010391',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348720',
      rtl_batch_array: '4348720',
      name_search: 'NARDEVON',
      name: 'Narcissus Red Devon',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 16.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RDNN3ZPX;D4KEHJTJ;ZZ52HYB4;SBJX4Z4K;7TC6A15Z;PV3JKLLP;984T6YKT;7DWLJG6L;CDH92SLH;WGAW8P13;JJKP48LK;CDNVNZJ6;PT96DZLV;6V3V4876;4DAGLRGZ;DA3VKJ8N;P14DXG9W;YHKL27AR;7A46BE2E;2C98P6HB;GYW443YL;6JBWDJD9;2DRHG6RG;1G6BRXDP',
      statusContent: 'dot',
      hash: 'L4NSSWRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4NSSWRS'
    },
    {
      status: 210,
      sku: 'PM0077713',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348745',
      rtl_batch_array: '4348745',
      name_search: 'EDI62555',
      name: "Narcissus (trs) 'Hawera'",
      sppl_size_code: '010012BOP10',
      rng_range_identifier: 'BU\u00d810012100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 11.702,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B7YD3GTT;55SH7S3Z;6K74ZKP9;577NRX3S',
      statusContent: 'dot',
      hash: 'GXB91SDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXB91SDV'
    },
    {
      status: 210,
      sku: 'PM0081251',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339740',
      rtl_batch_array: '6339740',
      name_search: 'ALSBREAK',
      name: 'Alstroemeria Summer Break',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BRBEP6XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRBEP6XB'
    },
    {
      status: 910,
      sku: 'PM0060096',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307761',
      rtl_batch_array: '6307761',
      name_search: 'PIPTEMPE',
      name: "Pinus parviflora 'Tempelhof'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 21.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PR7B35NA',
      statusContent: 'dot',
      hash: 'D6H8NK61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6H8NK61'
    },
    {
      status: 210,
      sku: 'PM0054722',
      core_name: 'Plant',
      sppl_ean: '8720664877511',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080727',
      rtl_batch_array: '6080727',
      name_search: 'PECGDITA',
      name: "Petroselinum cr. 'Gigante d'Italia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 353,
      chnn_stock_retail: 353,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2G6HV4V9;2YB4857D;C6V94XA6;BZHVBKA7;VPW7T2VJ;L6TPRV8Y',
      statusContent: 'dot',
      hash: '54JKRCNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54JKRCNE'
    },
    {
      status: 210,
      sku: 'PM0081252',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321881',
      rtl_batch_array: '6339742, 6321881',
      name_search: 'ANTSYLVE',
      name: 'Anthriscus sylvestris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 1225,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7C198JP',
      statusContent: 'dot',
      hash: 'C4Z11D5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4Z11D5Y'
    },
    {
      status: 210,
      sku: 'PM0077714',
      core_name: 'Plant',
      sppl_ean: '8719223012050',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348746',
      rtl_batch_array: '4348746',
      name_search: 'NAOPLENU',
      name: 'Narcissus Odorus Plenus',
      sppl_size_code: '010012BOP10',
      rng_range_identifier: 'BU\u00d810012100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.502,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2PEL52GL',
      statusContent: 'dot',
      hash: 'PV35V129',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PV35V129'
    },
    {
      status: 210,
      sku: 'PM0081253',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339745',
      rtl_batch_array: '6339745',
      name_search: 'ARSNATTR',
      name: "Artemsia schmidtiana 'Nana Attraction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 453,
      chnn_stock_retail: 453,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '87PWADRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87PWADRP'
    },
    {
      status: 210,
      sku: 'PM0077715',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348747',
      rtl_batch_array: '4348747',
      name_search: 'EDI62783',
      name: "Narcissus (dou) 'Rip van Winkle'",
      sppl_size_code: '010012BOP10',
      rng_range_identifier: 'BU\u00d810012100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVN6DPK2;CL2SJBKL;CJ1ZW11E;EXWL8STJ;PXTDP3A8;T1G3LATW;865H7432;VRP2KWRG;LSRANRL3;BPGBT8NC;923VYSBE;XK8Z6462;NHECSY67;969CPJT6;7XS739CL;AHNAPD32;1CJ6TLSC;CCXXVSVE',
      statusContent: 'dot',
      hash: 'V1XTVCPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1XTVCPV'
    },
    {
      status: 210,
      sku: 'PM0077717',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348761',
      rtl_batch_array: '4348761',
      name_search: 'EDI60431',
      name: "Tulipa (sea) 'Flair'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '17JWT2AX;8BC3BR2R;69YNAPG1;NTPNXVJZ;6Y4ATD19;LYZWHSAC;6RLLY1DW;WSTC2PA9;99RT9L4S;HY3S6VVD;B8BGA7YA;BA1ARSCH;8N2TAD1Y;VDK4NEZH;PVKNBGC9;C5LWX9GC;VTZ7Y55T;5BTSXE3D;CA8DJ511;HEDN1R9E',
      statusContent: 'dot',
      hash: 'KTRBLV35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTRBLV35'
    },
    {
      status: 210,
      sku: 'PM0077718',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348769',
      rtl_batch_array: '4348769',
      name_search: 'EDI60997',
      name: "Tulipa (trh) 'Prinses Irene'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8C2DPGRD;ZLRGHSHD;Z4GNJL6J;VB4TNTT5;GG7XLGP9;K2A2HW6V;DT5SN4YH;LHYBGHZW;KYTPNJRL;SWXKBGBL;B6DVK9ZD;V5WH4NPZ;P8PHV668;1KSVA3Y9;P7HX5K2E;T367ZPYH;EXP9E913;VN3PDKB3;A4BC9SGW;2J278L2K;WGPD4YLK;DAG5G839;XREPST4Y;82S7CZXD',
      statusContent: 'dot',
      hash: 'VEEDRCB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VEEDRCB9'
    },
    {
      status: 210,
      sku: 'PM0063504',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253088',
      rtl_batch_array: '6253088',
      name_search: 'FULTHUMB',
      name: "Fuchsia 'Lady Thumb'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 667,
      chnn_stock_retail: 667,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9LZE88A2;HDAJEETB;W4HK4NA4;41XVVNHA;N6K97PDD;449PWZD6;6S2CPT3T;J7XX5SWZ;58NRAWLL',
      statusContent: 'dot',
      hash: 'EA717BVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA717BVZ'
    },
    {
      status: 210,
      sku: 'PM0045979',
      core_name: 'Plant',
      sppl_ean: '8720664549777',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054690',
      rtl_batch_array: '6054690',
      name_search: 'HOGMARQU',
      name: "Hosta 'Grand Marquee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 613,
      chnn_stock_retail: 613,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T5J3JR1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5J3JR1K'
    },
    {
      status: 210,
      sku: 'PM0077719',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348777',
      rtl_batch_array: '4348777',
      name_search: 'EDI74677',
      name: "Tulipa (sea) 'White Prince'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLGZHSVL;W7JLVKSY;8A3XZL45;N9NCZPGE;W1YECS1X;JVTXZ9XB;CEKZEL22;CLTB3ZNH;YJ8RRHE1;62BN7VW1;ZXSJHJ15;BJY374RD;6JDG8TC1;ED63259B;16PH9XJP;6YTATE8H',
      statusContent: 'dot',
      hash: 'TKD15WWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKD15WWR'
    },
    {
      status: 210,
      sku: 'PM0081254',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339749',
      rtl_batch_array: '6339749, 6345190',
      name_search: 'ASAEMURA',
      name: "Aster ageratoides 'Ezo Murazaki'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 329,
      chnn_stock_retail: 1606,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TSNWA93X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSNWA93X'
    },
    {
      status: 210,
      sku: 'PM0058154',
      core_name: 'Plant',
      sppl_ean: '8720664850330',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112313',
      rtl_batch_array: '6112313, 6274821',
      name_search: 'ACMLBEAU',
      name: "Achillea millefolium 'Lilac Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1467,
      chnn_stock_retail: 1868,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XA2C52C9;AEJDW1J3;6Y73KDBG;PW37RW41;HRTTXPLH;2AV4S713;JL7ZTZY2;L3L6X8GR',
      statusContent: 'dot',
      hash: 'L91P2357',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L91P2357'
    },
    {
      status: 210,
      sku: 'PM0077692',
      core_name: 'Plant',
      sppl_ean: '8719223013729',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348622',
      rtl_batch_array: '4348622',
      name_search: 'ALKARATA',
      name: 'Allium karataviense',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 26.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YR8CLCSH;DH1RNWG7;63SKSEZA;4S9ZNPKV;V5VW2JX8;ERA7VY38;WHPLPKKA;NKJB2WP4;KG1PKZY9',
      imageBatch: 'R1L7NRE6',
      statusContent: 'dot',
      hash: 'N7R7AKAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7R7AKAC'
    },
    {
      status: 210,
      sku: 'PM0077694',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348624',
      rtl_batch_array: '4348624',
      name_search: 'ALAFLATU',
      name: 'Allium aflatunense',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 99979,
      chnn_stock_retail: 99979,
      sppl_prcp: 20.404,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R5LKRYCG;7XV8VNYE;1ZDCG3KT;VZ2BZD15;252G186W;BLTD3XG5;8ZDG84RB;V2XGHRD8',
      statusContent: 'dot',
      hash: 'P1TGBNX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1TGBNX1'
    },
    {
      status: 210,
      sku: 'PM0077695',
      core_name: 'Plant',
      sppl_ean: '8720664658479',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348626',
      rtl_batch_array: '4348626',
      name_search: 'ALSCHUBE',
      name: 'Allium schubertii',
      sppl_size_code: '012014BOP01',
      rng_range_identifier: 'BU\u00d81201425PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 5,
      sppl_prcp: 13.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZGTJAA4G;GSKX5EVS;WR1KNT5W;PKCTYAB3;YT9ZNKKJ;8N666NZV;53N5WKW9;K22T7L1D;EWCN9XYZ',
      imageBatch: '243KWKJ5',
      statusContent: 'dot',
      hash: '94VGE9HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94VGE9HZ'
    },
    {
      status: 210,
      sku: 'PM0077696',
      core_name: 'Plant',
      sppl_ean: '8719223015747',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348629',
      rtl_batch_array: '4348629',
      name_search: 'LEAGGIAN',
      name: "Leucojum aestivum 'Gravetye Giant'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 25.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y4XPDRVR;8KP7PSN2;G4PYEG4A;ECDV54GV;XC1DP4VA;9XXKA84N;B2ZXR111;JA92GVC5;B6C9TWTP;R7T588SA;569TDSNC;T1SNBA13;L3TC1HLV;LB99183X;XPZ3WD7K;XHHYWRAW;SJSGANN7;HTXHEVDJ;5PGBTR7R;PPWSWNLD;RLL4L9Z1;VW3GJYJZ;XKSZYBSD',
      imageBatch: 'D1T1CT9V',
      statusContent: 'dot',
      hash: 'CV9136XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV9136XA'
    },
    {
      status: 210,
      sku: 'PM0077697',
      core_name: 'Plant',
      sppl_ean: '8719223013835',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348632',
      rtl_batch_array: '4348632',
      name_search: 'ALOREOPH',
      name: 'Allium oreophilum',
      sppl_size_code: '004005BOP10',
      rng_range_identifier: 'BU\u00d84005100PCS',
      rng_range_description: 'Bulb \u00d8 4 cm 5 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 5.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HLE6YDTC;WVRDX7DH;C198EL8H',
      imageBatch: 'JRZKHK5C',
      statusContent: 'dot',
      hash: 'EXD1NCTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXD1NCTN'
    },
    {
      status: 210,
      sku: 'PM0057342',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339860',
      rtl_batch_array: '6339860, 6348385',
      name_search: 'NEDROPMO',
      name: "Nepeta 'Dropmore'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1426,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E1ET24P;7SL41J55;2DHTW6S2',
      statusContent: 'dot',
      hash: 'CLACEW7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CLACEW7K'
    },
    {
      status: 910,
      sku: 'PM0060092',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345520',
      rtl_batch_array: '6345520',
      name_search: 'PINPYRAM',
      name: "Pinus nigra 'Pyramidalis'",
      sppl_size_code: '060080C20',
      rng_range_identifier: 'H060080C20',
      rng_range_description: 'H60 cm 80 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 42.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2PHJWH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2PHJWH8'
    },
    {
      status: 210,
      sku: 'PM0066403',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315067',
      rtl_batch_array: '6230987, 6315067, 6353319',
      name_search: 'SANBLAUH',
      name: "Salvia nemorosa 'Blauh\u00fcgel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7326,
      chnn_stock_retail: 7932,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BT2VVV4H;5CBR2TAT;AXBKARR4;7K45XA55;VA1738SY',
      statusContent: 'dot',
      hash: 'PZKGRKTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZKGRKTA'
    },
    {
      status: 810,
      sku: 'PM0038933',
      core_name: 'Plant',
      sppl_ean: '8720664680067',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054335',
      rtl_batch_array: '6054335',
      name_search: 'EDI81858',
      name: "Allium 'Miami'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CXD6JNP;L97GZ72K',
      statusContent: 'dot',
      hash: 'T363X8ZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T363X8ZZ'
    },
    {
      status: 210,
      sku: 'PM0077698',
      core_name: 'Plant',
      sppl_ean: '8719223014801',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348634',
      rtl_batch_array: '4348634',
      name_search: 'ERCILICI',
      name: 'Eranthis cilicica',
      sppl_size_code: '004005BOP10',
      rng_range_identifier: 'BU\u00d84005100PCS',
      rng_range_description: 'Bulb \u00d8 4 cm 5 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D6YHZ3HZ;4DBZ73A5;LCJ87BKB;18N6C28T;BRGAGTAT;TL9S37AT;TEZJ2LD8;N647DELR;HVDH6161;6ECGHJJE;J48LGBBW;ZBYG5GND;8L5VNY6X;LESN5CBL;ZJD1LNYD;6BRYW73Y;KJCR9YPE;EH23YRTH;AZCJDY17;NZ7D8XWB;H44SSDZ5;SL1Y8TLT',
      imageBatch: 'DCPPRVB7',
      statusContent: 'dot',
      hash: 'BB5X97HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BB5X97HZ'
    },
    {
      status: 210,
      sku: 'PM0077699',
      core_name: 'Plant',
      sppl_ean: '8719223015181',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348642',
      rtl_batch_array: '4348642',
      name_search: 'GANIVALI',
      name: 'Galanthus nivalis',
      sppl_size_code: '005006BOP10',
      rng_range_identifier: 'BU\u00d85006100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm 6 cm 100 Pieces',
      sppl_stock_available: 99931,
      chnn_stock_retail: 99931,
      sppl_order_minimum: 1,
      sppl_prcp: 23.401,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CBJ63N1B;9SR6NDJ2;XD9SB7PD;4JYPA2GN;XKS3RLEC;LCYC7LZZ;1ZECXBDJ;6J5TSDT5;2ZCJP2ZB;62REBP8T;T5DLDW96;ER1RDBE8;NLW5K748;R6XGC977;6WAV3KJL;X7G7B6XW;S3HH6LXD;GYWG5BPZ;9751J454;7KZYK3V7;5HZ368T2;N1B8X53C;D1YJ8XRB;LA15VJKY',
      imageBatch: '6W4AR9P8',
      statusContent: 'dot',
      hash: 'H8KHBSVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8KHBSVX'
    },
    {
      status: 210,
      sku: 'PM0077701',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348653',
      rtl_batch_array: '4348653',
      name_search: 'ANBWSPLE',
      name: "Anemone blanda 'White Splendour'",
      sppl_size_code: '005007BOP10',
      rng_range_identifier: 'BU\u00d85007100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KHH99PV;1THKPLPB;KBDEGR68;V6V17V8A;ZTDLGEL4;EVGANSG2;P5H9BBE2;68YGP52T;4J83XDCK;A8KK7BV2;PNGB7HRG;N31ET92B;LGBHCSCJ;2GGZNRNP;7ZNZJ57V;B948GXEY;SZL691ZX;XPDV1Z2P;Z6XVW4Z6;4HJGNJV4;1KAYRK8C;8WNPPA93;WL51W79T',
      imageBatch: 'KALBHZ9Y',
      statusContent: 'dot',
      hash: '9VXCW5WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VXCW5WH'
    },
    {
      status: 210,
      sku: 'PM0045990',
      core_name: 'Plant',
      sppl_ean: '8720664549951',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015349',
      rtl_batch_array: '6015349',
      name_search: 'HORCADET',
      name: "Hosta 'Red Cadet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12P1N9HB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12P1N9HB'
    },
    {
      status: 210,
      sku: 'PM0077702',
      core_name: 'Plant',
      sppl_ean: '8719223015020',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348679',
      rtl_batch_array: '4348679',
      name_search: 'FRMELEAG',
      name: 'Fritillaria meleagris',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 99998,
      chnn_stock_retail: 99998,
      sppl_order_minimum: 1,
      sppl_prcp: 10.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S89WLXGN;APB63JVT;1ZVHK6VC;12B33VCK;KXEJE71P;D6LBHAXD;NCLT1WG7;KYWYLH7V',
      imageBatch: 'NK512XTP',
      statusContent: 'dot',
      hash: 'D57LX65X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D57LX65X'
    },
    {
      status: 210,
      sku: 'PM0077703',
      core_name: 'Plant',
      sppl_ean: '8719223015129',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348681',
      rtl_batch_array: '4348681',
      name_search: 'GAELWESI',
      name: 'Galanthus elwesii',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_order_minimum: 1,
      sppl_prcp: 17.851,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P9V99151;VYNPG5VZ;BT45693R;8Y5LR8BS;84AZJRZ4;R98D7YAG;V1YA41D8;L8NC9AP8;9VV8ZN53;PWH6XXA1;CR1CK3YP;HTHY9HNL;HCDY6BCL;K3N1J8G9;S9S12D5Z',
      imageBatch: 'JA23XKGW',
      statusContent: 'dot',
      hash: '1R8BY4SB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R8BY4SB'
    },
    {
      status: 210,
      sku: 'PM0077707',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348706',
      rtl_batch_array: '4348706',
      name_search: 'CRFRECOR',
      name: 'Crocus Flower Record (LF)',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 99994,
      chnn_stock_retail: 99994,
      sppl_prcp: 11.051,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8VDXBYE;BC3TEAH9;7B7EEW63;B89L3386;12NWKHVR;Z12L9RS5;LNWTYA21;15135RY2;EEYAH2LK;BP3TK6XH;Y4NJS2JZ;XNC3JE27;2L1YVXV6;ZEYA3XGE;L3VZJXBX;C3H5BCDE;L6P2X55Y;WHZ82KVC;7VW5TNZK;44T57JAY;H5A8TP2N;RJ2B5VY7;D3LJ2HRN;STX8BT9P;J1VEKS7L;16LPAC78;KWWNKJX2;2XLPYCBV;VP4GXE2G;9AJEYDPJ',
      statusContent: 'dot',
      hash: 'RPPALB36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPPALB36'
    },
    {
      status: 210,
      sku: 'PM0077712',
      core_name: 'Plant',
      sppl_ean: '8719223015341',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348741',
      rtl_batch_array: '4348741',
      name_search: 'HYHMIX',
      name: 'Hyacinthoides hispanica Mix',
      sppl_size_code: '008010BOP10',
      rng_range_identifier: 'BU\u00d88010100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 10 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.401,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '51XPSZRN;KT6123PY;XA1X48N6',
      statusContent: 'dot',
      hash: '1ZWZGXD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZWZGXD5'
    },
    {
      status: 210,
      sku: 'PM0077716',
      core_name: 'Plant',
      sppl_ean: '8719223015723',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348751',
      rtl_batch_array: '4348751',
      name_search: 'LEAESTIV',
      name: 'Leucojum aestivum',
      sppl_size_code: '010012BOP10',
      rng_range_identifier: 'BU\u00d810012100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '51WRVKST;5DHT7Z8X;747NWWWP;5SYH9KJJ;87NDGTXC;NTSHZS3E;1H9L4HLV;CDY73EJ5;KALRAXSG;NSGWRNZH;7XZDTEAD;JEKJJDH7;236ZXSY5;693ZDREZ;SVGW6693;SRTWL7JY',
      imageBatch: '5X9CKE6E',
      statusContent: 'dot',
      hash: 'Y93XSCYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y93XSCYC'
    },
    {
      status: 210,
      sku: 'PM0058162',
      core_name: 'Plant',
      sppl_ean: '8720664852457',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112327',
      rtl_batch_array: '6112327, 6301444',
      name_search: 'ASWLBLUE',
      name: "Aster 'Wood's Light Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 454,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WCTZSDWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCTZSDWR'
    },
    {
      status: 210,
      sku: 'PM0063505',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253091',
      rtl_batch_array: '6253091',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: '9Y2NRXAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Y2NRXAY'
    },
    {
      status: 210,
      sku: 'PM0057971',
      core_name: 'Plant',
      sppl_ean: '8720664881709',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084677',
      rtl_batch_array: '6084677',
      name_search: 'PRGBPINK',
      name: "Prunella grandiflora 'Bella Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3789,
      chnn_stock_retail: 3789,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SSS79YTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSS79YTY'
    },
    {
      status: 210,
      sku: 'PM0057343',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339861',
      rtl_batch_array: '6339861',
      name_search: 'NESENIOR',
      name: "Nepeta 'Senior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y4CS5AY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4CS5AY4'
    },
    {
      status: 210,
      sku: 'PM0058363',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302636',
      rtl_batch_array: '6208017, 6302636',
      name_search: 'KAMMJARD',
      name: "Kalimeris mongolica 'Mon Jardin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 109,
      chnn_stock_retail: 397,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GH4ECLSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GH4ECLSA'
    },
    {
      status: 210,
      sku: 'PM0045977',
      core_name: 'Plant',
      sppl_ean: '8720664549746',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015331',
      rtl_batch_array: '6015331',
      name_search: 'HOFGTOMA',
      name: "Hosta 'Fried Green Tomatoes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SVNWC24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SVNWC24'
    },
    {
      status: 810,
      sku: 'PM0066402',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6173059',
      rtl_batch_array: '6173059',
      name_search: 'CHAGOLD',
      name: "Choisya 'Aztec Gold'",
      sppl_size_code: '030040C10',
      rng_range_identifier: 'H030040C10',
      rng_range_description: 'H30 cm 40 cm C10',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRBZPJR;CXYAP33L;XPZ2STEH;XPGTVKWE;PPEXNEP2;VWDVY9VZ;8SJ52K9W;TSYKT4G3;G7BP1HLT;AZNGTT5W;VJA6VVVS;94869PBK;SBBXWYRB;WVLC9ZH3;J1BTEDCK;S41XBA3T;VC113LYZ;9XE14T7S',
      statusContent: 'dot',
      hash: 'H1V1KBP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1V1KBP9'
    },
    {
      status: 210,
      sku: 'PM0034119',
      core_name: 'Plant',
      sppl_ean: '8720664680999',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866457',
      rtl_batch_array: '5866457',
      name_search: 'ARFHAIR',
      name: "Aruncus 'Fairy Hair'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 536,
      chnn_stock_retail: 536,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K48LTLH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K48LTLH5'
    },
    {
      status: 210,
      sku: 'PM0038932',
      core_name: 'Plant',
      sppl_ean: '8720664680012',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054320',
      rtl_batch_array: '6054320',
      name_search: 'EDI61732',
      name: "Allium 'Firmament'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NHNGW89B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHNGW89B'
    },
    {
      status: 210,
      sku: 'PM0058368',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350609',
      rtl_batch_array: '6350609',
      name_search: 'KNPCITRO',
      name: 'Kniphofia Poco Citron',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZBH56DZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZBH56DZ'
    },
    {
      status: 210,
      sku: 'PM0069091',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6211041',
      rtl_batch_array: '6211041',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 274,
      chnn_stock_retail: 274,
      sppl_prcp: 8.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'SYXLSSZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYXLSSZ8'
    },
    {
      status: 210,
      sku: 'PM0084899',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369659',
      rtl_batch_array: '6369659',
      name_search: 'COACSENS',
      name: "Cordyline australis 'Cherry Sensation'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7BPXRTXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BPXRTXK'
    },
    {
      status: 210,
      sku: 'PM0074221',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268712',
      rtl_batch_array: '6268712, 6334766',
      name_search: 'CAOEVERL',
      name: "Carex oshimensis 'Everlime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 247,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZX98YK1S;H2NY3RBY;4KAZZC91;83DYE284;5REY1D6C;KCJR1S1R;EBNLS9CG;42XWE7YH;LZ8ZLXEG;CHAW489E',
      statusContent: 'dot',
      hash: 'CJ7DEE45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJ7DEE45'
    },
    {
      status: 810,
      sku: 'PM0058527',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6187610',
      rtl_batch_array: '6187610, 6386163',
      name_search: 'PHDPINK',
      name: "Phlox (S) 'Drummond's Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 556,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ES1W2Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ES1W2Y3'
    },
    {
      status: 810,
      sku: 'PM0060156',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301509',
      rtl_batch_array: '6301509',
      name_search: 'CHBIENCH',
      name: "Chrysanthemum (I) 'Bienchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '624ZN5GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '624ZN5GL'
    },
    {
      status: 210,
      sku: 'PM0077547',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304921',
      rtl_batch_array: '6304921',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 918,
      chnn_stock_retail: 918,
      sppl_prcp: 3.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: 'PJ9RSY6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJ9RSY6W'
    },
    {
      status: 210,
      sku: 'PM0046114',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6305178',
      rtl_batch_array: '5364244, 6350538, 6305178',
      name_search: 'HOWBRIM',
      name: "Hosta 'Wide Brim'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 371,
      chnn_stock_retail: 1053,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W124CX91;D8NJKA3N;77X215VX;H1PESLVT;VRTKZ3VW;D7CN85W7;9YA6BWD4;2HK6C5AR;WSCTS22K;SVEK1872;XX9JLLWJ;ESSVBVHC;25LWTEKL;4V4D9XHW;SPE83D3Z',
      statusContent: 'dot',
      hash: '89NWEJBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89NWEJBY'
    },
    {
      status: 210,
      sku: 'PM0077548',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304922',
      rtl_batch_array: '6304922',
      name_search: 'WEBWHITE',
      name: 'Weigela Black and White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z8S4PTXR',
      statusContent: 'dot',
      hash: 'NZPE7YHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZPE7YHP'
    },
    {
      status: 210,
      sku: 'PM0077549',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304923',
      rtl_batch_array: '6304923',
      name_search: 'WEFPPOPP',
      name: 'Weigela florida Pink Poppet',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 801,
      chnn_stock_retail: 801,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '82AVJC8V;4377G18A;5GNRP32G;ADET5YSY;1AXEDRH2;23BGCZJY;82EVNTAV',
      statusContent: 'dot',
      hash: 'XX5CJ4D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX5CJ4D7'
    },
    {
      status: 210,
      sku: 'PM0024937',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284503',
      rtl_batch_array: '4305054, 6284503',
      name_search: 'ACGOGON',
      name: "Acorus gramineus 'Ogon'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1772,
      chnn_stock_retail: 2181,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XEX4W2P1;7B3N2R24;HXBERTTP;PV39NESW;E264R1NJ;R5PEL6CS;PE7Z6R3T;V4T6VD5X',
      statusContent: 'dot',
      hash: 'N2Y8X4TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2Y8X4TW'
    },
    {
      status: 810,
      sku: 'PM0081255',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349764',
      rtl_batch_array: '6339751, 6349764',
      name_search: 'ASAKGEOR',
      name: "Aster amellus 'King George'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 1884,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9L6X2LDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L6X2LDE'
    },
    {
      status: 210,
      sku: 'PM0077551',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304925',
      rtl_batch_array: '6304925',
      name_search: 'CHONGRAC',
      name: "Chamaecyparis obtusa 'Nana Gracilis'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3022,
      chnn_stock_retail: 3022,
      sppl_prcp: 3.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1HJET36;XWH728VL;5XNYWHYN;PVNJ4EWY;E1AS7L2X;2J1E1CX4;HPJH6N7P;ZBV2G5VZ;7LB298WC',
      statusContent: 'dot',
      hash: 'R9SW4E65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9SW4E65'
    },
    {
      status: 210,
      sku: 'PM0068627',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307534',
      rtl_batch_array: '6307534',
      name_search: 'LOPWBEAU',
      name: "Lonicera purpusii 'Winter Beauty'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 688,
      chnn_stock_retail: 688,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AGVLPZXY',
      statusContent: 'dot',
      hash: 'SZKW6WA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZKW6WA1'
    },
    {
      status: 210,
      sku: 'PM0063429',
      core_name: 'Plant',
      sppl_ean: '8720349404704',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4276381',
      rtl_batch_array: '4276381',
      name_search: 'DECGOLDT',
      name: "Deschampsia cespitosa 'Goldtau'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7ZNEJDA;S4JYADT8',
      imageBatch: 'HAY2613T',
      statusContent: 'dot',
      hash: 'G27YWG4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G27YWG4V'
    },
    {
      status: 810,
      sku: 'PM0081256',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339752',
      rtl_batch_array: '6370326, 6339752',
      name_search: 'ASAMIRA',
      name: "Aster amellus 'Mira'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G75PG3V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G75PG3V1'
    },
    {
      status: 210,
      sku: 'PM0046132',
      core_name: 'Plant',
      sppl_ean: '8720353027166',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015385',
      rtl_batch_array: '6015385',
      name_search: 'JOHIRTA',
      name: 'Jovibarba hirta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V96XZ1BG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V96XZ1BG'
    },
    {
      status: 210,
      sku: 'PM0077552',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304926',
      rtl_batch_array: '6304926',
      name_search: 'CHTTPOIN',
      name: "Chamaecyparis thyoides 'Top Point'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 12200,
      chnn_stock_retail: 12200,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JKC3AP;6NKLZA4S;8RXGWSTS;7LH83EY6;8K7EZDJR;ELV9WX61;8PPJ43P4;TC9E3BHB',
      statusContent: 'dot',
      hash: 'P62X8WZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P62X8WZK'
    },
    {
      status: 810,
      sku: 'PM0081257',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349767',
      rtl_batch_array: '6339755, 6349767',
      name_search: 'ASFWSTAE',
      name: "Aster frikartii 'Wunder von St\u00e4fa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 401,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E4LD6DK',
      statusContent: 'dot',
      hash: '5YA7DZ69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YA7DZ69'
    },
    {
      status: 210,
      sku: 'PM0070957',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307563',
      rtl_batch_array: '6307563',
      name_search: 'MASASUPE',
      name: "Magnolia soulangeana 'Alba Superba'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_prcp: 12.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZJ839J8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ839J8K'
    },
    {
      status: 210,
      sku: 'PM0077553',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304927',
      rtl_batch_array: '6304927',
      name_search: 'CRJCOMPR',
      name: "Cryptomeria japonica 'Compressa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 930,
      chnn_stock_retail: 930,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTRW44XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTRW44XA'
    },
    {
      status: 210,
      sku: 'PM0084900',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369660',
      rtl_batch_array: '6369660',
      name_search: 'FAJSWEB',
      name: "Fatsia japonica 'Spider's Web'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.259,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3B94AJ2;NKD557B2;X4KAN56K;Y3RNSPWK;8TED5GNZ;Y68T7YTZ;HWNG8EZT;EW9BY6TD;XC4DAZXW;CPZBWXY9;ZDVS9LBL;GWTWBRV2;NAB5S1ZA;VP8A4ZS6;JK7VTB2C;C4B6PJLY;WX9SSXS3',
      statusContent: 'dot',
      hash: 'CSNGK8YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSNGK8YR'
    },
    {
      status: 910,
      sku: 'PM0081258',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASJBBBEA',
      name: "Aster 'JS Barts Blue Beauty'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'TT1VY2YX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TT1VY2YX'
    },
    {
      status: 210,
      sku: 'PM0046821',
      core_name: 'Plant',
      sppl_ean: '8720664691186',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054640',
      rtl_batch_array: '6054640',
      name_search: 'GETYELLO',
      name: 'Geum Tempo Yellow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VTKZNH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VTKZNH5'
    },
    {
      status: 210,
      sku: 'PM0046817',
      core_name: 'Plant',
      sppl_ean: '8720626300354',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6053860',
      rtl_batch_array: '6053860, 6301344',
      name_search: 'FRSTICKB',
      name: "Fragaria 'Stickbolwi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YBLHTSW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBLHTSW2'
    },
    {
      status: 210,
      sku: 'PM0077554',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304928',
      rtl_batch_array: '6304928',
      name_search: 'CRJYOKOH',
      name: "Cryptomeria japonica 'Yokohama'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X546HZJP;E39TP22A;KT47L89S;AJNXVDWC;RNWPNDE8;XCRRG7TG;YE6394HL;1H5S76ES;K2CD2PZC',
      statusContent: 'dot',
      hash: '15D18Z72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15D18Z72'
    },
    {
      status: 210,
      sku: 'PM0077555',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304930',
      rtl_batch_array: '6304930',
      name_search: 'PICGDECE',
      name: "Picea glauca 'December'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 11043,
      chnn_stock_retail: 11043,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'STGBPS4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STGBPS4A'
    },
    {
      status: 210,
      sku: 'PM0077556',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304931',
      rtl_batch_array: '6304931',
      name_search: 'PICGDECE',
      name: "Picea glauca 'December'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6350,
      chnn_stock_retail: 6350,
      sppl_order_minimum: 3,
      sppl_prcp: 2.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7J773P9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7J773P9'
    },
    {
      status: 210,
      sku: 'PM0077720',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348779',
      rtl_batch_array: '4348779',
      name_search: 'EDI61394',
      name: "Tulipa (trh) 'Yokohama'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APKSH8DY;2X2PHJD6;1KBVJLWD;KG7XSDZ4;TSXTAY3V;GRGXS3EB;8N224RR7;YVLD11WK;ZTGHCKT5;ADNDZK9S;B3BGEWW5;2LBSB8R7;5GPW1KD5;XE661NAA',
      statusContent: 'dot',
      hash: 'DACWEPX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DACWEPX4'
    },
    {
      status: 210,
      sku: 'PM0077721',
      core_name: 'Plant',
      sppl_ean: '8719223002105',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348784',
      rtl_batch_array: '4348784',
      name_search: 'TUFOXTRO',
      name: 'Tulipa Foxy Foxtrot',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7LHE2RS5',
      statusContent: 'dot',
      hash: 'PLZXLJSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLZXLJSD'
    },
    {
      status: 210,
      sku: 'PM0063516',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272823',
      rtl_batch_array: '6272823',
      name_search: 'HEALBICA',
      name: 'Hebe albicans',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1680,
      chnn_stock_retail: 1680,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TZS7YL6H;BEEEXX1B;V8SD7NR9;SWDSA3HL;RLPH2D7R;JB39NE6N;KVVE9GTP;XYN52PN4;XCDS8YDC;7YXAY939;YDZHXEC8;BHXLV5W7;4VBJ8VG8;NT3B5V7P;9CB28JNA',
      statusContent: 'dot',
      hash: 'G6S1ZE3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6S1ZE3K'
    },
    {
      status: 810,
      sku: 'PM0070960',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307580',
      rtl_batch_array: '6307580',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 16.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'WJ8LBH51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJ8LBH51'
    },
    {
      status: 210,
      sku: 'PM0077722',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348792',
      rtl_batch_array: '4348792',
      name_search: 'EDI60818',
      name: "Tulipa (dea) 'Monte Carlo'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN7E5CCJ;2919V23L;DL4XRH72;X57RJ46A;ANW4B3P9;93G2BDLW;N7AN4E4R;TYRHB9RV;9ZWWXVJ5;TWYDLD1C;TBBKLBRT;4P925XGD;V6KAA3CS',
      statusContent: 'dot',
      hash: 'X5VA5RK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5VA5RK5'
    },
    {
      status: 810,
      sku: 'PM0081259',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349745',
      rtl_batch_array: '6349745',
      name_search: 'ANHBGLOW',
      name: "Anemone hybrida 'Bressingham Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YJR11Y9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YJR11Y9'
    },
    {
      status: 910,
      sku: 'PM0081260',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASPMAJOR',
      name: "Aster ptarmicoides 'Major'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '53JP357R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '53JP357R'
    },
    {
      status: 810,
      sku: 'PM0058436',
      core_name: 'Plant',
      sppl_ean: '8720664801158',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112754',
      rtl_batch_array: '6112754',
      name_search: 'PECCHPIN',
      name: "Penstemon 'Cha Cha Hot Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '14VCLGCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14VCLGCK'
    },
    {
      status: 210,
      sku: 'PM0077723',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348804',
      rtl_batch_array: '4348804',
      name_search: 'EDI60199',
      name: "Tulipa (trh) 'Calgary'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 16.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5YEE3PHW;8KCLD3TY;VTX6ZDP3;CAXD1T4V;XRXG3H3G;DVACTNTT;T7TKC5T5;94ZP7AXX;25LKB88Z;YEX4S58T;2NHG2X4Z;B57W2W2H',
      statusContent: 'dot',
      hash: '68N3DC31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68N3DC31'
    },
    {
      status: 810,
      sku: 'PM0063419',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228444',
      rtl_batch_array: '6228444',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'N925SS1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N925SS1T'
    },
    {
      status: 210,
      sku: 'PM0085087',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371044',
      rtl_batch_array: '6371044',
      name_search: 'GESMAYFL',
      name: "Geranium sylvaticum 'Mayflower'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2603,
      chnn_stock_retail: 2603,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L56NEW3J;CWS1PP3Z;TH17XXNA;WGSJJVYY;ASDJRHG6',
      statusContent: 'dot',
      hash: 'W57R9CGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W57R9CGC'
    },
    {
      status: 210,
      sku: 'PM0077724',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348809',
      rtl_batch_array: '4348809',
      name_search: 'EDI60344',
      name: "Tulipa (trh) 'Don Quichotte'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XS419TEX;9BWC1YSH;8PZ8EVLB;7BSZAR4G;KWG57VTG;HHGJR1BN;DRR5ZYX5;YBD8K7TC;NAA2ZG31;84EP8PGT;NBY6824D;BCXBDT55;7416LG8J;JB1YGB8A;TGWC13LW;L2HV5XAN',
      statusContent: 'dot',
      hash: 'SCRVW594',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCRVW594'
    },
    {
      status: 210,
      sku: 'PM0077725',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348815',
      rtl_batch_array: '4348815',
      name_search: 'TUFRANCO',
      name: 'Tulipa Francoise',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GEGT99A;V6E9C9KR',
      statusContent: 'dot',
      hash: 'Z8HSLL2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8HSLL2A'
    },
    {
      status: 210,
      sku: 'PM0038376',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 3,
      btch_active_retail: '6268491',
      rtl_batch_array: '6209134, 6200451, 6268491',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 8004,
      sppl_prcp: 4.952,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '6BZP681L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BZP681L'
    },
    {
      status: 210,
      sku: 'PM0077726',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348820',
      rtl_batch_array: '4348820',
      name_search: 'EDI66307',
      name: "Tulipa (trh) 'Grand Perfection'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WZ78VXJ7;9ZWWHP8G;R872SJ62;CH4EV19L;BSZLRSD6;WPKATLX7;BG3925G4;GW5X645L;S1LCRSGV;YS5ES4NX;G3HDTH9Y;PAWL1AV8;HX7P48A5',
      statusContent: 'dot',
      hash: 'ZEB47PTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZEB47PTX'
    },
    {
      status: 210,
      sku: 'PM0065054',
      core_name: 'Plant',
      sppl_ean: '8720664851481',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161491',
      rtl_batch_array: '6161491',
      name_search: 'ALSPPLAN',
      name: "Allium senescens 'Pink Planet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EXK4NSKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXK4NSKP'
    },
    {
      status: 210,
      sku: 'PM0077727',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348839',
      rtl_batch_array: '4348839',
      name_search: 'EDI60844',
      name: "Tulipa (trh) 'Negrita'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A5284K8S;47LAYPVD;7KHCSXSK;D4B19EWV;B9YR63PZ;SJVG26VB;ASXGG35D;2S97Y375;LDVTJZRP;H3A25LTW;GKYXYBSK;PSHKD29G;V3ZVAAAG;ZETCXVEN;BSBL5654;AA9CWG2Z;4X4Y1H1W;C6BPSD68;CCLP9AW9;16ERVKB9;LSVJSKPG;EYHE7WEZ;ZT2NTEL6;T8H8VK73;WTN13KLG',
      statusContent: 'dot',
      hash: '8ZA3D3CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZA3D3CJ'
    },
    {
      status: 210,
      sku: 'PM0046796',
      core_name: 'Plant',
      sppl_ean: '8720626390744',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014519',
      rtl_batch_array: '6302394, 6014519',
      name_search: 'ASSAMOA',
      name: "Aster 'Samoa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X2LBT83L;4ZS6C3NA;HH1ZELK6;NRR7ZNCG;BEJA8ZEV;9C15CXXT;4Z4BLHW5',
      statusContent: 'dot',
      hash: 'G88K2P9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G88K2P9E'
    },
    {
      status: 210,
      sku: 'PM0077736',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348919',
      rtl_batch_array: '4348919',
      name_search: 'CRGMAITR',
      name: 'Crocus Grand Maitre (LF)',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 11.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZH7XWEN1;21XZVTD6;DG5DG92Y;TJ8WJD4L;J8WHDG71;ALZCW1CY;1K6HDA67;1X2BB31X;9BEW5SZ4;DVNBL37L;BZ6VDS4N;VGZP498Z;6CRKJTYL;H2PTRH79;CEAPDYH8;CR4VSEZP;WB4B719T;GCEJHZP2',
      statusContent: 'dot',
      hash: 'AKRA76V8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKRA76V8'
    },
    {
      status: 210,
      sku: 'PM0077728',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348863',
      rtl_batch_array: '4348863',
      name_search: 'TUTRIUMP',
      name: 'Tulipa Triumph mixed',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XR4YSNEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XR4YSNEG'
    },
    {
      status: 210,
      sku: 'PM0077729',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348873',
      rtl_batch_array: '4348873',
      name_search: 'EDI60128',
      name: "Tulipa (dar) 'Beauty of Apeldoorn'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5EKNCSCP;6WN12CV6',
      statusContent: 'dot',
      hash: 'ACX157EY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACX157EY'
    },
    {
      status: 210,
      sku: 'PM0077730',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348876',
      rtl_batch_array: '4348876',
      name_search: 'EDI60316',
      name: "Tulipa (dar) 'Daydream'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYHPK2W5;T77NZ4TL;ZJ78S76C;GV21D1YT;A17YXKED;KYAT5LR6;L1WGXAEC;N8D8XR59;RGRY5N62',
      statusContent: 'dot',
      hash: 'DL63YRJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL63YRJ7'
    },
    {
      status: 210,
      sku: 'PM0077731',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348892',
      rtl_batch_array: '4348892',
      name_search: 'EDI66025',
      name: "Tulipa (dar) 'Red Impression'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NAHLP5KL;8HX6P53C;XPLZKJ96;J1AAGHKE;Y1CB6KGV;6E5RCN2D;WBE8ZBZS;ED4WXXWJ;4X2WL61S;TBBDVNGB;C5GZS7T7;HLT995DH',
      statusContent: 'dot',
      hash: 'ELCPAACB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELCPAACB'
    },
    {
      status: 210,
      sku: 'PM0070963',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307541',
      rtl_batch_array: '6307541',
      name_search: 'MABYBIRD',
      name: "Magnolia brooklynensis 'Yellow Bird'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 223,
      chnn_stock_retail: 223,
      sppl_prcp: 28.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '257BR9SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '257BR9SK'
    },
    {
      status: 210,
      sku: 'PM0077732',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348905',
      rtl_batch_array: '4348905',
      name_search: 'EDI60173',
      name: "Tulipa (sla) 'Blushing Lady'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKP9P2JC;PXN65PHH;D12N9D99;RPEVA5Y8;4DRYKP25;X3AVCSSX;RHS8DNJE;ALCRT511;ZY7YJZT2;BX7GEL9K;PKWSP34D;1S4DH2BR;YNCAWKK5;DAVTCTC2',
      statusContent: 'dot',
      hash: '6E4A2D2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6E4A2D2G'
    },
    {
      status: 210,
      sku: 'PM0077733',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348906',
      rtl_batch_array: '4348906',
      name_search: 'EDI70216',
      name: "Tulipa (sla) 'Clearwater'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4GYX5JNZ;G31SB9SL;6XA6NDBC;W5JSZ78N;ABWYTAJ5',
      statusContent: 'dot',
      hash: 'GTTB6NAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTTB6NAY'
    },
    {
      status: 210,
      sku: 'PM0057359',
      core_name: 'Plant',
      sppl_ean: '8720664876958',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133470',
      rtl_batch_array: '6133470, 6242178',
      name_search: 'PEAWESER',
      name: "Pennisetum alopecuroides 'Weserbergland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 188,
      chnn_stock_retail: 778,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AEC9Y447',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEC9Y447'
    },
    {
      status: 210,
      sku: 'PM0045622',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350021',
      rtl_batch_array: '6350021',
      name_search: 'ECGSKIPP',
      name: "Echinacea 'Golden Skipper'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EB6GRX1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB6GRX1E'
    },
    {
      status: 210,
      sku: 'PM0070964',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307542',
      rtl_batch_array: '6307542',
      name_search: 'MABYBIRD',
      name: "Magnolia brooklynensis 'Yellow Bird'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 247,
      chnn_stock_retail: 247,
      sppl_prcp: 34.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SALE29LY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SALE29LY'
    },
    {
      status: 210,
      sku: 'PM0077734',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348916',
      rtl_batch_array: '4348916',
      name_search: 'EDI60793',
      name: "Tulipa (sla) 'Menton'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH9NA13S;JBSPZ52A;TRYPXZDB;PE4WPRKJ;674WAP3E;CNBY2579;6LT7RL6T;LRY5AJZ2;7WGT7D1N;8JDHVKP8;T963B445;DXY2YDJK;8PBKKNRY;VXLDNLE6;R3TTGX73;X6VDNRDR;2LH6S64B;8A3Y66S5;6EBASKB9;464WD3JX;CRGXTG19',
      statusContent: 'dot',
      hash: 'DC3D1N5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC3D1N5T'
    },
    {
      status: 210,
      sku: 'PM0077735',
      core_name: 'Plant',
      sppl_ean: '8719223005366',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348918',
      rtl_batch_array: '4348918',
      name_search: 'TUQNIGHT',
      name: 'Tulipa Queen of Night',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 11.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S9L7TG5R;ZT4LJR2A;19K3T6KK;NZT7ZCWB;2W2R2XBB;YWY4X9H4;ZXXCZKA7;D7XSZ5ZP;BN2KNRXZ;V3BVNSLZ;99JB94HZ;SX9DLRRK;GRBASNW7;EESXH6NB;632SCRGK;L7757NNH;LWV9N45A;KKG2JT4B;JSBP6GV9;RZ3CBETG;WD6L55YS;5KPZ1X6H;7GH5R61K;XXTBCJH5',
      statusContent: 'dot',
      hash: 'R1LX4PT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1LX4PT6'
    },
    {
      status: 910,
      sku: 'PM0070958',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338282',
      rtl_batch_array: '6307574, 6338282',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 19,
      chnn_stock_retail: 461,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'JKSRN418',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKSRN418'
    },
    {
      status: 810,
      sku: 'PM0081261',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339760',
      rtl_batch_array: '6339760',
      name_search: 'ASSERICE',
      name: 'Aster sericeus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BXT2NTYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXT2NTYA'
    },
    {
      status: 210,
      sku: 'PM0077740',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348931',
      rtl_batch_array: '4348931',
      name_search: 'CRDMIX',
      name: 'Crocus Dutch Mixed (LF)',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 11.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'TNZ241DE',
      statusContent: 'dot',
      hash: 'G6BH8DZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6BH8DZ7'
    },
    {
      status: 210,
      sku: 'PM0077741',
      core_name: 'Plant',
      sppl_ean: '8719223015785',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348936',
      rtl_batch_array: '4348936',
      name_search: 'MUARMENI',
      name: 'Muscari armeniacum',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 6.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '55GVYWZC',
      statusContent: 'dot',
      hash: 'S41HPP31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S41HPP31'
    },
    {
      status: 210,
      sku: 'PM0077742',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348969',
      rtl_batch_array: '4348969',
      name_search: 'EDI60153',
      name: "Tulipa (par) 'Black Parrot'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YHRCYCN4;7ZJSH656;YNBNY186;SH9K428P;2JYCPHDV;T49DNKT3;99XTWRAK;85H32KLW;A9CTPZ5L;CBRV4KDG;NDZS93NE;T4YRWZSL;89VLRXLD;ZD69XTXB;DPEK8YHT;16XYWBZ8;Y3KPR49V',
      statusContent: 'dot',
      hash: 'XL2TAAX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL2TAAX7'
    },
    {
      status: 210,
      sku: 'PM0077743',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348975',
      rtl_batch_array: '4348975',
      name_search: 'EDI61088',
      name: "Tulipa (par) 'Rococo'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PAPTNJN5;4W8HJ4R5;CDPASGSZ;XV55LY12;ZXJLS1E2;EDB47B3N;ZV93L7L1;S6LAS8WJ;BGYTV1GJ;1K78J3W5;A2SXNWXG;48TN8HKS;81YYB7CY;277KLNCZ;8KNAS8LD;V2Y4RPR9;LZ6JT8K9',
      statusContent: 'dot',
      hash: '7629713T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7629713T'
    },
    {
      status: 210,
      sku: 'PM0077744',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348977',
      rtl_batch_array: '4348977',
      name_search: 'TUANGELI',
      name: 'Tulipa Angelique',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_order_minimum: 1,
      sppl_prcp: 17.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8Y3H4VK;1XVGYHJG;E12NP72E;VY1WJW62;NZ794JBG;G8XL8XZG;XVGCPATB;HNP63DG5;1ZWH1N1B;88CHJ6AC;D9KY34VV;B37SE9BN;RJLN6RD4;K9N41SST;GJ279DTT;VT86SCES;B9NAJRK8;R7NLRHST;DPXKYP8L;28KPG77K',
      statusContent: 'dot',
      hash: 'AE47NJGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AE47NJGN'
    },
    {
      status: 210,
      sku: 'PM0077745',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348979',
      rtl_batch_array: '4348979',
      name_search: 'EDI60152',
      name: "Tulipa (dla) 'Black Hero'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 23.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJ1G6736;A1B7VED2;E816TBCL;G2JSXKTY;CB3RHG7H;XX94ZLA4;Z49AZ7XW;ZBPNKCPN;T4WHPLVK;222PYC7N;JNLP6WRA;LK327DE6;G36BKRHA;4BVNK1H3;E1594S4C;PK2NK4AD;B4B6K92X;TSBLWBAA',
      statusContent: 'dot',
      hash: 'DXYAC3HE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXYAC3HE'
    },
    {
      status: 210,
      sku: 'PM0081262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339761',
      rtl_batch_array: '6339761, 6352617',
      name_search: 'ASTHOMSO',
      name: 'Aster thomsonii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 684,
      chnn_stock_retail: 733,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TRAVW49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TRAVW49'
    },
    {
      status: 210,
      sku: 'PM0046070',
      core_name: 'Plant',
      sppl_ean: '8720626350724',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5972575',
      rtl_batch_array: '5972575, 6329959, 6291708',
      name_search: 'HEPFEU',
      name: "Heuchera 'Pluie de Feu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4124,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPCB6CR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPCB6CR1'
    },
    {
      status: 210,
      sku: 'PM0058530',
      core_name: 'Plant',
      sppl_ean: '8720626362154',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495463',
      rtl_batch_array: '5495463',
      name_search: 'PHMAISCH',
      name: "Phlox (S) 'Maischnee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EHJTZJ31;L7BP2D9G;CPJRRCAX;AH934LSA;YHA7SELJ;TJJ1WAE4;WKSLJZDL;9A3T2WZY',
      statusContent: 'dot',
      hash: 'C55XRXBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C55XRXBE'
    },
    {
      status: 210,
      sku: 'PM0077746',
      core_name: 'Plant',
      sppl_ean: '8719223007766',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348980',
      rtl_batch_array: '4348980',
      name_search: 'TUBDIAMO',
      name: 'Tulipa Blue Diamond',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W4J63WC6;JG1YDZZD;8C88RVZP;SXP1V8JS;ASDBN9YB;5HA546ZE;LLNDCJER',
      statusContent: 'dot',
      hash: '9HBJP6GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HBJP6GL'
    },
    {
      status: 210,
      sku: 'PM0077747',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348985',
      rtl_batch_array: '4348985',
      name_search: 'EDI60830',
      name: "Tulipa (dla) 'Mount Tacoma'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'REX4PV98;E47PC9NK;GD3WRH9N;XY5VZPJ3;GYNAXJ6J;NAVC47E1',
      statusContent: 'dot',
      hash: 'HRZE4NS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRZE4NS2'
    },
    {
      status: 210,
      sku: 'PM0077748',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348989',
      rtl_batch_array: '4348989',
      name_search: 'EDI60880',
      name: "Tulipa (dla) 'Orange Princess'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 20.703,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4TZXZY5L;N7DTAY57;4T6PT7ZY;9JJHAKVB;75L3873K;WKYS77YY;R3G35TT8;66JJATAN;TRD7VTNC;YZVY67RY;4BBAGJPJ;NHE23ARG;BSE48HYX;6LD3VSCY;28RB7V5H;YTHHLBJP;EBT1RGK5;RS9P1ET6;J1HZPDXH;YT1RXLNG;25P4WX43',
      statusContent: 'dot',
      hash: 'X1L5DRNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1L5DRNT'
    },
    {
      status: 210,
      sku: 'PM0077749',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348996',
      rtl_batch_array: '4348996',
      name_search: 'EDI60578',
      name: "Tulipa (kau) 'Heart's Delight'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T9WR3YP7;Y8HX86VY;A167HLBP;KA79SZA6;DLZSK2AE;9191J1A6;K4X8R6GB;A2DK1TPV;K2359VHT;7789J2GG;B5P3R6C6;24P2PLRX;E7SLZCYG;G6DVBG3K;L6YE1V55',
      statusContent: 'dot',
      hash: 'LR9WRTKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LR9WRTKW'
    },
    {
      status: 210,
      sku: 'PM0045637',
      core_name: 'Plant',
      sppl_ean: '8720664860537',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147761',
      rtl_batch_array: '6147761, 6301546',
      name_search: 'ECMWHITE',
      name: "Echinacea 'Meditation White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 491,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZXD9VJRR;8HWJ5WG2;NE86278R;45LTEL5B;D11ZTLV5;6JP7XG7W;432EP68S;94A4VWKZ;19D322YK',
      statusContent: 'dot',
      hash: 'N95EW6RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N95EW6RA'
    },
    {
      status: 210,
      sku: 'PM0077750',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349019',
      rtl_batch_array: '4349019',
      name_search: 'EDI60876',
      name: "Tulipa (fos) 'Orange Emperor'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XHN9E7DB;7HEPX6X8;YNVJ48NY;47SXD7Y6;1B1YZJ3T;P3SVG9WA;81L715DC;X7ECP39Z;9H1JKYXD;DLTN7YXW;J5WNDLXA;JG312EAZ;ANWJEH9A;ZVLWC9TN;C7VAD1JP;ZK5N46R7;X14STE8T;XRB5R3Z8;TLTC3HGS',
      statusContent: 'dot',
      hash: 'H4B181GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4B181GH'
    },
    {
      status: 210,
      sku: 'PM0077751',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349023',
      rtl_batch_array: '4349023',
      name_search: 'EDI61021',
      name: "Tulipa (fos) 'Purissima'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NLVC41AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLVC41AW'
    },
    {
      status: 210,
      sku: 'PM0077752',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349029',
      rtl_batch_array: '4349029',
      name_search: 'EDI60208',
      name: "Tulipa (gri) 'Cape Cod'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JTKES6ZH;WYYR5CGC;SNTSR3NH;BS2CZNXG;GDCD44V8;XN9S619C;DE241Z41;2ZSDHGCZ;NTBTAXVW',
      statusContent: 'dot',
      hash: 'V2Y411KZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2Y411KZ'
    },
    {
      status: 210,
      sku: 'PM0077753',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349032',
      rtl_batch_array: '4349032',
      name_search: 'EDI60544',
      name: "Tulipa (gri) 'Grand Prestige'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A48NCZZE;DN2JVNZ3;YJ4HYREE;VLY31TRH;PSD82GXW;VZH8312J;GKJX2WE4',
      statusContent: 'dot',
      hash: '7PLG5WWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PLG5WWY'
    },
    {
      status: 210,
      sku: 'PM0077755',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349043',
      rtl_batch_array: '4349043',
      name_search: 'EDI81858',
      name: "Allium 'Miami'",
      sppl_size_code: '010+BOP100',
      rng_range_identifier: 'BU\u00d810+100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CXD6JNP;L97GZ72K',
      statusContent: 'dot',
      hash: 'LPXR79HV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPXR79HV'
    },
    {
      status: 210,
      sku: 'PM0081263',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339762',
      rtl_batch_array: '6339762',
      name_search: 'ASMDWHIT',
      name: 'Astrantia maj. Diamonds White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWVGXRS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWVGXRS5'
    },
    {
      status: 210,
      sku: 'PM0081264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339763',
      rtl_batch_array: '6339763',
      name_search: 'BOPYGMAE',
      name: 'Borago pygmaea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H455PXYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H455PXYP'
    },
    {
      status: 810,
      sku: 'PM0081265',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339765',
      rtl_batch_array: '6339765, 6321929',
      name_search: 'BRMLIMOU',
      name: "Briza media 'Limouzi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 61,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZCXKWX9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCXKWX9N'
    },
    {
      status: 210,
      sku: 'PM0081266',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339766',
      rtl_batch_array: '6339766',
      name_search: 'ASNBROSA',
      name: "Aster novi-belgii 'Rosaperle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C92BGETE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C92BGETE'
    },
    {
      status: 210,
      sku: 'PM0077760',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349080',
      rtl_batch_array: '4349080',
      name_search: 'EDI62993',
      name: "Colchicum autumnale 'Alboplenum'",
      sppl_size_code: '013+BOP10',
      rng_range_identifier: 'BU\u00d813+10PCS',
      rng_range_description: 'Bulb \u00d8 13 cm + 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K5RSWW45;XRX8D5JN;NLL57KTG;1PG92538',
      statusContent: 'dot',
      hash: 'L742A8J9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L742A8J9'
    },
    {
      status: 810,
      sku: 'PM0045628',
      core_name: 'Plant',
      sppl_ean: '8720664688087',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6090947',
      rtl_batch_array: '6147757, 6090947',
      name_search: 'ECPJIPOE',
      name: "Echinacea p. 'JS Ivo's Poem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CN18E53G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CN18E53G'
    },
    {
      status: 210,
      sku: 'PM0077737',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348921',
      rtl_batch_array: '4348921',
      name_search: 'CRJARC',
      name: "Crocus Jeanne d'Arc (LF)",
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 99988,
      chnn_stock_retail: 99988,
      sppl_prcp: 11.138,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HGVRV1Y4;X3Y2VTKP;L7NCJ2VD;195H3VYZ;ZAJDA79P;DYSJ1D77;6RRR3SH9;YHR6Z9YZ;LK996D3L;6712DKCY;SD11C5DX;PGZL49BX;63CJG4E5;RXT3R3EE;NZT4KRKB;RNHL5EDJ;DB4AXW85;AAWD2926;WETCHPTS;WXXR99JR;SVGN3R7L;86XREW14',
      statusContent: 'dot',
      hash: 'XXHEB81S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXHEB81S'
    },
    {
      status: 210,
      sku: 'PM0077738',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348923',
      rtl_batch_array: '4348923',
      name_search: 'CRPICKWI',
      name: 'Crocus Pickwick (LF)',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTC35AHN;E5ZLYDX8;GALSBXLJ;LD5B4Y43;VCVLE5G2;K3BD787X;2VT1HYHJ;4LA9TAJY;J17DNLTS;7BN21NGK;TTLTPJ3T;PTLV11N3;5VHX9X36;TERLNBDL;XHLBBX6R;GCR9EJTE;CBSV5L6X;7PE9SJ86;ER4LJY52;56W2PWLX;ETCC4XJD;T7WG296S;B1WTX79S;9TD6XW1T;ZZV5WEER;RC3RGXJ5;PJTEZ1G9;9YPB7XJ5;S91EJ4CJ;WADC55PH',
      statusContent: 'dot',
      hash: 'YJ73SE12',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ73SE12'
    },
    {
      status: 210,
      sku: 'PM0077739',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348927',
      rtl_batch_array: '4348927',
      name_search: 'CRREMEMB',
      name: 'Crocus Remembrance (LF)',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PSS8P2WW;4NY2BZWH;43ZJ6HJK;2WZGGP69;6YNKAJTX;9BNG3NWP;RSVXDTSJ;76WEZX8C;VEH1YPNL;28BJJKRW',
      statusContent: 'dot',
      hash: 'HBBRTCPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBBRTCPG'
    },
    {
      status: 210,
      sku: 'PM0070962',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307537',
      rtl_batch_array: '6307537',
      name_search: 'MABETTY',
      name: "Magnolia 'Betty'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 253,
      chnn_stock_retail: 253,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12YLB58;V48KACWP',
      statusContent: 'dot',
      hash: '9VRND5VV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VRND5VV'
    },
    {
      status: 210,
      sku: 'PM0077754',
      core_name: 'Plant',
      sppl_ean: '8719223013521',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349042',
      rtl_batch_array: '4349042',
      name_search: 'ALATROPU',
      name: 'Allium atropurpureum',
      sppl_size_code: '010+BOP100',
      rng_range_identifier: 'BU\u00d810+100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 27.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A64RDCET;WK745ZCS;SA7NZ88W;48KP1TAG',
      imageBatch: 'VHBVYA5P',
      statusContent: 'dot',
      hash: '5GLXR2XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GLXR2XA'
    },
    {
      status: 810,
      sku: 'PM0070959',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307579',
      rtl_batch_array: '6307579',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'EJCEW38X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJCEW38X'
    },
    {
      status: 210,
      sku: 'PM0077757',
      core_name: 'Plant',
      sppl_ean: '8719223013804',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349070',
      rtl_batch_array: '4349070',
      name_search: 'ALNIGRUM',
      name: 'Allium nigrum',
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 23.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G2H89JRE;R6ZDH2WV;9G38N8AD;1KSB86KW;CZZDNLTN;1PDNEBX4;5H6K34DB;K5ZXX2C8',
      imageBatch: 'LX33GE7H',
      statusContent: 'dot',
      hash: 'E2XJA2L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2XJA2L6'
    },
    {
      status: 210,
      sku: 'PM0077758',
      core_name: 'Plant',
      sppl_ean: '8719223013965',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349074',
      rtl_batch_array: '4349074',
      name_search: 'ALSDRUMM',
      name: "Allium 'Summer Drummer'",
      sppl_size_code: '012+BOP25',
      rng_range_identifier: 'BU\u00d812+25PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWVV43R2;K58GX18E',
      imageBatch: 'VYBBSGNY',
      statusContent: 'dot',
      hash: 'Z7S3AZC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7S3AZC2'
    },
    {
      status: 210,
      sku: 'PM0077759',
      core_name: 'Plant',
      sppl_ean: '8719223013323',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349078',
      rtl_batch_array: '4349078',
      name_search: 'COAUTUMN',
      name: 'Colchicum autumnale',
      sppl_size_code: '013+BOP10',
      rng_range_identifier: 'BU\u00d813+10PCS',
      rng_range_description: 'Bulb \u00d8 13 cm + 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 11.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XTG67LHH;C8TB97ZR;BSA5T3L9',
      imageBatch: 'WWARSHLW',
      statusContent: 'dot',
      hash: 'PVLA381H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVLA381H'
    },
    {
      status: 210,
      sku: 'PM0077557',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304932',
      rtl_batch_array: '6304932',
      name_search: 'POLRTIP',
      name: "Podocarpus lawrencei 'Red Tip'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1854,
      chnn_stock_retail: 1854,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H1Z3VAYG;W5LY52K8;1N4LVHZ7;LPY7GLE3;VZE8KTD5',
      statusContent: 'dot',
      hash: 'EHS59L6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHS59L6A'
    },
    {
      status: 210,
      sku: 'PM0068689',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207767',
      rtl_batch_array: '6207767',
      name_search: 'AJIBENIG',
      name: "Ajuga incisa 'Blue Enigma'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 464,
      chnn_stock_retail: 464,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBKK2VZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBKK2VZX'
    },
    {
      status: 210,
      sku: 'PM0054341',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307567',
      rtl_batch_array: '6307567, 6287489',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 507,
      chnn_stock_retail: 513,
      sppl_prcp: 12.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'KNEVYGW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNEVYGW6'
    },
    {
      status: 210,
      sku: 'PM0058540',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353105',
      rtl_batch_array: '6353105',
      name_search: 'PHSPURPU',
      name: "Phuopsis stylosa 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 912,
      chnn_stock_retail: 912,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YN6TESTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN6TESTE'
    },
    {
      status: 210,
      sku: 'PM0070010',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382290',
      rtl_batch_array: '6382290, 6291715',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15290,
      chnn_stock_retail: 20779,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'JJ3878CV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJ3878CV'
    },
    {
      status: 210,
      sku: 'PM0046102',
      core_name: 'Plant',
      sppl_ean: '8720664871946',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161809',
      rtl_batch_array: '6161809, 6301684',
      name_search: 'LESEROTI',
      name: 'Leucanthemella serotina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8D29J5KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8D29J5KY'
    },
    {
      status: 210,
      sku: 'PM0054739',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345212',
      rtl_batch_array: '6080789, 6345212',
      name_search: 'TROCHROL',
      name: 'Trifolium ochroleucon',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 427,
      chnn_stock_retail: 658,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZ1NLGSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ1NLGSY'
    },
    {
      status: 810,
      sku: 'PM0070008',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217168',
      rtl_batch_array: '6217168, 6350365',
      name_search: 'HEMESBRE',
      name: "Hemerocallis 'Sunny Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 788,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TNBJA78H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNBJA78H'
    },
    {
      status: 210,
      sku: 'PM0054731',
      core_name: 'Plant',
      sppl_ean: '8720664886599',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133728',
      rtl_batch_array: '6133728',
      name_search: 'SOSGFLEE',
      name: "Solidago sphacelata 'Golden Fleece'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JZV6V2HK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZV6V2HK'
    },
    {
      status: 910,
      sku: 'PM0069094',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      rng_range_identifier: 'H040050C7',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'D2TXHZTC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D2TXHZTC'
    },
    {
      status: 210,
      sku: 'PM0068624',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293875',
      rtl_batch_array: '6293875',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 12.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: '61HHZSV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61HHZSV4'
    },
    {
      status: 910,
      sku: 'PM0052511',
      core_name: 'Plant',
      sppl_ean: '8720664696099',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6000138',
      rtl_batch_array: '6000138',
      name_search: 'LADDYNAM',
      name: 'Lagerstroemia i. Double Dynamite',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'REAGLA6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REAGLA6G'
    },
    {
      status: 210,
      sku: 'PM0047902',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196752',
      rtl_batch_array: '6196752',
      name_search: 'DESUTHER',
      name: 'Delosperma sutherlandii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9N8ANP8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N8ANP8C'
    },
    {
      status: 910,
      sku: 'PM0046822',
      core_name: 'Plant',
      sppl_ean: '8720664691926',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6035496',
      rtl_batch_array: '6035496, 6352642',
      name_search: 'HEODEWSP',
      name: "Helleborus orientalis 'Double Ellen White Spotted'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EY53DCJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY53DCJG'
    },
    {
      status: 210,
      sku: 'PM0046790',
      core_name: 'Plant',
      sppl_ean: '8720353000527',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054353',
      rtl_batch_array: '6054353',
      name_search: 'ALSMONTA',
      name: 'Allium senescens montanum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R4SP5Y3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4SP5Y3G'
    },
    {
      status: 210,
      sku: 'PM0046133',
      core_name: 'Plant',
      sppl_ean: '8720664695504',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054710',
      rtl_batch_array: '6054710',
      name_search: 'JOHPURPU',
      name: "Jovibarba hirta 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YP8TBRTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YP8TBRTG'
    },
    {
      status: 810,
      sku: 'PM0068630',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 4,
      btch_active_retail: '6276345',
      rtl_batch_array: '6299744, 6276345, 6307626, 6305947',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 4521,
      sppl_prcp: 7.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'Y822ADRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y822ADRH'
    },
    {
      status: 210,
      sku: 'PM0068631',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298779',
      rtl_batch_array: '6298779',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '120STC5',
      rng_range_identifier: 'STE120C5',
      rng_range_description: 'Stem 120 cm C5',
      sppl_stock_available: 795,
      chnn_stock_retail: 795,
      sppl_prcp: 6.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'B73X1KBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B73X1KBC'
    },
    {
      status: 210,
      sku: 'PM0081267',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339769',
      rtl_batch_array: '6339769',
      name_search: 'CAPTBLUE',
      name: "Campanula persicifolia 'Takion Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 553,
      chnn_stock_retail: 553,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GVGNJAZP;JB52GBR5',
      statusContent: 'dot',
      hash: 'J7SGK33B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7SGK33B'
    },
    {
      status: 210,
      sku: 'PM0077764',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349092',
      rtl_batch_array: '4349092',
      name_search: 'EDI77046',
      name: "Allium 'Forelock'",
      sppl_size_code: '014+BOP25',
      rng_range_identifier: 'BU\u00d814+25PCS',
      rng_range_description: 'Bulb \u00d8 14 cm + 25 Pieces',
      sppl_stock_available: 99990,
      chnn_stock_retail: 99990,
      sppl_prcp: 34.206,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TN6RX1SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TN6RX1SS'
    },
    {
      status: 210,
      sku: 'PM0045748',
      core_name: 'Plant',
      sppl_ean: '8720349491957',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6015078',
      rtl_batch_array: '6015078, 6355360',
      name_search: 'GEASCLEP',
      name: 'Gentiana asclepiadea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 996,
      chnn_stock_retail: 1098,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7D1G618',
      statusContent: 'dot',
      hash: 'JH336KBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH336KBC'
    },
    {
      status: 210,
      sku: 'PM0077768',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349109',
      rtl_batch_array: '4349109',
      name_search: 'EDI61438',
      name: "Hyacinthus orientalis 'Carnegie'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97TAL4H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97TAL4H9'
    },
    {
      status: 210,
      sku: 'PM0068693',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207775',
      rtl_batch_array: '6207775',
      name_search: 'ACMCHRIS',
      name: "Achillea millefolium 'Christel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9A1ATZ97',
      statusContent: 'dot',
      hash: '4B914K2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B914K2G'
    },
    {
      status: 210,
      sku: 'PM0066437',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184091',
      rtl_batch_array: '6184091',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'RS63HL9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS63HL9N'
    },
    {
      status: 210,
      sku: 'PM0068696',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6380828',
      rtl_batch_array: '6380828',
      name_search: 'ACMWESER',
      name: "Achillea millefolium 'Wesersandstein'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 303,
      chnn_stock_retail: 303,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2W8BWD78',
      statusContent: 'dot',
      hash: 'ZXTN9DCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXTN9DCG'
    },
    {
      status: 210,
      sku: 'PM0077769',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349113',
      rtl_batch_array: '4349113',
      name_search: 'EDI61448',
      name: "Hyacinthus orientalis 'Delft Blue'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRC4LG9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRC4LG9V'
    },
    {
      status: 210,
      sku: 'PM0077770',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349115',
      rtl_batch_array: '4349115',
      name_search: 'EDI61459',
      name: "Hyacinthus orientalis 'Gipsy Queen'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PWVW6ZNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWVW6ZNW'
    },
    {
      status: 210,
      sku: 'PM0070011',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295910',
      rtl_batch_array: '6220947, 6295910',
      name_search: 'VISALBIF',
      name: "Viola sororia 'Albiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 748,
      chnn_stock_retail: 2888,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7J19ZK9R;VDG89871;YGTE5CSJ;7YGXJSNA;DHRVVAXJ;2YK6PVDZ;RC76GTZA;V9KRWYLK;7HR1XHDH;9XCCK24H;YLNDV85B',
      statusContent: 'dot',
      hash: '8X7BL5WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8X7BL5WG'
    },
    {
      status: 910,
      sku: 'PM0045711',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIGBOMBA',
      name: "Dianthus gratianopolitanus 'Bombardier'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BY7LXV3J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BY7LXV3J'
    },
    {
      status: 210,
      sku: 'PM0077771',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349119',
      rtl_batch_array: '4349119',
      name_search: 'EDI61501',
      name: "Hyacinthus orientalis 'Pink Pearl'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2E7GZJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2E7GZJD'
    },
    {
      status: 210,
      sku: 'PM0068647',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307794',
      rtl_batch_array: '6307794',
      name_search: 'VACG23',
      name: "Vaccinium corymbosum 'Goldtraube 23'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2032,
      chnn_stock_retail: 2032,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L51RCC81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L51RCC81'
    },
    {
      status: 210,
      sku: 'PM0085088',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371140',
      rtl_batch_array: '6371140',
      name_search: 'ALSPOETR',
      name: "Alcalthaea suffrutescens 'Poetry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 251,
      chnn_stock_retail: 251,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1XYCYCZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XYCYCZR'
    },
    {
      status: 910,
      sku: 'PM0069095',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'THDACBNA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'THDACBNA'
    },
    {
      status: 210,
      sku: 'PM0077772',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349121',
      rtl_batch_array: '4349121',
      name_search: 'EDI61504',
      name: "Hyacinthus orientalis 'Pink Surprise'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5DE64WHT;GT5KKR5H',
      statusContent: 'dot',
      hash: '6WXZA55W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WXZA55W'
    },
    {
      status: 210,
      sku: 'PM0077773',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349123',
      rtl_batch_array: '4349123',
      name_search: 'EDI67056',
      name: "Hyacinthus orientalis 'Purple Sensation'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V1Z6XDVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1Z6XDVD'
    },
    {
      status: 210,
      sku: 'PM0070965',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307543',
      rtl_batch_array: '6307543',
      name_search: 'MAGALAXY',
      name: "Magnolia 'Galaxy'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D9H8P365',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9H8P365'
    },
    {
      status: 210,
      sku: 'PM0077774',
      core_name: 'Plant',
      sppl_ean: '8719223005526',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349138',
      rtl_batch_array: '4349138',
      name_search: 'TUSORBET',
      name: 'Tulipa Sorbet mix',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 16.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CHZ24SGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHZ24SGN'
    },
    {
      status: 810,
      sku: 'PM0052854',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370360',
      rtl_batch_array: '6370360',
      name_search: 'FRCCHAVA',
      name: "Fragaria chiloensis 'Chaval'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DY7Y78WZ',
      statusContent: 'dot',
      hash: '2E4EEHCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E4EEHCP'
    },
    {
      status: 210,
      sku: 'PM0057414',
      core_name: 'Plant',
      sppl_ean: '8720664884519',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133608',
      rtl_batch_array: '6369253, 6133608',
      name_search: 'SAFORRER',
      name: 'Salvia forreri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 178,
      chnn_stock_retail: 418,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P2J19H5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2J19H5N'
    },
    {
      status: 210,
      sku: 'PM0045799',
      core_name: 'Plant',
      sppl_ean: '8720664688964',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014841',
      rtl_batch_array: '6014841',
      name_search: 'ERPMSYMP',
      name: 'Eryngium planum Magical Symphony',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KYGNTZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KYGNTZW'
    },
    {
      status: 210,
      sku: 'PM0077775',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349139',
      rtl_batch_array: '4349139',
      name_search: 'EDI60114',
      name: "Tulipa (lif) 'Ballerina'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X1R6WS6J;9JKVSVCK;H774JTP8;TVLNJTG2;74KY6EGH;T7V82A3S;JCKJ8XBA;XNRGBCYW;YW74C9AW;NE5D222D;8K8P3D89;CAD7HGCY;VSDG8TSY;5X385XJ5;CV9JPBHP;Y7VYBX4Y;CDY43BSV;17P7DZEZ;GLD7AW7A',
      statusContent: 'dot',
      hash: 'VJAYE6DR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJAYE6DR'
    },
    {
      status: 210,
      sku: 'PM0077776',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349152',
      rtl_batch_array: '4349152',
      name_search: 'EDI79193',
      name: "Tulipa (lif) 'Merlot'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 16.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WKX64PC;HCSNV1NL;6KSL1AGL;VAB8DK27',
      statusContent: 'dot',
      hash: 'YRZ85ZE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRZ85ZE6'
    },
    {
      status: 210,
      sku: 'PM0053377',
      core_name: 'Plant',
      sppl_ean: '8720349483020',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053960',
      rtl_batch_array: '6053960',
      name_search: 'ACMCANDL',
      name: "Acanthus 'Morning's Candle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3556,
      chnn_stock_retail: 3556,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RTTHYZ71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTTHYZ71'
    },
    {
      status: 210,
      sku: 'PM0077777',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349156',
      rtl_batch_array: '4349156',
      name_search: 'TULPDREA',
      name: 'Tulipa Purple Dream',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3L116R4;TRWX8WVD;X5G848E7;D8SA6LDA;B4KV9L37;D2N5AS24;6TY6NG87;NRNKLY83;S6N3K52N;ZV9YJVEA;REEYRSA7;SLEAWT6R;WPLLDCZR;X5DXV67S;PGPHSPXH;Y1AAS2T1;2KV86KH2;RRW9NLSW;J16SH6G5;SE6VLRHJ;4J56VSLT;KVT566V4',
      statusContent: 'dot',
      hash: 'YR13J14N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YR13J14N'
    },
    {
      status: 210,
      sku: 'PM0077778',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349168',
      rtl_batch_array: '4349168',
      name_search: 'EDI61702',
      name: "Fritillaria imperialis 'Premier'",
      sppl_size_code: '020024BOP10',
      rng_range_identifier: 'BU\u00d82002410PCS',
      rng_range_description: 'Bulb \u00d8 20 cm 24 cm 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 27.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVCBDKZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVCBDKZJ'
    },
    {
      status: 810,
      sku: 'PM0045786',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301324',
      rtl_batch_array: '6350129, 6301324',
      name_search: 'ERTRICHO',
      name: 'Eragrostis trichodes',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 1282,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CNGB8W6;9HLDV3TC;GSBJCBRH;CT5CERJ9;8Z9ZTD8E;D1AXPCHX',
      statusContent: 'dot',
      hash: '5A7RT9S7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5A7RT9S7'
    },
    {
      status: 210,
      sku: 'PM0077779',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349171',
      rtl_batch_array: '4349171',
      name_search: 'EDI61680',
      name: "Fritillaria imperialis 'Aurora'",
      sppl_size_code: '024+BOP10',
      rng_range_identifier: 'BU\u00d824+10PCS',
      rng_range_description: 'Bulb \u00d8 24 cm + 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.135,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z583N57Z;R55NWYXZ;S7J1SNEL;R2G46HYE;9LP2K513;V2E5R6K4;92S6SAV7;NPLA7REW',
      statusContent: 'dot',
      hash: 'TZZ5CKCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZZ5CKCJ'
    },
    {
      status: 210,
      sku: 'PM0077780',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349175',
      rtl_batch_array: '4349175',
      name_search: 'EDI61707',
      name: "Fritillaria imperialis 'Rubra Maxima'",
      sppl_size_code: '024+BOP10',
      rng_range_identifier: 'BU\u00d824+10PCS',
      rng_range_description: 'Bulb \u00d8 24 cm + 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.035,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6TLXNLTK;WTTH7K7X;KV2H258B;CB2Y6YNA;N67LEXDE;1H219YWE;T7PLHELY;5TTNRNWG',
      imageBatch: 'NXWTL8PL',
      statusContent: 'dot',
      hash: 'XR27PJK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XR27PJK8'
    },
    {
      status: 810,
      sku: 'PM0068651',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307832',
      rtl_batch_array: '6307832',
      name_search: 'FAROBUST',
      name: 'Fargesia robusta',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 8.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '29R2K8TC',
      statusContent: 'dot',
      hash: 'HHH8XLC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHH8XLC7'
    },
    {
      status: 210,
      sku: 'PM0077781',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349195',
      rtl_batch_array: '4349195',
      name_search: 'EDI61544',
      name: 'Crocus angustifolius',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.901,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y8YAYR6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8YAYR6Y'
    },
    {
      status: 210,
      sku: 'PM0009019',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6353277',
      rtl_batch_array: '5902404, 6353277, 6333304, 6352667',
      name_search: 'ROAESCUL',
      name: 'Rodgersia aesculifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2289,
      chnn_stock_retail: 5246,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XW9JNYXW;YDHR7WLJ;ZKREDZ92;9Y1VRZ5W;N1LJS7HT',
      statusContent: 'dot',
      hash: 'PTYSPWBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTYSPWBE'
    },
    {
      status: 210,
      sku: 'PM0064400',
      core_name: 'Plant',
      sppl_ean: '8720664855311',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151509',
      rtl_batch_array: '6151509',
      name_search: 'CAFFALLS',
      name: "Carex 'Feather Falls'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SWTEBKB2;L2483766;SP761YTY;76N9NLAJ;CY3EZYXS;GN3N9S8T;PTBBRL6A;4WP8RTZK;PY2L3NWL;56GRS1YN;VVVTBCA6;4S3RAXZ6;54D1RL41;SKA1G2BS',
      statusContent: 'dot',
      hash: '64W33X8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64W33X8V'
    },
    {
      status: 210,
      sku: 'PM0004592',
      core_name: 'Plant',
      sppl_ean: '8720349412075',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '4496049',
      rtl_batch_array: '4496049, 6142249',
      name_search: 'CAOEVERI',
      name: "Carex oshimensis 'Everillo'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 542,
      sppl_order_minimum: 3,
      sppl_prcp: 1.586,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V56NDZA1;14BTWSPK;GYJ6D49L;CS4EYZDJ;Y5ZY5GR8;2379TG2W;JWS9HHXC;JLXPTH2P;KZ9L7A9S;67879P5Y',
      imageBatch: 'RY61NTB7',
      statusContent: 'dot',
      hash: '71XNPNDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71XNPNDX'
    },
    {
      status: 810,
      sku: 'PM0085089',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371141',
      rtl_batch_array: '6371141',
      name_search: 'ALTCDOVE',
      name: 'Allium tuberosum Cliffs of Dover',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N87XP4CL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N87XP4CL'
    },
    {
      status: 210,
      sku: 'PM0077782',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349199',
      rtl_batch_array: '4349199',
      name_search: 'EDI61545',
      name: "Crocus chrysanthus 'Ard Schenk'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G4SRNKW1;CHJG5KWK;VXLGX7DA;HTTDX81D;HLNPN51A;JBVD3JBY;4ACST4XN;DRRP9LGE;85GAW4VJ;VYVL3LL7;YLCKTGE4;GW7VBVZB;N2EZSNJ8;A1JT7K12;JN5LP22D;AX94ZG6A;S631DD47;G25116Z3;NXBAZNSD;9LW6B2LJ;4GGJBZNJ;7BDAE2CH;T6C39GA4',
      statusContent: 'dot',
      hash: '5BVLAAYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BVLAAYJ'
    },
    {
      status: 810,
      sku: 'PM0070966',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307544',
      rtl_batch_array: '6307544',
      name_search: 'MAGALAXY',
      name: "Magnolia 'Galaxy'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_prcp: 19.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6H9A9WG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H9A9WG9'
    },
    {
      status: 210,
      sku: 'PM0077783',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349201',
      rtl_batch_array: '4349201',
      name_search: 'EDI61557',
      name: "Crocus chrysanthus 'Blue Pearl'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 99990,
      chnn_stock_retail: 99990,
      sppl_prcp: 9.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BNDLZ6BN;AELT9TE4;N7H55G32;DXY7E1E4;BP1P4J44;7LL5W2L3;WPXZLCCK;GH494K8J;4SDTX26C;6E55GT7Y;DXYZX23A;WYYDRDCY;7WCXKAV1;63N6N6CX;GEGVJPC4;DLDJBV2D;GAG3AGEA;J159YCD2;VHTHRZ2C;KE5TYDVK;L3WR6CG4;XGVZRRDS;A2RY5PTC;J9WBHRWW;6RSVTHAP;SLRGBB6H;D8PVJ37C;C4CGSAEC;4YKG31SC;J7GG8GTP',
      statusContent: 'dot',
      hash: '5NV77DS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NV77DS3'
    },
    {
      status: 210,
      sku: 'PM0045762',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242150',
      rtl_batch_array: '6301356, 6242150',
      name_search: 'GECWESTR',
      name: "Geranium cantabrigiense 'Westray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RPWBKH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RPWBKH4'
    },
    {
      status: 810,
      sku: 'PM0081268',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339770',
      rtl_batch_array: '6339770',
      name_search: 'CAPSBELL',
      name: "Campanula punctata 'Silver Bells'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '49YTGYZ3;1GDP7EXX;TL8EEP6X;G2XA2X6Z',
      statusContent: 'dot',
      hash: 'DELNP2E8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DELNP2E8'
    },
    {
      status: 210,
      sku: 'PM0074222',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6369723',
      rtl_batch_array: '6369723, 6268714, 6320839',
      name_search: 'ASMCBUTT',
      name: 'Astrantia maj. Cerise Button',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 414,
      chnn_stock_retail: 612,
      sppl_order_minimum: 3,
      sppl_prcp: 1.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E97AJEAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E97AJEAD'
    },
    {
      status: 210,
      sku: 'PM0077784',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349203',
      rtl_batch_array: '4349203',
      name_search: 'EDI61568',
      name: "Crocus chrysanthus 'Cream Beauty'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R9Z54DHT;NK834VLN;H756TV26;SWZCB6SX;JZZ8Z3C9;AJP9KGWJ;AL87RV7A;AAJK85JS;ZL75RWTZ;1VPXRC2W;46K4HACS;7L5P3AEZ;PGS1D4ZX;PR1E3AWE',
      statusContent: 'dot',
      hash: 'ZK3G3W3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK3G3W3Y'
    },
    {
      status: 210,
      sku: 'PM0068695',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207777',
      rtl_batch_array: '6207777, 6216970',
      name_search: 'ACMLACHS',
      name: "Achillea millefolium 'Lachssch\u00f6nheit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 947,
      chnn_stock_retail: 1247,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2B255WED;R4RXH1KR;1P3GWLTW;B4JTVXVY;BEV1BAX8;85KX18L9;CPLZ2EE7;J2NBTKE5;6XYRYXDL;DZAC6K55;PRYYDKH1;HBPA94EL;EG3PV4H2;1CS9R8TK;L1D62K65;LY8DNJ12',
      statusContent: 'dot',
      hash: 'W19P4NB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W19P4NB6'
    },
    {
      status: 210,
      sku: 'PM0077785',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349207',
      rtl_batch_array: '4349207',
      name_search: 'EDI61591',
      name: "Crocus chrysanthus 'Goldilocks'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5C6HZTD8;16WZVV73;NLHVXH5A;1S86SXK6;4526NC36;JVZYR2ZG',
      statusContent: 'dot',
      hash: 'KVHTTS5H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVHTTS5H'
    },
    {
      status: 210,
      sku: 'PM0077786',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349209',
      rtl_batch_array: '4349209',
      name_search: 'CRCOMONA',
      name: 'Crocus chr. Orange Monarch',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 12.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TJRJGZW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJRJGZW4'
    },
    {
      status: 210,
      sku: 'PM0077787',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349211',
      rtl_batch_array: '4349211',
      name_search: 'EDI61628',
      name: "Crocus chrysanthus 'Prins Claus'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 99990,
      chnn_stock_retail: 99990,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8NJAAJGD;T12DX8LV;WV6W33DG;LEEYCTBZ;71R2YYTE;7344YGA6;HL21WBPJ;HADJPG1K;Y5SLJPZP;DD5579ED;CZBKJX4D;Y6DJPXER;TP6XCRGN;N4A7ZHAE;ERJ951DS;5P68RZL3;5HX477BP;Z38B8TX8;T5ZZ1N5L;8LC3BYJ8;7V8HDNN8;V7ETDCE4;PAT6JZW6;9TT64V6A;W7CZ7TEB;TV3HAPS3',
      statusContent: 'dot',
      hash: 'L62V7YH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L62V7YH2'
    },
    {
      status: 210,
      sku: 'PM0053409',
      core_name: 'Plant',
      sppl_ean: '8720626387096',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054342',
      rtl_batch_array: '6054342',
      name_search: 'EDI78789',
      name: 'Allium obliquum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L6773YE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6773YE7'
    },
    {
      status: 810,
      sku: 'PM0081269',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339771',
      rtl_batch_array: '6339771',
      name_search: 'CABRGREE',
      name: "Carex brunnea 'Racing Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J33SN55Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J33SN55Y'
    },
    {
      status: 810,
      sku: 'PM0070970',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280970',
      rtl_batch_array: '6280970, 6307457',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 62,
      sppl_prcp: 12.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'PCS9L68W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCS9L68W'
    },
    {
      status: 210,
      sku: 'PM0065089',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349907',
      rtl_batch_array: '6349907',
      name_search: 'CARDKNIS',
      name: "Carex dolichostachya 'Kaga-nishiki'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7ZTYED3T;48DALX4R;CA2P1SB8;5N85SBBT;1VCVYXK9;PJDSNGCY;L97LZW82;WDJP88PL',
      statusContent: 'dot',
      hash: 'BGTW7B1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGTW7B1N'
    },
    {
      status: 210,
      sku: 'PM0045767',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350197',
      rtl_batch_array: '6350197',
      name_search: 'GERGEM',
      name: 'Geranium Rothbury Gem',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 403,
      chnn_stock_retail: 403,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z71BAW69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z71BAW69'
    },
    {
      status: 210,
      sku: 'PM0046169',
      core_name: 'Plant',
      sppl_ean: '8720664871373',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164326',
      rtl_batch_array: '6164326',
      name_search: 'LALWPEAR',
      name: "Lathyrus latifolius 'White Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 969,
      chnn_stock_retail: 969,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13ANJJ57',
      statusContent: 'dot',
      hash: 'T4WHGN6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4WHGN6T'
    },
    {
      status: 210,
      sku: 'PM0077788',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349213',
      rtl_batch_array: '4349213',
      name_search: 'EDI61637',
      name: "Crocus chrysanthus 'Romance'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 7.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XZPBZVBN;W3982CDD;7N3AVRDN;GVYCP9HV;VVZ711JG;VWET275D;SPD82NVG;JTZYBHND;AGG2B7NZ;KX3NT31G;2A7N2DCK;L79P11C8;KRBZP5CP;A3XVK4H3;9XCK7D8N;C82C7HY4;87SR2Z53;5SYNJXS9;1S9SJXRC;T2ETKH7V;HGX71YPY;7LN8WZ9X;TLHGC9YB;HVS44ZWT;PS6E6LCY',
      statusContent: 'dot',
      hash: 'WVLR4AE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVLR4AE2'
    },
    {
      status: 210,
      sku: 'PM0077789',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349215',
      rtl_batch_array: '4349215',
      name_search: 'EDI61647',
      name: "Crocus chrysanthus 'Snow Bunting'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 99988,
      chnn_stock_retail: 99988,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YPHR87S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YPHR87S'
    },
    {
      status: 210,
      sku: 'PM0077790',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349219',
      rtl_batch_array: '4349219',
      name_search: 'EDI61580',
      name: "Crocus sieberi 'Firefly'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 7.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '81Z5TS1B;HYL93333;7CJSH3GB;83ATTPK3;EWHX2PT6;A41DSDRV;C3A4EAJ4;DA4G9ADE;7BX1A2TY;JS1V2RN5;EPGEN2XV;G5WTP1TA;PJD5GB5S;DBPB5SR7',
      statusContent: 'dot',
      hash: 'EWTZAPSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWTZAPSS'
    },
    {
      status: 910,
      sku: 'PM0038385',
      core_name: 'Plant',
      sppl_ean: '8720626367647',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5426652',
      rtl_batch_array: '5426652',
      name_search: 'RORFAIRY',
      name: "Rosa (P) 'Rote The Fairy'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 3.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXJZ5HP5',
      statusContent: 'dot',
      hash: 'ZRJVZYS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRJVZYS1'
    },
    {
      status: 210,
      sku: 'PM0032335',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6306606',
      rtl_batch_array: '6112712, 6306606',
      name_search: 'PADNEMOU',
      name: "Paeonia (LD) 'Duchesse de Nemours'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2637,
      chnn_stock_retail: 2802,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RSVYDKLZ;TWAZ6DN3;DHV3ENV2;139VGDE4;GST2CRBN;27SLBV7N;71T9GAYP;8Z2KACYL;RHVBCSX7;K6EY9RT3;EYVS37GD;CEJSDG3D;XWA8213L;VJD1YSDW;G9HSEZKA;8SCEL1PS',
      statusContent: 'dot',
      hash: 'CDPAZL1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDPAZL1V'
    },
    {
      status: 210,
      sku: 'PM0077791',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349221',
      rtl_batch_array: '4349221',
      name_search: 'EDI61653',
      name: 'Crocus tommasinianus',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YJCLJY4S;H2SN87JN;DHCGEWDT;KLV3XTH1;ZLH415G4;4RB3LKT4;C5HRGXB5;8BKDJVDE;A2W1K65H;HH2R8655;JZAW11S6;248WSW9K;J5E2J86V;H5RALCP8;GGSJWP69;GPPZCG55;R58A7P5A;XB6DKZKW;6PD6P3CL;W1KH7C3Y;AVK5L6AD;RRNCXTJ4;17P6V91T;AE5DEZA6',
      statusContent: 'dot',
      hash: '5G3DS1VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G3DS1VX'
    },
    {
      status: 210,
      sku: 'PM0077792',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349223',
      rtl_batch_array: '4349223',
      name_search: 'EDI61549',
      name: "Crocus tommasinianus 'Barr's Purple'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 99990,
      chnn_stock_retail: 99990,
      sppl_prcp: 6.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T69836KK;54LW4XVR;81DBS627;RJBWXHE7;5K3BDHXH;W9RETVGW;XB4RD44J;K94WJ4D3;T6K3RG8P;234CYTKD;ZYVHWL21;4JEY9SKC;JR7P9KTD;2XZTRGBL;6449H9TH;H2N2WXDX;21EP5DH6;BAX7BG61;R6HS6WRT;AVAARRVL;CTW8LBDX;G8C68STC',
      statusContent: 'dot',
      hash: 'WV4WCA1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV4WCA1P'
    },
    {
      status: 210,
      sku: 'PM0077793',
      core_name: 'Plant',
      sppl_ean: '8720664661264',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349225',
      rtl_batch_array: '4349225',
      name_search: 'CRTRGIAN',
      name: 'Crocus tom. Ruby Giant',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 5.951,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5JHLDEKR;7HWDS9V9;2CPAL6Y7;16L15LH7;KGY85KXN;BKV7BRPL;CSDAYZX7;CWW9LYNT;6E2JLPR6;P1YNCWC6;P286JLG3;Y5E1EWH6;EJP59ARB;28Y92A86;GCLTW7G7;9LTAJWJH;9K6VVXWB;AAPDTBXJ;B7HWKJD2;WRG6HG4T;97X9NETY;67TYGVS8;42KV6ZD4;1C3JTWYC;9ENJ1TPX',
      statusContent: 'dot',
      hash: '61CEXHT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61CEXHT3'
    },
    {
      status: 210,
      sku: 'PM0077794',
      core_name: 'Plant',
      sppl_ean: '8719223013187',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349229',
      rtl_batch_array: '4349229',
      name_search: 'CRSMIX',
      name: 'Crocus Species Mix',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 7.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'K227YZL8',
      statusContent: 'dot',
      hash: 'RKSGWAJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKSGWAJD'
    },
    {
      status: 210,
      sku: 'PM0068916',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202370',
      rtl_batch_array: '6202370, 6338435',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1935,
      chnn_stock_retail: 2850,
      sppl_prcp: 2.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '4NYWD2EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NYWD2EZ'
    },
    {
      status: 210,
      sku: 'PM0085090',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371142',
      rtl_batch_array: '6371142',
      name_search: 'ALMONTES',
      name: "Alstroemeria 'Montesoreau'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 403,
      chnn_stock_retail: 403,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1Z9V4CJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Z9V4CJ1'
    },
    {
      status: 210,
      sku: 'PM0077799',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349265',
      rtl_batch_array: '4349265',
      name_search: 'EDI61854',
      name: "Galanthus nivalis (dou) 'Flore Pleno'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 24.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HBZV6AKT;ALAP5ZPP;ZL1WGN8A;R1N2PKSX;XX1TZPVX',
      statusContent: 'dot',
      hash: 'Y52P5YWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y52P5YWW'
    },
    {
      status: 210,
      sku: 'PM0077800',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349283',
      rtl_batch_array: '4349283',
      name_search: 'TUCPEPPE',
      name: 'Tulipa clusiana Peppermintstick',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GE81N9WZ;HBSHG212;Y1WHHLHS;J22P31G9;H8GRVWHE;453AG5CW;A65BT5HC;DPN1HGJ5;P1ACDTSH;SC268APE',
      statusContent: 'dot',
      hash: 'C951ZVDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C951ZVDG'
    },
    {
      status: 210,
      sku: 'PM0077801',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349291',
      rtl_batch_array: '4349291',
      name_search: 'EDI61008',
      name: "Tulipa humilis (msc) 'Pulchella'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '74YSSN6P;RAW7JAVK;AYE8W26J;S7PXHB4R;RA385TW2;VYPNBVJC;JPVB255A',
      statusContent: 'dot',
      hash: 'RZ8JYHDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ8JYHDA'
    },
    {
      status: 210,
      sku: 'PM0077802',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349297',
      rtl_batch_array: '4349297',
      name_search: 'EDI61236',
      name: 'Tulipa sylvestris (msc)',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 13.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19JZV4J;CYVDH4YG;BVJG5A22;LND55KBY;4HBJT2HJ;43NSXSTR;DGBN1SSY;HLATDPBC;24EEH92D;XTPS2JA8;1YCVP4ET;4WP23TGX;PB7E4PDW;9DS4R9DL;XBYCHR4P;625W8L5C;2BX2H7K7;CJ65HBX3;WJ3YCTHG',
      statusContent: 'dot',
      hash: 'A68HZ2GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A68HZ2GG'
    },
    {
      status: 210,
      sku: 'PM0077803',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349299',
      rtl_batch_array: '4349299',
      name_search: 'TUBMIX',
      name: 'Tulipa Botanical Mix',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.901,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WKCV8HLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKCV8HLB'
    },
    {
      status: 810,
      sku: 'PM0070967',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307452',
      rtl_batch_array: '6280967, 6307452',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 41,
      chnn_stock_retail: 91,
      sppl_prcp: 15.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'E5VETRAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5VETRAD'
    },
    {
      status: 210,
      sku: 'PM0077763',
      core_name: 'Plant',
      sppl_ean: '8719223013576',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349091',
      rtl_batch_array: '4349091',
      name_search: 'ALCHRIST',
      name: 'Allium christophii',
      sppl_size_code: '014+BOP100',
      rng_range_identifier: 'BU\u00d814+100PCS',
      rng_range_description: 'Bulb \u00d8 14 cm + 100 Pieces',
      sppl_stock_available: 99988,
      chnn_stock_retail: 99988,
      sppl_prcp: 28.806,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C53Y24T4;2GTJ4ERB;RV43CY39;B666ZAST;5ZARJTZA;PC3Z6S4T;STGYWBCG;86ATJ8WG;S7VBAWHH;BJ4DWXA3;6X4RGDP8;86R1GA9H;PS4CB2AT;YKB1HWHC;V8ZCHA37;JE6N4LPD;D1HGJ6VY;GJCEATZ6;6D39JPCA;PZPGW7SS;8ESHL7VX;9JJ4S7PA;GGCAS4VV;4D3SEVK5;CLSXR62H;7G5W2ZN4;D2N4AL66;EJJBT821',
      imageBatch: '7EPGRX1X',
      statusContent: 'dot',
      hash: 'ASCBYTJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASCBYTJH'
    },
    {
      status: 210,
      sku: 'PM0077765',
      core_name: 'Plant',
      sppl_ean: '8719223014429',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349094',
      rtl_batch_array: '4349094',
      name_search: 'CACUSICK',
      name: 'Camassia cusickii',
      sppl_size_code: '014+BOP25',
      rng_range_identifier: 'BU\u00d814+25PCS',
      rng_range_description: 'Bulb \u00d8 14 cm + 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.306,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y24ZL6EV;NYCSPKG7;9CNEGTDA;8ATYAS2J',
      imageBatch: 'L9RTWV23',
      statusContent: 'dot',
      hash: 'DGA34WYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGA34WYV'
    },
    {
      status: 210,
      sku: 'PM0077766',
      core_name: 'Plant',
      sppl_ean: '8719223014443',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349096',
      rtl_batch_array: '4349096',
      name_search: 'CAMALALB',
      name: "Camassia leichtlinii 'Alba'",
      sppl_size_code: '014+BOP25',
      rng_range_identifier: 'BU\u00d814+25PCS',
      rng_range_description: 'Bulb \u00d8 14 cm + 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.306,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L8JNAS7G;683TZJDA',
      imageBatch: 'WY6W3E9E',
      statusContent: 'dot',
      hash: 'ETH5NN4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETH5NN4P'
    },
    {
      status: 210,
      sku: 'PM0081270',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339776',
      rtl_batch_array: '6339776',
      name_search: 'CARFOLIO',
      name: 'Carex foliosissima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 672,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1PHJ7428',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PHJ7428'
    },
    {
      status: 210,
      sku: 'PM0066647',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 4,
      btch_active_retail: '6239642',
      rtl_batch_array: '6209205, 6245927, 6239642, 6373176',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 350,
      chnn_stock_retail: 10570,
      sppl_prcp: 3.592,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '9A3D2SBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9A3D2SBZ'
    },
    {
      status: 210,
      sku: 'PM0057385',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308732',
      rtl_batch_array: '6301572, 6308732',
      name_search: 'POREPTAN',
      name: 'Polemonium reptans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 164,
      chnn_stock_retail: 266,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '65GBC142',
      statusContent: 'dot',
      hash: '81RDP5BX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81RDP5BX'
    },
    {
      status: 210,
      sku: 'PM0064859',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6368816',
      rtl_batch_array: '6368816, 6219482',
      name_search: 'GERMSIMP',
      name: "Geranium riversleaianum 'Mavis Simpson'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3829,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CJ53KR59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJ53KR59'
    },
    {
      status: 810,
      sku: 'PM0085091',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371143',
      rtl_batch_array: '6371143',
      name_search: 'ANGBLACK',
      name: 'Andropogon g. Blackhawks',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCVYL6T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCVYL6T6'
    },
    {
      status: 210,
      sku: 'PM0077767',
      core_name: 'Plant',
      sppl_ean: '8719223014467',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349098',
      rtl_batch_array: '4349098',
      name_search: 'CAMLCAER',
      name: "Camassia leichtlinii 'Caerulea'",
      sppl_size_code: '014+BOP25',
      rng_range_identifier: 'BU\u00d814+25PCS',
      rng_range_description: 'Bulb \u00d8 14 cm + 25 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 14.456,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A3Y7K6V7;CB5J5BNR;CA3N8W9E;GA4TZWWA;8CW8TN9E;9K9YJE83',
      imageBatch: '91AY1TCZ',
      statusContent: 'dot',
      hash: 'VCA6K5R6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCA6K5R6'
    },
    {
      status: 210,
      sku: 'PM0077795',
      core_name: 'Plant',
      sppl_ean: '8719223013538',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349239',
      rtl_batch_array: '4349239',
      name_search: 'ALCAERUL',
      name: 'Allium caeruleum',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KDBG22LL;CJXP1YHZ;Y4Y56N7Z;VNS5Y796;49ASLAB3',
      imageBatch: 'PDNH174S',
      statusContent: 'dot',
      hash: 'HC4NXTCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HC4NXTCT'
    },
    {
      status: 210,
      sku: 'PM0085092',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371144',
      rtl_batch_array: '6371144',
      name_search: 'ANFAGRAN',
      name: "Anemone fulgens 'Annulata Grandiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YS5NWLV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS5NWLV6'
    },
    {
      status: 210,
      sku: 'PM0077796',
      core_name: 'Plant',
      sppl_ean: '8719223013743',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349244',
      rtl_batch_array: '4349244',
      name_search: 'ALMOLY',
      name: 'Allium moly',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 5.401,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1XR3GXW9;222ZZBWL;629XG7GR;WJCDKC6W;TN22B71J;64TKX2WW',
      imageBatch: 'AZXXR7SA',
      statusContent: 'dot',
      hash: 'KXXBR37V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXXBR37V'
    },
    {
      status: 210,
      sku: 'PM0077797',
      core_name: 'Plant',
      sppl_ean: '8719223014238',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349248',
      rtl_batch_array: '4349248',
      name_search: 'ANBBSHAD',
      name: "Anemone blanda 'Blue Shades'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDG6X2AC;EZ3CSBE5;ELPGLWET;P6JZVSCT;GB6D8Z9X;79P2D7B2;W1HH468S;45LEZ6AD;KSPENA7W;S1J52N93;W22LGGKZ;47A41L19;JHNT124B;W3NXWY1D;6A6VHRYN;ES9Y1NPG;7WCPTK6P;2LSYL319;LK1PPY54;RVA7TXKL;LDYZ565A;G1HZHTD7;2B72VTHG;ZLZY4DLB',
      imageBatch: 'S3PDEKAH',
      statusContent: 'dot',
      hash: 'TVCP22HT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVCP22HT'
    },
    {
      status: 210,
      sku: 'PM0077798',
      core_name: 'Plant',
      sppl_ean: '8719223014313',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349252',
      rtl_batch_array: '4349252',
      name_search: 'ANBLANDA',
      name: 'Anemone blanda',
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 7.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DTSEJZCK;TG7AX9E1;TXNYZYZ8;1ZTJYXAP;199ZWYST;XLAP3HDY;ELZ8SZ6K;6P7TLG29;8JZY1SRZ;HTNLSKYV;ZHL9NY73;PXSEAN9C;8CX6H2NZ;JWTKY2BC;WT8CA9AD;KWAWWBE8;66W1VXDE;HEERLSJR;B8BPV6V9;VGZHPJL5;C1XRY845;9BVD9HYX;YXSWK2AS;54YAC467;SB6P2NEP;BSS6WGX6;GVPG8WJ3;77S8PH3S;76NDT89J;GXW5DZBZ',
      imageBatch: 'AJ62NJL8',
      statusContent: 'dot',
      hash: 'RP99YGJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RP99YGJ5'
    },
    {
      status: 810,
      sku: 'PM0053385',
      core_name: 'Plant',
      sppl_ean: '8720353004877',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053979',
      rtl_batch_array: '6053979',
      name_search: 'ALMOLY',
      name: 'Allium moly',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1XR3GXW9;222ZZBWL;629XG7GR;WJCDKC6W;TN22B71J;64TKX2WW',
      statusContent: 'dot',
      hash: 'RV1XRA9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV1XRA9H'
    },
    {
      status: 910,
      sku: 'PM0069104',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'PK5X7RV5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PK5X7RV5'
    },
    {
      status: 210,
      sku: 'PM0077804',
      core_name: 'Plant',
      sppl_ean: '8719223013682',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349303',
      rtl_batch_array: '4349303',
      name_search: 'ALAGBEAU',
      name: "Allium amplectens 'Graceful Beauty'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 23.401,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5LLCAR59;JCKXRKSC;BLA2WAES;BG7HPZR6;ASSPL4HE;17GWY3ZD;CLXN67CC;KWWNZHK4;L4Z1W25C;HNRLLLP2;R4YXVSBB;27LKWJV7;GT85ZTX3;EEJ2SB15;LR7GSA3B;NSANPC13;645KJGT3;RW1TYJZ2;8BAS37ET',
      statusContent: 'dot',
      hash: 'CSNLC2HC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSNLC2HC'
    },
    {
      status: 210,
      sku: 'PM0077805',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349304',
      rtl_batch_array: '4349304',
      name_search: 'ALSPHAER',
      name: 'Allium sphaerocephalon',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 99996,
      chnn_stock_retail: 99996,
      sppl_prcp: 5.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '752NEGPR;A7665ZV9;DXY742ZY;WY1HEWTN;8R6KPCLC;W6BSHNBT;ENXR7P1J;GZRPP213;J55XJXST;YZYTJ1DJ;83YHTE2G;Z46Y6WC2;JJBVWGY3',
      imageBatch: 'EE3AY16G',
      statusContent: 'dot',
      hash: '6A849EJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A849EJW'
    },
    {
      status: 910,
      sku: 'PM0069098',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'NW8JXSX7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NW8JXSX7'
    },
    {
      status: 910,
      sku: 'PM0069097',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'TTRNZVTJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TTRNZVTJ'
    },
    {
      status: 210,
      sku: 'PM0047598',
      core_name: 'Plant',
      sppl_ean: '8720349413331',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953540',
      rtl_batch_array: '5953540',
      name_search: 'CHOALBA',
      name: "Chelone obliqua 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1813,
      chnn_stock_retail: 1813,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '15L9N9V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15L9N9V1'
    },
    {
      status: 810,
      sku: 'PM0057013',
      core_name: 'Plant',
      sppl_ean: '8720664851030',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6090651',
      rtl_batch_array: '6090651',
      name_search: 'AJTPNADI',
      name: 'Ajuga tenorii Princess Nadia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y2Z4G1AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2Z4G1AP'
    },
    {
      status: 910,
      sku: 'PM0069100',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'PEYSSGDH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PEYSSGDH'
    },
    {
      status: 810,
      sku: 'PM0063459',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217163',
      rtl_batch_array: '6217163, 6339823',
      name_search: 'HEITCURR',
      name: "Helichrysum italicum 'Tall Curry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 436,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G3JG1XTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3JG1XTD'
    },
    {
      status: 910,
      sku: 'PM0069096',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WESTYRIA',
      name: "Weigela 'Styriaca'",
      rng_range_identifier: 'H040050C7',
      statusContent: 'dot',
      hash: '6REPBJ2K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6REPBJ2K'
    },
    {
      status: 810,
      sku: 'PM0057405',
      core_name: 'Plant',
      sppl_ean: '8720664882973',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133583',
      rtl_batch_array: '6133583',
      name_search: 'PYFLEXUO',
      name: 'Pycnanthemum flexuosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7J789LJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7J789LJJ'
    },
    {
      status: 810,
      sku: 'PM0053395',
      core_name: 'Plant',
      sppl_ean: '8720664680241',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054315',
      rtl_batch_array: '6054315',
      name_search: 'EDI83768',
      name: "Allium carolinianum 'Rosy Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GHRR6H7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHRR6H7R'
    },
    {
      status: 210,
      sku: 'PM0058122',
      core_name: 'Plant',
      sppl_ean: '8720664853485',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112056',
      rtl_batch_array: '6112056',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 10443,
      chnn_stock_retail: 10443,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: '9WG6RBL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WG6RBL3'
    },
    {
      status: 810,
      sku: 'PM0070969',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307456',
      rtl_batch_array: '6307456',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 9.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'Y94YTYX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y94YTYX1'
    },
    {
      status: 210,
      sku: 'PM0045775',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220780',
      rtl_batch_array: '6220780',
      name_search: 'GECJJRAS',
      name: 'Geranium cinereum Jolly Jewel Raspberry',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A237J5LH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A237J5LH'
    },
    {
      status: 810,
      sku: 'PM0065073',
      core_name: 'Plant',
      sppl_ean: '8720664853096',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161565',
      rtl_batch_array: '6229864, 6161565',
      name_search: 'ASCPURPU',
      name: "Astilbe chinensis 'Purpurkerze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 51,
      chnn_stock_retail: 257,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2H95G7JJ;5L9733PB;WJRG6A9N;XZEVTWBL;WL5G58PX;E7NP713G;VYXJE286',
      statusContent: 'dot',
      hash: 'TS49D5VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TS49D5VK'
    },
    {
      status: 210,
      sku: 'PM0064403',
      core_name: 'Plant',
      sppl_ean: '8720664855557',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151512',
      rtl_batch_array: '6151512',
      name_search: 'CAOEVERC',
      name: "Carex oshimensis 'Evercream'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9878,
      chnn_stock_retail: 9878,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHYDAYZ7;824ALR5C;78B491P4;A5YVTLGC;EZKX9J3L;27NZ3ZA6;1TGH2DYP;SNHVK6BT;G2EP7D6V;4BX27KC6;D9ZY5CKC;RAC6N5RC;1GL5T997;D5TAD56B;2671EJER;TWZ523JN;X8DADD9B;6VTKNK1V;Z8VASE2C;LE7P74BX;BHWZTAYB;BS381A96;81H14WR8;6A114P81;5YY6EBZD',
      statusContent: 'dot',
      hash: 'G368NHBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G368NHBJ'
    },
    {
      status: 210,
      sku: 'PM0057406',
      core_name: 'Plant',
      sppl_ean: '8720664882980',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133585',
      rtl_batch_array: '6133585, 6339888',
      name_search: 'PYPILOSU',
      name: 'Pycnanthemum pilosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 366,
      chnn_stock_retail: 844,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPES91SP;PC4GB7B9',
      statusContent: 'dot',
      hash: '94CASTVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94CASTVA'
    },
    {
      status: 210,
      sku: 'PM0053423',
      core_name: 'Plant',
      sppl_ean: '8720353006468',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054363',
      rtl_batch_array: '6202259, 6054363',
      name_search: 'ALURSINU',
      name: 'Allium ursinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4691,
      chnn_stock_retail: 6189,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9EC3TW14;WV1YZ916;RT8LSDG8',
      statusContent: 'dot',
      hash: '6RZ1GLTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RZ1GLTP'
    },
    {
      status: 210,
      sku: 'PM0069102',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 2,
      btch_active_retail: '6239634',
      rtl_batch_array: '6211058, 6239634',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 6432,
      sppl_prcp: 3.845,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'K6YRR61B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6YRR61B'
    },
    {
      status: 210,
      sku: 'PM0069103',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239635',
      rtl_batch_array: '6239635',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'WZB95TPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZB95TPN'
    },
    {
      status: 210,
      sku: 'PM0060138',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6216270',
      rtl_batch_array: '6216270',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '080100C4',
      rng_range_identifier: 'H080100C4',
      sppl_stock_available: 2702,
      chnn_stock_retail: 2702,
      sppl_prcp: 3.975,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'N5CEVEVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5CEVEVW'
    },
    {
      status: 210,
      sku: 'PM0053404',
      core_name: 'Plant',
      sppl_ean: '8720664680418',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054331',
      rtl_batch_array: '6054331',
      name_search: 'EDI83762',
      name: "Allium schubertii 'Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 338,
      chnn_stock_retail: 338,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8YNKG1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8YNKG1B'
    },
    {
      status: 210,
      sku: 'PM0058182',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6324687',
      rtl_batch_array: '6350158, 6317848, 6324687, 6349035',
      name_search: 'EURCHOCO',
      name: "Eupatorium rugosum 'Chocolate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 4756,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ELHJWXHB;B6PGZ96Z;28AAJBYT;67L1GJ6L;ZVKBGHLZ;Z6141NHY;YSCPV2H2;6C51W2T4;C5EK5RAZ',
      statusContent: 'dot',
      hash: '9RKTYSS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RKTYSS8'
    },
    {
      status: 210,
      sku: 'PM0057398',
      core_name: 'Plant',
      sppl_ean: '8720664881587',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112830',
      rtl_batch_array: '6112830',
      name_search: 'PRATREAD',
      name: "Pratia angulata 'Treadwellii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ7KV16T;5DAX2AHB;VVZGWEP5;Z3BBJ44V',
      statusContent: 'dot',
      hash: 'LKWWRL15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKWWRL15'
    },
    {
      status: 210,
      sku: 'PM0066436',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6376237',
      rtl_batch_array: '6376237, 6338425',
      name_search: 'PRLCBRAN',
      name: "Prunus laurocerasus 'Cherry Brandy'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2358,
      sppl_prcp: 2.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1866NEBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1866NEBN'
    },
    {
      status: 810,
      sku: 'PM0044785',
      core_name: 'Plant',
      sppl_ean: '8720664679207',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5818818',
      rtl_batch_array: '5818818',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.817,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '8DV417VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DV417VN'
    },
    {
      status: 210,
      sku: 'PM0077806',
      core_name: 'Plant',
      sppl_ean: '8719223013996',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349306',
      rtl_batch_array: '4349306',
      name_search: 'ALWCLOUD',
      name: 'Allium hybrid White Cloud',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VZA27AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VZA27AP'
    },
    {
      status: 210,
      sku: 'PM0058063',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315109',
      rtl_batch_array: '6315109, 6295433, 6205774',
      name_search: 'AJREPTAN',
      name: 'Ajuga reptans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1372,
      chnn_stock_retail: 3898,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9TYY7NSC;6HNAC18P;TNHTW3RR;G2K2S9B3;2PN6WTKB;JWZZJZWY;7XZSTCJ9;52XE24L9;VESGJRR7;G7SWKYAA;E6546H5T;25JATYLH;1REAGRVZ;X655RS1W;VKPNLCDC;725JXKH4;LTZZHZV5;XEYXYGZ6;ET1T1235;NAJC85VE;94T4R859',
      statusContent: 'dot',
      hash: 'EYVH1K96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYVH1K96'
    },
    {
      status: 210,
      sku: 'PM0077807',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349313',
      rtl_batch_array: '4349313',
      name_search: 'EDI75096',
      name: "Corydalis solida 'Beth Evans'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '151PV9H6;2JVGYRLK;XPVH2NNS;D6LYT4WL;ZNC518DW;8T29E2WS;JPBXGRWY;X6YEPA3A;HV79TDRL;DJ6P2K1J;C1P4WWKK;ZRYCNKEW;XJ5P1BCZ',
      statusContent: 'dot',
      hash: 'DH6RY7GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH6RY7GX'
    },
    {
      status: 210,
      sku: 'PM0058477',
      core_name: 'Plant',
      sppl_ean: '8720664800083',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112814',
      rtl_batch_array: '6112814',
      name_search: 'NEMMBLUE',
      name: "Nepeta manchuriensis 'Manchu Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALHAX6HL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALHAX6HL'
    },
    {
      status: 210,
      sku: 'PM0077808',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349316',
      rtl_batch_array: '4349316',
      name_search: 'GLBYZANT',
      name: 'Gladiolus byzantinus',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 10.801,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4NC864V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NC864V4'
    },
    {
      status: 210,
      sku: 'PM0077809',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349317',
      rtl_batch_array: '4349317',
      name_search: 'EDI61927',
      name: "Iris reticulata 'Harmony'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '76K2KE8A;VP2LT162;929KXND9;XVPAPYWH;HZ85AZV8;VPRBZ2DY;2JLTY1V9;GWSYDWPW;C79CVA93;2ZB29GL8;XHR37YJZ;JRD8P9V1;6A8XDXGB;K3L48ZD3;4YZS9AR5;ENJVSW9X;KJE2ED3E',
      statusContent: 'dot',
      hash: '5XPKVPEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XPKVPEL'
    },
    {
      status: 210,
      sku: 'PM0077810',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349319',
      rtl_batch_array: '4349319',
      name_search: 'EDI84912',
      name: "Iris reticulata 'Painted Lady'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 10.801,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1AX6SLCD;P2R4HGVW;NT519P9H;ZZ317J5K;G5YDRRT7;Y51KC7BG;1Y5YV1E5;75RZXR35',
      statusContent: 'dot',
      hash: 'XN12KPAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XN12KPAW'
    },
    {
      status: 210,
      sku: 'PM0077811',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349329',
      rtl_batch_array: '4349329',
      name_search: 'EDI69147',
      name: "Iris 'Katharine Hodgkin'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 11.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8RVR7XZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RVR7XZD'
    },
    {
      status: 210,
      sku: 'PM0077812',
      core_name: 'Plant',
      sppl_ean: '8719223015907',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349331',
      rtl_batch_array: '4349331',
      name_search: 'MUAZUREU',
      name: 'Muscari azureum',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 7.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVBHRDDW;9VVSRN3G;CJHCB15R;JGLELV31;NL6YLZH8',
      imageBatch: 'WHLBTY1W',
      statusContent: 'dot',
      hash: '8P81W6CB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8P81W6CB'
    },
    {
      status: 210,
      sku: 'PM0045333',
      core_name: 'Plant',
      sppl_ean: '8720664681057',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014514',
      rtl_batch_array: '6014514',
      name_search: 'ASMARJOR',
      name: "Aster (D) 'Marjorie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '877LLA7K;YBWT7GXZ;WJJSREWB;RE93YLD6;V9ASYG84;D69D18AP;K346N7ET;Z6L3YB69;8649PEJK;EAAZNV3R',
      statusContent: 'dot',
      hash: 'Z2LXCZS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2LXCZS6'
    },
    {
      status: 210,
      sku: 'PM0077813',
      core_name: 'Plant',
      sppl_ean: '8719223015921',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349332',
      rtl_batch_array: '4349332',
      name_search: 'MUBALBUM',
      name: "Muscari botryoides 'Album'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.901,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X8JXDLL8;BYGLPZRD;EPSHL21B;T6ZWLA1K;VKSCC412;1S23AZWX;XSERW9SN;NL7TJ5X2;R6BB9A23',
      imageBatch: 'HPAARXKB',
      statusContent: 'dot',
      hash: '4XL7ZKCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XL7ZKCD'
    },
    {
      status: 210,
      sku: 'PM0046290',
      core_name: 'Plant',
      sppl_ean: '8720664876378',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112725',
      rtl_batch_array: '6112725, 6301753',
      name_search: 'PAADBLUE',
      name: "Panicum amarum 'Dewey Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 550,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E22VRYJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E22VRYJ6'
    },
    {
      status: 210,
      sku: 'PM0064457',
      core_name: 'Plant',
      sppl_ean: '8720664871953',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151582',
      rtl_batch_array: '6151582',
      name_search: 'LEDDUKE',
      name: "Leucanthemum 'Daisy Duke'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2414,
      chnn_stock_retail: 2414,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SSD3536',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SSD3536'
    },
    {
      status: 210,
      sku: 'PM0047583',
      core_name: 'Plant',
      sppl_ean: '8720664800670',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5951645',
      rtl_batch_array: '5951645',
      name_search: 'PAVSTAEF',
      name: "Panicum virgatum 'St\u00e4fa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DGG671DH;V8ZD6H6L;B6XGG79E;V6ZCBL37;HZCZ3PEX;WYZTC2TS;454SV535;8YAWKD9E;JRK7K3DD;A5KLPNTH;565HPG2S;JX3K2YPW;WDB6R57N;5KG2VDAR;7PE5YHXR;GLRC3B4E',
      statusContent: 'dot',
      hash: 'Y8RE45ER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8RE45ER'
    },
    {
      status: 210,
      sku: 'PM0077814',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349337',
      rtl_batch_array: '4349337',
      name_search: 'EDI63917',
      name: 'Muscari latifolium',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 5.951,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNX1ZGLX;YCH2ALW5;1R59T9PR;4T1TSEKZ;4EK95SW4;41Z7KW3S;2A41R4CK;B4JE177J;HBWE8NZP;T3EJGBWY;N2S4XKHZ;1HXCD75L;GHC2LTLS;8AYLBSKK;7761K5PH;67G6RSGK;VLBTDRVT;2E7R155X',
      statusContent: 'dot',
      hash: 'RN2JX58V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RN2JX58V'
    },
    {
      status: 210,
      sku: 'PM0045882',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350233',
      rtl_batch_array: '6350233',
      name_search: 'GEPELACE',
      name: "Geranium pr. 'Else Lacey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDAV415G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDAV415G'
    },
    {
      status: 210,
      sku: 'PM0057213',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267786',
      rtl_batch_array: '6091306, 6267786',
      name_search: 'HEMLLACE',
      name: "Hemerocallis 'Luxury Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1075,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTZDNRGV;CK9BZ5KL;C6L36C1C;CLT2HWXS;RKKVEETY',
      statusContent: 'dot',
      hash: 'JBNVAX6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBNVAX6P'
    },
    {
      status: 210,
      sku: 'PM0085093',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371145',
      rtl_batch_array: '6371145',
      name_search: 'ANHGBLAN',
      name: "Anemone hybrida 'G\u00e9ante Blanche'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 402,
      chnn_stock_retail: 402,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GXPJZBAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXPJZBAR'
    },
    {
      status: 210,
      sku: 'PM0074223',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268719',
      rtl_batch_array: '6268719',
      name_search: 'DEDEMERA',
      name: "Delphinium 'Delphis Emerald'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '613NAHZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '613NAHZX'
    },
    {
      status: 910,
      sku: 'PM0066443',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: '5WVLLV5X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5WVLLV5X'
    },
    {
      status: 210,
      sku: 'PM0077815',
      core_name: 'Plant',
      sppl_ean: '8719223016126',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349343',
      rtl_batch_array: '4349343',
      name_search: 'ORUMBELL',
      name: 'Ornithogalum umbellatum',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZHEHKTC;5LNNVSBB',
      imageBatch: '8TRPT7AV',
      statusContent: 'dot',
      hash: 'D33ZXXW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D33ZXXW2'
    },
    {
      status: 210,
      sku: 'PM0015553',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353427',
      rtl_batch_array: '6353427',
      name_search: 'SEBTOP',
      name: "Sempervivum 'Black Top'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LKV6HESA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKV6HESA'
    },
    {
      status: 210,
      sku: 'PM0077816',
      core_name: 'Plant',
      sppl_ean: '8719223016140',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349345',
      rtl_batch_array: '4349345',
      name_search: 'OXADENOP',
      name: 'Oxalis adenophylla',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 20.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J874EAE5;S5AA9PL7;NWSDY9K4;KNBX3696;2X8TT858;K978V3H6;D1AL83V2;8TCB5HD5;WTJ9D2D2;VZJV138B;V89WTRYT;D6NSVR2Y;WB4LBXB5;47ARJ15Y;5ZJXSSK2;KG5PEV9V;PLP5BL2W;S28VL87N;W36JL942;BG5RDGHH;XXV9DJV3;NLJW4LX9;1C1Y14X6;LAV674NG;WCSD4EWE;ZSHAECX9;NT8VEJ6Y;128Y39XS',
      imageBatch: 'LR2VZCJL',
      statusContent: 'dot',
      hash: '5EYWL1YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EYWL1YT'
    },
    {
      status: 910,
      sku: 'PM0081271',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAKYOTO',
      name: "Carex 'Kyoto'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'GAJLVG9T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GAJLVG9T'
    },
    {
      status: 210,
      sku: 'PM0077817',
      core_name: 'Plant',
      sppl_ean: '8720349407231',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349352',
      rtl_batch_array: '4349352',
      name_search: 'NAPAPERW',
      name: 'Narcissus Paperwhite',
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XABJJAD4;SDTJS2B1;VD1H9DTX;2XGTRPA8;51B7SNJY;XAHTV4B1;LVXDRZDW;7L91JYTV;ATC7CK25;HSLTLCR1;CJXW5HS2;71NZB1E8;BXSYWWA9;19N7BZ1S;CD82S3A4;CEA3R724;79Y2DHE9;Y855Y9JX;DT76CNY8;AHRXE381;89XANBBR',
      statusContent: 'dot',
      hash: '51LTZ2DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51LTZ2DJ'
    },
    {
      status: 210,
      sku: 'PM0077818',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349364',
      rtl_batch_array: '4349364',
      name_search: 'EDI63001',
      name: 'Colchicum cilicicum',
      sppl_size_code: '020+BOP10',
      rng_range_identifier: 'BU\u00d820+10PCS',
      rng_range_description: 'Bulb \u00d8 20 cm + 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'KN6V35DV',
      statusContent: 'dot',
      hash: '9EXEY6K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EXEY6K2'
    },
    {
      status: 210,
      sku: 'PM0081272',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339779',
      rtl_batch_array: '6339779',
      name_search: 'CATENUIC',
      name: 'Carex tenuiculmis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R58ZXTYN;EKJGNEBN;P59LSR3L;93ZNCS3X;RE5KE29X;P4VDVESR;DEG8JKLB;Y13RCYCZ',
      statusContent: 'dot',
      hash: 'L9RE52LW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9RE52LW'
    },
    {
      status: 210,
      sku: 'PM0063684',
      core_name: 'Plant',
      sppl_ean: '8720664885912',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147859',
      rtl_batch_array: '6147859',
      name_search: 'SEPACHYC',
      name: 'Sedum pachyclados',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 396,
      chnn_stock_retail: 396,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2GYKZKWK;21S97B9G;YB63A3C7;WKKKDZB7;27EH68K5;1N8D7WNS;DYEC8E88',
      statusContent: 'dot',
      hash: 'ZP1XY5P8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP1XY5P8'
    },
    {
      status: 210,
      sku: 'PM0046244',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315057',
      rtl_batch_array: '6112784, 6315057',
      name_search: 'MOCAERUL',
      name: 'Molinia caerulea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1441,
      chnn_stock_retail: 1819,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L96DADDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L96DADDN'
    },
    {
      status: 210,
      sku: 'PM0014086',
      core_name: 'Plant',
      sppl_ean: '8720349432127',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5607024',
      rtl_batch_array: '6220862, 5607024, 6291718',
      name_search: 'MOCDAUER',
      name: "Molinia caerulea 'Dauerstrahl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5500,
      chnn_stock_retail: 8052,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GSAAVLVL',
      statusContent: 'dot',
      hash: 'WTB2V5WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTB2V5WE'
    },
    {
      status: 210,
      sku: 'PM0081273',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339780',
      rtl_batch_array: '6339780',
      name_search: 'CAUMBROS',
      name: 'Carex umbrosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K75PLKTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K75PLKTX'
    },
    {
      status: 210,
      sku: 'PM0046276',
      core_name: 'Plant',
      sppl_ean: '8720626315631',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014201',
      rtl_batch_array: '6014201',
      name_search: 'PHLARISS',
      name: "Phlox (P) 'Larissa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '54P9P2N6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54P9P2N6'
    },
    {
      status: 210,
      sku: 'PM0074224',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268720',
      rtl_batch_array: '6268720',
      name_search: 'DEDIAMAN',
      name: "Delphinium 'Diamant'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WCB5234Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCB5234Z'
    },
    {
      status: 210,
      sku: 'PM0077821',
      core_name: 'Plant',
      sppl_ean: '8719223013705',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349372',
      rtl_batch_array: '4349372',
      name_search: 'EDI61739',
      name: "Allium macleanii 'His Excellency'",
      sppl_size_code: '020+BOP25',
      rng_range_identifier: 'BU\u00d820+25PCS',
      rng_range_description: 'Bulb \u00d8 20 cm + 25 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 28.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X63B81D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X63B81D9'
    },
    {
      status: 210,
      sku: 'PM0053498',
      core_name: 'Plant',
      sppl_ean: '8720626301795',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054639',
      rtl_batch_array: '6054639',
      name_search: 'GETUBERO',
      name: 'Geranium tuberosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WWGKN7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WWGKN7B'
    },
    {
      status: 210,
      sku: 'PM0081274',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349785',
      rtl_batch_array: '6339781, 6349785',
      name_search: 'CACARVI',
      name: 'Carum carvi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 486,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EAEE7H3K;137TR6A8;NGA7Z3A4;6H83YWHA;91G25DN1;SA7X8ZV8;R1RRRCJ4',
      statusContent: 'dot',
      hash: '1821E532',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1821E532'
    },
    {
      status: 210,
      sku: 'PM0085094',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371146',
      rtl_batch_array: '6371146',
      name_search: 'ARLWDAME',
      name: "Artemisia lactiflora 'Weisse Dame'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2XBBYE8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XBBYE8N'
    },
    {
      status: 210,
      sku: 'PM0058395',
      core_name: 'Plant',
      sppl_ean: '8720664697324',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112695',
      rtl_batch_array: '6112695',
      name_search: 'LELACROS',
      name: "Leucanthemum (S) 'Lacrosse'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RDAAH1AB;SJNLLNT7;V18GRG6C',
      statusContent: 'dot',
      hash: 'NNWEESP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NNWEESP8'
    },
    {
      status: 210,
      sku: 'PM0077823',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349392',
      rtl_batch_array: '4349392',
      name_search: 'EDI61288',
      name: 'Tulipa turkestanica (msc)',
      sppl_size_code: '007+BOP100',
      rng_range_identifier: 'BU\u00d87+100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm + 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 9.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9513JLVW;TJWSRS42;D9G453Z6;WVX94VE9;VLSRYBKP;ZWWZ2EXP;1KJNK7KX;HWVLGC62;KR1VH4WA;2TB4RW4K;A4P4LPRA;D8VNJS1V;LH26YW8H;ENB6EN1X;8WRHG7N8;NWJYRYV4',
      statusContent: 'dot',
      hash: 'GCSVGHBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCSVGHBL'
    },
    {
      status: 210,
      sku: 'PM0077819',
      core_name: 'Plant',
      sppl_ean: '8719223013651',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349369',
      rtl_batch_array: '4349369',
      name_search: 'ALGLADIA',
      name: "Allium 'Gladiator'",
      sppl_size_code: '020+BOP100',
      rng_range_identifier: 'BU\u00d820+100PCS',
      rng_range_description: 'Bulb \u00d8 20 cm + 100 Pieces',
      sppl_stock_available: 99997,
      chnn_stock_retail: 99997,
      sppl_prcp: 94.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNYZT5X9;E6SCDK3E;VLVVZ6WP;AH87KXNP;XZH12T6Z;166VATRV;SGPH99K6;XD93C3K6',
      imageBatch: '6GT8ZYV4',
      statusContent: 'dot',
      hash: 'DV6JYVCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DV6JYVCS'
    },
    {
      status: 210,
      sku: 'PM0081275',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339782',
      rtl_batch_array: '6339782',
      name_search: 'COBUTTER',
      name: "Coreopsis 'Buttermilk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75B1JGZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75B1JGZ1'
    },
    {
      status: 210,
      sku: 'PM0077824',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349399',
      rtl_batch_array: '4349399',
      name_search: 'EDI63973',
      name: 'Scilla mischtschenkoana',
      sppl_size_code: '008+BOP100',
      rng_range_identifier: 'BU\u00d88+100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEG1545R;TV2LZ34R;54V64G21;T3S45A75;K73CKJZ3;E7J7ZYCZ;JSY7E544;JE1CB63X;A9E3Z7BK;W9HEX58G;69ELLP6J;465T16B8;LP7B2ASX;D2DWGG27',
      statusContent: 'dot',
      hash: 'SGC97HRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGC97HRR'
    },
    {
      status: 210,
      sku: 'PM0077825',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349400',
      rtl_batch_array: '4349400',
      name_search: 'EDI67616',
      name: "Tulipa praestans (msc) 'Shogun'",
      sppl_size_code: '009+BOP100',
      rng_range_identifier: 'BU\u00d89+100PCS',
      rng_range_description: 'Bulb \u00d8 9 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 10.801,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y8AA3PJS;983ZS6H5;ZNY2KBSB;8C5JB83H;9CZSK2RG;AJ8ZX21Z;5W4EJ16W;GDACRDJT;4X78BBZR;D8YX1D6Z;TC2JXZC9;RSJVNSBX;4S87Y65K;R19GX8W8;YST4RHAG;SWWX6Z39',
      statusContent: 'dot',
      hash: 'YPYAK5A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPYAK5A2'
    },
    {
      status: 210,
      sku: 'PM0063387',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281440',
      rtl_batch_array: '6281440',
      name_search: 'SAREPENS',
      name: 'Salix repens',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 7744,
      chnn_stock_retail: 7744,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V4KNL9LB;DSE8LZKJ;PH288W6G',
      statusContent: 'dot',
      hash: 'SWVYH9E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWVYH9E5'
    },
    {
      status: 810,
      sku: 'PM0081276',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339783',
      rtl_batch_array: '6339783',
      name_search: 'COCEYE',
      name: "Coreopsis 'Cosmic Eye'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1P7V6EZP;ZE5GRR2D;E24NL97S;7DV5WT2J;XXLA2HWE;H26CNNC1;2S2SHWNK',
      statusContent: 'dot',
      hash: 'BCW5JK4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCW5JK4D'
    },
    {
      status: 210,
      sku: 'PM0068700',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308483',
      rtl_batch_array: '6252835, 6295453, 6308483',
      name_search: 'ANHKCHAR',
      name: "Anemone hybrida 'K\u00f6nigin Charlotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 7435,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CN2NWZE3;TK6REXC5;13HS42A7;KRCG6G6P;8JNNAC86;H8J5G6AG;L7XWCREE;J3P8J796;RG8BPZST;HKXY5RWR;CGT44YW7',
      statusContent: 'dot',
      hash: 'VSL7945W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSL7945W'
    },
    {
      status: 210,
      sku: 'PM0068701',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315038',
      rtl_batch_array: '6315038, 6242081',
      name_search: 'ANHSEREN',
      name: "Anemone hybrida 'Serenade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1694,
      chnn_stock_retail: 2030,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V9B6VWV6;B74ETH8B;BHL6SVHS;HPYZKNRY;1AXAWYLG;149P7AHH;6DPWTGRE;PL2J2XKZ;HC6ZA5JX;YHL4RJH5;7C7EYGJ9;ZTCBP7C7',
      statusContent: 'dot',
      hash: 'VEH5EAL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VEH5EAL8'
    },
    {
      status: 810,
      sku: 'PM0081277',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339784',
      rtl_batch_array: '6339784',
      name_search: 'COGALAXY',
      name: 'Cornus Galaxy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9C32DSSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9C32DSSA'
    },
    {
      status: 210,
      sku: 'PM0045503',
      core_name: 'Plant',
      sppl_ean: '8720353007304',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6014731',
      rtl_batch_array: '6129658, 6014731, 6301506',
      name_search: 'CHOALBA',
      name: "Chelone obliqua 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1195,
      chnn_stock_retail: 3803,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2GL4T1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2GL4T1Z'
    },
    {
      status: 210,
      sku: 'PM0081278',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339785',
      rtl_batch_array: '6339785',
      name_search: 'COLPASSI',
      name: "Coreopsis 'Limerock Passion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBVJ6KZD;5DDYER3W;RCH7CBDX;4EX6P642',
      statusContent: 'dot',
      hash: 'KCNNNH8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCNNNH8C'
    },
    {
      status: 210,
      sku: 'PM0046264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353103',
      rtl_batch_array: '6353103',
      name_search: 'PHFPINK',
      name: "Phlox 'Forever Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WPHV9WLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPHV9WLG'
    },
    {
      status: 210,
      sku: 'PM0045492',
      core_name: 'Plant',
      sppl_ean: '8720664692596',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5741221',
      rtl_batch_array: '5741221',
      name_search: 'HOSROAD',
      name: "Hosta 'Silk Road'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRJHPCDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRJHPCDL'
    },
    {
      status: 210,
      sku: 'PM0069106',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300231',
      rtl_batch_array: '6300231, 6310369',
      name_search: 'RORUGOSA',
      name: 'Rosa rugosa',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4262,
      chnn_stock_retail: 11511,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YYVTCPJ7;JWELRSGK;G53PXS8P;1KJ6X6PA;G92ZEN4B;97RDXCY3;WAAWHLJR;EP85ETA7',
      statusContent: 'dot',
      hash: 'WZT2A32L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZT2A32L'
    },
    {
      status: 210,
      sku: 'PM0081279',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339786',
      rtl_batch_array: '6339786',
      name_search: 'COMMAGIC',
      name: "Coreopsis 'Madras Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTGP9LXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTGP9LXY'
    },
    {
      status: 910,
      sku: 'PM0066438',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'HPVWB2VV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HPVWB2VV'
    },
    {
      status: 910,
      sku: 'PM0066444',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJDRED',
      name: "Spiraea japonica 'Dart's Red'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      imageCore: 'V48RLPL3',
      statusContent: 'dot',
      hash: 'BSANP157',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BSANP157'
    },
    {
      status: 210,
      sku: 'PM0039081',
      core_name: 'Plant',
      sppl_ean: '8720349433827',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014460',
      rtl_batch_array: '6014460',
      name_search: 'PASBERNH',
      name: "Paeonia (LD) 'Sarah Bernhardt'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_order_minimum: 3,
      sppl_prcp: 2.169,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5LR26REP;LAG8TSE2;HGPLY67A;5CS8R3BX;H5TVCZC6;B76P6499;HXAV6XLP;G18TXJHR;D2LVRV4P;S717VWNG',
      statusContent: 'dot',
      hash: 'GLPHP92S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLPHP92S'
    },
    {
      status: 210,
      sku: 'PM0058187',
      core_name: 'Plant',
      sppl_ean: '8720664865075',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112373',
      rtl_batch_array: '6112373',
      name_search: 'GESBKISS',
      name: "Geranium sobol. 'Butterfly Kisses'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '63HL8V23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63HL8V23'
    },
    {
      status: 210,
      sku: 'PM0046243',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382298',
      rtl_batch_array: '6080712, 6159892, 6382298',
      name_search: 'MOAWINDS',
      name: "Molinia arundinacea 'Windspiel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2582,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1G7WVB95',
      statusContent: 'dot',
      hash: 'H2R95A6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2R95A6T'
    },
    {
      status: 210,
      sku: 'PM0047584',
      core_name: 'Plant',
      sppl_ean: '8720664800687',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5951646',
      rtl_batch_array: '5951646',
      name_search: 'PAVSTAEF',
      name: "Panicum virgatum 'St\u00e4fa'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 854,
      chnn_stock_retail: 854,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DGG671DH;V8ZD6H6L;B6XGG79E;V6ZCBL37;HZCZ3PEX;WYZTC2TS;454SV535;8YAWKD9E;JRK7K3DD;A5KLPNTH;565HPG2S;JX3K2YPW;WDB6R57N;5KG2VDAR;7PE5YHXR;GLRC3B4E',
      statusContent: 'dot',
      hash: 'YJB6PR6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJB6PR6C'
    },
    {
      status: 210,
      sku: 'PM0027126',
      core_name: 'Plant',
      sppl_ean: '8720664855571',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161604',
      rtl_batch_array: '5587686, 6161604',
      name_search: 'CAOEVERI',
      name: "Carex oshimensis 'Everillo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 290,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V56NDZA1;14BTWSPK;GYJ6D49L;CS4EYZDJ;Y5ZY5GR8;2379TG2W;JWS9HHXC;JLXPTH2P;KZ9L7A9S;67879P5Y',
      statusContent: 'dot',
      hash: 'E7NBKRDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7NBKRDZ'
    },
    {
      status: 210,
      sku: 'PM0064456',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298666',
      rtl_batch_array: '6298666',
      name_search: 'LAIWLVIR',
      name: 'Lagerstroemia indica With Love Virgin',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDS8XSRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDS8XSRD'
    },
    {
      status: 210,
      sku: 'PM0068698',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207791',
      rtl_batch_array: '6207791',
      name_search: 'ALTIERCE',
      name: "Alstroemeria 'Tierc\u00e9'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E3B3G5BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3B3G5BT'
    },
    {
      status: 210,
      sku: 'PM0059893',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176242',
      rtl_batch_array: '6220661, 6176242',
      name_search: 'ASPWIELE',
      name: "Astilbe (T) 'Prof. van der Wielen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4128,
      chnn_stock_retail: 4440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NE1Z6X2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NE1Z6X2J'
    },
    {
      status: 210,
      sku: 'PM0081280',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339787',
      rtl_batch_array: '6339787',
      name_search: 'COMAJOR',
      name: 'Coreopsis major',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XSLXLPVG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSLXLPVG'
    },
    {
      status: 810,
      sku: 'PM0038425',
      core_name: 'Plant',
      sppl_ean: '8720664542853',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496552',
      rtl_batch_array: '5496552',
      name_search: 'HAVIRGIN',
      name: 'Hamamelis virginiana',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PLT32T6E;KHE2ADXH',
      statusContent: 'dot',
      hash: 'KJCDNEX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJCDNEX2'
    },
    {
      status: 210,
      sku: 'PM0066445',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183988',
      rtl_batch_array: '6183988',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 1466,
      chnn_stock_retail: 1466,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '6LPVJNSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LPVJNSN'
    },
    {
      status: 810,
      sku: 'PM0085095',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371147',
      rtl_batch_array: '6371147',
      name_search: 'ASAZURO',
      name: "Aster 'Azuro'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YB7YC1VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YB7YC1VT'
    },
    {
      status: 210,
      sku: 'PM0077832',
      core_name: 'Plant',
      sppl_ean: '8719223014849',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349415',
      rtl_batch_array: '4349415',
      name_search: 'ERHIMALA',
      name: 'Eremurus himalaicus',
      sppl_size_code: 'BOK1P10',
      rng_range_identifier: 'BU10PCS',
      rng_range_description: 'Bulb 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 44.193,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ZBB81V1G',
      statusContent: 'dot',
      hash: 'BAS4ASSL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAS4ASSL'
    },
    {
      status: 210,
      sku: 'PM0047597',
      core_name: 'Plant',
      sppl_ean: '8720353092027',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953539',
      rtl_batch_array: '5953539',
      name_search: 'CEPLUMBA',
      name: 'Ceratostigma plumbaginoides',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 974,
      chnn_stock_retail: 974,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JDPSCTN2;PGN26CCN',
      statusContent: 'dot',
      hash: 'J91BD5X5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J91BD5X5'
    },
    {
      status: 910,
      sku: 'PM0046280',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHRDANCE',
      name: 'Phlox (P) Rainbow Dancer',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BE7PXYKA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BE7PXYKA'
    },
    {
      status: 210,
      sku: 'PM0063528',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353421',
      rtl_batch_array: '6301843, 6353421',
      name_search: 'SETMPURP',
      name: "Sedum t. 'Munstead Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 456,
      chnn_stock_retail: 571,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7XC4CWN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XC4CWN3'
    },
    {
      status: 210,
      sku: 'PM0065109',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301315',
      rtl_batch_array: '6350115, 6301315',
      name_search: 'ECVULGAR',
      name: 'Echium vulgare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 256,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '832ZZJ35;AB3BJXH5',
      statusContent: 'dot',
      hash: 'WTSL7RJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTSL7RJ9'
    },
    {
      status: 210,
      sku: 'PM0077833',
      core_name: 'Plant',
      sppl_ean: '8719223014863',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349417',
      rtl_batch_array: '4349417',
      name_search: 'ERICLEOP',
      name: "Eremurus isabellinus 'Cleopatra'",
      sppl_size_code: 'BOK1P10',
      rng_range_identifier: 'BU10PCS',
      rng_range_description: 'Bulb 10 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_order_minimum: 1,
      sppl_prcp: 17.193,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '2YPEY65K',
      statusContent: 'dot',
      hash: '4RN2W323',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RN2W323'
    },
    {
      status: 210,
      sku: 'PM0046277',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330046',
      rtl_batch_array: '6202839, 6330046',
      name_search: 'PHMLBELL',
      name: "Phlox (P) 'Monica Lynden-Bell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 476,
      chnn_stock_retail: 782,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6PPHDY5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PPHDY5B'
    },
    {
      status: 810,
      sku: 'PM0070976',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307471',
      rtl_batch_array: '6198285, 6307471',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 59,
      chnn_stock_retail: 109,
      sppl_prcp: 34.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'JBTR7GNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBTR7GNW'
    },
    {
      status: 210,
      sku: 'PM0077834',
      core_name: 'Plant',
      sppl_ean: '8719223021205',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349427',
      rtl_batch_array: '4349427',
      name_search: 'LEVERNUM',
      name: 'Leucojum vernum',
      sppl_size_code: 'BOK1P100',
      rng_range_identifier: 'BU100PCS',
      rng_range_description: 'Bulb 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 36.093,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AXCTWDXE;GR2XN5SL',
      imageBatch: 'SNPHK1V7',
      statusContent: 'dot',
      hash: 'W6KXHPRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6KXHPRV'
    },
    {
      status: 210,
      sku: 'PM0021747',
      core_name: 'Plant',
      sppl_ean: '8720349432721',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 5,
      btch_active_retail: '5364214',
      rtl_batch_array: '6196839, 6315076, 5364214, 6385486, 6339974',
      name_search: 'VEBLOLLI',
      name: "Verbena bonariensis 'Lollipop'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7904,
      chnn_stock_retail: 18753,
      sppl_order_minimum: 3,
      sppl_prcp: 0.878,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GVJLSHW;GKVZJA6N;ZA1BDD7V;LS7KZHLC;E1EK8TCG;L3LS49D2;8L9ZD89X;JERE7SHP;SLYE773K;GNZC7VRW;S453G263;CPPL5L76',
      statusContent: 'dot',
      hash: 'SW68RV73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW68RV73'
    },
    {
      status: 210,
      sku: 'PM0045869',
      core_name: 'Plant',
      sppl_ean: '8720664690868',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015122',
      rtl_batch_array: '6015122',
      name_search: 'GEMDBILT',
      name: "Geranium macrorrhizum 'De Bilt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S3Z8B59R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3Z8B59R'
    },
    {
      status: 210,
      sku: 'PM0077836',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4395918',
      rtl_batch_array: '4395918',
      name_search: 'EDI81897',
      name: "Tulipa (dea) 'Showcase'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L52W3P5R',
      statusContent: 'dot',
      hash: '9AZXDC5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AZXDC5L'
    },
    {
      status: 210,
      sku: 'PM0077837',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4395919',
      rtl_batch_array: '4395919',
      name_search: 'EDI78668',
      name: "Tulipa (trh) 'Mascara'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2LLKG8S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2LLKG8S'
    },
    {
      status: 910,
      sku: 'PM0070975',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307469',
      rtl_batch_array: '6307469, 6287266',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 6,
      chnn_stock_retail: 22,
      sppl_prcp: 18.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: '48SB71NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48SB71NS'
    },
    {
      status: 210,
      sku: 'PM0058061',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176291',
      rtl_batch_array: '6176291, 6290226',
      name_search: 'AGTWISTE',
      name: 'Agapanthus Twister',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1706,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '75J2DR6N;G4CB7SRV;9P1W2T9B;67LN29CJ',
      statusContent: 'dot',
      hash: 'CV26L1VR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV26L1VR'
    },
    {
      status: 810,
      sku: 'PM0045505',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301508',
      rtl_batch_array: '6301508',
      name_search: 'CHANASTA',
      name: "Chrysanthemum (I) 'Anastasia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6CBZSSGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CBZSSGY'
    },
    {
      status: 210,
      sku: 'PM0077838',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4395923',
      rtl_batch_array: '4395923',
      name_search: 'EDI76429',
      name: "Tulipa (dla) 'Danceline'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 26.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RENT3PNB;9C5T8W2G;4CT8ZLSV;VGY9DWP2;73XX6KJA;28ZX2DEL;5ZDTZD16;V5RPCJCT;X79VDBYW;ZJPHNDZC;P3WWCGPZ;62LLK4G6;SEWCCW3W',
      statusContent: 'dot',
      hash: 'A5WC97B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5WC97B8'
    },
    {
      status: 910,
      sku: 'PM0054364',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287582',
      rtl_batch_array: '6287582',
      name_search: 'SYVMLEMO',
      name: "Syringa vulgaris 'Monique Lemoine'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 12.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZTYZ7PL9',
      statusContent: 'dot',
      hash: '6313BH64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6313BH64'
    },
    {
      status: 210,
      sku: 'PM0077820',
      core_name: 'Plant',
      sppl_ean: '8719223013668',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349370',
      rtl_batch_array: '4349370',
      name_search: 'ALGLOBEM',
      name: "Allium 'Globemaster'",
      sppl_size_code: '020+BOP25',
      rng_range_identifier: 'BU\u00d820+25PCS',
      rng_range_description: 'Bulb \u00d8 20 cm + 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 60.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D72SYRWV;BLAA5BH8;4NSYTZWB;K1PS3395;B92HT1P7;YR3NJY95;EKJNHVRD;A9YLCEWH;YLCWV2JW;4R27735H;19145WJK;1DH62ED5;NKP8NGN5;2V753X93;53ZRP3T7;737RW9BD;6ALKLPJD;GHECVHHJ;86ZNVSSH;KP3WGNX9;245PZL9E;YW6WP5BP',
      imageBatch: 'WG7RL4JT',
      statusContent: 'dot',
      hash: 'YJ4KXYXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ4KXYXA'
    },
    {
      status: 210,
      sku: 'PM0058185',
      core_name: 'Plant',
      sppl_ean: '8720664864641',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112371',
      rtl_batch_array: '6112371',
      name_search: 'GEPRAIN',
      name: "Geranium hybrid 'Purple Rain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BJPT6KV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BJPT6KV'
    },
    {
      status: 210,
      sku: 'PM0077822',
      core_name: 'Plant',
      sppl_ean: '8719223016157',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349386',
      rtl_batch_array: '4349386',
      name_search: 'PULIBANO',
      name: 'Puschkinia Libanotica',
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 6.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8PZYWXBS',
      statusContent: 'dot',
      hash: 'E856VXVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E856VXVC'
    },
    {
      status: 210,
      sku: 'PM0064458',
      core_name: 'Plant',
      sppl_ean: '8720664872479',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151583',
      rtl_batch_array: '6151583',
      name_search: 'LIJROTUN',
      name: "Ligustrum japonicum 'Rotundifolium'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4Z838WZC;6S3YA3XV',
      statusContent: 'dot',
      hash: 'DEX2JPYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEX2JPYZ'
    },
    {
      status: 210,
      sku: 'PM0077826',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349404',
      rtl_batch_array: '4349404',
      name_search: 'HYMROSE',
      name: 'Hyacinthus multiflora rose',
      sppl_size_code: 'BOK1P25',
      rng_range_identifier: 'BU25PCS',
      rng_range_description: 'Bulb 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 22.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '75NB6BVL;CYDE66VN;XB4YPB11;X9E86DRC;DC4LE31Z;VXR5PPWE;492V8TZT;KH2XVEGH',
      statusContent: 'dot',
      hash: '7WZ6P9A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WZ6P9A4'
    },
    {
      status: 210,
      sku: 'PM0077827',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349405',
      rtl_batch_array: '4349405',
      name_search: 'HYMWIT',
      name: 'Hyacinthus multiflora wit',
      sppl_size_code: 'BOK1P25',
      rng_range_identifier: 'BU25PCS',
      rng_range_description: 'Bulb 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 22.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXDJEGP8;CAZ4XHD6;2EE4EWRB;7L9819R6;P7Y2R9S9;NAB8ZVYB;TCSZK59D;Z7RBVXC5;4CDY3CLG;AP1214H6',
      statusContent: 'dot',
      hash: 'XA6LDETL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA6LDETL'
    },
    {
      status: 910,
      sku: 'PM0066441',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: 'JYA2L291',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JYA2L291'
    },
    {
      status: 210,
      sku: 'PM0068564',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307499',
      rtl_batch_array: '6307499',
      name_search: 'JANUDIFL',
      name: 'Jasminum nudiflorum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 6038,
      chnn_stock_retail: 6038,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TKK7Y96C;EC55166Y;EHN4G591;R2N5X45G;NKRW2BK7;SAZ3BWN1;L125CLNR;TE197YB3',
      statusContent: 'dot',
      hash: 'P8DAYXKT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8DAYXKT'
    },
    {
      status: 210,
      sku: 'PM0077828',
      core_name: 'Plant',
      sppl_ean: '8719223013552',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349406',
      rtl_batch_array: '4349406',
      name_search: 'ALCERNUU',
      name: 'Allium cernuum',
      sppl_size_code: 'BOK1P100',
      rng_range_identifier: 'BU100PCS',
      rng_range_description: 'Bulb 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 36.093,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKZ9DLLX',
      imageBatch: 'VLTZG4V3',
      statusContent: 'dot',
      hash: '15T9JLNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15T9JLNW'
    },
    {
      status: 210,
      sku: 'PM0070015',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271404',
      rtl_batch_array: '6271404, 6218658',
      name_search: 'LEFORMOS',
      name: 'Leycesteria formosa',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1060,
      chnn_stock_retail: 1083,
      sppl_prcp: 2.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72LLZYLW;4LT85GG3;98BYDGT2;EA8EXSLG;DWBT25YS;CEY4EZNV;1391SWRD;9V7H93X2',
      statusContent: 'dot',
      hash: 'VAD5HL83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAD5HL83'
    },
    {
      status: 210,
      sku: 'PM0053500',
      core_name: 'Plant',
      sppl_ean: '8720664691278',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054643',
      rtl_batch_array: '6054643',
      name_search: 'GYKNUDDE',
      name: "Gypsophila 'Knuddel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '531ZXP4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '531ZXP4H'
    },
    {
      status: 210,
      sku: 'PM0077829',
      core_name: 'Plant',
      sppl_ean: '8719223013613',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349407',
      rtl_batch_array: '4349407',
      name_search: 'ALFLAVUM',
      name: 'Allium flavum',
      sppl_size_code: 'BOK1P100',
      rng_range_identifier: 'BU100PCS',
      rng_range_description: 'Bulb 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 38.793,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '49L82EVX;Z4E13TN4;GJRB4K4W;LA7XRTY1',
      imageBatch: 'C7KGW8B3',
      statusContent: 'dot',
      hash: 'W92Y2PGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W92Y2PGC'
    },
    {
      status: 810,
      sku: 'PM0070973',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307466',
      rtl_batch_array: '6307466',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 9.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'NAGRE6V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAGRE6V1'
    },
    {
      status: 210,
      sku: 'PM0077831',
      core_name: 'Plant',
      sppl_ean: '8719223014368',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349412',
      rtl_batch_array: '4349412',
      name_search: 'ARITALIC',
      name: 'Arum italicum',
      sppl_size_code: 'BOK1P100',
      rng_range_identifier: 'BU100PCS',
      rng_range_description: 'Bulb 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 31.593,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1SESN8V1;JZEZXGL6;RAPSL1GK;XVHGLYGN;SS1E7VYK;XNBJW6NH;E19B459G;LX21C156;K1SNLYES;RG7H8EN7;LJ1G4CSP;XB31K765;VTH3G7VJ;DAEYT2XA;NGDG34SR;18ZC7A5T;ZAV41AZH;5SY9729T;R88CW27D;LSEH4TKR;6G7KY3E7;2V11GVRJ;7TCSSCSG',
      imageBatch: 'X7B3XTWW',
      statusContent: 'dot',
      hash: 'GW22SXED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GW22SXED'
    },
    {
      status: 210,
      sku: 'PM0068702',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6336458',
      rtl_batch_array: '6207799, 6317826, 6336458, 6324692',
      name_search: 'ANTROBUS',
      name: "Anemone tomentosa 'Robustissima'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1618,
      chnn_stock_retail: 4748,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CPJT45S',
      statusContent: 'dot',
      hash: 'P874HAAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P874HAAW'
    },
    {
      status: 810,
      sku: 'PM0058134',
      core_name: 'Plant',
      sppl_ean: '8720664883420',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112266',
      rtl_batch_array: '6112266',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 5.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: '1KZSWA29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KZSWA29'
    },
    {
      status: 810,
      sku: 'PM0068704',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207801',
      rtl_batch_array: '6207801',
      name_search: 'ANMFPLEN',
      name: "Anemonopsis macrop. 'Flore Pleno'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_order_minimum: 3,
      sppl_prcp: 13.158,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12R77A4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12R77A4C'
    },
    {
      status: 810,
      sku: 'PM0046231',
      core_name: 'Plant',
      sppl_ean: '8720664874213',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080706',
      rtl_batch_array: '6080706',
      name_search: 'MARADICA',
      name: 'Mazus radicans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B691YVRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B691YVRP'
    },
    {
      status: 210,
      sku: 'PM0046242',
      core_name: 'Plant',
      sppl_ean: '8720353011363',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014166',
      rtl_batch_array: '6014166',
      name_search: 'MOAJMOST',
      name: 'Molinia arundinacea JH Mostenveld',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26CCPV2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26CCPV2G'
    },
    {
      status: 210,
      sku: 'PM0070021',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218713',
      rtl_batch_array: '6218713',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '100125C44',
      rng_range_identifier: 'H100125C4.5',
      rng_range_description: 'H100 cm 125 cm C4.5',
      sppl_stock_available: 963,
      chnn_stock_retail: 963,
      sppl_prcp: 4.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'WEP5B6KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEP5B6KY'
    },
    {
      status: 810,
      sku: 'PM0060158',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301738',
      rtl_batch_array: '6221373, 6301738',
      name_search: 'NENERVOS',
      name: 'Nepeta nervosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 89,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SC5VJTBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SC5VJTBT'
    },
    {
      status: 210,
      sku: 'PM0070022',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218714',
      rtl_batch_array: '6218714',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '125150C4',
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_prcp: 4.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'WYLBTJP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYLBTJP4'
    },
    {
      status: 810,
      sku: 'PM0057247',
      core_name: 'Plant',
      sppl_ean: '8720664868212',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091436',
      rtl_batch_array: '6091436',
      name_search: 'HOGACRES',
      name: "Hosta 'Green Acres'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XD1RVJGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD1RVJGC'
    },
    {
      status: 810,
      sku: 'PM0057446',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301842',
      rtl_batch_array: '6301842, 6353305',
      name_search: 'SETMARIN',
      name: "Sedum telephium 'Marina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7C1N3P8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7C1N3P8J'
    },
    {
      status: 210,
      sku: 'PM0070971',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307458',
      rtl_batch_array: '6280971, 6307458',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 124,
      chnn_stock_retail: 174,
      sppl_prcp: 14.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'Y8DWH5EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8DWH5EC'
    },
    {
      status: 210,
      sku: 'PM0057429',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349148',
      rtl_batch_array: '6349148, 6267763, 6301818, 6330094',
      name_search: 'SAMINOR',
      name: 'Sanguisorba minor',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 936,
      chnn_stock_retail: 3032,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ET64G1L2;55ALK435;YB43T66S;H6JGDLNN;V7HXPLRV;YEVXL424;T3XDE2AL',
      statusContent: 'dot',
      hash: 'XN2CZ9RG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XN2CZ9RG'
    },
    {
      status: 210,
      sku: 'PM0069108',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 7,
      btch_active_retail: '6300215',
      rtl_batch_array:
        '6299856, 6300215, 6286682, 6354799, 6355658, 6294852, 6348834',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2611,
      chnn_stock_retail: 16261,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'DWRNA63S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWRNA63S'
    },
    {
      status: 210,
      sku: 'PM0058359',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350588',
      rtl_batch_array: '6350588',
      name_search: 'ISUDFORM',
      name: "Isodon umbrosus 'Dark Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 341,
      chnn_stock_retail: 341,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DCBD2WZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCBD2WZ2'
    },
    {
      status: 210,
      sku: 'PM0045467',
      core_name: 'Plant',
      sppl_ean: '8720626392021',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014404',
      rtl_batch_array: '6014404',
      name_search: 'ASDBASS',
      name: "Astilbe (A) 'Drum and Bass'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X7EV4JZ;SR5LK64X',
      statusContent: 'dot',
      hash: '7VB88BG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VB88BG9'
    },
    {
      status: 210,
      sku: 'PM0081281',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339788',
      rtl_batch_array: '6339788',
      name_search: 'CORSATIN',
      name: "Coreopsis 'Red Satin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 516,
      chnn_stock_retail: 516,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7ZZWGX2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZZWGX2T'
    },
    {
      status: 210,
      sku: 'PM0077839',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4684910',
      rtl_batch_array: '4684910',
      name_search: 'EDI80614',
      name: "Tulipa (trh) 'Spitsbergen'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 16.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GG9BCHHY;SJ8WJT11;6K557L17;DX969TNB;RPE42LHR',
      statusContent: 'dot',
      hash: 'HWTTCV8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWTTCV8D'
    },
    {
      status: 210,
      sku: 'PM0058202',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319709',
      rtl_batch_array: '6350323, 6319709',
      name_search: 'HELSALIC',
      name: 'Helianthus salicifolius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 204,
      chnn_stock_retail: 1021,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWK2RYS6;6W4PN2XP;9Z4HGJEH;SR289H28;NWT1ZGL2;JGWSXDPV;NGZ3R3L1;2CBS42ZT;S6Z6JB7N',
      statusContent: 'dot',
      hash: 'H79CCAXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H79CCAXP'
    },
    {
      status: 210,
      sku: 'PM0048203',
      core_name: 'Plant',
      sppl_ean: '8720664681798',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014606',
      rtl_batch_array: '6014606',
      name_search: 'BEDAVID',
      name: "Bergenia 'David'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WW8YZG2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW8YZG2Z'
    },
    {
      status: 810,
      sku: 'PM0069109',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6211600',
      rtl_batch_array: '5932498, 6276625, 6211600',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 15860,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'E5NC3J56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5NC3J56'
    },
    {
      status: 210,
      sku: 'PM0077842',
      core_name: 'Plant',
      sppl_ean: '8719223030405',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4685514',
      rtl_batch_array: '4685514',
      name_search: 'NARAPTUR',
      name: 'Narcissus Rapture',
      sppl_size_code: '010012BOP10',
      rng_range_identifier: 'BU\u00d810012100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HAXX68HY;A14Z485H;K48SY9TA;LKAKTWB5;ZJH9V2DH;XKWHX569;BYZN3LAY',
      statusContent: 'dot',
      hash: '9XEBS5WD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XEBS5WD'
    },
    {
      status: 210,
      sku: 'PM0077843',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687129',
      rtl_batch_array: '4687129',
      name_search: 'EDI76904',
      name: "Hyacinthus orientalis 'Aqua'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CCS4HETE;DCAPS5K1;WCG76SR5;YTG1X1CX;5N7VSDY4;JT1R6TZ1',
      statusContent: 'dot',
      hash: 'RHP1J37D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHP1J37D'
    },
    {
      status: 210,
      sku: 'PM0077844',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687131',
      rtl_batch_array: '4687131',
      name_search: 'EDI80305',
      name: "Hyacinthus orientalis 'Blue Trophy'",
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LZ3AZC2T;6L3EKWZ5;H2WPAXTY;K93LNYXR;6EKTDR5X;Y5Z5X5TB;HGS254XN;83XBEA7K',
      statusContent: 'dot',
      hash: '6Z1KK534',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z1KK534'
    },
    {
      status: 210,
      sku: 'PM0065807',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212008',
      rtl_batch_array: '6212008',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 17.928,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'KHR42XR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHR42XR4'
    },
    {
      status: 210,
      sku: 'PM0077845',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687145',
      rtl_batch_array: '4687145',
      name_search: 'EDI79321',
      name: "Tulipa (trh) 'Request'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 17.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'STDBG3Y5;T55ZY9H4;PGVH2VBE;XKR5P2J3;K59NZVY4;PCCR5V31;KC3KW7X2;9T8XBJZ5;PJ2AB6RN;91W688S4;ZDPY386V;XZJH9XY6',
      imageBatch: 'K68AEDVX',
      statusContent: 'dot',
      hash: '9L97WXED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L97WXED'
    },
    {
      status: 210,
      sku: 'PM0077846',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687146',
      rtl_batch_array: '4687146',
      name_search: 'EDI67882',
      name: "Tulipa (trh) 'Royal Virgin'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 14.453,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EX5HK28J;LY6BL8LA;PABXKJ23;STKLREAB;8NYAHAVV;4WTYV7BD;R4KH2BRT;8RHT2R9E;W972VDGW',
      statusContent: 'dot',
      hash: '7HYHRCYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HYHRCYW'
    },
    {
      status: 210,
      sku: 'PM0081282',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6181377',
      rtl_batch_array: '6339789, 6181377',
      name_search: 'CAGALBA',
      name: "Campanula glomerata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 446,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T6Y3WKGJ;SP3LHX9B;1HKSZPBZ;PL3ZXGEH;GNENTY9P;SBSYPCPE;7VE2BL2Y;KD8WXJHA;1LEB9VW4;1BKYXL6K;BJ2PY7CT;D3VRTVJ8;GDZ1X46H;J6K7GP3V;VK1LSZ1G;X2DD8LAZ;LCZTTAYG;RYY4D87D;Y4YGNDRL;E7NEV334',
      statusContent: 'dot',
      hash: 'V5SJYZ1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5SJYZ1Z'
    },
    {
      status: 910,
      sku: 'PM0065131',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEWASDEL',
      name: 'Geranium w. All Summer Delight',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'TLPPCHV8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TLPPCHV8'
    },
    {
      status: 210,
      sku: 'PM0052790',
      core_name: 'Plant',
      sppl_ean: '8720664689657',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6044908',
      rtl_batch_array: '6044908',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100120C2GEB',
      rng_range_identifier: 'H100120C2',
      rng_range_description: 'H100 cm 120 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.109,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '9BWYCX81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BWYCX81'
    },
    {
      status: 210,
      sku: 'PM0077847',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687158',
      rtl_batch_array: '4687158',
      name_search: 'TULBMIX',
      name: 'Tulipa Leliebloemig mix',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BT1RBSXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BT1RBSXJ'
    },
    {
      status: 210,
      sku: 'PM0052308',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217234',
      rtl_batch_array: '6217234',
      name_search: 'VIOBBLUE',
      name: "Viola (C) 'Boughton Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5KDKDEC9',
      statusContent: 'dot',
      hash: 'GK5Y8Z69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GK5Y8Z69'
    },
    {
      status: 210,
      sku: 'PM0046405',
      core_name: 'Plant',
      sppl_ean: '8720664881570',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '5932464',
      rtl_batch_array: '5932464, 6295819, 6353227',
      name_search: 'POTNUUK',
      name: "Potentilla tridentata 'Nuuk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7033,
      chnn_stock_retail: 10784,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5VGJ8ZB;K4K9K2E3',
      statusContent: 'dot',
      hash: 'W6X5ZPD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6X5ZPD5'
    },
    {
      status: 210,
      sku: 'PM0077848',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687179',
      rtl_batch_array: '4687179',
      name_search: 'EDI60653',
      name: "Tulipa (kau) 'Johann Strauss'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SKC6X4SK;N7E5GGPN;YTTD3BZV;9L8EXBHX;ETPH3LP9;HN213BNH;WDLER7HD;2WN5G3WZ;4WG9THCG;LVR9CVLK;4R8V5CVY;TN7GSBND;N3LSDXL9;YX3NBX7Z;Y8ZGRX4W;G8NRJP6R;E5J4VREC;RDRR69JH;C9VHKXC6',
      statusContent: 'dot',
      hash: 'J8EB685A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8EB685A'
    },
    {
      status: 210,
      sku: 'PM0077850',
      core_name: 'Plant',
      sppl_ean: '8719223030610',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687783',
      rtl_batch_array: '4687783',
      name_search: 'LIREGALE',
      name: 'Lilium regale',
      sppl_size_code: '018020BOP25',
      rng_range_identifier: 'BU\u00d81802025PCS',
      rng_range_description: 'Bulb \u00d8 18 cm 20 cm 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 23.416,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XPH3H811;HKEKZX9D;TCB4D1XN;ABDN2TX9;1VVKY4VD;YKLZXKJP;L35Z2DPW;5VH9WW2D;NBK8ZBVZ',
      imageBatch: 'RKVDT3HB',
      statusContent: 'dot',
      hash: '7VWJBXAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VWJBXAX'
    },
    {
      status: 210,
      sku: 'PM0077851',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702558',
      rtl_batch_array: '4702558',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      imageBatch: 'G5TAJ8LA',
      statusContent: 'dot',
      hash: '71N2NNRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71N2NNRX'
    },
    {
      status: 210,
      sku: 'PM0039665',
      core_name: 'Plant',
      sppl_ean: '8720664683532',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905503',
      rtl_batch_array: '5905503',
      name_search: 'CAREX',
      name: 'Carex',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2WBWY24H;DDR5529A',
      statusContent: 'dot',
      hash: 'K827PZW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K827PZW9'
    },
    {
      status: 910,
      sku: 'PM0066447',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: '7SK9JKN9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7SK9JKN9'
    },
    {
      status: 210,
      sku: 'PM0077852',
      core_name: 'Plant',
      sppl_ean: '8720626312708',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702559',
      rtl_batch_array: '4702559',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      imageBatch: 'H2K7GDSB',
      statusContent: 'dot',
      hash: '2KC11NX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KC11NX6'
    },
    {
      status: 210,
      sku: 'PM0084901',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369661',
      rtl_batch_array: '6369661',
      name_search: 'HYACBUBB',
      name: 'Hydrangea arborescens Candybelle Bubblegum',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AVNXBZJT;RGNAGNYD;W3TT1XJX;J3R56BY3;DX2G7PHG;TE3B5K9E;ZRB7K7E3;9ZN7XNVL;XZX78ZPG;B73CWL2A',
      statusContent: 'dot',
      hash: 'PY24NPK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY24NPK8'
    },
    {
      status: 910,
      sku: 'PM0048264',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CORICHAR',
      name: 'Cortaderia richardii',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'XP2ZYDA5',
      statusContent: 'dot',
      hash: 'VV15JHCS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VV15JHCS'
    },
    {
      status: 210,
      sku: 'PM0081283',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339791',
      rtl_batch_array: '6339791',
      name_search: 'DIRCOOLV',
      name: 'Dianella revoluta Coolvista',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V4J8D1CK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4J8D1CK'
    },
    {
      status: 210,
      sku: 'PM0077853',
      core_name: 'Plant',
      sppl_ean: '8720664699335',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702562',
      rtl_batch_array: '4702562',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      imageBatch: 'WX4KH7PS',
      statusContent: 'dot',
      hash: 'SEEW5DB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEEW5DB8'
    },
    {
      status: 210,
      sku: 'PM0077854',
      core_name: 'Plant',
      sppl_ean: '8720626312814',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702564',
      rtl_batch_array: '4702564',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      imageBatch: 'EX6C7ZCR',
      statusContent: 'dot',
      hash: 'D13TNKWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D13TNKWV'
    },
    {
      status: 210,
      sku: 'PM0045667',
      core_name: 'Plant',
      sppl_ean: '8720664688421',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6037873',
      rtl_batch_array: '6350078, 6037873, 6301551',
      name_search: 'ECPRUBIN',
      name: "Echinacea purpurea 'Rubinstern'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1415,
      chnn_stock_retail: 4048,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8HJV3D4P;Y6T5V5BD',
      statusContent: 'dot',
      hash: 'L58GG9NZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L58GG9NZ'
    },
    {
      status: 210,
      sku: 'PM0046335',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353161',
      rtl_batch_array: '6353161',
      name_search: 'PHALEXAN',
      name: "Phlox (P) 'Alexandra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SPDE18YH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPDE18YH'
    },
    {
      status: 210,
      sku: 'PM0081284',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339794',
      rtl_batch_array: '6339794',
      name_search: 'EQFLUVIA',
      name: 'Equisetum fluviatile',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '16XB29GT;YTE4E5SW;EJPRTV84;1ZVZ5ALJ;KJ96845R',
      statusContent: 'dot',
      hash: '7Y69YA7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y69YA7H'
    },
    {
      status: 210,
      sku: 'PM0085096',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371148',
      rtl_batch_array: '6371148',
      name_search: 'CAOGOLDW',
      name: "Carex oshim. 'Goldwell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WNLR577D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNLR577D'
    },
    {
      status: 210,
      sku: 'PM0077856',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308803',
      rtl_batch_array: '5837445, 5382003, 6308803',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 371,
      chnn_stock_retail: 2715,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: 'WW13B958',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW13B958'
    },
    {
      status: 810,
      sku: 'PM0065810',
      core_name: 'Plant',
      sppl_ean: '8720664850033',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169425',
      rtl_batch_array: '6169425',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.717,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'LJ5A3669',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ5A3669'
    },
    {
      status: 210,
      sku: 'PM0060162',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6276035',
      rtl_batch_array: '5364466, 6276035, 6291735, 6339930',
      name_search: 'RUNHERBS',
      name: "Rudbeckia nitida 'Herbstsonne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 599,
      chnn_stock_retail: 2466,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RBB2LSY4;9WS8N51P;5KK7Y2ET;X1L8E7KA;WGY5XY2E',
      statusContent: 'dot',
      hash: '1SBBP8Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SBBP8Y3'
    },
    {
      status: 210,
      sku: 'PM0085097',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371149',
      rtl_batch_array: '6371149',
      name_search: 'CAUTTHIN',
      name: "Carex umbrosa 'Thinny Thin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R892HG6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R892HG6G'
    },
    {
      status: 210,
      sku: 'PM0058203',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301634',
      rtl_batch_array: '6350325, 6301634',
      name_search: 'HEHASAHI',
      name: "Heliopsis helianthoides 'Asahi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 172,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1ACCSAP',
      statusContent: 'dot',
      hash: 'G95GDP23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G95GDP23'
    },
    {
      status: 210,
      sku: 'PM0077857',
      core_name: 'Plant',
      sppl_ean: '8720349406432',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5430083',
      rtl_batch_array: '5430083',
      name_search: 'MOATRANS',
      name: "Molinia arundinacea 'Transparent'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALG98ZE6;GCJGRDK7;SHRZ2K6R;7JPK1P1J;417K9K93;S3N8HNWN;TBBET22K;HEJYBATD;H29E7PZH',
      imageBatch: '4KAKB7YB',
      statusContent: 'dot',
      hash: 'BLNWKKVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLNWKKVA'
    },
    {
      status: 210,
      sku: 'PM0081285',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339795',
      rtl_batch_array: '6339795',
      name_search: 'EQHYEMAL',
      name: 'Equisetum hyemale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX6VC5E3;Z5G274VT;NY7ASCKR;2GRD6WTW;2YNY9DT8;W8AK4N2G;ET2TDL5G;XCT2HERY;AV6BWTLJ;GEKAVTVT;BJ7S36Z3;C3WLL5ZX;8TKBCR67;4P969WCP;N4W62B5Y;7GE4Y4E4',
      statusContent: 'dot',
      hash: '6DWZ83RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DWZ83RA'
    },
    {
      status: 210,
      sku: 'PM0046330',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301559',
      rtl_batch_array: '6301559',
      name_search: 'PHOCHSEN',
      name: "Phlox (D) 'Ochsenblut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6X4RZTX',
      statusContent: 'dot',
      hash: '1G9YLNTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G9YLNTH'
    },
    {
      status: 210,
      sku: 'PM0057469',
      core_name: 'Plant',
      sppl_ean: '8720664886643',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133735',
      rtl_batch_array: '6133735',
      name_search: 'SONWSQUA',
      name: 'Sorghastrum nutans Winnetous Squaw',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2W8EY4GN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2W8EY4GN'
    },
    {
      status: 810,
      sku: 'PM0077858',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367979',
      rtl_batch_array: '6367979',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 11.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'NB9WYPK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB9WYPK1'
    },
    {
      status: 910,
      sku: 'PM0077859',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPLQFIR',
      name: 'Hydrangea paniculata Little Quick Fire',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: '4SDK8NDL;4386ZPWE;SR1WBTD5;PXTX12BG;AE6LZ6HN',
      statusContent: 'dot',
      hash: 'Y7Y5N7YR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y7Y5N7YR'
    },
    {
      status: 210,
      sku: 'PM0084902',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369662',
      rtl_batch_array: '6369662',
      name_search: 'COALRSTA',
      name: 'Cordyline aus. Little Red Star',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SYV561K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYV561K5'
    },
    {
      status: 210,
      sku: 'PM0065138',
      core_name: 'Plant',
      sppl_ean: '8720664864948',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161708',
      rtl_batch_array: '6161708',
      name_search: 'GEPBBEAU',
      name: 'Geranium pratense Black Beauty',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 822,
      chnn_stock_retail: 822,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CAY1YKSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAY1YKSN'
    },
    {
      status: 910,
      sku: 'PM0077860',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WISALBA',
      name: "Wisteria sinensis 'Alba'",
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      imageCore:
        '5C98R9CR;1XASNEWH;AZ21HDHY;TCX6T96W;TG5VBKPH;J6KXJSER;AJ6JGEJE;A6J9ETBZ;PLC4N6SS;GRLXX496',
      statusContent: 'dot',
      hash: 'HE5BSZJW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HE5BSZJW'
    },
    {
      status: 210,
      sku: 'PM0048154',
      core_name: 'Plant',
      sppl_ean: '8720626392069',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014405',
      rtl_batch_array: '6014405',
      name_search: 'ASICECRE',
      name: "Astilbe (A) 'Icecream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NTVX7V7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTVX7V7C'
    },
    {
      status: 210,
      sku: 'PM0064461',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252690',
      rtl_batch_array: '6151588, 6252690',
      name_search: 'MOAKFOER',
      name: "Molinia arundinacea 'Karl Foerster'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1381,
      chnn_stock_retail: 11021,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RYY9XHX8;4Z1GG7NG;2EVDY2G3;DAGD582L;CE33VY79;VB9X6CZ4;Z1E1584H;CBATDK6R;E8PYECE4;7SVVWBK2;H9ZL2RJS;J3KBTD3S;W4DSYPB1',
      statusContent: 'dot',
      hash: 'GENYGYR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GENYGYR7'
    },
    {
      status: 210,
      sku: 'PM0068710',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207809',
      rtl_batch_array: '6207809',
      name_search: 'ARSIKOKI',
      name: 'Arisaema sikokianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 3,
      sppl_prcp: 7.098,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1SRBJRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1SRBJRD'
    },
    {
      status: 210,
      sku: 'PM0077861',
      core_name: 'Plant',
      sppl_ean: '8720664663305',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484317',
      rtl_batch_array: '5484317',
      name_search: 'HYACINTH',
      name: 'Hyacinthus Mix',
      sppl_size_code: '015016BOP50',
      rng_range_identifier: 'BU\u00d81501650PCS',
      rng_range_description: 'Bulb \u00d8 15 cm 16 cm 50 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TVBXJ9XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVBXJ9XE'
    },
    {
      status: 810,
      sku: 'PM0085098',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371150',
      rtl_batch_array: '6371150',
      name_search: 'CLBRIBBO',
      name: "Clematis (I) 'Blue Ribbons'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXR9JSRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXR9JSRW'
    },
    {
      status: 210,
      sku: 'PM0077862',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484328',
      rtl_batch_array: '5484328',
      name_search: 'TUDMIX',
      name: 'Tulipa Darwin hyrb. Mix',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44R26BH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44R26BH4'
    },
    {
      status: 210,
      sku: 'PM0081286',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339796',
      rtl_batch_array: '6339796',
      name_search: 'EQHROBUS',
      name: 'Equisetum hyemale robustum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97AWKX4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97AWKX4E'
    },
    {
      status: 210,
      sku: 'PM0077863',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484338',
      rtl_batch_array: '5484338',
      name_search: 'EDI60111',
      name: "Tulipa bakeri (msc) 'Lilac Wonder'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.901,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GBDJJTB;7RSBGCDP;BKHLPYRY;DT6G2EP8;ZX5PRYHZ;LHZN88ZX;ZY2WHXBC;R9BCRB5B;642RPR7J;VV4R22WX;58HE9KH7;APY1V16D;582HCWGZ;1S5D9T8G;5EYVJB56;TVC9LCDW;R34RVA2Y;S1RT27BJ;JRH9X87J;SPK76TDC;2ARDDPP9;PZA54X8C;EW74RSC3;2YNS7A12;73KBGCS1;715L5R5V;R81LZY83',
      statusContent: 'dot',
      hash: 'D7N7V1RV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7N7V1RV'
    },
    {
      status: 910,
      sku: 'PM0077879',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'LSR5APWR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LSR5APWR'
    },
    {
      status: 210,
      sku: 'PM0048191',
      core_name: 'Plant',
      sppl_ean: '8720353088044',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014577',
      rtl_batch_array: '6014577',
      name_search: 'ASELISTE',
      name: 'Astilbe Easy Listening',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEJBX5YJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEJBX5YJ'
    },
    {
      status: 210,
      sku: 'PM0077864',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484341',
      rtl_batch_array: '5484341',
      name_search: 'EDI60726',
      name: "Tulipa (msc) 'Little Beauty'",
      sppl_size_code: '006+BOP100',
      rng_range_identifier: 'BU\u00d86+100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.901,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A3C55Z2A;DCEVPRR4;E9ZG8DNT;L7HVDBRZ;9431K8NB;L4LE4R7S;S2PWT65G;GT4YRX74;5H99GJKE;YL9H33AR;BTDRV1PH;J24R52D1;LD9R9GW5;TNNLJLB1;NET61ZH6;1VGXTDAG;DYVCAT14',
      statusContent: 'dot',
      hash: 'YG7EP986',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YG7EP986'
    },
    {
      status: 810,
      sku: 'PM0048255',
      core_name: 'Plant',
      sppl_ean: '8720349496228',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014746',
      rtl_batch_array: '6014746',
      name_search: 'CHKARMIN',
      name: "Chrysanthemum (I) 'Karminsilber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1G3HXB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1G3HXB7'
    },
    {
      status: 210,
      sku: 'PM0077865',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484344',
      rtl_batch_array: '5484344',
      name_search: 'EDI61247',
      name: 'Tulipa tarda (msc)',
      sppl_size_code: '007008BOP10',
      rng_range_identifier: 'BU\u00d87008100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm 8 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7B67JA97;2671KV6P;LEXTCXWZ;P9JVWTLY;GG9HTG7W;8582CVHJ;T1S2E9VL;621WCHP5;ENB1AJC9;4LYXVWSD;EYL5VZLX;E5WPWAGS;649XPLB4;WYH8GR3B;LG9DBSJ9;5B62Z3S4;DR3L441K;C2DTLV4S',
      statusContent: 'dot',
      hash: '465YAP78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '465YAP78'
    },
    {
      status: 810,
      sku: 'PM0085099',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371151',
      rtl_batch_array: '6371151',
      name_search: 'CLGLETSC',
      name: "Clematis (I) 'Gletschereis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J8DY2L1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8DY2L1Y'
    },
    {
      status: 210,
      sku: 'PM0077866',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484348',
      rtl_batch_array: '5484348',
      name_search: 'EDI61662',
      name: "Crocus tommasinianus 'Whitewell Purple'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 6.301,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1J4V4VK9;KNBK2648;2C2B7SHW;77255SVB;5CHDED3E;NXWS25K1;BHJ5E446;BSNB4WYS;5XXAS4HT;72ET4LDW;XBACGX5Y;8Y4WXPCT;GTCR4C7L;5WKW92Y6;A7BY9DXE;5VC5EKY5;8C24CA6D;T55B4LV4;C8WAYKX6',
      statusContent: 'dot',
      hash: '8C97WR7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8C97WR7P'
    },
    {
      status: 210,
      sku: 'PM0077867',
      core_name: 'Plant',
      sppl_ean: '8719223032225',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484351',
      rtl_batch_array: '5484351',
      name_search: 'EDI83762',
      name: "Allium schubertii 'Magic'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 1,
      sppl_prcp: 18.004,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEH1SNZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEH1SNZH'
    },
    {
      status: 210,
      sku: 'PM0085100',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371152',
      rtl_batch_array: '6371152',
      name_search: 'CLVNIGHT',
      name: "Clematis 'Velvet Night'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '25WAP8JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25WAP8JE'
    },
    {
      status: 210,
      sku: 'PM0077869',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484363',
      rtl_batch_array: '5484363',
      name_search: 'EDI69122',
      name: 'Hyacinthoides non-scripta',
      sppl_size_code: '007008BOP10',
      rng_range_identifier: 'BU\u00d87008100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm 8 cm 100 Pieces',
      sppl_stock_available: 99992,
      chnn_stock_retail: 99992,
      sppl_prcp: 13.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1BWBYNY;29BE4RY1;2AL9C8N7;EJ5YR6ZZ;NESC24X1;EDJ12G3H;93231NEB;4HJ2VDK9;AR39TTPH;JJPWBAWB;C4KGL9EY;EK9K8X8E;9LA11B1W;JW4BDELA;AHVL75XP',
      statusContent: 'dot',
      hash: '1RC1A6K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RC1A6K6'
    },
    {
      status: 210,
      sku: 'PM0077871',
      core_name: 'Plant',
      sppl_ean: '8719223030665',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484366',
      rtl_batch_array: '5484366',
      name_search: 'MUSCARI',
      name: 'Muscari',
      sppl_size_code: '008009BOP10',
      rng_range_identifier: 'BU\u00d88009100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 9 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CV1DWZBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV1DWZBJ'
    },
    {
      status: 210,
      sku: 'PM0077872',
      core_name: 'Plant',
      sppl_ean: '8720626314825',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488228',
      rtl_batch_array: '5488228',
      name_search: 'PEMRBTAI',
      name: 'Pennisetum massaicum Red Bunny Tails',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LKDN6XX5;CEC8AJ8W',
      imageBatch: '64ZH3ATN',
      statusContent: 'dot',
      hash: 'EHV7E5Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHV7E5Y3'
    },
    {
      status: 210,
      sku: 'PM0077873',
      core_name: 'Plant',
      sppl_ean: '8720664699397',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488233',
      rtl_batch_array: '5488233',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      imageBatch: 'KA14H3PA',
      statusContent: 'dot',
      hash: 'HK226ACG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HK226ACG'
    },
    {
      status: 210,
      sku: 'PM0085101',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371153',
      rtl_batch_array: '6371153',
      name_search: 'COREDSHI',
      name: "Coreopsis 'Redshift'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 405,
      chnn_stock_retail: 405,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6A9ZGAB',
      statusContent: 'dot',
      hash: 'CH1TNADG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH1TNADG'
    },
    {
      status: 210,
      sku: 'PM0081287',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339798',
      rtl_batch_array: '6339798',
      name_search: 'EROFLAME',
      name: "Erysimum 'Orange Flame'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 595,
      chnn_stock_retail: 595,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TR4E57K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR4E57K8'
    },
    {
      status: 810,
      sku: 'PM0077875',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302300',
      rtl_batch_array: '6302300',
      name_search: 'ACJSDANC',
      name: "Actaea japonica 'Silver Dance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 2.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GX5JP5BA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GX5JP5BA'
    },
    {
      status: 210,
      sku: 'PM0077877',
      core_name: 'Plant',
      sppl_ean: '8720626359536',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5519731',
      rtl_batch_array: '5519731',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      imageBatch: 'D616VGBK',
      statusContent: 'dot',
      hash: 'WCA6WCEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCA6WCEW'
    },
    {
      status: 810,
      sku: 'PM0070977',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280989',
      rtl_batch_array: '6280989, 6307473',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 67,
      sppl_prcp: 14.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'W1W7YH76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1W7YH76'
    },
    {
      status: 210,
      sku: 'PM0046404',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301577',
      rtl_batch_array: '6301577',
      name_search: 'POTONGUE',
      name: 'Potentilla tonguei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LG26LCWJ;B5E1W8TV;LEG7ADN9;LCWHD43P;Z5EWGTSY;EY4RW4N7;2WJ6GGWT;ZJ47B7RJ;RAP9D2CV',
      statusContent: 'dot',
      hash: 'TLED5BDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLED5BDG'
    },
    {
      status: 210,
      sku: 'PM0046345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353096',
      rtl_batch_array: '6353096',
      name_search: 'PHSSOCEA',
      name: 'Phlox (P) Sweet Summer Ocean',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 734,
      chnn_stock_retail: 734,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NLZ18B72;6L89CXTN',
      statusContent: 'dot',
      hash: '289565DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '289565DN'
    },
    {
      status: 810,
      sku: 'PM0065812',
      core_name: 'Plant',
      sppl_ean: '8720664849907',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169427',
      rtl_batch_array: '6169427',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.547,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '9XHA1JZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XHA1JZW'
    },
    {
      status: 210,
      sku: 'PM0077840',
      core_name: 'Plant',
      sppl_ean: '8719223030474',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4685009',
      rtl_batch_array: '4685009',
      name_search: 'ALTUBERO',
      name: 'Allium tuberosum',
      sppl_size_code: 'BOK1P100',
      rng_range_identifier: 'BU100PCS',
      rng_range_description: 'Bulb 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 54.093,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98SV9Y8N;A8D4TW6Z;AK1V5ZTH;A5CC327R;W4JDCB8E;1HHDDX1G;N43VX5BZ;LSZ3ZPAG;Z13VS43E;CSH4B183;1PHK75WX;S4VYKSET;NATLH2WT;DLYAA28A;44V9SDHE',
      imageBatch: 'K559W6SY',
      statusContent: 'dot',
      hash: 'KZBNNDWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZBNNDWC'
    },
    {
      status: 210,
      sku: 'PM0077841',
      core_name: 'Plant',
      sppl_ean: '8719223028952',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4685201',
      rtl_batch_array: '4685201',
      name_search: 'ALOSTARA',
      name: "Allium 'Ostara'",
      sppl_size_code: '012+BOP25',
      rng_range_identifier: 'BU\u00d812+25PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 10.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2XWD57TB;WR5NLJAR;GTB5D4HX;PGXWZ75S;AG9ZZVVS;SJ5EN8CR;KZ5N39S9;GS17WNVS;WND3B61X;L6LHLTWJ;VHYA4VVE;K68WBH3E',
      imageBatch: 'YV9397YE',
      statusContent: 'dot',
      hash: '141ET6S4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '141ET6S4'
    },
    {
      status: 210,
      sku: 'PM0077849',
      core_name: 'Plant',
      sppl_ean: '8719223030481',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4687577',
      rtl_batch_array: '4687577',
      name_search: 'HYHISPAN',
      name: 'Hyacinthoides hispanica',
      sppl_size_code: '008010BOP10',
      rng_range_identifier: 'BU\u00d88010100PCS',
      rng_range_description: 'Bulb \u00d8 8 cm 10 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VDWNBNJ7;6VCRJVLT;TH5ZRVSD;BECCB3C9;KRZR5VGR;CDDA7W27;C5X4EVPL;XW6WNYCZ;9KS39GW8;ATBPJX1X;HNEVTAAP;723CNSP7;B9ZVNC1Z;BJV7ELW7;498X833P;XBXNNADL;YSXH4725;6A9TH9J8;LJDSSBE8;97AS6G7E;GH755WTW',
      imageBatch: '5X1YGK1W',
      statusContent: 'dot',
      hash: '7GE5G93B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GE5G93B'
    },
    {
      status: 210,
      sku: 'PM0077868',
      core_name: 'Plant',
      sppl_ean: '8719223013989',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484357',
      rtl_batch_array: '5484357',
      name_search: 'ALURSINU',
      name: 'Allium ursinum',
      sppl_size_code: 'BOK1P100',
      rng_range_identifier: 'BU100PCS',
      rng_range_description: 'Bulb 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 20.793,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9EC3TW14;WV1YZ916;RT8LSDG8',
      statusContent: 'dot',
      hash: 'S2599Z7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2599Z7B'
    },
    {
      status: 810,
      sku: 'PM0065117',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301335',
      rtl_batch_array: '6161674, 6301335',
      name_search: 'EUCBPEAR',
      name: "Euphorbia characias 'Black Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 317,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WR4Z9SCW',
      statusContent: 'dot',
      hash: '2H9WHRWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2H9WHRWP'
    },
    {
      status: 210,
      sku: 'PM0077870',
      core_name: 'Plant',
      sppl_ean: '8719223032324',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484364',
      rtl_batch_array: '5484364',
      name_search: 'LICANDID',
      name: 'Lilium candidum',
      sppl_size_code: '020024BOP25',
      rng_range_identifier: 'BU\u00d82002425PCS',
      rng_range_description: 'Bulb \u00d8 20 cm 24 cm 25 Pieces',
      sppl_stock_available: 99998,
      chnn_stock_retail: 99998,
      sppl_prcp: 54.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JG61LZ57;W1Y2V3BJ;XKPBYLGA;K9JCXXSV;GJXB282G',
      statusContent: 'dot',
      hash: 'YTEZHZAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTEZHZAB'
    },
    {
      status: 810,
      sku: 'PM0065811',
      core_name: 'Plant',
      sppl_ean: '8720664850088',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169426',
      rtl_batch_array: '6169426',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: 'PSXGG87T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSXGG87T'
    },
    {
      status: 210,
      sku: 'PM0066455',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376232',
      rtl_batch_array: '6376232',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: '5ZNVPC7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZNVPC7E'
    },
    {
      status: 210,
      sku: 'PM0077878',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305953',
      rtl_batch_array: '6305953',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '020025P9',
      rng_range_identifier: 'H020025P9',
      rng_range_description: 'H20 cm 25 cm P9',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 6,
      sppl_prcp: 0.612,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'TDN8SNWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDN8SNWG'
    },
    {
      status: 910,
      sku: 'PM0068006',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACSCARBO',
      name: "Actaea simplex 'Carbonella'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'PRCHBD1E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PRCHBD1E'
    },
    {
      status: 210,
      sku: 'PM0065135',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207983',
      rtl_batch_array: '6207983, 6187587',
      name_search: 'GEMANEMO',
      name: "Geranium magnificum 'Anemoneflorum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '83CK3GZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83CK3GZC'
    },
    {
      status: 810,
      sku: 'PM0070978',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307474',
      rtl_batch_array: '6307474',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: '1VAB3H2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VAB3H2Y'
    },
    {
      status: 210,
      sku: 'PM0074225',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268721',
      rtl_batch_array: '6268721',
      name_search: 'DEFLAMEN',
      name: "Delphinium 'Flamenco'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C928YY49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C928YY49'
    },
    {
      status: 210,
      sku: 'PM0063549',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6330065',
      rtl_batch_array: '6301780, 6164399, 6330065',
      name_search: 'PEAROSEA',
      name: "Persicaria amplexicaulis 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2880,
      chnn_stock_retail: 8753,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8PEYE54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8PEYE54'
    },
    {
      status: 210,
      sku: 'PM0046328',
      core_name: 'Plant',
      sppl_ean: '8720664877702',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164402',
      rtl_batch_array: '6164402, 6353155',
      name_search: 'PHSAMIA',
      name: 'Phlomis samia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 839,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TGYJ6VWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGYJ6VWP'
    },
    {
      status: 210,
      sku: 'PM0066451',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184006',
      rtl_batch_array: '6184006',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'S6BJP5RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6BJP5RT'
    },
    {
      status: 210,
      sku: 'PM0046320',
      core_name: 'Plant',
      sppl_ean: '8720626315297',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057446',
      rtl_batch_array: '6057446',
      name_search: 'PEVERTIC',
      name: 'Peucedanum verticillare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 291,
      chnn_stock_retail: 291,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KK5GT1RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KK5GT1RX'
    },
    {
      status: 810,
      sku: 'PM0057511',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301908',
      rtl_batch_array: '6301908',
      name_search: 'VIMARS',
      name: "Viola 'Mars'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LRVAK8WC',
      statusContent: 'dot',
      hash: 'CHP9TDDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHP9TDDV'
    },
    {
      status: 910,
      sku: 'PM0048193',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASHPEARL',
      name: 'Astilbe Hot Pearls',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '9BZPYC7W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9BZPYC7W'
    },
    {
      status: 210,
      sku: 'PM0045974',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268763',
      rtl_batch_array: '6268763, 6302608',
      name_search: 'HOENINO',
      name: "Hosta 'El Ni\u00f1o'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 931,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HYEY4B7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYEY4B7Z'
    },
    {
      status: 210,
      sku: 'PM0070023',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218715',
      rtl_batch_array: '6218715',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '100125C44',
      rng_range_identifier: 'H100125C4.5',
      rng_range_description: 'H100 cm 125 cm C4.5',
      sppl_stock_available: 963,
      chnn_stock_retail: 963,
      sppl_prcp: 4.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'N5RY9C4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5RY9C4Y'
    },
    {
      status: 210,
      sku: 'PM0048195',
      core_name: 'Plant',
      sppl_ean: '8720664681385',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014583',
      rtl_batch_array: '6014583',
      name_search: 'ASPROCK',
      name: "Astilbe (A) 'Punk Rock'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KG9TBYB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG9TBYB7'
    },
    {
      status: 810,
      sku: 'PM0039576',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353357',
      rtl_batch_array: '6353357',
      name_search: 'SAVENOSU',
      name: 'Sauromatum venosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XHS5LDVN',
      statusContent: 'dot',
      hash: 'EG7L559S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG7L559S'
    },
    {
      status: 810,
      sku: 'PM0058041',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301340',
      rtl_batch_array: '6301340',
      name_search: 'FEGFESTI',
      name: "Festuca glauca 'Festina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WE3BZGYT;E29974KE;7CRTS4EW;4V7XH5PE',
      statusContent: 'dot',
      hash: 'S19BESBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S19BESBB'
    },
    {
      status: 210,
      sku: 'PM0048229',
      core_name: 'Plant',
      sppl_ean: '8720353096339',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014673',
      rtl_batch_array: '6014673',
      name_search: 'DEMFWDBE',
      name: "Delphinium (P) 'Magic Fountains White Dark Bee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V82ZC9Z7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V82ZC9Z7'
    },
    {
      status: 210,
      sku: 'PM0057513',
      core_name: 'Plant',
      sppl_ean: '8720349496464',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5990059',
      rtl_batch_array: '5990059',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: '5LKZJJGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LKZJJGK'
    },
    {
      status: 910,
      sku: 'PM0048132',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AJRSTOEL',
      name: "Ajuga reptans 'St\u00f6lzle'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'XKJG4BEA;WA5H445P;ZZ6XLLLT;JSLY7SX5',
      statusContent: 'dot',
      hash: 'N34HE9XP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N34HE9XP'
    },
    {
      status: 910,
      sku: 'PM0066456',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSSKOGH',
      name: "Cotoneaster suecicus 'Skogholm'",
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      imageCore:
        'BECWJ1C1;771X3AR6;AZXP97EK;59KPW61B;K3XE5X71;JRAZNJ4S;866VZR8D;57JY8B2H;9HT2131W;TNB3WRSA;TG15Y59H;6WSAWK8Z',
      statusContent: 'dot',
      hash: 'KJCCEPCZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KJCCEPCZ'
    },
    {
      status: 210,
      sku: 'PM0048234',
      core_name: 'Plant',
      sppl_ean: '8720664687134',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014678',
      rtl_batch_array: '6014678',
      name_search: 'DEPLAVAN',
      name: "Delphinium (E) 'Paramo Lavanda'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NL9RGJ1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL9RGJ1Y'
    },
    {
      status: 810,
      sku: 'PM0070982',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307566',
      rtl_batch_array: '6307566',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'SV76LXX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SV76LXX6'
    },
    {
      status: 210,
      sku: 'PM0048209',
      core_name: 'Plant',
      sppl_ean: '8720664683174',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014635',
      rtl_batch_array: '6014635',
      name_search: 'EDI81272',
      name: "Camassia 'Blue Candle'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4T6EHLJV;S99PGNJD;2L4A11X2;SNCVVBSA;7GCG99PG;ZCWRY518',
      statusContent: 'dot',
      hash: 'V5G2JYDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5G2JYDY'
    },
    {
      status: 210,
      sku: 'PM0057236',
      core_name: 'Plant',
      sppl_ean: '8720664867628',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091387',
      rtl_batch_array: '6091387',
      name_search: 'HEVENUS',
      name: "Heuchera 'Venus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N26D5ZS4;KN7BWNAP',
      statusContent: 'dot',
      hash: '8CP4S3L7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CP4S3L7'
    },
    {
      status: 810,
      sku: 'PM0064881',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321894',
      rtl_batch_array: '6300433, 6321894',
      name_search: 'ASNAHERB',
      name: "Aster novae-angliae 'Herbstschnee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 651,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5DSZ8TZS;VC9HLDLW;TWA7RTVR;PD3G3ZHZ;1CBBCGP4',
      statusContent: 'dot',
      hash: 'XPZC1A6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPZC1A6X'
    },
    {
      status: 210,
      sku: 'PM0070024',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218716',
      rtl_batch_array: '6218716',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '125150C4',
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_prcp: 4.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'KCSKZVS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCSKZVS9'
    },
    {
      status: 210,
      sku: 'PM0070025',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218764',
      rtl_batch_array: '6218764',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C44',
      rng_range_identifier: 'H100125C4.5',
      rng_range_description: 'H100 cm 125 cm C4.5',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_prcp: 4.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'Z5SBYCY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5SBYCY1'
    },
    {
      status: 210,
      sku: 'PM0057228',
      core_name: 'Plant',
      sppl_ean: '8720664867444',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091359',
      rtl_batch_array: '6091359',
      name_search: 'HEMOCHA',
      name: "Heuchera 'Mocha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P41HKWE4;XBH9B9G9',
      statusContent: 'dot',
      hash: 'TW3LRHLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TW3LRHLV'
    },
    {
      status: 210,
      sku: 'PM0077881',
      core_name: 'Plant',
      sppl_ean: '8720664699465',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5571358',
      rtl_batch_array: '5571358',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      imageBatch: 'C88SNCZ8',
      statusContent: 'dot',
      hash: '9KEWXBE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KEWXBE1'
    },
    {
      status: 210,
      sku: 'PM0081288',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370340',
      rtl_batch_array: '6370340, 6339799',
      name_search: 'EUCOELES',
      name: 'Eupatorium coelestinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 392,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNBZSZL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNBZSZL2'
    },
    {
      status: 210,
      sku: 'PM0058229',
      core_name: 'Plant',
      sppl_ean: '8720664868038',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112442',
      rtl_batch_array: '6112442',
      name_search: 'HOBSADDL',
      name: "Hosta 'Blazing Saddles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 387,
      chnn_stock_retail: 387,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5TBHT28A;YYLZB1LB',
      statusContent: 'dot',
      hash: 'CLPP3VJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CLPP3VJP'
    },
    {
      status: 210,
      sku: 'PM0081289',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339800',
      rtl_batch_array: '6339800',
      name_search: 'FITICRYS',
      name: "Ficinia truncata 'Ice Crystal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YG2DGZPX;G188EJAY;RB2SVJVZ;P5412HG4;TYE16GGE;55C3VLHE',
      statusContent: 'dot',
      hash: 'YNLYE9TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNLYE9TL'
    },
    {
      status: 210,
      sku: 'PM0077882',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5640128',
      rtl_batch_array: '5640128',
      name_search: 'RABROZE',
      name: 'Ranunculus bulb. Roze/ Pink',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PV1G7467',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PV1G7467'
    },
    {
      status: 910,
      sku: 'PM0045896',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEFFLEUR',
      name: "Geranium 'Fruit d'Fleur'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '2A695NZP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2A695NZP'
    },
    {
      status: 210,
      sku: 'PM0077883',
      core_name: 'Plant',
      sppl_ean: '8719223012333',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5652868',
      rtl_batch_array: '5652868',
      name_search: 'NATHALIA',
      name: 'Narcissus Thalia (TR)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 99997,
      chnn_stock_retail: 99997,
      sppl_order_minimum: 1,
      sppl_prcp: 20.404,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1SVXCKYH;K92TSAWN;21LH4V62;9N49HSEK;7X93TN7D;L47CD6CC;PCXTTJVD;YKEJZZWS;CENEV7AK;GJNDCR9G;XVZRZZEA;WN1CNZDT;GR8AW4VN;91VGHNRG;RVWEW43V;KXTC8S73;KG8K2EN5;H9SBWLVG;PE7P3926;LNWABYHX;AC3TT3NS',
      statusContent: 'dot',
      hash: 'KLAJGXH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLAJGXH6'
    },
    {
      status: 210,
      sku: 'PM0081290',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339801',
      rtl_batch_array: '6339801',
      name_search: 'FUGALADR',
      name: "Fuchsia 'Galadriel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GL23BGB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GL23BGB3'
    },
    {
      status: 210,
      sku: 'PM0081291',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339802',
      rtl_batch_array: '6339802',
      name_search: 'FULTHUMB',
      name: "Fuchsia 'Lady Thumb'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9LZE88A2;HDAJEETB;W4HK4NA4;41XVVNHA;N6K97PDD;449PWZD6;6S2CPT3T;J7XX5SWZ;58NRAWLL',
      statusContent: 'dot',
      hash: '4BYZY2T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BYZY2T6'
    },
    {
      status: 210,
      sku: 'PM0077884',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5743442',
      rtl_batch_array: '5743442',
      name_search: 'RABORANJ',
      name: 'Ranunculus bulb. Oranje/ Orange',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BPRW1T54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPRW1T54'
    },
    {
      status: 210,
      sku: 'PM0081292',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339803',
      rtl_batch_array: '6339803',
      name_search: 'FUMALBA',
      name: "Fuchsia magellanica 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYRWK1DG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYRWK1DG'
    },
    {
      status: 210,
      sku: 'PM0039685',
      core_name: 'Plant',
      sppl_ean: '8720353025926',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905541',
      rtl_batch_array: '5905541',
      name_search: 'ILMGENTL',
      name: 'Ilex meserveae Gentle',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 362,
      chnn_stock_retail: 362,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BBGKSGLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBGKSGLX'
    },
    {
      status: 210,
      sku: 'PM0048266',
      core_name: 'Plant',
      sppl_ean: '8720353094755',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014791',
      rtl_batch_array: '6014791',
      name_search: 'COSWFEAT',
      name: "Cortaderia selloana 'White Feather'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2100,
      chnn_stock_retail: 2100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5RJYBHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5RJYBHL'
    },
    {
      status: 210,
      sku: 'PM0046496',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353300',
      rtl_batch_array: '6353300',
      name_search: 'SEHJTUFF',
      name: "Sedum hybridum 'Jade Tuffet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SDBW227L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDBW227L'
    },
    {
      status: 210,
      sku: 'PM0085102',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371154',
      rtl_batch_array: '6371154',
      name_search: 'CYFROCHF',
      name: "Cyrtomium falcatum 'Rochfordianum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KE14A6GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE14A6GL'
    },
    {
      status: 210,
      sku: 'PM0081293',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339804',
      rtl_batch_array: '6339804',
      name_search: 'FUMAUREA',
      name: "Fuchsia magellanica 'Aurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'STS6CC19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STS6CC19'
    },
    {
      status: 210,
      sku: 'PM0081294',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339806',
      rtl_batch_array: '6339806',
      name_search: 'FUTENDRE',
      name: "Fuchsia 'Tendrement'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BVX5DSP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVX5DSP4'
    },
    {
      status: 210,
      sku: 'PM0048624',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272882',
      rtl_batch_array: '6272882',
      name_search: 'HERAKAIE',
      name: 'Hebe rakaiensis',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 676,
      chnn_stock_retail: 676,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LH7PH7Y6;RZ7VS1WG;VY4Y2CJN;4S6BXGKB;NB6YSND5;WXG96JK5;Z74524NT;ZB43HNAX;H2514ZWJ',
      statusContent: 'dot',
      hash: 'JJSWNSRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJSWNSRR'
    },
    {
      status: 210,
      sku: 'PM0070983',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307571',
      rtl_batch_array: '6307571',
      name_search: 'MASWATER',
      name: "Magnolia stellata 'Waterlily'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WD5DJX9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WD5DJX9S'
    },
    {
      status: 210,
      sku: 'PM0077886',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259150',
      rtl_batch_array: '6259150',
      name_search: 'CIRRATRO',
      name: "Cirsium rivulare 'Atropurpureum'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1343,
      chnn_stock_retail: 1343,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZAEZCKJ5;X9E9YY8B',
      statusContent: 'dot',
      hash: 'C7VY23P5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7VY23P5'
    },
    {
      status: 810,
      sku: 'PM0085103',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371155',
      rtl_batch_array: '6371155',
      name_search: 'DISEYES',
      name: "Dianthus (PL) 'Starry Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZXG7VBXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXG7VBXR'
    },
    {
      status: 210,
      sku: 'PM0081295',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339807',
      rtl_batch_array: '6383352, 6339807',
      name_search: 'FUTTHUMB',
      name: "Fuchsia 'Tom Thumb'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1111,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GN92TR3;HVEZL64A;SCTBVHZH;1G825SDJ;GXVX5HVB;V6RP7PJ8;1CCRVRSR;5D8APRRE;P4WP1K77;CPLR64E8;DT5B7T6E;4LPRN9GW;XKDXWKSD;KT2R26DT;58V3W793',
      statusContent: 'dot',
      hash: 'LLXZ246D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLXZ246D'
    },
    {
      status: 210,
      sku: 'PM0081296',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348975',
      rtl_batch_array: '6339808, 6352633, 6348975',
      name_search: 'ECSCOCKT',
      name: "Echinacea 'Summer Cocktail'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 724,
      chnn_stock_retail: 1531,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N4PKXHT;6AHPH5DA;AS5XC8Y9;WLEGHSYN',
      statusContent: 'dot',
      hash: '7G7YKKCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7G7YKKCJ'
    },
    {
      status: 810,
      sku: 'PM0081297',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339811',
      rtl_batch_array: '6339811',
      name_search: 'GEJANETT',
      name: "Geranium 'Janette'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VWHC63VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWHC63VK'
    },
    {
      status: 210,
      sku: 'PM0009482',
      core_name: 'Plant',
      sppl_ean: '8720353028705',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5608760',
      rtl_batch_array: '6133459, 6014485, 5608760',
      name_search: 'PEAFOXTR',
      name: "Pennisetum alopecuroides 'Foxtrot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1748,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G5S6114B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5S6114B'
    },
    {
      status: 210,
      sku: 'PM0048251',
      core_name: 'Plant',
      sppl_ean: '8720664684744',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014734',
      rtl_batch_array: '6014734',
      name_search: 'EDI79030',
      name: "Chionodoxa luciliae 'Rosy Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZLTA7L17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLTA7L17'
    },
    {
      status: 210,
      sku: 'PM0052982',
      core_name: 'Plant',
      sppl_ean: '8720664810204',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057318',
      rtl_batch_array: '6057318',
      name_search: 'THODSPIR',
      name: "Thuja occidentalis 'Degroot's Spire'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 754,
      chnn_stock_retail: 754,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y7VSG4KD;GL8AR2TS;SKJYSCT6',
      statusContent: 'dot',
      hash: 'WH52H7SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH52H7SE'
    },
    {
      status: 810,
      sku: 'PM0081298',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339812',
      rtl_batch_array: '6339812',
      name_search: 'GEPKLEPP',
      name: "Geranium phaeum 'Klepper'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2KE55Z8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KE55Z8T'
    },
    {
      status: 810,
      sku: 'PM0048268',
      core_name: 'Plant',
      sppl_ean: '8720664686489',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014804',
      rtl_batch_array: '6014804',
      name_search: 'EDI61549',
      name: "Crocus tommasinianus 'Barr's Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T69836KK;54LW4XVR;81DBS627;RJBWXHE7;5K3BDHXH;W9RETVGW;XB4RD44J;K94WJ4D3;T6K3RG8P;234CYTKD;ZYVHWL21;4JEY9SKC;JR7P9KTD;2XZTRGBL;6449H9TH;H2N2WXDX;21EP5DH6;BAX7BG61;R6HS6WRT;AVAARRVL;CTW8LBDX;G8C68STC',
      statusContent: 'dot',
      hash: 'VYJYD1E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYJYD1E4'
    },
    {
      status: 210,
      sku: 'PM0074226',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268723',
      rtl_batch_array: '6268723',
      name_search: 'DERPSENS',
      name: "Delphinium ruysii 'Pink Sensation'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 621,
      chnn_stock_retail: 621,
      sppl_order_minimum: 3,
      sppl_prcp: 2.068,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12JHKD9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12JHKD9D'
    },
    {
      status: 210,
      sku: 'PM0085104',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371156',
      rtl_batch_array: '6371156',
      name_search: 'DISCUPID',
      name: "Dicentra spectabilis 'Cupid'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHW15NDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHW15NDH'
    },
    {
      status: 210,
      sku: 'PM0048269',
      core_name: 'Plant',
      sppl_ean: '8720664686496',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014805',
      rtl_batch_array: '6014805',
      name_search: 'EDI61613',
      name: "Crocus tommasinianus 'Lilac Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '71VZL89A',
      statusContent: 'dot',
      hash: '165TD9RR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '165TD9RR'
    },
    {
      status: 810,
      sku: 'PM0081299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339813',
      rtl_batch_array: '6339813',
      name_search: 'GEPSPRIN',
      name: "Geranium phaeum 'Springtime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DPYH5R9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DPYH5R9'
    },
    {
      status: 210,
      sku: 'PM0068264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207964',
      rtl_batch_array: '6207964, 6356597',
      name_search: 'ERBOURGA',
      name: 'Eryngium bourgatii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1969,
      chnn_stock_retail: 2030,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '64B66WZG',
      statusContent: 'dot',
      hash: '556PWS72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '556PWS72'
    },
    {
      status: 210,
      sku: 'PM0070984',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307572',
      rtl_batch_array: '6307572',
      name_search: 'MASWATER',
      name: "Magnolia stellata 'Waterlily'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_prcp: 12.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5AP195PP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AP195PP'
    },
    {
      status: 210,
      sku: 'PM0069110',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 3,
      btch_active_retail: '6320175',
      rtl_batch_array: '6170240, 6284476, 6320175',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4094,
      chnn_stock_retail: 16746,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'K494PWH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K494PWH5'
    },
    {
      status: 210,
      sku: 'PM0085105',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371157',
      rtl_batch_array: '6371157',
      name_search: 'DISWGOLD',
      name: "Dicentra spectabilis 'White Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 334,
      chnn_stock_retail: 334,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7TG2TV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7TG2TV7'
    },
    {
      status: 210,
      sku: 'PM0081300',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339814',
      rtl_batch_array: '6339814',
      name_search: 'GALBLAZE',
      name: "Gaura lindheimeri 'Blaze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVX1V3W2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVX1V3W2'
    },
    {
      status: 210,
      sku: 'PM0085106',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371158',
      rtl_batch_array: '6371158',
      name_search: 'DIPDCERI',
      name: "Dierama pulcherrimum 'Dark Cerise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 916,
      chnn_stock_retail: 916,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X922JLGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X922JLGC'
    },
    {
      status: 210,
      sku: 'PM0085107',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371159',
      rtl_batch_array: '6371159',
      name_search: 'ECSELEGA',
      name: 'Echinacea Supreme Elegance',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YPVLD81E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPVLD81E'
    },
    {
      status: 210,
      sku: 'PM0085108',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371160',
      rtl_batch_array: '6371160',
      name_search: 'ECSFLAMI',
      name: 'Echinacea Supreme Flamingo',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5535SNRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5535SNRA'
    },
    {
      status: 910,
      sku: 'PM0085109',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUGFIREG',
      name: "Euphorbia griffithii 'Fireglow'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'J3G596TY;7HXLWCDE;LKCHDR7H;X5JEJ7B5;99VW7ZES;7EPB8VCC;GR71GWWG',
      statusContent: 'dot',
      hash: 'RDPTYANP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RDPTYANP'
    },
    {
      status: 210,
      sku: 'PM0085110',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371164',
      rtl_batch_array: '6371164',
      name_search: 'FRAANANA',
      name: "Fragaria ananassa 'Ananasaardbei'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 525,
      chnn_stock_retail: 525,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '435G6E14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '435G6E14'
    },
    {
      status: 210,
      sku: 'PM0045967',
      core_name: 'Plant',
      sppl_ean: '8720626305014',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015318',
      rtl_batch_array: '6015318',
      name_search: 'HOBOUT',
      name: "Hosta 'Branching Out'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BLYPADT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLYPADT5'
    },
    {
      status: 210,
      sku: 'PM0069111',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284477',
      rtl_batch_array: '6284477, 6310204',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 3203,
      chnn_stock_retail: 10632,
      sppl_prcp: 1.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'EBKATBL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBKATBL3'
    },
    {
      status: 210,
      sku: 'PM0059354',
      core_name: 'Plant',
      sppl_ean: '8720664856196',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6114067',
      rtl_batch_array: '6114067',
      name_search: 'CEHFASTI',
      name: "Cephalotaxus harringtonii 'Fastigiata'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 612,
      chnn_stock_retail: 612,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GTXKZHS9;J1XGR4GH;LW54X38Y',
      statusContent: 'dot',
      hash: 'HJB36KPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJB36KPC'
    },
    {
      status: 210,
      sku: 'PM0039691',
      core_name: 'Plant',
      sppl_ean: '8720349482924',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905553',
      rtl_batch_array: '5905553',
      name_search: 'LONTGREE',
      name: "Lonicera nitida 'Tiny Green'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 861,
      chnn_stock_retail: 861,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7LAXPDVZ;7AC9KGGH;2VEZTRAC;52Y6KHLB;NYDBYGN7;5NR166WH;2XDT7VWE;9CJC8DZD;7G67NKVA',
      statusContent: 'dot',
      hash: 'GTBKAJGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTBKAJGC'
    },
    {
      status: 210,
      sku: 'PM0070985',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307573',
      rtl_batch_array: '6307573',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 492,
      chnn_stock_retail: 492,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: '1XB9NEGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XB9NEGV'
    },
    {
      status: 910,
      sku: 'PM0077880',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      rng_range_identifier: 'H020030C1.5',
      rng_range_description: 'H20 cm 30 cm C1.5',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'Z5E7WX6S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z5E7WX6S'
    },
    {
      status: 210,
      sku: 'PM0058210',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6254376',
      rtl_batch_array: '6260116, 6350227, 6107075, 6254376',
      name_search: 'GEPHAEUM',
      name: 'Geranium phaeum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 247,
      chnn_stock_retail: 8524,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E19R7WLV',
      statusContent: 'dot',
      hash: '7L4R75ZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7L4R75ZT'
    },
    {
      status: 810,
      sku: 'PM0045483',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187528',
      rtl_batch_array: '6187528',
      name_search: 'CAPILOSA',
      name: 'Carex pilosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YXTSCL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YXTSCL1'
    },
    {
      status: 810,
      sku: 'PM0068716',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207826',
      rtl_batch_array: '6207826, 6349786',
      name_search: 'CEMJORDY',
      name: "Centaurea montana 'Jordy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 275,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '25AYABX7',
      statusContent: 'dot',
      hash: '9W2L15WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9W2L15WH'
    },
    {
      status: 210,
      sku: 'PM0063488',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350111',
      rtl_batch_array: '6350111, 6301554',
      name_search: 'ECBSFROS',
      name: "Echinops bannaticus 'Star Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1118,
      chnn_stock_retail: 1246,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DR3X6EB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR3X6EB5'
    },
    {
      status: 210,
      sku: 'PM0060150',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260683',
      rtl_batch_array: '6260683',
      name_search: 'ECBANNAT',
      name: 'Echinops bannaticus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6ENXRKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6ENXRKC'
    },
    {
      status: 810,
      sku: 'PM0081301',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349796',
      rtl_batch_array: '6339816, 6349796',
      name_search: 'GALCGOLD',
      name: "Gaura lindheimeri 'Corrie's Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XDEWLJKY;EB9YT6ZB;YGX3RJ2W;YL5ZKH2T;WB9N8C3V',
      statusContent: 'dot',
      hash: 'BJC5BHX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJC5BHX2'
    },
    {
      status: 210,
      sku: 'PM0081302',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302522',
      rtl_batch_array: '6339818, 6302522',
      name_search: 'GALSNOWB',
      name: "Gaura lindheimeri 'Snowbird'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 740,
      chnn_stock_retail: 1813,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X5P7NWVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5P7NWVY'
    },
    {
      status: 210,
      sku: 'PM0081303',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339819',
      rtl_batch_array: '6339819',
      name_search: 'GALSWHIT',
      name: "Gaura lindheimeri 'Sparkle White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9EPJG9V;2CT55TY9',
      statusContent: 'dot',
      hash: 'JRJWV6KK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRJWV6KK'
    },
    {
      status: 210,
      sku: 'PM0081304',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339820',
      rtl_batch_array: '6339820',
      name_search: 'GALSEMOT',
      name: "Gaura lindheimeri 'Summer Emotions'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVC552SG',
      statusContent: 'dot',
      hash: 'AVBPLE5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVBPLE5W'
    },
    {
      status: 210,
      sku: 'PM0081305',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339822',
      rtl_batch_array: '6339822',
      name_search: 'HELUCRET',
      name: "Hemerocallis 'Lucretius'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9G4L7XW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9G4L7XW3'
    },
    {
      status: 910,
      sku: 'PM0081306',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GLCORDIF',
      name: 'Globularia cordifolia',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '2XD4GSCZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2XD4GSCZ'
    },
    {
      status: 810,
      sku: 'PM0081307',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339825',
      rtl_batch_array: '6339825',
      name_search: 'IMOISTOR',
      name: "Impatiens omeiana 'Ice Storm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C1BR6SBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1BR6SBN'
    },
    {
      status: 910,
      sku: 'PM0081308',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRRORCHI',
      name: "Iris (G) 'Red Orchid'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'ZZYPT11H',
      statusContent: 'dot',
      hash: 'N469R8BZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N469R8BZ'
    },
    {
      status: 810,
      sku: 'PM0081309',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339827',
      rtl_batch_array: '6339827',
      name_search: 'IRSANNIC',
      name: "Iris sibirica 'Annick'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NG1VPL54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG1VPL54'
    },
    {
      status: 210,
      sku: 'PM0038437',
      core_name: 'Plant',
      sppl_ean: '8720349483273',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '5506353',
      rtl_batch_array: '5506353',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.162,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'TH3XN3EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH3XN3EC'
    },
    {
      status: 210,
      sku: 'PM0085111',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371165',
      rtl_batch_array: '6371165',
      name_search: 'FRAMSCHI',
      name: "Fragaria ananassa 'Mieze Schindler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8ZKDYA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8ZKDYA1'
    },
    {
      status: 210,
      sku: 'PM0081310',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349096',
      rtl_batch_array: '6349096, 6339828',
      name_search: 'HEGLABRA',
      name: 'Herniaria glabra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2543,
      chnn_stock_retail: 3197,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YP5C4Y11;SC95K7JC;BJAYBZ4J;BPD77YWK;8J5XAGR2',
      statusContent: 'dot',
      hash: 'Y48YJ7LN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y48YJ7LN'
    },
    {
      status: 210,
      sku: 'PM0081311',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339829',
      rtl_batch_array: '6339829',
      name_search: 'LELEONUR',
      name: 'Leonotis leonurus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2999SXV5;EH55DEJT;9JARW8JH;Z779AZ45;45DWYZ1B;WNXGNTDZ;HC4LLKX1;CSKPLG3W;6ZBXZBDR',
      statusContent: 'dot',
      hash: 'ARX24KBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARX24KBC'
    },
    {
      status: 210,
      sku: 'PM0084903',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369722',
      rtl_batch_array: '6369722',
      name_search: 'ASBAVARI',
      name: 'Astrantia bavarica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NE4T5C5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NE4T5C5K'
    },
    {
      status: 210,
      sku: 'PM0085112',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371166',
      rtl_batch_array: '6371166',
      name_search: 'FRASELVA',
      name: "Fragaria ananassa 'Selva'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPYV8RC5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPYV8RC5'
    },
    {
      status: 210,
      sku: 'PM0085113',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371167',
      rtl_batch_array: '6371167',
      name_search: 'FRATRIST',
      name: "Fragaria ananassa 'Tristan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GG97GSB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GG97GSB4'
    },
    {
      status: 210,
      sku: 'PM0085114',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371168',
      rtl_batch_array: '6371168',
      name_search: 'FUBSARAH',
      name: "Fuchsia 'Bella Sarah'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YTWE1SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YTWE1SX'
    },
    {
      status: 210,
      sku: 'PM0046482',
      core_name: 'Plant',
      sppl_ean: '8720664885523',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133670',
      rtl_batch_array: '6353363, 6133670',
      name_search: 'SCOCHROL',
      name: 'Scabiosa ochroleuca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 2084,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G4PPRCSZ;259THBW9;ECAXW3PH;TTAZG16J;1JXT2DH4;DD4K3BY1;6G4VYLE6;R5Z6TAH7',
      statusContent: 'dot',
      hash: '4AJ2LJXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AJ2LJXR'
    },
    {
      status: 210,
      sku: 'PM0068715',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349918',
      rtl_batch_array: '6349918',
      name_search: 'CACDKNIG',
      name: "Caryopteris clandonensis 'Dark Knight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4XYB89S3',
      statusContent: 'dot',
      hash: 'VYL8PYWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYL8PYWP'
    },
    {
      status: 210,
      sku: 'PM0085115',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371169',
      rtl_batch_array: '6371169',
      name_search: 'FUMARAUC',
      name: "Fuchsia magell. 'Arauco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YVE1NJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YVE1NJ1'
    },
    {
      status: 210,
      sku: 'PM0049493',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307700',
      rtl_batch_array: '6307700',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '040050C12',
      rng_range_identifier: 'H040050C12',
      rng_range_description: 'H40 cm 50 cm C12',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_prcp: 11.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'KA6DCB5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KA6DCB5T'
    },
    {
      status: 910,
      sku: 'PM0066457',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSSKOGH',
      name: "Cotoneaster suecicus 'Skogholm'",
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      imageCore:
        'BECWJ1C1;771X3AR6;AZXP97EK;59KPW61B;K3XE5X71;JRAZNJ4S;866VZR8D;57JY8B2H;9HT2131W;TNB3WRSA;TG15Y59H;6WSAWK8Z',
      statusContent: 'dot',
      hash: 'Z2C16JLX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z2C16JLX'
    },
    {
      status: 210,
      sku: 'PM0059612',
      core_name: 'Plant',
      sppl_ean: '8720349472321',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '5796025',
      rtl_batch_array: '5796025, 6159816',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1760,
      chnn_stock_retail: 10686,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'HGB9B255',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGB9B255'
    },
    {
      status: 210,
      sku: 'PM0049078',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 3,
      btch_active_retail: '6181271',
      rtl_batch_array: '6222165, 6281004, 6181271',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 293,
      chnn_stock_retail: 443,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'A58Z486S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A58Z486S'
    },
    {
      status: 810,
      sku: 'PM0064598',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307698',
      rtl_batch_array: '6307698',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'X78R121Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X78R121Z'
    },
    {
      status: 810,
      sku: 'PM0070992',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307821',
      rtl_batch_array: '6307821',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 28.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'Y4NBGEZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4NBGEZX'
    },
    {
      status: 810,
      sku: 'PM0039814',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281126',
      rtl_batch_array: '6281126',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'JT962HZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT962HZE'
    },
    {
      status: 810,
      sku: 'PM0039795',
      core_name: 'Plant',
      sppl_ean: '8720664685215',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5919323',
      rtl_batch_array: '5919323',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'P8CRGXWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8CRGXWA'
    },
    {
      status: 910,
      sku: 'PM0046504',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SERXENOX',
      name: "Sedum 'Rainbow Xenox'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'SD66Y5SP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SD66Y5SP'
    },
    {
      status: 210,
      sku: 'PM0053033',
      core_name: 'Plant',
      sppl_ean: '8720664808737',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057483',
      rtl_batch_array: '6057483',
      name_search: 'SOSSYMPH',
      name: "Soldanella 'Spring Symphony'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TCK17SSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCK17SSJ'
    },
    {
      status: 210,
      sku: 'PM0064462',
      core_name: 'Plant',
      sppl_ean: '8720664875845',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151589',
      rtl_batch_array: '6151589',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 12569,
      chnn_stock_retail: 12569,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '9EWYT16L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EWYT16L'
    },
    {
      status: 210,
      sku: 'PM0069113',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6211959',
      rtl_batch_array: '6211959',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_prcp: 5.253,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'KCNG3VWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCNG3VWE'
    },
    {
      status: 210,
      sku: 'PM0046502',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353310',
      rtl_batch_array: '6353310',
      name_search: 'SEPICOLE',
      name: "Sedum 'Picolette'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DWY8YLW9;NDBXB936;KAN4CARD;ZBSN1KZP;4ZPX6VRH;P79E81WK;LCRHKGD8;1NJW2BRC',
      statusContent: 'dot',
      hash: '2GXCND7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GXCND7C'
    },
    {
      status: 210,
      sku: 'PM0059370',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272024',
      rtl_batch_array: '6272024',
      name_search: 'CRJKITAY',
      name: "Cryptomeria japonica 'Kitayama'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 1362,
      chnn_stock_retail: 1362,
      sppl_prcp: 9.104,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5BNAZ5D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BNAZ5D5'
    },
    {
      status: 210,
      sku: 'PM0059615',
      core_name: 'Plant',
      sppl_ean: '8720349416325',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591991',
      rtl_batch_array: '5591991',
      name_search: 'GECSUBCA',
      name: 'Geranium cinereum subcaulescens',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '8CA6PJBV',
      statusContent: 'dot',
      hash: '86VX6DD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86VX6DD6'
    },
    {
      status: 210,
      sku: 'PM0046506',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353315',
      rtl_batch_array: '6353315',
      name_search: 'SEROBUST',
      name: "Sedum 'Robustum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYLV4XS5',
      statusContent: 'dot',
      hash: 'BK3BS4KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BK3BS4KS'
    },
    {
      status: 210,
      sku: 'PM0059310',
      core_name: 'Plant',
      sppl_ean: '8720664881631',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133570',
      rtl_batch_array: '6133570, 6353239',
      name_search: 'PRPULVER',
      name: 'Primula pulverulenta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 398,
      chnn_stock_retail: 1898,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5THXR9PP',
      statusContent: 'dot',
      hash: '13LTGTAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13LTGTAX'
    },
    {
      status: 210,
      sku: 'PM0039764',
      core_name: 'Plant',
      sppl_ean: '8720349456123',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054388',
      rtl_batch_array: '6054388',
      name_search: 'ARMVARRE',
      name: "Armeria mac. 'Varretu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 739,
      chnn_stock_retail: 739,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D35P4GBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D35P4GBE'
    },
    {
      status: 210,
      sku: 'PM0046435',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217068',
      rtl_batch_array: '6217068, 6330072',
      name_search: 'SANBBOUQ',
      name: 'Salvia nemorosa Blue Bouquetta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 362,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RSCB9VV9;XRAVE9DX;C58DRTV3;CYGNHX75;ZYK4R2WH;1YJ2TD4C',
      statusContent: 'dot',
      hash: '5W4YSBKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W4YSBKB'
    },
    {
      status: 210,
      sku: 'PM0059610',
      core_name: 'Plant',
      sppl_ean: '8720349425266',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354874',
      rtl_batch_array: '5354874',
      name_search: 'PHWSTAR',
      name: "Phlox (P) 'White Star'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '2GXAHLTN',
      statusContent: 'dot',
      hash: 'BR2WK4PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BR2WK4PA'
    },
    {
      status: 210,
      sku: 'PM0058226',
      core_name: 'Plant',
      sppl_ean: '8720664692466',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112438',
      rtl_batch_array: '6112438',
      name_search: 'HOANTIOC',
      name: "Hosta 'Antioch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EE9PDEZE;TSLBC2EK;NP73XV7E',
      statusContent: 'dot',
      hash: 'KNW2NBRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNW2NBRY'
    },
    {
      status: 810,
      sku: 'PM0069112',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6211958',
      rtl_batch_array: '6211958',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_prcp: 5.253,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: '78ABNHDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78ABNHDJ'
    },
    {
      status: 910,
      sku: 'PM0066478',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LITLTHOM',
      name: "Ligustrum tschonoskii 'Little Thomas'",
      rng_range_identifier: 'H050060C7.5',
      statusContent: 'dot',
      hash: 'ZL99EDD6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZL99EDD6'
    },
    {
      status: 210,
      sku: 'PM0068542',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 2,
      btch_active_retail: '6205983',
      rtl_batch_array: '6267747, 6205983',
      name_search: 'PEALBUNN',
      name: "Pennisetum alopecuroides 'Little Bunny'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 150,
      chnn_stock_retail: 850,
      sppl_order_minimum: 3,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63YVZZCG;A1BBJ6YJ;B5P86S8E;TH6PBLYX;GPV1LGBL;BNVEJBEX;5A63TARS;XN9HZKN6;6VRVEYET;1LKNTYL8;9P4AJG3C;Z5EA68H7;BY43YC1W;T159CA6D;EYTDLB28;EN1ZK2DY;9BY9SNAW;776T7RDZ;JGPE9T29;WAY2L8DE',
      statusContent: 'dot',
      hash: '49368EKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49368EKD'
    },
    {
      status: 210,
      sku: 'PM0074227',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268726',
      rtl_batch_array: '6268726',
      name_search: 'DIRFOUNT',
      name: "Dicentra 'Red Fountain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HHXLEJXB;EW339VP2;LBL34R3R;WZCRH6RE;8WN9VG39;BBRCBE1G;YJKPDY26',
      statusContent: 'dot',
      hash: 'ZEVXB44H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZEVXB44H'
    },
    {
      status: 810,
      sku: 'PM0085116',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371173',
      rtl_batch_array: '6371173',
      name_search: 'GEPMWILS',
      name: "Geranium phaeum 'Margaret Wilson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P3SL8ZY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3SL8ZY1'
    },
    {
      status: 210,
      sku: 'PM0059600',
      core_name: 'Plant',
      sppl_ean: '8720349424825',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354818',
      rtl_batch_array: '5354818',
      name_search: 'PHANASTA',
      name: "Phlox (P) 'Anastasia'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72KSP1L9;PXDXZH12;E2KX5B7K;ZBNXS8K6',
      imageBatch: 'RW86BDLK',
      statusContent: 'dot',
      hash: 'SJ5SAZHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJ5SAZHZ'
    },
    {
      status: 210,
      sku: 'PM0077888',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348773',
      rtl_batch_array: '4348773',
      name_search: 'EDI61024',
      name: "Tulipa (sea) 'Purple Prince'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D4838SYX;632AP56K;92WK1G9P;PBHX8G26;XW16H6G9;KPD86C5L;WV8W2PWW;6E9YB25B;JXDTRRTA',
      statusContent: 'dot',
      hash: 'CNKWYCD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNKWYCD1'
    },
    {
      status: 210,
      sku: 'PM0077889',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348819',
      rtl_batch_array: '4348819',
      name_search: 'TUGDYNAS',
      name: 'Tulipa Golden Dynasty',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZSWSWX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZSWSWX5'
    },
    {
      status: 210,
      sku: 'PM0081312',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319746',
      rtl_batch_array: '6339833, 6319746',
      name_search: 'LINVULGA',
      name: 'Linaria vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 985,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '72AE8LCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72AE8LCC'
    },
    {
      status: 210,
      sku: 'PM0053010',
      core_name: 'Plant',
      sppl_ean: '8720664699861',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057427',
      rtl_batch_array: '6057427',
      name_search: 'MUBSUPER',
      name: 'Muscari botryoides Superstar',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C9T1N9KH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9T1N9KH'
    },
    {
      status: 210,
      sku: 'PM0077890',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348857',
      rtl_batch_array: '4348857',
      name_search: 'TUSEADOV',
      name: 'Tulipa Seadove',
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99999,
      chnn_stock_retail: 99999,
      sppl_prcp: 11.703,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X87747NH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X87747NH'
    },
    {
      status: 210,
      sku: 'PM0081313',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339834',
      rtl_batch_array: '6339834',
      name_search: 'LISSDRAG',
      name: "Liriope spicata 'Silver Dragon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HZVCYTTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZVCYTTZ'
    },
    {
      status: 210,
      sku: 'PM0081314',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339835',
      rtl_batch_array: '6339835',
      name_search: 'LOMMOMEN',
      name: "Lobelia 'Monet Moment'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 522,
      chnn_stock_retail: 522,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKCHVLH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKCHVLH2'
    },
    {
      status: 210,
      sku: 'PM0077500',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304857',
      rtl_batch_array: '6304857',
      name_search: 'ACPBHOO',
      name: "Acer palmatum 'Bi-hoo'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1694,
      chnn_stock_retail: 1694,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJNPS2X8;N8DBB3WB;H99XGZKA;EWCKTXEE;GN5LG2R9;9ZH1W5ZS;G3HA2JSE;7CDYJEYJ;756P4RLC;LXS58H13;SEYVCP3P;YNDYHGHA;HVABCEYG;RJ1LLLED',
      statusContent: 'dot',
      hash: '7SCYPK1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SCYPK1Z'
    },
    {
      status: 910,
      sku: 'PM0058230',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188635',
      rtl_batch_array: '6188635',
      name_search: 'HODREMEM',
      name: "Hosta 'Diana Remembered'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 3,
      sppl_prcp: 1.391,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KWB1JTBX',
      statusContent: 'dot',
      hash: 'BS26H5C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS26H5C3'
    },
    {
      status: 910,
      sku: 'PM0074206',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '5NETLCR1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5NETLCR1'
    },
    {
      status: 210,
      sku: 'PM0068900',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271358',
      rtl_batch_array: '6304796, 6271358, 5365951',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3584,
      chnn_stock_retail: 5924,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'RKRXTCJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKRXTCJ2'
    },
    {
      status: 210,
      sku: 'PM0081315',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348360',
      rtl_batch_array: '6339836, 6348360',
      name_search: 'LOCORNIC',
      name: 'Lotus corniculatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 2129,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y7CESE2H',
      statusContent: 'dot',
      hash: '7N45R23A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N45R23A'
    },
    {
      status: 910,
      sku: 'PM0066477',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYIRHEIN',
      name: "Hypericum inodorum 'Rheingold'",
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      imageCore: 'Y2P47184',
      statusContent: 'dot',
      hash: 'XRSBCS1W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XRSBCS1W'
    },
    {
      status: 810,
      sku: 'PM0085117',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371174',
      rtl_batch_array: '6371174',
      name_search: 'GEPWBLUS',
      name: "Geranium phaeum 'Wendy's Blush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZDESHJT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDESHJT2'
    },
    {
      status: 910,
      sku: 'PM0081316',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LYAROSEA',
      name: "Lychnis alpina 'Rosea'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'TK2TDCKP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TK2TDCKP'
    },
    {
      status: 210,
      sku: 'PM0081317',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339839',
      rtl_batch_array: '6339839',
      name_search: 'LYCOCULA',
      name: "Lychnis coronaria 'Oculata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 448,
      chnn_stock_retail: 448,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6N1WD88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6N1WD88'
    },
    {
      status: 210,
      sku: 'PM0085118',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371175',
      rtl_batch_array: '6371175',
      name_search: 'GEPMGHOS',
      name: 'Geranium pratense Midnight Ghost',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HS3DV3GT',
      statusContent: 'dot',
      hash: 'HCSC3ACP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCSC3ACP'
    },
    {
      status: 210,
      sku: 'PM0085119',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371178',
      rtl_batch_array: '6371178',
      name_search: 'IRECHALO',
      name: "Iris ensata 'Crystal Halo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NLKP39PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLKP39PD'
    },
    {
      status: 210,
      sku: 'PM0085120',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371179',
      rtl_batch_array: '6371179',
      name_search: 'IREKMEND',
      name: "Iris e. 'Katy Mendez'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EW8P3SXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW8P3SXW'
    },
    {
      status: 910,
      sku: 'PM0071007',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307387',
      rtl_batch_array: '6307387',
      name_search: 'COARMAJE',
      name: "Corylus avellana 'Red Majestic'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 19.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S9YZKEVX;6CDJD15W;126G5LKS;63ATDJGR;LR4C43SP;5YV8H4Z7;GY5T3NZA;4WKEAEY1;49LZ1GG7;TBHHXCVT;KHZWAL8G;EN3YDE39',
      statusContent: 'dot',
      hash: 'NXL1NX6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXL1NX6X'
    },
    {
      status: 810,
      sku: 'PM0046808',
      core_name: 'Plant',
      sppl_ean: '8720664856721',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133248',
      rtl_batch_array: '6133248',
      name_search: 'CHCCURTI',
      name: "Chrysanthemum (R) 'Clara Curtis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XEARHBCG;ABTWP8LY;S96NL89Y;14JV5KBD;ST71E21L;WN7Z1YG5',
      statusContent: 'dot',
      hash: 'AWZXZLW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWZXZLW5'
    },
    {
      status: 210,
      sku: 'PM0077855',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6297475',
      rtl_batch_array: '6297475',
      name_search: 'HOWHIRLW',
      name: "Hosta 'Whirlwind'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2RXAPXZD;XGW2SJZG;2NWXXDDW;G5Y5W2HL',
      statusContent: 'dot',
      hash: 'CNG9PSZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNG9PSZH'
    },
    {
      status: 210,
      sku: 'PM0077679',
      core_name: 'Plant',
      sppl_ean: '8719223010674',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348516',
      rtl_batch_array: '4348516',
      name_search: 'NARECURV',
      name: 'Narcissus Recurvus (PO)',
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 99998,
      chnn_stock_retail: 99998,
      sppl_order_minimum: 1,
      sppl_prcp: 17.004,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYP3YBL7;G99A1LVK;W9EBY7A6;W2K37VBD;CZYK8L4Z;853HCZNZ;BELRJTYV;4S6KABSW;HNGV3W75',
      statusContent: 'dot',
      hash: 'W5NPE8HP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5NPE8HP'
    },
    {
      status: 810,
      sku: 'PM0081318',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339841',
      rtl_batch_array: '6339841',
      name_search: 'LYFCJENN',
      name: 'Lychnis flos-cuculi Jenny',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYA19GSZ;G1PDRRA3;6EKSDX3A;LB4B3Y3V;PCB4AYH6;XK29D2ZH;A7XG38RW;NV9766DG;GNP1RE2R',
      statusContent: 'dot',
      hash: 'BCJWJ8PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCJWJ8PE'
    },
    {
      status: 810,
      sku: 'PM0071005',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307385',
      rtl_batch_array: '6307385',
      name_search: 'COARMAJE',
      name: "Corylus avellana 'Red Majestic'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S9YZKEVX;6CDJD15W;126G5LKS;63ATDJGR;LR4C43SP;5YV8H4Z7;GY5T3NZA;4WKEAEY1;49LZ1GG7;TBHHXCVT;KHZWAL8G;EN3YDE39',
      statusContent: 'dot',
      hash: 'RRVNK5A6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRVNK5A6'
    },
    {
      status: 210,
      sku: 'PM0068929',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205321',
      rtl_batch_array: '6205321',
      name_search: 'PHEPEYE',
      name: 'Phlox (P) Early Purple Eye',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9Y5X5VDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Y5X5VDN'
    },
    {
      status: 210,
      sku: 'PM0049618',
      core_name: 'Plant',
      sppl_ean: '8720664682573',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5543039',
      rtl_batch_array: '5543039',
      name_search: 'BUDWBOUQ',
      name: "Buddleja davidii 'White Bouquet'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 145,
      chnn_stock_retail: 145,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '678JS1GL;T8E9XXDY',
      statusContent: 'dot',
      hash: 'YTE2RRP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTE2RRP7'
    },
    {
      status: 210,
      sku: 'PM0068719',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220693',
      rtl_batch_array: '6220693',
      name_search: 'CAAELDOR',
      name: "Calamagrostis acutiflora 'Eldorado'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RXH6AL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RXH6AL1'
    },
    {
      status: 810,
      sku: 'PM0065831',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281098',
      rtl_batch_array: '6281098',
      name_search: 'SOTORMIN',
      name: 'Sorbus torminalis',
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPXYWNWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPXYWNWB'
    },
    {
      status: 810,
      sku: 'PM0071004',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307380',
      rtl_batch_array: '6307380, 6287406',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 32,
      chnn_stock_retail: 48,
      sppl_prcp: 14.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'S699TE1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S699TE1C'
    },
    {
      status: 210,
      sku: 'PM0085121',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371180',
      rtl_batch_array: '6371180',
      name_search: 'IRELDAWN',
      name: "Iris ensata 'Light at Dawn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69EZBVWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69EZBVWN'
    },
    {
      status: 210,
      sku: 'PM0077954',
      core_name: 'Plant',
      sppl_ean: '8720664697454',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207201',
      rtl_batch_array: '5207201',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_prcp: 2.433,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      imageBatch: '43L3RXPC',
      statusContent: 'dot',
      hash: 'SLTVBCCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLTVBCCL'
    },
    {
      status: 210,
      sku: 'PM0077955',
      core_name: 'Plant',
      sppl_ean: '8720626377677',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207266',
      rtl_batch_array: '5207266',
      name_search: 'LEKRRUBY',
      name: "Leucothoe keiskei 'Royal Ruby'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 2.433,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DELW2ZC;9Z8CRP4D;JZ7LKYEC;28Z6ES5R;VT2R8L9A;5VW35V25;4W719N2W;DB9TBNA6;VAD78P86',
      imageBatch: 'WW4JEH9A',
      statusContent: 'dot',
      hash: 'CVDNB5SP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVDNB5SP'
    },
    {
      status: 210,
      sku: 'PM0077956',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207480',
      rtl_batch_array: '5207480, 6354180',
      name_search: 'CAJDKING',
      name: "Camellia japonica 'Dr King'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1480,
      chnn_stock_retail: 4480,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z84V5AG7;KBH3YV7B;G6BLA2AS;P3XY1A7H;7TYH9PD4;P96HL321;CL6RG5BV;PDLSZJYK;1BNBP9XA;8LCXKEXS;6SKS2JC2;Y7DBVSXD;7B1PATVE;HAYAYEHY;KPHV6YXN',
      imageBatch: '891CH2SC',
      statusContent: 'dot',
      hash: 'JC3KTPSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JC3KTPSG'
    },
    {
      status: 210,
      sku: 'PM0081319',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339844',
      rtl_batch_array: '6339844',
      name_search: 'KNCITRIN',
      name: 'Kniphofia citrina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDNDC75R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDNDC75R'
    },
    {
      status: 210,
      sku: 'PM0081320',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339845',
      rtl_batch_array: '6339845',
      name_search: 'KNINCAND',
      name: "Kniphofia 'Incandensce'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SC1H5GJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SC1H5GJT'
    },
    {
      status: 210,
      sku: 'PM0081321',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339847',
      rtl_batch_array: '6339847',
      name_search: 'KNMOONST',
      name: "Kniphofia 'Moonstone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TZTSNZ7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZTSNZ7D'
    },
    {
      status: 910,
      sku: 'PM0070974',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307467',
      rtl_batch_array: '6307467',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 14.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'KRH18TLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRH18TLJ'
    },
    {
      status: 210,
      sku: 'PM0085122',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371181',
      rtl_batch_array: '6371181',
      name_search: 'IRPVARIE',
      name: "Iris pallida 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X3YHRSA;VYC17H13;YPTA1WJ4;JPRTCN7Y;ZGWCZA1W;R2YA8V8T',
      statusContent: 'dot',
      hash: 'K3R6SZB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3R6SZB4'
    },
    {
      status: 210,
      sku: 'PM0064465',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271410',
      rtl_batch_array: '6271410',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'D9878P5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9878P5J'
    },
    {
      status: 210,
      sku: 'PM0077961',
      core_name: 'Plant',
      sppl_ean: '8720626333178',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5372952',
      rtl_batch_array: '5372952',
      name_search: 'ACKELLER',
      name: 'Achillea kellereri',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 603,
      chnn_stock_retail: 603,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBDG4Z9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBDG4Z9K'
    },
    {
      status: 210,
      sku: 'PM0081322',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339848',
      rtl_batch_array: '6339848',
      name_search: 'KNOBLAZE',
      name: "Kniphofia 'Orange Blaze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1L9N5HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1L9N5HH'
    },
    {
      status: 210,
      sku: 'PM0063558',
      core_name: 'Plant',
      sppl_ean: '8720664874251',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146924',
      rtl_batch_array: '6146924',
      name_search: 'MECILIAT',
      name: 'Melica ciliata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2536,
      chnn_stock_retail: 2536,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '451NDZR4;REA562ND;J3EJW5P2;4XTRB6C3',
      statusContent: 'dot',
      hash: '9PX7SY12',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PX7SY12'
    },
    {
      status: 210,
      sku: 'PM0068543',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205984',
      rtl_batch_array: '6205984',
      name_search: 'PEACSPAR',
      name: "Pennisetum advena 'Cherry Sparkler'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 2.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WXCSN3NT;LT7C6LDH;5CH9X9Y4;813LH55Y;49P6HN3D;R1NZPPDE',
      statusContent: 'dot',
      hash: '8WWWL9LS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WWWL9LS'
    },
    {
      status: 910,
      sku: 'PM0068544',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEAFIREW',
      name: "Pennisetum advena 'Fireworks'",
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        '41WDP4KL;EDKDXJ35;PBPRYJ35;YRLA5EPJ;24VHJ1LL;ATK3J5RZ;BSHR47RP;A13EY98K;KXC4E3KN;H5CXRHRJ;6V8H93KB;STJABS11;EPH4P72T;CB9H5Y5K;X7AHD1JX',
      statusContent: 'dot',
      hash: 'JSNW9BVE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JSNW9BVE'
    },
    {
      status: 810,
      sku: 'PM0046546',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133773',
      rtl_batch_array: '6133773, 6353393',
      name_search: 'THSPHAER',
      name: 'Thalictrum sphaerostachyum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 354,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEAEGT69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEAEGT69'
    },
    {
      status: 910,
      sku: 'PM0068545',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEARUBRU',
      name: "Pennisetum advena 'Rubrum'",
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      imageCore:
        'J6C25DWW;VJT3ZRAP;ZACZP7TZ;EW59DX9G;N2WYZ1C9;NRLDVYX5;LGD4ZEEG;S4EEAAZR;WVHDRZ99;PBZLX9EG;RTEYHY9B;J9PWY479;98V4VGKK;LWEZ8D7E;TZBW9958;KGX25VCY;KD69RY4A;C9NPZ1N9;738Z415A;SP67698E;LZKLAEVE;RCNZHP1N;TP5Z8GWT;K3Z66JDE;5HN3S3YJ;YPYWCGB2;K3TBB8R4;H42WWAWD',
      statusContent: 'dot',
      hash: '7DHVAD36',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7DHVAD36'
    },
    {
      status: 210,
      sku: 'PM0077962',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346707',
      rtl_batch_array: '6346707',
      name_search: 'CYFLEXUO',
      name: 'Cymbopogon flexuosus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J9VWL97Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9VWL97Y'
    },
    {
      status: 210,
      sku: 'PM0077964',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284226',
      rtl_batch_array: '6329889, 6284226',
      name_search: 'CAPTWHIT',
      name: "Campanula persicifolia 'Takion White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 469,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72LXHXW7',
      statusContent: 'dot',
      hash: 'W83CTN97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W83CTN97'
    },
    {
      status: 210,
      sku: 'PM0077965',
      core_name: 'Plant',
      sppl_ean: '8720626338050',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5448388',
      rtl_batch_array: '5448388',
      name_search: 'CAJBYELL',
      name: "Camellia japonica 'Brushfield's Yellow'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6ZLZ48A;9KNXTTVB;TD3XAJD1;NTG3LD66;PS86LZWJ;46A7G9CE;EEDNZWXD',
      imageBatch: '54H2JNZA',
      statusContent: 'dot',
      hash: '8STV48HT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8STV48HT'
    },
    {
      status: 210,
      sku: 'PM0063559',
      core_name: 'Plant',
      sppl_ean: '8720664877108',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146939',
      rtl_batch_array: '6146939',
      name_search: 'PERUBICU',
      name: "Penstemon 'Rubicunda'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1155,
      chnn_stock_retail: 1155,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2WDBVYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2WDBVYW'
    },
    {
      status: 210,
      sku: 'PM0058149',
      core_name: 'Plant',
      sppl_ean: '8720664851399',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112301',
      rtl_batch_array: '5902367, 6112301',
      name_search: 'ALAPSENS',
      name: "Allium aflatunense 'Purple Sensation'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 369,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NAH18D1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAH18D1W'
    },
    {
      status: 210,
      sku: 'PM0085123',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371182',
      rtl_batch_array: '6371182',
      name_search: 'LAISEDGE',
      name: 'Lavandula intermedia Silver Edge',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KV2G6B8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KV2G6B8K'
    },
    {
      status: 210,
      sku: 'PM0069115',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224032',
      rtl_batch_array: '6224032',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 5.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: 'AARD1X57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AARD1X57'
    },
    {
      status: 210,
      sku: 'PM0066473',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184051',
      rtl_batch_array: '6184051',
      name_search: 'HYPSFRAI',
      name: 'Hydrangea paniculata Sundae Fraise',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_prcp: 2.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JE1RX5H2;TVJKSH5R;LBKPNDD1;ALZHHNXR;YCCVESHH;142DDV9G;87AGDKC1;NH16J9WA;1YJWB1NJ;KE8BAA3N;9HBXW791;HRNKJRGK;HNWVSRL9;EA7XZXYC;AH96E5GW;1X2TCPAL;8R2Z9NZA;PRLB2BNY',
      statusContent: 'dot',
      hash: '8PRXH3ZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PRXH3ZL'
    },
    {
      status: 210,
      sku: 'PM0077966',
      core_name: 'Plant',
      sppl_ean: '8720353007564',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5448392',
      rtl_batch_array: '5448392',
      name_search: 'CAJWBART',
      name: "Camellia japonica 'William Bartlett'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1985,
      chnn_stock_retail: 1985,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42ENTKAH;71YCER23;SZVCB6P4',
      imageBatch: 'HSGYWRRP',
      statusContent: 'dot',
      hash: 'NT7SCXKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NT7SCXKW'
    },
    {
      status: 210,
      sku: 'PM0066825',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176301',
      rtl_batch_array: '6176301',
      name_search: 'ALCHINON',
      name: "Alstroemeria 'Chinon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RR9KPN5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR9KPN5Y'
    },
    {
      status: 210,
      sku: 'PM0074228',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268730',
      rtl_batch_array: '6268730, 6321901',
      name_search: 'COCNEORU',
      name: 'Convolvulus cneorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3913,
      chnn_stock_retail: 4008,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D574AA9T;K9GD3V54;1D8XG7E4;6XWVVAG9;TAEAYK35;XC3SBBEB;W1KHWDTY;T2PWDGWD;DEJSYPCK;XSTHJK23;RWY6DZCB;TXKCVSHT;V5CLXH1S;8KZ323JZ;BD2RGXR9;7YD8HX35',
      statusContent: 'dot',
      hash: 'A758874E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A758874E'
    },
    {
      status: 810,
      sku: 'PM0005305',
      core_name: 'Plant',
      sppl_ean: '8720626361577',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364401',
      rtl_batch_array: '5364401, 6217039',
      name_search: 'PEADLOWN',
      name: "Persicaria affinis 'Donald Lowndes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3BBAXJJ',
      statusContent: 'dot',
      hash: 'TAK6RTJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TAK6RTJD'
    },
    {
      status: 910,
      sku: 'PM0077970',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUGJBLUE',
      name: "Ruta graveolens 'Jackman's Blue'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '7AHZYYYY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7AHZYYYY'
    },
    {
      status: 210,
      sku: 'PM0059638',
      core_name: 'Plant',
      sppl_ean: '8720664850705',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121907',
      rtl_batch_array: '6121907',
      name_search: 'AGBUMBRE',
      name: "Agapanthus 'Blue Umbrella'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '135TK4XT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '135TK4XT'
    },
    {
      status: 210,
      sku: 'PM0068931',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207577',
      rtl_batch_array: '6207577',
      name_search: 'RUFTEVER',
      name: "Rubus fruticosus 'Thornless Evergreen'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GDVL76YR;JYNSTL1S;JH6JBD75;CP52JXB9;6GW2GWA4;LKA2YTKC;X34P75LV',
      statusContent: 'dot',
      hash: 'DY2GX1L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY2GX1L4'
    },
    {
      status: 210,
      sku: 'PM0085124',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371183',
      rtl_batch_array: '6371183',
      name_search: 'LECGROBB',
      name: "Leonurus cardiaca 'Grobbebol'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A3G9K4K3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3G9K4K3'
    },
    {
      status: 210,
      sku: 'PM0077972',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495448',
      rtl_batch_array: '5495448',
      name_search: 'PEOJDWME',
      name: 'Pennisetum orientale JS Dance With Me',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T33PX83S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T33PX83S'
    },
    {
      status: 210,
      sku: 'PM0077973',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5521421',
      rtl_batch_array: '5521421',
      name_search: 'HAMSUNFL',
      name: 'Hakonechloa macra Sunflare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1CK1TR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1CK1TR6'
    },
    {
      status: 210,
      sku: 'PM0085125',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371184',
      rtl_batch_array: '6371184',
      name_search: 'LEBLIGHT',
      name: 'Leucanthemum (S) Broadway Lights',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNG5YJGD;G2BPYED2;Y57RJ9N3;X8LZ4GE2;8Y1E1CX2;S346ES3G;5KCLSCCL;959LKJ1A;N6NXX3YN;E94B5W5N;D1T7JCB1',
      statusContent: 'dot',
      hash: 'JXRWPREJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXRWPREJ'
    },
    {
      status: 210,
      sku: 'PM0077974',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252873',
      rtl_batch_array: '6252873',
      name_search: 'IRSFGOLD',
      name: "Iris sibirica Fran's Gold",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A16TWHTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A16TWHTS'
    },
    {
      status: 210,
      sku: 'PM0085126',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371185',
      rtl_batch_array: '6371185',
      name_search: 'LEKCROWN',
      name: "Leucanthemum (S) 'King's Crown'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJYW7G4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJYW7G4G'
    },
    {
      status: 810,
      sku: 'PM0085127',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371189',
      rtl_batch_array: '6371189',
      name_search: 'LIOFANTA',
      name: "Ligularia 'Osiris Fantaisie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69LTZA68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69LTZA68'
    },
    {
      status: 810,
      sku: 'PM0065836',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281139',
      rtl_batch_array: '6281139',
      name_search: 'VILANTAN',
      name: 'Viburnum lantana',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.587,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8RJDYE1;E5TGX8J3;EEWC33GD;99BYSAN4;KP98VB62;SKRTREDV;YX7YB1LL;H199BA28;6ZPK789N;XECZ6GZ3;L1LCTX4J;9EZ4NXZ2;W5GJZ4DE;EA5A97P3;DNHA5G5K;7J524CB3',
      statusContent: 'dot',
      hash: 'G5LPGL5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5LPGL5Z'
    },
    {
      status: 210,
      sku: 'PM0066469',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184043',
      rtl_batch_array: '6184043',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'SBLTGJJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBLTGJJ6'
    },
    {
      status: 210,
      sku: 'PM0071001',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307379',
      rtl_batch_array: '6307379',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 1470,
      chnn_stock_retail: 1470,
      sppl_prcp: 8.061,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'JLYAX2N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLYAX2N7'
    },
    {
      status: 910,
      sku: 'PM0066475',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYIANNEB',
      name: "Hypericum inodorum 'Annebel'",
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      statusContent: 'dot',
      hash: 'T64VHG25',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T64VHG25'
    },
    {
      status: 810,
      sku: 'PM0071003',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307378',
      rtl_batch_array: '6307378',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 7.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'JHNK2K4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHNK2K4H'
    },
    {
      status: 910,
      sku: 'PM0071006',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307386',
      rtl_batch_array: '6307386',
      name_search: 'COARMAJE',
      name: "Corylus avellana 'Red Majestic'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 16.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S9YZKEVX;6CDJD15W;126G5LKS;63ATDJGR;LR4C43SP;5YV8H4Z7;GY5T3NZA;4WKEAEY1;49LZ1GG7;TBHHXCVT;KHZWAL8G;EN3YDE39',
      statusContent: 'dot',
      hash: 'CEKWPH72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEKWPH72'
    },
    {
      status: 210,
      sku: 'PM0081323',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339849',
      rtl_batch_array: '6339849',
      name_search: 'KNOVPOPS',
      name: "Kniphofia 'Orange Vanilla Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H5G5LA6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5G5LA6E'
    },
    {
      status: 210,
      sku: 'PM0054386',
      core_name: 'Plant',
      sppl_ean: '8720664686366',
      btch_manufacturer: 430,
      rtl_batch_array_total: 3,
      btch_active_retail: '6067919',
      rtl_batch_array: '6067919, 6157582, 6236237',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 10000,
      chnn_stock_retail: 12367,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: 'RDJA5ZZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDJA5ZZR'
    },
    {
      status: 210,
      sku: 'PM0066480',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184081',
      rtl_batch_array: '6184081',
      name_search: 'POFELIZA',
      name: "Potentilla fruticosa 'Elizabeth'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XY5JR1T3;BWWTSRRY;6SR67SWX;79CS1C7X',
      statusContent: 'dot',
      hash: 'P6CP51YW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6CP51YW'
    },
    {
      status: 210,
      sku: 'PM0066481',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184082',
      rtl_batch_array: '6184082',
      name_search: 'POFGOLDT',
      name: "Potentilla fruticosa 'Goldteppich'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPG1YEWB',
      statusContent: 'dot',
      hash: 'XWXGNJDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWXGNJDC'
    },
    {
      status: 810,
      sku: 'PM0058088',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291709',
      rtl_batch_array: '6291709',
      name_search: 'LUCHANDE',
      name: "Lupinus 'Chandelier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X9EANK5K;PNZ42HR1;C859W72Y;8J38AVCW',
      statusContent: 'dot',
      hash: 'BJY6HT9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJY6HT9D'
    },
    {
      status: 810,
      sku: 'PM0068598',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307672',
      rtl_batch_array: '6307672',
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 14.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7E45XDD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E45XDD6'
    },
    {
      status: 210,
      sku: 'PM0077968',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308484',
      rtl_batch_array: '6300414, 6308484, 6321877',
      name_search: 'ANHROSEN',
      name: "Anemone hybrida 'Rosenschale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1519,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SE7XDBAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SE7XDBAD'
    },
    {
      status: 810,
      sku: 'PM0077969',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300437',
      rtl_batch_array: '6300437',
      name_search: 'ASTJINDA',
      name: "Aster tataricus 'Jindai'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PT8H9724',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PT8H9724'
    },
    {
      status: 910,
      sku: 'PM0077963',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LEVMAIKO',
      name: "Leucanthemum vulgare 'Maik\u00f6nigin'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'VPEJPET1;C6SNZBW8;NBXVAZS5;YBPNZL1H;6R7624PW;LZR22RVA',
      statusContent: 'dot',
      hash: 'T7KB3WX4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T7KB3WX4'
    },
    {
      status: 810,
      sku: 'PM0068718',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207832',
      rtl_batch_array: '6207832',
      name_search: 'BOGBAMBI',
      name: "Bouteloua gr. 'Blonde Ambition'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '53L2CVHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53L2CVHA'
    },
    {
      status: 210,
      sku: 'PM0077952',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267770',
      rtl_batch_array: '6267770, 6320835',
      name_search: 'CEMASNOW',
      name: "Centaurea montana 'Amethyst in Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 421,
      chnn_stock_retail: 513,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9Y5ZXTC;R5A825DT;4HRCBZZV;P7EEECAZ;NBWYLD8S;NVW4X7GV',
      statusContent: 'dot',
      hash: 'TW4C2BTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TW4C2BTW'
    },
    {
      status: 210,
      sku: 'PM0064470',
      core_name: 'Plant',
      sppl_ean: '8720664881945',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151598',
      rtl_batch_array: '6151598',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 939,
      chnn_stock_retail: 939,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'YEC7VBSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEC7VBSZ'
    },
    {
      status: 210,
      sku: 'PM0045944',
      core_name: 'Plant',
      sppl_ean: '8720626303201',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015194',
      rtl_batch_array: '6015194',
      name_search: 'HEPIPQUE',
      name: "Helenium 'Pipsqueak'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AWLP6ZHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWLP6ZHJ'
    },
    {
      status: 210,
      sku: 'PM0066377',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5880631',
      rtl_batch_array: '5880631',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 2.809,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: '934VZ84D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '934VZ84D'
    },
    {
      status: 210,
      sku: 'PM0074229',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268732',
      rtl_batch_array: '6286660, 6268732',
      name_search: 'CRMARITI',
      name: 'Crambe maritima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 581,
      chnn_stock_retail: 775,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S94JR8J6',
      statusContent: 'dot',
      hash: 'X9YBG537',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9YBG537'
    },
    {
      status: 210,
      sku: 'PM0057092',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217109',
      rtl_batch_array: '6217109',
      name_search: 'ECDPURPL',
      name: 'Echinacea Drumstick Purple',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S6SXVY4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6SXVY4N'
    },
    {
      status: 210,
      sku: 'PM0059617',
      core_name: 'Plant',
      sppl_ean: '8720349408252',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592053',
      rtl_batch_array: '5592053',
      name_search: 'ACTERRAC',
      name: "Achillea 'Terracotta'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P54ZSLA8;YV2SSJLS;1B26DHNY;V1HTCTJR;ZJHWAN7T;V1JKWRXK',
      imageBatch: 'S233C18T',
      statusContent: 'dot',
      hash: 'VNEG1TB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNEG1TB5'
    },
    {
      status: 910,
      sku: 'PM0066476',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYIEFLAI',
      name: "Hypericum inodorum 'Excellent Flair'",
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      statusContent: 'dot',
      hash: 'YRV3P7KX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YRV3P7KX'
    },
    {
      status: 210,
      sku: 'PM0048157',
      core_name: 'Plant',
      sppl_ean: '8720626392007',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014417',
      rtl_batch_array: '6014417',
      name_search: 'ASVALERI',
      name: "Astilbe 'Valerie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ADELLP7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADELLP7B'
    },
    {
      status: 210,
      sku: 'PM0066470',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184044',
      rtl_batch_array: '6184044',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '5TXBW45A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TXBW45A'
    },
    {
      status: 210,
      sku: 'PM0046600',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353558',
      rtl_batch_array: '6353558',
      name_search: 'VEMFMATC',
      name: 'Veronica media First Match',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B2AB8EVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2AB8EVL'
    },
    {
      status: 210,
      sku: 'PM0048113',
      core_name: 'Plant',
      sppl_ean: '8720664679016',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034171',
      rtl_batch_array: '6034171',
      name_search: 'ACSBEAUT',
      name: "Acanthus 'Summer Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKYZDWL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKYZDWL1'
    },
    {
      status: 910,
      sku: 'PM0046583',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAAHOUR',
      name: "Paeonia (LD) 'Augustin d'Hour'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '25943VSA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '25943VSA'
    },
    {
      status: 210,
      sku: 'PM0046537',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353374',
      rtl_batch_array: '6133770, 6353374',
      name_search: 'THNPINK',
      name: 'Thalictrum Nimbus Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 262,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8AX1BESH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AX1BESH'
    },
    {
      status: 810,
      sku: 'PM0077951',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267764',
      rtl_batch_array: '6267764',
      name_search: 'SCSHHTON',
      name: "Schizachyrium scoparium 'Ha Ha Tonka'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZ7WL62A;4P6W8JAA;BWYX2S1Y',
      statusContent: 'dot',
      hash: 'XY585GBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XY585GBB'
    },
    {
      status: 210,
      sku: 'PM0077953',
      core_name: 'Plant',
      sppl_ean: '8720349485659',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5173409',
      rtl_batch_array: '5173409',
      name_search: 'KNPOBLAZ',
      name: 'Kniphofia Pyromania Orange Blaze',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XG5CCX5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG5CCX5A'
    },
    {
      status: 210,
      sku: 'PM0059658',
      core_name: 'Plant',
      sppl_ean: '8720353096421',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053824',
      rtl_batch_array: '6053824',
      name_search: 'DECGOLDG',
      name: "Deschampsia cespitosa 'Goldgeh\u00e4nge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '27C1XZEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27C1XZEN'
    },
    {
      status: 810,
      sku: 'PM0065832',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281119',
      rtl_batch_array: '6281119',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.072,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: '777GWE9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '777GWE9G'
    },
    {
      status: 210,
      sku: 'PM0045912',
      core_name: 'Plant',
      sppl_ean: '8720349494842',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015160',
      rtl_batch_array: '6015160',
      name_search: 'GESBREEZ',
      name: "Geum 'Sea Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5T72KH6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T72KH6K'
    },
    {
      status: 210,
      sku: 'PM0071000',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307565',
      rtl_batch_array: '6307565',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: '82S5DBYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82S5DBYY'
    },
    {
      status: 910,
      sku: 'PM0059554',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '2WZ1D8VK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2WZ1D8VK'
    },
    {
      status: 910,
      sku: 'PM0046571',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRNTRACE',
      name: "Iris (G) 'Natchez Trace'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'LH7XWHTC',
      statusContent: 'dot',
      hash: '57WET7RB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '57WET7RB'
    },
    {
      status: 210,
      sku: 'PM0046643',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308468',
      rtl_batch_array: '6161676, 6301336, 6308468',
      name_search: 'EUCWULFE',
      name: 'Euphorbia characias wulfenii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 2351,
      sppl_order_minimum: 3,
      sppl_prcp: 0.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D91D29E3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D91D29E3'
    },
    {
      status: 910,
      sku: 'PM0066486',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      rng_range_identifier: 'H020025C2.5',
      rng_range_description: 'H20 cm 25 cm C2.5',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'LKNDR4A6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LKNDR4A6'
    },
    {
      status: 210,
      sku: 'PM0077891',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4348894',
      rtl_batch_array: '4348894',
      name_search: 'EDI67700',
      name: "Tulipa (dar) 'Salmon Impression'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6T3CBJ67;A68PKCAK;49TKWAT9;D58RSXX3;H45ZJDJN;YRX6L8DV;HH3H3BVE;TANDP6A6;DD9P9VKV;6B8P6ELP;R8CVW1ND;7WKWPWL1;G59Y8VYZ',
      statusContent: 'dot',
      hash: 'AYP25DY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYP25DY3'
    },
    {
      status: 210,
      sku: 'PM0077892',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349006',
      rtl_batch_array: '4349006',
      name_search: 'EDI61163',
      name: "Tulipa (kau) 'Showwinner'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YE9B2KZ2;RGJ464J2;ZEY6CEHS;49B292RY;N82Y1TBL;ANT1NBKZ;X6H7JAP6;BYZ36Z67;EJ1D7C2K;E4JVLWV8;4JG4DLXB;NRH6E1RS;YNRW7A15;5HTVE2RH;BSB7RGR3;L98WP64S;9N4AJ6WE;B2ND976K;BWCCWD7V;G8GYLH1E;KL3D7PW8;1A8Y8PE5;N4GDRSRP;ATCDNDHW;RKHN3JEV;CR1RPS7D;G4S8DDD1;1KWBNNLC;AD8JW7HX;11295T7R',
      statusContent: 'dot',
      hash: 'GSEBJRVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSEBJRVE'
    },
    {
      status: 210,
      sku: 'PM0077893',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349008',
      rtl_batch_array: '4349008',
      name_search: 'EDI61207',
      name: "Tulipa (kau) 'Stresa'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4XT9CPT9;SS3J825C;26XZK7R1;HS6AJLSC;NCTT3656;8E8KR44Y;59V664SP;9BDR2PCC;JN9EP83H;EJHAJ2GA;V8DSAZDW;EPYB13DD;WHK6JT49',
      statusContent: 'dot',
      hash: 'G92S57CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G92S57CZ'
    },
    {
      status: 210,
      sku: 'PM0068600',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230996',
      rtl_batch_array: '6230996',
      name_search: 'GECKPURP',
      name: "Geranium clarkei 'Kashmir Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BX12TR1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX12TR1R'
    },
    {
      status: 210,
      sku: 'PM0064491',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295620',
      rtl_batch_array: '6295620',
      name_search: 'GECARLSK',
      name: "Geum 'Carlskaer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 122,
      chnn_stock_retail: 122,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GTBGPBAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTBGPBAE'
    },
    {
      status: 210,
      sku: 'PM0077894',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349147',
      rtl_batch_array: '4349147',
      name_search: 'EDI65957',
      name: "Tulipa (lif) 'Holland Chic'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNAVVN8X;47YZ3X7Y;E6CTXBZZ;LZC2G59W;5ACTL3GJ;162BK6BV;4HYR8PKP;TPNCJZBJ;J52ES74C;H524BZHA;4CBL1YY3;XLVBVGNW',
      statusContent: 'dot',
      hash: 'AGKSRWTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGKSRWTX'
    },
    {
      status: 210,
      sku: 'PM0077895',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349161',
      rtl_batch_array: '4349161',
      name_search: 'EDI61355',
      name: "Tulipa (lif) 'White Triumphator'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D63VWGBD;7V6YNWJK;92JK64TT;5XYYS5ZN;XBZ2V61H;H7HTXYV1;PZEZCLJ1;6P3YN9TS;TK8DWSP5;ESKKG429;D2G1L177',
      statusContent: 'dot',
      hash: 'EG3BDA6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG3BDA6D'
    },
    {
      status: 210,
      sku: 'PM0077896',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349173',
      rtl_batch_array: '4349173',
      name_search: 'FRILMAX',
      name: 'Fritillaria imperalis Lutea Max',
      sppl_size_code: '024+BOP10',
      rng_range_identifier: 'BU\u00d824+10PCS',
      rng_range_description: 'Bulb \u00d8 24 cm + 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 26.135,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z57TV5TC;9DSLNHNT;Y17K4X88;HE7NLN3N;65ANT783;CP3WTRSC;7YTCVRXT;HRNH1Z13;BYCYW6XJ;8Y8EE25E;SZ67SWGA;CSPAELRS;7YATPV1A;5962TWZH;4ZE8N445;27NSS2L8;JNXY2H23;CG6CLSD3',
      statusContent: 'dot',
      hash: '2BK3DRPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BK3DRPZ'
    },
    {
      status: 210,
      sku: 'PM0077897',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349382',
      rtl_batch_array: '4349382',
      name_search: 'EDI63004',
      name: "Colchicum 'Giant'",
      sppl_size_code: '020022BOP10',
      rng_range_identifier: 'BU\u00d82002210PCS',
      rng_range_description: 'Bulb \u00d8 20 cm 22 cm 10 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NTXZZKBV;VDWRG4PZ;CNSADCCN;1296PCTE;4T8YZKDT;PGJRD2RD;K775XXVR;LKR6N1V1;K2LC17TT',
      statusContent: 'dot',
      hash: 'Y3EDXTB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3EDXTB5'
    },
    {
      status: 910,
      sku: 'PM0074231',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GASSUNRI',
      name: "Gaillardia 'Sunset Sunrise'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'W1EEDPG5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W1EEDPG5'
    },
    {
      status: 210,
      sku: 'PM0077898',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '4349403',
      rtl_batch_array: '4349403',
      name_search: 'HYMBLAU',
      name: 'Hyacinthus multiflora blauw',
      sppl_size_code: 'BOK1P25',
      rng_range_identifier: 'BU25PCS',
      rng_range_description: 'Bulb 25 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 22.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2EXK6AB;5Y3G64D7;GSWTTXWZ;HCP97LG8;K5VTJ59A;9A9GZPDZ;8J9XVV3S;WEN969EY;KXBE38Z3;SL8GA3YV;9Y5N21SA;ARZXTLXB',
      statusContent: 'dot',
      hash: '4RJRZ96T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RJRZ96T'
    },
    {
      status: 210,
      sku: 'PM0081324',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339850',
      rtl_batch_array: '6339850',
      name_search: 'KNPSUNSE',
      name: "Kniphofia 'Poco Sunset'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L2YHG3XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2YHG3XY'
    },
    {
      status: 210,
      sku: 'PM0032838',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244460',
      rtl_batch_array: '6244460, 5837467',
      name_search: 'PEALOPEC',
      name: 'Pennisetum alopecuroides',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 3476,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRWLYSDR;83B33ADA;27VD1BC4;7LNJ3ARN;SNP3L9WL;GW5J4LST;L9HPT3EL;D4LB3PJK;KLP2TT9K;S46D2SL9;8CV52Z65;YKGTHGSS;VZVDC4J7;S4RBGSPH;D6S1W9CB;149JH1D2;PG5CA87X;NPW9N9WY;YSN92SY3',
      statusContent: 'dot',
      hash: '589JE5KK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '589JE5KK'
    },
    {
      status: 210,
      sku: 'PM0077899',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5484334',
      rtl_batch_array: '5484334',
      name_search: 'EDI61194',
      name: "Tulipa (vir) 'Spring Green'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 99998,
      chnn_stock_retail: 99998,
      sppl_prcp: 16.153,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9HJ1LS65;2G993VD4;2AYXHE2W;CRAR57EW;K2GAVXJD;42ZJVA9W;6YGWPH8C;LPVVLRJZ;99BTEAB7;XYK8NKV7;DJPV8V8H;ZY3KBCSN',
      statusContent: 'dot',
      hash: 'ZSXN42DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSXN42DA'
    },
    {
      status: 210,
      sku: 'PM0081325',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339851',
      rtl_batch_array: '6339851',
      name_search: 'KNSYELLO',
      name: "Kniphofia 'Sunningdale Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATD2C4G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATD2C4G4'
    },
    {
      status: 210,
      sku: 'PM0081326',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339855',
      rtl_batch_array: '6339855',
      name_search: 'MUCAPILL',
      name: 'Muhlenbergia capillaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2697,
      chnn_stock_retail: 2697,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CXGXBXG8;TD6C4NGA',
      statusContent: 'dot',
      hash: '5B7D9X8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B7D9X8R'
    },
    {
      status: 210,
      sku: 'PM0077900',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '5744403',
      rtl_batch_array: '5744403',
      name_search: 'EDI60559',
      name: "Tulipa (vir) 'Groenland'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 16.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '569VYN55;EPZZ13KX;D7JRY2W8;P9JT72E3;4KESXG8D;X8VLX6JW;A5GBJGW4',
      statusContent: 'dot',
      hash: '7SVP4ZLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SVP4ZLE'
    },
    {
      status: 810,
      sku: 'PM0085128',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371190',
      rtl_batch_array: '6371190',
      name_search: 'LOCBTRUF',
      name: "Lobelia cardinalis 'Black Truffle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R6BWDYES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6BWDYES'
    },
    {
      status: 210,
      sku: 'PM0077977',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346658',
      rtl_batch_array: '6346658',
      name_search: 'HEHKOLIB',
      name: "Hedera helix 'Kolibri'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SX2XEX1L;1D6ELAX9;AYWAZ7JD;5X1H7LJZ;N1XEAE77',
      statusContent: 'dot',
      hash: 'L6EG6HBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6EG6HBC'
    },
    {
      status: 210,
      sku: 'PM0038379',
      core_name: 'Plant',
      sppl_ean: '8720664862609',
      btch_manufacturer: 565,
      rtl_batch_array_total: 3,
      btch_active_retail: '6076593',
      rtl_batch_array: '6076593, 6265437, 6266111',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 2260,
      chnn_stock_retail: 12220,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '8DDRCHPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DDRCHPL'
    },
    {
      status: 210,
      sku: 'PM0084904',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369732',
      rtl_batch_array: '6369732',
      name_search: 'COLUTEA',
      name: 'Corydalis lutea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4L7CA612;P44PWAE2;T12Z7KNR;8K25N6XN;G12YVYSG;111KHDRH',
      statusContent: 'dot',
      hash: 'CN877LYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CN877LYP'
    },
    {
      status: 210,
      sku: 'PM0085129',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371191',
      rtl_batch_array: '6371191',
      name_search: 'LOSSBLUE',
      name: 'Lobelia s. Starship Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7V97HLDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7V97HLDP'
    },
    {
      status: 210,
      sku: 'PM0077979',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346659',
      rtl_batch_array: '6346659',
      name_search: 'HEHMELEG',
      name: "Hedera helix 'Marginata Elegantissima'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7AH3SWL6',
      statusContent: 'dot',
      hash: 'G1GP1DTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1GP1DTZ'
    },
    {
      status: 810,
      sku: 'PM0081327',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339856',
      rtl_batch_array: '6339856',
      name_search: 'MURCFANS',
      name: 'Mukdenia rossii Crimson Fans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8279N4X9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8279N4X9'
    },
    {
      status: 210,
      sku: 'PM0085130',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371192',
      rtl_batch_array: '6371192',
      name_search: 'LOSSBURG',
      name: 'Lobelia s. Starship Burgundy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LVR7E32G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVR7E32G'
    },
    {
      status: 210,
      sku: 'PM0046693',
      core_name: 'Plant',
      sppl_ean: '8720664695139',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875133',
      rtl_batch_array: '5875133',
      name_search: 'IRNEDITI',
      name: "Iris (G) 'Night Edition'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A22N8VD2',
      imageBatch: 'AK7VA488',
      statusContent: 'dot',
      hash: 'DH8XWKLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH8XWKLL'
    },
    {
      status: 810,
      sku: 'PM0045933',
      core_name: 'Plant',
      sppl_ean: '8720664866034',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161719',
      rtl_batch_array: '6301626, 6161719',
      name_search: 'HEBANDER',
      name: "Helenium 'Bandera'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 205,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8LW9TXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8LW9TXZ'
    },
    {
      status: 210,
      sku: 'PM0077981',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346660',
      rtl_batch_array: '6346660',
      name_search: 'HEHPLATT',
      name: "Hedera helix 'Plattensee'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1960,
      chnn_stock_retail: 1960,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EKN1GPSE;ELNL25E6',
      statusContent: 'dot',
      hash: 'VXDTHEN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXDTHEN3'
    },
    {
      status: 210,
      sku: 'PM0085131',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371193',
      rtl_batch_array: '6371193',
      name_search: 'LOSSSCAR',
      name: "Lobelia speciosa 'Starship Scarlet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T4JCZLT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4JCZLT5'
    },
    {
      status: 210,
      sku: 'PM0081328',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339857',
      rtl_batch_array: '6339857',
      name_search: 'MYPALUST',
      name: 'Myosotis palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1580,
      chnn_stock_retail: 1580,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HPKVW1D6',
      statusContent: 'dot',
      hash: '7VCYW6C7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VCYW6C7'
    },
    {
      status: 210,
      sku: 'PM0071016',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6216842',
      rtl_batch_array: '6216842',
      name_search: 'JUHWILTO',
      name: "Juniperus horizontalis 'Wiltonii'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1213,
      chnn_stock_retail: 1213,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTXR91JA;XE2PNDN2;79XC8391;KA6JEJWN;TTPAJR27;5DLCNL9T;8PDW76PN;77XT6W5A;171G33ND;HCB8CRHL;PKNT5STB;SX87XJ56',
      statusContent: 'dot',
      hash: 'A7JN6DL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7JN6DL5'
    },
    {
      status: 210,
      sku: 'PM0077983',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346661',
      rtl_batch_array: '6346661',
      name_search: 'HEHSHAMR',
      name: "Hedera helix 'Shamrock'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1990,
      chnn_stock_retail: 1990,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NS6PY3GE;BXH4G32E;LD4E64CR;Z4GX26CK',
      statusContent: 'dot',
      hash: '9LE9T5HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LE9T5HA'
    },
    {
      status: 210,
      sku: 'PM0077984',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346662',
      rtl_batch_array: '6346662',
      name_search: 'HEHTHORN',
      name: "Hedera helix 'Thorndale'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K6WLXGYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6WLXGYV'
    },
    {
      status: 210,
      sku: 'PM0085132',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371194',
      rtl_batch_array: '6371194',
      name_search: 'LYSCANDL',
      name: 'Lysimachia Snow Candles',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 666,
      chnn_stock_retail: 666,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A7V5R9PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7V5R9PV'
    },
    {
      status: 810,
      sku: 'PM0081329',
      core_name: 'Plant',
      sppl_ean: '8720664875005',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133410',
      rtl_batch_array: '6339858, 6133410',
      name_search: 'NEALBA',
      name: "Nepeta 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZGE7K2T4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGE7K2T4'
    },
    {
      status: 210,
      sku: 'PM0081330',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319719',
      rtl_batch_array: '6339862, 6319719',
      name_search: 'NEGOVANI',
      name: 'Nepeta govaniana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '47A9ADYY;VT4KWSYY;RC5ARTB2',
      statusContent: 'dot',
      hash: '25SYSEL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25SYSEL7'
    },
    {
      status: 810,
      sku: 'PM0068599',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307673',
      rtl_batch_array: '6307673',
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 18.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N6REHAEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6REHAEP'
    },
    {
      status: 210,
      sku: 'PM0063561',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260692',
      rtl_batch_array: '6260692, 6254739',
      name_search: 'GEPHAEUM',
      name: 'Geranium phaeum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2533,
      chnn_stock_retail: 2933,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E19R7WLV',
      statusContent: 'dot',
      hash: 'ALP2KVTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALP2KVTE'
    },
    {
      status: 210,
      sku: 'PM0084905',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369740',
      rtl_batch_array: '6369740',
      name_search: 'HEFRILLY',
      name: "Heuchera 'Frilly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HNS15C9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNS15C9P'
    },
    {
      status: 210,
      sku: 'PM0084906',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369742',
      rtl_batch_array: '6369742',
      name_search: 'HEMAGMA',
      name: "Heuchera 'Magma'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6ASE9DZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ASE9DZN'
    },
    {
      status: 210,
      sku: 'PM0045541',
      core_name: 'Plant',
      sppl_ean: '8720664858503',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133274',
      rtl_batch_array: '6133274',
      name_search: 'COSROSEA',
      name: "Cortaderia selloana 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 426,
      chnn_stock_retail: 426,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25X4TCHJ;27YWYHL2;Y7GGS9RN;A1CBSRN5;82XSDGPX;G2Y373YL;WG33R43S;H1AWYZBV',
      statusContent: 'dot',
      hash: '74P1ADK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74P1ADK3'
    },
    {
      status: 210,
      sku: 'PM0077992',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254719',
      rtl_batch_array: '6254719',
      name_search: 'ERMARITI',
      name: 'Eryngium maritimum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2K7HYHBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2K7HYHBV'
    },
    {
      status: 210,
      sku: 'PM0084907',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369745',
      rtl_batch_array: '6369745',
      name_search: 'HESECLIP',
      name: "Heucherella 'Solar Eclips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VLBXG2ZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLBXG2ZR'
    },
    {
      status: 810,
      sku: 'PM0085133',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371195',
      rtl_batch_array: '6371195',
      name_search: 'MAKAMTSC',
      name: 'Maianthemum kamtschaticum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J1WH5TC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1WH5TC7'
    },
    {
      status: 210,
      sku: 'PM0066941',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176526',
      rtl_batch_array: '6176526, 6377873',
      name_search: 'LUPILOSA',
      name: 'Luzula pilosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '465L38YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '465L38YX'
    },
    {
      status: 210,
      sku: 'PM0060152',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276632',
      rtl_batch_array: '6254761, 6276632',
      name_search: 'KNMACEDO',
      name: 'Knautia macedonica',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2553,
      chnn_stock_retail: 2665,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1V3B96XA;TN9V5X3T;V9J43XHP',
      statusContent: 'dot',
      hash: 'CJDDZWW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJDDZWW3'
    },
    {
      status: 210,
      sku: 'PM0077993',
      core_name: 'Plant',
      sppl_ean: '8720664696235',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040489',
      rtl_batch_array: '6040489',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '060STC24B25',
      rng_range_identifier: 'BALL\u00d8025STE060C2.5',
      rng_range_description: 'Ball \u00d8 25 cm Stem 60 cm C2.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 12.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '6J31E3AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J31E3AK'
    },
    {
      status: 210,
      sku: 'PM0077994',
      core_name: 'Plant',
      sppl_ean: '8720664696242',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040490',
      rtl_batch_array: '6040490',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '060STC5B25',
      rng_range_identifier: 'BALL\u00d8025STE060C5',
      rng_range_description: 'Ball \u00d8 25 cm Stem 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 21.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'B4T7BJ77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4T7BJ77'
    },
    {
      status: 810,
      sku: 'PM0084908',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378462',
      rtl_batch_array: '6378462',
      name_search: 'BUDWFPOW',
      name: "Buddleja weyeriana 'Flower Power'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 6.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V6LS63EP;BLAL1XLT;ZH17R6WH;DHJZ9BX4;D38JR32Y;YZ9SDLYE;AWNPBXSK;6KZSHGC3;9XR7P9S1;L7BEDJL8;HL2CGBTW;RBSBP6HV;DWYY3NXE;DPZZJDPJ;K93KCZR7;ZLJHA95N',
      statusContent: 'dot',
      hash: 'BY2DZ915',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY2DZ915'
    },
    {
      status: 910,
      sku: 'PM0059558',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'E6HLKTTD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E6HLKTTD'
    },
    {
      status: 810,
      sku: 'PM0069127',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212017',
      rtl_batch_array: '6212017',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '150175C50',
      rng_range_identifier: 'H150175C50',
      rng_range_description: 'H150 cm 175 cm C50',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 132.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: '5AGLJ6SR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AGLJ6SR'
    },
    {
      status: 210,
      sku: 'PM0077995',
      core_name: 'Plant',
      sppl_ean: '8720664696273',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040495',
      rtl_batch_array: '6040495',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '050STC5B25',
      rng_range_identifier: 'BALL\u00d8025STE050C5',
      rng_range_description: 'Ball \u00d8 25 cm Stem 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 21.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'VC8397T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VC8397T6'
    },
    {
      status: 210,
      sku: 'PM0063562',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177581',
      rtl_batch_array: '6177581',
      name_search: 'HEORUBY',
      name: "Hemerocallis 'Oriental Ruby'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9Y7L27X1',
      statusContent: 'dot',
      hash: 'ESCPCSS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESCPCSS9'
    },
    {
      status: 210,
      sku: 'PM0028849',
      core_name: 'Plant',
      sppl_ean: '8720664850309',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6084976',
      rtl_batch_array: '6084976, 6090588, 6054288, 5495146',
      name_search: 'ACMCQUEE',
      name: "Achillea millefolium 'Cerise Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 27004,
      chnn_stock_retail: 28261,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYAP7K78;113NZJKA;G1DCNVHG;LHAK24LV',
      statusContent: 'dot',
      hash: 'T7PTXZ3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7PTXZ3R'
    },
    {
      status: 810,
      sku: 'PM0077996',
      core_name: 'Plant',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040497',
      rtl_batch_array: '6040497',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '060STC10B45',
      rng_range_identifier: 'BALL\u00d8045STE060C10',
      rng_range_description: 'Ball \u00d8 45 cm Stem 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'TN3AA6SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TN3AA6SW'
    },
    {
      status: 810,
      sku: 'PM0077997',
      core_name: 'Plant',
      sppl_ean: '8720664696204',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040498',
      rtl_batch_array: '6040498',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '050STC10B50',
      rng_range_identifier: 'BALL\u00d8050STE050C10',
      rng_range_description: 'Ball \u00d8 50 cm Stem 50 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 61.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '55VCP5PS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55VCP5PS'
    },
    {
      status: 210,
      sku: 'PM0077998',
      core_name: 'Plant',
      sppl_ean: '8720664696198',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040501',
      rtl_batch_array: '6040501',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '070STC10B45',
      rng_range_identifier: 'BALL\u00d8045STE070C10',
      rng_range_description: 'Ball \u00d8 45 cm Stem 70 cm C10',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 54.843,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'W1NJX2KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1NJX2KA'
    },
    {
      status: 210,
      sku: 'PM0030948',
      core_name: 'Plant',
      sppl_ean: '8720664805415',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5534233',
      rtl_batch_array: '5534233',
      name_search: 'ROLFIZZ',
      name: 'Rosa (F) Lemon Fizz',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5CL1J4A8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CL1J4A8'
    },
    {
      status: 210,
      sku: 'PM0077999',
      core_name: 'Plant',
      sppl_ean: '8720664696228',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040502',
      rtl_batch_array: '6040502',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '060STC10B50',
      rng_range_identifier: 'BALL\u00d8050STE060C10',
      rng_range_description: 'Ball \u00d8 50 cm Stem 60 cm C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 63.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'THSZRC96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THSZRC96'
    },
    {
      status: 210,
      sku: 'PM0078000',
      core_name: 'Plant',
      sppl_ean: '8720664696303',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040505',
      rtl_batch_array: '6040505',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '080STC10B45',
      rng_range_identifier: 'BALL\u00d8045STE080C10',
      rng_range_description: 'Ball \u00d8 45 cm Stem 80 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 53.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '4CC9AX94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CC9AX94'
    },
    {
      status: 210,
      sku: 'PM0078001',
      core_name: 'Plant',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040506',
      rtl_batch_array: '6040506',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '100STC10B35',
      rng_range_identifier: 'BALL\u00d8035STE100C10',
      rng_range_description: 'Ball \u00d8 35 cm Stem 100 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 36.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '9L1G915X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L1G915X'
    },
    {
      status: 210,
      sku: 'PM0046638',
      core_name: 'Plant',
      sppl_ean: '8720626335714',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014575',
      rtl_batch_array: '6229867, 6014575',
      name_search: 'ASCVPINK',
      name: "Astilbe chinensis 'Vision in Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 206,
      chnn_stock_retail: 489,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NGYE189K',
      statusContent: 'dot',
      hash: '5TAS8K8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TAS8K8L'
    },
    {
      status: 210,
      sku: 'PM0078002',
      core_name: 'Plant',
      sppl_ean: '8720664696310',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040507',
      rtl_batch_array: '6040507',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '100STC10B40',
      rng_range_identifier: 'BALL\u00d8040STE100C10',
      rng_range_description: 'Ball \u00d8 40 cm Stem 100 cm C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 46.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'R9ZLSVZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9ZLSVZ3'
    },
    {
      status: 810,
      sku: 'PM0078003',
      core_name: 'Plant',
      sppl_ean: '8720664696372',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040508',
      rtl_batch_array: '6040508',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '100STC10B45',
      rng_range_identifier: 'BALL\u00d8045STE100C10',
      rng_range_description: 'Ball \u00d8 45 cm Stem 100 cm C10',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 55.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'WSEH1VXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSEH1VXX'
    },
    {
      status: 210,
      sku: 'PM0084909',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378463',
      rtl_batch_array: '6378463',
      name_search: 'BUDWFPOW',
      name: "Buddleja weyeriana 'Flower Power'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2286,
      chnn_stock_retail: 2286,
      sppl_prcp: 3.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V6LS63EP;BLAL1XLT;ZH17R6WH;DHJZ9BX4;D38JR32Y;YZ9SDLYE;AWNPBXSK;6KZSHGC3;9XR7P9S1;L7BEDJL8;HL2CGBTW;RBSBP6HV;DWYY3NXE;DPZZJDPJ;K93KCZR7;ZLJHA95N',
      statusContent: 'dot',
      hash: 'ALVE4LBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALVE4LBT'
    },
    {
      status: 810,
      sku: 'PM0078004',
      core_name: 'Plant',
      sppl_ean: '8720664696327',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040509',
      rtl_batch_array: '6040509',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '100STC10B50',
      rng_range_identifier: 'BALL\u00d8050STE100C10',
      rng_range_description: 'Ball \u00d8 50 cm Stem 100 cm C10',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 65.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'GXE4TCGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXE4TCGA'
    },
    {
      status: 210,
      sku: 'PM0026507',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353343',
      rtl_batch_array: '6353343',
      name_search: 'SAPRATEN',
      name: 'Salvia pratensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9RNS2CZ1;6ECWKSRT;CPLPV5N6;9XE8XDNP;ES4WBYSK;C8TDZ19Y;DRHJ7RDD;K13L7XVG;2YDT5K3Z;9EZP78K6;HXN2KB1Z;ATVNASV1',
      statusContent: 'dot',
      hash: '256GGG8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '256GGG8B'
    },
    {
      status: 910,
      sku: 'PM0085134',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MEGGINGE',
      name: "Mentha gentilis 'Ginger'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '67GGJE7Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '67GGJE7Z'
    },
    {
      status: 910,
      sku: 'PM0085135',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'NEFSHGGO',
      name: "Nepeta faassenii 'Six Hills Giant Gold'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WCTLSZAB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WCTLSZAB'
    },
    {
      status: 210,
      sku: 'PM0046714',
      core_name: 'Plant',
      sppl_ean: '8720664695092',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875177',
      rtl_batch_array: '5875177',
      name_search: 'IRLAWILL',
      name: "Iris (G) 'Lent A. Williamson'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'C3TZ7RWK',
      statusContent: 'dot',
      hash: 'VC2E4K3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VC2E4K3Z'
    },
    {
      status: 210,
      sku: 'PM0085136',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371198',
      rtl_batch_array: '6371198',
      name_search: 'OPJVARIE',
      name: "Ophiopogon japonicus 'Variegatus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 273,
      chnn_stock_retail: 273,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3A5JSN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3A5JSN8'
    },
    {
      status: 210,
      sku: 'PM0059609',
      core_name: 'Plant',
      sppl_ean: '8720349425303',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354852',
      rtl_batch_array: '5354852',
      name_search: 'PHPANICU',
      name: 'Phlox paniculata',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'T3XEJ47W',
      statusContent: 'dot',
      hash: 'D6PCD6KD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6PCD6KD'
    },
    {
      status: 210,
      sku: 'PM0078006',
      core_name: 'Plant',
      sppl_ean: '8720664696341',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040512',
      rtl_batch_array: '6040512',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '060STC15B55',
      rng_range_identifier: 'BALL\u00d8055STE060C15',
      rng_range_description: 'Ball \u00d8 55 cm Stem 60 cm C15',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 96.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'ZGCB1KBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGCB1KBT'
    },
    {
      status: 910,
      sku: 'PM0066484',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: '2G5R68NX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2G5R68NX'
    },
    {
      status: 210,
      sku: 'PM0071017',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254874',
      rtl_batch_array: '6254874',
      name_search: 'AJURBSCA',
      name: 'Ajuga reptans Black Scallop',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B95HB5XL;HLH7NZZ5;T6KNDJHB;2ZK9W9KE;6DG2RG8R;G2GR7WTR;JAZVK9BY;561C4XNY;67SN631N',
      statusContent: 'dot',
      hash: 'WVN8C6HV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVN8C6HV'
    },
    {
      status: 210,
      sku: 'PM0078007',
      core_name: 'Plant',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040514',
      rtl_batch_array: '6040514',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '070STC15B55',
      rng_range_identifier: 'BALL\u00d8055STE070C15',
      rng_range_description: 'Ball \u00d8 55 cm Stem 70 cm C15',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 96.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'VNJ7L46D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNJ7L46D'
    },
    {
      status: 210,
      sku: 'PM0054088',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6258898',
      rtl_batch_array: '6059254, 6258898',
      name_search: 'ROSWANY',
      name: 'Rosa (H) Swany',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4490,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C5G29CKZ;EZLGG4P9;H66RVJZ4;EGN5DYEE',
      statusContent: 'dot',
      hash: 'Z7JSW4WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7JSW4WG'
    },
    {
      status: 210,
      sku: 'PM0059605',
      core_name: 'Plant',
      sppl_ean: '8720349425181',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354824',
      rtl_batch_array: '5354824',
      name_search: 'PHSSTRIP',
      name: 'Phlox (P) Stars and Stripes',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '6CYLKADT',
      statusContent: 'dot',
      hash: 'B1ARDJCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1ARDJCS'
    },
    {
      status: 210,
      sku: 'PM0064553',
      core_name: 'Garden',
      sppl_ean: '5413448072426',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '4661911',
      rtl_batch_array: '4661911',
      name_search: 'VIUNIVER',
      name: 'Vivimus universeel',
      sppl_size_code: '40LTRZAK',
      rng_range_identifier: 'BAG40LTR',
      rng_range_description: 'Bag 40 Liter',
      sppl_stock_available: 892,
      chnn_stock_retail: 892,
      sppl_prcp: 4.65,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '946B7J13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '946B7J13'
    },
    {
      status: 810,
      sku: 'PM0078008',
      core_name: 'Plant',
      sppl_ean: '8720664696532',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040525',
      rtl_batch_array: '6040525',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '025C5BOL',
      rng_range_identifier: 'BALL\u00d8025H025025C5',
      rng_range_description: 'Ball \u00d8 25 cm H25 cm 25 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'Z9W5XYE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9W5XYE9'
    },
    {
      status: 210,
      sku: 'PM0054486',
      core_name: 'Plant',
      sppl_ean: '8720664886261',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6076346',
      rtl_batch_array: '6076346',
      name_search: 'SISCHAFT',
      name: 'Silene schafta',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4059,
      chnn_stock_retail: 4059,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RSRNWPEL;2GSNTX1P;45HDHJEH;1K1EAZP9;WPTDCX5A;25YAS7ZJ;WP752CBD;JJS9ZABH',
      statusContent: 'dot',
      hash: 'J47BRAPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J47BRAPY'
    },
    {
      status: 210,
      sku: 'PM0078009',
      core_name: 'Plant',
      sppl_ean: '8720664696402',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040526',
      rtl_batch_array: '6040526',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '030C5BOL',
      rng_range_identifier: 'BALL\u00d8030H030030C5',
      rng_range_description: 'Ball \u00d8 30 cm H30 cm 30 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 20.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'RZL5WEJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZL5WEJG'
    },
    {
      status: 210,
      sku: 'PM0078010',
      core_name: 'Plant',
      sppl_ean: '8720664696419',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040527',
      rtl_batch_array: '6040527',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '035C5BOL',
      rng_range_identifier: 'BALL\u00d8035H035035C5',
      rng_range_description: 'Ball \u00d8 35 cm H35 cm 35 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 23.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'S9JPDNGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9JPDNGC'
    },
    {
      status: 210,
      sku: 'PM0069122',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212012',
      rtl_batch_array: '6212012',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 25.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'Z2GNNHEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2GNNHEW'
    },
    {
      status: 210,
      sku: 'PM0058249',
      core_name: 'Plant',
      sppl_ean: '8720664694897',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112477',
      rtl_batch_array: '6112477',
      name_search: 'IRBTAFET',
      name: "Iris (G) 'Black Tafetta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 259,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VNH5A791',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNH5A791'
    },
    {
      status: 210,
      sku: 'PM0046680',
      core_name: 'Plant',
      sppl_ean: '8720664694965',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875106',
      rtl_batch_array: '5875106',
      name_search: 'IRCWATTE',
      name: "Iris (G) 'Constant Wattez'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ZH5J6VAT',
      statusContent: 'dot',
      hash: '6JRZDWK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JRZDWK9'
    },
    {
      status: 210,
      sku: 'PM0078011',
      core_name: 'Plant',
      sppl_ean: '8720664696594',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040544',
      rtl_batch_array: '6040544',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '100110C15KE',
      rng_range_identifier: 'CONEH100110C15',
      rng_range_description: 'Cone H100 cm 110 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 68.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'YSG9EYW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSG9EYW5'
    },
    {
      status: 210,
      sku: 'PM0070029',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205325',
      rtl_batch_array: '6205325',
      name_search: 'PHLPFLAM',
      name: 'Phlox (P) Light Pink Flame',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LV9BS7DA',
      statusContent: 'dot',
      hash: 'ZW2DALPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW2DALPY'
    },
    {
      status: 810,
      sku: 'PM0066433',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174178',
      rtl_batch_array: '6174178',
      name_search: 'PIATOMPA',
      name: "Picea abies 'Tompa'",
      sppl_size_code: '015020C3',
      rng_range_identifier: 'H015020C3',
      rng_range_description: 'H15 cm 20 cm C3',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZYL4L1BZ;W5YDGWJV;DDKE7K3C;ETCAEAB5;4BTJYWJC;KG5SH6G8',
      statusContent: 'dot',
      hash: 'PA9RCGYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA9RCGYZ'
    },
    {
      status: 210,
      sku: 'PM0078012',
      core_name: 'Plant',
      sppl_ean: '8720349421114',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040545',
      rtl_batch_array: '6040545',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '110120C15KE',
      rng_range_identifier: 'CONEH110120C15',
      rng_range_description: 'Cone H110 cm 120 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 74.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'CR9K7CLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR9K7CLD'
    },
    {
      status: 210,
      sku: 'PM0078013',
      core_name: 'Plant',
      sppl_ean: '8720349421121',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040546',
      rtl_batch_array: '6040546',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '120130C15KE',
      rng_range_identifier: 'CONEH120130C15',
      rng_range_description: 'Cone H120 cm 130 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 82.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'JDSTS32J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDSTS32J'
    },
    {
      status: 210,
      sku: 'PM0046684',
      core_name: 'Plant',
      sppl_ean: '8720664695160',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875118',
      rtl_batch_array: '5875118',
      name_search: 'IRPCOUNT',
      name: "Iris (G) 'Perfume Counter'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RWW463EV',
      statusContent: 'dot',
      hash: 'RCRZX51R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCRZX51R'
    },
    {
      status: 910,
      sku: 'PM0064479',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PIAWZWER',
      name: "Picea abies 'Will's Zwerg'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: 'XT4JELHX;RYE9ALYA;R2BX7HZG',
      statusContent: 'dot',
      hash: '1TW3WEGB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1TW3WEGB'
    },
    {
      status: 210,
      sku: 'PM0057955',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6196856',
      rtl_batch_array: '6196856, 6301558, 6164407, 6330043',
      name_search: 'PHLAURA',
      name: "Phlox (P) 'Laura'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10608,
      chnn_stock_retail: 11953,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCZ13N1T;HCVHRP3N;YEBJ6BGN;D9PXLDV7;A7DW98VY;11A9TSLZ;J56HVS97;N51Y6JJN;N95RDV82;77RHP285;BTCR5DB9;V14THECA',
      statusContent: 'dot',
      hash: 'AAJ6EYPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAJ6EYPV'
    },
    {
      status: 210,
      sku: 'PM0078014',
      core_name: 'Plant',
      sppl_ean: '8720664696600',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040547',
      rtl_batch_array: '6040547',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '130140C15KE',
      rng_range_identifier: 'CONEH130140C15',
      rng_range_description: 'Cone H130 cm 140 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 89.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '1XEZ7LG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XEZ7LG4'
    },
    {
      status: 210,
      sku: 'PM0078015',
      core_name: 'Plant',
      sppl_ean: '8720664696617',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040548',
      rtl_batch_array: '6040548',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '140150C15KE',
      rng_range_identifier: 'CONEH140150C15',
      rng_range_description: 'Cone H140 cm 150 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 110.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '8KASJRN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KASJRN5'
    },
    {
      status: 810,
      sku: 'PM0069131',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 2,
      btch_active_retail: '6212083',
      rtl_batch_array: '6244923, 6212083',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 18.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'TLA719JB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLA719JB'
    },
    {
      status: 210,
      sku: 'PM0069121',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212011',
      rtl_batch_array: '6212011',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 30.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'HTT3WZ45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTT3WZ45'
    },
    {
      status: 210,
      sku: 'PM0069132',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 2,
      btch_active_retail: '6212084',
      rtl_batch_array: '6244924, 6212084',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 300,
      sppl_prcp: 18.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'H3NENGEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3NENGEX'
    },
    {
      status: 210,
      sku: 'PM0057059',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350240',
      rtl_batch_array: '6350240, 6301606',
      name_search: 'GEROSETT',
      name: "Geranium 'Rosetta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 362,
      chnn_stock_retail: 398,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2L5BBD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2L5BBD6'
    },
    {
      status: 210,
      sku: 'PM0074232',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268742',
      rtl_batch_array: '6268742',
      name_search: 'DIPLUCAS',
      name: 'Digitalis purpurea Lucas',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XHSGHXY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHSGHXY8'
    },
    {
      status: 210,
      sku: 'PM0058485',
      core_name: 'Plant',
      sppl_ean: '8720664881600',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112831',
      rtl_batch_array: '6112831',
      name_search: 'PRDROSAL',
      name: "Primula 'Dark Rosaleen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7ZTA57DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZTA57DX'
    },
    {
      status: 210,
      sku: 'PM0046722',
      core_name: 'Plant',
      sppl_ean: '8720664694927',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875186',
      rtl_batch_array: '5875186',
      name_search: 'IRBBRIGH',
      name: "Iris (G) 'Burning Bright'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1JBZ814J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JBZ814J'
    },
    {
      status: 810,
      sku: 'PM0057413',
      core_name: 'Plant',
      sppl_ean: '8720664884502',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133607',
      rtl_batch_array: '6133607, 6330070',
      name_search: 'SAFPEACO',
      name: 'Salvia Feathers Peacock',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2L51RBSA;WTRR24BE;9RDRN125;L74D152P;BS7XKBLX;8GBACD72;YTDDLL55',
      statusContent: 'dot',
      hash: '543CG3SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '543CG3SG'
    },
    {
      status: 210,
      sku: 'PM0046721',
      core_name: 'Plant',
      sppl_ean: '8720664694910',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875185',
      rtl_batch_array: '5875185',
      name_search: 'IRBRUNO',
      name: "Iris (G) 'Bruno'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PPTWWN5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPTWWN5Y'
    },
    {
      status: 210,
      sku: 'PM0070030',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205326',
      rtl_batch_array: '6205326',
      name_search: 'PHLFLAME',
      name: 'Phlox (P) Lilac Flame',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7XBHEK61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XBHEK61'
    },
    {
      status: 210,
      sku: 'PM0059506',
      core_name: 'Plant',
      sppl_ean: '8720664868724',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6119830',
      rtl_batch_array: '6119830',
      name_search: 'HYMMASJ',
      name: "Hydrangea m. 'Masja'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1604,
      chnn_stock_retail: 1604,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AY5GH6N2;GJVT5SXH;8DPRVEC6;E7CB2ABV',
      statusContent: 'dot',
      hash: 'HX9GE1GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX9GE1GC'
    },
    {
      status: 210,
      sku: 'PM0064492',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317859',
      rtl_batch_array: '6220871, 6317859',
      name_search: 'PAOBRILL',
      name: "Papaver orientale 'Brilliant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 422,
      chnn_stock_retail: 542,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CPD8V2TZ;S447X4WC;CD44BA1J;TRZZR2XD;98SZT8EX',
      statusContent: 'dot',
      hash: '2VPRXST7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VPRXST7'
    },
    {
      status: 210,
      sku: 'PM0048145',
      core_name: 'Plant',
      sppl_ean: '8720664680005',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014391',
      rtl_batch_array: '6014391',
      name_search: 'EDI82786',
      name: "Allium 'Dutchman'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 174,
      chnn_stock_retail: 174,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '49J9RP9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49J9RP9J'
    },
    {
      status: 210,
      sku: 'PM0068603',
      core_name: 'Plant',
      sppl_ean: '8720349497850',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '5964235',
      rtl_batch_array: '6370491, 5964235, 6254693',
      name_search: 'DECESPIT',
      name: 'Deschampsia cespitosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 7335,
      chnn_stock_retail: 9735,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWAZ5E95',
      statusContent: 'dot',
      hash: 'PN57GVT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PN57GVT6'
    },
    {
      status: 210,
      sku: 'PM0077901',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306364',
      rtl_batch_array: '6306364',
      name_search: 'EDI60403',
      name: "Tulipa (par) 'Estella Rijnveld'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 23.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KK2YXRYE;LL1V6C31;HBHCW2BP;7WJNG6ZT;9Y7G2B53;ZSKH5RZ3;XH8KVNS3',
      statusContent: 'dot',
      hash: 'AEY2532D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEY2532D'
    },
    {
      status: 210,
      sku: 'PM0046725',
      core_name: 'Plant',
      sppl_ean: '8720664695191',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875189',
      rtl_batch_array: '5875189',
      name_search: 'IRROBUST',
      name: "Iris (G) 'Robusto'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AC8D9X5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AC8D9X5Z'
    },
    {
      status: 210,
      sku: 'PM0081331',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339863',
      rtl_batch_array: '6339863, 6302688',
      name_search: 'NEGSMAGI',
      name: "Nepeta grandiflora 'Summer Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1893,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RP5WZ17;T7496CDW;P452GK26;6A1WR2V4;AH7VDZTW;RE8RK5TT;CAK613GL',
      statusContent: 'dot',
      hash: 'AAVJCZJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAVJCZJG'
    },
    {
      status: 210,
      sku: 'PM0077902',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306365',
      rtl_batch_array: '6306365',
      name_search: 'EDI60416',
      name: "Tulipa (fri) 'Fancy Frills'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.305,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A7WC4AKX;HWD7R2BP;RPALVCD9;GVS76Y87;77VXEGNC;G9P3J83K;KSTTB6B7;P6Y825A8',
      statusContent: 'dot',
      hash: 'AP7XY28H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AP7XY28H'
    },
    {
      status: 210,
      sku: 'PM0077903',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306366',
      rtl_batch_array: '6306366',
      name_search: 'EDI75654',
      name: "Tulipa (trh) 'Flaming Flag'",
      sppl_size_code: '011012BOP10',
      rng_range_identifier: 'BU\u00d811012100PCS',
      rng_range_description: 'Bulb \u00d8 11 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4T6JT8E;N32SYAND;HTJGAL3A;Y4RT92AA;WL6NST1B;K9W4XTWA;2XX2RT3L;ASRWBRC1;Z8DC9R71;5KZ9SLGS;PNH6XGY9;E6BRSAXT;G8ETRH91;TS1D9DP9;YXTRKPW7;NYH2C4BB;WHW7GPCN;LPTCYVGC;7C3SWZSE;BDBRTTX3;YWBAV1ZC;RE52WHBA;T7X34L79;2Z4B8AYT;6ZXBXCRY;EEPLWDRW;ZATSR4JE;Z38VNXNT;1SVVG6XY',
      statusContent: 'dot',
      hash: 'R1D172RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1D172RT'
    },
    {
      status: 210,
      sku: 'PM0077904',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306367',
      rtl_batch_array: '6306367',
      name_search: 'EDI80981',
      name: "Tulipa (fri) 'Labrador'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 18.005,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5CSH5TX9;6X7VASGB;RGYZNWLH;E8JKKXDT;D5XB12RP;9ADJ3TK4;N4ZNYAY1;KJPP7946;623WRNAN;9R238B9P;AJSAE51G;LBRH79X2;TKTNW9NS;Y3NSSZKA;EHHZZ9GB;ARY8PY5C;RB69JR4C;ENJP8T82;KH1VLNER;1169DZB1;E6L3NDEY;CELGT92X',
      statusContent: 'dot',
      hash: 'EEJKVHEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEJKVHEX'
    },
    {
      status: 210,
      sku: 'PM0077905',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306368',
      rtl_batch_array: '6306368',
      name_search: 'EDI65390',
      name: "Tulipa (trh) 'Pallada'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 16.205,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y1BXNEXL;188P1ANY;DJYDAXKN;9CVTGB8H;23DB9DHV;41PR5ZKR;PPPPDTAC;VLZSNAEZ;ZEWWWY9W',
      statusContent: 'dot',
      hash: 'PJYH8K9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJYH8K9P'
    },
    {
      status: 210,
      sku: 'PM0077906',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306369',
      rtl_batch_array: '6306369',
      name_search: 'ALSMEVER',
      name: "Allium stipitatum 'Mount Everest'",
      sppl_size_code: '020+BOP100',
      rng_range_identifier: 'BU\u00d820+100PCS',
      rng_range_description: 'Bulb \u00d8 20 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 132.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '256A7LX6;EDV1XK5R;XTNL8PP1;JR9K4P1H;DHS87T5D;AXD3WHB1;LTG6JCXC',
      statusContent: 'dot',
      hash: 'H5C1JK1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5C1JK1W'
    },
    {
      status: 210,
      sku: 'PM0045778',
      core_name: 'Plant',
      sppl_ean: '8720626301085',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6034297',
      rtl_batch_array: '6034297, 6217149',
      name_search: 'GEJMVU',
      name: "Geranium 'JS Matu Vu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 780,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4S6265X6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S6265X6'
    },
    {
      status: 210,
      sku: 'PM0046769',
      core_name: 'Plant',
      sppl_ean: '8720664687912',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5887887',
      rtl_batch_array: '5887887',
      name_search: 'ECBUTTER',
      name: "Echinacea 'Butterfly'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'S6D113EG',
      statusContent: 'dot',
      hash: 'H3ERV46L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3ERV46L'
    },
    {
      status: 210,
      sku: 'PM0077908',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306371',
      rtl_batch_array: '6306371',
      name_search: 'EDI73215',
      name: 'Galanthus woronowii',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 16.209,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JB9A99WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB9A99WY'
    },
    {
      status: 210,
      sku: 'PM0077910',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306373',
      rtl_batch_array: '6306373',
      name_search: 'IPUACAST',
      name: "Ipheion uniflorum 'Alberto Castillo'",
      sppl_size_code: '005+BOP100',
      rng_range_identifier: 'BU\u00d85+100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 10.801,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J1K7Y4KS;K3D526XV;D8BVEDV6;HB8ECHKT;NS9ZCK33;BV93B1T4;TSPW5EKD;PPHSHL4G;N5BPPG9X;H9PH4X4W;DG2BXCWV;9WDY58TR;89Z4TP4W',
      statusContent: 'dot',
      hash: '2V1AZJGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V1AZJGJ'
    },
    {
      status: 210,
      sku: 'PM0077911',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306374',
      rtl_batch_array: '6306374',
      name_search: 'NABBOOME',
      name: 'Narcissus Baby Boomer',
      sppl_size_code: '010012BOP10',
      rng_range_identifier: 'BU\u00d810012100PCS',
      rng_range_description: 'Bulb \u00d8 10 cm 12 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '95H123JJ;XZA87LSW;ZDXJ6SWX;BB4J35VJ;RHHRNKGZ;C1EHZ8PH;AD88JX4T;6XLKJ1BZ;PVJ6XY9D;KWP53B5B;YTJ6A25J;C27XPR8N;531ASKCS;LKKRGJYH;PX8DA5VL',
      statusContent: 'dot',
      hash: 'P7VA89LJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7VA89LJ'
    },
    {
      status: 210,
      sku: 'PM0077912',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306375',
      rtl_batch_array: '6306375',
      name_search: 'EDI62582',
      name: "Narcissus (cyc) 'Jack Snipe'",
      sppl_size_code: '012014BOP10',
      rng_range_identifier: 'BU\u00d812014100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm 14 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 20.704,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GZ346ZZ3;WZJ9C3B4;HWGCSGNH;SLKYSK7L;YX657LYP;Z4TRAK51',
      statusContent: 'dot',
      hash: 'TEJSVCNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEJSVCNV'
    },
    {
      status: 210,
      sku: 'PM0077913',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306376',
      rtl_batch_array: '6306376',
      name_search: 'RUBGEEL',
      name: 'Ranunculus bulb. Geel/ Yellow',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NEH8E8ZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NEH8E8ZZ'
    },
    {
      status: 210,
      sku: 'PM0077914',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306377',
      rtl_batch_array: '6306377',
      name_search: 'RANUNCUL',
      name: 'Ranunculus mix',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 11.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KRDC7CKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRDC7CKE'
    },
    {
      status: 210,
      sku: 'PM0077915',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306378',
      rtl_batch_array: '6306378',
      name_search: 'RABROOD',
      name: 'Ranunculus bulb. Rood/ Red',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C4A6WPC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4A6WPC2'
    },
    {
      status: 210,
      sku: 'PM0077916',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306379',
      rtl_batch_array: '6306379',
      name_search: 'RABWIT',
      name: 'Ranunculus bulb. Wit/ White',
      sppl_size_code: '006007BOP10',
      rng_range_identifier: 'BU\u00d86007100PCS',
      rng_range_description: 'Bulb \u00d8 6 cm 7 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 12.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1SX93GH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SX93GH8'
    },
    {
      status: 210,
      sku: 'PM0074234',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268752',
      rtl_batch_array: '6268752',
      name_search: 'GEACAULI',
      name: 'Gentiana acaulis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DX4GHWN;6JCHABET;HPR7HB57;A8BZ82GH;6WV1RLRX;WRHE2XTG;9SJV28HA;HS2CV66A',
      statusContent: 'dot',
      hash: 'WEGXJLW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEGXJLW7'
    },
    {
      status: 210,
      sku: 'PM0077907',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306370',
      rtl_batch_array: '6306370',
      name_search: 'ANCDCAEN',
      name: "Anemone coronaria 'De Caen'",
      sppl_size_code: '005006BOP10',
      rng_range_identifier: 'BU\u00d85006100PCS',
      rng_range_description: 'Bulb \u00d8 5 cm 6 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 9.978,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8L1Z18J2;C7EE8H56;A24VX345;5Y1T731B;BTPB97VP;JVAL1CPJ;T6HWX5TY;4P5HCZCB;RLGHHHYY;AD2D53SP;KTGR7EAH;CW5GB7EC;562ZK9P5',
      statusContent: 'dot',
      hash: '2VXRT98H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VXRT98H'
    },
    {
      status: 810,
      sku: 'PM0085137',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371199',
      rtl_batch_array: '6371199',
      name_search: 'PAVAROSE',
      name: "Panicum virgatum 'Apache Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SDKXTXXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDKXTXXV'
    },
    {
      status: 210,
      sku: 'PM0077909',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306372',
      rtl_batch_array: '6306372',
      name_search: 'EDI63080',
      name: 'Ipheion uniflorum',
      sppl_size_code: '004+BOP100',
      rng_range_identifier: 'BU\u00d84+100PCS',
      rng_range_description: 'Bulb \u00d8 4 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 7.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDGKRL9K;LPVRA6ZH;HRX4VSTH;SA19KTTJ;2NX51AW4;8DYK1BGG;S3K3VNS6;L7NDSK58;86X3WV1J;HAJ2W5Z6;BD3AE5JV;DP8212EZ;N3GSBDXZ;E7B525YA;SDENSKV1;W4ESEZ48',
      statusContent: 'dot',
      hash: 'VAY7CD9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAY7CD9V'
    },
    {
      status: 810,
      sku: 'PM0078016',
      core_name: 'Plant',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6040563',
      rtl_batch_array: '6040563',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '090STSPC5B2',
      rng_range_identifier: 'SPIR\u00d8025STE090C5',
      rng_range_description: 'Spiral \u00d8 25 cm Stem 90 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 31.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'V391EDLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V391EDLY'
    },
    {
      status: 210,
      sku: 'PM0081332',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339864',
      rtl_batch_array: '6339864',
      name_search: 'NEPNPCAT',
      name: "Nepeta nervosa 'Pink Cat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 184,
      chnn_stock_retail: 184,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H82NZ61;BHLNWD1K;N95AL82L;79N1YLR8;BGXN3T1Y;NW6JWA4R;289Y5H7R;TK1TDY1N;DB3HADYN',
      statusContent: 'dot',
      hash: 'AL6SWY5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AL6SWY5B'
    },
    {
      status: 210,
      sku: 'PM0078018',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350699',
      rtl_batch_array: '6350699',
      name_search: 'MOCSTRAH',
      name: "Molinia caerulea 'Strahlenquelle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8BZV1L3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8BZV1L3'
    },
    {
      status: 810,
      sku: 'PM0064573',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177304',
      rtl_batch_array: '6177304, 6307637',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 29,
      chnn_stock_retail: 349,
      sppl_prcp: 20.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'CY4W38VZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CY4W38VZ'
    },
    {
      status: 810,
      sku: 'PM0057204',
      core_name: 'Plant',
      sppl_ean: '8720664866560',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091293',
      rtl_batch_array: '6091293',
      name_search: 'HEBCANDY',
      name: "Hemerocallis 'Blackberry Candy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5TXVH56B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TXVH56B'
    },
    {
      status: 210,
      sku: 'PM0081333',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363188',
      rtl_batch_array: '6339868, 6363188',
      name_search: 'ORVULGAR',
      name: 'Origanum vulgare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7776,
      chnn_stock_retail: 7853,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '91EJR1AJ;5PBSWE37;D3CTX94L;DCNEPGVY;1W5Z2RGA;WKDV28Y4;4PW94CCD;9LT83JX3;S52G1ZDE;THY267YB;KDP72R53;WSZWZNK3;5WZC152A;56BGEJZC;P2H7LJC1;CVH8XVGE;T4KP6TEW;C8EPDHSK;PYWK57NJ;JTJX8NPX;ZGDDC1SS;1BK3HBSD;134BHXE9;8DG5T3RP;JHBASPXK',
      statusContent: 'dot',
      hash: 'L9V47TAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9V47TAL'
    },
    {
      status: 210,
      sku: 'PM0078019',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346710',
      rtl_batch_array: '6346710',
      name_search: 'FEVGLAUC',
      name: "Festuca valesiaca 'Glaucantha'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BD4BA9S',
      statusContent: 'dot',
      hash: 'RBYY2YP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBYY2YP5'
    },
    {
      status: 210,
      sku: 'PM0078020',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346711',
      rtl_batch_array: '6346711',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 3.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: '1GCHXWLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GCHXWLP'
    },
    {
      status: 210,
      sku: 'PM0081334',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339869',
      rtl_batch_array: '6339869',
      name_search: 'OXMNELSO',
      name: "Oxalis magellanica 'Nelson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N1SJG36W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1SJG36W'
    },
    {
      status: 210,
      sku: 'PM0078021',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346716',
      rtl_batch_array: '6346716',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: '78N3KXBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78N3KXBC'
    },
    {
      status: 210,
      sku: 'PM0071019',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218808',
      rtl_batch_array: '6218808',
      name_search: 'PINNANA',
      name: "Pinus nigra 'Nana'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 503,
      chnn_stock_retail: 503,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9B72VHVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B72VHVB'
    },
    {
      status: 210,
      sku: 'PM0046726',
      core_name: 'Plant',
      sppl_ean: '8720664695214',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875190',
      rtl_batch_array: '5875190',
      name_search: 'IRSAXBY',
      name: "Iris (G) 'Saxby'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B9XAYSW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9XAYSW3'
    },
    {
      status: 210,
      sku: 'PM0081335',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302772',
      rtl_batch_array: '6339872, 6302772',
      name_search: 'LYVULGAR',
      name: 'Lysimachia vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 879,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TVEVBSYX;4P882DTB;S47LNGL4;L22P4RSD;SLNNRYGG',
      statusContent: 'dot',
      hash: 'HYZ1T1NN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYZ1T1NN'
    },
    {
      status: 210,
      sku: 'PM0064588',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6344105',
      rtl_batch_array: '5495524, 6353392, 6344105',
      name_search: 'THROCHEB',
      name: 'Thalictrum rochebrunianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 253,
      chnn_stock_retail: 2174,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHHNBYPX;PZJ1W33A;W5BKA168;LYX8VSYB;L99CXEX6;VRV49ZW8;C6H4KYK6;5C869NHE',
      statusContent: 'dot',
      hash: 'YYP1XE4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYP1XE4Y'
    },
    {
      status: 810,
      sku: 'PM0085138',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371200',
      rtl_batch_array: '6371200',
      name_search: 'PAVBFOUN',
      name: "Panicum virgatum 'Blue Fountain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XAG161ZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAG161ZN'
    },
    {
      status: 910,
      sku: 'PM0078023',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383823',
      rtl_batch_array: '6383823',
      name_search: 'HAINTERM',
      name: 'Hamamelis intermedia',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JT2HVN7C;P8XNA5YS;67R64N8V',
      statusContent: 'dot',
      hash: '4W4AEYN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W4AEYN3'
    },
    {
      status: 210,
      sku: 'PM0058262',
      core_name: 'Plant',
      sppl_ean: '8720664866447',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112493',
      rtl_batch_array: '6112493',
      name_search: 'HERD09',
      name: "Helleborus 'Rd09'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 3.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '488BZXS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '488BZXS3'
    },
    {
      status: 210,
      sku: 'PM0085139',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371201',
      rtl_batch_array: '6371201',
      name_search: 'PAVBGREE',
      name: "Panicum virgatum 'Buffalo Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '94E16SZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94E16SZL'
    },
    {
      status: 210,
      sku: 'PM0058266',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6177963',
      rtl_batch_array: '6196421, 5364153, 6284221, 6161745, 6177963',
      name_search: 'HESRUSSE',
      name: "Hemerocallis 'Sammy Russell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 13860,
      sppl_order_minimum: 3,
      sppl_prcp: 0.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4KNHZP9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KNHZP9W'
    },
    {
      status: 910,
      sku: 'PM0081336',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEISUMME',
      name: "Persicaria 'Indian Summer'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '4LVYWVNG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4LVYWVNG'
    },
    {
      status: 210,
      sku: 'PM0064600',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333294',
      rtl_batch_array: '6199483, 6330053, 6333294',
      name_search: 'PHSTARFI',
      name: "Phlox (P) 'Starfire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2016,
      chnn_stock_retail: 3580,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEKW2ST5',
      statusContent: 'dot',
      hash: 'P76LKG63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P76LKG63'
    },
    {
      status: 810,
      sku: 'PM0081337',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339876',
      rtl_batch_array: '6339876',
      name_search: 'PEMSBROW',
      name: "Persicaria micr. 'Silver Brown'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VT77GD26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VT77GD26'
    },
    {
      status: 210,
      sku: 'PM0081338',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339878',
      rtl_batch_array: '6339878',
      name_search: 'PHLWADMI',
      name: "Phlox (D) 'White Admiral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 586,
      chnn_stock_retail: 586,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C8LZ1VAJ;GCLX88GC',
      statusContent: 'dot',
      hash: 'B7LARDD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7LARDD3'
    },
    {
      status: 210,
      sku: 'PM0081339',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339879',
      rtl_batch_array: '6339879',
      name_search: 'PEBTAFFY',
      name: "Penstemon 'Blueberry Taffy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DZTVV2ZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZTVV2ZR'
    },
    {
      status: 210,
      sku: 'PM0078028',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209225',
      rtl_batch_array: '6221458, 6209225',
      name_search: 'FEGLAUCA',
      name: 'Festuca glauca',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2287,
      chnn_stock_retail: 2378,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSVA34T4;ABP86W12;8EDK3VYW',
      statusContent: 'dot',
      hash: '5D24BB7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5D24BB7A'
    },
    {
      status: 210,
      sku: 'PM0071022',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218811',
      rtl_batch_array: '6218811',
      name_search: 'CEHFASTI',
      name: "Cephalotaxus harringtonii 'Fastigiata'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GTXKZHS9;J1XGR4GH;LW54X38Y',
      statusContent: 'dot',
      hash: 'R4S3GASJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4S3GASJ'
    },
    {
      status: 210,
      sku: 'PM0078029',
      core_name: 'Plant',
      sppl_ean: '8720664876569',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112732',
      rtl_batch_array: '6112732, 6319725',
      name_search: 'PAVWARRI',
      name: "Panicum virgatum 'Warrior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 548,
      chnn_stock_retail: 746,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7R6NGNVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R6NGNVA'
    },
    {
      status: 210,
      sku: 'PM0078031',
      core_name: 'Plant',
      sppl_ean: '8720664697478',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063496',
      rtl_batch_array: '6063496',
      name_search: 'LEFRAINB',
      name: "Leucothoe fontanesiana 'Rainbow'",
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 2.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1H5P3ZSD;CCB5LRLZ;SJS31JLE;SE71TG9R;8LE8AELD;ZEZ3P78W;253XTPC7;48TWELTH;S87WRHT4;6BYV1J6X;K7HNJ6G5;HHP3PN72;2C8EJXR8;6H77HESP;5GX4KEH4;9CSW38ZJ;GJK122WL;HWHWALRR',
      statusContent: 'dot',
      hash: 'G227L12W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G227L12W'
    },
    {
      status: 810,
      sku: 'PM0081340',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339880',
      rtl_batch_array: '6339880',
      name_search: 'PECCPURP',
      name: "Penstemon 'Cha Cha Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GHK4K8V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHK4K8V1'
    },
    {
      status: 210,
      sku: 'PM0046723',
      core_name: 'Plant',
      sppl_ean: '8720664695122',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875187',
      rtl_batch_array: '5875187',
      name_search: 'IRNTRACE',
      name: "Iris (G) 'Natchez Trace'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LH7XWHTC',
      statusContent: 'dot',
      hash: '77NZWBCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77NZWBCZ'
    },
    {
      status: 210,
      sku: 'PM0081341',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339881',
      rtl_batch_array: '6339881',
      name_search: 'PEMENSAR',
      name: 'Penstemon mensarum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EYLVNAK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYLVNAK6'
    },
    {
      status: 910,
      sku: 'PM0081342',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEWTAFFY',
      name: "Penstemon 'Watermelon Taffy'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HZN714PE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HZN714PE'
    },
    {
      status: 210,
      sku: 'PM0081343',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339884',
      rtl_batch_array: '6339884',
      name_search: 'PRGALBA',
      name: "Prunella grandiflora 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6T23RHCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T23RHCW'
    },
    {
      status: 210,
      sku: 'PM0081344',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339885',
      rtl_batch_array: '6339885',
      name_search: 'PRGRUBRA',
      name: "Prunella grandiflora 'Rubra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XDBD8E9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDBD8E9A'
    },
    {
      status: 210,
      sku: 'PM0081345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339886',
      rtl_batch_array: '6339886',
      name_search: 'PRUGRAND',
      name: 'Prunella grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1076,
      chnn_stock_retail: 1076,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X33CT752;KT2BS474;HCK28WA2;JYCVYCEL;TJYB15AK;A58JW5AK;EJ9NAPVY;AX2X4G7L;CNDZ6ZT8;LWR94162;14P61YAZ;G4EHCLCH',
      statusContent: 'dot',
      hash: 'XBBGYBYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBBGYBYY'
    },
    {
      status: 210,
      sku: 'PM0078040',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6220214',
      rtl_batch_array: '6329888, 5866473, 6220214, 6153411',
      name_search: 'CAPCOERU',
      name: "Campanula persicifolia 'Coerulea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17867,
      chnn_stock_retail: 18805,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJ4T3DJD;4GHSBTTK',
      statusContent: 'dot',
      hash: 'W8A3XX87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8A3XX87'
    },
    {
      status: 210,
      sku: 'PM0046753',
      core_name: 'Plant',
      sppl_ean: '8720664691674',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875241',
      rtl_batch_array: '5875241',
      name_search: 'HEKANARI',
      name: "Helenium 'Kanaria'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2R99J37;NZ3ZSREY;8L4951TE;HGRXJYK1;5NJTYTZJ',
      imageBatch: 'YVCGGSRX',
      statusContent: 'dot',
      hash: '526GZCZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '526GZCZG'
    },
    {
      status: 910,
      sku: 'PM0081346',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PULONGIF',
      name: 'Pulmonaria longifolia',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'N4XXRVHY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N4XXRVHY'
    },
    {
      status: 810,
      sku: 'PM0081347',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339889',
      rtl_batch_array: '6339889',
      name_search: 'PHSSSNOW',
      name: 'Phlox (P) Sweet Summer Snow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '26X8EPC4;7X371879;6RBPJWVA',
      statusContent: 'dot',
      hash: 'E3RJ1CRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3RJ1CRS'
    },
    {
      status: 910,
      sku: 'PM0081348',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHMINNEH',
      name: "Phlox 'Minnehaha'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WWRGDCL4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WWRGDCL4'
    },
    {
      status: 210,
      sku: 'PM0081349',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339891',
      rtl_batch_array: '6339891',
      name_search: 'PHASENSA',
      name: 'Phygelius aequalis Sensation',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGTBSGLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGTBSGLS'
    },
    {
      status: 210,
      sku: 'PM0046727',
      core_name: 'Plant',
      sppl_ean: '8720664695252',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875191',
      rtl_batch_array: '5875191',
      name_search: 'IRSMOUND',
      name: "Iris (G) 'Snow Mound'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYGWHYX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYGWHYX1'
    },
    {
      status: 810,
      sku: 'PM0038340',
      core_name: 'Garden',
      sppl_ean: '5413448072570',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '4667631',
      rtl_batch_array: '4667631',
      name_search: 'VIHRHODO',
      name: 'Vivimus Heide & Rhododendron',
      sppl_size_code: '40LTRZAK',
      rng_range_identifier: 'BAG40LTR',
      rng_range_description: 'Bag 40 Liter',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 4.65,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'PL11RDZP',
      statusContent: 'dot',
      hash: 'T1XZB9TX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'T1XZB9TX'
    },
    {
      status: 910,
      sku: 'PM0069137',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212089',
      rtl_batch_array: '6212089',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '080100C35',
      rng_range_identifier: 'H080100C35',
      rng_range_description: 'H80 cm 100 cm C35',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 100.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'KRRZ3SP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRRZ3SP8'
    },
    {
      status: 810,
      sku: 'PM0069136',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212088',
      rtl_batch_array: '6212088',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '070080C35',
      rng_range_identifier: 'H070080C35',
      rng_range_description: 'H70 cm 80 cm C35',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 95.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: '4GB7AVVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GB7AVVW'
    },
    {
      status: 210,
      sku: 'PM0074233',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268743',
      rtl_batch_array: '6268743',
      name_search: 'DIWHITEH',
      name: "Dianthus (PL) 'Whitehills'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 223,
      chnn_stock_retail: 223,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L8NTYDE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8NTYDE9'
    },
    {
      status: 210,
      sku: 'PM0078044',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242166',
      rtl_batch_array: '6242166, 6355369',
      name_search: 'KNCREAMS',
      name: "Kniphofia 'Creamsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 462,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZJ8JDN8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ8JDN8E'
    },
    {
      status: 210,
      sku: 'PM0064614',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196806',
      rtl_batch_array: '6196806, 6345191',
      name_search: 'ASESNOWF',
      name: "Aster ericoides 'Snowflurry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4817,
      chnn_stock_retail: 5694,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9CHN1Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9CHN1Z1'
    },
    {
      status: 810,
      sku: 'PM0078027',
      core_name: 'Plant',
      sppl_ean: '8720353087597',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054458',
      rtl_batch_array: '6054458',
      name_search: 'ASDLACE',
      name: "Astilbe 'Delft Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PKWAJ158',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKWAJ158'
    },
    {
      status: 210,
      sku: 'PM0058178',
      core_name: 'Plant',
      sppl_ean: '8720664861732',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6107073',
      rtl_batch_array: '6107073, 6386116',
      name_search: 'ERVBFORM',
      name: "Erodium variabile 'Bishop's Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 1908,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TSA7LTSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSA7LTSX'
    },
    {
      status: 210,
      sku: 'PM0074236',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268755',
      rtl_batch_array: '6268755',
      name_search: 'GEJJLILA',
      name: "Geranium hybrid 'Jolly Jewel Lilac'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 371,
      chnn_stock_retail: 371,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YNH42ZLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNH42ZLJ'
    },
    {
      status: 210,
      sku: 'PM0074235',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350188',
      rtl_batch_array: '6350188',
      name_search: 'GESBLUE',
      name: 'Geranium Sabani Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TWA5J75X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWA5J75X'
    },
    {
      status: 810,
      sku: 'PM0064572',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301715',
      rtl_batch_array: '6301715',
      name_search: 'MERVARIE',
      name: "Mentha rotundifolia 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RD647SKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD647SKE'
    },
    {
      status: 810,
      sku: 'PM0058268',
      core_name: 'Plant',
      sppl_ean: '8720664867062',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112501',
      rtl_batch_array: '6112501',
      name_search: 'HETLOOK',
      name: "Hemerocallis 'Total Look'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KPAD22J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPAD22J1'
    },
    {
      status: 210,
      sku: 'PM0071023',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218812',
      rtl_batch_array: '6218812',
      name_search: 'CHPTBEAR',
      name: "Chamaecyparis pisifera 'Teddy Bear'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XTTASZ5Z;RCDX5EZN;WV3XZD7G;LVJRDT6S;1AB5C5HH;L6X5H255;5W2J737T;13L6EGEP;8K8A8J36;7XP14EVT',
      statusContent: 'dot',
      hash: 'SS5JZD63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS5JZD63'
    },
    {
      status: 210,
      sku: 'PM0078030',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112855',
      rtl_batch_array: '6112855',
      name_search: 'PHADGART',
      name: "Phalaris arundinacea 'Dwarf Garters'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1K8VTTXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K8VTTXS'
    },
    {
      status: 210,
      sku: 'PM0046770',
      core_name: 'Plant',
      sppl_ean: '8720664851375',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146961',
      rtl_batch_array: '6146961',
      name_search: 'ALMILLEN',
      name: "Allium 'Millennium'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 424,
      chnn_stock_retail: 424,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P8KWJS8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8KWJS8T'
    },
    {
      status: 210,
      sku: 'PM0081350',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339892',
      rtl_batch_array: '6339892',
      name_search: 'PHRLTEAR',
      name: "Phygelius r. 'Lemon Teardrops'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YNTV75J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNTV75J1'
    },
    {
      status: 910,
      sku: 'PM0085140',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAVJDNIG',
      name: "Panicum virgatum 'JS Dark Night'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '6ZEP58VP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6ZEP58VP'
    },
    {
      status: 210,
      sku: 'PM0071018',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254876',
      rtl_batch_array: '6254876',
      name_search: 'AJRCGIAN',
      name: "Ajuga reptans 'Catlin's Giant'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 901,
      chnn_stock_retail: 901,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ERAZC3L;Z6X1ACD1;XSTZ4E72;S4SBLT1R;4LXVY64Z;C5P71B7C;PX3EVE8J;RD7ET1G5',
      statusContent: 'dot',
      hash: 'JH77S7LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH77S7LD'
    },
    {
      status: 210,
      sku: 'PM0085141',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371203',
      rtl_batch_array: '6371203',
      name_search: 'PAVTHUND',
      name: "Panicum virgatum 'Thundercloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SH4TBJTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SH4TBJTB'
    },
    {
      status: 210,
      sku: 'PM0064610',
      core_name: 'Plant',
      sppl_ean: '8720664860070',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6135819',
      rtl_batch_array: '6135819',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S5PENAKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5PENAKS'
    },
    {
      status: 810,
      sku: 'PM0046830',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301724',
      rtl_batch_array: '6301724',
      name_search: 'MOCROTSC',
      name: "Molinia caerulea 'Rotschopf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X8CW3XS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8CW3XS3'
    },
    {
      status: 210,
      sku: 'PM0078032',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295685',
      rtl_batch_array: '6164312, 6295685',
      name_search: 'HOLANCIF',
      name: 'Hosta lancifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 766,
      chnn_stock_retail: 822,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '66BC9P9P',
      statusContent: 'dot',
      hash: 'EZXEW3DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZXEW3DB'
    },
    {
      status: 910,
      sku: 'PM0078035',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRLAWILL',
      name: "Iris (G) 'Lent A. Williamson'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'JDL5ZP7V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JDL5ZP7V'
    },
    {
      status: 210,
      sku: 'PM0085142',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371204',
      rtl_batch_array: '6371204',
      name_search: 'PEOTTAIL',
      name: "Pennisetum orientale 'Tall Tails'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 743,
      chnn_stock_retail: 743,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P1XJEN79;EGJWDCJD',
      statusContent: 'dot',
      hash: 'RLLE969H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLLE969H'
    },
    {
      status: 210,
      sku: 'PM0057164',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6353154',
      rtl_batch_array: '6353154, 6193662, 6133499',
      name_search: 'PHAPICTA',
      name: "Phalaris arundinacea 'Picta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 745,
      chnn_stock_retail: 1995,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GNT2XSAG;DPG99TWH;55E82H1K;E8A62ZYG;BDDGPBA8;R69NRPA9;YVB8AKLY;9BWLCVYJ;T35C5T18;SDC67YPB;E55CA6LE',
      statusContent: 'dot',
      hash: 'CAR7ZPYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAR7ZPYX'
    },
    {
      status: 210,
      sku: 'PM0085143',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371205',
      rtl_batch_array: '6371205',
      name_search: 'PHAAUGEN',
      name: "Phlox ampl. 'Augenstern'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4G23H5WX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4G23H5WX'
    },
    {
      status: 210,
      sku: 'PM0085144',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371206',
      rtl_batch_array: '6371206',
      name_search: 'PHAWCLOU',
      name: "Phlox amplifolia 'White Clouds'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11V6LCTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11V6LCTG'
    },
    {
      status: 210,
      sku: 'PM0085145',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371207',
      rtl_batch_array: '6371207',
      name_search: 'PHPANICU',
      name: 'Phlox paniculata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 222,
      chnn_stock_retail: 222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YW7VDD7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW7VDD7R'
    },
    {
      status: 210,
      sku: 'PM0085146',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371208',
      rtl_batch_array: '6371208',
      name_search: 'POVRUBRU',
      name: "Polygonatum verticillatum 'Rubrum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 571,
      chnn_stock_retail: 571,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N523X4CB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N523X4CB'
    },
    {
      status: 910,
      sku: 'PM0085147',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PORUPEST',
      name: 'Potentilla rupestris',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'RHV8TE9L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RHV8TE9L'
    },
    {
      status: 210,
      sku: 'PM0085148',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371210',
      rtl_batch_array: '6371210',
      name_search: 'PUAMVARI',
      name: "Pulmonaria angustifolia 'Mawson's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CWLAHERD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWLAHERD'
    },
    {
      status: 810,
      sku: 'PM0085149',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371211',
      rtl_batch_array: '6371211',
      name_search: 'PURDAVID',
      name: "Pulmonaria 'Roy Davidson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SP7SVWP9;9HH6JNHW',
      statusContent: 'dot',
      hash: 'TZVZENWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZVZENWT'
    },
    {
      status: 210,
      sku: 'PM0085150',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371212',
      rtl_batch_array: '6371212',
      name_search: 'PUBMIST',
      name: "Pulmonaria 'Blue Mist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DG3AJX6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG3AJX6C'
    },
    {
      status: 210,
      sku: 'PM0071024',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218813',
      rtl_batch_array: '6218813',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 1154,
      chnn_stock_retail: 1154,
      sppl_prcp: 7.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: '7NAPVWA5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NAPVWA5'
    },
    {
      status: 210,
      sku: 'PM0085151',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371214',
      rtl_batch_array: '6371214',
      name_search: 'SAMPLIPS',
      name: 'Salvia microphylla Pink Lips',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NACPNTE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NACPNTE9'
    },
    {
      status: 210,
      sku: 'PM0085152',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371216',
      rtl_batch_array: '6371216',
      name_search: 'SARLFIZZ',
      name: "Santolina rosmarinifolia 'Lemon Fizz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EV17Y1TZ;5TLWH7H5;J18SV5SE;N1P2YXT3',
      statusContent: 'dot',
      hash: 'A715ZSTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A715ZSTP'
    },
    {
      status: 910,
      sku: 'PM0047346',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SEULTRAT',
      name: "Sempervivum 'Ultraturmaline'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '6N5KCGCW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6N5KCGCW'
    },
    {
      status: 210,
      sku: 'PM0063457',
      core_name: 'Plant',
      sppl_ean: '8720664881143',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6141834',
      rtl_batch_array: '6141834',
      name_search: 'PITGSTAR',
      name: "Pittosporum tenuifolium 'Gold Star'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 465,
      chnn_stock_retail: 465,
      sppl_prcp: 5.865,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JREBG5XN;8X7LRC8Z;6PLDABRE;WY1ANK5H',
      statusContent: 'dot',
      hash: 'K3HAVWD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3HAVWD2'
    },
    {
      status: 210,
      sku: 'PM0064601',
      core_name: 'Plant',
      sppl_ean: '8720664643178',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5548048',
      rtl_batch_array: '5548048',
      name_search: 'THBSTOCK',
      name: "Thalictrum 'Black Stockings'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4393,
      chnn_stock_retail: 4393,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1WPTJ919',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WPTJ919'
    },
    {
      status: 210,
      sku: 'PM0064609',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196858',
      rtl_batch_array: '6196858, 6353114',
      name_search: 'PLGAWHIT',
      name: "Platycodon grandiflorus 'Astra White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2689,
      chnn_stock_retail: 3114,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SWHJ3LZ;9N898127;CHXVAR8W',
      statusContent: 'dot',
      hash: 'HZPXL27A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZPXL27A'
    },
    {
      status: 210,
      sku: 'PM0046728',
      core_name: 'Plant',
      sppl_ean: '8720664695269',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875192',
      rtl_batch_array: '5875192',
      name_search: 'IRSMADON',
      name: "Iris (G) 'Springtime Madonna'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G3DRC7RG;GT1P1NHS;45NSWNR5;W9C4YT63',
      statusContent: 'dot',
      hash: '4GGRBSLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GGRBSLX'
    },
    {
      status: 210,
      sku: 'PM0068725',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350034',
      rtl_batch_array: '6350034',
      name_search: 'ECPCLIME',
      name: "Echinacea purpurea 'Coconut Lime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 684,
      chnn_stock_retail: 684,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ3EA82A;1YV3ENBT;82X6EKWJ',
      statusContent: 'dot',
      hash: 'X8K71DH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8K71DH1'
    },
    {
      status: 210,
      sku: 'PM0005398',
      core_name: 'Plant',
      sppl_ean: '8720349446919',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4791294',
      rtl_batch_array: '4791294',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 488,
      chnn_stock_retail: 488,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      imageBatch: 'BB58YVNR',
      statusContent: 'dot',
      hash: '5K83HTXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K83HTXS'
    },
    {
      status: 210,
      sku: 'PM0070031',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205393',
      rtl_batch_array: '6205393',
      name_search: 'GENTTALI',
      name: "Geranium n. 'Tony's Talisman'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YX4LD1EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YX4LD1EV'
    },
    {
      status: 210,
      sku: 'PM0063565',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244220',
      rtl_batch_array: '6311212, 6244220',
      name_search: 'RUTRICOL',
      name: 'Rubus tricolor',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1510,
      chnn_stock_retail: 4876,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9Z754P4K',
      statusContent: 'dot',
      hash: 'HBHJB6ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBHJB6ZH'
    },
    {
      status: 910,
      sku: 'PM0064574',
      core_name: 'Plant',
      sppl_ean: '8720626396241',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '5476617',
      rtl_batch_array: '5476617',
      name_search: 'BUWBALL',
      name: "Buddleja 'White Ball'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 5.327,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B1573HE;YH81KX32;5TL2J7GY;9RKLX3GN',
      statusContent: 'dot',
      hash: '6BAB9K34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BAB9K34'
    },
    {
      status: 910,
      sku: 'PM0060141',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307743',
      rtl_batch_array: '6307743',
      name_search: 'PIHCGEM',
      name: "Pinus heldreichii 'Compact Gem'",
      sppl_size_code: '080100C35',
      rng_range_identifier: 'H080100C35',
      rng_range_description: 'H80 cm 100 cm C35',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 38.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5Y14BN6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Y14BN6S'
    },
    {
      status: 210,
      sku: 'PM0046724',
      core_name: 'Plant',
      sppl_ean: '8720664695184',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875188',
      rtl_batch_array: '5875188',
      name_search: 'IRRCLOUD',
      name: "Iris (G) 'Rippling Clouds'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DPBXW547',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPBXW547'
    },
    {
      status: 810,
      sku: 'PM0064589',
      core_name: 'Plant',
      sppl_ean: '8720349430871',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495553',
      rtl_batch_array: '6220944, 5495553, 6319769',
      name_search: 'VILABRAD',
      name: 'Viola labradorica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 89,
      chnn_stock_retail: 1339,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5A3W8SEZ',
      statusContent: 'dot',
      hash: 'XETXJ67D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XETXJ67D'
    },
    {
      status: 210,
      sku: 'PM0081351',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339895',
      rtl_batch_array: '6339895',
      name_search: 'PLMPURPU',
      name: "Plantago major 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VXVK9GJK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXVK9GJK'
    },
    {
      status: 210,
      sku: 'PM0077917',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306380',
      rtl_batch_array: '6306380',
      name_search: 'EDI63966',
      name: "Scilla siberica 'Alba'",
      sppl_size_code: '007008BOP10',
      rng_range_identifier: 'BU\u00d87008100PCS',
      rng_range_description: 'Bulb \u00d8 7 cm 8 cm 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 8.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '883H8SJT;X2KA1L7X',
      statusContent: 'dot',
      hash: 'JAPH5JBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAPH5JBT'
    },
    {
      status: 210,
      sku: 'PM0081352',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339896',
      rtl_batch_array: '6339896',
      name_search: 'PLCILIAT',
      name: 'Plectranthus ciliatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NYKJS5HB',
      statusContent: 'dot',
      hash: 'GPVDLCK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPVDLCK4'
    },
    {
      status: 210,
      sku: 'PM0081353',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339897',
      rtl_batch_array: '6339897',
      name_search: 'PLMLAVEN',
      name: 'Plectranthus Mona Lavender',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WA1P16PR;WZPKXD95;CLXWPAJB;9KEDBXD6;P8EGBEVR;ARXPZ43P;AJ3GHKAN;59YL84VS;GES3HDSB;BY44HGA8',
      statusContent: 'dot',
      hash: '6ESV2RN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ESV2RN2'
    },
    {
      status: 210,
      sku: 'PM0077918',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306381',
      rtl_batch_array: '6306381',
      name_search: 'EDI60147',
      name: "Tulipa (sla) 'Big Smile'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 14.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3LRLVG9;8RWJDVA2;WY133LV2;PP1N4P4E;931CW6J3;VRLYWDN1;8H1GJXRL;4RS56A1E',
      statusContent: 'dot',
      hash: '8RPBK6K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RPBK6K5'
    },
    {
      status: 910,
      sku: 'PM0060142',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307758',
      rtl_batch_array: '6307758',
      name_search: 'PINPYRAM',
      name: "Pinus nigra 'Pyramidalis'",
      sppl_size_code: '080100C35',
      rng_range_identifier: 'H080100C35',
      rng_range_description: 'H80 cm 100 cm C35',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 48.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9KGHD3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9KGHD3G'
    },
    {
      status: 210,
      sku: 'PM0077919',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306382',
      rtl_batch_array: '6306382',
      name_search: 'EDI73872',
      name: "Tulipa (trh) 'Calgary Flames'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 19.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N11S78P3;AVDDTBEG;YV83YBW2;Y474KVBG;1NHW9BE5',
      statusContent: 'dot',
      hash: 'Y4PSCS4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4PSCS4T'
    },
    {
      status: 210,
      sku: 'PM0064629',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196847',
      rtl_batch_array: '6164652, 6196847, 6193649',
      name_search: 'MAREPTAN',
      name: 'Mazus reptans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3358,
      chnn_stock_retail: 4593,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KYPEH7G7;966HKP7Y;W38Y6C11;YPRP8V1Y;CYTDNGNL;16TE9RYA;RBSA2JY5;ZJPPTKYG',
      statusContent: 'dot',
      hash: 'S7NBZH7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7NBZH7Z'
    },
    {
      status: 210,
      sku: 'PM0077920',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306383',
      rtl_batch_array: '6306383',
      name_search: 'EDI67690',
      name: "Tulipa (dar) 'Design Impression'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 15.305,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V7BVGZSD;XD4YC196;NSANZ92N;7EJ4D3AH;W9SL3NLL;YNGGS3KV;677RJC8E;NN852CRE;SY7S97DA;EA58GCTL;ZRPXVA39;XPBWEP6B;5N17WHRC;LYN8ZS6T',
      statusContent: 'dot',
      hash: 'SHVLVET6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHVLVET6'
    },
    {
      status: 210,
      sku: 'PM0077921',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306384',
      rtl_batch_array: '6306384',
      name_search: 'EDI60341',
      name: "Tulipa (vir) 'Doll's Minuet'",
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 13.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AR3CZHJY;C7G6AH6D;AGAW95KD;53PE7T57;9XCCCBSY;PBD3K7B2;CY8SC8YJ',
      statusContent: 'dot',
      hash: 'JCYKSJ3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCYKSJ3Z'
    },
    {
      status: 210,
      sku: 'PM0077922',
      core_name: 'Plant',
      btch_manufacturer: 14143305,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306385',
      rtl_batch_array: '6306385',
      name_search: 'TUESPARA',
      name: 'Tulipa Esparanto',
      sppl_size_code: '012+BOP100',
      rng_range_identifier: 'BU\u00d812+100PCS',
      rng_range_description: 'Bulb \u00d8 12 cm + 100 Pieces',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_prcp: 20.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z29SRPC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z29SRPC8'
    },
    {
      status: 210,
      sku: 'PM0032276',
      core_name: 'Plant',
      sppl_ean: '8720353019963',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054313',
      rtl_batch_array: '6054313',
      name_search: 'ALCAESIU',
      name: 'Allium caesium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RLBZ7VKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLBZ7VKP'
    },
    {
      status: 210,
      sku: 'PM0074242',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268762',
      rtl_batch_array: '6268762',
      name_search: 'HICCRUSH',
      name: "Hibiscus 'Cranberry Crush'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPH9TSZ5;SZHP37SJ;BW4GW98C',
      statusContent: 'dot',
      hash: 'VKD3GEP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKD3GEP7'
    },
    {
      status: 210,
      sku: 'PM0081354',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349087',
      rtl_batch_array: '6349087',
      name_search: 'POCALBUM',
      name: "Polemonium caeruleum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P3RD2TPW;5W6TVZNB',
      statusContent: 'dot',
      hash: 'KKCA8T3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKCA8T3V'
    },
    {
      status: 910,
      sku: 'PM0078046',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACERGMD',
      name: 'Acer Gemengd',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      imageCore: '5VK5YW35',
      statusContent: 'dot',
      hash: 'GX4E8YXA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GX4E8YXA'
    },
    {
      status: 210,
      sku: 'PM0066502',
      core_name: 'Plant',
      sppl_ean: '8720353008134',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5903750',
      rtl_batch_array: '5903750',
      name_search: 'COSSKOGH',
      name: "Cotoneaster suecicus 'Skogholm'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_order_minimum: 3,
      sppl_prcp: 0.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BECWJ1C1;771X3AR6;AZXP97EK;59KPW61B;K3XE5X71;JRAZNJ4S;866VZR8D;57JY8B2H;9HT2131W;TNB3WRSA;TG15Y59H;6WSAWK8Z',
      statusContent: 'dot',
      hash: '694LXB9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '694LXB9L'
    },
    {
      status: 910,
      sku: 'PM0078047',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '5XXGN1XL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5XXGN1XL'
    },
    {
      status: 210,
      sku: 'PM0057241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350482',
      rtl_batch_array: '6369746, 6350482',
      name_search: 'HESTEA',
      name: "Heucherella 'Sweet Tea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 833,
      chnn_stock_retail: 1445,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '724A7JPP',
      statusContent: 'dot',
      hash: '59XS4BHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59XS4BHE'
    },
    {
      status: 210,
      sku: 'PM0071025',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6218815',
      rtl_batch_array: '6218815',
      name_search: 'PIDLGLOW',
      name: "Pinus densiflora 'Low Glow'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 937,
      chnn_stock_retail: 937,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L6N2WNX8',
      statusContent: 'dot',
      hash: 'KPETD215',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPETD215'
    },
    {
      status: 910,
      sku: 'PM0046842',
      core_name: 'Garden',
      sppl_ean: '5413448139358',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '5915106',
      rtl_batch_array: '5915106',
      name_search: 'DCVMGEKR',
      name: 'DCM Vloeibare mestst. Groente en Kruiden',
      sppl_size_code: '04LTRFLES',
      rng_range_identifier: 'BOT0.4LTR',
      rng_range_description: 'Bottle 0.4 Liter',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 3.5,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '63CPYVD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '63CPYVD7'
    },
    {
      status: 910,
      sku: 'PM0046844',
      core_name: 'Plant',
      sppl_ean: '8720664681927',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '5915108',
      rtl_batch_array: '5915108',
      name_search: 'BLMLIEVE',
      name: 'Bloemenmengsel Lieveheersbeestjes',
      sppl_size_code: 'ZADOOS520G',
      rng_range_identifier: 'BOX520GR',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 6.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H598KC4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H598KC4S'
    },
    {
      status: 910,
      sku: 'PM0046845',
      core_name: 'Plant',
      sppl_ean: '8720664681934',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '5915109',
      rtl_batch_array: '5915109',
      name_search: 'BLMNINSE',
      name: 'Bloemenmengsel Nuttige Insecten',
      sppl_size_code: 'ZADOOS520G',
      rng_range_identifier: 'BOX520GR',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 6.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LNEHJJD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNEHJJD5'
    },
    {
      status: 910,
      sku: 'PM0046843',
      core_name: 'Plant',
      sppl_ean: '8720664681910',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '5915107',
      rtl_batch_array: '5915107',
      name_search: 'BLMBIJEN',
      name: 'Bloemenmengsel Bestuivers',
      sppl_size_code: 'ZADOOS520G',
      rng_range_identifier: 'BOX520GR',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 6.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W5EDR8SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5EDR8SY'
    },
    {
      status: 210,
      sku: 'PM0046914',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329933',
      rtl_batch_array: '6329933',
      name_search: 'GALFROSY',
      name: 'Gaura lindheimeri Freefolk Rosy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L83E26R8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L83E26R8'
    },
    {
      status: 210,
      sku: 'PM0066497',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269600',
      rtl_batch_array: '6269600',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.36,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '1KJP1Y61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KJP1Y61'
    },
    {
      status: 210,
      sku: 'PM0068732',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208413',
      rtl_batch_array: '6208413, 6339817',
      name_search: 'GALELURR',
      name: "Gaura lindheimeri 'Elurra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6160,
      chnn_stock_retail: 7360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8HLATLRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HLATLRH'
    },
    {
      status: 210,
      sku: 'PM0068734',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350189',
      rtl_batch_array: '6350189',
      name_search: 'GEAMAYES',
      name: "Geranium 'Alan Mayes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9JHRTEDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JHRTEDW'
    },
    {
      status: 210,
      sku: 'PM0068736',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301595',
      rtl_batch_array: '6207979, 6301595',
      name_search: 'GEJOY',
      name: "Geranium 'Joy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 282,
      chnn_stock_retail: 632,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6S7GSB28',
      statusContent: 'dot',
      hash: 'SXCX78AC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXCX78AC'
    },
    {
      status: 910,
      sku: 'PM0068737',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEMOLYMP',
      name: "Geranium macrorrhizum 'Olympos'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'K8RHKNPE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K8RHKNPE'
    },
    {
      status: 210,
      sku: 'PM0066491',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244077',
      rtl_batch_array: '6244077',
      name_search: 'HYPPDIAM',
      name: "Hydrangea paniculata 'Pink Diamond'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WWBLZD3E;7CCBP3NH;68STTESS',
      statusContent: 'dot',
      hash: 'SXX51957',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXX51957'
    },
    {
      status: 210,
      sku: 'PM0066505',
      core_name: 'Plant',
      sppl_ean: '8720626386389',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420745',
      rtl_batch_array: '5420745',
      name_search: 'AKQUINAT',
      name: 'Akebia quinata',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 799,
      chnn_stock_retail: 799,
      sppl_order_minimum: 3,
      sppl_prcp: 3.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YA8H49S7;1PD4YN1L;XR8L16NA;L23SYYRZ;XPSYNY1T;WVH7DEDD;1RGDRZHP;LKYER7P7;6SV47663;B1V71WA5;84ZGCLTK;GPRZ1B8P;TRA52T42;996S9XYN',
      statusContent: 'dot',
      hash: 'PPXGYRKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPXGYRKN'
    },
    {
      status: 910,
      sku: 'PM0081355',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POATROSA',
      name: 'Potentilla atrosanguinea',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '82TBDZND',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '82TBDZND'
    },
    {
      status: 210,
      sku: 'PM0068739',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207988',
      rtl_batch_array: '6229816, 6207988',
      name_search: 'GEPRAVEN',
      name: "Geranium phaeum 'Raven'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 326,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X4LA1JT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4LA1JT2'
    },
    {
      status: 210,
      sku: 'PM0057209',
      core_name: 'Plant',
      sppl_ean: '8720664866706',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091302',
      rtl_batch_array: '6091302',
      name_search: 'HEHDELIG',
      name: "Hemerocallis 'Holiday Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHZYRC6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHZYRC6L'
    },
    {
      status: 910,
      sku: 'PM0046871',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIBDMAGI',
      name: 'Dianthus b. Dash Magican',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'SASEB692;4HYVYWAX',
      statusContent: 'dot',
      hash: 'P5DRENKJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P5DRENKJ'
    },
    {
      status: 210,
      sku: 'PM0068281',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363214',
      rtl_batch_array: '6221377, 6301892, 6363214',
      name_search: 'TROEUROP',
      name: 'Trollius europaeus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2784,
      chnn_stock_retail: 3215,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VDRTXCAC;DCBTHPSZ;8RAJNPEC;B5XB31TH;VZ9LHD6Z;885PRTNA;69WZCS6R;DPS9DY87;9CZH2SXR;5JVAEG71',
      statusContent: 'dot',
      hash: 'C9AJA8VG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9AJA8VG'
    },
    {
      status: 210,
      sku: 'PM0081356',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339904',
      rtl_batch_array: '6339904',
      name_search: 'SANSMPIN',
      name: 'Salvia nemorosa Sensation Medium Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TYPG4D2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYPG4D2D'
    },
    {
      status: 210,
      sku: 'PM0067192',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177912',
      rtl_batch_array: '6177912',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1296,
      chnn_stock_retail: 1296,
      sppl_prcp: 6.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'BNP3HRGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNP3HRGS'
    },
    {
      status: 210,
      sku: 'PM0045892',
      core_name: 'Plant',
      sppl_ean: '8720353029122',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6034339',
      rtl_batch_array: '6136344, 6034339',
      name_search: 'GESAVIEM',
      name: "Geranium sanguineum 'Aviemore'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 917,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TNZP1SC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNZP1SC1'
    },
    {
      status: 210,
      sku: 'PM0057255',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329974',
      rtl_batch_array: '6329974',
      name_search: 'IRSETOSA',
      name: 'Iris setosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JE3B6HWW',
      statusContent: 'dot',
      hash: '74SY1V6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74SY1V6T'
    },
    {
      status: 210,
      sku: 'PM0081357',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319738',
      rtl_batch_array: '6339905, 6319738',
      name_search: 'SANSMWHI',
      name: 'Salvia nem. Sensation Medium White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 347,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZPVTE8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZPVTE8L'
    },
    {
      status: 210,
      sku: 'PM0081358',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339906',
      rtl_batch_array: '6339906',
      name_search: 'SASSEREN',
      name: "Salvia sylvestris 'Serenade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TVCDVL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TVCDVL9'
    },
    {
      status: 810,
      sku: 'PM0057232',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301649',
      rtl_batch_array: '6301649',
      name_search: 'HERPEARL',
      name: "Heuchera 'Red Pearls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WYC62ANK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYC62ANK'
    },
    {
      status: 810,
      sku: 'PM0057258',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350563',
      rtl_batch_array: '6350563, 6217185',
      name_search: 'IRMRIDGE',
      name: "Iris (G) 'Mission Ridge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 130,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TRTC656Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRTC656Y'
    },
    {
      status: 210,
      sku: 'PM0045884',
      core_name: 'Plant',
      sppl_ean: '8720626301641',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034331',
      rtl_batch_array: '6034331',
      name_search: 'GEPPALBU',
      name: "Geranium pratense 'Plenum Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZ1LCDTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ1LCDTN'
    },
    {
      status: 210,
      sku: 'PM0081359',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339907',
      rtl_batch_array: '6339907',
      name_search: 'SAUASKIE',
      name: "Salvia uliginosa 'African Skies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8YN2DRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8YN2DRW'
    },
    {
      status: 210,
      sku: 'PM0081360',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339908',
      rtl_batch_array: '6339908',
      name_search: 'SAUBAZUL',
      name: "Salvia uliginosa 'Ballon Azul'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WJ7TR2YH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJ7TR2YH'
    },
    {
      status: 210,
      sku: 'PM0066500',
      core_name: 'Plant',
      sppl_ean: '8720664811492',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871966',
      rtl_batch_array: '5871966',
      name_search: 'VESNBLAU',
      name: "Veronica spicata 'Nana Blauteppich'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 701,
      chnn_stock_retail: 701,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RV79VV6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV79VV6R'
    },
    {
      status: 210,
      sku: 'PM0046903',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220926',
      rtl_batch_array: '6220926',
      name_search: 'SESHSTUF',
      name: "Sedum spectabile 'Hot Stuff'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TVSDHB7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVSDHB7G'
    },
    {
      status: 210,
      sku: 'PM0070033',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284508',
      rtl_batch_array: '6284508, 6252438',
      name_search: 'AGBADDER',
      name: "Agastache 'Black Adder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 932,
      chnn_stock_retail: 2023,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '951ZVWNH;67D4KK2D;CP4DB3EZ',
      statusContent: 'dot',
      hash: 'SN83JWE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN83JWE2'
    },
    {
      status: 910,
      sku: 'PM0081361',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAVSTORC',
      name: "Salvia verticillata 'Smouldering Torches'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'JLWG6CCY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JLWG6CCY'
    },
    {
      status: 910,
      sku: 'PM0081362',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SABLACKT',
      name: "Sanguisorba 'Blackthorn'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BK2XBVNH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BK2XBVNH'
    },
    {
      status: 910,
      sku: 'PM0081363',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAORTHUN',
      name: "Sanguisorba officinalis 'Red Thunder'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '7RBBBS3V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7RBBBS3V'
    },
    {
      status: 810,
      sku: 'PM0046935',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307812',
      rtl_batch_array: '6307812',
      name_search: 'FAGENF',
      name: "Fargesia 'Genf'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NSTZ2CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NSTZ2CX'
    },
    {
      status: 210,
      sku: 'PM0063478',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308671',
      rtl_batch_array: '6217226, 6386204, 6308671',
      name_search: 'TISSPICE',
      name: "Tiarella 'Sugar and Spice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 628,
      chnn_stock_retail: 951,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6XW29GYE;C9GKKPSJ;TDSDGXHS;WA3WGBSK;PS62XW75;BLVBR232;YXTN61TH;2GJ1AHGT',
      statusContent: 'dot',
      hash: 'REP731YN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REP731YN'
    },
    {
      status: 910,
      sku: 'PM0046910',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALHAIR',
      name: "Allium hybrid 'Hair'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'R8N7J69K;9VSK9RDN',
      statusContent: 'dot',
      hash: 'GHBHV493',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GHBHV493'
    },
    {
      status: 210,
      sku: 'PM0081364',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339914',
      rtl_batch_array: '6339914',
      name_search: 'SAORROLL',
      name: "Sanguisorba o. 'Rock and Roll'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W3WE12W8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3WE12W8'
    },
    {
      status: 210,
      sku: 'PM0038712',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276613',
      rtl_batch_array: '6276613, 6385455',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 1994,
      chnn_stock_retail: 2144,
      sppl_prcp: 8.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '6YKJTEV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YKJTEV6'
    },
    {
      status: 210,
      sku: 'PM0081365',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339918',
      rtl_batch_array: '6339918',
      name_search: 'ROOALBIF',
      name: "Rosmarinus officinalis 'Albiflorus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 146,
      chnn_stock_retail: 146,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GV8X55Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GV8X55Y8'
    },
    {
      status: 210,
      sku: 'PM0081366',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339919',
      rtl_batch_array: '6339919',
      name_search: 'ROOBLAUL',
      name: "Rosmarinus off. 'Blaulippe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YBDZHEX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBDZHEX6'
    },
    {
      status: 210,
      sku: 'PM0081367',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339920',
      rtl_batch_array: '6339920',
      name_search: 'ROOBWINT',
      name: "Rosmarinus officinalis 'Blue Winter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJXS5VG6;G6G882P7;KVR5TXHZ;WNKVEW2X;YG67C4P9',
      statusContent: 'dot',
      hash: 'G1N3SJD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1N3SJD2'
    },
    {
      status: 210,
      sku: 'PM0081368',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339921',
      rtl_batch_array: '6339921',
      name_search: 'ROOBOULE',
      name: "Rosmarinus officinalis 'Boule'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HANH9HYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HANH9HYT'
    },
    {
      status: 210,
      sku: 'PM0081369',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339922',
      rtl_batch_array: '6339922',
      name_search: 'ROOCINGR',
      name: "Rosmarinus off. 'Collinwood Ingram'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6D17VR7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6D17VR7T'
    },
    {
      status: 210,
      sku: 'PM0081370',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339923',
      rtl_batch_array: '6339923',
      name_search: 'ROOMPINK',
      name: "Rosmarinus officinalis 'Majorca Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WVTPKE9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVTPKE9H'
    },
    {
      status: 210,
      sku: 'PM0085153',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371219',
      rtl_batch_array: '6371219',
      name_search: 'SCMTUBER',
      name: "Scilla mischts. 'Tubergeniana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y65NCS55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y65NCS55'
    },
    {
      status: 910,
      sku: 'PM0085154',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SIPCARNI',
      name: "Silphium perf. 'Carnica'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'JVSVCYHL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JVSVCYHL'
    },
    {
      status: 210,
      sku: 'PM0085155',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371222',
      rtl_batch_array: '6371222',
      name_search: 'THDDECOR',
      name: 'Thalictrum delavayi decorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CTJYTJ7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTJYTJ7V'
    },
    {
      status: 210,
      sku: 'PM0085156',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371223',
      rtl_batch_array: '6371223',
      name_search: 'THVSPOSI',
      name: "Thymus vulgaris 'Silver Posie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKPCN1AH;CL6PAH6T;KJRA1VGD;YEKDJBRW;7WV7TGGN;RD5JXGK3;CGBT4PJD;YBV7GLJA',
      statusContent: 'dot',
      hash: 'HX451WNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX451WNW'
    },
    {
      status: 210,
      sku: 'PM0058048',
      core_name: 'Plant',
      sppl_ean: '8720664865426',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084949',
      rtl_batch_array: '6084949, 6112380, 6370363',
      name_search: 'GYCERAST',
      name: 'Gypsophila cerastioides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9571,
      chnn_stock_retail: 10102,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWKB6DSE;KZPT6TLB;EDW7CR2R;75KAT99P;6WRACASL;Y7ZWZR82;L9YG6L4R;T23ERPV7;T6LSZ8DB;WJ1NSD6B;5CWE913P;8B5V9SSG;CD318B8L;SDD7XDBW;6WTHLAT2;171RN2XB',
      statusContent: 'dot',
      hash: 'ZAE83WZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAE83WZ5'
    },
    {
      status: 210,
      sku: 'PM0085157',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371224',
      rtl_batch_array: '6371224',
      name_search: 'VELFCHOI',
      name: 'Veronica longif. First Choice',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRTH1C9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRTH1C9J'
    },
    {
      status: 210,
      sku: 'PM0066490',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6238817',
      rtl_batch_array: '6350359, 6238817',
      name_search: 'HEMIDDEN',
      name: 'Hemerocallis middendorffii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 810,
      chnn_stock_retail: 1481,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGYGSJCR',
      statusContent: 'dot',
      hash: 'S7DVR8BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7DVR8BW'
    },
    {
      status: 810,
      sku: 'PM0066499',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091297',
      rtl_batch_array: '6091297',
      name_search: 'HEMCCHEE',
      name: "Hemerocallis 'Cherry Cheeks'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PD4AEJDB;496PEBR5;ZD2PG9ST',
      statusContent: 'dot',
      hash: 'V41WJEWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V41WJEWG'
    },
    {
      status: 210,
      sku: 'PM0000715',
      core_name: 'Plant',
      sppl_ean: '8720664849808',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6090575',
      rtl_batch_array: '6034168, 6176232, 5364189, 6090575',
      name_search: 'ACMKUPFE',
      name: "Acaena microphylla 'Kupferteppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2431,
      chnn_stock_retail: 5888,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'THVPBN12;Y1PPN7W5;6EB11VWY;T3554NA2;75T1573P;GTW66P4R',
      statusContent: 'dot',
      hash: 'Z275KXJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z275KXJZ'
    },
    {
      status: 210,
      sku: 'PM0064624',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350712',
      rtl_batch_array: '6350712',
      name_search: 'NEPARNAS',
      name: 'Nepeta parnassica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJC3D61N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJC3D61N'
    },
    {
      status: 210,
      sku: 'PM0038375',
      core_name: 'Plant',
      sppl_ean: '8720626336261',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '5417517',
      rtl_batch_array: '5417517',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 5.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      imageBatch: 'WPP4HSYY',
      statusContent: 'dot',
      hash: '787YEN42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '787YEN42'
    },
    {
      status: 210,
      sku: 'PM0046932',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307557',
      rtl_batch_array: '6307557',
      name_search: 'MARICKI',
      name: "Magnolia 'Ricki'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 11.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CP52LB7;4RPRR9Z6;831RHC8G;T2J9ENW2',
      statusContent: 'dot',
      hash: 'DGYGYSWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGYGYSWW'
    },
    {
      status: 210,
      sku: 'PM0067187',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244095',
      rtl_batch_array: '6244095, 6281220',
      name_search: 'HYPSFRAI',
      name: 'Hydrangea paniculata Sundae Fraise',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 473,
      chnn_stock_retail: 523,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JE1RX5H2;TVJKSH5R;LBKPNDD1;ALZHHNXR;YCCVESHH;142DDV9G;87AGDKC1;NH16J9WA;1YJWB1NJ;KE8BAA3N;9HBXW791;HRNKJRGK;HNWVSRL9;EA7XZXYC;AH96E5GW;1X2TCPAL;8R2Z9NZA;PRLB2BNY',
      statusContent: 'dot',
      hash: 'T1EZ9HRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1EZ9HRR'
    },
    {
      status: 210,
      sku: 'PM0067193',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177913',
      rtl_batch_array: '6177913',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'XP3RVHXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP3RVHXB'
    },
    {
      status: 210,
      sku: 'PM0074237',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268757',
      rtl_batch_array: '6268757',
      name_search: 'HIMCGRED',
      name: 'Hibiscus moscheutos Carousel Geant Red',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S6XSLA32;RRLG59C6',
      statusContent: 'dot',
      hash: 'JE2HWEKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JE2HWEKJ'
    },
    {
      status: 210,
      sku: 'PM0045893',
      core_name: 'Plant',
      sppl_ean: '8720664691018',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034341',
      rtl_batch_array: '6034341',
      name_search: 'GESCMORR',
      name: "Geranium sanguineum 'Cedric Morris'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 569,
      chnn_stock_retail: 569,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LX233PAL;X152C398;WA7LNHWJ;R2T91CWE',
      statusContent: 'dot',
      hash: 'PWJRX1Y7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWJRX1Y7'
    },
    {
      status: 210,
      sku: 'PM0046890',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193643',
      rtl_batch_array: '6193643',
      name_search: 'LOSALBA',
      name: "Lobelia siphilitica 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LRAX79TZ;1WC4L465;GAZ6ES79;S8YCP8PP;5JNVKLXD;6PB5D3SR',
      statusContent: 'dot',
      hash: 'EZJVX7RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZJVX7RC'
    },
    {
      status: 210,
      sku: 'PM0066943',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176529',
      rtl_batch_array: '6176529',
      name_search: 'LUSTAUER',
      name: "Luzula sylvatica 'Tauernpass'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 712,
      chnn_stock_retail: 712,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9V4TW2P9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V4TW2P9'
    },
    {
      status: 810,
      sku: 'PM0058133',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236253',
      rtl_batch_array: '6236253',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 3.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: '7CEGKXW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CEGKXW2'
    },
    {
      status: 210,
      sku: 'PM0067182',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244076',
      rtl_batch_array: '6244076',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'ANYNRRXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANYNRRXJ'
    },
    {
      status: 210,
      sku: 'PM0046921',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350747',
      rtl_batch_array: '6350747, 6319724',
      name_search: 'PAVOAUTU',
      name: "Panicum virgatum 'Oxblood Autumn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 429,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJ724ALH;G8XXNJTS;1AR591A8;C8XJ24KZ;CG15CZ98;HT318KKT',
      statusContent: 'dot',
      hash: 'SCLKK2S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCLKK2S2'
    },
    {
      status: 910,
      sku: 'PM0058438',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PECCPINK',
      name: "Penstemon 'Cha Cha Pink'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '4BYWKS23',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4BYWKS23'
    },
    {
      status: 210,
      sku: 'PM0011996',
      core_name: 'Plant',
      sppl_ean: '8720664867185',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084969',
      rtl_batch_array: '6084969, 6091322',
      name_search: 'HEMALBA',
      name: "Hesperis matronalis 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4925,
      chnn_stock_retail: 5537,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JGECYRTW;HEZLKTBT;GXRE3WVH;VJTNA7Y5;CCD15164;XHGKBD5K;GHSHT639',
      statusContent: 'dot',
      hash: 'BEW7WPRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEW7WPRZ'
    },
    {
      status: 210,
      sku: 'PM0046855',
      core_name: 'Plant',
      sppl_ean: '8720664876088',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112718',
      rtl_batch_array: '6112718',
      name_search: 'PAMHRETU',
      name: "Paeonia 'Many Happy Returns'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8N769KZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8N769KZ'
    },
    {
      status: 210,
      sku: 'PM0074238',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268758',
      rtl_batch_array: '6268758',
      name_search: 'HIMCPCAN',
      name: 'Hibiscus moscheutos Carousel Pink Candy',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V7WZXKX4',
      statusContent: 'dot',
      hash: '8PLZ4XR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PLZ4XR1'
    },
    {
      status: 810,
      sku: 'PM0074239',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268759',
      rtl_batch_array: '6268759',
      name_search: 'HIMCPRWI',
      name: 'Hibiscus moscheutos Carousel Pink Red Wine',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TEVKBBPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEVKBBPC'
    },
    {
      status: 210,
      sku: 'PM0074240',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268760',
      rtl_batch_array: '6268760',
      name_search: 'HIFIREBA',
      name: "Hibiscus 'Fireball'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52CZ2DAJ;1Z6H726R;NENYHD34;SDBLYKPS',
      statusContent: 'dot',
      hash: 'YZ1ZLE9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ1ZLE9N'
    },
    {
      status: 210,
      sku: 'PM0064500',
      core_name: 'Plant',
      sppl_ean: '8720664878020',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153766',
      rtl_batch_array: '6153766',
      name_search: 'PHMGREEN',
      name: "Phlox (P) 'Mystique Green'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6ZSDV8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6ZSDV8C'
    },
    {
      status: 210,
      sku: 'PM0074241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268761',
      rtl_batch_array: '6268761',
      name_search: 'HIKKING',
      name: "Hibiscus 'Kopper King'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DGEAZXS2;VXCVSECY;ZAHZKNYY;N1P2RGAP;WLP3ZZ6Z',
      statusContent: 'dot',
      hash: '6ZWCD59V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZWCD59V'
    },
    {
      status: 210,
      sku: 'PM0046907',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353377',
      rtl_batch_array: '6353377',
      name_search: 'THAWCLOU',
      name: "Thalictrum a. 'White Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69KDE1B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69KDE1B3'
    },
    {
      status: 210,
      sku: 'PM0068738',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207987',
      rtl_batch_array: '6207987',
      name_search: 'GEPGSPRI',
      name: "Geranium phaeum 'Golden Spring'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J1SSJG4W;Y3ZZ9KHZ;WHWY2VC8;SVTG5X6H;A3L4Y3PT;SRH2G32Z;V9TVKKLH;87JBBW5C',
      statusContent: 'dot',
      hash: 'R3YC71GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3YC71GG'
    },
    {
      status: 210,
      sku: 'PM0066942',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176528',
      rtl_batch_array: '6176528',
      name_search: 'LUSMARGI',
      name: "Luzula sylvatica 'Marginata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 326,
      chnn_stock_retail: 326,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D3VDW3NJ',
      statusContent: 'dot',
      hash: 'BT79AJR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BT79AJR9'
    },
    {
      status: 210,
      sku: 'PM0057246',
      core_name: 'Plant',
      sppl_ean: '8720664868199',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091434',
      rtl_batch_array: '6091434, 6350508',
      name_search: 'HOGTIARA',
      name: "Hosta 'Golden Tiara'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 445,
      chnn_stock_retail: 943,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GP86WYPP;RTH6JASC;2CW4KRAE;67Z7SJES;2P4JJJXN;D39AZSAJ',
      statusContent: 'dot',
      hash: 'C1XVZTEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1XVZTEL'
    },
    {
      status: 210,
      sku: 'PM0085158',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373045',
      rtl_batch_array: '6373045',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 917,
      chnn_stock_retail: 917,
      sppl_prcp: 6.09,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '8RVZ3SCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RVZ3SCH'
    },
    {
      status: 210,
      sku: 'PM0046812',
      core_name: 'Plant',
      sppl_ean: '8720664687332',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054551',
      rtl_batch_array: '6054551',
      name_search: 'DIALLWOO',
      name: 'Dianthus allwoodii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 389,
      chnn_stock_retail: 389,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5L23XA7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L23XA7W'
    },
    {
      status: 210,
      sku: 'PM0077925',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306594',
      rtl_batch_array: '6306594',
      name_search: 'DIORED',
      name: "Dianthus 'Odessa Red'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '693X4ASY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '693X4ASY'
    },
    {
      status: 210,
      sku: 'PM0081371',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339924',
      rtl_batch_array: '6339924',
      name_search: 'ROOPYRAM',
      name: "Rosmarinus off. 'Pyramidalis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GKW24JSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKW24JSD'
    },
    {
      status: 910,
      sku: 'PM0077926',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DISOBBLI',
      name: 'Dianthus Sunflor Orange Bling Bling',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '1ENTZBSE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1ENTZBSE'
    },
    {
      status: 210,
      sku: 'PM0085159',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373047',
      rtl_batch_array: '6373047',
      name_search: 'ALJULIBR',
      name: 'Albizia julibrissin',
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 7.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4W3B1GG;W8HK2HCT;K97TWYEC;C48GPAYH',
      statusContent: 'dot',
      hash: 'SJK83ZZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJK83ZZC'
    },
    {
      status: 210,
      sku: 'PM0077927',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306596',
      rtl_batch_array: '6306596',
      name_search: 'DIDALBIF',
      name: "Dianthus deltoides 'Albiflorus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HTL27L59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTL27L59'
    },
    {
      status: 910,
      sku: 'PM0077928',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DISJOLIN',
      name: 'Dianthus Sunflor Jolina',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'TN3GG4K4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TN3GG4K4'
    },
    {
      status: 210,
      sku: 'PM0085160',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373048',
      rtl_batch_array: '6373048',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1526,
      chnn_stock_retail: 1526,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'YG9ZXR83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YG9ZXR83'
    },
    {
      status: 210,
      sku: 'PM0077930',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306600',
      rtl_batch_array: '6306600',
      name_search: 'ERRALBUM',
      name: "Erodium reichardii 'Album'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1534,
      chnn_stock_retail: 1534,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A3S63RYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3S63RYG'
    },
    {
      status: 210,
      sku: 'PM0047732',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284963',
      rtl_batch_array: '6284963',
      name_search: 'WEFNVARI',
      name: "Weigela florida 'Nana Variegata'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXYVGERS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXYVGERS'
    },
    {
      status: 210,
      sku: 'PM0047725',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353375',
      rtl_batch_array: '6353375',
      name_search: 'THANWHIT',
      name: "Thalictrum aquil. 'Nimbus White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 793,
      chnn_stock_retail: 793,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1DWS6D7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DWS6D7V'
    },
    {
      status: 210,
      sku: 'PM0077931',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306601',
      rtl_batch_array: '6306601',
      name_search: 'GAURLFLO',
      name: "Gaura lindheimeri 'Florgauros'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VL9SWEKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL9SWEKG'
    },
    {
      status: 210,
      sku: 'PM0077932',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306602',
      rtl_batch_array: '6306602',
      name_search: 'HEEGRIFF',
      name: "Helenium 'Elibertus Griffioen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1465,
      chnn_stock_retail: 1465,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RSHL29YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSHL29YC'
    },
    {
      status: 810,
      sku: 'PM0074243',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268765',
      rtl_batch_array: '6268765',
      name_search: 'IBSFINDE',
      name: "Iberis sempervirens 'Findel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97NKVH4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97NKVH4P'
    },
    {
      status: 210,
      sku: 'PM0057533',
      core_name: 'Plant',
      sppl_ean: '8720664871069',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6093714',
      rtl_batch_array: '6093714',
      name_search: 'KOGLAUCA',
      name: 'Koeleria glauca',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2054,
      chnn_stock_retail: 2054,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YER5SPN8;T5NN83DN;W7XR4SW5;HWLR2SAZ;N9X726CD;A88RXT8A;XVBKW59K;JNAWPLBS;SVHAE79R;PEBKLRH6;14TAJW5C;1E8WSSH7;6NY6HTY9;EKL2SE7J;GWSCNGSC',
      statusContent: 'dot',
      hash: 'K1PJT1Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1PJT1Z1'
    },
    {
      status: 210,
      sku: 'PM0077934',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306605',
      rtl_batch_array: '6306605',
      name_search: 'PAANIMA',
      name: "Paeonia (LD) 'Anima'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1625,
      chnn_stock_retail: 1625,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CAJGHJL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAJGHJL5'
    },
    {
      status: 810,
      sku: 'PM0053390',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6090609',
      rtl_batch_array: '6090609',
      name_search: 'ACOALPIN',
      name: 'Aconogonon alpinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S3L4CRSW',
      statusContent: 'dot',
      hash: 'AXBHK19T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXBHK19T'
    },
    {
      status: 210,
      sku: 'PM0081372',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339925',
      rtl_batch_array: '6339925',
      name_search: 'ROOREX',
      name: "Rosmarinus officinalis 'Rex'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9PH53WNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PH53WNS'
    },
    {
      status: 210,
      sku: 'PM0067195',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177915',
      rtl_batch_array: '6177915',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 3388,
      chnn_stock_retail: 3388,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'R28PDEVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R28PDEVD'
    },
    {
      status: 210,
      sku: 'PM0081373',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339926',
      rtl_batch_array: '6339926',
      name_search: 'ROOSSEA',
      name: "Rosmarinus officinalis 'Severn Sea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V7JGK725',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7JGK725'
    },
    {
      status: 210,
      sku: 'PM0077923',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306591',
      rtl_batch_array: '6306591',
      name_search: 'ACPPEARL',
      name: "Achillea ptarmica 'The Pearl'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1287,
      chnn_stock_retail: 1287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X49A7BAW;725YY5HK;CR85CHAT;BTYBJDB8',
      statusContent: 'dot',
      hash: 'HRZY2VEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRZY2VEC'
    },
    {
      status: 210,
      sku: 'PM0077933',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306603',
      rtl_batch_array: '6306603',
      name_search: 'LISPICAT',
      name: 'Liatris spicata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5677,
      chnn_stock_retail: 5677,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6SSGZ2RA;V6D684P7;G8261HWL;VYWC8C87;5LSYGEHA;JG9Z26S7;LTPGL7BG;LD1W4CCE;R1XZDTCV;8K11Z9JG;T31Y1L61;W98CBH3A;LTGKKT8W;K6ZT3PDP;XYL945S6',
      statusContent: 'dot',
      hash: 'BBXD87PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBXD87PD'
    },
    {
      status: 210,
      sku: 'PM0068741',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254380',
      rtl_batch_array: '6207996, 6254380, 6291694',
      name_search: 'GITRIFOL',
      name: 'Gillenia trifoliata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1371,
      chnn_stock_retail: 2280,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1DTS55L;N1NYVLEJ;LJZG1HAN',
      statusContent: 'dot',
      hash: '7P516NA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P516NA4'
    },
    {
      status: 210,
      sku: 'PM0077935',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306607',
      rtl_batch_array: '6306607',
      name_search: 'PHKROSET',
      name: "Phlox kelseyi 'Rosette'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3958,
      chnn_stock_retail: 3958,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BDGEH7CT',
      statusContent: 'dot',
      hash: '8EE5Z25N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EE5Z25N'
    },
    {
      status: 210,
      sku: 'PM0077937',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306609',
      rtl_batch_array: '6306609',
      name_search: 'SERSPARK',
      name: "Sedum 'Red Sparkle'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 969,
      chnn_stock_retail: 969,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCRVCKPD;NGRR73LL;B3YBDN3J;VN5C48C8;LSTLRCGJ;ELC4PA66;RNR4B41Y',
      statusContent: 'dot',
      hash: 'H5D9HHD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5D9HHD5'
    },
    {
      status: 210,
      sku: 'PM0078048',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307306',
      rtl_batch_array: '6307306',
      name_search: 'CLBALTYK',
      name: "Clematis 'Baltyk'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94CNJPK6;NYDZTZCL',
      statusContent: 'dot',
      hash: '9GSSS3ST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GSSS3ST'
    },
    {
      status: 210,
      sku: 'PM0081374',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339927',
      rtl_batch_array: '6339927',
      name_search: 'RUFAGRUS',
      name: "Rudbeckia f. 'American Gold Rush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T32ABYH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T32ABYH9'
    },
    {
      status: 210,
      sku: 'PM0078049',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307308',
      rtl_batch_array: '6307308',
      name_search: 'CLCFRECK',
      name: "Clematis cirrhosa 'Freckles'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 4.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LTXXBVKA',
      statusContent: 'dot',
      hash: 'TKXAHVW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKXAHVW4'
    },
    {
      status: 210,
      sku: 'PM0085161',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373049',
      rtl_batch_array: '6373049',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 3442,
      chnn_stock_retail: 3442,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'EVPT8RJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVPT8RJ5'
    },
    {
      status: 210,
      sku: 'PM0081375',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339928',
      rtl_batch_array: '6339928',
      name_search: 'RUFDEAMI',
      name: 'Rudbeckia fulgida deamii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1738,
      chnn_stock_retail: 1738,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HJSCBADC;6VX7K53L;HKNLRB71;D8DA8EEA;V1ADB9VW;5C97G7Z8;54R7WXDH;EAAZHRLD;AZGEW262',
      statusContent: 'dot',
      hash: 'W8W4JCKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8W4JCKW'
    },
    {
      status: 210,
      sku: 'PM0078050',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307310',
      rtl_batch_array: '6307310',
      name_search: 'CLDESTIN',
      name: "Clematis 'Destiny'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 846,
      chnn_stock_retail: 846,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '828X22BW;R6Z55S51;E8DVBD53;J2EDRTT3;7P78SHLK;RZ99S794;ZWHJVT61;BXNL93KV;8Y5EBV5E;DXYWR7P3',
      statusContent: 'dot',
      hash: 'A5PY3JX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5PY3JX9'
    },
    {
      status: 210,
      sku: 'PM0078051',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307315',
      rtl_batch_array: '6307315',
      name_search: 'CLFLAMMU',
      name: 'Clematis flammula',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_order_minimum: 3,
      sppl_prcp: 4.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NE4ZV38D',
      statusContent: 'dot',
      hash: '2762RRSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2762RRSX'
    },
    {
      status: 810,
      sku: 'PM0071039',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181273',
      rtl_batch_array: '6181273',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 5,
      sppl_prcp: 1.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: '82Y2WWC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82Y2WWC9'
    },
    {
      status: 210,
      sku: 'PM0046814',
      core_name: 'Plant',
      sppl_ean: '8720664860995',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147742',
      rtl_batch_array: '6147742',
      name_search: 'ECPOSKIP',
      name: "Echinacea purpurea 'Orange Skipper'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1440,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TJAX4CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TJAX4CW'
    },
    {
      status: 910,
      sku: 'PM0058207',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GETBLUE',
      name: "Gentiana 'True Blue'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'LARB6RWG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LARB6RWG'
    },
    {
      status: 910,
      sku: 'PM0058208',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GENSZRIN',
      name: "Gentiana scabra 'Zuki Rindo'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'PTGT27S6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PTGT27S6'
    },
    {
      status: 210,
      sku: 'PM0067231',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164285',
      rtl_batch_array: '6350194, 6164285',
      name_search: 'GECINTEN',
      name: "Geranium cant. 'Intense'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 655,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '62RE6A8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62RE6A8C'
    },
    {
      status: 210,
      sku: 'PM0046111',
      core_name: 'Plant',
      sppl_ean: '8720664550070',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6015359',
      rtl_batch_array: '6305177, 6015359',
      name_search: 'HOTCLASS',
      name: "Hosta 'Touch of Class'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 693,
      chnn_stock_retail: 791,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5WXCRBZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WXCRBZ5'
    },
    {
      status: 910,
      sku: 'PM0081376',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUHEISA',
      name: "Rubus 'Heisa'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'DTZZ65RE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DTZZ65RE'
    },
    {
      status: 210,
      sku: 'PM0068742',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208001',
      rtl_batch_array: '6208001',
      name_search: 'HEBLINNE',
      name: "Helenium 'Baudirektor Linne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 859,
      chnn_stock_retail: 859,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJNZ169J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJNZ169J'
    },
    {
      status: 210,
      sku: 'PM0074246',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268769',
      rtl_batch_array: '6268769',
      name_search: 'HEMAHAGO',
      name: "Helenium 'Mahagoni'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2GKT2DRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GKT2DRL'
    },
    {
      status: 210,
      sku: 'PM0066426',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217165',
      rtl_batch_array: '6217165',
      name_search: 'HEIGGLAC',
      name: "Hemerocallis 'Iron Gate Glacier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 347,
      chnn_stock_retail: 347,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A14GD8K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A14GD8K1'
    },
    {
      status: 210,
      sku: 'PM0053387',
      core_name: 'Plant',
      sppl_ean: '8720349473243',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054283',
      rtl_batch_array: '6054283',
      name_search: 'ACMRLEDA',
      name: "Acanthus mollis 'Rue Ledan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AA1BH35H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA1BH35H'
    },
    {
      status: 210,
      sku: 'PM0081377',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339931',
      rtl_batch_array: '6339931',
      name_search: 'SAGABPUR',
      name: 'Salvia greggii Arctic Blaze Purple',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1RZN3ER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1RZN3ER'
    },
    {
      status: 210,
      sku: 'PM0081378',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339933',
      rtl_batch_array: '6339933',
      name_search: 'SAAGRAND',
      name: "Salvia azurea 'Grandiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y4WXK2DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4WXK2DA'
    },
    {
      status: 910,
      sku: 'PM0058309',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LERDREAM',
      name: "Leucanthemum (S) 'Real Dream'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '2GSBGWTR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2GSBGWTR'
    },
    {
      status: 210,
      sku: 'PM0069141',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254740',
      rtl_batch_array: '6254740',
      name_search: 'GEADOUBL',
      name: "Geranium 'Algera Double'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_prcp: 2.554,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LLKVS9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LLKVS9B'
    },
    {
      status: 210,
      sku: 'PM0069143',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254741',
      rtl_batch_array: '6254741',
      name_search: 'GEPHPOCU',
      name: "Geranium pratense 'Hocus Pocus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_prcp: 2.554,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NTNPCN24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTNPCN24'
    },
    {
      status: 210,
      sku: 'PM0070041',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269846',
      rtl_batch_array: '6250999, 6269846',
      name_search: 'NEPBLUE',
      name: "Nepeta 'Purssian Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 708,
      chnn_stock_retail: 1184,
      sppl_prcp: 1.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6ZHPRP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6ZHPRP7'
    },
    {
      status: 210,
      sku: 'PM0047740',
      core_name: 'Plant',
      sppl_ean: '8720664864108',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112399',
      rtl_batch_array: '6112399',
      name_search: 'GALWDOVE',
      name: "Gaura lindheimeri 'White Dove'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '937X6RXB',
      statusContent: 'dot',
      hash: '11C8BDAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11C8BDAR'
    },
    {
      status: 210,
      sku: 'PM0069142',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234743',
      rtl_batch_array: '6234743',
      name_search: 'GEPBWARM',
      name: "Geranium pratense 'Black 'n White Army'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4C29B7JS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4C29B7JS'
    },
    {
      status: 210,
      sku: 'PM0081379',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339934',
      rtl_batch_array: '6339934',
      name_search: 'SACACALI',
      name: 'Salvia cacaliifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KDHJ751',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KDHJ751'
    },
    {
      status: 210,
      sku: 'PM0081380',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339935',
      rtl_batch_array: '6339935',
      name_search: 'SAESPINE',
      name: "Salvia elegans 'Scarlet Pineapple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G19AGDST;AZX4EY6R;T66Y6WTT;9R2C229Y;8XHPTDHP;RNDDPLR2;9KWRCW7L;8J7CW9Z5;XLK1CW21;EDTGJB3R;E55RYLDS;4WG5LCNK;GEKCX9G2;1Y9SVNLS;S3GHVJHR;7SN5LHAW;TK2NTCBV',
      statusContent: 'dot',
      hash: 'EYBGTR8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYBGTR8J'
    },
    {
      status: 210,
      sku: 'PM0081381',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339936',
      rtl_batch_array: '6339936',
      name_search: 'SAFORSKA',
      name: 'Salvia forskaohlei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P5XBG8EP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5XBG8EP'
    },
    {
      status: 210,
      sku: 'PM0067197',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177917',
      rtl_batch_array: '6177917',
      name_search: 'SANGSPAR',
      name: 'Sambucus nigra Gold Spark',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 699,
      chnn_stock_retail: 699,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D7TAZ34E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7TAZ34E'
    },
    {
      status: 210,
      sku: 'PM0085162',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373050',
      rtl_batch_array: '6373050',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 676,
      chnn_stock_retail: 676,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '56Y5N8CS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56Y5N8CS'
    },
    {
      status: 210,
      sku: 'PM0081382',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339937',
      rtl_batch_array: '6339937',
      name_search: 'SAGLUTIN',
      name: 'Salvia glutinosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EG4SE2N8',
      statusContent: 'dot',
      hash: 'DTVVGHV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTVVGHV7'
    },
    {
      status: 210,
      sku: 'PM0081383',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339939',
      rtl_batch_array: '6339939',
      name_search: 'SAGBNOTE',
      name: "Salvia greggii 'Blue Note'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7GCBAP1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GCBAP1R'
    },
    {
      status: 810,
      sku: 'PM0081384',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339940',
      rtl_batch_array: '6339940',
      name_search: 'SAGCLIPS',
      name: "Salvia greggii 'Cherry Lips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LL6AYSE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL6AYSE5'
    },
    {
      status: 210,
      sku: 'PM0081385',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339941',
      rtl_batch_array: '6339941',
      name_search: 'SAGLLIGH',
      name: "Salvia greggii 'Lemon Light'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '54LNYK9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54LNYK9D'
    },
    {
      status: 810,
      sku: 'PM0081386',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339942',
      rtl_batch_array: '6339942',
      name_search: 'SAGCABLU',
      name: "Salvia guaranitica 'Carine's Amazing Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NGKH7YXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGKH7YXP'
    },
    {
      status: 210,
      sku: 'PM0081387',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348413',
      rtl_batch_array: '6339943, 6348413',
      name_search: 'SAGBBLUE',
      name: "Salvia guaranitica 'Black and Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 836,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GWD8RVC7;H1VCYBNJ;P57WD5W1;CERCAL5P;WW5Z7AW3;WH5S3C61;N4W73NNC;9PWPB5TZ;17HVCJ63;GAAX7CWH;7YPW3D9G',
      statusContent: 'dot',
      hash: 'H9PSC64W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9PSC64W'
    },
    {
      status: 210,
      sku: 'PM0081388',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339944',
      rtl_batch_array: '6339944',
      name_search: 'SAMDAQUA',
      name: "Salvia micr. 'Delice Aquamarine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '47V9WPGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47V9WPGV'
    },
    {
      status: 210,
      sku: 'PM0047698',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301371',
      rtl_batch_array: '6053966, 6301371',
      name_search: 'ACNALBUM',
      name: "Aconitum napellus 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 612,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJSJCPDT;GCN1AZLG;L4C34TXG',
      statusContent: 'dot',
      hash: 'B34687RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B34687RZ'
    },
    {
      status: 210,
      sku: 'PM0081389',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339945',
      rtl_batch_array: '6339945',
      name_search: 'SAMDGWIN',
      name: "Salvia micr. 'Delice Gold and Wine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6HZEN7E5;GJTT54HC',
      statusContent: 'dot',
      hash: 'TA5S37G1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA5S37G1'
    },
    {
      status: 210,
      sku: 'PM0081390',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339946',
      rtl_batch_array: '6339946',
      name_search: 'SAMSUZAN',
      name: "Salvia micr. 'Suzanne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X553TAT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X553TAT1'
    },
    {
      status: 810,
      sku: 'PM0081391',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345221',
      rtl_batch_array: '6339947, 6345221',
      name_search: 'SAMICROP',
      name: 'Salvia microphylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 557,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKDKSVGT;KNKBAEAN;55JZJHW6;L1TER7L5;TGYR46NE;Y317ENBG;2BX3XTG8;4JN78K74;E5427TA6;T7442SWT;PGL3TECY;Z3AXXCBH;EDGWGRE1;6T46ZSHT;4K6VY247;EPP57ZKB;4ETTWH5B;WPXB9VJG;57VXAL3G',
      statusContent: 'dot',
      hash: '7X3VXW3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7X3VXW3D'
    },
    {
      status: 210,
      sku: 'PM0048196',
      core_name: 'Plant',
      sppl_ean: '8720626392076',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014584',
      rtl_batch_array: '6014584',
      name_search: 'ASPRAIN',
      name: "Astilbe (A) 'Purple Rain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 317,
      chnn_stock_retail: 317,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APPAJ45Z;X31V4GEA',
      statusContent: 'dot',
      hash: '9WPNVWGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WPNVWGR'
    },
    {
      status: 210,
      sku: 'PM0081392',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339949',
      rtl_batch_array: '6339949',
      name_search: 'SASBMARV',
      name: 'Salvia sylvestris Blue Marvel',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA48BHV5;HB9Y54KW;5SGALECD',
      statusContent: 'dot',
      hash: 'ALL4DYKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALL4DYKD'
    },
    {
      status: 210,
      sku: 'PM0081393',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339950',
      rtl_batch_array: '6339950',
      name_search: 'SEHSWIFF',
      name: "Setcreacea hirsuta 'Swifttale",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R49HP21T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R49HP21T'
    },
    {
      status: 210,
      sku: 'PM0057353',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350749',
      rtl_batch_array: '6301757, 6350749',
      name_search: 'PAVSANGR',
      name: "Panicum virgatum 'Sangria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1184,
      chnn_stock_retail: 1398,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G1VVV9XB;72NX1NVV;BBBW3AY9;GXRXRERK;ZKYKC5C4;SDG6D5V6;HKT9BKBH;87JC9CCR;C9ACB943;G97B3DCA;5WXEXVR2',
      statusContent: 'dot',
      hash: 'GSTDA71D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSTDA71D'
    },
    {
      status: 210,
      sku: 'PM0081394',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339951',
      rtl_batch_array: '6339951',
      name_search: 'SICGIRL',
      name: "Sidalcea 'Candy Girl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LZSD6218',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZSD6218'
    },
    {
      status: 210,
      sku: 'PM0047741',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348983',
      rtl_batch_array: '6369735, 6317841, 6348983',
      name_search: 'GERMTAI',
      name: "Geum rivale 'Mai Tai'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 314,
      chnn_stock_retail: 1953,
      sppl_order_minimum: 3,
      sppl_prcp: 1.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PZLDWJGG;W2TEKS9J;RXX2X1YP;PBA2NDL9;X4JW76T7;5EZKTHEA;KDHA4PX5;DJTNP9AS;7W1SB9ZX;CG35143W;HJ1GPV37;Y7HYV9B4;2BW2XB14;C97BG347',
      statusContent: 'dot',
      hash: 'GZ4PDGLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZ4PDGLA'
    },
    {
      status: 210,
      sku: 'PM0085163',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373051',
      rtl_batch_array: '6373051',
      name_search: 'AMSPICAT',
      name: 'Amelanchier spicata',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1022,
      chnn_stock_retail: 1022,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z4C8DVKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4C8DVKJ'
    },
    {
      status: 210,
      sku: 'PM0085164',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373052',
      rtl_batch_array: '6373052',
      name_search: 'AMSPICAT',
      name: 'Amelanchier spicata',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1830,
      chnn_stock_retail: 1830,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A14VY331',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A14VY331'
    },
    {
      status: 210,
      sku: 'PM0038386',
      core_name: 'Plant',
      sppl_ean: '8720626367234',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431825',
      rtl_batch_array: '5431825',
      name_search: 'RHYAKUSH',
      name: 'Rhododendron yakushimanum',
      sppl_size_code: '020025C5',
      rng_range_identifier: 'H020025C5',
      rng_range_description: 'H20 cm 25 cm C5',
      sppl_stock_available: 248,
      chnn_stock_retail: 248,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NA95K87G;PPKA83R5;75ABDCTR;45X4HW2V',
      statusContent: 'dot',
      hash: '8YZZ7LL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YZZ7LL9'
    },
    {
      status: 210,
      sku: 'PM0064506',
      core_name: 'Plant',
      sppl_ean: '8720664875111',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153774',
      rtl_batch_array: '6153774',
      name_search: 'NESHGOLD',
      name: "Nepeta 'Six Hills Gold'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZR9JVYSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR9JVYSH'
    },
    {
      status: 210,
      sku: 'PM0085165',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373053',
      rtl_batch_array: '6373053',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1543,
      chnn_stock_retail: 1543,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'LTB6JLN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTB6JLN2'
    },
    {
      status: 810,
      sku: 'PM0038441',
      core_name: 'Plant',
      sppl_ean: '8720664560147',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '5506381',
      rtl_batch_array: '5506381, 6067893',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '040060C12',
      rng_range_identifier: 'H040060C12',
      rng_range_description: 'H40 cm 60 cm C12',
      sppl_stock_available: 60,
      chnn_stock_retail: 1018,
      sppl_prcp: 6.635,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'E736Z9DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E736Z9DS'
    },
    {
      status: 210,
      sku: 'PM0085166',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373054',
      rtl_batch_array: '6373054',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 951,
      chnn_stock_retail: 951,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'SS99JRL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS99JRL3'
    },
    {
      status: 210,
      sku: 'PM0074245',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268768',
      rtl_batch_array: '6268768',
      name_search: 'HEHLAVA',
      name: "Helenium 'Hot Lava'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2H6X4ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2H6X4ZJ'
    },
    {
      status: 210,
      sku: 'PM0085167',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373055',
      rtl_batch_array: '6373055',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 159,
      chnn_stock_retail: 159,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: '798GDXNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '798GDXNA'
    },
    {
      status: 910,
      sku: 'PM0066452',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CODMIRAN',
      name: "Cotoneaster dammeri 'Miranda'",
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      statusContent: 'dot',
      hash: '18513GTA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '18513GTA'
    },
    {
      status: 210,
      sku: 'PM0085168',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373056',
      rtl_batch_array: '6373056',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2069,
      chnn_stock_retail: 2069,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6ZS1WXCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZS1WXCT'
    },
    {
      status: 210,
      sku: 'PM0085169',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373057',
      rtl_batch_array: '6373057',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 3782,
      chnn_stock_retail: 3782,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '22AWACD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22AWACD6'
    },
    {
      status: 210,
      sku: 'PM0047733',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333242',
      rtl_batch_array: '6192626, 6253015, 6333242',
      name_search: 'ALEPIPSI',
      name: 'Alchemilla epipsila',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8612,
      chnn_stock_retail: 13404,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96P7GYDV',
      statusContent: 'dot',
      hash: '7HK5H9GN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HK5H9GN'
    },
    {
      status: 210,
      sku: 'PM0028772',
      core_name: 'Plant',
      sppl_ean: '8720353031194',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '5607007',
      rtl_batch_array: '5607007, 6133292',
      name_search: 'DECBRONZ',
      name: "Deschampsia cespitosa 'Bronzeschleier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7800,
      chnn_stock_retail: 9108,
      sppl_order_minimum: 3,
      sppl_prcp: 0.568,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WXB4CWLY',
      statusContent: 'dot',
      hash: '8G65B1VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G65B1VK'
    },
    {
      status: 810,
      sku: 'PM0085170',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373058',
      rtl_batch_array: '6373058',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AH39VD75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AH39VD75'
    },
    {
      status: 210,
      sku: 'PM0085171',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373059',
      rtl_batch_array: '6373059',
      name_search: 'BUDMGKNI',
      name: "Buddleja davidii 'Magda's Gold Knight''",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1788,
      chnn_stock_retail: 1788,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRVW5VCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRVW5VCX'
    },
    {
      status: 810,
      sku: 'PM0085172',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373060',
      rtl_batch_array: '6373060',
      name_search: 'BUDMGKNI',
      name: "Buddleja davidii 'Magda's Gold Knight''",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHSY6HN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHSY6HN4'
    },
    {
      status: 210,
      sku: 'PM0085173',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373061',
      rtl_batch_array: '6373061',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1893,
      chnn_stock_retail: 1893,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'AEA2AYR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEA2AYR5'
    },
    {
      status: 210,
      sku: 'PM0085174',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373062',
      rtl_batch_array: '6373062',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'NRPKZGZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRPKZGZ2'
    },
    {
      status: 810,
      sku: 'PM0085175',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373063',
      rtl_batch_array: '6373063',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'GJW6WJWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJW6WJWV'
    },
    {
      status: 210,
      sku: 'PM0085176',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373064',
      rtl_batch_array: '6373064',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1528,
      chnn_stock_retail: 1528,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: '4YDVJKDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YDVJKDS'
    },
    {
      status: 210,
      sku: 'PM0085177',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373065',
      rtl_batch_array: '6373065',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 729,
      chnn_stock_retail: 729,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'NW3EV1WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW3EV1WH'
    },
    {
      status: 210,
      sku: 'PM0085178',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373066',
      rtl_batch_array: '6373066',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'YAVTCYBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAVTCYBY'
    },
    {
      status: 210,
      sku: 'PM0085179',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373067',
      rtl_batch_array: '6373067',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1335,
      chnn_stock_retail: 1335,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'R7JXWPAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7JXWPAE'
    },
    {
      status: 210,
      sku: 'PM0085180',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373068',
      rtl_batch_array: '6373068',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'RBEE8D1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBEE8D1V'
    },
    {
      status: 210,
      sku: 'PM0067236',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267720',
      rtl_batch_array: '6267720',
      name_search: 'PATERMIN',
      name: 'Pachysandra terminalis',
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_order_minimum: 6,
      sppl_prcp: 0.506,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '75KDGC7C;LELCZ73R;NDHGKGGT;GN61W2KN;TX925EKH;W9S1NG9S;WP6ZBEBW',
      statusContent: 'dot',
      hash: 'EH5YACSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH5YACSJ'
    },
    {
      status: 210,
      sku: 'PM0071041',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221456',
      rtl_batch_array: '6221456',
      name_search: 'DIGCBELL',
      name: "Digitalis grandiflora 'Creme Bell'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YY8YCCAA;9GXAW7XT',
      statusContent: 'dot',
      hash: 'CEW476N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEW476N5'
    },
    {
      status: 210,
      sku: 'PM0085181',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373069',
      rtl_batch_array: '6373069',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'D4EW8D7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4EW8D7C'
    },
    {
      status: 210,
      sku: 'PM0085182',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373070',
      rtl_batch_array: '6373070',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1652,
      chnn_stock_retail: 1652,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: '5LS8YPLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LS8YPLC'
    },
    {
      status: 910,
      sku: 'PM0066446',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BETGCARP',
      name: "Berberis thunbergii 'Green Carpet'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      imageCore:
        'SNWWRAKZ;YD196LHY;E2DS8XYP;92KV2V3Y;1TWS9VZ5;AL7ZSVGW;RRP2GJTN;CEHD2ZR6;7B62XH6R;TREST3EE',
      statusContent: 'dot',
      hash: 'P6NLYHN9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P6NLYHN9'
    },
    {
      status: 910,
      sku: 'PM0070043',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAERMAG',
      name: "Paeonia (LD) 'Red Magic'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'B31NKCB9',
      statusContent: 'dot',
      hash: 'EVLJS1HT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EVLJS1HT'
    },
    {
      status: 210,
      sku: 'PM0058448',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349882',
      rtl_batch_array: '6349882',
      name_search: 'MISETINC',
      name: "Miscanthus sinensis 'Etincelle'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2WKBSKCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WKBSKCC'
    },
    {
      status: 810,
      sku: 'PM0068305',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274526',
      rtl_batch_array: '6368813, 6274526',
      name_search: 'ASWPURPL',
      name: "Aster 'Wood's Purple'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 40,
      chnn_stock_retail: 1040,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K4CV1K17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4CV1K17'
    },
    {
      status: 210,
      sku: 'PM0046326',
      core_name: 'Plant',
      sppl_ean: '8720664592582',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015457',
      rtl_batch_array: '6015457',
      name_search: 'PHDPINNA',
      name: 'Phegopteris decursive-pinnata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '16N6LE9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16N6LE9L'
    },
    {
      status: 210,
      sku: 'PM0054327',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6287419',
      rtl_batch_array: '6254892, 6287419, 6330160',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 361,
      chnn_stock_retail: 641,
      sppl_prcp: 7.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '77BVRKS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77BVRKS1'
    },
    {
      status: 810,
      sku: 'PM0065913',
      core_name: 'Plant',
      sppl_ean: '8720664861275',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169533',
      rtl_batch_array: '6169533',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 16.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: 'DB72JDTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB72JDTZ'
    },
    {
      status: 210,
      sku: 'PM0053410',
      core_name: 'Plant',
      sppl_ean: '8720664680081',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054343',
      rtl_batch_array: '6054343',
      name_search: 'EDI89233',
      name: "Allium 'Party Balloons'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9WCZ79XX;5REJ748K;ADHZWAA7;91JPS9VB;1RS9K636;4XPXLSCH;ZNKK4GCW;24KADEKZ;R9P3HTY2;9NWC5EDV;NAEHCJ4H;B58C616Y;NG984ZEC',
      statusContent: 'dot',
      hash: 'EX92J2X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX92J2X2'
    },
    {
      status: 210,
      sku: 'PM0058167',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382276',
      rtl_batch_array: '6112340, 6238785, 6382276',
      name_search: 'DECGOLDT',
      name: "Deschampsia cespitosa 'Goldtau'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10940,
      chnn_stock_retail: 17607,
      sppl_order_minimum: 3,
      sppl_prcp: 0.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7ZNEJDA;S4JYADT8',
      statusContent: 'dot',
      hash: 'L775HLGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L775HLGB'
    },
    {
      status: 210,
      sku: 'PM0069140',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254734',
      rtl_batch_array: '6254734',
      name_search: 'GEMBVARI',
      name: "Geranium macrorrhizum 'Bevan's Variety'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 629,
      chnn_stock_retail: 629,
      sppl_prcp: 1.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8YYPL3HW',
      statusContent: 'dot',
      hash: 'J49RY572',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J49RY572'
    },
    {
      status: 210,
      sku: 'PM0077938',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306611',
      rtl_batch_array: '6306611',
      name_search: 'SEMPERVI',
      name: "Sempervivum hybrid in c.v.'s",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3964,
      chnn_stock_retail: 3964,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KX26DR5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KX26DR5E'
    },
    {
      status: 810,
      sku: 'PM0065915',
      core_name: 'Plant',
      sppl_ean: '8720664862586',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169535',
      rtl_batch_array: '6169535',
      name_search: 'EXRACEMO',
      name: 'Exochorda racemosa',
      sppl_size_code: '125150C30',
      rng_range_identifier: 'H125150C30',
      rng_range_description: 'H125 cm 150 cm C30',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 45.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BD6XTJ6S;YCBKHZAN',
      statusContent: 'dot',
      hash: 'PV14EG4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PV14EG4E'
    },
    {
      status: 210,
      sku: 'PM0071042',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284551',
      rtl_batch_array: '6315031, 6252564, 6284551',
      name_search: 'EUAROBBI',
      name: 'Euphorbia amygdaloides robbiae',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4534,
      chnn_stock_retail: 6435,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6RX3GZLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RX3GZLS'
    },
    {
      status: 210,
      sku: 'PM0069145',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252603',
      rtl_batch_array: '6252603',
      name_search: 'GETROSE',
      name: 'Geum Tempo Rose',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.406,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZJ5L45J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZJ5L45J'
    },
    {
      status: 810,
      sku: 'PM0065917',
      core_name: 'Plant',
      sppl_ean: '8720664865617',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6169537',
      rtl_batch_array: '6169537, 6307451',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 99,
      sppl_prcp: 14.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: '2A6G3TV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A6G3TV5'
    },
    {
      status: 810,
      sku: 'PM0065918',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 2,
      btch_active_retail: '6292733',
      rtl_batch_array: '6169538, 6292733',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 95,
      chnn_stock_retail: 145,
      sppl_prcp: 17.059,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'D5G7KB5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5G7KB5E'
    },
    {
      status: 210,
      sku: 'PM0069147',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254751',
      rtl_batch_array: '6254751',
      name_search: 'HEGLGLOS',
      name: "Helleborus Frostkiss Glenda's Gloss",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 4.653,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6HEA5NX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6HEA5NX'
    },
    {
      status: 210,
      sku: 'PM0068711',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290209',
      rtl_batch_array: '6301425, 6290209',
      name_search: 'ASAPOLLO',
      name: "Aster (D) 'Apollo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7422,
      chnn_stock_retail: 8399,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SEKTKSP',
      statusContent: 'dot',
      hash: '2DTCXE1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DTCXE1W'
    },
    {
      status: 210,
      sku: 'PM0085183',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373071',
      rtl_batch_array: '6373071',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 552,
      chnn_stock_retail: 552,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: '4C9JLB1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4C9JLB1L'
    },
    {
      status: 910,
      sku: 'PM0066459',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DISPLEND',
      name: 'Diervilla splendens',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      statusContent: 'dot',
      hash: 'GPH73R9T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GPH73R9T'
    },
    {
      status: 210,
      sku: 'PM0081395',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339952',
      rtl_batch_array: '6339952',
      name_search: 'SIPARTYG',
      name: "Sidalcea 'Partygirl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XE8LJD9Y;TGLXZXK6',
      statusContent: 'dot',
      hash: 'KJH39K33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJH39K33'
    },
    {
      status: 210,
      sku: 'PM0078052',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307339',
      rtl_batch_array: '6307339',
      name_search: 'CLMBLUE',
      name: "Clematis 'Multi Blue'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1182,
      chnn_stock_retail: 1182,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBY5H17A;CRBXC9RK;6CKDCXR5;57C4GKBR;EKWNXX7Z;5YY8TAG3;K89G93S5;KGLDAZ1G;KE5CBZWX;1RLYGTXA;L9BTJ3R4;DBY1HP6R;XKKWN4DX;8CPKWVN2',
      statusContent: 'dot',
      hash: '4B3ES6J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B3ES6J8'
    },
    {
      status: 210,
      sku: 'PM0071047',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252617',
      rtl_batch_array: '6252617',
      name_search: 'HESRUSSE',
      name: "Hemerocallis 'Sammy Russell'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DV79B8D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DV79B8D7'
    },
    {
      status: 810,
      sku: 'PM0065916',
      core_name: 'Plant',
      sppl_ean: '8720664862692',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169536',
      rtl_batch_array: '6169536',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '200225C20',
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'G3N2EAN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3N2EAN1'
    },
    {
      status: 210,
      sku: 'PM0058174',
      core_name: 'Plant',
      sppl_ean: '8720664861206',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164267',
      rtl_batch_array: '6349074, 6112351, 6164267, 6302494',
      name_search: 'ECBBGLOB',
      name: "Echinops bannaticus 'Blue Globe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 362,
      chnn_stock_retail: 13278,
      sppl_order_minimum: 3,
      sppl_prcp: 0.779,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B29L4BGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B29L4BGW'
    },
    {
      status: 210,
      sku: 'PM0058414',
      core_name: 'Plant',
      sppl_ean: '8720664800601',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112726',
      rtl_batch_array: '6112726',
      name_search: 'PAVDIWAL',
      name: "Panicum virgatum 'Diwali'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KVN5KRLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVN5KRLD'
    },
    {
      status: 210,
      sku: 'PM0063573',
      core_name: 'Plant',
      sppl_ean: '8720664863682',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147169',
      rtl_batch_array: '6147169',
      name_search: 'FRFREL',
      name: "Fragaria 'Frel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AALE676T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AALE676T'
    },
    {
      status: 210,
      sku: 'PM0074247',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291240',
      rtl_batch_array: '6291240',
      name_search: 'HERUBINZ',
      name: "Helenium 'Rubinzwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NVP94JR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVP94JR8'
    },
    {
      status: 910,
      sku: 'PM0081396',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SIMOHRII',
      name: 'Silphium mohrii',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'W69GTCH1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W69GTCH1'
    },
    {
      status: 210,
      sku: 'PM0081397',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339954',
      rtl_batch_array: '6339954',
      name_search: 'SILUCERN',
      name: "Sisyrinchium 'Lucerne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BZ331L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BZ331L8'
    },
    {
      status: 810,
      sku: 'PM0053372',
      core_name: 'Plant',
      sppl_ean: '8720353014265',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053948',
      rtl_batch_array: '6053948',
      name_search: 'ALSCMAUV',
      name: "Allium schoenoprasum 'Curly Mauve'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9GYN6DPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GYN6DPA'
    },
    {
      status: 210,
      sku: 'PM0081398',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301849',
      rtl_batch_array: '6339955, 6301849',
      name_search: 'SICALIFO',
      name: 'Sisyrinchium californicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 487,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NP7R2PBG;8YA1D2EK;7ELZYCDH;11HW2WT7;JY37AGDP;19NGZ6S3;BZTAJ8RN',
      statusContent: 'dot',
      hash: 'H4NX3X9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4NX3X9L'
    },
    {
      status: 210,
      sku: 'PM0081399',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339956',
      rtl_batch_array: '6339956',
      name_search: 'SIICEBER',
      name: "Sisyrinchium 'Iceberg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NTSKWGN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTSKWGN5'
    },
    {
      status: 210,
      sku: 'PM0025494',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370348',
      rtl_batch_array: '6370348, 6164272, 6333224',
      name_search: 'ERRJUWEL',
      name: "Erigeron 'Rosa Juwel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 2380,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BCJKSDH7;BDRCGV6G;X3TS8W7X',
      statusContent: 'dot',
      hash: 'TP5TPG3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TP5TPG3N'
    },
    {
      status: 210,
      sku: 'PM0063240',
      core_name: 'Plant',
      sppl_ean: '8720664871250',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147806',
      rtl_batch_array: '6147806, 6370368',
      name_search: 'LAMALBUM',
      name: "Lamium maculatum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 562,
      chnn_stock_retail: 1362,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZVHJRYD;XR4V5HEC;ACEK3J38;91DG16JN;NNT23HBE;H2333DGV',
      statusContent: 'dot',
      hash: 'R6V82NVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6V82NVL'
    },
    {
      status: 210,
      sku: 'PM0081400',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339957',
      rtl_batch_array: '6339957',
      name_search: 'SISAPPHI',
      name: "Sisyrinchium 'Sapphire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HEWWTPVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEWWTPVV'
    },
    {
      status: 210,
      sku: 'PM0081401',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339958',
      rtl_batch_array: '6339958',
      name_search: 'SISTRIAT',
      name: 'Sisyrinchium striatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VZA1Z2NJ;9STAR2TL;RLSJC19T;DA62BWST;CPEDAD74;KER4WKTS;RL1P491T;L8WR6K6H;8D2Y58NR;HDE6TKHH;TJ2NDPP6;82WEE9N1;DNZZPYXC',
      statusContent: 'dot',
      hash: '4B6VRHVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B6VRHVS'
    },
    {
      status: 210,
      sku: 'PM0081402',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339960',
      rtl_batch_array: '6339960',
      name_search: 'SPWRIGHT',
      name: 'Sporobolus wrightii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RNCRYK7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNCRYK7W'
    },
    {
      status: 210,
      sku: 'PM0007341',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363193',
      rtl_batch_array: '6350175, 6202293, 6363193',
      name_search: 'GABURGUN',
      name: "Gaillardia 'Burgunder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2568,
      chnn_stock_retail: 3200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K176BVXE;PBV4LGTD;HPJGBSEZ;22YBLXXE;GYLWS5BA;48KG7TP4;6RYVSP21;9VHGEVDY;RP6T62ZE;ANX3VAPT;STT95L2H;PL1N7E6R',
      statusContent: 'dot',
      hash: 'N12K8E4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N12K8E4Z'
    },
    {
      status: 210,
      sku: 'PM0081403',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339961',
      rtl_batch_array: '6339961',
      name_search: 'STATROPU',
      name: 'Strobilanthes atropurpureus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 334,
      chnn_stock_retail: 334,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X7PXD37R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7PXD37R'
    },
    {
      status: 910,
      sku: 'PM0047045',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SETSHRDS',
      name: "Sedum t. 'Seduction Hot Rose Dark Stems'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'S11H8EGR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S11H8EGR'
    },
    {
      status: 210,
      sku: 'PM0081404',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349158',
      rtl_batch_array: '6349158, 6339964, 6340050',
      name_search: 'VEHASTAT',
      name: 'Verbena hastata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2016,
      chnn_stock_retail: 4172,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDD3C4RY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDD3C4RY'
    },
    {
      status: 210,
      sku: 'PM0081405',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339965',
      rtl_batch_array: '6339965',
      name_search: 'VELFRANC',
      name: "Verbena 'La France'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VWYEG7ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWYEG7ZD'
    },
    {
      status: 210,
      sku: 'PM0068717',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6267754',
      rtl_batch_array: '6290001, 6267754, 6320842',
      name_search: 'ASMSBEAU',
      name: "Astrantia major 'Star of Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 845,
      chnn_stock_retail: 1321,
      sppl_order_minimum: 3,
      sppl_prcp: 1.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9K93Y1VB;XX9S28RY;Z61T59SS;KKE6AY5L;4T7WLP4W;9L81RJEP;PCY61H8J;NCVTT99V;JP78P4C9',
      statusContent: 'dot',
      hash: '45RBRSXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45RBRSXV'
    },
    {
      status: 810,
      sku: 'PM0052761',
      core_name: 'Plant',
      sppl_ean: '8720664802179',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6041215',
      rtl_batch_array: '6041215',
      name_search: 'PIAFORMA',
      name: "Picea abies 'Formanek'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBW7JXCP',
      statusContent: 'dot',
      hash: 'DESDDDVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DESDDDVZ'
    },
    {
      status: 810,
      sku: 'PM0065920',
      core_name: 'Plant',
      sppl_ean: '8720664867147',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169540',
      rtl_batch_array: '6169540',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'TJL382X6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJL382X6'
    },
    {
      status: 210,
      sku: 'PM0063523',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349097',
      rtl_batch_array: '6349097, 6350370, 6130749',
      name_search: 'HEADALE',
      name: "Heuchera americana 'Dale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4056,
      chnn_stock_retail: 4804,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZ1NPPEC;N2S5TRYT',
      statusContent: 'dot',
      hash: 'EYZRB24D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYZRB24D'
    },
    {
      status: 810,
      sku: 'PM0058316',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350647',
      rtl_batch_array: '6350647',
      name_search: 'LEFORMOS',
      name: 'Leycesteria formosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72LLZYLW;4LT85GG3;98BYDGT2;EA8EXSLG;DWBT25YS;CEY4EZNV;1391SWRD;9V7H93X2',
      statusContent: 'dot',
      hash: 'RWPN42V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWPN42V7'
    },
    {
      status: 210,
      sku: 'PM0064726',
      core_name: 'Plant',
      sppl_ean: '8720664877900',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140809',
      rtl_batch_array: '6140809',
      name_search: 'PHDANIEL',
      name: "Phlox (P) 'Danielle'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HCA113BT',
      statusContent: 'dot',
      hash: '9VD3ZKC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VD3ZKC8'
    },
    {
      status: 210,
      sku: 'PM0081406',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339966',
      rtl_batch_array: '6339966',
      name_search: 'VESTRICT',
      name: 'Verbena stricta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SD7YG4B6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD7YG4B6'
    },
    {
      status: 210,
      sku: 'PM0053436',
      core_name: 'Plant',
      sppl_ean: '8720353087184',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054439',
      rtl_batch_array: '6054439',
      name_search: 'ASPPAN',
      name: "Aster (D) 'Peter Pan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TTXXCEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TTXXCEV'
    },
    {
      status: 910,
      sku: 'PM0081407',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VEALLION',
      name: 'Veronica allionii',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'EPNDDN6D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EPNDDN6D'
    },
    {
      status: 210,
      sku: 'PM0081408',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339968',
      rtl_batch_array: '6339968',
      name_search: 'VEASPIRE',
      name: 'Veronica Aspire',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7XHNEPKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XHNEPKJ'
    },
    {
      status: 210,
      sku: 'PM0058227',
      core_name: 'Plant',
      sppl_ean: '8720664867994',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112439',
      rtl_batch_array: '6112439',
      name_search: 'HOADICKI',
      name: "Hosta 'Austin Dickinson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1RB7DDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1RB7DDB'
    },
    {
      status: 210,
      sku: 'PM0081409',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6345216',
      rtl_batch_array: '6349159, 6339969, 6345216',
      name_search: 'VEARBLUE',
      name: "Veronica austriaca 'Royal Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 247,
      chnn_stock_retail: 4080,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZ5PSNYP;RXNHR5EW',
      statusContent: 'dot',
      hash: 'GWVLSVHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWVLSVHR'
    },
    {
      status: 210,
      sku: 'PM0047058',
      core_name: 'Plant',
      sppl_ean: '8720664871229',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153401',
      rtl_batch_array: '6153401',
      name_search: 'LAGALEOB',
      name: 'Lamiastrum galeobdolon',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2444,
      chnn_stock_retail: 2444,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '665HTZ2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '665HTZ2D'
    },
    {
      status: 210,
      sku: 'PM0058228',
      core_name: 'Plant',
      sppl_ean: '8720664868007',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112441',
      rtl_batch_array: '6112441',
      name_search: 'HOBANN',
      name: "Hosta 'Barbara Ann'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G421AP1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G421AP1J'
    },
    {
      status: 810,
      sku: 'PM0057388',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238774',
      rtl_batch_array: '6238774',
      name_search: 'POHYBRID',
      name: 'Polygonatum hybridum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7L3VLTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7L3VLTX'
    },
    {
      status: 210,
      sku: 'PM0074250',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268777',
      rtl_batch_array: '6268777',
      name_search: 'IRSPERSI',
      name: "Iris sibirica 'Persimmon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X8DR4WDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8DR4WDY'
    },
    {
      status: 910,
      sku: 'PM0068752',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KNCAPPLE',
      name: "Kniphofia 'Candy Apple'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'SW4Y4YEJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SW4Y4YEJ'
    },
    {
      status: 910,
      sku: 'PM0058315',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LEJGANGE',
      name: "Leucosceptrum japonicum 'Golden Angel'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'V9Z4AJ1L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V9Z4AJ1L'
    },
    {
      status: 810,
      sku: 'PM0068755',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208029',
      rtl_batch_array: '6208029',
      name_search: 'LIMJBURC',
      name: "Liriope muscari 'John Burch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VEYDBLDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VEYDBLDH'
    },
    {
      status: 210,
      sku: 'PM0068756',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208030',
      rtl_batch_array: '6208030',
      name_search: 'LIMSBLUE',
      name: "Liriope muscari 'Super Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AL5JB5D8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AL5JB5D8'
    },
    {
      status: 210,
      sku: 'PM0071044',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252556',
      rtl_batch_array: '6252556',
      name_search: 'ECBSFROS',
      name: "Echinops bannaticus 'Star Frost'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '58VW9Y6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58VW9Y6P'
    },
    {
      status: 810,
      sku: 'PM0081410',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339971',
      rtl_batch_array: '6339971',
      name_search: 'VELSONJA',
      name: "Veronica longifolia 'Sonja'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7TCLWGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7TCLWGD'
    },
    {
      status: 210,
      sku: 'PM0064730',
      core_name: 'Plant',
      sppl_ean: '8720664866744',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140813',
      rtl_batch_array: '6140813',
      name_search: 'HEJSWANN',
      name: "Hemerocallis 'Jean Swann'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VW4P8LJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VW4P8LJE'
    },
    {
      status: 210,
      sku: 'PM0074248',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268772',
      rtl_batch_array: '6268772',
      name_search: 'HENEMMA',
      name: "Helleborus nigercors 'Emma'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 3.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HDGPEBPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDGPEBPR'
    },
    {
      status: 910,
      sku: 'PM0081411',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THCLEMON',
      name: "Thymus citriodorus 'Lemon'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'PK3WZAY6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PK3WZAY6'
    },
    {
      status: 210,
      sku: 'PM0081412',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339976',
      rtl_batch_array: '6339976',
      name_search: 'YURSSKIE',
      name: "Yucca rostrata 'Sapphire Skies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6S89ETZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6S89ETZL'
    },
    {
      status: 210,
      sku: 'PM0074249',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268773',
      rtl_batch_array: '6268773',
      name_search: 'HENSLOVE',
      name: "Helleborus nigercors 'Snow Love'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 3.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATE2ZZX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATE2ZZX7'
    },
    {
      status: 210,
      sku: 'PM0085184',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373072',
      rtl_batch_array: '6373072',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'XWZB4XP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWZB4XP4'
    },
    {
      status: 210,
      sku: 'PM0081413',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339977',
      rtl_batch_array: '6339977',
      name_search: 'ZACALIFO',
      name: 'Zauschneria californica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26T2PALC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26T2PALC'
    },
    {
      status: 210,
      sku: 'PM0053407',
      core_name: 'Plant',
      sppl_ean: '8720664680050',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054334',
      rtl_batch_array: '6054334',
      name_search: 'EDI83764',
      name: "Allium 'Metallic Shine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TSDK1DGD;95RK3EH4;BTXHRVRY;2S3P2WLA;X9L9NCE3',
      statusContent: 'dot',
      hash: '9PH49E4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PH49E4B'
    },
    {
      status: 810,
      sku: 'PM0081414',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339978',
      rtl_batch_array: '6339978',
      name_search: 'ZIMIOGA',
      name: 'Zingiber mioga',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P4JBZBB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4JBZBB6'
    },
    {
      status: 810,
      sku: 'PM0085185',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373073',
      rtl_batch_array: '6373073',
      name_search: 'BUDPI',
      name: "Buddleja davidii 'PIIBD-I'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8A3V7BHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8A3V7BHC'
    },
    {
      status: 810,
      sku: 'PM0085186',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373074',
      rtl_batch_array: '6373074',
      name_search: 'BUFPLFLO',
      name: 'Buddleja Free Petite Lavender Flow',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XALTTLWR;95XJPGXK',
      statusContent: 'dot',
      hash: 'GD518YGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GD518YGE'
    },
    {
      status: 210,
      sku: 'PM0057544',
      core_name: 'Plant',
      sppl_ean: '8720664885899',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6093725',
      rtl_batch_array: '6093725',
      name_search: 'SEOREGAN',
      name: 'Sedum oreganum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 624,
      chnn_stock_retail: 624,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HEW6DZZV;GTBDYRGV;9SRCG26Z;4CJRR5C7;DGW81VKS;WREV6DYP;21C89E93;YCYWLZHL;91DBHC62',
      statusContent: 'dot',
      hash: 'GHH1KLWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHH1KLWN'
    },
    {
      status: 210,
      sku: 'PM0085187',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373075',
      rtl_batch_array: '6373075',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 3741,
      chnn_stock_retail: 3741,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: '95X3C1Y2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95X3C1Y2'
    },
    {
      status: 810,
      sku: 'PM0085188',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373076',
      rtl_batch_array: '6373076',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: 'Y96ES529',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y96ES529'
    },
    {
      status: 210,
      sku: 'PM0064731',
      core_name: 'Plant',
      sppl_ean: '8720664878082',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140814',
      rtl_batch_array: '6140814',
      name_search: 'PHSBLEND',
      name: "Phlox (P) 'Sherbet Blend'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V54TRC29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V54TRC29'
    },
    {
      status: 210,
      sku: 'PM0085189',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373077',
      rtl_batch_array: '6373077',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 2554,
      chnn_stock_retail: 2554,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WXJCYRX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXJCYRX6'
    },
    {
      status: 810,
      sku: 'PM0085190',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373078',
      rtl_batch_array: '6373078',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B36D95LR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B36D95LR'
    },
    {
      status: 210,
      sku: 'PM0085191',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373079',
      rtl_batch_array: '6373079',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'W1S6VGZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1S6VGZY'
    },
    {
      status: 910,
      sku: 'PM0085192',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373080',
      rtl_batch_array: '6373080',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 9.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'J4CG8YGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4CG8YGW'
    },
    {
      status: 210,
      sku: 'PM0085193',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373081',
      rtl_batch_array: '6373081',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 949,
      chnn_stock_retail: 949,
      sppl_prcp: 3.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'NTKRAB16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTKRAB16'
    },
    {
      status: 210,
      sku: 'PM0085194',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373082',
      rtl_batch_array: '6373082',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 1748,
      chnn_stock_retail: 1748,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'P1LW6127',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1LW6127'
    },
    {
      status: 210,
      sku: 'PM0085195',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373083',
      rtl_batch_array: '6373083',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1334,
      chnn_stock_retail: 1334,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: '2768G545',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2768G545'
    },
    {
      status: 210,
      sku: 'PM0085196',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373084',
      rtl_batch_array: '6373084',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 437,
      chnn_stock_retail: 437,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: '6ANHTP3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ANHTP3P'
    },
    {
      status: 210,
      sku: 'PM0085197',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373085',
      rtl_batch_array: '6373085',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2843,
      chnn_stock_retail: 2843,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '5T26K6PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T26K6PD'
    },
    {
      status: 210,
      sku: 'PM0046436',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353326',
      rtl_batch_array: '6133628, 6353326',
      name_search: 'SASHBLUE',
      name: "Salvia sylv. 'Heaven in Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 319,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BBL7NABW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBL7NABW'
    },
    {
      status: 910,
      sku: 'PM0046968',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIBNCMIX',
      name: 'Dianthus b. Nana Compacta Mix',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'N2BN2H7K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N2BN2H7K'
    },
    {
      status: 810,
      sku: 'PM0046505',
      core_name: 'Plant',
      sppl_ean: '8720664885738',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133692',
      rtl_batch_array: '6133692, 6353313',
      name_search: 'SERSPARK',
      name: "Sedum 'Red Sparkle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCRVCKPD;NGRR73LL;B3YBDN3J;VN5C48C8;LSTLRCGJ;ELC4PA66;RNR4B41Y',
      statusContent: 'dot',
      hash: '5DLBJ7AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DLBJ7AK'
    },
    {
      status: 210,
      sku: 'PM0047046',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353434',
      rtl_batch_array: '6353434',
      name_search: 'SEMPERVI',
      name: "Sempervivum hybrid in c.v.'s",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7N29XL7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N29XL7W'
    },
    {
      status: 210,
      sku: 'PM0068712',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207816',
      rtl_batch_array: '6207816, 6302376',
      name_search: 'ASBGLETS',
      name: "Aster (D) 'Blauer Gletscher'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1829,
      chnn_stock_retail: 2003,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5EKDK9T;RVGG3V7C;CWHYKVL2;7E7ETXXZ',
      statusContent: 'dot',
      hash: 'JWX55T38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWX55T38'
    },
    {
      status: 210,
      sku: 'PM0064729',
      core_name: 'Plant',
      sppl_ean: '8720664866669',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140812',
      rtl_batch_array: '6140812',
      name_search: 'HEFWHEEL',
      name: "Hemerocallis 'Free Wheelin'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '96AA3VZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96AA3VZ7'
    },
    {
      status: 210,
      sku: 'PM0064728',
      core_name: 'Plant',
      sppl_ean: '8720664877993',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140811',
      rtl_batch_array: '6140811',
      name_search: 'PHMFAVOU',
      name: "Phlox (P) 'Mike's Favourite'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTN5X3DL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTN5X3DL'
    },
    {
      status: 810,
      sku: 'PM0047057',
      core_name: 'Plant',
      sppl_ean: '8720353099118',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6003381',
      rtl_batch_array: '6350159, 6003381',
      name_search: 'EUCHDUMP',
      name: "Euphorbia characias 'Humpty Dumpty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 35,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 1.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDBELAEX;6V52BLY7;45A6PTKN;C2RHP1TA;492VYE4S;AJSP7LNN;L543H7B9;TWGN8THA',
      statusContent: 'dot',
      hash: 'E5Z2LCJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5Z2LCJE'
    },
    {
      status: 210,
      sku: 'PM0064725',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350728',
      rtl_batch_array: '6140347, 6350728, 6383367',
      name_search: 'OPPNIGER',
      name: "Ophiopogon planiscapus 'Niger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 201,
      chnn_stock_retail: 6656,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25R4ACJC;BH5J4TVA;HTWGYL4C;DHA7PWLC;KT7BJXHS;R6SAL88Y;BZ9Y3Y79;Z78DL5DS;Z172P7HE;GC99JDYB;BPNTXA1P;GLZEYVJ8;Z876JDX3;7VPH93ZT;R8HL3A2P;GY1H5XS5;CSESV7VP;NXHY35VC;TZWK5HPE;PEAVW116;G9J6D69D;GPN8ETTH;X33GJ1S2;W9W7VNPW;WNEZ3V7E;GSKAGG83;6RN1EHK9',
      statusContent: 'dot',
      hash: 'SH4ZBTHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SH4ZBTHX'
    },
    {
      status: 210,
      sku: 'PM0068697',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6315042',
      rtl_batch_array: '6315042, 6252843, 6254362, 6333190',
      name_search: 'ASFMOENC',
      name: "Aster frikartii 'M\u00f6nch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2088,
      chnn_stock_retail: 8754,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K1SKNAE4',
      statusContent: 'dot',
      hash: 'W9SL6CGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9SL6CGG'
    },
    {
      status: 210,
      sku: 'PM0032616',
      core_name: 'Plant',
      sppl_ean: '8720349478163',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5964240',
      rtl_batch_array: '5964240, 6252602',
      name_search: 'GECMBRAD',
      name: "Geum chiloense 'Mrs Bradshaw'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1972,
      chnn_stock_retail: 3912,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JK1V7PAW;NH686YWK;X3LGKZJE;LP4JVHYE;WAVDRWCV;9DZBYSZB;A8EB7923;Y2A29GHG;8E6KWPNY;721DDX71;ERH72YT5;9XWAYYKJ;28YT2AX3;T9XTPZ39',
      statusContent: 'dot',
      hash: '9N2H2TNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N2H2TNR'
    },
    {
      status: 210,
      sku: 'PM0046145',
      core_name: 'Plant',
      sppl_ean: '8720664870918',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6164322',
      rtl_batch_array: '6054713, 6164322, 6348349',
      name_search: 'KNMMMIDG',
      name: "Knautia macedonica 'Mars Midget'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 545,
      chnn_stock_retail: 1545,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TW7E4LS3;KS2XVVWH;KEDLTYB3;8ZJX57ZD;PA95A67T',
      statusContent: 'dot',
      hash: 'HA4HLCP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HA4HLCP5'
    },
    {
      status: 210,
      sku: 'PM0053422',
      core_name: 'Plant',
      sppl_ean: '8720664680142',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054362',
      rtl_batch_array: '6054362',
      name_search: 'ALSDRUMM',
      name: "Allium 'Summer Drummer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 469,
      chnn_stock_retail: 469,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWVV43R2;K58GX18E',
      statusContent: 'dot',
      hash: 'KZ7875WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ7875WA'
    },
    {
      status: 210,
      sku: 'PM0066431',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257829',
      rtl_batch_array: '6257829',
      name_search: 'CHPBPLAN',
      name: "Chamaecyparis pisifera 'Blue Planet'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2389,
      chnn_stock_retail: 2389,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A5LXEBHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5LXEBHE'
    },
    {
      status: 210,
      sku: 'PM0053401',
      core_name: 'Plant',
      sppl_ean: '8720664680333',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054327',
      rtl_batch_array: '6054327',
      name_search: 'ALEEMPER',
      name: 'Allium hybrid Early Emperor',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_order_minimum: 3,
      sppl_prcp: 2.169,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WAW9868H;6WJLRGYD;8HDNJ81P;SN2XAJB1;693TE8GH;A28GLNJE;KY1BABAA',
      statusContent: 'dot',
      hash: 'GZYG2J46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZYG2J46'
    },
    {
      status: 210,
      sku: 'PM0047010',
      core_name: 'Plant',
      sppl_ean: '8720664680432',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054358',
      rtl_batch_array: '6054358',
      name_search: 'ALSTIPIT',
      name: 'Allium stipitatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VDK55YW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDK55YW5'
    },
    {
      status: 210,
      sku: 'PM0064736',
      core_name: 'Plant',
      sppl_ean: '8720664878174',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140823',
      rtl_batch_array: '6140823',
      name_search: 'PHBPRIMA',
      name: 'Phlox (P) Bambini Primadonna',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SV378ETT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SV378ETT'
    },
    {
      status: 210,
      sku: 'PM0057543',
      core_name: 'Plant',
      sppl_ean: '8720664881693',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6093724',
      rtl_batch_array: '6093724',
      name_search: 'PRGALBA',
      name: "Prunella grandiflora 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6GTC52N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GTC52N8'
    },
    {
      status: 210,
      sku: 'PM0066439',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184096',
      rtl_batch_array: '6184096',
      name_search: 'PYFIRELI',
      name: "Pyracantha 'Firelight'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DVAX9DNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVAX9DNW'
    },
    {
      status: 210,
      sku: 'PM0069144',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252601',
      rtl_batch_array: '6252601',
      name_search: 'GECLSTRA',
      name: "Geum chiloense 'Lady Stratheden'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2135,
      chnn_stock_retail: 2135,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V13A357L;V4C3T6TB;XGECDJ5W;K49DJAWW;Z1T8LRKK;52P4H4H5;SWVV39CE',
      statusContent: 'dot',
      hash: 'EWGEW91C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWGEW91C'
    },
    {
      status: 210,
      sku: 'PM0070048',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219686',
      rtl_batch_array: '6219686',
      name_search: 'RHFKISSE',
      name: 'Rhodoxis Fairy Kisses',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 3.779,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P6BDET8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6BDET8H'
    },
    {
      status: 210,
      sku: 'PM0070049',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219687',
      rtl_batch_array: '6219687',
      name_search: 'RHFAIRYT',
      name: 'Rhodoxis Fairytale',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 3.779,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9J9J8ET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9J9J8ET'
    },
    {
      status: 210,
      sku: 'PM0081415',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269471',
      rtl_batch_array: '6269471',
      name_search: 'WEFNPURP',
      name: "Weigela florida 'Nana Purpurea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8E4X3P5K;W26V4REV',
      statusContent: 'dot',
      hash: 'VPHC2NJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPHC2NJ5'
    },
    {
      status: 210,
      sku: 'PM0064459',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271430',
      rtl_batch_array: '6271430',
      name_search: 'LIMRPURP',
      name: "Liriope muscari 'Royal Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1190,
      chnn_stock_retail: 1190,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W3S248BN;8N2CWD95;9H9W471D;J5VXH9RL;2G2S2Y4Z;2EWHENLB;2SHZ7RA5;XJGK78BG;KK5N2A12;BAK8CGP8;7X377R9C;2P6S4VVA;2CDKR85G;A4BZ442G;12YV6XXV;CDRL3R2Z',
      statusContent: 'dot',
      hash: 'GNL826TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNL826TY'
    },
    {
      status: 210,
      sku: 'PM0085198',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373086',
      rtl_batch_array: '6373086',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '4SST5HZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SST5HZK'
    },
    {
      status: 210,
      sku: 'PM0064742',
      core_name: 'Plant',
      sppl_ean: '8720664875203',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140829',
      rtl_batch_array: '6140829',
      name_search: 'NEGDDUSK',
      name: "Nepeta grandiflora 'Dawn to Dusk'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYN34PJ3;CSW4JW5Y;W62B94TD',
      statusContent: 'dot',
      hash: 'XT1E1W3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT1E1W3S'
    },
    {
      status: 210,
      sku: 'PM0047181',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382322',
      rtl_batch_array: '6282506, 6242172, 6382322, 6308715',
      name_search: 'PEACASSI',
      name: "Pennisetum alopecuroides 'Cassian'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2640,
      chnn_stock_retail: 6749,
      sppl_order_minimum: 3,
      sppl_prcp: 0.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5E34TGNR;CP3TBSRW',
      statusContent: 'dot',
      hash: 'DYWC9NHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYWC9NHK'
    },
    {
      status: 210,
      sku: 'PM0077940',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307034',
      rtl_batch_array: '6307034',
      name_search: 'HEGORME',
      name: "Hebe 'Great Orme'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1455,
      chnn_stock_retail: 1455,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '817AGT2S;2JP22AVS;22YNRY15;RKT1GNNE;RS2S6DH8;6GYYD7D5;PLACK4R9;WG42WBV4;9879C3A4',
      statusContent: 'dot',
      hash: 'HSSE745R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSSE745R'
    },
    {
      status: 210,
      sku: 'PM0047123',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220858',
      rtl_batch_array: '6220858',
      name_search: 'MISRIGOL',
      name: "Miscanthus sinensis 'Rigoletto'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '47CYXXZV',
      statusContent: 'dot',
      hash: 'NRJEVS5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRJEVS5X'
    },
    {
      status: 210,
      sku: 'PM0081416',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269878',
      rtl_batch_array: '6269878',
      name_search: 'CAPLVARI',
      name: "Campanula poscharskyana 'Lisduggan Variety'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1412,
      chnn_stock_retail: 1412,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7W537D2R;2A9K48CK;12WZC96C;N4PLXC4K;KYWHL87X;Z1NLWPSA',
      statusContent: 'dot',
      hash: 'EBPEDAW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBPEDAW1'
    },
    {
      status: 210,
      sku: 'PM0047127',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382296',
      rtl_batch_array: '6301723, 6014167, 6382296',
      name_search: 'MOASKYRA',
      name: "Molinia arundinacea 'Skyracer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2700,
      chnn_stock_retail: 3324,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W1Y7PXL9;WGBPZDJZ;SKYKN8P6;1Y89EDZL;GN6S2XY1',
      statusContent: 'dot',
      hash: 'R7AEPEZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7AEPEZN'
    },
    {
      status: 210,
      sku: 'PM0019454',
      core_name: 'Plant',
      btch_manufacturer: 89,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193870',
      rtl_batch_array: '6193870',
      name_search: 'GAMUCRON',
      name: 'Gaultheria mucronata',
      sppl_size_code: '010015P10',
      rng_range_identifier: 'H010015P10',
      rng_range_description: 'H10 cm 15 cm P10',
      sppl_stock_available: 50000,
      chnn_stock_retail: 50000,
      sppl_prcp: 2.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H9KW94HE;SWDVTV9W;Y85XA82Y;SHAPZ8VR;G6RSPXJK;K1WJPPXL;VATW737P;K3KGJL1L;P9SCLHXS;NC3E7BJC;7RA3D8J7;YKTATAD1;H6BALCYW;48SVYS77;BT7L71LX;1P4T71N1;4CEB2KEK',
      statusContent: 'dot',
      hash: 'Y5V37R1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5V37R1A'
    },
    {
      status: 210,
      sku: 'PM0077941',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307035',
      rtl_batch_array: '6307035',
      name_search: 'HEGORME',
      name: "Hebe 'Great Orme'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 386,
      chnn_stock_retail: 386,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '817AGT2S;2JP22AVS;22YNRY15;RKT1GNNE;RS2S6DH8;6GYYD7D5;PLACK4R9;WG42WBV4;9879C3A4',
      statusContent: 'dot',
      hash: 'KPK819TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPK819TB'
    },
    {
      status: 210,
      sku: 'PM0064756',
      core_name: 'Plant',
      sppl_ean: '8720664876170',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140843',
      rtl_batch_array: '6140843',
      name_search: 'PAPSPLEN',
      name: "Paeonia (I) 'Pastel Splendor'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZGGAXAYP;XBKDBEGW;VWYLTDTR;7P6DZTSC',
      statusContent: 'dot',
      hash: 'PDBAWCXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDBAWCXN'
    },
    {
      status: 210,
      sku: 'PM0054580',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217691',
      rtl_batch_array: '6217691',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 3.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'XYYX72ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYYX72ZY'
    },
    {
      status: 210,
      sku: 'PM0048612',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272023',
      rtl_batch_array: '6272023',
      name_search: 'CEHKGOLD',
      name: "Cephalotaxus harringtonii 'Korean Gold'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1058,
      chnn_stock_retail: 1058,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KVTSY5HK;JHHGT1DD',
      statusContent: 'dot',
      hash: 'ZV3ZRCZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV3ZRCZ3'
    },
    {
      status: 210,
      sku: 'PM0064739',
      core_name: 'Plant',
      sppl_ean: '8720664877719',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140826',
      rtl_batch_array: '6140826',
      name_search: 'PHJULIA',
      name: "Phlox 'Julia'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJK2JT8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJK2JT8H'
    },
    {
      status: 210,
      sku: 'PM0081417',
      core_name: 'Plant',
      sppl_ean: '8720349411573',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5393823',
      rtl_batch_array: '5393823',
      name_search: 'CALLANNA',
      name: "Campanula lactiflora 'Loddon Anna'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 8031,
      chnn_stock_retail: 8031,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLGR2TLV;TXSRV1Y8;E4ETRD3J;5YPPKE9L',
      imageBatch: '76XV9N51',
      statusContent: 'dot',
      hash: 'PS8E6TSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PS8E6TSA'
    },
    {
      status: 210,
      sku: 'PM0064761',
      core_name: 'Plant',
      sppl_ean: '8720664850392',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140849',
      rtl_batch_array: '6140849',
      name_search: 'ACMMRRED',
      name: 'Achillea millefolium Milly Rock Red',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4VXEVEKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VXEVEKY'
    },
    {
      status: 210,
      sku: 'PM0053473',
      core_name: 'Plant',
      sppl_ean: '8720664686847',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054583',
      rtl_batch_array: '6054583',
      name_search: 'DEHORANG',
      name: 'Delosperma HP Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 385,
      chnn_stock_retail: 385,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZAWE987R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAWE987R'
    },
    {
      status: 210,
      sku: 'PM0077942',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307036',
      rtl_batch_array: '6307036',
      name_search: 'HEGORME',
      name: "Hebe 'Great Orme'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 387,
      chnn_stock_retail: 387,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '817AGT2S;2JP22AVS;22YNRY15;RKT1GNNE;RS2S6DH8;6GYYD7D5;PLACK4R9;WG42WBV4;9879C3A4',
      statusContent: 'dot',
      hash: '1JH14E8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JH14E8E'
    },
    {
      status: 210,
      sku: 'PM0081418',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383820',
      rtl_batch_array: '6383820',
      name_search: 'EUPBBLUE',
      name: "Eucalyptus pulverulenta 'Baby Blue'",
      sppl_size_code: 'GMC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1Y44EB1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y44EB1X'
    },
    {
      status: 210,
      sku: 'PM0047144',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333284',
      rtl_batch_array: '6133411, 6333284',
      name_search: 'NEGDDUSK',
      name: "Nepeta grandiflora 'Dawn to Dusk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 984,
      chnn_stock_retail: 1147,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYN34PJ3;CSW4JW5Y;W62B94TD',
      statusContent: 'dot',
      hash: 'Y26LJT1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y26LJT1C'
    },
    {
      status: 210,
      sku: 'PM0077943',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307037',
      rtl_batch_array: '6307037',
      name_search: 'HEBE',
      name: "Hebe in cv's",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CY9TXKT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CY9TXKT2'
    },
    {
      status: 210,
      sku: 'PM0047155',
      core_name: 'Plant',
      sppl_ean: '8720664588202',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014243',
      rtl_batch_array: '6014243',
      name_search: 'OMASBLUE',
      name: "Omphalodes 'All Summer Blues'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 528,
      chnn_stock_retail: 528,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V242RP9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V242RP9H'
    },
    {
      status: 210,
      sku: 'PM0047129',
      core_name: 'Plant',
      sppl_ean: '8720349498130',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015450',
      rtl_batch_array: '6015450',
      name_search: 'MOCTORCH',
      name: "Molinia caerulea 'Torch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 738,
      chnn_stock_retail: 738,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ENVDAWRT;J74K399E;74K6DC37;DYYXZZ7N;T3VAHES2;ER5WPDT8;RK6SZRPZ',
      statusContent: 'dot',
      hash: 'GJ17XGAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJ17XGAA'
    },
    {
      status: 210,
      sku: 'PM0081419',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314914',
      rtl_batch_array: '6314914',
      name_search: 'LOPWBEAU',
      name: "Lonicera purpusii 'Winter Beauty'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_prcp: 5.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AGVLPZXY',
      statusContent: 'dot',
      hash: 'GL4AC7TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GL4AC7TY'
    },
    {
      status: 210,
      sku: 'PM0077944',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307038',
      rtl_batch_array: '6307038',
      name_search: 'HEBE',
      name: "Hebe in cv's",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 2.121,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '24AL83RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24AL83RC'
    },
    {
      status: 910,
      sku: 'PM0048252',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDI75093',
      name: "Chionodoxa luciliae 'Violet Beauty'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'SPDVDTCR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SPDVDTCR'
    },
    {
      status: 210,
      sku: 'PM0064747',
      core_name: 'Plant',
      sppl_ean: '8720664878129',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140834',
      rtl_batch_array: '6140834',
      name_search: 'PHSDAIQU',
      name: "Phlox (P) 'Strawberry Daiquiri'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RW633RAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW633RAC'
    },
    {
      status: 210,
      sku: 'PM0064758',
      core_name: 'Plant',
      sppl_ean: '8720664864870',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140845',
      rtl_batch_array: '6140845',
      name_search: 'GEPRAVEN',
      name: "Geranium phaeum 'Raven'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L88D4L2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L88D4L2C'
    },
    {
      status: 210,
      sku: 'PM0077950',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307044',
      rtl_batch_array: '6307044',
      name_search: 'HEWJOY',
      name: "Hebe 'Wiri Joy'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZPC9241X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPC9241X'
    },
    {
      status: 910,
      sku: 'PM0046585',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAIMMACU',
      name: "Paeonia (LD) 'Immacul\u00e9e'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '7XNAG533',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7XNAG533'
    },
    {
      status: 810,
      sku: 'PM0067387',
      core_name: 'Plant',
      sppl_ean: '8720349406906',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4252331',
      rtl_batch_array: '4252331',
      name_search: 'SENITIDA',
      name: 'Sesleria nitida',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5LPJJSLN;VS4KE7DB',
      imageBatch: 'RD7LBJ67',
      statusContent: 'dot',
      hash: 'XEJN78S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEJN78S8'
    },
    {
      status: 210,
      sku: 'PM0077945',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307039',
      rtl_batch_array: '6307039',
      name_search: 'HEMWINDE',
      name: "Hebe 'Mrs Winder'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 868,
      chnn_stock_retail: 868,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XDCXWHT;CN9X1CLJ;5X5P9A55;K1CH4TL9;JH9G37Y4;96TTR931',
      statusContent: 'dot',
      hash: '9SBKAZ31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SBKAZ31'
    },
    {
      status: 210,
      sku: 'PM0077946',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307040',
      rtl_batch_array: '6307040',
      name_search: 'HEREDGE',
      name: "Hebe 'Red Edge'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3291,
      chnn_stock_retail: 3291,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6ZZSKXH;7LJTJYEW;TGSTWGGG;S3LJYXZW;8WBY4J4G;L2GGXHV6;G4YSBYTE;16GTYBJ2;LW426RZE;NZRE28PL;JT7CBSY3;NVY8ACEN;WC51ANHS',
      statusContent: 'dot',
      hash: 'VHXVWHEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHXVWHEY'
    },
    {
      status: 210,
      sku: 'PM0047109',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339938',
      rtl_batch_array: '6301807, 6339938',
      name_search: 'SAGALIPS',
      name: "Salvia greggii 'Amethyst Lips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 524,
      chnn_stock_retail: 680,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JT36YKC;N3DZ2VVS;5W1X2ZSE;1X1ASKSA',
      statusContent: 'dot',
      hash: '5YC24V9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YC24V9V'
    },
    {
      status: 210,
      sku: 'PM0078053',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307355',
      rtl_batch_array: '6307355',
      name_search: 'CLVITICE',
      name: 'Clematis viticella',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RDEAHC1L;PJV55C61;BR134GES;WGYSDVH9',
      statusContent: 'dot',
      hash: 'Z24CW6GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z24CW6GX'
    },
    {
      status: 810,
      sku: 'PM0074252',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268779',
      rtl_batch_array: '6268779',
      name_search: 'IRSPURIA',
      name: 'Iris spuria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XP2VALAS',
      statusContent: 'dot',
      hash: 'R31SAK8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R31SAK8C'
    },
    {
      status: 210,
      sku: 'PM0085199',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373087',
      rtl_batch_array: '6373087',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'R1552P2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1552P2T'
    },
    {
      status: 210,
      sku: 'PM0047143',
      core_name: 'Plant',
      sppl_ean: '8720626313385',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014219',
      rtl_batch_array: '6014219',
      name_search: 'NEHGROUN',
      name: "Nepeta 'Hill Grounds'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1160,
      chnn_stock_retail: 1160,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RTGTWWSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTGTWWSP'
    },
    {
      status: 210,
      sku: 'PM0018460',
      core_name: 'Plant',
      sppl_ean: '8720664881563',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133559',
      rtl_batch_array: '6133559, 6339902',
      name_search: 'PONMWILL',
      name: "Potentilla nepalensis 'Miss Willmott'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 386,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2ZWVBKXD;BY3N2XL4;SV9ALRDH;WDHG54BL;CETE2W8P;ZGN8X319;W3BPCWDK;8P8GWST8;8EJZVZE7;1AV1457P;E7SA6L77;YPHG34CC;XE6KKB8Z;5TNAXGPB',
      statusContent: 'dot',
      hash: '52PG5K8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52PG5K8Z'
    },
    {
      status: 210,
      sku: 'PM0085200',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373088',
      rtl_batch_array: '6373088',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1310,
      chnn_stock_retail: 1310,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: '4GSNWWND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GSNWWND'
    },
    {
      status: 910,
      sku: 'PM0081422',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIMGRAVE',
      name: "Vinca minor 'La Grave'",
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      statusContent: 'dot',
      hash: 'C96CN4X4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C96CN4X4'
    },
    {
      status: 210,
      sku: 'PM0064759',
      core_name: 'Plant',
      sppl_ean: '8720664878099',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140847',
      rtl_batch_array: '6140847',
      name_search: 'PHSMOKEY',
      name: "Phlox (P) 'Smokey'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXR6YA1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXR6YA1R'
    },
    {
      status: 210,
      sku: 'PM0064755',
      core_name: 'Plant',
      sppl_ean: '8720664866775',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140842',
      rtl_batch_array: '6140842',
      name_search: 'HEJHEART',
      name: "Hemerocallis 'Jolly Hearts'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7SAEZEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7SAEZEW'
    },
    {
      status: 210,
      sku: 'PM0033296',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353353',
      rtl_batch_array: '6353353',
      name_search: 'SACLSILV',
      name: "Santolina chamaecyparissus 'Lambrook Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 648,
      chnn_stock_retail: 648,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WYH5GG1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYH5GG1Z'
    },
    {
      status: 210,
      sku: 'PM0078054',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307358',
      rtl_batch_array: '6307358',
      name_search: 'CLWBEAUT',
      name: "Clematis 'Winter Beauty'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 457,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 4.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXZD3WBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXZD3WBW'
    },
    {
      status: 210,
      sku: 'PM0064453',
      core_name: 'Plant',
      sppl_ean: '8720664869677',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151577',
      rtl_batch_array: '6151577',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 2350,
      chnn_stock_retail: 2350,
      sppl_prcp: 3.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: '25C6AWPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25C6AWPR'
    },
    {
      status: 810,
      sku: 'PM0065922',
      core_name: 'Plant',
      sppl_ean: '8720664868670',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169542',
      rtl_batch_array: '6169542',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'BP9S4WR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BP9S4WR8'
    },
    {
      status: 210,
      sku: 'PM0046295',
      core_name: 'Plant',
      sppl_ean: '8720664801066',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015434',
      rtl_batch_array: '6015434',
      name_search: 'PEAMAGIC',
      name: "Pennisetum alopecuroides 'Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C21H56V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C21H56V5'
    },
    {
      status: 210,
      sku: 'PM0064751',
      core_name: 'Plant',
      sppl_ean: '8720664877740',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140838',
      rtl_batch_array: '6140838',
      name_search: 'PHPTWIST',
      name: "Phlox 'Peppermint Twist'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1Z6NDHZ7',
      statusContent: 'dot',
      hash: 'RZCKNN83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZCKNN83'
    },
    {
      status: 210,
      sku: 'PM0066467',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 5,
      btch_active_retail: '6184040',
      rtl_batch_array: '6184040, 6318829, 6294793, 6300742, 6338101',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 496,
      chnn_stock_retail: 10811,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'HEP7LBPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEP7LBPB'
    },
    {
      status: 910,
      sku: 'PM0066460',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUFDBLAN',
      name: "Euonymus fortunei 'Dart's Blanket'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      statusContent: 'dot',
      hash: '1RBJET9N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1RBJET9N'
    },
    {
      status: 210,
      sku: 'PM0077949',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307043',
      rtl_batch_array: '6307043',
      name_search: 'HEREDGE',
      name: "Hebe 'Red Edge'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 926,
      chnn_stock_retail: 926,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6ZZSKXH;7LJTJYEW;TGSTWGGG;S3LJYXZW;8WBY4J4G;L2GGXHV6;G4YSBYTE;16GTYBJ2;LW426RZE;NZRE28PL;JT7CBSY3;NVY8ACEN;WC51ANHS',
      statusContent: 'dot',
      hash: '5V4YE1EW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V4YE1EW'
    },
    {
      status: 210,
      sku: 'PM0077947',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307041',
      rtl_batch_array: '6307041',
      name_search: 'HEREDGE',
      name: "Hebe 'Red Edge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1501,
      chnn_stock_retail: 1501,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6ZZSKXH;7LJTJYEW;TGSTWGGG;S3LJYXZW;8WBY4J4G;L2GGXHV6;G4YSBYTE;16GTYBJ2;LW426RZE;NZRE28PL;JT7CBSY3;NVY8ACEN;WC51ANHS',
      statusContent: 'dot',
      hash: 'JT1CHLL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT1CHLL9'
    },
    {
      status: 210,
      sku: 'PM0077948',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307042',
      rtl_batch_array: '6307042',
      name_search: 'HEREDGE',
      name: "Hebe 'Red Edge'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2373,
      chnn_stock_retail: 2373,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6ZZSKXH;7LJTJYEW;TGSTWGGG;S3LJYXZW;8WBY4J4G;L2GGXHV6;G4YSBYTE;16GTYBJ2;LW426RZE;NZRE28PL;JT7CBSY3;NVY8ACEN;WC51ANHS',
      statusContent: 'dot',
      hash: '4TA394G8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TA394G8'
    },
    {
      status: 210,
      sku: 'PM0053471',
      core_name: 'Plant',
      sppl_ean: '8720664860575',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147591',
      rtl_batch_array: '6147591',
      name_search: 'ECPGREEN',
      name: "Echinacea 'Papillon Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V2TASTE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2TASTE3'
    },
    {
      status: 210,
      sku: 'PM0081423',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '5486246',
      rtl_batch_array: '5486246',
      name_search: 'EUFHBLAZ',
      name: "Euonymus fortunei 'Hort's Blaze'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XL7BNR1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL7BNR1K'
    },
    {
      status: 210,
      sku: 'PM0081424',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '5486248',
      rtl_batch_array: '5486248',
      name_search: 'EUFDBLAN',
      name: "Euonymus fortunei 'Dart's Blanket'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'HRVL5R38',
      statusContent: 'dot',
      hash: 'CDH261D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDH261D7'
    },
    {
      status: 210,
      sku: 'PM0046146',
      core_name: 'Plant',
      sppl_ean: '8720626355903',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364269',
      rtl_batch_array: '5364269, 6383360, 6348350',
      name_search: 'KNMMPAST',
      name: "Knautia macedonica 'Melton Pastels'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 661,
      chnn_stock_retail: 2890,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EBBPLBVY;YYDA599B;ZZDTJNA6;WH7ALTB4;P6J5E5EL;P9TPZJRW;CJE6BZ5L;KCPRSLAN',
      statusContent: 'dot',
      hash: 'NJNK8SE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJNK8SE6'
    },
    {
      status: 210,
      sku: 'PM0081425',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292270',
      rtl_batch_array: '6292270',
      name_search: 'THOHOLMS',
      name: "Thuja occidentalis 'Holmstrup'",
      sppl_size_code: '060070C34',
      rng_range_identifier: 'H060070C3.5',
      rng_range_description: 'H60 cm 70 cm C3.5',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EAEJ5B1W;CAYBZVRJ;V6KAA8ZL;5EJBPPDR;6AX7SCLH;R8GHXD4W;W1PYZBGY;TPVBJTCD;EN1VBWS9;78ZD4G6G;JH8WC5N8',
      statusContent: 'dot',
      hash: 'YX8V9TV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YX8V9TV2'
    },
    {
      status: 210,
      sku: 'PM0047594',
      core_name: 'Plant',
      sppl_ean: '8720349411887',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953536',
      rtl_batch_array: '5953536',
      name_search: 'CAMAUREO',
      name: "Carex morrowii 'Aureovariegata'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1130,
      chnn_stock_retail: 1130,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G56L7E27;C6Z1SRPH',
      statusContent: 'dot',
      hash: 'YR3A8VEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YR3A8VEB'
    },
    {
      status: 210,
      sku: 'PM0081426',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301351',
      rtl_batch_array: '6301351',
      name_search: 'GALFWHIT',
      name: "Gaura lindh. 'Flamingo White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJVEK1DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJVEK1DJ'
    },
    {
      status: 210,
      sku: 'PM0081427',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295714',
      rtl_batch_array: '6295714',
      name_search: 'ASASTARS',
      name: "Aster ageratoides 'Starshine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4277,
      chnn_stock_retail: 4277,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C8Y7C6P9;8128C2VH;12XY9NRD;RD1DGS69;DDK72YT6',
      statusContent: 'dot',
      hash: 'R9WXSP1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9WXSP1J'
    },
    {
      status: 210,
      sku: 'PM0068759',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6374029',
      rtl_batch_array: '6208043, 6374029',
      name_search: 'MECAMBRI',
      name: 'Meconopsis cambrica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 132,
      chnn_stock_retail: 248,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NW8DZK8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW8DZK8C'
    },
    {
      status: 810,
      sku: 'PM0074251',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268778',
      rtl_batch_array: '6268778',
      name_search: 'IRSRFLAR',
      name: "Iris sibirica 'Red Flare'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPE1RW1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPE1RW1W'
    },
    {
      status: 210,
      sku: 'PM0064750',
      core_name: 'Plant',
      sppl_ean: '8720664877917',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140837',
      rtl_batch_array: '6140837',
      name_search: 'PHEUROPA',
      name: "Phlox (P) 'Europa'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NB8EJR8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB8EJR8T'
    },
    {
      status: 210,
      sku: 'PM0047111',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363197',
      rtl_batch_array: '6242184, 6363197',
      name_search: 'SAMGLETS',
      name: "Salvia microphylla 'Gletsjer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 996,
      chnn_stock_retail: 1328,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H47LNX7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H47LNX7T'
    },
    {
      status: 210,
      sku: 'PM0063577',
      core_name: 'Plant',
      sppl_ean: '8720664869462',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147178',
      rtl_batch_array: '6147178, 6315052',
      name_search: 'IBSSCHNE',
      name: "Iberis sempervirens 'Schneeflocke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 3989,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5JEHKWG4;TRX86EW1;E6BE45TP;4JRYN835;DVZNYCPW',
      statusContent: 'dot',
      hash: 'TJ11GPA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJ11GPA9'
    },
    {
      status: 810,
      sku: 'PM0058245',
      core_name: 'Plant',
      sppl_ean: '8720664869264',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112471',
      rtl_batch_array: '6112471',
      name_search: 'HYDCANAD',
      name: 'Hydrophyllum canadense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6HXZYCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6HXZYCJ'
    },
    {
      status: 210,
      sku: 'PM0057425',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349146',
      rtl_batch_array: '6349146, 6202335',
      name_search: 'SANSDROS',
      name: 'Salvia nemorosa Sensation Deep Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7450,
      chnn_stock_retail: 7717,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SLEGRPZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLEGRPZ8'
    },
    {
      status: 210,
      sku: 'PM0058324',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6192816',
      rtl_batch_array: '6192816, 6301696',
      name_search: 'LIMLWOND',
      name: "Liriope muscari 'Lilac Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1213,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WAWRG6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WAWRG6L'
    },
    {
      status: 210,
      sku: 'PM0047141',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386145',
      rtl_batch_array: '6133409, 6386145',
      name_search: 'NEFLORIN',
      name: "Nepeta 'Florina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 304,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67ZXS3EB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67ZXS3EB'
    },
    {
      status: 210,
      sku: 'PM0047079',
      core_name: 'Plant',
      sppl_ean: '8720664872134',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112563',
      rtl_batch_array: '6112563, 6301688',
      name_search: 'LESSUP',
      name: "Leucanthemum (S) 'Sunny Side Up'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 258,
      chnn_stock_retail: 326,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZAXAR81;KZKXGJB4;WLKCR1E7;97DTBG7J;8J82VYRW;L676Z67B;GLVLX59N',
      statusContent: 'dot',
      hash: '45KSRB89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45KSRB89'
    },
    {
      status: 210,
      sku: 'PM0054585',
      core_name: 'Plant',
      sppl_ean: '8720664890343',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6174172',
      rtl_batch_array: '6079485, 6174172',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 297,
      chnn_stock_retail: 1147,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: 'EN7R581P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EN7R581P'
    },
    {
      status: 210,
      sku: 'PM0063555',
      core_name: 'Plant',
      sppl_ean: '8720664887763',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146819',
      rtl_batch_array: '6146819',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 17920,
      chnn_stock_retail: 17920,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '8X2JV9JW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8X2JV9JW'
    },
    {
      status: 810,
      sku: 'PM0047084',
      core_name: 'Plant',
      sppl_ean: '8720664872417',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161818',
      rtl_batch_array: '6161818, 6301692',
      name_search: 'LIBGRAND',
      name: 'Libertia grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RLY4VSNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLY4VSNR'
    },
    {
      status: 210,
      sku: 'PM0064745',
      core_name: 'Plant',
      sppl_ean: '8720664866966',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140832',
      rtl_batch_array: '6140832',
      name_search: 'HEPURPLE',
      name: "Hemerocallis 'Purplelicious'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WXH76VBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXH76VBJ'
    },
    {
      status: 210,
      sku: 'PM0008723',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382283',
      rtl_batch_array: '6193081, 6054791, 6382283',
      name_search: 'LIRSPICA',
      name: 'Liriope spicata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4750,
      chnn_stock_retail: 6881,
      sppl_order_minimum: 3,
      sppl_prcp: 0.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2PYZ78G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2PYZ78G'
    },
    {
      status: 210,
      sku: 'PM0068757',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208031',
      rtl_batch_array: '6208031',
      name_search: 'LIMWWIDE',
      name: "Liriope muscari 'Webster Wideleaf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DD6E2R37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD6E2R37'
    },
    {
      status: 810,
      sku: 'PM0081428',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302481',
      rtl_batch_array: '6302481',
      name_search: 'ECORSKIP',
      name: 'Echinacea Orange Skipper',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_order_minimum: 3,
      sppl_prcp: 2.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EL1V9H21',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EL1V9H21'
    },
    {
      status: 210,
      sku: 'PM0081429',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302540',
      rtl_batch_array: '6302540',
      name_search: 'GESANDRI',
      name: "Geranium hybrid 'Sandrine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GX5DSL2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GX5DSL2S'
    },
    {
      status: 210,
      sku: 'PM0085201',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373089',
      rtl_batch_array: '6373089',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'R2CK7P1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2CK7P1J'
    },
    {
      status: 210,
      sku: 'PM0081431',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334475',
      rtl_batch_array: '6334475, 6348806',
      name_search: 'HYMPIA',
      name: "Hydrangea macrophylla 'Pia'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A65SRCXT;E8CTSJ1E;6CENRYDW',
      statusContent: 'dot',
      hash: '58W66RRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58W66RRR'
    },
    {
      status: 210,
      sku: 'PM0071050',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6243699',
      rtl_batch_array: '6274210, 6243699',
      name_search: 'ANHHJOBE',
      name: "Anemone hybrida 'Honorine Jobert'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 13147,
      chnn_stock_retail: 13647,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2J5SXH1;YKNJ7TZ1;4ETXSNCC;WC36SR2J;WVEGZW26;AXVRKPX2;8BZR9YJB;RSCKAH9Z;YBKSLZRK;8DH2V7SA;X6H88CAY;BD76SGZT;X95H1D2Y;DE3EEBSW;S9DBWLDV',
      statusContent: 'dot',
      hash: 'CP6XX31C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP6XX31C'
    },
    {
      status: 210,
      sku: 'PM0081432',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334477',
      rtl_batch_array: '6334477',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'K19SWEVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K19SWEVV'
    },
    {
      status: 210,
      sku: 'PM0081433',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334482',
      rtl_batch_array: '6334482',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '010020P9',
      rng_range_identifier: 'H010020P9',
      rng_range_description: 'H10 cm 20 cm P9',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_order_minimum: 8,
      sppl_prcp: 0.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'K956BK2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K956BK2C'
    },
    {
      status: 210,
      sku: 'PM0081434',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334486',
      rtl_batch_array: '6334486',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '020040C2',
      rng_range_identifier: 'H020040C2',
      rng_range_description: 'H20 cm 40 cm C2',
      sppl_stock_available: 6572,
      chnn_stock_retail: 6572,
      sppl_prcp: 1.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '22CWVV98',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22CWVV98'
    },
    {
      status: 210,
      sku: 'PM0081435',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334491',
      rtl_batch_array: '6334491',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_prcp: 2.842,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'TTW7SLNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTW7SLNB'
    },
    {
      status: 210,
      sku: 'PM0081436',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334495',
      rtl_batch_array: '6334495',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 7491,
      chnn_stock_retail: 7491,
      sppl_prcp: 1.666,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'AVPDWDTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVPDWDTW'
    },
    {
      status: 210,
      sku: 'PM0081437',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334496',
      rtl_batch_array: '6334496',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '040060P9',
      rng_range_identifier: 'H040060P9',
      rng_range_description: 'H40 cm 60 cm P9',
      sppl_stock_available: 9900,
      chnn_stock_retail: 9900,
      sppl_order_minimum: 6,
      sppl_prcp: 0.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'WC1LEND6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC1LEND6'
    },
    {
      status: 910,
      sku: 'PM0066543',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287262',
      rtl_batch_array: '6287262',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 61.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'WTSGTEGX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTSGTEGX'
    },
    {
      status: 210,
      sku: 'PM0081438',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334497',
      rtl_batch_array: '6334497',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 7247,
      chnn_stock_retail: 7247,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: '8N5YTP6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8N5YTP6L'
    },
    {
      status: 210,
      sku: 'PM0081439',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334498',
      rtl_batch_array: '6334498',
      name_search: 'SAROSMAR',
      name: 'Salix rosmarinifolia',
      sppl_size_code: '050080C14',
      rng_range_identifier: 'H050080C1.5',
      rng_range_description: 'H50 cm 80 cm C1.5',
      sppl_stock_available: 6418,
      chnn_stock_retail: 6418,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EZVB3GYK;NRAT57N3;HSV6SWAA;BNZ5AZJC;CRJR24D3',
      statusContent: 'dot',
      hash: 'HVWBZSAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVWBZSAD'
    },
    {
      status: 210,
      sku: 'PM0064762',
      core_name: 'Plant',
      sppl_ean: '8720664878150',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140850',
      rtl_batch_array: '6140850',
      name_search: 'PHBCCRUS',
      name: 'Phlox (P) Bambini Candy Crush',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26K3AEH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26K3AEH6'
    },
    {
      status: 210,
      sku: 'PM0081440',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334503',
      rtl_batch_array: '6334503',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060070C74',
      rng_range_identifier: 'H060070C7.5',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_prcp: 6.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'Z35TE6SJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z35TE6SJ'
    },
    {
      status: 810,
      sku: 'PM0081441',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346672',
      rtl_batch_array: '6346672',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 39.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'KT92NBZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KT92NBZ4'
    },
    {
      status: 910,
      sku: 'PM0081442',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ARELATA',
      name: 'Aralia elata',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      imageCore: 'NKRE4AD2',
      statusContent: 'dot',
      hash: 'WVA8V5W2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WVA8V5W2'
    },
    {
      status: 910,
      sku: 'PM0081443',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ARELATA',
      name: 'Aralia elata',
      rng_range_identifier: 'H150175C45',
      rng_range_description: 'H150 cm 175 cm C45',
      imageCore: 'NKRE4AD2',
      statusContent: 'dot',
      hash: 'S2K7VLR3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S2K7VLR3'
    },
    {
      status: 210,
      sku: 'PM0058235',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305176',
      rtl_batch_array: '6112456, 6305176',
      name_search: 'HOQJOSEP',
      name: "Hosta 'Queen Josephine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 364,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H1962AT3',
      statusContent: 'dot',
      hash: 'SBD4TR24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBD4TR24'
    },
    {
      status: 210,
      sku: 'PM0064744',
      core_name: 'Plant',
      sppl_ean: '8720664878006',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140831',
      rtl_batch_array: '6140831',
      name_search: 'PHMHOLLA',
      name: "Phlox (P) 'Miss Holland'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G5VL6B1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5VL6B1T'
    },
    {
      status: 810,
      sku: 'PM0065921',
      core_name: 'Plant',
      sppl_ean: '8720664867154',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169541',
      rtl_batch_array: '6169541',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: '77TCLS5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77TCLS5G'
    },
    {
      status: 810,
      sku: 'PM0066440',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184099',
      rtl_batch_array: '6184099',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 7.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: '9TZ865JJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TZ865JJ'
    },
    {
      status: 210,
      sku: 'PM0064746',
      core_name: 'Plant',
      sppl_ean: '8720664866683',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140833',
      rtl_batch_array: '6140833',
      name_search: 'HEGSCOUT',
      name: "Hemerocallis 'Girl Scout'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZB36SKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZB36SKR'
    },
    {
      status: 210,
      sku: 'PM0064737',
      core_name: 'Plant',
      sppl_ean: '8720664877986',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140824',
      rtl_batch_array: '6140824',
      name_search: 'PHLIZZY',
      name: "Phlox (P) 'Lizzy'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G191YDNS',
      statusContent: 'dot',
      hash: 'YK3EJDD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YK3EJDD7'
    },
    {
      status: 210,
      sku: 'PM0064740',
      core_name: 'Plant',
      sppl_ean: '8720664878167',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140827',
      rtl_batch_array: '6140827',
      name_search: 'PHBDESIR',
      name: 'Phlox (P) Bambini Desire',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'STV62KRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STV62KRZ'
    },
    {
      status: 210,
      sku: 'PM0047126',
      core_name: 'Plant',
      sppl_ean: '8720664699649',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014165',
      rtl_batch_array: '6014165',
      name_search: 'MOAFONTA',
      name: "Molinia arundinacea 'Font\u00e4ne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GWDC7ZPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWDC7ZPA'
    },
    {
      status: 210,
      sku: 'PM0064749',
      core_name: 'Plant',
      sppl_ean: '8720664877887',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140836',
      rtl_batch_array: '6140836',
      name_search: 'PHBEYE',
      name: "Phlox (P) 'Brilliant Eye'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZWX48K1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWX48K1B'
    },
    {
      status: 810,
      sku: 'PM0066538',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307455',
      rtl_batch_array: '6307455, 6287258',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 36,
      chnn_stock_retail: 45,
      sppl_prcp: 34.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: '8SCJBX2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SCJBX2P'
    },
    {
      status: 210,
      sku: 'PM0071049',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223363',
      rtl_batch_array: '6223363',
      name_search: 'ACKELLER',
      name: 'Achillea kellereri',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1494,
      chnn_stock_retail: 1494,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VHBZ7577',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHBZ7577'
    },
    {
      status: 810,
      sku: 'PM0058321',
      core_name: 'Plant',
      sppl_ean: '8720664872882',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112576',
      rtl_batch_array: '6112576',
      name_search: 'LICANDID',
      name: 'Lilium candidum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_order_minimum: 3,
      sppl_prcp: 3.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JG61LZ57;W1Y2V3BJ;XKPBYLGA;K9JCXXSV;GJXB282G',
      statusContent: 'dot',
      hash: 'HEALCT11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEALCT11'
    },
    {
      status: 210,
      sku: 'PM0039760',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254251',
      rtl_batch_array: '6254251',
      name_search: 'COSERICE',
      name: 'Cornus sericea',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 4908,
      chnn_stock_retail: 4908,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZZAAW336',
      statusContent: 'dot',
      hash: 'VA473CWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VA473CWH'
    },
    {
      status: 210,
      sku: 'PM0005970',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356497',
      rtl_batch_array: '5356497, 6336318',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 4572,
      sppl_prcp: 5.865,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      imageBatch: 'D6EY86GA',
      statusContent: 'dot',
      hash: 'W8LY7YE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8LY7YE6'
    },
    {
      status: 210,
      sku: 'PM0053437',
      core_name: 'Plant',
      sppl_ean: '8720353087771',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054453',
      rtl_batch_array: '6054453',
      name_search: 'ASCCANDY',
      name: "Astilbe (A) 'Cotton Candy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XRTAA485',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRTAA485'
    },
    {
      status: 910,
      sku: 'PM0081444',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307281',
      rtl_batch_array: '6307281',
      name_search: 'ARELATA',
      name: 'Aralia elata',
      sppl_size_code: '200250C50',
      rng_range_identifier: 'H200250C50',
      rng_range_description: 'H200 cm 250 cm C50',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKRE4AD2',
      statusContent: 'dot',
      hash: 'KZ9XDSRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ9XDSRK'
    },
    {
      status: 810,
      sku: 'PM0081445',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307283',
      rtl_batch_array: '6307283',
      name_search: 'ARUNEDO',
      name: 'Arbutus unedo',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 8.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DSDKCNZG;59W87SK6',
      statusContent: 'dot',
      hash: '56G84D1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56G84D1D'
    },
    {
      status: 210,
      sku: 'PM0085202',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373090',
      rtl_batch_array: '6373090',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 4555,
      chnn_stock_retail: 4555,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: '5TJV3GW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TJV3GW6'
    },
    {
      status: 210,
      sku: 'PM0066483',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184110',
      rtl_batch_array: '6184110',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2997,
      chnn_stock_retail: 2997,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: '2XT5J76N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XT5J76N'
    },
    {
      status: 810,
      sku: 'PM0081447',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363977',
      rtl_batch_array: '6363977',
      name_search: 'CLETRICH',
      name: 'Clerodendrum trichotomum',
      sppl_size_code: 'GMC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 16.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DEHYXGRA;4YVC7JC5;E42VHS1D',
      statusContent: 'dot',
      hash: 'ADJ1KDD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADJ1KDD5'
    },
    {
      status: 210,
      sku: 'PM0039662',
      core_name: 'Plant',
      sppl_ean: '8720664683051',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905500',
      rtl_batch_array: '5905500',
      name_search: 'CALAMAGR',
      name: 'Calamagrostis',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 723,
      chnn_stock_retail: 723,
      sppl_prcp: 6.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWP5L6CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWP5L6CR'
    },
    {
      status: 810,
      sku: 'PM0081448',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363978',
      rtl_batch_array: '6363978',
      name_search: 'CLTFARGE',
      name: 'Clerodendrum trichotomum fargesii',
      sppl_size_code: 'GMC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 16.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JK8X6N3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JK8X6N3A'
    },
    {
      status: 210,
      sku: 'PM0047235',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307849',
      rtl_batch_array: '6307849',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 635,
      chnn_stock_retail: 635,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: '6JEW8RP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JEW8RP5'
    },
    {
      status: 210,
      sku: 'PM0066454',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184015',
      rtl_batch_array: '6184015',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: 'CKCVHRTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKCVHRTA'
    },
    {
      status: 910,
      sku: 'PM0081449',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      rng_range_identifier: 'H060070C3',
      rng_range_description: 'H60 cm 70 cm C3',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'WZKVXDAV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WZKVXDAV'
    },
    {
      status: 210,
      sku: 'PM0085203',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373091',
      rtl_batch_array: '6373091',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: '172SJATB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '172SJATB'
    },
    {
      status: 210,
      sku: 'PM0058346',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208040',
      rtl_batch_array: '6208040, 6301086',
      name_search: 'LYSRGEM',
      name: "Lythrum salicaria 'Rosy Gem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 426,
      chnn_stock_retail: 10690,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5WERBBAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WERBBAP'
    },
    {
      status: 210,
      sku: 'PM0070053',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266262',
      rtl_batch_array: '6266262',
      name_search: 'SAGBBLUE',
      name: "Salvia guaranitica 'Black and Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 785,
      chnn_stock_retail: 785,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GWD8RVC7;H1VCYBNJ;P57WD5W1;CERCAL5P;WW5Z7AW3;WH5S3C61;N4W73NNC;9PWPB5TZ;17HVCJ63;GAAX7CWH;7YPW3D9G',
      statusContent: 'dot',
      hash: 'T24T8L7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T24T8L7K'
    },
    {
      status: 210,
      sku: 'PM0064467',
      core_name: 'Plant',
      sppl_ean: '8720664879515',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5400062',
      rtl_batch_array: '5400062, 6304787',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 482,
      chnn_stock_retail: 7703,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      imageBatch: 'KSVTDJ64',
      statusContent: 'dot',
      hash: '2Z828GYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Z828GYP'
    },
    {
      status: 210,
      sku: 'PM0046375',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348298',
      rtl_batch_array: '6037871, 6317989, 6348298',
      name_search: 'COCANADE',
      name: 'Cornus canadensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2982,
      chnn_stock_retail: 4578,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DA1NWV7N;P9NKX3RS',
      statusContent: 'dot',
      hash: 'BVDLJJ76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVDLJJ76'
    },
    {
      status: 810,
      sku: 'PM0066553',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161515',
      rtl_batch_array: '6161515',
      name_search: 'ALRDROSE',
      name: "Alcea rosea 'Double Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5TW2L34C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TW2L34C'
    },
    {
      status: 210,
      sku: 'PM0065486',
      core_name: 'Plant',
      sppl_ean: '8720664878075',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164538',
      rtl_batch_array: '6164538',
      name_search: 'PHRIJNST',
      name: "Phlox (P) 'Rijnstroom'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWBVZ9CA;KLC441KK',
      statusContent: 'dot',
      hash: 'JDN2YDGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDN2YDGH'
    },
    {
      status: 210,
      sku: 'PM0066554',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161519',
      rtl_batch_array: '6161519',
      name_search: 'ALRPOLAR',
      name: "Alcea rosea 'Polarstar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 341,
      chnn_stock_retail: 341,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GTTR5Z2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTTR5Z2L'
    },
    {
      status: 210,
      sku: 'PM0022859',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6231158',
      rtl_batch_array: '6231158, 6077453, 6054411, 6333189',
      name_search: 'ASDIVARI',
      name: 'Aster divaricatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 22597,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JVRPGXXG',
      statusContent: 'dot',
      hash: 'TELJ92XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TELJ92XY'
    },
    {
      status: 210,
      sku: 'PM0068707',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207804',
      rtl_batch_array: '6207804',
      name_search: 'ANLILIAG',
      name: 'Anthericum liliago',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1242,
      chnn_stock_retail: 1242,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58SB8P4K',
      statusContent: 'dot',
      hash: 'BZ4ZY5V6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ4ZY5V6'
    },
    {
      status: 810,
      sku: 'PM0068721',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207846',
      rtl_batch_array: '6207846',
      name_search: 'DISSESSI',
      name: 'Disporum sessile',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VH6714R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VH6714R7'
    },
    {
      status: 210,
      sku: 'PM0085204',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373092',
      rtl_batch_array: '6373092',
      name_search: 'COAMIRAC',
      name: 'Cornus alba Miracle',
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 3.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X58NVLR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X58NVLR1'
    },
    {
      status: 910,
      sku: 'PM0081450',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUJAPONI',
      name: 'Euonymus japonicus',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore:
        'HB4P35YR;LPP1YVTR;2JRL3BYR;CZPTWYNZ;9CJRABCH;J1R4ES31;KCATGG38;N8TNHKK6;786WHLE8;2GTB4JD5;V5J616XS;7VP6BCA5;ND8B1HCZ',
      statusContent: 'dot',
      hash: '7WPY5TXY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7WPY5TXY'
    },
    {
      status: 210,
      sku: 'PM0081451',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363980',
      rtl_batch_array: '6363980',
      name_search: 'FATJAPON',
      name: 'Fatsia japonica',
      sppl_size_code: 'GMC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_prcp: 16.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RSC48GZ;R5SVETV2;49WXNLEH;NAPTC6TV;BR2DPDZE;H4CHEYKL;GEPH7HV3;8PSCVAB6;CH5TD35H;RDK4EXYN;6TX92Y4T;1NB9EV7C;YWTA9LTT;H96S38ZG;2DPPH8G6;52AYEX2C;AJ6CK65G;SDSSNPWV;7Z6RYRPY;JJ7K336S;42JXK8ZT;RVR78BZ6;E45K1DJ9;72RDDVZ6',
      statusContent: 'dot',
      hash: '2YG48WJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YG48WJS'
    },
    {
      status: 910,
      sku: 'PM0081452',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307485',
      rtl_batch_array: '6307485',
      name_search: 'HYMESUMM',
      name: 'Hydrangea macrophylla Endless Summer',
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 11.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55KJ1VVB;ZW88Z6P9;V29K9DLC;DSXXHSCY;R37D5EJK;7W6495T9;CCJ7ZVYY;ZTLG22LZ;N7VKA175',
      statusContent: 'dot',
      hash: '4GNJE3YE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GNJE3YE'
    },
    {
      status: 210,
      sku: 'PM0081453',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307486',
      rtl_batch_array: '6307486',
      name_search: 'HYMESUMM',
      name: 'Hydrangea macrophylla Endless Summer',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_prcp: 8.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55KJ1VVB;ZW88Z6P9;V29K9DLC;DSXXHSCY;R37D5EJK;7W6495T9;CCJ7ZVYY;ZTLG22LZ;N7VKA175',
      statusContent: 'dot',
      hash: '64N2XBY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64N2XBY6'
    },
    {
      status: 210,
      sku: 'PM0085205',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373093',
      rtl_batch_array: '6373093',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 3228,
      chnn_stock_retail: 3228,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: '12ZYPVGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12ZYPVGK'
    },
    {
      status: 210,
      sku: 'PM0085206',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373094',
      rtl_batch_array: '6373094',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 2088,
      chnn_stock_retail: 2088,
      sppl_prcp: 9.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'W8TZ79VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8TZ79VT'
    },
    {
      status: 210,
      sku: 'PM0085207',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373095',
      rtl_batch_array: '6373095',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 502,
      chnn_stock_retail: 502,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: '4X4KA5XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4X4KA5XE'
    },
    {
      status: 210,
      sku: 'PM0057476',
      core_name: 'Plant',
      sppl_ean: '8720664887466',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133756',
      rtl_batch_array: '6133756',
      name_search: 'SYGHBLUE',
      name: "Symphytum grandiflorum 'Hidcote Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1868,
      chnn_stock_retail: 1868,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1EVL2LTP',
      statusContent: 'dot',
      hash: 'G38LJVRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G38LJVRV'
    },
    {
      status: 210,
      sku: 'PM0021194',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353496',
      rtl_batch_array: '6133853, 6353496',
      name_search: 'VISDFREC',
      name: "Viola sororia 'Dark Freckles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1397,
      chnn_stock_retail: 1462,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RSVRH1JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSVRH1JE'
    },
    {
      status: 210,
      sku: 'PM0081457',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283848',
      rtl_batch_array: '6283848',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'SKDV7Z56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKDV7Z56'
    },
    {
      status: 810,
      sku: 'PM0033559',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291895',
      rtl_batch_array: '6291895',
      name_search: 'GESAPFEL',
      name: "Geranium sanguineum 'Apfelblute'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3TZSPCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3TZSPCX'
    },
    {
      status: 210,
      sku: 'PM0081458',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358879',
      rtl_batch_array: '6358879',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 856,
      chnn_stock_retail: 856,
      sppl_prcp: 13.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: '75H9AE7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75H9AE7Y'
    },
    {
      status: 210,
      sku: 'PM0033629',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272783',
      rtl_batch_array: '6272783',
      name_search: 'ASNBCBRO',
      name: "Aster novi-belgii 'Crimson Brocade'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VXZ1B3YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXZ1B3YX'
    },
    {
      status: 910,
      sku: 'PM0081459',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'HDPNX9T3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HDPNX9T3'
    },
    {
      status: 910,
      sku: 'PM0081460',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'STE080C4',
      rng_range_description: 'Stem 80 cm C4',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: '74N86EAX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '74N86EAX'
    },
    {
      status: 810,
      sku: 'PM0064549',
      core_name: 'Plant',
      sppl_ean: '8720664886308',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6156136',
      rtl_batch_array: '6156136',
      name_search: 'SIPERFOL',
      name: 'Silphium perfoliatum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2DVCGLT;K6214W5R',
      statusContent: 'dot',
      hash: 'ZVLC398K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVLC398K'
    },
    {
      status: 910,
      sku: 'PM0081461',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'STE100C4',
      rng_range_description: 'Stem 100 cm C4',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'PH59LD36',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PH59LD36'
    },
    {
      status: 910,
      sku: 'PM0068765',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDI63952',
      name: "Oxalis deppei 'Iron Cross'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '942AC88L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '942AC88L'
    },
    {
      status: 210,
      sku: 'PM0058341',
      core_name: 'Plant',
      sppl_ean: '8720664873643',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112615',
      rtl_batch_array: '6112615',
      name_search: 'LYFCWROB',
      name: "Lychnis flos-cuculi 'White Robin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 218,
      chnn_stock_retail: 218,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2ZWSEBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2ZWSEBD'
    },
    {
      status: 810,
      sku: 'PM0054592',
      core_name: 'Plant',
      sppl_ean: '8720664880399',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079498',
      rtl_batch_array: '6079498',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '010012C2',
      rng_range_identifier: 'H010012C2',
      rng_range_description: 'H10 cm 12 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'XGAX2R7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGAX2R7K'
    },
    {
      status: 210,
      sku: 'PM0066557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161911',
      rtl_batch_array: '6161911',
      name_search: 'PRAABLOS',
      name: "Primula allionii 'Apple Blossom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 565,
      chnn_stock_retail: 565,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VCDKB941',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCDKB941'
    },
    {
      status: 210,
      sku: 'PM0066555',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161563',
      rtl_batch_array: '6229876, 6161563',
      name_search: 'ASWGLORI',
      name: "Astilbe (A) 'Weisse Gloria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 883,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BETAV425',
      statusContent: 'dot',
      hash: 'BZ5JPGKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ5JPGKH'
    },
    {
      status: 210,
      sku: 'PM0074254',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268784',
      rtl_batch_array: '6268784',
      name_search: 'MOBMARAM',
      name: "Monarda bradburiana 'Maramek'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 381,
      chnn_stock_retail: 381,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CYRPT5CS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYRPT5CS'
    },
    {
      status: 210,
      sku: 'PM0068764',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208057',
      rtl_batch_array: '6208057',
      name_search: 'OPPOLIVA',
      name: "Ophiopogon plan. 'Olivaceus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2404,
      chnn_stock_retail: 2404,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z7H5CEVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7H5CEVY'
    },
    {
      status: 210,
      sku: 'PM0068766',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268806',
      rtl_batch_array: '6268806, 6352661',
      name_search: 'PAMACROP',
      name: 'Pachyphragma macrophyllum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2960,
      chnn_stock_retail: 3545,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4S352S63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S352S63'
    },
    {
      status: 910,
      sku: 'PM0068767',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAQUADRI',
      name: 'Paris quadrifolia',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'XDPRDVA8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XDPRDVA8'
    },
    {
      status: 910,
      sku: 'PM0081462',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'STE175C7.5',
      rng_range_description: 'Stem 175 cm C7.5',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'C3V1E7L7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C3V1E7L7'
    },
    {
      status: 910,
      sku: 'PM0066448',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: 'VSSLACKJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VSSLACKJ'
    },
    {
      status: 210,
      sku: 'PM0068768',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208067',
      rtl_batch_array: '6208067',
      name_search: 'PEMMASQU',
      name: "Penstemon 'Midnight Masquerade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A3K4XX59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3K4XX59'
    },
    {
      status: 210,
      sku: 'PM0070052',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272796',
      rtl_batch_array: '6272796',
      name_search: 'SAFORRER',
      name: 'Salvia forreri',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '266RSS7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '266RSS7D'
    },
    {
      status: 210,
      sku: 'PM0063578',
      core_name: 'Plant',
      sppl_ean: '8720664875753',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147184',
      rtl_batch_array: '6147184, 6307048',
      name_search: 'ORVAUREU',
      name: "Origanum vulgare 'Aureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 754,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9Z6ZTW5;7BSCXSSP;DZ2H6H9L;JT1V1JYL;CNWWLL2T;5CAATDLV;4CR7W1J9;Y1C3RDB2',
      statusContent: 'dot',
      hash: '7PE165EH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PE165EH'
    },
    {
      status: 810,
      sku: 'PM0069153',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234789',
      rtl_batch_array: '6234789',
      name_search: 'HEFRILLY',
      name: "Heuchera 'Frilly'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4NHKHJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4NHKHJD'
    },
    {
      status: 210,
      sku: 'PM0085208',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373096',
      rtl_batch_array: '6373096',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 591,
      chnn_stock_retail: 591,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'A7S4H987',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7S4H987'
    },
    {
      status: 210,
      sku: 'PM0070054',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272799',
      rtl_batch_array: '6272799',
      name_search: 'SAJROSEA',
      name: "Salvia jurisicii 'Rosea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 346,
      chnn_stock_retail: 346,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HX7D7EPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX7D7EPC'
    },
    {
      status: 210,
      sku: 'PM0071054',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223373',
      rtl_batch_array: '6223373',
      name_search: 'TRORIENT',
      name: 'Trachystemon orientalis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 509,
      chnn_stock_retail: 509,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WHBCVEZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHBCVEZ7'
    },
    {
      status: 210,
      sku: 'PM0064561',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6177631',
      rtl_batch_array: '6192692, 6177631, 6291339, 6336463',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3500,
      chnn_stock_retail: 10488,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      statusContent: 'dot',
      hash: 'A85BVXWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A85BVXWB'
    },
    {
      status: 210,
      sku: 'PM0070055',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6243117',
      rtl_batch_array: '6243117, 6334338',
      name_search: 'SAMBMONR',
      name: "Salvia microphylla 'Blue Monrovia'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1886,
      chnn_stock_retail: 1980,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BH7SWG77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BH7SWG77'
    },
    {
      status: 210,
      sku: 'PM0069154',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254753',
      rtl_batch_array: '6254753',
      name_search: 'HEMIRACL',
      name: "Heuchera 'Miracle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 392,
      chnn_stock_retail: 392,
      sppl_prcp: 3.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJRG7L4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJRG7L4C'
    },
    {
      status: 210,
      sku: 'PM0085209',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373097',
      rtl_batch_array: '6373097',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: '6XL6C5C5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XL6C5C5'
    },
    {
      status: 810,
      sku: 'PM0081465',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307823',
      rtl_batch_array: '6307823',
      name_search: 'FAMWBLAC',
      name: "Fargesia murieliae 'Winter Black'",
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 21.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KK1X3TG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KK1X3TG4'
    },
    {
      status: 810,
      sku: 'PM0081466',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307824',
      rtl_batch_array: '6307824',
      name_search: 'FAMWBLAC',
      name: "Fargesia murieliae 'Winter Black'",
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_prcp: 23.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NS1J3ZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NS1J3ZN'
    },
    {
      status: 210,
      sku: 'PM0081467',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307825',
      rtl_batch_array: '6307825',
      name_search: 'FAMWBLAC',
      name: "Fargesia murieliae 'Winter Black'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 25.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZZLY1HK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZZLY1HK'
    },
    {
      status: 210,
      sku: 'PM0063580',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6231164',
      rtl_batch_array: '6188634, 6231164, 6338961, 6348998',
      name_search: 'TISSYMPH',
      name: "Tiarella 'Spring Symphony'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 3796,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D827D52R;ZYYT378B;LKNCAWVB;CBT6ZS1D;PXGJRACV;9JHGN9LT;Z9BVGW86;4JTCK77A;76VPP85N;4WNGP8JH;KE4A2D83;2G5HH2BH;D13C2TCG',
      statusContent: 'dot',
      hash: 'D3PPG28Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3PPG28Y'
    },
    {
      status: 210,
      sku: 'PM0081468',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307826',
      rtl_batch_array: '6307826',
      name_search: 'FAMWBLAC',
      name: "Fargesia murieliae 'Winter Black'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 27.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C94T3TP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C94T3TP9'
    },
    {
      status: 210,
      sku: 'PM0081469',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307846',
      rtl_batch_array: '6307846',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 25.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: 'YP8Z568H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YP8Z568H'
    },
    {
      status: 810,
      sku: 'PM0081470',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307847',
      rtl_batch_array: '6307847',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 27.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: 'TW8XVCJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TW8XVCJP'
    },
    {
      status: 210,
      sku: 'PM0081471',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309062',
      rtl_batch_array: '6309062',
      name_search: 'EUJJHUGU',
      name: "Euonymus japonicus 'Jean Hugues'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 11.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y55TVJYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y55TVJYR'
    },
    {
      status: 210,
      sku: 'PM0081472',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309065',
      rtl_batch_array: '6309065',
      name_search: 'LESCARLE',
      name: 'Leucothoe Scarletta',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 975,
      chnn_stock_retail: 975,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KADNDBWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KADNDBWK'
    },
    {
      status: 210,
      sku: 'PM0081473',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309069',
      rtl_batch_array: '6309069',
      name_search: 'PIJPASSI',
      name: "Pieris japonica 'Passion'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 16.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ALXRATH;R666VY63;V44EW5A5;LPXRZVCH;546NDJ9C;8TESAHAG;YAKSWRCE;6ZCXYLK6;T2GKESYC;62EKEJDT;WTKKCPT4;BS457Y7P;VHEV4JRY;4HKHN85H;S8SDZG3T;PW6LHC45',
      statusContent: 'dot',
      hash: 'ARAD6EG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARAD6EG6'
    },
    {
      status: 210,
      sku: 'PM0081474',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309070',
      rtl_batch_array: '6309070',
      name_search: 'PIEJPPAS',
      name: 'Pieris japonica Polar Passion',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CJ7CHTPY',
      statusContent: 'dot',
      hash: 'ARZWKRAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARZWKRAP'
    },
    {
      status: 210,
      sku: 'PM0085210',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373098',
      rtl_batch_array: '6373098',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2751,
      chnn_stock_retail: 2751,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'EHSPX95R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHSPX95R'
    },
    {
      status: 210,
      sku: 'PM0068763',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242970',
      rtl_batch_array: '6350727, 6242970',
      name_search: 'OPJMINOR',
      name: "Ophiopogon japonicus 'Minor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2419,
      chnn_stock_retail: 2740,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L9AKTYT1',
      statusContent: 'dot',
      hash: '7KPJ2E9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KPJ2E9A'
    },
    {
      status: 210,
      sku: 'PM0033614',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6276627',
      rtl_batch_array: '5837393, 6251048, 6276627',
      name_search: 'ARPCASTL',
      name: "Artemisia 'Powis Castle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2030,
      chnn_stock_retail: 2484,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '42P8BHC5;YB9AYJWE;BCYNKNPC;7CZ6TSXH;WSAEXT48;B6P136D7;ALZEVC8L;KJDCWGKZ;G5WCXB53;56EWJ2CB;ECXD4DB6;841TD52X;CRCNG4ZC;J193V2X1;EHRERWCA;BE5TGWE6',
      statusContent: 'dot',
      hash: 'WXNXXE3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXNXXE3K'
    },
    {
      status: 910,
      sku: 'PM0033642',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASRHEINL',
      name: "Astilbe (J) 'Rheinland'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'JWG53GAA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JWG53GAA'
    },
    {
      status: 210,
      sku: 'PM0081476',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349562',
      rtl_batch_array: '6349562, 6319682',
      name_search: 'AMTSALIC',
      name: 'Amsonia tabernaemontana salicifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2449,
      chnn_stock_retail: 2529,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2W5Z2KZR',
      statusContent: 'dot',
      hash: 'LSAB4WH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSAB4WH7'
    },
    {
      status: 910,
      sku: 'PM0047204',
      core_name: 'Plant',
      sppl_ean: '8720349438754',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5931816',
      rtl_batch_array: '5931816',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 35.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: '83LD889G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83LD889G'
    },
    {
      status: 210,
      sku: 'PM0081477',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319700',
      rtl_batch_array: '6350263, 6319700',
      name_search: 'GEPJULIA',
      name: "Geum 'Prinses Juliana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W3EAVCR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3EAVCR5'
    },
    {
      status: 810,
      sku: 'PM0081478',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319701',
      rtl_batch_array: '6319701',
      name_search: 'GERFPASS',
      name: "Geum rivale 'Flames of Passion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9B5HGANK;S1WD1ZGY;C9P7XNG7;EHADB9XK;ZRSSLEDB',
      statusContent: 'dot',
      hash: '9N51NPPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N51NPPS'
    },
    {
      status: 210,
      sku: 'PM0027491',
      core_name: 'Plant',
      sppl_ean: '8720626376908',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '5592135',
      rtl_batch_array: '5592135, 6274527',
      name_search: 'ASSPRITE',
      name: "Astilbe (S) 'Sprite'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 6710,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TNKKXZC5;VCD2ZEYW',
      imageBatch: 'PGHWVHHX',
      statusContent: 'dot',
      hash: 'KC98LW49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KC98LW49'
    },
    {
      status: 810,
      sku: 'PM0081479',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319710',
      rtl_batch_array: '6319710',
      name_search: 'HESCHWEF',
      name: "Helichrysum 'Schwefellicht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NRTGC2LP;56RZELBC',
      statusContent: 'dot',
      hash: '85DLBPD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85DLBPD7'
    },
    {
      status: 210,
      sku: 'PM0033638',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252463',
      rtl_batch_array: '5849624, 6252463',
      name_search: 'ASBRAUTS',
      name: "Astilbe (A) 'Brautschleier'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1011,
      chnn_stock_retail: 1796,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KG4BSX6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG4BSX6V'
    },
    {
      status: 210,
      sku: 'PM0048155',
      core_name: 'Plant',
      sppl_ean: '8720353011950',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014412',
      rtl_batch_array: '6014412',
      name_search: 'ASBPEARL',
      name: 'Astilbe Black Pearls',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LX22LLGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LX22LLGG'
    },
    {
      status: 210,
      sku: 'PM0081480',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319715',
      rtl_batch_array: '6319715',
      name_search: 'MOFMENTH',
      name: 'Monarda fistulosa menthifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6R5WAPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6R5WAPB'
    },
    {
      status: 210,
      sku: 'PM0054600',
      core_name: 'Plant',
      sppl_ean: '8720664890268',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079509',
      rtl_batch_array: '6079509',
      name_search: 'WATERNAT',
      name: 'Waldsteinia ternata',
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 542,
      chnn_stock_retail: 542,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GGTG67JR;JH3S8P8S;WWLKCH3X;CNZ8KPNP;WK3VV735;BTYE2CC8;PKLJ2LZB;86NNZJZJ',
      statusContent: 'dot',
      hash: 'PCH39GPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCH39GPC'
    },
    {
      status: 210,
      sku: 'PM0081481',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319716',
      rtl_batch_array: '6319716',
      name_search: 'MOKARDIN',
      name: "Monarda 'Kardinal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 316,
      chnn_stock_retail: 316,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZPB3XW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZPB3XW9'
    },
    {
      status: 810,
      sku: 'PM0000053',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271435',
      rtl_batch_array: '6271435',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'DTN61V5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTN61V5S'
    },
    {
      status: 810,
      sku: 'PM0081482',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319720',
      rtl_batch_array: '6386151, 6319720',
      name_search: 'NESSACHA',
      name: "Nepeta sibirica 'Souvenir d'Andr\u00e9 Chaudron'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 376,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBVVBL41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBVVBL41'
    },
    {
      status: 210,
      sku: 'PM0071051',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223365',
      rtl_batch_array: '6223365',
      name_search: 'ANSYLVES',
      name: 'Anemone sylvestris',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 510,
      chnn_stock_retail: 510,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDNPY5NV;RYXR445X;1C56H6XT;BA9YNSV8;EKHSHDER;WPL8BCE9;PBPPDXDY;LHT9SD2D;JKYBBLDE',
      statusContent: 'dot',
      hash: '88JC5BXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88JC5BXZ'
    },
    {
      status: 210,
      sku: 'PM0081483',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6386152',
      rtl_batch_array: '6383365, 6386152, 6319721',
      name_search: 'NESSDREA',
      name: "Nepeta subsessilis 'Sweet Dreams'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 595,
      chnn_stock_retail: 998,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7PZY21LZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PZY21LZ'
    },
    {
      status: 210,
      sku: 'PM0047184',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301765',
      rtl_batch_array: '6301765, 6015437',
      name_search: 'PEAPIGLE',
      name: "Pennisetum alopecuroides 'Piglet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 810,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8P2PWP5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8P2PWP5P'
    },
    {
      status: 210,
      sku: 'PM0081484',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319722',
      rtl_batch_array: '6319722',
      name_search: 'OEBIENNI',
      name: 'Oenothera biennis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7P92W1JD;HCLJJ66X;Y5H89HYJ',
      statusContent: 'dot',
      hash: 'DEEWD3DT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEEWD3DT'
    },
    {
      status: 210,
      sku: 'PM0081485',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319723',
      rtl_batch_array: '6319723',
      name_search: 'ORVTVARI',
      name: "Origanum vulgare 'Thumble's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWHRBCBC',
      statusContent: 'dot',
      hash: 'PYHLA3XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYHLA3XY'
    },
    {
      status: 210,
      sku: 'PM0033951',
      core_name: 'Plant',
      sppl_ean: '8720349447947',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6058233',
      rtl_batch_array: '6058233, 6254684',
      name_search: 'CAGRAYI',
      name: 'Carex grayi',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1334,
      chnn_stock_retail: 1463,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CL7ELY6X;1JA53KZS;HWD343T1;ZLJK4X5C;ZWJ5A69N;NANZD1L1;EBCD1PVW;GAT5Y7Z2;BE1Z47BK;LKVACE45;K9SSW437;W3GE4ZN8;61B7DVB3;K69CLEBR;LH39JBG6;EXX7P8RL',
      statusContent: 'dot',
      hash: '2BJ353CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BJ353CR'
    },
    {
      status: 210,
      sku: 'PM0081486',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386161',
      rtl_batch_array: '6386161, 6319727',
      name_search: 'PHALPHA',
      name: "Phlox (M) 'Alpha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YELNAAWG',
      statusContent: 'dot',
      hash: 'X9665NEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9665NEC'
    },
    {
      status: 810,
      sku: 'PM0081487',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319730',
      rtl_batch_array: '6319730',
      name_search: 'PHKING',
      name: "Phlox (P) 'The King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4G924C3',
      statusContent: 'dot',
      hash: 'THTBTZZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THTBTZZB'
    },
    {
      status: 210,
      sku: 'PM0047193',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353391',
      rtl_batch_array: '6353391',
      name_search: 'THPURPLE',
      name: "Thalictrum 'Purplelicious'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 934,
      chnn_stock_retail: 934,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BEE3B1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BEE3B1D'
    },
    {
      status: 810,
      sku: 'PM0081488',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319731',
      rtl_batch_array: '6319731',
      name_search: 'PHUTOPIA',
      name: "Phlox (P) 'Utopia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 58,
      chnn_stock_retail: 58,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CAKRV25J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAKRV25J'
    },
    {
      status: 210,
      sku: 'PM0058345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208037',
      rtl_batch_array: '6208037, 6302773',
      name_search: 'LYSMPINK',
      name: "Lythrum salicaria 'Morden Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TY5BNJV1;56T8TCYH',
      statusContent: 'dot',
      hash: 'W2CLZ6V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2CLZ6V2'
    },
    {
      status: 210,
      sku: 'PM0081489',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349086',
      rtl_batch_array: '6349086, 6319732',
      name_search: 'PHVALBA',
      name: "Physostegia virginiana 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2120,
      chnn_stock_retail: 2399,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J5453TB3;ZGKWTLKW;NY9N4K12;SSD3XTPW;J2ZTXXHY;B65ED9NH;AX98YVNW;N5AJBKT3;ALGYD2KA;YRJSW15D;2CPAHLBZ;RHKLKWE9;PLX8KEDP;NDD5LGAJ;72HEP2ND;16SPTV6S',
      statusContent: 'dot',
      hash: 'PG7EDN74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PG7EDN74'
    },
    {
      status: 810,
      sku: 'PM0081490',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319739',
      rtl_batch_array: '6319739',
      name_search: 'SAOCRISP',
      name: "Salvia officinalis 'Crispa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XASZXSAH;GD67SK3D;PLLKXPTK;EWGXDZTH;L34BLK6C;2VGWVLPE',
      statusContent: 'dot',
      hash: 'LB36B5R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB36B5R5'
    },
    {
      status: 210,
      sku: 'PM0081491',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348963',
      rtl_batch_array: '6369747, 6319740, 6348963',
      name_search: 'IBMASTER',
      name: "Iberis 'Masterpiece'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 696,
      chnn_stock_retail: 1412,
      sppl_order_minimum: 3,
      sppl_prcp: 0.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RP6KDPRV;ATXXVJ4Z',
      statusContent: 'dot',
      hash: 'ZTCH1CSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTCH1CSW'
    },
    {
      status: 210,
      sku: 'PM0085211',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373099',
      rtl_batch_array: '6373099',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 5677,
      chnn_stock_retail: 5677,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: '6AZLH7WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6AZLH7WA'
    },
    {
      status: 210,
      sku: 'PM0068769',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208068',
      rtl_batch_array: '6208068, 6291730',
      name_search: 'PEALBLUE',
      name: 'Perovskia atriplicifolia Lacey Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 709,
      chnn_stock_retail: 1196,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEJ7ZK7G',
      statusContent: 'dot',
      hash: 'A9JS4W6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9JS4W6S'
    },
    {
      status: 210,
      sku: 'PM0058347',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208041',
      rtl_batch_array: '6208041',
      name_search: 'LYSBEACO',
      name: "Lythrum salicaria 'The Beacon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 266,
      chnn_stock_retail: 266,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44HNREA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44HNREA2'
    },
    {
      status: 210,
      sku: 'PM0071053',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223367',
      rtl_batch_array: '6223367',
      name_search: 'DIBBSALM',
      name: "Dianthus barbatus 'Barbarini Salmon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1264,
      chnn_stock_retail: 1264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJ2B1WDA;KP43WE2B;CDT68YXD',
      statusContent: 'dot',
      hash: '4LBLK9GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LBLK9GD'
    },
    {
      status: 210,
      sku: 'PM0054603',
      core_name: 'Plant',
      sppl_ean: '8720664877658',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079972',
      rtl_batch_array: '6079972',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A23N6KBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A23N6KBE'
    },
    {
      status: 210,
      sku: 'PM0011013',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298690',
      rtl_batch_array: '6298690',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'GNWPJYKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNWPJYKJ'
    },
    {
      status: 210,
      sku: 'PM0071058',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243715',
      rtl_batch_array: '6243715',
      name_search: 'PEAMPLEX',
      name: 'Persicaria amplexicaulis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 11877,
      chnn_stock_retail: 11877,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KG2H81CK;V5P722P1;18NBHG9T;S36JV4GX;X1TV7BKY;C2DS4669;K12YL938;6DJDY2AS;5KDJJNBG;XSTEGW9V;X6XE3BWN;THXAB7DT;WJL4TWKW;HKDNXX3Y;GZVH5EWY;7DNDHWK1',
      statusContent: 'dot',
      hash: '9SKZ4J4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SKZ4J4Z'
    },
    {
      status: 210,
      sku: 'PM0033724',
      core_name: 'Plant',
      sppl_ean: '8720626350335',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849741',
      rtl_batch_array: '5849741',
      name_search: 'HEWISLAN',
      name: "Hemerocallis 'Wassaw Island'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JH6KSSGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH6KSSGA'
    },
    {
      status: 210,
      sku: 'PM0074255',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268786',
      rtl_batch_array: '6268786',
      name_search: 'MISCONDE',
      name: 'Miscanthus sinensis condensatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 289,
      chnn_stock_retail: 289,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXCC531P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXCC531P'
    },
    {
      status: 210,
      sku: 'PM0048133',
      core_name: 'Plant',
      sppl_ean: '8720664679863',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014364',
      rtl_batch_array: '6014364',
      name_search: 'AJRSPLUM',
      name: 'Ajuga reptans Sugar Plum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JWVW5JY3',
      statusContent: 'dot',
      hash: '7G1L7T25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7G1L7T25'
    },
    {
      status: 210,
      sku: 'PM0021505',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284482',
      rtl_batch_array: '6311301, 6284482',
      name_search: 'COSSKOGH',
      name: "Cotoneaster suecicus 'Skogholm'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4250,
      chnn_stock_retail: 9910,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BECWJ1C1;771X3AR6;AZXP97EK;59KPW61B;K3XE5X71;JRAZNJ4S;866VZR8D;57JY8B2H;9HT2131W;TNB3WRSA;TG15Y59H;6WSAWK8Z',
      statusContent: 'dot',
      hash: '6JPRAYLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JPRAYLY'
    },
    {
      status: 910,
      sku: 'PM0066485',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: 'DPGVSKCW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DPGVSKCW'
    },
    {
      status: 210,
      sku: 'PM0081492',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319741',
      rtl_batch_array: '6319741',
      name_search: 'KAMANTON',
      name: "Kalimeris mongolica 'Antonia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSDA6SC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSDA6SC4'
    },
    {
      status: 210,
      sku: 'PM0085212',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373102',
      rtl_batch_array: '6373102',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 1521,
      chnn_stock_retail: 1521,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '4HSSE5CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HSSE5CR'
    },
    {
      status: 210,
      sku: 'PM0048629',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253104',
      rtl_batch_array: '6253104',
      name_search: 'HEMWINDE',
      name: "Hebe 'Mrs Winder'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1181,
      chnn_stock_retail: 1181,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XDCXWHT;CN9X1CLJ;5X5P9A55;K1CH4TL9;JH9G37Y4;96TTR931',
      statusContent: 'dot',
      hash: '4PY3KGBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PY3KGBZ'
    },
    {
      status: 210,
      sku: 'PM0057499',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308456',
      rtl_batch_array: '6353551, 6308456, 6295900',
      name_search: 'VEASBLUE',
      name: "Veronica austriaca 'Shirley Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 735,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LDBYJ4SZ',
      statusContent: 'dot',
      hash: 'NAXT3TPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAXT3TPZ'
    },
    {
      status: 210,
      sku: 'PM0085213',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373103',
      rtl_batch_array: '6373103',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 1260,
      chnn_stock_retail: 1260,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: '83A4RG68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83A4RG68'
    },
    {
      status: 210,
      sku: 'PM0081495',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319748',
      rtl_batch_array: '6319748',
      name_search: 'LYCALBA',
      name: "Lychnis chalcedonica 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1Y41VEKB',
      statusContent: 'dot',
      hash: 'TEAWBLA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEAWBLA4'
    },
    {
      status: 210,
      sku: 'PM0057503',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202340',
      rtl_batch_array: '6315078, 6202340',
      name_search: 'VESROTFU',
      name: "Veronica spicata 'Rotfuchs'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 412,
      chnn_stock_retail: 1395,
      sppl_order_minimum: 3,
      sppl_prcp: 0.769,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9YD85VH3;7381NCJC;SGZC8ZZP;Z5DNXAS9;JYVCN9PS;LN33JY83;XY81HAD3;S4KPBK4A;G7GRHR2G;TWGLXDDL;8ZPH5SRL;CT422TY9;2CD2YP1K;DZGBBN59;TTB5LR9L',
      statusContent: 'dot',
      hash: 'YDR8T6YB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDR8T6YB'
    },
    {
      status: 810,
      sku: 'PM0064801',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307381',
      rtl_batch_array: '6307381',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 91,
      chnn_stock_retail: 91,
      sppl_prcp: 17.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'CTNSTKGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTNSTKGV'
    },
    {
      status: 210,
      sku: 'PM0047287',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184102',
      rtl_batch_array: '6184102',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'H7YLSE3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7YLSE3E'
    },
    {
      status: 210,
      sku: 'PM0047281',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212855',
      rtl_batch_array: '6212855',
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV7A8VKK;2D7HYSVP;5E11VCT7;WEDN342H;6CBCVY9E;DRD2X2DT;5R8EASNG;TT28BTWY;2189SZS3;YZGT72RE;1BG4Z21C;RSHDGCEX;S1V6CXEZ;PW1TVV1A;27N88R7Y',
      statusContent: 'dot',
      hash: 'WERVD4B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WERVD4B7'
    },
    {
      status: 210,
      sku: 'PM0066453',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184013',
      rtl_batch_array: '6184013',
      name_search: 'COREICHH',
      name: "Cotoneaster radicans 'Eichholz'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ3H5N6X;C372KZ93;Y22E9J4N;1J88TZGL;WTTXJPSA;LL3WSH5P',
      statusContent: 'dot',
      hash: 'A834GW6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A834GW6R'
    },
    {
      status: 210,
      sku: 'PM0068728',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207963',
      rtl_batch_array: '6207963',
      name_search: 'EPASROSE',
      name: "Epilobium angustifolium 'Stahl Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P7WWY3PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7WWY3PE'
    },
    {
      status: 210,
      sku: 'PM0081496',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320566',
      rtl_batch_array: '6320566',
      name_search: 'PHSCRUNC',
      name: 'Photinia serratifolia Crunchy',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RVC8GYAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVC8GYAY'
    },
    {
      status: 210,
      sku: 'PM0066561',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162729',
      rtl_batch_array: '6162729',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3456,
      chnn_stock_retail: 3456,
      sppl_prcp: 3.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'LE58A9WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LE58A9WR'
    },
    {
      status: 210,
      sku: 'PM0048929',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307701',
      rtl_batch_array: '6307701',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'VD7G5L4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD7G5L4P'
    },
    {
      status: 210,
      sku: 'PM0046656',
      core_name: 'Plant',
      sppl_ean: '8720349428786',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5960161',
      rtl_batch_array: '6242188, 6202846, 5960161',
      name_search: 'SESSTARD',
      name: "Sedum spectabile 'Stardust'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 1667,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VXAJYPY',
      statusContent: 'dot',
      hash: 'YHALA9T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHALA9T8'
    },
    {
      status: 210,
      sku: 'PM0081497',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320569',
      rtl_batch_array: '6320569',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      sppl_size_code: '010020P9',
      rng_range_identifier: 'H010020P9',
      rng_range_description: 'H10 cm 20 cm P9',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 8,
      sppl_prcp: 0.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: 'X8G2HBY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8G2HBY1'
    },
    {
      status: 210,
      sku: 'PM0046631',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301379',
      rtl_batch_array: '4773429, 6301379, 6054272',
      name_search: 'AEPVARIE',
      name: "Aegopodium podagraria 'Variegatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1087,
      chnn_stock_retail: 6093,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C488DZS3;ESASK4HK;NSB5XXPJ;6B9TWBS8;WEDLN6ZH;RDWWZTJY',
      statusContent: 'dot',
      hash: 'YBXKE8CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBXKE8CZ'
    },
    {
      status: 210,
      sku: 'PM0081498',
      core_name: 'Plant',
      sppl_ean: '8720349468423',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '5550884',
      rtl_batch_array: '5550884',
      name_search: 'COREICHH',
      name: "Cotoneaster radicans 'Eichholz'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 12000,
      chnn_stock_retail: 12000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ3H5N6X;C372KZ93;Y22E9J4N;1J88TZGL;WTTXJPSA;LL3WSH5P',
      imageBatch: '4ZNLSS2X',
      statusContent: 'dot',
      hash: 'SGPSWXGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGPSWXGL'
    },
    {
      status: 210,
      sku: 'PM0058357',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301713',
      rtl_batch_array: '6126358, 6193651, 6301713',
      name_search: 'MEAQUATI',
      name: 'Mentha aquatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1127,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CELDAE22;XVDHXCSE;T83EGY2B;RS4SKGVS;NEAVE6GA',
      statusContent: 'dot',
      hash: '5KAHNHZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KAHNHZ5'
    },
    {
      status: 910,
      sku: 'PM0081499',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUPAPURP',
      name: "Euphorbia amygdaloides 'Purpurea'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        '1XL4HTY2;11JK28B3;W1GE9D7L;EDXYWJCL;K2T3L1AG;XGWG8TBR;EVCHSTH9;6VT3LDZN;ZSA42E3R;NSGS83AC;Y73C5D2D;Z153N58L;562ACYBV;7EA9P6YA',
      statusContent: 'dot',
      hash: 'LWS48C1T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LWS48C1T'
    },
    {
      status: 210,
      sku: 'PM0081500',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191436',
      rtl_batch_array: '6191436',
      name_search: 'CAPEFROS',
      name: "Campanula poscharskyana 'E.H. Frost'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2104,
      chnn_stock_retail: 2104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5JY57LWE;ASKK779Z;HWGXXWDA;ZLKHD84L;EN9GZP65;JEL2967D;K3A3ALXZ;7LWYLSJL',
      statusContent: 'dot',
      hash: 'DE1G1CZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE1G1CZC'
    },
    {
      status: 210,
      sku: 'PM0066569',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080737',
      rtl_batch_array: '6080737',
      name_search: 'PRGLBLUE',
      name: "Prunella grand. 'Light Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5N1RW8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5N1RW8J'
    },
    {
      status: 210,
      sku: 'PM0046669',
      core_name: 'Plant',
      sppl_ean: '8720664695078',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875075',
      rtl_batch_array: '5875075',
      name_search: 'IRICHIEF',
      name: "Iris (G) 'Indian Chief'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZK34ARA',
      imageBatch: 'Z6VZ3KV9',
      statusContent: 'dot',
      hash: 'NBH73WZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBH73WZP'
    },
    {
      status: 210,
      sku: 'PM0081501',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191439',
      rtl_batch_array: '6191439',
      name_search: 'THPRCARP',
      name: "Thymus praecox 'Red Carpet'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1147,
      chnn_stock_retail: 1147,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67J6BBBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67J6BBBW'
    },
    {
      status: 210,
      sku: 'PM0068770',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208071',
      rtl_batch_array: '6208071, 6257212',
      name_search: 'PEAJCALO',
      name: "Persicaria amplexicaulis 'JS Calor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 1027,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5HR1KKEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HR1KKEA'
    },
    {
      status: 210,
      sku: 'PM0081502',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349008',
      rtl_batch_array: '5479983, 6330156, 6349008',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1620,
      sppl_prcp: 0.234,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'DBG9AT6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBG9AT6N'
    },
    {
      status: 810,
      sku: 'PM0068775',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208078',
      rtl_batch_array: '6208078',
      name_search: 'POFVARIE',
      name: "Polygonatum falcatum 'Variegatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGZPNKBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGZPNKBP'
    },
    {
      status: 810,
      sku: 'PM0069155',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234802',
      rtl_batch_array: '6234802',
      name_search: 'HEPTHERE',
      name: "Heuchera 'Princess Theresa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1BVRVNDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BVRVNDV'
    },
    {
      status: 210,
      sku: 'PM0046713',
      core_name: 'Plant',
      sppl_ean: '8720664695030',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875175',
      rtl_batch_array: '5875175',
      name_search: 'IRHGLAZE',
      name: "Iris (G) 'Honey Glazed'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B1ADNTHY',
      imageBatch: '4V2RDCJA',
      statusContent: 'dot',
      hash: '5X2BX5E8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X2BX5E8'
    },
    {
      status: 210,
      sku: 'PM0048197',
      core_name: 'Plant',
      sppl_ean: '8720353087696',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014585',
      rtl_batch_array: '6014585',
      name_search: 'ASRDAZZL',
      name: "Astilbe 'Razzle Dazzle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 256,
      chnn_stock_retail: 256,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BG5J86DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BG5J86DJ'
    },
    {
      status: 810,
      sku: 'PM0081503',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330159',
      rtl_batch_array: '6330159',
      name_search: 'DAODORA',
      name: 'Daphne odora',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 11.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SEJ21TLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEJ21TLG'
    },
    {
      status: 210,
      sku: 'PM0066466',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184039',
      rtl_batch_array: '6184039',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'V9R7LKLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9R7LKLC'
    },
    {
      status: 210,
      sku: 'PM0069157',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234809',
      rtl_batch_array: '6234809',
      name_search: 'HEBRUM',
      name: "Heucherella 'Buttered Rum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H13N8P13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H13N8P13'
    },
    {
      status: 210,
      sku: 'PM0069158',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234810',
      rtl_batch_array: '6234810',
      name_search: 'HEPCASCA',
      name: "Heucherella 'Plum Cascade'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PALL2D5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PALL2D5K'
    },
    {
      status: 210,
      sku: 'PM0085214',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373104',
      rtl_batch_array: '6373104',
      name_search: 'COCOGGYG',
      name: 'Cotinus coggygria',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 375,
      chnn_stock_retail: 375,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSA84DWE;Y4AN75K2;6S9YSYV4;4XW7XSEE;NK8DRPE4',
      statusContent: 'dot',
      hash: 'BN2ACZC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BN2ACZC4'
    },
    {
      status: 910,
      sku: 'PM0081505',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330166',
      rtl_batch_array: '6330166',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '035C74BOL',
      rng_range_identifier: 'BALL\u00d8035H035035C7.5',
      rng_range_description: 'Ball \u00d8 35 cm H35 cm 35 cm C7.5',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 9.778,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'WBXNYL7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBXNYL7J'
    },
    {
      status: 810,
      sku: 'PM0081506',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330167',
      rtl_batch_array: '6330167',
      name_search: 'PHCTRICO',
      name: "Phormium cookianum 'Tricolor'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '71W7G6HW;LYJYGYHT;B5R2ZT7D;T9GH2L1C;NRE234XV;RJK532GN;277WV5SS;9RP2P796;Y2HBAHNT;G344643X;C7SWY2A4;6ZX4CJ44',
      statusContent: 'dot',
      hash: '6RRCTD8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RRCTD8R'
    },
    {
      status: 210,
      sku: 'PM0068771',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301783',
      rtl_batch_array: '6301783, 6254410, 6353149',
      name_search: 'PEBSUPER',
      name: "Persicaria bistorta 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2513,
      chnn_stock_retail: 6246,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4SHCRTEZ;KJRZCY74;KC4VCH16;L48JAS2W;V5S9RL52',
      statusContent: 'dot',
      hash: 'PG419A1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PG419A1X'
    },
    {
      status: 810,
      sku: 'PM0081507',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330168',
      rtl_batch_array: '6330168',
      name_search: 'PHDUET',
      name: "Phormium 'Duet'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRSXRD6H;1CW1LN9G;TDSSWRAA;N2BV3H42;9YVDY43N;D84DNAW4;8H2WLRP9;188PADJ5;GH9954S2;JZKRAWEJ;WTDJP32E',
      statusContent: 'dot',
      hash: '6ZPWYNJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZPWYNJX'
    },
    {
      status: 210,
      sku: 'PM0063557',
      core_name: 'Plant',
      sppl_ean: '8720664873391',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146922',
      rtl_batch_array: '6146922',
      name_search: 'LUGWHITE',
      name: "Lupinus 'Gallery White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 815,
      chnn_stock_retail: 815,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GPJEK462;ZWAXBG6G;JX3SXV8B;172HHSV3;XEJ653ZE;5ZZBKY1C;2W83DE1C;Y8ECLEY7;GDSYAYWD;EHXET38X;C171PXSY;4PX2VYCE;B6V7YAED;BHJ2NRCJ;PYSXHL9V;1VRS7XDN;CYY2AT57;YJBZLNWW',
      statusContent: 'dot',
      hash: 'Z5XLYJNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5XLYJNS'
    },
    {
      status: 810,
      sku: 'PM0081508',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330169',
      rtl_batch_array: '6330169',
      name_search: 'PHEGLOW',
      name: "Phormium 'Evening Glow'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J8GRSW2V;HJ4H4V3T;16XWW7WC;13BAATNB;9AVWKG97',
      statusContent: 'dot',
      hash: '93D5PDPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93D5PDPY'
    },
    {
      status: 810,
      sku: 'PM0081509',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330170',
      rtl_batch_array: '6330170',
      name_search: 'PHOFLAMI',
      name: "Phormium 'Flamingo'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LWTSK9WB;K1C1872A;KL2DDD42;A9VJ2VC4;GECXKVKN',
      statusContent: 'dot',
      hash: 'RP9ARY3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RP9ARY3D'
    },
    {
      status: 810,
      sku: 'PM0081510',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330171',
      rtl_batch_array: '6330171',
      name_search: 'PHGRAY',
      name: "Phormium 'Golden Ray'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVAP58E2;Y7B1R7A5;1VEE3ADE;EK1757LT;NG2NCXEN',
      statusContent: 'dot',
      hash: 'PRL6ZWRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRL6ZWRD'
    },
    {
      status: 210,
      sku: 'PM0068723',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229888',
      rtl_batch_array: '6229888, 6207850',
      name_search: 'DAPELTAT',
      name: 'Darmera peltata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 807,
      chnn_stock_retail: 1929,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1SZETJA;ZCRER6DZ;4SA51W4X;7WRHN3LP;BLNXKHJT;55W94S38;6PBR753Z;Y46S9D29;ETPT4TNE;SCYAKL2P;VSYNYR34',
      statusContent: 'dot',
      hash: 'JWL6CLXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWL6CLXK'
    },
    {
      status: 810,
      sku: 'PM0081511',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330172',
      rtl_batch_array: '6330172',
      name_search: 'PHPSTRIP',
      name: "Phormium 'Pink Stripe'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GPEHLLG8;AATSVJWA;EGENZCSZ;RZVHAC4W;9XZW231R;ENZAW17T;LAPXVXP5;2R4Y7D6J;2VZTPSYR;S6AAX189;5PRJ8G67',
      statusContent: 'dot',
      hash: '16EYWVLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16EYWVLV'
    },
    {
      status: 210,
      sku: 'PM0046672',
      core_name: 'Plant',
      sppl_ean: '8720664859975',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875081',
      rtl_batch_array: '5875081',
      name_search: 'DISPECTA',
      name: 'Dicentra spectabilis',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S6CXXVX6',
      imageBatch: 'HH4Y69ZY',
      statusContent: 'dot',
      hash: '1T6LJTVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1T6LJTVJ'
    },
    {
      status: 810,
      sku: 'PM0081512',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330173',
      rtl_batch_array: '6330173',
      name_search: 'PHRSENSA',
      name: "Phormium 'Red Sensation'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLNE3KPV;WDY4PYGJ;CB97ZDDR',
      statusContent: 'dot',
      hash: '2GBSRAAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GBSRAAJ'
    },
    {
      status: 210,
      sku: 'PM0033648',
      core_name: 'Plant',
      sppl_ean: '8720349491971',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849638',
      rtl_batch_array: '5849638',
      name_search: 'BEDAKISS',
      name: 'Bergenia Dragonfly Angel Kiss',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_prcp: 3.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SBD8RC8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBD8RC8H'
    },
    {
      status: 810,
      sku: 'PM0081513',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330174',
      rtl_batch_array: '6330174',
      name_search: 'PHSUNDOW',
      name: "Phormium 'Sundowner'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ED55X22A;R81V4GPX;H6RGJ3T7',
      statusContent: 'dot',
      hash: '25W5KGCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25W5KGCZ'
    },
    {
      status: 810,
      sku: 'PM0081514',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330175',
      rtl_batch_array: '6330175',
      name_search: 'PHYWAVE',
      name: "Phormium 'Yellow Wave'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZDY9AHS;7GZ4N3GP;XPHJC86T',
      statusContent: 'dot',
      hash: '7E6KZ657',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E6KZ657'
    },
    {
      status: 810,
      sku: 'PM0081515',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330177',
      rtl_batch_array: '6330177',
      name_search: 'PITNANUM',
      name: "Pittosporum tobira 'Nanum'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 8.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2HT6RTTY;4ZXDYAX8;LW5SEP3C;CC24ZL44;CT8ZYYV6;1EGK51WG;AGCJ7Y2T;6DNVLVL2;7HEABZVL',
      statusContent: 'dot',
      hash: 'S1PXHSTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1PXHSTX'
    },
    {
      status: 210,
      sku: 'PM0081516',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330178',
      rtl_batch_array: '6330178',
      name_search: 'RUACULEA',
      name: 'Ruscus aculeatus',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_prcp: 6.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPGBR3D1;99JAB6D9',
      statusContent: 'dot',
      hash: '72863Y2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72863Y2Y'
    },
    {
      status: 210,
      sku: 'PM0046732',
      core_name: 'Plant',
      sppl_ean: '8720664695115',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875202',
      rtl_batch_array: '5875202',
      name_search: 'IRMHDARW',
      name: "Iris (G) 'Mrs Horace Darwin'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN7P2S7;RDEZZ8NT',
      imageBatch: '54YT1VTK',
      statusContent: 'dot',
      hash: '85J3EV2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85J3EV2D'
    },
    {
      status: 910,
      sku: 'PM0081517',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SKJPEROS',
      name: "Skimmia japonica 'Perosa'",
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      imageCore:
        'D7TRBZRW;K2EAHCVK;5ZPGDYDA;53GCYED1;Z27A6AHR;4A2PNX2Y;C5GY3P3N;YY1XCG1S;CEG97R6W;1A6A87B4;DGC7CZ9V;Z77DZ7DL;E5J153H7;N26HSGDK;7J9H6EDY;EV4R7E3R',
      statusContent: 'dot',
      hash: 'W517CTX5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W517CTX5'
    },
    {
      status: 910,
      sku: 'PM0081518',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      rng_range_identifier: 'H040045C1.5',
      rng_range_description: 'H40 cm 45 cm C1.5',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      statusContent: 'dot',
      hash: 'JPGT82HH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JPGT82HH'
    },
    {
      status: 210,
      sku: 'PM0081519',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346668',
      rtl_batch_array: '6346668',
      name_search: 'ILCLGLOB',
      name: 'Ilex crenata Luxus Globe',
      sppl_size_code: '020C2BOL01',
      rng_range_identifier: 'BALL\u00d8020H020020C2',
      rng_range_description: 'Ball \u00d8 20 cm H20 cm 20 cm C2',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_prcp: 4.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LKXX6PB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKXX6PB6'
    },
    {
      status: 210,
      sku: 'PM0054607',
      core_name: 'Plant',
      sppl_ean: '8720664877665',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080398',
      rtl_batch_array: '6080398',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: 'PLGP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1681,
      chnn_stock_retail: 1681,
      sppl_order_minimum: 3,
      sppl_prcp: 0.784,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZ287D79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ287D79'
    },
    {
      status: 210,
      sku: 'PM0058250',
      core_name: 'Plant',
      sppl_ean: '8720664695047',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112479',
      rtl_batch_array: '6112479',
      name_search: 'IRHWEED',
      name: "Iris (G) 'Howard Weed'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SD2D639N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD2D639N'
    },
    {
      status: 910,
      sku: 'PM0081520',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KAARUBRA',
      name: "Kalmia angustifolia 'Rubra'",
      rng_range_identifier: 'H035040C1.5',
      rng_range_description: 'H35 cm 40 cm C1.5',
      imageCore: 'AWYB66YV',
      statusContent: 'dot',
      hash: 'WC5A5LAV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WC5A5LAV'
    },
    {
      status: 910,
      sku: 'PM0081521',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KAARUBRA',
      name: "Kalmia angustifolia 'Rubra'",
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      imageCore: 'AWYB66YV',
      statusContent: 'dot',
      hash: 'YJNH7P2C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YJNH7P2C'
    },
    {
      status: 210,
      sku: 'PM0048236',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349864',
      rtl_batch_array: '6349864',
      name_search: 'DEHSAMBA',
      name: 'Delphinium Highlander Samba',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 3.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCEWY579;5YNGT1X3;VYZV3GZS;LHSC2ENC',
      statusContent: 'dot',
      hash: 'JVY9ZS5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVY9ZS5L'
    },
    {
      status: 810,
      sku: 'PM0081522',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346671',
      rtl_batch_array: '6346671',
      name_search: 'LADYNABI',
      name: 'Lagerstroemia Dynabite',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HYAZPA5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYAZPA5C'
    },
    {
      status: 210,
      sku: 'PM0064487',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217201',
      rtl_batch_array: '6217201',
      name_search: 'LESANTE',
      name: "Leucanthemum 'Sante'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HACNR53X',
      statusContent: 'dot',
      hash: 'P64Z3XR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P64Z3XR5'
    },
    {
      status: 210,
      sku: 'PM0058248',
      core_name: 'Plant',
      sppl_ean: '8720664869769',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112476',
      rtl_batch_array: '6112476',
      name_search: 'EDI63080',
      name: 'Ipheion uniflorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 573,
      chnn_stock_retail: 573,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDGKRL9K;LPVRA6ZH;HRX4VSTH;SA19KTTJ;2NX51AW4;8DYK1BGG;S3K3VNS6;L7NDSK58;86X3WV1J;HAJ2W5Z6;BD3AE5JV;DP8212EZ;N3GSBDXZ;E7B525YA;SDENSKV1;W4ESEZ48',
      statusContent: 'dot',
      hash: '9VSWT3S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VSWT3S6'
    },
    {
      status: 210,
      sku: 'PM0046712',
      core_name: 'Plant',
      sppl_ean: '8720664695009',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875174',
      rtl_batch_array: '5875174',
      name_search: 'IRGMUFFI',
      name: "Iris (G) 'Golden Muffin'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BL3P9EZJ;698PEP3B;G9HYCEEA;2KGBA93S',
      imageBatch: 'GLLYVCSE',
      statusContent: 'dot',
      hash: '2HWVBK47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HWVBK47'
    },
    {
      status: 210,
      sku: 'PM0081523',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330189',
      rtl_batch_array: '6330189',
      name_search: 'LAIEVELI',
      name: 'Lagerstroemia indica Eveline',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SJ9KWPH',
      statusContent: 'dot',
      hash: '1P78SN7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1P78SN7D'
    },
    {
      status: 810,
      sku: 'PM0081524',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330190',
      rtl_batch_array: '6330190',
      name_search: 'MAGRANDI',
      name: 'Magnolia grandiflora',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z4XRBZGS;BA23HE4L;CXGNE86A;KKSYE57D;22C5EC6K;8THTXCJR',
      statusContent: 'dot',
      hash: 'XA6G2X7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA6G2X7G'
    },
    {
      status: 810,
      sku: 'PM0081525',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330191',
      rtl_batch_array: '6330191',
      name_search: 'MAGEXMOU',
      name: "Magnolia grandiflora 'Exmouth'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SC33JHBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SC33JHBS'
    },
    {
      status: 810,
      sku: 'PM0081526',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330192',
      rtl_batch_array: '6330192',
      name_search: 'MAGFERRU',
      name: "Magnolia grandiflora 'Ferruginea'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XG9P9WC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG9P9WC9'
    },
    {
      status: 810,
      sku: 'PM0081527',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330193',
      rtl_batch_array: '6330193',
      name_search: 'MAGGALIS',
      name: "Magnolia grandiflora 'Galissonni\u00e8re'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A1NDYABY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1NDYABY'
    },
    {
      status: 810,
      sku: 'PM0081528',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330194',
      rtl_batch_array: '6330194',
      name_search: 'MAGLGEM',
      name: "Magnolia grandiflora 'Little Gem'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J7ZK7YX6;GA1TC5D9',
      statusContent: 'dot',
      hash: '128R6PD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '128R6PD5'
    },
    {
      status: 810,
      sku: 'PM0081529',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330195',
      rtl_batch_array: '6330195',
      name_search: 'MONIGRA',
      name: 'Morus nigra',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 6.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APPRB2PC;KG2K79BC;RXALSNE6;CC3AT8R3;W6BAK1RT;ZRL9YTNG;WGLV6VXH;N51R48HJ;CZGX3ZZS;732N4T5E;B3Z2VGWW;K517A55Z;9JEZANJ5;YR5BPLHH;TV771L8R',
      statusContent: 'dot',
      hash: 'Y37L2H3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y37L2H3E'
    },
    {
      status: 810,
      sku: 'PM0081530',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330196',
      rtl_batch_array: '6330196',
      name_search: 'NADOMEST',
      name: 'Nandina domestica',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_prcp: 8.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VD8H147C;BLZ2GW1V;CL276JTD;N2EPWKKW;J2S57PCB;47P9JE8W;HE8J2D6E;LCZ9E9SR;VJ7T2E41;XA2GVAD8;XGCGLADH;J2PXJC5V;E81ESH4G;S9BJX1AE;86GSB4H1',
      statusContent: 'dot',
      hash: 'D82DNT8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D82DNT8P'
    },
    {
      status: 210,
      sku: 'PM0081531',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330197',
      rtl_batch_array: '6330197',
      name_search: 'NADFIREP',
      name: "Nandina domestica 'Firepower'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7CLBZJNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CLBZJNH'
    },
    {
      status: 210,
      sku: 'PM0081532',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340017',
      rtl_batch_array: '6340017',
      name_search: 'JUSHOLGE',
      name: "Juniperus squamata 'Holger'",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 875,
      chnn_stock_retail: 875,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '27RDVKTT;CR8CL3Z4;DEXSKTNY;C8Z6KHZ6;BVE5HJDT;13CER4GX;T5JPZL46;S82JXW9H;SCY2ZHED;G2S4ZZVD;BAYEH66C',
      statusContent: 'dot',
      hash: 'YRGVGRJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRGVGRJ6'
    },
    {
      status: 210,
      sku: 'PM0081533',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340018',
      rtl_batch_array: '6340018',
      name_search: 'TABCAPPE',
      name: "Taxus baccata 'Cappenberg'",
      sppl_size_code: '060070C34',
      rng_range_identifier: 'H060070C3.5',
      rng_range_description: 'H60 cm 70 cm C3.5',
      sppl_stock_available: 322,
      chnn_stock_retail: 322,
      sppl_prcp: 6.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7725WD6K',
      statusContent: 'dot',
      hash: 'B65NREJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B65NREJY'
    },
    {
      status: 210,
      sku: 'PM0064807',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6177561',
      rtl_batch_array: '6254724, 5837427, 6177561, 6272820',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1283,
      chnn_stock_retail: 6430,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: '8HDTHTB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HDTHTB5'
    },
    {
      status: 210,
      sku: 'PM0081534',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340019',
      rtl_batch_array: '6340019',
      name_search: 'TABFROBU',
      name: "Taxus baccata 'Fastigiata Robusta'",
      sppl_size_code: '060070C34',
      rng_range_identifier: 'H060070C3.5',
      rng_range_description: 'H60 cm 70 cm C3.5',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_prcp: 7.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJRLWDE4;5WYTHSPR;5EJXXPAJ;7JH3XKZC;7K755T8W',
      statusContent: 'dot',
      hash: 'YKZ1HWBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKZ1HWBD'
    },
    {
      status: 210,
      sku: 'PM0063556',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282369',
      rtl_batch_array: '6282369',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '060080C4',
      rng_range_identifier: 'H060080C4',
      sppl_stock_available: 939,
      chnn_stock_retail: 939,
      sppl_prcp: 4.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'PRENHNXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRENHNXG'
    },
    {
      status: 210,
      sku: 'PM0081535',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340021',
      rtl_batch_array: '6340021',
      name_search: 'TACFARME',
      name: "Taxus cuspidata 'Farmen'",
      sppl_size_code: '020025P10',
      rng_range_identifier: 'H020025P10',
      rng_range_description: 'H20 cm 25 cm P10',
      sppl_stock_available: 4300,
      chnn_stock_retail: 4300,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2RZ7L51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2RZ7L51'
    },
    {
      status: 210,
      sku: 'PM0081536',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340109',
      rtl_batch_array: '6340109',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.954,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'RS55ZJLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS55ZJLY'
    },
    {
      status: 210,
      sku: 'PM0081537',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340110',
      rtl_batch_array: '6340110',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: '74J1PGYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74J1PGYS'
    },
    {
      status: 210,
      sku: 'PM0063560',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284557',
      rtl_batch_array: '6284557',
      name_search: 'TICORDIF',
      name: 'Tiarella cordifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1925,
      chnn_stock_retail: 1925,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VRBR3KXS;K55S8VR8;T5AXTVDE;NSXNCAAG;1WV11RHZ;PSRZ2DXR;YERVCWH7;9A6W3JBX;A3ZPRK4Z',
      statusContent: 'dot',
      hash: 'H4WN9V3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4WN9V3P'
    },
    {
      status: 210,
      sku: 'PM0069159',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244251',
      rtl_batch_array: '6244251, 6252633',
      name_search: 'HOAMOON',
      name: "Hosta 'August Moon'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1186,
      chnn_stock_retail: 1873,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DDGGJRHX;1HKNLLAP;83VZVJKV;S4PVJXKT;1N6CWT4D',
      statusContent: 'dot',
      hash: 'TYBRDJDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYBRDJDE'
    },
    {
      status: 210,
      sku: 'PM0046683',
      core_name: 'Plant',
      sppl_ean: '8720664695153',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875117',
      rtl_batch_array: '5875117',
      name_search: 'IROEDGE',
      name: "Iris (G) 'On Edge'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ZAB49E35',
      statusContent: 'dot',
      hash: 'WNXLJ59D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNXLJ59D'
    },
    {
      status: 210,
      sku: 'PM0081538',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340112',
      rtl_batch_array: '6340112',
      name_search: 'DECPROCH',
      name: "Deutzia crenata 'Pride of Rochester'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E42YC5BC',
      statusContent: 'dot',
      hash: 'ZEDY5K53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZEDY5K53'
    },
    {
      status: 210,
      sku: 'PM0081539',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340113',
      rtl_batch_array: '6340113',
      name_search: 'DIRHONEY',
      name: 'Diervilla rivularis Honeybee',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GGVW5EKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGVW5EKW'
    },
    {
      status: 210,
      sku: 'PM0081540',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340114',
      rtl_batch_array: '6340114',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'Y32Y3VWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y32Y3VWG'
    },
    {
      status: 210,
      sku: 'PM0081541',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340115',
      rtl_batch_array: '6340115',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'CZPDZ2E6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZPDZ2E6'
    },
    {
      status: 210,
      sku: 'PM0081542',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340116',
      rtl_batch_array: '6340116',
      name_search: 'HYPPOLES',
      name: 'Hydrangea paniculata Polestar',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '68WWVJPZ;D86JYRPH;GL6R7HZN',
      statusContent: 'dot',
      hash: 'Y7H5BREA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7H5BREA'
    },
    {
      status: 810,
      sku: 'PM0081543',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340117',
      rtl_batch_array: '6340117',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: '2NN3CXSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NN3CXSW'
    },
    {
      status: 210,
      sku: 'PM0064565',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291726',
      rtl_batch_array: '6291726, 6386155',
      name_search: 'PAVREHBR',
      name: "Panicum virgatum 'Rehbraun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2849,
      chnn_stock_retail: 3089,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTPBCVG1;BDLXK19Y;AVX56D5S;7RH9XSJ5;1XCWN1HP;2D2J95VP;DHYCCDXV;2YJN9CAT;2BR3BZTA;D1K77WWG;7RDRGPE4;KLY9228P',
      statusContent: 'dot',
      hash: 'CPEHTC4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPEHTC4P'
    },
    {
      status: 210,
      sku: 'PM0081544',
      core_name: 'Plant',
      sppl_ean: '8720349411528',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5683301',
      rtl_batch_array: '5683301',
      name_search: 'CAGARGAN',
      name: 'Campanula garganica',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V7XC9C6W;AWXN3ZEX;TPGSRZEZ;WPW62WL7;PVXG4NGD;HK2NEGSY;D8NS5DDH;9PZVVAYW;4R5N9523;XEARTHT9;18V1E2ZY;1EG3C3JL;EAL9948S',
      imageBatch: 'BAHEW58R',
      statusContent: 'dot',
      hash: '69ZKPYK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69ZKPYK4'
    },
    {
      status: 210,
      sku: 'PM0081545',
      core_name: 'Plant',
      sppl_ean: '8720664854888',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137763',
      rtl_batch_array: '6137763',
      name_search: 'CAPORTEN',
      name: 'Campanula portenschlagiana',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7773,
      chnn_stock_retail: 7773,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NL6C3ZYC;R6133292;4AKC91CE;D1K357CG;ZLLVB5JJ;67G5Y3EH;X9STW145;18G244RN;X1P6DSJT;C3VV56AJ;R5WYL6E2;83ZSLVD6;CJ8CAS98;DRDLPA9X;2J5XY8R2;71LGVZNX;EDJV3GZJ;T88XVX92;9HPE78E6;WSYHAE15;2SH3DLNT;GWLV1WKE;EHW9KPBS;CZ9WELA2;JS9AETAL;RAZBDLCH;KGD4359H;Z4ZVNYCN;LLS5VVHH;JC2A5AHC',
      statusContent: 'dot',
      hash: 'NPJBYYYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPJBYYYC'
    },
    {
      status: 810,
      sku: 'PM0081546',
      core_name: 'Plant',
      sppl_ean: '8720664810655',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5756056',
      rtl_batch_array: '5756056',
      name_search: 'THCSAMBE',
      name: "Thymus citriodorus 'Sambesi'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'XRJ7D1J2',
      statusContent: 'dot',
      hash: 'ZVTJDVAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVTJDVAA'
    },
    {
      status: 210,
      sku: 'PM0046688',
      core_name: 'Plant',
      sppl_ean: '8720664695245',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875122',
      rtl_batch_array: '5875122',
      name_search: 'IRSKYFIR',
      name: "Iris (G) 'Skyfire'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EKW1E33C',
      imageBatch: 'TDR3RNRB',
      statusContent: 'dot',
      hash: 'GLXKGEZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLXKGEZT'
    },
    {
      status: 210,
      sku: 'PM0074256',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268787',
      rtl_batch_array: '6268787',
      name_search: 'MISCOSMO',
      name: "Miscanthus sinensis 'Cosmopolitan'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 1.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DS8CHGSA;EHJ5XCX1;NZ5323NH;SBXE8V4E;HSLDX4HB;J2CA1ND8;DJA1DKHT;1SH5PN66;VC84K294;69CKGVBK;KZGNLL5H',
      statusContent: 'dot',
      hash: 'E4BXB3DC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4BXB3DC'
    },
    {
      status: 210,
      sku: 'PM0064800',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308445',
      rtl_batch_array: '6308445, 6348400',
      name_search: 'PEABLOSS',
      name: "Penstemon 'Apple Blossom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 373,
      sppl_order_minimum: 3,
      sppl_prcp: 0.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '41XG3GSY;KEEL3YXY;R6836YAN;J39781NV;ETLR5VPJ;VT5NJ17L;6HZEDNPW',
      statusContent: 'dot',
      hash: 'ED1L1XJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED1L1XJA'
    },
    {
      status: 210,
      sku: 'PM0012897',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266166',
      rtl_batch_array: '6266166',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 11720,
      chnn_stock_retail: 11720,
      sppl_prcp: 1.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '7EKS9Y5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7EKS9Y5P'
    },
    {
      status: 210,
      sku: 'PM0057467',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220928',
      rtl_batch_array: '6220928, 6133733',
      name_search: 'SOSTRAHL',
      name: "Solidago 'Strahlenkrone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 246,
      chnn_stock_retail: 747,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KE9SWH4W;V1W692TT;CKLBHZD3;9CARWTY3',
      statusContent: 'dot',
      hash: '5ZYHBZL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZYHBZL9'
    },
    {
      status: 210,
      sku: 'PM0046691',
      core_name: 'Plant',
      sppl_ean: '8720664691735',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875130',
      rtl_batch_array: '5875130',
      name_search: 'HEWESERG',
      name: "Helenium 'Wesergold'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'WGEX7CWG',
      statusContent: 'dot',
      hash: '1Y4KN7JK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y4KN7JK'
    },
    {
      status: 210,
      sku: 'PM0074257',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268788',
      rtl_batch_array: '6268788',
      name_search: 'MISDLOCK',
      name: "Miscanthus sinensis 'Dread Locks'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XTLLDCJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTLLDCJJ'
    },
    {
      status: 210,
      sku: 'PM0074258',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268789',
      rtl_batch_array: '6268789',
      name_search: 'MISSFEDE',
      name: "Miscanthus sinensis 'Federweiser'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCCCTG7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCCCTG7D'
    },
    {
      status: 210,
      sku: 'PM0074259',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382318',
      rtl_batch_array: '6315054, 6268790, 6382318',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14250,
      chnn_stock_retail: 16938,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: 'SSHZVP7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSHZVP7S'
    },
    {
      status: 210,
      sku: 'PM0057496',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6301896',
      rtl_batch_array: '6349156, 6301896, 6164441, 6112948',
      name_search: 'VERNIGRU',
      name: 'Verbascum nigrum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1162,
      chnn_stock_retail: 3090,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5D53TKHZ;94E6TCH1',
      statusContent: 'dot',
      hash: 'NDB33TDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDB33TDY'
    },
    {
      status: 210,
      sku: 'PM0046632',
      core_name: 'Plant',
      sppl_ean: '8720664850941',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6171203',
      rtl_batch_array: '6349551, 6263741, 6171203',
      name_search: 'AJRBRAUN',
      name: "Ajuga reptans 'Braunherz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 322,
      chnn_stock_retail: 3072,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C1WPKKVY;P6P5J6CT;7HD92WK5;EA4P3HZP',
      statusContent: 'dot',
      hash: 'HPJP8J2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPJP8J2Z'
    },
    {
      status: 210,
      sku: 'PM0059958',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350639',
      rtl_batch_array: '6301685, 6350639',
      name_search: 'LEBECKY',
      name: "Leucanthemum (S) 'Becky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 815,
      chnn_stock_retail: 1048,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L24E9LXZ;J41DEADB;86JTBDCN;TJG2VE5C;HGXXZR5Z;XH2D4794;6R9ZSDRH;9EJSXZ85;AEYD34RK;24S512L5;VLVXWJ5S;8WZELRL1;NYYYAGGX;JWYWBNXN',
      statusContent: 'dot',
      hash: 'CK5LDN7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CK5LDN7S'
    },
    {
      status: 210,
      sku: 'PM0046668',
      core_name: 'Plant',
      sppl_ean: '8720664687530',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875074',
      rtl_batch_array: '5875074',
      name_search: 'DIFLUXUR',
      name: "Dicentra formosa 'Luxuriant'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1EHWYB86',
      imageBatch: '6GE2BH5C',
      statusContent: 'dot',
      hash: 'E7D24NGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7D24NGV'
    },
    {
      status: 210,
      sku: 'PM0046561',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284516',
      rtl_batch_array: '6252456, 6284516',
      name_search: 'ARAETHUS',
      name: 'Aruncus aethusifolius',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1127,
      chnn_stock_retail: 2136,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B82S79N;WJANAE6T;25Z8EWAD;APGG4RL8;6LCPKWD4;RE12TVSC;NW3H315C;RXJ3XT26',
      statusContent: 'dot',
      hash: 'W5RCJSGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5RCJSGK'
    },
    {
      status: 210,
      sku: 'PM0069156',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252632',
      rtl_batch_array: '6252632',
      name_search: 'HESSCROL',
      name: "Heuchera 'Silver Scrolls'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1220,
      chnn_stock_retail: 1220,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6LR72VW;CTS1P9YL;Y9S868BR;C7K3SNJA;XRVLGCL9;95X2CRJK;KLR1LTE5;7VWGRS7Y;K26T9P5X;YRT89GZ8',
      statusContent: 'dot',
      hash: 'TEYH32LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEYH32LD'
    },
    {
      status: 810,
      sku: 'PM0085215',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373105',
      rtl_batch_array: '6373105',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'J2L6LH27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2L6LH27'
    },
    {
      status: 210,
      sku: 'PM0078059',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346655',
      rtl_batch_array: '6346655',
      name_search: 'HEHGLACI',
      name: "Hedera helix 'Glacier'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YNKZE78G;ST39J6WN;1AK4BT6L;KJ6P7ESR;743J88XA;EZL4Y25P;ZH8ZXXVK;H45XV8VC',
      statusContent: 'dot',
      hash: '9RE98BBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RE98BBK'
    },
    {
      status: 810,
      sku: 'PM0071063',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286673',
      rtl_batch_array: '6286673',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 4.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'NH896ZXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH896ZXS'
    },
    {
      status: 210,
      sku: 'PM0078060',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346656',
      rtl_batch_array: '6346656',
      name_search: 'HEHGOLDC',
      name: "Hedera helix 'Goldchild'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2940,
      chnn_stock_retail: 2940,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJRW5453;57LGK482;7ZC75WXR;XXLZ6PKD;YD2JNZA2',
      statusContent: 'dot',
      hash: 'V3ADEW64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3ADEW64'
    },
    {
      status: 910,
      sku: 'PM0081547',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CACWCLIP',
      name: "Campanula carpatica 'Weisse Clips'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: '7DS7GTC7;NKBP72RK;58K3N8J7;1ZEJA8V4;ZG7RT8YA',
      statusContent: 'dot',
      hash: 'SYRZ6AP4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SYRZ6AP4'
    },
    {
      status: 210,
      sku: 'PM0064841',
      core_name: 'Plant',
      sppl_ean: '8720664698383',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6067921',
      rtl_batch_array: '6067921, 6310316',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15165,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'WH6S274H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH6S274H'
    },
    {
      status: 810,
      sku: 'PM0065931',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281050',
      rtl_batch_array: '6281050',
      name_search: 'LOHENRYI',
      name: 'Lonicera henryi',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN37L23T;JG217ECD;8H823RVC;REDLBRVV;CRZV8V5A;TPDWP3EY;7XRTK71Y;EENTKELG;E63ER6TJ;NWD4J7CW;GLSRDX75;J6K74GCC;W8A35BAE;CK844ZSW',
      statusContent: 'dot',
      hash: 'TZNLB1DZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZNLB1DZ'
    },
    {
      status: 210,
      sku: 'PM0070058',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220219',
      rtl_batch_array: '6220219',
      name_search: 'DECYANKE',
      name: "Delphinium (B) 'Connecticut Yankee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5779,
      chnn_stock_retail: 5779,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HYXBV3YW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYXBV3YW'
    },
    {
      status: 210,
      sku: 'PM0064845',
      core_name: 'Plant',
      sppl_ean: '8720664860278',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147735',
      rtl_batch_array: '6147735',
      name_search: 'DOMAPHRO',
      name: "Dodecatheon meadia 'Aphrodite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZKKLHSJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKKLHSJ1'
    },
    {
      status: 210,
      sku: 'PM0081548',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197112',
      rtl_batch_array: '6197112',
      name_search: 'SASCHNEE',
      name: "Saxifraga (A) 'Schneeteppich'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1153,
      chnn_stock_retail: 1153,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L2BG41NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2BG41NS'
    },
    {
      status: 810,
      sku: 'PM0063586',
      core_name: 'Plant',
      sppl_ean: '8720664862449',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147606',
      rtl_batch_array: '6147606',
      name_search: 'EUCASH',
      name: "Euphorbia 'Copton Ash'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52TB8TJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52TB8TJL'
    },
    {
      status: 210,
      sku: 'PM0081549',
      core_name: 'Plant',
      sppl_ean: '8720626392779',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837398',
      rtl_batch_array: '5837398',
      name_search: 'ATNIPONI',
      name: 'Athyrium niponicum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 494,
      chnn_stock_retail: 494,
      sppl_prcp: 1.951,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8G59TPV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G59TPV3'
    },
    {
      status: 210,
      sku: 'PM0068744',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208003',
      rtl_batch_array: '6208003',
      name_search: 'HEMERANT',
      name: "Helenium 'Meranti'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 158,
      chnn_stock_retail: 158,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZJT9KAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZJT9KAZ'
    },
    {
      status: 210,
      sku: 'PM0081550',
      core_name: 'Plant',
      sppl_ean: '8720664854727',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142525',
      rtl_batch_array: '6142525',
      name_search: 'CAGALBA',
      name: "Campanula glomerata 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 654,
      chnn_stock_retail: 654,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T6Y3WKGJ;SP3LHX9B;1HKSZPBZ;PL3ZXGEH;GNENTY9P;SBSYPCPE;7VE2BL2Y;KD8WXJHA;1LEB9VW4;1BKYXL6K;BJ2PY7CT;D3VRTVJ8;GDZ1X46H;J6K7GP3V;VK1LSZ1G;X2DD8LAZ;LCZTTAYG;RYY4D87D;Y4YGNDRL;E7NEV334',
      statusContent: 'dot',
      hash: '2ZHYSJCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZHYSJCV'
    },
    {
      status: 210,
      sku: 'PM0068745',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317842',
      rtl_batch_array: '6208004, 6317842',
      name_search: 'HESEFLOW',
      name: "Helenium 'Sahin's Early Flowerer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 489,
      chnn_stock_retail: 1210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2ECK99W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2ECK99W'
    },
    {
      status: 210,
      sku: 'PM0081551',
      core_name: 'Plant',
      sppl_ean: '8720664854895',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142526',
      rtl_batch_array: '6142526',
      name_search: 'CAPOSCHA',
      name: 'Campanula poscharskyana',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 799,
      chnn_stock_retail: 799,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8SYZHHW;59GLBEPR;DG4KPLWV;63E2PWS7;7AE8N8Y9;24CES13Y;VKJBG9PX;B7L11J7T;WW7LPKAB;B8L52A8S;8XW789ZC;WTZSZKD2;VJAKT7ZN;RNDSWTCK;1L84PETH;ZJ2Y7LD7',
      statusContent: 'dot',
      hash: 'XYVPDBP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYVPDBP9'
    },
    {
      status: 210,
      sku: 'PM0046168',
      core_name: 'Plant',
      sppl_ean: '8720664871366',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164325',
      rtl_batch_array: '6164325',
      name_search: 'LALRPEAR',
      name: "Lathyrus latifolius 'Red Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J86WGLZ;BWGTBXLY;S8HWWAY4',
      statusContent: 'dot',
      hash: '99CVEV73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99CVEV73'
    },
    {
      status: 210,
      sku: 'PM0081552',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255391',
      rtl_batch_array: '6255391',
      name_search: 'DEPKALMI',
      name: "Deutzia purpurascens 'Kalmiiflora'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E529B3ZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E529B3ZE'
    },
    {
      status: 210,
      sku: 'PM0081553',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255392',
      rtl_batch_array: '6255392',
      name_search: 'DEROSEA',
      name: 'Deutzia rosea',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7ZA4RV89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZA4RV89'
    },
    {
      status: 810,
      sku: 'PM0032593',
      core_name: 'Plant',
      sppl_ean: '8720664679986',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866445',
      rtl_batch_array: '5866445',
      name_search: 'ALMSENIO',
      name: "Alchemilla mollis 'Senior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W2BSDRZ1;B9S285PV;EJHLK79L;KV4HDN5Y;JVH7N6KN;LV8TX1NC;XE3BSHSL;GC76LH87',
      statusContent: 'dot',
      hash: 'WSWVSLV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSWVSLV2'
    },
    {
      status: 210,
      sku: 'PM0064851',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6265900',
      rtl_batch_array: '6265900, 6242779, 6244217',
      name_search: 'RORFAIRY',
      name: "Rosa (P) 'Rote The Fairy'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 6066,
      chnn_stock_retail: 6578,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXJZ5HP5',
      statusContent: 'dot',
      hash: '1GTLNGNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GTLNGNN'
    },
    {
      status: 210,
      sku: 'PM0081554',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255654',
      rtl_batch_array: '6255654',
      name_search: 'THPALBIF',
      name: "Thymus praecox 'Albiflorus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JG29AXRT;KPAZN8XE;DP4YYZZ6;7XH2J639;PDHGP48W;CTV34KNY;Z2BEA1LL;84VZLH1A;GNAR9DYK;WE9ELLS2;4V3VLNXX;4ZBCZDPS;5BPNY9S9;24K57SYG;61A68A64;YLL6CAAN;9YCBJXHT;JDGN81YV;8REC3NWA;BC2EBWJE;R51R78DC;6GNXZ4VR;SGJ6D7S5;XD6L9172;JBXG9VP5;K23NP4YX;RJ4427WT',
      statusContent: 'dot',
      hash: 'LEGZVBYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEGZVBYB'
    },
    {
      status: 210,
      sku: 'PM0081555',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257462',
      rtl_batch_array: '6257462',
      name_search: 'JUCSCHLA',
      name: "Juniperus conferta 'Schlager'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 545,
      chnn_stock_retail: 545,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE4YPK6H;SXWS95KY;XEPG53BJ;YL1L8H4W;K7RBDDAE;71EYV6VB;2W7AAP17;YG3HWY7K;ZTSDELW2;YL2ETV7K;BJXWD1KV',
      statusContent: 'dot',
      hash: 'ESNAVT3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESNAVT3P'
    },
    {
      status: 210,
      sku: 'PM0081556',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257469',
      rtl_batch_array: '6257469',
      name_search: 'JUHACOMP',
      name: "Juniperus horizontalis 'Andorra Compact'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1312,
      chnn_stock_retail: 1312,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K238G1SY;8P31AZWY;9BR7BCDE;WDNVXGZ5;2PKZNTA8;GYH9655H;4E96WXGS;CCVXJDLZ;4EB3LNY5;A7HB96X1;BGKSEV16;XEYBETB8;NVTLTT7X',
      statusContent: 'dot',
      hash: '4V1PYV6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V1PYV6X'
    },
    {
      status: 210,
      sku: 'PM0081557',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257472',
      rtl_batch_array: '6257472',
      name_search: 'JUHWILTO',
      name: "Juniperus horizontalis 'Wiltonii'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1670,
      chnn_stock_retail: 1670,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTXR91JA;XE2PNDN2;79XC8391;KA6JEJWN;TTPAJR27;5DLCNL9T;8PDW76PN;77XT6W5A;171G33ND;HCB8CRHL;PKNT5STB;SX87XJ56',
      statusContent: 'dot',
      hash: 'EW97B87R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW97B87R'
    },
    {
      status: 210,
      sku: 'PM0081558',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257476',
      rtl_batch_array: '6257476',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '060080C34',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      sppl_stock_available: 1331,
      chnn_stock_retail: 1331,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'JT63ND2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT63ND2T'
    },
    {
      status: 210,
      sku: 'PM0074260',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268792',
      rtl_batch_array: '6268792',
      name_search: 'MISGRAZI',
      name: "Miscanthus sinensis 'Graziella'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 361,
      chnn_stock_retail: 361,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ARAYGJZC',
      statusContent: 'dot',
      hash: 'DRXLYA91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRXLYA91'
    },
    {
      status: 210,
      sku: 'PM0064861',
      core_name: 'Plant',
      sppl_ean: '8720664886964',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149375',
      rtl_batch_array: '6149375',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'CEC9258D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEC9258D'
    },
    {
      status: 210,
      sku: 'PM0068785',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208111',
      rtl_batch_array: '6208111',
      name_search: 'STRANKAN',
      name: 'Strobilanthes rankanensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 564,
      chnn_stock_retail: 564,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PESJ2CC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PESJ2CC7'
    },
    {
      status: 210,
      sku: 'PM0074261',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268793',
      rtl_batch_array: '6268793',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2957,
      chnn_stock_retail: 2957,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'N67BXKS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N67BXKS1'
    },
    {
      status: 210,
      sku: 'PM0047313',
      core_name: 'Plant',
      sppl_ean: '8720664871670',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6083851',
      rtl_batch_array: '6083851, 6252660, 5997286',
      name_search: 'LABARNSL',
      name: "Lavatera 'Barnsley'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2706,
      chnn_stock_retail: 3483,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L97DRPYN;AE9DWZBS;68VZZ3CX;SAV8VV3D;L9DYXRKW;T8A77PYA;VGS7W4N5;VCHGD2VB;SYEHZ737;KW1RGGET',
      statusContent: 'dot',
      hash: 'HDJ6EA9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDJ6EA9X'
    },
    {
      status: 210,
      sku: 'PM0081559',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257485',
      rtl_batch_array: '6257485',
      name_search: 'MIDCPRID',
      name: 'Microbiota decussata Celtic Pride',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 469,
      chnn_stock_retail: 469,
      sppl_prcp: 3.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z3DS357C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3DS357C'
    },
    {
      status: 210,
      sku: 'PM0074262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268794',
      rtl_batch_array: '6268794',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 781,
      chnn_stock_retail: 781,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      statusContent: 'dot',
      hash: 'DHZE6H28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHZE6H28'
    },
    {
      status: 210,
      sku: 'PM0046704',
      core_name: 'Plant',
      sppl_ean: '8720664694941',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875149',
      rtl_batch_array: '5875149',
      name_search: 'IRCALIGU',
      name: "Iris (G) 'Caligula'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'LN3BL2RY',
      statusContent: 'dot',
      hash: 'JZRW98EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZRW98EC'
    },
    {
      status: 210,
      sku: 'PM0068788',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301875',
      rtl_batch_array: '6301875, 6301098, 6353380',
      name_search: 'THDALBUM',
      name: "Thalictrum delavayi 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 124,
      chnn_stock_retail: 2386,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZN1ENPR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN1ENPR6'
    },
    {
      status: 210,
      sku: 'PM0068789',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208115',
      rtl_batch_array: '6208115',
      name_search: 'THDANKUM',
      name: "Thalictrum delavayi 'Ankum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NW4AB15T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW4AB15T'
    },
    {
      status: 210,
      sku: 'PM0046703',
      core_name: 'Plant',
      sppl_ean: '8720664859982',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875148',
      rtl_batch_array: '5875148',
      name_search: 'DISALBA',
      name: "Dicentra spectabilis 'Alba'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XB5C666R',
      imageBatch: '4CZ2TPNC',
      statusContent: 'dot',
      hash: 'J3SRLBJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3SRLBJB'
    },
    {
      status: 210,
      sku: 'PM0068791',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208121',
      rtl_batch_array: '6208121',
      name_search: 'TRGFPLEN',
      name: "Trillium grandiflorum 'Flore Pleno'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_order_minimum: 3,
      sppl_prcp: 10.128,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SLT811S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLT811S2'
    },
    {
      status: 810,
      sku: 'PM0058379',
      core_name: 'Plant',
      sppl_ean: '8720664871328',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112672',
      rtl_batch_array: '6112672',
      name_search: 'LAOSILVA',
      name: "Lamium orvala 'Silva'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XT13YWBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT13YWBC'
    },
    {
      status: 210,
      sku: 'PM0081560',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257487',
      rtl_batch_array: '6257487',
      name_search: 'PIGCONIC',
      name: "Picea glauca 'Conica'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 4757,
      chnn_stock_retail: 4757,
      sppl_prcp: 4.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '87PZRZ53;ER3ZT26K;1VRBWBS4;HK4179JR;8DDLCE3H;X1AB1LDA;E9G3Z1V9;8786ZZY5;NN6B56NY;9G3DD8AR;BAGHPL1E;NR6NP3GS;LVS3RC5P;W4AW6Z7S;6YXGS4YZ;GZZR5637;4N46C5N2;GYALTER1;2HGJHCJ1;XZDC85LT;5VGKVJLW;HCC43767;RERBDZS9;EVX3J1EN;D14S3D5G;79C25LCG;61JR648Z;NT5VJGRV;1K91N6WT',
      statusContent: 'dot',
      hash: 'Z8VJZ5HC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8VJZ5HC'
    },
    {
      status: 210,
      sku: 'PM0064867',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '6317948',
      rtl_batch_array: '6220882, 6215481, 6275777, 6317948, 6356274',
      name_search: 'PHRUSSEL',
      name: 'Phlomis russeliana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9082,
      chnn_stock_retail: 20153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YP42PBWA;NCT5P9T1;63VD521Y;PRAP2EJX',
      statusContent: 'dot',
      hash: 'YHNTZY4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHNTZY4L'
    },
    {
      status: 210,
      sku: 'PM0058376',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350616',
      rtl_batch_array: '6350616',
      name_search: 'KNUEMANG',
      name: "Kniphofia uvaria 'Echo Mango'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AJ3NJYX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJ3NJYX9'
    },
    {
      status: 910,
      sku: 'PM0081561',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257490',
      rtl_batch_array: '6257490',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'A1RTEY3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1RTEY3X'
    },
    {
      status: 210,
      sku: 'PM0081562',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257495',
      rtl_batch_array: '6257495',
      name_search: 'TABSEMPE',
      name: "Taxus baccata 'Semperaurea'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 391,
      chnn_stock_retail: 391,
      sppl_prcp: 4.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '65SAZJDT;N1TDHW82',
      statusContent: 'dot',
      hash: 'TJ69KSJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJ69KSJ9'
    },
    {
      status: 810,
      sku: 'PM0058387',
      core_name: 'Plant',
      sppl_ean: '8720664871830',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6171162',
      rtl_batch_array: '6171162, 6112683',
      name_search: 'LAMARITI',
      name: 'Lavatera maritima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 545,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '43XCZ7ZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43XCZ7ZL'
    },
    {
      status: 210,
      sku: 'PM0081563',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257498',
      rtl_batch_array: '6257498',
      name_search: 'TABSUMME',
      name: "Taxus baccata 'Summergold'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3046,
      chnn_stock_retail: 3046,
      sppl_prcp: 4.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KSV5SYC;J3ATLRXA;85VREB5P;1B7X7CVY;7XH9WWVH;N261GH3S',
      statusContent: 'dot',
      hash: '6XZWHKR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XZWHKR5'
    },
    {
      status: 810,
      sku: 'PM0068783',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353450',
      rtl_batch_array: '6353450',
      name_search: 'SMRACEMO',
      name: 'Smilacina racemosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2JNH8Y4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JNH8Y4W'
    },
    {
      status: 210,
      sku: 'PM0081564',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257500',
      rtl_batch_array: '6257500',
      name_search: 'TACFARME',
      name: "Taxus cuspidata 'Farmen'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 736,
      chnn_stock_retail: 736,
      sppl_prcp: 5.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WAA8K27J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAA8K27J'
    },
    {
      status: 210,
      sku: 'PM0047343',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353311',
      rtl_batch_array: '6353311',
      name_search: 'SEPTALK',
      name: "Sedum 'Pillow Talk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JT2W5EBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT2W5EBC'
    },
    {
      status: 210,
      sku: 'PM0068784',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353462',
      rtl_batch_array: '6301860, 6353462',
      name_search: 'STBCBOLL',
      name: "Stachys byzantina 'Cotton Boll'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 332,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTX57BKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTX57BKJ'
    },
    {
      status: 210,
      sku: 'PM0081565',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257501',
      rtl_batch_array: '6257501',
      name_search: 'TACNANA',
      name: 'Taxus cuspidata nana',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3094,
      chnn_stock_retail: 3094,
      sppl_prcp: 4.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '48EJ571A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48EJ571A'
    },
    {
      status: 210,
      sku: 'PM0074263',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268796',
      rtl_batch_array: '6268796',
      name_search: 'MISPUENK',
      name: "Miscanthus sinensis 'P\u00fcnktchen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TS3JZGTR;CP5C8LCK;6TRL6C1A;WYPVBVJK',
      statusContent: 'dot',
      hash: '5VN13GBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VN13GBY'
    },
    {
      status: 810,
      sku: 'PM0068790',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208120',
      rtl_batch_array: '6208120',
      name_search: 'TRFLEXIP',
      name: 'Trillium flexipes',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 3.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4W6EER1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W6EER1X'
    },
    {
      status: 210,
      sku: 'PM0064862',
      core_name: 'Plant',
      sppl_ean: '8720664887091',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149376',
      rtl_batch_array: '6149376',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.728,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: '5B7TAS5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B7TAS5E'
    },
    {
      status: 210,
      sku: 'PM0078061',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346657',
      rtl_batch_array: '6346657',
      name_search: 'HEHGKOLI',
      name: "Hedera helix 'Golden Kolibri'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9Z7KVXV;861SRW1A;XWE9ZCPY;S3H7J1DE;E51WJ31G;K259JLXN',
      statusContent: 'dot',
      hash: '7YD5TX66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YD5TX66'
    },
    {
      status: 210,
      sku: 'PM0081566',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257504',
      rtl_batch_array: '6257504',
      name_search: 'TAMDENSI',
      name: "Taxus media 'Densiformis'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1804,
      chnn_stock_retail: 1804,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1H6W6WAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H6W6WAC'
    },
    {
      status: 210,
      sku: 'PM0058386',
      core_name: 'Plant',
      sppl_ean: '8720664871762',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112682',
      rtl_batch_array: '6112682',
      name_search: 'LACFLOSS',
      name: "Lavatera 'Candy Floss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9N1WRTKK;Y4WATH64;A72KKJLY;W7T7BJ59;5ZH616L7',
      statusContent: 'dot',
      hash: '14K3CKC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14K3CKC7'
    },
    {
      status: 210,
      sku: 'PM0081567',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257505',
      rtl_batch_array: '6257505',
      name_search: 'TAMGROEN',
      name: "Taxus media 'Groenland'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GDXHH5SN;61DEPLVH;AY9THTDK;H7L6DT1P;W34X7N4E',
      statusContent: 'dot',
      hash: 'V1XB5P1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1XB5P1K'
    },
    {
      status: 210,
      sku: 'PM0081568',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257507',
      rtl_batch_array: '6257507',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '060080C34',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      sppl_stock_available: 701,
      chnn_stock_retail: 701,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'J8ZAGG44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8ZAGG44'
    },
    {
      status: 210,
      sku: 'PM0081569',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257511',
      rtl_batch_array: '6257511',
      name_search: 'THOTTIM',
      name: "Thuja occidentalis 'Tiny Tim'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2GN55EXS;BB4L3EGC;HCJCETDG;LDJZZC8X;L98DJ7YD;PEJG8Z2C;D1DCNRPN;7KREZ931;WAWALLCV;CJGE14G4;JEG7769A;BRAXLW3L;1DEDGW4T;S979L11H;CVLWZC74;S115657X;BWPZ1LLA;PKPG18XS',
      statusContent: 'dot',
      hash: 'R6XAS6BS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6XAS6BS'
    },
    {
      status: 210,
      sku: 'PM0081570',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6201420',
      rtl_batch_array: '6201420',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1370,
      chnn_stock_retail: 1370,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: 'XVB2LJCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVB2LJCJ'
    },
    {
      status: 210,
      sku: 'PM0081571',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6201421',
      rtl_batch_array: '6201421',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '060080C34',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      sppl_stock_available: 6444,
      chnn_stock_retail: 6444,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '58ZRXDTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58ZRXDTZ'
    },
    {
      status: 910,
      sku: 'PM0081572',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CACBCLIP',
      name: "Campanula carpatica 'Blaue Clips'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'YBV9DCZK;PS936ENH;PR66ZWHT;NZ5C231L;6JJK223J',
      statusContent: 'dot',
      hash: '2TPYKD47',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2TPYKD47'
    },
    {
      status: 210,
      sku: 'PM0081573',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205396',
      rtl_batch_array: '6205396',
      name_search: 'SAXPURPU',
      name: "Saxifraga (A) 'Purpurmantel'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1019,
      chnn_stock_retail: 1019,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '82LH5BT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82LH5BT9'
    },
    {
      status: 210,
      sku: 'PM0081574',
      core_name: 'Plant',
      sppl_ean: '8720664856325',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162682',
      rtl_batch_array: '6162682',
      name_search: 'CHLIVONN',
      name: "Chamaecyparis lawsoniana 'Ivonne'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1689,
      chnn_stock_retail: 1689,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1L1KXLT',
      statusContent: 'dot',
      hash: 'PH2SAKXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH2SAKXE'
    },
    {
      status: 210,
      sku: 'PM0081575',
      core_name: 'Plant',
      sppl_ean: '8720664870635',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162702',
      rtl_batch_array: '6162702',
      name_search: 'JUPMJULE',
      name: "Juniperus pfitzeriana 'Mint Julep'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2170,
      chnn_stock_retail: 2170,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ECA5W8B7;WDB1X2HH;G9494HJY;H8A3JGVH;97N7RTGT;TL2TY95E;TEPY57C3;4G143PN2;2KL5BZSJ;ZRYC7KKP;AXK4LGDR;11WNEGGE;AJ126KGJ;B7D947SS;X78L6BGC',
      statusContent: 'dot',
      hash: 'CB6G6BG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CB6G6BG9'
    },
    {
      status: 210,
      sku: 'PM0081576',
      core_name: 'Plant',
      sppl_ean: '8720664870703',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162704',
      rtl_batch_array: '6162704',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 2421,
      chnn_stock_retail: 2421,
      sppl_prcp: 3.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'A12J712V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A12J712V'
    },
    {
      status: 210,
      sku: 'PM0081577',
      core_name: 'Plant',
      sppl_ean: '8720664879133',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162708',
      rtl_batch_array: '6162708',
      name_search: 'PIGAGLOB',
      name: "Picea glauca 'Alberta Globe'",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 4209,
      chnn_stock_retail: 4209,
      sppl_prcp: 4.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV3TNY1Y;SYL9ZJWZ;WBYGXE4E;2D7SNGX3;8BXW263X;52R49AND;8174AN7A',
      statusContent: 'dot',
      hash: 'AJ8SJRXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJ8SJRXC'
    },
    {
      status: 210,
      sku: 'PM0081578',
      core_name: 'Plant',
      sppl_ean: '8720664888074',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162726',
      rtl_batch_array: '6162726',
      name_search: 'TAMGMOUN',
      name: "Taxus media 'Green Mountain'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_prcp: 3.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HKTAZ116',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKTAZ116'
    },
    {
      status: 210,
      sku: 'PM0081579',
      core_name: 'Plant',
      sppl_ean: '8720664888203',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162744',
      rtl_batch_array: '6162744',
      name_search: 'THOANANA',
      name: "Thuja occidentalis 'Aurea Nana'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1491,
      chnn_stock_retail: 1491,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPT3LGC',
      statusContent: 'dot',
      hash: '29EK9PAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29EK9PAH'
    },
    {
      status: 210,
      sku: 'PM0081580',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186660',
      rtl_batch_array: '6186660',
      name_search: 'TAMGMOUN',
      name: "Taxus media 'Green Mountain'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PPSBB656',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPSBB656'
    },
    {
      status: 210,
      sku: 'PM0081581',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229123',
      rtl_batch_array: '6229123',
      name_search: 'CADGOLD',
      name: "Campanula 'Dickson's Gold'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2964,
      chnn_stock_retail: 2964,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NS4R64PS;C78RAWYA;DHT22TP6;2DVJZGCA;8KT1RLA2;BB6J3DCA;75GPKKXW;BTN7HBKX;XPHN7HY5;T2Y64PLK;6VHTBDYE;82WLV6YK;14T4RP71;YDH5PVNG;8DXWVHGC;XTYNY3JR;S516W9Y1;CC5TBYSJ',
      statusContent: 'dot',
      hash: '6Z129WL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z129WL7'
    },
    {
      status: 210,
      sku: 'PM0081582',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236227',
      rtl_batch_array: '6236227',
      name_search: 'TABSEMPE',
      name: "Taxus baccata 'Semperaurea'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 371,
      chnn_stock_retail: 371,
      sppl_prcp: 4.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '65SAZJDT;N1TDHW82',
      statusContent: 'dot',
      hash: '5YB33YG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YB33YG1'
    },
    {
      status: 910,
      sku: 'PM0081583',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'STE120C5',
      rng_range_description: 'Stem 120 cm C5',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: '4JETGS22',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4JETGS22'
    },
    {
      status: 210,
      sku: 'PM0081584',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243721',
      rtl_batch_array: '6243721',
      name_search: 'THPMINOR',
      name: "Thymus praecox 'Minor'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DK78XSC2;Z6HTGB7A;E2AXSGRJ;44STXNVH;922B647Y;B8ECWZXH;2GEDH95J;LB9HA7PT',
      statusContent: 'dot',
      hash: '4AGA3AX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AGA3AX4'
    },
    {
      status: 210,
      sku: 'PM0081585',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244380',
      rtl_batch_array: '6244380',
      name_search: 'JUPGCOAS',
      name: "Juniperus pfitzeriana 'Gold Coast'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1126,
      chnn_stock_retail: 1126,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EKGY93JY;16ZCPHTE;SWYLL49R;RRNGWR67;4C3YTV67',
      statusContent: 'dot',
      hash: 'A3LJJ6Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3LJJ6Z4'
    },
    {
      status: 210,
      sku: 'PM0085216',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373106',
      rtl_batch_array: '6373106',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 562,
      chnn_stock_retail: 562,
      sppl_prcp: 10.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'X1DBWNNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1DBWNNY'
    },
    {
      status: 210,
      sku: 'PM0085217',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373107',
      rtl_batch_array: '6373107',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 3240,
      chnn_stock_retail: 3240,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: '4D8PT7S4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4D8PT7S4'
    },
    {
      status: 210,
      sku: 'PM0047735',
      core_name: 'Plant',
      sppl_ean: '8720664853768',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6143843',
      rtl_batch_array: '6143843, 6164381',
      name_search: 'BRMEDIA',
      name: 'Briza media',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5062,
      chnn_stock_retail: 5387,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V71BEV4S;RHLKG923;5B396BZK;C2L1NTHD',
      statusContent: 'dot',
      hash: '2CZSJ4ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CZSJ4ZD'
    },
    {
      status: 210,
      sku: 'PM0085218',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373109',
      rtl_batch_array: '6373109',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 688,
      chnn_stock_retail: 688,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'K5T1YPCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5T1YPCP'
    },
    {
      status: 210,
      sku: 'PM0046694',
      core_name: 'Plant',
      sppl_ean: '8720664695276',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875134',
      rtl_batch_array: '5875134',
      name_search: 'IRSPALAC',
      name: "Iris (G) 'Sultan's Palace'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5K19V1A5;LXD53DL3',
      imageBatch: 'ZZ9XVREW',
      statusContent: 'dot',
      hash: 'GHE695L7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHE695L7'
    },
    {
      status: 210,
      sku: 'PM0085219',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373110',
      rtl_batch_array: '6373110',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1727,
      chnn_stock_retail: 1727,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'GTHN949X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTHN949X'
    },
    {
      status: 210,
      sku: 'PM0085220',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373112',
      rtl_batch_array: '6373112',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 533,
      chnn_stock_retail: 533,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: 'TSZ3GSKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSZ3GSKN'
    },
    {
      status: 210,
      sku: 'PM0074264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268797',
      rtl_batch_array: '6268797',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      statusContent: 'dot',
      hash: 'E4HGYY16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4HGYY16'
    },
    {
      status: 210,
      sku: 'PM0085221',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373113',
      rtl_batch_array: '6373113',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 530,
      chnn_stock_retail: 530,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: 'S6PJ9YV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6PJ9YV6'
    },
    {
      status: 210,
      sku: 'PM0046752',
      core_name: 'Plant',
      sppl_ean: '8720349463824',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014459',
      rtl_batch_array: '6014459',
      name_search: 'PARCHARM',
      name: "Paeonia 'Red Charm'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 3.583,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ER3Z13KY;8WZNDC32;NC17Y5V8;74ER5G72;LKTL1C6C;73LPXL52;2YCDPH9H;ACZ63VYY;H5PCCCAG;YSRC9L3T',
      statusContent: 'dot',
      hash: 'GWWTRJ5H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWWTRJ5H'
    },
    {
      status: 210,
      sku: 'PM0054614',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317610',
      rtl_batch_array: '6267742, 6317610',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 245,
      chnn_stock_retail: 395,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'PH3GX4YN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH3GX4YN'
    },
    {
      status: 210,
      sku: 'PM0046682',
      core_name: 'Plant',
      sppl_ean: '8720664694873',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875116',
      rtl_batch_array: '5875116',
      name_search: 'IROBANDI',
      name: "Iris 'Oklahoma Bandit'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '1JCHZN6A',
      statusContent: 'dot',
      hash: 'EZH14W93',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZH14W93'
    },
    {
      status: 210,
      sku: 'PM0046839',
      core_name: 'Plant',
      sppl_ean: '8720664695085',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5914384',
      rtl_batch_array: '5914384',
      name_search: 'IRJOANNA',
      name: "Iris (G) 'Joanna'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '4C3AAXTL',
      statusContent: 'dot',
      hash: 'PYVEC3HT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYVEC3HT'
    },
    {
      status: 210,
      sku: 'PM0063571',
      core_name: 'Plant',
      sppl_ean: '8720664863460',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146972',
      rtl_batch_array: '6146972',
      name_search: 'FEMAIREI',
      name: 'Festuca mairei',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1598,
      chnn_stock_retail: 1598,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YD3S8CRA',
      statusContent: 'dot',
      hash: 'ZSS3VPKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSS3VPKR'
    },
    {
      status: 210,
      sku: 'PM0046624',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6288292',
      rtl_batch_array: '6329893, 6288292',
      name_search: 'ACMDMATT',
      name: "Acaena microphylla 'Dichte Matte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 431,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZDT4N5C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDT4N5C8'
    },
    {
      status: 210,
      sku: 'PM0058375',
      core_name: 'Plant',
      sppl_ean: '8720664695924',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112668',
      rtl_batch_array: '6112668',
      name_search: 'KNRRGLAR',
      name: "Kniphofia 'Rocket's Red Glare'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7JCBGP1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JCBGP1X'
    },
    {
      status: 910,
      sku: 'PM0052803',
      core_name: 'Plant',
      sppl_ean: '8720664685857',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5998705',
      rtl_batch_array: '5998705',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'BV2ZBEKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BV2ZBEKW'
    },
    {
      status: 210,
      sku: 'PM0064866',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6307994',
      rtl_batch_array: '6319691, 6383344, 6307994',
      name_search: 'BRMSHEAR',
      name: "Brunnera macrophylla 'Sea Heart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7645,
      chnn_stock_retail: 11991,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LYSL9PJH;S7KXG9JP',
      statusContent: 'dot',
      hash: 'C1NPLSDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1NPLSDH'
    },
    {
      status: 210,
      sku: 'PM0046670',
      core_name: 'Plant',
      sppl_ean: '8720664692541',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875078',
      rtl_batch_array: '5875078',
      name_search: 'HOELISAB',
      name: "Hosta 'Elisabeth'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RJVD66X1',
      statusContent: 'dot',
      hash: 'RVYVK3VP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVYVK3VP'
    },
    {
      status: 210,
      sku: 'PM0069160',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254758',
      rtl_batch_array: '6236077, 6254758',
      name_search: 'IBSPICE',
      name: "Iberis sempervirens 'Pink Ice'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 247,
      chnn_stock_retail: 530,
      sppl_prcp: 2.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y8BTTS28;HTX41EN2;19PJTACX;NELBPBLA;7ZNCCN6N;S589R2G9',
      statusContent: 'dot',
      hash: 'YZP5X9WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZP5X9WR'
    },
    {
      status: 210,
      sku: 'PM0058398',
      core_name: 'Plant',
      sppl_ean: '8720664800144',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112699',
      rtl_batch_array: '6112699',
      name_search: 'OPWALLIC',
      name: 'Ophiopogon wallichianus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 481,
      chnn_stock_retail: 481,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DC93GS3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC93GS3V'
    },
    {
      status: 210,
      sku: 'PM0081586',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334006',
      rtl_batch_array: '6334006',
      name_search: 'ACEPOLAC',
      name: 'Acer pal. Orange Lace',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.717,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZPLPJEB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPLPJEB7'
    },
    {
      status: 210,
      sku: 'PM0063572',
      core_name: 'Plant',
      sppl_ean: '8720664864047',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6146975',
      rtl_batch_array: '6146975, 5837429, 6313199, 6254726',
      name_search: 'GALSPINK',
      name: "Gaura lindheimeri 'Siskiyou Pink'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2350,
      chnn_stock_retail: 4272,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZWVG6YET;RD5RT11V;BYPTWR7D;KY645T1Z;5RTYJEAT;W1BWWRH1;WVNK4956;TAS4P527;2NZ14NS9',
      statusContent: 'dot',
      hash: 'PSV3TKWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSV3TKWV'
    },
    {
      status: 210,
      sku: 'PM0078062',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307604',
      rtl_batch_array: '6307604',
      name_search: 'PAEDULIS',
      name: 'Passiflora edulis',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 913,
      chnn_stock_retail: 913,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2N1JN1SY;GJC2ER3Y;YEW4Y3VD;XE76XKLH;46RCNLKX;KGJGJL9R;2XN3Z4KC;BTY7Y2T6;2GX7AE3C;J7P8NRP7;AX44BK59;YWL2RLS4;8GCVGVHK;JSTWATEY;SG3R1RB3;55VT68SG;HAE2YNHJ',
      statusContent: 'dot',
      hash: 'J8XRRCXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8XRRCXC'
    },
    {
      status: 210,
      sku: 'PM0081606',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370829',
      rtl_batch_array: '6370829',
      name_search: 'GECSOLA',
      name: "Geranium cantabrigiense 'St Ola'",
      sppl_size_code: 'GMP8',
      rng_range_identifier: 'P8',
      rng_range_description: 'P8',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4NEZK75',
      statusContent: 'dot',
      hash: 'WKKBBZHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKKBBZHW'
    },
    {
      status: 210,
      sku: 'PM0085222',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373114',
      rtl_batch_array: '6373114',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 159,
      chnn_stock_retail: 159,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: '69WXPJGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69WXPJGE'
    },
    {
      status: 210,
      sku: 'PM0081607',
      core_name: 'Plant',
      sppl_ean: '8720664810648',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5553898',
      rtl_batch_array: '5553898, 5969528, 6187625',
      name_search: 'THCBANDE',
      name: "Thymus citriodorus 'Bertram Anderson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 546,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4LBZ3SJ2;9PG1TT23;X7951SCC;5TKC6HZP;DK9KEYRL;Z36BWVRN;4GDPBTW1;SL2WA4XW;NBGR4E25;DN9RRJE9;8SY9VBP3;AGPHB6PG;7K3R1BCY;L4VCKE4K;N48GLTY4;SWKNGBYR;EX7DR2YG;JXYYZ2GS;2G2YP3N6;H3KET31Z;Z3H3THZN',
      statusContent: 'dot',
      hash: 'SJ62YK1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJ62YK1X'
    },
    {
      status: 210,
      sku: 'PM0081608',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324699',
      rtl_batch_array: '6324699, 6345224, 6363206',
      name_search: 'SANROSMA',
      name: 'Santolina rosmarinifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 3390,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLVH6NZ1;2X3BWJXE;5A87T5PA;9JLAYSG6;PC9187HN;57AZN651;JVE915SX;A1N6ZV1E;PNVWN8ND;Z59BBDWW;SZ6CKLW7;HYXCB6GK;1TT6DA7G;TCJYPSTS;KBCEZBT1;ZP6Z29DJ;XJXX1XPX;BTGVC85E',
      statusContent: 'dot',
      hash: 'DPNYLDB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPNYLDB5'
    },
    {
      status: 210,
      sku: 'PM0058009',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6291247',
      rtl_batch_array: '6350733, 6291247, 6333286, 6309497',
      name_search: 'OSREGALI',
      name: 'Osmunda regalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 12316,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6P27E8EE;1KYVGLT7;63YC456J;2R45XWGZ;BGW96VB1;5EKVGZZA;BS328D37;R2X5YBE3;1ASL4D56;P9G6B154',
      statusContent: 'dot',
      hash: 'JGDVT541',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGDVT541'
    },
    {
      status: 910,
      sku: 'PM0081609',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POTSIMEN',
      name: 'Fout neem POTSUSSI',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WKGLCZJV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WKGLCZJV'
    },
    {
      status: 210,
      sku: 'PM0081610',
      core_name: 'Plant',
      sppl_ean: '8720349453917',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5960681',
      rtl_batch_array: '5960681, 6133795',
      name_search: 'THVCOMPA',
      name: "Thymus vulgaris 'Compactus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 1482,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AS8EGAE8;E642L37J;DWHSSN46;ZSSG494G;JYSZ11W7;52TAYLKG;R4JTAD12;GY6NTNKR;4TLVP15B;ESLYKN8G;7CP55G5X;D7829T12;AEDJJDH1;JZSNZACG;PV153EBP',
      statusContent: 'dot',
      hash: 'DAWK9C3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAWK9C3A'
    },
    {
      status: 210,
      sku: 'PM0058083',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6333310',
      rtl_batch_array: '6353395, 6193691, 6333310, 6348429',
      name_search: 'THPALUST',
      name: 'Thelypteris palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1032,
      chnn_stock_retail: 2678,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6DGX93LG;T7Z4NT4K;XCLZ9ZPN;VYZ2HW3X',
      statusContent: 'dot',
      hash: 'Y9H3DPN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9H3DPN2'
    },
    {
      status: 910,
      sku: 'PM0064623',
      core_name: 'Plant',
      sppl_ean: '8720353012506',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '5742365',
      rtl_batch_array: '5742365',
      name_search: 'POCORDAT',
      name: 'Pontederia cordata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X42A92J8;C8KCKYTT;D8ER9738;SYNHCXHZ;ZJ35G3KV;LZ1WKGRK;WVTGL4CT;C4C7T9SD;5PKSY8BH;AH3SX741',
      statusContent: 'dot',
      hash: 'J6HS4996',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6HS4996'
    },
    {
      status: 210,
      sku: 'PM0068565',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307520',
      rtl_batch_array: '6307520',
      name_search: 'LOBDSCAR',
      name: "Lonicera brownii 'Dropmore Scarlet'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1986,
      chnn_stock_retail: 1986,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HRTY5Z56;KYAA5AE3;LVCECV1Z;82824D6X;PKDCCSHJ;ALG82C2J;KA8C4BHG;N3XT2CAC;CTBHCJAW;H27YVTBB;6TZJWLPY;5SGY7JHZ;YR94JL91;4A1V5NZJ;DETDPH31;NHKSVAWZ;SRZSRK37;SB3HCNRP;CPLS19Y1;A5BP2L6G;YSG8BWYW;5WK11HAP',
      statusContent: 'dot',
      hash: 'N28BT1R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N28BT1R3'
    },
    {
      status: 210,
      sku: 'PM0064879',
      core_name: 'Plant',
      sppl_ean: '8720664866102',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053905',
      rtl_batch_array: '6053905',
      name_search: 'HEKAPRIK',
      name: "Helenium 'Kleine Aprikose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9JDYST8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9JDYST8'
    },
    {
      status: 210,
      sku: 'PM0081611',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6205783',
      rtl_batch_array: '6205783, 6133790',
      name_search: 'THPPSEUD',
      name: "Thymus praecox 'Pseudolanuginosus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1732,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HKTAYBJD;LDNVXX6H;AC41KT46',
      statusContent: 'dot',
      hash: 'YEP1Z6G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEP1Z6G3'
    },
    {
      status: 810,
      sku: 'PM0068646',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307791',
      rtl_batch_array: '6307791',
      name_search: 'VACDUKE',
      name: "Vaccinium corymbosum 'Duke'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1ALCRK2',
      statusContent: 'dot',
      hash: 'KZVCJ6ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZVCJ6ZA'
    },
    {
      status: 910,
      sku: 'PM0046954',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LADUET',
      name: "Lavatera 'Duet'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'VS6VZYX6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VS6VZYX6'
    },
    {
      status: 210,
      sku: 'PM0081612',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6113114',
      rtl_batch_array: '6113114, 6215721',
      name_search: 'SAUVARIE',
      name: "Saxifraga urbium 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 459,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S1AKL6LY;755TZT6T;5VXL8BPV;L397V4ZL;1N61XDK8;WNBD16VP;Y981Y6PC;SVT1VECH;JEDYJNV1',
      statusContent: 'dot',
      hash: 'YRKJPWD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRKJPWD9'
    },
    {
      status: 210,
      sku: 'PM0081613',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177968',
      rtl_batch_array: '6177968',
      name_search: 'SAXCSSEE',
      name: "Saxifraga cotyledon 'Southside Seedling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L8DGV3CY',
      statusContent: 'dot',
      hash: '6VK2DW7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VK2DW7R'
    },
    {
      status: 210,
      sku: 'PM0081614',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242123',
      rtl_batch_array: '6014548, 6242123, 6176205, 6340055',
      name_search: 'CAPORTEN',
      name: 'Campanula portenschlagiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 19706,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NL6C3ZYC;R6133292;4AKC91CE;D1K357CG;ZLLVB5JJ;67G5Y3EH;X9STW145;18G244RN;X1P6DSJT;C3VV56AJ;R5WYL6E2;83ZSLVD6;CJ8CAS98;DRDLPA9X;2J5XY8R2;71LGVZNX;EDJV3GZJ;T88XVX92;9HPE78E6;WSYHAE15;2SH3DLNT;GWLV1WKE;EHW9KPBS;CZ9WELA2;JS9AETAL;RAZBDLCH;KGD4359H;Z4ZVNYCN;LLS5VVHH;JC2A5AHC',
      statusContent: 'dot',
      hash: 'XEGRDNHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEGRDNHW'
    },
    {
      status: 210,
      sku: 'PM0064504',
      core_name: 'Plant',
      sppl_ean: '8720664850415',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153772',
      rtl_batch_array: '6153772',
      name_search: 'ACMRCCRE',
      name: 'Achillea millefolium Rainbow Creme de la Creme',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9863E9TJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9863E9TJ'
    },
    {
      status: 210,
      sku: 'PM0064507',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6174824',
      rtl_batch_array: '6153776, 6174824',
      name_search: 'NERSNOWF',
      name: "Nepeta racemosa 'Snowflake'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 529,
      chnn_stock_retail: 1529,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLN4C3GT;P2ZL52LP',
      statusContent: 'dot',
      hash: 'Y33HHPRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y33HHPRT'
    },
    {
      status: 210,
      sku: 'PM0068747',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208010',
      rtl_batch_array: '6208010',
      name_search: 'HEDRWYE',
      name: "Hemerocallis 'Double River Wye'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 596,
      chnn_stock_retail: 596,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S2DEBYVC;WCSE4G4B;9LZX2B8E;ZLZNL9AY;GTJS88Y3;5AXAKYDY;KWT59D34',
      statusContent: 'dot',
      hash: '1P1AGYVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1P1AGYVL'
    },
    {
      status: 210,
      sku: 'PM0081615',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337715',
      rtl_batch_array: '6337715',
      name_search: 'BUFPBHEA',
      name: 'Buddleja Free Petite Blue Heaven',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRGYB1XG;6W23LEGC;AJ55KEY1;CLPSGHX5',
      statusContent: 'dot',
      hash: 'V912HE29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V912HE29'
    },
    {
      status: 210,
      sku: 'PM0081624',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321834',
      rtl_batch_array: '6321834',
      name_search: 'CHLLSPIR',
      name: "Chamaecyparis lawsoniana 'Little Spire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1161,
      chnn_stock_retail: 1161,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CK7PCN4R;8JE2N3NN;191HVJDX;RXJ927V6;NCHDJSH6',
      statusContent: 'dot',
      hash: '7WN4696J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WN4696J'
    },
    {
      status: 210,
      sku: 'PM0064886',
      core_name: 'Plant',
      sppl_ean: '8720664687813',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014888',
      rtl_batch_array: '6014888',
      name_search: 'DOPRWING',
      name: "Dodecatheon pulchellum 'Red Wings'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SVAC8ZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SVAC8ZP'
    },
    {
      status: 910,
      sku: 'PM0070059',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEHPPINK',
      name: 'Penstemon hartwegii Phoenix Pink',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'RHDR1VAY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RHDR1VAY'
    },
    {
      status: 210,
      sku: 'PM0047151',
      core_name: 'Plant',
      sppl_ean: '8720664875128',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133426',
      rtl_batch_array: '6133426, 6176558',
      name_search: 'NEVBLAUW',
      name: "Nepeta 'Veluw's Blauwtje'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 135,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WV79563J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV79563J'
    },
    {
      status: 810,
      sku: 'PM0064898',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300388',
      rtl_batch_array: '6300388',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_prcp: 6.288,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'YY9EG5A8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YY9EG5A8'
    },
    {
      status: 210,
      sku: 'PM0058275',
      core_name: 'Plant',
      sppl_ean: '8720664867673',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161755',
      rtl_batch_array: '6161755',
      name_search: 'HEGSODA',
      name: "Heuchera hybrid 'Grape Soda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 468,
      chnn_stock_retail: 468,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5RLZ7T8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RLZ7T8V'
    },
    {
      status: 810,
      sku: 'PM0046870',
      core_name: 'Plant',
      sppl_ean: '8720664684850',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014743',
      rtl_batch_array: '6014743',
      name_search: 'CHGVEGEL',
      name: "Chrysanthemum (I) 'Gart. Vegelahn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YP5YTS2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YP5YTS2C'
    },
    {
      status: 210,
      sku: 'PM0068796',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208131',
      rtl_batch_array: '6208131',
      name_search: 'UVGPALLI',
      name: 'Uvularia grandiflora pallida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HT37AZ1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT37AZ1N'
    },
    {
      status: 210,
      sku: 'PM0081625',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321835',
      rtl_batch_array: '6321835',
      name_search: 'CHLSWHIT',
      name: "Chamaecyparis lawsoniana 'Snow White'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 773,
      chnn_stock_retail: 773,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTX1CSRW;CPDEB399;W5EHGS9K;HYKWDNP2',
      statusContent: 'dot',
      hash: 'HAACG9S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAACG9S8'
    },
    {
      status: 210,
      sku: 'PM0081626',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321836',
      rtl_batch_array: '6321836',
      name_search: 'CHNPENDU',
      name: "Chamaecyparis nootkatensis 'Pendula'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_prcp: 7.887,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4SPKPYDS;ZTLGETXC',
      statusContent: 'dot',
      hash: 'XCPCC91R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCPCC91R'
    },
    {
      status: 210,
      sku: 'PM0081627',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321837',
      rtl_batch_array: '6321837',
      name_search: 'CHOLUCAS',
      name: "Chamaecyparis obtusa 'Lucas'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 526,
      chnn_stock_retail: 526,
      sppl_prcp: 4.359,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A3HLTCSY;VZ3G7VYB;W1XTZVPD;4W5GZ44A;G5D559ZV',
      statusContent: 'dot',
      hash: '4HCRRCBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HCRRCBH'
    },
    {
      status: 210,
      sku: 'PM0081628',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321838',
      rtl_batch_array: '6321838',
      name_search: 'CHTBROCK',
      name: 'Chamaecyparis thyoides Blue Rock',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 759,
      chnn_stock_retail: 759,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DHAER6YL;88SAW67R;298J346E;X2753L1P;VSWDC4CW;GK248R9A;VSD7L9G1;VX46A993',
      statusContent: 'dot',
      hash: 'T4SPV382',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4SPV382'
    },
    {
      status: 210,
      sku: 'PM0081629',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321839',
      rtl_batch_array: '6321839',
      name_search: 'CRJEVIRI',
      name: "Cryptomeria japonica 'Elegans Viridis'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1222,
      chnn_stock_retail: 1222,
      sppl_prcp: 6.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WDP3HZ7B;S7A5X2SL;4NV55KR3;JKKA9RYB;WXE1887R;YPWXRP9G;Z9PBYTR3;8PR6CKC6;LDPBVSTX;JZZ8DJP5;2GNX1XKC;XE6EAWBG;D2NXNBYB',
      statusContent: 'dot',
      hash: 'VZXR7J3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZXR7J3K'
    },
    {
      status: 210,
      sku: 'PM0081630',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321840',
      rtl_batch_array: '6321840',
      name_search: 'CRJYOKOH',
      name: "Cryptomeria japonica 'Yokohama'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 936,
      chnn_stock_retail: 936,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X546HZJP;E39TP22A;KT47L89S;AJNXVDWC;RNWPNDE8;XCRRG7TG;YE6394HL;1H5S76ES;K2CD2PZC',
      statusContent: 'dot',
      hash: '78EVWPNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78EVWPNA'
    },
    {
      status: 210,
      sku: 'PM0064503',
      core_name: 'Plant',
      sppl_ean: '8720664878068',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153771',
      rtl_batch_array: '6153771',
      name_search: 'PHPKISS',
      name: "Phlox (P) 'Purple Kiss'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LT5H5Y5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT5H5Y5Z'
    },
    {
      status: 210,
      sku: 'PM0058389',
      core_name: 'Plant',
      sppl_ean: '8720664871885',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112685',
      rtl_batch_array: '6112685',
      name_search: 'LEABSNOW',
      name: 'Leontopodium alpinum Blossom of Snow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LH6ZCEX7',
      statusContent: 'dot',
      hash: 'LTL82ZXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTL82ZXX'
    },
    {
      status: 210,
      sku: 'PM0085223',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373115',
      rtl_batch_array: '6373115',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 648,
      chnn_stock_retail: 648,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NHADEWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NHADEWG'
    },
    {
      status: 810,
      sku: 'PM0047358',
      core_name: 'Plant',
      sppl_ean: '8720664687806',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054566',
      rtl_batch_array: '6054566',
      name_search: 'DOMALBUM',
      name: "Dodecatheon meadia 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APNRJE2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APNRJE2X'
    },
    {
      status: 210,
      sku: 'PM0081631',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321841',
      rtl_batch_array: '6321841',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '080100C4',
      rng_range_identifier: 'H080100C4',
      sppl_stock_available: 16359,
      chnn_stock_retail: 16359,
      sppl_prcp: 4.318,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'PYJ5984W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYJ5984W'
    },
    {
      status: 210,
      sku: 'PM0081632',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321842',
      rtl_batch_array: '6321842',
      name_search: 'JUCBALPS',
      name: "Juniperus chinensis 'Blue Alps'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2209,
      chnn_stock_retail: 2209,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H822KC9;WZD3AJ7H;DBAC7VR1;YED299HD;6TR8KJEW;YCP4NESG;WH27KK7Z;GKK13P73;Y2J5YSEX;YB3HA8X7;5HN5TXHC;9K9Z11JB;JVG243P3;H4E4VNX3;D25GXCTE;ZV74J6G9;PNK11B3H',
      statusContent: 'dot',
      hash: '85E1WAGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85E1WAGG'
    },
    {
      status: 810,
      sku: 'PM0066515',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014806',
      rtl_batch_array: '6014806',
      name_search: 'EDI61639',
      name: "Crocus tommasinianus 'Ruby Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DZKNS1X4;4W7T6DL9;PYDSSH5N;N82X67J8;G35C99BJ;NR4VXZ27;NEA84TRY;K4ARXVN4;8WX7B3NP;2713TX5G;1LYVHD86;L6PV59V4;BR1DZ3VW;DGYR6ED4;KWZGEHPG;K52L7Y37;483DYG9N;ZEXJGC2N;73J6XHG5;G3EA6SKH;N7RWKS73;8W5SE7LW;E6SKEXWB;JBAHT6DY;YW578GYD',
      statusContent: 'dot',
      hash: 'AW8PXLGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AW8PXLGA'
    },
    {
      status: 210,
      sku: 'PM0064757',
      core_name: 'Plant',
      sppl_ean: '8720664859913',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140844',
      rtl_batch_array: '6140844',
      name_search: 'DIKHEART',
      name: "Dicentra 'King of Hearts'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPKDZKRV;JPXT7X7C;TZN8G6HA;BY8GJYSZ',
      statusContent: 'dot',
      hash: '5TBG39AZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TBG39AZ'
    },
    {
      status: 210,
      sku: 'PM0081633',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321843',
      rtl_batch_array: '6321843',
      name_search: 'JUCKETEL',
      name: "Juniperus chinensis 'Keteleeri'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D6X4L5K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6X4L5K2'
    },
    {
      status: 210,
      sku: 'PM0081634',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321844',
      rtl_batch_array: '6321844',
      name_search: 'JUHIBLUE',
      name: 'Juniperus horizontalis Icee Blue',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 3290,
      chnn_stock_retail: 3290,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJ9G5WJD;7NTBHCZL;H2GTDSY9;5BSC3EEE;WDNEBS37;863LXCGJ;Y5TCTSTH',
      statusContent: 'dot',
      hash: 'LZWTZ73C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZWTZ73C'
    },
    {
      status: 210,
      sku: 'PM0064498',
      core_name: 'Plant',
      sppl_ean: '8720664884977',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6160883',
      rtl_batch_array: '6160883',
      name_search: 'SANOBELI',
      name: "Sambucus nigra 'Obelisk'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPDB3Y5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPDB3Y5X'
    },
    {
      status: 210,
      sku: 'PM0063591',
      core_name: 'Plant',
      sppl_ean: '8720664859944',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147619',
      rtl_batch_array: '6147619',
      name_search: 'DIFAUROR',
      name: "Dicentra formosa 'Aurora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G43G641K',
      statusContent: 'dot',
      hash: 'SAX1V896',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAX1V896'
    },
    {
      status: 210,
      sku: 'PM0081635',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321845',
      rtl_batch_array: '6321845',
      name_search: 'JUHPANCA',
      name: "Juniperus horizontalis 'Pancake'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 889,
      chnn_stock_retail: 889,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7C982RXG;2G65AWW7;7L3A72AX;D5T9ZSJS;BWCWH3W9;K1SAXYGH',
      statusContent: 'dot',
      hash: 'XA2BP98V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA2BP98V'
    },
    {
      status: 210,
      sku: 'PM0064502',
      core_name: 'Plant',
      sppl_ean: '8720664877757',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153770',
      rtl_batch_array: '6153770',
      name_search: 'PHPHILL',
      name: "Phlox 'Pinky Hill'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KE35Z1X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE35Z1X1'
    },
    {
      status: 210,
      sku: 'PM0081636',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321846',
      rtl_batch_array: '6321846',
      name_search: 'JUSMEYER',
      name: "Juniperus squamata 'Meyeri'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 978,
      chnn_stock_retail: 978,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JALLJG7A;6LATRALB;RV8LTDSA;KTA14B8N;HVCW8NZ7',
      statusContent: 'dot',
      hash: 'ZL5NAN54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZL5NAN54'
    },
    {
      status: 210,
      sku: 'PM0066516',
      core_name: 'Plant',
      sppl_ean: '8720349489756',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420613',
      rtl_batch_array: '5420613',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 3413,
      chnn_stock_retail: 3413,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: 'ZATXZNHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZATXZNHL'
    },
    {
      status: 210,
      sku: 'PM0064915',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300268',
      rtl_batch_array: '6300268',
      name_search: 'WEMINUET',
      name: "Weigela 'Minuet'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3090,
      chnn_stock_retail: 3090,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8TL2J5LT;EHZBBASD;J3KLG283;15NTZ5YN;47VWY2KL',
      statusContent: 'dot',
      hash: 'VGPCKGE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGPCKGE1'
    },
    {
      status: 210,
      sku: 'PM0053392',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242089',
      rtl_batch_array: '6242089',
      name_search: 'AGVCAP',
      name: "Agapanthus 'Vall\u00e9e du Cap'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52YLSW25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52YLSW25'
    },
    {
      status: 210,
      sku: 'PM0081637',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321847',
      rtl_batch_array: '6321847',
      name_search: 'PONKCREA',
      name: "Podocarpus nivalis 'Kilworth Cream'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 411,
      chnn_stock_retail: 411,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KVX5KRY;GKX1243D;THN9LND5;6EDGJLVP',
      statusContent: 'dot',
      hash: 'W2RLB5TE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2RLB5TE'
    },
    {
      status: 210,
      sku: 'PM0070843',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307305',
      rtl_batch_array: '6307305',
      name_search: 'CLBAGATE',
      name: "Clematis 'Bagatelle'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BEPLHCND',
      statusContent: 'dot',
      hash: 'BRJ7W5G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRJ7W5G4'
    },
    {
      status: 210,
      sku: 'PM0081638',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321848',
      rtl_batch_array: '6321848',
      name_search: 'SEGGLAUC',
      name: "Sequoiadendron giganteum 'Glaucum'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_prcp: 7.446,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7XR16YPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XR16YPH'
    },
    {
      status: 210,
      sku: 'PM0068746',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6302577',
      rtl_batch_array: '6208008, 6302577, 6324693',
      name_search: 'HEASNOW',
      name: "Hemerocallis 'Arctic Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1529,
      chnn_stock_retail: 2545,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW23NYKV',
      statusContent: 'dot',
      hash: '2V35N1EW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V35N1EW'
    },
    {
      status: 210,
      sku: 'PM0081639',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321849',
      rtl_batch_array: '6321849',
      name_search: 'TAMGROEN',
      name: "Taxus media 'Groenland'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 9905,
      chnn_stock_retail: 9905,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GDXHH5SN;61DEPLVH;AY9THTDK;H7L6DT1P;W34X7N4E',
      statusContent: 'dot',
      hash: '5CXPJXTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CXPJXTR'
    },
    {
      status: 210,
      sku: 'PM0057527',
      core_name: 'Plant',
      sppl_ean: '8720664859364',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6149694',
      rtl_batch_array: '6149694, 6375763',
      name_search: 'DEBKNIGH',
      name: "Delphinium (P) 'Black Knight'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2413,
      chnn_stock_retail: 3413,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDY4YACC;N9SS16PZ;82N8G81A;TRYN5EEB;7XHRDBPR;6X5EDLN8;KNAPVBPA;BVE2V7VK;P1EZSB4H;G2GR3D61',
      statusContent: 'dot',
      hash: 'PY7ZZ7HG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY7ZZ7HG'
    },
    {
      status: 210,
      sku: 'PM0085224',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373116',
      rtl_batch_array: '6373116',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2377,
      chnn_stock_retail: 2377,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4ZD9CTC5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZD9CTC5'
    },
    {
      status: 210,
      sku: 'PM0081641',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321851',
      rtl_batch_array: '6321851',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 1545,
      chnn_stock_retail: 1545,
      sppl_prcp: 11.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: '5BTGVAHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BTGVAHY'
    },
    {
      status: 210,
      sku: 'PM0070846',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307309',
      rtl_batch_array: '6307309',
      name_search: 'CLCBOUCH',
      name: "Clematis 'Comtesse de Bouchaud'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAEED98R;2DVSRGTD;CEEW1C7P;7D4CDBA5;Y2HBR3ZW',
      statusContent: 'dot',
      hash: 'NGBE3WTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGBE3WTA'
    },
    {
      status: 210,
      sku: 'PM0081642',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321853',
      rtl_batch_array: '6321853',
      name_search: 'THPWHIPC',
      name: "Thuja plicata 'Whipcord'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PTZZ7EXJ;BHA97TVL;T6ADDNB5;CBSAJZHC;A6EHTCB8;8DBK8RE8;D6VNRE6D;AZKRBE9J;5ARLXDVH;83G54CZ8',
      statusContent: 'dot',
      hash: 'WYAH9LVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYAH9LVT'
    },
    {
      status: 210,
      sku: 'PM0081643',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321854',
      rtl_batch_array: '6321854',
      name_search: 'TSCCPROS',
      name: "Tsuga canadensis 'Cole's Prostrate'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '17J163JA;1DXN3TYX;1XVRVR53;D5VACVVP;K1713X82;DZBSCBCP',
      statusContent: 'dot',
      hash: '77JE568S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77JE568S'
    },
    {
      status: 910,
      sku: 'PM0081644',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LADECIDU',
      name: 'Larix decidua',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      imageCore: '7ZC1LAAX',
      statusContent: 'dot',
      hash: '922RE42T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '922RE42T'
    },
    {
      status: 210,
      sku: 'PM0057530',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259155',
      rtl_batch_array: '6259155',
      name_search: 'HEDGOLD',
      name: "Hemerocallis 'Dutch Gold'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NDK9SDT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDK9SDT1'
    },
    {
      status: 210,
      sku: 'PM0081645',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321857',
      rtl_batch_array: '6321857',
      name_search: 'PIPGLAUC',
      name: 'Picea pungens glauca',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 757,
      chnn_stock_retail: 757,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7WZ71PB;1E7VWEBT;ZXLNRY7X;6A46T79J;KSA892BJ;2LSJ3D1W;P1J1D3J3;HSDZZ63Y;L7BBAR18;HXYVN768;G55ASPH1;DLZNXRXV;5S453W62;JNTNCC66;WV7RBSJT;J5PT7Y91;69SGPLWX;LS4H5L6W;DGRWLT63;2L7D5TTH',
      statusContent: 'dot',
      hash: 'Z4153BRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4153BRV'
    },
    {
      status: 210,
      sku: 'PM0085225',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373117',
      rtl_batch_array: '6373117',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KSDVP98N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSDVP98N'
    },
    {
      status: 210,
      sku: 'PM0081646',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321858',
      rtl_batch_array: '6321858',
      name_search: 'PISTROBU',
      name: 'Pinus strobus',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 955,
      chnn_stock_retail: 955,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPB3A6KX;HKZW3981;41TJTY3V;BR55WZEH',
      statusContent: 'dot',
      hash: 'H7A31DJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7A31DJZ'
    },
    {
      status: 210,
      sku: 'PM0085226',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373118',
      rtl_batch_array: '6373118',
      name_search: 'DISPLEND',
      name: 'Diervilla splendens',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YC3XYYBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YC3XYYBT'
    },
    {
      status: 210,
      sku: 'PM0064505',
      core_name: 'Plant',
      sppl_ean: '8720664875074',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153773',
      rtl_batch_array: '6153773',
      name_search: 'NEPBLUE',
      name: "Nepeta 'Purssian Blue'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNNBGAYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNNBGAYT'
    },
    {
      status: 210,
      sku: 'PM0081648',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324884',
      rtl_batch_array: '6324884',
      name_search: 'LADECIDU',
      name: 'Larix decidua',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 604,
      chnn_stock_retail: 604,
      sppl_prcp: 10.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZC1LAAX',
      statusContent: 'dot',
      hash: '1CJW3WHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CJW3WHE'
    },
    {
      status: 210,
      sku: 'PM0081649',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329716',
      rtl_batch_array: '6329716',
      name_search: 'THPKBEAU',
      name: "Thuja plicata 'Kager's Beauty'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 3.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58BS9PEY;J4PSVNCG;647RLR4L;21P9D4Y3',
      statusContent: 'dot',
      hash: '1S16PDLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1S16PDLA'
    },
    {
      status: 210,
      sku: 'PM0081650',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339259',
      rtl_batch_array: '6339259',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 10959,
      chnn_stock_retail: 10959,
      sppl_prcp: 3.285,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '6H1LY5L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H1LY5L6'
    },
    {
      status: 210,
      sku: 'PM0054647',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307538',
      rtl_batch_array: '6307538',
      name_search: 'MABETTY',
      name: "Magnolia 'Betty'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12YLB58;V48KACWP',
      statusContent: 'dot',
      hash: 'BW79BHS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BW79BHS4'
    },
    {
      status: 910,
      sku: 'PM0081651',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341723',
      rtl_batch_array: '6341723',
      name_search: 'PIANIDIF',
      name: "Picea abies 'Nidiformis'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SDXTPGH;ZS2L161S;B24CVBXA;GPRNNAHK;Z4NB8RW8;WK976K43;H2XA7VDP;TA5725B7',
      statusContent: 'dot',
      hash: 'LGRA3B4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGRA3B4K'
    },
    {
      status: 210,
      sku: 'PM0064501',
      core_name: 'Plant',
      sppl_ean: '8720664877948',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153768',
      rtl_batch_array: '6153768',
      name_search: 'PHFFANCY',
      name: "Phlox (P) 'Fondant Fancy'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CNEY7RXY;GY82DAYB;1SWHLZJJ;9X863VV6;TSJ3XBGY;LBA94JSV;5JDWJK4J;RJT68SDE;89ZP2YP9;PBZKX2EN',
      statusContent: 'dot',
      hash: 'LYA17TPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYA17TPA'
    },
    {
      status: 210,
      sku: 'PM0081652',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341724',
      rtl_batch_array: '6341724',
      name_search: 'THOSALLA',
      name: "Thuja occidentalis 'Salland'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 417,
      chnn_stock_retail: 417,
      sppl_prcp: 3.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R57W1AD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R57W1AD4'
    },
    {
      status: 210,
      sku: 'PM0070811',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307336',
      rtl_batch_array: '6307336',
      name_search: 'CLMCHOLM',
      name: "Clematis 'Mrs Cholmondeley'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1423,
      chnn_stock_retail: 1423,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C5P8XSY4;DEP5KBJD;D281NRZZ;DENKCZBN;PX61BP1B;V3ENCL6H;BREKHDXT;GPP4CA7R;GD1LVG1T',
      statusContent: 'dot',
      hash: 'LY51LRTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY51LRTN'
    },
    {
      status: 210,
      sku: 'PM0047737',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 5,
      btch_active_retail: '6217017',
      rtl_batch_array: '6242139, 5960144, 6217017, 6276025, 6193719',
      name_search: 'CAMUSKIN',
      name: 'Carex muskingumensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 369,
      chnn_stock_retail: 4466,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLSDJ67V;CLKK4ADP;VGHX8BDH;27JCTENL;GW3RHL98;DH6SHBYB;YP4YR2XK',
      statusContent: 'dot',
      hash: 'PJXN4TD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJXN4TD6'
    },
    {
      status: 910,
      sku: 'PM0081653',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUFGOLDS',
      name: "Rudbeckia fulgida 'Goldschirm'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '1A8AK14J;SKKHJLD4;T5AJ59LG;DL4LLZLD;NRGE87R2;2ZJ33W19;H35TTRK1;BNVR3KDH;X5EDA2SS;5GWY8B88;K164SZAV;GY7HNLJY;WVY8PCPT;NH14WR71;YH1B9D26;LL4L5DZ9;5LC1GNHN',
      statusContent: 'dot',
      hash: 'XNJH5KL7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XNJH5KL7'
    },
    {
      status: 210,
      sku: 'PM0046137',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329978',
      rtl_batch_array: '6329978',
      name_search: 'JUENSIFO',
      name: 'Juncus ensifolius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1PAVS63C;4SVHXCTY;EJ5C7BY9;5AAZ1N5G;LXZ4TTL3;S67N84WL;7RP8CECD',
      statusContent: 'dot',
      hash: 'H61JAGT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H61JAGT2'
    },
    {
      status: 210,
      sku: 'PM0047728',
      core_name: 'Plant',
      sppl_ean: '8720349412204',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6006958',
      rtl_batch_array: '6006958',
      name_search: 'CARFALLS',
      name: 'Carex Ribbon Falls',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 2.544,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1AGT6454;EBRZ94TE;D1JKT933;BHBLYR23;PNVB286B;J5XWYE65;9LVK19AC;89Y7DPVH;2HKGHD6L;YKELS7XA;S8KK36Y9;RXS2TWY6;P1N41PP6;7VJXERXR;79VXNCWR;ES3GG9YY;RA1GP1K1;K523XY2D;66DHTSBS;LTYABY8P;CVETVTEK;8ZJ2KYE5;KEG2T3CR;Y1AGPGZY;6V9PHSX9',
      statusContent: 'dot',
      hash: 'CZVYCTXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZVYCTXK'
    },
    {
      status: 210,
      sku: 'PM0081654',
      core_name: 'Plant',
      sppl_ean: '8720349430833',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '4358729',
      rtl_batch_array: '4358729, 6348860',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 3000,
      sppl_prcp: 5.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      imageBatch: 'S6R5KXYJ',
      statusContent: 'dot',
      hash: 'JAZS9XTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAZS9XTR'
    },
    {
      status: 210,
      sku: 'PM0081655',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355348',
      rtl_batch_array: '6355348, 5206980',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 345,
      chnn_stock_retail: 1845,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'K4V1YECK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4V1YECK'
    },
    {
      status: 210,
      sku: 'PM0064743',
      core_name: 'Plant',
      sppl_ean: '8720664864283',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6165717',
      rtl_batch_array: '6140830, 6165717',
      name_search: 'GEJBLUE',
      name: "Geranium 'Johnson's Blue'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 19157,
      chnn_stock_retail: 20157,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8Y833LL;8KYS292B;5Y865CZD;SNTR5ZAT;H9JAND4T;NAJCXDPP;JBC8W97N;4W67GJP2;JWYSAJEN;CS5G2HB9;B66LCXLN;P8H3XAAB;HZ7K52ZS;TRGRSEXB;HN27NEPC;PJG195K9',
      statusContent: 'dot',
      hash: 'RC715B5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC715B5E'
    },
    {
      status: 210,
      sku: 'PM0081656',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207214',
      rtl_batch_array: '5207214',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '040045C10',
      rng_range_identifier: 'H040045C10',
      rng_range_description: 'H40 cm 45 cm C10',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 8.933,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'DXKGCCEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXKGCCEL'
    },
    {
      status: 210,
      sku: 'PM0081657',
      core_name: 'Plant',
      sppl_ean: '8720626365681',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207226',
      rtl_batch_array: '5207226',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 2.332,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      imageBatch: 'AW7HE393',
      statusContent: 'dot',
      hash: 'AVG3759T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVG3759T'
    },
    {
      status: 210,
      sku: 'PM0081658',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370828',
      rtl_batch_array: '6370828, 5303742, 6355380',
      name_search: 'THPCOCCI',
      name: "Thymus praecox 'Coccineus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1382,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V56H41N3;HDKXZNCJ;81ER9S3T;9ZR13NZZ;9GTHBHVG;DHB5ALLX',
      statusContent: 'dot',
      hash: '1CHKG9KV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CHKG9KV'
    },
    {
      status: 210,
      sku: 'PM0081659',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6269610',
      rtl_batch_array: '6192658, 6269610, 5495223, 6187518',
      name_search: 'CALLANNA',
      name: "Campanula lactiflora 'Loddon Anna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 3266,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLGR2TLV;TXSRV1Y8;E4ETRD3J;5YPPKE9L',
      statusContent: 'dot',
      hash: '8RS2BLVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RS2BLVN'
    },
    {
      status: 210,
      sku: 'PM0081660',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6350408',
      rtl_batch_array: '6350408, 5364172, 6333260, 6352652',
      name_search: 'HEGSPICE',
      name: "Heuchera 'Green Spice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 2755,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KVZBHAXV;2LRAZGH3;RDE55LYT;RBGXTXSH;ZPCHD1VG;VYRRWBY7',
      statusContent: 'dot',
      hash: 'G7A7G2TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7A7G2TW'
    },
    {
      status: 210,
      sku: 'PM0058060',
      core_name: 'Plant',
      sppl_ean: '8720664850576',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084987',
      rtl_batch_array: '6084987, 6193704, 6349737',
      name_search: 'ACCALAMU',
      name: 'Acorus calamus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1005,
      chnn_stock_retail: 2145,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2TECA5PZ;ZLLZ5B35;7GL6K3WH',
      statusContent: 'dot',
      hash: '8RDA2SDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RDA2SDW'
    },
    {
      status: 210,
      sku: 'PM0074265',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268800',
      rtl_batch_array: '6268800',
      name_search: 'MISYDANC',
      name: "Miscanthus sinensis 'Yaka Dance'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R7JGPK6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7JGPK6R'
    },
    {
      status: 210,
      sku: 'PM0066512',
      core_name: 'Plant',
      sppl_ean: '8720664509290',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054532',
      rtl_batch_array: '6054532, 6352628',
      name_search: 'CHRVIRGI',
      name: 'Chrysogonum virginianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 137,
      chnn_stock_retail: 217,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V9TWBP9X',
      statusContent: 'dot',
      hash: '54B1NARZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54B1NARZ'
    },
    {
      status: 210,
      sku: 'PM0064509',
      core_name: 'Plant',
      sppl_ean: '8720664850217',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153779',
      rtl_batch_array: '6153779',
      name_search: 'ACPBELIN',
      name: "Achillea 'Pretty Belinda'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R1THLCZ6;DXTXYVJC',
      statusContent: 'dot',
      hash: 'RPNWYW31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPNWYW31'
    },
    {
      status: 810,
      sku: 'PM0046883',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301603',
      rtl_batch_array: '6301603',
      name_search: 'GEPIVAN',
      name: "Geranium psilostemon 'Ivan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HSEYCP7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSEYCP7V'
    },
    {
      status: 210,
      sku: 'PM0057529',
      core_name: 'Plant',
      sppl_ean: '8720664863385',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6093709',
      rtl_batch_array: '6093709',
      name_search: 'FEAMETHY',
      name: 'Festuca amethystina',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YXG8VWD7;CTG6TLJB;HKDV9HD9;BL12J4VP;VXELKNDH;ZNWD17RV;PZ9AJEL6;C2WLXBY4;Y6241XHY;X14EC4TP;4WLKTNPN',
      statusContent: 'dot',
      hash: 'R5YK1P4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5YK1P4K'
    },
    {
      status: 210,
      sku: 'PM0063594',
      core_name: 'Plant',
      sppl_ean: '8720664864818',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147635',
      rtl_batch_array: '6147635',
      name_search: 'GEOROSEN',
      name: "Geranium oxonianum 'Rosenlicht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WWZTE8V8;84LSH97N',
      statusContent: 'dot',
      hash: 'PY6JK15T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY6JK15T'
    },
    {
      status: 810,
      sku: 'PM0068629',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307600',
      rtl_batch_array: '6307600',
      name_search: 'PAAMETHY',
      name: "Passiflora 'Amethyst'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC87VC64;7RT8X2L3;HVKHYL9L;KVHJTPBP',
      statusContent: 'dot',
      hash: 'RLCSAVDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLCSAVDV'
    },
    {
      status: 210,
      sku: 'PM0057523',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 6,
      btch_active_retail: '6197012',
      rtl_batch_array: '6298533, 6243116, 6197012, 6093544, 6206043, 6234612',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 16027,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: 'RSYXXXJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSYXXXJY'
    },
    {
      status: 210,
      sku: 'PM0057989',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349126',
      rtl_batch_array: '6349126, 6176496, 6193636, 6348346',
      name_search: 'IRVERSIC',
      name: 'Iris versicolor',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 888,
      chnn_stock_retail: 2368,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZV18S1AX;2P6XSZ4W;T4GKYG5Z;W5H3K7KB;A4RGBV1R',
      statusContent: 'dot',
      hash: 'WZXSN8CL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZXSN8CL'
    },
    {
      status: 210,
      sku: 'PM0064508',
      core_name: 'Plant',
      sppl_ean: '8720664875296',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153777',
      rtl_batch_array: '6153777',
      name_search: 'NERBWOND',
      name: "Nepeta racemosa 'Blue Wonder'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S1S6ZA5E;XRLETWS2;84EDN17K',
      statusContent: 'dot',
      hash: '7WXA489V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WXA489V'
    },
    {
      status: 210,
      sku: 'PM0058400',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208061',
      rtl_batch_array: '6208061',
      name_search: 'ORRDFAIR',
      name: "Origanum r. 'Dingle Fairy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 122,
      chnn_stock_retail: 122,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EZ2LSX9P;RCA42WG2',
      statusContent: 'dot',
      hash: 'SGDES6HG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGDES6HG'
    },
    {
      status: 910,
      sku: 'PM0064891',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'SW16ZV5C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SW16ZV5C'
    },
    {
      status: 210,
      sku: 'PM0046209',
      core_name: 'Plant',
      sppl_ean: '8720349422067',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5960182',
      rtl_batch_array: '6349137, 5960182, 6339840',
      name_search: 'LYFCUCUL',
      name: 'Lychnis flos-cuculi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 1628,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WT5YWS4D;R1ZGX79Z;R41W4WW2;1NWBL694;NHL29RRR;2DZK58EY;ATHYDT5S;6R72P95D;56T55A5K',
      statusContent: 'dot',
      hash: 'ZPJT9EDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPJT9EDA'
    },
    {
      status: 210,
      sku: 'PM0058396',
      core_name: 'Plant',
      sppl_ean: '8720664697331',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112696',
      rtl_batch_array: '6112696',
      name_search: 'LELUNA',
      name: "Leucanthemum (S) 'Luna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TY47G87B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TY47G87B'
    },
    {
      status: 210,
      sku: 'PM0046854',
      core_name: 'Plant',
      sppl_ean: '8720664695108',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5916143',
      rtl_batch_array: '5916143',
      name_search: 'IRMRIDGE',
      name: "Iris (G) 'Mission Ridge'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RA7C2YHR',
      statusContent: 'dot',
      hash: '2LHL3R8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LHL3R8L'
    },
    {
      status: 810,
      sku: 'PM0033994',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5606754',
      rtl_batch_array: '5606754',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 4.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: '23XG7Z26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23XG7Z26'
    },
    {
      status: 210,
      sku: 'PM0058393',
      core_name: 'Plant',
      sppl_ean: '8720664872028',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6137007',
      rtl_batch_array: '6112693, 6137007',
      name_search: 'LECHAGEM',
      name: "Leucanthemum (S) 'Christine Hagemann'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V3VK7KS',
      statusContent: 'dot',
      hash: 'JN5ARSKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN5ARSKD'
    },
    {
      status: 210,
      sku: 'PM0074268',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268803',
      rtl_batch_array: '6268803',
      name_search: 'PEKAHIL',
      name: 'Persicaria kahil',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E79WS3GT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E79WS3GT'
    },
    {
      status: 210,
      sku: 'PM0058411',
      core_name: 'Plant',
      sppl_ean: '8720664876262',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112722',
      rtl_batch_array: '6112722',
      name_search: 'PAPFAY',
      name: "Paeonia (LD) 'Paula Fay'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5KEC3JPS;L6HY8VLA',
      statusContent: 'dot',
      hash: 'XYJZKG5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYJZKG5A'
    },
    {
      status: 910,
      sku: 'PM0081587',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYBLOOMI',
      name: 'Syringa Blooming Mix',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      statusContent: 'dot',
      hash: 'AEP79GDK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AEP79GDK'
    },
    {
      status: 210,
      sku: 'PM0070063',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220369',
      rtl_batch_array: '6220369',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 920,
      chnn_stock_retail: 920,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      statusContent: 'dot',
      hash: 'Z787Z4CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z787Z4CD'
    },
    {
      status: 210,
      sku: 'PM0085227',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373119',
      rtl_batch_array: '6373119',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2735,
      chnn_stock_retail: 2735,
      sppl_prcp: 3.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'HZ65L46D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZ65L46D'
    },
    {
      status: 210,
      sku: 'PM0078063',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307605',
      rtl_batch_array: '6307605',
      name_search: 'PAPHAZE',
      name: "Passiflora 'Purple Haze'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1261,
      chnn_stock_retail: 1261,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZESWHSY;SZPJKE33;R45DRPS8',
      statusContent: 'dot',
      hash: 'XRN8JYPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRN8JYPD'
    },
    {
      status: 210,
      sku: 'PM0078064',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307606',
      rtl_batch_array: '6307606',
      name_search: 'PASQUEEN',
      name: "Passiflora 'Snow Queen'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 394,
      chnn_stock_retail: 394,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGS8T2AW;HV75XR27;K2HEXDS2;2EBELL92;72SZKLW6',
      statusContent: 'dot',
      hash: 'HNDA8W65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNDA8W65'
    },
    {
      status: 210,
      sku: 'PM0078066',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307610',
      rtl_batch_array: '6307610',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_prcp: 33.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'K5CB7GN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5CB7GN5'
    },
    {
      status: 210,
      sku: 'PM0068810',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244390',
      rtl_batch_array: '6244390',
      name_search: 'TAMGROEN',
      name: "Taxus media 'Groenland'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1393,
      chnn_stock_retail: 1393,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GDXHH5SN;61DEPLVH;AY9THTDK;H7L6DT1P;W34X7N4E',
      statusContent: 'dot',
      hash: '8ZZGGX5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZZGGX5B'
    },
    {
      status: 210,
      sku: 'PM0078067',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345503',
      rtl_batch_array: '6345503',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 39.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'GGTYV27W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGTYV27W'
    },
    {
      status: 210,
      sku: 'PM0081616',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385814',
      rtl_batch_array: '6385814, 6344812',
      name_search: 'BULOCHIN',
      name: "Buddleja 'Lochinch'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 957,
      chnn_stock_retail: 3081,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GXTTXG5;EKY59A7K;VYLJHNCG;JJ9B2SR1;45TG8BZ3;EY83V219;LVSBYP12',
      statusContent: 'dot',
      hash: '1GA1Z541',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GA1Z541'
    },
    {
      status: 210,
      sku: 'PM0081617',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6344813',
      rtl_batch_array: '6378424, 6344813',
      name_search: 'BUDBCREA',
      name: 'Buddleja davidii Berries & Cream',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1375,
      chnn_stock_retail: 6589,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SW81GSW4;Y38WK3LS;98CEJ9P9;S12EZ3SV;C8V96LRP',
      statusContent: 'dot',
      hash: 'PLT22K8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLT22K8N'
    },
    {
      status: 810,
      sku: 'PM0078070',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346729',
      rtl_batch_array: '6346729',
      name_search: 'SAALBA',
      name: 'Salix alba',
      sppl_size_code: '150STC15',
      rng_range_identifier: 'STE150C15',
      rng_range_description: 'Stem 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V15JV75R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V15JV75R'
    },
    {
      status: 210,
      sku: 'PM0081618',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344814',
      rtl_batch_array: '6344814',
      name_search: 'CHGOLDFI',
      name: 'Choisya Goldfingers',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1787,
      chnn_stock_retail: 1787,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E5PBBJ9;TX8TB739;B3L4GZP6',
      statusContent: 'dot',
      hash: 'CHBL35EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHBL35EV'
    },
    {
      status: 210,
      sku: 'PM0081619',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344815',
      rtl_batch_array: '6344815',
      name_search: 'CHGOLDFI',
      name: 'Choisya Goldfingers',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 347,
      chnn_stock_retail: 347,
      sppl_prcp: 5.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E5PBBJ9;TX8TB739;B3L4GZP6',
      statusContent: 'dot',
      hash: '6PTRA52K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PTRA52K'
    },
    {
      status: 210,
      sku: 'PM0064760',
      core_name: 'Plant',
      sppl_ean: '8720664866904',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140848',
      rtl_batch_array: '6140848',
      name_search: 'HEPME',
      name: "Hemerocallis 'Pardon Me'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P9GZ4H57;PARAEVYW',
      statusContent: 'dot',
      hash: 'PLBHHJP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLBHHJP7'
    },
    {
      status: 210,
      sku: 'PM0058405',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6214319',
      rtl_batch_array: '6112714, 6214319',
      name_search: 'PAILAVER',
      name: "Paeonia (LD) 'Inspecteur Lavergne'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1266,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9955119K',
      statusContent: 'dot',
      hash: '185ACZ5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '185ACZ5T'
    },
    {
      status: 210,
      sku: 'PM0081620',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344816',
      rtl_batch_array: '6344816',
      name_search: 'HYMFBLUE',
      name: 'Hydrangea macrophylla Forever Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1488,
      chnn_stock_retail: 1488,
      sppl_prcp: 2.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9B2SPB1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B2SPB1S'
    },
    {
      status: 810,
      sku: 'PM0063615',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229866',
      rtl_batch_array: '6229866, 6147682',
      name_search: 'ASCVKLOS',
      name: "Astilbe chinensis 'Veronica Klose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 286,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '957J99LA;SC8C113C;PCR4KD13;9KCP923R;JCN25HN9;7YEPD3LN;2HDDGZDV;W7EX4AGA;HRPAJ8VW;HC23C5LT;A9XD7YBE;VZYHVGYE;P3JC64B7',
      statusContent: 'dot',
      hash: 'Z8ZTL8XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8ZTL8XV'
    },
    {
      status: 810,
      sku: 'PM0081621',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344817',
      rtl_batch_array: '6344817',
      name_search: 'HYMSCHNE',
      name: "Hydrangea macrophylla 'Schneeball'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z19ELEZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z19ELEZ2'
    },
    {
      status: 210,
      sku: 'PM0081622',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344818',
      rtl_batch_array: '6344818',
      name_search: 'PHOPANTH',
      name: 'Physocarpus opulifolius Panther',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WYKAJLHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYKAJLHH'
    },
    {
      status: 210,
      sku: 'PM0078098',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307892',
      rtl_batch_array: '6307892',
      name_search: 'ACSLPARA',
      name: "Achillea sibirica 'Love Parade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2CZ5KT4',
      statusContent: 'dot',
      hash: 'BLG2R267',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLG2R267'
    },
    {
      status: 210,
      sku: 'PM0078074',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307666',
      rtl_batch_array: '6307666',
      name_search: 'SCHSSENS',
      name: 'Schizophragma hyd. Snow Sensation',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5WAW79JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WAW79JY'
    },
    {
      status: 210,
      sku: 'PM0058404',
      core_name: 'Plant',
      sppl_ean: '8720664876347',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112713',
      rtl_batch_array: '6112713',
      name_search: 'PAFLAME',
      name: "Paeonia (LE) 'Flame'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 472,
      chnn_stock_retail: 472,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V3CKNW9L;RZ7V2APK;WW3E99V5;N71V4TBS;ZBDZND6L',
      statusContent: 'dot',
      hash: '7R5NL9E3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R5NL9E3'
    },
    {
      status: 210,
      sku: 'PM0078075',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307671',
      rtl_batch_array: '6307671',
      name_search: 'SCHWINDM',
      name: "Schizophragma hydrangeoides 'Windmills'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D19PZ9AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D19PZ9AE'
    },
    {
      status: 810,
      sku: 'PM0078076',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307686',
      rtl_batch_array: '6307686',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 11.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: '61TEXV63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61TEXV63'
    },
    {
      status: 210,
      sku: 'PM0006058',
      core_name: 'Plant',
      sppl_ean: '8720664810303',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5755800',
      rtl_batch_array: '5755800',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '060080C4',
      rng_range_identifier: 'H060080C4',
      sppl_stock_available: 6803,
      chnn_stock_retail: 6803,
      sppl_prcp: 3.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      imageBatch: 'HADHJBXT',
      statusContent: 'dot',
      hash: '9TLCE52P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TLCE52P'
    },
    {
      status: 210,
      sku: 'PM0078077',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307687',
      rtl_batch_array: '6307687',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_prcp: 13.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'YN9Z43J6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN9Z43J6'
    },
    {
      status: 210,
      sku: 'PM0064930',
      core_name: 'Plant',
      sppl_ean: '8720664881112',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6157603',
      rtl_batch_array: '6157603',
      name_search: 'PIWALLIC',
      name: 'Pinus wallichiana',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 612,
      chnn_stock_retail: 612,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJ4DPHTK;S7YWKNVX;G7LYA11R;KAK23EZX;GEWXWSKZ;V798ZY64;KAXJPGSX;6B61XV4W',
      statusContent: 'dot',
      hash: 'XCK799C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCK799C8'
    },
    {
      status: 910,
      sku: 'PM0064954',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DOOLLEO',
      name: "Doronicum orientale 'Little Leo'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '9YYJBETE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9YYJBETE'
    },
    {
      status: 210,
      sku: 'PM0081623',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344821',
      rtl_batch_array: '6344821',
      name_search: 'PAVPBREE',
      name: 'Panicum virgatum Purple Breeze',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 3.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2GZ57TPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GZ57TPL'
    },
    {
      status: 210,
      sku: 'PM0078078',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307699',
      rtl_batch_array: '6307699',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030040C12',
      rng_range_identifier: 'H030040C12',
      rng_range_description: 'H30 cm 40 cm C12',
      sppl_stock_available: 326,
      chnn_stock_retail: 326,
      sppl_prcp: 9.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'XZGL79L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZGL79L4'
    },
    {
      status: 210,
      sku: 'PM0081661',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333175',
      rtl_batch_array: '6333175',
      name_search: 'ANMARGAR',
      name: 'Anaphalis margaritacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5100,
      chnn_stock_retail: 5100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9AD1XN22',
      statusContent: 'dot',
      hash: 'Y4RSA53J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4RSA53J'
    },
    {
      status: 210,
      sku: 'PM0081662',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333176',
      rtl_batch_array: '6333176',
      name_search: 'ANSCHWEF',
      name: "Anaphalis 'Schwefellicht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9XX9RBCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XX9RBCD'
    },
    {
      status: 210,
      sku: 'PM0078081',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307793',
      rtl_batch_array: '6307793',
      name_search: 'VACDUKE',
      name: "Vaccinium corymbosum 'Duke'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 5.893,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1ALCRK2',
      statusContent: 'dot',
      hash: 'AZH69X56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZH69X56'
    },
    {
      status: 210,
      sku: 'PM0047462',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353409',
      rtl_batch_array: '6353409',
      name_search: 'SESLISA',
      name: "Sedum spectabile 'Lisa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4E2P1JX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E2P1JX7'
    },
    {
      status: 210,
      sku: 'PM0064946',
      core_name: 'Plant',
      sppl_ean: '8720664852648',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6157618',
      rtl_batch_array: '6157618',
      name_search: 'ASAHEND',
      name: "Aster alpinus 'Happy End'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2397,
      chnn_stock_retail: 2397,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LKZ14YZ8;PLL31GRD;SBRD8R2P;9XVYN4NZ;NC2LN2ES;C6J3HANW',
      statusContent: 'dot',
      hash: 'T63BGA7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T63BGA7G'
    },
    {
      status: 210,
      sku: 'PM0064694',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349125',
      rtl_batch_array: '6349125, 6350585',
      name_search: 'IRSIBIRI',
      name: 'Iris sibirica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1554,
      chnn_stock_retail: 1676,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TTV16Y17;DK6D6335;69HA5PVW;219XTGRG;W2KK4WEK;NYBGK1RS;57RZ1R7D;TLRBPJ3X;74JGVW4S;23KCY9EV;J1P4J357;SGGT7CC6;1Z7B2AY6',
      statusContent: 'dot',
      hash: '68DXEY21',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68DXEY21'
    },
    {
      status: 210,
      sku: 'PM0066614',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222183',
      rtl_batch_array: '6222183',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'AE5KJT2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AE5KJT2X'
    },
    {
      status: 810,
      sku: 'PM0078082',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346704',
      rtl_batch_array: '6346704',
      name_search: 'CAAAVALA',
      name: "Calamagrostis acutiflora 'Avalanche'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 2.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '165JB7T8;SN12XP6W;CTN9H182;J984RS7E;T4RDVB1C;TEEDLRE8',
      statusContent: 'dot',
      hash: '5LSKZN4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LSKZN4L'
    },
    {
      status: 810,
      sku: 'PM0078083',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346705',
      rtl_batch_array: '6346705',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: '2WX9AJ2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WX9AJ2J'
    },
    {
      status: 210,
      sku: 'PM0078084',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346708',
      rtl_batch_array: '6346708',
      name_search: 'CYFLEXUO',
      name: 'Cymbopogon flexuosus',
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LL642XNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL642XNY'
    },
    {
      status: 810,
      sku: 'PM0078085',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346714',
      rtl_batch_array: '6346714',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      statusContent: 'dot',
      hash: 'R8Y3REJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8Y3REJT'
    },
    {
      status: 810,
      sku: 'PM0078086',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346715',
      rtl_batch_array: '6346715',
      name_search: 'MISSDWAR',
      name: "Miscanthus sinensis 'Strictus Dwarf'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A5Y6BSL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5Y6BSL6'
    },
    {
      status: 210,
      sku: 'PM0078087',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346757',
      rtl_batch_array: '6346757',
      name_search: 'MOCVARIE',
      name: "Molinia caerulea 'Variegata'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APGCDRGV;H3Z46J2G;YRCP5SX2;884LNKDE;4VDJGWA7;R78CP6VZ;EYBY3X4X',
      statusContent: 'dot',
      hash: 'JHJBAV5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHJBAV5A'
    },
    {
      status: 210,
      sku: 'PM0085228',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373120',
      rtl_batch_array: '6373120',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '030040C7',
      rng_range_identifier: 'H030040C7',
      sppl_stock_available: 1833,
      chnn_stock_retail: 1833,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'DX7GDBJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX7GDBJW'
    },
    {
      status: 210,
      sku: 'PM0064620',
      core_name: 'Plant',
      sppl_ean: '8720349436514',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6037879',
      rtl_batch_array: '6037879, 6321867',
      name_search: 'AGRALABA',
      name: "Agastache rugosa 'Alabaster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 101,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B766ZR44',
      statusContent: 'dot',
      hash: '459BJHET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '459BJHET'
    },
    {
      status: 210,
      sku: 'PM0085229',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373121',
      rtl_batch_array: '6373121',
      name_search: 'FOGOLDRA',
      name: "Forsythia 'Goldrausch'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1566,
      chnn_stock_retail: 1566,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XK6BNHX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XK6BNHX2'
    },
    {
      status: 210,
      sku: 'PM0078097',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307891',
      rtl_batch_array: '6307891',
      name_search: 'ANHFLSWE',
      name: 'Anemone h. Fall in Love Sweetly',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JGBCLXVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGBCLXVN'
    },
    {
      status: 810,
      sku: 'PM0064937',
      core_name: 'Plant',
      sppl_ean: '8720664886070',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6157613',
      rtl_batch_array: '6157613',
      name_search: 'SESSODRO',
      name: 'Sedum spurium Spot On Deep Rose',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GK8XGKD8;G4ZZ236C;29STDC3H;2L4WXLB7;2WH956J5;SC8W4P7K;R5A3ZCXC;D4PL5XVK;KWWWYDGZ;9ZC8XZ7C',
      statusContent: 'dot',
      hash: '59SX11H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59SX11H9'
    },
    {
      status: 810,
      sku: 'PM0078100',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307894',
      rtl_batch_array: '6307894',
      name_search: 'ACGARGEN',
      name: "Acorus gramineus 'Argenteostriatus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GKX6968;XGAA2WX6;5WKAAYH5;ZW1XCE2E;Z8CSGN7T',
      statusContent: 'dot',
      hash: 'P8V3D8D6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8V3D8D6'
    },
    {
      status: 210,
      sku: 'PM0078101',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307895',
      rtl_batch_array: '6307895',
      name_search: 'ACGOBORO',
      name: "Acorus gramineus 'Oborozuki'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '67T9BA9G',
      statusContent: 'dot',
      hash: 'DVZ4BDVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVZ4BDVP'
    },
    {
      status: 210,
      sku: 'PM0003126',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6336452',
      rtl_batch_array: '6254421, 6308994, 6336452, 6339328',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15000,
      chnn_stock_retail: 44145,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'LYE1S7LZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYE1S7LZ'
    },
    {
      status: 210,
      sku: 'PM0085230',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373122',
      rtl_batch_array: '6373122',
      name_search: 'FOGOLDRA',
      name: "Forsythia 'Goldrausch'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 406,
      chnn_stock_retail: 406,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S5YPTSRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5YPTSRT'
    },
    {
      status: 210,
      sku: 'PM0078108',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307902',
      rtl_batch_array: '6307902',
      name_search: 'AGBTHUND',
      name: 'Agapanthus Blue Thunder',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 766,
      chnn_stock_retail: 766,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CTZ2C97K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTZ2C97K'
    },
    {
      status: 810,
      sku: 'PM0047471',
      core_name: 'Plant',
      sppl_ean: '8720664679221',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5948269',
      rtl_batch_array: '5948269',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 39.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'VGL3AVTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGL3AVTX'
    },
    {
      status: 210,
      sku: 'PM0081663',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6348430',
      rtl_batch_array: '6353397, 5364546, 5817918, 6348430',
      name_search: 'THPALBIF',
      name: "Thymus praecox 'Albiflorus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1753,
      chnn_stock_retail: 7497,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JG29AXRT;KPAZN8XE;DP4YYZZ6;7XH2J639;PDHGP48W;CTV34KNY;Z2BEA1LL;84VZLH1A;GNAR9DYK;WE9ELLS2;4V3VLNXX;4ZBCZDPS;5BPNY9S9;24K57SYG;61A68A64;YLL6CAAN;9YCBJXHT;JDGN81YV;8REC3NWA;BC2EBWJE;R51R78DC;6GNXZ4VR;SGJ6D7S5;XD6L9172;JBXG9VP5;K23NP4YX;RJ4427WT',
      statusContent: 'dot',
      hash: 'B96C5KCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B96C5KCC'
    },
    {
      status: 210,
      sku: 'PM0085231',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373123',
      rtl_batch_array: '6373123',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1393,
      chnn_stock_retail: 1393,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: 'W4DVAB8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4DVAB8Z'
    },
    {
      status: 210,
      sku: 'PM0078065',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307607',
      rtl_batch_array: '6307607',
      name_search: 'PAVVICTO',
      name: "Passiflora violacea 'Victoria'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1117,
      chnn_stock_retail: 1117,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TXGBAW79;85W5DPBA;RY6BTTLV',
      statusContent: 'dot',
      hash: 'AA9LEDRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA9LEDRH'
    },
    {
      status: 210,
      sku: 'PM0058410',
      core_name: 'Plant',
      sppl_ean: '8720664800557',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112721',
      rtl_batch_array: '6112721',
      name_search: 'PAEORPLE',
      name: "Paeonia officinalis 'Rubra Plena'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 508,
      chnn_stock_retail: 508,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W3L32R6V;S11DTS2L;CBG159BS;7T9PYX3D;766Y37PV;NEABL4PZ',
      statusContent: 'dot',
      hash: '968CEHZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '968CEHZC'
    },
    {
      status: 210,
      sku: 'PM0070064',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220371',
      rtl_batch_array: '6220371',
      name_search: 'CAPENDUL',
      name: 'Carex pendula',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8ZBR6W7S;DZ8A7RBS;J1LKHJRB;G66P3WJ5;E6RAB6TD;X1JXZKJV;6YVN7EJ9;N2GLX2EW;ER1TNX9C;L6AABLYN;1JEKEE3W;NRKR5DXX;HP54W3NB',
      statusContent: 'dot',
      hash: 'XY72WWYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XY72WWYC'
    },
    {
      status: 210,
      sku: 'PM0069167',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234364',
      rtl_batch_array: '6234364',
      name_search: 'LEARENAR',
      name: 'Leymus arenarius',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 782,
      chnn_stock_retail: 782,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CB7JK5TZ;CW31ST4Y;SNX89TE4;A2599D1J;J7DVX4TA;L9TTVA59;5YZ57GP2;RG9GEDNV;EHKNV4SE;CCAY493R;NDY3TPYJ',
      statusContent: 'dot',
      hash: 'EKCZDRKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKCZDRKX'
    },
    {
      status: 810,
      sku: 'PM0064532',
      core_name: 'Plant',
      sppl_ean: '8720664852976',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6156116',
      rtl_batch_array: '6156116',
      name_search: 'ASDEUTSC',
      name: "Astilbe (J) 'Deutschland'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8HHGXL1;66WV4KD2;8KWC3Y9Y;7J8DYNSA;WBWX85DN',
      statusContent: 'dot',
      hash: 'WCBEPNAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCBEPNAD'
    },
    {
      status: 210,
      sku: 'PM0063603',
      core_name: 'Plant',
      sppl_ean: '8720664850194',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147651',
      rtl_batch_array: '6147651',
      name_search: 'ACPETRA',
      name: "Achillea 'Petra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 273,
      chnn_stock_retail: 273,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69LV3VAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69LV3VAE'
    },
    {
      status: 210,
      sku: 'PM0078099',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307893',
      rtl_batch_array: '6307893',
      name_search: 'ACVULPAR',
      name: 'Aconitum vulparia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 639,
      chnn_stock_retail: 639,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HWWPPHEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWWPPHEE'
    },
    {
      status: 810,
      sku: 'PM0078102',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6374006',
      rtl_batch_array: '6374006, 6307896',
      name_search: 'ACPMBLUE',
      name: 'Actaea pachypoda Misty Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 203,
      sppl_order_minimum: 3,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5B8E3HN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B8E3HN8'
    },
    {
      status: 210,
      sku: 'PM0058406',
      core_name: 'Plant',
      sppl_ean: '8720664876224',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112715',
      rtl_batch_array: '6112715',
      name_search: 'PAJACORM',
      name: "Paeonia (LD) 'Jacorma'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B4794E93',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4794E93'
    },
    {
      status: 210,
      sku: 'PM0078103',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307897',
      rtl_batch_array: '6307897',
      name_search: 'ACRQSHEB',
      name: "Actaea racemosa 'Queen of Sheba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3331,
      chnn_stock_retail: 3331,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PKSPSZZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKSPSZZ2'
    },
    {
      status: 210,
      sku: 'PM0078105',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307899',
      rtl_batch_array: '6307899',
      name_search: 'AGBMAGIC',
      name: "Agapanthus 'Black Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1410,
      chnn_stock_retail: 1410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SH7C5TCY;GVARXHS2;WDKVTAZK;EZVLSP73',
      statusContent: 'dot',
      hash: 'X7DB4VBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7DB4VBN'
    },
    {
      status: 210,
      sku: 'PM0047171',
      core_name: 'Plant',
      sppl_ean: '8720664876576',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084621',
      rtl_batch_array: '6084621',
      name_search: 'PANGARTE',
      name: "Papaver nudicaule 'Gartenzwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 514,
      chnn_stock_retail: 514,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LKX6VX23;WT9XSCX9;164SE765;WDXV3JJZ;DW1NGC11;9EWS1Z5S',
      statusContent: 'dot',
      hash: 'T3A649X4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3A649X4'
    },
    {
      status: 210,
      sku: 'PM0078088',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307880',
      rtl_batch_array: '6307880',
      name_search: 'ALXANTHO',
      name: 'Alchemilla xanthochlora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A7N73XZJ',
      statusContent: 'dot',
      hash: '8AYP2VB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AYP2VB4'
    },
    {
      status: 210,
      sku: 'PM0078106',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307900',
      rtl_batch_array: '6307900',
      name_search: 'AGBPANTH',
      name: "Agapanthus 'Black Pantha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1435,
      chnn_stock_retail: 1435,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A1ZP9V1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1ZP9V1W'
    },
    {
      status: 210,
      sku: 'PM0058407',
      core_name: 'Plant',
      sppl_ean: '8720664800366',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112717',
      rtl_batch_array: '6112717',
      name_search: 'PAKWHITE',
      name: "Paeonia (L) 'Krinkled White'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 161,
      chnn_stock_retail: 161,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A6N4AC6T;6S1JY7LR;TT95WKHS;H14H759B;712NYX1T;RA2L1XGJ',
      statusContent: 'dot',
      hash: 'H3J2RS89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3J2RS89'
    },
    {
      status: 810,
      sku: 'PM0078107',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307901',
      rtl_batch_array: '6307901',
      name_search: 'AGBBABY',
      name: "Agapanthus 'Blue Baby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E1WVKX5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1WVKX5X'
    },
    {
      status: 210,
      sku: 'PM0068807',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208426',
      rtl_batch_array: '6208426, 6268785',
      name_search: 'NENBMOON',
      name: "Nepeta nervosa 'Blue Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3287,
      chnn_stock_retail: 3814,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94XALY75;2LH6J3TG;KWRGPSSB;RWPHSL8A;CK94XPP5;B2VC6KB7',
      statusContent: 'dot',
      hash: 'R4V1H5XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4V1H5XR'
    },
    {
      status: 810,
      sku: 'PM0058408',
      core_name: 'Plant',
      sppl_ean: '8720664800526',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112719',
      rtl_batch_array: '6112719',
      name_search: 'PAOAPLEN',
      name: "Paeonia officinalis 'Alba Plena'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K92TX27T;8C6878PE',
      statusContent: 'dot',
      hash: 'E87BCDZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E87BCDZG'
    },
    {
      status: 210,
      sku: 'PM0078109',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307903',
      rtl_batch_array: '6324731, 6307903',
      name_search: 'AGBLBLUE',
      name: 'Agapanthus Brilliant Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 780,
      chnn_stock_retail: 987,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4ZYSJ9Z7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZYSJ9Z7'
    },
    {
      status: 210,
      sku: 'PM0058343',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208033',
      rtl_batch_array: '6208033',
      name_search: 'LYSHAPPY',
      name: "Lythrum salicaria 'Happy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BS1K7YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BS1K7YX'
    },
    {
      status: 210,
      sku: 'PM0078104',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307898',
      rtl_batch_array: '6307898',
      name_search: 'AGAQUEEN',
      name: 'Agapanthus hybrid African Queen \u00ae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9NL3YH9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NL3YH9S'
    },
    {
      status: 210,
      sku: 'PM0068805',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355378',
      rtl_batch_array: '6353301, 6355378',
      name_search: 'SEJAUBER',
      name: "Sedum 'Jos\u00e9 Aubergine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 1046,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L65PJD6H',
      statusContent: 'dot',
      hash: 'V4PXD8DZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4PXD8DZ'
    },
    {
      status: 210,
      sku: 'PM0064953',
      core_name: 'Plant',
      sppl_ean: '8720664850965',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161510',
      rtl_batch_array: '6161510',
      name_search: 'AJRCCHIP',
      name: "Ajuga reptans 'Chocolate Chip'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZNKVC7L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNKVC7L8'
    },
    {
      status: 210,
      sku: 'PM0063609',
      core_name: 'Plant',
      sppl_ean: '8720664851115',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161517',
      rtl_batch_array: '6161517',
      name_search: 'ALRBLACK',
      name: "Alcea rosea 'Blacknight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JJCAANDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJCAANDH'
    },
    {
      status: 210,
      sku: 'PM0063601',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274837',
      rtl_batch_array: '6147649, 6274837',
      name_search: 'ACFEUERL',
      name: "Achillea 'Feuerland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 339,
      chnn_stock_retail: 507,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZC752H1H;LVASZAG4',
      statusContent: 'dot',
      hash: '5D7CTXEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5D7CTXEY'
    },
    {
      status: 910,
      sku: 'PM0063610',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALRMMAGI',
      name: "Alcea rosea 'Mars Magic'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BBZLR98R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BBZLR98R'
    },
    {
      status: 210,
      sku: 'PM0047020',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 2,
      btch_active_retail: '6193713',
      rtl_batch_array: '6054486, 6193713',
      name_search: 'BLSTRIAT',
      name: 'Bletilla striata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1113,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CR2XTAJR;YA19VZ2V;2494NYLJ;4TGHH7D8;NGG1SEZ5;2RLYSPA7;5WP6KZJB;DDTYCZZB;K9Z5YXH1;XNY5B12J;BP29JT1P;9GTV71G3;8T41LNJ7;6SXWV3ES;845KAPT2',
      statusContent: 'dot',
      hash: 'S7TAEBE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7TAEBE5'
    },
    {
      status: 210,
      sku: 'PM0078089',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307881',
      rtl_batch_array: '6307881',
      name_search: 'ALCERNUU',
      name: 'Allium cernuum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKZ9DLLX',
      statusContent: 'dot',
      hash: 'PZ2BB1G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ2BB1G6'
    },
    {
      status: 210,
      sku: 'PM0078090',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307883',
      rtl_batch_array: '6307883',
      name_search: 'ALSCWHIT',
      name: "Allium schoenoprasum 'Corsican White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z8TES2RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8TES2RZ'
    },
    {
      status: 210,
      sku: 'PM0078092',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307886',
      rtl_batch_array: '6307886',
      name_search: 'AMEPAGEL',
      name: "Amsonia 'Ernst Pagels'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z3PALJAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3PALJAK'
    },
    {
      status: 810,
      sku: 'PM0078093',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307887',
      rtl_batch_array: '6307887',
      name_search: 'AMHARKAN',
      name: "Amsonia 'Halfway to Arkansas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G2N6S6YK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2N6S6YK'
    },
    {
      status: 210,
      sku: 'PM0068054',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196418',
      rtl_batch_array: '6350339, 6196418',
      name_search: 'HEARED',
      name: "Hemerocallis 'Autumn Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1198,
      chnn_stock_retail: 1678,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KE1AEEZH;287DLK1N;SWCNVP6V',
      statusContent: 'dot',
      hash: 'GEG7SP3C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEG7SP3C'
    },
    {
      status: 210,
      sku: 'PM0078091',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307885',
      rtl_batch_array: '6307885',
      name_search: 'AMCILIAT',
      name: 'Amsonia ciliata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J68WR215',
      statusContent: 'dot',
      hash: '8Y9G9XHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Y9G9XHV'
    },
    {
      status: 210,
      sku: 'PM0078094',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307888',
      rtl_batch_array: '6307888',
      name_search: 'AMILLUST',
      name: 'Amsonia illustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HWW9CP3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWW9CP3Y'
    },
    {
      status: 210,
      sku: 'PM0078095',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307889',
      rtl_batch_array: '6307889, 6352605',
      name_search: 'AMSORIEN',
      name: 'Amsonia orientalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 157,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8H5BWBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8H5BWBT'
    },
    {
      status: 210,
      sku: 'PM0078096',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307890',
      rtl_batch_array: '6307890',
      name_search: 'AMTSAZUL',
      name: "Amsonia tabernaemontana 'Stella Azul'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 396,
      chnn_stock_retail: 396,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P4B6CEYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4B6CEYH'
    },
    {
      status: 210,
      sku: 'PM0063619',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220657',
      rtl_batch_array: '6220657',
      name_search: 'ASMSSPIN',
      name: "Astrantia major 'Sparkling Stars Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 672,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y2BD3PV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2BD3PV2'
    },
    {
      status: 210,
      sku: 'PM0074266',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268801',
      rtl_batch_array: '6268801',
      name_search: 'MISZEBRI',
      name: "Miscanthus sinensis 'Zebrinus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJBCBBRB;ADKPAXAV;YS489W1S;XRPXYL62;8GZVNA6C;EPCW87X6;J1YTEA1P;1GC6D5AS;6CZRSXHC;N4Y9WKJG',
      statusContent: 'dot',
      hash: '7N4RPD5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N4RPD5C'
    },
    {
      status: 210,
      sku: 'PM0074267',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315053',
      rtl_batch_array: '6315053, 6268802, 6290222',
      name_search: 'MISINENS',
      name: 'Miscanthus sinensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2094,
      chnn_stock_retail: 6677,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NEG1CAST;W8P249C2;8RPAYC3S;19EKBP98;XGE8EG6E;YC6S6Y67;P815BJA7;L1Y4897A;YP2L9ESZ;VVE7ADHZ;8L1SESSZ;67YTNS9G;B7HA614W',
      statusContent: 'dot',
      hash: 'KE3CP5LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE3CP5LE'
    },
    {
      status: 210,
      sku: 'PM0068801',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350191',
      rtl_batch_array: '6350191',
      name_search: 'GEBTIME',
      name: "Geranium 'Bloom Time'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 137,
      chnn_stock_retail: 137,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '23AHSALX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23AHSALX'
    },
    {
      status: 210,
      sku: 'PM0058338',
      core_name: 'Plant',
      sppl_ean: '8720664873520',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112607',
      rtl_batch_array: '6112607',
      name_search: 'LUPGRUEN',
      name: "Luzula pilosa 'Gr\u00fcnfink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NP9RDCJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NP9RDCJP'
    },
    {
      status: 210,
      sku: 'PM0068803',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208416',
      rtl_batch_array: '6208416, 6350653',
      name_search: 'LIDMLADY',
      name: "Ligularia dentata 'Midnight Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4728,
      chnn_stock_retail: 5208,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SK1DS8W3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK1DS8W3'
    },
    {
      status: 210,
      sku: 'PM0068694',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207776',
      rtl_batch_array: '6207776',
      name_search: 'ACMHEIDI',
      name: "Achillea millefolium 'Heidi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 402,
      chnn_stock_retail: 402,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4ENKTYG;YH44CSG3',
      statusContent: 'dot',
      hash: 'BYDH5ZJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYDH5ZJT'
    },
    {
      status: 210,
      sku: 'PM0064926',
      core_name: 'Plant',
      sppl_ean: '8720664887725',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6157598',
      rtl_batch_array: '6157598',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 5500,
      chnn_stock_retail: 5500,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'GXWDK6BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXWDK6BN'
    },
    {
      status: 210,
      sku: 'PM0011038',
      core_name: 'Plant',
      sppl_ean: '8720664693319',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495628',
      rtl_batch_array: '5495628',
      name_search: 'HYMSBAUT',
      name: "Hydrangea macrophylla 'Sch\u00f6ne Bautznerin'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1034,
      chnn_stock_retail: 1034,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R1RZJWGE',
      statusContent: 'dot',
      hash: '7CK6VK5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CK6VK5R'
    },
    {
      status: 210,
      sku: 'PM0064943',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6371040',
      rtl_batch_array: '6371040',
      name_search: 'AGRPOSIE',
      name: "Agastache 'Rosie Posie'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1892,
      chnn_stock_retail: 1892,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1HY9J55A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HY9J55A'
    },
    {
      status: 210,
      sku: 'PM0064929',
      core_name: 'Plant',
      sppl_ean: '8720664880405',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6157602',
      rtl_batch_array: '6298824, 6157602',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2200,
      chnn_stock_retail: 3061,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'B625H1BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B625H1BV'
    },
    {
      status: 210,
      sku: 'PM0064947',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243703',
      rtl_batch_array: '6243703',
      name_search: 'COVRRED',
      name: "Coreopsis verticillata 'Ruby Red'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 584,
      chnn_stock_retail: 584,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2K6BS6WC',
      statusContent: 'dot',
      hash: '6ECT52GZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ECT52GZ'
    },
    {
      status: 210,
      sku: 'PM0058401',
      core_name: 'Plant',
      sppl_ean: '8720664876149',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112709',
      rtl_batch_array: '6112709',
      name_search: 'PAFARRIV',
      name: "Paeonia (I) 'First Arrival'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 6.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CLJED5CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CLJED5CX'
    },
    {
      status: 210,
      sku: 'PM0070065',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220378',
      rtl_batch_array: '6220378',
      name_search: 'PAVREHBR',
      name: "Panicum virgatum 'Rehbraun'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTPBCVG1;BDLXK19Y;AVX56D5S;7RH9XSJ5;1XCWN1HP;2D2J95VP;DHYCCDXV;2YJN9CAT;2BR3BZTA;D1K77WWG;7RDRGPE4;KLY9228P',
      statusContent: 'dot',
      hash: 'L4SV5ZGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4SV5ZGJ'
    },
    {
      status: 210,
      sku: 'PM0064563',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6305163',
      rtl_batch_array: '5364182, 6350491, 6305163',
      name_search: 'HOBDADDY',
      name: "Hosta 'Big Daddy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1086,
      chnn_stock_retail: 2805,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1VLZHV76;ZLB4Y6KK;V7DNZA9J;X3PWJB2T',
      statusContent: 'dot',
      hash: 'NWZB3D7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWZB3D7W'
    },
    {
      status: 810,
      sku: 'PM0070066',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220379',
      rtl_batch_array: '6220379, 5488237',
      name_search: 'PEAREDHE',
      name: "Pennisetum alopecuroides 'Redhead'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 60,
      chnn_stock_retail: 560,
      sppl_prcp: 2.248,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5HWBHBZC',
      statusContent: 'dot',
      hash: '7KGGLJ3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KGGLJ3V'
    },
    {
      status: 910,
      sku: 'PM0038873',
      core_name: 'Plant',
      sppl_ean: '8720664803787',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5663957',
      rtl_batch_array: '5663957',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 7.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'YS51C7WP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS51C7WP'
    },
    {
      status: 210,
      sku: 'PM0058413',
      core_name: 'Plant',
      sppl_ean: '8720664876323',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112724',
      rtl_batch_array: '6112724',
      name_search: 'PATBRASS',
      name: "Paeonia (LD) 'Top Brass'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 3.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CAB6BDVT',
      statusContent: 'dot',
      hash: 'JWY8GN1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWY8GN1P'
    },
    {
      status: 210,
      sku: 'PM0069165',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254770',
      rtl_batch_array: '6313206, 6254770',
      name_search: 'LABBIRD',
      name: 'Lavatera Blue Bird',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 198,
      sppl_prcp: 2.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDJNBSZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDJNBSZP'
    },
    {
      status: 210,
      sku: 'PM0078110',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324732',
      rtl_batch_array: '6324732, 6307904',
      name_search: 'AGCHARLO',
      name: "Agapanthus 'Charlotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 183,
      chnn_stock_retail: 757,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8S3HEYVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S3HEYVT'
    },
    {
      status: 210,
      sku: 'PM0068852',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253060',
      rtl_batch_array: '6253060, 6336211',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5415,
      chnn_stock_retail: 8115,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'WZ2A6Y6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZ2A6Y6N'
    },
    {
      status: 210,
      sku: 'PM0069172',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254904',
      rtl_batch_array: '6254904',
      name_search: 'MISGRAZI',
      name: "Miscanthus sinensis 'Graziella'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1164,
      chnn_stock_retail: 1164,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ARAYGJZC',
      statusContent: 'dot',
      hash: 'J4ZDZX3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4ZDZX3S'
    },
    {
      status: 810,
      sku: 'PM0078113',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307908',
      rtl_batch_array: '6307908',
      name_search: 'AGLLAZUL',
      name: "Agapanthus 'Lapis Lazuli'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9JAGDW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9JAGDW1'
    },
    {
      status: 210,
      sku: 'PM0068856',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253066',
      rtl_batch_array: '6253066, 6336217',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1482,
      chnn_stock_retail: 4482,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: '5PX5PSDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PX5PSDL'
    },
    {
      status: 910,
      sku: 'PM0064546',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RHALEXAN',
      name: 'Rheum alexandrae',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '5JPTBTYZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5JPTBTYZ'
    },
    {
      status: 210,
      sku: 'PM0078118',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307913',
      rtl_batch_array: '6307913',
      name_search: 'AGPOPURP',
      name: "Agapanthus Poppin' Purple",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E7PBA2XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7PBA2XG'
    },
    {
      status: 210,
      sku: 'PM0047236',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307851',
      rtl_batch_array: '6307851',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_prcp: 10.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: 'SZ8Z5ZTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZ8Z5ZTV'
    },
    {
      status: 210,
      sku: 'PM0074269',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268811',
      rtl_batch_array: '6268811',
      name_search: 'PLAPUMIL',
      name: 'Pleioblastus argenteostriatus pumilus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1099,
      chnn_stock_retail: 1099,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N4ZN73LJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4ZN73LJ'
    },
    {
      status: 210,
      sku: 'PM0078126',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307922',
      rtl_batch_array: '6307922',
      name_search: 'ASBZWERG',
      name: "Aster (D) 'Blauer Zwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ZBXWH7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZBXWH7E'
    },
    {
      status: 210,
      sku: 'PM0064961',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6177950',
      rtl_batch_array: '6242924, 6192672, 6158267, 6164372, 6177950',
      name_search: 'ASFANAL',
      name: "Astilbe (A) 'Fanal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 11396,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C7S2Y1R;2ZA6BV4X;NKJPNGXX;GVT9WCBE;4LZB2BZB',
      statusContent: 'dot',
      hash: 'TEXD3Z54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEXD3Z54'
    },
    {
      status: 210,
      sku: 'PM0068883',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6209216',
      rtl_batch_array: '6209216, 6210025, 6266236',
      name_search: 'ACMPAPRI',
      name: "Achillea millefolium 'Paprika'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 571,
      chnn_stock_retail: 1302,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5VJGREE;2CAXHCNT;9EVTVGC8;1622HBS8;LTLPJ621',
      statusContent: 'dot',
      hash: 'HPN8SLCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPN8SLCP'
    },
    {
      status: 210,
      sku: 'PM0078129',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307926',
      rtl_batch_array: '6307926',
      name_search: 'ASAGRUEN',
      name: "Aster amellus 'Gr\u00fcnder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LKB9DLAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKB9DLAA'
    },
    {
      status: 210,
      sku: 'PM0063575',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6336440',
      rtl_batch_array: '6167451, 6291239, 6336440, 6356599',
      name_search: 'GENODOSU',
      name: 'Geranium nodosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 1534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKRXGWGZ;YN7CGPWY;2TZX3DB7',
      statusContent: 'dot',
      hash: '2PA1TVZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PA1TVZ5'
    },
    {
      status: 210,
      sku: 'PM0063576',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6207986',
      rtl_batch_array: '6207986, 5495324, 6291692',
      name_search: 'GEORION',
      name: "Geranium 'Orion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4497,
      chnn_stock_retail: 8302,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9AX9ATP;EB3P6ET1;D9KGSLXN;45D5VY6Z;RC2JK5BY;21XX77S2;A7L6CB42',
      statusContent: 'dot',
      hash: '4XLNJB2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XLNJB2A'
    },
    {
      status: 210,
      sku: 'PM0068855',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253065',
      rtl_batch_array: '6253065',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3982,
      chnn_stock_retail: 3982,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'XWCB8GS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWCB8GS3'
    },
    {
      status: 210,
      sku: 'PM0064545',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272794',
      rtl_batch_array: '6272794',
      name_search: 'PRWGISER',
      name: "Prunella webbiana 'Gruss Aus Isernhagen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YDNJ4VEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDNJ4VEV'
    },
    {
      status: 210,
      sku: 'PM0068867',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209194',
      rtl_batch_array: '6209194',
      name_search: 'POFELIZA',
      name: "Potentilla fruticosa 'Elizabeth'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 654,
      chnn_stock_retail: 654,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XY5JR1T3;BWWTSRRY;6SR67SWX;79CS1C7X',
      statusContent: 'dot',
      hash: '7KDLN8YE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KDLN8YE'
    },
    {
      status: 210,
      sku: 'PM0068878',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6310405',
      rtl_batch_array: '6209210, 6050728, 6310405',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6958,
      chnn_stock_retail: 13619,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: 'RD3ZCANE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD3ZCANE'
    },
    {
      status: 210,
      sku: 'PM0058418',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382305',
      rtl_batch_array: '6350748, 6274853, 6382305',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3600,
      chnn_stock_retail: 5479,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      statusContent: 'dot',
      hash: 'V72ZEEVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V72ZEEVD'
    },
    {
      status: 210,
      sku: 'PM0064956',
      core_name: 'Plant',
      sppl_ean: '8720664866607',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6158254',
      rtl_batch_array: '6108203, 6158254',
      name_search: 'HECSUNRI',
      name: "Hemerocallis 'Chicago Sunrise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 478,
      chnn_stock_retail: 685,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZB6K8WS;G5XZSYAV',
      statusContent: 'dot',
      hash: '8Y65BCNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Y65BCNX'
    },
    {
      status: 210,
      sku: 'PM0068880',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209212',
      rtl_batch_array: '6209212',
      name_search: 'SPNHSILV',
      name: "Spiraea nipponica 'Halward's Silver'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YBYD67V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBYD67V2'
    },
    {
      status: 210,
      sku: 'PM0068885',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385795',
      rtl_batch_array: '6252565, 6385795',
      name_search: 'EUCWULFE',
      name: 'Euphorbia characias wulfenii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 211,
      chnn_stock_retail: 708,
      sppl_prcp: 2.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7WYAV9W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WYAV9W9'
    },
    {
      status: 210,
      sku: 'PM0068861',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304955',
      rtl_batch_array: '6304955',
      name_search: 'BUWBALL',
      name: "Buddleja 'White Ball'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 1.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B1573HE;YH81KX32;5TL2J7GY;9RKLX3GN',
      statusContent: 'dot',
      hash: 'N8XGKLX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8XGKLX5'
    },
    {
      status: 210,
      sku: 'PM0068872',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209202',
      rtl_batch_array: '6209202',
      name_search: 'POFSNOWF',
      name: "Potentilla fruticosa 'Snowflake'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1137,
      chnn_stock_retail: 1137,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SL5KBXG',
      statusContent: 'dot',
      hash: 'EG7JGGS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG7JGGS7'
    },
    {
      status: 210,
      sku: 'PM0045472',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6276022',
      rtl_batch_array: '6301449, 6054450, 6276022',
      name_search: 'ASHGRAAF',
      name: "Astilbe (S) 'Hennie Graafland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2268,
      chnn_stock_retail: 2960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7GNB51J8;9GCHK9CJ;Z7SVKJAH;81Z3BNGV;ATLWZWWK;ER7YHR57;ZWWS97J2;6WR3PN3X;57EG465A',
      statusContent: 'dot',
      hash: 'CW76HADV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW76HADV'
    },
    {
      status: 210,
      sku: 'PM0064957',
      core_name: 'Plant',
      sppl_ean: '8720664870178',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6158257',
      rtl_batch_array: '6158257',
      name_search: 'IRSSEDGE',
      name: "Iris sibirica 'Silver Edge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1369,
      chnn_stock_retail: 1369,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C326X6LH',
      statusContent: 'dot',
      hash: 'H2YB7K9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2YB7K9S'
    },
    {
      status: 210,
      sku: 'PM0068853',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336212',
      rtl_batch_array: '6336212',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2400,
      chnn_stock_retail: 2400,
      sppl_prcp: 2.421,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WL61Z8CK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL61Z8CK'
    },
    {
      status: 910,
      sku: 'PM0066624',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174491',
      rtl_batch_array: '6174491',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'H38X5YRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H38X5YRZ'
    },
    {
      status: 210,
      sku: 'PM0081588',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338830',
      rtl_batch_array: '6338830',
      name_search: 'FUCHSGMD',
      name: 'Fuchsia Gemengd',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 945,
      chnn_stock_retail: 945,
      sppl_prcp: 2.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JS5YBGLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JS5YBGLL'
    },
    {
      status: 210,
      sku: 'PM0068860',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253075',
      rtl_batch_array: '6253075',
      name_search: 'BULOCHIN',
      name: "Buddleja 'Lochinch'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 486,
      chnn_stock_retail: 486,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GXTTXG5;EKY59A7K;VYLJHNCG;JJ9B2SR1;45TG8BZ3;EY83V219;LVSBYP12',
      statusContent: 'dot',
      hash: '76VXJ4EH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76VXJ4EH'
    },
    {
      status: 210,
      sku: 'PM0058440',
      core_name: 'Plant',
      sppl_ean: '8720664877061',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6171167',
      rtl_batch_array: '6171167, 6112758',
      name_search: 'PEEVELYN',
      name: "Penstemon 'Evelyn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 615,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KASRNWT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KASRNWT9'
    },
    {
      status: 210,
      sku: 'PM0078135',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307936',
      rtl_batch_array: '6307936',
      name_search: 'ASEALASK',
      name: "Aster ericoides 'Alaska'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BTKPPBET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTKPPBET'
    },
    {
      status: 210,
      sku: 'PM0068875',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6209206',
      rtl_batch_array: '6209206, 6037581, 6310393',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3481,
      chnn_stock_retail: 15842,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'NBT6PJLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBT6PJLG'
    },
    {
      status: 210,
      sku: 'PM0064968',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307576',
      rtl_batch_array: '6307576',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 610,
      chnn_stock_retail: 610,
      sppl_prcp: 12.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: '1DP8HPXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DP8HPXC'
    },
    {
      status: 210,
      sku: 'PM0068854',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253062',
      rtl_batch_array: '6253062, 6336213',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5157,
      chnn_stock_retail: 6657,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'PJVBS3V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJVBS3V1'
    },
    {
      status: 210,
      sku: 'PM0081664',
      core_name: 'Plant',
      sppl_ean: '8720626373679',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364548',
      rtl_batch_array: '5364548',
      name_search: 'THPPCHIN',
      name: "Thymus praecox 'Pink Chintz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CPYTXV9',
      statusContent: 'dot',
      hash: '2SZN86V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SZN86V4'
    },
    {
      status: 210,
      sku: 'PM0068865',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209188',
      rtl_batch_array: '6209188, 5837482',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4388,
      chnn_stock_retail: 5112,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'PRD22H8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRD22H8K'
    },
    {
      status: 210,
      sku: 'PM0081665',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348432',
      rtl_batch_array: '5364551, 6129671, 6348432',
      name_search: 'THSERPYL',
      name: 'Thymus serpyllum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2927,
      chnn_stock_retail: 9939,
      sppl_order_minimum: 3,
      sppl_prcp: 0.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7JGVWDD;GJJHC5TD;427WX8WA;8NWKD8ZG;JJH957KL;YHK72S3X;J64X8Z1H;45PVT992;BNHKGTBH;45SB2AZG;KPVNARKG;CKYCWAW3;Z4EZJVA2',
      statusContent: 'dot',
      hash: 'L4P78SE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4P78SE8'
    },
    {
      status: 210,
      sku: 'PM0081666',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333178',
      rtl_batch_array: '6333178',
      name_search: 'AQSMBWHI',
      name: "Aquilegia 'Spring Magic Blue White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6345,
      chnn_stock_retail: 6345,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ACK632VL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACK632VL'
    },
    {
      status: 210,
      sku: 'PM0081667',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333193',
      rtl_batch_array: '6349581, 6333193, 6348291',
      name_search: 'ATFFFRIZ',
      name: "Athyrium filix-femina 'Frizelliae'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1029,
      chnn_stock_retail: 1936,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P22XBSN2',
      statusContent: 'dot',
      hash: 'SZX82DBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZX82DBJ'
    },
    {
      status: 210,
      sku: 'PM0064958',
      core_name: 'Plant',
      sppl_ean: '8720664870185',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6158258',
      rtl_batch_array: '6158258, 6305179',
      name_search: 'IRSSROSE',
      name: "Iris sibirica 'Sparkling Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 713,
      chnn_stock_retail: 790,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W9AKPL7P;B4PJ3V8Y',
      statusContent: 'dot',
      hash: 'YKG8N2SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKG8N2SL'
    },
    {
      status: 910,
      sku: 'PM0064959',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LECELISE',
      name: "Lewisia cotyledon 'Elise'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'A36E5RYX;JAV9H5WT;GED8K3VK;6RBPLW1K;Y5D8ZX2B;WZ5CT9S9;J5DVNSG3',
      statusContent: 'dot',
      hash: 'ZBKY6LL4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZBKY6LL4'
    },
    {
      status: 210,
      sku: 'PM0068848',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209164',
      rtl_batch_array: '6209164',
      name_search: 'AGAFRICA',
      name: 'Agapanthus africanus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 421,
      chnn_stock_retail: 421,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JSV2HX9P;LAVA9RSS;GCSCKNA9;WBCHVK77;PZA6ZRA1;WDXX8ZYB;BVPEJDPT;CYWSEJRN;SR86GZHG',
      statusContent: 'dot',
      hash: 'N7WLEKJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7WLEKJH'
    },
    {
      status: 210,
      sku: 'PM0081668',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348341',
      rtl_batch_array: '6349894, 6333202, 6348341',
      name_search: 'CAPEFROS',
      name: "Campanula poscharskyana 'E.H. Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1119,
      chnn_stock_retail: 2334,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5JY57LWE;ASKK779Z;HWGXXWDA;ZLKHD84L;EN9GZP65;JEL2967D;K3A3ALXZ;7LWYLSJL',
      statusContent: 'dot',
      hash: 'G3G3WB9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3G3WB9Y'
    },
    {
      status: 210,
      sku: 'PM0068859',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253073',
      rtl_batch_array: '6253073, 6336220',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5987,
      chnn_stock_retail: 8287,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: '23C82TDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23C82TDA'
    },
    {
      status: 210,
      sku: 'PM0068873',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209203',
      rtl_batch_array: '6209203',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 191,
      chnn_stock_retail: 191,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'PWSRVS2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWSRVS2X'
    },
    {
      status: 210,
      sku: 'PM0068849',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209165',
      rtl_batch_array: '6209165, 6254872',
      name_search: 'AJREPTAN',
      name: 'Ajuga reptans',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2436,
      chnn_stock_retail: 3807,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9TYY7NSC;6HNAC18P;TNHTW3RR;G2K2S9B3;2PN6WTKB;JWZZJZWY;7XZSTCJ9;52XE24L9;VESGJRR7;G7SWKYAA;E6546H5T;25JATYLH;1REAGRVZ;X655RS1W;VKPNLCDC;725JXKH4;LTZZHZV5;XEYXYGZ6;ET1T1235;NAJC85VE;94T4R859',
      statusContent: 'dot',
      hash: '1G543L9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G543L9S'
    },
    {
      status: 210,
      sku: 'PM0068866',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209193',
      rtl_batch_array: '6311193, 6209193',
      name_search: 'POFELIZA',
      name: "Potentilla fruticosa 'Elizabeth'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2313,
      chnn_stock_retail: 3913,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XY5JR1T3;BWWTSRRY;6SR67SWX;79CS1C7X',
      statusContent: 'dot',
      hash: 'T1CPX95L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1CPX95L'
    },
    {
      status: 210,
      sku: 'PM0068851',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253058',
      rtl_batch_array: '6253058',
      name_search: 'BUDNANHO',
      name: 'Buddleja davidii nanhoensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KTK2DJKY;S5PLYE3P;P4ED541B;JC6GP795',
      statusContent: 'dot',
      hash: 'SWAJZ4NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWAJZ4NE'
    },
    {
      status: 210,
      sku: 'PM0078130',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307927',
      rtl_batch_array: '6307927',
      name_search: 'ACCSSPRA',
      name: "Achillea cartilaginea 'Silver Spray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VACCRWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VACCRWH'
    },
    {
      status: 210,
      sku: 'PM0070068',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385805',
      rtl_batch_array: '6220383, 6229132, 6385805',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1595,
      chnn_stock_retail: 5758,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'A96YAJH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A96YAJH7'
    },
    {
      status: 210,
      sku: 'PM0068870',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209197',
      rtl_batch_array: '6311195, 6209197',
      name_search: 'POFGOLDS',
      name: "Potentilla fruticosa 'Goldstar'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 946,
      chnn_stock_retail: 3715,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERJLRANR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERJLRANR'
    },
    {
      status: 210,
      sku: 'PM0068881',
      core_name: 'Plant',
      sppl_ean: '8720626371651',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5937513',
      rtl_batch_array: '5937513',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'H6J713WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6J713WB'
    },
    {
      status: 210,
      sku: 'PM0066629',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 3,
      btch_active_retail: '6195682',
      rtl_batch_array: '6311909, 6310275, 6195682',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 220,
      chnn_stock_retail: 2527,
      sppl_prcp: 4.814,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'KE7LD967',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE7LD967'
    },
    {
      status: 210,
      sku: 'PM0066628',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385792',
      rtl_batch_array: '6239616, 6385792',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 108,
      sppl_prcp: 12.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'NRVVYYN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRVVYYN6'
    },
    {
      status: 210,
      sku: 'PM0078158',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307970',
      rtl_batch_array: '6307970',
      name_search: 'ANHPLJUL',
      name: "Anemone hupehensis 'Pretty Lady Julia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 174,
      chnn_stock_retail: 174,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3KADC3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3KADC3E'
    },
    {
      status: 810,
      sku: 'PM0078159',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307971',
      rtl_batch_array: '6307971',
      name_search: 'ANHPLSUS',
      name: "Anemone hupehensis 'Pretty Lady Susan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GZ9NLN84',
      statusContent: 'dot',
      hash: 'JV3NS8T3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV3NS8T3'
    },
    {
      status: 210,
      sku: 'PM0068877',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209209',
      rtl_batch_array: '6209209',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'S4BSTPL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4BSTPL6'
    },
    {
      status: 210,
      sku: 'PM0068871',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6310347',
      rtl_batch_array: '6311202, 6209201, 6310347',
      name_search: 'POFPBEAU',
      name: "Potentilla fruticosa 'Primrose Beauty'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2094,
      chnn_stock_retail: 4805,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5HYGEX5;PNXB7VNR;L9L6LZJ1;GAL36RY2;G71E12H6;Y4X3Y5LG',
      statusContent: 'dot',
      hash: 'TWSBTERJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWSBTERJ'
    },
    {
      status: 210,
      sku: 'PM0068879',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6209211',
      rtl_batch_array: '6209211, 6050730, 6310420',
      name_search: 'SPJMANON',
      name: "Spiraea japonica 'Manon'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1601,
      chnn_stock_retail: 8170,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZAWNADB',
      statusContent: 'dot',
      hash: 'V19BWNT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V19BWNT6'
    },
    {
      status: 210,
      sku: 'PM0078114',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307909',
      rtl_batch_array: '6307909',
      name_search: 'AGNBLUE',
      name: "Agapanthus 'Navy Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1749,
      chnn_stock_retail: 1749,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G3CEPW5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3CEPW5D'
    },
    {
      status: 210,
      sku: 'PM0068882',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209215',
      rtl_batch_array: '6209215',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 862,
      chnn_stock_retail: 862,
      sppl_prcp: 4.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '594BS7AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '594BS7AX'
    },
    {
      status: 210,
      sku: 'PM0068884',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209219',
      rtl_batch_array: '6209219',
      name_search: 'COAECLIP',
      name: "Cosmos atr. 'Eclips'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 602,
      chnn_stock_retail: 602,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WDW761T3;JSX6WBWG;GSZRKVT9;DG16YE9J',
      statusContent: 'dot',
      hash: '79D5T2KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79D5T2KA'
    },
    {
      status: 210,
      sku: 'PM0069170',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244256',
      rtl_batch_array: '6244256, 6252673',
      name_search: 'LYSALICA',
      name: 'Lythrum salicaria',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 335,
      chnn_stock_retail: 1603,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V6AC6K6P;E4N5HJDG;4B83RE2S;52X2815T;8L7H2K6Z;LX6CSTK6;Y8A5AHBX;GD76A67L;GL3TSX82',
      statusContent: 'dot',
      hash: 'HWVA7J39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWVA7J39'
    },
    {
      status: 210,
      sku: 'PM0068869',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209196',
      rtl_batch_array: '6209196',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2202,
      chnn_stock_retail: 2202,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'ST36KNGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST36KNGB'
    },
    {
      status: 210,
      sku: 'PM0069171',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254776',
      rtl_batch_array: '6254776',
      name_search: 'MEPIPERI',
      name: 'Mentha piperita',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 967,
      chnn_stock_retail: 967,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8PB2G7LJ;HYBX47XP;XG9G42CC;JE58PA8K;2RLSL42E;1KZ64JZX;GY2EKTZ1;DE3DR32D;WLP5JED3;A7V79YGW;AEA4LGAV;H52DZHYS;W8T9K7BB;BHG4BWS4;E76VDL35;RNY136WE;4GRXC1TG;PVHW1ZKH;4TNHJXXN;19669CTA',
      statusContent: 'dot',
      hash: 'VVEVR6WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVEVR6WY'
    },
    {
      status: 810,
      sku: 'PM0069173',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254787',
      rtl_batch_array: '6254787',
      name_search: 'MISVARIE',
      name: "Miscanthus sinensis 'Variegatus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_prcp: 2.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNC1GJLZ;8HJEBAB8;WKT41DLJ;2C2PCKGB;C6A7HCL3;VGV2Y757;2GDEE6DR;T7KY5CGV;S9W5PJHJ;EG7SXJ33;CKPC6BGS;86WLLTJY;TPRW3A84;S8HTLDAJ;2B8VX3EK;4AZX7HRE',
      statusContent: 'dot',
      hash: 'VPCV46LW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPCV46LW'
    },
    {
      status: 210,
      sku: 'PM0068868',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 5,
      btch_active_retail: '6310340',
      rtl_batch_array: '6311194, 6209195, 6282392, 6304875, 6310340',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6280,
      chnn_stock_retail: 18858,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'ZTP2NWWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTP2NWWH'
    },
    {
      status: 210,
      sku: 'PM0078115',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324733',
      rtl_batch_array: '6324733, 6307910',
      name_search: 'AGNSTAR',
      name: "Agapanthus 'Northern Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 785,
      chnn_stock_retail: 1222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A4THP299;WHDKZJKC;5VL1TBAD;CTRBZG71;X9CC9GTG',
      statusContent: 'dot',
      hash: 'GN2YPZ28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GN2YPZ28'
    },
    {
      status: 210,
      sku: 'PM0078116',
      core_name: 'Plant',
      sppl_ean: '8720626385757',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495153',
      rtl_batch_array: '6307911, 5495153',
      name_search: 'AGPPAN',
      name: "Agapanthus 'Peter Pan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 623,
      chnn_stock_retail: 1700,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JJYC4J2V;NWAR62T7;5L751SP7;A29R3YPT',
      statusContent: 'dot',
      hash: '7H2TJ4A6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7H2TJ4A6'
    },
    {
      status: 210,
      sku: 'PM0078117',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307912',
      rtl_batch_array: '6307912',
      name_search: 'AGPBLUE',
      name: "Agapanthus 'Prolific Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9JAPED55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JAPED55'
    },
    {
      status: 810,
      sku: 'PM0058415',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301756',
      rtl_batch_array: '6301756',
      name_search: 'PAVHROD',
      name: "Panicum virgatum 'Hot Rod'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CV5GW96V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV5GW96V'
    },
    {
      status: 210,
      sku: 'PM0078119',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307914',
      rtl_batch_array: '6307914',
      name_search: 'AGPCLOUD',
      name: "Agapanthus 'Purple Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 943,
      chnn_stock_retail: 943,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '57JCBLTX',
      statusContent: 'dot',
      hash: 'AHT162XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHT162XR'
    },
    {
      status: 210,
      sku: 'PM0078120',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307915',
      rtl_batch_array: '6307915',
      name_search: 'AGSBABY',
      name: "Agapanthus 'Silver Baby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JTVLGR3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTVLGR3E'
    },
    {
      status: 210,
      sku: 'PM0078121',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307916',
      rtl_batch_array: '6307916',
      name_search: 'AGTORNAD',
      name: "Agapanthus 'Tornado'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 562,
      chnn_stock_retail: 562,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EXYYL4ZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXYYL4ZK'
    },
    {
      status: 210,
      sku: 'PM0078122',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307917',
      rtl_batch_array: '6307917',
      name_search: 'AGVLOIRE',
      name: "Agapanthus 'Vall\u00e9e de Loire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DSY1KCY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSY1KCY2'
    },
    {
      status: 210,
      sku: 'PM0078123',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307918',
      rtl_batch_array: '6307918',
      name_search: 'AGWHEAVE',
      name: "Agapanthus 'White Heaven'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 878,
      chnn_stock_retail: 878,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J92LKXES;DL4SA199',
      statusContent: 'dot',
      hash: 'YRS15AAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRS15AAV'
    },
    {
      status: 210,
      sku: 'PM0078124',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307919',
      rtl_batch_array: '6307919',
      name_search: 'AGWGREY',
      name: "Agapanthus 'Windsor Grey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZX4579ES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX4579ES'
    },
    {
      status: 210,
      sku: 'PM0078111',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369729',
      rtl_batch_array: '6369729, 6307905',
      name_search: 'AGDDIAMO',
      name: 'Agapanthus Double Diamond',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 404,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YSABSZ4W;CKNXCXRT;59SHWWXJ;EDEKCXLS;5R78GJH6',
      statusContent: 'dot',
      hash: '4XEEZAWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XEEZAWL'
    },
    {
      status: 210,
      sku: 'PM0078112',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307906',
      rtl_batch_array: '6307906',
      name_search: 'AGEWHITE',
      name: 'Agapanthus Ever White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75HN7H6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75HN7H6B'
    },
    {
      status: 210,
      sku: 'PM0078125',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307921',
      rtl_batch_array: '6307921',
      name_search: 'ALCONJUN',
      name: 'Alchemilla conjuncta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DEYYLZGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEYYLZGD'
    },
    {
      status: 210,
      sku: 'PM0078160',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307973',
      rtl_batch_array: '6307973, 6352606',
      name_search: 'ANESWAN',
      name: 'Anemone Elfin Swan',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2085,
      chnn_stock_retail: 2519,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W585EHCD;8T19VWVV;LXE66XTB;9T2RYNCB;HRJAEAYR;Z241JLTZ;C5CKGJST;YWRR69NV',
      statusContent: 'dot',
      hash: 'H4G5LNK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4G5LNK5'
    },
    {
      status: 210,
      sku: 'PM0078156',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307967',
      rtl_batch_array: '6307967',
      name_search: 'ANHLWPRI',
      name: "Anemone hup. 'Little White Princes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A7X1BT47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7X1BT47'
    },
    {
      status: 210,
      sku: 'PM0078154',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307965',
      rtl_batch_array: '6307965, 6352608',
      name_search: 'ANHLPRIN',
      name: "Anemone hupehensis 'Little Princess'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 601,
      chnn_stock_retail: 710,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3BCS82;8Z31AREY;9JH38XWA;TC69RXVY',
      statusContent: 'dot',
      hash: '5Y6X3LGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Y6X3LGT'
    },
    {
      status: 210,
      sku: 'PM0078161',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349747',
      rtl_batch_array: '6307975, 6349747',
      name_search: 'ANHMARGA',
      name: "Anemone hybrida 'Margarete'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 784,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7JRA3C;BWCANKT6;JV1CB4YY;4G3VBRKN;H48K5LTE;SG2PW84E',
      statusContent: 'dot',
      hash: 'X77VYB8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X77VYB8D'
    },
    {
      status: 210,
      sku: 'PM0078162',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324691',
      rtl_batch_array: '6307976, 6324691, 6345189',
      name_search: 'ANHPAMIN',
      name: "Anemone hybrida 'Pamina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1452,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6R9V346;W4KAAL6D;HH98TZAC;SCNYWCWG;V7K1VNRY;13LJW99E;C6ZH7BBA;RN71E431;5E6TP5GT;85ECCB5S',
      statusContent: 'dot',
      hash: '9KRZPVJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KRZPVJW'
    },
    {
      status: 810,
      sku: 'PM0078163',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307977',
      rtl_batch_array: '6307977',
      name_search: 'ANHROTKA',
      name: "Anemone hybrida 'Rotk\u00e4ppchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6REZLVLK;TH5SHV96;VN6EZWEY;DGXLN321;638VA6NN;H7BE9972;CX14VSC5;TLYS1B2K',
      statusContent: 'dot',
      hash: 'PHAKCE11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHAKCE11'
    },
    {
      status: 210,
      sku: 'PM0078140',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307942',
      rtl_batch_array: '6307942',
      name_search: 'ASSGITTE',
      name: "Aster 'Schneegitter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BT8RHG3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BT8RHG3W'
    },
    {
      status: 210,
      sku: 'PM0078127',
      core_name: 'Plant',
      sppl_ean: '8720664852488',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6081406',
      rtl_batch_array: '6081406, 6307923',
      name_search: 'ASHRICHA',
      name: "Aster (D) 'Heinz Richard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 124,
      chnn_stock_retail: 244,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '591CNR1X',
      statusContent: 'dot',
      hash: 'B12LV87W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B12LV87W'
    },
    {
      status: 210,
      sku: 'PM0078128',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307925',
      rtl_batch_array: '6307925',
      name_search: 'ASAGLUEC',
      name: "Aster amellus 'Gl\u00fccksfund'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W47WVZ56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W47WVZ56'
    },
    {
      status: 210,
      sku: 'PM0078131',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307931',
      rtl_batch_array: '6307931',
      name_search: 'ASCBHEAV',
      name: "Aster cordifolius 'Blue Heaven'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WDW829N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDW829N5'
    },
    {
      status: 210,
      sku: 'PM0078132',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307933',
      rtl_batch_array: '6267600, 6307933',
      name_search: 'ASCIDEAL',
      name: "Aster cordifolius 'Ideal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 968,
      chnn_stock_retail: 1018,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PVW21ZZP',
      statusContent: 'dot',
      hash: 'GR1697W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GR1697W1'
    },
    {
      status: 210,
      sku: 'PM0078133',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307934',
      rtl_batch_array: '6370329, 6307934',
      name_search: 'ASCPHOTO',
      name: "Aster cordifolius 'Photograph'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 322,
      chnn_stock_retail: 402,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GHNL4H57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHNL4H57'
    },
    {
      status: 210,
      sku: 'PM0078134',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307935',
      rtl_batch_array: '6307935',
      name_search: 'ASCSSPRA',
      name: "Aster cordifolius 'Silver Spray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V3BSSSJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3BSSSJ2'
    },
    {
      status: 210,
      sku: 'PM0078136',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349766',
      rtl_batch_array: '6307938, 6349766',
      name_search: 'ASEBWOND',
      name: "Aster ericoides 'Blue Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 771,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J1Z46TJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1Z46TJP'
    },
    {
      status: 210,
      sku: 'PM0078137',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307939',
      rtl_batch_array: '6307939',
      name_search: 'ASEGSPRA',
      name: "Aster ericoides 'Golden Spray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WJ3GZ5SW;YA3AJJT8;Z8WBW6X3;1TTG1AC6;T44CX4EV',
      statusContent: 'dot',
      hash: 'RVEVNKBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVEVNKBA'
    },
    {
      status: 210,
      sku: 'PM0078138',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307940',
      rtl_batch_array: '6302380, 6307940',
      name_search: 'ASELOVEL',
      name: "Aster ericoides 'Lovely'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 934,
      chnn_stock_retail: 1026,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K3VRT95B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3VRT95B'
    },
    {
      status: 210,
      sku: 'PM0078139',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307941',
      rtl_batch_array: '6307941, 6321889',
      name_search: 'ASEPCLOU',
      name: "Aster ericoides 'Pink Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 316,
      chnn_stock_retail: 393,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZLC25KC5',
      statusContent: 'dot',
      hash: '9SP3H2V6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SP3H2V6'
    },
    {
      status: 210,
      sku: 'PM0078141',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307943',
      rtl_batch_array: '6370330, 6307943',
      name_search: 'ASTESCHN',
      name: "Aster ericoides 'Schneetanne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 908,
      chnn_stock_retail: 1008,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5BVA9KRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BVA9KRP'
    },
    {
      status: 210,
      sku: 'PM0078142',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321890',
      rtl_batch_array: '6307944, 6321890',
      name_search: 'ASFJUNGF',
      name: "Aster frikartii 'Jungfrau'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NCENRYWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCENRYWJ'
    },
    {
      status: 210,
      sku: 'PM0078143',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307946',
      rtl_batch_array: '6301435, 6307946',
      name_search: 'ASLCALLI',
      name: "Aster laevis 'Calliope'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 659,
      chnn_stock_retail: 916,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N1TZVW51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1TZVW51'
    },
    {
      status: 210,
      sku: 'PM0078144',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307948',
      rtl_batch_array: '6307948',
      name_search: 'ASLLBLAC',
      name: "Aster lateriflorus 'Lady in Black'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L166KA6B;8H1Y8H2L;EA7NL97C;812BBB7B;7YW38N2C;24TDNV9L;V74568T3;9CT7D64Y;1PGZ9CRB;H2YZSV7E;2LAC6BLD;BNZG69XK;SYJBTET3',
      statusContent: 'dot',
      hash: '1VE2APVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VE2APVD'
    },
    {
      status: 210,
      sku: 'PM0078145',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321892',
      rtl_batch_array: '6307949, 6321892',
      name_search: 'ASLPRINC',
      name: "Aster lateriflorus 'Prince'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 285,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LN3DVZ6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LN3DVZ6X'
    },
    {
      status: 210,
      sku: 'PM0078146',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302386',
      rtl_batch_array: '6302386, 6307951',
      name_search: 'ASMALBUS',
      name: "Aster macrophyllus 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1202,
      chnn_stock_retail: 1802,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XDWD9XCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDWD9XCH'
    },
    {
      status: 810,
      sku: 'PM0078149',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6344090',
      rtl_batch_array: '6307959, 6344090',
      name_search: 'ASNAAPGE',
      name: "Aster novae-angliae 'Andenken an Paul Gerber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P6DSGGB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6DSGGB4'
    },
    {
      status: 210,
      sku: 'PM0078150',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321893',
      rtl_batch_array: '6307960, 6321893',
      name_search: 'ASNAHPIN',
      name: "Aster novae-angliae 'Harrington's Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AL6GR2DB;G85X16SC;R7JL6JSY;VS2D2J81;717G36NV;8LH8NVJD;R252JK36',
      statusContent: 'dot',
      hash: 'L5G79TH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5G79TH2'
    },
    {
      status: 210,
      sku: 'PM0078151',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307961',
      rtl_batch_array: '6307961',
      name_search: 'ASNAVDOM',
      name: "Aster novae-angliae 'Vibrant Dome'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 780,
      chnn_stock_retail: 780,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '61ERYY2W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61ERYY2W'
    },
    {
      status: 210,
      sku: 'PM0078147',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307953',
      rtl_batch_array: '6307953',
      name_search: 'ASNBBRIG',
      name: "Aster novi-belgii 'Brigitte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKT2S8KH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKT2S8KH'
    },
    {
      status: 210,
      sku: 'PM0078148',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307957',
      rtl_batch_array: '6307957',
      name_search: 'ASNBRRUB',
      name: "Aster novi-belgii 'Royal Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4JA7L7E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JA7L7E5'
    },
    {
      status: 810,
      sku: 'PM0078152',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321896',
      rtl_batch_array: '6307962, 6321896',
      name_search: 'ASOOSKIE',
      name: "Aster oblongifolius 'October Skies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 765,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '59HH5KZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59HH5KZR'
    },
    {
      status: 210,
      sku: 'PM0078153',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307963',
      rtl_batch_array: '6307963',
      name_search: 'ASRASKY',
      name: "Aster radula 'August Sky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 472,
      chnn_stock_retail: 472,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P9JYY5WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9JYY5WA'
    },
    {
      status: 210,
      sku: 'PM0064548',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272800',
      rtl_batch_array: '6272800',
      name_search: 'SALYCIOI',
      name: 'Salvia lycioides',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SEEE9W4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEEE9W4Y'
    },
    {
      status: 210,
      sku: 'PM0069168',
      core_name: 'Plant',
      sppl_ean: '8720349406210',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '4638894',
      rtl_batch_array: '4638894, 6271429',
      name_search: 'LIMMWHIT',
      name: "Liriope muscari 'Monroe White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 660,
      chnn_stock_retail: 3262,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AYLD59KE;4NY9BBP2;28G2AKK8;D4B3XNSA;T4SJCPL6;KVVDLPV1;HGER8DSK;DV6AZ3ST;XED3AWGH;P4RDERDW;VETGZ9Y7',
      imageBatch: 'DAG7E23S',
      statusContent: 'dot',
      hash: 'DN3K5NNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DN3K5NNB'
    },
    {
      status: 910,
      sku: 'PM0054709',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LECARDIA',
      name: 'Leonurus cardiaca',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'DV73J5E9',
      statusContent: 'dot',
      hash: 'R5K31X4A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R5K31X4A'
    },
    {
      status: 210,
      sku: 'PM0064805',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244227',
      rtl_batch_array: '6244227, 5837425, 6253089',
      name_search: 'FUMCORNE',
      name: "Fuchsia 'Madame Cornelissen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1263,
      chnn_stock_retail: 2133,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7V1GVRRN;VW4HZ6JG;NDHD7Y8X;H1RB9BTV;BJE6PN6C;79APZY56;ERKSB6PT;PSXVLKBJ',
      statusContent: 'dot',
      hash: '1VZCWBTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VZCWBTX'
    },
    {
      status: 210,
      sku: 'PM0081589',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339739',
      rtl_batch_array: '6339739',
      name_search: 'ALMAMAZ\u00c9',
      name: 'Alstroemeria Majestic Maz\u00e9',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6KKGRJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6KKGRJL'
    },
    {
      status: 810,
      sku: 'PM0081590',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339830',
      rtl_batch_array: '6339830',
      name_search: 'LEHASTAT',
      name: 'Lepechinia hastata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L82Z9WWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L82Z9WWC'
    },
    {
      status: 810,
      sku: 'PM0081591',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339911',
      rtl_batch_array: '6339911',
      name_search: 'SAMELLY',
      name: "Sanguisorba 'Miss Elly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R47KK152',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R47KK152'
    },
    {
      status: 210,
      sku: 'PM0047638',
      core_name: 'Plant',
      sppl_ean: '8720664690042',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953598',
      rtl_batch_array: '5953598',
      name_search: 'FEGIGANT',
      name: 'Festuca gigantea',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2PL17BBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PL17BBW'
    },
    {
      status: 210,
      sku: 'PM0074276',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268824',
      rtl_batch_array: '6268824',
      name_search: 'SEIRAZU',
      name: "Sempervivum 'Irazu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3BK9BP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3BK9BP7'
    },
    {
      status: 210,
      sku: 'PM0066609',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6301794',
      rtl_batch_array: '6221375, 6301794, 6353264, 6290223',
      name_search: 'RHPTANGU',
      name: 'Rheum palmatum tanguticum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 683,
      chnn_stock_retail: 2446,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1GYAKLE;R6V169T3',
      statusContent: 'dot',
      hash: '7RHYTAJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RHYTAJD'
    },
    {
      status: 210,
      sku: 'PM0081669',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333203',
      rtl_batch_array: '6333203',
      name_search: 'CAPRUBRI',
      name: 'Campanula punctata rubriflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3096,
      chnn_stock_retail: 3096,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '73VS2SD9',
      statusContent: 'dot',
      hash: 'BXGXL2DR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXGXL2DR'
    },
    {
      status: 210,
      sku: 'PM0081670',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333206',
      rtl_batch_array: '6333206',
      name_search: 'CABRROOS',
      name: "Carex buchananii 'Red Rooster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3672,
      chnn_stock_retail: 3672,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DDNXC7WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDNXC7WL'
    },
    {
      status: 210,
      sku: 'PM0081671',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333217',
      rtl_batch_array: '6333217',
      name_search: 'DIPGLOXI',
      name: "Digitalis purpurea 'Gloxiniiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2520,
      chnn_stock_retail: 2520,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VCZVCRWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCZVCRWB'
    },
    {
      status: 210,
      sku: 'PM0081672',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333221',
      rtl_batch_array: '6333221',
      name_search: 'DUINDICA',
      name: 'Duchesnea indica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 267,
      chnn_stock_retail: 267,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26L9H7V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26L9H7V1'
    },
    {
      status: 810,
      sku: 'PM0047214',
      core_name: 'Plant',
      sppl_ean: '8720349492992',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5931844',
      rtl_batch_array: '5931844',
      name_search: 'COPAUCIF',
      name: 'Corylopsis pauciflora',
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 39.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LDGETZ35;4HWKVX7H;D9G19CA5;ZYCA4VNX;YKTAZCR3;C6GJZPXP;PK4P4993;RL7VSYRE;7A59BD77',
      statusContent: 'dot',
      hash: 'W1SV1DCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1SV1DCY'
    },
    {
      status: 210,
      sku: 'PM0070072',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220388',
      rtl_batch_array: '6220388',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 4282,
      chnn_stock_retail: 4282,
      sppl_prcp: 1.977,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: '1N7PW3H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1N7PW3H9'
    },
    {
      status: 210,
      sku: 'PM0081673',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333225',
      rtl_batch_array: '6386118, 6333225',
      name_search: 'EUCANNAB',
      name: 'Eupatorium cannabinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1711,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8PBAVP98;B9DSBH39',
      statusContent: 'dot',
      hash: 'E6L9KVW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6L9KVW2'
    },
    {
      status: 210,
      sku: 'PM0078172',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307988',
      rtl_batch_array: '6307988',
      name_search: 'AQVCBLUE',
      name: "Aquilegia vulgaris 'Clementine Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CEBCP1E7;T1RN8NBZ;2Y5XDSAH',
      statusContent: 'dot',
      hash: 'DDTN7Z5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDTN7Z5T'
    },
    {
      status: 210,
      sku: 'PM0047202',
      core_name: 'Plant',
      sppl_ean: '8720664811669',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5929443',
      rtl_batch_array: '5929443',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030040C10',
      rng_range_identifier: 'H030040C10',
      rng_range_description: 'H30 cm 40 cm C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'WXVZD2H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXVZD2H8'
    },
    {
      status: 210,
      sku: 'PM0085232',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373124',
      rtl_batch_array: '6373124',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1651,
      chnn_stock_retail: 1651,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: 'LKAPKND2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKAPKND2'
    },
    {
      status: 210,
      sku: 'PM0058492',
      core_name: 'Plant',
      sppl_ean: '8720664801288',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112841',
      rtl_batch_array: '6112841, 6301770',
      name_search: 'PEASBLUE',
      name: 'Perovskia atriplicifolia Silvery Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 686,
      chnn_stock_retail: 762,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y7DJJ3KR;566VV31C;AEP2YG7A;XWE79YTB;N9XEXGX8;XSXCCKLD;Y9GY78RD',
      statusContent: 'dot',
      hash: 'BRN9PKEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRN9PKEH'
    },
    {
      status: 210,
      sku: 'PM0081674',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333227',
      rtl_batch_array: '6333227',
      name_search: 'EUCPLENU',
      name: "Eupatorium cannabinum 'Plenum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ATCSWEB3',
      statusContent: 'dot',
      hash: 'TSAZAYNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSAZAYNH'
    },
    {
      status: 210,
      sku: 'PM0047095',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348364',
      rtl_batch_array: '6348364',
      name_search: 'LUGYELLO',
      name: "Lupinus 'Gallery Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CNRYJBK2;8E54WA3L;W2AB2BL1;19H4L3ZR;BKCWAADY',
      statusContent: 'dot',
      hash: '26YEDKH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26YEDKH9'
    },
    {
      status: 810,
      sku: 'PM0081675',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333229',
      rtl_batch_array: '6370355, 6333229',
      name_search: 'EUMRIESE',
      name: "Eupatorium maculatum 'Riesenschirm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 349,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EK6WXT5W',
      statusContent: 'dot',
      hash: 'VHTGPDAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHTGPDAD'
    },
    {
      status: 210,
      sku: 'PM0081676',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333236',
      rtl_batch_array: '6371170, 6333236',
      name_search: 'GALTFRUT',
      name: "Gaura lindheimeri 'Tutti Frutti'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 476,
      chnn_stock_retail: 644,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJ1SZASA;D19TBTTC;W6K8XXZ9;N41C2D6V;NCZ25KNL;XGTXT5DT',
      statusContent: 'dot',
      hash: '9RGBXRJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RGBXRJN'
    },
    {
      status: 210,
      sku: 'PM0081677',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6340027',
      rtl_batch_array: '6333243, 6340027',
      name_search: 'GYPANICU',
      name: 'Gypsophila paniculata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1104,
      chnn_stock_retail: 2156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P94S6BWW;82K75K39;RNA6XZHS;HVPSTTEV;1B6TY5R8;TGAJ9B54;NK5V7Z66;RJ8JBEXB;XR45AKT3;HXKXTLCJ',
      statusContent: 'dot',
      hash: 'D7YAA6DL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7YAA6DL'
    },
    {
      status: 810,
      sku: 'PM0078176',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349858',
      rtl_batch_array: '6349858',
      name_search: 'ARFABBRE',
      name: 'Arisaema flavum abbreviatum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_order_minimum: 3,
      sppl_prcp: 3.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67LPK6BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67LPK6BC'
    },
    {
      status: 810,
      sku: 'PM0064515',
      core_name: 'Plant',
      sppl_ean: '8720664852303',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6156096',
      rtl_batch_array: '6156096',
      name_search: 'ASEUROPA',
      name: 'Asarum europaeum',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_order_minimum: 3,
      sppl_prcp: 3.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RYRN5591;ZKTN3XKC;H71PLP5B;9BAXRT2D;Z5EL57BS;K53GHG4P',
      statusContent: 'dot',
      hash: '5K8L1YJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K8L1YJB'
    },
    {
      status: 210,
      sku: 'PM0085233',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373125',
      rtl_batch_array: '6373125',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 520,
      chnn_stock_retail: 520,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: '2D6PB38G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2D6PB38G'
    },
    {
      status: 910,
      sku: 'PM0058463',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MOBWHITE',
      name: "Monarda 'Balmy White'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '6S9K54X3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6S9K54X3'
    },
    {
      status: 210,
      sku: 'PM0054692',
      core_name: 'Plant',
      sppl_ean: '8720664864030',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080660',
      rtl_batch_array: '6080660',
      name_search: 'GALPBLUS',
      name: "Gaura lindheimeri 'Passionate Blush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 696,
      chnn_stock_retail: 696,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X4TBJX9A',
      statusContent: 'dot',
      hash: 'P877SXDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P877SXDT'
    },
    {
      status: 210,
      sku: 'PM0066560',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162712',
      rtl_batch_array: '6162712',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 1077,
      chnn_stock_retail: 1077,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'W3DABX79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3DABX79'
    },
    {
      status: 910,
      sku: 'PM0069179',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEBURGUN',
      name: "Penstemon 'Burgundy'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '499WWH6B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '499WWH6B'
    },
    {
      status: 210,
      sku: 'PM0064738',
      core_name: 'Plant',
      sppl_ean: '8720664877764',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140825',
      rtl_batch_array: '6140825',
      name_search: 'PHROBERT',
      name: "Phlox 'Roberta'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2V73SASL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V73SASL'
    },
    {
      status: 210,
      sku: 'PM0047621',
      core_name: 'Plant',
      sppl_ean: '8720349414703',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953577',
      rtl_batch_array: '5953577',
      name_search: 'ECPALBA',
      name: "Echinacea purpurea 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 16581,
      chnn_stock_retail: 16581,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSG792BE;BWC2X2CD;JD32Y745',
      statusContent: 'dot',
      hash: 'PTT58B1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTT58B1N'
    },
    {
      status: 210,
      sku: 'PM0068762',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301750',
      rtl_batch_array: '6301750',
      name_search: 'OPJAPONI',
      name: 'Ophiopogon japonicus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYT9BYDR;J7GNGA2T;1Y4BAK1A;NARJE2WA',
      statusContent: 'dot',
      hash: 'X8EXVNZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8EXVNZH'
    },
    {
      status: 210,
      sku: 'PM0068795',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208130',
      rtl_batch_array: '6208130',
      name_search: 'ZAAETHIO',
      name: 'Zantedeschia aethiopica',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G4N57S4T;747PGYXB;8LL5ZJYW;22L9EV1K;GKBYZZHN;S1N9G69E;NBA1LT92;Z95CPTGR;22NH39D4;4AL2LC8R;ZNNAW5TZ;8TW66E9S;8CYX1Y2T',
      statusContent: 'dot',
      hash: '9789NJ2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9789NJ2E'
    },
    {
      status: 210,
      sku: 'PM0047632',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286493',
      rtl_batch_array: '6286493',
      name_search: 'EUMATROP',
      name: "Eupatorium maculatum 'Atropurpureum'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 757,
      chnn_stock_retail: 757,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V9H31EY8;JDAR99JZ;7WPG4SVR;EKNXT7NS;J3LH5YA3',
      statusContent: 'dot',
      hash: 'AC9EN6B2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AC9EN6B2'
    },
    {
      status: 210,
      sku: 'PM0047162',
      core_name: 'Plant',
      sppl_ean: '8720664875777',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133437',
      rtl_batch_array: '6133437, 6350731',
      name_search: 'ORVHSPIC',
      name: "Origanum vulgare 'Hot Spicy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 226,
      chnn_stock_retail: 987,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YSSYPSE',
      statusContent: 'dot',
      hash: '2ZX1AZ58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZX1AZ58'
    },
    {
      status: 910,
      sku: 'PM0064984',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346749',
      rtl_batch_array: '6346749',
      name_search: 'MEGGOLDR',
      name: 'Metasequoia glyptostroboides Goldrush',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XHYCJRNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHYCJRNN'
    },
    {
      status: 210,
      sku: 'PM0078189',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349982',
      rtl_batch_array: '6349982',
      name_search: 'DAPNANA',
      name: "Darmera peltata 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '21KB45VC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21KB45VC'
    },
    {
      status: 910,
      sku: 'PM0046588',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PASTEMPL',
      name: "Paeonia (LD) 'Shirley Temple'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'PPWTDJ9G;SP2P82ER;LRCNG25H;SHDCJXDE;1D77YH4G;9G5RDZBS;N7GZGE51;8LG4CW7P;XRAHC8PX',
      statusContent: 'dot',
      hash: 'XSGXGBHC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XSGXGBHC'
    },
    {
      status: 210,
      sku: 'PM0058472',
      core_name: 'Plant',
      sppl_ean: '8720664800014',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112806',
      rtl_batch_array: '6112806',
      name_search: 'EDI81972',
      name: "Narcissus (bul) 'Spoirot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WD6654Z3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WD6654Z3'
    },
    {
      status: 210,
      sku: 'PM0069150',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252621',
      rtl_batch_array: '6252621',
      name_search: 'HECARAME',
      name: "Heuchera 'Caramel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1851,
      chnn_stock_retail: 1851,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1WBVG799;TGVX4RYT;2T51378Z;B3BGRN53;48ZLP6PN;4PL5HYGT;SY3K85SW;D4AK4EW4;85EPS5C4',
      statusContent: 'dot',
      hash: '6X3ADCX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X3ADCX4'
    },
    {
      status: 210,
      sku: 'PM0081678',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348985',
      rtl_batch_array: '6374027, 6333244, 6348985',
      name_search: 'HAMAGOLD',
      name: "Hakonechloa macra 'All Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 2166,
      sppl_order_minimum: 3,
      sppl_prcp: 1.541,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X1LDXZPZ;2GAPK5AR;2VS21H7H;879VJCCL;L77ZYAPY;EZ31HP75;P57NRDLS;EGKHP86K;HNAC44DK;2XL93WZY;A6ZRAXCG;R9T9K2HW;RH5T7XSX;E49BXR4P',
      statusContent: 'dot',
      hash: '1XRJ17N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XRJ17N8'
    },
    {
      status: 210,
      sku: 'PM0054703',
      core_name: 'Plant',
      sppl_ean: '8720664865020',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080679',
      rtl_batch_array: '6080679',
      name_search: 'GESJELSL',
      name: "Geranium sanguineum 'John Elsley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2TNX75WN;GEE4WT5V;RX7GL6A6',
      statusContent: 'dot',
      hash: '7LXS3CCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LXS3CCN'
    },
    {
      status: 810,
      sku: 'PM0078193',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349935',
      rtl_batch_array: '6349935',
      name_search: 'CHGRYLLU',
      name: 'Chrysopogon gryllus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXACB2WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXACB2WV'
    },
    {
      status: 810,
      sku: 'PM0074277',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268825',
      rtl_batch_array: '6268825',
      name_search: 'SILPRINC',
      name: "Sidalcea 'Little Princess'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZY2B5YCA',
      statusContent: 'dot',
      hash: '87V87HEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87V87HEK'
    },
    {
      status: 210,
      sku: 'PM0069169',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252668',
      rtl_batch_array: '6252668',
      name_search: 'LIDHBLUE',
      name: "Lithodora diffusa 'Heavenly Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1663,
      chnn_stock_retail: 1663,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RV13BXSN;BV2Y2ZRN;ZDPKXPXZ;SN938LTA;L5E5KHAW;6XR4NNR7;H96W98DY;7VGPDRY4;CZBJHDXT',
      statusContent: 'dot',
      hash: 'LYVB5KA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYVB5KA3'
    },
    {
      status: 210,
      sku: 'PM0070070',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220386',
      rtl_batch_array: '6220386',
      name_search: 'CACKBLUE',
      name: "Caryopteris clandonensis 'Kew Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1299,
      chnn_stock_retail: 1299,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2PCYA2A;JRHPKVBA',
      statusContent: 'dot',
      hash: 'ZLWBJTPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLWBJTPN'
    },
    {
      status: 210,
      sku: 'PM0064753',
      core_name: 'Plant',
      sppl_ean: '8720664877955',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140840',
      rtl_batch_array: '6140840',
      name_search: 'PHGDREAM',
      name: "Phlox (P) 'Grenadine Dream'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1XSGXVA3;2WBYHSXK;9652BHT2;WRH9RYJ6;6WB9LJRD',
      statusContent: 'dot',
      hash: '7ATRKSJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ATRKSJT'
    },
    {
      status: 210,
      sku: 'PM0078201',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350047',
      rtl_batch_array: '6350047',
      name_search: 'ECPHONEY',
      name: "Echinacea purpurea 'Honeydew'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 507,
      chnn_stock_retail: 507,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1LB18WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1LB18WC'
    },
    {
      status: 210,
      sku: 'PM0068887',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209223',
      rtl_batch_array: '6209223',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: 'TJ5LNLRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJ5LNLRK'
    },
    {
      status: 210,
      sku: 'PM0068888',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209224',
      rtl_batch_array: '6209224',
      name_search: 'FANITIDA',
      name: 'Fargesia nitida',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RX7LD9EV;W6A24647',
      statusContent: 'dot',
      hash: 'EDSLATJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDSLATJY'
    },
    {
      status: 210,
      sku: 'PM0070069',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385810',
      rtl_batch_array: '6220384, 6385810',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 865,
      chnn_stock_retail: 2548,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: '8VR71VYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VR71VYW'
    },
    {
      status: 210,
      sku: 'PM0069149',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252619',
      rtl_batch_array: '6252619',
      name_search: 'HEACRISP',
      name: "Heuchera 'Apple Crisp'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 681,
      chnn_stock_retail: 681,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LBERP718;SYPPALB3;N9AN7KS3;18Y8SGZT;773Z27EC;J98K6P9V;H5ZRGGJC',
      statusContent: 'dot',
      hash: '7N1BHG41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N1BHG41'
    },
    {
      status: 210,
      sku: 'PM0068890',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260734',
      rtl_batch_array: '6260734',
      name_search: 'MISHMUSS',
      name: "Miscanthus sinensis 'Herman Mussel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1177,
      chnn_stock_retail: 1177,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HD81J22;4V87XL3S;BRHBW4XV;BXARZKN3',
      statusContent: 'dot',
      hash: 'L4E9DEDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4E9DEDA'
    },
    {
      status: 810,
      sku: 'PM0069166',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254771',
      rtl_batch_array: '6266256, 6254771',
      name_search: 'LEVULGAR',
      name: 'Leucanthemum vulgare',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 142,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '984CC8C4;5N15VJ61;W2RPPYGS;XNK6SEHB',
      statusContent: 'dot',
      hash: '5CN3L7J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CN3L7J8'
    },
    {
      status: 210,
      sku: 'PM0078194',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308542',
      rtl_batch_array: '6349941, 6302320, 6308542',
      name_search: 'ACCHOCOH',
      name: "Actaea 'Chocoholic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 936,
      chnn_stock_retail: 2240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '785VV1GZ;PLT9654N;BATJXDGH;P2CY2S6W;9B5Z2HCR;JX29LT3C;BYN19TY1',
      statusContent: 'dot',
      hash: 'JBD6WB4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBD6WB4C'
    },
    {
      status: 210,
      sku: 'PM0071033',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234808',
      rtl_batch_array: '6234808',
      name_search: 'HEVENUS',
      name: "Heuchera 'Venus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 444,
      chnn_stock_retail: 444,
      sppl_prcp: 3.169,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N26D5ZS4;KN7BWNAP',
      statusContent: 'dot',
      hash: 'H9L4GVWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9L4GVWL'
    },
    {
      status: 210,
      sku: 'PM0064401',
      core_name: 'Plant',
      sppl_ean: '8720664855410',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151510',
      rtl_batch_array: '6151510',
      name_search: 'CAFBZING',
      name: "Carex flacca 'Blue Zinger'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 685,
      chnn_stock_retail: 685,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9G4P5KKX;YXKLT2AB;GH9ESHCR;SSNEWWXV;N4AAN9Y3;DCDK42V9;CR2Z5NCZ',
      statusContent: 'dot',
      hash: 'B58V3RSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B58V3RSR'
    },
    {
      status: 210,
      sku: 'PM0078195',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349942',
      rtl_batch_array: '6349942, 6348269',
      name_search: 'ACSBRUNE',
      name: "Actaea simplex 'Brunette'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 988,
      chnn_stock_retail: 2106,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V227LA75;ZSNVBLB1;HT3ALK3A;KALLKRJ4;HYP2PWZ9',
      statusContent: 'dot',
      hash: '4L9D3ZVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4L9D3ZVZ'
    },
    {
      status: 210,
      sku: 'PM0078164',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307979',
      rtl_batch_array: '6307979',
      name_search: 'ANTALBAD',
      name: "Anemone tomentosa 'Albadura'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 169,
      chnn_stock_retail: 169,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XA8A6LK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA8A6LK1'
    },
    {
      status: 210,
      sku: 'PM0078179',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308003',
      rtl_batch_array: '6308003',
      name_search: 'ASVVULCA',
      name: "Astilbe 'Vision Vulcano'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZECXSKRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZECXSKRK'
    },
    {
      status: 910,
      sku: 'PM0046587',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PANDEMAY',
      name: "Paeonia (LD) 'No\u00e9mi Demay'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'V2VBTDED;LDAXHEEH',
      statusContent: 'dot',
      hash: 'S7TPD6ZY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S7TPD6ZY'
    },
    {
      status: 210,
      sku: 'PM0074271',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268815',
      rtl_batch_array: '6268815',
      name_search: 'PUBENEDI',
      name: "Pulmonaria 'Benediction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AD29DGRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AD29DGRX'
    },
    {
      status: 210,
      sku: 'PM0078178',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308002',
      rtl_batch_array: '6308002',
      name_search: 'ASCLVPUR',
      name: "Astilbe chinensis 'Little Vision Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 875,
      chnn_stock_retail: 875,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H8XBRK4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8XBRK4J'
    },
    {
      status: 210,
      sku: 'PM0078180',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6344091',
      rtl_batch_array: '6308004, 6344091',
      name_search: 'ASTABULA',
      name: 'Astilboides tabularis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 686,
      chnn_stock_retail: 749,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XHJXCWJT;RGWK2NZZ;X96CDAB8;Z6N8V26H;LJE6ZZCL;CA5SNRP7;76RR5V2C;AJPHHYA7;Z9VLEC66;8XRBD8LG;LS41BKXA',
      statusContent: 'dot',
      hash: 'X1T3EDY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1T3EDY7'
    },
    {
      status: 210,
      sku: 'PM0078181',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308005',
      rtl_batch_array: '6308005, 6338926',
      name_search: 'ASBUCKLA',
      name: "Astrantia 'Buckland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 800,
      chnn_stock_retail: 1971,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PL34689G;95XAYK75;4DT2YP6Z;NJYXD6GY',
      statusContent: 'dot',
      hash: 'HJGVW71A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJGVW71A'
    },
    {
      status: 810,
      sku: 'PM0047633',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259152',
      rtl_batch_array: '6259152',
      name_search: 'EUPURPUR',
      name: 'Eupatorium purpureum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZXWBKT;GAWJRSDN;K7W1LGN9;K9JCAZHW',
      statusContent: 'dot',
      hash: 'CTDTA517',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTDTA517'
    },
    {
      status: 910,
      sku: 'PM0059549',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASHGRAAF',
      name: "Astilbe (S) 'Hennie Graafland'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '7GNB51J8;9GCHK9CJ;Z7SVKJAH;81Z3BNGV;ATLWZWWK;ER7YHR57;ZWWS97J2;6WR3PN3X;57EG465A',
      statusContent: 'dot',
      hash: 'ECZC4VX7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ECZC4VX7'
    },
    {
      status: 210,
      sku: 'PM0078182',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369726',
      rtl_batch_array: '6369726, 6308006',
      name_search: 'ASMSLOVE',
      name: "Astrantia major 'Star of Love'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 828,
      chnn_stock_retail: 1519,
      sppl_order_minimum: 3,
      sppl_prcp: 1.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '23HNXBCC',
      statusContent: 'dot',
      hash: 'X71L8PTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X71L8PTL'
    },
    {
      status: 210,
      sku: 'PM0078183',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308008',
      rtl_batch_array: '6308008',
      name_search: 'BAPSMOKE',
      name: "Baptisia 'Purple Smoke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 573,
      chnn_stock_retail: 573,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEE9PXZK;5W1B6133;42GBGZ95;11WCNVZC',
      statusContent: 'dot',
      hash: 'BX5ZLVRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX5ZLVRA'
    },
    {
      status: 910,
      sku: 'PM0078177',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CASUNDRO',
      name: "Calylophus 'Sundrop'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'TKSY6794',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TKSY6794'
    },
    {
      status: 210,
      sku: 'PM0078190',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349927',
      rtl_batch_array: '6349927',
      name_search: 'CHLHLIPS',
      name: "Chelone lyonii 'Hot Lips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 582,
      chnn_stock_retail: 582,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2PTE8VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2PTE8VW'
    },
    {
      status: 210,
      sku: 'PM0078191',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349930',
      rtl_batch_array: '6349930',
      name_search: 'CHOPTURT',
      name: 'Chelone obliqua Pink Turtle',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W2LXBPD9;A95YJVAL;K2XAXYYW',
      statusContent: 'dot',
      hash: 'K4V1817C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4V1817C'
    },
    {
      status: 810,
      sku: 'PM0078192',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349932',
      rtl_batch_array: '6349932',
      name_search: 'CHSERRAT',
      name: 'Chloranthus serratus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BRZW1VZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRZW1VZL'
    },
    {
      status: 210,
      sku: 'PM0078197',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349952',
      rtl_batch_array: '6349952',
      name_search: 'COFMOON',
      name: "Coreopsis 'Full Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JBG8CPVH;A2S97PE8;8JX19AZN;C1D49CD6;A7YGZW8N;4VJ5339B;GBND7KJK',
      statusContent: 'dot',
      hash: 'EYVX3KJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYVX3KJ2'
    },
    {
      status: 210,
      sku: 'PM0078196',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349949',
      rtl_batch_array: '6349949',
      name_search: 'COANANA',
      name: "Coreopsis auriculata 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHZLLD9W;2YP6E3X9;GJ749EJ4;RN2NNTXK;7E5AR412;75ZR3KGX;SN2J3DSK',
      statusContent: 'dot',
      hash: 'P1T1A7XZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1T1A7XZ'
    },
    {
      status: 210,
      sku: 'PM0078184',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349954',
      rtl_batch_array: '6349954',
      name_search: 'CORSDREA',
      name: "Coreopsis rosea 'Sweet Dreams'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RRDTNZ53;9GGKT813;JAEDN9T2;1H8BTTC5;BJ7HXSEE',
      statusContent: 'dot',
      hash: 'J4RPYKSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4RPYKSX'
    },
    {
      status: 210,
      sku: 'PM0078185',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349960',
      rtl_batch_array: '6349960',
      name_search: 'COBHERON',
      name: "Corydalis 'Blue Heron'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 413,
      chnn_stock_retail: 413,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2H63LP84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2H63LP84'
    },
    {
      status: 210,
      sku: 'PM0071040',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244267',
      rtl_batch_array: '6244267, 6221455, 6305782',
      name_search: 'AGBFORTU',
      name: "Agastache 'Blue Fortune'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1695,
      chnn_stock_retail: 2613,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B2R11S8Z;W48S9DTS;5DSE5BTA;HTWDBNAP;24XTTG9S;SNL33P1P',
      statusContent: 'dot',
      hash: 'D2N2EVLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2N2EVLR'
    },
    {
      status: 210,
      sku: 'PM0078187',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349972',
      rtl_batch_array: '6349972',
      name_search: 'CRSARACE',
      name: "Crocosmia 'Saracen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZKYJAGT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKYJAGT6'
    },
    {
      status: 210,
      sku: 'PM0078188',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349974',
      rtl_batch_array: '6349974',
      name_search: 'CRTFGOLD',
      name: "Crocosmia 'Twilight Fairy Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VHW7WAHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHW7WAHE'
    },
    {
      status: 210,
      sku: 'PM0074272',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268817',
      rtl_batch_array: '6268817',
      name_search: 'RHBPLATY',
      name: 'Rhodohypoxis baurii platypetala',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TDV85JA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TDV85JA'
    },
    {
      status: 210,
      sku: 'PM0070074',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220393',
      rtl_batch_array: '6220393',
      name_search: 'HYMRSTEI',
      name: "Hydrangea macrophylla 'Renate Steiniger'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 737,
      chnn_stock_retail: 737,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HYNJ1PA;25ADCYW2;PKL78Z5G;7YGK7B2C;1WNG69PG;CG9D67Z7',
      statusContent: 'dot',
      hash: '6JW4NC2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JW4NC2Y'
    },
    {
      status: 210,
      sku: 'PM0064615',
      core_name: 'Plant',
      sppl_ean: '8720349435210',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6017138',
      rtl_batch_array: '6017138, 6254720',
      name_search: 'ERBMAUVE',
      name: "Erysimum 'Bowles' Mauve'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 709,
      chnn_stock_retail: 816,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E77TACCP;R457CNE9;ZJ4GB27E;9XL459H3;9X6XTVK8;75B2NNVH;6BZS1JAY;CK9VZ638;CNACP5D4;1YL1RP9E;BCBNCCW6;2BN8ARP7',
      statusContent: 'dot',
      hash: 'G8WVHRDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8WVHRDT'
    },
    {
      status: 810,
      sku: 'PM0078186',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349971',
      rtl_batch_array: '6349971',
      name_search: 'CROPEKOE',
      name: 'Crocosmia Orange Pekoe',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZC2HAXD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZC2HAXD'
    },
    {
      status: 210,
      sku: 'PM0078200',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348972',
      rtl_batch_array: '6350044, 6320850, 6348972',
      name_search: 'ECFYELLO',
      name: "Echinacea 'Funky Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 413,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '76381AGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76381AGL'
    },
    {
      status: 210,
      sku: 'PM0078198',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350029',
      rtl_batch_array: '6350029',
      name_search: 'ECCCAKE',
      name: 'Echinacea Carrot Cake',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P2WBRPAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2WBRPAJ'
    },
    {
      status: 210,
      sku: 'PM0078199',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350031',
      rtl_batch_array: '6350031',
      name_search: 'ECPCSPIR',
      name: "Echinacea purpurea 'Cheyenne Spirit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1018,
      chnn_stock_retail: 1018,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YVN4J1WC',
      statusContent: 'dot',
      hash: '28JSJ9CB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28JSJ9CB'
    },
    {
      status: 810,
      sku: 'PM0065924',
      core_name: 'Plant',
      sppl_ean: '8720664869257',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169544',
      rtl_batch_array: '6169544',
      name_search: 'HYVILLOS',
      name: 'Hydrangea villosa',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8BCPEGWK',
      statusContent: 'dot',
      hash: 'B9B6AGA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9B6AGA1'
    },
    {
      status: 210,
      sku: 'PM0054609',
      core_name: 'Plant',
      sppl_ean: '8720664865921',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080400',
      rtl_batch_array: '6080400',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '160180C3',
      rng_range_identifier: 'H160180C3',
      rng_range_description: 'H160 cm 180 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.822,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'EPW4XLSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPW4XLSE'
    },
    {
      status: 910,
      sku: 'PM0063525',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MOCSCARL',
      name: "Monarda 'Cambridge Scarlet'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'CTKLX8JS;L6632JEY;K3EBS9CS;R9LLKCAZ;YD9B327W;HJXCDTR8;NA6YH63N;4234A6DR;VN948NXT;DJWCWRZ2;2GAVVEWG;XNA7TCH6;R3BDPSGS;PNP8KPRZ;JNLZ3VYD;B51DZ86J;R5K5K777;9LHK3AZZ',
      statusContent: 'dot',
      hash: 'GAB9WWDD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GAB9WWDD'
    },
    {
      status: 210,
      sku: 'PM0078202',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6267776',
      rtl_batch_array: '6350048, 6267776, 6320853',
      name_search: 'ECPHLAVA',
      name: "Echinacea purpurea 'Hot Lava'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 421,
      chnn_stock_retail: 1071,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERKXLPGX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERKXLPGX'
    },
    {
      status: 210,
      sku: 'PM0074273',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268819',
      rtl_batch_array: '6268819',
      name_search: 'SEDBPART',
      name: "Sedum 'Birthday Party'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 616,
      chnn_stock_retail: 616,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V11J61AZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V11J61AZ'
    },
    {
      status: 210,
      sku: 'PM0058325',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192819',
      rtl_batch_array: '6192819',
      name_search: 'LIMPPASS',
      name: "Liriope muscari 'Purple Passion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1942,
      chnn_stock_retail: 1942,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4GAKW71A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GAKW71A'
    },
    {
      status: 210,
      sku: 'PM0074270',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268814',
      rtl_batch_array: '6268814',
      name_search: 'PRVSSHAD',
      name: "Primula veris 'Sunset Shades'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5W432RT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W432RT7'
    },
    {
      status: 210,
      sku: 'PM0039015',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266270',
      rtl_batch_array: '6266270',
      name_search: 'THDSWHIT',
      name: 'Thalictrum delavayi Splendide White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 482,
      chnn_stock_retail: 482,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S171R1VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S171R1VY'
    },
    {
      status: 210,
      sku: 'PM0058326',
      core_name: 'Plant',
      sppl_ean: '8720664873087',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112585',
      rtl_batch_array: '6112585',
      name_search: 'LIMSAMAN',
      name: "Liriope muscari 'Samantha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JZGE82W8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZGE82W8'
    },
    {
      status: 210,
      sku: 'PM0069175',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234407',
      rtl_batch_array: '6234407',
      name_search: 'NEALBA',
      name: "Nepeta 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '879VZT6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '879VZT6P'
    },
    {
      status: 210,
      sku: 'PM0070071',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220387',
      rtl_batch_array: '6220387',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1424,
      chnn_stock_retail: 1424,
      sppl_prcp: 2.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: 'WAK52C5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAK52C5W'
    },
    {
      status: 210,
      sku: 'PM0068779',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208090',
      rtl_batch_array: '6208090',
      name_search: 'SANCANAD',
      name: 'Sanguinaria canadensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H1YC2EHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1YC2EHR'
    },
    {
      status: 210,
      sku: 'PM0074274',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268821',
      rtl_batch_array: '6268821',
      name_search: 'SESRMOUN',
      name: "Sedum 'Stewed Rhubarb Mountain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4ZSLJGJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZSLJGJ7'
    },
    {
      status: 910,
      sku: 'PM0074275',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SECGUIL',
      name: "Sempervivum cal. 'Guillaumes'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'C8SYWHRR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C8SYWHRR'
    },
    {
      status: 210,
      sku: 'PM0070073',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220390',
      rtl_batch_array: '6220390',
      name_search: 'CHTLICH',
      name: "Choisya ternata 'Lich'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CVZ3GKT;KKH3BXHZ',
      statusContent: 'dot',
      hash: '5H46NB66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H46NB66'
    },
    {
      status: 210,
      sku: 'PM0064982',
      core_name: 'Plant',
      sppl_ean: '8720349403837',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5606423',
      rtl_batch_array: '5606423',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_order_minimum: 3,
      sppl_prcp: 3.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: 'S68TZB36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S68TZB36'
    },
    {
      status: 210,
      sku: 'PM0071032',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252524',
      rtl_batch_array: '6252524',
      name_search: 'DEMFDBLU',
      name: "Delphinium (P) 'Magic Fountains Dark Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 861,
      chnn_stock_retail: 861,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PT4N1N37',
      statusContent: 'dot',
      hash: 'X1Y6Z8EE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1Y6Z8EE'
    },
    {
      status: 210,
      sku: 'PM0068886',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209222',
      rtl_batch_array: '6209222',
      name_search: 'EUMARTIN',
      name: 'Euphorbia martini',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1884,
      chnn_stock_retail: 1884,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZGGER66P;A464B1DA;759D1DX8;NGTNSJ8K;5RVP5971;EZAXP6D9;6VDSKX6W;K5ANLWE4;67CC2W84;56AAC9E6;JZWWWBAX;2DJEGSH6;JHYKGRW2;Y21BGV1L;XS1ATDTX;PXR7PRW4;KYWPS1PA;ADWTY1K8',
      statusContent: 'dot',
      hash: 'AW18K3XK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AW18K3XK'
    },
    {
      status: 210,
      sku: 'PM0069176',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234415',
      rtl_batch_array: '6234415',
      name_search: 'PAVIRGAT',
      name: 'Panicum virgatum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 544,
      chnn_stock_retail: 544,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '92KXLW3K',
      statusContent: 'dot',
      hash: 'BLEA1K1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLEA1K1K'
    },
    {
      status: 210,
      sku: 'PM0069177',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244289',
      rtl_batch_array: '6244289',
      name_search: 'PAVSHENA',
      name: "Panicum virgatum 'Shenandoah'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5D1Y5Y1;EWAYJRGL;THZVCBYK;XR4S95TL;65B156B5;NNG5RJPG;6A52ZT9L;H6V1JPDC;XE3CWL3V;NDXW85ZB',
      statusContent: 'dot',
      hash: 'X1C1W824',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1C1W824'
    },
    {
      status: 210,
      sku: 'PM0069178',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244291',
      rtl_batch_array: '6244291, 6234420, 5837466',
      name_search: 'PEALOPEC',
      name: 'Pennisetum alopecuroides',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9129,
      chnn_stock_retail: 15615,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRWLYSDR;83B33ADA;27VD1BC4;7LNJ3ARN;SNP3L9WL;GW5J4LST;L9HPT3EL;D4LB3PJK;KLP2TT9K;S46D2SL9;8CV52Z65;YKGTHGSS;VZVDC4J7;S4RBGSPH;D6S1W9CB;149JH1D2;PG5CA87X;NPW9N9WY;YSN92SY3',
      statusContent: 'dot',
      hash: 'BYB2LT4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYB2LT4Z'
    },
    {
      status: 210,
      sku: 'PM0071015',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252773',
      rtl_batch_array: '6252773',
      name_search: 'SESFULDA',
      name: "Sedum spurium 'Fuldaglut'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 374,
      chnn_stock_retail: 374,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X31E5GNB;5G89913K;DYVTWPPL;9CJEZN57;JNJ7XJKE;CKA346SD;8P61PHPY',
      statusContent: 'dot',
      hash: '5ZERNBJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZERNBJX'
    },
    {
      status: 210,
      sku: 'PM0069195',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308799',
      rtl_batch_array: '6254849, 6308799',
      name_search: 'TEGRANDI',
      name: 'Tellima grandiflora',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2355,
      chnn_stock_retail: 3381,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T91WD47P;PSK8CTY4;PYA8RY86;S9LS4YWX;VB2T7YRK',
      statusContent: 'dot',
      hash: '5C68GNKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5C68GNKB'
    },
    {
      status: 210,
      sku: 'PM0056936',
      core_name: 'Plant',
      sppl_ean: '8720349406869',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4306390',
      rtl_batch_array: '4306390',
      name_search: 'SECAWING',
      name: 'Senecio candicans Angel Wings',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_order_minimum: 3,
      sppl_prcp: 2.486,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LAH254P9;WYJ4J6D5;PD9NJNT2;DL27C71T;KTSSA1KH;7S3SH1WP;RNR7HZYX;93HAJY1B;R5C4CSAJ;8CNSHZT9;67EX8JKD;E7N8LT6T;VHYP4H8N',
      imageBatch: 'G3ZYHK4A',
      statusContent: 'dot',
      hash: '5Z4YABWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Z4YABWK'
    },
    {
      status: 210,
      sku: 'PM0070079',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220399',
      rtl_batch_array: '6220399, 6321577',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1295,
      chnn_stock_retail: 1762,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: 'P6TKCZXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6TKCZXB'
    },
    {
      status: 210,
      sku: 'PM0047799',
      core_name: 'Plant',
      sppl_ean: '8720349409204',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6009928',
      rtl_batch_array: '6009928',
      name_search: 'ANHHABUN',
      name: "Anemone hybrida 'Hadspen Abundance'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7727,
      chnn_stock_retail: 7727,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZWJ3J4E;T11J9SV4;1D6X7ZEE;W6A7VVRE;N656ELN8',
      statusContent: 'dot',
      hash: 'DXVB239P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXVB239P'
    },
    {
      status: 910,
      sku: 'PM0064877',
      core_name: 'Plant',
      sppl_ean: '8720664881433',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6151404',
      rtl_batch_array: '6254819, 6151404',
      name_search: 'POSPROLI',
      name: "Polystichum setiferum 'Proliferum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 13,
      chnn_stock_retail: 705,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E17KD9WR;9KBSX7J8;ZRXC8S92;V5EYEGD2;K2J9LX5Y;NCY39L6P;K83TKTP3;PEPS5LEA',
      statusContent: 'dot',
      hash: 'R585827J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R585827J'
    },
    {
      status: 210,
      sku: 'PM0065029',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308610',
      rtl_batch_array: '6350607, 6308610, 6320887',
      name_search: 'KNPPOPSI',
      name: "Kniphofia 'Papaya Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 624,
      chnn_stock_retail: 1757,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZ9W4C26',
      statusContent: 'dot',
      hash: 'D9D2334D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9D2334D'
    },
    {
      status: 910,
      sku: 'PM0081592',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUACULEA',
      name: 'Ruscus aculeatus',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore: 'WPGBR3D1;99JAB6D9',
      statusContent: 'dot',
      hash: '96V1H1E8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '96V1H1E8'
    },
    {
      status: 210,
      sku: 'PM0081593',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340278',
      rtl_batch_array: '6340278',
      name_search: 'TROPRINC',
      name: "Trollius 'Orange Princess'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3662,
      chnn_stock_retail: 3662,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P5N9BGWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5N9BGWG'
    },
    {
      status: 210,
      sku: 'PM0045688',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301525',
      rtl_batch_array: '6301525',
      name_search: 'DECOOPER',
      name: 'Delosperma cooperi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1333,
      chnn_stock_retail: 1333,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9YAPNNPY;VH3LLRDP;GAB6NT4X;2HKSJH8C;HBAZJ6CC;C722XVCN;EW63X1NE;PEKHB8XZ;ZX81HXRY;SXTSZRW7',
      statusContent: 'dot',
      hash: 'A93H464X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A93H464X'
    },
    {
      status: 210,
      sku: 'PM0081679',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333247',
      rtl_batch_array: '6333247',
      name_search: 'HEHSCABR',
      name: 'Heliopsis helianthoides scabra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '24LJA18C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24LJA18C'
    },
    {
      status: 210,
      sku: 'PM0078206',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350075',
      rtl_batch_array: '6350075',
      name_search: 'ECPRAZZM',
      name: "Echinacea purpurea 'Razzmatazz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 387,
      chnn_stock_retail: 387,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZE43K4BT',
      statusContent: 'dot',
      hash: 'GPEJW4XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPEJW4XV'
    },
    {
      status: 210,
      sku: 'PM0078207',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349862',
      rtl_batch_array: '6349862',
      name_search: 'DEEGOSSA',
      name: "Delphinium elatum 'Gossamer'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJ6ZTJRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJ6ZTJRE'
    },
    {
      status: 810,
      sku: 'PM0078208',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349985',
      rtl_batch_array: '6349985, 6370324',
      name_search: 'DECPFOUN',
      name: "Deschampsia cespitosa 'Pixie Fountain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K8NRXT2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8NRXT2J'
    },
    {
      status: 210,
      sku: 'PM0058360',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350589',
      rtl_batch_array: '6350589, 6193638',
      name_search: 'JUESPIRA',
      name: "Juncus effusus 'Spiralis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 1141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JERJ2D94;BGJDZ76B;T94V9DA8;599D3CZK;DCPARL8Y;BGG3PLZ8;X1592N28;WL13VJJD;S7DWXAT8;6VW9J7E3;R86NC3KD;HXSSKRZ5;YPBAV6WC;RC1XSYJ1;1RSKYARL;6SZ1YLY4;T7BDZ8N7;4YG3VHR5;AAVZCEH3;TXGVY6DB;6RYBCJK8;AY5PADBJ;PYGR4VGZ;795LA34G;V82P8N63',
      statusContent: 'dot',
      hash: 'EDBKL545',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDBKL545'
    },
    {
      status: 210,
      sku: 'PM0065041',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202331',
      rtl_batch_array: '6202331, 6159913',
      name_search: 'PHWDELIG',
      name: "Phlox (S) 'White Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 534,
      chnn_stock_retail: 755,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4GBYKZV;L1SBHWCG;JEGAT3NC',
      statusContent: 'dot',
      hash: '2GT3LPW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GT3LPW8'
    },
    {
      status: 810,
      sku: 'PM0078211',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350000',
      rtl_batch_array: '6350000',
      name_search: 'DIIBELLA',
      name: 'Digitalis isabelliana Bella',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TWXJE4VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWXJE4VD'
    },
    {
      status: 210,
      sku: 'PM0070077',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220397',
      rtl_batch_array: '6220397',
      name_search: 'HYPSKYFA',
      name: 'Hydrangea paniculata Skyfall',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 1928,
      chnn_stock_retail: 1928,
      sppl_prcp: 3.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB65XZ1A;576ZXYVH;L2LSYTCA;N7234DKB;8LX9HJ78;NRSLWCTS',
      statusContent: 'dot',
      hash: 'AWSBJ9PC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWSBJ9PC'
    },
    {
      status: 210,
      sku: 'PM0063604',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301365',
      rtl_batch_array: '6301365',
      name_search: 'ACMWBEAU',
      name: "Achillea millefolium 'White Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 564,
      chnn_stock_retail: 564,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z8178SA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8178SA4'
    },
    {
      status: 210,
      sku: 'PM0071046',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252616',
      rtl_batch_array: '6252616',
      name_search: 'HEARED',
      name: "Hemerocallis 'Autumn Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 453,
      chnn_stock_retail: 453,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KE1AEEZH;287DLK1N;SWCNVP6V',
      statusContent: 'dot',
      hash: 'N9Z3BRY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9Z3BRY9'
    },
    {
      status: 210,
      sku: 'PM0081680',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333250',
      rtl_batch_array: '6333250',
      name_search: 'HECPRIDE',
      name: "Hemerocallis 'Country Pride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 938,
      chnn_stock_retail: 938,
      sppl_order_minimum: 3,
      sppl_prcp: 1.734,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2R3XT983',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2R3XT983'
    },
    {
      status: 910,
      sku: 'PM0070046',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VEOBAMPT',
      name: "Verbena officinalis 'Bampton'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'ASZYSN93;T36DBR8W;V56BGA28;DA45BC32;REPNNX7R',
      statusContent: 'dot',
      hash: 'YXANH7LA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YXANH7LA'
    },
    {
      status: 910,
      sku: 'PM0064825',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FEGGTOUP',
      name: "Festuca glauca 'Golden Toupee'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'BCJCPGY1',
      statusContent: 'dot',
      hash: 'BL52N5VN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BL52N5VN'
    },
    {
      status: 910,
      sku: 'PM0064822',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FEGIBLUE',
      name: 'Festuca glauca Intense Blue',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'HREZSYE9;W4RSKZW1;RHK4EB6Z;JETZKT9K;NLDNZVCX;C5NASEJG;K2XWC2JB',
      statusContent: 'dot',
      hash: 'KT61CEYW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KT61CEYW'
    },
    {
      status: 210,
      sku: 'PM0007948',
      core_name: 'Plant',
      sppl_ean: '8720349449989',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5608761',
      rtl_batch_array: '6133460, 6014486, 5608761',
      name_search: 'PEAGELBS',
      name: "Pennisetum alopecuroides 'Gelbstiel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6300,
      chnn_stock_retail: 8762,
      sppl_order_minimum: 3,
      sppl_prcp: 0.588,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PH59S415;5EXCD748;BJJWRXSZ;DS45PLG3',
      statusContent: 'dot',
      hash: 'XT5A7L39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT5A7L39'
    },
    {
      status: 210,
      sku: 'PM0058553',
      core_name: 'Plant',
      sppl_ean: '8720664885714',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112924',
      rtl_batch_array: '6112924',
      name_search: 'SEOXENOX',
      name: "Sedum 'Orange Xenox'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 169,
      chnn_stock_retail: 169,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RH2AXZK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RH2AXZK2'
    },
    {
      status: 210,
      sku: 'PM0070078',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220398',
      rtl_batch_array: '6220398',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 7160,
      chnn_stock_retail: 7160,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: 'YSZT8W1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSZT8W1C'
    },
    {
      status: 210,
      sku: 'PM0081681',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333251',
      rtl_batch_array: '6333251',
      name_search: 'HEMSBREE',
      name: "Hemerocallis 'Scarlet Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 13800,
      chnn_stock_retail: 13800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9LKEWY1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LKEWY1L'
    },
    {
      status: 210,
      sku: 'PM0065028',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308608',
      rtl_batch_array: '6350598, 6238827, 6308608',
      name_search: 'KNBPOPSI',
      name: "Kniphofia 'Banana Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 624,
      chnn_stock_retail: 1120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VX89T83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VX89T83'
    },
    {
      status: 210,
      sku: 'PM0058388',
      core_name: 'Plant',
      sppl_ean: '8720664871809',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112684',
      rtl_batch_array: '6112684',
      name_search: 'LASBARNS',
      name: "Lavatera 'Silver Barnsley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NXCY959L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXCY959L'
    },
    {
      status: 210,
      sku: 'PM0081682',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350400',
      rtl_batch_array: '6350400, 6333257, 6352650',
      name_search: 'HEFCHIEF',
      name: "Heuchera 'Fire Chief'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2158,
      chnn_stock_retail: 3024,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZD25PZBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD25PZBJ'
    },
    {
      status: 210,
      sku: 'PM0047399',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6208409',
      rtl_batch_array: '6208409, 6300416',
      name_search: 'ANMADROS',
      name: "Anemone multifida 'Annabella Deep Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7920,
      chnn_stock_retail: 8920,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XCPSJXKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCPSJXKG'
    },
    {
      status: 910,
      sku: 'PM0078236',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GESCMILE',
      name: "Geranium sanguineum 'Canon Miles'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'B2SS9R2S',
      statusContent: 'dot',
      hash: 'TH44CPN4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TH44CPN4'
    },
    {
      status: 210,
      sku: 'PM0058504',
      core_name: 'Plant',
      sppl_ean: '8720664877573',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112856',
      rtl_batch_array: '6112856, 6363094',
      name_search: 'PHARUNDI',
      name: 'Phalaris arundinacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 776,
      chnn_stock_retail: 4926,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '95LSYZJC;T16J9DA2;LD2EYBJR;NDDTHB7R;D2N47W4B',
      statusContent: 'dot',
      hash: '2L9JD2A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2L9JD2A2'
    },
    {
      status: 210,
      sku: 'PM0085234',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373126',
      rtl_batch_array: '6373126',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1625,
      chnn_stock_retail: 1625,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'NV5C3V1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV5C3V1G'
    },
    {
      status: 210,
      sku: 'PM0078226',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350208',
      rtl_batch_array: '6350208',
      name_search: 'GEIVITAL',
      name: "Geranium ibericum 'Vital'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 206,
      chnn_stock_retail: 206,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TJ5S8ZGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJ5S8ZGD'
    },
    {
      status: 910,
      sku: 'PM0064823',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAMEVERG',
      name: "Carex morrowii 'Everglow'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'LVY17TZ4;X4YD9ZLV;Z9Y4J9Y8;PZ94TYD3;NCVD5BGB;X95EHYNT',
      statusContent: 'dot',
      hash: 'VDNW2AP7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VDNW2AP7'
    },
    {
      status: 210,
      sku: 'PM0085235',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373127',
      rtl_batch_array: '6373127',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2401,
      chnn_stock_retail: 2401,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'K6CRHKC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6CRHKC6'
    },
    {
      status: 910,
      sku: 'PM0078231',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEPCNINE',
      name: "Geranium pratense 'Cloud Nine'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '14LYXZZ5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '14LYXZZ5'
    },
    {
      status: 210,
      sku: 'PM0081683',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333259',
      rtl_batch_array: '6333259',
      name_search: 'HEFSEA',
      name: "Heuchera 'Flores Sea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 1.991,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '24CV1BTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24CV1BTK'
    },
    {
      status: 210,
      sku: 'PM0047798',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278468',
      rtl_batch_array: '6278468',
      name_search: 'ANHAATKI',
      name: "Anemone hybrida 'Andrea Atkinson'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 10436,
      chnn_stock_retail: 10436,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4PPT85W',
      statusContent: 'dot',
      hash: 'DAWL3KDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAWL3KDB'
    },
    {
      status: 210,
      sku: 'PM0078228',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350226',
      rtl_batch_array: '6350226',
      name_search: 'GEPNANA',
      name: "Gentiana purpurea 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44ZE9N8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44ZE9N8D'
    },
    {
      status: 210,
      sku: 'PM0081684',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320883',
      rtl_batch_array: '6333263, 6320883',
      name_search: 'HERACHEL',
      name: "Heuchera 'Rachel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 857,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1N8CALST',
      statusContent: 'dot',
      hash: 'TVGHD8ZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVGHD8ZB'
    },
    {
      status: 210,
      sku: 'PM0081685',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350453',
      rtl_batch_array: '6350453, 6333264',
      name_search: 'HETIRAMI',
      name: "Heuchera 'Tiramisu'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 344,
      chnn_stock_retail: 595,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KX36KGP9;NDLST378;2CGJNZSC;5DLCKY36',
      statusContent: 'dot',
      hash: 'JE96S6K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JE96S6K6'
    },
    {
      status: 210,
      sku: 'PM0065035',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329881',
      rtl_batch_array: '6159863, 6329881',
      name_search: 'BRMSSPEA',
      name: "Brunnera macrophylla 'Silver Spear'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2094,
      chnn_stock_retail: 2426,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2LZ5H1JL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LZ5H1JL'
    },
    {
      status: 210,
      sku: 'PM0070080',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220400',
      rtl_batch_array: '6220400',
      name_search: 'PEABSPRI',
      name: "Perovskia atriplicifolia 'Blue Spritzer'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1890,
      chnn_stock_retail: 1890,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZVLA5TGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVLA5TGZ'
    },
    {
      status: 210,
      sku: 'PM0070081',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220402',
      rtl_batch_array: '6220402',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '020C26L30PL',
      rng_range_identifier: 'H020020C2',
      rng_range_description: 'H20 cm 20 cm C2',
      sppl_stock_available: 1476,
      chnn_stock_retail: 1476,
      sppl_prcp: 2.094,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'D95PRSRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D95PRSRS'
    },
    {
      status: 210,
      sku: 'PM0070082',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220404',
      rtl_batch_array: '6220404, 6336317',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 943,
      chnn_stock_retail: 2327,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '1BEGG7S3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BEGG7S3'
    },
    {
      status: 210,
      sku: 'PM0065049',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 4,
      btch_active_retail: '6243057',
      rtl_batch_array: '6220391, 6243057, 6334188, 6294833',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 382,
      chnn_stock_retail: 9165,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'LLA97K4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLA97K4D'
    },
    {
      status: 210,
      sku: 'PM0070083',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220407',
      rtl_batch_array: '5356499, 6220407, 6336324',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 504,
      chnn_stock_retail: 1698,
      sppl_prcp: 4.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: 'V1LSGTWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1LSGTWE'
    },
    {
      status: 210,
      sku: 'PM0078209',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349995',
      rtl_batch_array: '6349995',
      name_search: 'DICSHEAR',
      name: "Dicentra 'Sulphur Hearts'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VHRSVLC3;TPCGZV26',
      statusContent: 'dot',
      hash: '27WBRTPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27WBRTPA'
    },
    {
      status: 210,
      sku: 'PM0078241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350277',
      rtl_batch_array: '6350277',
      name_search: 'HEADWARF',
      name: "Helenium 'Amber Dwarf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERPLY1VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERPLY1VB'
    },
    {
      status: 210,
      sku: 'PM0070075',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220395',
      rtl_batch_array: '6220395',
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 3265,
      chnn_stock_retail: 3265,
      sppl_prcp: 3.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYVL3TAR;96HC75LG;J1CYKELX;6C5G3SPX;Z3HVWB3X;ZAG3GRCD;DEG14R1N',
      statusContent: 'dot',
      hash: 'NV8Z6YG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV8Z6YG6'
    },
    {
      status: 210,
      sku: 'PM0064922',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300224',
      rtl_batch_array: '6300224, 6267729',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1186,
      chnn_stock_retail: 1536,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: '6TLNT64Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TLNT64Z'
    },
    {
      status: 210,
      sku: 'PM0069180',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234441',
      rtl_batch_array: '6234441',
      name_search: 'PHLFPURP',
      name: 'Phlox (P) Famous Purple',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YLKDYCB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLKDYCB7'
    },
    {
      status: 210,
      sku: 'PM0070084',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6341916',
      rtl_batch_array: '5356496, 6336328, 6341916',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 298,
      chnn_stock_retail: 4199,
      sppl_prcp: 4.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: '4A25DRYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4A25DRYP'
    },
    {
      status: 210,
      sku: 'PM0078247',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350295',
      rtl_batch_array: '6350295',
      name_search: 'HEKOKARD',
      name: "Helenium 'Kokarde'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8BV4DJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8BV4DJL'
    },
    {
      status: 210,
      sku: 'PM0078250',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350308',
      rtl_batch_array: '6350308',
      name_search: 'HEWONADO',
      name: "Helenium 'Wonadonga'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 221,
      chnn_stock_retail: 221,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1729BLV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1729BLV6'
    },
    {
      status: 210,
      sku: 'PM0013918',
      core_name: 'Plant',
      sppl_ean: '8720349411917',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014698',
      rtl_batch_array: '6084850, 6014698',
      name_search: 'CAMVICE',
      name: 'Carex morrowii Vanilla Ice',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1606,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XT82J9G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT82J9G9'
    },
    {
      status: 210,
      sku: 'PM0081686',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333267',
      rtl_batch_array: '6333267',
      name_search: 'IRISCGOL',
      name: "Iris (P) 'Cache of Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRCLAXRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRCLAXRS'
    },
    {
      status: 210,
      sku: 'PM0047807',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306604',
      rtl_batch_array: '6306604',
      name_search: 'LIASALBA',
      name: "Liatris spicata 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1575,
      chnn_stock_retail: 1575,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S2BV65NK;LAVXBN3X',
      statusContent: 'dot',
      hash: 'KBYNJNWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBYNJNWS'
    },
    {
      status: 810,
      sku: 'PM0078253',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350319',
      rtl_batch_array: '6350319',
      name_search: 'HEHDAYS',
      name: "Helianthus 'Happy Days'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A2WEB5AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2WEB5AB'
    },
    {
      status: 210,
      sku: 'PM0063624',
      core_name: 'Plant',
      sppl_ean: '8720664852594',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147702',
      rtl_batch_array: '6147702',
      name_search: 'ASAEPURP',
      name: "Aster ageratoides 'Eleven Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T7E9EZD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7E9EZD3'
    },
    {
      status: 210,
      sku: 'PM0064808',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177556',
      rtl_batch_array: '6177556, 5837500, 6254828',
      name_search: 'SANMAINA',
      name: "Salvia nemorosa 'Mainacht'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 933,
      chnn_stock_retail: 4314,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6PB4STH;R7P83E3T',
      statusContent: 'dot',
      hash: 'X56J78ZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X56J78ZG'
    },
    {
      status: 210,
      sku: 'PM0078210',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321909',
      rtl_batch_array: '6349998, 6321909',
      name_search: 'DIPULCHE',
      name: 'Dierama pulcherrimum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 1444,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1SE5ACBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SE5ACBE'
    },
    {
      status: 910,
      sku: 'PM0074279',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VEVCHALL',
      name: "Veronicastrum virginicum 'Challenger'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'VHNHTVZD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VHNHTVZD'
    },
    {
      status: 210,
      sku: 'PM0078212',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350006',
      rtl_batch_array: '6350006',
      name_search: 'DRRUYSCH',
      name: 'Dracocephalum ruyschiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z7JS6ZH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7JS6ZH2'
    },
    {
      status: 210,
      sku: 'PM0078215',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350084',
      rtl_batch_array: '6350084',
      name_search: 'ECSBELLE',
      name: "Echinacea 'Southern Belle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 693,
      chnn_stock_retail: 693,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '98LGV8W6;5ES2HC2X',
      statusContent: 'dot',
      hash: 'JBCL1TRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBCL1TRH'
    },
    {
      status: 210,
      sku: 'PM0058534',
      core_name: 'Plant',
      sppl_ean: '8720664877931',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112898',
      rtl_batch_array: '6112898',
      name_search: 'PHFPETTI',
      name: "Phlox (P) 'Fairy's Petticoat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GGZ38276',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGZ38276'
    },
    {
      status: 210,
      sku: 'PM0065025',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177961',
      rtl_batch_array: '6242960, 6350353, 6177961',
      name_search: 'HEGSHEPH',
      name: "Hemerocallis 'Gentle Shepherd'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2891,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EPJAJVYD;JC5V7JG8;ZJRRRG6N;HNKALR81',
      statusContent: 'dot',
      hash: '1DN5L426',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DN5L426'
    },
    {
      status: 210,
      sku: 'PM0078213',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350016',
      rtl_batch_array: '6350016, 6302474',
      name_search: 'ECANGUST',
      name: 'Echinacea angustifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T1L4B1TA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1L4B1TA'
    },
    {
      status: 210,
      sku: 'PM0064928',
      core_name: 'Plant',
      sppl_ean: '8720664880368',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6157601',
      rtl_batch_array: '6157601',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: 'PC9Y14TE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PC9Y14TE'
    },
    {
      status: 210,
      sku: 'PM0063649',
      core_name: 'Plant',
      sppl_ean: '8720664873551',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147788',
      rtl_batch_array: '6147788',
      name_search: 'LUSSFLAI',
      name: "Luzula sylvatica 'Solar Flair'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1017,
      chnn_stock_retail: 1017,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N7L3916L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7L3916L'
    },
    {
      status: 210,
      sku: 'PM0078217',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350107',
      rtl_batch_array: '6350107',
      name_search: 'ECARAINB',
      name: 'Echinacea Apricot Rainbow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDAH2SET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDAH2SET'
    },
    {
      status: 210,
      sku: 'PM0078205',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350054',
      rtl_batch_array: '6350054',
      name_search: 'ECPKMHEA',
      name: "Echinacea purpurea 'Kim's Mop Head'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '88ZPAGEW;68S41R1L;9HW2PTED;EADBR1CS',
      statusContent: 'dot',
      hash: 'NLXD4L53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLXD4L53'
    },
    {
      status: 210,
      sku: 'PM0069181',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234442',
      rtl_batch_array: '6234442',
      name_search: 'PHFWEYE',
      name: 'Phlox (P) Famous White Eye',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TBVHTLDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBVHTLDD'
    },
    {
      status: 910,
      sku: 'PM0058524',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHBENITA',
      name: "Phlox (S) 'Benita'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'BB5DALE6;RPZJA1TE;B59T24CV;GVJSJLGN;GBX8YXEV;BEAV9D7C;6NBVECWT;NLJALW3J;PZ2KJE31;16ZT1JYY;63HZ6NY5;2KDXR88H;6WHPV146;H7WZJ432;ARNR334P',
      statusContent: 'dot',
      hash: '5T19SKXD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5T19SKXD'
    },
    {
      status: 210,
      sku: 'PM0078214',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350083',
      rtl_batch_array: '6350083, 6302490',
      name_search: 'ECSAORAN',
      name: 'Echinacea Sombrero Adobe Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 572,
      chnn_stock_retail: 604,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JKJEKZTL;13DE1GRN;YS6AJHRA;A5B1XYJY;ZWP2JHSC;YCVBADGD;JNBA73WN',
      statusContent: 'dot',
      hash: 'WK9DSX3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WK9DSX3X'
    },
    {
      status: 210,
      sku: 'PM0058500',
      core_name: 'Plant',
      sppl_ean: '8720664877498',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112852',
      rtl_batch_array: '6112852',
      name_search: 'PEVACCIN',
      name: 'Persicaria vacciniifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '774X129C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '774X129C'
    },
    {
      status: 810,
      sku: 'PM0047263',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280865',
      rtl_batch_array: '6280865, 6287326',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 64,
      sppl_prcp: 24.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: '97HK5PYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97HK5PYN'
    },
    {
      status: 210,
      sku: 'PM0054741',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349155',
      rtl_batch_array: '6080794, 6349155, 6370399, 6164440',
      name_search: 'VECALBUM',
      name: "Verbascum chaixii 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 437,
      chnn_stock_retail: 1225,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94C7T2B7',
      statusContent: 'dot',
      hash: 'P6E4ET15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6E4ET15'
    },
    {
      status: 210,
      sku: 'PM0063647',
      core_name: 'Plant',
      sppl_ean: '8720664871861',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147782',
      rtl_batch_array: '6147782',
      name_search: 'LATHURIN',
      name: 'Lavatera thuringiaca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AV1E4JSJ',
      statusContent: 'dot',
      hash: 'V7D2675B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7D2675B'
    },
    {
      status: 810,
      sku: 'PM0071045',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254730',
      rtl_batch_array: '6254730',
      name_search: 'GECCAMBR',
      name: "Geranium cantabrigiense 'Cambridge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 33,
      chnn_stock_retail: 33,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '57XYWWA3;T583W45B;8LD5V18W',
      statusContent: 'dot',
      hash: 'ZJVSVDAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJVSVDAK'
    },
    {
      status: 210,
      sku: 'PM0078216',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308551',
      rtl_batch_array: '6350092, 6302491, 6308551',
      name_search: 'ECSRAINB',
      name: 'Echinacea Sunseekers Rainbow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 616,
      chnn_stock_retail: 1362,
      sppl_order_minimum: 3,
      sppl_prcp: 1.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXSAZ1J7;XHNXH4LH;Y5XXW2TX',
      statusContent: 'dot',
      hash: 'G97EJ8YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G97EJ8YC'
    },
    {
      status: 810,
      sku: 'PM0069182',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252732',
      rtl_batch_array: '6252732',
      name_search: 'PLGABLUE',
      name: "Platycodon grandiflorus 'Astra Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AT13JB1J;AD2JE96R;TBAL85JV;VWY3TG9G;N4AV2DZ6;GC2RCA54',
      statusContent: 'dot',
      hash: 'Y5NNWH41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5NNWH41'
    },
    {
      status: 210,
      sku: 'PM0078218',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350116',
      rtl_batch_array: '6350116',
      name_search: 'EPAALBUM',
      name: "Epilobium angustifolium 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGE175RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGE175RB'
    },
    {
      status: 210,
      sku: 'PM0078219',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350137',
      rtl_batch_array: '6350137',
      name_search: 'ERQUARKE',
      name: "Erigeron 'Quarkeress'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GNXEB2HS',
      statusContent: 'dot',
      hash: '1NVY9KJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NVY9KJR'
    },
    {
      status: 210,
      sku: 'PM0078220',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350146',
      rtl_batch_array: '6350146',
      name_search: 'ERVARIIF',
      name: 'Eryngium variifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 911,
      chnn_stock_retail: 911,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TGAZYXD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGAZYXD1'
    },
    {
      status: 210,
      sku: 'PM0064955',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6205776',
      rtl_batch_array: '6174112, 6205776, 6308568, 6340023',
      name_search: 'EUAROBBI',
      name: 'Euphorbia amygdaloides robbiae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 6726,
      sppl_order_minimum: 3,
      sppl_prcp: 0.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DKWRACE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DKWRACE'
    },
    {
      status: 810,
      sku: 'PM0078221',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317852',
      rtl_batch_array: '6350192, 6317852',
      name_search: 'GEBCLOUD',
      name: "Geranium 'Blue Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 454,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JP6R63T5',
      statusContent: 'dot',
      hash: 'HR7N68PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR7N68PA'
    },
    {
      status: 210,
      sku: 'PM0078223',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350203',
      rtl_batch_array: '6350203, 6352634',
      name_search: 'GEHVELVE',
      name: "Geranium 'Hexham Velvet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 202,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z13R57W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z13R57W9'
    },
    {
      status: 810,
      sku: 'PM0078227',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355361',
      rtl_batch_array: '6355361',
      name_search: 'GELICE',
      name: "Geranium 'Lilac Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G3ESK69E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3ESK69E'
    },
    {
      status: 210,
      sku: 'PM0078238',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308579',
      rtl_batch_array: '6350251, 6308579',
      name_search: 'GESSURPR',
      name: "Geranium 'Sylvia's Surprise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 548,
      chnn_stock_retail: 1258,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VHJTVV1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHJTVV1R'
    },
    {
      status: 910,
      sku: 'PM0078222',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GECKBLUE',
      name: "Geranium clarkei 'Kashmir Blue'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'KZ5PYL99',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KZ5PYL99'
    },
    {
      status: 210,
      sku: 'PM0078224',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350206',
      rtl_batch_array: '6350206',
      name_search: 'GEHIBLUE',
      name: "Geranium himalayense 'Irish Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 839,
      chnn_stock_retail: 839,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELTS5927',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELTS5927'
    },
    {
      status: 810,
      sku: 'PM0078225',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350207',
      rtl_batch_array: '6350207',
      name_search: 'GEHKAYA',
      name: 'Geranium himalayense Kaya',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1WYSJZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1WYSJZG'
    },
    {
      status: 210,
      sku: 'PM0063593',
      core_name: 'Plant',
      sppl_ean: '8720664864795',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147633',
      rtl_batch_array: '6147633',
      name_search: 'GEOKADEL',
      name: "Geranium oxonianum 'Katherine Adele'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 266,
      chnn_stock_retail: 266,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7P2W2K88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P2W2K88'
    },
    {
      status: 210,
      sku: 'PM0078229',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350230',
      rtl_batch_array: '6350230',
      name_search: 'GEPWALKU',
      name: "Geranium phaeum 'Walk\u00fcre'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XX4TPVZA',
      statusContent: 'dot',
      hash: 'NSYWEXHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSYWEXHD'
    },
    {
      status: 210,
      sku: 'PM0078230',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350232',
      rtl_batch_array: '6350232, 6352698',
      name_search: 'GEPBWARM',
      name: "Geranium pratense 'Black 'n White Army'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T4GVCXBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4GVCXBX'
    },
    {
      status: 210,
      sku: 'PM0078232',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350234',
      rtl_batch_array: '6350234',
      name_search: 'GEPGALAC',
      name: "Geranium pratense 'Galactic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WHJJ3V2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHJJ3V2N'
    },
    {
      status: 210,
      sku: 'PM0078237',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350247',
      rtl_batch_array: '6350247',
      name_search: 'GESHANNE',
      name: "Geranium sanguineum 'Hannelore'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4LB7RJ4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LB7RJ4P'
    },
    {
      status: 210,
      sku: 'PM0078239',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350254',
      rtl_batch_array: '6350254, 6380813',
      name_search: 'GEWBVARI',
      name: "Geranium wallichianum 'Buxton's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 937,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LS7AP5TG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LS7AP5TG'
    },
    {
      status: 210,
      sku: 'PM0078240',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308475',
      rtl_batch_array: '6350255, 6302545, 6308475',
      name_search: 'GEWLASSO',
      name: 'Geranium wlassovianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 641,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XWCYCDYR',
      statusContent: 'dot',
      hash: 'X27V8WX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X27V8WX6'
    },
    {
      status: 210,
      sku: 'PM0078242',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350282',
      rtl_batch_array: '6350282',
      name_search: 'HEDAUERB',
      name: "Helenium 'Dauerbrenner'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2V5CAXGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V5CAXGT'
    },
    {
      status: 210,
      sku: 'PM0078243',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350286',
      rtl_batch_array: '6350286',
      name_search: 'HEFFAN',
      name: "Helenium 'Fancy Fan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JVESV2VL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVESV2VL'
    },
    {
      status: 210,
      sku: 'PM0078244',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350288',
      rtl_batch_array: '6350288, 6302552',
      name_search: 'HEFKAETH',
      name: "Helenium 'Flammendes K\u00e4thchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 449,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DLJGBBXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLJGBBXA'
    },
    {
      status: 210,
      sku: 'PM0078245',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350289',
      rtl_batch_array: '6350289',
      name_search: 'HEFLAMME',
      name: "Helenium 'Flammenrad'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 124,
      chnn_stock_retail: 124,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C9JYLN9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9JYLN9K'
    },
    {
      status: 210,
      sku: 'PM0078246',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350290',
      rtl_batch_array: '6350290',
      name_search: 'HEFUEGO',
      name: "Helenium 'Fuego'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZCJNK4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZCJNK4T'
    },
    {
      status: 210,
      sku: 'PM0078249',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302559',
      rtl_batch_array: '6350304, 6302559',
      name_search: 'HEROTGOL',
      name: "Helenium 'Rotgold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 362,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPH4GTV8;KZVD8WG6',
      statusContent: 'dot',
      hash: 'EK5TXE56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EK5TXE56'
    },
    {
      status: 210,
      sku: 'PM0078248',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6319705',
      rtl_batch_array: '6350300, 6319705, 6348988',
      name_search: 'HEMGRAS',
      name: 'Helenium Mardi Gras',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 182,
      chnn_stock_retail: 1364,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GA58P776;85J7BYX6',
      statusContent: 'dot',
      hash: '2A2XYXLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A2XYXLV'
    },
    {
      status: 210,
      sku: 'PM0078251',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301632',
      rtl_batch_array: '6350311, 6301632',
      name_search: 'HEATRORU',
      name: 'Helianthus atrorubens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 524,
      chnn_stock_retail: 614,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SSZY7CLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSZY7CLZ'
    },
    {
      status: 210,
      sku: 'PM0078252',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350318',
      rtl_batch_array: '6350318',
      name_search: 'HEGSSUNS',
      name: "Helianthus giganteus 'Shelia's Sunshine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 563,
      chnn_stock_retail: 563,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AXP4XNAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXP4XNAD'
    },
    {
      status: 810,
      sku: 'PM0078255',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350329',
      rtl_batch_array: '6350329',
      name_search: 'HEHLSUNS',
      name: 'Heliopsis helianthoides Loraine Sunshine',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G328GG42;61RA42VV;W2PGXG7A;C34Y3HZJ;V47DT58B;C2BD928A',
      statusContent: 'dot',
      hash: '5HX4B7SP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HX4B7SP'
    },
    {
      status: 910,
      sku: 'PM0058539',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHLMPEAR',
      name: "Phlox 'Minnie Pearl'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WSGJH191',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WSGJH191'
    },
    {
      status: 210,
      sku: 'PM0065042',
      core_name: 'Plant',
      sppl_ean: '8720664885417',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164424',
      rtl_batch_array: '6164424, 6340046',
      name_search: 'SCCALBA',
      name: "Scabiosa caucasica 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 171,
      chnn_stock_retail: 1038,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CH9AYKA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH9AYKA7'
    },
    {
      status: 210,
      sku: 'PM0064806',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6197044',
      rtl_batch_array: '6197044, 5837426, 6272819',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1082,
      chnn_stock_retail: 4453,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: 'YL7YNA9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YL7YNA9V'
    },
    {
      status: 210,
      sku: 'PM0085236',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373128',
      rtl_batch_array: '6373128',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 961,
      chnn_stock_retail: 961,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'KVE5CS8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVE5CS8A'
    },
    {
      status: 910,
      sku: 'PM0058491',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEWSTAR',
      name: "Penstemon 'Winter Star'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '9J5X16PT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9J5X16PT'
    },
    {
      status: 210,
      sku: 'PM0054749',
      core_name: 'Plant',
      sppl_ean: '8720664851467',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083893',
      rtl_batch_array: '6083893',
      name_search: 'ALSRSTAR',
      name: "Allium schoenoprasum 'Rising Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 492,
      chnn_stock_retail: 492,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NBVEA2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NBVEA2Z'
    },
    {
      status: 210,
      sku: 'PM0078256',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350330',
      rtl_batch_array: '6350330',
      name_search: 'HEHSPITZ',
      name: "Heliopsis helianthoides 'Spitzent\u00e4nzerin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '85G95XKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85G95XKE'
    },
    {
      status: 210,
      sku: 'PM0069193',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305232',
      rtl_batch_array: '6305232, 6254847',
      name_search: 'STBYZANT',
      name: 'Stachys byzantina',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2406,
      chnn_stock_retail: 3733,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1CV8LTLG;TBP1W3JB;PHYN521K;WDCB8LY9;KPCCBH3P;RAJY27N3;64LP9ZBS;28RBC2XV;LBL5V6SD;AHNGGL28;XZ8K5AHG;GB2YHYLE;P32TXN73;KSSS28D1;HN5RBGET;9RR28RE3;J2SZ2T3S;SSYWVXGB;BNHC6KSB;ZJNDGAES;NYHPYAVJ',
      statusContent: 'dot',
      hash: '1R4SXHSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R4SXHSY'
    },
    {
      status: 810,
      sku: 'PM0065104',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350001',
      rtl_batch_array: '6350001',
      name_search: 'DIPARVIF',
      name: 'Digitalis parviflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2YVTY184',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YVTY184'
    },
    {
      status: 210,
      sku: 'PM0068607',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308463',
      rtl_batch_array: '6254366, 6308463, 6291688',
      name_search: 'CHNTRENE',
      name: "Chamaemelum nobile 'Treneague'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2017,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PZR3BCEZ;1YLZ5NX5;87L48XYZ;J2TSA468;ZDT1WA4Y;YV6G35VT;4VTPB378;ZG5HJR7V;A5L62Y2W;HL6KKBB4;BTB3T75A',
      statusContent: 'dot',
      hash: '48ER2GVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48ER2GVZ'
    },
    {
      status: 810,
      sku: 'PM0078257',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350332',
      rtl_batch_array: '6350332',
      name_search: 'HEHSPINK',
      name: "Heliopsis helianthoides Summer Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GE4X69J9;XTBR8PW9',
      statusContent: 'dot',
      hash: 'H71L53V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H71L53V5'
    },
    {
      status: 910,
      sku: 'PM0078258',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHVENUS',
      name: "Heliopsis helianthoides 'Venus'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'YVXZ28LZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YVXZ28LZ'
    },
    {
      status: 210,
      sku: 'PM0085237',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373129',
      rtl_batch_array: '6373129',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1343,
      chnn_stock_retail: 1343,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'GYW8C6R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYW8C6R5'
    },
    {
      status: 210,
      sku: 'PM0074281',
      core_name: 'Plant',
      sppl_ean: '8720349405039',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '4638872',
      rtl_batch_array: '4638872, 6306979',
      name_search: 'HAMAGOLD',
      name: "Hakonechloa macra 'All Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3892,
      chnn_stock_retail: 4380,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X1LDXZPZ;2GAPK5AR;2VS21H7H;879VJCCL;L77ZYAPY;EZ31HP75;P57NRDLS;EGKHP86K;HNAC44DK;2XL93WZY;A6ZRAXCG;R9T9K2HW;RH5T7XSX;E49BXR4P',
      imageBatch: 'EZV9BXHT',
      statusContent: 'dot',
      hash: 'GLXDCD93',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLXDCD93'
    },
    {
      status: 210,
      sku: 'PM0078259',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349868',
      rtl_batch_array: '6349868',
      name_search: 'HENCLOVE',
      name: "Helleborus nigercors 'Candy Love'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 3,
      sppl_prcp: 3.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNSX9S1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNSX9S1C'
    },
    {
      status: 210,
      sku: 'PM0078260',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363181',
      rtl_batch_array: '6350544, 6363181',
      name_search: 'HYOLYMPI',
      name: 'Hypericum olympicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8688,
      chnn_stock_retail: 9648,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18A8VNBT;18DVZARW;A2ZVSJLL',
      statusContent: 'dot',
      hash: 'DJ7BTK3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJ7BTK3V'
    },
    {
      status: 210,
      sku: 'PM0085238',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373130',
      rtl_batch_array: '6373130',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 3298,
      chnn_stock_retail: 3298,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: '4YR9BBC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YR9BBC1'
    },
    {
      status: 810,
      sku: 'PM0065096',
      core_name: 'Plant',
      sppl_ean: '8720664858558',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164392',
      rtl_batch_array: '6164392',
      name_search: 'COFPLEAF',
      name: "Corydalis flexuosa 'Purple Leaf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_order_minimum: 3,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PRGVEY53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRGVEY53'
    },
    {
      status: 810,
      sku: 'PM0081594',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341901',
      rtl_batch_array: '6341901',
      name_search: 'CARAPHRO',
      name: "Calycanthus raulstonii 'Aphrodite'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_prcp: 5.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZC2SCW7H;P11RJR6W;8NX69JZA;1GG8TDHY;ZGCJWP74;HJEB4RCY;D6C5KYY8;R1LXYW5S;88HH4VTZ;4WAYSPZ8;EJVVSPDH;LYC3X4ZS',
      statusContent: 'dot',
      hash: 'KLBLG93A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLBLG93A'
    },
    {
      status: 210,
      sku: 'PM0078261',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350552',
      rtl_batch_array: '6350552',
      name_search: 'IMARGUTA',
      name: 'Impatiens arguta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WNGRTNT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNGRTNT3'
    },
    {
      status: 210,
      sku: 'PM0069187',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252760',
      rtl_batch_array: '6252760',
      name_search: 'SABRESSI',
      name: "Saponaria 'Bressingham'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SCCNSD85;TWKNBE6K;ERTAG42N;9KPX2TR1;B7YXPP7A;JGSXB7Z8;4HACVNSB;98TLE15E;KBY8H7JH;2D9ABP9R;4VERY84J;1JE9JL2L',
      statusContent: 'dot',
      hash: 'HPEBGCS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPEBGCS1'
    },
    {
      status: 210,
      sku: 'PM0085239',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373131',
      rtl_batch_array: '6373131',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1141,
      chnn_stock_retail: 1141,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'RSVAXTY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSVAXTY9'
    },
    {
      status: 210,
      sku: 'PM0058572',
      core_name: 'Plant',
      sppl_ean: '8720664865099',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6113102',
      rtl_batch_array: '6295618, 6113102, 6276032',
      name_search: 'GESMAYFL',
      name: "Geranium sylvaticum 'Mayflower'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 5134,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L56NEW3J;CWS1PP3Z;TH17XXNA;WGSJJVYY;ASDJRHG6',
      statusContent: 'dot',
      hash: 'NBZD9JNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBZD9JNT'
    },
    {
      status: 210,
      sku: 'PM0064799',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301627',
      rtl_batch_array: '6301627, 6176453',
      name_search: 'HEBIEDER',
      name: "Helenium 'Biedermeier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 160,
      chnn_stock_retail: 554,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '85BYXVBR;4SLKETXW',
      statusContent: 'dot',
      hash: 'DJACEP3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJACEP3Y'
    },
    {
      status: 210,
      sku: 'PM0071070',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308575',
      rtl_batch_array: '6308575, 6295598',
      name_search: 'GEDREAML',
      name: 'Geranium Dreamland',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5099,
      chnn_stock_retail: 6931,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AH8KDCSD;LJH1XBT9',
      statusContent: 'dot',
      hash: 'LW3BXJZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LW3BXJZN'
    },
    {
      status: 210,
      sku: 'PM0054704',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308477',
      rtl_batch_array: '6177413, 6308477',
      name_search: 'HEAPLENU',
      name: "Helianthemum 'Amabile Plenum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 765,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X3C75Y6A;VJWXARDY',
      statusContent: 'dot',
      hash: 'XH72P12R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH72P12R'
    },
    {
      status: 210,
      sku: 'PM0085240',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373132',
      rtl_batch_array: '6373132',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 3361,
      chnn_stock_retail: 3361,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'PATABZX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PATABZX6'
    },
    {
      status: 210,
      sku: 'PM0027231',
      core_name: 'Plant',
      sppl_ean: '8720664865471',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084950',
      rtl_batch_array: '6084950, 6207999',
      name_search: 'GYRFROSE',
      name: "Gypsophila repens 'Filou Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 319,
      chnn_stock_retail: 1442,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZC9JDL4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZC9JDL4K'
    },
    {
      status: 210,
      sku: 'PM0069185',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254825',
      rtl_batch_array: '6254825',
      name_search: 'ARAVARIE',
      name: "Arachniodes aristata 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5YPBA998',
      statusContent: 'dot',
      hash: 'SV9T3Z8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SV9T3Z8Z'
    },
    {
      status: 210,
      sku: 'PM0071068',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 4,
      btch_active_retail: '6356251',
      rtl_batch_array: '6350659, 6356251, 6287683, 6338946',
      name_search: 'LIMMWHIT',
      name: "Liriope muscari 'Monroe White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 976,
      chnn_stock_retail: 4364,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AYLD59KE;4NY9BBP2;28G2AKK8;D4B3XNSA;T4SJCPL6;KVVDLPV1;HGER8DSK;DV6AZ3ST;XED3AWGH;P4RDERDW;VETGZ9Y7',
      statusContent: 'dot',
      hash: '618TGDSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '618TGDSX'
    },
    {
      status: 210,
      sku: 'PM0081595',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341903',
      rtl_batch_array: '6341903',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1606,
      chnn_stock_retail: 1606,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'EV215XBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EV215XBZ'
    },
    {
      status: 910,
      sku: 'PM0081596',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYAPANNA',
      name: 'Hydrangea arborescens Pink Annabelle',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      statusContent: 'dot',
      hash: 'ZAT3EC4J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZAT3EC4J'
    },
    {
      status: 210,
      sku: 'PM0068591',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217136',
      rtl_batch_array: '6217136',
      name_search: 'EUCYPARI',
      name: 'Euphorbia cyparissias',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZJ2EJ5E',
      statusContent: 'dot',
      hash: 'LVWTB739',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVWTB739'
    },
    {
      status: 210,
      sku: 'PM0065081',
      core_name: 'Plant',
      sppl_ean: '8720664853515',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161577',
      rtl_batch_array: '6161577',
      name_search: 'BEBIEDER',
      name: "Bergenia 'Biedermeier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YER59CRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YER59CRV'
    },
    {
      status: 210,
      sku: 'PM0081597',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341906',
      rtl_batch_array: '6341906',
      name_search: 'BUMGKNIG',
      name: "Buddleja d. Magda's Gold Knight",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52A4N8T5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52A4N8T5'
    },
    {
      status: 210,
      sku: 'PM0078285',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308232',
      rtl_batch_array: '6308232',
      name_search: 'MISROTSI',
      name: "Miscanthus sinensis 'Rotsilber'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 820,
      chnn_stock_retail: 820,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2XC5L8JE;JEDYS2GK;X5DTHE6L',
      statusContent: 'dot',
      hash: 'HTRZPN4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTRZPN4S'
    },
    {
      status: 210,
      sku: 'PM0081598',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341907',
      rtl_batch_array: '6341907',
      name_search: 'HYMBELA',
      name: "Hydrangea macrophylla 'Bela'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 2.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RW86Z1V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW86Z1V9'
    },
    {
      status: 810,
      sku: 'PM0081599',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341908',
      rtl_batch_array: '6341908',
      name_search: 'HYMHOERN',
      name: "Hydrangea macrophylla 'H\u00f6rnli'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 2.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LAG2DBTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAG2DBTR'
    },
    {
      status: 210,
      sku: 'PM0069190',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252766',
      rtl_batch_array: '6252766',
      name_search: 'SEBANDER',
      name: "Sedum 'Bertram Anderson'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S3LHT6HS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3LHT6HS'
    },
    {
      status: 210,
      sku: 'PM0069194',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234505',
      rtl_batch_array: '6234505',
      name_search: 'STTENUIF',
      name: 'Stipa tenuifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4152,
      chnn_stock_retail: 4152,
      sppl_prcp: 1.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NZDLLB68;SRXG6NV9;6GAWPACH;AD9XJK64;ZSEZBKVA;TZJYJ4XY;BXHRBANE;ZXEELT41;2SPTW1XC;5YKV3XXR;GEJDACX8;GXY8YAP7;TGD4SDVP',
      statusContent: 'dot',
      hash: '55HREC7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55HREC7X'
    },
    {
      status: 210,
      sku: 'PM0081601',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 4,
      btch_active_retail: '6373412',
      rtl_batch_array: '6373412, 6356040, 6383862, 6341910',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 590,
      chnn_stock_retail: 2740,
      sppl_prcp: 4.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'Y4T97AV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4T97AV1'
    },
    {
      status: 210,
      sku: 'PM0081602',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341912',
      rtl_batch_array: '6341912',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'RJY97S99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJY97S99'
    },
    {
      status: 210,
      sku: 'PM0078270',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350619',
      rtl_batch_array: '6350619',
      name_search: 'LAGKDWAR',
      name: "Lamiastrum galeobdolon 'Kirkcudbright Dwarf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8XRRNBTJ',
      statusContent: 'dot',
      hash: 'H12PXRY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H12PXRY4'
    },
    {
      status: 910,
      sku: 'PM0081603',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341915',
      rtl_batch_array: '6341915',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 15.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: '2CP2PG35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CP2PG35'
    },
    {
      status: 210,
      sku: 'PM0081604',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341918',
      rtl_batch_array: '6341918',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 5.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: '8WCEPR1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WCEPR1E'
    },
    {
      status: 210,
      sku: 'PM0081605',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341919',
      rtl_batch_array: '6341919',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 313,
      chnn_stock_retail: 313,
      sppl_prcp: 2.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: 'ZG19WVZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG19WVZG'
    },
    {
      status: 910,
      sku: 'PM0070086',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239637',
      rtl_batch_array: '6239637',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 23.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'CY9D99CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CY9D99CJ'
    },
    {
      status: 210,
      sku: 'PM0081687',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333278',
      rtl_batch_array: '6333278',
      name_search: 'MESPICAT',
      name: 'Mentha spicata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1550,
      chnn_stock_retail: 1550,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NTKT5TJD;NX8RANEK;PD7Z6DA3;GC73HDW2;8PL8PSL3;DCZSZCZK;RT78RYEB;Y5CPSLSL;2J5WL4S5;JT574TK7;4AVL82CG;12DNC19H;AXRNEJ36;7V4986ZA;J4D8CBKV;5RCVYBEY;9ZV5GAHT;51PJB8BR;2HT2R117;Z21TRVV9;1CKH42VW',
      statusContent: 'dot',
      hash: 'K1D574PP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1D574PP'
    },
    {
      status: 210,
      sku: 'PM0071072',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6216276',
      rtl_batch_array: '6216276',
      name_search: 'HEMPPURP',
      name: "Heuchera micrantha 'Palace Purple'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JVG47C77;8RAZ4NEY;7CGSBYGW;ZCB25BW5;6SXB6EWG;CV5JXRTV;VENPDWKV;93HG2PVG;PC4EZA6V;4NE1SRNW;DHNBLKSB;PW83K5DX;WDLJVDJ5;7EZ42RDX',
      statusContent: 'dot',
      hash: 'YXH4JSZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXH4JSZV'
    },
    {
      status: 210,
      sku: 'PM0065103',
      core_name: 'Plant',
      sppl_ean: '8720664859951',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161641',
      rtl_batch_array: '6161641',
      name_search: 'DIFBACCH',
      name: "Dicentra formosa 'Bacchanal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 222,
      chnn_stock_retail: 222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7VC9GR5P',
      statusContent: 'dot',
      hash: 'EKBWBN3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKBWBN3T'
    },
    {
      status: 210,
      sku: 'PM0058573',
      core_name: 'Plant',
      sppl_ean: '8720664864412',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6113105',
      rtl_batch_array: '6349090, 6113105, 6377872',
      name_search: 'GESIRAK',
      name: "Geranium 'Sirak'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 6022,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PAJBYA8R;RJ5DDC7G;YLE75N34',
      statusContent: 'dot',
      hash: 'E2GRKPVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2GRKPVW'
    },
    {
      status: 210,
      sku: 'PM0066633',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373852',
      rtl_batch_array: '6228443, 6373852',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2360,
      chnn_stock_retail: 3179,
      sppl_prcp: 1.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'GLBXLWY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLBXLWY2'
    },
    {
      status: 810,
      sku: 'PM0068597',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197036',
      rtl_batch_array: '6197036, 6252725',
      name_search: 'PEADLOWN',
      name: "Persicaria affinis 'Donald Lowndes'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 64,
      chnn_stock_retail: 1047,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3BBAXJJ',
      statusContent: 'dot',
      hash: 'VYR3TRYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYR3TRYT'
    },
    {
      status: 210,
      sku: 'PM0081688',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333288',
      rtl_batch_array: '6333288',
      name_search: 'PESAXIFR',
      name: 'Petrorhagia saxifraga',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4344,
      chnn_stock_retail: 4344,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TG1G7YDZ',
      statusContent: 'dot',
      hash: 'S3EZTZB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3EZTZB8'
    },
    {
      status: 210,
      sku: 'PM0081689',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333302',
      rtl_batch_array: '6333302',
      name_search: 'PUVPRSHA',
      name: "Pulsatilla vulgaris 'Pulsar Red Shades'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2916,
      chnn_stock_retail: 2916,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X7DESDAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7DESDAN'
    },
    {
      status: 210,
      sku: 'PM0065072',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386060',
      rtl_batch_array: '6302391, 6386060',
      name_search: 'ASNBVIOL',
      name: "Aster novi-belgii 'Violetta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 295,
      chnn_stock_retail: 461,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKJG735S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKJG735S'
    },
    {
      status: 210,
      sku: 'PM0078276',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349878',
      rtl_batch_array: '6349878',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      statusContent: 'dot',
      hash: 'XDHXZY81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDHXZY81'
    },
    {
      status: 810,
      sku: 'PM0065929',
      core_name: 'Plant',
      sppl_ean: '8720664872967',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169549',
      rtl_batch_array: '6169549',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '200250C30',
      rng_range_identifier: 'H200250C30',
      rng_range_description: 'H200 cm 250 cm C30',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 65.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'H1S2EAJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1S2EAJ5'
    },
    {
      status: 210,
      sku: 'PM0078277',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349880',
      rtl_batch_array: '6349880',
      name_search: 'MISCHINA',
      name: "Miscanthus sinensis 'China'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7G6ZS1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7G6ZS1V'
    },
    {
      status: 210,
      sku: 'PM0078278',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349881',
      rtl_batch_array: '6349881',
      name_search: 'MISDINGR',
      name: "Miscanthus sinensis 'Dronning Ingrid'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '49GVGPC1;PBDYYPRX;5W2H7Y1L;TA1YWBA3;1RW3ABTE',
      statusContent: 'dot',
      hash: 'L753386L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L753386L'
    },
    {
      status: 210,
      sku: 'PM0078279',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308225',
      rtl_batch_array: '6308225',
      name_search: 'MISGHANA',
      name: "Miscanthus sinensis 'Ghana'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 364,
      chnn_stock_retail: 364,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LBHDZD3N;79ZDE76P;XTK51RBH;1A92A6ER;RRGS4DYS;LHXV9RSZ;5CD7ANCN;5PWEN7E9;TGC4SJD5;YKV7W2KZ',
      statusContent: 'dot',
      hash: '9ERGHHWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ERGHHWK'
    },
    {
      status: 210,
      sku: 'PM0070088',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287035',
      rtl_batch_array: '6287035',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 5682,
      chnn_stock_retail: 5682,
      sppl_prcp: 3.285,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'ZET9RKTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZET9RKTN'
    },
    {
      status: 210,
      sku: 'PM0081690',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6340051',
      rtl_batch_array: '6333309, 6340051, 6348994',
      name_search: 'SYOFFICI',
      name: 'Symphytum officinale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1330,
      chnn_stock_retail: 4488,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CNY9LEH;LB79RY85;WAH6RYA6;SATJKPAB;6BRXJGN7',
      statusContent: 'dot',
      hash: 'DWSDTTXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWSDTTXN'
    },
    {
      status: 210,
      sku: 'PM0081691',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333315',
      rtl_batch_array: '6333315',
      name_search: 'VEOLYMPI',
      name: 'Verbascum olympicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2208,
      chnn_stock_retail: 2208,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RHB2D93P;5AEKWD4V',
      statusContent: 'dot',
      hash: 'TNYAYXKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNYAYXKG'
    },
    {
      status: 210,
      sku: 'PM0078280',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308226',
      rtl_batch_array: '6308226',
      name_search: 'MISGFONT',
      name: "Miscanthus sinensis 'Grosse Font\u00e4ne'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 286,
      chnn_stock_retail: 286,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8E7AY83A',
      statusContent: 'dot',
      hash: 'J5JZVZWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5JZVZWH'
    },
    {
      status: 210,
      sku: 'PM0078281',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308227',
      rtl_batch_array: '6308227',
      name_search: 'MISKASKA',
      name: "Miscanthus sinensis 'Kaskade'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHAGADGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHAGADGG'
    },
    {
      status: 210,
      sku: 'PM0065093',
      core_name: 'Plant',
      sppl_ean: '8720664861060',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161618',
      rtl_batch_array: '6161618',
      name_search: 'ECPSGLOW',
      name: "Echinacea purpurea 'Secret Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '87W4ZWW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87W4ZWW2'
    },
    {
      status: 210,
      sku: 'PM0078282',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382319',
      rtl_batch_array: '6350682, 6382319, 6319753',
      name_search: 'MISKFONT',
      name: "Miscanthus sinensis 'Kleine Font\u00e4ne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3500,
      chnn_stock_retail: 5284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPX27258;HHV2A21K',
      statusContent: 'dot',
      hash: '1BBA6P2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BBA6P2H'
    },
    {
      status: 210,
      sku: 'PM0065095',
      core_name: 'Plant',
      sppl_ean: '8720664857919',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161621',
      rtl_batch_array: '6161621',
      name_search: 'COGSUNKI',
      name: 'Coreopsis grandiflora Sunkiss',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZXY75TC;876ZZ3YA;PKAPXDTV',
      statusContent: 'dot',
      hash: 'GR81K4TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GR81K4TL'
    },
    {
      status: 210,
      sku: 'PM0078283',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308229',
      rtl_batch_array: '6308229',
      name_search: 'MISKRATE',
      name: "Miscanthus sinensis 'Krater'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y36LRZ8X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y36LRZ8X'
    },
    {
      status: 210,
      sku: 'PM0065101',
      core_name: 'Plant',
      sppl_ean: '8720664859289',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161631',
      rtl_batch_array: '6161631',
      name_search: 'DESLEMON',
      name: "Delphinium 'Sherbet Lemon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VKK9R7HG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKK9R7HG'
    },
    {
      status: 210,
      sku: 'PM0078284',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308230',
      rtl_batch_array: '6308230',
      name_search: 'MISLZEBR',
      name: "Miscanthus sinensis 'Little Zebra'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 1.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BAH38TT2;TDP9N3BL;GEC3B53L;NE7R6WX4;2AHZS4T1;EL6R5VD1;W65LCK79;1DBC5DWS;67CSE5S5;XSGL1AJS;L3DRCPG9;L8KKHVC4;T48CSEGE;RJX7NYN5;1TJGJKHR;E38CYGAD;G78WEZ1E;9DV7SS41;2PW7E9K7;94Z37G9N;CE5TD7DG;2E3EBBTE;DPB312PR;JT23LDD1;VBC6AEA5;APXLE99T',
      statusContent: 'dot',
      hash: 'Y2TJPL89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2TJPL89'
    },
    {
      status: 210,
      sku: 'PM0078286',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308233',
      rtl_batch_array: '6308233',
      name_search: 'MISSARAB',
      name: "Miscanthus sinensis 'Sarabande'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 594,
      chnn_stock_retail: 594,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7D8SPLTP;XV8763E7',
      statusContent: 'dot',
      hash: 'R1ZL1VEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1ZL1VEP'
    },
    {
      status: 210,
      sku: 'PM0078287',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308236',
      rtl_batch_array: '6308236',
      name_search: 'MISUNDIN',
      name: "Miscanthus sinensis 'Undine'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JZB52GSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZB52GSN'
    },
    {
      status: 210,
      sku: 'PM0068602',
      core_name: 'Plant',
      sppl_ean: '8720349459988',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5964225',
      rtl_batch_array: '5964225, 6234567',
      name_search: 'ARMALBA',
      name: "Armeria maritima 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1514,
      chnn_stock_retail: 2241,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72JY2TSY;D6S5XHHJ;2V3GXWET;9WSWZHVY;5NZZCXNA;5Y2VRYKZ;7TVNDA14;1LSJHST2;SXSAZ2EL;2ARCSCZE;47YAXKYP;26RBD57Z;W6133S5Y',
      statusContent: 'dot',
      hash: 'TRC5PYZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRC5PYZK'
    },
    {
      status: 210,
      sku: 'PM0081692',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333316',
      rtl_batch_array: '6333316',
      name_search: 'VESPICAT',
      name: 'Veronica spicata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4824,
      chnn_stock_retail: 4824,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HA6G6K35;PL7VL9C1;VW19BT1K;BWLT2YPL;VZ32S1J9;SYXTW238;SDNTB1LY;G1ELKW55',
      statusContent: 'dot',
      hash: '7V8HVJH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7V8HVJH2'
    },
    {
      status: 210,
      sku: 'PM0063236',
      core_name: 'Plant',
      sppl_ean: '8720664885080',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164423',
      rtl_batch_array: '6164423, 6242187',
      name_search: 'SAMENZIE',
      name: 'Sanguisorba menziesii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 256,
      chnn_stock_retail: 298,
      sppl_order_minimum: 3,
      sppl_prcp: 1.343,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6CX7JRJD',
      statusContent: 'dot',
      hash: 'SY1ARTZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY1ARTZN'
    },
    {
      status: 210,
      sku: 'PM0071067',
      core_name: 'Plant',
      sppl_ean: '8720349448593',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '5838050',
      rtl_batch_array: '5838050, 6350736, 6270624, 6348396',
      name_search: 'PATGSHEE',
      name: "Pachysandra terminalis 'Green Sheen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4575,
      chnn_stock_retail: 10821,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '539CG3DN;7D85AS1B',
      statusContent: 'dot',
      hash: 'LAHREKKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAHREKKW'
    },
    {
      status: 210,
      sku: 'PM0081693',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333370',
      rtl_batch_array: '6333370',
      name_search: 'CACCBLUE',
      name: "Campanula cochleariifolia 'Cambridge Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7541,
      chnn_stock_retail: 7541,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '98AARH45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98AARH45'
    },
    {
      status: 210,
      sku: 'PM0078289',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350694',
      rtl_batch_array: '6350694',
      name_search: 'MOALPCE',
      name: "Molinia arund. 'Les Ponts de C\u00e9'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AN95XD1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AN95XD1W'
    },
    {
      status: 210,
      sku: 'PM0081694',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333371',
      rtl_batch_array: '6333371',
      name_search: 'KNMRKNIG',
      name: "Knautia macedonica 'Red Knight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2568,
      chnn_stock_retail: 2568,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ASZRW6Y3;Z9551XR8;75Z9TYKX;4R9E6JL2;BBL4L1ES;A3BVKBEZ;YRXNV9NW;2T7TA595;8C1REGLR',
      statusContent: 'dot',
      hash: '2LZ8DTZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LZ8DTZ9'
    },
    {
      status: 210,
      sku: 'PM0078291',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350700',
      rtl_batch_array: '6350700',
      name_search: 'MODONNER',
      name: "Monarda 'Donnerwolke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VD7G4WZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VD7G4WZ'
    },
    {
      status: 210,
      sku: 'PM0081695',
      core_name: 'Plant',
      sppl_ean: '8720626344938',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5448401',
      rtl_batch_array: '5448401, 6348765',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 5300,
      sppl_prcp: 3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'G5LTTZLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5LTTZLZ'
    },
    {
      status: 210,
      sku: 'PM0071064',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207212',
      rtl_batch_array: '5207212',
      name_search: 'CHGOLDFI',
      name: 'Choisya Goldfingers',
      sppl_size_code: '040045C10',
      rng_range_identifier: 'H040045C10',
      rng_range_description: 'H40 cm 45 cm C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E5PBBJ9;TX8TB739;B3L4GZP6',
      statusContent: 'dot',
      hash: 'Z9TK4EKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9TK4EKK'
    },
    {
      status: 210,
      sku: 'PM0078293',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350702',
      rtl_batch_array: '6350702',
      name_search: 'MOREBECC',
      name: "Monarda 'Rebecca'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 134,
      chnn_stock_retail: 134,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DAWETHCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAWETHCV'
    },
    {
      status: 210,
      sku: 'PM0081696',
      core_name: 'Plant',
      sppl_ean: '8720664689145',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486253',
      rtl_batch_array: '5486253, 6348773',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: '010015C1',
      rng_range_identifier: 'H010015C1',
      rng_range_description: 'H10 cm 15 cm C1',
      sppl_stock_available: 5000,
      chnn_stock_retail: 12400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      imageBatch: 'ETLS9YD3',
      statusContent: 'dot',
      hash: '8AZ5ZTD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AZ5ZTD8'
    },
    {
      status: 210,
      sku: 'PM0081697',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291710',
      rtl_batch_array: '6291710',
      name_search: 'LUGWHITE',
      name: "Lupinus 'Gallery White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GPJEK462;ZWAXBG6G;JX3SXV8B;172HHSV3;XEJ653ZE;5ZZBKY1C;2W83DE1C;Y8ECLEY7;GDSYAYWD;EHXET38X;C171PXSY;4PX2VYCE;B6V7YAED;BHJ2NRCJ;PYSXHL9V;1VRS7XDN;CYY2AT57;YJBZLNWW',
      statusContent: 'dot',
      hash: 'YBHJVHZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBHJVHZR'
    },
    {
      status: 810,
      sku: 'PM0065930',
      core_name: 'Plant',
      sppl_ean: '8720664872943',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169550',
      rtl_batch_array: '6169550',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: 'XLB5EZY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLB5EZY3'
    },
    {
      status: 810,
      sku: 'PM0081698',
      core_name: 'Plant',
      sppl_ean: '8720626334007',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495159',
      rtl_batch_array: '5495159',
      name_search: 'ALRPLENI',
      name: "Alcea rosea 'Pleniflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 0.769,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RGWXJ8K5;TAZ1LGGA;JSEBKRK9;LSD3TLPB',
      statusContent: 'dot',
      hash: 'B3BNB1SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3BNB1SK'
    },
    {
      status: 810,
      sku: 'PM0081699',
      core_name: 'Plant',
      sppl_ean: '8720626338227',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495225',
      rtl_batch_array: '5495225',
      name_search: 'CALSENIO',
      name: "Campanula lactiflora 'Senior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G5Y5G63J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5Y5G63J'
    },
    {
      status: 210,
      sku: 'PM0071075',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234444',
      rtl_batch_array: '6234444',
      name_search: 'PHTAMAON',
      name: "Phlox (S) 'Tamaongalei'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GSGLWCG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSGLWCG6'
    },
    {
      status: 210,
      sku: 'PM0081700',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301361',
      rtl_batch_array: '6301361',
      name_search: 'ACFPVARI',
      name: "Achillea filipendulina 'Parker's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 457,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y88SZ2BV;PNTT19ZK;7RS9KTRA;TWTHWD8W',
      statusContent: 'dot',
      hash: 'K4WYT1Z3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4WYT1Z3'
    },
    {
      status: 210,
      sku: 'PM0081701',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301368',
      rtl_batch_array: '6301368',
      name_search: 'ACTOMENT',
      name: 'Achillea tomentosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 347,
      chnn_stock_retail: 347,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6W4TS6PS;SR6TH61N;C848NAJH;957ZHGPJ;59C8A5CB;NYND667Y',
      statusContent: 'dot',
      hash: '6V36WZB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6V36WZB7'
    },
    {
      status: 210,
      sku: 'PM0081702',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301545',
      rtl_batch_array: '6301545',
      name_search: 'DRAPINDE',
      name: "Dryopteris affinis 'Pinderi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 243,
      chnn_stock_retail: 243,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4TSC4H7',
      statusContent: 'dot',
      hash: 'N7HY16R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7HY16R5'
    },
    {
      status: 210,
      sku: 'PM0081703',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349102',
      rtl_batch_array: '6349102, 6350518, 6301663',
      name_search: 'HOPATRIO',
      name: "Hosta 'Patriot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 864,
      chnn_stock_retail: 2774,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LC8R96LY;2JEH4V3Z;TH34A1H6;RTN2DN2K;87X8C2TZ;V2EW727A;R53BPL4A;SPP13VWH;Y6X6JR79;BTEPCS7A;A9Z9JPEZ;1KLDXJG5;R7R3BT28;ETLSSJ7V;CXWCTYBJ;A5D4VH3T',
      statusContent: 'dot',
      hash: 'XZBYEJLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZBYEJLD'
    },
    {
      status: 910,
      sku: 'PM0071066',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'XEP8YZSA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XEP8YZSA'
    },
    {
      status: 210,
      sku: 'PM0071074',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254674',
      rtl_batch_array: '6254674',
      name_search: 'CAARUNDI',
      name: 'Calamagrostis arundinacea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1160,
      chnn_stock_retail: 1160,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RYW9BVW7;RV78KXEZ;WDHJSC62;J6BVKTSR;E3PARHGR;T27988NV;V2BDTC3S;BTK1TGGR',
      statusContent: 'dot',
      hash: '6TBYZNRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TBYZNRX'
    },
    {
      status: 210,
      sku: 'PM0071076',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269851',
      rtl_batch_array: '6269851, 6291892',
      name_search: 'EUARAINB',
      name: "Euphorbia 'Ascot Rainbow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 875,
      chnn_stock_retail: 1271,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXW5535K;HGWR6NJV;Z6THLVBS;TLHDE497;PRZ6VB4W;JEN87NZ4;AH7LK741;KA7BWCKE;PH2D1KER;HAXHG175;PTDWKR2C;66LARD52;JTXL5GXY;V5123AAZ;DGP7YTZD;98GWZNA9;E3VL86PT;GD8DKANB;D7B81B8K;7D2B91LX;Y7LSV513;HB32R7CB',
      statusContent: 'dot',
      hash: 'STAG4A2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STAG4A2K'
    },
    {
      status: 210,
      sku: 'PM0065057',
      core_name: 'Plant',
      sppl_ean: '8720664850675',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161503',
      rtl_batch_array: '6161503',
      name_search: 'ADGVIOLE',
      name: "Adenophora hybrid 'Gaudi Violet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K4VHXHZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4VHXHZ6'
    },
    {
      status: 210,
      sku: 'PM0054697',
      core_name: 'Plant',
      sppl_ean: '8720664850767',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080668',
      rtl_batch_array: '6080668',
      name_search: 'AGPWHITE',
      name: "Agapanthus 'Prolific White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y4Y6WHZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4Y6WHZB'
    },
    {
      status: 210,
      sku: 'PM0065077',
      core_name: 'Plant',
      sppl_ean: '8720664856745',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161570',
      rtl_batch_array: '6161570',
      name_search: 'CHALTERN',
      name: 'Chrysosplenium alternifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J82PLW2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J82PLW2H'
    },
    {
      status: 210,
      sku: 'PM0065102',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207844',
      rtl_batch_array: '6207844',
      name_search: 'DIESBOOT',
      name: "Dicentra eximia 'Stuart Boothman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EP9N3YNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EP9N3YNN'
    },
    {
      status: 210,
      sku: 'PM0078262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350554',
      rtl_batch_array: '6350554',
      name_search: 'INDSNOWT',
      name: "Incarvillea delavayi 'Snowtop'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JX547ZCX',
      statusContent: 'dot',
      hash: 'JJT7WP1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJT7WP1H'
    },
    {
      status: 210,
      sku: 'PM0065056',
      core_name: 'Plant',
      sppl_ean: '8720664850545',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161502',
      rtl_batch_array: '6161502',
      name_search: 'ACLAMARC',
      name: 'Aconitum lamarckii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 565,
      chnn_stock_retail: 565,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XX8R4K5C;T72HYC4K',
      statusContent: 'dot',
      hash: 'WH7HGZJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH7HGZJ9'
    },
    {
      status: 210,
      sku: 'PM0058569',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6340049',
      rtl_batch_array: '6112947, 6340049',
      name_search: 'TRRUBENS',
      name: 'Trifolium rubens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 502,
      chnn_stock_retail: 982,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1GETKZBJ',
      statusContent: 'dot',
      hash: '4P39E6AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P39E6AW'
    },
    {
      status: 910,
      sku: 'PM0047903',
      core_name: 'Plant',
      sppl_ean: '8720664807051',
      btch_manufacturer: 142908,
      rtl_batch_array_total: 1,
      btch_active_retail: '5969628',
      rtl_batch_array: '5969628',
      name_search: 'RUISOLU',
      name: 'Rubus idaeus Solu',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 3.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '751164BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '751164BW'
    },
    {
      status: 910,
      sku: 'PM0005317',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMBODEN',
      name: "Hydrangea macrophylla 'Bodensee'",
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        'VB81K7SZ;ZRDAT7B4;9TL5SAS2;CAKYVNEH;T2EHAA8J;6RT98EYD;KHD4XTSL;G454XKTX',
      statusContent: 'dot',
      hash: '23CWDRRL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '23CWDRRL'
    },
    {
      status: 210,
      sku: 'PM0065099',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217099',
      rtl_batch_array: '6161629, 6217099',
      name_search: 'CRMASONI',
      name: 'Crocosmia masoniorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 496,
      chnn_stock_retail: 2056,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AGKJW6T2',
      statusContent: 'dot',
      hash: '8YA8YB55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YA8YB55'
    },
    {
      status: 810,
      sku: 'PM0078263',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350564',
      rtl_batch_array: '6350564',
      name_search: 'IRNTRACE',
      name: "Iris (G) 'Natchez Trace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LH7XWHTC',
      statusContent: 'dot',
      hash: '4GCNZL9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GCNZL9E'
    },
    {
      status: 210,
      sku: 'PM0078264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350592',
      rtl_batch_array: '6350592',
      name_search: 'KAPHORTE',
      name: "Kalimeris pinnatifida 'Hortensis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 207,
      chnn_stock_retail: 207,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DHJGS3E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHJGS3E4'
    },
    {
      status: 210,
      sku: 'PM0078265',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350593',
      rtl_batch_array: '6350593',
      name_search: 'KAYSHOGU',
      name: "Kalimeris yomena 'Shogun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7LYTG3S7;7S2A4TS4;PAYDT41C;K1WDYGDP;627PZRLD;7P19WDT7;N1NYTA57',
      statusContent: 'dot',
      hash: 'JSA67L97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSA67L97'
    },
    {
      status: 210,
      sku: 'PM0058470',
      core_name: 'Plant',
      sppl_ean: '8720664874909',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112804',
      rtl_batch_array: '6112804',
      name_search: 'MUCGGIRL',
      name: "Muehlenbeckia complexa 'Golden Girl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 911,
      chnn_stock_retail: 911,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JYDZ2654',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYDZ2654'
    },
    {
      status: 210,
      sku: 'PM0063475',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6363204',
      rtl_batch_array: '6164422, 6217074, 6353335, 6363204',
      name_search: 'SAOPURPU',
      name: "Salvia officinalis 'Purpurascens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1146,
      chnn_stock_retail: 1697,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4X5VEDTS;WR8DCE91;LYP4S7N6;ZL2139NX;TR8KXKAL;NGKTTKKY;RSXE9WE8;CPA8235B;8GPHPW1A;GY6ZHLTN;KX9G6JLA;9WBS3WA1;J94X4SX9;9CGNX8E9;B1KTP7CD;JBKL9ZBT;HZBD4CRR;GAX4VG7K;GHEJYA87;WZ813HGS;6BACN9AD;YTZ5KZED;4GZ6N6CP;N1YHRVVV',
      statusContent: 'dot',
      hash: 'P3J4RB34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3J4RB34'
    },
    {
      status: 210,
      sku: 'PM0068592',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196997',
      rtl_batch_array: '6196997, 6254841, 6117846',
      name_search: 'SESBRILL',
      name: "Sedum spectabile 'Brillant'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1655,
      chnn_stock_retail: 4022,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46ANWG7S;GYTXK7C7;NP85B2AP;HE168LG8;SJ6YV7TS;E15EZ8WH;EZGLE5H1;8EC89EJN;JEG8AES9;6THWDC97;C1L2V8W7',
      statusContent: 'dot',
      hash: 'ZK78S2ZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK78S2ZK'
    },
    {
      status: 210,
      sku: 'PM0069196',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260708',
      rtl_batch_array: '6260708, 6252785',
      name_search: 'TELUCIDR',
      name: 'Teucrium lucidrys',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2408,
      chnn_stock_retail: 3303,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '98W9529H;S6X471TC;A5CK8G7H',
      statusContent: 'dot',
      hash: 'E2DG948Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2DG948Z'
    },
    {
      status: 210,
      sku: 'PM0069213',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254727',
      rtl_batch_array: '6250991, 6254727',
      name_search: 'GALSNOWS',
      name: "Gaura lindheimeri 'Snowstorm'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 665,
      chnn_stock_retail: 725,
      sppl_prcp: 2.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZY6H3PGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY6H3PGH'
    },
    {
      status: 210,
      sku: 'PM0068595',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197010',
      rtl_batch_array: '6197010',
      name_search: 'ARMARITI',
      name: 'Armeria maritima',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2403,
      chnn_stock_retail: 2403,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YWRXS3ZZ;W8HTRYXX;2PJP2822;W71BWD3K;HE1VP8T8;PHTY8YKR;HXPXLYWA;EENNXZTE;2VCBDPL2',
      statusContent: 'dot',
      hash: 'G15W11YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G15W11YX'
    },
    {
      status: 910,
      sku: 'PM0078300',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPHCORA',
      name: "Paeonia (LD) 'Pink Hawaiian Coral'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'L3V2JTA4',
      statusContent: 'dot',
      hash: '5TEY86BB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5TEY86BB'
    },
    {
      status: 210,
      sku: 'PM0078301',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350741',
      rtl_batch_array: '6350741',
      name_search: 'PAVCNINE',
      name: "Panicum virgatum 'Cloud Nine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1WNJ7HBV;PYVSHK4D;NY8BSWBC',
      statusContent: 'dot',
      hash: 'JCCXL9GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCCXL9GE'
    },
    {
      status: 210,
      sku: 'PM0078302',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350742',
      rtl_batch_array: '6350742',
      name_search: 'PAVDBLUE',
      name: "Panicum virgatum 'Dallas Blues'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSJGR7DV;BLTBBY62',
      statusContent: 'dot',
      hash: '5LXXPGJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LXXPGJS'
    },
    {
      status: 910,
      sku: 'PM0074282',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEDAUREA',
      name: "Cedrus deodara 'Aurea'",
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      imageCore: 'EA9BCR4N;XZLVYHDY;VABXE9W9',
      statusContent: 'dot',
      hash: 'YWVX8HG4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YWVX8HG4'
    },
    {
      status: 210,
      sku: 'PM0069206',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305220',
      rtl_batch_array: '6254700, 6305220',
      name_search: 'DIPALBA',
      name: "Digitalis purpurea 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2059,
      chnn_stock_retail: 3770,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PL17BX4L;62ZLAE6L',
      statusContent: 'dot',
      hash: 'XTNJR436',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTNJR436'
    },
    {
      status: 810,
      sku: 'PM0078310',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353135',
      rtl_batch_array: '6353135',
      name_search: 'PEADFLOS',
      name: "Persicaria amplexicaulis 'Dikke Floskes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZJENX9BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJENX9BW'
    },
    {
      status: 210,
      sku: 'PM0065154',
      core_name: 'Plant',
      sppl_ean: '8720664866720',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161737',
      rtl_batch_array: '6161737',
      name_search: 'HEISKY',
      name: "Hemerocallis 'Indian Sky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WYEVAXC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYEVAXC8'
    },
    {
      status: 210,
      sku: 'PM0052909',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162490',
      rtl_batch_array: '6162490',
      name_search: 'LUGBLUE',
      name: "Lupinus 'Gallery Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1007,
      chnn_stock_retail: 1007,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6A2XVBGP;CSWEN8B5;Y52GX7JR;S4ZE4B55;Z4KHSL8K;Y6W1NLC6;SSNSYA7R;86ENKC6N;T8WWSXNH;Y8R8XVLP;AWKN8EZ1;WKYYGJGE',
      statusContent: 'dot',
      hash: '6SKWHLHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SKWHLHV'
    },
    {
      status: 210,
      sku: 'PM0063674',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242179',
      rtl_batch_array: '6242179',
      name_search: 'PEMACROU',
      name: 'Pennisetum macrourum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZV4131Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV4131Z4'
    },
    {
      status: 810,
      sku: 'PM0078304',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353068',
      rtl_batch_array: '6353068',
      name_search: 'PEABALVE',
      name: "Pennisetum alopecuroides 'Black Alvernee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78ANTSXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78ANTSXY'
    },
    {
      status: 810,
      sku: 'PM0065007',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345478',
      rtl_batch_array: '6345478',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '150175C30',
      rng_range_identifier: 'H150175C30',
      rng_range_description: 'H150 cm 175 cm C30',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 37.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'GDCV5N44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDCV5N44'
    },
    {
      status: 210,
      sku: 'PM0065968',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307848',
      rtl_batch_array: '6307848',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_prcp: 7.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: 'NV84N3EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV84N3EA'
    },
    {
      status: 210,
      sku: 'PM0063679',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220917',
      rtl_batch_array: '6220917',
      name_search: 'SETDMAGI',
      name: "Sedum telephium 'Dark Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 985,
      chnn_stock_retail: 985,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YLTPNJ5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLTPNJ5S'
    },
    {
      status: 210,
      sku: 'PM0078305',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382309',
      rtl_batch_array: '6353070, 6382309, 6348397',
      name_search: 'PEACOMP',
      name: "Pennisetum al. 'Compressum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7250,
      chnn_stock_retail: 8450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8KYA53WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KYA53WB'
    },
    {
      status: 210,
      sku: 'PM0065150',
      core_name: 'Plant',
      sppl_ean: '8720664866195',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161726',
      rtl_batch_array: '6161726, 6302561',
      name_search: 'HESIESTA',
      name: "Helenium 'Siesta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 308,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SNEZ3EK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNEZ3EK4'
    },
    {
      status: 210,
      sku: 'PM0069215',
      core_name: 'Plant',
      btch_manufacturer: 189,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349111',
      rtl_batch_array: '6349111, 6259983',
      name_search: 'HECHAMPA',
      name: "Hebe 'Champagne'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1909,
      sppl_prcp: 1.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CSWT2D27;A4JB28N9;Z9HJ8RJ4;AB4REKDW;L293HHYR;BGYXW51K;6WWL8HPP;DR57JLNK;H3127WZ5;WCG8RSA9;VASYRJPH',
      statusContent: 'dot',
      hash: 'ZS3G15VE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZS3G15VE'
    },
    {
      status: 210,
      sku: 'PM0047978',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345228',
      rtl_batch_array: '6345228',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '060080C14',
      rng_range_identifier: 'H060080C1.5',
      rng_range_description: 'H60 cm 80 cm C1.5',
      sppl_stock_available: 5850,
      chnn_stock_retail: 5850,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'RE6WSX8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RE6WSX8K'
    },
    {
      status: 810,
      sku: 'PM0078306',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353074',
      rtl_batch_array: '6353074',
      name_search: 'PEAPGIAN',
      name: "Pennisetum alopecuroides 'Paul's Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9JAAYCY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JAAYCY1'
    },
    {
      status: 210,
      sku: 'PM0078307',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339318',
      rtl_batch_array: '6353081, 6339318',
      name_search: 'PEORIENT',
      name: 'Pennisetum orientale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1315,
      chnn_stock_retail: 1802,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BLPH2G4G;TC6J4497',
      statusContent: 'dot',
      hash: '442SLBVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '442SLBVE'
    },
    {
      status: 210,
      sku: 'PM0078308',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350696',
      rtl_batch_array: '6350696',
      name_search: 'MOLAWIND',
      name: "Molinia arundinacea 'Winds\u00e4ule'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 398,
      chnn_stock_retail: 398,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X3VHZYLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3VHZYLE'
    },
    {
      status: 210,
      sku: 'PM0039098',
      core_name: 'Plant',
      sppl_ean: '8720349485826',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5867486',
      rtl_batch_array: '5867486',
      name_search: 'COGDCORE',
      name: "Coreopsis grandiflora 'Dcoreo16'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 513,
      chnn_stock_retail: 513,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJNC55DE;7847P2LN;5A7NB6HX;XKJDRVHK;1CZSTWNG',
      statusContent: 'dot',
      hash: 'WRR4VSET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRR4VSET'
    },
    {
      status: 210,
      sku: 'PM0068042',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266351',
      rtl_batch_array: '6266351',
      name_search: 'THOGGLOB',
      name: "Thuja occidentalis 'Golden Globe'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2024,
      chnn_stock_retail: 2024,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RD5LGE47;8TD9DDNK;8X745LHB;RHB2X2BR;T12GN6XT;C66Y35N7',
      statusContent: 'dot',
      hash: 'ASJ3T8J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASJ3T8J8'
    },
    {
      status: 810,
      sku: 'PM0067997',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217031',
      rtl_batch_array: '6217031',
      name_search: 'NESHGOLD',
      name: "Nepeta 'Six Hills Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9A1B67KX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9A1B67KX'
    },
    {
      status: 210,
      sku: 'PM0081704',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301761',
      rtl_batch_array: '6301761',
      name_search: 'PAINTEGR',
      name: 'Parthenium integrifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZVZW77L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVZW77L8'
    },
    {
      status: 210,
      sku: 'PM0065107',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192727',
      rtl_batch_array: '6192727',
      name_search: 'ECPHPAPA',
      name: "Echinacea purpurea 'Hot Papaya'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 811,
      chnn_stock_retail: 811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5EET26G8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EET26G8'
    },
    {
      status: 810,
      sku: 'PM0085241',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373134',
      rtl_batch_array: '6373134',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: 'DE9SX861',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE9SX861'
    },
    {
      status: 210,
      sku: 'PM0078319',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353166',
      rtl_batch_array: '6353166',
      name_search: 'PHLFLAME',
      name: 'Phlox (P) Lilac Flame',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 371,
      chnn_stock_retail: 371,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W116VLHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W116VLHJ'
    },
    {
      status: 210,
      sku: 'PM0068041',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266345',
      rtl_batch_array: '6266345',
      name_search: 'PISTKURL',
      name: "Pinus strobus 'Tiny Kurls'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 678,
      chnn_stock_retail: 678,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RAW1Z7TG;Y4RS5266;YXYDGH2Y;EY94LTPG;1E5YXS9C;4S2YA78N;LK35AYPB;GNRL6NL6;DD3LY8G2;J4KVB455;41S1JVKK',
      statusContent: 'dot',
      hash: 'YHPRWR1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHPRWR1D'
    },
    {
      status: 210,
      sku: 'PM0081705',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301787',
      rtl_batch_array: '6301787',
      name_search: 'PECNEAPO',
      name: 'Petroselinum crispum neapolitanum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HB9SXPZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB9SXPZP'
    },
    {
      status: 210,
      sku: 'PM0078323',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353087',
      rtl_batch_array: '6353087',
      name_search: 'PHWFLAME',
      name: 'Phlox (P) White Flame',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YCKW4C3N',
      statusContent: 'dot',
      hash: 'SZWCTJTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZWCTJTP'
    },
    {
      status: 210,
      sku: 'PM0065116',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350144',
      rtl_batch_array: '6350144, 6338936',
      name_search: 'ERPBLUE',
      name: "Eryngium 'Pen Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 804,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NXHJP1ZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXHJP1ZW'
    },
    {
      status: 210,
      sku: 'PM0081706',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295489',
      rtl_batch_array: '6295489',
      name_search: 'CACSNOWL',
      name: "Carex conica 'Snowline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WGZX116V;HWEE7K39;GBWH8425;95XAHY25;7843EWTA;D973L3J4;6ZK7KTK7;D1E2SSCC;L96X7DJZ;E6HCXW6J',
      statusContent: 'dot',
      hash: 'V5DPY7BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5DPY7BC'
    },
    {
      status: 210,
      sku: 'PM0068003',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349067',
      rtl_batch_array: '6349067, 6238784',
      name_search: 'DEBELLAM',
      name: "Delphinium (B) 'Bellamosum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5660,
      chnn_stock_retail: 6025,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B1Z2TB8C',
      statusContent: 'dot',
      hash: 'TBX8X1CV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBX8X1CV'
    },
    {
      status: 210,
      sku: 'PM0068044',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254909',
      rtl_batch_array: '6254909, 6195728',
      name_search: 'PAVHMETA',
      name: "Panicum virgatum 'Heavy Metal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 5852,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V5XSXSYX;C8S1WKJ1;BTG42V3L;4RPES78Z;97Z52BYT;CWPCSTAN;B27L9XH1;E71GYABT;NGTG2JP9;9YE7Z337;R1NWHNC8;K9DC6BKX;XENCB779;LT5N1A2T;H8ABNY7W;1E6ZHVGW;YAD39A58;XKLGYA4D;VCKC5T7W;XXBCD7VY;1P73SDWB;WRKX9E81;BH52Y7WX;6L6ASRXS;2D21YBST;JHS4125Z;8S2SCRWD',
      statusContent: 'dot',
      hash: 'DK4RJECY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DK4RJECY'
    },
    {
      status: 210,
      sku: 'PM0068043',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6195727',
      rtl_batch_array: '6195727',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 12765,
      chnn_stock_retail: 12765,
      sppl_prcp: 3.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: '4AT5YS92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AT5YS92'
    },
    {
      status: 910,
      sku: 'PM0078344',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRBULLEE',
      name: 'Primula bulleesiana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'N7BNJ67R;WYK4PDXR;WNW6KY9N;A3N4WNXV;PRZLWVEA;NLNA5RJD;CYZD4W88;6RJT49E4;KEHBVNCV;XW7VGYA1;RYVZERN5;2DEWJX36;YSZ6EEWS;4A4TP5WK;YE8SRXC8;DX9AEZ9Z',
      statusContent: 'dot',
      hash: 'XP97D52D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XP97D52D'
    },
    {
      status: 210,
      sku: 'PM0025185',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6077497',
      rtl_batch_array: '6077497',
      name_search: 'ASKASSEL',
      name: "Aster (D) 'Kassel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZ25E144;PRGRX8A2',
      statusContent: 'dot',
      hash: 'LWHVX3NW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWHVX3NW'
    },
    {
      status: 210,
      sku: 'PM0078325',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353091',
      rtl_batch_array: '6353091',
      name_search: 'PHSTERNH',
      name: "Phlox (P) 'Sternhimmel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GCVBT1B7',
      statusContent: 'dot',
      hash: 'PKH17VAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKH17VAL'
    },
    {
      status: 910,
      sku: 'PM0063683',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SEHISPAN',
      name: 'Sedum hispanicum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        '65ZK88JC;48C1YZAJ;8ZJD1V3T;9DHNEE1X;C7RYDNTL;G8CX359L;4T275ZKB;R9RT2DSH;R857X629;HV2L2SDH',
      statusContent: 'dot',
      hash: 'GYA7B4VV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GYA7B4VV'
    },
    {
      status: 210,
      sku: 'PM0039123',
      core_name: 'Plant',
      sppl_ean: '8720664800090',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5871792',
      rtl_batch_array: '5871792, 6254794',
      name_search: 'NENEPTUN',
      name: 'Nepeta Neptune',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1145,
      chnn_stock_retail: 2587,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '49BVPP9T;DXZDLYVJ;48D85TB4;TTDDA44N;VA3G6RTT;8K4AXY5E;V7KTS8RT;XAC4W8JW',
      statusContent: 'dot',
      hash: 'YH4SNWPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YH4SNWPJ'
    },
    {
      status: 210,
      sku: 'PM0052910',
      core_name: 'Plant',
      sppl_ean: '8720664698512',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6003585',
      rtl_batch_array: '6003585',
      name_search: 'LUNMAIDE',
      name: "Lupinus 'Noble Maiden'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 458,
      chnn_stock_retail: 458,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E6PDYS87;935YDA1T',
      statusContent: 'dot',
      hash: 'ELH7Y2GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELH7Y2GS'
    },
    {
      status: 910,
      sku: 'PM0039151',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'INENSIFO',
      name: 'Inula ensifolia',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'GCAKPEKL;GHSKDTPC;5KX1NRDY',
      statusContent: 'dot',
      hash: '2AA7ZBTH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2AA7ZBTH'
    },
    {
      status: 210,
      sku: 'PM0078326',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353092',
      rtl_batch_array: '6353092',
      name_search: 'PHSSCAND',
      name: 'Phlox (P) Sweet Summer Candy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 259,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7LZX7PR2;AVP61D91;62SNKW7K',
      statusContent: 'dot',
      hash: 'EJB7GH91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJB7GH91'
    },
    {
      status: 210,
      sku: 'PM0066596',
      core_name: 'Plant',
      sppl_ean: '8720349466672',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420615',
      rtl_batch_array: '5420615',
      name_search: 'CLMWILSO',
      name: 'Clematis montana wilsonii',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1998,
      chnn_stock_retail: 1998,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RDCHEWDY',
      statusContent: 'dot',
      hash: 'EZVDRRBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZVDRRBT'
    },
    {
      status: 210,
      sku: 'PM0069209',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244283',
      rtl_batch_array: '6244283, 6252561',
      name_search: 'ERPLANUM',
      name: 'Eryngium planum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2119,
      chnn_stock_retail: 2881,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVJGV8X5;6L8XTN1S;966YXAT3;9PLBPRX7;HRB4SAB9;96BZK23T;4D3RKS97;CDGCX942;ZER67XZD;X26JN68H;NH71N92K;BAKN3Z21;W76DYJJE;VK7A495B;7ZNAEY4L;ZDJSK3L5;NEBB4D4H;B78VXE16;RTLBYP6T;C4WSSBKC;9C21R25T;RKHV1VJB;42G34W5B',
      statusContent: 'dot',
      hash: 'T8X7YDAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8X7YDAE'
    },
    {
      status: 210,
      sku: 'PM0069212',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244228',
      rtl_batch_array: '6244228, 6234718',
      name_search: 'FUTTHUMB',
      name: "Fuchsia 'Tom Thumb'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 833,
      chnn_stock_retail: 1266,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GN92TR3;HVEZL64A;SCTBVHZH;1G825SDJ;GXVX5HVB;V6RP7PJ8;1CCRVRSR;5D8APRRE;P4WP1K77;CPLR64E8;DT5B7T6E;4LPRN9GW;XKDXWKSD;KT2R26DT;58V3W793',
      statusContent: 'dot',
      hash: '6AJLREHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6AJLREHH'
    },
    {
      status: 210,
      sku: 'PM0078327',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353093',
      rtl_batch_array: '6353093',
      name_search: 'PHSSDREA',
      name: 'Phlox (P) Sweet Summer Dream',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 184,
      chnn_stock_retail: 184,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJ4CT7XS;2J4YKH9C',
      statusContent: 'dot',
      hash: 'BPHHB4DK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPHHB4DK'
    },
    {
      status: 210,
      sku: 'PM0069203',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6233394',
      rtl_batch_array: '6233394, 6254858',
      name_search: 'VESUDBLU',
      name: "Veronica spicata 'Ulster Dwarf Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 1350,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XLPRNV5A;6PB4KV39;NBJKAZNK;N6K39S8W;93GV6W39;7RX2PL8Y;AKZ7YLLW;N9SL1ZAE;K1WGKSW3;BK236749;Y8ZKX3BL;J2C69YW7;GL6AVRSY;9TTGTKX4;J1244EVZ;PY4XLR1J;47AVCVXX;WEGHCCCY;4SDW3SDK',
      statusContent: 'dot',
      hash: 'Z7GETE26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7GETE26'
    },
    {
      status: 210,
      sku: 'PM0069207',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254701',
      rtl_batch_array: '6254701',
      name_search: 'DIPABEAU',
      name: "Digitalis purpurea 'Apricot Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 755,
      chnn_stock_retail: 755,
      sppl_prcp: 1.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ARZBZK4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARZBZK4K'
    },
    {
      status: 810,
      sku: 'PM0069205',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254862',
      rtl_batch_array: '6254862',
      name_search: 'VIMGRAVE',
      name: "Vinca minor 'La Grave'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_prcp: 2.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B5LELH5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5LELH5S'
    },
    {
      status: 210,
      sku: 'PM0065145',
      core_name: 'Plant',
      sppl_ean: '8720664866089',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161720',
      rtl_batch_array: '6161720',
      name_search: 'HEJTRUBE',
      name: "Helenium 'Jubel Trubel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PXSCBSDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXSCBSDX'
    },
    {
      status: 210,
      sku: 'PM0078329',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353095',
      rtl_batch_array: '6353095',
      name_search: 'PHSSFEST',
      name: 'Phlox (P) Sweet Summer Festival',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ED895VT3;TKBADWPK;D3NCC6C1',
      statusContent: 'dot',
      hash: 'HXLWAWTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXLWAWTN'
    },
    {
      status: 810,
      sku: 'PM0078330',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353097',
      rtl_batch_array: '6353097',
      name_search: 'PHSSSENS',
      name: 'Phlox (P) Sweet Summer Sensation',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPCYTTBL;89VJ1EDX;ATLVV6VH',
      statusContent: 'dot',
      hash: '5JW1KSG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JW1KSG8'
    },
    {
      status: 210,
      sku: 'PM0078331',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353098',
      rtl_batch_array: '6353098',
      name_search: 'PHSSSURP',
      name: 'Phlox (P) Sweet Summer Surprise',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XV8BZEYW;GD68DG4R;4CNTYZLS',
      statusContent: 'dot',
      hash: '9ZCT924E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZCT924E'
    },
    {
      status: 810,
      sku: 'PM0078333',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353101',
      rtl_batch_array: '6353101',
      name_search: 'PHTIARA',
      name: "Phlox (P) 'Tiara'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S33B98K4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S33B98K4'
    },
    {
      status: 210,
      sku: 'PM0078334',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353108',
      rtl_batch_array: '6353108',
      name_search: 'PHVCPWHI',
      name: "Physostegia virginiana 'Cristal Peak White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 605,
      chnn_stock_retail: 605,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CS2XA7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CS2XA7P'
    },
    {
      status: 910,
      sku: 'PM0058552',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6224199',
      rtl_batch_array: '6153436, 6224199, 6164426, 6177948, 6353306, 6287693',
      name_search: 'SEMATRON',
      name: "Sedum 'Matrona'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 11,
      chnn_stock_retail: 11613,
      sppl_order_minimum: 3,
      sppl_prcp: 0.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDRL76HJ;SBRCJ4T3;W5DHZ68K;GL3LTC14;T84VJLB9;7VYX8TAZ;9WP65ACW;X13NRXA1',
      statusContent: 'dot',
      hash: '8GV78NS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GV78NS9'
    },
    {
      status: 210,
      sku: 'PM0063676',
      core_name: 'Plant',
      sppl_ean: '8720664877351',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6147846',
      rtl_batch_array: '6147846, 6320891',
      name_search: 'PEAJDMAC',
      name: "Persicaria amplexicaulis 'JS Delgado Macho'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1428,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZHTPAHVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHTPAHVR'
    },
    {
      status: 210,
      sku: 'PM0071081',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6225019',
      rtl_batch_array: '6225019',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '060100C5',
      rng_range_identifier: 'H060100C5',
      rng_range_description: 'H60 cm 100 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'K2BELJT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2BELJT4'
    },
    {
      status: 810,
      sku: 'PM0078339',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353218',
      rtl_batch_array: '6353218',
      name_search: 'POARGENT',
      name: 'Potentilla argentea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GKWLAC3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKWLAC3Z'
    },
    {
      status: 210,
      sku: 'PM0068013',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217211',
      rtl_batch_array: '6217211',
      name_search: 'LYROBIN',
      name: 'Lythrum Robin',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4BT2CGD9;6VATJAGJ;ELBNJ4EG;4X42TYE2;28V5GNDP;T19DDGSH;LDB6GLW8;7V9G5J33;51PH1N56;JSYALT1Y;BN6SVTL6;5XPY2BNN;9B596W57;XANPVZD6;HXPRYE37',
      statusContent: 'dot',
      hash: 'VTZXLRD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTZXLRD9'
    },
    {
      status: 810,
      sku: 'PM0078309',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353133',
      rtl_batch_array: '6353133',
      name_search: 'PEAAMETH',
      name: "Persicaria amplexicaulis 'Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L7S77NCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7S77NCR'
    },
    {
      status: 210,
      sku: 'PM0065152',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176463',
      rtl_batch_array: '6176463',
      name_search: 'HEBAKABA',
      name: "Hemerocallis 'Bakabana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EK17KLB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EK17KLB9'
    },
    {
      status: 210,
      sku: 'PM0078342',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353224',
      rtl_batch_array: '6353224',
      name_search: 'POFLAMEN',
      name: "Potentilla 'Flamenco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PD8NBCZA',
      statusContent: 'dot',
      hash: 'WZV7LXTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZV7LXTC'
    },
    {
      status: 210,
      sku: 'PM0081707',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295856',
      rtl_batch_array: '6295856',
      name_search: 'SEAMURAL',
      name: "Sedum album 'Murale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8APB7X1H;G6G7ANP5;S49R54C6;YS6DWR7X;HA11S5KE;B96WKS2Z;7LP3YXY6;Y1RT7GD5;LKTPEC3G;PCELVA9Y',
      statusContent: 'dot',
      hash: 'CW9YXAD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW9YXAD5'
    },
    {
      status: 910,
      sku: 'PM0071084',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239625',
      rtl_batch_array: '6239625',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 6.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'EA2D2H96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA2D2H96'
    },
    {
      status: 210,
      sku: 'PM0049049',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 4,
      btch_active_retail: '6356181',
      rtl_batch_array: '6228336, 6356181, 6338319, 6339003',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 12819,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'E6YHX8ZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6YHX8ZW'
    },
    {
      status: 210,
      sku: 'PM0065142',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301618',
      rtl_batch_array: '6301618, 6161714',
      name_search: 'GECKOI',
      name: "Geum coccineum 'Koi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 242,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DKLGS61H;VST5WZLS;VP61JHW3;8P1JE8KC;JTETK7G5;Y6Y1HK2K;GARTHGC4;R5WBPS71;A2E3VGYK;89DPZJET;G5E5LY3E',
      statusContent: 'dot',
      hash: '56WWWW5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56WWWW5B'
    },
    {
      status: 810,
      sku: 'PM0071085',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6225024',
      rtl_batch_array: '6225024',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080090C74',
      rng_range_identifier: 'H080090C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.142,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'BGY832AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGY832AB'
    },
    {
      status: 210,
      sku: 'PM0081708',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295899',
      rtl_batch_array: '6295899',
      name_search: 'VEAKNALL',
      name: "Veronica austriaca 'Knallblau'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z38TNPNP',
      statusContent: 'dot',
      hash: 'HRZ1R8TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRZ1R8TY'
    },
    {
      status: 910,
      sku: 'PM0052941',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307546',
      rtl_batch_array: '6307546',
      name_search: 'MAGHKERN',
      name: "Magnolia 'George Henry Kern'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 11.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDX3ZPEN;PDB2YP8N;E9GRPBCC',
      statusContent: 'dot',
      hash: 'WXZVKXSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXZVKXSR'
    },
    {
      status: 210,
      sku: 'PM0078347',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353272',
      rtl_batch_array: '6353272',
      name_search: 'RHFKISSE',
      name: 'Rhodoxis Fairy Kisses',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DWA8JB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DWA8JB5'
    },
    {
      status: 810,
      sku: 'PM0078348',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353175',
      rtl_batch_array: '6353175',
      name_search: 'RODGBLAN',
      name: "Rodgersia 'La Blanche'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K7SPZYR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7SPZYR5'
    },
    {
      status: 210,
      sku: 'PM0065139',
      core_name: 'Plant',
      sppl_ean: '8720664864955',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161709',
      rtl_batch_array: '6161709',
      name_search: 'GEPRADMI',
      name: "Geranium psilostemon 'Red Admiral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NH5BWSR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH5BWSR7'
    },
    {
      status: 810,
      sku: 'PM0065149',
      core_name: 'Plant',
      sppl_ean: '8720664866157',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161725',
      rtl_batch_array: '6161725, 6348335',
      name_search: 'HESALSA',
      name: "Helenium 'Salsa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G5GC5W3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5GC5W3A'
    },
    {
      status: 210,
      sku: 'PM0078303',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350745',
      rtl_batch_array: '6350745',
      name_search: 'PAVNICAN',
      name: "Panicum virgatum 'Nican'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DA1ET8PK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA1ET8PK'
    },
    {
      status: 210,
      sku: 'PM0078311',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353136',
      rtl_batch_array: '6353136, 6320890',
      name_search: 'PEAFASCI',
      name: "Persicaria amplexicaulis 'Fascination'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7AAW5BE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AAW5BE1'
    },
    {
      status: 210,
      sku: 'PM0078312',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348409',
      rtl_batch_array: '6353137, 6348409',
      name_search: 'PEAFIRED',
      name: "Persicaria amplexicaulis 'Firedance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1719,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '58JX1H1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58JX1H1D'
    },
    {
      status: 210,
      sku: 'PM0063677',
      core_name: 'Plant',
      sppl_ean: '8720664885783',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147849',
      rtl_batch_array: '6147849',
      name_search: 'SEAOKTOB',
      name: "Sedum acre 'Oktoberfest'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JLZTXV6J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLZTXV6J'
    },
    {
      status: 210,
      sku: 'PM0078315',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353158',
      rtl_batch_array: '6353158',
      name_search: 'PHDELTA',
      name: "Phlox (M) 'Delta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NWKTXYX',
      statusContent: 'dot',
      hash: 'VALGK932',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VALGK932'
    },
    {
      status: 210,
      sku: 'PM0078316',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353160',
      rtl_batch_array: '6353160',
      name_search: 'PHROSALI',
      name: "Phlox (M) 'Rosalinde'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8DNXLK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8DNXLK3'
    },
    {
      status: 210,
      sku: 'PM0078317',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353162',
      rtl_batch_array: '6353162',
      name_search: 'PHARGUS',
      name: "Phlox (P) 'Argus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '269LGS2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '269LGS2S'
    },
    {
      status: 810,
      sku: 'PM0078322',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353170',
      rtl_batch_array: '6353170',
      name_search: 'PHRFLAME',
      name: 'Phlox (P) Red Flame',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K79AAS2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K79AAS2D'
    },
    {
      status: 810,
      sku: 'PM0078324',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353088',
      rtl_batch_array: '6353088',
      name_search: 'PHGLION',
      name: "Phlox (P) 'Green Lion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RX4NA4KK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX4NA4KK'
    },
    {
      status: 810,
      sku: 'PM0069210',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284554',
      rtl_batch_array: '6284554',
      name_search: 'EUPSENIO',
      name: "Euphorbia polychroma 'Senior'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6G4VX7B5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6G4VX7B5'
    },
    {
      status: 210,
      sku: 'PM0065147',
      core_name: 'Plant',
      sppl_ean: '8720664866843',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161722',
      rtl_batch_array: '6161722',
      name_search: 'HEMRUYS',
      name: "Hemerocallis 'Mien Ruys'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AKK5XAA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKK5XAA2'
    },
    {
      status: 210,
      sku: 'PM0069218',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212858',
      rtl_batch_array: '6212858',
      name_search: 'PITGSTAR',
      name: "Pittosporum tenuifolium 'Gold Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JREBG5XN;8X7LRC8Z;6PLDABRE;WY1ANK5H',
      statusContent: 'dot',
      hash: 'W4699RBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4699RBK'
    },
    {
      status: 210,
      sku: 'PM0068015',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217027',
      rtl_batch_array: '6217027',
      name_search: 'NEJWALKE',
      name: 'Nepeta Junior Walker',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HRWBR36L;DLAZRLD3;Y3NYRCHX;253HKYWC;KDRXRW8E',
      statusContent: 'dot',
      hash: 'WAEKDJ9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAEKDJ9K'
    },
    {
      status: 210,
      sku: 'PM0078328',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353094',
      rtl_batch_array: '6353094',
      name_search: 'PHSSFANT',
      name: 'Phlox (P) Sweet Summer Fantasy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2L7A7X7T;4LA19SXW;Z1VG7L8D',
      statusContent: 'dot',
      hash: '14Z95AS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14Z95AS2'
    },
    {
      status: 210,
      sku: 'PM0078336',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353117',
      rtl_batch_array: '6353117',
      name_search: 'PLGHBLUE',
      name: "Platycodon grandiflorus 'Hakone Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8YBG6AHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YBG6AHB'
    },
    {
      status: 210,
      sku: 'PM0052905',
      core_name: 'Plant',
      sppl_ean: '8720664863538',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6146973',
      rtl_batch_array: '6146973',
      name_search: 'FOVULGAR',
      name: 'Foeniculum vulgare',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 724,
      chnn_stock_retail: 724,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J855KRX7;5DXWKHL4;X1HGJ482;ZKD1NCEZ;KHJ4N18C;86S2WTZB;ABYXYWT4;7PBXGLXR;TNH5YYSE;S6LD18ND;2H3TGSW2;X229EYDN;GWBNC341;8LCZ5KL4;CBTZZRXN;4GR5CTN7;XR1X4EYS;EH8HPHEK;EZRENBRA;5JHTK6GE;1CGJ6X4T;29E63VJK',
      statusContent: 'dot',
      hash: 'K45XV4SV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K45XV4SV'
    },
    {
      status: 210,
      sku: 'PM0078332',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353099',
      rtl_batch_array: '6353099',
      name_search: 'PHSSTEMP',
      name: 'Phlox (P) Sweet Summer Temptation',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WWX8LDLJ;W62K7S15;KX2KDBVR',
      statusContent: 'dot',
      hash: '6D5L7ZJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6D5L7ZJY'
    },
    {
      status: 910,
      sku: 'PM0078335',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PLGFPINK',
      name: "Platycodon grandiflorus 'Fuji Pink'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'RCBJDS7S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RCBJDS7S'
    },
    {
      status: 210,
      sku: 'PM0078337',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333298',
      rtl_batch_array: '6353118, 6333298, 6345204',
      name_search: 'PLGMARIE',
      name: "Platycodon grandiflorus 'Mariesii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2136,
      chnn_stock_retail: 2979,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E594C1NC;Y8XLDT5K;GWX3CSN2;EBRJW1NA;J6B4CYS1;LRN6LRT8',
      statusContent: 'dot',
      hash: 'R6J2J5BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6J2J5BC'
    },
    {
      status: 810,
      sku: 'PM0078338',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353119',
      rtl_batch_array: '6353119',
      name_search: 'POPELTAT',
      name: 'Podophyllum peltatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K9YG5H5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9YG5H5B'
    },
    {
      status: 210,
      sku: 'PM0065151',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290216',
      rtl_batch_array: '6161732, 6290216',
      name_search: 'HEALTISS',
      name: 'Hemerocallis altissima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1032,
      chnn_stock_retail: 1213,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATNACYA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATNACYA9'
    },
    {
      status: 810,
      sku: 'PM0069204',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269816',
      rtl_batch_array: '6269816, 6252801',
      name_search: 'VIMAJOR',
      name: 'Vinca major',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 95,
      chnn_stock_retail: 2045,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9DVAL8E9;LKGVHAZJ;7V854YDB',
      statusContent: 'dot',
      hash: '9BK6SSSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BK6SSSN'
    },
    {
      status: 810,
      sku: 'PM0078340',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353219',
      rtl_batch_array: '6353219',
      name_search: 'POAARGYR',
      name: 'Potentilla atrosanguinea argyrophylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XECPCXK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XECPCXK5'
    },
    {
      status: 210,
      sku: 'PM0078341',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353221',
      rtl_batch_array: '6353221',
      name_search: 'POEMILIE',
      name: "Potentilla 'Emilie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 338,
      chnn_stock_retail: 338,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VG6T9TS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VG6T9TS1'
    },
    {
      status: 210,
      sku: 'PM0078345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353230',
      rtl_batch_array: '6353230',
      name_search: 'PRBULLEY',
      name: 'Primula bulleyana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1702,
      chnn_stock_retail: 1702,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BBTPGHTY;2ZSXAEEJ;BEV9NNV8;YZ462685;RZ7RVTEP;NTREH1ZV;L71JS8CC;2TRXT968',
      statusContent: 'dot',
      hash: 'ALACW2DW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALACW2DW'
    },
    {
      status: 210,
      sku: 'PM0078346',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353237',
      rtl_batch_array: '6353237',
      name_search: 'PRJCARMI',
      name: "Primula japonica 'Carminea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C1KP821W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1KP821W'
    },
    {
      status: 810,
      sku: 'PM0069200',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252793',
      rtl_batch_array: '6252793',
      name_search: 'TRPUMILU',
      name: 'Trollius pumilus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AAH4STB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAH4STB9'
    },
    {
      status: 210,
      sku: 'PM0069208',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254702',
      rtl_batch_array: '6254702',
      name_search: 'DIPGLOXI',
      name: "Digitalis purpurea 'Gloxiniiflora'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1371,
      chnn_stock_retail: 1371,
      sppl_prcp: 1.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XK74Y4ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XK74Y4ZA'
    },
    {
      status: 210,
      sku: 'PM0069211',
      core_name: 'Plant',
      sppl_ean: '8720626347335',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5871872',
      rtl_batch_array: '6252570, 5871872',
      name_search: 'FRVESCA',
      name: 'Fragaria vesca',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 393,
      chnn_stock_retail: 1088,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NG94EY2C;K2DCSSXA;CA3PN5XL;RWXV4VCT;LYWLJEPK;DCCYE7D9;8WNYJYXW;CGCJBT4P;TTXGBD7X;TW2AYA2G;8X3D9J28;KGPVVN4T',
      statusContent: 'dot',
      hash: 'LL9D2R8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL9D2R8A'
    },
    {
      status: 210,
      sku: 'PM0068596',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6305224',
      rtl_batch_array: '5837431, 6254729, 6305224',
      name_search: 'GECBIOKO',
      name: "Geranium cantabrigiense 'Biokovo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1099,
      chnn_stock_retail: 2709,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DVDG9E94;WVXXXA4Z;GPH756PG;5EXRA7WG;DXRGE7XS;R2N72X5P;ZAN9PRYS;YLEGBWGZ;L16JD9VE;VNT4AYH4;KYDR38NK;P9723XS5',
      statusContent: 'dot',
      hash: 'Y9T52C4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9T52C4T'
    },
    {
      status: 210,
      sku: 'PM0068593',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284555',
      rtl_batch_array: '6233395, 6284555, 6254839',
      name_search: 'SEMATRON',
      name: "Sedum 'Matrona'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 923,
      chnn_stock_retail: 3852,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDRL76HJ;SBRCJ4T3;W5DHZ68K;GL3LTC14;T84VJLB9;7VYX8TAZ;9WP65ACW;X13NRXA1',
      statusContent: 'dot',
      hash: 'CR9E5NE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR9E5NE4'
    },
    {
      status: 910,
      sku: 'PM0067998',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      rng_range_identifier: 'CONEH140150C40',
      rng_range_description: 'Cone H140 cm 150 cm C40',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'GH8HDDG2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GH8HDDG2'
    },
    {
      status: 210,
      sku: 'PM0069202',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234524',
      rtl_batch_array: '6234524',
      name_search: 'VEREPENS',
      name: 'Veronica repens',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 656,
      chnn_stock_retail: 656,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPWA3VT8;6ABJ18NA;CY141VNT',
      statusContent: 'dot',
      hash: 'WJNVXT73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJNVXT73'
    },
    {
      status: 210,
      sku: 'PM0078313',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353138',
      rtl_batch_array: '6353138, 6338953',
      name_search: 'PEAGARRO',
      name: "Persicaria amplexicaulis 'Golden Arrow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WV9YC6PP',
      statusContent: 'dot',
      hash: 'Y5NZZ9V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5NZZ9V7'
    },
    {
      status: 210,
      sku: 'PM0078318',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353163',
      rtl_batch_array: '6353163',
      name_search: 'PHBLION',
      name: "Phlox (P) 'Blind Lion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J9BVPX8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9BVPX8W'
    },
    {
      status: 810,
      sku: 'PM0078320',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353167',
      rtl_batch_array: '6353167',
      name_search: 'PHFPSPIN',
      name: 'Phlox (P) Flame Pro Soft Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AY9TH2WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AY9TH2WC'
    },
    {
      status: 210,
      sku: 'PM0063713',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271349',
      rtl_batch_array: '6271349, 6378464, 6334293',
      name_search: 'BUWSUNGO',
      name: "Buddleja weyeriana 'Sungold'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2051,
      chnn_stock_retail: 7696,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B84PXPB;KETH4VA6',
      statusContent: 'dot',
      hash: '56ZB3NNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56ZB3NNE'
    },
    {
      status: 210,
      sku: 'PM0081709',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295939',
      rtl_batch_array: '6295939',
      name_search: 'MAPENSYL',
      name: 'Matteuccia pensylvanica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BDCXK87A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDCXK87A'
    },
    {
      status: 210,
      sku: 'PM0078349',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353177',
      rtl_batch_array: '6353177',
      name_search: 'ROPHANNA',
      name: "Rodgersia pinnata 'Hanna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DT66RLLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT66RLLY'
    },
    {
      status: 210,
      sku: 'PM0085242',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373135',
      rtl_batch_array: '6373135',
      name_search: 'PHCAUREU',
      name: "Philadelphus coronarius 'Aureus'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A49BJ4E8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A49BJ4E8'
    },
    {
      status: 210,
      sku: 'PM0085243',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373136',
      rtl_batch_array: '6373136',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1981,
      chnn_stock_retail: 1981,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: 'ENK5RLZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENK5RLZL'
    },
    {
      status: 210,
      sku: 'PM0081710',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302662',
      rtl_batch_array: '6302662',
      name_search: 'MAORIENT',
      name: 'Matteuccia orientalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD36TGBK',
      statusContent: 'dot',
      hash: 'EL79YPEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EL79YPEG'
    },
    {
      status: 210,
      sku: 'PM0078352',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308319',
      rtl_batch_array: '6308319',
      name_search: 'ROBEESIA',
      name: "Roscoea 'Beesiana'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97V98VBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97V98VBW'
    },
    {
      status: 910,
      sku: 'PM0039124',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'NESHGIAN',
      name: "Nepeta 'Six Hills Giant'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'P5A3KB96;G9JH2P1K;B9TSLTR6;HHSZEGKL;K9D8H9CT;SS7TWGDZ;BDXC99PY;PPG4YWKG;A9DV867W;PPTNE2DP;L2HEWPPV;6H8W59KV;ZK55T26J;2926Y1WV;4B4DB35R;CPD69W2P;6G4G1W64;KV1WXYS1;ZBTHA3DP;XS3NB11N;WSDAA929;CTLZ3671;9B2HKLC5',
      statusContent: 'dot',
      hash: 'T54JZ36V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T54JZ36V'
    },
    {
      status: 810,
      sku: 'PM0081711',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302670',
      rtl_batch_array: '6302670',
      name_search: 'MISGHANA',
      name: "Miscanthus sinensis 'Ghana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LBHDZD3N;79ZDE76P;XTK51RBH;1A92A6ER;RRGS4DYS;LHXV9RSZ;5CD7ANCN;5PWEN7E9;TGC4SJD5;YKV7W2KZ',
      statusContent: 'dot',
      hash: 'CST84J6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CST84J6C'
    },
    {
      status: 210,
      sku: 'PM0078353',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370390',
      rtl_batch_array: '6370390, 6353200',
      name_search: 'RUTPGLOW',
      name: "Rudbeckia triloba 'Prairie Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 553,
      chnn_stock_retail: 1499,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ERBNB6R1;BJTZWBP1;RA8TNBWW;BX1KKPRZ;KA58GYSV',
      statusContent: 'dot',
      hash: 'WVTYCYZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVTYCYZV'
    },
    {
      status: 210,
      sku: 'PM0081712',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302679',
      rtl_batch_array: '6302679',
      name_search: 'MOELAVEN',
      name: "Monarda 'Elsie's Lavender'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3KY4V28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3KY4V28'
    },
    {
      status: 210,
      sku: 'PM0081713',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302680',
      rtl_batch_array: '6302680',
      name_search: 'MOFIREBA',
      name: "Monarda 'Fireball'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EP74VW5B;1P3HZJA1;7XVBPLP1;B1NSYV4S;LYCA9S45;HWYV8RR1;5HREJB6J;Z7RVCY7T;AA4ZACN5;HE5B66YW',
      statusContent: 'dot',
      hash: 'SCXKVSZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCXKVSZ9'
    },
    {
      status: 910,
      sku: 'PM0063698',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VEHPURPL',
      name: "Verbena 'Homestead Purple'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'Y9CJX76N',
      statusContent: 'dot',
      hash: 'WV64YY1B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WV64YY1B'
    },
    {
      status: 210,
      sku: 'PM0085244',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373137',
      rtl_batch_array: '6373137',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1381,
      chnn_stock_retail: 1381,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: '7A9VY11T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7A9VY11T'
    },
    {
      status: 210,
      sku: 'PM0078360',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353308',
      rtl_batch_array: '6353308',
      name_search: 'SEMJSAPP',
      name: "Sedum 'Mojave Jewels Sapphire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JW3992JR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JW3992JR'
    },
    {
      status: 210,
      sku: 'PM0081714',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302682',
      rtl_batch_array: '6302682',
      name_search: 'MOPDELIG',
      name: 'Monarda Petite Delight',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72LVKB8Y;PJP1A3RS;6647BYXX;HXD4WJRA;9JK5NC4J;H64149L6',
      statusContent: 'dot',
      hash: '1BJY3H51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BJY3H51'
    },
    {
      status: 210,
      sku: 'PM0081715',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302683',
      rtl_batch_array: '6302683',
      name_search: 'MOPLACE',
      name: "Monarda 'Pink Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 218,
      chnn_stock_retail: 218,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CVE7KLA9;DEC66SB1;ABGSZS8E;2JESWRYK;N64VVWWS;8LGN9ZZG;9YK53SAY',
      statusContent: 'dot',
      hash: 'KKXCEZJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKXCEZJ5'
    },
    {
      status: 210,
      sku: 'PM0081716',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302775',
      rtl_batch_array: '6302775',
      name_search: 'LYVRQUEE',
      name: "Lythrum virgatum 'Rose Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8CHH895',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8CHH895'
    },
    {
      status: 210,
      sku: 'PM0085245',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373138',
      rtl_batch_array: '6373138',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: 'WNCG932W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNCG932W'
    },
    {
      status: 210,
      sku: 'PM0081717',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302796',
      rtl_batch_array: '6302796',
      name_search: 'THPRCARP',
      name: "Thymus praecox 'Red Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '23Z47EER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23Z47EER'
    },
    {
      status: 210,
      sku: 'PM0039120',
      core_name: 'Plant',
      sppl_ean: '8720626313217',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871785',
      rtl_batch_array: '5871785',
      name_search: 'MOSCHNEE',
      name: "Monarda 'Schneewittchen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1009,
      chnn_stock_retail: 1009,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7AHL834A;KLAW589W',
      statusContent: 'dot',
      hash: 'JDNGG14H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDNGG14H'
    },
    {
      status: 210,
      sku: 'PM0064865',
      core_name: 'Plant',
      sppl_ean: '8720664890299',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149379',
      rtl_batch_array: '6149379',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'HJRGY94Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJRGY94Y'
    },
    {
      status: 210,
      sku: 'PM0078366',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386198',
      rtl_batch_array: '6386198',
      name_search: 'STOFFICI',
      name: 'Styrax officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1386,
      chnn_stock_retail: 1386,
      sppl_order_minimum: 3,
      sppl_prcp: 0.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PAN1PTGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAN1PTGJ'
    },
    {
      status: 210,
      sku: 'PM0081718',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302814',
      rtl_batch_array: '6302814',
      name_search: 'VEPDAMAS',
      name: "Veronica 'Pink Damask'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 248,
      chnn_stock_retail: 248,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DSL12HYT;SA5GTTR7',
      statusContent: 'dot',
      hash: '2Z6AJSTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Z6AJSTG'
    },
    {
      status: 210,
      sku: 'PM0078370',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353292',
      rtl_batch_array: '6353292',
      name_search: 'SECACT',
      name: "Sedum 'Class Act'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CHRC89HJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHRC89HJ'
    },
    {
      status: 210,
      sku: 'PM0068023',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6270454',
      rtl_batch_array: '6209208, 6270454, 6037582',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1700,
      chnn_stock_retail: 17041,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'SS72RGKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS72RGKA'
    },
    {
      status: 210,
      sku: 'PM0081719',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302824',
      rtl_batch_array: '6302824',
      name_search: 'SAVERTIC',
      name: 'Salvia verticillata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LD5T2HX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LD5T2HX2'
    },
    {
      status: 210,
      sku: 'PM0078375',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353514',
      rtl_batch_array: '6353514',
      name_search: 'TRFAGLOW',
      name: "Tricyrtis form. 'Autumn Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H9AL5WZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9AL5WZZ'
    },
    {
      status: 210,
      sku: 'PM0063704',
      core_name: 'Plant',
      sppl_ean: '8720664890121',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147929',
      rtl_batch_array: '6147929',
      name_search: 'VIHEDERA',
      name: 'Viola hederacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E6P6SCLY;6JHWA41V;SLA9Z91J;C5SYDTAN;6N46ECGV;EXR8E1ZK',
      statusContent: 'dot',
      hash: 'Z18ADGC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z18ADGC6'
    },
    {
      status: 210,
      sku: 'PM0078372',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353302',
      rtl_batch_array: '6353302',
      name_search: 'SELAJOS',
      name: "Sedum 'Lajos'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G8ZAEAWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8ZAEAWJ'
    },
    {
      status: 910,
      sku: 'PM0068029',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      rng_range_identifier: 'BALL\u00d8055H050055C20',
      rng_range_description: 'Ball \u00d8 55 cm H50 cm 55 cm C20',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'CN8DR88H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CN8DR88H'
    },
    {
      status: 210,
      sku: 'PM0081720',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302832',
      rtl_batch_array: '6302832',
      name_search: 'SCCOLUMB',
      name: 'Scabiosa columbaria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4VKBTRW',
      statusContent: 'dot',
      hash: 'E4HKT9X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4HKT9X3'
    },
    {
      status: 210,
      sku: 'PM0070098',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338381',
      rtl_batch_array: '6338381',
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1435,
      chnn_stock_retail: 1435,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV7A8VKK;2D7HYSVP;5E11VCT7;WEDN342H;6CBCVY9E;DRD2X2DT;5R8EASNG;TT28BTWY;2189SZS3;YZGT72RE;1BG4Z21C;RSHDGCEX;S1V6CXEZ;PW1TVV1A;27N88R7Y',
      statusContent: 'dot',
      hash: 'VDLYA733',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDLYA733'
    },
    {
      status: 210,
      sku: 'PM0071087',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6225713',
      rtl_batch_array: '6225713',
      name_search: 'LONHFIND',
      name: "Lonicera nitida 'Hohenheimer Findling'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6K49X8J3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6K49X8J3'
    },
    {
      status: 210,
      sku: 'PM0078373',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353307',
      rtl_batch_array: '6353307',
      name_search: 'SEMJRUBY',
      name: "Sedum 'Mojave Jewels Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1Y2EW6E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y2EW6E1'
    },
    {
      status: 210,
      sku: 'PM0065157',
      core_name: 'Plant',
      sppl_ean: '8720664866911',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161742',
      rtl_batch_array: '6229845, 6161742',
      name_search: 'HEPME',
      name: "Hemerocallis 'Pardon Me'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 1014,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P9GZ4H57;PARAEVYW',
      statusContent: 'dot',
      hash: 'N2LCXRPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2LCXRPP'
    },
    {
      status: 210,
      sku: 'PM0063718',
      core_name: 'Plant',
      sppl_ean: '8720664850958',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142519',
      rtl_batch_array: '6142519',
      name_search: 'AJRCGIAN',
      name: "Ajuga reptans 'Catlin's Giant'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ERAZC3L;Z6X1ACD1;XSTZ4E72;S4SBLT1R;4LXVY64Z;C5P71B7C;PX3EVE8J;RD7ET1G5',
      statusContent: 'dot',
      hash: 'RR3NLH1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR3NLH1R'
    },
    {
      status: 810,
      sku: 'PM0078374',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353513',
      rtl_batch_array: '6353513',
      name_search: 'TRCAROLI',
      name: 'Trautvetteria carolinensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVNCGGT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVNCGGT1'
    },
    {
      status: 210,
      sku: 'PM0081721',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349083',
      rtl_batch_array: '6349083, 6303630',
      name_search: 'PHFUJIYA',
      name: "Phlox (P) 'Fujiyama'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1008,
      chnn_stock_retail: 1257,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61AJJ5G6;7CXDLXVV;GZT4PVKG',
      statusContent: 'dot',
      hash: 'D7NZABX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7NZABX1'
    },
    {
      status: 210,
      sku: 'PM0081722',
      core_name: 'Plant',
      sppl_ean: '8720664547476',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5521428',
      rtl_batch_array: '5521428, 6352656',
      name_search: 'HESGUMDR',
      name: "Heuchera 'Silver Gumdrop'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 409,
      chnn_stock_retail: 786,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C5S66E1G;A3PD3X7D;G7KVX258',
      statusContent: 'dot',
      hash: '59ZCBZLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59ZCBZLY'
    },
    {
      status: 210,
      sku: 'PM0081723',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308552',
      rtl_batch_array: '6308552',
      name_search: 'ECSSANDI',
      name: 'Echinacea Sweet Sandia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 623,
      chnn_stock_retail: 623,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TX71ZND3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TX71ZND3'
    },
    {
      status: 210,
      sku: 'PM0078401',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308450',
      rtl_batch_array: '6302764, 6383374, 6308450',
      name_search: 'SAOTRICO',
      name: "Salvia officinalis 'Tricolor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1235,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XLDWN2H;7VK6YDGE;XRY9KBRB;DJ19A1LK;5Z3A9KJW;NV1C1LEK;9GT8AS65;TJCY7S4Y;V3DNCDS3;N6B83T21;N68LEDA5;AB7JDYH3;94RNWX5X;CCWX6D68;4A8EGX1G;PHBR2JGX;WNL2T3XW;SANZY739;HKLS561Y;X32SRKBN;XEERDPBV;DC8SZPB1',
      statusContent: 'dot',
      hash: 'VBTN567B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBTN567B'
    },
    {
      status: 210,
      sku: 'PM0085246',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373139',
      rtl_batch_array: '6373139',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2650,
      chnn_stock_retail: 2650,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: '9D2GC1P3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D2GC1P3'
    },
    {
      status: 210,
      sku: 'PM0085247',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373140',
      rtl_batch_array: '6373140',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2302,
      chnn_stock_retail: 2302,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'VSGGL9PG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSGGL9PG'
    },
    {
      status: 210,
      sku: 'PM0078382',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353547',
      rtl_batch_array: '6353547',
      name_search: 'VENWLIGH',
      name: "Vernonia noveboracensis 'White Lightning'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 313,
      chnn_stock_retail: 313,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GS3S12R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS3S12R5'
    },
    {
      status: 210,
      sku: 'PM0068022',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6336454',
      rtl_batch_array: '6353586, 6336454, 6356613',
      name_search: 'VIMAUREO',
      name: "Vinca minor 'Aureovariegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1933,
      chnn_stock_retail: 2479,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SA2TVNPH;HWT225EE;XA3RW4E1;ZD24ZXRA;9A4G1X46;2G74PVNE;7JRSXH8E;CEVL5BY4',
      statusContent: 'dot',
      hash: 'B88178CG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B88178CG'
    },
    {
      status: 810,
      sku: 'PM0071104',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6199470',
      rtl_batch_array: '6199470, 6310327',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 40,
      chnn_stock_retail: 100,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: '8E2PSR1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8E2PSR1Z'
    },
    {
      status: 210,
      sku: 'PM0078386',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353553',
      rtl_batch_array: '6353553',
      name_search: 'VEGMAIHI',
      name: "Veronica gent. 'Maihimmel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LYCV21SC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYCV21SC'
    },
    {
      status: 210,
      sku: 'PM0078387',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363580',
      rtl_batch_array: '6363580',
      name_search: 'VELIWANE',
      name: 'Veronica liwanensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2S1VCBZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S1VCBZL'
    },
    {
      status: 210,
      sku: 'PM0054803',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176235',
      rtl_batch_array: '6176235, 6349740',
      name_search: 'ALRCROSA',
      name: "Alcea rosea 'Charters Rosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 982,
      chnn_stock_retail: 1229,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '65ABL76T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65ABL76T'
    },
    {
      status: 910,
      sku: 'PM0039134',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECSBLANC',
      name: 'Echinacea Sombrero Blanco',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      statusContent: 'dot',
      hash: '8HEX6CSH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8HEX6CSH'
    },
    {
      status: 810,
      sku: 'PM0078392',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353566',
      rtl_batch_array: '6353566',
      name_search: 'VETPOOL',
      name: "Veronica 'Tidal Pool'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWVDLN1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWVDLN1N'
    },
    {
      status: 210,
      sku: 'PM0078381',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353532',
      rtl_batch_array: '6353532',
      name_search: 'TRPUMILU',
      name: 'Trollius pumilus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V9HAKG3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9HAKG3J'
    },
    {
      status: 210,
      sku: 'PM0078395',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353580',
      rtl_batch_array: '6353580',
      name_search: 'VIMBALCA',
      name: 'Vinca major balcanica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B39YS363',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B39YS363'
    },
    {
      status: 210,
      sku: 'PM0063716',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280774',
      rtl_batch_array: '6280774',
      name_search: 'COCYLADY',
      name: "Cotinus coggygria 'Young Lady'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 536,
      chnn_stock_retail: 536,
      sppl_prcp: 4.946,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EHKVAYDW;7D24RW6K;RY5BWZZT;6CXND4DH',
      statusContent: 'dot',
      hash: 'KHXV85LX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHXV85LX'
    },
    {
      status: 210,
      sku: 'PM0063689',
      core_name: 'Plant',
      sppl_ean: '8720664884458',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147880',
      rtl_batch_array: '6147880',
      name_search: 'SARBUMBL',
      name: "Salvia 'Royal Bumble'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NVWJS4W9',
      statusContent: 'dot',
      hash: '2RZ1NJYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RZ1NJYH'
    },
    {
      status: 210,
      sku: 'PM0068028',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317853',
      rtl_batch_array: '6263736, 6353131, 6317853',
      name_search: 'PEASUPER',
      name: "Persicaria affinis 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3816,
      chnn_stock_retail: 6403,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4RYX2WYB;KL9S5L21',
      statusContent: 'dot',
      hash: 'R4K4GPHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4K4GPHE'
    },
    {
      status: 210,
      sku: 'PM0078396',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353483',
      rtl_batch_array: '6353483',
      name_search: 'VIMELISA',
      name: "Vinca minor 'Elisa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 538,
      chnn_stock_retail: 538,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XY9BR2BS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XY9BR2BS'
    },
    {
      status: 910,
      sku: 'PM0071105',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      rng_range_identifier: 'H100125C4.5',
      rng_range_description: 'H100 cm 125 cm C4.5',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '9P8Z6PEJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9P8Z6PEJ'
    },
    {
      status: 210,
      sku: 'PM0078397',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336456',
      rtl_batch_array: '6336456',
      name_search: 'VIMRSHUG',
      name: "Vinca minor 'Ralph Shugert'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8356,
      chnn_stock_retail: 8356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3CB44HX;9TB6869P',
      statusContent: 'dot',
      hash: 'D7DD7SP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7DD7SP9'
    },
    {
      status: 910,
      sku: 'PM0078399',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THKIUSIA',
      name: 'Thalictrum kiusianum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'GSWY7J43;NBTH3C1W',
      statusContent: 'dot',
      hash: 'GT7G7XA8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GT7G7XA8'
    },
    {
      status: 210,
      sku: 'PM0063741',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253093',
      rtl_batch_array: '6253093',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 526,
      chnn_stock_retail: 526,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: 'SG954PVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG954PVA'
    },
    {
      status: 210,
      sku: 'PM0078402',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308454',
      rtl_batch_array: '6308454',
      name_search: 'SESMARAG',
      name: "Sempervivum 'Smaragd'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '26XCET2H;7KEDXPH7;X586T468;J77NWGDL;67KK8BLB;79XX2795',
      statusContent: 'dot',
      hash: 'JXZ23ZWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXZ23ZWS'
    },
    {
      status: 210,
      sku: 'PM0047991',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307575',
      rtl_batch_array: '6307575, 6287493',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 495,
      chnn_stock_retail: 513,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'VPT4DSLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPT4DSLC'
    },
    {
      status: 210,
      sku: 'PM0068032',
      core_name: 'Plant',
      sppl_ean: '8720349412310',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '5773653',
      rtl_batch_array: '6209138, 5773653',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 217,
      chnn_stock_retail: 667,
      sppl_prcp: 6.635,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'A373NLN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A373NLN9'
    },
    {
      status: 210,
      sku: 'PM0068033',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6195516',
      rtl_batch_array: '6195516',
      name_search: 'HEDCFFAV',
      name: "Hedera colchica 'Fall Favourite'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_prcp: 2.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHC76BAZ;HVLEN2ZV;PDVV9TW9;7WV2LS4E;BX8B2LWB;2RVLX4BK;TLDZB1ZL',
      statusContent: 'dot',
      hash: '6PWA9EVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PWA9EVL'
    },
    {
      status: 210,
      sku: 'PM0071086',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6225712',
      rtl_batch_array: '6225712, 6226096',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 18750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: '49VEVKJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49VEVKJV'
    },
    {
      status: 810,
      sku: 'PM0069221',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259388',
      rtl_batch_array: '6259388',
      name_search: 'ANHLGILM',
      name: "Anemone hybrida 'Lady Gilmour'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EB86WD42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB86WD42'
    },
    {
      status: 210,
      sku: 'PM0069244',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229856',
      rtl_batch_array: '6229856, 6268703',
      name_search: 'ASCAUDAT',
      name: 'Asarum caudatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 198,
      chnn_stock_retail: 1336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVTELAJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVTELAJN'
    },
    {
      status: 810,
      sku: 'PM0069248',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284299',
      rtl_batch_array: '6284299',
      name_search: 'ASNWESTL',
      name: "Astelia nervosa 'Westland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GBW2312R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBW2312R'
    },
    {
      status: 210,
      sku: 'PM0078405',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308465',
      rtl_batch_array: '6308465, 6339792, 6340058',
      name_search: 'COVGRAND',
      name: "Coreopsis verticillata 'Grandiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 2879,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RT36ZZ4X;AXZ628WW;L4JW9N3L;SRBGBDX1;5WR1P2NE;A5JBBL8S;5B514CDJ;8JB3XJ71;78L31H7E;BK7G8TPY;CX82G78P',
      statusContent: 'dot',
      hash: 'VSHX5BX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSHX5BX9'
    },
    {
      status: 210,
      sku: 'PM0065155',
      core_name: 'Plant',
      sppl_ean: '8720664866812',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161740',
      rtl_batch_array: '6161740',
      name_search: 'HELPEANU',
      name: "Hemerocallis 'Little Peanut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RVVXKS5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVVXKS5N'
    },
    {
      status: 210,
      sku: 'PM0039156',
      core_name: 'Plant',
      sppl_ean: '8720664699045',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871921',
      rtl_batch_array: '5871921',
      name_search: 'MAMOSCHA',
      name: 'Malva moschata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2T8VB364;TKG42WK6;RC7TX9KL',
      statusContent: 'dot',
      hash: 'D9WXTTZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9WXTTZ1'
    },
    {
      status: 210,
      sku: 'PM0069235',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6340053',
      rtl_batch_array: '6284291, 6340053, 6349045',
      name_search: 'AQCSTAR',
      name: "Aquilegia 'Crimson Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 511,
      chnn_stock_retail: 2023,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B98EC523;9EALZYKC;ZR4NVZ6L;6KDN28ER;V3GT2XAR;WLX3DGZN;8CPBZ9JP;HXXTHXTC;DCDJLYS1',
      statusContent: 'dot',
      hash: '2TPYPZZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TPYPZZD'
    },
    {
      status: 210,
      sku: 'PM0047339',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353346',
      rtl_batch_array: '6353346',
      name_search: 'SASKISS',
      name: "Salvia 'Snow Kiss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75JCNL5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75JCNL5N'
    },
    {
      status: 210,
      sku: 'PM0063730',
      core_name: 'Plant',
      sppl_ean: '8720664867048',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142536',
      rtl_batch_array: '6142536',
      name_search: 'HETBRIDE',
      name: "Hemerocallis 'The Bride'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3914,
      chnn_stock_retail: 3914,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9175Y64J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9175Y64J'
    },
    {
      status: 210,
      sku: 'PM0078394',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353577',
      rtl_batch_array: '6353577',
      name_search: 'VEVKERIK',
      name: "Veronicastrum virginicum 'Kleine Erika'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H5116X5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5116X5B'
    },
    {
      status: 210,
      sku: 'PM0069234',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349754',
      rtl_batch_array: '6300424, 6349754',
      name_search: 'AQBSTAR',
      name: "Aquilegia 'Blue Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BEJ5HL6E',
      statusContent: 'dot',
      hash: '9S6ASEVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S6ASEVZ'
    },
    {
      status: 210,
      sku: 'PM0078361',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353411',
      rtl_batch_array: '6353411',
      name_search: 'SESROSEN',
      name: "Sedum spectabile 'Rosenteller'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZV2YYJW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV2YYJW2'
    },
    {
      status: 910,
      sku: 'PM0078351',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ROCOULTE',
      name: 'Romneya coulteri',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'JVNDD83G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JVNDD83G'
    },
    {
      status: 210,
      sku: 'PM0063697',
      core_name: 'Plant',
      sppl_ean: '8720664889156',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147896',
      rtl_batch_array: '6147896',
      name_search: 'VENALBUM',
      name: 'Verbascum nigrum album',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y1813A13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1813A13'
    },
    {
      status: 210,
      sku: 'PM0078354',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6363200',
      rtl_batch_array: '6369748, 6353318, 6338957, 6363200',
      name_search: 'SANAMETH',
      name: "Salvia nemorosa 'Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 968,
      chnn_stock_retail: 3206,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WBYZZJNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBYZZJNY'
    },
    {
      status: 210,
      sku: 'PM0078355',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353320',
      rtl_batch_array: '6353320',
      name_search: 'SANBUMBL',
      name: "Salvia nemorosa 'Bumbleberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C5XXHT1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5XXHT1V'
    },
    {
      status: 210,
      sku: 'PM0078356',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353333',
      rtl_batch_array: '6353333',
      name_search: 'SANWESUW',
      name: "Salvia nemorosa 'Wesuwe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1006,
      chnn_stock_retail: 1006,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EZEH9Z8H;1ZWZGBWG',
      statusContent: 'dot',
      hash: 'W9WB1YWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9WB1YWP'
    },
    {
      status: 210,
      sku: 'PM0078357',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353281',
      rtl_batch_array: '6353281',
      name_search: 'SCSCOPAR',
      name: 'Schizachyrium scoparium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 484,
      chnn_stock_retail: 484,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHZ1L9T2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHZ1L9T2'
    },
    {
      status: 910,
      sku: 'PM0068034',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOREGEL',
      name: 'Ligustrum obtusifolium regelianum',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      statusContent: 'dot',
      hash: 'AZAL4PTX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AZAL4PTX'
    },
    {
      status: 210,
      sku: 'PM0078358',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315069',
      rtl_batch_array: '6315069',
      name_search: 'SCINCANA',
      name: 'Scutellaria incana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 357,
      chnn_stock_retail: 357,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VSA8Z65N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSA8Z65N'
    },
    {
      status: 910,
      sku: 'PM0078359',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RECARNEA',
      name: 'Reineckea carnea',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '9LEDYYZE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9LEDYYZE'
    },
    {
      status: 210,
      sku: 'PM0063699',
      core_name: 'Plant',
      sppl_ean: '8720664889279',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147900',
      rtl_batch_array: '6147900',
      name_search: 'VEMMERCI',
      name: "Veronica 'Madame Mercier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 374,
      chnn_stock_retail: 374,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '86PP9245',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86PP9245'
    },
    {
      status: 210,
      sku: 'PM0078362',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353446',
      rtl_batch_array: '6353446',
      name_search: 'SIAFLORI',
      name: "Silene acaulis 'Floribunda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GD4YV4S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GD4YV4S6'
    },
    {
      status: 210,
      sku: 'PM0078364',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353453',
      rtl_batch_array: '6353453',
      name_search: 'SOROANEN',
      name: 'Solidago roanensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HTVKV2AR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTVKV2AR'
    },
    {
      status: 210,
      sku: 'PM0078365',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302787',
      rtl_batch_array: '6302787, 6339959',
      name_search: 'SONUTANS',
      name: 'Sorghastrum nutans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WLR99R4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLR99R4E'
    },
    {
      status: 210,
      sku: 'PM0078367',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353469',
      rtl_batch_array: '6353469',
      name_search: 'STHOLOST',
      name: 'Stellaria holostea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 347,
      chnn_stock_retail: 347,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R9NDJSBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9NDJSBJ'
    },
    {
      status: 810,
      sku: 'PM0078350',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353181',
      rtl_batch_array: '6353181',
      name_search: 'ROPROTLA',
      name: "Rodgersia pinnata 'Rotlaub'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8SJX9G78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SJX9G78'
    },
    {
      status: 210,
      sku: 'PM0078369',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353373',
      rtl_batch_array: '6353373',
      name_search: 'THAMLFAV',
      name: "Thalictrum aquilegifolium 'My Little Favourite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1P17TPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1P17TPV'
    },
    {
      status: 210,
      sku: 'PM0078371',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353293',
      rtl_batch_array: '6353293',
      name_search: 'SECSACHA',
      name: "Sedum cyaneum 'Sachalin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TRAA4LR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TRAA4LR'
    },
    {
      status: 210,
      sku: 'PM0078376',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353515',
      rtl_batch_array: '6353515',
      name_search: 'TRBWONDE',
      name: "Tricyrtis 'Blue Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J17V7STJ',
      statusContent: 'dot',
      hash: 'ZAAHZVTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAAHZVTW'
    },
    {
      status: 210,
      sku: 'PM0078377',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353516',
      rtl_batch_array: '6353516',
      name_search: 'TREMPRES',
      name: "Tricyrtis 'Empress'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L8BY8JK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8BY8JK6'
    },
    {
      status: 810,
      sku: 'PM0078378',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352673',
      rtl_batch_array: '6353517, 6352673',
      name_search: 'TRFORMOS',
      name: 'Tricyrtis formosana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RZZVSJKL;4E72BD82;5WHPAHL3;V39DZXCK;G9RV5PXB;YADGKH8S;B9JVCAW4;JC3XCW7A;2WHG7RP4;W862N73X;5C4XK83A',
      statusContent: 'dot',
      hash: '1G4DZVPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G4DZVPP'
    },
    {
      status: 210,
      sku: 'PM0078379',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353523',
      rtl_batch_array: '6353523',
      name_search: 'TRTSILK',
      name: "Tricyrtis 'Taipei Silk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VSSX73L7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSSX73L7'
    },
    {
      status: 910,
      sku: 'PM0054798',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRIMMORT',
      name: "Iris (G) 'Immortality'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'W5RW2BCA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W5RW2BCA'
    },
    {
      status: 210,
      sku: 'PM0078383',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353549',
      rtl_batch_array: '6353549',
      name_search: 'VEAJACQU',
      name: "Veronica austriaca 'Jacqueline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JZ62VDVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ62VDVP'
    },
    {
      status: 210,
      sku: 'PM0078384',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353550',
      rtl_batch_array: '6353550',
      name_search: 'VEAKAPIT',
      name: "Veronica austriaca 'Kapit\u00e4n'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KKNPHRWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKNPHRWP'
    },
    {
      status: 210,
      sku: 'PM0078385',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353552',
      rtl_batch_array: '6353552',
      name_search: 'VEBINDIG',
      name: "Veronica 'Blue Indigo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 191,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '43883TLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43883TLC'
    },
    {
      status: 210,
      sku: 'PM0078388',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353557',
      rtl_batch_array: '6353557, 6319763',
      name_search: 'VELCHARL',
      name: "Veronica longifolia 'Charlotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 182,
      chnn_stock_retail: 382,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N8Z1BJE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8Z1BJE2'
    },
    {
      status: 210,
      sku: 'PM0078389',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353559',
      rtl_batch_array: '6353559',
      name_search: 'VELMWHIT',
      name: "Veronica longifolia 'Melanie White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1YLXBDC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YLXBDC3'
    },
    {
      status: 210,
      sku: 'PM0078390',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353563',
      rtl_batch_array: '6301904, 6353563',
      name_search: 'VESHEIDE',
      name: "Veronica spicata 'Heidekind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 984,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1PY65HHG;RL7NDDSW;L7X9V13X;GDP4HDHS;AL6JY118;D6DZPNDH;E9DZWCZZ;CZW42XAR;HC4HL6V2',
      statusContent: 'dot',
      hash: 'ZC133XVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZC133XVX'
    },
    {
      status: 210,
      sku: 'PM0078391',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353565',
      rtl_batch_array: '6353565',
      name_search: 'VEAAZURI',
      name: "Veronica austr. 'Azurit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VDJV5PNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDJV5PNJ'
    },
    {
      status: 210,
      sku: 'PM0078393',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353570',
      rtl_batch_array: '6353570',
      name_search: 'VEVAMETH',
      name: "Veronicastrum virginicum 'Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2LEZ31WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LEZ31WA'
    },
    {
      status: 210,
      sku: 'PM0065156',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350361',
      rtl_batch_array: '6350361',
      name_search: 'HEOMEYES',
      name: "Hemerocallis 'Open My Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 345,
      chnn_stock_retail: 345,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSKGVW1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSKGVW1S'
    },
    {
      status: 210,
      sku: 'PM0078398',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353388',
      rtl_batch_array: '6353388',
      name_search: 'THIPMARB',
      name: "Thalictrum ichangense 'Purple Marble'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X3HCDWJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3HCDWJJ'
    },
    {
      status: 210,
      sku: 'PM0078400',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308444',
      rtl_batch_array: '6370378, 6308444, 6308707, 6386150, 6333283',
      name_search: 'NERSNOWF',
      name: "Nepeta racemosa 'Snowflake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 7204,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLN4C3GT;P2ZL52LP',
      statusContent: 'dot',
      hash: 'EXDG33RH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXDG33RH'
    },
    {
      status: 210,
      sku: 'PM0047380',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329815',
      rtl_batch_array: '6329815',
      name_search: 'ACSSEDU',
      name: "Achillea hybrid 'Saucy Seduction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1S1SJ5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1S1SJ5G'
    },
    {
      status: 210,
      sku: 'PM0068068',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244379',
      rtl_batch_array: '6244379',
      name_search: 'JUHPWALE',
      name: "Juniperus horizontalis 'Prince of Wales'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 362,
      chnn_stock_retail: 362,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HV4G8K7R;4DDP56PC;6Z3RDN63;X1JSG9LP;PS853GCJ;7JRYEC16',
      statusContent: 'dot',
      hash: 'HY59HPJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HY59HPJJ'
    },
    {
      status: 210,
      sku: 'PM0085248',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373141',
      rtl_batch_array: '6373141',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 321,
      chnn_stock_retail: 321,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'CVYYNRCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVYYNRCS'
    },
    {
      status: 910,
      sku: 'PM0078441',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASSCOLOP',
      name: 'Asplenium scolopendrium',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'GVRCSWGV;1NPN4ZCZ;PYN2SS79;WKBGHRVT;PT1G7DBV;TPW2ZZNN;Z2XX16HY;T4VLSHXH;WA4YG64G;ASX1AEWY;VJKT6GYC;YLSRHAEZ',
      statusContent: 'dot',
      hash: 'YJ6N1EZE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YJ6N1EZE'
    },
    {
      status: 210,
      sku: 'PM0071099',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226108',
      rtl_batch_array: '6226108',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'HG66ZGWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HG66ZGWA'
    },
    {
      status: 210,
      sku: 'PM0085249',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373142',
      rtl_batch_array: '6373142',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 899,
      chnn_stock_retail: 899,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'P83Y3GN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P83Y3GN9'
    },
    {
      status: 210,
      sku: 'PM0068061',
      core_name: 'Plant',
      sppl_ean: '8720626346154',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5932391',
      rtl_batch_array: '5932391',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12083,
      chnn_stock_retail: 12083,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'JLNGSYHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLNGSYHG'
    },
    {
      status: 210,
      sku: 'PM0085250',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373143',
      rtl_batch_array: '6373143',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 898,
      chnn_stock_retail: 898,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: '67V997LZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67V997LZ'
    },
    {
      status: 210,
      sku: 'PM0081724',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308597',
      rtl_batch_array: '6308597',
      name_search: 'HOFPATRI',
      name: 'gebruik HOPATRIO',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1134,
      chnn_stock_retail: 1134,
      sppl_order_minimum: 3,
      sppl_prcp: 1.195,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4X2JH8AR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4X2JH8AR'
    },
    {
      status: 210,
      sku: 'PM0081725',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308629',
      rtl_batch_array: '6308629',
      name_search: 'ANFARIEL',
      name: 'Anemone Fantasy Ariel',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '572DZ8DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '572DZ8DP'
    },
    {
      status: 210,
      sku: 'PM0078410',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308544',
      rtl_batch_array: '6364219, 6308544',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1135,
      chnn_stock_retail: 1670,
      sppl_order_minimum: 3,
      sppl_prcp: 1.294,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: 'SHT2K9NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHT2K9NS'
    },
    {
      status: 210,
      sku: 'PM0071089',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267682',
      rtl_batch_array: '6267682',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 1495,
      chnn_stock_retail: 1495,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'A989Z9B6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A989Z9B6'
    },
    {
      status: 210,
      sku: 'PM0078419',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6329953',
      rtl_batch_array: '6329953, 6308589, 6352641',
      name_search: 'HEODEWHI',
      name: "Helleborus orientalis 'Double Ellen White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1109,
      chnn_stock_retail: 1434,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5BTJXN7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BTJXN7V'
    },
    {
      status: 210,
      sku: 'PM0078411',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308545',
      rtl_batch_array: '6308545',
      name_search: 'COFPBLUE',
      name: "Corydalis flexuosa 'Porcelain Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 615,
      chnn_stock_retail: 615,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CWEXTW27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWEXTW27'
    },
    {
      status: 810,
      sku: 'PM0078412',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308557',
      rtl_batch_array: '6308557',
      name_search: 'ECPGENVY',
      name: "Echinacea purpurea 'Green Envy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5VYS1E41',
      statusContent: 'dot',
      hash: '2Z56Y63G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Z56Y63G'
    },
    {
      status: 210,
      sku: 'PM0078413',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308558',
      rtl_batch_array: '6308558',
      name_search: 'ECPHSTAR',
      name: "Echinacea purpurea 'Happy Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2006,
      chnn_stock_retail: 2006,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SZ5ZNL1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZ5ZNL1X'
    },
    {
      status: 210,
      sku: 'PM0085251',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373144',
      rtl_batch_array: '6373144',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'ANH8DE3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANH8DE3T'
    },
    {
      status: 210,
      sku: 'PM0085252',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373145',
      rtl_batch_array: '6373145',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1501,
      chnn_stock_retail: 1501,
      sppl_prcp: 3.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: '7LRWTB76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LRWTB76'
    },
    {
      status: 210,
      sku: 'PM0078418',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308581',
      rtl_batch_array: '6308581, 6302549',
      name_search: 'HAMALBOS',
      name: "Hakonechloa macra 'Albostriata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 181,
      chnn_stock_retail: 519,
      sppl_order_minimum: 3,
      sppl_prcp: 1.541,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BXRY2ZAT;AACT5DEZ;5K61B5A3;B8CVCWZY;YGLTB2WN;T2KBAHJ1',
      statusContent: 'dot',
      hash: 'HSPX395D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSPX395D'
    },
    {
      status: 210,
      sku: 'PM0048077',
      core_name: 'Plant',
      sppl_ean: '8720349486472',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '5974742',
      rtl_batch_array: '5974742',
      name_search: 'LONDANIE',
      name: "Lonicera nitida 'Daniela'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DNZ7W2DV;DG55ZS7N',
      statusContent: 'dot',
      hash: 'YA9V51KB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YA9V51KB'
    },
    {
      status: 210,
      sku: 'PM0081726',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319686',
      rtl_batch_array: '6319686',
      name_search: 'ARSBSILV',
      name: "Artemisia stelleriana 'Boughton Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 399,
      chnn_stock_retail: 399,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DCEYNJW7',
      statusContent: 'dot',
      hash: 'JHT4G9BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHT4G9BE'
    },
    {
      status: 210,
      sku: 'PM0085253',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373146',
      rtl_batch_array: '6373146',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 466,
      chnn_stock_retail: 466,
      sppl_prcp: 6.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'AGXTARW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGXTARW2'
    },
    {
      status: 210,
      sku: 'PM0078455',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309012',
      rtl_batch_array: '6309012',
      name_search: 'PUVPVSHA',
      name: "Pulsatilla vulgaris 'Pulsar Violet Shades'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8340,
      chnn_stock_retail: 8340,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WT72EHPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WT72EHPR'
    },
    {
      status: 210,
      sku: 'PM0078426',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308660',
      rtl_batch_array: '6308660',
      name_search: 'BADCHOCO',
      name: "Baptisia 'Dark Chocolate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_order_minimum: 3,
      sppl_prcp: 2.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S4HWASGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4HWASGB'
    },
    {
      status: 210,
      sku: 'PM0078429',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308720',
      rtl_batch_array: '6308720',
      name_search: 'PEABSPRI',
      name: "Perovskia atriplicifolia 'Blue Spritzer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1113,
      chnn_stock_retail: 1113,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X35CGXS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X35CGXS7'
    },
    {
      status: 210,
      sku: 'PM0081727',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339116',
      rtl_batch_array: '6339116',
      name_search: 'PEAMETAL',
      name: "Pennisetum advena 'Metallica'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HG4C5TJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HG4C5TJ4'
    },
    {
      status: 210,
      sku: 'PM0078431',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308760',
      rtl_batch_array: '6308760',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'JWLGLDR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWLGLDR1'
    },
    {
      status: 210,
      sku: 'PM0078432',
      core_name: 'Plant',
      btch_manufacturer: 234,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308764',
      rtl_batch_array: '6308764',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '060STB50',
      rng_range_identifier: 'BALL\u00d8050STE060',
      rng_range_description: 'Ball \u00d8 50 cm Stem 60 cm',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 63.004,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'DKPXEHJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKPXEHJB'
    },
    {
      status: 210,
      sku: 'PM0078459',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302501',
      rtl_batch_array: '6369233, 6302501',
      name_search: 'ERGIGANT',
      name: 'Eryngium giganteum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAJNYLVY;KVVBNZHV',
      statusContent: 'dot',
      hash: 'WPALSZ55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPALSZ55'
    },
    {
      status: 810,
      sku: 'PM0071091',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226095',
      rtl_batch_array: '6226095',
      name_search: 'LEFRAINB',
      name: "Leucothoe fontanesiana 'Rainbow'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 6.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1H5P3ZSD;CCB5LRLZ;SJS31JLE;SE71TG9R;8LE8AELD;ZEZ3P78W;253XTPC7;48TWELTH;S87WRHT4;6BYV1J6X;K7HNJ6G5;HHP3PN72;2C8EJXR8;6H77HESP;5GX4KEH4;9CSW38ZJ;GJK122WL;HWHWALRR',
      statusContent: 'dot',
      hash: '6S7AX1C2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6S7AX1C2'
    },
    {
      status: 910,
      sku: 'PM0078433',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308783',
      rtl_batch_array: '6308783',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '5PSS7J5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PSS7J5A'
    },
    {
      status: 210,
      sku: 'PM0078434',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308784',
      rtl_batch_array: '6308784',
      name_search: 'ADPEDATU',
      name: 'Adiantum pedatum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 802,
      chnn_stock_retail: 802,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJ68TTYK;S89H2LH1;D4X4KHBR;ENRAN45D;K94LVZR3;X7RCE59N',
      statusContent: 'dot',
      hash: '4NBLDVS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NBLDVS7'
    },
    {
      status: 210,
      sku: 'PM0078435',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308786',
      rtl_batch_array: '6308786',
      name_search: 'DRACRIST',
      name: "Dryopteris affinis 'Cristata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 875,
      chnn_stock_retail: 875,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W672BRB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W672BRB2'
    },
    {
      status: 210,
      sku: 'PM0071106',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177380',
      rtl_batch_array: '6177380',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '100125C44',
      rng_range_identifier: 'H100125C4.5',
      rng_range_description: 'H100 cm 125 cm C4.5',
      sppl_stock_available: 418,
      chnn_stock_retail: 418,
      sppl_prcp: 4.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'HEZBY3NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEZBY3NE'
    },
    {
      status: 210,
      sku: 'PM0071107',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6246539',
      rtl_batch_array: '6311918, 6246539',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 470,
      chnn_stock_retail: 2970,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'L9N6TX7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9N6TX7P'
    },
    {
      status: 210,
      sku: 'PM0078436',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308787',
      rtl_batch_array: '6308787',
      name_search: 'DRWALLIC',
      name: 'Dryopteris wallichiana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 567,
      chnn_stock_retail: 567,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXBV7TR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXBV7TR1'
    },
    {
      status: 210,
      sku: 'PM0065181',
      core_name: 'Plant',
      sppl_ean: '8720664870239',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161793',
      rtl_batch_array: '6161793',
      name_search: 'ISLONGIT',
      name: 'Isodon longitubus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6BR73J3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6BR73J3'
    },
    {
      status: 210,
      sku: 'PM0081728',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339117',
      rtl_batch_array: '6339117',
      name_search: 'PESRUPEL',
      name: "Pennisetum advena 'Rupellii'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X53K2BDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X53K2BDC'
    },
    {
      status: 210,
      sku: 'PM0013570',
      core_name: 'Plant',
      sppl_ean: '8720664868113',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6091425',
      rtl_batch_array: '5364247, 6350526, 6091425, 6333265',
      name_search: 'HOELEGAN',
      name: "Hosta 'Elegans'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8000,
      chnn_stock_retail: 29167,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7YREELR;6RXH8E7C',
      statusContent: 'dot',
      hash: '293VPA51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '293VPA51'
    },
    {
      status: 910,
      sku: 'PM0063774',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307770',
      rtl_batch_array: '6307770',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '050060C74PY',
      rng_range_identifier: 'PYRAH050060C7.5',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 28.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'BC5HV8LG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BC5HV8LG'
    },
    {
      status: 210,
      sku: 'PM0078442',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308795',
      rtl_batch_array: '6308795',
      name_search: 'CATESTAC',
      name: 'Carex testacea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2188,
      chnn_stock_retail: 2188,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E6TWCJXL;P46ZLCXN;144GD2CN;S626GYJX;YLEP9K94;4S3TTRB9;J3V1YVXR',
      statusContent: 'dot',
      hash: 'G57BW8JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G57BW8JD'
    },
    {
      status: 210,
      sku: 'PM0078446',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308802',
      rtl_batch_array: '6308802',
      name_search: 'MAORIENT',
      name: 'Matteuccia orientalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 515,
      chnn_stock_retail: 515,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD36TGBK',
      statusContent: 'dot',
      hash: '9ZNEWWXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZNEWWXJ'
    },
    {
      status: 210,
      sku: 'PM0081729',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339118',
      rtl_batch_array: '6339118',
      name_search: 'PEARDWAR',
      name: "Pennisetum advena 'Rubrum Dwarf'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LAPXE9VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAPXE9VY'
    },
    {
      status: 210,
      sku: 'PM0063760',
      core_name: 'Plant',
      sppl_ean: '8720664884274',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6143859',
      rtl_batch_array: '6143859',
      name_search: 'SASSUPRE',
      name: "Sagina subulata 'Supreme'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3406,
      chnn_stock_retail: 3406,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K9WZLLP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9WZLLP6'
    },
    {
      status: 210,
      sku: 'PM0063757',
      core_name: 'Plant',
      sppl_ean: '8720664863910',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6143852',
      rtl_batch_array: '6143852',
      name_search: 'GAMYELLO',
      name: "Gaillardia 'Mesa Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7896,
      chnn_stock_retail: 7896,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '727ZPNDL;NXH77H9R',
      statusContent: 'dot',
      hash: 'XYDCLSZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYDCLSZZ'
    },
    {
      status: 810,
      sku: 'PM0071093',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226098',
      rtl_batch_array: '6226098',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: 'R69JY91J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R69JY91J'
    },
    {
      status: 810,
      sku: 'PM0081730',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6344086',
      rtl_batch_array: '6349578, 6344086',
      name_search: 'ASASONIA',
      name: "Aster amellus 'Sonia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWDVYCJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWDVYCJ9'
    },
    {
      status: 210,
      sku: 'PM0054846',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276624',
      rtl_batch_array: '6276624, 6258827',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 1299,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'CXCT8XGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXCT8XGT'
    },
    {
      status: 210,
      sku: 'PM0069259',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6290210',
      rtl_batch_array: '6229858, 6300434, 6290210',
      name_search: 'ASNAPDOM',
      name: "Aster novae-angliae 'Purple Dome'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7377,
      chnn_stock_retail: 10754,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7Z4SBCS;DR8AV5DN;11KTR8YZ;L4ZD9XX5;TCEV9KKA;N4KLGN9J;23CATXL3;VL2PN92C;2S8B425H;24BBLEWE',
      statusContent: 'dot',
      hash: '6EJPHVZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EJPHVZH'
    },
    {
      status: 210,
      sku: 'PM0068045',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6195732',
      rtl_batch_array: '6195732',
      name_search: 'PIGPERFE',
      name: 'Picea glauca Perfecta',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 301,
      chnn_stock_retail: 301,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NLERT41',
      statusContent: 'dot',
      hash: 'KRG443N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRG443N7'
    },
    {
      status: 210,
      sku: 'PM0068058',
      core_name: 'Plant',
      sppl_ean: '8720626346048',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837938',
      rtl_batch_array: '5837938',
      name_search: 'EUFRADIC',
      name: 'Euonymus fortunei radicans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4646,
      chnn_stock_retail: 4646,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9KDG79Z2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KDG79Z2'
    },
    {
      status: 210,
      sku: 'PM0063763',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353484',
      rtl_batch_array: '6353484',
      name_search: 'VIMILLUM',
      name: 'Vinca minor Illumination',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HBJ4J7S;LZ93WVDK;CYKHBWP1',
      statusContent: 'dot',
      hash: '7BG2SJ24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BG2SJ24'
    },
    {
      status: 210,
      sku: 'PM0078458',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349917',
      rtl_batch_array: '6370337, 6349917',
      name_search: 'CATESTAC',
      name: 'Carex testacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1110,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E6TWCJXL;P46ZLCXN;144GD2CN;S626GYJX;YLEP9K94;4S3TTRB9;J3V1YVXR',
      statusContent: 'dot',
      hash: 'YNV97AD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNV97AD2'
    },
    {
      status: 210,
      sku: 'PM0078460',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385620',
      rtl_batch_array: '6349310, 6309369, 6385620',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1050,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'KAEG6K3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAEG6K3T'
    },
    {
      status: 210,
      sku: 'PM0078461',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 4,
      btch_active_retail: '6385621',
      rtl_batch_array: '6346210, 6349311, 6309370, 6385621',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 900,
      chnn_stock_retail: 2950,
      sppl_prcp: 4.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'AY6NEJW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AY6NEJW7'
    },
    {
      status: 210,
      sku: 'PM0078462',
      core_name: 'Plant',
      btch_manufacturer: 1795,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309371',
      rtl_batch_array: '6309371',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'TXZAB3D6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXZAB3D6'
    },
    {
      status: 210,
      sku: 'PM0071097',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243065',
      rtl_batch_array: '6243065',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 1.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: '47L68AP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47L68AP5'
    },
    {
      status: 210,
      sku: 'PM0078463',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 4,
      btch_active_retail: '6385627',
      rtl_batch_array: '6346218, 6349312, 6309372, 6385627',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 2150,
      chnn_stock_retail: 3700,
      sppl_prcp: 5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'ZLRACVKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLRACVKC'
    },
    {
      status: 210,
      sku: 'PM0081731',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344096',
      rtl_batch_array: '6344096',
      name_search: 'GYPBFAIR',
      name: "Gypsophila paniculata 'Bristol Fairy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4LBYWWHR;1E9EXP7H;C57GYTC6;HBGYSNLP;EPDB7GZH;KH6RTJ5J;Y6RA1HN1;CE1WS82D;PPSX3ZH8',
      statusContent: 'dot',
      hash: 'K94C7RJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K94C7RJ6'
    },
    {
      status: 210,
      sku: 'PM0063756',
      core_name: 'Plant',
      sppl_ean: '8720664860148',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6143849',
      rtl_batch_array: '6143849',
      name_search: 'DIMSKING',
      name: "Digitalis mertonensis 'Summer King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KWT9EWAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWT9EWAS'
    },
    {
      status: 210,
      sku: 'PM0078447',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308804',
      rtl_batch_array: '6254796, 6308804',
      name_search: 'OSREGALI',
      name: 'Osmunda regalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1927,
      chnn_stock_retail: 3400,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6P27E8EE;1KYVGLT7;63YC456J;2R45XWGZ;BGW96VB1;5EKVGZZA;BS328D37;R2X5YBE3;1ASL4D56;P9G6B154',
      statusContent: 'dot',
      hash: 'P39A42ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P39A42ED'
    },
    {
      status: 210,
      sku: 'PM0071098',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243066',
      rtl_batch_array: '6243066',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1415,
      chnn_stock_retail: 1415,
      sppl_prcp: 1.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '43VYW66X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43VYW66X'
    },
    {
      status: 210,
      sku: 'PM0068062',
      core_name: 'Plant',
      sppl_ean: '8720626315501',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5932450',
      rtl_batch_array: '5932450',
      name_search: 'PHRUSSEL',
      name: 'Phlomis russeliana',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4097,
      chnn_stock_retail: 4097,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YP42PBWA;NCT5P9T1;63VD521Y;PRAP2EJX',
      statusContent: 'dot',
      hash: '2LR7NNNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LR7NNNH'
    },
    {
      status: 210,
      sku: 'PM0048095',
      core_name: 'Plant',
      sppl_ean: '8720664877733',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161883',
      rtl_batch_array: '6161883',
      name_search: 'PHOLJENK',
      name: "Phlox 'Oljenka'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SPRCEL2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPRCEL2D'
    },
    {
      status: 210,
      sku: 'PM0078443',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308798',
      rtl_batch_array: '6308798',
      name_search: 'PLPYGMAE',
      name: 'Pleioblastus pygmaeus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V7E3YDKZ;GJD889KS;J6D7YEY5;JJ1HYDY9;YTHBKKKK;4T7J9TJ8',
      statusContent: 'dot',
      hash: 'K9YW8SAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9YW8SAD'
    },
    {
      status: 210,
      sku: 'PM0078448',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308669',
      rtl_batch_array: '6302792, 6353376, 6308995, 6308669',
      name_search: 'THATHUND',
      name: "Thalictrum aquilegifolium 'Thundercloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 1708,
      sppl_order_minimum: 3,
      sppl_prcp: 1.541,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LJ5HTD9Y',
      statusContent: 'dot',
      hash: 'X3ZLL73J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3ZLL73J'
    },
    {
      status: 210,
      sku: 'PM0048082',
      core_name: 'Plant',
      sppl_ean: '8720664867390',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6113110',
      rtl_batch_array: '6161758, 6113110, 6217173',
      name_search: 'HEULMARM',
      name: "Heuchera 'Lime Marmalade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 2090,
      sppl_order_minimum: 3,
      sppl_prcp: 1.487,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2K6RAZ6B;5471CTLN;P1BJA3DV;ZJ41C743;XC6ES1PL;JS17Z8SG;CVWP95Y9;DCSZV8X5;SKWEW342;5JXZ9R5R;1AVRWHW1',
      statusContent: 'dot',
      hash: 'VK3BAYL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VK3BAYL4'
    },
    {
      status: 810,
      sku: 'PM0063742',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272833',
      rtl_batch_array: '6272833',
      name_search: 'HEFBGEM',
      name: "Hebe franciscana 'Blue Gem'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LZ2V3S6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ2V3S6X'
    },
    {
      status: 810,
      sku: 'PM0063747',
      core_name: 'Plant',
      sppl_ean: '8720664870338',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170287',
      rtl_batch_array: '6170287',
      name_search: 'JUGREGIA',
      name: 'Juglans regia',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J3RCV4EW;VYRDZSHN;CCN68RCY;XLXZ2KEG;EA292SLS;5WPSWWNH;216656X2;LLCX97DS;KB1V4E8L;V1ESJ956;P5GBHTWG;ZWZ776H1;DB4HPK1K;1PTTCH1L;N34V5ZG1;PVX29D93;8Z7WNV9L;96P3HJJ2;GDJGRNTW;Z8G85AG7',
      statusContent: 'dot',
      hash: 'V34514AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V34514AT'
    },
    {
      status: 210,
      sku: 'PM0081732',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345186',
      rtl_batch_array: '6345186',
      name_search: 'ANTSILBE',
      name: "Anaphalis triplinervis 'Silberregen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 577,
      chnn_stock_retail: 577,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WTCCRRRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTCCRRRS'
    },
    {
      status: 210,
      sku: 'PM0078420',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308611',
      rtl_batch_array: '6308611',
      name_search: 'ACWHITEW',
      name: "Acanthus 'Whitewater'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7PAHJA7;RSYN3K69;W18GVPHX;H72GJGTD;X8ETW892',
      statusContent: 'dot',
      hash: 'DVYC1GR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVYC1GR1'
    },
    {
      status: 210,
      sku: 'PM0078421',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308616',
      rtl_batch_array: '6308616',
      name_search: 'ACMPOMEG',
      name: "Achillea millefolium 'Pomegranate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 520,
      chnn_stock_retail: 520,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1BZAB1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1BZAB1R'
    },
    {
      status: 210,
      sku: 'PM0078422',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308618',
      rtl_batch_array: '6302317, 6308618',
      name_search: 'ACPNCOMP',
      name: "Achillea ptarmica 'Nana Compacta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 218,
      chnn_stock_retail: 524,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1V5AP4;JJ53KXJ7;12NB5KW3;TZ9NBJKE',
      statusContent: 'dot',
      hash: 'SHSXDNYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHSXDNYA'
    },
    {
      status: 810,
      sku: 'PM0078423',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308620',
      rtl_batch_array: '6308620, 6348267',
      name_search: 'ACRCORDI',
      name: 'Actaea racemosa cordifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 1014,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W2RA76T4;SKWTAJE5;R8AW2ZY2;NPBW26CN',
      statusContent: 'dot',
      hash: '9Y2JY788',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Y2JY788'
    },
    {
      status: 210,
      sku: 'PM0078453',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309009',
      rtl_batch_array: '6309009, 6319747',
      name_search: 'LYAVESUV',
      name: "Lychnis arkwrightii 'Vesuvius'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12017,
      chnn_stock_retail: 12242,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WKP4411W',
      statusContent: 'dot',
      hash: '6PLCWW3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PLCWW3X'
    },
    {
      status: 210,
      sku: 'PM0078456',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309013',
      rtl_batch_array: '6309013, 6348273',
      name_search: 'AGAFRICA',
      name: 'Agapanthus africanus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15084,
      chnn_stock_retail: 15602,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JSV2HX9P;LAVA9RSS;GCSCKNA9;WBCHVK77;PZA6ZRA1;WDXX8ZYB;BVPEJDPT;CYWSEJRN;SR86GZHG',
      statusContent: 'dot',
      hash: '5EAVZ8T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EAVZ8T8'
    },
    {
      status: 810,
      sku: 'PM0078424',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308654',
      rtl_batch_array: '6308654, 6333586',
      name_search: 'ASMRCLOU',
      name: "Astrantia major 'Ruby Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 428,
      sppl_order_minimum: 3,
      sppl_prcp: 1.294,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E3HB2H7',
      statusContent: 'dot',
      hash: '4RLDK23V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RLDK23V'
    },
    {
      status: 910,
      sku: 'PM0063778',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 2,
      btch_active_retail: '6239627',
      rtl_batch_array: '6239627, 6307639',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 3,
      chnn_stock_retail: 17,
      sppl_prcp: 23.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '1DXVDJS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DXVDJS6'
    },
    {
      status: 210,
      sku: 'PM0078425',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308655',
      rtl_batch_array: '6308655',
      name_search: 'ASMRWEDD',
      name: "Astrantia major 'Ruby Wedding'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1256,
      chnn_stock_retail: 1256,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4DWETSJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DWETSJG'
    },
    {
      status: 210,
      sku: 'PM0078457',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309019',
      rtl_batch_array: '6309019',
      name_search: 'BERCPURP',
      name: "Bergenia cordifolia 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 39143,
      chnn_stock_retail: 39143,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AY1634E6;EG9EX7YC;P1SSAX16;L571TZJE;H7R41VXY;2XHDLGBN;YJA3XGAV;18G9BHJ9',
      statusContent: 'dot',
      hash: 'LNR63B6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNR63B6R'
    },
    {
      status: 210,
      sku: 'PM0078408',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6329878',
      rtl_batch_array: '6329878, 6308530, 6352623',
      name_search: 'BRMEMIST',
      name: "Brunnera macrophylla 'Emerald Mist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 886,
      chnn_stock_retail: 1214,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '43JNBXVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43JNBXVY'
    },
    {
      status: 210,
      sku: 'PM0063590',
      core_name: 'Plant',
      sppl_ean: '8720664859746',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147618',
      rtl_batch_array: '6147618',
      name_search: 'DIALMAID',
      name: "Dianthus arenarius 'Little Maiden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 221,
      chnn_stock_retail: 221,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X362E7GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X362E7GH'
    },
    {
      status: 210,
      sku: 'PM0069257',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301439',
      rtl_batch_array: '6300431, 6301439',
      name_search: 'ASNAAAPO',
      name: "Aster novae-angliae 'Andenken an Alma P\u00f6tschke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 507,
      chnn_stock_retail: 1658,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9XBZHGVN;YYT4D2KP;PVDDG6RN;GD3TJW47;W9N18LYA;9AANEWY8;7YN2P59S;B22GGBZ9;RSEWSRVE',
      statusContent: 'dot',
      hash: '842SS3HS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '842SS3HS'
    },
    {
      status: 210,
      sku: 'PM0078414',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308560',
      rtl_batch_array: '6308560',
      name_search: 'ECPIRRES',
      name: "Echinacea purpurea 'Irrestistible'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 742,
      chnn_stock_retail: 742,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANP7C9AJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANP7C9AJ'
    },
    {
      status: 210,
      sku: 'PM0048026',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350477',
      rtl_batch_array: '6301652, 6350477',
      name_search: 'HEPWHISP',
      name: 'Heucherella Pink Whispers',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E8N1WLW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8N1WLW9'
    },
    {
      status: 210,
      sku: 'PM0063781',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329898',
      rtl_batch_array: '6329898',
      name_search: 'CLMRBRYD',
      name: "Clematis 'Mrs Robert Brydon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NZY6S8NT;9K25B6NT;JVRA7V9H',
      statusContent: 'dot',
      hash: 'KNYLKRB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNYLKRB6'
    },
    {
      status: 210,
      sku: 'PM0078415',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308561',
      rtl_batch_array: '6308561, 6320860',
      name_search: 'ECPJSROH',
      name: "Echinacea purpurea 'JSROHO'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 611,
      chnn_stock_retail: 991,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RV6D2G7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV6D2G7K'
    },
    {
      status: 210,
      sku: 'PM0071094',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226101',
      rtl_batch_array: '6226101',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 374,
      chnn_stock_retail: 374,
      sppl_prcp: 1.382,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'ADZYPT69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADZYPT69'
    },
    {
      status: 210,
      sku: 'PM0071096',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243064',
      rtl_batch_array: '6243064',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: 'GAATHWJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAATHWJ9'
    },
    {
      status: 210,
      sku: 'PM0068053',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350069',
      rtl_batch_array: '6295536, 6350069',
      name_search: 'ECPPDDEL',
      name: "Echinacea purpurea 'Pink Double Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 834,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TH5PYT17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH5PYT17'
    },
    {
      status: 210,
      sku: 'PM0064888',
      core_name: 'Plant',
      sppl_ean: '8720353019284',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420610',
      rtl_batch_array: '5420610',
      name_search: 'CLMAYLEE',
      name: "Clematis 'Mayleen'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 451,
      chnn_stock_retail: 451,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '49BSDVK6;JBZH1GCG;4HS1SL2Z;ZC47WV66;PCNXCJJ4;XTG8D194;9PS6X6S3;745RCDAH;9XX3PK1X;LHL95ZG4;YYD2BT52;44EYZZ61;KEC99YA6;YPDPT9Y7',
      statusContent: 'dot',
      hash: 'TNX41APH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNX41APH'
    },
    {
      status: 210,
      sku: 'PM0048087',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244284',
      rtl_batch_array: '6244284, 6205798',
      name_search: 'GALRJANE',
      name: 'Gaura lindheimeri Rosy Jane',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 565,
      chnn_stock_retail: 1079,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YG81187X',
      statusContent: 'dot',
      hash: 'CE9C4KAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE9C4KAY'
    },
    {
      status: 210,
      sku: 'PM0078406',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308470',
      rtl_batch_array: '6308470',
      name_search: 'FRLIPSTI',
      name: 'Fragaria Lipstick',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J212ZZBW',
      statusContent: 'dot',
      hash: 'XCRKE4L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCRKE4L2'
    },
    {
      status: 210,
      sku: 'PM0054650',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307558',
      rtl_batch_array: '6307558',
      name_search: 'MARICKI',
      name: "Magnolia 'Ricki'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_prcp: 12.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CP52LB7;4RPRR9Z6;831RHC8G;T2J9ENW2',
      statusContent: 'dot',
      hash: '6DXGEBPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DXGEBPZ'
    },
    {
      status: 210,
      sku: 'PM0078407',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308478',
      rtl_batch_array: '6308478, 6319707',
      name_search: 'HELPINK',
      name: "Helianthemum 'Lawrenson's Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8HCCWPYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HCCWPYB'
    },
    {
      status: 210,
      sku: 'PM0065170',
      core_name: 'Plant',
      sppl_ean: '8720664866294',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161773',
      rtl_batch_array: '6161773',
      name_search: 'HESTOPLI',
      name: "Helianthemum 'Stoplight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1152,
      chnn_stock_retail: 1152,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6R71D6VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6R71D6VD'
    },
    {
      status: 210,
      sku: 'PM0078452',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309008',
      rtl_batch_array: '6309008',
      name_search: 'LESAWHIT',
      name: "Leontopodium souliei 'Alpine White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2244,
      chnn_stock_retail: 2244,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KPXR7SW4;ESYEXREY;S29GXEK1;YV47R2A6;RYVEXL2W;7CDWGERX;EEEW9SVX;294WK6EY;GCSPN793',
      statusContent: 'dot',
      hash: '51TL9AWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51TL9AWK'
    },
    {
      status: 210,
      sku: 'PM0065180',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 2,
      btch_active_retail: '6193635',
      rtl_batch_array: '6161792, 6193635',
      name_search: 'IRSNQWHI',
      name: "Iris sibirica 'Not Quite White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LEZHJ8CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEZHJ8CT'
    },
    {
      status: 210,
      sku: 'PM0078454',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309011',
      rtl_batch_array: '6309011',
      name_search: 'PHLOPFLA',
      name: 'Phlox (P) Pink Flame',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5135,
      chnn_stock_retail: 5135,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4ZEG8KN;G8AYGC9P;PH6R9T48;CTT5KNGA;K8GDXK2C;25D5KXP8;YLNR8VVY;DDKN9731',
      statusContent: 'dot',
      hash: 'L1DV8SBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1DV8SBT'
    },
    {
      status: 810,
      sku: 'PM0078427',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6320897',
      rtl_batch_array: '6353385, 6308668, 6320897',
      name_search: 'THELIN',
      name: "Thalictrum 'Elin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 73,
      chnn_stock_retail: 1457,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PGNTR1L3',
      statusContent: 'dot',
      hash: '9N4B4E5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N4B4E5A'
    },
    {
      status: 810,
      sku: 'PM0065161',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252867',
      rtl_batch_array: '6252867',
      name_search: 'HEWDRAGO',
      name: "Hemerocallis 'Water Dragon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6PAW7NX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PAW7NX5'
    },
    {
      status: 210,
      sku: 'PM0068056',
      core_name: 'Plant',
      sppl_ean: '8720664808478',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '5817888',
      rtl_batch_array: '5817888',
      name_search: 'SEGJESTE',
      name: "Sempervivum 'Gay Jester'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BX2GEXBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX2GEXBE'
    },
    {
      status: 210,
      sku: 'PM0078437',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308788',
      rtl_batch_array: '6308788',
      name_search: 'ERGIGANT',
      name: 'Eryngium giganteum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 633,
      chnn_stock_retail: 633,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAJNYLVY;KVVBNZHV',
      statusContent: 'dot',
      hash: 'T9K5LBB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9K5LBB1'
    },
    {
      status: 210,
      sku: 'PM0078428',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324934',
      rtl_batch_array: '6324934',
      name_search: 'TICOAKLE',
      name: "Tiarella cordifolia 'Oakleaf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6WB1XS3;V78NEZW8;SSS53C1Y;A9YS728B;RKLSSP9R',
      statusContent: 'dot',
      hash: 'J19XYVGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J19XYVGW'
    },
    {
      status: 810,
      sku: 'PM0078430',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308731',
      rtl_batch_array: '6308731',
      name_search: 'POHSCENT',
      name: "Polemonium 'Heaven Scent'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7VGERHLZ;DXRPXS3A;HHGVT7RJ;L98C56C8;Z5CW7JWE;2ZKKE54J;725RAB9W;8ZENPHYE',
      statusContent: 'dot',
      hash: 'H5R43GCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5R43GCV'
    },
    {
      status: 210,
      sku: 'PM0078449',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308996',
      rtl_batch_array: '6308996',
      name_search: 'TRKARMIN',
      name: "Tradescantia (A) 'Karminglut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S261PCW1',
      statusContent: 'dot',
      hash: 'P4AZP2HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4AZP2HH'
    },
    {
      status: 210,
      sku: 'PM0078450',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309000',
      rtl_batch_array: '6309000',
      name_search: 'HESBREEZ',
      name: "Hemerocallis 'Sandy Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2671,
      chnn_stock_retail: 2671,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YDTR53JC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDTR53JC'
    },
    {
      status: 210,
      sku: 'PM0078438',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308789',
      rtl_batch_array: '6308789',
      name_search: 'GALWDOVE',
      name: "Gaura lindheimeri 'White Dove'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2394,
      chnn_stock_retail: 2394,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '937X6RXB',
      statusContent: 'dot',
      hash: 'ETCGPW6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETCGPW6T'
    },
    {
      status: 210,
      sku: 'PM0078439',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308791',
      rtl_batch_array: '6308791',
      name_search: 'GECKARMI',
      name: "Geranium cantabrigiense 'Karmina'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KRT57258;81LT3WDN;7D1LEZY6;7HXGSVVT;VGN9WECW;DJEHPDDB;TH816VLC;THTTYTV4;LPP74BZD;G3BA1HJE;Y8YKSBL8;TSL8Y4Y5;P4436YKA;JZ2J8ABD;RE6JAAS2;DX5JBD8V;JLKYLH44;2418B6PV;ZCA8SV68;HT399TP4',
      statusContent: 'dot',
      hash: 'E7ZGKNNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7ZGKNNT'
    },
    {
      status: 210,
      sku: 'PM0078440',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308792',
      rtl_batch_array: '6308792',
      name_search: 'GECSOLA',
      name: "Geranium cantabrigiense 'St Ola'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2355,
      chnn_stock_retail: 2355,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4NEZK75',
      statusContent: 'dot',
      hash: 'TKANK6X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKANK6X3'
    },
    {
      status: 210,
      sku: 'PM0078444',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308800',
      rtl_batch_array: '6252631, 6308800',
      name_search: 'HESLEUCH',
      name: "Heuchera sanguinea 'Leuchtk\u00e4fer'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2007,
      chnn_stock_retail: 2793,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1844X8AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1844X8AE'
    },
    {
      status: 210,
      sku: 'PM0078445',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308801',
      rtl_batch_array: '6308801',
      name_search: 'KNRCASTL',
      name: "Kniphofia 'Royal Castle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2824,
      chnn_stock_retail: 2824,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6GXE577J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GXE577J'
    },
    {
      status: 210,
      sku: 'PM0071095',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243062',
      rtl_batch_array: '6243062',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_order_minimum: 5,
      sppl_prcp: 1.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'T9YHN5D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9YHN5D9'
    },
    {
      status: 210,
      sku: 'PM0081733',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345194',
      rtl_batch_array: '6345194',
      name_search: 'PEAINVER',
      name: "Persicaria amplexicaulis 'Inverleith'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1147,
      chnn_stock_retail: 1147,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZX7AB9G',
      statusContent: 'dot',
      hash: 'VJVGGJXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJVGGJXZ'
    },
    {
      status: 810,
      sku: 'PM0054867',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280898',
      rtl_batch_array: '6280898, 6294794',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 62,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: 'NGPHV5GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGPHV5GK'
    },
    {
      status: 210,
      sku: 'PM0081734',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345200',
      rtl_batch_array: '6345200',
      name_search: 'PHLCLOUD',
      name: "Phlox (D) 'Lilac Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LLZP7CNB;LKCE3TBJ',
      statusContent: 'dot',
      hash: 'ZWV7EBXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWV7EBXR'
    },
    {
      status: 210,
      sku: 'PM0078464',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 5,
      btch_active_retail: '6346219',
      rtl_batch_array: '6346219, 6354763, 6349313, 6309373, 6287545',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 4600,
      sppl_prcp: 7.052,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'SSD5CBBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSD5CBBA'
    },
    {
      status: 210,
      sku: 'PM0039484',
      core_name: 'Plant',
      sppl_ean: '8720664686007',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5891070',
      rtl_batch_array: '5891070',
      name_search: 'COSROSEA',
      name: "Cortaderia selloana 'Rosea'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 454,
      chnn_stock_retail: 454,
      sppl_prcp: 5.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25X4TCHJ;27YWYHL2;Y7GGS9RN;A1CBSRN5;82XSDGPX;G2Y373YL;WG33R43S;H1AWYZBV',
      imageBatch: 'GDJV69VD',
      statusContent: 'dot',
      hash: 'PEP2T4RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEP2T4RJ'
    },
    {
      status: 210,
      sku: 'PM0039466',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353273',
      rtl_batch_array: '6353273',
      name_search: 'RHFAIRYT',
      name: 'Rhodoxis Fairytale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1952,
      chnn_stock_retail: 1952,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TZTXAVKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZTXAVKB'
    },
    {
      status: 810,
      sku: 'PM0081735',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345205',
      rtl_batch_array: '6345205',
      name_search: 'PRGIRUBY',
      name: "Prunella grandiflora 'Inshriach Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BADWLYEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BADWLYEE'
    },
    {
      status: 210,
      sku: 'PM0069278',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6259399',
      rtl_batch_array: '6259399, 6308653, 6338928',
      name_search: 'ASMROMA',
      name: "Astrantia major 'Roma'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5860,
      chnn_stock_retail: 9658,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VLA9SDR2;4B7Z6T2W;W19K5T3K;9NN1845H;52HLJGZ6;A9ZBVAR6;D3K5TW7R;9LZ56S2Z;G65KPBBX;AGY1EDH4;8T78RYHH',
      statusContent: 'dot',
      hash: 'PZLZS6R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZLZS6R7'
    },
    {
      status: 210,
      sku: 'PM0081736',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345206',
      rtl_batch_array: '6345206',
      name_search: 'PRWGISER',
      name: "Prunella webbiana 'Gruss Aus Isernhagen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NZPY6K4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NZPY6K4'
    },
    {
      status: 210,
      sku: 'PM0078465',
      core_name: 'Plant',
      btch_manufacturer: 1795,
      rtl_batch_array_total: 4,
      btch_active_retail: '6309374',
      rtl_batch_array: '6349315, 6383597, 6309374, 6358846',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 481,
      chnn_stock_retail: 881,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'HGCAS41C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGCAS41C'
    },
    {
      status: 210,
      sku: 'PM0078466',
      core_name: 'Plant',
      btch_manufacturer: 1795,
      rtl_batch_array_total: 3,
      btch_active_retail: '6309375',
      rtl_batch_array: '6373842, 6349316, 6309375',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 245,
      chnn_stock_retail: 395,
      sppl_prcp: 15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'RHWLBZ3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHWLBZ3H'
    },
    {
      status: 210,
      sku: 'PM0068071',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 1,
      btch_active_retail: '3453745',
      rtl_batch_array: '3453745',
      name_search: 'TOCARPET',
      name: 'TOPBUXUS Carpet',
      sppl_size_code: 'ZAK70L5',
      rng_range_identifier: 'BAG70LTR',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.448,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DC1XCD8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC1XCD8K'
    },
    {
      status: 810,
      sku: 'PM0068075',
      core_name: 'Garden',
      btch_manufacturer: 143198,
      rtl_batch_array_total: 1,
      btch_active_retail: '4788373',
      rtl_batch_array: '4788373',
      name_search: 'PHCMBVG',
      name: 'PHC Multi Bio Vin granular 20kg / 250 m2',
      sppl_size_code: '20KGZAK',
      rng_range_identifier: 'BAG20KG',
      rng_range_description: 'Bag 20 Kilogram',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 17.92,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'R88X4YAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'R88X4YAB'
    },
    {
      status: 210,
      sku: 'PM0068078',
      core_name: 'Plant',
      sppl_ean: '8720349465026',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 1,
      btch_active_retail: '4856796',
      rtl_batch_array: '4856796',
      name_search: 'TOMOTTEN',
      name: 'TOPBUXUS Mottenval 2 spuitjes',
      sppl_size_code: 'DOOS',
      rng_range_identifier: 'BOX',
      rng_range_description: 'Box',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 14.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'PBENZPNZ',
      statusContent: 'dot',
      hash: '2PXB131B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PXB131B'
    },
    {
      status: 910,
      sku: 'PM0068070',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 2,
      btch_active_retail: '6286709',
      rtl_batch_array: '3453743, 6286709',
      name_search: 'TOGROW',
      name: 'TOPBUXUS Grow',
      sppl_size_code: 'GREMMER20K4',
      rng_range_identifier: 'BUC20KG',
      sppl_stock_available: 4,
      chnn_stock_retail: 487,
      sppl_prcp: 31.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HG2XZ7RS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HG2XZ7RS'
    },
    {
      status: 910,
      sku: 'PM0068076',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 3,
      btch_active_retail: '6198970',
      rtl_batch_array: '4856344, 6198970, 6249543',
      name_search: 'TOHMIX',
      name: 'TOPBUXUS Health Mix',
      sppl_size_code: 'EMMER40T10',
      rng_range_identifier: 'BUC40TAB',
      sppl_stock_available: 3,
      chnn_stock_retail: 994,
      sppl_prcp: 19.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KG3R3L7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG3R3L7E'
    },
    {
      status: 810,
      sku: 'PM0068081',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 1,
      btch_active_retail: '5310634',
      rtl_batch_array: '5310634',
      name_search: 'TOHMIX',
      name: 'TOPBUXUS Health Mix',
      sppl_size_code: 'GREMMER800T',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SYT4B9J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYT4B9J8'
    },
    {
      status: 910,
      sku: 'PM0068072',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 3,
      btch_active_retail: '6198971',
      rtl_batch_array: '3459801, 6198971, 6249544',
      name_search: 'TOHMIX',
      name: 'TOPBUXUS Health Mix',
      sppl_size_code: 'EMMER100T10',
      rng_range_identifier: 'BUC100TAB',
      sppl_stock_available: 2,
      chnn_stock_retail: 488,
      sppl_prcp: 41.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8J9JXTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8J9JXTD'
    },
    {
      status: 210,
      sku: 'PM0081737',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345208',
      rtl_batch_array: '6345208',
      name_search: 'SABNOTE',
      name: "Salvia 'Blue Note'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9YVX4HSB;1DY93749;G655D4AS;9LW753CY;PSTWB2RP;8E1B82ZX',
      statusContent: 'dot',
      hash: 'RGRR3N7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGRR3N7L'
    },
    {
      status: 810,
      sku: 'PM0068077',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 2,
      btch_active_retail: '6233677',
      rtl_batch_array: '4856637, 6233677',
      name_search: 'TOXTARI',
      name: 'TOPBUXUS Xen Tari',
      sppl_size_code: 'DSJ5X3G',
      rng_range_identifier: 'BOX5',
      sppl_stock_available: 41,
      chnn_stock_retail: 961,
      sppl_prcp: 10.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCBR4ZYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCBR4ZYH'
    },
    {
      status: 910,
      sku: 'PM0064896',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLMARIB',
      name: "Prunus laurocerasus 'Mariblon'",
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      statusContent: 'dot',
      hash: 'R11P3758',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R11P3758'
    },
    {
      status: 210,
      sku: 'PM0039491',
      core_name: 'Plant',
      sppl_ean: '8720349435814',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5892541',
      rtl_batch_array: '5592191, 5892541',
      name_search: 'HEFHALS',
      name: "Hemerocallis 'Frans Hals'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 793,
      chnn_stock_retail: 1793,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XT1ENN5V;7LX1P1RV;D4G9AGKT;TSJSJT88;WRALV73B;J1NCBABH',
      imageBatch: 'SN1BL1X2',
      statusContent: 'dot',
      hash: '4BHNBV1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BHNBV1Y'
    },
    {
      status: 810,
      sku: 'PM0081738',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345209',
      rtl_batch_array: '6345209',
      name_search: 'SERBSPRU',
      name: "Sedum reflexum 'Blue Spruce'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LNCYRZR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNCYRZR6'
    },
    {
      status: 210,
      sku: 'PM0063793',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220902',
      rtl_batch_array: '6220902, 6164437, 6301871',
      name_search: 'TEGRUBRA',
      name: "Tellima grandiflora 'Rubra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 1411,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A64RPC7P',
      statusContent: 'dot',
      hash: '5SGEPLY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SGEPLY6'
    },
    {
      status: 210,
      sku: 'PM0048143',
      core_name: 'Plant',
      sppl_ean: '8720349474127',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014388',
      rtl_batch_array: '6014388',
      name_search: 'ALAFOREL',
      name: "Allium ameth. 'Forelock'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NT4C9HHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NT4C9HHW'
    },
    {
      status: 810,
      sku: 'PM0048131',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321868',
      rtl_batch_array: '6321868',
      name_search: 'AJRMAHOG',
      name: "Ajuga reptans 'Mahogany'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HHVJBTZN;DWSEERY5;KJWSS7X4;2GCXSYSX;KDAYBBRP',
      statusContent: 'dot',
      hash: '7K67SLR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7K67SLR7'
    },
    {
      status: 910,
      sku: 'PM0068069',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 2,
      btch_active_retail: '6198967',
      rtl_batch_array: '3453742, 6198967',
      name_search: 'TOGROW',
      name: 'TOPBUXUS Grow',
      sppl_size_code: 'EMMER5K100',
      rng_range_identifier: 'BUC5KG',
      sppl_stock_available: 4,
      chnn_stock_retail: 994,
      sppl_prcp: 10.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZH2C1JBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZH2C1JBX'
    },
    {
      status: 210,
      sku: 'PM0048150',
      core_name: 'Plant',
      sppl_ean: '8720664800359',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014397',
      rtl_batch_array: '6368805, 6014397',
      name_search: 'PABARTZE',
      name: "Paeonia (I) 'Bartzella'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 449,
      chnn_stock_retail: 1449,
      sppl_order_minimum: 3,
      sppl_prcp: 4.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BBKRW3XN;GPADPRYN;2S79RNDZ;W1VPE3B5',
      statusContent: 'dot',
      hash: 'DX23BPNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX23BPNX'
    },
    {
      status: 210,
      sku: 'PM0081739',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345210',
      rtl_batch_array: '6345210',
      name_search: 'THPULEGI',
      name: 'Thymus pulegioides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6T3CLAXR;71T98ZE8',
      statusContent: 'dot',
      hash: 'EE46SY78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE46SY78'
    },
    {
      status: 210,
      sku: 'PM0081740',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345211',
      rtl_batch_array: '6345211',
      name_search: 'TRDFORM',
      name: "Tricyrtis 'Dark Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NBBWRCHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBBWRCHW'
    },
    {
      status: 210,
      sku: 'PM0069271',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220658',
      rtl_batch_array: '6229859, 6220658',
      name_search: 'ASAMETHY',
      name: "Astilbe (A) 'Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 324,
      chnn_stock_retail: 550,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RA8Z12KR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA8Z12KR'
    },
    {
      status: 210,
      sku: 'PM0069272',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229871',
      rtl_batch_array: '6229871',
      name_search: 'ASGPURPU',
      name: "Astilbe (A) 'Gloria Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KDCWJ65B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDCWJ65B'
    },
    {
      status: 810,
      sku: 'PM0069285',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6338932',
      rtl_batch_array: '6300469, 6308659, 6338932',
      name_search: 'BABSUNDA',
      name: "Baptisia 'Blueberry Sundae'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 775,
      sppl_order_minimum: 3,
      sppl_prcp: 2.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9PH6SP2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PH6SP2E'
    },
    {
      status: 210,
      sku: 'PM0081741',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345215',
      rtl_batch_array: '6345215',
      name_search: 'VEAUSTRI',
      name: 'Veronica austriaca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HNYK3LN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HNYK3LN'
    },
    {
      status: 210,
      sku: 'PM0081742',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6383386',
      rtl_batch_array: '6383386, 6386184, 6345222',
      name_search: 'SANSCHWE',
      name: "Salvia nemorosa 'Schwellenburg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5064,
      chnn_stock_retail: 5751,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PG311CJ',
      statusContent: 'dot',
      hash: 'RLKK9G5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLKK9G5X'
    },
    {
      status: 210,
      sku: 'PM0081743',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345225',
      rtl_batch_array: '6345225',
      name_search: 'SCSCAIRO',
      name: "Schizachyrium scoparium 'Ca\u00efro'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N6BJPJXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6BJPJXH'
    },
    {
      status: 810,
      sku: 'PM0081744',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6371218',
      rtl_batch_array: '6371218, 6345226',
      name_search: 'SCSWWEST',
      name: "Schizachyrium scoparium 'Wild West'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 85,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8YWE1V3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YWE1V3E'
    },
    {
      status: 210,
      sku: 'PM0081745',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196812',
      rtl_batch_array: '6014639, 6196812, 6193076',
      name_search: 'CACBCLIP',
      name: "Campanula carpatica 'Blaue Clips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1663,
      chnn_stock_retail: 5284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBV9DCZK;PS936ENH;PR66ZWHT;NZ5C231L;6JJK223J',
      statusContent: 'dot',
      hash: 'DB4K8X71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB4K8X71'
    },
    {
      status: 210,
      sku: 'PM0081746',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196813',
      rtl_batch_array: '6014640, 6196813',
      name_search: 'CACWCLIP',
      name: "Campanula carpatica 'Weisse Clips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1718,
      chnn_stock_retail: 4048,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7DS7GTC7;NKBP72RK;58K3N8J7;1ZEJA8V4;ZG7RT8YA',
      statusContent: 'dot',
      hash: 'PK13TBY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PK13TBY7'
    },
    {
      status: 210,
      sku: 'PM0081747',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196814',
      rtl_batch_array: '6196814',
      name_search: 'CACJBLUE',
      name: "Campanula cochleariifolia 'Jingle Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10445,
      chnn_stock_retail: 10445,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E6ER73AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6ER73AS'
    },
    {
      status: 210,
      sku: 'PM0081748',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196816',
      rtl_batch_array: '6161589, 6196816, 6217012',
      name_search: 'CAGACAUL',
      name: "Campanula glomerata 'Acaulis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15025,
      chnn_stock_retail: 15361,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V2S2R4GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2S2R4GD'
    },
    {
      status: 210,
      sku: 'PM0085254',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373148',
      rtl_batch_array: '6373148',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'XPWGKEV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPWGKEV2'
    },
    {
      status: 210,
      sku: 'PM0081749',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6340056',
      rtl_batch_array: '6014552, 5866474, 6176206, 6340056',
      name_search: 'CAPOSCHA',
      name: 'Campanula poscharskyana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 813,
      chnn_stock_retail: 4622,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8SYZHHW;59GLBEPR;DG4KPLWV;63E2PWS7;7AE8N8Y9;24CES13Y;VKJBG9PX;B7L11J7T;WW7LPKAB;B8L52A8S;8XW789ZC;WTZSZKD2;VJAKT7ZN;RNDSWTCK;1L84PETH;ZJ2Y7LD7',
      statusContent: 'dot',
      hash: 'Z6A8S8YS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6A8S8YS'
    },
    {
      status: 210,
      sku: 'PM0039464',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353271',
      rtl_batch_array: '6353271',
      name_search: 'RHBEVERL',
      name: "Rhodoxis 'Beverly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J5A72ZPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5A72ZPG'
    },
    {
      status: 210,
      sku: 'PM0065191',
      core_name: 'Plant',
      sppl_ean: '8720664873605',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161824',
      rtl_batch_array: '6161824',
      name_search: 'LYCRAUHR',
      name: "Lychnis chalcedonica 'Rauhreif'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DNCB7Z13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DNCB7Z13'
    },
    {
      status: 210,
      sku: 'PM0068100',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193582',
      rtl_batch_array: '6193582',
      name_search: 'NYMAYLA',
      name: "Nymphaea 'Mayla'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4VJ52JZA;WSW8H2ZX;DXZS17WW;1WDKSKS6;2XPS1SA9',
      statusContent: 'dot',
      hash: 'EH8JPXC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH8JPXC7'
    },
    {
      status: 210,
      sku: 'PM0071112',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226951',
      rtl_batch_array: '6226951',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_order_minimum: 3,
      sppl_prcp: 0.798,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'CT4LHEAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT4LHEAT'
    },
    {
      status: 910,
      sku: 'PM0065195',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMAPPLE',
      name: "Malva moschata 'Appleblossom'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5TCLWL47',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5TCLWL47'
    },
    {
      status: 910,
      sku: 'PM0065189',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIFCOMPA',
      name: "Linum flavum 'Compactum'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'V5SK1PLT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V5SK1PLT'
    },
    {
      status: 810,
      sku: 'PM0068073',
      core_name: 'Garden',
      sppl_ean: '8720349479559',
      btch_manufacturer: 143198,
      rtl_batch_array_total: 1,
      btch_active_retail: '4788371',
      rtl_batch_array: '4788371',
      name_search: 'PHCTS',
      name: 'PHC Tree Saver Transplant 85gr',
      sppl_size_code: '85GRSACH',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 4.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'JAP2YN79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'JAP2YN79'
    },
    {
      status: 210,
      sku: 'PM0081750',
      core_name: 'Plant',
      sppl_ean: '8720664804647',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866515',
      rtl_batch_array: '5866515',
      name_search: 'PUPPINK',
      name: "Pulmonaria 'Pretty in Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 422,
      chnn_stock_retail: 422,
      sppl_order_minimum: 3,
      sppl_prcp: 1.541,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HPS73APR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPS73APR'
    },
    {
      status: 210,
      sku: 'PM0071114',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308457',
      rtl_batch_array: '6349162, 6229361, 6301906, 6353569, 6308457',
      name_search: 'VEVALBUM',
      name: "Veronicastrum virginicum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 4750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T2B9EH4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2B9EH4A'
    },
    {
      status: 210,
      sku: 'PM0065188',
      core_name: 'Plant',
      sppl_ean: '8720664872912',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161820',
      rtl_batch_array: '6161820',
      name_search: 'LIAUSTRI',
      name: 'Linum austriacum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 547,
      chnn_stock_retail: 547,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4PZ8YSWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PZ8YSWW'
    },
    {
      status: 210,
      sku: 'PM0065215',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302723',
      rtl_batch_array: '6161885, 6302723',
      name_search: 'PHSPITFI',
      name: "Phlox (P) 'Spitfire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 505,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DY47KX91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY47KX91'
    },
    {
      status: 210,
      sku: 'PM0054863',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6272119',
      rtl_batch_array: '6272119, 6348810',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1913,
      chnn_stock_retail: 16913,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '45JL2J1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45JL2J1B'
    },
    {
      status: 210,
      sku: 'PM0081751',
      core_name: 'Plant',
      sppl_ean: '8720664679795',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5878629',
      rtl_batch_array: '5878629',
      name_search: 'AGBLBLUE',
      name: 'Agapanthus Brilliant Blue',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5HVHTERC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HVHTERC'
    },
    {
      status: 210,
      sku: 'PM0081752',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202275',
      rtl_batch_array: '6080616, 6202275',
      name_search: 'CAMLALBA',
      name: "Campanula latifolia 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 427,
      chnn_stock_retail: 1173,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XBRZ4WA9',
      statusContent: 'dot',
      hash: '9Y99BTRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Y99BTRC'
    },
    {
      status: 210,
      sku: 'PM0081753',
      core_name: 'Plant',
      sppl_ean: '8720664683327',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084837',
      rtl_batch_array: '6084837',
      name_search: 'CAGEMAJO',
      name: "Campanula garganica 'Erinus Major'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2106,
      chnn_stock_retail: 2106,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JYXL6GYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYXL6GYD'
    },
    {
      status: 910,
      sku: 'PM0081754',
      core_name: 'Plant',
      sppl_ean: '8720664885790',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164427',
      rtl_batch_array: '6164427',
      name_search: 'SEALBUM',
      name: 'Sedum album',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XGL7YREX;AKB2TJGA',
      statusContent: 'dot',
      hash: '2854JB2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2854JB2V'
    },
    {
      status: 210,
      sku: 'PM0081755',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348431',
      rtl_batch_array: '6164439, 6348431',
      name_search: 'THPPBEAU',
      name: "Thymus praecox 'Purple Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2999,
      chnn_stock_retail: 3131,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NDCK7P8X',
      statusContent: 'dot',
      hash: 'BXK79SGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXK79SGL'
    },
    {
      status: 210,
      sku: 'PM0081756',
      core_name: 'Plant',
      sppl_ean: '8720664854680',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129655',
      rtl_batch_array: '6129655',
      name_search: 'CACPBLUE',
      name: "Campanula carpatica 'Pearl Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4680,
      chnn_stock_retail: 4680,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GVRK8K2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVRK8K2K'
    },
    {
      status: 210,
      sku: 'PM0068101',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193583',
      rtl_batch_array: '6193583',
      name_search: 'NYMWGONN',
      name: "Nymphaea 'Madame Wilfron Gonn\u00e8re'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZHV6CKLC',
      statusContent: 'dot',
      hash: '7P5VASPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P5VASPA'
    },
    {
      status: 210,
      sku: 'PM0081757',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229440',
      rtl_batch_array: '6229440',
      name_search: 'CAPCATHA',
      name: "Campanula portenschlagiana 'Catharina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 594,
      chnn_stock_retail: 594,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2DCC1PA;DDADH2NT;R4TATVDJ;X6295G5D',
      statusContent: 'dot',
      hash: '1NVJA2LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NVJA2LK'
    },
    {
      status: 210,
      sku: 'PM0085255',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373149',
      rtl_batch_array: '6373149',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 526,
      chnn_stock_retail: 526,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'KHDZRBWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHDZRBWB'
    },
    {
      status: 210,
      sku: 'PM0085256',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373150',
      rtl_batch_array: '6373150',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 464,
      chnn_stock_retail: 464,
      sppl_prcp: 5.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'XVEP82A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVEP82A5'
    },
    {
      status: 210,
      sku: 'PM0085257',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373152',
      rtl_batch_array: '6373152',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 4318,
      chnn_stock_retail: 4318,
      sppl_prcp: 2.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'SCADWEBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCADWEBW'
    },
    {
      status: 210,
      sku: 'PM0085258',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373153',
      rtl_batch_array: '6373153',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 5490,
      chnn_stock_retail: 5490,
      sppl_prcp: 3.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: '973SE2XZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '973SE2XZ'
    },
    {
      status: 210,
      sku: 'PM0085259',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373154',
      rtl_batch_array: '6373154',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2478,
      chnn_stock_retail: 2478,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'C2ASAJ1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2ASAJ1P'
    },
    {
      status: 210,
      sku: 'PM0068094',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193561',
      rtl_batch_array: '6193561',
      name_search: 'NYCMEURV',
      name: "Nymphaea 'Charles de Meurville'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H67X2GP3;WG9SA67B;H4CW28NX',
      statusContent: 'dot',
      hash: 'CBP1SBYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBP1SBYK'
    },
    {
      status: 210,
      sku: 'PM0085260',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373155',
      rtl_batch_array: '6373155',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1358,
      chnn_stock_retail: 1358,
      sppl_prcp: 9.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: '8L22WN3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8L22WN3P'
    },
    {
      status: 210,
      sku: 'PM0085261',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373156',
      rtl_batch_array: '6373156',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 2546,
      chnn_stock_retail: 2546,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '63WPDGT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63WPDGT8'
    },
    {
      status: 210,
      sku: 'PM0085262',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373157',
      rtl_batch_array: '6373157',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3500,
      chnn_stock_retail: 3500,
      sppl_prcp: 2.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '6LW3H7ZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LW3H7ZW'
    },
    {
      status: 210,
      sku: 'PM0085263',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373158',
      rtl_batch_array: '6373158',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 8467,
      chnn_stock_retail: 8467,
      sppl_prcp: 2.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'BCY745EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCY745EA'
    },
    {
      status: 210,
      sku: 'PM0065176',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 5,
      btch_active_retail: '6349165',
      rtl_batch_array: '6349165, 6350506, 6164310, 6167486, 6301659',
      name_search: 'HOFRANCE',
      name: "Hosta 'Francee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2520,
      chnn_stock_retail: 4312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6L39X4TT;Z754WEC7;Y2T6XDTY;BZBYGL5S;PL98W7D4;HKKD7H8P;2LHRSZVD;XXJ99PE5;TVE71W4V;975DX8X9;PLN4TXN8',
      statusContent: 'dot',
      hash: 'VWZN61J3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWZN61J3'
    },
    {
      status: 210,
      sku: 'PM0085264',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373159',
      rtl_batch_array: '6373159',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 11330,
      chnn_stock_retail: 11330,
      sppl_prcp: 4.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'GAWV8SXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAWV8SXP'
    },
    {
      status: 210,
      sku: 'PM0085265',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373160',
      rtl_batch_array: '6373160',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 9313,
      chnn_stock_retail: 9313,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '21AELH5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21AELH5L'
    },
    {
      status: 210,
      sku: 'PM0085266',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373162',
      rtl_batch_array: '6373162',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 760,
      chnn_stock_retail: 760,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'T8Y5SSW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8Y5SSW8'
    },
    {
      status: 210,
      sku: 'PM0039481',
      core_name: 'Plant',
      sppl_ean: '8720664689367',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5890757',
      rtl_batch_array: '5890757',
      name_search: 'EUJGOLDM',
      name: 'Euonymus japonicus Goldmine',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WE55TSTK',
      imageBatch: 'RWPBCYAS',
      statusContent: 'dot',
      hash: '6DC7GX7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DC7GX7E'
    },
    {
      status: 210,
      sku: 'PM0085267',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373163',
      rtl_batch_array: '6373163',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 2915,
      chnn_stock_retail: 2915,
      sppl_prcp: 5.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'AXS2RJ7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXS2RJ7K'
    },
    {
      status: 210,
      sku: 'PM0085268',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373164',
      rtl_batch_array: '6373164',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 273,
      chnn_stock_retail: 273,
      sppl_prcp: 9.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'T17VRPWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T17VRPWK'
    },
    {
      status: 210,
      sku: 'PM0085269',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373165',
      rtl_batch_array: '6373165',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3916,
      chnn_stock_retail: 3916,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'BLBTL9A6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLBTL9A6'
    },
    {
      status: 210,
      sku: 'PM0054871',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301073',
      rtl_batch_array: '6230482, 6301423, 6301073',
      name_search: 'ASAHASLA',
      name: "Aster (D) 'Alice Haslam'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 1070,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '529BZVWT;H8T5DCCR;L36BRG66;YZHD2EDN;47YCW2RR;J3ZECSN3;WL8JYCEE',
      statusContent: 'dot',
      hash: '81WJVH31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81WJVH31'
    },
    {
      status: 910,
      sku: 'PM0039333',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307583',
      rtl_batch_array: '6307583',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '120STC15',
      rng_range_identifier: 'STE120C15',
      rng_range_description: 'Stem 120 cm C15',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 27.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'W3SHTTA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3SHTTA3'
    },
    {
      status: 210,
      sku: 'PM0085270',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373166',
      rtl_batch_array: '6373166',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 4860,
      chnn_stock_retail: 4860,
      sppl_prcp: 3.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'YJ75LSTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ75LSTV'
    },
    {
      status: 210,
      sku: 'PM0085271',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373167',
      rtl_batch_array: '6373167',
      name_search: 'PRLMANO',
      name: "Prunus laurocerasus 'Mano'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2852,
      chnn_stock_retail: 2852,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CBV58A24',
      statusContent: 'dot',
      hash: 'S3PXARGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3PXARGA'
    },
    {
      status: 210,
      sku: 'PM0085272',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373168',
      rtl_batch_array: '6373168',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 12574,
      chnn_stock_retail: 12574,
      sppl_prcp: 2.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'PE24XBCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PE24XBCB'
    },
    {
      status: 210,
      sku: 'PM0085273',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373169',
      rtl_batch_array: '6373169',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '080100C7',
      rng_range_identifier: 'H080100C7',
      sppl_stock_available: 560,
      chnn_stock_retail: 560,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'E6RB7VBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6RB7VBA'
    },
    {
      status: 210,
      sku: 'PM0085274',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373170',
      rtl_batch_array: '6373170',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 4589,
      chnn_stock_retail: 4589,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'RAPS2VE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAPS2VE5'
    },
    {
      status: 210,
      sku: 'PM0085275',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373172',
      rtl_batch_array: '6373172',
      name_search: 'PRLPIRAN',
      name: "Prunus laurocerasus 'Piranha'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 2218,
      chnn_stock_retail: 2218,
      sppl_prcp: 4.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZGYVWCEY;JHRWWS1T',
      statusContent: 'dot',
      hash: '9XW811JK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XW811JK'
    },
    {
      status: 210,
      sku: 'PM0068095',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193562',
      rtl_batch_array: '6193562',
      name_search: 'NYALBA',
      name: 'Nymphaea alba',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 997,
      chnn_stock_retail: 997,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7RTDKZ35;NELGEPT3;K53JCC2Z;VNTXCDLZ',
      statusContent: 'dot',
      hash: 'H8214WEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8214WEL'
    },
    {
      status: 210,
      sku: 'PM0085276',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373173',
      rtl_batch_array: '6373173',
      name_search: 'PRLREYNV',
      name: "Prunus laurocerasus 'Reynvaanii'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1712,
      chnn_stock_retail: 1712,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BTA419X6',
      statusContent: 'dot',
      hash: '2XSNXJN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XSNXJN2'
    },
    {
      status: 210,
      sku: 'PM0085277',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373174',
      rtl_batch_array: '6373174',
      name_search: 'PRLREYNV',
      name: "Prunus laurocerasus 'Reynvaanii'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 1373,
      chnn_stock_retail: 1373,
      sppl_prcp: 3.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BTA419X6',
      statusContent: 'dot',
      hash: '29LTZAWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29LTZAWB'
    },
    {
      status: 210,
      sku: 'PM0085278',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373178',
      rtl_batch_array: '6373178',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 402,
      chnn_stock_retail: 402,
      sppl_prcp: 6.535,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'HX6SW3NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX6SW3NE'
    },
    {
      status: 210,
      sku: 'PM0085279',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373180',
      rtl_batch_array: '6373180',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: '4H8PG7G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4H8PG7G9'
    },
    {
      status: 210,
      sku: 'PM0085280',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373181',
      rtl_batch_array: '6373181',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1730,
      chnn_stock_retail: 1730,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: 'H3DXYRJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3DXYRJR'
    },
    {
      status: 210,
      sku: 'PM0085281',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373182',
      rtl_batch_array: '6373182',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 894,
      chnn_stock_retail: 894,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDT7WBGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDT7WBGB'
    },
    {
      status: 210,
      sku: 'PM0085282',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373183',
      rtl_batch_array: '6373183',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DXEA6EL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DXEA6EL'
    },
    {
      status: 210,
      sku: 'PM0085283',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373184',
      rtl_batch_array: '6373184',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'VGWKDTGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGWKDTGE'
    },
    {
      status: 210,
      sku: 'PM0085284',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373185',
      rtl_batch_array: '6373185',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 1589,
      chnn_stock_retail: 1589,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '6982YGH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6982YGH1'
    },
    {
      status: 210,
      sku: 'PM0039498',
      core_name: 'Plant',
      sppl_ean: '8720664679597',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5892947',
      rtl_batch_array: '5892947',
      name_search: 'ACGGDELI',
      name: "Acorus gramineus 'Golden Delight'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LS4SL112',
      imageBatch: 'JB7E9VAP',
      statusContent: 'dot',
      hash: 'SW8WY5CY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW8WY5CY'
    },
    {
      status: 210,
      sku: 'PM0085285',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373186',
      rtl_batch_array: '6373186',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 1235,
      chnn_stock_retail: 1235,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'A4PNVXTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4PNVXTZ'
    },
    {
      status: 210,
      sku: 'PM0085286',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373187',
      rtl_batch_array: '6373187',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 1618,
      chnn_stock_retail: 1618,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'ALZLH6BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALZLH6BY'
    },
    {
      status: 210,
      sku: 'PM0085287',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373188',
      rtl_batch_array: '6373188',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '030040C7',
      rng_range_identifier: 'H030040C7',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'D1BRJVEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1BRJVEC'
    },
    {
      status: 210,
      sku: 'PM0085288',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373189',
      rtl_batch_array: '6373189',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'AVDAZCL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVDAZCL3'
    },
    {
      status: 810,
      sku: 'PM0071121',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228333',
      rtl_batch_array: '6228333',
      name_search: 'NADFIREP',
      name: "Nandina domestica 'Firepower'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4972C1NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4972C1NK'
    },
    {
      status: 210,
      sku: 'PM0071122',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228334',
      rtl_batch_array: '6228334',
      name_search: 'NADGSTRE',
      name: "Nandina domestica 'Gulf Stream'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5177CD65;2ZXX95WG;HXBEY3NS;1KGAWP8J;DKXS5BGB;6D121H4B;VSRDDRHB;KW74VYE1;W3K99X4E;BC74L52W;ECSHV643;ZNS6C38G;A9NSHWK2',
      statusContent: 'dot',
      hash: '77TD4WEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77TD4WEH'
    },
    {
      status: 210,
      sku: 'PM0039317',
      core_name: 'Plant',
      sppl_ean: '8720664679801',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5878630',
      rtl_batch_array: '5878630',
      name_search: 'AGTWISTE',
      name: 'Agapanthus Twister',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 6.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '75J2DR6N;G4CB7SRV;9P1W2T9B;67LN29CJ',
      statusContent: 'dot',
      hash: '87Y2EARN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87Y2EARN'
    },
    {
      status: 210,
      sku: 'PM0065214',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333292',
      rtl_batch_array: '6161884, 6319729, 6333292',
      name_search: 'PHOPERFE',
      name: "Phlox (P) 'Orange Perfection'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 332,
      chnn_stock_retail: 1392,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX1PR4K2',
      statusContent: 'dot',
      hash: 'XSLYH1PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSLYH1PY'
    },
    {
      status: 210,
      sku: 'PM0054842',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356146',
      rtl_batch_array: '5779252, 6356146',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '150175C24',
      rng_range_identifier: 'H150175C2.5',
      rng_range_description: 'H150 cm 175 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 875,
      sppl_prcp: 2.817,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'D54ENRN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D54ENRN1'
    },
    {
      status: 910,
      sku: 'PM0068074',
      core_name: 'Garden',
      btch_manufacturer: 143198,
      rtl_batch_array_total: 1,
      btch_active_retail: '4788372',
      rtl_batch_array: '4788372',
      name_search: 'PHCMYPT',
      name: 'PHC Mycor Dip Pt.425gr',
      sppl_size_code: '425GRSACH',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 36.74,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'HPVC3825',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'HPVC3825'
    },
    {
      status: 210,
      sku: 'PM0068097',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193566',
      rtl_batch_array: '6193566',
      name_search: 'NYBPRINC',
      name: "Nymphaea 'Burgundy Princess'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 7.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4K2A3W1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K2A3W1J'
    },
    {
      status: 810,
      sku: 'PM0068088',
      core_name: 'Plant',
      sppl_ean: '8720664883499',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6151158',
      rtl_batch_array: '6151158, 6281086',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 47,
      chnn_stock_retail: 97,
      sppl_prcp: 5.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'E27VW2ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E27VW2ZS'
    },
    {
      status: 210,
      sku: 'PM0068089',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300236',
      rtl_batch_array: '6300236',
      name_search: 'SAREPENS',
      name: 'Salix repens',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4500,
      chnn_stock_retail: 4500,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V4KNL9LB;DSE8LZKJ;PH288W6G',
      statusContent: 'dot',
      hash: '6BLT8AKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BLT8AKZ'
    },
    {
      status: 210,
      sku: 'PM0069297',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284312',
      rtl_batch_array: '6284312, 6321931',
      name_search: 'CAAAVALA',
      name: "Calamagrostis acutiflora 'Avalanche'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 601,
      chnn_stock_retail: 701,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '165JB7T8;SN12XP6W;CTN9H182;J984RS7E;T4RDVB1C;TEEDLRE8',
      statusContent: 'dot',
      hash: 'BABR5HA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BABR5HA4'
    },
    {
      status: 210,
      sku: 'PM0069298',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252847',
      rtl_batch_array: '6252847',
      name_search: 'CAAENGLA',
      name: "Calamagrostis acutiflora 'England'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A7KD22ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7KD22ZJ'
    },
    {
      status: 910,
      sku: 'PM0048219',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEVELEBI',
      name: 'Degenia velebitica',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '53R8XYAV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '53R8XYAV'
    },
    {
      status: 210,
      sku: 'PM0085289',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373190',
      rtl_batch_array: '6373190',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'J6V4EEWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6V4EEWG'
    },
    {
      status: 210,
      sku: 'PM0081758',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348461',
      rtl_batch_array: '6348461',
      name_search: 'CEHKGOLD',
      name: "Cephalotaxus harringtonii 'Korean Gold'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 9.922,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KVTSY5HK;JHHGT1DD',
      statusContent: 'dot',
      hash: 'R8K2EVDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8K2EVDN'
    },
    {
      status: 210,
      sku: 'PM0065196',
      core_name: 'Plant',
      sppl_ean: '8720664874244',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161837',
      rtl_batch_array: '6161837',
      name_search: 'MEMAJOR',
      name: 'Melianthus major',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1417,
      chnn_stock_retail: 1417,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8R76HBVV',
      statusContent: 'dot',
      hash: 'YDY4RNVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDY4RNVD'
    },
    {
      status: 210,
      sku: 'PM0078482',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267744',
      rtl_batch_array: '6267744',
      name_search: 'CAMORROW',
      name: 'Carex morrowii',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 1.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEY1DCSC',
      statusContent: 'dot',
      hash: '71YPXYS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71YPXYS6'
    },
    {
      status: 210,
      sku: 'PM0081759',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348462',
      rtl_batch_array: '6348462',
      name_search: 'CRJDINGE',
      name: "Cryptomeria japonica 'Dinger'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 509,
      chnn_stock_retail: 509,
      sppl_prcp: 6.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GCSJ59SZ;T72HWGJ7;YZHNEZ92;CV7VEYZ8;8RY6D9NE;PS2AEPCR',
      statusContent: 'dot',
      hash: 'RDK9XD4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDK9XD4X'
    },
    {
      status: 810,
      sku: 'PM0078483',
      core_name: 'Plant',
      sppl_ean: '8720664681736',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '5411036',
      rtl_batch_array: '5411036',
      name_search: 'BETHUNBE',
      name: 'Berberis thunbergii',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VR4D883C;JYT62D7E;YXAHYVN7;J5B3L6ZR;STRL4G62;8NNCVVSS;Z5J8YP2K;WZ3RLL1A;9A3RJR1S;CWCB45VD',
      imageBatch: 'E2H5YLDX',
      statusContent: 'dot',
      hash: 'NSY85XLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSY85XLA'
    },
    {
      status: 210,
      sku: 'PM0068117',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350568',
      rtl_batch_array: '6350568, 6193620',
      name_search: 'IRBGAMEC',
      name: "Iris (L) 'Black Gamecock'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 347,
      chnn_stock_retail: 1347,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CGC7TYKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGC7TYKV'
    },
    {
      status: 910,
      sku: 'PM0064910',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '7P1B57EH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7P1B57EH'
    },
    {
      status: 210,
      sku: 'PM0081760',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348463',
      rtl_batch_array: '6348463',
      name_search: 'CUAGLAUC',
      name: "Cupressus arizonica 'Glauca'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 1655,
      chnn_stock_retail: 1655,
      sppl_prcp: 6.127,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HETZSCDG;HCT84Z1K;G1KLG4BJ;J1P22WBC;JEG6J3V5;RWPDH3DE;T7JWC71L;J4EWT1HR;HPSZ31SC;AVA55G8T;PLY7CXCG',
      statusContent: 'dot',
      hash: '55LWNDYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55LWNDYZ'
    },
    {
      status: 210,
      sku: 'PM0085290',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373191',
      rtl_batch_array: '6373191',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 1444,
      chnn_stock_retail: 1444,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: '9REDEAEZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9REDEAEZ'
    },
    {
      status: 210,
      sku: 'PM0068118',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193626',
      rtl_batch_array: '6193626',
      name_search: 'IRKAEMPF',
      name: 'Iris kaempferii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6DTZ6L9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DTZ6L9J'
    },
    {
      status: 210,
      sku: 'PM0081761',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348467',
      rtl_batch_array: '6348467',
      name_search: 'COVERTIC',
      name: 'Conradina verticillata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZ4JT7Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZ4JT7Y8'
    },
    {
      status: 910,
      sku: 'PM0048224',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEDSAFFI',
      name: "Delphinium 'Delphi's Saffier'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'JJCY8THP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JJCY8THP'
    },
    {
      status: 210,
      sku: 'PM0048225',
      core_name: 'Plant',
      sppl_ean: '8720664687073',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014669',
      rtl_batch_array: '6014669',
      name_search: 'DEDZOE',
      name: "Delphinium 'Delphi's Zoe'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZTK48WZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZTK48WZ'
    },
    {
      status: 210,
      sku: 'PM0085291',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373192',
      rtl_batch_array: '6373192',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'L32X64LG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L32X64LG'
    },
    {
      status: 210,
      sku: 'PM0048211',
      core_name: 'Plant',
      sppl_ean: '8720664683235',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014638',
      rtl_batch_array: '6014638',
      name_search: 'EDI68601',
      name: "Camassia leichtlinii 'Semiplena'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_prcp: 1.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S2DZEPZ4;VD3RSAB1;L4HN5CX4;KAV27VXN;8HBGGWDR;AT3DGXZJ;GL3BWXAP;T4G3R49T',
      statusContent: 'dot',
      hash: '6GRLZPAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GRLZPAP'
    },
    {
      status: 210,
      sku: 'PM0081762',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348470',
      rtl_batch_array: '6348470',
      name_search: 'GEMCZAKO',
      name: "Geranium macrorrhizum 'Czakor'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5233,
      chnn_stock_retail: 5233,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H9D3THEK',
      statusContent: 'dot',
      hash: 'RJCXLWHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJCXLWHX'
    },
    {
      status: 210,
      sku: 'PM0074286',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269455',
      rtl_batch_array: '6269455',
      name_search: 'LYVDPURP',
      name: "Lythrum virgatum 'Dropmore Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7S5J3JE;AV74VDYJ;JJWZVB7B;DXN4R7AD',
      statusContent: 'dot',
      hash: 'Y2WSXE6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2WSXE6Z'
    },
    {
      status: 810,
      sku: 'PM0048152',
      core_name: 'Plant',
      sppl_ean: '8720626314238',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014399',
      rtl_batch_array: '6014399',
      name_search: 'PAPDDAND',
      name: "Paeonia (I) 'Pink Double Dandy'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_order_minimum: 3,
      sppl_prcp: 4.593,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9B8SVL3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B8SVL3T'
    },
    {
      status: 210,
      sku: 'PM0065198',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348378',
      rtl_batch_array: '6348378',
      name_search: 'MOCITRIO',
      name: 'Monarda citriodora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4HSN9TWN;GP6WJBYB;S883PNSL;63B2Z8LX;22T6VYDD;72SAV3DJ',
      statusContent: 'dot',
      hash: 'HAG678K7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAG678K7'
    },
    {
      status: 210,
      sku: 'PM0048215',
      core_name: 'Plant',
      sppl_ean: '8720626343504',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014655',
      rtl_batch_array: '6014655',
      name_search: 'DESNEEUW',
      name: "Delphinium (B) 'Sneeuwwitje'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 2.068,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C19NVLJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C19NVLJD'
    },
    {
      status: 210,
      sku: 'PM0081763',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348471',
      rtl_batch_array: '6348471',
      name_search: 'PHCSTRIP',
      name: 'Phlox (S) Candy Stripes',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3958,
      chnn_stock_retail: 3958,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4Z281VAV;TETBH8GH;WHWCYWHG;WLZLNL76;NKL54L69;NBJ2AYG4;8DZ61RGS;KN21ZDRW;8YYWGJ2W;2SHRP78K',
      statusContent: 'dot',
      hash: 'AEZX3REL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEZX3REL'
    },
    {
      status: 210,
      sku: 'PM0081764',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348472',
      rtl_batch_array: '6348472',
      name_search: 'PHECBLUE',
      name: "Phlox (S) 'Emerald Cushion Blue'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3873,
      chnn_stock_retail: 3873,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1R1S915;2ETE28TH;WW2WD4RA;2B3CRWDB;T2X7GAKT;L9TWE6LN;L7HRCYNH;G1LY5XBD;9TKP9KTP;C25JSX29',
      statusContent: 'dot',
      hash: 'R8KHJSV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8KHJSV8'
    },
    {
      status: 210,
      sku: 'PM0081765',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348473',
      rtl_batch_array: '6348473',
      name_search: 'PHMCUSHI',
      name: "Phlox (S) 'MacDaniel's Cushion'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3907,
      chnn_stock_retail: 3907,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPSN9TKG;WJSE1K7H',
      statusContent: 'dot',
      hash: 'CHAVB32C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHAVB32C'
    },
    {
      status: 210,
      sku: 'PM0085292',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373193',
      rtl_batch_array: '6373193',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 889,
      chnn_stock_retail: 889,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'AKGP5G7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKGP5G7V'
    },
    {
      status: 210,
      sku: 'PM0085293',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373194',
      rtl_batch_array: '6373194',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 1854,
      chnn_stock_retail: 1854,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'ETTEBCDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETTEBCDC'
    },
    {
      status: 210,
      sku: 'PM0032215',
      core_name: 'Plant',
      sppl_ean: '8720349404957',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6003553',
      rtl_batch_array: '6003553, 6254725',
      name_search: 'GAODORAT',
      name: 'Galium odoratum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1485,
      chnn_stock_retail: 4152,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PPD93DZV;1245S7YA;8EJ11S97;GLCNSSC9;KJC9DW3W;P4EB3RCC',
      statusContent: 'dot',
      hash: '41CWGBWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41CWGBWY'
    },
    {
      status: 210,
      sku: 'PM0065223',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6255892',
      rtl_batch_array: '6176222, 6161915, 6255892, 6356610',
      name_search: 'PUSMMOON',
      name: "Pulmonaria saccharata 'Mrs Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1698,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GW1GSE8D;6D99VZR9;9N4GC8KH;519GRG2Z;7YW5N93N;DABR1WNH',
      statusContent: 'dot',
      hash: '4H3JXVCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4H3JXVCW'
    },
    {
      status: 210,
      sku: 'PM0065257',
      core_name: 'Plant',
      sppl_ean: '8720664886278',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161988',
      rtl_batch_array: '6161988',
      name_search: 'SITATARI',
      name: 'Silene tatarica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4N4N5DD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4N4N5DD6'
    },
    {
      status: 210,
      sku: 'PM0085294',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373195',
      rtl_batch_array: '6373195',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 386,
      chnn_stock_retail: 386,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'BX4H91Y5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX4H91Y5'
    },
    {
      status: 210,
      sku: 'PM0068091',
      core_name: 'Plant',
      sppl_ean: '8720626340619',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207200',
      rtl_batch_array: '5207200',
      name_search: 'COCNEORU',
      name: 'Convolvulus cneorum',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D574AA9T;K9GD3V54;1D8XG7E4;6XWVVAG9;TAEAYK35;XC3SBBEB;W1KHWDTY;T2PWDGWD;DEJSYPCK;XSTHJK23;RWY6DZCB;TXKCVSHT;V5CLXH1S;8KZ323JZ;BD2RGXR9;7YD8HX35',
      imageBatch: 'JVLC2SLC',
      statusContent: 'dot',
      hash: 'PWGRW5YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWGRW5YT'
    },
    {
      status: 210,
      sku: 'PM0068802',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6231149',
      rtl_batch_array: '6208415, 6231149',
      name_search: 'IRSHHAPP',
      name: "Iris sibirica 'Harpswell Happiness'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1224,
      sppl_order_minimum: 3,
      sppl_prcp: 0.747,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JB8KLDCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB8KLDCY'
    },
    {
      status: 210,
      sku: 'PM0048231',
      core_name: 'Plant',
      sppl_ean: '8720664687110',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014675',
      rtl_batch_array: '6014675',
      name_search: 'DEMOONBE',
      name: "Delphinium 'Moonbeam'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LAAPRAHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAAPRAHR'
    },
    {
      status: 210,
      sku: 'PM0085295',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373196',
      rtl_batch_array: '6373196',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'DVL43TP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVL43TP2'
    },
    {
      status: 210,
      sku: 'PM0078493',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221749',
      rtl_batch_array: '6221749',
      name_search: 'CHOPYGMA',
      name: "Chamaecyparis obtusa 'Pygmaea'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P7SDSH2B;K77GLWYX',
      statusContent: 'dot',
      hash: 'G6S95S3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6S95S3X'
    },
    {
      status: 810,
      sku: 'PM0065254',
      core_name: 'Plant',
      sppl_ean: '8720664889354',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161984',
      rtl_batch_array: '6161984',
      name_search: 'VESBARCA',
      name: "Veronica spicata 'Barcarolle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 58,
      chnn_stock_retail: 58,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A9JAC8PA',
      statusContent: 'dot',
      hash: 'PNCL3DL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNCL3DL3'
    },
    {
      status: 210,
      sku: 'PM0031773',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284525',
      rtl_batch_array: '6174729, 6284525, 6383867',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2025,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '1CCB7HRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CCB7HRW'
    },
    {
      status: 210,
      sku: 'PM0039499',
      core_name: 'Plant',
      sppl_ean: '8720664679603',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5892949',
      rtl_batch_array: '5892949',
      name_search: 'ACGOGON',
      name: "Acorus gramineus 'Ogon'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XEX4W2P1;7B3N2R24;HXBERTTP;PV39NESW;E264R1NJ;R5PEL6CS;PE7Z6R3T;V4T6VD5X',
      imageBatch: 'B9K26BDV',
      statusContent: 'dot',
      hash: 'T8VWS2LY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8VWS2LY'
    },
    {
      status: 210,
      sku: 'PM0068120',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193629',
      rtl_batch_array: '6193629',
      name_search: 'IRLMBEAU',
      name: "Iris laevigata 'Mottled Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SSKSZRVN',
      statusContent: 'dot',
      hash: '69ZET476',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69ZET476'
    },
    {
      status: 210,
      sku: 'PM0078715',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207510',
      rtl_batch_array: '5207510',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '035040C5',
      rng_range_identifier: 'H035040C5',
      rng_range_description: 'H35 cm 40 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      imageBatch: 'SVZL717S',
      statusContent: 'dot',
      hash: 'SNC7PXZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNC7PXZX'
    },
    {
      status: 210,
      sku: 'PM0078498',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6004016',
      rtl_batch_array: '6004016',
      name_search: 'MOALBA',
      name: 'Morus alba',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 489,
      chnn_stock_retail: 489,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XA9H45K6;LJ74YVZR;RS58BJT4',
      statusContent: 'dot',
      hash: 'KP6HTKLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP6HTKLE'
    },
    {
      status: 210,
      sku: 'PM0078500',
      core_name: 'Plant',
      sppl_ean: '8720664887787',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079505',
      rtl_batch_array: '6079505',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 2955,
      chnn_stock_retail: 2955,
      sppl_prcp: 5.327,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'GXGXH5E6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXGXH5E6'
    },
    {
      status: 910,
      sku: 'PM0054889',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307764',
      rtl_batch_array: '6307764',
      name_search: 'PINSFAST',
      name: "Pinus sylvestris 'Fastigiata'",
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ND7DEYY',
      statusContent: 'dot',
      hash: 'YJC2LAWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJC2LAWR'
    },
    {
      status: 210,
      sku: 'PM0078503',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170327',
      rtl_batch_array: '6170327',
      name_search: 'SARNITID',
      name: 'Salix repens nitida',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZHP2L4T',
      statusContent: 'dot',
      hash: '857HN5E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '857HN5E7'
    },
    {
      status: 210,
      sku: 'PM0065203',
      core_name: 'Plant',
      sppl_ean: '8720664875616',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161854',
      rtl_batch_array: '6161854',
      name_search: 'OEFWCUTH',
      name: "Oenothera fruticosa 'W. Cuthbertson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AY2YDEE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AY2YDEE7'
    },
    {
      status: 810,
      sku: 'PM0078504',
      core_name: 'Plant',
      sppl_ean: '8720664880382',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170371',
      rtl_batch_array: '6170371',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '015020C5',
      rng_range_identifier: 'H015020C5',
      rng_range_description: 'H15 cm 20 cm C5',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 5.027,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: '6V66SP99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6V66SP99'
    },
    {
      status: 810,
      sku: 'PM0066699',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239628',
      rtl_batch_array: '6239628',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.819,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '41N8G742',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41N8G742'
    },
    {
      status: 210,
      sku: 'PM0048189',
      core_name: 'Plant',
      sppl_ean: '8720664683648',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014570',
      rtl_batch_array: '6014570',
      name_search: 'CADWEEKE',
      name: "Carex dipsacea 'Weekend'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RVG43LPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVG43LPY'
    },
    {
      status: 210,
      sku: 'PM0068103',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193593',
      rtl_batch_array: '6193593',
      name_search: 'NYTETRAG',
      name: 'Nymphaea tetragona',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALGD5GA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALGD5GA1'
    },
    {
      status: 210,
      sku: 'PM0078506',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234183',
      rtl_batch_array: '6234183',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'GG87Z4L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GG87Z4L2'
    },
    {
      status: 210,
      sku: 'PM0048222',
      core_name: 'Plant',
      sppl_ean: '8720664687059',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014666',
      rtl_batch_array: '6014666',
      name_search: 'DEDMISTY',
      name: "Delphinium 'Delphi's Misty'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZW6J4W1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW6J4W1P'
    },
    {
      status: 210,
      sku: 'PM0065207',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220870',
      rtl_batch_array: '6220870',
      name_search: 'PALUSITA',
      name: 'Paradisea lusitanica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SAN84TPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAN84TPP'
    },
    {
      status: 810,
      sku: 'PM0078508',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243727',
      rtl_batch_array: '6243727',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'D9HXJR2W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9HXJR2W'
    },
    {
      status: 210,
      sku: 'PM0078509',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309567',
      rtl_batch_array: '6309567',
      name_search: 'SETATLAN',
      name: 'Sedum takesimense Atlantis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 629,
      chnn_stock_retail: 629,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '928SE5NG;5ZTELBH2',
      statusContent: 'dot',
      hash: '54P1DWAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54P1DWAC'
    },
    {
      status: 210,
      sku: 'PM0078510',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309568',
      rtl_batch_array: '6309568',
      name_search: 'STGIGANT',
      name: 'Stipa gigantea',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 396,
      chnn_stock_retail: 396,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RWT5S8TE;ESBDT8HW',
      statusContent: 'dot',
      hash: 'AATYAZRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AATYAZRK'
    },
    {
      status: 210,
      sku: 'PM0078710',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304763',
      rtl_batch_array: '6304763',
      name_search: 'MAMAMRWI',
      name: 'Mangave Mad About Mangave Red Wing',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5CRAE7AJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CRAE7AJ'
    },
    {
      status: 210,
      sku: 'PM0078711',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304764',
      rtl_batch_array: '6304764',
      name_search: 'MAMAMSFO',
      name: 'Mangave Mad About Mangave Silv. Fox',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '62P6C312',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62P6C312'
    },
    {
      status: 210,
      sku: 'PM0078403',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6329844',
      rtl_batch_array: '6329844, 6333183, 6348284',
      name_search: 'ASSCOLOP',
      name: 'Asplenium scolopendrium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 695,
      chnn_stock_retail: 9587,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GVRCSWGV;1NPN4ZCZ;PYN2SS79;WKBGHRVT;PT1G7DBV;TPW2ZZNN;Z2XX16HY;T4VLSHXH;WA4YG64G;ASX1AEWY;VJKT6GYC;YLSRHAEZ',
      statusContent: 'dot',
      hash: 'KA6P83ZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KA6P83ZE'
    },
    {
      status: 210,
      sku: 'PM0078713',
      core_name: 'Plant',
      sppl_ean: '8720349415090',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207248',
      rtl_batch_array: '5207248',
      name_search: 'ERDDDALE',
      name: "Erica darleyensis 'Darley Dale'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1120,
      chnn_stock_retail: 1120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'NJ2V3PY7',
      statusContent: 'dot',
      hash: '81VB4AJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81VB4AJL'
    },
    {
      status: 210,
      sku: 'PM0078714',
      core_name: 'Plant',
      sppl_ean: '8720353097978',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207484',
      rtl_batch_array: '5207484',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      imageBatch: 'SCSY7EH6',
      statusContent: 'dot',
      hash: 'R7WYB3YL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7WYB3YL'
    },
    {
      status: 810,
      sku: 'PM0048207',
      core_name: 'Plant',
      sppl_ean: '8720664683181',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014633',
      rtl_batch_array: '6014633',
      name_search: 'EDI68602',
      name: "Camassia cusickii 'Zwanenburg'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 1.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGNSJ1YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGNSJ1YA'
    },
    {
      status: 210,
      sku: 'PM0048223',
      core_name: 'Plant',
      sppl_ean: '8720353096124',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014667',
      rtl_batch_array: '6014667',
      name_search: 'DEDPPOWE',
      name: "Delphinium 'Delphis Pink Power'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYR2AK1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYR2AK1L'
    },
    {
      status: 210,
      sku: 'PM0066700',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '6236766',
      rtl_batch_array: '6236766, 6373161',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 8604,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'P3WZKGD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3WZKGD4'
    },
    {
      status: 210,
      sku: 'PM0078716',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207550',
      rtl_batch_array: '5207550',
      name_search: 'WEFALEXA',
      name: "Weigela florida 'Alexandra'",
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6B2ZE5E',
      imageBatch: '84EAD9V1',
      statusContent: 'dot',
      hash: '4RT49EAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RT49EAL'
    },
    {
      status: 210,
      sku: 'PM0048210',
      core_name: 'Plant',
      sppl_ean: '8720664683228',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014637',
      rtl_batch_array: '6014637',
      name_search: 'EDI78454',
      name: "Camassia leichtlinii 'Sacajawea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_prcp: 1.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1BV743H8;YX1VWRDT;CJ8HLH9V',
      statusContent: 'dot',
      hash: 'CCSYPK2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCSYPK2B'
    },
    {
      status: 210,
      sku: 'PM0078488',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309341',
      rtl_batch_array: '6309341',
      name_search: 'ASMAJOR',
      name: 'Astrantia major',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 587,
      chnn_stock_retail: 587,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXRH9BAN;C48S1Z5D;DB66DHVC;HW6RB225;6WXGEJV5;5EV4ZX66;J4H8SDAC;DTDN2AZ1;ERRDVXVA',
      statusContent: 'dot',
      hash: '4RTBE668',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RTBE668'
    },
    {
      status: 910,
      sku: 'PM0065204',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'OMVELFEN',
      name: "Omphalodes verna 'Elfenauge'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'VB5X8NZZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VB5X8NZZ'
    },
    {
      status: 210,
      sku: 'PM0078718',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295124',
      rtl_batch_array: '6295124',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1547,
      chnn_stock_retail: 1547,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: 'EAL3G5BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAL3G5BR'
    },
    {
      status: 210,
      sku: 'PM0058774',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317473',
      rtl_batch_array: '6317473, 6234689, 6356974',
      name_search: 'ECPFATTR',
      name: "Echinacea purpurea 'Fatal Attraction'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 862,
      chnn_stock_retail: 2862,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9LG9B6L1;65R4YGZW',
      statusContent: 'dot',
      hash: 'WXBSYGW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXBSYGW7'
    },
    {
      status: 210,
      sku: 'PM0058412',
      core_name: 'Plant',
      sppl_ean: '8720664800465',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112723',
      rtl_batch_array: '6112723',
      name_search: 'PARSUNDA',
      name: "Paeonia (LD) 'Raspberry Sundae'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4VJAXXC;E7ZJW54Z',
      statusContent: 'dot',
      hash: '2CJ77JAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CJ77JAY'
    },
    {
      status: 210,
      sku: 'PM0066703',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174819',
      rtl_batch_array: '6174819',
      name_search: 'ALERYTHR',
      name: 'Alchemilla erythropoda',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2136,
      chnn_stock_retail: 2136,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7AZY9S8;LLL5J26H;WG6RL1JG;HLL2XDH1;R6BBV7DP;GGGDLH4G;51NBD2RW;E98119SA;GB3E7WA8;V8EBJ34Y',
      statusContent: 'dot',
      hash: 'HRE5NXA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRE5NXA9'
    },
    {
      status: 210,
      sku: 'PM0078719',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295125',
      rtl_batch_array: '6295125',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2496,
      chnn_stock_retail: 2496,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: 'YXW63NA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXW63NA1'
    },
    {
      status: 210,
      sku: 'PM0078720',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295127',
      rtl_batch_array: '6295127',
      name_search: 'PHSCHNEE',
      name: "Philadelphus 'Schneesturm'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3200,
      chnn_stock_retail: 3200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S8W923C8;1YGB42NY',
      statusContent: 'dot',
      hash: 'G49KRT7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G49KRT7L'
    },
    {
      status: 210,
      sku: 'PM0078721',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295178',
      rtl_batch_array: '6295178',
      name_search: 'VIMALBA',
      name: "Vinca minor 'Alba'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5775,
      chnn_stock_retail: 5775,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XHY7T4KR;J9VCL9CP;YL2JYJ16',
      statusContent: 'dot',
      hash: 'VZ6GX6SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ6GX6SE'
    },
    {
      status: 210,
      sku: 'PM0078722',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295179',
      rtl_batch_array: '6295179',
      name_search: 'VIMARGEN',
      name: "Vinca minor 'Argenteovariegata'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2122,
      chnn_stock_retail: 2122,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EW16H3V3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW16H3V3'
    },
    {
      status: 210,
      sku: 'PM0078723',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295187',
      rtl_batch_array: '6295187',
      name_search: 'VIMRSHUG',
      name: "Vinca minor 'Ralph Shugert'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2122,
      chnn_stock_retail: 2122,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3CB44HX;9TB6869P',
      statusContent: 'dot',
      hash: 'N9G5DSE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9G5DSE6'
    },
    {
      status: 210,
      sku: 'PM0065200',
      core_name: 'Plant',
      sppl_ean: '8720664875289',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161851',
      rtl_batch_array: '6161851, 6348390',
      name_search: 'NERAMELI',
      name: "Nepeta racemosa 'Amelia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 425,
      chnn_stock_retail: 525,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZLVS3DLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLVS3DLY'
    },
    {
      status: 210,
      sku: 'PM0078725',
      core_name: 'Plant',
      sppl_ean: '8720664520066',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '5506317',
      rtl_batch_array: '5506317',
      name_search: 'COLUCIDU',
      name: 'Cotoneaster lucidus',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 1654,
      chnn_stock_retail: 1654,
      sppl_prcp: 3.576,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPJW8JTN',
      statusContent: 'dot',
      hash: 'LT9G1EL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT9G1EL5'
    },
    {
      status: 210,
      sku: 'PM0032218',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260712',
      rtl_batch_array: '6260712, 6252652',
      name_search: 'LAMBSILV',
      name: "Lamium maculatum 'Beacon Silver'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 862,
      chnn_stock_retail: 1673,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5SDS5BAS;Y7VKCZ68;6JSP4ALE;JAEVB74X;Z5LGHT2D;7DZD226A;BX34SBL1;C7D8ECK1;ZXWWEYN2;A88BL2AS',
      statusContent: 'dot',
      hash: 'X4HL4SKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4HL4SKY'
    },
    {
      status: 810,
      sku: 'PM0078726',
      core_name: 'Plant',
      sppl_ean: '8720349415595',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '5506330',
      rtl_batch_array: '5506330',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 7.152,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '2B5VJK7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B5VJK7V'
    },
    {
      status: 210,
      sku: 'PM0078727',
      core_name: 'Plant',
      sppl_ean: '8720664697751',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '5506354',
      rtl_batch_array: '5506354',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 1650,
      chnn_stock_retail: 1650,
      sppl_prcp: 6.635,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'CJY8TV8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJY8TV8G'
    },
    {
      status: 210,
      sku: 'PM0078728',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310995',
      rtl_batch_array: '6310995',
      name_search: 'PISGTWIS',
      name: "Pinus strobus 'Green Twist'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2C83645',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2C83645'
    },
    {
      status: 210,
      sku: 'PM0078491',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339852',
      rtl_batch_array: '6309494, 6339852',
      name_search: 'KNTKING',
      name: "Kniphofia 'Tawny King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 303,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DGJZLE8L;J13SW48J;2247K4AD;2AT29W2V;TSWE4NJ5',
      statusContent: 'dot',
      hash: 'XNVEJYD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNVEJYD1'
    },
    {
      status: 210,
      sku: 'PM0078502',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170276',
      rtl_batch_array: '6170276',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_prcp: 1.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '4D32L4HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4D32L4HA'
    },
    {
      status: 210,
      sku: 'PM0078467',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309505',
      rtl_batch_array: '6352688, 6309505',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 225,
      sppl_prcp: 4.111,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'WE99KPC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WE99KPC7'
    },
    {
      status: 810,
      sku: 'PM0078499',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149382',
      rtl_batch_array: '6149382',
      name_search: 'WEFMONET',
      name: 'Weigela florida Monet',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '945P1HEP;SRZ69G98;9LJ3D77Z;TV22RBNY;YEL53WD7;84WXKKZ3;L6RTSJP4;6K6K6PZ2;VKP2DKZ8;XD2YGCSE;2GWBPRK3;HV2A5TED;5N2HP3YY;WCE43XBC;RATTLHZX',
      statusContent: 'dot',
      hash: '61C3R8ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61C3R8ZA'
    },
    {
      status: 810,
      sku: 'PM0078489',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349577',
      rtl_batch_array: '6349577',
      name_search: 'ASABKING',
      name: "Aster amellus 'Blue King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '96TL3T7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96TL3T7W'
    },
    {
      status: 810,
      sku: 'PM0048230',
      core_name: 'Plant',
      sppl_ean: '8720664687103',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014674',
      rtl_batch_array: '6014674',
      name_search: 'DEMATOM',
      name: "Delphinium 'Mighty Atom'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ5EH9EW;CDB7YEL3;ZT8HS4HD;LS4SB2YV',
      statusContent: 'dot',
      hash: 'S6XHC9CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6XHC9CX'
    },
    {
      status: 210,
      sku: 'PM0058399',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208059',
      rtl_batch_array: '6208059',
      name_search: 'ORMSEEDL',
      name: "Origanum 'Marchants Seedling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5GJPD27E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GJPD27E'
    },
    {
      status: 810,
      sku: 'PM0078717',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301452',
      rtl_batch_array: '6301452',
      name_search: 'ASMONTGO',
      name: "Astilbe (J) 'Montgomery'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PC6WES1N;9W8924V8;C35RTS22;28CA6J46;HJWHVYJE',
      statusContent: 'dot',
      hash: 'XLY5XVNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLY5XVNL'
    },
    {
      status: 910,
      sku: 'PM0078724',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HETUBERO',
      name: 'Helianthus tuberosus',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'H88XSC62;6RV49XPY;5Y2BDW5X;66ELPE2N;4TDTYK7H;SAD413LD;R9NG1K45;JDRK1WCY;CXT9EGE1;KXC2A1SJ;JDHSBG2B;SR8BTYHR;P2ACHZBW;YL68BHTC;WXNB8TNW;C84WP7XL;A5GV7DC3;XTLXYTYL;YDESZV29;X87J7JV1;XL9C5N2V;NL5WXY57;DVXGEPB3;9H95PLYE;LYAHNRHD;CXT7WXS3;XRX6CRBJ;L5Y3S465;6T552T21',
      statusContent: 'dot',
      hash: 'XVDWWV3H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XVDWWV3H'
    },
    {
      status: 910,
      sku: 'PM0078505',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEODEYSP',
      name: "Helleborus orientalis 'Double Ellen Yellow Spotted'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'S37KK9R8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S37KK9R8'
    },
    {
      status: 810,
      sku: 'PM0078490',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309491',
      rtl_batch_array: '6309491',
      name_search: 'HEORED',
      name: 'Helleborus orientalis Red Hybrids',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SP1X5C78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP1X5C78'
    },
    {
      status: 210,
      sku: 'PM0065252',
      core_name: 'Plant',
      sppl_ean: '8720664889170',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161980',
      rtl_batch_array: '6161980',
      name_search: 'VEPROSET',
      name: "Verbascum phoeniceum 'Rosetta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7DLEYWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7DLEYWH'
    },
    {
      status: 210,
      sku: 'PM0065253',
      core_name: 'Plant',
      sppl_ean: '8720664889118',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161982',
      rtl_batch_array: '6161982',
      name_search: 'VESPICA',
      name: "Verbascum 'Spica'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B6AZVA7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6AZVA7C'
    },
    {
      status: 210,
      sku: 'PM0065228',
      core_name: 'Plant',
      sppl_ean: '8720664877504',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161927',
      rtl_batch_array: '6161927',
      name_search: 'PEJGIGAN',
      name: "Petasites japonicus 'Giganteus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L564GVNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L564GVNG'
    },
    {
      status: 210,
      sku: 'PM0078495',
      core_name: 'Plant',
      sppl_ean: '8720626314870',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837473',
      rtl_batch_array: '5837473',
      name_search: 'PEAHAHN',
      name: "Penstemon 'Andenken an F. Hahn'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L782XWN2;52A7BNC1;S9TEB8TX;NWSAL68Y;7JD4RDRB;1H2SGS37',
      statusContent: 'dot',
      hash: 'Y9WWN2VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9WWN2VK'
    },
    {
      status: 210,
      sku: 'PM0078496',
      core_name: 'Plant',
      sppl_ean: '8720626314887',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837475',
      rtl_batch_array: '5837475',
      name_search: 'PEBSPRIN',
      name: "Penstemon 'Blue Spring'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHPV68AD;GPJ51XS2;8JDE1ZWH;GY76DBCP',
      statusContent: 'dot',
      hash: '8Y5AZKX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Y5AZKX8'
    },
    {
      status: 210,
      sku: 'PM0078484',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278281',
      rtl_batch_array: '6278281',
      name_search: 'GAPROCUM',
      name: 'Gaultheria procumbens',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2208,
      chnn_stock_retail: 2208,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SAPSVZ78;2E9BY7E8;N9J99DZA;XV9X8219;T5TT7PBP;66H4BZ4X;16ATYWBR;XYW5E6YX;VVPNT1AR;9WSSE2RC;BCD3XLKT;Y8VHXSEW;W4ATXP61;H476TL3X;TCX6DHZA;WKEK1XBV;RYDYAA46;AJYW4K1V;Z12J3TY1;PVXGE37Y;K2W9LKND;CCYX83RP',
      statusContent: 'dot',
      hash: 'K83RSDNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K83RSDNX'
    },
    {
      status: 210,
      sku: 'PM0039496',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6140822',
      rtl_batch_array: '6140822',
      name_search: 'HESWINE',
      name: "Hemerocallis 'Summer Wine'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AC5A15J;BW7T231S',
      statusContent: 'dot',
      hash: '5W4WSE74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W4WSE74'
    },
    {
      status: 210,
      sku: 'PM0069317',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349903',
      rtl_batch_array: '6349903',
      name_search: 'CACALOTI',
      name: 'Carex calotides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N76GS55H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N76GS55H'
    },
    {
      status: 910,
      sku: 'PM0065206',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAORPLEN',
      name: "Paeonia officinalis 'Rosea Plena'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: '2V9ERVT1',
      statusContent: 'dot',
      hash: 'CAVLZGBV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CAVLZGBV'
    },
    {
      status: 210,
      sku: 'PM0048200',
      core_name: 'Plant',
      sppl_ean: '8720353087726',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014590',
      rtl_batch_array: '6014590',
      name_search: 'ASYALWAY',
      name: "Astilbe 'Youandme Always'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZX6AAPYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX6AAPYZ'
    },
    {
      status: 210,
      sku: 'PM0078740',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209155',
      rtl_batch_array: '6209155',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.545,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'RCLL6A13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCLL6A13'
    },
    {
      status: 210,
      sku: 'PM0065266',
      core_name: 'Plant',
      sppl_ean: '8720664866348',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6162057',
      rtl_batch_array: '6350324, 6162057, 6340029',
      name_search: 'HEITALIC',
      name: 'Helichrysum italicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1924,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZPP3W6DT;X1RHZ2WR;WX9BJ799;NREGBJHG;RZ64DLSY;PT2628NB;REYECZTZ;YJRT36RK;JSRBWGDW;A2H9216Y;EKL84Z4D;5L3W8P3R;61W9ZTT6;4SLDVBX9;PTCJE5CW;487335Y4;AEDGNB2A;Y78KAY3K',
      statusContent: 'dot',
      hash: 'RXG18DSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXG18DSB'
    },
    {
      status: 210,
      sku: 'PM0071134',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228349',
      rtl_batch_array: '6228349',
      name_search: 'SAMHLIPS',
      name: "Salvia microphylla 'Hot Lips'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 602,
      chnn_stock_retail: 602,
      sppl_prcp: 1.912,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1Z23AJEH;AD1PCVB1;BAKA6Y3N;132W86X5;12KNZZ51;YSZKHDCR;DL23CSAX;NZ2W7SZZ;JVRJY9G3;9TVVH5DN;NEVVDG32;R2VCAAVS;6EVREY43;S5ZLPZZJ;J195DWNG;HP554TCE;2E5VSSZH;L3LDB5YX;HKTN4BZB;YTBDHANV;J8SCBA95;5J5DJWW9;TBHTCSX4;TGK6PH6S;JZS8B232;C7ACSS26;DP52WTHW;E19CE7PZ;KW5K1PV4;8ZR9VXZ6',
      statusContent: 'dot',
      hash: 'PTGVTK5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTGVTK5Z'
    },
    {
      status: 210,
      sku: 'PM0065289',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244370',
      rtl_batch_array: '6244370',
      name_search: 'CEHDGARD',
      name: "Cephalotaxus harringtonii 'Duke Gardens'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 967,
      chnn_stock_retail: 967,
      sppl_prcp: 5.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6VJTHN4;199ASL3L;L7EL1RYX',
      statusContent: 'dot',
      hash: 'A95RNGS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A95RNGS9'
    },
    {
      status: 210,
      sku: 'PM0069326',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6380815',
      rtl_batch_array: '6380815',
      name_search: 'CAPLANTA',
      name: 'Carex plantaginea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1440,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2L4ACXLA',
      statusContent: 'dot',
      hash: 'EY1K7NS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY1K7NS1'
    },
    {
      status: 210,
      sku: 'PM0068099',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193581',
      rtl_batch_array: '6193581',
      name_search: 'NYMCHROM',
      name: "Nymphaea 'Marliacea Chromatella'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YWAV81WD;82VDAYYE;TVYY9A87;25DCNNSG',
      statusContent: 'dot',
      hash: '71852YPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71852YPS'
    },
    {
      status: 210,
      sku: 'PM0085296',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373197',
      rtl_batch_array: '6373197',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 492,
      chnn_stock_retail: 492,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'WLY9KHEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLY9KHEH'
    },
    {
      status: 210,
      sku: 'PM0069330',
      core_name: 'Plant',
      sppl_ean: '8720664855861',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112344',
      rtl_batch_array: '6112344',
      name_search: 'CACAERUL',
      name: 'Catananche caerulea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1980,
      chnn_stock_retail: 1980,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2A6GH6PH;S5Z84T1E;98RG6HE5;V3WAGWBN;HCD2W8R3;42TR6W78;C56C7Z35;TTW61CX9;ZYAREZ6V;Y7P4RJ96;SS3SSE9T;THGYGTH3;6KK7PP8V;E8PNSYWD;C5NB51YL;7CGD542E',
      statusContent: 'dot',
      hash: 'VJPRGGC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJPRGGC7'
    },
    {
      status: 210,
      sku: 'PM0081766',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348638',
      rtl_batch_array: '6348638',
      name_search: 'ARUURSI',
      name: 'Arctostaphylos uva-ursi',
      sppl_size_code: '020025C1',
      rng_range_identifier: 'H020025C1',
      rng_range_description: 'H20 cm 25 cm C1',
      sppl_stock_available: 4964,
      chnn_stock_retail: 4964,
      sppl_order_minimum: 3,
      sppl_prcp: 2.511,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6AR34AVV',
      statusContent: 'dot',
      hash: 'A9DY1SNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9DY1SNP'
    },
    {
      status: 210,
      sku: 'PM0081767',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348639',
      rtl_batch_array: '6348639',
      name_search: 'EMNIGRUM',
      name: 'Empetrum nigrum',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 1.911,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '677XRA41',
      statusContent: 'dot',
      hash: 'S32L955R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S32L955R'
    },
    {
      status: 210,
      sku: 'PM0078741',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209158',
      rtl_batch_array: '6209158',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.803,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'WYA9L1E2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYA9L1E2'
    },
    {
      status: 210,
      sku: 'PM0081768',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348640',
      rtl_batch_array: '6348640, 6348661',
      name_search: 'ERARBORE',
      name: 'Erica arborea',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYY88D5N',
      statusContent: 'dot',
      hash: 'NH2ETT9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH2ETT9Z'
    },
    {
      status: 210,
      sku: 'PM0068804',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345223',
      rtl_batch_array: '6353345, 6345223',
      name_search: 'SASCLARE',
      name: 'Salvia sclarea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 1971,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RE3V9TYG;LN2K94DS;CEHNGW6V;YV46K9PV',
      statusContent: 'dot',
      hash: 'BRA2JWE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRA2JWE6'
    },
    {
      status: 210,
      sku: 'PM0081769',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348662',
      rtl_batch_array: '6348662',
      name_search: 'ERLUSITA',
      name: 'Erica lusitanica',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LSKEB9PJ',
      statusContent: 'dot',
      hash: 'N5DJPNV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5DJPNV3'
    },
    {
      status: 210,
      sku: 'PM0065290',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244371',
      rtl_batch_array: '6244371',
      name_search: 'CEHDGARD',
      name: "Cephalotaxus harringtonii 'Duke Gardens'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 509,
      chnn_stock_retail: 509,
      sppl_prcp: 6.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6VJTHN4;199ASL3L;L7EL1RYX',
      statusContent: 'dot',
      hash: 'LD875BZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LD875BZJ'
    },
    {
      status: 210,
      sku: 'PM0048276',
      core_name: 'Plant',
      sppl_ean: '8720664687028',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014818',
      rtl_batch_array: '6014818',
      name_search: 'DELOSPER',
      name: 'Delosperma Mix',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYY9TRZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYY9TRZY'
    },
    {
      status: 210,
      sku: 'PM0078469',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309507',
      rtl_batch_array: '6309507',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'VXCS4L9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXCS4L9K'
    },
    {
      status: 210,
      sku: 'PM0081770',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348663',
      rtl_batch_array: '6348663',
      name_search: 'ERAEGOLD',
      name: "Erica arborea 'Estrella Gold'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K38Y7GRJ',
      statusContent: 'dot',
      hash: 'RXBA8GSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXBA8GSP'
    },
    {
      status: 210,
      sku: 'PM0078742',
      core_name: 'Plant',
      sppl_ean: '8720664862623',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162326',
      rtl_batch_array: '6162326',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_prcp: 5.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'SXX9YZHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXX9YZHW'
    },
    {
      status: 910,
      sku: 'PM0078471',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'L3RC64SJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L3RC64SJ'
    },
    {
      status: 910,
      sku: 'PM0078472',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309510',
      rtl_batch_array: '6309510',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 8.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: '8XJB6ZTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XJB6ZTH'
    },
    {
      status: 210,
      sku: 'PM0078473',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309511',
      rtl_batch_array: '6309511',
      name_search: 'LIJROTUN',
      name: "Ligustrum japonicum 'Rotundifolium'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4Z838WZC;6S3YA3XV',
      statusContent: 'dot',
      hash: '9GRTAETH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GRTAETH'
    },
    {
      status: 810,
      sku: 'PM0054896',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307843',
      rtl_batch_array: '6307843',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: 'CXLEGV1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXLEGV1C'
    },
    {
      status: 210,
      sku: 'PM0078474',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385788',
      rtl_batch_array: '6309512, 6385788, 6356662',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 3615,
      sppl_prcp: 1.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '9DJTVHCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DJTVHCB'
    },
    {
      status: 210,
      sku: 'PM0081771',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348664',
      rtl_batch_array: '6348664',
      name_search: 'ERAAGOLD',
      name: "Erica arborea 'Albert's Gold'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V2NSJCZR;5S48SB7L;XJNWK5GN;XSV9RXNK;4N9TGWSV',
      statusContent: 'dot',
      hash: 'E8YEEV4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8YEEV4Y'
    },
    {
      status: 810,
      sku: 'PM0078475',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309513',
      rtl_batch_array: '5856645, 6309513',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 35,
      chnn_stock_retail: 535,
      sppl_prcp: 6.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'YW1S42ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW1S42ZD'
    },
    {
      status: 210,
      sku: 'PM0065280',
      core_name: 'Plant',
      sppl_ean: '8720664882348',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162496',
      rtl_batch_array: '6162496',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 899,
      chnn_stock_retail: 899,
      sppl_prcp: 4.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'K91X1LVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K91X1LVV'
    },
    {
      status: 210,
      sku: 'PM0078476',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309514',
      rtl_batch_array: '6309514',
      name_search: 'OSHGOSHI',
      name: "Osmanthus heterophyllus 'Goshiki'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7DT7HZTW;H3611KS5;HHWLDZG5;C4LVH8XZ;P61R6DLC;WVR7T9BV;YD81PWH8;VX335SLL;CNKTN4N8;GX5HBXJ9;NLYT7ZPN;26RLBC7W;D5PTRYSY;Y6LGAB3K;TKLEDCZP;XG83C181;CGDSPS5B;DC98HZPC',
      statusContent: 'dot',
      hash: 'B5LCYNZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5LCYNZ7'
    },
    {
      status: 810,
      sku: 'PM0078477',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317590',
      rtl_batch_array: '6317590',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_prcp: 8.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '5EGXT5S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EGXT5S2'
    },
    {
      status: 210,
      sku: 'PM0078478',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309516',
      rtl_batch_array: '6309516',
      name_search: 'PIJDEBUT',
      name: "Pieris japonica 'Debutante'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '28C4G9Z7;TJBDT4GA;E736XHBT;1SD1LN4B;GYBD11SY;T6AKPDLP;938DW86L;Z6ALPBB5;ET5TKR37;HCJY8V13;N52Z4R56;83YVXS5Z',
      statusContent: 'dot',
      hash: '9K7Z1S6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9K7Z1S6N'
    },
    {
      status: 210,
      sku: 'PM0081772',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348670',
      rtl_batch_array: '6348670',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'PH4W8YTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH4W8YTA'
    },
    {
      status: 210,
      sku: 'PM0071127',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228342',
      rtl_batch_array: '6228342, 6300788',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 155,
      chnn_stock_retail: 446,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'R2BSDV2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2BSDV2D'
    },
    {
      status: 210,
      sku: 'PM0081773',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348671',
      rtl_batch_array: '6348671',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'HP8D92BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP8D92BY'
    },
    {
      status: 210,
      sku: 'PM0085297',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373198',
      rtl_batch_array: '6373198',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: 'A8XXLTSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8XXLTSA'
    },
    {
      status: 210,
      sku: 'PM0066706',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174822',
      rtl_batch_array: '6174822',
      name_search: 'LOQVICTO',
      name: "Lobelia 'Queen Victoria'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RA5AY2XN;GRB37WC6;J5PR3C23;JZY4XBH8;4TC9X8DD;21BB76YB',
      statusContent: 'dot',
      hash: 'LJ94RY92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ94RY92'
    },
    {
      status: 810,
      sku: 'PM0081774',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348673',
      rtl_batch_array: '6348673',
      name_search: 'FEGLAUCA',
      name: 'Festuca glauca',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSVA34T4;ABP86W12;8EDK3VYW',
      statusContent: 'dot',
      hash: 'AT4WR1BS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AT4WR1BS'
    },
    {
      status: 910,
      sku: 'PM0048275',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEBGIANT',
      name: "Delosperma 'Beaufort Giant'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'ZHA6SB3S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZHA6SB3S'
    },
    {
      status: 210,
      sku: 'PM0039658',
      core_name: 'Plant',
      sppl_ean: '8720664800953',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905488',
      rtl_batch_array: '5905488',
      name_search: 'PENNISET',
      name: 'Pennisetum',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9E9E5X53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E9E5X53'
    },
    {
      status: 210,
      sku: 'PM0081775',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348675',
      rtl_batch_array: '6348675',
      name_search: 'VACCBLUE',
      name: "Vaccinium corymbosum 'Bluecrop'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2XNBEBWB;B7722WXJ;6H776WK8;BT18YHKT;B6LSDLDW;X2KGEJ55;6L8JWB9N;P8CD2N7Y;TJYH8Z7X;74PK3H97;D8JH3L2A',
      statusContent: 'dot',
      hash: 'RRAH2EG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRAH2EG5'
    },
    {
      status: 210,
      sku: 'PM0078512',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310175',
      rtl_batch_array: '6310175',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1212,
      chnn_stock_retail: 1212,
      sppl_prcp: 1.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'GREYX7RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GREYX7RT'
    },
    {
      status: 210,
      sku: 'PM0048242',
      core_name: 'Plant',
      sppl_ean: '8720353009391',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014693',
      rtl_batch_array: '6014693',
      name_search: 'CABBLUE',
      name: "Carex 'Bunny Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '92ZLG134',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92ZLG134'
    },
    {
      status: 210,
      sku: 'PM0066709',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233458',
      rtl_batch_array: '6233458',
      name_search: 'PEAALBA',
      name: "Persicaria amplexicaulis 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3607,
      chnn_stock_retail: 3607,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBTRPWST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBTRPWST'
    },
    {
      status: 210,
      sku: 'PM0085298',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373199',
      rtl_batch_array: '6373199',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 841,
      chnn_stock_retail: 841,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: 'S3ZAKYTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3ZAKYTK'
    },
    {
      status: 810,
      sku: 'PM0065292',
      core_name: 'Plant',
      sppl_ean: '8720664856172',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162679',
      rtl_batch_array: '6162679',
      name_search: 'CEHDGARD',
      name: "Cephalotaxus harringtonii 'Duke Gardens'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_prcp: 7.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6VJTHN4;199ASL3L;L7EL1RYX',
      statusContent: 'dot',
      hash: '98929ZKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98929ZKP'
    },
    {
      status: 210,
      sku: 'PM0078513',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310176',
      rtl_batch_array: '6310176',
      name_search: 'AMSPICAT',
      name: 'Amelanchier spicata',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 1.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HK4BBR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HK4BBR1'
    },
    {
      status: 210,
      sku: 'PM0081776',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348677',
      rtl_batch_array: '6348677',
      name_search: 'VACDIXI',
      name: "Vaccinium corymbosum 'Dixi'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9RLSWP8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RLSWP8H'
    },
    {
      status: 210,
      sku: 'PM0078514',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310177',
      rtl_batch_array: '6310177',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: '5K4PDWAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K4PDWAD'
    },
    {
      status: 210,
      sku: 'PM0078515',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310179',
      rtl_batch_array: '6310179',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: 'RD9NCW6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD9NCW6K'
    },
    {
      status: 810,
      sku: 'PM0048259',
      core_name: 'Plant',
      sppl_ean: '8720664685376',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014769',
      rtl_batch_array: '6014769',
      name_search: 'EDI62993',
      name: "Colchicum autumnale 'Alboplenum'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 3.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K5RSWW45;XRX8D5JN;NLL57KTG;1PG92538',
      statusContent: 'dot',
      hash: '9YJY463H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YJY463H'
    },
    {
      status: 210,
      sku: 'PM0085299',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373200',
      rtl_batch_array: '6373200',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: 'D7NVRHSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7NVRHSB'
    },
    {
      status: 210,
      sku: 'PM0078516',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310180',
      rtl_batch_array: '6310180',
      name_search: 'BEJULIAN',
      name: 'Berberis julianae',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3800,
      chnn_stock_retail: 3800,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CR5PR6LW;ZWPRE3WN;BRH4S6JY;YH3RCTE5;LCD1WJYL',
      statusContent: 'dot',
      hash: 'DG5NKLRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG5NKLRH'
    },
    {
      status: 210,
      sku: 'PM0078517',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310181',
      rtl_batch_array: '6310181',
      name_search: 'BEJULIAN',
      name: 'Berberis julianae',
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 1721,
      chnn_stock_retail: 1721,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CR5PR6LW;ZWPRE3WN;BRH4S6JY;YH3RCTE5;LCD1WJYL',
      statusContent: 'dot',
      hash: 'JVJPE3CG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVJPE3CG'
    },
    {
      status: 210,
      sku: 'PM0078518',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310183',
      rtl_batch_array: '6310183',
      name_search: 'BETHUNBE',
      name: 'Berberis thunbergii',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 594,
      chnn_stock_retail: 594,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VR4D883C;JYT62D7E;YXAHYVN7;J5B3L6ZR;STRL4G62;8NNCVVSS;Z5J8YP2K;WZ3RLL1A;9A3RJR1S;CWCB45VD',
      statusContent: 'dot',
      hash: 'T3S5SZKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3S5SZKL'
    },
    {
      status: 910,
      sku: 'PM0078519',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310185',
      rtl_batch_array: '6310185',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 5.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: 'W457LPNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W457LPNZ'
    },
    {
      status: 210,
      sku: 'PM0065284',
      core_name: 'Plant',
      sppl_ean: '8720664863002',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162503',
      rtl_batch_array: '6162503',
      name_search: 'FANITIDA',
      name: 'Fargesia nitida',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RX7LD9EV;W6A24647',
      statusContent: 'dot',
      hash: 'EB33AW8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB33AW8Y'
    },
    {
      status: 210,
      sku: 'PM0078520',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310186',
      rtl_batch_array: '6310186',
      name_search: 'BEPENDUL',
      name: 'Betula pendula',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 733,
      chnn_stock_retail: 733,
      sppl_prcp: 1.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2L111WX;P674XDDL;7G125933;GJ69KJHY;5VTCDCKL;LD1WP2V1;BD4TDAYE;E4N23XWJ',
      statusContent: 'dot',
      hash: '4DH5GH7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DH5GH7N'
    },
    {
      status: 210,
      sku: 'PM0078521',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310187',
      rtl_batch_array: '6310187',
      name_search: 'BEPENDUL',
      name: 'Betula pendula',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 788,
      chnn_stock_retail: 788,
      sppl_prcp: 1.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2L111WX;P674XDDL;7G125933;GJ69KJHY;5VTCDCKL;LD1WP2V1;BD4TDAYE;E4N23XWJ',
      statusContent: 'dot',
      hash: 'DG6KXL2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG6KXL2T'
    },
    {
      status: 210,
      sku: 'PM0078522',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385808',
      rtl_batch_array: '6310192, 6385808',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 3020,
      chnn_stock_retail: 7755,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'XP2NWYW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP2NWYW1'
    },
    {
      status: 210,
      sku: 'PM0085300',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373201',
      rtl_batch_array: '6373201',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'J1V7736B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1V7736B'
    },
    {
      status: 910,
      sku: 'PM0048244',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAPPAMIR',
      name: "Carex panicea 'Pamira'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'E8T475VS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E8T475VS'
    },
    {
      status: 210,
      sku: 'PM0085301',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373202',
      rtl_batch_array: '6373202',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2386,
      chnn_stock_retail: 2386,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'WSWBAYPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSWBAYPK'
    },
    {
      status: 210,
      sku: 'PM0078712',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304840',
      rtl_batch_array: '6304840',
      name_search: 'GAJPINWH',
      name: 'Gardenia jasm. Pinwheel',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3252,
      chnn_stock_retail: 3252,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1H49DZVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H49DZVP'
    },
    {
      status: 210,
      sku: 'PM0085302',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373203',
      rtl_batch_array: '6373203',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'ETBW51CS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETBW51CS'
    },
    {
      status: 210,
      sku: 'PM0078731',
      core_name: 'Plant',
      sppl_ean: '8720664872561',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6153843',
      rtl_batch_array: '6153843',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 2452,
      chnn_stock_retail: 2452,
      sppl_order_minimum: 3,
      sppl_prcp: 0.798,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'BWA3SZPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWA3SZPD'
    },
    {
      status: 810,
      sku: 'PM0078732',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209129',
      rtl_batch_array: '6209129',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 5.545,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: 'Z7WHZER9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7WHZER9'
    },
    {
      status: 210,
      sku: 'PM0078733',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209133',
      rtl_batch_array: '6209133',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '100125C5GEB',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 5.323,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'H43TENCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H43TENCB'
    },
    {
      status: 210,
      sku: 'PM0085303',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373204',
      rtl_batch_array: '6373204',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 582,
      chnn_stock_retail: 582,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: '1GP7RNEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GP7RNEK'
    },
    {
      status: 210,
      sku: 'PM0081777',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348678',
      rtl_batch_array: '6348678',
      name_search: 'VACCOVIL',
      name: "Vaccinium corymbosum 'Coville'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CNNXKWJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNNXKWJR'
    },
    {
      status: 210,
      sku: 'PM0078734',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356656',
      rtl_batch_array: '6209135, 6356656',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '175200C5',
      rng_range_identifier: 'H175200C5',
      rng_range_description: 'H175 cm 200 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 3000,
      sppl_prcp: 5.348,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '7RJD4AY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RJD4AY6'
    },
    {
      status: 910,
      sku: 'PM0085304',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373205',
      rtl_batch_array: '6373205, 6385470',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 215,
      sppl_prcp: 9.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: '96R48D8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96R48D8N'
    },
    {
      status: 210,
      sku: 'PM0078736',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209141',
      rtl_batch_array: '6209141',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C34',
      rng_range_identifier: 'H080100C3.5',
      rng_range_description: 'H80 cm 100 cm C3.5',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 2.511,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'D7VK5R4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7VK5R4W'
    },
    {
      status: 810,
      sku: 'PM0071132',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228347',
      rtl_batch_array: '6228347',
      name_search: 'PRCUNEAT',
      name: 'Prostanthera cuneata',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DN1JDC8L;TWR2AGL2;LEYYX3DL;CB41Z9N2;94ED8D3P;99TVW9BA;JC8W3LLB;G32B49NJ;NCXKV3NZ;5ZHVSY42',
      statusContent: 'dot',
      hash: 'HA2S8S8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HA2S8S8W'
    },
    {
      status: 210,
      sku: 'PM0085305',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373206',
      rtl_batch_array: '6373206',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 953,
      chnn_stock_retail: 953,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: '7N73CES1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N73CES1'
    },
    {
      status: 210,
      sku: 'PM0085306',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373207',
      rtl_batch_array: '6373207',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1462,
      chnn_stock_retail: 1462,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'PJE52A36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJE52A36'
    },
    {
      status: 210,
      sku: 'PM0085307',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373208',
      rtl_batch_array: '6373208',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1033,
      chnn_stock_retail: 1033,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: '7TZXR1AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TZXR1AK'
    },
    {
      status: 210,
      sku: 'PM0078737',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209144',
      rtl_batch_array: '6209144',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 5.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '9WPZBZC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WPZBZC3'
    },
    {
      status: 210,
      sku: 'PM0085308',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373209',
      rtl_batch_array: '6373209',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 917,
      chnn_stock_retail: 917,
      sppl_prcp: 3.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'BVK7TXZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVK7TXZK'
    },
    {
      status: 210,
      sku: 'PM0085309',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373210',
      rtl_batch_array: '6373210',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1118,
      chnn_stock_retail: 1118,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'N78VZ51P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N78VZ51P'
    },
    {
      status: 210,
      sku: 'PM0069318',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301487',
      rtl_batch_array: '6301487, 6339773',
      name_search: 'CACFCURL',
      name: "Carex comans 'Frosted Curls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 1497,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XWKKGR1V;WBG361VY;99GYTLL2;SX6AA7AB;TAT6WW2B;4W5AP4D3;5XED8LE6;TV536JZ1;W7KPK1J9;7971T56V;BD5VP1GT;CACEEJZK;DXGX5LHS;TZWJXXTJ;CTT6XEBW;TXCN2PBC;ZSNPT7LE;YLWRR1D7;HA74WY1T;423BPXP4;8Y43J9RJ;5XD6K3KB;7CY4KK8R',
      statusContent: 'dot',
      hash: '816HHJ4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '816HHJ4W'
    },
    {
      status: 210,
      sku: 'PM0078739',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209152',
      rtl_batch_array: '6209152',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.522,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'XNTX1D56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNTX1D56'
    },
    {
      status: 810,
      sku: 'PM0078745',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317586',
      rtl_batch_array: '6126732, 6317586, 6334249',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 4145,
      sppl_prcp: 4.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '1CECWKT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CECWKT3'
    },
    {
      status: 210,
      sku: 'PM0071133',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334492',
      rtl_batch_array: '6228348, 6334492',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '030050C3',
      rng_range_identifier: 'H030050C3',
      rng_range_description: 'H30 cm 50 cm C3',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7524,
      sppl_prcp: 2.793,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'ESCL3XR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESCL3XR2'
    },
    {
      status: 210,
      sku: 'PM0078744',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356655',
      rtl_batch_array: '6076579, 6356655',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '200225C74',
      rng_range_identifier: 'H200225C7.5',
      rng_range_description: 'H200 cm 225 cm C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 2250,
      sppl_prcp: 8.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'RK5L8WB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RK5L8WB5'
    },
    {
      status: 210,
      sku: 'PM0078735',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356657',
      rtl_batch_array: '6209139, 6356657',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 240,
      chnn_stock_retail: 2740,
      sppl_prcp: 7.758,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'GWLVZW2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWLVZW2Z'
    },
    {
      status: 210,
      sku: 'PM0078743',
      core_name: 'Plant',
      sppl_ean: '8720664862678',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162328',
      rtl_batch_array: '6162328',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 2280,
      chnn_stock_retail: 2280,
      sppl_prcp: 5.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'LGDKA1PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGDKA1PA'
    },
    {
      status: 210,
      sku: 'PM0085310',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373211',
      rtl_batch_array: '6373211',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 623,
      chnn_stock_retail: 623,
      sppl_prcp: 9.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: '6AGKJ25S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6AGKJ25S'
    },
    {
      status: 810,
      sku: 'PM0078738',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370588',
      rtl_batch_array: '6209149, 6370588',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 48,
      chnn_stock_retail: 2356,
      sppl_prcp: 8.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'HRS6VSBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRS6VSBS'
    },
    {
      status: 810,
      sku: 'PM0078511',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310174',
      rtl_batch_array: '6310174',
      name_search: 'ALGLUTIN',
      name: 'Alnus glutinosa',
      sppl_size_code: '175200C5',
      rng_range_identifier: 'H175200C5',
      rng_range_description: 'H175 cm 200 cm C5',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 4.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BW17L2SZ;LV96GEJD;CT86XB4Z;HYDK4GYV;HKJXJXSX',
      statusContent: 'dot',
      hash: 'N97GX7AR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N97GX7AR'
    },
    {
      status: 210,
      sku: 'PM0078468',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309506',
      rtl_batch_array: '6309506',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: '6LVPH1B5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LVPH1B5'
    },
    {
      status: 210,
      sku: 'PM0071128',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228343',
      rtl_batch_array: '6228343',
      name_search: 'PHSNOWBE',
      name: "Philadelphus 'Snowbelle'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_prcp: 2.355,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPBCVAY5;RC5JVBYP',
      statusContent: 'dot',
      hash: '41VTJ9HY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41VTJ9HY'
    },
    {
      status: 210,
      sku: 'PM0078479',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309517',
      rtl_batch_array: '6309517',
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV7A8VKK;2D7HYSVP;5E11VCT7;WEDN342H;6CBCVY9E;DRD2X2DT;5R8EASNG;TT28BTWY;2189SZS3;YZGT72RE;1BG4Z21C;RSHDGCEX;S1V6CXEZ;PW1TVV1A;27N88R7Y',
      statusContent: 'dot',
      hash: 'T2V917JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2V917JE'
    },
    {
      status: 210,
      sku: 'PM0085311',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373212',
      rtl_batch_array: '6373212',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1066,
      chnn_stock_retail: 1066,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'YGZXWL3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGZXWL3Y'
    },
    {
      status: 210,
      sku: 'PM0078480',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309518',
      rtl_batch_array: '6309518',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 394,
      chnn_stock_retail: 394,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      statusContent: 'dot',
      hash: 'SYRPXVP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYRPXVP7'
    },
    {
      status: 810,
      sku: 'PM0078481',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309519',
      rtl_batch_array: '6309519, 6338389',
      name_search: 'PIJVARIE',
      name: "Pieris japonica 'Variegata'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 2187,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XK5JHNLD',
      statusContent: 'dot',
      hash: 'X2VHSE4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2VHSE4H'
    },
    {
      status: 210,
      sku: 'PM0066710',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243716',
      rtl_batch_array: '6243716',
      name_search: 'PHSSCAND',
      name: 'Phlox (P) Sweet Summer Candy',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6500,
      chnn_stock_retail: 6500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7LZX7PR2;AVP61D91;62SNKW7K',
      statusContent: 'dot',
      hash: 'ET2E2Y6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET2E2Y6H'
    },
    {
      status: 910,
      sku: 'PM0065288',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEHDGARD',
      name: "Cephalotaxus harringtonii 'Duke Gardens'",
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      imageCore: 'H6VJTHN4;199ASL3L;L7EL1RYX',
      statusContent: 'dot',
      hash: 'E1DYHGNJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E1DYHGNJ'
    },
    {
      status: 210,
      sku: 'PM0069340',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6290214',
      rtl_batch_array: '6329896, 6290214, 6320836',
      name_search: 'CIRTBWON',
      name: "Cirsium rivulare 'Trevors Blue Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 864,
      sppl_order_minimum: 3,
      sppl_prcp: 1.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCZLW4SR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCZLW4SR'
    },
    {
      status: 210,
      sku: 'PM0085312',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373213',
      rtl_batch_array: '6373213',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1305,
      chnn_stock_retail: 1305,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'JSZ1G6EK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSZ1G6EK'
    },
    {
      status: 210,
      sku: 'PM0078730',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '5932443',
      rtl_batch_array: '5932443, 6339873, 6348407',
      name_search: 'PEAMPLEX',
      name: 'Persicaria amplexicaulis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1615,
      chnn_stock_retail: 9188,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KG2H81CK;V5P722P1;18NBHG9T;S36JV4GX;X1TV7BKY;C2DS4669;K12YL938;6DJDY2AS;5KDJJNBG;XSTEGW9V;X6XE3BWN;THXAB7DT;WJL4TWKW;HKDNXX3Y;GZVH5EWY;7DNDHWK1',
      statusContent: 'dot',
      hash: '9NNP535C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NNP535C'
    },
    {
      status: 210,
      sku: 'PM0069319',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301488',
      rtl_batch_array: '6301488',
      name_search: 'CAFKIWI',
      name: "Carex flagellifera 'Kiwi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XLE652L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLE652L5'
    },
    {
      status: 210,
      sku: 'PM0068131',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193659',
      rtl_batch_array: '6193659',
      name_search: 'OEFFLAMI',
      name: "Oenanthe fistulosa 'Flamingo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X5BD2ERV;HCP3RV37;BEBXCTT1;WKWCJG7S',
      statusContent: 'dot',
      hash: 'TBJT495L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBJT495L'
    },
    {
      status: 210,
      sku: 'PM0065275',
      core_name: 'Plant',
      sppl_ean: '8720664872790',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162336',
      rtl_batch_array: '6162336',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 1028,
      chnn_stock_retail: 1028,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: '9BKZ8JHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BKZ8JHC'
    },
    {
      status: 210,
      sku: 'PM0065281',
      core_name: 'Plant',
      sppl_ean: '8720664882300',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162499',
      rtl_batch_array: '6162499',
      name_search: 'PRLVNES',
      name: "Prunus laurocerasus 'Van Nes'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P23BHVJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P23BHVJ2'
    },
    {
      status: 210,
      sku: 'PM0068098',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193569',
      rtl_batch_array: '6193569',
      name_search: 'NYFABIOL',
      name: "Nymphaea 'Fabiola'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VVA6ATK7',
      statusContent: 'dot',
      hash: '143V11TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '143V11TN'
    },
    {
      status: 810,
      sku: 'PM0069331',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301496',
      rtl_batch_array: '6301496',
      name_search: 'CATCALBA',
      name: "Catananche caerulea 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HHYNXX5T;YH581VTA;52Z369XD;885W4653;SV34YAK8;5WNDYCCB;TWW4WSD9;EKWAB2EZ;DZLERS55',
      statusContent: 'dot',
      hash: 'HZJ8SV7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZJ8SV7S'
    },
    {
      status: 210,
      sku: 'PM0048238',
      core_name: 'Plant',
      sppl_ean: '8720664859319',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147615',
      rtl_batch_array: '6147615',
      name_search: 'DEYPEARL',
      name: "Delphinium 'Yellow Pearl'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANHNTXAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANHNTXAV'
    },
    {
      status: 210,
      sku: 'PM0065286',
      core_name: 'Plant',
      sppl_ean: '8720664863293',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162505',
      rtl_batch_array: '6162505',
      name_search: 'FASAWOND',
      name: "Fargesia scabrida 'Asian Wonder'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2Y81GDV;6GHXCB27;Y1AWLJDK;Y973Z946;K9CESGSD;WDETGSX4;WNZL7SS5;CNTHHX7Z;G6X3DALL;KBW7E83A;P63VE7R3',
      statusContent: 'dot',
      hash: 'HETRH1S7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HETRH1S7'
    },
    {
      status: 810,
      sku: 'PM0071129',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228344',
      rtl_batch_array: '6228344',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_prcp: 3.378,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'PGYB4WCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGYB4WCG'
    },
    {
      status: 210,
      sku: 'PM0039686',
      core_name: 'Plant',
      sppl_ean: '8720349491216',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905542',
      rtl_batch_array: '5905542',
      name_search: 'ILMLRASC',
      name: 'Ilex meserveae Little Rascal',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 4708,
      chnn_stock_retail: 4708,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13GARV24;71E7CTSY;BS8SHAWL;XPE48L27',
      statusContent: 'dot',
      hash: '1KJCN7B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KJCN7B8'
    },
    {
      status: 210,
      sku: 'PM0065291',
      core_name: 'Plant',
      sppl_ean: '8720664856165',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162678',
      rtl_batch_array: '6162678',
      name_search: 'CEHDGARD',
      name: "Cephalotaxus harringtonii 'Duke Gardens'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 6.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6VJTHN4;199ASL3L;L7EL1RYX',
      statusContent: 'dot',
      hash: 'S1G3L86E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1G3L86E'
    },
    {
      status: 210,
      sku: 'PM0069322',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349912',
      rtl_batch_array: '6349912',
      name_search: 'CAMRAUHR',
      name: "Carex montana 'Rauhreif'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NBHPDSTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBHPDSTT'
    },
    {
      status: 210,
      sku: 'PM0069324',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6386080',
      rtl_batch_array: '6301490, 6267768, 6386080',
      name_search: 'CAMMOSTE',
      name: "Carex morrowii 'Mosten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 2322,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KL7KKLW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KL7KKLW6'
    },
    {
      status: 910,
      sku: 'PM0069334',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CERGRIFF',
      name: 'Ceratostigma griffithii',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'E2BGB6RL;WAEP3Y4H;ESNTJ345;EVC23VT3;48KCNG4W',
      statusContent: 'dot',
      hash: '75C7PVB5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '75C7PVB5'
    },
    {
      status: 210,
      sku: 'PM0048300',
      core_name: 'Plant',
      sppl_ean: '8720664860889',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147744',
      rtl_batch_array: '6147744',
      name_search: 'ECPBTRUF',
      name: "Echinacea purpurea 'Blackberry Truffle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 663,
      chnn_stock_retail: 663,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGGYVNJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGGYVNJY'
    },
    {
      status: 910,
      sku: 'PM0078525',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'VBNTE9XB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VBNTE9XB'
    },
    {
      status: 210,
      sku: 'PM0078527',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310210',
      rtl_batch_array: '6310210',
      name_search: 'CECANADE',
      name: 'Cercis canadensis',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LVTLE62R;AH74D8P4;545RX5ZD;54E271T2;RJ41P2H4;P86Y86VH;4KE5VSDD;HZTGKDH6;JX8HCT51;4S697TKJ;JWLCTNWZ;KNWPRTJK;CX7HPD7W;R47NA8E7;JNGNS34S;TVAXKYJ8;8Z8YYAYB;YWSDW6E8;TVX1V7HG;AWZ6NCWS;47K15XY4',
      statusContent: 'dot',
      hash: 'CRXBNB4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRXBNB4B'
    },
    {
      status: 210,
      sku: 'PM0078528',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310211',
      rtl_batch_array: '6310211',
      name_search: 'COALBA',
      name: 'Cornus alba',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2389,
      chnn_stock_retail: 2389,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCJ416P1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCJ416P1'
    },
    {
      status: 210,
      sku: 'PM0078529',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310212',
      rtl_batch_array: '6310212',
      name_search: 'COALBA',
      name: 'Cornus alba',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 4.831,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8BEH6AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8BEH6AX'
    },
    {
      status: 910,
      sku: 'PM0078530',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'ES185KNY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ES185KNY'
    },
    {
      status: 210,
      sku: 'PM0068121',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193630',
      rtl_batch_array: '6193630',
      name_search: 'IRLRQUEE',
      name: "Iris laevigata 'Rose Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8SN3YTWN;8RWD2W2R',
      statusContent: 'dot',
      hash: 'K4ES97JT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4ES97JT'
    },
    {
      status: 210,
      sku: 'PM0081778',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369632',
      rtl_batch_array: '6369632',
      name_search: 'ASSSHADO',
      name: "Astelia 'Silver Shadow'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ENKEJWC4;E66YR88H;Y77HVKJ3;JSNDX748;XY4ATWYX;AEBCSZ54;V1786VCZ;DPWWDE9C;8P56SGCV;PZR9XWL6;VCSZ6HXE',
      statusContent: 'dot',
      hash: '2R27WNSL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2R27WNSL'
    },
    {
      status: 210,
      sku: 'PM0085313',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373214',
      rtl_batch_array: '6373214',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'X6XY9799',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6XY9799'
    },
    {
      status: 910,
      sku: 'PM0078531',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: '62AW632G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '62AW632G'
    },
    {
      status: 910,
      sku: 'PM0078532',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'HZXZXYAT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HZXZXYAT'
    },
    {
      status: 210,
      sku: 'PM0078746',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307928',
      rtl_batch_array: '6307928',
      name_search: 'ACFBGOLD',
      name: "Achillea fil. 'Bishkek Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z5GJ5SS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5GJ5SS4'
    },
    {
      status: 210,
      sku: 'PM0081779',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356097',
      rtl_batch_array: '6356097, 6348738',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'D4SHJ17R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4SHJ17R'
    },
    {
      status: 910,
      sku: 'PM0078848',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEPARIS',
      name: "Heuchera 'Paris'",
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      imageCore: '4V1T7ER5',
      statusContent: 'dot',
      hash: 'XD2XXBHH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XD2XXBHH'
    },
    {
      status: 810,
      sku: 'PM0078849',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313185',
      rtl_batch_array: '6313185',
      name_search: 'LUGBLUE',
      name: "Lupinus 'Gallery Blue'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6A2XVBGP;CSWEN8B5;Y52GX7JR;S4ZE4B55;Z4KHSL8K;Y6W1NLC6;SSNSYA7R;86ENKC6N;T8WWSXNH;Y8R8XVLP;AWKN8EZ1;WKYYGJGE',
      statusContent: 'dot',
      hash: 'BL358V5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BL358V5T'
    },
    {
      status: 210,
      sku: 'PM0085314',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373215',
      rtl_batch_array: '6373215',
      name_search: 'WECANDID',
      name: "Weigela 'Candida'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1009,
      chnn_stock_retail: 1009,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4GXH9E8;7BS3ASET',
      statusContent: 'dot',
      hash: 'RGW4G4BG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGW4G4BG'
    },
    {
      status: 810,
      sku: 'PM0078850',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313186',
      rtl_batch_array: '6313186',
      name_search: 'LUGPINK',
      name: "Lupinus 'Gallery Pink'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9B1KH9S9;E272T6WK;X72BPDTK;TK53SVH2;DWHVE5K4;L65527HR;596EL16Z;CACX2TPK;7JJZS667;7ZNBS349;6HLNRK9N',
      statusContent: 'dot',
      hash: '5V423WPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V423WPN'
    },
    {
      status: 810,
      sku: 'PM0078851',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313187',
      rtl_batch_array: '6313187',
      name_search: 'LUGRED',
      name: "Lupinus 'Gallery Red'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YALKRHB8;N26N3V6G;ZHW2TRLN;RBS1XEL5',
      statusContent: 'dot',
      hash: 'WV8ELA7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV8ELA7V'
    },
    {
      status: 810,
      sku: 'PM0078852',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313188',
      rtl_batch_array: '6313188',
      name_search: 'LUGWHITE',
      name: "Lupinus 'Gallery White'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GPJEK462;ZWAXBG6G;JX3SXV8B;172HHSV3;XEJ653ZE;5ZZBKY1C;2W83DE1C;Y8ECLEY7;GDSYAYWD;EHXET38X;C171PXSY;4PX2VYCE;B6V7YAED;BHJ2NRCJ;PYSXHL9V;1VRS7XDN;CYY2AT57;YJBZLNWW',
      statusContent: 'dot',
      hash: '5A2CGZCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5A2CGZCB'
    },
    {
      status: 810,
      sku: 'PM0078853',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313189',
      rtl_batch_array: '6313189',
      name_search: 'LUGYELLO',
      name: "Lupinus 'Gallery Yellow'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CNRYJBK2;8E54WA3L;W2AB2BL1;19H4L3ZR;BKCWAADY',
      statusContent: 'dot',
      hash: 'V1A26P67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1A26P67'
    },
    {
      status: 210,
      sku: 'PM0081780',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348740',
      rtl_batch_array: '6348740',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1450,
      chnn_stock_retail: 1450,
      sppl_prcp: 0.229,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: 'B2J3V7XZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2J3V7XZ'
    },
    {
      status: 210,
      sku: 'PM0068148',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193693',
      rtl_batch_array: '6193693',
      name_search: 'TYANGUST',
      name: 'Typha angustifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YLZZXJR4;W4ERHZ3X;T62HSYW4;J5V6SANT;BPB68GL7;RR7R2T6C;E72E3V7Z;W4JHPKCL;HYKGK1RX',
      statusContent: 'dot',
      hash: 'BCRSZ6D8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCRSZ6D8'
    },
    {
      status: 210,
      sku: 'PM0065239',
      core_name: 'Plant',
      sppl_ean: '8720664884151',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161957',
      rtl_batch_array: '6161957',
      name_search: 'RUFFGOLD',
      name: "Rudbeckia fulgens 'Forever Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 924,
      chnn_stock_retail: 924,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K9S982HG;5HZ69BDX;5EWGGDJ9;LTGY8N7A;VKVEXL3B;C55C2ABP',
      statusContent: 'dot',
      hash: '6NDJD2RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NDJD2RA'
    },
    {
      status: 210,
      sku: 'PM0085315',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373216',
      rtl_batch_array: '6373216',
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP1W33TG;HBYJJCC4',
      statusContent: 'dot',
      hash: 'SAD1DK3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAD1DK3R'
    },
    {
      status: 210,
      sku: 'PM0081781',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348742',
      rtl_batch_array: '6348742',
      name_search: 'CACSSILV',
      name: 'Caryopteris clandonensis Sterling Silver',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 0.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EV3LWWD5;72VBDSNZ;7NEC35YV;X2RT2WW1;L4VR9C1V;679Y5D6B;S6RSW26H;6PYBP943;AV18RJA9;L66BACVB',
      statusContent: 'dot',
      hash: 'YN1PLRXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN1PLRXH'
    },
    {
      status: 210,
      sku: 'PM0078882',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313320',
      rtl_batch_array: '6313320',
      name_search: 'HYNZELAN',
      name: 'Hydrocotyle novae-zelandiae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RY8Z4GVL;Z563T6N4;WJGY8E8K',
      statusContent: 'dot',
      hash: 'ZYWHGSN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYWHGSN9'
    },
    {
      status: 210,
      sku: 'PM0065299',
      core_name: 'Plant',
      sppl_ean: '8720664856363',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162686',
      rtl_batch_array: '6162686',
      name_search: 'CHLWSPOT',
      name: "Chamaecyparis lawsoniana 'White Spot'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1375,
      chnn_stock_retail: 1375,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SY3SJNN2;Y9T2P2W9;51T6KXJW;D4LKN2VT;SGAPK1VN;LVDT8RWT;YZT45GT4;P95KD38X;49SDSD31;1WXTYN2K;69Y1EX8Z',
      statusContent: 'dot',
      hash: 'KD6NEXR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KD6NEXR2'
    },
    {
      status: 210,
      sku: 'PM0081782',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348743',
      rtl_batch_array: '6348743',
      name_search: 'CARCTHET',
      name: "Caryopteris clandonensis 'Thetis'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2700,
      chnn_stock_retail: 2700,
      sppl_prcp: 0.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8EG8EHKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EG8EHKS'
    },
    {
      status: 210,
      sku: 'PM0081783',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348744',
      rtl_batch_array: '6348744',
      name_search: 'CARCTHET',
      name: "Caryopteris clandonensis 'Thetis'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 0.229,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DXZKPKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DXZKPKG'
    },
    {
      status: 210,
      sku: 'PM0085316',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373217',
      rtl_batch_array: '6373217',
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP1W33TG;HBYJJCC4',
      statusContent: 'dot',
      hash: 'WNZSN6XX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNZSN6XX'
    },
    {
      status: 810,
      sku: 'PM0052975',
      core_name: 'Plant',
      sppl_ean: '8720626319363',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057311',
      rtl_batch_array: '6057311',
      name_search: 'POLBGEM',
      name: "Podocarpus lawrencei 'Blue Gem'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84V3EXWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84V3EXWD'
    },
    {
      status: 210,
      sku: 'PM0078858',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313195',
      rtl_batch_array: '6313195',
      name_search: 'ECPNCHEE',
      name: "Echinacea purpurea 'Now Cheesier'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 618,
      chnn_stock_retail: 618,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZH9VG8PP',
      statusContent: 'dot',
      hash: 'E27GL653',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E27GL653'
    },
    {
      status: 210,
      sku: 'PM0085317',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373218',
      rtl_batch_array: '6373218',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 691,
      chnn_stock_retail: 691,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'TJRJE7DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJRJE7DJ'
    },
    {
      status: 210,
      sku: 'PM0068122',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193631',
      rtl_batch_array: '6193631',
      name_search: 'IRLSNOWD',
      name: "Iris laevigata 'Snowdrift'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96S198GY;XTEGG6YB;7LNGZCPS',
      statusContent: 'dot',
      hash: 'A5L64RJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5L64RJX'
    },
    {
      status: 910,
      sku: 'PM0078860',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECSCANTA',
      name: 'Echinacea Supreme Cantaloupe',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '1ZSYGBJB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1ZSYGBJB'
    },
    {
      status: 210,
      sku: 'PM0081784',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348747',
      rtl_batch_array: '6348747',
      name_search: 'CEIVICTO',
      name: "Ceanothus impressus 'Victoria'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.845,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44K5NBYW;8TNED8P4;GP2AJCGE;LD73LVKY;EHZLC4K7;P3H93PRW;TZSYEV99;KJ9P9TGP;AS1ZD1PC;1G2AYZEE;BGHY1W5K;G1VRTS2W;12TK9EVS;LZV52BW7;D6NCLPGR',
      statusContent: 'dot',
      hash: 'T4488BKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4488BKZ'
    },
    {
      status: 210,
      sku: 'PM0085318',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373219',
      rtl_batch_array: '6373219',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'SB7YPS7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SB7YPS7B'
    },
    {
      status: 210,
      sku: 'PM0065298',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244374',
      rtl_batch_array: '6244374',
      name_search: 'CHALPBLU',
      name: "Chamaecyparis lawsoniana 'Pelt's Blue'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1286,
      chnn_stock_retail: 1286,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6RXW34E6',
      statusContent: 'dot',
      hash: 'CX75EEWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CX75EEWT'
    },
    {
      status: 210,
      sku: 'PM0078861',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313198',
      rtl_batch_array: '6313198',
      name_search: 'ECPHOPE',
      name: "Echinacea purpurea 'Hope'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLXCC9EX;E23NT4CH',
      statusContent: 'dot',
      hash: 'ZTWPS3HY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTWPS3HY'
    },
    {
      status: 210,
      sku: 'PM0078877',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313315',
      rtl_batch_array: '6313315',
      name_search: 'NYELLISI',
      name: "Nymphaea 'Ellisiana'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 12.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '655H3VYC;69BPV4T7;69WZHHVA',
      statusContent: 'dot',
      hash: 'KJJ836WJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJJ836WJ'
    },
    {
      status: 210,
      sku: 'PM0078878',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313316',
      rtl_batch_array: '6313316',
      name_search: 'ACCVARIE',
      name: "Acorus calamus 'Variegatus'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '99J326KZ;86G111GC;2VJA8ANN;ZJ4P5S33;CL7A4G54;KLN9S8SE',
      statusContent: 'dot',
      hash: 'LB68R8XJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB68R8XJ'
    },
    {
      status: 210,
      sku: 'PM0085319',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373220',
      rtl_batch_array: '6373220',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1320,
      chnn_stock_retail: 1320,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: '4SE542WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SE542WB'
    },
    {
      status: 210,
      sku: 'PM0078523',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310198',
      rtl_batch_array: '6310198',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: '6HBAWG56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HBAWG56'
    },
    {
      status: 210,
      sku: 'PM0078879',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313317',
      rtl_batch_array: '6313317',
      name_search: 'IRPSEUDA',
      name: 'Iris pseudacorus',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR7KWGHP;BXCAX7PR;WPXV2NYL;HB7747PE;CP7J3V7W;T58DD8AX;WKRA1JTZ;6RE256WJ;WDS1W4G1;H7BNN7EZ;TWHD3WES;A4H1RKP1;W6WZW26N;96V5ZHGC;8T79XHCT;CGJ9DN9E;K8DZYWSX;SNGZDATZ;TK39H2BP;RL8YNC95',
      statusContent: 'dot',
      hash: 'RHSLKVTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHSLKVTL'
    },
    {
      status: 210,
      sku: 'PM0078880',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313318',
      rtl_batch_array: '6313318',
      name_search: 'POCALBUM',
      name: "Polemonium caeruleum 'Album'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P3RD2TPW;5W6TVZNB',
      statusContent: 'dot',
      hash: 'HVNRA6L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVNRA6L2'
    },
    {
      status: 210,
      sku: 'PM0081785',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348748',
      rtl_batch_array: '6348748',
      name_search: 'CEIVICTO',
      name: "Ceanothus impressus 'Victoria'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44K5NBYW;8TNED8P4;GP2AJCGE;LD73LVKY;EHZLC4K7;P3H93PRW;TZSYEV99;KJ9P9TGP;AS1ZD1PC;1G2AYZEE;BGHY1W5K;G1VRTS2W;12TK9EVS;LZV52BW7;D6NCLPGR',
      statusContent: 'dot',
      hash: '8P42V7ZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8P42V7ZX'
    },
    {
      status: 210,
      sku: 'PM0069348',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349961',
      rtl_batch_array: '6349961',
      name_search: 'COCBLUE',
      name: "Corydalis 'Craigton Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNAT3NR5;CV2A5SK6;S4ADRP5H',
      statusContent: 'dot',
      hash: 'EP77KW1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EP77KW1A'
    },
    {
      status: 210,
      sku: 'PM0071140',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228355',
      rtl_batch_array: '6228355, 6294789',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 947,
      chnn_stock_retail: 2189,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: 'LN3TD42E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LN3TD42E'
    },
    {
      status: 210,
      sku: 'PM0032225',
      core_name: 'Plant',
      sppl_ean: '8720349406913',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6003599',
      rtl_batch_array: '6266268, 6003599, 6252779',
      name_search: 'STBSCARP',
      name: "Stachys byzantina 'Silver Carpet'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2192,
      chnn_stock_retail: 4410,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J8K8NR8V;P74YSYSB;4XY17D6W;CP21H1DC;15DY8NV2;X86553HE;WG57WZWD;4JB7CTVY',
      statusContent: 'dot',
      hash: 'H7ELBWGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7ELBWGT'
    },
    {
      status: 210,
      sku: 'PM0081786',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348749',
      rtl_batch_array: '6348749',
      name_search: 'CEIVICTO',
      name: "Ceanothus impressus 'Victoria'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 4300,
      chnn_stock_retail: 4300,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44K5NBYW;8TNED8P4;GP2AJCGE;LD73LVKY;EHZLC4K7;P3H93PRW;TZSYEV99;KJ9P9TGP;AS1ZD1PC;1G2AYZEE;BGHY1W5K;G1VRTS2W;12TK9EVS;LZV52BW7;D6NCLPGR',
      statusContent: 'dot',
      hash: 'Z54D1Y4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z54D1Y4R'
    },
    {
      status: 210,
      sku: 'PM0078884',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313322',
      rtl_batch_array: '6313322',
      name_search: 'LIBRASIL',
      name: 'Lilaeopsis brasiliensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2SLRRBDL;XLBRCJ21;V3D6N2WP',
      statusContent: 'dot',
      hash: '2V1SGPEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V1SGPEK'
    },
    {
      status: 210,
      sku: 'PM0081787',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348750',
      rtl_batch_array: '6348750',
      name_search: 'CEPMSIMO',
      name: "Ceanothus pallidus 'Marie Simon'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAHPJKYX;E4937RK4;SXG491X3;BLYASDEK',
      statusContent: 'dot',
      hash: '9S68VAXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S68VAXS'
    },
    {
      status: 210,
      sku: 'PM0078885',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313323',
      rtl_batch_array: '6313323',
      name_search: 'LIUNIFLO',
      name: 'Littorella uniflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DV5W24EG;JPJZ1TVN;XV7GWX9T;A5D1KWGS',
      statusContent: 'dot',
      hash: 'GBCH3P9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBCH3P9A'
    },
    {
      status: 210,
      sku: 'PM0081788',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348752',
      rtl_batch_array: '6348752',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: 'RWN4C9XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWN4C9XP'
    },
    {
      status: 210,
      sku: 'PM0078886',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313324',
      rtl_batch_array: '6313324',
      name_search: 'MAQUADRI',
      name: 'Marsilea quadrifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8HPSE5BH',
      statusContent: 'dot',
      hash: '8CK5RDLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CK5RDLD'
    },
    {
      status: 210,
      sku: 'PM0048335',
      core_name: 'Plant',
      sppl_ean: '8720664690370',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015052',
      rtl_batch_array: '6015052',
      name_search: 'FUMVARIE',
      name: "Fuchsia magellanica 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K9WK816E',
      statusContent: 'dot',
      hash: 'SY1W5RVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY1W5RVR'
    },
    {
      status: 210,
      sku: 'PM0081789',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348753',
      rtl_batch_array: '6348753',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: 'YTK8741Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTK8741Y'
    },
    {
      status: 810,
      sku: 'PM0071143',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228359',
      rtl_batch_array: '6228359',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 2.801,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: '6WCPN91V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WCPN91V'
    },
    {
      status: 210,
      sku: 'PM0078888',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313326',
      rtl_batch_array: '6313326',
      name_search: 'MYRSTEM',
      name: "Myriophyllum 'Red Stem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B9KLJW9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9KLJW9G'
    },
    {
      status: 210,
      sku: 'PM0078889',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313327',
      rtl_batch_array: '6313327',
      name_search: 'ROINDICA',
      name: 'Rotala indica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GTJ4X7WC;HW45XZBS;RSW3SNPC;T9CN9D5H;187ZVNZ4;XEW9WBG7;RZZZ5ADW',
      statusContent: 'dot',
      hash: '6J3RCPWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J3RCPWT'
    },
    {
      status: 210,
      sku: 'PM0065236',
      core_name: 'Plant',
      sppl_ean: '8720664885462',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161943',
      rtl_batch_array: '6161943',
      name_search: 'SCCBNOTE',
      name: "Scabiosa columbaria 'Blue Note'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 134,
      chnn_stock_retail: 134,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CL49HJ7G;ZE6LJB6W;B3ESL1X8;GYR3PJ9J;LBGYVKBA;HBBXAWZN;L298PSG8;ESA1PZE9;D35HRS8D;LZJE2WJY;7EVJXVPZ',
      statusContent: 'dot',
      hash: 'KSCXRW53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSCXRW53'
    },
    {
      status: 210,
      sku: 'PM0048320',
      core_name: 'Plant',
      sppl_ean: '8720664860667',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147597',
      rtl_batch_array: '6147597',
      name_search: 'ECSMMAMA',
      name: "Echinacea 'Sweet Meadow Mama'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 191,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44G9KXP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44G9KXP4'
    },
    {
      status: 210,
      sku: 'PM0081790',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348754',
      rtl_batch_array: '6348754',
      name_search: 'CEPBLUE',
      name: "Ceanothus 'Puget Blue'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_order_minimum: 3,
      sppl_prcp: 2.845,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LLRS91AA;5JEDVG2X;RWGVKCPW;46DZL2CK;9BR19664;R5RRXXA8;ZJV9G2P5;XLJ5CBWL;VBJW3YVK',
      statusContent: 'dot',
      hash: 'ALXTN76N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALXTN76N'
    },
    {
      status: 210,
      sku: 'PM0048306',
      core_name: 'Plant',
      sppl_ean: '8720664861015',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147769',
      rtl_batch_array: '6147769',
      name_search: 'ECPPPOOD',
      name: "Echinacea purpurea 'Pink Poodle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDA6D49Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDA6D49Z'
    },
    {
      status: 810,
      sku: 'PM0071144',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228360',
      rtl_batch_array: '6228360',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: '6D8GRS1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6D8GRS1H'
    },
    {
      status: 810,
      sku: 'PM0078906',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193114',
      rtl_batch_array: '6193114',
      name_search: 'ERMANESC',
      name: 'Erodium manescavii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RXED2Y42;1XB82B8K;V37VCKYV;7BX1HKJN;B57P6694;43CTBGSK;R12RG71Z',
      statusContent: 'dot',
      hash: 'PVADAGNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVADAGNG'
    },
    {
      status: 210,
      sku: 'PM0078894',
      core_name: 'Plant',
      sppl_ean: '8720664884496',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133606',
      rtl_batch_array: '6133606, 6353209',
      name_search: 'SAFFLAMI',
      name: 'Salvia Feathers Flamingo',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 494,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WH2LJ2ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH2LJ2ZY'
    },
    {
      status: 210,
      sku: 'PM0078890',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313328',
      rtl_batch_array: '6313328',
      name_search: 'ROROTUND',
      name: 'Rotala rotundifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9NXKNLK3;5VV9HZEN;JT4S4YZL;KG193E2A;GNTEW5YE;PBJ7H17L;NDTZLRCB;6ELDTNDR;K5CE4H2Y',
      statusContent: 'dot',
      hash: 'K7JG19AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7JG19AS'
    },
    {
      status: 210,
      sku: 'PM0078729',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258064',
      rtl_batch_array: '6258064',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '100125C24',
      rng_range_identifier: 'H100125C2.5',
      rng_range_description: 'H100 cm 125 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 2.047,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'RPSJ3B1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPSJ3B1W'
    },
    {
      status: 210,
      sku: 'PM0078892',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298588',
      rtl_batch_array: '6298588',
      name_search: 'ERDMHELE',
      name: "Erica darleyensis 'Mary Helen'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HXVVRET8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXVVRET8'
    },
    {
      status: 210,
      sku: 'PM0078893',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298793',
      rtl_batch_array: '6298793',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: 'LEVC44',
      rng_range_identifier: 'C4.5',
      rng_range_description: 'C4.5',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_prcp: 7.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'V29DZ7BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V29DZ7BW'
    },
    {
      status: 210,
      sku: 'PM0081791',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348755',
      rtl_batch_array: '6348755',
      name_search: 'CEPBLUE',
      name: "Ceanothus 'Puget Blue'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LLRS91AA;5JEDVG2X;RWGVKCPW;46DZL2CK;9BR19664;R5RRXXA8;ZJV9G2P5;XLJ5CBWL;VBJW3YVK',
      statusContent: 'dot',
      hash: 'PXVB4L8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXVB4L8Y'
    },
    {
      status: 210,
      sku: 'PM0048326',
      core_name: 'Plant',
      sppl_ean: '8720664690844',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015001',
      rtl_batch_array: '6015001',
      name_search: 'GERRED',
      name: "Geranium hybrid 'Rothbury Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7LALY2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7LALY2E'
    },
    {
      status: 210,
      sku: 'PM0081792',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348756',
      rtl_batch_array: '6348756',
      name_search: 'CEPBLUE',
      name: "Ceanothus 'Puget Blue'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LLRS91AA;5JEDVG2X;RWGVKCPW;46DZL2CK;9BR19664;R5RRXXA8;ZJV9G2P5;XLJ5CBWL;VBJW3YVK',
      statusContent: 'dot',
      hash: '639B2G3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '639B2G3S'
    },
    {
      status: 210,
      sku: 'PM0081793',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348757',
      rtl_batch_array: '6348757',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: 'XT3L1CEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT3L1CEJ'
    },
    {
      status: 210,
      sku: 'PM0078895',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280251',
      rtl_batch_array: '6280251',
      name_search: 'POSETIFE',
      name: 'Polystichum setiferum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1757,
      chnn_stock_retail: 1757,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HC19TGYC;ELKBAT9Z;24CT8HN2;VGAVGVLV;E77T1ANN',
      statusContent: 'dot',
      hash: '288XW1B6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '288XW1B6'
    },
    {
      status: 210,
      sku: 'PM0081794',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348759',
      rtl_batch_array: '6348759',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'JHZYJNL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHZYJNL4'
    },
    {
      status: 210,
      sku: 'PM0068126',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348359',
      rtl_batch_array: '6231002, 6193642, 6348359',
      name_search: 'LOSIPHIL',
      name: 'Lobelia siphilitica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 1321,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLC8K81Y;BV3WDG5H;CBHZBEVG;GTCC7W6K;B67VK14Y;8ZR1WCRH',
      statusContent: 'dot',
      hash: 'C3649RWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3649RWZ'
    },
    {
      status: 210,
      sku: 'PM0078524',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310203',
      rtl_batch_array: '6310203',
      name_search: 'CAARBORE',
      name: 'Caragana arborescens',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 830,
      chnn_stock_retail: 830,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9ZDVBS61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZDVBS61'
    },
    {
      status: 210,
      sku: 'PM0078899',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363174',
      rtl_batch_array: '6301404, 6363174',
      name_search: 'ANHSHOLL',
      name: "Anthemis hybrida 'Sauce Hollandaise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1017,
      chnn_stock_retail: 1454,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D57GC45N;687J2NSX;LTR24P7K;R9E7N1H7;8V9CPCJ3;EC95TY27;9H6SDWYA;81DG63EK;PZ6PZBCY;Y4N6H9K5;XAPRCK7Y;BH4YWCVS;VNX3Z9ZP;8YY73X9D',
      statusContent: 'dot',
      hash: 'ZY5ZPH2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY5ZPH2V'
    },
    {
      status: 210,
      sku: 'PM0066722',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176452',
      rtl_batch_array: '6176452, 6274535',
      name_search: 'GYROSENS',
      name: "Gypsophila 'Rosenschleier'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 220,
      chnn_stock_retail: 1528,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XES7TRDY',
      statusContent: 'dot',
      hash: 'WN1PTH65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WN1PTH65'
    },
    {
      status: 810,
      sku: 'PM0078903',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301612',
      rtl_batch_array: '6301612',
      name_search: 'GESIBLUE',
      name: "Geranium sylvaticum 'Ice Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '831R3DBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '831R3DBD'
    },
    {
      status: 210,
      sku: 'PM0071142',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228357',
      rtl_batch_array: '6228357',
      name_search: 'SYPMKIM',
      name: "Syringa patula 'Miss Kim'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 224,
      chnn_stock_retail: 224,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H9C84CRR;RBGZ96SX;XPA14KLK;N7KPZ796;711JRA1B;JY6DHXP8',
      statusContent: 'dot',
      hash: 'WLKKPJA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLKKPJA4'
    },
    {
      status: 210,
      sku: 'PM0078904',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304924',
      rtl_batch_array: '6304924',
      name_search: 'WEPROSSO',
      name: 'Weigela Picobella Rosso',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1196,
      chnn_stock_retail: 1196,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DL38Y3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DL38Y3R'
    },
    {
      status: 810,
      sku: 'PM0078905',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311805',
      rtl_batch_array: '6311805',
      name_search: 'MOBYBTRU',
      name: 'Monarda Bee You Bee-true',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4ZPT6YJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZPT6YJX'
    },
    {
      status: 210,
      sku: 'PM0048307',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350070',
      rtl_batch_array: '6350070',
      name_search: 'ECPSKIPP',
      name: 'Echinacea Pink Skipper',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LBXHC52B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LBXHC52B'
    },
    {
      status: 210,
      sku: 'PM0068113',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193612',
      rtl_batch_array: '6193612',
      name_search: 'GROFFICI',
      name: 'Gratiola officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KYW5EGK2;D5KLG73K;LY33WA3Z;WLELV2PR',
      statusContent: 'dot',
      hash: 'SDSCZ11H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDSCZ11H'
    },
    {
      status: 210,
      sku: 'PM0065300',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244375',
      rtl_batch_array: '6244375',
      name_search: 'CHODRATH',
      name: "Chamaecyparis obtusa 'Drath'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1206,
      chnn_stock_retail: 1206,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1H82WVR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H82WVR4'
    },
    {
      status: 810,
      sku: 'PM0065303',
      core_name: 'Plant',
      sppl_ean: '8720664856400',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162691',
      rtl_batch_array: '6162691',
      name_search: 'CHOKAMAR',
      name: "Chamaecyparis obtusa 'Kamarachiba'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S48852HG;W8TCVX1L;VL52KT58',
      statusContent: 'dot',
      hash: 'XLWZ1T57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLWZ1T57'
    },
    {
      status: 810,
      sku: 'PM0078526',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310208',
      rtl_batch_array: '6310208',
      name_search: 'CASATIVA',
      name: 'Castanea sativa',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 5.277,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LA8C21SW;BGYJDH9S;BYTT7W4K;PN655R62;S2H4K4EW;G7EKTG64;EC9G3SGB;P14HYP1S;K97S88SS;7P4NCCC1;WR485ZY7;KBDL6PZK;RE2ZHP6R;9ASPZVTP;8J5JTCWX;89TTCGKT;K6B1HH79;LSX7L6GW;KTRHDYP7;JSLYTH72;P5J8E112;CZEG2J67;7VHYHRCW;2BZDCED4',
      statusContent: 'dot',
      hash: 'LWC6PWDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWC6PWDV'
    },
    {
      status: 210,
      sku: 'PM0071138',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228353',
      rtl_batch_array: '6228353',
      name_search: 'SOLBABY',
      name: "Sophora 'Little Baby'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_prcp: 2.652,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5AN67XKK;13X2HXHN;2GT739C3;YDTN8LJC;E91RXJ6P;SRSZ5656;RRWE2BPY;YCA786GS;RC9J7764;7W7XPRYR;DD3A6743;E9APZK83;R75NZSJ9',
      statusContent: 'dot',
      hash: 'P5EHY4L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5EHY4L6'
    },
    {
      status: 210,
      sku: 'PM0078881',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313319',
      rtl_batch_array: '6313319',
      name_search: 'BACAROLI',
      name: 'Bacopa caroliniana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CRHXGPHE;VYANB6WD',
      statusContent: 'dot',
      hash: '2XAWLB2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XAWLB2T'
    },
    {
      status: 210,
      sku: 'PM0069346',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274834',
      rtl_batch_array: '6274834',
      name_search: 'COSDANCE',
      name: "Coreopsis 'Solar Dance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYJK3WCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYJK3WCB'
    },
    {
      status: 210,
      sku: 'PM0048310',
      core_name: 'Plant',
      sppl_ean: '8720664860612',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147575',
      rtl_batch_array: '6147575',
      name_search: 'ECRTRUFF',
      name: "Echinacea 'Raspberry Truffle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TYHBANNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYHBANNE'
    },
    {
      status: 210,
      sku: 'PM0039536',
      core_name: 'Plant',
      sppl_ean: '8720349424948',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5896720',
      rtl_batch_array: '5896720',
      name_search: 'PHDAVID',
      name: "Phlox (P) 'David'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 719,
      chnn_stock_retail: 719,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2DKVDL4Z;WLCDZC3X;N856B7LN;JKHYT3H6;C5L9Z5PJ;HXRLYLJ7',
      statusContent: 'dot',
      hash: '55D24AD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55D24AD9'
    },
    {
      status: 810,
      sku: 'PM0078902',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301597',
      rtl_batch_array: '6301597',
      name_search: 'GEMCHATT',
      name: "Geranium maculatum 'Chatto'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R6T6JTD4',
      statusContent: 'dot',
      hash: 'J18V7T1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J18V7T1G'
    },
    {
      status: 210,
      sku: 'PM0078883',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313321',
      rtl_batch_array: '6313321',
      name_search: 'HYSVARIE',
      name: "Hydrocotyle sibthorpioides 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZZG3B7B5;GH15GLX8;8RHDLEDT;4LXTC246',
      statusContent: 'dot',
      hash: 'H3Z215SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3Z215SX'
    },
    {
      status: 210,
      sku: 'PM0071139',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228354',
      rtl_batch_array: '6228354',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2082,
      chnn_stock_retail: 2082,
      sppl_prcp: 2.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: '2CZVESZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CZVESZY'
    },
    {
      status: 210,
      sku: 'PM0078887',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313325',
      rtl_batch_array: '6313325',
      name_search: 'MYCRISPA',
      name: 'Myriophyllum crispatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EPVLNPG2',
      statusContent: 'dot',
      hash: '51CZL7YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51CZL7YC'
    },
    {
      status: 210,
      sku: 'PM0065241',
      core_name: 'Plant',
      sppl_ean: '8720664887299',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161961',
      rtl_batch_array: '6161961',
      name_search: 'STLBSTAR',
      name: "Stokesia laevis 'Blue Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1HYW3L5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HYW3L5W'
    },
    {
      status: 210,
      sku: 'PM0078854',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313190',
      rtl_batch_array: '6313190',
      name_search: 'ACSAFRAN',
      name: "Achillea 'Safran'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 398,
      chnn_stock_retail: 398,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZS2D3J1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZS2D3J1Y'
    },
    {
      status: 810,
      sku: 'PM0078901',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301568',
      rtl_batch_array: '6301568',
      name_search: 'PHVRBEAU',
      name: "Physostegia virginiana 'Red Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LTYGCSCV',
      statusContent: 'dot',
      hash: '5GX9G5RG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GX9G5RG'
    },
    {
      status: 210,
      sku: 'PM0068115',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193616',
      rtl_batch_array: '6193616',
      name_search: 'HYVULGAR',
      name: 'Hydrocotyle vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SSHYR1RG;TN24SGG2;YT5VT11B;KV9TV55C;48DPK7T2',
      statusContent: 'dot',
      hash: 'V483G7CE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V483G7CE'
    },
    {
      status: 210,
      sku: 'PM0078896',
      core_name: 'Plant',
      sppl_ean: '8720664885806',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133678',
      rtl_batch_array: '6133678',
      name_search: 'SEACCARP',
      name: "Sedum album 'Coral Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1433,
      chnn_stock_retail: 1433,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V158DJVS;RBC2NVHB;ATJ2G7HP;VKL4Z558;X2DZR6GE;ZR1R9YR7;EKP3WW4Z;4X4ZB51N;CNBS7414;2Y367LCS;C6CX9ZX1;TVE6A223;9PSBSDN8;HRL8LGAG;967L4ZJ6;ZJ5VHRAW;4N4PJZXX;VZGBRZNX;V6L1TT8K;DK46JS3A;SJ41C5LG;2CJWN8GW;PZNK6BRR;L345SGKK;WS49RJY9;KSH2DYEL;98H94NRH',
      statusContent: 'dot',
      hash: 'P6D49R89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6D49R89'
    },
    {
      status: 210,
      sku: 'PM0068102',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193584',
      rtl_batch_array: '6193584',
      name_search: 'NYOALBA',
      name: "Nymphaea odorata 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCZ5WXA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCZ5WXA4'
    },
    {
      status: 210,
      sku: 'PM0078855',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313191',
      rtl_batch_array: '6313191',
      name_search: 'AGFLEUR',
      name: "Agastache 'Fleur'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YPP5SKE',
      statusContent: 'dot',
      hash: '2AP6PH6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AP6PH6C'
    },
    {
      status: 810,
      sku: 'PM0065242',
      core_name: 'Plant',
      sppl_ean: '8720664887312',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161962',
      rtl_batch_array: '6161962',
      name_search: 'STABCARP',
      name: "Strobilanthes atropurpureus 'Blue Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2X2C4L7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2X2C4L7S'
    },
    {
      status: 210,
      sku: 'PM0078891',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313329',
      rtl_batch_array: '6313329',
      name_search: 'SCCERNUU',
      name: 'Scirpus cernuus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZWV1S7WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWV1S7WV'
    },
    {
      status: 210,
      sku: 'PM0058777',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269852',
      rtl_batch_array: '6269852, 6252574',
      name_search: 'GALINDHE',
      name: 'Gaura lindheimeri',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5714,
      chnn_stock_retail: 7961,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RP4K3PBG;D2SZPWDW;4E31EAV4;SN719255;67TSPL34;HSDR43A1;E3VPEGWX;GC2GESRA;NDEYHPR6;VA4ABZ32;JBG9PR4L',
      statusContent: 'dot',
      hash: 'CKWD3W5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKWD3W5X'
    },
    {
      status: 210,
      sku: 'PM0078859',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313196',
      rtl_batch_array: '6313196',
      name_search: 'ECPTIP',
      name: "Echinacea 'Pink Tip'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 184,
      chnn_stock_retail: 184,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WLW783Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLW783Z4'
    },
    {
      status: 210,
      sku: 'PM0078898',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290373',
      rtl_batch_array: '6290373',
      name_search: 'KNCITRIN',
      name: 'Kniphofia citrina',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7E3WGEBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E3WGEBB'
    },
    {
      status: 210,
      sku: 'PM0052983',
      core_name: 'Plant',
      sppl_ean: '8720664810235',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057319',
      rtl_batch_array: '6057319',
      name_search: 'THOGBRAB',
      name: "Thuja occidentalis 'Golden Brabant'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 622,
      chnn_stock_retail: 622,
      sppl_prcp: 3.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRAHGKLH',
      statusContent: 'dot',
      hash: 'ZNCJESBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNCJESBR'
    },
    {
      status: 210,
      sku: 'PM0065306',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244377',
      rtl_batch_array: '6244377',
      name_search: 'CHOPYGMA',
      name: "Chamaecyparis obtusa 'Pygmaea'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P7SDSH2B;K77GLWYX',
      statusContent: 'dot',
      hash: 'TXRBHBLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXRBHBLV'
    },
    {
      status: 210,
      sku: 'PM0078533',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310221',
      rtl_batch_array: '6310221',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'ETHCXYCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETHCXYCT'
    },
    {
      status: 810,
      sku: 'PM0078534',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310222',
      rtl_batch_array: '6310222',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 5.231,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'ZR8241BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR8241BY'
    },
    {
      status: 210,
      sku: 'PM0078535',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310223',
      rtl_batch_array: '6310223',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1779,
      chnn_stock_retail: 1779,
      sppl_order_minimum: 3,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'RC2JCCHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC2JCCHT'
    },
    {
      status: 210,
      sku: 'PM0065340',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244393',
      rtl_batch_array: '6244393',
      name_search: 'THOGLOBO',
      name: "Thuja occidentalis 'Globosa'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 448,
      chnn_stock_retail: 448,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N6L9J5WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6L9J5WA'
    },
    {
      status: 210,
      sku: 'PM0078538',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310226',
      rtl_batch_array: '6310226',
      name_search: 'COSANGUI',
      name: 'Cornus sanguinea',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 1410,
      chnn_stock_retail: 1410,
      sppl_prcp: 5.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X4V1AR2G;JATJ5LR2;PEWSNGWB;78CDZ1AA;JVEEB7VA;PGNCTZX2;RWP4CHAX',
      statusContent: 'dot',
      hash: '815NH162',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '815NH162'
    },
    {
      status: 210,
      sku: 'PM0048389',
      core_name: 'Plant',
      sppl_ean: '8720664692503',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015323',
      rtl_batch_array: '6015323',
      name_search: 'HODLAKE',
      name: "Hosta 'Diamond Lake'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 158,
      chnn_stock_retail: 158,
      sppl_order_minimum: 3,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VPV3Y485',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPV3Y485'
    },
    {
      status: 910,
      sku: 'PM0078539',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '6GCEGP1C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6GCEGP1C'
    },
    {
      status: 210,
      sku: 'PM0065361',
      core_name: 'Plant',
      sppl_ean: '8720664883697',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6118954',
      rtl_batch_array: '6118954',
      name_search: 'RODHASTR',
      name: 'Rosa (R) Dagmar Hastrup',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 4841,
      chnn_stock_retail: 4841,
      sppl_order_minimum: 3,
      sppl_prcp: 1.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DXN74Y99;4TD59ZW7;KX88NXG4',
      statusContent: 'dot',
      hash: 'YDC3LCS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDC3LCS8'
    },
    {
      status: 210,
      sku: 'PM0081795',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348761',
      rtl_batch_array: '6348761',
      name_search: 'COBEFLAS',
      name: "Cordyline banksii 'Electric Flash'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 0.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B5N71C7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5N71C7H'
    },
    {
      status: 210,
      sku: 'PM0065339',
      core_name: 'Plant',
      sppl_ean: '8720664888401',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162733',
      rtl_batch_array: '6162733',
      name_search: 'THOAMBAL',
      name: "Thuja occidentalis Anna's Magic Ball",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 971,
      chnn_stock_retail: 971,
      sppl_prcp: 4.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '994Y28GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '994Y28GH'
    },
    {
      status: 810,
      sku: 'PM0078543',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310232',
      rtl_batch_array: '6310232',
      name_search: 'COCOGGYG',
      name: 'Cotinus coggygria',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSA84DWE;Y4AN75K2;6S9YSYV4;4XW7XSEE;NK8DRPE4',
      statusContent: 'dot',
      hash: 'J3DKW327',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3DKW327'
    },
    {
      status: 910,
      sku: 'PM0078544',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'X2XHN71P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X2XHN71P'
    },
    {
      status: 910,
      sku: 'PM0078545',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'STW8ZXVY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'STW8ZXVY'
    },
    {
      status: 210,
      sku: 'PM0081796',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348762',
      rtl_batch_array: '6348762',
      name_search: 'COBEPINK',
      name: 'Cordilyne banksii Electric Pink \u00ae',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 0.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JANBHS5G;XRAKA322;XWDK675Z;7P7LVYE3;VA51XRV3;VRCLB561;68GR41BP;W4A8RPRD;DKP4WPLE',
      statusContent: 'dot',
      hash: 'JE8VCKNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JE8VCKNV'
    },
    {
      status: 210,
      sku: 'PM0065344',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244395',
      rtl_batch_array: '6244395',
      name_search: 'THOGTUFF',
      name: "Thuja occidentalis 'Golden Tuffet'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6YT63PXP;NAPXD1VW;PA7B5RVB;7GBK7CKA;VGT853C5;N67PK32Y;HYBN8NHS;XCC3S29L;PHLH3AWC;9EV6ESKJ;R5DY2VP5;5LSX1SDB;4E9CTASH;SAVKBA9P;EA2653D8;LRS7D92P',
      statusContent: 'dot',
      hash: 'AGGSW66G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGGSW66G'
    },
    {
      status: 210,
      sku: 'PM0081797',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348763',
      rtl_batch_array: '6348763',
      name_search: 'COSUPERS',
      name: 'Cordyline Superstar',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4Y7S4NC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y7S4NC4'
    },
    {
      status: 210,
      sku: 'PM0081798',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348764',
      rtl_batch_array: '6348764',
      name_search: 'COTDMAJO',
      name: 'Cotoneaster dielsianus major',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPP516WT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPP516WT'
    },
    {
      status: 210,
      sku: 'PM0078546',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385817',
      rtl_batch_array: '6385817',
      name_search: 'CODMAJOR',
      name: "Cotoneaster dammeri 'Major'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.121,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V37PA1X8',
      statusContent: 'dot',
      hash: '4J96L45W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J96L45W'
    },
    {
      status: 210,
      sku: 'PM0081799',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348766',
      rtl_batch_array: '6348766',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 5300,
      chnn_stock_retail: 5300,
      sppl_prcp: 3.583,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'J3Y5DAT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3Y5DAT2'
    },
    {
      status: 210,
      sku: 'PM0078547',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310236',
      rtl_batch_array: '6310236',
      name_search: 'CODMAJOR',
      name: "Cotoneaster dammeri 'Major'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 4050,
      chnn_stock_retail: 4050,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V37PA1X8',
      statusContent: 'dot',
      hash: 'V33DTD7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V33DTD7D'
    },
    {
      status: 210,
      sku: 'PM0078548',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310237',
      rtl_batch_array: '6310237',
      name_search: 'CODMAJOR',
      name: "Cotoneaster dammeri 'Major'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V37PA1X8',
      statusContent: 'dot',
      hash: 'K1WR2KYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1WR2KYZ'
    },
    {
      status: 910,
      sku: 'PM0078549',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'DJ16LEXS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DJ16LEXS'
    },
    {
      status: 210,
      sku: 'PM0078550',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310239',
      rtl_batch_array: '6310239',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 4.781,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'K5L41H43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5L41H43'
    },
    {
      status: 210,
      sku: 'PM0065342',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244394',
      rtl_batch_array: '6244394',
      name_search: 'THOGGLOB',
      name: "Thuja occidentalis 'Golden Globe'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 535,
      chnn_stock_retail: 535,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RD5LGE47;8TD9DDNK;8X745LHB;RHB2X2BR;T12GN6XT;C66Y35N7',
      statusContent: 'dot',
      hash: '7JSVB7GZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JSVB7GZ'
    },
    {
      status: 210,
      sku: 'PM0078747',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307969',
      rtl_batch_array: '6307969',
      name_search: 'ANPLDIAN',
      name: "Anemone 'Pretty Lady Diana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XE2CGE61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE2CGE61'
    },
    {
      status: 210,
      sku: 'PM0068149',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295896',
      rtl_batch_array: '6295896',
      name_search: 'TYLATIFO',
      name: 'Typha latifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3KZWY33;1PXN25LG;2P4WZSEX;WVGA79BZ;ZTA2G7R5',
      statusContent: 'dot',
      hash: 'XZR8YPL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZR8YPL9'
    },
    {
      status: 210,
      sku: 'PM0081800',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348768',
      rtl_batch_array: '6348768',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: '9HGK4CLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HGK4CLX'
    },
    {
      status: 210,
      sku: 'PM0065365',
      core_name: 'Plant',
      sppl_ean: '8720664888326',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6163756',
      rtl_batch_array: '6163756',
      name_search: 'THOMIRJA',
      name: "Thuja occidentalis 'Mirjam'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 535,
      chnn_stock_retail: 535,
      sppl_prcp: 7.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XA4ZXEDC;P272LPK6;PS419EA1;S2RDPHYW',
      statusContent: 'dot',
      hash: 'SHD4C45C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHD4C45C'
    },
    {
      status: 210,
      sku: 'PM0065349',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244398',
      rtl_batch_array: '6244398',
      name_search: 'THP4EVER',
      name: "Thuja plicata '4ever'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1015,
      chnn_stock_retail: 1015,
      sppl_prcp: 4.786,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C2ERA97B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2ERA97B'
    },
    {
      status: 210,
      sku: 'PM0081801',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348769',
      rtl_batch_array: '6348769',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 0.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'NB2CWPK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB2CWPK3'
    },
    {
      status: 810,
      sku: 'PM0071145',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228361',
      rtl_batch_array: '6228361',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 2.751,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: 'DB6DCZH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB6DCZH6'
    },
    {
      status: 210,
      sku: 'PM0081802',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348770',
      rtl_batch_array: '6348770',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 5,
      sppl_prcp: 2.998,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      statusContent: 'dot',
      hash: '9DXJTLN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DXJTLN7'
    },
    {
      status: 210,
      sku: 'PM0081803',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348771',
      rtl_batch_array: '6348771',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: '010015C1',
      rng_range_identifier: 'H010015C1',
      rng_range_description: 'H10 cm 15 cm C1',
      sppl_stock_available: 4200,
      chnn_stock_retail: 4200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.298,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'LDLX91KT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDLX91KT'
    },
    {
      status: 210,
      sku: 'PM0081804',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348772',
      rtl_batch_array: '6348772',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'AR7N15EB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR7N15EB'
    },
    {
      status: 210,
      sku: 'PM0078867',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313214',
      rtl_batch_array: '6313214',
      name_search: 'CIAMACUL',
      name: "Cistus aguilarii 'Maculatus'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CBW9H9K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBW9H9K2'
    },
    {
      status: 210,
      sku: 'PM0048355',
      core_name: 'Plant',
      sppl_ean: '8720664691124',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015162',
      rtl_batch_array: '6015162',
      name_search: 'GESFLY',
      name: "Geum 'Spanish Fly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJ34W918',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJ34W918'
    },
    {
      status: 210,
      sku: 'PM0048352',
      core_name: 'Plant',
      sppl_ean: '8720664690738',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015149',
      rtl_batch_array: '6015149',
      name_search: 'GEBMAWAY',
      name: 'Geranium Bloom Me Away',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1SWBL9G2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SWBL9G2'
    },
    {
      status: 210,
      sku: 'PM0081805',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348774',
      rtl_batch_array: '6348774',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'DLBBXNHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLBBXNHB'
    },
    {
      status: 810,
      sku: 'PM0078871',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313218',
      rtl_batch_array: '6313218',
      name_search: 'HEHFSPIN',
      name: "Heliopsis h. 'Funky Spinner'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 3.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4J1R7YAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J1R7YAA'
    },
    {
      status: 210,
      sku: 'PM0078872',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313219',
      rtl_batch_array: '6313219',
      name_search: 'LIDBMCRA',
      name: "Ligularia dentata 'Britt-Marie Crawford'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3GY8E7L;PNG54DJZ;S2JNDWG9',
      statusContent: 'dot',
      hash: '4B9HCZ5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B9HCZ5A'
    },
    {
      status: 210,
      sku: 'PM0069371',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349997',
      rtl_batch_array: '6349997',
      name_search: 'DIALBUS',
      name: 'Dictamnus albus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1589,
      chnn_stock_retail: 1589,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LKVZ3VTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKVZ3VTB'
    },
    {
      status: 210,
      sku: 'PM0081806',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348775',
      rtl_batch_array: '6348775',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'VHDTJPY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHDTJPY9'
    },
    {
      status: 210,
      sku: 'PM0081807',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348776',
      rtl_batch_array: '6348776',
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW2EVKB5;SP7Y5ZWK;SX1DDW1R;JCCEY4LT;A3VB9EVA;HY6AJV5K;CGP52GK8;YKBYTZKC;C9PLV17X;8YNL22WX;Z2CG7A8J;RJD8YAXS;A9KC643C;5V2J3N1E;5AGVBE54;D21HDP9S',
      statusContent: 'dot',
      hash: 'GVW7HCC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVW7HCC9'
    },
    {
      status: 210,
      sku: 'PM0068850',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209166',
      rtl_batch_array: '6209166',
      name_search: 'ASAWHITE',
      name: "Astilbe (A) 'Astary White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 561,
      chnn_stock_retail: 561,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J75AN429;ZCPNLNYH;NL718N62;S2ZEVAY6',
      statusContent: 'dot',
      hash: 'J6DASLCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6DASLCG'
    },
    {
      status: 210,
      sku: 'PM0048421',
      core_name: 'Plant',
      sppl_ean: '8720664881259',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161902',
      rtl_batch_array: '6161902',
      name_search: 'PLAURICO',
      name: 'Pleioblastus auricomus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_order_minimum: 3,
      sppl_prcp: 3.331,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2XZCSY8B;X4RE5ED9;JBS956S6;4EWDH413',
      statusContent: 'dot',
      hash: 'P23XT74C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P23XT74C'
    },
    {
      status: 210,
      sku: 'PM0078873',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313220',
      rtl_batch_array: '6313220',
      name_search: 'RUSLHENR',
      name: "Rudbeckia subtomentosa 'Little Henry'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_prcp: 3.581,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9YLXSEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9YLXSEA'
    },
    {
      status: 810,
      sku: 'PM0078536',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310224',
      rtl_batch_array: '6310224',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_prcp: 5.177,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'ZG98BPT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG98BPT9'
    },
    {
      status: 210,
      sku: 'PM0081808',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348777',
      rtl_batch_array: '6348777',
      name_search: 'EUJKATHY',
      name: "Euonymus japonicus 'Kathy'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7AYYBDZ9;PJ1WE347;71RR6VTP;4ZS6JS6R;WNW9N6BN;6RG9HR69;B81979LC;2PB1Y5J8;C95PJGX2;V71HT66P;RXA9CAYR;ERCT8HPH;6RK2JS7T;SS2T58C4;LDYV8ZYT;1HSLXJ6Z;N5JDJ4LT',
      statusContent: 'dot',
      hash: '4LR2C67C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LR2C67C'
    },
    {
      status: 210,
      sku: 'PM0068151',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193696',
      rtl_batch_array: '6193696',
      name_search: 'TYMINIMA',
      name: 'Typha minima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N3E2ZGGV;CSLT2T3R;C743VCT1;4JSHYY4A;GCZER8WX;A47DSEZW',
      statusContent: 'dot',
      hash: '6R8R4LAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6R8R4LAC'
    },
    {
      status: 210,
      sku: 'PM0065382',
      core_name: 'Plant',
      sppl_ean: '8720664880887',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6163775',
      rtl_batch_array: '6163775',
      name_search: 'PISRADIA',
      name: "Pinus strobus 'Radiata'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 339,
      chnn_stock_retail: 339,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LNYX6TTL;5ZBVSA38',
      statusContent: 'dot',
      hash: '8EYEKDTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EYEKDTB'
    },
    {
      status: 210,
      sku: 'PM0078874',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313221',
      rtl_batch_array: '6313221',
      name_search: 'SAMENZIE',
      name: 'Sanguisorba menziesii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6CX7JRJD',
      statusContent: 'dot',
      hash: '7HYXK1KX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HYXK1KX'
    },
    {
      status: 210,
      sku: 'PM0081809',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348778',
      rtl_batch_array: '6348778',
      name_search: 'EUJMARIE',
      name: "Euonymus japonicus 'Marieke'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.073,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BA2ZPN3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA2ZPN3Y'
    },
    {
      status: 210,
      sku: 'PM0048425',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353222',
      rtl_batch_array: '6353222',
      name_search: 'POEANN',
      name: "Potentilla 'Esta Ann'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5GB3NGLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GB3NGLZ'
    },
    {
      status: 910,
      sku: 'PM0078875',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313222',
      rtl_batch_array: '6313222',
      name_search: 'ASAEMURA',
      name: "Aster ageratoides 'Ezo Murazaki'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 7.378,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9J4ZTXES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J4ZTXES'
    },
    {
      status: 210,
      sku: 'PM0054922',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307437',
      rtl_batch_array: '6307437',
      name_search: 'EUJSUSAN',
      name: "Euonymus japonicus 'Susan'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_prcp: 7.111,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YRYJCSDB;G2ENXP6V;582DNDNN;XNBTS5Y9;SKVS2VKD;S5K81W5C;6J6DNDEH;1DXEJT81;BKKNA9NR;XHP9R2WC;H1S8WV1E;61TL7CXY;RSADHCJP',
      statusContent: 'dot',
      hash: 'CZNALG3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZNALG3A'
    },
    {
      status: 210,
      sku: 'PM0081810',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348779',
      rtl_batch_array: '6348779',
      name_search: 'EUJMICRO',
      name: "Euonymus japonicus 'Microphyllus'",
      sppl_size_code: '010015C1',
      rng_range_identifier: 'H010015C1',
      rng_range_description: 'H10 cm 15 cm C1',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.298,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7TCCLEB;V59GN3RX;CRJBAARW;7SYD41S7;SXLDXYRT;CH2T2NVE;P32AK36A;1NT8E3D2;85JEYV2G;EXTSCZY1;2356KPR1',
      statusContent: 'dot',
      hash: '81SP3THC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81SP3THC'
    },
    {
      status: 210,
      sku: 'PM0081811',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348780',
      rtl_batch_array: '6348780',
      name_search: 'EUJPBLAN',
      name: 'Euonymus japonicus Paloma Blanca',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.129,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H3R51T6;B7E9D968;L2K9DYN2;RE2CWTBT;RCPWNR7V;TCAXJVBK;HBBA57GJ;S2VJP2XN;CLH92YZR;L4C927H1;9948PKVC;65LE7L44;ATD8AAJC',
      statusContent: 'dot',
      hash: 'XHK2AYPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHK2AYPS'
    },
    {
      status: 210,
      sku: 'PM0065348',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244397',
      rtl_batch_array: '6244397',
      name_search: 'THP4EVER',
      name: "Thuja plicata '4ever'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 253,
      chnn_stock_retail: 253,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6CWZJX1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CWZJX1J'
    },
    {
      status: 210,
      sku: 'PM0081812',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348781',
      rtl_batch_array: '6348781',
      name_search: 'EXRBPEAR',
      name: 'Exochorda racemosa Blushing Pearl',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 0.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RJLDG1KD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJLDG1KD'
    },
    {
      status: 210,
      sku: 'PM0078909',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252509',
      rtl_batch_array: '6252509',
      name_search: 'CAMUSKIN',
      name: 'Carex muskingumensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 647,
      chnn_stock_retail: 647,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLSDJ67V;CLKK4ADP;VGHX8BDH;27JCTENL;GW3RHL98;DH6SHBYB;YP4YR2XK',
      statusContent: 'dot',
      hash: 'RCJB3RXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCJB3RXW'
    },
    {
      status: 210,
      sku: 'PM0085320',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373221',
      rtl_batch_array: '6373221',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: '59YZD9YE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59YZD9YE'
    },
    {
      status: 210,
      sku: 'PM0085321',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373222',
      rtl_batch_array: '6373222',
      name_search: 'WESTYRIA',
      name: "Weigela 'Styriaca'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1357,
      chnn_stock_retail: 1357,
      sppl_prcp: 2.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PEVKDYLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEVKDYLW'
    },
    {
      status: 210,
      sku: 'PM0085322',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373223',
      rtl_batch_array: '6373223',
      name_search: 'WESTYRIA',
      name: "Weigela 'Styriaca'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2545,
      chnn_stock_retail: 2545,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ZN4ELZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZN4ELZ8'
    },
    {
      status: 210,
      sku: 'PM0065337',
      core_name: 'Plant',
      sppl_ean: '8720664888135',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162731',
      rtl_batch_array: '6162731',
      name_search: 'TAMNIDIF',
      name: "Taxus media 'Nidiformis'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 301,
      chnn_stock_retail: 301,
      sppl_prcp: 5.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5Z3VLWDB;RG5X7BV7;BVVRYRY6;BK9GBCP9;YBR9AN4A',
      statusContent: 'dot',
      hash: '9754WTCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9754WTCG'
    },
    {
      status: 210,
      sku: 'PM0078914',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252733',
      rtl_batch_array: '6252733',
      name_search: 'POVULGAR',
      name: 'Polypodium vulgare',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 828,
      chnn_stock_retail: 828,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ESLXN3BR;D792YKHT;CSAYPLAG;43ARZVG5;7YEHZVRP;RNCX8J6B',
      statusContent: 'dot',
      hash: 'DYNNC7PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYNNC7PD'
    },
    {
      status: 210,
      sku: 'PM0078915',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6373578',
      rtl_batch_array: '5837397, 6373578, 6349808',
      name_search: 'ATFFEMIN',
      name: 'Athyrium filix-femina',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 168,
      chnn_stock_retail: 1078,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGWSJ4WR;Y5ZW7H7Z;VYWWGHEP',
      statusContent: 'dot',
      hash: 'E4KSA4ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4KSA4ZD'
    },
    {
      status: 210,
      sku: 'PM0065351',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244399',
      rtl_batch_array: '6244399',
      name_search: 'THOANANA',
      name: "Thuja occidentalis 'Aurea Nana'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1546,
      chnn_stock_retail: 1546,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPT3LGC',
      statusContent: 'dot',
      hash: '2ZR5N324',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZR5N324'
    },
    {
      status: 810,
      sku: 'PM0048369',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220804',
      rtl_batch_array: '6220804',
      name_search: 'HEOQDPIC',
      name: "Helleborus o. 'Queens D. Picotee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7X593HL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7X593HL4'
    },
    {
      status: 210,
      sku: 'PM0078924',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385799',
      rtl_batch_array: '6254788, 6385799',
      name_search: 'MOARUNDI',
      name: 'Molinia arundinacea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1509,
      chnn_stock_retail: 1976,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6C47DXNN',
      statusContent: 'dot',
      hash: 'LHET9AYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHET9AYS'
    },
    {
      status: 210,
      sku: 'PM0078925',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254797',
      rtl_batch_array: '6254797',
      name_search: 'OSRPURPU',
      name: "Osmunda regalis 'Purpurascens'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CNY7JSLL',
      statusContent: 'dot',
      hash: 'ZR16CSBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR16CSBL'
    },
    {
      status: 210,
      sku: 'PM0078926',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254813',
      rtl_batch_array: '6254813',
      name_search: 'POACULEA',
      name: 'Polystichum aculeatum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1189,
      chnn_stock_retail: 1189,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R9TRX944;WBXBGB36;WT2GR6S9;ZZC8TX5G;A44CS6WH;68K2BJZV;2WGS931X;W7JALXYA;2KX6DW1T;X6ZYPLSC;2XRZDARB;TN3RTTW8;DLNP7V33;EP2TNSXG;N3YJYTEN;TR91LVB2;K6HCANB7;G2Z55W2R',
      statusContent: 'dot',
      hash: 'SR2Z9EH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SR2Z9EH4'
    },
    {
      status: 910,
      sku: 'PM0078927',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POMUNITU',
      name: 'Polystichum munitum',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'Z1TW92G8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z1TW92G8'
    },
    {
      status: 210,
      sku: 'PM0078928',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254815',
      rtl_batch_array: '6254815',
      name_search: 'POPOLYBL',
      name: 'Polystichum polyblepharum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 724,
      chnn_stock_retail: 724,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLEW96CY;YVSXH5L4;CER6HTPA;LBAHT1GD;GVACZPJK;RBGE8TGS;7AHKBL7C;NHEA7N3E;AV2AHLS3;C7LL43WE;1HP1YKXT',
      statusContent: 'dot',
      hash: 'SWK3WVR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWK3WVR1'
    },
    {
      status: 210,
      sku: 'PM0048349',
      core_name: 'Plant',
      sppl_ean: '8720353019826',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034347',
      rtl_batch_array: '6034347',
      name_search: 'GESNHPUR',
      name: "Geranium sanguineum 'New Hampshire Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1172,
      chnn_stock_retail: 1172,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDL263JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDL263JD'
    },
    {
      status: 210,
      sku: 'PM0078929',
      core_name: 'Plant',
      sppl_ean: '8720626360921',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905596',
      rtl_batch_array: '5905596',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 804,
      chnn_stock_retail: 804,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'S35ZN1VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S35ZN1VB'
    },
    {
      status: 210,
      sku: 'PM0078932',
      core_name: 'Plant',
      sppl_ean: '8720664876484',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151590',
      rtl_batch_array: '6151590',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 558,
      chnn_stock_retail: 558,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'BVXJKAYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVXJKAYA'
    },
    {
      status: 210,
      sku: 'PM0078542',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310231',
      rtl_batch_array: '6310231',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_prcp: 5.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'KGYEBSJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGYEBSJY'
    },
    {
      status: 210,
      sku: 'PM0065338',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244392',
      rtl_batch_array: '6244392',
      name_search: 'THODANIC',
      name: "Thuja occidentalis 'Danica'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 4224,
      chnn_stock_retail: 4224,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8BKY3T6;XJHVS4RW;X1HJK86R;ALA6PH9S;RNK6445D;P4VJXZ64;CLH1AKYW;ND2ZDWH4;D2CKJAEB;P29431BK;CLPLYN3C;8XDHEV62;9RJ5XTBB;A1BBTE4T',
      statusContent: 'dot',
      hash: 'STZJS258',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STZJS258'
    },
    {
      status: 210,
      sku: 'PM0048391',
      core_name: 'Plant',
      sppl_ean: '8720664549821',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015338',
      rtl_batch_array: '6015338',
      name_search: 'HOIEYES',
      name: "Hosta 'Irish Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NHADR7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NHADR7H'
    },
    {
      status: 210,
      sku: 'PM0048361',
      core_name: 'Plant',
      sppl_ean: '8720626304499',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053900',
      rtl_batch_array: '6053900',
      name_search: 'HECARMEN',
      name: "Heuchera 'Carmen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 573,
      chnn_stock_retail: 573,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDYE4V74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDYE4V74'
    },
    {
      status: 210,
      sku: 'PM0054664',
      core_name: 'Plant',
      sppl_ean: '8720664853447',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080606',
      rtl_batch_array: '6080606',
      name_search: 'BANIGRA',
      name: 'Ballota nigra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V54K43W7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V54K43W7'
    },
    {
      status: 210,
      sku: 'PM0054943',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369228',
      rtl_batch_array: '6369228, 6187533',
      name_search: 'CIRFMAGI',
      name: 'Cirsium rivulare Frosted Magic',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 174,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RAXA9DPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAXA9DPC'
    },
    {
      status: 210,
      sku: 'PM0069406',
      core_name: 'Plant',
      sppl_ean: '8720664688148',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5741470',
      rtl_batch_array: '6350104, 6302485, 5741470',
      name_search: 'ECPPARAS',
      name: 'Echinacea Pretty Parasols',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 202,
      chnn_stock_retail: 1201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9XNYA8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9XNYA8K'
    },
    {
      status: 210,
      sku: 'PM0069391',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350022',
      rtl_batch_array: '6350022',
      name_search: 'ECPALASK',
      name: "Echinacea purpurea 'Alaska'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SK65VR46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK65VR46'
    },
    {
      status: 210,
      sku: 'PM0069399',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242023',
      rtl_batch_array: '6350072, 6238791, 6242023',
      name_search: 'ECPPWBER',
      name: 'Echinacea purpurea Powwow Wild Berry',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 25128,
      chnn_stock_retail: 27117,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X33VL8JL;WYNH1H77;XER6JWCE;52BALRDB;RCNVALD7;AZH3VCLA;XBRK6TSV;8D3JRN2W;NBY732ZS;TCV61R4W;PY43TD7L',
      statusContent: 'dot',
      hash: 'VABRHSPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VABRHSPL'
    },
    {
      status: 910,
      sku: 'PM0069393',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECPFIREB',
      name: "Echinacea purpurea 'Firebird'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'VAE5P48P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VAE5P48P'
    },
    {
      status: 210,
      sku: 'PM0069404',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350073',
      rtl_batch_array: '6350073',
      name_search: 'ECPPURIT',
      name: "Echinacea purpurea 'Purity'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RWL3GP5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWL3GP5B'
    },
    {
      status: 210,
      sku: 'PM0069405',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350074',
      rtl_batch_array: '6350074',
      name_search: 'ECPRMARC',
      name: "Echinacea purpurea 'Rainbow Marcella'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 638,
      chnn_stock_retail: 638,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J65BLZLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J65BLZLK'
    },
    {
      status: 210,
      sku: 'PM0078862',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313204',
      rtl_batch_array: '6313204',
      name_search: 'LABBABY',
      name: "Lavatera 'Barnsley Baby'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VESE5VJZ;8CB89BAK;PC1BGKHB;1BERNVZD;1R8Y1YBA;6R84G5WG;V8E44GEJ;C6VVN257;ETXCWT94;BA185XAL;CAH53L87;EP15BJ8K;B9PZ9TKL',
      statusContent: 'dot',
      hash: 'J7Y8ADCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7Y8ADCA'
    },
    {
      status: 210,
      sku: 'PM0078863',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313205',
      rtl_batch_array: '6313205',
      name_search: 'LABSILVE',
      name: "Lavatera 'Barnsley Silver'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 482,
      chnn_stock_retail: 482,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NZVNZWAJ;PW2ZTR2W',
      statusContent: 'dot',
      hash: 'S8TLTATT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8TLTATT'
    },
    {
      status: 210,
      sku: 'PM0071146',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338548',
      rtl_batch_array: '6228362, 6338548',
      name_search: 'VIPWATAN',
      name: "Viburnum plicatum 'Watanabe'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1904,
      chnn_stock_retail: 1973,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9LXJV3K;827Z6E42;AS1PNED1;NRRZK322;DNGB43HJ;ZE1WC5B3',
      statusContent: 'dot',
      hash: 'AJTHAC8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJTHAC8G'
    },
    {
      status: 210,
      sku: 'PM0078864',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313209',
      rtl_batch_array: '6313209',
      name_search: 'LAFREDER',
      name: "Lavatera 'Frederique'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A9BX9ZZZ',
      statusContent: 'dot',
      hash: 'ZY7JNKA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY7JNKA1'
    },
    {
      status: 210,
      sku: 'PM0078919',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254668',
      rtl_batch_array: '6254668',
      name_search: 'BEVINTER',
      name: "Bergenia 'Vintergl\u00f6d'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RZA17C9A;7E8D5HTK;J25NW5CR;ET27B68E;ZSVXDTE5;X1W1BPPP;Z5V1VD43',
      statusContent: 'dot',
      hash: 'JXDPXZ5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXDPXZ5R'
    },
    {
      status: 210,
      sku: 'PM0078868',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313215',
      rtl_batch_array: '6313215',
      name_search: 'CIPMAJOR',
      name: 'Cistus populifolius major',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WC5YZ6K',
      statusContent: 'dot',
      hash: 'VL1G8SY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL1G8SY7'
    },
    {
      status: 810,
      sku: 'PM0078869',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313216',
      rtl_batch_array: '6313216',
      name_search: 'CISKANBE',
      name: 'Cistus skanbergii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V1S1GE3S;PZWDBZBV;BTZ9HPCZ',
      statusContent: 'dot',
      hash: '9JGL6CP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JGL6CP7'
    },
    {
      status: 210,
      sku: 'PM0078537',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310225',
      rtl_batch_array: '6310225',
      name_search: 'COSANGUI',
      name: 'Cornus sanguinea',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 5844,
      chnn_stock_retail: 5844,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X4V1AR2G;JATJ5LR2;PEWSNGWB;78CDZ1AA;JVEEB7VA;PGNCTZX2;RWP4CHAX',
      statusContent: 'dot',
      hash: '62VX14YL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62VX14YL'
    },
    {
      status: 210,
      sku: 'PM0078540',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310229',
      rtl_batch_array: '6310229',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 8230,
      chnn_stock_retail: 8230,
      sppl_order_minimum: 3,
      sppl_prcp: 0.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'E87ACXJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E87ACXJN'
    },
    {
      status: 810,
      sku: 'PM0071147',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 4,
      btch_active_retail: '6314045',
      rtl_batch_array: '6314045, 6370653, 5420723, 6348855',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 3593,
      sppl_prcp: 2.751,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'K284ADYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K284ADYV'
    },
    {
      status: 210,
      sku: 'PM0066741',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6255111',
      rtl_batch_array: '6300185, 6255111',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 691,
      chnn_stock_retail: 3491,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'L5DP1LP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5DP1LP9'
    },
    {
      status: 910,
      sku: 'PM0078551',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COLACTEU',
      name: 'Cotoneaster lacteus',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore: 'ZBLSAC4B;PR1173VV;DPHVETH3;7BA9YC8Y;SLHYXZLL;77D3WDW4',
      statusContent: 'dot',
      hash: 'T2ZKKHYC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T2ZKKHYC'
    },
    {
      status: 210,
      sku: 'PM0078936',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234380',
      rtl_batch_array: '6234380',
      name_search: 'MIEAUREU',
      name: "Milium effusum 'Aureum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PA1HGNEJ;WATZBWTA;PNN88RTC;Y1PSZDTA;HYCY176A;TK424JW2;4ZRPJZGR;6EB9G46C;4W53D3DR;CV2DZYYE;PTZ8LNAC;CRE65RJH;W95Z18C7;G2HJ4C6C;V9TE2ZEV;HTV4ST7Z;62TWHZS2',
      statusContent: 'dot',
      hash: 'APL72R9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APL72R9Y'
    },
    {
      status: 910,
      sku: 'PM0078553',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSIMONS',
      name: 'Cotoneaster simonsii',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore: '693G1Z6B',
      statusContent: 'dot',
      hash: 'K64ZP61R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K64ZP61R'
    },
    {
      status: 210,
      sku: 'PM0078937',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234541',
      rtl_batch_array: '6234541',
      name_search: 'ACSPSPIK',
      name: "Actaea simplex 'Pink Spike'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 406,
      chnn_stock_retail: 406,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D6SR6ZWL;C1GRTNNY;WAD5WCKW;HPWPEB7T;NLTV1YAX;Y1V4NRXV;TTP5SRLC;1RY6LWRP',
      statusContent: 'dot',
      hash: 'SPGHCKET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPGHCKET'
    },
    {
      status: 210,
      sku: 'PM0078554',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310245',
      rtl_batch_array: '6310245',
      name_search: 'COSIMONS',
      name: 'Cotoneaster simonsii',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '693G1Z6B',
      statusContent: 'dot',
      hash: 'N6KRAJL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6KRAJL8'
    },
    {
      status: 810,
      sku: 'PM0066746',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280958',
      rtl_batch_array: '6280958',
      name_search: 'FRAALNUS',
      name: 'Frangula alnus',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W6LZP94;WNG5165T;PVE56JTL;NY6S2ASZ',
      statusContent: 'dot',
      hash: 'N8342NXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8342NXE'
    },
    {
      status: 810,
      sku: 'PM0078555',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310246',
      rtl_batch_array: '6310246',
      name_search: 'COSIMONS',
      name: 'Cotoneaster simonsii',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 4.781,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '693G1Z6B',
      statusContent: 'dot',
      hash: 'B67VN53X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B67VN53X'
    },
    {
      status: 210,
      sku: 'PM0068181',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193790',
      rtl_batch_array: '6193790',
      name_search: 'IRKVARIE',
      name: "Iris kaempferi 'Variegata'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WW1H931S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW1H931S'
    },
    {
      status: 210,
      sku: 'PM0078556',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310247',
      rtl_batch_array: '6310247',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 5564,
      chnn_stock_retail: 5564,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'K227D6B5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K227D6B5'
    },
    {
      status: 210,
      sku: 'PM0078938',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234562',
      rtl_batch_array: '6234562',
      name_search: 'ANHAATKI',
      name: "Anemone hybrida 'Andrea Atkinson'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.215,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4PPT85W',
      statusContent: 'dot',
      hash: '48ZNSXE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48ZNSXE3'
    },
    {
      status: 210,
      sku: 'PM0078557',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310249',
      rtl_batch_array: '6310249',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_prcp: 5.153,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: '929ZA14A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '929ZA14A'
    },
    {
      status: 210,
      sku: 'PM0078558',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356582',
      rtl_batch_array: '6310250, 6356582',
      name_search: 'CYSCOPAR',
      name: 'Cytisus scoparius',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 12146,
      sppl_prcp: 1.224,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PB1BT1A9',
      statusContent: 'dot',
      hash: 'LJ1KXP17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ1KXP17'
    },
    {
      status: 210,
      sku: 'PM0078560',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310253',
      rtl_batch_array: '6310253',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'G9CYT5P5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9CYT5P5'
    },
    {
      status: 910,
      sku: 'PM0078559',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DAINVOLU',
      name: 'Davidia involucrata',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      imageCore: 'JLRXRGKY',
      statusContent: 'dot',
      hash: 'ZT6V7EX2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZT6V7EX2'
    },
    {
      status: 810,
      sku: 'PM0065439',
      core_name: 'Plant',
      sppl_ean: '8720664890220',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164489',
      rtl_batch_array: '6164489',
      name_search: 'VIREGENT',
      name: "Vitis 'Regent'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 3.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4R7TJPPS',
      statusContent: 'dot',
      hash: 'CC18NKBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CC18NKBY'
    },
    {
      status: 210,
      sku: 'PM0078561',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310256',
      rtl_batch_array: '6310256',
      name_search: 'DESCABRA',
      name: 'Deutzia scabra',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJBTE6SV',
      statusContent: 'dot',
      hash: '612B59PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '612B59PE'
    },
    {
      status: 210,
      sku: 'PM0068857',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253067',
      rtl_batch_array: '6253067',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1420,
      chnn_stock_retail: 1420,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: '9129REZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9129REZG'
    },
    {
      status: 910,
      sku: 'PM0078562',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DISPLEND',
      name: 'Diervilla splendens',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '9P4PZAWK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9P4PZAWK'
    },
    {
      status: 210,
      sku: 'PM0078563',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310258',
      rtl_batch_array: '6298584, 6310258',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2300,
      chnn_stock_retail: 3184,
      sppl_prcp: 1.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '6R4VXTWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6R4VXTWE'
    },
    {
      status: 210,
      sku: 'PM0078564',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310261',
      rtl_batch_array: '6310261',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'NJGLYA22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJGLYA22'
    },
    {
      status: 210,
      sku: 'PM0078565',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310262',
      rtl_batch_array: '6310262',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 770,
      chnn_stock_retail: 770,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'NLYXBL82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLYXBL82'
    },
    {
      status: 210,
      sku: 'PM0078566',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310263',
      rtl_batch_array: '6310263',
      name_search: 'ESCBALL',
      name: "Escallonia 'C.F. Ball'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4300,
      chnn_stock_retail: 4300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '55DBW322',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55DBW322'
    },
    {
      status: 210,
      sku: 'PM0071151',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228368',
      rtl_batch_array: '6228368',
      name_search: 'CHPSUNGO',
      name: "Chamaecyparis pisifera 'Sungold'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_prcp: 2.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZHLJTR1H;5XZEYB8G;7YNLHJLH;Y1BTW97G;GZJG415D;ECLLZG7H;JAYJDR7D;Z2T7J22C;5592HDCX;AA25R4RZ;2Z5DKD8B;9CYC76PC;ZHDTBX3N;B9C4KJ17;5D5APVLG;64XLHEK9;WP8N54HS',
      statusContent: 'dot',
      hash: '452J5GZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '452J5GZV'
    },
    {
      status: 210,
      sku: 'PM0078567',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310264',
      rtl_batch_array: '6310264',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_prcp: 4.777,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: '8L61RERA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8L61RERA'
    },
    {
      status: 810,
      sku: 'PM0078748',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350257',
      rtl_batch_array: '6350257',
      name_search: 'GEWZEPPE',
      name: "Geranium wlassovianum 'Zeppelin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DBVTJHE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBVTJHE6'
    },
    {
      status: 210,
      sku: 'PM0065434',
      core_name: 'Plant',
      sppl_ean: '8720664876132',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164483',
      rtl_batch_array: '6164483',
      name_search: 'PACLOUIS',
      name: "Paeonia (I) 'Cora Louise'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBHB24AG',
      statusContent: 'dot',
      hash: '2XHHSJTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XHHSJTR'
    },
    {
      status: 210,
      sku: 'PM0068858',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253069',
      rtl_batch_array: '6253069, 6336218',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3965,
      chnn_stock_retail: 6665,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: '4BKA4WDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BKA4WDR'
    },
    {
      status: 810,
      sku: 'PM0078934',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6128575',
      rtl_batch_array: '6128575',
      name_search: 'PEVLCORP',
      name: "Persicaria virginiana 'Lance Corporal'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TZLE16KP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZLE16KP'
    },
    {
      status: 210,
      sku: 'PM0085323',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373224',
      rtl_batch_array: '6373224',
      name_search: 'WESTYRIA',
      name: "Weigela 'Styriaca'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 739,
      chnn_stock_retail: 739,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8AANCDY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AANCDY7'
    },
    {
      status: 210,
      sku: 'PM0068179',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193771',
      rtl_batch_array: '6193771',
      name_search: 'CARIPARI',
      name: 'Carex riparia',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCLLYR5L;EL2K96EY',
      statusContent: 'dot',
      hash: 'E9S6RH77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9S6RH77'
    },
    {
      status: 910,
      sku: 'PM0065438',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLBALTYK',
      name: "Clematis 'Baltyk'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '94CNJPK6;NYDZTZCL',
      statusContent: 'dot',
      hash: 'PY9K92AA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PY9K92AA'
    },
    {
      status: 210,
      sku: 'PM0068177',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193740',
      rtl_batch_array: '6193740',
      name_search: 'SCCERNUU',
      name: 'Scirpus cernuus',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JSHJZLER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSHJZLER'
    },
    {
      status: 210,
      sku: 'PM0078552',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310243',
      rtl_batch_array: '6310243, 6338976',
      name_search: 'COPQCARP',
      name: "Cotoneaster procumbens 'Queen of Carpets'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4200,
      chnn_stock_retail: 12745,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4WX354E',
      statusContent: 'dot',
      hash: '17SXKYAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17SXKYAX'
    },
    {
      status: 810,
      sku: 'PM0066626',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174585',
      rtl_batch_array: '6174585',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 1.788,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'VWHH7P25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWHH7P25'
    },
    {
      status: 210,
      sku: 'PM0081813',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348782',
      rtl_batch_array: '6348782',
      name_search: 'EXRNIAGA',
      name: "Exochorda racemosa 'Niagara'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.098,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AERC8NXG;BNYXBTEC;XT44X7YT;VD3B6Z1B;JLH3SBCK;B9C8HS3R;J6XTP7A1;H1CR5KZC;SWGBWSRL;AW9Y692B;D9DTVY2V;TTS9K5VT;5A4YC53D;7HT5556T;9147LVST;W3B5LVLP;T1VRCPZ9;YRBZRN1Z;V6V3VVD7;2EJT8SWX;6AYWW6T1;2XCKNVJS',
      statusContent: 'dot',
      hash: '7GGDW1ZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GGDW1ZC'
    },
    {
      status: 210,
      sku: 'PM0081814',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348783',
      rtl_batch_array: '6348783',
      name_search: 'FICALMFI',
      name: 'Ficus car. Little Miss Figgy',
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXJW24WN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXJW24WN'
    },
    {
      status: 210,
      sku: 'PM0081815',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348786',
      rtl_batch_array: '6348786',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_prcp: 6.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '7B96E6DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7B96E6DS'
    },
    {
      status: 210,
      sku: 'PM0069425',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259381',
      rtl_batch_array: '6259381',
      name_search: 'ALLMSOPH',
      name: 'Alstroemeria Little Miss Sophie',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZCNC87X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZCNC87X'
    },
    {
      status: 210,
      sku: 'PM0085324',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373225',
      rtl_batch_array: '6373225',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 1043,
      chnn_stock_retail: 1043,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: '55KHX2PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55KHX2PD'
    },
    {
      status: 910,
      sku: 'PM0048494',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHOBDREA',
      name: "Chaenorhinum origanifolium 'Blue Dream'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        'XCRGYK67;A3AHPWDL;R34EEXK3;9WJP5BCD;84KH1X2T;5EBD2LNR;643JXCZG;H5C2P2JX;NRNJEPXZ;T89SGCBT;CH7J85JB;ZG2VDZ3D;H8XXCRBV;BWC3YP53;17NEPG1E;R6HBG9X6',
      statusContent: 'dot',
      hash: '2E912LW2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2E912LW2'
    },
    {
      status: 210,
      sku: 'PM0069430',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284284',
      rtl_batch_array: '6284284, 6321876',
      name_search: 'ANALROYA',
      name: "Anchusa azurea 'Loddon Royalist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3363,
      chnn_stock_retail: 3531,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '85B4XHAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85B4XHAP'
    },
    {
      status: 910,
      sku: 'PM0069435',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ANECYLIN',
      name: 'Anemone cylindrica',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WVDN88EL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WVDN88EL'
    },
    {
      status: 210,
      sku: 'PM0085325',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373226',
      rtl_batch_array: '6373226',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'C145EHBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C145EHBK'
    },
    {
      status: 210,
      sku: 'PM0069437',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284287',
      rtl_batch_array: '6284287',
      name_search: 'ANHFPOCA',
      name: 'Anemone hybrida Fantasy Pocahontas',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '45ZLCWR7;ZVLZ3Z6B;DDT7C8YP',
      statusContent: 'dot',
      hash: 'ND5G5KGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND5G5KGS'
    },
    {
      status: 910,
      sku: 'PM0065448',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLBANIOL',
      name: "Clematis 'Blekitny Aniol'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'HXGKZJVR;6EYR26SS',
      statusContent: 'dot',
      hash: '956RZP71',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '956RZP71'
    },
    {
      status: 210,
      sku: 'PM0065408',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207805',
      rtl_batch_array: '6207805',
      name_search: 'AQVALB',
      name: "Aquilegia vulgaris 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PJH2C7H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJH2C7H9'
    },
    {
      status: 210,
      sku: 'PM0048457',
      core_name: 'Plant',
      sppl_ean: '8720626350854',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6015826',
      rtl_batch_array: '6350462, 6254389, 6015826, 6338942',
      name_search: 'HEABBLOO',
      name: "Heucherella alba 'Bridget Bloom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 1796,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SENBSPDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SENBSPDZ'
    },
    {
      status: 210,
      sku: 'PM0065407',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363175',
      rtl_batch_array: '6300425, 6164358, 6363175',
      name_search: 'AQVBBARL',
      name: "Aquilegia vulgaris 'Black Barlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2016,
      chnn_stock_retail: 2312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X72244EW',
      statusContent: 'dot',
      hash: '2EAEA51P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EAEA51P'
    },
    {
      status: 210,
      sku: 'PM0085326',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373227',
      rtl_batch_array: '6373227',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2036,
      chnn_stock_retail: 2036,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'DA5L7S6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA5L7S6K'
    },
    {
      status: 210,
      sku: 'PM0078935',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217001',
      rtl_batch_array: '6217001',
      name_search: 'ASNBWCHU',
      name: "Aster novi-belgii 'Winston Churchill'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HL6N8S9T;XHCTWLW6;NG7V5J69',
      statusContent: 'dot',
      hash: 'B8J9P1NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8J9P1NJ'
    },
    {
      status: 210,
      sku: 'PM0071152',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228369',
      rtl_batch_array: '6228369',
      name_search: 'JUCSTRIC',
      name: "Juniperus chinensis 'Stricta'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8WRAT7VR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WRAT7VR'
    },
    {
      status: 210,
      sku: 'PM0065452',
      core_name: 'Plant',
      sppl_ean: '8720664876736',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164503',
      rtl_batch_array: '6164503',
      name_search: 'PAQENGEL',
      name: 'Parthenocissus quinquefolia engelmannii',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG1H657G;1D75YPT3;JC84WWJZ;XY55AD12;DPP95XZR;TAVEAT1N',
      statusContent: 'dot',
      hash: 'RYTV3EGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYTV3EGK'
    },
    {
      status: 210,
      sku: 'PM0065410',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348457',
      rtl_batch_array: '6164380, 6348457',
      name_search: 'BECHERBS',
      name: "Bergenia cordifolia 'Herbstbl\u00fcte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1413,
      chnn_stock_retail: 1525,
      sppl_order_minimum: 3,
      sppl_prcp: 0.605,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1TG6B1EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TG6B1EV'
    },
    {
      status: 210,
      sku: 'PM0065386',
      core_name: 'Plant',
      sppl_ean: '8720664860865',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164265',
      rtl_batch_array: '6164265, 6319714',
      name_search: 'ECPBSWHI',
      name: "Echinacea purpurea 'Baby Swan White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 469,
      chnn_stock_retail: 759,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9S7YPTTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S7YPTTJ'
    },
    {
      status: 210,
      sku: 'PM0071150',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228366',
      rtl_batch_array: '6228366',
      name_search: 'YUFILAME',
      name: 'Yucca filamentosa',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1419,
      chnn_stock_retail: 1419,
      sppl_prcp: 2.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G8W1SGKZ;2TYTVK3S',
      statusContent: 'dot',
      hash: 'HX8JVP73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX8JVP73'
    },
    {
      status: 210,
      sku: 'PM0081816',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348787',
      rtl_batch_array: '6348787',
      name_search: 'HYACMARS',
      name: 'Hydrangea arborescens Candybelle Marshmallow',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4ABC9GK1;7WSRRPNW;K8DSJNDW;BH73L1R7;1P1SC19D',
      statusContent: 'dot',
      hash: 'K1DYDVDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1DYDVDT'
    },
    {
      status: 210,
      sku: 'PM0085327',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373228',
      rtl_batch_array: '6373228',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2395,
      chnn_stock_retail: 2395,
      sppl_prcp: 4.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'WEKNLAVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEKNLAVZ'
    },
    {
      status: 210,
      sku: 'PM0069412',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350110',
      rtl_batch_array: '6350110',
      name_search: 'ECBALBUS',
      name: "Echinops bannaticus 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5BHZ8XT3',
      statusContent: 'dot',
      hash: '6ED842HR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ED842HR'
    },
    {
      status: 210,
      sku: 'PM0085328',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373229',
      rtl_batch_array: '6373229',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2168,
      chnn_stock_retail: 2168,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'L1V2ZKTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1V2ZKTZ'
    },
    {
      status: 210,
      sku: 'PM0081817',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348790',
      rtl_batch_array: '6348790',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 5.469,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: '2T5X71VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T5X71VK'
    },
    {
      status: 210,
      sku: 'PM0065389',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6287670',
      rtl_batch_array: '6268746, 6287670, 6238793',
      name_search: 'EPPFROHN',
      name: "Epimedium perralchicum 'Frohnleiten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 835,
      chnn_stock_retail: 15819,
      sppl_order_minimum: 3,
      sppl_prcp: 1.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TRC2LNDT;1GHXH3WP',
      statusContent: 'dot',
      hash: 'Y5CD6RTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5CD6RTE'
    },
    {
      status: 210,
      sku: 'PM0081818',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356149',
      rtl_batch_array: '6356149, 6348792',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 3100,
      sppl_order_minimum: 5,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: 'NPPNDHRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPPNDHRK'
    },
    {
      status: 210,
      sku: 'PM0081819',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348793',
      rtl_batch_array: '6348793',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.469,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: 'HHWZSB3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHWZSB3H'
    },
    {
      status: 210,
      sku: 'PM0068180',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193778',
      rtl_batch_array: '6193778',
      name_search: 'EQJAPONI',
      name: 'Equisetum japonicum',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LCXWAEVC;CH9815T5;D5HKGN7E;SD41BAL2;BG8T3VLN',
      statusContent: 'dot',
      hash: 'T6TD6ESX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6TD6ESX'
    },
    {
      status: 210,
      sku: 'PM0069441',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350171',
      rtl_batch_array: '6350171, 5866504',
      name_search: 'FRVRUGEN',
      name: "Fragaria vesca 'Rugen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1538,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GWRA5BVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWRA5BVL'
    },
    {
      status: 210,
      sku: 'PM0081820',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348794',
      rtl_batch_array: '6348794',
      name_search: 'HYMEBLUE',
      name: 'Hydrangea macrophylla Early Blue',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_order_minimum: 3,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TEP8X58H;LNL7W4G5',
      statusContent: 'dot',
      hash: '62NNY45V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62NNY45V'
    },
    {
      status: 210,
      sku: 'PM0065249',
      core_name: 'Plant',
      sppl_ean: '8720664888838',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161975',
      rtl_batch_array: '6161975, 6274839',
      name_search: 'TROSPREY',
      name: "Tradescantia (A) 'Osprey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 419,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VDJ3VW7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDJ3VW7G'
    },
    {
      status: 210,
      sku: 'PM0065248',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252969',
      rtl_batch_array: '6208116, 6252969',
      name_search: 'TICMOORG',
      name: "Tiarella cordifolia 'Moorgr\u00fcn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1234,
      chnn_stock_retail: 2434,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNLBCWZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNLBCWZL'
    },
    {
      status: 910,
      sku: 'PM0065461',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLASHVA',
      name: "Clematis 'Ashva'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'W7SHN1LA;YBP8G5TG',
      statusContent: 'dot',
      hash: 'RJGBJ273',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RJGBJ273'
    },
    {
      status: 210,
      sku: 'PM0081821',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6184045',
      rtl_batch_array: '6184045, 6348795',
      name_search: 'HYMLIBEL',
      name: "Hydrangea macrophylla 'Libelle'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 1050,
      sppl_order_minimum: 3,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D32EL74L;ZDCX7HCW;PPGVSD31;87SBT7K6;WEGAXENS;6BCBCPWW;2TZ4DEE7;82K99P7A;VCXPTL8T;AY6YND7C;55V2VJX3;5224VEL6;477G57W9;2R1PPD42;5NDCBTSG;LE6GNY12;PTHA8NJV',
      statusContent: 'dot',
      hash: '2L25Z6B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2L25Z6B7'
    },
    {
      status: 210,
      sku: 'PM0065423',
      core_name: 'Plant',
      sppl_ean: '8720664877924',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6164406',
      rtl_batch_array: '6164406, 6330034, 6345198',
      name_search: 'PHEUROPA',
      name: "Phlox (P) 'Europa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 1187,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HCHRSEZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCHRSEZB'
    },
    {
      status: 210,
      sku: 'PM0081822',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348796',
      rtl_batch_array: '6348796',
      name_search: 'HYMMASJ',
      name: "Hydrangea m. 'Masja'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AY5GH6N2;GJVT5SXH;8DPRVEC6;E7CB2ABV',
      statusContent: 'dot',
      hash: 'Z891HJVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z891HJVD'
    },
    {
      status: 210,
      sku: 'PM0081823',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348797',
      rtl_batch_array: '6348797',
      name_search: 'HYMMASJ',
      name: "Hydrangea m. 'Masja'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.469,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AY5GH6N2;GJVT5SXH;8DPRVEC6;E7CB2ABV',
      statusContent: 'dot',
      hash: 'DGRYHCBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGRYHCBB'
    },
    {
      status: 210,
      sku: 'PM0081824',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6246534',
      rtl_batch_array: '6246534, 6348798',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 3100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'GAYD7Z6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAYD7Z6X'
    },
    {
      status: 210,
      sku: 'PM0065429',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308447',
      rtl_batch_array: '6308447',
      name_search: 'SASSENIO',
      name: "Sagina subulata 'Senior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN6BH75A',
      statusContent: 'dot',
      hash: 'DK7CE2ZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DK7CE2ZZ'
    },
    {
      status: 910,
      sku: 'PM0065443',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLVOLUCE',
      name: "Clematis 'Voluceau'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'HZB15TG8;K5R19GNN;RN2ENVY6;8EXC16BX',
      statusContent: 'dot',
      hash: '7SXHJ66H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7SXHJ66H'
    },
    {
      status: 210,
      sku: 'PM0081825',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348799',
      rtl_batch_array: '6348799',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.469,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'TZG21CRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZG21CRW'
    },
    {
      status: 210,
      sku: 'PM0058906',
      core_name: 'Plant',
      sppl_ean: '8720664854536',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6146965',
      rtl_batch_array: '6146965, 6252495',
      name_search: 'CANNEPET',
      name: 'Calamintha nepeta nepeta',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1844,
      chnn_stock_retail: 3657,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9S3N79A;7YC1YZAD;B2K2VY9N',
      statusContent: 'dot',
      hash: 'CCXSC87C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCXSC87C'
    },
    {
      status: 210,
      sku: 'PM0081826',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356152',
      rtl_batch_array: '6356152, 6348800',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 5,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: '2H76NDZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2H76NDZK'
    },
    {
      status: 210,
      sku: 'PM0081827',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348801',
      rtl_batch_array: '6348801',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.469,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'D8LEB1BZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8LEB1BZ'
    },
    {
      status: 210,
      sku: 'PM0081828',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348802',
      rtl_batch_array: '6348802',
      name_search: 'HYMROTKE',
      name: "Hydrangea macrophylla 'Rotkehlchen'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 5,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ETTGREYR;5CG6K47R;L1B5W7C8;KK6ZACSV;G45WYBKL;1L93NLXB;1XXT79J2;K6A4HY15;P3DVTTPE;KJDAP7JY',
      statusContent: 'dot',
      hash: 'B9W1LS9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9W1LS9C'
    },
    {
      status: 210,
      sku: 'PM0081829',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348803',
      rtl_batch_array: '6348803',
      name_search: 'HYMMSANG',
      name: "Hydrangea macrophylla 'Merveille Sanguinea'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JHKGRAR8;2Y5NDCGL;R28CB3Y9;P1K57H9C;VG9WBNL5;63WA4367;EJ839NPE;W6KK371B;PGN62YXH;5HB93812;PVTD8WH6;74A4NVGZ;HE5NZPWJ',
      statusContent: 'dot',
      hash: '4XJE9DAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XJE9DAS'
    },
    {
      status: 210,
      sku: 'PM0085329',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373231',
      rtl_batch_array: '6373231',
      name_search: 'KEJPLENI',
      name: "Kerria japonica 'Pleniflora'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 742,
      chnn_stock_retail: 742,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y49EYZE8;945VKGPK;J19DN9A8;9Z6LSTEH;8N4SZZ37;27N1C4K3;YC9D7VAX;WHNEBXT4;V7NR7ETW;GZ9679V3;6T1ZH9LV;5EN8PSJ2;9881V54B;5TVCNZ8L',
      statusContent: 'dot',
      hash: 'ATD7SNW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATD7SNW5'
    },
    {
      status: 210,
      sku: 'PM0085330',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373232',
      rtl_batch_array: '6373232',
      name_search: 'KOAPCLOU',
      name: "Kolkwitzia amabilis 'Pink Cloud'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 712,
      chnn_stock_retail: 712,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DT525ENS;T83TB1ZW;CP4XJR5D;11XNXX48;TT7K71E6;8H3AABLZ;N2K8NJK1;SDS449K5',
      statusContent: 'dot',
      hash: 'HGH6SHZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGH6SHZE'
    },
    {
      status: 210,
      sku: 'PM0068175',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193738',
      rtl_batch_array: '6193738',
      name_search: 'ROINDICA',
      name: 'Rotala indica',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GTJ4X7WC;HW45XZBS;RSW3SNPC;T9CN9D5H;187ZVNZ4;XEW9WBG7;RZZZ5ADW',
      statusContent: 'dot',
      hash: '5877GWH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5877GWH4'
    },
    {
      status: 810,
      sku: 'PM0085331',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373233',
      rtl_batch_array: '6373233',
      name_search: 'KOAPCLOU',
      name: "Kolkwitzia amabilis 'Pink Cloud'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 6.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DT525ENS;T83TB1ZW;CP4XJR5D;11XNXX48;TT7K71E6;8H3AABLZ;N2K8NJK1;SDS449K5',
      statusContent: 'dot',
      hash: '4TENGXDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TENGXDA'
    },
    {
      status: 210,
      sku: 'PM0085332',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373235',
      rtl_batch_array: '6373235',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 2654,
      chnn_stock_retail: 2654,
      sppl_prcp: 6.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'K9WSH24K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9WSH24K'
    },
    {
      status: 210,
      sku: 'PM0085333',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373236',
      rtl_batch_array: '6373236',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 2074,
      chnn_stock_retail: 2074,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'HYVB9C9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYVB9C9H'
    },
    {
      status: 210,
      sku: 'PM0085334',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373237',
      rtl_batch_array: '6373237',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: 'GR8DVCDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GR8DVCDT'
    },
    {
      status: 210,
      sku: 'PM0085335',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373238',
      rtl_batch_array: '6373238',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 607,
      chnn_stock_retail: 607,
      sppl_prcp: 5.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: 'L93GGBSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L93GGBSE'
    },
    {
      status: 210,
      sku: 'PM0065404',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324690',
      rtl_batch_array: '6276639, 6324690, 6349744',
      name_search: 'ANHSPLEN',
      name: "Anemone hupehensis 'Splendens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 4259,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RY283ZVB;N2HHZHBE;AHEHX9D8;8XBBR6H7;E98ZGW45;SZE1X88E;AC7ADPGT;7EZR8V12;Y8KP4KNK;GSJ4GVYS',
      statusContent: 'dot',
      hash: 'Y99BV2YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y99BV2YT'
    },
    {
      status: 210,
      sku: 'PM0068171',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193734',
      rtl_batch_array: '6193734',
      name_search: 'MAQUADRI',
      name: 'Marsilea quadrifolia',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8HPSE5BH',
      statusContent: 'dot',
      hash: '19GRT1V3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19GRT1V3'
    },
    {
      status: 910,
      sku: 'PM0065458',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLRUEUET',
      name: "Clematis 'R\u00fc\u00fctel'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '1XD4247H;NPDG2WGJ',
      statusContent: 'dot',
      hash: '71D6ZGJD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '71D6ZGJD'
    },
    {
      status: 210,
      sku: 'PM0069427',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265353',
      rtl_batch_array: '6265353',
      name_search: 'AMTABERN',
      name: 'Amsonia tabernaemontana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 638,
      chnn_stock_retail: 638,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DENZDY22;7PGCD17A;47L87ZZP;TCVRVHWJ;DPXTA5JA;K1T38CCZ;R83ZWSCH;LS1JGELE;1KNTPVJY;GADYXK58',
      statusContent: 'dot',
      hash: 'N7S2Z4BB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7S2Z4BB'
    },
    {
      status: 210,
      sku: 'PM0065463',
      core_name: 'Plant',
      sppl_ean: '8720664870116',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164514',
      rtl_batch_array: '6164514',
      name_search: 'IRSANHAN',
      name: "Iris sibirica 'Ama-no-hana'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '95CCWNP9;XVX4K456;HC96YGAK',
      statusContent: 'dot',
      hash: 'SVYSVZR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVYSVZR3'
    },
    {
      status: 910,
      sku: 'PM0065435',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLJACKMA',
      name: "Clematis 'Jackmanii'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'WSPR3JDC;NDP77J4G;YCEDW2JB;PKKAAEB1;GTS3ZW5N;79GNYJYW;5WNHYCL8;H2YRSR9E',
      statusContent: 'dot',
      hash: 'RTP3SATD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RTP3SATD'
    },
    {
      status: 910,
      sku: 'PM0065459',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLARABEL',
      name: "Clematis 'Arabella'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'PVBZV29G;STGNCLEX;A9JAJCSS;X429P1NV;P3K3WTY9',
      statusContent: 'dot',
      hash: 'ADX76VXB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ADX76VXB'
    },
    {
      status: 210,
      sku: 'PM0069426',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276637',
      rtl_batch_array: '6276637',
      name_search: 'AMHUBRIC',
      name: 'Amsonia hubrichtii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7KWRL112;T5TPB1LW',
      statusContent: 'dot',
      hash: '64WNL2EL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64WNL2EL'
    },
    {
      status: 210,
      sku: 'PM0069432',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349565',
      rtl_batch_array: '6349565, 6263743, 6345187',
      name_search: 'ANLSIROC',
      name: "Anemanthele lessoniana 'Sirocco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 525,
      chnn_stock_retail: 2161,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EER61EPY;Z7ETYHLW;62WJ6SP2;S5692YV3;8PCV91SE;XXZ65D5L;ZK6JBDA9;98BCZCL1',
      statusContent: 'dot',
      hash: '22B745SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22B745SS'
    },
    {
      status: 210,
      sku: 'PM0069436',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268708',
      rtl_batch_array: '6268708, 6352607',
      name_search: 'ANFCINDE',
      name: 'Anemone Fantasy Cinderella',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P2W39G15;YNN8396B;TCBXHTNT;H8YRE13P',
      statusContent: 'dot',
      hash: '6ATKBRDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ATKBRDE'
    },
    {
      status: 210,
      sku: 'PM0047606',
      core_name: 'Plant',
      sppl_ean: '8720349437191',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953551',
      rtl_batch_array: '5953551',
      name_search: 'DESUTHER',
      name: 'Delosperma sutherlandii',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1636,
      chnn_stock_retail: 1636,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '53Z4DDR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53Z4DDR6'
    },
    {
      status: 910,
      sku: 'PM0078568',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310267',
      rtl_batch_array: '6310267',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 4.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'ZJL55H2W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJL55H2W'
    },
    {
      status: 210,
      sku: 'PM0085336',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373390',
      rtl_batch_array: '6373390',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 7.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: '2PDRV8CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PDRV8CR'
    },
    {
      status: 210,
      sku: 'PM0085337',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373391',
      rtl_batch_array: '6373391',
      name_search: 'BETHUNBE',
      name: 'Berberis thunbergii',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 899,
      chnn_stock_retail: 899,
      sppl_prcp: 5.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VR4D883C;JYT62D7E;YXAHYVN7;J5B3L6ZR;STRL4G62;8NNCVVSS;Z5J8YP2K;WZ3RLL1A;9A3RJR1S;CWCB45VD',
      statusContent: 'dot',
      hash: '8PZXP215',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PZXP215'
    },
    {
      status: 210,
      sku: 'PM0085338',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373392',
      rtl_batch_array: '6373392',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '060100C5',
      rng_range_identifier: 'H060100C5',
      rng_range_description: 'H60 cm 100 cm C5',
      sppl_stock_available: 783,
      chnn_stock_retail: 783,
      sppl_prcp: 4.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '6TBPDS6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TBPDS6B'
    },
    {
      status: 210,
      sku: 'PM0078571',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310272',
      rtl_batch_array: '6310272',
      name_search: 'EUFRADIC',
      name: 'Euonymus fortunei radicans',
      sppl_size_code: '020030C14',
      rng_range_identifier: 'H020030C1.5',
      rng_range_description: 'H20 cm 30 cm C1.5',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZ8WJD5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ8WJD5W'
    },
    {
      status: 210,
      sku: 'PM0081830',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348804',
      rtl_batch_array: '6348804',
      name_search: 'HYMSNOWB',
      name: "Hydrangea m. 'Snowball'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D9JATKTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9JATKTW'
    },
    {
      status: 210,
      sku: 'PM0085339',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373393',
      rtl_batch_array: '6373393',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'VPEBKCNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPEBKCNC'
    },
    {
      status: 910,
      sku: 'PM0065472',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLEMARKH',
      name: "Clematis 'Ernest Markham'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'H911WLZN;YKWJRTHC;VP7VBEJY;NP6G7DBX;TJY1HE3W;6JYKVED4;VVSJ3C7G',
      statusContent: 'dot',
      hash: 'DA37RP4Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DA37RP4Y'
    },
    {
      status: 210,
      sku: 'PM0078572',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310273',
      rtl_batch_array: '6315179, 6310273',
      name_search: 'EUFTUSTI',
      name: "Euonymus fortunei 'Tustin'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3100,
      chnn_stock_retail: 6214,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '645R4TB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '645R4TB9'
    },
    {
      status: 910,
      sku: 'PM0078573',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      rng_range_identifier: 'H100110C2',
      rng_range_description: 'H100 cm 110 cm C2',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'CPEDT45P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CPEDT45P'
    },
    {
      status: 910,
      sku: 'PM0065477',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLEMALIC',
      name: "Clematis 'Etoile de Malicorne'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'J3X8RGXE',
      statusContent: 'dot',
      hash: 'X4LB1DKK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X4LB1DKK'
    },
    {
      status: 910,
      sku: 'PM0065473',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLTTANGU',
      name: 'Clematis tibetana tangutica',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'V78AZ85R;C43CDZND;9D5CNZTY;YG4155K1;B2H6WENR',
      statusContent: 'dot',
      hash: 'P8CWN3BS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P8CWN3BS'
    },
    {
      status: 210,
      sku: 'PM0081831',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348805',
      rtl_batch_array: '6348805',
      name_search: 'HYMSNOWB',
      name: "Hydrangea m. 'Snowball'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 5.469,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T95V1GGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T95V1GGD'
    },
    {
      status: 210,
      sku: 'PM0081832',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348807',
      rtl_batch_array: '6348807',
      name_search: 'HYDRPBLA',
      name: 'Hydrangea paniculata Baby Lace',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 0.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SKSDWR1K;X13YTXY5;S53LWCSW',
      statusContent: 'dot',
      hash: '61SETH7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61SETH7D'
    },
    {
      status: 810,
      sku: 'PM0078574',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310276',
      rtl_batch_array: '6310276',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 1.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'T5P2469J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5P2469J'
    },
    {
      status: 210,
      sku: 'PM0081833',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348808',
      rtl_batch_array: '6348808',
      name_search: 'HYDRPBLA',
      name: 'Hydrangea paniculata Baby Lace',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 0.36,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SKSDWR1K;X13YTXY5;S53LWCSW',
      statusContent: 'dot',
      hash: '688LW91P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '688LW91P'
    },
    {
      status: 210,
      sku: 'PM0078575',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310277',
      rtl_batch_array: '6310277',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: '8NKBTXCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NKBTXCX'
    },
    {
      status: 210,
      sku: 'PM0068192',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193756',
      rtl_batch_array: '6193756',
      name_search: 'NYSULPHU',
      name: "Nymphaea 'Sulphurea'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YSA9H57N;ZG9NR8CY;SD5RNHJL;XNHCKLPL',
      statusContent: 'dot',
      hash: 'V3ACXYTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3ACXYTG'
    },
    {
      status: 210,
      sku: 'PM0047623',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223368',
      rtl_batch_array: '6223368',
      name_search: 'ECPMAGNU',
      name: "Echinacea purpurea 'Magnus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 17897,
      chnn_stock_retail: 17897,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B5BBAX8;H61X9HC9;VDTJAZV1;AL37J663;8RWVZ719',
      statusContent: 'dot',
      hash: 'SK2NHR5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK2NHR5A'
    },
    {
      status: 810,
      sku: 'PM0065512',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177558',
      rtl_batch_array: '6236078, 6164870, 6177558',
      name_search: 'SARBUMBL',
      name: "Salvia 'Royal Bumble'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 3141,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NVWJS4W9',
      statusContent: 'dot',
      hash: '591AJE1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '591AJE1D'
    },
    {
      status: 210,
      sku: 'PM0074291',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269464',
      rtl_batch_array: '6269464',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'ZG9Z32HN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG9Z32HN'
    },
    {
      status: 910,
      sku: 'PM0065476',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLVITALB',
      name: 'Clematis vitalba',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'VGNLAR8S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VGNLAR8S'
    },
    {
      status: 210,
      sku: 'PM0065489',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6176249',
      rtl_batch_array: '6176249, 6350638, 6164633',
      name_search: 'LEALPINU',
      name: 'Leontopodium alpinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17896,
      chnn_stock_retail: 18848,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4KWS8CHW;8RR8RTN1;9XARNHCX;ABTWGCK7;7SY1LK43;6KRBWAG3;NPVJR9W5;EA3YS9S7;RK1X7V21;XP1RJ97Y;DEHDH85T;P23RA2C8;4ZGPJDN4;1BYNT9LA;KNENK3SS;J412LCA8;NBKPYJLZ;723BRV7H;BVLPPXSY;PY52X8G2;JYZE6AA7',
      statusContent: 'dot',
      hash: 'PEPD154J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEPD154J'
    },
    {
      status: 210,
      sku: 'PM0065467',
      core_name: 'Plant',
      sppl_ean: '8720664865006',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164518',
      rtl_batch_array: '6164518',
      name_search: 'GESAVIEM',
      name: "Geranium sanguineum 'Aviemore'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GWVS2WGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWVS2WGD'
    },
    {
      status: 210,
      sku: 'PM0078576',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320186',
      rtl_batch_array: '6320186, 6310278',
      name_search: 'FRAALNUS',
      name: 'Frangula alnus',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2810,
      chnn_stock_retail: 8713,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W6LZP94;WNG5165T;PVE56JTL;NY6S2ASZ',
      statusContent: 'dot',
      hash: 'EJ79YTT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ79YTT2'
    },
    {
      status: 210,
      sku: 'PM0085340',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373395',
      rtl_batch_array: '6373395',
      name_search: 'COSANGUI',
      name: 'Cornus sanguinea',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 575,
      chnn_stock_retail: 575,
      sppl_prcp: 6.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X4V1AR2G;JATJ5LR2;PEWSNGWB;78CDZ1AA;JVEEB7VA;PGNCTZX2;RWP4CHAX',
      statusContent: 'dot',
      hash: 'H5X9XALL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5X9XALL'
    },
    {
      status: 210,
      sku: 'PM0065485',
      core_name: 'Plant',
      sppl_ean: '8720664878037',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164537',
      rtl_batch_array: '6164537',
      name_search: 'PHNICKY',
      name: "Phlox (P) 'Nicky'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2CKA9CG',
      statusContent: 'dot',
      hash: 'R4PZPEYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4PZPEYD'
    },
    {
      status: 210,
      sku: 'PM0047624',
      core_name: 'Plant',
      sppl_ean: '8720349414826',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953582',
      rtl_batch_array: '5953582',
      name_search: 'ECPSPINK',
      name: "Echinacea purpurea 'Sensation Pink'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2098,
      chnn_stock_retail: 2098,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NH21D5EX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH21D5EX'
    },
    {
      status: 910,
      sku: 'PM0078749',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LYVJOY',
      name: "Lythrum virgatum 'Joy'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '7VLSBBB9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7VLSBBB9'
    },
    {
      status: 210,
      sku: 'PM0078939',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314032',
      rtl_batch_array: '6314032',
      name_search: 'CAVENUS',
      name: "Calycanthus 'Venus'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_prcp: 3.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J68BRLLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J68BRLLV'
    },
    {
      status: 210,
      sku: 'PM0085341',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373397',
      rtl_batch_array: '6373397',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: '030050C24',
      rng_range_identifier: 'H030050C2.5',
      rng_range_description: 'H30 cm 50 cm C2.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'CBDSGK6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBDSGK6P'
    },
    {
      status: 210,
      sku: 'PM0081834',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348813',
      rtl_batch_array: '6348813',
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYVL3TAR;96HC75LG;J1CYKELX;6C5G3SPX;Z3HVWB3X;ZAG3GRCD;DEG14R1N',
      statusContent: 'dot',
      hash: 'H1ZN232V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1ZN232V'
    },
    {
      status: 210,
      sku: 'PM0085342',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373398',
      rtl_batch_array: '6373398',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 1995,
      chnn_stock_retail: 1995,
      sppl_prcp: 7.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: '6T6NB4KC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T6NB4KC'
    },
    {
      status: 210,
      sku: 'PM0068190',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193754',
      rtl_batch_array: '6193754',
      name_search: 'NYMCHROM',
      name: "Nymphaea 'Marliacea Chromatella'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YWAV81WD;82VDAYYE;TVYY9A87;25DCNNSG',
      statusContent: 'dot',
      hash: '5ZAP1HRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZAP1HRH'
    },
    {
      status: 210,
      sku: 'PM0068187',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193751',
      rtl_batch_array: '6193751',
      name_search: 'NYALBA',
      name: 'Nymphaea alba',
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7RTDKZ35;NELGEPT3;K53JCC2Z;VNTXCDLZ',
      statusContent: 'dot',
      hash: 'RB4CD3NH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RB4CD3NH'
    },
    {
      status: 210,
      sku: 'PM0058950',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308790',
      rtl_batch_array: '5896717, 6252577, 6188659, 6308790',
      name_search: 'GALWBUTT',
      name: "Gaura lindheimeri 'Whirling Butterflies'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1330,
      chnn_stock_retail: 5480,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTLZ5ECG;HVJTDHN5;7JGCXCSR;C82KY3XL;RNKR1E1Y;R9VJC266;AT5E56RB;B5JLPNG5;7X8BP9VG;VPD9H31H;Z5XX96DY;E48BGWSX;TG86L5VH;VS26TCVT;LBREGA43',
      statusContent: 'dot',
      hash: 'DLTH31KZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLTH31KZ'
    },
    {
      status: 210,
      sku: 'PM0078941',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314035',
      rtl_batch_array: '6314035',
      name_search: 'LOFRAGRA',
      name: 'Lonicera fragrantissima',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 632,
      chnn_stock_retail: 632,
      sppl_prcp: 2.308,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A4VYZVET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4VYZVET'
    },
    {
      status: 210,
      sku: 'PM0065529',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197032',
      rtl_batch_array: '6197032, 6254792',
      name_search: 'NEGROL',
      name: "Nepeta 'Grol'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 371,
      chnn_stock_retail: 462,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHS9GGXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHS9GGXZ'
    },
    {
      status: 210,
      sku: 'PM0078942',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314037',
      rtl_batch_array: '6314037',
      name_search: 'NADRICHM',
      name: "Nandina domestica 'Richmond'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZJN2BL3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJN2BL3H'
    },
    {
      status: 210,
      sku: 'PM0085343',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373399',
      rtl_batch_array: '6373399',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 413,
      chnn_stock_retail: 413,
      sppl_prcp: 6.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: '4595YHTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4595YHTS'
    },
    {
      status: 210,
      sku: 'PM0081835',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348814',
      rtl_batch_array: '6348814',
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYVL3TAR;96HC75LG;J1CYKELX;6C5G3SPX;Z3HVWB3X;ZAG3GRCD;DEG14R1N',
      statusContent: 'dot',
      hash: 'WRKCR1JZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRKCR1JZ'
    },
    {
      status: 810,
      sku: 'PM0078943',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314038',
      rtl_batch_array: '6314038',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 4.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'JZ781R5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ781R5A'
    },
    {
      status: 910,
      sku: 'PM0078944',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPBELLA',
      name: "Parrotia persica 'Bella'",
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      statusContent: 'dot',
      hash: '9CDTC4R3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9CDTC4R3'
    },
    {
      status: 210,
      sku: 'PM0068183',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193797',
      rtl_batch_array: '6193797',
      name_search: 'LOCARDIN',
      name: 'Lobelia cardinalis',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KL4WB9A7;SD1XA62P;B29CJXT9;843XGP7B;DRBPS37Z',
      statusContent: 'dot',
      hash: 'X1P9YD92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1P9YD92'
    },
    {
      status: 210,
      sku: 'PM0065264',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274840',
      rtl_batch_array: '6274840, 6386212',
      name_search: 'VIOKCHAR',
      name: "Viola odorata 'K\u00f6nigin Charlotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3135,
      chnn_stock_retail: 4635,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HGKZHK9',
      statusContent: 'dot',
      hash: 'ZZS93WS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZS93WS4'
    },
    {
      status: 210,
      sku: 'PM0078940',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314034',
      rtl_batch_array: '6314034',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_prcp: 2.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: 'EGLRXJRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGLRXJRX'
    },
    {
      status: 210,
      sku: 'PM0078945',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314040',
      rtl_batch_array: '6314040',
      name_search: 'PAPVANES',
      name: "Parrotia persica 'Vanessa'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 4.434,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XYJNTGVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYJNTGVB'
    },
    {
      status: 210,
      sku: 'PM0081836',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348815',
      rtl_batch_array: '6348815',
      name_search: 'HYPPASTE',
      name: 'Hydrangea paniculata Pastelgreen',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 0.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XLPJX48Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLPJX48Y'
    },
    {
      status: 210,
      sku: 'PM0068195',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193759',
      rtl_batch_array: '6193759',
      name_search: 'ANCALIFO',
      name: 'Anemopsis californica',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '911YXR6D;7J7ZYYAY;W2LPRCER;2C5L5SY8;2S7S3A2Y',
      statusContent: 'dot',
      hash: '5W23R9NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W23R9NJ'
    },
    {
      status: 810,
      sku: 'PM0065469',
      core_name: 'Plant',
      sppl_ean: '8720664876699',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164520',
      rtl_batch_array: '6164520',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 2.756,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: '256GNXD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '256GNXD8'
    },
    {
      status: 210,
      sku: 'PM0078946',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314041',
      rtl_batch_array: '6314041',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '035040C4',
      rng_range_identifier: 'H035040C4',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 3.378,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'VBSTLGZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBSTLGZN'
    },
    {
      status: 210,
      sku: 'PM0081837',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348816',
      rtl_batch_array: '6356162, 6348816',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'G9399ZJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9399ZJB'
    },
    {
      status: 210,
      sku: 'PM0081838',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348817',
      rtl_batch_array: '6348817',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_prcp: 0.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: 'H7LZB28T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7LZB28T'
    },
    {
      status: 210,
      sku: 'PM0078947',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314042',
      rtl_batch_array: '6314042',
      name_search: 'SARHOOKE',
      name: 'Sarcococca hookeriana',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_order_minimum: 3,
      sppl_prcp: 2.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJPH4DG4;1KJKNPL5',
      statusContent: 'dot',
      hash: 'REKTLLZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REKTLLZY'
    },
    {
      status: 210,
      sku: 'PM0078948',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314043',
      rtl_batch_array: '6314043',
      name_search: 'SAHHUMIL',
      name: 'Sarcococca hookeriana humilis',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_order_minimum: 3,
      sppl_prcp: 2.528,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8129VXKJ;CAEVYGPS;P2B7KXZW;NCL6CE76;21AB2EWV;C2T4155L;VHE3YL9H;XZDDJKXE;B53W7ND6;YKWKZYAY;ZZXP9NHA;GY4LK5GN;HWH2S3DP;T8RNDZNT',
      statusContent: 'dot',
      hash: '8LWB5YK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LWB5YK1'
    },
    {
      status: 210,
      sku: 'PM0081839',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348818',
      rtl_batch_array: '6348818',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_prcp: 0.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: 'KPHP79GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPHP79GK'
    },
    {
      status: 210,
      sku: 'PM0081840',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348820',
      rtl_batch_array: '6348820',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 2.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'EXK451ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXK451ZS'
    },
    {
      status: 210,
      sku: 'PM0081841',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348822',
      rtl_batch_array: '6348822',
      name_search: 'HYPMOJIT',
      name: 'Hydrangea paniculata Mojito',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8XK17ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8XK17ZJ'
    },
    {
      status: 810,
      sku: 'PM0065560',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284504',
      rtl_batch_array: '6284504',
      name_search: 'AGAALBUS',
      name: "Agapanthus africanus 'Albus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAXYWE1K;JGE9ZRJ8;NEX5HJNV;AV14EGZ4;LEV5WRS6',
      statusContent: 'dot',
      hash: '1Z1NB9XH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Z1NB9XH'
    },
    {
      status: 910,
      sku: 'PM0065475',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLJPAWEL',
      name: "Clematis 'Jan Pawel II'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'W9L9V8AS;RS2DARV1;VBY8TKHE;8PJ5ZSZN',
      statusContent: 'dot',
      hash: '5X5GGCR3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5X5GGCR3'
    },
    {
      status: 210,
      sku: 'PM0068193',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193757',
      rtl_batch_array: '6193757',
      name_search: 'ACCALAMU',
      name: 'Acorus calamus',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2TECA5PZ;ZLLZ5B35;7GL6K3WH',
      statusContent: 'dot',
      hash: '133B2X69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '133B2X69'
    },
    {
      status: 210,
      sku: 'PM0071155',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264775',
      rtl_batch_array: '6264775',
      name_search: 'JUPNANA',
      name: "Juniperus procumbens 'Nana'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 5684,
      chnn_stock_retail: 5684,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YPH14G7X;KRS7HGXY;V2B488EW;LA1AHTSX;G2D5PA79;DGYVNPD5;KB81N2R4;T1HLSSNG;1YXCNZW9;5B55H4XV;1JRJ3A43',
      statusContent: 'dot',
      hash: 'L6ECWEJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6ECWEJD'
    },
    {
      status: 210,
      sku: 'PM0081842',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348823',
      rtl_batch_array: '6348823',
      name_search: 'HYPMOJIT',
      name: 'Hydrangea paniculata Mojito',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HAZZV4JK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAZZV4JK'
    },
    {
      status: 210,
      sku: 'PM0068191',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193755',
      rtl_batch_array: '6193755',
      name_search: 'NYPALBA',
      name: "Nymphaea 'Pygmaea Alba'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19LT1WZK',
      statusContent: 'dot',
      hash: 'R4V1W3CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4V1W3CW'
    },
    {
      status: 210,
      sku: 'PM0047803',
      core_name: 'Plant',
      sppl_ean: '8720664687158',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6009933',
      rtl_batch_array: '6140818, 6009933',
      name_search: 'DEGALAHA',
      name: "Delphinium (P) 'Galahad'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2713,
      chnn_stock_retail: 3713,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2H9ST199;Z4Y6J6YT;YPH9Y82H;RXX5YV67;9TDBPBKW;C1P7NX6T',
      statusContent: 'dot',
      hash: 'G2LK6L1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2LK6L1E'
    },
    {
      status: 210,
      sku: 'PM0085344',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373400',
      rtl_batch_array: '6373400',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '200250C74',
      rng_range_identifier: 'H200250C7.5',
      rng_range_description: 'H200 cm 250 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 11.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '5EWWVKXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EWWVKXX'
    },
    {
      status: 210,
      sku: 'PM0065511',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 5,
      btch_active_retail: '6197039',
      rtl_batch_array: '6197039, 6234468, 6266264, 6164869, 6258904',
      name_search: 'SAMHLIPS',
      name: "Salvia microphylla 'Hot Lips'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1287,
      chnn_stock_retail: 5584,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1Z23AJEH;AD1PCVB1;BAKA6Y3N;132W86X5;12KNZZ51;YSZKHDCR;DL23CSAX;NZ2W7SZZ;JVRJY9G3;9TVVH5DN;NEVVDG32;R2VCAAVS;6EVREY43;S5ZLPZZJ;J195DWNG;HP554TCE;2E5VSSZH;L3LDB5YX;HKTN4BZB;YTBDHANV;J8SCBA95;5J5DJWW9;TBHTCSX4;TGK6PH6S;JZS8B232;C7ACSS26;DP52WTHW;E19CE7PZ;KW5K1PV4;8ZR9VXZ6',
      statusContent: 'dot',
      hash: 'JCT3K5JW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCT3K5JW'
    },
    {
      status: 210,
      sku: 'PM0085345',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373402',
      rtl_batch_array: '6373402',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '17Z9ZZ9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17Z9ZZ9C'
    },
    {
      status: 210,
      sku: 'PM0071156',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228373',
      rtl_batch_array: '6228373',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_prcp: 2.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: 'R3EXWBNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3EXWBNP'
    },
    {
      status: 210,
      sku: 'PM0078570',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310271',
      rtl_batch_array: '6310271',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 1425,
      chnn_stock_retail: 1425,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'P32XX79A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P32XX79A'
    },
    {
      status: 210,
      sku: 'PM0078569',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310269',
      rtl_batch_array: '6310269',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'JEAPSY55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEAPSY55'
    },
    {
      status: 210,
      sku: 'PM0085346',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373403',
      rtl_batch_array: '6373403',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '100125C5GEB',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.09,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'BPGV3SGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPGV3SGD'
    },
    {
      status: 210,
      sku: 'PM0071154',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228371',
      rtl_batch_array: '6228371',
      name_search: 'JUCGCARP',
      name: "Juniperus communis 'Green Carpet'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_prcp: 2.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8JCWSDK;ZR2S66Y8;8LENK36Y;PCPJKSAW;AR8SW9TC;R52VR1LR;PHC1CE39;TKSV3RR5;Z65N4W5A;SHYT7DT2;DEB813ZV;X12G2J1L;Y8AGGRKY;2GX8TKBR;E2GBBLY7;SXP5663N;1BR9CVW8;7N3R494Y;E1BY7K57',
      statusContent: 'dot',
      hash: 'B3C54S9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3C54S9X'
    },
    {
      status: 210,
      sku: 'PM0071158',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228375',
      rtl_batch_array: '6228375',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 366,
      chnn_stock_retail: 366,
      sppl_prcp: 3.248,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'N5KX9HEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5KX9HEG'
    },
    {
      status: 210,
      sku: 'PM0085347',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373404',
      rtl_batch_array: '6373404',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '125150C5GEB',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'DPW3VLW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPW3VLW1'
    },
    {
      status: 210,
      sku: 'PM0058522',
      core_name: 'Plant',
      sppl_ean: '8720664878136',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6112879',
      rtl_batch_array: '6112879, 6301563, 6333295',
      name_search: 'PHTENOR',
      name: "Phlox (P) 'Tenor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 574,
      chnn_stock_retail: 1396,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YNS9L4NH;YWZXG4A2;6XA6HBE5',
      statusContent: 'dot',
      hash: '7EET7WBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7EET7WBG'
    },
    {
      status: 210,
      sku: 'PM0068186',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193750',
      rtl_batch_array: '6193750',
      name_search: 'NYMCARNE',
      name: "Nymphaea 'Marliacea Carnea'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1LSTD89R;47JKG5PW;AAWJAGA3;7WZ3JVYP;GPR46HTG',
      statusContent: 'dot',
      hash: 'C82BXZDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C82BXZDJ'
    },
    {
      status: 210,
      sku: 'PM0085348',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373405',
      rtl_batch_array: '6373405',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'XAHBTEYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAHBTEYL'
    },
    {
      status: 210,
      sku: 'PM0085349',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373407',
      rtl_batch_array: '6373407',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '6YZVZ987',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YZVZ987'
    },
    {
      status: 210,
      sku: 'PM0085350',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373408',
      rtl_batch_array: '6373408',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.26,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'N33Z4ZBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N33Z4ZBB'
    },
    {
      status: 210,
      sku: 'PM0068188',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193752',
      rtl_batch_array: '6193752',
      name_search: 'NYGONNER',
      name: "Nymphaea 'Gonn\u00e8re'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W7DCDR6;VHBLVT49;A453ZKXE;V5TJ5CDG',
      statusContent: 'dot',
      hash: 'CHW56VE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHW56VE4'
    },
    {
      status: 210,
      sku: 'PM0065520',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 4,
      btch_active_retail: '6338370',
      rtl_batch_array: '6164888, 6294853, 6338370, 6348842',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 369,
      chnn_stock_retail: 1493,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: '9VX9D1NH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VX9D1NH'
    },
    {
      status: 210,
      sku: 'PM0063644',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350284',
      rtl_batch_array: '6350284, 6348331',
      name_search: 'HEDPRACH',
      name: "Helenium 'Dunkle Pracht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 232,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GHWXR8G7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHWXR8G7'
    },
    {
      status: 210,
      sku: 'PM0085351',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373409',
      rtl_batch_array: '6373409',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'KT1VL195',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KT1VL195'
    },
    {
      status: 210,
      sku: 'PM0065501',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268829',
      rtl_batch_array: '6268829, 6291743',
      name_search: 'VEVROSEU',
      name: "Veronicastrum virginicum 'Roseum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 642,
      chnn_stock_retail: 1288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8YE1CPBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YE1CPBC'
    },
    {
      status: 910,
      sku: 'PM0065479',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLGCREAM',
      name: "Clematis 'Guernsey Cream'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'SE4Z71S5;2NVVBVG3;RAWLVJ7D;Z1RYE88L;GV7X9AP8',
      statusContent: 'dot',
      hash: 'NX7PLLEJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NX7PLLEJ'
    },
    {
      status: 210,
      sku: 'PM0065509',
      core_name: 'Plant',
      sppl_ean: '8720664871847',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164865',
      rtl_batch_array: '6164865',
      name_search: 'LAMARITI',
      name: 'Lavatera maritima',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 411,
      chnn_stock_retail: 411,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2KRADW14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KRADW14'
    },
    {
      status: 210,
      sku: 'PM0065527',
      core_name: 'Plant',
      sppl_ean: '8720664887008',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164899',
      rtl_batch_array: '6164899',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'NW3JG98J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW3JG98J'
    },
    {
      status: 910,
      sku: 'PM0064981',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188315',
      rtl_batch_array: '6188315',
      name_search: 'WISALBA',
      name: "Wisteria sinensis 'Alba'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_order_minimum: 3,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5C98R9CR;1XASNEWH;AZ21HDHY;TCX6T96W;TG5VBKPH;J6KXJSER;AJ6JGEJE;A6J9ETBZ;PLC4N6SS;GRLXX496',
      statusContent: 'dot',
      hash: '9RWTT44D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RWTT44D'
    },
    {
      status: 210,
      sku: 'PM0071163',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228380',
      rtl_batch_array: '6228380, 6253852',
      name_search: 'THORHEIN',
      name: "Thuja occidentalis 'Rheingold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 138,
      chnn_stock_retail: 852,
      sppl_prcp: 2.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9TAJP9E;YNXYJ8XZ;AS3WYCP5;T1XR11AY;N1ZTRDCB;AT66C11P;CWV558BC;VBGSR2HX;4VPNJC61;SCA5S3YH;LLVLRVNY;HE2STS63;2GSYA9H7;8Y2JLYNX;W66WGT36;DCK5A2XH',
      statusContent: 'dot',
      hash: 'RT98Z6Z8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RT98Z6Z8'
    },
    {
      status: 210,
      sku: 'PM0071164',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228381',
      rtl_batch_array: '6228381',
      name_search: 'THOANANA',
      name: "Thuja occidentalis 'Aurea Nana'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_prcp: 2.751,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPT3LGC',
      statusContent: 'dot',
      hash: '1RYGA52W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RYGA52W'
    },
    {
      status: 210,
      sku: 'PM0085352',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373410',
      rtl_batch_array: '6373410',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: '9R471HJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9R471HJR'
    },
    {
      status: 210,
      sku: 'PM0085353',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373411',
      rtl_batch_array: '6373411',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060080C34',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '4VTXRLEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VTXRLEW'
    },
    {
      status: 210,
      sku: 'PM0085354',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373413',
      rtl_batch_array: '6373413',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 1298,
      chnn_stock_retail: 1298,
      sppl_prcp: 11.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'VBGGTBNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBGGTBNC'
    },
    {
      status: 210,
      sku: 'PM0085355',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373414',
      rtl_batch_array: '6373414',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'HAVS71YL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAVS71YL'
    },
    {
      status: 210,
      sku: 'PM0071160',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228377',
      rtl_batch_array: '6228377',
      name_search: 'PIORIENT',
      name: 'Picea orientalis',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1364,
      chnn_stock_retail: 1364,
      sppl_prcp: 2.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W62JEC4D;H6YCD3C9;H8GGD4VR',
      statusContent: 'dot',
      hash: 'PJRAN7PZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJRAN7PZ'
    },
    {
      status: 210,
      sku: 'PM0048237',
      core_name: 'Plant',
      sppl_ean: '8720664859302',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147613',
      rtl_batch_array: '6147613',
      name_search: 'DESSENSA',
      name: "Delphinium 'Sweet Sensation'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 161,
      chnn_stock_retail: 161,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPDKDHWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPDKDHWR'
    },
    {
      status: 810,
      sku: 'PM0071153',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228370',
      rtl_batch_array: '6228370',
      name_search: 'JUCCOMPR',
      name: "Juniperus communis 'Compressa'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_order_minimum: 5,
      sppl_prcp: 2.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WX1GRV22;H2G3GS33',
      statusContent: 'dot',
      hash: 'VVNZVR4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVNZVR4K'
    },
    {
      status: 210,
      sku: 'PM0068182',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193791',
      rtl_batch_array: '6193791',
      name_search: 'IRLRQUEE',
      name: "Iris laevigata 'Rose Queen'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8SN3YTWN;8RWD2W2R',
      statusContent: 'dot',
      hash: '9JH1NGN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JH1NGN4'
    },
    {
      status: 810,
      sku: 'PM0065470',
      core_name: 'Plant',
      sppl_ean: '8720664890206',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164521',
      rtl_batch_array: '6164521',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 3.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: '9W9KAYGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9W9KAYGG'
    },
    {
      status: 910,
      sku: 'PM0063634',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DECJDRUB',
      name: "Delosperma cooperi 'Jewel of Desert Ruby'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'GTL3XW67',
      statusContent: 'dot',
      hash: 'RP6HWWYK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RP6HWWYK'
    },
    {
      status: 210,
      sku: 'PM0057593',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219500',
      rtl_batch_array: '6219500',
      name_search: 'SESBRILL',
      name: "Sedum spectabile 'Brillant'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1704,
      chnn_stock_retail: 1704,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46ANWG7S;GYTXK7C7;NP85B2AP;HE168LG8;SJ6YV7TS;E15EZ8WH;EZGLE5H1;8EC89EJN;JEG8AES9;6THWDC97;C1L2V8W7',
      statusContent: 'dot',
      hash: 'L5BVRJND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5BVRJND'
    },
    {
      status: 210,
      sku: 'PM0065482',
      core_name: 'Plant',
      sppl_ean: '8720664864528',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164534',
      rtl_batch_array: '6164534',
      name_search: 'GECSOLA',
      name: "Geranium cantabrigiense 'St Ola'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4NEZK75',
      statusContent: 'dot',
      hash: '632HCLTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '632HCLTT'
    },
    {
      status: 210,
      sku: 'PM0071157',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267743',
      rtl_batch_array: '6228374, 6267743',
      name_search: 'JUSBSTAR',
      name: "Juniperus squamata 'Blue Star'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 785,
      chnn_stock_retail: 952,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '223S71E6;YL28ZCAS;BESLT261;C5DRTS5J;JR1P944L;27XBARC5;KE55BKLZ;CYYDK5J8;TP7NVDP8;VJA9BAZY;68BCEN7R;5KJ2YNCK;XX713EV4;4717E53N;LXXWRJ96;SDP1WS9C;2KN4YKZ4;TYC557EP;882YW612;A8PLDHJH;LYT7PRN9;8WHJ75GB;YEL79DVY;XHVVVA2E',
      statusContent: 'dot',
      hash: 'YLPRHZN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLPRHZN8'
    },
    {
      status: 210,
      sku: 'PM0071161',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228378',
      rtl_batch_array: '6228378',
      name_search: 'THODANIC',
      name: "Thuja occidentalis 'Danica'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 386,
      chnn_stock_retail: 386,
      sppl_prcp: 2.209,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8BKY3T6;XJHVS4RW;X1HJK86R;ALA6PH9S;RNK6445D;P4VJXZ64;CLH1AKYW;ND2ZDWH4;D2CKJAEB;P29431BK;CLPLYN3C;8XDHEV62;9RJ5XTBB;A1BBTE4T',
      statusContent: 'dot',
      hash: 'CSJPTDLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSJPTDLS'
    },
    {
      status: 210,
      sku: 'PM0069445',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242149',
      rtl_batch_array: '6242149',
      name_search: 'GAELWESI',
      name: 'Galanthus elwesii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P9V99151;VYNPG5VZ;BT45693R;8Y5LR8BS;84AZJRZ4;R98D7YAG;V1YA41D8;L8NC9AP8;9VV8ZN53;PWH6XXA1;CR1CK3YP;HTHY9HNL;HCDY6BCL;K3N1J8G9;S9S12D5Z',
      statusContent: 'dot',
      hash: 'TH675RG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH675RG6'
    },
    {
      status: 210,
      sku: 'PM0065518',
      core_name: 'Plant',
      sppl_ean: '8720664872615',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164886',
      rtl_batch_array: '6164886',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '245S47XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '245S47XW'
    },
    {
      status: 210,
      sku: 'PM0068185',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193749',
      rtl_batch_array: '6193749',
      name_search: 'NULUTEA',
      name: 'Nuphar lutea',
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VBN18EY;KE5EYEPJ',
      statusContent: 'dot',
      hash: 'GRBPPT47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRBPPT47'
    },
    {
      status: 210,
      sku: 'PM0065510',
      core_name: 'Plant',
      sppl_ean: '8720664874152',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164866',
      rtl_batch_array: '6164866',
      name_search: 'MASPBLUE',
      name: "Malva sylvestris 'Primley Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPGJVSVR',
      statusContent: 'dot',
      hash: 'HB69NN8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB69NN8G'
    },
    {
      status: 210,
      sku: 'PM0068189',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193753',
      rtl_batch_array: '6193753',
      name_search: 'NYJTOMOC',
      name: "Nymphaea 'Joey Tomocik'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA5ENAGB;LEE38PYV;EVRZCKJS;HXBKLRWG;X38737TV;1LC38ZRW',
      statusContent: 'dot',
      hash: 'KVESTRZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVESTRZA'
    },
    {
      status: 810,
      sku: 'PM0065521',
      core_name: 'Plant',
      sppl_ean: '8720664878952',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164891',
      rtl_batch_array: '6164891',
      name_search: 'PHOSWINE',
      name: 'Physocarpus opulifolius Summer Wine',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 9.911,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJBWSWTW;PAZLGJK7;WAP8SBGR;PYSTNZDL;CB5NHEEY;EYCGXX44',
      statusContent: 'dot',
      hash: 'NA95CNL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NA95CNL3'
    },
    {
      status: 210,
      sku: 'PM0065471',
      core_name: 'Plant',
      sppl_ean: '8720664876163',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164522',
      rtl_batch_array: '6164522',
      name_search: 'PALDREAM',
      name: "Paeonia (I) 'Lemon Dream'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKJZK8A8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKJZK8A8'
    },
    {
      status: 210,
      sku: 'PM0058571',
      core_name: 'Plant',
      sppl_ean: '8720664853058',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6113100',
      rtl_batch_array: '6230491, 6113100, 6164374, 6305154',
      name_search: 'ASSPRITE',
      name: "Astilbe (S) 'Sprite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2271,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TNKKXZC5;VCD2ZEYW',
      statusContent: 'dot',
      hash: 'CSS41BXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSS41BXN'
    },
    {
      status: 210,
      sku: 'PM0081843',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348824',
      rtl_batch_array: '6348824',
      name_search: 'HYPMOJIT',
      name: 'Hydrangea paniculata Mojito',
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KGDTHZW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGDTHZW3'
    },
    {
      status: 210,
      sku: 'PM0065618',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6373853',
      rtl_batch_array: '6300184, 6373853, 6355669',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 9145,
      sppl_prcp: 1.274,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'HPR75ZG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPR75ZG2'
    },
    {
      status: 210,
      sku: 'PM0068209',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193779',
      rtl_batch_array: '6193779',
      name_search: 'EQSCIRPO',
      name: 'Equisetum scirpoides',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCY5X1Z1;73WWG99W;5NLGT927;XKK6BKLP;AL713D5B;LLJVD728;TCE4A5WP',
      statusContent: 'dot',
      hash: 'BVKTP7LW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVKTP7LW'
    },
    {
      status: 210,
      sku: 'PM0085356',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373416',
      rtl_batch_array: '6373416',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'VGT4GL7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGT4GL7J'
    },
    {
      status: 210,
      sku: 'PM0078579',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310282',
      rtl_batch_array: '6310282',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'PWN4KCHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWN4KCHY'
    },
    {
      status: 210,
      sku: 'PM0078577',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310279',
      rtl_batch_array: '6310279',
      name_search: 'FRAALNUS',
      name: 'Frangula alnus',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_prcp: 4.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W6LZP94;WNG5165T;PVE56JTL;NY6S2ASZ',
      statusContent: 'dot',
      hash: 'T7H2VZJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7H2VZJH'
    },
    {
      status: 910,
      sku: 'PM0078578',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'XLKT1HSC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XLKT1HSC'
    },
    {
      status: 810,
      sku: 'PM0065282',
      core_name: 'Plant',
      sppl_ean: '8720664882317',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162500',
      rtl_batch_array: '6162500',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L532J1SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L532J1SL'
    },
    {
      status: 210,
      sku: 'PM0071167',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228384',
      rtl_batch_array: '6228384',
      name_search: 'RHAMOENU',
      name: "Rhododendron (AJ) 'Amoenum'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VRK6BYP;RYB5WASD;GV7Z6WAC',
      statusContent: 'dot',
      hash: 'V334AE7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V334AE7A'
    },
    {
      status: 210,
      sku: 'PM0057867',
      core_name: 'Plant',
      sppl_ean: '8720664861404',
      btch_manufacturer: 612,
      rtl_batch_array_total: 2,
      btch_active_retail: '6099388',
      rtl_batch_array: '6099388, 6307409',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 487,
      chnn_stock_retail: 685,
      sppl_prcp: 5.314,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '4VK8RY9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VK8RY9Z'
    },
    {
      status: 210,
      sku: 'PM0078582',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310285',
      rtl_batch_array: '6310285',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3400,
      chnn_stock_retail: 3400,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'X1751166',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1751166'
    },
    {
      status: 210,
      sku: 'PM0078583',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310286',
      rtl_batch_array: '6310286',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 122,
      chnn_stock_retail: 122,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'JRA4E2N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRA4E2N8'
    },
    {
      status: 210,
      sku: 'PM0078584',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310287',
      rtl_batch_array: '6310287',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 2487,
      chnn_stock_retail: 2487,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'RJJ8LJDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJJ8LJDZ'
    },
    {
      status: 210,
      sku: 'PM0085357',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373419',
      rtl_batch_array: '6373419',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 2494,
      chnn_stock_retail: 2494,
      sppl_prcp: 6.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'J329JVXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J329JVXG'
    },
    {
      status: 210,
      sku: 'PM0078585',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310288',
      rtl_batch_array: '6310288',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 5207,
      chnn_stock_retail: 5207,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TGARH2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TGARH2D'
    },
    {
      status: 210,
      sku: 'PM0085358',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373421',
      rtl_batch_array: '6373421',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '040060C34',
      rng_range_identifier: 'H040060C3.5',
      rng_range_description: 'H40 cm 60 cm C3.5',
      sppl_stock_available: 398,
      chnn_stock_retail: 398,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'EE1TETGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE1TETGT'
    },
    {
      status: 210,
      sku: 'PM0078586',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310289',
      rtl_batch_array: '6310289',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 4470,
      chnn_stock_retail: 4470,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'B59KZPVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B59KZPVB'
    },
    {
      status: 210,
      sku: 'PM0085359',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373422',
      rtl_batch_array: '6373422',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '060080C34',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 4.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '57XDLY6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57XDLY6W'
    },
    {
      status: 210,
      sku: 'PM0085360',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373423',
      rtl_batch_array: '6373423',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'GKGXVTZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKGXVTZ4'
    },
    {
      status: 210,
      sku: 'PM0085361',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373528',
      rtl_batch_array: '6373528',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 3500,
      chnn_stock_retail: 3500,
      sppl_prcp: 1.43,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'WX6CAPLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WX6CAPLX'
    },
    {
      status: 210,
      sku: 'PM0081844',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6184054',
      rtl_batch_array: '6184054, 6348825',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4000,
      sppl_prcp: 2.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'GR8K2P7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GR8K2P7W'
    },
    {
      status: 210,
      sku: 'PM0081845',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348827',
      rtl_batch_array: '6348827',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 5,
      sppl_prcp: 2.845,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'ASXLNKA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASXLNKA6'
    },
    {
      status: 910,
      sku: 'PM0085362',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RHKAZUKO',
      name: "Rhododendron (AJ) 'Kazuko'",
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      statusContent: 'dot',
      hash: '8S7HSL9C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8S7HSL9C'
    },
    {
      status: 910,
      sku: 'PM0085363',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: '2J7TE8HG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2J7TE8HG'
    },
    {
      status: 810,
      sku: 'PM0078580',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310283',
      rtl_batch_array: '6310283',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '7PCC1THL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PCC1THL'
    },
    {
      status: 210,
      sku: 'PM0081846',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348828',
      rtl_batch_array: '6348828',
      name_search: 'HYQALICE',
      name: "Hydrangea quercifolia 'Alice'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 0.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9766V5DT;XJ1NN636',
      statusContent: 'dot',
      hash: 'KJRT1D7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJRT1D7R'
    },
    {
      status: 910,
      sku: 'PM0085364',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HOFRANCE',
      name: "Hosta 'Francee'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '6L39X4TT;Z754WEC7;Y2T6XDTY;BZBYGL5S;PL98W7D4;HKKD7H8P;2LHRSZVD;XXJ99PE5;TVE71W4V;975DX8X9;PLN4TXN8',
      statusContent: 'dot',
      hash: 'G6J6J8DD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G6J6J8DD'
    },
    {
      status: 210,
      sku: 'PM0085365',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373585',
      rtl_batch_array: '6373585',
      name_search: 'MISSDWAR',
      name: "Miscanthus sinensis 'Strictus Dwarf'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 2.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D1NXKYVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1NXKYVD'
    },
    {
      status: 910,
      sku: 'PM0085366',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEOFTAIL',
      name: "Pennisetum orientale 'Fairy Tails'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '4AJL1YLS;4WDJXT26',
      statusContent: 'dot',
      hash: 'S66H8KL5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S66H8KL5'
    },
    {
      status: 210,
      sku: 'PM0085367',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373810',
      rtl_batch_array: '6373810',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: '2ZB9JTPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZB9JTPH'
    },
    {
      status: 210,
      sku: 'PM0078590',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310293',
      rtl_batch_array: '6310293',
      name_search: 'HYKGEMO',
      name: "Hypericum kalmianum 'Gemo'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '95YDVR17;DP12YKSA',
      statusContent: 'dot',
      hash: 'SET5EDKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SET5EDKS'
    },
    {
      status: 210,
      sku: 'PM0085368',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373811',
      rtl_batch_array: '6373811',
      name_search: 'ACPWPDWA',
      name: "Acer palmatum 'Wilson's Pink Dwarf'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTH4GLX3',
      statusContent: 'dot',
      hash: '9DXJZ3W6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DXJZ3W6'
    },
    {
      status: 210,
      sku: 'PM0078591',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310294',
      rtl_batch_array: '6310294',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'ECBE9DB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECBE9DB2'
    },
    {
      status: 210,
      sku: 'PM0057913',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '6216282',
      rtl_batch_array: '6101302, 6216282',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1050,
      sppl_prcp: 7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '1K961HE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K961HE4'
    },
    {
      status: 210,
      sku: 'PM0078592',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310295',
      rtl_batch_array: '6310295',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'N9YB7AKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9YB7AKD'
    },
    {
      status: 210,
      sku: 'PM0057858',
      core_name: 'Plant',
      sppl_ean: '8720664858176',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099139',
      rtl_batch_array: '6099139',
      name_search: 'COCANADE',
      name: 'Cornus canadensis',
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_order_minimum: 3,
      sppl_prcp: 2.423,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DA1NWV7N;P9NKX3RS',
      statusContent: 'dot',
      hash: 'P6DYTK2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6DYTK2T'
    },
    {
      status: 210,
      sku: 'PM0069467',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301355',
      rtl_batch_array: '6301355, 6220741',
      name_search: 'GEBBLOOD',
      name: "Geranium 'Blue Blood'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 417,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XPST3V7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPST3V7E'
    },
    {
      status: 210,
      sku: 'PM0078593',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310296',
      rtl_batch_array: '6310296',
      name_search: 'HYPMTRIC',
      name: "Hypericum moserianum 'Tricolor'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 3600,
      chnn_stock_retail: 3600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XJXT9L93;B39K2H5X;X42CRZK5;236CJRZ5;CJ5D2ZP2;CSEE1Z5X;877EHJ5N;75JAZ3E6',
      statusContent: 'dot',
      hash: '6SYSBGZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SYSBGZY'
    },
    {
      status: 210,
      sku: 'PM0071166',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228383',
      rtl_batch_array: '6228383',
      name_search: 'ABDROSEU',
      name: "Abeliophyllum distichum 'Roseum'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_order_minimum: 3,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ESC5X55',
      statusContent: 'dot',
      hash: 'PPXLNZB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPXLNZB5'
    },
    {
      status: 210,
      sku: 'PM0081847',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348829',
      rtl_batch_array: '6348829',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.845,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: 'KZNHW6S7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZNHW6S7'
    },
    {
      status: 210,
      sku: 'PM0054480',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238448',
      rtl_batch_array: '6238448',
      name_search: 'CEMGRAND',
      name: "Centaurea montana 'Grandiflora'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7255,
      chnn_stock_retail: 7255,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29RSBGTJ;RYDLWBET;PJ1JCWC4;XSZ87KZT;X3CDDK9T;NHHWLGW3;BYB6ADSE;B1L2DB6L;PCVP5S68;P4RNXZ9H;H8LY2LJL;X5NVKB8A;Y6VSR6PK;8PTDCSLY;LDW1J19N;CY8636ER;4C7DP43D;PVVWJX12;NEJV2CX8;ZHA7NTDC;LR2ZWWAJ;ZNXS8L51;DSA218GE',
      statusContent: 'dot',
      hash: '6JD977L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JD977L6'
    },
    {
      status: 210,
      sku: 'PM0069460',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301594',
      rtl_batch_array: '6301594',
      name_search: 'GEHPLENU',
      name: "Geranium himalayense 'Plenum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VBS3BEG;ATA2BKZT',
      statusContent: 'dot',
      hash: '7D36JS69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D36JS69'
    },
    {
      status: 210,
      sku: 'PM0078595',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310298',
      rtl_batch_array: '6310298',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3600,
      chnn_stock_retail: 3600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'SKZKPZAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKZKPZAK'
    },
    {
      status: 210,
      sku: 'PM0065963',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307838',
      rtl_batch_array: '6307838',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 36.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'JBW71STE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBW71STE'
    },
    {
      status: 210,
      sku: 'PM0078596',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310299',
      rtl_batch_array: '6310299',
      name_search: 'LAANAGYR',
      name: 'Laburnum anagyroides',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 627,
      chnn_stock_retail: 627,
      sppl_prcp: 1.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PRKDYWTP',
      statusContent: 'dot',
      hash: 'WP65PGBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WP65PGBZ'
    },
    {
      status: 810,
      sku: 'PM0069452',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301596',
      rtl_batch_array: '6301596, 6339810',
      name_search: 'GELEA',
      name: "Geranium 'Lea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XL3PESVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL3PESVB'
    },
    {
      status: 210,
      sku: 'PM0065565',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254884',
      rtl_batch_array: '6254884',
      name_search: 'ASQHOLLA',
      name: "Astilbe (J) 'Queen of Holland'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_prcp: 2.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6ZC6X14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6ZC6X14'
    },
    {
      status: 210,
      sku: 'PM0068227',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193800',
      rtl_batch_array: '6193800',
      name_search: 'LYNAUREA',
      name: "Lysimachia nummularia 'Aurea'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8R3Y534R;S57CWHHR;D9P7ETDK;VT4JNJW7',
      statusContent: 'dot',
      hash: 'JP39ZWWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP39ZWWL'
    },
    {
      status: 210,
      sku: 'PM0081848',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356165',
      rtl_batch_array: '6356165, 6348830',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2150,
      sppl_order_minimum: 5,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: '7GRLHCRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GRLHCRZ'
    },
    {
      status: 210,
      sku: 'PM0065583',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254715',
      rtl_batch_array: '6254715',
      name_search: 'ECPBSWHI',
      name: "Echinacea purpurea 'Baby Swan White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VHHTDSLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHHTDSLP'
    },
    {
      status: 210,
      sku: 'PM0065576',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254692',
      rtl_batch_array: '6254692',
      name_search: 'DEMFDRWB',
      name: "Delphinium 'Magic Fountains Deep Rose White Bee'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BZYA8DL2;GPKXNXX9;RYW31PEG',
      statusContent: 'dot',
      hash: 'B9DRBS8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9DRBS8A'
    },
    {
      status: 210,
      sku: 'PM0065567',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254656',
      rtl_batch_array: '6254656',
      name_search: 'ATFFFRIZ',
      name: "Athyrium filix-femina 'Frizelliae'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P22XBSN2',
      statusContent: 'dot',
      hash: '7SNP2DAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SNP2DAT'
    },
    {
      status: 210,
      sku: 'PM0085369',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373812',
      rtl_batch_array: '6373812',
      name_search: 'CAFBLUSH',
      name: "Camellia 'Fairy Blush'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 15.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PTRC1W7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTRC1W7V'
    },
    {
      status: 210,
      sku: 'PM0078581',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310284',
      rtl_batch_array: '6310284',
      name_search: 'HYANDROS',
      name: 'Hypericum androsaemum',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 10737,
      chnn_stock_retail: 10737,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WNHWLXG',
      statusContent: 'dot',
      hash: '27BJAR4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27BJAR4K'
    },
    {
      status: 210,
      sku: 'PM0081849',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348831',
      rtl_batch_array: '6348831',
      name_search: 'LESSHEEN',
      name: "Leptospermum 'Silver Sheen'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BAZXYN7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAZXYN7S'
    },
    {
      status: 910,
      sku: 'PM0065569',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ATNSFALL',
      name: "Athyrium niponicum 'Silver Falls'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'TN2RJXRL;4CS359EG',
      statusContent: 'dot',
      hash: 'E1ZDXXJZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E1ZDXXJZ'
    },
    {
      status: 210,
      sku: 'PM0081850',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348832',
      rtl_batch_array: '6348832',
      name_search: 'LESSHEEN',
      name: "Leptospermum 'Silver Sheen'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.289,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GNSW9NC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNSW9NC4'
    },
    {
      status: 210,
      sku: 'PM0081851',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348833',
      rtl_batch_array: '6348833',
      name_search: 'LIJTEXAN',
      name: "Ligustrum japonicum 'Texanum'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9AL9VBH2;E98GN7ZG;L9E6NTCY;4K4R16Y7;E6K2J92D;PRRAL57L',
      statusContent: 'dot',
      hash: 'L84413CN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L84413CN'
    },
    {
      status: 210,
      sku: 'PM0081852',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348835',
      rtl_batch_array: '6348835',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'JZGZZV3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZGZZV3Z'
    },
    {
      status: 210,
      sku: 'PM0085370',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373813',
      rtl_batch_array: '6373813',
      name_search: 'COCGLADY',
      name: 'Cotinus coggygria Golden Lady',
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61A5SVZS;AD6H87RX;69CBJZKL;G96K7J1T;R2GW45AZ;L9RYVPE6',
      statusContent: 'dot',
      hash: 'JJTJ9KTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJTJ9KTA'
    },
    {
      status: 210,
      sku: 'PM0065568',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254657',
      rtl_batch_array: '6254657',
      name_search: 'ATFFLRED',
      name: "Athyrium filix-femina 'Lady in Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 488,
      chnn_stock_retail: 488,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2VNSAYJ3;GD7EJ79S;SA13JT5V;9WJ94V5L;6K7ZE7E8;KWL21VL9;N2VNRY1B;58T7VDZT;78R8VWT4;Z1CPVNJY;7C3XCTEH;PGYPGA5S;1LXTBHBP',
      statusContent: 'dot',
      hash: 'TZZHC8S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZZHC8S1'
    },
    {
      status: 210,
      sku: 'PM0078750',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353489',
      rtl_batch_array: '6353489',
      name_search: 'VIMVALEN',
      name: "Vinca minor 'Valentin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 272,
      chnn_stock_retail: 272,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B2762WS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2762WS4'
    },
    {
      status: 210,
      sku: 'PM0069453',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350186',
      rtl_batch_array: '6350186',
      name_search: 'GELUTEA',
      name: 'Gentiana lutea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSTS3VJC',
      statusContent: 'dot',
      hash: 'SK38G6P3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK38G6P3'
    },
    {
      status: 210,
      sku: 'PM0081853',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348836',
      rtl_batch_array: '6348836',
      name_search: 'PEASBLUE',
      name: 'Perovskia atriplicifolia Silvery Blue',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y7DJJ3KR;566VV31C;AEP2YG7A;XWE79YTB;N9XEXGX8;XSXCCKLD;Y9GY78RD',
      statusContent: 'dot',
      hash: 'TPE3V52P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPE3V52P'
    },
    {
      status: 810,
      sku: 'PM0057881',
      core_name: 'Plant',
      sppl_ean: '8720664885585',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099403',
      rtl_batch_array: '6099403',
      name_search: 'SCVSTERN',
      name: "Sciadopitys verticillata 'Sternschnuppe'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 19.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1KT2S19G',
      statusContent: 'dot',
      hash: '5EXE2JE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EXE2JE9'
    },
    {
      status: 810,
      sku: 'PM0078751',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353492',
      rtl_batch_array: '6353492',
      name_search: 'VIOCALSA',
      name: "Viola odorata 'Coeur d'Alsace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WV5YRGVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV5YRGVS'
    },
    {
      status: 210,
      sku: 'PM0085371',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373814',
      rtl_batch_array: '6373814',
      name_search: 'EUPBBLUE',
      name: "Eucalyptus pulverulenta 'Baby Blue'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 6.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B333D4J2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B333D4J2'
    },
    {
      status: 210,
      sku: 'PM0078949',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314044',
      rtl_batch_array: '6314044',
      name_search: 'SARUSCIF',
      name: 'Sarcococca ruscifolia',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 293,
      chnn_stock_retail: 293,
      sppl_order_minimum: 3,
      sppl_prcp: 2.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R9JTP1R2;84KEP91T;AXX8GSH7;YP6DSTPT',
      statusContent: 'dot',
      hash: 'ZZ2ZD3AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZ2ZD3AA'
    },
    {
      status: 210,
      sku: 'PM0065587',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252568',
      rtl_batch_array: '2624745, 6252568',
      name_search: 'FEGAUTIE',
      name: 'Festuca gautieri',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 323,
      chnn_stock_retail: 771,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CPWB3TK4;WRBWGCRB;K5KDSBY6;7Z1VRHL3;TY2JSAZY;NPVWV9J8;SD9S95A6;YA6BB8AK;9LWLP1SJ;4X6TJTPZ;DJH79ZW8',
      statusContent: 'dot',
      hash: '1JRNKAKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JRNKAKZ'
    },
    {
      status: 210,
      sku: 'PM0021479',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6192815',
      rtl_batch_array: '6192815, 6254398, 6348356',
      name_search: 'LIMINGWE',
      name: "Liriope muscari 'Ingwersen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 26166,
      chnn_stock_retail: 34448,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12HDS6E;VTEX3J3R;25KXLVN1;KVV6JXRX',
      statusContent: 'dot',
      hash: 'N9KXW4WT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9KXW4WT'
    },
    {
      status: 210,
      sku: 'PM0078588',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310291',
      rtl_batch_array: '6310291',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'AN2LY9J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AN2LY9J7'
    },
    {
      status: 210,
      sku: 'PM0085372',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373815',
      rtl_batch_array: '6373815',
      name_search: 'EUJWSPIR',
      name: "Euonymus japonicus 'White Spire'",
      sppl_size_code: '025030C1',
      rng_range_identifier: 'H025030C1',
      rng_range_description: 'H25 cm 30 cm C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA5HB839;PTNHXAVG;A5EZR9PE;7TBP5XJ1;ZLZ9C4DD;EDPZX8BX',
      statusContent: 'dot',
      hash: 'S28JW7TC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S28JW7TC'
    },
    {
      status: 210,
      sku: 'PM0078587',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320567',
      rtl_batch_array: '6310290, 6320567',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5100,
      sppl_prcp: 1.224,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'R6T15HJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6T15HJE'
    },
    {
      status: 210,
      sku: 'PM0078589',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310292',
      rtl_batch_array: '6310292',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 6500,
      chnn_stock_retail: 6500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'WN8KJ6K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WN8KJ6K5'
    },
    {
      status: 210,
      sku: 'PM0057977',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370392',
      rtl_batch_array: '6370392',
      name_search: 'SAOICTER',
      name: "Salvia officinalis 'Icterina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7XGYKWG;VS8V9RPB;23TYSBRD;HHZHC9YK;PN9YA6WD;837D8XLX;ATZR7T17;PC3ZP11A;N48JL91S;Z6S1NAP2;P363TXLV;AVSRV4HX',
      statusContent: 'dot',
      hash: 'W1LXY6VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1LXY6VJ'
    },
    {
      status: 210,
      sku: 'PM0063663',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382306',
      rtl_batch_array: '6286614, 6242974, 6350750, 6382306',
      name_search: 'PAVSHENA',
      name: "Panicum virgatum 'Shenandoah'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4550,
      chnn_stock_retail: 10677,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5D1Y5Y1;EWAYJRGL;THZVCBYK;XR4S95TL;65B156B5;NNG5RJPG;6A52ZT9L;H6V1JPDC;XE3CWL3V;NDXW85ZB',
      statusContent: 'dot',
      hash: 'R3KXYST9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3KXYST9'
    },
    {
      status: 210,
      sku: 'PM0060041',
      core_name: 'Plant',
      sppl_ean: '8720664852785',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6058232',
      rtl_batch_array: '6058232, 6254883',
      name_search: 'ASFMOENC',
      name: "Aster frikartii 'M\u00f6nch'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1420,
      chnn_stock_retail: 3160,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K1SKNAE4',
      statusContent: 'dot',
      hash: 'N4729NR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4729NR4'
    },
    {
      status: 210,
      sku: 'PM0085373',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373819',
      rtl_batch_array: '6373819',
      name_search: 'MABSADDL',
      name: "Mangave 'Blazing Saddles'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 7.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KLWAHH11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLWAHH11'
    },
    {
      status: 210,
      sku: 'PM0071172',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337719',
      rtl_batch_array: '6337719',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3432,
      chnn_stock_retail: 3432,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'VS6P997W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VS6P997W'
    },
    {
      status: 210,
      sku: 'PM0081854',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348838',
      rtl_batch_array: '6348838',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'GED3T5YW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GED3T5YW'
    },
    {
      status: 210,
      sku: 'PM0085374',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373820',
      rtl_batch_array: '6373820',
      name_search: 'RODEPEND',
      name: 'Rostrinucula dependens',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 6.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S6EBDWH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6EBDWH3'
    },
    {
      status: 210,
      sku: 'PM0057877',
      core_name: 'Plant',
      sppl_ean: '8720664883208',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099399',
      rtl_batch_array: '6099399',
      name_search: 'RHTATJAN',
      name: "Rhododendron (Y) 'Tatjana'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRHYAXNC',
      statusContent: 'dot',
      hash: 'ZHZWGA5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHZWGA5W'
    },
    {
      status: 210,
      sku: 'PM0081855',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348839',
      rtl_batch_array: '6348839',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '175DAAXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '175DAAXT'
    },
    {
      status: 210,
      sku: 'PM0081856',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348841',
      rtl_batch_array: '6348841',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 0.219,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'LYKTSH4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYKTSH4T'
    },
    {
      status: 210,
      sku: 'PM0085375',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373821',
      rtl_batch_array: '6373821',
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 4.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9251DGD;4AK589AB;GT6C1BJL;77RP3J8Y;4ZPJNXK5;RHJJ77R5;1E1112G6;A3KPK1CH;DCNZEK7T;6CZ7HE6H;5EEDE6GG;A535J46V;VERWST57;13ZDCST5;8S57K3RX;TPNXG1TC;LS2CKYLV;REENVR6J',
      statusContent: 'dot',
      hash: 'VPVJD7PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPVJD7PA'
    },
    {
      status: 210,
      sku: 'PM0081857',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348843',
      rtl_batch_array: '6348843',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 0.219,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'C28EDXEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C28EDXEX'
    },
    {
      status: 210,
      sku: 'PM0081858',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348844',
      rtl_batch_array: '6348844',
      name_search: 'RHUMINOR',
      name: "Rhaphiolepis umbellata 'Minor'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 0.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VKLVZEBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKLVZEBN'
    },
    {
      status: 210,
      sku: 'PM0081859',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348845',
      rtl_batch_array: '6348845',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 0.134,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'DX13LA7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX13LA7A'
    },
    {
      status: 210,
      sku: 'PM0081860',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348846',
      rtl_batch_array: '6348846',
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 5,
      sppl_prcp: 3.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9251DGD;4AK589AB;GT6C1BJL;77RP3J8Y;4ZPJNXK5;RHJJ77R5;1E1112G6;A3KPK1CH;DCNZEK7T;6CZ7HE6H;5EEDE6GG;A535J46V;VERWST57;13ZDCST5;8S57K3RX;TPNXG1TC;LS2CKYLV;REENVR6J',
      statusContent: 'dot',
      hash: 'XJAR22Z7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJAR22Z7'
    },
    {
      status: 210,
      sku: 'PM0081861',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348847',
      rtl_batch_array: '6348847',
      name_search: 'SASALIGN',
      name: 'Sarcococca saligna',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B55P3E9B;4CVD4RZJ;9351GWL3',
      statusContent: 'dot',
      hash: 'T52P4TPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T52P4TPY'
    },
    {
      status: 210,
      sku: 'PM0081862',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348849',
      rtl_batch_array: '6348849',
      name_search: 'STABRUNE',
      name: 'Strobilanthes anisophylla Brunetthy',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.229,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6YCLJRS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YCLJRS8'
    },
    {
      status: 210,
      sku: 'PM0085376',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373822',
      rtl_batch_array: '6373822',
      name_search: 'SASALIGN',
      name: 'Sarcococca saligna',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 4.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B55P3E9B;4CVD4RZJ;9351GWL3',
      statusContent: 'dot',
      hash: '23A3H16A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23A3H16A'
    },
    {
      status: 210,
      sku: 'PM0085377',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373823',
      rtl_batch_array: '6373823',
      name_search: 'SARHWGEM',
      name: 'Sarcococca hookeriana Winter Gem',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 4.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7NDHSZ1;JXAK8JH8;PXLB1JYE;C9XNP74L;LYK13S49;23A6D9XW;ST5E6XZ3',
      statusContent: 'dot',
      hash: 'JY18J2TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY18J2TN'
    },
    {
      status: 210,
      sku: 'PM0085378',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373824',
      rtl_batch_array: '6373824',
      name_search: 'VAVIRCAN',
      name: "Vaccinium vitis-idaea 'Red Candy'",
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HHYEHSZ5',
      statusContent: 'dot',
      hash: 'P1LARKEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1LARKEB'
    },
    {
      status: 210,
      sku: 'PM0071168',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228386',
      rtl_batch_array: '6228386',
      name_search: 'RHMOEDER',
      name: "Rhododendron (AJ) 'Moederkensdag'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8XA3234P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XA3234P'
    },
    {
      status: 210,
      sku: 'PM0085379',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373839',
      rtl_batch_array: '6373839',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 4925,
      chnn_stock_retail: 4925,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: '6HC6YC24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HC6YC24'
    },
    {
      status: 210,
      sku: 'PM0085380',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373845',
      rtl_batch_array: '6373845',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 4.165,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'BEE76PD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEE76PD7'
    },
    {
      status: 210,
      sku: 'PM0085381',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373848',
      rtl_batch_array: '6373848',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 6,
      sppl_prcp: 0.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '1NP3RVRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NP3RVRX'
    },
    {
      status: 210,
      sku: 'PM0057874',
      core_name: 'Plant',
      sppl_ean: '8720664883161',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099395',
      rtl_batch_array: '6099395',
      name_search: 'RHFANTAS',
      name: "Rhododendron (Y) 'Fantastica'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JCVCD4WG;C2ENLY4Y;BZGBXXWP;JZ2K5CGV;T9A64HEE;7S2RR971;544BP7JR;YD1AENC2;JKLS2JVN;DNY92W8P;WRTZCW5J;Z57W32NV;XR8526R5;29TKNAX9;NKJL6TA4;H5541NBC;T7J1CXHC;5T3ESDH4;83LVDZ95',
      statusContent: 'dot',
      hash: '7BYCCA6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BYCCA6E'
    },
    {
      status: 210,
      sku: 'PM0085382',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373849',
      rtl_batch_array: '6373849',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 0.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'ABA13NDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ABA13NDC'
    },
    {
      status: 210,
      sku: 'PM0085383',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373850',
      rtl_batch_array: '6373850',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '125150C3',
      rng_range_identifier: 'H125150C3',
      rng_range_description: 'H125 cm 150 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'BYKVA1VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYKVA1VN'
    },
    {
      status: 210,
      sku: 'PM0071171',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228389',
      rtl_batch_array: '6228389',
      name_search: 'RHPERSIL',
      name: "Rhododendron (AK) 'Persil'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 3.444,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EKKHJY7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKKHJY7X'
    },
    {
      status: 210,
      sku: 'PM0058007',
      core_name: 'Plant',
      sppl_ean: '8720664875623',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6084798',
      rtl_batch_array: '6084798, 6202324, 6350720, 6338950',
      name_search: 'OEMACROC',
      name: 'Oenothera macrocarpa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2684,
      chnn_stock_retail: 4634,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7E6DLX3J;839ST729;EKSKP4RA',
      statusContent: 'dot',
      hash: 'G9HVL7DK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9HVL7DK'
    },
    {
      status: 210,
      sku: 'PM0085384',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6373851',
      rtl_batch_array: '6373851',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 2.793,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'G6PN351K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6PN351K'
    },
    {
      status: 210,
      sku: 'PM0085385',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375761',
      rtl_batch_array: '6375761',
      name_search: 'LUCHATEL',
      name: "Lupinus 'The Chatelaine'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CP9VN61;1VRAV4BN',
      statusContent: 'dot',
      hash: 'Y13T35TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y13T35TP'
    },
    {
      status: 210,
      sku: 'PM0085386',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375762',
      rtl_batch_array: '6375762',
      name_search: 'LUCHANDE',
      name: "Lupinus 'Chandelier'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X9EANK5K;PNZ42HR1;C859W72Y;8J38AVCW',
      statusContent: 'dot',
      hash: '7E64KSBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E64KSBK'
    },
    {
      status: 210,
      sku: 'PM0058546',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6315068',
      rtl_batch_array: '6112915, 6315068, 5960157, 6383385',
      name_search: 'SANSCHNE',
      name: "Salvia nemorosa 'Schneeh\u00fcgel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6158,
      chnn_stock_retail: 18200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8R3CD1WS;NR9GEH7B;RK2RJPY3;LW2R47G7;2TPTNYLL;1SEKLWVT;JL46NNHZ;CHA4A52T;D7V7WJYT',
      statusContent: 'dot',
      hash: '7S6ZR65P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7S6ZR65P'
    },
    {
      status: 210,
      sku: 'PM0071169',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228387',
      rtl_batch_array: '6228387',
      name_search: 'RHPWHITE',
      name: "Rhododendron (AJ) 'Pleasant White'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TAL4R83R;5X9TH1VY;RT4BSBP3;6CKXTYAN;JKX1L8S7;RJ1D1VSN;PW5EE35W',
      statusContent: 'dot',
      hash: 'BRXG52BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRXG52BC'
    },
    {
      status: 810,
      sku: 'PM0065581',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254710',
      rtl_batch_array: '6254710',
      name_search: 'DRFMBARN',
      name: "Dryopteris filix-mas 'Barnesii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GWG1CNJZ',
      statusContent: 'dot',
      hash: 'D86JVASG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D86JVASG'
    },
    {
      status: 210,
      sku: 'PM0065969',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307852',
      rtl_batch_array: '6307852',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_prcp: 8.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: '6J81CRWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J81CRWP'
    },
    {
      status: 810,
      sku: 'PM0071170',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228388',
      rtl_batch_array: '6228388',
      name_search: 'RHVSCARL',
      name: "Rhododendron (AJ) 'Vuyk's Scarlet'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9TN67VJV;YJSHN8Z9;GP7JHY4S;7JDENSY3;K7299T1X;CSAAR3AW;GHPABD4R;SJR2LK3C;9J5LHL9G;Z8335X8Z;PA627KKW;YX2VTW1N;PSNN61NW;VGL2G629;KRJ2JNXC;VX3HJKWV;CZ8JPKW6;12WZR5VL;CVRSRXLW',
      statusContent: 'dot',
      hash: '2CSRJ6BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CSRJ6BC'
    },
    {
      status: 210,
      sku: 'PM0065084',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 8,
      btch_active_retail: '6356622',
      rtl_batch_array:
        '6207836, 6267758, 6274827, 6382271, 6287669, 6356622, 6385661, 6333200',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 349,
      chnn_stock_retail: 32396,
      sppl_order_minimum: 3,
      sppl_prcp: 0.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: 'X4ZG2WDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4ZG2WDC'
    },
    {
      status: 210,
      sku: 'PM0059131',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301900',
      rtl_batch_array: '6301900, 6383380',
      name_search: 'VERIGIDA',
      name: 'Verbena rigida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JHZ9E1E3;7ZK7HPY6;P4DNZJEJ;2V62XSB3;923W5HVA;KAL39NZ1;9E8AEPLP;A7HXBCY5;PSY1D8W2;EE9SXL7E;BPVSXRV6;C8CZJX7K;JHB93VZP;APPBK6H4',
      statusContent: 'dot',
      hash: 'HNSLSCXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNSLSCXC'
    },
    {
      status: 210,
      sku: 'PM0085387',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375764',
      rtl_batch_array: '6375764',
      name_search: 'LUMCASTL',
      name: "Lupinus 'My Castle'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '87ZW41DK;1ZB27R6Y',
      statusContent: 'dot',
      hash: 'NSKNL6SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSKNL6SE'
    },
    {
      status: 210,
      sku: 'PM0078597',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310302',
      rtl_batch_array: '6310302',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 267,
      chnn_stock_retail: 267,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: 'L9DTKLHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9DTKLHG'
    },
    {
      status: 210,
      sku: 'PM0081863',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348851',
      rtl_batch_array: '6348851',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1650,
      chnn_stock_retail: 1650,
      sppl_prcp: 6.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: '7CY4ZTZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CY4ZTZS'
    },
    {
      status: 210,
      sku: 'PM0078598',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310303',
      rtl_batch_array: '6310303',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '020025C5',
      rng_range_identifier: 'H020025C5',
      rng_range_description: 'H20 cm 25 cm C5',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: 'DKTYEKA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKTYEKA8'
    },
    {
      status: 210,
      sku: 'PM0078968',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314145',
      rtl_batch_array: '6314145',
      name_search: 'HEDEVA',
      name: 'Hebe Donna Eva',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 3010,
      chnn_stock_retail: 3010,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CTH91E8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTH91E8K'
    },
    {
      status: 810,
      sku: 'PM0081864',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355346',
      rtl_batch_array: '6355346, 6348853',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 33,
      chnn_stock_retail: 1033,
      sppl_prcp: 4.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: '4JNZD7A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JNZD7A5'
    },
    {
      status: 210,
      sku: 'PM0081865',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348854',
      rtl_batch_array: '6348854',
      name_search: 'VIPWATAN',
      name: "Viburnum plicatum 'Watanabe'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9LXJV3K;827Z6E42;AS1PNED1;NRRZK322;DNGB43HJ;ZE1WC5B3',
      statusContent: 'dot',
      hash: 'R1P3T8S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1P3T8S1'
    },
    {
      status: 210,
      sku: 'PM0078599',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310305',
      rtl_batch_array: '6310305',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 6583,
      chnn_stock_retail: 6583,
      sppl_prcp: 5.253,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '58A3YEHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58A3YEHY'
    },
    {
      status: 210,
      sku: 'PM0065639',
      core_name: 'Plant',
      sppl_ean: '8720664850866',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165699',
      rtl_batch_array: '6165699',
      name_search: 'AGRALABA',
      name: "Agastache rugosa 'Alabaster'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4084,
      chnn_stock_retail: 4084,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B766ZR44',
      statusContent: 'dot',
      hash: '2682D6H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2682D6H8'
    },
    {
      status: 210,
      sku: 'PM0081866',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369365',
      rtl_batch_array: '6369365, 6348856',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 2250,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '2B7JVL3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B7JVL3A'
    },
    {
      status: 210,
      sku: 'PM0081867',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348857',
      rtl_batch_array: '6348857',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'J15DVASP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J15DVASP'
    },
    {
      status: 210,
      sku: 'PM0078600',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310306',
      rtl_batch_array: '6310306',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2999,
      chnn_stock_retail: 2999,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'KSX9RR6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSX9RR6A'
    },
    {
      status: 210,
      sku: 'PM0078601',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310307',
      rtl_batch_array: '6310307',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 1179,
      chnn_stock_retail: 1179,
      sppl_prcp: 5.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'L6BKWL7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6BKWL7B'
    },
    {
      status: 210,
      sku: 'PM0065647',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219475',
      rtl_batch_array: '6219475',
      name_search: 'CORADREA',
      name: "Coreopsis rosea 'American Dream'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KX3L1DWL;RDV5Y8EZ;G2BGTHW5;WLP5VT4H;XH5SBB4T;EJRLK6BT;K286J448;V9HPNX4R',
      statusContent: 'dot',
      hash: '8YLXC3XJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YLXC3XJ'
    },
    {
      status: 210,
      sku: 'PM0078602',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310309',
      rtl_batch_array: '6310309',
      name_search: 'LONITIDA',
      name: 'Lonicera nitida',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 4290,
      chnn_stock_retail: 4290,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1JTDKH6;YKG75834;CRAYVTZ4;SSSSXDTD;YP31E8G3;TTA9TLTC;NSZGC3XE;C11D8GKN;R6GTGC3Z;R4RBWG2C',
      statusContent: 'dot',
      hash: '5RV8PCHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RV8PCHD'
    },
    {
      status: 210,
      sku: 'PM0081868',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348859',
      rtl_batch_array: '6348859',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 0.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: 'R1K7YD1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1K7YD1V'
    },
    {
      status: 210,
      sku: 'PM0068229',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193803',
      rtl_batch_array: '6193803',
      name_search: 'MEAQUATI',
      name: 'Mentha aquatica',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CELDAE22;XVDHXCSE;T83EGY2B;RS4SKGVS;NEAVE6GA',
      statusContent: 'dot',
      hash: '9YGRP3V3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YGRP3V3'
    },
    {
      status: 210,
      sku: 'PM0078604',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310311',
      rtl_batch_array: '6310311',
      name_search: 'LONHFIND',
      name: "Lonicera nitida 'Hohenheimer Findling'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P9EJPBW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9EJPBW3'
    },
    {
      status: 210,
      sku: 'PM0081869',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348861',
      rtl_batch_array: '6348861',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 3.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: 'A53EJ776',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A53EJ776'
    },
    {
      status: 210,
      sku: 'PM0085388',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 4,
      btch_active_retail: '6375765',
      rtl_batch_array: '6375765, 6375775, 6375776, 6375777',
      name_search: 'LEMAXIMU',
      name: 'Leucanthemum maximum',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4ZY387A6;NGTDZ6K2',
      statusContent: 'dot',
      hash: '5W2D1JTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W2D1JTK'
    },
    {
      status: 210,
      sku: 'PM0066724',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174844',
      rtl_batch_array: '6174844',
      name_search: 'HONBCHRI',
      name: "Hosta 'Night Before Christmas'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1183,
      chnn_stock_retail: 1183,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HCD2DVHL;9YX75SVG;GZDVBNPZ;CZSR3XAW;5HYZ7BDD;TJW5TC79;HGZ5YGTW;ZR4C31KP',
      statusContent: 'dot',
      hash: 'P1CPCAEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1CPCAEA'
    },
    {
      status: 210,
      sku: 'PM0078609',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310317',
      rtl_batch_array: '6310317',
      name_search: 'LOPMGREE',
      name: "Lonicera pileata 'Moss Green'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HSBWNK6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSBWNK6K'
    },
    {
      status: 210,
      sku: 'PM0081870',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348862',
      rtl_batch_array: '6348862',
      name_search: 'VACELLIO',
      name: "Vaccinium corymbosum 'Elliot'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NP3J5X43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NP3J5X43'
    },
    {
      status: 210,
      sku: 'PM0081871',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348863',
      rtl_batch_array: '6348863',
      name_search: 'VACG23',
      name: "Vaccinium corymbosum 'Goldtraube 23'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VELAK4GB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VELAK4GB'
    },
    {
      status: 210,
      sku: 'PM0081872',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348864',
      rtl_batch_array: '6348864',
      name_search: 'VACNCOUN',
      name: "Vaccinium corymbosum 'Northcountry'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7AZ7J946',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AZ7J946'
    },
    {
      status: 210,
      sku: 'PM0081873',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348865',
      rtl_batch_array: '6348865',
      name_search: 'VACCNSKY',
      name: "Vaccinium corymbosum 'Northsky'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SZN5PVXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZN5PVXV'
    },
    {
      status: 210,
      sku: 'PM0069477',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254382',
      rtl_batch_array: '6254382, 6302548',
      name_search: 'GYPFLAMI',
      name: "Gypsophila paniculata 'Flamingo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HLC9CP1K;NJK3KBYL',
      statusContent: 'dot',
      hash: 'E1RC2B7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1RC2B7G'
    },
    {
      status: 210,
      sku: 'PM0078610',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310318',
      rtl_batch_array: '6310318',
      name_search: 'LOTATARI',
      name: 'Lonicera tatarica',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_prcp: 5.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72JEBHXW',
      statusContent: 'dot',
      hash: 'NXKB22X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXKB22X1'
    },
    {
      status: 210,
      sku: 'PM0078611',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310319',
      rtl_batch_array: '6310319',
      name_search: 'LOXCDWAR',
      name: "Lonicera xylosteoides 'Clavey's Dwarf'",
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 393,
      chnn_stock_retail: 393,
      sppl_prcp: 5.403,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H64L9TLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H64L9TLC'
    },
    {
      status: 210,
      sku: 'PM0078954',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314131',
      rtl_batch_array: '6314131',
      name_search: 'COKSATOM',
      name: "Cornus kousa 'Satomi'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 206,
      chnn_stock_retail: 206,
      sppl_prcp: 6.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46W63H9G;ZWPZR8X9;HCL32Y1H;WE7LXD8X',
      statusContent: 'dot',
      hash: 'CAN15P1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAN15P1V'
    },
    {
      status: 210,
      sku: 'PM0063681',
      core_name: 'Plant',
      sppl_ean: '8720664885653',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147855',
      rtl_batch_array: '6147855',
      name_search: 'SEGFOOL',
      name: "Sedum 'Gooseberry Fool'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6YR6SXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6YR6SXA'
    },
    {
      status: 210,
      sku: 'PM0065653',
      core_name: 'Plant',
      sppl_ean: '8720664864269',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165715',
      rtl_batch_array: '6165715',
      name_search: 'GEDCRUG',
      name: "Geranium 'Dusky Cr\u00fbg'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1796,
      chnn_stock_retail: 1796,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SEL6NG4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEL6NG4L'
    },
    {
      status: 210,
      sku: 'PM0078752',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298772',
      rtl_batch_array: '6298772',
      name_search: 'RHEASTAR',
      name: 'Rhododendron Enc. Autumn Starburst',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 603,
      chnn_stock_retail: 603,
      sppl_prcp: 5.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VKN4Z312',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKN4Z312'
    },
    {
      status: 210,
      sku: 'PM0081874',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348866',
      rtl_batch_array: '6348866',
      name_search: 'VACPIONE',
      name: "Vaccinium corymbosum 'Pioneer'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CDS9HSY4',
      statusContent: 'dot',
      hash: 'JDYDSZV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDYDSZV8'
    },
    {
      status: 210,
      sku: 'PM0063685',
      core_name: 'Plant',
      sppl_ean: '8720664885721',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147861',
      rtl_batch_array: '6147861',
      name_search: 'SEPPRIDE',
      name: "Sedum 'Postmans Pride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D66VG32W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D66VG32W'
    },
    {
      status: 210,
      sku: 'PM0078950',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314127',
      rtl_batch_array: '6314127',
      name_search: 'CECSHIRO',
      name: "Cercis chinensis 'Shirobana'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 15.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8JDCY6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8JDCY6K'
    },
    {
      status: 210,
      sku: 'PM0078951',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314128',
      rtl_batch_array: '6314128',
      name_search: 'CHOTGOLD',
      name: "Chamaecyparis obtusa 'Tsatsumi Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3419,
      chnn_stock_retail: 3419,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '69J1EL5G;71V43H94;GCGAYSYY',
      statusContent: 'dot',
      hash: 'P23YKYB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P23YKYB4'
    },
    {
      status: 210,
      sku: 'PM0059125',
      core_name: 'Plant',
      sppl_ean: '8720664852013',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161534',
      rtl_batch_array: '6161534',
      name_search: 'AQFMINIS',
      name: "Aquilegia flabellata 'Ministar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KTNVT8A7;BH4T5CK8;H6SYXEGN;BKCND16D;E5RCX9R6;97S8GJ61;7C3CA9CX;EZLD57N8;REBTHG3A;KHYXNVGN;RP2R7NHY;WKYJ8345;7XXBY98Y;ZSPRG8XG;X9EN76SB;W4G7N71P',
      statusContent: 'dot',
      hash: 'P3R3LHD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3R3LHD5'
    },
    {
      status: 210,
      sku: 'PM0068239',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193813',
      rtl_batch_array: '6193813',
      name_search: 'POCORDAT',
      name: 'Pontederia cordata',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X42A92J8;C8KCKYTT;D8ER9738;SYNHCXHZ;ZJ35G3KV;LZ1WKGRK;WVTGL4CT;C4C7T9SD;5PKSY8BH;AH3SX741',
      statusContent: 'dot',
      hash: '5WTXEAHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WTXEAHC'
    },
    {
      status: 210,
      sku: 'PM0081875',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348867',
      rtl_batch_array: '6348867',
      name_search: 'VACRUBEL',
      name: "Vaccinium corymbosum 'Rubel'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4AYPRGZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AYPRGZK'
    },
    {
      status: 210,
      sku: 'PM0081876',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348868',
      rtl_batch_array: '6348868',
      name_search: 'VACBERKE',
      name: "Vaccinium corymbosum 'Berkeley'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JH4BSHRZ',
      statusContent: 'dot',
      hash: 'XG4GLNZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG4GLNZJ'
    },
    {
      status: 810,
      sku: 'PM0063680',
      core_name: 'Plant',
      sppl_ean: '8720664885837',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164664',
      rtl_batch_array: '6164664',
      name_search: 'SEEWERSI',
      name: 'Sedum ewersii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LVHWGTNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVHWGTNL'
    },
    {
      status: 210,
      sku: 'PM0068230',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193804',
      rtl_batch_array: '6193804',
      name_search: 'MEPULEGI',
      name: 'Mentha pulegium',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYKTP4YJ;KWZCKBJB;JA9L6E9X;GDCV7BX5;9BYD2LSL;D6ZYW8WK;BHHPEL52;J8W3TE1Z;9YZTHC57;PW59JXRA;P88H3W8V;DY8YY97K;7T7EXY3J;DSL4N1A6;YVSSS2AV',
      statusContent: 'dot',
      hash: 'Z9KWGNTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9KWGNTL'
    },
    {
      status: 210,
      sku: 'PM0071174',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337726',
      rtl_batch_array: '6337726',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1897,
      chnn_stock_retail: 1897,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: '23CRB269',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23CRB269'
    },
    {
      status: 210,
      sku: 'PM0065683',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244212',
      rtl_batch_array: '6299799, 6244212, 6286687',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 594,
      chnn_stock_retail: 3661,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '73XSZZJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73XSZZJ3'
    },
    {
      status: 210,
      sku: 'PM0085389',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375766',
      rtl_batch_array: '6375766',
      name_search: 'LUGOVERN',
      name: "Lupinus 'The Governor'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1ELTRZGT;NJ86PZLE;NXL82TSX;SXTEXPEY;1RRTLEH9;TG3756B9;XL2WCVBE;5P915HGW;WVYZH61A;ETWWVGYJ;D3G9PL49;8A64YEGX;K1YT2PV9;J9YNHRKW;EV86ZDP8',
      statusContent: 'dot',
      hash: 'ELYK2YZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELYK2YZW'
    },
    {
      status: 210,
      sku: 'PM0078955',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314132',
      rtl_batch_array: '6314132',
      name_search: 'COKSCHME',
      name: "Cornus kousa 'Schmetterling'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 454,
      chnn_stock_retail: 454,
      sppl_prcp: 6.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WX1SSJ4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WX1SSJ4K'
    },
    {
      status: 210,
      sku: 'PM0078956',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314133',
      rtl_batch_array: '6314133',
      name_search: 'EXRNIAGA',
      name: "Exochorda racemosa 'Niagara'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 386,
      chnn_stock_retail: 386,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AERC8NXG;BNYXBTEC;XT44X7YT;VD3B6Z1B;JLH3SBCK;B9C8HS3R;J6XTP7A1;H1CR5KZC;SWGBWSRL;AW9Y692B;D9DTVY2V;TTS9K5VT;5A4YC53D;7HT5556T;9147LVST;W3B5LVLP;T1VRCPZ9;YRBZRN1Z;V6V3VVD7;2EJT8SWX;6AYWW6T1;2XCKNVJS',
      statusContent: 'dot',
      hash: '25WLLEWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25WLLEWR'
    },
    {
      status: 210,
      sku: 'PM0078957',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314134',
      rtl_batch_array: '6314134',
      name_search: 'HAIAPHRO',
      name: "Hamamelis intermedia 'Aphrodite'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 316,
      chnn_stock_retail: 316,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VCD51HJD;2TBEVLBS;YJG8VVR6;EDZ11GYS;JJNEACJS;SH4VVNXL;5XVYK2YB;HJZW5L3H;2H428NV7',
      statusContent: 'dot',
      hash: 'WZGK3KBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZGK3KBH'
    },
    {
      status: 210,
      sku: 'PM0085390',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375767',
      rtl_batch_array: '6375767',
      name_search: 'ASVWHITE',
      name: "Astilbe 'Vision in White'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NYRJPJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NYRJPJJ'
    },
    {
      status: 210,
      sku: 'PM0085391',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375768',
      rtl_batch_array: '6375768',
      name_search: 'LUPAGES',
      name: "Lupinus 'The Pages'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BJJRASLB;K15XS3LE;ZTC5837Y;2VYB9G37;BP9TGDYA;1266JPER;4L5BCC1Z;8R5T72HZ',
      statusContent: 'dot',
      hash: 'TTRC4TWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTRC4TWH'
    },
    {
      status: 210,
      sku: 'PM0078958',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314135',
      rtl_batch_array: '6314135',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1022,
      chnn_stock_retail: 1022,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'LLBBSSRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLBBSSRE'
    },
    {
      status: 210,
      sku: 'PM0069479',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324744',
      rtl_batch_array: '6301625, 6324744',
      name_search: 'HAMBKAZE',
      name: "Hakonechloa macra 'Beni-kaze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 392,
      chnn_stock_retail: 688,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97ABD2E3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97ABD2E3'
    },
    {
      status: 210,
      sku: 'PM0065672',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300267',
      rtl_batch_array: '6300267',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2510,
      chnn_stock_retail: 2510,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '8HGNNNZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HGNNNZH'
    },
    {
      status: 810,
      sku: 'PM0078960',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314137',
      rtl_batch_array: '6314137',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: '5X3WD2N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X3WD2N7'
    },
    {
      status: 210,
      sku: 'PM0065679',
      core_name: 'Plant',
      sppl_ean: '8720664869011',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6166107',
      rtl_batch_array: '6166107',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 1.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'WLERCKVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLERCKVJ'
    },
    {
      status: 910,
      sku: 'PM0063682',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SEHGRAY',
      name: "Sedum 'Hab Gray'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '485B2ZD4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '485B2ZD4'
    },
    {
      status: 210,
      sku: 'PM0078608',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310315',
      rtl_batch_array: '6310315',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 9750,
      chnn_stock_retail: 9750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'L4B4N2AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4B4N2AA'
    },
    {
      status: 210,
      sku: 'PM0078962',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314139',
      rtl_batch_array: '6314139',
      name_search: 'HAIOPEEL',
      name: "Hamamelis intermedia 'Orange Peel'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z5Z8TN23;AZEAN5HA',
      statusContent: 'dot',
      hash: 'GDJ8996P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDJ8996P'
    },
    {
      status: 210,
      sku: 'PM0085392',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375769',
      rtl_batch_array: '6375769',
      name_search: 'LUNMAIDE',
      name: "Lupinus 'Noble Maiden'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E6PDYS87;935YDA1T',
      statusContent: 'dot',
      hash: 'V3DSV1Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3DSV1Y3'
    },
    {
      status: 210,
      sku: 'PM0078963',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314140',
      rtl_batch_array: '6314140',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 316,
      chnn_stock_retail: 316,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: '6A5JLRAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A5JLRAJ'
    },
    {
      status: 210,
      sku: 'PM0068238',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193812',
      rtl_batch_array: '6193812',
      name_search: 'POCPPONS',
      name: "Pontederia cordata 'Pink Pons'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DVGEWK75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVGEWK75'
    },
    {
      status: 210,
      sku: 'PM0085393',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375770',
      rtl_batch_array: '6375770',
      name_search: 'LEMADONN',
      name: "Leucanthemum (S) 'Madonna'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WL566EYK',
      statusContent: 'dot',
      hash: '6LXHWRZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LXHWRZL'
    },
    {
      status: 210,
      sku: 'PM0078966',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314143',
      rtl_batch_array: '6314143',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 829,
      chnn_stock_retail: 829,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'D99V7PS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D99V7PS7'
    },
    {
      status: 210,
      sku: 'PM0078967',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314144',
      rtl_batch_array: '6314144',
      name_search: 'HEHALEXA',
      name: 'Hebe Hebedonna Alexa',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 1977,
      chnn_stock_retail: 1977,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8LPD1D62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LPD1D62'
    },
    {
      status: 210,
      sku: 'PM0071180',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228398',
      rtl_batch_array: '6228398',
      name_search: 'CAJASIMP',
      name: "Camellia japonica 'Alba Simplex'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_prcp: 3.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '475H1T8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '475H1T8E'
    },
    {
      status: 210,
      sku: 'PM0085394',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375772',
      rtl_batch_array: '6375772',
      name_search: 'LESDCHER',
      name: 'Leucanthemum (S) Sweet Daisy Cher',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2WHGDRW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WHGDRW3'
    },
    {
      status: 210,
      sku: 'PM0078969',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314146',
      rtl_batch_array: '6314146',
      name_search: 'HEDEVA',
      name: 'Hebe Donna Eva',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6205,
      chnn_stock_retail: 6205,
      sppl_order_minimum: 3,
      sppl_prcp: 2.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2JVK8EH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JVK8EH2'
    },
    {
      status: 210,
      sku: 'PM0078970',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314148',
      rtl_batch_array: '6314148',
      name_search: 'HEHJULIA',
      name: 'Hebe Hebedonna Julia',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 885,
      chnn_stock_retail: 885,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NXYNHH3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXYNHH3H'
    },
    {
      status: 210,
      sku: 'PM0065681',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6299794',
      rtl_batch_array: '6299794, 6244214',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 7492,
      chnn_stock_retail: 8147,
      sppl_prcp: 3.918,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'WLYBY67V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLYBY67V'
    },
    {
      status: 910,
      sku: 'PM0065684',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'SJXKAT3B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SJXKAT3B'
    },
    {
      status: 210,
      sku: 'PM0065632',
      core_name: 'Plant',
      sppl_ean: '8720664877412',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165691',
      rtl_batch_array: '6165691',
      name_search: 'PEAROSEA',
      name: "Persicaria amplexicaulis 'Rosea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4459,
      chnn_stock_retail: 4459,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7B29ARX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7B29ARX1'
    },
    {
      status: 210,
      sku: 'PM0085395',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375773',
      rtl_batch_array: '6375773',
      name_search: 'LELACROS',
      name: "Leucanthemum (S) 'Lacrosse'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RDAAH1AB;SJNLLNT7;V18GRG6C',
      statusContent: 'dot',
      hash: '1CETP494',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CETP494'
    },
    {
      status: 210,
      sku: 'PM0085396',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375774',
      rtl_batch_array: '6375774',
      name_search: 'LEMOLAGR',
      name: 'Leucanthemum maximum Ohh la Lagrande',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BAP2YHKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAP2YHKC'
    },
    {
      status: 210,
      sku: 'PM0085397',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6375778',
      rtl_batch_array: '6375778',
      name_search: 'ASDDREAM',
      name: "Astilbe 'Darwin's Dream'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '83AJHNCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83AJHNCC'
    },
    {
      status: 210,
      sku: 'PM0085398',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376233',
      rtl_batch_array: '6376233',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '080100C24',
      rng_range_identifier: 'H080100C2.5',
      rng_range_description: 'H80 cm 100 cm C2.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 4.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '9RC3R31R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RC3R31R'
    },
    {
      status: 210,
      sku: 'PM0085399',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376234',
      rtl_batch_array: '6376234',
      name_search: 'ITVHGARN',
      name: "Itea virginica 'Henry's Garnet'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 3,
      sppl_prcp: 1.26,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZLL83K2',
      statusContent: 'dot',
      hash: 'TCX6V8L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCX6V8L2'
    },
    {
      status: 810,
      sku: 'PM0059133',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6272830',
      rtl_batch_array: '6272830, 6107112',
      name_search: 'HEEGEM',
      name: "Hebe 'Emerald Gem'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 36,
      chnn_stock_retail: 479,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VPJ5SS7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPJ5SS7D'
    },
    {
      status: 210,
      sku: 'PM0059112',
      core_name: 'Plant',
      sppl_ean: '8720664863422',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6105437',
      rtl_batch_array: '6105437',
      name_search: 'FEGLAUCA',
      name: 'Festuca glauca',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1158,
      chnn_stock_retail: 1158,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSVA34T4;ABP86W12;8EDK3VYW',
      statusContent: 'dot',
      hash: 'AK1CJP4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AK1CJP4N'
    },
    {
      status: 210,
      sku: 'PM0078603',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310310',
      rtl_batch_array: '6310310',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 10900,
      chnn_stock_retail: 10900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'KE1Z9H29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE1Z9H29'
    },
    {
      status: 210,
      sku: 'PM0085400',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376235',
      rtl_batch_array: '6376235',
      name_search: 'PRLTICO',
      name: 'Prunus lusitanica Tico',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWY41PZS;7R6JJ5AZ;4JP57245;K7VPGT28;G23X8HE1',
      statusContent: 'dot',
      hash: 'L6KT3NE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6KT3NE4'
    },
    {
      status: 210,
      sku: 'PM0085401',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376236',
      rtl_batch_array: '6376236',
      name_search: 'PRLTICO',
      name: 'Prunus lusitanica Tico',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWY41PZS;7R6JJ5AZ;4JP57245;K7VPGT28;G23X8HE1',
      statusContent: 'dot',
      hash: 'GRYDHWYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRYDHWYD'
    },
    {
      status: 210,
      sku: 'PM0078607',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355338',
      rtl_batch_array: '6355338, 6310314',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 150,
      chnn_stock_retail: 241,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'DAG9JY1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAG9JY1Z'
    },
    {
      status: 210,
      sku: 'PM0078606',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310313',
      rtl_batch_array: '6310313',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 11772,
      chnn_stock_retail: 11772,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '5C41E5DV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5C41E5DV'
    },
    {
      status: 210,
      sku: 'PM0078605',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310312',
      rtl_batch_array: '6310312',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 4500,
      chnn_stock_retail: 4500,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '7Y1LA41E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y1LA41E'
    },
    {
      status: 210,
      sku: 'PM0069490',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350313',
      rtl_batch_array: '6350313',
      name_search: 'HEDMETEO',
      name: "Helianthus decapetalus 'Meteor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18BE5LK1',
      statusContent: 'dot',
      hash: 'AHBB9KJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHBB9KJ3'
    },
    {
      status: 210,
      sku: 'PM0085402',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376238',
      rtl_batch_array: '6376238',
      name_search: 'PRLCBRAN',
      name: "Prunus laurocerasus 'Cherry Brandy'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 2.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCEH9RTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCEH9RTY'
    },
    {
      status: 210,
      sku: 'PM0071173',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337724',
      rtl_batch_array: '6337724',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: 'B5K5BS1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5K5BS1S'
    },
    {
      status: 210,
      sku: 'PM0071175',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337727',
      rtl_batch_array: '6228393, 6337727',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2985,
      chnn_stock_retail: 3015,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'NE6TRLWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NE6TRLWJ'
    },
    {
      status: 810,
      sku: 'PM0071176',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228394',
      rtl_batch_array: '6228394',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_prcp: 2.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'HZ75B9A1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZ75B9A1'
    },
    {
      status: 210,
      sku: 'PM0085403',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376241',
      rtl_batch_array: '6376241',
      name_search: 'PRLPOLST',
      name: "Prunus laurocerasus 'Polster'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_prcp: 2.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GVWH2TYH',
      statusContent: 'dot',
      hash: 'SWDAL4VG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWDAL4VG'
    },
    {
      status: 210,
      sku: 'PM0085404',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376242',
      rtl_batch_array: '6376242',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8N4C9KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8N4C9KS'
    },
    {
      status: 810,
      sku: 'PM0065106',
      core_name: 'Plant',
      sppl_ean: '8720664860285',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161646',
      rtl_batch_array: '6161646',
      name_search: 'DOMQVICT',
      name: "Dodecatheon meadia 'Queen Victoria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YECRYNP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YECRYNP8'
    },
    {
      status: 210,
      sku: 'PM0069474',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301614',
      rtl_batch_array: '6301614',
      name_search: 'GELDROPS',
      name: "Geum 'Lemon Drops'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZP86XHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZP86XHC'
    },
    {
      status: 810,
      sku: 'PM0069471',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350264',
      rtl_batch_array: '6350264',
      name_search: 'GERORANG',
      name: "Geum 'Rustico Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CKVDVB6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKVDVB6T'
    },
    {
      status: 210,
      sku: 'PM0069475',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350269',
      rtl_batch_array: '6350269',
      name_search: 'GEWKISS',
      name: "Geum 'Wet Kiss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 451,
      chnn_stock_retail: 451,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WXZYX5YK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXZYX5YK'
    },
    {
      status: 210,
      sku: 'PM0069485',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350302',
      rtl_batch_array: '6350302',
      name_search: 'HEMONIQU',
      name: "Helenium 'Monique'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GAKP3KRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAKP3KRX'
    },
    {
      status: 210,
      sku: 'PM0069488',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291696',
      rtl_batch_array: '6291696',
      name_search: 'HESTERNT',
      name: "Helianthemum 'Sterntaler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '82NY2YV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82NY2YV4'
    },
    {
      status: 210,
      sku: 'PM0069491',
      core_name: 'Plant',
      sppl_ean: '8720664691797',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054655',
      rtl_batch_array: '6054655',
      name_search: 'HEFSAUCE',
      name: "Helianthus 'Flying Saucers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 146,
      chnn_stock_retail: 146,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4PY4BE1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PY4BE1J'
    },
    {
      status: 210,
      sku: 'PM0069493',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350322',
      rtl_batch_array: '6350322',
      name_search: 'HEMOLLIS',
      name: 'Helianthus mollis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TSZ63XP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSZ63XP4'
    },
    {
      status: 810,
      sku: 'PM0069489',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350312',
      rtl_batch_array: '6350312',
      name_search: 'HEDCSTAR',
      name: "Helianthus decapetalus 'Capenoch Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5RCB23R3;6LEZZEGK',
      statusContent: 'dot',
      hash: 'HTAYSLE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTAYSLE9'
    },
    {
      status: 810,
      sku: 'PM0063686',
      core_name: 'Plant',
      sppl_ean: '8720664886117',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147863',
      rtl_batch_array: '6147863',
      name_search: 'SETRGLOB',
      name: "Sedum telephium 'Red Globe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7GE3W6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7GE3W6K'
    },
    {
      status: 910,
      sku: 'PM0071178',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 3,
      btch_active_retail: '6286150',
      rtl_batch_array: '6264398, 6281999, 6286150',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2,
      chnn_stock_retail: 220,
      sppl_prcp: 2.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: '1APR6DNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1APR6DNT'
    },
    {
      status: 210,
      sku: 'PM0078952',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314129',
      rtl_batch_array: '6314129',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 131,
      chnn_stock_retail: 131,
      sppl_prcp: 6.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: 'PZG4WP9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZG4WP9Y'
    },
    {
      status: 810,
      sku: 'PM0078953',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314130',
      rtl_batch_array: '6314130',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 6.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: '9ANGSWSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ANGSWSG'
    },
    {
      status: 910,
      sku: 'PM0074293',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: '5YD5814W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5YD5814W'
    },
    {
      status: 210,
      sku: 'PM0078961',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314138',
      rtl_batch_array: '6314138',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: 'PSRZZDS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSRZZDS4'
    },
    {
      status: 210,
      sku: 'PM0078959',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314136',
      rtl_batch_array: '6314136',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'P43V7PZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P43V7PZS'
    },
    {
      status: 810,
      sku: 'PM0078964',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314141',
      rtl_batch_array: '6314141',
      name_search: 'HAIRUBIN',
      name: "Hamamelis intermedia 'Rubin'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ38ZTBB;7G4J3W6T;PP39767R;Y55BSRTZ;ENJCRJ9T;CV28YW5D;B9C67895;NYJSJGPR',
      statusContent: 'dot',
      hash: 'Y4B9XBAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4B9XBAZ'
    },
    {
      status: 810,
      sku: 'PM0069482',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350276',
      rtl_batch_array: '6350276',
      name_search: 'HAMSRICH',
      name: "Hakonechloa macra 'Stripe it Rich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDRS4LB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDRS4LB6'
    },
    {
      status: 210,
      sku: 'PM0078965',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314142',
      rtl_batch_array: '6314142',
      name_search: 'HAIRGLOW',
      name: "Hamamelis intermedia 'Ruby Glow'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LB3PLRET',
      statusContent: 'dot',
      hash: 'PTEEK8D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTEEK8D5'
    },
    {
      status: 210,
      sku: 'PM0052926',
      core_name: 'Plant',
      sppl_ean: '8720626325029',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5964249',
      rtl_batch_array: '5964249',
      name_search: 'SAMINOR',
      name: 'Sanguisorba minor',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ET64G1L2;55ALK435;YB43T66S;H6JGDLNN;V7HXPLRV;YEVXL424;T3XDE2AL',
      statusContent: 'dot',
      hash: 'C1DTLVDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1DTLVDY'
    },
    {
      status: 210,
      sku: 'PM0065190',
      core_name: 'Plant',
      sppl_ean: '8720664873452',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161822',
      rtl_batch_array: '6161822',
      name_search: 'LUTFLAME',
      name: "Lupinus 'Tequila Flame'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SW7LZZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SW7LZZZ'
    },
    {
      status: 910,
      sku: 'PM0065707',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '8K16PGS5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8K16PGS5'
    },
    {
      status: 910,
      sku: 'PM0059182',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346750',
      rtl_batch_array: '6346750',
      name_search: 'PIPEFRAH',
      name: "Picea pungens 'Erich Frahm'",
      sppl_size_code: '175200C65',
      rng_range_identifier: 'H175200C65',
      rng_range_description: 'H175 cm 200 cm C65',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CG6H7VZG',
      statusContent: 'dot',
      hash: 'TPDKH72V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPDKH72V'
    },
    {
      status: 210,
      sku: 'PM0059223',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350603',
      rtl_batch_array: '6350603',
      name_search: 'KNELVIRA',
      name: "Kniphofia 'Elvira'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XH1HZAEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH1HZAEP'
    },
    {
      status: 910,
      sku: 'PM0059260',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'D5DV8R3V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D5DV8R3V'
    },
    {
      status: 210,
      sku: 'PM0081877',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348932',
      rtl_batch_array: '6348932',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'K1CB6CZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1CB6CZE'
    },
    {
      status: 210,
      sku: 'PM0071208',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228429',
      rtl_batch_array: '6228429',
      name_search: 'GRLITTOR',
      name: 'Griselinia littoralis',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1166,
      chnn_stock_retail: 1166,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'STYAHC45;NLA1EY63;PHXW78VN;NYKR71JV;R5BVEB9X;GP34GEWA;9GCVSGSL;JT5NKVY9;R42BK62V',
      statusContent: 'dot',
      hash: 'AYS29K84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYS29K84'
    },
    {
      status: 210,
      sku: 'PM0048125',
      core_name: 'Plant',
      sppl_ean: '8720626333796',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014343',
      rtl_batch_array: '6014343',
      name_search: 'AGCFORTU',
      name: 'Agastache Crazy Fortune',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XDEKY9ET;YWCRT37Z;4932V3E5;GR9V6Z7R;S44ECTYX;EX5CZKZG',
      statusContent: 'dot',
      hash: 'X64TGJJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X64TGJJX'
    },
    {
      status: 210,
      sku: 'PM0081878',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348948',
      rtl_batch_array: '6348948',
      name_search: 'ANWSWAN',
      name: 'Anemone Wild Swan',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1209,
      chnn_stock_retail: 1209,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B98YG278',
      statusContent: 'dot',
      hash: 'LP8WNAH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LP8WNAH5'
    },
    {
      status: 210,
      sku: 'PM0068079',
      core_name: 'Plant',
      sppl_ean: '8720349469147',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 1,
      btch_active_retail: '4856906',
      rtl_batch_array: '4856906',
      name_search: 'TOLOSTOF',
      name: 'TOPBUXUS Lokstof',
      sppl_size_code: 'DSJ2DS',
      rng_range_identifier: 'BOX2DOS',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '7LK5RLGR',
      statusContent: 'dot',
      hash: 'YHTRB3LH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHTRB3LH'
    },
    {
      status: 210,
      sku: 'PM0059228',
      core_name: 'Plant',
      sppl_ean: '8720664871397',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147809',
      rtl_batch_array: '6147809',
      name_search: 'LASWAGNE',
      name: "Lathyrus sylvestris 'Wagneri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9PZ9DL5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PZ9DL5N'
    },
    {
      status: 210,
      sku: 'PM0078612',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310320',
      rtl_batch_array: '6310320',
      name_search: 'LONXYLOS',
      name: 'Lonicera xylosteum',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1668,
      chnn_stock_retail: 1668,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TP72CYT5;DTRPWB64;A1GY4G4Y;5KBTB34C;PHBKGEK1',
      statusContent: 'dot',
      hash: '93L3D1YG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93L3D1YG'
    },
    {
      status: 210,
      sku: 'PM0078614',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310322',
      rtl_batch_array: '6310322',
      name_search: 'MASYLVES',
      name: 'Malus sylvestris',
      sppl_size_code: '060100C2',
      rng_range_identifier: 'H060100C2',
      rng_range_description: 'H60 cm 100 cm C2',
      sppl_stock_available: 3130,
      chnn_stock_retail: 3130,
      sppl_prcp: 1.422,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZGXBETB',
      statusContent: 'dot',
      hash: '1H5LG7KV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H5LG7KV'
    },
    {
      status: 210,
      sku: 'PM0052912',
      core_name: 'Plant',
      sppl_ean: '8720349495641',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6003589',
      rtl_batch_array: '6003589, 6254795',
      name_search: 'ORVULGAR',
      name: 'Origanum vulgare',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2456,
      chnn_stock_retail: 4094,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '91EJR1AJ;5PBSWE37;D3CTX94L;DCNEPGVY;1W5Z2RGA;WKDV28Y4;4PW94CCD;9LT83JX3;S52G1ZDE;THY267YB;KDP72R53;WSZWZNK3;5WZC152A;56BGEJZC;P2H7LJC1;CVH8XVGE;T4KP6TEW;C8EPDHSK;PYWK57NJ;JTJX8NPX;ZGDDC1SS;1BK3HBSD;134BHXE9;8DG5T3RP;JHBASPXK',
      statusContent: 'dot',
      hash: 'P2GXW9E3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2GXW9E3'
    },
    {
      status: 810,
      sku: 'PM0066656',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174657',
      rtl_batch_array: '6174657',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 6.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'RXYEDT3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXYEDT3L'
    },
    {
      status: 210,
      sku: 'PM0059219',
      core_name: 'Plant',
      sppl_ean: '8720664864801',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6162054',
      rtl_batch_array: '6147634, 6162054, 6333311, 6320243',
      name_search: 'GEORCLAI',
      name: "Geranium oxonianum 'Rose Clair'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 5652,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KK2G2932;N6PJ6BNL',
      statusContent: 'dot',
      hash: 'L7LZJGNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7LZJGNJ'
    },
    {
      status: 210,
      sku: 'PM0078615',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310323',
      rtl_batch_array: '6310323',
      name_search: 'MEGERMAN',
      name: 'Mespilus germanica',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A74E7AXE;8AS3Y1K6;99GBS7W4;H57WVXRG;LTDZ2597;NLDB3SJS;V1131G5E;D6SCVHDN;452E2C62;KHX9G811;Y3B6B6S7;BZB3X34V;7KRP9NZJ;EHY9HBPC;LXAAK7A5;Z91HW9DJ;BDKVC6HX;6492SSST',
      statusContent: 'dot',
      hash: '691WDZ5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '691WDZ5C'
    },
    {
      status: 210,
      sku: 'PM0059262',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184117',
      rtl_batch_array: '6184117',
      name_search: 'WEFALEXA',
      name: "Weigela florida 'Alexandra'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6B2ZE5E',
      statusContent: 'dot',
      hash: '2AHVL3V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AHVL3V2'
    },
    {
      status: 210,
      sku: 'PM0059248',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353503',
      rtl_batch_array: '6301914, 6353503',
      name_search: 'YUFCGUAR',
      name: "Yucca filamentosa 'Color Guard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 271,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XH8AP9EZ',
      statusContent: 'dot',
      hash: '7LNXHJ3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LNXHJ3L'
    },
    {
      status: 910,
      sku: 'PM0078616',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'YJ5YZYL3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YJ5YZYL3'
    },
    {
      status: 210,
      sku: 'PM0078617',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310325',
      rtl_batch_array: '6310325',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'JTVDRDJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTVDRDJZ'
    },
    {
      status: 810,
      sku: 'PM0059209',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229883',
      rtl_batch_array: '6229883',
      name_search: 'COGCHRIS',
      name: "Coreopsis grandiflora 'Christchurch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '47KW6GET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47KW6GET'
    },
    {
      status: 210,
      sku: 'PM0069501',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242161',
      rtl_batch_array: '6242161',
      name_search: 'HEPSCREA',
      name: "Hemerocallis 'Primal Scream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GPSAGSN2',
      statusContent: 'dot',
      hash: 'RN27JWXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RN27JWXW'
    },
    {
      status: 910,
      sku: 'PM0078975',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314156',
      rtl_batch_array: '6314156',
      name_search: 'ACPAOYAG',
      name: "Acer palmatum 'Aoyagi'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A85SHCKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A85SHCKY'
    },
    {
      status: 910,
      sku: 'PM0078618',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'W6XE7NJS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W6XE7NJS'
    },
    {
      status: 910,
      sku: 'PM0065702',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLMARIB',
      name: "Prunus laurocerasus 'Mariblon'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      statusContent: 'dot',
      hash: 'LAY71JD9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LAY71JD9'
    },
    {
      status: 210,
      sku: 'PM0078619',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310330',
      rtl_batch_array: '6310330',
      name_search: 'PHOLUTEU',
      name: "Physocarpus opulifolius 'Luteus'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1R7E91LL;W6XE2JAJ;77S82XY8;AP2R9RNP',
      statusContent: 'dot',
      hash: 'P1EB381J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1EB381J'
    },
    {
      status: 210,
      sku: 'PM0081879',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348950',
      rtl_batch_array: '6320840, 6348950',
      name_search: 'ASMCLARE',
      name: "Astrantia major 'Claret'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1645,
      chnn_stock_retail: 1907,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NYLR5E5X;93XLYCNY',
      statusContent: 'dot',
      hash: '6LCE1E37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LCE1E37'
    },
    {
      status: 210,
      sku: 'PM0069502',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6231147',
      rtl_batch_array: '5364154, 6231147, 6242030',
      name_search: 'HESWINE',
      name: "Hemerocallis 'Summer Wine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 1826,
      sppl_order_minimum: 3,
      sppl_prcp: 0.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AC5A15J;BW7T231S',
      statusContent: 'dot',
      hash: 'RZT54DEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZT54DEN'
    },
    {
      status: 210,
      sku: 'PM0068048',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301076',
      rtl_batch_array: '6301076, 6196397, 6307956',
      name_search: 'ASNBPBAL',
      name: "Aster novi-belgii 'Patricia Ballard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 986,
      chnn_stock_retail: 1776,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6X3DVTK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X3DVTK3'
    },
    {
      status: 210,
      sku: 'PM0078620',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310331',
      rtl_batch_array: '6310331',
      name_search: 'PHOLUTEU',
      name: "Physocarpus opulifolius 'Luteus'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1R7E91LL;W6XE2JAJ;77S82XY8;AP2R9RNP',
      statusContent: 'dot',
      hash: 'VVL95Z98',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVL95Z98'
    },
    {
      status: 210,
      sku: 'PM0078621',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310332',
      rtl_batch_array: '6310332',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4PHW2YW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PHW2YW4'
    },
    {
      status: 210,
      sku: 'PM0065124',
      core_name: 'Plant',
      sppl_ean: '8720664863729',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161683',
      rtl_batch_array: '6161683',
      name_search: 'FRADELIZ',
      name: 'Fragaria ananassa Delizz',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1N7C5L9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1N7C5L9P'
    },
    {
      status: 210,
      sku: 'PM0081880',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349590',
      rtl_batch_array: '6349590, 6348951',
      name_search: 'BAVCREAM',
      name: "Baptisia 'Vanilla Cream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 491,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4NYD2V97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NYD2V97'
    },
    {
      status: 210,
      sku: 'PM0078625',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310338',
      rtl_batch_array: '6310338',
      name_search: 'POFBLINK',
      name: "Potentilla fruticosa 'Blink'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 766,
      chnn_stock_retail: 766,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '66LY5D8Y;HLAEKCP6;59DVX3AS;YS2ZBCEZ;L28DN3LJ;1J6D5Z9B',
      statusContent: 'dot',
      hash: 'NHP9JNSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHP9JNSY'
    },
    {
      status: 210,
      sku: 'PM0078626',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310339',
      rtl_batch_array: '6310339',
      name_search: 'POFELIZA',
      name: "Potentilla fruticosa 'Elizabeth'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XY5JR1T3;BWWTSRRY;6SR67SWX;79CS1C7X',
      statusContent: 'dot',
      hash: 'Z4BJJJSL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4BJJJSL'
    },
    {
      status: 810,
      sku: 'PM0085405',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376244',
      rtl_batch_array: '6376244',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '060065KLBOL',
      rng_range_identifier: 'BALL\u00d8065H060065RB',
      rng_range_description: 'Ball \u00d8 65 cm H60 cm 65 cm Root ball',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 45.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'E2E2WW7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2E2WW7T'
    },
    {
      status: 210,
      sku: 'PM0059254',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271416',
      rtl_batch_array: '6271416',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 8562,
      chnn_stock_retail: 8562,
      sppl_prcp: 2.864,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '5LHENN3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LHENN3T'
    },
    {
      status: 210,
      sku: 'PM0078753',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269280',
      rtl_batch_array: '6269280',
      name_search: 'PEAMPLEX',
      name: 'Persicaria amplexicaulis',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4426,
      chnn_stock_retail: 4426,
      sppl_order_minimum: 3,
      sppl_prcp: 1.483,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KG2H81CK;V5P722P1;18NBHG9T;S36JV4GX;X1TV7BKY;C2DS4669;K12YL938;6DJDY2AS;5KDJJNBG;XSTEGW9V;X6XE3BWN;THXAB7DT;WJL4TWKW;HKDNXX3Y;GZVH5EWY;7DNDHWK1',
      statusContent: 'dot',
      hash: 'SZZK8JZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZZK8JZ1'
    },
    {
      status: 210,
      sku: 'PM0071194',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228414',
      rtl_batch_array: '6228414',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 2.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'P9ZY5TAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9ZY5TAC'
    },
    {
      status: 810,
      sku: 'PM0085406',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376245',
      rtl_batch_array: '6376245',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '070075KLBOL',
      rng_range_identifier: 'BALL\u00d8075H070075RB',
      rng_range_description: 'Ball \u00d8 75 cm H70 cm 75 cm Root ball',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 75.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '6HE7HYYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HE7HYYY'
    },
    {
      status: 210,
      sku: 'PM0065160',
      core_name: 'Plant',
      sppl_ean: '8720664867055',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161748',
      rtl_batch_array: '6161748',
      name_search: 'HETSPIDE',
      name: "Hemerocallis 'Tigereye Spider'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NTLK113',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NTLK113'
    },
    {
      status: 210,
      sku: 'PM0081881',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350404',
      rtl_batch_array: '6350404, 6348953',
      name_search: 'HEGPLUM',
      name: "Heuchera 'Georgia Plum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 724,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '88YV6DNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88YV6DNG'
    },
    {
      status: 210,
      sku: 'PM0078972',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314151',
      rtl_batch_array: '6314151',
      name_search: 'HEVDPURP',
      name: 'Hebe Vinoa \u00ae Dark Purple',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 1148,
      chnn_stock_retail: 1148,
      sppl_order_minimum: 3,
      sppl_prcp: 1.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HH4TAT5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HH4TAT5X'
    },
    {
      status: 210,
      sku: 'PM0078973',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314152',
      rtl_batch_array: '6314152',
      name_search: 'HEDCFFAV',
      name: "Hedera colchica 'Fall Favourite'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1382,
      chnn_stock_retail: 1382,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHC76BAZ;HVLEN2ZV;PDVV9TW9;7WV2LS4E;BX8B2LWB;2RVLX4BK;TLDZB1ZL',
      statusContent: 'dot',
      hash: 'DKY6ZPDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKY6ZPDR'
    },
    {
      status: 210,
      sku: 'PM0078974',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314153',
      rtl_batch_array: '6314153',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'R6PZ3TNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6PZ3TNX'
    },
    {
      status: 210,
      sku: 'PM0081882',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348956',
      rtl_batch_array: '6348956',
      name_search: 'HETNIGHT',
      name: "Heuchera 'Timeless Night'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2T2G512Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T2G512Z'
    },
    {
      status: 210,
      sku: 'PM0078976',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314157',
      rtl_batch_array: '6314157',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 692,
      chnn_stock_retail: 692,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'D5EAXXV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5EAXXV1'
    },
    {
      status: 210,
      sku: 'PM0071191',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 4,
      btch_active_retail: '6337859',
      rtl_batch_array: '6228411, 6294822, 6337859, 6348751',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3642,
      chnn_stock_retail: 5868,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: '82W2LNZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82W2LNZ1'
    },
    {
      status: 210,
      sku: 'PM0065309',
      core_name: 'Plant',
      sppl_ean: '8720664870611',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162700',
      rtl_batch_array: '6162700',
      name_search: 'JUPGCOAS',
      name: "Juniperus pfitzeriana 'Gold Coast'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 589,
      chnn_stock_retail: 589,
      sppl_prcp: 3.786,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EKGY93JY;16ZCPHTE;SWYLL49R;RRNGWR67;4C3YTV67',
      statusContent: 'dot',
      hash: 'T3VX3X7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3VX3X7C'
    },
    {
      status: 210,
      sku: 'PM0081883',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348958',
      rtl_batch_array: '6352657, 6348958',
      name_search: 'HEWILDBE',
      name: "Heuchera 'Wildberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 405,
      chnn_stock_retail: 501,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HD8EXC37;WWJHHCCG;7PX6V7BD;KRER3J5B;AD6TV1Z5;7YL352BC;2KTSAAK7',
      statusContent: 'dot',
      hash: 'R1XLJ43P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1XLJ43P'
    },
    {
      status: 210,
      sku: 'PM0078978',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314159',
      rtl_batch_array: '6314159',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 643,
      chnn_stock_retail: 643,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'PXPVZJ28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXPVZJ28'
    },
    {
      status: 210,
      sku: 'PM0071197',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228417',
      rtl_batch_array: '6228417, 5503631',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 872,
      chnn_stock_retail: 1372,
      sppl_prcp: 3.098,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '1987VZY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1987VZY2'
    },
    {
      status: 210,
      sku: 'PM0078979',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314160',
      rtl_batch_array: '6314160',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 4206,
      chnn_stock_retail: 4206,
      sppl_prcp: 22.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'W5VYEL34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5VYEL34'
    },
    {
      status: 210,
      sku: 'PM0065714',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257470',
      rtl_batch_array: '6257470',
      name_search: 'JUHBCHIP',
      name: "Juniperus horizontalis 'Blue Chip'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3013,
      chnn_stock_retail: 3013,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YJ8PR1BY;HDCYCYS6;CJTGT6CS;CEZEJ8AW;HJKDEBZP;PDJ1LX84;TKZZ5TKL;66XE4CC2',
      statusContent: 'dot',
      hash: '2HW5B725',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HW5B725'
    },
    {
      status: 210,
      sku: 'PM0065126',
      core_name: 'Plant',
      sppl_ean: '8720664863712',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161687',
      rtl_batch_array: '6161687',
      name_search: 'FRAZANTA',
      name: "Fragaria ananassa 'Zanta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5N4T6T8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N4T6T8L'
    },
    {
      status: 910,
      sku: 'PM0085407',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6376246',
      rtl_batch_array: '6376246',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '080KLBOL01',
      rng_range_identifier: 'BALL\u00d8080RB',
      rng_range_description: 'Ball \u00d8 80 cm Root ball',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 91.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'N6NBSKG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6NBSKG6'
    },
    {
      status: 210,
      sku: 'PM0078980',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314161',
      rtl_batch_array: '6314161',
      name_search: 'ACPBMAIK',
      name: "Acer palmatum 'Beni-maiko'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 4388,
      chnn_stock_retail: 4388,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JAA1EAER;XC6GY87T;CZEL4767;YSHNBSTS;KJYV9PJG;G27ZBKTG;1Z8RWKED;XW2Y7YY8;8VNCA163;Y8DGZH4W;SGZZVX1Z;ZYL7BGNZ;Y64VNZE1',
      statusContent: 'dot',
      hash: 'YLSEAGEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLSEAGEB'
    },
    {
      status: 810,
      sku: 'PM0069499',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220777',
      rtl_batch_array: '6220777',
      name_search: 'HEEKISSE',
      name: "Hemerocallis 'Eskimo Kisses'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4J3T56Z2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J3T56Z2'
    },
    {
      status: 210,
      sku: 'PM0065127',
      core_name: 'Plant',
      sppl_ean: '8720664863941',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161688',
      rtl_batch_array: '6161688',
      name_search: 'GAACELEB',
      name: "Gaillardia aristata 'Celebration'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 131,
      chnn_stock_retail: 131,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEKKA3GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEKKA3GW'
    },
    {
      status: 810,
      sku: 'PM0078981',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314162',
      rtl_batch_array: '6314162',
      name_search: 'ACPBMAIK',
      name: "Acer palmatum 'Beni-maiko'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JAA1EAER;XC6GY87T;CZEL4767;YSHNBSTS;KJYV9PJG;G27ZBKTG;1Z8RWKED;XW2Y7YY8;8VNCA163;Y8DGZH4W;SGZZVX1Z;ZYL7BGNZ;Y64VNZE1',
      statusContent: 'dot',
      hash: 'SY2NBC6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY2NBC6W'
    },
    {
      status: 210,
      sku: 'PM0078982',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314163',
      rtl_batch_array: '6314163',
      name_search: 'ACPBHOO',
      name: "Acer palmatum 'Bi-hoo'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJNPS2X8;N8DBB3WB;H99XGZKA;EWCKTXEE;GN5LG2R9;9ZH1W5ZS;G3HA2JSE;7CDYJEYJ;756P4RLC;LXS58H13;SEYVCP3P;YNDYHGHA;HVABCEYG;RJ1LLLED',
      statusContent: 'dot',
      hash: 'N4RWCGR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4RWCGR6'
    },
    {
      status: 210,
      sku: 'PM0059259',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184106',
      rtl_batch_array: '6184106',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'Z4X82V5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4X82V5X'
    },
    {
      status: 210,
      sku: 'PM0078983',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314164',
      rtl_batch_array: '6314164',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'CWGGEGPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWGGEGPE'
    },
    {
      status: 210,
      sku: 'PM0078984',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314165',
      rtl_batch_array: '6314165',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 674,
      chnn_stock_retail: 674,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'DABRAZGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DABRAZGT'
    },
    {
      status: 810,
      sku: 'PM0065713',
      core_name: 'Plant',
      sppl_ean: '8720664870567',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6166444',
      rtl_batch_array: '6166444',
      name_search: 'JUHBCHIP',
      name: "Juniperus horizontalis 'Blue Chip'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YJ8PR1BY;HDCYCYS6;CJTGT6CS;CEZEJ8AW;HJKDEBZP;PDJ1LX84;TKZZ5TKL;66XE4CC2',
      statusContent: 'dot',
      hash: 'DDGBH79C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDGBH79C'
    },
    {
      status: 210,
      sku: 'PM0065736',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310304',
      rtl_batch_array: '6310304, 6338239',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 17920,
      chnn_stock_retail: 20850,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'P27AB9KZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P27AB9KZ'
    },
    {
      status: 210,
      sku: 'PM0048318',
      core_name: 'Plant',
      sppl_ean: '8720664860681',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147589',
      rtl_batch_array: '6147589',
      name_search: 'ECWDDELI',
      name: "Echinacea 'White Double Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ZHRLRJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZHRLRJ7'
    },
    {
      status: 210,
      sku: 'PM0059200',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268705',
      rtl_batch_array: '6268705',
      name_search: 'ASLUSITA',
      name: 'Asphodelus lusitanicus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 492,
      chnn_stock_retail: 492,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N9R9LCTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9R9LCTZ'
    },
    {
      status: 210,
      sku: 'PM0059253',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184075',
      rtl_batch_array: '6184075',
      name_search: 'PHCTPARK',
      name: "Physocarpus capitatus 'Tilden Park'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TJKB6GN9',
      statusContent: 'dot',
      hash: 'NZ4VBR33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZ4VBR33'
    },
    {
      status: 210,
      sku: 'PM0066725',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291791',
      rtl_batch_array: '6291791',
      name_search: 'KNMACEDO',
      name: 'Knautia macedonica',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1706,
      chnn_stock_retail: 1706,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1V3B96XA;TN9V5X3T;V9J43XHP',
      statusContent: 'dot',
      hash: 'WYS84ET2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYS84ET2'
    },
    {
      status: 810,
      sku: 'PM0078985',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314166',
      rtl_batch_array: '6314166',
      name_search: 'ACPBSUGA',
      name: 'Acer palmatum Brown Sugar',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KTNL53NL;4E3P6R81;LTSTAC4L;J3G2ZAD2;99564YT9;ZAL47G1E;H1RZX1KD',
      statusContent: 'dot',
      hash: 'T54KTB8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T54KTB8R'
    },
    {
      status: 210,
      sku: 'PM0059214',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350018',
      rtl_batch_array: '6350018',
      name_search: 'ECFLAMIN',
      name: "Echinacea 'Flamingo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 323,
      chnn_stock_retail: 323,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NR7Y8N9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NR7Y8N9V'
    },
    {
      status: 210,
      sku: 'PM0039080',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6214315',
      rtl_batch_array: '6112716, 6214315',
      name_search: 'PAKROSEN',
      name: "Paeonia (LD) 'Karl Rosenfield'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1477,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25VJ5AS1;DE8L34XV;NGXVVV8Y;JAVSBX8G;V622SNR3;14VCRPEA;HP71LGS8;8P1K23AV;YA9NP5RK;EY3WEJNP',
      statusContent: 'dot',
      hash: 'SW2KHSWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW2KHSWR'
    },
    {
      status: 910,
      sku: 'PM0078987',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314168',
      rtl_batch_array: '6314168',
      name_search: 'ACPCHITO',
      name: "Acer palmatum 'Chitoseyama'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH1X8LSC',
      statusContent: 'dot',
      hash: 'R8WDX9N2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8WDX9N2'
    },
    {
      status: 810,
      sku: 'PM0078991',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314172',
      rtl_batch_array: '6314172',
      name_search: 'ACPDESHO',
      name: "Acer palmatum 'Deshojo'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L78XXX78',
      statusContent: 'dot',
      hash: 'Y6NVV4VR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6NVV4VR'
    },
    {
      status: 210,
      sku: 'PM0078988',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314169',
      rtl_batch_array: '6314169',
      name_search: 'ACPCHITO',
      name: "Acer palmatum 'Chitoseyama'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH1X8LSC',
      statusContent: 'dot',
      hash: 'KXABP736',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXABP736'
    },
    {
      status: 210,
      sku: 'PM0078989',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314170',
      rtl_batch_array: '6314170',
      name_search: 'ACPCHITO',
      name: "Acer palmatum 'Chitoseyama'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 306,
      chnn_stock_retail: 306,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH1X8LSC',
      statusContent: 'dot',
      hash: '2NXBBT9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NXBBT9R'
    },
    {
      status: 910,
      sku: 'PM0071188',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEGYPOIN',
      name: "Ceanothus griseus 'Yankee Point'",
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      imageCore: '5WNGL5X9;B2RSV8WC;GJRSTXRT',
      statusContent: 'dot',
      hash: '65VNHBY2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '65VNHBY2'
    },
    {
      status: 210,
      sku: 'PM0063788',
      core_name: 'Plant',
      sppl_ean: '8720664875661',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6162060',
      rtl_batch_array: '6192836, 6162060, 6215395',
      name_search: 'OMVERNA',
      name: 'Omphalodes verna',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4820,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6PG5L959;VZGC63ZZ;XP71J7DH;L1V9TNAP;CRG7J4VK;46J7X8HZ;YP3W1TYA;SX48LGWN',
      statusContent: 'dot',
      hash: '6RLWTG5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RLWTG5X'
    },
    {
      status: 810,
      sku: 'PM0078990',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314171',
      rtl_batch_array: '6314171',
      name_search: 'ACPDESHO',
      name: "Acer palmatum 'Deshojo'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L78XXX78',
      statusContent: 'dot',
      hash: 'WL4ZT33T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL4ZT33T'
    },
    {
      status: 210,
      sku: 'PM0078613',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310321',
      rtl_batch_array: '6310321',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 1.985,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: '96G5A8KB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96G5A8KB'
    },
    {
      status: 210,
      sku: 'PM0071200',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228420',
      rtl_batch_array: '6228420',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_prcp: 2.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'P7KCZC96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7KCZC96'
    },
    {
      status: 210,
      sku: 'PM0078992',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314173',
      rtl_batch_array: '6314173',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: '4W4W2NYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W4W2NYV'
    },
    {
      status: 210,
      sku: 'PM0078624',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310337',
      rtl_batch_array: '6310337',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: '5XTAWZCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XTAWZCA'
    },
    {
      status: 210,
      sku: 'PM0078622',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310335',
      rtl_batch_array: '6310335',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2057,
      chnn_stock_retail: 2057,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'Z5W8256B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5W8256B'
    },
    {
      status: 210,
      sku: 'PM0059247',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6238852',
      rtl_batch_array: '6230992, 6238852',
      name_search: 'VEHROSEA',
      name: "Verbena hastata 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 295,
      chnn_stock_retail: 448,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRVLWYR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRVLWYR3'
    },
    {
      status: 810,
      sku: 'PM0059193',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6216975',
      rtl_batch_array: '6216975',
      name_search: 'ACTJAPON',
      name: 'Actaea japonica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 2.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '526XSLPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '526XSLPD'
    },
    {
      status: 210,
      sku: 'PM0071201',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228422',
      rtl_batch_array: '6228422',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: '030035C4',
      rng_range_identifier: 'H030035C4',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_prcp: 3.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      statusContent: 'dot',
      hash: '7PH4TC5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PH4TC5B'
    },
    {
      status: 910,
      sku: 'PM0048148',
      core_name: 'Plant',
      sppl_ean: '8720664851368',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170755',
      rtl_batch_array: '6170755',
      name_search: 'ALGLOBEM',
      name: "Allium 'Globemaster'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_order_minimum: 3,
      sppl_prcp: 0,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D72SYRWV;BLAA5BH8;4NSYTZWB;K1PS3395;B92HT1P7;YR3NJY95;EKJNHVRD;A9YLCEWH;YLCWV2JW;4R27735H;19145WJK;1DH62ED5;NKP8NGN5;2V753X93;53ZRP3T7;737RW9BD;6ALKLPJD;GHECVHHJ;86ZNVSSH;KP3WGNX9;245PZL9E;YW6WP5BP',
      statusContent: 'dot',
      hash: '64JC1BDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64JC1BDV'
    },
    {
      status: 210,
      sku: 'PM0071204',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228425',
      rtl_batch_array: '6228425',
      name_search: 'EUFMINIM',
      name: "Euonymus fortunei 'Minimus'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_prcp: 1.912,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDL3HXLK;6EHP8PK6;W6KV7BGR;5XRTS6G4;4DALEPTE;1JPXWJ7K;KS11S8J6;AAES5KR6;1TBHNWB9;G49S5AKV',
      statusContent: 'dot',
      hash: 'X5CWPAT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5CWPAT6'
    },
    {
      status: 910,
      sku: 'PM0065712',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JUCREPAN',
      name: "Juniperus communis 'Repanda'",
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      imageCore:
        'TCX3E1WY;AHRHLBLB;XLS3DSDH;8NW6KNDW;GSXVB568;YB6RY9D3;G47EZ7SW;649BBC73;STWVW2RL;YN213YVT;ZYJVR4AG;ARJ5LEEG;NSCSLPPB;LLLY47EE;G9ZP44BJ;E5EEEL2Y;5W75BBW8;43YH4JB9;DAH8E3D3;4J8HVX2E;W3K49ZNW',
      statusContent: 'dot',
      hash: '987ABRAD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '987ABRAD'
    },
    {
      status: 210,
      sku: 'PM0071196',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280773',
      rtl_batch_array: '6280773',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1648,
      chnn_stock_retail: 1648,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: '9A7W7VX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9A7W7VX3'
    },
    {
      status: 210,
      sku: 'PM0074294',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269467',
      rtl_batch_array: '6269467',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '1GSSAT32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GSSAT32'
    },
    {
      status: 210,
      sku: 'PM0071203',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228424',
      rtl_batch_array: '6228424',
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1011,
      chnn_stock_retail: 1011,
      sppl_prcp: 1.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW2EVKB5;SP7Y5ZWK;SX1DDW1R;JCCEY4LT;A3VB9EVA;HY6AJV5K;CGP52GK8;YKBYTZKC;C9PLV17X;8YNL22WX;Z2CG7A8J;RJD8YAXS;A9KC643C;5V2J3N1E;5AGVBE54;D21HDP9S',
      statusContent: 'dot',
      hash: 'W5NSW1PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5NSW1PV'
    },
    {
      status: 210,
      sku: 'PM0071205',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228426',
      rtl_batch_array: '6228426',
      name_search: 'EUJHAPPI',
      name: "Euonymus japonicus 'Happiness'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 2328,
      chnn_stock_retail: 2328,
      sppl_prcp: 2.308,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNTCLRWN;JCJT2ZR7;E61BDLE5;JSS3Y38Y;5BX75ZSD;8W9SSXK3;RRAB97C7',
      statusContent: 'dot',
      hash: 'LHW1T5KD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHW1T5KD'
    },
    {
      status: 210,
      sku: 'PM0059261',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184109',
      rtl_batch_array: '6184109',
      name_search: 'SPNJBRID',
      name: "Spiraea nipponica 'June Bride'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZD9G8DS;Y27GCWAD',
      statusContent: 'dot',
      hash: '1WB8E99Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WB8E99Y'
    },
    {
      status: 210,
      sku: 'PM0071206',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228427',
      rtl_batch_array: '6228427',
      name_search: 'EUJMALBO',
      name: "Euonymus japonicus 'Microphyllus Albovariegatus'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 1249,
      chnn_stock_retail: 1249,
      sppl_prcp: 1.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RPCNN3P8;CLPV112N;4V5SY12J;91S7D8SD;Z96NABGW;1LRXKRXD;CJKGHRXR;EYAYN47W;LT3892CP;DDTCZTAP;DYALEX3G;2YT96E24;484GPBP7;C2JBESDN;PHSY87Z9;WSZ53RRT;51TBGKA1;P5CWWLXE;XCVHCT9Z;RWVTGAXR;6H3HZ7D9;L9XC9BAX',
      statusContent: 'dot',
      hash: 'DVH8EPRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVH8EPRA'
    },
    {
      status: 210,
      sku: 'PM0059206',
      core_name: 'Plant',
      sppl_ean: '8720664855519',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133201',
      rtl_batch_array: '6133201',
      name_search: 'CAMLMIDG',
      name: "Carex muskingumensis 'Little Midge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K9A5VNCE;XT9381ZT',
      statusContent: 'dot',
      hash: 'CXYVGC8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXYVGC8R'
    },
    {
      status: 810,
      sku: 'PM0065128',
      core_name: 'Plant',
      sppl_ean: '8720664863927',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161689',
      rtl_batch_array: '6161689',
      name_search: 'GASCUTIE',
      name: "Gaillardia 'Sunset Cutie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JN4LRW5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN4LRW5A'
    },
    {
      status: 210,
      sku: 'PM0068001',
      core_name: 'Plant',
      sppl_ean: '8720664803466',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084670',
      rtl_batch_array: '6084670',
      name_search: 'PONRMCBE',
      name: "Potentilla nepalensis 'Ron McBeath'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 318,
      chnn_stock_retail: 318,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T7LCAASZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7LCAASZ'
    },
    {
      status: 210,
      sku: 'PM0078977',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314158',
      rtl_batch_array: '6314158',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 51418,
      chnn_stock_retail: 51418,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'JY4ANTYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY4ANTYY'
    },
    {
      status: 810,
      sku: 'PM0059230',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217205',
      rtl_batch_array: '6217205',
      name_search: 'LIGRANDI',
      name: 'Lindernia grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TTWEJ4BZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTWEJ4BZ'
    },
    {
      status: 810,
      sku: 'PM0059231',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301699',
      rtl_batch_array: '6301699',
      name_search: 'LIMOKINA',
      name: "Liriope muscari 'Okina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_order_minimum: 3,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ZVKCD83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZVKCD83'
    },
    {
      status: 210,
      sku: 'PM0078986',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314167',
      rtl_batch_array: '6314167',
      name_search: 'ACPBUTTE',
      name: "Acer palmatum 'Butterfly'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z8LTJ8S9;AG3R7G4L;W113J7X5;9W94Y6HL;4D9B3KT2;L1R2L9G9;81S3BYXS;EZE7H3GR;RD4HPXTL;55Z5SL7X',
      statusContent: 'dot',
      hash: 'WLGJTHPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLGJTHPN'
    },
    {
      status: 210,
      sku: 'PM0071190',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228410',
      rtl_batch_array: '6228410',
      name_search: 'CESFLURR',
      name: "Ceanothus 'Snow Flurries'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_prcp: 2.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P84B1BP2;XX5N4RD4;45RZDRLR',
      statusContent: 'dot',
      hash: '1CXW3LJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CXW3LJB'
    },
    {
      status: 210,
      sku: 'PM0071198',
      core_name: 'Plant',
      sppl_ean: '8720353097473',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 3,
      btch_active_retail: '5207483',
      rtl_batch_array: '5207483, 6314033, 6337977',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4977,
      sppl_prcp: 3.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      imageBatch: 'ET56Y8DY',
      statusContent: 'dot',
      hash: '8Y5KXZ6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Y5KXZ6D'
    },
    {
      status: 210,
      sku: 'PM0065302',
      core_name: 'Plant',
      sppl_ean: '8720664856431',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162689',
      rtl_batch_array: '6162689',
      name_search: 'CHONAURE',
      name: "Chamaecyparis obtusa 'Nana Aurea'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 6.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTHWK8H;LZ1V2K2Y;65A8NG2C',
      statusContent: 'dot',
      hash: 'AEK1KACZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEK1KACZ'
    },
    {
      status: 210,
      sku: 'PM0065121',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329932',
      rtl_batch_array: '6329932',
      name_search: 'FAJWGRAV',
      name: "Farfugium japonicum 'Wavy Gravy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BWEEGY59;9YRHDE45;95HDW5DD;S9NT2PBV;R3PNW7C6;6SJ23RTL;CW6HSR8T;DBH1ZR2L;NTRHT7SP;SL6W122L',
      statusContent: 'dot',
      hash: 'ZXGB6TZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXGB6TZZ'
    },
    {
      status: 910,
      sku: 'PM0059250',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONRTIPS',
      name: "Lonicera nitida 'Red Tips'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      imageCore: 'GHYT48JB',
      statusContent: 'dot',
      hash: 'AE4PGA5G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AE4PGA5G'
    },
    {
      status: 210,
      sku: 'PM0065130',
      core_name: 'Plant',
      sppl_ean: '8720664864153',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161691',
      rtl_batch_array: '6161691',
      name_search: 'GEDAHURI',
      name: 'Gentiana dahurica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A8DP362X;JZCBL9AX',
      statusContent: 'dot',
      hash: 'DN8PABXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DN8PABXE'
    },
    {
      status: 910,
      sku: 'PM0068038',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WEFNPURP',
      name: "Weigela florida 'Nana Purpurea'",
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      imageCore: '8E4X3P5K;W26V4REV',
      statusContent: 'dot',
      hash: 'BA8XZTWE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BA8XZTWE'
    },
    {
      status: 810,
      sku: 'PM0059183',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307258',
      rtl_batch_array: '6307258',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 7.861,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'YSYZ9HNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSYZ9HNL'
    },
    {
      status: 210,
      sku: 'PM0065762',
      core_name: 'Plant',
      sppl_ean: '8720349473342',
      btch_manufacturer: 734,
      rtl_batch_array_total: 5,
      btch_active_retail: '4706032',
      rtl_batch_array: '6181282, 6378407, 6338534, 6182652, 4706032',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 11489,
      sppl_order_minimum: 5,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'VYVYYTP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYVYYTP8'
    },
    {
      status: 210,
      sku: 'PM0065201',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295761',
      rtl_batch_array: '6295761, 6386153',
      name_search: 'NESUBSES',
      name: 'Nepeta subsessilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 485,
      chnn_stock_retail: 1035,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4L327E42;L8JN45NL;H5698S63;NCGGS11K;CW9PWHY7;469Z4BEP;J5W7VY4Z;8TAS89JW;WXXHYY2N;KPVWE73R;JWKYNVXT',
      statusContent: 'dot',
      hash: 'SZ4ZZB3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZ4ZZB3D'
    },
    {
      status: 910,
      sku: 'PM0059265',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAVDBLUE',
      name: "Panicum virgatum 'Dallas Blues'",
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      imageCore: 'KSJGR7DV;BLTBBY62',
      statusContent: 'dot',
      hash: 'ZX9YR6PX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZX9YR6PX'
    },
    {
      status: 210,
      sku: 'PM0078628',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310344',
      rtl_batch_array: '6310344',
      name_search: 'POFKDYKE',
      name: "Potentilla fruticosa 'Katherine Dykes'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 3600,
      chnn_stock_retail: 3600,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HT6ENDV6;WEVLDX8C;YGKBHWB2;S9ALGDP1;81PALNVR;Y8L388GH;7L8Z8945;83N661Y5',
      statusContent: 'dot',
      hash: 'PXYLKEWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXYLKEWV'
    },
    {
      status: 210,
      sku: 'PM0065316',
      core_name: 'Plant',
      sppl_ean: '8720664879065',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162707',
      rtl_batch_array: '6162707',
      name_search: 'PIANIDIF',
      name: "Picea abies 'Nidiformis'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3008,
      chnn_stock_retail: 3008,
      sppl_prcp: 5.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SDXTPGH;ZS2L161S;B24CVBXA;GPRNNAHK;Z4NB8RW8;WK976K43;H2XA7VDP;TA5725B7',
      statusContent: 'dot',
      hash: 'V78C58YG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V78C58YG'
    },
    {
      status: 210,
      sku: 'PM0059275',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184011',
      rtl_batch_array: '6184011',
      name_search: 'CODMOONC',
      name: "Cotoneaster dammeri 'Mooncreeper'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WEX4N66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WEX4N66'
    },
    {
      status: 210,
      sku: 'PM0066723',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233455',
      rtl_batch_array: '6233455',
      name_search: 'HELMBEAU',
      name: "Helenium 'Moerheim Beauty'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1577,
      chnn_stock_retail: 1577,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BC2XLJ8X;E938N398;NZRE4ASR;67TJGVZ8;BJ4HV1LH;JDJ8AS9V;BHC3ETJW;WED4CXX3;XCSW959Y;DZBL1TS2;W1757Y63',
      statusContent: 'dot',
      hash: 'JGRPXT24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGRPXT24'
    },
    {
      status: 210,
      sku: 'PM0071210',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 4,
      btch_active_retail: '6228431',
      rtl_batch_array: '6228431, 5955991, 6294756, 6300738',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 551,
      chnn_stock_retail: 3293,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: '43GHZA2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43GHZA2P'
    },
    {
      status: 810,
      sku: 'PM0071211',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228432',
      rtl_batch_array: '6228432',
      name_search: 'HEHACOMP',
      name: "Hedera hibernica 'Arbori Compact'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_order_minimum: 3,
      sppl_prcp: 2.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N37HS328',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N37HS328'
    },
    {
      status: 210,
      sku: 'PM0068142',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 2,
      btch_active_retail: '6193681',
      rtl_batch_array: '6330115, 6193681',
      name_search: 'SCCMHEGA',
      name: "Schizostylis coccinea 'Mrs Hegarty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HDGCACP3',
      statusContent: 'dot',
      hash: '1GNDB6PC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GNDB6PC'
    },
    {
      status: 210,
      sku: 'PM0081884',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348959',
      rtl_batch_array: '6348959',
      name_search: 'HEUWROSE',
      name: "Heuchera 'Wild Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 393,
      chnn_stock_retail: 393,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ERJD8W9Z;4TCAHK72;TLHYVAZV;SB7E2BV9;AWW1APZJ',
      statusContent: 'dot',
      hash: 'Z5VN26JT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5VN26JT'
    },
    {
      status: 210,
      sku: 'PM0066718',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174837',
      rtl_batch_array: '6174837',
      name_search: 'FUPPRINC',
      name: "Fuchsia 'Panylla Prince'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1083,
      chnn_stock_retail: 1083,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4XBG42VC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XBG42VC'
    },
    {
      status: 210,
      sku: 'PM0078629',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310345',
      rtl_batch_array: '6310345',
      name_search: 'POFKLOND',
      name: "Potentilla fruticosa 'Klondike'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JTT47BS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTT47BS3'
    },
    {
      status: 210,
      sku: 'PM0078630',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310346',
      rtl_batch_array: '6310346',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 2404,
      chnn_stock_retail: 2404,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: 'H8Y2R7A8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8Y2R7A8'
    },
    {
      status: 210,
      sku: 'PM0074295',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356671',
      rtl_batch_array: '6269468, 6356671',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 998,
      chnn_stock_retail: 1098,
      sppl_prcp: 2.309,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'WGAD553W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGAD553W'
    },
    {
      status: 210,
      sku: 'PM0085408',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6377766',
      rtl_batch_array: '6377766',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '030050C3',
      rng_range_identifier: 'H030050C3',
      rng_range_description: 'H30 cm 50 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'PW133PYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW133PYN'
    },
    {
      status: 210,
      sku: 'PM0078631',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310348',
      rtl_batch_array: '6310348',
      name_search: 'POFPBEAU',
      name: "Potentilla fruticosa 'Primrose Beauty'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 918,
      chnn_stock_retail: 918,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5HYGEX5;PNXB7VNR;L9L6LZJ1;GAL36RY2;G71E12H6;Y4X3Y5LG',
      statusContent: 'dot',
      hash: '8BAKKPJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BAKKPJA'
    },
    {
      status: 210,
      sku: 'PM0078632',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310350',
      rtl_batch_array: '6310350',
      name_search: 'POFSOMME',
      name: "Potentilla fruticosa 'Sommerflor'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJEBSAWR;R44DTY7H;KXCK8TV2;SL8GRTT6;PLR7VDJL',
      statusContent: 'dot',
      hash: '95LB3RX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95LB3RX1'
    },
    {
      status: 210,
      sku: 'PM0078633',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310351',
      rtl_batch_array: '6310351',
      name_search: 'POFSUNSE',
      name: "Potentilla fruticosa 'Sunset'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 3988,
      chnn_stock_retail: 3988,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GYADY69V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYADY69V'
    },
    {
      status: 210,
      sku: 'PM0048412',
      core_name: 'Plant',
      sppl_ean: '8720664593497',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015485',
      rtl_batch_array: '6015485',
      name_search: 'PHHELENA',
      name: "Phlox 'Helena'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A7E6DHZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7E6DHZY'
    },
    {
      status: 210,
      sku: 'PM0066705',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174821',
      rtl_batch_array: '6174821',
      name_search: 'LEALASKA',
      name: "Leucanthemum (S) 'Alaska'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2280,
      chnn_stock_retail: 2280,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J879SRGC;NDZSVC5Z;2GP7KAHH;1DXSBXYC;DRPNZ3AE',
      statusContent: 'dot',
      hash: 'LS8STL5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LS8STL5L'
    },
    {
      status: 210,
      sku: 'PM0078635',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310353',
      rtl_batch_array: '6310353',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 2440,
      chnn_stock_retail: 2440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'SV8ZTX9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SV8ZTX9C'
    },
    {
      status: 910,
      sku: 'PM0059283',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      rng_range_identifier: 'H200225C3',
      rng_range_description: 'H200 cm 225 cm C3',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'LD2XB55G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LD2XB55G'
    },
    {
      status: 810,
      sku: 'PM0078636',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310354',
      rtl_batch_array: '6310354',
      name_search: 'PRPADUS',
      name: 'Prunus padus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_prcp: 1.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KV46D9EZ;T6JL51WS;EZ95SZXZ;GZV81PRD',
      statusContent: 'dot',
      hash: 'G37RJSD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G37RJSD3'
    },
    {
      status: 210,
      sku: 'PM0085409',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378034',
      rtl_batch_array: '6378034',
      name_search: 'PIHCGEM',
      name: "Pinus heldreichii 'Compact Gem'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 430,
      chnn_stock_retail: 430,
      sppl_prcp: 7.887,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GE6WH3N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GE6WH3N7'
    },
    {
      status: 210,
      sku: 'PM0032213',
      core_name: 'Plant',
      sppl_ean: '8720664860162',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6132971',
      rtl_batch_array: '6132971, 6254699',
      name_search: 'DIPURPUR',
      name: 'Digitalis purpurea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2860,
      chnn_stock_retail: 4820,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1XYAY1SZ',
      statusContent: 'dot',
      hash: 'AYNS56T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYNS56T7'
    },
    {
      status: 910,
      sku: 'PM0071214',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228436',
      rtl_batch_array: '6228436, 6356161',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 18,
      chnn_stock_retail: 2018,
      sppl_prcp: 3.296,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: '9D9SBZZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D9SBZZR'
    },
    {
      status: 210,
      sku: 'PM0081885',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350461',
      rtl_batch_array: '6350461, 6348960',
      name_search: 'HEASUNRI',
      name: "Heucherella 'Alabama Sunrise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 714,
      chnn_stock_retail: 1129,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A546BJDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A546BJDT'
    },
    {
      status: 210,
      sku: 'PM0071217',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228439',
      rtl_batch_array: '6228439',
      name_search: 'ILAGKING',
      name: "Ilex altaclerensis 'Golden King'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 691,
      chnn_stock_retail: 691,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TEYKWN7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEYKWN7G'
    },
    {
      status: 910,
      sku: 'PM0078638',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310356',
      rtl_batch_array: '6310356',
      name_search: 'PRSPINOS',
      name: 'Prunus spinosa',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 5.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NNAGJ3G;HCLHBHJR;ACJT86AY;ZCDXKXH2;9YTBC1EC;Y994TXX8;49PWEAKR;BVNEB8A6;P6BZ84H3;564BYGKH;NT3ZJDWL;1GYEV4PZ;74VT253K;28PJD3E1;9XPT87KW;236N7DA1;8XKL4D8S;ZNGC59GE;R474ZWDV;X45CPXEL;DGX6S9DL;RG5KAWSY',
      statusContent: 'dot',
      hash: '5AJ8GY74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AJ8GY74'
    },
    {
      status: 210,
      sku: 'PM0069515',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350432',
      rtl_batch_array: '6267787, 6350432',
      name_search: 'HEPARIS',
      name: "Heuchera 'Paris'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 458,
      chnn_stock_retail: 2541,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4V1T7ER5',
      statusContent: 'dot',
      hash: 'ER5C2XLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ER5C2XLS'
    },
    {
      status: 210,
      sku: 'PM0078641',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310359',
      rtl_batch_array: '6310359',
      name_search: 'QURUBRA',
      name: 'Quercus rubra',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1JJVPAYP;HD4YDLVZ',
      statusContent: 'dot',
      hash: 'RGHP3C8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGHP3C8C'
    },
    {
      status: 210,
      sku: 'PM0078758',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311192',
      rtl_batch_array: '6311192',
      name_search: 'POFBLINK',
      name: "Potentilla fruticosa 'Blink'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2750,
      chnn_stock_retail: 2750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '66LY5D8Y;HLAEKCP6;59DVX3AS;YS2ZBCEZ;L28DN3LJ;1J6D5Z9B',
      statusContent: 'dot',
      hash: 'CJYZ1TKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJYZ1TKR'
    },
    {
      status: 210,
      sku: 'PM0065766',
      core_name: 'Plant',
      sppl_ean: '8720664859555',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6079267',
      rtl_batch_array: '6282817, 6269808, 6079267',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 5097,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'NB29BAR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB29BAR4'
    },
    {
      status: 210,
      sku: 'PM0065301',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244376',
      rtl_batch_array: '6244376',
      name_search: 'CHONAURE',
      name: "Chamaecyparis obtusa 'Nana Aurea'",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_prcp: 5.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTHWK8H;LZ1V2K2Y;65A8NG2C',
      statusContent: 'dot',
      hash: '7JW1N3N9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JW1N3N9'
    },
    {
      status: 210,
      sku: 'PM0065759',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338522',
      rtl_batch_array: '6174489, 6338522',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1251,
      chnn_stock_retail: 1276,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'TNDN3R5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNDN3R5J'
    },
    {
      status: 210,
      sku: 'PM0078642',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310360',
      rtl_batch_array: '6310360',
      name_search: 'QURUBRA',
      name: 'Quercus rubra',
      sppl_size_code: '125150C2',
      rng_range_identifier: 'H125150C2',
      rng_range_description: 'H125 cm 150 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.677,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1JJVPAYP;HD4YDLVZ',
      statusContent: 'dot',
      hash: 'YT7J61PL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT7J61PL'
    },
    {
      status: 210,
      sku: 'PM0068134',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193667',
      rtl_batch_array: '6193667',
      name_search: 'POCWPIKE',
      name: "Pontederia cordata 'White Pike'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VE39JCZ2',
      statusContent: 'dot',
      hash: '5KA4K2WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KA4K2WG'
    },
    {
      status: 210,
      sku: 'PM0039548',
      core_name: 'Plant',
      sppl_ean: '8720664681811',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054481',
      rtl_batch_array: '6054481',
      name_search: 'BEKERSTI',
      name: "Bergenia 'Kerstin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WLEJ9646',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLEJ9646'
    },
    {
      status: 210,
      sku: 'PM0078993',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314174',
      rtl_batch_array: '6314174',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: '2XP423E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XP423E7'
    },
    {
      status: 210,
      sku: 'PM0081886',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350488',
      rtl_batch_array: '6350488, 6348962',
      name_search: 'HOAFROST',
      name: "Hosta 'Autumn Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 920,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XW3TDG3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW3TDG3D'
    },
    {
      status: 210,
      sku: 'PM0068141',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 2,
      btch_active_retail: '6193680',
      rtl_batch_array: '6220911, 6193680',
      name_search: 'SCCMAJOR',
      name: "Schizostylis coccinea 'Major'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 996,
      chnn_stock_retail: 1831,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EV7R1JP4;TWA3D81N;H4BCP5H3;JJBHN96V;PDKEZ3NH;2YJ9TWPE;PEDPTR6C;DYXDDGL1;T73XA4YX;TBJT7SNW;ZALYLXCJ;LTAHXBCV;7EKTX617',
      statusContent: 'dot',
      hash: 'GYHY6DPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYHY6DPH'
    },
    {
      status: 810,
      sku: 'PM0071224',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228448',
      rtl_batch_array: '6228448',
      name_search: 'ILECGGEM',
      name: "Ilex crenata 'Golden Gem'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 3.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6H1T152;ACELZCTX;L8LWT5T5;DNH7B45X;W1E88G6R;PWBSSNSK;H37PG1Y5;BES2N1AV;1WTGN263;4KJBZN66',
      statusContent: 'dot',
      hash: 'GXKTTKDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXKTTKDH'
    },
    {
      status: 210,
      sku: 'PM0065315',
      core_name: 'Plant',
      sppl_ean: '8720664870796',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162706',
      rtl_batch_array: '6162706',
      name_search: 'JUSHOLGE',
      name: "Juniperus squamata 'Holger'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1779,
      chnn_stock_retail: 1779,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '27RDVKTT;CR8CL3Z4;DEXSKTNY;C8Z6KHZ6;BVE5HJDT;13CER4GX;T5JPZL46;S82JXW9H;SCY2ZHED;G2S4ZZVD;BAYEH66C',
      statusContent: 'dot',
      hash: 'B9BCC6YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9BCC6YA'
    },
    {
      status: 210,
      sku: 'PM0078754',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311111',
      rtl_batch_array: '6311111',
      name_search: 'HYAAPURP',
      name: "Hypericum androsaemum 'Albury Purple'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2499,
      chnn_stock_retail: 2499,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1WX9DC6Y;W8KV9SW3',
      statusContent: 'dot',
      hash: '4D9R5D2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4D9R5D2H'
    },
    {
      status: 210,
      sku: 'PM0085410',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378035',
      rtl_batch_array: '6378035',
      name_search: 'PIHCGEM',
      name: "Pinus heldreichii 'Compact Gem'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_prcp: 10.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VVB4ANHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVB4ANHG'
    },
    {
      status: 210,
      sku: 'PM0048372',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220801',
      rtl_batch_array: '6220801',
      name_search: 'HEOWSPOT',
      name: "Helleborus orientalis 'White Spotted'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 588,
      chnn_stock_retail: 588,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PH9N2VRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH9N2VRL'
    },
    {
      status: 210,
      sku: 'PM0078755',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311140',
      rtl_batch_array: '6311140',
      name_search: 'ITVIRGIN',
      name: 'Itea virginica',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1629,
      chnn_stock_retail: 1629,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBE9K5JB',
      statusContent: 'dot',
      hash: '1EHXL6H3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1EHXL6H3'
    },
    {
      status: 210,
      sku: 'PM0078756',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311141',
      rtl_batch_array: '6311141',
      name_search: 'ITVHGARN',
      name: "Itea virginica 'Henry's Garnet'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1693,
      chnn_stock_retail: 1693,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZLL83K2',
      statusContent: 'dot',
      hash: 'EEENXSVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEENXSVN'
    },
    {
      status: 210,
      sku: 'PM0085411',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378036',
      rtl_batch_array: '6378036',
      name_search: 'PIMCARST',
      name: "Pinus mugo 'Carsten'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 2349,
      chnn_stock_retail: 2349,
      sppl_prcp: 7.887,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NYTBG6XN;ZD15G5E3;85XY1CC2;5XA9YHVS',
      statusContent: 'dot',
      hash: '2K5EDLZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2K5EDLZZ'
    },
    {
      status: 210,
      sku: 'PM0078760',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311198',
      rtl_batch_array: '6311198',
      name_search: 'POFLIMEL',
      name: "Potentilla fruticosa 'Limelight'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1707,
      chnn_stock_retail: 1707,
      sppl_order_minimum: 3,
      sppl_prcp: 0.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7C78VJ2T;BVG6EN1S;5725WCYW',
      statusContent: 'dot',
      hash: '41L5ZPTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41L5ZPTV'
    },
    {
      status: 210,
      sku: 'PM0078761',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311199',
      rtl_batch_array: '6311199',
      name_search: 'POFMAANE',
      name: "Potentilla fruticosa 'Maanelys'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1448,
      chnn_stock_retail: 1448,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RJ5Z4BWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJ5Z4BWV'
    },
    {
      status: 810,
      sku: 'PM0081887',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349780',
      rtl_batch_array: '6349780, 6348970',
      name_search: 'CAGCAROL',
      name: "Campanula glomerata 'Caroline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 653,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAJ77RR9;8ETKPPW6',
      statusContent: 'dot',
      hash: '93776YH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93776YH1'
    },
    {
      status: 210,
      sku: 'PM0078763',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311251',
      rtl_batch_array: '6311251',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '125150C4',
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      sppl_stock_available: 1490,
      chnn_stock_retail: 1490,
      sppl_prcp: 4.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'GYVSBRZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYVSBRZB'
    },
    {
      status: 210,
      sku: 'PM0078764',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311253',
      rtl_batch_array: '6311253',
      name_search: 'CHJSARGE',
      name: "Chaenomeles japonica 'Sargentii'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4482,
      chnn_stock_retail: 4482,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VV69BPYW;ZK3RYVLX;763KY6R4;LWNSYZVV;NSDKJDCD',
      statusContent: 'dot',
      hash: '8D13PWGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8D13PWGL'
    },
    {
      status: 210,
      sku: 'PM0066720',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174839',
      rtl_batch_array: '6174839',
      name_search: 'GEJOY',
      name: "Geranium 'Joy'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6S7GSB28',
      statusContent: 'dot',
      hash: 'YJYZWXJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJYZWXJC'
    },
    {
      status: 210,
      sku: 'PM0078765',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311255',
      rtl_batch_array: '6311255',
      name_search: 'CHSJTRAI',
      name: "Chaenomeles superba 'Jet Trail'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2599,
      chnn_stock_retail: 2599,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6LGYLPL8;78SXWW53;7A721S9T;2GSSWH84;WDC9NHHH',
      statusContent: 'dot',
      hash: '9T419CAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T419CAR'
    },
    {
      status: 210,
      sku: 'PM0065781',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 2,
      btch_active_retail: '6378409',
      rtl_batch_array: '6378244, 6378409',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1870,
      chnn_stock_retail: 3870,
      sppl_prcp: 5.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: 'J4639JRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4639JRW'
    },
    {
      status: 810,
      sku: 'PM0065772',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280850',
      rtl_batch_array: '6280850',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.448,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '4A4J35PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4A4J35PV'
    },
    {
      status: 210,
      sku: 'PM0081888',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6321904',
      rtl_batch_array: '6349066, 6321904, 6348971',
      name_search: 'CYSCOLYM',
      name: 'Cynara scolymus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1133,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3N327H9;R4TWPCN7;1CCPT5C5;BTGNPSAK;KJK3K556;ZX4EYNE7;R76AH2LC;H6S42Y8J;CEZWHXPE;1HB8C699;E31A8XKP;8NHYY1H3;S2YP2KKD;J3WEAGRJ;AR29SDNB;PTR3L3KH;CXBY97NX;JPG3CTGP;W2DZKN47',
      statusContent: 'dot',
      hash: 'APK6H5BJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APK6H5BJ'
    },
    {
      status: 210,
      sku: 'PM0059278',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184021',
      rtl_batch_array: '6184021',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1H2ZZ32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1H2ZZ32'
    },
    {
      status: 210,
      sku: 'PM0078766',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311256',
      rtl_batch_array: '6311256',
      name_search: 'CHSPTRAI',
      name: 'Chaenomeles superba Pink Trail',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B2ZZEVY9;DJ5X34L6;8PYATY2R;7K569C86;NG983Y8Y;4A4R88WY;D51KG3GN;ZBC2KSLZ',
      statusContent: 'dot',
      hash: 'BXS3G9P7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXS3G9P7'
    },
    {
      status: 210,
      sku: 'PM0085412',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378037',
      rtl_batch_array: '6378037',
      name_search: 'PIMCARST',
      name: "Pinus mugo 'Carsten'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 1084,
      chnn_stock_retail: 1084,
      sppl_prcp: 10.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NYTBG6XN;ZD15G5E3;85XY1CC2;5XA9YHVS',
      statusContent: 'dot',
      hash: 'YXVLXCCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXVLXCCT'
    },
    {
      status: 210,
      sku: 'PM0078767',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311257',
      rtl_batch_array: '6311257',
      name_search: 'CHSRTRAI',
      name: "Chaenomeles superba 'Red Trail'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1170,
      chnn_stock_retail: 1170,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6W2B9A69;V13VDL29;GSKE2JLZ;RVTERP6S;RJD8L165',
      statusContent: 'dot',
      hash: 'LZ1GDWXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ1GDWXL'
    },
    {
      status: 210,
      sku: 'PM0078634',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310352',
      rtl_batch_array: '6310352',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 639,
      chnn_stock_retail: 639,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '5A2TSSR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5A2TSSR9'
    },
    {
      status: 210,
      sku: 'PM0068124',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329977',
      rtl_batch_array: '6329977, 6193637',
      name_search: 'JUEFFUSU',
      name: 'Juncus effusus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 223,
      chnn_stock_retail: 1191,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6JYZBCA4;YRKGPRPA;GR1BKBCT;YELBYZZL;KSZDYWJ4;4KPBDRTN;PKK2D51X',
      statusContent: 'dot',
      hash: '7VG9B92N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VG9B92N'
    },
    {
      status: 210,
      sku: 'PM0078768',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311258',
      rtl_batch_array: '6311258',
      name_search: 'CHSTSCAR',
      name: "Chaenomeles superba 'Texas Scarlet'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2050,
      chnn_stock_retail: 2050,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E47XA132',
      statusContent: 'dot',
      hash: '7HARCAEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HARCAEK'
    },
    {
      status: 210,
      sku: 'PM0078769',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311259',
      rtl_batch_array: '6311259',
      name_search: 'CLAHUMMI',
      name: "Clethra alnifolia 'Hummingbird'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1707,
      chnn_stock_retail: 1707,
      sppl_prcp: 2.618,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LELV52NG;HA9RX2KC;HEHB17GC;DJK235R9',
      statusContent: 'dot',
      hash: 'XW47AYTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW47AYTY'
    },
    {
      status: 210,
      sku: 'PM0068110',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193604',
      rtl_batch_array: '6193604',
      name_search: 'ELPALUST',
      name: 'Eleocharis palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 971,
      chnn_stock_retail: 971,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GWHRZN6G;YNLV6CC7',
      statusContent: 'dot',
      hash: '2HDLBY3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HDLBY3T'
    },
    {
      status: 210,
      sku: 'PM0081889',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348977',
      rtl_batch_array: '6380812, 6348977',
      name_search: 'GESHEIDY',
      name: "Geranium 'Sweet Heidy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 390,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7VYLZD4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VYLZD4Y'
    },
    {
      status: 210,
      sku: 'PM0078640',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310358',
      rtl_batch_array: '6310358',
      name_search: 'QUROBUR',
      name: 'Quercus robur',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 1372,
      chnn_stock_retail: 1372,
      sppl_prcp: 5.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZ2WHRDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZ2WHRDX'
    },
    {
      status: 210,
      sku: 'PM0078770',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311263',
      rtl_batch_array: '6311263',
      name_search: 'COASPEAR',
      name: "Cornus alba 'Siberian Pearls'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1734,
      chnn_stock_retail: 1734,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4SNEPDZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SNEPDZY'
    },
    {
      status: 210,
      sku: 'PM0066717',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174835',
      rtl_batch_array: '6174835',
      name_search: 'DIGRANDI',
      name: 'Digitalis grandiflora',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1483,
      chnn_stock_retail: 1483,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YDGN3TWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDGN3TWA'
    },
    {
      status: 210,
      sku: 'PM0078637',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320184',
      rtl_batch_array: '6320184, 6310355',
      name_search: 'PRSPINOS',
      name: 'Prunus spinosa',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1892,
      chnn_stock_retail: 3734,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NNAGJ3G;HCLHBHJR;ACJT86AY;ZCDXKXH2;9YTBC1EC;Y994TXX8;49PWEAKR;BVNEB8A6;P6BZ84H3;564BYGKH;NT3ZJDWL;1GYEV4PZ;74VT253K;28PJD3E1;9XPT87KW;236N7DA1;8XKL4D8S;ZNGC59GE;R474ZWDV;X45CPXEL;DGX6S9DL;RG5KAWSY',
      statusContent: 'dot',
      hash: '2HCP1PGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HCP1PGA'
    },
    {
      status: 810,
      sku: 'PM0071213',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228435',
      rtl_batch_array: '6228435',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 3.004,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '7NH5LZ8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NH5LZ8L'
    },
    {
      status: 210,
      sku: 'PM0068119',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193628',
      rtl_batch_array: '6193628',
      name_search: 'IRLAEVIG',
      name: 'Iris laevigata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4991RPCH;NW5DWHTA;ADH5ZDZL',
      statusContent: 'dot',
      hash: '53WGJAYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53WGJAYD'
    },
    {
      status: 210,
      sku: 'PM0081890',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348981',
      rtl_batch_array: '6371176, 6348981',
      name_search: 'GECTEMPE',
      name: 'Geum Coral Tempest',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 415,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LXW6YPB3;ZYYH6R3V;98DHP2CP;N9J89D5Y;WB6EHSWB;CZNCBJ6W',
      statusContent: 'dot',
      hash: 'TK238CT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TK238CT6'
    },
    {
      status: 210,
      sku: 'PM0085413',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378161',
      rtl_batch_array: '6378161',
      name_search: 'LOCFDANC',
      name: "Loropetalum chinense 'Fire Dance'",
      sppl_size_code: '015020C13',
      rng_range_identifier: 'H015020C1.3',
      rng_range_description: 'H15 cm 20 cm C1.3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7PEY731L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PEY731L'
    },
    {
      status: 210,
      sku: 'PM0085414',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378162',
      rtl_batch_array: '6378162',
      name_search: 'LOCERED',
      name: 'Loropetalum chinense Ever Red',
      sppl_size_code: '015020C13',
      rng_range_identifier: 'H015020C1.3',
      rng_range_description: 'H15 cm 20 cm C1.3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LTHVJN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LTHVJN7'
    },
    {
      status: 210,
      sku: 'PM0071220',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228442',
      rtl_batch_array: '6228442',
      name_search: 'ILAJTOL',
      name: "Ilex aquifolium 'J.C. van Tol'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 678,
      chnn_stock_retail: 678,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CVLG67DE;B42AGHSH;2PBD56VG;8NRC3DRJ;9CSJSL6G;PY213WDA;5952BJHK;EELRV6HZ',
      statusContent: 'dot',
      hash: 'DHXXDR8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHXXDR8N'
    },
    {
      status: 210,
      sku: 'PM0071215',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228437',
      rtl_batch_array: '6228437',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 2.702,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'EWS3W83L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWS3W83L'
    },
    {
      status: 210,
      sku: 'PM0071218',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228440',
      rtl_batch_array: '6228440',
      name_search: 'ILAALASK',
      name: "Ilex aquifolium 'Alaska'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 423,
      chnn_stock_retail: 423,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZCK3Z7A;YGGASRN8;KTLGECKH;RHJP88XY;929N1BNB;P8JVW1RC;PY7B1HE1;A395L6HY;X2Z7CNL6;CEB5DR44;VBT7XPTG;NEV587VA;CS8HW7TW;LRHE52TP;XJB2NTVZ',
      statusContent: 'dot',
      hash: 'B19EEGVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B19EEGVY'
    },
    {
      status: 210,
      sku: 'PM0071219',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228441',
      rtl_batch_array: '6228441',
      name_search: 'ILAAMARG',
      name: "Ilex aquifolium 'Argentea Marginata'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '94HJREXC;7R7Z66N7;E27LV4Y8;83ASHZG1;XEKYZ74N;CHV3A266;BTANHGXY;6PLKRZSY;YNSCWSGC;X9D1BKV8;D7DTAEZX;W72YL83S',
      statusContent: 'dot',
      hash: '5255NHA5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5255NHA5'
    },
    {
      status: 210,
      sku: 'PM0071223',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228447',
      rtl_batch_array: '6228447',
      name_search: 'ILECGGEM',
      name: "Ilex crenata 'Golden Gem'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_prcp: 2.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6H1T152;ACELZCTX;L8LWT5T5;DNH7B45X;W1E88G6R;PWBSSNSK;H37PG1Y5;BES2N1AV;1WTGN263;4KJBZN66',
      statusContent: 'dot',
      hash: 'RZG3T8Y4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZG3T8Y4'
    },
    {
      status: 210,
      sku: 'PM0081891',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348982',
      rtl_batch_array: '6348982',
      name_search: 'GEFTEMP',
      name: "Geum 'Fiery Tempest'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '99N3J9JW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99N3J9JW'
    },
    {
      status: 210,
      sku: 'PM0085415',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378163',
      rtl_batch_array: '6378163',
      name_search: 'LOCRSNOW',
      name: 'Loropetalum chinense Ruby Snow',
      sppl_size_code: '015020C13',
      rng_range_identifier: 'H015020C1.3',
      rng_range_description: 'H15 cm 20 cm C1.3',
      sppl_stock_available: 5500,
      chnn_stock_retail: 5500,
      sppl_order_minimum: 3,
      sppl_prcp: 4.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VADZTLKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VADZTLKZ'
    },
    {
      status: 210,
      sku: 'PM0081892',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348984',
      rtl_batch_array: '6348984',
      name_search: 'GETFRUTT',
      name: "Geum 'Tutti Frutti'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PX1DHHZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PX1DHHZB'
    },
    {
      status: 210,
      sku: 'PM0052976',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280171',
      rtl_batch_array: '6280171',
      name_search: 'POLRTIP',
      name: "Podocarpus lawrencei 'Red Tip'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 326,
      chnn_stock_retail: 326,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H1Z3VAYG;W5LY52K8;1N4LVHZ7;LPY7GLE3;VZE8KTD5',
      statusContent: 'dot',
      hash: '6XYW7846',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XYW7846'
    },
    {
      status: 210,
      sku: 'PM0066712',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174830',
      rtl_batch_array: '6174830',
      name_search: 'SANMAINA',
      name: "Salvia nemorosa 'Mainacht'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 10504,
      chnn_stock_retail: 10504,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6PB4STH;R7P83E3T',
      statusContent: 'dot',
      hash: 'RCHYP1C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCHYP1C3'
    },
    {
      status: 210,
      sku: 'PM0065187',
      core_name: 'Plant',
      sppl_ean: '8720664872165',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161812',
      rtl_batch_array: '6161812',
      name_search: 'LEBLACE',
      name: 'Leucanthemum (S) Belgian Lace',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '17JGHWKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17JGHWKL'
    },
    {
      status: 810,
      sku: 'PM0078627',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310343',
      rtl_batch_array: '6310343',
      name_search: 'POFGOLDT',
      name: "Potentilla fruticosa 'Goldteppich'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPG1YEWB',
      statusContent: 'dot',
      hash: 'WY8HLT1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WY8HLT1R'
    },
    {
      status: 210,
      sku: 'PM0078639',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310357',
      rtl_batch_array: '6310357',
      name_search: 'QUROBUR',
      name: 'Quercus robur',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1645,
      chnn_stock_retail: 1645,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SZGAA71N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZGAA71N'
    },
    {
      status: 210,
      sku: 'PM0085416',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378228',
      rtl_batch_array: '6378228',
      name_search: 'BUDBCREA',
      name: 'Buddleja davidii Berries & Cream',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 5.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SW81GSW4;Y38WK3LS;98CEJ9P9;S12EZ3SV;C8V96LRP',
      statusContent: 'dot',
      hash: '23N76Y7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23N76Y7X'
    },
    {
      status: 210,
      sku: 'PM0071221',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228445',
      rtl_batch_array: '6228445',
      name_search: 'ILCCGOLD',
      name: "Ilex crenata 'Convexed Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 977,
      chnn_stock_retail: 977,
      sppl_order_minimum: 3,
      sppl_prcp: 2.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BYKS7D8B;1KA5253W',
      statusContent: 'dot',
      hash: '6L2AXYR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L2AXYR1'
    },
    {
      status: 210,
      sku: 'PM0066721',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174840',
      rtl_batch_array: '6174840',
      name_search: 'GEVERSIC',
      name: 'Geranium versicolor',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2410,
      chnn_stock_retail: 2410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GL8KK64V;YZTVLP64',
      statusContent: 'dot',
      hash: 'PSK3T6NL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSK3T6NL'
    },
    {
      status: 210,
      sku: 'PM0071222',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228446',
      rtl_batch_array: '6228446',
      name_search: 'ILCCGOLD',
      name: "Ilex crenata 'Convexed Gold'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_prcp: 3.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BYKS7D8B;1KA5253W',
      statusContent: 'dot',
      hash: 'Y55XN7XX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y55XN7XX'
    },
    {
      status: 210,
      sku: 'PM0048403',
      core_name: 'Plant',
      sppl_ean: '8720349468683',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015436',
      rtl_batch_array: '6015436',
      name_search: 'PEAPPIG',
      name: "Pennisetum alopecuroides 'Peppa Pig'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7BW87AXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BW87AXR'
    },
    {
      status: 210,
      sku: 'PM0010398',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370350',
      rtl_batch_array: '6207948, 6370350',
      name_search: 'ERBMAUVE',
      name: "Erysimum 'Bowles' Mauve'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 273,
      chnn_stock_retail: 379,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E77TACCP;R457CNE9;ZJ4GB27E;9XL459H3;9X6XTVK8;75B2NNVH;6BZS1JAY;CK9VZ638;CNACP5D4;1YL1RP9E;BCBNCCW6;2BN8ARP7',
      statusContent: 'dot',
      hash: '7C5ES727',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7C5ES727'
    },
    {
      status: 210,
      sku: 'PM0078762',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311205',
      rtl_batch_array: '6311205',
      name_search: 'POTNUUK',
      name: "Potentilla tridentata 'Nuuk'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 13682,
      chnn_stock_retail: 13682,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5VGJ8ZB;K4K9K2E3',
      statusContent: 'dot',
      hash: 'TT52RRJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TT52RRJ3'
    },
    {
      status: 210,
      sku: 'PM0085417',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378229',
      rtl_batch_array: '6378229',
      name_search: 'BUFPEACE',
      name: 'Buddleja Flutterby Peace',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7JS14PVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JS14PVS'
    },
    {
      status: 210,
      sku: 'PM0031816',
      core_name: 'Plant',
      sppl_ean: '8720664865488',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084951',
      rtl_batch_array: '6084951, 6208000',
      name_search: 'GYRFWHIT',
      name: "Gypsophila repens 'Filou White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 717,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6XW1L2GJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XW1L2GJ'
    },
    {
      status: 210,
      sku: 'PM0085418',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378230',
      rtl_batch_array: '6378230',
      name_search: 'BUWSUNGO',
      name: "Buddleja weyeriana 'Sungold'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 659,
      chnn_stock_retail: 659,
      sppl_prcp: 3.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B84PXPB;KETH4VA6',
      statusContent: 'dot',
      hash: 'DG6YKR64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG6YKR64'
    },
    {
      status: 210,
      sku: 'PM0078757',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311189',
      rtl_batch_array: '6311189',
      name_search: 'PHMHERMI',
      name: "Philadelphus 'Manteau d'Hermine'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59LL5L5C',
      statusContent: 'dot',
      hash: 'SH5WPCHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SH5WPCHJ'
    },
    {
      status: 210,
      sku: 'PM0065224',
      core_name: 'Plant',
      sppl_ean: '8720664882911',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161916',
      rtl_batch_array: '6161916',
      name_search: 'PUSPDAWN',
      name: "Pulmonaria saccharata 'Pink Dawn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W14BLHNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W14BLHNZ'
    },
    {
      status: 210,
      sku: 'PM0085419',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378318',
      rtl_batch_array: '6378318',
      name_search: 'DAODORA',
      name: 'Daphne odora',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_prcp: 4.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4GSCAB89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GSCAB89'
    },
    {
      status: 210,
      sku: 'PM0085420',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378342',
      rtl_batch_array: '6378342',
      name_search: 'HEHEMMA',
      name: 'Hebe Hebedonna Emma',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y6ZW9712',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6ZW9712'
    },
    {
      status: 210,
      sku: 'PM0078759',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311196',
      rtl_batch_array: '6311196',
      name_search: 'POFJVARI',
      name: "Potentilla fruticosa 'Jackman's Variety'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1750,
      chnn_stock_retail: 1750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '67YXK97V;5DEA9R3B;773WYXS7',
      statusContent: 'dot',
      hash: 'AAJDDDXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAJDDDXX'
    },
    {
      status: 810,
      sku: 'PM0054912',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346727',
      rtl_batch_array: '6346727',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '045050C15BO',
      rng_range_identifier: 'BALL\u00d8050H045050C15',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm C15',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 19.778,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'TJS1V3CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJS1V3CW'
    },
    {
      status: 210,
      sku: 'PM0065780',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280769',
      rtl_batch_array: '6280769',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1856,
      chnn_stock_retail: 1856,
      sppl_prcp: 5.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: 'TBYD4695',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBYD4695'
    },
    {
      status: 210,
      sku: 'PM0085421',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378369',
      rtl_batch_array: '6378369',
      name_search: 'ILVERTIC',
      name: 'Ilex verticillata',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 10.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LHVTGWTX;88XDXTNC;EHX4XTNA;XEB8ZRNL;ETPTGTE7;LH7AYCSV;RTGZ2V3E;PS9T1KHP;7EJVD8G3;XEERY7HR;48WV18V8',
      statusContent: 'dot',
      hash: 'E1GDHWPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1GDHWPW'
    },
    {
      status: 810,
      sku: 'PM0085422',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378382',
      rtl_batch_array: '6378382',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '100STC5',
      rng_range_identifier: 'STE100C5',
      rng_range_description: 'Stem 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'TGKTDLZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGKTDLZJ'
    },
    {
      status: 210,
      sku: 'PM0065318',
      core_name: 'Plant',
      sppl_ean: '8720664879256',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162709',
      rtl_batch_array: '6162709',
      name_search: 'PIOKAREL',
      name: "Picea omorika 'Karel'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 771,
      chnn_stock_retail: 771,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NXHZTRBZ;8VZS1GHD;EJEYG6L3;PLHVCD9C;RW7HTEKW;VTD61YTH;JBJAK28J;PY5R216V;JA2WW5ST;933V57AW;AJ9932NX;T8L8AGEZ;ZXKSBX6R;AX8JYLP4;GJWGXNJ9;EYRE3PTN',
      statusContent: 'dot',
      hash: 'ZRLS9471',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRLS9471'
    },
    {
      status: 210,
      sku: 'PM0069539',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349121',
      rtl_batch_array: '6349121, 6370367',
      name_search: 'HYOFFICI',
      name: 'Hyssopus officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5006,
      chnn_stock_retail: 6455,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LD35SZ49;GK2E2RS7;H6DNLBXY;136LHVLL;599V1L3G;NCSTW2E3;2DS23PPT;7X7CH55V;7KZETV4G;VGBADH1E;ERBV6EPN;PE6CB7Y1;91HRG21A;8N5H4JJP;4JLLRS8C;BD9B2BEP;7KC3EHSV;KXVCWK8E;KYV2GRA4;JGYVNNZC',
      statusContent: 'dot',
      hash: 'C78HNZRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C78HNZRE'
    },
    {
      status: 210,
      sku: 'PM0065327',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244387',
      rtl_batch_array: '6244387',
      name_search: 'TABOVERE',
      name: "Taxus baccata 'Overeynderi'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 612,
      chnn_stock_retail: 612,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RH718G7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RH718G7J'
    },
    {
      status: 210,
      sku: 'PM0068155',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193706',
      rtl_batch_array: '6193706',
      name_search: 'ACCVARIE',
      name: "Acorus calamus 'Variegatus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.377,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '99J326KZ;86G111GC;2VJA8ANN;ZJ4P5S33;CL7A4G54;KLN9S8SE',
      statusContent: 'dot',
      hash: 'ABT16WHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ABT16WHD'
    },
    {
      status: 210,
      sku: 'PM0066100',
      core_name: 'Plant',
      sppl_ean: '8720664858824',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170257',
      rtl_batch_array: '6170257',
      name_search: 'COLUCIDU',
      name: 'Cotoneaster lucidus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPJW8JTN',
      statusContent: 'dot',
      hash: 'K55AG8K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K55AG8K1'
    },
    {
      status: 210,
      sku: 'PM0069538',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350524',
      rtl_batch_array: '6350524, 6291343, 6309003',
      name_search: 'HORSTAND',
      name: "Hosta 'Royal Standard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1776,
      chnn_stock_retail: 3661,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X2VR7RS1;A8E8312R;J74WY77E;VPPTPBGX;ABP42BP4;JC9TSSAV;GP24C1HV;9JTWLBEH;5RG2913H;D3ZP5PZ3;W346T4AK;7E8D7DL7;DZRB18BY;6CJT3LL4;BY7E1LWV;9ZG8RYJ2',
      statusContent: 'dot',
      hash: '59YT66CV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59YT66CV'
    },
    {
      status: 210,
      sku: 'PM0069535',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301662',
      rtl_batch_array: '6301662',
      name_search: 'HOKREGAL',
      name: "Hosta 'Krossa Regal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 508,
      chnn_stock_retail: 508,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNTYRWWH;EL1XKP5T;TJ243EYX',
      statusContent: 'dot',
      hash: 'Y55JWZVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y55JWZVK'
    },
    {
      status: 210,
      sku: 'PM0069544',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350558',
      rtl_batch_array: '6350558',
      name_search: 'INORIENT',
      name: 'Inula orientalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 354,
      chnn_stock_retail: 354,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKAEXB1Y',
      statusContent: 'dot',
      hash: 'T1T5Y4WS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1T5Y4WS'
    },
    {
      status: 210,
      sku: 'PM0078643',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310361',
      rtl_batch_array: '6310361',
      name_search: 'RHCATHAR',
      name: 'Rhamnus cathartica',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1282,
      chnn_stock_retail: 1282,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XS7LHYE;B42JD4B2;LC374DCT;6GBV282T;8HZV1SA1;JD7RRX9A',
      statusContent: 'dot',
      hash: 'JV9B8192',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV9B8192'
    },
    {
      status: 210,
      sku: 'PM0078644',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310362',
      rtl_batch_array: '6310362',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: '4T49XLYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T49XLYE'
    },
    {
      status: 910,
      sku: 'PM0078645',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'A28BA7EK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A28BA7EK'
    },
    {
      status: 210,
      sku: 'PM0078646',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310364',
      rtl_batch_array: '6310364',
      name_search: 'ROPSEUDO',
      name: 'Robinia pseudoacacia',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_prcp: 1.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NG2L1A3X;H9P852CE;Z1LKWC3A;RNPBZNL4',
      statusContent: 'dot',
      hash: 'PYN6ZA8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYN6ZA8L'
    },
    {
      status: 210,
      sku: 'PM0085423',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378454',
      rtl_batch_array: '6378454',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9112,
      chnn_stock_retail: 9112,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'LVBW66E2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVBW66E2'
    },
    {
      status: 210,
      sku: 'PM0078647',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310367',
      rtl_batch_array: '6310367',
      name_search: 'ROMULTIF',
      name: 'Rosa multiflora',
      sppl_size_code: '060100C2',
      rng_range_identifier: 'H060100C2',
      rng_range_description: 'H60 cm 100 cm C2',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2492,
      chnn_stock_retail: 2492,
      sppl_prcp: 1.422,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '14R5V599;WV18AJV2;4V124LSE;Z6YLBD6R;96K2KJC7;W4RN42TX;C4N5CYKY',
      statusContent: 'dot',
      hash: 'YYY8TE1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYY8TE1Y'
    },
    {
      status: 210,
      sku: 'PM0078648',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310368',
      rtl_batch_array: '6310368',
      name_search: 'RONITIDA',
      name: 'Rosa nitida',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1065,
      chnn_stock_retail: 1065,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R7RG1LTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7RG1LTC'
    },
    {
      status: 210,
      sku: 'PM0078649',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310373',
      rtl_batch_array: '6310373',
      name_search: 'ROPIMPIN',
      name: 'Rosa pimpinellifolia',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_prcp: 4.877,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '486Z1YPP;4V9J4RHE;SABDCKDZ;KC4YCDBT;K27V5R5H',
      statusContent: 'dot',
      hash: 'G1S9W4G7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1S9W4G7'
    },
    {
      status: 210,
      sku: 'PM0068257',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196552',
      rtl_batch_array: '6196552',
      name_search: 'PIGAGLOB',
      name: "Picea glauca 'Alberta Globe'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1999,
      chnn_stock_retail: 1999,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV3TNY1Y;SYL9ZJWZ;WBYGXE4E;2D7SNGX3;8BXW263X;52R49AND;8174AN7A',
      statusContent: 'dot',
      hash: 'CG3J6BN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CG3J6BN4'
    },
    {
      status: 210,
      sku: 'PM0085424',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379066',
      rtl_batch_array: '6379066',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '04006010',
      rng_range_identifier: 'H040060BRSeedA1+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+0',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.179,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: '6DP38N56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DP38N56'
    },
    {
      status: 210,
      sku: 'PM0068143',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193682',
      rtl_batch_array: '6193682',
      name_search: 'SCHCALBA',
      name: "Schizostylis coccinea 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJ1P8LBD;H85RGD6L',
      statusContent: 'dot',
      hash: '5LZAEZYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LZAEZYB'
    },
    {
      status: 210,
      sku: 'PM0078650',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310374',
      rtl_batch_array: '6310374',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: 'PSY9YT64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSY9YT64'
    },
    {
      status: 210,
      sku: 'PM0078771',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311265',
      rtl_batch_array: '6311265',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'Z4DS8S5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4DS8S5L'
    },
    {
      status: 210,
      sku: 'PM0071225',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228449',
      rtl_batch_array: '6228449',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_prcp: 1.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T22JLBZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T22JLBZD'
    },
    {
      status: 210,
      sku: 'PM0085425',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379067',
      rtl_batch_array: '6379067',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '04006020',
      rng_range_identifier: 'H040060BRSeedA2+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 2+0',
      sppl_stock_available: 20000,
      chnn_stock_retail: 20000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.251,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '6PA1VEAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PA1VEAP'
    },
    {
      status: 210,
      sku: 'PM0079003',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314185',
      rtl_batch_array: '6314185',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 4743,
      chnn_stock_retail: 4743,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: 'SPJGV7YY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPJGV7YY'
    },
    {
      status: 210,
      sku: 'PM0078773',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311279',
      rtl_batch_array: '6311279',
      name_search: 'CODSCHOO',
      name: "Cotoneaster dammeri 'Schoon'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8280,
      chnn_stock_retail: 8280,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CY6P8Y8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CY6P8Y8A'
    },
    {
      status: 210,
      sku: 'PM0081893',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348989',
      rtl_batch_array: '6348989',
      name_search: 'HEBTIMEL',
      name: "Heuchera 'Berry Timeless'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VL336RNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL336RNS'
    },
    {
      status: 210,
      sku: 'PM0078774',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311280',
      rtl_batch_array: '6311280',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2520,
      chnn_stock_retail: 2520,
      sppl_order_minimum: 3,
      sppl_prcp: 0.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'JTP5XZZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTP5XZZE'
    },
    {
      status: 210,
      sku: 'PM0065321',
      core_name: 'Plant',
      sppl_ean: '8720664887893',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162713',
      rtl_batch_array: '6162713',
      name_search: 'TABCAPPE',
      name: "Taxus baccata 'Cappenberg'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 4.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7725WD6K',
      statusContent: 'dot',
      hash: 'X5TDVR57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5TDVR57'
    },
    {
      status: 210,
      sku: 'PM0078775',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311307',
      rtl_batch_array: '6311307',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2750,
      chnn_stock_retail: 2750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      statusContent: 'dot',
      hash: 'Y9NC819A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9NC819A'
    },
    {
      status: 210,
      sku: 'PM0078776',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300411',
      rtl_batch_array: '6300411',
      name_search: 'ALSELBE',
      name: "Allium schoenop. 'Elbe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2AAZY7RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AAZY7RA'
    },
    {
      status: 210,
      sku: 'PM0078994',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314176',
      rtl_batch_array: '6314176',
      name_search: 'ACPELACE',
      name: "Acer palmatum 'Emerald Lace'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 6493,
      chnn_stock_retail: 6493,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S4ZEJ9DK;CX5HD27D;ZXTRHS5R;Z1XPGBZ5;NVY1RZDE',
      statusContent: 'dot',
      hash: '5E49NK3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5E49NK3N'
    },
    {
      status: 210,
      sku: 'PM0078995',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314177',
      rtl_batch_array: '6314177',
      name_search: 'ACPELACE',
      name: "Acer palmatum 'Emerald Lace'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S4ZEJ9DK;CX5HD27D;ZXTRHS5R;Z1XPGBZ5;NVY1RZDE',
      statusContent: 'dot',
      hash: '17HX7TN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17HX7TN7'
    },
    {
      status: 210,
      sku: 'PM0068223',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193795',
      rtl_batch_array: '6193795',
      name_search: 'JUESPIRA',
      name: "Juncus effusus 'Spiralis'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JERJ2D94;BGJDZ76B;T94V9DA8;599D3CZK;DCPARL8Y;BGG3PLZ8;X1592N28;WL13VJJD;S7DWXAT8;6VW9J7E3;R86NC3KD;HXSSKRZ5;YPBAV6WC;RC1XSYJ1;1RSKYARL;6SZ1YLY4;T7BDZ8N7;4YG3VHR5;AAVZCEH3;TXGVY6DB;6RYBCJK8;AY5PADBJ;PYGR4VGZ;795LA34G;V82P8N63',
      statusContent: 'dot',
      hash: 'LVNH46YY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVNH46YY'
    },
    {
      status: 210,
      sku: 'PM0065326',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244386',
      rtl_batch_array: '6244386',
      name_search: 'TABOVERE',
      name: "Taxus baccata 'Overeynderi'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPAZ45ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPAZ45ZH'
    },
    {
      status: 810,
      sku: 'PM0078997',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314179',
      rtl_batch_array: '6314179',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'N6K4CR64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6K4CR64'
    },
    {
      status: 210,
      sku: 'PM0085426',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379068',
      rtl_batch_array: '6379068',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '06008020',
      rng_range_identifier: 'H060080BRA2+0',
      rng_range_description: 'H60 cm 80 cm Bare root 2+0',
      sppl_stock_available: 20000,
      chnn_stock_retail: 20000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'R4ECAEKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4ECAEKW'
    },
    {
      status: 210,
      sku: 'PM0078998',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314180',
      rtl_batch_array: '6314180',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 662,
      chnn_stock_retail: 662,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: '62CH373E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62CH373E'
    },
    {
      status: 910,
      sku: 'PM0078999',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314181',
      rtl_batch_array: '6314181',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: 'TR1L84K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR1L84K2'
    },
    {
      status: 210,
      sku: 'PM0079000',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314182',
      rtl_batch_array: '6314182',
      name_search: 'ACPJSCHW',
      name: "Acer palmatum 'Jerre Schwartz'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYSD56WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYSD56WE'
    },
    {
      status: 210,
      sku: 'PM0057852',
      core_name: 'Plant',
      sppl_ean: '8720349472284',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '5902143',
      rtl_batch_array: '5902143',
      name_search: 'HEHWRIPP',
      name: "Hedera helix 'White Ripple'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CB11SKW;L5BRSNDS;XR9DVZX4;LEYG7KJ5',
      statusContent: 'dot',
      hash: '8R89S155',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8R89S155'
    },
    {
      status: 210,
      sku: 'PM0066770',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267533',
      rtl_batch_array: '6267533',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_prcp: 10.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'TVXVA3LJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVXVA3LJ'
    },
    {
      status: 210,
      sku: 'PM0085427',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379069',
      rtl_batch_array: '6379069',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '10012520',
      rng_range_identifier: 'H100125BRA2+0',
      rng_range_description: 'H100 cm 125 cm Bare root A2+0',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'AW1XXV5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AW1XXV5B'
    },
    {
      status: 210,
      sku: 'PM0065424',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6176199',
      rtl_batch_array: '6176199, 6164408, 6355381',
      name_search: 'PHCSTRIP',
      name: 'Phlox (S) Candy Stripes',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1003,
      chnn_stock_retail: 2262,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4Z281VAV;TETBH8GH;WHWCYWHG;WLZLNL76;NKL54L69;NBJ2AYG4;8DZ61RGS;KN21ZDRW;8YYWGJ2W;2SHRP78K',
      statusContent: 'dot',
      hash: '8RHK1N4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RHK1N4B'
    },
    {
      status: 210,
      sku: 'PM0079001',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314183',
      rtl_batch_array: '6314183',
      name_search: 'ACPJSCHW',
      name: "Acer palmatum 'Jerre Schwartz'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TPZ4L4ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPZ4L4ZS'
    },
    {
      status: 210,
      sku: 'PM0085428',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379070',
      rtl_batch_array: '6379070',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '04006010',
      rng_range_identifier: 'H040060BRSeedA1+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+0',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'WZS9ZPSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZS9ZPSV'
    },
    {
      status: 210,
      sku: 'PM0085429',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379071',
      rtl_batch_array: '6379071',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '06010010',
      rng_range_identifier: 'H060100BRSeedA1+0',
      rng_range_description: 'H60 cm 100 cm Bare root Seed 1+0',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: '8NJR7LA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NJR7LA2'
    },
    {
      status: 210,
      sku: 'PM0065322',
      core_name: 'Plant',
      sppl_ean: '8720664887909',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162714',
      rtl_batch_array: '6162714',
      name_search: 'TABCAPPE',
      name: "Taxus baccata 'Cappenberg'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 373,
      chnn_stock_retail: 373,
      sppl_prcp: 4.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7725WD6K',
      statusContent: 'dot',
      hash: 'ZHRY5X48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHRY5X48'
    },
    {
      status: 210,
      sku: 'PM0085430',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379072',
      rtl_batch_array: '6379072',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '04006010',
      rng_range_identifier: 'H040060BRSeedA1+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+0',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'HP5Y4VC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP5Y4VC2'
    },
    {
      status: 210,
      sku: 'PM0085431',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379073',
      rtl_batch_array: '6379073',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '06010010',
      rng_range_identifier: 'H060100BRSeedA1+0',
      rng_range_description: 'H60 cm 100 cm Bare root Seed 1+0',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'R8LJZL16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8LJZL16'
    },
    {
      status: 810,
      sku: 'PM0079008',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314191',
      rtl_batch_array: '6314191',
      name_search: 'ACPPHOEN',
      name: "Acer palmatum 'Phoenix'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAXE5AJC',
      statusContent: 'dot',
      hash: 'X9T6SHAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9T6SHAC'
    },
    {
      status: 810,
      sku: 'PM0079011',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314194',
      rtl_batch_array: '6314194',
      name_search: 'ACPRPYGM',
      name: "Acer palmatum 'Red Pygmy'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2P5EAZ6X',
      statusContent: 'dot',
      hash: 'NN2HEC9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN2HEC9T'
    },
    {
      status: 210,
      sku: 'PM0074296',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269469',
      rtl_batch_array: '6269469',
      name_search: 'POFSNOWF',
      name: "Potentilla fruticosa 'Snowflake'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SL5KBXG',
      statusContent: 'dot',
      hash: 'Z9B9V4CA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9B9V4CA'
    },
    {
      status: 210,
      sku: 'PM0079013',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314196',
      rtl_batch_array: '6314196',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: '5B1TCC32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B1TCC32'
    },
    {
      status: 210,
      sku: 'PM0079014',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314197',
      rtl_batch_array: '6314197',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 951,
      chnn_stock_retail: 951,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: '41VLT2GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41VLT2GK'
    },
    {
      status: 210,
      sku: 'PM0079015',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314198',
      rtl_batch_array: '6314198',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 748,
      chnn_stock_retail: 748,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'L2C8GSGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2C8GSGS'
    },
    {
      status: 210,
      sku: 'PM0079017',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314200',
      rtl_batch_array: '6314200',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1365,
      chnn_stock_retail: 1365,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: '29CJL7TC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29CJL7TC'
    },
    {
      status: 210,
      sku: 'PM0079007',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314190',
      rtl_batch_array: '6314190',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2649,
      chnn_stock_retail: 2649,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'CGG271LV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGG271LV'
    },
    {
      status: 210,
      sku: 'PM0085432',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379074',
      rtl_batch_array: '6379074',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '04006012',
      rng_range_identifier: 'H040060BRSeedA1+2',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 10,
      sppl_prcp: 0.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '2TZWGTVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TZWGTVJ'
    },
    {
      status: 810,
      sku: 'PM0048319',
      core_name: 'Plant',
      sppl_ean: '8720664861053',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147594',
      rtl_batch_array: '6147594',
      name_search: 'ECPSAFFA',
      name: "Echinacea purpurea 'Secret Affair'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YHCTTZ5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHCTTZ5V'
    },
    {
      status: 210,
      sku: 'PM0081894',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348992',
      rtl_batch_array: '6350379, 6348992',
      name_search: 'HEBPEARL',
      name: "Heuchera 'Black Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1223,
      chnn_stock_retail: 1479,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C1WC9RKT;231TK3HD;SYTNSP87;KDYW34J6;A6RVXL8Z;W41BK7PK;RLKNNRLB;KA9LHHPS;E1C31LPT',
      statusContent: 'dot',
      hash: '8BLVAZWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BLVAZWY'
    },
    {
      status: 210,
      sku: 'PM0079005',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314187',
      rtl_batch_array: '6314187',
      name_search: 'ACPLPRIN',
      name: "Acer palmatum 'Little Princess'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 9435,
      chnn_stock_retail: 9435,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VXJ9CKKW;59STYAKN',
      statusContent: 'dot',
      hash: '8H81V3GZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H81V3GZ'
    },
    {
      status: 210,
      sku: 'PM0058840',
      core_name: 'Plant',
      sppl_ean: '8720664859371',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6132963',
      rtl_batch_array: '6132963, 6236082',
      name_search: 'DEBKNIGH',
      name: "Delphinium (P) 'Black Knight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 312,
      chnn_stock_retail: 362,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDY4YACC;N9SS16PZ;82N8G81A;TRYN5EEB;7XHRDBPR;6X5EDLN8;KNAPVBPA;BVE2V7VK;P1EZSB4H;G2GR3D61',
      statusContent: 'dot',
      hash: '7N4Z2482',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N4Z2482'
    },
    {
      status: 210,
      sku: 'PM0085433',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379075',
      rtl_batch_array: '6379075',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '06008012',
      rng_range_identifier: 'H060080BRSeedA1+2',
      rng_range_description: 'H60 cm 80 cm Bare root Seed 1+2',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 5,
      sppl_prcp: 0.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '7N3DWATJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N3DWATJ'
    },
    {
      status: 210,
      sku: 'PM0069555',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350600',
      rtl_batch_array: '6350600',
      name_search: 'KNCAULES',
      name: 'Kniphofia caulescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 348,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WE1DBR4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WE1DBR4A'
    },
    {
      status: 210,
      sku: 'PM0079006',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314189',
      rtl_batch_array: '6314189',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 4971,
      chnn_stock_retail: 4971,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'NJ5DR15L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJ5DR15L'
    },
    {
      status: 210,
      sku: 'PM0085434',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379076',
      rtl_batch_array: '6379076',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '06008020',
      rng_range_identifier: 'H060080BRA2+0',
      rng_range_description: 'H60 cm 80 cm Bare root 2+0',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'HD6SZS6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HD6SZS6Z'
    },
    {
      status: 210,
      sku: 'PM0079009',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314192',
      rtl_batch_array: '6314192',
      name_search: 'ACPPHOEN',
      name: "Acer palmatum 'Phoenix'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 9260,
      chnn_stock_retail: 9260,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAXE5AJC',
      statusContent: 'dot',
      hash: 'VBT8SPA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBT8SPA9'
    },
    {
      status: 210,
      sku: 'PM0085435',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379077',
      rtl_batch_array: '6379077',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '08010012',
      rng_range_identifier: 'H080100BRA1+2',
      rng_range_description: 'H80 cm 100 cm Bare root 1+2',
      sppl_stock_available: 20000,
      chnn_stock_retail: 20000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'HG1DWZCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HG1DWZCD'
    },
    {
      status: 210,
      sku: 'PM0079010',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314193',
      rtl_batch_array: '6314193',
      name_search: 'ACPPHOEN',
      name: "Acer palmatum 'Phoenix'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAXE5AJC',
      statusContent: 'dot',
      hash: '9NLVWK2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NLVWK2L'
    },
    {
      status: 810,
      sku: 'PM0069542',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301668',
      rtl_batch_array: '6301668',
      name_search: 'IBSZWERG',
      name: "Iberis sempervirens 'Zwergschneeflocke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '48XSJ7XK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48XSJ7XK'
    },
    {
      status: 210,
      sku: 'PM0081895',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349001',
      rtl_batch_array: '6349001',
      name_search: 'CETMPOIN',
      name: "Ceanothus thyrsiflorus 'Millerton Point'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_order_minimum: 3,
      sppl_prcp: 2.845,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SNT3REBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNT3REBC'
    },
    {
      status: 810,
      sku: 'PM0069540',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208014',
      rtl_batch_array: '6208014',
      name_search: 'IBSAAMET',
      name: "Iberis semp. 'Absolutely Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PXTT3CE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXTT3CE8'
    },
    {
      status: 210,
      sku: 'PM0069546',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6231150',
      rtl_batch_array: '6231150',
      name_search: 'IRSMROWE',
      name: "Iris sibirica 'Mrs Rowe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.747,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CBPJCHD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBPJCHD8'
    },
    {
      status: 210,
      sku: 'PM0069554',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220830',
      rtl_batch_array: '6220830',
      name_search: 'KNAFUN',
      name: "Kniphofia 'Amazing Fun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CGWR5TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CGWR5TB'
    },
    {
      status: 210,
      sku: 'PM0069557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242165',
      rtl_batch_array: '6242165, 6302637',
      name_search: 'KNBACKDR',
      name: "Kniphofia 'Backdraft'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 470,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '23EKSSD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23EKSSD4'
    },
    {
      status: 210,
      sku: 'PM0078996',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314178',
      rtl_batch_array: '6314178',
      name_search: 'ACPELACE',
      name: "Acer palmatum 'Emerald Lace'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 861,
      chnn_stock_retail: 861,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S4ZEJ9DK;CX5HD27D;ZXTRHS5R;Z1XPGBZ5;NVY1RZDE',
      statusContent: 'dot',
      hash: '48A94W82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48A94W82'
    },
    {
      status: 810,
      sku: 'PM0065453',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253802',
      rtl_batch_array: '6253802',
      name_search: 'ACAISSAI',
      name: "Actinidia arguta 'Issai'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC3129JH;9G2Y6K9A;WVSP5PPA',
      statusContent: 'dot',
      hash: '19R75WBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19R75WBB'
    },
    {
      status: 210,
      sku: 'PM0079012',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314195',
      rtl_batch_array: '6314195',
      name_search: 'ACPRPYGM',
      name: "Acer palmatum 'Red Pygmy'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 529,
      chnn_stock_retail: 529,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2P5EAZ6X',
      statusContent: 'dot',
      hash: 'H2SHC2H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2SHC2H1'
    },
    {
      status: 210,
      sku: 'PM0079016',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314199',
      rtl_batch_array: '6314199',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 1833,
      chnn_stock_retail: 1833,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: 'WWHLKHY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWHLKHY3'
    },
    {
      status: 210,
      sku: 'PM0085436',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379078',
      rtl_batch_array: '6379078',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '10012512',
      rng_range_identifier: 'H100125BRA1+2',
      rng_range_description: 'H100 cm 125 cm Bare root A1+2',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '2YYJAVWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YYJAVWX'
    },
    {
      status: 210,
      sku: 'PM0081896',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349002',
      rtl_batch_array: '6349002',
      name_search: 'CEGYPOIN',
      name: "Ceanothus griseus 'Yankee Point'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5WNGL5X9;B2RSV8WC;GJRSTXRT',
      statusContent: 'dot',
      hash: 'NA78ZYX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NA78ZYX6'
    },
    {
      status: 210,
      sku: 'PM0081897',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349003',
      rtl_batch_array: '6349003',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.998,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: 'ZCE2SJCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCE2SJCA'
    },
    {
      status: 210,
      sku: 'PM0081898',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349005',
      rtl_batch_array: '6349005',
      name_search: 'CHTBRICA',
      name: "Choisya ternata 'Brica'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.998,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNC6SJWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNC6SJWB'
    },
    {
      status: 210,
      sku: 'PM0085437',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379079',
      rtl_batch_array: '6379079',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '12515012',
      rng_range_identifier: 'H125150BRA1+2',
      rng_range_description: 'H125 cm 150 cm Bare root A1+2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 20,
      sppl_prcp: 1.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'G1CRN7E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1CRN7E1'
    },
    {
      status: 210,
      sku: 'PM0081899',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349006',
      rtl_batch_array: '6349006',
      name_search: 'CHTBRICA',
      name: "Choisya ternata 'Brica'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.889,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TWY9RS57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWY9RS57'
    },
    {
      status: 210,
      sku: 'PM0081900',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349007',
      rtl_batch_array: '6349007',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 0.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'JWW3ZNNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWW3ZNNZ'
    },
    {
      status: 210,
      sku: 'PM0081901',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349009',
      rtl_batch_array: '6349009',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.998,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: '43KHNPDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43KHNPDP'
    },
    {
      status: 210,
      sku: 'PM0068168',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193731',
      rtl_batch_array: '6193731',
      name_search: 'HYSVARIE',
      name: "Hydrocotyle sibthorpioides 'Variegata'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZZG3B7B5;GH15GLX8;8RHDLEDT;4LXTC246',
      statusContent: 'dot',
      hash: 'N6DXHNDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6DXHNDY'
    },
    {
      status: 210,
      sku: 'PM0081902',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349011',
      rtl_batch_array: '6349011',
      name_search: 'COARSTAR',
      name: "Cordyline australis 'Red Star'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 10.814,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9ZWEBRTX;L215AB7C;H2KWRL7X;VYKGBPTD;KP1W5YVS;Z9PNA43D;G6CZ3W6J;CR4K2HLY;D69RVERX;KK654R6Z;9BLYZSVB;75XXT1A5;CTJ2WVSG',
      statusContent: 'dot',
      hash: 'EG4Y1G88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG4Y1G88'
    },
    {
      status: 210,
      sku: 'PM0068145',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193684',
      rtl_batch_array: '6193684',
      name_search: 'SCTZEBRI',
      name: "Schoenoplectus tabernaemontani 'Zebrinus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7KDR3ZN8;JANRPV1R;S1W8YKDC;DSVTDS4S;7B8XA2X1;STBLR4K8',
      statusContent: 'dot',
      hash: 'PR348SH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR348SH9'
    },
    {
      status: 210,
      sku: 'PM0085438',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379081',
      rtl_batch_array: '6379081',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '06010010',
      rng_range_identifier: 'H060100BRSeedA1+0',
      rng_range_description: 'H60 cm 100 cm Bare root Seed 1+0',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'WYNAWHP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYNAWHP9'
    },
    {
      status: 210,
      sku: 'PM0085439',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379082',
      rtl_batch_array: '6379082',
      name_search: 'PRAVIUM',
      name: 'Prunus avium',
      sppl_size_code: '04006010',
      rng_range_identifier: 'H040060BRSeedA1+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+0',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NVZKRGJ5;KZGWTGZ1;98CXZCCP;L99WV59T;DZ9CAP9E;YV1LLRVA;XNE3RCPK;V2DZGJGB;T5TVEDW1;ATX6TKBX;HJCLGVNA;1BHP4TRH;74HTC5L7',
      statusContent: 'dot',
      hash: 'E8J3AHN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8J3AHN1'
    },
    {
      status: 210,
      sku: 'PM0085440',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379083',
      rtl_batch_array: '6379083',
      name_search: 'PRAVIUM',
      name: 'Prunus avium',
      sppl_size_code: '06010010',
      rng_range_identifier: 'H060100BRSeedA1+0',
      rng_range_description: 'H60 cm 100 cm Bare root Seed 1+0',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.298,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NVZKRGJ5;KZGWTGZ1;98CXZCCP;L99WV59T;DZ9CAP9E;YV1LLRVA;XNE3RCPK;V2DZGJGB;T5TVEDW1;ATX6TKBX;HJCLGVNA;1BHP4TRH;74HTC5L7',
      statusContent: 'dot',
      hash: '9BWNC4E3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BWNC4E3'
    },
    {
      status: 210,
      sku: 'PM0085441',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379084',
      rtl_batch_array: '6379084',
      name_search: 'COCOLURN',
      name: 'Corylus colurna',
      sppl_size_code: '04006010',
      rng_range_identifier: 'H040060BRSeedA1+0',
      rng_range_description: 'H40 cm 60 cm Bare root Seed 1+0',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K9XN2KRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9XN2KRY'
    },
    {
      status: 210,
      sku: 'PM0085442',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379085',
      rtl_batch_array: '6379085',
      name_search: 'COCOLURN',
      name: 'Corylus colurna',
      sppl_size_code: '06010010',
      rng_range_identifier: 'H060100BRSeedA1+0',
      rng_range_description: 'H60 cm 100 cm Bare root Seed 1+0',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WB96EZBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB96EZBD'
    },
    {
      status: 210,
      sku: 'PM0085443',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379165',
      rtl_batch_array: '6379165',
      name_search: 'QUROBUR',
      name: 'Quercus robur',
      sppl_size_code: '03005010',
      rng_range_identifier: 'H030050BRSeedA1+0',
      rng_range_description: 'H30 cm 50 cm Bare root Seed 1+0',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 50,
      sppl_prcp: 0.248,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8CH6KBB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CH6KBB3'
    },
    {
      status: 210,
      sku: 'PM0085444',
      core_name: 'Plant',
      btch_manufacturer: 18,
      rtl_batch_array_total: 1,
      btch_active_retail: '6379166',
      rtl_batch_array: '6379166',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '030040012',
      rng_range_identifier: 'H030040BRCuttingA0+1+2',
      rng_range_description: 'H30 cm 40 cm Bare root Cutting 0+1+2',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 25,
      sppl_prcp: 0.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'T33RRLNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T33RRLNH'
    },
    {
      status: 210,
      sku: 'PM0065320',
      core_name: 'Plant',
      sppl_ean: '8720664879201',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162711',
      rtl_batch_array: '6162711',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 796,
      chnn_stock_retail: 796,
      sppl_prcp: 4.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'J1G59R6J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1G59R6J'
    },
    {
      status: 210,
      sku: 'PM0048434',
      core_name: 'Plant',
      sppl_ean: '8720664884632',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080748',
      rtl_batch_array: '6080748',
      name_search: 'SAMCAROL',
      name: "Salvia microphyllus 'Carolus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2HDGJSKZ;A7DYL19E;P948KK1V;BG8WB2X6;ZY9PG3HW',
      statusContent: 'dot',
      hash: '775VYTA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '775VYTA1'
    },
    {
      status: 210,
      sku: 'PM0074298',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278106',
      rtl_batch_array: '6278106',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1840,
      chnn_stock_retail: 1840,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'EVGN5VZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVGN5VZ7'
    },
    {
      status: 210,
      sku: 'PM0069550',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317845',
      rtl_batch_array: '6350590, 5960178, 6317845',
      name_search: 'KAINCISA',
      name: 'Kalimeris incisa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1078,
      chnn_stock_retail: 3570,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YH826DYW;SHB2DVC1',
      statusContent: 'dot',
      hash: 'BJ7EXHRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJ7EXHRJ'
    },
    {
      status: 210,
      sku: 'PM0069552',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6317847',
      rtl_batch_array: '6317847, 6383359, 6308607, 6339842',
      name_search: 'KAIMADIV',
      name: "Kalimeris incisa 'Madiva'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4303,
      chnn_stock_retail: 6443,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GJVLHZJH;JAPSCLB1;ECWSYRNX;6ZJX8SB3;HV8ZAZ17;GXGPZWPY;KGLJS8SV;DVCLALBK;83Z23WB6;7YD4YB21',
      statusContent: 'dot',
      hash: 'JT18K29L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT18K29L'
    },
    {
      status: 210,
      sku: 'PM0069556',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350615',
      rtl_batch_array: '6350615',
      name_search: 'KNUVARIA',
      name: 'Kniphofia uvaria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDSAALYZ;7SAW3EXS;GA5Z1XEL;JS3X75Z8;9NL6Z18E;PE9LSLCZ;YWCDGG4A;1WHSWR4B;7DPLP8ZV',
      statusContent: 'dot',
      hash: 'K19C5D9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K19C5D9C'
    },
    {
      status: 910,
      sku: 'PM0065330',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABSEMPE',
      name: "Taxus baccata 'Semperaurea'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      imageCore: '65SAZJDT;N1TDHW82',
      statusContent: 'dot',
      hash: 'L1W5N83Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L1W5N83Y'
    },
    {
      status: 210,
      sku: 'PM0068226',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193799',
      rtl_batch_array: '6193799',
      name_search: 'LYNUMMUL',
      name: 'Lysimachia nummularia',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7L31VN57;BWXX8AX1;2V2VBKW6;DDZBD1WH;DSXJ5AY9',
      statusContent: 'dot',
      hash: 'NX5H5T2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NX5H5T2S'
    },
    {
      status: 210,
      sku: 'PM0048406',
      core_name: 'Plant',
      sppl_ean: '8720664594074',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015466',
      rtl_batch_array: '6015466',
      name_search: 'PHADINDA',
      name: 'Phlox (P) Adinda',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6XD1P4SZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XD1P4SZ'
    },
    {
      status: 910,
      sku: 'PM0065457',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLOMOSHI',
      name: "Clematis 'Omoshiro'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'CY9RNS3J',
      statusContent: 'dot',
      hash: 'D9A8SKXN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D9A8SKXN'
    },
    {
      status: 210,
      sku: 'PM0068219',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193789',
      rtl_batch_array: '6193789',
      name_search: 'IRKAEMPF',
      name: 'Iris kaempferii',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H9WE26HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9WE26HA'
    },
    {
      status: 210,
      sku: 'PM0048397',
      core_name: 'Plant',
      sppl_ean: '8720664694170',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015368',
      rtl_batch_array: '6015368',
      name_search: 'HYCRSHAR',
      name: "Hypericum calycinum 'Rose von Sharon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G1DDHNKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1DDHNKE'
    },
    {
      status: 810,
      sku: 'PM0048358',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386127',
      rtl_batch_array: '6386127',
      name_search: 'GYPMPINK',
      name: 'Gypsophila paniculata My Pink',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_order_minimum: 3,
      sppl_prcp: 2.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JC1PDD9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JC1PDD9L'
    },
    {
      status: 210,
      sku: 'PM0058251',
      core_name: 'Plant',
      sppl_ean: '8720664695221',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112480',
      rtl_batch_array: '6112480',
      name_search: 'IRISCINT',
      name: "Iris (G) 'Scintilla'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6AH6N2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6AH6N2P'
    },
    {
      status: 810,
      sku: 'PM0048351',
      core_name: 'Plant',
      sppl_ean: '8720626301757',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015143',
      rtl_batch_array: '6015143',
      name_search: 'GESPSUMM',
      name: "Geranium sanguineum 'Pink Summer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KCKZV4R4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCKZV4R4'
    },
    {
      status: 210,
      sku: 'PM0048365',
      core_name: 'Plant',
      sppl_ean: '8720664691865',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015220',
      rtl_batch_array: '6015220',
      name_search: 'HEGGLOSS',
      name: "Helleborus Glenda's Gloss",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 3.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SRYGC3L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SRYGC3L4'
    },
    {
      status: 210,
      sku: 'PM0081903',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349012',
      rtl_batch_array: '6349012',
      name_search: 'ESIVEYI',
      name: "Escallonia 'Iveyi'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBWCL9PR;BHV9S7WZ;PZSG7SKR;LGJVDGY9;T7A7Y6GN',
      statusContent: 'dot',
      hash: 'XR4RT4HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XR4RT4HZ'
    },
    {
      status: 210,
      sku: 'PM0059441',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274733',
      rtl_batch_array: '6274733',
      name_search: 'HEHGOLDC',
      name: "Hedera helix 'Goldchild'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJRW5453;57LGK482;7ZC75WXR;XXLZ6PKD;YD2JNZA2',
      statusContent: 'dot',
      hash: 'NLEPRXL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLEPRXL9'
    },
    {
      status: 210,
      sku: 'PM0081904',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349013',
      rtl_batch_array: '6349013',
      name_search: 'EUJHIMAL',
      name: 'Euonymus japonicus Himalaya',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.129,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5PJ7GE43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PJ7GE43'
    },
    {
      status: 210,
      sku: 'PM0054936',
      core_name: 'Plant',
      sppl_ean: '8720664852525',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161541',
      rtl_batch_array: '6081409, 6161541',
      name_search: 'ASNIOBE',
      name: "Aster (D) 'Niobe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 401,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KAWXYH8L;5GPRA9S6;G5734ZZN;456P855S;V59DJ87W;LAHVGG7Y;EEVHAECD',
      statusContent: 'dot',
      hash: 'Z71CWTTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z71CWTTP'
    },
    {
      status: 210,
      sku: 'PM0081905',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349014',
      rtl_batch_array: '6349014',
      name_search: 'GAJDDIAM',
      name: 'Gardenia jasm. Double Diamonds',
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VG74G23B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VG74G23B'
    },
    {
      status: 210,
      sku: 'PM0065635',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219491',
      rtl_batch_array: '6219491',
      name_search: 'SARBUMBL',
      name: "Salvia 'Royal Bumble'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NVWJS4W9',
      statusContent: 'dot',
      hash: '1LRXAAAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LRXAAAP'
    },
    {
      status: 210,
      sku: 'PM0068205',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193772',
      rtl_batch_array: '6193772',
      name_search: 'COEHPUNC',
      name: "Colocasia esculenta 'Hawaiian Punch'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7K4SGPL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7K4SGPL9'
    },
    {
      status: 210,
      sku: 'PM0081906',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349015',
      rtl_batch_array: '6349015',
      name_search: 'HYPHERCU',
      name: 'Hydrangea paniculata Hercules',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '72DZ94SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72DZ94SG'
    },
    {
      status: 910,
      sku: 'PM0046846',
      core_name: 'Plant',
      sppl_ean: '8720664681941',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '5915110',
      rtl_batch_array: '5915110',
      name_search: 'BLMVLIND',
      name: 'Bloemenmengsel Vlinders',
      sppl_size_code: 'ZADOOS520G',
      rng_range_identifier: 'BOX520GR',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 6.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78SRB1C4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78SRB1C4'
    },
    {
      status: 210,
      sku: 'PM0078651',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310375',
      rtl_batch_array: '6310375',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: 'C1GADXWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1GADXWV'
    },
    {
      status: 210,
      sku: 'PM0078652',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310376',
      rtl_batch_array: '6310376',
      name_search: 'RUIDAEUS',
      name: 'Rubus idaeus',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 161,
      chnn_stock_retail: 161,
      sppl_prcp: 2.135,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LTHDGKB1;Y3NRZRTA;JVPA25VP;HXL5HWDD;K1LHXREB;W1EASEK4;8H4PBTT7;NDD9XYLV;G19ABY46;Y6YKPEJE;YE55S945;LZESSKT2;PS7N9SAX;LJKCS5W4;ZG6R79RD;LT6SHXN8;K8APA8XE;K8AE8CDE;VDKH3EWJ;7DG11VZG;PG1THBAE;HJ1PCEKN',
      statusContent: 'dot',
      hash: '1RK625LL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RK625LL'
    },
    {
      status: 210,
      sku: 'PM0079022',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314205',
      rtl_batch_array: '6314205',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: '7C1ZTX5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7C1ZTX5S'
    },
    {
      status: 210,
      sku: 'PM0064887',
      core_name: 'Plant',
      sppl_ean: '8720353006680',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420608',
      rtl_batch_array: '5420608',
      name_search: 'CLMARJOR',
      name: "Clematis 'Marjorie'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZ86EHE1;HKGSYLJJ;7HHJ82RY;EA166CHR;KTS41VV8;N52LCCP8;T5JVVHDC;LB2NG52Z;4PXXHD4Z;ZHXC8LJ8;95XXYYPD;BWJJGWSC',
      statusContent: 'dot',
      hash: 'B4RS6Y92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4RS6Y92'
    },
    {
      status: 210,
      sku: 'PM0081907',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349016',
      rtl_batch_array: '6349016',
      name_search: 'HYPPIXIO',
      name: 'Hydrangea pan. Pixio',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BAL43RP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAL43RP4'
    },
    {
      status: 210,
      sku: 'PM0078653',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310377',
      rtl_batch_array: '6310377',
      name_search: 'RUTRICOL',
      name: 'Rubus tricolor',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 17000,
      chnn_stock_retail: 17000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9Z754P4K',
      statusContent: 'dot',
      hash: 'TNHZS43L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNHZS43L'
    },
    {
      status: 910,
      sku: 'PM0071237',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WISALBA',
      name: "Wisteria sinensis 'Alba'",
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      imageCore:
        '5C98R9CR;1XASNEWH;AZ21HDHY;TCX6T96W;TG5VBKPH;J6KXJSER;AJ6JGEJE;A6J9ETBZ;PLC4N6SS;GRLXX496',
      statusContent: 'dot',
      hash: 'X7C9H2SK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X7C9H2SK'
    },
    {
      status: 210,
      sku: 'PM0078654',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310378',
      rtl_batch_array: '6310378',
      name_search: 'SACINERE',
      name: 'Salix cinerea',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2625,
      chnn_stock_retail: 2625,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T88HRKX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T88HRKX3'
    },
    {
      status: 210,
      sku: 'PM0059425',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308563',
      rtl_batch_array: '6308563, 6352632',
      name_search: 'ECPVIRGI',
      name: "Echinacea purpurea 'Virgin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1943,
      chnn_stock_retail: 3091,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHE3RNZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHE3RNZ8'
    },
    {
      status: 210,
      sku: 'PM0081908',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349017',
      rtl_batch_array: '6349017',
      name_search: 'HYPSKYFA',
      name: 'Hydrangea paniculata Skyfall',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB65XZ1A;576ZXYVH;L2LSYTCA;N7234DKB;8LX9HJ78;NRSLWCTS',
      statusContent: 'dot',
      hash: '2JBZ1CVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JBZ1CVZ'
    },
    {
      status: 210,
      sku: 'PM0078655',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310379',
      rtl_batch_array: '6310379',
      name_search: 'SAEANGUS',
      name: "Salix elaeagnos 'Angustifolia'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JGDS1NBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGDS1NBX'
    },
    {
      status: 210,
      sku: 'PM0078656',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310381',
      rtl_batch_array: '6310381',
      name_search: 'SAPURPUR',
      name: 'Salix purpurea',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 6854,
      chnn_stock_retail: 6854,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8EHWCYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8EHWCYD'
    },
    {
      status: 210,
      sku: 'PM0078658',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373840',
      rtl_batch_array: '6373840, 6310385',
      name_search: 'SARNITID',
      name: 'Salix repens nitida',
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 850,
      chnn_stock_retail: 971,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZHP2L4T',
      statusContent: 'dot',
      hash: 'YXRAN5YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXRAN5YR'
    },
    {
      status: 910,
      sku: 'PM0078659',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAVIMINA',
      name: 'Salix viminalis',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      imageCore: 'HNL4GNKY;HLED25D3;PPPSNJ2S;9C5J5BNG;85Y9E85A;R6X2EZJC',
      statusContent: 'dot',
      hash: '816SWSV8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '816SWSV8'
    },
    {
      status: 210,
      sku: 'PM0038576',
      core_name: 'Plant',
      sppl_ean: '8720664686373',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '5572844',
      rtl_batch_array: '5572844',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      imageBatch: 'RLZYS5JZ',
      statusContent: 'dot',
      hash: '1HWD1E2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HWD1E2H'
    },
    {
      status: 210,
      sku: 'PM0078664',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310392',
      rtl_batch_array: '6310392',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2800,
      chnn_stock_retail: 2800,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'RZXPRH7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZXPRH7H'
    },
    {
      status: 210,
      sku: 'PM0081909',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349018',
      rtl_batch_array: '6349018',
      name_search: 'HYPSKYFA',
      name: 'Hydrangea paniculata Skyfall',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB65XZ1A;576ZXYVH;L2LSYTCA;N7234DKB;8LX9HJ78;NRSLWCTS',
      statusContent: 'dot',
      hash: '9W7CLXG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9W7CLXG9'
    },
    {
      status: 210,
      sku: 'PM0081910',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349019',
      rtl_batch_array: '6349019',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 0.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'GVSG2XC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVSG2XC2'
    },
    {
      status: 210,
      sku: 'PM0078661',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310389',
      rtl_batch_array: '6310389',
      name_search: 'SARACEMO',
      name: 'Sambucus racemosa',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RNA7S3EW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNA7S3EW'
    },
    {
      status: 210,
      sku: 'PM0078662',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310390',
      rtl_batch_array: '6310390',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 538,
      chnn_stock_retail: 538,
      sppl_prcp: 1.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: 'CVHZB6TH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVHZB6TH'
    },
    {
      status: 210,
      sku: 'PM0081911',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349020',
      rtl_batch_array: '6352691, 6349020',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 325,
      sppl_prcp: 0.141,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: '9RYVAS32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RYVAS32'
    },
    {
      status: 210,
      sku: 'PM0068249',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193823',
      rtl_batch_array: '6193823',
      name_search: 'SCLACUST',
      name: 'Schoenoplectus lacustris',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GLNHT5L1;S3G6CNYV;56YJEY1C;94N2A984',
      statusContent: 'dot',
      hash: 'BWZAGVRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWZAGVRE'
    },
    {
      status: 910,
      sku: 'PM0085445',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282514',
      rtl_batch_array: '6282514',
      name_search: 'ACRSRED',
      name: "Acer rubrum 'Summer Red'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 15.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ESP6JBX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESP6JBX5'
    },
    {
      status: 210,
      sku: 'PM0058307',
      core_name: 'Plant',
      sppl_ean: '8720664697348',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112556',
      rtl_batch_array: '6112556',
      name_search: 'LEMACARO',
      name: "Leucanthemum (S) 'Macaroon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CTYR1CTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTYR1CTY'
    },
    {
      status: 210,
      sku: 'PM0081912',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349021',
      rtl_batch_array: '6349021',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.219,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: 'DZ31HJR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ31HJR5'
    },
    {
      status: 210,
      sku: 'PM0059428',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301734',
      rtl_batch_array: '6301734',
      name_search: 'MOSQUAW',
      name: "Monarda 'Squaw'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 817,
      chnn_stock_retail: 817,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PCR794WB;KN4TCHND;XBE8Z522;5LVPCBLA;8X112XBJ;JEW4YD3V;SCRN22AN;1P3NXGV7;HPCAZXYG;SZW6ER69;L1CVCN58',
      statusContent: 'dot',
      hash: 'AWB6HHNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWB6HHNT'
    },
    {
      status: 210,
      sku: 'PM0078663',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310391',
      rtl_batch_array: '6310391',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_prcp: 1.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: 'Y6S3EK67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6S3EK67'
    },
    {
      status: 210,
      sku: 'PM0047724',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353274',
      rtl_batch_array: '6353274',
      name_search: 'RHGRIETJ',
      name: "Rhodoxis 'Grietje'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WXZRY4GJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXZRY4GJ'
    },
    {
      status: 210,
      sku: 'PM0047340',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353356',
      rtl_batch_array: '6353356',
      name_search: 'SASINTER',
      name: 'Saponaria sicula intermedia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 696,
      chnn_stock_retail: 696,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VX8DJHVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VX8DJHVS'
    },
    {
      status: 810,
      sku: 'PM0069572',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238745',
      rtl_batch_array: '6238745',
      name_search: 'LABBRIDE',
      name: "Lavatera 'Blushing Bride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H96437DT;LJ4GNKPB;ASDGW9WJ;TGX15VYT;2A7LRLJE;P7874X9A;YE2ASATR;23VKYJ9J;87X1S3HW;7NEXH1WZ;LL2B3VRP;LBYSYD4X',
      statusContent: 'dot',
      hash: 'YKA9ETZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKA9ETZ4'
    },
    {
      status: 210,
      sku: 'PM0081913',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349022',
      rtl_batch_array: '6349022',
      name_search: 'PHOLJOKE',
      name: 'Physocarpus opulifolius Little Joker',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.141,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E48CH8E;RPZCTBD6;PNCPDPEJ;5P2VJZ97;SX8GWW1H;15PJWBEG;JTELK2CN;1BSE16EY',
      statusContent: 'dot',
      hash: '6C2NL7JS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6C2NL7JS'
    },
    {
      status: 210,
      sku: 'PM0066810',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176198',
      rtl_batch_array: '6370383, 6176198',
      name_search: 'PHAGRACE',
      name: "Phlox (S) 'Amazing Grace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 597,
      chnn_stock_retail: 820,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y8TC1TKS;72X62YS7;HJ7TKPT3;5NSXG8D5;YWDTRDRR;4TNK7B7H;JXX2K8EN;VZ8PR4E5;8YKV9AED;CKBDY3EJ;K597VHWR;EP18SHL2;WWBCKH9G;YAKG1G9V;RS73SZKN',
      statusContent: 'dot',
      hash: '16E3G9T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16E3G9T6'
    },
    {
      status: 910,
      sku: 'PM0078777',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ARWMEIER',
      name: "Aruncus 'Woldemar Meier'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5Z2EAPDX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5Z2EAPDX'
    },
    {
      status: 210,
      sku: 'PM0078778',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311318',
      rtl_batch_array: '6311318',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '125150C4',
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      sppl_stock_available: 973,
      chnn_stock_retail: 973,
      sppl_prcp: 4.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'AZZYKSLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZZYKSLW'
    },
    {
      status: 210,
      sku: 'PM0081914',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349023',
      rtl_batch_array: '6349023',
      name_search: 'PHOLJOKE',
      name: 'Physocarpus opulifolius Little Joker',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 0.219,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E48CH8E;RPZCTBD6;PNCPDPEJ;5P2VJZ97;SX8GWW1H;15PJWBEG;JTELK2CN;1BSE16EY',
      statusContent: 'dot',
      hash: 'N36SVR5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N36SVR5A'
    },
    {
      status: 210,
      sku: 'PM0071229',
      core_name: 'Plant',
      sppl_ean: '8720664683624',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4729667',
      rtl_batch_array: '4729667',
      name_search: 'CACFCURL',
      name: "Carex comans 'Frosted Curls'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XWKKGR1V;WBG361VY;99GYTLL2;SX6AA7AB;TAT6WW2B;4W5AP4D3;5XED8LE6;TV536JZ1;W7KPK1J9;7971T56V;BD5VP1GT;CACEEJZK;DXGX5LHS;TZWJXXTJ;CTT6XEBW;TXCN2PBC;ZSNPT7LE;YLWRR1D7;HA74WY1T;423BPXP4;8Y43J9RJ;5XD6K3KB;7CY4KK8R',
      imageBatch: '4XAXXBHY',
      statusContent: 'dot',
      hash: '4NA32X1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NA32X1N'
    },
    {
      status: 910,
      sku: 'PM0078779',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'H1D3AZBS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H1D3AZBS'
    },
    {
      status: 210,
      sku: 'PM0071230',
      core_name: 'Plant',
      sppl_ean: '8720664501461',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4729669',
      rtl_batch_array: '4729669',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      imageBatch: 'J2EDCYCZ',
      statusContent: 'dot',
      hash: 'ZDJZDLZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDJZDLZ9'
    },
    {
      status: 210,
      sku: 'PM0071231',
      core_name: 'Plant',
      sppl_ean: '8720349423408',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207512',
      rtl_batch_array: '5207512, 6383861',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1924,
      chnn_stock_retail: 2293,
      sppl_prcp: 6.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      statusContent: 'dot',
      hash: '6S1VZCR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6S1VZCR1'
    },
    {
      status: 810,
      sku: 'PM0078780',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349991',
      rtl_batch_array: '6349991',
      name_search: 'DIFPARAM',
      name: "Dicentra formosa 'Paramount'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XX7ZS976',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX7ZS976'
    },
    {
      status: 210,
      sku: 'PM0058308',
      core_name: 'Plant',
      sppl_ean: '8720664697362',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112557',
      rtl_batch_array: '6112557, 6107988',
      name_search: 'LEOCOURT',
      name: "Leucanthemum (S) 'Old Court'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 185,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T8YNNR72;6KZ86NCL;2DDYW4SL;2V87BS67',
      statusContent: 'dot',
      hash: '6BG887CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BG887CX'
    },
    {
      status: 910,
      sku: 'PM0079018',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314201',
      rtl_batch_array: '6314201',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: 'VB4XT2PT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VB4XT2PT'
    },
    {
      status: 210,
      sku: 'PM0079019',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314202',
      rtl_batch_array: '6314202',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: 'CASV71D4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CASV71D4'
    },
    {
      status: 810,
      sku: 'PM0079020',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314203',
      rtl_batch_array: '6314203',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: 'GARTJ5V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GARTJ5V2'
    },
    {
      status: 210,
      sku: 'PM0079021',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314204',
      rtl_batch_array: '6314204',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 463,
      chnn_stock_retail: 463,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: 'EG4TCDVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG4TCDVX'
    },
    {
      status: 810,
      sku: 'PM0079023',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314207',
      rtl_batch_array: '6314207',
      name_search: 'ACEPSGOL',
      name: "Acer palmatum 'Summer Gold'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJRX1T2W',
      statusContent: 'dot',
      hash: 'HZL55PDK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZL55PDK'
    },
    {
      status: 210,
      sku: 'PM0066814',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176215',
      rtl_batch_array: '6176215',
      name_search: 'GECTOSAI',
      name: "Geum coccineum 'Tosai'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17583,
      chnn_stock_retail: 17583,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EJ5VGCWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ5VGCWX'
    },
    {
      status: 810,
      sku: 'PM0079024',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314208',
      rtl_batch_array: '6314208',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'C1DV7VCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1DV7VCV'
    },
    {
      status: 910,
      sku: 'PM0079025',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314209',
      rtl_batch_array: '6314209',
      name_search: 'ACPTAYLO',
      name: "Acer palmatum 'Taylor'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJA4YCVK;1KWE1DBX;D6TR26ZY;AK3HTNTZ',
      statusContent: 'dot',
      hash: 'A3DSGTDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3DSGTDC'
    },
    {
      status: 810,
      sku: 'PM0079026',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314210',
      rtl_batch_array: '6314210',
      name_search: 'ACPTROMP',
      name: "Acer palmatum 'Trompenburg'",
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 57.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLLHRL72',
      statusContent: 'dot',
      hash: 'X29CHECL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X29CHECL'
    },
    {
      status: 210,
      sku: 'PM0079027',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314211',
      rtl_batch_array: '6314211',
      name_search: 'ACPTROMP',
      name: "Acer palmatum 'Trompenburg'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1739,
      chnn_stock_retail: 1739,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLLHRL72',
      statusContent: 'dot',
      hash: 'AXAT1JW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXAT1JW4'
    },
    {
      status: 210,
      sku: 'PM0079028',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314212',
      rtl_batch_array: '6314212',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 653,
      chnn_stock_retail: 653,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'L5LJNSNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5LJNSNS'
    },
    {
      status: 210,
      sku: 'PM0046951',
      core_name: 'Plant',
      sppl_ean: '8720664687141',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5992123',
      rtl_batch_array: '6140817, 5992123',
      name_search: 'DEASTOLA',
      name: "Delphinium (P) 'Astolat'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5531,
      chnn_stock_retail: 6531,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '19NJ5S1C;HHY3DV3Z;PZ66E6VD;WKJ9BYR3;79W8N3V1;BGV6NVZA;SASEG299;DWRKJCA3;XZ9Z3XVX',
      statusContent: 'dot',
      hash: '56DC1AXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56DC1AXV'
    },
    {
      status: 210,
      sku: 'PM0079029',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314213',
      rtl_batch_array: '6314213',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 7586,
      chnn_stock_retail: 7586,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '7RTLXSEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RTLXSEV'
    },
    {
      status: 210,
      sku: 'PM0079030',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314214',
      rtl_batch_array: '6314214',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1045,
      chnn_stock_retail: 1045,
      sppl_prcp: 9.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'W887H7BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W887H7BD'
    },
    {
      status: 810,
      sku: 'PM0079032',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314216',
      rtl_batch_array: '6314216',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'D52D563R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D52D563R'
    },
    {
      status: 210,
      sku: 'PM0081915',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349024',
      rtl_batch_array: '6349024',
      name_search: 'PRIKNMAI',
      name: "Prunus incisa 'Kojou-no-mai'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9B8SLJH;LJKEV9AE;PRLZCKB2;H674SENK;NTL778JL;XAC44826;1ZJ5JHY8;RJWAELSD;P9SAD49D;DHSLTBC4;BEB8XJ5E;T62GK6HN;KY53KRSX;RY5BV2N8;X555ZXSE;HG5X55DZ;85S5ZK5K;T4KZ886G;RY8KPRB4;LR4GA7VW',
      statusContent: 'dot',
      hash: '2S9EZHZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S9EZHZN'
    },
    {
      status: 210,
      sku: 'PM0081916',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349025',
      rtl_batch_array: '6349025',
      name_search: 'PRLSOFIA',
      name: 'Prunus l. Sofia',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 0.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55C22JJD;KVAR9T4R;W61381ZV;BWE9DJRE;489A9SSG;BC68HSEG;VSECYCP2;EBERS9E5;9PP3TLZ8;SB4GEBPG;GDH58JP7',
      statusContent: 'dot',
      hash: 'S3GH81BA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3GH81BA'
    },
    {
      status: 210,
      sku: 'PM0079033',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314217',
      rtl_batch_array: '6314217',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 451,
      chnn_stock_retail: 451,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'STG88336',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STG88336'
    },
    {
      status: 210,
      sku: 'PM0066114',
      core_name: 'Plant',
      sppl_ean: '8720664557833',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486258',
      rtl_batch_array: '6170285, 5486258',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'HRNX4TZC',
      statusContent: 'dot',
      hash: 'BEA4SS32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEA4SS32'
    },
    {
      status: 210,
      sku: 'PM0065347',
      core_name: 'Plant',
      sppl_ean: '8720664888319',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162741',
      rtl_batch_array: '6162741',
      name_search: 'THOLGIAN',
      name: "Thuja occidentalis 'Little Giant'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 3132,
      chnn_stock_retail: 3132,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LNGAX1LE',
      statusContent: 'dot',
      hash: 'RT54BGHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RT54BGHE'
    },
    {
      status: 210,
      sku: 'PM0079034',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314218',
      rtl_batch_array: '6314218',
      name_search: 'AUJPICTU',
      name: "Aucuba japonica 'Picturata'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 899,
      chnn_stock_retail: 899,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1A6C1KSX',
      statusContent: 'dot',
      hash: 'ST7HLKEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST7HLKEP'
    },
    {
      status: 210,
      sku: 'PM0079035',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314219',
      rtl_batch_array: '6314219',
      name_search: 'AUJROZAN',
      name: "Aucuba japonica 'Rozannie'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 812,
      chnn_stock_retail: 812,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SP38Y4JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP38Y4JH'
    },
    {
      status: 210,
      sku: 'PM0079036',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314220',
      rtl_batch_array: '6314220',
      name_search: 'CAVLENA',
      name: "Calluna vulgaris 'Lena'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 2742,
      chnn_stock_retail: 2742,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BT6KWE77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BT6KWE77'
    },
    {
      status: 210,
      sku: 'PM0081917',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349026',
      rtl_batch_array: '6349026',
      name_search: 'SYPMKIM',
      name: "Syringa patula 'Miss Kim'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H9C84CRR;RBGZ96SX;XPA14KLK;N7KPZ796;711JRA1B;JY6DHXP8',
      statusContent: 'dot',
      hash: '5Z1KC635',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Z1KC635'
    },
    {
      status: 210,
      sku: 'PM0078657',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310384',
      rtl_batch_array: '6310384',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '020030C14',
      rng_range_identifier: 'H020030C1.5',
      rng_range_description: 'H20 cm 30 cm C1.5',
      sppl_stock_available: 3740,
      chnn_stock_retail: 3740,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'R4TVVALL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4TVVALL'
    },
    {
      status: 210,
      sku: 'PM0065450',
      core_name: 'Plant',
      sppl_ean: '8720664876330',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164501',
      rtl_batch_array: '6164501',
      name_search: 'PABBEAUT',
      name: "Paeonia (LE) 'Bowl of Beauty'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1BRESE9;LCGNJYHB',
      statusContent: 'dot',
      hash: '2YRCJKV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YRCJKV7'
    },
    {
      status: 910,
      sku: 'PM0065447',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLMBATEM',
      name: "Clematis 'Miss Bateman'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'AGB8V3L4;ZB2RH1RT;J11CKA42;VSJEJEZ8',
      statusContent: 'dot',
      hash: '521VLZRV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '521VLZRV'
    },
    {
      status: 210,
      sku: 'PM0059429',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308443',
      rtl_batch_array: '5902395, 6217030, 6383364, 6308443, 6339865',
      name_search: 'NERGROG',
      name: "Nepeta racemosa 'Grog'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 16616,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PEJGBDDT',
      statusContent: 'dot',
      hash: 'B1VS22GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1VS22GW'
    },
    {
      status: 210,
      sku: 'PM0079037',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314221',
      rtl_batch_array: '6314221',
      name_search: 'CAVBONIT',
      name: "Calluna vulgaris 'Bonita'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DJT9VT4L;A6LDPNSW;J8X99EBL;77NENV4R;ZX3X715A;9A9WVHR4;LWJ4ANHJ',
      statusContent: 'dot',
      hash: 'V7J6BRDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7J6BRDN'
    },
    {
      status: 210,
      sku: 'PM0085446',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334202',
      rtl_batch_array: '6334202',
      name_search: 'LASTOECH',
      name: 'Lavandula stoechas',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1001,
      chnn_stock_retail: 1001,
      sppl_order_minimum: 3,
      sppl_prcp: 1.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P26KEK4Z;6PGD231W;DH5VG3N5;2GRS5B48;VB8W6THD;NJJWEJEJ;1A51D692;KVXG3VPP;RENLXGD9;LJWGWVHN;EHNVWE6N;1J2Z79S2;VYZR35RS;C3847468;7G3AA4W6;REPKT893;18T8PY9N;16E3CW9L;BH366JBZ;A7Z43XBV;9T3VH29L;JPL5GYG7;LEBHYEX5;AGE65TVC;SYT7ZBS4;9BAK5ZBW',
      statusContent: 'dot',
      hash: 'DERTZHLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DERTZHLJ'
    },
    {
      status: 810,
      sku: 'PM0081918',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349027',
      rtl_batch_array: '6349027',
      name_search: 'VACFLAMI',
      name: 'Vaccinium corymbosum Flamingo',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 0.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DV4GT647',
      statusContent: 'dot',
      hash: '1RD2HHWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RD2HHWN'
    },
    {
      status: 210,
      sku: 'PM0078660',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310387',
      rtl_batch_array: '6310387',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 760,
      chnn_stock_retail: 760,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'B63AG5NW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B63AG5NW'
    },
    {
      status: 910,
      sku: 'PM0071232',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LOPERICL',
      name: 'Lonicera periclymenum',
      rng_range_identifier: 'H060070C1.5',
      rng_range_description: 'H60 cm 70 cm C1.5',
      imageCore: 'HYGLHEPG;61EXCRG3;XS5W43AY;VC6L2AA1;HARWJ729',
      statusContent: 'dot',
      hash: 'A22Y2HHG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A22Y2HHG'
    },
    {
      status: 810,
      sku: 'PM0079031',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314215',
      rtl_batch_array: '6314215',
      name_search: 'ACSAUREU',
      name: "Acer shirasawanum 'Aureum'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 17.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T674X59V;YZYWEY44;458EWB6E;NYRBA675;Z23SB7RR;CPW35J2S;EHDC7N9E;ZL8XPW5T;BE87T1RV;GBWBW947;T79C2SD6;B9B3TYA7;A17BHVR6;44CVPRZ6;5RJLDWKZ;JBWGNRWG;GH76VT4H',
      statusContent: 'dot',
      hash: 'G4BNJLAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4BNJLAJ'
    },
    {
      status: 210,
      sku: 'PM0081919',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349028',
      rtl_batch_array: '6349028',
      name_search: 'VIOCOPPE',
      name: 'Viburnum odoratissimum Coppertop',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 0.229,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5XLB1J5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XLB1J5L'
    },
    {
      status: 810,
      sku: 'PM0047731',
      core_name: 'Plant',
      sppl_ean: '8720664809024',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5959715',
      rtl_batch_array: '5959715',
      name_search: 'SPJMCARP',
      name: 'Spiraea japonica Magic Carpet',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L36W8Z95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L36W8Z95'
    },
    {
      status: 210,
      sku: 'PM0071228',
      core_name: 'Plant',
      sppl_ean: '8720349498352',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '2136967',
      rtl_batch_array: '2136967',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '060080C14',
      rng_range_identifier: 'H060080C1.5',
      rng_range_description: 'H60 cm 80 cm C1.5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 1.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: 'WC43CNPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC43CNPD'
    },
    {
      status: 210,
      sku: 'PM0066813',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176210',
      rtl_batch_array: '6176210',
      name_search: 'DIPDWHIT',
      name: "Digitalis purpurea 'Dalmatian White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4113,
      chnn_stock_retail: 4113,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CSKS24TR',
      statusContent: 'dot',
      hash: 'JADVA593',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JADVA593'
    },
    {
      status: 210,
      sku: 'PM0059400',
      core_name: 'Plant',
      sppl_ean: '8720664879225',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6114113',
      rtl_batch_array: '6114113',
      name_search: 'PIGPERFE',
      name: 'Picea glauca Perfecta',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 1149,
      chnn_stock_retail: 1149,
      sppl_prcp: 9.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NLERT41',
      statusContent: 'dot',
      hash: 'RX6L4EX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX6L4EX2'
    },
    {
      status: 910,
      sku: 'PM0071233',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: '29Y4ZNPW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '29Y4ZNPW'
    },
    {
      status: 210,
      sku: 'PM0071238',
      core_name: 'Plant',
      sppl_ean: '8720353008264',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5415379',
      rtl_batch_array: '5415379',
      name_search: 'OMVERNA',
      name: 'Omphalodes verna',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7008,
      chnn_stock_retail: 7008,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6PG5L959;VZGC63ZZ;XP71J7DH;L1V9TNAP;CRG7J4VK;46J7X8HZ;YP3W1TYA;SX48LGWN',
      imageBatch: 'WS2HPPPE',
      statusContent: 'dot',
      hash: 'DRLA2WC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRLA2WC4'
    },
    {
      status: 210,
      sku: 'PM0066815',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176216',
      rtl_batch_array: '6176216',
      name_search: 'HEMCFIRE',
      name: "Hemerocallis 'Crown Fire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 708,
      chnn_stock_retail: 708,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RW5R9X67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW5R9X67'
    },
    {
      status: 210,
      sku: 'PM0081920',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349029',
      rtl_batch_array: '6349029',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: 'ALV9S2YB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALV9S2YB'
    },
    {
      status: 210,
      sku: 'PM0081921',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349030',
      rtl_batch_array: '6349030',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.229,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: '4KA9C9NP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KA9C9NP'
    },
    {
      status: 910,
      sku: 'PM0085447',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334211',
      rtl_batch_array: '6334211',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '200225C5',
      rng_range_identifier: 'H200225C5',
      rng_range_description: 'H200 cm 225 cm C5',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 18.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: '9T1WJKL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T1WJKL8'
    },
    {
      status: 210,
      sku: 'PM0066809',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176192',
      rtl_batch_array: '6176192, 6339867',
      name_search: 'OEFRUTIC',
      name: 'Oenothera fruticosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 209,
      chnn_stock_retail: 1009,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6CVS5GDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CVS5GDE'
    },
    {
      status: 910,
      sku: 'PM0065444',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLHHYBRI',
      name: "Clematis 'Hagley Hybrid'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'H8NYBL6C;ERG8894J;ZY93V8JZ;6GLHTTNW;G5J9Y5WP;8215KY9J;PNL7KLG1;544TE7CA;XEDVEPCD;1E31WSNX;W1AXAN6R;Z54DL677;9YS7N277;THK9J8YW;A9JGZ764;51C7X6KL;69AK7ABP;RN7VGG1H',
      statusContent: 'dot',
      hash: '8HA2YKX4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8HA2YKX4'
    },
    {
      status: 810,
      sku: 'PM0085448',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6359441',
      rtl_batch_array: '6359441',
      name_search: 'ECSMANGO',
      name: 'Echinacea Sensation Mango',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVTGBPWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVTGBPWX'
    },
    {
      status: 810,
      sku: 'PM0066119',
      core_name: 'Plant',
      sppl_ean: '8720664872868',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170293',
      rtl_batch_array: '6170293',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '95SXT426',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95SXT426'
    },
    {
      status: 810,
      sku: 'PM0069574',
      core_name: 'Plant',
      sppl_ean: '8720664871915',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112686',
      rtl_batch_array: '6112686',
      name_search: 'LEPUSILL',
      name: 'Leptinella pusilla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8PDG66T5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PDG66T5'
    },
    {
      status: 910,
      sku: 'PM0047042',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHRCDDRO',
      name: 'Phygelius rectus Candy Drops Deep Rose',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BBDK1Y9R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BBDK1Y9R'
    },
    {
      status: 210,
      sku: 'PM0059456',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6184079',
      rtl_batch_array: '6184079, 6274772',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CEWZPBW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEWZPBW8'
    },
    {
      status: 210,
      sku: 'PM0066812',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176209',
      rtl_batch_array: '6176209',
      name_search: 'COGSUNFI',
      name: "Coreopsis grandiflora 'Sunfire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17032,
      chnn_stock_retail: 17032,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7VYZDE94;NAJLDSSP;STEE1T9V;KKL2V7K4;X64YSEBE;KKDCNXLD',
      statusContent: 'dot',
      hash: 'W446E8LW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W446E8LW'
    },
    {
      status: 210,
      sku: 'PM0085449',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6359442',
      rtl_batch_array: '6359442',
      name_search: 'ERBPBLUE',
      name: "Eryngium bourgatii 'Pen Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '66S3YS12',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66S3YS12'
    },
    {
      status: 210,
      sku: 'PM0085450',
      core_name: 'Plant',
      sppl_ean: '8720664585102',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '5607029',
      rtl_batch_array: '5607029',
      name_search: 'MOCOVERD',
      name: "Molinia caerulea 'Overdam'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BR2AHXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BR2AHXH'
    },
    {
      status: 210,
      sku: 'PM0066811',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176202',
      rtl_batch_array: '6176202, 6330058',
      name_search: 'POMEGALA',
      name: 'Potentilla megalantha',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 562,
      chnn_stock_retail: 818,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HH2LGGHZ',
      statusContent: 'dot',
      hash: 'VR63XK1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VR63XK1P'
    },
    {
      status: 210,
      sku: 'PM0085451',
      core_name: 'Plant',
      sppl_ean: '8720353009711',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '5608762',
      rtl_batch_array: '5608762',
      name_search: 'PEAGOLDS',
      name: "Pennisetum alopecuroides 'Goldstrich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4300,
      chnn_stock_retail: 4300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.588,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BADGJ569',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BADGJ569'
    },
    {
      status: 910,
      sku: 'PM0065446',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLWNIKE',
      name: "Clematis 'Warszawska Nike'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'L5EH2EX1;YLZZCW2Y;P8G3H1SZ;N4RRR2G3;53RBE44D;2H7B571C;N1LDWVJ6',
      statusContent: 'dot',
      hash: 'H9K79876',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H9K79876'
    },
    {
      status: 210,
      sku: 'PM0085452',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6364218',
      rtl_batch_array: '6364218, 6207819',
      name_search: 'CATRACHE',
      name: 'Campanula trachelium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 727,
      chnn_stock_retail: 3302,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A8TWDVE8',
      statusContent: 'dot',
      hash: '64TDH6LH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64TDH6LH'
    },
    {
      status: 210,
      sku: 'PM0059404',
      core_name: 'Plant',
      sppl_ean: '8720664879249',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6114117',
      rtl_batch_array: '6114117',
      name_search: 'PIOMORIK',
      name: 'Picea omorika',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 1166,
      chnn_stock_retail: 1166,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTZ1YHLR;6E88GZGS;VHEP6CJT;C4YRA6N2;XE7N32SW',
      statusContent: 'dot',
      hash: 'P3EB9RXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3EB9RXL'
    },
    {
      status: 810,
      sku: 'PM0085453',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '6380810',
      rtl_batch_array: '6380810',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_prcp: 2.723,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: '7W5X6DAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W5X6DAY'
    },
    {
      status: 810,
      sku: 'PM0047473',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281059',
      rtl_batch_array: '6281059',
      name_search: 'MEGERMAN',
      name: 'Mespilus germanica',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.027,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A74E7AXE;8AS3Y1K6;99GBS7W4;H57WVXRG;LTDZ2597;NLDB3SJS;V1131G5E;D6SCVHDN;452E2C62;KHX9G811;Y3B6B6S7;BZB3X34V;7KRP9NZJ;EHY9HBPC;LXAAK7A5;Z91HW9DJ;BDKVC6HX;6492SSST',
      statusContent: 'dot',
      hash: 'YGWSW9P1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGWSW9P1'
    },
    {
      status: 210,
      sku: 'PM0085454',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6380814',
      rtl_batch_array: '6380814',
      name_search: 'CAOVARIE',
      name: "Carex ornithopoda 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5JAG1B9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JAG1B9Z'
    },
    {
      status: 210,
      sku: 'PM0059307',
      core_name: 'Plant',
      sppl_ean: '8720349484713',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '5417920',
      rtl_batch_array: '5417920',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      statusContent: 'dot',
      hash: 'V74SKBTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V74SKBTJ'
    },
    {
      status: 210,
      sku: 'PM0081922',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349031',
      rtl_batch_array: '6349031',
      name_search: 'VIACALBU',
      name: "Vitex agnus-castus 'Albus'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 3.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECDK1Z69',
      statusContent: 'dot',
      hash: 'D44CXSHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D44CXSHE'
    },
    {
      status: 210,
      sku: 'PM0059630',
      core_name: 'Plant',
      sppl_ean: '8720664878051',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121899',
      rtl_batch_array: '6121899',
      name_search: 'PHOPERFE',
      name: "Phlox (P) 'Orange Perfection'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX1PR4K2',
      statusContent: 'dot',
      hash: 'SBRDZ89Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBRDZ89Y'
    },
    {
      status: 810,
      sku: 'PM0078665',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310395',
      rtl_batch_array: '6310395',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'ZJ78N32R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ78N32R'
    },
    {
      status: 210,
      sku: 'PM0059606',
      core_name: 'Plant',
      sppl_ean: '8720349425228',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354825',
      rtl_batch_array: '5354825',
      name_search: 'PHTATJAN',
      name: "Phlox (P) 'Tatjana'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'E764EEDX',
      statusContent: 'dot',
      hash: 'ZN7PS19K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN7PS19K'
    },
    {
      status: 910,
      sku: 'PM0078666',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'G2JA4Z47',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G2JA4Z47'
    },
    {
      status: 810,
      sku: 'PM0081923',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349032',
      rtl_batch_array: '6349032',
      name_search: 'VIACALBU',
      name: "Vitex agnus-castus 'Albus'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 5.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECDK1Z69',
      statusContent: 'dot',
      hash: 'TZNV8J5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZNV8J5J'
    },
    {
      status: 210,
      sku: 'PM0065465',
      core_name: 'Plant',
      sppl_ean: '8720664876156',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164516',
      rtl_batch_array: '6164516',
      name_search: 'PAFARRIV',
      name: "Paeonia (I) 'First Arrival'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SPHNSDX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPHNSDX6'
    },
    {
      status: 210,
      sku: 'PM0078669',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310402',
      rtl_batch_array: '6310402',
      name_search: 'SPJDRED',
      name: "Spiraea japonica 'Dart's Red'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 4800,
      chnn_stock_retail: 4800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V48RLPL3',
      statusContent: 'dot',
      hash: 'C1KR43BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1KR43BR'
    },
    {
      status: 810,
      sku: 'PM0078670',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310406',
      rtl_batch_array: '6310406',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: '5R7Y5T5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5R7Y5T5X'
    },
    {
      status: 210,
      sku: 'PM0081924',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349036',
      rtl_batch_array: '6386123, 6349036',
      name_search: 'FIVULGAR',
      name: 'Filipendula vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 915,
      chnn_stock_retail: 1403,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPHHC86T;76NWSLHR;2GE8VBZ7;HTX41YGG;SWSE8P4P;E95L6VAL',
      statusContent: 'dot',
      hash: 'TTLRKY75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTLRKY75'
    },
    {
      status: 210,
      sku: 'PM0078671',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310407',
      rtl_batch_array: '6310407',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: '5C6P13K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5C6P13K5'
    },
    {
      status: 210,
      sku: 'PM0078781',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311355',
      rtl_batch_array: '6311355',
      name_search: 'VIMAJOR',
      name: 'Vinca major',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7440,
      chnn_stock_retail: 7440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9DVAL8E9;LKGVHAZJ;7V854YDB',
      statusContent: 'dot',
      hash: 'TLWGJ67X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLWGJ67X'
    },
    {
      status: 810,
      sku: 'PM0079038',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314222',
      rtl_batch_array: '6314222',
      name_search: 'CAVLOKI',
      name: "Calluna vulgaris 'Loki'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '926KHGNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '926KHGNT'
    },
    {
      status: 210,
      sku: 'PM0079039',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314223',
      rtl_batch_array: '6314223',
      name_search: 'CAVMADON',
      name: "Calluna vulgaris 'Madonna'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1170,
      chnn_stock_retail: 1170,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CW8HRKST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW8HRKST'
    },
    {
      status: 210,
      sku: 'PM0068271',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333273',
      rtl_batch_array: '6220823, 6333273, 6352702',
      name_search: 'LILATIFO',
      name: 'Limonium latifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4802,
      chnn_stock_retail: 6411,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A7JAC637;RPA74RT2;T548BHRP;V1CGN6XS;X2AY5Z63;832PHEDG;ZGC373JH;L24CRABL;JA57XHTV',
      statusContent: 'dot',
      hash: 'P7JR2P3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7JR2P3L'
    },
    {
      status: 210,
      sku: 'PM0079040',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314224',
      rtl_batch_array: '6314224',
      name_search: 'CAVSANDY',
      name: "Calluna vulgaris 'Sandy'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 2587,
      chnn_stock_retail: 2587,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AL8457V7;L3A75TXV;4CAYV4TA;NKR5VTGR;KA247EYX;YGRVSR98;4R9RWXHH;BDPYKJAK',
      statusContent: 'dot',
      hash: '6H312BWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H312BWJ'
    },
    {
      status: 210,
      sku: 'PM0079041',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314225',
      rtl_batch_array: '6314225',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2168,
      chnn_stock_retail: 2168,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: 'TW7VSNTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TW7VSNTG'
    },
    {
      status: 210,
      sku: 'PM0079043',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314227',
      rtl_batch_array: '6314227',
      name_search: 'PRTRILOB',
      name: 'Prunus triloba',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 846,
      chnn_stock_retail: 846,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PAPS16V2;W4LBJHRE;N2SCYCLT;CJW44BDS;KJ75DRCN;TH6KVZG6;P7RC93DR;NNAEJDBZ;AV8ZBHRZ;RGL11RL9;TPWR74DX;DEKHEH5R;6S9JEV1J;V8YW3T76;JX379PP5;8S7E9K8A;AXXGJGTK;C71GCGZ3;9RYG2WAS',
      statusContent: 'dot',
      hash: 'YEDG8HLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEDG8HLX'
    },
    {
      status: 810,
      sku: 'PM0079044',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314228',
      rtl_batch_array: '6314228',
      name_search: 'ROANECTA',
      name: "Rosa (F) 'Apricot Nectar'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 2.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E5WL8Z5A;673YST1P;2K7RLY7X',
      statusContent: 'dot',
      hash: '4CVP3X1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CVP3X1D'
    },
    {
      status: 810,
      sku: 'PM0079045',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314230',
      rtl_batch_array: '6314230',
      name_search: 'ROFKING',
      name: 'Rosa (PA) Fairy King',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 2.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H8RGAJZZ;8C43EDY7;DP78BREV;A33WD5YH',
      statusContent: 'dot',
      hash: 'H6C7AE96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6C7AE96'
    },
    {
      status: 810,
      sku: 'PM0079046',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314232',
      rtl_batch_array: '6314232',
      name_search: 'ROLFAIRY',
      name: "Rosa 'Lucky Fairy'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 2.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6T86XN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6T86XN1'
    },
    {
      status: 810,
      sku: 'PM0079047',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314233',
      rtl_batch_array: '6314233',
      name_search: 'ROSATCHM',
      name: "Rosa (F) 'Satchmo'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 2.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P51H98GA;S7C6K5WK;W28TRSSP',
      statusContent: 'dot',
      hash: '64Z8ZHTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64Z8ZHTE'
    },
    {
      status: 210,
      sku: 'PM0079048',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314234',
      rtl_batch_array: '6314234',
      name_search: 'SASALIGN',
      name: 'Sarcococca saligna',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 946,
      chnn_stock_retail: 946,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B55P3E9B;4CVD4RZJ;9351GWL3',
      statusContent: 'dot',
      hash: 'Y2VHS9CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2VHS9CH'
    },
    {
      status: 210,
      sku: 'PM0079049',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314235',
      rtl_batch_array: '6314235',
      name_search: 'TRFORTUN',
      name: 'Trachycarpus fortunei',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 9500,
      chnn_stock_retail: 9500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X74TZTVW;HNW81PGZ;TR1RRV3C;Y2STVG6V;XLPR87S9;6WJPY8RX;72YCZRV6;HAW8S38G;L4TW44ZG;5A55S1YR;74GYT1ZB;2X49JZ5B;PH6RHEYR;BSPXTXZA;49CE93ZP;VT2CBK24;YPHTD5K8;ZD9GDRJT;HRVSZC68;RGZPWRXK;RDZNV767;CXXZL4XL;BRSKWHWY',
      statusContent: 'dot',
      hash: 'PGPHYS1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGPHYS1B'
    },
    {
      status: 210,
      sku: 'PM0081925',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349039',
      rtl_batch_array: '6349039',
      name_search: 'ANHKELWA',
      name: "Anthemis hybrida 'Kelwayi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1581,
      chnn_stock_retail: 1581,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7NVEPHC;ZVSACDN5;CCS55L5B',
      statusContent: 'dot',
      hash: 'X5NR4CG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5NR4CG6'
    },
    {
      status: 210,
      sku: 'PM0079050',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314236',
      rtl_batch_array: '6314236',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: 'TH1GWGZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH1GWGZA'
    },
    {
      status: 210,
      sku: 'PM0059619',
      core_name: 'Plant',
      sppl_ean: '8720626333253',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592258',
      rtl_batch_array: '5592258',
      name_search: 'ACMREBLU',
      name: 'Achillea millefolium Rainbow Ending Blue',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'VVX87LCB',
      statusContent: 'dot',
      hash: 'TDPVJ754',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDPVJ754'
    },
    {
      status: 210,
      sku: 'PM0079051',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314237',
      rtl_batch_array: '6314237',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 937,
      chnn_stock_retail: 937,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: '4W4ZR959',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W4ZR959'
    },
    {
      status: 810,
      sku: 'PM0059648',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350737',
      rtl_batch_array: '6350737',
      name_search: 'PATVARIE',
      name: "Pachysandra terminalis 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HSVRNAR4',
      statusContent: 'dot',
      hash: '6SKB3V3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SKB3V3T'
    },
    {
      status: 210,
      sku: 'PM0065460',
      core_name: 'Plant',
      sppl_ean: '8720664876125',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164511',
      rtl_batch_array: '6164511',
      name_search: 'PACBRILL',
      name: "Paeonia (I) 'Canary Brilliant'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCE7SA7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCE7SA7H'
    },
    {
      status: 210,
      sku: 'PM0079052',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314238',
      rtl_batch_array: '6314238',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 955,
      chnn_stock_retail: 955,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J42GT1YH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J42GT1YH'
    },
    {
      status: 210,
      sku: 'PM0059632',
      core_name: 'Plant',
      sppl_ean: '8720664850736',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121901',
      rtl_batch_array: '6121901',
      name_search: 'AGINTERM',
      name: "Agapanthus 'Intermedius'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6N2LKSDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6N2LKSDY'
    },
    {
      status: 810,
      sku: 'PM0065437',
      core_name: 'Plant',
      sppl_ean: '8720664884069',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164487',
      rtl_batch_array: '6164487',
      name_search: 'RUIFALLG',
      name: "Rubus idaeus 'Fallgold'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BY6DCCVW;G8J6J7LT',
      statusContent: 'dot',
      hash: '76AWRX3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76AWRX3R'
    },
    {
      status: 210,
      sku: 'PM0081926',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349040',
      rtl_batch_array: '6349040',
      name_search: 'AQANBLUE',
      name: "Aquilegia alpina 'Navy Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1704,
      chnn_stock_retail: 1704,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLDED7CC;7CDJ3EZ2;V3WBL7SD;6YBT9YTA',
      statusContent: 'dot',
      hash: '6EVDNL5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EVDNL5Z'
    },
    {
      status: 210,
      sku: 'PM0079053',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314239',
      rtl_batch_array: '6314239',
      name_search: 'LEACGOLD',
      name: 'Leucothoe axillaris Curly Gold',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4951,
      chnn_stock_retail: 4951,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCGCJYRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCGCJYRL'
    },
    {
      status: 210,
      sku: 'PM0079054',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314240',
      rtl_batch_array: '6314240',
      name_search: 'LEACGOLD',
      name: 'Leucothoe axillaris Curly Gold',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 5836,
      chnn_stock_retail: 5836,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WGAHC5JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGAHC5JD'
    },
    {
      status: 210,
      sku: 'PM0066146',
      core_name: 'Plant',
      sppl_ean: '8720664885042',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170331',
      rtl_batch_array: '6170331',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_prcp: 7.337,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: '7JLZWKS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JLZWKS2'
    },
    {
      status: 210,
      sku: 'PM0049556',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184008',
      rtl_batch_array: '6184008',
      name_search: 'CODMAJOR',
      name: "Cotoneaster dammeri 'Major'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V37PA1X8',
      statusContent: 'dot',
      hash: '6YDHW41C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YDHW41C'
    },
    {
      status: 210,
      sku: 'PM0079055',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314241',
      rtl_batch_array: '6314241',
      name_search: 'LEACRED',
      name: "Leucothoe axillaris 'Curly Red'",
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 4790,
      chnn_stock_retail: 4790,
      sppl_prcp: 15.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8H857G1;R8H1ZAJ1;586DR5XV;KV1DANDT;55LZW6WD;8EHDL7EE;ASEKT1V4;J49WYD7L;61KYLN78;CBNKX9YG;1ZS3D7GK;LD38B6JT',
      statusContent: 'dot',
      hash: 'KRD1C6XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRD1C6XG'
    },
    {
      status: 210,
      sku: 'PM0081927',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349042',
      rtl_batch_array: '6349042',
      name_search: 'AQERWHIT',
      name: 'Aquilegia Earlybird Red White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1128,
      chnn_stock_retail: 1128,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZG4C4BG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZG4C4BG'
    },
    {
      status: 810,
      sku: 'PM0066165',
      core_name: 'Plant',
      sppl_ean: '8720664887657',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170360',
      rtl_batch_array: '6170360',
      name_search: 'SYVULGAR',
      name: 'Syringa vulgaris',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2HRB4X5;YKB45ZLV;15KSWZR1;S1LRAANK;A6PWADE1;E1HJATGN;AYDVATA4;WJAJARZR;4361DXJ2;Y4G96EP2;8ZJ1PJ7D',
      statusContent: 'dot',
      hash: '1YVSD45Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YVSD45Y'
    },
    {
      status: 210,
      sku: 'PM0066166',
      core_name: 'Plant',
      sppl_ean: '8720664887664',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170361',
      rtl_batch_array: '6170361',
      name_search: 'SYVULGAR',
      name: 'Syringa vulgaris',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 398,
      chnn_stock_retail: 398,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2HRB4X5;YKB45ZLV;15KSWZR1;S1LRAANK;A6PWADE1;E1HJATGN;AYDVATA4;WJAJARZR;4361DXJ2;Y4G96EP2;8ZJ1PJ7D',
      statusContent: 'dot',
      hash: 'D95NEPBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D95NEPBR'
    },
    {
      status: 810,
      sku: 'PM0078667',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310400',
      rtl_batch_array: '6310400',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'CCKPX2S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCKPX2S6'
    },
    {
      status: 810,
      sku: 'PM0078668',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310401',
      rtl_batch_array: '6310401',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'ZR7WSV2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR7WSV2X'
    },
    {
      status: 810,
      sku: 'PM0065454',
      core_name: 'Plant',
      sppl_ean: '8720664890190',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164505',
      rtl_batch_array: '6164505',
      name_search: 'VIBETA',
      name: "Vitis 'Beta'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 3.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JYLNNBPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYLNNBPV'
    },
    {
      status: 210,
      sku: 'PM0069585',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301327',
      rtl_batch_array: '6350132, 6301327',
      name_search: 'ERGLAUCU',
      name: 'Erigeron glaucus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 602,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DB5JPKLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB5JPKLJ'
    },
    {
      status: 210,
      sku: 'PM0069587',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268734',
      rtl_batch_array: '6268734',
      name_search: 'ERCHRYSA',
      name: 'Erodium chrysanthum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4912W69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4912W69'
    },
    {
      status: 210,
      sku: 'PM0065462',
      core_name: 'Plant',
      sppl_ean: '8720664888777',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164513',
      rtl_batch_array: '6164513',
      name_search: 'TRJSTOSC',
      name: 'Trachelospermum jasminoides Star of Toscane',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A88B4AJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A88B4AJB'
    },
    {
      status: 210,
      sku: 'PM0069591',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350149',
      rtl_batch_array: '6350149, 6215324',
      name_search: 'EUBJOE',
      name: "Eupatorium 'Baby Joe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2280,
      chnn_stock_retail: 2477,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26Y99W1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26Y99W1P'
    },
    {
      status: 210,
      sku: 'PM0081928',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349049',
      rtl_batch_array: '6348286, 6349049',
      name_search: 'ASSCRIST',
      name: "Asplenium scolopendrium 'Cristatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 777,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLRR19ZP;S5ZHNBZS;VV8AE261;ATXELLGS;1NDRZ9HH;Z4KN27NZ;DJWTEDK3;WL22A6C9;9ZG3SEBV',
      statusContent: 'dot',
      hash: '26CP81VH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26CP81VH'
    },
    {
      status: 810,
      sku: 'PM0069593',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350152',
      rtl_batch_array: '6350152',
      name_search: 'EUFCAPRI',
      name: "Eupatorium fortunei 'Capri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PKWL64DK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKWL64DK'
    },
    {
      status: 210,
      sku: 'PM0059601',
      core_name: 'Plant',
      sppl_ean: '8720349424955',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354820',
      rtl_batch_array: '5354820',
      name_search: 'PHDLAVEN',
      name: "Phlox (P) 'David's Lavender'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'S8Y2PEEY',
      statusContent: 'dot',
      hash: '64NB1JSC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64NB1JSC'
    },
    {
      status: 210,
      sku: 'PM0069595',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350157',
      rtl_batch_array: '6350157, 6321922',
      name_search: 'EURBRAUN',
      name: "Eupatorium rugosum 'Braunlaub'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 401,
      chnn_stock_retail: 481,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4YEE1DTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YEE1DTS'
    },
    {
      status: 210,
      sku: 'PM0079042',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314226',
      rtl_batch_array: '6314226',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: 'CRHG6HLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRHG6HLD'
    },
    {
      status: 210,
      sku: 'PM0081929',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349051',
      rtl_batch_array: '6349051, 6349761',
      name_search: 'ASADSCHO',
      name: "Aster alpinus 'Dunkle Sch\u00f6ne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2144,
      chnn_stock_retail: 2612,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AVZ8K4KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVZ8K4KY'
    },
    {
      status: 210,
      sku: 'PM0059643',
      core_name: 'Plant',
      sppl_ean: '8720664877962',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5875170',
      rtl_batch_array: '5875170',
      name_search: 'PHJPINK',
      name: "Phlox (P) 'Jeff's Pink'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '7CNWRX2B',
      statusContent: 'dot',
      hash: '6GEKKV78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GEKKV78'
    },
    {
      status: 210,
      sku: 'PM0081930',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349054',
      rtl_batch_array: '6349054, 6077561',
      name_search: 'ATFFROTS',
      name: "Athyrium filix-femina 'Rotstiel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1073,
      chnn_stock_retail: 1206,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GS26N28E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS26N28E'
    },
    {
      status: 210,
      sku: 'PM0081931',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386069',
      rtl_batch_array: '6349056, 6386069',
      name_search: 'ATOOKANU',
      name: "Athyrium otophorum 'Okanum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2400,
      chnn_stock_retail: 3144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V5TC29CE;EAP7EW83',
      statusContent: 'dot',
      hash: '95KAVWG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95KAVWG4'
    },
    {
      status: 210,
      sku: 'PM0081932',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349057',
      rtl_batch_array: '6349057',
      name_search: 'CAGSCHNE',
      name: "Campanula glomerata 'Schneekrone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12356,
      chnn_stock_retail: 12356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A3Z4VWPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3Z4VWPA'
    },
    {
      status: 210,
      sku: 'PM0059635',
      core_name: 'Plant',
      sppl_ean: '8720664877894',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121904',
      rtl_batch_array: '6121904',
      name_search: 'PHCMENTH',
      name: "Phlox (P) 'Cr\u00e8me de Menthe'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2WGJEVC',
      statusContent: 'dot',
      hash: 'R6HBGYAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6HBGYAW'
    },
    {
      status: 810,
      sku: 'PM0085455',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6380822',
      rtl_batch_array: '6380822',
      name_search: 'POWGIANT',
      name: "Polypodium 'Whitley Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VVDR9XK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VVDR9XK'
    },
    {
      status: 810,
      sku: 'PM0085456',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374008',
      rtl_batch_array: '6374008',
      name_search: 'AZTNANA',
      name: "Azorella trifurcata 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '224ACWLS;T2WBKH1E;NLS6SLHB;4VB4X66P;Z3YVJPDA;4RJ31PW2',
      statusContent: 'dot',
      hash: 'WN54J6K9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WN54J6K9'
    },
    {
      status: 210,
      sku: 'PM0085457',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374009',
      rtl_batch_array: '6374009',
      name_search: 'BEABLOSS',
      name: "Bergenia 'Apple Blossom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DPSK21G8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPSK21G8'
    },
    {
      status: 210,
      sku: 'PM0059628',
      core_name: 'Plant',
      sppl_ean: '8720664850774',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121897',
      rtl_batch_array: '6121897',
      name_search: 'AGSUNFIE',
      name: "Agapanthus 'Sunfield'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JJX421Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJX421Z6'
    },
    {
      status: 210,
      sku: 'PM0085458',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374015',
      rtl_batch_array: '6374015',
      name_search: 'BESFLING',
      name: "Bergenia 'Spring Fling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44HB69G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44HB69G4'
    },
    {
      status: 210,
      sku: 'PM0085459',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374017',
      rtl_batch_array: '6374017',
      name_search: 'HEPEWSPO',
      name: "Helleborus 'P. Ellen White Spotted'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XPLSTJZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPLSTJZV'
    },
    {
      status: 810,
      sku: 'PM0085460',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374021',
      rtl_batch_array: '6374021',
      name_search: 'HEGPEACH',
      name: "Heuchera 'Georgia Peach'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 53,
      chnn_stock_retail: 53,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'THHKWDPB;ZZ158G3E',
      statusContent: 'dot',
      hash: '7WYSEKYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WYSEKYL'
    },
    {
      status: 810,
      sku: 'PM0085461',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374022',
      rtl_batch_array: '6374022',
      name_search: 'HEPPANKY',
      name: "Heuchera 'Pinky Panky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6H4LPAYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H4LPAYK'
    },
    {
      status: 810,
      sku: 'PM0085462',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374028',
      rtl_batch_array: '6374028',
      name_search: 'PASTEMPL',
      name: "Paeonia (LD) 'Shirley Temple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPWTDJ9G;SP2P82ER;LRCNG25H;SHDCJXDE;1D77YH4G;9G5RDZBS;N7GZGE51;8LG4CW7P;XRAHC8PX',
      statusContent: 'dot',
      hash: 'VXW3Y66K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXW3Y66K'
    },
    {
      status: 210,
      sku: 'PM0059634',
      core_name: 'Plant',
      sppl_ean: '8720664850781',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121903',
      rtl_batch_array: '6121903',
      name_search: 'AGWOLGA',
      name: "Agapanthus 'Wolga'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3EZ72AL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3EZ72AL'
    },
    {
      status: 910,
      sku: 'PM0085463',
      core_name: 'Plant',
      sppl_ean: '8720664857452',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6144790',
      rtl_batch_array: '6144790',
      name_search: 'CLSINDIA',
      name: "Clematis 'Star of India'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 2.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VRY1KR7T;PD2L7XXS;HETWTN7G;Y4LW7KES;CS847KD9;SHZVRBEA;H358T23T;7Y3W9T3P;K41G9R63;LRY87G58;7DH1ARPZ;8WZD6BT9;XYP8GNG3;2EEW53E8',
      statusContent: 'dot',
      hash: 'WTWSZYVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTWSZYVP'
    },
    {
      status: 210,
      sku: 'PM0085464',
      core_name: 'Plant',
      sppl_ean: '8720664692015',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084970',
      rtl_batch_array: '6084970',
      name_search: 'HEMVIOL',
      name: "Hesperis matronalis 'Violet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7243,
      chnn_stock_retail: 7243,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WJJ9L64P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJJ9L64P'
    },
    {
      status: 210,
      sku: 'PM0085465',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177632',
      rtl_batch_array: '6177632',
      name_search: 'MISVSKY',
      name: "Miscanthus sinensis 'Vanilla Sky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3100,
      chnn_stock_retail: 3100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.772,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EDJGVV2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDJGVV2K'
    },
    {
      status: 910,
      sku: 'PM0085466',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239560',
      rtl_batch_array: '6239560',
      name_search: 'ALIAUREA',
      name: "Alnus incana 'Aurea'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BP525R1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BP525R1G'
    },
    {
      status: 210,
      sku: 'PM0065466',
      core_name: 'Plant',
      sppl_ean: '8720664850378',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164517',
      rtl_batch_array: '6164517',
      name_search: 'ACMSUMME',
      name: "Achillea millefolium 'Summerwine'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DWTDSHC7',
      statusContent: 'dot',
      hash: '4GENDDP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GENDDP8'
    },
    {
      status: 210,
      sku: 'PM0068262',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6260562',
      rtl_batch_array: '6349071, 6204233, 6386111, 6260562, 6348308',
      name_search: 'DRFMAS',
      name: 'Dryopteris filix-mas',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1195,
      chnn_stock_retail: 18282,
      sppl_order_minimum: 3,
      sppl_prcp: 0.737,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P8PA1NH9',
      statusContent: 'dot',
      hash: 'ZJPES1JN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJPES1JN'
    },
    {
      status: 210,
      sku: 'PM0069603',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350167',
      rtl_batch_array: '6350167',
      name_search: 'FEGGTOUP',
      name: "Festuca glauca 'Golden Toupee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BCJCPGY1',
      statusContent: 'dot',
      hash: 'KYD6JZY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYD6JZY2'
    },
    {
      status: 210,
      sku: 'PM0069604',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350168',
      rtl_batch_array: '6350168, 6349795',
      name_search: 'FEOVINA',
      name: 'Festuca ovina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 826,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '586DPSZR;AZEHT9A3;RYVB96SG;14LPPWNL;47LS6RTT;B319VA28;DSB15SHS;RZ6AT68K;CE27NE9D;8CXJSGTR;176H34VC;D3J39AVS;ZPRPDH96;CATRE45Y;RBK9A48R',
      statusContent: 'dot',
      hash: '7LADWHKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LADWHKY'
    },
    {
      status: 910,
      sku: 'PM0059556',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIJROTUN',
      name: "Ligustrum japonicum 'Rotundifolium'",
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      imageCore: '4Z838WZC;6S3YA3XV',
      statusContent: 'dot',
      hash: '65GS1BG9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '65GS1BG9'
    },
    {
      status: 910,
      sku: 'PM0065451',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLALPINA',
      name: 'Clematis alpina',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '2C7PLSEZ;AE5XEJS4;55X28X29;SAZB1E3B;SP1BKTHH;GK1NTCX9;976R9WLB;4WLYX3XG',
      statusContent: 'dot',
      hash: 'AZL1W1NE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AZL1W1NE'
    },
    {
      status: 210,
      sku: 'PM0065464',
      core_name: 'Plant',
      sppl_ean: '8720664850422',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164515',
      rtl_batch_array: '6164515',
      name_search: 'ACMRLPIN',
      name: 'Achillea millefolium Rainbow Lightning Pink',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HTYSNXL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTYSNXL8'
    },
    {
      status: 910,
      sku: 'PM0059559',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIHWINTO',
      name: "Viburnum hillieri 'Winton'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore: '1CVRYRWV',
      statusContent: 'dot',
      hash: 'JH4RT1D1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JH4RT1D1'
    },
    {
      status: 210,
      sku: 'PM0059603',
      core_name: 'Plant',
      sppl_ean: '8720664878211',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354822',
      rtl_batch_array: '5354822',
      name_search: 'PHRDANCE',
      name: 'Phlox (P) Rainbow Dancer',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'R1XPG8ND',
      statusContent: 'dot',
      hash: 'LS6SXLPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LS6SXLPZ'
    },
    {
      status: 210,
      sku: 'PM0059629',
      core_name: 'Plant',
      sppl_ean: '8720664850743',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6121898',
      rtl_batch_array: '6121898',
      name_search: 'AGPICE',
      name: "Agapanthus 'Polar Ice'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T2YZ4VEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2YZ4VEE'
    },
    {
      status: 210,
      sku: 'PM0078678',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310419',
      rtl_batch_array: '6310419',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 337,
      chnn_stock_retail: 337,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '5TK428CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TK428CW'
    },
    {
      status: 210,
      sku: 'PM0066188',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244278',
      rtl_batch_array: '6173665, 6244278',
      name_search: 'COCNEORU',
      name: 'Convolvulus cneorum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1899,
      chnn_stock_retail: 1911,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D574AA9T;K9GD3V54;1D8XG7E4;6XWVVAG9;TAEAYK35;XC3SBBEB;W1KHWDTY;T2PWDGWD;DEJSYPCK;XSTHJK23;RWY6DZCB;TXKCVSHT;V5CLXH1S;8KZ323JZ;BD2RGXR9;7YD8HX35',
      statusContent: 'dot',
      hash: 'EWKTAXSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWKTAXSX'
    },
    {
      status: 210,
      sku: 'PM0069623',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383366',
      rtl_batch_array: '6383366, 6291722',
      name_search: 'OESSISKI',
      name: "Oenothera speciosa 'Siskiyou'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2034,
      chnn_stock_retail: 2492,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPX61WW1;VW88HK4A;ZE1A98EA;Y8VJC24X;2AN1XKS9;C78V1K5R',
      statusContent: 'dot',
      hash: 'L5XHBXPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5XHBXPW'
    },
    {
      status: 210,
      sku: 'PM0039750',
      core_name: 'Plant',
      sppl_ean: '8720664812499',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5606422',
      rtl_batch_array: '5606422',
      name_search: 'WISINENS',
      name: 'Wisteria sinensis',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 606,
      chnn_stock_retail: 606,
      sppl_order_minimum: 3,
      sppl_prcp: 2.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GR3TKRKN;B8PNDWVB;T1BWX7CJ;ZHBCKZ13;K4W7C5V5;PE78N3BN;JVAC2983;89JLXBDK;9K7V9ELE;1P3HYWHN;XKZ4SZ75;PCPVSE7V;9RC389DS;4BG6DY3J',
      statusContent: 'dot',
      hash: 'GDES1Y7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDES1Y7C'
    },
    {
      status: 210,
      sku: 'PM0078672',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310410',
      rtl_batch_array: '6310410',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: '2XK218HE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XK218HE'
    },
    {
      status: 210,
      sku: 'PM0078673',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310411',
      rtl_batch_array: '6310411',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 379,
      chnn_stock_retail: 379,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'J6PZPE8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6PZPE8L'
    },
    {
      status: 210,
      sku: 'PM0085467',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382311',
      rtl_batch_array: '6382311',
      name_search: 'PEMRBTAI',
      name: 'Pennisetum massaicum Red Bunny Tails',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2550,
      chnn_stock_retail: 2550,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LKDN6XX5;CEC8AJ8W',
      statusContent: 'dot',
      hash: 'PYD9K4T5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYD9K4T5'
    },
    {
      status: 210,
      sku: 'PM0085468',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382312',
      rtl_batch_array: '6382312',
      name_search: 'PEARUBRU',
      name: "Pennisetum advena 'Rubrum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6C25DWW;VJT3ZRAP;ZACZP7TZ;EW59DX9G;N2WYZ1C9;NRLDVYX5;LGD4ZEEG;S4EEAAZR;WVHDRZ99;PBZLX9EG;RTEYHY9B;J9PWY479;98V4VGKK;LWEZ8D7E;TZBW9958;KGX25VCY;KD69RY4A;C9NPZ1N9;738Z415A;SP67698E;LZKLAEVE;RCNZHP1N;TP5Z8GWT;K3Z66JDE;5HN3S3YJ;YPYWCGB2;K3TBB8R4;H42WWAWD',
      statusContent: 'dot',
      hash: 'ZYV7V8YK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYV7V8YK'
    },
    {
      status: 210,
      sku: 'PM0078680',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310424',
      rtl_batch_array: '6310424',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: '6741JHAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6741JHAJ'
    },
    {
      status: 210,
      sku: 'PM0078674',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310412',
      rtl_batch_array: '6310412',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'PK9LP8BJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PK9LP8BJ'
    },
    {
      status: 210,
      sku: 'PM0085469',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382315',
      rtl_batch_array: '6382315',
      name_search: 'SENITIDA',
      name: 'Sesleria nitida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5LPJJSLN;VS4KE7DB',
      statusContent: 'dot',
      hash: 'TNXWG9RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNXWG9RX'
    },
    {
      status: 210,
      sku: 'PM0085470',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382320',
      rtl_batch_array: '6382320',
      name_search: 'MISNIPPO',
      name: "Miscanthus sinensis 'Nippon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZL29PS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZL29PS9'
    },
    {
      status: 210,
      sku: 'PM0069618',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350708',
      rtl_batch_array: '6301736, 6350708',
      name_search: 'NEGPBANK',
      name: "Nepeta grandiflora 'Pool Bank'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '93NGN8VZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93NGN8VZ'
    },
    {
      status: 210,
      sku: 'PM0059706',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196864',
      rtl_batch_array: '6196864, 6118343, 6329833',
      name_search: 'ANDRUBRA',
      name: "Antennaria dioica 'Rubra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 832,
      chnn_stock_retail: 1592,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVWZ4S6L',
      statusContent: 'dot',
      hash: 'WELX2BE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WELX2BE3'
    },
    {
      status: 210,
      sku: 'PM0078675',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310413',
      rtl_batch_array: '6310413',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: '6H3AC4H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H3AC4H1'
    },
    {
      status: 210,
      sku: 'PM0065523',
      core_name: 'Plant',
      sppl_ean: '8720664883352',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164894',
      rtl_batch_array: '6164894',
      name_search: 'RIASCHMI',
      name: "Ribes alpinum 'Schmidt'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVALV4JT',
      statusContent: 'dot',
      hash: 'R8HB14RD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8HB14RD'
    },
    {
      status: 210,
      sku: 'PM0078676',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310414',
      rtl_batch_array: '6310414',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 5500,
      chnn_stock_retail: 5500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'JKV8SNYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKV8SNYE'
    },
    {
      status: 210,
      sku: 'PM0066838',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301401',
      rtl_batch_array: '6301401, 6207798',
      name_search: 'ANSYLVES',
      name: 'Anemone sylvestris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 912,
      chnn_stock_retail: 2464,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDNPY5NV;RYXR445X;1C56H6XT;BA9YNSV8;EKHSHDER;WPL8BCE9;PBPPDXDY;LHT9SD2D;JKYBBLDE',
      statusContent: 'dot',
      hash: '93WJ9G9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93WJ9G9A'
    },
    {
      status: 210,
      sku: 'PM0057857',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246580',
      rtl_batch_array: '6246580',
      name_search: 'PIPGLAUC',
      name: 'Picea pungens glauca',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1714,
      chnn_stock_retail: 1714,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7WZ71PB;1E7VWEBT;ZXLNRY7X;6A46T79J;KSA892BJ;2LSJ3D1W;P1J1D3J3;HSDZZ63Y;L7BBAR18;HXYVN768;G55ASPH1;DLZNXRXV;5S453W62;JNTNCC66;WV7RBSJT;J5PT7Y91;69SGPLWX;LS4H5L6W;DGRWLT63;2L7D5TTH',
      statusContent: 'dot',
      hash: 'CL9NLAB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CL9NLAB1'
    },
    {
      status: 210,
      sku: 'PM0065608',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177583',
      rtl_batch_array: '6252644, 6177583',
      name_search: 'IRPSEUDA',
      name: 'Iris pseudacorus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2688,
      chnn_stock_retail: 3038,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR7KWGHP;BXCAX7PR;WPXV2NYL;HB7747PE;CP7J3V7W;T58DD8AX;WKRA1JTZ;6RE256WJ;WDS1W4G1;H7BNN7EZ;TWHD3WES;A4H1RKP1;W6WZW26N;96V5ZHGC;8T79XHCT;CGJ9DN9E;K8DZYWSX;SNGZDATZ;TK39H2BP;RL8YNC95',
      statusContent: 'dot',
      hash: 'PGY4NDAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGY4NDAN'
    },
    {
      status: 210,
      sku: 'PM0059675',
      core_name: 'Plant',
      sppl_ean: '8720664859432',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117680',
      rtl_batch_array: '6117680',
      name_search: 'DEMFSBWB',
      name: "Delphinium (P) 'Magic Fountain Sky Blue White Bee'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KTE3K1CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTE3K1CJ'
    },
    {
      status: 210,
      sku: 'PM0053043',
      core_name: 'Plant',
      sppl_ean: '8720349431793',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6058021',
      rtl_batch_array: '6058021, 6350228, 6164295, 6295610',
      name_search: 'GEPALBUM',
      name: "Geranium phaeum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 3438,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '476RWV87;J4EW2HKN',
      statusContent: 'dot',
      hash: 'G2ZLLBAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2ZLLBAK'
    },
    {
      status: 210,
      sku: 'PM0069636',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301762',
      rtl_batch_array: '6301762',
      name_search: 'PEAHGOLD',
      name: "Pennisetum alopecuroides 'Hameln Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y58PTPBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y58PTPBS'
    },
    {
      status: 210,
      sku: 'PM0078681',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310425',
      rtl_batch_array: '6310425',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 392,
      chnn_stock_retail: 392,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'VHVZ5H4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHVZ5H4C'
    },
    {
      status: 210,
      sku: 'PM0078682',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310427',
      rtl_batch_array: '6310427',
      name_search: 'SPTHUNBE',
      name: 'Spiraea thunbergii',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5BVPVZ6;X3719ERG',
      statusContent: 'dot',
      hash: '54A5T1G8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54A5T1G8'
    },
    {
      status: 210,
      sku: 'PM0083696',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321571',
      rtl_batch_array: '6321571',
      name_search: 'ALGLOBEM',
      name: "Allium 'Globemaster'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D72SYRWV;BLAA5BH8;4NSYTZWB;K1PS3395;B92HT1P7;YR3NJY95;EKJNHVRD;A9YLCEWH;YLCWV2JW;4R27735H;19145WJK;1DH62ED5;NKP8NGN5;2V753X93;53ZRP3T7;737RW9BD;6ALKLPJD;GHECVHHJ;86ZNVSSH;KP3WGNX9;245PZL9E;YW6WP5BP',
      statusContent: 'dot',
      hash: 'C6PHPDT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6PHPDT3'
    },
    {
      status: 810,
      sku: 'PM0078683',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310430',
      rtl_batch_array: '6310430',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'R9SD2433',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9SD2433'
    },
    {
      status: 210,
      sku: 'PM0078685',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310433',
      rtl_batch_array: '6310433',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'S81PSAZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S81PSAZX'
    },
    {
      status: 210,
      sku: 'PM0078677',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310416',
      rtl_batch_array: '6310416',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 2700,
      chnn_stock_retail: 2700,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: '2VP8852X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VP8852X'
    },
    {
      status: 210,
      sku: 'PM0065526',
      core_name: 'Plant',
      sppl_ean: '8720664886889',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164897',
      rtl_batch_array: '6164897',
      name_search: 'SPJFIREL',
      name: "Spiraea japonica 'Firelight'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6RV2C77;KKHHCA3V;STSC46D8;Y6JNBEPZ;GRNCJ8L4;5X39CDEG;RHCNZLBG;85R683CC;T4W9EBZP',
      statusContent: 'dot',
      hash: 'S2GD8DC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2GD8DC2'
    },
    {
      status: 210,
      sku: 'PM0081933',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349060',
      rtl_batch_array: '6349060',
      name_search: 'CAPLVARI',
      name: "Campanula poscharskyana 'Lisduggan Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4288,
      chnn_stock_retail: 4288,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7W537D2R;2A9K48CK;12WZC96C;N4PLXC4K;KYWHL87X;Z1NLWPSA',
      statusContent: 'dot',
      hash: '6JS71C7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JS71C7B'
    },
    {
      status: 210,
      sku: 'PM0059699',
      core_name: 'Plant',
      sppl_ean: '8720664878785',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117713',
      rtl_batch_array: '6117713',
      name_search: 'PHRCDDRO',
      name: 'Phygelius rectus Candy Drops Deep Rose',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8D5CVCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8D5CVCJ'
    },
    {
      status: 210,
      sku: 'PM0078679',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310422',
      rtl_batch_array: '6310422',
      name_search: 'SPNJBRID',
      name: "Spiraea nipponica 'June Bride'",
      sppl_size_code: '040050C14',
      rng_range_identifier: 'H040045C1.5',
      rng_range_description: 'H40 cm 45 cm C1.5',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZD9G8DS;Y27GCWAD',
      statusContent: 'dot',
      hash: 'RPA6BGXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPA6BGXH'
    },
    {
      status: 210,
      sku: 'PM0069609',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229793',
      rtl_batch_array: '6229793',
      name_search: 'MOBLAUST',
      name: "Monarda 'Blaustrumpf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D5G3KEK8;RGX3G9D9',
      statusContent: 'dot',
      hash: 'VYXBYDTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYXBYDTB'
    },
    {
      status: 210,
      sku: 'PM0066761',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300446',
      rtl_batch_array: '6300446',
      name_search: 'ANHBPINK',
      name: "Anemone hybrida 'Bowles' Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 375,
      chnn_stock_retail: 375,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WRHZ2KR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRHZ2KR5'
    },
    {
      status: 210,
      sku: 'PM0069627',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6291248',
      rtl_batch_array: '6349078, 6350734, 6291248, 6309498',
      name_search: 'OSRPURPU',
      name: "Osmunda regalis 'Purpurascens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 3954,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CNY7JSLL',
      statusContent: 'dot',
      hash: 'E7SPTEYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7SPTEYX'
    },
    {
      status: 210,
      sku: 'PM0074301',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269491',
      rtl_batch_array: '6269491, 6275757, 6310270',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 7074,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'WB6K6TZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB6K6TZN'
    },
    {
      status: 210,
      sku: 'PM0078782',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311357',
      rtl_batch_array: '6311357',
      name_search: 'VIMVARIE',
      name: "Vinca major 'Variegata'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4118,
      chnn_stock_retail: 4118,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZBSSC1Y;GXX15NG3;2BRXA81Y;8DX5YJZH;Y6RE5CYA',
      statusContent: 'dot',
      hash: '4KPHJA23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KPHJA23'
    },
    {
      status: 210,
      sku: 'PM0081934',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349070',
      rtl_batch_array: '6349070, 6348310',
      name_search: 'DRFMLPOL',
      name: "Dryopteris filix-mas 'Linearis Polydactyla'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1008,
      chnn_stock_retail: 1295,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XLR7AYSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLR7AYSD'
    },
    {
      status: 210,
      sku: 'PM0081935',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6338952',
      rtl_batch_array: '6349079, 6353085, 6338952',
      name_search: 'PEDHRED',
      name: "Penstemon digitalis 'Husker Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 799,
      chnn_stock_retail: 2975,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4PJBES4H;4PN42GTS;HZ1SWDD8;HEES2B9X;T1KVKP9K;V4LVY3DS;7CYH8TE1;DSSX55HL;Z49LRAHD;T957CXVW;G1JGK8B9;R44V8GSL;SSJ2C2E8;REGWYW8N;L3DKA41E;G8YEH4YE',
      statusContent: 'dot',
      hash: '5T5TTLJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T5TTLJP'
    },
    {
      status: 210,
      sku: 'PM0078783',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311365',
      rtl_batch_array: '6311365',
      name_search: 'WECANDID',
      name: "Weigela 'Candida'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4GXH9E8;7BS3ASET',
      statusContent: 'dot',
      hash: 'NV6HSE12',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV6HSE12'
    },
    {
      status: 210,
      sku: 'PM0059700',
      core_name: 'Plant',
      sppl_ean: '8720664878808',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117714',
      rtl_batch_array: '6117714',
      name_search: 'PHRCDTAN',
      name: 'Phygelius rectus Candy Drops Tangerine',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1VXCRN7W;92WKXTTJ',
      statusContent: 'dot',
      hash: 'K2E75V2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2E75V2R'
    },
    {
      status: 210,
      sku: 'PM0065577',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317380',
      rtl_batch_array: '6317380, 6252525',
      name_search: 'DEMFWHIT',
      name: "Delphinium 'Magic Fountains White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 688,
      chnn_stock_retail: 1773,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VBT4NRYB',
      statusContent: 'dot',
      hash: 'HLBK493K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLBK493K'
    },
    {
      status: 210,
      sku: 'PM0066747',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 3,
      btch_active_retail: '6338073',
      rtl_batch_array: '6286677, 6338073, 6348784',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 5470,
      chnn_stock_retail: 9240,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'HSXLADZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSXLADZT'
    },
    {
      status: 210,
      sku: 'PM0066836',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207796',
      rtl_batch_array: '6207796',
      name_search: 'ANLSBWHI',
      name: 'Anemone lesseri Spring Beauty White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KGZX2EGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGZX2EGB'
    },
    {
      status: 210,
      sku: 'PM0078684',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310431',
      rtl_batch_array: '6310431',
      name_search: 'SYALBUS',
      name: 'Symphoricarpos albus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1880,
      chnn_stock_retail: 1880,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SH55ZB5R;4JY5CL24',
      statusContent: 'dot',
      hash: '818CG1KR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '818CG1KR'
    },
    {
      status: 210,
      sku: 'PM0078785',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311367',
      rtl_batch_array: '6311367',
      name_search: 'WEFTANGO',
      name: "Weigela florida 'Tango'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2706,
      chnn_stock_retail: 2706,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSJSCG34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSJSCG34'
    },
    {
      status: 210,
      sku: 'PM0078786',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311383',
      rtl_batch_array: '6311383',
      name_search: 'STLMBLUE',
      name: 'Stokesia laevis Mels Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.029,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5T3D293T;EYNS4HYD;CTC2TLT3;L19PEHZD;NJ71Z9EY;H71CJEWP;AXK56734;PJD7ZS9J;5BSVXBAT;AD3VD9HD;LG8V8LAJ',
      statusContent: 'dot',
      hash: '2AWN61DW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AWN61DW'
    },
    {
      status: 210,
      sku: 'PM0079056',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314242',
      rtl_batch_array: '6314242',
      name_search: 'LEATRED',
      name: 'Leucothoe axillaris Twisting Red',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 659,
      chnn_stock_retail: 659,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GPDSSVT1;WBECD9GD;2DV7252G;EZEV4B4Y;SV551YG6;665P2ABA;T4R795E6;X45LCLT1;WZXD18L9;GATD3J41;AY9NBZZ2',
      statusContent: 'dot',
      hash: 'D9A7S4R1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9A7S4R1'
    },
    {
      status: 210,
      sku: 'PM0071252',
      core_name: 'Plant',
      btch_manufacturer: 89,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193861',
      rtl_batch_array: '6193861',
      name_search: 'GAMUCRON',
      name: 'Gaultheria mucronata',
      sppl_size_code: '020025P12',
      rng_range_identifier: 'H020025P12',
      rng_range_description: 'H20 cm 25 cm P12',
      sppl_stock_available: 50000,
      chnn_stock_retail: 50000,
      sppl_prcp: 2.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H9KW94HE;SWDVTV9W;Y85XA82Y;SHAPZ8VR;G6RSPXJK;K1WJPPXL;VATW737P;K3KGJL1L;P9SCLHXS;NC3E7BJC;7RA3D8J7;YKTATAD1;H6BALCYW;48SVYS77;BT7L71LX;1P4T71N1;4CEB2KEK',
      statusContent: 'dot',
      hash: 'LL542EJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL542EJY'
    },
    {
      status: 210,
      sku: 'PM0079057',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314243',
      rtl_batch_array: '6314243',
      name_search: 'LEKHALLO',
      name: 'Leucothoe keiskei Halloween',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1263,
      chnn_stock_retail: 1263,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B396CR9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B396CR9H'
    },
    {
      status: 210,
      sku: 'PM0079058',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314244',
      rtl_batch_array: '6314244',
      name_search: 'LEKRRUBY',
      name: "Leucothoe keiskei 'Royal Ruby'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DELW2ZC;9Z8CRP4D;JZ7LKYEC;28Z6ES5R;VT2R8L9A;5VW35V25;4W719N2W;DB9TBNA6;VAD78P86',
      statusContent: 'dot',
      hash: '5THZ38RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5THZ38RA'
    },
    {
      status: 210,
      sku: 'PM0071253',
      core_name: 'Plant',
      btch_manufacturer: 89,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193873',
      rtl_batch_array: '6193873',
      name_search: 'GAMMANNE',
      name: 'Gaultheria mucronata (mnl)',
      sppl_size_code: '020030C14',
      rng_range_identifier: 'H020030C1.5',
      rng_range_description: 'H20 cm 30 cm C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.794,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BNC7NCL2;DP197LEL;GX7W7T7P;DXS8V8AR;VELZVPLA;V4998E8W;JGBXC5TV;B18K25RN;1RHDJRSS;EAG86HE3;X8EZY3HG;BWKD9BT4;CC9KYV5R;7BP2HCB2;7SN9G3SA;8WZC161D;BHEBKTY2;6BXSN4JZ;NN34JSTW;XSN6H7SK;WG41KAZR;JRSXKJZG',
      statusContent: 'dot',
      hash: 'JDWJCW9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDWJCW9S'
    },
    {
      status: 210,
      sku: 'PM0079059',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314245',
      rtl_batch_array: '6314245',
      name_search: 'LEFMAKIJ',
      name: "Leucothoe fontanesiana 'Makijaz'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6809,
      chnn_stock_retail: 6809,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GHT1129G;KHGLN5HW;2ELJX1HN;9ZHY36XL;KA2RPDVB;6SHANJLW;ETN9N56R;RSR1VWG5;19S8GE49;27NTL3BW;8H7RXYKK;2DSPB39B;RVZNNN1H;YAN7HAWV',
      statusContent: 'dot',
      hash: '4S1PA7V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S1PA7V9'
    },
    {
      status: 210,
      sku: 'PM0071254',
      core_name: 'Plant',
      btch_manufacturer: 89,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193890',
      rtl_batch_array: '6193890',
      name_search: 'GAMMANNE',
      name: 'Gaultheria mucronata (mnl)',
      sppl_size_code: '020025P12',
      rng_range_identifier: 'H020025P12',
      rng_range_description: 'H20 cm 25 cm P12',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BNC7NCL2;DP197LEL;GX7W7T7P;DXS8V8AR;VELZVPLA;V4998E8W;JGBXC5TV;B18K25RN;1RHDJRSS;EAG86HE3;X8EZY3HG;BWKD9BT4;CC9KYV5R;7BP2HCB2;7SN9G3SA;8WZC161D;BHEBKTY2;6BXSN4JZ;NN34JSTW;XSN6H7SK;WG41KAZR;JRSXKJZG',
      statusContent: 'dot',
      hash: 'E3EA9JA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3EA9JA7'
    },
    {
      status: 210,
      sku: 'PM0059693',
      core_name: 'Plant',
      sppl_ean: '8720664811447',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117701',
      rtl_batch_array: '6117701',
      name_search: 'VESABLUE',
      name: 'Veronica spic. Anniversary Blue',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NRR5JLH1;226YR866;Y5GGZC6T;4H6LC1SD;BR1S5DW3',
      statusContent: 'dot',
      hash: 'V8BW6949',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8BW6949'
    },
    {
      status: 210,
      sku: 'PM0059704',
      core_name: 'Plant',
      sppl_ean: '8720664866928',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117723',
      rtl_batch_array: '6117723',
      name_search: 'HEPJOJO',
      name: "Hemerocallis 'Patricia Jojo'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VSDLN1TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSDLN1TN'
    },
    {
      status: 210,
      sku: 'PM0079060',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314246',
      rtl_batch_array: '6314246',
      name_search: 'LIJTEXAN',
      name: "Ligustrum japonicum 'Texanum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2034,
      chnn_stock_retail: 2034,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9AL9VBH2;E98GN7ZG;L9E6NTCY;4K4R16Y7;E6K2J92D;PRRAL57L',
      statusContent: 'dot',
      hash: '76Z1HH1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76Z1HH1S'
    },
    {
      status: 210,
      sku: 'PM0083932',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358901',
      rtl_batch_array: '6358901',
      name_search: 'ALSPHAER',
      name: 'Allium sphaerocephalon',
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 1975,
      chnn_stock_retail: 1975,
      sppl_prcp: 0.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '752NEGPR;A7665ZV9;DXY742ZY;WY1HEWTN;8R6KPCLC;W6BSHNBT;ENXR7P1J;GZRPP213;J55XJXST;YZYTJ1DJ;83YHTE2G;Z46Y6WC2;JJBVWGY3',
      statusContent: 'dot',
      hash: 'HXR4VW7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXR4VW7J'
    },
    {
      status: 210,
      sku: 'PM0085475',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268782',
      rtl_batch_array: '6268782',
      name_search: 'LAACONTR',
      name: "Lavandula angustifolia 'Contrast'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8J3NKH6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8J3NKH6L'
    },
    {
      status: 210,
      sku: 'PM0079061',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314247',
      rtl_batch_array: '6314247',
      name_search: 'LIJTEXAN',
      name: "Ligustrum japonicum 'Texanum'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1185,
      chnn_stock_retail: 1185,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9AL9VBH2;E98GN7ZG;L9E6NTCY;4K4R16Y7;E6K2J92D;PRRAL57L',
      statusContent: 'dot',
      hash: 'BA2LNT7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA2LNT7S'
    },
    {
      status: 210,
      sku: 'PM0079062',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314248',
      rtl_batch_array: '6314248',
      name_search: 'LORBPEAR',
      name: "Lophomyrthus ralphii 'Black Pearl'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3080,
      chnn_stock_retail: 3080,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYW539X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYW539X1'
    },
    {
      status: 210,
      sku: 'PM0066831',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301367',
      rtl_batch_array: '6176311, 6254351, 6301367',
      name_search: 'ACTAYGET',
      name: "Achillea 'Taygetea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 109,
      chnn_stock_retail: 588,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZG4EGGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZG4EGGS'
    },
    {
      status: 810,
      sku: 'PM0081936',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349080',
      rtl_batch_array: '6349080, 6370382',
      name_search: 'PESGRAPE',
      name: "Penstemon 'Sour Grapes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 357,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4Y2B5P6P;7VELANJP;GW51CK97;NDE5LZ2S;HWEJC1PY;5TRN78HD;965VL99P;CDXXG9NP',
      statusContent: 'dot',
      hash: 'RW2GSKPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW2GSKPN'
    },
    {
      status: 210,
      sku: 'PM0070158',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220636',
      rtl_batch_array: '6220636',
      name_search: 'ALRUGOSA',
      name: 'Alcea rugosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RGXXSR2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGXXSR2E'
    },
    {
      status: 210,
      sku: 'PM0064591',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6339797',
      rtl_batch_array: '5519248, 6193607, 6339797',
      name_search: 'EQSCIRPO',
      name: 'Equisetum scirpoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 1179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCY5X1Z1;73WWG99W;5NLGT927;XKK6BKLP;AL713D5B;LLJVD728;TCE4A5WP',
      statusContent: 'dot',
      hash: '9EZEH4GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EZEH4GC'
    },
    {
      status: 210,
      sku: 'PM0079063',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314249',
      rtl_batch_array: '6314249',
      name_search: 'MAEMPERO',
      name: 'Magnolia Emperor',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 569,
      chnn_stock_retail: 569,
      sppl_prcp: 22.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2VLGHEL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VLGHEL8'
    },
    {
      status: 210,
      sku: 'PM0079064',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314250',
      rtl_batch_array: '6314250',
      name_search: 'MAGALAXY',
      name: "Magnolia 'Galaxy'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 18.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CHDCZR8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHDCZR8E'
    },
    {
      status: 210,
      sku: 'PM0079065',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314251',
      rtl_batch_array: '6314251',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1251,
      chnn_stock_retail: 1251,
      sppl_prcp: 18.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'CSTYBWBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSTYBWBL'
    },
    {
      status: 210,
      sku: 'PM0079066',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314252',
      rtl_batch_array: '6314252',
      name_search: 'MAGHKERN',
      name: "Magnolia 'George Henry Kern'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 826,
      chnn_stock_retail: 826,
      sppl_prcp: 13.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDX3ZPEN;PDB2YP8N;E9GRPBCC',
      statusContent: 'dot',
      hash: '87TAEVRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87TAEVRK'
    },
    {
      status: 210,
      sku: 'PM0079067',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314253',
      rtl_batch_array: '6314253',
      name_search: 'MAPPYRAM',
      name: 'Magnolia Pink Pyramid',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 760,
      chnn_stock_retail: 760,
      sppl_prcp: 22.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SD3TL5L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD3TL5L1'
    },
    {
      status: 210,
      sku: 'PM0079068',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314254',
      rtl_batch_array: '6314254',
      name_search: 'MASSATIS',
      name: "Magnolia soulangeana 'Satisfaction'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 151,
      chnn_stock_retail: 151,
      sppl_prcp: 18.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '25V2EEDW;CLSP67PN',
      statusContent: 'dot',
      hash: '8XC4SNCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XC4SNCH'
    },
    {
      status: 210,
      sku: 'PM0079069',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314255',
      rtl_batch_array: '6314255',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 561,
      chnn_stock_retail: 561,
      sppl_prcp: 13.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: 'PH81T3JK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH81T3JK'
    },
    {
      status: 210,
      sku: 'PM0079070',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314256',
      rtl_batch_array: '6314256',
      name_search: 'MASTARBU',
      name: 'Magnolia Starburst',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 22.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B16A23D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B16A23D5'
    },
    {
      status: 810,
      sku: 'PM0081937',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349085',
      rtl_batch_array: '6349085',
      name_search: 'PHLWFLAM',
      name: "Phlox 'White Flame'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWK3KZBW',
      statusContent: 'dot',
      hash: 'PEE8455S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEE8455S'
    },
    {
      status: 210,
      sku: 'PM0079071',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314257',
      rtl_batch_array: '6314257',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 901,
      chnn_stock_retail: 901,
      sppl_prcp: 13.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'CR1GRJ1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR1GRJ1E'
    },
    {
      status: 210,
      sku: 'PM0079072',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314258',
      rtl_batch_array: '6314258',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 896,
      chnn_stock_retail: 896,
      sppl_prcp: 13.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'BSP7S2DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSP7S2DY'
    },
    {
      status: 210,
      sku: 'PM0085476',
      core_name: 'Plant',
      sppl_ean: '8720664852730',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6132958',
      rtl_batch_array: '6132958',
      name_search: 'ASDIVARI',
      name: 'Aster divaricatus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3096,
      chnn_stock_retail: 3096,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JVRPGXXG',
      statusContent: 'dot',
      hash: 'W8E7WBZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8E7WBZA'
    },
    {
      status: 210,
      sku: 'PM0079073',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314259',
      rtl_batch_array: '6314259',
      name_search: 'MAWATERM',
      name: 'Magnolia Watermelon',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1139,
      chnn_stock_retail: 1139,
      sppl_prcp: 22.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RKZZK1B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKZZK1B7'
    },
    {
      status: 210,
      sku: 'PM0079074',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314260',
      rtl_batch_array: '6314260',
      name_search: 'MAYLANTE',
      name: "Magnolia 'Yellow Lantern'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 18.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ARAN4BGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARAN4BGL'
    },
    {
      status: 210,
      sku: 'PM0085477',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300733',
      rtl_batch_array: '6300733',
      name_search: 'EUFVEGET',
      name: "Euonymus fortunei 'Vegetus'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1511,
      chnn_stock_retail: 1511,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T15PSV39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T15PSV39'
    },
    {
      status: 210,
      sku: 'PM0066840',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300419',
      rtl_batch_array: '6300419, 6321880',
      name_search: 'ANGIGAS',
      name: 'Angelica gigas',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2317,
      chnn_stock_retail: 2713,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RK68NPR3',
      statusContent: 'dot',
      hash: 'ZPALL8ZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPALL8ZC'
    },
    {
      status: 210,
      sku: 'PM0066827',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176303',
      rtl_batch_array: '6176303',
      name_search: 'ALLAYON',
      name: "Alstroemeria 'Layon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AB1Y9RH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB1Y9RH3'
    },
    {
      status: 210,
      sku: 'PM0059720',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229424',
      rtl_batch_array: '6229424, 6124315',
      name_search: 'GECLOHFE',
      name: "Geranium cantabrigiense 'Lohfelden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 382,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEBLN9VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEBLN9VW'
    },
    {
      status: 210,
      sku: 'PM0085478',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307692',
      rtl_batch_array: '6307692',
      name_search: 'VAMPILGR',
      name: "Vaccinium macrocarpon 'Pilgrim'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1430,
      chnn_stock_retail: 1430,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B83LB9ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B83LB9ED'
    },
    {
      status: 210,
      sku: 'PM0048345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192758',
      rtl_batch_array: '6192758',
      name_search: 'GEJJPURP',
      name: "Geranium hybrid 'Jolly Jewel Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 718,
      chnn_stock_retail: 718,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TB6YEK9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TB6YEK9G'
    },
    {
      status: 210,
      sku: 'PM0085479',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321747',
      rtl_batch_array: '6321747',
      name_search: 'ROGDHAST',
      name: 'Rosa (H) Gelbe Dagmar Hastrup',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TA5SETH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA5SETH4'
    },
    {
      status: 910,
      sku: 'PM0065502',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAANAGYR',
      name: 'Laburnum anagyroides',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: 'PRKDYWTP',
      statusContent: 'dot',
      hash: '8DJSTSPP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8DJSTSPP'
    },
    {
      status: 210,
      sku: 'PM0078784',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311366',
      rtl_batch_array: '6311366',
      name_search: 'WEFPPRIN',
      name: "Weigela florida 'Pink Princess'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 660,
      chnn_stock_retail: 660,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PGYALCHD;GPY1JCAW;DTAVW5J6',
      statusContent: 'dot',
      hash: 'DP2X1EGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP2X1EGC'
    },
    {
      status: 210,
      sku: 'PM0066183',
      core_name: 'Plant',
      sppl_ean: '8720664849730',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6173653',
      rtl_batch_array: '6173653',
      name_search: 'ABGKALEI',
      name: "Abelia grandiflora 'Kaleidoscope'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_prcp: 11.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TVC8SJ7B;71K9HKKB',
      statusContent: 'dot',
      hash: 'GCTETZZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCTETZZX'
    },
    {
      status: 210,
      sku: 'PM0085480',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321748',
      rtl_batch_array: '6321748',
      name_search: 'ROHANSA',
      name: "Rosa (R) 'Hansa'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 642,
      chnn_stock_retail: 642,
      sppl_order_minimum: 3,
      sppl_prcp: 1.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZJNSG1H;273XXG8W',
      statusContent: 'dot',
      hash: 'PLV16Y4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLV16Y4T'
    },
    {
      status: 210,
      sku: 'PM0069615',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6339866',
      rtl_batch_array: '6257786, 6301741, 6339866',
      name_search: 'NERSUPER',
      name: "Nepeta racemosa 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2976,
      chnn_stock_retail: 4733,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CE445TZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE445TZ3'
    },
    {
      status: 210,
      sku: 'PM0085481',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321749',
      rtl_batch_array: '6321749',
      name_search: 'ROMGRAF',
      name: "Rosa (R) 'Max Graf'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 938,
      chnn_stock_retail: 938,
      sppl_order_minimum: 3,
      sppl_prcp: 1.999,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '695EJCP9;C748T4XP',
      statusContent: 'dot',
      hash: 'R52J6HTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R52J6HTK'
    },
    {
      status: 210,
      sku: 'PM0085482',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321750',
      rtl_batch_array: '6321750',
      name_search: 'ROPAULII',
      name: 'Rosa (H) paulii',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 765,
      chnn_stock_retail: 765,
      sppl_order_minimum: 3,
      sppl_prcp: 1.999,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SSE2LHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SSE2LHC'
    },
    {
      status: 210,
      sku: 'PM0085483',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321751',
      rtl_batch_array: '6321751',
      name_search: 'ROPOLARE',
      name: 'Rosa (H) Polareis',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 875,
      chnn_stock_retail: 875,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RD3SG4WG;53C4XH1R',
      statusContent: 'dot',
      hash: 'XK54WXKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XK54WXKC'
    },
    {
      status: 210,
      sku: 'PM0085484',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321752',
      rtl_batch_array: '6321752',
      name_search: 'RORHANNO',
      name: 'Rosa (H) Rote Hannover',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1060,
      chnn_stock_retail: 1060,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BW5D6S66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BW5D6S66'
    },
    {
      status: 210,
      sku: 'PM0068217',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193787',
      rtl_batch_array: '6193787',
      name_search: 'IRACHOWN',
      name: "Iris (L) 'Ann Chowning'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LK7C23D6',
      statusContent: 'dot',
      hash: 'WYSYTE6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYSYTE6Y'
    },
    {
      status: 210,
      sku: 'PM0048477',
      core_name: 'Plant',
      sppl_ean: '8720626317369',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6017589',
      rtl_batch_array: '6017589',
      name_search: 'PISTENAS',
      name: "Picea sitchensis 'Tenas'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1152,
      chnn_stock_retail: 1152,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9LB1XKSV',
      statusContent: 'dot',
      hash: 'E4DB1K3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4DB1K3P'
    },
    {
      status: 210,
      sku: 'PM0085485',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321753',
      rtl_batch_array: '6321753',
      name_search: 'ROSCABRO',
      name: "Rosa (H) 'Scabrosa'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1145,
      chnn_stock_retail: 1145,
      sppl_order_minimum: 3,
      sppl_prcp: 1.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HL8CYCR9;LHS6SLNL',
      statusContent: 'dot',
      hash: 'D3EPW6W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3EPW6W5'
    },
    {
      status: 210,
      sku: 'PM0085486',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321754',
      rtl_batch_array: '6321754',
      name_search: 'ROWROADR',
      name: 'Rosa (R) White Roadrunner',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KWT1K3CE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWT1K3CE'
    },
    {
      status: 910,
      sku: 'PM0040056',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'XZL3YKHE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XZL3YKHE'
    },
    {
      status: 210,
      sku: 'PM0085487',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330007',
      rtl_batch_array: '6330007',
      name_search: 'LAAMIDDA',
      name: "Lavandula angustifolia 'Middachten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5BS5LXTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BS5LXTC'
    },
    {
      status: 210,
      sku: 'PM0085488',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330009',
      rtl_batch_array: '6330009',
      name_search: 'LAAPPAN',
      name: "Lavandula angustifolia 'Peter Pan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C7JE4823',
      statusContent: 'dot',
      hash: 'PAD56721',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAD56721'
    },
    {
      status: 210,
      sku: 'PM0085489',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330010',
      rtl_batch_array: '6330010',
      name_search: 'LAATLEIG',
      name: "Lavandula angustifolia 'Thumbelina Leigh'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 547,
      chnn_stock_retail: 547,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GG2T4L9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GG2T4L9T'
    },
    {
      status: 210,
      sku: 'PM0066847',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355354',
      rtl_batch_array: '6349908, 6355354',
      name_search: 'CAEAUREA',
      name: "Carex elata 'Aurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 130,
      chnn_stock_retail: 856,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y1S1ZZCY;8XWGT7J2;CEW5P27X;PNA47LHL;BENBS2PP;RL5BRP8T;P5HP6ZVZ;C1XYE8AZ;4RBPLB7S;4T6EA97Y;NVSHJL7A;VX6EY79P',
      statusContent: 'dot',
      hash: 'L46DJZH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L46DJZH8'
    },
    {
      status: 210,
      sku: 'PM0085490',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330011',
      rtl_batch_array: '6330011',
      name_search: 'LAATPURP',
      name: "Lavandula angustifolia 'Twickel Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 431,
      chnn_stock_retail: 431,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SG5A7K7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG5A7K7D'
    },
    {
      status: 210,
      sku: 'PM0085491',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330012',
      rtl_batch_array: '6330012',
      name_search: 'LAIANIGH',
      name: "Lavandula int. 'Arabian Night'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S76AYP47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S76AYP47'
    },
    {
      status: 210,
      sku: 'PM0085492',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6362634',
      rtl_batch_array: '6362634',
      name_search: 'EQJAPONI',
      name: 'Equisetum japonicum',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LCXWAEVC;CH9815T5;D5HKGN7E;SD41BAL2;BG8T3VLN',
      statusContent: 'dot',
      hash: 'Y8CA36AR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8CA36AR'
    },
    {
      status: 810,
      sku: 'PM0085493',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192895',
      rtl_batch_array: '6192895',
      name_search: 'THDUFTKI',
      name: "Thymus 'Duftkissen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 91,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PADZL6CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PADZL6CD'
    },
    {
      status: 210,
      sku: 'PM0069631',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350739',
      rtl_batch_array: '6350739',
      name_search: 'PAVCARDI',
      name: "Panicum virgatum 'Cardinal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 874,
      chnn_stock_retail: 874,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WJYG3E5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJYG3E5A'
    },
    {
      status: 210,
      sku: 'PM0069632',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382307',
      rtl_batch_array: '6350751, 6382307, 6291727',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3850,
      chnn_stock_retail: 6181,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: 'SCRC8JN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCRC8JN6'
    },
    {
      status: 210,
      sku: 'PM0069637',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242174',
      rtl_batch_array: '6242174, 6274855',
      name_search: 'PEAHERBS',
      name: "Pennisetum alopecuroides 'Herbstzauber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1834,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '12ZKVJ4T;D7X86ZYJ;NA9NSN7N;8VHJD6CH;A9K5PEDE;8WHNHETC;E44HCE9T;7Z8ZTA5E;X6WNXSVY;L4B8VAES',
      statusContent: 'dot',
      hash: '42R5DN4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42R5DN4Z'
    },
    {
      status: 810,
      sku: 'PM0069638',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301763',
      rtl_batch_array: '6301763, 6242176',
      name_search: 'PEALHONE',
      name: "Pennisetum alopecuroides 'Little Honey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 85,
      chnn_stock_retail: 448,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '837KASEC;PXC865V9;246H6JRB;YA81155C;X9LDBKDE;7RL2YEY6;SPBTX8SR;2ZBYGPC7;15KPJWHL;PLG24DCA;6RP5PEXR;5BJK623R;YGL63LE3',
      statusContent: 'dot',
      hash: 'BKAW35TH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKAW35TH'
    },
    {
      status: 210,
      sku: 'PM0069641',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353125',
      rtl_batch_array: '6353125',
      name_search: 'PEHPYGMA',
      name: "Penstemon hirsutus 'Pygmaeus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 572,
      chnn_stock_retail: 572,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TX2Y22YP',
      statusContent: 'dot',
      hash: 'YRLSE19X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRLSE19X'
    },
    {
      status: 210,
      sku: 'PM0078686',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310434',
      rtl_batch_array: '6310434',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: '7373EPCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7373EPCR'
    },
    {
      status: 910,
      sku: 'PM0066213',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: '7337CBJ4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7337CBJ4'
    },
    {
      status: 210,
      sku: 'PM0066906',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6229833',
      rtl_batch_array: '6349095, 6229833, 6350350',
      name_search: 'HEDUMORT',
      name: 'Hemerocallis dumortieri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 1028,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JWAS426N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWAS426N'
    },
    {
      status: 210,
      sku: 'PM0066920',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350567',
      rtl_batch_array: '6350567',
      name_search: 'IRQUECHE',
      name: "Iris (G) 'Quechee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WRK9AGWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRK9AGWY'
    },
    {
      status: 210,
      sku: 'PM0059607',
      core_name: 'Plant',
      sppl_ean: '8720349425082',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354828',
      rtl_batch_array: '5354828',
      name_search: 'PHMLBELL',
      name: "Phlox (P) 'Monica Lynden-Bell'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'B7BKHCYB',
      statusContent: 'dot',
      hash: 'Z3SSXBVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3SSXBVK'
    },
    {
      status: 210,
      sku: 'PM0066897',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269613',
      rtl_batch_array: '6350253, 6269613, 6274850',
      name_search: 'GEVERSIC',
      name: 'Geranium versicolor',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 978,
      chnn_stock_retail: 2415,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GL8KK64V;YZTVLP64',
      statusContent: 'dot',
      hash: '5KGP6ZSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KGP6ZSS'
    },
    {
      status: 210,
      sku: 'PM0066886',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176422',
      rtl_batch_array: '6176422',
      name_search: 'HEPONCHO',
      name: "Helenium 'Poncho'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCR92AGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCR92AGJ'
    },
    {
      status: 910,
      sku: 'PM0053047',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACRCORDI',
      name: 'Actaea racemosa cordifolia',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'W2RA76T4;SKWTAJE5;R8AW2ZY2;NPBW26CN',
      statusContent: 'dot',
      hash: 'L4YH14JL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L4YH14JL'
    },
    {
      status: 910,
      sku: 'PM0078688',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: '1B54NV5K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1B54NV5K'
    },
    {
      status: 210,
      sku: 'PM0078689',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310439',
      rtl_batch_array: '6373389, 6310439',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 190,
      chnn_stock_retail: 690,
      sppl_prcp: 5.07,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'NETSXL6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NETSXL6L'
    },
    {
      status: 210,
      sku: 'PM0078691',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310441',
      rtl_batch_array: '6310441',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '100110C5',
      rng_range_identifier: 'H100110C5',
      rng_range_description: 'H100 cm 110 cm C5',
      sppl_stock_available: 836,
      chnn_stock_retail: 836,
      sppl_prcp: 4.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'T1DZCWZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1DZCWZE'
    },
    {
      status: 210,
      sku: 'PM0058471',
      core_name: 'Plant',
      sppl_ean: '8720664699854',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112805',
      rtl_batch_array: '6112805',
      name_search: 'MUCPCLOU',
      name: "Muhlenbergia capillaris 'Pink Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A1DESPAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1DESPAG'
    },
    {
      status: 910,
      sku: 'PM0078692',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TICORDAT',
      name: 'Tilia cordata',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      imageCore:
        'NCG6PYVL;RDEHBSC7;6VSVBNK7;KXN4ASEV;JDPR21AD;SEZPDXWN;A4LW64YJ',
      statusContent: 'dot',
      hash: 'KHGGEG5N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KHGGEG5N'
    },
    {
      status: 910,
      sku: 'PM0078693',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: 'DV9VRATE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DV9VRATE'
    },
    {
      status: 210,
      sku: 'PM0081938',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349094',
      rtl_batch_array: '6349094',
      name_search: 'HECQUEEN',
      name: "Helianthemum 'Cerise Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4448,
      chnn_stock_retail: 4448,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EGPA8AT;4YG87RRG;87PAC67R;8EHCR25W;A92XAGAB;CYDL58PJ;K18PV6X9',
      statusContent: 'dot',
      hash: '1P4GZV34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1P4GZV34'
    },
    {
      status: 210,
      sku: 'PM0081939',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350380',
      rtl_batch_array: '6349098, 6350380, 6320878',
      name_search: 'HEBSEA',
      name: "Heuchera 'Black Sea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 967,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JX7HHVZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JX7HHVZ2'
    },
    {
      status: 210,
      sku: 'PM0078695',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310445',
      rtl_batch_array: '6310445',
      name_search: 'VILANTAN',
      name: 'Viburnum lantana',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 3226,
      chnn_stock_retail: 3226,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8RJDYE1;E5TGX8J3;EEWC33GD;99BYSAN4;KP98VB62;SKRTREDV;YX7YB1LL;H199BA28;6ZPK789N;XECZ6GZ3;L1LCTX4J;9EZ4NXZ2;W5GJZ4DE;EA5A97P3;DNHA5G5K;7J524CB3',
      statusContent: 'dot',
      hash: 'ZGXX1R53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGXX1R53'
    },
    {
      status: 210,
      sku: 'PM0081940',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349101',
      rtl_batch_array: '6349101, 6350514',
      name_search: 'HOMINUTE',
      name: "Hosta 'Minuteman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3792,
      chnn_stock_retail: 4032,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K65R6EWZ;VV3H5S8L;T911ZXL7;61BKGKZZ;CX8LX6A2;W62SPXGX;D9L2GZA6',
      statusContent: 'dot',
      hash: 'JYZ9W7EH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYZ9W7EH'
    },
    {
      status: 910,
      sku: 'PM0071257',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGSUNFIE',
      name: "Agapanthus 'Sunfield'",
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      statusContent: 'dot',
      hash: 'AGJZSSRT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AGJZSSRT'
    },
    {
      status: 210,
      sku: 'PM0078696',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310446',
      rtl_batch_array: '6310446',
      name_search: 'VILANTAN',
      name: 'Viburnum lantana',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 233,
      chnn_stock_retail: 233,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8RJDYE1;E5TGX8J3;EEWC33GD;99BYSAN4;KP98VB62;SKRTREDV;YX7YB1LL;H199BA28;6ZPK789N;XECZ6GZ3;L1LCTX4J;9EZ4NXZ2;W5GJZ4DE;EA5A97P3;DNHA5G5K;7J524CB3',
      statusContent: 'dot',
      hash: 'WLS5DKND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLS5DKND'
    },
    {
      status: 210,
      sku: 'PM0078697',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310451',
      rtl_batch_array: '6310451',
      name_search: 'WEERATHK',
      name: "Weigela 'Eva Rathke'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R9847CB1;RRA9W7GB',
      statusContent: 'dot',
      hash: 'YLPN3PC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLPN3PC2'
    },
    {
      status: 210,
      sku: 'PM0085471',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383351',
      rtl_batch_array: '6383351, 6386122',
      name_search: 'FIULMARI',
      name: 'Filipendula ulmaria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 533,
      chnn_stock_retail: 1933,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V3P6J7RJ',
      statusContent: 'dot',
      hash: '8TWDZ433',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TWDZ433'
    },
    {
      status: 210,
      sku: 'PM0079085',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314277',
      rtl_batch_array: '6314277',
      name_search: 'PIEJPPAS',
      name: 'Pieris japonica Polar Passion',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2252,
      chnn_stock_retail: 2252,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CJ7CHTPY',
      statusContent: 'dot',
      hash: 'P4NY5ZBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4NY5ZBP'
    },
    {
      status: 210,
      sku: 'PM0059738',
      core_name: 'Plant',
      sppl_ean: '8720664876279',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125554',
      rtl_batch_array: '6125554',
      name_search: 'PAPECHE',
      name: "Paeonia (LD) 'Peche'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BS3HNRW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS3HNRW3'
    },
    {
      status: 210,
      sku: 'PM0085472',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383376',
      rtl_batch_array: '6383376',
      name_search: 'STBHVSTE',
      name: "Stachys byzantina 'Helene Von Stein'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1544,
      chnn_stock_retail: 1544,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '64E66P3G;Z9S1SCPY',
      statusContent: 'dot',
      hash: 'JP6VZXE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP6VZXE4'
    },
    {
      status: 810,
      sku: 'PM0066918',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350565',
      rtl_batch_array: '6350565',
      name_search: 'IRNIGHTF',
      name: "Iris (G) 'Nightfall'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGT4BEPC',
      statusContent: 'dot',
      hash: 'BPLZ814W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPLZ814W'
    },
    {
      status: 210,
      sku: 'PM0079086',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314278',
      rtl_batch_array: '6314278',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1435,
      chnn_stock_retail: 1435,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'JLKJPJSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLKJPJSX'
    },
    {
      status: 210,
      sku: 'PM0059749',
      core_name: 'Plant',
      sppl_ean: '8720664850408',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6125567',
      rtl_batch_array: '6125567, 6185785',
      name_search: 'ACMMRROS',
      name: 'Achillea millefolium Milly Rock Rose',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CT7ZSA4D;5GSPXCRN',
      statusContent: 'dot',
      hash: 'ESJ2WCPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESJ2WCPK'
    },
    {
      status: 210,
      sku: 'PM0059733',
      core_name: 'Plant',
      sppl_ean: '8720664881402',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125548',
      rtl_batch_array: '6125548',
      name_search: 'POPOLYBL',
      name: 'Polystichum polyblepharum',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.509,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLEW96CY;YVSXH5L4;CER6HTPA;LBAHT1GD;GVACZPJK;RBGE8TGS;7AHKBL7C;NHEA7N3E;AV2AHLS3;C7LL43WE;1HP1YKXT',
      statusContent: 'dot',
      hash: 'DA5H6NAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA5H6NAN'
    },
    {
      status: 210,
      sku: 'PM0079087',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314281',
      rtl_batch_array: '6314281',
      name_search: 'KNNOBILI',
      name: "Kniphofia 'Nobilis'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 2.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '69H7621G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69H7621G'
    },
    {
      status: 210,
      sku: 'PM0085494',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349634',
      rtl_batch_array: '6349634',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_prcp: 8.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '7LE27371',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LE27371'
    },
    {
      status: 210,
      sku: 'PM0065575',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254691',
      rtl_batch_array: '6254691',
      name_search: 'CYFORTUN',
      name: 'Cyrtomium fortunei',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1395,
      chnn_stock_retail: 1395,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BH5579JG',
      statusContent: 'dot',
      hash: 'XHJ3XRVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHJ3XRVH'
    },
    {
      status: 210,
      sku: 'PM0059725',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353412',
      rtl_batch_array: '6353412',
      name_search: 'SESSEPTE',
      name: "Sedum spectabile 'Septemberglut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XREXCCPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XREXCCPG'
    },
    {
      status: 210,
      sku: 'PM0066883',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254373',
      rtl_batch_array: '6254373, 6355359',
      name_search: 'FEGAUTIE',
      name: 'Festuca gautieri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 3283,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CPWB3TK4;WRBWGCRB;K5KDSBY6;7Z1VRHL3;TY2JSAZY;NPVWV9J8;SD9S95A6;YA6BB8AK;9LWLP1SJ;4X6TJTPZ;DJH79ZW8',
      statusContent: 'dot',
      hash: 'SNL4ATKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNL4ATKK'
    },
    {
      status: 210,
      sku: 'PM0066865',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349947',
      rtl_batch_array: '6349947',
      name_search: 'COMDOREE',
      name: "Convallaria majalis 'Doreen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LN63AZZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LN63AZZ5'
    },
    {
      status: 810,
      sku: 'PM0078787',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301548',
      rtl_batch_array: '6301548',
      name_search: 'ECCPURPL',
      name: "Echinacea 'Chunky Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7PN57VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7PN57VJ'
    },
    {
      status: 210,
      sku: 'PM0066876',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350139',
      rtl_batch_array: '6350139',
      name_search: 'ERAAMETH',
      name: "Eryngium alpinum 'Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EAXXJHVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAXXJHVR'
    },
    {
      status: 810,
      sku: 'PM0057876',
      core_name: 'Plant',
      sppl_ean: '8720664883185',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099397',
      rtl_batch_array: '6099397',
      name_search: 'RHKALINK',
      name: "Rhododendron (Y) 'Kalinka'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCW5D6A7;GSE5T1HT;E66CLH3P;R2AHDY2V;WJ3P9BDJ;D48BKPP2;ZJ6EYRL6;D8CATP5P;C82VGJYP;2AVDESBJ;9RHJY3DK;SDVRCADJ;2X35CTGB;ZVVT4RVL;RARE6P8K;9SSKGBLW;4GBN9J8V',
      statusContent: 'dot',
      hash: 'JV8PVLD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV8PVLD6'
    },
    {
      status: 210,
      sku: 'PM0059722',
      core_name: 'Plant',
      sppl_ean: '8720664882836',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161918',
      rtl_batch_array: '6161918',
      name_search: 'PUSBLUE',
      name: "Pulmonaria 'Smokey Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1CR7TSBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CR7TSBT'
    },
    {
      status: 210,
      sku: 'PM0079075',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314262',
      rtl_batch_array: '6314262',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'H26X3NS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H26X3NS6'
    },
    {
      status: 210,
      sku: 'PM0079088',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314283',
      rtl_batch_array: '6314283',
      name_search: 'AJRCCHIP',
      name: "Ajuga reptans 'Chocolate Chip'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 921,
      chnn_stock_retail: 921,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PD762G5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PD762G5N'
    },
    {
      status: 210,
      sku: 'PM0079076',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314263',
      rtl_batch_array: '6314263',
      name_search: 'NADMLLIM',
      name: 'Nandina domestica Magical Lemon & Lime',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2216,
      chnn_stock_retail: 2216,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVY8RD8G;G99N52BD;447JW7Z1;ZJBGXJ9W;K5R4AZL3;6HEXD3D3',
      statusContent: 'dot',
      hash: '1R9LA7V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R9LA7V4'
    },
    {
      status: 210,
      sku: 'PM0085495',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349635',
      rtl_batch_array: '6349635',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 992,
      chnn_stock_retail: 992,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: 'X886KAC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X886KAC7'
    },
    {
      status: 210,
      sku: 'PM0066904',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176457',
      rtl_batch_array: '6176457',
      name_search: 'HOVMOON',
      name: "Hosta 'Velvet Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G77PY7VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G77PY7VX'
    },
    {
      status: 210,
      sku: 'PM0065578',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234665',
      rtl_batch_array: '6234665',
      name_search: 'DEFLEXUO',
      name: 'Deschampsia flexuosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 677,
      chnn_stock_retail: 677,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1Y6ANSEL;63NZ8BHZ;AV5HEKV4',
      statusContent: 'dot',
      hash: '913BKRT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '913BKRT9'
    },
    {
      status: 210,
      sku: 'PM0028399',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308615',
      rtl_batch_array: '6136213, 6054292, 6308615',
      name_search: 'ACMPGRAP',
      name: "Achillea millefolium 'Pink Grapefruit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 624,
      chnn_stock_retail: 1031,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JNCS1SPJ',
      statusContent: 'dot',
      hash: 'ET6Z8HL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET6Z8HL9'
    },
    {
      status: 810,
      sku: 'PM0066821',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302337',
      rtl_batch_array: '6176295, 6302337',
      name_search: 'ALMROBUS',
      name: "Alchemilla mollis 'Robustica'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 21,
      chnn_stock_retail: 379,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPR674ZE;91KVPJPL',
      statusContent: 'dot',
      hash: '4BZDKKT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BZDKKT2'
    },
    {
      status: 210,
      sku: 'PM0066914',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350559',
      rtl_batch_array: '6350559',
      name_search: 'IREWOLFO',
      name: "Iris (G) 'Edith Wolford'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SXJTTZZD;WEBGR2P4;2Y3CKK1T;8CW9WVJ6',
      statusContent: 'dot',
      hash: 'YBR31BP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBR31BP5'
    },
    {
      status: 210,
      sku: 'PM0079077',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314265',
      rtl_batch_array: '6314265',
      name_search: 'PHFLOUIS',
      name: 'Photinia fraseri Louise',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2061,
      chnn_stock_retail: 2061,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KC195TTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KC195TTL'
    },
    {
      status: 210,
      sku: 'PM0066905',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252863',
      rtl_batch_array: '6252863, 6254428',
      name_search: 'HEFOETID',
      name: 'Helleborus foetidus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1076,
      chnn_stock_retail: 1837,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '62T7KYWZ;4R7DBHLA;95NAAGSK;D7VLL7J5;ABW1KCTD',
      statusContent: 'dot',
      hash: '1R7T7TYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R7T7TYC'
    },
    {
      status: 210,
      sku: 'PM0079078',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314266',
      rtl_batch_array: '6314266',
      name_search: 'PHFLOUIS',
      name: 'Photinia fraseri Louise',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1S6CH2V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1S6CH2V1'
    },
    {
      status: 210,
      sku: 'PM0079082',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314274',
      rtl_batch_array: '6314274',
      name_search: 'PIJPPASS',
      name: 'Pieris japonica Pink Passion',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1971,
      chnn_stock_retail: 1971,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9VLCRNBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VLCRNBV'
    },
    {
      status: 210,
      sku: 'PM0059727',
      core_name: 'Plant',
      sppl_ean: '8720664868625',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6124620',
      rtl_batch_array: '6124620',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_prcp: 1.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '423HADT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '423HADT2'
    },
    {
      status: 910,
      sku: 'PM0085496',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349650',
      rtl_batch_array: '6349650',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040C12BOL',
      rng_range_identifier: 'BALL\u00d8040H040040C12',
      rng_range_description: 'Ball \u00d8 40 cm H40 cm 40 cm C12',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 32.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'SR4GRSRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SR4GRSRK'
    },
    {
      status: 210,
      sku: 'PM0085497',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6362934',
      rtl_batch_array: '6362934',
      name_search: 'PHAUSTRA',
      name: 'Phragmites australis',
      sppl_size_code: 'BSTT77',
      rng_range_identifier: 'CUTTROCutting',
      rng_range_description: 'Cutting Rooted Cutting',
      sppl_stock_available: 319550,
      chnn_stock_retail: 319550,
      sppl_order_minimum: 1,
      sppl_prcp: 0.343,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9TB4A4Z;LXBT97CP;ZS4P7HSG;4291A3CA;PX94H3ND;X96L4HRN;2R39RHKW',
      statusContent: 'dot',
      hash: 'HVPA4RXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVPA4RXS'
    },
    {
      status: 210,
      sku: 'PM0065580',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254707',
      rtl_batch_array: '6254707',
      name_search: 'DRCYCADI',
      name: 'Dryopteris cycadina',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 451,
      chnn_stock_retail: 451,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ECX99X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ECX99X2'
    },
    {
      status: 210,
      sku: 'PM0000646',
      core_name: 'Plant',
      sppl_ean: '8720664692961',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495604',
      rtl_batch_array: '5495604, 6321574',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 851,
      chnn_stock_retail: 1349,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: '85SHCS84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85SHCS84'
    },
    {
      status: 210,
      sku: 'PM0059745',
      core_name: 'Plant',
      sppl_ean: '8720664853218',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125561',
      rtl_batch_array: '6125561',
      name_search: 'ATNIPONI',
      name: 'Athyrium niponicum',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.509,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HCNWHXCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCNWHXCA'
    },
    {
      status: 210,
      sku: 'PM0079083',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314275',
      rtl_batch_array: '6314275',
      name_search: 'PIJPPASS',
      name: 'Pieris japonica Pink Passion',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2443,
      chnn_stock_retail: 2443,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9T18EP8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T18EP8E'
    },
    {
      status: 210,
      sku: 'PM0078690',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310440',
      rtl_batch_array: '6310440',
      name_search: 'ALGLUTIN',
      name: 'Alnus glutinosa',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1959,
      chnn_stock_retail: 1959,
      sppl_prcp: 1.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BW17L2SZ;LV96GEJD;CT86XB4Z;HYDK4GYV;HKJXJXSX',
      statusContent: 'dot',
      hash: 'Z9N68SNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9N68SNP'
    },
    {
      status: 210,
      sku: 'PM0078687',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310436',
      rtl_batch_array: '6310436',
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 385,
      chnn_stock_retail: 385,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9R77LY5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9R77LY5R'
    },
    {
      status: 210,
      sku: 'PM0059735',
      core_name: 'Plant',
      sppl_ean: '8720664876187',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125550',
      rtl_batch_array: '6125550',
      name_search: 'PASORBET',
      name: "Paeonia (L) 'Sorbet'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CT4GPKBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT4GPKBX'
    },
    {
      status: 210,
      sku: 'PM0059737',
      core_name: 'Plant',
      sppl_ean: '8720664876231',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125552',
      rtl_batch_array: '6125552',
      name_search: 'PAKANSAS',
      name: "Paeonia (LD) 'Kansas'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXTZJ9WG',
      statusContent: 'dot',
      hash: 'AWHP7L47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWHP7L47'
    },
    {
      status: 910,
      sku: 'PM0059553',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: 'T28AED6D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T28AED6D'
    },
    {
      status: 210,
      sku: 'PM0085498',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382249',
      rtl_batch_array: '6382249',
      name_search: 'PATVARIE',
      name: "Pachysandra terminalis 'Variegata'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3107,
      chnn_stock_retail: 3107,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HSVRNAR4',
      statusContent: 'dot',
      hash: 'XRBDD7YK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRBDD7YK'
    },
    {
      status: 210,
      sku: 'PM0079079',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314268',
      rtl_batch_array: '6314268',
      name_search: 'PIFSILVE',
      name: "Pieris 'Flaming Silver'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2654,
      chnn_stock_retail: 2654,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCXSKVBJ;HVLPYJBZ;JT4D138Z;VA7AA76B;EP883SGR;H4EX7KA9;YW3E6JLJ;CA16JVCG;Y1PYT182;KKYGL9RE;YJB4NAY4;15XGJLJN;A3DNJ4NP;YJYZ14YH;7ST6YLTR;NG55PDP7;YXD8N8BG;ZJ23XEES;NH3KSJ9C;NXSZK6HG;BGYB1247',
      statusContent: 'dot',
      hash: '4HR9SBRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HR9SBRT'
    },
    {
      status: 210,
      sku: 'PM0065579',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254695',
      rtl_batch_array: '6254695',
      name_search: 'DIDALBIF',
      name: "Dianthus deltoides 'Albiflorus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 534,
      chnn_stock_retail: 534,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S2NTTPZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2NTTPZS'
    },
    {
      status: 210,
      sku: 'PM0074302',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269493',
      rtl_batch_array: '6269493, 6336307',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6842,
      chnn_stock_retail: 24051,
      sppl_prcp: 1.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      statusContent: 'dot',
      hash: 'W4PNWTW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4PNWTW2'
    },
    {
      status: 210,
      sku: 'PM0066911',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176474',
      rtl_batch_array: '6176474, 6356605',
      name_search: 'LIMGBAND',
      name: "Liriope muscari 'Gold Banded'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 544,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W4CZDJ5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4CZDJ5T'
    },
    {
      status: 810,
      sku: 'PM0066826',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349856',
      rtl_batch_array: '6349856',
      name_search: 'ALCHINON',
      name: "Alstroemeria 'Chinon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8759JPBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8759JPBW'
    },
    {
      status: 210,
      sku: 'PM0070159',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220637',
      rtl_batch_array: '6220637',
      name_search: 'ALSERRAN',
      name: "Alstroemeria 'Serrant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AX7TNJL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX7TNJL4'
    },
    {
      status: 210,
      sku: 'PM0085499',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6382970',
      rtl_batch_array: '6382970',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '060070KL',
      rng_range_identifier: 'H060070RB',
      rng_range_description: 'H60 cm 70 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 9.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: '9LK7DZ7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LK7DZ7L'
    },
    {
      status: 210,
      sku: 'PM0071262',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221896',
      rtl_batch_array: '6221896',
      name_search: 'HOUALBOM',
      name: "Hosta 'Undulata Albomarginata'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SCNVJAPP',
      statusContent: 'dot',
      hash: 'TR2V7Y4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR2V7Y4R'
    },
    {
      status: 210,
      sku: 'PM0085500',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383802',
      rtl_batch_array: '6383802',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 8.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: '1BKZVSWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BKZVSWG'
    },
    {
      status: 210,
      sku: 'PM0059731',
      core_name: 'Plant',
      sppl_ean: '8720664859166',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125546',
      rtl_batch_array: '6125546',
      name_search: 'CYFCLIVI',
      name: 'Cyrtomium fortunei clivicola',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.509,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GYGN6R7S;7GHHJ25D;19BXAKPN;41X6LRJK;NJ8T547A;EGRDK3VA;4SG7TVHZ;L72PY5X8',
      statusContent: 'dot',
      hash: '69CWPWNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69CWPWNA'
    },
    {
      status: 210,
      sku: 'PM0066878',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349792',
      rtl_batch_array: '6350160, 6349792',
      name_search: 'EUCCHARA',
      name: 'Euphorbia characias characias',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 275,
      chnn_stock_retail: 1075,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4H52RNWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4H52RNWY'
    },
    {
      status: 810,
      sku: 'PM0066881',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176412',
      rtl_batch_array: '6176412',
      name_search: 'EUPTEICH',
      name: "Euphorbia palustris 'Teichlaterne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZYKLBDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZYKLBDG'
    },
    {
      status: 910,
      sku: 'PM0066889',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEDBLUE',
      name: 'Geranium Daily Blue',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'SL357Z5S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SL357Z5S'
    },
    {
      status: 210,
      sku: 'PM0059729',
      core_name: 'Plant',
      sppl_ean: '8720664876095',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125543',
      rtl_batch_array: '6125543',
      name_search: 'PASCHIFF',
      name: "Paeonia 'Salmon Chiffon'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X55H8HN1',
      statusContent: 'dot',
      hash: 'H7BNN6V6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7BNN6V6'
    },
    {
      status: 210,
      sku: 'PM0085501',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383816',
      rtl_batch_array: '6383816',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_prcp: 31.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'PA97WL67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA97WL67'
    },
    {
      status: 210,
      sku: 'PM0059602',
      core_name: 'Plant',
      sppl_ean: '8720349425044',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354821',
      rtl_batch_array: '5354821',
      name_search: 'PHLBOY',
      name: "Phlox (P) 'Little Boy'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ET13CZVD;J4NX16RK',
      imageBatch: '1SZVELHJ',
      statusContent: 'dot',
      hash: 'K8XH4EWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8XH4EWN'
    },
    {
      status: 210,
      sku: 'PM0066890',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207984',
      rtl_batch_array: '6207984',
      name_search: 'GEMTURCO',
      name: "Geranium magnificum 'Turco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VNVRRJZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNVRRJZP'
    },
    {
      status: 210,
      sku: 'PM0066896',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176443',
      rtl_batch_array: '6176443',
      name_search: 'GESSNOWF',
      name: 'Geranium sanguineum Snowflake',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E36TGX93',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E36TGX93'
    },
    {
      status: 210,
      sku: 'PM0085502',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383817',
      rtl_batch_array: '6383817',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'DS3BH867',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DS3BH867'
    },
    {
      status: 210,
      sku: 'PM0085503',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383818',
      rtl_batch_array: '6383818',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 28.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'YXRVXNYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXRVXNYC'
    },
    {
      status: 210,
      sku: 'PM0066912',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176477',
      rtl_batch_array: '6176477',
      name_search: 'IRCALIGU',
      name: "Iris (G) 'Caligula'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 293,
      chnn_stock_retail: 293,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V7TL3VNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7TL3VNZ'
    },
    {
      status: 210,
      sku: 'PM0079080',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314271',
      rtl_batch_array: '6314271',
      name_search: 'PIJBONFI',
      name: "Pieris japonica 'Bonfire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2333,
      chnn_stock_retail: 2333,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DEBYHSP6;NE42JZBH;PTYAZLJE;TJYRW5GJ;PAAPTBDT;GWHTY7X7;GJPL62LD;SNNC9XGJ;YXT4SCR5;SERY8H6V;4T61H7VG',
      statusContent: 'dot',
      hash: '4AX9XK4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AX9XK4S'
    },
    {
      status: 210,
      sku: 'PM0079081',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314272',
      rtl_batch_array: '6314272',
      name_search: 'PIJDEBUT',
      name: "Pieris japonica 'Debutante'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3668,
      chnn_stock_retail: 3668,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '28C4G9Z7;TJBDT4GA;E736XHBT;1SD1LN4B;GYBD11SY;T6AKPDLP;938DW86L;Z6ALPBB5;ET5TKR37;HCJY8V13;N52Z4R56;83YVXS5Z',
      statusContent: 'dot',
      hash: 'T4K9YSBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4K9YSBY'
    },
    {
      status: 210,
      sku: 'PM0066916',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329972',
      rtl_batch_array: '6037905, 6329972',
      name_search: 'IRICHIEF',
      name: "Iris (G) 'Indian Chief'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 272,
      chnn_stock_retail: 327,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZK34ARA',
      statusContent: 'dot',
      hash: 'N8Z7H8L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8Z7H8L5'
    },
    {
      status: 210,
      sku: 'PM0085504',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383819',
      rtl_batch_array: '6383819',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_prcp: 28.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'EWDHP4AG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWDHP4AG'
    },
    {
      status: 910,
      sku: 'PM0071258',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGSUNFIE',
      name: "Agapanthus 'Sunfield'",
      rng_range_identifier: 'H040050C7.5',
      statusContent: 'dot',
      hash: 'GD8DVPRA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GD8DVPRA'
    },
    {
      status: 210,
      sku: 'PM0066919',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350566',
      rtl_batch_array: '6350566',
      name_search: 'IRPROVEN',
      name: "Iris (G) 'Provencal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EBH1DHT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBH1DHT5'
    },
    {
      status: 210,
      sku: 'PM0085505',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383822',
      rtl_batch_array: '6383822',
      name_search: 'EXBRIDE',
      name: "Exochorda 'The Bride'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1740,
      chnn_stock_retail: 1740,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V8PN9B5S;TCEC1LGX;H5DCBSA8;NJB8R34P;45T8J833;S9K5D3BJ;JD4LAR76',
      statusContent: 'dot',
      hash: '9DZ1D7J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DZ1D7J1'
    },
    {
      status: 810,
      sku: 'PM0085506',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383824',
      rtl_batch_array: '6383824',
      name_search: 'HEHGOLDH',
      name: "Hedera helix 'Goldheart'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 2.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TA51AG38;PL6RE31W;7ZEJB17L;XJVVT79W;ENA9B2ER;VCPL4Z4C;H53YSL6L;4G6SHS57;RWZ69TED;NKZLA5LX;JDK9SZA8',
      statusContent: 'dot',
      hash: 'DEDZNTVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEDZNTVV'
    },
    {
      status: 210,
      sku: 'PM0085507',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383825',
      rtl_batch_array: '6383825',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 1102,
      chnn_stock_retail: 1102,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: '9E74L1YD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E74L1YD'
    },
    {
      status: 210,
      sku: 'PM0085508',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383826',
      rtl_batch_array: '6383826',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 364,
      chnn_stock_retail: 364,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'ETJLEACT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETJLEACT'
    },
    {
      status: 210,
      sku: 'PM0085509',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383827',
      rtl_batch_array: '6383827',
      name_search: 'JANUDIFL',
      name: 'Jasminum nudiflorum',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 307,
      chnn_stock_retail: 307,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TKK7Y96C;EC55166Y;EHN4G591;R2N5X45G;NKRW2BK7;SAZ3BWN1;L125CLNR;TE197YB3',
      statusContent: 'dot',
      hash: 'JT283XTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT283XTB'
    },
    {
      status: 810,
      sku: 'PM0085510',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383838',
      rtl_batch_array: '6383838',
      name_search: 'SYVBNANC',
      name: "Syringa vulgaris 'Belle de Nancy'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1L1W6HP2;VRE929A1;K163A6N1;J9SA7TVY',
      statusContent: 'dot',
      hash: 'S8DKR99G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8DKR99G'
    },
    {
      status: 910,
      sku: 'PM0085511',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383849',
      rtl_batch_array: '6383849',
      name_search: 'WISALBA',
      name: "Wisteria sinensis 'Alba'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5C98R9CR;1XASNEWH;AZ21HDHY;TCX6T96W;TG5VBKPH;J6KXJSER;AJ6JGEJE;A6J9ETBZ;PLC4N6SS;GRLXX496',
      statusContent: 'dot',
      hash: 'EK64WSSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EK64WSSN'
    },
    {
      status: 210,
      sku: 'PM0085512',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383856',
      rtl_batch_array: '6383856',
      name_search: 'RUBBOYSE',
      name: 'Rubus Boysenberry',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYLD579C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYLD579C'
    },
    {
      status: 210,
      sku: 'PM0071264',
      core_name: 'Plant',
      sppl_ean: '8720664855700',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5860589',
      rtl_batch_array: '5860589',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      imageBatch: 'WBNAL96A',
      statusContent: 'dot',
      hash: 'TZ6H4G1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZ6H4G1K'
    },
    {
      status: 210,
      sku: 'PM0059604',
      core_name: 'Plant',
      sppl_ean: '8720349425136',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354823',
      rtl_batch_array: '5354823',
      name_search: 'PHREMBRA',
      name: "Phlox (P) 'Rembrandt'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'WC253Z4S',
      statusContent: 'dot',
      hash: 'X1AG64K3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1AG64K3'
    },
    {
      status: 210,
      sku: 'PM0071265',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221898',
      rtl_batch_array: '6221898',
      name_search: 'PAOEYECA',
      name: "Papaver orientale 'Eyecatcher'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SV4Z819',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SV4Z819'
    },
    {
      status: 910,
      sku: 'PM0059517',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AUJROZAN',
      name: "Aucuba japonica 'Rozannie'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      statusContent: 'dot',
      hash: 'HBCWLGX4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HBCWLGX4'
    },
    {
      status: 210,
      sku: 'PM0071263',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221897',
      rtl_batch_array: '6221897',
      name_search: 'PAOPICOT',
      name: "Papaver orientale 'Picotee'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KNL4CYX9',
      statusContent: 'dot',
      hash: 'WR6GB21S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WR6GB21S'
    },
    {
      status: 210,
      sku: 'PM0069640',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370381',
      rtl_batch_array: '6370381, 6353083',
      name_search: 'PEVILLOS',
      name: 'Pennisetum villosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 502,
      chnn_stock_retail: 2046,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKGVTLJC;X4HLJ75H;ESP64PH2;EE3RV5CH;H78GL358;1LJELSL9;D4AJ6AXD',
      statusContent: 'dot',
      hash: 'G4EPAXLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4EPAXLE'
    },
    {
      status: 210,
      sku: 'PM0047089',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 5,
      btch_active_retail: '6356250',
      rtl_batch_array: '5972559, 6192818, 6037907, 6306087, 6356250',
      name_search: 'LIMMONEY',
      name: "Liriope muscari 'Moneymaker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 16697,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '81XERJJ2;HAX44LHN;J253LY3D;9CTAT8KG;NKPP8YB9;2RYJ5AAB;PZE397WP;YR2HZA2B;B5YXPS9J',
      statusContent: 'dot',
      hash: '8CEKSBD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CEKSBD6'
    },
    {
      status: 210,
      sku: 'PM0066203',
      core_name: 'Plant',
      sppl_ean: '8720664876897',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6173692',
      rtl_batch_array: '6173692',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 660,
      chnn_stock_retail: 660,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: '26JTYZV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26JTYZV2'
    },
    {
      status: 210,
      sku: 'PM0059744',
      core_name: 'Plant',
      sppl_ean: '8720664860315',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125560',
      rtl_batch_array: '6125560',
      name_search: 'DRERYTHR',
      name: 'Dryopteris erythrosora',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.509,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W23J67HA',
      statusContent: 'dot',
      hash: '918XGX2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '918XGX2C'
    },
    {
      status: 210,
      sku: 'PM0059728',
      core_name: 'Plant',
      sppl_ean: '8720664866072',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125542',
      rtl_batch_array: '6125542',
      name_search: 'HEEDORAD',
      name: "Helenium 'El Dorado'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C22C5VEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C22C5VEY'
    },
    {
      status: 210,
      sku: 'PM0066875',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350131',
      rtl_batch_array: '6350131, 6301326',
      name_search: 'ERDALLER',
      name: "Erigeron 'Dunkelste Aller'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 130,
      chnn_stock_retail: 367,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP9ARHBY;8ZYLLRG5',
      statusContent: 'dot',
      hash: 'R1DR9ESR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1DR9ESR'
    },
    {
      status: 910,
      sku: 'PM0069644',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEABJBAB',
      name: "Perovskia atriplicifolia 'Blue Jean Baby'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'X173Z29Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X173Z29Y'
    },
    {
      status: 210,
      sku: 'PM0078698',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310452',
      rtl_batch_array: '6310452',
      name_search: 'WEERATHK',
      name: "Weigela 'Eva Rathke'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 5.113,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R9847CB1;RRA9W7GB',
      statusContent: 'dot',
      hash: 'JP8Y2GE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP8Y2GE6'
    },
    {
      status: 810,
      sku: 'PM0066938',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176522',
      rtl_batch_array: '6176522',
      name_search: 'LURRUM',
      name: "Lupinus 'Red Rum'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BPCKGN3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPCKGN3E'
    },
    {
      status: 210,
      sku: 'PM0078699',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310456',
      rtl_batch_array: '6310456',
      name_search: 'LADECIDU',
      name: 'Larix decidua',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1225,
      chnn_stock_retail: 1225,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZC1LAAX',
      statusContent: 'dot',
      hash: 'BEXHYEGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEXHYEGN'
    },
    {
      status: 210,
      sku: 'PM0078700',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310457',
      rtl_batch_array: '6310457',
      name_search: 'LAKAEMPF',
      name: 'Larix kaempferi',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2GR3C55;1JE1CHC2;JJ4BXCH4;LV3GWN1X;NPXHR7WP;GWAB5187;ZSPXSKNT;DC1251CR;DYK1AA8P;VB1ST6V9;PPDS8DWY;KK1DPR3D',
      statusContent: 'dot',
      hash: 'J1HK25ES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1HK25ES'
    },
    {
      status: 210,
      sku: 'PM0078701',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310458',
      rtl_batch_array: '6310458',
      name_search: 'PIABIES',
      name: 'Picea abies',
      sppl_size_code: '060100C2',
      rng_range_identifier: 'H060100C2',
      rng_range_description: 'H60 cm 100 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NTXNGRDX;DEWCGB55;L6SDSA66;C1HPN7SR;LA9HV1YB;AA3SLYTB;S1W3HLWS;48KDN6LK;W137RP29;1EHWASHV;5V66ST2C;WL2B2XX4;ZB67W5NH;AYPG4KBE;T7HHJNLL;PSXD9YLV;YNWX2KYV',
      statusContent: 'dot',
      hash: 'RYZCY3H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYZCY3H9'
    },
    {
      status: 810,
      sku: 'PM0081941',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349123',
      rtl_batch_array: '6349123',
      name_search: 'IRBSWAN',
      name: "Iris (G) 'Black Swan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_order_minimum: 3,
      sppl_prcp: 1.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DDCDB8Z2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDCDB8Z2'
    },
    {
      status: 210,
      sku: 'PM0078702',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310459',
      rtl_batch_array: '6310459',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 848,
      chnn_stock_retail: 848,
      sppl_prcp: 1.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: '19YC2JVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19YC2JVR'
    },
    {
      status: 210,
      sku: 'PM0078703',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310460',
      rtl_batch_array: '6310460',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_prcp: 1.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: '96TSZKHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96TSZKHC'
    },
    {
      status: 210,
      sku: 'PM0078704',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310462',
      rtl_batch_array: '6310462',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 3350,
      chnn_stock_retail: 3350,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: 'Y6T31W33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6T31W33'
    },
    {
      status: 210,
      sku: 'PM0059501',
      core_name: 'Plant',
      sppl_ean: '8720664868755',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6119825',
      rtl_batch_array: '6119825',
      name_search: 'HYMBLAUM',
      name: "Hydrangea macrophylla 'Blaumeise'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 881,
      chnn_stock_retail: 881,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GZZX7N8;ESE927KP;KYN2CXA5;AT3DLTTN;B7H86E48;4SSHW2B7;8JBJAJDX;NLW6SNC5;YWT46E3S;HK21GA6R;CNK7H6AX;GGNAZBCJ;HDNLVN2C',
      statusContent: 'dot',
      hash: 'LT7C265L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT7C265L'
    },
    {
      status: 210,
      sku: 'PM0069669',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220238',
      rtl_batch_array: '6220238',
      name_search: 'PHSAMSON',
      name: "Phlox (S) 'Samson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2100,
      chnn_stock_retail: 2100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '678NA9BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '678NA9BD'
    },
    {
      status: 210,
      sku: 'PM0066937',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176520',
      rtl_batch_array: '6176520',
      name_search: 'LUMLIGHT',
      name: "Lupinus 'Manhattan Lights'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 527,
      chnn_stock_retail: 527,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4ZANBWGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZANBWGP'
    },
    {
      status: 210,
      sku: 'PM0078706',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310464',
      rtl_batch_array: '6310464',
      name_search: 'PISYLVES',
      name: 'Pinus sylvestris',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 17735,
      chnn_stock_retail: 17735,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7L3J9VNA;KX78HJLD;N732DC1A;GGJG12CS;HKS1N5EE;C1SG5VDN;2BV6TH7P',
      statusContent: 'dot',
      hash: '1C7D88YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1C7D88YT'
    },
    {
      status: 910,
      sku: 'PM0078788',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'W3ZC68CN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W3ZC68CN'
    },
    {
      status: 210,
      sku: 'PM0058281',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350422',
      rtl_batch_array: '6350422',
      name_search: 'HEMILAN',
      name: "Heuchera 'Milan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PH2Y7DK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH2Y7DK2'
    },
    {
      status: 210,
      sku: 'PM0081942',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349128',
      rtl_batch_array: '6349128',
      name_search: 'KNRCASTL',
      name: "Kniphofia 'Royal Castle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5586,
      chnn_stock_retail: 5586,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AX9P97JJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX9P97JJ'
    },
    {
      status: 910,
      sku: 'PM0078789',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'R679W3ZY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R679W3ZY'
    },
    {
      status: 210,
      sku: 'PM0066978',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302743',
      rtl_batch_array: '6302743',
      name_search: 'PRPCPARK',
      name: "Pratia pedunculata 'County Park'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VAKH7XRB;8TJVX2VS;931A68V3;A8464RWW;GRHVVXCR;HV99TG76;1SZHP4KN;51P1NLGT;CDSWP5HW;LR7YXWEE;LN6V5D53;1J66XRTL;B7N28VZ4;W62T4WDW;B8GH9NZX;S6GNCVRT;6DLHZLVR',
      statusContent: 'dot',
      hash: '942VLCWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '942VLCWB'
    },
    {
      status: 810,
      sku: 'PM0078790',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311880',
      rtl_batch_array: '6311880',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 58,
      chnn_stock_retail: 58,
      sppl_prcp: 5.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: '4KRCLG9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KRCLG9D'
    },
    {
      status: 910,
      sku: 'PM0078791',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'YJZVLENZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YJZVLENZ'
    },
    {
      status: 210,
      sku: 'PM0078792',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311882',
      rtl_batch_array: '6311882',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 7.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'ETZ3GCCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETZ3GCCL'
    },
    {
      status: 910,
      sku: 'PM0078793',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BETHUNBE',
      name: 'Berberis thunbergii',
      rng_range_identifier: 'H040060C7.5',
      imageCore:
        'VR4D883C;JYT62D7E;YXAHYVN7;J5B3L6ZR;STRL4G62;8NNCVVSS;Z5J8YP2K;WZ3RLL1A;9A3RJR1S;CWCB45VD',
      statusContent: 'dot',
      hash: 'ARRBKJXT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ARRBKJXT'
    },
    {
      status: 210,
      sku: 'PM0066971',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353090',
      rtl_batch_array: '6353090',
      name_search: 'PHPMIRAC',
      name: "Phlox (P) 'Pixie Miracle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P4Y8AAV2;H7VTAPGE;TYDTV331;AC5PYPE7',
      statusContent: 'dot',
      hash: 'LJRDJLLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJRDJLLK'
    },
    {
      status: 210,
      sku: 'PM0069650',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339319',
      rtl_batch_array: '6353152, 6339319',
      name_search: 'PEHYBRID',
      name: 'Petasites hybridus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1702,
      chnn_stock_retail: 3454,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18EACDJH;BAHGLEPE',
      statusContent: 'dot',
      hash: 'P399EDRB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P399EDRB'
    },
    {
      status: 810,
      sku: 'PM0066932',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6202314',
      rtl_batch_array: '6217203, 6202314, 6176511',
      name_search: 'LEWSUPRE',
      name: "Leucanthemum (S) 'Wirral Supreme'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 1542,
      sppl_order_minimum: 3,
      sppl_prcp: 1.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T8E5GCP1',
      statusContent: 'dot',
      hash: 'AEJP82CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEJP82CW'
    },
    {
      status: 210,
      sku: 'PM0078806',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311899',
      rtl_batch_array: '6311899',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '060100C24',
      rng_range_identifier: 'H060100C2.5',
      rng_range_description: 'H60 cm 100 cm C2.5',
      sppl_stock_available: 2245,
      chnn_stock_retail: 2245,
      sppl_prcp: 1.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'LWG2B5ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWG2B5ZY'
    },
    {
      status: 210,
      sku: 'PM0066923',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350569',
      rtl_batch_array: '6350569',
      name_search: 'IRLSHADO',
      name: "Iris (P) 'Little Shadow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZEWBTCT7',
      statusContent: 'dot',
      hash: '47KGTZ6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47KGTZ6L'
    },
    {
      status: 210,
      sku: 'PM0078795',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311886',
      rtl_batch_array: '6311886',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 2460,
      chnn_stock_retail: 2460,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'PWBX8RE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWBX8RE8'
    },
    {
      status: 910,
      sku: 'PM0078807',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      rng_range_identifier: 'H080100C7.5',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'P1LJ32K5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P1LJ32K5'
    },
    {
      status: 210,
      sku: 'PM0068218',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193788',
      rtl_batch_array: '6193788',
      name_search: 'IRBGAMEC',
      name: "Iris (L) 'Black Gamecock'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DX3W661V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX3W661V'
    },
    {
      status: 210,
      sku: 'PM0081943',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349135',
      rtl_batch_array: '6349135',
      name_search: 'LYCCALBA',
      name: "Lychnis coronaria 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9YTTVTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9YTTVTG'
    },
    {
      status: 210,
      sku: 'PM0081944',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349136',
      rtl_batch_array: '6349136',
      name_search: 'LYCATROS',
      name: "Lychnis coronaria 'Atrosanguinea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2179,
      chnn_stock_retail: 2179,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78T6CV7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78T6CV7J'
    },
    {
      status: 210,
      sku: 'PM0071267',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221901',
      rtl_batch_array: '6221901',
      name_search: 'PAOFSUMM',
      name: "Papaver orientale 'Forncett Summer'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1C1BD85T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1C1BD85T'
    },
    {
      status: 210,
      sku: 'PM0078797',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311889',
      rtl_batch_array: '6311889',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 6.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'T53JDTV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T53JDTV7'
    },
    {
      status: 210,
      sku: 'PM0078798',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311890',
      rtl_batch_array: '6311890',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '494CT2SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '494CT2SL'
    },
    {
      status: 210,
      sku: 'PM0078799',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311892',
      rtl_batch_array: '6311892',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 748,
      chnn_stock_retail: 748,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'NCR8PB2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCR8PB2C'
    },
    {
      status: 210,
      sku: 'PM0078800',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311893',
      rtl_batch_array: '6311893',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 2.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'DZEWYZNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZEWYZNE'
    },
    {
      status: 210,
      sku: 'PM0078801',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311894',
      rtl_batch_array: '6311894',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 7.647,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'CAZ6PN38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAZ6PN38'
    },
    {
      status: 210,
      sku: 'PM0085473',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383381',
      rtl_batch_array: '6383381, 6386171',
      name_search: 'PRJALBA',
      name: "Primula japonica 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 1536,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRVA5KYJ;8K9GX7Z1',
      statusContent: 'dot',
      hash: 'LZBJJYRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZBJJYRN'
    },
    {
      status: 210,
      sku: 'PM0071270',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221905',
      rtl_batch_array: '6221905',
      name_search: 'HOHALCYO',
      name: "Hosta 'Halcyon'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XVLHLHDA;SXSLC6RG;RV1ADEB8;1LTDRAW2;1ZR7Z8T2;XBCNDDBG;A41WTTYV;GA5DASGA;E71S77ZN;SYP571EK;99ZGX7YP;V3LH4VED;BVELEDH5;2WWRK844;HCDT5288;A9Y6ASJ4',
      statusContent: 'dot',
      hash: 'E5JT9BJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5JT9BJT'
    },
    {
      status: 810,
      sku: 'PM0066939',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176523',
      rtl_batch_array: '6176523',
      name_search: 'LUSSTAR',
      name: "Lupinus 'Salmon Star'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5PGSR2CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PGSR2CD'
    },
    {
      status: 910,
      sku: 'PM0078804',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSANGUI',
      name: 'Cornus sanguinea',
      rng_range_identifier: 'H060080C7.5',
      imageCore:
        'X4V1AR2G;JATJ5LR2;PEWSNGWB;78CDZ1AA;JVEEB7VA;PGNCTZX2;RWP4CHAX',
      statusContent: 'dot',
      hash: 'A5PBG825',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A5PBG825'
    },
    {
      status: 210,
      sku: 'PM0078805',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311898',
      rtl_batch_array: '6311898',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.162,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '55E5HGAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55E5HGAR'
    },
    {
      status: 210,
      sku: 'PM0059697',
      core_name: 'Plant',
      sppl_ean: '8720664878761',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117711',
      rtl_batch_array: '6117711',
      name_search: 'PHRECTUS',
      name: 'Phygelius rectus',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C3ZE3GXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3ZE3GXL'
    },
    {
      status: 210,
      sku: 'PM0071268',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221902',
      rtl_batch_array: '6221902',
      name_search: 'PAHARLEM',
      name: "Papaver 'Harlem'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y85ENLB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y85ENLB6'
    },
    {
      status: 910,
      sku: 'PM0078809',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      rng_range_identifier: 'H080100C7.5',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'JDZLH6C9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JDZLH6C9'
    },
    {
      status: 910,
      sku: 'PM0078810',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'YSPBPL62',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YSPBPL62'
    },
    {
      status: 210,
      sku: 'PM0078811',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311904',
      rtl_batch_array: '6311904',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 1661,
      chnn_stock_retail: 1661,
      sppl_prcp: 7.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'CB24E374',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CB24E374'
    },
    {
      status: 210,
      sku: 'PM0078813',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6354237',
      rtl_batch_array: '6311906, 6354237',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C24',
      rng_range_identifier: 'H100125C2.5',
      rng_range_description: 'H100 cm 125 cm C2.5',
      sppl_stock_available: 5420,
      chnn_stock_retail: 5682,
      sppl_prcp: 1.777,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '2J7LJD3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2J7LJD3N'
    },
    {
      status: 210,
      sku: 'PM0078814',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311907',
      rtl_batch_array: '6311907',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '125150C34',
      rng_range_identifier: 'H125150C3.5',
      rng_range_description: 'H125 cm 150 cm C3.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 4.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'NNLZ2ANR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NNLZ2ANR'
    },
    {
      status: 210,
      sku: 'PM0068237',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193811',
      rtl_batch_array: '6193811',
      name_search: 'PHAUSTRA',
      name: 'Phragmites australis',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9TB4A4Z;LXBT97CP;ZS4P7HSG;4291A3CA;PX94H3ND;X96L4HRN;2R39RHKW',
      statusContent: 'dot',
      hash: '5W79GHYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W79GHYY'
    },
    {
      status: 210,
      sku: 'PM0078815',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311908',
      rtl_batch_array: '6311908',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 3.522,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'YH6SJDW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YH6SJDW9'
    },
    {
      status: 210,
      sku: 'PM0078816',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317489',
      rtl_batch_array: '6311911, 6317489',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 179,
      chnn_stock_retail: 2679,
      sppl_prcp: 7.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '8TG5HP1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TG5HP1L'
    },
    {
      status: 210,
      sku: 'PM0081945',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349138',
      rtl_batch_array: '6349138',
      name_search: 'LYVSPLEN',
      name: "Lychnis viscaria 'Splendens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4230,
      chnn_stock_retail: 4230,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZE8WV1T2',
      statusContent: 'dot',
      hash: 'SNYZ7ADC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNYZ7ADC'
    },
    {
      status: 210,
      sku: 'PM0071275',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5878632',
      rtl_batch_array: '5878632',
      name_search: 'AGTWISTE',
      name: 'Agapanthus Twister',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '75J2DR6N;G4CB7SRV;9P1W2T9B;67LN29CJ',
      statusContent: 'dot',
      hash: 'YCC1EPLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCC1EPLN'
    },
    {
      status: 210,
      sku: 'PM0059698',
      core_name: 'Plant',
      sppl_ean: '8720664878792',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117712',
      rtl_batch_array: '6117712',
      name_search: 'PHRCDRED',
      name: 'Phygelius rectus Candy Drops Red',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NV1EBYZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV1EBYZJ'
    },
    {
      status: 210,
      sku: 'PM0078817',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317490',
      rtl_batch_array: '6311912, 6317490',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 463,
      chnn_stock_retail: 2963,
      sppl_prcp: 8.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'SK4SY7DV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK4SY7DV'
    },
    {
      status: 210,
      sku: 'PM0071269',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221904',
      rtl_batch_array: '6221904',
      name_search: 'PAOTUERK',
      name: "Papaver orientale 'T\u00fcrkenlouis'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K7WVLG6T;E2PA72B4;AEN4JJ5Y;9KKT2J4L;479T6GPV;PABL42C4',
      statusContent: 'dot',
      hash: 'Z5DVCT4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5DVCT4N'
    },
    {
      status: 210,
      sku: 'PM0059507',
      core_name: 'Plant',
      sppl_ean: '8720664869134',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6119831',
      rtl_batch_array: '6119831',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1621,
      chnn_stock_retail: 1621,
      sppl_prcp: 3.122,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: '9D6Y4XJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D6Y4XJ3'
    },
    {
      status: 210,
      sku: 'PM0047088',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6192817',
      rtl_batch_array: '6192817, 6301697',
      name_search: 'LIMMAJES',
      name: "Liriope muscari 'Majestic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1036,
      chnn_stock_retail: 1666,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YYRE8RWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYRE8RWZ'
    },
    {
      status: 210,
      sku: 'PM0085474',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383384',
      rtl_batch_array: '6383384',
      name_search: 'RUSUBTOM',
      name: 'Rudbeckia subtomentosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X55ACY9;ESLC5Y59',
      statusContent: 'dot',
      hash: 'K7TAXPZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7TAXPZB'
    },
    {
      status: 210,
      sku: 'PM0078794',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311885',
      rtl_batch_array: '6311885',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'YXGLW3BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXGLW3BN'
    },
    {
      status: 210,
      sku: 'PM0078796',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311887',
      rtl_batch_array: '6311887',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '080100C24',
      rng_range_identifier: 'H080100C2.5',
      rng_range_description: 'H80 cm 100 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.809,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'A91X4C6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A91X4C6G'
    },
    {
      status: 210,
      sku: 'PM0079090',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314287',
      rtl_batch_array: '6314287',
      name_search: 'VEVLAVEN',
      name: "Veronicastrum virginicum 'Lavendelturm'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WJDLPZ4S;G94VZG15;ERLR1KSP;VRKCKJ94;L9PSVE8W',
      statusContent: 'dot',
      hash: '9AH2Y589',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AH2Y589'
    },
    {
      status: 910,
      sku: 'PM0085513',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383858',
      rtl_batch_array: '6383858',
      name_search: 'MAHSCENT',
      name: "Magnolia 'Heaven Scent'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7J17ZK1',
      statusContent: 'dot',
      hash: 'Z1XHXVNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1XHXVNZ'
    },
    {
      status: 910,
      sku: 'PM0078818',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '8ZVWRS4L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8ZVWRS4L'
    },
    {
      status: 210,
      sku: 'PM0078808',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311901',
      rtl_batch_array: '6311901',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_prcp: 6.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'THKKH8P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THKKH8P6'
    },
    {
      status: 210,
      sku: 'PM0078705',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310463',
      rtl_batch_array: '6310463',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: '741Z1K4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '741Z1K4V'
    },
    {
      status: 210,
      sku: 'PM0069679',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268812',
      rtl_batch_array: '6268812',
      name_search: 'PLPYGMAE',
      name: 'Pleioblastus pygmaeus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V7E3YDKZ;GJD889KS;J6D7YEY5;JJ1HYDY9;YTHBKKKK;4T7J9TJ8',
      statusContent: 'dot',
      hash: 'NN3L9ZW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN3L9ZW7'
    },
    {
      status: 210,
      sku: 'PM0078803',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311896',
      rtl_batch_array: '6311896',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 8.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'P41WYATV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P41WYATV'
    },
    {
      status: 210,
      sku: 'PM0078802',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311895',
      rtl_batch_array: '6311895',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '030050C24',
      rng_range_identifier: 'H030050C2.5',
      rng_range_description: 'H30 cm 50 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'TNG1KBB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNG1KBB6'
    },
    {
      status: 210,
      sku: 'PM0059664',
      core_name: 'Plant',
      sppl_ean: '8720664850514',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112205',
      rtl_batch_array: '6112205',
      name_search: 'ACCCLOUD',
      name: "Aconitum carmichaelii 'Cloudy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2462,
      chnn_stock_retail: 2462,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J2STP19J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2STP19J'
    },
    {
      status: 210,
      sku: 'PM0070165',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220655',
      rtl_batch_array: '6220655',
      name_search: 'AGPHEIDY',
      name: "Agapanthus 'Pretty Heidy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 146,
      chnn_stock_retail: 146,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4TCDY2TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TCDY2TW'
    },
    {
      status: 210,
      sku: 'PM0085514',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383859',
      rtl_batch_array: '6383859',
      name_search: 'MAMWSUN',
      name: "Mahonia media 'Winter Sun'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 614,
      chnn_stock_retail: 614,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2BBW8GXV;RPZR1885;B2S9GWN2;ZPCDY8JT;ZSSYKGDS;GLBBNLPJ;LZXLZRXN',
      statusContent: 'dot',
      hash: '9GN83JHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GN83JHW'
    },
    {
      status: 210,
      sku: 'PM0085515',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383860',
      rtl_batch_array: '6383860',
      name_search: 'NADFIREP',
      name: "Nandina domestica 'Firepower'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVNP7198',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVNP7198'
    },
    {
      status: 210,
      sku: 'PM0085516',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383863',
      rtl_batch_array: '6383863',
      name_search: 'PHTENAX',
      name: 'Phormium tenax',
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNNDY31D;9566B5PV;E5A2X5CK;1E1ABT8Z;RJRA1YLN;1VJ2W6DK;NNSBVZSG;7TE51JHR;GLKXZ78H;11XZE734;7Z1NRJ9V;GHTYP9H8;HT3EBZCP',
      statusContent: 'dot',
      hash: 'RA6YZ1Y2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA6YZ1Y2'
    },
    {
      status: 210,
      sku: 'PM0085517',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383864',
      rtl_batch_array: '6383864',
      name_search: 'PHTPURPU',
      name: "Phormium tenax 'Purpureum'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44WG5RVA;KLPRST38;4S29VPA6;L1KJB7DV;47V9E8TL;AR6EVEP1;4S9RJKT4;4T16C452;V81K4D6T;8JVLCYPP;WZ7NKC2S',
      statusContent: 'dot',
      hash: 'HK6C5RS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HK6C5RS4'
    },
    {
      status: 210,
      sku: 'PM0085518',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383865',
      rtl_batch_array: '6383865',
      name_search: 'PHWGOLD',
      name: "Phormium 'Wings of Gold'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '64S6ALGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64S6ALGD'
    },
    {
      status: 210,
      sku: 'PM0085519',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383866',
      rtl_batch_array: '6383866',
      name_search: 'PHFLOUIS',
      name: 'Photinia fraseri Louise',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_prcp: 9.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SEJXXH3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEJXXH3J'
    },
    {
      status: 210,
      sku: 'PM0065574',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317471',
      rtl_batch_array: '6317471, 6254690',
      name_search: 'COSWFEAT',
      name: "Cortaderia selloana 'White Feather'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1896,
      chnn_stock_retail: 2859,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C8K21LJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8K21LJV'
    },
    {
      status: 810,
      sku: 'PM0069670',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6215551',
      rtl_batch_array: '6215551',
      name_search: 'PHZWERGE',
      name: "Phlox (S) 'Zwergenteppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1JZYJA2J;TC9KLR1S;7JA4KZ7Z;PBPGYVEX;7YDJZ3Y5;CPLDPAT1;V318G1SB;X4SRNJ5T;1B5V21EX',
      statusContent: 'dot',
      hash: '8GWJW1W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GWJW1W5'
    },
    {
      status: 210,
      sku: 'PM0058232',
      core_name: 'Plant',
      sppl_ean: '8720664868205',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112449',
      rtl_batch_array: '6112449',
      name_search: 'HOGCOLE',
      name: "Hosta 'Gray Cole'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VR2VDD6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VR2VDD6C'
    },
    {
      status: 810,
      sku: 'PM0085520',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383871',
      rtl_batch_array: '6383871',
      name_search: 'COMROSEA',
      name: "Convallaria majalis 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9ZLJXXD2',
      statusContent: 'dot',
      hash: 'V6957VWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6957VWD'
    },
    {
      status: 210,
      sku: 'PM0085521',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384811',
      rtl_batch_array: '6384811',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'VLY2DR5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLY2DR5E'
    },
    {
      status: 210,
      sku: 'PM0069677',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353116',
      rtl_batch_array: '6353116',
      name_search: 'PLGFWHIT',
      name: "Platycodon grandiflorus 'Fuji White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ET67GTEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET67GTEK'
    },
    {
      status: 210,
      sku: 'PM0085522',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384812',
      rtl_batch_array: '6384812',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 4.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'GZ5ZXL9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZ5ZXL9B'
    },
    {
      status: 210,
      sku: 'PM0069659',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252882',
      rtl_batch_array: '6252882, 6345202',
      name_search: 'PHREMBRA',
      name: "Phlox (P) 'Rembrandt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 719,
      chnn_stock_retail: 919,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DGKYH3HS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGKYH3HS'
    },
    {
      status: 910,
      sku: 'PM0078812',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      rng_range_identifier: 'H080100C7.5',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'HLC6JCWW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HLC6JCWW'
    },
    {
      status: 210,
      sku: 'PM0085523',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384814',
      rtl_batch_array: '6384814',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.934,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'Z8K131YS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8K131YS'
    },
    {
      status: 910,
      sku: 'PM0085524',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384816',
      rtl_batch_array: '6384816',
      name_search: 'VIBURHYT',
      name: 'Viburnum rhytidophyllum',
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 15.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '48SGK5WV;L8E27T5T',
      statusContent: 'dot',
      hash: 'Y236HZ7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y236HZ7L'
    },
    {
      status: 810,
      sku: 'PM0085525',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384817',
      rtl_batch_array: '6384817',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 24.177,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '95D1L7K9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95D1L7K9'
    },
    {
      status: 210,
      sku: 'PM0059505',
      core_name: 'Plant',
      sppl_ean: '8720664868793',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6119829',
      rtl_batch_array: '6119829',
      name_search: 'HYMLEUCH',
      name: "Hydrangea macrophylla 'Leuchtfeuer'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1438,
      chnn_stock_retail: 1438,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5N5ES2HA',
      statusContent: 'dot',
      hash: 'GC6Z9JVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GC6Z9JVP'
    },
    {
      status: 210,
      sku: 'PM0085526',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384818',
      rtl_batch_array: '6384818',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.934,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: '1JZLXTS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JZLXTS3'
    },
    {
      status: 210,
      sku: 'PM0063567',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284517',
      rtl_batch_array: '6284517',
      name_search: 'ASPAKIPP',
      name: "Aster (D) 'Prof. Anton Kippenberg'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8R4P2H6;YCRCK5ZY;2RYWKLH8;KR6LX94K;WKDHV3J6',
      statusContent: 'dot',
      hash: 'CYJ369ZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYJ369ZN'
    },
    {
      status: 210,
      sku: 'PM0059502',
      core_name: 'Plant',
      sppl_ean: '8720664868762',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6119826',
      rtl_batch_array: '6119826',
      name_search: 'HYMBODEN',
      name: "Hydrangea macrophylla 'Bodensee'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1067,
      chnn_stock_retail: 1067,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB81K7SZ;ZRDAT7B4;9TL5SAS2;CAKYVNEH;T2EHAA8J;6RT98EYD;KHD4XTSL;G454XKTX',
      statusContent: 'dot',
      hash: '9LXP8BNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LXP8BNH'
    },
    {
      status: 210,
      sku: 'PM0066926',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383357',
      rtl_batch_array: '6176497, 6383357',
      name_search: 'JALBLAUL',
      name: "Jasione laevis 'Blaulicht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12744,
      chnn_stock_retail: 12828,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6TE7GYTR;KPWYX8ZL;WSWLVH45;A1E2WJE1;45YSCYBY;XK811397;JA9HG953',
      statusContent: 'dot',
      hash: '7D8NBVK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D8NBVK1'
    },
    {
      status: 210,
      sku: 'PM0066849',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349062',
      rtl_batch_array: '6349062, 6349922',
      name_search: 'CEMACROC',
      name: 'Centaurea macrocephala',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1212,
      chnn_stock_retail: 1513,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J4J6JEGT;V3KDKCZY;1Y5JGGPE;EH76K9T2;WTY2NVJG;GPW7VGGS;9B1LTLX7;D8A641TX;7J22B45Y;CRKDYLL4;45H75W6Z',
      statusContent: 'dot',
      hash: 'LVN5YWLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVN5YWLN'
    },
    {
      status: 210,
      sku: 'PM0071272',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221907',
      rtl_batch_array: '6221907',
      name_search: 'KNIQUEEN',
      name: "Kniphofia 'Ice Queen'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2TSE47H;6SV3ARB5;G4J3ANWN;ZECN9S4H;ZCVJPYAV',
      statusContent: 'dot',
      hash: '53XWY5LH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53XWY5LH'
    },
    {
      status: 810,
      sku: 'PM0085527',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6384819',
      rtl_batch_array: '6384819',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 12.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '1JAAZAVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JAAZAVN'
    },
    {
      status: 210,
      sku: 'PM0069680',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220888',
      rtl_batch_array: '6220888',
      name_search: 'PLVARIEG',
      name: 'Pleioblastus variegatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BVENVG63;54H6PEPV;DYZXHJKZ;STXH7BY5;E536TAZW;42DG1SR5;BY4GC1H7',
      statusContent: 'dot',
      hash: '8X83YDLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8X83YDLS'
    },
    {
      status: 810,
      sku: 'PM0085528',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252848',
      rtl_batch_array: '6252848',
      name_search: 'CACKOBAL',
      name: "Campanula carpatica 'Kobaltglocke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBV635YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBV635YX'
    },
    {
      status: 210,
      sku: 'PM0085529',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255967',
      rtl_batch_array: '6255967',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2572,
      chnn_stock_retail: 2572,
      sppl_order_minimum: 3,
      sppl_prcp: 1.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'KNAN92TR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNAN92TR'
    },
    {
      status: 210,
      sku: 'PM0085530',
      core_name: 'Plant',
      sppl_ean: '8720664854734',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147729',
      rtl_batch_array: '6147729',
      name_search: 'CAGCBLAN',
      name: "Campanula glomerata 'Chio Blanco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 406,
      chnn_stock_retail: 406,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NLNHBSG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLNHBSG6'
    },
    {
      status: 810,
      sku: 'PM0085531',
      core_name: 'Plant',
      sppl_ean: '8720664854659',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147734',
      rtl_batch_array: '6147734',
      name_search: 'CASARAST',
      name: "Campanula 'Sarastro'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D3ADBW9L;DABGJ492;GCXVZXT7;YX5V9RNN;TNWEHZSN;8KVRTELX',
      statusContent: 'dot',
      hash: 'EB1GKH6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB1GKH6W'
    },
    {
      status: 210,
      sku: 'PM0071274',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5878631',
      rtl_batch_array: '5878631',
      name_search: 'AGBLBLUE',
      name: 'Agapanthus Brilliant Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KNAZ7LYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNAZ7LYC'
    },
    {
      status: 210,
      sku: 'PM0085532',
      core_name: 'Plant',
      sppl_ean: '8720664686670',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053822',
      rtl_batch_array: '6053822',
      name_search: 'CYCSLEAF',
      name: "Cyclamen coum 'Silver Leaf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 760,
      chnn_stock_retail: 760,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C3W36P34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3W36P34'
    },
    {
      status: 210,
      sku: 'PM0063568',
      core_name: 'Plant',
      sppl_ean: '8720664854482',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6146964',
      rtl_batch_array: '6146964, 6254675, 6195725',
      name_search: 'CALBRACH',
      name: 'Calamagrostis brachytricha',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3251,
      chnn_stock_retail: 4937,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XZB6RDV5;Z1Y6ZW7B;X5RL9DAG;RL2SKJRT;TJNDBH6K;J4D5311G;WV5XVJTY;1A4C7G2Y;6Y1WGHAD;AE1868HW;XBRY6XRV;ATNC724L',
      statusContent: 'dot',
      hash: 'ST75W512',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST75W512'
    },
    {
      status: 210,
      sku: 'PM0071271',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221906',
      rtl_batch_array: '6221906',
      name_search: 'PAOBLIVE',
      name: "Papaver orientale 'Beauty of Livermere'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XSY2WZA3;H6JCH2E7;6PTZR7HJ;GX9NE5N5;SWJ6ZGYW;P1562DC5',
      statusContent: 'dot',
      hash: 'LYYL4E4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYYL4E4E'
    },
    {
      status: 210,
      sku: 'PM0070164',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6230506',
      rtl_batch_array: '6301387, 6230506',
      name_search: 'AGEUPATO',
      name: 'Agrimonia eupatoria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1291,
      chnn_stock_retail: 1351,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2Z25NEY',
      statusContent: 'dot',
      hash: 'VW96SPAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VW96SPAR'
    },
    {
      status: 210,
      sku: 'PM0066968',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353159',
      rtl_batch_array: '6295797, 6353159',
      name_search: 'PHNATASC',
      name: "Phlox (M) 'Natascha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 936,
      chnn_stock_retail: 1093,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RV37ZVKD;PK2219CD;GXSS7ZK2;X9HVN1ZG;75JXBCW7;HEX1LL5P',
      statusContent: 'dot',
      hash: '1RVLS6GP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RVLS6GP'
    },
    {
      status: 810,
      sku: 'PM0019262',
      core_name: 'Plant',
      sppl_ean: '8720664646971',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364208',
      rtl_batch_array: '5364208, 6353528',
      name_search: 'TRCHEDDA',
      name: "Trollius 'Cheddar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 421,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TYE5863T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYE5863T'
    },
    {
      status: 810,
      sku: 'PM0071276',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254789',
      rtl_batch_array: '6254789',
      name_search: 'MOCAERUL',
      name: 'Molinia caerulea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4Y3WS2JA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y3WS2JA'
    },
    {
      status: 910,
      sku: 'PM0038335',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083860',
      rtl_batch_array: '6083860',
      name_search: 'DCMHTCON',
      name: 'DCM Meststof Hagen, Taxus en Conif.',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'HJJYNG47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'HJJYNG47'
    },
    {
      status: 910,
      sku: 'PM0038332',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083863',
      rtl_batch_array: '6083863',
      name_search: 'DCMREBLO',
      name: 'DCM Meststof Rozen en Bloemen',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'LWS8ZTN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'LWS8ZTN3'
    },
    {
      status: 810,
      sku: 'PM0032028',
      core_name: 'Plant',
      sppl_ean: '8720664679214',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5818819',
      rtl_batch_array: '5818819',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.772,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '6RTZDRH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RTZDRH1'
    },
    {
      status: 210,
      sku: 'PM0078707',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310466',
      rtl_batch_array: '6310466',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '81CL5WED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81CL5WED'
    },
    {
      status: 210,
      sku: 'PM0078708',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310468',
      rtl_batch_array: '6310468',
      name_search: 'WATERNAT',
      name: 'Waldsteinia ternata',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3035,
      chnn_stock_retail: 3035,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GGTG67JR;JH3S8P8S;WWLKCH3X;CNZ8KPNP;WK3VV735;BTYE2CC8;PKLJ2LZB;86NNZJZJ',
      statusContent: 'dot',
      hash: 'K9SEDHD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9SEDHD3'
    },
    {
      status: 810,
      sku: 'PM0033820',
      core_name: 'Plant',
      sppl_ean: '8720349495559',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849886',
      rtl_batch_array: '5849886',
      name_search: 'THAMLFAV',
      name: "Thalictrum aquilegifolium 'My Little Favourite'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NK4SG4S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NK4SG4S9'
    },
    {
      status: 210,
      sku: 'PM0081946',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349147',
      rtl_batch_array: '6349147, 6348416',
      name_search: 'SALNSWHI',
      name: 'Salvia nemorosa Sensation White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7560,
      chnn_stock_retail: 7636,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JNT1SLXV;C9VG95GX;4DY5WHDT;9K3C4B1B;KD8C4YP8',
      statusContent: 'dot',
      hash: 'NAZJRD87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAZJRD87'
    },
    {
      status: 210,
      sku: 'PM0066903',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176456',
      rtl_batch_array: '6176456',
      name_search: 'HOSSWEET',
      name: "Hosta 'So Sweet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DT2YXG3K;4YTYADN3;NA4ABWA1',
      statusContent: 'dot',
      hash: 'LAJ84L7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAJ84L7Z'
    },
    {
      status: 210,
      sku: 'PM0068246',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193820',
      rtl_batch_array: '6193820',
      name_search: 'SCCMHEGA',
      name: "Schizostylis coccinea 'Mrs Hegarty'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HDGCACP3',
      statusContent: 'dot',
      hash: 'SCX1JP4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCX1JP4X'
    },
    {
      status: 210,
      sku: 'PM0079092',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339774',
      rtl_batch_array: '6133204, 6339774',
      name_search: 'CAFBZING',
      name: "Carex flacca 'Blue Zinger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 990,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9G4P5KKX;YXKLT2AB;GH9ESHCR;SSNEWWXV;N4AAN9Y3;DCDK42V9;CR2Z5NCZ',
      statusContent: 'dot',
      hash: 'KYCAK8R6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYCAK8R6'
    },
    {
      status: 210,
      sku: 'PM0085533',
      core_name: 'Plant',
      sppl_ean: '8720664683426',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054507',
      rtl_batch_array: '6054507',
      name_search: 'CAPLBL',
      name: "Campanula pun. 'Light Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HARS7S4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HARS7S4X'
    },
    {
      status: 210,
      sku: 'PM0065395',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6176455',
      rtl_batch_array: '6176455, 6301665, 6164314',
      name_search: 'HOSIEBOL',
      name: 'Hosta sieboldiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6752,
      chnn_stock_retail: 9849,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '45Y3AT9D;PENHZXHR;RBNA23G8;A1KYYB85',
      statusContent: 'dot',
      hash: 'W3EPKBTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3EPKBTH'
    },
    {
      status: 210,
      sku: 'PM0065427',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6260874',
      rtl_batch_array: '6260874, 6254411, 6383372, 6309503',
      name_search: 'POSDAHLE',
      name: "Polystichum setiferum 'Dahlem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4418,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EGSNDVXR;WT52DPWX',
      statusContent: 'dot',
      hash: 'HHBK7R3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHBK7R3E'
    },
    {
      status: 210,
      sku: 'PM0078819',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311915',
      rtl_batch_array: '6311915',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 7.647,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: 'GLX32J8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLX32J8D'
    },
    {
      status: 210,
      sku: 'PM0081947',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349150',
      rtl_batch_array: '6349150',
      name_search: 'SCCAUCAS',
      name: 'Scabiosa caucasica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12445,
      chnn_stock_retail: 12445,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E6Z5ZZXV;K34ZDPCD;1646Z9YT;X1DRW67R;WSVWZKD3',
      statusContent: 'dot',
      hash: '7DAX8P5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DAX8P5E'
    },
    {
      status: 210,
      sku: 'PM0078821',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311920',
      rtl_batch_array: '6311920',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.755,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'G532B37D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G532B37D'
    },
    {
      status: 210,
      sku: 'PM0081948',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349157',
      rtl_batch_array: '6349157',
      name_search: 'VEPHOENI',
      name: 'Verbascum phoeniceum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3185,
      chnn_stock_retail: 3185,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BCYG2T65;5H8ANXDL;VTH28T2E;AXXWP1VJ;8WWXA5BX;KSSP7CGP;ZBBLW5R6;WJH2PP5W;T4DZ491Y;1XEC14SX',
      statusContent: 'dot',
      hash: 'X8ZV8HEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8ZV8HEE'
    },
    {
      status: 910,
      sku: 'PM0033565',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GYRDTEAC',
      name: "Gypsophila repens 'Dorothy Teacher'",
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      imageCore: 'KCNTG19K;N59D5SJZ;YTE4G61W;K7V73249',
      statusContent: 'dot',
      hash: 'S66TJ4SR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S66TJ4SR'
    },
    {
      status: 210,
      sku: 'PM0068245',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193819',
      rtl_batch_array: '6193819',
      name_search: 'SCCOCCIN',
      name: 'Schizostylis coccinea',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J2NCE5WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2NCE5WL'
    },
    {
      status: 210,
      sku: 'PM0074304',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6079265',
      rtl_batch_array: '6079265, 6336465, 6300838',
      name_search: 'CODMAJOR',
      name: "Cotoneaster dammeri 'Major'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4850,
      chnn_stock_retail: 9153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V37PA1X8',
      statusContent: 'dot',
      hash: 'ASEY9S1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASEY9S1W'
    },
    {
      status: 210,
      sku: 'PM0085534',
      core_name: 'Plant',
      sppl_ean: '8720664683440',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054509',
      rtl_batch_array: '6054509',
      name_search: 'CAPRED',
      name: "Campanula punctata 'Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V8LSLBN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8LSLBN5'
    },
    {
      status: 210,
      sku: 'PM0085535',
      core_name: 'Plant',
      sppl_ean: '8720353090344',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014557',
      rtl_batch_array: '6014557',
      name_search: 'CAPSENSA',
      name: "Campanula 'Purple Sensation'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 503,
      chnn_stock_retail: 503,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VLA1NSPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLA1NSPS'
    },
    {
      status: 810,
      sku: 'PM0085536',
      core_name: 'Plant',
      sppl_ean: '8720664683372',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014645',
      rtl_batch_array: '6014645',
      name_search: 'CAPHIGHC',
      name: "Campanula persicifolia 'Highcliffe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '66LLB6DG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66LLB6DG'
    },
    {
      status: 910,
      sku: 'PM0078822',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'XGLZGJ2L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XGLZGJ2L'
    },
    {
      status: 210,
      sku: 'PM0068277',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6340042',
      rtl_batch_array: '6196825, 6353201, 6340042',
      name_search: 'RUGRAVEO',
      name: 'Ruta graveolens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 487,
      chnn_stock_retail: 5197,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJPA4J15;E8DJ9K5R;27YNDLH9;6RNTC9DA;T3Z4LGJR',
      statusContent: 'dot',
      hash: '1ND22971',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ND22971'
    },
    {
      status: 210,
      sku: 'PM0085537',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207841',
      rtl_batch_array: '6207841',
      name_search: 'CAKBELLE',
      name: "Campanula 'Kent Belle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AEL9PE41;E5HEKH5K;RTZXKVZ2',
      statusContent: 'dot',
      hash: 'BY2YTVPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY2YTVPT'
    },
    {
      status: 210,
      sku: 'PM0029377',
      core_name: 'Plant',
      sppl_ean: '8720349413980',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3559640',
      rtl_batch_array: '3559640',
      name_search: 'COSSSTAR',
      name: "Cortaderia selloana 'Splendid Star'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 5.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GACS7DHD;EE2C6N36;84SRD6ZR;57TPG3NN;9BXGTE3H;P62HLTEW;PYNXG6AC;H2TV4CT7;123Z5ZS5;TGER4LE8;2ZZ4ZWC1;KJCT5ATP;RZ3NZ24B;L68H8XNP;4EPG6ECV;H9K59E4D',
      imageBatch: 'YC4EJ3TR',
      statusContent: 'dot',
      hash: '4KCKW859',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KCKW859'
    },
    {
      status: 210,
      sku: 'PM0078823',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356674',
      rtl_batch_array: '6311922, 6356674',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 1541,
      sppl_prcp: 3.918,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'PBJV11PZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PBJV11PZ'
    },
    {
      status: 210,
      sku: 'PM0059736',
      core_name: 'Plant',
      sppl_ean: '8720664850699',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125551',
      rtl_batch_array: '6125551',
      name_search: 'AGBMAGIC',
      name: "Agapanthus 'Black Magic'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SH7C5TCY;GVARXHS2;WDKVTAZK;EZVLSP73',
      statusContent: 'dot',
      hash: '6KBDWE5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KBDWE5D'
    },
    {
      status: 210,
      sku: 'PM0078824',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311923',
      rtl_batch_array: '6311923',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_prcp: 7.647,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'RDGDRN8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDGDRN8N'
    },
    {
      status: 210,
      sku: 'PM0065428',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260875',
      rtl_batch_array: '6260875',
      name_search: 'POSPLUMO',
      name: "Polystichum setiferum 'Plumosum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78V2DZK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78V2DZK8'
    },
    {
      status: 210,
      sku: 'PM0065401',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382303',
      rtl_batch_array: '6315060, 6350743, 6382303',
      name_search: 'PAVHMETA',
      name: "Panicum virgatum 'Heavy Metal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 4156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V5XSXSYX;C8S1WKJ1;BTG42V3L;4RPES78Z;97Z52BYT;CWPCSTAN;B27L9XH1;E71GYABT;NGTG2JP9;9YE7Z337;R1NWHNC8;K9DC6BKX;XENCB779;LT5N1A2T;H8ABNY7W;1E6ZHVGW;YAD39A58;XKLGYA4D;VCKC5T7W;XXBCD7VY;1P73SDWB;WRKX9E81;BH52Y7WX;6L6ASRXS;2D21YBST;JHS4125Z;8S2SCRWD',
      statusContent: 'dot',
      hash: '92X9P48L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92X9P48L'
    },
    {
      status: 210,
      sku: 'PM0085538',
      core_name: 'Plant',
      sppl_ean: '8720664854864',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112321',
      rtl_batch_array: '6112321',
      name_search: 'CAPBAMIE',
      name: "Campanula persicifolia 'La Bonne Amie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VXPE1NXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXPE1NXZ'
    },
    {
      status: 210,
      sku: 'PM0012760',
      core_name: 'Plant',
      sppl_ean: '8720349423224',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3273966',
      rtl_batch_array: '3273966',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: '020030C5',
      rng_range_identifier: 'H020030C5',
      rng_range_description: 'H20 cm 30 cm C5',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      imageBatch: '841CCNNR',
      statusContent: 'dot',
      hash: 'HGLDS3LX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGLDS3LX'
    },
    {
      status: 910,
      sku: 'PM0057908',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLGRACIL',
      name: 'Clematis gracilifolia',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      statusContent: 'dot',
      hash: 'L94GLHHH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L94GLHHH'
    },
    {
      status: 210,
      sku: 'PM0078825',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356619',
      rtl_batch_array: '6356619',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'SP5SPXWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP5SPXWP'
    },
    {
      status: 210,
      sku: 'PM0078826',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311925',
      rtl_batch_array: '6311925',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 2215,
      chnn_stock_retail: 2215,
      sppl_prcp: 1.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'WL884EYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL884EYK'
    },
    {
      status: 210,
      sku: 'PM0078827',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311927',
      rtl_batch_array: '6311927, 6373234',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 4411,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'ZAC1LHPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAC1LHPK'
    },
    {
      status: 210,
      sku: 'PM0078828',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311928',
      rtl_batch_array: '6311928',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 3.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '6ACX7YXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ACX7YXP'
    },
    {
      status: 210,
      sku: 'PM0003623',
      core_name: 'Plant',
      sppl_ean: '8720664667266',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5399342',
      rtl_batch_array: '5399342',
      name_search: 'POFKLOND',
      name: "Potentilla fruticosa 'Klondike'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1517,
      chnn_stock_retail: 1517,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPCDJ3XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPCDJ3XW'
    },
    {
      status: 910,
      sku: 'PM0079094',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACERGMD',
      name: 'Acer Gemengd',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      imageCore: '5VK5YW35',
      statusContent: 'dot',
      hash: 'S5KRZA98',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S5KRZA98'
    },
    {
      status: 910,
      sku: 'PM0078829',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      rng_range_identifier: 'H040060C12',
      rng_range_description: 'H40 cm 60 cm C12',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'DXZ749G2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DXZ749G2'
    },
    {
      status: 210,
      sku: 'PM0078830',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311930',
      rtl_batch_array: '6311930',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_prcp: 12.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'RWEPHPH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWEPHPH2'
    },
    {
      status: 810,
      sku: 'PM0071278',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269463',
      rtl_batch_array: '6269463',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'EBLW6NX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBLW6NX6'
    },
    {
      status: 210,
      sku: 'PM0033758',
      core_name: 'Plant',
      sppl_ean: '8720664647039',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849796',
      rtl_batch_array: '5849796',
      name_search: 'TRCGQUEE',
      name: "Trollius chinensis 'Golden Queen'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_order_minimum: 3,
      sppl_prcp: 1.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LPHREY8L;9S61WPTE;GJ34B8ZV;6GX1WTJX;GTWDWAAS;Y32CWXSW;LN55YS2T;C48HAZ4B',
      statusContent: 'dot',
      hash: '87C1YHKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87C1YHKC'
    },
    {
      status: 210,
      sku: 'PM0065390',
      core_name: 'Plant',
      sppl_ean: '8720664864559',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164287',
      rtl_batch_array: '6164287, 6302659',
      name_search: 'GECGIUSE',
      name: "Geranium cinereum 'Giuseppii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 489,
      chnn_stock_retail: 589,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z61VEN5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z61VEN5T'
    },
    {
      status: 210,
      sku: 'PM0078831',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311931',
      rtl_batch_array: '6311931',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 5.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'VLWT7G69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLWT7G69'
    },
    {
      status: 210,
      sku: 'PM0079095',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314859',
      rtl_batch_array: '6314859',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 21.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'KG6K3PWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG6K3PWD'
    },
    {
      status: 910,
      sku: 'PM0057928',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      rng_range_identifier: 'H040060C4',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'LABAEABH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LABAEABH'
    },
    {
      status: 210,
      sku: 'PM0078832',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311932',
      rtl_batch_array: '6311932, 6356684',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2700,
      sppl_prcp: 5.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'EW1CXPHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW1CXPHJ'
    },
    {
      status: 810,
      sku: 'PM0085539',
      core_name: 'Plant',
      sppl_ean: '8720664868342',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112460',
      rtl_batch_array: '6112460',
      name_search: 'HOSBREEZ',
      name: "Hosta 'Summer Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TENZX75X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TENZX75X'
    },
    {
      status: 910,
      sku: 'PM0078833',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'PAJT8X45',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PAJT8X45'
    },
    {
      status: 210,
      sku: 'PM0085540',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112562',
      rtl_batch_array: '6112562',
      name_search: 'LESTINA',
      name: "Leucanthemum (S) 'Stina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 431,
      chnn_stock_retail: 431,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '697E76CP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '697E76CP'
    },
    {
      status: 210,
      sku: 'PM0085541',
      core_name: 'Plant',
      sppl_ean: '8720664873766',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112620',
      rtl_batch_array: '6112620',
      name_search: 'LYPHHERO',
      name: "Lysimachia punctata 'Hometown Hero'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9L9VRXGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L9VRXGC'
    },
    {
      status: 210,
      sku: 'PM0085542',
      core_name: 'Plant',
      sppl_ean: '8720664871625',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112678',
      rtl_batch_array: '6112678',
      name_search: 'LAAPBLON',
      name: 'Lavandula angustifolia Platinum Blonde',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 745,
      chnn_stock_retail: 745,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TLTYCYXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLTYCYXL'
    },
    {
      status: 210,
      sku: 'PM0085543',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220662',
      rtl_batch_array: '6220662',
      name_search: 'ATFFVICT',
      name: "Athyrium filix-femina 'Victoriae'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YZHK6NTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZHK6NTN'
    },
    {
      status: 210,
      sku: 'PM0079096',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314860',
      rtl_batch_array: '6314860, 6287299',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 223,
      chnn_stock_retail: 244,
      sppl_prcp: 23.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'LRKLSKTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRKLSKTN'
    },
    {
      status: 210,
      sku: 'PM0078836',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311937',
      rtl_batch_array: '6311937',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '060080C34',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      sppl_stock_available: 174,
      chnn_stock_retail: 174,
      sppl_prcp: 3.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'VEZHXBGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VEZHXBGP'
    },
    {
      status: 810,
      sku: 'PM0078837',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311938',
      rtl_batch_array: '6311938',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '080100C34',
      rng_range_identifier: 'H080100C3.5',
      rng_range_description: 'H80 cm 100 cm C3.5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 4.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'N5EWP71R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5EWP71R'
    },
    {
      status: 810,
      sku: 'PM0026277',
      core_name: 'Plant',
      sppl_ean: '8720353018249',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '4735893',
      rtl_batch_array: '4735893',
      name_search: 'SIRFAVOU',
      name: "Silene 'Rolly's Favourite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L4L89TJ8;JTY73Y55;JBYLTL47;CSY535K6;TT6SYNCX;6NLV8J54',
      statusContent: 'dot',
      hash: '6RC3REJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RC3REJV'
    },
    {
      status: 210,
      sku: 'PM0069691',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301091',
      rtl_batch_array: '6301091, 6301574',
      name_search: 'POMUNITU',
      name: 'Polystichum munitum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2958,
      chnn_stock_retail: 3994,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJR2JKX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJR2JKX7'
    },
    {
      status: 910,
      sku: 'PM0070167',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASTURBIN',
      name: 'Aster turbinellus',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'XJWAX7GJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XJWAX7GJ'
    },
    {
      status: 210,
      sku: 'PM0078840',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311941',
      rtl_batch_array: '6311941',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 722,
      chnn_stock_retail: 722,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: '2NJJ6XC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NJJ6XC4'
    },
    {
      status: 210,
      sku: 'PM0078841',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311942',
      rtl_batch_array: '6311942',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'NH2D78LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH2D78LC'
    },
    {
      status: 210,
      sku: 'PM0078842',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311943',
      rtl_batch_array: '6311943',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '040060C34',
      rng_range_identifier: 'H040060C3.5',
      rng_range_description: 'H40 cm 60 cm C3.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 3.996,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'E77TNAR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E77TNAR5'
    },
    {
      status: 210,
      sku: 'PM0079098',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345461',
      rtl_batch_array: '6345461',
      name_search: 'AKQCFORM',
      name: "Akebia quinata 'Cream Form'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 417,
      chnn_stock_retail: 417,
      sppl_order_minimum: 3,
      sppl_prcp: 5.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JREGS5ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JREGS5ED'
    },
    {
      status: 210,
      sku: 'PM0079099',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314865',
      rtl_batch_array: '6314865',
      name_search: 'AKQUINAT',
      name: 'Akebia quinata',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 9448,
      chnn_stock_retail: 9448,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YA8H49S7;1PD4YN1L;XR8L16NA;L23SYYRZ;XPSYNY1T;WVH7DEDD;1RGDRZHP;LKYER7P7;6SV47663;B1V71WA5;84ZGCLTK;GPRZ1B8P;TRA52T42;996S9XYN',
      statusContent: 'dot',
      hash: 'HT2EAJNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT2EAJNG'
    },
    {
      status: 210,
      sku: 'PM0079100',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314866',
      rtl_batch_array: '6314866',
      name_search: 'ASTRILOB',
      name: 'Asimina triloba',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LK93N2ZC',
      statusContent: 'dot',
      hash: 'XC36HKLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC36HKLR'
    },
    {
      status: 210,
      sku: 'PM0079101',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346678',
      rtl_batch_array: '6346678',
      name_search: 'AUJCROTO',
      name: "Aucuba japonica 'Crotonifolia'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PSDCYARP;6S6TGEKA;WW32JBP9;K12KTR8A;LDAXEP4N;B7JHYEN5;V3ZKDYBG;1772LZKR;2D2DECH5',
      statusContent: 'dot',
      hash: 'D71ENGHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D71ENGHG'
    },
    {
      status: 210,
      sku: 'PM0079102',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346679',
      rtl_batch_array: '6346679',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: 'WDXEZKA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDXEZKA6'
    },
    {
      status: 210,
      sku: 'PM0078839',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311940',
      rtl_batch_array: '6311940',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 846,
      chnn_stock_retail: 846,
      sppl_prcp: 7.152,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'KGWKAYNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGWKAYNS'
    },
    {
      status: 810,
      sku: 'PM0079103',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314871',
      rtl_batch_array: '6314871',
      name_search: 'CAMGRAND',
      name: 'Campsis grandiflora',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ7KN4YY;TV1R2SXN;CWEJY5ZD;YWPAW2KZ;887ACC3N;D68WNJ6V',
      statusContent: 'dot',
      hash: 'TG7JT2X8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TG7JT2X8'
    },
    {
      status: 210,
      sku: 'PM0079104',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314872',
      rtl_batch_array: '6314872',
      name_search: 'CARFLAVA',
      name: "Campsis radicans 'Flava'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JCHVEJWX;955JNY8S;NAVWW5X8;LRPCG31B;L7R7ZV3X;YJWS76ZG',
      statusContent: 'dot',
      hash: '1WA1NWRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WA1NWRJ'
    },
    {
      status: 210,
      sku: 'PM0079105',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314873',
      rtl_batch_array: '6314873',
      name_search: 'CEODIANA',
      name: "Celastrus orbiculatus 'Diana'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 773,
      chnn_stock_retail: 773,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9NZGN3WG;1VT9184W;4VJ541T8;L8RB8R3D;2AJ9PXNS;Z5ZCAAB7',
      statusContent: 'dot',
      hash: 'AHLHED32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHLHED32'
    },
    {
      status: 210,
      sku: 'PM0079106',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314874',
      rtl_batch_array: '6314874',
      name_search: 'CEOHERCU',
      name: "Celastrus orbiculatus 'Hercules'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 814,
      chnn_stock_retail: 814,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '798XXKZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '798XXKZ2'
    },
    {
      status: 210,
      sku: 'PM0047353',
      core_name: 'Plant',
      sppl_ean: '8720664890411',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164900',
      rtl_batch_array: '6164900',
      name_search: 'WEFNPURP',
      name: "Weigela florida 'Nana Purpurea'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8E4X3P5K;W26V4REV',
      statusContent: 'dot',
      hash: 'WD5K8XAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WD5K8XAZ'
    },
    {
      status: 210,
      sku: 'PM0066860',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350005',
      rtl_batch_array: '6350005',
      name_search: 'DOHIRSUT',
      name: 'Dorycnium hirsutum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTC1Y955',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTC1Y955'
    },
    {
      status: 810,
      sku: 'PM0079107',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314875',
      rtl_batch_array: '6314875',
      name_search: 'CLALPINA',
      name: 'Clematis alpina',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2C7PLSEZ;AE5XEJS4;55X28X29;SAZB1E3B;SP1BKTHH;GK1NTCX9;976R9WLB;4WLYX3XG',
      statusContent: 'dot',
      hash: 'G4E6ZWJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4E6ZWJV'
    },
    {
      status: 210,
      sku: 'PM0079108',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314876',
      rtl_batch_array: '6314876',
      name_search: 'CLCHRYSO',
      name: 'Clematis chrysocoma',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4690,
      chnn_stock_retail: 4690,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '143SVPTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '143SVPTH'
    },
    {
      status: 210,
      sku: 'PM0079109',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314877',
      rtl_batch_array: '6314877',
      name_search: 'CLDEDINB',
      name: "Clematis 'Duchess of Edinburgh'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PG52JD4D',
      statusContent: 'dot',
      hash: '6ZSTEK2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZSTEK2P'
    },
    {
      status: 210,
      sku: 'PM0079111',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314879',
      rtl_batch_array: '6314879',
      name_search: 'CLJOE',
      name: "Clematis 'Joe'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 978,
      chnn_stock_retail: 978,
      sppl_order_minimum: 3,
      sppl_prcp: 4.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8AEN8D21;1WKKTPEL;XTXWT441;HNX2KTX5',
      statusContent: 'dot',
      hash: '1W31ER96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1W31ER96'
    },
    {
      status: 210,
      sku: 'PM0027500',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244259',
      rtl_batch_array: '6244259, 6254777, 5837444',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 7873,
      chnn_stock_retail: 9582,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      statusContent: 'dot',
      hash: '2SPA32LX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SPA32LX'
    },
    {
      status: 210,
      sku: 'PM0009027',
      core_name: 'Plant',
      sppl_ean: '8720349412006',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2729612',
      rtl_batch_array: '2729612',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      imageBatch: 'PYPB9CZV',
      statusContent: 'dot',
      hash: '1C7B9GB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1C7B9GB4'
    },
    {
      status: 810,
      sku: 'PM0065391',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301654',
      rtl_batch_array: '6301654, 6164308',
      name_search: 'HIAURANT',
      name: 'Hieracium aurantiacum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 562,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R64T2KCR;G8CDVBZT;J1A1RZ68;9KDG1WV3;CXXNAR88;L8Y3YXDT;PT1598YW;P6W27GR3',
      statusContent: 'dot',
      hash: 'ZLT31BHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLT31BHV'
    },
    {
      status: 210,
      sku: 'PM0066910',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349876',
      rtl_batch_array: '6349876',
      name_search: 'EDI81622',
      name: "Lilium (mar) 'Orange Marmalade'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WC3PWG4B',
      statusContent: 'dot',
      hash: 'PYV4HNXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYV4HNXB'
    },
    {
      status: 210,
      sku: 'PM0068247',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193821',
      rtl_batch_array: '6193821',
      name_search: 'SCCMAJOR',
      name: "Schizostylis coccinea 'Major'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EV7R1JP4;TWA3D81N;H4BCP5H3;JJBHN96V;PDKEZ3NH;2YJ9TWPE;PEDPTR6C;DYXDDGL1;T73XA4YX;TBJT7SNW;ZALYLXCJ;LTAHXBCV;7EKTX617',
      statusContent: 'dot',
      hash: 'JSZ7XXHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSZ7XXHX'
    },
    {
      status: 210,
      sku: 'PM0078838',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311939',
      rtl_batch_array: '6311939',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 890,
      chnn_stock_retail: 890,
      sppl_prcp: 1.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'RZZH4EBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZZH4EBW'
    },
    {
      status: 210,
      sku: 'PM0065397',
      core_name: 'Plant',
      sppl_ean: '8720664869752',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164318',
      rtl_batch_array: '6350557, 6164318',
      name_search: 'INMAGNIF',
      name: 'Inula magnifica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 103,
      chnn_stock_retail: 694,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1RR1Y769;GXW3AJ91',
      statusContent: 'dot',
      hash: 'HGSGBE6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGSGBE6V'
    },
    {
      status: 210,
      sku: 'PM0032819',
      core_name: 'Plant',
      sppl_ean: '8720349410477',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837399',
      rtl_batch_array: '5837399',
      name_search: 'BECSENIO',
      name: "Bergenia cordifolia 'Senior'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1970,
      chnn_stock_retail: 1970,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XA6YBY4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA6YBY4C'
    },
    {
      status: 210,
      sku: 'PM0065546',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6234481',
      rtl_batch_array: '6195730, 6234481',
      name_search: 'SCCBBLUE',
      name: "Scabiosa columbaria 'Butterfly Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 860,
      chnn_stock_retail: 902,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RCX7ZER6;XS84HEJG;RCS8ERW9;248SSX9Y;VKEA4ZLE;1B1S1NY2;G434PYGR;XY35VGB6;AEWGA53Z;9NY4N5D2;LZTB1HYD;V1HA1LRE;RX2A6GTS;194VANWB;75ESPGRC;XBY3287P',
      statusContent: 'dot',
      hash: 'VB3N1H4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VB3N1H4A'
    },
    {
      status: 810,
      sku: 'PM0079097',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314863',
      rtl_batch_array: '6314863',
      name_search: 'ACKOLOMI',
      name: 'Actinidia kolomikta',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EBYDKRXR;KYS7K31K;S6RX6TGJ;974N3855',
      statusContent: 'dot',
      hash: '2AB335B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AB335B7'
    },
    {
      status: 210,
      sku: 'PM0078835',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311936',
      rtl_batch_array: '6311936',
      name_search: 'PRSPINOS',
      name: 'Prunus spinosa',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NNAGJ3G;HCLHBHJR;ACJT86AY;ZCDXKXH2;9YTBC1EC;Y994TXX8;49PWEAKR;BVNEB8A6;P6BZ84H3;564BYGKH;NT3ZJDWL;1GYEV4PZ;74VT253K;28PJD3E1;9XPT87KW;236N7DA1;8XKL4D8S;ZNGC59GE;R474ZWDV;X45CPXEL;DGX6S9DL;RG5KAWSY',
      statusContent: 'dot',
      hash: '2XVBJEWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XVBJEWE'
    },
    {
      status: 210,
      sku: 'PM0078834',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311935',
      rtl_batch_array: '6311935',
      name_search: 'PRSPINOS',
      name: 'Prunus spinosa',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 1361,
      chnn_stock_retail: 1361,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NNAGJ3G;HCLHBHJR;ACJT86AY;ZCDXKXH2;9YTBC1EC;Y994TXX8;49PWEAKR;BVNEB8A6;P6BZ84H3;564BYGKH;NT3ZJDWL;1GYEV4PZ;74VT253K;28PJD3E1;9XPT87KW;236N7DA1;8XKL4D8S;ZNGC59GE;R474ZWDV;X45CPXEL;DGX6S9DL;RG5KAWSY',
      statusContent: 'dot',
      hash: 'VDV95J1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDV95J1Z'
    },
    {
      status: 210,
      sku: 'PM0079110',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314878',
      rtl_batch_array: '6314878',
      name_search: 'CLESENSA',
      name: "Clematis 'Early Sensation'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1088,
      chnn_stock_retail: 1088,
      sppl_order_minimum: 3,
      sppl_prcp: 4.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NZBGSA1L;TY79CC94;NRNTNDJ8;ZP7X6B8S;TNJSZAEJ;AKH6KBVT;VNSK6R8H;VX7YW2BP;79GL14DH;J46DBNLH;84S1P9K5;7W4SR4VZ;VBRKLRZR;BTSDLP1C;4YYK9LZH;2PCR6XVS;BS7E6X74;W9HC8R2A;RPRBR8T4;VZPWGXPP;SDKPY9BL',
      statusContent: 'dot',
      hash: 'WBPX7XLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBPX7XLJ'
    },
    {
      status: 910,
      sku: 'PM0079093',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GAPROCUM',
      name: 'Gaultheria procumbens',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'SAPSVZ78;2E9BY7E8;N9J99DZA;XV9X8219;T5TT7PBP;66H4BZ4X;16ATYWBR;XYW5E6YX;VVPNT1AR;9WSSE2RC;BCD3XLKT;Y8VHXSEW;W4ATXP61;H476TL3X;TCX6DHZA;WKEK1XBV;RYDYAA46;AJYW4K1V;Z12J3TY1;PVXGE37Y;K2W9LKND;CCYX83RP',
      statusContent: 'dot',
      hash: 'CPZDDLKA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CPZDDLKA'
    },
    {
      status: 210,
      sku: 'PM0065547',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6234483',
      rtl_batch_array: '6195731, 6234483',
      name_search: 'SCCPMIST',
      name: "Scabiosa columbaria 'Pink Mist'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 644,
      chnn_stock_retail: 746,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2PKNNLY;Y9VCJZSB;DAPC8EPD;G8L1XJP9;P8SCRJH3;9DCWCDVV;76X5ZJZR',
      statusContent: 'dot',
      hash: 'JCKGGDJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCKGGDJC'
    },
    {
      status: 810,
      sku: 'PM0066863',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349939',
      rtl_batch_array: '6349939',
      name_search: 'ACAMERIC',
      name: 'Actaea americana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7V99VVWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7V99VVWB'
    },
    {
      status: 210,
      sku: 'PM0070169',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300470',
      rtl_batch_array: '6300470',
      name_search: 'BAADCHOC',
      name: "Baptisia australis 'Dutch Chocolate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GCS19DL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCS19DL5'
    },
    {
      status: 810,
      sku: 'PM0066859',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350002',
      rtl_batch_array: '6350002',
      name_search: 'DIUNIFLO',
      name: 'Disporum uniflorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7933T18Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7933T18Y'
    },
    {
      status: 210,
      sku: 'PM0023973',
      core_name: 'Plant',
      sppl_ean: '8720349411795',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2729611',
      rtl_batch_array: '2729611',
      name_search: 'CACFCURL',
      name: "Carex comans 'Frosted Curls'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 487,
      chnn_stock_retail: 487,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XWKKGR1V;WBG361VY;99GYTLL2;SX6AA7AB;TAT6WW2B;4W5AP4D3;5XED8LE6;TV536JZ1;W7KPK1J9;7971T56V;BD5VP1GT;CACEEJZK;DXGX5LHS;TZWJXXTJ;CTT6XEBW;TXCN2PBC;ZSNPT7LE;YLWRR1D7;HA74WY1T;423BPXP4;8Y43J9RJ;5XD6K3KB;7CY4KK8R',
      imageBatch: 'LXWGL69T',
      statusContent: 'dot',
      hash: '75HGLT3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75HGLT3B'
    },
    {
      status: 210,
      sku: 'PM0033856',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244257',
      rtl_batch_array: '6244257, 6305773',
      name_search: 'LYSBLUSH',
      name: "Lythrum salicaria 'Blush'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1627,
      chnn_stock_retail: 2101,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTYRH63;2VE522WZ',
      statusContent: 'dot',
      hash: 'VS5RTDR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VS5RTDR9'
    },
    {
      status: 210,
      sku: 'PM0065400',
      core_name: 'Plant',
      sppl_ean: '8720664871359',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164324',
      rtl_batch_array: '6268781, 6164324',
      name_search: 'LALPPEAR',
      name: "Lathyrus latifolius 'Pink Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 799,
      chnn_stock_retail: 1415,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '11LAW549;E3E6SSKL',
      statusContent: 'dot',
      hash: '7ZDJ65H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZDJ65H8'
    },
    {
      status: 210,
      sku: 'PM0065396',
      core_name: 'Plant',
      sppl_ean: '8720664868434',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164315',
      rtl_batch_array: '6164315',
      name_search: 'HOTHALCY',
      name: "Hosta tardiana 'Halcyon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 872,
      chnn_stock_retail: 872,
      sppl_order_minimum: 3,
      sppl_prcp: 1.195,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '54X14YKR;L3Z4VKT7;N7N8EANJ;AYC82YGA;VVDVJN3H;2WS5YX34;DELTHG7L;883PDDBA;GJ4EHN28;G3E7NVWK;DW6DTEAC;RS1NYJX2;LWP5X28Z;ZYZ5GSTL;YB6WKH8V;CLLG8CE8;HV1HN9GK',
      statusContent: 'dot',
      hash: 'VSTECABE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSTECABE'
    },
    {
      status: 210,
      sku: 'PM0065425',
      core_name: 'Plant',
      sppl_ean: '8720664878396',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164409',
      rtl_batch_array: '6159912, 6164409',
      name_search: 'PHSFLAME',
      name: "Phlox (S) 'Scarlet Flame'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 736,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJRZHX43;ZWEHN9AG',
      statusContent: 'dot',
      hash: 'VLNN81R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLNN81R7'
    },
    {
      status: 210,
      sku: 'PM0070170',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220671',
      rtl_batch_array: '6220671, 6301072, 6229855',
      name_search: 'ARDKNEIF',
      name: "Aruncus dioicus 'Kneiffii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1067,
      chnn_stock_retail: 2695,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7V2P9ZLV',
      statusContent: 'dot',
      hash: 'AZJ57JVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZJ57JVV'
    },
    {
      status: 210,
      sku: 'PM0059741',
      core_name: 'Plant',
      sppl_ean: '8720664866393',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125557',
      rtl_batch_array: '6125557',
      name_search: 'HEHELIAN',
      name: 'Heliopsis helianthoides',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.716,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4ET9BD6G',
      statusContent: 'dot',
      hash: 'XD1AS2E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD1AS2E1'
    },
    {
      status: 210,
      sku: 'PM0071283',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278302',
      rtl_batch_array: '6278302',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'B5G13SKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5G13SKA'
    },
    {
      status: 210,
      sku: 'PM0015193',
      core_name: 'Plant',
      sppl_ean: '8720349411955',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '3833897',
      rtl_batch_array: '3833897, 6336094',
      name_search: 'CAOEVERC',
      name: "Carex oshimensis 'Evercream'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 3365,
      sppl_prcp: 2.266,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHYDAYZ7;824ALR5C;78B491P4;A5YVTLGC;EZKX9J3L;27NZ3ZA6;1TGH2DYP;SNHVK6BT;G2EP7D6V;4BX27KC6;D9ZY5CKC;RAC6N5RC;1GL5T997;D5TAD56B;2671EJER;TWZ523JN;X8DADD9B;6VTKNK1V;Z8VASE2C;LE7P74BX;BHWZTAYB;BS381A96;81H14WR8;6A114P81;5YY6EBZD',
      imageBatch: '8HE43PAB',
      statusContent: 'dot',
      hash: 'EVSBB7NZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVSBB7NZ'
    },
    {
      status: 210,
      sku: 'PM0065655',
      core_name: 'Plant',
      sppl_ean: '8720664864405',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165718',
      rtl_batch_array: '6165718',
      name_search: 'GESANNE',
      name: "Geranium 'Sanne'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6812,
      chnn_stock_retail: 6812,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WN82671V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WN82671V'
    },
    {
      status: 210,
      sku: 'PM0068283',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196850',
      rtl_batch_array: '6196850, 6338949',
      name_search: 'MOSCHNEE',
      name: "Monarda 'Schneewittchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 775,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7AHL834A;KLAW589W',
      statusContent: 'dot',
      hash: 'JX8TR3XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JX8TR3XG'
    },
    {
      status: 210,
      sku: 'PM0002634',
      core_name: 'Plant',
      sppl_ean: '8720349406463',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2733855',
      rtl_batch_array: '2733855',
      name_search: 'MOCVARIE',
      name: "Molinia caerulea 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 465,
      chnn_stock_retail: 465,
      sppl_prcp: 2.119,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APGCDRGV;H3Z46J2G;YRCP5SX2;884LNKDE;4VDJGWA7;R78CP6VZ;EYBY3X4X',
      imageBatch: 'L9TRHJ1J',
      statusContent: 'dot',
      hash: 'XH5NSR3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH5NSR3X'
    },
    {
      status: 210,
      sku: 'PM0070172',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220678',
      rtl_batch_array: '6220678, 6309018, 6349772',
      name_search: 'ASMPRIMA',
      name: "Astrantia major 'Primadonna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2834,
      chnn_stock_retail: 15127,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BPTZKA1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPTZKA1L'
    },
    {
      status: 210,
      sku: 'PM0066994',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301827',
      rtl_batch_array: '6301827, 6330114',
      name_search: 'SCSPBLUE',
      name: "Schizachyrium scoparium 'Prairie Blues'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 395,
      chnn_stock_retail: 1779,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CVSHEN32;XJXE6YB2',
      statusContent: 'dot',
      hash: '1Y96K5DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y96K5DP'
    },
    {
      status: 910,
      sku: 'PM0038322',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083858',
      rtl_batch_array: '6083858',
      name_search: 'DCMGEBLO',
      name: 'DCM Meststof Geranium & Bloeiende pl.',
      sppl_size_code: '014KGDOOS',
      rng_range_identifier: 'BOX1.5KG',
      rng_range_description: 'Box 1.5 Kilogram',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 5,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '566K35LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '566K35LD'
    },
    {
      status: 910,
      sku: 'PM0079115',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUGUNNII',
      name: 'Eucalyptus gunnii',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        'N77JBGJC;KRR9XS6V;9EDYKY3J;24TEW54R;EJ7N4D8L;B9XV89SA;16THLA2T;CS9NC46S;GEJCCRHS;CRTS1WJC;XNJC7CSS;R269X1DV;KDB7BHC9',
      statusContent: 'dot',
      hash: 'PWS9C5BW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PWS9C5BW'
    },
    {
      status: 810,
      sku: 'PM0059786',
      core_name: 'Plant',
      sppl_ean: '8720664876491',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6128571',
      rtl_batch_array: '6128571',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'TVA47CBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVA47CBB'
    },
    {
      status: 910,
      sku: 'PM0038321',
      core_name: 'Garden',
      sppl_ean: '5413448127836',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 2,
      btch_active_retail: '5915104',
      rtl_batch_array: '6083856, 5915104',
      name_search: 'DCGKALK',
      name: 'DCM Groen-kalk',
      sppl_size_code: '04KGDOOS',
      rng_range_identifier: 'BOX4KG',
      rng_range_description: 'Box 4 Kilogram',
      sppl_stock_available: 3,
      chnn_stock_retail: 7,
      sppl_prcp: 4.36,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '2TZJWZWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '2TZJWZWH'
    },
    {
      status: 210,
      sku: 'PM0066996',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353449',
      rtl_batch_array: '6353449',
      name_search: 'SIDSKIES',
      name: "Sisyrinchium 'Devon Skies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6CEC1PZ5',
      statusContent: 'dot',
      hash: 'VXTNSZZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXTNSZZB'
    },
    {
      status: 210,
      sku: 'PM0079138',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314922',
      rtl_batch_array: '6314922',
      name_search: 'PYCRCOLU',
      name: "Pyracantha coccinea 'Red Column'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4845,
      chnn_stock_retail: 4845,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B18HB2C9;5ZAV8GNX',
      statusContent: 'dot',
      hash: 'THS2B7W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THS2B7W5'
    },
    {
      status: 210,
      sku: 'PM0079136',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314920',
      rtl_batch_array: '6314920',
      name_search: 'PATVROBU',
      name: "Parthenocissus tricuspidata 'Veitchii Robusta'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 388,
      chnn_stock_retail: 388,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X51Y72J8',
      statusContent: 'dot',
      hash: 'C24ZCJT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C24ZCJT1'
    },
    {
      status: 210,
      sku: 'PM0079139',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314923',
      rtl_batch_array: '6314923',
      name_search: 'PYCRCOLU',
      name: "Pyracantha coccinea 'Red Column'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B18HB2C9;5ZAV8GNX',
      statusContent: 'dot',
      hash: 'K7934Y1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7934Y1B'
    },
    {
      status: 910,
      sku: 'PM0038341',
      core_name: 'Garden',
      sppl_ean: '5413448139488',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 2,
      btch_active_retail: '4667629',
      rtl_batch_array: '4667629, 6083866',
      name_search: 'DCVMGBIO',
      name: 'DCM Vloeibare Mestst. Geraniums BIO',
      sppl_size_code: '08LTRFLES',
      rng_range_identifier: 'BOT0.8LTR',
      rng_range_description: 'Bottle 0.8 Liter',
      sppl_stock_available: 10,
      chnn_stock_retail: 22,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'VCLKTR6H',
      statusContent: 'dot',
      hash: 'BLHVSYBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'BLHVSYBG'
    },
    {
      status: 910,
      sku: 'PM0038336',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083862',
      rtl_batch_array: '6083862',
      name_search: 'DCMRHAZA',
      name: 'DCM Meststof Rhodo. Hortensia en Azalea',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'D4NXHVXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'D4NXHVXX'
    },
    {
      status: 210,
      sku: 'PM0079137',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314921',
      rtl_batch_array: '6314921',
      name_search: 'PUGRANAT',
      name: 'Punica granatum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G4RTGDNL;11S7J6DS;5H479RT1;CH9445Z6;8RA6PSK1;LY5VC4J3;L9C7ZW1V;ZP5ES5NP;R2JG5PWP;5Z3VYWD2;E3BNHAT3;XDC2J67C;12WSDV9R;E29J56RN;T353JAPN;VJT7T64H;WP4AX36R;ZA4P3NTY;21HR3TEY;AKT94J9H;CH7J6B54;VX5SDTBH;YZRY59EZ;C1VDYJ1N',
      statusContent: 'dot',
      hash: 'WVXYC749',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVXYC749'
    },
    {
      status: 210,
      sku: 'PM0081949',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349160',
      rtl_batch_array: '6349160',
      name_search: 'VESRZWER',
      name: "Veronica spicata 'Rosa Zwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5112,
      chnn_stock_retail: 5112,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPA7AYL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPA7AYL6'
    },
    {
      status: 910,
      sku: 'PM0038324',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083869',
      rtl_batch_array: '6083869',
      name_search: 'DCVMROZE',
      name: 'DCM Vloeibare Mestst. Rozen',
      sppl_size_code: '08LTRFLES',
      rng_range_identifier: 'BOT0.8LTR',
      rng_range_description: 'Bottle 0.8 Liter',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'D8PKR65G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'D8PKR65G'
    },
    {
      status: 210,
      sku: 'PM0081950',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349163',
      rtl_batch_array: '6349163',
      name_search: 'ALRCDOUB',
      name: 'Alcea rosea Chaters Double Hybrids',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1124,
      chnn_stock_retail: 1124,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1LNRSYA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LNRSYA8'
    },
    {
      status: 910,
      sku: 'PM0038329',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083867',
      rtl_batch_array: '6083867',
      name_search: 'DCVMGEKR',
      name: 'DCM Vloeibare mestst. Groente en Kruiden',
      sppl_size_code: '08LTRFLES',
      rng_range_identifier: 'BOT0.8LTR',
      rng_range_description: 'Bottle 0.8 Liter',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'K3P5SP18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'K3P5SP18'
    },
    {
      status: 210,
      sku: 'PM0081951',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349164',
      rtl_batch_array: '6349164',
      name_search: 'BRMASILV',
      name: 'Brunnera macr. Alchemy Silver',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 862,
      chnn_stock_retail: 862,
      sppl_order_minimum: 3,
      sppl_prcp: 1.991,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7D4HBR4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D4HBR4A'
    },
    {
      status: 210,
      sku: 'PM0067141',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6287672',
      rtl_batch_array: '6369734, 6177401, 6287672',
      name_search: 'EPPORANG',
      name: "Epimedium pubigerum 'Orangek\u00f6nigin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 235,
      chnn_stock_retail: 1741,
      sppl_order_minimum: 3,
      sppl_prcp: 1.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HXASHWW8',
      statusContent: 'dot',
      hash: '92EXC9ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92EXC9ED'
    },
    {
      status: 210,
      sku: 'PM0066137',
      core_name: 'Plant',
      sppl_ean: '8720664883932',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170319',
      rtl_batch_array: '6170319',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 2008,
      chnn_stock_retail: 2008,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: 'RY66H9G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RY66H9G3'
    },
    {
      status: 210,
      sku: 'PM0013236',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 6,
      btch_active_retail: '6184116',
      rtl_batch_array: '6133847, 6184116, 5495551, 5837875, 6353585, 6300719',
      name_search: 'VIMATROP',
      name: "Vinca minor 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2952,
      chnn_stock_retail: 24168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9RN6Z5R2;K9WE8974;SNN5WREA;VB7TSAX8;D86DG5HP;7DR4L4KK;Y3TE5BB4;EKSPVVN3;52KHDXEY',
      statusContent: 'dot',
      hash: 'CPVE2VS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPVE2VS6'
    },
    {
      status: 910,
      sku: 'PM0038337',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083861',
      rtl_batch_array: '6083861',
      name_search: 'DCMLAVEL',
      name: 'DCM Meststof Lavendel',
      sppl_size_code: '014KGDOOS',
      rng_range_identifier: 'BOX1.5KG',
      rng_range_description: 'Box 1.5 Kilogram',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 5,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'YCWBG5N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'YCWBG5N7'
    },
    {
      status: 210,
      sku: 'PM0021044',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6254903',
      rtl_batch_array: '3774091, 6254903, 6252666, 6271428',
      name_search: 'LIMMONEY',
      name: "Liriope muscari 'Moneymaker'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2963,
      chnn_stock_retail: 9170,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '81XERJJ2;HAX44LHN;J253LY3D;9CTAT8KG;NKPP8YB9;2RYJ5AAB;PZE397WP;YR2HZA2B;B5YXPS9J',
      statusContent: 'dot',
      hash: 'N4WETEJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4WETEJB'
    },
    {
      status: 210,
      sku: 'PM0065782',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301895',
      rtl_batch_array: '6080793, 6221378, 6301895',
      name_search: 'VEBOMBYC',
      name: 'Verbascum bombyciferum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 365,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9YGCP9H',
      statusContent: 'dot',
      hash: '2GPE6S6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GPE6S6C'
    },
    {
      status: 210,
      sku: 'PM0079140',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314924',
      rtl_batch_array: '6314924',
      name_search: 'PYGCHARM',
      name: "Pyracantha 'Golden Charmer'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 843,
      chnn_stock_retail: 843,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '47Z5KGBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47Z5KGBC'
    },
    {
      status: 810,
      sku: 'PM0081952',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349174',
      rtl_batch_array: '6349174',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.922,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'JP6T7HE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP6T7HE5'
    },
    {
      status: 210,
      sku: 'PM0053031',
      core_name: 'Plant',
      sppl_ean: '8720664808218',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057476',
      rtl_batch_array: '6057476',
      name_search: 'EDI63975',
      name: 'Scilla litardierei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ER1B2Y9V;EZHGGRL3;EYCJHXRV;6NR3EWY2;NSP8GBZ4;XALEY45K;E1VCRC8W;ADD19ECH;YZEE2TL9',
      statusContent: 'dot',
      hash: 'ARKZ37VS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARKZ37VS'
    },
    {
      status: 810,
      sku: 'PM0081953',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349175',
      rtl_batch_array: '6349175',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'TA4CJAW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA4CJAW5'
    },
    {
      status: 210,
      sku: 'PM0078844',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6312688',
      rtl_batch_array: '6312688',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 9.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'S3YA83GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3YA83GC'
    },
    {
      status: 210,
      sku: 'PM0078845',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6312690',
      rtl_batch_array: '6312690',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 8.678,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'K44SPV48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K44SPV48'
    },
    {
      status: 910,
      sku: 'PM0031885',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDMGKNI',
      name: "Buddleja davidii 'Magda's Gold Knight''",
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      statusContent: 'dot',
      hash: '27687YHZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '27687YHZ'
    },
    {
      status: 210,
      sku: 'PM0078846',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6312691',
      rtl_batch_array: '6312691',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 8.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'RW4XXP4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW4XXP4Y'
    },
    {
      status: 210,
      sku: 'PM0078847',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6312699',
      rtl_batch_array: '6312699',
      name_search: 'OSHGOSHI',
      name: "Osmanthus heterophyllus 'Goshiki'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7DT7HZTW;H3611KS5;HHWLDZG5;C4LVH8XZ;P61R6DLC;WVR7T9BV;YD81PWH8;VX335SLL;CNKTN4N8;GX5HBXJ9;NLYT7ZPN;26RLBC7W;D5PTRYSY;Y6LGAB3K;TKLEDCZP;XG83C181;CGDSPS5B;DC98HZPC',
      statusContent: 'dot',
      hash: 'T4XWXXA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4XWXXA7'
    },
    {
      status: 810,
      sku: 'PM0067006',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353534',
      rtl_batch_array: '6353534',
      name_search: 'UVGRANDI',
      name: 'Uvularia grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '78R6Z76G',
      statusContent: 'dot',
      hash: 'RH99VVLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RH99VVLL'
    },
    {
      status: 210,
      sku: 'PM0067004',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353519',
      rtl_batch_array: '6353519',
      name_search: 'TRFSAMUR',
      name: "Tricyrtis formosana 'Samurai'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1279,
      chnn_stock_retail: 1279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SDTWLH1T;7TN9L6LT;ADSLTZZ6;1WGVB251',
      statusContent: 'dot',
      hash: 'R13Z8HA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R13Z8HA3'
    },
    {
      status: 210,
      sku: 'PM0079112',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314882',
      rtl_batch_array: '6314882',
      name_search: 'CLORIENT',
      name: 'Clematis orientalis',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1464,
      chnn_stock_retail: 1464,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '57DCXJGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57DCXJGT'
    },
    {
      status: 210,
      sku: 'PM0079113',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314883',
      rtl_batch_array: '6314883',
      name_search: 'CLSQUEEN',
      name: "Clematis 'Snow Queen'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 888,
      chnn_stock_retail: 888,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECYD9N5W',
      statusContent: 'dot',
      hash: 'DE4C8LAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE4C8LAK'
    },
    {
      status: 210,
      sku: 'PM0079141',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314925',
      rtl_batch_array: '6314925',
      name_search: 'PYOGLOW',
      name: "Pyracantha 'Orange Glow'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2290,
      chnn_stock_retail: 2290,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBYDG2VB;JEBSXAXG',
      statusContent: 'dot',
      hash: 'AB23G5RY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB23G5RY'
    },
    {
      status: 810,
      sku: 'PM0081954',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349176',
      rtl_batch_array: '6349176',
      name_search: 'ACPORANG',
      name: "Acer palmatum 'Orangeola'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SJ46DJZL',
      statusContent: 'dot',
      hash: 'N19RN3V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N19RN3V7'
    },
    {
      status: 810,
      sku: 'PM0081955',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349177',
      rtl_batch_array: '6349177',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: '416Z9WV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '416Z9WV7'
    },
    {
      status: 210,
      sku: 'PM0085544',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385434',
      rtl_batch_array: '6385434',
      name_search: 'LIVLIGA',
      name: "Ligustrum vulgare 'Liga'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_order_minimum: 3,
      sppl_prcp: 1.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YW9BJHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YW9BJHV'
    },
    {
      status: 910,
      sku: 'PM0079116',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358868',
      rtl_batch_array: '6358868',
      name_search: 'EUGUNNII',
      name: 'Eucalyptus gunnii',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 7.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N77JBGJC;KRR9XS6V;9EDYKY3J;24TEW54R;EJ7N4D8L;B9XV89SA;16THLA2T;CS9NC46S;GEJCCRHS;CRTS1WJC;XNJC7CSS;R269X1DV;KDB7BHC9',
      statusContent: 'dot',
      hash: '4HNL9ETN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HNL9ETN'
    },
    {
      status: 810,
      sku: 'PM0081956',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349178',
      rtl_batch_array: '6349178',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.447,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: 'HG1C9XXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HG1C9XXR'
    },
    {
      status: 910,
      sku: 'PM0079117',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUAMERIC',
      name: 'Euonymus americanus',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore: 'J119NZ7D;T7VPLA2J;VTBRSP7H;SZERVEN4;8TD9122N;4G32E552',
      statusContent: 'dot',
      hash: 'ERY6ZG6H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ERY6ZG6H'
    },
    {
      status: 210,
      sku: 'PM0069708',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229796',
      rtl_batch_array: '6229796',
      name_search: 'PULEANDE',
      name: "Pulmonaria longifolia 'E.B. Anderson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 419,
      chnn_stock_retail: 419,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JN7P3BE6;HVDA4SDH;824VHEKD;S1N1SYKE;S2B1NDNZ;67BRXWBX;1ZHW8YCZ;CLYJW82C;JK11WWD1;NVWV6ZCJ',
      statusContent: 'dot',
      hash: 'J37KK1NV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J37KK1NV'
    },
    {
      status: 210,
      sku: 'PM0079118',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314896',
      rtl_batch_array: '6314896',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2300,
      chnn_stock_retail: 2300,
      sppl_order_minimum: 3,
      sppl_prcp: 2.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      statusContent: 'dot',
      hash: 'G9Z5XZNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9Z5XZNG'
    },
    {
      status: 810,
      sku: 'PM0081957',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349179',
      rtl_batch_array: '6349179',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: 'GRYTY2JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRYTY2JD'
    },
    {
      status: 210,
      sku: 'PM0059348',
      core_name: 'Plant',
      sppl_ean: '8720664862234',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6103247',
      rtl_batch_array: '6103247',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 11.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      statusContent: 'dot',
      hash: 'P8PTSBRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8PTSBRP'
    },
    {
      status: 210,
      sku: 'PM0079119',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314897',
      rtl_batch_array: '6314897',
      name_search: 'EUJGROCK',
      name: "Euonymus japonicus 'Green Rocket'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2438,
      chnn_stock_retail: 2438,
      sppl_prcp: 3.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HH3ETJHT;JZD8EJ9C;T6594AXT;XYDXESAH;BL4VJRJX;4C2AWT9Y;11R7ZSKW;YXJ49GV3;26E57Y79;9T87SV2E',
      statusContent: 'dot',
      hash: '28SSWLEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28SSWLEA'
    },
    {
      status: 210,
      sku: 'PM0079120',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314898',
      rtl_batch_array: '6314898',
      name_search: 'EUJGROCK',
      name: "Euonymus japonicus 'Green Rocket'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1958,
      chnn_stock_retail: 1958,
      sppl_prcp: 3.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HH3ETJHT;JZD8EJ9C;T6594AXT;XYDXESAH;BL4VJRJX;4C2AWT9Y;11R7ZSKW;YXJ49GV3;26E57Y79;9T87SV2E',
      statusContent: 'dot',
      hash: '6XESSELT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XESSELT'
    },
    {
      status: 210,
      sku: 'PM0079121',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314899',
      rtl_batch_array: '6314899',
      name_search: 'EUJGROCK',
      name: "Euonymus japonicus 'Green Rocket'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 468,
      chnn_stock_retail: 468,
      sppl_prcp: 4.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HH3ETJHT;JZD8EJ9C;T6594AXT;XYDXESAH;BL4VJRJX;4C2AWT9Y;11R7ZSKW;YXJ49GV3;26E57Y79;9T87SV2E',
      statusContent: 'dot',
      hash: 'E7HR5Y7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7HR5Y7S'
    },
    {
      status: 210,
      sku: 'PM0079122',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314900',
      rtl_batch_array: '6314900',
      name_search: 'EUJKATHY',
      name: "Euonymus japonicus 'Kathy'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2390,
      chnn_stock_retail: 2390,
      sppl_order_minimum: 3,
      sppl_prcp: 2.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7AYYBDZ9;PJ1WE347;71RR6VTP;4ZS6JS6R;WNW9N6BN;6RG9HR69;B81979LC;2PB1Y5J8;C95PJGX2;V71HT66P;RXA9CAYR;ERCT8HPH;6RK2JS7T;SS2T58C4;LDYV8ZYT;1HSLXJ6Z;N5JDJ4LT',
      statusContent: 'dot',
      hash: 'S11V49YP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S11V49YP'
    },
    {
      status: 210,
      sku: 'PM0085545',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385435',
      rtl_batch_array: '6385435',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8332RTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8332RTV'
    },
    {
      status: 210,
      sku: 'PM0079123',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314901',
      rtl_batch_array: '6314901',
      name_search: 'EUJSUSAN',
      name: "Euonymus japonicus 'Susan'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2385,
      chnn_stock_retail: 2385,
      sppl_order_minimum: 3,
      sppl_prcp: 2.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YRYJCSDB;G2ENXP6V;582DNDNN;XNBTS5Y9;SKVS2VKD;S5K81W5C;6J6DNDEH;1DXEJT81;BKKNA9NR;XHP9R2WC;H1S8WV1E;61TL7CXY;RSADHCJP',
      statusContent: 'dot',
      hash: 'LKJSSW3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKJSSW3A'
    },
    {
      status: 810,
      sku: 'PM0081958',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349181',
      rtl_batch_array: '6349181',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'TS2763G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TS2763G3'
    },
    {
      status: 810,
      sku: 'PM0081959',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349182',
      rtl_batch_array: '6349182',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'L6HDL1H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6HDL1H9'
    },
    {
      status: 910,
      sku: 'PM0079124',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      rng_range_identifier: 'H080100C2.5',
      rng_range_description: 'H80 cm 100 cm C2.5',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'NJSL7ZD5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NJSL7ZD5'
    },
    {
      status: 210,
      sku: 'PM0065656',
      core_name: 'Plant',
      sppl_ean: '8720664864443',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165719',
      rtl_batch_array: '6165719',
      name_search: 'GETRENDA',
      name: "Geranium 'Tanya Rendall'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1345,
      chnn_stock_retail: 1345,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YRY56ZRC',
      statusContent: 'dot',
      hash: 'YV6JTJBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YV6JTJBN'
    },
    {
      status: 910,
      sku: 'PM0085546',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385436',
      rtl_batch_array: '6385436',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 3.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'L55KK7V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L55KK7V2'
    },
    {
      status: 210,
      sku: 'PM0079126',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314905',
      rtl_batch_array: '6314905',
      name_search: 'LOCDUET',
      name: "Lonicera caerulea 'Duet'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 441,
      chnn_stock_retail: 441,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9L982W72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L982W72'
    },
    {
      status: 210,
      sku: 'PM0085547',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385440',
      rtl_batch_array: '6385440',
      name_search: 'ESCBALL',
      name: "Escallonia 'C.F. Ball'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '83BTXZW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83BTXZW7'
    },
    {
      status: 210,
      sku: 'PM0079144',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314928',
      rtl_batch_array: '6314928',
      name_search: 'RIUCRISP',
      name: 'Ribes uva-crispa',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPWWT5KY;1Y1AGK1S;XXVZX8L1;88CR5ET8;4LKWZSW5;1NEKNSHD;V7RH27EZ;YPGRDJX5;DXBVHEXG;ERYP3JZP;GSNGXNWG;H69GBY85;PYZ17N1R;PBK43BDE',
      statusContent: 'dot',
      hash: 'Y4WH3ZL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4WH3ZL6'
    },
    {
      status: 210,
      sku: 'PM0079145',
      core_name: 'Plant',
      sppl_ean: '8720626325210',
      btch_manufacturer: 734,
      rtl_batch_array_total: 3,
      btch_active_retail: '4706028',
      rtl_batch_array: '6346737, 6338484, 4706028',
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 150,
      chnn_stock_retail: 6096,
      sppl_prcp: 2.327,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9251DGD;4AK589AB;GT6C1BJL;77RP3J8Y;4ZPJNXK5;RHJJ77R5;1E1112G6;A3KPK1CH;DCNZEK7T;6CZ7HE6H;5EEDE6GG;A535J46V;VERWST57;13ZDCST5;8S57K3RX;TPNXG1TC;LS2CKYLV;REENVR6J',
      statusContent: 'dot',
      hash: 'EP7DTZ1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EP7DTZ1E'
    },
    {
      status: 210,
      sku: 'PM0079146',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346738',
      rtl_batch_array: '6346738',
      name_search: 'SAHHUMIL',
      name: 'Sarcococca hookeriana humilis',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 997,
      chnn_stock_retail: 997,
      sppl_prcp: 3.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8129VXKJ;CAEVYGPS;P2B7KXZW;NCL6CE76;21AB2EWV;C2T4155L;VHE3YL9H;XZDDJKXE;B53W7ND6;YKWKZYAY;ZZXP9NHA;GY4LK5GN;HWH2S3DP;T8RNDZNT',
      statusContent: 'dot',
      hash: 'DDEE8J2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDEE8J2K'
    },
    {
      status: 210,
      sku: 'PM0059789',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191455',
      rtl_batch_array: '6191455',
      name_search: 'PEADRED',
      name: "Persicaria affinis 'Darjeeling Red'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3135,
      chnn_stock_retail: 3135,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P1B9WA3A;RV18ATHJ;N7WVNH69;CRT52J8B',
      statusContent: 'dot',
      hash: 'XAY4ZEYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAY4ZEYD'
    },
    {
      status: 910,
      sku: 'PM0059274',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CODMOONC',
      name: "Cotoneaster dammeri 'Mooncreeper'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      statusContent: 'dot',
      hash: 'R5VLH4A3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R5VLH4A3'
    },
    {
      status: 210,
      sku: 'PM0067145',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382294',
      rtl_batch_array: '6301722, 6350690, 6382294',
      name_search: 'MISZEBRI',
      name: "Miscanthus sinensis 'Zebrinus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9000,
      chnn_stock_retail: 9805,
      sppl_order_minimum: 3,
      sppl_prcp: 0.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJBCBBRB;ADKPAXAV;YS489W1S;XRPXYL62;8GZVNA6C;EPCW87X6;J1YTEA1P;1GC6D5AS;6CZRSXHC;N4Y9WKJG',
      statusContent: 'dot',
      hash: '5ZYL69EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZYL69EV'
    },
    {
      status: 210,
      sku: 'PM0079127',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314906',
      rtl_batch_array: '6314906',
      name_search: 'LOCKAMTS',
      name: 'Lonicera caerulea kamtschatica',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 957,
      chnn_stock_retail: 957,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N1SDSTL3;G5XWZ6JS;J9E53BCG;Z8751Y7H;2TH2SJ56;YLZ4SE6N;1NHA3JDR;N8TE1ELP;13HJTVNR;DHBR21T7;LXG2STWN;WYHL68B9;Z8ZE4TW4',
      statusContent: 'dot',
      hash: '23E7ZB7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23E7ZB7B'
    },
    {
      status: 910,
      sku: 'PM0065787',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DICPRED',
      name: "Dianthus car. 'Peman Red'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'GNR28PV1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GNR28PV1'
    },
    {
      status: 210,
      sku: 'PM0079147',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314932',
      rtl_batch_array: '6314932',
      name_search: 'SCHMOONL',
      name: "Schizophragma hydrangeoides 'Moonlight'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1135,
      chnn_stock_retail: 1135,
      sppl_order_minimum: 3,
      sppl_prcp: 4.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LL5SD466;ZT3TVWWS;BVSR98PZ;9NEATXXT;HJRZLLJ9;1YJ4B1V5',
      statusContent: 'dot',
      hash: '4B8ZWLX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B8ZWLX6'
    },
    {
      status: 210,
      sku: 'PM0085548',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385441',
      rtl_batch_array: '6385441',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_prcp: 10.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'S83CRZBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S83CRZBY'
    },
    {
      status: 210,
      sku: 'PM0079143',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314927',
      rtl_batch_array: '6314927',
      name_search: 'PYSOR',
      name: "Pyracantha 'Soleil d'Or'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V7SYWATP;67HWYKH5;GNRYZY8E;2CLD6RED;ESH89THA;8KSP5GWY;V5D1LT7N;9JHPT4PG;ELJVPHGC',
      statusContent: 'dot',
      hash: 'BKEY8ZLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKEY8ZLC'
    },
    {
      status: 910,
      sku: 'PM0079148',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SKJREEVE',
      name: 'Skimmia japonica reevesiana',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      statusContent: 'dot',
      hash: '1YK1E68C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1YK1E68C'
    },
    {
      status: 210,
      sku: 'PM0079128',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314907',
      rtl_batch_array: '6314907',
      name_search: 'LOCSINOG',
      name: "Lonicera caerulea 'Sinoglaska'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZK1B2H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZK1B2H1'
    },
    {
      status: 210,
      sku: 'PM0079129',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314908',
      rtl_batch_array: '6314908',
      name_search: 'LOCINGA',
      name: "Lonicera caprifolium 'Inga'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1895,
      chnn_stock_retail: 1895,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NGHE4PR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGHE4PR7'
    },
    {
      status: 210,
      sku: 'PM0085549',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385444',
      rtl_batch_array: '6385444',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 367,
      chnn_stock_retail: 367,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'ZB1GBWTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZB1GBWTX'
    },
    {
      status: 210,
      sku: 'PM0066997',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353457',
      rtl_batch_array: '6353457',
      name_search: 'SPIJGCAR',
      name: "Spiraea japonica 'Green Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 618,
      chnn_stock_retail: 618,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N6X3KSK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6X3KSK1'
    },
    {
      status: 210,
      sku: 'PM0079130',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314909',
      rtl_batch_array: '6314909',
      name_search: 'LOFRAGRA',
      name: 'Lonicera fragrantissima',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 891,
      chnn_stock_retail: 891,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75B56H9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75B56H9L'
    },
    {
      status: 210,
      sku: 'PM0079131',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314910',
      rtl_batch_array: '6314910',
      name_search: 'LOHABEAU',
      name: "Lonicera heckrottii 'American Beauty'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YBZR4H14;ZE7A617B;K48637SS;CS33DV79;STBKRB2S;SPVEL8W9;83VR4644;Y3DA9VWE;EDXZYAK4;W17ZCDHZ',
      statusContent: 'dot',
      hash: 'PXJHGC9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXJHGC9J'
    },
    {
      status: 210,
      sku: 'PM0079132',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314911',
      rtl_batch_array: '6314911',
      name_search: 'LOJHPROL',
      name: "Lonicera japonica 'Hall's Prolific'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 611,
      chnn_stock_retail: 611,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHKL1DZY;PEANXCLV;CK5DDGCZ;2BXS6WAH;D3X52NJ1;5J46GSBA;5H8AKRL2;V11VCCSK;XE1KT76N;YV37XS54;KJAY3KGK',
      statusContent: 'dot',
      hash: '9H4DG27A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9H4DG27A'
    },
    {
      status: 210,
      sku: 'PM0079133',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314913',
      rtl_batch_array: '6314913',
      name_search: 'LOPBSELE',
      name: "Lonicera periclymenum 'Belgica Select'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 334,
      chnn_stock_retail: 334,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A3C9GH6E',
      statusContent: 'dot',
      hash: 'V1XXAKV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1XXAKV9'
    },
    {
      status: 210,
      sku: 'PM0079142',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314926',
      rtl_batch_array: '6314926',
      name_search: 'PYOGLOW',
      name: "Pyracantha 'Orange Glow'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBYDG2VB;JEBSXAXG',
      statusContent: 'dot',
      hash: '7VJJW778',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VJJW778'
    },
    {
      status: 210,
      sku: 'PM0079134',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314915',
      rtl_batch_array: '6314915',
      name_search: 'MOALBA',
      name: 'Morus alba',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 3.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XA9H45K6;LJ74YVZR;RS58BJT4',
      statusContent: 'dot',
      hash: 'VRBYV5SR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRBYV5SR'
    },
    {
      status: 210,
      sku: 'PM0079135',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314916',
      rtl_batch_array: '6314916',
      name_search: 'MONIGRA',
      name: 'Morus nigra',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APPRB2PC;KG2K79BC;RXALSNE6;CC3AT8R3;W6BAK1RT;ZRL9YTNG;WGLV6VXH;N51R48HJ;CZGX3ZZS;732N4T5E;B3Z2VGWW;K517A55Z;9JEZANJ5;YR5BPLHH;TV771L8R',
      statusContent: 'dot',
      hash: 'PCYYHXK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCYYHXK8'
    },
    {
      status: 910,
      sku: 'PM0059297',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLPOLST',
      name: "Prunus laurocerasus 'Polster'",
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      imageCore: 'GVWH2TYH',
      statusContent: 'dot',
      hash: 'ZEPVKGD8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZEPVKGD8'
    },
    {
      status: 210,
      sku: 'PM0005013',
      core_name: 'Plant',
      sppl_ean: '8720349411924',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3988746',
      rtl_batch_array: '3988746',
      name_search: 'CAMVICE',
      name: 'Carex morrowii Vanilla Ice',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 488,
      chnn_stock_retail: 488,
      sppl_prcp: 2.118,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'WEZSNDN4',
      statusContent: 'dot',
      hash: '7SLGY3C4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SLGY3C4'
    },
    {
      status: 810,
      sku: 'PM0079149',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314934',
      rtl_batch_array: '6314934',
      name_search: 'TRJRUMEL',
      name: 'Trachelium jacquinii rumelianum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8JEVB9W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8JEVB9W1'
    },
    {
      status: 210,
      sku: 'PM0079150',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314935',
      rtl_batch_array: '6314935',
      name_search: 'TRJSOSCI',
      name: "Trachelospermum j. 'Star of Sicily' \u00ae",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1445,
      chnn_stock_retail: 1445,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AH85VKZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AH85VKZK'
    },
    {
      status: 210,
      sku: 'PM0079151',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314937',
      rtl_batch_array: '6314937',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 496,
      chnn_stock_retail: 496,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'R1X3W3V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1X3W3V4'
    },
    {
      status: 210,
      sku: 'PM0079152',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314938',
      rtl_batch_array: '6314938',
      name_search: 'TRJPSHOW',
      name: "Trachelospermum jasminoides 'Pink Showers'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RBEE8L1P;CRB7RTYN;YCCDD1CK;9G9NXAEH',
      statusContent: 'dot',
      hash: '2K1NY5E9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2K1NY5E9'
    },
    {
      status: 210,
      sku: 'PM0079153',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314942',
      rtl_batch_array: '6314942',
      name_search: 'VIAMUREN',
      name: 'Vitis amurensis',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 890,
      chnn_stock_retail: 890,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TE7TZN5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TE7TZN5C'
    },
    {
      status: 210,
      sku: 'PM0079154',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314943',
      rtl_batch_array: '6314943',
      name_search: 'VICOIGNE',
      name: 'Vitis coignetiae',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 713,
      chnn_stock_retail: 713,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DE8PRWSA;D5V37SH6',
      statusContent: 'dot',
      hash: 'GGTB27Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGTB27Y3'
    },
    {
      status: 210,
      sku: 'PM0079155',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314944',
      rtl_batch_array: '6314944',
      name_search: 'VIVPURPU',
      name: "Vitis vinifera 'Purpurea'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 728,
      chnn_stock_retail: 728,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J5D3TEWK;BDK24VZA;V2YLLC5T;BWJSXKEB;PEP3YDB3;VV3HSB8N;LRW8T36K;AWB34TA4;PPBA7Z28;AS268B7X;4P49X8GW;GVHZKRSX;KPV3GW12;NZR5SJVE;8KDAYGZ6;8GA2E2RC;J7R286ER;H3AYG12R;53P6W64X',
      statusContent: 'dot',
      hash: 'AWLGT64A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWLGT64A'
    },
    {
      status: 210,
      sku: 'PM0079156',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314945',
      rtl_batch_array: '6314945',
      name_search: 'WIBOKAYA',
      name: "Wisteria brachybotrys 'Okayama'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 883,
      chnn_stock_retail: 883,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P6TRB5XN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6TRB5XN'
    },
    {
      status: 210,
      sku: 'PM0079157',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314946',
      rtl_batch_array: '6314946',
      name_search: 'WIBOKAYA',
      name: "Wisteria brachybotrys 'Okayama'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 676,
      chnn_stock_retail: 676,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6YP16VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6YP16VT'
    },
    {
      status: 210,
      sku: 'PM0079158',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314947',
      rtl_batch_array: '6314947',
      name_search: 'WIBSBENI',
      name: "Wisteria brachybotrys 'Showa-beni'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AAWL6Z4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAWL6Z4T'
    },
    {
      status: 210,
      sku: 'PM0079159',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314948',
      rtl_batch_array: '6314948',
      name_search: 'WIBSKAPI',
      name: "Wisteria brachybotrys 'Shiro Kapitan'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N39LHT16',
      statusContent: 'dot',
      hash: 'C883VJ8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C883VJ8B'
    },
    {
      status: 210,
      sku: 'PM0079160',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314949',
      rtl_batch_array: '6314949',
      name_search: 'WIBSKAPI',
      name: "Wisteria brachybotrys 'Shiro Kapitan'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 337,
      chnn_stock_retail: 337,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N39LHT16',
      statusContent: 'dot',
      hash: 'E8NEN5KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8NEN5KA'
    },
    {
      status: 210,
      sku: 'PM0079161',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314950',
      rtl_batch_array: '6314950',
      name_search: 'WIFDOMIN',
      name: "Wisteria floribunda 'Domino'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2475,
      chnn_stock_retail: 2475,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '43BWZGL4;DXTGZ8LY',
      statusContent: 'dot',
      hash: 'NBB8SDY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBB8SDY7'
    },
    {
      status: 210,
      sku: 'PM0079162',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314951',
      rtl_batch_array: '6314951',
      name_search: 'WIFERANT',
      name: "Wisteria floribunda 'Eranthema'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BKR6P6NR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKR6P6NR'
    },
    {
      status: 810,
      sku: 'PM0078843',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6312687',
      rtl_batch_array: '6312687',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 8.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'ACX94BXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACX94BXL'
    },
    {
      status: 210,
      sku: 'PM0079114',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314884',
      rtl_batch_array: '6314884',
      name_search: 'CLVITALB',
      name: 'Clematis vitalba',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9KT7YDEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KT7YDEL'
    },
    {
      status: 210,
      sku: 'PM0069715',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6231154',
      rtl_batch_array: '6243008, 6231154, 6353186',
      name_search: 'ROOCAPRI',
      name: "Rosmarinus officinalis 'Capri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 1432,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5RH5N4E7;ANGHJ8XD;KTCCH9RL;4E46N3AL;E6CJ27H4',
      statusContent: 'dot',
      hash: 'B5XYC2E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5XYC2E4'
    },
    {
      status: 210,
      sku: 'PM0068286',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196859',
      rtl_batch_array: '6220890, 6196859',
      name_search: 'PONEUMAN',
      name: 'Potentilla neumanniana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 340,
      chnn_stock_retail: 476,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4HJ6R2WA;44ZHL5RX;7TRY2R8K;HDB569B3;23SVATVB',
      statusContent: 'dot',
      hash: 'BENSRHDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BENSRHDP'
    },
    {
      status: 210,
      sku: 'PM0059256',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184103',
      rtl_batch_array: '6184103',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_prcp: 8.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'C2PXG6NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2PXG6NJ'
    },
    {
      status: 210,
      sku: 'PM0068287',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196863',
      rtl_batch_array: '6300417, 6196863',
      name_search: 'ANMAWHIT',
      name: "Anemone multifida 'Annabella White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6452,
      chnn_stock_retail: 6689,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EJTBVLZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJTBVLZ8'
    },
    {
      status: 210,
      sku: 'PM0011721',
      core_name: 'Plant',
      sppl_ean: '8720664887282',
      btch_manufacturer: 268,
      rtl_batch_array_total: 6,
      btch_active_retail: '6162493',
      rtl_batch_array: '5404062, 6083727, 6252782, 6304910, 6162493, 6348869',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 11965,
      chnn_stock_retail: 20118,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'VV6B7Y6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VV6B7Y6P'
    },
    {
      status: 210,
      sku: 'PM0065393',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6290220',
      rtl_batch_array: '6350531, 6302613, 6290220',
      name_search: 'HOSSUBST',
      name: "Hosta 'Sum and Substance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 962,
      chnn_stock_retail: 2289,
      sppl_order_minimum: 3,
      sppl_prcp: 1.288,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6P2DW1W;87EP5TS3;WBBB64NA;V74RCP2Z;1KY3D9EH;H29WJL85;L426AKTW;PKSPZD3E;4LX2ZR4P;17DR38DP;4K5JV77S;4LN2XZRZ;1383ZN9P;SCXTGBBL',
      statusContent: 'dot',
      hash: 'SEH1XZCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEH1XZCT'
    },
    {
      status: 210,
      sku: 'PM0085550',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385445',
      rtl_batch_array: '6385445',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1044,
      chnn_stock_retail: 1044,
      sppl_order_minimum: 3,
      sppl_prcp: 1.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'EVRN59AV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVRN59AV'
    },
    {
      status: 210,
      sku: 'PM0017974',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196755',
      rtl_batch_array: '5364012, 6196755',
      name_search: 'DIDBRILL',
      name: "Dianthus deltoides 'Brilliant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5745,
      chnn_stock_retail: 6070,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LZSHZ8N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZSHZ8N7'
    },
    {
      status: 910,
      sku: 'PM0067007',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'UVPERFOL',
      name: 'Uvularia perfoliata',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'JNKBTHTD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JNKBTHTD'
    },
    {
      status: 210,
      sku: 'PM0067008',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353536',
      rtl_batch_array: '6353536',
      name_search: 'VECQUEEN',
      name: "Verbascum 'Cotswold Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1315,
      chnn_stock_retail: 1315,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CY1KAWPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CY1KAWPL'
    },
    {
      status: 210,
      sku: 'PM0066985',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353269',
      rtl_batch_array: '6353269',
      name_search: 'RHHFCERI',
      name: "Rhodoxis 'Hebron Farm Cerise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RS7CTPW8;HGAC1233;DTDEN9W1;RWP6SV96;EEW3634S;VYLGV6RK;64LGGZ5G;D1WWV12S',
      statusContent: 'dot',
      hash: 'ZJ8Y35WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ8Y35WA'
    },
    {
      status: 210,
      sku: 'PM0031974',
      core_name: 'Plant',
      sppl_ean: '8720664681699',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6008297',
      rtl_batch_array: '6298520, 6008297',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 408,
      chnn_stock_retail: 2291,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: '18BRK14C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18BRK14C'
    },
    {
      status: 210,
      sku: 'PM0067218',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244104',
      rtl_batch_array: '6244104',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'D266Y7HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D266Y7HH'
    },
    {
      status: 210,
      sku: 'PM0069735',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353355',
      rtl_batch_array: '6353355',
      name_search: 'SAOLIVAN',
      name: 'Saponaria olivana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'THEBN3VN;AS6VDGR1;HH1YGARK;JS538J2S;CK8WPJNA;L67KNHYD;97APD3CS;S4E43VTZ',
      statusContent: 'dot',
      hash: '5HAZVSA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HAZVSA2'
    },
    {
      status: 210,
      sku: 'PM0059273',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184007',
      rtl_batch_array: '6184007',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'D671LC2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D671LC2V'
    },
    {
      status: 210,
      sku: 'PM0069744',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295849',
      rtl_batch_array: '6295849',
      name_search: 'SCCSTAEF',
      name: "Scabiosa caucasica 'St\u00e4fa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 159,
      chnn_stock_retail: 159,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1G278JX2',
      statusContent: 'dot',
      hash: 'Y1ZXWKRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1ZXWKRK'
    },
    {
      status: 210,
      sku: 'PM0085551',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385447',
      rtl_batch_array: '6385447',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_prcp: 10.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'R6HP9YA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6HP9YA8'
    },
    {
      status: 210,
      sku: 'PM0079163',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314952',
      rtl_batch_array: '6314952',
      name_search: 'WIFHONBE',
      name: "Wisteria floribunda 'Honbeni'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 338,
      chnn_stock_retail: 338,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9LWA9WS5;7PDDNJBL;N7KYD7HG;HYETNWDX;CBT1DSNV;WP5ASC5A;7S1R1TWK;CKR96TVD;52BP15ZB;BG8B7LS2;DWN5TX2L;CVSBSSRG;G8RNEXCV;RPWPZKKH;P3K49EXW;KGXN5G3C;91W22CE7',
      statusContent: 'dot',
      hash: 'EXZ7XNVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXZ7XNVR'
    },
    {
      status: 210,
      sku: 'PM0079194',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314986',
      rtl_batch_array: '6314986',
      name_search: 'RUFTHORN',
      name: "Rubus fruticosus 'Thornfree'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 303,
      chnn_stock_retail: 303,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZSRJ9NGH;T63KNDAR',
      statusContent: 'dot',
      hash: '51NN1X8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51NN1X8K'
    },
    {
      status: 210,
      sku: 'PM0007951',
      core_name: 'Plant',
      sppl_ean: '8720664690530',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034268',
      rtl_batch_array: '6034268',
      name_search: 'EDI75118',
      name: "Galanthus plicatus (dou) 'Dionysus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7E496EKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E496EKL'
    },
    {
      status: 910,
      sku: 'PM0065789',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DICPEMAN',
      name: "Dianthus caryophyllus 'Peman'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '4JCVSB8S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4JCVSB8S'
    },
    {
      status: 210,
      sku: 'PM0079164',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314954',
      rtl_batch_array: '6314954',
      name_search: 'WIFINAGA',
      name: "Wisteria floribunda 'Issai-naga'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2465,
      chnn_stock_retail: 2465,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V443H4J',
      statusContent: 'dot',
      hash: 'ZZBPE5B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZBPE5B7'
    },
    {
      status: 210,
      sku: 'PM0079165',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314955',
      rtl_batch_array: '6314955',
      name_search: 'WIFLAWRE',
      name: "Wisteria floribunda 'Lawrence'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1939,
      chnn_stock_retail: 1939,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PWZBAVBH',
      statusContent: 'dot',
      hash: 'J3LC63YH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3LC63YH'
    },
    {
      status: 210,
      sku: 'PM0079166',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314957',
      rtl_batch_array: '6314957',
      name_search: 'WIFSNODA',
      name: "Wisteria floribunda 'Shiro-noda'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2386,
      chnn_stock_retail: 2386,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XA4NV6AG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA4NV6AG'
    },
    {
      status: 210,
      sku: 'PM0079167',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314958',
      rtl_batch_array: '6314958',
      name_search: 'WIFVPLEN',
      name: "Wisteria floribunda 'Violacea Plena'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1050,
      chnn_stock_retail: 1050,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D23SPJEV;DBZ8YZV1;PC667NB5;9T4LVXTY;VSB6GTTB;TNYY88Y6;SX8TLS6H;V65KBNET;P6NJ49SB;CEG5GS1A;2A4ZBCKN;AC2CVYPJ;8JAJLTAD',
      statusContent: 'dot',
      hash: 'CPH6PLH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPH6PLH5'
    },
    {
      status: 210,
      sku: 'PM0079168',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314959',
      rtl_batch_array: '6314959',
      name_search: 'WIFVPLEN',
      name: "Wisteria floribunda 'Violacea Plena'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 14.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D23SPJEV;DBZ8YZV1;PC667NB5;9T4LVXTY;VSB6GTTB;TNYY88Y6;SX8TLS6H;V65KBNET;P6NJ49SB;CEG5GS1A;2A4ZBCKN;AC2CVYPJ;8JAJLTAD',
      statusContent: 'dot',
      hash: 'YCK4K2SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCK4K2SG'
    },
    {
      status: 910,
      sku: 'PM0067046',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGHKERN',
      name: "Magnolia 'George Henry Kern'",
      rng_range_identifier: 'H080100C7.5',
      imageCore: 'TDX3ZPEN;PDB2YP8N;E9GRPBCC',
      statusContent: 'dot',
      hash: 'HAYZ8JD4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HAYZ8JD4'
    },
    {
      status: 210,
      sku: 'PM0079169',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314960',
      rtl_batch_array: '6314960',
      name_search: 'WIMBMOON',
      name: "Wisteria macrostachya 'Blue Moon'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1137,
      chnn_stock_retail: 1137,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZW3GWAHJ',
      statusContent: 'dot',
      hash: 'BA8Y314H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA8Y314H'
    },
    {
      status: 210,
      sku: 'PM0034061',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254665',
      rtl_batch_array: '6254665',
      name_search: 'BERCORDI',
      name: 'Bergenia cordifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BCHCA61D;86AW2X93;R3NLXX6Z;8EZWEKKH;EYBADXT8;G6N3LKTD',
      statusContent: 'dot',
      hash: 'V4KSW6P7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4KSW6P7'
    },
    {
      status: 910,
      sku: 'PM0067026',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345486',
      rtl_batch_array: '6345486',
      name_search: 'HYMTOGET',
      name: 'Hydrangea macrophylla Together',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 5.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G13WVALC;GWZ8LVRH;N7W2L3CZ;WZWA71BP',
      statusContent: 'dot',
      hash: 'AZ9A5XYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ9A5XYK'
    },
    {
      status: 210,
      sku: 'PM0079170',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314961',
      rtl_batch_array: '6314961',
      name_search: 'WISALBA',
      name: "Wisteria sinensis 'Alba'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 911,
      chnn_stock_retail: 911,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5C98R9CR;1XASNEWH;AZ21HDHY;TCX6T96W;TG5VBKPH;J6KXJSER;AJ6JGEJE;A6J9ETBZ;PLC4N6SS;GRLXX496',
      statusContent: 'dot',
      hash: '86RC4DL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86RC4DL2'
    },
    {
      status: 210,
      sku: 'PM0079171',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314962',
      rtl_batch_array: '6314962',
      name_search: 'WISCAROL',
      name: "Wisteria sinensis 'Caroline'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1005,
      chnn_stock_retail: 1005,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A39CDBLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A39CDBLG'
    },
    {
      status: 210,
      sku: 'PM0067097',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307303',
      rtl_batch_array: '6307303',
      name_search: 'CLASNOWD',
      name: "Clematis armandii 'Snowdrift'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 6.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZNE11WG3;5B31DTYY;X2W3573T;VGLB2C7D;RY7LN3JK;BKPGX95G;CYWZJ4P3;GWKD4LLP;GE7J3XPY;6RJPLJ6P;L1YDD9H8;RR485X6V;CWKZ29GY;SA2T1KCC',
      statusContent: 'dot',
      hash: '79Y771LZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79Y771LZ'
    },
    {
      status: 210,
      sku: 'PM0079172',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314963',
      rtl_batch_array: '6314963',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4439,
      chnn_stock_retail: 4439,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: 'NW4W6CK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW4W6CK8'
    },
    {
      status: 210,
      sku: 'PM0079173',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314965',
      rtl_batch_array: '6314965',
      name_search: 'ACAANANA',
      name: "Actinidia arguta 'Ananasnaya'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 992,
      chnn_stock_retail: 992,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YS8HVKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YS8HVKZ'
    },
    {
      status: 210,
      sku: 'PM0079174',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314966',
      rtl_batch_array: '6314966',
      name_search: 'ACABAYER',
      name: "Actinidia arguta 'Bayern'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 825,
      chnn_stock_retail: 825,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VY6S9GNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY6S9GNY'
    },
    {
      status: 210,
      sku: 'PM0079175',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314967',
      rtl_batch_array: '6314967',
      name_search: 'ACAGENEV',
      name: "Actinidia arguta 'Geneva'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1133,
      chnn_stock_retail: 1133,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HAX7BRAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAX7BRAD'
    },
    {
      status: 210,
      sku: 'PM0059276',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184012',
      rtl_batch_array: '6184012',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'AZHG6HYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZHG6HYP'
    },
    {
      status: 210,
      sku: 'PM0067034',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307517',
      rtl_batch_array: '6307517',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'HACXYCRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HACXYCRH'
    },
    {
      status: 210,
      sku: 'PM0079177',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314969',
      rtl_batch_array: '6314969',
      name_search: 'ACAWEIKI',
      name: "Actinidia arguta 'Weiki'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1064,
      chnn_stock_retail: 1064,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HV9JCKJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HV9JCKJL'
    },
    {
      status: 210,
      sku: 'PM0079178',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314970',
      rtl_batch_array: '6314970',
      name_search: 'ACAJUMBO',
      name: "Actinidia arguta 'Jumbo'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1060,
      chnn_stock_retail: 1060,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZ6P2381',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ6P2381'
    },
    {
      status: 210,
      sku: 'PM0079179',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314971',
      rtl_batch_array: '6314971',
      name_search: 'ACAKRED',
      name: "Actinidia arguta 'Ken's Red'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 641,
      chnn_stock_retail: 641,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PX4DB7EV;GKZBV367;W7JTDVL3',
      statusContent: 'dot',
      hash: 'S8VXLT3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8VXLT3S'
    },
    {
      status: 210,
      sku: 'PM0079180',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314972',
      rtl_batch_array: '6314972',
      name_search: 'ACCMINKI',
      name: "Actinidia chinensis 'Minkigold'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HPRSCYB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPRSCYB8'
    },
    {
      status: 210,
      sku: 'PM0067105',
      core_name: 'Plant',
      sppl_ean: '8720353014128',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420606',
      rtl_batch_array: '5420606',
      name_search: 'CLFREDA',
      name: "Clematis 'Freda'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1346,
      chnn_stock_retail: 1346,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L3PDZ778;X1CL52AX;GKYK2VEZ;1CKBNZNC;A1ZVY34Z;6RX5Z1B2;6C6Z51WH;C7ZDK1T5;K3E9W1EH;AGZE2YTP;K46WSVSX;KYAG3D5Z;9CP5RWB3;28HYV6DG;1RN8DGPG;APNTK1KA;Y8RB6DEP;669PWKJ3;5HCWPWSC;XEBZ1NT4;W1EB8NWS;ZGTNYDL7',
      statusContent: 'dot',
      hash: 'WS7BTXZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WS7BTXZ8'
    },
    {
      status: 210,
      sku: 'PM0079181',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314973',
      rtl_batch_array: '6314973',
      name_search: 'ACDATLAS',
      name: "Actinidia deliciosa 'Atlas'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1276,
      chnn_stock_retail: 1276,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXNT4XDA;6EJJ4B52',
      statusContent: 'dot',
      hash: 'PP93D6Z3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP93D6Z3'
    },
    {
      status: 210,
      sku: 'PM0079182',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314974',
      rtl_batch_array: '6314974',
      name_search: 'ACDBOSKO',
      name: "Actinidia deliciosa 'Boskoop'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 765,
      chnn_stock_retail: 765,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S993PYZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S993PYZG'
    },
    {
      status: 210,
      sku: 'PM0079183',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345458',
      rtl_batch_array: '6345458',
      name_search: 'ACDHAYWA',
      name: "Actinidia deliciosa 'Hayward'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TLC6A61E',
      statusContent: 'dot',
      hash: 'D4YRX775',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4YRX775'
    },
    {
      status: 810,
      sku: 'PM0068290',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349654',
      rtl_batch_array: '6349654',
      name_search: 'TRFORTUN',
      name: 'Trachycarpus fortunei',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 7.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X74TZTVW;HNW81PGZ;TR1RRV3C;Y2STVG6V;XLPR87S9;6WJPY8RX;72YCZRV6;HAW8S38G;L4TW44ZG;5A55S1YR;74GYT1ZB;2X49JZ5B;PH6RHEYR;BSPXTXZA;49CE93ZP;VT2CBK24;YPHTD5K8;ZD9GDRJT;HRVSZC68;RGZPWRXK;RDZNV767;CXXZL4XL;BRSKWHWY',
      statusContent: 'dot',
      hash: '5X6E51L3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X6E51L3'
    },
    {
      status: 210,
      sku: 'PM0079184',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314976',
      rtl_batch_array: '6314976',
      name_search: 'ACDSOLIS',
      name: 'Actinidia deliciosa Solissimo',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 969,
      chnn_stock_retail: 969,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2SSWEHCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SSWEHCN'
    },
    {
      status: 210,
      sku: 'PM0079185',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345459',
      rtl_batch_array: '6345459',
      name_search: 'ACDSOLO',
      name: "Actinidia deliciosa 'Solo'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 978,
      chnn_stock_retail: 978,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6L9HL9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6L9HL9G'
    },
    {
      status: 210,
      sku: 'PM0067107',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314880',
      rtl_batch_array: '6314880',
      name_search: 'CLMAYLEE',
      name: "Clematis 'Mayleen'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 685,
      chnn_stock_retail: 685,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '49BSDVK6;JBZH1GCG;4HS1SL2Z;ZC47WV66;PCNXCJJ4;XTG8D194;9PS6X6S3;745RCDAH;9XX3PK1X;LHL95ZG4;YYD2BT52;44EYZZ61;KEC99YA6;YPDPT9Y7',
      statusContent: 'dot',
      hash: 'WVKD8B76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVKD8B76'
    },
    {
      status: 210,
      sku: 'PM0079186',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314978',
      rtl_batch_array: '6314978',
      name_search: 'RUFREUBE',
      name: "Rubus fruticosus 'Reuben'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1440,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PC83ECB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PC83ECB1'
    },
    {
      status: 210,
      sku: 'PM0079187',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314979',
      rtl_batch_array: '6314979',
      name_search: 'RUFBSATI',
      name: "Rubus fruticosus 'Black Satin'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7WJTCG9A;7ZZY21TS;SZ1LV5N6;PL8SXPD4;LJ9JS2SE;G49K7BRZ;E29TKSN8;HWL1XVK6',
      statusContent: 'dot',
      hash: 'ZLX7ZRGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLX7ZRGW'
    },
    {
      status: 210,
      sku: 'PM0079188',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314980',
      rtl_batch_array: '6314980',
      name_search: 'RUFCTHOR',
      name: "Rubus fruticosus 'Chester Thornless'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '53G1DD41;6NTNH1RN;88Y3BN5L;YB1EEK3A;RNKAYTJZ;ATT4CAYH;72776WXJ;VXD2V37S;PS3NCLKC',
      statusContent: 'dot',
      hash: '49JA54L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49JA54L6'
    },
    {
      status: 210,
      sku: 'PM0079189',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314981',
      rtl_batch_array: '6314981',
      name_search: 'RUFHIMAL',
      name: "Rubus fruticosus 'Himalaya'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z6B8EXAC',
      statusContent: 'dot',
      hash: 'DPXKEN3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPXKEN3G'
    },
    {
      status: 810,
      sku: 'PM0081960',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349186',
      rtl_batch_array: '6349186',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'T26WYCGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T26WYCGY'
    },
    {
      status: 210,
      sku: 'PM0079190',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314982',
      rtl_batch_array: '6314982',
      name_search: 'RUFJUMBO',
      name: "Rubus fruticosus 'Jumbo'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K13CN9T4;E3X88V8Z',
      statusContent: 'dot',
      hash: 'D68JJKXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D68JJKXJ'
    },
    {
      status: 210,
      sku: 'PM0079191',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345524',
      rtl_batch_array: '6345524',
      name_search: 'RUFLNESS',
      name: "Rubus fruticosus 'Loch Ness'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GT2S4HJ6;ZH78XTCX;LZDXZ7GS;HN4WPNT9',
      statusContent: 'dot',
      hash: 'Z96LAD93',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z96LAD93'
    },
    {
      status: 210,
      sku: 'PM0079192',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314984',
      rtl_batch_array: '6314984',
      name_search: 'RUFMTHOR',
      name: "Rubus fruticosus 'Merton Thornless'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '98KXCWT2',
      statusContent: 'dot',
      hash: 'LT4WCH19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT4WCH19'
    },
    {
      status: 210,
      sku: 'PM0079193',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314985',
      rtl_batch_array: '6314985',
      name_search: 'RUFNAVAH',
      name: "Rubus fruticosus 'Navaho'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4EL75Y7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EL75Y7Z'
    },
    {
      status: 810,
      sku: 'PM0079195',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314987',
      rtl_batch_array: '6314987',
      name_search: 'RUFTEVER',
      name: "Rubus fruticosus 'Thornless Evergreen'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 53,
      chnn_stock_retail: 53,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GDVL76YR;JYNSTL1S;JH6JBD75;CP52JXB9;6GW2GWA4;LKA2YTKC;X34P75LV',
      statusContent: 'dot',
      hash: '1WA27GSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WA27GSS'
    },
    {
      status: 210,
      sku: 'PM0079196',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314988',
      rtl_batch_array: '6314988',
      name_search: 'RUFTCROW',
      name: "Rubus fruticosus 'Triple Crown'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G5EJGWLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5EJGWLA'
    },
    {
      status: 810,
      sku: 'PM0079197',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314989',
      rtl_batch_array: '6314989',
      name_search: 'RUIFALLG',
      name: "Rubus idaeus 'Fallgold'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BY6DCCVW;G8J6J7LT',
      statusContent: 'dot',
      hash: '5ZLN1VE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZLN1VE8'
    },
    {
      status: 210,
      sku: 'PM0079198',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314990',
      rtl_batch_array: '6314990',
      name_search: 'RUIHERIT',
      name: "Rubus idaeus 'H\u00e9ritage'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JBSG2NX4;Z7BCHAJD;6LB4VLLL;16JRX11D;JKZGZWKE;DC983CYP;A15DAZ3C;CY1XHS6D;Z19YL8TV;J5A7B646;EEWTXCY3;P8JJ3HG2',
      statusContent: 'dot',
      hash: 'GHPZELH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHPZELH9'
    },
    {
      status: 810,
      sku: 'PM0081961',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349187',
      rtl_batch_array: '6349187',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'PNY1EEBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNY1EEBE'
    },
    {
      status: 210,
      sku: 'PM0079199',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314991',
      rtl_batch_array: '6314991',
      name_search: 'RUIZEVA',
      name: "Rubus idaeus 'Zeva'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7BNRBPHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BNRBPHK'
    },
    {
      status: 810,
      sku: 'PM0067035',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307519',
      rtl_batch_array: '6307519',
      name_search: 'LIOVARIE',
      name: "Ligustrum ovalifolium 'Variegatum'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TNDZ18NR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNDZ18NR'
    },
    {
      status: 210,
      sku: 'PM0079200',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346756',
      rtl_batch_array: '6346756',
      name_search: 'RUBLLOGA',
      name: 'Rubus loganobaccus Loganberry',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8TGWK2SY',
      statusContent: 'dot',
      hash: '4XA8HR1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XA8HR1S'
    },
    {
      status: 810,
      sku: 'PM0079201',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314993',
      rtl_batch_array: '6314993',
      name_search: 'RUPHOENI',
      name: 'Rubus phoenicolasius',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2P231P7N;DTTBSSCE;E23ZC7CK;VCJBAN7X;49YW3JGG;R83VYYRN;C5158JEG;DE7XZYRE;GA3NZT1H;PB22HCVC;2D2TG2ND;KVD43S6X;V7LVC19X;29AZ1JLJ;VNCVBEGT;D3R8P1DP;RXLGBPH1;D8WV9LWP;77HBPB6K',
      statusContent: 'dot',
      hash: 'XXT8WJKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXT8WJKW'
    },
    {
      status: 910,
      sku: 'PM0079202',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUTAYBER',
      name: "Rubus 'Tayberry'",
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore:
        'CHGWC4XH;LLZGNB83;VTE7K7AG;XG4SVPDK;7XXYBJ3E;W4G8GVZ2;C1C74ZLL;2Z1PXS2V;ZVED92CZ;8HTE5JA2;S3NC288X;N66H5P1V;7J82YAJY;18458AHS',
      statusContent: 'dot',
      hash: '282N857N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '282N857N'
    },
    {
      status: 210,
      sku: 'PM0065800',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307447',
      rtl_batch_array: '6307447',
      name_search: 'FATJAPON',
      name: 'Fatsia japonica',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 7.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RSC48GZ;R5SVETV2;49WXNLEH;NAPTC6TV;BR2DPDZE;H4CHEYKL;GEPH7HV3;8PSCVAB6;CH5TD35H;RDK4EXYN;6TX92Y4T;1NB9EV7C;YWTA9LTT;H96S38ZG;2DPPH8G6;52AYEX2C;AJ6CK65G;SDSSNPWV;7Z6RYRPY;JJ7K336S;42JXK8ZT;RVR78BZ6;E45K1DJ9;72RDDVZ6',
      statusContent: 'dot',
      hash: 'L1P41WW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1P41WW9'
    },
    {
      status: 210,
      sku: 'PM0079203',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314995',
      rtl_batch_array: '6314995',
      name_search: 'VACBBLUE',
      name: "Vaccinium corymbosum 'Brigitta Blue'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4310,
      chnn_stock_retail: 4310,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYA1AW9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYA1AW9R'
    },
    {
      status: 210,
      sku: 'PM0031978',
      core_name: 'Plant',
      sppl_ean: '8720664683716',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4729660',
      rtl_batch_array: '4729660',
      name_search: 'CAOEVERC',
      name: "Carex oshimensis 'Evercream'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHYDAYZ7;824ALR5C;78B491P4;A5YVTLGC;EZKX9J3L;27NZ3ZA6;1TGH2DYP;SNHVK6BT;G2EP7D6V;4BX27KC6;D9ZY5CKC;RAC6N5RC;1GL5T997;D5TAD56B;2671EJER;TWZ523JN;X8DADD9B;6VTKNK1V;Z8VASE2C;LE7P74BX;BHWZTAYB;BS381A96;81H14WR8;6A114P81;5YY6EBZD',
      imageBatch: 'PYS6C1K4',
      statusContent: 'dot',
      hash: 'SGPZ6NR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGPZ6NR6'
    },
    {
      status: 810,
      sku: 'PM0081962',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349189',
      rtl_batch_array: '6349189, 6287305',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 47,
      chnn_stock_retail: 53,
      sppl_prcp: 60.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'VCWNDWKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCWNDWKL'
    },
    {
      status: 810,
      sku: 'PM0085552',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385453',
      rtl_batch_array: '6385453',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: '1XC2A6Y1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XC2A6Y1'
    },
    {
      status: 210,
      sku: 'PM0079204',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314996',
      rtl_batch_array: '6314996',
      name_search: 'VACCBLUE',
      name: "Vaccinium corymbosum 'Bluecrop'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2471,
      chnn_stock_retail: 2471,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2XNBEBWB;B7722WXJ;6H776WK8;BT18YHKT;B6LSDLDW;X2KGEJ55;6L8JWB9N;P8CD2N7Y;TJYH8Z7X;74PK3H97;D8JH3L2A',
      statusContent: 'dot',
      hash: '22GVZV8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22GVZV8P'
    },
    {
      status: 210,
      sku: 'PM0079205',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314997',
      rtl_batch_array: '6314997',
      name_search: 'VACBLUEG',
      name: "Vaccinium corymbosum 'Bluegold'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1698,
      chnn_stock_retail: 1698,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6TH5ZSNH',
      statusContent: 'dot',
      hash: '2GBVEB2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GBVEB2Z'
    },
    {
      status: 210,
      sku: 'PM0079176',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314968',
      rtl_batch_array: '6314968',
      name_search: 'ACAISSAI',
      name: "Actinidia arguta 'Issai'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 830,
      chnn_stock_retail: 830,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC3129JH;9G2Y6K9A;WVSP5PPA',
      statusContent: 'dot',
      hash: 'W68Z1X51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W68Z1X51'
    },
    {
      status: 210,
      sku: 'PM0079206',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314998',
      rtl_batch_array: '6314998',
      name_search: 'VACCHAND',
      name: "Vaccinium corymbosum 'Chandler'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2138,
      chnn_stock_retail: 2138,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YHTHGCKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHTHGCKR'
    },
    {
      status: 210,
      sku: 'PM0079207',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314999',
      rtl_batch_array: '6314999',
      name_search: 'VAMEBLAC',
      name: "Vaccinium macrocarpon 'Early Black'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1052,
      chnn_stock_retail: 1052,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DV25KVHC',
      statusContent: 'dot',
      hash: 'WDHLA348',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDHLA348'
    },
    {
      status: 910,
      sku: 'PM0079208',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGASTAR',
      name: "Agapanthus 'Arctic Star'",
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      imageCore:
        'XVH6YSA7;VLD6EZ5W;JB56N1YH;DRHB261Y;LT6EVGA5;8RTDT4HB;Z4B3WCCB;WWJ2H6DC;ZV5XRNSW;7GND9DPW;SCL9CLTS;JKPALRZ3;8RB2YN3A;JARZL211;VPPL89VW;NCVCTEP7;8VAB9VL2',
      statusContent: 'dot',
      hash: 'L5T1AC7B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L5T1AC7B'
    },
    {
      status: 210,
      sku: 'PM0085553',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385454',
      rtl_batch_array: '6385454',
      name_search: 'NADOMEST',
      name: 'Nandina domestica',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 13.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VD8H147C;BLZ2GW1V;CL276JTD;N2EPWKKW;J2S57PCB;47P9JE8W;HE8J2D6E;LCZ9E9SR;VJ7T2E41;XA2GVAD8;XGCGLADH;J2PXJC5V;E81ESH4G;S9BJX1AE;86GSB4H1',
      statusContent: 'dot',
      hash: 'JC3A1P5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JC3A1P5P'
    },
    {
      status: 910,
      sku: 'PM0079209',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGOBLUE',
      name: "Agapanthus 'Ocean Blue'",
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      statusContent: 'dot',
      hash: 'ERAJL2WE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ERAJL2WE'
    },
    {
      status: 210,
      sku: 'PM0079210',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315002',
      rtl_batch_array: '6315002',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_prcp: 9.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'HNL1T9ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNL1T9ZJ'
    },
    {
      status: 810,
      sku: 'PM0079211',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315003',
      rtl_batch_array: '6315003',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_prcp: 10.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'LNCCVN9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNCCVN9D'
    },
    {
      status: 210,
      sku: 'PM0079212',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315004',
      rtl_batch_array: '6315004',
      name_search: 'FASAWOND',
      name: "Fargesia scabrida 'Asian Wonder'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 1890,
      chnn_stock_retail: 1890,
      sppl_prcp: 9.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2Y81GDV;6GHXCB27;Y1AWLJDK;Y973Z946;K9CESGSD;WDETGSX4;WNZL7SS5;CNTHHX7Z;G6X3DALL;KBW7E83A;P63VE7R3',
      statusContent: 'dot',
      hash: 'B5KJGXK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5KJGXK4'
    },
    {
      status: 210,
      sku: 'PM0079213',
      core_name: 'Plant',
      sppl_ean: '8720626361362',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488229',
      rtl_batch_array: '5488229',
      name_search: 'PEMRBTAI',
      name: 'Pennisetum massaicum Red Bunny Tails',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LKDN6XX5;CEC8AJ8W',
      imageBatch: 'P6N3HVGJ',
      statusContent: 'dot',
      hash: 'ZVVJ7K7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVVJ7K7D'
    },
    {
      status: 210,
      sku: 'PM0079214',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315006',
      rtl_batch_array: '6315006',
      name_search: 'JAOFFICI',
      name: 'Jasminum officinale',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 3799,
      chnn_stock_retail: 3799,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCHN4RN1;H5LBGN69;TXTCZZXG;WA2L7Z4C;WRJ6CX3E;6HZEXZSW;6GBCX9YX',
      statusContent: 'dot',
      hash: 'LJBHH84X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJBHH84X'
    },
    {
      status: 810,
      sku: 'PM0065794',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307450',
      rtl_batch_array: '6307450',
      name_search: 'FAJSWEB',
      name: "Fatsia japonica 'Spider's Web'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 11.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3B94AJ2;NKD557B2;X4KAN56K;Y3RNSPWK;8TED5GNZ;Y68T7YTZ;HWNG8EZT;EW9BY6TD;XC4DAZXW;CPZBWXY9;ZDVS9LBL;GWTWBRV2;NAB5S1ZA;VP8A4ZS6;JK7VTB2C;C4B6PJLY;WX9SSXS3',
      statusContent: 'dot',
      hash: 'BDAJEAWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDAJEAWS'
    },
    {
      status: 210,
      sku: 'PM0079215',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315007',
      rtl_batch_array: '6315007',
      name_search: 'JAPOLYAN',
      name: 'Jasminum polyanthum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 795,
      chnn_stock_retail: 795,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN8CREPH;NE6HAJ32;8XX76RA7;8L25CCVP;AYT7L9R2;4R4GED9G;PZBCH711;Z5H4Z3C3;TLC2AJ7C;RDEJS6VA;XAHAJ4WS;L149R6WK;TRZDRYZ3;HDBZH4WW;X64SDRR9',
      statusContent: 'dot',
      hash: 'H9W9YH2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9W9YH2Y'
    },
    {
      status: 210,
      sku: 'PM0079216',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315008',
      rtl_batch_array: '6315008',
      name_search: 'JASTEPHA',
      name: 'Jasminum stephanense',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1277,
      chnn_stock_retail: 1277,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CKXXVV77;JJBAC8HK;V86KBNT5',
      statusContent: 'dot',
      hash: '7LESV41L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LESV41L'
    },
    {
      status: 210,
      sku: 'PM0079224',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339778',
      rtl_batch_array: '6315047, 6339778',
      name_search: 'CASSCEPT',
      name: "Carex 'Silver Sceptre'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1074,
      chnn_stock_retail: 2024,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H2YCHBRG',
      statusContent: 'dot',
      hash: 'W6JPED2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6JPED2E'
    },
    {
      status: 210,
      sku: 'PM0068292',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197016',
      rtl_batch_array: '6197016, 6252528',
      name_search: 'DECGOLDS',
      name: "Deschampsia cespitosa 'Goldschleier'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1667,
      chnn_stock_retail: 2560,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'REYNCVGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REYNCVGY'
    },
    {
      status: 810,
      sku: 'PM0081963',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349190',
      rtl_batch_array: '6349190',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.985,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'DAL6P847',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAL6P847'
    },
    {
      status: 810,
      sku: 'PM0081964',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349191',
      rtl_batch_array: '6349191, 6287309',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 65,
      sppl_prcp: 59.835,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'K7CYYLER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7CYYLER'
    },
    {
      status: 810,
      sku: 'PM0081965',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349201',
      rtl_batch_array: '6349201',
      name_search: 'FOMAJOR',
      name: 'Fothergilla major',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N46NLV3Z;77E6K2RB;4X4CGH67;5G4EARD4;C42ZL9BE;PCB852GY;K5BZZVD1',
      statusContent: 'dot',
      hash: 'YRZKY5KX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRZKY5KX'
    },
    {
      status: 810,
      sku: 'PM0081966',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349202',
      rtl_batch_array: '6349202',
      name_search: 'FRAFLINE',
      name: 'Frangula alnus Fine Line',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.537,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7XXKVBDG',
      statusContent: 'dot',
      hash: 'DTWSWXXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTWSWXXG'
    },
    {
      status: 210,
      sku: 'PM0079217',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315009',
      rtl_batch_array: '6315009',
      name_search: 'HULNORDB',
      name: "Humulus lupulus 'Nordbrau'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2442,
      chnn_stock_retail: 2442,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1H34NZ3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H34NZ3D'
    },
    {
      status: 910,
      sku: 'PM0067049',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MASIEBOL',
      name: 'Magnolia sieboldii',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      imageCore:
        '992PB4CA;HSSZ9GWK;K1VDH3VC;68D7TER8;J9KH1PXR;4P2XTTL4;V57LCPKP;6TDKNEHR',
      statusContent: 'dot',
      hash: 'GNJJW55W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GNJJW55W'
    },
    {
      status: 210,
      sku: 'PM0079221',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315034',
      rtl_batch_array: '6315034',
      name_search: 'ACBUCHAN',
      name: 'Acaena buchananii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1126,
      chnn_stock_retail: 1126,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '49S152WC;4V62LVZH;8YBTAVR3',
      statusContent: 'dot',
      hash: 'LPKATP4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPKATP4C'
    },
    {
      status: 210,
      sku: 'PM0079222',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315036',
      rtl_batch_array: '6315036',
      name_search: 'ALSENESC',
      name: 'Allium senescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 872,
      chnn_stock_retail: 872,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EWR7H3V6;H9BZTTJ2;HEZ9VTYE',
      statusContent: 'dot',
      hash: 'EA351TPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA351TPB'
    },
    {
      status: 210,
      sku: 'PM0079223',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315045',
      rtl_batch_array: '6315045',
      name_search: 'CAOEGREE',
      name: "Carex oshimensis 'Evergreen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 926,
      chnn_stock_retail: 926,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JB56DL4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB56DL4Z'
    },
    {
      status: 210,
      sku: 'PM0079225',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348980',
      rtl_batch_array: '6315051, 6350242, 6348980',
      name_search: 'GESAPFEL',
      name: "Geranium sanguineum 'Apfelblute'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1777,
      chnn_stock_retail: 5384,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TBL9XSN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBL9XSN1'
    },
    {
      status: 210,
      sku: 'PM0079218',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315029',
      rtl_batch_array: '6315029',
      name_search: 'ACMAPFEL',
      name: "Achillea millefolium 'Apfelbl\u00fcte'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERYBY6TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERYBY6TL'
    },
    {
      status: 210,
      sku: 'PM0079219',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315030',
      rtl_batch_array: '6315030, 6334346',
      name_search: 'ASWPURPL',
      name: "Aster 'Wood's Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 545,
      chnn_stock_retail: 1247,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDA8VABL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDA8VABL'
    },
    {
      status: 210,
      sku: 'PM0071292',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6214318',
      rtl_batch_array: '6214318',
      name_search: 'PANYMPHE',
      name: "Paeonia (LE) 'Nymphe'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K68B7ZVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K68B7ZVX'
    },
    {
      status: 210,
      sku: 'PM0079220',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315033',
      rtl_batch_array: '6315033',
      name_search: 'PEAMPLEX',
      name: 'Persicaria amplexicaulis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1308,
      chnn_stock_retail: 1308,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KG2H81CK;V5P722P1;18NBHG9T;S36JV4GX;X1TV7BKY;C2DS4669;K12YL938;6DJDY2AS;5KDJJNBG;XSTEGW9V;X6XE3BWN;THXAB7DT;WJL4TWKW;HKDNXX3Y;GZVH5EWY;7DNDHWK1',
      statusContent: 'dot',
      hash: '62WX1HN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62WX1HN5'
    },
    {
      status: 810,
      sku: 'PM0081967',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349203',
      rtl_batch_array: '6349203',
      name_search: 'FRAFLINE',
      name: 'Frangula alnus Fine Line',
      sppl_size_code: '120140C10',
      rng_range_identifier: 'H120140C10',
      rng_range_description: 'H120 cm 140 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.012,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7XXKVBDG',
      statusContent: 'dot',
      hash: 'NDXHRN1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDXHRN1H'
    },
    {
      status: 810,
      sku: 'PM0065793',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307448',
      rtl_batch_array: '6307448',
      name_search: 'FATJAPON',
      name: 'Fatsia japonica',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 9.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RSC48GZ;R5SVETV2;49WXNLEH;NAPTC6TV;BR2DPDZE;H4CHEYKL;GEPH7HV3;8PSCVAB6;CH5TD35H;RDK4EXYN;6TX92Y4T;1NB9EV7C;YWTA9LTT;H96S38ZG;2DPPH8G6;52AYEX2C;AJ6CK65G;SDSSNPWV;7Z6RYRPY;JJ7K336S;42JXK8ZT;RVR78BZ6;E45K1DJ9;72RDDVZ6',
      statusContent: 'dot',
      hash: 'HS2SJXZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HS2SJXZX'
    },
    {
      status: 210,
      sku: 'PM0069727',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353329',
      rtl_batch_array: '6353329',
      name_search: 'SANRIANN',
      name: "Salvia nemorosa 'Rianne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWZEW8BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWZEW8BE'
    },
    {
      status: 210,
      sku: 'PM0067188',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244098',
      rtl_batch_array: '6244098, 6348826',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 994,
      chnn_stock_retail: 1894,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'WCDTHZZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCDTHZZ2'
    },
    {
      status: 210,
      sku: 'PM0069732',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353349',
      rtl_batch_array: '6302295, 6353349',
      name_search: 'SAWWISH',
      name: "Salvia 'Wendys Wish'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 354,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EL9W2PGV;PAGLPZZ4',
      statusContent: 'dot',
      hash: 'WASV867J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WASV867J'
    },
    {
      status: 210,
      sku: 'PM0034107',
      core_name: 'Plant',
      sppl_ean: '8720664680036',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054322',
      rtl_batch_array: '6054322',
      name_search: 'ALGLOBUS',
      name: "Allium 'Globus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 218,
      chnn_stock_retail: 218,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BZEY85A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BZEY85A'
    },
    {
      status: 210,
      sku: 'PM0068303',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197087',
      rtl_batch_array: '6197087',
      name_search: 'AGFLEUR',
      name: "Agastache 'Fleur'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1040,
      chnn_stock_retail: 1040,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YPP5SKE',
      statusContent: 'dot',
      hash: '1NJ3D2E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NJ3D2E5'
    },
    {
      status: 210,
      sku: 'PM0023958',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177571',
      rtl_batch_array: '6177571, 6234635, 5837404',
      name_search: 'CAMORROW',
      name: 'Carex morrowii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9076,
      chnn_stock_retail: 9578,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEY1DCSC',
      statusContent: 'dot',
      hash: '8VAPSX9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VAPSX9B'
    },
    {
      status: 910,
      sku: 'PM0067091',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307298',
      rtl_batch_array: '6307298',
      name_search: 'CHVIRGIN',
      name: 'Chionanthus virginicus',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 17.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7K2CV2D;8SLZH6JY;JYYA2V2E;ZY5JSVH8;P3LAVG2X;9W36NP98;N7TD4KX6;X9EKCA4X;NAJV1P77',
      statusContent: 'dot',
      hash: '267GGDXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '267GGDXB'
    },
    {
      status: 210,
      sku: 'PM0053028',
      core_name: 'Plant',
      sppl_ean: '8720664630147',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057470',
      rtl_batch_array: '6057470',
      name_search: 'SALANGEL',
      name: "Sanguisorba 'Little Angel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '66ED1VST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66ED1VST'
    },
    {
      status: 210,
      sku: 'PM0067095',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307301',
      rtl_batch_array: '6307301',
      name_search: 'CLARMAND',
      name: 'Clematis armandii',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 6.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A2HRDWJB;81D7Z7NT;SHH1R6CH;XVSDW131;7ZDCPZJC;XTLYZ1G9;4HHPASY8;LCRDTP5B;C4BLBTV3;T47TA769;7237NA5V;NRZ39N9Y;TZAK7BTG;Y1JD94C5',
      statusContent: 'dot',
      hash: 'D4L9SK6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4L9SK6T'
    },
    {
      status: 210,
      sku: 'PM0034062',
      core_name: 'Plant',
      sppl_ean: '8720626336568',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5871823',
      rtl_batch_array: '5871823, 6254666',
      name_search: 'BERCPURP',
      name: "Bergenia cordifolia 'Purpurea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4461,
      chnn_stock_retail: 5461,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AY1634E6;EG9EX7YC;P1SSAX16;L571TZJE;H7R41VXY;2XHDLGBN;YJA3XGAV;18G9BHJ9',
      statusContent: 'dot',
      hash: '6NHV7VH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NHV7VH5'
    },
    {
      status: 210,
      sku: 'PM0031977',
      core_name: 'Plant',
      sppl_ean: '8720664683549',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4729659',
      rtl_batch_array: '4729659',
      name_search: 'CABBLUE',
      name: "Carex 'Bunny Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'D3SSKYA8',
      statusContent: 'dot',
      hash: '7NEESBN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NEESBN5'
    },
    {
      status: 210,
      sku: 'PM0067092',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307299',
      rtl_batch_array: '6307299',
      name_search: 'POTRIFOL',
      name: 'Poncirus trifoliata',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_prcp: 7.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y3NGAW69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3NGAW69'
    },
    {
      status: 210,
      sku: 'PM0067217',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244103',
      rtl_batch_array: '6244103',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: 'DRJX7PGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRJX7PGE'
    },
    {
      status: 210,
      sku: 'PM0012349',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6284530',
      rtl_batch_array: '5905567, 6284530, 5837451, 5519733',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3335,
      chnn_stock_retail: 8950,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: 'JL1PLVKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL1PLVKE'
    },
    {
      status: 810,
      sku: 'PM0063903',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307514',
      rtl_batch_array: '6307514',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '080100C30',
      rng_range_identifier: 'H080100C30',
      rng_range_description: 'H80 cm 100 cm C30',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 32.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'LP3RAJ9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LP3RAJ9X'
    },
    {
      status: 210,
      sku: 'PM0069762',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353428',
      rtl_batch_array: '6353428',
      name_search: 'SECBORIS',
      name: 'Sempervivum ciliosum borisii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLGNREHA;T4C9DA4K;REX7XKJV;LCZ3B644',
      statusContent: 'dot',
      hash: 'S1Z35YGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1Z35YGB'
    },
    {
      status: 210,
      sku: 'PM0068299',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197040',
      rtl_batch_array: '6197040',
      name_search: 'SANPBEAU',
      name: "Salvia nemorosa 'Pink Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5PKB2A8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PKB2A8N'
    },
    {
      status: 810,
      sku: 'PM0069765',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356611',
      rtl_batch_array: '6353444, 6356611',
      name_search: 'SIEHEUGH',
      name: "Sidalcea 'Elsie Heugh'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 452,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2ZH736H6;7GC1LEVV;ZGKPK4K5;1GD1EY6X;8HSYZPK3;AX58X13G',
      statusContent: 'dot',
      hash: 'LHGGATBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHGGATBB'
    },
    {
      status: 210,
      sku: 'PM0032286',
      core_name: 'Plant',
      sppl_ean: '8720353024042',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054336',
      rtl_batch_array: '6054336',
      name_search: 'ALMJEANN',
      name: "Allium moly 'Jeannine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66JC7NSE;ZDRTR2LX;6R23KNKD;2DG3S9WR;XS48CH7N;67ZG45N9;85ESKEGW;SJESPJH4',
      statusContent: 'dot',
      hash: 'JHRWEJDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHRWEJDN'
    },
    {
      status: 810,
      sku: 'PM0067786',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217142',
      rtl_batch_array: '6217142',
      name_search: 'FIUPLENA',
      name: "Filipendula ulmaria 'Plena'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NV5WW82A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV5WW82A'
    },
    {
      status: 210,
      sku: 'PM0079230',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315093',
      rtl_batch_array: '6315093',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2356,
      chnn_stock_retail: 2356,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'NRL4SGVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRL4SGVX'
    },
    {
      status: 210,
      sku: 'PM0070177',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220683',
      rtl_batch_array: '6220683',
      name_search: 'CLEINTEG',
      name: 'Clematis integrifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2059,
      chnn_stock_retail: 2059,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LX1CH54E;EHN2552V',
      statusContent: 'dot',
      hash: '2KZ1EHA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KZ1EHA7'
    },
    {
      status: 210,
      sku: 'PM0053173',
      core_name: 'Plant',
      sppl_ean: '8720349452712',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6050807',
      rtl_batch_array: '6050807',
      name_search: 'RHCASABL',
      name: "Rhodanthemum 'Casablanca'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2796,
      chnn_stock_retail: 2796,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H776ANXB;G7E3TL3B;CNBTTWGN',
      statusContent: 'dot',
      hash: '823SYB16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '823SYB16'
    },
    {
      status: 210,
      sku: 'PM0079232',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315122',
      rtl_batch_array: '6315122',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1222,
      chnn_stock_retail: 1222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: 'EBPV8RVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBPV8RVV'
    },
    {
      status: 210,
      sku: 'PM0067172',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177584',
      rtl_batch_array: '6177584',
      name_search: 'LEBECKY',
      name: "Leucanthemum (S) 'Becky'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 629,
      chnn_stock_retail: 629,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L24E9LXZ;J41DEADB;86JTBDCN;TJG2VE5C;HGXXZR5Z;XH2D4794;6R9ZSDRH;9EJSXZ85;AEYD34RK;24S512L5;VLVXWJ5S;8WZELRL1;NYYYAGGX;JWYWBNXN',
      statusContent: 'dot',
      hash: 'DEEGHB37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEEGHB37'
    },
    {
      status: 210,
      sku: 'PM0079233',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315142',
      rtl_batch_array: '6315142',
      name_search: 'COCDECOR',
      name: 'Cotoneaster conspicuus decorus',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2395,
      chnn_stock_retail: 2395,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8XDD47V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XDD47V9'
    },
    {
      status: 210,
      sku: 'PM0068297',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197031',
      rtl_batch_array: '6197031',
      name_search: 'NERSNOWF',
      name: "Nepeta racemosa 'Snowflake'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLN4C3GT;P2ZL52LP',
      statusContent: 'dot',
      hash: '88XL6HD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88XL6HD1'
    },
    {
      status: 210,
      sku: 'PM0079234',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315143',
      rtl_batch_array: '6315143',
      name_search: 'CODGELRE',
      name: "Cotoneaster dammeri 'Gelre'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4088,
      chnn_stock_retail: 4088,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7RN9PV2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RN9PV2V'
    },
    {
      status: 210,
      sku: 'PM0067167',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177569',
      rtl_batch_array: '6266235, 6177569',
      name_search: 'ACTERRAC',
      name: "Achillea 'Terracotta'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1841,
      chnn_stock_retail: 2149,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P54ZSLA8;YV2SSJLS;1B26DHNY;V1HTCTJR;ZJHWAN7T;V1JKWRXK',
      statusContent: 'dot',
      hash: '1J1AB69Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1J1AB69Z'
    },
    {
      status: 210,
      sku: 'PM0079235',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315150',
      rtl_batch_array: '6315150',
      name_search: 'COLACTEU',
      name: 'Cotoneaster lacteus',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2685,
      chnn_stock_retail: 2685,
      sppl_order_minimum: 3,
      sppl_prcp: 0.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBLSAC4B;PR1173VV;DPHVETH3;7BA9YC8Y;SLHYXZLL;77D3WDW4',
      statusContent: 'dot',
      hash: 'VVS5ZRYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVS5ZRYW'
    },
    {
      status: 210,
      sku: 'PM0069767',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308440',
      rtl_batch_array: '6221370, 6301710, 6308440',
      name_search: 'LYSBLUSH',
      name: "Lythrum salicaria 'Blush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 1453,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTYRH63;2VE522WZ',
      statusContent: 'dot',
      hash: 'TSA9RAWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSA9RAWJ'
    },
    {
      status: 810,
      sku: 'PM0081968',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349213',
      rtl_batch_array: '6349213',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 42.297,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'ZAEWN68E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAEWN68E'
    },
    {
      status: 210,
      sku: 'PM0059344',
      core_name: 'Plant',
      sppl_ean: '8720664858435',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6093797',
      rtl_batch_array: '6093797',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'WG17L3PW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WG17L3PW'
    },
    {
      status: 210,
      sku: 'PM0079237',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315183',
      rtl_batch_array: '6315183',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2336,
      chnn_stock_retail: 2336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      statusContent: 'dot',
      hash: 'EXJ7JN1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXJ7JN1N'
    },
    {
      status: 210,
      sku: 'PM0067121',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420617',
      rtl_batch_array: '5420617',
      name_search: 'CLTTANGU',
      name: 'Clematis tibetana tangutica',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 964,
      chnn_stock_retail: 964,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V78AZ85R;C43CDZND;9D5CNZTY;YG4155K1;B2H6WENR',
      statusContent: 'dot',
      hash: '27D54WR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27D54WR3'
    },
    {
      status: 210,
      sku: 'PM0079239',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315201',
      rtl_batch_array: '6315201',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.385,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'C8NAK78N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8NAK78N'
    },
    {
      status: 810,
      sku: 'PM0067161',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284548',
      rtl_batch_array: '6284548',
      name_search: 'SANAMETH',
      name: "Salvia nemorosa 'Amethyst'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EJVYNGNK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJVYNGNK'
    },
    {
      status: 210,
      sku: 'PM0069748',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220919',
      rtl_batch_array: '6220919',
      name_search: 'SEFLORIF',
      name: 'Sedum floriferum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WC5E1RHE',
      statusContent: 'dot',
      hash: '7A7JG7JS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7A7JG7JS'
    },
    {
      status: 210,
      sku: 'PM0053169',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291793',
      rtl_batch_array: '5354806, 6291793',
      name_search: 'PHSTARFI',
      name: "Phlox (P) 'Starfire'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2079,
      chnn_stock_retail: 3079,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEKW2ST5',
      statusContent: 'dot',
      hash: 'LV3BKZ8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LV3BKZ8L'
    },
    {
      status: 210,
      sku: 'PM0079240',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315217',
      rtl_batch_array: '6315217',
      name_search: 'HYINODOR',
      name: 'Hypericum inodorum',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 9384,
      chnn_stock_retail: 9384,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YNYA773A;7986ABZH;613X2EYC',
      statusContent: 'dot',
      hash: 'DA5W1P59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA5W1P59'
    },
    {
      status: 210,
      sku: 'PM0079241',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315219',
      rtl_batch_array: '6315219',
      name_search: 'HYIANNEB',
      name: "Hypericum inodorum 'Annebel'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4495,
      chnn_stock_retail: 4495,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CYXNYX1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYXNYX1P'
    },
    {
      status: 210,
      sku: 'PM0068298',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197038',
      rtl_batch_array: '6197038, 6356982',
      name_search: 'SAAMISTA',
      name: "Salvia 'Amistad'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2437,
      chnn_stock_retail: 2587,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWW3GHSR;W4RV51L8;5Y76RSXV;TD1KVCPH;ZE662277;8Z22KA53',
      statusContent: 'dot',
      hash: 'KR1RHN9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KR1RHN9C'
    },
    {
      status: 910,
      sku: 'PM0070175',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAASIMPL',
      name: 'Carlina acaulis simplex',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'GNYRNHZ3',
      statusContent: 'dot',
      hash: 'PDSND5J3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PDSND5J3'
    },
    {
      status: 210,
      sku: 'PM0049980',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271406',
      rtl_batch_array: '6271406',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1969,
      chnn_stock_retail: 1969,
      sppl_prcp: 2.864,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'TS22KN9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TS22KN9A'
    },
    {
      status: 210,
      sku: 'PM0020015',
      core_name: 'Plant',
      sppl_ean: '8720664687660',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5358225',
      rtl_batch_array: '6279841, 5358225',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1172,
      chnn_stock_retail: 12177,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'BVC3GKAS',
      statusContent: 'dot',
      hash: 'X11S57H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X11S57H7'
    },
    {
      status: 210,
      sku: 'PM0067170',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177580',
      rtl_batch_array: '6177580',
      name_search: 'HERRUM',
      name: "Hemerocallis 'Red Rum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 857,
      chnn_stock_retail: 857,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61CLG6GH;ABXGKSJJ;PELGWZPB;PP6G85NE',
      statusContent: 'dot',
      hash: 'N57BZST7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N57BZST7'
    },
    {
      status: 210,
      sku: 'PM0079242',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315220',
      rtl_batch_array: '6315220',
      name_search: 'HYIABLAZ',
      name: "Hypericum inodorum 'Autumn Blaze'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3448,
      chnn_stock_retail: 3448,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J4SRV8RG',
      statusContent: 'dot',
      hash: 'P4V7PXK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4V7PXK7'
    },
    {
      status: 210,
      sku: 'PM0079243',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315222',
      rtl_batch_array: '6315222',
      name_search: 'HYIBEAUT',
      name: "Hypericum inodorum 'Beauty'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3750,
      chnn_stock_retail: 3750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9APT2T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9APT2T9'
    },
    {
      status: 910,
      sku: 'PM0067169',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEFRETUR',
      name: "Hemerocallis 'Fragrant Returns'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'YHDBCAPB',
      statusContent: 'dot',
      hash: '672R49D5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '672R49D5'
    },
    {
      status: 210,
      sku: 'PM0079244',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315223',
      rtl_batch_array: '6315223',
      name_search: 'HYIEFLAI',
      name: "Hypericum inodorum 'Excellent Flair'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CP9JRKCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP9JRKCV'
    },
    {
      status: 210,
      sku: 'PM0053170',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249950',
      rtl_batch_array: '6249950',
      name_search: 'PHSSFRAG',
      name: 'Phlox (P) Sweet Summer Fragrance',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6354,
      chnn_stock_retail: 6354,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5D6E9ZTH',
      statusContent: 'dot',
      hash: 'WPK2TX5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPK2TX5V'
    },
    {
      status: 210,
      sku: 'PM0079245',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315224',
      rtl_batch_array: '6315224',
      name_search: 'HYIMBEAU',
      name: 'Hypericum inodorum Magical Beauty',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7352,
      chnn_stock_retail: 7352,
      sppl_order_minimum: 3,
      sppl_prcp: 1.434,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PG5GEWJ4',
      statusContent: 'dot',
      hash: 'JG52BD89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JG52BD89'
    },
    {
      status: 210,
      sku: 'PM0079246',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315225',
      rtl_batch_array: '6315225',
      name_search: 'HYIMBLAC',
      name: 'Hypericum inodorum Magical Black',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7995,
      chnn_stock_retail: 7995,
      sppl_order_minimum: 3,
      sppl_prcp: 1.434,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y5XSEGJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5XSEGJE'
    },
    {
      status: 210,
      sku: 'PM0079258',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302416',
      rtl_batch_array: '6302416',
      name_search: 'STAOFFIC',
      name: 'Stachys officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1177,
      chnn_stock_retail: 1177,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V28ZNVGZ;SYB55H2T;YJG1TGKK;2JHZK3EW;CCVK2KLP',
      statusContent: 'dot',
      hash: 'GDB2LVNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDB2LVNZ'
    },
    {
      status: 210,
      sku: 'PM0079248',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315229',
      rtl_batch_array: '6315229',
      name_search: 'HYIMWHIT',
      name: 'Hypericum inodorum Magical White',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 895,
      chnn_stock_retail: 895,
      sppl_order_minimum: 3,
      sppl_prcp: 1.434,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '41GKBLR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41GKBLR6'
    },
    {
      status: 210,
      sku: 'PM0068296',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197030',
      rtl_batch_array: '6197030',
      name_search: 'NEFAASSE',
      name: 'Nepeta faassenii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2229,
      chnn_stock_retail: 2229,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XT1RXYHP;LEGK51PP',
      statusContent: 'dot',
      hash: 'GL6XCTPX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GL6XCTPX'
    },
    {
      status: 210,
      sku: 'PM0079249',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315230',
      rtl_batch_array: '6315230',
      name_search: 'HYIOFLAI',
      name: "Hypericum inodorum 'Orange Flair'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5593,
      chnn_stock_retail: 5593,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLYR3BYS;1PGBRS4Y',
      statusContent: 'dot',
      hash: '86SGXK26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86SGXK26'
    },
    {
      status: 210,
      sku: 'PM0079250',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315232',
      rtl_batch_array: '6315232',
      name_search: 'HYIRHEIN',
      name: "Hypericum inodorum 'Rheingold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 9384,
      chnn_stock_retail: 9384,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y2P47184',
      statusContent: 'dot',
      hash: 'AD73D4BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AD73D4BH'
    },
    {
      status: 210,
      sku: 'PM0079251',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315233',
      rtl_batch_array: '6315233',
      name_search: 'HYKOUYTC',
      name: 'Hypericum kouytchense',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2300,
      chnn_stock_retail: 2300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9RB138JX',
      statusContent: 'dot',
      hash: 'KWY9PRK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWY9PRK3'
    },
    {
      status: 210,
      sku: 'PM0067175',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301474',
      rtl_batch_array: '6230508, 6301474, 6333201',
      name_search: 'CALBRACH',
      name: 'Calamagrostis brachytricha',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4435,
      chnn_stock_retail: 8018,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XZB6RDV5;Z1Y6ZW7B;X5RL9DAG;RL2SKJRT;TJNDBH6K;J4D5311G;WV5XVJTY;1A4C7G2Y;6Y1WGHAD;AE1868HW;XBRY6XRV;ATNC724L',
      statusContent: 'dot',
      hash: '5YHVLEX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YHVLEX4'
    },
    {
      status: 210,
      sku: 'PM0079252',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315257',
      rtl_batch_array: '6315257',
      name_search: 'LONSBEAU',
      name: "Lonicera nitida 'Silver Beauty'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DH8VSY15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH8VSY15'
    },
    {
      status: 210,
      sku: 'PM0079253',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315272',
      rtl_batch_array: '6315272',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 4290,
      chnn_stock_retail: 4290,
      sppl_prcp: 1.878,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: 'KG3SL11E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG3SL11E'
    },
    {
      status: 210,
      sku: 'PM0067162',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177555',
      rtl_batch_array: '6177555',
      name_search: 'SANBLAUH',
      name: "Salvia nemorosa 'Blauh\u00fcgel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BT2VVV4H;5CBR2TAT;AXBKARR4;7K45XA55;VA1738SY',
      statusContent: 'dot',
      hash: 'JA8XVLVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JA8XVLVN'
    },
    {
      status: 210,
      sku: 'PM0079255',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315286',
      rtl_batch_array: '6315286',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2750,
      chnn_stock_retail: 2750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'P1VLC2N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1VLC2N5'
    },
    {
      status: 910,
      sku: 'PM0067153',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6186963',
      rtl_batch_array: '6186963, 6339032',
      name_search: 'CLARMAND',
      name: 'Clematis armandii',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 2,
      chnn_stock_retail: 3,
      sppl_order_minimum: 6,
      sppl_prcp: 6.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A2HRDWJB;81D7Z7NT;SHH1R6CH;XVSDW131;7ZDCPZJC;XTLYZ1G9;4HHPASY8;LCRDTP5B;C4BLBTV3;T47TA769;7237NA5V;NRZ39N9Y;TZAK7BTG;Y1JD94C5',
      statusContent: 'dot',
      hash: 'J3TRLN97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3TRLN97'
    },
    {
      status: 210,
      sku: 'PM0067178',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177719',
      rtl_batch_array: '6177719',
      name_search: 'MISBLUET',
      name: "Miscanthus sinensis 'Bl\u00fctenwunder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SC6KTVHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SC6KTVHK'
    },
    {
      status: 210,
      sku: 'PM0067173',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177585',
      rtl_batch_array: '6177585',
      name_search: 'LESNOWCA',
      name: "Leucanthemum (S) 'Snowcap'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1129,
      chnn_stock_retail: 1129,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '99KH6965',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99KH6965'
    },
    {
      status: 210,
      sku: 'PM0067156',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177545',
      rtl_batch_array: '6177545',
      name_search: 'SYGWBLUE',
      name: "Symphytum grandiflorum 'Wisley Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y2L9VV1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2L9VV1L'
    },
    {
      status: 910,
      sku: 'PM0046875',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ERPMBFAL',
      name: 'Eryngium planum Magical Blue Falls',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'Z5RJC4VA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z5RJC4VA'
    },
    {
      status: 910,
      sku: 'PM0071306',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ARMACROP',
      name: 'Aristolochia macrophylla',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'CPEX61KD;EJ4JD5WN;JJDJHYB2;6NER36TD;ZKBVW7BE;82N8W972;GLY77JE2;6L2AAV36;1NKZ3VN2;BP1ZDXGH',
      statusContent: 'dot',
      hash: 'N37XJG3B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N37XJG3B'
    },
    {
      status: 210,
      sku: 'PM0067131',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177043',
      rtl_batch_array: '6177043',
      name_search: 'TACFARME',
      name: "Taxus cuspidata 'Farmen'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1405,
      chnn_stock_retail: 1405,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVSD63WP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVSD63WP'
    },
    {
      status: 210,
      sku: 'PM0079260',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306980',
      rtl_batch_array: '6306980',
      name_search: 'HAMSUNFL',
      name: 'Hakonechloa macra Sunflare',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RC5GGKWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC5GGKWY'
    },
    {
      status: 210,
      sku: 'PM0079261',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307711',
      rtl_batch_array: '6307711',
      name_search: 'WIFHONBE',
      name: "Wisteria floribunda 'Honbeni'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 3055,
      chnn_stock_retail: 3055,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9LWA9WS5;7PDDNJBL;N7KYD7HG;HYETNWDX;CBT1DSNV;WP5ASC5A;7S1R1TWK;CKR96TVD;52BP15ZB;BG8B7LS2;DWN5TX2L;CVSBSSRG;G8RNEXCV;RPWPZKKH;P3K49EXW;KGXN5G3C;91W22CE7',
      statusContent: 'dot',
      hash: '15LRCTCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15LRCTCH'
    },
    {
      status: 210,
      sku: 'PM0053171',
      core_name: 'Plant',
      sppl_ean: '8720349426089',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6050805',
      rtl_batch_array: '6050805',
      name_search: 'POAUREA',
      name: 'Potentilla aurea',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLDP5ZB5;CYW4T1L2;D4WV799A;WS3TZL1L;A9AK9KNR;CCD2NLCW;HV4XC4DW;4L852VL7',
      statusContent: 'dot',
      hash: 'AJBX74KD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJBX74KD'
    },
    {
      status: 210,
      sku: 'PM0079264',
      core_name: 'Plant',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837002',
      rtl_batch_array: '5837002',
      name_search: 'LILGSCRE',
      name: 'Ligustrum lucidum Green Screen',
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RNKBPZ5X',
      statusContent: 'dot',
      hash: 'K3VS7SLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3VS7SLX'
    },
    {
      status: 210,
      sku: 'PM0068293',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197020',
      rtl_batch_array: '6197020, 6252662',
      name_search: 'LESLADY',
      name: "Leucanthemum (S) 'Snow Lady'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1180,
      chnn_stock_retail: 1635,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KVN18HJC;Z9K8DT6L;SZDTCGC7;WZ6BLYJR;2A1NBVDY;Y2ADT2JC;2JWAL6JG;H2PW69CR;EP6H8JG3;D6T2VE1T;56SVJ3NY',
      statusContent: 'dot',
      hash: 'K4EZTA25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4EZTA25'
    },
    {
      status: 210,
      sku: 'PM0079265',
      core_name: 'Plant',
      sppl_ean: '8720664689084',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837929',
      rtl_batch_array: '5837929',
      name_search: 'EUFCOLOR',
      name: "Euonymus fortunei 'Coloratus'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6781,
      chnn_stock_retail: 6781,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1LPHN5Y;NN7E8ELY',
      statusContent: 'dot',
      hash: 'NZAEDYD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZAEDYD4'
    },
    {
      status: 210,
      sku: 'PM0079266',
      core_name: 'Plant',
      sppl_ean: '8720626321052',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856636',
      rtl_batch_array: '5856636',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 3.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      imageBatch: 'PDBWSPZ9',
      statusContent: 'dot',
      hash: '56NDP359',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56NDP359'
    },
    {
      status: 210,
      sku: 'PM0079267',
      core_name: 'Plant',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856637',
      rtl_batch_array: '5856637',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      sppl_size_code: '050060C4',
      rng_range_identifier: 'H050060C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 8.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'C75ZLHG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C75ZLHG9'
    },
    {
      status: 810,
      sku: 'PM0081969',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349214',
      rtl_batch_array: '6349214',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 49.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: '61SWN6TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61SWN6TW'
    },
    {
      status: 210,
      sku: 'PM0079268',
      core_name: 'Plant',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856639',
      rtl_batch_array: '5856639',
      name_search: 'SASALIGN',
      name: 'Sarcococca saligna',
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 4.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B55P3E9B;4CVD4RZJ;9351GWL3',
      statusContent: 'dot',
      hash: 'CV554YV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV554YV2'
    },
    {
      status: 910,
      sku: 'PM0079238',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEDCFFAV',
      name: "Hedera colchica 'Fall Favourite'",
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      imageCore:
        'WHC76BAZ;HVLEN2ZV;PDVV9TW9;7WV2LS4E;BX8B2LWB;2RVLX4BK;TLDZB1ZL',
      statusContent: 'dot',
      hash: 'REJ86LGV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'REJ86LGV'
    },
    {
      status: 210,
      sku: 'PM0067165',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177566',
      rtl_batch_array: '6177566',
      name_search: 'ACCGOLD',
      name: "Achillea 'Coronation Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RK53DSVD;JY3SR1JC;TR9GVDJA;A9JKW7HY;X9Z5KEEA',
      statusContent: 'dot',
      hash: 'PA1TA48Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA1TA48Y'
    },
    {
      status: 210,
      sku: 'PM0079269',
      core_name: 'Plant',
      sppl_ean: '8720664800212',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856642',
      rtl_batch_array: '5856642',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      imageBatch: 'A6NABJEY',
      statusContent: 'dot',
      hash: 'HXY523XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXY523XP'
    },
    {
      status: 210,
      sku: 'PM0079271',
      core_name: 'Plant',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856646',
      rtl_batch_array: '5856646',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 3.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      imageBatch: 'LCB2LKVY',
      statusContent: 'dot',
      hash: '84TT6N3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84TT6N3G'
    },
    {
      status: 810,
      sku: 'PM0081970',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349215',
      rtl_batch_array: '6349215',
      name_search: 'AMAOBELI',
      name: "Amelanchier alnifolia 'Obelisk'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '946XB64B;EYS1BN2S;KL8VZVLJ;NBV7VACV;KPW59Y8G;ARX3247Z;7B9H832V;VJ69G259;Y27NBJP2;VRGV7ED9;J6JTCHE3;H6GG5LPL;WVC3DSJB;TZ686VJY',
      statusContent: 'dot',
      hash: 'X5ZZ11XL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5ZZ11XL'
    },
    {
      status: 210,
      sku: 'PM0079274',
      core_name: 'Plant',
      sppl_ean: '8720664882263',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6124619',
      rtl_batch_array: '6124619',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '010015P9',
      rng_range_identifier: 'H010015P9',
      rng_range_description: 'H10 cm 15 cm P9',
      sppl_stock_available: 20000,
      chnn_stock_retail: 20000,
      sppl_order_minimum: 8,
      sppl_prcp: 0.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '89EAEBP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89EAEBP1'
    },
    {
      status: 810,
      sku: 'PM0081971',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349216',
      rtl_batch_array: '6349216',
      name_search: 'AMAOBELI',
      name: "Amelanchier alnifolia 'Obelisk'",
      sppl_size_code: '120140C12',
      rng_range_identifier: 'H120140C12',
      rng_range_description: 'H120 cm 140 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '946XB64B;EYS1BN2S;KL8VZVLJ;NBV7VACV;KPW59Y8G;ARX3247Z;7B9H832V;VJ69G259;Y27NBJP2;VRGV7ED9;J6JTCHE3;H6GG5LPL;WVC3DSJB;TZ686VJY',
      statusContent: 'dot',
      hash: 'C7KLS2RP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7KLS2RP'
    },
    {
      status: 210,
      sku: 'PM0079276',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244268',
      rtl_batch_array: '6244268',
      name_search: 'ANLESSON',
      name: 'Anemanthele lessoniana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KB8LXK26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KB8LXK26'
    },
    {
      status: 210,
      sku: 'PM0079254',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315273',
      rtl_batch_array: '6315273',
      name_search: 'PAQENGEL',
      name: 'Parthenocissus quinquefolia engelmannii',
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 4237,
      chnn_stock_retail: 4237,
      sppl_prcp: 1.878,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG1H657G;1D75YPT3;JC84WWJZ;XY55AD12;DPP95XZR;TAVEAT1N',
      statusContent: 'dot',
      hash: 'W3K8HZYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3K8HZYB'
    },
    {
      status: 210,
      sku: 'PM0079270',
      core_name: 'Plant',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856643',
      rtl_batch_array: '5856643',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      imageBatch: '7XX5L98W',
      statusContent: 'dot',
      hash: '6WESLDPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WESLDPN'
    },
    {
      status: 210,
      sku: 'PM0066092',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385812',
      rtl_batch_array: '6170238, 6271340, 6385812',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1860,
      chnn_stock_retail: 3585,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'N8WAWP48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8WAWP48'
    },
    {
      status: 210,
      sku: 'PM0079236',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315173',
      rtl_batch_array: '6315173',
      name_search: 'EUFDBLAN',
      name: "Euonymus fortunei 'Dart's Blanket'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3140,
      chnn_stock_retail: 3140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZW67HHS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW67HHS1'
    },
    {
      status: 210,
      sku: 'PM0079259',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302656',
      rtl_batch_array: '6302656',
      name_search: 'LEARENAR',
      name: 'Leymus arenarius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4526,
      chnn_stock_retail: 4526,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CB7JK5TZ;CW31ST4Y;SNX89TE4;A2599D1J;J7DVX4TA;L9TTVA59;5YZ57GP2;RG9GEDNV;EHKNV4SE;CCAY493R;NDY3TPYJ',
      statusContent: 'dot',
      hash: '9PLL3D8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PLL3D8Z'
    },
    {
      status: 210,
      sku: 'PM0079231',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300711',
      rtl_batch_array: '6315094, 6300711',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3183,
      chnn_stock_retail: 7378,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'VWG3BL9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWG3BL9H'
    },
    {
      status: 210,
      sku: 'PM0053172',
      core_name: 'Plant',
      sppl_ean: '8720349426126',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6050806',
      rtl_batch_array: '6050806',
      name_search: 'POTNUUK',
      name: "Potentilla tridentata 'Nuuk'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5VGJ8ZB;K4K9K2E3',
      statusContent: 'dot',
      hash: 'LXY6R56N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXY6R56N'
    },
    {
      status: 810,
      sku: 'PM0079262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192644',
      rtl_batch_array: '6192644',
      name_search: 'ANNGFING',
      name: "Anemone nemorosa 'Green Fingers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V3L1ZDC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3L1ZDC1'
    },
    {
      status: 210,
      sku: 'PM0079263',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192645',
      rtl_batch_array: '6192645',
      name_search: 'ANNHILDA',
      name: "Anemone nemorosa 'Hilda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6YEV2P75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YEV2P75'
    },
    {
      status: 210,
      sku: 'PM0079272',
      core_name: 'Plant',
      sppl_ean: '8720664680692',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054380',
      rtl_batch_array: '6054380',
      name_search: 'ANNMONTR',
      name: "Anemone nemorosa 'Monstrosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 468,
      chnn_stock_retail: 468,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'REN1YAZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REN1YAZD'
    },
    {
      status: 810,
      sku: 'PM0079273',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254357',
      rtl_batch_array: '6161530, 6254357',
      name_search: 'ANNROBIN',
      name: "Anemone nemorosa 'Robinsoniana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KA1W3KND',
      statusContent: 'dot',
      hash: 'JNSKBXNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNSKBXNL'
    },
    {
      status: 210,
      sku: 'PM0079275',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176324',
      rtl_batch_array: '6176324',
      name_search: 'ANNRBLUE',
      name: "Anemone nemorosa 'Royal Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CXXH9XSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXXH9XSS'
    },
    {
      status: 210,
      sku: 'PM0070176',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220682',
      rtl_batch_array: '6220682',
      name_search: 'CIHBBEA',
      name: "Cimicifuga 'Hillside Black Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7VHNTA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7VHNTA3'
    },
    {
      status: 210,
      sku: 'PM0067779',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350053',
      rtl_batch_array: '6350053, 6214770',
      name_search: 'ECJULIA',
      name: "Echinacea 'Julia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 378,
      chnn_stock_retail: 577,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JYTJC1DL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYTJC1DL'
    },
    {
      status: 810,
      sku: 'PM0067784',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271633',
      rtl_batch_array: '6268737, 6271633, 6340024',
      name_search: 'EUPSENIO',
      name: "Euphorbia polychroma 'Senior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 40,
      chnn_stock_retail: 2499,
      sppl_order_minimum: 3,
      sppl_prcp: 0.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XC4WEZC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC4WEZC2'
    },
    {
      status: 210,
      sku: 'PM0069756',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353288',
      rtl_batch_array: '6353288',
      name_search: 'SEBPARTY',
      name: "Sedum 'Beach Party'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DX1AG287',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DX1AG287'
    },
    {
      status: 210,
      sku: 'PM0071309',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6219458',
      rtl_batch_array: '6219458, 6295415',
      name_search: 'CARFLAME',
      name: "Campsis radicans 'Flamenco'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 150,
      chnn_stock_retail: 157,
      sppl_prcp: 4.423,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C7425PY5;SR2G1LHW;LHXVR7H7',
      statusContent: 'dot',
      hash: '72PJXGLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72PJXGLN'
    },
    {
      status: 210,
      sku: 'PM0069757',
      core_name: 'Plant',
      sppl_ean: '8720664885745',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147872',
      rtl_batch_array: '6147872',
      name_search: 'SEVJAMES',
      name: "Sedum 'Vera Jameson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 526,
      chnn_stock_retail: 526,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BE2HC2KZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE2HC2KZ'
    },
    {
      status: 210,
      sku: 'PM0069755',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6266384',
      rtl_batch_array: '6266384, 6268822',
      name_search: 'SETSGYEL',
      name: "Sedum t. 'Seduction Green-Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 689,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HY764PP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HY764PP7'
    },
    {
      status: 210,
      sku: 'PM0067160',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177553',
      rtl_batch_array: '6177553',
      name_search: 'SAGBNOTE',
      name: "Salvia greggii 'Blue Note'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '528WWKYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '528WWKYG'
    },
    {
      status: 210,
      sku: 'PM0071308',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219457',
      rtl_batch_array: '6219457',
      name_search: 'CARFLAVA',
      name: "Campsis radicans 'Flava'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 4.423,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JCHVEJWX;955JNY8S;NAVWW5X8;LRPCG31B;L7R7ZV3X;YJWS76ZG',
      statusContent: 'dot',
      hash: 'A3VAK4NX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3VAK4NX'
    },
    {
      status: 210,
      sku: 'PM0079247',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315228',
      rtl_batch_array: '6315228',
      name_search: 'HYIMRED',
      name: 'Hypericum inodorum Magical Red',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7922,
      chnn_stock_retail: 7922,
      sppl_order_minimum: 3,
      sppl_prcp: 1.434,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WT1SKA8N',
      statusContent: 'dot',
      hash: '7JK7XK1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JK7XK1Z'
    },
    {
      status: 210,
      sku: 'PM0069749',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353309',
      rtl_batch_array: '6295861, 6353309',
      name_search: 'SEMGOODB',
      name: "Sedum 'Mr Goodbud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 689,
      chnn_stock_retail: 782,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TR54EZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TR54EZ1'
    },
    {
      status: 210,
      sku: 'PM0079256',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301580',
      rtl_batch_array: '6301580',
      name_search: 'PUAAZURE',
      name: "Pulmonaria angustifolia 'Azurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1237,
      chnn_stock_retail: 1237,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WLC1AXCS',
      statusContent: 'dot',
      hash: '6992WKR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6992WKR2'
    },
    {
      status: 210,
      sku: 'PM0069775',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382287',
      rtl_batch_array: '6315055, 6350680, 6382287',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1270,
      chnn_stock_retail: 3953,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5597KGNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5597KGNE'
    },
    {
      status: 210,
      sku: 'PM0079257',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301820',
      rtl_batch_array: '6301820',
      name_search: 'SANOFFIC',
      name: 'Sanguisorba officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1329,
      chnn_stock_retail: 1329,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AWYCK26P;H25WA1E5',
      statusContent: 'dot',
      hash: '18T6L8JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18T6L8JY'
    },
    {
      status: 210,
      sku: 'PM0067174',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177586',
      rtl_batch_array: '6177586',
      name_search: 'LUGPINK',
      name: "Lupinus 'Gallery Pink'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 729,
      chnn_stock_retail: 729,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9B1KH9S9;E272T6WK;X72BPDTK;TK53SVH2;DWHVE5K4;L65527HR;596EL16Z;CACX2TPK;7JJZS667;7ZNBS349;6HLNRK9N',
      statusContent: 'dot',
      hash: 'ZT6NS4TH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZT6NS4TH'
    },
    {
      status: 210,
      sku: 'PM0049981',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079248',
      rtl_batch_array: '6079248',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 4810,
      chnn_stock_retail: 4810,
      sppl_prcp: 3.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B2AR957K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2AR957K'
    },
    {
      status: 810,
      sku: 'PM0081972',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349218',
      rtl_batch_array: '6349218',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 50.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'C2L9J6BX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2L9J6BX'
    },
    {
      status: 810,
      sku: 'PM0032100',
      core_name: 'Plant',
      sppl_ean: '8720664689060',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5818917',
      rtl_batch_array: '5818917',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'YBZES7EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBZES7EV'
    },
    {
      status: 210,
      sku: 'PM0085554',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385458',
      rtl_batch_array: '6385458',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 13.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'D8JXH3RK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8JXH3RK'
    },
    {
      status: 210,
      sku: 'PM0053176',
      core_name: 'Plant',
      sppl_ean: '8720349408092',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6050810',
      rtl_batch_array: '6050810',
      name_search: 'ACMDESDE',
      name: "Achillea millefolium 'Desderos'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1127,
      chnn_stock_retail: 1127,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SG57W2JG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG57W2JG'
    },
    {
      status: 810,
      sku: 'PM0085555',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385459',
      rtl_batch_array: '6385459',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '160180C45',
      rng_range_identifier: 'H160180C45',
      rng_range_description: 'H160 cm 180 cm C45',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 56.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'EPZNTTX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPZNTTX4'
    },
    {
      status: 210,
      sku: 'PM0085556',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385460',
      rtl_batch_array: '6385460',
      name_search: 'PRLTICO',
      name: 'Prunus lusitanica Tico',
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWY41PZS;7R6JJ5AZ;4JP57245;K7VPGT28;G23X8HE1',
      statusContent: 'dot',
      hash: '8171Y11S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8171Y11S'
    },
    {
      status: 910,
      sku: 'PM0085557',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385467',
      rtl_batch_array: '6385467',
      name_search: 'TIEPALLI',
      name: "Tilia europaea 'Pallida'",
      sppl_size_code: '014016LEIC5',
      rng_range_identifier: 'PLEA014016C50',
      rng_range_description: 'Pleached 14 cm 16 cm C50',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 150,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GTJ2TX34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTJ2TX34'
    },
    {
      status: 910,
      sku: 'PM0085558',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385468',
      rtl_batch_array: '6385468',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '125150C30',
      rng_range_identifier: 'H125150C30',
      rng_range_description: 'H125 cm 150 cm C30',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 19.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'HHAYT153',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHAYT153'
    },
    {
      status: 810,
      sku: 'PM0085559',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385469',
      rtl_batch_array: '6385469',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 3.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'AYZZLJHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYZZLJHG'
    },
    {
      status: 210,
      sku: 'PM0066816',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6321872',
      rtl_batch_array: '6300412, 6176237, 6321872',
      name_search: 'ALTUBERO',
      name: 'Allium tuberosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 440,
      chnn_stock_retail: 2480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98SV9Y8N;A8D4TW6Z;AK1V5ZTH;A5CC327R;W4JDCB8E;1HHDDX1G;N43VX5BZ;LSZ3ZPAG;Z13VS43E;CSH4B183;1PHK75WX;S4VYKSET;NATLH2WT;DLYAA28A;44V9SDHE',
      statusContent: 'dot',
      hash: 'YPJALTX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPJALTX6'
    },
    {
      status: 210,
      sku: 'PM0067252',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228358',
      rtl_batch_array: '6228358',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1372,
      chnn_stock_retail: 1372,
      sppl_prcp: 2.704,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'JL8RK8BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL8RK8BV'
    },
    {
      status: 910,
      sku: 'PM0066844',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASECAUCA',
      name: 'Asarum europaeum caucasicum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1ZYVZH7X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1ZYVZH7X'
    },
    {
      status: 210,
      sku: 'PM0031971',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353533',
      rtl_batch_array: '6353533',
      name_search: 'TRSBOWL',
      name: "Trollius 'Super Bowl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BDP3GD62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDP3GD62'
    },
    {
      status: 210,
      sku: 'PM0067214',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177936',
      rtl_batch_array: '6177936',
      name_search: 'SANBTOWE',
      name: 'Sambucus nigra Black Tower',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 4.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '443DVLWA;CYXXJ6E8;KV2A5HYK;BCV14524;D5EY9XN9;G34YXXSK;D6398STY;HL5TN4T1;1B1446B7;1B5DH2ZJ;TYG49Z6A;XGVKGY5V;ZPZYXXCE',
      statusContent: 'dot',
      hash: '2NHCASZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NHCASZZ'
    },
    {
      status: 210,
      sku: 'PM0068307',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291785',
      rtl_batch_array: '6291785',
      name_search: 'BRMACROP',
      name: 'Brunnera macrophylla',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4058,
      chnn_stock_retail: 4058,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8KAG19SY;RS1KNBR7;TP6JKRW9;4BCBR62K;V41W818Z',
      statusContent: 'dot',
      hash: '49KKYA3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49KKYA3N'
    },
    {
      status: 210,
      sku: 'PM0069796',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353571',
      rtl_batch_array: '6353571, 6356612',
      name_search: 'VEVAPOLL',
      name: "Veronicastrum virginicum 'Apollo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1038,
      chnn_stock_retail: 1099,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JWY33LEW',
      statusContent: 'dot',
      hash: '6AC22LZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6AC22LZD'
    },
    {
      status: 810,
      sku: 'PM0078820',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311916',
      rtl_batch_array: '6311916',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'VHPWXJS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHPWXJS4'
    },
    {
      status: 210,
      sku: 'PM0065296',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244373',
      rtl_batch_array: '6244373',
      name_search: 'CHLSTARD',
      name: "Chamaecyparis lawsoniana 'Stardust'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z4NKRN9D;63ZC9SX9;ZVCCY891;PVL3LLYR;KHW5EDJZ;GB732SRN;VZED841A;7A7WDSA1;PSEYXY2X;SCJA9YXY;92J1R18V;E1TNWD1T;NDXNC5PL;8N15TVAC',
      statusContent: 'dot',
      hash: '8HGL8DVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HGL8DVB'
    },
    {
      status: 210,
      sku: 'PM0047183',
      core_name: 'Plant',
      sppl_ean: '8720664801073',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015435',
      rtl_batch_array: '6015435',
      name_search: 'PEANARBO',
      name: "Pennisetum alopecuroides 'National Arboretum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NDBZJKG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDBZJKG2'
    },
    {
      status: 210,
      sku: 'PM0068316',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243713',
      rtl_batch_array: '6243713',
      name_search: 'LIPRZEWA',
      name: 'Ligularia przewalskii',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6811,
      chnn_stock_retail: 6811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2JT1V775;7N62ZX59;WWS4KJ7H',
      statusContent: 'dot',
      hash: '47HBJG76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47HBJG76'
    },
    {
      status: 210,
      sku: 'PM0067196',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177916',
      rtl_batch_array: '6177916',
      name_search: 'SANGSPAR',
      name: 'Sambucus nigra Gold Spark',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1499,
      chnn_stock_retail: 1499,
      sppl_prcp: 6.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DANBNLPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DANBNLPR'
    },
    {
      status: 210,
      sku: 'PM0085560',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385476',
      rtl_batch_array: '6385476',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 6842,
      chnn_stock_retail: 6842,
      sppl_order_minimum: 3,
      sppl_prcp: 1.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'BBGSCR7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBGSCR7K'
    },
    {
      status: 210,
      sku: 'PM0085561',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385477',
      rtl_batch_array: '6385477',
      name_search: 'MISZEBRI',
      name: "Miscanthus sinensis 'Zebrinus'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1209,
      chnn_stock_retail: 1209,
      sppl_order_minimum: 3,
      sppl_prcp: 1.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJBCBBRB;ADKPAXAV;YS489W1S;XRPXYL62;8GZVNA6C;EPCW87X6;J1YTEA1P;1GC6D5AS;6CZRSXHC;N4Y9WKJG',
      statusContent: 'dot',
      hash: 'RDYXS18D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDYXS18D'
    },
    {
      status: 210,
      sku: 'PM0067219',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353297',
      rtl_batch_array: '6353297',
      name_search: 'SEFMORN',
      name: "Sedum 'Frosty Morn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 484,
      chnn_stock_retail: 484,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5ZJ14Y1;VNPSKVED',
      statusContent: 'dot',
      hash: '2NZPVNJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NZPVNJ9'
    },
    {
      status: 810,
      sku: 'PM0079279',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317203',
      rtl_batch_array: '6317203, 6364217',
      name_search: 'BACMOONL',
      name: "Baptisia 'Carolina Moonlight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 24,
      chnn_stock_retail: 92,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1A4G6HB;B5V1CX1K;SS7T3TV3;NZTR1199;GWP4H6NB',
      statusContent: 'dot',
      hash: 'GKPYEBDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKPYEBDS'
    },
    {
      status: 210,
      sku: 'PM0069777',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350684',
      rtl_batch_array: '6301720, 6350684',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 3499,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: '67WAASBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67WAASBY'
    },
    {
      status: 210,
      sku: 'PM0069778',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382292',
      rtl_batch_array: '6350689, 6382292, 6291717',
      name_search: 'MISYJIMA',
      name: "Miscanthus sinensis 'Yaku Jima'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10350,
      chnn_stock_retail: 11468,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALKSY8HJ;VVXD9HXD;4G41V652;AWKP9YYE;NXZN7NX2;AJZ5L16G;94WX15DG;8XBNL6KB;1H5ZA9BA',
      statusContent: 'dot',
      hash: 'VKPWL3D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKPWL3D7'
    },
    {
      status: 210,
      sku: 'PM0067191',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177911',
      rtl_batch_array: '6177911',
      name_search: 'SANSEREN',
      name: "Sambucus nigra 'Serenade'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K35B42B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K35B42B7'
    },
    {
      status: 210,
      sku: 'PM0085562',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385479',
      rtl_batch_array: '6385479',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1218,
      chnn_stock_retail: 1218,
      sppl_order_minimum: 3,
      sppl_prcp: 1.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: '459RBND1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '459RBND1'
    },
    {
      status: 210,
      sku: 'PM0068313',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197100',
      rtl_batch_array: '6197100',
      name_search: 'GAODORAT',
      name: 'Galium odoratum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4126,
      chnn_stock_retail: 4126,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PPD93DZV;1245S7YA;8EJ11S97;GLCNSSC9;KJC9DW3W;P4EB3RCC',
      statusContent: 'dot',
      hash: 'E165ZCXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E165ZCXG'
    },
    {
      status: 910,
      sku: 'PM0067255',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DICPVIOL',
      name: "Dianthus car. 'Peman Violet'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'XC2VYR3R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XC2VYR3R'
    },
    {
      status: 210,
      sku: 'PM0067224',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177965',
      rtl_batch_array: '6268776, 6177965',
      name_search: 'IRSBKING',
      name: "Iris sibirica 'Blue King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1263,
      sppl_order_minimum: 3,
      sppl_prcp: 0.747,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SDWN7STB;JTTNHJAP;S8JWASB8;9TRETYHJ;WVHS51KW;717GGCYG;WH9V7A8R;VDL5XJ1D',
      statusContent: 'dot',
      hash: '8VXAP1PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VXAP1PA'
    },
    {
      status: 210,
      sku: 'PM0059690',
      core_name: 'Plant',
      sppl_ean: '8720664864542',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6149700',
      rtl_batch_array: '6149700, 6117697',
      name_search: 'GECBALLE',
      name: "Geranium cinereum 'Ballerina'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9265,
      chnn_stock_retail: 10265,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y96Z6HR6;LG1H99G4',
      statusContent: 'dot',
      hash: '5H24K233',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H24K233'
    },
    {
      status: 210,
      sku: 'PM0068302',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197086',
      rtl_batch_array: '6197086',
      name_search: 'AGBBOA',
      name: "Agastache 'Blue Boa'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6962,
      chnn_stock_retail: 6962,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '47RR1YXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47RR1YXH'
    },
    {
      status: 210,
      sku: 'PM0048340',
      core_name: 'Plant',
      sppl_ean: '8720664540545',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034276',
      rtl_batch_array: '6034276',
      name_search: 'GELIPSTI',
      name: "Geum 'Lipstick'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 269,
      chnn_stock_retail: 269,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VR4AZHN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VR4AZHN8'
    },
    {
      status: 810,
      sku: 'PM0081973',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349224',
      rtl_batch_array: '6349224',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.132,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'X1JKZN6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1JKZN6R'
    },
    {
      status: 210,
      sku: 'PM0067216',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177939',
      rtl_batch_array: '6177939',
      name_search: 'SANGTOWE',
      name: 'Sambucus nigra Golden Tower',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1600,
      sppl_prcp: 4.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VTP8L3DB',
      statusContent: 'dot',
      hash: 'DZ88B5EJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ88B5EJ'
    },
    {
      status: 810,
      sku: 'PM0081974',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349225',
      rtl_batch_array: '6349225',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'SBZBCDEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBZBCDEH'
    },
    {
      status: 810,
      sku: 'PM0081975',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349226',
      rtl_batch_array: '6349226',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'T4ERP3Z3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4ERP3Z3'
    },
    {
      status: 210,
      sku: 'PM0085563',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385484',
      rtl_batch_array: '6385484',
      name_search: 'SPHETERO',
      name: 'Sporobolus heterolepis',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 525,
      chnn_stock_retail: 525,
      sppl_order_minimum: 3,
      sppl_prcp: 1.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3852BXC;89GK8LAW',
      statusContent: 'dot',
      hash: 'N1G9LZGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1G9LZGW'
    },
    {
      status: 210,
      sku: 'PM0021480',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6298668',
      rtl_batch_array: '6298668, 6309066',
      name_search: 'LEACRED',
      name: "Leucothoe axillaris 'Curly Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 56844,
      chnn_stock_retail: 57844,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8H857G1;R8H1ZAJ1;586DR5XV;KV1DANDT;55LZW6WD;8EHDL7EE;ASEKT1V4;J49WYD7L;61KYLN78;CBNKX9YG;1ZS3D7GK;LD38B6JT',
      statusContent: 'dot',
      hash: 'TB74ELJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TB74ELJJ'
    },
    {
      status: 210,
      sku: 'PM0066830',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176309',
      rtl_batch_array: '6176309',
      name_search: 'ACMRVELV',
      name: "Achillea millefolium 'Red Velvet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6Z1BPVZ2',
      statusContent: 'dot',
      hash: 'P8A1AG68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8A1AG68'
    },
    {
      status: 810,
      sku: 'PM0070178',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268729',
      rtl_batch_array: '6268729',
      name_search: 'COCLEMAT',
      name: 'Codonopsis clematidea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R9W3721H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9W3721H'
    },
    {
      status: 910,
      sku: 'PM0079296',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEGARDNE',
      name: 'Hedychium gardnerianum',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: '9DL82GR4',
      statusContent: 'dot',
      hash: 'HKNK7LGA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HKNK7LGA'
    },
    {
      status: 210,
      sku: 'PM0059673',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333277',
      rtl_batch_array: '5960184, 6333277, 6133365',
      name_search: 'MEPIPERI',
      name: 'Mentha piperita',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2826,
      chnn_stock_retail: 3223,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8PB2G7LJ;HYBX47XP;XG9G42CC;JE58PA8K;2RLSL42E;1KZ64JZX;GY2EKTZ1;DE3DR32D;WLP5JED3;A7V79YGW;AEA4LGAV;H52DZHYS;W8T9K7BB;BHG4BWS4;E76VDL35;RNY136WE;4GRXC1TG;PVHW1ZKH;4TNHJXXN;19669CTA',
      statusContent: 'dot',
      hash: 'VCZVYDN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCZVYDN2'
    },
    {
      status: 210,
      sku: 'PM0059702',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6282404',
      rtl_batch_array: '6368818, 6282404',
      name_search: 'PUVULGAR',
      name: 'Pulsatilla vulgaris',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3923,
      chnn_stock_retail: 4923,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KBYL8V2Y;BLPE7NPA;2N8ESW52;1WXXANC2;6ADK16HX;BVSD5557;B4Z2NY98;TVE1TW8K;JRR8W1ND;WY9DDK8H;46W9VZT2;GKKA9WY9;KDEJ4V9X;H8Y1NZ1L;9ZNP1PJG;86X22E89;L146XRGE;L9932J7E;T77SHYW3;XB8NEA9P;2DZ6TVRX;WSDSS2EK;KH6PS9KX;64L7WN9L;41G2CXXS',
      statusContent: 'dot',
      hash: 'JS7D2HET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JS7D2HET'
    },
    {
      status: 210,
      sku: 'PM0067194',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177914',
      rtl_batch_array: '6177914',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 3947,
      chnn_stock_retail: 3947,
      sppl_prcp: 6.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'GL1BZXKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GL1BZXKL'
    },
    {
      status: 810,
      sku: 'PM0068300',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197042',
      rtl_batch_array: '6197042',
      name_search: 'SAVPRAIN',
      name: "Salvia verticillata 'Purple Rain'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9HLA75N;6BYAWECY;NYXG54TZ;ZLCAG2GX;228BC34P;G5VXD89R;1WR985PG;2D647XWE;ZJW2ZGRD;85DBZC2Y',
      statusContent: 'dot',
      hash: 'XC8V9HXD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC8V9HXD'
    },
    {
      status: 210,
      sku: 'PM0059713',
      core_name: 'Plant',
      sppl_ean: '8720664872127',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6120645',
      rtl_batch_array: '6120645',
      name_search: 'LESLADY',
      name: "Leucanthemum (S) 'Snow Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 273,
      chnn_stock_retail: 273,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KVN18HJC;Z9K8DT6L;SZDTCGC7;WZ6BLYJR;2A1NBVDY;Y2ADT2JC;2JWAL6JG;H2PW69CR;EP6H8JG3;D6T2VE1T;56SVJ3NY',
      statusContent: 'dot',
      hash: 'D3C8RHGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3C8RHGH'
    },
    {
      status: 210,
      sku: 'PM0059649',
      core_name: 'Plant',
      sppl_ean: '8720626371002',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5941643',
      rtl_batch_array: '5941643',
      name_search: 'SPDENSIF',
      name: 'Spiraea densiflora',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6371,
      chnn_stock_retail: 6371,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZ2SH6T3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ2SH6T3'
    },
    {
      status: 210,
      sku: 'PM0067186',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177906',
      rtl_batch_array: '6177906',
      name_search: 'HYPDIAMA',
      name: 'Hydrangea paniculata Diamantino',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RR3LCHD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR3LCHD9'
    },
    {
      status: 210,
      sku: 'PM0067220',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6177958',
      rtl_batch_array: '6177958, 6230998, 6350356, 6217166',
      name_search: 'HELILIOA',
      name: 'Hemerocallis lilioasphodelus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 2396,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PDXDZ2B7;84H8B3HT',
      statusContent: 'dot',
      hash: '61365CBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61365CBT'
    },
    {
      status: 210,
      sku: 'PM0053260',
      core_name: 'Plant',
      sppl_ean: '8720626344242',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6052140',
      rtl_batch_array: '6052140',
      name_search: 'DISPLEND',
      name: 'Diervilla splendens',
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 825,
      chnn_stock_retail: 825,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WA7CK2WW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WA7CK2WW'
    },
    {
      status: 210,
      sku: 'PM0064860',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6181279',
      rtl_batch_array: '6149372, 6181279',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 563,
      chnn_stock_retail: 1563,
      sppl_prcp: 1.732,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'HW8XHGLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HW8XHGLS'
    },
    {
      status: 210,
      sku: 'PM0079125',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314904',
      rtl_batch_array: '6314904',
      name_search: 'FABALDSC',
      name: 'Fallopia baldschuanica',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NG4RBY9A;TZ3PRT9X;YV2VY1D4;79WVNGHK;JE4ETSWB;RCJ786DN;XPC2CAZP;9B6C61X5',
      statusContent: 'dot',
      hash: '2GV76JVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GV76JVY'
    },
    {
      status: 210,
      sku: 'PM0078623',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310336',
      rtl_batch_array: '6310336',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 11992,
      chnn_stock_retail: 11992,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: '9YECRYNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YECRYNW'
    },
    {
      status: 910,
      sku: 'PM0067254',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DICPFCER',
      name: "Dianthus car. 'Peman Fancy Cerise'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'ED8Y1E9T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ED8Y1E9T'
    },
    {
      status: 210,
      sku: 'PM0074308',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269601',
      rtl_batch_array: '6269601',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2900,
      chnn_stock_retail: 2900,
      sppl_order_minimum: 3,
      sppl_prcp: 2.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: '5V1PGCBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V1PGCBE'
    },
    {
      status: 210,
      sku: 'PM0078541',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310230',
      rtl_batch_array: '6310230',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 773,
      chnn_stock_retail: 773,
      sppl_prcp: 1.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'JXYE7XGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXYE7XGN'
    },
    {
      status: 210,
      sku: 'PM0032413',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253072',
      rtl_batch_array: '6253072',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'C7KGXJLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7KGXJLJ'
    },
    {
      status: 210,
      sku: 'PM0032414',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253074',
      rtl_batch_array: '6253074',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'DGJB72J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGJB72J1'
    },
    {
      status: 210,
      sku: 'PM0067215',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177937',
      rtl_batch_array: '6177937',
      name_search: 'SANBTOWE',
      name: 'Sambucus nigra Black Tower',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 6.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '443DVLWA;CYXXJ6E8;KV2A5HYK;BCV14524;D5EY9XN9;G34YXXSK;D6398STY;HL5TN4T1;1B1446B7;1B5DH2ZJ;TYG49Z6A;XGVKGY5V;ZPZYXXCE',
      statusContent: 'dot',
      hash: '7P5S3B9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P5S3B9V'
    },
    {
      status: 210,
      sku: 'PM0078157',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345188',
      rtl_batch_array: '6307968, 6345188',
      name_search: 'ANHPRAEC',
      name: "Anemone hupehensis 'Praecox'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 853,
      chnn_stock_retail: 1896,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEE7KAS1;BKE1D8SL;KKPSJ4WJ;SW3Z88N7;B5K4HPE7;K6DZ8WA5;RDY875H6',
      statusContent: 'dot',
      hash: 'KPX64XCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPX64XCX'
    },
    {
      status: 910,
      sku: 'PM0078155',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ANHLSBRE',
      name: 'Anemone hupehensis Little Summer Breeze',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '24EPCCGC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '24EPCCGC'
    },
    {
      status: 210,
      sku: 'PM0078165',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307980',
      rtl_batch_array: '6307980',
      name_search: 'ANSVMEAD',
      name: "Angelica sylvestris 'Vicar's Mead'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 396,
      chnn_stock_retail: 396,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7J7DHZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7J7DHZ2'
    },
    {
      status: 810,
      sku: 'PM0069225',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301402',
      rtl_batch_array: '6301402',
      name_search: 'ANDALBA',
      name: "Antennaria dioica 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JDANRED7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDANRED7'
    },
    {
      status: 210,
      sku: 'PM0078173',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349755',
      rtl_batch_array: '6349755, 6307989',
      name_search: 'AQGAPPLE',
      name: "Aquilegia 'Green Apples'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 361,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6CR2C3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6CR2C3V'
    },
    {
      status: 210,
      sku: 'PM0078169',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307984',
      rtl_batch_array: '6307984',
      name_search: 'AQHBLUE',
      name: "Aquilegia 'Heavenly Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BELB6P45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BELB6P45'
    },
    {
      status: 210,
      sku: 'PM0078170',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307985',
      rtl_batch_array: '6307985',
      name_search: 'AQKORALL',
      name: "Aquilegia 'Koralle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '174TZK8T;2Z7G8B4E;7WBK2EE9;PR3SHLNS;9WPGGA53;ZY65P3TC;JVGVBRCP;2ZT541YK',
      statusContent: 'dot',
      hash: 'XXCJETXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXCJETXV'
    },
    {
      status: 210,
      sku: 'PM0067199',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177919',
      rtl_batch_array: '6177919',
      name_search: 'SANGTOWE',
      name: 'Sambucus nigra Golden Tower',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VTP8L3DB',
      statusContent: 'dot',
      hash: 'J9PJT291',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9PJT291'
    },
    {
      status: 910,
      sku: 'PM0066169',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'C4GTZBP8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C4GTZBP8'
    },
    {
      status: 210,
      sku: 'PM0078171',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307987',
      rtl_batch_array: '6307987',
      name_search: 'AQMMNICH',
      name: "Aquilegia 'Mrs M. Nicholls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 354,
      chnn_stock_retail: 354,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZXRC83B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZXRC83B'
    },
    {
      status: 810,
      sku: 'PM0078166',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348277',
      rtl_batch_array: '6307981, 6348277',
      name_search: 'AQCAERUL',
      name: 'Aquilegia caerulea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 631,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2SX1ZB8',
      statusContent: 'dot',
      hash: 'D2BKYZ8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2BKYZ8B'
    },
    {
      status: 910,
      sku: 'PM0078167',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AQCLLANT',
      name: "Aquilegia canadensis 'Little Lanterns'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5VB82RDR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5VB82RDR'
    },
    {
      status: 210,
      sku: 'PM0078168',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349044',
      rtl_batch_array: '6307983, 6349044',
      name_search: 'AQCYQUEE',
      name: "Aquilegia chrysantha 'Yellow Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2244,
      chnn_stock_retail: 3940,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EB5AGYJB;WJ1JX7G2',
      statusContent: 'dot',
      hash: '6X5WS4T5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X5WS4T5'
    },
    {
      status: 210,
      sku: 'PM0078174',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348281',
      rtl_batch_array: '6307990, 6348281',
      name_search: 'AQVRPORT',
      name: "Aquilegia vulgaris 'Ruby Port'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 277,
      chnn_stock_retail: 706,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '75LX2RAC',
      statusContent: 'dot',
      hash: 'YB2JJ55C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YB2JJ55C'
    },
    {
      status: 210,
      sku: 'PM0078175',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307991',
      rtl_batch_array: '6307991',
      name_search: 'AQVWBARL',
      name: "Aquilegia vulgaris 'White Barlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 574,
      chnn_stock_retail: 574,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JKXXN97E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKXXN97E'
    },
    {
      status: 210,
      sku: 'PM0079280',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349951',
      rtl_batch_array: '6349951, 6370341',
      name_search: 'COEDORAD',
      name: "Coreopsis 'El Dorado'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 532,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WNEVL1VE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNEVL1VE'
    },
    {
      status: 810,
      sku: 'PM0079295',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350272',
      rtl_batch_array: '6350272',
      name_search: 'GLTRICHO',
      name: 'Globularia trichosantha',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E1J1THG8',
      statusContent: 'dot',
      hash: '426SGZ31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '426SGZ31'
    },
    {
      status: 210,
      sku: 'PM0079290',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350412',
      rtl_batch_array: '6350412',
      name_search: 'HELLOVE',
      name: "Hemerocallis 'Little Love'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CJ2V2DDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJ2V2DDB'
    },
    {
      status: 210,
      sku: 'PM0079289',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350410',
      rtl_batch_array: '6350410',
      name_search: 'HEVHHOPE',
      name: "Heuchera villosa 'High Hopes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANWRCV8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANWRCV8P'
    },
    {
      status: 210,
      sku: 'PM0079293',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350438',
      rtl_batch_array: '6350438',
      name_search: 'HEPNOIR',
      name: "Heuchera hybrid 'Pinot Noir'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 541,
      chnn_stock_retail: 541,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZ23KA16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZ23KA16'
    },
    {
      status: 210,
      sku: 'PM0079299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350452',
      rtl_batch_array: '6350452',
      name_search: 'HETHOMAS',
      name: "Heuchera 'Thomas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R47ZR97W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R47ZR97W'
    },
    {
      status: 210,
      sku: 'PM0079300',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350460',
      rtl_batch_array: '6350460',
      name_search: 'HEXXL',
      name: "Heuchera 'XXL'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AJY1CPD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJY1CPD1'
    },
    {
      status: 210,
      sku: 'PM0079301',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350473',
      rtl_batch_array: '6350473',
      name_search: 'HEINFINI',
      name: "Heucherella 'Infinity'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9LYYXD56',
      statusContent: 'dot',
      hash: 'RLCR33RV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLCR33RV'
    },
    {
      status: 210,
      sku: 'PM0059492',
      core_name: 'Plant',
      sppl_ean: '8720664874220',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6118555',
      rtl_batch_array: '6118555',
      name_search: 'MARALBUS',
      name: "Mazus reptans 'Albus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1339,
      chnn_stock_retail: 1339,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '28ZEDK2D;CG6Z48S3;SG1AYB9H;24ZGHEY5',
      statusContent: 'dot',
      hash: 'DA9KPHYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA9KPHYE'
    },
    {
      status: 210,
      sku: 'PM0069799',
      core_name: 'Plant',
      sppl_ean: '8720664889941',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '5932509',
      rtl_batch_array: '5932509, 6369262, 6269577, 6291744',
      name_search: 'VIMVARIE',
      name: "Vinca major 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7768,
      chnn_stock_retail: 9125,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZBSSC1Y;GXX15NG3;2BRXA81Y;8DX5YJZH;Y6RE5CYA',
      statusContent: 'dot',
      hash: 'T6VTYBT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6VTYBT8'
    },
    {
      status: 210,
      sku: 'PM0025763',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349945',
      rtl_batch_array: '6208420, 6349945, 6301514, 5495247',
      name_search: 'CIRRATRO',
      name: "Cirsium rivulare 'Atropurpureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4479,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZAEZCKJ5;X9E9YY8B',
      statusContent: 'dot',
      hash: 'Y1YZ47BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1YZ47BV'
    },
    {
      status: 210,
      sku: 'PM0004422',
      core_name: 'Plant',
      sppl_ean: '8720664852983',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6142523',
      rtl_batch_array: '5592261, 6142523',
      name_search: 'ASDEUTSC',
      name: "Astilbe (J) 'Deutschland'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6013,
      chnn_stock_retail: 7013,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8HHGXL1;66WV4KD2;8KWC3Y9Y;7J8DYNSA;WBWX85DN',
      statusContent: 'dot',
      hash: 'W74SYPYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W74SYPYA'
    },
    {
      status: 810,
      sku: 'PM0059523',
      core_name: 'Plant',
      sppl_ean: '8720626375970',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420739',
      rtl_batch_array: '5420739',
      name_search: 'VIVPURPU',
      name: "Vitis vinifera 'Purpurea'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 3.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J5D3TEWK;BDK24VZA;V2YLLC5T;BWJSXKEB;PEP3YDB3;VV3HSB8N;LRW8T36K;AWB34TA4;PPBA7Z28;AS268B7X;4P49X8GW;GVHZKRSX;KPV3GW12;NZR5SJVE;8KDAYGZ6;8GA2E2RC;J7R286ER;H3AYG12R;53P6W64X',
      statusContent: 'dot',
      hash: 'DALYHK4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DALYHK4N'
    },
    {
      status: 210,
      sku: 'PM0067185',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244081',
      rtl_batch_array: '6244081',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 388,
      chnn_stock_retail: 388,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: '7C233872',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7C233872'
    },
    {
      status: 910,
      sku: 'PM0067253',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DICPFLIL',
      name: "Dianthus car. 'Peman Fancy Lilac'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '7EH2LWB2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7EH2LWB2'
    },
    {
      status: 210,
      sku: 'PM0020061',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271375',
      rtl_batch_array: '6271375',
      name_search: 'HYMHRED',
      name: "Hydrangea macrophylla 'Harry's Red'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2063,
      chnn_stock_retail: 2063,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RX5ZY75Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX5ZY75Z'
    },
    {
      status: 210,
      sku: 'PM0079282',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350372',
      rtl_batch_array: '6350372',
      name_search: 'HEBEAUJO',
      name: "Heuchera 'Beaujolais'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NWL4EE97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWL4EE97'
    },
    {
      status: 210,
      sku: 'PM0068306',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197090',
      rtl_batch_array: '6197090',
      name_search: 'ASAALBUS',
      name: "Aster alpinus 'Albus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1272,
      chnn_stock_retail: 1272,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LCE6WVRZ',
      statusContent: 'dot',
      hash: 'SYXGRLKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYXGRLKR'
    },
    {
      status: 210,
      sku: 'PM0079283',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350375',
      rtl_batch_array: '6369736, 6350375, 6374020',
      name_search: 'HEBSMOOT',
      name: "Heuchera 'Berry Smoothie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 866,
      chnn_stock_retail: 1447,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CHYBT9D3;BE4E3V7N;V6VPHP6R;VPHGPLGV;KDCB3JZD;N7K9S1E1;BRB4D198;2WS8VYH2',
      statusContent: 'dot',
      hash: '4JBGS53J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JBGS53J'
    },
    {
      status: 210,
      sku: 'PM0067190',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177910',
      rtl_batch_array: '6177910',
      name_search: 'SANSEREN',
      name: "Sambucus nigra 'Serenade'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 3099,
      chnn_stock_retail: 3099,
      sppl_prcp: 6.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GT7B2AP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT7B2AP4'
    },
    {
      status: 210,
      sku: 'PM0079284',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350376',
      rtl_batch_array: '6350376, 6338938, 6348990',
      name_search: 'HEBINOCH',
      name: "Heuchera 'Binoche'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1568,
      chnn_stock_retail: 2413,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '971RWGWK',
      statusContent: 'dot',
      hash: '1GY4TPYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GY4TPYG'
    },
    {
      status: 210,
      sku: 'PM0079302',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350476',
      rtl_batch_array: '6350476',
      name_search: 'HEPREVOL',
      name: 'Heucherella Pink Revolution',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1496,
      chnn_stock_retail: 1496,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YERZ5ZWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YERZ5ZWP'
    },
    {
      status: 210,
      sku: 'PM0079303',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350479',
      rtl_batch_array: '6350479',
      name_search: 'HEUSECLI',
      name: "Heucherella 'Solar Eclipse'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 560,
      chnn_stock_retail: 560,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RPAXDWN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPAXDWN4'
    },
    {
      status: 210,
      sku: 'PM0079304',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350481',
      rtl_batch_array: '6350481',
      name_search: 'HESFALL',
      name: "Heucherella hybrid 'Sunrise Fall'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VXN25AE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXN25AE7'
    },
    {
      status: 210,
      sku: 'PM0059597',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5231063',
      rtl_batch_array: '5231063',
      name_search: 'EUJKATHY',
      name: "Euonymus japonicus 'Kathy'",
      sppl_size_code: '035040C5',
      rng_range_identifier: 'H035040C5',
      rng_range_description: 'H35 cm 40 cm C5',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7AYYBDZ9;PJ1WE347;71RR6VTP;4ZS6JS6R;WNW9N6BN;6RG9HR69;B81979LC;2PB1Y5J8;C95PJGX2;V71HT66P;RXA9CAYR;ERCT8HPH;6RK2JS7T;SS2T58C4;LDYV8ZYT;1HSLXJ6Z;N5JDJ4LT',
      imageBatch: 'ERNYVNWX',
      statusContent: 'dot',
      hash: 'KBCEDETW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBCEDETW'
    },
    {
      status: 210,
      sku: 'PM0068319',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238467',
      rtl_batch_array: '6238467',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1454,
      chnn_stock_retail: 1454,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: 'R24GKPGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R24GKPGV'
    },
    {
      status: 210,
      sku: 'PM0079305',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350492',
      rtl_batch_array: '6350492',
      name_search: 'HOBMAMA',
      name: "Hosta 'Big Mama'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '24CD91XV;C18YSCBH',
      statusContent: 'dot',
      hash: '8N8D7WEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8N8D7WEL'
    },
    {
      status: 210,
      sku: 'PM0079306',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350496',
      rtl_batch_array: '6350496',
      name_search: 'HOCFESTI',
      name: "Hosta 'Color Festival'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SW4RGLS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW4RGLS5'
    },
    {
      status: 210,
      sku: 'PM0059714',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301670',
      rtl_batch_array: '6301670',
      name_search: 'JALAEVIS',
      name: 'Jasione laevis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ED4NAYNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED4NAYNJ'
    },
    {
      status: 210,
      sku: 'PM0069785',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353548',
      rtl_batch_array: '6353548',
      name_search: 'VENOVEBO',
      name: 'Vernonia noveboracensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 439,
      chnn_stock_retail: 439,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5LPCTK3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LPCTK3L'
    },
    {
      status: 210,
      sku: 'PM0069787',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308678',
      rtl_batch_array: '6217232, 6308678',
      name_search: 'VEMFLOVE',
      name: 'Veronica media First Love',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 536,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JXGBWB4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXGBWB4Z'
    },
    {
      status: 210,
      sku: 'PM0069797',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353575',
      rtl_batch_array: '6353575, 6352678',
      name_search: 'VEVERIKA',
      name: "Veronicastrum virginicum 'Erika'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 917,
      chnn_stock_retail: 1009,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A31PT5KE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A31PT5KE'
    },
    {
      status: 910,
      sku: 'PM0038534',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083854',
      rtl_batch_array: '6083854',
      name_search: 'DCKIESER',
      name: 'DCM Kieseriet',
      sppl_size_code: '02KGDOOS',
      rng_range_identifier: 'BOX2KG',
      rng_range_description: 'Box 2 Kilogram',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 4.69,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'LGN4VGGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'LGN4VGGN'
    },
    {
      status: 910,
      sku: 'PM0038331',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083864',
      rtl_batch_array: '6083864',
      name_search: 'DCMSIERT',
      name: 'DCM Meststof Siertuin',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'TZWGGAGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'TZWGGAGA'
    },
    {
      status: 210,
      sku: 'PM0079307',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350516',
      rtl_batch_array: '6350516',
      name_search: 'HONEXPOS',
      name: "Hosta 'Northern Exposure'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHEPV6N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHEPV6N7'
    },
    {
      status: 810,
      sku: 'PM0081976',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349246',
      rtl_batch_array: '6349246',
      name_search: 'CLAABLOS',
      name: "Clematis armandii 'Apple Blossom'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KH64KE97;JJTG5491;RNB16E8D',
      statusContent: 'dot',
      hash: 'S5KZWPKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5KZWPKB'
    },
    {
      status: 810,
      sku: 'PM0081977',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349274',
      rtl_batch_array: '6349274',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: 'K3TPJ52B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3TPJ52B'
    },
    {
      status: 210,
      sku: 'PM0079309',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350530',
      rtl_batch_array: '6350530',
      name_search: 'HOSGLASS',
      name: "Hosta 'Stained Glass'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 306,
      chnn_stock_retail: 306,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSXTTW3V',
      statusContent: 'dot',
      hash: 'N6DEZJ6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6DEZJ6A'
    },
    {
      status: 210,
      sku: 'PM0079311',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350534',
      rtl_batch_array: '6350534',
      name_search: 'HOTWILIG',
      name: "Hosta 'Twilight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 815,
      chnn_stock_retail: 815,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5X1DPZL;VT8KWPHT;G4A5XS4Z',
      statusContent: 'dot',
      hash: 'W83ZHBY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W83ZHBY4'
    },
    {
      status: 210,
      sku: 'PM0079312',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350537',
      rtl_batch_array: '6350537',
      name_search: 'HOWHIRLW',
      name: "Hosta 'Whirlwind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2RXAPXZD;XGW2SJZG;2NWXXDDW;G5Y5W2HL',
      statusContent: 'dot',
      hash: '6RH38RHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RH38RHS'
    },
    {
      status: 810,
      sku: 'PM0081978',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349296',
      rtl_batch_array: '6349296',
      name_search: 'PHOAJUBI',
      name: 'Physocarpus opulifolius Amber Jubilee',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1WZ9ALB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1WZ9ALB'
    },
    {
      status: 210,
      sku: 'PM0085564',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385526',
      rtl_batch_array: '6385526',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_prcp: 4.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'P6JWWH3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6JWWH3S'
    },
    {
      status: 810,
      sku: 'PM0079314',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353086',
      rtl_batch_array: '6353086',
      name_search: 'PEHIRSUT',
      name: 'Penstemon hirsutus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AV998RGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AV998RGT'
    },
    {
      status: 810,
      sku: 'PM0085565',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385527',
      rtl_batch_array: '6385527',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: 'JZCYR2KD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZCYR2KD'
    },
    {
      status: 810,
      sku: 'PM0085566',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385528',
      rtl_batch_array: '6385528',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: 'Y81XKDLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y81XKDLA'
    },
    {
      status: 210,
      sku: 'PM0067311',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181315',
      rtl_batch_array: '6181315',
      name_search: 'POFGOLDS',
      name: "Potentilla fruticosa 'Goldstar'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JA157984',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JA157984'
    },
    {
      status: 910,
      sku: 'PM0071319',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'JJZBW1DH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JJZBW1DH'
    },
    {
      status: 210,
      sku: 'PM0064873',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287644',
      rtl_batch_array: '6287644',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: '9WNS38JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WNS38JH'
    },
    {
      status: 210,
      sku: 'PM0067321',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338513',
      rtl_batch_array: '6181327, 6338513',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1558,
      chnn_stock_retail: 1808,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: '8NGJDAL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NGJDAL5'
    },
    {
      status: 810,
      sku: 'PM0081979',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349325',
      rtl_batch_array: '6349325',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 8.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'VV6VCG5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VV6VCG5G'
    },
    {
      status: 210,
      sku: 'PM0085567',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385531',
      rtl_batch_array: '6385531',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 5.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: '9BJKCRGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BJKCRGW'
    },
    {
      status: 810,
      sku: 'PM0081980',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349326',
      rtl_batch_array: '6349326',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '060070C74',
      rng_range_identifier: 'H060070C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'S2973TVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2973TVB'
    },
    {
      status: 210,
      sku: 'PM0069233',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289992',
      rtl_batch_array: '6289992',
      name_search: 'AQVWDRWH',
      name: "Aquilegia vulgaris 'Winky Double Rose-White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 722,
      chnn_stock_retail: 722,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H446KKAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H446KKAD'
    },
    {
      status: 210,
      sku: 'PM0053373',
      core_name: 'Plant',
      sppl_ean: '8720664680159',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053949',
      rtl_batch_array: '6053949',
      name_search: 'EDI67974',
      name: "Allium 'Universe'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 3.583,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y6VG6G58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6VG6G58'
    },
    {
      status: 210,
      sku: 'PM0053309',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333369',
      rtl_batch_array: '6317831, 6307950, 6333369',
      name_search: 'ASLCARLO',
      name: "Aster 'Little Carlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 872,
      chnn_stock_retail: 2976,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YS6PHAG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS6PHAG9'
    },
    {
      status: 210,
      sku: 'PM0067267',
      core_name: 'Plant',
      sppl_ean: '8720349477449',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5953620',
      rtl_batch_array: '5953620',
      name_search: 'GERRPRIC',
      name: "Geranium riversleaianum 'Russell Prichard'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4053,
      chnn_stock_retail: 4053,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y97WY3BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y97WY3BH'
    },
    {
      status: 210,
      sku: 'PM0074309',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269609',
      rtl_batch_array: '6349579, 6269609, 6345192',
      name_search: 'ASMTWILI',
      name: "Aster macrophyllus 'Twilight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1361,
      chnn_stock_retail: 2217,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T336PBN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T336PBN5'
    },
    {
      status: 810,
      sku: 'PM0081981',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6283818',
      rtl_batch_array: '6349332, 6283818',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 63,
      chnn_stock_retail: 113,
      sppl_prcp: 14.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'BG9R3G54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BG9R3G54'
    },
    {
      status: 810,
      sku: 'PM0081982',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349333',
      rtl_batch_array: '6349333',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PGE6H29J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGE6H29J'
    },
    {
      status: 810,
      sku: 'PM0081983',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349334',
      rtl_batch_array: '6349334',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'JRJBZVSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRJBZVSB'
    },
    {
      status: 810,
      sku: 'PM0081984',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349335',
      rtl_batch_array: '6349335',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.112,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'SX942DLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SX942DLW'
    },
    {
      status: 810,
      sku: 'PM0081985',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349336',
      rtl_batch_array: '6349336',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.112,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'BAZ37D8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAZ37D8H'
    },
    {
      status: 810,
      sku: 'PM0081986',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349337',
      rtl_batch_array: '6349337',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.047,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'P8P69CDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8P69CDP'
    },
    {
      status: 810,
      sku: 'PM0081987',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349338',
      rtl_batch_array: '6349338',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.082,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'AXG5VVR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXG5VVR2'
    },
    {
      status: 810,
      sku: 'PM0081988',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349339',
      rtl_batch_array: '6349339',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.617,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'X3G9LJ26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3G9LJ26'
    },
    {
      status: 810,
      sku: 'PM0081989',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349340',
      rtl_batch_array: '6349340',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: 'XSWYS46L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSWYS46L'
    },
    {
      status: 810,
      sku: 'PM0081990',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349341',
      rtl_batch_array: '6349341',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.617,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'ZY2D1ETA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY2D1ETA'
    },
    {
      status: 210,
      sku: 'PM0079308',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350529',
      rtl_batch_array: '6350529',
      name_search: 'HOSEYES',
      name: "Hosta 'Snake Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D5E3A52T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5E3A52T'
    },
    {
      status: 210,
      sku: 'PM0079310',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350533',
      rtl_batch_array: '6350533',
      name_search: 'HOTREX',
      name: "Hosta 'T Rex'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 302,
      chnn_stock_retail: 302,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT637ARW',
      statusContent: 'dot',
      hash: 'XSY1J4EG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSY1J4EG'
    },
    {
      status: 810,
      sku: 'PM0057868',
      core_name: 'Plant',
      sppl_ean: '8720664861503',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6099389',
      rtl_batch_array: '6099389',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 5.014,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: '47XHA71W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47XHA71W'
    },
    {
      status: 810,
      sku: 'PM0081991',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349342',
      rtl_batch_array: '6349342',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.112,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'SPSJGR59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPSJGR59'
    },
    {
      status: 810,
      sku: 'PM0081992',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349343',
      rtl_batch_array: '6349343',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.112,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: 'SED4NCXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SED4NCXT'
    },
    {
      status: 810,
      sku: 'PM0081993',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349344',
      rtl_batch_array: '6349344',
      name_search: 'HYQPWEE',
      name: "Hydrangea quercifolia 'Pee Wee'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '669KPLH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '669KPLH1'
    },
    {
      status: 810,
      sku: 'PM0081994',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349350',
      rtl_batch_array: '6349350',
      name_search: 'ILCRENAT',
      name: 'Ilex crenata',
      sppl_size_code: '040045COBOL',
      rng_range_identifier: 'BALL\u00d8045H040050',
      rng_range_description: 'Ball \u00d8 45 cm H40 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.595,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZPHE4YX;G6GNL9HW;DEDHVPLC;S98DZ16D;JN9WPG9R;SSPZHTYG;8VTEEA4Z;8EVJD56A;62HCWZTE;L8L556GA;JG8E3W83;CTW46TT6;DLVBKSXX;J8P1G7W7;SNLD6864',
      statusContent: 'dot',
      hash: '775B5C6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '775B5C6K'
    },
    {
      status: 210,
      sku: 'PM0079317',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348410',
      rtl_batch_array: '6353235, 6348410',
      name_search: 'PRELATIO',
      name: 'Primula elatior',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 481,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1WH19J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1WH19J8'
    },
    {
      status: 210,
      sku: 'PM0079318',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353365',
      rtl_batch_array: '6353365',
      name_search: 'SCSTZONE',
      name: "Schizachyrium sc. 'Twilight Zone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 236,
      chnn_stock_retail: 236,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V8WJE5AN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8WJE5AN'
    },
    {
      status: 210,
      sku: 'PM0066963',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353145',
      rtl_batch_array: '6353145',
      name_search: 'PEARGEM',
      name: "Persicaria amplexicaulis 'Rowden Gem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A29YJJLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A29YJJLA'
    },
    {
      status: 810,
      sku: 'PM0069809',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301463',
      rtl_batch_array: '6301463',
      name_search: 'STMROSEA',
      name: "Stachys monieri 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TZCBAPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TZCBAPR'
    },
    {
      status: 910,
      sku: 'PM0071329',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: '121J4C39',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '121J4C39'
    },
    {
      status: 810,
      sku: 'PM0081995',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349351',
      rtl_batch_array: '6349351',
      name_search: 'ILCRENAT',
      name: 'Ilex crenata',
      sppl_size_code: '045050COBOL',
      rng_range_identifier: 'BALL\u00d8050H045050',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.076,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZPHE4YX;G6GNL9HW;DEDHVPLC;S98DZ16D;JN9WPG9R;SSPZHTYG;8VTEEA4Z;8EVJD56A;62HCWZTE;L8L556GA;JG8E3W83;CTW46TT6;DLVBKSXX;J8P1G7W7;SNLD6864',
      statusContent: 'dot',
      hash: 'T6HLWYGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6HLWYGY'
    },
    {
      status: 210,
      sku: 'PM0069820',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348995',
      rtl_batch_array: '6353379, 6320896, 6348995',
      name_search: 'THBSTOCK',
      name: "Thalictrum 'Black Stockings'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 2191,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X4AZS15E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4AZS15E'
    },
    {
      status: 810,
      sku: 'PM0069835',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353510',
      rtl_batch_array: '6353510',
      name_search: 'TRPCHABL',
      name: "Tradescantia 'Pink Chablis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9RH44DL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RH44DL4'
    },
    {
      status: 810,
      sku: 'PM0057916',
      core_name: 'Plant',
      sppl_ean: '8720664883475',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6101692',
      rtl_batch_array: '6101692',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'R64VZVXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R64VZVXE'
    },
    {
      status: 210,
      sku: 'PM0069836',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353518',
      rtl_batch_array: '6353518',
      name_search: 'TRFGEDGE',
      name: "Tricyrtis formosana 'Gilt Edge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YJA12WCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJA12WCX'
    },
    {
      status: 910,
      sku: 'PM0067315',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFPBEAU',
      name: "Potentilla fruticosa 'Primrose Beauty'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      imageCore: 'R5HYGEX5;PNXB7VNR;L9L6LZJ1;GAL36RY2;G71E12H6;Y4X3Y5LG',
      statusContent: 'dot',
      hash: 'ZL18EKJ8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZL18EKJ8'
    },
    {
      status: 810,
      sku: 'PM0081996',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349352',
      rtl_batch_array: '6349352',
      name_search: 'ILCRENAT',
      name: 'Ilex crenata',
      sppl_size_code: '060COBOL',
      rng_range_identifier: 'BALL\u00d8060H060060',
      rng_range_description: 'Ball \u00d8 60 cm H60 cm 60 cm',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 50.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZPHE4YX;G6GNL9HW;DEDHVPLC;S98DZ16D;JN9WPG9R;SSPZHTYG;8VTEEA4Z;8EVJD56A;62HCWZTE;L8L556GA;JG8E3W83;CTW46TT6;DLVBKSXX;J8P1G7W7;SNLD6864',
      statusContent: 'dot',
      hash: 'T983WHKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T983WHKY'
    },
    {
      status: 210,
      sku: 'PM0081997',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369379',
      rtl_batch_array: '6349353, 6369379',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 800,
      sppl_prcp: 2.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'K6RE6W8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6RE6W8P'
    },
    {
      status: 810,
      sku: 'PM0081998',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349354',
      rtl_batch_array: '6349354',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '040045COBOL',
      rng_range_identifier: 'BALL\u00d8045H040050',
      rng_range_description: 'Ball \u00d8 45 cm H40 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'G1G6VVBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1G6VVBJ'
    },
    {
      status: 810,
      sku: 'PM0081999',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349355',
      rtl_batch_array: '6349355',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '045050COBOL',
      rng_range_identifier: 'BALL\u00d8050H045050',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.026,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '7A59C2AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7A59C2AK'
    },
    {
      status: 810,
      sku: 'PM0032164',
      core_name: 'Plant',
      sppl_ean: '8720664694620',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5819004',
      rtl_batch_array: '5819004',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      sppl_size_code: '080COBOL',
      rng_range_identifier: 'BALL\u00d8080H080080',
      rng_range_description: 'Ball \u00d8 80 cm H80 cm 80 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 85.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: '4GE8XPL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GE8XPL3'
    },
    {
      status: 810,
      sku: 'PM0082000',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349356',
      rtl_batch_array: '6349356',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '055COBOL',
      rng_range_identifier: 'BALL\u00d8055H055055',
      rng_range_description: 'Ball \u00d8 55 cm H55 cm 55 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 52.642,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '14TAW97Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14TAW97Y'
    },
    {
      status: 210,
      sku: 'PM0067319',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181324',
      rtl_batch_array: '6181324',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: '2SKSGYH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SKSGYH7'
    },
    {
      status: 810,
      sku: 'PM0082001',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349357',
      rtl_batch_array: '6349357',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '060COBOL',
      rng_range_identifier: 'BALL\u00d8060H060060',
      rng_range_description: 'Ball \u00d8 60 cm H60 cm 60 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 60.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '2CVHNSX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CVHNSX1'
    },
    {
      status: 210,
      sku: 'PM0025129',
      core_name: 'Plant',
      sppl_ean: '8720349410453',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '4632240',
      rtl_batch_array: '4632240',
      name_search: 'BERCORDI',
      name: 'Bergenia cordifolia',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2527,
      chnn_stock_retail: 2527,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BCHCA61D;86AW2X93;R3NLXX6Z;8EZWEKKH;EYBADXT8;G6N3LKTD',
      imageBatch: 'ELXZRJWV',
      statusContent: 'dot',
      hash: 'EJ2B15C5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ2B15C5'
    },
    {
      status: 810,
      sku: 'PM0082002',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349358',
      rtl_batch_array: '6349358',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '070COBOL',
      rng_range_identifier: 'BALL\u00d8070H070070',
      rng_range_description: 'Ball \u00d8 70 cm H70 cm 70 cm',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 70.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '1YHYJNWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YHYJNWV'
    },
    {
      status: 810,
      sku: 'PM0082003',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349359',
      rtl_batch_array: '6349359',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '080COBOL',
      rng_range_identifier: 'BALL\u00d8080H080080',
      rng_range_description: 'Ball \u00d8 80 cm H80 cm 80 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 85.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '9T21X8KD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T21X8KD'
    },
    {
      status: 810,
      sku: 'PM0082004',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349360',
      rtl_batch_array: '6349360',
      name_search: 'ILCGDWAR',
      name: "Ilex crenata 'Glorie Dwarf'",
      sppl_size_code: '045050COBOL',
      rng_range_identifier: 'BALL\u00d8050H045050',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 47.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K5L83G7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5L83G7Z'
    },
    {
      status: 810,
      sku: 'PM0082005',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349361',
      rtl_batch_array: '6349361',
      name_search: 'ILCGDWAR',
      name: "Ilex crenata 'Glorie Dwarf'",
      sppl_size_code: '060COBOL',
      rng_range_identifier: 'BALL\u00d8060H060060',
      rng_range_description: 'Ball \u00d8 60 cm H60 cm 60 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 60.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NLBX13PJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLBX13PJ'
    },
    {
      status: 810,
      sku: 'PM0082006',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349362',
      rtl_batch_array: '6349362',
      name_search: 'ILCGDWAR',
      name: "Ilex crenata 'Glorie Dwarf'",
      sppl_size_code: '070COBOL',
      rng_range_identifier: 'BALL\u00d8070H070070',
      rng_range_description: 'Ball \u00d8 70 cm H70 cm 70 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 75.019,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KWVX9RDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWVX9RDV'
    },
    {
      status: 210,
      sku: 'PM0031220',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6300836',
      rtl_batch_array: '5838092, 6356122, 6300836',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 13820,
      chnn_stock_retail: 31820,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'LZ3NGE9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ3NGE9B'
    },
    {
      status: 210,
      sku: 'PM0067333',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5356502',
      rtl_batch_array: '5356502',
      name_search: 'EUJKATHY',
      name: "Euonymus japonicus 'Kathy'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 11.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7AYYBDZ9;PJ1WE347;71RR6VTP;4ZS6JS6R;WNW9N6BN;6RG9HR69;B81979LC;2PB1Y5J8;C95PJGX2;V71HT66P;RXA9CAYR;ERCT8HPH;6RK2JS7T;SS2T58C4;LDYV8ZYT;1HSLXJ6Z;N5JDJ4LT',
      imageBatch: 'EAZWN27Y',
      statusContent: 'dot',
      hash: '2A1L7V28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A1L7V28'
    },
    {
      status: 810,
      sku: 'PM0082007',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349363',
      rtl_batch_array: '6349363',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '025COBOL',
      rng_range_identifier: 'BALL\u00d8025H025025',
      rng_range_description: 'Ball \u00d8 25 cm H25 cm 25 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXPNA436',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXPNA436'
    },
    {
      status: 210,
      sku: 'PM0067316',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181321',
      rtl_batch_array: '6181321',
      name_search: 'POFSOMME',
      name: "Potentilla fruticosa 'Sommerflor'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJEBSAWR;R44DTY7H;KXCK8TV2;SL8GRTT6;PLR7VDJL',
      statusContent: 'dot',
      hash: '7W87DPHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W87DPHB'
    },
    {
      status: 810,
      sku: 'PM0082008',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349364',
      rtl_batch_array: '6349364',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '030C74BOL',
      rng_range_identifier: 'BALL\u00d8030H030030C7.5',
      rng_range_description: 'Ball \u00d8 30 cm H30 cm 30 cm C7.5',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XX3Y48CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX3Y48CX'
    },
    {
      status: 210,
      sku: 'PM0053347',
      core_name: 'Plant',
      sppl_ean: '8720664690264',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053861',
      rtl_batch_array: '6053861',
      name_search: 'FRVPLENA',
      name: "Fragaria vesca 'Plena'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXT98V2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXT98V2N'
    },
    {
      status: 990,
      sku: 'PM0059684',
      core_name: 'Plant',
      sppl_ean: '8720664869851',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6117690',
      rtl_batch_array: '6117690',
      name_search: 'IRIMMORT',
      name: "Iris (G) 'Immortality'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 0,
      chnn_stock_retail: 0,
      sppl_order_minimum: 1,
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7X3S6TNG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7X3S6TNG'
    },
    {
      status: 810,
      sku: 'PM0082009',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349365',
      rtl_batch_array: '6349365',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '035COBOL',
      rng_range_identifier: 'BALL\u00d8035H035035',
      rng_range_description: 'Ball \u00d8 35 cm H35 cm 35 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2B3SNC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2B3SNC8'
    },
    {
      status: 210,
      sku: 'PM0067317',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181322',
      rtl_batch_array: '6181322',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: '9LTVBDNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LTVBDNG'
    },
    {
      status: 210,
      sku: 'PM0067314',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181319',
      rtl_batch_array: '6181319',
      name_search: 'POFLIMEL',
      name: "Potentilla fruticosa 'Limelight'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7C78VJ2T;BVG6EN1S;5725WCYW',
      statusContent: 'dot',
      hash: 'EDGZR3T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDGZR3T7'
    },
    {
      status: 810,
      sku: 'PM0082010',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349366',
      rtl_batch_array: '6349366',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '055COBOL',
      rng_range_identifier: 'BALL\u00d8055H055055',
      rng_range_description: 'Ball \u00d8 55 cm H55 cm 55 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 52.642,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3NRRS13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3NRRS13'
    },
    {
      status: 210,
      sku: 'PM0067332',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314264',
      rtl_batch_array: '5329085, 6314264',
      name_search: 'OSHGOSHI',
      name: "Osmanthus heterophyllus 'Goshiki'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 930,
      chnn_stock_retail: 1430,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7DT7HZTW;H3611KS5;HHWLDZG5;C4LVH8XZ;P61R6DLC;WVR7T9BV;YD81PWH8;VX335SLL;CNKTN4N8;GX5HBXJ9;NLYT7ZPN;26RLBC7W;D5PTRYSY;Y6LGAB3K;TKLEDCZP;XG83C181;CGDSPS5B;DC98HZPC',
      statusContent: 'dot',
      hash: 'V1TN3HYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1TN3HYE'
    },
    {
      status: 810,
      sku: 'PM0082011',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349367',
      rtl_batch_array: '6349367',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '080COBOL',
      rng_range_identifier: 'BALL\u00d8080H080080',
      rng_range_description: 'Ball \u00d8 80 cm H80 cm 80 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 90.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6CH93D8X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CH93D8X'
    },
    {
      status: 810,
      sku: 'PM0082012',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349377',
      rtl_batch_array: '6349377',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.595,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: 'PXHJSRCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXHJSRCT'
    },
    {
      status: 210,
      sku: 'PM0066851',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370339',
      rtl_batch_array: '6370339',
      name_search: 'CHOBDREA',
      name: "Chaenorhinum origanifolium 'Blue Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XCRGYK67;A3AHPWDL;R34EEXK3;9WJP5BCD;84KH1X2T;5EBD2LNR;643JXCZG;H5C2P2JX;NRNJEPXZ;T89SGCBT;CH7J85JB;ZG2VDZ3D;H8XXCRBV;BWC3YP53;17NEPG1E;R6HBG9X6',
      statusContent: 'dot',
      hash: 'G7DGDE6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7DGDE6W'
    },
    {
      status: 810,
      sku: 'PM0082013',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349378',
      rtl_batch_array: '6349378, 6283833',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 104,
      sppl_prcp: 13.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: 'VZ816CRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ816CRK'
    },
    {
      status: 810,
      sku: 'PM0082014',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349379',
      rtl_batch_array: '6349379',
      name_search: 'LOPURPUS',
      name: 'Lonicera purpusii',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JV1RDPTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV1RDPTA'
    },
    {
      status: 810,
      sku: 'PM0082015',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349397',
      rtl_batch_array: '6349397',
      name_search: 'PAPPSPIR',
      name: 'Parrotia persica Persian Spire',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC91A6XY;XR1JSY4C;WRLSCDV9;4CJJKXYX;WZGPTPVX',
      statusContent: 'dot',
      hash: 'B1BPVJEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1BPVJEX'
    },
    {
      status: 810,
      sku: 'PM0082016',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349403',
      rtl_batch_array: '6349403',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'K6GDGXSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6GDGXSE'
    },
    {
      status: 810,
      sku: 'PM0082017',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349404',
      rtl_batch_array: '6349404',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'G29354E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G29354E5'
    },
    {
      status: 810,
      sku: 'PM0082018',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349405',
      rtl_batch_array: '6349405',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.537,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'V7CB5292',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7CB5292'
    },
    {
      status: 910,
      sku: 'PM0054551',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: 'NY6N8RVP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NY6N8RVP'
    },
    {
      status: 810,
      sku: 'PM0067328',
      core_name: 'Plant',
      sppl_ean: '8720349413362',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5206979',
      rtl_batch_array: '5206979',
      name_search: 'CHTSUNDA',
      name: 'Choisya ternata Sundance',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_prcp: 4.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V42YL5RC;JRCE449K;XYRHEPZN;HXDXG4H2;L9Z5895X;JYVD7ZN6;KKWV491Z;9XHSEVLD;59ZX6N6T;6EHZS8RS;BSE13AEH;T399A1TP;PNN3V7YY;R25ZGNYL;NT63J78C;SN359S4T;DE4A48GH;JCW58RHJ;GN1JY58L;A2NE21ED;TZZATBVK;ZK7BGDRG;JC8HHA2W;PSTA47GK',
      statusContent: 'dot',
      hash: 'JAW32WLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAW32WLL'
    },
    {
      status: 910,
      sku: 'PM0066955',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAOBOLER',
      name: "Papaver orientale 'Bolero'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'E9L7T281;7K9EJ91V',
      statusContent: 'dot',
      hash: 'S4ASR3SB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S4ASR3SB'
    },
    {
      status: 810,
      sku: 'PM0082019',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349406',
      rtl_batch_array: '6349406',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 23.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'J6T338BP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6T338BP'
    },
    {
      status: 810,
      sku: 'PM0082020',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349407',
      rtl_batch_array: '6349407',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.587,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'ZANXPNRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZANXPNRX'
    },
    {
      status: 810,
      sku: 'PM0082021',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349408',
      rtl_batch_array: '6349408',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.537,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'K2CD6TTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2CD6TTP'
    },
    {
      status: 810,
      sku: 'PM0082022',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349409',
      rtl_batch_array: '6349409',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 23.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'JT4SZ7HK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT4SZ7HK'
    },
    {
      status: 810,
      sku: 'PM0082023',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349412',
      rtl_batch_array: '6349412',
      name_search: 'SARACEMO',
      name: 'Sambucus racemosa',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6XGCDG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6XGCDG3'
    },
    {
      status: 810,
      sku: 'PM0082024',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349425',
      rtl_batch_array: '6349425',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'LJL2JTJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJL2JTJJ'
    },
    {
      status: 810,
      sku: 'PM0082025',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349426',
      rtl_batch_array: '6349426',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'PTJ3TL7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTJ3TL7V'
    },
    {
      status: 210,
      sku: 'PM0053316',
      core_name: 'Plant',
      sppl_ean: '8720664685468',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053816',
      rtl_batch_array: '6053816',
      name_search: 'COLGOLDT',
      name: "Coreopsis lanceolata 'Goldteppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LRBBLWVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRBBLWVY'
    },
    {
      status: 810,
      sku: 'PM0082026',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349427',
      rtl_batch_array: '6349427',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'LCCNJTWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCCNJTWD'
    },
    {
      status: 810,
      sku: 'PM0082027',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349428',
      rtl_batch_array: '6349428',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'KHRJJWJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHRJJWJ1'
    },
    {
      status: 810,
      sku: 'PM0082028',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349429',
      rtl_batch_array: '6349429',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.642,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: '44EDV5H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44EDV5H8'
    },
    {
      status: 810,
      sku: 'PM0082029',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349430',
      rtl_batch_array: '6349430',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 19.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'S73JE91L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S73JE91L'
    },
    {
      status: 810,
      sku: 'PM0082030',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349437',
      rtl_batch_array: '6349437',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'Z4H354EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4H354EC'
    },
    {
      status: 810,
      sku: 'PM0082031',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349438',
      rtl_batch_array: '6349438',
      name_search: 'ALJCFOUN',
      name: 'Albizia julibrissin Chocolate Fountain',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.435,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZWW95DL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWW95DL3'
    },
    {
      status: 810,
      sku: 'PM0082032',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349439',
      rtl_batch_array: '6349439',
      name_search: 'ALJCFOUN',
      name: 'Albizia julibrissin Chocolate Fountain',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.012,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '953B7LCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '953B7LCK'
    },
    {
      status: 810,
      sku: 'PM0082033',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349440',
      rtl_batch_array: '6349440',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '120140C74',
      rng_range_identifier: 'H120140C7.5',
      rng_range_description: 'H120 cm 140 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'NHYS7AS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHYS7AS3'
    },
    {
      status: 810,
      sku: 'PM0082034',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349441',
      rtl_batch_array: '6349441',
      name_search: 'CLTTANGU',
      name: 'Clematis tibetana tangutica',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V78AZ85R;C43CDZND;9D5CNZTY;YG4155K1;B2H6WENR',
      statusContent: 'dot',
      hash: 'JDXWZ3WS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDXWZ3WS'
    },
    {
      status: 810,
      sku: 'PM0082035',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349442',
      rtl_batch_array: '6349442',
      name_search: 'COHRIESE',
      name: "Corylus 'Halle'sche Riesen'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EWCV883Y',
      statusContent: 'dot',
      hash: 'ST1TDHX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST1TDHX2'
    },
    {
      status: 810,
      sku: 'PM0082036',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349443',
      rtl_batch_array: '6349443',
      name_search: 'COMPURPU',
      name: "Corylus maxima 'Purpurea'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.062,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GSSG2XKA;R5952436;HTCB8A75;CHBBTL6W;KJY5DA67;6EJJTVSJ;D18NH15Y;YHHPD2XC;9X13B88A;ZKX3KBZP;X547KTWE;W7D5X9GZ;YYGB3NR8;8HDGZDS2',
      statusContent: 'dot',
      hash: '7TPB8NPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TPB8NPP'
    },
    {
      status: 810,
      sku: 'PM0082037',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349444',
      rtl_batch_array: '6349444',
      name_search: 'COMPURPU',
      name: "Corylus maxima 'Purpurea'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GSSG2XKA;R5952436;HTCB8A75;CHBBTL6W;KJY5DA67;6EJJTVSJ;D18NH15Y;YHHPD2XC;9X13B88A;ZKX3KBZP;X547KTWE;W7D5X9GZ;YYGB3NR8;8HDGZDS2',
      statusContent: 'dot',
      hash: 'Y56PLXGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y56PLXGK'
    },
    {
      status: 810,
      sku: 'PM0082038',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349448',
      rtl_batch_array: '6349448',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: 'V3T5Z576',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3T5Z576'
    },
    {
      status: 810,
      sku: 'PM0082039',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349450',
      rtl_batch_array: '6349450',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 15.072,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: '96KDPY2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96KDPY2L'
    },
    {
      status: 910,
      sku: 'PM0071335',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'GR8HZXW1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GR8HZXW1'
    },
    {
      status: 210,
      sku: 'PM0069848',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289981',
      rtl_batch_array: '6289981',
      name_search: 'ACCBICOL',
      name: "Aconitum cammarum 'Bicolor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KXEZB7ZD',
      statusContent: 'dot',
      hash: 'AXY2SSWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXY2SSWB'
    },
    {
      status: 810,
      sku: 'PM0053398',
      core_name: 'Plant',
      sppl_ean: '8720664680296',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054324',
      rtl_batch_array: '6054324',
      name_search: 'ALHVALBU',
      name: 'Allium hirtifolium var. album',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_order_minimum: 3,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8DBZPEPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DBZPEPH'
    },
    {
      status: 910,
      sku: 'PM0079320',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RHGORANG',
      name: 'Rhododendron (AJ) Geisha Orange',
      rng_range_identifier: 'H025030C4',
      statusContent: 'dot',
      hash: 'H36RK5XG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H36RK5XG'
    },
    {
      status: 210,
      sku: 'PM0079321',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317475',
      rtl_batch_array: '6317475',
      name_search: 'RHAFRANK',
      name: "Rhododendron (AJ) 'Anne Frank'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_prcp: 3.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NZ2563WD;WC66NCS1;8K51NLEN;78ABCJ3H;TAD68SRA',
      statusContent: 'dot',
      hash: 'ZK9LTKDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK9LTKDP'
    },
    {
      status: 210,
      sku: 'PM0067344',
      core_name: 'Plant',
      sppl_ean: '8720664509603',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420751',
      rtl_batch_array: '5420751',
      name_search: 'CLAUREOL',
      name: "Clematis 'Aureolin'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 394,
      chnn_stock_retail: 394,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '428EXC9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '428EXC9Y'
    },
    {
      status: 810,
      sku: 'PM0082040',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349451',
      rtl_batch_array: '6349451',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: 'Y2CT6A23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2CT6A23'
    },
    {
      status: 210,
      sku: 'PM0079322',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317476',
      rtl_batch_array: '6317476',
      name_search: 'RHFUMIKO',
      name: "Rhododendron (AJ) 'Fumiko'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 3.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8B2BWJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8B2BWJ9'
    },
    {
      status: 210,
      sku: 'PM0079323',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317478',
      rtl_batch_array: '6317478',
      name_search: 'PEABBEAU',
      name: "Pennisetum alopecuroides 'Black Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2586,
      chnn_stock_retail: 2586,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2SVSXB1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SVSXB1V'
    },
    {
      status: 210,
      sku: 'PM0079324',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317479',
      rtl_batch_array: '6317479',
      name_search: 'PEOFLAMI',
      name: "Pennisetum orientale 'Flamingo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2840,
      chnn_stock_retail: 2840,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8YP8PSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8YP8PSS'
    },
    {
      status: 210,
      sku: 'PM0079326',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317481',
      rtl_batch_array: '6317481',
      name_search: 'RUFCGARD',
      name: "Rudbeckia fulgida 'City Garden'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 847,
      chnn_stock_retail: 847,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC2CLL85',
      statusContent: 'dot',
      hash: 'GDL88Y61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDL88Y61'
    },
    {
      status: 810,
      sku: 'PM0082041',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349452',
      rtl_batch_array: '6349452',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: '6LJ55EBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LJ55EBN'
    },
    {
      status: 210,
      sku: 'PM0079327',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317482',
      rtl_batch_array: '6317482',
      name_search: 'SANSBLUE',
      name: "Salvia nemorosa 'Slimming Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2146H8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2146H8H'
    },
    {
      status: 210,
      sku: 'PM0032848',
      core_name: 'Plant',
      sppl_ean: '8720349454198',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837489',
      rtl_batch_array: '5837489',
      name_search: 'PHMCUSHI',
      name: "Phlox (S) 'MacDaniel's Cushion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1454,
      chnn_stock_retail: 1454,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPSN9TKG;WJSE1K7H',
      statusContent: 'dot',
      hash: 'LENSCRZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LENSCRZ6'
    },
    {
      status: 210,
      sku: 'PM0079328',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317483',
      rtl_batch_array: '6317483',
      name_search: 'SETSCCHO',
      name: 'Sedum t. Seduction Cherry Chocolate',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1050,
      chnn_stock_retail: 1050,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DZ28EY71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ28EY71'
    },
    {
      status: 210,
      sku: 'PM0079329',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317484',
      rtl_batch_array: '6317484',
      name_search: 'SETSPPAS',
      name: 'Sedum t. Seduction Pink Passion',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1059,
      chnn_stock_retail: 1059,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZH3DJT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZH3DJT1'
    },
    {
      status: 210,
      sku: 'PM0079330',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317485',
      rtl_batch_array: '6317485',
      name_search: 'SETSRSOI',
      name: 'Sedum t. Seduction Ros\u00e9 Soir\u00e9e',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1095,
      chnn_stock_retail: 1095,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N2HC2ZD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2HC2ZD8'
    },
    {
      status: 210,
      sku: 'PM0079331',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317486',
      rtl_batch_array: '6317486',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '060100C10',
      rng_range_identifier: 'H060100C10',
      rng_range_description: 'H60 cm 100 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 12.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '123LTJEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '123LTJEE'
    },
    {
      status: 210,
      sku: 'PM0079332',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317487',
      rtl_batch_array: '6317487',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 8.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '2C6VZPBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2C6VZPBX'
    },
    {
      status: 210,
      sku: 'PM0079333',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317488',
      rtl_batch_array: '6317488, 6339754',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 248,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'S699VVCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S699VVCG'
    },
    {
      status: 210,
      sku: 'PM0079334',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317491',
      rtl_batch_array: '6317491',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 3.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'BSWB4BA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSWB4BA2'
    },
    {
      status: 210,
      sku: 'PM0053399',
      core_name: 'Plant',
      sppl_ean: '8720349454419',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054325',
      rtl_batch_array: '6054325',
      name_search: 'ALHEXCEL',
      name: "Allium 'His Excellency'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7G61TCVL;VBCBCPLD;Z7LGBRTC',
      statusContent: 'dot',
      hash: 'BRAJ5SAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRAJ5SAZ'
    },
    {
      status: 810,
      sku: 'PM0082042',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349453',
      rtl_batch_array: '6349453',
      name_search: 'VAMYRTIL',
      name: 'Vaccinium myrtillus',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 2.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WPEWD3J3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPEWD3J3'
    },
    {
      status: 210,
      sku: 'PM0006462',
      core_name: 'Plant',
      sppl_ean: '8720349428281',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5334988',
      rtl_batch_array: '5334988',
      name_search: 'SANCARAD',
      name: "Salvia nemorosa 'Caradonna'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9088,
      chnn_stock_retail: 9088,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4G44ZLTY;CH999Z8V;82N5TCCX;H9PZEKPC',
      imageBatch: 'V68J2ZG8',
      statusContent: 'dot',
      hash: '2S35YSW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S35YSW2'
    },
    {
      status: 210,
      sku: 'PM0079335',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317492',
      rtl_batch_array: '6317492',
      name_search: 'RHSTEWAR',
      name: "Rhododendron (AJ) 'Stewartstonian'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_prcp: 3.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5V8XDV8X;2SV4YEHC;HGRTGCJH;61SG1873;H73T9WP3;JD9PBT3H;H86Z98ZB;V7EKBVW7;4D4RKPB8',
      statusContent: 'dot',
      hash: '2HB52KE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HB52KE3'
    },
    {
      status: 210,
      sku: 'PM0079336',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317493',
      rtl_batch_array: '6317493',
      name_search: 'RHTOREAD',
      name: "Rhododendron (AJ) 'Toreador'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 3.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGS2N221;434L74ZP;17KE32PY;W1W3WLS4;8A7NC224;PC5J3Y2Z;WTLBWR1C;X1CJ4NZX;JRW7L456;VLJNWENY',
      statusContent: 'dot',
      hash: '1AXBG1AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AXBG1AT'
    },
    {
      status: 210,
      sku: 'PM0070183',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220715',
      rtl_batch_array: '6220715',
      name_search: 'ECKYELLO',
      name: 'Echinacea Kismet Yellow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ELXS2C4;K59XC7Y5;S9YTTN8L;XG5CAZ8C;8GNP2B7A;EBJ1XH9A;63GZ6TD9',
      statusContent: 'dot',
      hash: 'N1W7TKV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1W7TKV2'
    },
    {
      status: 210,
      sku: 'PM0059781',
      core_name: 'Plant',
      sppl_ean: '8720664866867',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6149709',
      rtl_batch_array: '6140846, 6149709',
      name_search: 'HEMFIRE',
      name: "Hemerocallis 'Moses Fire'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3138,
      chnn_stock_retail: 4138,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '47VYT4DH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47VYT4DH'
    },
    {
      status: 810,
      sku: 'PM0079337',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317494',
      rtl_batch_array: '6317494',
      name_search: 'RHGEMBER',
      name: "Rhododendron (AK) 'Glowing Embers'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTB8V37G;KERR36KS',
      statusContent: 'dot',
      hash: 'RBCWD51K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBCWD51K'
    },
    {
      status: 810,
      sku: 'PM0079338',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317495',
      rtl_batch_array: '6317495',
      name_search: 'RHHOMEBU',
      name: "Rhododendron (AK) 'Homebush'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K8YB6LB1;ZA8Z423P;LZ4AZJ9T;5ELJ6TVE;XA7E7NRX;SJL7ZD3K;PT4HK2SN;G3P64K7A;9S995HWA;HHP8YHVH;J2DV88XW;4HWERY5J',
      statusContent: 'dot',
      hash: 'CZK8K2WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZK8K2WH'
    },
    {
      status: 910,
      sku: 'PM0068327',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      rng_range_identifier: 'H030040C7',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: '7284J24R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7284J24R'
    },
    {
      status: 810,
      sku: 'PM0069843',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288294',
      rtl_batch_array: '6288294',
      name_search: 'ACMSAMME',
      name: "Achillea millefolium 'Sammetriese'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y1T9ACNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1T9ACNP'
    },
    {
      status: 810,
      sku: 'PM0067346',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133703',
      rtl_batch_array: '6133703',
      name_search: 'SESVARIE',
      name: "Sequoia sempervirens 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W9LYXTTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9LYXTTA'
    },
    {
      status: 210,
      sku: 'PM0078409',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308532',
      rtl_batch_array: '6308532',
      name_search: 'BRMSSILV',
      name: "Brunnera macrophylla 'Sterling Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZEDN36V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZEDN36V9'
    },
    {
      status: 210,
      sku: 'PM0069299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276647',
      rtl_batch_array: '6276647, 6321932',
      name_search: 'CAAWALDE',
      name: "Calamagrostis acutiflora 'Waldenbuch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KDHZH2H3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDHZH2H3'
    },
    {
      status: 210,
      sku: 'PM0069301',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309021',
      rtl_batch_array: '6309021, 6349776',
      name_search: 'CANMBLUE',
      name: "Calamintha nepeta 'Marvelette Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2076,
      chnn_stock_retail: 2345,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H935LE65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H935LE65'
    },
    {
      status: 210,
      sku: 'PM0078203',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350049',
      rtl_batch_array: '6350049, 6320851',
      name_search: 'ECHSUMME',
      name: "Echinacea 'Hot Summer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 595,
      chnn_stock_retail: 1286,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZY4SS53L;XDYA3L2P;G67T7LSP;ZB65ATTV',
      statusContent: 'dot',
      hash: 'DW4BPK7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DW4BPK7T'
    },
    {
      status: 210,
      sku: 'PM0025550',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 6,
      btch_active_retail: '6382274',
      rtl_batch_array: '6267769, 6176208, 6382274, 6356244, 6386081, 6348296',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4150,
      chnn_stock_retail: 34338,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: 'YBNSKJ3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBNSKJ3J'
    },
    {
      status: 210,
      sku: 'PM0078204',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350050',
      rtl_batch_array: '6350050, 6320852',
      name_search: 'ECIRRESI',
      name: "Echinacea 'Irresistible'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 414,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P6V13BZT;X6A4Z62C',
      statusContent: 'dot',
      hash: 'V1H2ZY2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1H2ZY2Z'
    },
    {
      status: 210,
      sku: 'PM0048308',
      core_name: 'Plant',
      sppl_ean: '8720664861022',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147771',
      rtl_batch_array: '6147771',
      name_search: 'ECPPOSTM',
      name: "Echinacea purpurea 'Postman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TXB46SXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXB46SXR'
    },
    {
      status: 210,
      sku: 'PM0069400',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6229417',
      rtl_batch_array: '6350071, 6229417, 6321912',
      name_search: 'ECPPWHIT',
      name: 'Echinacea purpurea Powwow White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15845,
      chnn_stock_retail: 16792,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SE947XBZ',
      statusContent: 'dot',
      hash: 'AEZWJ1DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEZWJ1DS'
    },
    {
      status: 210,
      sku: 'PM0058462',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196849',
      rtl_batch_array: '6196849',
      name_search: 'MOBROSE',
      name: 'Monarda Balmy Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3723,
      chnn_stock_retail: 3723,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZETBDEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZETBDEX'
    },
    {
      status: 810,
      sku: 'PM0078933',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267607',
      rtl_batch_array: '6267607',
      name_search: 'ECRMARCE',
      name: 'Echinacea Rainbow Marcella',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_order_minimum: 3,
      sppl_prcp: 2.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GN7A7Z4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GN7A7Z4S'
    },
    {
      status: 210,
      sku: 'PM0067142',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6287674',
      rtl_batch_array: '6202288, 6217129, 6287674',
      name_search: 'EPVSULPH',
      name: "Epimedium versicolor 'Sulphureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 4902,
      sppl_order_minimum: 3,
      sppl_prcp: 1.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BK5GH4SV',
      statusContent: 'dot',
      hash: '81VCSSRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81VCSSRC'
    },
    {
      status: 810,
      sku: 'PM0078233',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350235',
      rtl_batch_array: '6350235',
      name_search: 'GEPHPOCU',
      name: "Geranium pratense 'Hocus Pocus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V8ZBWADJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8ZBWADJ'
    },
    {
      status: 210,
      sku: 'PM0053413',
      core_name: 'Plant',
      sppl_ean: '8720664680104',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054346',
      rtl_batch_array: '6054346',
      name_search: 'EDI83765',
      name: "Allium 'Powder Puff'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 697,
      chnn_stock_retail: 697,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHTB3LZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHTB3LZL'
    },
    {
      status: 210,
      sku: 'PM0038357',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310342',
      rtl_batch_array: '5903777, 6310342',
      name_search: 'POFGOLDT',
      name: "Potentilla fruticosa 'Goldteppich'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5475,
      chnn_stock_retail: 6245,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPG1YEWB',
      statusContent: 'dot',
      hash: 'RWPVY6EY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWPVY6EY'
    },
    {
      status: 910,
      sku: 'PM0068328',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      rng_range_identifier: 'H030040C7',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'PY1AXA9C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PY1AXA9C'
    },
    {
      status: 210,
      sku: 'PM0069858',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301382',
      rtl_batch_array: '6301382',
      name_search: 'AGAINDIG',
      name: "Agastache 'Astello Indigo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXPPTCE6;DKGGH5V7',
      statusContent: 'dot',
      hash: 'E9B13S7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9B13S7B'
    },
    {
      status: 210,
      sku: 'PM0078234',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350238',
      rtl_batch_array: '6350238, 6301604',
      name_search: 'GERMSIMP',
      name: "Geranium riversleaianum 'Mavis Simpson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 422,
      chnn_stock_retail: 721,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6G4XCN6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6G4XCN6V'
    },
    {
      status: 210,
      sku: 'PM0053420',
      core_name: 'Plant',
      sppl_ean: '8720664680326',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054360',
      rtl_batch_array: '6054360',
      name_search: 'ALSMELT',
      name: "Allium hybrid 'Sugar Melt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5J9S4E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5J9S4E4'
    },
    {
      status: 210,
      sku: 'PM0059779',
      core_name: 'Plant',
      sppl_ean: '8720664862395',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6128563',
      rtl_batch_array: '6128563',
      name_search: 'EUDLJOE',
      name: "Eupatorium dubium 'Little Joe'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1690,
      chnn_stock_retail: 1690,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L8JK4X65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8JK4X65'
    },
    {
      status: 210,
      sku: 'PM0067345',
      core_name: 'Plant',
      sppl_ean: '8720626340374',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420752',
      rtl_batch_array: '5420752',
      name_search: 'CLBMACKE',
      name: "Clematis 'Bill MacKenzie'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CDWW7DV',
      statusContent: 'dot',
      hash: 'AGX8DVR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGX8DVR1'
    },
    {
      status: 210,
      sku: 'PM0078235',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349089',
      rtl_batch_array: '6349089, 6350239, 6320244',
      name_search: 'GERRPRIC',
      name: "Geranium riversleaianum 'Russell Prichard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 964,
      chnn_stock_retail: 1643,
      sppl_order_minimum: 3,
      sppl_prcp: 1.288,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VNJT3NB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNJT3NB7'
    },
    {
      status: 210,
      sku: 'PM0032845',
      core_name: 'Plant',
      sppl_ean: '8720349424900',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837486',
      rtl_batch_array: '5837486, 6254810',
      name_search: 'PHBEYES',
      name: "Phlox (P) 'Bright Eyes'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1798,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8VVS9HNH;6SCP614V;TZBJD7R3;KWXNT1DW;LDX4KEBV;G6D6AV6R;B8C5KYBG;ARW4WT54;L8JC3TTB;WDE57CL1;D2L7J888',
      statusContent: 'dot',
      hash: '5724CVSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5724CVSP'
    },
    {
      status: 210,
      sku: 'PM0005485',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 5,
      btch_active_retail: '6254901',
      rtl_batch_array: '4660608, 6252642, 6254901, 6336196, 5837434',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 9194,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: '2E1Z2AJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E1Z2AJJ'
    },
    {
      status: 910,
      sku: 'PM0067369',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBURHYT',
      name: 'Viburnum rhytidophyllum',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      imageCore: '48SGK5WV;L8E27T5T',
      statusContent: 'dot',
      hash: '42DE61T3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '42DE61T3'
    },
    {
      status: 910,
      sku: 'PM0067371',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177294',
      rtl_batch_array: '6177294',
      name_search: 'FAMURIEL',
      name: 'Fargesia murieliae',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 30.135,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WDRG6KVZ;PK3N994H',
      statusContent: 'dot',
      hash: 'VS55DCTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VS55DCTA'
    },
    {
      status: 210,
      sku: 'PM0032847',
      core_name: 'Plant',
      sppl_ean: '8720353020785',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837488',
      rtl_batch_array: '5837488',
      name_search: 'PHECBLUE',
      name: "Phlox (S) 'Emerald Cushion Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1391,
      chnn_stock_retail: 1391,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1R1S915;2ETE28TH;WW2WD4RA;2B3CRWDB;T2X7GAKT;L9TWE6LN;L7HRCYNH;G1LY5XBD;9TKP9KTP;C25JSX29',
      statusContent: 'dot',
      hash: '97LVYKEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97LVYKEC'
    },
    {
      status: 910,
      sku: 'PM0067372',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307399',
      rtl_batch_array: '6177296, 6307399',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 17,
      chnn_stock_retail: 26,
      sppl_prcp: 25.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'DTAV5SA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTAV5SA9'
    },
    {
      status: 910,
      sku: 'PM0067373',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253741',
      rtl_batch_array: '6253741',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '2726ZDAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2726ZDAG'
    },
    {
      status: 210,
      sku: 'PM0032866',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315274',
      rtl_batch_array: '6315274, 6276631',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 16256,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'CRD77PAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRD77PAA'
    },
    {
      status: 210,
      sku: 'PM0026893',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6209228',
      rtl_batch_array: '6209228, 5263153, 6252651',
      name_search: 'KOGLAUCA',
      name: 'Koeleria glauca',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 614,
      chnn_stock_retail: 3048,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YER5SPN8;T5NN83DN;W7XR4SW5;HWLR2SAZ;N9X726CD;A88RXT8A;XVBKW59K;JNAWPLBS;SVHAE79R;PEBKLRH6;14TAJW5C;1E8WSSH7;6NY6HTY9;EKL2SE7J;GWSCNGSC',
      statusContent: 'dot',
      hash: 'R8A7YJ6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8A7YJ6N'
    },
    {
      status: 810,
      sku: 'PM0082043',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349454',
      rtl_batch_array: '6349454',
      name_search: 'WIBOKAYA',
      name: "Wisteria brachybotrys 'Okayama'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 23.551,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '73KDST4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73KDST4H'
    },
    {
      status: 210,
      sku: 'PM0053397',
      core_name: 'Plant',
      sppl_ean: '8720664680272',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054319',
      rtl_batch_array: '6054319',
      name_search: 'EDI80768',
      name: 'Allium cowanii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '425XCC2P;W6733239;ESGGBTH9;46NYNCWS;DEELLA7N;RV181ZP7;B7DRZDAG;AZB6YBSZ;VAR9AB1P',
      statusContent: 'dot',
      hash: 'K2DZS4PT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2DZS4PT'
    },
    {
      status: 910,
      sku: 'PM0067338',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'K3J67H3G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K3J67H3G'
    },
    {
      status: 210,
      sku: 'PM0053380',
      core_name: 'Plant',
      sppl_ean: '8720353085166',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053968',
      rtl_batch_array: '6053968',
      name_search: 'ACSIVORI',
      name: "Aconitum septentrionale 'Ivorine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 516,
      chnn_stock_retail: 516,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A6X114GT;P1T9H1KB;K32X24B8',
      statusContent: 'dot',
      hash: '65GG2LT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65GG2LT1'
    },
    {
      status: 210,
      sku: 'PM0079319',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317472',
      rtl_batch_array: '6317472, 6356975',
      name_search: 'ECPGJEWE',
      name: "Echinacea purpurea 'Green Jewel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 789,
      chnn_stock_retail: 1293,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N15T54N7;6WZTDRBZ;6WCGDVB7;E1CGJ5CK;4BR1J7C8;E467VAZ8;Z1W4VR2E;P918BZ3L',
      statusContent: 'dot',
      hash: 'VGWGS2XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGWGS2XG'
    },
    {
      status: 210,
      sku: 'PM0004168',
      core_name: 'Plant',
      sppl_ean: '8720349423194',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4345370',
      rtl_batch_array: '4345370',
      name_search: 'MISLZEBR',
      name: "Miscanthus sinensis 'Little Zebra'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BAH38TT2;TDP9N3BL;GEC3B53L;NE7R6WX4;2AHZS4T1;EL6R5VD1;W65LCK79;1DBC5DWS;67CSE5S5;XSGL1AJS;L3DRCPG9;L8KKHVC4;T48CSEGE;RJX7NYN5;1TJGJKHR;E38CYGAD;G78WEZ1E;9DV7SS41;2PW7E9K7;94Z37G9N;CE5TD7DG;2E3EBBTE;DPB312PR;JT23LDD1;VBC6AEA5;APXLE99T',
      imageBatch: 'E49B5CYV',
      statusContent: 'dot',
      hash: 'WKTHSB2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKTHSB2A'
    },
    {
      status: 210,
      sku: 'PM0032854',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6197041',
      rtl_batch_array: '5837502, 6197041, 6254829',
      name_search: 'SANSCHNE',
      name: "Salvia nemorosa 'Schneeh\u00fcgel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1911,
      chnn_stock_retail: 3699,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8R3CD1WS;NR9GEH7B;RK2RJPY3;LW2R47G7;2TPTNYLL;1SEKLWVT;JL46NNHZ;CHA4A52T;D7V7WJYT',
      statusContent: 'dot',
      hash: 'Y2BE6NX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2BE6NX9'
    },
    {
      status: 810,
      sku: 'PM0046067',
      core_name: 'Plant',
      sppl_ean: '8720664867468',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091368',
      rtl_batch_array: '6091368',
      name_search: 'HEPSPICE',
      name: "Heuchera 'Peppermint Spice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYHN3AWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYHN3AWH'
    },
    {
      status: 210,
      sku: 'PM0057376',
      core_name: 'Plant',
      sppl_ean: '8720664878778',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133532',
      rtl_batch_array: '6133532, 6330056',
      name_search: 'PHRAQUEE',
      name: "Phygelius rectus 'African Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 174,
      chnn_stock_retail: 341,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZVCABV3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVCABV3X'
    },
    {
      status: 210,
      sku: 'PM0057430',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353351',
      rtl_batch_array: '6049478, 6353351',
      name_search: 'SAOTTANN',
      name: "Sanguisorba o. 'Tall Tanna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 598,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CD4XGE85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CD4XGE85'
    },
    {
      status: 910,
      sku: 'PM0067360',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAILAVER',
      name: "Paeonia (LD) 'Inspecteur Lavergne'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '9955119K',
      statusContent: 'dot',
      hash: 'A26N7BJA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A26N7BJA'
    },
    {
      status: 210,
      sku: 'PM0057427',
      core_name: 'Plant',
      sppl_ean: '8720664884885',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133647',
      rtl_batch_array: '6133647',
      name_search: 'SAREPTAN',
      name: 'Salvia reptans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P8ZHJC67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8ZHJC67'
    },
    {
      status: 210,
      sku: 'PM0057215',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301639',
      rtl_batch_array: '6301639',
      name_search: 'HESCBREE',
      name: "Hemerocallis 'Spacecoast Cranberry Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4EY3AG1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EY3AG1G'
    },
    {
      status: 210,
      sku: 'PM0077959',
      core_name: 'Plant',
      sppl_ean: '8720664646988',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364209',
      rtl_batch_array: '5364209, 6353529',
      name_search: 'TRLQUEEN',
      name: "Trollius 'Lemon Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 420,
      chnn_stock_retail: 718,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RJCB2DH3;GXNWLN5W;6EW43DBD;13ER6KG3;A3TW71PJ;VPAZ36CP;Y1ZH23LS;KSS3BSWZ;G2D7ZWK4;43HVYYAV;HNZDG6GX;2DP8CN5L',
      statusContent: 'dot',
      hash: 'RVG2P7L9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVG2P7L9'
    },
    {
      status: 210,
      sku: 'PM0058241',
      core_name: 'Plant',
      sppl_ean: '8720664868397',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112465',
      rtl_batch_array: '6112465',
      name_search: 'HOWSNOW',
      name: "Hosta 'Winter Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7XGKC33N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XGKC33N'
    },
    {
      status: 210,
      sku: 'PM0077960',
      core_name: 'Plant',
      sppl_ean: '8720626308602',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364271',
      rtl_batch_array: '5364271, 6352659',
      name_search: 'KNRPOPSI',
      name: "Kniphofia 'Redhot Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 630,
      chnn_stock_retail: 709,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WY95T3JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WY95T3JH'
    },
    {
      status: 210,
      sku: 'PM0058237',
      core_name: 'Plant',
      sppl_ean: '8720664868328',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112459',
      rtl_batch_array: '6112459',
      name_search: 'HOSFLING',
      name: "Hosta 'Spring Fling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7J3YA89J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7J3YA89J'
    },
    {
      status: 210,
      sku: 'PM0067367',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209213',
      rtl_batch_array: '6209213, 6310421',
      name_search: 'SPNJBRID',
      name: "Spiraea nipponica 'June Bride'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1044,
      chnn_stock_retail: 5344,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZD9G8DS;Y27GCWAD',
      statusContent: 'dot',
      hash: 'V69947RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V69947RT'
    },
    {
      status: 210,
      sku: 'PM0067349',
      core_name: 'Plant',
      sppl_ean: '8720664689268',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5701552',
      rtl_batch_array: '5701552',
      name_search: 'EUJBRAVO',
      name: "Euonymus japonicus 'Bravo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L3C6V2SX;G7P4T4LT;E226CW6B;41DRYLEH;4D7E1XKE;P1RVCLXX;BNWPK6JD;K6TDJSSS;5VADZL5X;J9W8C26X;47YXL32T',
      imageBatch: 'HKJ96LTW',
      statusContent: 'dot',
      hash: 'VN5WW9D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VN5WW9D5'
    },
    {
      status: 910,
      sku: 'PM0022806',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 6,
      btch_active_retail: '6191567',
      rtl_batch_array: '6207823, 6191567, 6274829, 6382273, 6336462, 6333208',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3,
      chnn_stock_retail: 54830,
      sppl_order_minimum: 3,
      sppl_prcp: 0.612,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      statusContent: 'dot',
      hash: 'NV2CBGDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV2CBGDE'
    },
    {
      status: 210,
      sku: 'PM0007642',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 5,
      btch_active_retail: '6382308',
      rtl_batch_array: '6084626, 5960152, 6015441, 6382308, 6291345',
      name_search: 'PEALOPEC',
      name: 'Pennisetum alopecuroides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10750,
      chnn_stock_retail: 38707,
      sppl_order_minimum: 3,
      sppl_prcp: 0.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRWLYSDR;83B33ADA;27VD1BC4;7LNJ3ARN;SNP3L9WL;GW5J4LST;L9HPT3EL;D4LB3PJK;KLP2TT9K;S46D2SL9;8CV52Z65;YKGTHGSS;VZVDC4J7;S4RBGSPH;D6S1W9CB;149JH1D2;PG5CA87X;NPW9N9WY;YSN92SY3',
      statusContent: 'dot',
      hash: 'S8E7XSLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8E7XSLE'
    },
    {
      status: 210,
      sku: 'PM0058236',
      core_name: 'Plant',
      sppl_ean: '8720664868311',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112457',
      rtl_batch_array: '6112457',
      name_search: 'HOSSONAT',
      name: "Hosta 'Shiny Sonata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PGXVN4ZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGXVN4ZN'
    },
    {
      status: 210,
      sku: 'PM0058240',
      core_name: 'Plant',
      sppl_ean: '8720664868373',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112463',
      rtl_batch_array: '6112463',
      name_search: 'HOVGLACI',
      name: "Hosta 'Valley's Glacier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z7Z6JVPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7Z6JVPC'
    },
    {
      status: 210,
      sku: 'PM0058234',
      core_name: 'Plant',
      sppl_ean: '8720664868243',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112452',
      rtl_batch_array: '6112452',
      name_search: 'HOMGRAS',
      name: "Hosta 'Mardi Gras'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1C2DSSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1C2DSSS'
    },
    {
      status: 810,
      sku: 'PM0063659',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350718',
      rtl_batch_array: '6350718',
      name_search: 'OECCRICK',
      name: "Oenothera 'Cold Crick'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T41JZSS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T41JZSS4'
    },
    {
      status: 210,
      sku: 'PM0078266',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350594',
      rtl_batch_array: '6350594, 6348348',
      name_search: 'KIKOREAN',
      name: 'Kirengeshoma koreana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 530,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5TZLXT6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TZLXT6G'
    },
    {
      status: 210,
      sku: 'PM0078267',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308609',
      rtl_batch_array: '6350605, 6308609, 6320886',
      name_search: 'KNMPOPSI',
      name: "Kniphofia 'Mango Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1790,
      chnn_stock_retail: 2777,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZTRXAZJ;8G9EGHCA',
      statusContent: 'dot',
      hash: 'CACX6EGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CACX6EGZ'
    },
    {
      status: 210,
      sku: 'PM0078268',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350610',
      rtl_batch_array: '6350610',
      name_search: 'KNPORANG',
      name: 'Kniphofia Poco Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 487,
      chnn_stock_retail: 487,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VSB59139',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSB59139'
    },
    {
      status: 210,
      sku: 'PM0078269',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350618',
      rtl_batch_array: '6350618',
      name_search: 'KNVLEPAG',
      name: "Kniphofia 'Vincent Lepage'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DBADWX2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBADWX2K'
    },
    {
      status: 210,
      sku: 'PM0078274',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350675',
      rtl_batch_array: '6350675',
      name_search: 'MELSFORM',
      name: "Mentha longifolia 'Silver Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVB2HNAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVB2HNAV'
    },
    {
      status: 210,
      sku: 'PM0078314',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353140',
      rtl_batch_array: '6353140, 6338954',
      name_search: 'PEAJMMOR',
      name: "Persicaria a. 'JS Misty Morning'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 324,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GPWZ2TAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPWZ2TAL'
    },
    {
      status: 210,
      sku: 'PM0078343',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353226',
      rtl_batch_array: '6353226',
      name_search: 'PORSULPH',
      name: "Potentilla recta 'Sulphurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZJXB83CE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJXB83CE'
    },
    {
      status: 210,
      sku: 'PM0065159',
      core_name: 'Plant',
      sppl_ean: '8720664867123',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161747',
      rtl_batch_array: '6161747',
      name_search: 'HETHUNBE',
      name: 'Hemerocallis thunbergii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '874BAE1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '874BAE1D'
    },
    {
      status: 210,
      sku: 'PM0079285',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6350377',
      rtl_batch_array: '6350377, 6302583, 6333253, 6348991',
      name_search: 'HEBBEAUT',
      name: "Heuchera 'Black Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 522,
      chnn_stock_retail: 2178,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPJ33KH',
      statusContent: 'dot',
      hash: 'DLLNZCJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLLNZCJP'
    },
    {
      status: 210,
      sku: 'PM0079286',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350386',
      rtl_batch_array: '6350386, 6320880',
      name_search: 'HEBROWNI',
      name: "Heuchera 'Brownies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 475,
      chnn_stock_retail: 571,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E154GVW8;1GH7HZDA',
      statusContent: 'dot',
      hash: 'CNDP16H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNDP16H1'
    },
    {
      status: 210,
      sku: 'PM0079287',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350392',
      rtl_batch_array: '6350392',
      name_search: 'HECIRCUS',
      name: "Heuchera 'Circus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JLY46ZZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLY46ZZL'
    },
    {
      status: 210,
      sku: 'PM0079288',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350405',
      rtl_batch_array: '6350405, 6302588',
      name_search: 'HEGLITTE',
      name: "Heuchera 'Glitter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 526,
      chnn_stock_retail: 632,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DHVW8D6D',
      statusContent: 'dot',
      hash: 'ZKKREWA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKKREWA6'
    },
    {
      status: 210,
      sku: 'PM0079291',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350419',
      rtl_batch_array: '6350419',
      name_search: 'HEMCARAM',
      name: "Heuchera 'Mega Caramel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 812,
      chnn_stock_retail: 812,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K86SDBV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K86SDBV8'
    },
    {
      status: 810,
      sku: 'PM0079292',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350437',
      rtl_batch_array: '6350437',
      name_search: 'HEPGRIS',
      name: "Heuchera 'Pinot Gris'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7TTHL1ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TTHL1ZY'
    },
    {
      status: 210,
      sku: 'PM0079294',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350440',
      rtl_batch_array: '6350440, 6374023',
      name_search: 'HEPROYAL',
      name: "Heuchera 'Plum Royale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 440,
      chnn_stock_retail: 540,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LHB5Y4HD',
      statusContent: 'dot',
      hash: '196XGEE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '196XGEE1'
    },
    {
      status: 210,
      sku: 'PM0079297',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350450',
      rtl_batch_array: '6350450, 6320885',
      name_search: 'HESSCROL',
      name: "Heuchera 'Silver Scrolls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 393,
      chnn_stock_retail: 668,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6LR72VW;CTS1P9YL;Y9S868BR;C7K3SNJA;XRVLGCL9;95X2CRJK;KLR1LTE5;7VWGRS7Y;K26T9P5X;YRT89GZ8',
      statusContent: 'dot',
      hash: 'YEJV8Y4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEJV8Y4S'
    },
    {
      status: 210,
      sku: 'PM0074310',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269616',
      rtl_batch_array: '6269616',
      name_search: 'PEHPMAGE',
      name: 'Penstemon hartwegii Phoenix Magenta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2604,
      chnn_stock_retail: 2604,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNAPA91N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNAPA91N'
    },
    {
      status: 210,
      sku: 'PM0079325',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317480',
      rtl_batch_array: '6317480',
      name_search: 'PEABLACK',
      name: "Persicaria amplexicaulis 'Blackfield'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 813,
      chnn_stock_retail: 813,
      sppl_prcp: 2.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K76ZEZY6;LA21DDKZ;NA3TEPVB',
      statusContent: 'dot',
      hash: '92AJKX96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92AJKX96'
    },
    {
      status: 910,
      sku: 'PM0067375',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177299',
      rtl_batch_array: '6177299',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 18.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'ZDADVSEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDADVSEW'
    },
    {
      status: 910,
      sku: 'PM0067355',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRGLURON',
      name: "Iris (G) 'Guy Luron'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'SWHVGHSN',
      statusContent: 'dot',
      hash: '9GV6NZWV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9GV6NZWV'
    },
    {
      status: 210,
      sku: 'PM0067343',
      core_name: 'Plant',
      sppl_ean: '8720626349667',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420636',
      rtl_batch_array: '5420636',
      name_search: 'HEHWOERN',
      name: "Hedera helix 'Woerner'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 401,
      chnn_stock_retail: 401,
      sppl_prcp: 8.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X279SEH;RGYYYN9X;JG872GJN;8T9TWZ5J',
      statusContent: 'dot',
      hash: 'JZB4BH5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZB4BH5T'
    },
    {
      status: 210,
      sku: 'PM0059281',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184027',
      rtl_batch_array: '6184027',
      name_search: 'EUFVEGET',
      name: "Euonymus fortunei 'Vegetus'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NV3HRKCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV3HRKCY'
    },
    {
      status: 210,
      sku: 'PM0053400',
      core_name: 'Plant',
      sppl_ean: '8720664680319',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054326',
      rtl_batch_array: '6054326',
      name_search: 'ALJACKPO',
      name: "Allium hybrid 'Jackpot'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TC5J2VGN;78PD2RSR;8DRNZY69;E8TCZVT1',
      statusContent: 'dot',
      hash: '7LPZZH3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LPZZH3R'
    },
    {
      status: 210,
      sku: 'PM0012618',
      core_name: 'Plant',
      sppl_ean: '8720349423149',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '3544451',
      rtl_batch_array: '3544451, 6304765',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 481,
      chnn_stock_retail: 641,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      imageBatch: 'LPRV8X9Y',
      statusContent: 'dot',
      hash: 'AH2NT63P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AH2NT63P'
    },
    {
      status: 210,
      sku: 'PM0053419',
      core_name: 'Plant',
      sppl_ean: '8720664680449',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054359',
      rtl_batch_array: '6054359',
      name_search: 'ALSTIPIT',
      name: 'Allium stipitatum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 3,
      sppl_prcp: 2.068,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J1DGNP6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1DGNP6N'
    },
    {
      status: 810,
      sku: 'PM0071342',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6256067',
      rtl_batch_array: '6294773, 6256067',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 28,
      chnn_stock_retail: 57,
      sppl_prcp: 2.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      statusContent: 'dot',
      hash: 'XB627L32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XB627L32'
    },
    {
      status: 210,
      sku: 'PM0067152',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6192872',
      rtl_batch_array: '6192872, 6291738',
      name_search: 'SAOTANNA',
      name: "Sanguisorba officinalis 'Tanna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7924,
      chnn_stock_retail: 10683,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ZATRR51;TPD6XV5R;C6Z9JZX4;N48G5LEB',
      statusContent: 'dot',
      hash: 'KZ4ZJLH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ4ZJLH1'
    },
    {
      status: 210,
      sku: 'PM0078271',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350649',
      rtl_batch_array: '6350649',
      name_search: 'LIPYCNOS',
      name: 'Liatris pycnostachya',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 756,
      chnn_stock_retail: 756,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CE7573WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE7573WV'
    },
    {
      status: 210,
      sku: 'PM0053475',
      core_name: 'Plant',
      sppl_ean: '8720664686861',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054585',
      rtl_batch_array: '6054585',
      name_search: 'DEHWHITE',
      name: 'Delosperma HP White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PTGHW842',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTGHW842'
    },
    {
      status: 910,
      sku: 'PM0059879',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUFPLFLO',
      name: 'Buddleja Free Petite Lavender Flow',
      rng_range_identifier: 'H020030C7',
      imageCore: 'XALTTLWR;95XJPGXK',
      statusContent: 'dot',
      hash: '2VB9LGJG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2VB9LGJG'
    },
    {
      status: 210,
      sku: 'PM0082044',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349553',
      rtl_batch_array: '6349553',
      name_search: 'AJRMMAHO',
      name: "Ajuga reptans 'Mini Mahogany'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 593,
      chnn_stock_retail: 593,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HCCARLNG;SPSJZ4TC;J8HS6PV3;JY1BNVZY;TX32HTJX',
      statusContent: 'dot',
      hash: '4P1DTDEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P1DTDEE'
    },
    {
      status: 210,
      sku: 'PM0038427',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209136',
      rtl_batch_array: '6209136',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '200225C5GEB',
      rng_range_identifier: 'H200225C5',
      rng_range_description: 'H200 cm 225 cm C5',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_prcp: 8.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'Y6ECLR9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6ECLR9X'
    },
    {
      status: 810,
      sku: 'PM0085568',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385532',
      rtl_batch_array: '6385532',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 5.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'SWH5XPN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWH5XPN2'
    },
    {
      status: 210,
      sku: 'PM0085569',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385533',
      rtl_batch_array: '6385533',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'LN2G2AT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LN2G2AT4'
    },
    {
      status: 810,
      sku: 'PM0079341',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317570',
      rtl_batch_array: '6317570',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 3.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '9WZA4AH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WZA4AH1'
    },
    {
      status: 810,
      sku: 'PM0079342',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317571',
      rtl_batch_array: '6317571',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '040060C6',
      rng_range_identifier: 'H040060C6',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 5.865,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'CBEHCBZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBEHCBZS'
    },
    {
      status: 810,
      sku: 'PM0079343',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355342',
      rtl_batch_array: '6317572, 6355342',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 85,
      sppl_prcp: 6.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'CYWSPNBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYWSPNBA'
    },
    {
      status: 210,
      sku: 'PM0085570',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385534',
      rtl_batch_array: '6385534',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 880,
      chnn_stock_retail: 880,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'A95PD6JT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A95PD6JT'
    },
    {
      status: 910,
      sku: 'PM0053476',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEHYELLO',
      name: 'Delosperma HP Yellow',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'YDNESCC4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YDNESCC4'
    },
    {
      status: 210,
      sku: 'PM0085571',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385535',
      rtl_batch_array: '6385535',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'PX96YNSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PX96YNSY'
    },
    {
      status: 210,
      sku: 'PM0085572',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385536',
      rtl_batch_array: '6385536',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'NJ2RZNCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJ2RZNCH'
    },
    {
      status: 210,
      sku: 'PM0085573',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385537',
      rtl_batch_array: '6385537',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 345,
      chnn_stock_retail: 345,
      sppl_prcp: 3.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'AS2329GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AS2329GC'
    },
    {
      status: 210,
      sku: 'PM0078272',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350660',
      rtl_batch_array: '6350660, 6348357',
      name_search: 'LIMVARIE',
      name: "Liriope muscari 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 2384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TH6ES4SP;YZP9ND3H',
      statusContent: 'dot',
      hash: 'D2LXVTV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2LXVTV2'
    },
    {
      status: 210,
      sku: 'PM0085574',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385538',
      rtl_batch_array: '6385538',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'HX2C67V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX2C67V2'
    },
    {
      status: 210,
      sku: 'PM0085575',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385540',
      rtl_batch_array: '6385540',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_prcp: 4.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: 'NDAYTS4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDAYTS4A'
    },
    {
      status: 210,
      sku: 'PM0085576',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385541',
      rtl_batch_array: '6385541',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_prcp: 5.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: '4V75KERA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V75KERA'
    },
    {
      status: 210,
      sku: 'PM0085577',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385542',
      rtl_batch_array: '6385542',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040050BW',
      rng_range_identifier: 'H040050BR',
      rng_range_description: 'H40 cm 50 cm Bare root',
      sppl_stock_available: 2950,
      chnn_stock_retail: 2950,
      sppl_order_minimum: 10,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'PHWZKVLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHWZKVLW'
    },
    {
      status: 810,
      sku: 'PM0079352',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317582',
      rtl_batch_array: '6317582',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 3.592,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'BWY3KXVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWY3KXVW'
    },
    {
      status: 210,
      sku: 'PM0085578',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385543',
      rtl_batch_array: '6385543',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '050060BW',
      rng_range_identifier: 'H050060BR',
      rng_range_description: 'H50 cm 60 cm Bare root',
      sppl_stock_available: 2720,
      chnn_stock_retail: 2720,
      sppl_prcp: 1.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'RK1EWB6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RK1EWB6B'
    },
    {
      status: 210,
      sku: 'PM0067132',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177044',
      rtl_batch_array: '6177044',
      name_search: 'TACFARME',
      name: "Taxus cuspidata 'Farmen'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 4619,
      chnn_stock_retail: 4619,
      sppl_prcp: 4.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKR44N5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKR44N5C'
    },
    {
      status: 210,
      sku: 'PM0082045',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349554',
      rtl_batch_array: '6349554',
      name_search: 'AJRSPARK',
      name: "Ajuga reptans 'Sparkler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '977YYCLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '977YYCLV'
    },
    {
      status: 210,
      sku: 'PM0085579',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385544',
      rtl_batch_array: '6385544',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: 'LEVBW35T',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 2.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'RHBD97SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHBD97SK'
    },
    {
      status: 210,
      sku: 'PM0015243',
      core_name: 'Plant',
      sppl_ean: '8720349439010',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 7,
      btch_active_retail: '5608765',
      rtl_batch_array:
        '6267798, 6353073, 6274856, 5533719, 5608765, 6287688, 6333287',
      name_search: 'PEALBUNN',
      name: "Pennisetum alopecuroides 'Little Bunny'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70550,
      chnn_stock_retail: 102099,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63YVZZCG;A1BBJ6YJ;B5P86S8E;TH6PBLYX;GPV1LGBL;BNVEJBEX;5A63TARS;XN9HZKN6;6VRVEYET;1LKNTYL8;9P4AJG3C;Z5EA68H7;BY43YC1W;T159CA6D;EYTDLB28;EN1ZK2DY;9BY9SNAW;776T7RDZ;JGPE9T29;WAY2L8DE',
      statusContent: 'dot',
      hash: 'L4V1EY5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4V1EY5P'
    },
    {
      status: 910,
      sku: 'PM0079355',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317587',
      rtl_batch_array: '6317587, 6373415',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 108,
      sppl_prcp: 13.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'B5D2CHXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5D2CHXW'
    },
    {
      status: 210,
      sku: 'PM0067135',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177047',
      rtl_batch_array: '6177047',
      name_search: 'CEHFASTI',
      name: "Cephalotaxus harringtonii 'Fastigiata'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 4.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GTXKZHS9;J1XGR4GH;LW54X38Y',
      statusContent: 'dot',
      hash: 'EZ4T15ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZ4T15ZA'
    },
    {
      status: 810,
      sku: 'PM0079356',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317588',
      rtl_batch_array: '6317588',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '040045C5',
      rng_range_identifier: 'H040045C5',
      rng_range_description: 'H40 cm 45 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.855,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'DASB9RR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DASB9RR7'
    },
    {
      status: 210,
      sku: 'PM0068294',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197022',
      rtl_batch_array: '6197022',
      name_search: 'ERGSBREE',
      name: "Erigeron glaucus 'Sea Breeze'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZGV11PAH;D159SZ24;WTJECVV8;S91TDA6R;WBSVDNNG;GKHLYR61;B2NCPKPT',
      statusContent: 'dot',
      hash: 'LWVPA23Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWVPA23Z'
    },
    {
      status: 210,
      sku: 'PM0067168',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260693',
      rtl_batch_array: '6260693, 6252598',
      name_search: 'GESANGUI',
      name: 'Geranium sanguineum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 747,
      chnn_stock_retail: 1247,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EKJ6426;Y5L86BXG;6YSRB1JT;AT5RV1BJ;ZD1EJ9K9;P38CDZ5J;T4ASJV2R;1NDBZLXW;DSENE4P6;JKAZE5GA;LE7KAVRL',
      statusContent: 'dot',
      hash: 'TNGPDP4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNGPDP4S'
    },
    {
      status: 210,
      sku: 'PM0085580',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385545',
      rtl_batch_array: '6385545',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: 'LEVBW35T',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 2175,
      chnn_stock_retail: 2175,
      sppl_order_minimum: 3,
      sppl_prcp: 2.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'BWHXYAXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWHXYAXT'
    },
    {
      status: 210,
      sku: 'PM0079357',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356685',
      rtl_batch_array: '6317591, 6356685',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 525,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '6J8P8ZC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J8P8ZC4'
    },
    {
      status: 810,
      sku: 'PM0067096',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307302',
      rtl_batch_array: '6307302',
      name_search: 'CLAABLOS',
      name: "Clematis armandii 'Apple Blossom'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_order_minimum: 3,
      sppl_prcp: 6.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KH64KE97;JJTG5491;RNB16E8D',
      statusContent: 'dot',
      hash: 'P9T9LZC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9T9LZC4'
    },
    {
      status: 210,
      sku: 'PM0053468',
      core_name: 'Plant',
      sppl_ean: '8720664860490',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147753',
      rtl_batch_array: '6147753',
      name_search: 'ECGWOODP',
      name: "Echinacea 'Green Woodpecker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9AZB3WJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AZB3WJW'
    },
    {
      status: 210,
      sku: 'PM0085581',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385546',
      rtl_batch_array: '6385546',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: 'LEVBW35T',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 430,
      chnn_stock_retail: 430,
      sppl_order_minimum: 3,
      sppl_prcp: 2.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'S4YP7KE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4YP7KE1'
    },
    {
      status: 210,
      sku: 'PM0018425',
      core_name: 'Plant',
      sppl_ean: '8720349430628',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '5817940',
      rtl_batch_array: '5817940, 6369260',
      name_search: 'VEREPENS',
      name: 'Veronica repens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5844,
      chnn_stock_retail: 5916,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPWA3VT8;6ABJ18NA;CY141VNT',
      statusContent: 'dot',
      hash: 'X8NA8ECH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8NA8ECH'
    },
    {
      status: 210,
      sku: 'PM0067067',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307691',
      rtl_batch_array: '6307691',
      name_search: 'VACORYMB',
      name: 'Vaccinium corymbosum',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_prcp: 5.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VT8ND56T;8TNV3EJ4;TG719NET;89YL8PDH;7JHY49CW;T6TLWK91;6J9N2HDC;ADWY7SC3;H327ZELJ;SSJZ2VJP;ZGEAWRDH;WELPBB4R;27HSCWJX;VK2N8AK1;HTWCYS2Y;D3W1DA8S;EJ51T6V1;E3LA1PGH;BP7JHNAS',
      statusContent: 'dot',
      hash: 'B9SVW42G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9SVW42G'
    },
    {
      status: 210,
      sku: 'PM0085582',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385547',
      rtl_batch_array: '6385547',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: 'LEVBW35T',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 2120,
      chnn_stock_retail: 2120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: '69VW5TEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69VW5TEP'
    },
    {
      status: 810,
      sku: 'PM0053488',
      core_name: 'Plant',
      sppl_ean: '8720664690479',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054621',
      rtl_batch_array: '6054621',
      name_search: 'EDI78326',
      name: "Galanthus (imp) 'Atkinsii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEV2W2A9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEV2W2A9'
    },
    {
      status: 210,
      sku: 'PM0053479',
      core_name: 'Plant',
      sppl_ean: '8720664686915',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054590',
      rtl_batch_array: '6054590',
      name_search: 'DECJDPER',
      name: "Delosperma cooperi 'Jewel of Desert Peridott'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7XXD19HR;994T3PE8;JYNKLHTB;2PE48PG1;N79Y14B6;HZZDGX62;BKNTWBE4;LSTD57EZ;9Z9T4RRK;JYV91GXG;BHSWXRVW;BX31R43E;X2R5PK4E;EWRVN6CJ',
      statusContent: 'dot',
      hash: 'JZ52XSGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ52XSGS'
    },
    {
      status: 210,
      sku: 'PM0085583',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385548',
      rtl_batch_array: '6385548',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: 'LEVBW35T',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_order_minimum: 3,
      sppl_prcp: 2.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'ZKR7NPT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKR7NPT7'
    },
    {
      status: 910,
      sku: 'PM0068337',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      rng_range_identifier: 'H030040C7',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'VH44DSX6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VH44DSX6'
    },
    {
      status: 810,
      sku: 'PM0079361',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317595',
      rtl_batch_array: '6317595',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'BEWG7TP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEWG7TP7'
    },
    {
      status: 810,
      sku: 'PM0050001',
      core_name: 'Plant',
      sppl_ean: '8720626378155',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '5562788',
      rtl_batch_array: '5562788',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'GD7GBNE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GD7GBNE1'
    },
    {
      status: 210,
      sku: 'PM0085584',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385549',
      rtl_batch_array: '6385549',
      name_search: 'HYPUNIQU',
      name: "Hydrangea paniculata 'Unique'",
      sppl_size_code: 'LEVBW35T',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 2.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DD9LJPH;KB36AY45;J7Z7T8C5;1KGH13HA;YNA2SKY1;V2X3SEVT;EEC6931R;XX2XP852;WPKTKD2R',
      statusContent: 'dot',
      hash: 'K7HT5D29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7HT5D29'
    },
    {
      status: 210,
      sku: 'PM0026705',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6209229',
      rtl_batch_array: '3774090, 6209229, 6271426, 6252664',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5379,
      chnn_stock_retail: 10932,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: 'JXTP5S63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXTP5S63'
    },
    {
      status: 810,
      sku: 'PM0079363',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317597',
      rtl_batch_array: '6317597',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 3.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '4CZERRJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CZERRJV'
    },
    {
      status: 210,
      sku: 'PM0085585',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385550',
      rtl_batch_array: '6385550',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      sppl_size_code: '030050KL',
      rng_range_identifier: 'H030050RB',
      rng_range_description: 'H30 cm 50 cm Root ball',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'X77WPT87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X77WPT87'
    },
    {
      status: 210,
      sku: 'PM0067166',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177568',
      rtl_batch_array: '6177568',
      name_search: 'ACSPASTE',
      name: "Achillea 'Summer Pastels'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 933,
      chnn_stock_retail: 933,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L73AB3WZ;ZBZS28EX;DD6GNW4C;KCR88KG6',
      statusContent: 'dot',
      hash: 'WWBZBPKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWBZBPKR'
    },
    {
      status: 210,
      sku: 'PM0053489',
      core_name: 'Plant',
      sppl_ean: '8720664690486',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054623',
      rtl_batch_array: '6054623',
      name_search: 'EDI85726',
      name: "Galanthus elwesii (two) 'Polar Bear'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 251,
      chnn_stock_retail: 251,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SP82W5TG;83SWG2AZ;GTTCYPC4;P7S48W12;CJ2NWZN7;4EBKNELR;TJX6KKBS;GRXZEP8Z;AH6RRCTK',
      statusContent: 'dot',
      hash: 'EKYNYZTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKYNYZTK'
    },
    {
      status: 210,
      sku: 'PM0078273',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350670',
      rtl_batch_array: '6350670',
      name_search: 'MAVULGAR',
      name: 'Marrubium vulgare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KHHAJXAB;HGXRBHGR;K251ZADZ;67S9PZHR;WBB796TR;W2A8TRBR',
      statusContent: 'dot',
      hash: 'KVB2VGTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVB2VGTL'
    },
    {
      status: 210,
      sku: 'PM0032938',
      core_name: 'Plant',
      sppl_ean: '8720664574700',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5838039',
      rtl_batch_array: '5838039',
      name_search: 'LOPPPEAR',
      name: "Lonicera pileata 'Purple Pearl'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5500,
      chnn_stock_retail: 5500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SZLCJTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SZLCJTB'
    },
    {
      status: 910,
      sku: 'PM0079365',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317600',
      rtl_batch_array: '6317600',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '100120C5',
      rng_range_identifier: 'H100120C5',
      rng_range_description: 'H100 cm 120 cm C5',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 5.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'G4EEJVSL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4EEJVSL'
    },
    {
      status: 810,
      sku: 'PM0079366',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317601',
      rtl_batch_array: '6317601',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '93X16ZZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93X16ZZX'
    },
    {
      status: 210,
      sku: 'PM0082046',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349555',
      rtl_batch_array: '6349555',
      name_search: 'AJGTOTTE',
      name: "Ajuga genevensis 'Tottenham'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 661,
      chnn_stock_retail: 661,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8BHAPCV6',
      statusContent: 'dot',
      hash: 'LDWS92V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDWS92V9'
    },
    {
      status: 810,
      sku: 'PM0032138',
      core_name: 'Plant',
      sppl_ean: '8720664692824',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '5818958',
      rtl_batch_array: '5818958, 5818959',
      name_search: 'HYASANNA',
      name: 'Hydrangea arborescens Strong Annabelle',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 10.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6WESVPSZ;8S94HRR7;EW3Z6NPE;DW2PR9BP;PPAKRZVE;55NPDE9T;575TA36W;772K6D22;TS72Y5SD;JW5PHY85;EKWLYTBA;D389C7VL;XTSCRK29',
      statusContent: 'dot',
      hash: 'C1R7Y3B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1R7Y3B7'
    },
    {
      status: 910,
      sku: 'PM0068339',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'B181RN58',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'B181RN58'
    },
    {
      status: 910,
      sku: 'PM0068336',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      rng_range_identifier: 'H020030C7',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'RB3VENWR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RB3VENWR'
    },
    {
      status: 210,
      sku: 'PM0069873',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217669',
      rtl_batch_array: '6217669, 6310252',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1242,
      chnn_stock_retail: 1439,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'WRSYCHCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRSYCHCW'
    },
    {
      status: 210,
      sku: 'PM0023939',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382299',
      rtl_batch_array: '6208046, 6291719, 6382299, 6333281',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 11250,
      chnn_stock_retail: 26155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'THJX8AZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THJX8AZ6'
    },
    {
      status: 910,
      sku: 'PM0071347',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '5EDCA6DE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5EDCA6DE'
    },
    {
      status: 910,
      sku: 'PM0068340',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      rng_range_identifier: 'H030040C7',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'DY1GWH14',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DY1GWH14'
    },
    {
      status: 210,
      sku: 'PM0053480',
      core_name: 'Plant',
      sppl_ean: '8720664686946',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054591',
      rtl_batch_array: '6054591',
      name_search: 'DECJDROS',
      name: 'Delosperma cooperi Jewel of Desert Rosequartz',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8WCB8ETN',
      statusContent: 'dot',
      hash: 'YWLDVCXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWLDVCXS'
    },
    {
      status: 210,
      sku: 'PM0069866',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321870',
      rtl_batch_array: '6259379, 6321870',
      name_search: 'ALPARKRO',
      name: "Alcea 'Parkrondel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 580,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6CC5Y524',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CC5Y524'
    },
    {
      status: 910,
      sku: 'PM0067378',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177302',
      rtl_batch_array: '6177302',
      name_search: 'TABFAURE',
      name: "Taxus baccata 'Fastigiata Aurea'",
      sppl_size_code: '150175C50',
      rng_range_identifier: 'H150175C50',
      rng_range_description: 'H150 cm 175 cm C50',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 75.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CHGPNCTC;RERJP6PT;T7H9XT3A;L86DXY61',
      statusContent: 'dot',
      hash: '8R13SYER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8R13SYER'
    },
    {
      status: 910,
      sku: 'PM0067379',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177303',
      rtl_batch_array: '6177303',
      name_search: 'TABFAURE',
      name: "Taxus baccata 'Fastigiata Aurea'",
      sppl_size_code: '200225C50',
      rng_range_identifier: 'H200225C50',
      rng_range_description: 'H200 cm 225 cm C50',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 130.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CHGPNCTC;RERJP6PT;T7H9XT3A;L86DXY61',
      statusContent: 'dot',
      hash: '41D5Y1L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41D5Y1L8'
    },
    {
      status: 210,
      sku: 'PM0005272',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 5,
      btch_active_retail: '6382301',
      rtl_batch_array: '6080714, 5364345, 6382301, 6291720, 6333282',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 13250,
      chnn_stock_retail: 25355,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      statusContent: 'dot',
      hash: 'ZP7C6KYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP7C6KYB'
    },
    {
      status: 210,
      sku: 'PM0070185',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220718',
      rtl_batch_array: '6220718, 6349789',
      name_search: 'CORELF',
      name: "Coreopsis 'Red Elf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 131,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NEWPG14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NEWPG14'
    },
    {
      status: 210,
      sku: 'PM0053466',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350020',
      rtl_batch_array: '6350020',
      name_search: 'ECGPINK',
      name: "Echinacea 'Giddy Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 398,
      chnn_stock_retail: 398,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BC7W616G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BC7W616G'
    },
    {
      status: 210,
      sku: 'PM0027618',
      core_name: 'Plant',
      sppl_ean: '8720349416554',
      btch_manufacturer: 238,
      rtl_batch_array_total: 3,
      btch_active_retail: '5415374',
      rtl_batch_array: '5592004, 6235369, 5415374',
      name_search: 'GESANGUI',
      name: 'Geranium sanguineum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 8555,
      chnn_stock_retail: 9767,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EKJ6426;Y5L86BXG;6YSRB1JT;AT5RV1BJ;ZD1EJ9K9;P38CDZ5J;T4ASJV2R;1NDBZLXW;DSENE4P6;JKAZE5GA;LE7KAVRL',
      imageBatch: 'SL19SDRW',
      statusContent: 'dot',
      hash: '7JB39AR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JB39AR5'
    },
    {
      status: 210,
      sku: 'PM0067159',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177550',
      rtl_batch_array: '6177550',
      name_search: 'MOMDELIG',
      name: "Monarda 'Marshall's Delight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 613,
      chnn_stock_retail: 613,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DNV6C3L;VV33RPVJ;5NPKKTHD;YEABWLXK;26HGJNRV;LEZPVACR;4276L48J;WBVH1XEC;RTV2A852;GR7XCB8T;HV58PTS4;SJH3GB4R;B3YVC7H3',
      statusContent: 'dot',
      hash: 'CSARYBW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSARYBW2'
    },
    {
      status: 210,
      sku: 'PM0053470',
      core_name: 'Plant',
      sppl_ean: '8720664860650',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147585',
      rtl_batch_array: '6147585',
      name_search: 'ECSCHILI',
      name: "Echinacea 'Sweet Chili'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RKN64935',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKN64935'
    },
    {
      status: 210,
      sku: 'PM0066986',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353270',
      rtl_batch_array: '6353270',
      name_search: 'RHMILLOI',
      name: 'Rhodohypoxis milloides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XZ53BBWW;RAZZ6XWT;CP61H7K6',
      statusContent: 'dot',
      hash: 'XBGR7288',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBGR7288'
    },
    {
      status: 810,
      sku: 'PM0053467',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6207950',
      rtl_batch_array: '6207950',
      name_search: 'ECPAPRIL',
      name: "Echinacea p. 'April'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8ELDSK6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ELDSK6K'
    },
    {
      status: 210,
      sku: 'PM0059775',
      core_name: 'Plant',
      sppl_ean: '8720664850934',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6128558',
      rtl_batch_array: '6128558',
      name_search: 'AJRATROP',
      name: "Ajuga reptans 'Atropurpurea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2778,
      chnn_stock_retail: 2778,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NYXH8SZ5;RYZVDHRL;43164DTS;4CRTNCEC;JJBC7P65;8XEL25RD;A8HBGSG2',
      statusContent: 'dot',
      hash: 'JJ87L6V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJ87L6V5'
    },
    {
      status: 810,
      sku: 'PM0018028',
      core_name: 'Plant',
      sppl_ean: '8720626377325',
      btch_manufacturer: 508,
      rtl_batch_array_total: 3,
      btch_active_retail: '5496597',
      rtl_batch_array: '5496597, 5496598, 6258789',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 450,
      sppl_prcp: 8.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'YZRYK27J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZRYK27J'
    },
    {
      status: 210,
      sku: 'PM0053490',
      core_name: 'Plant',
      sppl_ean: '8720664690523',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054624',
      rtl_batch_array: '6054624',
      name_search: 'EDI61854',
      name: "Galanthus nivalis (dou) 'Flore Pleno'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HBZV6AKT;ALAP5ZPP;ZL1WGN8A;R1N2PKSX;XX1TZPVX',
      statusContent: 'dot',
      hash: 'CNHAYPDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNHAYPDN'
    },
    {
      status: 210,
      sku: 'PM0053469',
      core_name: 'Plant',
      sppl_ean: '8720664860766',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147584',
      rtl_batch_array: '6147584',
      name_search: 'ECPSAGRE',
      name: "Echinacea p. 'Sunseek. Apple Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1PV521L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PV521L2'
    },
    {
      status: 210,
      sku: 'PM0059784',
      core_name: 'Plant',
      sppl_ean: '8720664872066',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6128569',
      rtl_batch_array: '6128569',
      name_search: 'LELASPID',
      name: "Leucanthemum (S) 'Laspider'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1374,
      chnn_stock_retail: 1374,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8EA24YCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EA24YCR'
    },
    {
      status: 810,
      sku: 'PM0078275',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350676',
      rtl_batch_array: '6350676',
      name_search: 'MELBUDDL',
      name: "Mentha longifolia 'Buddleia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBZYJ1H2',
      statusContent: 'dot',
      hash: 'YZ7V6JYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ7V6JYY'
    },
    {
      status: 210,
      sku: 'PM0078288',
      core_name: 'Plant',
      sppl_ean: '8720349473618',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '5608758',
      rtl_batch_array: '6350693, 5608758',
      name_search: 'MOCBARRO',
      name: "Molinia caerulea 'Black Arrows'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4200,
      chnn_stock_retail: 4560,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YLAVXBGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLAVXBGL'
    },
    {
      status: 210,
      sku: 'PM0078294',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350703',
      rtl_batch_array: '6350703',
      name_search: 'MUKARASU',
      name: "Mukdenia 'Karasuba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K7LSKK2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7LSKK2A'
    },
    {
      status: 210,
      sku: 'PM0078295',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348388',
      rtl_batch_array: '6350707, 6348388',
      name_search: 'NEGBRAMD',
      name: "Nepeta grandiflora 'Bramdean'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 323,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXG9915X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXG9915X'
    },
    {
      status: 810,
      sku: 'PM0078296',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350709',
      rtl_batch_array: '6350709',
      name_search: 'NEGZGIAN',
      name: "Nepeta grandiflora 'Zinser's Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WV4HKS93',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV4HKS93'
    },
    {
      status: 210,
      sku: 'PM0058356',
      core_name: 'Plant',
      sppl_ean: '8720664874299',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112643',
      rtl_batch_array: '6112643',
      name_search: 'MEMRVDIS',
      name: "Melittis melissophyllum 'Royal Velvet Distinction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J8ATLWS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8ATLWS1'
    },
    {
      status: 210,
      sku: 'PM0078297',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350710',
      rtl_batch_array: '6350710',
      name_search: 'NENALBIF',
      name: 'Nepeta nuda albiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DCHVT667',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCHVT667'
    },
    {
      status: 210,
      sku: 'PM0058480',
      core_name: 'Plant',
      sppl_ean: '8720664875364',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112821',
      rtl_batch_array: '6112821',
      name_search: 'NIREPENS',
      name: 'Nierembergia repens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 354,
      chnn_stock_retail: 354,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8G4N1HHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G4N1HHH'
    },
    {
      status: 210,
      sku: 'PM0071352',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224721',
      rtl_batch_array: '6224721',
      name_search: 'GECCAMBR',
      name: "Geranium cantabrigiense 'Cambridge'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '57XYWWA3;T583W45B;8LD5V18W',
      statusContent: 'dot',
      hash: '5SGKWWVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SGKWWVK'
    },
    {
      status: 910,
      sku: 'PM0059295',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'CBEPDJZL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CBEPDJZL'
    },
    {
      status: 810,
      sku: 'PM0078298',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350711',
      rtl_batch_array: '6350711',
      name_search: 'NENACHOI',
      name: "Nepeta nuda 'Anne's Choice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GV5SW38Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GV5SW38Z'
    },
    {
      status: 210,
      sku: 'PM0078299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350716',
      rtl_batch_array: '6350716',
      name_search: 'NESLAUFE',
      name: "Nepeta subsessilis 'Laufen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HWXJPERH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWXJPERH'
    },
    {
      status: 210,
      sku: 'PM0071353',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226846',
      rtl_batch_array: '6226846',
      name_search: 'HOSGROOV',
      name: "Hosta 'Sunset Grooves'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N7A6VRHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7A6VRHC'
    },
    {
      status: 210,
      sku: 'PM0069871',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6216843',
      rtl_batch_array: '6216843',
      name_search: 'PIALGEM',
      name: "Picea abies 'Little Gem'",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 623,
      chnn_stock_retail: 623,
      sppl_prcp: 5.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GJ1GP8ZY;L68K9YT5;RX5T3XK6;ST3KW93Y;TRHC4E63;GSNRW614;JEK8T3VH;49VVGX6J',
      statusContent: 'dot',
      hash: '24GBCT78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24GBCT78'
    },
    {
      status: 210,
      sku: 'PM0063670',
      core_name: 'Plant',
      sppl_ean: '8720664881464',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147835',
      rtl_batch_array: '6147835',
      name_search: 'POAECIEL',
      name: "Potentilla 'Arc-en-ciel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RPN7YY1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPN7YY1X'
    },
    {
      status: 210,
      sku: 'PM0053472',
      core_name: 'Plant',
      sppl_ean: '8720664686960',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054582',
      rtl_batch_array: '6054582',
      name_search: 'DEICFIRE',
      name: 'Delosperma Ice Cream Fire',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BGTCVW8V;X9CP38R9;PJAVPAT1;13S2KW94;YC32HAJD;TZRLD3K3',
      statusContent: 'dot',
      hash: '1P9X2VXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1P9X2VXV'
    },
    {
      status: 210,
      sku: 'PM0058493',
      core_name: 'Plant',
      sppl_ean: '8720664877368',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161926',
      rtl_batch_array: '6161926, 6217043',
      name_search: 'PEALISAN',
      name: "Persicaria amplexicaulis 'Lisan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5452,
      chnn_stock_retail: 11653,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y7SAEA3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7SAEA3D'
    },
    {
      status: 210,
      sku: 'PM0078321',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353168',
      rtl_batch_array: '6353168',
      name_search: 'PHFPVCHA',
      name: 'Phlox (P) Flame Pro Violet Charme',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C58SXRBC;THBAVBPP',
      statusContent: 'dot',
      hash: 'V9SDNPSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9SDNPSA'
    },
    {
      status: 210,
      sku: 'PM0031159',
      core_name: 'Plant',
      sppl_ean: '8720664683723',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5860559',
      rtl_batch_array: '5860559',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: 'GMC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      imageBatch: 'H6E53LNB',
      statusContent: 'dot',
      hash: 'BZJZRNJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZJZRNJZ'
    },
    {
      status: 210,
      sku: 'PM0078380',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353524',
      rtl_batch_array: '6353524',
      name_search: 'TRTOJEN',
      name: "Tricyrtis 'Tojen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DVNDJP5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVNDJP5A'
    },
    {
      status: 210,
      sku: 'PM0069876',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217673',
      rtl_batch_array: '6217673',
      name_search: 'HEHGOLDC',
      name: "Hedera helix 'Goldchild'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJRW5453;57LGK482;7ZC75WXR;XXLZ6PKD;YD2JNZA2',
      statusContent: 'dot',
      hash: 'Z653NYTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z653NYTG'
    },
    {
      status: 210,
      sku: 'PM0065398',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295703',
      rtl_batch_array: '6350591, 6295703',
      name_search: 'KAIALBA',
      name: "Kalimeris incisa 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3261,
      chnn_stock_retail: 5117,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EVEZG75R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVEZG75R'
    },
    {
      status: 210,
      sku: 'PM0071354',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 4,
      btch_active_retail: '6226847',
      rtl_batch_array: '6226847, 6226865, 6226866, 6226879',
      name_search: 'MODIDYMA',
      name: 'Monarda didyma',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9BTLRZ8H;4VKZXYDB;67SJD1V5;T71APS63;2XPRJHVC',
      statusContent: 'dot',
      hash: 'VENZXRBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VENZXRBE'
    },
    {
      status: 210,
      sku: 'PM0031764',
      core_name: 'Plant',
      sppl_ean: '8720664801332',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5804840',
      rtl_batch_array: '5804840',
      name_search: 'PEALISAN',
      name: "Persicaria amplexicaulis 'Lisan'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 992,
      chnn_stock_retail: 992,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GT7YLDTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT7YLDTW'
    },
    {
      status: 210,
      sku: 'PM0053482',
      core_name: 'Plant',
      sppl_ean: '8720664686977',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054593',
      rtl_batch_array: '6054593',
      name_search: 'DEICORAN',
      name: 'Delosperma Ice Cream Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T4X37VVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4X37VVP'
    },
    {
      status: 210,
      sku: 'PM0067157',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244309',
      rtl_batch_array: '6244309, 6210029, 6258930',
      name_search: 'VERIGIDA',
      name: 'Verbena rigida',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 697,
      chnn_stock_retail: 2382,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JHZ9E1E3;7ZK7HPY6;P4DNZJEJ;2V62XSB3;923W5HVA;KAL39NZ1;9E8AEPLP;A7HXBCY5;PSY1D8W2;EE9SXL7E;BPVSXRV6;C8CZJX7K;JHB93VZP;APPBK6H4',
      statusContent: 'dot',
      hash: 'B1A7NCCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1A7NCCP'
    },
    {
      status: 210,
      sku: 'PM0053453',
      core_name: 'Plant',
      sppl_ean: '8720664683631',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054517',
      rtl_batch_array: '6054517',
      name_search: 'CADHOLID',
      name: "Carex dipsacea 'Holiday'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HB3HDGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HB3HDGC'
    },
    {
      status: 210,
      sku: 'PM0053067',
      core_name: 'Plant',
      sppl_ean: '8720349489442',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6058244',
      rtl_batch_array: '6058244, 6254845',
      name_search: 'SOGOLDKI',
      name: "Solidago 'Goldkind'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 865,
      chnn_stock_retail: 1359,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D4J2NX7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4J2NX7Y'
    },
    {
      status: 910,
      sku: 'PM0068342',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      rng_range_identifier: 'H030040C7',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'G121ERLB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G121ERLB'
    },
    {
      status: 910,
      sku: 'PM0067381',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'RWT3HHYC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RWT3HHYC'
    },
    {
      status: 210,
      sku: 'PM0067384',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 3,
      btch_active_retail: '6200441',
      rtl_batch_array: '6178003, 6200441, 6253079',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 2086,
      sppl_prcp: 10.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'WTXDRW9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTXDRW9W'
    },
    {
      status: 210,
      sku: 'PM0069880',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217678',
      rtl_batch_array: '6217678',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'B1N1YHSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1N1YHSS'
    },
    {
      status: 210,
      sku: 'PM0085586',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385551',
      rtl_batch_array: '6385551',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 670,
      chnn_stock_retail: 670,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'LCRLPK6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCRLPK6K'
    },
    {
      status: 210,
      sku: 'PM0067203',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177925',
      rtl_batch_array: '6255116, 6177925',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 10000,
      chnn_stock_retail: 11925,
      sppl_prcp: 1.516,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'KS599CSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS599CSB'
    },
    {
      status: 210,
      sku: 'PM0053421',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6207790',
      rtl_batch_array: '6207790, 6202270, 6301392',
      name_search: 'ALSBEAUT',
      name: "Allium 'Summer Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1018,
      chnn_stock_retail: 3063,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '14KN57JS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14KN57JS'
    },
    {
      status: 810,
      sku: 'PM0082047',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349738',
      rtl_batch_array: '6349556, 6349738',
      name_search: 'AJRVARIE',
      name: "Ajuga reptans 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 801,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRTS6ERP;SD5ZXL2Z;XV46DEXK;B5TVJ2RE',
      statusContent: 'dot',
      hash: 'ZDND9AEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDND9AEN'
    },
    {
      status: 210,
      sku: 'PM0069879',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217676',
      rtl_batch_array: '6217676',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 4.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: '8TD376KN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TD376KN'
    },
    {
      status: 210,
      sku: 'PM0079383',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317620',
      rtl_batch_array: '6317620',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '015020C3',
      rng_range_identifier: 'H015020C3',
      rng_range_description: 'H15 cm 20 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 2.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: 'AXP3NZ52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXP3NZ52'
    },
    {
      status: 210,
      sku: 'PM0085587',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385552',
      rtl_batch_array: '6385552',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1175,
      chnn_stock_retail: 1175,
      sppl_prcp: 4.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'DZ12J1WS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ12J1WS'
    },
    {
      status: 810,
      sku: 'PM0053494',
      core_name: 'Plant',
      sppl_ean: '8720349433285',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054628',
      rtl_batch_array: '6054628',
      name_search: 'EDI73215',
      name: 'Galanthus woronowii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TR9C7E83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR9C7E83'
    },
    {
      status: 210,
      sku: 'PM0067404',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260060',
      rtl_batch_array: '6260060',
      name_search: 'HEALBICA',
      name: 'Hebe albicans',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1695,
      chnn_stock_retail: 1695,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TZS7YL6H;BEEEXX1B;V8SD7NR9;SWDSA3HL;RLPH2D7R;JB39NE6N;KVVE9GTP;XYN52PN4;XCDS8YDC;7YXAY939;YDZHXEC8;BHXLV5W7;4VBJ8VG8;NT3B5V7P;9CB28JNA',
      statusContent: 'dot',
      hash: '468292PG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '468292PG'
    },
    {
      status: 210,
      sku: 'PM0053493',
      core_name: 'Plant',
      sppl_ean: '8720664690516',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054627',
      rtl_batch_array: '6054627',
      name_search: 'GANVIRID',
      name: "Galanthus nivalis 'Viride-apice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2P8T31CE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2P8T31CE'
    },
    {
      status: 210,
      sku: 'PM0067396',
      core_name: 'Plant',
      sppl_ean: '8720626359512',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5406882',
      rtl_batch_array: '5406882',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_prcp: 2.516,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '2X137VAX',
      statusContent: 'dot',
      hash: 'PEK6VKB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEK6VKB7'
    },
    {
      status: 210,
      sku: 'PM0069886',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236257',
      rtl_batch_array: '6236257',
      name_search: 'SARNITID',
      name: 'Salix repens nitida',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 124,
      chnn_stock_retail: 124,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZHP2L4T',
      statusContent: 'dot',
      hash: 'WH7JBGKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH7JBGKS'
    },
    {
      status: 210,
      sku: 'PM0067183',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177902',
      rtl_batch_array: '6177902',
      name_search: 'HYPPBEAR',
      name: "Hydrangea paniculata 'Polar Bear'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ED2GCDGB;26E1HYAK;EX94Y5YD;V354N854;JCWVR1BA',
      statusContent: 'dot',
      hash: 'T2GL2X8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2GL2X8L'
    },
    {
      status: 810,
      sku: 'PM0082048',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349557',
      rtl_batch_array: '6349557',
      name_search: 'MISSAMUR',
      name: "Miscanthus sinensis 'Samurai'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '198AL6ZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '198AL6ZX'
    },
    {
      status: 210,
      sku: 'PM0071363',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226857',
      rtl_batch_array: '6226857',
      name_search: 'ACCGOLD',
      name: "Achillea 'Coronation Gold'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RK53DSVD;JY3SR1JC;TR9GVDJA;A9JKW7HY;X9Z5KEEA',
      statusContent: 'dot',
      hash: 'R8RBP5SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8RBP5SG'
    },
    {
      status: 810,
      sku: 'PM0082049',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349558',
      rtl_batch_array: '6349558',
      name_search: 'MISVERNE',
      name: "Miscanthus sinensis 'Verneigung'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YKPASLXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKPASLXH'
    },
    {
      status: 910,
      sku: 'PM0068355',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      rng_range_identifier: 'H030040C7',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'NX27ZZB3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NX27ZZB3'
    },
    {
      status: 210,
      sku: 'PM0085588',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385553',
      rtl_batch_array: '6385553',
      name_search: 'ILAALASK',
      name: "Ilex aquifolium 'Alaska'",
      sppl_size_code: '030050KL',
      rng_range_identifier: 'H030050RB',
      rng_range_description: 'H30 cm 50 cm Root ball',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZCK3Z7A;YGGASRN8;KTLGECKH;RHJP88XY;929N1BNB;P8JVW1RC;PY7B1HE1;A395L6HY;X2Z7CNL6;CEB5DR44;VBT7XPTG;NEV587VA;CS8HW7TW;LRHE52TP;XJB2NTVZ',
      statusContent: 'dot',
      hash: 'WHP6GTLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHP6GTLT'
    },
    {
      status: 210,
      sku: 'PM0085589',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385554',
      rtl_batch_array: '6385554',
      name_search: 'ILAALASK',
      name: "Ilex aquifolium 'Alaska'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZCK3Z7A;YGGASRN8;KTLGECKH;RHJP88XY;929N1BNB;P8JVW1RC;PY7B1HE1;A395L6HY;X2Z7CNL6;CEB5DR44;VBT7XPTG;NEV587VA;CS8HW7TW;LRHE52TP;XJB2NTVZ',
      statusContent: 'dot',
      hash: 'H9VB6YWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9VB6YWB'
    },
    {
      status: 210,
      sku: 'PM0085590',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385555',
      rtl_batch_array: '6385555',
      name_search: 'ILAALASK',
      name: "Ilex aquifolium 'Alaska'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1050,
      chnn_stock_retail: 1050,
      sppl_prcp: 4.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZCK3Z7A;YGGASRN8;KTLGECKH;RHJP88XY;929N1BNB;P8JVW1RC;PY7B1HE1;A395L6HY;X2Z7CNL6;CEB5DR44;VBT7XPTG;NEV587VA;CS8HW7TW;LRHE52TP;XJB2NTVZ',
      statusContent: 'dot',
      hash: '844VXB4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '844VXB4S'
    },
    {
      status: 210,
      sku: 'PM0085591',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385556',
      rtl_batch_array: '6385556',
      name_search: 'ILCRENAT',
      name: 'Ilex crenata',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 1375,
      chnn_stock_retail: 1375,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZPHE4YX;G6GNL9HW;DEDHVPLC;S98DZ16D;JN9WPG9R;SSPZHTYG;8VTEEA4Z;8EVJD56A;62HCWZTE;L8L556GA;JG8E3W83;CTW46TT6;DLVBKSXX;J8P1G7W7;SNLD6864',
      statusContent: 'dot',
      hash: 'NSG8TV9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSG8TV9X'
    },
    {
      status: 810,
      sku: 'PM0082050',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349559',
      rtl_batch_array: '6349559',
      name_search: 'MITRANSM',
      name: 'Miscanthus transmorrisonensis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1PW2ARVL;2NG2XHNN;7LZ6ZW1A;W4WSZZWT;PGGJNZZR;7X4849LB;D4WLEPDR;CNH57CR4;DW4N9ZP7;A57H7VVB;J1873X4H',
      statusContent: 'dot',
      hash: 'SJGNDPL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJGNDPL8'
    },
    {
      status: 910,
      sku: 'PM0068350',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      rng_range_identifier: 'H030040C7',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: 'NP1SSEXD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NP1SSEXD'
    },
    {
      status: 210,
      sku: 'PM0069887',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243063',
      rtl_batch_array: '6243063',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 448,
      chnn_stock_retail: 448,
      sppl_prcp: 1.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'V15XPZVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V15XPZVE'
    },
    {
      status: 210,
      sku: 'PM0067395',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5394426',
      rtl_batch_array: '5394426',
      name_search: 'HICMIX',
      name: 'Hibiscus Chiffon Mix',
      sppl_size_code: '035040C33L2',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 458,
      chnn_stock_retail: 458,
      sppl_prcp: 4.906,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'H592JJE2',
      statusContent: 'dot',
      hash: 'R43H3GKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R43H3GKZ'
    },
    {
      status: 210,
      sku: 'PM0033300',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353285',
      rtl_batch_array: '6353285',
      name_search: 'SEABYGAL',
      name: "Sedum 'Abygale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DT593Z71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT593Z71'
    },
    {
      status: 210,
      sku: 'PM0085592',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385557',
      rtl_batch_array: '6385557',
      name_search: 'ILCRENAT',
      name: 'Ilex crenata',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 2025,
      chnn_stock_retail: 2025,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZPHE4YX;G6GNL9HW;DEDHVPLC;S98DZ16D;JN9WPG9R;SSPZHTYG;8VTEEA4Z;8EVJD56A;62HCWZTE;L8L556GA;JG8E3W83;CTW46TT6;DLVBKSXX;J8P1G7W7;SNLD6864',
      statusContent: 'dot',
      hash: '4E2J4AYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E2J4AYK'
    },
    {
      status: 210,
      sku: 'PM0071356',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226849',
      rtl_batch_array: '6226849',
      name_search: 'HORBUSIN',
      name: "Hosta 'Risky Business'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y2H7446Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2H7446Y'
    },
    {
      status: 210,
      sku: 'PM0074312',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269618',
      rtl_batch_array: '6269618',
      name_search: 'PEPPINK',
      name: "Penstemon 'Polaris Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2999,
      chnn_stock_retail: 2999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G9RLKCYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9RLKCYV'
    },
    {
      status: 210,
      sku: 'PM0071357',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226850',
      rtl_batch_array: '6226850',
      name_search: 'HOCATHER',
      name: "Hosta 'Catherine'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SS9H6Z7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS9H6Z7D'
    },
    {
      status: 210,
      sku: 'PM0068317',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197105',
      rtl_batch_array: '6197105',
      name_search: 'NEFAASSE',
      name: 'Nepeta faassenii',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2562,
      chnn_stock_retail: 2562,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XT1RXYHP;LEGK51PP',
      statusContent: 'dot',
      hash: '6VJTK4CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VJTK4CR'
    },
    {
      status: 210,
      sku: 'PM0067403',
      core_name: 'Plant',
      sppl_ean: '8720349412464',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5652894',
      rtl_batch_array: '5652894',
      name_search: 'CEDEALBA',
      name: 'Centaurea dealbata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3811,
      chnn_stock_retail: 3811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E7ASRY1E;V5ABGCLS;G5RD93CA;TYV9PZWS;8KHGG8W8;SCDJR9JT;JETACTPN',
      imageBatch: 'P9NVXAP1',
      statusContent: 'dot',
      hash: 'VY8R899Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY8R899Z'
    },
    {
      status: 210,
      sku: 'PM0071358',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226852',
      rtl_batch_array: '6226852',
      name_search: 'IRSHAUDA',
      name: "Iris sibirica 'How Audacious'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2DRBBDTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DRBBDTH'
    },
    {
      status: 210,
      sku: 'PM0071359',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226853',
      rtl_batch_array: '6226853',
      name_search: 'IRSNQWHI',
      name: "Iris sibirica 'Not Quite White'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4YEE547E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YEE547E'
    },
    {
      status: 210,
      sku: 'PM0085593',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385558',
      rtl_batch_array: '6385558',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: '2WE2DCKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WE2DCKP'
    },
    {
      status: 210,
      sku: 'PM0071360',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226854',
      rtl_batch_array: '6226854',
      name_search: 'PASSNISH',
      name: "Paeonia suff. 'Shima-nishiki'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.858,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TLB7782D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLB7782D'
    },
    {
      status: 210,
      sku: 'PM0082051',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349560',
      rtl_batch_array: '6349560, 6321865',
      name_search: 'AGMRFORT',
      name: "Agastache mexicana 'Red Fortune'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 924,
      chnn_stock_retail: 1073,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YZBE7PAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZBE7PAY'
    },
    {
      status: 210,
      sku: 'PM0085594',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385559',
      rtl_batch_array: '6385559',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: '28C9ES88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28C9ES88'
    },
    {
      status: 210,
      sku: 'PM0085595',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385560',
      rtl_batch_array: '6385560',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'GPN816D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPN816D3'
    },
    {
      status: 810,
      sku: 'PM0079376',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317612',
      rtl_batch_array: '6317612',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 9.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'DVN84PAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVN84PAD'
    },
    {
      status: 210,
      sku: 'PM0053517',
      core_name: 'Plant',
      sppl_ean: '8720664694262',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054694',
      rtl_batch_array: '6054694',
      name_search: 'HYMBLOSS',
      name: 'Hypericum Miracle Blossom',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDNVKY8S;PRW4CTHT;LG6L1SLP;WZNZ52JD;JXCGHH2G;J1GYYHV7',
      statusContent: 'dot',
      hash: 'H8APLVGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8APLVGJ'
    },
    {
      status: 210,
      sku: 'PM0079377',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317613',
      rtl_batch_array: '6317613',
      name_search: 'CULTWEED',
      name: "Cupressocyparis leylandii 'Tweeduizend\u00e9\u00e9n'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JG4CZ7R;9ZSW198W;6ZNCADAK;J965WSDB;NHP4BB6R;17LPS8VL;E9C5SA68;76GJ7AGR;AHNVVWLD;X2EDX346;BZ1A1NSL',
      statusContent: 'dot',
      hash: '6S63ADKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6S63ADKS'
    },
    {
      status: 910,
      sku: 'PM0068349',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      rng_range_identifier: 'H040050C7',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'WZZZCJ5R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WZZZCJ5R'
    },
    {
      status: 910,
      sku: 'PM0067419',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CANIGRA',
      name: 'Carex nigra',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'A4YLBTTW',
      statusContent: 'dot',
      hash: 'XPSXWPDJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XPSXWPDJ'
    },
    {
      status: 810,
      sku: 'PM0069891',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255035',
      rtl_batch_array: '6255035',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: '9VBPK9EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VBPK9EV'
    },
    {
      status: 910,
      sku: 'PM0053495',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEKANAHI',
      name: "Geranium 'Kanahitobanawa'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CR8G3726',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CR8G3726'
    },
    {
      status: 210,
      sku: 'PM0067394',
      core_name: 'Plant',
      sppl_ean: '8720353007359',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '5369883',
      rtl_batch_array: '5369883, 6338019',
      name_search: 'LIMMWHIT',
      name: "Liriope muscari 'Monroe White'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 427,
      chnn_stock_retail: 570,
      sppl_prcp: 2.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AYLD59KE;4NY9BBP2;28G2AKK8;D4B3XNSA;T4SJCPL6;KVVDLPV1;HGER8DSK;DV6AZ3ST;XED3AWGH;P4RDERDW;VETGZ9Y7',
      imageBatch: 'N1CV11ZX',
      statusContent: 'dot',
      hash: 'P573Z8NY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P573Z8NY'
    },
    {
      status: 910,
      sku: 'PM0071361',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PASYHQDU',
      name: "Paeonia suffruticosa 'Yin Hong Qiao Dui'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'BD2ECDNL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BD2ECDNL'
    },
    {
      status: 910,
      sku: 'PM0082052',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALCPALBU',
      name: "Allium carinatum pulchellum 'Album'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '4XNTG68Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4XNTG68Z'
    },
    {
      status: 210,
      sku: 'PM0071364',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226858',
      rtl_batch_array: '6226858',
      name_search: 'IRSBJOKE',
      name: "Iris sibirica 'Black Joker'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZ5KPCXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ5KPCXC'
    },
    {
      status: 210,
      sku: 'PM0071365',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226859',
      rtl_batch_array: '6226859',
      name_search: 'PAKANSAS',
      name: "Paeonia (LD) 'Kansas'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXTZJ9WG',
      statusContent: 'dot',
      hash: 'LDTDJXNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDTDJXNC'
    },
    {
      status: 210,
      sku: 'PM0085596',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385561',
      rtl_batch_array: '6385561',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 2380,
      chnn_stock_retail: 2380,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'TWLP48PL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWLP48PL'
    },
    {
      status: 210,
      sku: 'PM0085597',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385562',
      rtl_batch_array: '6385562',
      name_search: 'ILECGGEM',
      name: "Ilex crenata 'Golden Gem'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 3.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6H1T152;ACELZCTX;L8LWT5T5;DNH7B45X;W1E88G6R;PWBSSNSK;H37PG1Y5;BES2N1AV;1WTGN263;4KJBZN66',
      statusContent: 'dot',
      hash: 'A2WBJK2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2WBJK2S'
    },
    {
      status: 210,
      sku: 'PM0071366',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226860',
      rtl_batch_array: '6226860',
      name_search: 'PAPECHE',
      name: "Paeonia (LD) 'Peche'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EEJTLR3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEJTLR3E'
    },
    {
      status: 210,
      sku: 'PM0071368',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226862',
      rtl_batch_array: '6226862',
      name_search: 'HOCGLORY',
      name: "Hosta 'Color Glory'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7B2SZWBW;8N4G544X;1S7663BL;1SNJY2E8;NCEYZGG6;YP224SSB;GL3S3S5V',
      statusContent: 'dot',
      hash: 'DZ39V7H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ39V7H6'
    },
    {
      status: 210,
      sku: 'PM0085598',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385563',
      rtl_batch_array: '6385563',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '79RKZELL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79RKZELL'
    },
    {
      status: 210,
      sku: 'PM0071370',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226864',
      rtl_batch_array: '6226864',
      name_search: 'IRSCURRI',
      name: "Iris sibirica 'Currier'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5546BD39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5546BD39'
    },
    {
      status: 210,
      sku: 'PM0082053',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349563',
      rtl_batch_array: '6349563',
      name_search: 'ANHJPKON',
      name: "Andropogon hallii 'JS Purple Konza'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L4C41G1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4C41G1T'
    },
    {
      status: 810,
      sku: 'PM0079386',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317623',
      rtl_batch_array: '6317623',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '015020C3',
      rng_range_identifier: 'H015020C3',
      rng_range_description: 'H15 cm 20 cm C3',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 2.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'ARRX4AYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARRX4AYJ'
    },
    {
      status: 210,
      sku: 'PM0069882',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6226099',
      rtl_batch_array: '6300225, 6226099',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 224,
      chnn_stock_retail: 1724,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: '67C7ZV76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67C7ZV76'
    },
    {
      status: 210,
      sku: 'PM0069878',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486256',
      rtl_batch_array: '6217675, 5486256',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: '6BYL8XCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BYL8XCJ'
    },
    {
      status: 210,
      sku: 'PM0069883',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300233',
      rtl_batch_array: '6300233',
      name_search: 'SAEANGUS',
      name: "Salix elaeagnos 'Angustifolia'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4475,
      chnn_stock_retail: 4475,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W1T9D8LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1T9D8LE'
    },
    {
      status: 210,
      sku: 'PM0069884',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300234',
      rtl_batch_array: '6300234, 6310383',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 5500,
      chnn_stock_retail: 13980,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'L3KYA146',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3KYA146'
    },
    {
      status: 210,
      sku: 'PM0053519',
      core_name: 'Plant',
      sppl_ean: '8720664694255',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054696',
      rtl_batch_array: '6054696',
      name_search: 'HYMBLIZZ',
      name: 'Hypericum Miracle Blizz',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDDEBKS8;J2E353SS;L53YY4AR;VX6K1611;SJPZECLX;9W6SC131;E5PSW1YW',
      statusContent: 'dot',
      hash: 'WD5N58BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WD5N58BK'
    },
    {
      status: 210,
      sku: 'PM0067198',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177918',
      rtl_batch_array: '6177918',
      name_search: 'SANGTOWE',
      name: 'Sambucus nigra Golden Tower',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1047,
      chnn_stock_retail: 1047,
      sppl_prcp: 6.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VTP8L3DB',
      statusContent: 'dot',
      hash: 'V7PZ5KT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7PZ5KT9'
    },
    {
      status: 210,
      sku: 'PM0065319',
      core_name: 'Plant',
      sppl_ean: '8720664879195',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162710',
      rtl_batch_array: '6162710',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 4.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'YKGPKSWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKGPKSWY'
    },
    {
      status: 210,
      sku: 'PM0070188',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220723',
      rtl_batch_array: '6220723',
      name_search: 'DEBALLKL',
      name: "Delphinium (B) 'Ballkleid'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 2.068,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YYPGDL4H',
      statusContent: 'dot',
      hash: 'EWCAVDZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWCAVDZ8'
    },
    {
      status: 210,
      sku: 'PM0053167',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279842',
      rtl_batch_array: '6279842',
      name_search: 'DISDISE',
      name: "Diervilla sessilifolia 'Dise'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3255,
      chnn_stock_retail: 3255,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AVZJSVRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVZJSVRL'
    },
    {
      status: 210,
      sku: 'PM0002523',
      core_name: 'Plant',
      sppl_ean: '8720664688094',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5866491',
      rtl_batch_array: '5866491, 6187551, 6350052',
      name_search: 'ECPJSTIL',
      name: "Echinacea p. 'JS Stiletto'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 492,
      chnn_stock_retail: 1096,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRGYTA56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRGYTA56'
    },
    {
      status: 210,
      sku: 'PM0067401',
      core_name: 'Plant',
      sppl_ean: '8720664691032',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6034343',
      rtl_batch_array: '6034343, 6319696',
      name_search: 'GESGLENL',
      name: "Geranium sanguineum 'Glenluce'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 369,
      chnn_stock_retail: 465,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9L5XVEV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L5XVEV7'
    },
    {
      status: 210,
      sku: 'PM0071367',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226861',
      rtl_batch_array: '6226861',
      name_search: 'HOBKID',
      name: "Hosta 'Banana Kid'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '77GECSG7;L5JDWPCE;63TXA5X8;KY7DE49R;GSBVJDYT;WG46D5YD;27T2S58X;94D85XVH',
      statusContent: 'dot',
      hash: 'DAHDCY6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAHDCY6S'
    },
    {
      status: 210,
      sku: 'PM0066984',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353268',
      rtl_batch_array: '6353268',
      name_search: 'RHDMANN',
      name: "Rhodohypoxis 'Donald Mann'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2399,
      chnn_stock_retail: 2399,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZSXB64BA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSXB64BA'
    },
    {
      status: 210,
      sku: 'PM0071355',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226848',
      rtl_batch_array: '6226848',
      name_search: 'HOGUACAM',
      name: "Hosta 'Guacamole'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9YA69HSY;4SE4X6X6;YDTB3R46;5GKDYSLL;VT113561;TX9H3LCY;VGBYR7BB;A2BXHDR6;R1VKKB55',
      statusContent: 'dot',
      hash: 'LSCZXJTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSCZXJTE'
    },
    {
      status: 210,
      sku: 'PM0066983',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353252',
      rtl_batch_array: '6353252',
      name_search: 'PUVBROOC',
      name: "Pulmonaria 'Victorian Brooch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T29G3V6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T29G3V6Y'
    },
    {
      status: 210,
      sku: 'PM0069697',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301516',
      rtl_batch_array: '6301516',
      name_search: 'POPALUST',
      name: 'Potentilla palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JR17JXCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JR17JXCH'
    },
    {
      status: 810,
      sku: 'PM0069698',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353225',
      rtl_batch_array: '6353225',
      name_search: 'PORECTA',
      name: 'Potentilla recta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KCBYPB6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCBYPB6C'
    },
    {
      status: 210,
      sku: 'PM0066990',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353210',
      rtl_batch_array: '6353210',
      name_search: 'SADJOY',
      name: "Salvia 'Dyson's Joy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KCCRRNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KCCRRNW'
    },
    {
      status: 810,
      sku: 'PM0067015',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353505',
      rtl_batch_array: '6353505',
      name_search: 'ZIAPTERA',
      name: 'Zizia aptera',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7PVVPY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7PVVPY5'
    },
    {
      status: 210,
      sku: 'PM0066991',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353324',
      rtl_batch_array: '6353324',
      name_search: 'SANECCOM',
      name: "Salvia nem. 'Caradonna Compact'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 914,
      chnn_stock_retail: 914,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSGZV1VH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSGZV1VH'
    },
    {
      status: 210,
      sku: 'PM0066995',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353447',
      rtl_batch_array: '6353447',
      name_search: 'SIREGIA',
      name: 'Silene regia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LY32LXSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY32LXSE'
    },
    {
      status: 210,
      sku: 'PM0066998',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353459',
      rtl_batch_array: '6353459',
      name_search: 'SPAIROID',
      name: 'Sporobolus airoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.391,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C667Y1BA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C667Y1BA'
    },
    {
      status: 910,
      sku: 'PM0020411',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIPBECKY',
      name: "Dianthus (PL) 'Pretty Becky'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1K4PH1EY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1K4PH1EY'
    },
    {
      status: 210,
      sku: 'PM0069720',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295830',
      rtl_batch_array: '6295830',
      name_search: 'RULGOLDQ',
      name: "Rudbeckia laciniata 'Goldquelle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 446,
      chnn_stock_retail: 446,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XC7LXNDK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC7LXNDK'
    },
    {
      status: 210,
      sku: 'PM0067400',
      core_name: 'Plant',
      sppl_ean: '8720664517639',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5571355',
      rtl_batch_array: '5571355',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      imageBatch: 'K8GNJ26S',
      statusContent: 'dot',
      hash: '7NE7WK1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NE7WK1B'
    },
    {
      status: 210,
      sku: 'PM0008495',
      core_name: 'Plant',
      sppl_ean: '8720349465880',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5507483',
      rtl_batch_array: '6161789, 5507483, 6193627',
      name_search: 'IREVARIE',
      name: "Iris ensata 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2437,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBCBWHJ6;JR59S7KT;1AT5TN34;EG4D7JPG;9JDWZK65;6L7WGH3W',
      statusContent: 'dot',
      hash: 'RLEZRSXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLEZRSXP'
    },
    {
      status: 810,
      sku: 'PM0053514',
      core_name: 'Plant',
      sppl_ean: '8720664692480',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054687',
      rtl_batch_array: '6054687',
      name_search: 'HECTCOAS',
      name: "Hosta 'Coast to Coast'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCREPPT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCREPPT2'
    },
    {
      status: 810,
      sku: 'PM0053344',
      core_name: 'Plant',
      sppl_ean: '8720664690127',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091069',
      rtl_batch_array: '6091069',
      name_search: 'FIPRSCHL',
      name: "Filipendula palmata 'Rosa Schleier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1TAWLSKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TAWLSKL'
    },
    {
      status: 210,
      sku: 'PM0067390',
      core_name: 'Plant',
      sppl_ean: '8720626310759',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5308226',
      rtl_batch_array: '5308226',
      name_search: 'LIMVARIE',
      name: "Liriope muscari 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_prcp: 2.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TH6ES4SP;YZP9ND3H',
      imageBatch: 'JH4GWBN8',
      statusContent: 'dot',
      hash: '27JGKSY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27JGKSY6'
    },
    {
      status: 210,
      sku: 'PM0067389',
      core_name: 'Plant',
      sppl_ean: '8720664685987',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5205955',
      rtl_batch_array: '5205955',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      imageBatch: '6CZ5666E',
      statusContent: 'dot',
      hash: '2XA5XJS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XA5XJS5'
    },
    {
      status: 910,
      sku: 'PM0068354',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      rng_range_identifier: 'H020030C7',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'C2K46EAX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C2K46EAX'
    },
    {
      status: 210,
      sku: 'PM0070187',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220722',
      rtl_batch_array: '6220722',
      name_search: 'DEATLANT',
      name: "Delphinium (B) 'Atlantis'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1608,
      chnn_stock_retail: 1608,
      sppl_order_minimum: 3,
      sppl_prcp: 2.068,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VS6DTJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VS6DTJ2'
    },
    {
      status: 210,
      sku: 'PM0071369',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226863',
      rtl_batch_array: '6226863',
      name_search: 'IRSBSUGA',
      name: "Iris sibirica 'Butter and Sugar'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N496LAH9;B5PX1Y57;CJW1G7WP;5EZTHX1A;JZC491DH',
      statusContent: 'dot',
      hash: '83JELK2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83JELK2E'
    },
    {
      status: 210,
      sku: 'PM0082054',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349566',
      rtl_batch_array: '6349566',
      name_search: 'ANHFJASM',
      name: 'Anemone hupehensis Fantasy Jasmine',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECA6VVY6;VCSKVYKN',
      statusContent: 'dot',
      hash: 'W5T15L37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5T15L37'
    },
    {
      status: 210,
      sku: 'PM0067391',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837450',
      rtl_batch_array: '5318989, 5837450',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 990,
      chnn_stock_retail: 1474,
      sppl_prcp: 1.951,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      statusContent: 'dot',
      hash: '77G98JR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77G98JR4'
    },
    {
      status: 810,
      sku: 'PM0053537',
      core_name: 'Plant',
      sppl_ean: '8720664698109',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054781',
      rtl_batch_array: '6054781',
      name_search: 'LIGBITCO',
      name: "Liriope graminifolia 'Bitcoin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6396LENP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6396LENP'
    },
    {
      status: 210,
      sku: 'PM0067406',
      core_name: 'Plant',
      sppl_ean: '8720664686014',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5891086',
      rtl_batch_array: '5891086',
      name_search: 'COSROSEA',
      name: "Cortaderia selloana 'Rosea'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 469,
      chnn_stock_retail: 469,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25X4TCHJ;27YWYHL2;Y7GGS9RN;A1CBSRN5;82XSDGPX;G2Y373YL;WG33R43S;H1AWYZBV',
      imageBatch: 'XZBJ8SRL',
      statusContent: 'dot',
      hash: 'DP99Z2V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP99Z2V7'
    },
    {
      status: 210,
      sku: 'PM0067200',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244109',
      rtl_batch_array: '6244109',
      name_search: 'VIDMDODG',
      name: "Viburnum dilatatum 'Michael Dodge'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1490,
      chnn_stock_retail: 1490,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EH1E5PEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH1E5PEW'
    },
    {
      status: 210,
      sku: 'PM0067405',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272846',
      rtl_batch_array: '6272846',
      name_search: 'HENBLUSH',
      name: "Hebe 'Nicola's Blush'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1222,
      chnn_stock_retail: 1222,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7N958EYK',
      statusContent: 'dot',
      hash: 'NGA3WP36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGA3WP36'
    },
    {
      status: 210,
      sku: 'PM0069889',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217690',
      rtl_batch_array: '6217690',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_prcp: 1.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: '5XKNZJAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XKNZJAR'
    },
    {
      status: 210,
      sku: 'PM0067201',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244110',
      rtl_batch_array: '6244110',
      name_search: 'VIDSWAX',
      name: "Viburnum dilatatum 'Sealing Wax'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 2995,
      chnn_stock_retail: 2995,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKGPRAJ9;G5VEDNCG;5TXJYA9T;T1HJK8HK;HNVD6TAZ;JA8HHGV5;HLZLXR6V;WB4SEWKH;8D7272V5;JL8GJLVA',
      statusContent: 'dot',
      hash: 'A2N8WX7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2N8WX7Y'
    },
    {
      status: 210,
      sku: 'PM0067392',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284531',
      rtl_batch_array: '5318990, 6284531, 5837453',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3078,
      chnn_stock_retail: 4259,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      statusContent: 'dot',
      hash: 'RC6Z7TCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC6Z7TCP'
    },
    {
      status: 210,
      sku: 'PM0067202',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244112',
      rtl_batch_array: '6244112',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: 'PP8CDNNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP8CDNNB'
    },
    {
      status: 910,
      sku: 'PM0067413',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACMMRROS',
      name: 'Achillea millefolium Milly Rock Rose',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'CT7ZSA4D;5GSPXCRN',
      statusContent: 'dot',
      hash: 'B66TWPHB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'B66TWPHB'
    },
    {
      status: 210,
      sku: 'PM0033307',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330126',
      rtl_batch_array: '6330126',
      name_search: 'VEJSPOTS',
      name: "Verbascum 'Jackie in Spots'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L86DANDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L86DANDJ'
    },
    {
      status: 210,
      sku: 'PM0053491',
      core_name: 'Plant',
      sppl_ean: '8720664690462',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054625',
      rtl_batch_array: '6054625',
      name_search: 'EDI75119',
      name: "Galanthus (dou) 'Hippolyta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCC2E1C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCC2E1C3'
    },
    {
      status: 210,
      sku: 'PM0033242',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229433',
      rtl_batch_array: '6229433',
      name_search: 'AQSMRIVO',
      name: "Aquilegia 'Spring Magic Rose and Ivory'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7670,
      chnn_stock_retail: 7670,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z28RT7R1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z28RT7R1'
    },
    {
      status: 810,
      sku: 'PM0070189',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220726',
      rtl_batch_array: '6220726',
      name_search: 'DECCHA',
      name: "Delphinium 'Cha Cha'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H8NEKLE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8NEKLE1'
    },
    {
      status: 210,
      sku: 'PM0067223',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177964',
      rtl_batch_array: '6242963, 6350366, 6177964',
      name_search: 'HEWTEMPT',
      name: "Hemerocallis 'White Temptation'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2583,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVG9VADN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVG9VADN'
    },
    {
      status: 210,
      sku: 'PM0085599',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385564',
      rtl_batch_array: '6385564',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 3.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWC9KY4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWC9KY4Z'
    },
    {
      status: 910,
      sku: 'PM0020564',
      core_name: 'Plant',
      sppl_ean: '8720349438037',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5427504',
      rtl_batch_array: '5427504',
      name_search: 'LIMUSCAR',
      name: 'Liriope muscari',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 1.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8G328LN;HCWXY5YV;J5JSCB5H;SES94664;6AVPE4HA;ZLE4P9CW;YSZYRDV1;488LSVLV',
      statusContent: 'dot',
      hash: '9NSPAV86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NSPAV86'
    },
    {
      status: 210,
      sku: 'PM0053376',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288291',
      rtl_batch_array: '6288291',
      name_search: 'ACMAGELL',
      name: 'Acaena magellanica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 172,
      chnn_stock_retail: 172,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '189KADWA;SS9AK56Z;XLL6SA5J',
      statusContent: 'dot',
      hash: 'KLL1D4V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLL1D4V5'
    },
    {
      status: 210,
      sku: 'PM0085600',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385565',
      rtl_batch_array: '6385565',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: '5NNEZKE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NNEZKE8'
    },
    {
      status: 210,
      sku: 'PM0085601',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385566',
      rtl_batch_array: '6385566',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 1598,
      chnn_stock_retail: 1598,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: 'CB6N779K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CB6N779K'
    },
    {
      status: 210,
      sku: 'PM0067440',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191456',
      rtl_batch_array: '6191456',
      name_search: 'POVULGAR',
      name: 'Polypodium vulgare',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3847,
      chnn_stock_retail: 3847,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ESLXN3BR;D792YKHT;CSAYPLAG;43ARZVG5;7YEHZVRP;RNCX8J6B',
      statusContent: 'dot',
      hash: 'REPLYEY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REPLYEY8'
    },
    {
      status: 210,
      sku: 'PM0085602',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385567',
      rtl_batch_array: '6385567',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 1580,
      chnn_stock_retail: 1580,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: '7VZH28ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VZH28ZH'
    },
    {
      status: 210,
      sku: 'PM0085603',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385568',
      rtl_batch_array: '6385568',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: '5L4P6SJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L4P6SJ3'
    },
    {
      status: 210,
      sku: 'PM0085604',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385569',
      rtl_batch_array: '6385569',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: '143NTY65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '143NTY65'
    },
    {
      status: 210,
      sku: 'PM0085605',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385570',
      rtl_batch_array: '6385570',
      name_search: 'ILMBANGE',
      name: "Ilex meserveae 'Blue Angel'",
      sppl_size_code: '020025KL',
      rng_range_identifier: 'H020025RB',
      rng_range_description: 'H20 cm 25 cm Root ball',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 3.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18SDPRET',
      statusContent: 'dot',
      hash: 'JB415G9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB415G9B'
    },
    {
      status: 210,
      sku: 'PM0082055',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349568',
      rtl_batch_array: '6349568',
      name_search: 'ARPBRED',
      name: "Armeria pseudarmeria 'Ballerina Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 158,
      chnn_stock_retail: 158,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWXS5NZ1;BV9XL5VZ;DXGCSJ6W;LJ7D65HS;753RBZ1W;K9TTLJN7;YP563LAC;A5LSVPPJ;6ZW8Y7XY',
      statusContent: 'dot',
      hash: 'J62H2CJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J62H2CJC'
    },
    {
      status: 910,
      sku: 'PM0079395',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317634',
      rtl_batch_array: '6317634',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '065070C45BO',
      rng_range_identifier: 'BALL\u00d8070H065070C45',
      rng_range_description: 'Ball \u00d8 70 cm H65 cm 70 cm C45',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 65.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'XHNRA2WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHNRA2WC'
    },
    {
      status: 910,
      sku: 'PM0079396',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317635',
      rtl_batch_array: '6317635',
      name_search: 'TABFAURE',
      name: "Taxus baccata 'Fastigiata Aurea'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 24.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CHGPNCTC;RERJP6PT;T7H9XT3A;L86DXY61',
      statusContent: 'dot',
      hash: 'N4NH2D5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4NH2D5A'
    },
    {
      status: 210,
      sku: 'PM0085606',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385571',
      rtl_batch_array: '6385571',
      name_search: 'ILMBANGE',
      name: "Ilex meserveae 'Blue Angel'",
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18SDPRET',
      statusContent: 'dot',
      hash: 'B8DTJNYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8DTJNYR'
    },
    {
      status: 210,
      sku: 'PM0059402',
      core_name: 'Plant',
      sppl_ean: '8720664879232',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6114115',
      rtl_batch_array: '6114115',
      name_search: 'PIMNANA',
      name: "Picea mariana 'Nana'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 594,
      chnn_stock_retail: 594,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZRGP8YSZ;2DY1VWNJ;9ADPASZZ;WYDGVDXE;PCKWYBZS;GJ3YZ933;2JY5T4GN',
      statusContent: 'dot',
      hash: '7XG8LWKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XG8LWKW'
    },
    {
      status: 210,
      sku: 'PM0085607',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385572',
      rtl_batch_array: '6385572',
      name_search: 'ILMBANGE',
      name: "Ilex meserveae 'Blue Angel'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_prcp: 3.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18SDPRET',
      statusContent: 'dot',
      hash: '8A71XBW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8A71XBW8'
    },
    {
      status: 210,
      sku: 'PM0085608',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385573',
      rtl_batch_array: '6385573',
      name_search: 'ILMBFANT',
      name: 'Ilex meserveae Blue Fantasy',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DWJH63RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWJH63RW'
    },
    {
      status: 910,
      sku: 'PM0079400',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317640',
      rtl_batch_array: '6317640',
      name_search: 'TABSUMME',
      name: "Taxus baccata 'Summergold'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 4.602,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KSV5SYC;J3ATLRXA;85VREB5P;1B7X7CVY;7XH9WWVH;N261GH3S',
      statusContent: 'dot',
      hash: 'NDVES81L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDVES81L'
    },
    {
      status: 210,
      sku: 'PM0082056',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349569',
      rtl_batch_array: '6349569',
      name_search: 'ARPBWHIT',
      name: "Armeria pseudarmeria 'Ballerina White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1S742RR;T317EJ8X;LSLX9V54;YWLRRVKK;7A6DECKZ;65XL677B;E8DD93KB;J21916SD;YESN9L7W;WHN73RJ9;6YYGX185;C5ZHAZ2Z;GV8SPS1T;W372W79N;6HLNEDH7;WRSBBBB6;23RVN5A9;J7KG8ZTX;RB5P7NL5;V3L5ZJ6S;DJ2LWXP7;J94NLX4K',
      statusContent: 'dot',
      hash: 'D2X9AT4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2X9AT4P'
    },
    {
      status: 210,
      sku: 'PM0085609',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385574',
      rtl_batch_array: '6385574',
      name_search: 'ILMBFANT',
      name: 'Ilex meserveae Blue Fantasy',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_prcp: 4.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9YYNNTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9YYNNTK'
    },
    {
      status: 210,
      sku: 'PM0082057',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349570',
      rtl_batch_array: '6349570',
      name_search: 'ARMCCOLA',
      name: "Artemisia maritima 'Coca-cola'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8GP5CK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8GP5CK7'
    },
    {
      status: 210,
      sku: 'PM0085610',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385575',
      rtl_batch_array: '6385575',
      name_search: 'ILMBMAID',
      name: 'Ilex meserveae Blue Maid',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BZE31P4;A7CZK69D;7LV39KNB;L2KZCAKX',
      statusContent: 'dot',
      hash: 'YNV4L5YP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNV4L5YP'
    },
    {
      status: 210,
      sku: 'PM0074313',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269619',
      rtl_batch_array: '6269619',
      name_search: 'VIMSWHIT',
      name: "Vinca minor 'Simply White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 348,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DLANTSLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLANTSLS'
    },
    {
      status: 210,
      sku: 'PM0085611',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385577',
      rtl_batch_array: '6385577',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: 'DHEP6BKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHEP6BKH'
    },
    {
      status: 210,
      sku: 'PM0085612',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385579',
      rtl_batch_array: '6385579',
      name_search: 'ILEMBPRI',
      name: "Ilex meserveae 'Blue Princess'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PADZJ324;XGVTGR1A',
      statusContent: 'dot',
      hash: 'YXW349GN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXW349GN'
    },
    {
      status: 210,
      sku: 'PM0082058',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349573',
      rtl_batch_array: '6349573',
      name_search: 'ASMLLING',
      name: "Asarum maximum 'Ling Ling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VYEC4D1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYEC4D1X'
    },
    {
      status: 210,
      sku: 'PM0082059',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349574',
      rtl_batch_array: '6349574, 6352616',
      name_search: 'ASSPLEND',
      name: 'Asarum splendens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1158,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E52CJL5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E52CJL5B'
    },
    {
      status: 210,
      sku: 'PM0082060',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349575',
      rtl_batch_array: '6349575',
      name_search: 'ASISOULM',
      name: "Asclepias incarnata 'Soulmate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 743,
      chnn_stock_retail: 743,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8A9GTD3C;L64W1S7Y;KN7ZN83B;BR7SWRWE;2Y95LG6T;1JH1PA99;DZRXHV7Z;RZXB691E',
      statusContent: 'dot',
      hash: 'GBJKPHPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBJKPHPC'
    },
    {
      status: 210,
      sku: 'PM0082061',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349580',
      rtl_batch_array: '6349580',
      name_search: 'ASMACROP',
      name: 'Aster macrophyllus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HRPXP471',
      statusContent: 'dot',
      hash: 'TH1SH5J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH1SH5J7'
    },
    {
      status: 210,
      sku: 'PM0065375',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287032',
      rtl_batch_array: '6287032',
      name_search: 'JUPMJULE',
      name: "Juniperus pfitzeriana 'Mint Julep'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2698,
      chnn_stock_retail: 2698,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ECA5W8B7;WDB1X2HH;G9494HJY;H8A3JGVH;97N7RTGT;TL2TY95E;TEPY57C3;4G143PN2;2KL5BZSJ;ZRYC7KKP;AXK4LGDR;11WNEGGE;AJ126KGJ;B7D947SS;X78L6BGC',
      statusContent: 'dot',
      hash: '7JBSPS49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JBSPS49'
    },
    {
      status: 910,
      sku: 'PM0079405',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317645',
      rtl_batch_array: '6317645',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 17.605,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: 'CA4L5E5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA4L5E5B'
    },
    {
      status: 210,
      sku: 'PM0032929',
      core_name: 'Plant',
      sppl_ean: '8720664694293',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '5565331',
      rtl_batch_array: '5565331, 6338991',
      name_search: 'HYPMTRIC',
      name: "Hypericum moserianum 'Tricolor'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 2714,
      chnn_stock_retail: 2914,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XJXT9L93;B39K2H5X;X42CRZK5;236CJRZ5;CJ5D2ZP2;CSEE1Z5X;877EHJ5N;75JAZ3E6',
      statusContent: 'dot',
      hash: 'EKS5PNYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKS5PNYH'
    },
    {
      status: 810,
      sku: 'PM0085613',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385580',
      rtl_batch_array: '6385580',
      name_search: 'ILEMBPRI',
      name: "Ilex meserveae 'Blue Princess'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PADZJ324;XGVTGR1A',
      statusContent: 'dot',
      hash: 'JX4JBLDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JX4JBLDG'
    },
    {
      status: 810,
      sku: 'PM0082062',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349583',
      rtl_batch_array: '6349583',
      name_search: 'ATNBLACE',
      name: "Athyrium niponicum 'Burgundy Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5Z5WP98L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Z5WP98L'
    },
    {
      status: 210,
      sku: 'PM0082063',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349584',
      rtl_batch_array: '6349584',
      name_search: 'ATNCSURF',
      name: "Athyrium niponicum 'Crested Surf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VE9KG4SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE9KG4SS'
    },
    {
      status: 210,
      sku: 'PM0082064',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349586',
      rtl_batch_array: '6349586',
      name_search: 'ATNSFALL',
      name: "Athyrium niponicum 'Silver Falls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 824,
      chnn_stock_retail: 824,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TN2RJXRL;4CS359EG',
      statusContent: 'dot',
      hash: '8XRDRVZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XRDRVZ1'
    },
    {
      status: 210,
      sku: 'PM0082065',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349588',
      rtl_batch_array: '6349588',
      name_search: 'AUSGCUSH',
      name: "Aurinia saxatilis 'Gold Cushion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 528,
      chnn_stock_retail: 528,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KRHGZC3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRHGZC3J'
    },
    {
      status: 210,
      sku: 'PM0085614',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385581',
      rtl_batch_array: '6385581',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 4.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: 'G873XBYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G873XBYZ'
    },
    {
      status: 210,
      sku: 'PM0065324',
      core_name: 'Plant',
      sppl_ean: '8720664887923',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162716',
      rtl_batch_array: '6162716',
      name_search: 'TABDAVID',
      name: "Taxus baccata 'David'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1094,
      chnn_stock_retail: 1094,
      sppl_prcp: 4.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JGV8D2V;61VZV35Y;K2SB5P8G',
      statusContent: 'dot',
      hash: 'DGYJ7NLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGYJ7NLC'
    },
    {
      status: 210,
      sku: 'PM0085615',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385582',
      rtl_batch_array: '6385582',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 4.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: '6A29ZZ87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A29ZZ87'
    },
    {
      status: 210,
      sku: 'PM0048265',
      core_name: 'Plant',
      sppl_ean: '8720664685994',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014790',
      rtl_batch_array: '6014790',
      name_search: 'COSRFEDE',
      name: "Cortaderia selloana 'Rosa Feder'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXDBZ4PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXDBZ4PV'
    },
    {
      status: 210,
      sku: 'PM0071373',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226873',
      rtl_batch_array: '6226873',
      name_search: 'PAKROSEN',
      name: "Paeonia (LD) 'Karl Rosenfield'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25VJ5AS1;DE8L34XV;NGXVVV8Y;JAVSBX8G;V622SNR3;14VCRPEA;HP71LGS8;8P1K23AV;YA9NP5RK;EY3WEJNP',
      statusContent: 'dot',
      hash: '6C21X31T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6C21X31T'
    },
    {
      status: 910,
      sku: 'PM0079409',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317649',
      rtl_batch_array: '6317649',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 13.463,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: '1XL9PD3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XL9PD3K'
    },
    {
      status: 210,
      sku: 'PM0082066',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349589',
      rtl_batch_array: '6349589',
      name_search: 'BAAALBA',
      name: "Baptisia australis 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1001,
      chnn_stock_retail: 1001,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LC9X6JAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC9X6JAC'
    },
    {
      status: 210,
      sku: 'PM0085616',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385583',
      rtl_batch_array: '6385583',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1170,
      chnn_stock_retail: 1170,
      sppl_prcp: 5.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: '9VK3Z4CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VK3Z4CX'
    },
    {
      status: 210,
      sku: 'PM0071377',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6226877',
      rtl_batch_array: '6226877, 6297477',
      name_search: 'ASMAJOR',
      name: 'Astrantia major',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXRH9BAN;C48S1Z5D;DB66DHVC;HW6RB225;6WXGEJV5;5EV4ZX66;J4H8SDAC;DTDN2AZ1;ERRDVXVA',
      statusContent: 'dot',
      hash: 'BK7LGAD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BK7LGAD1'
    },
    {
      status: 210,
      sku: 'PM0067313',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181318',
      rtl_batch_array: '6181318',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: 'GSDXTP9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSDXTP9V'
    },
    {
      status: 210,
      sku: 'PM0085617',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385584',
      rtl_batch_array: '6385584',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      sppl_size_code: '030050KL',
      rng_range_identifier: 'H030050RB',
      rng_range_description: 'H30 cm 50 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: 'R1G9BTCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1G9BTCL'
    },
    {
      status: 210,
      sku: 'PM0079412',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321852',
      rtl_batch_array: '6321852',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '100120C5',
      rng_range_identifier: 'H100120C5',
      rng_range_description: 'H100 cm 120 cm C5',
      sppl_stock_available: 1676,
      chnn_stock_retail: 1676,
      sppl_prcp: 4.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'ASVR8KYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASVR8KYT'
    },
    {
      status: 210,
      sku: 'PM0071379',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226880',
      rtl_batch_array: '6226880',
      name_search: 'MOPLACE',
      name: "Monarda 'Pink Lace'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CVE7KLA9;DEC66SB1;ABGSZS8E;2JESWRYK;N64VVWWS;8LGN9ZZG;9YK53SAY',
      statusContent: 'dot',
      hash: 'ANY79TVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANY79TVH'
    },
    {
      status: 210,
      sku: 'PM0065715',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244383',
      rtl_batch_array: '6244383',
      name_search: 'JUSBSTAR',
      name: "Juniperus squamata 'Blue Star'",
      sppl_size_code: '020025C34',
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      sppl_stock_available: 3533,
      chnn_stock_retail: 3533,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '223S71E6;YL28ZCAS;BESLT261;C5DRTS5J;JR1P944L;27XBARC5;KE55BKLZ;CYYDK5J8;TP7NVDP8;VJA9BAZY;68BCEN7R;5KJ2YNCK;XX713EV4;4717E53N;LXXWRJ96;SDP1WS9C;2KN4YKZ4;TYC557EP;882YW612;A8PLDHJH;LYT7PRN9;8WHJ75GB;YEL79DVY;XHVVVA2E',
      statusContent: 'dot',
      hash: '1PYCNZBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PYCNZBW'
    },
    {
      status: 210,
      sku: 'PM0085618',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385585',
      rtl_batch_array: '6385585',
      name_search: 'ILEXMHEC',
      name: "Ilex meserveae 'Heckenpracht'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VAZGWKRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAZGWKRN'
    },
    {
      status: 210,
      sku: 'PM0068194',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193758',
      rtl_batch_array: '6193758',
      name_search: 'ACGOGON',
      name: "Acorus gramineus 'Ogon'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XEX4W2P1;7B3N2R24;HXBERTTP;PV39NESW;E264R1NJ;R5PEL6CS;PE7Z6R3T;V4T6VD5X',
      statusContent: 'dot',
      hash: 'Y3C9VDYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3C9VDYX'
    },
    {
      status: 210,
      sku: 'PM0085619',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385586',
      rtl_batch_array: '6385586',
      name_search: 'ILEXMHEC',
      name: "Ilex meserveae 'Heckenpracht'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 635,
      chnn_stock_retail: 635,
      sppl_prcp: 5.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '49LS66ZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49LS66ZG'
    },
    {
      status: 210,
      sku: 'PM0082067',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349591',
      rtl_batch_array: '6349591',
      name_search: 'BESSEMPE',
      name: "Berberis stenophylla 'Semperflorens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRS612CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRS612CR'
    },
    {
      status: 210,
      sku: 'PM0065294',
      core_name: 'Plant',
      sppl_ean: '8720664856318',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162681',
      rtl_batch_array: '6162681',
      name_search: 'CHLGLOBO',
      name: "Chamaecyparis lawsoniana 'Globosa'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 780,
      chnn_stock_retail: 780,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WDS29B36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDS29B36'
    },
    {
      status: 210,
      sku: 'PM0068258',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196554',
      rtl_batch_array: '6196554',
      name_search: 'PIGECHIN',
      name: "Picea glauca 'Echiniformis'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 606,
      chnn_stock_retail: 606,
      sppl_prcp: 5.339,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJC562T8;YBDXH7SH',
      statusContent: 'dot',
      hash: 'NED5X23H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NED5X23H'
    },
    {
      status: 210,
      sku: 'PM0016393',
      core_name: 'Plant',
      sppl_ean: '8720664693173',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495621',
      rtl_batch_array: '5495621',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 484,
      chnn_stock_retail: 484,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: '9RZD4Z2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RZD4Z2X'
    },
    {
      status: 810,
      sku: 'PM0053348',
      core_name: 'Plant',
      sppl_ean: '8720664690325',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053862',
      rtl_batch_array: '6053862',
      name_search: 'EDI61699',
      name: 'Fritillaria persica',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PEKTERGR;S3AG7JCA;71GX3GJ9;7V3PSE2W;6XE65EVJ;GSTW5LTT;W3HHL9TK;J57AG375;9CXTXRTB',
      statusContent: 'dot',
      hash: '4TX53ZDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TX53ZDN'
    },
    {
      status: 210,
      sku: 'PM0082068',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349592',
      rtl_batch_array: '6349592, 6374010',
      name_search: 'BEBACH',
      name: "Bergenia 'Bach'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 246,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDYLYSYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDYLYSYG'
    },
    {
      status: 810,
      sku: 'PM0082069',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349595',
      rtl_batch_array: '6349595, 6374013',
      name_search: 'BEIQUEEN',
      name: "Bergenia 'Ice Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 85,
      chnn_stock_retail: 181,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CTXV9TR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTXV9TR2'
    },
    {
      status: 210,
      sku: 'PM0028688',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244234',
      rtl_batch_array: '6244234',
      name_search: 'POFMRROB',
      name: 'Potentilla fruticosa Marian Red Robin',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 393,
      chnn_stock_retail: 393,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '19N6GREH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19N6GREH'
    },
    {
      status: 210,
      sku: 'PM0085620',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385587',
      rtl_batch_array: '6385587',
      name_search: 'ILEMHECK',
      name: "Ilex meserveae 'Heckenstar'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 4.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ABH6LW4X',
      statusContent: 'dot',
      hash: '4W8P7GJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W8P7GJL'
    },
    {
      status: 210,
      sku: 'PM0053346',
      core_name: 'Plant',
      sppl_ean: '8720664690240',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053859',
      rtl_batch_array: '6053859',
      name_search: 'FRAELAN',
      name: "Fragaria ananassa 'Elan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9L1CDNAJ',
      statusContent: 'dot',
      hash: 'B6BEXD6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6BEXD6D'
    },
    {
      status: 210,
      sku: 'PM0085621',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385588',
      rtl_batch_array: '6385588',
      name_search: 'ILEMHECK',
      name: "Ilex meserveae 'Heckenstar'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_prcp: 5.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ABH6LW4X',
      statusContent: 'dot',
      hash: 'RJRLAHYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJRLAHYX'
    },
    {
      status: 210,
      sku: 'PM0068464',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264769',
      rtl_batch_array: '6264769',
      name_search: 'CHPSUNGO',
      name: "Chamaecyparis pisifera 'Sungold'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 753,
      chnn_stock_retail: 753,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZHLJTR1H;5XZEYB8G;7YNLHJLH;Y1BTW97G;GZJG415D;ECLLZG7H;JAYJDR7D;Z2T7J22C;5592HDCX;AA25R4RZ;2Z5DKD8B;9CYC76PC;ZHDTBX3N;B9C4KJ17;5D5APVLG;64XLHEK9;WP8N54HS',
      statusContent: 'dot',
      hash: 'Y1VNLJC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1VNLJC1'
    },
    {
      status: 810,
      sku: 'PM0079390',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317629',
      rtl_batch_array: '6317629',
      name_search: 'PISYLVES',
      name: 'Pinus sylvestris',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 3.567,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7L3J9VNA;KX78HJLD;N732DC1A;GGJG12CS;HKS1N5EE;C1SG5VDN;2BV6TH7P',
      statusContent: 'dot',
      hash: 'RG8NLEBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG8NLEBV'
    },
    {
      status: 210,
      sku: 'PM0067435',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229114',
      rtl_batch_array: '6229114',
      name_search: 'HEBBISHO',
      name: "Helenium bigelovii 'The Bishop'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5127,
      chnn_stock_retail: 5127,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6RP8WD71;NZYPCXL2;NPWK2P47',
      statusContent: 'dot',
      hash: 'KEARLB9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEARLB9Z'
    },
    {
      status: 210,
      sku: 'PM0067248',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228433',
      rtl_batch_array: '6228433',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '030050C3',
      rng_range_identifier: 'H030050C3',
      rng_range_description: 'H30 cm 50 cm C3',
      sppl_stock_available: 626,
      chnn_stock_retail: 626,
      sppl_prcp: 2.652,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '55YPNT9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55YPNT9B'
    },
    {
      status: 210,
      sku: 'PM0069722',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353199',
      rtl_batch_array: '6353199',
      name_search: 'RUTBGOLD',
      name: "Rudbeckia triloba 'Blackjack Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J3BCL49P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3BCL49P'
    },
    {
      status: 210,
      sku: 'PM0069729',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353340',
      rtl_batch_array: '6353340',
      name_search: 'SAPSDANC',
      name: "Salvia pratensis 'Sky Dance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '829ZKH79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '829ZKH79'
    },
    {
      status: 210,
      sku: 'PM0079226',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330029',
      rtl_batch_array: '6315063, 6330029',
      name_search: 'PHBPARAD',
      name: "Phlox (P) 'Blue Paradise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1924,
      chnn_stock_retail: 3345,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6XEH3B6;DS1L57VH;NVBBLZND;97XABELR;SZCE85KR',
      statusContent: 'dot',
      hash: '81BR8TDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81BR8TDH'
    },
    {
      status: 210,
      sku: 'PM0079227',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6330030',
      rtl_batch_array: '6315064, 6330030, 6333291',
      name_search: 'PHBEYES',
      name: "Phlox (P) 'Bright Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1919,
      chnn_stock_retail: 5787,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8VVS9HNH;6SCP614V;TZBJD7R3;KWXNT1DW;LDX4KEBV;G6D6AV6R;B8C5KYBG;ARW4WT54;L8JC3TTB;WDE57CL1;D2L7J888',
      statusContent: 'dot',
      hash: 'NX3H18Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NX3H18Z4'
    },
    {
      status: 210,
      sku: 'PM0079228',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315065',
      rtl_batch_array: '6315065',
      name_search: 'PUANGUST',
      name: 'Pulmonaria angustifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SK88S4WH;H48H5P5V;VWAHXL7W;275B782B;6LJPZ61K;H5AJHEP2',
      statusContent: 'dot',
      hash: '9R1H7N5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9R1H7N5Z'
    },
    {
      status: 210,
      sku: 'PM0071372',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226872',
      rtl_batch_array: '6226872',
      name_search: 'HESFRITZ',
      name: "Hemerocallis 'Schnickel Fritz'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7ARX3JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7ARX3JE'
    },
    {
      status: 910,
      sku: 'PM0053342',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ERPMBGLO',
      name: "Eryngium planum 'Magical Blue Globe'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'N2TXB4P6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N2TXB4P6'
    },
    {
      status: 210,
      sku: 'PM0071374',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226874',
      rtl_batch_array: '6226874',
      name_search: 'HOFLINE',
      name: "Hosta 'Firn Line'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78Y9GVYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78Y9GVYJ'
    },
    {
      status: 210,
      sku: 'PM0059888',
      core_name: 'Plant',
      sppl_ean: '8720664860100',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129538',
      rtl_batch_array: '6129538',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '040050C7',
      rng_range_identifier: 'H040050C7',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W1A1DE2W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1A1DE2W'
    },
    {
      status: 910,
      sku: 'PM0067261',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'OESSISKI',
      name: "Oenothera speciosa 'Siskiyou'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'JPX61WW1;VW88HK4A;ZE1A98EA;Y8VJC24X;2AN1XKS9;C78V1K5R',
      statusContent: 'dot',
      hash: 'YTSLTXY2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YTSLTXY2'
    },
    {
      status: 210,
      sku: 'PM0071375',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226875',
      rtl_batch_array: '6226875',
      name_search: 'HEMRTIGE',
      name: "Hemerocallis 'My Reggae Tiger'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4PH9A2BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PH9A2BC'
    },
    {
      status: 210,
      sku: 'PM0079229',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315077',
      rtl_batch_array: '6315077',
      name_search: 'VELSCHNE',
      name: "Veronica longifolia 'Schneeriesin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 717,
      chnn_stock_retail: 717,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6TNNEZT9',
      statusContent: 'dot',
      hash: '9XNDNB81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XNDNB81'
    },
    {
      status: 810,
      sku: 'PM0053320',
      core_name: 'Plant',
      sppl_ean: '8720664687516',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053825',
      rtl_batch_array: '6053825',
      name_search: 'DISMAGIC',
      name: "Dicentra 'Spring Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EZJHAVPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZJHAVPJ'
    },
    {
      status: 210,
      sku: 'PM0079298',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350451',
      rtl_batch_array: '6350451, 6333398',
      name_search: 'HESCOMFO',
      name: "Heuchera 'Southern Comfort'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 520,
      chnn_stock_retail: 590,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XAWPLA89',
      statusContent: 'dot',
      hash: 'PJL73D97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJL73D97'
    },
    {
      status: 210,
      sku: 'PM0067260',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6244261',
      rtl_batch_array: '2681752, 6244261, 5837449, 6336346',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6471,
      chnn_stock_retail: 15467,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'HRJ89T5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRJ89T5L'
    },
    {
      status: 210,
      sku: 'PM0069798',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353579',
      rtl_batch_array: '6353579, 6345218',
      name_search: 'VEVRARRO',
      name: "Veronicastrum virginicum 'Red Arrows'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1590,
      chnn_stock_retail: 1742,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1B1B939R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1B1B939R'
    },
    {
      status: 210,
      sku: 'PM0069804',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301853',
      rtl_batch_array: '6301853, 6353454',
      name_search: 'SORFIREW',
      name: "Solidago rugosa 'Fireworks'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 551,
      chnn_stock_retail: 1503,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XL52RW3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL52RW3H'
    },
    {
      status: 210,
      sku: 'PM0074311',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269617',
      rtl_batch_array: '6269617',
      name_search: 'PEHPRED',
      name: 'Penstemon hartwegii Phoenix Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2386,
      chnn_stock_retail: 2386,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3R4VHJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3R4VHJ3'
    },
    {
      status: 210,
      sku: 'PM0071378',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226878',
      rtl_batch_array: '6226878',
      name_search: 'HOSSUBST',
      name: "Hosta 'Sum and Substance'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6P2DW1W;87EP5TS3;WBBB64NA;V74RCP2Z;1KY3D9EH;H29WJL85;L426AKTW;PKSPZD3E;4LX2ZR4P;17DR38DP;4K5JV77S;4LN2XZRZ;1383ZN9P;SCXTGBBL',
      statusContent: 'dot',
      hash: 'DN7WKERD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DN7WKERD'
    },
    {
      status: 210,
      sku: 'PM0067256',
      core_name: 'Plant',
      sppl_ean: '8720349421305',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5317842',
      rtl_batch_array: '5317842',
      name_search: 'LAVROSEA',
      name: "Lavatera 'Rosea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 324,
      chnn_stock_retail: 324,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZYXB1CXC;G1LLL57H;Y6HJR89T',
      statusContent: 'dot',
      hash: 'JTVY2DVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTVY2DVA'
    },
    {
      status: 210,
      sku: 'PM0067409',
      core_name: 'Plant',
      sppl_ean: '8720349434268',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014188',
      rtl_batch_array: '6014188',
      name_search: 'MOLEDERS',
      name: "Monarda 'Lederstrumpf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7Z73T4AN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z73T4AN'
    },
    {
      status: 210,
      sku: 'PM0028033',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301607',
      rtl_batch_array: '6301607, 6350249, 5495344',
      name_search: 'GESPPOUF',
      name: "Geranium sanguineum 'Pink Pouffe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 275,
      chnn_stock_retail: 385,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V8L53YBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8L53YBN'
    },
    {
      status: 210,
      sku: 'PM0067225',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6269576',
      rtl_batch_array: '6269576, 6353348, 6333306, 6339327',
      name_search: 'SAVPRAIN',
      name: "Salvia verticillata 'Purple Rain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 4911,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9HLA75N;6BYAWECY;NYXG54TZ;ZLCAG2GX;228BC34P;G5VXD89R;1WR985PG;2D647XWE;ZJW2ZGRD;85DBZC2Y',
      statusContent: 'dot',
      hash: '4ZJPREYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZJPREYA'
    },
    {
      status: 210,
      sku: 'PM0067436',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191449',
      rtl_batch_array: '6191449',
      name_search: 'HEARED',
      name: "Hemerocallis 'Autumn Red'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 728,
      chnn_stock_retail: 728,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KE1AEEZH;287DLK1N;SWCNVP6V',
      statusContent: 'dot',
      hash: 'DA4WP9AJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA4WP9AJ'
    },
    {
      status: 210,
      sku: 'PM0048479',
      core_name: 'Plant',
      sppl_ean: '8720664810211',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6017591',
      rtl_batch_array: '6017591',
      name_search: 'THODSPIR',
      name: "Thuja occidentalis 'Degroot's Spire'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 822,
      chnn_stock_retail: 822,
      sppl_prcp: 6.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y7VSG4KD;GL8AR2TS;SKJYSCT6',
      statusContent: 'dot',
      hash: 'C1Z45DTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1Z45DTZ'
    },
    {
      status: 210,
      sku: 'PM0067208',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177930',
      rtl_batch_array: '6177930',
      name_search: 'SAGMASO',
      name: "Salix gracilistyla 'Mt. Aso'",
      sppl_size_code: '120STC5',
      rng_range_identifier: 'STE120C5',
      rng_range_description: 'Stem 120 cm C5',
      sppl_stock_available: 1508,
      chnn_stock_retail: 1508,
      sppl_prcp: 5.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6D3VW1V;WJRLV8YG;KZ72ZX5P;ZZS2JPXZ;617P4E5X;APZ9WDVE;5K7WYWES;6ZZBLXD9;7G5YGATB;TR2TTVG7;EK7K84DH;1PN7GRH8;D5PN6A8J',
      statusContent: 'dot',
      hash: 'EER6R7B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EER6R7B8'
    },
    {
      status: 210,
      sku: 'PM0067213',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177935',
      rtl_batch_array: '6177935',
      name_search: 'SANSEREN',
      name: "Sambucus nigra 'Serenade'",
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 2487,
      chnn_stock_retail: 2487,
      sppl_prcp: 4.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '93G85T1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93G85T1P'
    },
    {
      status: 210,
      sku: 'PM0071371',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226871',
      rtl_batch_array: '6226871',
      name_search: 'KNALCAZA',
      name: "Kniphofia 'Alcazar'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AGKZ99AR;14GJ1C6K;N4AKWW5S;WH5LH75K;2RDCDNP9;WZDYR832;4S3T94VN;DR19VA2K;NVJCHR44;K8R39AWX;JNWA2ZD3;RWAGBNXH;67PB6EN7',
      statusContent: 'dot',
      hash: '6JL7ZHBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JL7ZHBE'
    },
    {
      status: 210,
      sku: 'PM0071376',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226876',
      rtl_batch_array: '6226876',
      name_search: 'PABBEAUT',
      name: "Paeonia (LE) 'Bowl of Beauty'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1BRESE9;LCGNJYHB',
      statusContent: 'dot',
      hash: 'JTXAK782',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTXAK782'
    },
    {
      status: 810,
      sku: 'PM0067247',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181295',
      rtl_batch_array: '6181295',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_prcp: 3.103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '4V9VLAVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V9VLAVZ'
    },
    {
      status: 210,
      sku: 'PM0000173',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6268754',
      rtl_batch_array: '6268754, 5495320, 6290215, 6295589',
      name_search: 'GEBTURTL',
      name: "Geranium 'Blushing Turtle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 1993,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6E3L6GP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6E3L6GP'
    },
    {
      status: 210,
      sku: 'PM0068221',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193793',
      rtl_batch_array: '6193793',
      name_search: 'IRVERSIC',
      name: 'Iris versicolor',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZV18S1AX;2P6XSZ4W;T4GKYG5Z;W5H3K7KB;A4RGBV1R',
      statusContent: 'dot',
      hash: '6H125PYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H125PYN'
    },
    {
      status: 210,
      sku: 'PM0070190',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220727',
      rtl_batch_array: '6220727',
      name_search: 'DEDHGLOR',
      name: "Delphinium 'Delphis Hollands Glorie'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NCEJ5LHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCEJ5LHT'
    },
    {
      status: 210,
      sku: 'PM0070191',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220728',
      rtl_batch_array: '6220728',
      name_search: 'DEDJEWEL',
      name: "Delphinium 'Delphi's Jewel'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z3RYV4DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3RYV4DS'
    },
    {
      status: 910,
      sku: 'PM0032233',
      core_name: 'Plant',
      sppl_ean: '8720626385733',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5884397',
      rtl_batch_array: '5884397',
      name_search: 'AGNSTAR',
      name: "Agapanthus 'Northern Star'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 10.016,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A4THP299;WHDKZJKC;5VL1TBAD;CTRBZG71;X9CC9GTG',
      imageBatch: 'PB7T6B1L',
      statusContent: 'dot',
      hash: 'ZAWN7VC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAWN7VC7'
    },
    {
      status: 210,
      sku: 'PM0020895',
      core_name: 'Plant',
      sppl_ean: '8720664631878',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5539728',
      rtl_batch_array: '5539728, 6133674',
      name_search: 'SEACRE',
      name: 'Sedum acre',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 260,
      chnn_stock_retail: 1334,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D5SAVB6X;6GX7Y836;EJ9JA9S8;JJ4VJDAB;H43VP4HY;8PJGL15W;9WBCDKAS;ZB6CBC3H;B9NA57ZT;YSLBDPNH;LNLV838G;8YH4Z4NG',
      statusContent: 'dot',
      hash: 'ZAGDTSBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAGDTSBW'
    },
    {
      status: 210,
      sku: 'PM0068463',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266198',
      rtl_batch_array: '6266198',
      name_search: 'CHPBMOON',
      name: "Chamaecyparis pisifera 'Blue Moon'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1223,
      chnn_stock_retail: 1223,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CY9TR3SZ;PJY1CCSP;TX25HPLP;CZYSK3JR;TS7SXR8P;V6NXLTB8;YZPKLYW2',
      statusContent: 'dot',
      hash: '4VXHKG5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VXHKG5A'
    },
    {
      status: 210,
      sku: 'PM0053343',
      core_name: 'Plant',
      sppl_ean: '8720664863477',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164281',
      rtl_batch_array: '6053856, 6164281, 6333232, 6348319',
      name_search: 'FEMAIREI',
      name: 'Festuca mairei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 172,
      chnn_stock_retail: 4026,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YD3S8CRA',
      statusContent: 'dot',
      hash: '5LYPK716',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LYPK716'
    },
    {
      status: 210,
      sku: 'PM0063378',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249993',
      rtl_batch_array: '6249993',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 259,
      chnn_stock_retail: 259,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: '4J69DW1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J69DW1W'
    },
    {
      status: 210,
      sku: 'PM0026057',
      core_name: 'Plant',
      sppl_ean: '8720353018263',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5459717',
      rtl_batch_array: '5459717',
      name_search: 'WEFVICTO',
      name: "Weigela florida 'Victoria'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '77L8S29W;VD34HGKR',
      statusContent: 'dot',
      hash: 'XHJPDDES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHJPDDES'
    },
    {
      status: 210,
      sku: 'PM0068475',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246581',
      rtl_batch_array: '6246581',
      name_search: 'PLOANANA',
      name: "Platycladus orientalis 'Aurea Nana'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3864,
      chnn_stock_retail: 3864,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WATJ17Y7;LVLCX381;LN7KZH6S;KCRKE9H3;N2A8NNZ3;LXCE2DXC;HXJZVJCX;RZJ73ZRR;YYSHW1KS;CADW2G56;4LH2JGWX;H3JX9G12;TXG4PJAG;TZZSL4WS;VW971LHP',
      statusContent: 'dot',
      hash: 'YND7L4ZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YND7L4ZL'
    },
    {
      status: 210,
      sku: 'PM0067434',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191446',
      rtl_batch_array: '6191446',
      name_search: 'GENODOSU',
      name: 'Geranium nodosum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3039,
      chnn_stock_retail: 3039,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKRXGWGZ;YN7CGPWY;2TZX3DB7',
      statusContent: 'dot',
      hash: '8D7REXLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8D7REXLD'
    },
    {
      status: 210,
      sku: 'PM0053396',
      core_name: 'Plant',
      sppl_ean: '8720664680258',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054317',
      rtl_batch_array: '6054317',
      name_search: 'ALCYFANT',
      name: "Allium chloranthum 'Yellow Fantasy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2V62VCE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2V62VCE'
    },
    {
      status: 210,
      sku: 'PM0067441',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191457',
      rtl_batch_array: '6191457',
      name_search: 'SABRESSI',
      name: "Saponaria 'Bressingham'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 698,
      chnn_stock_retail: 698,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SCCNSD85;TWKNBE6K;ERTAG42N;9KPX2TR1;B7YXPP7A;JGSXB7Z8;4HACVNSB;98TLE15E;KBY8H7JH;2D9ABP9R;4VERY84J;1JE9JL2L',
      statusContent: 'dot',
      hash: 'RW463HBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW463HBG'
    },
    {
      status: 810,
      sku: 'PM0079429',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317670',
      rtl_batch_array: '6317670',
      name_search: 'THPGELDE',
      name: "Thuja plicata 'Gelderland'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N23Y9G8B;C9T6V67H;JWB1ZB6D;4KWXGXT1;2YNBYNEH;N795AKAR;93EJ6XV1',
      statusContent: 'dot',
      hash: 'HT4L2EGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT4L2EGJ'
    },
    {
      status: 210,
      sku: 'PM0068210',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193780',
      rtl_batch_array: '6193780',
      name_search: 'ERANGUST',
      name: 'Eriophorum angustifolium',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GR2ZB1EP;VPEXBN1R',
      statusContent: 'dot',
      hash: 'H2LR9W5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2LR9W5P'
    },
    {
      status: 910,
      sku: 'PM0068351',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'NYHW8HV6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NYHW8HV6'
    },
    {
      status: 210,
      sku: 'PM0085622',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385589',
      rtl_batch_array: '6385589',
      name_search: 'ILEMHECK',
      name: "Ilex meserveae 'Heckenstar'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ABH6LW4X',
      statusContent: 'dot',
      hash: 'SLK9WK1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLK9WK1A'
    },
    {
      status: 210,
      sku: 'PM0067412',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260001',
      rtl_batch_array: '6260001',
      name_search: 'HEMWINDE',
      name: "Hebe 'Mrs Winder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XDCXWHT;CN9X1CLJ;5X5P9A55;K1CH4TL9;JH9G37Y4;96TTR931',
      statusContent: 'dot',
      hash: '2LK7RCR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LK7RCR8'
    },
    {
      status: 210,
      sku: 'PM0082070',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349597',
      rtl_batch_array: '6349597, 6374014',
      name_search: 'BEPDRAGO',
      name: "Bergenia 'Pink Dragonfly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WBNDK747',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBNDK747'
    },
    {
      status: 210,
      sku: 'PM0065495',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349134',
      rtl_batch_array: '6349134, 6164650',
      name_search: 'LYALPINA',
      name: 'Lychnis alpina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4968,
      chnn_stock_retail: 5129,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AL76EA17;L3772PTR;L7ZBGAX5;W1LGEA15;CPLL1R2E;78HVZ4C9;X44AX8V3;PKVX9ART;7ZN5HE2T;247XKWHA;T64VR2ZJ;57JWDDNC;V6RVKZ1C;25TGKLX2;EN4JX6X3;LSDVBP7Y;RB5YJWL8',
      statusContent: 'dot',
      hash: 'GEDX7HVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEDX7HVL'
    },
    {
      status: 210,
      sku: 'PM0082071',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349599',
      rtl_batch_array: '6349599, 6374016',
      name_search: 'BEWINTER',
      name: "Bergenia 'Winterm\u00e4rchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 714,
      chnn_stock_retail: 834,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G3GHWS22',
      statusContent: 'dot',
      hash: 'BTBKYC17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTBKYC17'
    },
    {
      status: 210,
      sku: 'PM0085623',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385591',
      rtl_batch_array: '6385591',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 5.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'Z82V9ZY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z82V9ZY4'
    },
    {
      status: 210,
      sku: 'PM0004648',
      core_name: 'Plant',
      sppl_ean: '8720664697447',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5501750',
      rtl_batch_array: '5501750',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 55781,
      chnn_stock_retail: 55781,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      imageBatch: 'BAJZ3EY1',
      statusContent: 'dot',
      hash: 'AT14HW3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AT14HW3P'
    },
    {
      status: 210,
      sku: 'PM0055073',
      core_name: 'Plant',
      sppl_ean: '8720349453320',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5665943',
      rtl_batch_array: '5665943',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '050COBOL',
      rng_range_identifier: 'BALL\u00d8050H050050',
      rng_range_description: 'Ball \u00d8 50 cm H50 cm 50 cm',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 26,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'C9ECZJW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9ECZJW3'
    },
    {
      status: 210,
      sku: 'PM0085624',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385592',
      rtl_batch_array: '6385592',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 780,
      chnn_stock_retail: 780,
      sppl_prcp: 4.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: '61B119ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61B119ED'
    },
    {
      status: 210,
      sku: 'PM0085625',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385595',
      rtl_batch_array: '6385595',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 580,
      chnn_stock_retail: 580,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'RBT9NLA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBT9NLA3'
    },
    {
      status: 210,
      sku: 'PM0082072',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349603',
      rtl_batch_array: '6349603',
      name_search: 'CAMGKUHN',
      name: "Calamintha menthifolia'Gottfried Kuhn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GV572924',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GV572924'
    },
    {
      status: 210,
      sku: 'PM0068163',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193726',
      rtl_batch_array: '6193726',
      name_search: 'BACAROLI',
      name: 'Bacopa caroliniana',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CRHXGPHE;VYANB6WD',
      statusContent: 'dot',
      hash: 'JXN7JRVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXN7JRVR'
    },
    {
      status: 210,
      sku: 'PM0053535',
      core_name: 'Plant',
      sppl_ean: '8720664697409',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054768',
      rtl_batch_array: '6054768',
      name_search: 'LESDCHER',
      name: 'Leucanthemum (S) Sweet Daisy Cher',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LNGGH3NT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNGGH3NT'
    },
    {
      status: 210,
      sku: 'PM0079418',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317658',
      rtl_batch_array: '6317658',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.314,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'YNLVEKWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNLVEKWD'
    },
    {
      status: 210,
      sku: 'PM0070192',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220729',
      rtl_batch_array: '6220729',
      name_search: 'DEFINSTE',
      name: "Delphinium 'Finsteraarhorn'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EDSZNB9X;CY62PCRS;HLBYYPXS',
      statusContent: 'dot',
      hash: 'BLZ5W7GV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLZ5W7GV'
    },
    {
      status: 210,
      sku: 'PM0068153',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193699',
      rtl_batch_array: '6193699',
      name_search: 'ZECANDID',
      name: 'Zephyranthes candida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DW7W7E1J;GRKANACW;EAB69JTZ;P58DHH7G',
      statusContent: 'dot',
      hash: 'S24LRJZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S24LRJZP'
    },
    {
      status: 210,
      sku: 'PM0070200',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220742',
      rtl_batch_array: '6220742',
      name_search: 'GEBSUNSE',
      name: "Geum 'Blazing Sunset'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69CBWB7D;SD6HCPAG;XJ3221C2;T4S75R95;DLY4V6XP;ACX92HTT;RY8HR8X3;4KYJVGBR;BW5AK79Y;KGKG9ACX;14ARSKTN;8JXVARRK',
      statusContent: 'dot',
      hash: 'TPK85A7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPK85A7J'
    },
    {
      status: 810,
      sku: 'PM0079419',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317659',
      rtl_batch_array: '6317659',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 3.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'SD28H67B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD28H67B'
    },
    {
      status: 810,
      sku: 'PM0085626',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385596',
      rtl_batch_array: '6385596',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'CZD5HRL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZD5HRL6'
    },
    {
      status: 210,
      sku: 'PM0067385',
      core_name: 'Plant',
      sppl_ean: '8720349424313',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2789410',
      rtl_batch_array: '2789410',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      imageBatch: '6JWR8C8A',
      statusContent: 'dot',
      hash: 'Y1KDKS9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1KDKS9J'
    },
    {
      status: 910,
      sku: 'PM0079421',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317661',
      rtl_batch_array: '6317661',
      name_search: 'THPATROV',
      name: "Thuja plicata 'Atrovirens'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 3.484,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3A6JD6G;99JNGRDJ;ETS7G912',
      statusContent: 'dot',
      hash: '81LZ9WD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81LZ9WD1'
    },
    {
      status: 210,
      sku: 'PM0082073',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349604',
      rtl_batch_array: '6349604',
      name_search: 'CANMENTH',
      name: "Calamintha nepeta 'Menthe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 542,
      chnn_stock_retail: 542,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DDLL34RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDLL34RA'
    },
    {
      status: 810,
      sku: 'PM0079422',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317663',
      rtl_batch_array: '6317663',
      name_search: 'THPEXCEL',
      name: "Thuja plicata 'Excelsa'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5DGZ5WT',
      statusContent: 'dot',
      hash: 'EAWWKC5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAWWKC5C'
    },
    {
      status: 210,
      sku: 'PM0082074',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349605',
      rtl_batch_array: '6349605',
      name_search: 'CANWRIES',
      name: "Calamintha nepeta 'Weisser Riese'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 184,
      chnn_stock_retail: 184,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBRVJBXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBRVJBXA'
    },
    {
      status: 910,
      sku: 'PM0068433',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDI89265',
      name: "Acis autumnalis 'September Snow'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'YK1TSKVN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YK1TSKVN'
    },
    {
      status: 910,
      sku: 'PM0067380',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBURHYT',
      name: 'Viburnum rhytidophyllum',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      imageCore: '48SGK5WV;L8E27T5T',
      statusContent: 'dot',
      hash: 'CYKR9VL6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CYKR9VL6'
    },
    {
      status: 210,
      sku: 'PM0033649',
      core_name: 'Plant',
      sppl_ean: '8720664681859',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849639',
      rtl_batch_array: '5849639',
      name_search: 'BEDSAKUR',
      name: 'Bergenia Dragonfly Sakura',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_prcp: 3.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LEGA46BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEGA46BE'
    },
    {
      status: 810,
      sku: 'PM0079423',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317664',
      rtl_batch_array: '6317664',
      name_search: 'THPEXCEL',
      name: "Thuja plicata 'Excelsa'",
      sppl_size_code: '125150C3',
      rng_range_identifier: 'H125150C3',
      rng_range_description: 'H125 cm 150 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 3.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5DGZ5WT',
      statusContent: 'dot',
      hash: '94G711D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94G711D5'
    },
    {
      status: 210,
      sku: 'PM0082075',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345339',
      rtl_batch_array: '6345339',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '200225C74',
      rng_range_identifier: 'H200225C7.5',
      rng_range_description: 'H200 cm 225 cm C7.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 9.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'JT5HC66V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT5HC66V'
    },
    {
      status: 810,
      sku: 'PM0082076',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345462',
      rtl_batch_array: '6345462',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '020030C1',
      rng_range_identifier: 'H020030C1',
      rng_range_description: 'H20 cm 30 cm C1',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'THAZZV9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THAZZV9S'
    },
    {
      status: 210,
      sku: 'PM0085627',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385597',
      rtl_batch_array: '6385597',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'GVE5EL9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVE5EL9J'
    },
    {
      status: 210,
      sku: 'PM0085628',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385598',
      rtl_batch_array: '6385598',
      name_search: 'PRLANI',
      name: "Prunus laurocerasus 'Ani'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPPAKRLW;ZK6EWK9T;DEYNRRTL;R5PLENBD',
      statusContent: 'dot',
      hash: '7N4KV53A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N4KV53A'
    },
    {
      status: 810,
      sku: 'PM0082077',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345463',
      rtl_batch_array: '6345463',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '025030C3BOL',
      rng_range_identifier: 'BALL\u00d8030H025030C3',
      rng_range_description: 'Ball \u00d8 30 cm H25 cm 30 cm C3',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '93A9B3XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93A9B3XW'
    },
    {
      status: 810,
      sku: 'PM0082078',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345464',
      rtl_batch_array: '6345464',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '030035C5BOL',
      rng_range_identifier: 'BALL\u00d8035H030035C5',
      rng_range_description: 'Ball \u00d8 35 cm H30 cm 35 cm C5',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_prcp: 6.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'EJZAX96L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJZAX96L'
    },
    {
      status: 810,
      sku: 'PM0082079',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345476',
      rtl_batch_array: '6345476',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '150175C45',
      rng_range_identifier: 'H150175C45',
      rng_range_description: 'H150 cm 175 cm C45',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 40.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'XTYD5Y9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTYD5Y9C'
    },
    {
      status: 910,
      sku: 'PM0082080',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345477',
      rtl_batch_array: '6345477',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '175200C45',
      rng_range_identifier: 'H175200C45',
      rng_range_description: 'H175 cm 200 cm C45',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 46.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'DBK439NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBK439NS'
    },
    {
      status: 210,
      sku: 'PM0085629',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385599',
      rtl_batch_array: '6385599',
      name_search: 'PRLANI',
      name: "Prunus laurocerasus 'Ani'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 975,
      chnn_stock_retail: 975,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPPAKRLW;ZK6EWK9T;DEYNRRTL;R5PLENBD',
      statusContent: 'dot',
      hash: 'HATGXWAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HATGXWAG'
    },
    {
      status: 210,
      sku: 'PM0067386',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269858',
      rtl_batch_array: '5837512, 6269858, 6254843',
      name_search: 'SEAUTUMN',
      name: 'Sesleria autumnalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2626,
      chnn_stock_retail: 4016,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44CY955K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44CY955K'
    },
    {
      status: 210,
      sku: 'PM0085630',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385602',
      rtl_batch_array: '6385602',
      name_search: 'PRLCBRAN',
      name: "Prunus laurocerasus 'Cherry Brandy'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.43,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZ72SREN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ72SREN'
    },
    {
      status: 210,
      sku: 'PM0085631',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385603',
      rtl_batch_array: '6385603',
      name_search: 'PRLCBRAN',
      name: "Prunus laurocerasus 'Cherry Brandy'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H4YRJ6VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4YRJ6VK'
    },
    {
      status: 210,
      sku: 'PM0068434',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192709',
      rtl_batch_array: '6192709',
      name_search: 'COEDULIS',
      name: 'Corydalis edulis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 437,
      chnn_stock_retail: 437,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GK7LZ8KV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GK7LZ8KV'
    },
    {
      status: 810,
      sku: 'PM0065499',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217081',
      rtl_batch_array: '6217081',
      name_search: 'SECAUTIC',
      name: 'Sedum cauticola',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5WS5P24J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WS5P24J'
    },
    {
      status: 210,
      sku: 'PM0085632',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385604',
      rtl_batch_array: '6385604',
      name_search: 'PRLCBRAN',
      name: "Prunus laurocerasus 'Cherry Brandy'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHVJ39S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHVJ39S2'
    },
    {
      status: 210,
      sku: 'PM0085633',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385605',
      rtl_batch_array: '6385605',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'G5J1K22G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5J1K22G'
    },
    {
      status: 210,
      sku: 'PM0085634',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385606',
      rtl_batch_array: '6385606',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'EEKEZJWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEKEZJWN'
    },
    {
      status: 910,
      sku: 'PM0079434',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317676',
      rtl_batch_array: '6317676',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 20.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: '69YSPTEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69YSPTEL'
    },
    {
      status: 210,
      sku: 'PM0085635',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385607',
      rtl_batch_array: '6385607',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 4.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'V45514G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V45514G6'
    },
    {
      status: 810,
      sku: 'PM0067243',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228421',
      rtl_batch_array: '6228421, 6268909',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 45,
      chnn_stock_retail: 56,
      sppl_prcp: 3.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'XV5ETXET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV5ETXET'
    },
    {
      status: 810,
      sku: 'PM0079435',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317678',
      rtl_batch_array: '6317678',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.809,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: 'XL4SXWEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL4SXWEV'
    },
    {
      status: 210,
      sku: 'PM0085636',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385610',
      rtl_batch_array: '6385610',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '040060KL',
      rng_range_identifier: 'H040060RB',
      rng_range_description: 'H40 cm 60 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'AB1X491S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB1X491S'
    },
    {
      status: 210,
      sku: 'PM0053450',
      core_name: 'Plant',
      sppl_ean: '8720353090726',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054513',
      rtl_batch_array: '6054513',
      name_search: 'CABJENNE',
      name: "Carex brunnea 'Jenneke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K6RSSZWD;R8BWZ2WW;8NYC8JV5',
      statusContent: 'dot',
      hash: 'EKZL5RNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKZL5RNY'
    },
    {
      status: 210,
      sku: 'PM0085637',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385612',
      rtl_batch_array: '6385612',
      name_search: 'PRLMANO',
      name: "Prunus laurocerasus 'Mano'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CBV58A24',
      statusContent: 'dot',
      hash: 'GLN7ZENW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLN7ZENW'
    },
    {
      status: 810,
      sku: 'PM0082081',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345488',
      rtl_batch_array: '6345488',
      name_search: 'LAWVOSSI',
      name: "Laburnum watereri 'Vossii'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 9.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6P8DCEB;B4HD3CG7;CJZ9RAHK;DEXRAHR7;G9LDP2G5;T7DJHNLB;9G78LHKK',
      statusContent: 'dot',
      hash: '7XDHNJ8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XDHNJ8K'
    },
    {
      status: 210,
      sku: 'PM0071380',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226881',
      rtl_batch_array: '6226881',
      name_search: 'HOSEYES',
      name: "Hosta 'Snake Eyes'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DWE6KDT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWE6KDT8'
    },
    {
      status: 210,
      sku: 'PM0071381',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226882',
      rtl_batch_array: '6226882',
      name_search: 'HOSLOVIN',
      name: "Hosta 'Summer Lovin'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZD9D7G2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZD9D7G2'
    },
    {
      status: 210,
      sku: 'PM0068346',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311934',
      rtl_batch_array: '6311934, 6299743',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 5598,
      sppl_prcp: 2.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '5RLYTJX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RLYTJX3'
    },
    {
      status: 210,
      sku: 'PM0024035',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196830',
      rtl_batch_array: '6196830, 6353362, 6303632',
      name_search: 'SCCPERFE',
      name: "Scabiosa caucasica 'Perfecta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5076,
      chnn_stock_retail: 8643,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C3AW915T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3AW915T'
    },
    {
      status: 210,
      sku: 'PM0071382',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226883',
      rtl_batch_array: '6226883',
      name_search: 'MOBMOON',
      name: "Monarda 'Blue Moon'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BE5AR1R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE5AR1R3'
    },
    {
      status: 210,
      sku: 'PM0067443',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191461',
      rtl_batch_array: '6191461',
      name_search: 'SESALBUM',
      name: "Sedum spurium 'Album'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2891,
      chnn_stock_retail: 2891,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEZS7VG7;CSL18XH9',
      statusContent: 'dot',
      hash: '6WLJGJEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WLJGJEG'
    },
    {
      status: 210,
      sku: 'PM0033715',
      core_name: 'Plant',
      sppl_ean: '8720664540668',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849731',
      rtl_batch_array: '5849731',
      name_search: 'GERFPASS',
      name: "Geum rivale 'Flames of Passion'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_prcp: 3.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9B5HGANK;S1WD1ZGY;C9P7XNG7;EHADB9XK;ZRSSLEDB',
      statusContent: 'dot',
      hash: 'NV1RD9EP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV1RD9EP'
    },
    {
      status: 810,
      sku: 'PM0070193',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268722',
      rtl_batch_array: '6268722',
      name_search: 'DEBOHEME',
      name: "Delphinium (B) 'La Boheme'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44YGWPVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44YGWPVT'
    },
    {
      status: 810,
      sku: 'PM0074314',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269800',
      rtl_batch_array: '6269800',
      name_search: 'FANG952',
      name: "Fargesia nitida 'Gansu 95/2'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PBZTYCXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PBZTYCXY'
    },
    {
      status: 210,
      sku: 'PM0068197',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193761',
      rtl_batch_array: '6193761',
      name_search: 'BUUMBELL',
      name: 'Butomus umbellatus',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7V9THRN;8N2BC9BP;9P21HBGY;KHVVP61G;K5GE5EPB;BLK7ZLLE;74VWYYT2;P3E9TKX3;S2T7PH64;PS77YZ63;2BTTBCAA;6644VG9B;BJR4V5S5;E5X2JNV8',
      statusContent: 'dot',
      hash: 'GGXL4HC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGXL4HC6'
    },
    {
      status: 210,
      sku: 'PM0032395',
      core_name: 'Plant',
      sppl_ean: '8720349411825',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5860566',
      rtl_batch_array: '5860566',
      name_search: 'CAFFALLS',
      name: "Carex 'Feather Falls'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SWTEBKB2;L2483766;SP761YTY;76N9NLAJ;CY3EZYXS;GN3N9S8T;PTBBRL6A;4WP8RTZK;PY2L3NWL;56GRS1YN;VVVTBCA6;4S3RAXZ6;54D1RL41;SKA1G2BS',
      imageBatch: 'ZBC7DGS9',
      statusContent: 'dot',
      hash: '2CTE6PHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CTE6PHA'
    },
    {
      status: 210,
      sku: 'PM0068341',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197197',
      rtl_batch_array: '6197197',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: '030040C7',
      rng_range_identifier: 'H030040C7',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 5.545,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: '4PWBAVTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PWBAVTE'
    },
    {
      status: 210,
      sku: 'PM0067415',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363173',
      rtl_batch_array: '6161500, 6321859, 6363173',
      name_search: 'ACMAPFEL',
      name: "Achillea millefolium 'Apfelbl\u00fcte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1989,
      chnn_stock_retail: 2677,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9WXS7J2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9WXS7J2'
    },
    {
      status: 210,
      sku: 'PM0005977',
      core_name: 'Plant',
      sppl_ean: '8720626333802',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5741519',
      rtl_batch_array: '5741519, 6386053',
      name_search: 'AGRUGOSA',
      name: 'Agastache rugosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 590,
      chnn_stock_retail: 1747,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8XSSHA2H;A153Y1SG;A1N7Y6AY;NSHSJZ48',
      statusContent: 'dot',
      hash: 'SAB27WGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAB27WGH'
    },
    {
      status: 210,
      sku: 'PM0068206',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193774',
      rtl_batch_array: '6193774',
      name_search: 'COBMAGIC',
      name: "Colocasia 'Black Magic'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RJB4727Z;KT3ELDX4;ZN7Y2TXG;N3XCCEJP;CAT1KKW9;CGN4EWAW;NGNN2VBK;G7EHE754',
      statusContent: 'dot',
      hash: 'DG55N96A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG55N96A'
    },
    {
      status: 910,
      sku: 'PM0070197',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEICYELL',
      name: 'Delosperma Ice Cream Yellow',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'L2R97186',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L2R97186'
    },
    {
      status: 210,
      sku: 'PM0085638',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385613',
      rtl_batch_array: '6385613',
      name_search: 'PRLMANO',
      name: "Prunus laurocerasus 'Mano'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CBV58A24',
      statusContent: 'dot',
      hash: 'TBBTY6X7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBBTY6X7'
    },
    {
      status: 210,
      sku: 'PM0033728',
      core_name: 'Plant',
      sppl_ean: '8720664692640',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849746',
      rtl_batch_array: '5849746',
      name_search: 'HOWBRIM',
      name: "Hosta 'Wide Brim'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W124CX91;D8NJKA3N;77X215VX;H1PESLVT;VRTKZ3VW;D7CN85W7;9YA6BWD4;2HK6C5AR;WSCTS22K;SVEK1872;XX9JLLWJ;ESSVBVHC;25LWTEKL;4V4D9XHW;SPE83D3Z',
      statusContent: 'dot',
      hash: '9529VGXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9529VGXK'
    },
    {
      status: 210,
      sku: 'PM0053411',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192634',
      rtl_batch_array: '6192634',
      name_search: 'ALPWIT',
      name: 'Allium hybrid Paars-Wit Purple-White',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 864,
      chnn_stock_retail: 864,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67RVNWJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67RVNWJH'
    },
    {
      status: 210,
      sku: 'PM0070199',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349068',
      rtl_batch_array: '6349068, 6220738',
      name_search: 'DIMERTON',
      name: "Digitalis 'Mertonensis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5016,
      chnn_stock_retail: 5167,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94WJ9HK1',
      statusContent: 'dot',
      hash: 'PYEK3ZGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYEK3ZGS'
    },
    {
      status: 210,
      sku: 'PM0070203',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220746',
      rtl_batch_array: '6220746',
      name_search: 'ELPINNAT',
      name: 'Ellisiophyllum pinnatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BA3NJPXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA3NJPXE'
    },
    {
      status: 210,
      sku: 'PM0053451',
      core_name: 'Plant',
      sppl_ean: '8720664683600',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054514',
      rtl_batch_array: '6054514',
      name_search: 'CABLSUNS',
      name: "Carex brunnea 'Lady Sunshine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 362,
      chnn_stock_retail: 362,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1XY3R4Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XY3R4Z5'
    },
    {
      status: 210,
      sku: 'PM0065492',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350180',
      rtl_batch_array: '6196770, 6350180, 6302521',
      name_search: 'GALRJANE',
      name: 'Gaura lindheimeri Rosy Jane',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1988,
      chnn_stock_retail: 5279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YG81187X',
      statusContent: 'dot',
      hash: 'D4HAYENS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4HAYENS'
    },
    {
      status: 210,
      sku: 'PM0070201',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220744',
      rtl_batch_array: '6220744',
      name_search: 'GECRUCIA',
      name: 'Gentiana cruciata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRCXXVA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRCXXVA7'
    },
    {
      status: 910,
      sku: 'PM0077663',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECPSPINK',
      name: "Echinacea purpurea 'Sensation Pink'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'YDNB4R6Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YDNB4R6Z'
    },
    {
      status: 210,
      sku: 'PM0068004',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192541',
      rtl_batch_array: '6192541',
      name_search: 'PIANIDIF',
      name: "Picea abies 'Nidiformis'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3663,
      chnn_stock_retail: 3663,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SDXTPGH;ZS2L161S;B24CVBXA;GPRNNAHK;Z4NB8RW8;WK976K43;H2XA7VDP;TA5725B7',
      statusContent: 'dot',
      hash: 'XXXNWCPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXXNWCPK'
    },
    {
      status: 210,
      sku: 'PM0078856',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313193',
      rtl_batch_array: '6313193',
      name_search: 'ASELOVEL',
      name: "Aster ericoides 'Lovely'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 648,
      chnn_stock_retail: 648,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S38S6YHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S38S6YHL'
    },
    {
      status: 210,
      sku: 'PM0078857',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313194',
      rtl_batch_array: '6313194',
      name_search: 'ASLPRINC',
      name: "Aster lateriflorus 'Prince'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 458,
      chnn_stock_retail: 458,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZE3XS77X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZE3XS77X'
    },
    {
      status: 210,
      sku: 'PM0033797',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266267',
      rtl_batch_array: '6266267',
      name_search: 'SANVKLOS',
      name: "Salvia nemorosa 'Viola Klose'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 124,
      chnn_stock_retail: 124,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H4AGGCRS',
      statusContent: 'dot',
      hash: 'KZRE4WVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZRE4WVR'
    },
    {
      status: 210,
      sku: 'PM0055084',
      core_name: 'Plant',
      sppl_ean: '8720626372924',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5665942',
      rtl_batch_array: '5665942',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '040COBOL',
      rng_range_identifier: 'BALL\u00d8040H040040',
      rng_range_description: 'Ball \u00d8 40 cm H40 cm 40 cm',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'RJSAHYBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJSAHYBY'
    },
    {
      status: 210,
      sku: 'PM0078866',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313212',
      rtl_batch_array: '6313212',
      name_search: 'SAVEVINA',
      name: "Salvia 'Vevina'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 302,
      chnn_stock_retail: 302,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E227ZRPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E227ZRPH'
    },
    {
      status: 210,
      sku: 'PM0070196',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220733',
      rtl_batch_array: '6220733',
      name_search: 'DEPICCOL',
      name: "Delphinium (B) 'Piccolo'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 2.068,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRARSCRG;LWAXNPZ7;WVWPS9AP;JSB6L331;YVHA3J4Z',
      statusContent: 'dot',
      hash: 'J5JAT8X7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5JAT8X7'
    },
    {
      status: 910,
      sku: 'PM0059520',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TADISTIC',
      name: 'Taxodium distichum',
      rng_range_identifier: 'H080100C3.5',
      rng_range_description: 'H80 cm 100 cm C3.5',
      imageCore:
        '57YKZK3C;1SG6WLB2;EWN73NH9;XJYW3ZXR;KN9TTC1W;5G93H264;YJACBVDD;YVLPL8RW;EZ173YX5;S3JTC69H;VNPRRPSZ;NTAWXLN1;869EL85E',
      statusContent: 'dot',
      hash: 'HBJWN3W6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HBJWN3W6'
    },
    {
      status: 210,
      sku: 'PM0019059',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197029',
      rtl_batch_array: '6197029, 5905585',
      name_search: 'MOCMOORF',
      name: "Molinia caerulea 'Moorflamme'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1300,
      chnn_stock_retail: 2125,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CVVT2CE9;YK519G92;H1DRK87A',
      statusContent: 'dot',
      hash: 'T526J6T2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T526J6T2'
    },
    {
      status: 210,
      sku: 'PM0078876',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6313223',
      rtl_batch_array: '6313223',
      name_search: 'ASMSBEAU',
      name: "Astrantia major 'Star of Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 557,
      chnn_stock_retail: 557,
      sppl_prcp: 3.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9K93Y1VB;XX9S28RY;Z61T59SS;KKE6AY5L;4T7WLP4W;9L81RJEP;PCY61H8J;NCVTT99V;JP78P4C9',
      statusContent: 'dot',
      hash: '85XZ4VLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85XZ4VLX'
    },
    {
      status: 210,
      sku: 'PM0078907',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252437',
      rtl_batch_array: '6252437',
      name_search: 'AGFIREWO',
      name: 'Agapanthus Fireworks',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 347,
      chnn_stock_retail: 347,
      sppl_prcp: 3.074,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2C31BW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2C31BW2'
    },
    {
      status: 910,
      sku: 'PM0067073',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307720',
      rtl_batch_array: '6307720',
      name_search: 'CELIBANI',
      name: 'Cedrus libani',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 11.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPK35RB9;YVSAPN13',
      statusContent: 'dot',
      hash: 'X18E5SRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X18E5SRG'
    },
    {
      status: 210,
      sku: 'PM0053551',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208034',
      rtl_batch_array: '6208034',
      name_search: 'LYSJPBLU',
      name: 'Lythrum salicaria JS Pink Blush',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HWKCDZJZ',
      statusContent: 'dot',
      hash: 'GYKEES31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYKEES31'
    },
    {
      status: 210,
      sku: 'PM0070194',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220731',
      rtl_batch_array: '6220731',
      name_search: 'DEMORGEN',
      name: "Delphinium 'Morgenstrahl'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BKY86992',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKY86992'
    },
    {
      status: 810,
      sku: 'PM0074315',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269801',
      rtl_batch_array: '6269801',
      name_search: 'FANG952',
      name: "Fargesia nitida 'Gansu 95/2'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 10.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EL84X227',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EL84X227'
    },
    {
      status: 210,
      sku: 'PM0033639',
      core_name: 'Plant',
      sppl_ean: '8720626392045',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849625',
      rtl_batch_array: '5849625',
      name_search: 'ASFANAL',
      name: "Astilbe (A) 'Fanal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C7S2Y1R;2ZA6BV4X;NKJPNGXX;GVT9WCBE;4LZB2BZB',
      statusContent: 'dot',
      hash: '5SRZ2GC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SRZ2GC4'
    },
    {
      status: 210,
      sku: 'PM0074317',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269804',
      rtl_batch_array: '6269804',
      name_search: 'BEVERRUC',
      name: 'Berberis verruculosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 911,
      chnn_stock_retail: 911,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9SLGS6VA;7ACH56LP;S6DEVWNH;K6AAAGX1;TD1H5G5E;P6EVDBJ2;B5ER52PD',
      statusContent: 'dot',
      hash: 'B5SNX28X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5SNX28X'
    },
    {
      status: 810,
      sku: 'PM0074318',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291899',
      rtl_batch_array: '6291899',
      name_search: 'CIHYBRID',
      name: 'Cistus hybridus',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KZA75HV2;VWZA31A2;21BKATHG;WTP87H6N;H3DZXYSW;WGWNV88C;DCVSVWH3;TJ43355P',
      statusContent: 'dot',
      hash: 'LVW8LPEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVW8LPEB'
    },
    {
      status: 210,
      sku: 'PM0067388',
      core_name: 'Plant',
      sppl_ean: '8720349433414',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702544',
      rtl_batch_array: '4702544',
      name_search: 'DIBSTREA',
      name: 'Dianella Blue Stream',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'V9AKE7E2',
      statusContent: 'dot',
      hash: 'PRRH5N89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRRH5N89'
    },
    {
      status: 210,
      sku: 'PM0074319',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269809',
      rtl_batch_array: '6269809, 6310255',
      name_search: 'DEROSEA',
      name: 'Deutzia rosea',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1069,
      chnn_stock_retail: 3319,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W3N9NJX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3N9NJX1'
    },
    {
      status: 210,
      sku: 'PM0067246',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209685',
      rtl_batch_array: '6209685',
      name_search: 'EXRNIAGA',
      name: "Exochorda racemosa 'Niagara'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 874,
      chnn_stock_retail: 874,
      sppl_prcp: 3.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AERC8NXG;BNYXBTEC;XT44X7YT;VD3B6Z1B;JLH3SBCK;B9C8HS3R;J6XTP7A1;H1CR5KZC;SWGBWSRL;AW9Y692B;D9DTVY2V;TTS9K5VT;5A4YC53D;7HT5556T;9147LVST;W3B5LVLP;T1VRCPZ9;YRBZRN1Z;V6V3VVD7;2EJT8SWX;6AYWW6T1;2XCKNVJS',
      statusContent: 'dot',
      hash: 'XAJ44A1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAJ44A1C'
    },
    {
      status: 910,
      sku: 'PM0059883',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'E979NHHX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E979NHHX'
    },
    {
      status: 210,
      sku: 'PM0068204',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193770',
      rtl_batch_array: '6193770',
      name_search: 'CAPENDUL',
      name: 'Carex pendula',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8ZBR6W7S;DZ8A7RBS;J1LKHJRB;G66P3WJ5;E6RAB6TD;X1JXZKJV;6YVN7EJ9;N2GLX2EW;ER1TNX9C;L6AABLYN;1JEKEE3W;NRKR5DXX;HP54W3NB',
      statusContent: 'dot',
      hash: 'B4S3PDYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4S3PDYG'
    },
    {
      status: 210,
      sku: 'PM0065297',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257457',
      rtl_batch_array: '6257457',
      name_search: 'CHLSUNKI',
      name: "Chamaecyparis lawsoniana 'Sunkist'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2221,
      chnn_stock_retail: 2221,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '74HKX81L;TC8D3PRS',
      statusContent: 'dot',
      hash: 'Y16369HK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y16369HK'
    },
    {
      status: 210,
      sku: 'PM0067442',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6191459',
      rtl_batch_array: '6191459',
      name_search: 'SESEXANG',
      name: 'Sedum sexangulare',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1716,
      chnn_stock_retail: 1716,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VN61CTG;NGX9KZ5D;2YCG89C2;VVSKBZ9G;1HH9XY96;4VS66YR3;Z4ZZVHE3;7S62A1WA;CNN6K456;2TY1ZPR9;HDSNBXYL;E2C15RS3;LVNLPXKW;5C1TP88C;4NKLA4DZ;LSZ3DJ9J;XZBXXJJ1;7HYX3VK1;A59AD7LZ;N4WWXT4K;ZTSK8AD6;VNZPJV9J;NDTCA6TL;62DALWPC',
      statusContent: 'dot',
      hash: 'RC423TJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC423TJN'
    },
    {
      status: 210,
      sku: 'PM0053483',
      core_name: 'Plant',
      sppl_ean: '8720664686984',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054594',
      rtl_batch_array: '6054594',
      name_search: 'DEICSALM',
      name: 'Delosperma Ice Cream Salmon',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C73LKPWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C73LKPWY'
    },
    {
      status: 210,
      sku: 'PM0053552',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6208035',
      rtl_batch_array: '6208035',
      name_search: 'LYSJPTAI',
      name: 'Lythrum salicaria JS Pink Tails',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJKP5EJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJKP5EJP'
    },
    {
      status: 210,
      sku: 'PM0067130',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244385',
      rtl_batch_array: '6244385',
      name_search: 'TABFROBU',
      name: "Taxus baccata 'Fastigiata Robusta'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1870,
      chnn_stock_retail: 1870,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJRLWDE4;5WYTHSPR;5EJXXPAJ;7JH3XKZC;7K755T8W',
      statusContent: 'dot',
      hash: 'KSTPXZV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSTPXZV7'
    },
    {
      status: 210,
      sku: 'PM0006737',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6209200',
      rtl_batch_array: '6311197, 6209200, 6304876',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2184,
      chnn_stock_retail: 9534,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: 'ND1JPJLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND1JPJLZ'
    },
    {
      status: 210,
      sku: 'PM0006082',
      core_name: 'Plant',
      sppl_ean: '8720664691827',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5521422',
      rtl_batch_array: '6295642, 6350328, 5521422',
      name_search: 'HEHFSPIN',
      name: "Heliopsis h. 'Funky Spinner'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 491,
      chnn_stock_retail: 821,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2WZ7E2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2WZ7E2V'
    },
    {
      status: 210,
      sku: 'PM0082082',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345489',
      rtl_batch_array: '6345489',
      name_search: 'LAWVOSSI',
      name: "Laburnum watereri 'Vossii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6P8DCEB;B4HD3CG7;CJZ9RAHK;DEXRAHR7;G9LDP2G5;T7DJHNLB;9G78LHKK',
      statusContent: 'dot',
      hash: 'KDSRXZ18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDSRXZ18'
    },
    {
      status: 210,
      sku: 'PM0082083',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345535',
      rtl_batch_array: '6345535',
      name_search: 'CULGRIDE',
      name: "Cupressocyparis leylandii 'Gold Rider'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1544,
      chnn_stock_retail: 1544,
      sppl_prcp: 4.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D1NG67W5;NH4X2A5R',
      statusContent: 'dot',
      hash: '8R22KGNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8R22KGNJ'
    },
    {
      status: 210,
      sku: 'PM0027863',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253103',
      rtl_batch_array: '6253103',
      name_search: 'HEMBEAUT',
      name: "Hebe 'Midsummer Beauty'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 537,
      chnn_stock_retail: 537,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9SE4CNH',
      statusContent: 'dot',
      hash: 'BNPZN7EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNPZN7EA'
    },
    {
      status: 210,
      sku: 'PM0064826',
      core_name: 'Plant',
      sppl_ean: '8720664856097',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6093705',
      rtl_batch_array: '6093705',
      name_search: 'CERALBUS',
      name: "Centranthus ruber 'Albus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 496,
      chnn_stock_retail: 496,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXJP9Z7E;LEB3HGJK;BPBWVS7H;R7RZH464',
      statusContent: 'dot',
      hash: 'JJJ27BGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJJ27BGT'
    },
    {
      status: 810,
      sku: 'PM0046203',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363542',
      rtl_batch_array: '6363542',
      name_search: 'LUMCASTL',
      name: "Lupinus 'My Castle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '87ZW41DK;1ZB27R6Y',
      statusContent: 'dot',
      hash: 'JLZ27XEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLZ27XEH'
    },
    {
      status: 210,
      sku: 'PM0085639',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385617',
      rtl_batch_array: '6385617',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 2850,
      chnn_stock_retail: 2850,
      sppl_prcp: 3.43,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '56VH7HJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56VH7HJG'
    },
    {
      status: 210,
      sku: 'PM0085640',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385619',
      rtl_batch_array: '6385619',
      name_search: 'PRLREYNV',
      name: "Prunus laurocerasus 'Reynvaanii'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BTA419X6',
      statusContent: 'dot',
      hash: 'G9CG5SAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9CG5SAR'
    },
    {
      status: 210,
      sku: 'PM0085641',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385622',
      rtl_batch_array: '6385622',
      name_search: 'PRLVNES',
      name: "Prunus laurocerasus 'Van Nes'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDS5C98A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDS5C98A'
    },
    {
      status: 210,
      sku: 'PM0085642',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385623',
      rtl_batch_array: '6385623',
      name_search: 'PRLVNES',
      name: "Prunus laurocerasus 'Van Nes'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8S3LYYZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S3LYYZ9'
    },
    {
      status: 910,
      sku: 'PM0079439',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317683',
      rtl_batch_array: '6317683',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '200225C15',
      rng_range_identifier: 'H200225C15',
      rng_range_description: 'H200 cm 225 cm C15',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 50.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'JLZV7RRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLZV7RRT'
    },
    {
      status: 810,
      sku: 'PM0048124',
      core_name: 'Plant',
      sppl_ean: '8720664679788',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014337',
      rtl_batch_array: '6014337',
      name_search: 'AGWBABY',
      name: "Agapanthus 'White Baby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSB5JNST;Z7ZSWBTV;5EWNT7B2',
      statusContent: 'dot',
      hash: '7E5J4JY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E5J4JY2'
    },
    {
      status: 210,
      sku: 'PM0010227',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6209185',
      rtl_batch_array: '6209185, 6304745, 6271617, 6278049',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6852,
      chnn_stock_retail: 20424,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'CKR3L9NZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKR3L9NZ'
    },
    {
      status: 810,
      sku: 'PM0067303',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181306',
      rtl_batch_array: '6181306',
      name_search: 'DERPLENA',
      name: "Deutzia 'Rosea Plena'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PCS4DV7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCS4DV7Y'
    },
    {
      status: 210,
      sku: 'PM0067305',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181308',
      rtl_batch_array: '6181308',
      name_search: 'DEMAGNIF',
      name: 'Deutzia magnifica',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GYNTZPNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYNTZPNT'
    },
    {
      status: 210,
      sku: 'PM0071388',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299777',
      rtl_batch_array: '6299777',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1146,
      chnn_stock_retail: 1146,
      sppl_prcp: 4.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'JAL3WPJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAL3WPJT'
    },
    {
      status: 210,
      sku: 'PM0048142',
      core_name: 'Plant',
      sppl_ean: '8720353085883',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014383',
      rtl_batch_array: '6014383',
      name_search: 'ALMAUSLE',
      name: "Alchemilla mollis 'Auslese'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2293,
      chnn_stock_retail: 2293,
      sppl_order_minimum: 3,
      sppl_prcp: 0.684,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AT4L1D57;LHNK1SCJ;KR6EDR3A',
      statusContent: 'dot',
      hash: 'GT7RS5AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT7RS5AK'
    },
    {
      status: 210,
      sku: 'PM0067302',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181305',
      rtl_batch_array: '6181305',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'PR2AN4NR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR2AN4NR'
    },
    {
      status: 210,
      sku: 'PM0067312',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181316',
      rtl_batch_array: '6181316',
      name_search: 'POFGOLDT',
      name: "Potentilla fruticosa 'Goldteppich'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPG1YEWB',
      statusContent: 'dot',
      hash: 'D48C2GE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D48C2GE3'
    },
    {
      status: 910,
      sku: 'PM0067446',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: '51VTJ7E2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '51VTJ7E2'
    },
    {
      status: 210,
      sku: 'PM0068411',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6202285',
      rtl_batch_array: '6202285',
      name_search: 'DIFGHERO',
      name: "Digitalis ferruginea 'Gelber Herold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '14B2CA41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14B2CA41'
    },
    {
      status: 810,
      sku: 'PM0065431',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301097',
      rtl_batch_array: '6301874, 6301097, 6353378',
      name_search: 'THAQUILE',
      name: 'Thalictrum aquilegifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 884,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RJ6G6VD1;W6PXWAWY;NSXJ3K4G;S1XGH8E3;E7G3KBBH;N1Y7JPEJ;STZPE1YK;EXXCBYPX',
      statusContent: 'dot',
      hash: 'RT29BVX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RT29BVX9'
    },
    {
      status: 210,
      sku: 'PM0070209',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220754',
      rtl_batch_array: '6220754',
      name_search: 'ERAGAVIF',
      name: 'Eryngium agavifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RKG929LJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKG929LJ'
    },
    {
      status: 210,
      sku: 'PM0070210',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220756',
      rtl_batch_array: '6220756',
      name_search: 'ERVMMARB',
      name: "Eryngium variifolium 'Mrs Marble'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GB529GSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB529GSX'
    },
    {
      status: 810,
      sku: 'PM0070213',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220762',
      rtl_batch_array: '6220762',
      name_search: 'EUMHAGEV',
      name: "Eupatorium mac. 'Hageveld'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RNVYHZT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNVYHZT4'
    },
    {
      status: 210,
      sku: 'PM0085643',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385624',
      rtl_batch_array: '6385624',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.78,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YD4WLRY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD4WLRY8'
    },
    {
      status: 210,
      sku: 'PM0059896',
      core_name: 'Plant',
      sppl_ean: '8720664885530',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161944',
      rtl_batch_array: '6161944',
      name_search: 'SCOMDANC',
      name: "Scabiosa ochroleuca 'Moon Dance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L8Y3SRDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8Y3SRDZ'
    },
    {
      status: 210,
      sku: 'PM0078908',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252504',
      rtl_batch_array: '6252504',
      name_search: 'CAFKIWI',
      name: "Carex flagellifera 'Kiwi'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1NW82W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1NW82W1'
    },
    {
      status: 210,
      sku: 'PM0078910',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252557',
      rtl_batch_array: '6252557',
      name_search: 'ECSAGLOW',
      name: "Echinops sphaerocephalus 'Arctic Glow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WXK8R9WS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXK8R9WS'
    },
    {
      status: 210,
      sku: 'PM0068000',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333276',
      rtl_batch_array: '6202321, 6333276',
      name_search: 'MEOFFICI',
      name: 'Melissa officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3269,
      chnn_stock_retail: 3480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z7WBWWCG;KY67SKKX;YT2H2H3A;7Z1CKS3H;J94H1PKJ;ZG8XRTW3;E6ZZCHTB;SB2TTNCD;YR684ZL2;TPWYVCDT;ZR4X8BAY;6LNH3WYC;1721TXP5;HYDVSB1R;975GARRY;WPN3X3NJ;ZGABCSZP;XG6KHC2W;AK3WGXJZ;64AP3BGT',
      statusContent: 'dot',
      hash: 'LLPNT4VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLPNT4VY'
    },
    {
      status: 210,
      sku: 'PM0078911',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252622',
      rtl_batch_array: '6252622',
      name_search: 'HECCOLA',
      name: "Heuchera 'Cherry Cola'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 413,
      chnn_stock_retail: 413,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8VJBA4Z',
      statusContent: 'dot',
      hash: 'K7S3Y34C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7S3Y34C'
    },
    {
      status: 210,
      sku: 'PM0063569',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244282',
      rtl_batch_array: '6244282, 6252558',
      name_search: 'ERKARVIN',
      name: 'Erigeron karvinskianus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2391,
      chnn_stock_retail: 3047,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLCP6LRG;VHVSLXHH;D1XJ7VCY;CN8JXGLS;ZZJ48WYR;SXECCCSZ;DX1PEKZ3;XE6HBY14;55VYV45P;EDLAC4EH;6AEWVSK9',
      statusContent: 'dot',
      hash: 'G9S7SXJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9S7SXJV'
    },
    {
      status: 210,
      sku: 'PM0078913',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252672',
      rtl_batch_array: '6252672',
      name_search: 'LYPUNCTA',
      name: 'Lysimachia punctata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 760,
      chnn_stock_retail: 760,
      sppl_prcp: 1.547,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EDGB4Z3N;Z368GRK6;S8C1WBXE;Z1VLJ1JK',
      statusContent: 'dot',
      hash: '7Z6XAGHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z6XAGHE'
    },
    {
      status: 210,
      sku: 'PM0078918',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254667',
      rtl_batch_array: '6254667',
      name_search: 'BERGPURP',
      name: 'Bergenia purpurascens',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1227,
      chnn_stock_retail: 1227,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EG8PDPLC;E1N134XJ;EPZNARSA;1RBS3YY5',
      statusContent: 'dot',
      hash: 'YNCWKYZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNCWKYZS'
    },
    {
      status: 210,
      sku: 'PM0067308',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 3,
      btch_active_retail: '6367968',
      rtl_batch_array: '6367968, 6181312, 6348840',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 348,
      chnn_stock_retail: 948,
      sppl_prcp: 3.695,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'L6ACY8D1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6ACY8D1'
    },
    {
      status: 210,
      sku: 'PM0085644',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385625',
      rtl_batch_array: '6385625',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 4.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DP4BEZS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP4BEZS4'
    },
    {
      status: 210,
      sku: 'PM0068361',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252464',
      rtl_batch_array: '6252464',
      name_search: 'ASGRANAT',
      name: "Astilbe (A) 'Granat'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VLESJT5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLESJT5X'
    },
    {
      status: 210,
      sku: 'PM0085645',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385626',
      rtl_batch_array: '6385626',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 4.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'R57RZPTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R57RZPTW'
    },
    {
      status: 210,
      sku: 'PM0032843',
      core_name: 'Plant',
      sppl_ean: '8720626361423',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837480',
      rtl_batch_array: '5837480',
      name_search: 'PESGRAPE',
      name: "Penstemon 'Sour Grapes'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4Y2B5P6P;7VELANJP;GW51CK97;NDE5LZ2S;HWEJC1PY;5TRN78HD;965VL99P;CDXXG9NP',
      statusContent: 'dot',
      hash: 'WTSJX2BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTSJX2BD'
    },
    {
      status: 210,
      sku: 'PM0059717',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339859',
      rtl_batch_array: '6133418, 6339859',
      name_search: 'NERBWOND',
      name: "Nepeta racemosa 'Blue Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2928,
      chnn_stock_retail: 3160,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S1S6ZA5E;XRLETWS2;84EDN17K',
      statusContent: 'dot',
      hash: 'V26466RD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V26466RD'
    },
    {
      status: 210,
      sku: 'PM0085646',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385628',
      rtl_batch_array: '6385628',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 4.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: '16WDKCAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16WDKCAV'
    },
    {
      status: 210,
      sku: 'PM0085647',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385629',
      rtl_batch_array: '6385629',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: '42TRXEW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42TRXEW6'
    },
    {
      status: 210,
      sku: 'PM0063507',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253122',
      rtl_batch_array: '6253122',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2310,
      chnn_stock_retail: 2310,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: 'V7SDPJKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7SDPJKN'
    },
    {
      status: 210,
      sku: 'PM0039437',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6319752',
      rtl_batch_array: '6202322, 6350679, 6383361, 6319752',
      name_search: 'MIEAUREU',
      name: "Milium effusum 'Aureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 2702,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PA1HGNEJ;WATZBWTA;PNN88RTC;Y1PSZDTA;HYCY176A;TK424JW2;4ZRPJZGR;6EB9G46C;4W53D3DR;CV2DZYYE;PTZ8LNAC;CRE65RJH;W95Z18C7;G2HJ4C6C;V9TE2ZEV;HTV4ST7Z;62TWHZS2',
      statusContent: 'dot',
      hash: 'TGWLDZ1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGWLDZ1Y'
    },
    {
      status: 810,
      sku: 'PM0085648',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385630',
      rtl_batch_array: '6385630',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 5.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'GTX3NKWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTX3NKWD'
    },
    {
      status: 910,
      sku: 'PM0085649',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385632',
      rtl_batch_array: '6385632',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 4.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'NAL4KWEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAL4KWEK'
    },
    {
      status: 210,
      sku: 'PM0085650',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385633',
      rtl_batch_array: '6385633',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 4.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'DE361A7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE361A7T'
    },
    {
      status: 210,
      sku: 'PM0048452',
      core_name: 'Plant',
      sppl_ean: '8720626369788',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6028379',
      rtl_batch_array: '6028379, 6287694',
      name_search: 'SESCBLAN',
      name: "Sedum spathulifolium 'Cape Blanco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 703,
      chnn_stock_retail: 919,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LVBA58VE;83NCYVY9;24LNE3W2;SS3LWL5W;YNSX8KGV;A32D5EKR;TEXGB6C4;9ZVP2NS9;DNRZPTBZ',
      statusContent: 'dot',
      hash: '5YS2CGNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YS2CGNB'
    },
    {
      status: 810,
      sku: 'PM0085651',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385634',
      rtl_batch_array: '6385634',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'AR7Z9KN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR7Z9KN2'
    },
    {
      status: 210,
      sku: 'PM0016891',
      core_name: 'Plant',
      sppl_ean: '8720626396173',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '5450839',
      rtl_batch_array: '5450839, 6378460',
      name_search: 'BULOCHIN',
      name: "Buddleja 'Lochinch'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 375,
      chnn_stock_retail: 2828,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GXTTXG5;EKY59A7K;VYLJHNCG;JJ9B2SR1;45TG8BZ3;EY83V219;LVSBYP12',
      statusContent: 'dot',
      hash: 'VY1CAC7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY1CAC7V'
    },
    {
      status: 210,
      sku: 'PM0085652',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385635',
      rtl_batch_array: '6385635',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 4.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: '5T4SAK9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T4SAK9T'
    },
    {
      status: 210,
      sku: 'PM0085653',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385636',
      rtl_batch_array: '6385636',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: '244T69SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '244T69SW'
    },
    {
      status: 210,
      sku: 'PM0085654',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385637',
      rtl_batch_array: '6385637',
      name_search: 'VIBURHYT',
      name: 'Viburnum rhytidophyllum',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_prcp: 3.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '48SGK5WV;L8E27T5T',
      statusContent: 'dot',
      hash: '8AY6YK78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AY6YK78'
    },
    {
      status: 210,
      sku: 'PM0085655',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385638',
      rtl_batch_array: '6385638',
      name_search: 'VIBURHYT',
      name: 'Viburnum rhytidophyllum',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 4.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '48SGK5WV;L8E27T5T',
      statusContent: 'dot',
      hash: 'A25TEYR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A25TEYR8'
    },
    {
      status: 210,
      sku: 'PM0085656',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385639',
      rtl_batch_array: '6385639',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: 'NC5753V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NC5753V2'
    },
    {
      status: 210,
      sku: 'PM0085657',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385640',
      rtl_batch_array: '6385640',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 825,
      chnn_stock_retail: 825,
      sppl_prcp: 3.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: 'WG25BV3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WG25BV3W'
    },
    {
      status: 210,
      sku: 'PM0085658',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385641',
      rtl_batch_array: '6385641',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: '4DDREW34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DDREW34'
    },
    {
      status: 210,
      sku: 'PM0085659',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385642',
      rtl_batch_array: '6385642',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 3.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: '891PC6S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '891PC6S6'
    },
    {
      status: 210,
      sku: 'PM0085660',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385643',
      rtl_batch_array: '6385643',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 5.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: '4LN1NVWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LN1NVWH'
    },
    {
      status: 210,
      sku: 'PM0085661',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385644',
      rtl_batch_array: '6385644',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1125,
      chnn_stock_retail: 1125,
      sppl_prcp: 5.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: '4GPTJXAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GPTJXAG'
    },
    {
      status: 210,
      sku: 'PM0085662',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385645',
      rtl_batch_array: '6385645',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 6.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: 'S12R6TYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S12R6TYW'
    },
    {
      status: 210,
      sku: 'PM0001626',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6255893',
      rtl_batch_array: '6176223, 6255893, 6353190, 6340040',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 11503,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: 'B62GEZ2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B62GEZ2D'
    },
    {
      status: 210,
      sku: 'PM0085663',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385646',
      rtl_batch_array: '6385646',
      name_search: 'PISYLVES',
      name: 'Pinus sylvestris',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 1315,
      chnn_stock_retail: 1315,
      sppl_prcp: 5.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7L3J9VNA;KX78HJLD;N732DC1A;GGJG12CS;HKS1N5EE;C1SG5VDN;2BV6TH7P',
      statusContent: 'dot',
      hash: 'BYTHA5E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYTHA5E5'
    },
    {
      status: 210,
      sku: 'PM0085664',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385647',
      rtl_batch_array: '6385647',
      name_search: 'PIWALLIC',
      name: 'Pinus wallichiana',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 3.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJ4DPHTK;S7YWKNVX;G7LYA11R;KAK23EZX;GEWXWSKZ;V798ZY64;KAXJPGSX;6B61XV4W',
      statusContent: 'dot',
      hash: 'AAR1TD8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAR1TD8N'
    },
    {
      status: 210,
      sku: 'PM0085665',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385648',
      rtl_batch_array: '6385648',
      name_search: 'PIWALLIC',
      name: 'Pinus wallichiana',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJ4DPHTK;S7YWKNVX;G7LYA11R;KAK23EZX;GEWXWSKZ;V798ZY64;KAXJPGSX;6B61XV4W',
      statusContent: 'dot',
      hash: '2AP46VTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AP46VTS'
    },
    {
      status: 210,
      sku: 'PM0085666',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385649',
      rtl_batch_array: '6385649',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 1950,
      chnn_stock_retail: 1950,
      sppl_prcp: 3.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'HYJSRY1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYJSRY1J'
    },
    {
      status: 210,
      sku: 'PM0085667',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385650',
      rtl_batch_array: '6385650',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 1050,
      chnn_stock_retail: 1050,
      sppl_prcp: 3.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'ZKWXYENJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKWXYENJ'
    },
    {
      status: 210,
      sku: 'PM0067306',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181310',
      rtl_batch_array: '6181310',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2GR278TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GR278TZ'
    },
    {
      status: 210,
      sku: 'PM0085668',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385652',
      rtl_batch_array: '6385652',
      name_search: 'TAMGROEN',
      name: "Taxus media 'Groenland'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GDXHH5SN;61DEPLVH;AY9THTDK;H7L6DT1P;W34X7N4E',
      statusContent: 'dot',
      hash: '65WP7AT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65WP7AT5'
    },
    {
      status: 210,
      sku: 'PM0048305',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350065',
      rtl_batch_array: '6350065, 6320861',
      name_search: 'ECPMERLO',
      name: "Echinacea purpurea 'Merlot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 328,
      chnn_stock_retail: 428,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SZEKB5G;GLR2379B',
      statusContent: 'dot',
      hash: 'PR6XPPVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR6XPPVY'
    },
    {
      status: 210,
      sku: 'PM0032844',
      core_name: 'Plant',
      sppl_ean: '8720349472857',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837484',
      rtl_batch_array: '5837484',
      name_search: 'PHAGRACE',
      name: "Phlox (S) 'Amazing Grace'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y8TC1TKS;72X62YS7;HJ7TKPT3;5NSXG8D5;YWDTRDRR;4TNK7B7H;JXX2K8EN;VZ8PR4E5;8YKV9AED;CKBDY3EJ;K597VHWR;EP18SHL2;WWBCKH9G;YAKG1G9V;RS73SZKN',
      statusContent: 'dot',
      hash: 'PL3VK5JZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PL3VK5JZ'
    },
    {
      status: 210,
      sku: 'PM0085669',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385653',
      rtl_batch_array: '6385653',
      name_search: 'TAMGROEN',
      name: "Taxus media 'Groenland'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GDXHH5SN;61DEPLVH;AY9THTDK;H7L6DT1P;W34X7N4E',
      statusContent: 'dot',
      hash: 'N4LS2KAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4LS2KAB'
    },
    {
      status: 210,
      sku: 'PM0063755',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6229887',
      rtl_batch_array: '6229887, 6230981, 6252853, 6333213',
      name_search: 'CREMCKEN',
      name: "Crocosmia 'Emily McKenzie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1455,
      chnn_stock_retail: 3179,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DC69V24L;YH7EWXYY;7T621Y78',
      statusContent: 'dot',
      hash: 'HR2DBS6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR2DBS6X'
    },
    {
      status: 210,
      sku: 'PM0085670',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385654',
      rtl_batch_array: '6385654',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 4.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: 'SB3BL91A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SB3BL91A'
    },
    {
      status: 910,
      sku: 'PM0046586',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAKROSEN',
      name: "Paeonia (LD) 'Karl Rosenfield'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '25VJ5AS1;DE8L34XV;NGXVVV8Y;JAVSBX8G;V622SNR3;14VCRPEA;HP71LGS8;8P1K23AV;YA9NP5RK;EY3WEJNP',
      statusContent: 'dot',
      hash: '2YT41SKE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2YT41SKE'
    },
    {
      status: 210,
      sku: 'PM0085671',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385655',
      rtl_batch_array: '6385655',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '060070KL',
      rng_range_identifier: 'H060070RB',
      rng_range_description: 'H60 cm 70 cm Root ball',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 4.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: '81W65NKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81W65NKD'
    },
    {
      status: 210,
      sku: 'PM0057979',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6176225',
      rtl_batch_array: '6153435, 6176225, 6254416',
      name_search: 'SAMONTAN',
      name: 'Satureja montana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 1072,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C6ND8GDG;ELT54CGZ;DCSDB49A;XCGP347X;WE56VV7E;CL32L21A;6GBL9LPX;YYYN4RLA;4WS1JCCC;L6762APC;2SJWPAWD;TLWSXXVN;XL8PCGPE;W2TX5YLH;GEYBN3JC;AZWXDB3J;4HRZ99GR;P7ELA1J7;KDJNCD3T;7RHZ5ZB5;SH2N14WD;P8N414ZA;7H47TXPN;R2X3WHLV',
      statusContent: 'dot',
      hash: 'A95D26SP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A95D26SP'
    },
    {
      status: 210,
      sku: 'PM0085672',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385656',
      rtl_batch_array: '6385656',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'WTHEJDB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTHEJDB9'
    },
    {
      status: 210,
      sku: 'PM0085673',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385657',
      rtl_batch_array: '6385657',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.43,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: 'Z4AP14S4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4AP14S4'
    },
    {
      status: 210,
      sku: 'PM0085674',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385658',
      rtl_batch_array: '6385658',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 4.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: '82GKPVEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82GKPVEB'
    },
    {
      status: 210,
      sku: 'PM0085675',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385659',
      rtl_batch_array: '6385659',
      name_search: 'ROMHAMMA',
      name: "Rosa (R) 'Moje Hammarberg'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1460,
      chnn_stock_retail: 1460,
      sppl_order_minimum: 3,
      sppl_prcp: 1.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SGABAJNW',
      statusContent: 'dot',
      hash: 'K9X1JKAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9X1JKAX'
    },
    {
      status: 210,
      sku: 'PM0009593',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6344143',
      rtl_batch_array: '6344143',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7000,
      sppl_prcp: 2.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DCL9E2GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCL9E2GD'
    },
    {
      status: 210,
      sku: 'PM0085676',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385660',
      rtl_batch_array: '6385660',
      name_search: 'RORZWERG',
      name: "Rosa (R) 'Rosa Zwerg'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2165,
      chnn_stock_retail: 2165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TLTYEE7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLTYEE7B'
    },
    {
      status: 210,
      sku: 'PM0053394',
      core_name: 'Plant',
      sppl_ean: '8720664680210',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054310',
      rtl_batch_array: '6054310',
      name_search: 'ALAGBEAU',
      name: "Allium amplectens 'Graceful Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5LLCAR59;JCKXRKSC;BLA2WAES;BG7HPZR6;ASSPL4HE;17GWY3ZD;CLXN67CC;KWWNZHK4;L4Z1W25C;HNRLLLP2;R4YXVSBB;27LKWJV7;GT85ZTX3;EEJ2SB15;LR7GSA3B;NSANPC13;645KJGT3;RW1TYJZ2;8BAS37ET',
      statusContent: 'dot',
      hash: 'LLLN3Y7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLLN3Y7W'
    },
    {
      status: 210,
      sku: 'PM0052911',
      core_name: 'Plant',
      sppl_ean: '8720349498314',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6003586',
      rtl_batch_array: '6003586, 6254775',
      name_search: 'MEOFFICI',
      name: 'Melissa officinalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2822,
      chnn_stock_retail: 3372,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z7WBWWCG;KY67SKKX;YT2H2H3A;7Z1CKS3H;J94H1PKJ;ZG8XRTW3;E6ZZCHTB;SB2TTNCD;YR684ZL2;TPWYVCDT;ZR4X8BAY;6LNH3WYC;1721TXP5;HYDVSB1R;975GARRY;WPN3X3NJ;ZGABCSZP;XG6KHC2W;AK3WGXJZ;64AP3BGT',
      statusContent: 'dot',
      hash: 'B31HT75X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B31HT75X'
    },
    {
      status: 210,
      sku: 'PM0065406',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220647',
      rtl_batch_array: '6220647',
      name_search: 'ANRAMOSU',
      name: 'Anthericum ramosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 622,
      chnn_stock_retail: 622,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJW3N5KY',
      statusContent: 'dot',
      hash: 'LW71P6YD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LW71P6YD'
    },
    {
      status: 210,
      sku: 'PM0053403',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329822',
      rtl_batch_array: '6329822',
      name_search: 'ALLUSITA',
      name: 'Allium lusitanicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VNEZEJNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNEZEJNC'
    },
    {
      status: 210,
      sku: 'PM0005881',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243019',
      rtl_batch_array: '6243019',
      name_search: 'LEPOTENT',
      name: 'Leptinella potentillina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SY1G5N37',
      statusContent: 'dot',
      hash: 'SD1NW9G7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD1NW9G7'
    },
    {
      status: 210,
      sku: 'PM0049854',
      core_name: 'Plant',
      sppl_ean: '8720664689688',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '5393666',
      rtl_batch_array: '5393666, 5582246',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 250,
      sppl_prcp: 2.012,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'ZZR95HGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZR95HGL'
    },
    {
      status: 210,
      sku: 'PM0033486',
      core_name: 'Plant',
      sppl_ean: '8720626332416',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849417',
      rtl_batch_array: '5849417',
      name_search: 'ACIPURPU',
      name: "Acaena inermis 'Purpurea'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CRZLB297',
      imageBatch: 'WNZ4TCES',
      statusContent: 'dot',
      hash: 'P82VG5S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P82VG5S1'
    },
    {
      status: 210,
      sku: 'PM0059856',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299796',
      rtl_batch_array: '6299796',
      name_search: 'PRLMANO',
      name: "Prunus laurocerasus 'Mano'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1464,
      chnn_stock_retail: 1464,
      sppl_prcp: 3.918,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CBV58A24',
      statusContent: 'dot',
      hash: 'H1RPNAB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1RPNAB2'
    },
    {
      status: 910,
      sku: 'PM0071389',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FICDPORT',
      name: "Ficus carica 'Del Portogallo'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      statusContent: 'dot',
      hash: 'A3RXL9EX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A3RXL9EX'
    },
    {
      status: 210,
      sku: 'PM0066887',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350331',
      rtl_batch_array: '6350331, 6302571',
      name_search: 'HEHSNIGH',
      name: "Heliopsis helianthoides 'Summer Nights'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 183,
      chnn_stock_retail: 321,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P7VK9SDC;T7N55ZL9;7N2GLDZZ',
      statusContent: 'dot',
      hash: '7ZXN5TGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZXN5TGV'
    },
    {
      status: 810,
      sku: 'PM0053414',
      core_name: 'Plant',
      sppl_ean: '8720664680395',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054349',
      rtl_batch_array: '6054349',
      name_search: 'ALSDIVA',
      name: "Allium schoen. 'Diva'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '73976N7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73976N7N'
    },
    {
      status: 210,
      sku: 'PM0048362',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308998',
      rtl_batch_array: '6037898, 6176419, 6308998, 6319703',
      name_search: 'HEKANARI',
      name: "Helenium 'Kanaria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1208,
      chnn_stock_retail: 2050,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2R99J37;NZ3ZSREY;8L4951TE;HGRXJYK1;5NJTYTZJ',
      statusContent: 'dot',
      hash: 'D3XH877B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3XH877B'
    },
    {
      status: 210,
      sku: 'PM0068410',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302458',
      rtl_batch_array: '6302458',
      name_search: 'DEVOELKE',
      name: "Delphinium (B) 'V\u00f6lkerfrieden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ABE1HABJ;1DL64J4J',
      statusContent: 'dot',
      hash: 'EHE6YWHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHE6YWHG'
    },
    {
      status: 210,
      sku: 'PM0052518',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363205',
      rtl_batch_array: '6142899, 6317857, 6363205',
      name_search: 'SAOFFICI',
      name: 'Salvia officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7198,
      chnn_stock_retail: 7691,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KH989H8A;ASEDG7N1;96JG2RGP;7EBJN7GH;GGKPYL7L;5S5WHJHY;7EVKDY18;R8DDDH2Z;GWBLLYD2;JWVAXGJ8;GTE4N5BC;LHR87C97;G7H1NB3C;VZ373N22;KHKLYG9J;KEPTSH4Z;NTT9ZDCC;L8GHZ3JC;J67T4CLC',
      statusContent: 'dot',
      hash: '4L5SHBES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4L5SHBES'
    },
    {
      status: 210,
      sku: 'PM0071383',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348746',
      rtl_batch_array: '6348746',
      name_search: 'CEDGVERS',
      name: "Ceanothus delilianus 'Gloire de Versailles'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ATGPTYLR;XA17B3K4;HC77YL3A;4TPEZX29',
      statusContent: 'dot',
      hash: 'WPRWET7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPRWET7B'
    },
    {
      status: 210,
      sku: 'PM0067304',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337960',
      rtl_batch_array: '6181307, 6337960',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 340,
      chnn_stock_retail: 440,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: '58S2YC1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58S2YC1L'
    },
    {
      status: 210,
      sku: 'PM0071384',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348745',
      rtl_batch_array: '6348745',
      name_search: 'CEDHDEFO',
      name: "Ceanothus delilianus 'Henri D\u00e9foss\u00e9'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K957WB19;7B3BSKP8;PC98D382',
      statusContent: 'dot',
      hash: 'E2A4PR55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2A4PR55'
    },
    {
      status: 210,
      sku: 'PM0060151',
      core_name: 'Plant',
      sppl_ean: '8720664863545',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6132976',
      rtl_batch_array: '6132976',
      name_search: 'FOVPURPU',
      name: "Foeniculum vulgare 'Purpureum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 322,
      chnn_stock_retail: 322,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NTE65B9R;7N42B8DB;78JG3WN5;4DX6WV98;A2N1XP7C;JNRETVLS;6JXAJDK3;TTHJ54N5;D9N956WV;LZKHG8G5;GJB7R9EY;NDDAAZLH;95ZR5W46;D4C2W36Y;C1CE1YR9;RX4K9VY4;ZT91KPY8;PN8TC7Z5;AGWYLBL5',
      statusContent: 'dot',
      hash: 'YS52YP9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS52YP9P'
    },
    {
      status: 910,
      sku: 'PM0071386',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEWILLMO',
      name: 'Ceratostigma willmottianum',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore: '8VW817YL',
      statusContent: 'dot',
      hash: '6BDY6ZJ9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6BDY6ZJ9'
    },
    {
      status: 910,
      sku: 'PM0071387',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COPAUCIF',
      name: 'Corylopsis pauciflora',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore:
        'LDGETZ35;4HWKVX7H;D9G19CA5;ZYCA4VNX;YKTAZCR3;C6GJZPXP;PK4P4993;RL7VSYRE;7A59BD77',
      statusContent: 'dot',
      hash: 'RK6KECES',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RK6KECES'
    },
    {
      status: 210,
      sku: 'PM0033906',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260713',
      rtl_batch_array: '6260713, 6254764',
      name_search: 'LAMWNANC',
      name: "Lamium maculatum 'White Nancy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1429,
      chnn_stock_retail: 1785,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DP1KYYLN;6DXKXJ2K;DG1G149H;4PWEXTXA;TRKTCS31',
      statusContent: 'dot',
      hash: 'JK956HJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JK956HJJ'
    },
    {
      status: 210,
      sku: 'PM0059895',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6255890',
      rtl_batch_array: '6176219, 6255890, 6171160',
      name_search: 'LAMBSILV',
      name: "Lamium maculatum 'Beacon Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1675,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5SDS5BAS;Y7VKCZ68;6JSP4ALE;JAEVB74X;Z5LGHT2D;7DZD226A;BX34SBL1;C7D8ECK1;ZXWWEYN2;A88BL2AS',
      statusContent: 'dot',
      hash: 'PYYKG85N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYYKG85N'
    },
    {
      status: 910,
      sku: 'PM0074321',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'WESNW95N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WESNW95N'
    },
    {
      status: 210,
      sku: 'PM0058156',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301363',
      rtl_batch_array: '6301363, 6112315',
      name_search: 'ACMRBEAU',
      name: "Achillea millefolium 'Red Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 561,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JDE31K72;CS451W75;8H55624L;G2JVAN7D;9X1HYAXL;PZNCBRXB',
      statusContent: 'dot',
      hash: '5VY8DNGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VY8DNGV'
    },
    {
      status: 910,
      sku: 'PM0082084',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ABEGHDAY',
      name: 'Abelia grandiflora Happy Daydream',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'JC4G7LDK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JC4G7LDK'
    },
    {
      status: 210,
      sku: 'PM0023990',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353386',
      rtl_batch_array: '6353386',
      name_search: 'THFGLAUC',
      name: 'Thalictrum flavum glaucum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V5AJLC9J',
      statusContent: 'dot',
      hash: 'KK8XYL72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KK8XYL72'
    },
    {
      status: 210,
      sku: 'PM0063735',
      core_name: 'Plant',
      sppl_ean: '8720664871779',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142541',
      rtl_batch_array: '6142541',
      name_search: 'LAFREDER',
      name: "Lavatera 'Frederique'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 779,
      chnn_stock_retail: 779,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A9BX9ZZZ',
      statusContent: 'dot',
      hash: 'EDAGV61X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDAGV61X'
    },
    {
      status: 910,
      sku: 'PM0079452',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WECANDID',
      name: "Weigela 'Candida'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: 'T4GXH9E8;7BS3ASET',
      statusContent: 'dot',
      hash: 'ARNZX5P7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ARNZX5P7'
    },
    {
      status: 210,
      sku: 'PM0059897',
      core_name: 'Plant',
      sppl_ean: '8720664886605',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6129669',
      rtl_batch_array: '6129669, 6301854, 6308754',
      name_search: 'SOLLEMOR',
      name: "Solidaster luteus 'Lemore'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 775,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N2P71CPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2P71CPL'
    },
    {
      status: 210,
      sku: 'PM0068268',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196791',
      rtl_batch_array: '6196791',
      name_search: 'IRBRASSI',
      name: "Iris (P) 'Brassie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T7D8LEJ6;VC68ENGP',
      statusContent: 'dot',
      hash: 'VAA4692S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAA4692S'
    },
    {
      status: 210,
      sku: 'PM0068436',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193773',
      rtl_batch_array: '6193773',
      name_search: 'EDI89206',
      name: "Colocasia esculenta 'Mojito'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BDDH46E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDDH46E7'
    },
    {
      status: 210,
      sku: 'PM0054545',
      core_name: 'Plant',
      sppl_ean: '8720349431458',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '4695280',
      rtl_batch_array: '6349063, 4695280',
      name_search: 'CERALBUS',
      name: "Centranthus ruber 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 195,
      chnn_stock_retail: 764,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXJP9Z7E;LEB3HGJK;BPBWVS7H;R7RZH464',
      statusContent: 'dot',
      hash: 'SATL6LS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SATL6LS6'
    },
    {
      status: 210,
      sku: 'PM0046218',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6363186',
      rtl_batch_array: '6161831, 5960183, 6363186',
      name_search: 'LYPUNCTA',
      name: 'Lysimachia punctata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5328,
      chnn_stock_retail: 6120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EDGB4Z3N;Z368GRK6;S8C1WBXE;Z1VLJ1JK',
      statusContent: 'dot',
      hash: 'XXJJ6KDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXJJ6KDP'
    },
    {
      status: 210,
      sku: 'PM0074325',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269822',
      rtl_batch_array: '6269822',
      name_search: 'ACMRVELV',
      name: "Achillea millefolium 'Red Velvet'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 683,
      chnn_stock_retail: 683,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6Z1BPVZ2',
      statusContent: 'dot',
      hash: 'DAGVDB3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAGVDB3H'
    },
    {
      status: 210,
      sku: 'PM0074326',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269823',
      rtl_batch_array: '6269823, 6305789',
      name_search: 'ANHRSWAN',
      name: "Anemone hybrida 'Ruffled Swan'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1675,
      chnn_stock_retail: 2463,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKB7BP5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKB7BP5A'
    },
    {
      status: 910,
      sku: 'PM0071390',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'NRTXW4X6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NRTXW4X6'
    },
    {
      status: 210,
      sku: 'PM0067462',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272877',
      rtl_batch_array: '6272877',
      name_search: 'HEPPAGEI',
      name: "Hebe pinguifolia 'Pagei'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 864,
      chnn_stock_retail: 864,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWV3S6NB;5B1CCRZS;HT4AH9T2',
      statusContent: 'dot',
      hash: 'AVNC171V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVNC171V'
    },
    {
      status: 210,
      sku: 'PM0070214',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350166',
      rtl_batch_array: '6350166',
      name_search: 'EUPPURPU',
      name: "Euphorbia polychroma 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2592,
      chnn_stock_retail: 2592,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KREYZ7TL;HJ29YZAE',
      statusContent: 'dot',
      hash: 'RYW1LRR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYW1LRR4'
    },
    {
      status: 810,
      sku: 'PM0068461',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301838',
      rtl_batch_array: '6301838',
      name_search: 'SESIEBOL',
      name: 'Sedum sieboldii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZP6B73B;X452DHVY;T919SRWT;D4BDV9S8;G2NPDCEH',
      statusContent: 'dot',
      hash: '1Z6WPP65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Z6WPP65'
    },
    {
      status: 910,
      sku: 'PM0082085',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ABEGHDAY',
      name: 'Abelia grandiflora Happy Daydream',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      statusContent: 'dot',
      hash: '6XZS4TEP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6XZS4TEP'
    },
    {
      status: 210,
      sku: 'PM0057445',
      core_name: 'Plant',
      sppl_ean: '8720664885868',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133686',
      rtl_batch_array: '6133686',
      name_search: 'SEHIMMER',
      name: "Sedum hybridum 'Immergr\u00fcnchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 452,
      chnn_stock_retail: 452,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LC94J52Y',
      statusContent: 'dot',
      hash: 'BYSN7KVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYSN7KVA'
    },
    {
      status: 210,
      sku: 'PM0065564',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234576',
      rtl_batch_array: '6234576',
      name_search: 'ASASTARS',
      name: "Aster ageratoides 'Starshine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 2.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C8Y7C6P9;8128C2VH;12XY9NRD;RD1DGS69;DDK72YT6',
      statusContent: 'dot',
      hash: 'HCCDVBSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCCDVBSZ'
    },
    {
      status: 210,
      sku: 'PM0074327',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269824',
      rtl_batch_array: '6269824',
      name_search: 'ASNBWCHU',
      name: "Aster novi-belgii 'Winston Churchill'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 815,
      chnn_stock_retail: 815,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HL6N8S9T;XHCTWLW6;NG7V5J69',
      statusContent: 'dot',
      hash: 'SPG4JW1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPG4JW1R'
    },
    {
      status: 210,
      sku: 'PM0014432',
      core_name: 'Plant',
      sppl_ean: '8720349497447',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '5608764',
      rtl_batch_array: '6133463, 5608764',
      name_search: 'PEAJAPON',
      name: "Pennisetum alopecuroides 'Japonicum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3340,
      chnn_stock_retail: 4505,
      sppl_order_minimum: 3,
      sppl_prcp: 0.588,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEG2C4CG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEG2C4CG'
    },
    {
      status: 210,
      sku: 'PM0059900',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207812',
      rtl_batch_array: '6207812, 6301419',
      name_search: 'ARSZWEIW',
      name: "Aruncus sinensis 'Zweiweltenkind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 112,
      chnn_stock_retail: 256,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CLN9762',
      statusContent: 'dot',
      hash: '772Z6W2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '772Z6W2K'
    },
    {
      status: 810,
      sku: 'PM0067469',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284278',
      rtl_batch_array: '6284278',
      name_search: 'ACMLATIF',
      name: "Acanthus mollis 'Latifolius'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HV9T78Z8;71RNHHXG',
      statusContent: 'dot',
      hash: 'ZDXSANX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDXSANX6'
    },
    {
      status: 810,
      sku: 'PM0067471',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192635',
      rtl_batch_array: '6192635',
      name_search: 'ALRAMOSU',
      name: 'Allium ramosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TBG5N1XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBG5N1XV'
    },
    {
      status: 210,
      sku: 'PM0068364',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301399',
      rtl_batch_array: '6301399, 6307974',
      name_search: 'ANHLOREL',
      name: "Anemone hybrida 'Loreley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 145,
      chnn_stock_retail: 1118,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XE22L5VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE22L5VJ'
    },
    {
      status: 210,
      sku: 'PM0001102',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284216',
      rtl_batch_array: '6133291, 6284216, 5439534',
      name_search: 'DECESPIT',
      name: 'Deschampsia cespitosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5836,
      chnn_stock_retail: 7833,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWAZ5E95',
      statusContent: 'dot',
      hash: 'C4AP8P2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4AP8P2L'
    },
    {
      status: 210,
      sku: 'PM0032214',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254712',
      rtl_batch_array: '6254712',
      name_search: 'DRMARGIN',
      name: 'Dryopteris marginalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DXYBARZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXYBARZ9'
    },
    {
      status: 210,
      sku: 'PM0067479',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192657',
      rtl_batch_array: '6192657',
      name_search: 'EDI62261',
      name: "Camassia quamash 'Orion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KRLT79YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRLT79YA'
    },
    {
      status: 910,
      sku: 'PM0082086',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294796',
      rtl_batch_array: '6294796',
      name_search: 'HISOBLEU',
      name: "Hibiscus syriacus 'Oiseau Bleu'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 39.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLS6B2VD;T9G74VLT;G886HN24;E3ZTJG2L;7JR472KD',
      statusContent: 'dot',
      hash: 'J2PKAACX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2PKAACX'
    },
    {
      status: 910,
      sku: 'PM0070220',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GESTEPHA',
      name: "Geranium 'Stephanie'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'ZDZK1H2J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZDZK1H2J'
    },
    {
      status: 210,
      sku: 'PM0070219',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220779',
      rtl_batch_array: '6220779',
      name_search: 'GECJJNIG',
      name: 'Geranium cinereum Jolly Jewel Night',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 598,
      chnn_stock_retail: 598,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GGGCKJYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGGCKJYB'
    },
    {
      status: 210,
      sku: 'PM0032223',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6221462',
      rtl_batch_array: '6221462, 6254840',
      name_search: 'SEPEMPER',
      name: "Sedum 'Purple Emperor'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 937,
      chnn_stock_retail: 2857,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WA68ZRR',
      statusContent: 'dot',
      hash: 'K2C88LPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2C88LPG'
    },
    {
      status: 210,
      sku: 'PM0068366',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217162',
      rtl_batch_array: '6217162, 6386129',
      name_search: 'HEIDCURR',
      name: "Helichrysum italicum 'Dwarf Curry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 188,
      chnn_stock_retail: 308,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W7EB7L2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7EB7L2V'
    },
    {
      status: 210,
      sku: 'PM0079440',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270486',
      rtl_batch_array: '6270486',
      name_search: 'ASROSENW',
      name: "Aster (D) 'Rosenwichtel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5CY4X947',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CY4X947'
    },
    {
      status: 210,
      sku: 'PM0082087',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6324697',
      rtl_batch_array: '6324697, 6350633, 6260119, 6309006',
      name_search: 'LAIEDELW',
      name: "Lavandula intermedia 'Edelweiss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 24371,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GN628X7Y;D3BR2DV9;GLJR739N;936WCW3B;V24GK371;WP84S8N3',
      statusContent: 'dot',
      hash: 'ZSHA5V5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSHA5V5V'
    },
    {
      status: 810,
      sku: 'PM0082088',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 7,
      btch_active_retail: '6348450',
      rtl_batch_array:
        '6324698, 6269837, 6164328, 6350626, 6315073, 6348450, 6309007',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 63527,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: '112L5CE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '112L5CE9'
    },
    {
      status: 210,
      sku: 'PM0079441',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301677',
      rtl_batch_array: '6301677',
      name_search: 'LAORVALA',
      name: 'Lamium orvala',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 986,
      chnn_stock_retail: 986,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PLJBLC3V;97938T3D;Y6HXZEDE;ESBWEY3D',
      statusContent: 'dot',
      hash: '5KEYYZ8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KEYYZ8R'
    },
    {
      status: 910,
      sku: 'PM0057478',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TAVULGAR',
      name: 'Tanacetum vulgare',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'Z1C9LEZL;B9TNYWE5;H3XYE9PL;Y5HE8D3J',
      statusContent: 'dot',
      hash: 'TKC4N4H4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TKC4N4H4'
    },
    {
      status: 810,
      sku: 'PM0079442',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301928',
      rtl_batch_array: '6301928',
      name_search: 'KALCAROU',
      name: "Kalmia latifolia 'Carousel'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7AKAJV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7AKAJV9'
    },
    {
      status: 210,
      sku: 'PM0079443',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301929',
      rtl_batch_array: '6301929',
      name_search: 'KALELF',
      name: "Kalmia latifolia 'Elf'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 8.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H28LN6VE',
      statusContent: 'dot',
      hash: 'XASG23TR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XASG23TR'
    },
    {
      status: 810,
      sku: 'PM0079444',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301931',
      rtl_batch_array: '6301931',
      name_search: 'KALLLIND',
      name: "Kalmia latifolia 'Little Linda'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 8.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S2CNA3Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2CNA3Y8'
    },
    {
      status: 210,
      sku: 'PM0065631',
      core_name: 'Plant',
      sppl_ean: '8720664875630',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165690',
      rtl_batch_array: '6165690',
      name_search: 'OEMACROC',
      name: 'Oenothera macrocarpa',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 401,
      chnn_stock_retail: 401,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7E6DLX3J;839ST729;EKSKP4RA',
      statusContent: 'dot',
      hash: 'NGKHSSGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGKHSSGP'
    },
    {
      status: 210,
      sku: 'PM0065630',
      core_name: 'Plant',
      sppl_ean: '8720664872196',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165689',
      rtl_batch_array: '6165689',
      name_search: 'LEVMAIKO',
      name: "Leucanthemum vulgare 'Maik\u00f6nigin'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6069,
      chnn_stock_retail: 6069,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VPEJPET1;C6SNZBW8;NBXVAZS5;YBPNZL1H;6R7624PW;LZR22RVA',
      statusContent: 'dot',
      hash: '7WD3JY39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WD3JY39'
    },
    {
      status: 910,
      sku: 'PM0082089',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '915H4TV1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '915H4TV1'
    },
    {
      status: 810,
      sku: 'PM0079445',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301932',
      rtl_batch_array: '6301932',
      name_search: 'KALMINUE',
      name: "Kalmia latifolia 'Minuet'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 8.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '46GZ45TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46GZ45TB'
    },
    {
      status: 210,
      sku: 'PM0068437',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193775',
      rtl_batch_array: '6193775',
      name_search: 'EDI89297',
      name: "Colocasia esculenta 'Tea Cup'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6762G4AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6762G4AB'
    },
    {
      status: 210,
      sku: 'PM0045881',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319695',
      rtl_batch_array: '6350225, 6319695',
      name_search: 'GEPALUST',
      name: 'Geranium palustre',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6L6RYGKW;13DZEVN5',
      statusContent: 'dot',
      hash: 'G8L5AGDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8L5AGDS'
    },
    {
      status: 210,
      sku: 'PM0065643',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6299650',
      rtl_batch_array: '6299650, 6368795',
      name_search: 'ASJENNY',
      name: "Aster (D) 'Jenny'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2784,
      chnn_stock_retail: 3784,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ES6TNHE1;WWWEGX5W;A1LTXPKY;CXE9R7AS;XL9655W5;HXYDNX46;Z3825STW;XX8YSN71;HY1LCL2B',
      statusContent: 'dot',
      hash: 'P4N4T9ZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4N4T9ZB'
    },
    {
      status: 810,
      sku: 'PM0068363',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6199473',
      rtl_batch_array: '6199473, 6276344',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 70,
      chnn_stock_retail: 270,
      sppl_prcp: 6.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'V9KCEVXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9KCEVXT'
    },
    {
      status: 810,
      sku: 'PM0079446',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301934',
      rtl_batch_array: '6301934',
      name_search: 'KALPEPPE',
      name: "Kalmia latifolia 'Peppermint'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NLH385ZH',
      statusContent: 'dot',
      hash: '2ETC7PS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ETC7PS7'
    },
    {
      status: 210,
      sku: 'PM0067473',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242076',
      rtl_batch_array: '6242076',
      name_search: 'ALISUMME',
      name: 'Alstroemeria Indian Summer',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1415,
      chnn_stock_retail: 1415,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXTDYYH1;B63A3VJ7;5LDH54WV;CVECY9LE;956G5835;LW97HNCG;AAAEJDL8;R7XTZADR',
      statusContent: 'dot',
      hash: '1SGA2H79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SGA2H79'
    },
    {
      status: 810,
      sku: 'PM0079447',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301937',
      rtl_batch_array: '6301937',
      name_search: 'KALTIDDL',
      name: "Kalmia latifolia 'Tiddlywinks'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 8.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P657P669',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P657P669'
    },
    {
      status: 210,
      sku: 'PM0079448',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6369238',
      rtl_batch_array: '6369238, 6295623, 6324743',
      name_search: 'GETTANGE',
      name: "Geum 'Totally Tangerine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1396,
      chnn_stock_retail: 3433,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N446YYD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N446YYD1'
    },
    {
      status: 910,
      sku: 'PM0033561',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GESSTRIA',
      name: 'Geranium sanguineum striatum',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      imageCore: 'TLP4T4EE;VBRH6X1W;Y2BBW4CV',
      statusContent: 'dot',
      hash: 'XE1WEZH9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XE1WEZH9'
    },
    {
      status: 210,
      sku: 'PM0068269',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290221',
      rtl_batch_array: '6290221, 6339843',
      name_search: 'KNALCAZA',
      name: "Kniphofia 'Alcazar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1206,
      chnn_stock_retail: 2406,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AGKZ99AR;14GJ1C6K;N4AKWW5S;WH5LH75K;2RDCDNP9;WZDYR832;4S3T94VN;DR19VA2K;NVJCHR44;K8R39AWX;JNWA2ZD3;RWAGBNXH;67PB6EN7',
      statusContent: 'dot',
      hash: '5NK1E9EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NK1E9EC'
    },
    {
      status: 210,
      sku: 'PM0079450',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317913',
      rtl_batch_array: '6317913',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '080100C24',
      rng_range_identifier: 'H080100C2.5',
      rng_range_description: 'H80 cm 100 cm C2.5',
      sppl_stock_available: 3244,
      chnn_stock_retail: 3244,
      sppl_order_minimum: 3,
      sppl_prcp: 1.938,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '5B6YVXBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B6YVXBN'
    },
    {
      status: 210,
      sku: 'PM0067460',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253056',
      rtl_batch_array: '6253056',
      name_search: 'BRSUNSHI',
      name: "Brachyglottis 'Sunshine'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 632,
      chnn_stock_retail: 632,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJGNTNB2',
      statusContent: 'dot',
      hash: 'BPY2C7K9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPY2C7K9'
    },
    {
      status: 210,
      sku: 'PM0079453',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309522',
      rtl_batch_array: '6309522',
      name_search: 'WEERATHK',
      name: "Weigela 'Eva Rathke'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R9847CB1;RRA9W7GB',
      statusContent: 'dot',
      hash: 'AKXWZZRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKXWZZRW'
    },
    {
      status: 210,
      sku: 'PM0067464',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284542',
      rtl_batch_array: '6192502, 6284542',
      name_search: 'PEKROSE',
      name: "Pennisetum 'Karley Rose'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6086,
      chnn_stock_retail: 6528,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D19LRXJV;D8A55SKN;51DX5VWV;TX31G2TD;8EDGRZ6C;HAX7A9XG;XJ45L2TN;ZYPGA3EG;4HD962YL;P39YTXE2;NZHS679A;ND417A7H;B85TRYP5;9VYJK25V;AHCZZD8E;NS7YTJ9Z;6KGTCDDT;6Z1YN71D;BZRC2KG5;XTLDN8N3;PL6ALNTB;YTZ2TPBR;X6CKBENZ',
      statusContent: 'dot',
      hash: '6DP1B442',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DP1B442'
    },
    {
      status: 210,
      sku: 'PM0064485',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176236',
      rtl_batch_array: '6207768, 6176236',
      name_search: 'ALERYTHR',
      name: 'Alchemilla erythropoda',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5744,
      chnn_stock_retail: 7844,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7AZY9S8;LLL5J26H;WG6RL1JG;HLL2XDH1;R6BBV7DP;GGGDLH4G;51NBD2RW;E98119SA;GB3E7WA8;V8EBJ34Y',
      statusContent: 'dot',
      hash: '23YB14LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23YB14LE'
    },
    {
      status: 210,
      sku: 'PM0023839',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6267801',
      rtl_batch_array: '6349081, 6315062, 6267801, 6353156',
      name_search: 'PHTUBERO',
      name: 'Phlomis tuberosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 644,
      chnn_stock_retail: 3891,
      sppl_order_minimum: 3,
      sppl_prcp: 0.769,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7H4CWE4J;A6YWC6DR',
      statusContent: 'dot',
      hash: 'BV6PZ9LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BV6PZ9LC'
    },
    {
      status: 210,
      sku: 'PM0079451',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309520',
      rtl_batch_array: '6309520',
      name_search: 'SOSORBIF',
      name: 'Sorbaria sorbifolia',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 2.728,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '65NYEZ6R;L1ZZHYX1;4SN9NN55;ZW4R86BA;29DKHC6Y;WLYHBR5G;1ZT7VZ4D',
      statusContent: 'dot',
      hash: 'XBCJYD8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBCJYD8Y'
    },
    {
      status: 210,
      sku: 'PM0066800',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177552',
      rtl_batch_array: '6254807, 6177552, 5837481',
      name_search: 'PEWBEDDE',
      name: "Penstemon 'White Bedder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 160,
      chnn_stock_retail: 2299,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYZWWVBT;2ECBN5G2;923PZEEJ;A6DAHHZN;563PGEP9;BW2W1TAC;LHCG71B3;TAAVV9WT;LHG916ZC',
      statusContent: 'dot',
      hash: 'Y8LP98WJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8LP98WJ'
    },
    {
      status: 210,
      sku: 'PM0079455',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309524',
      rtl_batch_array: '6309524',
      name_search: 'WEMINUET',
      name: "Weigela 'Minuet'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8TL2J5LT;EHZBBASD;J3KLG283;15NTZ5YN;47VWY2KL',
      statusContent: 'dot',
      hash: 'YVWXZJJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVWXZJJ4'
    },
    {
      status: 210,
      sku: 'PM0079456',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309525',
      rtl_batch_array: '6309525',
      name_search: 'PHMBLANC',
      name: "Philadelphus 'Mont Blanc'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.708,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X14TKD9X',
      statusContent: 'dot',
      hash: '84DEAJ5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84DEAJ5V'
    },
    {
      status: 210,
      sku: 'PM0079457',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309526',
      rtl_batch_array: '6309526',
      name_search: 'ARPVIKIN',
      name: "Aronia prunifolia 'Viking'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YC78YB;8JRTXES5;CW46RE5L;XD2CED58;NX1GBSDX;S5E4T7PW;7V78YR9S;L83Y4B14',
      statusContent: 'dot',
      hash: '1XHG75D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XHG75D9'
    },
    {
      status: 210,
      sku: 'PM0048205',
      core_name: 'Plant',
      sppl_ean: '8720353090016',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5866471',
      rtl_batch_array: '6207838, 5866471',
      name_search: 'CANEPETA',
      name: 'Calamintha nepeta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1616,
      chnn_stock_retail: 2813,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y3SJ5GLV;6146GBGT;J938V29G',
      statusContent: 'dot',
      hash: 'TK5PPKG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TK5PPKG7'
    },
    {
      status: 210,
      sku: 'PM0068255',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260685',
      rtl_batch_array: '6260685, 6252563',
      name_search: 'EUPAPURP',
      name: "Euphorbia amygdaloides 'Purpurea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5608,
      chnn_stock_retail: 6947,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1XL4HTY2;11JK28B3;W1GE9D7L;EDXYWJCL;K2T3L1AG;XGWG8TBR;EVCHSTH9;6VT3LDZN;ZSA42E3R;NSGS83AC;Y73C5D2D;Z153N58L;562ACYBV;7EA9P6YA',
      statusContent: 'dot',
      hash: 'TB9BA2CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TB9BA2CJ'
    },
    {
      status: 210,
      sku: 'PM0063736',
      core_name: 'Plant',
      sppl_ean: '8720664872318',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142542',
      rtl_batch_array: '6142542',
      name_search: 'LECOTYLE',
      name: 'Lewisia cotyledon',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1343,
      chnn_stock_retail: 1343,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6AS9RKHE;1892KYES',
      statusContent: 'dot',
      hash: 'JHYPGLST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHYPGLST'
    },
    {
      status: 210,
      sku: 'PM0046498',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220920',
      rtl_batch_array: '6220920, 6037932',
      name_search: 'SEKAMTSC',
      name: 'Sedum kamtschaticum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 195,
      chnn_stock_retail: 303,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E5D3ARV3;H7A69EPZ;Z3SKYRYP;V2YBVWHL;1RY1EE2D',
      statusContent: 'dot',
      hash: 'DXL36ZT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXL36ZT5'
    },
    {
      status: 210,
      sku: 'PM0079458',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309527',
      rtl_batch_array: '6309527',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'ZWP6PYHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWP6PYHR'
    },
    {
      status: 210,
      sku: 'PM0020534',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220237',
      rtl_batch_array: '6220237, 6355374',
      name_search: 'PHPBEAUT',
      name: "Phlox (S) 'Purple Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4288,
      chnn_stock_retail: 5183,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46V5WG5Y;REDZ32WZ',
      statusContent: 'dot',
      hash: 'Z86X3SGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z86X3SGD'
    },
    {
      status: 910,
      sku: 'PM0068272',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6224217',
      rtl_batch_array: '6164379, 6224217, 6287668',
      name_search: 'BERCORDI',
      name: 'Bergenia cordifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2,
      chnn_stock_retail: 1324,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BCHCA61D;86AW2X93;R3NLXX6Z;8EZWEKKH;EYBADXT8;G6N3LKTD',
      statusContent: 'dot',
      hash: 'B1C7S7VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1C7S7VB'
    },
    {
      status: 210,
      sku: 'PM0079459',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6309528',
      rtl_batch_array: '6309528',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'A1EC3L1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1EC3L1P'
    },
    {
      status: 210,
      sku: 'PM0079460',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6318827',
      rtl_batch_array: '6318827',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: '6BB7SZ3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BB7SZ3Z'
    },
    {
      status: 210,
      sku: 'PM0079461',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6318828',
      rtl_batch_array: '6318828',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.708,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: 'T27AN79N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T27AN79N'
    },
    {
      status: 210,
      sku: 'PM0079462',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338373',
      rtl_batch_array: '6318830, 6338373',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 873,
      chnn_stock_retail: 923,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'WDC2KD58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDC2KD58'
    },
    {
      status: 210,
      sku: 'PM0079463',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6318831',
      rtl_batch_array: '6318831',
      name_search: 'PHOPERSP',
      name: "Physocarpus opulifolius 'Perspectiva'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YJYPE4X4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJYPE4X4'
    },
    {
      status: 210,
      sku: 'PM0079464',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6318832',
      rtl_batch_array: '6318832',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'NXPXSAVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXPXSAVB'
    },
    {
      status: 210,
      sku: 'PM0079465',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338498',
      rtl_batch_array: '6318833, 6338498',
      name_search: 'SPNJBRID',
      name: "Spiraea nipponica 'June Bride'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 186,
      chnn_stock_retail: 286,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZD9G8DS;Y27GCWAD',
      statusContent: 'dot',
      hash: 'VL349NEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL349NEL'
    },
    {
      status: 210,
      sku: 'PM0079466',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6318834',
      rtl_batch_array: '6318834',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'HLTXV5D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLTXV5D3'
    },
    {
      status: 210,
      sku: 'PM0079467',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6318835',
      rtl_batch_array: '6318835',
      name_search: 'WEFVICTO',
      name: "Weigela florida 'Victoria'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '77L8S29W;VD34HGKR',
      statusContent: 'dot',
      hash: 'NLC2W4L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLC2W4L8'
    },
    {
      status: 210,
      sku: 'PM0065646',
      core_name: 'Plant',
      sppl_ean: '8720664856103',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165706',
      rtl_batch_array: '6165706',
      name_search: 'CERCOCCI',
      name: "Centranthus ruber 'Coccineus'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1966,
      chnn_stock_retail: 1966,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH9S5DBH;98WBXW7B;2PTAE8W3;9Z3RCZDH;J9CA92EN;5NWRVBYN;1DPC3C3B',
      statusContent: 'dot',
      hash: 'H44JDSHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H44JDSHJ'
    },
    {
      status: 210,
      sku: 'PM0074322',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269813',
      rtl_batch_array: '6269813',
      name_search: 'ESDROSYR',
      name: "Escallonia 'Dart's Rosyred'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1097,
      chnn_stock_retail: 1097,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLWVS5P5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLWVS5P5'
    },
    {
      status: 210,
      sku: 'PM0059716',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217192',
      rtl_batch_array: '6217192',
      name_search: 'LAMACULA',
      name: 'Lamium maculatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJV9S3E2;EYYDNKXL;CX6S4EYX;LL7ZD9TR',
      statusContent: 'dot',
      hash: 'B6HVPCZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6HVPCZZ'
    },
    {
      status: 210,
      sku: 'PM0047739',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6380827',
      rtl_batch_array: '6176308, 5960148, 6380827',
      name_search: 'ACMILLEF',
      name: 'Achillea millefolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 174,
      chnn_stock_retail: 291,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1212VPG;V6GYCJYZ;ZRNH51HD;D3BKV6HX;G7TV1N5P;YRW1RKZJ;JVAJ17P9;6CDHRJ5V',
      statusContent: 'dot',
      hash: 'XW74PYJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW74PYJZ'
    },
    {
      status: 210,
      sku: 'PM0067461',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253078',
      rtl_batch_array: '6253078',
      name_search: 'CHTSUNDA',
      name: 'Choisya ternata Sundance',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 496,
      chnn_stock_retail: 496,
      sppl_prcp: 13.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V42YL5RC;JRCE449K;XYRHEPZN;HXDXG4H2;L9Z5895X;JYVD7ZN6;KKWV491Z;9XHSEVLD;59ZX6N6T;6EHZS8RS;BSE13AEH;T399A1TP;PNN3V7YY;R25ZGNYL;NT63J78C;SN359S4T;DE4A48GH;JCW58RHJ;GN1JY58L;A2NE21ED;TZZATBVK;ZK7BGDRG;JC8HHA2W;PSTA47GK',
      statusContent: 'dot',
      hash: '9893SP6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9893SP6E'
    },
    {
      status: 210,
      sku: 'PM0079468',
      core_name: 'Plant',
      sppl_ean: '8720349498390',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5712473',
      rtl_batch_array: '5712473',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 702,
      chnn_stock_retail: 702,
      sppl_prcp: 3.592,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '5S4HRCL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5S4HRCL8'
    },
    {
      status: 210,
      sku: 'PM0045258',
      core_name: 'Plant',
      sppl_ean: '8720349491377',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6053967',
      rtl_batch_array: '6053967',
      name_search: 'ACNAPELL',
      name: 'Aconitum napellus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E63H18E9;JL5YJAZZ;YP91TXRL;7A164917;KE9SV5T1',
      statusContent: 'dot',
      hash: 'JN4D2SEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN4D2SEJ'
    },
    {
      status: 210,
      sku: 'PM0074324',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269817',
      rtl_batch_array: '6269817',
      name_search: 'VIMVARIE',
      name: "Vinca major 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2629,
      chnn_stock_retail: 2629,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZBSSC1Y;GXX15NG3;2BRXA81Y;8DX5YJZH;Y6RE5CYA',
      statusContent: 'dot',
      hash: 'HYHXGJSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYHXGJSX'
    },
    {
      status: 210,
      sku: 'PM0067470',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348265',
      rtl_batch_array: '6192629, 6224221, 6348265',
      name_search: 'ACSPINOS',
      name: 'Acanthus spinosus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 589,
      chnn_stock_retail: 812,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VES592JS;JSN8VYR8;2LNGJ82C',
      statusContent: 'dot',
      hash: 'T6YH9BRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6YH9BRZ'
    },
    {
      status: 210,
      sku: 'PM0065644',
      core_name: 'Plant',
      sppl_ean: '8720664853812',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165704',
      rtl_batch_array: '6165704',
      name_search: 'BRMBBOWR',
      name: "Brunnera macrophylla 'Betty Bowring'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2863,
      chnn_stock_retail: 2863,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD7111WT;P6698YX4;BX3BSRV4;743VBYA9',
      statusContent: 'dot',
      hash: 'ZZ4ZSCL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZ4ZSCL3'
    },
    {
      status: 210,
      sku: 'PM0067465',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192510',
      rtl_batch_array: '6192510',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: '015020C1',
      rng_range_identifier: 'H015020C1',
      rng_range_description: 'H15 cm 20 cm C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      statusContent: 'dot',
      hash: '59JBTBTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59JBTBTN'
    },
    {
      status: 810,
      sku: 'PM0057512',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353501',
      rtl_batch_array: '6353501',
      name_search: 'WAGEOIDE',
      name: 'Waldsteinia geoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.391,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WE5JJN2V;YEAJD4D6;PDAHE6GH',
      statusContent: 'dot',
      hash: '27SENB2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27SENB2X'
    },
    {
      status: 210,
      sku: 'PM0046488',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353298',
      rtl_batch_array: '6301832, 6353298',
      name_search: 'SECBALL',
      name: "Sedum 'Chocolate Ball'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 357,
      chnn_stock_retail: 417,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V37SRT9R;7K5B93A8;1VR7XYW1;6RBKYR9Y;65G9K6LT;172RTY8Y;5KCBVTN5;8KRD6VWS;C4HYBBV2',
      statusContent: 'dot',
      hash: 'V5YEXTV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5YEXTV4'
    },
    {
      status: 210,
      sku: 'PM0059681',
      core_name: 'Plant',
      sppl_ean: '8720664860827',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 4,
      btch_active_retail: '6117717',
      rtl_batch_array: '6117687, 6117717, 6117719, 6117726',
      name_search: 'ECPURPUR',
      name: 'Echinacea purpurea',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7TXBJJ8K;4TZJCNLZ;2S124CT9;G12TBABS;VXE1Z3EP;A727S678;P9TWHCKA;1GE2ZB3S;7G16KJXE;18JGWAYG;CCHNWHH9;HY2A66AY;7ZNRBA3P;5CWD2TGW;WT6W1T3W;V53VY5JB;11S2XENG;XKSLB8D1;CS8WTBXD;NBL2DRRY;WW6GS14S;BG8A1C8L;JST2T4LS;PR78AA8R',
      statusContent: 'dot',
      hash: '2P5846VG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2P5846VG'
    },
    {
      status: 910,
      sku: 'PM0063605',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPOMEGR',
      name: "Achillea 'Pomegranate'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'SWWCXPBA;6L1CPREP',
      statusContent: 'dot',
      hash: '4KYVJJKA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4KYVJJKA'
    },
    {
      status: 810,
      sku: 'PM0046216',
      core_name: 'Plant',
      sppl_ean: '8720664873698',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112618',
      rtl_batch_array: '6112618, 6133341',
      name_search: 'LYCFIREC',
      name: "Lysimachia ciliata 'Firecracker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 514,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CH4SV9T;HD41TJ2H;2ZLZXBHS;24K32N8V',
      statusContent: 'dot',
      hash: 'P3WKYXJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3WKYXJ7'
    },
    {
      status: 210,
      sku: 'PM0068263',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196762',
      rtl_batch_array: '6196762',
      name_search: 'ERGSBREE',
      name: "Erigeron glaucus 'Sea Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZGV11PAH;D159SZ24;WTJECVV8;S91TDA6R;WBSVDNNG;GKHLYR61;B2NCPKPT',
      statusContent: 'dot',
      hash: '4VH69BA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VH69BA1'
    },
    {
      status: 210,
      sku: 'PM0066704',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233457',
      rtl_batch_array: '6233457',
      name_search: 'LAVROSEA',
      name: "Lavatera 'Rosea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 964,
      chnn_stock_retail: 964,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZYXB1CXC;G1LLL57H;Y6HJR89T',
      statusContent: 'dot',
      hash: 'NX97G6BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NX97G6BY'
    },
    {
      status: 210,
      sku: 'PM0059898',
      core_name: 'Plant',
      sppl_ean: '8720626333215',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364196',
      rtl_batch_array: '5364196, 6315035',
      name_search: 'ACMPAPRI',
      name: "Achillea millefolium 'Paprika'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 1073,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5VJGREE;2CAXHCNT;9EVTVGC8;1622HBS8;LTLPJ621',
      statusContent: 'dot',
      hash: '57T9TGG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57T9TGG4'
    },
    {
      status: 210,
      sku: 'PM0066804',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254686',
      rtl_batch_array: '6254686',
      name_search: 'CETOMENT',
      name: 'Cerastium tomentosum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 574,
      chnn_stock_retail: 574,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W4G58TXY;X5JNL7SS;KLWS5JEV;NVYHSTYA;275GZX5J;H3ACB535;TWWRS7RJ;CP7GR5Z6;91XTRWHB;2CNEHPNN;ZCZHZYV3',
      statusContent: 'dot',
      hash: 'ZRV6D4PP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRV6D4PP'
    },
    {
      status: 210,
      sku: 'PM0059659',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274851',
      rtl_batch_array: '6274851',
      name_search: 'LEDIOICA',
      name: 'Leptinella dioica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 528,
      chnn_stock_retail: 528,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPX6EKSK;LBW43SNJ;SDZVAJ76;KR899C4T;4RLXX3LH;K1RV4Z8H;ADG4G2RP',
      statusContent: 'dot',
      hash: 'YDXPHLE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDXPHLE1'
    },
    {
      status: 210,
      sku: 'PM0068267',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196785',
      rtl_batch_array: '6196785, 6301667',
      name_search: 'IBSFISCH',
      name: "Iberis sempervirens 'Fischbeck'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14650,
      chnn_stock_retail: 14750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GGRZ3KN5;S9XCDYN9;Y94X9BY9;RE7D9W8A;BLY342D3;67CD89ZS',
      statusContent: 'dot',
      hash: 'YEEJ12ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEEJ12ZY'
    },
    {
      status: 210,
      sku: 'PM0027892',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6196841',
      rtl_batch_array: '5866532, 6196841',
      name_search: 'VEGENTIA',
      name: 'Veronica gentianoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5367,
      chnn_stock_retail: 6048,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XGE85TEL;W6YJ8J6A;JVTRHP86;D2EBPJBC;5GP7HW51;WAEKX3C2;5LP653BA;5WGSLYX3;BDTC232N;LV5ESDPG;CLPXCZES;B9WWHTYA;BN8JYWJC;G6EJZRDR;V4V5D9Z8;TAD58A62;R7669P91;6B998AC8;7KBCN1JP;58PY35C4;PVBB11LR;BC23KSB9;2YPD17AP',
      statusContent: 'dot',
      hash: '91RN1ZNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '91RN1ZNT'
    },
    {
      status: 210,
      sku: 'PM0058242',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 5,
      btch_active_retail: '6349120',
      rtl_batch_array: '6349120, 6350540, 6302616, 6164316, 6193615',
      name_search: 'HOCCHAME',
      name: "Houttuynia cordata 'Chameleon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1008,
      chnn_stock_retail: 3565,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '539PZ7V3;WD27TGX4;LACKG14L;6NCYBA2A;WD13G1Z9;HP5N45B6;6CV8RS3J;E8DRC52R;4XJE8H66;PPEGLSG4;8LTYP7E7;XY2D6LK7;KHEBGCST;21L2762S;4RWEPGG8;BGYK65KY;72DGWPY2;85DB9HNW',
      statusContent: 'dot',
      hash: 'K23X3584',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K23X3584'
    },
    {
      status: 210,
      sku: 'PM0038557',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244264',
      rtl_batch_array: '6244264',
      name_search: 'POFKDYKE',
      name: "Potentilla fruticosa 'Katherine Dykes'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1395,
      chnn_stock_retail: 1395,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HT6ENDV6;WEVLDX8C;YGKBHWB2;S9ALGDP1;81PALNVR;Y8L388GH;7L8Z8945;83N661Y5',
      statusContent: 'dot',
      hash: 'EDBVDZA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDBVDZA6'
    },
    {
      status: 210,
      sku: 'PM0059494',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243723',
      rtl_batch_array: '6243723',
      name_search: 'WATERNAT',
      name: 'Waldsteinia ternata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4762,
      chnn_stock_retail: 4762,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GGTG67JR;JH3S8P8S;WWLKCH3X;CNZ8KPNP;WK3VV735;BTYE2CC8;PKLJ2LZB;86NNZJZJ',
      statusContent: 'dot',
      hash: 'YAY3TXTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAY3TXTG'
    },
    {
      status: 910,
      sku: 'PM0058183',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GAAWHEEL',
      name: "Gaillardia 'Amber Wheels'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'XG5YBBYL',
      statusContent: 'dot',
      hash: 'KAA2AT8V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KAA2AT8V'
    },
    {
      status: 210,
      sku: 'PM0065658',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183174',
      rtl_batch_array: '6183174',
      name_search: 'HEJSENIO',
      name: "Hemerocallis 'Joan Senior'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 371,
      chnn_stock_retail: 371,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GKCANXL4',
      statusContent: 'dot',
      hash: 'PXDV9EEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXDV9EEX'
    },
    {
      status: 210,
      sku: 'PM0027943',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317843',
      rtl_batch_array: '6317843',
      name_search: 'HEWALTRA',
      name: "Helenium 'Waltraut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 618,
      chnn_stock_retail: 618,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BK54GC5D;R4Z39XXJ;LGJ8C8H6;WLLR8Z4T;WJ9TB1TV;2PV82BTT',
      statusContent: 'dot',
      hash: 'VRCN2HVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRCN2HVT'
    },
    {
      status: 210,
      sku: 'PM0065481',
      core_name: 'Plant',
      sppl_ean: '8720664864504',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164533',
      rtl_batch_array: '6164533',
      name_search: 'GECKARMI',
      name: "Geranium cantabrigiense 'Karmina'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KRT57258;81LT3WDN;7D1LEZY6;7HXGSVVT;VGN9WECW;DJEHPDDB;TH816VLC;THTTYTV4;LPP74BZD;G3BA1HJE;Y8YKSBL8;TSL8Y4Y5;P4436YKA;JZ2J8ABD;RE6JAAS2;DX5JBD8V;JLKYLH44;2418B6PV;ZCA8SV68;HT399TP4',
      statusContent: 'dot',
      hash: 'ZW3R9YBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW3R9YBN'
    },
    {
      status: 210,
      sku: 'PM0074323',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269815',
      rtl_batch_array: '6269815',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_prcp: 2.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'B1S1VL9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1S1VL9B'
    },
    {
      status: 210,
      sku: 'PM0074328',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269827',
      rtl_batch_array: '6311910, 6269827',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 515,
      chnn_stock_retail: 660,
      sppl_prcp: 6.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '59AWXS83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59AWXS83'
    },
    {
      status: 210,
      sku: 'PM0082090',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346162',
      rtl_batch_array: '6346162',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 4.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V2GHV23Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2GHV23Z'
    },
    {
      status: 210,
      sku: 'PM0068395',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300186',
      rtl_batch_array: '6300186, 6355670',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2236,
      chnn_stock_retail: 6233,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: '2PC677PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PC677PA'
    },
    {
      status: 910,
      sku: 'PM0082091',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      statusContent: 'dot',
      hash: 'J9HLEA6B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J9HLEA6B'
    },
    {
      status: 810,
      sku: 'PM0068422',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6202315',
      rtl_batch_array: '6202315',
      name_search: 'LEVULGAR',
      name: 'Leucanthemum vulgare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_order_minimum: 3,
      sppl_prcp: 0.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '984CC8C4;5N15VJ61;W2RPPYGS;XNK6SEHB',
      statusContent: 'dot',
      hash: '8VX2TAXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VX2TAXS'
    },
    {
      status: 910,
      sku: 'PM0082092',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      statusContent: 'dot',
      hash: 'SRAKA3S7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SRAKA3S7'
    },
    {
      status: 810,
      sku: 'PM0059904',
      core_name: 'Plant',
      sppl_ean: '8720664852792',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6129713',
      rtl_batch_array: '6129713, 6302384',
      name_search: 'ASLBLAUS',
      name: "Aster laevis 'Blauschleier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 172,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HZD413D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZD413D5'
    },
    {
      status: 210,
      sku: 'PM0067163',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177559',
      rtl_batch_array: '6177559, 6252759, 6272871',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 205,
      chnn_stock_retail: 2070,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      statusContent: 'dot',
      hash: 'DXDWDYYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXDWDYYX'
    },
    {
      status: 910,
      sku: 'PM0068439',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CIACERIN',
      name: 'Cimicifuga acerina',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '77H8ZNBW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '77H8ZNBW'
    },
    {
      status: 210,
      sku: 'PM0068413',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202295',
      rtl_batch_array: '6202295, 6301589',
      name_search: 'GECLFLAT',
      name: "Geranium cinereum 'Laurence Flatman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 426,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K34DNXYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K34DNXYS'
    },
    {
      status: 910,
      sku: 'PM0065144',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GLHVARIE',
      name: "Glechoma hederacea 'Variegata'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'S92NDWD4;Y5X4PGNE;BSYV126W;GSST7H5L;AVPCDS8Y;58LH82ZY;LKTYRKY8;A1Z435BR;73H5CWKX;4TSV3EEX;TL877NR7;S1B8LT1R;VWLVG142',
      statusContent: 'dot',
      hash: 'PVB6RGL5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PVB6RGL5'
    },
    {
      status: 910,
      sku: 'PM0082093',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      statusContent: 'dot',
      hash: '6167AZZS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6167AZZS'
    },
    {
      status: 910,
      sku: 'PM0082094',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      statusContent: 'dot',
      hash: '675RP74J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '675RP74J'
    },
    {
      status: 910,
      sku: 'PM0082095',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: 'JHNKLR17',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JHNKLR17'
    },
    {
      status: 910,
      sku: 'PM0082096',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: 'X19R898X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X19R898X'
    },
    {
      status: 210,
      sku: 'PM0068429',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202336',
      rtl_batch_array: '6202336, 6353477',
      name_search: 'SUPNANA',
      name: "Succisa pratensis 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NV4B6166',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV4B6166'
    },
    {
      status: 210,
      sku: 'PM0068406',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386191',
      rtl_batch_array: '6274866, 6386191',
      name_search: 'SAOPTANN',
      name: "Sanguisorba officinalis 'Pink Tanna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2286,
      chnn_stock_retail: 2957,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7PLL8Y6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7PLL8Y6'
    },
    {
      status: 210,
      sku: 'PM0068423',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349133',
      rtl_batch_array: '6349133, 6229789, 6202318, 6208026',
      name_search: 'LIPRZEWA',
      name: 'Ligularia przewalskii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1752,
      chnn_stock_retail: 5392,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2JT1V775;7N62ZX59;WWS4KJ7H',
      statusContent: 'dot',
      hash: 'J38N3YND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J38N3YND'
    },
    {
      status: 210,
      sku: 'PM0068419',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350457',
      rtl_batch_array: '6202309, 6301650, 6350457',
      name_search: 'HEVCHANT',
      name: "Heuchera villosa 'Chantilly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1056,
      chnn_stock_retail: 3855,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RD5Y1SZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD5Y1SZH'
    },
    {
      status: 210,
      sku: 'PM0067487',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192679',
      rtl_batch_array: '6192679',
      name_search: 'BECGWTWI',
      name: "Iris domestica 'Gone With The Wind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N18DCV1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N18DCV1V'
    },
    {
      status: 210,
      sku: 'PM0068428',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202333',
      rtl_batch_array: '6370388, 6202333',
      name_search: 'PRWANDA',
      name: "Primula (J) 'Wanda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 233,
      chnn_stock_retail: 395,
      sppl_order_minimum: 3,
      sppl_prcp: 1.046,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TSNCTYEN;GKCLJE7J;TVDZKTNG;TVRVE5X6',
      statusContent: 'dot',
      hash: 'ZL3XDZZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZL3XDZZA'
    },
    {
      status: 210,
      sku: 'PM0068384',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200432',
      rtl_batch_array: '6200432',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 337,
      chnn_stock_retail: 337,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: 'D1VTYZYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1VTYZYX'
    },
    {
      status: 810,
      sku: 'PM0079470',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5880396',
      rtl_batch_array: '5880396',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 3.062,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      imageBatch: '1N5X4449',
      statusContent: 'dot',
      hash: 'VDVVN7DC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDVVN7DC'
    },
    {
      status: 910,
      sku: 'PM0079471',
      core_name: 'Plant',
      sppl_ean: '8720626330009',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5880636',
      rtl_batch_array: '5880636',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '175200C30',
      rng_range_identifier: 'H175200C30',
      rng_range_description: 'H175 cm 200 cm C30',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 20.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: 'TTA1S1VR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTA1S1VR'
    },
    {
      status: 210,
      sku: 'PM0079472',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 3,
      btch_active_retail: '6356686',
      rtl_batch_array: '6174625, 6300796, 6356686',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 482,
      chnn_stock_retail: 1007,
      sppl_prcp: 2.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'XD6CL7RY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD6CL7RY'
    },
    {
      status: 210,
      sku: 'PM0067481',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6077534',
      rtl_batch_array: '6077534',
      name_search: 'ASCATTLE',
      name: "Astilbe (A) 'Cattleya'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '77ZNYN61;YB43ETDL',
      statusContent: 'dot',
      hash: 'NBYKCS1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBYKCS1D'
    },
    {
      status: 210,
      sku: 'PM0067483',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207788',
      rtl_batch_array: '6207788, 6333381',
      name_search: 'ASGLUT',
      name: "Astilbe (A) 'Glut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 790,
      chnn_stock_retail: 859,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GW21KJ9E;VS7X6PTN;AP8PJBSR',
      statusContent: 'dot',
      hash: '729KSD2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '729KSD2A'
    },
    {
      status: 810,
      sku: 'PM0079473',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239647',
      rtl_batch_array: '6239647',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.809,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: '589PZJT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '589PZJT7'
    },
    {
      status: 210,
      sku: 'PM0068418',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6231145',
      rtl_batch_array: '6231145, 6350338, 6254434, 6344095',
      name_search: 'HEORIENT',
      name: 'Helleborus orientalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 9829,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NC1TLY2;7HSSH1HE;ACBH9CGX;91X5KHCH;PEJ2VTTZ;W1LTW742;4K99Y91E;SDGW7LLA;AHGP9HET;JD8WZK7Y;CSDBLDSC;L8J9GYER;PLRZ76GS;HGDYR41W;JELJR4HA;81WBW2HB;SASC86EP;H6K98P4P;D1W6VBS3;284KPGP7;GLC3E3ZN;X1KWTJZS;4AGD9WG2',
      statusContent: 'dot',
      hash: 'VW1V7JA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VW1V7JA1'
    },
    {
      status: 210,
      sku: 'PM0082097',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346169',
      rtl_batch_array: '6346169',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: '521R6EJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '521R6EJ7'
    },
    {
      status: 210,
      sku: 'PM0082098',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346170',
      rtl_batch_array: '6346170',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: '9CVBB1G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CVBB1G9'
    },
    {
      status: 210,
      sku: 'PM0067137',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300423',
      rtl_batch_array: '6300423, 6301408',
      name_search: 'AQATRATA',
      name: 'Aquilegia atrata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 392,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AYSJHW5J;64ESN7XN',
      statusContent: 'dot',
      hash: 'DPKSEGH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPKSEGH2'
    },
    {
      status: 910,
      sku: 'PM0082099',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: '8NHT5YSA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8NHT5YSA'
    },
    {
      status: 210,
      sku: 'PM0082100',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346172',
      rtl_batch_array: '6346172',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 12.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: '94RJZ1T4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94RJZ1T4'
    },
    {
      status: 910,
      sku: 'PM0082101',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILMHECKE',
      name: 'Ilex meserveae Heckenfee',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      imageCore: 'HYW23CV8;JT5D22G6;J8Y57ZAN;KXK74JTE;DV3Z2NV7;NJZ7S1XD',
      statusContent: 'dot',
      hash: '5K2PGYPC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5K2PGYPC'
    },
    {
      status: 210,
      sku: 'PM0082102',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346174',
      rtl_batch_array: '6346174',
      name_search: 'PIOMORIK',
      name: 'Picea omorika',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.965,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTZ1YHLR;6E88GZGS;VHEP6CJT;C4YRA6N2;XE7N32SW',
      statusContent: 'dot',
      hash: '2HBEERST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HBEERST'
    },
    {
      status: 210,
      sku: 'PM0082103',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346175',
      rtl_batch_array: '6346175',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'V55DTVRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V55DTVRN'
    },
    {
      status: 210,
      sku: 'PM0082104',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346176',
      rtl_batch_array: '6346176',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.253,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: '215DS9EG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '215DS9EG'
    },
    {
      status: 210,
      sku: 'PM0082105',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346177',
      rtl_batch_array: '6346177',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 8.059,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: '25CGBY49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25CGBY49'
    },
    {
      status: 210,
      sku: 'PM0082106',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385618',
      rtl_batch_array: '6346178, 6385618',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 2200,
      chnn_stock_retail: 2450,
      sppl_prcp: 3.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'N694AB5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N694AB5A'
    },
    {
      status: 210,
      sku: 'PM0059782',
      core_name: 'Plant',
      sppl_ean: '8720664868267',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6128567',
      rtl_batch_array: '6128567',
      name_search: 'HOPATRIO',
      name: "Hosta 'Patriot'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2109,
      chnn_stock_retail: 2109,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LC8R96LY;2JEH4V3Z;TH34A1H6;RTN2DN2K;87X8C2TZ;V2EW727A;R53BPL4A;SPP13VWH;Y6X6JR79;BTEPCS7A;A9Z9JPEZ;1KLDXJG5;R7R3BT28;ETLSSJ7V;CXWCTYBJ;A5D4VH3T',
      statusContent: 'dot',
      hash: 'RR8AZT95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR8AZT95'
    },
    {
      status: 210,
      sku: 'PM0082107',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346179',
      rtl_batch_array: '6346179',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 4.902,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'ZX2ZKT3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX2ZKT3X'
    },
    {
      status: 210,
      sku: 'PM0068405',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6309014',
      rtl_batch_array: '6202269, 6207770, 6274823, 6309014',
      name_search: 'ALVULGAR',
      name: 'Alchemilla vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1056,
      chnn_stock_retail: 3399,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R2CESTGL;5K5EPA41;PCPW46XP',
      statusContent: 'dot',
      hash: 'X32BWB7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X32BWB7S'
    },
    {
      status: 210,
      sku: 'PM0082133',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385616',
      rtl_batch_array: '6385616',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 4.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '641KSWBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '641KSWBS'
    },
    {
      status: 210,
      sku: 'PM0082108',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346180',
      rtl_batch_array: '6346180',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '060070KL',
      rng_range_identifier: 'H060070RB',
      rng_range_description: 'H60 cm 70 cm Root ball',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'HH7XNXCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HH7XNXCS'
    },
    {
      status: 210,
      sku: 'PM0082109',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385601',
      rtl_batch_array: '6346181, 6349305, 6385601',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 2050,
      chnn_stock_retail: 4600,
      sppl_prcp: 4.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'HZ5N5XYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZ5N5XYW'
    },
    {
      status: 210,
      sku: 'PM0082110',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346182',
      rtl_batch_array: '6346182',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.018,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '1KJ838NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KJ838NS'
    },
    {
      status: 210,
      sku: 'PM0082111',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 3,
      btch_active_retail: '6346183',
      rtl_batch_array: '6346183, 6349307, 6358840',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1650,
      sppl_prcp: 11.952,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'JHZHBET9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHZHBET9'
    },
    {
      status: 210,
      sku: 'PM0082112',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346184',
      rtl_batch_array: '6346184, 6349308',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 550,
      sppl_prcp: 19.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'EYPEVC3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYPEVC3L'
    },
    {
      status: 810,
      sku: 'PM0082113',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349309',
      rtl_batch_array: '6346185, 6349309',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 550,
      sppl_prcp: 26.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '5PB3L88R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PB3L88R'
    },
    {
      status: 210,
      sku: 'PM0082114',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346186',
      rtl_batch_array: '6346186, 6349445',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1050,
      sppl_prcp: 7.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'V8H3HY36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8H3HY36'
    },
    {
      status: 910,
      sku: 'PM0082115',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'BP4SDVGV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BP4SDVGV'
    },
    {
      status: 910,
      sku: 'PM0082116',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'X7L1A9GZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X7L1A9GZ'
    },
    {
      status: 910,
      sku: 'PM0082117',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: '7BLXAH19',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7BLXAH19'
    },
    {
      status: 910,
      sku: 'PM0082118',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'GDL2WSEP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GDL2WSEP'
    },
    {
      status: 210,
      sku: 'PM0082119',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346191',
      rtl_batch_array: '6346191, 6354766',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: '2E5ZKLP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E5ZKLP6'
    },
    {
      status: 210,
      sku: 'PM0082120',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346192',
      rtl_batch_array: '6346192, 6354767',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2000,
      sppl_prcp: 8.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'CT8SVDWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT8SVDWB'
    },
    {
      status: 210,
      sku: 'PM0082121',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346193',
      rtl_batch_array: '6346193',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'YD4VGTSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD4VGTSV'
    },
    {
      status: 210,
      sku: 'PM0082122',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346194',
      rtl_batch_array: '6346194',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 13.194,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'VD655N7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD655N7K'
    },
    {
      status: 210,
      sku: 'PM0068386',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200434',
      rtl_batch_array: '6200434',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: '6XHCNNBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XHCNNBB'
    },
    {
      status: 210,
      sku: 'PM0082123',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346195',
      rtl_batch_array: '6346195',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 19.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'H243HL7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H243HL7Y'
    },
    {
      status: 210,
      sku: 'PM0082124',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346196',
      rtl_batch_array: '6346196',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 23.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'BTN84413',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTN84413'
    },
    {
      status: 210,
      sku: 'PM0082125',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346197',
      rtl_batch_array: '6346197',
      name_search: 'PRULGENO',
      name: 'Prunus laurocerasus Genolia',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 206,
      chnn_stock_retail: 206,
      sppl_prcp: 33.817,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9Y92TG8D;565X7566;72CRWELR;ZVN4JZ96;64RR65K7;GJAVS9ER;BDKD994D;522953ZB;6CZC4NA4;ADAC3S4K;L3148WXC;T8J6LGVA;DBPSN5AB',
      statusContent: 'dot',
      hash: 'D7BZG947',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7BZG947'
    },
    {
      status: 210,
      sku: 'PM0082126',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385611',
      rtl_batch_array: '6346198, 6385611',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 625,
      chnn_stock_retail: 1125,
      sppl_prcp: 4.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'LWYDTA24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWYDTA24'
    },
    {
      status: 210,
      sku: 'PM0082127',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346199',
      rtl_batch_array: '6346199',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'BN8Y9YKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BN8Y9YKY'
    },
    {
      status: 210,
      sku: 'PM0082128',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346200',
      rtl_batch_array: '6346200',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.018,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'DVW66W9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVW66W9A'
    },
    {
      status: 210,
      sku: 'PM0082129',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346201',
      rtl_batch_array: '6346201',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.454,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: '84Z6EVVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84Z6EVVW'
    },
    {
      status: 210,
      sku: 'PM0082130',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346202',
      rtl_batch_array: '6346202',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 20.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'H6N2898J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6N2898J'
    },
    {
      status: 210,
      sku: 'PM0082131',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346203',
      rtl_batch_array: '6346203',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '200220KL',
      rng_range_identifier: 'H200220RB',
      rng_range_description: 'H200 cm 220 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 41.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: '7TSJDHPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TSJDHPK'
    },
    {
      status: 210,
      sku: 'PM0082132',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385615',
      rtl_batch_array: '6346204, 6385615',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 2100,
      chnn_stock_retail: 3100,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '8D21WPAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8D21WPAS'
    },
    {
      status: 910,
      sku: 'PM0082134',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '1HT37HBZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1HT37HBZ'
    },
    {
      status: 210,
      sku: 'PM0082135',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346207',
      rtl_batch_array: '6346207',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 9.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'PZWS6P2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZWS6P2S'
    },
    {
      status: 210,
      sku: 'PM0082136',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346208',
      rtl_batch_array: '6346208',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 13.561,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'K9TD8ZAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9TD8ZAB'
    },
    {
      status: 210,
      sku: 'PM0082137',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346209',
      rtl_batch_array: '6346209',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 29.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'WADPX368',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WADPX368'
    },
    {
      status: 210,
      sku: 'PM0082138',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346211',
      rtl_batch_array: '6346211',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 6.253,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '7HEHJXR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HEHJXR5'
    },
    {
      status: 210,
      sku: 'PM0082139',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346212',
      rtl_batch_array: '6346212, 6358843',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5100,
      sppl_prcp: 10.095,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '8GYRXK72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GYRXK72'
    },
    {
      status: 210,
      sku: 'PM0082140',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346214',
      rtl_batch_array: '6346214',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 3500,
      chnn_stock_retail: 3500,
      sppl_prcp: 19.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'Z77TXJRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z77TXJRK'
    },
    {
      status: 210,
      sku: 'PM0082141',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346215',
      rtl_batch_array: '6346215',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '180200KL',
      rng_range_identifier: 'H180200RB',
      rng_range_description: 'H180 cm 200 cm Root ball',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 28.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'HR8YJ11X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR8YJ11X'
    },
    {
      status: 210,
      sku: 'PM0082142',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346216',
      rtl_batch_array: '6346216',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '200220KL',
      rng_range_identifier: 'H200220RB',
      rng_range_description: 'H200 cm 220 cm Root ball',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 33.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'CA87W16E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA87W16E'
    },
    {
      status: 210,
      sku: 'PM0026158',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301424',
      rtl_batch_array: '6207784, 6301424, 6333187',
      name_search: 'ASARGOET',
      name: "Aster amellus 'Rudolf Goethe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2447,
      chnn_stock_retail: 11526,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7B9R11B',
      statusContent: 'dot',
      hash: 'VE4NEJR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE4NEJR3'
    },
    {
      status: 210,
      sku: 'PM0074329',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315213',
      rtl_batch_array: '6315213, 6269828',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8000,
      chnn_stock_retail: 11900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ALL6DGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ALL6DGA'
    },
    {
      status: 210,
      sku: 'PM0014369',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321883',
      rtl_batch_array: '5363877, 6321883',
      name_search: 'ARMALBA',
      name: "Armeria maritima 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 158,
      chnn_stock_retail: 1304,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72JY2TSY;D6S5XHHJ;2V3GXWET;9WSWZHVY;5NZZCXNA;5Y2VRYKZ;7TVNDA14;1LSJHST2;SXSAZ2EL;2ARCSCZE;47YAXKYP;26RBD57Z;W6133S5Y',
      statusContent: 'dot',
      hash: 'CWWYL6TD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWWYL6TD'
    },
    {
      status: 210,
      sku: 'PM0082143',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346217',
      rtl_batch_array: '6346217',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 8.824,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'SBVXYKLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBVXYKLY'
    },
    {
      status: 210,
      sku: 'PM0082144',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 3,
      btch_active_retail: '6346717',
      rtl_batch_array: '6346220, 6346717, 6354764',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 5500,
      sppl_prcp: 9.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '27TC58PP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27TC58PP'
    },
    {
      status: 210,
      sku: 'PM0082145',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346755',
      rtl_batch_array: '6346221, 6346755',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 5000,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'YLP7N954',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLP7N954'
    },
    {
      status: 210,
      sku: 'PM0082146',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346222',
      rtl_batch_array: '6346222',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 19.458,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'AREKS4HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AREKS4HA'
    },
    {
      status: 210,
      sku: 'PM0082147',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346223',
      rtl_batch_array: '6346223, 6354765',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '160180KL',
      rng_range_identifier: 'H160180RB',
      rng_range_description: 'H160 cm 180 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 1000,
      sppl_prcp: 24.781,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'V8R3GLDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8R3GLDW'
    },
    {
      status: 210,
      sku: 'PM0066891',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197133',
      rtl_batch_array: '6242945, 6197133',
      name_search: 'GEMAGNIF',
      name: 'Geranium magnificum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 4789,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BSJ3SYY;6R6GBE2R',
      statusContent: 'dot',
      hash: 'RG2KL365',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG2KL365'
    },
    {
      status: 210,
      sku: 'PM0068426',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6202330',
      rtl_batch_array: '6202330',
      name_search: 'PHTEMISK',
      name: "Phlox (S) 'Temiskaming'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HGE6NP6',
      statusContent: 'dot',
      hash: '7DYP3Y1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DYP3Y1R'
    },
    {
      status: 810,
      sku: 'PM0068371',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198966',
      rtl_batch_array: '6198966',
      name_search: 'TOGROW',
      name: 'TOPBUXUS Grow',
      sppl_size_code: 'KLEMMER05K1',
      rng_range_identifier: 'BUC0.5KG',
      rng_range_description: 'Bucket 0.5 Kilogram',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 2.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PESTJB3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PESTJB3E'
    },
    {
      status: 810,
      sku: 'PM0068372',
      core_name: 'Plant',
      btch_manufacturer: 2285,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198969',
      rtl_batch_array: '6198969',
      name_search: 'TOHMIX',
      name: 'TOPBUXUS Health Mix',
      sppl_size_code: 'KLEMMER10T1',
      rng_range_identifier: 'BUC10TAB',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 5.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RPC17P69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPC17P69'
    },
    {
      status: 210,
      sku: 'PM0068391',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6200439',
      rtl_batch_array: '6200439',
      name_search: 'CEJPHELP',
      name: "Ceanothus 'Julia Phelps'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2RWBRHK4',
      statusContent: 'dot',
      hash: '684LWCXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '684LWCXE'
    },
    {
      status: 210,
      sku: 'PM0068414',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291697',
      rtl_batch_array: '6350320, 6291697',
      name_search: 'HELQUEEN',
      name: "Helianthus 'Lemon Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1926,
      chnn_stock_retail: 2482,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E524LSA5;RRV874YE',
      statusContent: 'dot',
      hash: '5G8YN72H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G8YN72H'
    },
    {
      status: 210,
      sku: 'PM0005492',
      core_name: 'Plant',
      sppl_ean: '8720626335493',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054444',
      rtl_batch_array: '6229868, 6054444',
      name_search: 'ASDIAMAN',
      name: "Astilbe (A) 'Diamant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1175,
      chnn_stock_retail: 1333,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CGKXPEC3;2J98C3WP;5TZCN7YG;SP73ZLED;DRDZHKL4;JLZSSTVS;48L7CLH9;V2ES9CX4;RX7KZ652',
      statusContent: 'dot',
      hash: 'DXE6V5PS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXE6V5PS'
    },
    {
      status: 210,
      sku: 'PM0068374',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253733',
      rtl_batch_array: '6253733',
      name_search: 'LEFPRAIN',
      name: "Leycesteria formosa 'Purple Rain'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SG1D8L6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG1D8L6V'
    },
    {
      status: 210,
      sku: 'PM0068427',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383371',
      rtl_batch_array: '6383371, 6348444',
      name_search: 'POMULTIF',
      name: 'Polygonatum multiflorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10452,
      chnn_stock_retail: 12794,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ES59LLRS;PDNKDGRL;HCHEG11J;24GZYHRJ;ZX8ALGX7;ET8ACP5X',
      statusContent: 'dot',
      hash: 'JPZ1HA1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPZ1HA1E'
    },
    {
      status: 210,
      sku: 'PM0065399',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6317846',
      rtl_batch_array: '6164321, 6329979, 6317846, 6383358',
      name_search: 'KAIBSTAR',
      name: "Kalimeris incisa 'Blue Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2872,
      chnn_stock_retail: 14021,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1ZBZWXJL;YYWNHXA1',
      statusContent: 'dot',
      hash: 'PZBGZC1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZBGZC1Y'
    },
    {
      status: 210,
      sku: 'PM0012599',
      core_name: 'Plant',
      sppl_ean: '8720664643192',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364541',
      rtl_batch_array: '5364541, 6302794, 6353390',
      name_search: 'THLPINKI',
      name: "Thalictrum 'Little Pinkie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 211,
      chnn_stock_retail: 446,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEVERTNB',
      statusContent: 'dot',
      hash: 'G83HXPHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G83HXPHV'
    },
    {
      status: 210,
      sku: 'PM0033865',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260705',
      rtl_batch_array: '6260705, 6252726, 6272792',
      name_search: 'PEASUPER',
      name: "Persicaria affinis 'Superba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 866,
      chnn_stock_retail: 2377,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4RYX2WYB;KL9S5L21',
      statusContent: 'dot',
      hash: 'WL77WLWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL77WLWW'
    },
    {
      status: 210,
      sku: 'PM0065392',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302605',
      rtl_batch_array: '6350493, 6302605',
      name_search: 'HOBANGEL',
      name: "Hosta 'Blue Angel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 513,
      chnn_stock_retail: 3013,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '751HY16E;XZ99S65A;2EKVB18Y;9SEGBN6V;PBBP8A6C;675KXZJP',
      statusContent: 'dot',
      hash: 'A1KX5B5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1KX5B5T'
    },
    {
      status: 210,
      sku: 'PM0046798',
      core_name: 'Plant',
      sppl_ean: '8720664681118',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6014533',
      rtl_batch_array: '6302378, 6014533',
      name_search: 'ASEERLKO',
      name: "Aster ericoides 'Erlk\u00f6nig'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 588,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LH38PRAE',
      statusContent: 'dot',
      hash: 'NDXAE48K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDXAE48K'
    },
    {
      status: 210,
      sku: 'PM0067482',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6207787',
      rtl_batch_array: '6229869, 6207787',
      name_search: 'ASERIKA',
      name: "Astilbe (A) 'Erika'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 424,
      chnn_stock_retail: 727,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S793C898;NPYDYK5N;6SDCPAC8;XSAPTGZA;YLEA7E7T;WDBEGLE1;Z1KZZHNA;87CBS8Z2',
      statusContent: 'dot',
      hash: 'H5L5NR9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5L5NR9C'
    },
    {
      status: 210,
      sku: 'PM0017877',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348363',
      rtl_batch_array: '6112594, 6254403, 6348363',
      name_search: 'LUGRED',
      name: "Lupinus 'Gallery Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 178,
      chnn_stock_retail: 824,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YALKRHB8;N26N3V6G;ZHW2TRLN;RBS1XEL5',
      statusContent: 'dot',
      hash: '4A2XHJZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4A2XHJZY'
    },
    {
      status: 210,
      sku: 'PM0015355',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 7,
      btch_active_retail: '6209181',
      rtl_batch_array:
        '6099805, 5837435, 6209181, 6271402, 6373682, 6272857, 6254767',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6100,
      chnn_stock_retail: 23513,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: 'W6SYS2PH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6SYS2PH'
    },
    {
      status: 210,
      sku: 'PM0068396',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254265',
      rtl_batch_array: '6254265',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4461,
      chnn_stock_retail: 4461,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: 'D7NLPL81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7NLPL81'
    },
    {
      status: 210,
      sku: 'PM0068403',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6202261',
      rtl_batch_array: '6202261, 6317825, 6289983',
      name_search: 'ANHUPEHE',
      name: 'Anemone hupehensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 268,
      chnn_stock_retail: 1821,
      sppl_order_minimum: 3,
      sppl_prcp: 0.769,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NYZ6RG6H;H9B88889;8YA23WLR;8TCXZTGV;RTKCJDJ8;HHPCDBES;R9RDNAXG;1V6E81VC;C76SX2K2',
      statusContent: 'dot',
      hash: 'SPX4TE8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPX4TE8D'
    },
    {
      status: 210,
      sku: 'PM0068404',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6202263',
      rtl_batch_array: '6369220, 6202263',
      name_search: 'AQSQUEEN',
      name: "Aquilegia 'Silver Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 676,
      chnn_stock_retail: 1095,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TCWDCDNJ',
      statusContent: 'dot',
      hash: 'CERANP39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CERANP39'
    },
    {
      status: 210,
      sku: 'PM0010188',
      core_name: 'Plant',
      sppl_ean: '8720353022390',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5607027',
      rtl_batch_array: '6133382, 6014170, 5607027',
      name_search: 'MOCCARMA',
      name: "Molinia caerulea 'Carmarthen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 700,
      chnn_stock_retail: 1241,
      sppl_order_minimum: 3,
      sppl_prcp: 0.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6JREJCWR;5EXP7DX9;NXJXTGHC;T7WNN3W6;DS46NK74;4Y283W6W;784PJD18',
      statusContent: 'dot',
      hash: 'DRDLC9VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRDLC9VT'
    },
    {
      status: 210,
      sku: 'PM0065178',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348964',
      rtl_batch_array: '6295692, 6383356, 6348964',
      name_search: 'IBSPICE',
      name: "Iberis sempervirens 'Pink Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 622,
      chnn_stock_retail: 1828,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y8BTTS28;HTX41EN2;19PJTACX;NELBPBLA;7ZNCCN6N;S589R2G9',
      statusContent: 'dot',
      hash: 'HWXY1AZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWXY1AZX'
    },
    {
      status: 210,
      sku: 'PM0071391',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229115',
      rtl_batch_array: '6229115',
      name_search: 'HEBHECKL',
      name: "Helianthemum 'Ben Heckla'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 905,
      chnn_stock_retail: 905,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2XER5SN;J4X3DLZE;J5ZB1DKC',
      statusContent: 'dot',
      hash: '7JDRX1LW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JDRX1LW'
    },
    {
      status: 210,
      sku: 'PM0068387',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284478',
      rtl_batch_array: '6200435, 6284478',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2284,
      chnn_stock_retail: 3295,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'WVCVB33Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVCVB33Z'
    },
    {
      status: 210,
      sku: 'PM0071392',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229116',
      rtl_batch_array: '6229116',
      name_search: 'HEBRONZE',
      name: "Helianthemum 'Bronzeteppich'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 833,
      chnn_stock_retail: 833,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2R9DTTS;5K7AVPLE;D3DLRPY9;C72RS1EA;ZWYPTETC;R95N2C7D;8ETWEXR2',
      statusContent: 'dot',
      hash: '999CD7DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '999CD7DB'
    },
    {
      status: 210,
      sku: 'PM0071393',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229117',
      rtl_batch_array: '6229117',
      name_search: 'HECQUEEN',
      name: "Helianthemum 'Cerise Queen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EGPA8AT;4YG87RRG;87PAC67R;8EHCR25W;A92XAGAB;CYDL58PJ;K18PV6X9',
      statusContent: 'dot',
      hash: 'L9SWY4E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9SWY4E7'
    },
    {
      status: 210,
      sku: 'PM0071394',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229119',
      rtl_batch_array: '6229119',
      name_search: 'HIAURANT',
      name: 'Hieracium aurantiacum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1491,
      chnn_stock_retail: 1491,
      sppl_order_minimum: 3,
      sppl_prcp: 1.774,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R64T2KCR;G8CDVBZT;J1A1RZ68;9KDG1WV3;CXXNAR88;L8Y3YXDT;PT1598YW;P6W27GR3',
      statusContent: 'dot',
      hash: 'A7NHE6JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7NHE6JY'
    },
    {
      status: 210,
      sku: 'PM0074332',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269831',
      rtl_batch_array: '6269831',
      name_search: 'HYIELSTE',
      name: "Hypericum inodorum 'Elstead'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 769,
      chnn_stock_retail: 769,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L75ZNA42;ATCXXEGR;6P4CP7B3;9BLGGCVP;V7G4T67H;44GPR7C3;72RNHJHL;9A4334CA',
      statusContent: 'dot',
      hash: 'BL1Z1ZAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BL1Z1ZAK'
    },
    {
      status: 210,
      sku: 'PM0070262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386202',
      rtl_batch_array: '6301876, 6386202',
      name_search: 'THDHDOUB',
      name: "Thalictrum delavayi 'Hewitt's Double'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 597,
      chnn_stock_retail: 646,
      sppl_order_minimum: 3,
      sppl_prcp: 1.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZ68X9GS;6BSXYJDC;L4ZA65B6;SCJ95TG7;XW2H3T14',
      statusContent: 'dot',
      hash: 'X3JE5EPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3JE5EPP'
    },
    {
      status: 210,
      sku: 'PM0082148',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346765',
      rtl_batch_array: '6346224, 6346765',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '180200KL',
      rng_range_identifier: 'H180200RB',
      rng_range_description: 'H180 cm 200 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 1500,
      sppl_prcp: 28.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'SA571B4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SA571B4G'
    },
    {
      status: 210,
      sku: 'PM0079485',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319470',
      rtl_batch_array: '6319470',
      name_search: 'CALPVARI',
      name: "Campanula lactiflora 'Prichard's Variety'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5926,
      chnn_stock_retail: 5926,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHN19HHR;P8GLVY47',
      statusContent: 'dot',
      hash: '63SPG2A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63SPG2A2'
    },
    {
      status: 210,
      sku: 'PM0070239',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220851',
      rtl_batch_array: '6220851, 6348371',
      name_search: 'MESMOROC',
      name: "Mentha spicata 'Moroccan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 124,
      chnn_stock_retail: 635,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NC8E5WVV;H64D79Y7;4GW6TBZT;5L1VKAKL;S4GSL6JG;RT6PRY3D;B2CDCBAH;6TPLATES;YWE4C1A3;EBGWB6ZZ;146EBXSZ;8YD63LDS;NTDZ4KZS;E3YXS9E9;4G9PHYL6;WSZTGT6B;W7CRZS6L;ZPG93PNS;ACGHXTHS;46339T8X;TJ45RA21;J4N8TPN1;P2KSPTVE;RBHG9XAL;CTL43R2K;PWPLB61B;YYBXJT2E;Z7DS7VCH',
      statusContent: 'dot',
      hash: 'H3HR7AKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3HR7AKZ'
    },
    {
      status: 210,
      sku: 'PM0070246',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355372',
      rtl_batch_array: '6220865, 6355372',
      name_search: 'MYODORAT',
      name: 'Myrrhis odorata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 130,
      chnn_stock_retail: 466,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EPJKD4AT',
      statusContent: 'dot',
      hash: '4Y14KGJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y14KGJ4'
    },
    {
      status: 210,
      sku: 'PM0067490',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192693',
      rtl_batch_array: '6192693',
      name_search: 'CAOEVERS',
      name: "Carex oshimensis 'Eversheen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 283,
      chnn_stock_retail: 283,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B8XX5C8;4NXXLR6X;22A7GN8T;TEEY7ESK;BCP4A5HD;HAVHL1CG;YT31LELT;VGZHYRRT;WYC3T5EN;WSLP6VCG',
      statusContent: 'dot',
      hash: 'PST9R26E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PST9R26E'
    },
    {
      status: 210,
      sku: 'PM0070247',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242118',
      rtl_batch_array: '6242118, 6220868, 6333285, 6348393',
      name_search: 'ONSENSIB',
      name: 'Onoclea sensibilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 4258,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XS9LEB9Y;X3PRK8HC;4SD5WHVA;77L5TJ2V;KKH2W1TE;XWHNAB5G;RR4WXZEW;LXJ45R6Y',
      statusContent: 'dot',
      hash: '6YT4DHVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YT4DHVL'
    },
    {
      status: 210,
      sku: 'PM0068478',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227920',
      rtl_batch_array: '6227920',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1663,
      chnn_stock_retail: 1663,
      sppl_prcp: 4.506,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: 'YW98N734',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW98N734'
    },
    {
      status: 810,
      sku: 'PM0059905',
      core_name: 'Plant',
      sppl_ean: '8720664851573',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129720',
      rtl_batch_array: '6129720',
      name_search: 'ALWGSPRI',
      name: "Alyssum wulfenianum 'Golden Spring'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ETY7S2CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETY7S2CH'
    },
    {
      status: 210,
      sku: 'PM0067497',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229886',
      rtl_batch_array: '6229886, 6192711',
      name_search: 'CREMBERG',
      name: "Crocosmia 'Emberglow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 130,
      chnn_stock_retail: 1017,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KN5TSARL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KN5TSARL'
    },
    {
      status: 810,
      sku: 'PM0070271',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6221384',
      rtl_batch_array: '6221384, 6370366',
      name_search: 'HEOYLADY',
      name: "Helleborus orientalis 'Yellow Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 25,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E26TEZ47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E26TEZ47'
    },
    {
      status: 210,
      sku: 'PM0070227',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350509',
      rtl_batch_array: '6350509',
      name_search: 'HOGEXPEC',
      name: "Hosta 'Great Expectations'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KZCT9S1V;EC1VZVGA;6E8E4AB5',
      statusContent: 'dot',
      hash: 'TYE3NPX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYE3NPX9'
    },
    {
      status: 210,
      sku: 'PM0079486',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319472',
      rtl_batch_array: '6319472',
      name_search: 'ECPAUGUS',
      name: "Echinacea purpurea 'Augustk\u00f6nigin'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2706,
      chnn_stock_retail: 2706,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PC8ZN98T;H28VVV6T;2YSWBWLT;KPARJN76;7JWDZ5EE;XVLAW5HX',
      statusContent: 'dot',
      hash: 'TYS6AYY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYS6AYY1'
    },
    {
      status: 810,
      sku: 'PM0079487',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301473',
      rtl_batch_array: '6301473',
      name_search: 'CAARUNDI',
      name: 'Calamagrostis arundinacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RYW9BVW7;RV78KXEZ;WDHJSC62;J6BVKTSR;E3PARHGR;T27988NV;V2BDTC3S;BTK1TGGR',
      statusContent: 'dot',
      hash: 'B6NZ9WW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6NZ9WW6'
    },
    {
      status: 210,
      sku: 'PM0079488',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330040',
      rtl_batch_array: '6301557, 6330040',
      name_search: 'PHHERBST',
      name: "Phlox (P) 'Herbstwalzer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 549,
      chnn_stock_retail: 771,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S1NGVJ8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1NGVJ8D'
    },
    {
      status: 210,
      sku: 'PM0079489',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301694',
      rtl_batch_array: '6371186, 6301694',
      name_search: 'LIDOTHEL',
      name: "Ligularia dentata 'Othello'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1510,
      chnn_stock_retail: 1654,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8CAZERD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8CAZERD'
    },
    {
      status: 210,
      sku: 'PM0079490',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301730',
      rtl_batch_array: '6301730',
      name_search: 'MODIDYMA',
      name: 'Monarda didyma',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9BTLRZ8H;4VKZXYDB;67SJD1V5;T71APS63;2XPRJHVC',
      statusContent: 'dot',
      hash: 'Y11PRJB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y11PRJB3'
    },
    {
      status: 210,
      sku: 'PM0045219',
      core_name: 'Plant',
      sppl_ean: '8720349419586',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592233',
      rtl_batch_array: '5592233',
      name_search: 'IRSDWALT',
      name: "Iris sibirica 'Dawn Waltz'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EALB1XZZ;AR23DWAE',
      imageBatch: '2ZVGLRJ1',
      statusContent: 'dot',
      hash: '7YA18GS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YA18GS9'
    },
    {
      status: 210,
      sku: 'PM0070244',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220863',
      rtl_batch_array: '6220863',
      name_search: 'MULINDHE',
      name: 'Muhlenbergia lindheimeri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GVJR4Y9R;58J2TAHP',
      statusContent: 'dot',
      hash: 'W9L7WAJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9L7WAJZ'
    },
    {
      status: 210,
      sku: 'PM0079491',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302551',
      rtl_batch_array: '6302551',
      name_search: 'HEAPMAGN',
      name: "Helenium autumnale 'Pumilum Magnificum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GVWN853G',
      statusContent: 'dot',
      hash: '44DTK99R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44DTK99R'
    },
    {
      status: 810,
      sku: 'PM0079492',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302564',
      rtl_batch_array: '6302564',
      name_search: 'HEFDRAGO',
      name: "Helianthemum 'Fire Dragon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TBHLA427;WY7LWLWS;1X36XHCZ;Y99N5JHZ;BAY4YY8G;XL2RCHK2;DPWVXLK7;5C531E86;2LSKHSB6;57V99DLR;GA1WHPE8;LKG9L3VV;1H2C7LZN',
      statusContent: 'dot',
      hash: 'YY5VTV58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YY5VTV58'
    },
    {
      status: 810,
      sku: 'PM0079493',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302619',
      rtl_batch_array: '6302619',
      name_search: 'IBAAMETH',
      name: 'Iberis Absolutely Amethyst',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XT6G28SA;1NYZL16T;WVYXE6LN;BAG1KJ2S;RLG55X1N;1135XN1C;ZCSG2ELH;8WRVKHZG;11J9LD26',
      statusContent: 'dot',
      hash: '5EN8NVEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EN8NVEP'
    },
    {
      status: 210,
      sku: 'PM0070228',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220822',
      rtl_batch_array: '6220822',
      name_search: 'LIIXIOID',
      name: 'Libertia ixioides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZBDPH7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZBDPH7W'
    },
    {
      status: 210,
      sku: 'PM0070232',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220841',
      rtl_batch_array: '6220841',
      name_search: 'LUGLADIA',
      name: "Lupinus 'Gladiator'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6H264D86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H264D86'
    },
    {
      status: 210,
      sku: 'PM0082149',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346225',
      rtl_batch_array: '6346225',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'XE82S9EL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE82S9EL'
    },
    {
      status: 910,
      sku: 'PM0070234',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LUPSLIPP',
      name: "Lupinus 'Persian Slipper'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'C8AJ84YX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C8AJ84YX'
    },
    {
      status: 810,
      sku: 'PM0070231',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220839',
      rtl_batch_array: '6220839',
      name_search: 'LUDSUN',
      name: "Lupinus 'Desert Sun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LH1N8J9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH1N8J9P'
    },
    {
      status: 210,
      sku: 'PM0079494',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302620',
      rtl_batch_array: '6302620',
      name_search: 'IBSAETZ',
      name: "Iberis sempervirens 'Appen-Etz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XW7WRA2D;CZ5H5GPR;R3282J35;2X41C335;YW24ZL49;VVS5K4CD;D5KSRP7Y;71P4DX9E;KWE3SB7Z;9LADG5ZB',
      statusContent: 'dot',
      hash: 'L8C4X4SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8C4X4SX'
    },
    {
      status: 210,
      sku: 'PM0070235',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220845',
      rtl_batch_array: '6220845',
      name_search: 'LUTINFER',
      name: "Lupinus 'Towering Inferno'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 211,
      chnn_stock_retail: 211,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRLLRX11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRLLRX11'
    },
    {
      status: 210,
      sku: 'PM0070240',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220852',
      rtl_batch_array: '6220852',
      name_search: 'MESZITRO',
      name: "Mentha spicata 'Zitronenminze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H28J18BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H28J18BR'
    },
    {
      status: 210,
      sku: 'PM0070245',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220864',
      rtl_batch_array: '6220864',
      name_search: 'MURIGENS',
      name: 'Muhlenbergia rigens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RWTCRJLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWTCRJLZ'
    },
    {
      status: 210,
      sku: 'PM0070253',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353122',
      rtl_batch_array: '6353122',
      name_search: 'POYEZOEN',
      name: 'Polemonium yezoense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NGHWTHPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGHWTHPS'
    },
    {
      status: 210,
      sku: 'PM0082150',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346226',
      rtl_batch_array: '6346226',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 7.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'TZ8K7RHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZ8K7RHL'
    },
    {
      status: 210,
      sku: 'PM0070256',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220912',
      rtl_batch_array: '6220912',
      name_search: 'SCCPALBA',
      name: "Scabiosa caucasica 'Perfecta Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1893,
      chnn_stock_retail: 1893,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7V7DEZXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7V7DEZXC'
    },
    {
      status: 210,
      sku: 'PM0067489',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333204',
      rtl_batch_array: '6193718, 6333204',
      name_search: 'CAPRATEN',
      name: 'Cardamine pratensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7248,
      chnn_stock_retail: 8248,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SXGAGBY;NPJD8TYS;Z9HS4E98;KL6LYD38',
      statusContent: 'dot',
      hash: 'RC5S12AJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC5S12AJ'
    },
    {
      status: 910,
      sku: 'PM0070260',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SETKARFU',
      name: "Sedum telephium 'Karfunkelstein'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5P88SRZS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5P88SRZS'
    },
    {
      status: 210,
      sku: 'PM0070261',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220923',
      rtl_batch_array: '6220923',
      name_search: 'SESCREAM',
      name: "Sedum 'Strawberry and Cream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K6JA7JAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6JA7JAH'
    },
    {
      status: 810,
      sku: 'PM0070263',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220933',
      rtl_batch_array: '6220933',
      name_search: 'TRRDBLOO',
      name: "Trifolium repens 'Dragon's Blood'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VVL569DW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVL569DW'
    },
    {
      status: 210,
      sku: 'PM0070264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220934',
      rtl_batch_array: '6220934',
      name_search: 'UNEGMONT',
      name: 'Uncinia egmontiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '428T4859',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '428T4859'
    },
    {
      status: 210,
      sku: 'PM0032613',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260694',
      rtl_batch_array: '6260694, 5837433, 6234749',
      name_search: 'GESMFREI',
      name: "Geranium sanguineum 'Max Frei'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1683,
      chnn_stock_retail: 4418,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WBYASXG1;6H7XNWSD;SEB7R9LS;KSDHVT2L;N1TAX1SV;NNKE2BWR;5LXCW5PA;G9739E15;6CHZLZ1A;LNLT96AE;DAZJERZT',
      statusContent: 'dot',
      hash: '9SL4T8G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SL4T8G9'
    },
    {
      status: 210,
      sku: 'PM0032779',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269850',
      rtl_batch_array: '5837417, 6269850, 6234687',
      name_search: 'ECPALBA',
      name: "Echinacea purpurea 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1160,
      chnn_stock_retail: 5449,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSG792BE;BWC2X2CD;JD32Y745',
      statusContent: 'dot',
      hash: 'WNY3P3GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNY3P3GH'
    },
    {
      status: 210,
      sku: 'PM0074330',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269829',
      rtl_batch_array: '6269829',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2206,
      chnn_stock_retail: 2206,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y5ABZNCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5ABZNCT'
    },
    {
      status: 210,
      sku: 'PM0070266',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291742',
      rtl_batch_array: '6353573, 6291742',
      name_search: 'VEVCUPID',
      name: "Veronicastrum virginicum 'Cupid'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 403,
      chnn_stock_retail: 795,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ZL9JN4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZL9JN4G'
    },
    {
      status: 210,
      sku: 'PM0070268',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220942',
      rtl_batch_array: '6220942, 6336453, 6345219',
      name_search: 'VINMALBA',
      name: "Vinca major 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 952,
      chnn_stock_retail: 6869,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNK8DYGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNK8DYGH'
    },
    {
      status: 210,
      sku: 'PM0079474',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319345',
      rtl_batch_array: '6319345, 6338977',
      name_search: 'COPSFIND',
      name: "Cotoneaster procumbens 'Streib's Findling'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3985,
      chnn_stock_retail: 9940,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y1SABGR;HNWPVZWE',
      statusContent: 'dot',
      hash: '25V5Z5G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25V5Z5G5'
    },
    {
      status: 210,
      sku: 'PM0067491',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6306086',
      rtl_batch_array: '6349916, 6269612, 6306086',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1144,
      chnn_stock_retail: 2456,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      statusContent: 'dot',
      hash: 'ACLLVGW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACLLVGW5'
    },
    {
      status: 210,
      sku: 'PM0044980',
      core_name: 'Plant',
      sppl_ean: '8720626324602',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354801',
      rtl_batch_array: '5354801',
      name_search: 'SANSROSE',
      name: "Salvia nemorosa 'Sensation Rose'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEX3JZY1;7SETCRT7;KEBD4JCT;B3V3JNP2;8R7HS16P;XAHWKJ8N',
      imageBatch: '4JHZD3YT',
      statusContent: 'dot',
      hash: 'BSHT1PNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSHT1PNB'
    },
    {
      status: 210,
      sku: 'PM0074331',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269830',
      rtl_batch_array: '6269830',
      name_search: 'HYIELSTE',
      name: "Hypericum inodorum 'Elstead'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2815,
      chnn_stock_retail: 2815,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L75ZNA42;ATCXXEGR;6P4CP7B3;9BLGGCVP;V7G4T67H;44GPR7C3;72RNHJHL;9A4334CA',
      statusContent: 'dot',
      hash: '17GL7CHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17GL7CHH'
    },
    {
      status: 210,
      sku: 'PM0082151',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346227',
      rtl_batch_array: '6346227',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 10.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'VKT6KYTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKT6KYTL'
    },
    {
      status: 910,
      sku: 'PM0032663',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KNPYELLO',
      name: 'Kniphofia Poco Yellow',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'DSHZ4L7P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DSHZ4L7P'
    },
    {
      status: 210,
      sku: 'PM0079476',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319460',
      rtl_batch_array: '6319460',
      name_search: 'PHOANDRE',
      name: "Physocarpus opulifolius 'Andre'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '48KGTDTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48KGTDTH'
    },
    {
      status: 210,
      sku: 'PM0079477',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319461',
      rtl_batch_array: '6319461',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: 'N46DJV1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N46DJV1D'
    },
    {
      status: 810,
      sku: 'PM0068412',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6202294',
      rtl_batch_array: '6220743, 6202294, 6301588',
      name_search: 'GECBALLE',
      name: "Geranium cinereum 'Ballerina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 2355,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y96Z6HR6;LG1H99G4',
      statusContent: 'dot',
      hash: 'ZG2XZR7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG2XZR7N'
    },
    {
      status: 210,
      sku: 'PM0082152',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346228',
      rtl_batch_array: '6346228, 6385590',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '55GDY4AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55GDY4AX'
    },
    {
      status: 210,
      sku: 'PM0079479',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319463',
      rtl_batch_array: '6319463',
      name_search: 'GEORCLAI',
      name: "Geranium oxonianum 'Rose Clair'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1914,
      chnn_stock_retail: 1914,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KK2G2932;N6PJ6BNL',
      statusContent: 'dot',
      hash: 'TXL1NSH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXL1NSH4'
    },
    {
      status: 210,
      sku: 'PM0079495',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302663',
      rtl_batch_array: '6350672, 6302663',
      name_search: 'MEAATROP',
      name: "Melica altissima 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 321,
      chnn_stock_retail: 1621,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V26Y38TE',
      statusContent: 'dot',
      hash: '4HTA8W5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HTA8W5J'
    },
    {
      status: 210,
      sku: 'PM0045208',
      core_name: 'Plant',
      sppl_ean: '8720349409822',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592218',
      rtl_batch_array: '5592218',
      name_search: 'ASBAHAMA',
      name: "Aster 'Bahamas'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZGGAB39;CYHG69K5;TPDLJB9Y;YHERR7RY;NVWK4PZ1;2ZLJ8TH1;9R9HSLW5;X2DNY861;XLYXP7GH',
      imageBatch: 'ZECC38PD',
      statusContent: 'dot',
      hash: 'GC95TS4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GC95TS4C'
    },
    {
      status: 210,
      sku: 'PM0074333',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6275359',
      rtl_batch_array: '6275359, 6315215, 6269834',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3970,
      chnn_stock_retail: 20967,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'P652S35G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P652S35G'
    },
    {
      status: 210,
      sku: 'PM0045214',
      core_name: 'Plant',
      sppl_ean: '8720349409952',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592228',
      rtl_batch_array: '5592228',
      name_search: 'ASBBEAUT',
      name: "Astilbe (A) 'Bressingham Beauty'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y2C7872J;2ZJAEEK8',
      imageBatch: 'NW6S7VDL',
      statusContent: 'dot',
      hash: 'GE1XXPXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GE1XXPXN'
    },
    {
      status: 810,
      sku: 'PM0079496',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302677',
      rtl_batch_array: '6302677',
      name_search: 'MOADAM',
      name: "Monarda 'Adam'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZP9WT4GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP9WT4GC'
    },
    {
      status: 210,
      sku: 'PM0079497',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330037',
      rtl_batch_array: '6302719, 6330037',
      name_search: 'PHFSCHUB',
      name: "Phlox (P) 'Franz Schubert'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 778,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTS7GASX',
      statusContent: 'dot',
      hash: 'WK3BZPN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WK3BZPN4'
    },
    {
      status: 210,
      sku: 'PM0067496',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229885',
      rtl_batch_array: '6229885, 6192710',
      name_search: 'CRCBRILL',
      name: "Crocosmia 'Carmine Brilliant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 241,
      chnn_stock_retail: 1530,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJKA9LCH',
      statusContent: 'dot',
      hash: 'ZJ4YN57D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ4YN57D'
    },
    {
      status: 210,
      sku: 'PM0069017',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315039',
      rtl_batch_array: '6300422, 6315039',
      name_search: 'AQALPINA',
      name: 'Aquilegia alpina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1403,
      chnn_stock_retail: 2567,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T8YX11RC;7XXGAWAY;JYLD1V2P;E5LSZ7AN;H72CTVXN;JZZNBE4E;DWDTBJE5;54GDKZL8;V7LDPH25;RSA67WNB;DD4SKADS;GPTXPP25;Y2P941C2;4DTDWG59;4GWD7S6Z;611SBX3Y',
      statusContent: 'dot',
      hash: '86GK3Y8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86GK3Y8N'
    },
    {
      status: 210,
      sku: 'PM0079480',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319464',
      rtl_batch_array: '6319464',
      name_search: 'HEMLOA',
      name: "Hemerocallis 'Mauna Loa'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A6XNRGCV;NAVE4W7Z',
      statusContent: 'dot',
      hash: 'ALW3GV42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALW3GV42'
    },
    {
      status: 210,
      sku: 'PM0068295',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260696',
      rtl_batch_array: '6260696, 6252607',
      name_search: 'HELMBEAU',
      name: "Helenium 'Moerheim Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 432,
      chnn_stock_retail: 1467,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BC2XLJ8X;E938N398;NZRE4ASR;67TJGVZ8;BJ4HV1LH;JDJ8AS9V;BHC3ETJW;WED4CXX3;XCSW959Y;DZBL1TS2;W1757Y63',
      statusContent: 'dot',
      hash: 'ZLVV5JD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLVV5JD3'
    },
    {
      status: 210,
      sku: 'PM0045137',
      core_name: 'Plant',
      sppl_ean: '8720664688261',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5592030',
      rtl_batch_array: '5592030',
      name_search: 'ECPGTWIS',
      name: "Echinacea purpurea 'Green Twister'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ALT5GDRZ;92DHA2H6',
      imageBatch: 'RLDCYV8R',
      statusContent: 'dot',
      hash: 'PN145RHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PN145RHZ'
    },
    {
      status: 210,
      sku: 'PM0070258',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220914',
      rtl_batch_array: '6220914',
      name_search: 'SCSJRFRO',
      name: 'Schizachyrium scoparium JS Red Frost',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2X2439A;1LGESWGV;KV9GANR1;6BTEJ5H1;2DLADPG7;APND7HA5;P6V1VD1G;G3YC2SXK;YYSX1BRL;YGXXPJ2S',
      statusContent: 'dot',
      hash: 'BSVLSPVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSVLSPVW'
    },
    {
      status: 210,
      sku: 'PM0068911',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254866',
      rtl_batch_array: '6254866',
      name_search: 'ACTFPOME',
      name: 'Achillea Tutti Frutti Pomegranate',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 2.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GNH1RTPC;JWHN64BA',
      statusContent: 'dot',
      hash: 'LH2EEJTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH2EEJTB'
    },
    {
      status: 210,
      sku: 'PM0079483',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319467',
      rtl_batch_array: '6319467',
      name_search: 'LIDHBLUE',
      name: "Lithodora diffusa 'Heavenly Blue'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2706,
      chnn_stock_retail: 2706,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RV13BXSN;BV2Y2ZRN;ZDPKXPXZ;SN938LTA;L5E5KHAW;6XR4NNR7;H96W98DY;7VGPDRY4;CZBJHDXT',
      statusContent: 'dot',
      hash: '2SGP1EBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SGP1EBN'
    },
    {
      status: 210,
      sku: 'PM0079498',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302759',
      rtl_batch_array: '6302759, 6386183',
      name_search: 'SANNEGRI',
      name: "Salvia nemorosa 'Negrito'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 911,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7SR7VTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7SR7VTV'
    },
    {
      status: 210,
      sku: 'PM0070265',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220935',
      rtl_batch_array: '6220935',
      name_search: 'UNRUBRA',
      name: 'Uncinia rubra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LDPD2Z6L',
      statusContent: 'dot',
      hash: '2N1PCETE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N1PCETE'
    },
    {
      status: 210,
      sku: 'PM0079499',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302761',
      rtl_batch_array: '6302761, 6330084',
      name_search: 'SANTAENZ',
      name: "Salvia nemorosa 'T\u00e4nzerin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 105,
      chnn_stock_retail: 783,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W18N32RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W18N32RW'
    },
    {
      status: 210,
      sku: 'PM0082153',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346229',
      rtl_batch_array: '6346229',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.978,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'HTNGTWA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTNGTWA3'
    },
    {
      status: 810,
      sku: 'PM0079500',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302769',
      rtl_batch_array: '6302769',
      name_search: 'LULUZULO',
      name: 'Luzula luzuloides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7TLZ5V3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TLZ5V3E'
    },
    {
      status: 210,
      sku: 'PM0079501',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302774',
      rtl_batch_array: '6302774',
      name_search: 'LYSZIGEU',
      name: "Lythrum salicaria 'Zigeunerblut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1ABNZ77K;SYB44YTA',
      statusContent: 'dot',
      hash: 'R4CR5T77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4CR5T77'
    },
    {
      status: 810,
      sku: 'PM0070267',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6231166',
      rtl_batch_array: '6220941, 6231166, 6315081, 6267809',
      name_search: 'VEVPGLOW',
      name: "Veronicastrum virginicum 'Pink Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 3109,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SSX89NB4;AAL6HSJ7',
      statusContent: 'dot',
      hash: 'CE3YDLJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE3YDLJC'
    },
    {
      status: 210,
      sku: 'PM0070237',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350674',
      rtl_batch_array: '6350674',
      name_search: 'MECARMIN',
      name: 'Metrosideros carminea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1LNRNCEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LNRNCEV'
    },
    {
      status: 210,
      sku: 'PM0070241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220854',
      rtl_batch_array: '6220854',
      name_search: 'MISCONE',
      name: "Miscanthus sinensis 'Cute One'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CA9ES9VN;RT1TRSKG',
      statusContent: 'dot',
      hash: 'DADELRN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DADELRN8'
    },
    {
      status: 210,
      sku: 'PM0070242',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230646',
      rtl_batch_array: '6230646',
      name_search: 'MISHMUSS',
      name: "Miscanthus sinensis 'Herman Mussel'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HD81J22;4V87XL3S;BRHBW4XV;BXARZKN3',
      statusContent: 'dot',
      hash: '4HWW488R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HWW488R'
    },
    {
      status: 210,
      sku: 'PM0079502',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302811',
      rtl_batch_array: '6302811',
      name_search: 'VELMARIE',
      name: "Veronica longifolia 'Marietta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VLLN1EJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VLLN1EJ'
    },
    {
      status: 210,
      sku: 'PM0079503',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302816',
      rtl_batch_array: '6302816',
      name_search: 'VESINCAN',
      name: 'Veronica spicata incana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YT5WHTYE',
      statusContent: 'dot',
      hash: '1C97X8HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1C97X8HH'
    },
    {
      status: 210,
      sku: 'PM0070249',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350744',
      rtl_batch_array: '6350744',
      name_search: 'PAVHHAIN',
      name: "Panicum virgatum 'Heiliger Hain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1238,
      chnn_stock_retail: 1238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '725234RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '725234RJ'
    },
    {
      status: 210,
      sku: 'PM0082154',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346230',
      rtl_batch_array: '6346230',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.569,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'SLARNZW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLARNZW5'
    },
    {
      status: 210,
      sku: 'PM0079504',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302827',
      rtl_batch_array: '6302827',
      name_search: 'SAJONI',
      name: "Sanguisorba 'Joni'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LDVENNG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDVENNG6'
    },
    {
      status: 210,
      sku: 'PM0079505',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254244',
      rtl_batch_array: '6254244',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3090,
      chnn_stock_retail: 3090,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: 'LHCRVBG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHCRVBG6'
    },
    {
      status: 910,
      sku: 'PM0079506',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'BJJS2VZX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BJJS2VZX'
    },
    {
      status: 210,
      sku: 'PM0079449',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317691',
      rtl_batch_array: '6317691',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_prcp: 5.014,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: '7ZY3EXHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZY3EXHJ'
    },
    {
      status: 210,
      sku: 'PM0079535',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314288',
      rtl_batch_array: '6314288',
      name_search: 'VIMINOR',
      name: 'Vinca minor',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3623,
      chnn_stock_retail: 3623,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EG281Z1T;7PHP8SX2;A9ZBL78N;VZ4C57B6;YEEWSG3H;YZL8SB43;VWHK82DS;AG2GCWHL;HXBZK6K5;D5EP2G8A;41VVEG6A;2X2V95LJ;BZCBAD26;N6895BNJ;HKDSTCGY;ABE1R6VC',
      statusContent: 'dot',
      hash: 'R1XAJ3HN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1XAJ3HN'
    },
    {
      status: 210,
      sku: 'PM0082155',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346231',
      rtl_batch_array: '6346231',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 10.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'STXDKDA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STXDKDA6'
    },
    {
      status: 210,
      sku: 'PM0082156',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346232',
      rtl_batch_array: '6346232',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 13.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'GE8AB5SC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GE8AB5SC'
    },
    {
      status: 210,
      sku: 'PM0070229',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220835',
      rtl_batch_array: '6220835',
      name_search: 'LISFVIOL',
      name: "Liatris spicata 'Floristan Violett'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2986,
      chnn_stock_retail: 2986,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DB24JYT5;PDB5YG8N',
      statusContent: 'dot',
      hash: 'SABZH8GA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SABZH8GA'
    },
    {
      status: 210,
      sku: 'PM0070238',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220850',
      rtl_batch_array: '6220850, 6334771',
      name_search: 'MEPCHOCO',
      name: "Mentha piperita 'Chocolate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 243,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y93WTGL2;KXK1J2NB;VTYZ21PW;T1N7JS35;2XPJ6G1H;LPYLL9N3;B61HYSE2;YREJCDL6;L2L8L685;7XCA2591;CKYXZ1DS;9DGD14G6;223Y4ZR1;1BJHDE9Y;1DDSC8S8;JYLYZTDZ;ZCHY2S6W;X8HW9G8W',
      statusContent: 'dot',
      hash: 'KHGTJB5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHGTJB5D'
    },
    {
      status: 210,
      sku: 'PM0082157',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346233',
      rtl_batch_array: '6346233',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 16.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'A2NA61GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2NA61GS'
    },
    {
      status: 210,
      sku: 'PM0070270',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6340026',
      rtl_batch_array: '6349088, 6340026',
      name_search: 'GEPRATEN',
      name: 'Geranium pratense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1179,
      chnn_stock_retail: 1875,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1S8EZGJD',
      statusContent: 'dot',
      hash: 'WZPA5NAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZPA5NAY'
    },
    {
      status: 210,
      sku: 'PM0044983',
      core_name: 'Plant',
      sppl_ean: '8720349425259',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5354810',
      rtl_batch_array: '5354810',
      name_search: 'PHUSPECH',
      name: "Phlox (P) 'Uspech'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BRDTTEB',
      imageBatch: 'NWDG69T8',
      statusContent: 'dot',
      hash: '24VNTJBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24VNTJBW'
    },
    {
      status: 210,
      sku: 'PM0045118',
      core_name: 'Plant',
      sppl_ean: '8720349419647',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5591979',
      rtl_batch_array: '5591979',
      name_search: 'IRSMAPPL',
      name: "Iris sibirica 'Miss Apple'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ZGLPV7S;N8L2TXC6;D8JAR98T;YSD68D1V;KKN39RY9;27THXLZ7;H5L7S45H;BDCPD1D2;KKHHKV8W;WGYRZZVG;RBJE1E8Z',
      imageBatch: 'GPP13ZK2',
      statusContent: 'dot',
      hash: '6NLHCTTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NLHCTTP'
    },
    {
      status: 210,
      sku: 'PM0079539',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321506',
      rtl_batch_array: '6321506',
      name_search: 'SASUBULA',
      name: 'Sagina subulata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 442,
      chnn_stock_retail: 442,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N9RZSZH6;7YVJ7E7T;JAPL8TYL;R4JJC7RG;WZXH5277;RNJPN82S;W2Y5ZDJP;SJJC95A5;ZY4KS3BA;EBH22TA3;24DX6R1S;6TAEN25W;C899288A;E1DP5TD1;8TJJJLRH;8YRV2V3P;A281Y57T;2988Y9TA;HZYSZ3X3;RD4WB6KE;GBNBW2D7',
      statusContent: 'dot',
      hash: 'JNB2GHLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNB2GHLE'
    },
    {
      status: 910,
      sku: 'PM0079540',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334187',
      rtl_batch_array: '6322363, 6334187',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 15,
      chnn_stock_retail: 1359,
      sppl_prcp: 8.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '5ST651H2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ST651H2'
    },
    {
      status: 210,
      sku: 'PM0079541',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336150',
      rtl_batch_array: '6336150',
      name_search: 'GRLITTOR',
      name: 'Griselinia littoralis',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1235,
      chnn_stock_retail: 1235,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'STYAHC45;NLA1EY63;PHXW78VN;NYKR71JV;R5BVEB9X;GP34GEWA;9GCVSGSL;JT5NKVY9;R42BK62V',
      statusContent: 'dot',
      hash: 'TBW3WDEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBW3WDEK'
    },
    {
      status: 210,
      sku: 'PM0079542',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6322366',
      rtl_batch_array: '6322366',
      name_search: 'LISKOBOL',
      name: "Liatris spicata 'Kobold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 286,
      chnn_stock_retail: 286,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5B5GENNE;CL16HNJ3;ZTDASLE6;BC94D4EC;GV5NX2PY;XEBRDZEH;VPTL1N53;212TTE5R;AT4XWSS9;T3SBT4DS;ZDWEE1DS;8T6C2S56;6GW9XA89',
      statusContent: 'dot',
      hash: 'SGW88CZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGW88CZB'
    },
    {
      status: 210,
      sku: 'PM0079550',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329816',
      rtl_batch_array: '6329816',
      name_search: 'ACGGDELI',
      name: "Acorus gramineus 'Golden Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LS4SL112',
      statusContent: 'dot',
      hash: 'AG6JL8Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AG6JL8Y8'
    },
    {
      status: 210,
      sku: 'PM0079551',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302328',
      rtl_batch_array: '6329817, 6302328',
      name_search: 'AGFLEUR',
      name: "Agastache 'Fleur'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 113,
      chnn_stock_retail: 329,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YPP5SKE',
      statusContent: 'dot',
      hash: '98STEE6J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98STEE6J'
    },
    {
      status: 210,
      sku: 'PM0079552',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329818',
      rtl_batch_array: '6329818',
      name_search: 'AGPDBLUE',
      name: 'Agastache Poquito Dark Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 266,
      chnn_stock_retail: 266,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RG79T35H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG79T35H'
    },
    {
      status: 210,
      sku: 'PM0079553',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329819',
      rtl_batch_array: '6329819',
      name_search: 'AGPLAVEN',
      name: 'Agastache Poquito Lavender',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4HS5ZRL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HS5ZRL2'
    },
    {
      status: 210,
      sku: 'PM0079554',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329820',
      rtl_batch_array: '6329820',
      name_search: 'AJANICE',
      name: 'Ajanthemum Arella Nice as Ice',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BGHJ3ZGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGHJ3ZGE'
    },
    {
      status: 210,
      sku: 'PM0079555',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329821',
      rtl_batch_array: '6329821',
      name_search: 'AJRGCHAN',
      name: "Ajuga reptans 'Gold Chang'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YS6Y3EXZ',
      statusContent: 'dot',
      hash: 'ND1ZEKRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND1ZEKRN'
    },
    {
      status: 210,
      sku: 'PM0079556',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329823',
      rtl_batch_array: '6329823',
      name_search: 'ASEWZWER',
      name: "Aster ericoides 'Weisser Zwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TEANRAVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEANRAVV'
    },
    {
      status: 210,
      sku: 'PM0079557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329824',
      rtl_batch_array: '6329824',
      name_search: 'ASLBAUTU',
      name: "Aster linnaeus 'Blue Autumn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 338,
      chnn_stock_retail: 338,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SBRJS33E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBRJS33E'
    },
    {
      status: 810,
      sku: 'PM0079558',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329825',
      rtl_batch_array: '6329825',
      name_search: 'ASNBBNAC',
      name: "Aster novi-belgii 'Blaue Nachhut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KP1H4VP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP1H4VP2'
    },
    {
      status: 210,
      sku: 'PM0079559',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329826',
      rtl_batch_array: '6329826',
      name_search: 'ASNBBLUE',
      name: "Aster novi-belgii 'Bl\u00fctenmeer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SP5V7DYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP5V7DYK'
    },
    {
      status: 810,
      sku: 'PM0079560',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329827',
      rtl_batch_array: '6329827',
      name_search: 'ASNBLISE',
      name: "Aster novi-belgii 'Lisette'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9K3J4YD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9K3J4YD1'
    },
    {
      status: 210,
      sku: 'PM0082158',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385593',
      rtl_batch_array: '6385593',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 730,
      chnn_stock_retail: 730,
      sppl_prcp: 4.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'KJLS6L2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJLS6L2V'
    },
    {
      status: 210,
      sku: 'PM0059913',
      core_name: 'Plant',
      sppl_ean: '8720664854338',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129737',
      rtl_batch_array: '6129737',
      name_search: 'BUFASORA',
      name: "Bulbine frut. 'Avera Sunset Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8SJNKEJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SJNKEJJ'
    },
    {
      status: 810,
      sku: 'PM0067499',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321936',
      rtl_batch_array: '6192715, 6321936',
      name_search: 'CYASCYRI',
      name: 'Cynanchum ascyrifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 40,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3N3XEG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3N3XEG7'
    },
    {
      status: 210,
      sku: 'PM0026544',
      core_name: 'Plant',
      sppl_ean: '8720349428700',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '4751719',
      rtl_batch_array: '4751719',
      name_search: 'SEFWGOLD',
      name: "Sedum floriferum 'Weihenstephaner Gold'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1786,
      chnn_stock_retail: 1786,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55NKSBZC;DGR2B7S7;RP8WC93T;9L77ERP5;4ED49BE3;BV3Y6NB3;R96JXA6P;51T1JJJN;V1D4322Z;7S7X81TK;8PN6CBLJ;RV4B1T43;6W9GH2AK;K92DW8HA',
      imageBatch: 'XJAL6GKG',
      statusContent: 'dot',
      hash: 'PCCELRW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCCELRW5'
    },
    {
      status: 210,
      sku: 'PM0064333',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278052',
      rtl_batch_array: '6278052',
      name_search: 'LONHFIND',
      name: "Lonicera nitida 'Hohenheimer Findling'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.748,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TETVKWK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TETVKWK4'
    },
    {
      status: 210,
      sku: 'PM0074334',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269840',
      rtl_batch_array: '6269840',
      name_search: 'LONITIDA',
      name: 'Lonicera nitida',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3587,
      chnn_stock_retail: 3587,
      sppl_prcp: 1.471,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1JTDKH6;YKG75834;CRAYVTZ4;SSSSXDTD;YP31E8G3;TTA9TLTC;NSZGC3XE;C11D8GKN;R6GTGC3Z;R4RBWG2C',
      statusContent: 'dot',
      hash: '97K36SD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97K36SD7'
    },
    {
      status: 210,
      sku: 'PM0074342',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269853',
      rtl_batch_array: '6269853',
      name_search: 'GALBBUTT',
      name: 'Gaura lindheimeri Blushing Butterflies',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 931,
      chnn_stock_retail: 931,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SG9V2GY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SG9V2GY'
    },
    {
      status: 210,
      sku: 'PM0082159',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346235',
      rtl_batch_array: '6346235',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060070KL',
      rng_range_identifier: 'H060070RB',
      rng_range_description: 'H60 cm 70 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'PJ735VNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJ735VNE'
    },
    {
      status: 210,
      sku: 'PM0082160',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385594',
      rtl_batch_array: '6287501, 6385594',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1880,
      chnn_stock_retail: 2380,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'WKSTTHS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKSTTHS7'
    },
    {
      status: 210,
      sku: 'PM0074335',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269841',
      rtl_batch_array: '6269841',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5414,
      chnn_stock_retail: 5414,
      sppl_prcp: 1.471,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'AJ2NKZGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJ2NKZGA'
    },
    {
      status: 210,
      sku: 'PM0082161',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346237',
      rtl_batch_array: '6346237',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 11.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '299YXHL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '299YXHL5'
    },
    {
      status: 210,
      sku: 'PM0082162',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346238',
      rtl_batch_array: '6346238',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 14.158,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'C1YTRNW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1YTRNW6'
    },
    {
      status: 210,
      sku: 'PM0082163',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346239',
      rtl_batch_array: '6346239',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 20.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'H7SXV5C4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7SXV5C4'
    },
    {
      status: 210,
      sku: 'PM0026456',
      core_name: 'Plant',
      sppl_ean: '8720664881266',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161903',
      rtl_batch_array: '6161903',
      name_search: 'PLCTSUBO',
      name: "Pleioblastus chino 'Tsuboi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VAL5YNBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAL5YNBV'
    },
    {
      status: 910,
      sku: 'PM0082164',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'KKKHWXRP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KKKHWXRP'
    },
    {
      status: 210,
      sku: 'PM0082165',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346241',
      rtl_batch_array: '6346241',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 38.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '235TDE89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '235TDE89'
    },
    {
      status: 210,
      sku: 'PM0082166',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346242',
      rtl_batch_array: '6346242',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 46.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'YX1K5NDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YX1K5NDZ'
    },
    {
      status: 210,
      sku: 'PM0082167',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346243',
      rtl_batch_array: '6346243',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 14.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'X2G3A2KW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2G3A2KW'
    },
    {
      status: 210,
      sku: 'PM0074336',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269842',
      rtl_batch_array: '6269842',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4034,
      chnn_stock_retail: 4034,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '7B7D3C38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7B7D3C38'
    },
    {
      status: 210,
      sku: 'PM0082168',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346244',
      rtl_batch_array: '6346244',
      name_search: 'CHLCOLUM',
      name: "Chamaecyparis lawsoniana 'Columnaris'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 5.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZ65YEBT;NABYNC2K;1AGEPTPC;9VL4TXHX;949C1DA4;L676NZGD;233NPP8C;54BG9RKD;NXE71H79;832DA6RK;C8CWKS7S;D2ZK24VK;G1W87X9S;AAZ6HGPP',
      statusContent: 'dot',
      hash: 'WW2VD8XT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW2VD8XT'
    },
    {
      status: 210,
      sku: 'PM0074337',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269843',
      rtl_batch_array: '6269843',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 861,
      chnn_stock_retail: 861,
      sppl_prcp: 1.471,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'V9JXN4E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9JXN4E5'
    },
    {
      status: 210,
      sku: 'PM0079517',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320176',
      rtl_batch_array: '6320176',
      name_search: 'COALBA',
      name: 'Cornus alba',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 4700,
      chnn_stock_retail: 4700,
      sppl_prcp: 1.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJ5HW9S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJ5HW9S8'
    },
    {
      status: 210,
      sku: 'PM0074338',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6311211',
      rtl_batch_array: '6311211, 6269845',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2274,
      chnn_stock_retail: 5062,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: 'T7H1852K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7H1852K'
    },
    {
      status: 210,
      sku: 'PM0079518',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320178',
      rtl_batch_array: '6320178',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 2341,
      chnn_stock_retail: 2341,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: '7BCEPDBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BCEPDBB'
    },
    {
      status: 210,
      sku: 'PM0079520',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320180',
      rtl_batch_array: '6320180',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1520,
      chnn_stock_retail: 1520,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: 'Y5YCXEH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5YCXEH1'
    },
    {
      status: 910,
      sku: 'PM0074339',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEOFTALE',
      name: "Pennisetum orientale 'Fairy Tales'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'ZY8YJSPH;CJRG5RYD',
      statusContent: 'dot',
      hash: '6B2ED1WT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6B2ED1WT'
    },
    {
      status: 210,
      sku: 'PM0065641',
      core_name: 'Plant',
      sppl_ean: '8720664850910',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165701',
      rtl_batch_array: '6165701',
      name_search: 'AJREPTAN',
      name: 'Ajuga reptans',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9TYY7NSC;6HNAC18P;TNHTW3RR;G2K2S9B3;2PN6WTKB;JWZZJZWY;7XZSTCJ9;52XE24L9;VESGJRR7;G7SWKYAA;E6546H5T;25JATYLH;1REAGRVZ;X655RS1W;VKPNLCDC;725JXKH4;LTZZHZV5;XEYXYGZ6;ET1T1235;NAJC85VE;94T4R859',
      statusContent: 'dot',
      hash: 'XJ264BX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJ264BX5'
    },
    {
      status: 210,
      sku: 'PM0079521',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320181',
      rtl_batch_array: '6320181',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 29750,
      chnn_stock_retail: 29750,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'R9J66T8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9J66T8A'
    },
    {
      status: 210,
      sku: 'PM0079522',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320182',
      rtl_batch_array: '6320182',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 6387,
      chnn_stock_retail: 6387,
      sppl_prcp: 1.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'NGXTB5YE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGXTB5YE'
    },
    {
      status: 210,
      sku: 'PM0074340',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269848',
      rtl_batch_array: '6315276, 6269848',
      name_search: 'PEALBUNN',
      name: "Pennisetum alopecuroides 'Little Bunny'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 276,
      chnn_stock_retail: 1596,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63YVZZCG;A1BBJ6YJ;B5P86S8E;TH6PBLYX;GPV1LGBL;BNVEJBEX;5A63TARS;XN9HZKN6;6VRVEYET;1LKNTYL8;9P4AJG3C;Z5EA68H7;BY43YC1W;T159CA6D;EYTDLB28;EN1ZK2DY;9BY9SNAW;776T7RDZ;JGPE9T29;WAY2L8DE',
      statusContent: 'dot',
      hash: 'Z564G86T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z564G86T'
    },
    {
      status: 210,
      sku: 'PM0079523',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320183',
      rtl_batch_array: '6320183',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 14200,
      chnn_stock_retail: 14200,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'PZWEBKSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZWEBKSW'
    },
    {
      status: 210,
      sku: 'PM0079524',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320185',
      rtl_batch_array: '6320185',
      name_search: 'RHCATHAR',
      name: 'Rhamnus cathartica',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 2017,
      chnn_stock_retail: 2017,
      sppl_prcp: 1.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XS7LHYE;B42JD4B2;LC374DCT;6GBV282T;8HZV1SA1;JD7RRX9A',
      statusContent: 'dot',
      hash: 'LA9Y8CGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA9Y8CGA'
    },
    {
      status: 210,
      sku: 'PM0079525',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320187',
      rtl_batch_array: '6320187',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1278,
      chnn_stock_retail: 1278,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: '97CNEKY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97CNEKY7'
    },
    {
      status: 210,
      sku: 'PM0079526',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320188',
      rtl_batch_array: '6320188',
      name_search: 'RINBNEVI',
      name: "Ribes nigrum 'Ben Nevis'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1390,
      chnn_stock_retail: 1390,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C87YJRLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C87YJRLH'
    },
    {
      status: 210,
      sku: 'PM0079527',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320189',
      rtl_batch_array: '6320189',
      name_search: 'SACAPREA',
      name: 'Salix caprea',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 3614,
      chnn_stock_retail: 3614,
      sppl_prcp: 1.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z5JJBKXY;5RHENR4S;GJT9ZNZD;TEL2Y4PD;59RDC12H;EK43CG9T;XZTNXGL4;Z3CETTT1',
      statusContent: 'dot',
      hash: '2VEX32AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VEX32AE'
    },
    {
      status: 210,
      sku: 'PM0079528',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320190',
      rtl_batch_array: '6320190',
      name_search: 'SAPURPUR',
      name: 'Salix purpurea',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 1885,
      chnn_stock_retail: 1885,
      sppl_prcp: 1.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XLCZPK7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLCZPK7Z'
    },
    {
      status: 210,
      sku: 'PM0079529',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320191',
      rtl_batch_array: '6320191',
      name_search: 'SAROSMAR',
      name: 'Salix rosmarinifolia',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2419,
      chnn_stock_retail: 2419,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EZVB3GYK;NRAT57N3;HSV6SWAA;BNZ5AZJC;CRJR24D3',
      statusContent: 'dot',
      hash: '2V6ZZ2C6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V6ZZ2C6'
    },
    {
      status: 210,
      sku: 'PM0079530',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320192',
      rtl_batch_array: '6320192',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '020040C2',
      rng_range_identifier: 'H020040C2',
      rng_range_description: 'H20 cm 40 cm C2',
      sppl_stock_available: 1470,
      chnn_stock_retail: 1470,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'W7KNB8XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7KNB8XY'
    },
    {
      status: 210,
      sku: 'PM0079531',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320193',
      rtl_batch_array: '6320193',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'C36K7633',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C36K7633'
    },
    {
      status: 910,
      sku: 'PM0079532',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: '7D2RXKTS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7D2RXKTS'
    },
    {
      status: 810,
      sku: 'PM0082169',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346245',
      rtl_batch_array: '6346245',
      name_search: 'CHLCOLUM',
      name: "Chamaecyparis lawsoniana 'Columnaris'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 8.569,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZ65YEBT;NABYNC2K;1AGEPTPC;9VL4TXHX;949C1DA4;L676NZGD;233NPP8C;54BG9RKD;NXE71H79;832DA6RK;C8CWKS7S;D2ZK24VK;G1W87X9S;AAZ6HGPP',
      statusContent: 'dot',
      hash: 'AXPS3VD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXPS3VD1'
    },
    {
      status: 910,
      sku: 'PM0082170',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHLCOLUM',
      name: "Chamaecyparis lawsoniana 'Columnaris'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'YZ65YEBT;NABYNC2K;1AGEPTPC;9VL4TXHX;949C1DA4;L676NZGD;233NPP8C;54BG9RKD;NXE71H79;832DA6RK;C8CWKS7S;D2ZK24VK;G1W87X9S;AAZ6HGPP',
      statusContent: 'dot',
      hash: 'DDVDWDG6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DDVDWDG6'
    },
    {
      status: 210,
      sku: 'PM0079519',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320179',
      rtl_batch_array: '6320179',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 482,
      chnn_stock_retail: 482,
      sppl_prcp: 1.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'Z1K7ZA18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1K7ZA18'
    },
    {
      status: 210,
      sku: 'PM0079543',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324730',
      rtl_batch_array: '6324730, 6329879, 6352624',
      name_search: 'BRMHCREA',
      name: "Brunnera macrophylla 'Hadspen Cream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 592,
      chnn_stock_retail: 1736,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TECVHSXT',
      statusContent: 'dot',
      hash: 'B27PR5PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B27PR5PV'
    },
    {
      status: 210,
      sku: 'PM0079544',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324734',
      rtl_batch_array: '6324734',
      name_search: 'AGPPURPL',
      name: 'Agapanthus Poppin Purple',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 622,
      chnn_stock_retail: 622,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6A8ZV9CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A8ZV9CH'
    },
    {
      status: 210,
      sku: 'PM0074345',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269857',
      rtl_batch_array: '6252578, 6269857',
      name_search: 'GEAFOLKA',
      name: "Geranium 'Ann Folkard'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 467,
      chnn_stock_retail: 1429,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K7XXYWAX;YN47Y245;J6CTRK9C;94K3THGA',
      statusContent: 'dot',
      hash: '21N737S5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21N737S5'
    },
    {
      status: 210,
      sku: 'PM0079545',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324736',
      rtl_batch_array: '6324736',
      name_search: 'ECPSAFFR',
      name: 'Echinacea Prima Saffron',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 207,
      chnn_stock_retail: 207,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PTWEZBCE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTWEZBCE'
    },
    {
      status: 210,
      sku: 'PM0079561',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329828',
      rtl_batch_array: '6329828, 6321895',
      name_search: 'ASNBPORZ',
      name: "Aster novi-belgii 'Porzellan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 399,
      chnn_stock_retail: 466,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B39WP8ZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B39WP8ZX'
    },
    {
      status: 210,
      sku: 'PM0079562',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329829',
      rtl_batch_array: '6329829',
      name_search: 'ASTENBRO',
      name: "Aster novi-belgii 'Rosenquarz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D998R7YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D998R7YC'
    },
    {
      status: 210,
      sku: 'PM0069545',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349124',
      rtl_batch_array: '6349124, 6301669, 6350571',
      name_search: 'IRENSATA',
      name: 'Iris ensata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1269,
      chnn_stock_retail: 2084,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZH3E9Z6D;D7TJBWPS;TXYNW13A;115K1AJD;S89S3WEJ;L25NVASJ',
      statusContent: 'dot',
      hash: 'YJ9SCATS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ9SCATS'
    },
    {
      status: 210,
      sku: 'PM0074343',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269855',
      rtl_batch_array: '6269855',
      name_search: 'GEPSAMOB',
      name: "Geranium phaeum 'Samobor'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 676,
      chnn_stock_retail: 676,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTYASSVB;PYXVCNTK',
      statusContent: 'dot',
      hash: 'PXNHRT33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXNHRT33'
    },
    {
      status: 210,
      sku: 'PM0079563',
      core_name: 'Plant',
      sppl_ean: '8720664852853',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6077481',
      rtl_batch_array: '6329830, 6077481',
      name_search: 'ASNBSBAL',
      name: "Aster novi-belgii 'Sarah Ballard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VX5Z9H3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VX5Z9H3J'
    },
    {
      status: 210,
      sku: 'PM0079564',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352611',
      rtl_batch_array: '6329831, 6352611',
      name_search: 'ANDIOICA',
      name: 'Antennaria dioica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 402,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C49EJCTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C49EJCTW'
    },
    {
      status: 210,
      sku: 'PM0079565',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329832',
      rtl_batch_array: '6329832',
      name_search: 'ANDADUGU',
      name: "Antennaria dioica 'Alex Duguid'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 348,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DY1PPHAB;4ZY478D4',
      statusContent: 'dot',
      hash: 'J86RZKJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J86RZKJW'
    },
    {
      status: 210,
      sku: 'PM0074344',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269856',
      rtl_batch_array: '6269856, 6254743',
      name_search: 'GESALBUM',
      name: "Geranium sanguineum 'Album'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2523,
      chnn_stock_retail: 2993,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWW894AG;H339Y9JL;BJAD93HB;8N3G6J8Y;BN3YAHX6;K3D4B4Z8;5JT3ZVH6',
      statusContent: 'dot',
      hash: 'N7VY9XLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7VY9XLA'
    },
    {
      status: 210,
      sku: 'PM0074341',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269849',
      rtl_batch_array: '6254805, 6269849, 6383869',
      name_search: 'PEVILLOS',
      name: 'Pennisetum villosum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 2654,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKGVTLJC;X4HLJ75H;ESP64PH2;EE3RV5CH;H78GL358;1LJELSL9;D4AJ6AXD',
      statusContent: 'dot',
      hash: 'SKAHAZ7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKAHAZ7A'
    },
    {
      status: 210,
      sku: 'PM0069186',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244298',
      rtl_batch_array: '6244298, 6252752',
      name_search: 'SANBLAUK',
      name: "Salvia nemorosa 'Blauk\u00f6nigin'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4521,
      chnn_stock_retail: 4914,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XLB9HYYP;GX5WZWPZ;GGTLJNCR;KG43N8DW;E2Z14LPE;9VVCPLNG;TBN7KPWK;KP23H1LJ;TJCPSPPC;5LCAGGW4;RZ183BET;6PZ3L3D3',
      statusContent: 'dot',
      hash: '14Z7PTDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14Z7PTDX'
    },
    {
      status: 810,
      sku: 'PM0079566',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329834',
      rtl_batch_array: '6329834',
      name_search: 'ANPLANTA',
      name: 'Antennaria plantaginifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SARA7SJG',
      statusContent: 'dot',
      hash: 'V96GT7H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V96GT7H1'
    },
    {
      status: 210,
      sku: 'PM0079567',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329837',
      rtl_batch_array: '6329837',
      name_search: 'ARREVARI',
      name: "Arrhenatherum elatius 'Variegatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3R5NLYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3R5NLYJ'
    },
    {
      status: 210,
      sku: 'PM0079568',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301421',
      rtl_batch_array: '6329840, 6333182, 6301421',
      name_search: 'ASTUBERO',
      name: 'Asclepias tuberosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 502,
      chnn_stock_retail: 2259,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDP2T8YL;WZ5Y6RP1;HXLR17E5;7B5XP645;5DD4JYV9;B9DRWJ3R;BHETSTAL;KX3HS7TJ;HNEPRAWV;SLBYTD9P;NV7ZYVDG;APV1TJD3',
      statusContent: 'dot',
      hash: 'VEE3A6GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VEE3A6GX'
    },
    {
      status: 210,
      sku: 'PM0069188',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254832',
      rtl_batch_array: '6254832',
      name_search: 'SAOCYMOI',
      name: 'Saponaria ocymoides',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44G456NP;J4GVWAGN;2T52DK6X;W8BXDPRG;H79CJ3S7;PW49YZBE;X8V5YY4T;N57VNNKR;LLS64XDV;S8Z496CB',
      statusContent: 'dot',
      hash: '115LLVVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '115LLVVD'
    },
    {
      status: 210,
      sku: 'PM0079569',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329841',
      rtl_batch_array: '6329841',
      name_search: 'ASLIBURN',
      name: 'Asphodeline liburnica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGL65BVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGL65BVK'
    },
    {
      status: 910,
      sku: 'PM0082171',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHLCOLUM',
      name: "Chamaecyparis lawsoniana 'Columnaris'",
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      imageCore:
        'YZ65YEBT;NABYNC2K;1AGEPTPC;9VL4TXHX;949C1DA4;L676NZGD;233NPP8C;54BG9RKD;NXE71H79;832DA6RK;C8CWKS7S;D2ZK24VK;G1W87X9S;AAZ6HGPP',
      statusContent: 'dot',
      hash: 'XRG2DZHY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XRG2DZHY'
    },
    {
      status: 210,
      sku: 'PM0082172',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346249',
      rtl_batch_array: '6346249',
      name_search: 'CHLYVONN',
      name: "Chamaecyparis lawsoniana 'Yvonne'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GGE33AK;2JGSGH6N;Z71DCRCA;ZG76V8LD',
      statusContent: 'dot',
      hash: 'EASEZ38R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EASEZ38R'
    },
    {
      status: 210,
      sku: 'PM0082173',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346250',
      rtl_batch_array: '6346250',
      name_search: 'CHLYVONN',
      name: "Chamaecyparis lawsoniana 'Yvonne'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.589,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GGE33AK;2JGSGH6N;Z71DCRCA;ZG76V8LD',
      statusContent: 'dot',
      hash: 'BRYCZ6D6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRYCZ6D6'
    },
    {
      status: 210,
      sku: 'PM0069192',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254846',
      rtl_batch_array: '6254846',
      name_search: 'SOPRAECO',
      name: "Solidago 'Praecox'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 233,
      chnn_stock_retail: 233,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1PTY7GW4',
      statusContent: 'dot',
      hash: 'Y63HNZDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y63HNZDP'
    },
    {
      status: 210,
      sku: 'PM0074346',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269859',
      rtl_batch_array: '6269859',
      name_search: 'SPHETERO',
      name: 'Sporobolus heterolepis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2370,
      chnn_stock_retail: 2370,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3852BXC;89GK8LAW',
      statusContent: 'dot',
      hash: 'E2VDE58W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2VDE58W'
    },
    {
      status: 210,
      sku: 'PM0082174',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346251',
      rtl_batch_array: '6346251',
      name_search: 'CHLYVONN',
      name: "Chamaecyparis lawsoniana 'Yvonne'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 12.385,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GGE33AK;2JGSGH6N;Z71DCRCA;ZG76V8LD',
      statusContent: 'dot',
      hash: '9LNC6ZGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LNC6ZGS'
    },
    {
      status: 210,
      sku: 'PM0082175',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346252',
      rtl_batch_array: '6346252',
      name_search: 'CHLYVONN',
      name: "Chamaecyparis lawsoniana 'Yvonne'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 17.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GGE33AK;2JGSGH6N;Z71DCRCA;ZG76V8LD',
      statusContent: 'dot',
      hash: 'EY824H75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY824H75'
    },
    {
      status: 910,
      sku: 'PM0082176',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHLSTARD',
      name: "Chamaecyparis lawsoniana 'Stardust'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'Z4NKRN9D;63ZC9SX9;ZVCCY891;PVL3LLYR;KHW5EDJZ;GB732SRN;VZED841A;7A7WDSA1;PSEYXY2X;SCJA9YXY;92J1R18V;E1TNWD1T;NDXNC5PL;8N15TVAC',
      statusContent: 'dot',
      hash: 'VRPKKSZY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VRPKKSZY'
    },
    {
      status: 910,
      sku: 'PM0082177',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHLSTARD',
      name: "Chamaecyparis lawsoniana 'Stardust'",
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'Z4NKRN9D;63ZC9SX9;ZVCCY891;PVL3LLYR;KHW5EDJZ;GB732SRN;VZED841A;7A7WDSA1;PSEYXY2X;SCJA9YXY;92J1R18V;E1TNWD1T;NDXNC5PL;8N15TVAC',
      statusContent: 'dot',
      hash: 'JCRNTBD8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JCRNTBD8'
    },
    {
      status: 910,
      sku: 'PM0082178',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHLSTARD',
      name: "Chamaecyparis lawsoniana 'Stardust'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'Z4NKRN9D;63ZC9SX9;ZVCCY891;PVL3LLYR;KHW5EDJZ;GB732SRN;VZED841A;7A7WDSA1;PSEYXY2X;SCJA9YXY;92J1R18V;E1TNWD1T;NDXNC5PL;8N15TVAC',
      statusContent: 'dot',
      hash: 'BGSVB9K1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BGSVB9K1'
    },
    {
      status: 210,
      sku: 'PM0082179',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346256',
      rtl_batch_array: '6346256',
      name_search: 'CHLSTARD',
      name: "Chamaecyparis lawsoniana 'Stardust'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 13.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z4NKRN9D;63ZC9SX9;ZVCCY891;PVL3LLYR;KHW5EDJZ;GB732SRN;VZED841A;7A7WDSA1;PSEYXY2X;SCJA9YXY;92J1R18V;E1TNWD1T;NDXNC5PL;8N15TVAC',
      statusContent: 'dot',
      hash: 'YAH7X6ZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAH7X6ZN'
    },
    {
      status: 910,
      sku: 'PM0082180',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'LY75SGVX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LY75SGVX'
    },
    {
      status: 910,
      sku: 'PM0082181',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'RS4G6LS8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RS4G6LS8'
    },
    {
      status: 910,
      sku: 'PM0082182',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'SAHKB7SA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SAHKB7SA'
    },
    {
      status: 910,
      sku: 'PM0082183',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'BKVG3SG2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BKVG3SG2'
    },
    {
      status: 910,
      sku: 'PM0082184',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'GYD7GCDV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GYD7GCDV'
    },
    {
      status: 210,
      sku: 'PM0082185',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346262',
      rtl_batch_array: '6346262',
      name_search: 'CULCASTL',
      name: "Cupressocyparis leylandii 'Castlewellan'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 7.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XHDEAYKT;8CDV7C9S;T8L67KVE;RC4BCP1B;WREYB9RJ;BA2CVSE2;P2HB9TY1;Z8NPSHYL;VTZ9965W;J83ZA789;2BNC7PKG;YV8DYAG8;R7AB7HR1;H5R1R5LY;4WVD73ST;N3PNG1T4;4WBDLCDW;6T7ZXNZL;R8EX2C4A',
      statusContent: 'dot',
      hash: 'DC5RRGZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC5RRGZS'
    },
    {
      status: 210,
      sku: 'PM0082186',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346263',
      rtl_batch_array: '6346263',
      name_search: 'CULCASTL',
      name: "Cupressocyparis leylandii 'Castlewellan'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 15.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XHDEAYKT;8CDV7C9S;T8L67KVE;RC4BCP1B;WREYB9RJ;BA2CVSE2;P2HB9TY1;Z8NPSHYL;VTZ9965W;J83ZA789;2BNC7PKG;YV8DYAG8;R7AB7HR1;H5R1R5LY;4WVD73ST;N3PNG1T4;4WBDLCDW;6T7ZXNZL;R8EX2C4A',
      statusContent: 'dot',
      hash: 'GXCXV8KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXCXV8KY'
    },
    {
      status: 210,
      sku: 'PM0069683',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229425',
      rtl_batch_array: '6229425',
      name_search: 'POCBBLUE',
      name: "Polemonium caeruleum 'Bambino Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7608,
      chnn_stock_retail: 7608,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1GKZ78ZK;DRYWWRVP',
      statusContent: 'dot',
      hash: 'VPTRV99R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPTRV99R'
    },
    {
      status: 910,
      sku: 'PM0082187',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JUCARNOL',
      name: "Juniperus communis 'Arnold'",
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      statusContent: 'dot',
      hash: 'S29HZC1J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S29HZC1J'
    },
    {
      status: 210,
      sku: 'PM0082188',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346266',
      rtl_batch_array: '6346266',
      name_search: 'JUCHIBER',
      name: "Juniperus communis 'Hibernica'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 5.965,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4BTW9R11;HNYBVE47;C97K2HYD;R2ABV3Z5;WPA9J1KG;Z8KE852X;WH4BT2B1;H3GY95XR;LB5CKVE7;2TNDD51H;HSPR8EXC;PRGRNE5X;WV1668NW;ZCJLTSSY;HG61KP77;S821K8WR;WBWD9XR3;64771STS',
      statusContent: 'dot',
      hash: 'H314EDLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H314EDLC'
    },
    {
      status: 210,
      sku: 'PM0082189',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346267',
      rtl_batch_array: '6346267',
      name_search: 'JUCHIBER',
      name: "Juniperus communis 'Hibernica'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 10.354,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4BTW9R11;HNYBVE47;C97K2HYD;R2ABV3Z5;WPA9J1KG;Z8KE852X;WH4BT2B1;H3GY95XR;LB5CKVE7;2TNDD51H;HSPR8EXC;PRGRNE5X;WV1668NW;ZCJLTSSY;HG61KP77;S821K8WR;WBWD9XR3;64771STS',
      statusContent: 'dot',
      hash: '94TE7RBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94TE7RBG'
    },
    {
      status: 210,
      sku: 'PM0082190',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346268',
      rtl_batch_array: '6346268',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'AHY5DKER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHY5DKER'
    },
    {
      status: 210,
      sku: 'PM0082208',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346287',
      rtl_batch_array: '6346287',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 1490,
      chnn_stock_retail: 1490,
      sppl_prcp: 10.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '9H7S92GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9H7S92GG'
    },
    {
      status: 210,
      sku: 'PM0082191',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346269',
      rtl_batch_array: '6346269',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'LETYNSZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LETYNSZD'
    },
    {
      status: 210,
      sku: 'PM0082192',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346270',
      rtl_batch_array: '6346270',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 2.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: '42PSZGL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42PSZGL9'
    },
    {
      status: 210,
      sku: 'PM0082193',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346271',
      rtl_batch_array: '6346271',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_prcp: 3.703,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: '7JJ51CEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JJ51CEW'
    },
    {
      status: 210,
      sku: 'PM0050143',
      core_name: 'Plant',
      sppl_ean: '8720349462988',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582268',
      rtl_batch_array: '5582268',
      name_search: 'PHOTWINE',
      name: 'Physocarpus opulifolius Tiny Wine',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CL1TTKDB;8RTXK5C9;6EH61CE3',
      statusContent: 'dot',
      hash: '2CY78N6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CY78N6R'
    },
    {
      status: 210,
      sku: 'PM0082198',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346277',
      rtl_batch_array: '6346277',
      name_search: 'THOPCOMP',
      name: "Thuja occidentalis 'Pyramidalis Compacta'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPT6V4J9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPT6V4J9'
    },
    {
      status: 210,
      sku: 'PM0082199',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346278',
      rtl_batch_array: '6346278',
      name_search: 'THOPCOMP',
      name: "Thuja occidentalis 'Pyramidalis Compacta'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.978,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JKAL5ZSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKAL5ZSZ'
    },
    {
      status: 210,
      sku: 'PM0082200',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346279',
      rtl_batch_array: '6346279',
      name_search: 'THOPCOMP',
      name: "Thuja occidentalis 'Pyramidalis Compacta'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 7.549,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NKGKBWNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKGKBWNB'
    },
    {
      status: 210,
      sku: 'PM0082201',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346280',
      rtl_batch_array: '6346280',
      name_search: 'THOPCOMP',
      name: "Thuja occidentalis 'Pyramidalis Compacta'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9J22SZRB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J22SZRB'
    },
    {
      status: 210,
      sku: 'PM0082202',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346281',
      rtl_batch_array: '6346281',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 2.93,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'CSPZLXBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSPZLXBA'
    },
    {
      status: 810,
      sku: 'PM0007271',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307553',
      rtl_batch_array: '6307553',
      name_search: 'MALMERRI',
      name: "Magnolia loebneri 'Merrill'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_prcp: 27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VRCSEY1P;HYNLPAJ9;VBZG2KWE;SSWZEW5G;VN91J1TX;NHB2VBBT;XSZYENPY;1264ZLHH;H83G8G42;11HY5ACJ;CKYWENT5;TK44494D',
      statusContent: 'dot',
      hash: '2EBKR22J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EBKR22J'
    },
    {
      status: 210,
      sku: 'PM0059627',
      core_name: 'Plant',
      sppl_ean: '8720664688124',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '5764876',
      rtl_batch_array: '5764876',
      name_search: 'ECPHDANC',
      name: "Echinacea pallida 'Hula Dancer'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ANSYC',
      statusContent: 'dot',
      hash: '419XS1NC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '419XS1NC'
    },
    {
      status: 210,
      sku: 'PM0082203',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346282',
      rtl_batch_array: '6346282',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 3.415,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'B12D586T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B12D586T'
    },
    {
      status: 210,
      sku: 'PM0082204',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346283',
      rtl_batch_array: '6346283',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 5.095,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'V9WNHP57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9WNHP57'
    },
    {
      status: 210,
      sku: 'PM0066648',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 3,
      btch_active_retail: '6239643',
      rtl_batch_array: '6239643, 6373177, 6356688',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 225,
      chnn_stock_retail: 8104,
      sppl_prcp: 4.151,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '42LY1TDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42LY1TDZ'
    },
    {
      status: 210,
      sku: 'PM0082205',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346284',
      rtl_batch_array: '6346284',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 6.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '7ES4L98H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ES4L98H'
    },
    {
      status: 210,
      sku: 'PM0082206',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346285',
      rtl_batch_array: '6346285',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 8.155,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'R98LW11S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R98LW11S'
    },
    {
      status: 210,
      sku: 'PM0082194',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346272',
      rtl_batch_array: '6346272, 6358895',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2650,
      sppl_prcp: 5.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: '2593DNHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2593DNHG'
    },
    {
      status: 210,
      sku: 'PM0082195',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346273',
      rtl_batch_array: '6346273',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 8.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'B47S4RX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B47S4RX5'
    },
    {
      status: 210,
      sku: 'PM0027758',
      core_name: 'Plant',
      sppl_ean: '8720349491018',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905602',
      rtl_batch_array: '5905602',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 924,
      chnn_stock_retail: 924,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: '8VB22SWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VB22SWG'
    },
    {
      status: 210,
      sku: 'PM0029020',
      core_name: 'Plant',
      sppl_ean: '8720353011509',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '4894386',
      rtl_batch_array: '4894386',
      name_search: 'PAVROTST',
      name: "Panicum virgatum 'Rotstrahlbusch'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '465RJY8V;GLTPRJWC;5PDC2D6E;EX449DD1;Y91VH24K;B2E85B5G;E2Z6YKLP;S8ZTES9R;R7XVP3LX;4CPBG92G;9PR892J8;YJC76RY8;CG53XZ3S',
      statusContent: 'dot',
      hash: 'ARTZRXTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARTZRXTK'
    },
    {
      status: 210,
      sku: 'PM0024026',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336139',
      rtl_batch_array: '3216653, 6336139',
      name_search: 'EUJKATHY',
      name: "Euonymus japonicus 'Kathy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 8261,
      chnn_stock_retail: 8661,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7AYYBDZ9;PJ1WE347;71RR6VTP;4ZS6JS6R;WNW9N6BN;6RG9HR69;B81979LC;2PB1Y5J8;C95PJGX2;V71HT66P;RXA9CAYR;ERCT8HPH;6RK2JS7T;SS2T58C4;LDYV8ZYT;1HSLXJ6Z;N5JDJ4LT',
      statusContent: 'dot',
      hash: 'C83985ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C83985ED'
    },
    {
      status: 210,
      sku: 'PM0059912',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317833',
      rtl_batch_array: '6129733, 6317833',
      name_search: 'ASPLUTET',
      name: "Aster pyrenaeus 'Lutetia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1746,
      chnn_stock_retail: 3865,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ADCXD1G4;42D3T3EX',
      statusContent: 'dot',
      hash: 'G518HDXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G518HDXT'
    },
    {
      status: 210,
      sku: 'PM0005584',
      core_name: 'Plant',
      sppl_ean: '8720349426195',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5756049',
      rtl_batch_array: '5756049',
      name_search: 'PRVIALII',
      name: 'Primula vialii',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2562,
      chnn_stock_retail: 2562,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V9TLHN7H;S5PXG4TS;RYZBG3BB;ZDG2926K;7173J2EX;L6TVYECG;1C8X8K2N;45ZARZ8W;STSHJPLE;XR74C53K;6NW923XL;TXWA6Z19;JH193TN2',
      statusContent: 'dot',
      hash: 'J1AAEJX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1AAEJX8'
    },
    {
      status: 210,
      sku: 'PM0082196',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346274',
      rtl_batch_array: '6346274',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '180200KL',
      rng_range_identifier: 'H180200RB',
      rng_range_description: 'H180 cm 200 cm Root ball',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: '5LAW5ATW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LAW5ATW'
    },
    {
      status: 210,
      sku: 'PM0082197',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346275',
      rtl_batch_array: '6346275',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '200220KL',
      rng_range_identifier: 'H200220RB',
      rng_range_description: 'H200 cm 220 cm Root ball',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 14.858,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'SWPE7V4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWPE7V4W'
    },
    {
      status: 910,
      sku: 'PM0050156',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307748',
      rtl_batch_array: '6307748',
      name_search: 'PIMOPHIR',
      name: "Pinus mugo 'Ophir'",
      sppl_size_code: '030040C12',
      rng_range_identifier: 'H030040C12',
      rng_range_description: 'H30 cm 40 cm C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 23.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BZCGVL16',
      statusContent: 'dot',
      hash: 'N8SBYDGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8SBYDGK'
    },
    {
      status: 210,
      sku: 'PM0064335',
      core_name: 'Plant',
      sppl_ean: '8720664850323',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149685',
      rtl_batch_array: '6149685',
      name_search: 'ACMLACHS',
      name: "Achillea millefolium 'Lachssch\u00f6nheit'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1074,
      chnn_stock_retail: 1074,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2B255WED;R4RXH1KR;1P3GWLTW;B4JTVXVY;BEV1BAX8;85KX18L9;CPLZ2EE7;J2NBTKE5;6XYRYXDL;DZAC6K55;PRYYDKH1;HBPA94EL;EG3PV4H2;1CS9R8TK;L1D62K65;LY8DNJ12',
      statusContent: 'dot',
      hash: 'NHV6R6V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHV6R6V4'
    },
    {
      status: 210,
      sku: 'PM0003966',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298501',
      rtl_batch_array: '6298501',
      name_search: 'DACCUPID',
      name: "Daboecia cantabrica 'Cupido'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1WLLTGT',
      statusContent: 'dot',
      hash: '16A39DDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16A39DDN'
    },
    {
      status: 210,
      sku: 'PM0029705',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298499',
      rtl_batch_array: '6298499',
      name_search: 'DACATROP',
      name: "Daboecia cantabrica 'Atropurpurea'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2AZKXYV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AZKXYV1'
    },
    {
      status: 210,
      sku: 'PM0018566',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253098',
      rtl_batch_array: '6253098',
      name_search: 'HEPIMELE',
      name: 'Hebe pimeleoides',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 483,
      chnn_stock_retail: 483,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBGCE1GB;T317JGSA;JNYZS1HK;PSTACL85;LWV1PEGG;YW85LL3K;XJG6ZTH5;JNE5DE7B;BKHKPWZK;GDBB2BV8;J7884SCS;H824C7XA;E1GNXSWJ;89BX6RRX;1X5ZYRLJ;RHA6XL49',
      statusContent: 'dot',
      hash: 'DVJWSNWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVJWSNWR'
    },
    {
      status: 210,
      sku: 'PM0008561',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298500',
      rtl_batch_array: '6298500',
      name_search: 'DACCWHIT',
      name: "Daboecia cantabrica 'Creeping White'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4YS8BCH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YS8BCH6'
    },
    {
      status: 210,
      sku: 'PM0059914',
      core_name: 'Plant',
      sppl_ean: '8720664854598',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129738',
      rtl_batch_array: '6129738',
      name_search: 'CAPALBA',
      name: 'Caltha palustris alba',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VPPVN998;LJ4EPLJV;J5YSW219;WPK9K349;RDTYCG11',
      statusContent: 'dot',
      hash: '812V1EG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '812V1EG9'
    },
    {
      status: 210,
      sku: 'PM0071408',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229143',
      rtl_batch_array: '6229143',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      statusContent: 'dot',
      hash: 'GGTJEY7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGTJEY7P'
    },
    {
      status: 210,
      sku: 'PM0059920',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349958',
      rtl_batch_array: '6349958',
      name_search: 'COVTWEET',
      name: "Coreopsis verticillata 'Tweety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '95SCD5ZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95SCD5ZR'
    },
    {
      status: 210,
      sku: 'PM0079546',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324921',
      rtl_batch_array: '6324921',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_prcp: 8.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'P8KV7REH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8KV7REH'
    },
    {
      status: 210,
      sku: 'PM0079547',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324922',
      rtl_batch_array: '6324922',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C4',
      rng_range_identifier: 'H080100C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'JWCPWBY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWCPWBY1'
    },
    {
      status: 210,
      sku: 'PM0079548',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324923',
      rtl_batch_array: '6324923',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '175200C5',
      rng_range_identifier: 'H175200C5',
      rng_range_description: 'H175 cm 200 cm C5',
      sppl_stock_available: 545,
      chnn_stock_retail: 545,
      sppl_prcp: 7.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'XP42R2CY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP42R2CY'
    },
    {
      status: 210,
      sku: 'PM0079549',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324924',
      rtl_batch_array: '6324924',
      name_search: 'STCALAMA',
      name: 'Stipa calamagrostis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 409,
      chnn_stock_retail: 409,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '76DHGHNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76DHGHNG'
    },
    {
      status: 210,
      sku: 'PM0079570',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329842',
      rtl_batch_array: '6329842, 6349759',
      name_search: 'ASLUTEA',
      name: 'Asphodeline lutea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 969,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BWAJKB1A;28YY3H2H;W2CBLNGX',
      statusContent: 'dot',
      hash: '45KALTZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45KALTZX'
    },
    {
      status: 210,
      sku: 'PM0079571',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329843',
      rtl_batch_array: '6329843',
      name_search: 'ASCERASI',
      name: 'Asphodelus cerasiferus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GPA73V2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPA73V2R'
    },
    {
      status: 810,
      sku: 'PM0079572',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349760',
      rtl_batch_array: '6329845, 6349760',
      name_search: 'ASAHSCHM',
      name: "Aster ageratoides 'Harry Schmidt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 322,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SK52WDVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK52WDVA'
    },
    {
      status: 210,
      sku: 'PM0082232',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6287618',
      rtl_batch_array: '6346312, 6287618',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 15500,
      chnn_stock_retail: 20500,
      sppl_prcp: 8.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'H9AHP481',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9AHP481'
    },
    {
      status: 210,
      sku: 'PM0082234',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346649',
      rtl_batch_array: '6346314, 6346649',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1500,
      sppl_prcp: 24.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'LZ5EBN68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ5EBN68'
    },
    {
      status: 210,
      sku: 'PM0079573',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333184',
      rtl_batch_array: '6329846, 6333184',
      name_search: 'ASAALBUS',
      name: "Aster alpinus 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5088,
      chnn_stock_retail: 5273,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LCE6WVRZ',
      statusContent: 'dot',
      hash: 'DCVKTWVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCVKTWVP'
    },
    {
      status: 210,
      sku: 'PM0074349',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270299',
      rtl_batch_array: '6270299',
      name_search: 'MATAPPLE',
      name: 'Malus transitoria Appletini',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8KPA1XP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KPA1XP2'
    },
    {
      status: 210,
      sku: 'PM0079574',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329848',
      rtl_batch_array: '6329848',
      name_search: 'ASADANZI',
      name: "Aster amellus 'Danzig'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5W3XDCS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W3XDCS8'
    },
    {
      status: 210,
      sku: 'PM0074350',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270300',
      rtl_batch_array: '6270300',
      name_search: 'PEAFIREW',
      name: "Pennisetum advena 'Fireworks'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1431,
      chnn_stock_retail: 1431,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '41WDP4KL;EDKDXJ35;PBPRYJ35;YRLA5EPJ;24VHJ1LL;ATK3J5RZ;BSHR47RP;A13EY98K;KXC4E3KN;H5CXRHRJ;6V8H93KB;STJABS11;EPH4P72T;CB9H5Y5K;X7AHD1JX',
      statusContent: 'dot',
      hash: 'CZCBKKDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZCBKKDD'
    },
    {
      status: 210,
      sku: 'PM0079575',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302375',
      rtl_batch_array: '6329849, 6302375',
      name_search: 'ASARERFU',
      name: "Aster amellus 'Rosa Erf\u00fcllung'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 476,
      chnn_stock_retail: 640,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJ86Z329',
      statusContent: 'dot',
      hash: '8H9SEG7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H9SEG7V'
    },
    {
      status: 210,
      sku: 'PM0079576',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329850',
      rtl_batch_array: '6329850',
      name_search: 'ASAFREIB',
      name: "Aster amethystinus 'Freiburg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 346,
      chnn_stock_retail: 346,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D85ZPE4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D85ZPE4D'
    },
    {
      status: 210,
      sku: 'PM0082211',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346290',
      rtl_batch_array: '6346290',
      name_search: 'THOGSMAR',
      name: 'Thuja occidentalis Golden Smaragd',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 5.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YXRWAKD;AS33KGY6;5JZEB95L;28KYJJCV;TCZ8BWKR',
      statusContent: 'dot',
      hash: 'A4K4DLRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4K4DLRV'
    },
    {
      status: 210,
      sku: 'PM0071405',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229139',
      rtl_batch_array: '6229139',
      name_search: 'EUJBRAVO',
      name: "Euonymus japonicus 'Bravo'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 11.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L3C6V2SX;G7P4T4LT;E226CW6B;41DRYLEH;4D7E1XKE;P1RVCLXX;BNWPK6JD;K6TDJSSS;5VADZL5X;J9W8C26X;47YXL32T',
      statusContent: 'dot',
      hash: 'W3ZHTWVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3ZHTWVR'
    },
    {
      status: 210,
      sku: 'PM0074352',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270304',
      rtl_batch_array: '6270304',
      name_search: 'CETMPOIN',
      name: "Ceanothus thyrsiflorus 'Millerton Point'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z4CX16K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4CX16K1'
    },
    {
      status: 210,
      sku: 'PM0079577',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329851',
      rtl_batch_array: '6329851',
      name_search: 'ASACHOIC',
      name: "Aster 'Anja's Choice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GT7H42CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT7H42CH'
    },
    {
      status: 210,
      sku: 'PM0079578',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329852',
      rtl_batch_array: '6329852',
      name_search: 'ASALEYS',
      name: "Aster 'Ann Leys'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 367,
      chnn_stock_retail: 367,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '911TP8JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '911TP8JD'
    },
    {
      status: 210,
      sku: 'PM0082212',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346291',
      rtl_batch_array: '6346291',
      name_search: 'THOGSMAR',
      name: 'Thuja occidentalis Golden Smaragd',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 7.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YXRWAKD;AS33KGY6;5JZEB95L;28KYJJCV;TCZ8BWKR',
      statusContent: 'dot',
      hash: 'NLPW9T42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLPW9T42'
    },
    {
      status: 210,
      sku: 'PM0079579',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329853',
      rtl_batch_array: '6329853',
      name_search: 'CACANADE',
      name: 'Calamagrostis canadensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KT8P9KYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KT8P9KYY'
    },
    {
      status: 210,
      sku: 'PM0079580',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349773',
      rtl_batch_array: '6329854, 6349773',
      name_search: 'CALGRAND',
      name: 'Calamintha grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 145,
      chnn_stock_retail: 971,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '24821WK6;72D9X74T',
      statusContent: 'dot',
      hash: '2APV8LAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2APV8LAL'
    },
    {
      status: 210,
      sku: 'PM0071407',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229142',
      rtl_batch_array: '6229142',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: '74ZXHA78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74ZXHA78'
    },
    {
      status: 210,
      sku: 'PM0082213',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346292',
      rtl_batch_array: '6346292',
      name_search: 'THOANANA',
      name: "Thuja occidentalis 'Aurea Nana'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.647,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPT3LGC',
      statusContent: 'dot',
      hash: '58J6RLVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58J6RLVC'
    },
    {
      status: 210,
      sku: 'PM0079581',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329856',
      rtl_batch_array: '6329856',
      name_search: 'ASNBSCHN',
      name: "Aster novi-belgii 'Schneeberg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VC494BLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VC494BLK'
    },
    {
      status: 210,
      sku: 'PM0074353',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270305',
      rtl_batch_array: '6270305',
      name_search: 'CETMPOIN',
      name: "Ceanothus thyrsiflorus 'Millerton Point'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 958,
      chnn_stock_retail: 958,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WYHDRAG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYHDRAG8'
    },
    {
      status: 210,
      sku: 'PM0079582',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329857',
      rtl_batch_array: '6329857',
      name_search: 'ASNZAUBE',
      name: "Aster n.-b. 'Zauberspiel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H6YND79X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6YND79X'
    },
    {
      status: 210,
      sku: 'PM0079583',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329858',
      rtl_batch_array: '6329858',
      name_search: 'ASNAABEN',
      name: "Aster novae-angliae 'Abendsonne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZGBVWZSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGBVWZSN'
    },
    {
      status: 210,
      sku: 'PM0079584',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370331',
      rtl_batch_array: '6370331, 6329859',
      name_search: 'ASNABPIN',
      name: "Aster novae-angliae 'Barr's Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 294,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K8XTNL87;G578ST9C;LJ1KJ4ZP;758C7K2S',
      statusContent: 'dot',
      hash: 'DPB2HNYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPB2HNYP'
    },
    {
      status: 210,
      sku: 'PM0079585',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329860',
      rtl_batch_array: '6329860',
      name_search: 'ASNARUBI',
      name: "Aster novae-angliae 'Rubinrot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRKS9ZVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRKS9ZVT'
    },
    {
      status: 210,
      sku: 'PM0079586',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329862',
      rtl_batch_array: '6329862',
      name_search: 'ASNAWBOW',
      name: "Aster novae-angliae 'W.Bowman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4EADCY4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EADCY4Z'
    },
    {
      status: 210,
      sku: 'PM0079587',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329864',
      rtl_batch_array: '6329864',
      name_search: 'ASVALTWE',
      name: "Aster versicolor 'Altweibersommer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NBYK48X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBYK48X1'
    },
    {
      status: 210,
      sku: 'PM0019668',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6226870',
      rtl_batch_array: '6226870, 6157609',
      name_search: 'HOELEGAN',
      name: "Hosta 'Elegans'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 10883,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7YREELR;6RXH8E7C',
      statusContent: 'dot',
      hash: '1KXWH58H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KXWH58H'
    },
    {
      status: 210,
      sku: 'PM0014185',
      core_name: 'Plant',
      sppl_ean: '8720626302808',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '5861505',
      rtl_batch_array: '5861505',
      name_search: 'HEPPAGEI',
      name: "Hebe pinguifolia 'Pagei'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 666,
      chnn_stock_retail: 666,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWV3S6NB;5B1CCRZS;HT4AH9T2',
      statusContent: 'dot',
      hash: '7G28DTP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7G28DTP9'
    },
    {
      status: 210,
      sku: 'PM0079588',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329866',
      rtl_batch_array: '6329866, 6338929',
      name_search: 'ASMSSTAR',
      name: "Astrantia major 'Snow Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 755,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BALEDXEY',
      statusContent: 'dot',
      hash: 'AT5HJ4NT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AT5HJ4NT'
    },
    {
      status: 210,
      sku: 'PM0082214',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346293',
      rtl_batch_array: '6346293',
      name_search: 'THPATROV',
      name: "Thuja plicata 'Atrovirens'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.978,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3A6JD6G;99JNGRDJ;ETS7G912',
      statusContent: 'dot',
      hash: 'RZLERSH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZLERSH1'
    },
    {
      status: 210,
      sku: 'PM0082215',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346294',
      rtl_batch_array: '6346294',
      name_search: 'THPATROV',
      name: "Thuja plicata 'Atrovirens'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3A6JD6G;99JNGRDJ;ETS7G912',
      statusContent: 'dot',
      hash: 'GHEP47SB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHEP47SB'
    },
    {
      status: 210,
      sku: 'PM0082216',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346295',
      rtl_batch_array: '6346295, 6358896',
      name_search: 'THPATROV',
      name: "Thuja plicata 'Atrovirens'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 600,
      chnn_stock_retail: 750,
      sppl_prcp: 8.824,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3A6JD6G;99JNGRDJ;ETS7G912',
      statusContent: 'dot',
      hash: 'AGXGKGBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGXGKGBG'
    },
    {
      status: 210,
      sku: 'PM0082217',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346296',
      rtl_batch_array: '6346296',
      name_search: 'THPATROV',
      name: "Thuja plicata 'Atrovirens'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 11.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J3A6JD6G;99JNGRDJ;ETS7G912',
      statusContent: 'dot',
      hash: '9DACN4SC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DACN4SC'
    },
    {
      status: 210,
      sku: 'PM0082218',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346297',
      rtl_batch_array: '6346297',
      name_search: 'THPEXCEL',
      name: "Thuja plicata 'Excelsa'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 7.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5DGZ5WT',
      statusContent: 'dot',
      hash: 'R2R7JEVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2R7JEVZ'
    },
    {
      status: 910,
      sku: 'PM0067501',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEMFWHIT',
      name: "Delphinium 'Magic Fountains White'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'VBT4NRYB',
      statusContent: 'dot',
      hash: 'GBA4K31L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GBA4K31L'
    },
    {
      status: 910,
      sku: 'PM0068442',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204867',
      rtl_batch_array: '6204867',
      name_search: 'WEFALEXA',
      name: "Weigela florida 'Alexandra'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 4.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6B2ZE5E',
      statusContent: 'dot',
      hash: 'HXS96WCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXS96WCL'
    },
    {
      status: 810,
      sku: 'PM0029018',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353321',
      rtl_batch_array: '6353321',
      name_search: 'SALVNBUM',
      name: "Salvia nemorosa 'Bumblesky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J78GGPKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J78GGPKG'
    },
    {
      status: 210,
      sku: 'PM0026225',
      core_name: 'Plant',
      sppl_ean: '8720664685970',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '4745657',
      rtl_batch_array: '4745657',
      name_search: 'COSGSTAR',
      name: "Cortaderia selloana 'Gold Star'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 134,
      chnn_stock_retail: 134,
      sppl_prcp: 5.502,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JDR971CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDR971CH'
    },
    {
      status: 210,
      sku: 'PM0082219',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346298',
      rtl_batch_array: '6346298',
      name_search: 'THPEXCEL',
      name: "Thuja plicata 'Excelsa'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 10.345,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5DGZ5WT',
      statusContent: 'dot',
      hash: 'Z74NPR48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z74NPR48'
    },
    {
      status: 210,
      sku: 'PM0082220',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346299',
      rtl_batch_array: '6346299',
      name_search: 'THPEXCEL',
      name: "Thuja plicata 'Excelsa'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 17.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5DGZ5WT',
      statusContent: 'dot',
      hash: 'C2DHDLPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2DHDLPB'
    },
    {
      status: 210,
      sku: 'PM0082221',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346300',
      rtl_batch_array: '6346300',
      name_search: 'THPEXCEL',
      name: "Thuja plicata 'Excelsa'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 20.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5DGZ5WT',
      statusContent: 'dot',
      hash: 'D62C37X5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D62C37X5'
    },
    {
      status: 210,
      sku: 'PM0082222',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346302',
      rtl_batch_array: '6346302',
      name_search: 'THPGELDE',
      name: "Thuja plicata 'Gelderland'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 11.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N23Y9G8B;C9T6V67H;JWB1ZB6D;4KWXGXT1;2YNBYNEH;N795AKAR;93EJ6XV1',
      statusContent: 'dot',
      hash: '6XXP6AXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XXP6AXB'
    },
    {
      status: 210,
      sku: 'PM0027648',
      core_name: 'Plant',
      sppl_ean: '8720626304567',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364171',
      rtl_batch_array: '5364171',
      name_search: 'HEGALE',
      name: "Heuchera 'Ginger Ale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 580,
      chnn_stock_retail: 580,
      sppl_order_minimum: 3,
      sppl_prcp: 1.541,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTBJA97E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTBJA97E'
    },
    {
      status: 210,
      sku: 'PM0014256',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164484',
      rtl_batch_array: '6164484',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'NXHL59TE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXHL59TE'
    },
    {
      status: 210,
      sku: 'PM0004766',
      core_name: 'Plant',
      sppl_ean: '8720349460618',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5358226',
      rtl_batch_array: '5358226',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2472,
      chnn_stock_retail: 2472,
      sppl_prcp: 1.371,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'WZ5SW1K9',
      statusContent: 'dot',
      hash: 'TTZDNV34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTZDNV34'
    },
    {
      status: 210,
      sku: 'PM0000705',
      core_name: 'Plant',
      sppl_ean: '8720664694736',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5501696',
      rtl_batch_array: '5501696',
      name_search: 'ILMGENTL',
      name: 'Ilex meserveae Gentle',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5021,
      chnn_stock_retail: 5021,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '8RXV4A6J',
      statusContent: 'dot',
      hash: 'V3E95NCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3E95NCJ'
    },
    {
      status: 910,
      sku: 'PM0008759',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'V4XKLSK7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V4XKLSK7'
    },
    {
      status: 210,
      sku: 'PM0064340',
      core_name: 'Plant',
      sppl_ean: '8720664857902',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149692',
      rtl_batch_array: '6149692',
      name_search: 'COGSONNE',
      name: "Coreopsis grandiflora 'Sonnenkind'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1068,
      chnn_stock_retail: 1068,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9ZJEGHV5;YD9E29XY;JD6ZYB85;VW897HJP;L5NBN1RL;R784N1LC;5E4ZVKT1;5T4BDVB4;BESY4RLA;7TS71399;RZ2GZACZ;R6495LV5;PPZ133PB',
      statusContent: 'dot',
      hash: 'VSLWJYS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSLWJYS2'
    },
    {
      status: 210,
      sku: 'PM0082223',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346303',
      rtl_batch_array: '6346303',
      name_search: 'THPGELDE',
      name: "Thuja plicata 'Gelderland'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 17.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N23Y9G8B;C9T6V67H;JWB1ZB6D;4KWXGXT1;2YNBYNEH;N795AKAR;93EJ6XV1',
      statusContent: 'dot',
      hash: 'VLPX8YE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLPX8YE4'
    },
    {
      status: 210,
      sku: 'PM0082224',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346304',
      rtl_batch_array: '6346304',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.978,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: 'AX17L6DE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX17L6DE'
    },
    {
      status: 210,
      sku: 'PM0082225',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346305',
      rtl_batch_array: '6346305',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: 'W523WCGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W523WCGK'
    },
    {
      status: 210,
      sku: 'PM0082226',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346306',
      rtl_batch_array: '6346306',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.294,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: 'AC7NWA8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AC7NWA8J'
    },
    {
      status: 210,
      sku: 'PM0082227',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346307',
      rtl_batch_array: '6346307',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 11.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: 'P9BX14B2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9BX14B2'
    },
    {
      status: 210,
      sku: 'PM0082228',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346308',
      rtl_batch_array: '6346308',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 13.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: 'JEKB26WK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEKB26WK'
    },
    {
      status: 210,
      sku: 'PM0082229',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346309',
      rtl_batch_array: '6346309',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 18.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      statusContent: 'dot',
      hash: '6C4W1GDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6C4W1GDA'
    },
    {
      status: 210,
      sku: 'PM0082230',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385651',
      rtl_batch_array: '6346310, 6385651',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 2800,
      sppl_prcp: 4.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '46TPZXCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46TPZXCG'
    },
    {
      status: 210,
      sku: 'PM0082231',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346311',
      rtl_batch_array: '6346311, 6287617',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 4984,
      chnn_stock_retail: 20484,
      sppl_prcp: 6.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'N3D81H8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3D81H8P'
    },
    {
      status: 810,
      sku: 'PM0082240',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346320',
      rtl_batch_array: '6346320',
      name_search: 'TABREPAN',
      name: "Taxus baccata 'Repandens'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 3.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '43L48WA5;RZ7W67KK;SKTPY2R1;T9799XAC;REHZ57Z8;JGK4PTX4;4E58PEN2;6WVK1D3A;N3C2RV34;C2W3JNX3;184LH1TA;LKHNSLNC',
      statusContent: 'dot',
      hash: 'DE8RRX77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE8RRX77'
    },
    {
      status: 210,
      sku: 'PM0082241',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346321',
      rtl_batch_array: '6346321',
      name_search: 'TABREPAN',
      name: "Taxus baccata 'Repandens'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_prcp: 4.392,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '43L48WA5;RZ7W67KK;SKTPY2R1;T9799XAC;REHZ57Z8;JGK4PTX4;4E58PEN2;6WVK1D3A;N3C2RV34;C2W3JNX3;184LH1TA;LKHNSLNC',
      statusContent: 'dot',
      hash: '9C992BJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9C992BJ4'
    },
    {
      status: 210,
      sku: 'PM0082242',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346322',
      rtl_batch_array: '6346322',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.385,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'XP47KB5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP47KB5C'
    },
    {
      status: 210,
      sku: 'PM0082243',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346323',
      rtl_batch_array: '6346323',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '040060KL',
      rng_range_identifier: 'H040060RB',
      rng_range_description: 'H40 cm 60 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 5.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'P1R4LRVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1R4LRVL'
    },
    {
      status: 210,
      sku: 'PM0082244',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346324',
      rtl_batch_array: '6346324',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 7.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'B1YHDTP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1YHDTP6'
    },
    {
      status: 810,
      sku: 'PM0082245',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346325',
      rtl_batch_array: '6346325',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: '56AL7SL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56AL7SL4'
    },
    {
      status: 810,
      sku: 'PM0082246',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346326',
      rtl_batch_array: '6346326',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_prcp: 24.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'X7SAVHSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7SAVHSW'
    },
    {
      status: 910,
      sku: 'PM0082247',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'G18TXRH1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G18TXRH1'
    },
    {
      status: 210,
      sku: 'PM0082249',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6358987',
      rtl_batch_array: '6346329, 6358987',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 370,
      chnn_stock_retail: 1120,
      sppl_prcp: 6.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: 'H759JZLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H759JZLB'
    },
    {
      status: 910,
      sku: 'PM0082250',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'H040060RB',
      rng_range_description: 'H40 cm 60 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'JET9C6ED',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JET9C6ED'
    },
    {
      status: 910,
      sku: 'PM0082251',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAALASK',
      name: "Ilex aquifolium 'Alaska'",
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      imageCore:
        'YZCK3Z7A;YGGASRN8;KTLGECKH;RHJP88XY;929N1BNB;P8JVW1RC;PY7B1HE1;A395L6HY;X2Z7CNL6;CEB5DR44;VBT7XPTG;NEV587VA;CS8HW7TW;LRHE52TP;XJB2NTVZ',
      statusContent: 'dot',
      hash: 'PVA2CCZ7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PVA2CCZ7'
    },
    {
      status: 910,
      sku: 'PM0082252',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAALASK',
      name: "Ilex aquifolium 'Alaska'",
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      imageCore:
        'YZCK3Z7A;YGGASRN8;KTLGECKH;RHJP88XY;929N1BNB;P8JVW1RC;PY7B1HE1;A395L6HY;X2Z7CNL6;CEB5DR44;VBT7XPTG;NEV587VA;CS8HW7TW;LRHE52TP;XJB2NTVZ',
      statusContent: 'dot',
      hash: 'SEBX61Y2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SEBX61Y2'
    },
    {
      status: 210,
      sku: 'PM0082253',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346333',
      rtl_batch_array: '6346333',
      name_search: 'ILAJTOL',
      name: "Ilex aquifolium 'J.C. van Tol'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 10.045,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CVLG67DE;B42AGHSH;2PBD56VG;8NRC3DRJ;9CSJSL6G;PY213WDA;5952BJHK;EELRV6HZ',
      statusContent: 'dot',
      hash: '17ETNSJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17ETNSJ7'
    },
    {
      status: 210,
      sku: 'PM0082254',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346334',
      rtl_batch_array: '6346334',
      name_search: 'ILAJTOL',
      name: "Ilex aquifolium 'J.C. van Tol'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 11.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CVLG67DE;B42AGHSH;2PBD56VG;8NRC3DRJ;9CSJSL6G;PY213WDA;5952BJHK;EELRV6HZ',
      statusContent: 'dot',
      hash: '86SY1EG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86SY1EG7'
    },
    {
      status: 210,
      sku: 'PM0082255',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385578',
      rtl_batch_array: '6358875, 6385578',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 4877,
      chnn_stock_retail: 5877,
      sppl_prcp: 4.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: '7HLRVPWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HLRVPWZ'
    },
    {
      status: 210,
      sku: 'PM0082256',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300763',
      rtl_batch_array: '6300763',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 9.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: 'KKV82B2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKV82B2A'
    },
    {
      status: 210,
      sku: 'PM0082257',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346337',
      rtl_batch_array: '6346337, 6385576',
      name_search: 'ILMBMAID',
      name: 'Ilex meserveae Blue Maid',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1650,
      sppl_prcp: 4.715,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BZE31P4;A7CZK69D;7LV39KNB;L2KZCAKX',
      statusContent: 'dot',
      hash: '9YG4XNTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YG4XNTB'
    },
    {
      status: 210,
      sku: 'PM0082258',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346338',
      rtl_batch_array: '6346338',
      name_search: 'ILMBMAID',
      name: 'Ilex meserveae Blue Maid',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 9.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BZE31P4;A7CZK69D;7LV39KNB;L2KZCAKX',
      statusContent: 'dot',
      hash: 'RWP9J2NY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWP9J2NY'
    },
    {
      status: 210,
      sku: 'PM0082259',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346339',
      rtl_batch_array: '6346339',
      name_search: 'ILMBMAID',
      name: 'Ilex meserveae Blue Maid',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.118,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BZE31P4;A7CZK69D;7LV39KNB;L2KZCAKX',
      statusContent: 'dot',
      hash: 'HGNH5W75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGNH5W75'
    },
    {
      status: 210,
      sku: 'PM0082260',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346340',
      rtl_batch_array: '6346340',
      name_search: 'ILMBMAID',
      name: 'Ilex meserveae Blue Maid',
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 12.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BZE31P4;A7CZK69D;7LV39KNB;L2KZCAKX',
      statusContent: 'dot',
      hash: '5Y7H2DSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Y7H2DSR'
    },
    {
      status: 210,
      sku: 'PM0082261',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346341',
      rtl_batch_array: '6346341',
      name_search: 'ILMBMAID',
      name: 'Ilex meserveae Blue Maid',
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 18.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BZE31P4;A7CZK69D;7LV39KNB;L2KZCAKX',
      statusContent: 'dot',
      hash: 'RG1HNEDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG1HNEDN'
    },
    {
      status: 910,
      sku: 'PM0082262',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'CG7NEGVW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CG7NEGVW'
    },
    {
      status: 910,
      sku: 'PM0082263',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'HZSWGNY5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HZSWGNY5'
    },
    {
      status: 910,
      sku: 'PM0082264',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'TYHLE7C1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TYHLE7C1'
    },
    {
      status: 910,
      sku: 'PM0082265',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'J82LR99H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J82LR99H'
    },
    {
      status: 910,
      sku: 'PM0082266',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'KNAWDHSX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KNAWDHSX'
    },
    {
      status: 210,
      sku: 'PM0082267',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346347',
      rtl_batch_array: '6346347',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'Z98D3ZZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z98D3ZZN'
    },
    {
      status: 210,
      sku: 'PM0082268',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346348',
      rtl_batch_array: '6346348',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'ET7JGEKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET7JGEKW'
    },
    {
      status: 910,
      sku: 'PM0082269',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'N5722XHJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N5722XHJ'
    },
    {
      status: 210,
      sku: 'PM0082270',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346766',
      rtl_batch_array: '6346766',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'GWXHNTWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWXHNTWY'
    },
    {
      status: 210,
      sku: 'PM0082271',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346767',
      rtl_batch_array: '6346767',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.824,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: 'Y73A6TTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y73A6TTA'
    },
    {
      status: 210,
      sku: 'PM0082272',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346768',
      rtl_batch_array: '6346768, 6358848',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '200220KL',
      rng_range_identifier: 'H200220RB',
      rng_range_description: 'H200 cm 220 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 350,
      sppl_prcp: 44.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'SAPP85VH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAPP85VH'
    },
    {
      status: 210,
      sku: 'PM0082273',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346769',
      rtl_batch_array: '6346769',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'JXT6AYX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXT6AYX4'
    },
    {
      status: 210,
      sku: 'PM0082236',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346316',
      rtl_batch_array: '6346316',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 45.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'BEY59KPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEY59KPK'
    },
    {
      status: 210,
      sku: 'PM0082237',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346317',
      rtl_batch_array: '6346317, 6358893',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '160180KL',
      rng_range_identifier: 'H160180RB',
      rng_range_description: 'H160 cm 180 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 600,
      sppl_prcp: 49.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'DCWG2C25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCWG2C25'
    },
    {
      status: 210,
      sku: 'PM0082239',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346319',
      rtl_batch_array: '6346319, 6358891',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '200220KL',
      rng_range_identifier: 'H200220RB',
      rng_range_description: 'H200 cm 220 cm Root ball',
      sppl_stock_available: 248,
      chnn_stock_retail: 348,
      sppl_prcp: 69.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'RGT78WZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGT78WZV'
    },
    {
      status: 210,
      sku: 'PM0082238',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346318',
      rtl_batch_array: '6346318',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '180200KL',
      rng_range_identifier: 'H180200RB',
      rng_range_description: 'H180 cm 200 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 54.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '4PNH728T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PNH728T'
    },
    {
      status: 210,
      sku: 'PM0082235',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 3,
      btch_active_retail: '6346650',
      rtl_batch_array: '6346315, 6346650, 6358892',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1750,
      sppl_prcp: 29.992,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'K6Y5C68L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6Y5C68L'
    },
    {
      status: 210,
      sku: 'PM0082209',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346288',
      rtl_batch_array: '6346288',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 745,
      chnn_stock_retail: 745,
      sppl_prcp: 15.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'RPKD3ACH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPKD3ACH'
    },
    {
      status: 210,
      sku: 'PM0082210',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346289',
      rtl_batch_array: '6346289',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 20.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '776EWKTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '776EWKTK'
    },
    {
      status: 210,
      sku: 'PM0082248',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346328',
      rtl_batch_array: '6346328',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '160180KL',
      rng_range_identifier: 'H160180RB',
      rng_range_description: 'H160 cm 180 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 59.206,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'E4AZAP99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4AZAP99'
    },
    {
      status: 810,
      sku: 'PM0071482',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350342',
      rtl_batch_array: '6229827, 6350342',
      name_search: 'HEBISLAN',
      name: "Hemerocallis 'Blackbeard Island'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 46,
      chnn_stock_retail: 142,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RX77T5BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX77T5BY'
    },
    {
      status: 810,
      sku: 'PM0016649',
      core_name: 'Plant',
      sppl_ean: '8720349481170',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5564516',
      rtl_batch_array: '6369377, 5564516',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '175200C3',
      rng_range_identifier: 'H175200C3',
      rng_range_description: 'H175 cm 200 cm C3',
      sppl_stock_available: 85,
      chnn_stock_retail: 2409,
      sppl_prcp: 4.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '86RVT1YL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86RVT1YL'
    },
    {
      status: 210,
      sku: 'PM0071498',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308480',
      rtl_batch_array: '6291703, 6308480',
      name_search: 'ANHHABUN',
      name: "Anemone hybrida 'Hadspen Abundance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1538,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZWJ3J4E;T11J9SV4;1D6X7ZEE;W6A7VVRE;N656ELN8',
      statusContent: 'dot',
      hash: 'XLRPEWYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLRPEWYT'
    },
    {
      status: 210,
      sku: 'PM0071475',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301352',
      rtl_batch_array: '6301352',
      name_search: 'GAULCBRA',
      name: 'Gaura lindheimeri Cherry Brandy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 712,
      chnn_stock_retail: 712,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W6W8X2B7',
      statusContent: 'dot',
      hash: '6Y7L6NAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Y7L6NAD'
    },
    {
      status: 210,
      sku: 'PM0064352',
      core_name: 'Plant',
      sppl_ean: '8720664871700',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149711',
      rtl_batch_array: '6149711',
      name_search: 'LABBRIDE',
      name: "Lavatera 'Blushing Bride'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 405,
      chnn_stock_retail: 405,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H96437DT;LJ4GNKPB;ASDGW9WJ;TGX15VYT;2A7LRLJE;P7874X9A;YE2ASATR;23VKYJ9J;87X1S3HW;7NEXH1WZ;LL2B3VRP;LBYSYD4X',
      statusContent: 'dot',
      hash: 'C72AHW2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C72AHW2L'
    },
    {
      status: 210,
      sku: 'PM0071507',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345456',
      rtl_batch_array: '6345456',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 16.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'BLB49KZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLB49KZR'
    },
    {
      status: 210,
      sku: 'PM0071506',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307263',
      rtl_batch_array: '6307263, 6334268',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 454,
      chnn_stock_retail: 524,
      sppl_prcp: 12.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'L3BHK4VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3BHK4VB'
    },
    {
      status: 210,
      sku: 'PM0071447',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091295',
      rtl_batch_array: '6091295, 6308999',
      name_search: 'HEBEMPER',
      name: "Hemerocallis 'Byzantine Emperor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 1166,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H9REK7VA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9REK7VA'
    },
    {
      status: 810,
      sku: 'PM0065283',
      core_name: 'Plant',
      sppl_ean: '8720664862869',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6162502',
      rtl_batch_array: '6162502',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: 'XLA3LJAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLA3LJAB'
    },
    {
      status: 210,
      sku: 'PM0071505',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307262',
      rtl_batch_array: '6281011, 6307262',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 195,
      chnn_stock_retail: 245,
      sppl_prcp: 11.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'BC51CVKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BC51CVKX'
    },
    {
      status: 210,
      sku: 'PM0071499',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229857',
      rtl_batch_array: '6230486, 6229857',
      name_search: 'ASASTARD',
      name: "Aster ageratoides 'Stardust'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5183,
      chnn_stock_retail: 5610,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D7GCNJ69',
      statusContent: 'dot',
      hash: 'A5B8SX2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5B8SX2J'
    },
    {
      status: 210,
      sku: 'PM0033313',
      core_name: 'Plant',
      sppl_ean: '8720626395077',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496322',
      rtl_batch_array: '5496322',
      name_search: 'BEWILSON',
      name: 'Berberis wilsoniae',
      sppl_size_code: 'PLGP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TJXW3KVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJXW3KVK'
    },
    {
      status: 210,
      sku: 'PM0071409',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242288',
      rtl_batch_array: '6242288',
      name_search: 'BUALTERN',
      name: 'Buddleja alternifolia',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 595,
      chnn_stock_retail: 595,
      sppl_prcp: 3.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '988R2HD3;GHZNGWGE;2K9D87YC;GRXWYBJW;7L96JD41;RBKDCV7X;51TH18E5;ZAEP38XD',
      statusContent: 'dot',
      hash: 'AD1SG61V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AD1SG61V'
    },
    {
      status: 210,
      sku: 'PM0082274',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346770',
      rtl_batch_array: '6346770',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'NAEBZY69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAEBZY69'
    },
    {
      status: 210,
      sku: 'PM0082275',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346774',
      rtl_batch_array: '6346771, 6346774',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 750,
      sppl_prcp: 16.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'V1J5L8Z9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1J5L8Z9'
    },
    {
      status: 210,
      sku: 'PM0071510',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 3,
      btch_active_retail: '6307267',
      rtl_batch_array: '6281022, 6307267, 6287311',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 296,
      chnn_stock_retail: 367,
      sppl_prcp: 12.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'WVR1VA5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVR1VA5P'
    },
    {
      status: 210,
      sku: 'PM0071509',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307266',
      rtl_batch_array: '6281021, 6307266',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 358,
      chnn_stock_retail: 408,
      sppl_prcp: 11.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'WTA38CV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTA38CV9'
    },
    {
      status: 210,
      sku: 'PM0064345',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183173',
      rtl_batch_array: '6183173',
      name_search: 'GEPHAEUM',
      name: 'Geranium phaeum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1774,
      chnn_stock_retail: 1774,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E19R7WLV',
      statusContent: 'dot',
      hash: 'N62SS65T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N62SS65T'
    },
    {
      status: 210,
      sku: 'PM0071457',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386066',
      rtl_batch_array: '6288301, 6386066',
      name_search: 'ARMDSTOL',
      name: "Armeria maritima 'Dusseldorfer Stolz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 973,
      chnn_stock_retail: 1276,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2LXP76Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2LXP76Y'
    },
    {
      status: 210,
      sku: 'PM0071503',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229873',
      rtl_batch_array: '6229873',
      name_search: 'ASVISION',
      name: "Astilbe 'Vision'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DXW66Z65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXW66Z65'
    },
    {
      status: 810,
      sku: 'PM0064343',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282402',
      rtl_batch_array: '6282402',
      name_search: 'DIPGLOXI',
      name: "Digitalis purpurea 'Gloxiniiflora'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AGGYKR9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGGYKR9E'
    },
    {
      status: 210,
      sku: 'PM0071440',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5394466',
      rtl_batch_array: '5394466',
      name_search: 'HIBISCUS',
      name: "Hibiscus in cv's",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ALC9EV9X',
      statusContent: 'dot',
      hash: '9YJZTYT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YJZTYT2'
    },
    {
      status: 210,
      sku: 'PM0015341',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382295',
      rtl_batch_array: '6208045, 6133384, 6382295, 6333280',
      name_search: 'MOCEDUDS',
      name: "Molinia caerulea 'Edith Dudszus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10500,
      chnn_stock_retail: 19286,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9KXNAK1H;YDJDPZC6;CNTVT778;4HRRSPED;2CXKBNHA;GJGGTLN4;51S5V8X9;SP827C96;TL2PL2A8;WTYG3AK3;27PY65GE;AET1GN3Z;9WS14VP9;Z1CA99EH;C8D7YR8Y',
      statusContent: 'dot',
      hash: 'T2AH2ABT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2AH2ABT'
    },
    {
      status: 910,
      sku: 'PM0071441',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HIBISCUS',
      name: "Hibiscus in cv's",
      rng_range_identifier: 'STE030C3',
      rng_range_description: 'Stem 30 cm C3',
      statusContent: 'dot',
      hash: 'L5362JER',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L5362JER'
    },
    {
      status: 910,
      sku: 'PM0071442',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HIBISCUS',
      name: "Hibiscus in cv's",
      rng_range_identifier: 'STE060C4',
      rng_range_description: 'Stem 60 cm C4',
      statusContent: 'dot',
      hash: '858D74XY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '858D74XY'
    },
    {
      status: 210,
      sku: 'PM0071500',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229862',
      rtl_batch_array: '6229862',
      name_search: 'ASBUMALD',
      name: "Astilbe (A) 'Bumalda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1ENKV3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1ENKV3W'
    },
    {
      status: 210,
      sku: 'PM0067503',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308556',
      rtl_batch_array: '6350038, 6301549, 6301084, 6308556',
      name_search: 'ECPFATTR',
      name: "Echinacea purpurea 'Fatal Attraction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4011,
      chnn_stock_retail: 7732,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9LG9B6L1;65R4YGZW',
      statusContent: 'dot',
      hash: 'D3CZHGKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3CZHGKJ'
    },
    {
      status: 210,
      sku: 'PM0071502',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6276021',
      rtl_batch_array: '6229872, 6276021, 6308651',
      name_search: 'ASRHEINL',
      name: "Astilbe (J) 'Rheinland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1070,
      chnn_stock_retail: 2037,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AHC95XEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHC95XEA'
    },
    {
      status: 210,
      sku: 'PM0071468',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217097',
      rtl_batch_array: '6217097',
      name_search: 'CRBUTTER',
      name: "Crocosmia 'Buttercups'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1190,
      chnn_stock_retail: 1190,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LWWE87S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWWE87S8'
    },
    {
      status: 810,
      sku: 'PM0071451',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147724',
      rtl_batch_array: '6147724',
      name_search: 'DESRED',
      name: "Delosperma 'Sundella Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NK8LN3A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NK8LN3A2'
    },
    {
      status: 210,
      sku: 'PM0071444',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192720',
      rtl_batch_array: '6192720',
      name_search: 'DISYLEAF',
      name: 'Dicentra spect. Yellow Leaf',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTGYYGX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTGYYGX9'
    },
    {
      status: 210,
      sku: 'PM0006075',
      core_name: 'Plant',
      sppl_ean: '8720626366206',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5404301',
      rtl_batch_array: '6368800, 5404301',
      name_search: 'PUVALBA',
      name: "Pulsatilla vulgaris 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4364,
      chnn_stock_retail: 5364,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LDJJELAY;7CCXRE49;67ARERE8;9LGLAAAD;ZW2VHRNR',
      imageBatch: 'HAJEJLZ8',
      statusContent: 'dot',
      hash: 'HALVEHYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HALVEHYS'
    },
    {
      status: 210,
      sku: 'PM0064354',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238462',
      rtl_batch_array: '6238462',
      name_search: 'LIMINGWE',
      name: "Liriope muscari 'Ingwersen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4934,
      chnn_stock_retail: 4934,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12HDS6E;VTEX3J3R;25KXLVN1;KVV6JXRX',
      statusContent: 'dot',
      hash: 'LZP3X9VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZP3X9VN'
    },
    {
      status: 210,
      sku: 'PM0068443',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217112',
      rtl_batch_array: '6217112',
      name_search: 'ECHMPINK',
      name: "Echinacea 'Meditation Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '62HGDPP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62HGDPP8'
    },
    {
      status: 810,
      sku: 'PM0068444',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217121',
      rtl_batch_array: '6217121',
      name_search: 'ECSBPEAR',
      name: "Echinacea 'Sensation Black Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 53,
      chnn_stock_retail: 53,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8X6J2A83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8X6J2A83'
    },
    {
      status: 210,
      sku: 'PM0085677',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385793',
      rtl_batch_array: '6385793',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 920,
      chnn_stock_retail: 920,
      sppl_prcp: 4.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'JZ4R5ATW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ4R5ATW'
    },
    {
      status: 210,
      sku: 'PM0082276',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346772',
      rtl_batch_array: '6346772',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 23.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '1KVNW1E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KVNW1E4'
    },
    {
      status: 210,
      sku: 'PM0071461',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268738',
      rtl_batch_array: '6268738',
      name_search: 'EUPOLYCH',
      name: 'Euphorbia polychroma',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZVHS9372',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVHS9372'
    },
    {
      status: 210,
      sku: 'PM0071453',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315044',
      rtl_batch_array: '6054491, 6315044, 6309020',
      name_search: 'BOGRACIL',
      name: 'Bouteloua gracilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1343,
      chnn_stock_retail: 3457,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JJR8YPKP',
      statusContent: 'dot',
      hash: '95VGWVDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95VGWVDD'
    },
    {
      status: 210,
      sku: 'PM0068445',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217141',
      rtl_batch_array: '6217141',
      name_search: 'FIPGOETE',
      name: "Filipendula palmata 'Goeteborg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GS5B78K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS5B78K6'
    },
    {
      status: 910,
      sku: 'PM0071458',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GESNANUM',
      name: "Geranium sanguineum 'Nanum'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'A82Z56KY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A82Z56KY'
    },
    {
      status: 210,
      sku: 'PM0071460',
      core_name: 'Plant',
      sppl_ean: '8720664883109',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161923',
      rtl_batch_array: '6161923',
      name_search: 'RHRVALEN',
      name: "Rheum rhabarbarum 'Valentine'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 1.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z7LWTLNK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7LWTLNK'
    },
    {
      status: 210,
      sku: 'PM0071478',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238813',
      rtl_batch_array: '6238813',
      name_search: 'HEBHAY',
      name: "Hemerocallis 'Baley Hay'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W5PEXTX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5PEXTX7'
    },
    {
      status: 210,
      sku: 'PM0071479',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229824',
      rtl_batch_array: '6229824',
      name_search: 'HEBANDOL',
      name: "Hemerocallis 'Bandolero'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ZR52PW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZR52PW4'
    },
    {
      status: 210,
      sku: 'PM0071470',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6181357',
      rtl_batch_array: '6350341, 6181357',
      name_search: 'HEBTHAPP',
      name: "Hemerocallis 'Big Time Happy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1582,
      chnn_stock_retail: 1931,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SNH4XAA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNH4XAA9'
    },
    {
      status: 810,
      sku: 'PM0071486',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229832',
      rtl_batch_array: '6229832',
      name_search: 'HECOSMOP',
      name: "Hemerocallis 'Cosmopolitan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G37DBXHH',
      statusContent: 'dot',
      hash: '57YBLPX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57YBLPX6'
    },
    {
      status: 210,
      sku: 'PM0071487',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350351',
      rtl_batch_array: '6229834, 6350351',
      name_search: 'HEFTOUCH',
      name: "Hemerocallis 'Final Touch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 174,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2LDZAGPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LDZAGPB'
    },
    {
      status: 910,
      sku: 'PM0017438',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUJOAURE',
      name: "Euonymus japonicus 'Ovatus Aureus'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'ZT8N68VC;G7TL8S4A;JDRW35SL;6H3XB3NW;JP35W8PV;1A21N181;77X532BH;67S3P7A3;LNLZX6L3;XJVH47J5;17SPLX7X;Z5VC7H1N',
      statusContent: 'dot',
      hash: 'BCNPHZ6W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BCNPHZ6W'
    },
    {
      status: 810,
      sku: 'PM0071476',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229820',
      rtl_batch_array: '6229820',
      name_search: 'HEHORANG',
      name: "Hemerocallis 'House of Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4CZD34BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CZD34BE'
    },
    {
      status: 210,
      sku: 'PM0071489',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229837',
      rtl_batch_array: '6229837',
      name_search: 'HEJCLUB',
      name: "Hemerocallis 'Jockey Club'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N32V12JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N32V12JE'
    },
    {
      status: 910,
      sku: 'PM0038981',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: '6GDJKBTZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6GDJKBTZ'
    },
    {
      status: 210,
      sku: 'PM0064353',
      core_name: 'Plant',
      sppl_ean: '8720664872110',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149712',
      rtl_batch_array: '6149712',
      name_search: 'LESILBER',
      name: "Leucanthemum (S) 'Silberprinzesschen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JZY8DZZL;CBWGTBAK;TN12CRND;9T79KN6Z;96Y5DYT5;E7JTT62V;1575B33T;7TKL2K3Z;RWJSLBYW;E8ADL5KL;J5WBXLL4;7SZX657K;964NV6GK;24HENG7B;2WXPTRKW;6PVDYSRL;8GV1217D;A78TNT6P;AX4T4JP8;JEDVHPCT;KSAVBWXC;J1GGN4LD',
      statusContent: 'dot',
      hash: 'A1EYBDPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1EYBDPK'
    },
    {
      status: 210,
      sku: 'PM0004678',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6202825',
      rtl_batch_array: '6350292, 6202825, 6333245',
      name_search: 'HEHOOPES',
      name: 'Helenium hoopesii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 856,
      chnn_stock_retail: 2241,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H245SKNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H245SKNW'
    },
    {
      status: 810,
      sku: 'PM0071490',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229839',
      rtl_batch_array: '6229839, 6350357',
      name_search: 'HELFAIRY',
      name: "Hemerocallis 'Little Fairy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 526,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPYTBHNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPYTBHNT'
    },
    {
      status: 210,
      sku: 'PM0079590',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329870',
      rtl_batch_array: '6329870, 6374012',
      name_search: 'BEHARZKR',
      name: "Bergenia 'Harzkristall'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 594,
      chnn_stock_retail: 694,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JVH9BX8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVH9BX8B'
    },
    {
      status: 210,
      sku: 'PM0079591',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329871',
      rtl_batch_array: '6329871',
      name_search: 'BEMGIANT',
      name: "Bergenia 'Magic Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KSP9SE14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSP9SE14'
    },
    {
      status: 210,
      sku: 'PM0079592',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329872',
      rtl_batch_array: '6329872',
      name_search: 'BOCYLIND',
      name: 'Boehmeria cylindrica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HY5S1SK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HY5S1SK6'
    },
    {
      status: 910,
      sku: 'PM0079593',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BRSYLVAT',
      name: 'Brachypodium sylvaticum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5RSXYD8A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5RSXYD8A'
    },
    {
      status: 210,
      sku: 'PM0079594',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329876',
      rtl_batch_array: '6329876',
      name_search: 'BRMATTRA',
      name: "Brunnera macr. 'Attractive'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W47ND3HT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W47ND3HT'
    },
    {
      status: 210,
      sku: 'PM0079595',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329877',
      rtl_batch_array: '6329877',
      name_search: 'BRMDGOLD',
      name: "Brunnera macrophylla 'Dianes Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWJNHK8S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWJNHK8S'
    },
    {
      status: 210,
      sku: 'PM0071496',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229846',
      rtl_batch_array: '6229846',
      name_search: 'HEPWATER',
      name: "Hemerocallis 'Purple Waters'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NG91G9WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG91G9WE'
    },
    {
      status: 210,
      sku: 'PM0071455',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6108212',
      rtl_batch_array: '6108212, 6350363, 6333252',
      name_search: 'HESSUNBU',
      name: "Hemerocallis 'Serena Sunburst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 236,
      chnn_stock_retail: 1676,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9ESLH3AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ESLH3AX'
    },
    {
      status: 810,
      sku: 'PM0006530',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281106',
      rtl_batch_array: '6281106',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '175200C30',
      rng_range_identifier: 'H175200C30',
      rng_range_description: 'H175 cm 200 cm C30',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'JXTVXE6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXTVXE6W'
    },
    {
      status: 210,
      sku: 'PM0071456',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6108214',
      rtl_batch_array: '6108214',
      name_search: 'HEYLOLLI',
      name: "Hemerocallis 'Yellow Lollipop'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4XJDZ8S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XJDZ8S1'
    },
    {
      status: 810,
      sku: 'PM0071471',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301729',
      rtl_batch_array: '6301729',
      name_search: 'MOBYBPUR',
      name: 'Monarda Bee-you Bee-pure',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RK37RH39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RK37RH39'
    },
    {
      status: 910,
      sku: 'PM0024676',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CORRAMI',
      name: "Cotoneaster radicans 'Rami'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      statusContent: 'dot',
      hash: 'V98XHL53',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V98XHL53'
    },
    {
      status: 210,
      sku: 'PM0071493',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229842',
      rtl_batch_array: '6229842',
      name_search: 'HEMPERRY',
      name: "Hemerocallis 'Margaret Perry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 677,
      chnn_stock_retail: 677,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JACEZG2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JACEZG2P'
    },
    {
      status: 810,
      sku: 'PM0071495',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229844',
      rtl_batch_array: '6229844, 6290219',
      name_search: 'HENCRANE',
      name: "Hemerocallis 'Nile Crane'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 81,
      chnn_stock_retail: 5060,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z72JBEJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z72JBEJP'
    },
    {
      status: 210,
      sku: 'PM0079589',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329868',
      rtl_batch_array: '6329868',
      name_search: 'BECALTHI',
      name: 'Beesia calthifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6J5G433T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J5G433T'
    },
    {
      status: 210,
      sku: 'PM0079596',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329882',
      rtl_batch_array: '6329882',
      name_search: 'COAHAZE',
      name: "Coprosma 'Autumn Haze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y6VJH2SN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6VJH2SN'
    },
    {
      status: 810,
      sku: 'PM0079597',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349775',
      rtl_batch_array: '6329883, 6349775',
      name_search: 'CANLRIES',
      name: "Calamintha nepeta 'Lila Riese'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 81,
      chnn_stock_retail: 260,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGAPELPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGAPELPJ'
    },
    {
      status: 210,
      sku: 'PM0079598',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349058',
      rtl_batch_array: '6349058, 6329884, 6171173',
      name_search: 'CAGSUPER',
      name: "Campanula glomerata 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5037,
      chnn_stock_retail: 6810,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S46RH1BR;9AHWRPK5;GY9BJ4LY;BRYLHWB8;NCDBJG65;DLABNLV7;WK9957V1;65KEL9J6;7V2ST19C',
      statusContent: 'dot',
      hash: 'RKX1R2WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKX1R2WV'
    },
    {
      status: 210,
      sku: 'PM0079599',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352625',
      rtl_batch_array: '6329885, 6352625',
      name_search: 'CALALBA',
      name: "Campanula lactiflora 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 252,
      chnn_stock_retail: 890,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X3752HHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3752HHT'
    },
    {
      status: 210,
      sku: 'PM0071448',
      core_name: 'Plant',
      sppl_ean: '8720664866959',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091307',
      rtl_batch_array: '6091307',
      name_search: 'HEPBEYES',
      name: "Hemerocallis 'Prairie Blue Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XWNXT846',
      statusContent: 'dot',
      hash: '5VYR2HPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VYR2HPC'
    },
    {
      status: 210,
      sku: 'PM0085678',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385794',
      rtl_batch_array: '6385794',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 4.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'P7AWX3Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7AWX3Z4'
    },
    {
      status: 210,
      sku: 'PM0079600',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329886',
      rtl_batch_array: '6329886',
      name_search: 'CALSUPER',
      name: "Campanula lactiflora 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZW8DPVZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW8DPVZX'
    },
    {
      status: 910,
      sku: 'PM0079601',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAPATULA',
      name: 'Campanula patula',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '6X466214',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6X466214'
    },
    {
      status: 210,
      sku: 'PM0082277',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346773',
      rtl_batch_array: '6346773',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '44EE4A2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44EE4A2N'
    },
    {
      status: 210,
      sku: 'PM0071477',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229822',
      rtl_batch_array: '6229822, 6242155',
      name_search: 'HEATEN',
      name: "Hemerocallis 'Aten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 243,
      chnn_stock_retail: 746,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZE7H726',
      statusContent: 'dot',
      hash: '4A6W2L8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4A6W2L8L'
    },
    {
      status: 210,
      sku: 'PM0082278',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 6,
      btch_active_retail: '6348451',
      rtl_batch_array: '5364280, 5903363, 6348451, 6057367, 6199480, 6084751',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1600,
      chnn_stock_retail: 8586,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: 'EJRCBTV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJRCBTV2'
    },
    {
      status: 210,
      sku: 'PM0082279',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 7,
      btch_active_retail: '6340062',
      rtl_batch_array:
        '5364279, 6147181, 6269839, 6350629, 6340062, 6260118, 6196797',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4018,
      chnn_stock_retail: 51459,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'ND4ETG7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND4ETG7K'
    },
    {
      status: 910,
      sku: 'PM0085679',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: '7DJ28SYN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7DJ28SYN'
    },
    {
      status: 210,
      sku: 'PM0085680',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385800',
      rtl_batch_array: '6385800',
      name_search: 'STPULCHE',
      name: 'Stipa pulcherrima',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JG1VBYN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JG1VBYN6'
    },
    {
      status: 210,
      sku: 'PM0085681',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385801',
      rtl_batch_array: '6385801',
      name_search: 'NEFJWALK',
      name: "Nepeta f. 'Junior Walker'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41LGZBNY;NC495WDT;HRNTZC4Z;KK7JV3VR;T4TTSWG6',
      statusContent: 'dot',
      hash: '6D5TEHWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6D5TEHWJ'
    },
    {
      status: 210,
      sku: 'PM0071480',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350340',
      rtl_batch_array: '6350340',
      name_search: 'HEBLUGOS',
      name: "Hemerocallis 'Bela Lugosi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6BE31HWZ',
      statusContent: 'dot',
      hash: 'SHVXJWAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHVXJWAW'
    },
    {
      status: 210,
      sku: 'PM0071481',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229826',
      rtl_batch_array: '6229826',
      name_search: 'HEBPRINC',
      name: "Hemerocallis 'Black Prince'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ANDR9S6',
      statusContent: 'dot',
      hash: 'XH1TBGC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH1TBGC6'
    },
    {
      status: 210,
      sku: 'PM0071483',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229828',
      rtl_batch_array: '6229828, 6350344',
      name_search: 'HECEMBER',
      name: "Hemerocallis 'Campfire Embers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 501,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BW1A7C6K',
      statusContent: 'dot',
      hash: 'DDAV7LS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDAV7LS5'
    },
    {
      status: 210,
      sku: 'PM0071484',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229829',
      rtl_batch_array: '6229829',
      name_search: 'HECFESTI',
      name: "Hemerocallis 'Children's Festival'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 101,
      chnn_stock_retail: 101,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6ND2DAN;C259N4Y7',
      statusContent: 'dot',
      hash: '5KYBE8H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KYBE8H8'
    },
    {
      status: 210,
      sku: 'PM0071485',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350348',
      rtl_batch_array: '6350348, 6238816, 6333248',
      name_search: 'HECORKY',
      name: "Hemerocallis 'Corky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 2209,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7S5B3JV2',
      statusContent: 'dot',
      hash: 'WDJHKAHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDJHKAHG'
    },
    {
      status: 910,
      sku: 'PM0071488',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEFULVA',
      name: 'Hemerocallis fulva',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'XBS3EWBY',
      statusContent: 'dot',
      hash: '7ZDCRG8C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7ZDCRG8C'
    },
    {
      status: 210,
      sku: 'PM0071491',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229840',
      rtl_batch_array: '6229840',
      name_search: 'HELGRAPE',
      name: "Hemerocallis 'Little Grapette'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BBA73ASH;ABP3R2PV;7GK3NKWH',
      statusContent: 'dot',
      hash: '716GEWGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '716GEWGD'
    },
    {
      status: 810,
      sku: 'PM0071492',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229841',
      rtl_batch_array: '6229841',
      name_search: 'HELBABY',
      name: "Hemerocallis 'Lullaby Baby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L68HGN58;JG3B6JGL;DJX2629D',
      statusContent: 'dot',
      hash: 'ACWJC98A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACWJC98A'
    },
    {
      status: 210,
      sku: 'PM0071494',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242160',
      rtl_batch_array: '6229843, 6242160',
      name_search: 'HEMPEARL',
      name: "Hemerocallis 'Mini Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 469,
      chnn_stock_retail: 646,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WLTX1V1S;X3RHPX5Y',
      statusContent: 'dot',
      hash: '68AL5BTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68AL5BTA'
    },
    {
      status: 210,
      sku: 'PM0071501',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6229865',
      rtl_batch_array: '6229865, 6349884, 6385472',
      name_search: 'ASCSUPER',
      name: "Astilbe chinensis 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 545,
      chnn_stock_retail: 1534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '98Z9BKJZ;8GA3821C;9YLEYNPA;8B49P9CT',
      statusContent: 'dot',
      hash: 'N1TJVZB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1TJVZB8'
    },
    {
      status: 810,
      sku: 'PM0071497',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229848',
      rtl_batch_array: '6229848',
      name_search: 'HERAPRIC',
      name: "Hemerocallis 'Ruffled Apricot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XK46CYB8;19PTHTZV',
      statusContent: 'dot',
      hash: '1JYBVJA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JYBVJA6'
    },
    {
      status: 210,
      sku: 'PM0064341',
      core_name: 'Plant',
      sppl_ean: '8720664859395',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6149695',
      rtl_batch_array: '6149695, 6221900',
      name_search: 'DEBBIRD',
      name: "Delphinium (P) 'Blue Bird'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 604,
      chnn_stock_retail: 1604,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A36HXYV9;7BD2XYH4;ZTZ1S77K;GSYHSP8X;4EKW6A9J;94BBKBEV;LT517R74;ZY8L4ES3;ZZXSEPJ2;LSRVG41Z;HK23STXR;NAN431VZ;GCR28HWJ',
      statusContent: 'dot',
      hash: 'GDWRYKPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDWRYKPB'
    },
    {
      status: 210,
      sku: 'PM0085682',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385802',
      rtl_batch_array: '6385802',
      name_search: 'ROPINNAT',
      name: 'Rodgersia pinnata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1017,
      chnn_stock_retail: 1017,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9PVA5J9;56YXT1TA;26HNV5KA;996VNZ53;4HXCRPX4;T2YNWSPY;LSN79VYZ;PZN9ERJ8;JG39XVWW',
      statusContent: 'dot',
      hash: 'CSL9WZEZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSL9WZEZ'
    },
    {
      status: 210,
      sku: 'PM0071439',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6339330',
      rtl_batch_array: '5364220, 6353561, 6339330, 6363215',
      name_search: 'VERLONGI',
      name: 'Veronica longifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1717,
      chnn_stock_retail: 3029,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LVHWN754;CLKTRNNH',
      statusContent: 'dot',
      hash: '949LWNX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '949LWNX7'
    },
    {
      status: 210,
      sku: 'PM0071508',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307265',
      rtl_batch_array: '6281018, 6307265',
      name_search: 'ACPFIREC',
      name: "Acer palmatum 'Firecracker'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 266,
      chnn_stock_retail: 316,
      sppl_prcp: 19.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RZT9SKTC;CD296LJC;H2ZAS53R',
      statusContent: 'dot',
      hash: 'YGBDZAG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGBDZAG1'
    },
    {
      status: 910,
      sku: 'PM0029082',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMTOVEL',
      name: "Hydrangea macrophylla 'Tovelit'",
      rng_range_identifier: 'H020025C3.5',
      rng_range_description: 'H20 cm 25 cm C3.5',
      imageCore: '1A8YBWLN',
      statusContent: 'dot',
      hash: '2NRAKGNW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2NRAKGNW'
    },
    {
      status: 810,
      sku: 'PM0071517',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307427',
      rtl_batch_array: '6307427',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 12.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'ZLZZSHX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLZZSHX6'
    },
    {
      status: 210,
      sku: 'PM0059951',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350500',
      rtl_batch_array: '6350500',
      name_search: 'HOFICE',
      name: "Hosta 'Fire and Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 622,
      chnn_stock_retail: 622,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YSH65SC3',
      statusContent: 'dot',
      hash: 'Y197SW7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y197SW7B'
    },
    {
      status: 210,
      sku: 'PM0079616',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329907',
      rtl_batch_array: '6329907',
      name_search: 'CRJATROP',
      name: 'Cryptotaenia japonica atropurpurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 281,
      chnn_stock_retail: 281,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '72XYSX9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72XYSX9H'
    },
    {
      status: 210,
      sku: 'PM0071418',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229421',
      rtl_batch_array: '6229421',
      name_search: 'GALGROSE',
      name: 'Gaura lindheimeri Gaudi Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17455,
      chnn_stock_retail: 17455,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7E3AR12B',
      statusContent: 'dot',
      hash: 'S9EAT6SR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9EAT6SR'
    },
    {
      status: 210,
      sku: 'PM0079617',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329908',
      rtl_batch_array: '6329908',
      name_search: 'DEXBWOND',
      name: "Deinanthe x 'Blue Wonder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 568,
      chnn_stock_retail: 568,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ASZYKTG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASZYKTG5'
    },
    {
      status: 910,
      sku: 'PM0079618',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEFTGOLD',
      name: "Deschampsia flexuosa 'Tatra Gold'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'LSXN5YTV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LSXN5YTV'
    },
    {
      status: 810,
      sku: 'PM0067506',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301322',
      rtl_batch_array: '6301322',
      name_search: 'ERCTBURG',
      name: "Eragrostis curvula 'Totnes Burgundy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '759DKXSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '759DKXSB'
    },
    {
      status: 810,
      sku: 'PM0067512',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192743',
      rtl_batch_array: '6192743',
      name_search: 'EUCTBOY',
      name: "Euphorbia cyp. 'Tall Boy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y8EHGAP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8EHGAP4'
    },
    {
      status: 210,
      sku: 'PM0071419',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229422',
      rtl_batch_array: '6229422',
      name_search: 'GAAPURA',
      name: "Gaillardia 'Aurea Pura'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4337,
      chnn_stock_retail: 4337,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K5ZJ8BDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5ZJ8BDG'
    },
    {
      status: 210,
      sku: 'PM0007532',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6231157',
      rtl_batch_array: '6207780, 6224222, 6231157',
      name_search: 'ACWFUNCK',
      name: "Achillea 'Walther Funcke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1183,
      sppl_order_minimum: 3,
      sppl_prcp: 0.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YSDBL1Y;6R1462X1;X9W95RT2;2H17KA5C',
      statusContent: 'dot',
      hash: 'JL7DCCX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL7DCCX4'
    },
    {
      status: 810,
      sku: 'PM0065970',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307853',
      rtl_batch_array: '6307853',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_prcp: 12.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: 'SWXK1VWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWXK1VWS'
    },
    {
      status: 210,
      sku: 'PM0079619',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329911',
      rtl_batch_array: '6329911',
      name_search: 'DECANADE',
      name: 'Desmodium canadense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7P6S4L42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P6S4L42'
    },
    {
      status: 210,
      sku: 'PM0009648',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299649',
      rtl_batch_array: '6299649',
      name_search: 'ASAHASLA',
      name: "Aster (D) 'Alice Haslam'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1228,
      chnn_stock_retail: 1228,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '529BZVWT;H8T5DCCR;L36BRG66;YZHD2EDN;47YCW2RR;J3ZECSN3;WL8JYCEE',
      statusContent: 'dot',
      hash: 'Z1P51BKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1P51BKN'
    },
    {
      status: 210,
      sku: 'PM0059964',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350662',
      rtl_batch_array: '6350662',
      name_search: 'LUPIGEL',
      name: "Luzula pilosa 'Igel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SDGJSDZ3',
      statusContent: 'dot',
      hash: '78RAZYDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78RAZYDE'
    },
    {
      status: 810,
      sku: 'PM0059944',
      core_name: 'Plant',
      sppl_ean: '8720664866133',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161724',
      rtl_batch_array: '6161724',
      name_search: 'HERANCHE',
      name: "Helenium 'Ranchera'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TJTSV36J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJTSV36J'
    },
    {
      status: 210,
      sku: 'PM0064355',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263439',
      rtl_batch_array: '6263439',
      name_search: 'MOMDELIG',
      name: "Monarda 'Marshall's Delight'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2668,
      chnn_stock_retail: 2668,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DNV6C3L;VV33RPVJ;5NPKKTHD;YEABWLXK;26HGJNRV;LEZPVACR;4276L48J;WBVH1XEC;RTV2A852;GR7XCB8T;HV58PTS4;SJH3GB4R;B3YVC7H3',
      statusContent: 'dot',
      hash: 'NYBJN3NH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYBJN3NH'
    },
    {
      status: 210,
      sku: 'PM0079620',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386099',
      rtl_batch_array: '6386099',
      name_search: 'DIWMAGEN',
      name: "Dianthus 'Whatfield Magenta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTEGH18E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTEGH18E'
    },
    {
      status: 910,
      sku: 'PM0039029',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PASBERNH',
      name: "Paeonia (LD) 'Sarah Bernhardt'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '5LR26REP;LAG8TSE2;HGPLY67A;5CS8R3BX;H5TVCZC6;B76P6499;HXAV6XLP;G18TXJHR;D2LVRV4P;S717VWNG',
      statusContent: 'dot',
      hash: 'JXB9ELZP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JXB9ELZP'
    },
    {
      status: 210,
      sku: 'PM0079621',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329913',
      rtl_batch_array: '6329913',
      name_search: 'DIWWISP',
      name: "Dianthus 'Whatfield Wisp'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HKKAAVS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKKAAVS2'
    },
    {
      status: 910,
      sku: 'PM0082280',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALGLOBUS',
      name: "Allium 'Globus'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '2VEGXHL5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2VEGXHL5'
    },
    {
      status: 810,
      sku: 'PM0082281',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349859',
      rtl_batch_array: '6349859',
      name_search: 'CAELIZAB',
      name: "Campanula 'Elizabeth'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.462,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9Z9NG32N;KHH8LPTS',
      statusContent: 'dot',
      hash: 'DYKGZV6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYKGZV6C'
    },
    {
      status: 210,
      sku: 'PM0082282',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349867',
      rtl_batch_array: '6349867',
      name_search: 'HEIPRINC',
      name: "Helleborus 'Ivory Prince'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 3.583,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BB55BN5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BB55BN5V'
    },
    {
      status: 910,
      sku: 'PM0039030',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAWWINGS',
      name: "Paeonia (LE) 'White Wings'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '9TWGGYEL;DVDDCBCC',
      statusContent: 'dot',
      hash: 'Z57K4VE9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z57K4VE9'
    },
    {
      status: 210,
      sku: 'PM0082283',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349869',
      rtl_batch_array: '6349869',
      name_search: 'HEPBEAUT',
      name: "Helleborus 'Pink Beauty'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 3.583,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WBVJ9JL4',
      statusContent: 'dot',
      hash: 'K1P9HN3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1P9HN3R'
    },
    {
      status: 210,
      sku: 'PM0085683',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385816',
      rtl_batch_array: '6385816',
      name_search: 'CEBMOUND',
      name: "Ceanothus 'Blue Mound'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7W32SR6;DES3VTSB;7YDGE247',
      statusContent: 'dot',
      hash: 'NX9PKPXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NX9PKPXW'
    },
    {
      status: 210,
      sku: 'PM0032853',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177557',
      rtl_batch_array: '5837501, 6177557',
      name_search: 'SANOSTFR',
      name: "Salvia nemorosa 'Ostfriesland'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2321,
      chnn_stock_retail: 3343,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8CP8CVY;7HJDG7W5;WN5NK6H4;6S8768BL;738ADD1T;2CYAKL22;H44PR129;9BP6H4BZ;TGNL65XV;ZPXLDZW3;HZP275Y2;PEWXGZHY;7JKJ6VC7;9T4E9GVG;WPBS61E8;BD635VT7;TWBHZ4SY;RHYB8ZAJ',
      statusContent: 'dot',
      hash: 'DE8PHBL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE8PHBL4'
    },
    {
      status: 210,
      sku: 'PM0059940',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350266',
      rtl_batch_array: '6350266',
      name_search: 'GETHEX',
      name: "Geum 'Tales of Hex'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 617,
      chnn_stock_retail: 617,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BZ5DGZSG;SXWC1S2L;A2CLN6J5;RDCV2GXZ',
      statusContent: 'dot',
      hash: '1AY65ABT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AY65ABT'
    },
    {
      status: 210,
      sku: 'PM0059970',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350719',
      rtl_batch_array: '6350719',
      name_search: 'OEFMPLOE',
      name: "Oenothera fruticosa 'Michelle Ploeger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SELW2CLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SELW2CLH'
    },
    {
      status: 810,
      sku: 'PM0059962',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350658',
      rtl_batch_array: '6350658',
      name_search: 'LIMCTREE',
      name: "Liriope muscari 'Christmas Tree'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8YXS6L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8YXS6L1'
    },
    {
      status: 910,
      sku: 'PM0059956',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRTWOOLO',
      name: "Iris tectorum 'Woolong'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'E7BWJ8KD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E7BWJ8KD'
    },
    {
      status: 210,
      sku: 'PM0059926',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350037',
      rtl_batch_array: '6350037, 6320849',
      name_search: 'ECECCENT',
      name: "Echinacea 'Eccentric'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 286,
      chnn_stock_retail: 584,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S4PPW5HR',
      statusContent: 'dot',
      hash: '7LSV8J63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LSV8J63'
    },
    {
      status: 210,
      sku: 'PM0039007',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266250',
      rtl_batch_array: '6266250',
      name_search: 'GEJMVU',
      name: "Geranium 'JS Matu Vu'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 589,
      chnn_stock_retail: 589,
      sppl_prcp: 3.299,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RYNEPKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RYNEPKP'
    },
    {
      status: 210,
      sku: 'PM0064359',
      core_name: 'Plant',
      sppl_ean: '8720664884595',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149719',
      rtl_batch_array: '6149719',
      name_search: 'SALYCIOI',
      name: 'Salvia lycioides',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1220,
      chnn_stock_retail: 1220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2DTS18J5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DTS18J5'
    },
    {
      status: 210,
      sku: 'PM0067513',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6267784',
      rtl_batch_array: '6268750, 6267784, 6291695',
      name_search: 'HAMACRA',
      name: 'Hakonechloa macra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7239,
      chnn_stock_retail: 25547,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKX1PER7;26R9HE2P',
      statusContent: 'dot',
      hash: 'K2CVEEVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2CVEEVK'
    },
    {
      status: 910,
      sku: 'PM0059941',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GYPFLAMI',
      name: "Gypsophila paniculata 'Flamingo'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'HLC9CP1K;NJK3KBYL',
      statusContent: 'dot',
      hash: 'G8G6G7GJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G8G6G7GJ'
    },
    {
      status: 210,
      sku: 'PM0059976',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353142',
      rtl_batch_array: '6353142, 6380821',
      name_search: 'PEAPENDU',
      name: 'Persicaria amplexicaulis pendula',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1056,
      chnn_stock_retail: 1092,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYZJ25L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYZJ25L2'
    },
    {
      status: 910,
      sku: 'PM0071527',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'N8TKL5SJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N8TKL5SJ'
    },
    {
      status: 210,
      sku: 'PM0059987',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268816',
      rtl_batch_array: '6268816',
      name_search: 'RHALEXAN',
      name: 'Rheum alexandrae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L6WD6EYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6WD6EYC'
    },
    {
      status: 210,
      sku: 'PM0059985',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353220',
      rtl_batch_array: '6353220',
      name_search: 'POCRANTZ',
      name: 'Potentilla crantzii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 537,
      chnn_stock_retail: 537,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LAYPJV92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAYPJV92'
    },
    {
      status: 210,
      sku: 'PM0059968',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308706',
      rtl_batch_array: '6295956, 6308706, 6386149',
      name_search: 'NECPAJAM',
      name: "Nepeta 'Cat's Pajamas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 765,
      chnn_stock_retail: 2522,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C8785KLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8785KLG'
    },
    {
      status: 210,
      sku: 'PM0071524',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267726',
      rtl_batch_array: '6267726',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: 'APK1L5TA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APK1L5TA'
    },
    {
      status: 210,
      sku: 'PM0079602',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329890',
      rtl_batch_array: '6329890',
      name_search: 'CAPOCTOP',
      name: "Campanula 'Pink Octopus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 241,
      chnn_stock_retail: 241,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2KGE7DKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KGE7DKY'
    },
    {
      status: 210,
      sku: 'PM0000540',
      core_name: 'Plant',
      sppl_ean: '8720664691063',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5756038',
      rtl_batch_array: '5756038',
      name_search: 'GEWLASSO',
      name: 'Geranium wlassovianum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 428,
      chnn_stock_retail: 428,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XWCYCDYR',
      imageBatch: 'GGDP4ALC',
      statusContent: 'dot',
      hash: 'P57TSDS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P57TSDS5'
    },
    {
      status: 210,
      sku: 'PM0079603',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349784',
      rtl_batch_array: '6329891, 6349784',
      name_search: 'CATAKESI',
      name: 'Campanula takesimana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 1019,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KC5AEB5Y;5C64NZ3V;LX95HXLN;5ZJX9ZJX',
      statusContent: 'dot',
      hash: '5BZ43DHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BZ43DHC'
    },
    {
      status: 910,
      sku: 'PM0079604',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABALBOL',
      name: "Carex brunnea 'Albolineata'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'RH8ZR55G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RH8ZR55G'
    },
    {
      status: 210,
      sku: 'PM0079605',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329894',
      rtl_batch_array: '6329894',
      name_search: 'CENIGRA',
      name: 'Centaurea nigra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1590,
      chnn_stock_retail: 1590,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ECXHHD4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECXHHD4H'
    },
    {
      status: 210,
      sku: 'PM0079606',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333212',
      rtl_batch_array: '6333212',
      name_search: 'CHNOBILE',
      name: 'Chamaemelum nobile',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1029,
      chnn_stock_retail: 1029,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TJV23K1K;N24XGWTA;SB686GN9;TEDP147R;2ZRSS56G;KJN5BCV2;VLSC82NE;992E3SD3',
      statusContent: 'dot',
      hash: '2S2PDJ2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S2PDJ2R'
    },
    {
      status: 210,
      sku: 'PM0079607',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329897',
      rtl_batch_array: '6329897',
      name_search: 'CLBCREPU',
      name: "Clematis bonstedtii 'Cr\u00e9puscule'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZA8ZK75A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZA8ZK75A'
    },
    {
      status: 810,
      sku: 'PM0039027',
      core_name: 'Plant',
      sppl_ean: '8720349406579',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849967',
      rtl_batch_array: '5849967',
      name_search: 'PALADUFF',
      name: "Paeonia (LD) 'Lady Alexandra Duff'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_prcp: 3.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G75CA3N9;6D6N361W;4BTGXKHZ',
      statusContent: 'dot',
      hash: 'GDDYA6J4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDDYA6J4'
    },
    {
      status: 210,
      sku: 'PM0059949',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217176',
      rtl_batch_array: '6350420, 6217176',
      name_search: 'HEUMFIRE',
      name: "Heuchera 'Melting Fire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1299,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z6DXZS3E;5H29D3CX;RLHJ121W',
      statusContent: 'dot',
      hash: '7TTYHGTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TTYHGTJ'
    },
    {
      status: 210,
      sku: 'PM0059931',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6266380',
      rtl_batch_array: '6350174, 6266380',
      name_search: 'GAIASUN',
      name: "Gaillardia 'Arizona Sun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2943,
      chnn_stock_retail: 3159,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BJKCCR8B;RV5S3SD5;RTY5PN7V;EETBLB6V;SXDALAYV;6L5H5HD2;S1W897JA;45HRK4WZ;XK3CPT44;69TV778T;HVA5TLBT;PTX9EKT5;SL2CPVVK;C6AG99G3;15GTTAEC;1KLVLGRK;PG61DVSA;V7889T3T',
      statusContent: 'dot',
      hash: 'LH8N7C7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH8N7C7T'
    },
    {
      status: 210,
      sku: 'PM0079608',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329899',
      rtl_batch_array: '6329899',
      name_search: 'CLPRAECO',
      name: "Clematis 'Praecox'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B6B8DWR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6B8DWR8'
    },
    {
      status: 810,
      sku: 'PM0079609',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329900',
      rtl_batch_array: '6329900',
      name_search: 'COMBORDE',
      name: "Convallaria majalis 'Bordeaux'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z2JDCXS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2JDCXS3'
    },
    {
      status: 210,
      sku: 'PM0067111',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307809',
      rtl_batch_array: '6307809',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: 'ZE9VD2XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZE9VD2XE'
    },
    {
      status: 910,
      sku: 'PM0079610',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COMFGIAN',
      name: "Convallaria majalis 'Fortin's Giant'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'Z28G1HE8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z28G1HE8'
    },
    {
      status: 910,
      sku: 'PM0071529',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'PK28ATV2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PK28ATV2'
    },
    {
      status: 210,
      sku: 'PM0079611',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329902',
      rtl_batch_array: '6329902',
      name_search: 'COGARNET',
      name: "Coreopsis 'Garnet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RTH6W1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RTH6W1E'
    },
    {
      status: 210,
      sku: 'PM0079612',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329903',
      rtl_batch_array: '6329903',
      name_search: 'COHRGOLD',
      name: 'Coreopsis Honeybunch Red & Gold',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJEB7JVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJEB7JVX'
    },
    {
      status: 810,
      sku: 'PM0059960',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355371',
      rtl_batch_array: '6350650, 6355371',
      name_search: 'LIDBMCRA',
      name: "Ligularia dentata 'Britt-Marie Crawford'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3GY8E7L;PNG54DJZ;S2JNDWG9',
      statusContent: 'dot',
      hash: 'LHRTETK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHRTETK1'
    },
    {
      status: 210,
      sku: 'PM0079613',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329904',
      rtl_batch_array: '6329904',
      name_search: 'COLRUBY',
      name: "Coreopsis 'Limerock Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 621,
      chnn_stock_retail: 621,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SLWCY7T4;SX3VVK17',
      statusContent: 'dot',
      hash: 'P9N36T8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9N36T8C'
    },
    {
      status: 210,
      sku: 'PM0059975',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352665',
      rtl_batch_array: '6353141, 6352665',
      name_search: 'PEAORANG',
      name: "Persicaria amplexicaulis 'Orangofield'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 398,
      chnn_stock_retail: 854,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS8LJB2D;VS7ND297;SG6Z6ADD;7BA14B2E;X5R5THG3;B9KE3BWX',
      statusContent: 'dot',
      hash: 'TSTVVCGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSTVVCGT'
    },
    {
      status: 210,
      sku: 'PM0010304',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254352',
      rtl_batch_array: '6129714, 6254352, 6348266',
      name_search: 'ACCAREND',
      name: "Aconitum carmichaelii 'Arendsii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1076,
      chnn_stock_retail: 2481,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EK1DS4YP;1JHYCNS3',
      statusContent: 'dot',
      hash: '7YNJW94K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YNJW94K'
    },
    {
      status: 210,
      sku: 'PM0059933',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350367',
      rtl_batch_array: '6350367',
      name_search: 'HENOBILI',
      name: 'Hepatica nobilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 739,
      chnn_stock_retail: 739,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4DYKKD4',
      statusContent: 'dot',
      hash: '9X66LW6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X66LW6C'
    },
    {
      status: 210,
      sku: 'PM0079614',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329905',
      rtl_batch_array: '6329905',
      name_search: 'COMRISIN',
      name: "Coreopsis 'Mercury Rising'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XZ9EC37Y;KDD54C29;YR9V7ADY;Y4ZWTS2D;ERXDJTHJ;7VSSGVX6;E11VXPCJ;KBXSS45J;7VN1SK1W',
      statusContent: 'dot',
      hash: 'CSEN73S3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSEN73S3'
    },
    {
      status: 210,
      sku: 'PM0079615',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329906',
      rtl_batch_array: '6329906',
      name_search: 'ECRORANG',
      name: 'Echinacea Rhymes with Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 399,
      chnn_stock_retail: 399,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J9X9LJW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9X9LJW6'
    },
    {
      status: 210,
      sku: 'PM0059989',
      core_name: 'Plant',
      sppl_ean: '8720664883086',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129867',
      rtl_batch_array: '6129867',
      name_search: 'RHRGOLIA',
      name: "Rheum rhabarbarum 'Goliath'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PR69J3D6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR69J3D6'
    },
    {
      status: 810,
      sku: 'PM0059984',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353111',
      rtl_batch_array: '6353111',
      name_search: 'PLLANCEO',
      name: 'Plantago lanceolata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XVS2A9PP;23WYZ6S1',
      statusContent: 'dot',
      hash: 'N2SLBGXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2SLBGXE'
    },
    {
      status: 210,
      sku: 'PM0000665',
      core_name: 'Plant',
      sppl_ean: '8720349424627',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '5439746',
      rtl_batch_array: '5837472, 5439746',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 983,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      imageBatch: 'V5JERAGR',
      statusContent: 'dot',
      hash: 'PVYSPCJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVYSPCJJ'
    },
    {
      status: 210,
      sku: 'PM0071530',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229962',
      rtl_batch_array: '6229962',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 27.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: '5JNPW8SN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JNPW8SN'
    },
    {
      status: 810,
      sku: 'PM0071526',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307718',
      rtl_batch_array: '6307718',
      name_search: 'CEDBELEC',
      name: "Cedrus deodara 'Bushes Electra'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 11.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VT62RW3T;KEYJTB5R;KTXEXH5G;7YGWNTDB',
      statusContent: 'dot',
      hash: 'JTC3LD14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTC3LD14'
    },
    {
      status: 810,
      sku: 'PM0071521',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281201',
      rtl_batch_array: '6281201',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.072,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'XT9R9D8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT9R9D8L'
    },
    {
      status: 210,
      sku: 'PM0012598',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349046',
      rtl_batch_array: '6329835, 6340054, 6349046',
      name_search: 'AQNBARLO',
      name: "Aquilegia 'Nora Barlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 674,
      chnn_stock_retail: 1939,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJYD9YN9;RNVJDKHJ;S867E1TE;V4HR2LVY;6115WD37;8Z748YXJ;ELCJSGA1;RDAJXB9S',
      statusContent: 'dot',
      hash: 'VE1XRX16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE1XRX16'
    },
    {
      status: 910,
      sku: 'PM0059965',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LYFCNANA',
      name: "Lychnis flos-cuculi 'Nana'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'SJJZ2HZC;2T9EHHSP;D7PP2J3N;6EBC4958;SGLDLNGW;P6G7L8LZ;21JHS2AY;VHZGT2C7;YH3XDNCK',
      statusContent: 'dot',
      hash: 'VHT8NXYC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VHT8NXYC'
    },
    {
      status: 210,
      sku: 'PM0020944',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230509',
      rtl_batch_array: '6230509',
      name_search: 'ALMILLEN',
      name: "Allium 'Millennium'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6101,
      chnn_stock_retail: 6101,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2RNGJC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2RNGJC6'
    },
    {
      status: 210,
      sku: 'PM0065022',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307844',
      rtl_batch_array: '6307844',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: '4E79YEWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E79YEWL'
    },
    {
      status: 210,
      sku: 'PM0059994',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353344',
      rtl_batch_array: '6353344',
      name_search: 'SARHTRUM',
      name: "Salvia roemeriana 'Hot Trumpets'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZY39NT;VHHZJ9BY',
      statusContent: 'dot',
      hash: 'S2TNB89A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2TNB89A'
    },
    {
      status: 910,
      sku: 'PM0039025',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAAFLEMI',
      name: "Paeonia (LD) 'Alexander Fleming'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'ZTBXSZWX;VB3SSYNC;T7EKCVDT;AE7L2LZA;WSP6PVP9;981PNS5L;43DPT7ZX;ABLNNCLS;ZERSVYV4;WSWEJW4W;GCAGS2LV',
      statusContent: 'dot',
      hash: 'H29S3ZJ7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H29S3ZJ7'
    },
    {
      status: 210,
      sku: 'PM0059972',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350724',
      rtl_batch_array: '6350724',
      name_search: 'ONSPINOS',
      name: 'Ononis spinosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1TE7ZN5E;CNYS73WN',
      statusContent: 'dot',
      hash: 'HL26JR5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HL26JR5S'
    },
    {
      status: 210,
      sku: 'PM0039033',
      core_name: 'Plant',
      sppl_ean: '8720664807976',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856626',
      rtl_batch_array: '5856626',
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 3513,
      chnn_stock_retail: 3513,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9251DGD;4AK589AB;GT6C1BJL;77RP3J8Y;4ZPJNXK5;RHJJ77R5;1E1112G6;A3KPK1CH;DCNZEK7T;6CZ7HE6H;5EEDE6GG;A535J46V;VERWST57;13ZDCST5;8S57K3RX;TPNXG1TC;LS2CKYLV;REENVR6J',
      imageBatch: 'PS9HJ5BJ',
      statusContent: 'dot',
      hash: 'BDB7PW8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDB7PW8C'
    },
    {
      status: 210,
      sku: 'PM0007046',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6229809',
      rtl_batch_array: '6229809, 6350036, 6284218',
      name_search: 'ECPDDECK',
      name: "Echinacea purpurea 'Double-Decker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 476,
      chnn_stock_retail: 15705,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NS4B1HS;BXRA4R48;43BSBDWD',
      statusContent: 'dot',
      hash: '639ANTYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '639ANTYL'
    },
    {
      status: 210,
      sku: 'PM0059947',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350321',
      rtl_batch_array: '6350321',
      name_search: 'HEMICROC',
      name: 'Helianthus microcephalus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2NAX9H6',
      statusContent: 'dot',
      hash: '6RGPAV14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RGPAV14'
    },
    {
      status: 210,
      sku: 'PM0068446',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6298611',
      rtl_batch_array: '6298611, 6378344',
      name_search: 'HEHJENNA',
      name: 'Hebe Hebedonna Jenna',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 919,
      chnn_stock_retail: 3802,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6WNXTPRB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WNXTPRB'
    },
    {
      status: 910,
      sku: 'PM0063776',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307842',
      rtl_batch_array: '6307842',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 10.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: 'CW4DGDVG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW4DGDVG'
    },
    {
      status: 210,
      sku: 'PM0029142',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228450',
      rtl_batch_array: '6228450',
      name_search: 'ILCJELEN',
      name: "Ilex crenata 'Jelena'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3711,
      chnn_stock_retail: 3711,
      sppl_prcp: 1.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JDDJ6RDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDDJ6RDS'
    },
    {
      status: 810,
      sku: 'PM0059986',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353247',
      rtl_batch_array: '6301585, 6353247',
      name_search: 'PURREDST',
      name: "Pulmonaria rubra 'Redstart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 248,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A6TSSCWE',
      statusContent: 'dot',
      hash: 'WLXW1ZC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLXW1ZC9'
    },
    {
      status: 210,
      sku: 'PM0071758',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233433',
      rtl_batch_array: '6233433',
      name_search: 'RIASCHMI',
      name: "Ribes alpinum 'Schmidt'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVALV4JT',
      statusContent: 'dot',
      hash: 'DXRJL95N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXRJL95N'
    },
    {
      status: 210,
      sku: 'PM0071738',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227918',
      rtl_batch_array: '6227918',
      name_search: 'TABREPAN',
      name: "Taxus baccata 'Repandens'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1744,
      chnn_stock_retail: 1744,
      sppl_prcp: 7.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '43L48WA5;RZ7W67KK;SKTPY2R1;T9799XAC;REHZ57Z8;JGK4PTX4;4E58PEN2;6WVK1D3A;N3C2RV34;C2W3JNX3;184LH1TA;LKHNSLNC',
      statusContent: 'dot',
      hash: 'NXAL6RN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXAL6RN4'
    },
    {
      status: 210,
      sku: 'PM0060000',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353480',
      rtl_batch_array: '6353480',
      name_search: 'SYGGOLDS',
      name: "Symphytum grandiflorum 'Goldsmith'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SKLH7K91;AGA74R6G',
      statusContent: 'dot',
      hash: 'YEY8S9BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEY8S9BW'
    },
    {
      status: 210,
      sku: 'PM0014762',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353499',
      rtl_batch_array: '6353499',
      name_search: 'VISRUBRA',
      name: "Viola sororia 'Rubra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8ZKSX8L4;824YNC54;CRNVS3XN;NGBRB83W;PAZGTN2H;NDVCD5TG;DT79YYVB;4643181X',
      statusContent: 'dot',
      hash: '134J3Z2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '134J3Z2D'
    },
    {
      status: 210,
      sku: 'PM0006124',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269615',
      rtl_batch_array: '6269615, 5401586, 6349790',
      name_search: 'ERCURVUL',
      name: 'Eragrostis curvula',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 969,
      chnn_stock_retail: 1126,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YK8VYNLC;ZAN8VVG1;GETRHHX9;DSS7YKNC;N3YC5CBX',
      statusContent: 'dot',
      hash: '1HA77W2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HA77W2D'
    },
    {
      status: 210,
      sku: 'PM0071745',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246573',
      rtl_batch_array: '6246573',
      name_search: 'CHPSUNGO',
      name: "Chamaecyparis pisifera 'Sungold'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 3345,
      chnn_stock_retail: 3345,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZHLJTR1H;5XZEYB8G;7YNLHJLH;Y1BTW97G;GZJG415D;ECLLZG7H;JAYJDR7D;Z2T7J22C;5592HDCX;AA25R4RZ;2Z5DKD8B;9CYC76PC;ZHDTBX3N;B9C4KJ17;5D5APVLG;64XLHEK9;WP8N54HS',
      statusContent: 'dot',
      hash: 'S151VERS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S151VERS'
    },
    {
      status: 210,
      sku: 'PM0039083',
      core_name: 'Plant',
      sppl_ean: '8720353013206',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5867461',
      rtl_batch_array: '5867461',
      name_search: 'PHLSSWIN',
      name: 'Phlox (P) Sweet Summer Wine',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4235,
      chnn_stock_retail: 4235,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LP384KW8;CEN13LYR',
      imageBatch: '1Y32D1ZR',
      statusContent: 'dot',
      hash: 'K68JGPSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K68JGPSW'
    },
    {
      status: 810,
      sku: 'PM0079640',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348327',
      rtl_batch_array: '6348327',
      name_search: 'GERTCSCH',
      name: "Geranium renardii 'Tcschelda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7SNY7CHV;LP92CSZC;BPH6YC6J',
      statusContent: 'dot',
      hash: '93GT4TYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93GT4TYG'
    },
    {
      status: 210,
      sku: 'PM0071733',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227913',
      rtl_batch_array: '6227913',
      name_search: 'PISWATER',
      name: "Pinus sylvestris 'Watereri'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJDV27VG;HHKC8HHY;6TD9W7HW;Y9NEB4C8;ZD8KPPRT',
      statusContent: 'dot',
      hash: 'G2L122G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2L122G5'
    },
    {
      status: 210,
      sku: 'PM0068040',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6195648',
      rtl_batch_array: '6195648',
      name_search: 'PIGDWHIT',
      name: "Picea glauca 'Daisy's White'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1953,
      chnn_stock_retail: 1953,
      sppl_prcp: 5.339,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WTTH4V95;Z52YCVEL;C7Y2TSEZ;X6KB4DZS;545SPPKJ;R12DKC7E;L8XX87BC;WWZ4R5SZ',
      statusContent: 'dot',
      hash: '7GW7ERZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GW7ERZ4'
    },
    {
      status: 210,
      sku: 'PM0071741',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280180',
      rtl_batch_array: '6280180',
      name_search: 'THP4EVER',
      name: "Thuja plicata '4ever'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2180,
      chnn_stock_retail: 2180,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K6C7KG5H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6C7KG5H'
    },
    {
      status: 210,
      sku: 'PM0082284',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349885',
      rtl_batch_array: '6349885',
      name_search: 'CAALLIAR',
      name: 'Campanula alliariifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T317XB66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T317XB66'
    },
    {
      status: 210,
      sku: 'PM0005293',
      core_name: 'Plant',
      sppl_ean: '8720626375437',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '5423436',
      rtl_batch_array: '5423436, 6336320',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1350,
      chnn_stock_retail: 4033,
      sppl_prcp: 5.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'ZP8PEBZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP8PEBZC'
    },
    {
      status: 910,
      sku: 'PM0071731',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 2,
      btch_active_retail: '6286146',
      rtl_batch_array: '6246579, 6286146',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 9,
      chnn_stock_retail: 10880,
      sppl_prcp: 3.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'LH6VVVZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH6VVVZN'
    },
    {
      status: 210,
      sku: 'PM0071743',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257823',
      rtl_batch_array: '6257823',
      name_search: 'ARARAUCA',
      name: 'Araucaria araucana',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1315,
      chnn_stock_retail: 1315,
      sppl_prcp: 8.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJ216A9V;EDYSRYY5;SH9C4CBJ;JDNHK1AL;EA81GB3Y;C165X1H9;9XRY3E72;47RE5E7G;P3BJNJY2',
      statusContent: 'dot',
      hash: 'HCC17V2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCC17V2V'
    },
    {
      status: 210,
      sku: 'PM0085684',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386050',
      rtl_batch_array: '6386050',
      name_search: 'ANTSWAVE',
      name: "Anaphalis triplinervis 'Silver Wave'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 381,
      chnn_stock_retail: 381,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6XCRJ9DZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XCRJ9DZ'
    },
    {
      status: 210,
      sku: 'PM0065310',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244382',
      rtl_batch_array: '6244382',
      name_search: 'JUPMJULE',
      name: "Juniperus pfitzeriana 'Mint Julep'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2290,
      chnn_stock_retail: 2290,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ECA5W8B7;WDB1X2HH;G9494HJY;H8A3JGVH;97N7RTGT;TL2TY95E;TEPY57C3;4G143PN2;2KL5BZSJ;ZRYC7KKP;AXK4LGDR;11WNEGGE;AJ126KGJ;B7D947SS;X78L6BGC',
      statusContent: 'dot',
      hash: 'ZEB24ZA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZEB24ZA1'
    },
    {
      status: 210,
      sku: 'PM0085685',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386055',
      rtl_batch_array: '6386055',
      name_search: 'ASEESTHE',
      name: "Aster ericoides 'Esther'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCS8239A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCS8239A'
    },
    {
      status: 210,
      sku: 'PM0085686',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386056',
      rtl_batch_array: '6386056',
      name_search: 'ASLCHLOE',
      name: "Aster lateriflorus 'Chloe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 403,
      chnn_stock_retail: 403,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJSWV74E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJSWV74E'
    },
    {
      status: 210,
      sku: 'PM0067517',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6197132',
      rtl_batch_array: '6197132, 6037896, 6348326',
      name_search: 'GEMWNESS',
      name: "Geranium macrorrhizum 'White Ness'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 4006,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V3C671HJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3C671HJ'
    },
    {
      status: 210,
      sku: 'PM0060008',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353500',
      rtl_batch_array: '6353500',
      name_search: 'VIWSGEM',
      name: "Viola walteri 'Silver Gem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 243,
      chnn_stock_retail: 243,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8TKS3RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8TKS3RJ'
    },
    {
      status: 210,
      sku: 'PM0060009',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353526',
      rtl_batch_array: '6353526',
      name_search: 'TRRALBUM',
      name: "Trifolium rubens 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W5NAVYJK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5NAVYJK'
    },
    {
      status: 910,
      sku: 'PM0060005',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VEARMENA',
      name: 'Veronica armena',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CCST8LW4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CCST8LW4'
    },
    {
      status: 910,
      sku: 'PM0071714',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAPTSERE',
      name: "Salvia pratensis 'Twilight Serenade'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'Z5EASX8R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z5EASX8R'
    },
    {
      status: 210,
      sku: 'PM0085687',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386061',
      rtl_batch_array: '6386061',
      name_search: 'ANTLICE',
      name: "Anthemis tinctoria 'Lemon Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CCETSL94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCETSL94'
    },
    {
      status: 210,
      sku: 'PM0085688',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386062',
      rtl_batch_array: '6386062',
      name_search: 'ARFCOGOL',
      name: "Arabis ferdinandi-coburgii 'Old Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4C3XD5S8;AYWNL4PP;HLGAGSZ2;WY5E233H;8V8A4WB1;BCDKZJP5;JEPRKE7K;1Z39C9C8;DRSL9J4X;LAKWT5H3;12WTRAZE;DL99ZNYG;PS62SJN4;G13K4T3K;NXZ7VV5N;64B2RSXG;XBSAZSA8;STBKEL27;L7RPH1X5;J3PRJ1XE',
      statusContent: 'dot',
      hash: '2SAG835W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SAG835W'
    },
    {
      status: 810,
      sku: 'PM0071721',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6219664',
      rtl_batch_array: '6219664',
      name_search: 'CEPULCHE',
      name: 'Centaurea pulcherrima',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '61AA3229',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61AA3229'
    },
    {
      status: 210,
      sku: 'PM0079622',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348974',
      rtl_batch_array: '6329916, 6320855, 6348974',
      name_search: 'ECPAUGUS',
      name: "Echinacea purpurea 'Augustk\u00f6nigin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1051,
      chnn_stock_retail: 3733,
      sppl_order_minimum: 3,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PC8ZN98T;H28VVV6T;2YSWBWLT;KPARJN76;7JWDZ5EE;XVLAW5HX',
      statusContent: 'dot',
      hash: 'JGD42ZTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGD42ZTA'
    },
    {
      status: 810,
      sku: 'PM0079623',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329917',
      rtl_batch_array: '6329917',
      name_search: 'AGMEIBON',
      name: "Agapanthus 'Meibont'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 3,
      sppl_prcp: 1.765,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6BE9NJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6BE9NJZ'
    },
    {
      status: 210,
      sku: 'PM0085689',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386063',
      rtl_batch_array: '6386063',
      name_search: 'ARFCVARI',
      name: "Arabis ferdinandi-coburgii 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XC4VDDR1',
      statusContent: 'dot',
      hash: 'K6595BBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6595BBY'
    },
    {
      status: 210,
      sku: 'PM0079624',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329918',
      rtl_batch_array: '6329918',
      name_search: 'DIFIRECR',
      name: "Digitalis 'Firecracker'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.462,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PPVRPANW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPVRPANW'
    },
    {
      status: 910,
      sku: 'PM0079625',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GYPFPINK',
      name: 'Gypsophila paniculata Festival Pink',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '7LDPZL7D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7LDPZL7D'
    },
    {
      status: 210,
      sku: 'PM0079626',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329920',
      rtl_batch_array: '6329920',
      name_search: 'GYFWFLAR',
      name: "Gypsophila ' Festival White Flare'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 2.169,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WXJT5TER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXJT5TER'
    },
    {
      status: 910,
      sku: 'PM0079627',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECOPASSI',
      name: 'Echinacea Orange Passion',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'ZCRTT26G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZCRTT26G'
    },
    {
      status: 210,
      sku: 'PM0065125',
      core_name: 'Plant',
      sppl_ean: '8720664863743',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161686',
      rtl_batch_array: '6161686',
      name_search: 'FRASWHIT',
      name: 'Fragaria ananassa Snow White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 688,
      chnn_stock_retail: 688,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '41T72BPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41T72BPY'
    },
    {
      status: 810,
      sku: 'PM0085690',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386065',
      rtl_batch_array: '6386065',
      name_search: 'ARSUENDE',
      name: 'Arabis suendermannii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RWN4SCTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWN4SCTN'
    },
    {
      status: 210,
      sku: 'PM0079628',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329922',
      rtl_batch_array: '6329922, 6320865',
      name_search: 'ECSORANG',
      name: 'Echinacea Sunseekers Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1132,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CPL5R56R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPL5R56R'
    },
    {
      status: 210,
      sku: 'PM0079629',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333389',
      rtl_batch_array: '6329923, 6333389',
      name_search: 'ECTRTOP',
      name: 'Echinacea tennesseensis Rocky Top Hybrids',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 394,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CSG96SWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSG96SWC'
    },
    {
      status: 810,
      sku: 'PM0079630',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355357',
      rtl_batch_array: '6329924, 6355357',
      name_search: 'ERFLIEBL',
      name: "Erigeron 'Foersters Liebling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 169,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AE6EHZ9A',
      statusContent: 'dot',
      hash: 'E4PJG2RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4PJG2RB'
    },
    {
      status: 210,
      sku: 'PM0085691',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386068',
      rtl_batch_array: '6386068',
      name_search: 'ARDONAX',
      name: 'Arundo donax',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 2.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRL8WBHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRL8WBHZ'
    },
    {
      status: 210,
      sku: 'PM0079631',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329926',
      rtl_batch_array: '6329926',
      name_search: 'ERSOMMER',
      name: "Erigeron 'Sommerabend'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 286,
      chnn_stock_retail: 286,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6TC337G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6TC337G'
    },
    {
      status: 810,
      sku: 'PM0079632',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329927',
      rtl_batch_array: '6329927',
      name_search: 'ERPTJACK',
      name: "Eryngium planum 'Tiny Jackpot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V6RW6K44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6RW6K44'
    },
    {
      status: 210,
      sku: 'PM0079633',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329928',
      rtl_batch_array: '6329928',
      name_search: 'EUCSBURG',
      name: "Eucomis comosa 'Sparkling Burgundy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K9YV4WED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9YV4WED'
    },
    {
      status: 210,
      sku: 'PM0079634',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329929',
      rtl_batch_array: '6329929',
      name_search: 'EUPERFOL',
      name: 'Eupatorium perfoliatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 472,
      chnn_stock_retail: 472,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A4VYWWW8',
      statusContent: 'dot',
      hash: '28NHD7G7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28NHD7G7'
    },
    {
      status: 210,
      sku: 'PM0001529',
      core_name: 'Plant',
      sppl_ean: '8720626347854',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 5,
      btch_active_retail: '5364077',
      rtl_batch_array: '6311019, 6161694, 5364077, 6351245, 6333238',
      name_search: 'GEARUSH',
      name: "Geranium 'Azure Rush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7962,
      chnn_stock_retail: 19234,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVSG4AS8',
      statusContent: 'dot',
      hash: '9X3SKPDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X3SKPDV'
    },
    {
      status: 210,
      sku: 'PM0064361',
      core_name: 'Plant',
      sppl_ean: '8720664884748',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149721',
      rtl_batch_array: '6149721',
      name_search: 'SANSCHNE',
      name: "Salvia nemorosa 'Schneeh\u00fcgel'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6123,
      chnn_stock_retail: 6123,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8R3CD1WS;NR9GEH7B;RK2RJPY3;LW2R47G7;2TPTNYLL;1SEKLWVT;JL46NNHZ;CHA4A52T;D7V7WJYT',
      statusContent: 'dot',
      hash: 'W4SNX4TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4SNX4TZ'
    },
    {
      status: 210,
      sku: 'PM0067129',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177039',
      rtl_batch_array: '6177039',
      name_search: 'PIGLAURI',
      name: "Picea glauca 'Laurin'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 958,
      chnn_stock_retail: 958,
      sppl_prcp: 4.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PH966BDR',
      statusContent: 'dot',
      hash: 'C6CGGERR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6CGGERR'
    },
    {
      status: 210,
      sku: 'PM0067125',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177035',
      rtl_batch_array: '6177035',
      name_search: 'JUPGSTAR',
      name: "Juniperus pfitzeriana 'Gold Star'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 798,
      chnn_stock_retail: 798,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PL8238PW;NL1SPXV8;SJS2EE6W;S73PXD3G;J24CLA6Z;VSNXR8ZX;7KDD6YGX;HZERSDKW;E1B9C9LX;C6R4GV65;CJP93ZXL',
      statusContent: 'dot',
      hash: 'GC1AEADJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GC1AEADJ'
    },
    {
      status: 210,
      sku: 'PM0079635',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370356',
      rtl_batch_array: '6370356, 6329930',
      name_search: 'EURUGOSU',
      name: 'Eupatorium rugosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 428,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALP61Z49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALP61Z49'
    },
    {
      status: 210,
      sku: 'PM0085692',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386070',
      rtl_batch_array: '6386070',
      name_search: 'ATVIDALI',
      name: 'Athyrium vidalii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZXKC9H1Y',
      statusContent: 'dot',
      hash: 'LTWHJTDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTWHJTDV'
    },
    {
      status: 910,
      sku: 'PM0039163',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SANOSTFR',
      name: "Salvia nemorosa 'Ostfriesland'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'L8CP8CVY;7HJDG7W5;WN5NK6H4;6S8768BL;738ADD1T;2CYAKL22;H44PR129;9BP6H4BZ;TGNL65XV;ZPXLDZW3;HZP275Y2;PEWXGZHY;7JKJ6VC7;9T4E9GVG;WPBS61E8;BD635VT7;TWBHZ4SY;RHYB8ZAJ',
      statusContent: 'dot',
      hash: 'PW18EE42',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PW18EE42'
    },
    {
      status: 210,
      sku: 'PM0085693',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386071',
      rtl_batch_array: '6386071',
      name_search: 'AUBLAUME',
      name: "Aubrieta 'Blaumeise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 546,
      chnn_stock_retail: 546,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9RYR5CVB;1KHRL6R5;4N94HPDA;WZE29YZ3;YK6NGT9K;DVY5RRAB;4GN3YEEG;X79TLY5C;PENYR9E5',
      statusContent: 'dot',
      hash: 'YYDA3TRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYDA3TRT'
    },
    {
      status: 210,
      sku: 'PM0085694',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386072',
      rtl_batch_array: '6386072',
      name_search: 'AUBRED',
      name: "Aubrieta 'Bressingham Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVVA978H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVVA978H'
    },
    {
      status: 910,
      sku: 'PM0079636',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUSNICIC',
      name: 'Euphorbia seguieriana niciciana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'ZN6XYDAS',
      statusContent: 'dot',
      hash: '1HZRZDN4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1HZRZDN4'
    },
    {
      status: 210,
      sku: 'PM0039131',
      core_name: 'Plant',
      sppl_ean: '8720349497096',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871830',
      rtl_batch_array: '5871830',
      name_search: 'CAFLAVA',
      name: 'Carex flava',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1391,
      chnn_stock_retail: 1391,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SLYKVZYN',
      statusContent: 'dot',
      hash: 'WER15NY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WER15NY2'
    },
    {
      status: 210,
      sku: 'PM0071709',
      core_name: 'Plant',
      sppl_ean: '8720349423972',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 3,
      btch_active_retail: '5519735',
      rtl_batch_array: '5519735, 4745654, 6336351',
      name_search: 'OPPNIGER',
      name: "Ophiopogon planiscapus 'Niger'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 387,
      chnn_stock_retail: 17328,
      sppl_prcp: 2.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25R4ACJC;BH5J4TVA;HTWGYL4C;DHA7PWLC;KT7BJXHS;R6SAL88Y;BZ9Y3Y79;Z78DL5DS;Z172P7HE;GC99JDYB;BPNTXA1P;GLZEYVJ8;Z876JDX3;7VPH93ZT;R8HL3A2P;GY1H5XS5;CSESV7VP;NXHY35VC;TZWK5HPE;PEAVW116;G9J6D69D;GPN8ETTH;X33GJ1S2;W9W7VNPW;WNEZ3V7E;GSKAGG83;6RN1EHK9',
      imageBatch: 'WWE7GYZD',
      statusContent: 'dot',
      hash: '787L6ZP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '787L6ZP9'
    },
    {
      status: 210,
      sku: 'PM0079637',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329934',
      rtl_batch_array: '6329934, 6348322',
      name_search: 'GALSFORM',
      name: "Gaura lindheimeri 'Short Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1980,
      chnn_stock_retail: 2813,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TZND97D4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZND97D4'
    },
    {
      status: 210,
      sku: 'PM0085695',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386073',
      rtl_batch_array: '6386073',
      name_search: 'AUROSENT',
      name: "Aubrieta 'Rosenteppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BP6XTSXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BP6XTSXA'
    },
    {
      status: 210,
      sku: 'PM0064363',
      core_name: 'Plant',
      sppl_ean: '8720664885943',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6149723',
      rtl_batch_array: '6149723',
      name_search: 'SESELSKI',
      name: 'Sedum selskianum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1466,
      chnn_stock_retail: 1466,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5KW5J93V',
      statusContent: 'dot',
      hash: 'W2A5BLDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2A5BLDA'
    },
    {
      status: 210,
      sku: 'PM0085696',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386074',
      rtl_batch_array: '6386074',
      name_search: 'AURUBINF',
      name: "Aubrieta 'Rubinfeuer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YJ91EDBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ91EDBE'
    },
    {
      status: 210,
      sku: 'PM0023616',
      core_name: 'Plant',
      sppl_ean: '8720664877047',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133475',
      rtl_batch_array: '6133475',
      name_search: 'PECMARE',
      name: "Penstemon 'Catherine de la Mare'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G8X8BY58;AJD16DKG;46C1V5LV',
      statusContent: 'dot',
      hash: 'SWW8L3DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWW8L3DP'
    },
    {
      status: 210,
      sku: 'PM0079638',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329936',
      rtl_batch_array: '6329936',
      name_search: 'GEDCRUG',
      name: "Geranium 'Dusky Cr\u00fbg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1108,
      chnn_stock_retail: 1108,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SN5W65TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN5W65TY'
    },
    {
      status: 210,
      sku: 'PM0079639',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329938',
      rtl_batch_array: '6329938',
      name_search: 'GEIBERIC',
      name: 'Geranium ibericum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 524,
      chnn_stock_retail: 524,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9K9Y81DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9K9Y81DA'
    },
    {
      status: 210,
      sku: 'PM0085697',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386075',
      rtl_batch_array: '6386075',
      name_search: 'ASORFAVO',
      name: "Aster oblongifolius 'Raydon's Favorite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 577,
      chnn_stock_retail: 577,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HG8K3V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HG8K3V2'
    },
    {
      status: 210,
      sku: 'PM0071700',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228987',
      rtl_batch_array: '6228987',
      name_search: 'AGPESKIM',
      name: "Agapanthus 'Petit Eskimo'",
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.232,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BSJ6Z77B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSJ6Z77B'
    },
    {
      status: 210,
      sku: 'PM0085698',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386077',
      rtl_batch_array: '6386077',
      name_search: 'ASUMBELL',
      name: 'Aster umbellatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 596,
      chnn_stock_retail: 596,
      sppl_order_minimum: 3,
      sppl_prcp: 0.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BEWJ5C38',
      statusContent: 'dot',
      hash: 'JDZC8L7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDZC8L7B'
    },
    {
      status: 210,
      sku: 'PM0085699',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386078',
      rtl_batch_array: '6386078',
      name_search: 'CABGTWIS',
      name: "Carex buchananii 'Green Twist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HCS49Y1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCS49Y1H'
    },
    {
      status: 210,
      sku: 'PM0085700',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386079',
      rtl_batch_array: '6386079',
      name_search: 'CALOSAKA',
      name: "Carex lenta 'Osaka'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P7B2LD6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7B2LD6Z'
    },
    {
      status: 810,
      sku: 'PM0085701',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386084',
      rtl_batch_array: '6386084',
      name_search: 'CATRSUNR',
      name: "Carex trifida 'Rekohu Sunrise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ857HA9',
      statusContent: 'dot',
      hash: 'KETVBRW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KETVBRW9'
    },
    {
      status: 210,
      sku: 'PM0085702',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386086',
      rtl_batch_array: '6386086',
      name_search: 'CEBIEBER',
      name: 'Cerastium biebersteinii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 496,
      chnn_stock_retail: 496,
      sppl_order_minimum: 3,
      sppl_prcp: 0.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X4RG5EE3;CYDZD41S;6PY2LA42;L1TDZ41X;X228JGPW;B8LL6GKC',
      statusContent: 'dot',
      hash: 'CWJ3BDC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWJ3BDC7'
    },
    {
      status: 210,
      sku: 'PM0071716',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222164',
      rtl_batch_array: '6222164',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: 'KHNT1X53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHNT1X53'
    },
    {
      status: 210,
      sku: 'PM0085703',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386088',
      rtl_batch_array: '6386088',
      name_search: 'CAGFREYA',
      name: "Campanula glomerata 'Freya'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VW1TJB9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VW1TJB9N'
    },
    {
      status: 210,
      sku: 'PM0085704',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386089',
      rtl_batch_array: '6386089',
      name_search: 'CAGGWHIT',
      name: 'Campanula glomerata Genti White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 1.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G6GXV25P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6GXV25P'
    },
    {
      status: 810,
      sku: 'PM0071720',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170318',
      rtl_batch_array: '6170318',
      name_search: 'ROVIRGIN',
      name: 'Rosa virginiana',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VWVK7K3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWVK7K3B'
    },
    {
      status: 210,
      sku: 'PM0085705',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386090',
      rtl_batch_array: '6386090',
      name_search: 'CAGSPECI',
      name: "Campanula glomerata 'Speciosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4K8CZ5KP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K8CZ5KP'
    },
    {
      status: 210,
      sku: 'PM0071728',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227907',
      rtl_batch_array: '6227907',
      name_search: 'JUSHOLGE',
      name: "Juniperus squamata 'Holger'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1549,
      chnn_stock_retail: 1549,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '27RDVKTT;CR8CL3Z4;DEXSKTNY;C8Z6KHZ6;BVE5HJDT;13CER4GX;T5JPZL46;S82JXW9H;SCY2ZHED;G2S4ZZVD;BAYEH66C',
      statusContent: 'dot',
      hash: 'R7KLW7HS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7KLW7HS'
    },
    {
      status: 210,
      sku: 'PM0071730',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227910',
      rtl_batch_array: '6227910',
      name_search: 'PIANIDIF',
      name: "Picea abies 'Nidiformis'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1044,
      chnn_stock_retail: 1044,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SDXTPGH;ZS2L161S;B24CVBXA;GPRNNAHK;Z4NB8RW8;WK976K43;H2XA7VDP;TA5725B7',
      statusContent: 'dot',
      hash: '6GDV9SDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GDV9SDR'
    },
    {
      status: 210,
      sku: 'PM0071735',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227915',
      rtl_batch_array: '6227915',
      name_search: 'TABDAVID',
      name: "Taxus baccata 'David'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 4147,
      chnn_stock_retail: 4147,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JGV8D2V;61VZV35Y;K2SB5P8G',
      statusContent: 'dot',
      hash: '8DPJCDXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DPJCDXW'
    },
    {
      status: 210,
      sku: 'PM0071737',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227917',
      rtl_batch_array: '6227917',
      name_search: 'TABFROBU',
      name: "Taxus baccata 'Fastigiata Robusta'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1336,
      chnn_stock_retail: 1336,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJRLWDE4;5WYTHSPR;5EJXXPAJ;7JH3XKZC;7K755T8W',
      statusContent: 'dot',
      hash: 'HT6JVRA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT6JVRA2'
    },
    {
      status: 210,
      sku: 'PM0071739',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227919',
      rtl_batch_array: '6227919',
      name_search: 'TAMFARME',
      name: "Taxus media 'Farmen'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 4235,
      chnn_stock_retail: 4235,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8Z7P59K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Z7P59K5'
    },
    {
      status: 910,
      sku: 'PM0071756',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFBLINK',
      name: "Potentilla fruticosa 'Blink'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      imageCore: '66LY5D8Y;HLAEKCP6;59DVX3AS;YS2ZBCEZ;L28DN3LJ;1J6D5Z9B',
      statusContent: 'dot',
      hash: 'V1BA9N6R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V1BA9N6R'
    },
    {
      status: 210,
      sku: 'PM0071798',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5382016',
      rtl_batch_array: '5382016',
      name_search: 'PEAFIREW',
      name: "Pennisetum advena 'Fireworks'",
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.891,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '41WDP4KL;EDKDXJ35;PBPRYJ35;YRLA5EPJ;24VHJ1LL;ATK3J5RZ;BSHR47RP;A13EY98K;KXC4E3KN;H5CXRHRJ;6V8H93KB;STJABS11;EPH4P72T;CB9H5Y5K;X7AHD1JX',
      imageBatch: 'DSNLDH5H',
      statusContent: 'dot',
      hash: 'ZA8YW2TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZA8YW2TN'
    },
    {
      status: 210,
      sku: 'PM0071799',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5382018',
      rtl_batch_array: '5382018',
      name_search: 'PEASROCK',
      name: "Pennisetum advena 'Sky Rocket'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.891,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '435EW1LD;XTBCHT5P;C9PJETT3;PHBERA2Y;W9SB7CP9;SYZE18JH;NVY44J2A;NHP4J3XS;25V4ZG3P;ZGEYJSB5;7GBS79WP;48RS2TDK;2H7E4214;PNNRWTTT;6A7GVJEN;BRH98LNJ;163PTSSZ;Y4K1GAT6',
      imageBatch: 'ZWZPK6K8',
      statusContent: 'dot',
      hash: '8NJZCCED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NJZCCED'
    },
    {
      status: 210,
      sku: 'PM0071421',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6229435',
      rtl_batch_array: '6349567, 6229435',
      name_search: 'ARPBPROS',
      name: "Armeria pseudarmeria 'Ballerina Purple Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 547,
      chnn_stock_retail: 1001,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SHS6YXDY;SW1GH9ZR;PBNSHJEE;2Y65T4WX;W7EVC6K8;6JPB37E9;VZ6YW2T2;1LLABV2B;6EZ31YZJ;XBHSG855;SXWCXAAS;RS3TVKWW;YS5DE5KP;R5XT8XVV;HGT66BTV;AW2STEPE;ACDCKW2S;V3KVP1WG;YPH6CA6S',
      statusContent: 'dot',
      hash: '8BBHNK2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BBHNK2D'
    },
    {
      status: 210,
      sku: 'PM0071717',
      core_name: 'Plant',
      sppl_ean: '8720626368613',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5964885',
      rtl_batch_array: '5964885',
      name_search: 'SAMICROP',
      name: 'Salvia microphylla',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1833,
      chnn_stock_retail: 1833,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKDKSVGT;KNKBAEAN;55JZJHW6;L1TER7L5;TGYR46NE;Y317ENBG;2BX3XTG8;4JN78K74;E5427TA6;T7442SWT;PGL3TECY;Z3AXXCBH;EDGWGRE1;6T46ZSHT;4K6VY247;EPP57ZKB;4ETTWH5B;WPXB9VJG;57VXAL3G',
      statusContent: 'dot',
      hash: 'X3LHGXDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3LHGXDX'
    },
    {
      status: 210,
      sku: 'PM0071722',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252534',
      rtl_batch_array: '6252534',
      name_search: 'DISPECTA',
      name: 'Dicentra spectabilis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S6CXXVX6',
      statusContent: 'dot',
      hash: '4AB19DYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AB19DYC'
    },
    {
      status: 910,
      sku: 'PM0071724',
      core_name: 'Plant',
      btch_manufacturer: 968,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224796',
      rtl_batch_array: '6224796',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '120130C30SP',
      rng_range_identifier: 'SPIRH120130C30',
      rng_range_description: 'Spiral H120 cm 130 cm C30',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'TLVJTE63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLVJTE63'
    },
    {
      status: 210,
      sku: 'PM0071725',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227899',
      rtl_batch_array: '6227899',
      name_search: 'CRJRASEN',
      name: "Cryptomeria japonica 'Rasen'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 823,
      chnn_stock_retail: 823,
      sppl_prcp: 5.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1D7GZN;JNVBLK37;DB633HJG',
      statusContent: 'dot',
      hash: '79A5CJLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79A5CJLG'
    },
    {
      status: 210,
      sku: 'PM0071726',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227900',
      rtl_batch_array: '6227900',
      name_search: 'CRJSEKKA',
      name: "Cryptomeria japonica 'Sekkan'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1194,
      chnn_stock_retail: 1194,
      sppl_prcp: 5.731,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VAZKC5ZK;6DR8KC7V;9N52LGJS;K117SWH7;DB5NT2XS;VYE52XJJ;4CEYSARP;27NPKPVB',
      statusContent: 'dot',
      hash: '28LZZNEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28LZZNEB'
    },
    {
      status: 210,
      sku: 'PM0071750',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6232057',
      rtl_batch_array: '6232057',
      name_search: 'PIPNEGIS',
      name: "Pinus parviflora 'Negishi'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 422,
      chnn_stock_retail: 422,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYJPKZV2;2NP81299;SA4VVH8E;H52BXA65',
      statusContent: 'dot',
      hash: 'W2JRCRZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2JRCRZ4'
    },
    {
      status: 210,
      sku: 'PM0071736',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227916',
      rtl_batch_array: '6227916',
      name_search: 'TABDAVID',
      name: "Taxus baccata 'David'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 2187,
      chnn_stock_retail: 2187,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JGV8D2V;61VZV35Y;K2SB5P8G',
      statusContent: 'dot',
      hash: '6ACSYBLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ACSYBLK'
    },
    {
      status: 210,
      sku: 'PM0071691',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233450',
      rtl_batch_array: '6233450',
      name_search: 'DECONGES',
      name: 'Delosperma congestum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2298,
      chnn_stock_retail: 2298,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y57XBK6A;9WCJ6S17;XE6PDYRK;KD9GCX5G;2WKSW7G4;2TXB22L8;4Y7RGSNH;TE62VB41;W4NCW1CS;1R8AKDJS;Z2R5B8LS;5E3N4VEN;D41BDE7N;44V1YEB2;KV1X2812;7VJ6WJXR;KH5H6YS3',
      statusContent: 'dot',
      hash: '1K3N6EWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K3N6EWX'
    },
    {
      status: 210,
      sku: 'PM0009954',
      core_name: 'Plant',
      sppl_ean: '8720664632349',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5372541',
      rtl_batch_array: '5372541, 6353430',
      name_search: 'SEFELDMA',
      name: "Sempervivum 'Feldmaier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 2101,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWHWYLRD;23DAR2X7;EVHE1SEL;TE8V5DJX;RW725AAP;RLWAWWE8;H8XZZA1X;KE1Z55E5;JRPE78TE',
      statusContent: 'dot',
      hash: 'J8D7WTDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8D7WTDP'
    },
    {
      status: 210,
      sku: 'PM0071729',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227908',
      rtl_batch_array: '6227908',
      name_search: 'LAKAEMPF',
      name: 'Larix kaempferi',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_prcp: 10.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2GR3C55;1JE1CHC2;JJ4BXCH4;LV3GWN1X;NPXHR7WP;GWAB5187;ZSPXSKNT;DC1251CR;DYK1AA8P;VB1ST6V9;PPDS8DWY;KK1DPR3D',
      statusContent: 'dot',
      hash: 'TZXKLD5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZXKLD5P'
    },
    {
      status: 210,
      sku: 'PM0071746',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6227930',
      rtl_batch_array: '6227930',
      name_search: 'CRJKGOLD',
      name: "Cryptomeria japonica 'Kyara Gold'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 631,
      chnn_stock_retail: 631,
      sppl_prcp: 7.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '22SNDV7Y;G1ZJ7YCV;D5T3K8Y6;8H3RLDHR;D8LXS59N;VP8RD5LS;2TLVXY41',
      statusContent: 'dot',
      hash: 'BBW6WWA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBW6WWA1'
    },
    {
      status: 910,
      sku: 'PM0071723',
      core_name: 'Plant',
      btch_manufacturer: 968,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223163',
      rtl_batch_array: '6223163',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '090100C20SP',
      rng_range_identifier: 'SPIRH090100C20',
      rng_range_description: 'Spiral H90 cm 100 cm C20',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 69.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'LJHEWED8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJHEWED8'
    },
    {
      status: 210,
      sku: 'PM0025039',
      core_name: 'Plant',
      sppl_ean: '8720664885516',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6084703',
      rtl_batch_array: '6084703, 5364500, 6133669, 6330112',
      name_search: 'SCCPMIST',
      name: "Scabiosa columbaria 'Pink Mist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1241,
      chnn_stock_retail: 3607,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2PKNNLY;Y9VCJZSB;DAPC8EPD;G8L1XJP9;P8SCRJH3;9DCWCDVV;76X5ZJZR',
      statusContent: 'dot',
      hash: '116XPBY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '116XPBY7'
    },
    {
      status: 910,
      sku: 'PM0067515',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GANIVALI',
      name: 'Galanthus nivalis',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'CBJ63N1B;9SR6NDJ2;XD9SB7PD;4JYPA2GN;XKS3RLEC;LCYC7LZZ;1ZECXBDJ;6J5TSDT5;2ZCJP2ZB;62REBP8T;T5DLDW96;ER1RDBE8;NLW5K748;R6XGC977;6WAV3KJL;X7G7B6XW;S3HH6LXD;GYWG5BPZ;9751J454;7KZYK3V7;5HZ368T2;N1B8X53C;D1YJ8XRB;LA15VJKY',
      statusContent: 'dot',
      hash: '9KEW2GHY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9KEW2GHY'
    },
    {
      status: 910,
      sku: 'PM0067514',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDI61707',
      name: "Fritillaria imperialis 'Rubra Maxima'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        '6TLXNLTK;WTTH7K7X;KV2H258B;CB2Y6YNA;N67LEXDE;1H219YWE;T7PLHELY;5TTNRNWG',
      statusContent: 'dot',
      hash: 'P9ZR322Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P9ZR322Y'
    },
    {
      status: 210,
      sku: 'PM0071801',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286491',
      rtl_batch_array: '6286491',
      name_search: 'ARAETHUS',
      name: 'Aruncus aethusifolius',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6630,
      chnn_stock_retail: 6630,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B82S79N;WJANAE6T;25Z8EWAD;APGG4RL8;6LCPKWD4;RE12TVSC;NW3H315C;RXJ3XT26',
      statusContent: 'dot',
      hash: 'AHCT1L7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHCT1L7Y'
    },
    {
      status: 210,
      sku: 'PM0082285',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349889',
      rtl_batch_array: '6349889',
      name_search: 'CAJENNY',
      name: "Campanula 'Jenny'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLR8RD6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLR8RD6D'
    },
    {
      status: 210,
      sku: 'PM0023086',
      core_name: 'Plant',
      sppl_ean: '8720664669048',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439509',
      rtl_batch_array: '5439509',
      name_search: 'RSOMMERW',
      name: 'Rosa (H) Sommerwind',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVRKWVY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVRKWVY1'
    },
    {
      status: 210,
      sku: 'PM0082286',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349890',
      rtl_batch_array: '6349890',
      name_search: 'CALBBLUE',
      name: 'Campanula lactiflora Border Blues',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SYYCSXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SYYCSXY'
    },
    {
      status: 210,
      sku: 'PM0020956',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284532',
      rtl_batch_array: '6350755, 6284532',
      name_search: 'MISZEBRI',
      name: "Miscanthus sinensis 'Zebrinus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 4468,
      sppl_prcp: 2.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJBCBBRB;ADKPAXAV;YS489W1S;XRPXYL62;8GZVNA6C;EPCW87X6;J1YTEA1P;1GC6D5AS;6CZRSXHC;N4Y9WKJG',
      statusContent: 'dot',
      hash: 'N15RTW75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N15RTW75'
    },
    {
      status: 810,
      sku: 'PM0082287',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349892',
      rtl_batch_array: '6349892',
      name_search: 'CAPBELLO',
      name: "Campanula persicifolia 'La Bello'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7P2WP192',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P2WP192'
    },
    {
      status: 810,
      sku: 'PM0055726',
      core_name: 'Plant',
      sppl_ean: '8720664869608',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5819001',
      rtl_batch_array: '5819001',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '060COBOL',
      rng_range_identifier: 'BALL\u00d8060H060060',
      rng_range_description: 'Ball \u00d8 60 cm H60 cm 60 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 60.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PJTREPBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJTREPBZ'
    },
    {
      status: 810,
      sku: 'PM0064373',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 3,
      btch_active_retail: '6380809',
      rtl_batch_array: '6228337, 6380809, 6386606',
      name_search: 'OSHGOSHI',
      name: "Osmanthus heterophyllus 'Goshiki'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 2337,
      sppl_prcp: 1.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7DT7HZTW;H3611KS5;HHWLDZG5;C4LVH8XZ;P61R6DLC;WVR7T9BV;YD81PWH8;VX335SLL;CNKTN4N8;GX5HBXJ9;NLYT7ZPN;26RLBC7W;D5PTRYSY;Y6LGAB3K;TKLEDCZP;XG83C181;CGDSPS5B;DC98HZPC',
      statusContent: 'dot',
      hash: 'ZPDBDVLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPDBDVLS'
    },
    {
      status: 210,
      sku: 'PM0071804',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301913',
      rtl_batch_array: '6301913, 6308685, 6339975',
      name_search: 'YUFILAME',
      name: 'Yucca filamentosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 366,
      chnn_stock_retail: 1275,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G8W1SGKZ;2TYTVK3S',
      statusContent: 'dot',
      hash: 'Z2DWCLPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2DWCLPT'
    },
    {
      status: 210,
      sku: 'PM0039242',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300728',
      rtl_batch_array: '5932384, 6300728',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1947,
      chnn_stock_retail: 13409,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: '1PSWPZXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PSWPZXN'
    },
    {
      status: 910,
      sku: 'PM0071764',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRCBRILL',
      name: "Crocosmia 'Carmine Brilliant'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'AJKA9LCH',
      statusContent: 'dot',
      hash: 'T2K953R9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T2K953R9'
    },
    {
      status: 210,
      sku: 'PM0071425',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234568',
      rtl_batch_array: '6234568',
      name_search: 'ARMSPLEN',
      name: "Armeria maritima 'Splendens'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 787,
      chnn_stock_retail: 787,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7W9819LA;GCGBAR2L;8HZLZ5A1;SH6T6YA2;EXJR741Z;CABJZDSL;WSBPEVKD;9G6EPLSV;BHPJBCNE',
      statusContent: 'dot',
      hash: '7HVNCGA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HVNCGA6'
    },
    {
      status: 210,
      sku: 'PM0071765',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236083',
      rtl_batch_array: '6236083',
      name_search: 'DECAMELL',
      name: "Delphinium (P) 'Camelliard'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_prcp: 2.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5HNDVW6L;C9RTL45R;W9HL8TY8;AH1C9K6L;5YKCHWTJ;14798SP8;TRSZGWZH',
      statusContent: 'dot',
      hash: 'NY555LB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NY555LB6'
    },
    {
      status: 910,
      sku: 'PM0071766',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEGALAHA',
      name: "Delphinium (P) 'Galahad'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '2H9ST199;Z4Y6J6YT;YPH9Y82H;RXX5YV67;9TDBPBKW;C1P7NX6T',
      statusContent: 'dot',
      hash: 'Y68NCY2T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y68NCY2T'
    },
    {
      status: 910,
      sku: 'PM0071537',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'JLJ2BX9J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JLJ2BX9J'
    },
    {
      status: 910,
      sku: 'PM0071543',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'ZNKEDJKS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZNKEDJKS'
    },
    {
      status: 210,
      sku: 'PM0085706',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386091',
      rtl_batch_array: '6386091',
      name_search: 'ACSENNII',
      name: 'Acanthus sennii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6PKV9CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6PKV9CH'
    },
    {
      status: 210,
      sku: 'PM0064374',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274770',
      rtl_batch_array: '6151149, 6274770',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 1450,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '8H4LT87K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H4LT87K'
    },
    {
      status: 810,
      sku: 'PM0085707',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386093',
      rtl_batch_array: '6386093',
      name_search: 'ACLKEDWA',
      name: "Achillea lewisii 'King Edward'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWCV5EG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWCV5EG8'
    },
    {
      status: 210,
      sku: 'PM0071701',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349965',
      rtl_batch_array: '6349965',
      name_search: 'CRFJUMPE',
      name: "Crocosmia 'Fire Jumper'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2S95W1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2S95W1L'
    },
    {
      status: 210,
      sku: 'PM0011900',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '6336439',
      rtl_batch_array: '6202297, 6161651, 6295603, 6336439, 6333239',
      name_search: 'GEMIVARI',
      name: "Geranium macrorrhizum 'Ingwersen's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9394,
      chnn_stock_retail: 21154,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BEHZP13D;1L15ZB4E;5HE2B34B;XTK5BB5W;NH36KGLW;XW4PYGV3;1XD4B9D7;L4BV6YDW;E7XZZ3WL;YEZKBGZS;CZ32WC7J;N76GA3TX;XPPYPY4H;T2ETJVDT;9XSVE9PW;H5WTB4D1;DD9VEP3N',
      statusContent: 'dot',
      hash: '993JXVT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '993JXVT4'
    },
    {
      status: 910,
      sku: 'PM0071536',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMSPICAT',
      name: 'Amelanchier spicata',
      rng_range_identifier: 'H040050C7',
      statusContent: 'dot',
      hash: '638CZL9X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '638CZL9X'
    },
    {
      status: 810,
      sku: 'PM0055730',
      core_name: 'Plant',
      sppl_ean: '8720664869615',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5819134',
      rtl_batch_array: '5819134',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '070COBOL',
      rng_range_identifier: 'BALL\u00d8070H070070',
      rng_range_description: 'Ball \u00d8 70 cm H70 cm 70 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 75.019,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ACX77ZKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACX77ZKK'
    },
    {
      status: 910,
      sku: 'PM0071538',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      rng_range_identifier: 'H040050C7',
      statusContent: 'dot',
      hash: 'C7KR5WT1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C7KR5WT1'
    },
    {
      status: 210,
      sku: 'PM0067521',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301587',
      rtl_batch_array: '6301587',
      name_search: 'GECHANTI',
      name: "Geranium 'Chantilly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TT3GPV3D',
      statusContent: 'dot',
      hash: 'LGCXRR5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGCXRR5W'
    },
    {
      status: 210,
      sku: 'PM0085708',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386095',
      rtl_batch_array: '6386095',
      name_search: 'AGASTAR',
      name: "Agapanthus 'Arctic Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XVH6YSA7;VLD6EZ5W;JB56N1YH;DRHB261Y;LT6EVGA5;8RTDT4HB;Z4B3WCCB;WWJ2H6DC;ZV5XRNSW;7GND9DPW;SCL9CLTS;JKPALRZ3;8RB2YN3A;JARZL211;VPPL89VW;NCVCTEP7;8VAB9VL2',
      statusContent: 'dot',
      hash: 'ZVERWG6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVERWG6S'
    },
    {
      status: 210,
      sku: 'PM0067519',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192768',
      rtl_batch_array: '6192768',
      name_search: 'GEOTWHIT',
      name: "Geranium oxonianum 'Trevor's White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67TVSX4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67TVSX4V'
    },
    {
      status: 810,
      sku: 'PM0067520',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192769',
      rtl_batch_array: '6192769',
      name_search: 'GEPPGHOS',
      name: "Geranium pratense 'Purple Ghost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6K4HT2LT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6K4HT2LT'
    },
    {
      status: 810,
      sku: 'PM0055724',
      core_name: 'Plant',
      sppl_ean: '8720664694569',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5288016',
      rtl_batch_array: '5288016',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '045050COBOL',
      rng_range_identifier: 'BALL\u00d8050H045050',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 49.976,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AGLYAC1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGLYAC1H'
    },
    {
      status: 210,
      sku: 'PM0085709',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386097',
      rtl_batch_array: '6386097',
      name_search: 'CASARMAT',
      name: 'Campanula sarmatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KCLNWEGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCLNWEGR'
    },
    {
      status: 210,
      sku: 'PM0050261',
      core_name: 'Plant',
      sppl_ean: '8720664610903',
      btch_manufacturer: 968,
      rtl_batch_array_total: 1,
      btch_active_retail: '5683058',
      rtl_batch_array: '5683058',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 34.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: 'PLZX6RAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLZX6RAL'
    },
    {
      status: 210,
      sku: 'PM0085710',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386098',
      rtl_batch_array: '6386098',
      name_search: 'CABROMOI',
      name: 'Carex bromoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VVXLDZH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVXLDZH2'
    },
    {
      status: 910,
      sku: 'PM0071540',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      rng_range_identifier: 'H040050C7',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'AYZ4NS2K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AYZ4NS2K'
    },
    {
      status: 210,
      sku: 'PM0071703',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353183',
      rtl_batch_array: '6353183',
      name_search: 'ROSPITZE',
      name: "Rodgersia 'Spitzent\u00e4nzerin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EXB77J9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXB77J9W'
    },
    {
      status: 210,
      sku: 'PM0015728',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382302',
      rtl_batch_array: '6208047, 6315058, 6382302',
      name_search: 'MOCPPETE',
      name: "Molinia caerulea 'Poul Petersen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4020,
      chnn_stock_retail: 8727,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2XYDJ7A2;56N5Z4XT',
      statusContent: 'dot',
      hash: 'RENG6S4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RENG6S4X'
    },
    {
      status: 910,
      sku: 'PM0071545',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COMAS',
      name: 'Cornus mas',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'PY766YN5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PY766YN5'
    },
    {
      status: 210,
      sku: 'PM0079641',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329944',
      rtl_batch_array: '6329944',
      name_search: 'GESPINNE',
      name: "Geranium 'Spinners'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRB6R926',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRB6R926'
    },
    {
      status: 910,
      sku: 'PM0079642',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEWRAINB',
      name: "Geranium wallichianum 'Rainbow'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1LYAV2XN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1LYAV2XN'
    },
    {
      status: 210,
      sku: 'PM0079643',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329946',
      rtl_batch_array: '6329946',
      name_search: 'GEYFLUFF',
      name: "Geum 'Yellow Fluffy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XB9Y56XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XB9Y56XG'
    },
    {
      status: 210,
      sku: 'PM0079644',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329947',
      rtl_batch_array: '6329947, 6256770',
      name_search: 'GESTEMPE',
      name: 'Geum Scarlet Tempest',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 596,
      chnn_stock_retail: 1008,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N47NJJ2H;1GZ2EGYV;57CG5NJH;ZXS83142',
      statusContent: 'dot',
      hash: 'B53BY1XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B53BY1XP'
    },
    {
      status: 210,
      sku: 'PM0079645',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329948',
      rtl_batch_array: '6329948',
      name_search: 'GOVIRGAT',
      name: 'Gomphostigma virgatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WKKPPGXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKKPPGXN'
    },
    {
      status: 210,
      sku: 'PM0085711',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386100',
      rtl_batch_array: '6386100',
      name_search: 'CIJCHEJU',
      name: "Cimicifuga jap. 'Cheju-Do'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NY3VHKNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NY3VHKNV'
    },
    {
      status: 210,
      sku: 'PM0079646',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329949',
      rtl_batch_array: '6329949',
      name_search: 'HEDLGOLD',
      name: "Helianthus decapetalus 'Loddon Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 159,
      chnn_stock_retail: 159,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TP4EK1P2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TP4EK1P2'
    },
    {
      status: 210,
      sku: 'PM0079647',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329951',
      rtl_batch_array: '6329951, 6352638',
      name_search: 'HEODEGRE',
      name: "Helleborus orientalis 'Double Ellen Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 419,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XARYNZYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XARYNZYD'
    },
    {
      status: 210,
      sku: 'PM0071694',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233459',
      rtl_batch_array: '6233459',
      name_search: 'PEAPMIST',
      name: "Persicaria amplexicaulis 'Pink Mist'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1658,
      chnn_stock_retail: 1658,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WWH5TPN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWH5TPN1'
    },
    {
      status: 210,
      sku: 'PM0085712',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386101',
      rtl_batch_array: '6386101',
      name_search: 'COFGOLDE',
      name: "Corydalis flexuosa 'Goldengreenheart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VRNE3ZH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRNE3ZH4'
    },
    {
      status: 210,
      sku: 'PM0079648',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329952',
      rtl_batch_array: '6329952',
      name_search: 'HEODEPSP',
      name: "Helleborus orientalis 'Double Ellen Pink Spotted'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 597,
      chnn_stock_retail: 597,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5H7N612C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H7N612C'
    },
    {
      status: 210,
      sku: 'PM0071695',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259164',
      rtl_batch_array: '6259164',
      name_search: 'STMHUMME',
      name: "Stachys monieri 'Hummelo'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '23KHDCW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23KHDCW2'
    },
    {
      status: 210,
      sku: 'PM0079649',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329954',
      rtl_batch_array: '6329954, 6352639',
      name_search: 'HEODEPIN',
      name: "Helleborus orientalis 'Double Ellen Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 554,
      chnn_stock_retail: 614,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '98DVWNW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98DVWNW2'
    },
    {
      status: 210,
      sku: 'PM0071697',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242346',
      rtl_batch_array: '6242346',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 6939,
      chnn_stock_retail: 6939,
      sppl_prcp: 7.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'CV7JPHES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV7JPHES'
    },
    {
      status: 210,
      sku: 'PM0079650',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329956',
      rtl_batch_array: '6329956',
      name_search: 'HEOPESPO',
      name: "Helleborus orientalis 'Pretty Ellen Spotted'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 503,
      chnn_stock_retail: 503,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AKC5A6RK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKC5A6RK'
    },
    {
      status: 210,
      sku: 'PM0085713',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386104',
      rtl_batch_array: '6386104',
      name_search: 'CRMLILYW',
      name: "Crambe maritima 'Lilywhite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GYS5BE51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYS5BE51'
    },
    {
      status: 810,
      sku: 'PM0085714',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386106',
      rtl_batch_array: '6386106',
      name_search: 'DITRICHO',
      name: 'Dierama trichorhizum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V7DSXGJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7DSXGJ9'
    },
    {
      status: 210,
      sku: 'PM0079651',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6329957',
      rtl_batch_array: '6329957, 6302576',
      name_search: 'HEAREVOL',
      name: "Hemerocallis 'American Revolution'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 799,
      chnn_stock_retail: 906,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ER8CX3G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ER8CX3G5'
    },
    {
      status: 210,
      sku: 'PM0079652',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329958',
      rtl_batch_array: '6329958',
      name_search: 'HEBIRKIN',
      name: "Heuchera 'Birkin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZWTYSKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZWTYSKJ'
    },
    {
      status: 210,
      sku: 'PM0079653',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329960',
      rtl_batch_array: '6329960',
      name_search: 'HEDDROPS',
      name: "Heuchera 'Dew Drops'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8ZWD2LB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8ZWD2LB'
    },
    {
      status: 210,
      sku: 'PM0071768',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236087',
      rtl_batch_array: '6236087',
      name_search: 'CEMASNOW',
      name: "Centaurea montana 'Amethyst in Snow'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_prcp: 2.141,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9Y5ZXTC;R5A825DT;4HRCBZZV;P7EEECAZ;NBWYLD8S;NVW4X7GV',
      statusContent: 'dot',
      hash: 'VWXVBHTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWXVBHTH'
    },
    {
      status: 210,
      sku: 'PM0079654',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329961',
      rtl_batch_array: '6329961',
      name_search: 'HEFSNOW',
      name: "Heuchera 'Flores Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRPDWHVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRPDWHVX'
    },
    {
      status: 210,
      sku: 'PM0079655',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329962',
      rtl_batch_array: '6329962',
      name_search: 'HEFPOWER',
      name: "Heuchera 'Flower Power'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2N61DC96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N61DC96'
    },
    {
      status: 210,
      sku: 'PM0071770',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236089',
      rtl_batch_array: '6236089',
      name_search: 'CRCOLUMB',
      name: "Crocosmia 'Columbus'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 247,
      chnn_stock_retail: 247,
      sppl_order_minimum: 3,
      sppl_prcp: 1.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '73K6XX7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73K6XX7N'
    },
    {
      status: 210,
      sku: 'PM0079656',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329963',
      rtl_batch_array: '6329963',
      name_search: 'HEMIRACL',
      name: "Heuchera 'Miracle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 221,
      chnn_stock_retail: 221,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJ2DS6CP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ2DS6CP'
    },
    {
      status: 210,
      sku: 'PM0085715',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386107',
      rtl_batch_array: '6386107',
      name_search: 'DRCHAMPI',
      name: 'Dryopteris championii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9D5ZXAYX',
      statusContent: 'dot',
      hash: '52VWE5X6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52VWE5X6'
    },
    {
      status: 210,
      sku: 'PM0079657',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329964',
      rtl_batch_array: '6329964',
      name_search: 'HENEPTUN',
      name: "Heuchera 'Neptune'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1498JJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1498JJZ'
    },
    {
      status: 210,
      sku: 'PM0085716',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386108',
      rtl_batch_array: '6386108',
      name_search: 'DRCOREAN',
      name: 'Dryopteris coreano-montana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y4NG5VC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4NG5VC9'
    },
    {
      status: 210,
      sku: 'PM0085717',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386112',
      rtl_batch_array: '6386112',
      name_search: 'DRGOLDIA',
      name: 'Dryopteris goldiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KW8SHTJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW8SHTJN'
    },
    {
      status: 210,
      sku: 'PM0085718',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386114',
      rtl_batch_array: '6386114',
      name_search: 'FUMICROP',
      name: 'Fuchsia microphylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3E7Y58C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3E7Y58C'
    },
    {
      status: 210,
      sku: 'PM0085719',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386115',
      rtl_batch_array: '6386115',
      name_search: 'GALSDREA',
      name: "Gaura lindheimeri 'Sunset Dreams'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '429LPJ4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '429LPJ4W'
    },
    {
      status: 210,
      sku: 'PM0085720',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386119',
      rtl_batch_array: '6386119',
      name_search: 'EUMPBUSH',
      name: "Eupatorium maculatum 'Purple Bush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TGC7TTTT;JNWWY53Y;26TWXHDE;X48CLPD1;1AYTLYGX;7YJZ7983;NS2EKTPR;LL1VJYGC;NLYJTDG2;R5S4DYCA;W449SB56;LAKZV215',
      statusContent: 'dot',
      hash: 'XDEH4YZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDEH4YZ6'
    },
    {
      status: 210,
      sku: 'PM0071800',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5382019',
      rtl_batch_array: '5382019',
      name_search: 'PEASSAMB',
      name: "Pennisetum advena 'Summer Samba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.891,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XY15GX4L;TA7LTTWX;R3T4D4DB;6J8BRZER;ADD5ANGB;VX4R65X8;NLSKZ4WS;VZRN2CGD;SBCWKAAZ;D75Y867E;SNRDRPAG;XN65X981',
      imageBatch: 'NEZDC3XR',
      statusContent: 'dot',
      hash: 'B5J2VZTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5J2VZTL'
    },
    {
      status: 210,
      sku: 'PM0071802',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5812580',
      rtl_batch_array: '5812580',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '020030C5',
      rng_range_identifier: 'H020030C5',
      rng_range_description: 'H20 cm 30 cm C5',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_prcp: 3.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '66XPL9TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66XPL9TN'
    },
    {
      status: 810,
      sku: 'PM0071805',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 2,
      btch_active_retail: '6174717',
      rtl_batch_array: '6174717, 6330176',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 470,
      sppl_prcp: 5.36,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'B3DLV5XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3DLV5XP'
    },
    {
      status: 910,
      sku: 'PM0006832',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ROSPSTAR',
      name: 'Rosa Pretty Star',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      statusContent: 'dot',
      hash: 'AZBL3E64',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AZBL3E64'
    },
    {
      status: 910,
      sku: 'PM0015224',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6167450',
      rtl_batch_array: '6113104, 6350209, 6242028, 6167450, 6274846, 6287678',
      name_search: 'GEJBLUE',
      name: "Geranium 'Johnson's Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 11,
      chnn_stock_retail: 22542,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8Y833LL;8KYS292B;5Y865CZD;SNTR5ZAT;H9JAND4T;NAJCXDPP;JBC8W97N;4W67GJP2;JWYSAJEN;CS5G2HB9;B66LCXLN;P8H3XAAB;HZ7K52ZS;TRGRSEXB;HN27NEPC;PJG195K9',
      statusContent: 'dot',
      hash: 'B2GY3KSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2GY3KSZ'
    },
    {
      status: 910,
      sku: 'PM0064371',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386604',
      rtl_batch_array: '6386604',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'XBLS8W4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBLS8W4Y'
    },
    {
      status: 210,
      sku: 'PM0050262',
      core_name: 'Plant',
      sppl_ean: '8720664804425',
      btch_manufacturer: 968,
      rtl_batch_array_total: 1,
      btch_active_retail: '5683057',
      rtl_batch_array: '5683057',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 22.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: 'BEZ4WYSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEZ4WYSG'
    },
    {
      status: 210,
      sku: 'PM0017563',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350245',
      rtl_batch_array: '6350245, 6295617',
      name_search: 'GESELKE',
      name: "Geranium sanguineum 'Elke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BH1XEL6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BH1XEL6H'
    },
    {
      status: 810,
      sku: 'PM0055727',
      core_name: 'Plant',
      sppl_ean: '8720664869660',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5819002',
      rtl_batch_array: '5819002',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      sppl_size_code: '060COBOL',
      rng_range_identifier: 'BALL\u00d8060H060060',
      rng_range_description: 'Ball \u00d8 60 cm H60 cm 60 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 60.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: 'YTN6APBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTN6APBC'
    },
    {
      status: 210,
      sku: 'PM0071769',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236088',
      rtl_batch_array: '6236088',
      name_search: 'CRCBRILL',
      name: "Crocosmia 'Carmine Brilliant'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.844,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJKA9LCH',
      statusContent: 'dot',
      hash: 'BA76NWHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA76NWHJ'
    },
    {
      status: 210,
      sku: 'PM0007038',
      core_name: 'Plant',
      sppl_ean: '8720353027654',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439486',
      rtl_batch_array: '5439486',
      name_search: 'ROSLMEID',
      name: 'Rosa (H) Lemon Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 522,
      chnn_stock_retail: 522,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSK7GYZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSK7GYZZ'
    },
    {
      status: 210,
      sku: 'PM0014363',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 8,
      btch_active_retail: '6184122',
      rtl_batch_array:
        '5932327, 6136257, 6143855, 6184122, 5439530, 6164353, 6014384, 6287665',
      name_search: 'ALMOLLIS',
      name: 'Alchemilla mollis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1991,
      chnn_stock_retail: 48082,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EHLKNB2V;CK9A2PG8;R2SRHX1J;J9RBBHEJ;HCWV9H57;NJDA177T;REXB3W18;N1JS3HZA;4VV1D59B;5RL49APS;JXHWCWSD;JZ5XW2K7;4K3P7PLL;E31H5L6W;KK56BZ8H;J1PS1T6J;YJ9B9V15;7H4ANG7G;ES93584N;C5Z4N6KL;VPHGPC3X;CTZCKHSA;1C75XRN8;1856E8BZ',
      statusContent: 'dot',
      hash: '12WRKR5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12WRKR5J'
    },
    {
      status: 210,
      sku: 'PM0025025',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 5,
      btch_active_retail: '6321921',
      rtl_batch_array: '6142838, 6350153, 6254372, 6333228, 6321921',
      name_search: 'EUMATROP',
      name: "Eupatorium maculatum 'Atropurpureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1184,
      chnn_stock_retail: 3730,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V9H31EY8;JDAR99JZ;7WPG4SVR;EKNXT7NS;J3LH5YA3',
      statusContent: 'dot',
      hash: '1PR3GX22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PR3GX22'
    },
    {
      status: 910,
      sku: 'PM0000343',
      core_name: 'Plant',
      sppl_ean: '8720349452156',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '4874785',
      rtl_batch_array: '4874785',
      name_search: 'CHLINNOC',
      name: "Chrysanthemum (I) 'L'Innocence'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RJT9VBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RJT9VBJ'
    },
    {
      status: 210,
      sku: 'PM0024090',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268783',
      rtl_batch_array: '6268783, 6291721',
      name_search: 'MOCVARIE',
      name: "Molinia caerulea 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1235,
      chnn_stock_retail: 1848,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APGCDRGV;H3Z46J2G;YRCP5SX2;884LNKDE;4VDJGWA7;R78CP6VZ;EYBY3X4X',
      statusContent: 'dot',
      hash: '1SWP66YE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SWP66YE'
    },
    {
      status: 210,
      sku: 'PM0064369',
      core_name: 'Plant',
      sppl_ean: '8720664868984',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151142',
      rtl_batch_array: '6151142',
      name_search: 'HYPANICU',
      name: 'Hydrangea paniculata',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 973,
      chnn_stock_retail: 973,
      sppl_prcp: 3.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E3G9RL7V;5K877CC4;4JDPBY2C;S41ZDECA;6K1X6BV1;PCAPCY2S',
      statusContent: 'dot',
      hash: 'D7KZKDJK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7KZKDJK'
    },
    {
      status: 910,
      sku: 'PM0064372',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'OSHGOSHI',
      name: "Osmanthus heterophyllus 'Goshiki'",
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      imageCore:
        '7DT7HZTW;H3611KS5;HHWLDZG5;C4LVH8XZ;P61R6DLC;WVR7T9BV;YD81PWH8;VX335SLL;CNKTN4N8;GX5HBXJ9;NLYT7ZPN;26RLBC7W;D5PTRYSY;Y6LGAB3K;TKLEDCZP;XG83C181;CGDSPS5B;DC98HZPC',
      statusContent: 'dot',
      hash: 'HT8G7VHB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HT8G7VHB'
    },
    {
      status: 210,
      sku: 'PM0071858',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350178',
      rtl_batch_array: '6350178',
      name_search: 'GACANDIC',
      name: 'Galtonia candicans',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZS4PNLD;4PTG7DL3;5LEDD1YX',
      statusContent: 'dot',
      hash: 'H7YTP9BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7YTP9BE'
    },
    {
      status: 210,
      sku: 'PM0071859',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350200',
      rtl_batch_array: '6350200, 6301592',
      name_search: 'GEDILYS',
      name: "Geranium 'Dilys'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 558,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GPPLY8E7;2HGGHT36;87S38AZA',
      statusContent: 'dot',
      hash: 'DB3S5ZY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB3S5ZY2'
    },
    {
      status: 210,
      sku: 'PM0021657',
      core_name: 'Plant',
      sppl_ean: '8720664866942',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6147176',
      rtl_batch_array: '6242961, 6147176, 5792492, 6158255, 6350362, 6164304',
      name_search: 'HEPDAMAS',
      name: "Hemerocallis 'Pink Damask'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 9391,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJYECKB7',
      statusContent: 'dot',
      hash: '6DGKED39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DGKED39'
    },
    {
      status: 210,
      sku: 'PM0006886',
      core_name: 'Plant',
      sppl_ean: '8720349461875',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439462',
      rtl_batch_array: '5439462',
      name_search: 'ROB82',
      name: 'Rosa (H) Bonica 82',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 4464,
      chnn_stock_retail: 4464,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VCNRXYW1;1KSNBT31;ZD7ZAAL6;YTP3LHW2;KJP2XX1S;TJTLVCV8;WBTHJ5ED;GGJ69XGP',
      statusContent: 'dot',
      hash: 'WSG7C6CB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSG7C6CB'
    },
    {
      status: 210,
      sku: 'PM0071907',
      core_name: 'Plant',
      sppl_ean: '8720349404674',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5220278',
      rtl_batch_array: '5220278',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      imageBatch: '9L6J25CS',
      statusContent: 'dot',
      hash: '87SE1ZY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87SE1ZY5'
    },
    {
      status: 810,
      sku: 'PM0071953',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198335',
      rtl_batch_array: '6198335',
      name_search: 'CHVIRGIN',
      name: 'Chionanthus virginicus',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7K2CV2D;8SLZH6JY;JYYA2V2E;ZY5JSVH8;P3LAVG2X;9W36NP98;N7TD4KX6;X9EKCA4X;NAJV1P77',
      statusContent: 'dot',
      hash: '46WE14DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46WE14DP'
    },
    {
      status: 210,
      sku: 'PM0064385',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6226111',
      rtl_batch_array: '6226111',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'NG845E7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG845E7Y'
    },
    {
      status: 210,
      sku: 'PM0071910',
      core_name: 'Plant',
      sppl_ean: '8720626349568',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420639',
      rtl_batch_array: '5420639',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_prcp: 8.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: '9NZ9GT2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NZ9GT2K'
    },
    {
      status: 210,
      sku: 'PM0082288',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349893',
      rtl_batch_array: '6349893',
      name_search: 'CAPLIESE',
      name: "Campanula portenschlagiana 'Lieselotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9XEAD6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9XEAD6H'
    },
    {
      status: 210,
      sku: 'PM0071869',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307594',
      rtl_batch_array: '6307594',
      name_search: 'PAQENGEL',
      name: 'Parthenocissus quinquefolia engelmannii',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 5006,
      chnn_stock_retail: 5006,
      sppl_order_minimum: 3,
      sppl_prcp: 2.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG1H657G;1D75YPT3;JC84WWJZ;XY55AD12;DPP95XZR;TAVEAT1N',
      statusContent: 'dot',
      hash: 'RGV5CCZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGV5CCZ6'
    },
    {
      status: 210,
      sku: 'PM0071911',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307595',
      rtl_batch_array: '5420702, 6307595',
      name_search: 'PAQENGEL',
      name: 'Parthenocissus quinquefolia engelmannii',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 765,
      chnn_stock_retail: 895,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG1H657G;1D75YPT3;JC84WWJZ;XY55AD12;DPP95XZR;TAVEAT1N',
      statusContent: 'dot',
      hash: 'AHVW9VLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHVW9VLB'
    },
    {
      status: 810,
      sku: 'PM0071916',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349247',
      rtl_batch_array: '6349247',
      name_search: 'CLVITALB',
      name: 'Clematis vitalba',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7T1E7VDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7T1E7VDR'
    },
    {
      status: 210,
      sku: 'PM0071909',
      core_name: 'Plant',
      sppl_ean: '8720349454150',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420619',
      rtl_batch_array: '5420619',
      name_search: 'CLVITALB',
      name: 'Clematis vitalba',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2685,
      chnn_stock_retail: 2685,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H13GRA6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H13GRA6T'
    },
    {
      status: 910,
      sku: 'PM0071707',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224288',
      rtl_batch_array: '6224288',
      name_search: 'CLVITALB',
      name: 'Clematis vitalba',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 6,
      sppl_prcp: 2.999,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8YDCAZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8YDCAZD'
    },
    {
      status: 910,
      sku: 'PM0071705',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224207',
      rtl_batch_array: '6224207',
      name_search: 'CATGALEN',
      name: "Campsis tagliabuana 'Mme Galen'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_order_minimum: 6,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V73YDNBX;BNLZZCHC;8S2RY642;TNJKEKT2;BVPTZ1DP;6R9D3JKJ;92YTRKYJ;Y9NHLYNX;1T6RYZR2;ZALD81S2',
      statusContent: 'dot',
      hash: 'EHJ6LYXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHJ6LYXS'
    },
    {
      status: 210,
      sku: 'PM0071873',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307715',
      rtl_batch_array: '6307715',
      name_search: 'WISINENS',
      name: 'Wisteria sinensis',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 581,
      chnn_stock_retail: 581,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GR3TKRKN;B8PNDWVB;T1BWX7CJ;ZHBCKZ13;K4W7C5V5;PE78N3BN;JVAC2983;89JLXBDK;9K7V9ELE;1P3HYWHN;XKZ4SZ75;PCPVSE7V;9RC389DS;4BG6DY3J',
      statusContent: 'dot',
      hash: 'V4KGZ39K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4KGZ39K'
    },
    {
      status: 210,
      sku: 'PM0064381',
      core_name: 'Plant',
      sppl_ean: '8720664886681',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151160',
      rtl_batch_array: '6151160',
      name_search: 'SPBETULI',
      name: 'Spiraea betulifolia',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 1.632,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '12N7JGWE',
      statusContent: 'dot',
      hash: 'WE5LS44V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WE5LS44V'
    },
    {
      status: 810,
      sku: 'PM0079670',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329983',
      rtl_batch_array: '6329983',
      name_search: 'KOMACRAN',
      name: 'Koeleria macrantha',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AKZJVWKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKZJVWKK'
    },
    {
      status: 810,
      sku: 'PM0071925',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198284',
      rtl_batch_array: '6198284',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.922,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'GRP4SSPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRP4SSPE'
    },
    {
      status: 210,
      sku: 'PM0071866',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307536',
      rtl_batch_array: '6307536',
      name_search: 'LYBARBAR',
      name: 'Lycium barbarum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S93T58NS;RWKYY7DS;R5XR33ZC;Z9DZTED4;PTYP1957;YASWSH4G;J3DYTLL2;9H7TB9J7;SJVXSBCH;5RS39RLL;RZLEY1EX;779D4K1X;LZHBVABE;YBPAS2E4;7X8XS811;Y882H767;GG8Z3SWK;6ZTE3S7V',
      statusContent: 'dot',
      hash: 'PDDZ4ZPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDDZ4ZPL'
    },
    {
      status: 210,
      sku: 'PM0071918',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746626',
      rtl_batch_array: '5746626',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'T9P61E6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9P61E6C'
    },
    {
      status: 210,
      sku: 'PM0067527',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6231144',
      rtl_batch_array: '6231144, 6252864, 6334769',
      name_search: 'HENIGER',
      name: 'Helleborus niger',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4275,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RVB32ABL;V9DJV4SD;CN7K6X19;8KN3XASX;9CNXKXGV;PRPXG6Y6;2AJHJESG;VZ6AAYNJ;VAGGYRV6;DA9PW75W;WSCPV35X;TLC3AHWL;ZY1YR8PZ;96HDHYKL;88HR175N;K5GREL5X;A79DX11H;EZY1R7D7;PYA1HN3P;JLZ4635X;RH58GJ33;GNVGCH1H;271YB5R9',
      statusContent: 'dot',
      hash: 'S4GSG3BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4GSG3BK'
    },
    {
      status: 210,
      sku: 'PM0071773',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291894',
      rtl_batch_array: '6291894',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: '1JACTTSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JACTTSY'
    },
    {
      status: 210,
      sku: 'PM0071919',
      core_name: 'Plant',
      sppl_ean: '8720664573048',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746627',
      rtl_batch_array: '5746627',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_prcp: 7.041,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'G8NRY9L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8NRY9L4'
    },
    {
      status: 210,
      sku: 'PM0064386',
      core_name: 'Plant',
      sppl_ean: '8720664889880',
      btch_manufacturer: 51,
      rtl_batch_array_total: 4,
      btch_active_retail: '6151165',
      rtl_batch_array: '6220405, 6151165, 5207517, 6348858',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 2461,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'D69GBB7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D69GBB7K'
    },
    {
      status: 210,
      sku: 'PM0071775',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236094',
      rtl_batch_array: '6236094',
      name_search: 'HECCOLA',
      name: "Heuchera 'Cherry Cola'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8VJBA4Z',
      statusContent: 'dot',
      hash: 'X2C8VRAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2C8VRAR'
    },
    {
      status: 210,
      sku: 'PM0071776',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236095',
      rtl_batch_array: '6236095',
      name_search: 'HEFCHIEF',
      name: "Heuchera 'Fire Chief'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2DZ9EN84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DZ9EN84'
    },
    {
      status: 210,
      sku: 'PM0071777',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236096',
      rtl_batch_array: '6236096',
      name_search: 'HENEAMBE',
      name: 'Heuchera Northern Exposure Amber',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9T37NJ13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T37NJ13'
    },
    {
      status: 210,
      sku: 'PM0071904',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300199',
      rtl_batch_array: '6300199',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4280,
      chnn_stock_retail: 4280,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'KNC5EAY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNC5EAY9'
    },
    {
      status: 210,
      sku: 'PM0079671',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329984',
      rtl_batch_array: '6329984',
      name_search: 'LELRASPB',
      name: "Lewisia 'Little Raspberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V9VPC1PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9VPC1PD'
    },
    {
      status: 210,
      sku: 'PM0079672',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329985',
      rtl_batch_array: '6329985',
      name_search: 'LELSNOWB',
      name: "Lewisia 'Little Snowberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52VJ1XVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52VJ1XVH'
    },
    {
      status: 210,
      sku: 'PM0064384',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 5,
      btch_active_retail: '6355347',
      rtl_batch_array: '6220403, 6334232, 6355347, 6293897, 5206974',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 750,
      chnn_stock_retail: 7005,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '119ENG73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '119ENG73'
    },
    {
      status: 210,
      sku: 'PM0079658',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329965',
      rtl_batch_array: '6329965',
      name_search: 'HEPALICE',
      name: "Heuchera 'Princess Alice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97W3X3E2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97W3X3E2'
    },
    {
      status: 210,
      sku: 'PM0079659',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329966',
      rtl_batch_array: '6329966',
      name_search: 'HEURLIGH',
      name: "Heuchera 'Red Lightning'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 286,
      chnn_stock_retail: 286,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3HE4A7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3HE4A7T'
    },
    {
      status: 210,
      sku: 'PM0082289',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349895',
      rtl_batch_array: '6349895',
      name_search: 'CAPFRUEH',
      name: "Campanula posch. 'Fr\u00fchlingszauber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GVHHG6LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVHHG6LC'
    },
    {
      status: 210,
      sku: 'PM0079660',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329967',
      rtl_batch_array: '6329967',
      name_search: 'HEURBLAC',
      name: "Heuchera 'Rex Black'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSKLW8W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSKLW8W1'
    },
    {
      status: 210,
      sku: 'PM0079661',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329968',
      rtl_batch_array: '6329968',
      name_search: 'HEPTEA',
      name: 'Heucherella Peach Tea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '21RGN9TT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21RGN9TT'
    },
    {
      status: 210,
      sku: 'PM0079662',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329969',
      rtl_batch_array: '6329969',
      name_search: 'HERFALLS',
      name: "Heucherella 'Redstone Falls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6RJV99P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RJV99P6'
    },
    {
      status: 210,
      sku: 'PM0071808',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238452',
      rtl_batch_array: '6238452',
      name_search: 'STOALBA',
      name: "Stachys officinalis 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2445,
      chnn_stock_retail: 2445,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8KCLRBL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KCLRBL6'
    },
    {
      status: 210,
      sku: 'PM0079663',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329970',
      rtl_batch_array: '6329970',
      name_search: 'HYANDROS',
      name: 'Hypericum androsaemum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 910,
      chnn_stock_retail: 910,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WNHWLXG',
      statusContent: 'dot',
      hash: 'JBWE98SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBWE98SS'
    },
    {
      status: 210,
      sku: 'PM0079664',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329971',
      rtl_batch_array: '6329971',
      name_search: 'HYTETRAP',
      name: 'Hypericum tetrapterum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J1K5427T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1K5427T'
    },
    {
      status: 210,
      sku: 'PM0071811',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238460',
      rtl_batch_array: '6238460',
      name_search: 'KAINCISA',
      name: 'Kalimeris incisa',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1957,
      chnn_stock_retail: 1957,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YH826DYW;SHB2DVC1',
      statusContent: 'dot',
      hash: 'VX4AJN45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VX4AJN45'
    },
    {
      status: 810,
      sku: 'PM0079665',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329975',
      rtl_batch_array: '6329975',
      name_search: 'IRSPPARF',
      name: "Iris sibirica 'Pink Parfait'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BRYP2GDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRYP2GDP'
    },
    {
      status: 810,
      sku: 'PM0079666',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329976',
      rtl_batch_array: '6329976',
      name_search: 'ISEFFUSU',
      name: 'Isodon effusus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6EZGHTVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EZGHTVW'
    },
    {
      status: 210,
      sku: 'PM0071861',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240196',
      rtl_batch_array: '6240196',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 29.377,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'SLSRGCSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLSRGCSJ'
    },
    {
      status: 210,
      sku: 'PM0079667',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329980',
      rtl_batch_array: '6329980',
      name_search: 'KIPBSTYL',
      name: "Kirengeshoma palmata 'Black Style'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 566,
      chnn_stock_retail: 566,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1Y7BSYE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y7BSYE9'
    },
    {
      status: 210,
      sku: 'PM0079668',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329981',
      rtl_batch_array: '6329981',
      name_search: 'KNPRED',
      name: 'Kniphofia Poco Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 430,
      chnn_stock_retail: 430,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8TRZTVA3',
      statusContent: 'dot',
      hash: 'PSVENHRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSVENHRR'
    },
    {
      status: 210,
      sku: 'PM0071862',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240199',
      rtl_batch_array: '6240199',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 26.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'HLKRNHXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLKRNHXL'
    },
    {
      status: 210,
      sku: 'PM0079669',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329982',
      rtl_batch_array: '6329982',
      name_search: 'KNNOBILI',
      name: "Kniphofia 'Nobilis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K5W6RWVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5W6RWVP'
    },
    {
      status: 210,
      sku: 'PM0071865',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307500',
      rtl_batch_array: '6307500',
      name_search: 'JAOFFICI',
      name: 'Jasminum officinale',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 261,
      chnn_stock_retail: 261,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCHN4RN1;H5LBGN69;TXTCZZXG;WA2L7Z4C;WRJ6CX3E;6HZEXZSW;6GBCX9YX',
      statusContent: 'dot',
      hash: 'BD3TXYZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BD3TXYZP'
    },
    {
      status: 210,
      sku: 'PM0079673',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329986',
      rtl_batch_array: '6329986',
      name_search: 'LIASPERA',
      name: 'Liatris aspera',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W5JHTA78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5JHTA78'
    },
    {
      status: 210,
      sku: 'PM0071867',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307590',
      rtl_batch_array: '6307590',
      name_search: 'PAHENRYA',
      name: 'Parthenocissus henryana',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1479,
      chnn_stock_retail: 1479,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '89G7ZTN2;XWAVDK9V',
      statusContent: 'dot',
      hash: 'H1HELC5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1HELC5G'
    },
    {
      status: 210,
      sku: 'PM0071868',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307591',
      rtl_batch_array: '6307591',
      name_search: 'PAKIRINM',
      name: "Paeonia (LD) 'Kirinmaru'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJR35E1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJR35E1D'
    },
    {
      status: 210,
      sku: 'PM0071870',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307597',
      rtl_batch_array: '6307597',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 9439,
      chnn_stock_retail: 9439,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: '1PY3R886',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PY3R886'
    },
    {
      status: 210,
      sku: 'PM0071871',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307598',
      rtl_batch_array: '6307598',
      name_search: 'PATVROBU',
      name: "Parthenocissus tricuspidata 'Veitchii Robusta'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4013,
      chnn_stock_retail: 4013,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X51Y72J8',
      statusContent: 'dot',
      hash: '477X8YJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '477X8YJL'
    },
    {
      status: 210,
      sku: 'PM0071872',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307684',
      rtl_batch_array: '6281121, 6307684',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 213,
      chnn_stock_retail: 263,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: '1LYYXX5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LYYXX5B'
    },
    {
      status: 210,
      sku: 'PM0079674',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329987',
      rtl_batch_array: '6329987',
      name_search: 'LIPEACHY',
      name: "Linaria 'Peachy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZATLCNDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZATLCNDE'
    },
    {
      status: 210,
      sku: 'PM0079675',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329988',
      rtl_batch_array: '6329988',
      name_search: 'LIPURPUR',
      name: 'Linaria purpurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 416,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSS2PHGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSS2PHGT'
    },
    {
      status: 210,
      sku: 'PM0079676',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329989',
      rtl_batch_array: '6329989',
      name_search: 'LIPCJWEN',
      name: "Linaria purpurea 'Canon J. Went'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 786,
      chnn_stock_retail: 786,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2SSJJAXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SSJJAXE'
    },
    {
      status: 810,
      sku: 'PM0071908',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307351',
      rtl_batch_array: '6307351',
      name_search: 'CLTTANGU',
      name: 'Clematis tibetana tangutica',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V78AZ85R;C43CDZND;9D5CNZTY;YG4155K1;B2H6WENR',
      statusContent: 'dot',
      hash: 'X8AE6P53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8AE6P53'
    },
    {
      status: 210,
      sku: 'PM0071913',
      core_name: 'Plant',
      sppl_ean: '8720349430796',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420722',
      rtl_batch_array: '5420722',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 699,
      chnn_stock_retail: 699,
      sppl_prcp: 3.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '91Z8Y8B1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '91Z8Y8B1'
    },
    {
      status: 210,
      sku: 'PM0085721',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386120',
      rtl_batch_array: '6386120',
      name_search: 'EURLMELO',
      name: 'Eupatorium rugosum Lucky Melody',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WB27S12K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB27S12K'
    },
    {
      status: 210,
      sku: 'PM0079677',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329990',
      rtl_batch_array: '6329990',
      name_search: 'LYCGWORL',
      name: 'Lychnis coronaria Gardeners World',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '739BKJ89;7TKED31A',
      statusContent: 'dot',
      hash: 'SCJ4T91K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCJ4T91K'
    },
    {
      status: 810,
      sku: 'PM0071923',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198282',
      rtl_batch_array: '6198282',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 32.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'K7H6TAZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7H6TAZL'
    },
    {
      status: 810,
      sku: 'PM0071926',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198286',
      rtl_batch_array: '6198286',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.922,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'ZLHA7RDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLHA7RDR'
    },
    {
      status: 810,
      sku: 'PM0079678',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329991',
      rtl_batch_array: '6329991',
      name_search: 'LYSLIPST',
      name: "Lychnis sieboldii 'Lipstick'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '859LA1L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '859LA1L8'
    },
    {
      status: 210,
      sku: 'PM0079679',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329992',
      rtl_batch_array: '6329992',
      name_search: 'LYBARYST',
      name: 'Lysimachia barystachys',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KB4C4J9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KB4C4J9W'
    },
    {
      status: 210,
      sku: 'PM0079680',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329993',
      rtl_batch_array: '6329993',
      name_search: 'LYINNLYS',
      name: "Lysimachia 'Innlyscand'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K9WYVNT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9WYVNT9'
    },
    {
      status: 810,
      sku: 'PM0071930',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198290',
      rtl_batch_array: '6198290',
      name_search: 'LOJHPROL',
      name: "Lonicera japonica 'Hall's Prolific'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHKL1DZY;PEANXCLV;CK5DDGCZ;2BXS6WAH;D3X52NJ1;5J46GSBA;5H8AKRL2;V11VCCSK;XE1KT76N;YV37XS54;KJAY3KGK',
      statusContent: 'dot',
      hash: '43JNJVKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43JNJVKC'
    },
    {
      status: 810,
      sku: 'PM0071931',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198293',
      rtl_batch_array: '6198293',
      name_search: 'LOPURPUS',
      name: 'Lonicera purpusii',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5TDSKV7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TDSKV7K'
    },
    {
      status: 210,
      sku: 'PM0079681',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329994',
      rtl_batch_array: '6329994',
      name_search: 'LYSFEUER',
      name: "Lythrum salicaria 'Feuerkerze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DN5T9TTV',
      statusContent: 'dot',
      hash: 'ALJ4SC1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALJ4SC1J'
    },
    {
      status: 810,
      sku: 'PM0071933',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198295',
      rtl_batch_array: '6198295',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.438,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'ZS55VPS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZS55VPS5'
    },
    {
      status: 810,
      sku: 'PM0071934',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198296',
      rtl_batch_array: '6198296',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '98SA5429',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98SA5429'
    },
    {
      status: 810,
      sku: 'PM0085722',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386121',
      rtl_batch_array: '6386121',
      name_search: 'FAJSWEB',
      name: "Fatsia japonica 'Spider's Web'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3B94AJ2;NKD557B2;X4KAN56K;Y3RNSPWK;8TED5GNZ;Y68T7YTZ;HWNG8EZT;EW9BY6TD;XC4DAZXW;CPZBWXY9;ZDVS9LBL;GWTWBRV2;NAB5S1ZA;VP8A4ZS6;JK7VTB2C;C4B6PJLY;WX9SSXS3',
      statusContent: 'dot',
      hash: '84N6WXWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84N6WXWL'
    },
    {
      status: 210,
      sku: 'PM0079682',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329996',
      rtl_batch_array: '6329996',
      name_search: 'LYSSTICH',
      name: "Lythrum salicaria 'Stichflamme'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6125R4YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6125R4YR'
    },
    {
      status: 210,
      sku: 'PM0079683',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329997',
      rtl_batch_array: '6329997',
      name_search: 'LYVHELEN',
      name: "Lythrum virgatum 'Helene'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1ALE3BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1ALE3BE'
    },
    {
      status: 210,
      sku: 'PM0085723',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386124',
      rtl_batch_array: '6386124',
      name_search: 'GEMMONTA',
      name: "Geranium macrorrhizum'Montasch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NVV5KWZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVV5KWZE'
    },
    {
      status: 810,
      sku: 'PM0079684',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329999',
      rtl_batch_array: '6329999',
      name_search: 'LYVWSWIR',
      name: "Lythrum virgatum 'White Swirl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 91,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J14YRART',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J14YRART'
    },
    {
      status: 910,
      sku: 'PM0079685',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMSRUBY',
      name: "Macleaya microcarpa 'Spetchley Ruby'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'J2JY6196',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J2JY6196'
    },
    {
      status: 210,
      sku: 'PM0079686',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348370',
      rtl_batch_array: '6330001, 6348370',
      name_search: 'MEBETONI',
      name: 'Meconopsis betonicifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 323,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TRCEB6WK;KNX577SP',
      statusContent: 'dot',
      hash: '23PS2CX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23PS2CX4'
    },
    {
      status: 810,
      sku: 'PM0079687',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330002',
      rtl_batch_array: '6330002',
      name_search: 'MEURTICI',
      name: 'Meehania urticifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K1V2HLRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1V2HLRJ'
    },
    {
      status: 210,
      sku: 'PM0085724',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386126',
      rtl_batch_array: '6386126',
      name_search: 'GLYUNNAN',
      name: 'Glycyrrhiza yunnanensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6B16E2CS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6B16E2CS'
    },
    {
      status: 210,
      sku: 'PM0079688',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330003',
      rtl_batch_array: '6330003',
      name_search: 'MEOLIMON',
      name: "Melissa officinalis 'Limonii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PGP5VY4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGP5VY4L'
    },
    {
      status: 210,
      sku: 'PM0085725',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386128',
      rtl_batch_array: '6386128',
      name_search: 'HEKELLER',
      name: 'Helianthus kellermanii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 243,
      chnn_stock_retail: 243,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A879CXR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A879CXR5'
    },
    {
      status: 210,
      sku: 'PM0079689',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330004',
      rtl_batch_array: '6330004',
      name_search: 'MIBMAJOR',
      name: "Mimulus 'Bees' Major'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4GDXAWDB',
      statusContent: 'dot',
      hash: 'YHG68R9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHG68R9R'
    },
    {
      status: 210,
      sku: 'PM0085726',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386131',
      rtl_batch_array: '6386131',
      name_search: 'HOCARNIV',
      name: "Hosta 'Carnival'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A44G5614',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A44G5614'
    },
    {
      status: 210,
      sku: 'PM0085727',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386132',
      rtl_batch_array: '6386132',
      name_search: 'HOCNORMA',
      name: 'Hosta clausa normalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6DBS6P8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DBS6P8Z'
    },
    {
      status: 210,
      sku: 'PM0085728',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386133',
      rtl_batch_array: '6386133',
      name_search: 'HOCFFIRE',
      name: "Hosta 'Clifford's Forest Fire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12LBYB68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12LBYB68'
    },
    {
      status: 810,
      sku: 'PM0071955',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198337',
      rtl_batch_array: '6198337',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'JY2HAL4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY2HAL4B'
    },
    {
      status: 210,
      sku: 'PM0071956',
      core_name: 'Plant',
      sppl_ean: '8720664874084',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6143773',
      rtl_batch_array: '6143773',
      name_search: 'MAAQUIFO',
      name: 'Mahonia aquifolium',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 677,
      chnn_stock_retail: 677,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRSS4XH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRSS4XH8'
    },
    {
      status: 210,
      sku: 'PM0071957',
      core_name: 'Plant',
      sppl_ean: '8720664683754',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5891069',
      rtl_batch_array: '5891069',
      name_search: 'CARFALLS',
      name: 'Carex Ribbon Falls',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1AGT6454;EBRZ94TE;D1JKT933;BHBLYR23;PNVB286B;J5XWYE65;9LVK19AC;89Y7DPVH;2HKGHD6L;YKELS7XA;S8KK36Y9;RXS2TWY6;P1N41PP6;7VJXERXR;79VXNCWR;ES3GG9YY;RA1GP1K1;K523XY2D;66DHTSBS;LTYABY8P;CVETVTEK;8ZJ2KYE5;KEG2T3CR;Y1AGPGZY;6V9PHSX9',
      imageBatch: '69YVVPRE',
      statusContent: 'dot',
      hash: 'GGJ3ENZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGJ3ENZS'
    },
    {
      status: 210,
      sku: 'PM0071914',
      core_name: 'Plant',
      sppl_ean: '8720626375789',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420728',
      rtl_batch_array: '5420728',
      name_search: 'VIBIANCA',
      name: "Vitis 'Bianca'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8SR58N91;8BW8W1G9;2WL41Z8S;VY4VGHZC;4GCPRSXC;2TDYDR7A;VEZTJ3A1;929VP2NC;R4SXTXB2',
      statusContent: 'dot',
      hash: 'PR7364DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR7364DS'
    },
    {
      status: 210,
      sku: 'PM0071857',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6286663',
      rtl_batch_array: '6254361, 6286663',
      name_search: 'ASEUROPA',
      name: 'Asarum europaeum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6222,
      chnn_stock_retail: 6289,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RYRN5591;ZKTN3XKC;H71PLP5B;9BAXRT2D;Z5EL57BS;K53GHG4P',
      statusContent: 'dot',
      hash: 'T9SDYDPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9SDYDPC'
    },
    {
      status: 210,
      sku: 'PM0039489',
      core_name: 'Plant',
      sppl_ean: '8720664681446',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5892538',
      rtl_batch_array: '5892538',
      name_search: 'ASCPURPU',
      name: "Astilbe chinensis 'Purpurkerze'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4057,
      chnn_stock_retail: 4057,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2H95G7JJ;5L9733PB;WJRG6A9N;XZEVTWBL;WL5G58PX;E7NP713G;VYXJE286',
      statusContent: 'dot',
      hash: '6HX923KX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HX923KX'
    },
    {
      status: 210,
      sku: 'PM0085729',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386134',
      rtl_batch_array: '6386134',
      name_search: 'HOFISLAN',
      name: "Hosta 'Fire Island'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHNZGZTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHNZGZTJ'
    },
    {
      status: 210,
      sku: 'PM0071917',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137527',
      rtl_batch_array: '6137527',
      name_search: 'SAMPBLUS',
      name: "Salvia microphylla 'Pink Blush'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PVPD2TP3;D3N4XA3K',
      statusContent: 'dot',
      hash: '42Z2N22L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42Z2N22L'
    },
    {
      status: 810,
      sku: 'PM0071951',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198333',
      rtl_batch_array: '6198333',
      name_search: 'CECANADE',
      name: 'Cercis canadensis',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LVTLE62R;AH74D8P4;545RX5ZD;54E271T2;RJ41P2H4;P86Y86VH;4KE5VSDD;HZTGKDH6;JX8HCT51;4S697TKJ;JWLCTNWZ;KNWPRTJK;CX7HPD7W;R47NA8E7;JNGNS34S;TVAXKYJ8;8Z8YYAYB;YWSDW6E8;TVX1V7HG;AWZ6NCWS;47K15XY4',
      statusContent: 'dot',
      hash: '716ZJ5YZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '716ZJ5YZ'
    },
    {
      status: 210,
      sku: 'PM0082290',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349896',
      rtl_batch_array: '6349896',
      name_search: 'CAPSCHNE',
      name: "Campanula posch. 'Schneeranke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TN2JHGNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TN2JHGNN'
    },
    {
      status: 810,
      sku: 'PM0071922',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198281',
      rtl_batch_array: '6198281',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'ZC9NVSBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZC9NVSBV'
    },
    {
      status: 810,
      sku: 'PM0071924',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198283',
      rtl_batch_array: '6198283',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: 'ZGTZPEN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGTZPEN3'
    },
    {
      status: 210,
      sku: 'PM0082291',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349897',
      rtl_batch_array: '6349897',
      name_search: 'CAPWEISS',
      name: "Campanula posch. 'Weissranke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 345,
      chnn_stock_retail: 345,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7R1S7LX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R1S7LX4'
    },
    {
      status: 810,
      sku: 'PM0071935',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198297',
      rtl_batch_array: '6198297',
      name_search: 'PRTOMENT',
      name: 'Prunus tomentosa',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ARSTV5A9;RLSK65XK;67HVSCET;ALW6YVEH;4VEYDHAE;ZL26WG2G',
      statusContent: 'dot',
      hash: 'R31DLHGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R31DLHGP'
    },
    {
      status: 210,
      sku: 'PM0082292',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349898',
      rtl_batch_array: '6349898',
      name_search: 'CARAPUNC',
      name: 'Campanula rapunculoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1252,
      chnn_stock_retail: 1252,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZY1SPCH;JDTYP9KH;W1V2K9KJ',
      statusContent: 'dot',
      hash: 'J3BNHL1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3BNHL1A'
    },
    {
      status: 910,
      sku: 'PM0071706',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224208',
      rtl_batch_array: '6224208',
      name_search: 'HECSHEAR',
      name: "Hedera colchica 'Sulphur Heart'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 6,
      sppl_prcp: 2.933,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ALAA1DE4;K82NZT1T;2E5KKWNZ;L677BA8L;7KCPWPXW',
      statusContent: 'dot',
      hash: '2VPN4A55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VPN4A55'
    },
    {
      status: 210,
      sku: 'PM0071912',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307593',
      rtl_batch_array: '5420704, 6307593',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 750,
      chnn_stock_retail: 914,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: 'AZ4VHEC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ4VHEC2'
    },
    {
      status: 210,
      sku: 'PM0082293',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349899',
      rtl_batch_array: '6349899, 6349783',
      name_search: 'CAROLYMP',
      name: "Campanula rotundifolia 'Olympica'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1792,
      chnn_stock_retail: 2242,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N6BYED77',
      statusContent: 'dot',
      hash: 'RREV6PPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RREV6PPA'
    },
    {
      status: 910,
      sku: 'PM0082294',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CARWAVE',
      name: "Campanula 'Royal Wave'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'N8VW25JC;PNGDYTAK;YW5S4J47;GC6VS2GL;69BPBY2C',
      statusContent: 'dot',
      hash: '526Z8JS5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '526Z8JS5'
    },
    {
      status: 210,
      sku: 'PM0071806',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238450',
      rtl_batch_array: '6238450',
      name_search: 'SESIEBOL',
      name: 'Sedum sieboldii',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1398,
      chnn_stock_retail: 1398,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZP6B73B;X452DHVY;T919SRWT;D4BDV9S8;G2NPDCEH',
      statusContent: 'dot',
      hash: 'L372S3N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L372S3N7'
    },
    {
      status: 910,
      sku: 'PM0082295',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CARBVARI',
      name: "Carex brunnea 'Variegata'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'ZENS97HV;ZX5A3N4W',
      statusContent: 'dot',
      hash: 'SCRJ84BC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SCRJ84BC'
    },
    {
      status: 210,
      sku: 'PM0071864',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307272',
      rtl_batch_array: '6284929, 6307272',
      name_search: 'AKQUINAT',
      name: 'Akebia quinata',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 375,
      chnn_stock_retail: 399,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YA8H49S7;1PD4YN1L;XR8L16NA;L23SYYRZ;XPSYNY1T;WVH7DEDD;1RGDRZHP;LKYER7P7;6SV47663;B1V71WA5;84ZGCLTK;GPRZ1B8P;TRA52T42;996S9XYN',
      statusContent: 'dot',
      hash: '48G5HAH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48G5HAH7'
    },
    {
      status: 210,
      sku: 'PM0039467',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353275',
      rtl_batch_array: '6353275',
      name_search: 'EDI82891',
      name: "Rhodoxis 'Mrs. Betsy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PH2SKTTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH2SKTTJ'
    },
    {
      status: 210,
      sku: 'PM0071774',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236093',
      rtl_batch_array: '6236093',
      name_search: 'HECARAME',
      name: "Heuchera 'Caramel'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 308,
      chnn_stock_retail: 308,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1WBVG799;TGVX4RYT;2T51378Z;B3BGRN53;48ZLP6PN;4PL5HYGT;SY3K85SW;D4AK4EW4;85EPS5C4',
      statusContent: 'dot',
      hash: '2XNPWKNK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XNPWKNK'
    },
    {
      status: 210,
      sku: 'PM0071915',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420750',
      rtl_batch_array: '5420750',
      name_search: 'CATGALEN',
      name: "Campsis tagliabuana 'Mme Galen'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 452,
      chnn_stock_retail: 452,
      sppl_order_minimum: 3,
      sppl_prcp: 3.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V73YDNBX;BNLZZCHC;8S2RY642;TNJKEKT2;BVPTZ1DP;6R9D3JKJ;92YTRKYJ;Y9NHLYNX;1T6RYZR2;ZALD81S2',
      statusContent: 'dot',
      hash: 'L1LSY1GJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1LSY1GJ'
    },
    {
      status: 210,
      sku: 'PM0019252',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349050',
      rtl_batch_array: '5866460, 6339750, 6319687, 6349050',
      name_search: 'ASALPINU',
      name: 'Aster alpinus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3132,
      chnn_stock_retail: 4433,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CWKYYLRY;ANW92ZJT;1SAB3ZRX;J3TB2TP7;62CKHCE7;XYLVSTY5;A1L97492;B2DSSE2A;H5TZ8LX2;157LKLGZ;CPSD4XYG;TNC16AJX;X2BJBDBR;NG16XCV6;NER5HH3L;69L9LVZC;4636WE2C;513BVXSJ;PL5BH9S5;ZRADGKXN;E1TSW2J5;Z2NDD3GL;LL8WPHTS;GYLW453R;ASYZWD85;VW1Y3BYV',
      statusContent: 'dot',
      hash: 'GX3YY1SV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GX3YY1SV'
    },
    {
      status: 210,
      sku: 'PM0071785',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236232',
      rtl_batch_array: '6236232',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '2GZRT4Z2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GZRT4Z2'
    },
    {
      status: 210,
      sku: 'PM0071787',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6304962',
      rtl_batch_array: '6304962',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2954,
      chnn_stock_retail: 2954,
      sppl_prcp: 1.274,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'XTCAG7P2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTCAG7P2'
    },
    {
      status: 210,
      sku: 'PM0085730',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386136',
      rtl_batch_array: '6386136',
      name_search: 'HOPARADI',
      name: "Hosta 'Paradigm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EX2LK6DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX2LK6DN'
    },
    {
      status: 210,
      sku: 'PM0031759',
      core_name: 'Plant',
      sppl_ean: '8720349417810',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5804835',
      rtl_batch_array: '5804835',
      name_search: 'HEBSEA',
      name: "Heuchera 'Black Sea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1141,
      chnn_stock_retail: 1141,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '1L31SE1E',
      statusContent: 'dot',
      hash: 'JTD27PAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTD27PAH'
    },
    {
      status: 210,
      sku: 'PM0071991',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291749',
      rtl_batch_array: '6291749',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'RZ11DVLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ11DVLP'
    },
    {
      status: 210,
      sku: 'PM0067533',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192794',
      rtl_batch_array: '6192794',
      name_search: 'HYHROZE',
      name: 'Hyacinthoides hispanica roze',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 393,
      chnn_stock_retail: 393,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RTCX2X32;KYWEXPSD;NK73LDX6;Y1EE87AL',
      statusContent: 'dot',
      hash: 'JV9VK4H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV9VK4H1'
    },
    {
      status: 210,
      sku: 'PM0071792',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236247',
      rtl_batch_array: '6236247',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_prcp: 1.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: '1AW633KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AW633KS'
    },
    {
      status: 210,
      sku: 'PM0071550',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301397',
      rtl_batch_array: '6301397, 6268706',
      name_search: 'ANGERARD',
      name: 'Andropogon gerardii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 431,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPC3XRDV',
      statusContent: 'dot',
      hash: 'AWL7PHE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWL7PHE4'
    },
    {
      status: 210,
      sku: 'PM0071553',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230492',
      rtl_batch_array: '6230492',
      name_search: 'ASCVRED',
      name: "Astilbe chinensis 'Vision in Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 864,
      chnn_stock_retail: 864,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6YGLG3HY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YGLG3HY'
    },
    {
      status: 210,
      sku: 'PM0071554',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324725',
      rtl_batch_array: '6324725, 6290000',
      name_search: 'ASMBMANO',
      name: "Astrantia major 'Burgundy Manor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 416,
      chnn_stock_retail: 667,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VS7RVBKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VS7RVBKG'
    },
    {
      status: 210,
      sku: 'PM0071556',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300451',
      rtl_batch_array: '6300451, 6308661',
      name_search: 'BALMERIN',
      name: "Baptisia 'Lemon Meringue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 447,
      chnn_stock_retail: 568,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ANY2519;R37NCZHC;AVA2ZTSY;RCBS94LR;PAXX9158;8KVV3NAL;VSEW14AA',
      statusContent: 'dot',
      hash: '5KNTVV4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KNTVV4K'
    },
    {
      status: 210,
      sku: 'PM0071557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230497',
      rtl_batch_array: '6230497',
      name_search: 'BESINENS',
      name: 'Begonia sinensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1D8HSB91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1D8HSB91'
    },
    {
      status: 210,
      sku: 'PM0025337',
      core_name: 'Plant',
      sppl_ean: '8720664860933',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147754',
      rtl_batch_array: '6147754',
      name_search: 'ECPGICE',
      name: "Echinacea purpurea 'Guave Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 441,
      chnn_stock_retail: 441,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1SSP1H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1SSP1H7'
    },
    {
      status: 210,
      sku: 'PM0082296',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349904',
      rtl_batch_array: '6349904',
      name_search: 'CACBEATL',
      name: "Carex caryophyllea 'the Beatles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 875,
      chnn_stock_retail: 875,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1X36CH2E;7EC9Y71H',
      statusContent: 'dot',
      hash: '4SX3ZYAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SX3ZYAE'
    },
    {
      status: 210,
      sku: 'PM0082297',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349906',
      rtl_batch_array: '6349906',
      name_search: 'CACKUPFE',
      name: "Carex comans 'Kupferflamme'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VTH9LY3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTH9LY3R'
    },
    {
      status: 210,
      sku: 'PM0085731',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386137',
      rtl_batch_array: '6386137',
      name_search: 'HOTKING',
      name: "Hosta 'The King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '46HN9293',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46HN9293'
    },
    {
      status: 910,
      sku: 'PM0067535',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRLUGANO',
      name: "Iris (G) 'Lugano'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'XBJK2B8Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XBJK2B8Z'
    },
    {
      status: 210,
      sku: 'PM0071561',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349915',
      rtl_batch_array: '6349915, 6301495',
      name_search: 'CASYLVAT',
      name: 'Carex sylvatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3495,
      chnn_stock_retail: 4503,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A16XE2Y8',
      statusContent: 'dot',
      hash: '9167C52V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9167C52V'
    },
    {
      status: 210,
      sku: 'PM0067536',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192798',
      rtl_batch_array: '6192798',
      name_search: 'IRGCHILD',
      name: "Iris (P) 'Golden Child'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5GGVLAVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GGVLAVY'
    },
    {
      status: 210,
      sku: 'PM0071786',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236233',
      rtl_batch_array: '6236233',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 971,
      chnn_stock_retail: 971,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: 'ELNET388',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELNET388'
    },
    {
      status: 210,
      sku: 'PM0021255',
      core_name: 'Plant',
      sppl_ean: '8720664868151',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112447',
      rtl_batch_array: '6112447',
      name_search: 'HOFBLUE',
      name: "Hosta 'Fragrant Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E827Z57H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E827Z57H'
    },
    {
      status: 210,
      sku: 'PM0071959',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284943',
      rtl_batch_array: '6284943',
      name_search: 'HEHGOLDH',
      name: "Hedera helix 'Goldheart'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 2.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TA51AG38;PL6RE31W;7ZEJB17L;XJVVT79W;ENA9B2ER;VCPL4Z4C;H53YSL6L;4G6SHS57;RWZ69TED;NKZLA5LX;JDK9SZA8',
      statusContent: 'dot',
      hash: 'CD8AAJWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CD8AAJWJ'
    },
    {
      status: 210,
      sku: 'PM0085732',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386139',
      rtl_batch_array: '6386139',
      name_search: 'HYRENIFO',
      name: 'Hypsela reniformis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPJA68DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPJA68DX'
    },
    {
      status: 210,
      sku: 'PM0085733',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386140',
      rtl_batch_array: '6386140',
      name_search: 'LYCCARNE',
      name: "Lychnis chalcedonica 'Carnea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 0.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5736DKJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5736DKJD'
    },
    {
      status: 210,
      sku: 'PM0071790',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236243',
      rtl_batch_array: '6236243',
      name_search: 'LEFIREST',
      name: 'Leucothoe Firestar',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRJX4SR6;TTNVHRR5;G77VHL1X;GYWLTZX4;2WHLE7C4;WTRRYBLR;TSCWE6EL',
      statusContent: 'dot',
      hash: 'V69V6NES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V69V6NES'
    },
    {
      status: 210,
      sku: 'PM0071791',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236245',
      rtl_batch_array: '6236245',
      name_search: 'LONITIDA',
      name: 'Lonicera nitida',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 554,
      chnn_stock_retail: 554,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1JTDKH6;YKG75834;CRAYVTZ4;SSSSXDTD;YP31E8G3;TTA9TLTC;NSZGC3XE;C11D8GKN;R6GTGC3Z;R4RBWG2C',
      statusContent: 'dot',
      hash: 'ZNER8E9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNER8E9J'
    },
    {
      status: 210,
      sku: 'PM0085734',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386141',
      rtl_batch_array: '6386141',
      name_search: 'LAHELMSD',
      name: "Lavandula 'Helmsdale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 354,
      chnn_stock_retail: 354,
      sppl_order_minimum: 3,
      sppl_prcp: 0.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AXZ98P5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXZ98P5C'
    },
    {
      status: 210,
      sku: 'PM0085735',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386142',
      rtl_batch_array: '6386142',
      name_search: 'LIGDROCK',
      name: 'Limonium gmelinii Dazzle Rocks',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2T5K581C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T5K581C'
    },
    {
      status: 210,
      sku: 'PM0085736',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386143',
      rtl_batch_array: '6386143',
      name_search: 'LIPSAPHI',
      name: "Linum perenne 'Saphir'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SW9RY2N2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW9RY2N2'
    },
    {
      status: 210,
      sku: 'PM0039697',
      core_name: 'Plant',
      sppl_ean: '8720664699984',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905591',
      rtl_batch_array: '5905591',
      name_search: 'NADMLLIM',
      name: 'Nandina domestica Magical Lemon & Lime',
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_prcp: 18.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVY8RD8G;G99N52BD;447JW7Z1;ZJBGXJ9W;K5R4AZL3;6HEXD3D3',
      statusContent: 'dot',
      hash: 'NXSCHT7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXSCHT7E'
    },
    {
      status: 210,
      sku: 'PM0085737',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386144',
      rtl_batch_array: '6386144',
      name_search: 'NEFJWALK',
      name: "Nepeta f. 'Junior Walker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41LGZBNY;NC495WDT;HRNTZC4Z;KK7JV3VR;T4TTSWG6',
      statusContent: 'dot',
      hash: 'YSPT1C8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSPT1C8Y'
    },
    {
      status: 210,
      sku: 'PM0079699',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330015',
      rtl_batch_array: '6330015',
      name_search: 'NECCITRI',
      name: "Nepeta cataria 'Citriodora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 906,
      chnn_stock_retail: 906,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '43YDTX7C',
      statusContent: 'dot',
      hash: 'P5B3DEVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5B3DEVH'
    },
    {
      status: 210,
      sku: 'PM0079700',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330016',
      rtl_batch_array: '6330016',
      name_search: 'PAVKUPFE',
      name: "Panicum virgatum 'Kupferhirse'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PAVP88ZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAVP88ZG'
    },
    {
      status: 210,
      sku: 'PM0071812',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238464',
      rtl_batch_array: '6238464',
      name_search: 'LOSSBLUE',
      name: 'Lobelia s. Starship Blue',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 611,
      chnn_stock_retail: 611,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YPYXYTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YPYXYTK'
    },
    {
      status: 210,
      sku: 'PM0071813',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308234',
      rtl_batch_array: '6238466, 6308234',
      name_search: 'MISSILBE',
      name: "Miscanthus sinensis 'Silberfeder'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 472,
      chnn_stock_retail: 593,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JW1RC3RL;6E6Z224A;843T7K64',
      statusContent: 'dot',
      hash: 'DB9Y76LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB9Y76LD'
    },
    {
      status: 210,
      sku: 'PM0079701',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370380',
      rtl_batch_array: '6370380, 6330017',
      name_search: 'PAKSERPY',
      name: 'Paronychia kapela serpyllifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 880,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RZ33W2H9',
      statusContent: 'dot',
      hash: '5AVPH6NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AVPH6NK'
    },
    {
      status: 810,
      sku: 'PM0079702',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330018',
      rtl_batch_array: '6330018',
      name_search: 'PEALU',
      name: "Pennisetum alopecuroides 'Lady U'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PKYALWE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKYALWE7'
    },
    {
      status: 810,
      sku: 'PM0079703',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330019',
      rtl_batch_array: '6330019',
      name_search: 'PEDGOLDF',
      name: "Penstemon digitalis 'Goldfinger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2L8H52D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2L8H52D'
    },
    {
      status: 210,
      sku: 'PM0079704',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330020',
      rtl_batch_array: '6330020',
      name_search: 'PEDPOCAH',
      name: "Penstemon digit. 'Pocahontas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 936,
      chnn_stock_retail: 936,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C82T92YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C82T92YT'
    },
    {
      status: 210,
      sku: 'PM0071958',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '5955990',
      rtl_batch_array: '5955990, 6294755',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 498,
      chnn_stock_retail: 745,
      sppl_prcp: 3.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'TPP2K78E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPP2K78E'
    },
    {
      status: 210,
      sku: 'PM0071960',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271117',
      rtl_batch_array: '6271117',
      name_search: 'CRJLSONJ',
      name: "Cryptomeria j. 'Little Sonja'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 248,
      chnn_stock_retail: 248,
      sppl_prcp: 6.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HYGXBLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HYGXBLX'
    },
    {
      status: 210,
      sku: 'PM0079705',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330024',
      rtl_batch_array: '6330024',
      name_search: 'MOFPUMME',
      name: "Monarda fistulosa 'Pummel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52TX2YPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52TX2YPS'
    },
    {
      status: 210,
      sku: 'PM0079706',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330025',
      rtl_batch_array: '6330025, 6319718',
      name_search: 'MUCOMPLE',
      name: 'Muehlenbeckia complexa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1596,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S3VJNB5W;LDSHNTCN;Z2BX3AGP;LW2XB1RP;5T4WV76A;RS44VY54;8K7RG1ZT',
      statusContent: 'dot',
      hash: 'GGJDWG6J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGJDWG6J'
    },
    {
      status: 210,
      sku: 'PM0079707',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349082',
      rtl_batch_array: '6349082, 6330026, 6348442',
      name_search: 'PHCRACKE',
      name: "Phlox (D) 'Crackerjack'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1470,
      chnn_stock_retail: 3017,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TWAL9RJB;Z6DS7BGA',
      statusContent: 'dot',
      hash: '7TYDH315',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TYDH315'
    },
    {
      status: 210,
      sku: 'PM0079708',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330027',
      rtl_batch_array: '6330027',
      name_search: 'PHRADMIR',
      name: "Phlox (D) 'Red Admiral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9AB8KNAA;PRW5RLYA;XS3GE935',
      statusContent: 'dot',
      hash: 'D27XD3SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D27XD3SX'
    },
    {
      status: 210,
      sku: 'PM0079709',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6330028',
      rtl_batch_array: '6330028, 6333289, 6348441',
      name_search: 'PHAMETHY',
      name: "Phlox (P) 'Amethyst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 676,
      chnn_stock_retail: 1914,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TYVS2XYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYVS2XYC'
    },
    {
      status: 810,
      sku: 'PM0079710',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330031',
      rtl_batch_array: '6330031',
      name_search: 'PHCLEOPA',
      name: "Phlox (P) 'Cleopatra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ACAVJYJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACAVJYJR'
    },
    {
      status: 210,
      sku: 'PM0071986',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6248956',
      rtl_batch_array: '6248956',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '030040P9',
      rng_range_identifier: 'H030040P9',
      rng_range_description: 'H30 cm 40 cm P9',
      sppl_stock_available: 9115,
      chnn_stock_retail: 9115,
      sppl_order_minimum: 6,
      sppl_prcp: 0.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'NCB3N5W8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCB3N5W8'
    },
    {
      status: 210,
      sku: 'PM0071992',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243051',
      rtl_batch_array: '6243051',
      name_search: 'WEWFIRE',
      name: "Weigela 'Wings of Fire'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X9W9Y2Y;DDRGK9JS;S1RAXPG6;WBPHS4XN;P9W6BXY9;BJXXHYSG',
      statusContent: 'dot',
      hash: '158XWA44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '158XWA44'
    },
    {
      status: 210,
      sku: 'PM0031755',
      core_name: 'Plant',
      sppl_ean: '8720349413638',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5804831',
      rtl_batch_array: '5804831',
      name_search: 'CLMRBRYD',
      name: "Clematis 'Mrs Robert Brydon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3629,
      chnn_stock_retail: 3629,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NZY6S8NT;9K25B6NT;JVRA7V9H',
      statusContent: 'dot',
      hash: 'TKJAXCHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKJAXCHT'
    },
    {
      status: 210,
      sku: 'PM0071964',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6250001',
      rtl_batch_array: '6250001',
      name_search: 'TSHETERO',
      name: 'Tsuga heterophylla',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 293,
      chnn_stock_retail: 293,
      sppl_prcp: 10.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C1TY5TP',
      statusContent: 'dot',
      hash: 'T6XSAD5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6XSAD5K'
    },
    {
      status: 810,
      sku: 'PM0008922',
      core_name: 'Plant',
      sppl_ean: '8720626327542',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582287',
      rtl_batch_array: '5582287',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'WZTNR8YJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZTNR8YJ'
    },
    {
      status: 210,
      sku: 'PM0071963',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238814',
      rtl_batch_array: '6238814',
      name_search: 'HECWOODB',
      name: "Hemerocallis 'Catherine Woodbury'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 686,
      chnn_stock_retail: 686,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5LX88XD2',
      statusContent: 'dot',
      hash: 'GNYPC4VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNYPC4VN'
    },
    {
      status: 810,
      sku: 'PM0039696',
      core_name: 'Plant',
      sppl_ean: '8720664699946',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905590',
      rtl_batch_array: '5905590',
      name_search: 'NADGSTRE',
      name: "Nandina domestica 'Gulf Stream'",
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_prcp: 18.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5177CD65;2ZXX95WG;HXBEY3NS;1KGAWP8J;DKXS5BGB;6D121H4B;VSRDDRHB;KW74VYE1;W3K99X4E;BC74L52W;ECSHV643;ZNS6C38G;A9NSHWK2',
      statusContent: 'dot',
      hash: 'YTC573T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTC573T8'
    },
    {
      status: 210,
      sku: 'PM0071427',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317477',
      rtl_batch_array: '6250992, 6317477, 6252582',
      name_search: 'GEDREAML',
      name: 'Geranium Dreamland',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 303,
      chnn_stock_retail: 866,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AH8KDCSD;LJH1XBT9',
      statusContent: 'dot',
      hash: '2HKX33CN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HKX33CN'
    },
    {
      status: 210,
      sku: 'PM0071560',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230503',
      rtl_batch_array: '6230503',
      name_search: 'ACPACHYP',
      name: 'Actaea pachypoda',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EVL6NSLR',
      statusContent: 'dot',
      hash: 'ATT1XCVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATT1XCVN'
    },
    {
      status: 210,
      sku: 'PM0071428',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252620',
      rtl_batch_array: '6252620',
      name_search: 'HEBINOCH',
      name: "Heuchera 'Binoche'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1314,
      chnn_stock_retail: 1314,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '971RWGWK',
      statusContent: 'dot',
      hash: '2GAHGL5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GAHGL5G'
    },
    {
      status: 910,
      sku: 'PM0071429',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEBBEAUT',
      name: "Heuchera 'Black Beauty'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'LEPJ33KH',
      statusContent: 'dot',
      hash: '66WJVKA4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '66WJVKA4'
    },
    {
      status: 910,
      sku: 'PM0074357',
      core_name: 'Garden',
      sppl_ean: '8720664859197',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083868',
      rtl_batch_array: '6083868',
      name_search: 'DCVMHETU',
      name: 'DCM Vloeibare Mestst. Huis & Tuin',
      sppl_size_code: '24LTRJER',
      rng_range_identifier: 'JAR2.5LTR',
      rng_range_description: 'Jerry can 2.5 Liter',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 8.3,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'X4GS9X4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'X4GS9X4V'
    },
    {
      status: 210,
      sku: 'PM0031766',
      core_name: 'Plant',
      sppl_ean: '8720349428472',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5804843',
      rtl_batch_array: '5804843',
      name_search: 'SAPMARY',
      name: "Sanguisorba 'Proud Mary'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2808,
      chnn_stock_retail: 2808,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '17N75R5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17N75R5E'
    },
    {
      status: 210,
      sku: 'PM0027196',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274537',
      rtl_batch_array: '6274537',
      name_search: 'HOGEXPEC',
      name: "Hosta 'Great Expectations'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1826,
      chnn_stock_retail: 1826,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KZCT9S1V;EC1VZVGA;6E8E4AB5',
      statusContent: 'dot',
      hash: '8XN469E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XN469E5'
    },
    {
      status: 210,
      sku: 'PM0039596',
      core_name: 'Plant',
      sppl_ean: '8720664688896',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5902333',
      rtl_batch_array: '5902333, 6350143, 6320871',
      name_search: 'ERLBLUE',
      name: "Eryngium 'Lapis Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 585,
      chnn_stock_retail: 913,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EVV5EHV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVV5EHV1'
    },
    {
      status: 910,
      sku: 'PM0071708',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'UNRUBRA',
      name: 'Uncinia rubra',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'LDPD2Z6L',
      statusContent: 'dot',
      hash: 'N7GBNSH6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N7GBNSH6'
    },
    {
      status: 210,
      sku: 'PM0021687',
      core_name: 'Plant',
      sppl_ean: '8720626347014',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495317',
      rtl_batch_array: '5495317',
      name_search: 'FOVPURPU',
      name: "Foeniculum vulgare 'Purpureum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NTE65B9R;7N42B8DB;78JG3WN5;4DX6WV98;A2N1XP7C;JNRETVLS;6JXAJDK3;TTHJ54N5;D9N956WV;LZKHG8G5;GJB7R9EY;NDDAAZLH;95ZR5W46;D4C2W36Y;C1CE1YR9;RX4K9VY4;ZT91KPY8;PN8TC7Z5;AGWYLBL5',
      statusContent: 'dot',
      hash: '61C3XNXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61C3XNXW'
    },
    {
      status: 210,
      sku: 'PM0031039',
      core_name: 'Plant',
      sppl_ean: '8720349406630',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4510460',
      rtl_batch_array: '4510460',
      name_search: 'PAVSANGR',
      name: "Panicum virgatum 'Sangria'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 488,
      chnn_stock_retail: 488,
      sppl_prcp: 2.516,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G1VVV9XB;72NX1NVV;BBBW3AY9;GXRXRERK;ZKYKC5C4;SDG6D5V6;HKT9BKBH;87JC9CCR;C9ACB943;G97B3DCA;5WXEXVR2',
      imageBatch: 'HVJ6CPXC',
      statusContent: 'dot',
      hash: 'E6PZ7TJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6PZ7TJ6'
    },
    {
      status: 210,
      sku: 'PM0074360',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350655',
      rtl_batch_array: '6350655',
      name_search: 'LILROCKE',
      name: "Ligularia 'Little Rocket'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYWD3SGG;CHBET7DL;HDHBL5ED;CLJ9VKZV;B8XN8XTJ;2ZDK713S;783K9XCG;KNSPRECK;7PSC5W88;ZAG8BRRR;4DENVW83',
      statusContent: 'dot',
      hash: '8DZ8XJ3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DZ8XJ3S'
    },
    {
      status: 210,
      sku: 'PM0025393',
      core_name: 'Plant',
      sppl_ean: '8720664549739',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054688',
      rtl_batch_array: '6054688',
      name_search: 'HOFLINE',
      name: "Hosta 'Firn Line'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H8BTWLLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8BTWLLZ'
    },
    {
      status: 210,
      sku: 'PM0071998',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266347',
      rtl_batch_array: '6266347',
      name_search: 'CHPFNANA',
      name: "Chamaecyparis pisifera 'Filifera Nana'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1130,
      chnn_stock_retail: 1130,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6H1P49H2;63BTDXDG;69KRN8NB;2PJJVP62',
      statusContent: 'dot',
      hash: '4LWKRZVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LWKRZVD'
    },
    {
      status: 210,
      sku: 'PM0039726',
      core_name: 'Plant',
      sppl_ean: '8720664683594',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5916270',
      rtl_batch_array: '5916270',
      name_search: 'CASSCEPT',
      name: "Carex 'Silver Sceptre'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H2YCHBRG',
      statusContent: 'dot',
      hash: 'WZ4WVLJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZ4WVLJG'
    },
    {
      status: 210,
      sku: 'PM0074359',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270455',
      rtl_batch_array: '6270455',
      name_search: 'SKJAPONI',
      name: 'Skimmia japonica',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P59871VN;P7P9766D;Z3RAG6X6;6LR3G5WK;NBCLYYLG;47GA8LJT;7H72RW62;CKJYJ4BV;C878G3ZG;SEDE51ES;A72CHZDZ;NN7J66C1;G5C1WDCH;EB8L66SC;2R5E177K;99VHELKW;7S58V8VX',
      statusContent: 'dot',
      hash: '62TTY52X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62TTY52X'
    },
    {
      status: 210,
      sku: 'PM0072002',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290765',
      rtl_batch_array: '6290765',
      name_search: 'JUPMJULE',
      name: "Juniperus pfitzeriana 'Mint Julep'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 314,
      chnn_stock_retail: 314,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ECA5W8B7;WDB1X2HH;G9494HJY;H8A3JGVH;97N7RTGT;TL2TY95E;TEPY57C3;4G143PN2;2KL5BZSJ;ZRYC7KKP;AXK4LGDR;11WNEGGE;AJ126KGJ;B7D947SS;X78L6BGC',
      statusContent: 'dot',
      hash: 'ZTNGNYCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTNGNYCD'
    },
    {
      status: 210,
      sku: 'PM0021413',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6202290',
      rtl_batch_array: '6202290, 6080650, 6321917',
      name_search: 'ERPBZWER',
      name: "Eryngium planum 'Blauer Zwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 1790,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VT7NABS7;LWC1NN9Y;Z92N7LBV',
      statusContent: 'dot',
      hash: 'DWJRLJ3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWJRLJ3R'
    },
    {
      status: 210,
      sku: 'PM0000159',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350241',
      rtl_batch_array: '6350241, 5495328, 6355363',
      name_search: 'GESALOME',
      name: "Geranium 'Salom\u00e9'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 415,
      chnn_stock_retail: 487,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S819HB9B',
      statusContent: 'dot',
      hash: 'Z6PSNGLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6PSNGLZ'
    },
    {
      status: 210,
      sku: 'PM0071568',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6242117',
      rtl_batch_array: '6252388, 6242117, 6242145, 6275770, 6348304',
      name_search: 'DRAFFINI',
      name: 'Dryopteris affinis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 17663,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG8DG3G6',
      statusContent: 'dot',
      hash: 'EVPVPKCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVPVPKCL'
    },
    {
      status: 210,
      sku: 'PM0071563',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349923',
      rtl_batch_array: '6349923',
      name_search: 'CEPROCER',
      name: 'Cephalaria procera',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KS9GPK21',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS9GPK21'
    },
    {
      status: 810,
      sku: 'PM0071564',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349968',
      rtl_batch_array: '6349968',
      name_search: 'CRHARLQU',
      name: "Crocosmia 'Harlquin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '98SD4HXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98SD4HXH'
    },
    {
      status: 210,
      sku: 'PM0071567',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349987',
      rtl_batch_array: '6349987',
      name_search: 'DIDESMON',
      name: "Dianthus (PL) 'Desmond'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 134,
      chnn_stock_retail: 134,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJLB7HAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJLB7HAA'
    },
    {
      status: 210,
      sku: 'PM0039701',
      core_name: 'Plant',
      sppl_ean: '8720664800007',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '5905595',
      rtl_batch_array: '6345502, 5905595',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 421,
      chnn_stock_retail: 541,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      statusContent: 'dot',
      hash: 'EP1LNWKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EP1LNWKH'
    },
    {
      status: 210,
      sku: 'PM0071569',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350019',
      rtl_batch_array: '6350019',
      name_search: 'ECFOBICO',
      name: "Echinacea 'Fountain Orange Bicolour'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N7BE71YS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7BE71YS'
    },
    {
      status: 210,
      sku: 'PM0074361',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350133',
      rtl_batch_array: '6350133, 6270490',
      name_search: 'ERKLLADY',
      name: "Erigeron karvinsk. 'Lavender Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 436,
      chnn_stock_retail: 664,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PXCKA23H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXCKA23H'
    },
    {
      status: 210,
      sku: 'PM0079711',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330032',
      rtl_batch_array: '6330032',
      name_search: 'PHDLAVEN',
      name: "Phlox (P) 'David's Lavender'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LPB5H666',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPB5H666'
    },
    {
      status: 210,
      sku: 'PM0079712',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330033',
      rtl_batch_array: '6330033',
      name_search: 'PHDUESTE',
      name: "Phlox (P) 'D\u00fcsterlohe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 774,
      chnn_stock_retail: 774,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TS393VVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TS393VVR'
    },
    {
      status: 210,
      sku: 'PM0079713',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330035',
      rtl_batch_array: '6330035',
      name_search: 'PHFCORAL',
      name: 'Photinia fraseri Corallina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NW3C6DHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW3C6DHR'
    },
    {
      status: 210,
      sku: 'PM0079714',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330038',
      rtl_batch_array: '6330038',
      name_search: 'PHGZEPPE',
      name: "Phlox (P) 'Graf Zeppelin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DEXCSLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DEXCSLW'
    },
    {
      status: 210,
      sku: 'PM0079715',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330039',
      rtl_batch_array: '6330039',
      name_search: 'PHGSCHWE',
      name: "Phlox 'Grafin von Schwerin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J7H6NYCZ',
      statusContent: 'dot',
      hash: 'ZV86DL9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV86DL9J'
    },
    {
      status: 210,
      sku: 'PM0079716',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330041',
      rtl_batch_array: '6330041',
      name_search: 'PHKATHER',
      name: "Phlox (P) 'Katherine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KXHJ5VV;Z2D74VJV',
      statusContent: 'dot',
      hash: '9E8KXZ7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E8KXZ7W'
    },
    {
      status: 210,
      sku: 'PM0004079',
      core_name: 'Plant',
      sppl_ean: '8720626346871',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5960165',
      rtl_batch_array: '5960165',
      name_search: 'FEAMETHY',
      name: 'Festuca amethystina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YXG8VWD7;CTG6TLJB;HKDV9HD9;BL12J4VP;VXELKNDH;ZNWD17RV;PZ9AJEL6;C2WLXBY4;Y6241XHY;X14EC4TP;4WLKTNPN',
      statusContent: 'dot',
      hash: 'DA524NB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA524NB9'
    },
    {
      status: 210,
      sku: 'PM0079717',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330042',
      rtl_batch_array: '6330042, 6345199',
      name_search: 'PHKIRCHE',
      name: "Phlox (P) 'Kirchenf\u00fcrst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 237,
      chnn_stock_retail: 337,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCHE78JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCHE78JE'
    },
    {
      status: 210,
      sku: 'PM0079718',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330044',
      rtl_batch_array: '6330044',
      name_search: 'PHMAHDI',
      name: "Phlox (P) 'Le Mahdi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PV2V6ND4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PV2V6ND4'
    },
    {
      status: 210,
      sku: 'PM0079719',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330045',
      rtl_batch_array: '6330045, 6345196',
      name_search: 'PHMPEPPE',
      name: 'Phlox (P) Miss Pepper',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBERLNJT;L8RPCDJ9',
      statusContent: 'dot',
      hash: '52LT5ZN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52LT5ZN7'
    },
    {
      status: 910,
      sku: 'PM0079720',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHNICKY',
      name: "Phlox (P) 'Nicky'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'J2CKA9CG',
      statusContent: 'dot',
      hash: 'CLA3HGYJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CLA3HGYJ'
    },
    {
      status: 910,
      sku: 'PM0071795',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6236254',
      rtl_batch_array: '6236254',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 3.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'LSH5TXE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSH5TXE2'
    },
    {
      status: 210,
      sku: 'PM0079721',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330048',
      rtl_batch_array: '6330048',
      name_search: 'PHOLYMPU',
      name: "Phlox (P) 'Olympus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GP4BHWS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GP4BHWS9'
    },
    {
      status: 210,
      sku: 'PM0079722',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330049',
      rtl_batch_array: '6330049',
      name_search: 'PHPOPEYE',
      name: "Phlox (P) 'Popeye'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P8LTRY1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8LTRY1V'
    },
    {
      status: 210,
      sku: 'PM0071430',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252624',
      rtl_batch_array: '6252624',
      name_search: 'HEULMARM',
      name: "Heuchera 'Lime Marmalade'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1047,
      chnn_stock_retail: 1047,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2K6RAZ6B;5471CTLN;P1BJA3DV;ZJ41C743;XC6ES1PL;JS17Z8SG;CVWP95Y9;DCSZV8X5;SKWEW342;5JXZ9R5R;1AVRWHW1',
      statusContent: 'dot',
      hash: 'W63HLVH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W63HLVH9'
    },
    {
      status: 210,
      sku: 'PM0079723',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330050',
      rtl_batch_array: '6330050',
      name_search: 'PHRRHOOD',
      name: "Phlox (P) 'Red Riding Hood'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2NK3XJ2A;D5WPZG55',
      statusContent: 'dot',
      hash: 'N565KLPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N565KLPY'
    },
    {
      status: 210,
      sku: 'PM0079724',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6330051',
      rtl_batch_array: '6330051, 6333293, 6307049',
      name_search: 'PHRIJNST',
      name: "Phlox (P) 'Rijnstroom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 695,
      chnn_stock_retail: 1427,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWBVZ9CA;KLC441KK',
      statusContent: 'dot',
      hash: 'E2JNN3BG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2JNN3BG'
    },
    {
      status: 210,
      sku: 'PM0079725',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330052',
      rtl_batch_array: '6330052, 6345203',
      name_search: 'PHRPASTE',
      name: "Phlox (P) 'Rosa Pastell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 546,
      chnn_stock_retail: 646,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZT42KCGT;57AJBWTD',
      statusContent: 'dot',
      hash: 'SDC3NHNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDC3NHNN'
    },
    {
      status: 210,
      sku: 'PM0079726',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330054',
      rtl_batch_array: '6330054, 6340037',
      name_search: 'PHWADMIR',
      name: "Phlox (P) 'White Admiral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 856,
      chnn_stock_retail: 1593,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W9V9WB97;951J2BBD',
      statusContent: 'dot',
      hash: 'X4C5EEGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4C5EEGH'
    },
    {
      status: 210,
      sku: 'PM0071816',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238756',
      rtl_batch_array: '6238756',
      name_search: 'MOPRSHAD',
      name: "Monarda 'Panorama Red Shades'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BGRGGRAX',
      statusContent: 'dot',
      hash: '2NHVBE9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NHVBE9H'
    },
    {
      status: 810,
      sku: 'PM0079727',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330055',
      rtl_batch_array: '6330055',
      name_search: 'PHAWWHEE',
      name: "Phlox adsurgens 'Wagon Wheel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6JT689G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JT689G9'
    },
    {
      status: 210,
      sku: 'PM0085738',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386157',
      rtl_batch_array: '6386157',
      name_search: 'PAOPVLUI',
      name: "Papaver orientale 'Prinzessin Viktoria Luise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_order_minimum: 3,
      sppl_prcp: 0.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N1GHC13K;HHHKA7PH',
      statusContent: 'dot',
      hash: 'EDXTD9GY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDXTD9GY'
    },
    {
      status: 810,
      sku: 'PM0079728',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330057',
      rtl_batch_array: '6330057',
      name_search: 'POCHOPLE',
      name: "Polemonium caeruleum 'Hopleys'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JSCYJRDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSCYJRDL'
    },
    {
      status: 810,
      sku: 'PM0071876',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242077',
      rtl_batch_array: '6242077',
      name_search: 'ALLAYON',
      name: "Alstroemeria 'Layon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8B9YXW49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8B9YXW49'
    },
    {
      status: 210,
      sku: 'PM0079729',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330059',
      rtl_batch_array: '6330059',
      name_search: 'PRBEESIA',
      name: 'Primula beesiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PTT4RTYJ;R32R7R16;SCNBSYC8;1R687HKS',
      statusContent: 'dot',
      hash: 'L2PJK5SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2PJK5SG'
    },
    {
      status: 210,
      sku: 'PM0071994',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287028',
      rtl_batch_array: '6287028',
      name_search: 'CHLELLWO',
      name: "Chamaecyparis lawsoniana 'Ellwoodii'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 605,
      chnn_stock_retail: 605,
      sppl_prcp: 3.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7VW17XAL;G73C4BY1;1REDTLLS;8RYG3V2X;EGZ7NWEL;EEYAWPC3;9ZJXB5JL;WCY61V3S;E5B9LR6G;TVHNESG3;8T72GYHT;VT4SZWK1;AHG8TJWR;JPTLC9ZL;921GK5BK;DNRNXY6Y;X2PRJPB4;EC1W6WSB;JHZ43P9G',
      statusContent: 'dot',
      hash: 'W8C1P7VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8C1P7VX'
    },
    {
      status: 210,
      sku: 'PM0071995',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290758',
      rtl_batch_array: '6290758',
      name_search: 'CHLYVONN',
      name: "Chamaecyparis lawsoniana 'Yvonne'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2082,
      chnn_stock_retail: 2082,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2GGE33AK;2JGSGH6N;Z71DCRCA;ZG76V8LD',
      statusContent: 'dot',
      hash: 'P3ALVTJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3ALVTJB'
    },
    {
      status: 210,
      sku: 'PM0071996',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295345',
      rtl_batch_array: '6295345',
      name_search: 'CHLWISSE',
      name: "Chamaecyparis lawsoniana 'Wisselii'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 848,
      chnn_stock_retail: 848,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WGDZ331J',
      statusContent: 'dot',
      hash: '2WKRETRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WKRETRP'
    },
    {
      status: 210,
      sku: 'PM0079730',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383382',
      rtl_batch_array: '6330060, 6383382',
      name_search: 'PRJMCRIM',
      name: "Primula japonica 'Miller's Crimson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1104,
      chnn_stock_retail: 2720,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6AJ2JAZW;CHVGEHBP',
      statusContent: 'dot',
      hash: 'KNHDHWKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNHDHWKG'
    },
    {
      status: 210,
      sku: 'PM0082298',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349926',
      rtl_batch_array: '6349926',
      name_search: 'CHLANOSA',
      name: 'Cheilanthes lanosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 538,
      chnn_stock_retail: 538,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T7KCEPVA;CK2TLVRS;B55HL2LK;EHT8JBBL;9NKDE4V7;BLVH86LP;VYDSSH2N;AN1ZDN6Z;LG2BT1TK',
      statusContent: 'dot',
      hash: 'G96DT8X8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G96DT8X8'
    },
    {
      status: 210,
      sku: 'PM0079731',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330061',
      rtl_batch_array: '6330061',
      name_search: 'PRVERIS',
      name: 'Primula veris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4467,
      chnn_stock_retail: 4467,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RGCYX9V4;NTNKZSLP;VPE34KEK;BYNEE652;BVYH9KYC;G16JYEY3;VP9WASG8;KBSXVXKB;X3NCVRKA;59B5HC8C;Z1R4PCTT;J3YXRKNB;7PXN38ZX;DA18DZ4L;DYTY627J;93RDSPCP;NKWZZCKX;J4VLG2VV;595W2AWA;A621VW51;DKTDBE25;ZC1PGZ8N;7135GN79',
      statusContent: 'dot',
      hash: 'JP4WPX87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP4WPX87'
    },
    {
      status: 210,
      sku: 'PM0082299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349933',
      rtl_batch_array: '6349933',
      name_search: 'CHKBERNS',
      name: "Chrysanthemum (I) 'Kleiner Bernstein'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z3S7NJ44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3S7NJ44'
    },
    {
      status: 810,
      sku: 'PM0082300',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349934',
      rtl_batch_array: '6349934',
      name_search: 'CHROSENS',
      name: "Chrysanthemum (I) 'Rosensilber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X4AR96WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4AR96WR'
    },
    {
      status: 210,
      sku: 'PM0071565',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349966',
      rtl_batch_array: '6349966',
      name_search: 'CRRKING',
      name: "Crocosmia 'Red King'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 539,
      chnn_stock_retail: 539,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW3Y5XH2;Y8W9ZC72;GND4ET54;WDSNN92G;HWCKBW38;662HW1S1;L54NL83L;G6AH2NLW',
      statusContent: 'dot',
      hash: 'E19ZECS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E19ZECS2'
    },
    {
      status: 210,
      sku: 'PM0085739',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386159',
      rtl_batch_array: '6386159',
      name_search: 'PECCBARR',
      name: "Penstemon caespitosus 'Claude Barr'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLC9P6NB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLC9P6NB'
    },
    {
      status: 210,
      sku: 'PM0085740',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386160',
      rtl_batch_array: '6386160',
      name_search: 'PEVLCORP',
      name: "Persicaria virginiana 'Lance Corporal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8LWK1V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8LWK1V1'
    },
    {
      status: 210,
      sku: 'PM0024947',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '6315184',
      rtl_batch_array: '6315184, 5866502, 6217143, 6015043, 6333234',
      name_search: 'FRVESCA',
      name: 'Fragaria vesca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1481,
      chnn_stock_retail: 26134,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NG94EY2C;K2DCSSXA;CA3PN5XL;RWXV4VCT;LYWLJEPK;DCCYE7D9;8WNYJYXW;CGCJBT4P;TTXGBD7X;TW2AYA2G;8X3D9J28;KGPVVN4T',
      statusContent: 'dot',
      hash: '6EBA5DN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EBA5DN7'
    },
    {
      status: 210,
      sku: 'PM0017476',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6255894',
      rtl_batch_array: '6349145, 6171193, 6208087, 6255894',
      name_search: 'SANMAINA',
      name: "Salvia nemorosa 'Mainacht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 27006,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H6PB4STH;R7P83E3T',
      statusContent: 'dot',
      hash: '7ETAVP5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ETAVP5W'
    },
    {
      status: 210,
      sku: 'PM0085741',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386162',
      rtl_batch_array: '6386162',
      name_search: 'PHFPPETE',
      name: "Photinia fraseri 'Purple Peter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.09,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X6D46N91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6D46N91'
    },
    {
      status: 210,
      sku: 'PM0085742',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386164',
      rtl_batch_array: '6386164',
      name_search: 'PHOBEYES',
      name: "Phlox (S) 'Oakington Blue Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 353,
      chnn_stock_retail: 353,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '65YYZACD;8DJ9EVZW;8RZ68REL;8LHS2GVZ;2AKGT3LA;CCVKGWZW;VEHJ5RR8;29D1VPH1;GLYYJWAB',
      statusContent: 'dot',
      hash: 'WR2YAWK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WR2YAWK4'
    },
    {
      status: 810,
      sku: 'PM0085743',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386166',
      rtl_batch_array: '6386166',
      name_search: 'PHBBLACK',
      name: "Phormium 'Back in Black'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z521RRYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z521RRYE'
    },
    {
      status: 210,
      sku: 'PM0085744',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386167',
      rtl_batch_array: '6386167',
      name_search: 'POCBANJO',
      name: "Polemonium caeruleum Brise d'Anjou",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L37T7WW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L37T7WW4'
    },
    {
      status: 210,
      sku: 'PM0085745',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386168',
      rtl_batch_array: '6386168',
      name_search: 'POSDWOLL',
      name: "Polystichum setiferum 'Divisilobum Wollaston'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y2GAD2A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2GAD2A4'
    },
    {
      status: 810,
      sku: 'PM0085746',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386170',
      rtl_batch_array: '6386170',
      name_search: 'PRMGGUIN',
      name: "Primula margotae 'Garryarde Guinevere'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AYCWDGAB',
      statusContent: 'dot',
      hash: 'LCPTJP87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCPTJP87'
    },
    {
      status: 210,
      sku: 'PM0085747',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386176',
      rtl_batch_array: '6386176',
      name_search: 'PURSPLAS',
      name: "Pulmonaria 'Raspberry Splash'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DXJZPXP3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXJZPXP3'
    },
    {
      status: 210,
      sku: 'PM0085748',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386177',
      rtl_batch_array: '6386177',
      name_search: 'PURBRED',
      name: "Pulmonaria rubra 'Bowles Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LA6GDAVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA6GDAVT'
    },
    {
      status: 210,
      sku: 'PM0071431',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260697',
      rtl_batch_array: '6260697, 6252625',
      name_search: 'HEMARMAL',
      name: "Heuchera 'Marmalade'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 949,
      chnn_stock_retail: 3651,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2E3D4BNL;BB94WBTB;28RKG91V;ETBZED7N;ZCV4GTHV;CNR8E1DR;4Y62BWT1;CC54DSKH;CYC5NB6R;B66536PT;C4V31773;WAPLRVCD;JHPNWADY;5NSV9J8R;B3PC9KWK',
      statusContent: 'dot',
      hash: '84WEZYG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84WEZYG3'
    },
    {
      status: 210,
      sku: 'PM0071433',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260700',
      rtl_batch_array: '6260700, 6252629',
      name_search: 'HEPPUDDI',
      name: "Heuchera 'Plum Pudding'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1392,
      chnn_stock_retail: 2721,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZEWYWHJ;KYBS9736;5DP65PX3',
      statusContent: 'dot',
      hash: 'LRSXYGC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRSXYGC2'
    },
    {
      status: 210,
      sku: 'PM0071432',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244249',
      rtl_batch_array: '6244249, 6254752',
      name_search: 'HEMPPURP',
      name: "Heuchera micrantha 'Palace Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3312,
      chnn_stock_retail: 4058,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JVG47C77;8RAZ4NEY;7CGSBYGW;ZCB25BW5;6SXB6EWG;CV5JXRTV;VENPDWKV;93HG2PVG;PC4EZA6V;4NE1SRNW;DHNBLKSB;PW83K5DX;WDLJVDJ5;7EZ42RDX',
      statusContent: 'dot',
      hash: 'L2CB2XC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2CB2XC2'
    },
    {
      status: 210,
      sku: 'PM0072001',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243349',
      rtl_batch_array: '6243349',
      name_search: 'JUHLIMEG',
      name: "Juniperus horizontalis 'Limeglow'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 861,
      chnn_stock_retail: 861,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ENZDAY65;C94T6J93;66HZJRJ2;VKJREEBP;E8R9YX3P;A18ZN9PT;Z8Y7CWRJ;VRDE9VDN;YTSGDGJA;7KG71EKP;ZBHNV74Y;W119BWVR;99SNLSPK;AZ7CZKPX;GC7A74E5;2TCW9TZ9',
      statusContent: 'dot',
      hash: '6E39XG5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6E39XG5C'
    },
    {
      status: 210,
      sku: 'PM0074358',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 4,
      btch_active_retail: '6270452',
      rtl_batch_array: '6270452, 6304908, 6334779, 6310415',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1400,
      chnn_stock_retail: 17577,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'VLNCKKJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLNCKKJH'
    },
    {
      status: 210,
      sku: 'PM0003630',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308451',
      rtl_batch_array: '6349149, 6153434, 5364492, 6308451, 6339916',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 9650,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      statusContent: 'dot',
      hash: '7PE7DAV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PE7DAV2'
    },
    {
      status: 210,
      sku: 'PM0039700',
      core_name: 'Plant',
      sppl_ean: '8720353010984',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905594',
      rtl_batch_array: '5905594',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_prcp: 18.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      statusContent: 'dot',
      hash: '12521TJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12521TJ6'
    },
    {
      status: 210,
      sku: 'PM0082301',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349950',
      rtl_batch_array: '6349950',
      name_search: 'COCBRULE',
      name: 'Coreopsis Cr\u00e8me Brulee',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HBB7CWV6;CJCYGP9T;VXYHVDKL;17R2T1JW;ATCZEBGW;9E4RX43C;2C52NDJT;V439Y44E;YA1EYCE9',
      statusContent: 'dot',
      hash: 'D8R1KV3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8R1KV3E'
    },
    {
      status: 210,
      sku: 'PM0082302',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349956',
      rtl_batch_array: '6349956',
      name_search: 'COSCLUST',
      name: "Coreopsis 'Star Cluster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZNVJTA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZNVJTA4'
    },
    {
      status: 210,
      sku: 'PM0082303',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349963',
      rtl_batch_array: '6349963, 6320845',
      name_search: 'COATROSA',
      name: 'Cosmos atrosanguineus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1794,
      chnn_stock_retail: 1890,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '54WDXBSK;6XDS5PDV;PC5E7JA9;RXA5VH9B',
      statusContent: 'dot',
      hash: '6NVTKR3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NVTKR3E'
    },
    {
      status: 210,
      sku: 'PM0024588',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370373',
      rtl_batch_array: '6370373, 6193645',
      name_search: 'LYNUMMUL',
      name: 'Lysimachia nummularia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1171,
      chnn_stock_retail: 2171,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7L31VN57;BWXX8AX1;2V2VBKW6;DDZBD1WH;DSXJ5AY9',
      statusContent: 'dot',
      hash: 'E5EX518R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5EX518R'
    },
    {
      status: 210,
      sku: 'PM0071881',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242120',
      rtl_batch_array: '6301089, 6242120, 6348446',
      name_search: 'POACULEA',
      name: 'Polystichum aculeatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4703,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R9TRX944;WBXBGB36;WT2GR6S9;ZZC8TX5G;A44CS6WH;68K2BJZV;2WGS931X;W7JALXYA;2KX6DW1T;X6ZYPLSC;2XRZDARB;TN3RTTW8;DLNP7V33;EP2TNSXG;N3YJYTEN;TR91LVB2;K6HCANB7;G2Z55W2R',
      statusContent: 'dot',
      hash: 'DRCH1N5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRCH1N5W'
    },
    {
      status: 210,
      sku: 'PM0072005',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243353',
      rtl_batch_array: '6243353',
      name_search: 'PIGREND',
      name: "Picea glauca 'Rainbow's End'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2187,
      chnn_stock_retail: 2187,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B5661AZG;D3PX41ZP;E3JLN1PE;94AV8B6X;22E7L2S6;XDVY1BHP;DL2HDKGT;N3N6LEVA;8K1EVD9D;T282HR76;VTGSSWTS;NB4Z5PLN;GVYXRT59',
      statusContent: 'dot',
      hash: 'TRPKAVEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRPKAVEJ'
    },
    {
      status: 210,
      sku: 'PM0082304',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349976',
      rtl_batch_array: '6349976',
      name_search: 'CYCALBUM',
      name: "Cyclamen coum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 514,
      chnn_stock_retail: 514,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K745LN4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K745LN4C'
    },
    {
      status: 210,
      sku: 'PM0001323',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300781',
      rtl_batch_array: '6300781',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14034,
      chnn_stock_retail: 14034,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'WY1T7T57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WY1T7T57'
    },
    {
      status: 210,
      sku: 'PM0072008',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253851',
      rtl_batch_array: '6253851',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1464,
      chnn_stock_retail: 1464,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: '2XC8NS51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XC8NS51'
    },
    {
      status: 210,
      sku: 'PM0012669',
      core_name: 'Plant',
      sppl_ean: '8720626360297',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364353',
      rtl_batch_array: '5364353, 6350706, 6217029',
      name_search: 'NEPBLUE',
      name: "Nepeta 'Purssian Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1168,
      chnn_stock_retail: 3090,
      sppl_order_minimum: 3,
      sppl_prcp: 0.918,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EH7L9E5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH7L9E5L'
    },
    {
      status: 210,
      sku: 'PM0085749',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386178',
      rtl_batch_array: '6386178',
      name_search: 'PUSBOUQU',
      name: "Pulmonaria 'Silver Bouquet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JXAD44XD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXAD44XD'
    },
    {
      status: 210,
      sku: 'PM0082305',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349977',
      rtl_batch_array: '6349977',
      name_search: 'CYHPINK',
      name: "Cyclamen hederifolium 'Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NNLV71D3',
      statusContent: 'dot',
      hash: 'LYABLK4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYABLK4L'
    },
    {
      status: 210,
      sku: 'PM0072003',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243351',
      rtl_batch_array: '6243351',
      name_search: 'JUSBSTAR',
      name: "Juniperus squamata 'Blue Star'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 5529,
      chnn_stock_retail: 5529,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '223S71E6;YL28ZCAS;BESLT261;C5DRTS5J;JR1P944L;27XBARC5;KE55BKLZ;CYYDK5J8;TP7NVDP8;VJA9BAZY;68BCEN7R;5KJ2YNCK;XX713EV4;4717E53N;LXXWRJ96;SDP1WS9C;2KN4YKZ4;TYC557EP;882YW612;A8PLDHJH;LYT7PRN9;8WHJ75GB;YEL79DVY;XHVVVA2E',
      statusContent: 'dot',
      hash: '6XXEAXC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XXEAXC2'
    },
    {
      status: 210,
      sku: 'PM0071879',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6288299',
      rtl_batch_array: '6242080, 6288299',
      name_search: 'ANHRAHRE',
      name: "Anemone hybrida 'Richard Ahrens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZZDWYP3;RVPEBABY',
      statusContent: 'dot',
      hash: 'TZBZLE89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZBZLE89'
    },
    {
      status: 210,
      sku: 'PM0071571',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349983',
      rtl_batch_array: '6349983',
      name_search: 'DEATLANT',
      name: "Delphinium (B) 'Atlantis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 652,
      chnn_stock_retail: 652,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJCKCSL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJCKCSL1'
    },
    {
      status: 210,
      sku: 'PM0071578',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350165',
      rtl_batch_array: '6350165',
      name_search: 'EUPBONFI',
      name: "Euphorbia polychroma 'Bonfire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 803,
      chnn_stock_retail: 803,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HP1X44E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HP1X44E'
    },
    {
      status: 210,
      sku: 'PM0085750',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386180',
      rtl_batch_array: '6386180',
      name_search: 'PUTTOES',
      name: "Pulmonaria 'Twinkle Toes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7ZB9SKXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZB9SKXA'
    },
    {
      status: 210,
      sku: 'PM0071579',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350270',
      rtl_batch_array: '6350270',
      name_search: 'EDI88223',
      name: 'Gladiolus papilio',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4E2G7G55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E2G7G55'
    },
    {
      status: 210,
      sku: 'PM0071576',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350205',
      rtl_batch_array: '6350205, 6317839',
      name_search: 'GEHDCOOK',
      name: "Geranium himalayense 'Derrick Cook'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 1039,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BN4XJADW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BN4XJADW'
    },
    {
      status: 210,
      sku: 'PM0071580',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350271',
      rtl_batch_array: '6350271',
      name_search: 'GLPRUBY',
      name: "Gladiolus papilio 'Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X6TTRN2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6TTRN2C'
    },
    {
      status: 210,
      sku: 'PM0071583',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350293',
      rtl_batch_array: '6350293',
      name_search: 'HEINDIAN',
      name: "Helenium 'Indianersommer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1375,
      chnn_stock_retail: 1375,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SKG68VS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKG68VS1'
    },
    {
      status: 810,
      sku: 'PM0071584',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319704',
      rtl_batch_array: '6319704',
      name_search: 'HELWIECK',
      name: "Helenium 'Loysder Wieck'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V751XKNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V751XKNT'
    },
    {
      status: 810,
      sku: 'PM0071581',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349865',
      rtl_batch_array: '6349865',
      name_search: 'HEAURANT',
      name: 'Hedychium aurantiacum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 2.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1Y6L7VGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y6L7VGT'
    },
    {
      status: 810,
      sku: 'PM0071586',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350317',
      rtl_batch_array: '6350317',
      name_search: 'HEDWHAMM',
      name: "Helianthus 'Double Whammy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N669S931',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N669S931'
    },
    {
      status: 210,
      sku: 'PM0067541',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192828',
      rtl_batch_array: '6192828',
      name_search: 'MEMELISS',
      name: 'Melittis melissophyllum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBN5ZCEJ',
      statusContent: 'dot',
      hash: 'NSHV6KLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSHV6KLA'
    },
    {
      status: 210,
      sku: 'PM0011431',
      core_name: 'Plant',
      sppl_ean: '8720664653931',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '5837879',
      rtl_batch_array: '5837879, 6353486, 6339331',
      name_search: 'VIMGRAVE',
      name: "Vinca minor 'La Grave'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 934,
      chnn_stock_retail: 1665,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XBP9B52N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBP9B52N'
    },
    {
      status: 210,
      sku: 'PM0085751',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386181',
      rtl_batch_array: '6386181',
      name_search: 'PYPBFRIE',
      name: "Pycnanthemum pilosum 'Bees' Friend'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TL8Z9BPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TL8Z9BPW'
    },
    {
      status: 210,
      sku: 'PM0022004',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6340033',
      rtl_batch_array: '6037913, 6340033',
      name_search: 'MAMROSEA',
      name: "Malva moschata 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1865,
      chnn_stock_retail: 1955,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LZY851ER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZY851ER'
    },
    {
      status: 810,
      sku: 'PM0011668',
      core_name: 'Plant',
      sppl_ean: '8720626364257',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5251447',
      rtl_batch_array: '5251447',
      name_search: 'POCAERUL',
      name: 'Polemonium caeruleum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1EBYDGBY;ZAS3B3ZN;WX5K7YN5;1R5ZJSA4;JPHX777V;6P51G5J5;LVSC5XG4;N3PHV9ET;73Y237DL;GTHW94E3;B1A3EY79;N21J89W5',
      statusContent: 'dot',
      hash: '6SGLW99X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SGLW99X'
    },
    {
      status: 210,
      sku: 'PM0005554',
      core_name: 'Plant',
      sppl_ean: '8720664881297',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084661',
      rtl_batch_array: '6100755, 6084661',
      name_search: 'POBHHABI',
      name: "Polemonium boreale 'Heavenly Habit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4488,
      chnn_stock_retail: 4503,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1DE3DEA8',
      statusContent: 'dot',
      hash: 'K96316XL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K96316XL'
    },
    {
      status: 210,
      sku: 'PM0085752',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386182',
      rtl_batch_array: '6386182',
      name_search: 'SALDISCO',
      name: 'Salvia discolor',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WVSJN6RV;5HVTC6Z3;WT16WVL9;6N45V9R7;673Y9PDY;KTHX6TE4;XWA836SL;GYLZ6J28;TN9AR7EV;24S3JAS8;EG4NNVEK;GACX46TK',
      statusContent: 'dot',
      hash: 'TH6N187N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH6N187N'
    },
    {
      status: 210,
      sku: 'PM0001092',
      core_name: 'Plant',
      sppl_ean: '8720664698598',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084777',
      rtl_batch_array: '6084777, 6339870',
      name_search: 'LYCPCHOC',
      name: "Lysimachia congestiflora 'Persian Chocolate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6G157DNX',
      statusContent: 'dot',
      hash: 'Y8B1TNS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8B1TNS6'
    },
    {
      status: 210,
      sku: 'PM0071434',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234807',
      rtl_batch_array: '6234807',
      name_search: 'HEGOGH',
      name: "Heuchera 'Van Gogh'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KW9L4XSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW9L4XSB'
    },
    {
      status: 210,
      sku: 'PM0074479',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6119823',
      rtl_batch_array: '6119823',
      name_search: 'ABGSHERW',
      name: "Abelia grandiflora 'Sherwood'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1411,
      chnn_stock_retail: 1411,
      sppl_prcp: 1.977,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKREPWDG;YZGYVWX7;24YD3GXA;X4YDD432;41LVPCDD',
      statusContent: 'dot',
      hash: 'G29C7DW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G29C7DW1'
    },
    {
      status: 210,
      sku: 'PM0079732',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330062',
      rtl_batch_array: '6330062, 6320889',
      name_search: 'PEAACHOI',
      name: "Persicaria amplexicaulis 'Anne's Choice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APJXYKT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APJXYKT2'
    },
    {
      status: 210,
      sku: 'PM0079733',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6330063',
      rtl_batch_array: '6330063, 6383369, 6320892',
      name_search: 'PEAPMIST',
      name: "Persicaria amplexicaulis 'Pink Mist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 2319,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L8PL4TN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8PL4TN1'
    },
    {
      status: 810,
      sku: 'PM0071817',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301521',
      rtl_batch_array: '6301521',
      name_search: 'COSWFEAT',
      name: "Cortaderia selloana 'White Feather'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WLKYBCPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLKYBCPY'
    },
    {
      status: 810,
      sku: 'PM0079734',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330064',
      rtl_batch_array: '6330064',
      name_search: 'PEAPINKF',
      name: "Persicaria a. 'Pinkfield'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4AHZ2JPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AHZ2JPZ'
    },
    {
      status: 810,
      sku: 'PM0071878',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242079',
      rtl_batch_array: '6242079',
      name_search: 'ALTIERCE',
      name: "Alstroemeria 'Tierc\u00e9'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X3ZJB3PN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3ZJB3PN'
    },
    {
      status: 810,
      sku: 'PM0079735',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330066',
      rtl_batch_array: '6330066',
      name_search: 'ROPCCARD',
      name: "Rodgersia pinnata 'Crug Cardinal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S55JLNA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S55JLNA8'
    },
    {
      status: 210,
      sku: 'PM0079736',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330067',
      rtl_batch_array: '6330067',
      name_search: 'RUTAIWAN',
      name: 'Rubus taiwanicola',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 318,
      chnn_stock_retail: 318,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRN1TN7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRN1TN7E'
    },
    {
      status: 210,
      sku: 'PM0072004',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243352',
      rtl_batch_array: '6243352',
      name_search: 'PIALGEM',
      name: "Picea abies 'Little Gem'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1897,
      chnn_stock_retail: 1897,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GJ1GP8ZY;L68K9YT5;RX5T3XK6;ST3KW93Y;TRHC4E63;GSNRW614;JEK8T3VH;49VVGX6J',
      statusContent: 'dot',
      hash: 'P5JDKELW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5JDKELW'
    },
    {
      status: 210,
      sku: 'PM0085753',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386185',
      rtl_batch_array: '6386185',
      name_search: 'SANEVA',
      name: "Sambucus nigra 'Eva'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2337VWYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2337VWYW'
    },
    {
      status: 210,
      sku: 'PM0079737',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330068',
      rtl_batch_array: '6330068',
      name_search: 'RUFPGOLD',
      name: "Rudbeckia fulgida 'Pot of Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S43WGA1Z;AX7VATZC;T17WL9N1;H4AANN28;7V35L87D',
      statusContent: 'dot',
      hash: 'T98RZ73S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T98RZ73S'
    },
    {
      status: 210,
      sku: 'PM0079738',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330069',
      rtl_batch_array: '6330069, 6302755',
      name_search: 'RUTRILOB',
      name: 'Rudbeckia triloba',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1137,
      chnn_stock_retail: 1456,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X4VB8K9V;4W545A5N;139RY83R',
      statusContent: 'dot',
      hash: 'GPNJLP59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPNJLP59'
    },
    {
      status: 210,
      sku: 'PM0079739',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330073',
      rtl_batch_array: '6330073, 6319737',
      name_search: 'SANPFRIE',
      name: "Salvia nemorosa 'Pink Friesland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 597,
      chnn_stock_retail: 693,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VE6YPSBR;P24DDC1X',
      statusContent: 'dot',
      hash: 'WV5BJLKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV5BJLKB'
    },
    {
      status: 210,
      sku: 'PM0079740',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330074',
      rtl_batch_array: '6330074',
      name_search: 'SANPLUMO',
      name: "Salvia nemorosa 'Plumosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NYSPJV36',
      statusContent: 'dot',
      hash: 'ACTPEBBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACTPEBBW'
    },
    {
      status: 210,
      sku: 'PM0079741',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330075',
      rtl_batch_array: '6330075',
      name_search: 'SASRMARV',
      name: "Salvia sylvestris 'Rose Marvel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '472PS82E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '472PS82E'
    },
    {
      status: 210,
      sku: 'PM0079742',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330078',
      rtl_batch_array: '6330078',
      name_search: 'SANSCWHI',
      name: 'Salvia nem. Sensation Compact White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 873,
      chnn_stock_retail: 873,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ASV6JLL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASV6JLL2'
    },
    {
      status: 810,
      sku: 'PM0079743',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348415',
      rtl_batch_array: '6330079, 6348415',
      name_search: 'SANSMDRO',
      name: 'Salvia nemorosa Sensation Medium Deep Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7V66E75B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7V66E75B'
    },
    {
      status: 910,
      sku: 'PM0079744',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SASSBMAR',
      name: 'Salvia sylv. Sky Blue Marvel',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'LNLB9VWN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LNLB9VWN'
    },
    {
      status: 210,
      sku: 'PM0079745',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330081',
      rtl_batch_array: '6330081',
      name_search: 'SANSBLUE',
      name: "Salvia nemorosa 'Slimming Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RBZ8ZXAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBZ8ZXAA'
    },
    {
      status: 210,
      sku: 'PM0079746',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330082',
      rtl_batch_array: '6330082',
      name_search: 'SANSPINK',
      name: 'Salvia n. Synchro Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '22XXA5PT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22XXA5PT'
    },
    {
      status: 210,
      sku: 'PM0079747',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330083',
      rtl_batch_array: '6330083',
      name_search: 'SANSWHIT',
      name: "Salvia nemorosa 'Smash White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 597,
      chnn_stock_retail: 597,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KYKW3ACL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYKW3ACL'
    },
    {
      status: 210,
      sku: 'PM0085754',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386187',
      rtl_batch_array: '6386187',
      name_search: 'SAOCQUEE',
      name: "Sanguisorba officinalis 'Crimson Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KG1WNJRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KG1WNJRA'
    },
    {
      status: 210,
      sku: 'PM0085755',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386189',
      rtl_batch_array: '6386189',
      name_search: 'SAOARNHE',
      name: "Sanguisorba officinalis 'Arnhem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYX69J9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYX69J9S'
    },
    {
      status: 210,
      sku: 'PM0003517',
      core_name: 'Plant',
      sppl_ean: '8720626360563',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5350160',
      rtl_batch_array: '6349077, 5350160, 6295770',
      name_search: 'ORVCOMPA',
      name: "Origanum vulgare 'Compactum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 3042,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '788Y66N8;NW4J7DYJ;RXT6N4YA;7WJY5JKZ;J452Y38X;C4H224CK;8HLGDX1K;6J4G5L38;6AEC4JVY;2EJ15R6E;2839XWW6;K59YWA12;EYNS4Z4A;RVSS5W2K;H2GLXW78;A7YKN1Y1;R5RZB5D5',
      statusContent: 'dot',
      hash: 'YN28YRCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN28YRCG'
    },
    {
      status: 210,
      sku: 'PM0085756',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386190',
      rtl_batch_array: '6386190',
      name_search: 'SAOJSESS',
      name: "Sanguisorba o. 'Jam Session'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 512,
      chnn_stock_retail: 512,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '93BNKN2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93BNKN2R'
    },
    {
      status: 210,
      sku: 'PM0079748',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386186',
      rtl_batch_array: '6386186',
      name_search: 'SAPLLAZU',
      name: "Salvia pratensis 'Lapis Lazuli'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XK9PKYV8;GCPYY7YE',
      statusContent: 'dot',
      hash: 'B2LVNEK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2LVNEK2'
    },
    {
      status: 210,
      sku: 'PM0085757',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386193',
      rtl_batch_array: '6386193',
      name_search: 'SCCCUSHI',
      name: "Scabiosa 'Crimson Cushion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P7NEBGVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7NEBGVK'
    },
    {
      status: 210,
      sku: 'PM0071573',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350169',
      rtl_batch_array: '6350169, 6317850',
      name_search: 'FIRVENUS',
      name: "Filipendula rubra 'Venusta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 121,
      chnn_stock_retail: 512,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RSDA44YW;G6C7THJ5;ZSD2977J;DVS68SNT;KNKEKHH5',
      statusContent: 'dot',
      hash: 'WSPBNAGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSPBNAGY'
    },
    {
      status: 210,
      sku: 'PM0071582',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308476',
      rtl_batch_array: '6350279, 6308476, 6199490',
      name_search: 'HEBBISHO',
      name: "Helenium bigelovii 'The Bishop'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 928,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6RP8WD71;NZYPCXL2;NPWK2P47',
      statusContent: 'dot',
      hash: 'XZDX5BXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZDX5BXT'
    },
    {
      status: 210,
      sku: 'PM0085758',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386194',
      rtl_batch_array: '6386194',
      name_search: 'SETMDRED',
      name: "Sedum telephium 'Munstead Dark Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D2LPE28R;EXB87PY3;ZNCN178S;CNA2Y39B;ZN5BT184;KBZZNLHX;ZZER1VD7;BKR37S5T',
      statusContent: 'dot',
      hash: '7J1VL37P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7J1VL37P'
    },
    {
      status: 210,
      sku: 'PM0085759',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386195',
      rtl_batch_array: '6386195',
      name_search: 'SECSENAW',
      name: "Senecio candicans 'Senaw'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 2.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GE6ZDD11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GE6ZDD11'
    },
    {
      status: 210,
      sku: 'PM0005096',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350417',
      rtl_batch_array: '6350417, 6217174, 6308592',
      name_search: 'HEMARMAL',
      name: "Heuchera 'Marmalade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2153,
      chnn_stock_retail: 3323,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2E3D4BNL;BB94WBTB;28RKG91V;ETBZED7N;ZCV4GTHV;CNR8E1DR;4Y62BWT1;CC54DSKH;CYC5NB6R;B66536PT;C4V31773;WAPLRVCD;JHPNWADY;5NSV9J8R;B3PC9KWK',
      statusContent: 'dot',
      hash: '9EG1CRCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EG1CRCJ'
    },
    {
      status: 210,
      sku: 'PM0085760',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386196',
      rtl_batch_array: '6386196',
      name_search: 'SIPERFOL',
      name: 'Silphium perfoliatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_order_minimum: 3,
      sppl_prcp: 1.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2DVCGLT;K6214W5R',
      statusContent: 'dot',
      hash: 'T8JLAA49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8JLAA49'
    },
    {
      status: 210,
      sku: 'PM0085761',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386199',
      rtl_batch_array: '6386199',
      name_search: 'STPALUST',
      name: 'Stachys palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZ8CKVRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ8CKVRN'
    },
    {
      status: 210,
      sku: 'PM0085762',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386200',
      rtl_batch_array: '6386200',
      name_search: 'STRECTA',
      name: 'Stachys recta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1DE3LV9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DE3LV9Y'
    },
    {
      status: 210,
      sku: 'PM0085763',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386201',
      rtl_batch_array: '6386201',
      name_search: 'BEOSSWEE',
      name: "Betonica off. 'Summer Sweets'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PA1BHPD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA1BHPD6'
    },
    {
      status: 210,
      sku: 'PM0085764',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386203',
      rtl_batch_array: '6386203',
      name_search: 'TICRTIGE',
      name: "Tiarella cordifolia 'Running Tiger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '28722T59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28722T59'
    },
    {
      status: 810,
      sku: 'PM0085765',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386205',
      rtl_batch_array: '6386205',
      name_search: 'TRETNA',
      name: "Trollius 'Etna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEG3V1GT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEG3V1GT'
    },
    {
      status: 210,
      sku: 'PM0021555',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6321910',
      rtl_batch_array: '6349069, 5582433, 6321910',
      name_search: 'DIPURPUR',
      name: 'Digitalis purpurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 717,
      chnn_stock_retail: 8870,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1XYAY1SZ',
      statusContent: 'dot',
      hash: '2T79TJA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T79TJA1'
    },
    {
      status: 210,
      sku: 'PM0082306',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349989',
      rtl_batch_array: '6349989',
      name_search: 'DICHEART',
      name: "Dicentra 'Candy Hearts'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2D6Z6EE5;PN5JLBYE',
      statusContent: 'dot',
      hash: 'H5D4EACR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5D4EACR'
    },
    {
      status: 210,
      sku: 'PM0071759',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6233434',
      rtl_batch_array: '6233434',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'P3G6VV2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3G6VV2A'
    },
    {
      status: 210,
      sku: 'PM0085766',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386208',
      rtl_batch_array: '6386208',
      name_search: 'TUVIOLAC',
      name: 'Tulbaghia violacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7A8X4BB3;574HWW78;NY2XERWD;SP5YWY94;PXGC3JRX;WDR86CAG;23H6959R;X7J5HSCZ;VRTW6C4S',
      statusContent: 'dot',
      hash: 'X8N6T5AG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8N6T5AG'
    },
    {
      status: 810,
      sku: 'PM0085767',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386209',
      rtl_batch_array: '6386209',
      name_search: 'TYGIGANT',
      name: 'Sauromatum giganteum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 3.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EX94BCWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX94BCWV'
    },
    {
      status: 210,
      sku: 'PM0085768',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386211',
      rtl_batch_array: '6386211',
      name_search: 'VEROFFIC',
      name: 'Veronica officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2LVKLALX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LVKLALX'
    },
    {
      status: 810,
      sku: 'PM0085769',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386213',
      rtl_batch_array: '6386213',
      name_search: 'YUBACCAT',
      name: 'Yucca baccata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WW68CW3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW68CW3B'
    },
    {
      status: 210,
      sku: 'PM0072025',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6250000',
      rtl_batch_array: '6250000',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 1406,
      chnn_stock_retail: 1406,
      sppl_prcp: 19.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'J75WSENJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J75WSENJ'
    },
    {
      status: 210,
      sku: 'PM0072019',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243368',
      rtl_batch_array: '6243368',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 1550,
      chnn_stock_retail: 1550,
      sppl_prcp: 11.814,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'LR8NVY2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LR8NVY2P'
    },
    {
      status: 810,
      sku: 'PM0071587',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350334',
      rtl_batch_array: '6350334, 6364220',
      name_search: 'HEFWFLIS',
      name: "Helleborus foetidus 'Wester Flisk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 23,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RG7V1J2V',
      statusContent: 'dot',
      hash: 'V7K86NK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7K86NK4'
    },
    {
      status: 210,
      sku: 'PM0072015',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280173',
      rtl_batch_array: '6280173',
      name_search: 'TABREPAN',
      name: "Taxus baccata 'Repandens'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 4668,
      chnn_stock_retail: 4668,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '43L48WA5;RZ7W67KK;SKTPY2R1;T9799XAC;REHZ57Z8;JGK4PTX4;4E58PEN2;6WVK1D3A;N3C2RV34;C2W3JNX3;184LH1TA;LKHNSLNC',
      statusContent: 'dot',
      hash: 'XY6PZLJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XY6PZLJS'
    },
    {
      status: 210,
      sku: 'PM0072016',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280174',
      rtl_batch_array: '6280174',
      name_search: 'TABSUMME',
      name: "Taxus baccata 'Summergold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1351,
      chnn_stock_retail: 1351,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KSV5SYC;J3ATLRXA;85VREB5P;1B7X7CVY;7XH9WWVH;N261GH3S',
      statusContent: 'dot',
      hash: 'PR6YAEYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR6YAEYZ'
    },
    {
      status: 210,
      sku: 'PM0006699',
      core_name: 'Plant',
      sppl_ean: '8720664695740',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420650',
      rtl_batch_array: '5420650',
      name_search: 'JUCSUECI',
      name: "Juniperus communis 'Suecica'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.992,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2Z2PRR9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Z2PRR9P'
    },
    {
      status: 210,
      sku: 'PM0059434',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6291312',
      rtl_batch_array: '6299842, 6271321, 6291312',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 1484,
      sppl_prcp: 1.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'BW71E9RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BW71E9RT'
    },
    {
      status: 910,
      sku: 'PM0066472',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'JGE4Y166',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JGE4Y166'
    },
    {
      status: 910,
      sku: 'PM0005941',
      core_name: 'Plant',
      sppl_ean: '8720626345492',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '5602662',
      rtl_batch_array: '6350142, 5602662, 6302503, 6308566',
      name_search: 'ERZBBLUE',
      name: "Eryngium zabelii 'Big Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3,
      chnn_stock_retail: 2811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8NGZ12GN;DRW5ZYRD;ZCBL9HE2',
      statusContent: 'dot',
      hash: 'J7YS8464',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7YS8464'
    },
    {
      status: 210,
      sku: 'PM0071436',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276629',
      rtl_batch_array: '6234382, 6276629',
      name_search: 'MISINENS',
      name: 'Miscanthus sinensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2640,
      chnn_stock_retail: 3234,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NEG1CAST;W8P249C2;8RPAYC3S;19EKBP98;XGE8EG6E;YC6S6Y67;P815BJA7;L1Y4897A;YP2L9ESZ;VVE7ADHZ;8L1SESSZ;67YTNS9G;B7HA614W',
      statusContent: 'dot',
      hash: 'YBL6C6BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBL6C6BY'
    },
    {
      status: 210,
      sku: 'PM0072017',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243365',
      rtl_batch_array: '6243365',
      name_search: 'TAMDENSI',
      name: "Taxus media 'Densiformis'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 7909,
      chnn_stock_retail: 7909,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRZGD2CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRZGD2CD'
    },
    {
      status: 210,
      sku: 'PM0071226',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228451',
      rtl_batch_array: '6228451, 6336195',
      name_search: 'ILMBANGE',
      name: "Ilex meserveae 'Blue Angel'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 422,
      chnn_stock_retail: 869,
      sppl_prcp: 2.949,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18SDPRET',
      statusContent: 'dot',
      hash: 'D7XRRDHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7XRRDHZ'
    },
    {
      status: 210,
      sku: 'PM0064424',
      core_name: 'Plant',
      sppl_ean: '8720664862111',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6163776',
      rtl_batch_array: '6163776, 6258738',
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 1629,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW2EVKB5;SP7Y5ZWK;SX1DDW1R;JCCEY4LT;A3VB9EVA;HY6AJV5K;CGP52GK8;YKBYTZKC;C9PLV17X;8YNL22WX;Z2CG7A8J;RJD8YAXS;A9KC643C;5V2J3N1E;5AGVBE54;D21HDP9S',
      statusContent: 'dot',
      hash: '1786R7VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1786R7VD'
    },
    {
      status: 210,
      sku: 'PM0005911',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6260733',
      rtl_batch_array: '6311161, 6260733, 6304746, 6271629',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4623,
      chnn_stock_retail: 30622,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'JKT2EZAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKT2EZAC'
    },
    {
      status: 210,
      sku: 'PM0072020',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243369',
      rtl_batch_array: '6243369',
      name_search: 'THODANIC',
      name: "Thuja occidentalis 'Danica'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 5686,
      chnn_stock_retail: 5686,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8BKY3T6;XJHVS4RW;X1HJK86R;ALA6PH9S;RNK6445D;P4VJXZ64;CLH1AKYW;ND2ZDWH4;D2CKJAEB;P29431BK;CLPLYN3C;8XDHEV62;9RJ5XTBB;A1BBTE4T',
      statusContent: 'dot',
      hash: 'YKDYS5P1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKDYS5P1'
    },
    {
      status: 910,
      sku: 'PM0066468',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'EER83XNT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EER83XNT'
    },
    {
      status: 210,
      sku: 'PM0008619',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 3,
      btch_active_retail: '6252510',
      rtl_batch_array: '2624738, 6252510, 6336096',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 899,
      chnn_stock_retail: 6035,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      statusContent: 'dot',
      hash: 'H8842BE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8842BE7'
    },
    {
      status: 810,
      sku: 'PM0050822',
      core_name: 'Plant',
      sppl_ean: '8720353008196',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 4,
      btch_active_retail: '5582256',
      rtl_batch_array: '6311919, 5582256, 6370808, 6259045',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 3250,
      sppl_prcp: 3.928,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'S5EPYV47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5EPYV47'
    },
    {
      status: 210,
      sku: 'PM0074079',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267690',
      rtl_batch_array: '6267690, 6278310',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 150,
      sppl_prcp: 3.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: '46497K8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46497K8P'
    },
    {
      status: 210,
      sku: 'PM0071884',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242142',
      rtl_batch_array: '6242142',
      name_search: 'FRAOSTAR',
      name: "Fragaria ananassa 'Ostara'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 909,
      chnn_stock_retail: 909,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TLR44V86;YT25REKG;Z6TSDCA8;BTNRS3GE;K99D3PRH',
      statusContent: 'dot',
      hash: 'YCZKVK9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCZKVK9G'
    },
    {
      status: 810,
      sku: 'PM0071885',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242148',
      rtl_batch_array: '6242148, 6301346, 6363191',
      name_search: 'FUMGRACI',
      name: 'Fuchsia magellanica gracilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 875,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKR9CWL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKR9CWL5'
    },
    {
      status: 210,
      sku: 'PM0071888',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242154',
      rtl_batch_array: '6242154',
      name_search: 'HEAMOR',
      name: "Helleborus 'Amor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 908,
      chnn_stock_retail: 908,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '49RLNSCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49RLNSCZ'
    },
    {
      status: 210,
      sku: 'PM0071889',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242156',
      rtl_batch_array: '6242156',
      name_search: 'HEBPLUSH',
      name: "Hemerocallis 'Black Plush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 387,
      chnn_stock_retail: 387,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TH798A38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH798A38'
    },
    {
      status: 210,
      sku: 'PM0047546',
      core_name: 'Plant',
      sppl_ean: '8720664887367',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079254',
      rtl_batch_array: '6079254',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.784,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'T3N15SE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3N15SE2'
    },
    {
      status: 210,
      sku: 'PM0071886',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242151',
      rtl_batch_array: '6242151',
      name_search: 'GENCCOUD',
      name: "Geranium nodosum 'Clos du Coudray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 691,
      chnn_stock_retail: 691,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZCS7BHRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCS7BHRZ'
    },
    {
      status: 210,
      sku: 'PM0071893',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242170',
      rtl_batch_array: '6242170',
      name_search: 'MOACORDO',
      name: "Molinia arundinacea 'Cordoba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 520,
      chnn_stock_retail: 520,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EXED78D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXED78D7'
    },
    {
      status: 210,
      sku: 'PM0071887',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242153',
      rtl_batch_array: '6242153',
      name_search: 'HERARMY',
      name: "Helenium 'Red Army'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 322,
      chnn_stock_retail: 322,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '77ZX22WX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77ZX22WX'
    },
    {
      status: 210,
      sku: 'PM0071588',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305151',
      rtl_batch_array: '6350346, 6305151',
      name_search: 'HEMCCAND',
      name: "Hemerocallis 'Chocolate Candy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 115,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NCT2LGYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCT2LGYH'
    },
    {
      status: 210,
      sku: 'PM0072012',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243360',
      rtl_batch_array: '6243360',
      name_search: 'TABBTOWE',
      name: "Taxus baccata 'Black Tower'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E8YEJYZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8YEJYZX'
    },
    {
      status: 810,
      sku: 'PM0071589',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350349',
      rtl_batch_array: '6350349',
      name_search: 'HEDICICL',
      name: "Hemerocallis 'Desert Icicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ND73W5K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND73W5K6'
    },
    {
      status: 210,
      sku: 'PM0072018',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243366',
      rtl_batch_array: '6243366',
      name_search: 'TAMGROEN',
      name: "Taxus media 'Groenland'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GDXHH5SN;61DEPLVH;AY9THTDK;H7L6DT1P;W34X7N4E',
      statusContent: 'dot',
      hash: '1CA7DJDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CA7DJDL'
    },
    {
      status: 210,
      sku: 'PM0072021',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243370',
      rtl_batch_array: '6243370',
      name_search: 'THOHOLMS',
      name: "Thuja occidentalis 'Holmstrup'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 665,
      chnn_stock_retail: 665,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EAEJ5B1W;CAYBZVRJ;V6KAA8ZL;5EJBPPDR;6AX7SCLH;R8GHXD4W;W1PYZBGY;TPVBJTCD;EN1VBWS9;78ZD4G6G;JH8WC5N8',
      statusContent: 'dot',
      hash: '1G1S6D28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G1S6D28'
    },
    {
      status: 910,
      sku: 'PM0072022',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THOKOBOL',
      name: "Thuja occidentalis 'Kobold'",
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      statusContent: 'dot',
      hash: 'GAY8HVSP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GAY8HVSP'
    },
    {
      status: 210,
      sku: 'PM0072024',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243373',
      rtl_batch_array: '6243373',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 6325,
      chnn_stock_retail: 6325,
      sppl_prcp: 8.391,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '6CT6YAST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CT6YAST'
    },
    {
      status: 210,
      sku: 'PM0072026',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243375',
      rtl_batch_array: '6243375',
      name_search: 'THOYRIBB',
      name: "Thuja occidentalis 'Yellow Ribbon'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 724,
      chnn_stock_retail: 724,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC38PVT2',
      statusContent: 'dot',
      hash: '21TZ62R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21TZ62R3'
    },
    {
      status: 210,
      sku: 'PM0071590',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350352',
      rtl_batch_array: '6350352',
      name_search: 'HEFREFLE',
      name: "Hemerocallis 'Fragrant Reflection'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E3P9SZW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3P9SZW6'
    },
    {
      status: 210,
      sku: 'PM0073902',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266138',
      rtl_batch_array: '6266138',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2560,
      chnn_stock_retail: 2560,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'JH8NHW9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH8NHW9S'
    },
    {
      status: 810,
      sku: 'PM0073951',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266272',
      rtl_batch_array: '6266272',
      name_search: 'CIGPINK',
      name: "Cistus 'Grayswood Pink'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V7RDL22C;X4WS1RJW;9B1TRHD6;6XLV9RBL;WGT2LHGV;LR259CT5;SPN9LKP5;Y8PD5JBC',
      statusContent: 'dot',
      hash: 'PH8ZP7WZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH8ZP7WZ'
    },
    {
      status: 210,
      sku: 'PM0071591',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350360',
      rtl_batch_array: '6350360',
      name_search: 'HEOON',
      name: "Hemerocallis 'On and On'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 327,
      chnn_stock_retail: 327,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TGLGGE7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGLGGE7S'
    },
    {
      status: 210,
      sku: 'PM0071597',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308603',
      rtl_batch_array: '6350570, 6308603',
      name_search: 'IRCBFORM',
      name: "Iris chrysographes 'Black Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1245,
      chnn_stock_retail: 1632,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PKD7HJEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKD7HJEA'
    },
    {
      status: 210,
      sku: 'PM0071818',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353279',
      rtl_batch_array: '6353279',
      name_search: 'ROPCWING',
      name: "Rodgersia pinnata 'Chocolate Wings'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7EHPWTA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7EHPWTA6'
    },
    {
      status: 810,
      sku: 'PM0071892',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242168',
      rtl_batch_array: '6242168',
      name_search: 'LIBFORMO',
      name: 'Libertia formosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V68YEZDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V68YEZDZ'
    },
    {
      status: 210,
      sku: 'PM0067550',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192857',
      rtl_batch_array: '6192857',
      name_search: 'RHCONFEC',
      name: 'Rhodohypoxis confecta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H54BWPR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H54BWPR6'
    },
    {
      status: 210,
      sku: 'PM0032329',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350705',
      rtl_batch_array: '6350705',
      name_search: 'NAGBELLS',
      name: "Narcissus Golden Bell's (BU)",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D1KY2G6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1KY2G6X'
    },
    {
      status: 810,
      sku: 'PM0071435',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254763',
      rtl_batch_array: '6254763',
      name_search: 'KNPPOPSI',
      name: "Kniphofia 'Papaya Popsicle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 33,
      chnn_stock_retail: 33,
      sppl_prcp: 2.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZ9W4C26',
      statusContent: 'dot',
      hash: '17A223N1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17A223N1'
    },
    {
      status: 210,
      sku: 'PM0079749',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330086',
      rtl_batch_array: '6330086',
      name_search: 'SAVFLAIR',
      name: "Salvia 'Vanity Flair'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T2T1RL8S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2T1RL8S'
    },
    {
      status: 210,
      sku: 'PM0079750',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330087',
      rtl_batch_array: '6330087',
      name_search: 'SAPPINK',
      name: "Salvia 'Pretty in Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4KVXESTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KVXESTB'
    },
    {
      status: 210,
      sku: 'PM0072011',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243359',
      rtl_batch_array: '6243359',
      name_search: 'PISMINIM',
      name: "Pinus strobus 'Minima'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 997,
      chnn_stock_retail: 997,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VDBE92E8;ANSY216Y;DLPN6BKK;K9W67RVK;LPV6W5XC;6K9LV8SG',
      statusContent: 'dot',
      hash: '5K6PG8K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K6PG8K5'
    },
    {
      status: 210,
      sku: 'PM0079751',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330088',
      rtl_batch_array: '6330088',
      name_search: 'SARCDIST',
      name: "Salvia 'Royal Crimson Distinction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JKP8KSNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKP8KSNC'
    },
    {
      status: 210,
      sku: 'PM0072013',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243361',
      rtl_batch_array: '6243361',
      name_search: 'TABFASTI',
      name: "Taxus baccata 'Fastigiata'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HCBVKWW7;XC3K6R5W',
      statusContent: 'dot',
      hash: 'P26831AC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P26831AC'
    },
    {
      status: 210,
      sku: 'PM0079752',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330089',
      rtl_batch_array: '6330089',
      name_search: 'SASVWHIT',
      name: "Salvia sclarea 'Vatican White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 609,
      chnn_stock_retail: 609,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPBZVX1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPBZVX1D'
    },
    {
      status: 210,
      sku: 'PM0079753',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6383375',
      rtl_batch_array: '6330090, 6383375, 6340045',
      name_search: 'SAULIGIN',
      name: 'Salvia uliginosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1018,
      chnn_stock_retail: 2690,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E14AHAS2;96A69AGP;YCAEW9LR;TRGD9CGZ;Y2GVNPH3',
      statusContent: 'dot',
      hash: 'NE7J429D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NE7J429D'
    },
    {
      status: 210,
      sku: 'PM0067547',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315066',
      rtl_batch_array: '6315066, 6353257',
      name_search: 'PUVULGAR',
      name: 'Pulsatilla vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3299,
      chnn_stock_retail: 5233,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KBYL8V2Y;BLPE7NPA;2N8ESW52;1WXXANC2;6ADK16HX;BVSD5557;B4Z2NY98;TVE1TW8K;JRR8W1ND;WY9DDK8H;46W9VZT2;GKKA9WY9;KDEJ4V9X;H8Y1NZ1L;9ZNP1PJG;86X22E89;L146XRGE;L9932J7E;T77SHYW3;XB8NEA9P;2DZ6TVRX;WSDSS2EK;KH6PS9KX;64L7WN9L;41G2CXXS',
      statusContent: 'dot',
      hash: 'BA3ZEBP3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA3ZEBP3'
    },
    {
      status: 210,
      sku: 'PM0079754',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330091',
      rtl_batch_array: '6330091',
      name_search: 'SAVELOVE',
      name: "Salvia verticillata 'Endless Love'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 864,
      chnn_stock_retail: 864,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8JTZSRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8JTZSRT'
    },
    {
      status: 210,
      sku: 'PM0079755',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330092',
      rtl_batch_array: '6330092',
      name_search: 'SACTIP',
      name: "Sanguisorba 'Chocolate Tip'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 281,
      chnn_stock_retail: 281,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZSDSLEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZSDSLEJ'
    },
    {
      status: 210,
      sku: 'PM0074300',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269490',
      rtl_batch_array: '6269490, 6282938, 6310268',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 5824,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'XHZT1CB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHZT1CB2'
    },
    {
      status: 810,
      sku: 'PM0071594',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305168',
      rtl_batch_array: '6324738, 6305168',
      name_search: 'HOFFROST',
      name: "Hosta 'First Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SNSXJ1Y;S59A7P7L;V7NSJDC1;P4HB52YX',
      statusContent: 'dot',
      hash: '94W7HJCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94W7HJCL'
    },
    {
      status: 210,
      sku: 'PM0049989',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244078',
      rtl_batch_array: '6244078',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: '7BEKCHH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BEKCHH1'
    },
    {
      status: 210,
      sku: 'PM0071883',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242141',
      rtl_batch_array: '6242141',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 146,
      chnn_stock_retail: 146,
      sppl_order_minimum: 3,
      sppl_prcp: 2.169,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: 'YP3EH2AY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YP3EH2AY'
    },
    {
      status: 810,
      sku: 'PM0071592',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349873',
      rtl_batch_array: '6349873',
      name_search: 'HIRFLEMI',
      name: "Hibiscus 'Robert Fleming'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5NASYAN',
      statusContent: 'dot',
      hash: 'DR4WTL8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR4WTL8E'
    },
    {
      status: 210,
      sku: 'PM0072023',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243372',
      rtl_batch_array: '6243372',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '080100C4',
      rng_range_identifier: 'H080100C4',
      sppl_stock_available: 5025,
      chnn_stock_retail: 5025,
      sppl_prcp: 4.318,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'GJW6D4XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJW6D4XA'
    },
    {
      status: 810,
      sku: 'PM0052746',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6218657',
      rtl_batch_array: '6218657, 6258768',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 28,
      chnn_stock_retail: 2270,
      sppl_prcp: 2.446,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'PS5ZXED5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PS5ZXED5'
    },
    {
      status: 210,
      sku: 'PM0079756',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330093',
      rtl_batch_array: '6330093',
      name_search: 'SAHLSQUI',
      name: "Sanguisorba hakusanensis 'Lilac Squirrel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHTRDCAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHTRDCAG'
    },
    {
      status: 210,
      sku: 'PM0068915',
      core_name: 'Plant',
      sppl_ean: '8720664689343',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5925267',
      rtl_batch_array: '5925267',
      name_search: 'EUJSUSAN',
      name: "Euonymus japonicus 'Susan'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YRYJCSDB;G2ENXP6V;582DNDNN;XNBTS5Y9;SKVS2VKD;S5K81W5C;6J6DNDEH;1DXEJT81;BKKNA9NR;XHP9R2WC;H1S8WV1E;61TL7CXY;RSADHCJP',
      statusContent: 'dot',
      hash: '75P45C8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75P45C8T'
    },
    {
      status: 210,
      sku: 'PM0074303',
      core_name: 'Plant',
      sppl_ean: '8720349416028',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837424',
      rtl_batch_array: '5837424',
      name_search: 'FUDAVID',
      name: "Fuchsia 'David'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HNHH4YK',
      statusContent: 'dot',
      hash: '1ZZ5AT9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZZ5AT9K'
    },
    {
      status: 910,
      sku: 'PM0079757',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAFBTSUK',
      name: "Saxifraga fortunei 'Beni-tsukasa'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'XKX99YD5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XKX99YD5'
    },
    {
      status: 210,
      sku: 'PM0068459',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253095',
      rtl_batch_array: '6253095',
      name_search: 'HEALBICA',
      name: 'Hebe albicans',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 251,
      chnn_stock_retail: 251,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TZS7YL6H;BEEEXX1B;V8SD7NR9;SWDSA3HL;RLPH2D7R;JB39NE6N;KVVE9GTP;XYN52PN4;XCDS8YDC;7YXAY939;YDZHXEC8;BHXLV5W7;4VBJ8VG8;NT3B5V7P;9CB28JNA',
      statusContent: 'dot',
      hash: 'XZ2XX93N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZ2XX93N'
    },
    {
      status: 810,
      sku: 'PM0071928',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198288',
      rtl_batch_array: '6198288',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.062,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: '56Y5879S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56Y5879S'
    },
    {
      status: 210,
      sku: 'PM0008865',
      core_name: 'Plant',
      sppl_ean: '8720349411801',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2624739',
      rtl_batch_array: '2624739',
      name_search: 'CACFCURL',
      name: "Carex comans 'Frosted Curls'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 391,
      chnn_stock_retail: 391,
      sppl_prcp: 2.119,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XWKKGR1V;WBG361VY;99GYTLL2;SX6AA7AB;TAT6WW2B;4W5AP4D3;5XED8LE6;TV536JZ1;W7KPK1J9;7971T56V;BD5VP1GT;CACEEJZK;DXGX5LHS;TZWJXXTJ;CTT6XEBW;TXCN2PBC;ZSNPT7LE;YLWRR1D7;HA74WY1T;423BPXP4;8Y43J9RJ;5XD6K3KB;7CY4KK8R',
      imageBatch: 'TWGTG5J1',
      statusContent: 'dot',
      hash: '2J3SXSGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2J3SXSGB'
    },
    {
      status: 210,
      sku: 'PM0018822',
      core_name: 'Plant',
      sppl_ean: '8720349413942',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2718184',
      rtl_batch_array: '2718184',
      name_search: 'COSMINIG',
      name: 'Cortaderia selloana Minigoldenpampas',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '4421VGJ1',
      statusContent: 'dot',
      hash: 'RTNLAPCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTNLAPCN'
    },
    {
      status: 910,
      sku: 'PM0071212',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYAHCHOC',
      name: 'Hydrangea aspera Hot Chocolate',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore:
        'JLDGE29Y;WZ2ZKT2V;JCXVD74W;ARS5J1ZG;7372G3ZG;HYRP6RCL;D9NXVDZA',
      statusContent: 'dot',
      hash: 'XYDTPS61',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XYDTPS61'
    },
    {
      status: 210,
      sku: 'PM0070994',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336168',
      rtl_batch_array: '6336168',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: 'N3CGSJ5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3CGSJ5D'
    },
    {
      status: 210,
      sku: 'PM0067211',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177933',
      rtl_batch_array: '6177933',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 1382,
      chnn_stock_retail: 1382,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'KK8ZRTWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KK8ZRTWP'
    },
    {
      status: 910,
      sku: 'PM0031749',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRASUNBU',
      name: "Prunus avium 'Sunburst'",
      rng_range_identifier: 'H140160C7.5',
      rng_range_description: 'H140 cm 160 cm C7.5',
      imageCore: 'KZ1AXAR7;E2S7KTBP',
      statusContent: 'dot',
      hash: '54RVH62D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '54RVH62D'
    },
    {
      status: 210,
      sku: 'PM0069044',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702232',
      rtl_batch_array: '4702232',
      name_search: 'PHFCAMIL',
      name: "Photinia fraseri 'Camilvy'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5GEWD2X;Y96SS7T6;H3XSZSRY;5937KDPN;HA13T26N',
      imageBatch: 'R7YJTK3N',
      statusContent: 'dot',
      hash: 'GP3NBALW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GP3NBALW'
    },
    {
      status: 210,
      sku: 'PM0031760',
      core_name: 'Plant',
      sppl_ean: '8720664698116',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5804836',
      rtl_batch_array: '5804836',
      name_search: 'LIMUSCAR',
      name: 'Liriope muscari',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5338,
      chnn_stock_retail: 5338,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8G328LN;HCWXY5YV;J5JSCB5H;SES94664;6AVPE4HA;ZLE4P9CW;YSZYRDV1;488LSVLV',
      imageBatch: '9H2HAJ13',
      statusContent: 'dot',
      hash: 'ZWGWTRDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWGWTRDZ'
    },
    {
      status: 210,
      sku: 'PM0031801',
      core_name: 'Plant',
      sppl_ean: '8720353027289',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054339',
      rtl_batch_array: '6202255, 6054339',
      name_search: 'ALNIGRUM',
      name: 'Allium nigrum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 587,
      chnn_stock_retail: 885,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G2H89JRE;R6ZDH2WV;9G38N8AD;1KSB86KW;CZZDNLTN;1PDNEBX4;5H6K34DB;K5ZXX2C8',
      statusContent: 'dot',
      hash: 'P58DETV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P58DETV3'
    },
    {
      status: 210,
      sku: 'PM0067551',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192859',
      rtl_batch_array: '6192859',
      name_search: 'RHMCLARE',
      name: "Rhodohypoxis milloides 'Claret'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT15P76;REPTSWX1;AXCKVEJN;EKZWGYCT;KEA5N4S9;ACJ24TCB;JH3RCS4E;8HD1XVZ3;G412XRN7;T7XKXVRS;BSA25CCX',
      statusContent: 'dot',
      hash: '794V18W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '794V18W5'
    },
    {
      status: 910,
      sku: 'PM0047457',
      core_name: 'Plant',
      sppl_ean: '8720664518414',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '5944267',
      rtl_batch_array: '5944267',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 1.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'CLA7S5E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CLA7S5E5'
    },
    {
      status: 210,
      sku: 'PM0068377',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6246676',
      rtl_batch_array: '6298723, 6246676',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 2338,
      sppl_prcp: 6.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'Z67WPTW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z67WPTW3'
    },
    {
      status: 210,
      sku: 'PM0071437',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254803',
      rtl_batch_array: '6254803',
      name_search: 'PEORIENT',
      name: 'Pennisetum orientale',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1359,
      chnn_stock_retail: 1359,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BLPH2G4G;TC6J4497',
      statusContent: 'dot',
      hash: 'ET1SKS3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET1SKS3W'
    },
    {
      status: 210,
      sku: 'PM0071438',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244294',
      rtl_batch_array: '6244294, 6254808',
      name_search: 'PEASPECI',
      name: "Persicaria amplexicaulis 'Speciosa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2008,
      chnn_stock_retail: 3916,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9R6TW6H3',
      statusContent: 'dot',
      hash: 'KVRHYS6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVRHYS6X'
    },
    {
      status: 210,
      sku: 'PM0074488',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271322',
      rtl_batch_array: '6271322',
      name_search: 'ABGCONFE',
      name: 'Abelia grandiflora Confetti',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 820,
      chnn_stock_retail: 820,
      sppl_prcp: 2.595,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HNXD9PNH;X65GTL1P;85K8DDLL;X21D296A;CVKRL4EK',
      statusContent: 'dot',
      hash: '64YPEACA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64YPEACA'
    },
    {
      status: 210,
      sku: 'PM0074506',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385813',
      rtl_batch_array: '6271343, 6310200, 6385813',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4867,
      chnn_stock_retail: 7159,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: '2N97B5J9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N97B5J9'
    },
    {
      status: 210,
      sku: 'PM0082307',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349996',
      rtl_batch_array: '6349996',
      name_search: 'DIANTARC',
      name: 'Dicksonia antarctica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T9EJCV2S;1Z3DVHVX',
      statusContent: 'dot',
      hash: '1XRDG2A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XRDG2A5'
    },
    {
      status: 910,
      sku: 'PM0071603',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIMSMORN',
      name: 'Lilium martagon Snowy Morning',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: '27PH9HCR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '27PH9HCR'
    },
    {
      status: 210,
      sku: 'PM0082308',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350003',
      rtl_batch_array: '6350003',
      name_search: 'DOOMEDIA',
      name: 'Doodia media',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HV63K2GY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HV63K2GY'
    },
    {
      status: 210,
      sku: 'PM0082309',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350007',
      rtl_batch_array: '6350007',
      name_search: 'DRACANGU',
      name: "Dryopteris affinis 'Cristata Angustata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EP4ATK6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EP4ATK6E'
    },
    {
      status: 210,
      sku: 'PM0082310',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350008',
      rtl_batch_array: '6350008',
      name_search: 'DRAPDADD',
      name: "Dryopteris affinis 'Polydactyla Dadds'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 373,
      chnn_stock_retail: 373,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXNG8BPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXNG8BPJ'
    },
    {
      status: 210,
      sku: 'PM0082311',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350009',
      rtl_batch_array: '6350009',
      name_search: 'DRCLINTO',
      name: 'Dryopteris clintoniana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCD661N6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCD661N6'
    },
    {
      status: 210,
      sku: 'PM0082312',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350010',
      rtl_batch_array: '6350010',
      name_search: 'DRDLEPID',
      name: "Dryopteris dilatata 'Lepidota'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '67DC7TPT',
      statusContent: 'dot',
      hash: 'Y123ATX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y123ATX5'
    },
    {
      status: 210,
      sku: 'PM0082313',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350012',
      rtl_batch_array: '6350012',
      name_search: 'DRFMFURC',
      name: "Dryopteris filix-mas 'Furcans'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5SVZVPNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SVZVPNL'
    },
    {
      status: 810,
      sku: 'PM0074490',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271324',
      rtl_batch_array: '6271324',
      name_search: 'BUMPEARL',
      name: "Buddleja 'Miss Pearl'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_prcp: 3.381,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8K729RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8K729RZ'
    },
    {
      status: 810,
      sku: 'PM0071598',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350578',
      rtl_batch_array: '6350578',
      name_search: 'IRSGWING',
      name: "Iris sibirica 'Gull's Wing'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5L91CK9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L91CK9X'
    },
    {
      status: 210,
      sku: 'PM0071599',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350579',
      rtl_batch_array: '6350579',
      name_search: 'IRSKABLU',
      name: "Iris sibirica 'Kabluey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 366,
      chnn_stock_retail: 366,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EVN39AEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVN39AEB'
    },
    {
      status: 210,
      sku: 'PM0071604',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350601',
      rtl_batch_array: '6350601',
      name_search: 'KNCCANDY',
      name: "Kniphofia 'Cotton Candy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BA2GGK52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA2GGK52'
    },
    {
      status: 210,
      sku: 'PM0082314',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350013',
      rtl_batch_array: '6350013',
      name_search: 'DRLUDOVI',
      name: 'Dryopteris ludoviciana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRYPKG3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRYPKG3Y'
    },
    {
      status: 210,
      sku: 'PM0071605',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350617',
      rtl_batch_array: '6350617',
      name_search: 'KNFLAMEN',
      name: "Kniphofia 'Flamenco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZ6SH4K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZ6SH4K6'
    },
    {
      status: 210,
      sku: 'PM0071607',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350715',
      rtl_batch_array: '6350715',
      name_search: 'NERACEMO',
      name: 'Nepeta racemosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4KKXBL4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KKXBL4P'
    },
    {
      status: 210,
      sku: 'PM0071897',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353456',
      rtl_batch_array: '6353456',
      name_search: 'SPMLREDH',
      name: "Spigelia maril. 'Little Redhead'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z869KDT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z869KDT5'
    },
    {
      status: 210,
      sku: 'PM0067555',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6230989',
      rtl_batch_array: '6192873, 6230989',
      name_search: 'SAHENRYI',
      name: 'Saruma henryi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 364,
      chnn_stock_retail: 417,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DB5E9HRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB5E9HRT'
    },
    {
      status: 210,
      sku: 'PM0079758',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330097',
      rtl_batch_array: '6330097',
      name_search: 'PUSAMURA',
      name: "Pulmonaria 'Samurai'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 942,
      chnn_stock_retail: 942,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZCLBGN1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCLBGN1K'
    },
    {
      status: 210,
      sku: 'PM0079759',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330098',
      rtl_batch_array: '6330098',
      name_search: 'PYVIRGIN',
      name: 'Pycnanthemum virginicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8JRCNC7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8JRCNC7C'
    },
    {
      status: 210,
      sku: 'PM0079760',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330099',
      rtl_batch_array: '6330099',
      name_search: 'SEALPHA',
      name: "Sempervivum 'Alpha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7KWBYHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7KWBYHJ'
    },
    {
      status: 210,
      sku: 'PM0079761',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330100',
      rtl_batch_array: '6330100',
      name_search: 'SEARACHN',
      name: 'Sempervivum arachnoideum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1622,
      chnn_stock_retail: 1622,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GCRG22CR;DCDP6ZW4;8JSAXJBP;V32WZSEJ;C6PRPWS2;J94YCS6Y;Y468G39L;83NC4S8L;738LH7GB;W63ERY3Y;212RG3AP;YT7DVSTZ;KZ8EYPZB;5PH22A25;JL6EWAAL;PR1KK4WP;DLN6NEJ2;R392XL23;LH9XGGKC;9RNYCYG1;LH3PV6G9;EXL44SPS',
      statusContent: 'dot',
      hash: 'H6KY6G1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6KY6G1E'
    },
    {
      status: 210,
      sku: 'PM0079762',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330102',
      rtl_batch_array: '6330102',
      name_search: 'SEROTKOP',
      name: "Sempervivum 'Rotkopf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2376,
      chnn_stock_retail: 2376,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ANA7B49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ANA7B49'
    },
    {
      status: 210,
      sku: 'PM0079763',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330103',
      rtl_batch_array: '6330103',
      name_search: 'SICANDID',
      name: 'Sidalcea candida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 452,
      chnn_stock_retail: 452,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C7XGY9G;DBYN3YX7',
      statusContent: 'dot',
      hash: 'XV8LETED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV8LETED'
    },
    {
      status: 210,
      sku: 'PM0079764',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330104',
      rtl_batch_array: '6330104',
      name_search: 'SIOBRILL',
      name: "Sidalcea oregana 'Brilliant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HLSA5AHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLSA5AHP'
    },
    {
      status: 910,
      sku: 'PM0079765',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPSWLAKE',
      name: "Spodiopogon sibiricus 'West Lake'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: '9B84APKW',
      statusContent: 'dot',
      hash: '2D1DTYJB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2D1DTYJB'
    },
    {
      status: 810,
      sku: 'PM0079766',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330107',
      rtl_batch_array: '6330107',
      name_search: 'SPHWISCO',
      name: "Sporobolus heterolepis 'Wisconsin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9EAW8WGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EAW8WGB'
    },
    {
      status: 210,
      sku: 'PM0079767',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330109',
      rtl_batch_array: '6330109',
      name_search: 'SCACMBLU',
      name: "Scabiosa columbaria 'Mariposa Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KYTT1NSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYTT1NSY'
    },
    {
      status: 210,
      sku: 'PM0082315',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350014',
      rtl_batch_array: '6350014',
      name_search: 'DRMARGIN',
      name: 'Dryopteris marginalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YPRXC2X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPRXC2X1'
    },
    {
      status: 210,
      sku: 'PM0085770',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386602',
      rtl_batch_array: '6386602',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 0.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: 'AD6CTKZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AD6CTKZ5'
    },
    {
      status: 210,
      sku: 'PM0085771',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386603',
      rtl_batch_array: '6386603',
      name_search: 'HEHGLACI',
      name: "Hedera helix 'Glacier'",
      sppl_size_code: '150175C3',
      rng_range_identifier: 'H150175C3',
      rng_range_description: 'H150 cm 175 cm C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YNKZE78G;ST39J6WN;1AK4BT6L;KJ6P7ESR;743J88XA;EZL4Y25P;ZH8ZXXVK;H45XV8VC',
      statusContent: 'dot',
      hash: '639DXWBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '639DXWBS'
    },
    {
      status: 210,
      sku: 'PM0085772',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386605',
      rtl_batch_array: '6386605',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: '6W4YHWSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W4YHWSP'
    },
    {
      status: 910,
      sku: 'PM0085773',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386607',
      rtl_batch_array: '6386607',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_order_minimum: 3,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'X79T3VJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X79T3VJ4'
    },
    {
      status: 810,
      sku: 'PM0085774',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386608',
      rtl_batch_array: '6386608',
      name_search: 'VIGJGLOB',
      name: "Viburnum globosum 'Jermyns Globe'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 4.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3V7DZEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3V7DZEC'
    },
    {
      status: 210,
      sku: 'PM0085775',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386609',
      rtl_batch_array: '6386609',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'WJNHES5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJNHES5R'
    },
    {
      status: 210,
      sku: 'PM0072029',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295348',
      rtl_batch_array: '6295348',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: 'W1V2HXJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1V2HXJD'
    },
    {
      status: 210,
      sku: 'PM0085776',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386610',
      rtl_batch_array: '6386610',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: 'TD39NAJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TD39NAJZ'
    },
    {
      status: 810,
      sku: 'PM0085777',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386611',
      rtl_batch_array: '6386611',
      name_search: 'COSTPGOL',
      name: 'Cortaderia selloana Tiny Pampa Gold',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3HB5ZAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3HB5ZAG'
    },
    {
      status: 210,
      sku: 'PM0074491',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271326',
      rtl_batch_array: '6271326',
      name_search: 'BUWBALL',
      name: "Buddleja 'White Ball'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1471,
      chnn_stock_retail: 1471,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B1573HE;YH81KX32;5TL2J7GY;9RKLX3GN',
      statusContent: 'dot',
      hash: '82LWJH25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82LWJH25'
    },
    {
      status: 210,
      sku: 'PM0074492',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271327',
      rtl_batch_array: '6367976, 6282356, 6271327',
      name_search: 'BUDLCHIP',
      name: 'Buddleja Lilac Chip',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4861,
      chnn_stock_retail: 5446,
      sppl_prcp: 3.226,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YT54A21C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT54A21C'
    },
    {
      status: 210,
      sku: 'PM0074493',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271328',
      rtl_batch_array: '6271328, 6263138',
      name_search: 'BUPCHIP',
      name: 'Buddleja Purple Chip',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4390,
      chnn_stock_retail: 4413,
      sppl_prcp: 3.226,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHXZV1NH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHXZV1NH'
    },
    {
      status: 210,
      sku: 'PM0074494',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385807',
      rtl_batch_array: '6271329, 6385807',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2692,
      chnn_stock_retail: 4107,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'TBX3V41Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBX3V41Y'
    },
    {
      status: 210,
      sku: 'PM0074495',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271330',
      rtl_batch_array: '6271330',
      name_search: 'BUDFASCI',
      name: "Buddleja davidii 'Fascinating'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 972,
      chnn_stock_retail: 972,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K5KCNZH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5KCNZH6'
    },
    {
      status: 210,
      sku: 'PM0074497',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271333',
      rtl_batch_array: '6271333',
      name_search: 'BUFPEACE',
      name: 'Buddleja Flutterby Peace',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 2.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TK73H8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TK73H8Y'
    },
    {
      status: 210,
      sku: 'PM0074498',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271334',
      rtl_batch_array: '6271334',
      name_search: 'BUGLOBOS',
      name: 'Buddleja globosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7ZRSBJG',
      statusContent: 'dot',
      hash: 'PJP4CHXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJP4CHXG'
    },
    {
      status: 210,
      sku: 'PM0074499',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271335',
      rtl_batch_array: '6271335',
      name_search: 'BUGLOBOS',
      name: 'Buddleja globosa',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 341,
      chnn_stock_retail: 341,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7ZRSBJG',
      statusContent: 'dot',
      hash: 'ET3HP7AJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET3HP7AJ'
    },
    {
      status: 210,
      sku: 'PM0074500',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271336',
      rtl_batch_array: '6271336',
      name_search: 'BUDHARLE',
      name: "Buddleja davidii 'Harlequin'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 725,
      chnn_stock_retail: 725,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6T53N6J8;XCEABYLT;C5PN7T3P;5B9Y41W7;1NX7NCN3',
      statusContent: 'dot',
      hash: 'DPKXA243',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPKXA243'
    },
    {
      status: 210,
      sku: 'PM0074501',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385809',
      rtl_batch_array: '6271337, 6310194, 6385809',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2990,
      chnn_stock_retail: 5156,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'XDPXCP2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDPXCP2E'
    },
    {
      status: 210,
      sku: 'PM0074502',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271338',
      rtl_batch_array: '6271338',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1279,
      chnn_stock_retail: 1279,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: 'JLLBKC52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLLBKC52'
    },
    {
      status: 210,
      sku: 'PM0074504',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271341',
      rtl_batch_array: '6271341',
      name_search: 'BUDSOPHI',
      name: "Buddleja davidii 'Sophie'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1790,
      chnn_stock_retail: 1790,
      sppl_prcp: 2.338,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RTH6Y96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RTH6Y96'
    },
    {
      status: 210,
      sku: 'PM0071177',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 2,
      btch_active_retail: '6228395',
      rtl_batch_array: '6228395, 6306411',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 114,
      chnn_stock_retail: 3114,
      sppl_prcp: 2.603,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '6K89REBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6K89REBJ'
    },
    {
      status: 210,
      sku: 'PM0070020',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 8,
      btch_active_retail: '6300176',
      rtl_batch_array:
        '6220385, 6228404, 6300176, 6267678, 6218667, 6334183, 6355666, 6348741',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 3500,
      chnn_stock_retail: 10709,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'EHSXWJGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHSXWJGE'
    },
    {
      status: 210,
      sku: 'PM0071192',
      core_name: 'Plant',
      btch_manufacturer: 481,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228412',
      rtl_batch_array: '6228412',
      name_search: 'CETUXEDO',
      name: "Ceanothus 'Tuxedo'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 3.444,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3WSTCA9;H1HVSDPJ;SEBNV73X;TSCYBA3E;DX8171HE;SWDG2WRG;ZWY96Y9D;1C1V7BCS;RAT478P3;GRTGD51C;WA3J18GH;SR92R797',
      statusContent: 'dot',
      hash: 'AGWAPGD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGWAPGD7'
    },
    {
      status: 210,
      sku: 'PM0071601',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349874',
      rtl_batch_array: '6349874',
      name_search: 'LIAKNIGH',
      name: "Lilium 'Arabian Knight'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7VEH8L9H',
      statusContent: 'dot',
      hash: 'TDXZBYN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDXZBYN8'
    },
    {
      status: 910,
      sku: 'PM0071602',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LICSHRID',
      name: "Lilium 'Claude Shride'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'HZ7L8NZR;AZ26CJ46',
      statusContent: 'dot',
      hash: 'C8BE5DE2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C8BE5DE2'
    },
    {
      status: 210,
      sku: 'PM0074487',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271320',
      rtl_batch_array: '6271320, 6334173',
      name_search: 'ABEGOUCH',
      name: "Abelia 'Edward Goucher'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 647,
      chnn_stock_retail: 847,
      sppl_prcp: 1.977,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKYDBZ5C;8WNRHDS9;63ABV37E;4C4KSXVN;44S1KAEP',
      statusContent: 'dot',
      hash: '74ZWVCBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74ZWVCBB'
    },
    {
      status: 210,
      sku: 'PM0074489',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271323',
      rtl_batch_array: '6271323',
      name_search: 'ABPBELLS',
      name: 'Abelia Pinky Bells',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2409,
      chnn_stock_retail: 2409,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LWGW1GEL',
      statusContent: 'dot',
      hash: '8ZKLP94K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZKLP94K'
    },
    {
      status: 210,
      sku: 'PM0074503',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271339',
      rtl_batch_array: '6271339',
      name_search: 'BUDOBEAU',
      name: "Buddleja davidii 'Orchid Beauty'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1188,
      chnn_stock_retail: 1188,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RH8V9YHK',
      statusContent: 'dot',
      hash: 'P95NEGT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P95NEGT4'
    },
    {
      status: 210,
      sku: 'PM0074505',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271342',
      rtl_batch_array: '6271342',
      name_search: 'BUDSBEAU',
      name: "Buddleja davidii 'Summer Beauty'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1065,
      chnn_stock_retail: 1065,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZTBZS838;BHNVCBJB;19L7G718;J517KW4X;APGYZVHR;H7C5EKWT;7W25629A;CH1G55BT;1CR62C6S;PD7KCVGZ;C84WRYC2;9H337DLB;TH6LTKKG',
      statusContent: 'dot',
      hash: 'RSZ3SYT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSZ3SYT5'
    },
    {
      status: 210,
      sku: 'PM0074496',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271332',
      rtl_batch_array: '6271332',
      name_search: 'BUFLAVEN',
      name: 'Buddleja Flutterby Lavender',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 779,
      chnn_stock_retail: 779,
      sppl_prcp: 2.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ES7PDARY;8JKHT8PT',
      statusContent: 'dot',
      hash: 'JCTW4RWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCTW4RWG'
    },
    {
      status: 810,
      sku: 'PM0017257',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301890',
      rtl_batch_array: '6301890, 6353520',
      name_search: 'TRHALBA',
      name: "Tricyrtis hirta 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 1932,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1VL8VK93;T1XLZ8ZK',
      statusContent: 'dot',
      hash: '1WENS163',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WENS163'
    },
    {
      status: 810,
      sku: 'PM0025559',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353530',
      rtl_batch_array: '6353530',
      name_search: 'TRLSUPRE',
      name: "Trollius 'Lemon Supreme'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '54WCPDYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54WCPDYX'
    },
    {
      status: 210,
      sku: 'PM0067320',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6181326',
      rtl_batch_array: '6181326',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'SE2G4JG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SE2G4JG3'
    },
    {
      status: 910,
      sku: 'PM0033881',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246563',
      rtl_batch_array: '6246563',
      name_search: 'SAAMANTE',
      name: "Salvia 'Amante'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VK7HXSKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VK7HXSKC'
    },
    {
      status: 210,
      sku: 'PM0074507',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271344',
      rtl_batch_array: '6271344',
      name_search: 'BUDFPLFL',
      name: "Buddleja davidii 'Free Petite\u00ae Lavender Flow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1407,
      chnn_stock_retail: 1407,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '29KVTCWB;CDBY8LEZ',
      statusContent: 'dot',
      hash: '8ZEY62VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZEY62VB'
    },
    {
      status: 210,
      sku: 'PM0074515',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271353',
      rtl_batch_array: '6271353',
      name_search: 'CEBSAPPH',
      name: "Ceanothus 'Blue Sapphire'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 5671,
      chnn_stock_retail: 5671,
      sppl_prcp: 2.235,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TBRXXBNK;7GRB9ND8;TLV39CYC;RXKW117W;ELCHGLSK;23G9XXPG;DTR1AX8E;742P6YJH;CE6NVPRH',
      statusContent: 'dot',
      hash: 'VNL28YJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNL28YJ2'
    },
    {
      status: 210,
      sku: 'PM0009963',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253100',
      rtl_batch_array: '6253100',
      name_search: 'HEKIRKII',
      name: "Hebe 'Kirkii'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 575,
      chnn_stock_retail: 575,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X34T4LRR',
      statusContent: 'dot',
      hash: 'W1WBT9WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1WBT9WR'
    },
    {
      status: 210,
      sku: 'PM0082316',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370347',
      rtl_batch_array: '6370347, 6350113',
      name_search: 'ECSAGLOW',
      name: "Echinops sphaerocephalus 'Arctic Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 1420,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRHRL8GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRHRL8GL'
    },
    {
      status: 210,
      sku: 'PM0011545',
      core_name: 'Plant',
      sppl_ean: '8720353091082',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054522',
      rtl_batch_array: '6054522, 6217019',
      name_search: 'CARFALLS',
      name: 'Carex Ribbon Falls',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 522,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1AGT6454;EBRZ94TE;D1JKT933;BHBLYR23;PNVB286B;J5XWYE65;9LVK19AC;89Y7DPVH;2HKGHD6L;YKELS7XA;S8KK36Y9;RXS2TWY6;P1N41PP6;7VJXERXR;79VXNCWR;ES3GG9YY;RA1GP1K1;K523XY2D;66DHTSBS;LTYABY8P;CVETVTEK;8ZJ2KYE5;KEG2T3CR;Y1AGPGZY;6V9PHSX9',
      statusContent: 'dot',
      hash: 'ZBJ697V6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBJ697V6'
    },
    {
      status: 210,
      sku: 'PM0082317',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350114',
      rtl_batch_array: '6350114',
      name_search: 'ECAMOENU',
      name: 'Echium amoenum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P5GRVVPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5GRVVPY'
    },
    {
      status: 210,
      sku: 'PM0074580',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271119',
      rtl_batch_array: '6271119',
      name_search: 'GIBILOBA',
      name: 'Ginkgo biloba',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 719,
      chnn_stock_retail: 719,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WY8TE552;DT2176J1;X7YVRRRC;ZA4NZ32C;CKEXKRAR;RLNHRA6B;XL1JAJ4D;DEPR9L6D;BWA6VT6S;1RDZ1KTZ;SJHWZYBP;HR2WJ79N;ZT59WZ7D;87HX4H3S',
      statusContent: 'dot',
      hash: 'SP9ZYD2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP9ZYD2Z'
    },
    {
      status: 210,
      sku: 'PM0082318',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348313',
      rtl_batch_array: '6350119, 6348313',
      name_search: 'EPAQUEEN',
      name: "Epimedium 'Amber Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1320,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R9KB3JLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9KB3JLK'
    },
    {
      status: 210,
      sku: 'PM0024612',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288313',
      rtl_batch_array: '6288313',
      name_search: 'AQALPINA',
      name: 'Aquilegia alpina',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7009,
      chnn_stock_retail: 7009,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T8YX11RC;7XXGAWAY;JYLD1V2P;E5LSZ7AN;H72CTVXN;JZZNBE4E;DWDTBJE5;54GDKZL8;V7LDPH25;RSA67WNB;DD4SKADS;GPTXPP25;Y2P941C2;4DTDWG59;4GWD7S6Z;611SBX3Y',
      statusContent: 'dot',
      hash: 'EDRPEBL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDRPEBL7'
    },
    {
      status: 910,
      sku: 'PM0082319',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EPGRANDI',
      name: 'Epimedium grandiflorum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: '9B18KRL2',
      statusContent: 'dot',
      hash: '1HD9WJ74',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1HD9WJ74'
    },
    {
      status: 210,
      sku: 'PM0027741',
      core_name: 'Plant',
      sppl_ean: '8720353085739',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5372924',
      rtl_batch_array: '5372924',
      name_search: 'AJRVARIE',
      name: "Ajuga reptans 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1817,
      chnn_stock_retail: 1817,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRTS6ERP;SD5ZXL2Z;XV46DEXK;B5TVJ2RE',
      statusContent: 'dot',
      hash: 'LPJV67KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPJV67KS'
    },
    {
      status: 210,
      sku: 'PM0082320',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350121',
      rtl_batch_array: '6350121',
      name_search: 'EPPNACHF',
      name: "Epimedium perralc. 'Nachfolger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DPJK4Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DPJK4Y3'
    },
    {
      status: 210,
      sku: 'PM0082321',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350122',
      rtl_batch_array: '6350122',
      name_search: 'EPPPINK',
      name: "Epimedium 'Pretty in Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y439NK17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y439NK17'
    },
    {
      status: 210,
      sku: 'PM0082322',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350123',
      rtl_batch_array: '6350123',
      name_search: 'EPRMAXIM',
      name: "Epimedium 'Red Maximum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PGYH65AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGYH65AT'
    },
    {
      status: 210,
      sku: 'PM0082323',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350125',
      rtl_batch_array: '6350125',
      name_search: 'EPSTINGL',
      name: "Epimedium 'Spine Tingler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B28W7NJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B28W7NJS'
    },
    {
      status: 210,
      sku: 'PM0082324',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350126',
      rtl_batch_array: '6350126',
      name_search: 'EPWEWILL',
      name: "Epimedium warleyense 'Ellen Willmott'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A92L2591',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A92L2591'
    },
    {
      status: 810,
      sku: 'PM0082325',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350130',
      rtl_batch_array: '6350130',
      name_search: 'ERDOMINA',
      name: "Erigeron 'Dominator'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2423CCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2423CCX'
    },
    {
      status: 910,
      sku: 'PM0071621',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ROPODOPH',
      name: 'Rodgersia podophylla',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'YDA8KNHT',
      statusContent: 'dot',
      hash: 'V1A8PJGX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V1A8PJGX'
    },
    {
      status: 210,
      sku: 'PM0072772',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207562',
      rtl_batch_array: '5207562',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '020025P11',
      rng_range_identifier: 'H020025P11',
      rng_range_description: 'H20 cm 25 cm P11',
      sppl_stock_available: 20000,
      chnn_stock_retail: 20000,
      sppl_prcp: 1.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      imageBatch: 'JZZRG2ZW',
      statusContent: 'dot',
      hash: 'E5HDPD5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5HDPD5V'
    },
    {
      status: 210,
      sku: 'PM0071611',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284403',
      rtl_batch_array: '6284403',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1276,
      chnn_stock_retail: 1276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: '5BJ59R32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BJ59R32'
    },
    {
      status: 210,
      sku: 'PM0074508',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271345',
      rtl_batch_array: '6271345',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2082,
      chnn_stock_retail: 2082,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5CSRAP5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CSRAP5J'
    },
    {
      status: 210,
      sku: 'PM0071612',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268795',
      rtl_batch_array: '6268795',
      name_search: 'MISNAVAJ',
      name: "Miscanthus sinensis 'Navajo'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '75P879S7;86GKNHKL;D6VEJK6B;2EV6E9XP;5C9HY2GG;BKVLKRCY;B66SLHS1;PYA466B7;7TD4R4BV;TX1S17XX',
      statusContent: 'dot',
      hash: 'SWALLWJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWALLWJL'
    },
    {
      status: 210,
      sku: 'PM0074509',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271346',
      rtl_batch_array: '6271346',
      name_search: 'BUFPBHEA',
      name: 'Buddleja Free Petite Blue Heaven',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1551,
      chnn_stock_retail: 1551,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRGYB1XG;6W23LEGC;AJ55KEY1;CLPSGHX5',
      statusContent: 'dot',
      hash: 'TZ7CJ8ZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZ7CJ8ZB'
    },
    {
      status: 210,
      sku: 'PM0071613',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6230652',
      rtl_batch_array: '6230652',
      name_search: 'MISROSI',
      name: "Miscanthus sinensis 'Rosi'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZVSLYYKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVSLYYKJ'
    },
    {
      status: 210,
      sku: 'PM0071614',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350692',
      rtl_batch_array: '6350692',
      name_search: 'MOABERGF',
      name: "Molinia arundinacea 'Bergfreund'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TK7898A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TK7898A'
    },
    {
      status: 210,
      sku: 'PM0059596',
      core_name: 'Plant',
      sppl_ean: '8720664887862',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6123675',
      rtl_batch_array: '6123675',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 19095,
      chnn_stock_retail: 19095,
      sppl_prcp: 3.281,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'L3D147Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3D147Y3'
    },
    {
      status: 210,
      sku: 'PM0071616',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353082',
      rtl_batch_array: '6353082',
      name_search: 'PENTHUNB',
      name: 'Pennisetum thunbergii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 637,
      chnn_stock_retail: 637,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6GADP1SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GADP1SL'
    },
    {
      status: 210,
      sku: 'PM0071617',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324933',
      rtl_batch_array: '6330022, 6291732, 6324933',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 5042,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'T5RRYXNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5RRYXNN'
    },
    {
      status: 210,
      sku: 'PM0069105',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 2,
      btch_active_retail: '6211590',
      rtl_batch_array: '6211590, 6300725',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1137,
      chnn_stock_retail: 1520,
      sppl_prcp: 3.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'VB8XPG6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VB8XPG6S'
    },
    {
      status: 210,
      sku: 'PM0074510',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271347',
      rtl_batch_array: '6271347',
      name_search: 'BUFPDPIN',
      name: 'Buddleja Flutterby Petite Dark Pink',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3600,
      chnn_stock_retail: 3600,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NRG419H1',
      statusContent: 'dot',
      hash: '8JYXDW57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8JYXDW57'
    },
    {
      status: 910,
      sku: 'PM0025236',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307255',
      rtl_batch_array: '6307255',
      name_search: 'ACOPALUS',
      name: 'Acer opalus',
      sppl_size_code: '175200C35',
      rng_range_identifier: 'H175200C35',
      rng_range_description: 'H175 cm 200 cm C35',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 43.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ET141X86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET141X86'
    },
    {
      status: 210,
      sku: 'PM0072493',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249995',
      rtl_batch_array: '6249995',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '080100C4',
      rng_range_identifier: 'H080100C4',
      sppl_stock_available: 27684,
      chnn_stock_retail: 27684,
      sppl_prcp: 6.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'TW1RHGN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TW1RHGN1'
    },
    {
      status: 210,
      sku: 'PM0074511',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271348',
      rtl_batch_array: '6271348',
      name_search: 'BUFPSWHI',
      name: 'Buddleja Flutterby Petite Snow White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2306,
      chnn_stock_retail: 2306,
      sppl_prcp: 2.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ETAE8Y8',
      statusContent: 'dot',
      hash: 'DVA2D2R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVA2D2R3'
    },
    {
      status: 910,
      sku: 'PM0040051',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'C2WXBY2A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C2WXBY2A'
    },
    {
      status: 210,
      sku: 'PM0067566',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192888',
      rtl_batch_array: '6192888',
      name_search: 'ROOMJUPR',
      name: "Rosmarinus officinalis 'Miss Jessopp's Upright'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH2H6229;JR97TD2N',
      statusContent: 'dot',
      hash: 'GXXNVR1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXXNVR1P'
    },
    {
      status: 210,
      sku: 'PM0067557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192878',
      rtl_batch_array: '6192878',
      name_search: 'RHNLOOK',
      name: "Rhodohypoxis 'New Look'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W4Y7YT5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4Y7YT5K'
    },
    {
      status: 210,
      sku: 'PM0067558',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192879',
      rtl_batch_array: '6192879',
      name_search: 'EDI89281',
      name: "Rhodohypoxis baurii 'Stella'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRVV472H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRVV472H'
    },
    {
      status: 910,
      sku: 'PM0010650',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307256',
      rtl_batch_array: '6307256',
      name_search: 'ACOPALUS',
      name: 'Acer opalus',
      sppl_size_code: '200225C35',
      rng_range_identifier: 'H200225C35',
      rng_range_description: 'H200 cm 225 cm C35',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 53.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZDS38Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZDS38Y3'
    },
    {
      status: 210,
      sku: 'PM0067561',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192882',
      rtl_batch_array: '6192882',
      name_search: 'RHSSPINK',
      name: 'Rhodoxis Summer Stars Pinky',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6KGXA3G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KGXA3G4'
    },
    {
      status: 210,
      sku: 'PM0067560',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192881',
      rtl_batch_array: '6192881',
      name_search: 'RHRGIANT',
      name: "Rhodoxis 'Ruby Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1B135WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1B135WG'
    },
    {
      status: 210,
      sku: 'PM0071619',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353276',
      rtl_batch_array: '6353276',
      name_search: 'ROAWMUEL',
      name: "Rodgersia aesc. 'Werner M\u00fcller'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHBJ2SL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHBJ2SL6'
    },
    {
      status: 210,
      sku: 'PM0071620',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353171',
      rtl_batch_array: '6353171',
      name_search: 'RODANMUT',
      name: "Rodgersia 'Die Anmutige'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRTGRG5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRTGRG5G'
    },
    {
      status: 810,
      sku: 'PM0071622',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353182',
      rtl_batch_array: '6353182',
      name_search: 'ROSROTHA',
      name: "Rodgersia sambucifolia 'Rothaut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EEW5SZKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEW5SZKE'
    },
    {
      status: 210,
      sku: 'PM0067559',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192880',
      rtl_batch_array: '6192880',
      name_search: 'EDI82892',
      name: "Rhodoxis 'Ria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8H2RT85R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H2RT85R'
    },
    {
      status: 210,
      sku: 'PM0074589',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254654',
      rtl_batch_array: '6254654',
      name_search: 'ASMROUGE',
      name: "Astrantia 'Moulin Rouge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1566,
      chnn_stock_retail: 1566,
      sppl_prcp: 2.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NHBPDD7;R78JP5S1',
      statusContent: 'dot',
      hash: '26D7GV2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26D7GV2X'
    },
    {
      status: 210,
      sku: 'PM0065559',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234539',
      rtl_batch_array: '6234539',
      name_search: 'ACPSPARR',
      name: "Aconitum 'Purple Sparrow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ANNTS3BB;CLN5JJV8;EPJEPC5E;RXKNHSB5;5X43APZE',
      statusContent: 'dot',
      hash: 'W9RW9G75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9RW9G75'
    },
    {
      status: 810,
      sku: 'PM0000578',
      core_name: 'Plant',
      sppl_ean: '8720349437115',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5755687',
      rtl_batch_array: '5755687, 5263982',
      name_search: 'OPJAPONI',
      name: 'Ophiopogon japonicus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 23,
      chnn_stock_retail: 25,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYT9BYDR;J7GNGA2T;1Y4BAK1A;NARJE2WA',
      statusContent: 'dot',
      hash: 'E1VX4RTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1VX4RTN'
    },
    {
      status: 210,
      sku: 'PM0001658',
      core_name: 'Plant',
      sppl_ean: '8720349438914',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495151',
      rtl_batch_array: '6307907, 5495151',
      name_search: 'AGFIREWO',
      name: 'Agapanthus Fireworks',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 412,
      chnn_stock_retail: 1218,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ADEDPGW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADEDPGW6'
    },
    {
      status: 810,
      sku: 'PM0074587',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252623',
      rtl_batch_array: '6252623',
      name_search: 'HEGUACAM',
      name: "Heuchera 'Guacamole'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GC598DJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GC598DJ9'
    },
    {
      status: 210,
      sku: 'PM0074588',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252630',
      rtl_batch_array: '6252630',
      name_search: 'HEURLIGH',
      name: "Heuchera 'Red Lightning'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_prcp: 2.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRLJ2PB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRLJ2PB6'
    },
    {
      status: 910,
      sku: 'PM0040052',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      statusContent: 'dot',
      hash: 'X318856X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X318856X'
    },
    {
      status: 210,
      sku: 'PM0079768',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330110',
      rtl_batch_array: '6330110',
      name_search: 'SCCNANA',
      name: "Scabiosa columbaria 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 592,
      chnn_stock_retail: 592,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A7TXPYHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7TXPYHZ'
    },
    {
      status: 210,
      sku: 'PM0050966',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307850',
      rtl_batch_array: '6307850',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 7.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: 'R9AVA8XG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9AVA8XG'
    },
    {
      status: 210,
      sku: 'PM0082233',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346313',
      rtl_batch_array: '6346313',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 14.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '28RWAYDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28RWAYDD'
    },
    {
      status: 910,
      sku: 'PM0079769',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SCCPPINK',
      name: "Scabiosa columbaria 'Pincushion Pink'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '48LPWDKP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '48LPWDKP'
    },
    {
      status: 210,
      sku: 'PM0079770',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330113',
      rtl_batch_array: '6330113',
      name_search: 'SCSCHAME',
      name: "Schizachyrium scoparium 'Chameleon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 137,
      chnn_stock_retail: 137,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'THWY4Z7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THWY4Z7G'
    },
    {
      status: 810,
      sku: 'PM0079771',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330116',
      rtl_batch_array: '6330116',
      name_search: 'SCAVARIE',
      name: "Scrophularia auriculata 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'THSASWNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THSASWNW'
    },
    {
      status: 210,
      sku: 'PM0079772',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330118',
      rtl_batch_array: '6330118',
      name_search: 'TRFPBEAU',
      name: "Tricyrtis formosana 'Purple Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 427,
      chnn_stock_retail: 427,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRKHSZ19;982TYCJ2;HPAXRKWW;GK4XTV1K;BZ8AGY36',
      statusContent: 'dot',
      hash: '7238JWEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7238JWEH'
    },
    {
      status: 210,
      sku: 'PM0032402',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338074',
      rtl_batch_array: '6338074',
      name_search: 'HEHACOMP',
      name: "Hedera hibernica 'Arbori Compact'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3448,
      chnn_stock_retail: 3448,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2X5W4KDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2X5W4KDP'
    },
    {
      status: 210,
      sku: 'PM0079773',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330120',
      rtl_batch_array: '6330120',
      name_search: 'TRSINONO',
      name: "Tricyrtis 'Sinonome'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '68KKT4RR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68KKT4RR'
    },
    {
      status: 210,
      sku: 'PM0077967',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6287619',
      rtl_batch_array: '6300163, 6287619',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 804,
      chnn_stock_retail: 804,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '8B18V9N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8B18V9N8'
    },
    {
      status: 210,
      sku: 'PM0082207',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346286',
      rtl_batch_array: '6346286',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 9.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: '2S8KYDVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S8KYDVD'
    },
    {
      status: 210,
      sku: 'PM0003657',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274737',
      rtl_batch_array: '6105545, 6274737',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '175200C2',
      rng_range_identifier: 'H175200C2',
      rng_range_description: 'H175 cm 200 cm C2',
      sppl_stock_available: 945,
      chnn_stock_retail: 1036,
      sppl_prcp: 3.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'HVHDWDRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVHDWDRT'
    },
    {
      status: 210,
      sku: 'PM0074514',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271352',
      rtl_batch_array: '6271352',
      name_search: 'CARAPHRO',
      name: "Calycanthus raulstonii 'Aphrodite'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 946,
      chnn_stock_retail: 946,
      sppl_prcp: 7.267,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZC2SCW7H;P11RJR6W;8NX69JZA;1GG8TDHY;ZGCJWP74;HJEB4RCY;D6C5KYY8;R1LXYW5S;88HH4VTZ;4WAYSPZ8;EJVVSPDH;LYC3X4ZS',
      statusContent: 'dot',
      hash: '2YZLWDC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YZLWDC3'
    },
    {
      status: 210,
      sku: 'PM0074516',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271354',
      rtl_batch_array: '6271354',
      name_search: 'CEDGVERS',
      name: "Ceanothus delilianus 'Gloire de Versailles'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 577,
      chnn_stock_retail: 577,
      sppl_prcp: 2.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ATGPTYLR;XA17B3K4;HC77YL3A;4TPEZX29',
      statusContent: 'dot',
      hash: 'SLLAKPLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLLAKPLW'
    },
    {
      status: 210,
      sku: 'PM0074517',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271355',
      rtl_batch_array: '6271355',
      name_search: 'CEPMSIMO',
      name: "Ceanothus pallidus 'Marie Simon'",
      sppl_size_code: '020C26L30PL',
      rng_range_identifier: 'H020020C2',
      rng_range_description: 'H20 cm 20 cm C2',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_prcp: 2.094,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAHPJKYX;E4937RK4;SXG491X3;BLYASDEK',
      statusContent: 'dot',
      hash: 'BSX54B66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSX54B66'
    },
    {
      status: 210,
      sku: 'PM0071148',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370652',
      rtl_batch_array: '6370652, 5420726',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 1201,
      sppl_prcp: 3.992,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: '84WDV1DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84WDV1DB'
    },
    {
      status: 210,
      sku: 'PM0070873',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307804',
      rtl_batch_array: '6307804',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: '9ES294K7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ES294K7'
    },
    {
      status: 210,
      sku: 'PM0068522',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205805',
      rtl_batch_array: '6205805',
      name_search: 'CRLUCIFE',
      name: "Crocosmia 'Lucifer'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_prcp: 3.581,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '16DN948R;2ZP5X5CE;Z1RVPG1Y;H2YAA3WG;K4WH8SY1;HY4GS1SR;5C4CPT29',
      statusContent: 'dot',
      hash: 'L3CCGSGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3CCGSGG'
    },
    {
      status: 210,
      sku: 'PM0028153',
      core_name: 'Plant',
      sppl_ean: '8720626350328',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5871891',
      rtl_batch_array: '5871891',
      name_search: 'HESORO',
      name: "Hemerocallis 'Stella de Oro'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6007,
      chnn_stock_retail: 6007,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J55KVYTA;C4TAZAYH;9RGZJJVV;K14SP74B;RJRGE5L7;YELRS667;DHGJXWPA;JP32E8G5;7V324DPR;NCGXDYRX;HY33XH71;L138KD8S;YVLC6WC9;ZVAN65VZ;TS1ZVWWG;RH9CLK3A',
      statusContent: 'dot',
      hash: 'JEZ1J438',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEZ1J438'
    },
    {
      status: 210,
      sku: 'PM0000719',
      core_name: 'Plant',
      sppl_ean: '8720349418718',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503814',
      rtl_batch_array: '5503814',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      imageBatch: '9VK2YSPH',
      statusContent: 'dot',
      hash: 'TB3H24X1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TB3H24X1'
    },
    {
      status: 210,
      sku: 'PM0074574',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267709',
      rtl_batch_array: '6267709, 6309063',
      name_search: 'LECARNAV',
      name: "Leucothoe 'Carnaval'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 240,
      chnn_stock_retail: 740,
      sppl_prcp: 4.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LNRV5JPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNRV5JPB'
    },
    {
      status: 210,
      sku: 'PM0074582',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272016',
      rtl_batch_array: '6272016',
      name_search: 'PLOPAURE',
      name: "Platycladus orientalis 'Pyramidalis Aurea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1192,
      chnn_stock_retail: 1192,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZXTNSGGY',
      statusContent: 'dot',
      hash: 'XPGHL7GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPGHL7GW'
    },
    {
      status: 210,
      sku: 'PM0074584',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272018',
      rtl_batch_array: '6272018',
      name_search: 'JUCSTRIC',
      name: "Juniperus chinensis 'Stricta'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 3883,
      chnn_stock_retail: 3883,
      sppl_prcp: 5.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRHENJC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRHENJC8'
    },
    {
      status: 210,
      sku: 'PM0074585',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272020',
      rtl_batch_array: '6272020',
      name_search: 'JUHWILTO',
      name: "Juniperus horizontalis 'Wiltonii'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 4485,
      chnn_stock_retail: 4485,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTXR91JA;XE2PNDN2;79XC8391;KA6JEJWN;TTPAJR27;5DLCNL9T;8PDW76PN;77XT6W5A;171G33ND;HCB8CRHL;PKNT5STB;SX87XJ56',
      statusContent: 'dot',
      hash: '9XKPPRDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XKPPRDJ'
    },
    {
      status: 210,
      sku: 'PM0074586',
      core_name: 'Plant',
      sppl_ean: '8720626375536',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5414830',
      rtl_batch_array: '5414830',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.519,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      imageBatch: '72L3KVV7',
      statusContent: 'dot',
      hash: 'HHNDNS9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHNDNS9B'
    },
    {
      status: 210,
      sku: 'PM0074583',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272017',
      rtl_batch_array: '6272017',
      name_search: 'CRJVGOLD',
      name: "Cryptomeria japonica 'Vilmorin Gold'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6AWN7X4K;NS6XH9NE;DVHX1YA1',
      statusContent: 'dot',
      hash: 'K5B687Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5B687Z5'
    },
    {
      status: 910,
      sku: 'PM0074581',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THDOLABR',
      name: 'Thujopsis dolabrata',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      imageCore: 'JW48VANP',
      statusContent: 'dot',
      hash: 'E21ETT14',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E21ETT14'
    },
    {
      status: 210,
      sku: 'PM0007888',
      core_name: 'Plant',
      sppl_ean: '8720664632424',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5507505',
      rtl_batch_array: '5507505, 6353439',
      name_search: 'SESILBER',
      name: "Sempervivum 'Silberkarneol'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 2200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8D8GGC55;425XD3T5;KK7SLDVT;ZYPYD8R9;7GLCXLXR;25Y22AT5;JL56945S;E2N3KSAZ;6YXYTBKV;28DSBB1H',
      statusContent: 'dot',
      hash: 'RXX8RA1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXX8RA1K'
    },
    {
      status: 210,
      sku: 'PM0074512',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271350',
      rtl_batch_array: '6271350',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_prcp: 2.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '7A83ZWPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7A83ZWPL'
    },
    {
      status: 810,
      sku: 'PM0056106',
      core_name: 'Plant',
      sppl_ean: '8720626354517',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496623',
      rtl_batch_array: '5496623',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '040045COBOL',
      rng_range_identifier: 'BALL\u00d8045H040050',
      rng_range_description: 'Ball \u00d8 45 cm H40 cm 50 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1AX3AH8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AX3AH8V'
    },
    {
      status: 210,
      sku: 'PM0023337',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 4,
      btch_active_retail: '6312692',
      rtl_batch_array: '6312692, 6378453, 6018431, 6270548',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 600,
      chnn_stock_retail: 5619,
      sppl_prcp: 1.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'WB4E3PB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB4E3PB7'
    },
    {
      status: 210,
      sku: 'PM0069146',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254748',
      rtl_batch_array: '6254748',
      name_search: 'HESSASSY',
      name: "Helenium 'Short and Sassy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 2.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PZY7WVY8;9H84ZNHL;YSVTE62J;8JZL8EPW;PXJPCH3E;TRZTY35A;536AHVSG',
      statusContent: 'dot',
      hash: '6EKE1SEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EKE1SEB'
    },
    {
      status: 210,
      sku: 'PM0067576',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192908',
      rtl_batch_array: '6192908',
      name_search: 'TUVALBA',
      name: "Tulbaghia violacea 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 672,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DL9YLYR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL9YLYR6'
    },
    {
      status: 210,
      sku: 'PM0017667',
      core_name: 'Plant',
      sppl_ean: '8720664864207',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6164282',
      rtl_batch_array: '6164282, 6015086, 6295585',
      name_search: 'GEAFOLKA',
      name: "Geranium 'Ann Folkard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1268,
      chnn_stock_retail: 6229,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K7XXYWAX;YN47Y245;J6CTRK9C;94K3THGA',
      statusContent: 'dot',
      hash: 'PW2LE2TR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW2LE2TR'
    },
    {
      status: 210,
      sku: 'PM0082326',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350135',
      rtl_batch_array: '6350135',
      name_search: 'ERNACHTH',
      name: "Erigeron 'Nachthimmel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9DSLCW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9DSLCW6'
    },
    {
      status: 210,
      sku: 'PM0067583',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192922',
      rtl_batch_array: '6192922',
      name_search: 'STICHU',
      name: 'Stipa ichu',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1440,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y1R1D3NN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1R1D3NN'
    },
    {
      status: 210,
      sku: 'PM0074519',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271360',
      rtl_batch_array: '6271360',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 241,
      chnn_stock_retail: 241,
      sppl_prcp: 1.834,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      statusContent: 'dot',
      hash: 'N4VJPKRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4VJPKRK'
    },
    {
      status: 210,
      sku: 'PM0012209',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6197136',
      rtl_batch_array: '6197136, 5364368, 6295767, 6363548',
      name_search: 'ORLHERRE',
      name: "Origanum laevigatum 'Herrenhausen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2790,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97K8B5TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97K8B5TL'
    },
    {
      status: 210,
      sku: 'PM0074521',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271362',
      rtl_batch_array: '6271362',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 2992,
      chnn_stock_retail: 2992,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'D821ALG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D821ALG3'
    },
    {
      status: 910,
      sku: 'PM0050418',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRDVICTO',
      name: "Prunus domestica 'Victoria'",
      rng_range_identifier: 'H080090C5',
      rng_range_description: 'H80 cm 90 cm C5',
      imageCore:
        'EL659B3C;SBR37DG7;RPB57WX8;TT5YYXB2;4JRSHGCY;TAGW217X;HVA5V1LV;K33JSX8W;DZNB6G86;XDYXABWY;94RR2AZB;2VXHT89S;J6WAN3K6',
      statusContent: 'dot',
      hash: 'EXNXGR8C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EXNXGR8C'
    },
    {
      status: 210,
      sku: 'PM0074525',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271366',
      rtl_batch_array: '6271366',
      name_search: 'HYMAYESH',
      name: "Hydrangea macrophylla 'Ayesha'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A6EXRAK7;4JBKAXR9;265X36VG;95B642HK;CRR6CCLK;5K7D55T9;RL44W8E9',
      statusContent: 'dot',
      hash: '745D6L4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '745D6L4S'
    },
    {
      status: 810,
      sku: 'PM0082327',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350136',
      rtl_batch_array: '6350136',
      name_search: 'ERPKOROD',
      name: "Erigeron 'Prof. Korodi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YLWXDPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YLWXDPL'
    },
    {
      status: 210,
      sku: 'PM0067642',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252447',
      rtl_batch_array: '6252447',
      name_search: 'ANLESSER',
      name: 'Anemone lesseri',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '42WY223B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42WY223B'
    },
    {
      status: 810,
      sku: 'PM0082328',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350138',
      rtl_batch_array: '6350138',
      name_search: 'ERSMEER',
      name: "Erigeron 'Schwarzes Meer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TNBJCJBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNBJCJBD'
    },
    {
      status: 210,
      sku: 'PM0074604',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260904',
      rtl_batch_array: '6260904',
      name_search: 'FAJSWEB',
      name: "Fatsia japonica 'Spider's Web'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3B94AJ2;NKD557B2;X4KAN56K;Y3RNSPWK;8TED5GNZ;Y68T7YTZ;HWNG8EZT;EW9BY6TD;XC4DAZXW;CPZBWXY9;ZDVS9LBL;GWTWBRV2;NAB5S1ZA;VP8A4ZS6;JK7VTB2C;C4B6PJLY;WX9SSXS3',
      statusContent: 'dot',
      hash: 'Y2YT1DEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2YT1DEL'
    },
    {
      status: 210,
      sku: 'PM0067624',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299787',
      rtl_batch_array: '6299787',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1758,
      chnn_stock_retail: 1758,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'PL45BGAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PL45BGAP'
    },
    {
      status: 210,
      sku: 'PM0067629',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197215',
      rtl_batch_array: '6197215',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '020030C7',
      rng_range_identifier: 'H020030C7',
      sppl_stock_available: 1461,
      chnn_stock_retail: 1461,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'A5BNLH4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5BNLH4A'
    },
    {
      status: 210,
      sku: 'PM0074518',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271359',
      rtl_batch_array: '6271359',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_prcp: 3.379,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'EGC4GWX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGC4GWX6'
    },
    {
      status: 810,
      sku: 'PM0067595',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6199469',
      rtl_batch_array: '6199469',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: '4JSBGJTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JSBGJTX'
    },
    {
      status: 810,
      sku: 'PM0067600',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6329941',
      rtl_batch_array: '6202298, 6329941, 6356600',
      name_search: 'GERENARD',
      name: 'Geranium renardii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 89,
      chnn_stock_retail: 247,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8CNGXR7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CNGXR7S'
    },
    {
      status: 210,
      sku: 'PM0074598',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305212',
      rtl_batch_array: '6305212, 6254860',
      name_search: 'VIMALBA',
      name: "Vinca minor 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 667,
      chnn_stock_retail: 1436,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XHY7T4KR;J9VCL9CP;YL2JYJ16',
      statusContent: 'dot',
      hash: 'L1VW64GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1VW64GH'
    },
    {
      status: 210,
      sku: 'PM0067571',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192900',
      rtl_batch_array: '6192900',
      name_search: 'TRGLUCK',
      name: "Tradescantia (A) 'Good Luck'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58YSY7H6;YEH7APPP;KS9J21X4;JH3KDXBG',
      statusContent: 'dot',
      hash: 'SVL6A2EJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVL6A2EJ'
    },
    {
      status: 210,
      sku: 'PM0074599',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254861',
      rtl_batch_array: '6254861',
      name_search: 'VIMATROP',
      name: "Vinca minor 'Atropurpurea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2144,
      chnn_stock_retail: 2144,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9RN6Z5R2;K9WE8974;SNN5WREA;VB7TSAX8;D86DG5HP;7DR4L4KK;Y3TE5BB4;EKSPVVN3;52KHDXEY',
      statusContent: 'dot',
      hash: '2HJRAHY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HJRAHY4'
    },
    {
      status: 210,
      sku: 'PM0079774',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330122',
      rtl_batch_array: '6330122',
      name_search: 'VECHANTI',
      name: "Verbascum 'Chantilly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LY3PYHH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY3PYHH7'
    },
    {
      status: 210,
      sku: 'PM0082329',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350140',
      rtl_batch_array: '6350140',
      name_search: 'ERGUTTAT',
      name: 'Erodium guttatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 431,
      chnn_stock_retail: 431,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G2KVL5YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2KVL5YR'
    },
    {
      status: 810,
      sku: 'PM0079775',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330123',
      rtl_batch_array: '6330123',
      name_search: 'VECYLIGH',
      name: "Verbascum 'Christo's Yellow Lightning'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '428PRN55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '428PRN55'
    },
    {
      status: 210,
      sku: 'PM0082330',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350145',
      rtl_batch_array: '6350145, 6349791',
      name_search: 'ERPBLAUK',
      name: "Eryngium planum 'Blaukappe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P3TRPVRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3TRPVRD'
    },
    {
      status: 210,
      sku: 'PM0079776',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330124',
      rtl_batch_array: '6330124',
      name_search: 'VERDENSI',
      name: 'Verbascum densiflorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1852,
      chnn_stock_retail: 1852,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '78BEJ2T2;KRDZRB62',
      statusContent: 'dot',
      hash: '4Z2N4SCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z2N4SCD'
    },
    {
      status: 810,
      sku: 'PM0067572',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254425',
      rtl_batch_array: '6192902, 6254425, 6348436',
      name_search: 'TRZBLUE',
      name: "Tradescantia (A) 'Zwanenburg Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 85,
      chnn_stock_retail: 614,
      sppl_order_minimum: 3,
      sppl_prcp: 0.819,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2TYEB46J;6JLL46JP',
      statusContent: 'dot',
      hash: '5AW9BZ2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AW9BZ2V'
    },
    {
      status: 210,
      sku: 'PM0067592',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267692',
      rtl_batch_array: '6267692, 6333579',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 486,
      chnn_stock_retail: 536,
      sppl_prcp: 3.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'AZKVBJWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZKVBJWD'
    },
    {
      status: 910,
      sku: 'PM0050416',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRDONTAR',
      name: "Prunus domestica 'Ontario'",
      rng_range_identifier: 'H080090C5',
      rng_range_description: 'H80 cm 90 cm C5',
      imageCore: 'PWXC7A1S',
      statusContent: 'dot',
      hash: '4JHBLCPS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4JHBLCPS'
    },
    {
      status: 210,
      sku: 'PM0079777',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330125',
      rtl_batch_array: '6330125',
      name_search: 'VEJPINK',
      name: "Verbascum 'Jackie in Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '61VR98RH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61VR98RH'
    },
    {
      status: 810,
      sku: 'PM0067579',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192912',
      rtl_batch_array: '6192912',
      name_search: 'SEGUMMIF',
      name: 'Seseli gummiferum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7173KA9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7173KA9A'
    },
    {
      status: 210,
      sku: 'PM0067568',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353404',
      rtl_batch_array: '6353404',
      name_search: 'TICSTRIP',
      name: "Tiarella 'Candy Striper'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZ7ELLA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZ7ELLA2'
    },
    {
      status: 210,
      sku: 'PM0071625',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353123',
      rtl_batch_array: '6230985, 6353123',
      name_search: 'POHWEIHE',
      name: "Polygonatum hybridum 'Weihenstephan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 395,
      chnn_stock_retail: 432,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8LS3GNSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LS3GNSH'
    },
    {
      status: 210,
      sku: 'PM0082331',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321918',
      rtl_batch_array: '6350147, 6321918',
      name_search: 'ERYUCCIF',
      name: 'Eryngium yuccifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 571,
      chnn_stock_retail: 931,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '975ESA16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '975ESA16'
    },
    {
      status: 910,
      sku: 'PM0082332',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUDLJOE',
      name: "Eupatorium dubium 'Little Joe'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HSWYR6VL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HSWYR6VL'
    },
    {
      status: 210,
      sku: 'PM0071626',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353214',
      rtl_batch_array: '6353214',
      name_search: 'POVERTIC',
      name: 'Polygonatum verticillatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 523,
      chnn_stock_retail: 523,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HK5KAGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HK5KAGN'
    },
    {
      status: 210,
      sku: 'PM0071627',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353267',
      rtl_batch_array: '6353267',
      name_search: 'RHBPERLE',
      name: "Rhodohypoxis baurii 'Perle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 597,
      chnn_stock_retail: 597,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RPR5Y6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RPR5Y6W'
    },
    {
      status: 210,
      sku: 'PM0071634',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353364',
      rtl_batch_array: '6353364',
      name_search: 'SCSBLAZE',
      name: "Schizachyrium scoparium 'Blaze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 528,
      chnn_stock_retail: 528,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HPVW7V5H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPVW7V5H'
    },
    {
      status: 210,
      sku: 'PM0071631',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353471',
      rtl_batch_array: '6353471',
      name_search: 'STBARBAT',
      name: 'Stipa barbata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W4PEZC2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4PEZC2Y'
    },
    {
      status: 210,
      sku: 'PM0079778',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330127',
      rtl_batch_array: '6330127',
      name_search: 'VEJYELLO',
      name: "Verbascum 'Jackie in Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGBW44EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGBW44EA'
    },
    {
      status: 210,
      sku: 'PM0079779',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330128',
      rtl_batch_array: '6330128',
      name_search: 'VEVHILL',
      name: "Verbascum 'Violet Hill'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WDY22K6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDY22K6L'
    },
    {
      status: 210,
      sku: 'PM0071900',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 2,
      btch_active_retail: '6378378',
      rtl_batch_array: '6298776, 6378378',
      name_search: 'ROOCAPRI',
      name: "Rosmarinus officinalis 'Capri'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 13788,
      chnn_stock_retail: 13938,
      sppl_prcp: 2.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5RH5N4E7;ANGHJ8XD;KTCCH9RL;4E46N3AL;E6CJ27H4',
      statusContent: 'dot',
      hash: '57NYRWAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57NYRWAC'
    },
    {
      status: 210,
      sku: 'PM0082333',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350161',
      rtl_batch_array: '6350161',
      name_search: 'EUCTTIGE',
      name: "Euphorbia characias 'Tasmanian Tiger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NXWNXDTN;TY57B751;9ZZR3Y5X;VP2S1R5Y;PY596A7H;N9S2VT3A;C45NBPBZ;PVESXHWC;E6SWZ5KX',
      statusContent: 'dot',
      hash: 'GRKNTWH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRKNTWH7'
    },
    {
      status: 210,
      sku: 'PM0079780',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330129',
      rtl_batch_array: '6330129',
      name_search: 'VEALTERN',
      name: 'Verbesina alternifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 389,
      chnn_stock_retail: 389,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WHWY5VLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHWY5VLK'
    },
    {
      status: 910,
      sku: 'PM0079781',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VECKPINK',
      name: "Veronica cantiana 'Kentish Pink'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '84759LVK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '84759LVK'
    },
    {
      status: 810,
      sku: 'PM0079782',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330131',
      rtl_batch_array: '6330131',
      name_search: 'VESIPINK',
      name: 'Veronica spicata Inspire Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7R1R2P6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R1R2P6E'
    },
    {
      status: 910,
      sku: 'PM0071821',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239435',
      rtl_batch_array: '6239435',
      name_search: 'ACCRUBRU',
      name: "Acer cappadocicum 'Rubrum'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJB5WJ2P;2RVB7RRT',
      statusContent: 'dot',
      hash: 'T3JNX4H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3JNX4H6'
    },
    {
      status: 210,
      sku: 'PM0079783',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330132',
      rtl_batch_array: '6330132, 6345217',
      name_search: 'VELALBA',
      name: "Veronica longifolia 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 356,
      chnn_stock_retail: 631,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6SP6Y9EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SP6Y9EC'
    },
    {
      status: 910,
      sku: 'PM0071822',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239475',
      rtl_batch_array: '6239475',
      name_search: 'ACFABLAZ',
      name: 'Acer freemanii Autumn Blaze',
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SCVY6SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SCVY6SL'
    },
    {
      status: 810,
      sku: 'PM0067573',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192905',
      rtl_batch_array: '6192905',
      name_search: 'TRCAMSCH',
      name: 'Trillium camschatcense',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 86,
      chnn_stock_retail: 86,
      sppl_order_minimum: 3,
      sppl_prcp: 6.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXJ6LASR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXJ6LASR'
    },
    {
      status: 210,
      sku: 'PM0079784',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330133',
      rtl_batch_array: '6330133',
      name_search: 'VELBJOHN',
      name: "Veronica longifolia 'Blue John'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B47RJRD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B47RJRD7'
    },
    {
      status: 210,
      sku: 'PM0079785',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330134',
      rtl_batch_array: '6330134',
      name_search: 'VELDMAET',
      name: "Veronica long. 'Dark Maetje'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 348,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CARRPPDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CARRPPDB'
    },
    {
      status: 210,
      sku: 'PM0079786',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330135',
      rtl_batch_array: '6330135',
      name_search: 'VEVLKARI',
      name: "Veronicastrum virginicum 'Lila Karina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GWXDLWVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWXDLWVE'
    },
    {
      status: 210,
      sku: 'PM0079787',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330136',
      rtl_batch_array: '6330136',
      name_search: 'VELRZORA',
      name: "Veronica longif. 'Rote Zora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TGYGSZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TGYGSZR'
    },
    {
      status: 210,
      sku: 'PM0079788',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330137',
      rtl_batch_array: '6330137',
      name_search: 'VERSALBA',
      name: "Veronica spicata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6DTC982V;V5C7VV7N',
      statusContent: 'dot',
      hash: 'EZGHVKCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZGHVKCZ'
    },
    {
      status: 810,
      sku: 'PM0000765',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 3,
      btch_active_retail: '6274735',
      rtl_batch_array: '5779251, 6274735, 6356145',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 84,
      chnn_stock_retail: 2339,
      sppl_prcp: 2.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'AYX72EGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYX72EGD'
    },
    {
      status: 210,
      sku: 'PM0067653',
      core_name: 'Plant',
      sppl_ean: '8720349440832',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207492',
      rtl_batch_array: '5207492, 6348837',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 6700,
      sppl_prcp: 3.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      imageBatch: 'YP36LWTH',
      statusContent: 'dot',
      hash: 'BAK3S4EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAK3S4EV'
    },
    {
      status: 210,
      sku: 'PM0032572',
      core_name: 'Plant',
      sppl_ean: '8720349412099',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866193',
      rtl_batch_array: '5866193',
      name_search: 'CAOEVERI',
      name: "Carex oshimensis 'Everillo'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V56NDZA1;14BTWSPK;GYJ6D49L;CS4EYZDJ;Y5ZY5GR8;2379TG2W;JWS9HHXC;JLXPTH2P;KZ9L7A9S;67879P5Y',
      imageBatch: 'VV927KVX',
      statusContent: 'dot',
      hash: 'LPC3HTLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPC3HTLZ'
    },
    {
      status: 210,
      sku: 'PM0025323',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 3,
      btch_active_retail: '6184130',
      rtl_batch_array: '6112580, 6184130, 6301695',
      name_search: 'LIMAMETH',
      name: 'Liriope muscari Amethyst',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1778,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XPGWDLH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPGWDLH9'
    },
    {
      status: 210,
      sku: 'PM0067641',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244269',
      rtl_batch_array: '6244269, 6251047, 6252446',
      name_search: 'AMARENAR',
      name: 'Ammophila arenaria',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1624,
      chnn_stock_retail: 6364,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YS8LC6TE;8355ZEEA;2T319S8G;1NVK5LRV;CGKAC4EX;E6XSYYAG;RJDRX6AV;VJKBPH51;N3ZC9EY2;DDTWAETH',
      statusContent: 'dot',
      hash: 'JXZKCND2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXZKCND2'
    },
    {
      status: 210,
      sku: 'PM0032573',
      core_name: 'Plant',
      sppl_ean: '8720349411771',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866194',
      rtl_batch_array: '5866194',
      name_search: 'CACBFORM',
      name: "Carex comans 'Bronze Form'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DETA2LLY;SLTRBH5C;GKB8WGT4;WYSS85WR;1TCTS9E8;Z7J2NP88;L5BBAJX3;V4XGJG8R;JVTX6T7A;9G91XD8D;H5GRDS1K;VBW63X7B;71XCG96S;D4SK9DZV;CYR4YT14;GSSBA74Y;HWW9956Z',
      imageBatch: 'VXWV68R5',
      statusContent: 'dot',
      hash: 'WZDWKPYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZDWKPYT'
    },
    {
      status: 210,
      sku: 'PM0074600',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254878',
      rtl_batch_array: '6254878',
      name_search: 'AQVULGAR',
      name: 'Aquilegia vulgaris',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1675,
      chnn_stock_retail: 1675,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BCSTVL3P;T6LDWP1Z;K5NE8NHV;EYXSL8WD;KXRTYAP9;83HEYBEB;27CH7XN2;7JWCPY1W;42ZB4TH4;LDTH6GKY',
      statusContent: 'dot',
      hash: 'A5PKZ8DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5PKZ8DJ'
    },
    {
      status: 910,
      sku: 'PM0074522',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYAHCHOC',
      name: 'Hydrangea aspera Hot Chocolate',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore:
        'JLDGE29Y;WZ2ZKT2V;JCXVD74W;ARS5J1ZG;7372G3ZG;HYRP6RCL;D9NXVDZA',
      statusContent: 'dot',
      hash: 'VE5XZB71',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VE5XZB71'
    },
    {
      status: 210,
      sku: 'PM0071632',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348428',
      rtl_batch_array: '6353478, 6348428',
      name_search: 'SYAZUREU',
      name: 'Symphytum azureum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 977,
      chnn_stock_retail: 4489,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6N5H4HZZ',
      statusContent: 'dot',
      hash: 'NC1K299S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NC1K299S'
    },
    {
      status: 210,
      sku: 'PM0074526',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271367',
      rtl_batch_array: '6271367',
      name_search: 'HYMBERGF',
      name: "Hydrangea macrophylla 'Bergfink'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WP5X7ZR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WP5X7ZR3'
    },
    {
      status: 910,
      sku: 'PM0071624',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POBIFLOR',
      name: 'Polygonatum biflorum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'TNDT1KDJ',
      statusContent: 'dot',
      hash: 'PC1DCTCJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PC1DCTCJ'
    },
    {
      status: 210,
      sku: 'PM0067606',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349122',
      rtl_batch_array: '6349122, 6181395, 6350555',
      name_search: 'INENSIFO',
      name: 'Inula ensifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2136,
      chnn_stock_retail: 3521,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GCAKPEKL;GHSKDTPC;5KX1NRDY',
      statusContent: 'dot',
      hash: 'TPYLBZ92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPYLBZ92'
    },
    {
      status: 210,
      sku: 'PM0023110',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6295892',
      rtl_batch_array: '6295892, 6353399, 6363213',
      name_search: 'THVULGAR',
      name: 'Thymus vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4116,
      chnn_stock_retail: 13628,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DCYCA5XG;74AT3YZX;KAXXC9WT;XRJ7PVV7;SJE2ZLGC;ZZYKV7XY;5VLRRV8Z;AHRXKSST;SZGY7AXC;9Y2YSBPX;B1W4JS7C',
      statusContent: 'dot',
      hash: 'N2CN6RVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2CN6RVL'
    },
    {
      status: 810,
      sku: 'PM0040075',
      core_name: 'Plant',
      sppl_ean: '8720664803428',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5923421',
      rtl_batch_array: '5923421',
      name_search: 'POFMRROB',
      name: 'Potentilla fruticosa Marian Red Robin',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKKZBP1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKKZBP1N'
    },
    {
      status: 210,
      sku: 'PM0074527',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271368',
      rtl_batch_array: '6271368, 6321575',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 475,
      chnn_stock_retail: 970,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: '8A4EXEP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8A4EXEP1'
    },
    {
      status: 210,
      sku: 'PM0074529',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271370',
      rtl_batch_array: '6271370',
      name_search: 'HYMEXPRE',
      name: 'Hydrangea macrophylla Expression',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZB625GLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZB625GLB'
    },
    {
      status: 210,
      sku: 'PM0067569',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242997',
      rtl_batch_array: '6242997, 6254424',
      name_search: 'TICORDIF',
      name: 'Tiarella cordifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 613,
      chnn_stock_retail: 828,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VRBR3KXS;K55S8VR8;T5AXTVDE;NSXNCAAG;1WV11RHZ;PSRZ2DXR;YERVCWH7;9A6W3JBX;A3ZPRK4Z',
      statusContent: 'dot',
      hash: 'YZLVSY5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZLVSY5Z'
    },
    {
      status: 210,
      sku: 'PM0071628',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353437',
      rtl_batch_array: '6353437',
      name_search: 'SEOTHELL',
      name: "Sempervivum 'Othello'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1151,
      chnn_stock_retail: 1151,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VAGHCZ41;LY52CJL9;WSTDWT55;E36DX863;TKL1WJDX',
      statusContent: 'dot',
      hash: 'YZ47B9D2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ47B9D2'
    },
    {
      status: 210,
      sku: 'PM0074530',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271371',
      rtl_batch_array: '6271371',
      name_search: 'HYMGCHAD',
      name: "Hydrangea macrophylla 'Geoffrey Chadbund'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AYXZA3GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYXZA3GG'
    },
    {
      status: 210,
      sku: 'PM0074531',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271373',
      rtl_batch_array: '6271373',
      name_search: 'HYMAGSHA',
      name: "Hydrangea macrophylla 'AB Green Shadow'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WKKY68L9',
      statusContent: 'dot',
      hash: 'A852XRNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A852XRNV'
    },
    {
      status: 210,
      sku: 'PM0050389',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184074',
      rtl_batch_array: '6184074',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5R9GC87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5R9GC87'
    },
    {
      status: 210,
      sku: 'PM0074534',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271377',
      rtl_batch_array: '6271377',
      name_search: 'HYMLOVE',
      name: 'Hydrangea macrophylla Love',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 334,
      chnn_stock_retail: 334,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VCSPVE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VCSPVE5'
    },
    {
      status: 910,
      sku: 'PM0067580',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SISCHAFT',
      name: 'Silene schafta',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'RSRNWPEL;2GSNTX1P;45HDHJEH;1K1EAZP9;WPTDCX5A;25YAS7ZJ;WP752CBD;JJS9ZABH',
      statusContent: 'dot',
      hash: 'B8NAPZDX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'B8NAPZDX'
    },
    {
      status: 210,
      sku: 'PM0074523',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271364',
      rtl_batch_array: '6271364',
      name_search: 'HYALRICK',
      name: 'Hydrangea arborescens Lime Rickey',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3596,
      chnn_stock_retail: 3596,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XLA57TNT;5DNAHYA1;17KSZJCD;99DRPVCL;15TH76JW;LTAJZ6LG',
      statusContent: 'dot',
      hash: 'GSB577S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSB577S6'
    },
    {
      status: 210,
      sku: 'PM0074524',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271365',
      rtl_batch_array: '6298631, 6271365',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 820,
      chnn_stock_retail: 1781,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'JKDRWD5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKDRWD5S'
    },
    {
      status: 210,
      sku: 'PM0074532',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271374',
      rtl_batch_array: '6271374',
      name_search: 'HYMHAMBU',
      name: "Hydrangea macrophylla 'Hamburg'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1920,
      chnn_stock_retail: 1920,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNRBTTW4;A9R8C7DY;H7WW2ZYT;S3WE97VB;9EVCP3WN;HW5VLGRT;J1GDYCTN',
      statusContent: 'dot',
      hash: 'KAVB65CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAVB65CW'
    },
    {
      status: 910,
      sku: 'PM0067589',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6226091',
      rtl_batch_array: '6265422, 6226091',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 18,
      chnn_stock_retail: 1289,
      sppl_prcp: 3.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '8A1C7895',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8A1C7895'
    },
    {
      status: 210,
      sku: 'PM0074533',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271376',
      rtl_batch_array: '6271376',
      name_search: 'HYMLIBEL',
      name: "Hydrangea macrophylla 'Libelle'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1497,
      chnn_stock_retail: 1497,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D32EL74L;ZDCX7HCW;PPGVSD31;87SBT7K6;WEGAXENS;6BCBCPWW;2TZ4DEE7;82K99P7A;VCXPTL8T;AY6YND7C;55V2VJX3;5224VEL6;477G57W9;2R1PPD42;5NDCBTSG;LE6GNY12;PTHA8NJV',
      statusContent: 'dot',
      hash: 'WGNZ2APH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGNZ2APH'
    },
    {
      status: 810,
      sku: 'PM0067577',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6192910',
      rtl_batch_array: '6192910',
      name_search: 'EDI64011',
      name: 'Urginea maritima',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '764XT2WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '764XT2WR'
    },
    {
      status: 210,
      sku: 'PM0074536',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271380',
      rtl_batch_array: '6271380, 6300746',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1566,
      chnn_stock_retail: 2081,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: '2B892Y11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B892Y11'
    },
    {
      status: 210,
      sku: 'PM0067643',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252465',
      rtl_batch_array: '6252465',
      name_search: 'ASTEUROP',
      name: "Astilbe (J) 'Europa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1142,
      chnn_stock_retail: 1142,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRD7E9PK;25VW25XY',
      statusContent: 'dot',
      hash: '2EABHA58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EABHA58'
    },
    {
      status: 210,
      sku: 'PM0019442',
      core_name: 'Plant',
      sppl_ean: '8720626345300',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5741476',
      rtl_batch_array: '6034249, 5741476',
      name_search: 'ERSPECTA',
      name: 'Eragrostis spectabilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1039,
      chnn_stock_retail: 5239,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JTGT1V3P;GLNZCGB7;P4XBJ7YW;D2VPKWKA;T3WW1HD6;B2X1A5W4;XX9ZWDHX;41SJ5XAH;H6XAYHPS',
      statusContent: 'dot',
      hash: 'YT3HB6HJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT3HB6HJ'
    },
    {
      status: 210,
      sku: 'PM0074528',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271369',
      rtl_batch_array: '6271369',
      name_search: 'HYMEBLUE',
      name: 'Hydrangea macrophylla Early Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1803,
      chnn_stock_retail: 1803,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TEP8X58H;LNL7W4G5',
      statusContent: 'dot',
      hash: '99Y3P7RN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99Y3P7RN'
    },
    {
      status: 210,
      sku: 'PM0074535',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271378',
      rtl_batch_array: '6271378',
      name_search: 'HYMMSAOR',
      name: 'Hydrangea macrophylla Miss Saori',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1149,
      chnn_stock_retail: 1149,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTT9PEE8;L1GKC1XZ',
      statusContent: 'dot',
      hash: 'T864JZ42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T864JZ42'
    },
    {
      status: 210,
      sku: 'PM0067567',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315294',
      rtl_batch_array: '6192891, 6315294, 6344101',
      name_search: 'SASUBULA',
      name: 'Sagina subulata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2281,
      chnn_stock_retail: 4482,
      sppl_order_minimum: 3,
      sppl_prcp: 0.497,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N9RZSZH6;7YVJ7E7T;JAPL8TYL;R4JJC7RG;WZXH5277;RNJPN82S;W2Y5ZDJP;SJJC95A5;ZY4KS3BA;EBH22TA3;24DX6R1S;6TAEN25W;C899288A;E1DP5TD1;8TJJJLRH;8YRV2V3P;A281Y57T;2988Y9TA;HZYSZ3X3;RD4WB6KE;GBNBW2D7',
      statusContent: 'dot',
      hash: '4VLEJ1SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VLEJ1SK'
    },
    {
      status: 210,
      sku: 'PM0067640',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254863',
      rtl_batch_array: '6254863',
      name_search: 'ACMKUPFE',
      name: "Acaena microphylla 'Kupferteppich'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 483,
      chnn_stock_retail: 483,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'THVPBN12;Y1PPN7W5;6EB11VWY;T3554NA2;75T1573P;GTW66P4R',
      statusContent: 'dot',
      hash: 'RP158G1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RP158G1K'
    },
    {
      status: 210,
      sku: 'PM0074595',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254804',
      rtl_batch_array: '6254804',
      name_search: 'PEOTTAIL',
      name: "Pennisetum orientale 'Tall Tails'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_prcp: 2.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P1XJEN79;EGJWDCJD',
      statusContent: 'dot',
      hash: '41RX6THW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41RX6THW'
    },
    {
      status: 210,
      sku: 'PM0074603',
      core_name: 'Plant',
      sppl_ean: '8720349417001',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '5861507',
      rtl_batch_array: '5861507',
      name_search: 'HEPSUTHE',
      name: "Hebe pinguifolia 'Sutherlandii'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 642,
      chnn_stock_retail: 642,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G956WPEA;ZVZ622DX;YZAJG146;7YR1WZBN;1JZLSCZV;59YNEXAE;RP8125WN',
      statusContent: 'dot',
      hash: 'HW4LW27L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HW4LW27L'
    },
    {
      status: 210,
      sku: 'PM0074597',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254857',
      rtl_batch_array: '6254857',
      name_search: 'VEARBLUE',
      name: "Veronica austriaca 'Royal Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZ5PSNYP;RXNHR5EW',
      statusContent: 'dot',
      hash: 'K4T18KA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4T18KA1'
    },
    {
      status: 210,
      sku: 'PM0074596',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254830',
      rtl_batch_array: '6254830',
      name_search: 'SAOFFICI',
      name: 'Salvia officinalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KH989H8A;ASEDG7N1;96JG2RGP;7EBJN7GH;GGKPYL7L;5S5WHJHY;7EVKDY18;R8DDDH2Z;GWBLLYD2;JWVAXGJ8;GTE4N5BC;LHR87C97;G7H1NB3C;VZ373N22;KHKLYG9J;KEPTSH4Z;NTT9ZDCC;L8GHZ3JC;J67T4CLC',
      statusContent: 'dot',
      hash: '2EZ6NLN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EZ6NLN2'
    },
    {
      status: 210,
      sku: 'PM0067690',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185788',
      rtl_batch_array: '6185788',
      name_search: 'HOWFEATH',
      name: "Hosta 'White Feather'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W2EKGAB8;NKJ4ASAJ;A3A7W1VG;H9LPYS8R;ZDLKLC6E',
      statusContent: 'dot',
      hash: '5ZW9LHZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZW9LHZ2'
    },
    {
      status: 210,
      sku: 'PM0003789',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6333275',
      rtl_batch_array: '6217209, 6202319, 6339838, 6333275',
      name_search: 'LYCHALCE',
      name: 'Lychnis chalcedonica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6144,
      chnn_stock_retail: 6890,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GBHS3LHD;2DV5WTXZ;JV6JHWB9;NXBP7Z3V;V1RZ6ENB',
      statusContent: 'dot',
      hash: 'WV8H21NC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV8H21NC'
    },
    {
      status: 210,
      sku: 'PM0074537',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271381',
      rtl_batch_array: '6271381',
      name_search: 'HYMPDIAN',
      name: 'Hydrangea macrophylla Princess Diana',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1489,
      chnn_stock_retail: 1489,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DXR3353D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXR3353D'
    },
    {
      status: 910,
      sku: 'PM0074605',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      rng_range_identifier: 'H040050C7',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: 'YX7KLCG1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YX7KLCG1'
    },
    {
      status: 910,
      sku: 'PM0079792',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VEVILLOS',
      name: 'Veronicastrum villosulum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'S1DC1CZK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S1DC1CZK'
    },
    {
      status: 210,
      sku: 'PM0032821',
      core_name: 'Plant',
      sppl_ean: '8720626343528',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837411',
      rtl_batch_array: '5837411, 6250987',
      name_search: 'DEASTOLA',
      name: "Delphinium (P) 'Astolat'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 498,
      chnn_stock_retail: 548,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '19NJ5S1C;HHY3DV3Z;PZ66E6VD;WKJ9BYR3;79W8N3V1;BGV6NVZA;SASEG299;DWRKJCA3;XZ9Z3XVX',
      statusContent: 'dot',
      hash: '1V7GVRJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1V7GVRJA'
    },
    {
      status: 210,
      sku: 'PM0033866',
      core_name: 'Plant',
      sppl_ean: '8720664801356',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849975',
      rtl_batch_array: '5849975',
      name_search: 'PEAAJUNI',
      name: 'Persicaria amplexicaulis Alba Junior',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9552ASHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9552ASHA'
    },
    {
      status: 210,
      sku: 'PM0074538',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271382',
      rtl_batch_array: '6271382',
      name_search: 'HYMROSIT',
      name: "Hydrangea macrophylla 'Rosita'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WCDXS5H6;YGP8DR88;TJXPY59R;AVZSBGLX;5B7BWV5A',
      statusContent: 'dot',
      hash: '6JYWVLNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JYWVLNN'
    },
    {
      status: 210,
      sku: 'PM0067687',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183183',
      rtl_batch_array: '6183183',
      name_search: 'VEGENTIA',
      name: 'Veronica gentianoides',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 656,
      chnn_stock_retail: 656,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XGE85TEL;W6YJ8J6A;JVTRHP86;D2EBPJBC;5GP7HW51;WAEKX3C2;5LP653BA;5WGSLYX3;BDTC232N;LV5ESDPG;CLPXCZES;B9WWHTYA;BN8JYWJC;G6EJZRDR;V4V5D9Z8;TAD58A62;R7669P91;6B998AC8;7KBCN1JP;58PY35C4;PVBB11LR;BC23KSB9;2YPD17AP',
      statusContent: 'dot',
      hash: '9DRTJX9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DRTJX9G'
    },
    {
      status: 210,
      sku: 'PM0071635',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353568',
      rtl_batch_array: '6315079, 6353568',
      name_search: 'VEVADORA',
      name: "Veronicastrum virginicum 'Adoration'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 554,
      chnn_stock_retail: 931,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8BBPDAXS;G2K1GG51;H2LX9328;549KH711;NZDL19X8',
      statusContent: 'dot',
      hash: 'T4YW16PW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4YW16PW'
    },
    {
      status: 210,
      sku: 'PM0071637',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353578',
      rtl_batch_array: '6353578',
      name_search: 'VEVLAVEN',
      name: "Veronicastrum virginicum 'Lavendelturm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2248,
      chnn_stock_retail: 2248,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WJDLPZ4S;G94VZG15;ERLR1KSP;VRKCKJ94;L9PSVE8W',
      statusContent: 'dot',
      hash: 'B34AV5RE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B34AV5RE'
    },
    {
      status: 210,
      sku: 'PM0067693',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185794',
      rtl_batch_array: '6185794',
      name_search: 'ECECCENT',
      name: "Echinacea 'Eccentric'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S4PPW5HR',
      statusContent: 'dot',
      hash: 'W9E5YAVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9E5YAVP'
    },
    {
      status: 910,
      sku: 'PM0067703',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: '923NZ1WX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '923NZ1WX'
    },
    {
      status: 210,
      sku: 'PM0032349',
      core_name: 'Plant',
      sppl_ean: '8720664680913',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5825788',
      rtl_batch_array: '5825788',
      name_search: 'ARMSPERF',
      name: "Armeria maritima 'Splendens Perfecta'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 151,
      chnn_stock_retail: 151,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A4Y7RV4V',
      imageBatch: 'SYWX8DDD',
      statusContent: 'dot',
      hash: 'VWJTC95Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWJTC95Z'
    },
    {
      status: 910,
      sku: 'PM0038334',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083872',
      rtl_batch_array: '6083872',
      name_search: 'DCMSBAMB',
      name: 'DCM Meststof Siergrassen en Bamboe',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '5ZY2G63P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '5ZY2G63P'
    },
    {
      status: 210,
      sku: 'PM0067745',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284563',
      rtl_batch_array: '6284563',
      name_search: 'TAMHICKS',
      name: "Taxus media 'Hicksii'",
      sppl_size_code: '060080C4',
      rng_range_identifier: 'H060080C4',
      sppl_stock_available: 3017,
      chnn_stock_retail: 3017,
      sppl_prcp: 5.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3VRWWDD;J2AKC2VL;8CZK2HD2;8ZV5VLG4;7AEJ3VV4;EBALXDRR;6ZDVKRTD;C13NKD2B;DWWDPWP8;ATDP6H3R;1JNBPJLR;CVXNHVL2;J1VVB4BW;BE7XPP8P;WPSSAW3R;AK5N9P64;HLECHH3D;J14TXL93;Z46WST52',
      statusContent: 'dot',
      hash: 'GWR44NWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWR44NWK'
    },
    {
      status: 910,
      sku: 'PM0067725',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186661',
      rtl_batch_array: '6186661',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 4.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: '6JXC7K6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JXC7K6E'
    },
    {
      status: 210,
      sku: 'PM0067721',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186655',
      rtl_batch_array: '6186655',
      name_search: 'TABREPAN',
      name: "Taxus baccata 'Repandens'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 3080,
      chnn_stock_retail: 3080,
      sppl_prcp: 4.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '43L48WA5;RZ7W67KK;SKTPY2R1;T9799XAC;REHZ57Z8;JGK4PTX4;4E58PEN2;6WVK1D3A;N3C2RV34;C2W3JNX3;184LH1TA;LKHNSLNC',
      statusContent: 'dot',
      hash: '61H39TEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61H39TEV'
    },
    {
      status: 210,
      sku: 'PM0067722',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186656',
      rtl_batch_array: '6186656',
      name_search: 'TABREPAN',
      name: "Taxus baccata 'Repandens'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 7481,
      chnn_stock_retail: 7481,
      sppl_prcp: 4.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '43L48WA5;RZ7W67KK;SKTPY2R1;T9799XAC;REHZ57Z8;JGK4PTX4;4E58PEN2;6WVK1D3A;N3C2RV34;C2W3JNX3;184LH1TA;LKHNSLNC',
      statusContent: 'dot',
      hash: 'K3E24DD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3E24DD1'
    },
    {
      status: 210,
      sku: 'PM0067744',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186992',
      rtl_batch_array: '6186992',
      name_search: 'TABSUMME',
      name: "Taxus baccata 'Summergold'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 248,
      chnn_stock_retail: 248,
      sppl_prcp: 7.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KSV5SYC;J3ATLRXA;85VREB5P;1B7X7CVY;7XH9WWVH;N261GH3S',
      statusContent: 'dot',
      hash: 'BLYXSTP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLYXSTP4'
    },
    {
      status: 210,
      sku: 'PM0028024',
      core_name: 'Plant',
      sppl_ean: '8720664680371',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054338',
      rtl_batch_array: '6054338',
      name_search: 'EDI85555',
      name: 'Allium nevskianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '92RZC1VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92RZC1VJ'
    },
    {
      status: 210,
      sku: 'PM0079793',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353366',
      rtl_batch_array: '6353366',
      name_search: 'TAVIGOLD',
      name: "Tanacetum vulgare 'Isla Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y5W96ZD6',
      statusContent: 'dot',
      hash: '4PHD1E5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PHD1E5Z'
    },
    {
      status: 210,
      sku: 'PM0067691',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185789',
      rtl_batch_array: '6185789',
      name_search: 'HOTFLAVO',
      name: "Hosta 'Tokudama Flavocircinalis'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZYCDEA8',
      statusContent: 'dot',
      hash: '9X18L36Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X18L36Y'
    },
    {
      status: 210,
      sku: 'PM0067700',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185801',
      rtl_batch_array: '6185801',
      name_search: 'HOBCUP',
      name: "Hosta 'Brim Cup'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T2LEY3DJ;J9E41DWE;6G3ECZVP;L1A7G7WZ;EETT6RBV;1LYTA6Y3;BV78LNDX;1TZPEYP5;XJDDZLSK;A9PKNVJL;L3BK3HJW;DGCVH5GS;WN51Y8EA;LAG56458;8PW68GYZ',
      statusContent: 'dot',
      hash: 'JD6ZERYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JD6ZERYR'
    },
    {
      status: 210,
      sku: 'PM0082334',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350162',
      rtl_batch_array: '6350162',
      name_search: 'EUGBLUE',
      name: "Euphorbia hybrid 'Glacier Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7YLCY9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7YLCY9P'
    },
    {
      status: 810,
      sku: 'PM0067733',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186978',
      rtl_batch_array: '6186978',
      name_search: 'CHOMAURE',
      name: "Chamaecyparis obtusa 'Maureen'",
      sppl_size_code: '015020C3',
      rng_range_identifier: 'H015020C3',
      rng_range_description: 'H15 cm 20 cm C3',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 5.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZSR6SSY;R245YZ92',
      statusContent: 'dot',
      hash: 'NVHYYWAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVHYYWAW'
    },
    {
      status: 210,
      sku: 'PM0032815',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6197008',
      rtl_batch_array: '6197008, 5837388, 5849828, 6252444',
      name_search: 'ALMOLLIS',
      name: 'Alchemilla mollis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5493,
      chnn_stock_retail: 14106,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EHLKNB2V;CK9A2PG8;R2SRHX1J;J9RBBHEJ;HCWV9H57;NJDA177T;REXB3W18;N1JS3HZA;4VV1D59B;5RL49APS;JXHWCWSD;JZ5XW2K7;4K3P7PLL;E31H5L6W;KK56BZ8H;J1PS1T6J;YJ9B9V15;7H4ANG7G;ES93584N;C5Z4N6KL;VPHGPC3X;CTZCKHSA;1C75XRN8;1856E8BZ',
      statusContent: 'dot',
      hash: 'K5RH4ZTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5RH4ZTW'
    },
    {
      status: 210,
      sku: 'PM0067677',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183167',
      rtl_batch_array: '6183167',
      name_search: 'ERVBFORM',
      name: "Erodium variabile 'Bishop's Form'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRB5K763',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRB5K763'
    },
    {
      status: 210,
      sku: 'PM0067736',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257838',
      rtl_batch_array: '6257838',
      name_search: 'JUCREPAN',
      name: "Juniperus communis 'Repanda'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 4629,
      chnn_stock_retail: 4629,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TCX3E1WY;AHRHLBLB;XLS3DSDH;8NW6KNDW;GSXVB568;YB6RY9D3;G47EZ7SW;649BBC73;STWVW2RL;YN213YVT;ZYJVR4AG;ARJ5LEEG;NSCSLPPB;LLLY47EE;G9ZP44BJ;E5EEEL2Y;5W75BBW8;43YH4JB9;DAH8E3D3;4J8HVX2E;W3K49ZNW',
      statusContent: 'dot',
      hash: '41GYH1Y9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41GYH1Y9'
    },
    {
      status: 210,
      sku: 'PM0067710',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244378',
      rtl_batch_array: '6244378',
      name_search: 'CHPSUNGO',
      name: "Chamaecyparis pisifera 'Sungold'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1592,
      chnn_stock_retail: 1592,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZHLJTR1H;5XZEYB8G;7YNLHJLH;Y1BTW97G;GZJG415D;ECLLZG7H;JAYJDR7D;Z2T7J22C;5592HDCX;AA25R4RZ;2Z5DKD8B;9CYC76PC;ZHDTBX3N;B9C4KJ17;5D5APVLG;64XLHEK9;WP8N54HS',
      statusContent: 'dot',
      hash: 'YNXV61KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNXV61KA'
    },
    {
      status: 210,
      sku: 'PM0074543',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271388',
      rtl_batch_array: '6271388',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1817,
      chnn_stock_retail: 1817,
      sppl_prcp: 2.349,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'EEZ5SADK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEZ5SADK'
    },
    {
      status: 910,
      sku: 'PM0032341',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SESVOODO',
      name: "Sedum spurium 'Voodoo'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: '4DV6JBWX;R67YDYKK;VNTC7DRN',
      statusContent: 'dot',
      hash: 'PDW54J6G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PDW54J6G'
    },
    {
      status: 210,
      sku: 'PM0074545',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271390',
      rtl_batch_array: '6271390',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 11332,
      chnn_stock_retail: 11332,
      sppl_prcp: 3.122,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'R298R4XC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R298R4XC'
    },
    {
      status: 210,
      sku: 'PM0074549',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271394',
      rtl_batch_array: '6271394',
      name_search: 'HYPUNIQU',
      name: "Hydrangea paniculata 'Unique'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_prcp: 2.349,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DD9LJPH;KB36AY45;J7Z7T8C5;1KGH13HA;YNA2SKY1;V2X3SEVT;EEC6931R;XX2XP852;WPKTKD2R',
      statusContent: 'dot',
      hash: 'B6SG25D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6SG25D5'
    },
    {
      status: 910,
      sku: 'PM0071828',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239575',
      rtl_batch_array: '6239575',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 19.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'S137647H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S137647H'
    },
    {
      status: 210,
      sku: 'PM0067729',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243340',
      rtl_batch_array: '6243340',
      name_search: 'ABKOREAN',
      name: 'Abies koreana',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2137,
      chnn_stock_retail: 2137,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PRN4VTP;25XPVV4T;973S6HBS;893SGVLD',
      statusContent: 'dot',
      hash: 'KHVBDA53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHVBDA53'
    },
    {
      status: 210,
      sku: 'PM0032823',
      core_name: 'Plant',
      sppl_ean: '8720349473939',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837413',
      rtl_batch_array: '5837413',
      name_search: 'DEPWHITE',
      name: "Delphinium (P) 'Pacific White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HWTSRL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HWTSRL8'
    },
    {
      status: 210,
      sku: 'PM0071636',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353574',
      rtl_batch_array: '6315080, 6353574',
      name_search: 'VEVDIANA',
      name: "Veronicastrum virginicum 'Diana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2910,
      chnn_stock_retail: 3100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H44PLGAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H44PLGAB'
    },
    {
      status: 210,
      sku: 'PM0067672',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6183157',
      rtl_batch_array: '6183157, 6253831',
      name_search: 'ACMPAPRI',
      name: "Achillea millefolium 'Paprika'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2066,
      chnn_stock_retail: 3066,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5VJGREE;2CAXHCNT;9EVTVGC8;1622HBS8;LTLPJ621',
      statusContent: 'dot',
      hash: 'BGP1Y7G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGP1Y7G6'
    },
    {
      status: 210,
      sku: 'PM0079794',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353381',
      rtl_batch_array: '6353381',
      name_search: 'THDHINCK',
      name: "Thalictrum delavayi 'Hinckley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RGP3R3LT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGP3R3LT'
    },
    {
      status: 810,
      sku: 'PM0071638',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353493',
      rtl_batch_array: '6353493',
      name_search: 'VIOTOULO',
      name: "Viola odorata 'Toulouse'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '79HLDXKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79HLDXKV'
    },
    {
      status: 210,
      sku: 'PM0032211',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252468',
      rtl_batch_array: '6252468',
      name_search: 'ASMFLORE',
      name: "Astrantia major 'Florence'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 928,
      chnn_stock_retail: 928,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1WSDVPS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WSDVPS7'
    },
    {
      status: 210,
      sku: 'PM0071639',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353527',
      rtl_batch_array: '6353527',
      name_search: 'TRKURABA',
      name: 'Trillium kurabayashii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 12.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LPD53SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LPD53SX'
    },
    {
      status: 210,
      sku: 'PM0074608',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234786',
      rtl_batch_array: '6234786',
      name_search: 'HEFPURPL',
      name: "Heuchera 'Forever Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 780,
      chnn_stock_retail: 780,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1E3EL39Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1E3EL39Y'
    },
    {
      status: 210,
      sku: 'PM0079795',
      core_name: 'Plant',
      sppl_ean: '8720626330030',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5553900',
      rtl_batch_array: '5553900, 6333313, 6137233',
      name_search: 'THDVALLE',
      name: "Thymus 'Doone Valley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 3237,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8NAB3B8;JA245VVH;EDG8SLJ3;W5HD1TZC;5ZW6TCJ4;C7T5RXZK;S9BJBH8T;5353C5V6;8TPEXZ18;XDYL2DZB;A87DRSS4;7NNKLYR2;8CR55LZ8;JWCTCGVE;J54HXHNL;CPDS2GY5;X1WNT39W;8RG6E53G;SLR68SAS;WXVT95XK;DN395EP6;SZSDDPTV',
      statusContent: 'dot',
      hash: '6GPR251S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GPR251S'
    },
    {
      status: 810,
      sku: 'PM0074546',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271391',
      rtl_batch_array: '6271391',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 3.894,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: '8NEG2NA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NEG2NA2'
    },
    {
      status: 210,
      sku: 'PM0079796',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353494',
      rtl_batch_array: '6353494',
      name_search: 'VIORNEIG',
      name: "Viola odorata 'Reine des Neiges'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 401,
      chnn_stock_retail: 401,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NTVXLV1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTVXLV1Z'
    },
    {
      status: 210,
      sku: 'PM0067678',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183168',
      rtl_batch_array: '6183168',
      name_search: 'ERRJEP',
      name: "Erysimum 'Red Jep'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CHEZKA6N;6GTDDAV5;N5CHKHV3;6VCEZ6N4;LG6W61LZ;JZ2X2SXN;VEVDP2T9;P3E29DYK;P9XN9YTL',
      statusContent: 'dot',
      hash: '19BNXSGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19BNXSGC'
    },
    {
      status: 210,
      sku: 'PM0079789',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330138',
      rtl_batch_array: '6330138',
      name_search: 'VESBCAND',
      name: 'Veronica spicata Bubblegum Candles',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V4SLDVWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4SLDVWB'
    },
    {
      status: 210,
      sku: 'PM0079790',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330139',
      rtl_batch_array: '6330139',
      name_search: 'VESRCAND',
      name: 'Veronica spicata Royal Candles',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LRTTLWJ8;DX9RRA5Z;Y2J8TZV2',
      statusContent: 'dot',
      hash: 'E51P1D28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E51P1D28'
    },
    {
      status: 810,
      sku: 'PM0079791',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353567',
      rtl_batch_array: '6353567',
      name_search: 'VESLAVEN',
      name: "Verbena Seabrooke's Lavender",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZTVJ4BCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTVJ4BCT'
    },
    {
      status: 210,
      sku: 'PM0079797',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353504',
      rtl_batch_array: '6353504',
      name_search: 'ZIMWFEAT',
      name: "Zingiber mioga 'White Feather'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 439,
      chnn_stock_retail: 439,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2DTV4KS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DTV4KS5'
    },
    {
      status: 810,
      sku: 'PM0011389',
      core_name: 'Plant',
      sppl_ean: '8720664690691',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6090718',
      rtl_batch_array: '6090718',
      name_search: 'GEDEYES',
      name: "Geranium 'Dark Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4858XB8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4858XB8R'
    },
    {
      status: 210,
      sku: 'PM0040235',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 4,
      btch_active_retail: '6356144',
      rtl_batch_array: '6346664, 6284486, 6356144, 5420643',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '080100C2GEB',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 996,
      chnn_stock_retail: 13093,
      sppl_order_minimum: 3,
      sppl_prcp: 2.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'VDCW45RK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDCW45RK'
    },
    {
      status: 210,
      sku: 'PM0067740',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186988',
      rtl_batch_array: '6186988',
      name_search: 'PIPWALDB',
      name: "Picea pungens 'Waldbrunn'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 5.339,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WVBSTPPA;L9NP2HP1;5Z8BE5PS;P5186JSW;XZ3KE1ZC;VKC5SEHY;TSCZD7JS;WYVBDEHB;5ZENSL83',
      statusContent: 'dot',
      hash: 'XGEB6XKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGEB6XKC'
    },
    {
      status: 910,
      sku: 'PM0067679',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUMARTIN',
      name: 'Euphorbia martini',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        'ZGGER66P;A464B1DA;759D1DX8;NGTNSJ8K;5RVP5971;EZAXP6D9;6VDSKX6W;K5ANLWE4;67CC2W84;56AAC9E6;JZWWWBAX;2DJEGSH6;JHYKGRW2;Y21BGV1L;XS1ATDTX;PXR7PRW4;KYWPS1PA;ADWTY1K8',
      statusContent: 'dot',
      hash: 'NJX2L91H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NJX2L91H'
    },
    {
      status: 210,
      sku: 'PM0074539',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271383',
      rtl_batch_array: '6271383',
      name_search: 'HYSVEERL',
      name: "Hydrangea serrata 'Veerle'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T61LVTNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T61LVTNN'
    },
    {
      status: 210,
      sku: 'PM0067739',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186987',
      rtl_batch_array: '6186987',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1545,
      chnn_stock_retail: 1545,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'YDXPCVCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDXPCVCR'
    },
    {
      status: 210,
      sku: 'PM0071640',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353540',
      rtl_batch_array: '6301898, 6353540',
      name_search: 'VEPDOMIN',
      name: "Verbascum 'Pink Domino'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 924,
      chnn_stock_retail: 1049,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NDD3T53R;2DSY1NPW',
      statusContent: 'dot',
      hash: 'HKYR7V1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKYR7V1H'
    },
    {
      status: 910,
      sku: 'PM0071823',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239556',
      rtl_batch_array: '6239556',
      name_search: 'ACROGLOR',
      name: "Acer rubrum 'October Glory'",
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECZK7X9D',
      statusContent: 'dot',
      hash: 'EHARL5PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHARL5PD'
    },
    {
      status: 210,
      sku: 'PM0032226',
      core_name: 'Plant',
      sppl_ean: '8720664889224',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6146947',
      rtl_batch_array: '6313213, 6252797, 6146947',
      name_search: 'VEBLOLLI',
      name: "Verbena bonariensis 'Lollipop'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2109,
      chnn_stock_retail: 4493,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GVJLSHW;GKVZJA6N;ZA1BDD7V;LS7KZHLC;E1EK8TCG;L3LS49D2;8L9ZD89X;JERE7SHP;SLYE773K;GNZC7VRW;S453G263;CPPL5L76',
      statusContent: 'dot',
      hash: 'Z37NC7YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z37NC7YA'
    },
    {
      status: 210,
      sku: 'PM0074540',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271384',
      rtl_batch_array: '6271384',
      name_search: 'HYPCANDL',
      name: 'Hydrangea paniculata Candlelight',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_prcp: 3.122,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJ6V2KAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJ6V2KAK'
    },
    {
      status: 910,
      sku: 'PM0071824',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239558',
      rtl_batch_array: '6239558',
      name_search: 'ACERRSUN',
      name: 'Acer rubrum Red Sunset',
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 21.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EPHKR75Z;69XD6TYP;4G46YTGH',
      statusContent: 'dot',
      hash: 'WL5Y6YAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL5Y6YAJ'
    },
    {
      status: 910,
      sku: 'PM0071825',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239559',
      rtl_batch_array: '6239559',
      name_search: 'ACSPYRAM',
      name: "Acer saccharinum 'Pyramidale'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 16.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '48521RW2',
      statusContent: 'dot',
      hash: 'ADAKTARC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADAKTARC'
    },
    {
      status: 210,
      sku: 'PM0074541',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271386',
      rtl_batch_array: '6271386',
      name_search: 'HYPBOMBS',
      name: "Hydrangea paniculata 'Bombshell'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4189,
      chnn_stock_retail: 4189,
      sppl_prcp: 3.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KSSDBBWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSSDBBWX'
    },
    {
      status: 910,
      sku: 'PM0071826',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239561',
      rtl_batch_array: '6239561',
      name_search: 'ALSPAETH',
      name: 'Alnus spaethii',
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 19.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SS1XVWAX',
      statusContent: 'dot',
      hash: 'PXTEBBAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXTEBBAN'
    },
    {
      status: 210,
      sku: 'PM0074542',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271387',
      rtl_batch_array: '6271387',
      name_search: 'HYPDROUG',
      name: 'Hydrangea paniculata Diamand Rouge',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1082,
      chnn_stock_retail: 1082,
      sppl_prcp: 3.122,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NLCTS6RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLCTS6RJ'
    },
    {
      status: 910,
      sku: 'PM0071827',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239562',
      rtl_batch_array: '6239562',
      name_search: 'AMARHILL',
      name: "Amelanchier arborea 'Robin Hill'",
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YYR88H22;2ARDZSYG;RXLRAEB4;TGB28SBH;6KVDAN7G',
      statusContent: 'dot',
      hash: 'AR47NLEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR47NLEN'
    },
    {
      status: 210,
      sku: 'PM0071902',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242405',
      rtl_batch_array: '6242405',
      name_search: 'HIBISCUS',
      name: "Hibiscus in cv's",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JJ7X6128',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJ7X6128'
    },
    {
      status: 210,
      sku: 'PM0074544',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271389',
      rtl_batch_array: '6271389',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 847,
      chnn_stock_retail: 847,
      sppl_prcp: 2.349,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: '5RWLAG6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RWLAG6Z'
    },
    {
      status: 210,
      sku: 'PM0067674',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183162',
      rtl_batch_array: '6183162',
      name_search: 'BRMJFROS',
      name: "Brunnera macrophylla 'Jack Frost'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5868,
      chnn_stock_retail: 5868,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G2LCES19;B447J5EN;RSJB1VH3;RPP2N27D;H4T88DYN',
      statusContent: 'dot',
      hash: 'D9BNSNPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9BNSNPG'
    },
    {
      status: 210,
      sku: 'PM0067682',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183172',
      rtl_batch_array: '6183172',
      name_search: 'GEMESPRE',
      name: "Geranium maculatum 'Espresso'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1302,
      chnn_stock_retail: 1302,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RKPRW9ZV;Y2TBVL67',
      statusContent: 'dot',
      hash: '9WG4XG8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WG4XG8A'
    },
    {
      status: 210,
      sku: 'PM0074547',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271392',
      rtl_batch_array: '6271392',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 2645,
      chnn_stock_retail: 2645,
      sppl_prcp: 2.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'RLLB5R37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLLB5R37'
    },
    {
      status: 210,
      sku: 'PM0067685',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183180',
      rtl_batch_array: '6183180',
      name_search: 'SEAYQUEE',
      name: "Sedum acre 'Yellow Queen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 841,
      chnn_stock_retail: 841,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HJ23LBR3;8PA58N32;CRZHLTH1;9JYA2AAJ;T7RSAB64;AN67L39G;RSWP3221',
      statusContent: 'dot',
      hash: '16V8VV4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16V8VV4S'
    },
    {
      status: 210,
      sku: 'PM0074551',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271397',
      rtl_batch_array: '6271397',
      name_search: 'HYQHARMO',
      name: "Hydrangea quercifolia 'Harmony'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 789,
      chnn_stock_retail: 789,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S3WGZYK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3WGZYK3'
    },
    {
      status: 210,
      sku: 'PM0074552',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271399',
      rtl_batch_array: '6271399',
      name_search: 'HYSDARED',
      name: 'Hydrangea serrata Daredevil',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 2.866,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LYVWLZEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYVWLZEC'
    },
    {
      status: 210,
      sku: 'PM0074553',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271400',
      rtl_batch_array: '6271400',
      name_search: 'HYSDOLMY',
      name: "Hydrangea serrata 'Dolmyf'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X139WZW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X139WZW3'
    },
    {
      status: 210,
      sku: 'PM0074554',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271401',
      rtl_batch_array: '6298641, 6271401, 6287457',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1646,
      chnn_stock_retail: 2640,
      sppl_prcp: 2.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'J4HLDEVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4HLDEVE'
    },
    {
      status: 210,
      sku: 'PM0074556',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271405',
      rtl_batch_array: '6271405, 6294795',
      name_search: 'LEFPRAIN',
      name: "Leycesteria formosa 'Purple Rain'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 329,
      chnn_stock_retail: 722,
      sppl_prcp: 2.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97SB71VP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97SB71VP'
    },
    {
      status: 210,
      sku: 'PM0074557',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271407',
      rtl_batch_array: '6271407, 6336256',
      name_search: 'PEALBLUE',
      name: 'Perovskia atriplicifolia Lacey Blue',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 409,
      chnn_stock_retail: 2350,
      sppl_prcp: 2.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEJ7ZK7G',
      statusContent: 'dot',
      hash: 'H1ZY1J7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1ZY1J7W'
    },
    {
      status: 210,
      sku: 'PM0074548',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271393',
      rtl_batch_array: '6271393',
      name_search: 'HYPTARDI',
      name: "Hydrangea paniculata 'Tardiva'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1305,
      chnn_stock_retail: 1305,
      sppl_prcp: 2.349,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R99DD2DK;A78RXRE7;E597Y3X1;W68EKPXR',
      statusContent: 'dot',
      hash: 'BWEHNLZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWEHNLZC'
    },
    {
      status: 210,
      sku: 'PM0074550',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271395',
      rtl_batch_array: '6271395',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 2870,
      chnn_stock_retail: 2870,
      sppl_prcp: 3.122,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'Z3A8R8NY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3A8R8NY'
    },
    {
      status: 210,
      sku: 'PM0067726',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186662',
      rtl_batch_array: '6186662',
      name_search: 'THOAMBAL',
      name: "Thuja occidentalis Anna's Magic Ball",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 4.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVCTTL57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVCTTL57'
    },
    {
      status: 810,
      sku: 'PM0074606',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229600',
      rtl_batch_array: '6229600',
      name_search: 'HOBMEARS',
      name: "Hosta 'Blue Mouse Ears'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 2.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2E1G5LHE;ND8B2WD1;7HK8YPC8;YHV8ZW21;SJ223CCC;1Z9LVYDX;YKWX35TB;4L29LAZP;1T89L37W;DG8HH4DJ;L231S4X5;SRYZBLRV;JRLPYE44;59GZS2S1;CJGLX6BL;7JXT42S1;H8N2X89E;955W7G92',
      statusContent: 'dot',
      hash: 'EK43SHBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EK43SHBW'
    },
    {
      status: 810,
      sku: 'PM0071901',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284547',
      rtl_batch_array: '6284547, 6378379, 6260044',
      name_search: 'ROOPROST',
      name: "Rosmarinus officinalis 'Prostratus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 33,
      chnn_stock_retail: 8014,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L7YDPZTP;A85BYZ6Z;VJHZ1AR1;6TTX2LKX;G96LGV7Y;HCBJPJW1;BXJ152H4;5E3N1XWA;TBNBCP3X;6GNN8W72;N73VWDDZ;JVL7D2KX;X9RXRWGJ;49G7JVWW;K1VRX8YG;H3G5X318;23EASCDH;T2YZ7KD7;LSJWJRRY;THA8RX26;TE1BVWHJ;ZPKT3PGD;YH251HAN;CHC4R2ZH;TD9KH3WJ;RD1L9K97',
      statusContent: 'dot',
      hash: 'R7C49E9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7C49E9Y'
    },
    {
      status: 210,
      sku: 'PM0067695',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6185796',
      rtl_batch_array: '6185796',
      name_search: 'HOGTIARA',
      name: "Hosta 'Golden Tiara'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GP86WYPP;RTH6JASC;2CW4KRAE;67Z7SJES;2P4JJJXN;D39AZSAJ',
      statusContent: 'dot',
      hash: 'NL5LWX97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL5LWX97'
    },
    {
      status: 210,
      sku: 'PM0074607',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234436',
      rtl_batch_array: '6234436',
      name_search: 'PEHYBRID',
      name: 'Petasites hybridus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 531,
      chnn_stock_retail: 531,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18EACDJH;BAHGLEPE',
      statusContent: 'dot',
      hash: 'PDJE1EPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDJE1EPP'
    },
    {
      status: 210,
      sku: 'PM0032859',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244305',
      rtl_batch_array: '6244305, 6254844',
      name_search: 'SECAERUL',
      name: 'Sesleria caerulea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1267,
      chnn_stock_retail: 2767,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B1KBABP9',
      statusContent: 'dot',
      hash: '7AL3EXG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AL3EXG5'
    },
    {
      status: 910,
      sku: 'PM0059282',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      rng_range_identifier: 'H125150C2.5',
      rng_range_description: 'H125 cm 150 cm C2.5',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '9XWR75W1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9XWR75W1'
    },
    {
      status: 210,
      sku: 'PM0032965',
      core_name: 'Plant',
      sppl_ean: '8720664850224',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6090604',
      rtl_batch_array: '6220207, 6090604',
      name_search: 'ACSPASTE',
      name: "Achillea 'Summer Pastels'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 2538,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L73AB3WZ;ZBZS28EX;DD6GNW4C;KCR88KG6',
      statusContent: 'dot',
      hash: '9LR3WKG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LR3WKG8'
    },
    {
      status: 210,
      sku: 'PM0067764',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209578',
      rtl_batch_array: '6209578, 6234645',
      name_search: 'CENMONTA',
      name: 'Centaurea montana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 434,
      chnn_stock_retail: 1088,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z8SGWLY5;WWLPC2CG;RHARGH8J;ZWRGLVY2;X6TPA1YD;25YBY4ZY;KHAN9536;99367WPD;LNGPL6D1;GH8SD7B1;JN4LVBDZ;WV31YL8N;EHL6JH5T',
      statusContent: 'dot',
      hash: 'RB8H6Z4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RB8H6Z4W'
    },
    {
      status: 210,
      sku: 'PM0032858',
      core_name: 'Plant',
      sppl_ean: '8720664808447',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837511',
      rtl_batch_array: '5837511',
      name_search: 'SETMDRED',
      name: "Sedum telephium 'Munstead Dark Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 834,
      chnn_stock_retail: 834,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D2LPE28R;EXB87PY3;ZNCN178S;CNA2Y39B;ZN5BT184;KBZZNLHX;ZZER1VD7;BKR37S5T',
      statusContent: 'dot',
      hash: 'XVPTXLNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVPTXLNX'
    },
    {
      status: 210,
      sku: 'PM0079802',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276587',
      rtl_batch_array: '6276587',
      name_search: 'NADBPINK',
      name: 'Nandina domestica Blush Pink',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D9ZWNXGA;T5XJZBGR;E9VZGB8B;LHJ4JWYD;A2E84DZB;GRAGJBYL;7VVEAEHA;RYNYRYLN',
      statusContent: 'dot',
      hash: 'SALNC7GP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SALNC7GP'
    },
    {
      status: 210,
      sku: 'PM0067763',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6197014',
      rtl_batch_array: '6197014, 6234642',
      name_search: 'CAPENDUL',
      name: 'Carex pendula',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6503,
      chnn_stock_retail: 8301,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8ZBR6W7S;DZ8A7RBS;J1LKHJRB;G66P3WJ5;E6RAB6TD;X1JXZKJV;6YVN7EJ9;N2GLX2EW;ER1TNX9C;L6AABLYN;1JEKEE3W;NRKR5DXX;HP54W3NB',
      statusContent: 'dot',
      hash: 'R6EEE3PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6EEE3PD'
    },
    {
      status: 210,
      sku: 'PM0079803',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276588',
      rtl_batch_array: '6276588',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      statusContent: 'dot',
      hash: 'Z4WEHR36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4WEHR36'
    },
    {
      status: 910,
      sku: 'PM0079804',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'NADMLLIM',
      name: 'Nandina domestica Magical Lemon & Lime',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      imageCore: 'HVY8RD8G;G99N52BD;447JW7Z1;ZJBGXJ9W;K5R4AZL3;6HEXD3D3',
      statusContent: 'dot',
      hash: '9CSK28ZL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9CSK28ZL'
    },
    {
      status: 210,
      sku: 'PM0067792',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301386',
      rtl_batch_array: '6301386, 6284269',
      name_search: 'AGRLADDE',
      name: "Agastache rugosa 'Little Adder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 1821,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TPESGLS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPESGLS7'
    },
    {
      status: 910,
      sku: 'PM0079805',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333377',
      rtl_batch_array: '6333377',
      name_search: 'ALJOMBRE',
      name: 'Albizia julibrissin Ombrella',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 20.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JHBCXJ;ZNPHTD5N;9NZJN8NP',
      statusContent: 'dot',
      hash: '292PXBP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '292PXBP8'
    },
    {
      status: 210,
      sku: 'PM0067768',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301075',
      rtl_batch_array: '6301075, 6216999, 6307954',
      name_search: 'ASNBCBRO',
      name: "Aster novi-belgii 'Crimson Brocade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1536,
      chnn_stock_retail: 2602,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'REYKLVCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REYKLVCA'
    },
    {
      status: 210,
      sku: 'PM0067769',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6309017',
      rtl_batch_array: '6217000, 6307958, 6309017',
      name_search: 'ASNBWLAD',
      name: "Aster novi-belgii 'White Ladies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4392,
      chnn_stock_retail: 5041,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2CJJL3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2CJJL3V'
    },
    {
      status: 210,
      sku: 'PM0067818',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217034',
      rtl_batch_array: '6217034',
      name_search: 'ORDFAIRY',
      name: "Origanum 'Dingle Fairy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZR84L4D1;H9ZW3N59',
      statusContent: 'dot',
      hash: 'A8589RGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8589RGC'
    },
    {
      status: 210,
      sku: 'PM0011786',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6344819',
      rtl_batch_array: '5997966, 6298734, 6344819',
      name_search: 'PHOTWINE',
      name: 'Physocarpus opulifolius Tiny Wine',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 479,
      chnn_stock_retail: 1957,
      sppl_prcp: 3.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CL1TTKDB;8RTXK5C9;6EH61CE3',
      statusContent: 'dot',
      hash: 'YYLXP1HC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYLXP1HC'
    },
    {
      status: 210,
      sku: 'PM0067776',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217021',
      rtl_batch_array: '6217021, 6386085',
      name_search: 'CEPULCHE',
      name: 'Centaurea pulcherrima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C1R1VRRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1R1VRRG'
    },
    {
      status: 210,
      sku: 'PM0067802',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217067',
      rtl_batch_array: '6217067, 6353213',
      name_search: 'SALWISHE',
      name: 'Salvia Love and Wishes',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 298,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5YZK56G;1762ZH63;C9ASTP73;DBSKZTVE;K3AR2KAW;549KPS9B',
      statusContent: 'dot',
      hash: '6ELEVG2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ELEVG2C'
    },
    {
      status: 810,
      sku: 'PM0067816',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217199',
      rtl_batch_array: '6217199',
      name_search: 'LABSILVE',
      name: "Lavatera 'Barnsley Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NZVNZWAJ;PW2ZTR2W',
      statusContent: 'dot',
      hash: 'JCY672XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCY672XV'
    },
    {
      status: 910,
      sku: 'PM0079806',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333378',
      rtl_batch_array: '6333378',
      name_search: 'ALJSCHOC',
      name: "Albizia julibrissin 'Summer Chocolate'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 20.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '81KLXWEX;8R6LRWVR;2YBT1KW5;XLB7TLLD;K3ZYB766;Z4A9VVJ3',
      statusContent: 'dot',
      hash: 'X5B1EEVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5B1EEVD'
    },
    {
      status: 210,
      sku: 'PM0071644',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6230982',
      rtl_batch_array: '6230982, 6309024',
      name_search: 'DIGRANDI',
      name: 'Digitalis grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 435,
      chnn_stock_retail: 561,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RR7B2PVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR7B2PVT'
    },
    {
      status: 210,
      sku: 'PM0079807',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279217',
      rtl_batch_array: '6279217',
      name_search: 'PHORUBEL',
      name: "Physocarpus opulifolius 'Rubella'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 6.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1WLGSRZ;YSRHSZ9A;E5T1XWHG;2C6DBA1H;RTWR75R7',
      statusContent: 'dot',
      hash: 'LSH5DDS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSH5DDS7'
    },
    {
      status: 210,
      sku: 'PM0079808',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279218',
      rtl_batch_array: '6279218',
      name_search: 'RHWROSES',
      name: "Rhododendron 'Wine and Roses'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 9.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HABNBDPG;7T9TD92K;BKGPNDXG;RXVVAL16;K8W6R79K;WT263AST;59251Z7L;WVXDVNNZ',
      statusContent: 'dot',
      hash: 'Z52WHBVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z52WHBVZ'
    },
    {
      status: 210,
      sku: 'PM0079809',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298987',
      rtl_batch_array: '6298987',
      name_search: 'ACPJSCHW',
      name: "Acer palmatum 'Jerre Schwartz'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B6E1SEV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6E1SEV7'
    },
    {
      status: 210,
      sku: 'PM0074559',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271409',
      rtl_batch_array: '6271409',
      name_search: 'PHFLFENN',
      name: "Photinia fraseri 'Little Fenna'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 3.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R59W3PPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R59W3PPW'
    },
    {
      status: 210,
      sku: 'PM0067759',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234577',
      rtl_batch_array: '6234577',
      name_search: 'ASARGOET',
      name: "Aster amellus 'Rudolf Goethe'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1725,
      chnn_stock_retail: 1725,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7B9R11B',
      statusContent: 'dot',
      hash: 'XCEK2VL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCEK2VL1'
    },
    {
      status: 210,
      sku: 'PM0079810',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298988',
      rtl_batch_array: '6298988',
      name_search: 'ACPLPRIN',
      name: "Acer palmatum 'Little Princess'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VXJ9CKKW;59STYAKN',
      statusContent: 'dot',
      hash: '547N7519',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '547N7519'
    },
    {
      status: 210,
      sku: 'PM0067824',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5214469',
      rtl_batch_array: '5214469',
      name_search: 'EUJSIEBE',
      name: "Euonymus japonicus 'Siebe'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TEEEYWRZ;X64BZPGN',
      imageBatch: 'CHZE2Z83',
      statusContent: 'dot',
      hash: 'ZP1L5KVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP1L5KVC'
    },
    {
      status: 210,
      sku: 'PM0079811',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298989',
      rtl_batch_array: '6298989',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: '55ERRWY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55ERRWY4'
    },
    {
      status: 810,
      sku: 'PM0067799',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187609',
      rtl_batch_array: '6187609',
      name_search: 'PHDBMOON',
      name: "Phlox divaricata 'Blue Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BYNNG1HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYNNG1HH'
    },
    {
      status: 210,
      sku: 'PM0067801',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6308733',
      rtl_batch_array: '6217055, 6308733',
      name_search: 'POYBPURP',
      name: 'Polemonium yezoense Bressingham Purple',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 415,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BSAESASK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSAESASK'
    },
    {
      status: 210,
      sku: 'PM0067820',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217045',
      rtl_batch_array: '6353148, 6217045',
      name_search: 'PEAVESUV',
      name: "Persicaria a. 'Vesuvius'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1848,
      chnn_stock_retail: 1944,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEZPYRP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEZPYRP8'
    },
    {
      status: 210,
      sku: 'PM0067800',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217051',
      rtl_batch_array: '6353165, 6217051',
      name_search: 'PHECULLU',
      name: "Phlox (P) 'Eva Cullum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 632,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KABWGP2Z;D347WA19',
      statusContent: 'dot',
      hash: '89YR7GTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89YR7GTJ'
    },
    {
      status: 210,
      sku: 'PM0067806',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6255895',
      rtl_batch_array: '6254420, 6255895, 6317835, 6353464, 6383377',
      name_search: 'STMHUMME',
      name: "Stachys monieri 'Hummelo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 11423,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5LVGB77R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LVGB77R'
    },
    {
      status: 210,
      sku: 'PM0067798',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6309016',
      rtl_batch_array: '6267602, 6307952, 6308644, 6309016',
      name_search: 'ASMCASSI',
      name: "Aster (U) 'Monte Cassino'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3576,
      chnn_stock_retail: 5792,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKPK254B;7PLW27PY',
      statusContent: 'dot',
      hash: 'AA3VAT52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA3VAT52'
    },
    {
      status: 210,
      sku: 'PM0067803',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217065',
      rtl_batch_array: '6217065, 6353206',
      name_search: 'SACBLUE',
      name: "Salvia 'Crystal Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 163,
      chnn_stock_retail: 327,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YX5H56J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YX5H56J7'
    },
    {
      status: 210,
      sku: 'PM0067766',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234692',
      rtl_batch_array: '6234692',
      name_search: 'ECMYELLO',
      name: "Echinacea 'Mellow Yellows'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NCVBG419',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCVBG419'
    },
    {
      status: 210,
      sku: 'PM0067765',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254696',
      rtl_batch_array: '6254696',
      name_search: 'DIDBRILL',
      name: "Dianthus deltoides 'Brilliant'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J4NB16R4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4NB16R4'
    },
    {
      status: 210,
      sku: 'PM0074565',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348850',
      rtl_batch_array: '6348850',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 3.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: '5HE7CZXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HE7CZXE'
    },
    {
      status: 210,
      sku: 'PM0079798',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6324729',
      rtl_batch_array: '6324729',
      name_search: 'BRMALEXA',
      name: "Brunnera macr. 'Alexandria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7TS25WN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7TS25WN'
    },
    {
      status: 210,
      sku: 'PM0079536',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321501',
      rtl_batch_array: '6321501',
      name_search: 'ACMOLLIS',
      name: 'Acanthus mollis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4150,
      chnn_stock_retail: 4150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72ZXRJN1;GTB79R6C;52561Y7L',
      statusContent: 'dot',
      hash: 'RZLP2SC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZLP2SC7'
    },
    {
      status: 210,
      sku: 'PM0079799',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6297641',
      rtl_batch_array: '6297641',
      name_search: 'MORMBERR',
      name: 'Morus rotundiloba Mojo Berry',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DVSSXJNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVSSXJNZ'
    },
    {
      status: 210,
      sku: 'PM0079812',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298990',
      rtl_batch_array: '6298990',
      name_search: 'ACPOLACE',
      name: "Acer palmatum 'Oriental Lace'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TG75XXDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TG75XXDC'
    },
    {
      status: 210,
      sku: 'PM0079800',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276582',
      rtl_batch_array: '6276582',
      name_search: 'COCLILLA',
      name: "Cotinus coggygria 'Lilla'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WPLH2NKK;P1DCGV94;PRZ6KVBN;SJBPG26A;G5VCRB8C;VHYA3S13;9DJ98K7G;7YPNVDV5',
      statusContent: 'dot',
      hash: '5HA568S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HA568S1'
    },
    {
      status: 810,
      sku: 'PM0071833',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239629',
      rtl_batch_array: '6239629',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 5.193,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'JYV1DLN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYV1DLN1'
    },
    {
      status: 210,
      sku: 'PM0079801',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276585',
      rtl_batch_array: '6276585',
      name_search: 'MAELSCAR',
      name: "Mahonia euryb.'Little Soft Caress'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZA9S23V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZA9S23V'
    },
    {
      status: 210,
      sku: 'PM0071903',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314912',
      rtl_batch_array: '6314912',
      name_search: 'LOPERICL',
      name: 'Lonicera periclymenum',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYGLHEPG;61EXCRG3;XS5W43AY;VC6L2AA1;HARWJ729',
      statusContent: 'dot',
      hash: 'HGNDTVRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGNDTVRT'
    },
    {
      status: 910,
      sku: 'PM0079813',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPREDWI',
      name: "Acer palmatum 'Redwine'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      statusContent: 'dot',
      hash: 'STKG6RTR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'STKG6RTR'
    },
    {
      status: 910,
      sku: 'PM0079814',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMMARS',
      name: "Mangave 'Mission to Mars'",
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      imageCore: 'KG3PH9YL;J4RSTB4P;VHCZATN1;WA9ARJ1D',
      statusContent: 'dot',
      hash: 'KD26EDL9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KD26EDL9'
    },
    {
      status: 210,
      sku: 'PM0067819',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348966',
      rtl_batch_array: '6295788, 6353143, 6348966',
      name_search: 'PEAPELEP',
      name: "Persicaria amplexicaulis 'Pink Elephant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1118,
      chnn_stock_retail: 3613,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6JTJ5E;HDWC1Y3H;CKZL4ZHJ',
      statusContent: 'dot',
      hash: '9NLXSY4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NLXSY4W'
    },
    {
      status: 910,
      sku: 'PM0079815',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAPEXPRE',
      name: "Mangave 'Pineapple Express'",
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      imageCore: '9P6E9GV8;PZLRXP18;CSRAV5SJ;X1J68GKH',
      statusContent: 'dot',
      hash: 'V257H9CE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V257H9CE'
    },
    {
      status: 210,
      sku: 'PM0074560',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271412',
      rtl_batch_array: '6314267, 6271412',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2068,
      chnn_stock_retail: 3197,
      sppl_prcp: 2.338,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'HBYD6DNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBYD6DNX'
    },
    {
      status: 210,
      sku: 'PM0071641',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308485',
      rtl_batch_array: '6315037, 6259384, 6308485',
      name_search: 'ANHSCHAR',
      name: "Anemone hupehensis 'September Charm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 7835,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYA7S356;K4GEXNBW;RX7Z4A7B;5RVJXA14;8J2D3GSL;TEGRHXT8;N4XSZ13K;KXCRH8ZS;XHEGBS6C;G53YNSNJ',
      statusContent: 'dot',
      hash: 'TETRPNAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TETRPNAX'
    },
    {
      status: 910,
      sku: 'PM0079816',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MASFOX',
      name: "Mangave 'Silver Fox'",
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      statusContent: 'dot',
      hash: 'XYZNZP8P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XYZNZP8P'
    },
    {
      status: 210,
      sku: 'PM0079817',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298996',
      rtl_batch_array: '6298996',
      name_search: 'MIFMAGN',
      name: "Michelia 'Fairy Magnolia'",
      sppl_size_code: '060070C3',
      rng_range_identifier: 'H060070C3',
      rng_range_description: 'H60 cm 70 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5T4TKWW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T4TKWW6'
    },
    {
      status: 210,
      sku: 'PM0079818',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333576',
      rtl_batch_array: '6333576',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: '8VDPZ4TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VDPZ4TP'
    },
    {
      status: 810,
      sku: 'PM0079819',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333577',
      rtl_batch_array: '6333577',
      name_search: 'COAMIRAC',
      name: 'Cornus alba Miracle',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.884,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KCKDGHE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCKDGHE4'
    },
    {
      status: 210,
      sku: 'PM0079820',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333578',
      rtl_batch_array: '6333578',
      name_search: 'COSWBEAU',
      name: "Cornus sanguinea 'Winter Beauty'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 3.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRDN1TCW;2Z3DSCS8;TZPXD2G1;LYJENPAZ;AYL13WRY;CZ8H6XYY',
      statusContent: 'dot',
      hash: '1KBRRDWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KBRRDWA'
    },
    {
      status: 210,
      sku: 'PM0079821',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333581',
      rtl_batch_array: '6333581',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 9.911,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JD9YHA89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JD9YHA89'
    },
    {
      status: 210,
      sku: 'PM0079822',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333582',
      rtl_batch_array: '6333582',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 9.911,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: '1N9539LA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1N9539LA'
    },
    {
      status: 210,
      sku: 'PM0032637',
      core_name: 'Plant',
      sppl_ean: '8720664687288',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4905159',
      rtl_batch_array: '4905159',
      name_search: 'DIBSTREA',
      name: 'Dianella Blue Stream',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 468,
      chnn_stock_retail: 468,
      sppl_prcp: 5.304,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '29J36B84',
      statusContent: 'dot',
      hash: '593N3SJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '593N3SJG'
    },
    {
      status: 210,
      sku: 'PM0079823',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333583',
      rtl_batch_array: '6333583',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'ANRX32SH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANRX32SH'
    },
    {
      status: 210,
      sku: 'PM0079824',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333584',
      rtl_batch_array: '6333584',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'JS9BV57L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JS9BV57L'
    },
    {
      status: 210,
      sku: 'PM0079825',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333585',
      rtl_batch_array: '6333585',
      name_search: 'WEMARJOR',
      name: "Weigela 'Marjorie'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TK1RHT8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TK1RHT8W'
    },
    {
      status: 210,
      sku: 'PM0079826',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288469',
      rtl_batch_array: '6288469',
      name_search: 'ACPBMAIK',
      name: "Acer palmatum 'Beni-maiko'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JAA1EAER;XC6GY87T;CZEL4767;YSHNBSTS;KJYV9PJG;G27ZBKTG;1Z8RWKED;XW2Y7YY8;8VNCA163;Y8DGZH4W;SGZZVX1Z;ZYL7BGNZ;Y64VNZE1',
      statusContent: 'dot',
      hash: 'XN37GGBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XN37GGBZ'
    },
    {
      status: 210,
      sku: 'PM0079827',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288470',
      rtl_batch_array: '6288470',
      name_search: 'ACPBHOO',
      name: "Acer palmatum 'Bi-hoo'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 9.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJNPS2X8;N8DBB3WB;H99XGZKA;EWCKTXEE;GN5LG2R9;9ZH1W5ZS;G3HA2JSE;7CDYJEYJ;756P4RLC;LXS58H13;SEYVCP3P;YNDYHGHA;HVABCEYG;RJ1LLLED',
      statusContent: 'dot',
      hash: 'TJYDXE48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJYDXE48'
    },
    {
      status: 210,
      sku: 'PM0079828',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288471',
      rtl_batch_array: '6288471',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: '28YLW4W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28YLW4W5'
    },
    {
      status: 210,
      sku: 'PM0079829',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288472',
      rtl_batch_array: '6288472',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'W8RD39PL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8RD39PL'
    },
    {
      status: 210,
      sku: 'PM0079830',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288473',
      rtl_batch_array: '6288473',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'SELZSLV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SELZSLV3'
    },
    {
      status: 210,
      sku: 'PM0079831',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348812',
      rtl_batch_array: '6288475, 6348812',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2521,
      sppl_prcp: 0.144,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: 'ZTHHD92N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTHHD92N'
    },
    {
      status: 210,
      sku: 'PM0079832',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288476',
      rtl_batch_array: '6288476',
      name_search: 'SYBDPURP',
      name: 'Syringa Bloomerang Dark Purple',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXJ3G1Z2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXJ3G1Z2'
    },
    {
      status: 210,
      sku: 'PM0067785',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6363189',
      rtl_batch_array: '6350164, 6202292, 6217139, 6363189',
      name_search: 'EUMYRSIN',
      name: 'Euphorbia myrsinites',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4476,
      chnn_stock_retail: 6593,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5PEP35DC;CXBZPEGY;7WV5SJ9C;1WN7TGX7;XW4ZXLH7;K1PEESEP;13V1VX6T;K5JWZWS6;Z2L8SPVJ;4Z63HR6D;HNJ77KA7;LVYCAZ91;GD9L2ZV7;PWEY84N8;XC5DXKY8;8YHL47CL;4CZBLH1V',
      statusContent: 'dot',
      hash: 'PDBHH23W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDBHH23W'
    },
    {
      status: 210,
      sku: 'PM0032846',
      core_name: 'Plant',
      sppl_ean: '8720664801738',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837487',
      rtl_batch_array: '5837487',
      name_search: 'PHCSTRIP',
      name: 'Phlox (S) Candy Stripes',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1450,
      chnn_stock_retail: 1450,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4Z281VAV;TETBH8GH;WHWCYWHG;WLZLNL76;NKL54L69;NBJ2AYG4;8DZ61RGS;KN21ZDRW;8YYWGJ2W;2SHRP78K',
      statusContent: 'dot',
      hash: 'VKNZG5J2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKNZG5J2'
    },
    {
      status: 210,
      sku: 'PM0079833',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288477',
      rtl_batch_array: '6288477',
      name_search: 'SYBPPERF',
      name: 'Syringa Bloomerang Pink Perfume',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZPN8NP6C;KLVKZV6N;5VYAPYK3;HX7AL2YN;1YRP1XDA;JEEA7R47;EGL7L4KS',
      statusContent: 'dot',
      hash: 'TCETXT8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCETXT8R'
    },
    {
      status: 210,
      sku: 'PM0079834',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299849',
      rtl_batch_array: '6299849',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 256,
      chnn_stock_retail: 256,
      sppl_prcp: 1.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: 'W9K7EZHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9K7EZHC'
    },
    {
      status: 210,
      sku: 'PM0079838',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301328',
      rtl_batch_array: '6301328',
      name_search: 'ERISOMME',
      name: "Erigeron 'Sommerneuschnee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 134,
      chnn_stock_retail: 134,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S3163KDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3163KDZ'
    },
    {
      status: 210,
      sku: 'PM0067775',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217016',
      rtl_batch_array: '6217016',
      name_search: 'CARLBBLU',
      name: "Carex laxiculmis 'Bunny Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDWVS51N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDWVS51N'
    },
    {
      status: 210,
      sku: 'PM0079839',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301341',
      rtl_batch_array: '6301341',
      name_search: 'FIPELEGA',
      name: "Filipendula purpurea 'Elegans'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYH563HS;W68T4AP1',
      statusContent: 'dot',
      hash: 'WKSL4B29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKSL4B29'
    },
    {
      status: 210,
      sku: 'PM0032849',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6234445',
      rtl_batch_array: '5837490, 6234445',
      name_search: 'PHPBEAUT',
      name: "Phlox (S) 'Purple Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 1385,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46V5WG5Y;REDZ32WZ',
      statusContent: 'dot',
      hash: 'R2RDCCDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2RDCCDT'
    },
    {
      status: 210,
      sku: 'PM0079840',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348945',
      rtl_batch_array: '6369227, 6301378, 6348945',
      name_search: 'ACSWPEAR',
      name: "Actaea simplex 'White Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2474,
      chnn_stock_retail: 5377,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BV6BHR4A;JPB3NWGK',
      statusContent: 'dot',
      hash: 'X9Z5YD3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9Z5YD3V'
    },
    {
      status: 210,
      sku: 'PM0079841',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301417',
      rtl_batch_array: '6301417',
      name_search: 'ARALSILV',
      name: "Artemisia absinthium 'Lambrook Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKGESKDV;6RL55ZA7;CHNLTLTL;9ZZKNRHV;8YWD47PP;8T4WN2TB;XR9DT3V7;PZ5S2XR7;8N77WPZ1;7B9DYK7S;87YV5VZP',
      statusContent: 'dot',
      hash: 'DPNBD6DW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPNBD6DW'
    },
    {
      status: 210,
      sku: 'PM0079842',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339767',
      rtl_batch_array: '6301440, 6339767',
      name_search: 'ASNARSIE',
      name: "Aster novae-angliae 'Rosa Sieger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWY7VENE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWY7VENE'
    },
    {
      status: 210,
      sku: 'PM0067832',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188645',
      rtl_batch_array: '6188645',
      name_search: 'HEFVDREA',
      name: "Heuchera 'Frosted Violet Dream'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V925EW27',
      statusContent: 'dot',
      hash: '7ZJ6GWNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZJ6GWNN'
    },
    {
      status: 210,
      sku: 'PM0079843',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349887',
      rtl_batch_array: '6349887, 6301478',
      name_search: 'CAGEMERA',
      name: "Campanula glomerata 'Emerald'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 143,
      chnn_stock_retail: 223,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D5VXDH2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5VXDH2B'
    },
    {
      status: 210,
      sku: 'PM0033487',
      core_name: 'Plant',
      sppl_ean: '8720626380035',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849418',
      rtl_batch_array: '5849418',
      name_search: 'ACMICROP',
      name: 'Acaena microphylla',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7D2ZTJHD',
      imageBatch: 'JC6NH81H',
      statusContent: 'dot',
      hash: '7KZ8YSSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KZ8YSSZ'
    },
    {
      status: 210,
      sku: 'PM0032850',
      core_name: 'Plant',
      sppl_ean: '8720349425174',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837491',
      rtl_batch_array: '5837491, 6254811',
      name_search: 'PHSTARFI',
      name: "Phlox (P) 'Starfire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 670,
      chnn_stock_retail: 1011,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEKW2ST5',
      statusContent: 'dot',
      hash: '7LWDPX3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LWDPX3H'
    },
    {
      status: 810,
      sku: 'PM0032603',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353541',
      rtl_batch_array: '6353541',
      name_search: 'VEROYALT',
      name: "Verbascum 'Royalty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYSYYR39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYSYYR39'
    },
    {
      status: 210,
      sku: 'PM0067837',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244276',
      rtl_batch_array: '6244276, 6334351',
      name_search: 'CERUBER',
      name: 'Centranthus ruber',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 599,
      chnn_stock_retail: 1519,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2W4VX6YW;NN59G2AN;7HD2CLYH',
      statusContent: 'dot',
      hash: 'V328456N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V328456N'
    },
    {
      status: 810,
      sku: 'PM0067821',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6204265',
      rtl_batch_array: '6204265, 6319726',
      name_search: 'PEMRDRAG',
      name: "Persicaria microcephala 'Red Dragon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 20,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A7Y2SEBR;NRNP873N;H5TED6YE',
      statusContent: 'dot',
      hash: 'EH49A6P3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH49A6P3'
    },
    {
      status: 210,
      sku: 'PM0071642',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333179',
      rtl_batch_array: '6349572, 6333179',
      name_search: 'ARDRACUN',
      name: 'Artemisia dracunculus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 582,
      chnn_stock_retail: 859,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EERY9TWZ;PECZN59L;PPPA2ZS8;2WC8LR9H;C6TJ86NP;8Y9EG7NA;J222KWAR;AVYBXX7R;8C53BWGW;16E31HRZ;D28NPA3S;Z6D45CS7',
      statusContent: 'dot',
      hash: 'H93WR2RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H93WR2RT'
    },
    {
      status: 210,
      sku: 'PM0074562',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271414',
      rtl_batch_array: '6271414',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 4.746,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'NHDSYZD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHDSYZD3'
    },
    {
      status: 910,
      sku: 'PM0067826',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6187758',
      rtl_batch_array: '6187758, 6274208',
      name_search: 'IRSBJOY',
      name: "Iris sibirica 'Bundle of Joy'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 8,
      chnn_stock_retail: 1008,
      sppl_order_minimum: 1,
      sppl_prcp: 1.716,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BNYC25B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BNYC25B'
    },
    {
      status: 210,
      sku: 'PM0074564',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356683',
      rtl_batch_array: '6271417, 6356683',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 273,
      chnn_stock_retail: 4010,
      sppl_prcp: 4.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'NZERH54Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZERH54Z'
    },
    {
      status: 210,
      sku: 'PM0074566',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271419',
      rtl_batch_array: '6271419',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '020C26L30PL',
      rng_range_identifier: 'H020020C2',
      rng_range_description: 'H20 cm 20 cm C2',
      sppl_stock_available: 1494,
      chnn_stock_retail: 1494,
      sppl_prcp: 2.094,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'GH6E8T4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GH6E8T4J'
    },
    {
      status: 210,
      sku: 'PM0020323',
      core_name: 'Plant',
      sppl_ean: '8720349428373',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5334992',
      rtl_batch_array: '5334992, 6205318',
      name_search: 'SANSDBLU',
      name: 'Salvia nemorosa Sensation Deep Blue',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6910,
      chnn_stock_retail: 7910,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'XLNBN1Y3',
      statusContent: 'dot',
      hash: 'DW54P65J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DW54P65J'
    },
    {
      status: 810,
      sku: 'PM0074567',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271421',
      rtl_batch_array: '6271421',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '020C26L30PL',
      rng_range_identifier: 'H020020C2',
      rng_range_description: 'H20 cm 20 cm C2',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_prcp: 2.094,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'C9B45L58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9B45L58'
    },
    {
      status: 210,
      sku: 'PM0074568',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271422',
      rtl_batch_array: '6271422',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1570,
      chnn_stock_retail: 1570,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: 'ZPPG4VHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPPG4VHH'
    },
    {
      status: 210,
      sku: 'PM0074569',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271423',
      rtl_batch_array: '6271423',
      name_search: 'COSPFEAT',
      name: "Cortaderia selloana 'Pink Feather'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_prcp: 3.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X365JT59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X365JT59'
    },
    {
      status: 210,
      sku: 'PM0067825',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5304248',
      rtl_batch_array: '5304248',
      name_search: 'EUJMARIE',
      name: "Euonymus japonicus 'Marieke'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '153JCHAG',
      statusContent: 'dot',
      hash: 'C5755HRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5755HRV'
    },
    {
      status: 210,
      sku: 'PM0074609',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348946',
      rtl_batch_array: '6268707, 6348946',
      name_search: 'ANFKNICK',
      name: 'Anemone Frilly Knickers',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 411,
      chnn_stock_retail: 636,
      sppl_order_minimum: 3,
      sppl_prcp: 1.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8PPKB6G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PPKB6G4'
    },
    {
      status: 210,
      sku: 'PM0067771',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217007',
      rtl_batch_array: '6217007',
      name_search: 'BAPSEUDO',
      name: 'Ballota pseudodictamnus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EB8YN66R;YENBHDBS;ADHY6JSZ;ES7LEDNG',
      statusContent: 'dot',
      hash: 'XA1HN2TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA1HN2TV'
    },
    {
      status: 210,
      sku: 'PM0067778',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196753',
      rtl_batch_array: '6196753, 6202283, 6217102',
      name_search: 'DEBKNIGH',
      name: "Delphinium (P) 'Black Knight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5789,
      chnn_stock_retail: 6229,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NDY4YACC;N9SS16PZ;82N8G81A;TRYN5EEB;7XHRDBPR;6X5EDLN8;KNAPVBPA;BVE2V7VK;P1EZSB4H;G2GR3D61',
      statusContent: 'dot',
      hash: '8TXW45HL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TXW45HL'
    },
    {
      status: 210,
      sku: 'PM0074570',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271424',
      rtl_batch_array: '6271424',
      name_search: 'COSWFEAT',
      name: "Cortaderia selloana 'White Feather'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 3.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6VWGJZZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VWGJZZH'
    },
    {
      status: 910,
      sku: 'PM0074571',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MIPURPUR',
      name: "Miscanthus 'Purpurascens'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: '6SBCGC6J;X9ZBSJYT;KRPLLADW',
      statusContent: 'dot',
      hash: 'BJHB32DZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BJHB32DZ'
    },
    {
      status: 210,
      sku: 'PM0074572',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271434',
      rtl_batch_array: '6271434',
      name_search: 'MOAKFOER',
      name: "Molinia arundinacea 'Karl Foerster'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RYY9XHX8;4Z1GG7NG;2EVDY2G3;DAGD582L;CE33VY79;VB9X6CZ4;Z1E1584H;CBATDK6R;E8PYECE4;7SVVWBK2;H9ZL2RJS;J3KBTD3S;W4DSYPB1',
      statusContent: 'dot',
      hash: 'GB7NGBX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB7NGBX5'
    },
    {
      status: 210,
      sku: 'PM0074558',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271408',
      rtl_batch_array: '6271408',
      name_search: 'PHFCAMIL',
      name: "Photinia fraseri 'Camilvy'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 917,
      chnn_stock_retail: 917,
      sppl_prcp: 2.864,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5GEWD2X;Y96SS7T6;H3XSZSRY;5937KDPN;HA13T26N',
      statusContent: 'dot',
      hash: '7VSRGYSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VSRGYSD'
    },
    {
      status: 210,
      sku: 'PM0074561',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271413',
      rtl_batch_array: '6271413',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 2.338,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'D8943L26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8943L26'
    },
    {
      status: 210,
      sku: 'PM0074563',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271415',
      rtl_batch_array: '6298741, 6271415',
      name_search: 'PRCUNEAT',
      name: 'Prostanthera cuneata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 525,
      chnn_stock_retail: 1350,
      sppl_prcp: 1.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DN1JDC8L;TWR2AGL2;LEYYX3DL;CB41Z9N2;94ED8D3P;99TVW9BA;JC8W3LLB;G32B49NJ;NCXKV3NZ;5ZHVSY42',
      statusContent: 'dot',
      hash: 'W1YVX7XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1YVX7XB'
    },
    {
      status: 210,
      sku: 'PM0067777',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217101',
      rtl_batch_array: '6217101',
      name_search: 'DECJDGAR',
      name: "Delosperma cooperi 'Jewel of Desert Garnet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAX3GCKE;9PEX82SA;48BGHRT2;9GJW9Y4L',
      statusContent: 'dot',
      hash: 'A5H7J2GZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5H7J2GZ'
    },
    {
      status: 910,
      sku: 'PM0074610',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIIRASPB',
      name: 'Digitalis Illumination Raspberry',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'NKE39JRW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NKE39JRW'
    },
    {
      status: 210,
      sku: 'PM0004134',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243719',
      rtl_batch_array: '6243719',
      name_search: 'SOSTRAHL',
      name: "Solidago 'Strahlenkrone'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1812,
      chnn_stock_retail: 1812,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KE9SWH4W;V1W692TT;CKLBHZD3;9CARWTY3',
      statusContent: 'dot',
      hash: '488SZ9XS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '488SZ9XS'
    },
    {
      status: 210,
      sku: 'PM0079910',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256439',
      rtl_batch_array: '6256439',
      name_search: 'PHMVOLCA',
      name: 'Photinia Magical Volcano',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5JPT2NG;KSNC8813',
      statusContent: 'dot',
      hash: 'CLVD3BDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CLVD3BDY'
    },
    {
      status: 210,
      sku: 'PM0079911',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258114',
      rtl_batch_array: '6258114',
      name_search: 'ALSSUNRI',
      name: 'Aloe Safari Sunrise',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 3.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y12L58B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y12L58B3'
    },
    {
      status: 210,
      sku: 'PM0082335',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350173',
      rtl_batch_array: '6350173',
      name_search: 'FURREITZ',
      name: 'Fuchsia regia reitzii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NY15CJLJ;H1EBYXZC;E6K1HBB6;J2VSBLTD;TS4P69EY;2E9X819C;SLXSPB34;XB65W1G4;BHG6JNET;WV49CTN8;V6HE4B84;4D9DEDKD;997A4AXX;PEX2T5Y7',
      statusContent: 'dot',
      hash: 'WLXGYTCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLXGYTCT'
    },
    {
      status: 810,
      sku: 'PM0074573',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272120',
      rtl_batch_array: '6272120',
      name_search: 'MYGALE',
      name: 'Myrica gale',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXV5S2Y2',
      statusContent: 'dot',
      hash: 'L3PRSV4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3PRSV4R'
    },
    {
      status: 210,
      sku: 'PM0067839',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260706',
      rtl_batch_array: '6260706, 6356980',
      name_search: 'PEAALBA',
      name: "Persicaria amplexicaulis 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1586,
      chnn_stock_retail: 2480,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CWDJ47GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWDJ47GW'
    },
    {
      status: 210,
      sku: 'PM0079844',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349888',
      rtl_batch_array: '6349888, 6301479',
      name_search: 'CAGJELLI',
      name: "Campanula glomerata 'Joan Elliott'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 228,
      chnn_stock_retail: 308,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6P69133G;5EHPWJ3N',
      statusContent: 'dot',
      hash: 'HP57BDG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP57BDG4'
    },
    {
      status: 810,
      sku: 'PM0079845',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301482',
      rtl_batch_array: '6301482',
      name_search: 'CALGLOAM',
      name: "Campanula latifolia 'Gloaming'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9K7XT2XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9K7XT2XB'
    },
    {
      status: 210,
      sku: 'PM0079846',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349059',
      rtl_batch_array: '6349059, 6301483, 6339768, 6164385',
      name_search: 'CAMPPALB',
      name: "Campanula persicifolia 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 13942,
      chnn_stock_retail: 14723,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BGLXHX8E;B61SXRC3;HXD7CVDA;2Z4LJT96;67P1AYA3;82Z5HYG8;29EDRC8W;BCY6ST18;2PTJEH17;4GNNL8KC;YRX7WRBE;SDGBPH87',
      statusContent: 'dot',
      hash: 'DYCN7ESY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYCN7ESY'
    },
    {
      status: 210,
      sku: 'PM0079847',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220700',
      rtl_batch_array: '6301484, 6220700',
      name_search: 'CAPRVARI',
      name: "Campanula portenschlagiana 'Resholt Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 611,
      chnn_stock_retail: 658,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XDX86S4C',
      statusContent: 'dot',
      hash: '8PDLXC31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PDLXC31'
    },
    {
      status: 810,
      sku: 'PM0079848',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301485',
      rtl_batch_array: '6301485',
      name_search: 'CAPTTEPP',
      name: "Campanula poscharskyana 'Templiner Teppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RA4TPHPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA4TPHPH'
    },
    {
      status: 210,
      sku: 'PM0079850',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349152',
      rtl_batch_array: '6349152, 6301878',
      name_search: 'THELFIN',
      name: "Thymus 'Elfin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 940,
      chnn_stock_retail: 1059,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9L46264Z;4916BVVB;811LA4Z2;AWEVK8RR;TVEAL2HY;WEJ3GP5T;HP4X3N83;N5KXPGY6;7AXS1LD4;2188ZLL5',
      statusContent: 'dot',
      hash: 'XJVG5522',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJVG5522'
    },
    {
      status: 210,
      sku: 'PM0079851',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301879',
      rtl_batch_array: '6301879',
      name_search: 'THPRAECO',
      name: 'Thymus praecox',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '278DNGVN;GJ6P635J;XVD76HXV',
      statusContent: 'dot',
      hash: 'HGC5J8XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGC5J8XR'
    },
    {
      status: 210,
      sku: 'PM0079852',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339723',
      rtl_batch_array: '6302327, 6339723',
      name_search: 'AGAEIGHT',
      name: "Agastache 'After Eight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 520,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XZYLC9CG;23BVBXDG',
      statusContent: 'dot',
      hash: '1G8AYTZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G8AYTZJ'
    },
    {
      status: 210,
      sku: 'PM0079853',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302348',
      rtl_batch_array: '6302348',
      name_search: 'ANRIVULA',
      name: 'Anemone rivularis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHTWEEBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHTWEEBG'
    },
    {
      status: 210,
      sku: 'PM0079854',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295540',
      rtl_batch_array: '6295540',
      name_search: 'ECSPINK',
      name: "Echinacea 'Sensation Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AVPWDTD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVPWDTD5'
    },
    {
      status: 210,
      sku: 'PM0022689',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184037',
      rtl_batch_array: '6184037',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '175200C34',
      rng_range_identifier: 'H175200C3.5',
      rng_range_description: 'H175 cm 200 cm C3.5',
      sppl_stock_available: 1490,
      chnn_stock_retail: 1490,
      sppl_prcp: 4.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '6H21PN5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H21PN5E'
    },
    {
      status: 810,
      sku: 'PM0079856',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302382',
      rtl_batch_array: '6302382',
      name_search: 'ASGDARK',
      name: "Aster 'Glow in the Dark'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6741Z91L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6741Z91L'
    },
    {
      status: 210,
      sku: 'PM0079857',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302383',
      rtl_batch_array: '6302383',
      name_search: 'ASHERBST',
      name: "Aster (D) 'Herbstpurzel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 101,
      chnn_stock_retail: 101,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YV1V6PJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YV1V6PJ8'
    },
    {
      status: 210,
      sku: 'PM0079858',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302387',
      rtl_batch_array: '6302387',
      name_search: 'ASMITTEL',
      name: "Aster (D) 'Mittelmeer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '65JY3R7X;4H6Y95AX;YTSXK5H3;W832J12A;4BKWL9AL;6Z3NN6DT',
      statusContent: 'dot',
      hash: 'KNZZ4PY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNZZ4PY7'
    },
    {
      status: 810,
      sku: 'PM0079859',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302389',
      rtl_batch_array: '6302389',
      name_search: 'ASTNARUB',
      name: "Aster novae-angliae 'Rubinschatz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AR7RAYW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR7RAYW5'
    },
    {
      status: 210,
      sku: 'PM0079860',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302420',
      rtl_batch_array: '6302420',
      name_search: 'BOASNOWB',
      name: "Boltonia asteroides 'Snowbank'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WYRHLY4J;G861WHLZ;J999Y8VC;SPXN8RSY;ZTEWBTEZ;EX49XR8H;LGPK9W1P',
      statusContent: 'dot',
      hash: 'VRKZAD4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRKZAD4G'
    },
    {
      status: 210,
      sku: 'PM0071647',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6309010',
      rtl_batch_array: '6231004, 6350673, 6309010, 6302296',
      name_search: 'MECILIAT',
      name: 'Melica ciliata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 841,
      chnn_stock_retail: 3967,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '451NDZR4;REA562ND;J3EJW5P2;4XTRB6C3',
      statusContent: 'dot',
      hash: 'BZJ18HS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZJ18HS4'
    },
    {
      status: 210,
      sku: 'PM0082336',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350177',
      rtl_batch_array: '6350177',
      name_search: 'GAOALBA',
      name: "Galega officinalis 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1WR8JEH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WR8JEH3'
    },
    {
      status: 210,
      sku: 'PM0079861',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349957',
      rtl_batch_array: '6349957, 6302449',
      name_search: 'COTRIPTE',
      name: 'Coreopsis tripteris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 399,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XT1NJ63P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT1NJ63P'
    },
    {
      status: 210,
      sku: 'PM0079862',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302452',
      rtl_batch_array: '6302452',
      name_search: 'COHISPID',
      name: 'Cotula hispida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 122,
      chnn_stock_retail: 122,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CV7GEXAR;V7Y1EBBT;A9NYL4WZ;WXTJ2ER3;61DH8TPY;VGV5ANWB;YHRS729Z;KZCREX12;4KWDZKE3;W86DC759',
      statusContent: 'dot',
      hash: 'GWPTRZLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWPTRZLT'
    },
    {
      status: 210,
      sku: 'PM0074611',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268798',
      rtl_batch_array: '6268798',
      name_search: 'MISISERE',
      name: "Miscanthus s. 'Serengeti'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZWC3NBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZWC3NBW'
    },
    {
      status: 810,
      sku: 'PM0033810',
      core_name: 'Plant',
      sppl_ean: '8720664632936',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849871',
      rtl_batch_array: '5849871',
      name_search: 'SIMAYA',
      name: "Silphium 'Maya'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1E7G698G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1E7G698G'
    },
    {
      status: 210,
      sku: 'PM0079863',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302460',
      rtl_batch_array: '6302460',
      name_search: 'DIGRATIA',
      name: 'Dianthus gratianopolitanus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 158,
      chnn_stock_retail: 158,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KL4S8WAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KL4S8WAH'
    },
    {
      status: 210,
      sku: 'PM0079864',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302461',
      rtl_batch_array: '6302461',
      name_search: 'DIGBADEN',
      name: "Dianthus gratianopolitanus 'Badenia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6WCN8B4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6WCN8B4'
    },
    {
      status: 210,
      sku: 'PM0079865',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302475',
      rtl_batch_array: '6302475',
      name_search: 'ECCSPIRI',
      name: "Echinacea 'Cheyenne Spirit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JHLAZEER',
      statusContent: 'dot',
      hash: '9XESW6LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XESW6LK'
    },
    {
      status: 210,
      sku: 'PM0079866',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302587',
      rtl_batch_array: '6302587',
      name_search: 'HECDAWN',
      name: "Heuchera 'Cascade Dawn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZWRL84NC;XGJVYHT7',
      statusContent: 'dot',
      hash: '1Y75BB25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y75BB25'
    },
    {
      status: 210,
      sku: 'PM0079867',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350425',
      rtl_batch_array: '6350425, 6302593',
      name_search: 'HENEAMBE',
      name: 'Heuchera Northern Exposure Amber',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 586,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CKT8111T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKT8111T'
    },
    {
      status: 210,
      sku: 'PM0079868',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6302596',
      rtl_batch_array: '6302596',
      name_search: 'HEPPETTI',
      name: "Heuchera 'Purple Petticoats'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1JL82ZD;HWCZ6BZL;C2ZZZ42G;VVV7SEGL;HSWWKPSY',
      statusContent: 'dot',
      hash: 'SP8DS3VH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP8DS3VH'
    },
    {
      status: 210,
      sku: 'PM0079870',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317823',
      rtl_batch_array: '6317823, 6339731',
      name_search: 'AGPHAZE',
      name: "Agastache 'Purple Haze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 575,
      chnn_stock_retail: 1295,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DN7WDZ21',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DN7WDZ21'
    },
    {
      status: 210,
      sku: 'PM0079871',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317827',
      rtl_batch_array: '6317827',
      name_search: 'ARABROTA',
      name: 'Artemisia abrotanum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '15GG6211;KPWD375B;2CZRT1X7;9K1V59J5;W65S4BTB;7VY1YEH5;6X6ZD4N3;8124NHXV;L19E9JHD;VC4W2KNB;Y1PV9H7K;YVL2APWS;7V179EXS;SZZCSL14',
      statusContent: 'dot',
      hash: 'ZER6AJ6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZER6AJ6E'
    },
    {
      status: 210,
      sku: 'PM0079872',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317829',
      rtl_batch_array: '6317829',
      name_search: 'ASAANANU',
      name: "Aster ageratoides 'Adustus Nanus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1077,
      chnn_stock_retail: 1077,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9GVLSZKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GVLSZKY'
    },
    {
      status: 210,
      sku: 'PM0079873',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339757',
      rtl_batch_array: '6317830, 6339757',
      name_search: 'ASLINOSY',
      name: 'Aster linosyris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 580,
      chnn_stock_retail: 2057,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y6BHGT6S;NABESDND',
      statusContent: 'dot',
      hash: 'WYGGHHR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYGGHHR2'
    },
    {
      status: 210,
      sku: 'PM0079874',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6317832',
      rtl_batch_array: '6317832, 6386058',
      name_search: 'ASNBMBAL',
      name: "Aster novi-belgii 'Marie Ballard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 569,
      chnn_stock_retail: 1565,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N5PSXWR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5PSXWR5'
    },
    {
      status: 210,
      sku: 'PM0079875',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386102',
      rtl_batch_array: '6317837, 6386102',
      name_search: 'CORVERTI',
      name: 'Coreopsis verticillata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1010,
      chnn_stock_retail: 1854,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYP634PC',
      statusContent: 'dot',
      hash: '14JN2PGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14JN2PGJ'
    },
    {
      status: 210,
      sku: 'PM0079876',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317840',
      rtl_batch_array: '6350222, 6317840, 6324931',
      name_search: 'GENSILVE',
      name: "Geranium nodosum 'Silverwood'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 939,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NN34SLWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN34SLWD'
    },
    {
      status: 210,
      sku: 'PM0079877',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317844',
      rtl_batch_array: '6317844',
      name_search: 'IBSEMPER',
      name: 'Iberis sempervirens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 642,
      chnn_stock_retail: 642,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N84Y8S3K;LR8TJ651;75KE5S7A;X2CESCCC;Y9SZYR69;NN75Z5JR;B387YK5D;5SBY24D5;E62V8PYZ;G19L4687;767Y9LTD;C8VYC8JE;SST1ZJXY;8ZSKAD5W;JDCXECTW;NBNGWXYT;1APKV74D;6GD2ZAY6;9WHCSW79',
      statusContent: 'dot',
      hash: 'X9G2L9G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9G2L9G6'
    },
    {
      status: 210,
      sku: 'PM0079878',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317849',
      rtl_batch_array: '6317849',
      name_search: 'EUCFRUBY',
      name: "Euphorbia cyparissias 'Fens Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JB7192NA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB7192NA'
    },
    {
      status: 910,
      sku: 'PM0079879',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '1EWR5KYJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1EWR5KYJ'
    },
    {
      status: 210,
      sku: 'PM0079880',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306412',
      rtl_batch_array: '6306412',
      name_search: 'NADTWILI',
      name: "Nandina domestica 'Twilight'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 795,
      chnn_stock_retail: 795,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WGYXCN6V;SSRVJ5RR;YXZAXPN4;XBXRSJSX;DTK851ES',
      statusContent: 'dot',
      hash: 'XTS85L1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTS85L1J'
    },
    {
      status: 210,
      sku: 'PM0079881',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306413',
      rtl_batch_array: '6306413',
      name_search: 'SIRHWINE',
      name: "Sinocalycanthus raul. 'Hartlage wine'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2XZX5L8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XZX5L8R'
    },
    {
      status: 210,
      sku: 'PM0079882',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306414',
      rtl_batch_array: '6306414',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: '9N938WNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N938WNT'
    },
    {
      status: 210,
      sku: 'PM0079883',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306558',
      rtl_batch_array: '6306558',
      name_search: 'CARBLACE',
      name: "Camellia reticulata 'Black Lace'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6AHVW4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6AHVW4J'
    },
    {
      status: 210,
      sku: 'PM0079884',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306559',
      rtl_batch_array: '6306559',
      name_search: 'CAWJYELL',
      name: "Camellia williamsii 'Jury's Yellow'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTZAZ8L6',
      statusContent: 'dot',
      hash: 'HB2L5SH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB2L5SH2'
    },
    {
      status: 210,
      sku: 'PM0079885',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306560',
      rtl_batch_array: '6306560',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: 'WW192XGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW192XGA'
    },
    {
      status: 210,
      sku: 'PM0033743',
      core_name: 'Plant',
      sppl_ean: '8720664807396',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849781',
      rtl_batch_array: '5849781',
      name_search: 'SANCARAD',
      name: "Salvia nemorosa 'Caradonna'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4G44ZLTY;CH999Z8V;82N5TCCX;H9PZEKPC',
      statusContent: 'dot',
      hash: '558PLNZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '558PLNZ3'
    },
    {
      status: 210,
      sku: 'PM0079886',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306561',
      rtl_batch_array: '6306561',
      name_search: 'CAJMATTE',
      name: "Camellia japonica 'Matterhorn'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GNBC9KSB',
      statusContent: 'dot',
      hash: '76PR7LB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76PR7LB4'
    },
    {
      status: 210,
      sku: 'PM0079887',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306562',
      rtl_batch_array: '6306562',
      name_search: 'CAJLCAMP',
      name: "Camellia japonica 'Lady Campbell'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C9ASV2H;CHCP2CPX;CE6YKGT8;L6E2DXRC;LV1JYG15',
      statusContent: 'dot',
      hash: 'C4NBG8V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4NBG8V7'
    },
    {
      status: 210,
      sku: 'PM0079888',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306563',
      rtl_batch_array: '6306563',
      name_search: 'CARMWILL',
      name: "Camellia reticulata 'Mary Williams'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TBGGGKAS;D5KZWAE1;2JYEBBA4;4WVZSEXV',
      statusContent: 'dot',
      hash: 'SGGKC5ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGGKC5ED'
    },
    {
      status: 210,
      sku: 'PM0079889',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306564',
      rtl_batch_array: '6306564',
      name_search: 'CAJMBRAC',
      name: "Camellia japonica 'Marie Bracey'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LS9KELH4',
      statusContent: 'dot',
      hash: 'S3BPPPGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3BPPPGG'
    },
    {
      status: 210,
      sku: 'PM0079890',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306565',
      rtl_batch_array: '6306565',
      name_search: 'CARBLACE',
      name: "Camellia reticulata 'Black Lace'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KKA8JB7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKA8JB7B'
    },
    {
      status: 210,
      sku: 'PM0079891',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306566',
      rtl_batch_array: '6306566',
      name_search: 'CAJCALBA',
      name: "Camellia japonica 'Centifolia Alba'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67REVVDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67REVVDZ'
    },
    {
      status: 210,
      sku: 'PM0079892',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306567',
      rtl_batch_array: '6306567',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: 'B6SLW3Y6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6SLW3Y6'
    },
    {
      status: 210,
      sku: 'PM0079893',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306568',
      rtl_batch_array: '6306568',
      name_search: 'CAWJYELL',
      name: "Camellia williamsii 'Jury's Yellow'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTZAZ8L6',
      statusContent: 'dot',
      hash: '6APW4354',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6APW4354'
    },
    {
      status: 210,
      sku: 'PM0079894',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306569',
      rtl_batch_array: '6306569',
      name_search: 'CAJMBRAC',
      name: "Camellia japonica 'Marie Bracey'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LS9KELH4',
      statusContent: 'dot',
      hash: 'LGASPX3C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGASPX3C'
    },
    {
      status: 210,
      sku: 'PM0079895',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306570',
      rtl_batch_array: '6306570',
      name_search: 'CAJLCAMP',
      name: "Camellia japonica 'Lady Campbell'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C9ASV2H;CHCP2CPX;CE6YKGT8;L6E2DXRC;LV1JYG15',
      statusContent: 'dot',
      hash: '4TE2WXNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TE2WXNN'
    },
    {
      status: 210,
      sku: 'PM0079896',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6306571',
      rtl_batch_array: '6306571',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: '1795SJH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1795SJH8'
    },
    {
      status: 810,
      sku: 'PM0079897',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319676',
      rtl_batch_array: '6319676',
      name_search: 'ACCREDO',
      name: "Achillea 'Credo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RBPNA2GV;TCHECJXT',
      statusContent: 'dot',
      hash: 'XTCZ8PP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTCZ8PP7'
    },
    {
      status: 210,
      sku: 'PM0033807',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254835',
      rtl_batch_array: '6251009, 6254835',
      name_search: 'SCCFPWHI',
      name: 'Scabiosa col. Flutter Pure White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 109,
      chnn_stock_retail: 459,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NGH6Z2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NGH6Z2P'
    },
    {
      status: 210,
      sku: 'PM0079898',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319679',
      rtl_batch_array: '6319679, 6339725',
      name_search: 'AGBBOA',
      name: "Agastache 'Blue Boa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 375,
      chnn_stock_retail: 1971,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T5N852JS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5N852JS'
    },
    {
      status: 210,
      sku: 'PM0079899',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386051',
      rtl_batch_array: '6319683, 6386051',
      name_search: 'ANTSOMME',
      name: "Anaphalis triplinervis 'Sommerschnee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1761,
      chnn_stock_retail: 1957,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YT5E3TB;ELR7VTB7;RRJJWHHE',
      statusContent: 'dot',
      hash: 'AX7TD8NB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX7TD8NB'
    },
    {
      status: 810,
      sku: 'PM0033738',
      core_name: 'Plant',
      sppl_ean: '8720664568365',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849770',
      rtl_batch_array: '5849770',
      name_search: 'LAORVALA',
      name: 'Lamium orvala',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PLJBLC3V;97938T3D;Y6HXZEDE;ESBWEY3D',
      statusContent: 'dot',
      hash: '6VXANJW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VXANJW8'
    },
    {
      status: 810,
      sku: 'PM0033822',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254854',
      rtl_batch_array: '6254854',
      name_search: 'TRCGQUEE',
      name: "Trollius chinensis 'Golden Queen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LPHREY8L;9S61WPTE;GJ34B8ZV;6GX1WTJX;GTWDWAAS;Y32CWXSW;LN55YS2T;C48HAZ4B',
      statusContent: 'dot',
      hash: '97D5ALNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97D5ALNL'
    },
    {
      status: 210,
      sku: 'PM0067847',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6189167',
      rtl_batch_array: '6189167',
      name_search: 'CHGFINGE',
      name: 'Choisya Green Fingers',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 16.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97WTCAWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97WTCAWP'
    },
    {
      status: 210,
      sku: 'PM0079900',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319697',
      rtl_batch_array: '6319697',
      name_search: 'GESANNE',
      name: "Geranium 'Sanne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CG3L72Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CG3L72Y'
    },
    {
      status: 210,
      sku: 'PM0067845',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314919',
      rtl_batch_array: '6314919, 6284953',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 734,
      chnn_stock_retail: 972,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: '9SHTN4KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SHTN4KS'
    },
    {
      status: 810,
      sku: 'PM0079901',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319699',
      rtl_batch_array: '6319699',
      name_search: 'GESBLILA',
      name: "Geranium sylvaticum 'Birch Lilac'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXWPRH5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXWPRH5Z'
    },
    {
      status: 210,
      sku: 'PM0079903',
      core_name: 'Plant',
      sppl_ean: '8720664886827',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137461',
      rtl_batch_array: '6137461',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '2NDA15EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NDA15EV'
    },
    {
      status: 810,
      sku: 'PM0079904',
      core_name: 'Plant',
      sppl_ean: '8720664851023',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6090652',
      rtl_batch_array: '6090652',
      name_search: 'AJTVALFR',
      name: "Ajuga tenorii 'Valfredda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VLVZXWH;V2J7G5XS;5K1ZPHV8;X9C52B1C;XWSG6NBH;NB2GRDJD;W1R5N4PV;BB5BC35B;SLZZEZP6;5YBPAGRR;KKWJ2JEZ;VBVERDLB;9GJVE26V;S42277WW;PXJ8H712;4BS8KRKK;57WG17W7;9YG6HKCE;XL2TAHZL',
      statusContent: 'dot',
      hash: 'G94R94D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G94R94D9'
    },
    {
      status: 210,
      sku: 'PM0079914',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210040',
      rtl_batch_array: '6210040',
      name_search: 'WEFALEXA',
      name: "Weigela florida 'Alexandra'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6B2ZE5E',
      statusContent: 'dot',
      hash: 'PCYVHLR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCYVHLR3'
    },
    {
      status: 210,
      sku: 'PM0006460',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348949',
      rtl_batch_array: '6077552, 6276645, 6348949',
      name_search: 'ASCSHOGU',
      name: "Astilbe 'Chocolate Shogun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 406,
      sppl_order_minimum: 3,
      sppl_prcp: 1.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAY4Z1ZX;1BT3KYA2;V838ZKLZ;K5DDKNLZ;Y58JLJ3R;JD3ZSB95;PKBCGCJD;HJE87LXD;XS4ZWWJH',
      statusContent: 'dot',
      hash: 'HLW6VPKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLW6VPKS'
    },
    {
      status: 210,
      sku: 'PM0067846',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6189160',
      rtl_batch_array: '6189160',
      name_search: 'EUJAMARI',
      name: 'Euonymus jap. Marieke',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KSB3DGG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSB3DGG8'
    },
    {
      status: 210,
      sku: 'PM0067848',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6189468',
      rtl_batch_array: '6189468',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'NSYKHPJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSYKHPJH'
    },
    {
      status: 210,
      sku: 'PM0029722',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267524',
      rtl_batch_array: '6267524',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: 'LEVC44',
      rng_range_identifier: 'C4.5',
      rng_range_description: 'C4.5',
      sppl_stock_available: 1450,
      chnn_stock_retail: 1450,
      sppl_prcp: 4.567,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'NTB765JN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTB765JN'
    },
    {
      status: 210,
      sku: 'PM0033869',
      core_name: 'Plant',
      sppl_ean: '8720349438266',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849979',
      rtl_batch_array: '5849979',
      name_search: 'PEALISAN',
      name: "Persicaria amplexicaulis 'Lisan'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P82H648J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P82H648J'
    },
    {
      status: 210,
      sku: 'PM0067858',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6298617',
      rtl_batch_array: '6298617, 6378349',
      name_search: 'HEHNIKKA',
      name: 'Hebe Hebedonna Nikka',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1465,
      chnn_stock_retail: 5050,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R45DEV49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R45DEV49'
    },
    {
      status: 210,
      sku: 'PM0079907',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256148',
      rtl_batch_array: '6256148',
      name_search: 'MAESCARE',
      name: "Mahonia eurybracteata 'Soft Caress'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N4AA3PC4;CD4JS8TD;5H313JL4;R2HPGR2A;7LRNS7R7',
      statusContent: 'dot',
      hash: '7APSV3LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7APSV3LE'
    },
    {
      status: 910,
      sku: 'PM0079908',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUJWSPIR',
      name: "Euonymus japonicus 'White Spire'",
      rng_range_identifier: 'H020025C1',
      rng_range_description: 'H20 cm 25 cm C1',
      imageCore: 'EA5HB839;PTNHXAVG;A5EZR9PE;7TBP5XJ1;ZLZ9C4DD;EDPZX8BX',
      statusContent: 'dot',
      hash: 'ZB2V6P9J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZB2V6P9J'
    },
    {
      status: 210,
      sku: 'PM0079909',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256438',
      rtl_batch_array: '6256438',
      name_search: 'PHFPMARB',
      name: 'Photinia fraseri Pink Marble',
      sppl_size_code: '045050C3',
      rng_range_identifier: 'H045050C3',
      rng_range_description: 'H45 cm 50 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4CT9L811;1HBNSB84;8N74BZ6V;YARAEHC9;HGEVRBBT;JXTCBW25;9WRNT3DN;JY6R1L8Z;V6W5T3DZ;ER19K222;SDG8NAG4;DP837D6H;P3RSE34G;E6DCE2W5;24KRVHDT;EAKC87TV;4V3R78Y9;VXNWKB91;ZC7LGHKY',
      statusContent: 'dot',
      hash: 'WNT8AP3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNT8AP3N'
    },
    {
      status: 210,
      sku: 'PM0014634',
      core_name: 'Plant',
      sppl_ean: '8720349465002',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5444265',
      rtl_batch_array: '5444265',
      name_search: 'PHOLJOKE',
      name: 'Physocarpus opulifolius Little Joker',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E48CH8E;RPZCTBD6;PNCPDPEJ;5P2VJZ97;SX8GWW1H;15PJWBEG;JTELK2CN;1BSE16EY',
      statusContent: 'dot',
      hash: 'RVBTVZEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVBTVZEX'
    },
    {
      status: 210,
      sku: 'PM0033745',
      core_name: 'Plant',
      sppl_ean: '8720626368842',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '5849783',
      rtl_batch_array: '5849783',
      name_search: 'SANVKLOS',
      name: "Salvia nemorosa 'Viola Klose'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H4AGGCRS',
      statusContent: 'dot',
      hash: 'SB187XW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SB187XW2'
    },
    {
      status: 210,
      sku: 'PM0079915',
      core_name: 'Plant',
      sppl_ean: '8720664877603',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164887',
      rtl_batch_array: '6164887',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'ST4LJ38Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST4LJ38Y'
    },
    {
      status: 210,
      sku: 'PM0079916',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267213',
      rtl_batch_array: '6267213',
      name_search: 'ACERGMD',
      name: 'Acer Gemengd',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5VK5YW35',
      statusContent: 'dot',
      hash: 'K1PYPJJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1PYPJJS'
    },
    {
      status: 210,
      sku: 'PM0079917',
      core_name: 'Plant',
      sppl_ean: '8720626338210',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495224',
      rtl_batch_array: '6349891, 6171174, 5495224',
      name_search: 'CALPVARI',
      name: "Campanula lactiflora 'Prichard's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 863,
      chnn_stock_retail: 3413,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHN19HHR;P8GLVY47',
      statusContent: 'dot',
      hash: 'HX1LLWT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX1LLWT7'
    },
    {
      status: 210,
      sku: 'PM0079918',
      core_name: 'Plant',
      sppl_ean: '8720664888609',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6171198',
      rtl_batch_array: '6171198, 6143864',
      name_search: 'THPFOXLE',
      name: "Thymus pulegioides 'Foxley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 2074,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2D92ELJC;ZAYL7BGH',
      statusContent: 'dot',
      hash: 'D1ZTDRY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1ZTDRY2'
    },
    {
      status: 810,
      sku: 'PM0079919',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174483',
      rtl_batch_array: '6174483',
      name_search: 'LOJHPROL',
      name: "Lonicera japonica 'Hall's Prolific'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 2.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHKL1DZY;PEANXCLV;CK5DDGCZ;2BXS6WAH;D3X52NJ1;5J46GSBA;5H8AKRL2;V11VCCSK;XE1KT76N;YV37XS54;KJAY3KGK',
      statusContent: 'dot',
      hash: 'XXEYYC1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXEYYC1P'
    },
    {
      status: 810,
      sku: 'PM0071646',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6231001',
      rtl_batch_array: '6231001',
      name_search: 'LAVALBOR',
      name: "Lathyrus vernus 'Alboroseus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 1.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L4N9W75E;KR9JJHCC;8SKTL4N7;G2RL4D5C;HYXDPGWV;SN7E2W74;HLCCE3PC;GG2HEENR;Y1ZBBV9Z',
      statusContent: 'dot',
      hash: 'DTJ7L8EJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTJ7L8EJ'
    },
    {
      status: 210,
      sku: 'PM0079920',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6178429',
      rtl_batch_array: '6178429',
      name_search: 'EUJWSPIR',
      name: "Euonymus japonicus 'White Spire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA5HB839;PTNHXAVG;A5EZR9PE;7TBP5XJ1;ZLZ9C4DD;EDPZX8BX',
      statusContent: 'dot',
      hash: 'RYE1SSL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYE1SSL4'
    },
    {
      status: 210,
      sku: 'PM0079921',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6178431',
      rtl_batch_array: '6178431',
      name_search: 'FABICOLO',
      name: "Fascicularia 'Bicolor'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6A9ACLXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A9ACLXV'
    },
    {
      status: 210,
      sku: 'PM0079922',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6178432',
      rtl_batch_array: '6178432',
      name_search: 'HYASANNA',
      name: 'Hydrangea arborescens Strong Annabelle',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 6.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6WESVPSZ;8S94HRR7;EW3Z6NPE;DW2PR9BP;PPAKRZVE;55NPDE9T;575TA36W;772K6D22;TS72Y5SD;JW5PHY85;EKWLYTBA;D389C7VL;XTSCRK29',
      statusContent: 'dot',
      hash: 'G3THDX72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3THDX72'
    },
    {
      status: 210,
      sku: 'PM0079923',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6178433',
      rtl_batch_array: '6178433',
      name_search: 'HYAPANNA',
      name: 'Hydrangea arborescens Pink Annabelle',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CYELV55N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYELV55N'
    },
    {
      status: 210,
      sku: 'PM0079924',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239259',
      rtl_batch_array: '6239259',
      name_search: 'FABICOLO',
      name: "Fascicularia 'Bicolor'",
      sppl_size_code: '015020C1',
      rng_range_identifier: 'H015020C1',
      rng_range_description: 'H15 cm 20 cm C1',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6J2SH2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6J2SH2L'
    },
    {
      status: 210,
      sku: 'PM0079925',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239260',
      rtl_batch_array: '6239260',
      name_search: 'MAESCARE',
      name: "Mahonia eurybracteata 'Soft Caress'",
      sppl_size_code: '015020C1',
      rng_range_identifier: 'H015020C1',
      rng_range_description: 'H15 cm 20 cm C1',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 4.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N4AA3PC4;CD4JS8TD;5H313JL4;R2HPGR2A;7LRNS7R7',
      statusContent: 'dot',
      hash: 'KZDRWDSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZDRWDSE'
    },
    {
      status: 210,
      sku: 'PM0012321',
      core_name: 'Plant',
      sppl_ean: '8720664699625',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5382004',
      rtl_batch_array: '5382004',
      name_search: 'MISPCLOU',
      name: 'Miscanthus sinensis Pink Cloud',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ACB2KASK',
      imageBatch: 'D5JKGJ7A',
      statusContent: 'dot',
      hash: 'TVK4BCAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVK4BCAK'
    },
    {
      status: 210,
      sku: 'PM0071649',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6231148',
      rtl_batch_array: '6231148, 6356604',
      name_search: 'IRSBSUGA',
      name: "Iris sibirica 'Butter and Sugar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1268,
      sppl_order_minimum: 3,
      sppl_prcp: 0.747,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N496LAH9;B5PX1Y57;CJW1G7WP;5EZTHX1A;JZC491DH',
      statusContent: 'dot',
      hash: '4X7DYVRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4X7DYVRN'
    },
    {
      status: 810,
      sku: 'PM0067861',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280937',
      rtl_batch_array: '6280937',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.545,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: '8YGJX96D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YGJX96D'
    },
    {
      status: 810,
      sku: 'PM0080012',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334253',
      rtl_batch_array: '6334253',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 23.197,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: '7LSLLH9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LSLLH9L'
    },
    {
      status: 210,
      sku: 'PM0071648',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6231146',
      rtl_batch_array: '6231146',
      name_search: 'HEMFIRE',
      name: "Hemerocallis 'Moses Fire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DSAL22K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSAL22K8'
    },
    {
      status: 210,
      sku: 'PM0079926',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329874',
      rtl_batch_array: '6329874',
      name_search: 'BRMWZEBR',
      name: "Brunnera macr. 'White Zebra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 696,
      chnn_stock_retail: 696,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '57HWRHWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57HWRHWD'
    },
    {
      status: 810,
      sku: 'PM0079927',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329914',
      rtl_batch_array: '6329914',
      name_search: 'DISRGOLD',
      name: "Dicentra spect. 'Ruby Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1VNADEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1VNADEW'
    },
    {
      status: 210,
      sku: 'PM0079931',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330101',
      rtl_batch_array: '6330101',
      name_search: 'SENROSE',
      name: "Sempervivum 'New Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALRJNT3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALRJNT3G'
    },
    {
      status: 210,
      sku: 'PM0079932',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330105',
      rtl_batch_array: '6330105',
      name_search: 'SPNCORAL',
      name: "Sphaeralcea 'Newleaze Coral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '15VRL51V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15VRL51V'
    },
    {
      status: 910,
      sku: 'PM0079933',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      rng_range_identifier: 'H150175C4',
      rng_range_description: 'H150 cm 175 cm C4',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'R25HJZ26',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R25HJZ26'
    },
    {
      status: 210,
      sku: 'PM0079934',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333667',
      rtl_batch_array: '6333667',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '150175C4',
      rng_range_identifier: 'H150175C4',
      rng_range_description: 'H150 cm 175 cm C4',
      sppl_stock_available: 679,
      chnn_stock_retail: 679,
      sppl_prcp: 4.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: '6EE834H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EE834H5'
    },
    {
      status: 210,
      sku: 'PM0079935',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333668',
      rtl_batch_array: '6333668',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '150175C4',
      rng_range_identifier: 'H150175C4',
      rng_range_description: 'H150 cm 175 cm C4',
      sppl_stock_available: 679,
      chnn_stock_retail: 679,
      sppl_prcp: 4.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: '29WWNWYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29WWNWYG'
    },
    {
      status: 910,
      sku: 'PM0079936',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SESSTARD',
      name: "Sedum spectabile 'Stardust'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: '6VXAJYPY',
      statusContent: 'dot',
      hash: '6H48DXE5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6H48DXE5'
    },
    {
      status: 810,
      sku: 'PM0079938',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333672',
      rtl_batch_array: '6333672',
      name_search: 'KNUVARIA',
      name: 'Kniphofia uvaria',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDSAALYZ;7SAW3EXS;GA5Z1XEL;JS3X75Z8;9NL6Z18E;PE9LSLCZ;YWCDGG4A;1WHSWR4B;7DPLP8ZV',
      statusContent: 'dot',
      hash: 'S2TH4ALR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2TH4ALR'
    },
    {
      status: 210,
      sku: 'PM0074612',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270298',
      rtl_batch_array: '6270298',
      name_search: 'LIPPARAC',
      name: "Limonium 'Purple Parachute'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 885,
      chnn_stock_retail: 885,
      sppl_prcp: 4.221,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JSJEZ5GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSJEZ5GE'
    },
    {
      status: 210,
      sku: 'PM0022570',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349132',
      rtl_batch_array: '6349132, 6202316, 6176512, 6091607',
      name_search: 'LEOFFICI',
      name: 'Levisticum officinale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4032,
      chnn_stock_retail: 5916,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NE76V2HR;8D6H4E69;AG32JC95;D36LZ5B9;WB8XG1AS;JZGGZ2W3',
      statusContent: 'dot',
      hash: '16SWWER4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16SWWER4'
    },
    {
      status: 210,
      sku: 'PM0029726',
      core_name: 'Plant',
      sppl_ean: '8720353027012',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5446546',
      rtl_batch_array: '5446546',
      name_search: 'NEAFFINI',
      name: 'Neillia affinis',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 375,
      chnn_stock_retail: 375,
      sppl_prcp: 3.251,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1G17GYZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G17GYZN'
    },
    {
      status: 910,
      sku: 'PM0079939',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CASFESTI',
      name: "Camellia 'Spring Festival'",
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      imageCore:
        'RKY2RYCD;GWTZ9YZE;1PVNEC7L;JXATN27S;PTB3W6BD;2K2XG1TA;W6ZAZW2J;AWTV6ND3;EDLTDDP5;9VHJSYK3;Z1VBN5VH;51CE97XE',
      statusContent: 'dot',
      hash: 'V2GGHGHN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V2GGHGHN'
    },
    {
      status: 210,
      sku: 'PM0033233',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6183989',
      rtl_batch_array: '6183989',
      name_search: 'BEMPARKJ',
      name: "Berberis media 'Parkjuweel'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DNS4ZEN7;DXHPLBP2;H19D93RE;LP5A84HC;TRSEJG36;P7WBZTA4',
      statusContent: 'dot',
      hash: '1SBBL8Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SBBL8Z5'
    },
    {
      status: 210,
      sku: 'PM0006450',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184031',
      rtl_batch_array: '6184031',
      name_search: 'HEHWOERN',
      name: "Hedera helix 'Woerner'",
      sppl_size_code: '150175C34',
      rng_range_identifier: 'H150175C3.5',
      rng_range_description: 'H150 cm 175 cm C3.5',
      sppl_stock_available: 936,
      chnn_stock_retail: 936,
      sppl_prcp: 4.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X279SEH;RGYYYN9X;JG872GJN;8T9TWZ5J',
      statusContent: 'dot',
      hash: '2A8573CC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A8573CC'
    },
    {
      status: 210,
      sku: 'PM0079940',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333995',
      rtl_batch_array: '6333995',
      name_search: 'CAJNGEM',
      name: "Camellia japonica 'Nuccio's Gem'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX6Y2LNB',
      statusContent: 'dot',
      hash: 'G2EX4EDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2EX4EDB'
    },
    {
      status: 210,
      sku: 'PM0079941',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333996',
      rtl_batch_array: '6333996',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: '7527PNDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7527PNDB'
    },
    {
      status: 210,
      sku: 'PM0067702',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6185816',
      rtl_batch_array: '6185816, 6336109',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 139,
      chnn_stock_retail: 699,
      sppl_prcp: 2.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: '2G543G4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2G543G4N'
    },
    {
      status: 210,
      sku: 'PM0079942',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333997',
      rtl_batch_array: '6333997',
      name_search: 'CAWJYELL',
      name: "Camellia williamsii 'Jury's Yellow'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 10.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTZAZ8L6',
      statusContent: 'dot',
      hash: 'R1ANSKEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1ANSKEG'
    },
    {
      status: 210,
      sku: 'PM0079943',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333998',
      rtl_batch_array: '6333998',
      name_search: 'CAWJYELL',
      name: "Camellia williamsii 'Jury's Yellow'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YTZAZ8L6',
      statusContent: 'dot',
      hash: 'L564W5PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L564W5PY'
    },
    {
      status: 210,
      sku: 'PM0079944',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333999',
      rtl_batch_array: '6333999',
      name_search: 'CAJVOLUN',
      name: "Camellia japonica 'Volunteer'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '87Z2JK6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87Z2JK6K'
    },
    {
      status: 210,
      sku: 'PM0079945',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334000',
      rtl_batch_array: '6334000',
      name_search: 'CAJNGEM',
      name: "Camellia japonica 'Nuccio's Gem'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX6Y2LNB',
      statusContent: 'dot',
      hash: 'STESGLRB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STESGLRB'
    },
    {
      status: 210,
      sku: 'PM0079946',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334001',
      rtl_batch_array: '6334001',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: 'C1LPNSZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1LPNSZ2'
    },
    {
      status: 210,
      sku: 'PM0079947',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334002',
      rtl_batch_array: '6334002',
      name_search: 'CAJNCAME',
      name: "Camellia japonica 'Nuccio's Cameo'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K2BXVN1;2CCD8NE7;92TBYA6X;JLHZ2DBS;1EB9C7C7',
      statusContent: 'dot',
      hash: 'EKESY79D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKESY79D'
    },
    {
      status: 210,
      sku: 'PM0029555',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324927',
      rtl_batch_array: '6291701, 6164352, 6324927',
      name_search: 'AJRATROP',
      name: "Ajuga reptans 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 2942,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NYXH8SZ5;RYZVDHRL;43164DTS;4CRTNCEC;JJBC7P65;8XEL25RD;A8HBGSG2',
      statusContent: 'dot',
      hash: '4JHTEGBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JHTEGBR'
    },
    {
      status: 210,
      sku: 'PM0079948',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334003',
      rtl_batch_array: '6334003',
      name_search: 'ACPBUTTE',
      name: "Acer palmatum 'Butterfly'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.717,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z8LTJ8S9;AG3R7G4L;W113J7X5;9W94Y6HL;4D9B3KT2;L1R2L9G9;81S3BYXS;EZE7H3GR;RD4HPXTL;55Z5SL7X',
      statusContent: 'dot',
      hash: '82ASLVEZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82ASLVEZ'
    },
    {
      status: 910,
      sku: 'PM0071841',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239680',
      rtl_batch_array: '6239680',
      name_search: 'GLTSUNBU',
      name: "Gleditsia triacanthos 'Sunburst'",
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSPHLSDZ;K2GZTXTE;2P36HKAH',
      statusContent: 'dot',
      hash: 'NSDCB5EH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSDCB5EH'
    },
    {
      status: 210,
      sku: 'PM0079949',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334004',
      rtl_batch_array: '6334004',
      name_search: 'ACPAGGRE',
      name: 'Acer pal. Going Green',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.717,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RVT4LB5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVT4LB5V'
    },
    {
      status: 210,
      sku: 'PM0079950',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334005',
      rtl_batch_array: '6334005',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.717,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'B12SBWY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B12SBWY6'
    },
    {
      status: 910,
      sku: 'PM0079951',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPAGGRE',
      name: 'Acer pal. Going Green',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      statusContent: 'dot',
      hash: '8ZGT1ZT3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8ZGT1ZT3'
    },
    {
      status: 210,
      sku: 'PM0079952',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334008',
      rtl_batch_array: '6334008',
      name_search: 'ACPTAYLO',
      name: "Acer palmatum 'Taylor'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJA4YCVK;1KWE1DBX;D6TR26ZY;AK3HTNTZ',
      statusContent: 'dot',
      hash: '1DGRL3S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DGRL3S9'
    },
    {
      status: 210,
      sku: 'PM0079953',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334009',
      rtl_batch_array: '6334009',
      name_search: 'HYARANNA',
      name: 'Hydrangea arborescens Ruby Annabelle',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NJW9XKAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJW9XKAR'
    },
    {
      status: 210,
      sku: 'PM0079954',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334010',
      rtl_batch_array: '6334010',
      name_search: 'HYPSOPHE',
      name: 'Hydrangea paniculata Switch Ophelia',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 7.11,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SB5STYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SB5STYT'
    },
    {
      status: 210,
      sku: 'PM0079955',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334011',
      rtl_batch_array: '6334011',
      name_search: 'SKJMARLO',
      name: "Skimmia j. 'Marlot'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHXPKHBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHXPKHBJ'
    },
    {
      status: 210,
      sku: 'PM0079956',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334012',
      rtl_batch_array: '6334012',
      name_search: 'SKIJWDWA',
      name: 'Skimmia japonica White Dwarf',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T3JKXA6E;LCVXVLRL;YBD267AD;Z4CBCTKG;K76S9L1Z;2X4261SL',
      statusContent: 'dot',
      hash: 'BR6NWS2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BR6NWS2D'
    },
    {
      status: 210,
      sku: 'PM0079957',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334172',
      rtl_batch_array: '6334172',
      name_search: 'PASQUEEN',
      name: "Passiflora 'Snow Queen'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGS8T2AW;HV75XR27;K2HEXDS2;2EBELL92;72SZKLW6',
      statusContent: 'dot',
      hash: '176TPXVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '176TPXVA'
    },
    {
      status: 210,
      sku: 'PM0079958',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 3,
      btch_active_retail: '6334174',
      rtl_batch_array: '6334174, 6356090, 6348732',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 2750,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'BD5JTCCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BD5JTCCY'
    },
    {
      status: 210,
      sku: 'PM0079959',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 3,
      btch_active_retail: '6334175',
      rtl_batch_array: '6334175, 6356092, 6348733',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'W4ZJ92AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4ZJ92AE'
    },
    {
      status: 210,
      sku: 'PM0079960',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334176',
      rtl_batch_array: '6334176, 6348734',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 1100,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'RG6Y4NGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG6Y4NGE'
    },
    {
      status: 210,
      sku: 'PM0079961',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 4,
      btch_active_retail: '6355663',
      rtl_batch_array: '6334177, 6355663, 6356093, 6348735',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4800,
      chnn_stock_retail: 6650,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'JKVR2ZD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKVR2ZD7'
    },
    {
      status: 210,
      sku: 'PM0079962',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334178',
      rtl_batch_array: '6334178, 6356094',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 1250,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: 'G7NHZRKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7NHZRKN'
    },
    {
      status: 210,
      sku: 'PM0079963',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334179',
      rtl_batch_array: '6334179',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: '6JZRS9LW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JZRS9LW'
    },
    {
      status: 210,
      sku: 'PM0079964',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 4,
      btch_active_retail: '6355664',
      rtl_batch_array: '6334180, 6355664, 6356095, 6348736',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4300,
      chnn_stock_retail: 6300,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'SAE1APPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAE1APPP'
    },
    {
      status: 210,
      sku: 'PM0079965',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 3,
      btch_active_retail: '6334181',
      rtl_batch_array: '6334181, 6356096, 6348737',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 499,
      chnn_stock_retail: 1999,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'ZKYL9NRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKYL9NRJ'
    },
    {
      status: 210,
      sku: 'PM0079966',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334182',
      rtl_batch_array: '6334182, 6348739',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: 'G39TX661',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G39TX661'
    },
    {
      status: 210,
      sku: 'PM0079967',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334184',
      rtl_batch_array: '6334184',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'DBPETZB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBPETZB5'
    },
    {
      status: 210,
      sku: 'PM0079968',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334185',
      rtl_batch_array: '6334185',
      name_search: 'CLEMGRAN',
      name: 'Clematis montana grandiflora',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_prcp: 2.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZLV5DGE;ZLNSJBK3;P24P7D41;853J6TVR;SD2APLBW;7EW5TT7W;6S3SRHL5;HWJH3XH5;E5GDPDKP;Z64T2K5J;D7BL4YEB;W5LT3RLW;453BNKST;PE4Y2V2R;G346D7NA;RCVTTXX3;HGHGXHVP;S482G2LY;S715HA8J;L7HHS4ER;RHHPLD5A',
      statusContent: 'dot',
      hash: '4Z8PE2GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z8PE2GC'
    },
    {
      status: 210,
      sku: 'PM0079969',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334186',
      rtl_batch_array: '6334186',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_prcp: 2.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: '9XZSXKDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XZSXKDJ'
    },
    {
      status: 210,
      sku: 'PM0079970',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334189',
      rtl_batch_array: '6334189',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'RLAHEHCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLAHEHCC'
    },
    {
      status: 210,
      sku: 'PM0079971',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348789',
      rtl_batch_array: '6334190, 6348789',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1700,
      sppl_order_minimum: 5,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: '23EXNET5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23EXNET5'
    },
    {
      status: 210,
      sku: 'PM0079972',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334191',
      rtl_batch_array: '6334191',
      name_search: 'HYMBLAUM',
      name: "Hydrangea macrophylla 'Blaumeise'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GZZX7N8;ESE927KP;KYN2CXA5;AT3DLTTN;B7H86E48;4SSHW2B7;8JBJAJDX;NLW6SNC5;YWT46E3S;HK21GA6R;CNK7H6AX;GGNAZBCJ;HDNLVN2C',
      statusContent: 'dot',
      hash: 'T5D4N32S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5D4N32S'
    },
    {
      status: 210,
      sku: 'PM0079973',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334192',
      rtl_batch_array: '6334192',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: 'RT9E1626',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RT9E1626'
    },
    {
      status: 210,
      sku: 'PM0079974',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334193',
      rtl_batch_array: '6334193',
      name_search: 'HYMLIBEL',
      name: "Hydrangea macrophylla 'Libelle'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D32EL74L;ZDCX7HCW;PPGVSD31;87SBT7K6;WEGAXENS;6BCBCPWW;2TZ4DEE7;82K99P7A;VCXPTL8T;AY6YND7C;55V2VJX3;5224VEL6;477G57W9;2R1PPD42;5NDCBTSG;LE6GNY12;PTHA8NJV',
      statusContent: 'dot',
      hash: '9S7RT9LN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S7RT9LN'
    },
    {
      status: 210,
      sku: 'PM0079975',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334194',
      rtl_batch_array: '6334194',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'TVKVDY34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVKVDY34'
    },
    {
      status: 810,
      sku: 'PM0080013',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334254',
      rtl_batch_array: '6334254',
      name_search: 'ACJVITIF',
      name: "Acer japonicum 'Vitifolium'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 29.622,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E2NG25VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2NG25VW'
    },
    {
      status: 210,
      sku: 'PM0016367',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6348302',
      rtl_batch_array: '6349993, 6269614, 6254368, 6348302',
      name_search: 'DISPECTA',
      name: 'Dicentra spectabilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 4370,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S6CXXVX6',
      statusContent: 'dot',
      hash: '9P9AT53J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9P9AT53J'
    },
    {
      status: 210,
      sku: 'PM0079976',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334195',
      rtl_batch_array: '6334195',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: '5B491BK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B491BK7'
    },
    {
      status: 210,
      sku: 'PM0016776',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 7,
      btch_active_retail: '6336469',
      rtl_batch_array:
        '6350061, 6205065, 6229416, 5495275, 6291691, 6336469, 6324928',
      name_search: 'ECPMAGNU',
      name: "Echinacea purpurea 'Magnus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3087,
      chnn_stock_retail: 67476,
      sppl_order_minimum: 3,
      sppl_prcp: 0.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B5BBAX8;H61X9HC9;VDTJAZV1;AL37J663;8RWVZ719',
      statusContent: 'dot',
      hash: 'AHZ6G8BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHZ6G8BW'
    },
    {
      status: 810,
      sku: 'PM0080014',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334255',
      rtl_batch_array: '6334255',
      name_search: 'ACJVITIF',
      name: "Acer japonicum 'Vitifolium'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 34.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DZ8Y6TTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ8Y6TTT'
    },
    {
      status: 210,
      sku: 'PM0022623',
      core_name: 'Plant',
      sppl_ean: '8720349495290',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420720',
      rtl_batch_array: '5420720',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'EWS6HAAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWS6HAAJ'
    },
    {
      status: 210,
      sku: 'PM0013411',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386156',
      rtl_batch_array: '6386156',
      name_search: 'PAONALLE',
      name: "Papaver or. 'Nana Allegro'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXE4BZCN;BAPRJ539;979JRWER',
      statusContent: 'dot',
      hash: 'EYA3GJAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYA3GJAR'
    },
    {
      status: 210,
      sku: 'PM0000245',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339744',
      rtl_batch_array: '5504712, 6339744',
      name_search: 'AQVULGAR',
      name: 'Aquilegia vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 1213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BCSTVL3P;T6LDWP1Z;K5NE8NHV;EYXSL8WD;KXRTYAP9;83HEYBEB;27CH7XN2;7JWCPY1W;42ZB4TH4;LDTH6GKY',
      statusContent: 'dot',
      hash: 'GAB9Y4J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAB9Y4J8'
    },
    {
      status: 210,
      sku: 'PM0028598',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 6,
      btch_active_retail: '6356278',
      rtl_batch_array: '5960164, 6196838, 6315099, 6353543, 6356278, 6348437',
      name_search: 'VEBONARI',
      name: 'Verbena bonariensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1917,
      chnn_stock_retail: 25327,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKY7SBA7;ABH792B4;8CJEW9TL;G44VBSBK;YB7W24DG;BJB4127V;AWB6PXWB;9HZJHLLE;255D2W23;W7L7YBJS;4NNCLH29;AYDY3PE7;V61K9VK7;DHK64TJ5;CS74R4R9;E2249VDY;RASG24N5;2DBH57A1;ZHYX6VGE;WTE4LSZZ;9RE66TL3;VCHXW3DN;VKPTAXLL',
      statusContent: 'dot',
      hash: 'HX5E96SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX5E96SS'
    },
    {
      status: 210,
      sku: 'PM0002254',
      core_name: 'Plant',
      sppl_ean: '8720664688292',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5504717',
      rtl_batch_array: '6350046, 5504717',
      name_search: 'ECPHMOON',
      name: "Echinacea purpurea 'Harvest Moon' (R)",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 882,
      chnn_stock_retail: 1242,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RL44ATD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RL44ATD5'
    },
    {
      status: 210,
      sku: 'PM0079977',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334196',
      rtl_batch_array: '6334196',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'Y8AGTE6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8AGTE6N'
    },
    {
      status: 210,
      sku: 'PM0005239',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353426',
      rtl_batch_array: '6353426',
      name_search: 'SEARUBRU',
      name: "Sempervivum arachnoideum 'Rubrum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AH6KV3PG;669YDBNE;BGL3KJ4P;H9NR79NY;HL7W7XZT',
      statusContent: 'dot',
      hash: 'RSDTA53G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSDTA53G'
    },
    {
      status: 210,
      sku: 'PM0079978',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334197',
      rtl_batch_array: '6334197',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'LTRZVTAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTRZVTAS'
    },
    {
      status: 210,
      sku: 'PM0005591',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 3,
      btch_active_retail: '6337923',
      rtl_batch_array: '5998765, 6337923, 6340111',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 1270,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'TAT29CSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TAT29CSK'
    },
    {
      status: 210,
      sku: 'PM0079979',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334198',
      rtl_batch_array: '6334198',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'W3381TTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3381TTT'
    },
    {
      status: 210,
      sku: 'PM0079980',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334199',
      rtl_batch_array: '6334199',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'A6VBR2XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6VBR2XB'
    },
    {
      status: 810,
      sku: 'PM0080015',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334256',
      rtl_batch_array: '6334256',
      name_search: 'ACJVITIF',
      name: "Acer japonicum 'Vitifolium'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 21.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCGL3Y5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCGL3Y5G'
    },
    {
      status: 210,
      sku: 'PM0005718',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350267',
      rtl_batch_array: '6350267, 5521418',
      name_search: 'GETROSE',
      name: 'Geum Tempo Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 272,
      chnn_stock_retail: 730,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VBZYKJXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBZYKJXA'
    },
    {
      status: 210,
      sku: 'PM0013573',
      core_name: 'Plant',
      sppl_ean: '8720664688506',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5504846',
      rtl_batch_array: '6350085, 5504846, 6320867',
      name_search: 'ECSWPERF',
      name: 'Echinacea Suns.White Perfection',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 659,
      chnn_stock_retail: 1042,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZH938G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZH938G9'
    },
    {
      status: 810,
      sku: 'PM0011784',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307709',
      rtl_batch_array: '6307709',
      name_search: 'WIBSKAPI',
      name: "Wisteria brachybotrys 'Shiro Kapitan'",
      sppl_size_code: '200225C15',
      rng_range_identifier: 'H200225C15',
      rng_range_description: 'H200 cm 225 cm C15',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 23.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N39LHT16',
      statusContent: 'dot',
      hash: 'Y6641GVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6641GVE'
    },
    {
      status: 210,
      sku: 'PM0079981',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334200',
      rtl_batch_array: '6334200',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: '7BX44J1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BX44J1H'
    },
    {
      status: 210,
      sku: 'PM0079982',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334201',
      rtl_batch_array: '6334201',
      name_search: 'JANUDIFL',
      name: 'Jasminum nudiflorum',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 2.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TKK7Y96C;EC55166Y;EHN4G591;R2N5X45G;NKRW2BK7;SAZ3BWN1;L125CLNR;TE197YB3',
      statusContent: 'dot',
      hash: 'E9BG17HE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9BG17HE'
    },
    {
      status: 210,
      sku: 'PM0079983',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334203',
      rtl_batch_array: '6334203',
      name_search: 'PACAERUL',
      name: 'Passiflora caerulea',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YEW1WTTX;NRVPZWNE;YC1BYG32;L3616X6A;TJX3SWET;AA13ZRNS;2RB6V6J4;D575SXJB;66NZYEE8;ZAG5S851;P1CE6NH3;J6CJ1CX1;4XTJ6WHC;46NDAPV2',
      statusContent: 'dot',
      hash: '2SVG654W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SVG654W'
    },
    {
      status: 210,
      sku: 'PM0021772',
      core_name: 'Plant',
      sppl_ean: '8720353019727',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905568',
      rtl_batch_array: '5905568',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 556,
      chnn_stock_retail: 556,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: 'ZCZPRD6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCZPRD6X'
    },
    {
      status: 210,
      sku: 'PM0079984',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334204',
      rtl_batch_array: '6334204',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'L246DCLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L246DCLD'
    },
    {
      status: 210,
      sku: 'PM0079985',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334205',
      rtl_batch_array: '6334205, 6356189',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 1050,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'T6527LYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6527LYS'
    },
    {
      status: 210,
      sku: 'PM0079986',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334206',
      rtl_batch_array: '6334206',
      name_search: 'ROOCAPRI',
      name: "Rosmarinus officinalis 'Capri'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 515,
      chnn_stock_retail: 515,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5RH5N4E7;ANGHJ8XD;KTCCH9RL;4E46N3AL;E6CJ27H4',
      statusContent: 'dot',
      hash: 'Z9NJR3NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9NJR3NE'
    },
    {
      status: 810,
      sku: 'PM0079991',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334213',
      rtl_batch_array: '6334213',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 9.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'ZED5261A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZED5261A'
    },
    {
      status: 210,
      sku: 'PM0079992',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334220',
      rtl_batch_array: '6334220',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.197,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: '86246R2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86246R2Z'
    },
    {
      status: 210,
      sku: 'PM0079995',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314261',
      rtl_batch_array: '6314261',
      name_search: 'MAVOLCAN',
      name: 'Mahonia Volcano',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WE3A46X9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WE3A46X9'
    },
    {
      status: 210,
      sku: 'PM0079996',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334222',
      rtl_batch_array: '6334222',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.793,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: '7HJCVGG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HJCVGG4'
    },
    {
      status: 910,
      sku: 'PM0079997',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334223',
      rtl_batch_array: '6334223',
      name_search: 'STCOBJAC',
      name: "Staphylea colchica 'Black Jack'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 17.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WR25WTGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WR25WTGW'
    },
    {
      status: 210,
      sku: 'PM0079999',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334225',
      rtl_batch_array: '6334225',
      name_search: 'STCOBJAC',
      name: "Staphylea colchica 'Black Jack'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 12.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7W4XV6EW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W4XV6EW'
    },
    {
      status: 810,
      sku: 'PM0080000',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334226',
      rtl_batch_array: '6334226',
      name_search: 'STCOBJAC',
      name: "Staphylea colchica 'Black Jack'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.412,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7PTN1NL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7PTN1NL'
    },
    {
      status: 910,
      sku: 'PM0080006',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334244',
      rtl_batch_array: '6334244',
      name_search: 'HAJZUCCA',
      name: "Hamamelis japonica 'Zuccariniana'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 17.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SB33RAWS',
      statusContent: 'dot',
      hash: 'RDXYV557',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDXYV557'
    },
    {
      status: 810,
      sku: 'PM0080007',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334245',
      rtl_batch_array: '6334245',
      name_search: 'ITEVLHEN',
      name: 'Itea virginica Little Henry',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 4.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3Y6EYN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3Y6EYN2'
    },
    {
      status: 210,
      sku: 'PM0080008',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334247',
      rtl_batch_array: '6334247',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 5,
      sppl_prcp: 6.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'SWWST33X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWWST33X'
    },
    {
      status: 210,
      sku: 'PM0080009',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338364',
      rtl_batch_array: '6334248, 6338364',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 3518,
      chnn_stock_retail: 4268,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'NTJ3ZN92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTJ3ZN92'
    },
    {
      status: 810,
      sku: 'PM0080010',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349188',
      rtl_batch_array: '6349188, 6334251',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 44,
      chnn_stock_retail: 119,
      sppl_prcp: 16.887,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'AX1HW12L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX1HW12L'
    },
    {
      status: 810,
      sku: 'PM0080011',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334252',
      rtl_batch_array: '6334252',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 17.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'L3DNSWYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3DNSWYY'
    },
    {
      status: 810,
      sku: 'PM0080016',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334261',
      rtl_batch_array: '6334261',
      name_search: 'ACPATROL',
      name: "Acer palmatum 'Atrolineare'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 15.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SHC1VZWN;7P7P9PNY;DGS9KDXV;N4W956DW',
      statusContent: 'dot',
      hash: 'RK5DJT1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RK5DJT1V'
    },
    {
      status: 810,
      sku: 'PM0080017',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334262',
      rtl_batch_array: '6334262',
      name_search: 'ACPATROL',
      name: "Acer palmatum 'Atrolineare'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 19.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SHC1VZWN;7P7P9PNY;DGS9KDXV;N4W956DW',
      statusContent: 'dot',
      hash: 'ATS331JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATS331JH'
    },
    {
      status: 910,
      sku: 'PM0080018',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334263',
      rtl_batch_array: '6334263',
      name_search: 'ACPAUREU',
      name: "Acer palmatum 'Aureum'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 22.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AB2B735B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB2B735B'
    },
    {
      status: 910,
      sku: 'PM0080019',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334265',
      rtl_batch_array: '6334265',
      name_search: 'ACPAUREU',
      name: "Acer palmatum 'Aureum'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 19.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DBBDPJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DBBDPJ9'
    },
    {
      status: 910,
      sku: 'PM0080020',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334267',
      rtl_batch_array: '6334267',
      name_search: 'ACPCORAL',
      name: "Acer palmatum 'Corallinum'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 19.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8RH1ZH8X',
      statusContent: 'dot',
      hash: 'KSEN4JJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSEN4JJ5'
    },
    {
      status: 810,
      sku: 'PM0080021',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334270',
      rtl_batch_array: '6334270',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 23.497,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'VLSR3P2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLSR3P2K'
    },
    {
      status: 910,
      sku: 'PM0080022',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334271',
      rtl_batch_array: '6334271',
      name_search: 'ACPFILIG',
      name: "Acer palmatum 'Filigree'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 17.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGAWKNWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGAWKNWN'
    },
    {
      status: 910,
      sku: 'PM0080023',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334272',
      rtl_batch_array: '6334272',
      name_search: 'ACPFILIG',
      name: "Acer palmatum 'Filigree'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 19.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z8D1Z4V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8D1Z4V1'
    },
    {
      status: 910,
      sku: 'PM0080024',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334279',
      rtl_batch_array: '6334279',
      name_search: 'ACPNICHO',
      name: "Acer palmatum 'Nicholsonii'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 19.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSVTAT8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSVTAT8C'
    },
    {
      status: 810,
      sku: 'PM0080025',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334282',
      rtl_batch_array: '6334282',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 19.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'D2RNK57D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2RNK57D'
    },
    {
      status: 810,
      sku: 'PM0080026',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334286',
      rtl_batch_array: '6334286',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 21.127,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: 'PK7G7Z3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PK7G7Z3J'
    },
    {
      status: 810,
      sku: 'PM0080027',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334287',
      rtl_batch_array: '6349180, 6334287',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 30,
      chnn_stock_retail: 80,
      sppl_prcp: 22.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'XNX888TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNX888TN'
    },
    {
      status: 210,
      sku: 'PM0080028',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334290',
      rtl_batch_array: '6334290',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 15.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'J52WEBNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J52WEBNR'
    },
    {
      status: 810,
      sku: 'PM0082337',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350182',
      rtl_batch_array: '6350182',
      name_search: 'GEBMAGIC',
      name: "Gentiana 'Blue Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EAHL8GHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAHL8GHC'
    },
    {
      status: 210,
      sku: 'PM0082338',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350183',
      rtl_batch_array: '6350183',
      name_search: 'GEBSTAR',
      name: "Gentiana 'Blue Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L9CTC2VG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9CTC2VG'
    },
    {
      status: 210,
      sku: 'PM0011163',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242121',
      rtl_batch_array: '6349140, 5890861, 6242121, 6348448',
      name_search: 'POSHERRE',
      name: "Polystichum setiferum 'Herrenhausen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 9711,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XCRZBCVB;X5PE5WWT;DHX9GPWA;YGZSEDVS;225N912G;6Y6NEB7G;DZW6V6B6;HB5XE6EZ;RR69CTST',
      statusContent: 'dot',
      hash: 'B2PNSE1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2PNSE1R'
    },
    {
      status: 210,
      sku: 'PM0016327',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 4,
      btch_active_retail: '6271630',
      rtl_batch_array: '6311191, 6209191, 6304874, 6271630',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1100,
      chnn_stock_retail: 18300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'HSHTTT5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSHTTT5L'
    },
    {
      status: 210,
      sku: 'PM0070340',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 4,
      btch_active_retail: '6177900',
      rtl_batch_array: '6373230, 5496599, 6177900, 6255917',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1297,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'ATDALNJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATDALNJB'
    },
    {
      status: 210,
      sku: 'PM0079928',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329998',
      rtl_batch_array: '6329998',
      name_search: 'LYVSBEAU',
      name: "Lythrum virgatum 'Sunfields Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 317,
      chnn_stock_retail: 317,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X5NRGSDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5NRGSDN'
    },
    {
      status: 210,
      sku: 'PM0079929',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330021',
      rtl_batch_array: '6330021',
      name_search: 'PEHPALBU',
      name: "Penstemon hirsutus 'Pygmaeus Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BNP2ZGAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNP2ZGAS'
    },
    {
      status: 210,
      sku: 'PM0079930',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6330023',
      rtl_batch_array: '6330023',
      name_search: 'MOFTETRA',
      name: 'Mondarda fistulosa (x) tetraploid',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 169,
      chnn_stock_retail: 169,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NKAVB469',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKAVB469'
    },
    {
      status: 210,
      sku: 'PM0079987',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334207',
      rtl_batch_array: '6334207',
      name_search: 'ROOMJUPR',
      name: "Rosmarinus officinalis 'Miss Jessopp's Upright'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH2H6229;JR97TD2N',
      statusContent: 'dot',
      hash: 'TGRS3JNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGRS3JNL'
    },
    {
      status: 210,
      sku: 'PM0079988',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334209',
      rtl_batch_array: '6334209',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 3.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'GWK58CE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWK58CE2'
    },
    {
      status: 910,
      sku: 'PM0079989',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334210',
      rtl_batch_array: '6334210',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '175200C4',
      rng_range_identifier: 'H175200C4',
      rng_range_description: 'H175 cm 200 cm C4',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 13.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'T24PCEKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T24PCEKS'
    },
    {
      status: 210,
      sku: 'PM0079990',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334212',
      rtl_batch_array: '6334212',
      name_search: 'VEBONARI',
      name: 'Verbena bonariensis',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKY7SBA7;ABH792B4;8CJEW9TL;G44VBSBK;YB7W24DG;BJB4127V;AWB6PXWB;9HZJHLLE;255D2W23;W7L7YBJS;4NNCLH29;AYDY3PE7;V61K9VK7;DHK64TJ5;CS74R4R9;E2249VDY;RASG24N5;2DBH57A1;ZHYX6VGE;WTE4LSZZ;9RE66TL3;VCHXW3DN;VKPTAXLL',
      statusContent: 'dot',
      hash: 'VZPCYN37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZPCYN37'
    },
    {
      status: 210,
      sku: 'PM0079993',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334221',
      rtl_batch_array: '6334221',
      name_search: 'PRSAROSE',
      name: "Prunus subhirtella 'Autumnalis Rosea'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 12.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDSCGNSS;ESZ44LNE;K3W41VY2;JZ98V6KV;XCE4HG4T;H7VN2WVD;5WY66PWK;CXC9ZWBS;WDTDLPLW;E2JCVPAS',
      statusContent: 'dot',
      hash: '5HKCA1YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HKCA1YX'
    },
    {
      status: 210,
      sku: 'PM0079994',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314188',
      rtl_batch_array: '6314188',
      name_search: 'ACPMETAM',
      name: 'Acer pal. Metamorphosa',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 805,
      chnn_stock_retail: 805,
      sppl_prcp: 7.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VK5A4JZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VK5A4JZX'
    },
    {
      status: 210,
      sku: 'PM0079998',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334224',
      rtl_batch_array: '6334224',
      name_search: 'STCOBJAC',
      name: "Staphylea colchica 'Black Jack'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 11.327,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9Y9V5EK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Y9V5EK6'
    },
    {
      status: 210,
      sku: 'PM0080001',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334228',
      rtl_batch_array: '6334228',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 6.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: 'EV23ZLEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EV23ZLEL'
    },
    {
      status: 810,
      sku: 'PM0080002',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334233',
      rtl_batch_array: '6334233',
      name_search: 'HAIRUBIN',
      name: "Hamamelis intermedia 'Rubin'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 17.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ38ZTBB;7G4J3W6T;PP39767R;Y55BSRTZ;ENJCRJ9T;CV28YW5D;B9C67895;NYJSJGPR',
      statusContent: 'dot',
      hash: 'AV296YJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AV296YJA'
    },
    {
      status: 910,
      sku: 'PM0080003',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334239',
      rtl_batch_array: '6334239',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 14.267,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'BE76W42L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE76W42L'
    },
    {
      status: 910,
      sku: 'PM0080004',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334242',
      rtl_batch_array: '6334242',
      name_search: 'HAJZUCCA',
      name: "Hamamelis japonica 'Zuccariniana'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 14.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SB33RAWS',
      statusContent: 'dot',
      hash: 'D6YK67VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6YK67VB'
    },
    {
      status: 910,
      sku: 'PM0080005',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334243',
      rtl_batch_array: '6334243',
      name_search: 'HAJZUCCA',
      name: "Hamamelis japonica 'Zuccariniana'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 15.535,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SB33RAWS',
      statusContent: 'dot',
      hash: 'GLBYCY44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLBYCY44'
    },
    {
      status: 810,
      sku: 'PM0070294',
      core_name: 'Plant',
      sppl_ean: '8720349439454',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496655',
      rtl_batch_array: '5496655',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.518,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'A8245TXD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8245TXD'
    },
    {
      status: 810,
      sku: 'PM0080029',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334291',
      rtl_batch_array: '6334291',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 12.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '6TNDH9CL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TNDH9CL'
    },
    {
      status: 210,
      sku: 'PM0080030',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334294',
      rtl_batch_array: '6334294',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_prcp: 16.803,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: 'NSL9E5A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSL9E5A5'
    },
    {
      status: 210,
      sku: 'PM0080031',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334295',
      rtl_batch_array: '6334295',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 22.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: '82SL8SSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82SL8SSZ'
    },
    {
      status: 210,
      sku: 'PM0080032',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334296',
      rtl_batch_array: '6334296',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 2900,
      chnn_stock_retail: 2900,
      sppl_prcp: 26.256,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: 'H7L8HCXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7L8HCXH'
    },
    {
      status: 210,
      sku: 'PM0080033',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334297',
      rtl_batch_array: '6334297',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      sppl_size_code: '200225C15',
      rng_range_identifier: 'H200225C15',
      rng_range_description: 'H200 cm 225 cm C15',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 28.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: 'RLJAYVTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLJAYVTT'
    },
    {
      status: 810,
      sku: 'PM0080034',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334298',
      rtl_batch_array: '6334298',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 15.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: 'CE5LX7TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE5LX7TY'
    },
    {
      status: 210,
      sku: 'PM0080035',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334301',
      rtl_batch_array: '6334301',
      name_search: 'COAIHALO',
      name: 'Cornus alba Ivory Halo',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 7.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CR321S2S;GNGZHV8Y;S4PECZA9;JNA3KWYB;8GX37KT3',
      statusContent: 'dot',
      hash: 'NETZ215T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NETZ215T'
    },
    {
      status: 810,
      sku: 'PM0080036',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334302',
      rtl_batch_array: '6334302',
      name_search: 'COCVARIE',
      name: "Cornus controversa 'Variegata'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 16.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRHXAAP;VEK1D3NC;6WDTDNED;WGHLZ3D2;KL8RWG2A;WW5D6NVX;2C2XNTYL;N83SHYJJ;81Z2ZVRA;X5561DV4;2C6BAK81;71NWG3EX;4JSCJXVC;7ZBKNZ5Y',
      statusContent: 'dot',
      hash: 'K98X7D8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K98X7D8E'
    },
    {
      status: 810,
      sku: 'PM0080037',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334303',
      rtl_batch_array: '6334303',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 15.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: 'EE4SXTJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE4SXTJC'
    },
    {
      status: 810,
      sku: 'PM0080038',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334306',
      rtl_batch_array: '6334306',
      name_search: 'COKSAMAR',
      name: 'Cornus kousa Samaritan',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 21.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VRNSJCGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRNSJCGJ'
    },
    {
      status: 210,
      sku: 'PM0080039',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334310',
      rtl_batch_array: '6334310',
      name_search: 'FORMOR',
      name: "Forsythia Mar\u00e9e d'Or",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 4.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CWWEG2LL;EP63V2WJ;9GDRPBV1;49BEL117;C7GG6XKL;YYGHARGE;SR8KP4X3;DJZKVSZG',
      statusContent: 'dot',
      hash: '4BSZXPNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BSZXPNS'
    },
    {
      status: 210,
      sku: 'PM0080040',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334311',
      rtl_batch_array: '6334311',
      name_search: 'FOMOR',
      name: "Forsythia M\u00eal\u00e9e d'Or",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 4.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDTAAGSW;RVPBJNTS;PZX436EX;S5LR7XR4;L3WRG123;D8SY13ED;6KCLC4B4;2CBBL6AV',
      statusContent: 'dot',
      hash: '2W2VL6VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2W2VL6VD'
    },
    {
      status: 810,
      sku: 'PM0080041',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334313',
      rtl_batch_array: '6334313',
      name_search: 'HAIANGEL',
      name: "Hamamelis intermedia 'Angelly'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 17.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9V533Y61',
      statusContent: 'dot',
      hash: '76LAKBYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76LAKBYL'
    },
    {
      status: 210,
      sku: 'PM0074613',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6271372',
      rtl_batch_array: '6271372',
      name_search: 'HYMGRACE',
      name: "Hydrangea m. 'Gracewood'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2100,
      chnn_stock_retail: 2100,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKD61ZSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKD61ZSR'
    },
    {
      status: 210,
      sku: 'PM0015978',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6221963',
      rtl_batch_array: '6221963, 6147647, 6349043',
      name_search: 'ACCGOLD',
      name: "Achillea 'Coronation Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2024,
      chnn_stock_retail: 3292,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RK53DSVD;JY3SR1JC;TR9GVDJA;A9JKW7HY;X9Z5KEEA',
      statusContent: 'dot',
      hash: '29AD5SXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29AD5SXA'
    },
    {
      status: 210,
      sku: 'PM0067881',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284511',
      rtl_batch_array: '6252451, 6284511',
      name_search: 'ANHSCHAR',
      name: "Anemone hupehensis 'September Charm'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 985,
      chnn_stock_retail: 1842,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYA7S356;K4GEXNBW;RX7Z4A7B;5RVJXA14;8J2D3GSL;TEGRHXT8;N4XSZ13K;KXCRH8ZS;XHEGBS6C;G53YNSNJ',
      statusContent: 'dot',
      hash: '8G7WVBPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G7WVBPG'
    },
    {
      status: 910,
      sku: 'PM0071650',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAESCARE',
      name: "Mahonia eurybracteata 'Soft Caress'",
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      imageCore: 'N4AA3PC4;CD4JS8TD;5H313JL4;R2HPGR2A;7LRNS7R7',
      statusContent: 'dot',
      hash: 'AT53T32D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AT53T32D'
    },
    {
      status: 810,
      sku: 'PM0015646',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '5932345',
      rtl_batch_array: '5932345, 5902326, 6197139, 6014726, 6291687',
      name_search: 'CEPLUMBA',
      name: 'Ceratostigma plumbaginoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 16223,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JDPSCTN2;PGN26CCN',
      statusContent: 'dot',
      hash: '8P9V5PAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8P9V5PAG'
    },
    {
      status: 910,
      sku: 'PM0071842',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239937',
      rtl_batch_array: '6239937',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 23.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'CP1WZR2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP1WZR2Z'
    },
    {
      status: 810,
      sku: 'PM0080042',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334315',
      rtl_batch_array: '6334315',
      name_search: 'HAIAPHRO',
      name: "Hamamelis intermedia 'Aphrodite'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 53,
      chnn_stock_retail: 53,
      sppl_prcp: 17.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VCD51HJD;2TBEVLBS;YJG8VVR6;EDZ11GYS;JJNEACJS;SH4VVNXL;5XVYK2YB;HJZW5L3H;2H428NV7',
      statusContent: 'dot',
      hash: 'DZB4T8AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZB4T8AA'
    },
    {
      status: 910,
      sku: 'PM0071843',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239938',
      rtl_batch_array: '6239938',
      name_search: 'LISWORPL',
      name: "Liquidambar styraciflua 'Worplesdon'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 15.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8EGDRGJ6;KXVD9AXA',
      statusContent: 'dot',
      hash: 'XPDYNTTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPDYNTTG'
    },
    {
      status: 910,
      sku: 'PM0080043',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334316',
      rtl_batch_array: '6334316',
      name_search: 'HAIAUROR',
      name: "Hamamelis intermedia 'Aurora'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 17.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AP2ATBP;H7KXJ3KK;7SLN9BYZ;8EDXG55V',
      statusContent: 'dot',
      hash: '4PDLPSJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PDLPSJ6'
    },
    {
      status: 910,
      sku: 'PM0080044',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334323',
      rtl_batch_array: '6334323',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 19.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: 'VLAAYTZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLAAYTZT'
    },
    {
      status: 210,
      sku: 'PM0080045',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334333',
      rtl_batch_array: '6334333',
      name_search: 'VEHALBA',
      name: "Verbena hastata 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9CZ9L29B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CZ9L29B'
    },
    {
      status: 210,
      sku: 'PM0080046',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334334',
      rtl_batch_array: '6334334',
      name_search: 'VEHBSPIR',
      name: "Verbena hastata 'Blue Spires'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T88ZCSD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T88ZCSD1'
    },
    {
      status: 210,
      sku: 'PM0080047',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334335',
      rtl_batch_array: '6334335',
      name_search: 'VEHROSEA',
      name: "Verbena hastata 'Rosea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DC32WH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DC32WH3'
    },
    {
      status: 910,
      sku: 'PM0080048',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LYROBIN',
      name: 'Lythrum Robin',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore:
        '4BT2CGD9;6VATJAGJ;ELBNJ4EG;4X42TYE2;28V5GNDP;T19DDGSH;LDB6GLW8;7V9G5J33;51PH1N56;JSYALT1Y;BN6SVTL6;5XPY2BNN;9B596W57;XANPVZD6;HXPRYE37',
      statusContent: 'dot',
      hash: 'HELDCB3Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HELDCB3Z'
    },
    {
      status: 910,
      sku: 'PM0080049',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SABLEPHA',
      name: 'Salvia blepharophylla',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'BBG5VK6V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BBG5VK6V'
    },
    {
      status: 210,
      sku: 'PM0080050',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334339',
      rtl_batch_array: '6334339',
      name_search: 'GALSNOWS',
      name: "Gaura lindheimeri 'Snowstorm'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 854,
      chnn_stock_retail: 854,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8EZ7E384',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EZ7E384'
    },
    {
      status: 210,
      sku: 'PM0080051',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334340',
      rtl_batch_array: '6334340',
      name_search: 'HEULMARM',
      name: "Heuchera 'Lime Marmalade'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 764,
      chnn_stock_retail: 764,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2K6RAZ6B;5471CTLN;P1BJA3DV;ZJ41C743;XC6ES1PL;JS17Z8SG;CVWP95Y9;DCSZV8X5;SKWEW342;5JXZ9R5R;1AVRWHW1',
      statusContent: 'dot',
      hash: 'KELJ2CJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KELJ2CJS'
    },
    {
      status: 210,
      sku: 'PM0080052',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334341',
      rtl_batch_array: '6334341',
      name_search: 'NEJWALKE',
      name: 'Nepeta Junior Walker',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HRWBR36L;DLAZRLD3;Y3NYRCHX;253HKYWC;KDRXRW8E',
      statusContent: 'dot',
      hash: 'SRJ3CCBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SRJ3CCBP'
    },
    {
      status: 210,
      sku: 'PM0080053',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334342',
      rtl_batch_array: '6334342',
      name_search: 'SEFWGOLD',
      name: "Sedum floriferum 'Weihenstephaner Gold'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 860,
      chnn_stock_retail: 860,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55NKSBZC;DGR2B7S7;RP8WC93T;9L77ERP5;4ED49BE3;BV3Y6NB3;R96JXA6P;51T1JJJN;V1D4322Z;7S7X81TK;8PN6CBLJ;RV4B1T43;6W9GH2AK;K92DW8HA',
      statusContent: 'dot',
      hash: 'Z3D5B3H3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3D5B3H3'
    },
    {
      status: 910,
      sku: 'PM0080054',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASNERON',
      name: "Aster 'Neron'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '6XB11GYZ;ZBRSV9RG;8X53KKXC;Z93VGCX4;68R9PLLW;K2BD21TK;41RNERKK',
      statusContent: 'dot',
      hash: '63DN7134',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '63DN7134'
    },
    {
      status: 210,
      sku: 'PM0080055',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334347',
      rtl_batch_array: '6334347',
      name_search: 'ASTESCHN',
      name: "Aster ericoides 'Schneetanne'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 584,
      chnn_stock_retail: 584,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '45V7NJ44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45V7NJ44'
    },
    {
      status: 210,
      sku: 'PM0080056',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334348',
      rtl_batch_array: '6334348',
      name_search: 'ASNBKARM',
      name: "Aster novi-belgii 'Karminkuppel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 324,
      chnn_stock_retail: 324,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A7A2J9KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7A2J9KS'
    },
    {
      status: 210,
      sku: 'PM0080057',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334349',
      rtl_batch_array: '6334349',
      name_search: 'ASPLUTET',
      name: "Aster pyrenaeus 'Lutetia'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ADCXD1G4;42D3T3EX',
      statusContent: 'dot',
      hash: 'WXVNCGPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXVNCGPT'
    },
    {
      status: 210,
      sku: 'PM0080058',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334353',
      rtl_batch_array: '6334353',
      name_search: 'ECPARROT',
      name: "Echinacea 'Parrot'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TLNH4ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TLNH4ZA'
    },
    {
      status: 210,
      sku: 'PM0080059',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334354',
      rtl_batch_array: '6334354',
      name_search: 'ECPBKAHU',
      name: "Echinacea purpurea 'Big Kahuna'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 848,
      chnn_stock_retail: 848,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6W19S5T4',
      statusContent: 'dot',
      hash: 'E23NKXLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E23NKXLY'
    },
    {
      status: 910,
      sku: 'PM0080060',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ECPHPAPA',
      name: "Echinacea purpurea 'Hot Papaya'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'LP9EV144',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LP9EV144'
    },
    {
      status: 210,
      sku: 'PM0080061',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334356',
      rtl_batch_array: '6334356',
      name_search: 'ERYUCCIF',
      name: 'Eryngium yuccifolium',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 503,
      chnn_stock_retail: 503,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R86NLE1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R86NLE1W'
    },
    {
      status: 210,
      sku: 'PM0080062',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334357',
      rtl_batch_array: '6334357',
      name_search: 'KNPORANG',
      name: 'Kniphofia Poco Orange',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_prcp: 3.299,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K61WRSK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K61WRSK6'
    },
    {
      status: 210,
      sku: 'PM0080063',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334358',
      rtl_batch_array: '6334358',
      name_search: 'SCSTROSE',
      name: "Scutellaria suffrutescens 'Texas Rose'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 770,
      chnn_stock_retail: 770,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H9C126PW;T8HGTVG1;1Y8CV7AG;S1BYC42Z;H21T1T8A;VK8EWCC2',
      statusContent: 'dot',
      hash: 'KZ9124S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ9124S2'
    },
    {
      status: 210,
      sku: 'PM0080064',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334359',
      rtl_batch_array: '6334359',
      name_search: 'SECARL',
      name: "Sedum 'Carl'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 664,
      chnn_stock_retail: 664,
      sppl_prcp: 2.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1AAZNB;6CL18H27;4J9PWWYY;DJTJLNYJ;8G15Z36J;KTA6KJ11',
      statusContent: 'dot',
      hash: 'EDKWJT8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDKWJT8W'
    },
    {
      status: 910,
      sku: 'PM0082339',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GECASHIR',
      name: "Gentiana 'Crystal Ashiro'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CNHWXR68',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CNHWXR68'
    },
    {
      status: 210,
      sku: 'PM0082340',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350185',
      rtl_batch_array: '6350185',
      name_search: 'GESLPINK',
      name: "Gentiana scabra 'Little Pinkie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '872BX8B1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '872BX8B1'
    },
    {
      status: 210,
      sku: 'PM0082341',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350210',
      rtl_batch_array: '6350210',
      name_search: 'GEJJSILV',
      name: "Geranium hybrid 'Jolly Jewel Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 545,
      chnn_stock_retail: 545,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '829ZVB54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '829ZVB54'
    },
    {
      status: 910,
      sku: 'PM0082342',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GESFAUTU',
      name: "Geranium sanguineum 'Feu d'Autumn'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HGE2TJGT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HGE2TJGT'
    },
    {
      status: 210,
      sku: 'PM0000069',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349909',
      rtl_batch_array: '6349909, 6295490',
      name_search: 'CAFFALLS',
      name: "Carex 'Feather Falls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 723,
      chnn_stock_retail: 909,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SWTEBKB2;L2483766;SP761YTY;76N9NLAJ;CY3EZYXS;GN3N9S8T;PTBBRL6A;4WP8RTZK;PY2L3NWL;56GRS1YN;VVVTBCA6;4S3RAXZ6;54D1RL41;SKA1G2BS',
      statusContent: 'dot',
      hash: 'HN1X2YR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HN1X2YR5'
    },
    {
      status: 210,
      sku: 'PM0082343',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350252',
      rtl_batch_array: '6350252',
      name_search: 'GETRAVER',
      name: 'Geranium traversii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPPSHYJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPPSHYJS'
    },
    {
      status: 210,
      sku: 'PM0082344',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350259',
      rtl_batch_array: '6350259',
      name_search: 'GECWAREN',
      name: "Geum coccineum 'Werner Arends'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJ7NDWGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJ7NDWGD'
    },
    {
      status: 210,
      sku: 'PM0010276',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6333223',
      rtl_batch_array: '6350106, 6037875, 6291690, 6333223',
      name_search: 'ECPURPUR',
      name: 'Echinacea purpurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 45960,
      chnn_stock_retail: 67079,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7TXBJJ8K;4TZJCNLZ;2S124CT9;G12TBABS;VXE1Z3EP;A727S678;P9TWHCKA;1GE2ZB3S;7G16KJXE;18JGWAYG;CCHNWHH9;HY2A66AY;7ZNRBA3P;5CWD2TGW;WT6W1T3W;V53VY5JB;11S2XENG;XKSLB8D1;CS8WTBXD;NBL2DRRY;WW6GS14S;BG8A1C8L;JST2T4LS;PR78AA8R',
      statusContent: 'dot',
      hash: 'JPNAXTDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPNAXTDH'
    },
    {
      status: 210,
      sku: 'PM0082345',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350261',
      rtl_batch_array: '6350261',
      name_search: 'GEFSTORM',
      name: "Geum 'Fire Storm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 307,
      chnn_stock_retail: 307,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6H4L58BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6H4L58BV'
    },
    {
      status: 210,
      sku: 'PM0082346',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350262',
      rtl_batch_array: '6350262',
      name_search: 'GEPPETTI',
      name: "Geum 'Pink Petticoats'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '25TY37L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25TY37L6'
    },
    {
      status: 210,
      sku: 'PM0005665',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 7,
      btch_active_retail: '6177953',
      rtl_batch_array:
        '5364074, 6281849, 6177953, 6329935, 6385451, 6333237, 6348321',
      name_search: 'GALINDHE',
      name: 'Gaura lindheimeri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 28269,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RP4K3PBG;D2SZPWDW;4E31EAV4;SN719255;67TSPL34;HSDR43A1;E3VPEGWX;GC2GESRA;NDEYHPR6;VA4ABZ32;JBG9PR4L',
      statusContent: 'dot',
      hash: 'KVEAL3XJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVEAL3XJ'
    },
    {
      status: 210,
      sku: 'PM0012452',
      core_name: 'Plant',
      sppl_ean: '8720664812406',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488248',
      rtl_batch_array: '5488248',
      name_search: 'WEASRED',
      name: 'Weigela All Summer Red',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LA1LJBN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA1LJBN5'
    },
    {
      status: 210,
      sku: 'PM0003423',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220817',
      rtl_batch_array: '6220817, 6305172, 5495385',
      name_search: 'HOGUACAM',
      name: "Hosta 'Guacamole'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1291,
      chnn_stock_retail: 2343,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9YA69HSY;4SE4X6X6;YDTB3R46;5GKDYSLL;VT113561;TX9H3LCY;VGBYR7BB;A2BXHDR6;R1VKKB55',
      statusContent: 'dot',
      hash: 'LLS7AC5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLS7AC5S'
    },
    {
      status: 210,
      sku: 'PM0082347',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350265',
      rtl_batch_array: '6350265',
      name_search: 'GESLIME',
      name: "Geum 'Sunkissed Lime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ENG6S1CP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENG6S1CP'
    },
    {
      status: 210,
      sku: 'PM0082348',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348329',
      rtl_batch_array: '6350268, 6348329',
      name_search: 'GEURBANU',
      name: 'Geum urbanum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 876,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5YANDB7W',
      statusContent: 'dot',
      hash: 'YXWZKVX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXWZKVX8'
    },
    {
      status: 210,
      sku: 'PM0082349',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350273',
      rtl_batch_array: '6350273',
      name_search: 'GYDPLUMO',
      name: "Gymnocarpium dryopteris 'Plumosum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1GK3B49A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GK3B49A'
    },
    {
      status: 210,
      sku: 'PM0082350',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350274',
      rtl_batch_array: '6350274',
      name_search: 'HAMMWINE',
      name: 'Hakonechloa macra Mulled Wine',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLKL1PC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLKL1PC3'
    },
    {
      status: 210,
      sku: 'PM0082351',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350275',
      rtl_batch_array: '6350275',
      name_search: 'HAMNICOL',
      name: "Hakonechloa macra 'Nicolas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1977,
      chnn_stock_retail: 1977,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WSG9E5XH;4GRVGSRC;G437WSAC;9XJNV9E5;D7YE29DV;TL5R8GZ2;ZS431ZDP',
      statusContent: 'dot',
      hash: 'RS8TWZWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS8TWZWA'
    },
    {
      status: 810,
      sku: 'PM0082352',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350314',
      rtl_batch_array: '6350314',
      name_search: 'HEDPLENU',
      name: "Helianthus decapetalus 'Plenus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11PPJHRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11PPJHRZ'
    },
    {
      status: 210,
      sku: 'PM0082353',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350335',
      rtl_batch_array: '6350335, 6352637',
      name_search: 'HENPRAEC',
      name: "Helleborus niger 'Praecox'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4284,
      chnn_stock_retail: 4658,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SY7H198N',
      statusContent: 'dot',
      hash: 'N3ZWX2BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3ZWX2BN'
    },
    {
      status: 210,
      sku: 'PM0015170',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315048',
      rtl_batch_array: '6220223, 6350101, 6315048',
      name_search: 'ECPALLID',
      name: 'Echinacea pallida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2687,
      chnn_stock_retail: 10356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZWH8WSY9;XGB5ZJYB;CZN9VC1Y;KX7W866N;Y6LN3ZDW;YBYCG21Z',
      statusContent: 'dot',
      hash: 'RA5XJL9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA5XJL9X'
    },
    {
      status: 210,
      sku: 'PM0082354',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350337',
      rtl_batch_array: '6350337, 6352640',
      name_search: 'HEODEPUR',
      name: "Helleborus orientalis 'Double Ellen Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 657,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8193GHWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8193GHWN'
    },
    {
      status: 210,
      sku: 'PM0050479',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222189',
      rtl_batch_array: '6222189',
      name_search: 'SPJMANON',
      name: "Spiraea japonica 'Manon'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZAWNADB',
      statusContent: 'dot',
      hash: 'T7NSHVLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7NSHVLJ'
    },
    {
      status: 210,
      sku: 'PM0007717',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '5932403',
      rtl_batch_array: '6084932, 6112406, 5932403, 5866506, 6115122',
      name_search: 'GECCAMBR',
      name: "Geranium cantabrigiense 'Cambridge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 646,
      chnn_stock_retail: 16248,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '57XYWWA3;T583W45B;8LD5V18W',
      statusContent: 'dot',
      hash: 'TLKANHY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLKANHY4'
    },
    {
      status: 910,
      sku: 'PM0082355',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HELGLORY',
      name: "Hemerocallis 'Longfields Glory'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'H6GX19B5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H6GX19B5'
    },
    {
      status: 210,
      sku: 'PM0005530',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6385811',
      rtl_batch_array: '6236308, 5495576, 6310196, 6385811',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2792,
      chnn_stock_retail: 3624,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'XDEZRDH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDEZRDH6'
    },
    {
      status: 210,
      sku: 'PM0005488',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6220355',
      rtl_batch_array: '6220355, 6176226, 6254417, 6353299, 6287692, 6348421',
      name_search: 'SEHERBST',
      name: "Sedum 'Herbstfreude'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 18680,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P1SA8853;SP34B88L;KDVZDRVP;TSXB4ZGP;PL3Z54Y6;6R64C6KZ;WTZ1ALZ3;NCR16GGC;BT3LJ6TC;XACL25K2;LNBHRD3P;A53L33R4;R8BANN8D;97LVH7GN;4WAX5TZP;E1NNJE6Z;4VZ2LD1K;48YCYHLX;BALG54TJ',
      statusContent: 'dot',
      hash: 'ZD7VRN4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD7VRN4T'
    },
    {
      status: 210,
      sku: 'PM0013668',
      core_name: 'Plant',
      sppl_ean: '8720664688445',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5866492',
      rtl_batch_array: '5866492, 6350081',
      name_search: 'ECPSPINK',
      name: "Echinacea purpurea 'Sensation Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 151,
      chnn_stock_retail: 1035,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZX65G1EW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX65G1EW'
    },
    {
      status: 210,
      sku: 'PM0082356',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355365',
      rtl_batch_array: '6350368, 6355365',
      name_search: 'HEMATRON',
      name: 'Hesperis matronalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 502,
      chnn_stock_retail: 1451,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8K31ET92;1AKTALST;P6BSHZVG;H7LKJBCC;YY63BE81;K9WP5LYL;1ZPJC654;K9VE15TE;ZCRX7LTK;DSWNSS96;G2ES7W65',
      statusContent: 'dot',
      hash: 'DAH5RX96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAH5RX96'
    },
    {
      status: 210,
      sku: 'PM0082357',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350369',
      rtl_batch_array: '6350369',
      name_search: 'HEAWAVES',
      name: "Heuchera 'Amber Waves'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W76A71LR;2ATA4LLA',
      statusContent: 'dot',
      hash: 'T5482LKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5482LKN'
    },
    {
      status: 210,
      sku: 'PM0082358',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350371',
      rtl_batch_array: '6350371',
      name_search: 'HEALEAVE',
      name: "Heuchera 'Autumn Leaves'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDL5Y131;8VXY78ZE;1WTCAWER',
      statusContent: 'dot',
      hash: 'ENZ8NAWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENZ8NAWK'
    },
    {
      status: 210,
      sku: 'PM0082359',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350373',
      rtl_batch_array: '6350373',
      name_search: 'HEBNOTTE',
      name: "Heuchera 'Bella Notte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '61TCD67B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61TCD67B'
    },
    {
      status: 210,
      sku: 'PM0082360',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350374',
      rtl_batch_array: '6350374',
      name_search: 'HEBMARMA',
      name: "Heuchera 'Berry Marmalade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '554XCBKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '554XCBKJ'
    },
    {
      status: 210,
      sku: 'PM0082361',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350381',
      rtl_batch_array: '6350381',
      name_search: 'HEBTAFFE',
      name: "Heuchera 'Black Taffeta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TLK9SWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TLK9SWP'
    },
    {
      status: 210,
      sku: 'PM0082362',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350382',
      rtl_batch_array: '6350382',
      name_search: 'HEBLACKO',
      name: "Heuchera 'Blackout'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XYRDW3WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYRDW3WB'
    },
    {
      status: 210,
      sku: 'PM0082363',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350383',
      rtl_batch_array: '6350383',
      name_search: 'HEBLONDI',
      name: "Heuchera 'Blondie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 593,
      chnn_stock_retail: 593,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84AZ7GCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84AZ7GCW'
    },
    {
      status: 210,
      sku: 'PM0082364',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350384',
      rtl_batch_array: '6350384, 6320879',
      name_search: 'HEBLIME',
      name: "Heuchera 'Blondie in Lime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 351,
      chnn_stock_retail: 443,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DXP5EBY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXP5EBY5'
    },
    {
      status: 210,
      sku: 'PM0082365',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350385',
      rtl_batch_array: '6350385',
      name_search: 'HEBDINOS',
      name: "Heuchera 'Bloody Dinosaur'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '76R9PYXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76R9PYXB'
    },
    {
      status: 210,
      sku: 'PM0082366',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350388',
      rtl_batch_array: '6350388',
      name_search: 'HECASSIS',
      name: "Heuchera 'Cassis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 549,
      chnn_stock_retail: 549,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELZLK4AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELZLK4AW'
    },
    {
      status: 210,
      sku: 'PM0082367',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350389',
      rtl_batch_array: '6350389, 6352648',
      name_search: 'HECCOLA',
      name: "Heuchera 'Cherry Cola'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1182,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8VJBA4Z',
      statusContent: 'dot',
      hash: 'XNC8X4NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNC8X4NJ'
    },
    {
      status: 210,
      sku: 'PM0082368',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350391',
      rtl_batch_array: '6350391',
      name_search: 'HECSILVE',
      name: "Heuchera 'Cinnabar Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S6A6GR18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6A6GR18'
    },
    {
      status: 210,
      sku: 'PM0082369',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350395',
      rtl_batch_array: '6350395, 6380819',
      name_search: 'HEDDAWN',
      name: "Heuchera 'Delta Dawn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C85V27JZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C85V27JZ'
    },
    {
      status: 210,
      sku: 'PM0082370',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350396',
      rtl_batch_array: '6350396',
      name_search: 'HEEIVORY',
      name: 'Heuchera Ebony & Ivory',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LDW4NCGG;SXTKXSW5',
      statusContent: 'dot',
      hash: 'GJRH2449',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJRH2449'
    },
    {
      status: 210,
      sku: 'PM0082371',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350397',
      rtl_batch_array: '6350397',
      name_search: 'HEELECTR',
      name: "Heuchera 'Electra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 706,
      chnn_stock_retail: 706,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8EDS1XVS;ERSASZ66',
      statusContent: 'dot',
      hash: '9NPHGAH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NPHGAH5'
    },
    {
      status: 210,
      sku: 'PM0082372',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350398',
      rtl_batch_array: '6350398',
      name_search: 'HEELIME',
      name: "Heuchera 'Electric Lime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 424,
      chnn_stock_retail: 424,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPBENX6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPBENX6T'
    },
    {
      status: 210,
      sku: 'PM0082373',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350399',
      rtl_batch_array: '6369739, 6350399, 6320882',
      name_search: 'HEFALARM',
      name: "Heuchera 'Fire Alarm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1120,
      chnn_stock_retail: 1675,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C91R4N27;C1DS9776',
      statusContent: 'dot',
      hash: 'G1CPH8RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1CPH8RX'
    },
    {
      status: 810,
      sku: 'PM0082374',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352651',
      rtl_batch_array: '6350401, 6352651',
      name_search: 'HEUFRED',
      name: 'Heuchera Forever Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 98,
      chnn_stock_retail: 842,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NGHK5WD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGHK5WD7'
    },
    {
      status: 210,
      sku: 'PM0082375',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350403',
      rtl_batch_array: '6350403',
      name_search: 'HEGAUGUI',
      name: "Heuchera 'Gauguin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7GVB581N;DK35TRPV;K5W2RZ23;JW16YKRV;ZP9VVVTT',
      statusContent: 'dot',
      hash: 'R978EC44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R978EC44'
    },
    {
      status: 210,
      sku: 'PM0082376',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350406',
      rtl_batch_array: '6350406',
      name_search: 'HEGOTHAM',
      name: "Heuchera 'Gotham'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1P1LCL38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1P1LCL38'
    },
    {
      status: 210,
      sku: 'PM0019218',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6192664',
      rtl_batch_array: '6077455, 6192664',
      name_search: 'ASDESTAR',
      name: "Aster divaricatus 'Eastern Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 2728,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '77GWWZL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77GWWZL4'
    },
    {
      status: 810,
      sku: 'PM0074621',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272781',
      rtl_batch_array: '6272781',
      name_search: 'ASTREFFP',
      name: "Aster 'Treffpunkt'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NK3NV63W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NK3NV63W'
    },
    {
      status: 210,
      sku: 'PM0074622',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272784',
      rtl_batch_array: '6272784',
      name_search: 'ASNBWLAD',
      name: "Aster novi-belgii 'White Ladies'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9TA4S99G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TA4S99G'
    },
    {
      status: 210,
      sku: 'PM0074623',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272785',
      rtl_batch_array: '6272785',
      name_search: 'CRMARITI',
      name: 'Crambe maritima',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_prcp: 3.002,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S94JR8J6',
      statusContent: 'dot',
      hash: 'Z96CBHN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z96CBHN3'
    },
    {
      status: 210,
      sku: 'PM0074624',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272786',
      rtl_batch_array: '6272786',
      name_search: 'ECDCANDY',
      name: 'Echinacea Delicious Candy',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JDVH5KSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDVH5KSV'
    },
    {
      status: 210,
      sku: 'PM0074627',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284544',
      rtl_batch_array: '6284544, 6272793',
      name_search: 'PEAKABOU',
      name: "Persicaria affinis 'Kabouter'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1297,
      chnn_stock_retail: 1621,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEAKT6JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEAKT6JY'
    },
    {
      status: 810,
      sku: 'PM0080065',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335107',
      rtl_batch_array: '6335107',
      name_search: 'ACMCASSI',
      name: "Achillea millefolium 'Cassis'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y6J9LSPE;WS8B399W',
      statusContent: 'dot',
      hash: '1B2NVHTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1B2NVHTG'
    },
    {
      status: 210,
      sku: 'PM0080066',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335108',
      rtl_batch_array: '6335108',
      name_search: 'ASMCASSI',
      name: "Aster (U) 'Monte Cassino'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKPK254B;7PLW27PY',
      statusContent: 'dot',
      hash: 'ZRBXG2NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRBXG2NJ'
    },
    {
      status: 210,
      sku: 'PM0080067',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335109',
      rtl_batch_array: '6335109',
      name_search: 'PONRMCBE',
      name: "Potentilla nepalensis 'Ron McBeath'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NJNXLN2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJNXLN2S'
    },
    {
      status: 210,
      sku: 'PM0080068',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335115',
      rtl_batch_array: '6335115',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 1449,
      chnn_stock_retail: 1449,
      sppl_prcp: 24.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: '63PJ6RB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63PJ6RB6'
    },
    {
      status: 910,
      sku: 'PM0080069',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335117',
      rtl_batch_array: '6335117',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'CZ4CR1TG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZ4CR1TG'
    },
    {
      status: 210,
      sku: 'PM0080070',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335118',
      rtl_batch_array: '6335118',
      name_search: 'COFCCHIE',
      name: "Cornus florida 'Cherokee Chief'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 18.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZW8K98K',
      statusContent: 'dot',
      hash: 'A7W7T4SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7W7T4SG'
    },
    {
      status: 910,
      sku: 'PM0080071',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335119',
      rtl_batch_array: '6335119',
      name_search: 'COKSGOLD',
      name: "Cornus kousa 'Summer Gold'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 15.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D8CAK9LY;GCGB22CH',
      statusContent: 'dot',
      hash: '4K8X5XNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K8X5XNY'
    },
    {
      status: 210,
      sku: 'PM0080072',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335120',
      rtl_batch_array: '6335120',
      name_search: 'COCLILLA',
      name: "Cotinus coggygria 'Lilla'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 7.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WPLH2NKK;P1DCGV94;PRZ6KVBN;SJBPG26A;G5VCRB8C;VHYA3S13;9DJ98K7G;7YPNVDV5',
      statusContent: 'dot',
      hash: 'S5EXEHC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5EXEHC6'
    },
    {
      status: 210,
      sku: 'PM0080073',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335121',
      rtl_batch_array: '6335121',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.387,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'N3AW4K7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3AW4K7J'
    },
    {
      status: 210,
      sku: 'PM0080074',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335122',
      rtl_batch_array: '6335122',
      name_search: 'LETHUNBE',
      name: 'Lespedeza thunbergii',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 5.422,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HNYLCBZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNYLCBZ1'
    },
    {
      status: 210,
      sku: 'PM0074614',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272769',
      rtl_batch_array: '6272769',
      name_search: 'TRSGREEN',
      name: "Tradescantia (A) 'Sweet Green'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 468,
      chnn_stock_retail: 468,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJ8DCNEB',
      statusContent: 'dot',
      hash: 'E4PE4AJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4PE4AJH'
    },
    {
      status: 210,
      sku: 'PM0082377',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350407',
      rtl_batch_array: '6350407',
      name_search: 'HEGAMETH',
      name: 'Heuchera Grande Amethyst',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BZH394Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BZH394Y'
    },
    {
      status: 910,
      sku: 'PM0080075',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335123',
      rtl_batch_array: '6335123',
      name_search: 'MAAMUREN',
      name: 'Maackia amurensis',
      sppl_size_code: '125150C25',
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 37.306,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6Y47J9PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Y47J9PY'
    },
    {
      status: 210,
      sku: 'PM0074618',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272776',
      rtl_batch_array: '6272776',
      name_search: 'HESHANGH',
      name: "Heuchera 'Shanghai'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DGSAJ4YP',
      statusContent: 'dot',
      hash: 'EWCR74AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWCR74AX'
    },
    {
      status: 810,
      sku: 'PM0074619',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272777',
      rtl_batch_array: '6272777',
      name_search: 'HESSCROL',
      name: "Heuchera 'Silver Scrolls'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6LR72VW;CTS1P9YL;Y9S868BR;C7K3SNJA;XRVLGCL9;95X2CRJK;KLR1LTE5;7VWGRS7Y;K26T9P5X;YRT89GZ8',
      statusContent: 'dot',
      hash: '1DTSJL8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DTSJL8D'
    },
    {
      status: 210,
      sku: 'PM0080076',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335124',
      rtl_batch_array: '6335124',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1820,
      chnn_stock_retail: 1820,
      sppl_prcp: 3.462,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'L9XB2A3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9XB2A3Y'
    },
    {
      status: 210,
      sku: 'PM0082378',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350409',
      rtl_batch_array: '6350409',
      name_search: 'HEGUACAM',
      name: "Heuchera 'Guacamole'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7EJGHRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7EJGHRL'
    },
    {
      status: 210,
      sku: 'PM0074620',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272778',
      rtl_batch_array: '6272778',
      name_search: 'HEUWROSE',
      name: "Heuchera 'Wild Rose'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ERJD8W9Z;4TCAHK72;TLHYVAZV;SB7E2BV9;AWW1APZJ',
      statusContent: 'dot',
      hash: 'LAEA1LTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAEA1LTY'
    },
    {
      status: 910,
      sku: 'PM0071844',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240172',
      rtl_batch_array: '6240172',
      name_search: 'MOALBA',
      name: 'Morus alba',
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 16.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XA9H45K6;LJ74YVZR;RS58BJT4',
      statusContent: 'dot',
      hash: 'B5KDXEVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5KDXEVN'
    },
    {
      status: 910,
      sku: 'PM0071845',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240175',
      rtl_batch_array: '6240175',
      name_search: 'PHAMUREN',
      name: 'Phellodendron amurense',
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6LKWKH52',
      statusContent: 'dot',
      hash: 'JNX7SPYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNX7SPYD'
    },
    {
      status: 210,
      sku: 'PM0082379',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350411',
      rtl_batch_array: '6350411',
      name_search: 'HELCHIFF',
      name: "Heuchera 'Lemon Chiffon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A4AEY48R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4AEY48R'
    },
    {
      status: 210,
      sku: 'PM0082380',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350413',
      rtl_batch_array: '6350413',
      name_search: 'HELRICKE',
      name: "Heuchera 'Lime Rickey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1128,
      chnn_stock_retail: 1128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R7VANG5G;KXVJKRCL',
      statusContent: 'dot',
      hash: 'P1GZ1NYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1GZ1NYJ'
    },
    {
      status: 210,
      sku: 'PM0082381',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350414',
      rtl_batch_array: '6350414',
      name_search: 'HELRUFFL',
      name: "Heuchera 'Lace Ruffles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WTSDKL7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTSDKL7G'
    },
    {
      status: 210,
      sku: 'PM0082382',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350415',
      rtl_batch_array: '6350415',
      name_search: 'HEPLIPST',
      name: "Heuchera 'Pink Lipstick'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1B6AY3WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1B6AY3WE'
    },
    {
      status: 210,
      sku: 'PM0082383',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350416',
      rtl_batch_array: '6350416, 6338939',
      name_search: 'HEMAGNUM',
      name: "Heuchera 'Magnum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 258,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YRD8Y2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YRD8Y2Y'
    },
    {
      status: 210,
      sku: 'PM0082384',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350418',
      rtl_batch_array: '6350418',
      name_search: 'HEMARS',
      name: "Heuchera 'Mars'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CVB34AK2;L25J5DRS;VKJZXCDP;11DSZ88V',
      statusContent: 'dot',
      hash: 'TLN2YARJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLN2YARJ'
    },
    {
      status: 210,
      sku: 'PM0082385',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350424',
      rtl_batch_array: '6350424',
      name_search: 'HEYUNNAN',
      name: 'Hedychium yunnanense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GWSDR8SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWSDR8SY'
    },
    {
      status: 210,
      sku: 'PM0082386',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350426',
      rtl_batch_array: '6350426',
      name_search: 'HENELIME',
      name: 'Heuchera Northern Exposure Lime',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9JZ6GD8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JZ6GD8D'
    },
    {
      status: 210,
      sku: 'PM0082387',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350427',
      rtl_batch_array: '6350427, 6320875',
      name_search: 'HENERED',
      name: 'Heuchera Northern Exposure Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '85KXY63A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85KXY63A'
    },
    {
      status: 210,
      sku: 'PM0082388',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350428',
      rtl_batch_array: '6350428',
      name_search: 'HENESIEN',
      name: 'Heuchera Northern Exposure Sienna',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EBPLPDTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBPLPDTV'
    },
    {
      status: 210,
      sku: 'PM0082389',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350429',
      rtl_batch_array: '6350429',
      name_search: 'HENESILV',
      name: 'Heuchera Northern Exposure Silver',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EGNLR6W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGNLR6W1'
    },
    {
      status: 210,
      sku: 'PM0082390',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350431',
      rtl_batch_array: '6350431',
      name_search: 'HEPAPRIK',
      name: "Heuchera 'Paprika'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 714,
      chnn_stock_retail: 714,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EEK46Y2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEK46Y2A'
    },
    {
      status: 210,
      sku: 'PM0082391',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350433',
      rtl_batch_array: '6350433',
      name_search: 'HEPCRISP',
      name: "Heuchera 'Peach Crisp'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y6WBT1D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6WBT1D3'
    },
    {
      status: 210,
      sku: 'PM0082392',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350434',
      rtl_batch_array: '6369743, 6350434, 6380820',
      name_search: 'HEPFLAMB',
      name: "Heuchera 'Peach Flamb\u00e9'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1462,
      chnn_stock_retail: 1670,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W53X51GK;1SC3B1JD;DVL178TK;89KY8N7L;LPT6SSA7',
      statusContent: 'dot',
      hash: 'DZBNDHAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZBNDHAS'
    },
    {
      status: 210,
      sku: 'PM0082393',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350435',
      rtl_batch_array: '6350435, 6374019',
      name_search: 'HEPEPPER',
      name: "Heuchera hybrid 'Peppermint'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 380,
      chnn_stock_retail: 482,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7T32H5ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7T32H5ZD'
    },
    {
      status: 210,
      sku: 'PM0082394',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350439',
      rtl_batch_array: '6350439',
      name_search: 'HEPISTAC',
      name: "Heuchera 'Pistache'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHAC9CLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHAC9CLH'
    },
    {
      status: 210,
      sku: 'PM0082395',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350441',
      rtl_batch_array: '6350441',
      name_search: 'HEPPERRI',
      name: "Heuchera 'Pretty Perrine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZZAWP1W',
      statusContent: 'dot',
      hash: '956AL8TH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '956AL8TH'
    },
    {
      status: 810,
      sku: 'PM0082396',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350442',
      rtl_batch_array: '6350442, 6374024',
      name_search: 'HERSEA',
      name: "Heuchera 'Red Sea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 166,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1GN9S8S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GN9S8S1'
    },
    {
      status: 210,
      sku: 'PM0082397',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350444',
      rtl_batch_array: '6350444',
      name_search: 'HERENOIR',
      name: "Heuchera 'Renoir'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VV97AZLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VV97AZLK'
    },
    {
      status: 210,
      sku: 'PM0082398',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350445',
      rtl_batch_array: '6350445, 6320884',
      name_search: 'HERIO',
      name: "Heuchera 'Rio'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 584,
      chnn_stock_retail: 665,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '42XHPKYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42XHPKYY'
    },
    {
      status: 210,
      sku: 'PM0082399',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350446',
      rtl_batch_array: '6350446',
      name_search: 'HERAMBER',
      name: "Heuchera 'Rockies Amber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZTSE8T3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTSE8T3W'
    },
    {
      status: 210,
      sku: 'PM0082400',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350447',
      rtl_batch_array: '6350447',
      name_search: 'HEURBEER',
      name: "Heuchera 'Root Beer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '68BDY39B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68BDY39B'
    },
    {
      status: 210,
      sku: 'PM0082401',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350448',
      rtl_batch_array: '6350448',
      name_search: 'HESHANGH',
      name: "Heuchera 'Shanghai'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DGSAJ4YP',
      statusContent: 'dot',
      hash: 'TA7DN9DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA7DN9DY'
    },
    {
      status: 210,
      sku: 'PM0082402',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350449',
      rtl_batch_array: '6350449',
      name_search: 'HESDOLLA',
      name: "Hebe 'Silver Dollar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCSWTKE3;AX7K4K3W',
      statusContent: 'dot',
      hash: 'S22SR5AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S22SR5AA'
    },
    {
      status: 210,
      sku: 'PM0082403',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350454',
      rtl_batch_array: '6350454',
      name_search: 'HETOKYO',
      name: "Heuchera hybrid 'Tokyo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERYVV6H2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERYVV6H2'
    },
    {
      status: 210,
      sku: 'PM0082404',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350455',
      rtl_batch_array: '6350455',
      name_search: 'HEVNIGHT',
      name: "Heuchera 'Velvet Night'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZYGJ87ZV;VCSHZJJ6;BK8P6513',
      statusContent: 'dot',
      hash: 'D3KNX5R6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3KNX5R6'
    },
    {
      status: 210,
      sku: 'PM0082405',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350456',
      rtl_batch_array: '6350456',
      name_search: 'HEVESUVI',
      name: "Heuchera 'Vesuvius'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NGZCNLZE;8Z2PCJ2R',
      statusContent: 'dot',
      hash: 'AP8K4TRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AP8K4TRA'
    },
    {
      status: 210,
      sku: 'PM0074625',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272787',
      rtl_batch_array: '6272787',
      name_search: 'ECFOBICO',
      name: "Echinacea 'Fountain Orange Bicolour'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LB481Y9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB481Y9B'
    },
    {
      status: 210,
      sku: 'PM0082406',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350458',
      rtl_batch_array: '6350458',
      name_search: 'HEWALNUT',
      name: "Heuchera hybrid 'Walnut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRCVL83T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRCVL83T'
    },
    {
      status: 210,
      sku: 'PM0074616',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272774',
      rtl_batch_array: '6272774',
      name_search: 'HEMARMAL',
      name: "Heuchera 'Marmalade'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2E3D4BNL;BB94WBTB;28RKG91V;ETBZED7N;ZCV4GTHV;CNR8E1DR;4Y62BWT1;CC54DSKH;CYC5NB6R;B66536PT;C4V31773;WAPLRVCD;JHPNWADY;5NSV9J8R;B3PC9KWK',
      statusContent: 'dot',
      hash: '8V8BPYWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8V8BPYWW'
    },
    {
      status: 210,
      sku: 'PM0082407',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350459',
      rtl_batch_array: '6350459',
      name_search: 'HEWCAMAR',
      name: "Heuchera World Caffe 'Amaretto'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RE9JJ2CL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RE9JJ2CL'
    },
    {
      status: 210,
      sku: 'PM0074617',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272775',
      rtl_batch_array: '6272775',
      name_search: 'HEOBSIDI',
      name: "Heuchera 'Obsidian'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '96JEHKY1;BPYSP6GS;G5SS1DS4;GH6L5ZCX;8C6GY8L1;TYZDGLW3;PCGYKY93',
      statusContent: 'dot',
      hash: '4GPCCNLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GPCCNLX'
    },
    {
      status: 210,
      sku: 'PM0082408',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350463',
      rtl_batch_array: '6350463',
      name_search: 'HEATROPI',
      name: "Heucherella 'Art Tropico'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLLPXBAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLLPXBAL'
    },
    {
      status: 210,
      sku: 'PM0082409',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350464',
      rtl_batch_array: '6350464',
      name_search: 'HEBRIDGE',
      name: "Heucherella 'Blue Ridge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4DYKV4LA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DYKV4LA'
    },
    {
      status: 210,
      sku: 'PM0082410',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350465',
      rtl_batch_array: '6350465',
      name_search: 'HEBLANTE',
      name: "Heucherella 'Brass Lantern'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NL2J5VVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL2J5VVS'
    },
    {
      status: 210,
      sku: 'PM0082411',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350466',
      rtl_batch_array: '6350466',
      name_search: 'HEBRUM',
      name: "Heucherella 'Buttered Rum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KHGK9KZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHGK9KZL'
    },
    {
      status: 210,
      sku: 'PM0082412',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350467',
      rtl_batch_array: '6350467',
      name_search: 'HEUCICE',
      name: "Heucherella 'Cracked Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCCTSRVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCCTSRVT'
    },
    {
      status: 210,
      sku: 'PM0026392',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 5,
      btch_active_retail: '6184143',
      rtl_batch_array: '6184143, 6161993, 6164431, 6333307, 6348426',
      name_search: 'STBYZANT',
      name: 'Stachys byzantina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 13933,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1CV8LTLG;TBP1W3JB;PHYN521K;WDCB8LY9;KPCCBH3P;RAJY27N3;64LP9ZBS;28RBC2XV;LBL5V6SD;AHNGGL28;XZ8K5AHG;GB2YHYLE;P32TXN73;KSSS28D1;HN5RBGET;9RR28RE3;J2SZ2T3S;SSYWVXGB;BNHC6KSB;ZJNDGAES;NYHPYAVJ',
      statusContent: 'dot',
      hash: '13ERGNWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13ERGNWG'
    },
    {
      status: 210,
      sku: 'PM0030198',
      core_name: 'Plant',
      sppl_ean: '8720664868182',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6084723',
      rtl_batch_array: '6084723, 6350527, 6164313, 6217181',
      name_search: 'HOFWILLI',
      name: "Hosta 'Frances Williams'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5376,
      chnn_stock_retail: 8563,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LNGTS6XP;93Z2AGV3;419YB131;A1RST7AP;TTA42KKK;NAD3R12L;ZH8R333C;Z7B9YTE3;SZCN38VD;2YJZVKYE;SYGRP3NC;VRET55BV;TEEA5X96',
      statusContent: 'dot',
      hash: '1Y7GG49E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y7GG49E'
    },
    {
      status: 210,
      sku: 'PM0021662',
      core_name: 'Plant',
      sppl_ean: '8720664885844',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6133684',
      rtl_batch_array: '6161948, 6133684, 6164428',
      name_search: 'SEFWGOLD',
      name: "Sedum floriferum 'Weihenstephaner Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1161,
      chnn_stock_retail: 2406,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55NKSBZC;DGR2B7S7;RP8WC93T;9L77ERP5;4ED49BE3;BV3Y6NB3;R96JXA6P;51T1JJJN;V1D4322Z;7S7X81TK;8PN6CBLJ;RV4B1T43;6W9GH2AK;K92DW8HA',
      statusContent: 'dot',
      hash: '2YT8JTRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YT8JTRL'
    },
    {
      status: 210,
      sku: 'PM0003508',
      core_name: 'Plant',
      sppl_ean: '8720353015958',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '5607028',
      rtl_batch_array: '6350698, 5607028',
      name_search: 'MOCMOORF',
      name: "Molinia caerulea 'Moorflamme'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4300,
      chnn_stock_retail: 4607,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CVVT2CE9;YK519G92;H1DRK87A',
      statusContent: 'dot',
      hash: '43R7N7Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43R7N7Z1'
    },
    {
      status: 210,
      sku: 'PM0017584',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353485',
      rtl_batch_array: '6353485',
      name_search: 'VIMJOSEF',
      name: "Vinca minor 'Josefine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4EKKT9N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EKKT9N5'
    },
    {
      status: 210,
      sku: 'PM0082413',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350468',
      rtl_batch_array: '6350468',
      name_search: 'HEFFROST',
      name: "Hemerocallis 'Forsyth Frostbound'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7TAE7VD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TAE7VD7'
    },
    {
      status: 210,
      sku: 'PM0082414',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350469',
      rtl_batch_array: '6350469',
      name_search: 'HEGZEBRA',
      name: 'Hemerocallis Golden Zebra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 810,
      chnn_stock_retail: 810,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCTKS86B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCTKS86B'
    },
    {
      status: 210,
      sku: 'PM0005023',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6353144',
      rtl_batch_array: '4897365, 6301779, 6353144',
      name_search: 'PEARBARO',
      name: "Persicaria amplexicaulis 'Red Baron'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 1748,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8BJB7W29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BJB7W29'
    },
    {
      status: 210,
      sku: 'PM0022178',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6282502',
      rtl_batch_array: '6282502, 5582442',
      name_search: 'GECBORIS',
      name: "Geum coccineum 'Borisii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 860,
      chnn_stock_retail: 1098,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T148ERE1;RB5RV5RV;XZL2HEAT;JPG7EP7L;CAHYNYZX;CSJP24RK;L7J6KNCX;WY6W4NX2;GTBTB242',
      statusContent: 'dot',
      hash: '92A2VPN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92A2VPN5'
    },
    {
      status: 210,
      sku: 'PM0082415',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350470',
      rtl_batch_array: '6350470',
      name_search: 'HEGUNSMO',
      name: "Heucherella 'Gunsmoke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7AA95K7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AA95K7W'
    },
    {
      status: 210,
      sku: 'PM0014044',
      core_name: 'Plant',
      sppl_ean: '8720626360440',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5243395',
      rtl_batch_array: '6220867, 5243395, 5364366',
      name_search: 'OMCSEYES',
      name: "Omphalodes cappadocica 'Starry Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1919,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C9TJDXKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9TJDXKN'
    },
    {
      status: 210,
      sku: 'PM0067897',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234608',
      rtl_batch_array: '6234608',
      name_search: 'BRMJFROS',
      name: "Brunnera macrophylla 'Jack Frost'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1095,
      chnn_stock_retail: 1095,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G2LCES19;B447J5EN;RSJB1VH3;RPP2N27D;H4T88DYN',
      statusContent: 'dot',
      hash: 'EA1T6T1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA1T6T1P'
    },
    {
      status: 210,
      sku: 'PM0008703',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305162',
      rtl_batch_array: '6305162, 6015312',
      name_search: 'HOAMOON',
      name: "Hosta 'August Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 274,
      chnn_stock_retail: 777,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DDGGJRHX;1HKNLLAP;83VZVJKV;S4PVJXKT;1N6CWT4D',
      statusContent: 'dot',
      hash: 'G5213AVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5213AVX'
    },
    {
      status: 210,
      sku: 'PM0023789',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300845',
      rtl_batch_array: '5837914, 6300845',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6555,
      chnn_stock_retail: 21555,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: 'N6ER2D3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6ER2D3D'
    },
    {
      status: 210,
      sku: 'PM0018761',
      core_name: 'Plant',
      sppl_ean: '8720664604605',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5867410',
      rtl_batch_array: '5867410',
      name_search: 'POLABILL',
      name: 'Poa labillardierei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.046,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YW9W5TN4',
      statusContent: 'dot',
      hash: 'RE22R2RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RE22R2RW'
    },
    {
      status: 210,
      sku: 'PM0082416',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350471',
      rtl_batch_array: '6350471',
      name_search: 'HEHHLIME',
      name: "Heucherella 'Happy Hour Lime'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HE897R9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HE897R9X'
    },
    {
      status: 210,
      sku: 'PM0023639',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301712',
      rtl_batch_array: '6301712, 6161836',
      name_search: 'MASPBLUE',
      name: "Malva sylvestris 'Primley Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 536,
      chnn_stock_retail: 693,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPGJVSVR',
      statusContent: 'dot',
      hash: 'VWTC47LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWTC47LK'
    },
    {
      status: 210,
      sku: 'PM0022703',
      core_name: 'Plant',
      sppl_ean: '8720664855359',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133197',
      rtl_batch_array: '6349902, 6133197',
      name_search: 'CABUCHAN',
      name: 'Carex buchananii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 642,
      chnn_stock_retail: 1602,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZDKHX3N;AHX69GCL;2R2BE5NB;S5B9ZHAT;TALW8N1E;JD8NYJGA;NTP2R168;SND92JHD;2YLJC5S8;E92HXVG6;BTHEKZ8C;LBVWDCZR',
      statusContent: 'dot',
      hash: 'W2ZGAZ2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2ZGAZ2T'
    },
    {
      status: 210,
      sku: 'PM0022664',
      core_name: 'Plant',
      sppl_ean: '8720626336735',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '5866469',
      rtl_batch_array: '6084832, 5866469, 6301466, 6268710',
      name_search: 'BRMACROP',
      name: 'Brunnera macrophylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9928,
      chnn_stock_retail: 34513,
      sppl_order_minimum: 3,
      sppl_prcp: 0.819,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8KAG19SY;RS1KNBR7;TP6JKRW9;4BCBR62K;V41W818Z',
      statusContent: 'dot',
      hash: 'Z7J2Y24Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7J2Y24Y'
    },
    {
      status: 210,
      sku: 'PM0067889',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254886',
      rtl_batch_array: '6254886',
      name_search: 'ASMSSTAR',
      name: "Astrantia major 'Snow Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_prcp: 2.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BALEDXEY',
      statusContent: 'dot',
      hash: 'Z7JN5ZY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7JN5ZY4'
    },
    {
      status: 210,
      sku: 'PM0082417',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350472',
      rtl_batch_array: '6350472',
      name_search: 'HEHROSE',
      name: "Heucherella 'Honey Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PVG379LB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVG379LB'
    },
    {
      status: 210,
      sku: 'PM0082418',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350474',
      rtl_batch_array: '6350474',
      name_search: 'HEONYX',
      name: "Heucherella 'Onyx'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VGTVCVBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGTVCVBJ'
    },
    {
      status: 210,
      sku: 'PM0082419',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350478',
      rtl_batch_array: '6350478',
      name_search: 'HEPCASCA',
      name: "Heucherella 'Plum Cascade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BR8NX2NP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BR8NX2NP'
    },
    {
      status: 210,
      sku: 'PM0082420',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350480',
      rtl_batch_array: '6350480',
      name_search: 'HESPOWER',
      name: "Heucherella 'Solar Power'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TWZD9BVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWZD9BVH'
    },
    {
      status: 210,
      sku: 'PM0082421',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350484',
      rtl_batch_array: '6350484',
      name_search: 'HEWREVOL',
      name: "Heucherella 'White Revolution'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '62PAZVAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62PAZVAB'
    },
    {
      status: 210,
      sku: 'PM0082422',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350485',
      rtl_batch_array: '6350485',
      name_search: 'HEYFALLS',
      name: "Heucherella 'Yellowstone Falls'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LE6V822Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LE6V822Z'
    },
    {
      status: 210,
      sku: 'PM0082423',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350487',
      rtl_batch_array: '6350487',
      name_search: 'HOAHALO',
      name: "Hosta 'American Halo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VCE2A2XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCE2A2XE'
    },
    {
      status: 210,
      sku: 'PM0082424',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350490',
      rtl_batch_array: '6350490',
      name_search: 'HOSBBOY',
      name: "Hosta 'Big Boy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8NAS5SP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8NAS5SP'
    },
    {
      status: 210,
      sku: 'PM0082425',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350494',
      rtl_batch_array: '6350494',
      name_search: 'HOBMAMMO',
      name: "Hosta 'Blue Mammoth'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZ9YTSCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZ9YTSCR'
    },
    {
      status: 210,
      sku: 'PM0082426',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350495',
      rtl_batch_array: '6350495',
      name_search: 'HOBBLUE',
      name: "Hosta 'Bressingham Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '647HLGY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '647HLGY3'
    },
    {
      status: 210,
      sku: 'PM0082427',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350498',
      rtl_batch_array: '6350498',
      name_search: 'HODQUEEN',
      name: "Hosta 'Dream Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '28BX8CPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28BX8CPC'
    },
    {
      status: 210,
      sku: 'PM0082428',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350501',
      rtl_batch_array: '6350501',
      name_search: 'HOFIREWO',
      name: "Hosta 'Fireworks'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YLJPPYW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLJPPYW7'
    },
    {
      status: 210,
      sku: 'PM0082429',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350512',
      rtl_batch_array: '6350512',
      name_search: 'HOJPARK',
      name: "Hosta 'Jurassic Park'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S21ND7TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S21ND7TP'
    },
    {
      status: 210,
      sku: 'PM0082430',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350517',
      rtl_batch_array: '6350517',
      name_search: 'HOOBELT',
      name: "Hosta 'Orion's Belt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BVTAEPA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVTAEPA2'
    },
    {
      status: 210,
      sku: 'PM0082431',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350520',
      rtl_batch_array: '6350520',
      name_search: 'HOPGRAND',
      name: 'Hosta plantaginea grandiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 233,
      chnn_stock_retail: 233,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H39XBSJB;YTD16X9H',
      statusContent: 'dot',
      hash: 'HR455TLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR455TLZ'
    },
    {
      status: 210,
      sku: 'PM0082432',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350522',
      rtl_batch_array: '6350522',
      name_search: 'HORSUNDA',
      name: "Hosta 'Raspberry Sundae'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 318,
      chnn_stock_retail: 318,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '19PB729B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19PB729B'
    },
    {
      status: 210,
      sku: 'PM0082433',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350523',
      rtl_batch_array: '6350523',
      name_search: 'HORFROST',
      name: "Hosta 'Robert Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1VSV2VBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VSV2VBN'
    },
    {
      status: 210,
      sku: 'PM0082434',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350525',
      rtl_batch_array: '6350525',
      name_search: 'HORBONE',
      name: "Hosta 'Ryan's Big One'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9C4XGB9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9C4XGB9N'
    },
    {
      status: 210,
      sku: 'PM0082435',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350528',
      rtl_batch_array: '6350528',
      name_search: 'HOSSIEBO',
      name: 'Hosta sieboldii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CJC95ES;B7ZDCXKC',
      statusContent: 'dot',
      hash: '7SGRZZD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SGRZZD8'
    },
    {
      status: 210,
      sku: 'PM0082436',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348342',
      rtl_batch_array: '6350536, 6348342',
      name_search: 'HOVENTRI',
      name: 'Hosta ventricosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 645,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJY1ALYW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJY1ALYW'
    },
    {
      status: 210,
      sku: 'PM0082437',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350539',
      rtl_batch_array: '6350539',
      name_search: 'HOWOLVER',
      name: "Hosta 'Wolverine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GDZTWRD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDZTWRD5'
    },
    {
      status: 210,
      sku: 'PM0082438',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348343',
      rtl_batch_array: '6350545, 6348343',
      name_search: 'HYOALBUS',
      name: "Hyssopus officinalis 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 375,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11RVNKA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11RVNKA8'
    },
    {
      status: 210,
      sku: 'PM0082439',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350546',
      rtl_batch_array: '6350546',
      name_search: 'HYOARIST',
      name: 'Hyssopus officinalis aristatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5J1EP9XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5J1EP9XR'
    },
    {
      status: 210,
      sku: 'PM0082440',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350549',
      rtl_batch_array: '6350549',
      name_search: 'IBSGCAND',
      name: "Iberis sempervirens 'Golden Candy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GX68PDR5;RW9AW3XZ;16HDJ7HA;8J99N43B;8YTTLDPD;PCWLJ14E;YAN6SRW1;EB2H77RC;5AC62N95;A9WENKHS;H7YRZB68;Z648XZYS;TL6ZGCAX',
      statusContent: 'dot',
      hash: 'GD32W7DV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GD32W7DV'
    },
    {
      status: 210,
      sku: 'PM0082441',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350550',
      rtl_batch_array: '6350550',
      name_search: 'IBSNEVIN',
      name: "Iberis semp. 'Nevina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 672,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97KAK7XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97KAK7XY'
    },
    {
      status: 210,
      sku: 'PM000291',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177909',
      rtl_batch_array: '6177909',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'ZX6C7D78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX6C7D78'
    },
    {
      status: 210,
      sku: 'PM0082442',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350551',
      rtl_batch_array: '6350551',
      name_search: 'IBSWHITE',
      name: "Iberis semp. 'Whiteout'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11HW4X7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11HW4X7K'
    },
    {
      status: 810,
      sku: 'PM0082443',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350560',
      rtl_batch_array: '6350560',
      name_search: 'IRECOTTA',
      name: "Iris (G) 'English Cottage'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_order_minimum: 3,
      sppl_prcp: 1.189,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BJB3AD6',
      statusContent: 'dot',
      hash: 'GS6AJGBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS6AJGBG'
    },
    {
      status: 210,
      sku: 'PM0082444',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350596',
      rtl_batch_array: '6350596',
      name_search: 'KIVITIFO',
      name: 'Kitaibela vitifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 573,
      chnn_stock_retail: 573,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TJHEJ645',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJHEJ645'
    },
    {
      status: 210,
      sku: 'PM0082445',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350611',
      rtl_batch_array: '6350611',
      name_search: 'KNRROCKE',
      name: "Kniphofia 'Red Rocket'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2NVSVVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2NVSVVC'
    },
    {
      status: 210,
      sku: 'PM0059305',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244074',
      rtl_batch_array: '6244074, 6117848, 6348811',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2356,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'PP6L9NC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP6L9NC4'
    },
    {
      status: 210,
      sku: 'PM0072105',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 3,
      btch_active_retail: '6338127',
      rtl_batch_array: '6351238, 6294841, 6338127',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3494,
      chnn_stock_retail: 5764,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'ECB7ZVYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECB7ZVYL'
    },
    {
      status: 910,
      sku: 'PM0071846',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240182',
      rtl_batch_array: '6240182',
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 17.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JB9JX3XZ;VTEBW9DL;GD21GLPT;153D2KY4;NS2G53K8;8LTGZBJ9;X1PWTCPA;T8ES29CJ;ZLLK2N28;LE5VCYZ2;PL4AP9XH;Y31HNANX;BELEV3SP;SCED3R6V;5LHZT6YT;ZK85R77R;8L96TE69;AY4HYHAW;4SXTX4YJ',
      statusContent: 'dot',
      hash: 'GJWVD4Z9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJWVD4Z9'
    },
    {
      status: 910,
      sku: 'PM0074628',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SASEREND',
      name: "Salvia 'Serendip6'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: '59Y2AR19',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '59Y2AR19'
    },
    {
      status: 210,
      sku: 'PM0074630',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272798',
      rtl_batch_array: '6272798',
      name_search: 'SAJURISI',
      name: 'Salvia jurisicii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJ6L9L3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJ6L9L3E'
    },
    {
      status: 910,
      sku: 'PM0074631',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAUCHIEL',
      name: "Salvia uliginosa 'Chiel'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'LHEYNBX2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LHEYNBX2'
    },
    {
      status: 910,
      sku: 'PM0080077',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'D7DGVSP1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D7DGVSP1'
    },
    {
      status: 910,
      sku: 'PM0080078',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUAUNIQU',
      name: 'Buddleja alternifolia Unique',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore:
        'KXWRJ9KT;AEREWWKG;57YYNLSG;SVAX9J4A;6G4VJZ8K;DK784BJ4;5KKSCBHR',
      statusContent: 'dot',
      hash: 'L9E56V9G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L9E56V9G'
    },
    {
      status: 210,
      sku: 'PM0082446',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6348449',
      rtl_batch_array: '5495406, 6350620, 6309495, 6348449',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1556,
      chnn_stock_retail: 2414,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: 'NL1VBVN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL1VBVN2'
    },
    {
      status: 910,
      sku: 'PM0080079',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUBUZZ',
      name: 'Buddleia davidii Buzz',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: '2KWBLEH3;86NA2H7G;7YG7BGEA;119S7AXZ',
      statusContent: 'dot',
      hash: 'ENJ1ES6Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ENJ1ES6Y'
    },
    {
      status: 210,
      sku: 'PM0080080',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378227',
      rtl_batch_array: '6378227',
      name_search: 'BUBCHIP',
      name: "Buddleja 'Blue Chip'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RSJNR3YN',
      statusContent: 'dot',
      hash: '4VYKZBEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VYKZBEC'
    },
    {
      status: 910,
      sku: 'PM0080081',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDWCLOU',
      name: "Buddleja davidii 'White Cloud'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'YEH5W7JY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YEH5W7JY'
    },
    {
      status: 210,
      sku: 'PM0080082',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378231',
      rtl_batch_array: '6378231',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 100000,
      chnn_stock_retail: 100000,
      sppl_order_minimum: 6,
      sppl_prcp: 0.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'A5BGDYH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5BGDYH9'
    },
    {
      status: 210,
      sku: 'PM0080083',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378233',
      rtl_batch_array: '6378233',
      name_search: 'DAOAUREO',
      name: "Daphne odora 'Aureomarginata'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 19994,
      chnn_stock_retail: 19994,
      sppl_prcp: 5.26,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZNTZXHX;HC6TPSNK',
      statusContent: 'dot',
      hash: 'DSDL844N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSDL844N'
    },
    {
      status: 210,
      sku: 'PM0080084',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378235',
      rtl_batch_array: '6378235',
      name_search: 'GAPBBERR',
      name: 'Gaultheria procumbens Big Berry',
      sppl_size_code: '015020C1',
      rng_range_identifier: 'H015020C1',
      rng_range_description: 'H15 cm 20 cm C1',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW1YX6YN;TV1WC9H1;LATLBEWL;VTB1SX66',
      statusContent: 'dot',
      hash: 'RVJHB42Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVJHB42Z'
    },
    {
      status: 910,
      sku: 'PM0080085',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GAPWPEAR',
      name: "Gaultheria procumbens 'White Pearl'",
      rng_range_identifier: 'H012015C1',
      rng_range_description: 'H12 cm 15 cm C1',
      imageCore: 'ND5K94TZ;XBR5B6G3',
      statusContent: 'dot',
      hash: 'PDKG1GE5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PDKG1GE5'
    },
    {
      status: 910,
      sku: 'PM0080086',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMMIX',
      name: 'Hypericum Miracle Mix',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      statusContent: 'dot',
      hash: 'XP46VAVL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XP46VAVL'
    },
    {
      status: 910,
      sku: 'PM0080087',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMMIX',
      name: 'Hypericum Miracle Mix',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      statusContent: 'dot',
      hash: 'JTWN7JYN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JTWN7JYN'
    },
    {
      status: 910,
      sku: 'PM0080088',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMMIX',
      name: 'Hypericum Miracle Mix',
      rng_range_identifier: 'H020025C1',
      rng_range_description: 'H20 cm 25 cm C1',
      statusContent: 'dot',
      hash: '72ADN66Y',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '72ADN66Y'
    },
    {
      status: 210,
      sku: 'PM0080089',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338225',
      rtl_batch_array: '6338225',
      name_search: 'LEACRED',
      name: "Leucothoe axillaris 'Curly Red'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 679,
      chnn_stock_retail: 679,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8H857G1;R8H1ZAJ1;586DR5XV;KV1DANDT;55LZW6WD;8EHDL7EE;ASEKT1V4;J49WYD7L;61KYLN78;CBNKX9YG;1ZS3D7GK;LD38B6JT',
      statusContent: 'dot',
      hash: 'LS1A8CKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LS1A8CKN'
    },
    {
      status: 910,
      sku: 'PM0080090',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGNOLIA',
      name: "Magnolia in cv's",
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      statusContent: 'dot',
      hash: '92K81A1K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '92K81A1K'
    },
    {
      status: 210,
      sku: 'PM0080091',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378238',
      rtl_batch_array: '6378238',
      name_search: 'ROOCAPRI',
      name: "Rosmarinus officinalis 'Capri'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5RH5N4E7;ANGHJ8XD;KTCCH9RL;4E46N3AL;E6CJ27H4',
      statusContent: 'dot',
      hash: 'DBRW5HB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBRW5HB6'
    },
    {
      status: 910,
      sku: 'PM0080092',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      rng_range_identifier: 'STE030C2',
      rng_range_description: 'Stem 30 cm C2',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'W11W71DX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W11W71DX'
    },
    {
      status: 210,
      sku: 'PM0080093',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378239',
      rtl_batch_array: '6378239',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: '060STC3',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: '2RXBZP4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RXBZP4B'
    },
    {
      status: 210,
      sku: 'PM0080094',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378240',
      rtl_batch_array: '6378240',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 7.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'LKK99C99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKK99C99'
    },
    {
      status: 210,
      sku: 'PM0082447',
      core_name: 'Plant',
      sppl_ean: '8720664871441',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091547',
      rtl_batch_array: '6350621, 6091547',
      name_search: 'LAABEATE',
      name: "Lavandula angustifolia 'Beate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 677,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HEG7CLHN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEG7CLHN'
    },
    {
      status: 210,
      sku: 'PM0082448',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350622',
      rtl_batch_array: '6350622, 6302647',
      name_search: 'LAABCUSH',
      name: 'Lavandula angustifolia Blue Cushion',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1650,
      chnn_stock_retail: 2118,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '449E5TEG',
      statusContent: 'dot',
      hash: 'DVP11757',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVP11757'
    },
    {
      status: 210,
      sku: 'PM0082449',
      core_name: 'Plant',
      sppl_ean: '8720664871458',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091548',
      rtl_batch_array: '6350623, 6091548',
      name_search: 'LAABLUST',
      name: "Lavandula angustifolia 'Brabants Lust'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 235,
      chnn_stock_retail: 637,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '114YC1NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '114YC1NK'
    },
    {
      status: 210,
      sku: 'PM0082450',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176248',
      rtl_batch_array: '6350625, 6176248',
      name_search: 'LAAEPURP',
      name: "Lavandula angustifolia 'Essence Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2429,
      chnn_stock_retail: 3019,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2BPX8LP3;AN3A3WEZ;165YY51H;PSYW6N65;2RYNA19K;1KS7NXYC;A91B7ZXT',
      statusContent: 'dot',
      hash: 'A2932RDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2932RDX'
    },
    {
      status: 210,
      sku: 'PM0054018',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177938',
      rtl_batch_array: '6177938',
      name_search: 'SANGSPAR',
      name: 'Sambucus nigra Gold Spark',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 1695,
      chnn_stock_retail: 1695,
      sppl_prcp: 4.182,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1D6AZSWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1D6AZSWD'
    },
    {
      status: 210,
      sku: 'PM0082451',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350627',
      rtl_batch_array: '6350627, 6348353',
      name_search: 'LAAHPINK',
      name: "Lavandula angustifolia 'Hidcote Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 786,
      chnn_stock_retail: 976,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7A5EH8XV;XV4SYLA9',
      statusContent: 'dot',
      hash: 'YYSWCD86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYSWCD86'
    },
    {
      status: 210,
      sku: 'PM0082452',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319742',
      rtl_batch_array: '6350628, 6319742',
      name_search: 'LAAIGEM',
      name: "Lavandula angustifolia 'Imperial Gem'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 259,
      chnn_stock_retail: 739,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '627ZN8XL;GPT52B8R;V1WT8L45',
      statusContent: 'dot',
      hash: 'SSSRR9YK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSSRR9YK'
    },
    {
      status: 210,
      sku: 'PM0082453',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350630',
      rtl_batch_array: '6350630',
      name_search: 'LAVAASBL',
      name: "Lavandula angustifolia 'Spear Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y15NYD1S',
      statusContent: 'dot',
      hash: '71RETP82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71RETP82'
    },
    {
      status: 210,
      sku: 'PM0059302',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338264',
      rtl_batch_array: '5459711, 6338264',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3495,
      chnn_stock_retail: 3595,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: '2AYPRYXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AYPRYXT'
    },
    {
      status: 210,
      sku: 'PM0082454',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350631',
      rtl_batch_array: '6350631',
      name_search: 'LAAWFRAG',
      name: 'Lavandula ang. White Fragrance',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4R7928YY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4R7928YY'
    },
    {
      status: 210,
      sku: 'PM0082455',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350632',
      rtl_batch_array: '6350632',
      name_search: 'LAIALBA',
      name: "Lavandula intermedia 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 719,
      chnn_stock_retail: 719,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DL7GK13N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL7GK13N'
    },
    {
      status: 210,
      sku: 'PM0058706',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 4,
      btch_active_retail: '6338440',
      rtl_batch_array: '6197003, 6217680, 6373846, 6338440',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1495,
      chnn_stock_retail: 8100,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '2S8WNCSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S8WNCSS'
    },
    {
      status: 210,
      sku: 'PM0082456',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350634',
      rtl_batch_array: '6350634',
      name_search: 'LAIFMEMO',
      name: "Lavandula intermedia 'Fragrant Memories'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E9ZESDRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9ZESDRX'
    },
    {
      status: 210,
      sku: 'PM0020220',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6301690',
      rtl_batch_array: '6080719, 6349131, 5539739, 6301690',
      name_search: 'LEVMAIKO',
      name: "Leucanthemum vulgare 'Maik\u00f6nigin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1558,
      chnn_stock_retail: 6565,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VPEJPET1;C6SNZBW8;NBXVAZS5;YBPNZL1H;6R7624PW;LZR22RVA',
      statusContent: 'dot',
      hash: '4KWJDGSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KWJDGSE'
    },
    {
      status: 210,
      sku: 'PM0082457',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6187651',
      rtl_batch_array: '6350635, 6187651',
      name_search: 'LAIGRAPP',
      name: "Lavandula intermedia 'Grappenhall'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 1365,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2X9WL6C;WK3YH128',
      statusContent: 'dot',
      hash: '8AT1A4L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AT1A4L2'
    },
    {
      status: 210,
      sku: 'PM0082458',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315075',
      rtl_batch_array: '6350636, 6315075',
      name_search: 'LARGRAY',
      name: "Lavandula 'Richard Gray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1159,
      chnn_stock_retail: 1625,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VSNJTHJP;9DCHY62V;C2PGT44Y;X728ZT59;P4ZT5W98;D78ENXAS',
      statusContent: 'dot',
      hash: '873YE69J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '873YE69J'
    },
    {
      status: 910,
      sku: 'PM0082459',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LEFCOGHI',
      name: "Leucanthemum (S) 'Fiona Coghill'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'SXKPZ1YG;B1K2APLG;L4BSDKXN;XKWCHJP5',
      statusContent: 'dot',
      hash: 'J3E16END',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J3E16END'
    },
    {
      status: 210,
      sku: 'PM0082460',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350642',
      rtl_batch_array: '6350642',
      name_search: 'LEMLASPI',
      name: "Leucanthemum m. 'Laspider'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZXL1GA2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXL1GA2J'
    },
    {
      status: 810,
      sku: 'PM0082461',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350645',
      rtl_batch_array: '6350645',
      name_search: 'LESONNEN',
      name: "Leucanthemum (S) 'Sonnenschein'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K9CZ2NJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9CZ2NJS'
    },
    {
      status: 910,
      sku: 'PM0071847',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240183',
      rtl_batch_array: '6240183',
      name_search: 'PRSSBOUL',
      name: "Prunus serrulata 'Sunset Boulevard'",
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '44KN3664',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44KN3664'
    },
    {
      status: 910,
      sku: 'PM0071848',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240184',
      rtl_batch_array: '6240184',
      name_search: 'PRSAROSE',
      name: "Prunus subhirtella 'Autumnalis Rosea'",
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDSCGNSS;ESZ44LNE;K3W41VY2;JZ98V6KV;XCE4HG4T;H7VN2WVD;5WY66PWK;CXC9ZWBS;WDTDLPLW;E2JCVPAS',
      statusContent: 'dot',
      hash: 'N1J3V5ZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1J3V5ZC'
    },
    {
      status: 910,
      sku: 'PM0071849',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240185',
      rtl_batch_array: '6240185',
      name_search: 'PRVCRED',
      name: "Prunus virginiana 'Canada Red'",
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TTL1P1PS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTL1P1PS'
    },
    {
      status: 910,
      sku: 'PM0071850',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240186',
      rtl_batch_array: '6240186',
      name_search: 'PRYEDOEN',
      name: 'Prunus yedoensis',
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T8P66DAJ;2SD19TBV',
      statusContent: 'dot',
      hash: 'XN6ZV52K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XN6ZV52K'
    },
    {
      status: 910,
      sku: 'PM0082462',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LEVERNUM',
      name: 'Leucojum vernum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'AXCTWDXE;GR2XN5SL',
      statusContent: 'dot',
      hash: 'XH6D1NA1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XH6D1NA1'
    },
    {
      status: 210,
      sku: 'PM0082463',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350656',
      rtl_batch_array: '6350656',
      name_search: 'LILSPLEN',
      name: 'Lilium lancifolium splendens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4CT4DG53;HR7WTNHL',
      statusContent: 'dot',
      hash: 'Y2VT8Y6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2VT8Y6L'
    },
    {
      status: 210,
      sku: 'PM0082464',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350657',
      rtl_batch_array: '6350657',
      name_search: 'LIPDIAMA',
      name: "Linum perenne 'Diamant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 426,
      chnn_stock_retail: 426,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XDL1A8L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDL1A8L4'
    },
    {
      status: 210,
      sku: 'PM0082465',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350683',
      rtl_batch_array: '6350683',
      name_search: 'MISLMISS',
      name: "Miscanthus sinensis 'Little Miss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8H67XDT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H67XDT3'
    },
    {
      status: 910,
      sku: 'PM0071851',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240188',
      rtl_batch_array: '6240188',
      name_search: 'SOARMAJO',
      name: "Sorbus aucuparia 'Rossica Major'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 15.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KD8PRSXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KD8PRSXR'
    },
    {
      status: 210,
      sku: 'PM0082466',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350691',
      rtl_batch_array: '6350691',
      name_search: 'MIREPENS',
      name: 'Mitchella repens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXW3CGP6;KPCWN411;2XJ8SVYZ;RVXNLXPD;JPGHWYR6;4JVZ6N81;TT7TALCG;KYC1SE5C;NCXPDKL1;4DL2E98J;21K64X4V;7XZY183W;BXDA6KX8',
      statusContent: 'dot',
      hash: 'TWV7X1S7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWV7X1S7'
    },
    {
      status: 210,
      sku: 'PM0082467',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350701',
      rtl_batch_array: '6350701',
      name_search: 'MOPANN',
      name: "Monarda 'Purple Ann'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DAP311Z8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAP311Z8'
    },
    {
      status: 210,
      sku: 'PM0082468',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350704',
      rtl_batch_array: '6350704',
      name_search: 'MUNFLAME',
      name: 'Mukgenia Nova Flame',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NNJHDPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NNJHDPA'
    },
    {
      status: 210,
      sku: 'PM0082469',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350721',
      rtl_batch_array: '6350721',
      name_search: 'OESSULPH',
      name: "Oenothera stricta 'Sulphurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7YGE7D6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7YGE7D6'
    },
    {
      status: 910,
      sku: 'PM0071852',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240189',
      rtl_batch_array: '6240189',
      name_search: 'SOASSEED',
      name: "Sorbus aucuparia 'Sheerwater Seedling'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K8WV25GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8WV25GC'
    },
    {
      status: 910,
      sku: 'PM0071853',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240190',
      rtl_batch_array: '6240190',
      name_search: 'SOCOMMIX',
      name: 'Sorbus commixta',
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 15.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YW92L1C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW92L1C8'
    },
    {
      status: 810,
      sku: 'PM0082470',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350726',
      rtl_batch_array: '6350726',
      name_search: 'OPBODINI',
      name: 'Ophiopogon bodinieri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KCNXXR59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCNXXR59'
    },
    {
      status: 210,
      sku: 'PM0082471',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350732',
      rtl_batch_array: '6350732',
      name_search: 'OSMJAPON',
      name: 'Osmunda japonica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2PW9V8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2PW9V8E'
    },
    {
      status: 910,
      sku: 'PM0082472',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367982',
      rtl_batch_array: '6367982',
      name_search: 'HISMARIN',
      name: "Hibiscus syriacus 'Marina'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69LD5BA3;4KY15DD1;J439RCSN;4WL845Y7;KCL3SGY8;8ARYR5SP;2GE451DS;945JC67P',
      statusContent: 'dot',
      hash: 'SZ5HS582',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZ5HS582'
    },
    {
      status: 910,
      sku: 'PM0071854',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240191',
      rtl_batch_array: '6240191',
      name_search: 'SOLHVINK',
      name: "Sorbus latifolia 'Henk Vink'",
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NHX16Z8L',
      statusContent: 'dot',
      hash: 'Y74BTYH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y74BTYH9'
    },
    {
      status: 910,
      sku: 'PM0071855',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240192',
      rtl_batch_array: '6240192',
      name_search: 'SOTFASTI',
      name: "Sorbus thuringiaca 'Fastigiata'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 17.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YVYD96E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YVYD96E'
    },
    {
      status: 810,
      sku: 'PM0082473',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367983',
      rtl_batch_array: '6367983',
      name_search: 'HISMONST',
      name: "Hibiscus syriacus 'Monstrosus'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDGX7TYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDGX7TYL'
    },
    {
      status: 910,
      sku: 'PM0082474',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367975',
      rtl_batch_array: '6367975',
      name_search: 'HISMONST',
      name: "Hibiscus syriacus 'Monstrosus'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRYPV9RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRYPV9RZ'
    },
    {
      status: 210,
      sku: 'PM0082475',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367984',
      rtl_batch_array: '6367984',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_prcp: 9.358,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'YNRS5C1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNRS5C1J'
    },
    {
      status: 910,
      sku: 'PM0040055',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: '5GAL5N6T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5GAL5N6T'
    },
    {
      status: 210,
      sku: 'PM0047570',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258933',
      rtl_batch_array: '6258933',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 937,
      chnn_stock_retail: 937,
      sppl_prcp: 9.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: '895LPBSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '895LPBSK'
    },
    {
      status: 210,
      sku: 'PM0007808',
      core_name: 'Plant',
      sppl_ean: '8720349433780',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5607030',
      rtl_batch_array: '6015439, 6274858, 5607030',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7100,
      chnn_stock_retail: 21075,
      sppl_order_minimum: 3,
      sppl_prcp: 0.588,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      statusContent: 'dot',
      hash: '9BGKAW3C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BGKAW3C'
    },
    {
      status: 210,
      sku: 'PM0067904',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254685',
      rtl_batch_array: '5837409, 6254685',
      name_search: 'CERCOCCI',
      name: "Centranthus ruber 'Coccineus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2323,
      chnn_stock_retail: 2684,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH9S5DBH;98WBXW7B;2PTAE8W3;9Z3RCZDH;J9CA92EN;5NWRVBYN;1DPC3C3B',
      statusContent: 'dot',
      hash: '9VGSNP6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VGSNP6X'
    },
    {
      status: 910,
      sku: 'PM0015428',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAORIENT',
      name: 'Papaver orientale',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        'XX2LTEYT;BBN35W8R;W4X8A5N7;W8N5VTCE;HRX8JNX4;AW1GRKSB;SCYZC297',
      statusContent: 'dot',
      hash: '9ZAZHTZ1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9ZAZHTZ1'
    },
    {
      status: 210,
      sku: 'PM0051912',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184036',
      rtl_batch_array: '6184036',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '150175C34',
      rng_range_identifier: 'H150175C3.5',
      rng_range_description: 'H150 cm 175 cm C3.5',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_prcp: 4.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'S56GZDYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S56GZDYZ'
    },
    {
      status: 210,
      sku: 'PM0067903',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244277',
      rtl_batch_array: '6244277, 6210026, 6234646',
      name_search: 'CERALBUS',
      name: "Centranthus ruber 'Albus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1086,
      chnn_stock_retail: 2398,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXJP9Z7E;LEB3HGJK;BPBWVS7H;R7RZH464',
      statusContent: 'dot',
      hash: 'AEDW2RPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEDW2RPH'
    },
    {
      status: 210,
      sku: 'PM0019582',
      core_name: 'Plant',
      sppl_ean: '8720349429097',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5342099',
      rtl_batch_array: '5342099',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      imageBatch: '2TKA7A14',
      statusContent: 'dot',
      hash: 'B42YYSWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B42YYSWY'
    },
    {
      status: 210,
      sku: 'PM0021046',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6333233',
      rtl_batch_array: '5866503, 6091078, 6015044, 6333233',
      name_search: 'FRVALEXA',
      name: "Fragaria vesca 'Alexandria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2988,
      chnn_stock_retail: 10371,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5BTPHXE6',
      statusContent: 'dot',
      hash: 'DTVA2J3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTVA2J3S'
    },
    {
      status: 910,
      sku: 'PM0018847',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 9,
      btch_active_retail: '6204211',
      rtl_batch_array:
        '6349075, 6147182, 6314435, 6204211, 6315265, 5303710, 6014223, 6295763, 6287684',
      name_search: 'NEWLOW',
      name: "Nepeta 'Walker's Low'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7,
      chnn_stock_retail: 37971,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '83KLR23B;2X11KXGZ;GZBEPLNA;ETAZAYTC;43X8CB9C;19HZ61AP;ZRJB5GRC;C63HC55X;5R6S3ZZ5;RR7SY6N1;RY99AV1V;HAKLLLPJ;951Y696D;SJTC13B9;TYPYG9Y6;84XT9D1J;5945D6RR',
      statusContent: 'dot',
      hash: 'EZYLDZ7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZYLDZ7B'
    },
    {
      status: 210,
      sku: 'PM0030642',
      core_name: 'Plant',
      sppl_ean: '8720349425730',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5405476',
      rtl_batch_array: '5405476',
      name_search: 'PIPKARPA',
      name: "Picea pungens 'Karpaten'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1157,
      chnn_stock_retail: 1157,
      sppl_prcp: 8.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'LY6587D1',
      statusContent: 'dot',
      hash: 'GDTNXBRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDTNXBRE'
    },
    {
      status: 910,
      sku: 'PM0016579',
      core_name: 'Plant',
      sppl_ean: '8720349432677',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '5564723',
      rtl_batch_array: '6242995, 6220903, 6147189, 6202338, 5564723, 6385485',
      name_search: 'TELUCIDR',
      name: 'Teucrium lucidrys',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14,
      chnn_stock_retail: 17333,
      sppl_order_minimum: 3,
      sppl_prcp: 0.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '98W9529H;S6X471TC;A5CK8G7H',
      statusContent: 'dot',
      hash: 'N92CAW4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N92CAW4H'
    },
    {
      status: 210,
      sku: 'PM0025598',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6266382',
      rtl_batch_array: '6266382, 6161811, 6302653',
      name_search: 'LEBCREAM',
      name: "Leucanthemum (S) 'Banana Cream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1160,
      chnn_stock_retail: 2019,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GABB7N8T',
      statusContent: 'dot',
      hash: 'P2E5KY5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2E5KY5P'
    },
    {
      status: 210,
      sku: 'PM0004171',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349598',
      rtl_batch_array: '6349598, 5363932, 6302414',
      name_search: 'BERKLOSE',
      name: "Bergenia 'Rosi Klose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 742,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P8ARWJBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8ARWJBN'
    },
    {
      status: 210,
      sku: 'PM0039036',
      core_name: 'Plant',
      sppl_ean: '8720664807983',
      btch_manufacturer: 561,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856648',
      rtl_batch_array: '5856648',
      name_search: 'SAHPURPL',
      name: "Sarcococca h. 'Purplerij15'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 3977,
      chnn_stock_retail: 3977,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ZHBV14K1',
      statusContent: 'dot',
      hash: 'PPA6557T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPA6557T'
    },
    {
      status: 910,
      sku: 'PM0047588',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BEMPARKJ',
      name: "Berberis media 'Parkjuweel'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore: 'DNS4ZEN7;DXHPLBP2;H19D93RE;LP5A84HC;TRSEJG36;P7WBZTA4',
      statusContent: 'dot',
      hash: 'R7YK86S2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R7YK86S2'
    },
    {
      status: 810,
      sku: 'PM0048075',
      core_name: 'Plant',
      sppl_ean: '8720664872233',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151146',
      rtl_batch_array: '6151146',
      name_search: 'LECARNAV',
      name: "Leucothoe 'Carnaval'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 6.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W9E2GDV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9E2GDV7'
    },
    {
      status: 810,
      sku: 'PM0050773',
      core_name: 'Plant',
      sppl_ean: '8720664686298',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5663944',
      rtl_batch_array: '5663944',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 3.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'YLACHPZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLACHPZ4'
    },
    {
      status: 210,
      sku: 'PM0063010',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339775',
      rtl_batch_array: '6295491, 6339775',
      name_search: 'CAFBUIS',
      name: "Carex flacca 'Buis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1199,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VT1H1LZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VT1H1LZV'
    },
    {
      status: 210,
      sku: 'PM0074633',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273311',
      rtl_batch_array: '6273311',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '030040C7',
      rng_range_identifier: 'H030040C7',
      sppl_stock_available: 849,
      chnn_stock_retail: 849,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'E4HPS74T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4HPS74T'
    },
    {
      status: 910,
      sku: 'PM0059861',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: '85C6XY6T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '85C6XY6T'
    },
    {
      status: 910,
      sku: 'PM0074639',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'LR7XT9Y6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LR7XT9Y6'
    },
    {
      status: 910,
      sku: 'PM0074638',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'PG95YY8C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PG95YY8C'
    },
    {
      status: 910,
      sku: 'PM0074642',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: 'WTDTA1P4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WTDTA1P4'
    },
    {
      status: 210,
      sku: 'PM0067906',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254697',
      rtl_batch_array: '6254697',
      name_search: 'DIDFLIGH',
      name: "Dianthus deltoides 'Flashing Light'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 665,
      chnn_stock_retail: 665,
      sppl_prcp: 1.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LSYDH6DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSYDH6DN'
    },
    {
      status: 210,
      sku: 'PM0082476',
      core_name: 'Plant',
      sppl_ean: '8720349433506',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5321510',
      rtl_batch_array: '5321510',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6508,
      chnn_stock_retail: 6508,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      imageBatch: '5G6Z2Z3G',
      statusContent: 'dot',
      hash: '8CZV8L4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CZV8L4C'
    },
    {
      status: 210,
      sku: 'PM0082477',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274538',
      rtl_batch_array: '6274538',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4498,
      chnn_stock_retail: 4498,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: 'WRW634CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRW634CZ'
    },
    {
      status: 810,
      sku: 'PM0072107',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6294807',
      rtl_batch_array: '6294807, 6300750',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 45,
      chnn_stock_retail: 345,
      sppl_prcp: 4.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: '967BZ59D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '967BZ59D'
    },
    {
      status: 910,
      sku: 'PM0080095',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYBLOOME',
      name: 'Syringa Bloomerang',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      statusContent: 'dot',
      hash: '4DACY62J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4DACY62J'
    },
    {
      status: 910,
      sku: 'PM0080096',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYBLOOME',
      name: 'Syringa Bloomerang',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      statusContent: 'dot',
      hash: 'GNZPL59L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GNZPL59L'
    },
    {
      status: 210,
      sku: 'PM0080097',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378241',
      rtl_batch_array: '6378241',
      name_search: 'VAVIFIRE',
      name: 'Vaccinium vitis-idaea Fireballs',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 3.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G13CT6TW;Y3NL3NLK;GXGDY6ER;KSCSCWL2;REH5SCCW;19EREVBK;Z6K289TP;4ZDTLDSN;PJH32HBK;DK5SLYWD;SS5ZHJKH;6YEDXS5K;Z6NBYJXL',
      statusContent: 'dot',
      hash: 'KADHEAK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KADHEAK2'
    },
    {
      status: 210,
      sku: 'PM0080098',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378242',
      rtl_batch_array: '6378242',
      name_search: 'VAVIFIRE',
      name: 'Vaccinium vitis-idaea Fireballs',
      sppl_size_code: '015020C1',
      rng_range_identifier: 'H015020C1',
      rng_range_description: 'H15 cm 20 cm C1',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G13CT6TW;Y3NL3NLK;GXGDY6ER;KSCSCWL2;REH5SCCW;19EREVBK;Z6K289TP;4ZDTLDSN;PJH32HBK;DK5SLYWD;SS5ZHJKH;6YEDXS5K;Z6NBYJXL',
      statusContent: 'dot',
      hash: '8129ZLBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8129ZLBK'
    },
    {
      status: 210,
      sku: 'PM0080099',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338527',
      rtl_batch_array: '6378243, 6338527',
      name_search: 'VAVIDAEA',
      name: 'Vaccinium vitis-idaea',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 615,
      chnn_stock_retail: 4615,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADRY42AW;RXBRJG8N;8VVZGLK9;47WL3Z4S;GYRW51R1;CATLCP8T;GDZSCZ98;BV7LXC5D;Y1EEEC7P;A11TYWJH;HESXVX72;D53ZT9PA',
      statusContent: 'dot',
      hash: 'ZDWT7BJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDWT7BJ2'
    },
    {
      status: 210,
      sku: 'PM0082478',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274539',
      rtl_batch_array: '6274539',
      name_search: 'LAASMIST',
      name: "Lavandula angustifolia 'Silver Mist'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JTVKZAYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTVKZAYJ'
    },
    {
      status: 210,
      sku: 'PM0082479',
      core_name: 'Plant',
      sppl_ean: '8720353006161',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 4,
      btch_active_retail: '5476702',
      rtl_batch_array: '6244233, 5476702, 6340063, 6260031',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3296,
      chnn_stock_retail: 17102,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: '2DDRJAG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DDRJAG7'
    },
    {
      status: 210,
      sku: 'PM0082480',
      core_name: 'Plant',
      sppl_ean: '8720664568624',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '5476703',
      rtl_batch_array: '5476703',
      name_search: 'LABSTAR',
      name: "Lavandula 'Blue Star'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5200,
      chnn_stock_retail: 5200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6KYDTB2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KYDTB2N'
    },
    {
      status: 210,
      sku: 'PM0082481',
      core_name: 'Plant',
      sppl_ean: '8720664871656',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112677',
      rtl_batch_array: '6315074, 6112677',
      name_search: 'LAIPHENO',
      name: 'Lavandula intermedia Phenomenal',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1291,
      chnn_stock_retail: 1516,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DX6CYSH;CBRN3JBS;J1HS51LW;SY7AS57S;DB5JE4VK;8G1BRDBV;CG4KNZJJ',
      statusContent: 'dot',
      hash: '9S2K2D5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S2K2D5L'
    },
    {
      status: 210,
      sku: 'PM0082482',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301680',
      rtl_batch_array: '6301680',
      name_search: 'LAIGROSS',
      name: "Lavandula intermedia 'Grosso'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6HYD5KP;JDS3DX51;85DAB5KS;DJ5DBE7H;ET45AAHT;ZBPGWW3W;2Y9A7JYL;VEZL5ZHE;E7GD7PPS;4A8LT5EX;Z35X3T1E;51SXNPZZ;HNEBXXAX;YETJWXWY;L89Y4LET;W2B8EC65;ELKWSKLS;A1GZ59LY;4XW5A5A2;14S47DS5',
      statusContent: 'dot',
      hash: 'ASZ6DESG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASZ6DESG'
    },
    {
      status: 210,
      sku: 'PM0082483',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295920',
      rtl_batch_array: '6295920',
      name_search: 'LASPEDUN',
      name: 'Lavandula stoechas pedunculata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 663,
      chnn_stock_retail: 663,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NL1BH7V;8CTSDG3R;72ABPNPY;B5LGPAL2;ZDVV28V1',
      statusContent: 'dot',
      hash: '499KTKCE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '499KTKCE'
    },
    {
      status: 210,
      sku: 'PM0082484',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330005',
      rtl_batch_array: '6302648, 6330005',
      name_search: 'LAAHWHIT',
      name: "Lavandula angustifolia 'Hidcote White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 916,
      chnn_stock_retail: 1200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHPR4K7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHPR4K7H'
    },
    {
      status: 910,
      sku: 'PM0071856',
      core_name: 'Plant',
      btch_manufacturer: 14143471,
      rtl_batch_array_total: 1,
      btch_active_retail: '6240232',
      rtl_batch_array: '6240232',
      name_search: 'SOTORMIN',
      name: 'Sorbus torminalis',
      sppl_size_code: '200250C10',
      rng_range_identifier: 'H200250C10',
      rng_range_description: 'H200 cm 250 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1JD4WBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1JD4WBN'
    },
    {
      status: 210,
      sku: 'PM0082485',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310300',
      rtl_batch_array: '6310300, 6267707',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 3823,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: '2X59CJHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2X59CJHP'
    },
    {
      status: 210,
      sku: 'PM0082486',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310301',
      rtl_batch_array: '6310301',
      name_search: 'LABSTAR',
      name: "Lavandula 'Blue Star'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1PKSRYB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PKSRYB9'
    },
    {
      status: 910,
      sku: 'PM0082487',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ULGLABRA',
      name: 'Ulmus glabra',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      statusContent: 'dot',
      hash: 'DVP8X2V8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DVP8X2V8'
    },
    {
      status: 210,
      sku: 'PM0082488',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319465',
      rtl_batch_array: '6319465',
      name_search: 'LAADBLUE',
      name: "Lavandula angustifolia 'Dwarf Blue'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4468,
      chnn_stock_retail: 4468,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1H3ESPR;X1DY9NER;13R414KC;S6ZPVWEE;DN6SPV9W;6H75BN8S',
      statusContent: 'dot',
      hash: 'X3RCD4S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3RCD4S9'
    },
    {
      status: 210,
      sku: 'PM0082489',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319466',
      rtl_batch_array: '6319466',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4763,
      chnn_stock_retail: 4763,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'BTEKBZXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTEKBZXL'
    },
    {
      status: 210,
      sku: 'PM0082490',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338211',
      rtl_batch_array: '6338211',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 4175,
      chnn_stock_retail: 4175,
      sppl_order_minimum: 5,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: '2NVATR6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NVATR6P'
    },
    {
      status: 210,
      sku: 'PM0082491',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338212',
      rtl_batch_array: '6338212',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2242,
      chnn_stock_retail: 2242,
      sppl_order_minimum: 5,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'ECAXH1VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECAXH1VN'
    },
    {
      status: 210,
      sku: 'PM0082492',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338213',
      rtl_batch_array: '6338213',
      name_search: 'LAIGROSS',
      name: "Lavandula intermedia 'Grosso'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1008,
      chnn_stock_retail: 1008,
      sppl_order_minimum: 5,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6HYD5KP;JDS3DX51;85DAB5KS;DJ5DBE7H;ET45AAHT;ZBPGWW3W;2Y9A7JYL;VEZL5ZHE;E7GD7PPS;4A8LT5EX;Z35X3T1E;51SXNPZZ;HNEBXXAX;YETJWXWY;L89Y4LET;W2B8EC65;ELKWSKLS;A1GZ59LY;4XW5A5A2;14S47DS5',
      statusContent: 'dot',
      hash: '6TPXGXPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TPXGXPL'
    },
    {
      status: 210,
      sku: 'PM0082493',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338214',
      rtl_batch_array: '6338214',
      name_search: 'LAIPHENO',
      name: 'Lavandula intermedia Phenomenal',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_order_minimum: 5,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DX6CYSH;CBRN3JBS;J1HS51LW;SY7AS57S;DB5JE4VK;8G1BRDBV;CG4KNZJJ',
      statusContent: 'dot',
      hash: '2N4ZS8DE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N4ZS8DE'
    },
    {
      status: 210,
      sku: 'PM0082494',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338215',
      rtl_batch_array: '6338215',
      name_search: 'LASPAPIL',
      name: "Lavandula stoechas 'Papillon'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 241,
      chnn_stock_retail: 241,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSRNTC84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSRNTC84'
    },
    {
      status: 210,
      sku: 'PM0082495',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339642',
      rtl_batch_array: '6339642',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: '6Z7NH64P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z7NH64P'
    },
    {
      status: 210,
      sku: 'PM0082496',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339643',
      rtl_batch_array: '6339643',
      name_search: 'SPNHSILV',
      name: "Spiraea nipponica 'Halward's Silver'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 2900,
      chnn_stock_retail: 2900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8TA4ED2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TA4ED2E'
    },
    {
      status: 210,
      sku: 'PM0082497',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339644',
      rtl_batch_array: '6339644',
      name_search: 'CIOBTUSI',
      name: 'Cistus obtusifolius',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1600,
      sppl_prcp: 1.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X5TCVZEC;YZ8G2AX1',
      statusContent: 'dot',
      hash: '1HAJ262Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HAJ262Z'
    },
    {
      status: 210,
      sku: 'PM0082498',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339645',
      rtl_batch_array: '6339645',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '080090C5',
      rng_range_identifier: 'H080090C5',
      rng_range_description: 'H80 cm 90 cm C5',
      sppl_stock_available: 596,
      chnn_stock_retail: 596,
      sppl_prcp: 5.07,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: 'KY66HRHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KY66HRHA'
    },
    {
      status: 910,
      sku: 'PM0082499',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339647',
      rtl_batch_array: '6339647',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: '4J3YDAL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J3YDAL9'
    },
    {
      status: 210,
      sku: 'PM0082500',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340277',
      rtl_batch_array: '6340277',
      name_search: 'LAIEDELW',
      name: "Lavandula intermedia 'Edelweiss'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3714,
      chnn_stock_retail: 3714,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GN628X7Y;D3BR2DV9;GLJR739N;936WCW3B;V24GK371;WP84S8N3',
      statusContent: 'dot',
      hash: '8XCYN6ZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XCYN6ZG'
    },
    {
      status: 910,
      sku: 'PM0074634',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      rng_range_identifier: 'H050060C7',
      statusContent: 'dot',
      hash: 'E3NYGCYJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E3NYGCYJ'
    },
    {
      status: 210,
      sku: 'PM0082501',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348452',
      rtl_batch_array: '6348452',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '060080C44',
      rng_range_identifier: 'H060080C4.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 3.519,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: '71ZRP8N2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71ZRP8N2'
    },
    {
      status: 210,
      sku: 'PM0082502',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348453',
      rtl_batch_array: '6348453',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '060080C44',
      rng_range_identifier: 'H060080C4.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 3.519,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: '8K66JXYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8K66JXYL'
    },
    {
      status: 210,
      sku: 'PM0082503',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348454',
      rtl_batch_array: '6348454',
      name_search: 'EUFMINIM',
      name: "Euonymus fortunei 'Minimus'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1980,
      chnn_stock_retail: 1980,
      sppl_order_minimum: 3,
      sppl_prcp: 0.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDL3HXLK;6EHP8PK6;W6KV7BGR;5XRTS6G4;4DALEPTE;1JPXWJ7K;KS11S8J6;AAES5KR6;1TBHNWB9;G49S5AKV',
      statusContent: 'dot',
      hash: 'N35XPJZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N35XPJZV'
    },
    {
      status: 210,
      sku: 'PM0082504',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348455',
      rtl_batch_array: '6348455',
      name_search: 'EUFVEGET',
      name: "Euonymus fortunei 'Vegetus'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1680,
      chnn_stock_retail: 1680,
      sppl_order_minimum: 3,
      sppl_prcp: 1.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '43D1LVYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43D1LVYH'
    },
    {
      status: 210,
      sku: 'PM0082505',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348458',
      rtl_batch_array: '6348458',
      name_search: 'COSAWORA',
      name: "Cornus sanguinea 'Anny's Winter Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7140,
      chnn_stock_retail: 7140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8JX285G;51W4XGP8;XTZ7J77E;VG8WJ3KX;4W7DSZ4D;2CR3VX57',
      statusContent: 'dot',
      hash: 'ERKN7X8X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERKN7X8X'
    },
    {
      status: 910,
      sku: 'PM0074635',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      statusContent: 'dot',
      hash: 'ZAEX9C6B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZAEX9C6B'
    },
    {
      status: 210,
      sku: 'PM0082506',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348460',
      rtl_batch_array: '6348460',
      name_search: 'COSWBEAU',
      name: "Cornus sanguinea 'Winter Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRDN1TCW;2Z3DSCS8;TZPXD2G1;LYJENPAZ;AYL13WRY;CZ8H6XYY',
      statusContent: 'dot',
      hash: 'J573PTZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J573PTZ9'
    },
    {
      status: 210,
      sku: 'PM0074637',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273315',
      rtl_batch_array: '6273315',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '020030C7',
      rng_range_identifier: 'H020030C7',
      sppl_stock_available: 1439,
      chnn_stock_retail: 1439,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'T3JDWZLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3JDWZLP'
    },
    {
      status: 210,
      sku: 'PM0082507',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6351916',
      rtl_batch_array: '6351916',
      name_search: 'CHLEGOLD',
      name: "Chamaecyparis lawsoniana 'Ellwood's Gold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1122,
      chnn_stock_retail: 1122,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KWH1Z7KE;9KDWTZ7W;LBCVDL5G;GSEYNWCV;GTCC8854;KX2BP4RE',
      statusContent: 'dot',
      hash: 'B7Y1BDYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7Y1BDYK'
    },
    {
      status: 210,
      sku: 'PM0082508',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6351918',
      rtl_batch_array: '6351918',
      name_search: 'JUCKETEL',
      name: "Juniperus chinensis 'Keteleeri'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_prcp: 10.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KC1LH53B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KC1LH53B'
    },
    {
      status: 210,
      sku: 'PM0082509',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352679',
      rtl_batch_array: '6352679',
      name_search: 'ARMHUGIN',
      name: "Aronia melanocarpa 'Hugin'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5TCDL68E;YGCTNB2R;1SRW387V;BPDCYZ86;NLP83ELZ;VTCT3H1S;KYDLXEAY;ELDY56HC;57S12SZ6',
      statusContent: 'dot',
      hash: 'SX3GGH18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SX3GGH18'
    },
    {
      status: 910,
      sku: 'PM0074640',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'NH98N19H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NH98N19H'
    },
    {
      status: 910,
      sku: 'PM0074641',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'V7A9GJLS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V7A9GJLS'
    },
    {
      status: 910,
      sku: 'PM0074643',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      rng_range_identifier: 'H030040C7',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: 'ZD6K7JS3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZD6K7JS3'
    },
    {
      status: 210,
      sku: 'PM0074644',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273322',
      rtl_batch_array: '6273322',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_prcp: 8.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: '16LWJVZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16LWJVZD'
    },
    {
      status: 910,
      sku: 'PM0074645',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'NTZNWVN2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NTZNWVN2'
    },
    {
      status: 910,
      sku: 'PM0074646',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      rng_range_identifier: 'H040050C7',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'TC58XHXX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TC58XHXX'
    },
    {
      status: 810,
      sku: 'PM0082510',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352680',
      rtl_batch_array: '6352680',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 5.354,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'BYTZHYDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYTZHYDE'
    },
    {
      status: 210,
      sku: 'PM0082511',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352681',
      rtl_batch_array: '6352681',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.884,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'JXT6YAYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXT6YAYV'
    },
    {
      status: 810,
      sku: 'PM0082512',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352682',
      rtl_batch_array: '6352682',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '85651G6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85651G6X'
    },
    {
      status: 210,
      sku: 'PM0082513',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352683',
      rtl_batch_array: '6352683, 6300851',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 692,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'HS1J6C5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HS1J6C5B'
    },
    {
      status: 910,
      sku: 'PM0074648',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      rng_range_identifier: 'H030040C7',
      imageCore: 'KP1W33TG;HBYJJCC4',
      statusContent: 'dot',
      hash: 'PK9VXW4V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PK9VXW4V'
    },
    {
      status: 910,
      sku: 'PM0074649',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'T4SEYZPN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T4SEYZPN'
    },
    {
      status: 210,
      sku: 'PM0082514',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352684',
      rtl_batch_array: '6352684',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.378,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'YPVNC5NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPVNC5NS'
    },
    {
      status: 910,
      sku: 'PM0074650',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      rng_range_identifier: 'H030040C7',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'E1VCD82S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E1VCD82S'
    },
    {
      status: 210,
      sku: 'PM0082515',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352685',
      rtl_batch_array: '6352685',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.708,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: '696GK81K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '696GK81K'
    },
    {
      status: 210,
      sku: 'PM0082516',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352686',
      rtl_batch_array: '6352686',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.378,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: 'P19XKELW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P19XKELW'
    },
    {
      status: 810,
      sku: 'PM0082517',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352687',
      rtl_batch_array: '6352687',
      name_search: 'DEMAGNIF',
      name: 'Deutzia magnifica',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NSBSBVWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSBSBVWB'
    },
    {
      status: 910,
      sku: 'PM0074651',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: '1H1GYA2W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1H1GYA2W'
    },
    {
      status: 210,
      sku: 'PM0082518',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352690',
      rtl_batch_array: '6352690',
      name_search: 'PHSNOWBE',
      name: "Philadelphus 'Snowbelle'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPBCVAY5;RC5JVBYP',
      statusContent: 'dot',
      hash: 'NJ1NSHKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJ1NSHKR'
    },
    {
      status: 810,
      sku: 'PM0082519',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352692',
      rtl_batch_array: '6352692',
      name_search: 'SANGPURP',
      name: "Sambucus nigra 'Guincho Purple'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BDTSBLZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDTSBLZ6'
    },
    {
      status: 210,
      sku: 'PM0082520',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352693',
      rtl_batch_array: '6352693',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'DEB3B5ND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEB3B5ND'
    },
    {
      status: 210,
      sku: 'PM0063033',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291746',
      rtl_batch_array: '6291746',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 3.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1246ZS53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1246ZS53'
    },
    {
      status: 210,
      sku: 'PM0063015',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339871',
      rtl_batch_array: '6339871, 6193646',
      name_search: 'LYNAUREA',
      name: "Lysimachia nummularia 'Aurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8R3Y534R;S57CWHHR;D9P7ETDK;VT4JNJW7',
      statusContent: 'dot',
      hash: '1L4D9ZGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L4D9ZGY'
    },
    {
      status: 210,
      sku: 'PM0026796',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6344820',
      rtl_batch_array: '5582269, 6344820',
      name_search: 'PHOTWINE',
      name: 'Physocarpus opulifolius Tiny Wine',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 300,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CL1TTKDB;8RTXK5C9;6EH61CE3',
      statusContent: 'dot',
      hash: '4TY7VZGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TY7VZGJ'
    },
    {
      status: 810,
      sku: 'PM0082521',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352695',
      rtl_batch_array: '6352695',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'D9SHNR9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9SHNR9P'
    },
    {
      status: 210,
      sku: 'PM0082522',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197099',
      rtl_batch_array: '6197099',
      name_search: 'GAKOBOLD',
      name: "Gaillardia 'Kobold'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NABW2JS9;EPX37XYC;88DYYJND;7JS5CELT;RWL19N3H;1A6DBN4J;R37WXGWY;BZZJZVK5;ADJ9XR8S',
      statusContent: 'dot',
      hash: 'ANCR9WBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANCR9WBK'
    },
    {
      status: 210,
      sku: 'PM0032412',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253070',
      rtl_batch_array: '6253070',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: '6T5W32ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T5W32ED'
    },
    {
      status: 210,
      sku: 'PM0023183',
      core_name: 'Plant',
      sppl_ean: '8720664538573',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015053',
      rtl_batch_array: '6015053',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 716,
      chnn_stock_retail: 716,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: '83E6XVKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83E6XVKV'
    },
    {
      status: 210,
      sku: 'PM0082523',
      core_name: 'Plant',
      sppl_ean: '8720664697195',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091566',
      rtl_batch_array: '6091566',
      name_search: 'LAIPROVE',
      name: "Lavandula intermedia 'Provence'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RAESV4BP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAESV4BP'
    },
    {
      status: 210,
      sku: 'PM0025875',
      core_name: 'Plant',
      sppl_ean: '8720349429028',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '5472323',
      rtl_batch_array: '6208108, 6164432, 5472323, 6260112, 6363210',
      name_search: 'STBSCARP',
      name: "Stachys byzantina 'Silver Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 16229,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J8K8NR8V;P74YSYSB;4XY17D6W;CP21H1DC;15DY8NV2;X86553HE;WG57WZWD;4JB7CTVY',
      statusContent: 'dot',
      hash: '9CY2Z59P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CY2Z59P'
    },
    {
      status: 910,
      sku: 'PM0033321',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: '9S12SLG1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9S12SLG1'
    },
    {
      status: 910,
      sku: 'PM0030841',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEONANA',
      name: "Hebe odora 'Nana'",
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      statusContent: 'dot',
      hash: '9X5BWHD6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9X5BWHD6'
    },
    {
      status: 210,
      sku: 'PM0030843',
      core_name: 'Plant',
      sppl_ean: '8720664692930',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495630',
      rtl_batch_array: '5495630',
      name_search: 'HYMSNOWB',
      name: "Hydrangea m. 'Snowball'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 754,
      chnn_stock_retail: 754,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYVB45CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYVB45CD'
    },
    {
      status: 210,
      sku: 'PM0082524',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330006',
      rtl_batch_array: '6257208, 6330006',
      name_search: 'LAVALLAD',
      name: 'Lavandula angustifolia Little Lady',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 518,
      chnn_stock_retail: 606,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JVHGZ1NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVHGZ1NE'
    },
    {
      status: 210,
      sku: 'PM0082525',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265436',
      rtl_batch_array: '6265436',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '020030C24',
      rng_range_identifier: 'H020030C2.5',
      rng_range_description: 'H20 cm 30 cm C2.5',
      sppl_stock_available: 19935,
      chnn_stock_retail: 19935,
      sppl_prcp: 1.423,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'L5VTWRW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5VTWRW7'
    },
    {
      status: 210,
      sku: 'PM0063046',
      core_name: 'Plant',
      sppl_ean: '8720664864771',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6134077',
      rtl_batch_array: '6134077, 6350220, 6164292, 6214921',
      name_search: 'GEMROSEM',
      name: "Geranium magnificum 'Rosemoor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1154,
      chnn_stock_retail: 4497,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C78Y29S3',
      statusContent: 'dot',
      hash: 'EEYELSD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEYELSD6'
    },
    {
      status: 210,
      sku: 'PM0082526',
      core_name: 'Plant',
      sppl_ean: '8720664880702',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170373',
      rtl_batch_array: '6170373',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: 'JJX6YX41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJX6YX41'
    },
    {
      status: 210,
      sku: 'PM0030649',
      core_name: 'Plant',
      sppl_ean: '8720664681804',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054479',
      rtl_batch_array: '6054479',
      name_search: 'BEFRANZI',
      name: "Bergenia 'Franziska'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6Y1V4K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6Y1V4K6'
    },
    {
      status: 210,
      sku: 'PM0082527',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348954',
      rtl_batch_array: '6348954',
      name_search: 'HEGTIMEL',
      name: "Heuchera 'Grape Timeless'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L58EW7TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L58EW7TW'
    },
    {
      status: 210,
      sku: 'PM0063018',
      core_name: 'Plant',
      sppl_ean: '8720664874237',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133361',
      rtl_batch_array: '6133361, 6193650',
      name_search: 'MARALBUS',
      name: "Mazus reptans 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 1118,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '28ZEDK2D;CG6Z48S3;SG1AYB9H;24ZGHEY5',
      statusContent: 'dot',
      hash: 'LLD77APR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLD77APR'
    },
    {
      status: 210,
      sku: 'PM0082528',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348955',
      rtl_batch_array: '6348955',
      name_search: 'HEULLOVE',
      name: "Heuchera 'Lemon Love'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WPH86DRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPH86DRW'
    },
    {
      status: 210,
      sku: 'PM0082529',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348957',
      rtl_batch_array: '6348957',
      name_search: 'HETTREAS',
      name: "Heuchera 'Timeless Treasure'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 406,
      chnn_stock_retail: 406,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CXS6ZEVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXS6ZEVE'
    },
    {
      status: 210,
      sku: 'PM0082538',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6351317',
      rtl_batch_array: '6351317',
      name_search: 'LAIPSTAR',
      name: 'Lagerstroemia i. Purple Star',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 402,
      chnn_stock_retail: 402,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HE6PKHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HE6PKHJ'
    },
    {
      status: 210,
      sku: 'PM0082539',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6351318',
      rtl_batch_array: '6351318',
      name_search: 'LAIRBLUE',
      name: 'Lagerstroemia i. Rhapsody in Blue',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 489,
      chnn_stock_retail: 489,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KC12P8C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KC12P8C3'
    },
    {
      status: 810,
      sku: 'PM0032066',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253076',
      rtl_batch_array: '6253076, 6258687',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 23,
      chnn_stock_retail: 983,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'RCV6VYDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCV6VYDG'
    },
    {
      status: 910,
      sku: 'PM0082540',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SESSDUST',
      name: 'Fout',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'BY1X3P66',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BY1X3P66'
    },
    {
      status: 910,
      sku: 'PM0059774',
      core_name: 'Plant',
      sppl_ean: '8720664858411',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6123433',
      rtl_batch_array: '6123433, 6373101',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 7,
      chnn_stock_retail: 4897,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'RYZ5WNSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYZ5WNSB'
    },
    {
      status: 810,
      sku: 'PM0032207',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281120',
      rtl_batch_array: '6281120',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'T534ZY29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T534ZY29'
    },
    {
      status: 210,
      sku: 'PM0030650',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349601',
      rtl_batch_array: '6349601',
      name_search: 'BOBILOBA',
      name: 'Boehmeria biloba',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W2PJDCZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2PJDCZ2'
    },
    {
      status: 210,
      sku: 'PM0030854',
      core_name: 'Plant',
      sppl_ean: '8720664692954',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5628399',
      rtl_batch_array: '5628399',
      name_search: 'HYMSCHBL',
      name: 'Hydrangea m. Saxon Candy Heart Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 348,
      chnn_stock_retail: 348,
      sppl_prcp: 2.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WA2XNSCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WA2XNSCH'
    },
    {
      status: 210,
      sku: 'PM0063054',
      core_name: 'Plant',
      sppl_ean: '8720664883604',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6134573',
      rtl_batch_array: '6134573',
      name_search: 'ROSAPDRI',
      name: 'Rosa (H) Peach Drift',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1410,
      chnn_stock_retail: 1410,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WHELDY6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHELDY6A'
    },
    {
      status: 210,
      sku: 'PM0031034',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '5838038',
      rtl_batch_array: '5838038',
      name_search: 'LOPMGREE',
      name: "Lonicera pileata 'Moss Green'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 9530,
      chnn_stock_retail: 9530,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZS3BA5JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZS3BA5JE'
    },
    {
      status: 210,
      sku: 'PM0063048',
      core_name: 'Plant',
      sppl_ean: '8720664883659',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6149236',
      rtl_batch_array: '6149236, 6164873, 6385789',
      name_search: 'ROWFAIRY',
      name: "Rosa (M) 'White Fairy'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 5002,
      chnn_stock_retail: 7938,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZZX6YSZ',
      statusContent: 'dot',
      hash: 'EEC4EBSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEC4EBSB'
    },
    {
      status: 210,
      sku: 'PM0022045',
      core_name: 'Plant',
      sppl_ean: '8720626349681',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420642',
      rtl_batch_array: '5420642',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 430,
      chnn_stock_retail: 430,
      sppl_prcp: 8.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '89X2V63Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89X2V63Z'
    },
    {
      status: 210,
      sku: 'PM0082530',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348978',
      rtl_batch_array: '6371172, 6348978',
      name_search: 'GEMSNIGH',
      name: 'Geranium macul. Stormy Night',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 403,
      chnn_stock_retail: 571,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '95EZ5JJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95EZ5JJT'
    },
    {
      status: 810,
      sku: 'PM0070543',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281130',
      rtl_batch_array: '6281130',
      name_search: 'VACNORTH',
      name: "Vaccinium corymbosum 'Northland'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.251,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLE5Z3P8;D5PC9EN1;SZECH9NT;NY61RGXL;J6ALZP1R',
      statusContent: 'dot',
      hash: '59W9GE71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59W9GE71'
    },
    {
      status: 210,
      sku: 'PM0074676',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273354',
      rtl_batch_array: '6273354',
      name_search: 'CYPALLGO',
      name: "Cytisus praecox 'Allgold'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 515,
      chnn_stock_retail: 515,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9A8TA8T;P2HGCLVJ;BCY23CCX;4DKDVTDC;45C5D533;WP47RDP1;APWK1A9T;LRCCRB2A;JRNTJ9RT;5NKLVW19;XKP6JSXN;DW1E68WJ',
      statusContent: 'dot',
      hash: 'BLHCCR5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLHCCR5J'
    },
    {
      status: 910,
      sku: 'PM0080100',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDFPETI',
      name: "Buddleja davidii 'Free Petite\u00ae Tutti Fruitti'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'PKZ8ASBA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PKZ8ASBA'
    },
    {
      status: 210,
      sku: 'PM0070618',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177904',
      rtl_batch_array: '6177904',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'D11YYH9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D11YYH9B'
    },
    {
      status: 910,
      sku: 'PM0080101',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUWSUNGO',
      name: "Buddleja weyeriana 'Sungold'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: '4B84PXPB;KETH4VA6',
      statusContent: 'dot',
      hash: '1GKRC6ZZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1GKRC6ZZ'
    },
    {
      status: 910,
      sku: 'PM0080102',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUWSUNGO',
      name: "Buddleja weyeriana 'Sungold'",
      rng_range_identifier: 'H050060C6',
      imageCore: '4B84PXPB;KETH4VA6',
      statusContent: 'dot',
      hash: 'XXKGVLB4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XXKGVLB4'
    },
    {
      status: 910,
      sku: 'PM0080103',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378306',
      rtl_batch_array: '6378306',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 24.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '8WKEWT3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WKEWT3K'
    },
    {
      status: 910,
      sku: 'PM0080104',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DATPFRAG',
      name: 'Daphne transatlantica Pink Fragrance',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      statusContent: 'dot',
      hash: 'SP22XYA9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SP22XYA9'
    },
    {
      status: 910,
      sku: 'PM0080105',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      rng_range_identifier: 'H060080C20',
      rng_range_description: 'H60 cm 80 cm C20',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: '5155YJ95',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5155YJ95'
    },
    {
      status: 910,
      sku: 'PM0080106',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDCRDRAG',
      name: "Edgeworthia chrysantha 'Red Dragon'",
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      statusContent: 'dot',
      hash: 'D2T1231C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D2T1231C'
    },
    {
      status: 910,
      sku: 'PM0080107',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGNOLIA',
      name: "Magnolia in cv's",
      rng_range_identifier: 'H080100C7.5',
      statusContent: 'dot',
      hash: '24HN8GZT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '24HN8GZT'
    },
    {
      status: 910,
      sku: 'PM0074652',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: 'V9GERG4H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V9GERG4H'
    },
    {
      status: 210,
      sku: 'PM0063073',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324735',
      rtl_batch_array: '6324735, 6329880',
      name_search: 'BRMLGLAS',
      name: "Brunnera macrophylla 'Looking Glass'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 621,
      chnn_stock_retail: 1432,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTA8YJXA;SRE9R499;V753LR83;GC15N5G9',
      statusContent: 'dot',
      hash: '1335TVBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1335TVBW'
    },
    {
      status: 910,
      sku: 'PM0080108',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      rng_range_identifier: 'H080100C7.5',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: '6KE5297R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6KE5297R'
    },
    {
      status: 910,
      sku: 'PM0074653',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: '61XXPJ96',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '61XXPJ96'
    },
    {
      status: 910,
      sku: 'PM0080109',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: 'LSCB5ZY1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LSCB5ZY1'
    },
    {
      status: 910,
      sku: 'PM0080110',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: '49HDBKY1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '49HDBKY1'
    },
    {
      status: 910,
      sku: 'PM0074654',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WESTYRIA',
      name: "Weigela 'Styriaca'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      statusContent: 'dot',
      hash: 'GGWX38AE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GGWX38AE'
    },
    {
      status: 210,
      sku: 'PM0082541',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353065',
      rtl_batch_array: '6353065',
      name_search: 'PAORWEDD',
      name: "Papaver orientale 'Royal Wedding'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 771,
      chnn_stock_retail: 771,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BGPV5BG2;7WVPJ8TC;WYLT75HG;61T59CWE;VE1CDXZH',
      statusContent: 'dot',
      hash: '6185W2VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6185W2VD'
    },
    {
      status: 210,
      sku: 'PM0082542',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353067',
      rtl_batch_array: '6353067',
      name_search: 'PEWATANA',
      name: 'Peltoboykinia watanabei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBXN9G91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBXN9G91'
    },
    {
      status: 810,
      sku: 'PM0021141',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267702',
      rtl_batch_array: '6267702',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '175200C5',
      rng_range_identifier: 'H175200C5',
      rng_range_description: 'H175 cm 200 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 4.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '7RYZLAC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RYZLAC1'
    },
    {
      status: 910,
      sku: 'PM0074655',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H040050C7',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'X953Y1S4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X953Y1S4'
    },
    {
      status: 910,
      sku: 'PM0074657',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'BJES2HVE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BJES2HVE'
    },
    {
      status: 910,
      sku: 'PM0074658',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      rng_range_identifier: 'H030040C7',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: '4L8NW3W2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4L8NW3W2'
    },
    {
      status: 910,
      sku: 'PM0074659',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      rng_range_identifier: 'H030040C7',
      statusContent: 'dot',
      hash: 'LJN2SHWS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LJN2SHWS'
    },
    {
      status: 910,
      sku: 'PM0074661',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'ZY3ELL5W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZY3ELL5W'
    },
    {
      status: 910,
      sku: 'PM0074662',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'J9A3NV9Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J9A3NV9Z'
    },
    {
      status: 210,
      sku: 'PM0074664',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273342',
      rtl_batch_array: '6273342',
      name_search: 'COAMIRAC',
      name: 'Cornus alba Miracle',
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 469,
      chnn_stock_retail: 469,
      sppl_prcp: 7.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AEDE1NG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEDE1NG3'
    },
    {
      status: 810,
      sku: 'PM0074665',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273343',
      rtl_batch_array: '6273343',
      name_search: 'COAMIRAC',
      name: 'Cornus alba Miracle',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 9.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BVCG15D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVCG15D3'
    },
    {
      status: 910,
      sku: 'PM0074666',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      rng_range_identifier: 'H040050C7',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'K83LGNXA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K83LGNXA'
    },
    {
      status: 910,
      sku: 'PM0074668',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      rng_range_identifier: 'H050060C7',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'TG4L2PYB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TG4L2PYB'
    },
    {
      status: 910,
      sku: 'PM0074669',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      rng_range_identifier: 'H050060C7',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'ZYBHDRYK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZYBHDRYK'
    },
    {
      status: 910,
      sku: 'PM0074670',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      rng_range_identifier: 'H040050C7',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: '5D828HBV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5D828HBV'
    },
    {
      status: 910,
      sku: 'PM0074671',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'KX9R9GJ7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KX9R9GJ7'
    },
    {
      status: 910,
      sku: 'PM0074672',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      rng_range_identifier: 'H050060C7',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'WKR1SJSD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WKR1SJSD'
    },
    {
      status: 910,
      sku: 'PM0074673',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'LR1K7XLD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LR1K7XLD'
    },
    {
      status: 910,
      sku: 'PM0074674',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COCOGGYG',
      name: 'Cotinus coggygria',
      rng_range_identifier: 'H030040C7',
      imageCore: 'KSA84DWE;Y4AN75K2;6S9YSYV4;4XW7XSEE;NK8DRPE4',
      statusContent: 'dot',
      hash: 'DAC7BGH4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DAC7BGH4'
    },
    {
      status: 210,
      sku: 'PM0074675',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273353',
      rtl_batch_array: '6273353',
      name_search: 'CYPALBUS',
      name: "Cytisus praecox 'Albus'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 528,
      chnn_stock_retail: 528,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KP66JVK;9YE8BDDL;538S5R5T;VJTEPC4S;2WJBWWEC;JH5A989V;Y6RBARWZ;1N6YCH3W;8H75LRV2;BBH911P9;SBYJWRDP;2NV3NWBC;ESN2XNAW;HKXR2953;4NE8EYVS',
      statusContent: 'dot',
      hash: '5HLHKCPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HLHKCPV'
    },
    {
      status: 810,
      sku: 'PM0074677',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273355',
      rtl_batch_array: '6273355',
      name_search: 'CYBRUBY',
      name: "Cytisus 'Boskoop Ruby'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6XDLNP54;S7N89E4J;5ZVLRWGH;25AGBBWD;X8427L61;JHDNVA1C;BWADA7DX;27NXV3C8;K1WNNNY2;TAD2NXBN;5XSLW295;LTLEYA2J;X6NSPA47;ZAV9Z5E5;47XCXRJD',
      statusContent: 'dot',
      hash: 'YYT8HYRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYT8HYRT'
    },
    {
      status: 910,
      sku: 'PM0010976',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6204192',
      rtl_batch_array: '5866407, 6204192, 6238721, 6291238, 6339724, 6333240',
      name_search: 'AGBADDER',
      name: "Agastache 'Black Adder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6,
      chnn_stock_retail: 5435,
      sppl_order_minimum: 3,
      sppl_prcp: 0.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '951ZVWNH;67D4KK2D;CP4DB3EZ',
      statusContent: 'dot',
      hash: '121ZS9NC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '121ZS9NC'
    },
    {
      status: 810,
      sku: 'PM0063057',
      core_name: 'Plant',
      sppl_ean: '8720664876316',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6135851',
      rtl_batch_array: '6135851',
      name_search: 'PASTEMPL',
      name: "Paeonia (LD) 'Shirley Temple'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPWTDJ9G;SP2P82ER;LRCNG25H;SHDCJXDE;1D77YH4G;9G5RDZBS;N7GZGE51;8LG4CW7P;XRAHC8PX',
      statusContent: 'dot',
      hash: '17D4WNAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17D4WNAY'
    },
    {
      status: 210,
      sku: 'PM0030901',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353107',
      rtl_batch_array: '6353107',
      name_search: 'PHAFGNOM',
      name: "Physalis alkekengi franchetii ' Gnome'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7TGT5V57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TGT5V57'
    },
    {
      status: 910,
      sku: 'PM0030858',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307613',
      rtl_batch_array: '6307613',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 40.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '81YYJK8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81YYJK8L'
    },
    {
      status: 210,
      sku: 'PM0008254',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319713',
      rtl_batch_array: '6350100, 6319713',
      name_search: 'ECPHDANC',
      name: "Echinacea pallida 'Hula Dancer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 145,
      chnn_stock_retail: 2613,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ANSYC',
      statusContent: 'dot',
      hash: '88BWRRRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88BWRRRE'
    },
    {
      status: 210,
      sku: 'PM0008666',
      core_name: 'Plant',
      sppl_ean: '8720664680906',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6107068',
      rtl_batch_array: '6107068, 6202264',
      name_search: 'ARMADROS',
      name: "Armeria maritima 'Abbey Deep Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2011,
      chnn_stock_retail: 2140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8W1AE4BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8W1AE4BW'
    },
    {
      status: 210,
      sku: 'PM0030936',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353250',
      rtl_batch_array: '6353250',
      name_search: 'PUSBARBI',
      name: "Pulmonaria 'Shrimps on the Barbie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AZZPZ1RK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZZPZ1RK'
    },
    {
      status: 210,
      sku: 'PM0063093',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321905',
      rtl_batch_array: '6158246, 6321905',
      name_search: 'DECONGES',
      name: 'Delosperma congestum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 267,
      chnn_stock_retail: 2211,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y57XBK6A;9WCJ6S17;XE6PDYRK;KD9GCX5G;2WKSW7G4;2TXB22L8;4Y7RGSNH;TE62VB41;W4NCW1CS;1R8AKDJS;Z2R5B8LS;5E3N4VEN;D41BDE7N;44V1YEB2;KV1X2812;7VJ6WJXR;KH5H6YS3',
      statusContent: 'dot',
      hash: 'CJBVA3W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJBVA3W9'
    },
    {
      status: 210,
      sku: 'PM0008170',
      core_name: 'Plant',
      sppl_ean: '8720349444526',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495214',
      rtl_batch_array: '6349596, 6077592, 5495214',
      name_search: 'BEMORGEN',
      name: "Bergenia 'Morgenr\u00f6te'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2020,
      chnn_stock_retail: 4829,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPHPSLZ4;C122WB4P;HEAER544;5E4HYX9H;JV8192KW',
      statusContent: 'dot',
      hash: 'HPXYYC9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPXYYC9L'
    },
    {
      status: 210,
      sku: 'PM0063075',
      core_name: 'Plant',
      sppl_ean: '8720664854321',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6136070',
      rtl_batch_array: '6136070, 5960142, 6014618',
      name_search: 'BUPURPUR',
      name: 'Buglossoides purpurocaerulea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2128,
      chnn_stock_retail: 4233,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1HJ65C4',
      statusContent: 'dot',
      hash: 'KW7LV6XD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW7LV6XD'
    },
    {
      status: 810,
      sku: 'PM0063086',
      core_name: 'Plant',
      sppl_ean: '8720664855526',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6136110',
      rtl_batch_array: '6136110',
      name_search: 'CAMSILBE',
      name: "Carex muskingumensis 'Silberstreif'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NAXYA839;DLRNXZ6Y;T2WLSVXR;RY64P99B;GX4772BN;ATC7TAK3;6RH5XDW4;E4HL13SR;S26K13J1;92H58KT3;S6A1X493;LLVEEVL7;DD12GSY5',
      statusContent: 'dot',
      hash: 'R2G8P5KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2G8P5KS'
    },
    {
      status: 810,
      sku: 'PM0063056',
      core_name: 'Plant',
      sppl_ean: '8720664876248',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6135850',
      rtl_batch_array: '6135850',
      name_search: 'PAKROSEN',
      name: "Paeonia (LD) 'Karl Rosenfield'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_order_minimum: 3,
      sppl_prcp: 1.287,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25VJ5AS1;DE8L34XV;NGXVVV8Y;JAVSBX8G;V622SNR3;14VCRPEA;HP71LGS8;8P1K23AV;YA9NP5RK;EY3WEJNP',
      statusContent: 'dot',
      hash: 'SY5XHC33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY5XHC33'
    },
    {
      status: 910,
      sku: 'PM0030916',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367971',
      rtl_batch_array: '6367971',
      name_search: 'PAPPSPIR',
      name: 'Parrotia persica Persian Spire',
      sppl_size_code: '200225C30',
      rng_range_identifier: 'H200225C30',
      rng_range_description: 'H200 cm 225 cm C30',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 47.858,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC91A6XY;XR1JSY4C;WRLSCDV9;4CJJKXYX;WZGPTPVX',
      statusContent: 'dot',
      hash: 'T5EZS5GN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5EZS5GN'
    },
    {
      status: 210,
      sku: 'PM0063055',
      core_name: 'Plant',
      sppl_ean: '8720664883666',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6134574',
      rtl_batch_array: '6134574',
      name_search: 'RORDRIFT',
      name: 'Rosa (M) Red Drift',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SDDGAD49;1N8XPKN6;1N8J9TRN;PCKPKJC3;DC5KV119',
      statusContent: 'dot',
      hash: 'ZAJTDCA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAJTDCA8'
    },
    {
      status: 210,
      sku: 'PM0082531',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349564',
      rtl_batch_array: '6349564',
      name_search: 'ANHJYKON',
      name: "Andropogon hallii 'JS Yellow Konza'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLJ4Z6GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLJ4Z6GX'
    },
    {
      status: 910,
      sku: 'PM0070655',
      core_name: 'Plant',
      sppl_ean: '8720626370807',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5429207',
      rtl_batch_array: '5429207',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 9.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'J1VLW176',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1VLW176'
    },
    {
      status: 910,
      sku: 'PM0074680',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      rng_range_identifier: 'H020030C7',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'AP7P5GET',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AP7P5GET'
    },
    {
      status: 910,
      sku: 'PM0074693',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: '91VACNZL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '91VACNZL'
    },
    {
      status: 210,
      sku: 'PM0074692',
      core_name: 'Plant',
      sppl_ean: '8720349418466',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207551',
      rtl_batch_array: '5207551',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 7.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'V3PNWBAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3PNWBAJ'
    },
    {
      status: 910,
      sku: 'PM0074696',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KOAPCLOU',
      name: "Kolkwitzia amabilis 'Pink Cloud'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'DT525ENS;T83TB1ZW;CP4XJR5D;11XNXX48;TT7K71E6;8H3AABLZ;N2K8NJK1;SDS449K5',
      statusContent: 'dot',
      hash: 'WYKVZJ91',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WYKVZJ91'
    },
    {
      status: 910,
      sku: 'PM0074686',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: '6JE1DEXK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6JE1DEXK'
    },
    {
      status: 910,
      sku: 'PM0074698',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      rng_range_identifier: 'H030040C7',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: 'WK4Z47HY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WK4Z47HY'
    },
    {
      status: 910,
      sku: 'PM0074699',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: 'RCYLVBL9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RCYLVBL9'
    },
    {
      status: 910,
      sku: 'PM0074695',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KEJPLENI',
      name: "Kerria japonica 'Pleniflora'",
      rng_range_identifier: 'H050060C7',
      imageCore:
        'Y49EYZE8;945VKGPK;J19DN9A8;9Z6LSTEH;8N4SZZ37;27N1C4K3;YC9D7VAX;WHNEBXT4;V7NR7ETW;GZ9679V3;6T1ZH9LV;5EN8PSJ2;9881V54B;5TVCNZ8L',
      statusContent: 'dot',
      hash: '2GDLHJJC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2GDLHJJC'
    },
    {
      status: 210,
      sku: 'PM0063197',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177954',
      rtl_batch_array: '6238800, 6177954, 6339809',
      name_search: 'GEENDRES',
      name: 'Geranium endressii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 11523,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TN55T71D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TN55T71D'
    },
    {
      status: 210,
      sku: 'PM0074678',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273356',
      rtl_batch_array: '6273356',
      name_search: 'CYLENA',
      name: "Cytisus 'Lena'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XP6Z81BW;JP7135W1;XGVABW97;GECHGL8H;92W19K91;NEJVW5E1;5CXD92TW;RZELBWXD;S1PV1E7B;27P2SZ8P',
      statusContent: 'dot',
      hash: 'HYAT7742',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYAT7742'
    },
    {
      status: 210,
      sku: 'PM0074679',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273357',
      rtl_batch_array: '6273357',
      name_search: 'CYPALETT',
      name: "Cytisus 'Palette'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1014,
      chnn_stock_retail: 1014,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YX8JPA8T;LDWJN841;L3G444AX;LAYHRETS;KC35K8LP;5286P25T;EXLVAH87;8E98Y9CR;L5J93EH4',
      statusContent: 'dot',
      hash: 'Z8PNHJLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8PNHJLW'
    },
    {
      status: 810,
      sku: 'PM0063150',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301651',
      rtl_batch_array: '6301651',
      name_search: 'HEUGZEBR',
      name: "Heucherella 'Golden Zebra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y3YEDHBT;K5Z47A4X;JR2TLA5L;1RSRWWNL',
      statusContent: 'dot',
      hash: 'Z6586GD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6586GD9'
    },
    {
      status: 210,
      sku: 'PM0080111',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335831',
      rtl_batch_array: '6335831',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '025030P9',
      rng_range_identifier: 'H025030P9',
      rng_range_description: 'H25 cm 30 cm P9',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_order_minimum: 6,
      sppl_prcp: 0.999,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '7HHV1WG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HHV1WG9'
    },
    {
      status: 210,
      sku: 'PM0080112',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335832',
      rtl_batch_array: '6335832',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '030040P9',
      rng_range_identifier: 'H030040P9',
      rng_range_description: 'H30 cm 40 cm P9',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_order_minimum: 6,
      sppl_prcp: 1.144,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '15ZKBGVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15ZKBGVE'
    },
    {
      status: 210,
      sku: 'PM0082532',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349571',
      rtl_batch_array: '6349571',
      name_search: 'ARDFDRAG',
      name: "Artemisia dracunculus 'French Dragon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TH1LHE3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH1LHE3E'
    },
    {
      status: 910,
      sku: 'PM0074682',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      rng_range_identifier: 'H040050C7',
      statusContent: 'dot',
      hash: 'ZP22GYXA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZP22GYXA'
    },
    {
      status: 810,
      sku: 'PM0082533',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349594',
      rtl_batch_array: '6349594',
      name_search: 'BEDUMBO',
      name: "Bergenia 'Dumbo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B6D5S7BZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6D5S7BZ'
    },
    {
      status: 210,
      sku: 'PM0082543',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353072',
      rtl_batch_array: '6353072',
      name_search: 'PEAJJOMM',
      name: "Pennisetum alopecuroides 'JS Jommenik'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '21PPLHKY;XA2H55ZT;YZ9B9XP1;YHT3DRNH;Y6TJLSWJ;HAAEGHJG;J7TGT38D;NA44H6LH;HW34AJED',
      statusContent: 'dot',
      hash: 'RTJLAGAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTJLAGAG'
    },
    {
      status: 910,
      sku: 'PM0082544',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEODWME',
      name: "Pennisetum orientale 'Dance With Me'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'EKG7G4AL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EKG7G4AL'
    },
    {
      status: 910,
      sku: 'PM0074684',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: '8TRZ4P1P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8TRZ4P1P'
    },
    {
      status: 210,
      sku: 'PM0072145',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337760',
      rtl_batch_array: '6280881, 6337760',
      name_search: 'ALJROSEA',
      name: 'Albizia julibrissin rosea',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 853,
      chnn_stock_retail: 903,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11T5R11Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11T5R11Z'
    },
    {
      status: 910,
      sku: 'PM0074685',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      rng_range_identifier: 'H040050C7',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'BXC3DV17',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BXC3DV17'
    },
    {
      status: 910,
      sku: 'PM0074687',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      rng_range_identifier: 'H050060C7',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'Y45PPV8X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y45PPV8X'
    },
    {
      status: 910,
      sku: 'PM0074688',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'TZ697TX2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TZ697TX2'
    },
    {
      status: 210,
      sku: 'PM0074689',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6273367',
      rtl_batch_array: '6273367',
      name_search: 'FOGOLDRA',
      name: "Forsythia 'Goldrausch'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1897,
      chnn_stock_retail: 1897,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B69K7S1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B69K7S1P'
    },
    {
      status: 910,
      sku: 'PM0074690',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOGOLDRA',
      name: "Forsythia 'Goldrausch'",
      rng_range_identifier: 'H030040C7',
      statusContent: 'dot',
      hash: '79HZDNE4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '79HZDNE4'
    },
    {
      status: 910,
      sku: 'PM0074691',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FOGOLDRA',
      name: "Forsythia 'Goldrausch'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      statusContent: 'dot',
      hash: 'HBWA8TG2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HBWA8TG2'
    },
    {
      status: 910,
      sku: 'PM0074694',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      rng_range_identifier: 'H040050C7',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'P7N72XY2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P7N72XY2'
    },
    {
      status: 910,
      sku: 'PM0074697',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'SDY9976E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SDY9976E'
    },
    {
      status: 210,
      sku: 'PM0000650',
      core_name: 'Plant',
      sppl_ean: '8720349439881',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5409652',
      rtl_batch_array: '5409652',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.728,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '4646RP76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4646RP76'
    },
    {
      status: 210,
      sku: 'PM0063182',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6386231',
      rtl_batch_array: '6204260, 6161673, 6333587, 6386231',
      name_search: 'EUPAPURP',
      name: "Euphorbia amygdaloides 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 5689,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1XL4HTY2;11JK28B3;W1GE9D7L;EDXYWJCL;K2T3L1AG;XGWG8TBR;EVCHSTH9;6VT3LDZN;ZSA42E3R;NSGS83AC;Y73C5D2D;Z153N58L;562ACYBV;7EA9P6YA',
      statusContent: 'dot',
      hash: '5S2LD2DK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5S2LD2DK'
    },
    {
      status: 210,
      sku: 'PM0070697',
      core_name: 'Plant',
      sppl_ean: '8720664878723',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449483',
      rtl_batch_array: '5449483',
      name_search: 'PHFPMARB',
      name: 'Photinia fraseri Pink Marble',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4CT9L811;1HBNSB84;8N74BZ6V;YARAEHC9;HGEVRBBT;JXTCBW25;9WRNT3DN;JY6R1L8Z;V6W5T3DZ;ER19K222;SDG8NAG4;DP837D6H;P3RSE34G;E6DCE2W5;24KRVHDT;EAKC87TV;4V3R78Y9;VXNWKB91;ZC7LGHKY',
      imageBatch: '4XVCKWTB',
      statusContent: 'dot',
      hash: 'XNB48D9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNB48D9B'
    },
    {
      status: 210,
      sku: 'PM0012031',
      core_name: 'Plant',
      sppl_ean: '8720664856202',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6133237',
      rtl_batch_array: '6133237, 5495239, 6268727',
      name_search: 'CETOMENT',
      name: 'Cerastium tomentosum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 604,
      chnn_stock_retail: 2014,
      sppl_order_minimum: 3,
      sppl_prcp: 0.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W4G58TXY;X5JNL7SS;KLWS5JEV;NVYHSTYA;275GZX5J;H3ACB535;TWWRS7RJ;CP7GR5Z6;91XTRWHB;2CNEHPNN;ZCZHZYV3',
      statusContent: 'dot',
      hash: '8YZGG32D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YZGG32D'
    },
    {
      status: 210,
      sku: 'PM0030944',
      core_name: 'Plant',
      sppl_ean: '8720664809147',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '5533847',
      rtl_batch_array: '5533847, 5533848',
      name_search: 'STCBJACK',
      name: "Staphylea colchica 'Black Jack\u00ae'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 400,
      sppl_prcp: 9.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9EBNDLV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EBNDLV7'
    },
    {
      status: 210,
      sku: 'PM0000103',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6113640',
      rtl_batch_array: '5450838, 6378457, 6113640',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 505,
      chnn_stock_retail: 3340,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'BTYRCPJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTYRCPJ5'
    },
    {
      status: 210,
      sku: 'PM0013746',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133584',
      rtl_batch_array: '6133584, 6353258',
      name_search: 'PYMUTICU',
      name: 'Pycnanthemum muticum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 750,
      chnn_stock_retail: 1699,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CXWDVHNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXWDVHNL'
    },
    {
      status: 210,
      sku: 'PM0018956',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271437',
      rtl_batch_array: '6271437, 5571360',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 791,
      chnn_stock_retail: 1291,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: 'ECJ9YYA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECJ9YYA4'
    },
    {
      status: 210,
      sku: 'PM0030979',
      core_name: 'Plant',
      sppl_ean: '8720664808386',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6107082',
      rtl_batch_array: '6107082, 6301841, 6287697',
      name_search: 'SESCOCCI',
      name: "Sedum spurium 'Coccineum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 499,
      chnn_stock_retail: 824,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KYRSJRLZ',
      statusContent: 'dot',
      hash: 'PY56BK18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY56BK18'
    },
    {
      status: 210,
      sku: 'PM0031017',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353325',
      rtl_batch_array: '6353325',
      name_search: 'SANCPINS',
      name: 'Salvia nem. Carad. Pink Inspiration',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 839,
      chnn_stock_retail: 839,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RCEN1TZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCEN1TZH'
    },
    {
      status: 210,
      sku: 'PM0008186',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308469',
      rtl_batch_array: '6219840, 6164644, 6308469',
      name_search: 'EUMARTIN',
      name: 'Euphorbia martini',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 839,
      sppl_order_minimum: 3,
      sppl_prcp: 0.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZGGER66P;A464B1DA;759D1DX8;NGTNSJ8K;5RVP5971;EZAXP6D9;6VDSKX6W;K5ANLWE4;67CC2W84;56AAC9E6;JZWWWBAX;2DJEGSH6;JHYKGRW2;Y21BGV1L;XS1ATDTX;PXR7PRW4;KYWPS1PA;ADWTY1K8',
      statusContent: 'dot',
      hash: 'TBDN2669',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBDN2669'
    },
    {
      status: 210,
      sku: 'PM0030960',
      core_name: 'Plant',
      sppl_ean: '8720664806795',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439498',
      rtl_batch_array: '5439498',
      name_search: 'ROPCREAM',
      name: 'Rosa Pretty Cream',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 2.656,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YB3XPKW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YB3XPKW2'
    },
    {
      status: 210,
      sku: 'PM0018444',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308797',
      rtl_batch_array: '6346761, 5905485, 5318991, 6308797',
      name_search: 'PEAREDHE',
      name: "Pennisetum alopecuroides 'Redhead'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 446,
      chnn_stock_retail: 1577,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5HWBHBZC',
      statusContent: 'dot',
      hash: 'XL6WT36H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL6WT36H'
    },
    {
      status: 910,
      sku: 'PM0074701',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      rng_range_identifier: 'H030040C7',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: 'LGEANDL7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LGEANDL7'
    },
    {
      status: 210,
      sku: 'PM0080113',
      core_name: 'Plant',
      btch_manufacturer: 1061,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335902',
      rtl_batch_array: '6335902',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'DVXXNWG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVXXNWG7'
    },
    {
      status: 210,
      sku: 'PM0082534',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349946',
      rtl_batch_array: '6349946',
      name_search: 'COEMEIEN',
      name: 'Coniogramme emeiensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 492,
      chnn_stock_retail: 492,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWY219RD;8DKV7P7R;DR7N6YGX',
      statusContent: 'dot',
      hash: 'B9K7XTER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9K7XTER'
    },
    {
      status: 210,
      sku: 'PM0082545',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353106',
      rtl_batch_array: '6353106',
      name_search: 'PHPASSIO',
      name: "Phygelius 'Passionate'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VK6KD52G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VK6KD52G'
    },
    {
      status: 210,
      sku: 'PM0072151',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337767',
      rtl_batch_array: '6269457, 6337767',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 445,
      chnn_stock_retail: 845,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: 'VJ5CC1C2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ5CC1C2'
    },
    {
      status: 910,
      sku: 'PM0074700',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      rng_range_identifier: 'H040050C7',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: 'S4JVKD4Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S4JVKD4Z'
    },
    {
      status: 910,
      sku: 'PM0074703',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: '7ZH7KZP7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7ZH7KZP7'
    },
    {
      status: 910,
      sku: 'PM0074704',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: '1K9VW8BS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1K9VW8BS'
    },
    {
      status: 910,
      sku: 'PM0074706',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLMARIB',
      name: "Prunus laurocerasus 'Mariblon'",
      rng_range_identifier: 'H050060C7',
      statusContent: 'dot',
      hash: '4VA39ANT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4VA39ANT'
    },
    {
      status: 910,
      sku: 'PM0074709',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      rng_range_identifier: 'H060080C7',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'ACDRDR7W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ACDRDR7W'
    },
    {
      status: 210,
      sku: 'PM0022532',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254659',
      rtl_batch_array: '6254659',
      name_search: 'ATNRBEAU',
      name: "Athyrium niponicum 'Red Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 940,
      chnn_stock_retail: 940,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12WJXW5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12WJXW5A'
    },
    {
      status: 210,
      sku: 'PM0018597',
      core_name: 'Plant',
      sppl_ean: '8720349484409',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6037887',
      rtl_batch_array: '6208418, 6077577, 6037887, 6014603',
      name_search: 'BECEROIC',
      name: "Bergenia cordifolia 'Eroica'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1257,
      chnn_stock_retail: 5074,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '653D78D9;PC74LZBE',
      statusContent: 'dot',
      hash: '4JBCTW38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JBCTW38'
    },
    {
      status: 210,
      sku: 'PM0025965',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184032',
      rtl_batch_array: '6184032',
      name_search: 'HEHWOERN',
      name: "Hedera helix 'Woerner'",
      sppl_size_code: '175200C34',
      rng_range_identifier: 'H175200C3.5',
      rng_range_description: 'H175 cm 200 cm C3.5',
      sppl_stock_available: 1109,
      chnn_stock_retail: 1109,
      sppl_prcp: 4.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X279SEH;RGYYYN9X;JG872GJN;8T9TWZ5J',
      statusContent: 'dot',
      hash: '5AC9CL48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AC9CL48'
    },
    {
      status: 210,
      sku: 'PM0017286',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6278123',
      rtl_batch_array: '6244246, 6304912, 6278123, 6310432',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4180,
      chnn_stock_retail: 13376,
      sppl_order_minimum: 3,
      sppl_prcp: 0.748,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: '5H9W534Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H9W534Z'
    },
    {
      status: 210,
      sku: 'PM0000872',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349034',
      rtl_batch_array: '6202289, 6340022, 6349034',
      name_search: 'ERPLANUM',
      name: 'Eryngium planum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 2453,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVJGV8X5;6L8XTN1S;966YXAT3;9PLBPRX7;HRB4SAB9;96BZK23T;4D3RKS97;CDGCX942;ZER67XZD;X26JN68H;NH71N92K;BAKN3Z21;W76DYJJE;VK7A495B;7ZNAEY4L;ZDJSK3L5;NEBB4D4H;B78VXE16;RTLBYP6T;C4WSSBKC;9C21R25T;RKHV1VJB;42G34W5B',
      statusContent: 'dot',
      hash: '7R9ZWKHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R9ZWKHT'
    },
    {
      status: 210,
      sku: 'PM0019388',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177952',
      rtl_batch_array: '6177952, 6253026, 6289996',
      name_search: 'CANBCLOU',
      name: "Calamintha nepeta 'Blue Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 7056,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ER9E2TT6;HV8BPC9E;1BATSV8H;RAVHCV9P;29WPGHWW;S51AJWVB;E22KGH84;Y199AAVD;NSEBH68N',
      statusContent: 'dot',
      hash: 'A371PAZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A371PAZT'
    },
    {
      status: 210,
      sku: 'PM0063241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220833',
      rtl_batch_array: '6220833',
      name_search: 'LAVERNUS',
      name: 'Lathyrus vernus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HAV1666X;DNEX54ZD;TDA7XE2C',
      statusContent: 'dot',
      hash: 'CGT4B6WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGT4B6WB'
    },
    {
      status: 210,
      sku: 'PM0012444',
      core_name: 'Plant',
      sppl_ean: '8720349422210',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5355809',
      rtl_batch_array: '5355809',
      name_search: 'LYSBLUSH',
      name: "Lythrum salicaria 'Blush'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 773,
      chnn_stock_retail: 773,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTYRH63;2VE522WZ',
      imageBatch: 'X7Y4KP8R',
      statusContent: 'dot',
      hash: 'H62TYACD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H62TYACD'
    },
    {
      status: 210,
      sku: 'PM0020687',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220406',
      rtl_batch_array: '6220406, 6336321, 6287605',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1180,
      chnn_stock_retail: 1988,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'J8ERA163',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8ERA163'
    },
    {
      status: 210,
      sku: 'PM0054098',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308487',
      rtl_batch_array: '6305150, 6276641, 6308487',
      name_search: 'ANHWHIRL',
      name: "Anemone hybrida 'Whirlwind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 6617,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXZXW2TW;TVZDYJ9Z;KW8683ST;64ZCB9YS;5C4Z92WR;KDYXL3CC;2NZZ6A41;786VAPSK;ZJ26YGB3;J24R6NC3',
      statusContent: 'dot',
      hash: 'LT39GHB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT39GHB7'
    },
    {
      status: 210,
      sku: 'PM0008624',
      core_name: 'Plant',
      sppl_ean: '8720353000701',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5429615',
      rtl_batch_array: '5429615',
      name_search: 'ACGAUREO',
      name: "Acorus gramineus 'Aureovariegatus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 676,
      chnn_stock_retail: 676,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJXXRLWR;YCXCT96V',
      statusContent: 'dot',
      hash: 'TSW7K467',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSW7K467'
    },
    {
      status: 210,
      sku: 'PM0063253',
      core_name: 'Plant',
      sppl_ean: '8720664873131',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147785',
      rtl_batch_array: '6147785',
      name_search: 'LOSRPRIN',
      name: "Lobelia speciosa 'Russian Princess'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 281,
      chnn_stock_retail: 281,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCP7BBSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCP7BBSY'
    },
    {
      status: 210,
      sku: 'PM0063302',
      core_name: 'Plant',
      sppl_ean: '8720664886810',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137460',
      rtl_batch_array: '6137460',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '7KGYJCTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KGYJCTT'
    },
    {
      status: 810,
      sku: 'PM0070759',
      core_name: 'Plant',
      sppl_ean: '8720349497225',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496373',
      rtl_batch_array: '5496373',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'XH92R14G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH92R14G'
    },
    {
      status: 210,
      sku: 'PM0070775',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177898',
      rtl_batch_array: '6281006, 6177898',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5050,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'ZRL7D2YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRL7D2YC'
    },
    {
      status: 810,
      sku: 'PM0070768',
      core_name: 'Plant',
      sppl_ean: '8720664849969',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169421',
      rtl_batch_array: '6169421',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'W42S8T9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W42S8T9D'
    },
    {
      status: 810,
      sku: 'PM0070769',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6169423',
      rtl_batch_array: '6169423',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: '1443DKCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1443DKCL'
    },
    {
      status: 810,
      sku: 'PM0063304',
      core_name: 'Plant',
      sppl_ean: '8720664886971',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137462',
      rtl_batch_array: '6137462',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'NC97ATLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NC97ATLK'
    },
    {
      status: 210,
      sku: 'PM0014157',
      core_name: 'Plant',
      sppl_ean: '8720664854512',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084834',
      rtl_batch_array: '6084834, 6220697, 5363945',
      name_search: 'CANMWHIT',
      name: "Calamintha nepeta 'Marvelette White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1015,
      chnn_stock_retail: 2140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '234AWE2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '234AWE2R'
    },
    {
      status: 210,
      sku: 'PM0082535',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350378',
      rtl_batch_array: '6369737, 6350378, 6352647',
      name_search: 'HEBFCAKE',
      name: "Heuchera 'Black Forest Cake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 893,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1VDSDKNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VDSDKNT'
    },
    {
      status: 210,
      sku: 'PM0082546',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353120',
      rtl_batch_array: '6353120',
      name_search: 'PORSHEAV',
      name: "Polemonium reptans 'Stairway to Heaven'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '97XKRRGV;BTW6GL7E;AB612CDW;XXWSXKSB;RYE7HEX5;463TA55N',
      statusContent: 'dot',
      hash: 'KLXXK9Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLXXK9Y8'
    },
    {
      status: 210,
      sku: 'PM0082547',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353121',
      rtl_batch_array: '6353121',
      name_search: 'POYHPARA',
      name: "Polemonium y. 'Halfway to Paradise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K51VBJ5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K51VBJ5J'
    },
    {
      status: 210,
      sku: 'PM0022421',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177565',
      rtl_batch_array: '6177565',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 137,
      chnn_stock_retail: 137,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: '2VALY7A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VALY7A2'
    },
    {
      status: 210,
      sku: 'PM0080118',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336097',
      rtl_batch_array: '6336097',
      name_search: 'CAROEVER',
      name: 'Carex oshimensis Everest',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2702,
      chnn_stock_retail: 2702,
      sppl_prcp: 2.421,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '12WD11VR;WNBKYY15;R7LK47LL;E87GZCC1;P56WL7K6;X38TJDAR;6G2HP7XK;44Y8P9K5;A7D1LWZT;E6WB5KR4;Y5EN1GG7;XA461Z7C;12TP46JP;SET8TEKR;G89E16Z8',
      statusContent: 'dot',
      hash: 'CP2K424G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP2K424G'
    },
    {
      status: 210,
      sku: 'PM0080119',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336102',
      rtl_batch_array: '6336102',
      name_search: 'CATPFIRE',
      name: "Carex testacea 'Prairie Fire'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEBPCWEX;LGRCNN59;EDBD6BEX;E6XAW7KA;CG2C2JD4;A79VAJ7G;J271T15V;VBA1C8CW;WSCHEJ77;V7W55W1G;GPCTTA52;XDRPCNE9;NDY4W9CZ;77TTCHHZ;L23TYKK9;RL96RH7C',
      statusContent: 'dot',
      hash: 'K866A5GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K866A5GS'
    },
    {
      status: 210,
      sku: 'PM0080120',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336103',
      rtl_batch_array: '6336103',
      name_search: 'CACBEMPI',
      name: 'Caryopteris clandonensis Blue Empire',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7DL4JR5;E6J6HLSK;BZELENET;JDXZ5PWY;7CK4VN3T;LNLBLK3E;D7SGXT74;R4NR8ATP;AAVXTDR5',
      statusContent: 'dot',
      hash: '4CXJ6EW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CXJ6EW6'
    },
    {
      status: 210,
      sku: 'PM0080121',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336104',
      rtl_batch_array: '6336104',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1433,
      chnn_stock_retail: 1433,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: 'ZC7Y2EC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZC7Y2EC4'
    },
    {
      status: 210,
      sku: 'PM0080122',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336105',
      rtl_batch_array: '6336105',
      name_search: 'CARCTHET',
      name: "Caryopteris clandonensis 'Thetis'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YER2ERLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YER2ERLB'
    },
    {
      status: 210,
      sku: 'PM0009614',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272831',
      rtl_batch_array: '6272831',
      name_search: 'HEFBGEM',
      name: "Hebe franciscana 'Blue Gem'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ETLP18D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ETLP18D'
    },
    {
      status: 210,
      sku: 'PM0080123',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336106',
      rtl_batch_array: '6336106',
      name_search: 'CACPPERF',
      name: 'Caryopteris clandonensis Pink Perfection',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 727,
      chnn_stock_retail: 727,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZZVBLP71;XEKRA5SG;CKVY2C17;2JJZRNP7;LN4HJ5H4;X9BKJ354',
      statusContent: 'dot',
      hash: '8W2NY4HD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8W2NY4HD'
    },
    {
      status: 210,
      sku: 'PM0080124',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336107',
      rtl_batch_array: '6336107',
      name_search: 'CEICBLUE',
      name: "Ceanothus impressus 'Cool Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2215,
      chnn_stock_retail: 2215,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCY24YA3;AXDTTS4H;RG6VSKDH;97C13KLC;636XTD52;2REEE2J4;E7J98241',
      statusContent: 'dot',
      hash: '2RW7D495',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RW7D495'
    },
    {
      status: 210,
      sku: 'PM0080125',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336108',
      rtl_batch_array: '6336108',
      name_search: 'CERGRIFF',
      name: 'Ceratostigma griffithii',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1625,
      chnn_stock_retail: 1625,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2BGB6RL;WAEP3Y4H;ESNTJ345;EVC23VT3;48KCNG4W',
      statusContent: 'dot',
      hash: 'DPKR73R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPKR73R3'
    },
    {
      status: 210,
      sku: 'PM0080126',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336110',
      rtl_batch_array: '6336110',
      name_search: 'CLARSPIC',
      name: "Clethra alnifolia 'Ruby Spice'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '16CEN8WB;2D15HZ47;47Y99NNX;1DRJ54GP;LPDEXV7R;PX8AS6WE',
      statusContent: 'dot',
      hash: 'BNDHDRW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNDHDRW5'
    },
    {
      status: 210,
      sku: 'PM0080127',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336111',
      rtl_batch_array: '6336111',
      name_search: 'COAUSTRA',
      name: 'Cordyline australis',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 13.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVEKGZYS;G4RCR9GD;L4KCYLDX;T37P555J;P2KZV9VZ;RW39X5L8;BNHZT7NC;1VLA8JD2;GY3NR7D8;XD118544;6G4SD49N;YD94TNEZ;SE78XB8Y;CR22YYK5;ZH6SZ68Y;KXRVX8A8',
      statusContent: 'dot',
      hash: 'D8Z78DXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8Z78DXT'
    },
    {
      status: 210,
      sku: 'PM0080128',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336112',
      rtl_batch_array: '6336112',
      name_search: 'COACBOY',
      name: 'Cordyline australis Charlie Boy',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2035,
      chnn_stock_retail: 2035,
      sppl_prcp: 5.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TE6TL885;4KSCWL28;A463WV41;C9Y7L816;C37D68YW',
      statusContent: 'dot',
      hash: 'GS5R8NYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS5R8NYT'
    },
    {
      status: 210,
      sku: 'PM0080129',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336113',
      rtl_batch_array: '6336113, 6337904',
      name_search: 'COPDOBLE',
      name: "Cordyline 'Paso Doble'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 869,
      chnn_stock_retail: 1014,
      sppl_prcp: 5.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWCC2JXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWCC2JXC'
    },
    {
      status: 210,
      sku: 'PM0080130',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336114',
      rtl_batch_array: '6336114',
      name_search: 'COPDOBLE',
      name: "Cordyline 'Paso Doble'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_prcp: 10.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EK94A47W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EK94A47W'
    },
    {
      status: 210,
      sku: 'PM0080131',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336115',
      rtl_batch_array: '6336115',
      name_search: 'COARSTAR',
      name: "Cordyline australis 'Red Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1906,
      chnn_stock_retail: 1906,
      sppl_prcp: 4.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9ZWEBRTX;L215AB7C;H2KWRL7X;VYKGBPTD;KP1W5YVS;Z9PNA43D;G6CZ3W6J;CR4K2HLY;D69RVERX;KK654R6Z;9BLYZSVB;75XXT1A5;CTJ2WVSG',
      statusContent: 'dot',
      hash: '6LTA843X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LTA843X'
    },
    {
      status: 210,
      sku: 'PM0080132',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336116',
      rtl_batch_array: '6336116',
      name_search: 'COARSTAR',
      name: "Cordyline australis 'Red Star'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 233,
      chnn_stock_retail: 233,
      sppl_prcp: 13.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9ZWEBRTX;L215AB7C;H2KWRL7X;VYKGBPTD;KP1W5YVS;Z9PNA43D;G6CZ3W6J;CR4K2HLY;D69RVERX;KK654R6Z;9BLYZSVB;75XXT1A5;CTJ2WVSG',
      statusContent: 'dot',
      hash: 'JLWTGXYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLWTGXYD'
    },
    {
      status: 210,
      sku: 'PM0080133',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336117',
      rtl_batch_array: '6336117',
      name_search: 'COATDAZZ',
      name: "Cordyline australis 'Torbay Dazzler'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 919,
      chnn_stock_retail: 919,
      sppl_prcp: 4.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4HVY2G9L;GNCBJXGA;CVHK1813;A4R95VHZ;H3ZN8DA2;5L26LTPX;GSSVRVDT;SAT3PDXG;DVGD81EY;W8D3HC9V;BDLRNXXA;7SW68CBK;PJ53DGXV;HY8SCCTV;YBSGXZ99;6TBG1DCX;A899ZNZE;KH4PW3HA;K114ALK2;NT3NH8WE;XY7TZWBH',
      statusContent: 'dot',
      hash: 'V13EPC1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V13EPC1H'
    },
    {
      status: 210,
      sku: 'PM0080134',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336118',
      rtl_batch_array: '6336118',
      name_search: 'COATDAZZ',
      name: "Cordyline australis 'Torbay Dazzler'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 13.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4HVY2G9L;GNCBJXGA;CVHK1813;A4R95VHZ;H3ZN8DA2;5L26LTPX;GSSVRVDT;SAT3PDXG;DVGD81EY;W8D3HC9V;BDLRNXXA;7SW68CBK;PJ53DGXV;HY8SCCTV;YBSGXZ99;6TBG1DCX;A899ZNZE;KH4PW3HA;K114ALK2;NT3NH8WE;XY7TZWBH',
      statusContent: 'dot',
      hash: '1GECE3VE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GECE3VE'
    },
    {
      status: 210,
      sku: 'PM0080135',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336119',
      rtl_batch_array: '6336119',
      name_search: 'COBEFLAS',
      name: "Cordyline banksii 'Electric Flash'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_prcp: 13.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RV684GZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV684GZA'
    },
    {
      status: 210,
      sku: 'PM0080136',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336120',
      rtl_batch_array: '6336120',
      name_search: 'COOSUPER',
      name: "Cordyline obtecta 'Superstar'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3270,
      chnn_stock_retail: 3270,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JGX543G9;8C6Z8K2S;4JLJA7ZN;XV4NAZKL;NLWVVXJY;WD9YGJA7;G1DCRN94;SCSBEHYS;TRERDN1L;EZYDC5GR',
      statusContent: 'dot',
      hash: 'YWSV67LS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWSV67LS'
    },
    {
      status: 210,
      sku: 'PM0080137',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336121',
      rtl_batch_array: '6336121',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 936,
      chnn_stock_retail: 936,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      statusContent: 'dot',
      hash: 'XVH8PBD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVH8PBD3'
    },
    {
      status: 210,
      sku: 'PM0080138',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336122',
      rtl_batch_array: '6336122',
      name_search: 'DISEMADR',
      name: "Diervilla splendens 'El Madrigal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_prcp: 3.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZZSW18T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZSW18T8'
    },
    {
      status: 210,
      sku: 'PM0080139',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336123',
      rtl_batch_array: '6336123',
      name_search: 'DISEMADR',
      name: "Diervilla splendens 'El Madrigal'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 10.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XC6A9G48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC6A9G48'
    },
    {
      status: 210,
      sku: 'PM0080140',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336124',
      rtl_batch_array: '6336124',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2117,
      chnn_stock_retail: 2117,
      sppl_prcp: 5.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: '9ARLX385',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ARLX385'
    },
    {
      status: 210,
      sku: 'PM0067954',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197019',
      rtl_batch_array: '6197019',
      name_search: 'HOELEGAN',
      name: "Hosta 'Elegans'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4436,
      chnn_stock_retail: 4436,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7YREELR;6RXH8E7C',
      statusContent: 'dot',
      hash: '5HVA1LC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HVA1LC7'
    },
    {
      status: 210,
      sku: 'PM0023106',
      core_name: 'Plant',
      sppl_ean: '8720349423545',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5481462',
      rtl_batch_array: '5481462',
      name_search: 'NESUBSES',
      name: 'Nepeta subsessilis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2681,
      chnn_stock_retail: 2681,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4L327E42;L8JN45NL;H5698S63;NCGGS11K;CW9PWHY7;469Z4BEP;J5W7VY4Z;8TAS89JW;WXXHYY2N;KPVWE73R;JWKYNVXT',
      imageBatch: '2SA4L7P4',
      statusContent: 'dot',
      hash: '7ACP9CZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ACP9CZY'
    },
    {
      status: 210,
      sku: 'PM0024324',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338978',
      rtl_batch_array: '6260723, 6338978',
      name_search: 'COREICHH',
      name: "Cotoneaster radicans 'Eichholz'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8370,
      chnn_stock_retail: 12000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.748,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ3H5N6X;C372KZ93;Y22E9J4N;1J88TZGL;WTTXJPSA;LL3WSH5P',
      statusContent: 'dot',
      hash: '7Y2LR596',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y2LR596'
    },
    {
      status: 810,
      sku: 'PM0063300',
      core_name: 'Plant',
      sppl_ean: '8720664877597',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137457',
      rtl_batch_array: '6137457',
      name_search: 'PHSCHNEE',
      name: "Philadelphus 'Schneesturm'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S8W923C8;1YGB42NY',
      statusContent: 'dot',
      hash: 'ARWNDGVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARWNDGVK'
    },
    {
      status: 810,
      sku: 'PM0027418',
      core_name: 'Plant',
      sppl_ean: '8720664694071',
      btch_manufacturer: 508,
      rtl_batch_array_total: 3,
      btch_active_retail: '5496611',
      rtl_batch_array: '5356256, 5496611, 5496612',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 150,
      sppl_prcp: 11.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'BBZZ9VR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBZZ9VR6'
    },
    {
      status: 210,
      sku: 'PM0028113',
      core_name: 'Plant',
      sppl_ean: '8720664850248',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6134074',
      rtl_batch_array: '6134074, 6263740, 6291699',
      name_search: 'ACTERRAC',
      name: "Achillea 'Terracotta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 6994,
      sppl_order_minimum: 3,
      sppl_prcp: 0.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P54ZSLA8;YV2SSJLS;1B26DHNY;V1HTCTJR;ZJHWAN7T;V1JKWRXK',
      statusContent: 'dot',
      hash: 'CHLG1W96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHLG1W96'
    },
    {
      status: 210,
      sku: 'PM0063301',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243048',
      rtl_batch_array: '6243048',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ED887NWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED887NWA'
    },
    {
      status: 210,
      sku: 'PM0014973',
      core_name: 'Plant',
      sppl_ean: '8720664692497',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '5741220',
      rtl_batch_array: '5741220, 5321499',
      name_search: 'HOCHULK',
      name: "Hosta 'Colored Hulk'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4096,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EL44ZAPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EL44ZAPW'
    },
    {
      status: 910,
      sku: 'PM0025328',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307633',
      rtl_batch_array: '6307633',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 24.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'JERLY196',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JERLY196'
    },
    {
      status: 910,
      sku: 'PM0007826',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEOBEAUT',
      name: "Hebe 'Oratia Beauty'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      statusContent: 'dot',
      hash: 'LGZNWRKY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LGZNWRKY'
    },
    {
      status: 210,
      sku: 'PM0001688',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272789',
      rtl_batch_array: '6272789',
      name_search: 'EUCSEDGE',
      name: "Euphorbia characias 'Silver Edge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_prcp: 3.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKEYA1EE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKEYA1EE'
    },
    {
      status: 210,
      sku: 'PM0015395',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6324727',
      rtl_batch_array: '6077616, 6324727',
      name_search: 'BRMMMORS',
      name: "Brunnera macrophylla 'Mister Morse'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1537,
      chnn_stock_retail: 1693,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3Y8P5L5;ZJSL8ZGV;6GCV3HT7;B3AW65Y2;6JPTEGJP;7JBA2CPC;5WHWAHE6',
      statusContent: 'dot',
      hash: 'TST7GBC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TST7GBC4'
    },
    {
      status: 210,
      sku: 'PM0006299',
      core_name: 'Plant',
      sppl_ean: '8720664889415',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164444',
      rtl_batch_array: '6349161, 6302819, 6164444, 6330140',
      name_search: 'VESUDBLU',
      name: "Veronica spicata 'Ulster Dwarf Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 2668,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XLPRNV5A;6PB4KV39;NBJKAZNK;N6K39S8W;93GV6W39;7RX2PL8Y;AKZ7YLLW;N9SL1ZAE;K1WGKSW3;BK236749;Y8ZKX3BL;J2C69YW7;GL6AVRSY;9TTGTKX4;J1244EVZ;PY4XLR1J;47AVCVXX;WEGHCCCY;4SDW3SDK',
      statusContent: 'dot',
      hash: 'TYX7XBH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYX7XBH1'
    },
    {
      status: 810,
      sku: 'PM0063286',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353473',
      rtl_batch_array: '6353473',
      name_search: 'STTENUIF',
      name: 'Stipa tenuifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NZDLLB68;SRXG6NV9;6GAWPACH;AD9XJK64;ZSEZBKVA;TZJYJ4XY;BXHRBANE;ZXEELT41;2SPTW1XC;5YKV3XXR;GEJDACX8;GXY8YAP7;TGD4SDVP',
      statusContent: 'dot',
      hash: 'V9YXHJYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9YXHJYY'
    },
    {
      status: 810,
      sku: 'PM0000616',
      core_name: 'Plant',
      sppl_ean: '8720664586796',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5481461',
      rtl_batch_array: '5481461',
      name_search: 'NESPDREA',
      name: "Nepeta subsessilis 'Pink Dreams'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '9XY9Y2SL',
      statusContent: 'dot',
      hash: 'W2PS18L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2PS18L5'
    },
    {
      status: 210,
      sku: 'PM0013259',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6245545',
      rtl_batch_array: '6245545, 5303673, 6319693',
      name_search: 'GECSOLA',
      name: "Geranium cantabrigiense 'St Ola'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 6284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4NEZK75',
      statusContent: 'dot',
      hash: 'R6RXNT4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6RXNT4A'
    },
    {
      status: 210,
      sku: 'PM0082536',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350643',
      rtl_batch_array: '6350643',
      name_search: 'LERNEAT',
      name: "Leucanthemum (S) 'Real Neat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VTVS9V62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTVS9V62'
    },
    {
      status: 210,
      sku: 'PM0082537',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350677',
      rtl_batch_array: '6350677',
      name_search: 'MEPMOJIT',
      name: "Mentha piperita 'Mojito'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 383,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BW97TYHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BW97TYHV'
    },
    {
      status: 210,
      sku: 'PM0002043',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6202334',
      rtl_batch_array: '6202334, 6301812, 6330077',
      name_search: 'SANSDBLU',
      name: 'Salvia nemorosa Sensation Deep Blue',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 753,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '71P1LCKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71P1LCKJ'
    },
    {
      status: 210,
      sku: 'PM0080141',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336125',
      rtl_batch_array: '6336125',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.371,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: 'JB3D99BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB3D99BN'
    },
    {
      status: 210,
      sku: 'PM0008035',
      core_name: 'Plant',
      sppl_ean: '8720349459865',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '5607021',
      rtl_batch_array: '6350687, 5607021',
      name_search: 'MISSDWAR',
      name: "Miscanthus sinensis 'Strictus Dwarf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4600,
      chnn_stock_retail: 5080,
      sppl_order_minimum: 3,
      sppl_prcp: 0.701,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YSS4VHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YSS4VHR'
    },
    {
      status: 210,
      sku: 'PM0074712',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293887',
      rtl_batch_array: '6293887',
      name_search: 'ILGGEMBU',
      name: 'Ilex glabra Gembux',
      sppl_size_code: '035040C74BO',
      rng_range_identifier: 'BALL\u00d8040H035040C7.5',
      rng_range_description: 'Ball \u00d8 40 cm H35 cm 40 cm C7.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 9.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5XJZAPLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XJZAPLT'
    },
    {
      status: 210,
      sku: 'PM0014000',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260701',
      rtl_batch_array: '6260701, 5838036, 6271628',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5880,
      chnn_stock_retail: 25880,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'A81411T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A81411T7'
    },
    {
      status: 210,
      sku: 'PM0074713',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293888',
      rtl_batch_array: '6293888',
      name_search: 'ILGSTRON',
      name: 'Ilex glabra Strongbux',
      sppl_size_code: '035040C74BO',
      rng_range_identifier: 'BALL\u00d8040H035040C7.5',
      rng_range_description: 'Ball \u00d8 40 cm H35 cm 40 cm C7.5',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 9.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R73R3P4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R73R3P4X'
    },
    {
      status: 210,
      sku: 'PM0067965',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254791',
      rtl_batch_array: '6254791',
      name_search: 'NADGSTRE',
      name: "Nandina domestica 'Gulf Stream'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5177CD65;2ZXX95WG;HXBEY3NS;1KGAWP8J;DKXS5BGB;6D121H4B;VSRDDRHB;KW74VYE1;W3K99X4E;BC74L52W;ECSHV643;ZNS6C38G;A9NSHWK2',
      statusContent: 'dot',
      hash: 'RGC52J29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGC52J29'
    },
    {
      status: 210,
      sku: 'PM0029337',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333246',
      rtl_batch_array: '6136401, 6333246',
      name_search: 'HEGQUEEN',
      name: "Helianthemum 'Golden Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6457,
      chnn_stock_retail: 6977,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GEK5XSJL;YG5ZATCZ;4NW864P1;A8S8GJ84;XB9RA5T6;AZ1C1RL5;BK55X3HV;BH53YGEZ',
      statusContent: 'dot',
      hash: 'RTBTEJ3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTBTEJ3P'
    },
    {
      status: 210,
      sku: 'PM0024788',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308479',
      rtl_batch_array: '6090645, 5303778, 6308479',
      name_search: 'AJRCGIAN',
      name: "Ajuga reptans 'Catlin's Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2381,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ERAZC3L;Z6X1ACD1;XSTZ4E72;S4SBLT1R;4LXVY64Z;C5P71B7C;PX3EVE8J;RD7ET1G5',
      statusContent: 'dot',
      hash: 'SSV4APV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSV4APV5'
    },
    {
      status: 210,
      sku: 'PM0008674',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 6,
      btch_active_retail: '6177572',
      rtl_batch_array: '2733853, 5837405, 5849655, 6252507, 5414682, 6177572',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3014,
      chnn_stock_retail: 9079,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: 'A83WN31B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A83WN31B'
    },
    {
      status: 210,
      sku: 'PM0029505',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333181',
      rtl_batch_array: '6284298, 6333181, 6352696',
      name_search: 'ARDIOICU',
      name: 'Aruncus dioicus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10759,
      chnn_stock_retail: 14705,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N43BAD8S;4NSCXXCJ;GGT35123;HVD1GX1T;WEWG9AX4;2GKJYJHX;D2R8D63S;P8H2L5B6;V16SSWK2;1D6EV6HT;P1KXKHTS',
      statusContent: 'dot',
      hash: 'SZDH66R1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZDH66R1'
    },
    {
      status: 210,
      sku: 'PM0080142',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336126',
      rtl_batch_array: '6336126, 6385791',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 965,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'XT3LR1BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT3LR1BD'
    },
    {
      status: 210,
      sku: 'PM0019687',
      core_name: 'Plant',
      sppl_ean: '8720664873681',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080702',
      rtl_batch_array: '6080702',
      name_search: 'LYATROPU',
      name: 'Lysimachia atropurpurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TBHX2B2N',
      statusContent: 'dot',
      hash: 'ALRJZ1XS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALRJZ1XS'
    },
    {
      status: 210,
      sku: 'PM0080143',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336127',
      rtl_batch_array: '6336127',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_prcp: 3.371,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: '8S45TSPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S45TSPG'
    },
    {
      status: 210,
      sku: 'PM0017796',
      core_name: 'Plant',
      sppl_ean: '8720349416172',
      btch_manufacturer: 93,
      rtl_batch_array_total: 6,
      btch_active_retail: '5837953',
      rtl_batch_array: '6204210, 5837953, 6205777, 5303672, 6287676, 6348323',
      name_search: 'GECBIOKO',
      name: "Geranium cantabrigiense 'Biokovo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5116,
      chnn_stock_retail: 15885,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DVDG9E94;WVXXXA4Z;GPH756PG;5EXRA7WG;DXRGE7XS;R2N72X5P;ZAN9PRYS;YLEGBWGZ;L16JD9VE;VNT4AYH4;KYDR38NK;P9723XS5',
      statusContent: 'dot',
      hash: 'CP7R76S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP7R76S1'
    },
    {
      status: 210,
      sku: 'PM0006794',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6274831',
      rtl_batch_array: '6084853, 6274831, 6386082',
      name_search: 'CAPENDUL',
      name: 'Carex pendula',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2485,
      chnn_stock_retail: 10105,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8ZBR6W7S;DZ8A7RBS;J1LKHJRB;G66P3WJ5;E6RAB6TD;X1JXZKJV;6YVN7EJ9;N2GLX2EW;ER1TNX9C;L6AABLYN;1JEKEE3W;NRKR5DXX;HP54W3NB',
      statusContent: 'dot',
      hash: 'VA96ZVAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VA96ZVAK'
    },
    {
      status: 210,
      sku: 'PM0017358',
      core_name: 'Plant',
      sppl_ean: '8720664887442',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6133755',
      rtl_batch_array: '5364535, 6133755, 6353479, 6383378',
      name_search: 'SYGRANDI',
      name: 'Symphytum grandiflorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 26543,
      chnn_stock_retail: 39642,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZPZ2L588',
      statusContent: 'dot',
      hash: 'CRJ74735',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRJ74735'
    },
    {
      status: 210,
      sku: 'PM0008842',
      core_name: 'Plant',
      sppl_ean: '8720664854550',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6162052',
      rtl_batch_array: '6159865, 6162052, 6254364, 6242137',
      name_search: 'CANNEPET',
      name: 'Calamintha nepeta nepeta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 11230,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9S3N79A;7YC1YZAD;B2K2VY9N',
      statusContent: 'dot',
      hash: 'GT95CWJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT95CWJ1'
    },
    {
      status: 210,
      sku: 'PM0003142',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 4,
      btch_active_retail: '6385481',
      rtl_batch_array: '6159902, 5303716, 6353146, 6385481',
      name_search: 'PEASPECI',
      name: "Persicaria amplexicaulis 'Speciosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2408,
      chnn_stock_retail: 20415,
      sppl_order_minimum: 3,
      sppl_prcp: 1.192,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9R6TW6H3',
      statusContent: 'dot',
      hash: 'GYX4NBG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYX4NBG1'
    },
    {
      status: 210,
      sku: 'PM0020209',
      core_name: 'Plant',
      sppl_ean: '8720664852204',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6147165',
      rtl_batch_array: '6147165, 6164360, 6321886',
      name_search: 'ARPCASTL',
      name: "Artemisia 'Powis Castle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1376,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '42P8BHC5;YB9AYJWE;BCYNKNPC;7CZ6TSXH;WSAEXT48;B6P136D7;ALZEVC8L;KJDCWGKZ;G5WCXB53;56EWJ2CB;ECXD4DB6;841TD52X;CRCNG4ZC;J193V2X1;EHRERWCA;BE5TGWE6',
      statusContent: 'dot',
      hash: 'JJL95JES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJL95JES'
    },
    {
      status: 910,
      sku: 'PM0012839',
      core_name: 'Plant',
      sppl_ean: '8720626302518',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856800',
      rtl_batch_array: '5856800',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 32.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'JVP5AKV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVP5AKV5'
    },
    {
      status: 210,
      sku: 'PM0080144',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336128',
      rtl_batch_array: '6336128',
      name_search: 'ERSPECTA',
      name: 'Eragrostis spectabilis',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JTGT1V3P;GLNZCGB7;P4XBJ7YW;D2VPKWKA;T3WW1HD6;B2X1A5W4;XX9ZWDHX;41SJ5XAH;H6XAYHPS',
      statusContent: 'dot',
      hash: '1SN5SWKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SN5SWKX'
    },
    {
      status: 210,
      sku: 'PM0080145',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336129',
      rtl_batch_array: '6336129',
      name_search: 'ESPDONAR',
      name: "Escallonia 'Pride of Donard'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 375,
      chnn_stock_retail: 375,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5AZZGG3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AZZGG3A'
    },
    {
      status: 210,
      sku: 'PM0080146',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336130',
      rtl_batch_array: '6336130',
      name_search: 'ESRMACRA',
      name: 'Escallonia rubra macrantha',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1192,
      chnn_stock_retail: 1192,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42914K6K',
      statusContent: 'dot',
      hash: 'YJZ9V49D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJZ9V49D'
    },
    {
      status: 210,
      sku: 'PM0080147',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336131',
      rtl_batch_array: '6336131',
      name_search: 'EUFBLOND',
      name: 'Euonymus fortunei Blondy',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8C8Y6YHK;HS11ACLH;THZ8EDAT;LNWY9W9E;BB6AB8HA;YK2ZRG73;1D2LXSL6;PEJGDNHR',
      statusContent: 'dot',
      hash: '61J28ZDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61J28ZDB'
    },
    {
      status: 210,
      sku: 'PM0080148',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336135',
      rtl_batch_array: '6336135',
      name_search: 'EUJHIMAL',
      name: 'Euonymus japonicus Himalaya',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9371,
      chnn_stock_retail: 9371,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JEWPXJLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEWPXJLY'
    },
    {
      status: 210,
      sku: 'PM0080149',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336137',
      rtl_batch_array: '6336137',
      name_search: 'EUJHIMAL',
      name: 'Euonymus japonicus Himalaya',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4610,
      chnn_stock_retail: 4610,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NL4STWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NL4STWZ'
    },
    {
      status: 210,
      sku: 'PM0080150',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336140',
      rtl_batch_array: '6336140',
      name_search: 'EUJMALBO',
      name: "Euonymus japonicus 'Microphyllus Albovariegatus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1340,
      chnn_stock_retail: 1340,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RPCNN3P8;CLPV112N;4V5SY12J;91S7D8SD;Z96NABGW;1LRXKRXD;CJKGHRXR;EYAYN47W;LT3892CP;DDTCZTAP;DYALEX3G;2YT96E24;484GPBP7;C2JBESDN;PHSY87Z9;WSZ53RRT;51TBGKA1;P5CWWLXE;XCVHCT9Z;RWVTGAXR;6H3HZ7D9;L9XC9BAX',
      statusContent: 'dot',
      hash: 'ZZ471DA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZ471DA8'
    },
    {
      status: 210,
      sku: 'PM0080151',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336141',
      rtl_batch_array: '6336141',
      name_search: 'EUJPBLAN',
      name: 'Euonymus japonicus Paloma Blanca',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9711,
      chnn_stock_retail: 9711,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H3R51T6;B7E9D968;L2K9DYN2;RE2CWTBT;RCPWNR7V;TCAXJVBK;HBBA57GJ;S2VJP2XN;CLH92YZR;L4C927H1;9948PKVC;65LE7L44;ATD8AAJC',
      statusContent: 'dot',
      hash: '8G8WG79W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G8WG79W'
    },
    {
      status: 210,
      sku: 'PM0020833',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6134076',
      rtl_batch_array: '6134076, 6329838, 6181369, 6349048',
      name_search: 'ARSNANA',
      name: "Artemisia schmidtiana 'Nana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 8424,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JL7PP2R9;9YKBT6BG;GG617GS7;8EY4KJ81;DSCHZ4G2',
      statusContent: 'dot',
      hash: 'VW1V688D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VW1V688D'
    },
    {
      status: 210,
      sku: 'PM0011112',
      core_name: 'Plant',
      sppl_ean: '8720349409761',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5206840',
      rtl_batch_array: '5206840',
      name_search: 'ASAASRAN',
      name: "Aster ageratoides 'Asran'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3314,
      chnn_stock_retail: 3314,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TY81J1LK;D4RYEXTX',
      imageBatch: '1W48HJJJ',
      statusContent: 'dot',
      hash: 'JYXR8HEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYXR8HEY'
    },
    {
      status: 210,
      sku: 'PM0080152',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336143',
      rtl_batch_array: '6336143',
      name_search: 'FOMAJOR',
      name: 'Fothergilla major',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 4.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N46NLV3Z;77E6K2RB;4X4CGH67;5G4EARD4;C42ZL9BE;PCB852GY;K5BZZVD1',
      statusContent: 'dot',
      hash: 'HBBX5WBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBBX5WBN'
    },
    {
      status: 210,
      sku: 'PM0080153',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336144',
      rtl_batch_array: '6336144',
      name_search: 'GAJFPROO',
      name: 'Gardenia jasm. Fool Proof',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 441,
      chnn_stock_retail: 441,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AJ4V5VJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJ4V5VJ9'
    },
    {
      status: 210,
      sku: 'PM0003395',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 5,
      btch_active_retail: '6356246',
      rtl_batch_array: '6147172, 6176213, 6164290, 6356246, 6348325',
      name_search: 'GEMSPESS',
      name: "Geranium macrorrhizum 'Spessart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2004,
      chnn_stock_retail: 16095,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HDAB2S7T;G7REDB73;2ZHA3EEG;6KD5XJXT;6C7EK5RV',
      statusContent: 'dot',
      hash: 'K59C5N9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K59C5N9B'
    },
    {
      status: 210,
      sku: 'PM0080154',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336146',
      rtl_batch_array: '6336146',
      name_search: 'GAPROCUM',
      name: 'Gaultheria procumbens',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_order_minimum: 3,
      sppl_prcp: 1.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SAPSVZ78;2E9BY7E8;N9J99DZA;XV9X8219;T5TT7PBP;66H4BZ4X;16ATYWBR;XYW5E6YX;VVPNT1AR;9WSSE2RC;BCD3XLKT;Y8VHXSEW;W4ATXP61;H476TL3X;TCX6DHZA;WKEK1XBV;RYDYAA46;AJYW4K1V;Z12J3TY1;PVXGE37Y;K2W9LKND;CCYX83RP',
      statusContent: 'dot',
      hash: 'BRTTP6X9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRTTP6X9'
    },
    {
      status: 210,
      sku: 'PM0080155',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336147',
      rtl_batch_array: '6336147',
      name_search: 'GAPROCUM',
      name: 'Gaultheria procumbens',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 951,
      chnn_stock_retail: 951,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SAPSVZ78;2E9BY7E8;N9J99DZA;XV9X8219;T5TT7PBP;66H4BZ4X;16ATYWBR;XYW5E6YX;VVPNT1AR;9WSSE2RC;BCD3XLKT;Y8VHXSEW;W4ATXP61;H476TL3X;TCX6DHZA;WKEK1XBV;RYDYAA46;AJYW4K1V;Z12J3TY1;PVXGE37Y;K2W9LKND;CCYX83RP',
      statusContent: 'dot',
      hash: 'B4P6NP3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4P6NP3E'
    },
    {
      status: 210,
      sku: 'PM0080156',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336148',
      rtl_batch_array: '6336148',
      name_search: 'GAPCSURP',
      name: 'Gaultheria proc. Color Surprise',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_order_minimum: 3,
      sppl_prcp: 1.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JV3PRHLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV3PRHLA'
    },
    {
      status: 210,
      sku: 'PM0080157',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336151',
      rtl_batch_array: '6336151',
      name_search: 'HEHANNA',
      name: 'Hebe Hebedonna Anna',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 1583,
      chnn_stock_retail: 1583,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W146H9H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W146H9H6'
    },
    {
      status: 210,
      sku: 'PM0080158',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336153',
      rtl_batch_array: '6336153',
      name_search: 'HEHBRITT',
      name: 'Hebe Hebedonna Britta',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 1444,
      chnn_stock_retail: 1444,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '17T524AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17T524AW'
    },
    {
      status: 210,
      sku: 'PM0080159',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336154',
      rtl_batch_array: '6336154',
      name_search: 'HEHJENNA',
      name: 'Hebe Hebedonna Jenna',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 1878,
      chnn_stock_retail: 1878,
      sppl_order_minimum: 3,
      sppl_prcp: 1.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1Y843V6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1Y843V6N'
    },
    {
      status: 210,
      sku: 'PM0080160',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336156',
      rtl_batch_array: '6336156',
      name_search: 'HEOCHRAC',
      name: 'Hebe ochracea',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KG18WV25',
      statusContent: 'dot',
      hash: 'NA5HDL13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NA5HDL13'
    },
    {
      status: 910,
      sku: 'PM0082548',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ROOBRAIN',
      name: "Rosmarinus officinalis 'Blue Rain'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1T54PNW4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1T54PNW4'
    },
    {
      status: 210,
      sku: 'PM0082549',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353189',
      rtl_batch_array: '6353189',
      name_search: 'ROOVEITS',
      name: "Rosmarinus off. 'Veitsh\u00f6chheim'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YD657NW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YD657NW'
    },
    {
      status: 210,
      sku: 'PM0082550',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353192',
      rtl_batch_array: '6353192',
      name_search: 'RUHCBRAN',
      name: "Rudbeckia hirta 'Cherry Brandy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 549,
      chnn_stock_retail: 549,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7KZZ879P',
      statusContent: 'dot',
      hash: 'DH4KWBVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH4KWBVJ'
    },
    {
      status: 210,
      sku: 'PM0082551',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353195',
      rtl_batch_array: '6353195',
      name_search: 'RUMISSOU',
      name: 'Rudbeckia missouriensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 346,
      chnn_stock_retail: 346,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZGH825RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGH825RT'
    },
    {
      status: 210,
      sku: 'PM0020776',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259804',
      rtl_batch_array: '6259804, 5532387',
      name_search: 'MOCEDUDS',
      name: "Molinia caerulea 'Edith Dudszus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 763,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9KXNAK1H;YDJDPZC6;CNTVT778;4HRRSPED;2CXKBNHA;GJGGTLN4;51S5V8X9;SP827C96;TL2PL2A8;WTYG3AK3;27PY65GE;AET1GN3Z;9WS14VP9;Z1CA99EH;C8D7YR8Y',
      statusContent: 'dot',
      hash: 'W3JBP4HP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3JBP4HP'
    },
    {
      status: 210,
      sku: 'PM0020159',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 4,
      btch_active_retail: '6269489',
      rtl_batch_array: '5837970, 6269489, 6356143, 6300739',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4994,
      chnn_stock_retail: 33450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '983KZSGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '983KZSGN'
    },
    {
      status: 210,
      sku: 'PM0000500',
      core_name: 'Plant',
      sppl_ean: '8720349474653',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5607009',
      rtl_batch_array: '6133297, 6329909, 5607009',
      name_search: 'DECTAUTR',
      name: "Deschampsia cespitosa 'Tautr\u00e4ger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7100,
      chnn_stock_retail: 7980,
      sppl_order_minimum: 3,
      sppl_prcp: 0.568,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8S53LDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8S53LDB'
    },
    {
      status: 210,
      sku: 'PM0000573',
      core_name: 'Plant',
      sppl_ean: '8720626358621',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866433',
      rtl_batch_array: '5866433',
      name_search: 'LYCORONA',
      name: 'Lychnis coronaria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 742,
      chnn_stock_retail: 742,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P48T887S;BP7BP3C1;ERX1GKRC;SL2ACZY1;4XXRCN5Y;BZW4XVSS;K6R6KD5L;RWE531L5;PJ1BY4DL;AXAV4EVZ',
      statusContent: 'dot',
      hash: 'XAPB7JEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAPB7JEV'
    },
    {
      status: 210,
      sku: 'PM0004977',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291241',
      rtl_batch_array: '6295635, 6291241',
      name_search: 'HEBHOPE',
      name: "Helianthemum 'Ben Hope'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 595,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5XPHS6BD;BRDZT967;6HVE5W1X',
      statusContent: 'dot',
      hash: 'YG9GGNJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YG9GGNJT'
    },
    {
      status: 210,
      sku: 'PM0015698',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 8,
      btch_active_retail: '6255978',
      rtl_batch_array:
        '6204206, 6350738, 6242042, 5364373, 5577520, 5838046, 6255978, 6340036',
      name_search: 'PATERMIN',
      name: 'Pachysandra terminalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10058,
      chnn_stock_retail: 25018,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '75KDGC7C;LELCZ73R;NDHGKGGT;GN61W2KN;TX925EKH;W9S1NG9S;WP6ZBEBW',
      statusContent: 'dot',
      hash: 'ZN3G64SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN3G64SL'
    },
    {
      status: 210,
      sku: 'PM0072220',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338572',
      rtl_batch_array: '6243050, 6338572',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1197,
      chnn_stock_retail: 1447,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '6CC83PDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CC83PDB'
    },
    {
      status: 910,
      sku: 'PM0030556',
      core_name: 'Plant',
      sppl_ean: '8720626302525',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5856801',
      rtl_batch_array: '5856801',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 37.388,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'KWPVCWJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWPVCWJZ'
    },
    {
      status: 210,
      sku: 'PM0021632',
      core_name: 'Plant',
      sppl_ean: '8720349474561',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '5313894',
      rtl_batch_array: '6015424, 6091541, 5313894, 5364275',
      name_search: 'LAMROSEU',
      name: "Lamium maculatum 'Roseum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2937,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9HS9ZDAA;SGPHVVK2;JJ1YLJLS;N21LLGDG;W9L6C439;6Z6WR2N9;YW41J9BK;CYJPLLEW;78H5HT4N;1XVS5PT2;WE6LGC5V;TJ1GKLXP',
      statusContent: 'dot',
      hash: '2KX666SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KX666SL'
    },
    {
      status: 210,
      sku: 'PM0014905',
      core_name: 'Plant',
      sppl_ean: '8720349431748',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6034944',
      rtl_batch_array: '6034944, 5960166, 6091095, 6305511',
      name_search: 'GAODORAT',
      name: 'Galium odoratum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 8247,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PPD93DZV;1245S7YA;8EJ11S97;GLCNSSC9;KJC9DW3W;P4EB3RCC',
      statusContent: 'dot',
      hash: '4V2WP13X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V2WP13X'
    },
    {
      status: 210,
      sku: 'PM0025486',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6370816',
      rtl_batch_array: '6313211, 5837483, 6370816, 6252724',
      name_search: 'PEADRED',
      name: "Persicaria affinis 'Darjeeling Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1615,
      chnn_stock_retail: 4161,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P1B9WA3A;RV18ATHJ;N7WVNH69;CRT52J8B',
      statusContent: 'dot',
      hash: '5N4LG2TT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N4LG2TT'
    },
    {
      status: 210,
      sku: 'PM0016525',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6196782',
      rtl_batch_array: '5303682, 6242962, 6196782, 6350364',
      name_search: 'HESORO',
      name: "Hemerocallis 'Stella de Oro'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 18058,
      chnn_stock_retail: 32614,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J55KVYTA;C4TAZAYH;9RGZJJVV;K14SP74B;RJRGE5L7;YELRS667;DHGJXWPA;JP32E8G5;7V324DPR;NCGXDYRX;HY33XH71;L138KD8S;YVLC6WC9;ZVAN65VZ;TS1ZVWWG;RH9CLK3A',
      statusContent: 'dot',
      hash: 'LA7CA2JJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA7CA2JJ'
    },
    {
      status: 210,
      sku: 'PM0018668',
      core_name: 'Plant',
      sppl_ean: '8720626325722',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5622549',
      rtl_batch_array: '6147850, 5622549',
      name_search: 'SEAYQUEE',
      name: "Sedum acre 'Yellow Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 906,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HJ23LBR3;8PA58N32;CRZHLTH1;9JYA2AAJ;T7RSAB64;AN67L39G;RSWP3221',
      statusContent: 'dot',
      hash: 'N4KDY6X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4KDY6X3'
    },
    {
      status: 210,
      sku: 'PM0004209',
      core_name: 'Plant',
      sppl_ean: '8720349472628',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 5,
      btch_active_retail: '5607004',
      rtl_batch_array: '6176207, 6274828, 6207821, 5607004, 6356243',
      name_search: 'CAMORROW',
      name: 'Carex morrowii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4800,
      chnn_stock_retail: 35104,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEY1DCSC',
      statusContent: 'dot',
      hash: '74V833PN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74V833PN'
    },
    {
      status: 810,
      sku: 'PM0004953',
      core_name: 'Plant',
      sppl_ean: '8720626350632',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364175',
      rtl_batch_array: '6350421, 5364175, 6091357',
      name_search: 'HEMROSE',
      name: "Heuchera 'Midnight Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 35,
      chnn_stock_retail: 824,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WK6WRBNW;BJAZD6BW',
      statusContent: 'dot',
      hash: 'P3A3ZHSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3A3ZHSZ'
    },
    {
      status: 210,
      sku: 'PM0008178',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 8,
      btch_active_retail: '6255979',
      rtl_batch_array:
        '5932555, 6204208, 6350735, 6164343, 6242041, 6385665, 6255979, 6348395',
      name_search: 'PATGCARP',
      name: "Pachysandra terminalis 'Green Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8725,
      chnn_stock_retail: 20956,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R6S2RGVY;RZBP43SX;8R4LVBKN;8JDW2D88',
      statusContent: 'dot',
      hash: 'R1EDCLCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1EDCLCC'
    },
    {
      status: 210,
      sku: 'PM0005223',
      core_name: 'Plant',
      sppl_ean: '8720353090924',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4770515',
      rtl_batch_array: '4770515',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      imageBatch: 'WRN89WXY',
      statusContent: 'dot',
      hash: 'BHAE9ANG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BHAE9ANG'
    },
    {
      status: 210,
      sku: 'PM0072239',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244070',
      rtl_batch_array: '6244070',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: '611V3E3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '611V3E3S'
    },
    {
      status: 210,
      sku: 'PM0072238',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244069',
      rtl_batch_array: '6244069',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: '7R53SSLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R53SSLA'
    },
    {
      status: 210,
      sku: 'PM0072275',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244219',
      rtl_batch_array: '6244219',
      name_search: 'ROFAIRY',
      name: "Rosa (P) 'The Fairy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 1740,
      chnn_stock_retail: 1740,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N3R9LW9X;VX8TLPCV;TYHX8SJD;H8DP8BNS;PCR6L1V9;1L13XG31;GRX3V9J9',
      statusContent: 'dot',
      hash: 'ZTBVHN7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTBVHN7C'
    },
    {
      status: 910,
      sku: 'PM0023023',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFGOLDT',
      name: "Potentilla fruticosa 'Goldteppich'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: 'YPG1YEWB',
      statusContent: 'dot',
      hash: 'P8X8PJSC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P8X8PJSC'
    },
    {
      status: 210,
      sku: 'PM0013499',
      core_name: 'Plant',
      sppl_ean: '8720664808560',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5571368',
      rtl_batch_array: '5571368',
      name_search: 'SECAWING',
      name: 'Senecio candicans Angel Wings',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LAH254P9;WYJ4J6D5;PD9NJNT2;DL27C71T;KTSSA1KH;7S3SH1WP;RNR7HZYX;93HAJY1B;R5C4CSAJ;8CNSHZT9;67EX8JKD;E7N8LT6T;VHYP4H8N',
      imageBatch: 'Z3DC6D2W',
      statusContent: 'dot',
      hash: 'BJTWP15J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJTWP15J'
    },
    {
      status: 210,
      sku: 'PM0072242',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244073',
      rtl_batch_array: '6244073, 6281213',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3050,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'V7RWACY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7RWACY9'
    },
    {
      status: 210,
      sku: 'PM0063351',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286490',
      rtl_batch_array: '6286490',
      name_search: 'ANTROBUS',
      name: "Anemone tomentosa 'Robustissima'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 19468,
      chnn_stock_retail: 19468,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CPJT45S',
      statusContent: 'dot',
      hash: 'JL2L46KT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL2L46KT'
    },
    {
      status: 210,
      sku: 'PM0063365',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238465',
      rtl_batch_array: '6238465',
      name_search: 'LYNUMMUL',
      name: 'Lysimachia nummularia',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1236,
      chnn_stock_retail: 1236,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7L31VN57;BWXX8AX1;2V2VBKW6;DDZBD1WH;DSXJ5AY9',
      statusContent: 'dot',
      hash: 'RL74RVR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RL74RVR4'
    },
    {
      status: 810,
      sku: 'PM0072274',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244211',
      rtl_batch_array: '6244211',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: '5PTSXB9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PTSXB9P'
    },
    {
      status: 210,
      sku: 'PM0063369',
      core_name: 'Plant',
      sppl_ean: '8720664883116',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137779',
      rtl_batch_array: '6137779',
      name_search: 'RHMARRAK',
      name: "Rhodanthemum 'Marrakech'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1458,
      chnn_stock_retail: 1458,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YE9DJWSA;W5C2A16T;A7JDK294;1WDY64VS;6YCW3GJ9;7CL8LZYS;LKSEDY6R',
      statusContent: 'dot',
      hash: 'VVLK1YAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVLK1YAV'
    },
    {
      status: 210,
      sku: 'PM0014880',
      core_name: 'Plant',
      sppl_ean: '8720349408337',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4305051',
      rtl_batch_array: '4305051',
      name_search: 'ACGARGEN',
      name: "Acorus gramineus 'Argenteostriatus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 494,
      chnn_stock_retail: 494,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GKX6968;XGAA2WX6;5WKAAYH5;ZW1XCE2E;Z8CSGN7T',
      imageBatch: 'VHVSPHCA',
      statusContent: 'dot',
      hash: 'YS774537',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS774537'
    },
    {
      status: 210,
      sku: 'PM0072273',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 5,
      btch_active_retail: '6310349',
      rtl_batch_array: '6244210, 6315285, 6304878, 6271631, 6310349',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6200,
      chnn_stock_retail: 16614,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: 'ZKHE415A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKHE415A'
    },
    {
      status: 210,
      sku: 'PM0016811',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 4,
      btch_active_retail: '6195524',
      rtl_batch_array: '6195524, 6164291, 6287679, 6348324',
      name_search: 'GEMACROR',
      name: 'Geranium macrorrhizum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1908,
      chnn_stock_retail: 21829,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S6K8V31Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6K8V31Y'
    },
    {
      status: 210,
      sku: 'PM0063352',
      core_name: 'Plant',
      sppl_ean: '8720664852938',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137759',
      rtl_batch_array: '6137759',
      name_search: 'ASAWHITE',
      name: "Astilbe (A) 'Astary White'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 854,
      chnn_stock_retail: 854,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J75AN429;ZCPNLNYH;NL718N62;S2ZEVAY6',
      statusContent: 'dot',
      hash: 'HR8TZ332',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR8TZ332'
    },
    {
      status: 210,
      sku: 'PM0072255',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244091',
      rtl_batch_array: '6244091',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: '7H7EJ7V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7H7EJ7V7'
    },
    {
      status: 210,
      sku: 'PM0080165',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336161',
      rtl_batch_array: '6336161',
      name_search: 'HEREDGE',
      name: "Hebe 'Red Edge'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6ZZSKXH;7LJTJYEW;TGSTWGGG;S3LJYXZW;8WBY4J4G;L2GGXHV6;G4YSBYTE;16GTYBJ2;LW426RZE;NZRE28PL;JT7CBSY3;NVY8ACEN;WC51ANHS',
      statusContent: 'dot',
      hash: '1EHHCANH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1EHHCANH'
    },
    {
      status: 210,
      sku: 'PM0025558',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6353147',
      rtl_batch_array: '6301782, 5364399, 6353147',
      name_search: 'PEATAURU',
      name: "Persicaria amplexicaulis 'Taurus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2552,
      chnn_stock_retail: 6002,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7KHECJBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KHECJBB'
    },
    {
      status: 210,
      sku: 'PM0015475',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259159',
      rtl_batch_array: '6259159',
      name_search: 'LYPUNCTA',
      name: 'Lysimachia punctata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2339,
      chnn_stock_retail: 2339,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EDGB4Z3N;Z368GRK6;S8C1WBXE;Z1VLJ1JK',
      statusContent: 'dot',
      hash: 'ZXEHKEDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXEHKEDD'
    },
    {
      status: 210,
      sku: 'PM0072256',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244092',
      rtl_batch_array: '6244092, 6281219, 6348819',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2250,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: 'RH63EYRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RH63EYRG'
    },
    {
      status: 210,
      sku: 'PM0022677',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6196756',
      rtl_batch_array: '6196756, 5582432, 6171010, 6301541',
      name_search: 'DIPALBA',
      name: "Digitalis purpurea 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3660,
      chnn_stock_retail: 4548,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PL17BX4L;62ZLAE6L',
      statusContent: 'dot',
      hash: '49RTWGX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49RTWGX5'
    },
    {
      status: 210,
      sku: 'PM0080166',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336162',
      rtl_batch_array: '6336162',
      name_search: 'HEVERNIC',
      name: 'Hebe vernicosa',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XKYKE26Y;RTEKL75S;J82RE5E9;WY4X9P8H;TJX2AKBB;AXDW5PY1;JEW56185',
      statusContent: 'dot',
      hash: 'YECP9X4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YECP9X4H'
    },
    {
      status: 210,
      sku: 'PM0072240',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244071',
      rtl_batch_array: '6244071',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: '77TVT1C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77TVT1C3'
    },
    {
      status: 210,
      sku: 'PM0004335',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6339877',
      rtl_batch_array: '6301785, 6308724, 6339877',
      name_search: 'PEPOLYMO',
      name: 'Persicaria polymorpha',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1194,
      chnn_stock_retail: 2242,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZG8EGRZ;NY1R58Z3;G64A2E7G;G4L67W6Z;VAY1Z2CY',
      statusContent: 'dot',
      hash: 'A4PNE38J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4PNE38J'
    },
    {
      status: 210,
      sku: 'PM0072226',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243705',
      rtl_batch_array: '6243705',
      name_search: 'GEPVAPEL',
      name: "Geranium 'Philippe Vapelle'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1902,
      chnn_stock_retail: 1902,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PWY964PZ;A7V9VRAW;1RXNKBVH;6W254PKX;65WE7SZA;PSJ5RL55;EBN1AW6Y;4HTAHVT3;7KB1RBL5;Y1BZBL8P',
      statusContent: 'dot',
      hash: '5GZ72H7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GZ72H7W'
    },
    {
      status: 210,
      sku: 'PM0072224',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274524',
      rtl_batch_array: '6274212, 6274524',
      name_search: 'ANHSPLEN',
      name: "Anemone hupehensis 'Splendens'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6855,
      chnn_stock_retail: 7355,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RY283ZVB;N2HHZHBE;AHEHX9D8;8XBBR6H7;E98ZGW45;SZE1X88E;AC7ADPGT;7EZR8V12;Y8KP4KNK;GSJ4GVYS',
      statusContent: 'dot',
      hash: 'PKSDRE5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKSDRE5E'
    },
    {
      status: 210,
      sku: 'PM0029686',
      core_name: 'Plant',
      sppl_ean: '8720664865976',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6132949',
      rtl_batch_array: '6132949, 6243055',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '150175C3',
      rng_range_identifier: 'H150175C3',
      rng_range_description: 'H150 cm 175 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 2575,
      sppl_prcp: 4.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'N6TNYSL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6TNYSL2'
    },
    {
      status: 210,
      sku: 'PM0063366',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274543',
      rtl_batch_array: '6153778, 6274543',
      name_search: 'NEWLOW',
      name: "Nepeta 'Walker's Low'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 8476,
      chnn_stock_retail: 9476,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '83KLR23B;2X11KXGZ;GZBEPLNA;ETAZAYTC;43X8CB9C;19HZ61AP;ZRJB5GRC;C63HC55X;5R6S3ZZ5;RR7SY6N1;RY99AV1V;HAKLLLPJ;951Y696D;SJTC13B9;TYPYG9Y6;84XT9D1J;5945D6RR',
      statusContent: 'dot',
      hash: 'ESEW3H5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESEW3H5Z'
    },
    {
      status: 210,
      sku: 'PM0080161',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336157',
      rtl_batch_array: '6336157',
      name_search: 'HEPIMELE',
      name: 'Hebe pimeleoides',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBGCE1GB;T317JGSA;JNYZS1HK;PSTACL85;LWV1PEGG;YW85LL3K;XJG6ZTH5;JNE5DE7B;BKHKPWZK;GDBB2BV8;J7884SCS;H824C7XA;E1GNXSWJ;89BX6RRX;1X5ZYRLJ;RHA6XL49',
      statusContent: 'dot',
      hash: 'NLN9PEJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLN9PEJ4'
    },
    {
      status: 210,
      sku: 'PM0005027',
      core_name: 'Plant',
      sppl_ean: '8720664659438',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5628393',
      rtl_batch_array: '5628393',
      name_search: 'BUSANNIV',
      name: 'Buddleja Silver Anniversery',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1785,
      chnn_stock_retail: 1785,
      sppl_prcp: 2.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KY4BN4KD;E6PR9R5D;Y52G45ZA;KRBRGA52',
      statusContent: 'dot',
      hash: 'BCWSPTCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCWSPTCP'
    },
    {
      status: 210,
      sku: 'PM0028278',
      core_name: 'Plant',
      sppl_ean: '8720353097060',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054565',
      rtl_batch_array: '6054565',
      name_search: 'DOMEADIA',
      name: 'Dodecatheon meadia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1197,
      chnn_stock_retail: 1197,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R3J4PP1H',
      statusContent: 'dot',
      hash: '6S28ET4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6S28ET4P'
    },
    {
      status: 910,
      sku: 'PM0050912',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUMROCOC',
      name: "Buxus microphylla 'Rococo'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'X871K5AC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X871K5AC'
    },
    {
      status: 210,
      sku: 'PM0080162',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336158',
      rtl_batch_array: '6336158',
      name_search: 'HEPQUICK',
      name: "Hebe pimeleoides 'Quicksilver'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WT8X5NST;YZC3WP2E;ZT1K3RK4;JRWZTKR7;4ZZ4N8Z5;BNA5ATB5;HJJ7W757;KRSK22AB;ZVS4AZXW;ZSY9ALXX;WV65YBJC;ESD4NNY8;GE6ZB4JB',
      statusContent: 'dot',
      hash: '16DN7X4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16DN7X4G'
    },
    {
      status: 210,
      sku: 'PM0080163',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336159',
      rtl_batch_array: '6336159',
      name_search: 'HEPSUTHE',
      name: "Hebe pinguifolia 'Sutherlandii'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G956WPEA;ZVZ622DX;YZAJG146;7YR1WZBN;1JZLSCZV;59YNEXAE;RP8125WN',
      statusContent: 'dot',
      hash: 'KGXCN4ZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGXCN4ZP'
    },
    {
      status: 210,
      sku: 'PM0012911',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242138',
      rtl_batch_array: '6242138, 6321898',
      name_search: 'CAMGOLDB',
      name: "Carex morrowii 'Goldband'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1178,
      chnn_stock_retail: 1370,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJRKPTA9;9EH6KZG6;CA5RYEST;1Y9PS1RB;2YP88B29',
      statusContent: 'dot',
      hash: 'TYTARRX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYTARRX6'
    },
    {
      status: 210,
      sku: 'PM0080164',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336160',
      rtl_batch_array: '6336160',
      name_search: 'HEPROSTR',
      name: "Hebe 'Prostrata'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8CWV79S;XKXE6JWA;8P3YJ7Y1;ECLW8VR2;CGY6GJAK;J9LRLAG2;YVZBZTTD',
      statusContent: 'dot',
      hash: 'X726HJ9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X726HJ9S'
    },
    {
      status: 210,
      sku: 'PM0080167',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336163',
      rtl_batch_array: '6336163',
      name_search: 'HYACBUBB',
      name: 'Hydrangea arborescens Candybelle Bubblegum',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 810,
      chnn_stock_retail: 810,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AVNXBZJT;RGNAGNYD;W3TT1XJX;J3R56BY3;DX2G7PHG;TE3B5K9E;ZRB7K7E3;9ZN7XNVL;XZX78ZPG;B73CWL2A',
      statusContent: 'dot',
      hash: 'LK7T5GRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LK7T5GRC'
    },
    {
      status: 210,
      sku: 'PM0031952',
      core_name: 'Plant',
      sppl_ean: '8720353081946',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5810962',
      rtl_batch_array: '5810962',
      name_search: 'ROSPRETT',
      name: 'Rosa (H) Sweet Pretty',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 2.656,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ZC4J6NX;5HYPYN98;XWESE979;56C2YW5L',
      statusContent: 'dot',
      hash: 'P27JJLRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P27JJLRV'
    },
    {
      status: 210,
      sku: 'PM0080168',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336165',
      rtl_batch_array: '6336165',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: 'HL38CAHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HL38CAHT'
    },
    {
      status: 210,
      sku: 'PM0063353',
      core_name: 'Plant',
      sppl_ean: '8720664853775',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137761',
      rtl_batch_array: '6137761',
      name_search: 'BRMEDIA',
      name: 'Briza media',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2621,
      chnn_stock_retail: 2621,
      sppl_order_minimum: 3,
      sppl_prcp: 1.774,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V71BEV4S;RHLKG923;5B396BZK;C2L1NTHD',
      statusContent: 'dot',
      hash: '72B31G17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72B31G17'
    },
    {
      status: 210,
      sku: 'PM0011059',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308466',
      rtl_batch_array: '5495250, 6308466, 6339793, 6340059',
      name_search: 'COVMOONB',
      name: "Coreopsis verticillata 'Moonbeam'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 5074,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KRVEBRG4;RY4RYHPK;2T2Z98KY;HDSTL4WP;77VR7AD6;939C72DP;XZB2LXGS',
      statusContent: 'dot',
      hash: 'BJHZ2EXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJHZ2EXT'
    },
    {
      status: 210,
      sku: 'PM0072258',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244094',
      rtl_batch_array: '6244094',
      name_search: 'HYPSFRAI',
      name: 'Hydrangea paniculata Sundae Fraise',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JE1RX5H2;TVJKSH5R;LBKPNDD1;ALZHHNXR;YCCVESHH;142DDV9G;87AGDKC1;NH16J9WA;1YJWB1NJ;KE8BAA3N;9HBXW791;HRNKJRGK;HNWVSRL9;EA7XZXYC;AH96E5GW;1X2TCPAL;8R2Z9NZA;PRLB2BNY',
      statusContent: 'dot',
      hash: 'K3RA85GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3RA85GG'
    },
    {
      status: 210,
      sku: 'PM0072228',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243708',
      rtl_batch_array: '6243708',
      name_search: 'GEENDRES',
      name: 'Geranium endressii',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1104,
      chnn_stock_retail: 1104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LX7S9YGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LX7S9YGG'
    },
    {
      status: 210,
      sku: 'PM0072229',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243709',
      rtl_batch_array: '6243709',
      name_search: 'GEHBBLUE',
      name: "Geranium himalayense 'Baby Blue'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3219,
      chnn_stock_retail: 3219,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K2DR3JAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2DR3JAN'
    },
    {
      status: 210,
      sku: 'PM0010540',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220209',
      rtl_batch_array: '6220209, 5741521, 6349743',
      name_search: 'ALRNIGRA',
      name: "Alcea rosea 'Nigra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 243,
      chnn_stock_retail: 813,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AEKZNZ45',
      statusContent: 'dot',
      hash: '21WD71BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21WD71BV'
    },
    {
      status: 210,
      sku: 'PM0072260',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244097',
      rtl_batch_array: '6244097',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'CN6KD3KW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CN6KD3KW'
    },
    {
      status: 210,
      sku: 'PM0001562',
      core_name: 'Plant',
      sppl_ean: '8720349423163',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2772370',
      rtl_batch_array: '2772370',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_prcp: 4.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'LP8YAJ8R',
      statusContent: 'dot',
      hash: '4LERJ5XT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LERJ5XT'
    },
    {
      status: 210,
      sku: 'PM0005229',
      core_name: 'Plant',
      sppl_ean: '8720349483778',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837403',
      rtl_batch_array: '5837403',
      name_search: 'CACBPERF',
      name: "Carex comans 'Bronze Perfection'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TY41Z714',
      statusContent: 'dot',
      hash: 'WW621T9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW621T9B'
    },
    {
      status: 210,
      sku: 'PM0005324',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 8,
      btch_active_retail: '6315296',
      rtl_batch_array:
        '6084690, 6231155, 5303729, 6315296, 6301942, 6353328, 6291737, 6287691',
      name_search: 'SANOSTFR',
      name: "Salvia nemorosa 'Ostfriesland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 558,
      chnn_stock_retail: 44169,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8CP8CVY;7HJDG7W5;WN5NK6H4;6S8768BL;738ADD1T;2CYAKL22;H44PR129;9BP6H4BZ;TGNL65XV;ZPXLDZW3;HZP275Y2;PEWXGZHY;7JKJ6VC7;9T4E9GVG;WPBS61E8;BD635VT7;TWBHZ4SY;RHYB8ZAJ',
      statusContent: 'dot',
      hash: '41G816DT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41G816DT'
    },
    {
      status: 210,
      sku: 'PM0026564',
      core_name: 'Plant',
      sppl_ean: '8720349478897',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '4866173',
      rtl_batch_array: '4866173',
      name_search: 'ROSOMMER',
      name: 'Rosa (T) Sommerabend',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 629,
      chnn_stock_retail: 629,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '56ZK7TBA;AW9S1VA5;CHXLV3XN;T13DKJA9',
      statusContent: 'dot',
      hash: 'T1W1A4AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1W1A4AE'
    },
    {
      status: 210,
      sku: 'PM0072230',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278462',
      rtl_batch_array: '6278462',
      name_search: 'HELPINK',
      name: "Helianthemum 'Lawrenson's Pink'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EC3V6RH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EC3V6RH1'
    },
    {
      status: 210,
      sku: 'PM0080169',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336166',
      rtl_batch_array: '6336166',
      name_search: 'HYMBLAUM',
      name: "Hydrangea macrophylla 'Blaumeise'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GZZX7N8;ESE927KP;KYN2CXA5;AT3DLTTN;B7H86E48;4SSHW2B7;8JBJAJDX;NLW6SNC5;YWT46E3S;HK21GA6R;CNK7H6AX;GGNAZBCJ;HDNLVN2C',
      statusContent: 'dot',
      hash: 'SHETGAPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHETGAPE'
    },
    {
      status: 210,
      sku: 'PM0080170',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336167',
      rtl_batch_array: '6336167',
      name_search: 'HYMBODEN',
      name: "Hydrangea macrophylla 'Bodensee'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB81K7SZ;ZRDAT7B4;9TL5SAS2;CAKYVNEH;T2EHAA8J;6RT98EYD;KHD4XTSL;G454XKTX',
      statusContent: 'dot',
      hash: '4D88HTZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4D88HTZS'
    },
    {
      status: 210,
      sku: 'PM0080171',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336169',
      rtl_batch_array: '6336169',
      name_search: 'HYMLIBEL',
      name: "Hydrangea macrophylla 'Libelle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D32EL74L;ZDCX7HCW;PPGVSD31;87SBT7K6;WEGAXENS;6BCBCPWW;2TZ4DEE7;82K99P7A;VCXPTL8T;AY6YND7C;55V2VJX3;5224VEL6;477G57W9;2R1PPD42;5NDCBTSG;LE6GNY12;PTHA8NJV',
      statusContent: 'dot',
      hash: '5Y4H32AG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Y4H32AG'
    },
    {
      status: 210,
      sku: 'PM0030150',
      core_name: 'Plant',
      sppl_ean: '8720664659377',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '5628429',
      rtl_batch_array: '6282357, 5628429',
      name_search: 'BUPMCHIP',
      name: "Buddleja 'Pink Micro Chip'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2967,
      chnn_stock_retail: 3167,
      sppl_prcp: 3.226,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P2DVZ1H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2DVZ1H5'
    },
    {
      status: 210,
      sku: 'PM0072231',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243711',
      rtl_batch_array: '6243711',
      name_search: 'IREDPBPI',
      name: 'Iris ensata Dinner Plate Blueberry Pie',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 566,
      chnn_stock_retail: 566,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D6K7SHTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6K7SHTV'
    },
    {
      status: 210,
      sku: 'PM0080172',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336170',
      rtl_batch_array: '6336170',
      name_search: 'HYMMASJ',
      name: "Hydrangea m. 'Masja'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AY5GH6N2;GJVT5SXH;8DPRVEC6;E7CB2ABV',
      statusContent: 'dot',
      hash: 'XPYBZHD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPYBZHD4'
    },
    {
      status: 210,
      sku: 'PM0080173',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336171',
      rtl_batch_array: '6336171',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'XDCDX9HY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDCDX9HY'
    },
    {
      status: 210,
      sku: 'PM0072259',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244096',
      rtl_batch_array: '6244096',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: '4TYR9E9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TYR9E9J'
    },
    {
      status: 210,
      sku: 'PM0080174',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336172',
      rtl_batch_array: '6336172',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'E3EA5HLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3EA5HLW'
    },
    {
      status: 210,
      sku: 'PM0080175',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336173',
      rtl_batch_array: '6336173',
      name_search: 'HYMRSTEI',
      name: "Hydrangea macrophylla 'Renate Steiniger'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HYNJ1PA;25ADCYW2;PKL78Z5G;7YGK7B2C;1WNG69PG;CG9D67Z7',
      statusContent: 'dot',
      hash: 'KDVJGCBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDVJGCBH'
    },
    {
      status: 210,
      sku: 'PM0008313',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348344',
      rtl_batch_array: '6350547, 5401606, 6348344',
      name_search: 'HYOROSEU',
      name: "Hyssopus officinalis 'Roseus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJ29ET53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJ29ET53'
    },
    {
      status: 810,
      sku: 'PM0072233',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281069',
      rtl_batch_array: '6281069',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.502,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'SVETAW68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVETAW68'
    },
    {
      status: 210,
      sku: 'PM0080176',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336174',
      rtl_batch_array: '6336174',
      name_search: 'HYMSTHER',
      name: "Hydrangea macrophylla 'Soeur Th\u00e9r\u00e8se'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P8NC46W6',
      statusContent: 'dot',
      hash: '9VEYGTWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VEYGTWY'
    },
    {
      status: 210,
      sku: 'PM0063345',
      core_name: 'Plant',
      sppl_ean: '8720664868854',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137536',
      rtl_batch_array: '6137536',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8201,
      chnn_stock_retail: 8201,
      sppl_order_minimum: 3,
      sppl_prcp: 1.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'H35LSJL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H35LSJL2'
    },
    {
      status: 210,
      sku: 'PM0080177',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336175',
      rtl_batch_array: '6336175',
      name_search: 'HYMLBLUE',
      name: "Hydrangea m. 'Little Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9999,
      chnn_stock_retail: 9999,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HX6S5T5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX6S5T5J'
    },
    {
      status: 210,
      sku: 'PM0080178',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336177',
      rtl_batch_array: '6336177',
      name_search: 'HYMLPINK',
      name: "Hydrangea m. 'Little Pink'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9999,
      chnn_stock_retail: 9999,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8HZWSR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8HZWSR2'
    },
    {
      status: 210,
      sku: 'PM0014640',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348358',
      rtl_batch_array: '6054798, 6193554, 6348358',
      name_search: 'LOQVICTO',
      name: "Lobelia 'Queen Victoria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 246,
      chnn_stock_retail: 2302,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RA5AY2XN;GRB37WC6;J5PR3C23;JZY4XBH8;4TC9X8DD;21BB76YB',
      statusContent: 'dot',
      hash: 'V55ETWVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V55ETWVN'
    },
    {
      status: 210,
      sku: 'PM0080179',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336181',
      rtl_batch_array: '6336181',
      name_search: 'HYMLPURP',
      name: "Hydrangea m. 'Little Purple'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9999,
      chnn_stock_retail: 9999,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '74JYTJ67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74JYTJ67'
    },
    {
      status: 210,
      sku: 'PM0080180',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336182',
      rtl_batch_array: '6336182',
      name_search: 'HYDMLWHI',
      name: "Hydrangea m. 'Little White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9999,
      chnn_stock_retail: 9999,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D15YKHY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D15YKHY4'
    },
    {
      status: 210,
      sku: 'PM0015146',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6258874',
      rtl_batch_array: '5439478, 6258874',
      name_search: 'ROIMEIDI',
      name: 'Rosa (H) Ice Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 550,
      chnn_stock_retail: 6485,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LRWDESBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRWDESBS'
    },
    {
      status: 210,
      sku: 'PM0074714',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293899',
      rtl_batch_array: '6293899',
      name_search: 'BUDRORCH',
      name: 'Buddleja d. Rocketstar Orchid',
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '28E9SP1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28E9SP1L'
    },
    {
      status: 210,
      sku: 'PM0063359',
      core_name: 'Plant',
      sppl_ean: '8720664863750',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137768',
      rtl_batch_array: '6137768',
      name_search: 'FRVESCA',
      name: 'Fragaria vesca',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3348,
      chnn_stock_retail: 3348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.774,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NG94EY2C;K2DCSSXA;CA3PN5XL;RWXV4VCT;LYWLJEPK;DCCYE7D9;8WNYJYXW;CGCJBT4P;TTXGBD7X;TW2AYA2G;8X3D9J28;KGPVVN4T',
      statusContent: 'dot',
      hash: 'JBC77T7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBC77T7H'
    },
    {
      status: 210,
      sku: 'PM0072236',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244067',
      rtl_batch_array: '6244067',
      name_search: 'HYPDROUG',
      name: 'Hydrangea paniculata Diamand Rouge',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '667R19GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '667R19GW'
    },
    {
      status: 210,
      sku: 'PM0072237',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244068',
      rtl_batch_array: '6244068, 6281211',
      name_search: 'HYPDROUG',
      name: 'Hydrangea paniculata Diamand Rouge',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 550,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NBRNGJDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBRNGJDP'
    },
    {
      status: 210,
      sku: 'PM0072241',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244072',
      rtl_batch_array: '6244072',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 2988,
      chnn_stock_retail: 2988,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'VYXCL7SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYXCL7SE'
    },
    {
      status: 210,
      sku: 'PM0014457',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301087',
      rtl_batch_array: '6301087, 6161833',
      name_search: 'LYVDPURP',
      name: "Lythrum virgatum 'Dropmore Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1339,
      chnn_stock_retail: 1493,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7S5J3JE;AV74VDYJ;JJWZVB7B;DXN4R7AD',
      statusContent: 'dot',
      hash: '66ZADK23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66ZADK23'
    },
    {
      status: 210,
      sku: 'PM0002654',
      core_name: 'Plant',
      sppl_ean: '8720664855366',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084845',
      rtl_batch_array: '6084845, 6339772',
      name_search: 'CACAMIST',
      name: "Carex comans 'Amazon Mist'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3141,
      chnn_stock_retail: 3825,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RAR8K7EY;LV55K7K1;EETBVAY4',
      statusContent: 'dot',
      hash: 'K98XP57C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K98XP57C'
    },
    {
      status: 210,
      sku: 'PM0072245',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244080',
      rtl_batch_array: '6244080',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: 'SEA4V2BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEA4V2BV'
    },
    {
      status: 210,
      sku: 'PM0072246',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244082',
      rtl_batch_array: '6244082',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: '294EH8BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '294EH8BK'
    },
    {
      status: 210,
      sku: 'PM0072247',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244083',
      rtl_batch_array: '6244083',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8B9EE1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8B9EE1H'
    },
    {
      status: 210,
      sku: 'PM0072248',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244084',
      rtl_batch_array: '6244084',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3X26X6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3X26X6C'
    },
    {
      status: 210,
      sku: 'PM0072249',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244085',
      rtl_batch_array: '6244085',
      name_search: 'HYPCANDL',
      name: 'Hydrangea paniculata Candlelight',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W1G9CX1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1G9CX1A'
    },
    {
      status: 210,
      sku: 'PM0072250',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244086',
      rtl_batch_array: '6244086',
      name_search: 'HYPCANDL',
      name: 'Hydrangea paniculata Candlelight',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '498HWYSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '498HWYSR'
    },
    {
      status: 210,
      sku: 'PM0072262',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244102',
      rtl_batch_array: '6244102',
      name_search: 'VIPRAGEN',
      name: "Viburnum 'Pragense'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YCWLP74;4P3G8PK3',
      statusContent: 'dot',
      hash: 'VKG9ZXTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKG9ZXTV'
    },
    {
      status: 210,
      sku: 'PM0072251',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244087',
      rtl_batch_array: '6244087',
      name_search: 'HYPCONFE',
      name: 'Hydrangea paniculata Confetti',
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VGE9DRVX;RT8DZZ6T',
      statusContent: 'dot',
      hash: 'S1PTK9R9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1PTK9R9'
    },
    {
      status: 210,
      sku: 'PM0063367',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255650',
      rtl_batch_array: '6255650',
      name_search: 'PHSSFANT',
      name: 'Phlox (P) Sweet Summer Fantasy',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6515,
      chnn_stock_retail: 6515,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2L7A7X7T;4LA19SXW;Z1VG7L8D',
      statusContent: 'dot',
      hash: 'Z1N6A7YB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1N6A7YB'
    },
    {
      status: 210,
      sku: 'PM0072252',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244088',
      rtl_batch_array: '6244088',
      name_search: 'HYPEHARR',
      name: 'Hydrangea paniculata Early Harry',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '18B939TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18B939TN'
    },
    {
      status: 210,
      sku: 'PM0072253',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244089',
      rtl_batch_array: '6244089',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 1960,
      chnn_stock_retail: 1960,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: 'RRWXYW8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRWXYW8Z'
    },
    {
      status: 210,
      sku: 'PM0072264',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244106',
      rtl_batch_array: '6244106',
      name_search: 'VIBBURKW',
      name: "Viburnum burkwoodii 'Burkwood'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RTN4JXVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTN4JXVA'
    },
    {
      status: 210,
      sku: 'PM0015777',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 3,
      btch_active_retail: '6274767',
      rtl_batch_array: '6228326, 6267714, 6274767',
      name_search: 'LIJROTUN',
      name: "Ligustrum japonicum 'Rotundifolium'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 170,
      chnn_stock_retail: 1848,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4Z838WZC;6S3YA3XV',
      statusContent: 'dot',
      hash: 'VL31WJJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL31WJJC'
    },
    {
      status: 210,
      sku: 'PM0072254',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244090',
      rtl_batch_array: '6244090',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: 'R1TY2YR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1TY2YR7'
    },
    {
      status: 210,
      sku: 'PM0025957',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6315043',
      rtl_batch_array: '6230484, 6221388, 6315043',
      name_search: 'ASJENNY',
      name: "Aster (D) 'Jenny'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1615,
      chnn_stock_retail: 1771,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ES6TNHE1;WWWEGX5W;A1LTXPKY;CXE9R7AS;XL9655W5;HXYDNX46;Z3825STW;XX8YSN71;HY1LCL2B',
      statusContent: 'dot',
      hash: '78BDN4AN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78BDN4AN'
    },
    {
      status: 210,
      sku: 'PM0072265',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244107',
      rtl_batch_array: '6244107',
      name_search: 'VICARLCE',
      name: 'Viburnum carlcephalum',
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLC4D9D5;1PE21WKK;PKZYVWCX;LCB99K7C;A4PWJPX1;HV2P3ZJH',
      statusContent: 'dot',
      hash: 'W9C4LN4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9C4LN4E'
    },
    {
      status: 210,
      sku: 'PM0028283',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6386179',
      rtl_batch_array: '5495477, 6217061, 6386179',
      name_search: 'PUTFOUNT',
      name: "Pulmonaria 'Trevi Fountain'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 687,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4XY2GY98;K7172YE4;SPGXG73L;HKHVLYXG;ZBZY6HPR',
      statusContent: 'dot',
      hash: 'Z6E112V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6E112V1'
    },
    {
      status: 210,
      sku: 'PM0072266',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244108',
      rtl_batch_array: '6244108',
      name_search: 'VICJUDDI',
      name: "Viburnum carlesii 'Juddii'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWVZR3LP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWVZR3LP'
    },
    {
      status: 210,
      sku: 'PM0072268',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244113',
      rtl_batch_array: '6244113',
      name_search: 'VIPPOPCO',
      name: "Viburnum plicatum 'Popcorn'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7WBSAKP',
      statusContent: 'dot',
      hash: 'CWX2HC4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWX2HC4B'
    },
    {
      status: 210,
      sku: 'PM0072270',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244115',
      rtl_batch_array: '6244115',
      name_search: 'VIPSKEVE',
      name: "Viburnum plicatum 'St Keverne'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4YYZBZ3',
      statusContent: 'dot',
      hash: '6JW5K6TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JW5K6TZ'
    },
    {
      status: 210,
      sku: 'PM0072276',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244223',
      rtl_batch_array: '6244223',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_prcp: 10.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: '44ARAT3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44ARAT3T'
    },
    {
      status: 210,
      sku: 'PM0072243',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244075',
      rtl_batch_array: '6244075',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'R7X2E26Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7X2E26Z'
    },
    {
      status: 210,
      sku: 'PM0072244',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244079',
      rtl_batch_array: '6244079, 6348821',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2500,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'X236A1JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X236A1JD'
    },
    {
      status: 210,
      sku: 'PM0072257',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244093',
      rtl_batch_array: '6244093, 6385446',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 750,
      chnn_stock_retail: 847,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: '7N6RBLSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N6RBLSS'
    },
    {
      status: 210,
      sku: 'PM0072272',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244209',
      rtl_batch_array: '6244209',
      name_search: 'POFMANCH',
      name: "Potentilla fruticosa 'Manchu'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1060,
      chnn_stock_retail: 1060,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPC5YJJT;KV6BZHDV;S32VA5X6;C2TRJPLN',
      statusContent: 'dot',
      hash: '7VLTHW3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VLTHW3V'
    },
    {
      status: 210,
      sku: 'PM0072261',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244101',
      rtl_batch_array: '6244101',
      name_search: 'VIESKIMO',
      name: "Viburnum 'Eskimo'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5BBGC5WW',
      statusContent: 'dot',
      hash: 'E1RR9L5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1RR9L5Y'
    },
    {
      status: 210,
      sku: 'PM0072263',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244105',
      rtl_batch_array: '6244105',
      name_search: 'VIBARUSS',
      name: "Viburnum burkwoodii 'Anne Russell'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AH9XESB;KLENXA51',
      statusContent: 'dot',
      hash: 'AP9E7EHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AP9E7EHW'
    },
    {
      status: 210,
      sku: 'PM0072269',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244114',
      rtl_batch_array: '6244114',
      name_search: 'VIPROSAC',
      name: "Viburnum plicatum 'Rosace'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KG7K1HW1',
      statusContent: 'dot',
      hash: '6NCDRKE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NCDRKE7'
    },
    {
      status: 210,
      sku: 'PM0072271',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244116',
      rtl_batch_array: '6244116',
      name_search: 'VIPWATAN',
      name: "Viburnum plicatum 'Watanabe'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9LXJV3K;827Z6E42;AS1PNED1;NRRZK322;DNGB43HJ;ZE1WC5B3',
      statusContent: 'dot',
      hash: '15X8WJV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15X8WJV2'
    },
    {
      status: 210,
      sku: 'PM0072267',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244111',
      rtl_batch_array: '6244111',
      name_search: 'VIPGRAND',
      name: "Viburnum plicatum 'Grandiflorum'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DXVEBA53;BEXB19ED',
      statusContent: 'dot',
      hash: '2ASG2AS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ASG2AS9'
    },
    {
      status: 210,
      sku: 'PM0063343',
      core_name: 'Plant',
      sppl_ean: '8720664868786',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137534',
      rtl_batch_array: '6137534',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7750,
      chnn_stock_retail: 7750,
      sppl_order_minimum: 3,
      sppl_prcp: 1.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: 'GYNCHC76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYNCHC76'
    },
    {
      status: 210,
      sku: 'PM0063361',
      core_name: 'Plant',
      sppl_ean: '8720664864122',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137770',
      rtl_batch_array: '6137770',
      name_search: 'GALRJANE',
      name: 'Gaura lindheimeri Rosy Jane',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 782,
      chnn_stock_retail: 782,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YG81187X',
      statusContent: 'dot',
      hash: 'J833TPAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J833TPAC'
    },
    {
      status: 210,
      sku: 'PM0072227',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243707',
      rtl_batch_array: '6243707',
      name_search: 'GEEWPINK',
      name: "Geranium endressii 'Wargrave Pink'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1553,
      chnn_stock_retail: 1553,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TE3GS59A',
      statusContent: 'dot',
      hash: 'HPYXRR2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPYXRR2B'
    },
    {
      status: 210,
      sku: 'PM0015505',
      core_name: 'Plant',
      sppl_ean: '8720353016030',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439473',
      rtl_batch_array: '5439473',
      name_search: 'ROSFMEID',
      name: 'Rosa (H) Fuchsia Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 615,
      chnn_stock_retail: 615,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X6T37HYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6T37HYZ'
    },
    {
      status: 210,
      sku: 'PM0072286',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6244241',
      rtl_batch_array: '6244241, 6304906, 6164878, 6310409',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8168,
      chnn_stock_retail: 26423,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'H6CHGXHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6CHGXHA'
    },
    {
      status: 210,
      sku: 'PM0027920',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348973',
      rtl_batch_array: '6350089, 6352631, 6348973',
      name_search: 'ECPSUNDO',
      name: "Echinacea purpurea 'Sundown'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1572,
      chnn_stock_retail: 2449,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BE5PGECZ',
      statusContent: 'dot',
      hash: 'EJ843B4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ843B4G'
    },
    {
      status: 910,
      sku: 'PM0009615',
      core_name: 'Plant',
      sppl_ean: '8720664697010',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 2,
      btch_active_retail: '5492634',
      rtl_batch_array: '5492634, 5861567',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: '025030C10',
      rng_range_identifier: 'H025030C10',
      rng_range_description: 'H25 cm 30 cm C10',
      sppl_stock_available: 3,
      chnn_stock_retail: 1459,
      sppl_prcp: 6.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'KV5GY645',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KV5GY645'
    },
    {
      status: 210,
      sku: 'PM0072297',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6284512',
      rtl_batch_array: '6284512, 6305787, 6234563, 6278280',
      name_search: 'ANHHJOBE',
      name: "Anemone hybrida 'Honorine Jobert'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3833,
      chnn_stock_retail: 10014,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2J5SXH1;YKNJ7TZ1;4ETXSNCC;WC36SR2J;WVEGZW26;AXVRKPX2;8BZR9YJB;RSCKAH9Z;YBKSLZRK;8DH2V7SA;X6H88CAY;BD76SGZT;X95H1D2Y;DE3EEBSW;S9DBWLDV',
      statusContent: 'dot',
      hash: 'AELRBJVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AELRBJVD'
    },
    {
      status: 210,
      sku: 'PM0072348',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244726',
      rtl_batch_array: '6244726',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: '49J8WERD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49J8WERD'
    },
    {
      status: 210,
      sku: 'PM0063374',
      core_name: 'Plant',
      sppl_ean: '8720664889200',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137785',
      rtl_batch_array: '6137785',
      name_search: 'VEBONARI',
      name: 'Verbena bonariensis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1981,
      chnn_stock_retail: 1981,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKY7SBA7;ABH792B4;8CJEW9TL;G44VBSBK;YB7W24DG;BJB4127V;AWB6PXWB;9HZJHLLE;255D2W23;W7L7YBJS;4NNCLH29;AYDY3PE7;V61K9VK7;DHK64TJ5;CS74R4R9;E2249VDY;RASG24N5;2DBH57A1;ZHYX6VGE;WTE4LSZZ;9RE66TL3;VCHXW3DN;VKPTAXLL',
      statusContent: 'dot',
      hash: 'PAV47DJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAV47DJX'
    },
    {
      status: 210,
      sku: 'PM0072344',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356292',
      rtl_batch_array: '6356292',
      name_search: 'PRPNUCIP',
      name: 'Prunus persica nucipersica',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 15.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DSSR12BX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSSR12BX'
    },
    {
      status: 210,
      sku: 'PM0072321',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244699',
      rtl_batch_array: '6244699',
      name_search: 'PRPERSIC',
      name: 'Prunus persica',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R3GNKWEK;RTA7ES48;XJPR31K9;BLVYZZXH;8Y4JC22W;S4J8KPP2;7DD7E9L2;ZAA4HR3S;YBGH4ANC;4RRP7243;KXH5BVT7;ADY7VB22;D8CDAWS3;V6GB23ZS;P1VDL1BY',
      statusContent: 'dot',
      hash: 'CDP2AXZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDP2AXZZ'
    },
    {
      status: 210,
      sku: 'PM0019781',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353521',
      rtl_batch_array: '6353521',
      name_search: 'TRHTADBA',
      name: "Tricyrtis hirta 'Taiwan Adbane'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HSTSB9L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSTSB9L4'
    },
    {
      status: 210,
      sku: 'PM0002984',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6258891',
      rtl_batch_array: '5439489, 6258891',
      name_search: 'ROMMEIDI',
      name: 'Rosa (H) Magic Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1600,
      chnn_stock_retail: 7569,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZTB5P5K4;GDSDJ2TG;5XRT9398',
      statusContent: 'dot',
      hash: 'SVCZPW7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVCZPW7E'
    },
    {
      status: 210,
      sku: 'PM0072280',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244235',
      rtl_batch_array: '6244235, 6278115',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3259,
      chnn_stock_retail: 6630,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: '4CTVK1R2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CTVK1R2'
    },
    {
      status: 210,
      sku: 'PM0014323',
      core_name: 'Plant',
      sppl_ean: '8720664669024',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439506',
      rtl_batch_array: '5439506',
      name_search: 'ROSSCHNE',
      name: 'Rosa (H) Schneek\u00f6nigin',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YEG9RN74;DRTRX25S',
      statusContent: 'dot',
      hash: 'THV146T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THV146T9'
    },
    {
      status: 210,
      sku: 'PM0030961',
      core_name: 'Plant',
      sppl_ean: '8720664806764',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439515',
      rtl_batch_array: '5439515',
      name_search: 'ROWDRIFT',
      name: 'Rosa hybrid (H) White Drift \u00ae',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 655,
      chnn_stock_retail: 655,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4S555GVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S555GVJ'
    },
    {
      status: 210,
      sku: 'PM0015585',
      core_name: 'Plant',
      sppl_ean: '8720664682085',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495577',
      rtl_batch_array: '5495577',
      name_search: 'BUDPPANT',
      name: 'Buddleja d. Pink Panther',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LNXJSHSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNXJSHSE'
    },
    {
      status: 210,
      sku: 'PM0072331',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244709',
      rtl_batch_array: '6244709',
      name_search: 'PRPERSIC',
      name: 'Prunus persica',
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R3GNKWEK;RTA7ES48;XJPR31K9;BLVYZZXH;8Y4JC22W;S4J8KPP2;7DD7E9L2;ZAA4HR3S;YBGH4ANC;4RRP7243;KXH5BVT7;ADY7VB22;D8CDAWS3;V6GB23ZS;P1VDL1BY',
      statusContent: 'dot',
      hash: '91R4VD7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '91R4VD7W'
    },
    {
      status: 810,
      sku: 'PM0018884',
      core_name: 'Plant',
      sppl_ean: '8720626380561',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496367',
      rtl_batch_array: '5496367',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 67.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'D51ST4X7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D51ST4X7'
    },
    {
      status: 210,
      sku: 'PM0009581',
      core_name: 'Plant',
      sppl_ean: '8720353087320',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6014532',
      rtl_batch_array: '6349052, 6301427, 6014532',
      name_search: 'ASDBCHAT',
      name: "Aster divaricatus 'Beth Chatto'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1953,
      chnn_stock_retail: 2983,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBK28ECA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBK28ECA'
    },
    {
      status: 810,
      sku: 'PM0028584',
      core_name: 'Plant',
      sppl_ean: '8720349441624',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '5496371',
      rtl_batch_array: '5496371, 5496374',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 19.933,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'KD2CL9C6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KD2CL9C6'
    },
    {
      status: 210,
      sku: 'PM0072343',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244721',
      rtl_batch_array: '6244721',
      name_search: 'PRPERSIC',
      name: 'Prunus persica',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R3GNKWEK;RTA7ES48;XJPR31K9;BLVYZZXH;8Y4JC22W;S4J8KPP2;7DD7E9L2;ZAA4HR3S;YBGH4ANC;4RRP7243;KXH5BVT7;ADY7VB22;D8CDAWS3;V6GB23ZS;P1VDL1BY',
      statusContent: 'dot',
      hash: '1H271ERH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H271ERH'
    },
    {
      status: 210,
      sku: 'PM0026625',
      core_name: 'Plant',
      sppl_ean: '8720626301009',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6015090',
      rtl_batch_array: '6302658, 6015090',
      name_search: 'GEBBLUND',
      name: "Geranium 'Bob's Blunder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 591,
      chnn_stock_retail: 703,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BXNYH7KL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXNYH7KL'
    },
    {
      status: 210,
      sku: 'PM0072357',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244735',
      rtl_batch_array: '6244735',
      name_search: 'RIRWHOLL',
      name: "Ribes rubrum (white) 'Witte Hollander'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E7E22Z4',
      statusContent: 'dot',
      hash: 'LET3WD3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LET3WD3J'
    },
    {
      status: 810,
      sku: 'PM0000416',
      core_name: 'Plant',
      sppl_ean: '8720664514492',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496482',
      rtl_batch_array: '5496482',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.035,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: 'WCA2NLX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCA2NLX1'
    },
    {
      status: 210,
      sku: 'PM0072356',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244734',
      rtl_batch_array: '6244734',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: 'YLGJYY46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLGJYY46'
    },
    {
      status: 810,
      sku: 'PM0028740',
      core_name: 'Plant',
      sppl_ean: '8720353083421',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496368',
      rtl_batch_array: '5496368',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '175200C18',
      rng_range_identifier: 'H175200C18',
      rng_range_description: 'H175 cm 200 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 79.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'Y7A7HPY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7A7HPY3'
    },
    {
      status: 210,
      sku: 'PM0072282',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244237',
      rtl_batch_array: '6298790, 6244237, 6310398',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2133,
      chnn_stock_retail: 4632,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: '64V5LYAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64V5LYAY'
    },
    {
      status: 210,
      sku: 'PM0072290',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244250',
      rtl_batch_array: '6244250',
      name_search: 'HOFWILLI',
      name: "Hosta 'Frances Williams'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1699,
      chnn_stock_retail: 1699,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LNGTS6XP;93Z2AGV3;419YB131;A1RST7AP;TTA42KKK;NAD3R12L;ZH8R333C;Z7B9YTE3;SZCN38VD;2YJZVKYE;SYGRP3NC;VRET55BV;TEEA5X96',
      statusContent: 'dot',
      hash: 'P3WK7JVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3WK7JVV'
    },
    {
      status: 210,
      sku: 'PM0072281',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 5,
      btch_active_retail: '6270451',
      rtl_batch_array: '6244236, 6270451, 6304905, 6050727, 6310399',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 24926,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'ZAS9N95B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAS9N95B'
    },
    {
      status: 810,
      sku: 'PM0004676',
      core_name: 'Plant',
      sppl_ean: '8720626380554',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496366',
      rtl_batch_array: '5496366',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 26.435,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'TAKJDYCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TAKJDYCJ'
    },
    {
      status: 210,
      sku: 'PM0072277',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244224',
      rtl_batch_array: '6244224',
      name_search: 'BEFAMSTE',
      name: "Berberis frikartii 'Amstelveen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4507,
      chnn_stock_retail: 4507,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JVCE7W73;BARL9DLP;2C6THZJ3;KXX6N1W8;W4SN9DGE;XRHLP77H;DDWYYYYJ;56RJAD5S;E1BWJYG5;82WAN6X6;NSJS7PB7',
      statusContent: 'dot',
      hash: '87RRCPT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87RRCPT3'
    },
    {
      status: 210,
      sku: 'PM0072349',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244727',
      rtl_batch_array: '6244727',
      name_search: 'VACNORTH',
      name: "Vaccinium corymbosum 'Northland'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLE5Z3P8;D5PC9EN1;SZECH9NT;NY61RGXL;J6ALZP1R',
      statusContent: 'dot',
      hash: '1L1ZDRXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L1ZDRXG'
    },
    {
      status: 910,
      sku: 'PM0072279',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYDMSIBI',
      name: "Hydrangea macrophylla 'Sibilla'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'JNJ2227N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JNJ2227N'
    },
    {
      status: 210,
      sku: 'PM0072284',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244239',
      rtl_batch_array: '6244239, 6257885, 6310404',
      name_search: 'SPJFIREL',
      name: "Spiraea japonica 'Firelight'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4691,
      chnn_stock_retail: 14469,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6RV2C77;KKHHCA3V;STSC46D8;Y6JNBEPZ;GRNCJ8L4;5X39CDEG;RHCNZLBG;85R683CC;T4W9EBZP',
      statusContent: 'dot',
      hash: 'VNZ791DD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNZ791DD'
    },
    {
      status: 210,
      sku: 'PM0072287',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 5,
      btch_active_retail: '6270453',
      rtl_batch_array: '6244242, 6270453, 6304909, 5941650, 6310418',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 32243,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '9518PY8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9518PY8V'
    },
    {
      status: 210,
      sku: 'PM0072288',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177564',
      rtl_batch_array: '6177564, 6310417',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1910,
      chnn_stock_retail: 3410,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'S4SV6J3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4SV6J3K'
    },
    {
      status: 210,
      sku: 'PM0072291',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244252',
      rtl_batch_array: '6244252',
      name_search: 'HOCTREE',
      name: "Hosta 'Christmas Tree'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZ9A6X78;KPBHKZNN;RVW98HPB;L142XR8S',
      statusContent: 'dot',
      hash: 'EPEPL5H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPEPL5H7'
    },
    {
      status: 210,
      sku: 'PM0072309',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244302',
      rtl_batch_array: '6244302',
      name_search: 'SAPPDBLU',
      name: "Salvia patens 'Patio Dark Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6TPGK22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6TPGK22'
    },
    {
      status: 210,
      sku: 'PM0072289',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244244',
      rtl_batch_array: '6244244, 6310423',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1449,
      chnn_stock_retail: 6449,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'GAHG4KLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAHG4KLL'
    },
    {
      status: 810,
      sku: 'PM0008793',
      core_name: 'Plant',
      sppl_ean: '8720664510647',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496467',
      rtl_batch_array: '5496467',
      name_search: 'CLARMAND',
      name: 'Clematis armandii',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 18.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A2HRDWJB;81D7Z7NT;SHH1R6CH;XVSDW131;7ZDCPZJC;XTLYZ1G9;4HHPASY8;LCRDTP5B;C4BLBTV3;T47TA769;7237NA5V;NRZ39N9Y;TZAK7BTG;Y1JD94C5',
      statusContent: 'dot',
      hash: 'YZ5DP2NH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ5DP2NH'
    },
    {
      status: 210,
      sku: 'PM0072346',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244724',
      rtl_batch_array: '6244724',
      name_search: 'PYCDCOMI',
      name: "Pyrus communis 'Doyenn\u00e9 du Comice'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CH7BGNZY;X5EG45CP;452LAS3T;JD8WJNCP;61194LDR;G2439YD4;7W6H6N2G;LJ5TVK3R;ZV3E89RS;LVJXP3NN;L1H8DYGZ;B3ZBAJJE;L5ALVSHX;YZJTGG56;P2RXKR82;NECE7T11;VLPCBNKL',
      statusContent: 'dot',
      hash: '567KJV9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '567KJV9Y'
    },
    {
      status: 210,
      sku: 'PM0082552',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353217',
      rtl_batch_array: '6353217',
      name_search: 'POSHOLLY',
      name: "Polystichum 'Spiny Holly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZCJX97E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZCJX97E'
    },
    {
      status: 210,
      sku: 'PM0072295',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244265',
      rtl_batch_array: '6244265, 6254859',
      name_search: 'VIMINOR',
      name: 'Vinca minor',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2940,
      chnn_stock_retail: 5305,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EG281Z1T;7PHP8SX2;A9ZBL78N;VZ4C57B6;YEEWSG3H;YZL8SB43;VWHK82DS;AG2GCWHL;HXBZK6K5;D5EP2G8A;41VVEG6A;2X2V95LJ;BZCBAD26;N6895BNJ;HKDSTCGY;ABE1R6VC',
      statusContent: 'dot',
      hash: '5ES3XAHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ES3XAHD'
    },
    {
      status: 210,
      sku: 'PM0080181',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336183',
      rtl_batch_array: '6336183',
      name_search: 'HYPDLDOT',
      name: "Hydrangea paniculata Dart's Little Dot",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 991,
      chnn_stock_retail: 991,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DL76EVRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL76EVRY'
    },
    {
      status: 910,
      sku: 'PM0072299',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ANGRAVEO',
      name: 'Anethum graveolens',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '1E47983Z;8XN69PNY;2VBHWRVZ;ZK7ASS1S;29ED4VC3;4VXEH4WY;JKJ2PNYR;GDTZTXKB;868GXXLJ;XPJWDXX5;W4YWRX8S;BD9V8D8C;2VT7S1D1;L5NPJPL6;SWK679S3;XWL97JWR;E55BZ5JA;K1D6XLPJ;41KVWALY',
      statusContent: 'dot',
      hash: '8PKYVARB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8PKYVARB'
    },
    {
      status: 210,
      sku: 'PM0012277',
      core_name: 'Plant',
      sppl_ean: '8720353090917',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5375696',
      rtl_batch_array: '5375696',
      name_search: 'CAMGOLDB',
      name: "Carex morrowii 'Goldband'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJRKPTA9;9EH6KZG6;CA5RYEST;1Y9PS1RB;2YP88B29',
      statusContent: 'dot',
      hash: 'T8XEJHWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8XEJHWX'
    },
    {
      status: 810,
      sku: 'PM0006729',
      core_name: 'Plant',
      sppl_ean: '8720664509467',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496464',
      rtl_batch_array: '5496464',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'TKLWSTEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKLWSTEY'
    },
    {
      status: 810,
      sku: 'PM0020844',
      core_name: 'Plant',
      sppl_ean: '8720664509795',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496473',
      rtl_batch_array: '5496473',
      name_search: 'CLFSPRIN',
      name: "Clematis 'Fragrant Spring'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XV5Z9LLB;YZ768L7E;NX2DDYK9',
      statusContent: 'dot',
      hash: 'ADZ5KKVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADZ5KKVH'
    },
    {
      status: 210,
      sku: 'PM0080182',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336184',
      rtl_batch_array: '6336184',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2700,
      chnn_stock_retail: 2700,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'VJ15AD9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ15AD9R'
    },
    {
      status: 210,
      sku: 'PM0072305',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244290',
      rtl_batch_array: '6244290',
      name_search: 'PAOBLIVE',
      name: "Papaver orientale 'Beauty of Livermere'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 551,
      chnn_stock_retail: 551,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XSY2WZA3;H6JCH2E7;6PTZR7HJ;GX9NE5N5;SWJ6ZGYW;P1562DC5',
      statusContent: 'dot',
      hash: '62T4C6WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62T4C6WB'
    },
    {
      status: 210,
      sku: 'PM0080183',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336185',
      rtl_batch_array: '6336185',
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 10.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYVL3TAR;96HC75LG;J1CYKELX;6C5G3SPX;Z3HVWB3X;ZAG3GRCD;DEG14R1N',
      statusContent: 'dot',
      hash: 'TPCT6YT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPCT6YT9'
    },
    {
      status: 210,
      sku: 'PM0080184',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336186',
      rtl_batch_array: '6336186',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 3.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'VRS2CSEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRS2CSEW'
    },
    {
      status: 210,
      sku: 'PM0080185',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336187',
      rtl_batch_array: '6336187',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: '5CXGZZ7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CXGZZ7T'
    },
    {
      status: 210,
      sku: 'PM0072313',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244308',
      rtl_batch_array: '6244308',
      name_search: 'VAOFFICI',
      name: 'Valeriana officinalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KK1VKY4P;8DYA7H2N;RA7B4XCG;GH7GBJHH;BNKXCV9A;Z964GY1A;5AX3XCNA;WT16SWZY;92DBK7N1;KGDEKBBP;W4PEPXD2;6BZL8XND;AP3EX6KD;A8EA2ARA;GKEHWEXH;XC9RWYSP;C3278T8V;4SPWE9CV',
      statusContent: 'dot',
      hash: 'NETLKLSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NETLKLSH'
    },
    {
      status: 210,
      sku: 'PM0080186',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336189',
      rtl_batch_array: '6336189',
      name_search: 'HYPSFRAI',
      name: 'Hydrangea paniculata Sundae Fraise',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 974,
      chnn_stock_retail: 974,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JE1RX5H2;TVJKSH5R;LBKPNDD1;ALZHHNXR;YCCVESHH;142DDV9G;87AGDKC1;NH16J9WA;1YJWB1NJ;KE8BAA3N;9HBXW791;HRNKJRGK;HNWVSRL9;EA7XZXYC;AH96E5GW;1X2TCPAL;8R2Z9NZA;PRLB2BNY',
      statusContent: 'dot',
      hash: 'SCHATG65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCHATG65'
    },
    {
      status: 910,
      sku: 'PM0072315',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THOFMMOM',
      name: "Thuja occidentalis 'Filips Magic Moment'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore: 'Z4TY8D4S',
      statusContent: 'dot',
      hash: 'JA6LZD12',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JA6LZD12'
    },
    {
      status: 210,
      sku: 'PM0072316',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244694',
      rtl_batch_array: '6244694',
      name_search: 'PRDVICTO',
      name: "Prunus domestica 'Victoria'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EL659B3C;SBR37DG7;RPB57WX8;TT5YYXB2;4JRSHGCY;TAGW217X;HVA5V1LV;K33JSX8W;DZNB6G86;XDYXABWY;94RR2AZB;2VXHT89S;J6WAN3K6',
      statusContent: 'dot',
      hash: '8G4R99RN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G4R99RN'
    },
    {
      status: 210,
      sku: 'PM0072317',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244695',
      rtl_batch_array: '6244695',
      name_search: 'PYCCONFE',
      name: "Pyrus communis 'Conference'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78TNR6AS;DVHDGPC2;NR5ETZAS;GZPRZKDK;B7JA2AX7;8VH6VLWV;WVSHB7Y8;EBYNZR1D;5AHEK6LG;9Z3EPSPN;P65ADCG8;9H9DSJ2B;ASK4T5HW;VRYCD2NE;VR62KGXS;C7LZAEBR;DW74T1JH;T9ZDR5SN;AL1GZY1W;SEPY5SRN;47PVKKJK;A7545WS5;4221RV75',
      statusContent: 'dot',
      hash: '8W7C9HPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8W7C9HPV'
    },
    {
      status: 210,
      sku: 'PM0072318',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244696',
      rtl_batch_array: '6244696',
      name_search: 'PYCDCOMI',
      name: "Pyrus communis 'Doyenn\u00e9 du Comice'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CH7BGNZY;X5EG45CP;452LAS3T;JD8WJNCP;61194LDR;G2439YD4;7W6H6N2G;LJ5TVK3R;ZV3E89RS;LVJXP3NN;L1H8DYGZ;B3ZBAJJE;L5ALVSHX;YZJTGG56;P2RXKR82;NECE7T11;VLPCBNKL',
      statusContent: 'dot',
      hash: '8Z87JN2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Z87JN2Z'
    },
    {
      status: 210,
      sku: 'PM0080187',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336190',
      rtl_batch_array: '6336190',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 842,
      chnn_stock_retail: 842,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: '9PW59SH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PW59SH5'
    },
    {
      status: 210,
      sku: 'PM0072319',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244697',
      rtl_batch_array: '6244697',
      name_search: 'MADGALA',
      name: "Malus domestica 'Gala'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YC84GTZE;7WWGNYLC;GE7PW553;VLY7DPVP;D7RPAVSB;R8SZXSHT;5YYVAN6Y;GZYNBZC1;VRE5CPZ6;HRC1P4B5;E165VD8V;S55A78ZV;NE5SR2LE;AGRLP12H;NSBZB215;DAC6P1XH;R4E9ZGSA',
      statusContent: 'dot',
      hash: 'RZW65V8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZW65V8T'
    },
    {
      status: 210,
      sku: 'PM0072320',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244698',
      rtl_batch_array: '6244698',
      name_search: 'MALDBRAE',
      name: "Malus domestica 'Braeburn'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SRJE6AER;JDTGYVEK;ZZTAW163;VPG83Z3B;SXLCW3JJ;ATCLVSE1;56LR2LB3;8DW55PET',
      statusContent: 'dot',
      hash: 'CRVK3S8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRVK3S8W'
    },
    {
      status: 210,
      sku: 'PM0080188',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336191',
      rtl_batch_array: '6336191',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1995,
      chnn_stock_retail: 1995,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: '2ZT3AL3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZT3AL3J'
    },
    {
      status: 210,
      sku: 'PM0072322',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356286',
      rtl_batch_array: '6356286',
      name_search: 'PRPNUCIP',
      name: 'Prunus persica nucipersica',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDDHR7JJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDDHR7JJ'
    },
    {
      status: 210,
      sku: 'PM0072323',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244701',
      rtl_batch_array: '6244701',
      name_search: 'MADELSTA',
      name: "Malus domestica 'Elstar'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDZVRK7Z;K8E21E1P;1EA2W54T;BGJSBCKX;8X1PRC56;ZLBZL51Y;XKS9C7DG;GSDJP9H4;S9A2PJ6H;7N5SWA3C;BJVDKDWY;HX39YY72;45Y52SND;81N2XLE6;74E5CN34;GR6X6C86;PDSEXGJ1;HRDKVDTH;WSNA7K9C',
      statusContent: 'dot',
      hash: 'LWTJNEAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWTJNEAR'
    },
    {
      status: 210,
      sku: 'PM0072324',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244702',
      rtl_batch_array: '6244702',
      name_search: 'MADJONAG',
      name: "Malus domestica 'Jonagold'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N853E2J;SLKLHWLB;NWKWEXCX;CL4SXVR9;AWDPTNB7;N5LKSHN3;KLN3WSK2;EAV7R7VJ;RBATW5KG;Y68X5SRL',
      statusContent: 'dot',
      hash: 'GP6XK8N2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GP6XK8N2'
    },
    {
      status: 210,
      sku: 'PM0072325',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244703',
      rtl_batch_array: '6244703',
      name_search: 'MADGALA',
      name: "Malus domestica 'Gala'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YC84GTZE;7WWGNYLC;GE7PW553;VLY7DPVP;D7RPAVSB;R8SZXSHT;5YYVAN6Y;GZYNBZC1;VRE5CPZ6;HRC1P4B5;E165VD8V;S55A78ZV;NE5SR2LE;AGRLP12H;NSBZB215;DAC6P1XH;R4E9ZGSA',
      statusContent: 'dot',
      hash: 'L1GW2KDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1GW2KDB'
    },
    {
      status: 210,
      sku: 'PM0072326',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244704',
      rtl_batch_array: '6244704',
      name_search: 'MALDBRAE',
      name: "Malus domestica 'Braeburn'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SRJE6AER;JDTGYVEK;ZZTAW163;VPG83Z3B;SXLCW3JJ;ATCLVSE1;56LR2LB3;8DW55PET',
      statusContent: 'dot',
      hash: 'ZL1KT78D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZL1KT78D'
    },
    {
      status: 210,
      sku: 'PM0072327',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244705',
      rtl_batch_array: '6244705',
      name_search: 'PRAREGIN',
      name: "Prunus avium 'Regina'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y83T9HY4;PZYJA6ZE;8LX9E4SV;GKNTLDV5',
      statusContent: 'dot',
      hash: 'GXGX3JPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXGX3JPL'
    },
    {
      status: 210,
      sku: 'PM0072328',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244706',
      rtl_batch_array: '6244706',
      name_search: 'PRAVAN',
      name: "Prunus avium 'Van'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XAE9B6G7;KB7DZ948;SZRWRAEX;RA3N4Z4B;A6XJVR7K;PHXSXPYH;ASPHDTJD',
      statusContent: 'dot',
      hash: '46REJJXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46REJJXH'
    },
    {
      status: 210,
      sku: 'PM0072329',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244707',
      rtl_batch_array: '6244707',
      name_search: 'PRDOPAL',
      name: "Prunus domestica 'Opal'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6PSZWG;BSXAWWB3;BN3WZB67;6B85S8H6;VWP38YZR;ZSCHLWLA',
      statusContent: 'dot',
      hash: 'R6VD9Z6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6VD9Z6E'
    },
    {
      status: 210,
      sku: 'PM0082553',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353228',
      rtl_batch_array: '6353228',
      name_search: 'POWROLLI',
      name: "Potentilla 'William Rollisson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZE13S1R5',
      statusContent: 'dot',
      hash: '4VYAEW2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VYAEW2Z'
    },
    {
      status: 210,
      sku: 'PM0072330',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244708',
      rtl_batch_array: '6244708',
      name_search: 'PRDVICTO',
      name: "Prunus domestica 'Victoria'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EL659B3C;SBR37DG7;RPB57WX8;TT5YYXB2;4JRSHGCY;TAGW217X;HVA5V1LV;K33JSX8W;DZNB6G86;XDYXABWY;94RR2AZB;2VXHT89S;J6WAN3K6',
      statusContent: 'dot',
      hash: '8WKNA843',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WKNA843'
    },
    {
      status: 210,
      sku: 'PM0072332',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356287',
      rtl_batch_array: '6356287',
      name_search: 'PRPNUCIP',
      name: 'Prunus persica nucipersica',
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXWPGT68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXWPGT68'
    },
    {
      status: 210,
      sku: 'PM0072333',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244711',
      rtl_batch_array: '6244711',
      name_search: 'PYCCONFE',
      name: "Pyrus communis 'Conference'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78TNR6AS;DVHDGPC2;NR5ETZAS;GZPRZKDK;B7JA2AX7;8VH6VLWV;WVSHB7Y8;EBYNZR1D;5AHEK6LG;9Z3EPSPN;P65ADCG8;9H9DSJ2B;ASK4T5HW;VRYCD2NE;VR62KGXS;C7LZAEBR;DW74T1JH;T9ZDR5SN;AL1GZY1W;SEPY5SRN;47PVKKJK;A7545WS5;4221RV75',
      statusContent: 'dot',
      hash: 'L8JX153L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8JX153L'
    },
    {
      status: 210,
      sku: 'PM0072334',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244712',
      rtl_batch_array: '6244712',
      name_search: 'PYCDCOMI',
      name: "Pyrus communis 'Doyenn\u00e9 du Comice'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CH7BGNZY;X5EG45CP;452LAS3T;JD8WJNCP;61194LDR;G2439YD4;7W6H6N2G;LJ5TVK3R;ZV3E89RS;LVJXP3NN;L1H8DYGZ;B3ZBAJJE;L5ALVSHX;YZJTGG56;P2RXKR82;NECE7T11;VLPCBNKL',
      statusContent: 'dot',
      hash: 'K9JRDBBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9JRDBBW'
    },
    {
      status: 210,
      sku: 'PM0072335',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244713',
      rtl_batch_array: '6244713',
      name_search: 'MADELSTA',
      name: "Malus domestica 'Elstar'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDZVRK7Z;K8E21E1P;1EA2W54T;BGJSBCKX;8X1PRC56;ZLBZL51Y;XKS9C7DG;GSDJP9H4;S9A2PJ6H;7N5SWA3C;BJVDKDWY;HX39YY72;45Y52SND;81N2XLE6;74E5CN34;GR6X6C86;PDSEXGJ1;HRDKVDTH;WSNA7K9C',
      statusContent: 'dot',
      hash: 'TA4WCDX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA4WCDX8'
    },
    {
      status: 210,
      sku: 'PM0072336',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244714',
      rtl_batch_array: '6244714',
      name_search: 'MADJONAG',
      name: "Malus domestica 'Jonagold'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N853E2J;SLKLHWLB;NWKWEXCX;CL4SXVR9;AWDPTNB7;N5LKSHN3;KLN3WSK2;EAV7R7VJ;RBATW5KG;Y68X5SRL',
      statusContent: 'dot',
      hash: 'C4118Y84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4118Y84'
    },
    {
      status: 210,
      sku: 'PM0072337',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244715',
      rtl_batch_array: '6244715',
      name_search: 'MADGALA',
      name: "Malus domestica 'Gala'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YC84GTZE;7WWGNYLC;GE7PW553;VLY7DPVP;D7RPAVSB;R8SZXSHT;5YYVAN6Y;GZYNBZC1;VRE5CPZ6;HRC1P4B5;E165VD8V;S55A78ZV;NE5SR2LE;AGRLP12H;NSBZB215;DAC6P1XH;R4E9ZGSA',
      statusContent: 'dot',
      hash: 'VYK6PRT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYK6PRT9'
    },
    {
      status: 210,
      sku: 'PM0072338',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244716',
      rtl_batch_array: '6244716',
      name_search: 'MALDBRAE',
      name: "Malus domestica 'Braeburn'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SRJE6AER;JDTGYVEK;ZZTAW163;VPG83Z3B;SXLCW3JJ;ATCLVSE1;56LR2LB3;8DW55PET',
      statusContent: 'dot',
      hash: 'ELEV63YG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELEV63YG'
    },
    {
      status: 210,
      sku: 'PM0072339',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244717',
      rtl_batch_array: '6244717',
      name_search: 'PRAREGIN',
      name: "Prunus avium 'Regina'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y83T9HY4;PZYJA6ZE;8LX9E4SV;GKNTLDV5',
      statusContent: 'dot',
      hash: 'A5H8LBS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5H8LBS9'
    },
    {
      status: 210,
      sku: 'PM0072340',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244718',
      rtl_batch_array: '6244718',
      name_search: 'PRAVAN',
      name: "Prunus avium 'Van'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XAE9B6G7;KB7DZ948;SZRWRAEX;RA3N4Z4B;A6XJVR7K;PHXSXPYH;ASPHDTJD',
      statusContent: 'dot',
      hash: 'D21JKGR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D21JKGR5'
    },
    {
      status: 210,
      sku: 'PM0072341',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244719',
      rtl_batch_array: '6244719',
      name_search: 'PRDOPAL',
      name: "Prunus domestica 'Opal'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6PSZWG;BSXAWWB3;BN3WZB67;6B85S8H6;VWP38YZR;ZSCHLWLA',
      statusContent: 'dot',
      hash: '12LJ3AX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12LJ3AX2'
    },
    {
      status: 210,
      sku: 'PM0072342',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244720',
      rtl_batch_array: '6244720',
      name_search: 'PRDVICTO',
      name: "Prunus domestica 'Victoria'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EL659B3C;SBR37DG7;RPB57WX8;TT5YYXB2;4JRSHGCY;TAGW217X;HVA5V1LV;K33JSX8W;DZNB6G86;XDYXABWY;94RR2AZB;2VXHT89S;J6WAN3K6',
      statusContent: 'dot',
      hash: 'T22RJ55W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T22RJ55W'
    },
    {
      status: 210,
      sku: 'PM0072347',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244725',
      rtl_batch_array: '6244725',
      name_search: 'VACCBLUE',
      name: "Vaccinium corymbosum 'Bluecrop'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2XNBEBWB;B7722WXJ;6H776WK8;BT18YHKT;B6LSDLDW;X2KGEJ55;6L8JWB9N;P8CD2N7Y;TJYH8Z7X;74PK3H97;D8JH3L2A',
      statusContent: 'dot',
      hash: 'RS77SCYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS77SCYC'
    },
    {
      status: 210,
      sku: 'PM0072350',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244728',
      rtl_batch_array: '6244728',
      name_search: 'VACPLEMO',
      name: "Vaccinium 'Pink Lemonade'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8YBALZWS;8GL6TCZ7;7L1VJALT;VAJ4JEDR;2D85DGV1;L7138EH1;2LCH6T3R;N2NC8YTH;1PRP5HY5;L5CHHBP3;ZBZD5HV8',
      statusContent: 'dot',
      hash: '2KHB87AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KHB87AW'
    },
    {
      status: 210,
      sku: 'PM0072351',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244729',
      rtl_batch_array: '6244729',
      name_search: 'VACHPETI',
      name: "Vaccinium corymbosum 'Hortblue Petite'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJ15SSTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJ15SSTS'
    },
    {
      status: 210,
      sku: 'PM0072352',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244730',
      rtl_batch_array: '6244730',
      name_search: 'VACLBWON',
      name: "Vaccinium corymbosum 'Little Blue Wonder'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JN6HBTWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN6HBTWP'
    },
    {
      status: 210,
      sku: 'PM0072353',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244731',
      rtl_batch_array: '6244731',
      name_search: 'RUILSSIS',
      name: "Rubus idaeus 'Little Sweet Sister'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1D2TSLYY;ZDY5HJ7Y',
      statusContent: 'dot',
      hash: 'JZ9Z9PGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ9Z9PGV'
    },
    {
      status: 210,
      sku: 'PM0072354',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244732',
      rtl_batch_array: '6244732',
      name_search: 'RUFLBPRI',
      name: "Rubus fruticosus 'Little Black Prince'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7PV4LN45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PV4LN45'
    },
    {
      status: 210,
      sku: 'PM0072355',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244733',
      rtl_batch_array: '6244733',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: 'LW4C3AEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LW4C3AEP'
    },
    {
      status: 210,
      sku: 'PM0072358',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244736',
      rtl_batch_array: '6244736',
      name_search: 'RIUCHGRO',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gr\u00f6n',
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRH69A6W',
      statusContent: 'dot',
      hash: 'HLYVYNAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLYVYNAY'
    },
    {
      status: 210,
      sku: 'PM0072283',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244238',
      rtl_batch_array: '6244238',
      name_search: 'SPJDRED',
      name: "Spiraea japonica 'Dart's Red'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5156,
      chnn_stock_retail: 5156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V48RLPL3',
      statusContent: 'dot',
      hash: '724ZCETL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '724ZCETL'
    },
    {
      status: 210,
      sku: 'PM0072301',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244280',
      rtl_batch_array: '6298972, 6244280, 6254717',
      name_search: 'ECPWSWAN',
      name: "Echinacea purpurea 'White Swan'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4209,
      chnn_stock_retail: 4424,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S2AWBZCB;HG39KDT2;1XHA49R5;WJE22S97;1R7XEGKY;L2PT3L6J;XCC4293G;ZRP79VSE;78B7VR6Y;JC4C9V1W;ESDA8HWG;85ZSVL31;165RBY87;1ED1CWKC;5ZJVSL9E;CH5Y8W8C;7G45P7EN;H35LYHCY;PC724XYC;ZTT4K37B;5PZK33ZJ;638NZG6P;PPLGAK2B;C5SRYL21;AC8943T5;BLEJNK6L;8G1S87NG;1NPZYHNX;BB5BDN4E',
      statusContent: 'dot',
      hash: 'EW1XJWYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW1XJWYG'
    },
    {
      status: 210,
      sku: 'PM0072292',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244253',
      rtl_batch_array: '6244253',
      name_search: 'HOFAUREO',
      name: "Hosta 'Fortunei Aureomarginata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 747,
      chnn_stock_retail: 747,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A34T7ERG;TY3C8ZR9;SSK3W62L;X96RPE39;T8Y5GYKH;5EW82J7P;AYLD8HA7;PVK9X9Z1;ZZ7J62W4;XE3CRXRP;XD37KXVK;V5XY122S;Y8J689KS;4L4R6XWS;HKDT7VJ3;G4P5CE5E;ZBYNEH34;9AZX9K5S;GBXCWW3X;YL53WNLB',
      statusContent: 'dot',
      hash: 'VJ8VAZD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ8VAZD8'
    },
    {
      status: 210,
      sku: 'PM0072302',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244281',
      rtl_batch_array: '6244281',
      name_search: 'ECRITRO',
      name: 'Echinops ritro',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2025,
      chnn_stock_retail: 2025,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T111ZP6P;KPYZZHXK;HYTENXCT;Z99HCVVL;5SN536P5;WZVG58V5;8ZZ4P9HR;4YPE5DKZ;DZYKJLDD;G5HE49TP;WBNBH4HG;CJDS87JX;JAN4G3XE;XJXXPN9E;1P5BR2RX;S31WCRCD',
      statusContent: 'dot',
      hash: 'K5NZ3LR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5NZ3LR7'
    },
    {
      status: 210,
      sku: 'PM0072307',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244297',
      rtl_batch_array: '6244297',
      name_search: 'SASUBULA',
      name: 'Sagina subulata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1062,
      chnn_stock_retail: 1062,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N9RZSZH6;7YVJ7E7T;JAPL8TYL;R4JJC7RG;WZXH5277;RNJPN82S;W2Y5ZDJP;SJJC95A5;ZY4KS3BA;EBH22TA3;24DX6R1S;6TAEN25W;C899288A;E1DP5TD1;8TJJJLRH;8YRV2V3P;A281Y57T;2988Y9TA;HZYSZ3X3;RD4WB6KE;GBNBW2D7',
      statusContent: 'dot',
      hash: '87BXBNEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87BXBNEA'
    },
    {
      status: 210,
      sku: 'PM0072308',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244300',
      rtl_batch_array: '6244300',
      name_search: 'SANRQUEE',
      name: "Salvia nemorosa 'Rose Queen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2009,
      chnn_stock_retail: 2009,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RZSTZW64;4HPA3HX1;2Z2XWE6C;1Y7HVSL5;P92HWAD1;XGY364VV;L2T8899T;CVSG7GWL',
      statusContent: 'dot',
      hash: '5XV1DBPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XV1DBPH'
    },
    {
      status: 210,
      sku: 'PM0072310',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244303',
      rtl_batch_array: '6244303',
      name_search: 'SARHTRUM',
      name: "Salvia roemeriana 'Hot Trumpets'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 751,
      chnn_stock_retail: 751,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZY39NT;VHHZJ9BY',
      statusContent: 'dot',
      hash: 'TYHKDLTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYHKDLTZ'
    },
    {
      status: 210,
      sku: 'PM0072311',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244304',
      rtl_batch_array: '6244304',
      name_search: 'SETELEPH',
      name: 'Sedum telephium',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TNLEVD2C;S8ZW6KKJ;BAD11ZKH;9Y6Z56K7;1TSZ67A2;4NV5VBYT',
      statusContent: 'dot',
      hash: 'BWTWNZ2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWTWNZ2S'
    },
    {
      status: 210,
      sku: 'PM0072285',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244240',
      rtl_batch_array: '6244240',
      name_search: 'SPJFIREL',
      name: "Spiraea japonica 'Firelight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2152,
      chnn_stock_retail: 2152,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6RV2C77;KKHHCA3V;STSC46D8;Y6JNBEPZ;GRNCJ8L4;5X39CDEG;RHCNZLBG;85R683CC;T4W9EBZP',
      statusContent: 'dot',
      hash: '89G61875',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89G61875'
    },
    {
      status: 210,
      sku: 'PM0072298',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244271',
      rtl_batch_array: '6244271, 6305790',
      name_search: 'ANHWHIRL',
      name: "Anemone hybrida 'Whirlwind'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3489,
      chnn_stock_retail: 3857,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXZXW2TW;TVZDYJ9Z;KW8683ST;64ZCB9YS;5C4Z92WR;KDYXL3CC;2NZZ6A41;786VAPSK;ZJ26YGB3;J24R6NC3',
      statusContent: 'dot',
      hash: '7HWZNEDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HWZNEDV'
    },
    {
      status: 810,
      sku: 'PM0029158',
      core_name: 'Plant',
      sppl_ean: '8720626380547',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496365',
      rtl_batch_array: '5496365',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 23.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'PRBV6VEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRBV6VEE'
    },
    {
      status: 210,
      sku: 'PM0074715',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293900',
      rtl_batch_array: '6293900',
      name_search: 'BUDRINDI',
      name: 'Buddleja d. Rocketstar Indigo',
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 5.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WBDLG18R',
      statusContent: 'dot',
      hash: '829RAW7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '829RAW7R'
    },
    {
      status: 210,
      sku: 'PM0072300',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244279',
      rtl_batch_array: '6244279',
      name_search: 'CRLUCIFE',
      name: "Crocosmia 'Lucifer'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6345,
      chnn_stock_retail: 6345,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '16DN948R;2ZP5X5CE;Z1RVPG1Y;H2YAA3WG;K4WH8SY1;HY4GS1SR;5C4CPT29',
      statusContent: 'dot',
      hash: 'LCBTNZLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCBTNZLH'
    },
    {
      status: 210,
      sku: 'PM0072294',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244262',
      rtl_batch_array: '6244262',
      name_search: 'MISNIPPO',
      name: "Miscanthus sinensis 'Nippon'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2928,
      chnn_stock_retail: 2928,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5A643CAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5A643CAE'
    },
    {
      status: 210,
      sku: 'PM0072303',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6244285',
      rtl_batch_array: '6244285, 6284560, 6258809, 6252695',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 17571,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: '471S1L5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '471S1L5C'
    },
    {
      status: 210,
      sku: 'PM0072304',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244288',
      rtl_batch_array: '6346758, 6244288, 5837465',
      name_search: 'PAVREHBR',
      name: "Panicum virgatum 'Rehbraun'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4099,
      chnn_stock_retail: 5044,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTPBCVG1;BDLXK19Y;AVX56D5S;7RH9XSJ5;1XCWN1HP;2D2J95VP;DHYCCDXV;2YJN9CAT;2BR3BZTA;D1K77WWG;7RDRGPE4;KLY9228P',
      statusContent: 'dot',
      hash: '9V6ZYBCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V6ZYBCK'
    },
    {
      status: 210,
      sku: 'PM0072306',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244295',
      rtl_batch_array: '6244295',
      name_search: 'PHAUSTRA',
      name: 'Phragmites australis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9TB4A4Z;LXBT97CP;ZS4P7HSG;4291A3CA;PX94H3ND;X96L4HRN;2R39RHKW',
      statusContent: 'dot',
      hash: 'EZA1SAK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZA1SAK9'
    },
    {
      status: 810,
      sku: 'PM0012498',
      core_name: 'Plant',
      sppl_ean: '8720626314658',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496666',
      rtl_batch_array: '5496666',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.946,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: 'LZR41VS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZR41VS9'
    },
    {
      status: 210,
      sku: 'PM0021835',
      core_name: 'Plant',
      sppl_ean: '8720349452040',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439513',
      rtl_batch_array: '5439513',
      name_search: 'ROTRIATH',
      name: 'Rosa (H) Triathlon',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 505,
      chnn_stock_retail: 505,
      sppl_order_minimum: 3,
      sppl_prcp: 3.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PTND5JNX',
      statusContent: 'dot',
      hash: 'S7A33WEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7A33WEA'
    },
    {
      status: 210,
      sku: 'PM0051459',
      core_name: 'Plant',
      sppl_ean: '8720349489572',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582266',
      rtl_batch_array: '5582266',
      name_search: 'PHOSWINE',
      name: 'Physocarpus opulifolius Summer Wine',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJBWSWTW;PAZLGJK7;WAP8SBGR;PYSTNZDL;CB5NHEEY;EYCGXX44',
      statusContent: 'dot',
      hash: 'DPGJ9EJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPGJ9EJN'
    },
    {
      status: 210,
      sku: 'PM0072388',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244820',
      rtl_batch_array: '6244820',
      name_search: 'PRDOPAL',
      name: "Prunus domestica 'Opal'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6PSZWG;BSXAWWB3;BN3WZB67;6B85S8H6;VWP38YZR;ZSCHLWLA',
      statusContent: 'dot',
      hash: 'YND1LYEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YND1LYEE'
    },
    {
      status: 810,
      sku: 'PM0072404',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244908',
      rtl_batch_array: '6244908',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'YEASLZ75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEASLZ75'
    },
    {
      status: 210,
      sku: 'PM0080199',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336206',
      rtl_batch_array: '6336206',
      name_search: 'AUJROZAN',
      name: "Aucuba japonica 'Rozannie'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 3.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YZNCZ4AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZNCZ4AS'
    },
    {
      status: 210,
      sku: 'PM0072359',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244737',
      rtl_batch_array: '6244737',
      name_search: 'RIUCHROE',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki R\u00f6d',
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECJGAL8T;H955H26G;V85N3L43;2Y2CY1AD;C8RL4TWB;B58HCCJS',
      statusContent: 'dot',
      hash: '9XPN8LED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XPN8LED'
    },
    {
      status: 210,
      sku: 'PM0072360',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244738',
      rtl_batch_array: '6244738',
      name_search: 'MADELSTA',
      name: "Malus domestica 'Elstar'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDZVRK7Z;K8E21E1P;1EA2W54T;BGJSBCKX;8X1PRC56;ZLBZL51Y;XKS9C7DG;GSDJP9H4;S9A2PJ6H;7N5SWA3C;BJVDKDWY;HX39YY72;45Y52SND;81N2XLE6;74E5CN34;GR6X6C86;PDSEXGJ1;HRDKVDTH;WSNA7K9C',
      statusContent: 'dot',
      hash: 'BJVVLAB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJVVLAB9'
    },
    {
      status: 210,
      sku: 'PM0051454',
      core_name: 'Plant',
      sppl_ean: '8720664685895',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582242',
      rtl_batch_array: '5582242',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '9AB7VEPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AB7VEPY'
    },
    {
      status: 210,
      sku: 'PM0072386',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244818',
      rtl_batch_array: '6244818',
      name_search: 'MADJONAG',
      name: "Malus domestica 'Jonagold'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N853E2J;SLKLHWLB;NWKWEXCX;CL4SXVR9;AWDPTNB7;N5LKSHN3;KLN3WSK2;EAV7R7VJ;RBATW5KG;Y68X5SRL',
      statusContent: 'dot',
      hash: 'HXEX126D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXEX126D'
    },
    {
      status: 810,
      sku: 'PM0072414',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244921',
      rtl_batch_array: '6244921',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'CXTHCJ6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXTHCJ6L'
    },
    {
      status: 810,
      sku: 'PM0004845',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281112',
      rtl_batch_array: '6281112',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '150175C25',
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'AKAKEB6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKAKEB6B'
    },
    {
      status: 810,
      sku: 'PM0072407',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244911',
      rtl_batch_array: '6244911',
      name_search: 'LOHENRYI',
      name: 'Lonicera henryi',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN37L23T;JG217ECD;8H823RVC;REDLBRVV;CRZV8V5A;TPDWP3EY;7XRTK71Y;EENTKELG;E63ER6TJ;NWD4J7CW;GLSRDX75;J6K74GCC;W8A35BAE;CK844ZSW',
      statusContent: 'dot',
      hash: 'YZNZKLXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZNZKLXZ'
    },
    {
      status: 210,
      sku: 'PM0080200',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336207',
      rtl_batch_array: '6336207',
      name_search: 'BEPGLOBE',
      name: "Betula pendula 'Globe'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_prcp: 28.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AEVT56Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEVT56Z4'
    },
    {
      status: 210,
      sku: 'PM0072381',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244792',
      rtl_batch_array: '6244792',
      name_search: 'ACAISSAI',
      name: "Actinidia arguta 'Issai'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 3.345,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC3129JH;9G2Y6K9A;WVSP5PPA',
      statusContent: 'dot',
      hash: '89RHZCJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89RHZCJH'
    },
    {
      status: 810,
      sku: 'PM0008113',
      core_name: 'Plant',
      sppl_ean: '8720664542860',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496554',
      rtl_batch_array: '5496554',
      name_search: 'HAVIRGIN',
      name: 'Hamamelis virginiana',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.537,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PLT32T6E;KHE2ADXH',
      statusContent: 'dot',
      hash: 'G7B71ZBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7B71ZBV'
    },
    {
      status: 910,
      sku: 'PM0014910',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6268490',
      rtl_batch_array: '6311888, 6268490',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 18,
      chnn_stock_retail: 2518,
      sppl_prcp: 4.588,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'GVYTKES4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVYTKES4'
    },
    {
      status: 810,
      sku: 'PM0072415',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244922',
      rtl_batch_array: '6244922',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 26.402,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'XPCVZX38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPCVZX38'
    },
    {
      status: 810,
      sku: 'PM0072401',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244904',
      rtl_batch_array: '6244904',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 39.822,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'ZSY141RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSY141RX'
    },
    {
      status: 810,
      sku: 'PM0072410',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244914',
      rtl_batch_array: '6244914',
      name_search: 'LOBDSCAR',
      name: "Lonicera brownii 'Dropmore Scarlet'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HRTY5Z56;KYAA5AE3;LVCECV1Z;82824D6X;PKDCCSHJ;ALG82C2J;KA8C4BHG;N3XT2CAC;CTBHCJAW;H27YVTBB;6TZJWLPY;5SGY7JHZ;YR94JL91;4A1V5NZJ;DETDPH31;NHKSVAWZ;SRZSRK37;SB3HCNRP;CPLS19Y1;A5BP2L6G;YSG8BWYW;5WK11HAP',
      statusContent: 'dot',
      hash: 'X29Y792R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X29Y792R'
    },
    {
      status: 210,
      sku: 'PM0072361',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244739',
      rtl_batch_array: '6244739',
      name_search: 'MADJONAG',
      name: "Malus domestica 'Jonagold'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N853E2J;SLKLHWLB;NWKWEXCX;CL4SXVR9;AWDPTNB7;N5LKSHN3;KLN3WSK2;EAV7R7VJ;RBATW5KG;Y68X5SRL',
      statusContent: 'dot',
      hash: 'HGKKSWW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGKKSWW1'
    },
    {
      status: 210,
      sku: 'PM0072362',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244740',
      rtl_batch_array: '6244740',
      name_search: 'PRAVAN',
      name: "Prunus avium 'Van'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XAE9B6G7;KB7DZ948;SZRWRAEX;RA3N4Z4B;A6XJVR7K;PHXSXPYH;ASPHDTJD',
      statusContent: 'dot',
      hash: 'P5NS4TT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5NS4TT6'
    },
    {
      status: 810,
      sku: 'PM0072406',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244910',
      rtl_batch_array: '6244910',
      name_search: 'LOCAPRIF',
      name: 'Lonicera caprifolium',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AP9DRCV6',
      statusContent: 'dot',
      hash: '4BCSXSVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BCSXSVZ'
    },
    {
      status: 210,
      sku: 'PM0080201',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336208',
      rtl_batch_array: '6336208',
      name_search: 'BEUJACQU',
      name: 'Betula utilis jacquemontii',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 595,
      chnn_stock_retail: 595,
      sppl_prcp: 18.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EXVJPNCA',
      statusContent: 'dot',
      hash: 'HE11LTHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HE11LTHD'
    },
    {
      status: 810,
      sku: 'PM0012697',
      core_name: 'Plant',
      sppl_ean: '8720664516281',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496494',
      rtl_batch_array: '5496494',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 50.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'JHE9K41H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHE9K41H'
    },
    {
      status: 810,
      sku: 'PM0011084',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6287395',
      rtl_batch_array: '5496493, 6287395',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 28,
      chnn_stock_retail: 78,
      sppl_prcp: 38.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'PJAH6DNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJAH6DNE'
    },
    {
      status: 210,
      sku: 'PM0072363',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244741',
      rtl_batch_array: '6244741',
      name_search: 'PRDOPAL',
      name: "Prunus domestica 'Opal'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6PSZWG;BSXAWWB3;BN3WZB67;6B85S8H6;VWP38YZR;ZSCHLWLA',
      statusContent: 'dot',
      hash: 'BDL8WBWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDL8WBWE'
    },
    {
      status: 810,
      sku: 'PM0072412',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244917',
      rtl_batch_array: '6244917',
      name_search: 'PRIKNMAI',
      name: "Prunus incisa 'Kojou-no-mai'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9B8SLJH;LJKEV9AE;PRLZCKB2;H674SENK;NTL778JL;XAC44826;1ZJ5JHY8;RJWAELSD;P9SAD49D;DHSLTBC4;BEB8XJ5E;T62GK6HN;KY53KRSX;RY5BV2N8;X555ZXSE;HG5X55DZ;85S5ZK5K;T4KZ886G;RY8KPRB4;LR4GA7VW',
      statusContent: 'dot',
      hash: 'Y766XX79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y766XX79'
    },
    {
      status: 210,
      sku: 'PM0072364',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244742',
      rtl_batch_array: '6244742',
      name_search: 'PRDRCALT',
      name: "Prunus domestica 'Reine Claude d'Althan'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TT45C5B3;RNNH8ZRA;J9841G6P;VZ1KATK2;KX6XBLJX;N3V827P5;PJ6WTH3P;14WGGGPA;9YZEBCST;SG1HR3H4;6KHTZ3CR;75S4BCK6',
      statusContent: 'dot',
      hash: 'HN9VE1D2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HN9VE1D2'
    },
    {
      status: 810,
      sku: 'PM0002298',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281107',
      rtl_batch_array: '6281107',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '200225C30',
      rng_range_identifier: 'H200225C30',
      rng_range_description: 'H200 cm 225 cm C30',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 60.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'ZNXPRG3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNXPRG3D'
    },
    {
      status: 810,
      sku: 'PM0051452',
      core_name: 'Plant',
      sppl_ean: '8720664512788',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582234',
      rtl_batch_array: '5582234',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: '52KHBTTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52KHBTTL'
    },
    {
      status: 210,
      sku: 'PM0080202',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336210',
      rtl_batch_array: '6336210',
      name_search: 'BUAUNIQU',
      name: 'Buddleja alternifolia Unique',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXWRJ9KT;AEREWWKG;57YYNLSG;SVAX9J4A;6G4VJZ8K;DK784BJ4;5KKSCBHR',
      statusContent: 'dot',
      hash: 'YW7PRG5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW7PRG5W'
    },
    {
      status: 210,
      sku: 'PM0072365',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244744',
      rtl_batch_array: '6244744',
      name_search: 'PRDRCOUL',
      name: "Prunus domestica 'Reine Claude d'Oullins'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PHZN9C3G;R6H74JWS;8C47SLKR;46AHSSGZ;ENS4ENC7;RWBBZVW2',
      statusContent: 'dot',
      hash: '6RRAEDPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RRAEDPY'
    },
    {
      status: 910,
      sku: 'PM0067991',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: '4WJ28D6L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4WJ28D6L'
    },
    {
      status: 210,
      sku: 'PM0022016',
      core_name: 'Plant',
      sppl_ean: '8720664886476',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6080761',
      rtl_batch_array: '6080761, 6133729',
      name_search: 'SOGDWARF',
      name: "Solidago 'Golden Dwarf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 231,
      chnn_stock_retail: 575,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YPNYNRP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPNYNRP8'
    },
    {
      status: 210,
      sku: 'PM0072383',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244794',
      rtl_batch_array: '6244794',
      name_search: 'ARPVIKIN',
      name: "Aronia prunifolia 'Viking'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YC78YB;8JRTXES5;CW46RE5L;XD2CED58;NX1GBSDX;S5E4T7PW;7V78YR9S;L83Y4B14',
      statusContent: 'dot',
      hash: 'D21R77TK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D21R77TK'
    },
    {
      status: 210,
      sku: 'PM0080203',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336214',
      rtl_batch_array: '6336214',
      name_search: 'BUDBHEAV',
      name: "Buddleja davidii 'Butterfly Heaven'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2345,
      chnn_stock_retail: 2345,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RGNPSGH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGNPSGH2'
    },
    {
      status: 210,
      sku: 'PM0080204',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336215',
      rtl_batch_array: '6336215',
      name_search: 'BUDFPDPI',
      name: 'Buddleja Free Petite Dark Pink',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2655,
      chnn_stock_retail: 2655,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W87GBDZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W87GBDZJ'
    },
    {
      status: 810,
      sku: 'PM0028562',
      core_name: 'Plant',
      sppl_ean: '8720353098265',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496514',
      rtl_batch_array: '5496514',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 12.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'DSL42NZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSL42NZL'
    },
    {
      status: 210,
      sku: 'PM0080189',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336192',
      rtl_batch_array: '6336192',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 845,
      chnn_stock_retail: 845,
      sppl_prcp: 3.371,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '7PYS8ZNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PYS8ZNW'
    },
    {
      status: 210,
      sku: 'PM0072369',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244760',
      rtl_batch_array: '6244760',
      name_search: 'RUFNAVAH',
      name: "Rubus fruticosus 'Navaho'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TVXLGVHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVXLGVHZ'
    },
    {
      status: 210,
      sku: 'PM0072371',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244764',
      rtl_batch_array: '6244764',
      name_search: 'RUBLTLOG',
      name: 'Rubus loganobaccus Thornless Loganberry',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8CRWW8LV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CRWW8LV'
    },
    {
      status: 210,
      sku: 'PM0072372',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244774',
      rtl_batch_array: '6244774',
      name_search: 'VIAURORE',
      name: "Vitis 'Aurore'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGA3R15B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGA3R15B'
    },
    {
      status: 210,
      sku: 'PM0072373',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244775',
      rtl_batch_array: '6244775',
      name_search: 'VIBIANCA',
      name: "Vitis 'Bianca'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8SR58N91;8BW8W1G9;2WL41Z8S;VY4VGHZC;4GCPRSXC;2TDYDR7A;VEZTJ3A1;929VP2NC;R4SXTXB2',
      statusContent: 'dot',
      hash: 'V71SDPX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V71SDPX5'
    },
    {
      status: 210,
      sku: 'PM0001275',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6277009',
      rtl_batch_array: '6277009',
      name_search: 'ROSCMEID',
      name: 'Rosa (H) Crimson Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '26389LYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26389LYJ'
    },
    {
      status: 210,
      sku: 'PM0072375',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244781',
      rtl_batch_array: '6244781',
      name_search: 'VIMBLANC',
      name: "Vitis 'Muscat Blanc'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BXS82993',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXS82993'
    },
    {
      status: 210,
      sku: 'PM0004322',
      core_name: 'Plant',
      sppl_ean: '8720349445530',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439488',
      rtl_batch_array: '5439488',
      name_search: 'ROLMEIDI',
      name: 'Rosa (H) Lovely Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSSB3376',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSSB3376'
    },
    {
      status: 210,
      sku: 'PM0080190',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336193',
      rtl_batch_array: '6336193',
      name_search: 'ILCCGOLD',
      name: "Ilex crenata 'Convexed Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 2.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BYKS7D8B;1KA5253W',
      statusContent: 'dot',
      hash: 'DDTJRB6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDTJRB6R'
    },
    {
      status: 210,
      sku: 'PM0080191',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336194',
      rtl_batch_array: '6336194',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_prcp: 3.371,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PTR1CWVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTR1CWVL'
    },
    {
      status: 210,
      sku: 'PM0080192',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336198',
      rtl_batch_array: '6336198',
      name_search: 'KALBSTAT',
      name: "Kalmia latifolia 'Bay State'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '146C7YNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '146C7YNA'
    },
    {
      status: 210,
      sku: 'PM0024626',
      core_name: 'Plant',
      sppl_ean: '8720664803534',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084671',
      rtl_batch_array: '6084671, 6353234',
      name_search: 'PRDRUBIN',
      name: "Primula denticulata 'Rubin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5018,
      chnn_stock_retail: 5610,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LA6PKLVW;BC2TTSBK;118HH5JE;L7A2DSCP',
      statusContent: 'dot',
      hash: 'V96X9CHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V96X9CHJ'
    },
    {
      status: 210,
      sku: 'PM0006060',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084919',
      rtl_batch_array: '6084919',
      name_search: 'FUMCORNE',
      name: "Fuchsia 'Madame Cornelissen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1964,
      chnn_stock_retail: 1964,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7V1GVRRN;VW4HZ6JG;NDHD7Y8X;H1RB9BTV;BJE6PN6C;79APZY56;ERKSB6PT;PSXVLKBJ',
      statusContent: 'dot',
      hash: 'VN1CGRGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VN1CGRGV'
    },
    {
      status: 210,
      sku: 'PM0080205',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336216',
      rtl_batch_array: '6336216',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1360,
      chnn_stock_retail: 1360,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: 'T19HHB79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T19HHB79'
    },
    {
      status: 810,
      sku: 'PM0021714',
      core_name: 'Plant',
      sppl_ean: '8720664800922',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496667',
      rtl_batch_array: '5496667',
      name_search: 'PAQENGEL',
      name: 'Parthenocissus quinquefolia engelmannii',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.946,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG1H657G;1D75YPT3;JC84WWJZ;XY55AD12;DPP95XZR;TAVEAT1N',
      statusContent: 'dot',
      hash: '49RV7AVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49RV7AVD'
    },
    {
      status: 210,
      sku: 'PM0080206',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336221',
      rtl_batch_array: '6336221',
      name_search: 'BUDBCLSW',
      name: 'Buddleja d. B. Candy Lila Sweeth.',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 15.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XS6NG56G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS6NG56G'
    },
    {
      status: 210,
      sku: 'PM0067989',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267677',
      rtl_batch_array: '6267677',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_prcp: 4.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'Z2J6J13L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2J6J13L'
    },
    {
      status: 210,
      sku: 'PM0080193',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336199',
      rtl_batch_array: '6336199',
      name_search: 'KALMOYLA',
      name: "Kalmia latifolia 'Moyland'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 418,
      chnn_stock_retail: 418,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SGAH5BEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGAH5BEH'
    },
    {
      status: 210,
      sku: 'PM0072379',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244788',
      rtl_batch_array: '6244788',
      name_search: 'CORZELLE',
      name: "Corylus 'Rode Zellernoot'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1DGK4V1;5679LLKP;BPAZ9HY4;9YLH4AX7;49H1CXWV;LLDGK8G5;4V6DGJWK;96CZ4G98;Y1P4HHYZ;NAJ1CLYJ;X27CH8VC;XN834NX2;B824C5AC;KYA77V24',
      statusContent: 'dot',
      hash: 'E7KH24SD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7KH24SD'
    },
    {
      status: 210,
      sku: 'PM0072380',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244789',
      rtl_batch_array: '6244789',
      name_search: 'ACAWEIKI',
      name: "Actinidia arguta 'Weiki'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.345,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84Y5DEV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84Y5DEV8'
    },
    {
      status: 210,
      sku: 'PM0072382',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244793',
      rtl_batch_array: '6244793',
      name_search: 'ARPNERO',
      name: "Aronia prunifolia 'Nero'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8BZ7ER2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BZ7ER2Y'
    },
    {
      status: 210,
      sku: 'PM0072387',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244819',
      rtl_batch_array: '6244819',
      name_search: 'PRAVAN',
      name: "Prunus avium 'Van'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XAE9B6G7;KB7DZ948;SZRWRAEX;RA3N4Z4B;A6XJVR7K;PHXSXPYH;ASPHDTJD',
      statusContent: 'dot',
      hash: 'TSCE4Y6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSCE4Y6K'
    },
    {
      status: 210,
      sku: 'PM0072390',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244822',
      rtl_batch_array: '6244822',
      name_search: 'PYCCONFE',
      name: "Pyrus communis 'Conference'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78TNR6AS;DVHDGPC2;NR5ETZAS;GZPRZKDK;B7JA2AX7;8VH6VLWV;WVSHB7Y8;EBYNZR1D;5AHEK6LG;9Z3EPSPN;P65ADCG8;9H9DSJ2B;ASK4T5HW;VRYCD2NE;VR62KGXS;C7LZAEBR;DW74T1JH;T9ZDR5SN;AL1GZY1W;SEPY5SRN;47PVKKJK;A7545WS5;4221RV75',
      statusContent: 'dot',
      hash: 'WZSXWR9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZSXWR9T'
    },
    {
      status: 210,
      sku: 'PM0072391',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244823',
      rtl_batch_array: '6244823',
      name_search: 'PYCDCOMI',
      name: "Pyrus communis 'Doyenn\u00e9 du Comice'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CH7BGNZY;X5EG45CP;452LAS3T;JD8WJNCP;61194LDR;G2439YD4;7W6H6N2G;LJ5TVK3R;ZV3E89RS;LVJXP3NN;L1H8DYGZ;B3ZBAJJE;L5ALVSHX;YZJTGG56;P2RXKR82;NECE7T11;VLPCBNKL',
      statusContent: 'dot',
      hash: 'NN2NCK1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN2NCK1Y'
    },
    {
      status: 210,
      sku: 'PM0072392',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244824',
      rtl_batch_array: '6244824',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: '8ZVW7PSC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZVW7PSC'
    },
    {
      status: 210,
      sku: 'PM0080194',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336200',
      rtl_batch_array: '6336200',
      name_search: 'KAPOLIFO',
      name: 'Kalmia polifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3300,
      chnn_stock_retail: 3300,
      sppl_prcp: 3.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KVL9BP6N;KDAA7SBD;TR29CB6S;EZCJPKNB',
      statusContent: 'dot',
      hash: 'AYY6G22S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYY6G22S'
    },
    {
      status: 910,
      sku: 'PM0080195',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336201',
      rtl_batch_array: '6336201',
      name_search: 'LAIBRED',
      name: 'Lagerstroemia indica Best Red',
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 34.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHB1RHC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHB1RHC2'
    },
    {
      status: 210,
      sku: 'PM0080196',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336203',
      rtl_batch_array: '6336203',
      name_search: 'ANPBICE',
      name: "Andromeda polifolia 'Blue Ice'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 46229,
      chnn_stock_retail: 46229,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JK4AY89J;S75DWC4K;D72VJB5G;HHECGGYP;6J4RHVRK;4Z28VW5Z;72ECN1VB;T2391BK2;T6R4CCXJ;C4JB33J8;JA98Y14H;WYSE99A5;J4AJA8BK;PVWLH1NX;VAVZHZR5;HNH59EWB;S44W2BHY;77JZWT7D;PWXVG1YB;HWZAA26Y;WESSTJD5;RLHP5XK5;1445XJDH',
      statusContent: 'dot',
      hash: 'RS2VBWE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS2VBWE8'
    },
    {
      status: 210,
      sku: 'PM0080197',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336204',
      rtl_batch_array: '6336204',
      name_search: 'ANPCOMPA',
      name: "Andromeda polifolia 'Compacta'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5571,
      chnn_stock_retail: 5571,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DHR6WDW;49K2T5N3;7BHD3Z3E;VJR4D2A1;5E2EC5RE;RN5JNPDS;9ELGTAZ9;ENYNN284;P4ZHW4PZ',
      statusContent: 'dot',
      hash: 'XNTARY5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNTARY5B'
    },
    {
      status: 210,
      sku: 'PM0080198',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336205',
      rtl_batch_array: '6336205',
      name_search: 'ARMHUGIN',
      name: "Aronia melanocarpa 'Hugin'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5TCDL68E;YGCTNB2R;1SRW387V;BPDCYZ86;NLP83ELZ;VTCT3H1S;KYDLXEAY;ELDY56HC;57S12SZ6',
      statusContent: 'dot',
      hash: '6ZXXCS2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZXXCS2R'
    },
    {
      status: 210,
      sku: 'PM0080207',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336223',
      rtl_batch_array: '6336223',
      name_search: 'BUDBCLPU',
      name: 'Buddleja d. B. Candy Little Purple',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 15.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H75ES7AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H75ES7AE'
    },
    {
      status: 210,
      sku: 'PM0080208',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336224',
      rtl_batch_array: '6336224',
      name_search: 'BUDBCLRU',
      name: 'Buddleja d. B. Candy Little Ruby',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_prcp: 15.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ER53BY3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ER53BY3W'
    },
    {
      status: 210,
      sku: 'PM0072378',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244786',
      rtl_batch_array: '6244786',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: 'ZN1RLBRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN1RLBRH'
    },
    {
      status: 210,
      sku: 'PM0082554',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353231',
      rtl_batch_array: '6353231',
      name_search: 'PRCPROSE',
      name: "Primula cortusoides 'Primadiente Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYNPS24A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYNPS24A'
    },
    {
      status: 210,
      sku: 'PM0082555',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353236',
      rtl_batch_array: '6353236',
      name_search: 'PRGLACE',
      name: "Primula 'Green Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '271PYBGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '271PYBGY'
    },
    {
      status: 210,
      sku: 'PM0014781',
      core_name: 'Plant',
      sppl_ean: '8720664669482',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439524',
      rtl_batch_array: '5439524',
      name_search: 'ROSPROAD',
      name: 'Rosa (R) Purple Roadrunner',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 745,
      chnn_stock_retail: 745,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XW3WB7JC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW3WB7JC'
    },
    {
      status: 210,
      sku: 'PM0082556',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353238',
      rtl_batch_array: '6353238',
      name_search: 'PRPKMIX',
      name: 'Primula pub. Kaleidoscope Mix',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1NETT29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1NETT29'
    },
    {
      status: 810,
      sku: 'PM0082557',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353241',
      rtl_batch_array: '6353241',
      name_search: 'PRSIKKIM',
      name: 'Primula sikkimensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '87NDHRN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87NDHRN3'
    },
    {
      status: 210,
      sku: 'PM0082558',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353242',
      rtl_batch_array: '6353242',
      name_search: 'PRSBEER',
      name: "Primula 'Strong Beer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZB7XYJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZB7XYJV'
    },
    {
      status: 210,
      sku: 'PM0082559',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353243',
      rtl_batch_array: '6353243',
      name_search: 'PRGAROSA',
      name: "Prunella grandiflora 'Altenberg Rosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8WPEDYSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WPEDYSA'
    },
    {
      status: 210,
      sku: 'PM0082560',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353245',
      rtl_batch_array: '6353245',
      name_search: 'PUBSILVE',
      name: "Pulmonaria 'Blake's Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C5ZZL5S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5ZZL5S1'
    },
    {
      status: 210,
      sku: 'PM0082561',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353246',
      rtl_batch_array: '6353246',
      name_search: 'PUBHUEGE',
      name: "Pulmonaria 'Blauer H\u00fcgel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4K476KAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K476KAX'
    },
    {
      status: 210,
      sku: 'PM0082562',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353249',
      rtl_batch_array: '6353249',
      name_search: 'PUSLEOPA',
      name: "Pulmonaria saccharata 'Leopard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7HVWNZ7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HVWNZ7C'
    },
    {
      status: 810,
      sku: 'PM0072408',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244912',
      rtl_batch_array: '6244912',
      name_search: 'LOPBELGI',
      name: "Lonicera periclymenum 'Belgica'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPRANN1H;GA61NY68',
      statusContent: 'dot',
      hash: '4K77Z155',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K77Z155'
    },
    {
      status: 810,
      sku: 'PM0072409',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244913',
      rtl_batch_array: '6244913',
      name_search: 'LOPERICL',
      name: 'Lonicera periclymenum',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYGLHEPG;61EXCRG3;XS5W43AY;VC6L2AA1;HARWJ729',
      statusContent: 'dot',
      hash: '892VYGCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '892VYGCB'
    },
    {
      status: 810,
      sku: 'PM0072413',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244920',
      rtl_batch_array: '6244920, 6307635',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 189,
      sppl_prcp: 22.538,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '6HHL768J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HHL768J'
    },
    {
      status: 210,
      sku: 'PM0072384',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244795',
      rtl_batch_array: '6244795',
      name_search: 'FICBTURK',
      name: "Ficus carica 'Brown Turkey'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 2.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DH7T8VNW;5YPR5G21;6H61HPED;4T289JRE;XN7L64H9;64GH657S;SDHBXYBS',
      statusContent: 'dot',
      hash: 'Z941R8JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z941R8JE'
    },
    {
      status: 210,
      sku: 'PM0072385',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244817',
      rtl_batch_array: '6244817',
      name_search: 'MADELSTA',
      name: "Malus domestica 'Elstar'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDZVRK7Z;K8E21E1P;1EA2W54T;BGJSBCKX;8X1PRC56;ZLBZL51Y;XKS9C7DG;GSDJP9H4;S9A2PJ6H;7N5SWA3C;BJVDKDWY;HX39YY72;45Y52SND;81N2XLE6;74E5CN34;GR6X6C86;PDSEXGJ1;HRDKVDTH;WSNA7K9C',
      statusContent: 'dot',
      hash: '6K4NSH1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6K4NSH1P'
    },
    {
      status: 210,
      sku: 'PM0072389',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244821',
      rtl_batch_array: '6244821',
      name_search: 'PRDVICTO',
      name: "Prunus domestica 'Victoria'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 4.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EL659B3C;SBR37DG7;RPB57WX8;TT5YYXB2;4JRSHGCY;TAGW217X;HVA5V1LV;K33JSX8W;DZNB6G86;XDYXABWY;94RR2AZB;2VXHT89S;J6WAN3K6',
      statusContent: 'dot',
      hash: 'JN1CJGY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN1CJGY9'
    },
    {
      status: 210,
      sku: 'PM0072368',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244759',
      rtl_batch_array: '6244759',
      name_search: 'RUFLNESS',
      name: "Rubus fruticosus 'Loch Ness'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GT2S4HJ6;ZH78XTCX;LZDXZ7GS;HN4WPNT9',
      statusContent: 'dot',
      hash: 'STYZX57L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STYZX57L'
    },
    {
      status: 210,
      sku: 'PM0072370',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244763',
      rtl_batch_array: '6244763',
      name_search: 'RUFTEVER',
      name: "Rubus fruticosus 'Thornless Evergreen'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GDVL76YR;JYNSTL1S;JH6JBD75;CP52JXB9;6GW2GWA4;LKA2YTKC;X34P75LV',
      statusContent: 'dot',
      hash: 'V2CERZ2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2CERZ2J'
    },
    {
      status: 210,
      sku: 'PM0072367',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244755',
      rtl_batch_array: '6244755',
      name_search: 'RUTAYBER',
      name: "Rubus 'Tayberry'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CHGWC4XH;LLZGNB83;VTE7K7AG;XG4SVPDK;7XXYBJ3E;W4G8GVZ2;C1C74ZLL;2Z1PXS2V;ZVED92CZ;8HTE5JA2;S3NC288X;N66H5P1V;7J82YAJY;18458AHS',
      statusContent: 'dot',
      hash: 'KER3ACP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KER3ACP4'
    },
    {
      status: 210,
      sku: 'PM0072366',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244754',
      rtl_batch_array: '6244754',
      name_search: 'RUPHOENI',
      name: 'Rubus phoenicolasius',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2P231P7N;DTTBSSCE;E23ZC7CK;VCJBAN7X;49YW3JGG;R83VYYRN;C5158JEG;DE7XZYRE;GA3NZT1H;PB22HCVC;2D2TG2ND;KVD43S6X;V7LVC19X;29AZ1JLJ;VNCVBEGT;D3R8P1DP;RXLGBPH1;D8WV9LWP;77HBPB6K',
      statusContent: 'dot',
      hash: 'XBJZ26VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBJZ26VX'
    },
    {
      status: 210,
      sku: 'PM0072374',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244778',
      rtl_batch_array: '6244778',
      name_search: 'VIHIMROD',
      name: "Vitis 'Himrod'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V4R7AN85',
      statusContent: 'dot',
      hash: 'S1K65WD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1K65WD7'
    },
    {
      status: 210,
      sku: 'PM0072376',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244782',
      rtl_batch_array: '6244782',
      name_search: 'VITPHOEN',
      name: "Vitis 'Phoenix'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PDJ98N82;NJA4SD2C;B2YL3BT3',
      statusContent: 'dot',
      hash: 'C3AR3SLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3AR3SLK'
    },
    {
      status: 210,
      sku: 'PM0072377',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244784',
      rtl_batch_array: '6244784',
      name_search: 'VISOLARI',
      name: "Vitis 'Solaris'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PGEV7LRW',
      statusContent: 'dot',
      hash: 'E17XDR76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E17XDR76'
    },
    {
      status: 210,
      sku: 'PM0074716',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284292',
      rtl_batch_array: '6284292',
      name_search: 'AQEWHITE',
      name: 'Aquilegia Earlybird White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 712,
      chnn_stock_retail: 712,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJ7ZRNT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ7ZRNT2'
    },
    {
      status: 210,
      sku: 'PM0010447',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274785',
      rtl_batch_array: '6226109, 6274785',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 1355,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'CEGLRT5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEGLRT5V'
    },
    {
      status: 810,
      sku: 'PM0072402',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244905',
      rtl_batch_array: '6244905',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.422,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: '1SPB76R2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SPB76R2'
    },
    {
      status: 810,
      sku: 'PM0026412',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496712',
      rtl_batch_array: '5496712',
      name_search: 'HYAGRAND',
      name: "Hydrangea arborescens 'Grandiflora'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.542,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PEA1EGH4',
      statusContent: 'dot',
      hash: '1KWNSJPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KWNSJPN'
    },
    {
      status: 210,
      sku: 'PM0074717',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289991',
      rtl_batch_array: '6289991',
      name_search: 'AQEYELLO',
      name: 'Aquilegia Earlybird Yellow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZGKA86N3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGKA86N3'
    },
    {
      status: 210,
      sku: 'PM0018636',
      core_name: 'Plant',
      sppl_ean: '8720349463152',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439465',
      rtl_batch_array: '5439465',
      name_search: 'ROSACMEI',
      name: 'Rosa (H) Colossal Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 945,
      chnn_stock_retail: 945,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EA6C7LVE',
      statusContent: 'dot',
      hash: 'VCHRR5Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCHRR5Z1'
    },
    {
      status: 810,
      sku: 'PM0082563',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353295',
      rtl_batch_array: '6353295',
      name_search: 'SEECEDGE',
      name: "Sedum ellacombianum 'Cutting Edge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVAA5Y6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVAA5Y6E'
    },
    {
      status: 210,
      sku: 'PM0072431',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254670',
      rtl_batch_array: '6254670',
      name_search: 'BRMMMORS',
      name: "Brunnera macrophylla 'Mister Morse'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_prcp: 2.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3Y8P5L5;ZJSL8ZGV;6GCV3HT7;B3AW65Y2;6JPTEGJP;7JBA2CPC;5WHWAHE6',
      statusContent: 'dot',
      hash: 'EA615S72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA615S72'
    },
    {
      status: 210,
      sku: 'PM0074757',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274743',
      rtl_batch_array: '6274743',
      name_search: 'HYMBLAUM',
      name: "Hydrangea macrophylla 'Blaumeise'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GZZX7N8;ESE927KP;KYN2CXA5;AT3DLTTN;B7H86E48;4SSHW2B7;8JBJAJDX;NLW6SNC5;YWT46E3S;HK21GA6R;CNK7H6AX;GGNAZBCJ;HDNLVN2C',
      statusContent: 'dot',
      hash: 'V7TKELRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7TKELRP'
    },
    {
      status: 210,
      sku: 'PM0072423',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252527',
      rtl_batch_array: '6252527',
      name_search: 'DECBRONZ',
      name: "Deschampsia cespitosa 'Bronzeschleier'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WXB4CWLY',
      statusContent: 'dot',
      hash: 'BDKTZRPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDKTZRPD'
    },
    {
      status: 210,
      sku: 'PM0016199',
      core_name: 'Plant',
      sppl_ean: '8720349448852',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '5479153',
      rtl_batch_array: '5479153',
      name_search: 'ILCTWIGG',
      name: "Ilex crenata 'Twiggy'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YD69E5SD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD69E5SD'
    },
    {
      status: 210,
      sku: 'PM0074753',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348788',
      rtl_batch_array: '6348788',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.998,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'Z7VRRAY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z7VRRAY7'
    },
    {
      status: 210,
      sku: 'PM0074720',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267540',
      rtl_batch_array: '6267540',
      name_search: 'BERBERIS',
      name: 'Berberis in cv',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HS7GK5XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HS7GK5XA'
    },
    {
      status: 210,
      sku: 'PM0080214',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336230',
      rtl_batch_array: '6336230',
      name_search: 'CAVATHEN',
      name: "Calluna vulgaris 'Athene'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 4522,
      chnn_stock_retail: 4522,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VEYJK3VX;YE6H7K49;1CRN59LS;K1N2Y4NP;5YTTE3TP;K9CB4DJG;DNZ4TEZG;18SWTY92',
      statusContent: 'dot',
      hash: '8YPLZKXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YPLZKXW'
    },
    {
      status: 210,
      sku: 'PM0025226',
      core_name: 'Plant',
      sppl_ean: '8720626376380',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488252',
      rtl_batch_array: '5488252',
      name_search: 'WEWFIRE',
      name: "Weigela 'Wings of Fire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X9W9Y2Y;DDRGK9JS;S1RAXPG6;WBPHS4XN;P9W6BXY9;BJXXHYSG',
      statusContent: 'dot',
      hash: '4ASKK2BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ASKK2BH'
    },
    {
      status: 210,
      sku: 'PM0074796',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275091',
      rtl_batch_array: '6275091',
      name_search: 'BUUMBELL',
      name: 'Butomus umbellatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B7V9THRN;8N2BC9BP;9P21HBGY;KHVVP61G;K5GE5EPB;BLK7ZLLE;74VWYYT2;P3E9TKX3;S2T7PH64;PS77YZ63;2BTTBCAA;6644VG9B;BJR4V5S5;E5X2JNV8',
      statusContent: 'dot',
      hash: 'Y91JT7KW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y91JT7KW'
    },
    {
      status: 210,
      sku: 'PM0074723',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6371162',
      rtl_batch_array: '6371162, 6193606',
      name_search: 'EQJAPONI',
      name: 'Equisetum japonicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 1300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LCXWAEVC;CH9815T5;D5HKGN7E;SD41BAL2;BG8T3VLN',
      statusContent: 'dot',
      hash: 'WZT1H18D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZT1H18D'
    },
    {
      status: 210,
      sku: 'PM0082564',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353334',
      rtl_batch_array: '6353334',
      name_search: 'SAOGFRIE',
      name: "Salvia off. 'Grower's Friend'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97RR2ZRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97RR2ZRD'
    },
    {
      status: 210,
      sku: 'PM0080215',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336232',
      rtl_batch_array: '6336232',
      name_search: 'CAVELISA',
      name: "Calluna vulgaris 'Elisa'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 4554,
      chnn_stock_retail: 4554,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H928L95A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H928L95A'
    },
    {
      status: 810,
      sku: 'PM0082565',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353337',
      rtl_batch_array: '6353337',
      name_search: 'SAPFANCY',
      name: "Salvia 'Phyllis Fancy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75PC5ETH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75PC5ETH'
    },
    {
      status: 210,
      sku: 'PM0032219',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6234365',
      rtl_batch_array: '6234365, 6258801',
      name_search: 'LEABDUNE',
      name: "Leymus arenarius 'Blue Dune'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 149,
      chnn_stock_retail: 958,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4SB15NT;LK9B89KX',
      statusContent: 'dot',
      hash: 'T8EW47CC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8EW47CC'
    },
    {
      status: 210,
      sku: 'PM0025473',
      core_name: 'Plant',
      sppl_ean: '8720664699199',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488231',
      rtl_batch_array: '5488231',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 489,
      chnn_stock_retail: 489,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'GCTHVSWZ',
      statusContent: 'dot',
      hash: '6YTHABW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YTHABW1'
    },
    {
      status: 210,
      sku: 'PM0080216',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336233',
      rtl_batch_array: '6336233',
      name_search: 'CAVGINA',
      name: "Calluna vulgaris 'Gina'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 746,
      chnn_stock_retail: 746,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PJ879VSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJ879VSV'
    },
    {
      status: 210,
      sku: 'PM0028842',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356129',
      rtl_batch_array: '5420628, 6356129',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1460,
      chnn_stock_retail: 3460,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'RABV8DG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RABV8DG8'
    },
    {
      status: 210,
      sku: 'PM0023085',
      core_name: 'Plant',
      sppl_ean: '8720664517622',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5571303',
      rtl_batch_array: '5571303',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_prcp: 5.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      imageBatch: 'E55DYLDK',
      statusContent: 'dot',
      hash: 'KVJGYG42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVJGYG42'
    },
    {
      status: 910,
      sku: 'PM0072424',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CYSCOPAR',
      name: 'Cytisus scoparius',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      imageCore: 'PB1BT1A9',
      statusContent: 'dot',
      hash: 'VSGT188B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VSGT188B'
    },
    {
      status: 210,
      sku: 'PM0074751',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 3,
      btch_active_retail: '6274734',
      rtl_batch_array: '6346654, 5420641, 6274734',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 3990,
      sppl_order_minimum: 3,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: 'VJ7N67LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ7N67LD'
    },
    {
      status: 210,
      sku: 'PM0072427',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363415',
      rtl_batch_array: '5479980, 6363415',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1494,
      chnn_stock_retail: 1989,
      sppl_prcp: 6.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'WH9E3VW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH9E3VW1'
    },
    {
      status: 210,
      sku: 'PM0082566',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353347',
      rtl_batch_array: '6353347',
      name_search: 'SAVALBA',
      name: "Salvia verticillata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WPAE5HXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPAE5HXJ'
    },
    {
      status: 210,
      sku: 'PM0029942',
      core_name: 'Plant',
      sppl_ean: '8720349461790',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488266',
      rtl_batch_array: '5488266',
      name_search: 'PHOSWINE',
      name: 'Physocarpus opulifolius Summer Wine',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJBWSWTW;PAZLGJK7;WAP8SBGR;PYSTNZDL;CB5NHEEY;EYCGXX44',
      statusContent: 'dot',
      hash: 'Y3A6ASRE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3A6ASRE'
    },
    {
      status: 210,
      sku: 'PM0074802',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275103',
      rtl_batch_array: '6275103',
      name_search: 'METRIFOL',
      name: 'Menyanthes trifoliata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 745,
      chnn_stock_retail: 745,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '816KNHZ7;1GJ355NS;HRN4JPH1;SWHJWT1V;XZS1TKZY',
      statusContent: 'dot',
      hash: 'SYNGGB8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYNGGB8G'
    },
    {
      status: 210,
      sku: 'PM0074810',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275113',
      rtl_batch_array: '6275113',
      name_search: 'NULUTEA',
      name: 'Nuphar lutea',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 5.212,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VBN18EY;KE5EYEPJ',
      statusContent: 'dot',
      hash: '2LD6T34N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LD6T34N'
    },
    {
      status: 210,
      sku: 'PM0072432',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252571',
      rtl_batch_array: '6252571',
      name_search: 'FUMMOLIN',
      name: 'Fuchsia magellanica molinae',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXH1WDWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXH1WDWV'
    },
    {
      status: 210,
      sku: 'PM0074795',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275089',
      rtl_batch_array: '6275089',
      name_search: 'NYATTRAC',
      name: "Nymphaea 'Attraction'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 6.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XBT111J6;6K3K1KL7;19X7A4CA;2XX2DWYY;7TCCW6DY;PRJ7KZG2',
      statusContent: 'dot',
      hash: 'TGY8R36R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGY8R36R'
    },
    {
      status: 210,
      sku: 'PM0080217',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336234',
      rtl_batch_array: '6336234',
      name_search: 'CAVGANGI',
      name: "Calluna vulgaris 'Golden Angie'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1EYLN6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1EYLN6N'
    },
    {
      status: 810,
      sku: 'PM0074748',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274729',
      rtl_batch_array: '6274729',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'WDCSEXTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDCSEXTZ'
    },
    {
      status: 210,
      sku: 'PM0080218',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336235',
      rtl_batch_array: '6336235',
      name_search: 'CAVHELEN',
      name: "Calluna vulgaris 'Helena'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 5099,
      chnn_stock_retail: 5099,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GAV93YR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAV93YR4'
    },
    {
      status: 210,
      sku: 'PM0074722',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133247',
      rtl_batch_array: '6133247',
      name_search: 'CHPOESIE',
      name: "Chrysanthemum (I) 'Poesie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XC2143DG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC2143DG'
    },
    {
      status: 210,
      sku: 'PM0080219',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336236',
      rtl_batch_array: '6336236',
      name_search: 'CAVHILDA',
      name: "Calluna vulgaris 'Hilda'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 4164,
      chnn_stock_retail: 4164,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WCGZYP18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCGZYP18'
    },
    {
      status: 210,
      sku: 'PM0080220',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336237',
      rtl_batch_array: '6336237',
      name_search: 'CAVROSIT',
      name: "Calluna vulgaris 'Rosita'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 2651,
      chnn_stock_retail: 2651,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EDBDGN9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDBDGN9W'
    },
    {
      status: 210,
      sku: 'PM0074727',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193686',
      rtl_batch_array: '6193686',
      name_search: 'SPERECTU',
      name: 'Sparganium erectum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1SG35XJW;CAWC1R21;HDH63VT5;WWVEEWXC',
      statusContent: 'dot',
      hash: 'WVTXC8JV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVTXC8JV'
    },
    {
      status: 210,
      sku: 'PM0018987',
      core_name: 'Garden',
      sppl_ean: '8720349416851',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 5,
      btch_active_retail: '2717282',
      rtl_batch_array: '2624747, 2717282, 4858157, 4713713, 4858178',
      name_search: 'GRAS',
      name: 'Gras in soorten',
      sppl_size_code: 'LEVC2',
      sppl_stock_available: 988,
      chnn_stock_retail: 3488,
      sppl_prcp: 2.168,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      imageBatch: 'D466TXHR',
      statusContent: 'dot',
      hash: 'TXY2KBER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'TXY2KBER'
    },
    {
      status: 210,
      sku: 'PM0072425',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6245489',
      rtl_batch_array: '6245489',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '6CCZ2KD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CCZ2KD4'
    },
    {
      status: 210,
      sku: 'PM0074736',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259668',
      rtl_batch_array: '6259668',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: 'EJWS6375',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJWS6375'
    },
    {
      status: 210,
      sku: 'PM0072481',
      core_name: 'Plant',
      sppl_ean: '8720664886223',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6165993',
      rtl_batch_array: '6165993',
      name_search: 'SEHEUFLE',
      name: 'Sesleria heufleriana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N6YKG48D',
      statusContent: 'dot',
      hash: 'HZ8T8NGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZ8T8NGE'
    },
    {
      status: 210,
      sku: 'PM0082567',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353358',
      rtl_batch_array: '6353358, 6320895',
      name_search: 'SACMAIGR',
      name: "Saxifraga cortusifolia 'Maigr\u00fcn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 174,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5VJGCRL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VJGCRL1'
    },
    {
      status: 210,
      sku: 'PM0072426',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6245490',
      rtl_batch_array: '6245490',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'L7ET7SA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7ET7SA8'
    },
    {
      status: 210,
      sku: 'PM0072419',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254826',
      rtl_batch_array: '6266261, 6254826',
      name_search: 'SAGALIPS',
      name: "Salvia greggii 'Amethyst Lips'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 190,
      chnn_stock_retail: 522,
      sppl_prcp: 2.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JT36YKC;N3DZ2VVS;5W1X2ZSE;1X1ASKSA',
      statusContent: 'dot',
      hash: '4WYEPS3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WYEPS3R'
    },
    {
      status: 210,
      sku: 'PM0082568',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217078',
      rtl_batch_array: '6217078',
      name_search: 'SACWADA',
      name: "Saxifraga c. 'Wada'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 159,
      chnn_stock_retail: 159,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXGLEKTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXGLEKTW'
    },
    {
      status: 210,
      sku: 'PM0082569',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353367',
      rtl_batch_array: '6353367',
      name_search: 'TAALBIDU',
      name: 'Taraxacum albidum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9X9B7XTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X9B7XTP'
    },
    {
      status: 210,
      sku: 'PM0074728',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193723',
      rtl_batch_array: '6193723',
      name_search: 'TYMINIMA',
      name: 'Typha minima',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N3E2ZGGV;CSLT2T3R;C743VCT1;4JSHYY4A;GCZER8WX;A47DSEZW',
      statusContent: 'dot',
      hash: 'C9DBVN8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9DBVN8C'
    },
    {
      status: 210,
      sku: 'PM0074729',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193764',
      rtl_batch_array: '6193764',
      name_search: 'EDI86989',
      name: "Canna 'Happy Cleo'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9B4HZP2W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B4HZP2W'
    },
    {
      status: 210,
      sku: 'PM0074730',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193765',
      rtl_batch_array: '6193765',
      name_search: 'EDI86990',
      name: "Canna 'Happy Emily'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KEBRJCS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEBRJCS7'
    },
    {
      status: 210,
      sku: 'PM0072477',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6248955',
      rtl_batch_array: '6248955',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: '030040P9',
      rng_range_identifier: 'H030040P9',
      rng_range_description: 'H30 cm 40 cm P9',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_order_minimum: 6,
      sppl_prcp: 0.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: 'A8CB49CV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8CB49CV'
    },
    {
      status: 210,
      sku: 'PM0074731',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193766',
      rtl_batch_array: '6193766',
      name_search: 'EDI87021',
      name: "Canna 'Happy Isabel'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LA4NJ2AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA4NJ2AW'
    },
    {
      status: 210,
      sku: 'PM0082570',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353369',
      rtl_batch_array: '6353369',
      name_search: 'TAPSEUDO',
      name: 'Taraxacum pseudoroseum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H431K6YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H431K6YA'
    },
    {
      status: 210,
      sku: 'PM0074733',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193827',
      rtl_batch_array: '6193827',
      name_search: 'EDI73427',
      name: 'Tulbaghia violacae violacea',
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DAG2CTE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAG2CTE9'
    },
    {
      status: 210,
      sku: 'PM0074734',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259661',
      rtl_batch_array: '6259661',
      name_search: 'RIUCHGRO',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gr\u00f6n',
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRH69A6W',
      statusContent: 'dot',
      hash: 'CTL39ELD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTL39ELD'
    },
    {
      status: 210,
      sku: 'PM0074735',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259666',
      rtl_batch_array: '6259666',
      name_search: 'RUIHERIT',
      name: "Rubus idaeus 'H\u00e9ritage'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JBSG2NX4;Z7BCHAJD;6LB4VLLL;16JRX11D;JKZGZWKE;DC983CYP;A15DAZ3C;CY1XHS6D;Z19YL8TV;J5A7B646;EEWTXCY3;P8JJ3HG2',
      statusContent: 'dot',
      hash: '8N7ZVBY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8N7ZVBY3'
    },
    {
      status: 210,
      sku: 'PM0074737',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239673',
      rtl_batch_array: '6239673',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 831,
      chnn_stock_retail: 831,
      sppl_prcp: 1.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: '7XRVWR2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XRVWR2B'
    },
    {
      status: 210,
      sku: 'PM0074738',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274522',
      rtl_batch_array: '6274522',
      name_search: 'BUDWFPOW',
      name: "Buddleja weyeriana 'Flower Power'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1560,
      chnn_stock_retail: 1560,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V6LS63EP;BLAL1XLT;ZH17R6WH;DHJZ9BX4;D38JR32Y;YZ9SDLYE;AWNPBXSK;6KZSHGC3;9XR7P9S1;L7BEDJL8;HL2CGBTW;RBSBP6HV;DWYY3NXE;DPZZJDPJ;K93KCZR7;ZLJHA95N',
      statusContent: 'dot',
      hash: '6B5J3Y1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6B5J3Y1K'
    },
    {
      status: 810,
      sku: 'PM0074741',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274530',
      rtl_batch_array: '6274530',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: '9HV3A4GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HV3A4GD'
    },
    {
      status: 210,
      sku: 'PM0080209',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336225',
      rtl_batch_array: '6336225',
      name_search: 'BUDBCLRU',
      name: 'Buddleja d. B. Candy Little Ruby',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2963,
      chnn_stock_retail: 2963,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJ7GK56B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJ7GK56B'
    },
    {
      status: 210,
      sku: 'PM0072420',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252757',
      rtl_batch_array: '6252757',
      name_search: 'SAOTRICO',
      name: "Salvia officinalis 'Tricolor'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XLDWN2H;7VK6YDGE;XRY9KBRB;DJ19A1LK;5Z3A9KJW;NV1C1LEK;9GT8AS65;TJCY7S4Y;V3DNCDS3;N6B83T21;N68LEDA5;AB7JDYH3;94RNWX5X;CCWX6D68;4A8EGX1G;PHBR2JGX;WNL2T3XW;SANZY739;HKLS561Y;X32SRKBN;XEERDPBV;DC8SZPB1',
      statusContent: 'dot',
      hash: '73JTPYKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73JTPYKD'
    },
    {
      status: 210,
      sku: 'PM0072430',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254838',
      rtl_batch_array: '6254838',
      name_search: 'SEJAUBER',
      name: "Sedum 'Jos\u00e9 Aubergine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_prcp: 2.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L65PJD6H',
      statusContent: 'dot',
      hash: 'NXN9ZWSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NXN9ZWSV'
    },
    {
      status: 210,
      sku: 'PM0074806',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349154',
      rtl_batch_array: '6349154, 6330121, 6301894',
      name_search: 'VAOFFICI',
      name: 'Valeriana officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1032,
      chnn_stock_retail: 2201,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KK1VKY4P;8DYA7H2N;RA7B4XCG;GH7GBJHH;BNKXCV9A;Z964GY1A;5AX3XCNA;WT16SWZY;92DBK7N1;KGDEKBBP;W4PEPXD2;6BZL8XND;AP3EX6KD;A8EA2ARA;GKEHWEXH;XC9RWYSP;C3278T8V;4SPWE9CV',
      statusContent: 'dot',
      hash: 'KPX54BZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPX54BZB'
    },
    {
      status: 210,
      sku: 'PM0072422',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254900',
      rtl_batch_array: '6254900, 6254687',
      name_search: 'CEPLUMBA',
      name: 'Ceratostigma plumbaginoides',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3002,
      chnn_stock_retail: 4495,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JDPSCTN2;PGN26CCN',
      statusContent: 'dot',
      hash: 'CEZRC1SJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEZRC1SJ'
    },
    {
      status: 210,
      sku: 'PM0072421',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252806',
      rtl_batch_array: '6252806',
      name_search: 'WATERNAT',
      name: 'Waldsteinia ternata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GGTG67JR;JH3S8P8S;WWLKCH3X;CNZ8KPNP;WK3VV735;BTYE2CC8;PKLJ2LZB;86NNZJZJ',
      statusContent: 'dot',
      hash: 'SSVPBX4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSVPBX4H'
    },
    {
      status: 210,
      sku: 'PM0080221',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336238',
      rtl_batch_array: '6336238',
      name_search: 'CAVTGIRL',
      name: "Calluna vulgaris 'Twin Girls'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 9825,
      chnn_stock_retail: 9825,
      sppl_order_minimum: 3,
      sppl_prcp: 1.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AK5133VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AK5133VB'
    },
    {
      status: 210,
      sku: 'PM0072417',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252610',
      rtl_batch_array: '6252610',
      name_search: 'HEARGUTI',
      name: 'Helleborus argutifolius',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3964,
      chnn_stock_retail: 3964,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BEHY7658;CEK54WNB;4SAAJR7S',
      statusContent: 'dot',
      hash: 'S6HHP8W7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6HHP8W7'
    },
    {
      status: 910,
      sku: 'PM0074594',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HOPATRIO',
      name: "Hosta 'Patriot'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'LC8R96LY;2JEH4V3Z;TH34A1H6;RTN2DN2K;87X8C2TZ;V2EW727A;R53BPL4A;SPP13VWH;Y6X6JR79;BTEPCS7A;A9Z9JPEZ;1KLDXJG5;R7R3BT28;ETLSSJ7V;CXWCTYBJ;A5D4VH3T',
      statusContent: 'dot',
      hash: 'XDSB6NLH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XDSB6NLH'
    },
    {
      status: 210,
      sku: 'PM0074755',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274741',
      rtl_batch_array: '6274741',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: 'HW5EG8GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HW5EG8GK'
    },
    {
      status: 210,
      sku: 'PM0074743',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274545',
      rtl_batch_array: '6274545',
      name_search: 'PHTAMAZO',
      name: "Phlomis tuberosa 'Amazone'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DCZBZWL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCZBZWL9'
    },
    {
      status: 210,
      sku: 'PM0082571',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353394',
      rtl_batch_array: '6353394',
      name_search: 'THKUNTHI',
      name: 'Thelypteris kunthii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D7XZ2BGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7XZ2BGH'
    },
    {
      status: 210,
      sku: 'PM0074745',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274724',
      rtl_batch_array: '6274724',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'VWH371CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWH371CJ'
    },
    {
      status: 810,
      sku: 'PM0074746',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274725',
      rtl_batch_array: '6274725',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'EDHSBY66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDHSBY66'
    },
    {
      status: 810,
      sku: 'PM0074747',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274728',
      rtl_batch_array: '6274728',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: 'ADX73YN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADX73YN8'
    },
    {
      status: 210,
      sku: 'PM0074750',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274731',
      rtl_batch_array: '6274731',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: '020030C5OP',
      rng_range_identifier: 'H020030C5PO',
      rng_range_description: 'H20 cm 30 cm C5 Potted',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_prcp: 3.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      statusContent: 'dot',
      hash: '21B1ZBSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21B1ZBSV'
    },
    {
      status: 210,
      sku: 'PM0080222',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336239',
      rtl_batch_array: '6336239',
      name_search: 'CABROWNI',
      name: 'Calocephalus brownii',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R895EV1L;RDA97T7R;PVLASW79;TSABGKCK;JKZAX7P8',
      statusContent: 'dot',
      hash: 'HC9HP9RE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HC9HP9RE'
    },
    {
      status: 210,
      sku: 'PM0080223',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336240',
      rtl_batch_array: '6336240',
      name_search: 'CAVENUS',
      name: "Calycanthus 'Venus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 274,
      chnn_stock_retail: 274,
      sppl_prcp: 4.221,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8XVRWP1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XVRWP1L'
    },
    {
      status: 210,
      sku: 'PM0072483',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6230490',
      rtl_batch_array: '6230490, 6301428, 6267751',
      name_search: 'ASDTRADE',
      name: "Aster divaricatus 'Tradescant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 284,
      chnn_stock_retail: 642,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R27L8BBV',
      statusContent: 'dot',
      hash: 'RKTYCJE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKTYCJE1'
    },
    {
      status: 910,
      sku: 'PM0072480',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EDI70285',
      name: "Ipheion uniflorum 'Charlotte Bishop'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'Z1Z33LLJ;DNKPZ856',
      statusContent: 'dot',
      hash: 'S1HEBB91',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S1HEBB91'
    },
    {
      status: 210,
      sku: 'PM0072486',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249948',
      rtl_batch_array: '6249948',
      name_search: 'MOCSCARL',
      name: "Monarda 'Cambridge Scarlet'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4457,
      chnn_stock_retail: 4457,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CTKLX8JS;L6632JEY;K3EBS9CS;R9LLKCAZ;YD9B327W;HJXCDTR8;NA6YH63N;4234A6DR;VN948NXT;DJWCWRZ2;2GAVVEWG;XNA7TCH6;R3BDPSGS;PNP8KPRZ;JNLZ3VYD;B51DZ86J;R5K5K777;9LHK3AZZ',
      statusContent: 'dot',
      hash: '6L2752AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L2752AP'
    },
    {
      status: 810,
      sku: 'PM0074752',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281005',
      rtl_batch_array: '6281005',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'VJPDY6K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJPDY6K8'
    },
    {
      status: 210,
      sku: 'PM0074756',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348791',
      rtl_batch_array: '6274742, 6348791',
      name_search: 'HYMBLAUM',
      name: "Hydrangea macrophylla 'Blaumeise'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 710,
      sppl_order_minimum: 5,
      sppl_prcp: 2.131,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GZZX7N8;ESE927KP;KYN2CXA5;AT3DLTTN;B7H86E48;4SSHW2B7;8JBJAJDX;NLW6SNC5;YWT46E3S;HK21GA6R;CNK7H6AX;GGNAZBCJ;HDNLVN2C',
      statusContent: 'dot',
      hash: 'P4BJVARB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4BJVARB'
    },
    {
      status: 810,
      sku: 'PM0074758',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370806',
      rtl_batch_array: '6370806, 6274744, 6294835',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 534,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: '2SKCPXZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SKCPXZ4'
    },
    {
      status: 210,
      sku: 'PM0080210',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336226',
      rtl_batch_array: '6336226',
      name_search: 'BUDBCLRU',
      name: 'Buddleja d. B. Candy Little Ruby',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1652,
      chnn_stock_retail: 1652,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1YALT7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1YALT7G'
    },
    {
      status: 910,
      sku: 'PM0072485',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307674',
      rtl_batch_array: '6307674',
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 21.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4YL4E8VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YL4E8VD'
    },
    {
      status: 210,
      sku: 'PM0032638',
      core_name: 'Plant',
      sppl_ean: '8720664683655',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4905161',
      rtl_batch_array: '4905161',
      name_search: 'CAMEVERG',
      name: "Carex morrowii 'Everglow'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.255,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LVY17TZ4;X4YD9ZLV;Z9Y4J9Y8;PZ94TYD3;NCVD5BGB;X95EHYNT',
      imageBatch: 'BR5L5B7L',
      statusContent: 'dot',
      hash: '53P3SHTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53P3SHTA'
    },
    {
      status: 210,
      sku: 'PM0074814',
      core_name: 'Plant',
      sppl_ean: '8720353089713',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '4416125',
      rtl_batch_array: '4416125',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '090100C15PY',
      rng_range_identifier: 'PYRAH090100C15',
      rng_range_description: 'Pyramid H90 cm 100 cm C15',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      imageBatch: 'JSGBK828',
      statusContent: 'dot',
      hash: 'ERNAG7VE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERNAG7VE'
    },
    {
      status: 210,
      sku: 'PM0080224',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336241',
      rtl_batch_array: '6336241',
      name_search: 'CAJAAUDU',
      name: "Camellia japonica 'Adolphe Audusson'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 790,
      chnn_stock_retail: 790,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XEBDREGZ;63AH44EP;SSLBZ9P7;JPDK58P5',
      statusContent: 'dot',
      hash: 'L9WHLS6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9WHLS6D'
    },
    {
      status: 210,
      sku: 'PM0074739',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274523',
      rtl_batch_array: '6274523',
      name_search: 'AGBADDER',
      name: "Agastache 'Black Adder'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 14188,
      chnn_stock_retail: 14188,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '951ZVWNH;67D4KK2D;CP4DB3EZ',
      statusContent: 'dot',
      hash: '8RXT5CWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RXT5CWC'
    },
    {
      status: 210,
      sku: 'PM0074740',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274525',
      rtl_batch_array: '6274525',
      name_search: 'ARDIOICU',
      name: 'Aruncus dioicus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 5230,
      chnn_stock_retail: 5230,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N43BAD8S;4NSCXXCJ;GGT35123;HVD1GX1T;WEWG9AX4;2GKJYJHX;D2R8D63S;P8H2L5B6;V16SSWK2;1D6EV6HT;P1KXKHTS',
      statusContent: 'dot',
      hash: '93AW3ARW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93AW3ARW'
    },
    {
      status: 210,
      sku: 'PM0074742',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274533',
      rtl_batch_array: '6274533',
      name_search: 'ECPFATTR',
      name: "Echinacea purpurea 'Fatal Attraction'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2574,
      chnn_stock_retail: 2574,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9LG9B6L1;65R4YGZW',
      statusContent: 'dot',
      hash: 'N55PBTWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N55PBTWJ'
    },
    {
      status: 810,
      sku: 'PM0021108',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271432',
      rtl_batch_array: '6271432, 5488230',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 52,
      chnn_stock_retail: 552,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: '9ZTRT5JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZTRT5JY'
    },
    {
      status: 210,
      sku: 'PM0012045',
      core_name: 'Plant',
      sppl_ean: '8720664853232',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084816',
      rtl_batch_array: '6084816, 6161495',
      name_search: 'ATNPLACE',
      name: "Athyrium niponicum 'Pewter Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 957,
      chnn_stock_retail: 2149,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A2NN7B83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2NN7B83'
    },
    {
      status: 210,
      sku: 'PM0072435',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252615',
      rtl_batch_array: '5997285, 6252615',
      name_search: 'HEORIENT',
      name: 'Helleborus orientalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1959,
      chnn_stock_retail: 6880,
      sppl_prcp: 2.215,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NC1TLY2;7HSSH1HE;ACBH9CGX;91X5KHCH;PEJ2VTTZ;W1LTW742;4K99Y91E;SDGW7LLA;AHGP9HET;JD8WZK7Y;CSDBLDSC;L8J9GYER;PLRZ76GS;HGDYR41W;JELJR4HA;81WBW2HB;SASC86EP;H6K98P4P;D1W6VBS3;284KPGP7;GLC3E3ZN;X1KWTJZS;4AGD9WG2',
      statusContent: 'dot',
      hash: '1J7C9RA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1J7C9RA8'
    },
    {
      status: 210,
      sku: 'PM0072429',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254802',
      rtl_batch_array: '6254802',
      name_search: 'PEMACROU',
      name: 'Pennisetum macrourum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_prcp: 2.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '17TGLXP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17TGLXP5'
    },
    {
      status: 210,
      sku: 'PM0072418',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 4,
      btch_active_retail: '6252747',
      rtl_batch_array: '6252747, 6253121, 6292686, 6338463',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1887,
      chnn_stock_retail: 9487,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: 'NWD73VXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWD73VXS'
    },
    {
      status: 210,
      sku: 'PM0072482',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176450',
      rtl_batch_array: '6176450',
      name_search: 'GLCALBA',
      name: "Globularia cordifolia 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WVP8SSH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVP8SSH4'
    },
    {
      status: 210,
      sku: 'PM0072434',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254750',
      rtl_batch_array: '6254750',
      name_search: 'HEERARED',
      name: "Helleborus Frostkiss Anna's Red",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 4.653,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '57ED1VTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57ED1VTB'
    },
    {
      status: 210,
      sku: 'PM0010291',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6255113',
      rtl_batch_array: '5606402, 6255113',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 1871,
      chnn_stock_retail: 3810,
      sppl_order_minimum: 3,
      sppl_prcp: 0.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'Z6D47LPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6D47LPC'
    },
    {
      status: 210,
      sku: 'PM0074721',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274077',
      rtl_batch_array: '6274077',
      name_search: 'PITIPATE',
      name: "Pittosporum tenuifolium 'Irene Paterson'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.865,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PCNW95P;GVWRT6BK;RPVETT1N;KWVWP6Z4',
      statusContent: 'dot',
      hash: '68A155XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68A155XE'
    },
    {
      status: 210,
      sku: 'PM0080211',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336227',
      rtl_batch_array: '6336227',
      name_search: 'BUDBCLWH',
      name: 'Buddleja d. B. Candy Little White',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 15.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B19JXDDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B19JXDDB'
    },
    {
      status: 210,
      sku: 'PM0080212',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336228',
      rtl_batch_array: '6336228',
      name_search: 'CAVALICI',
      name: "Calluna vulgaris 'Alicia'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 3667,
      chnn_stock_retail: 3667,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EN5EYW99',
      statusContent: 'dot',
      hash: 'AKGV2PAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKGV2PAS'
    },
    {
      status: 210,
      sku: 'PM0080213',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336229',
      rtl_batch_array: '6336229',
      name_search: 'CAVAMETH',
      name: "Calluna vulgaris 'Amethyst'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 4088,
      chnn_stock_retail: 4088,
      sppl_order_minimum: 3,
      sppl_prcp: 1.161,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '952CALCB;KRGGJD3W;WNKXCZ6S;ZLGZNCVS;6SAVZPZX;AYK3TREP;9XL6X34J;T14E9HWB',
      statusContent: 'dot',
      hash: 'AZ35KA9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ35KA9L'
    },
    {
      status: 210,
      sku: 'PM0080225',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336242',
      rtl_batch_array: '6336242',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: 'DLYTK4RE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLYTK4RE'
    },
    {
      status: 210,
      sku: 'PM0080226',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336243',
      rtl_batch_array: '6336243',
      name_search: 'CAJBYELL',
      name: "Camellia japonica 'Brushfield's Yellow'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6ZLZ48A;9KNXTTVB;TD3XAJD1;NTG3LD66;PS86LZWJ;46A7G9CE;EEDNZWXD',
      statusContent: 'dot',
      hash: 'SYBBVHY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYBBVHY4'
    },
    {
      status: 210,
      sku: 'PM0080227',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336244',
      rtl_batch_array: '6336244',
      name_search: 'CAJCLADY',
      name: "Camellia japonica 'Curly Lady'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 743,
      chnn_stock_retail: 743,
      sppl_prcp: 10.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6ZX7LLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6ZX7LLX'
    },
    {
      status: 210,
      sku: 'PM0080228',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336245',
      rtl_batch_array: '6336245',
      name_search: 'CAJDKING',
      name: "Camellia japonica 'Dr King'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z84V5AG7;KBH3YV7B;G6BLA2AS;P3XY1A7H;7TYH9PD4;P96HL321;CL6RG5BV;PDLSZJYK;1BNBP9XA;8LCXKEXS;6SKS2JC2;Y7DBVSXD;7B1PATVE;HAYAYEHY;KPHV6YXN',
      statusContent: 'dot',
      hash: 'HA9J5T88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HA9J5T88'
    },
    {
      status: 210,
      sku: 'PM0080229',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336246',
      rtl_batch_array: '6336246',
      name_search: 'CAJLCAMP',
      name: "Camellia japonica 'Lady Campbell'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1170,
      chnn_stock_retail: 1170,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C9ASV2H;CHCP2CPX;CE6YKGT8;L6E2DXRC;LV1JYG15',
      statusContent: 'dot',
      hash: 'AJKTRC94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJKTRC94'
    },
    {
      status: 210,
      sku: 'PM0080230',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336247',
      rtl_batch_array: '6336247',
      name_search: 'CAJPPUFF',
      name: "Camellia japonica 'Powder Puff'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1455,
      chnn_stock_retail: 1455,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DE4B2R57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE4B2R57'
    },
    {
      status: 210,
      sku: 'PM0080231',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336248',
      rtl_batch_array: '6336248',
      name_search: 'CARMWILL',
      name: "Camellia reticulata 'Mary Williams'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1220,
      chnn_stock_retail: 1220,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TBGGGKAS;D5KZWAE1;2JYEBBA4;4WVZSEXV',
      statusContent: 'dot',
      hash: 'RHE66LAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHE66LAK'
    },
    {
      status: 210,
      sku: 'PM0080232',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336249',
      rtl_batch_array: '6336249',
      name_search: 'CASCLEOP',
      name: "Camellia sasanqua 'Cleopatra'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LSC3P8DA;4CS16X5Y;4RRAAE2Y',
      statusContent: 'dot',
      hash: 'AHX8GL37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHX8GL37'
    },
    {
      status: 210,
      sku: 'PM0080233',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336250',
      rtl_batch_array: '6336250',
      name_search: 'CASHIRYU',
      name: "Camellia sasanqua 'Hiryu'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EBS5DS5R',
      statusContent: 'dot',
      hash: '4Y9WD8WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y9WD8WL'
    },
    {
      status: 210,
      sku: 'PM0080234',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336251',
      rtl_batch_array: '6336251',
      name_search: 'CASNDAWN',
      name: "Camellia sasanqua 'New Dawn'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TG849RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TG849RX'
    },
    {
      status: 210,
      sku: 'PM0080235',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336252',
      rtl_batch_array: '6336252',
      name_search: 'CAWDEBBI',
      name: "Camellia williamsii 'Debbie'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 820,
      chnn_stock_retail: 820,
      sppl_prcp: 10.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J65WK1TV;87989NVK;1GLTY2EA',
      statusContent: 'dot',
      hash: 'G12EYRKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G12EYRKG'
    },
    {
      status: 210,
      sku: 'PM0080236',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336253',
      rtl_batch_array: '6336253',
      name_search: 'CABAUREO',
      name: "Carex brunnea 'Aureomarginata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3828,
      chnn_stock_retail: 3828,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8XY9WW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8XY9WW9'
    },
    {
      status: 210,
      sku: 'PM0074849',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274940',
      rtl_batch_array: '6274940',
      name_search: 'CAJPPUFF',
      name: "Camellia japonica 'Powder Puff'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 1197,
      chnn_stock_retail: 1197,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJ469BXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ469BXC'
    },
    {
      status: 210,
      sku: 'PM0080241',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336261',
      rtl_batch_array: '6336261',
      name_search: 'PHFRCOMP',
      name: "Photinia fraseri 'Robusta Compacta'",
      sppl_size_code: '040STC74',
      rng_range_identifier: 'STE040C7.5',
      rng_range_description: 'Stem 40 cm C7.5',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_prcp: 8.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GDAKJ64L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDAKJ64L'
    },
    {
      status: 210,
      sku: 'PM0009701',
      core_name: 'Plant',
      sppl_ean: '8720349428595',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5494242',
      rtl_batch_array: '5494242',
      name_search: 'SCCAUCAS',
      name: 'Scabiosa caucasica',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 204,
      chnn_stock_retail: 204,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E6Z5ZZXV;K34ZDPCD;1646Z9YT;X1DRW67R;WSVWZKD3',
      imageBatch: 'BJ8RX1E6',
      statusContent: 'dot',
      hash: '4N57CZ7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4N57CZ7J'
    },
    {
      status: 210,
      sku: 'PM0025835',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350606',
      rtl_batch_array: '6350606, 5495405',
      name_search: 'KNNORTHI',
      name: 'Kniphofia northiae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 245,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '64ADB3N6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64ADB3N6'
    },
    {
      status: 210,
      sku: 'PM0080242',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336262',
      rtl_batch_array: '6336262',
      name_search: 'PHFRCOMP',
      name: "Photinia fraseri 'Robusta Compacta'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 1388,
      chnn_stock_retail: 1388,
      sppl_prcp: 11.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JZVNREGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZVNREGB'
    },
    {
      status: 210,
      sku: 'PM0024584',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301943',
      rtl_batch_array: '6208110, 6301943, 6308667',
      name_search: 'STTENUIS',
      name: 'Stipa tenuissima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2921,
      chnn_stock_retail: 6833,
      sppl_order_minimum: 3,
      sppl_prcp: 0.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A7ZVRVJK;E3S68JGD;EX5956X1;RE6JJL7Z;VTRJTRGK;KDS2EWD5;XJ29TW5L;W7DG928J;RGHJXBN3;8T53B49P;DY3R511Y;JCC51YYL;N26HNB2C;R9NYZSGW;XZ61TJKJ;2HD5WW5T;5VZZS36C;13YJHWT1;X2PNV4VT;JWZA53PK;B61TLB9G;VA52X1KD;RERA1JG1',
      statusContent: 'dot',
      hash: 'BES9EAEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BES9EAEC'
    },
    {
      status: 210,
      sku: 'PM0080243',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336263',
      rtl_batch_array: '6336263',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 699,
      chnn_stock_retail: 699,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'K87Y6AL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K87Y6AL6'
    },
    {
      status: 210,
      sku: 'PM0082572',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353398',
      rtl_batch_array: '6353398',
      name_search: 'THRPDAVI',
      name: "Thymus richardii 'Peter Davis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 317,
      chnn_stock_retail: 317,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWKLEB2C;PC3NWWHY;RK9DX1B2;7KNHX2N7;ZPS349HW;9GXVR1EJ',
      statusContent: 'dot',
      hash: 'KXW6A5DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXW6A5DJ'
    },
    {
      status: 810,
      sku: 'PM0074760',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274746',
      rtl_batch_array: '6274746',
      name_search: 'HYMLIBEL',
      name: "Hydrangea macrophylla 'Libelle'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D32EL74L;ZDCX7HCW;PPGVSD31;87SBT7K6;WEGAXENS;6BCBCPWW;2TZ4DEE7;82K99P7A;VCXPTL8T;AY6YND7C;55V2VJX3;5224VEL6;477G57W9;2R1PPD42;5NDCBTSG;LE6GNY12;PTHA8NJV',
      statusContent: 'dot',
      hash: 'CGD9L87S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGD9L87S'
    },
    {
      status: 210,
      sku: 'PM0074763',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356150',
      rtl_batch_array: '6274749, 6356150',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 976,
      sppl_order_minimum: 5,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: '62EPZLS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62EPZLS1'
    },
    {
      status: 210,
      sku: 'PM0072476',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276615',
      rtl_batch_array: '6298502, 6276615',
      name_search: 'ABEGOUCH',
      name: "Abelia 'Edward Goucher'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2755,
      chnn_stock_retail: 5589,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKYDBZ5C;8WNRHDS9;63ABV37E;4C4KSXVN;44S1KAEP',
      statusContent: 'dot',
      hash: 'HRG764K9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRG764K9'
    },
    {
      status: 210,
      sku: 'PM0021117',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333314',
      rtl_batch_array: '6254426, 6330119, 6333314',
      name_search: 'TRHIRTA',
      name: 'Tricyrtis hirta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2573,
      chnn_stock_retail: 3273,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L4E5SL2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4E5SL2D'
    },
    {
      status: 210,
      sku: 'PM0080244',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336264',
      rtl_batch_array: '6336264',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1199,
      chnn_stock_retail: 1199,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'A1H9PR57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1H9PR57'
    },
    {
      status: 810,
      sku: 'PM0074828',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274914',
      rtl_batch_array: '6349172, 6274914',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 54,
      chnn_stock_retail: 104,
      sppl_prcp: 8.864,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: 'WYA31526',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYA31526'
    },
    {
      status: 210,
      sku: 'PM0016508',
      core_name: 'Plant',
      sppl_ean: '8720664693364',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495633',
      rtl_batch_array: '5495633',
      name_search: 'HYMWUDU',
      name: "Hydrangea macrophylla 'Wudu'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1312,
      chnn_stock_retail: 1312,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5JE9DJJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JE9DJJW'
    },
    {
      status: 210,
      sku: 'PM0072437',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6245924',
      rtl_batch_array: '6245924',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060080C7',
      rng_range_identifier: 'H060080C7',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 6.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '534VR6Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '534VR6Z4'
    },
    {
      status: 210,
      sku: 'PM0004207',
      core_name: 'Plant',
      sppl_ean: '8720353015767',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495291',
      rtl_batch_array: '5495291, 6320862',
      name_search: 'ECPMISTR',
      name: "Echinacea purpurea 'Mistral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 420,
      chnn_stock_retail: 830,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7AKPCSS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AKPCSS2'
    },
    {
      status: 210,
      sku: 'PM0015768',
      core_name: 'Plant',
      sppl_ean: '8720664682689',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495569',
      rtl_batch_array: '5495569',
      name_search: 'BUDFPINK',
      name: 'Buddleja davidii Flutterby Pink',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '83B1WX3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83B1WX3P'
    },
    {
      status: 210,
      sku: 'PM0080245',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336265',
      rtl_batch_array: '6336265',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1569,
      chnn_stock_retail: 1569,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: 'X3P3NAVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3P3NAVJ'
    },
    {
      status: 210,
      sku: 'PM0080246',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336266',
      rtl_batch_array: '6336266',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 6.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: 'SR9YXKWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SR9YXKWX'
    },
    {
      status: 810,
      sku: 'PM0008913',
      core_name: 'Plant',
      sppl_ean: '8720664688315',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495274',
      rtl_batch_array: '5495274, 6338935',
      name_search: 'ECPHOPE',
      name: "Echinacea purpurea 'Hope'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 51,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 2.234,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RLXCC9EX;E23NT4CH',
      statusContent: 'dot',
      hash: 'GBCJLKTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBCJLKTN'
    },
    {
      status: 210,
      sku: 'PM0074847',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274936',
      rtl_batch_array: '6274936',
      name_search: 'CAJDKING',
      name: "Camellia japonica 'Dr King'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 548,
      chnn_stock_retail: 548,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z84V5AG7;KBH3YV7B;G6BLA2AS;P3XY1A7H;7TYH9PD4;P96HL321;CL6RG5BV;PDLSZJYK;1BNBP9XA;8LCXKEXS;6SKS2JC2;Y7DBVSXD;7B1PATVE;HAYAYEHY;KPHV6YXN',
      statusContent: 'dot',
      hash: 'LD9HKZPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LD9HKZPC'
    },
    {
      status: 210,
      sku: 'PM0021386',
      core_name: 'Plant',
      sppl_ean: '8720664648937',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495541',
      rtl_batch_array: '5495541',
      name_search: 'VEDEYES',
      name: "Verbascum 'Dark Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NP18W5X8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NP18W5X8'
    },
    {
      status: 210,
      sku: 'PM0074851',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274942',
      rtl_batch_array: '6274942',
      name_search: 'CASCLEOP',
      name: "Camellia sasanqua 'Cleopatra'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LSC3P8DA;4CS16X5Y;4RRAAE2Y',
      statusContent: 'dot',
      hash: 'E6DKBBJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6DKBBJY'
    },
    {
      status: 810,
      sku: 'PM0074822',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281320',
      rtl_batch_array: '6281320',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'YZH2KPXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZH2KPXR'
    },
    {
      status: 210,
      sku: 'PM0074853',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274945',
      rtl_batch_array: '6274945',
      name_search: 'CAWDEBBI',
      name: "Camellia williamsii 'Debbie'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 664,
      chnn_stock_retail: 664,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J65WK1TV;87989NVK;1GLTY2EA',
      statusContent: 'dot',
      hash: '8EYABB9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EYABB9B'
    },
    {
      status: 210,
      sku: 'PM0024685',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276023',
      rtl_batch_array: '6349910, 6276023',
      name_search: 'CAFLACCA',
      name: 'Carex flacca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2332,
      chnn_stock_retail: 3616,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SV5JDHT',
      statusContent: 'dot',
      hash: 'NPNCGRBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPNCGRBT'
    },
    {
      status: 210,
      sku: 'PM0013941',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339002',
      rtl_batch_array: '6339002',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_prcp: 2.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'PDGB9JEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDGB9JEC'
    },
    {
      status: 210,
      sku: 'PM0019606',
      core_name: 'Plant',
      sppl_ean: '8720664682665',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495564',
      rtl_batch_array: '5495564',
      name_search: 'BUDABLUE',
      name: 'Buddleja davidii Adonis Blue',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 992,
      chnn_stock_retail: 992,
      sppl_prcp: 1.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HAX1VARG;XGZTHL9D;EENEX8BP',
      statusContent: 'dot',
      hash: 'X27CETTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X27CETTT'
    },
    {
      status: 810,
      sku: 'PM0074825',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281010',
      rtl_batch_array: '6281010',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'NB9NZWA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB9NZWA8'
    },
    {
      status: 210,
      sku: 'PM0074761',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274747',
      rtl_batch_array: '6274747',
      name_search: 'HYMLIBEL',
      name: "Hydrangea macrophylla 'Libelle'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D32EL74L;ZDCX7HCW;PPGVSD31;87SBT7K6;WEGAXENS;6BCBCPWW;2TZ4DEE7;82K99P7A;VCXPTL8T;AY6YND7C;55V2VJX3;5224VEL6;477G57W9;2R1PPD42;5NDCBTSG;LE6GNY12;PTHA8NJV',
      statusContent: 'dot',
      hash: '15LTPPCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15LTPPCN'
    },
    {
      status: 210,
      sku: 'PM0074762',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274748',
      rtl_batch_array: '6274748',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'KJCT1ESZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJCT1ESZ'
    },
    {
      status: 210,
      sku: 'PM0029667',
      core_name: 'Plant',
      sppl_ean: '8720664698307',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495660',
      rtl_batch_array: '5495660, 5501341',
      name_search: 'LONGGLOW',
      name: "Lonicera nitida 'Golden Glow'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 890,
      chnn_stock_retail: 1390,
      sppl_prcp: 2.351,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2BCZ5W6',
      statusContent: 'dot',
      hash: 'JL6AS65X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL6AS65X'
    },
    {
      status: 210,
      sku: 'PM0074764',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274750',
      rtl_batch_array: '6274750',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: 'WH3PBB2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH3PBB2X'
    },
    {
      status: 210,
      sku: 'PM0072491',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249992',
      rtl_batch_array: '6249992',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 881,
      chnn_stock_retail: 881,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: '8AL1KD76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AL1KD76'
    },
    {
      status: 210,
      sku: 'PM0072492',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249994',
      rtl_batch_array: '6249994',
      name_search: 'SESEMPER',
      name: 'Sequoia sempervirens',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 369,
      chnn_stock_retail: 369,
      sppl_prcp: 10.751,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H52D3WK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H52D3WK4'
    },
    {
      status: 210,
      sku: 'PM0017576',
      core_name: 'Plant',
      sppl_ean: '8720664697539',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495415',
      rtl_batch_array: '6371187, 5495415',
      name_search: 'LIDPANDO',
      name: "Ligularia dentata 'Pandora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 131,
      chnn_stock_retail: 827,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SSWG4GTK;NZ5S34X2;YLCED79C;ELAN78SP;Y16667AP',
      statusContent: 'dot',
      hash: 'RNHCLZ34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNHCLZ34'
    },
    {
      status: 210,
      sku: 'PM0074766',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274752',
      rtl_batch_array: '6274752',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'KLSGZ7N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLSGZ7N8'
    },
    {
      status: 210,
      sku: 'PM0074770',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274756',
      rtl_batch_array: '6274756',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: '4VYHXH4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VYHXH4T'
    },
    {
      status: 910,
      sku: 'PM0072495',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249997',
      rtl_batch_array: '6249997',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 13.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '5TBY79Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TBY79Z5'
    },
    {
      status: 810,
      sku: 'PM0074826',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281020',
      rtl_batch_array: '6281020',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: '66BLDR1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66BLDR1B'
    },
    {
      status: 210,
      sku: 'PM0074774',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274760',
      rtl_batch_array: '6274760',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: 'V5TH6NBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5TH6NBJ'
    },
    {
      status: 210,
      sku: 'PM0074775',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274761',
      rtl_batch_array: '6274761',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: '2NPY6R9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NPY6R9A'
    },
    {
      status: 210,
      sku: 'PM0074759',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274745',
      rtl_batch_array: '6274745',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 223,
      chnn_stock_retail: 223,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: '69G8NBX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69G8NBX1'
    },
    {
      status: 810,
      sku: 'PM0082573',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353400',
      rtl_batch_array: '6353400',
      name_search: 'THVFREDO',
      name: "Thymus vulg. 'Fredo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZCAHJRRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCAHJRRP'
    },
    {
      status: 810,
      sku: 'PM0074771',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274757',
      rtl_batch_array: '6281221, 6274757',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 6.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'G1163T6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1163T6K'
    },
    {
      status: 910,
      sku: 'PM0074772',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274758',
      rtl_batch_array: '6274758',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 6.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: 'Z4VVV33W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4VVV33W'
    },
    {
      status: 210,
      sku: 'PM0010392',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274528',
      rtl_batch_array: '6274528',
      name_search: 'ROAESCUL',
      name: 'Rodgersia aesculifolia',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3402,
      chnn_stock_retail: 3402,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XW9JNYXW;YDHR7WLJ;ZKREDZ92;9Y1VRZ5W;N1LJS7HT',
      statusContent: 'dot',
      hash: 'P2J4YJNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2J4YJNS'
    },
    {
      status: 810,
      sku: 'PM0082574',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353401',
      rtl_batch_array: '6353401',
      name_search: 'THVPINEW',
      name: "Thymus vulgaris 'Pinewood'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LESGWVJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LESGWVJX'
    },
    {
      status: 210,
      sku: 'PM0074811',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275114',
      rtl_batch_array: '6275114',
      name_search: 'NYMWGONN',
      name: "Nymphaea 'Madame Wilfron Gonn\u00e8re'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZHV6CKLC',
      statusContent: 'dot',
      hash: 'VYHV4DLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYHV4DLJ'
    },
    {
      status: 210,
      sku: 'PM0074812',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275115',
      rtl_batch_array: '6275115',
      name_search: 'NYWANVIS',
      name: "Nymphaea 'Wanvisa'",
      sppl_size_code: 'LEVM3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X381CVD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X381CVD5'
    },
    {
      status: 210,
      sku: 'PM0074815',
      core_name: 'Plant',
      sppl_ean: '8720353012001',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '4707322',
      rtl_batch_array: '4707322',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      imageBatch: '4W7G6EA4',
      statusContent: 'dot',
      hash: 'N4B21BXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4B21BXR'
    },
    {
      status: 210,
      sku: 'PM0082575',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353402',
      rtl_batch_array: '6353402',
      name_search: 'THVPRECO',
      name: "Thymus vulgaris 'Precompa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AYNJE4R4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYNJE4R4'
    },
    {
      status: 210,
      sku: 'PM0074817',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '6311353',
      rtl_batch_array: '6311353, 6284490, 6268650',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7685,
      chnn_stock_retail: 27574,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: '8DZ1LZ4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DZ1LZ4T'
    },
    {
      status: 210,
      sku: 'PM0074818',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268718',
      rtl_batch_array: '6268718',
      name_search: 'DEVOELKE',
      name: "Delphinium (B) 'V\u00f6lkerfrieden'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1346,
      chnn_stock_retail: 1346,
      sppl_order_minimum: 3,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ABE1HABJ;1DL64J4J',
      statusContent: 'dot',
      hash: '744294LR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '744294LR'
    },
    {
      status: 210,
      sku: 'PM0074820',
      core_name: 'Plant',
      sppl_ean: '8720349425761',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5365961',
      rtl_batch_array: '5365961',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      imageBatch: 'JXWND8Y4',
      statusContent: 'dot',
      hash: 'VTXELS3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTXELS3S'
    },
    {
      status: 210,
      sku: 'PM0082576',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353403',
      rtl_batch_array: '6353403',
      name_search: 'TIAWINGS',
      name: "Tiarella 'Angel Wings'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 294,
      chnn_stock_retail: 294,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G5R5Y1RS;DC96LXRZ;NZVSKLEA;ZX3WEDN9;E4NC4CNP;5KCGTXVR;GNKCAH53',
      statusContent: 'dot',
      hash: 'B33CT4C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B33CT4C8'
    },
    {
      status: 810,
      sku: 'PM0074767',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274753',
      rtl_batch_array: '6281212, 6274753',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 95,
      chnn_stock_retail: 145,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'N9PHRGZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9PHRGZ7'
    },
    {
      status: 910,
      sku: 'PM0074827',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      rng_range_identifier: 'STE090C7.5',
      rng_range_description: 'Stem 90 cm C7.5',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: 'ZR57DC4X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZR57DC4X'
    },
    {
      status: 910,
      sku: 'PM0072440',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 2,
      btch_active_retail: '6246339',
      rtl_batch_array: '6246339, 6356976',
      name_search: 'IRSBKING',
      name: "Iris sibirica 'Blue King'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5,
      chnn_stock_retail: 40,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SDWN7STB;JTTNHJAP;S8JWASB8;9TRETYHJ;WVHS51KW;717GGCYG;WH9V7A8R;VDL5XJ1D',
      statusContent: 'dot',
      hash: 'PLDRYHNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLDRYHNR'
    },
    {
      status: 210,
      sku: 'PM0072475',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '6341905',
      rtl_batch_array: '6341905',
      name_search: 'ABGKALEI',
      name: "Abelia grandiflora 'Kaleidoscope'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 272,
      chnn_stock_retail: 272,
      sppl_prcp: 2.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TVC8SJ7B;71K9HKKB',
      statusContent: 'dot',
      hash: 'W4JKXB1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4JKXB1R'
    },
    {
      status: 810,
      sku: 'PM0074829',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274915',
      rtl_batch_array: '6349173, 6274915',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 72,
      chnn_stock_retail: 122,
      sppl_prcp: 10.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: '4JD4N4GA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JD4N4GA'
    },
    {
      status: 210,
      sku: 'PM0082577',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353413',
      rtl_batch_array: '6353413',
      name_search: 'SESGMANT',
      name: "Sedum spurium 'Green Mantle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ASG3APVZ;T9XNPP91;LYN26HP6;77K38G4V;DH7ZJES7',
      statusContent: 'dot',
      hash: '8BYP7RG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BYP7RG9'
    },
    {
      status: 810,
      sku: 'PM0074769',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274755',
      rtl_batch_array: '6274755',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '5K9HV63K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5K9HV63K'
    },
    {
      status: 210,
      sku: 'PM0072489',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257825',
      rtl_batch_array: '6257825',
      name_search: 'CHLPSWIR',
      name: "Chamaecyparis lawsoniana 'Pearly Swirls'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1316,
      chnn_stock_retail: 1316,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7X8NSHW;CBYWZD4X;WE4PD5X7;65W2WXP5;JT7WB7VL;D6RP2XA8;PXS8DXC2;ADXZ13ZK;B7EAEP2X;P2PCWX75;H1CZTC3H;7YY56955;JG1L6PAD;1CXKDJ5S;DXEAWJJH;VCEVZV48',
      statusContent: 'dot',
      hash: 'YZW1NH13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZW1NH13'
    },
    {
      status: 210,
      sku: 'PM0072490',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249991',
      rtl_batch_array: '6249991',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 1186,
      chnn_stock_retail: 1186,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'AL51W4LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AL51W4LK'
    },
    {
      status: 210,
      sku: 'PM0082578',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353429',
      rtl_batch_array: '6353429',
      name_search: 'SEDBEAUT',
      name: "Sempervivum 'Dark Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4D3NX3D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4D3NX3D9'
    },
    {
      status: 210,
      sku: 'PM0082579',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353438',
      rtl_batch_array: '6353438',
      name_search: 'SERKULM',
      name: "Sempervivum 'Rauer Kulm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YS2PKSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YS2PKSY'
    },
    {
      status: 910,
      sku: 'PM0082580',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SETALPIN',
      name: 'Sempervivum tectorum alpinum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'B55513VT',
      statusContent: 'dot',
      hash: 'VN43JCPA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VN43JCPA'
    },
    {
      status: 810,
      sku: 'PM0074821',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281313',
      rtl_batch_array: '6281313',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'Y73VB9LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y73VB9LE'
    },
    {
      status: 210,
      sku: 'PM0012136',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385804',
      rtl_batch_array: '5495580, 6385804',
      name_search: 'BUDNANHO',
      name: 'Buddleja davidii nanhoensis',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 746,
      chnn_stock_retail: 1643,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KTK2DJKY;S5PLYE3P;P4ED541B;JC6GP795',
      statusContent: 'dot',
      hash: 'J54BELKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J54BELKV'
    },
    {
      status: 210,
      sku: 'PM0030082',
      core_name: 'Plant',
      sppl_ean: '8720664810112',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495522',
      rtl_batch_array: '6295888, 5495522, 6353383',
      name_search: 'THDSWHIT',
      name: 'Thalictrum delavayi Splendide White',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1232,
      chnn_stock_retail: 3392,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9WKT3CK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9WKT3CK'
    },
    {
      status: 810,
      sku: 'PM0074832',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280849',
      rtl_batch_array: '6280849',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.402,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '7DY47BNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DY47BNX'
    },
    {
      status: 210,
      sku: 'PM0082581',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353452',
      rtl_batch_array: '6353452',
      name_search: 'SOLCROWN',
      name: "Solidago 'Loysder Crown'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PE59HVCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PE59HVCZ'
    },
    {
      status: 910,
      sku: 'PM0082582',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'STOALBA',
      name: "Stachys officinalis 'Alba'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'LZ4BEERV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LZ4BEERV'
    },
    {
      status: 210,
      sku: 'PM0080247',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336267',
      rtl_batch_array: '6336267',
      name_search: 'PIJVARIE',
      name: "Pieris japonica 'Variegata'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 567,
      chnn_stock_retail: 567,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XK5JHNLD',
      statusContent: 'dot',
      hash: 'P5B6AAGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5B6AAGK'
    },
    {
      status: 210,
      sku: 'PM0074841',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274928',
      rtl_batch_array: '6274928',
      name_search: 'ANPBICE',
      name: "Andromeda polifolia 'Blue Ice'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 5967,
      chnn_stock_retail: 5967,
      sppl_prcp: 2.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JK4AY89J;S75DWC4K;D72VJB5G;HHECGGYP;6J4RHVRK;4Z28VW5Z;72ECN1VB;T2391BK2;T6R4CCXJ;C4JB33J8;JA98Y14H;WYSE99A5;J4AJA8BK;PVWLH1NX;VAVZHZR5;HNH59EWB;S44W2BHY;77JZWT7D;PWXVG1YB;HWZAA26Y;WESSTJD5;RLHP5XK5;1445XJDH',
      statusContent: 'dot',
      hash: 'TTKZ3XXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTKZ3XXB'
    },
    {
      status: 210,
      sku: 'PM0002399',
      core_name: 'Plant',
      sppl_ean: '8720664881648',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6129663',
      rtl_batch_array: '6129663, 6353240',
      name_search: 'PRRGRAND',
      name: "Primula rosea 'Grandiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5808,
      chnn_stock_retail: 6168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ST9YBHVD;GC8A5GSG;R9VN2BH7;V45STA4Z;NG5PA6R9;8GRSW86B;R74S6BVS;HHXL9WBJ;SCP87P1N',
      statusContent: 'dot',
      hash: 'GE4T52Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GE4T52Y8'
    },
    {
      status: 210,
      sku: 'PM0080248',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336268',
      rtl_batch_array: '6336268',
      name_search: 'RHFANTAS',
      name: "Rhododendron (Y) 'Fantastica'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JCVCD4WG;C2ENLY4Y;BZGBXXWP;JZ2K5CGV;T9A64HEE;7S2RR971;544BP7JR;YD1AENC2;JKLS2JVN;DNY92W8P;WRTZCW5J;Z57W32NV;XR8526R5;29TKNAX9;NKJL6TA4;H5541NBC;T7J1CXHC;5T3ESDH4;83LVDZ95',
      statusContent: 'dot',
      hash: 'VYETNNAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYETNNAP'
    },
    {
      status: 810,
      sku: 'PM0074838',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274925',
      rtl_batch_array: '6274925',
      name_search: 'ALJEPRID',
      name: "Albizia julibrissin 'Evi's Pride'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_prcp: 20.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JWKCHVPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWKCHVPA'
    },
    {
      status: 210,
      sku: 'PM0074819',
      core_name: 'Plant',
      sppl_ean: '8720626317376',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5365959',
      rtl_batch_array: '5365959',
      name_search: 'PIFSILVE',
      name: "Pieris 'Flaming Silver'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCXSKVBJ;HVLPYJBZ;JT4D138Z;VA7AA76B;EP883SGR;H4EX7KA9;YW3E6JLJ;CA16JVCG;Y1PYT182;KKYGL9RE;YJB4NAY4;15XGJLJN;A3DNJ4NP;YJYZ14YH;7ST6YLTR;NG55PDP7;YXD8N8BG;ZJ23XEES;NH3KSJ9C;NXSZK6HG;BGYB1247',
      imageBatch: 'WYCXCZWC',
      statusContent: 'dot',
      hash: '6E7PV3TD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6E7PV3TD'
    },
    {
      status: 210,
      sku: 'PM0080249',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336269',
      rtl_batch_array: '6336269',
      name_search: 'RHKALINK',
      name: "Rhododendron (Y) 'Kalinka'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCW5D6A7;GSE5T1HT;E66CLH3P;R2AHDY2V;WJ3P9BDJ;D48BKPP2;ZJ6EYRL6;D8CATP5P;C82VGJYP;2AVDESBJ;9RHJY3DK;SDVRCADJ;2X35CTGB;ZVVT4RVL;RARE6P8K;9SSKGBLW;4GBN9J8V',
      statusContent: 'dot',
      hash: 'T8GTWTNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8GTWTNS'
    },
    {
      status: 210,
      sku: 'PM0074843',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274930',
      rtl_batch_array: '6274930',
      name_search: 'CAJAAUDU',
      name: "Camellia japonica 'Adolphe Audusson'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 732,
      chnn_stock_retail: 732,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XEBDREGZ;63AH44EP;SSLBZ9P7;JPDK58P5',
      statusContent: 'dot',
      hash: 'AE8LKJJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AE8LKJJ8'
    },
    {
      status: 210,
      sku: 'PM0074846',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274934',
      rtl_batch_array: '6274934',
      name_search: 'CAJCLADY',
      name: "Camellia japonica 'Curly Lady'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 649,
      chnn_stock_retail: 649,
      sppl_prcp: 8.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EBRYCRKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBRYCRKK'
    },
    {
      status: 210,
      sku: 'PM0074852',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274943',
      rtl_batch_array: '6274943',
      name_search: 'CASNDAWN',
      name: "Camellia sasanqua 'New Dawn'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NN8795WP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN8795WP'
    },
    {
      status: 810,
      sku: 'PM0074854',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274947',
      rtl_batch_array: '6274947',
      name_search: 'CATGUILF',
      name: "Campsis tagliabuana 'Guilfoylei'",
      sppl_size_code: '080STC15',
      rng_range_identifier: 'STE080C15',
      rng_range_description: 'Stem 80 cm C15',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 22.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TXDBLG9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXDBLG9X'
    },
    {
      status: 210,
      sku: 'PM0080250',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336270',
      rtl_batch_array: '6336270',
      name_search: 'RHMARLIS',
      name: "Rhododendron (Y) 'Marlis'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WA62K52S;1ZT6Y6KK;4VP2SHN6',
      statusContent: 'dot',
      hash: '6NJXTAZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NJXTAZS'
    },
    {
      status: 210,
      sku: 'PM0080237',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336255',
      rtl_batch_array: '6336255',
      name_search: 'PEARUBRU',
      name: "Pennisetum advena 'Rubrum'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 982,
      chnn_stock_retail: 982,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6C25DWW;VJT3ZRAP;ZACZP7TZ;EW59DX9G;N2WYZ1C9;NRLDVYX5;LGD4ZEEG;S4EEAAZR;WVHDRZ99;PBZLX9EG;RTEYHY9B;J9PWY479;98V4VGKK;LWEZ8D7E;TZBW9958;KGX25VCY;KD69RY4A;C9NPZ1N9;738Z415A;SP67698E;LZKLAEVE;RCNZHP1N;TP5Z8GWT;K3Z66JDE;5HN3S3YJ;YPYWCGB2;K3TBB8R4;H42WWAWD',
      statusContent: 'dot',
      hash: '71BBAXBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71BBAXBS'
    },
    {
      status: 810,
      sku: 'PM0074857',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274950',
      rtl_batch_array: '6274950',
      name_search: 'CECSHIRO',
      name: "Cercis chinensis 'Shirobana'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 16.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6L6ALPRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L6ALPRD'
    },
    {
      status: 210,
      sku: 'PM0082583',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382317',
      rtl_batch_array: '6382317, 6333403',
      name_search: 'STGIGANT',
      name: 'Stipa gigantea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3700,
      chnn_stock_retail: 5603,
      sppl_order_minimum: 3,
      sppl_prcp: 0.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RWT5S8TE;ESBDT8HW',
      statusContent: 'dot',
      hash: 'HAS6L188',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAS6L188'
    },
    {
      status: 210,
      sku: 'PM0074859',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284939',
      rtl_batch_array: '6284939',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 6.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'C93TR6RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C93TR6RC'
    },
    {
      status: 210,
      sku: 'PM0080238',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336257',
      rtl_batch_array: '6336257',
      name_search: 'PEASBLUE',
      name: 'Perovskia atriplicifolia Silvery Blue',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y7DJJ3KR;566VV31C;AEP2YG7A;XWE79YTB;N9XEXGX8;XSXCCKLD;Y9GY78RD',
      statusContent: 'dot',
      hash: '56V9GSPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56V9GSPT'
    },
    {
      status: 810,
      sku: 'PM0074861',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274954',
      rtl_batch_array: '6274954',
      name_search: 'COKNATIO',
      name: "Cornus kousa 'National'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 9.164,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '95P1N87P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95P1N87P'
    },
    {
      status: 210,
      sku: 'PM0080239',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336259',
      rtl_batch_array: '6336259',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 18.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'LCP91VNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCP91VNR'
    },
    {
      status: 210,
      sku: 'PM0082584',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353474',
      rtl_batch_array: '6353474',
      name_search: 'STLCOLOR',
      name: "Stokesia laevis 'Colorwheel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '53K7B92K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53K7B92K'
    },
    {
      status: 210,
      sku: 'PM0080240',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336260',
      rtl_batch_array: '6336260',
      name_search: 'PHFRCOMP',
      name: "Photinia fraseri 'Robusta Compacta'",
      sppl_size_code: '060STC74',
      rng_range_identifier: 'STE060C7.5',
      rng_range_description: 'Stem 60 cm C7.5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E1KCAC5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1KCAC5K'
    },
    {
      status: 210,
      sku: 'PM0080251',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336271',
      rtl_batch_array: '6336271',
      name_search: 'RHMORGEN',
      name: "Rhododendron (Y) 'Morgenrot'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWRP545G;NLN92A91;6PNZ9PY7;KLPYNL5P;D7HBL865;RH7TGVWY;A68SVN5T;PW1S9SHX;Z5A7SZEW',
      statusContent: 'dot',
      hash: 'HDA31CV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDA31CV4'
    },
    {
      status: 210,
      sku: 'PM0074842',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274929',
      rtl_batch_array: '6274929',
      name_search: 'ANPNIKKO',
      name: "Andromeda polifolia 'Nikko'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 649,
      chnn_stock_retail: 649,
      sppl_prcp: 3.09,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EGTXWT37',
      statusContent: 'dot',
      hash: 'CSPR6KH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSPR6KH1'
    },
    {
      status: 210,
      sku: 'PM0074850',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274941',
      rtl_batch_array: '6274941',
      name_search: 'CARMWILL',
      name: "Camellia reticulata 'Mary Williams'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TBGGGKAS;D5KZWAE1;2JYEBBA4;4WVZSEXV',
      statusContent: 'dot',
      hash: 'JKRV2EBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKRV2EBK'
    },
    {
      status: 210,
      sku: 'PM0074848',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274939',
      rtl_batch_array: '6274939',
      name_search: 'CAJLCAMP',
      name: "Camellia japonica 'Lady Campbell'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 827,
      chnn_stock_retail: 827,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C9ASV2H;CHCP2CPX;CE6YKGT8;L6E2DXRC;LV1JYG15',
      statusContent: 'dot',
      hash: 'NKT4RVL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKT4RVL9'
    },
    {
      status: 210,
      sku: 'PM0074845',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274933',
      rtl_batch_array: '6274933',
      name_search: 'CAJBYELL',
      name: "Camellia japonica 'Brushfield's Yellow'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 718,
      chnn_stock_retail: 718,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6ZLZ48A;9KNXTTVB;TD3XAJD1;NTG3LD66;PS86LZWJ;46A7G9CE;EEDNZWXD',
      statusContent: 'dot',
      hash: 'L7YSPS4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7YSPS4T'
    },
    {
      status: 210,
      sku: 'PM0080252',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336272',
      rtl_batch_array: '6336272',
      name_search: 'RHPOLARI',
      name: "Rhododendron (Y) 'Polaris'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_prcp: 6.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26LB2BVA;S8HXP96T;TRDCG1PS;YVWB6BNW;YB29B2WD;CR3N9HRS;EA1Y183S;DZXE5ZWD;46K4BZ7N;519H9THD;441RN712;KK6GBX3T;9787AN28',
      statusContent: 'dot',
      hash: 'BZ1GXA4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ1GXA4J'
    },
    {
      status: 210,
      sku: 'PM0074844',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274931',
      rtl_batch_array: '6274931',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 342,
      chnn_stock_retail: 342,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: 'Z8RWV1VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8RWV1VB'
    },
    {
      status: 210,
      sku: 'PM0080253',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336273',
      rtl_batch_array: '6336273',
      name_search: 'RHSILBER',
      name: "Rhododendron (Y) 'Silberwolke'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KZYRSE6E;D2PVRJHH;AKLHL4K5;8K5ZJCNX;223PGK2A;JYZV78PC;1LDY6RAR;XSRRG7HP;H2JJLLE5',
      statusContent: 'dot',
      hash: 'Z9TPRRZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9TPRRZ9'
    },
    {
      status: 210,
      sku: 'PM0080254',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336274',
      rtl_batch_array: '6336274',
      name_search: 'RHTATJAN',
      name: "Rhododendron (Y) 'Tatjana'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 6.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRHYAXNC',
      statusContent: 'dot',
      hash: 'NZTNAWRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZTNAWRX'
    },
    {
      status: 210,
      sku: 'PM0028005',
      core_name: 'Plant',
      sppl_ean: '8720349478798',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5448389',
      rtl_batch_array: '6080826, 5448389',
      name_search: 'CAJLCAMP',
      name: "Camellia japonica 'Lady Campbell'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1980,
      chnn_stock_retail: 2180,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C9ASV2H;CHCP2CPX;CE6YKGT8;L6E2DXRC;LV1JYG15',
      imageBatch: '439CY5PE',
      statusContent: 'dot',
      hash: '2LJJ3C1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LJJ3C1G'
    },
    {
      status: 210,
      sku: 'PM0080255',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336275',
      rtl_batch_array: '6336275',
      name_search: 'RHYAKUSH',
      name: 'Rhododendron yakushimanum',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NA95K87G;PPKA83R5;75ABDCTR;45X4HW2V',
      statusContent: 'dot',
      hash: 'PHG7TJKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHG7TJKG'
    },
    {
      status: 210,
      sku: 'PM0080256',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336277',
      rtl_batch_array: '6336277',
      name_search: 'ROCARMIN',
      name: "Rosa hybrid (PA) 'Carminia'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 392,
      chnn_stock_retail: 392,
      sppl_order_minimum: 3,
      sppl_prcp: 3.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YA6D7BJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YA6D7BJZ'
    },
    {
      status: 210,
      sku: 'PM0080257',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336278',
      rtl_batch_array: '6336278',
      name_search: 'ROEUFEMI',
      name: "Rosa hybrid (PA) 'Eufemia'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 3.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CAWR6LXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAWR6LXN'
    },
    {
      status: 210,
      sku: 'PM0080258',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336279',
      rtl_batch_array: '6336279',
      name_search: 'ROFAUSTI',
      name: 'Rosa (H) Francine Austin',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 692,
      chnn_stock_retail: 692,
      sppl_order_minimum: 3,
      sppl_prcp: 3.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7568X8SC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7568X8SC'
    },
    {
      status: 210,
      sku: 'PM0080259',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336280',
      rtl_batch_array: '6336280',
      name_search: 'ROFLORIA',
      name: 'Rosa (F) Florian',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 492,
      chnn_stock_retail: 492,
      sppl_order_minimum: 3,
      sppl_prcp: 3.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G1N5WPN2;8XDWXZT8;ANKXK8GG',
      statusContent: 'dot',
      hash: 'YP84C8HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YP84C8HH'
    },
    {
      status: 210,
      sku: 'PM0080260',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336281',
      rtl_batch_array: '6336281',
      name_search: 'ROICEBER',
      name: 'Rosa (F) Iceberg',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1642,
      chnn_stock_retail: 1642,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K54WHGPC;VRPZ8R1N;HN5TD7ZX;X1NHCZL3;ES5KG59Y;BDV71JVV;SG6CHSLZ;ETC3ESZY',
      statusContent: 'dot',
      hash: 'HNBXZ9D1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNBXZ9D1'
    },
    {
      status: 210,
      sku: 'PM0074891',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274987',
      rtl_batch_array: '6274987',
      name_search: 'KALPEPPE',
      name: "Kalmia latifolia 'Peppermint'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 569,
      chnn_stock_retail: 569,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NLH385ZH',
      statusContent: 'dot',
      hash: '8BNT3PEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BNT3PEY'
    },
    {
      status: 910,
      sku: 'PM0072503',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DISVALEN',
      name: 'Dicentra spectabilis Valentine',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '16V7Y8SD',
      statusContent: 'dot',
      hash: '6N4L2J13',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6N4L2J13'
    },
    {
      status: 810,
      sku: 'PM0074921',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275018',
      rtl_batch_array: '6275018',
      name_search: 'RHSILBER',
      name: "Rhododendron (Y) 'Silberwolke'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KZYRSE6E;D2PVRJHH;AKLHL4K5;8K5ZJCNX;223PGK2A;JYZV78PC;1LDY6RAR;XSRRG7HP;H2JJLLE5',
      statusContent: 'dot',
      hash: 'ZE7ETYAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZE7ETYAR'
    },
    {
      status: 810,
      sku: 'PM0072500',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6250983',
      rtl_batch_array: '6250983',
      name_search: 'VIHEDERA',
      name: 'Viola hederacea',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 3.581,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E6P6SCLY;6JHWA41V;SLA9Z91J;C5SYDTAN;6N46ECGV;EXR8E1ZK',
      statusContent: 'dot',
      hash: 'GKBZDA4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKBZDA4E'
    },
    {
      status: 910,
      sku: 'PM0022479',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COCOTONE',
      name: 'Corokia cotoneaster',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore: 'A2SNVRH6;C9HXKJ75;PDS9B9ZS',
      statusContent: 'dot',
      hash: 'E2PJL7K3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E2PJL7K3'
    },
    {
      status: 210,
      sku: 'PM0080264',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336285',
      rtl_batch_array: '6336285',
      name_search: 'ROSROMAN',
      name: 'Rosa (P) Sunlight Romantica',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 9940,
      chnn_stock_retail: 9940,
      sppl_order_minimum: 3,
      sppl_prcp: 2.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R3GET5JR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3GET5JR'
    },
    {
      status: 810,
      sku: 'PM0074869',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284941',
      rtl_batch_array: '6284941',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 9.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'X25GXNC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X25GXNC6'
    },
    {
      status: 910,
      sku: 'PM0082585',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIELATIO',
      name: 'Viola elatior',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '47S57DC1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '47S57DC1'
    },
    {
      status: 810,
      sku: 'PM0074867',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274961',
      rtl_batch_array: '6274961',
      name_search: 'COARMAJE',
      name: "Corylus avellana 'Red Majestic'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 8.344,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S9YZKEVX;6CDJD15W;126G5LKS;63ATDJGR;LR4C43SP;5YV8H4Z7;GY5T3NZA;4WKEAEY1;49LZ1GG7;TBHHXCVT;KHZWAL8G;EN3YDE39',
      statusContent: 'dot',
      hash: 'Z8167LAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8167LAG'
    },
    {
      status: 210,
      sku: 'PM0072449',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246537',
      rtl_batch_array: '6246537',
      name_search: 'HYKGEMO',
      name: "Hypericum kalmianum 'Gemo'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '95YDVR17;DP12YKSA',
      statusContent: 'dot',
      hash: 'YK2DXH4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YK2DXH4B'
    },
    {
      status: 210,
      sku: 'PM0074881',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274977',
      rtl_batch_array: '6274977',
      name_search: 'KALELF',
      name: "Kalmia latifolia 'Elf'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1792,
      chnn_stock_retail: 1792,
      sppl_prcp: 6.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H28LN6VE',
      statusContent: 'dot',
      hash: '2564LJ34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2564LJ34'
    },
    {
      status: 210,
      sku: 'PM0074890',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274986',
      rtl_batch_array: '6274986',
      name_search: 'KALORED',
      name: "Kalmia latifolia 'Ostbo Red'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 799,
      chnn_stock_retail: 799,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3K8NEVS',
      statusContent: 'dot',
      hash: 'PSPJEPTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSPJEPTP'
    },
    {
      status: 810,
      sku: 'PM0013027',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307723',
      rtl_batch_array: '6307723',
      name_search: 'CELIBANI',
      name: 'Cedrus libani',
      sppl_size_code: '100STC12',
      rng_range_identifier: 'STE100C12',
      rng_range_description: 'Stem 100 cm C12',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 19.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPK35RB9;YVSAPN13',
      statusContent: 'dot',
      hash: 'YC3VT1GY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YC3VT1GY'
    },
    {
      status: 210,
      sku: 'PM0028343',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348785',
      rtl_batch_array: '6348785',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_order_minimum: 5,
      sppl_prcp: 2.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'RWHPGL43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWHPGL43'
    },
    {
      status: 210,
      sku: 'PM0011595',
      core_name: 'Plant',
      sppl_ean: '8720664699427',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2995878',
      rtl_batch_array: '2995878',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_prcp: 4.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      imageBatch: 'W5GKGB4N',
      statusContent: 'dot',
      hash: 'ESV56SJK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESV56SJK'
    },
    {
      status: 210,
      sku: 'PM0074892',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274988',
      rtl_batch_array: '6274988',
      name_search: 'KALPINWH',
      name: "Kalmia latifolia 'Pinwheel'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HX732TX1',
      statusContent: 'dot',
      hash: '668YNTG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '668YNTG8'
    },
    {
      status: 210,
      sku: 'PM0074932',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275029',
      rtl_batch_array: '6275029',
      name_search: 'VIBCONOY',
      name: "Viburnum burkwoodii 'Conoy'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_prcp: 4.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C72SXY9;SDC3PPBC',
      statusContent: 'dot',
      hash: 'BRS2C3B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRS2C3B8'
    },
    {
      status: 210,
      sku: 'PM0072441',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246524',
      rtl_batch_array: '6246524',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_prcp: 12.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'KS8X3366',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS8X3366'
    },
    {
      status: 210,
      sku: 'PM0072442',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246527',
      rtl_batch_array: '6246527',
      name_search: 'CLAHUMMI',
      name: "Clethra alnifolia 'Hummingbird'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LELV52NG;HA9RX2KC;HEHB17GC;DJK235R9',
      statusContent: 'dot',
      hash: 'A6R452B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6R452B7'
    },
    {
      status: 210,
      sku: 'PM0072443',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246528',
      rtl_batch_array: '6246528',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: '9KBXSGZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KBXSGZC'
    },
    {
      status: 210,
      sku: 'PM0072445',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246530',
      rtl_batch_array: '6246530',
      name_search: 'EUFHBLAZ',
      name: "Euonymus fortunei 'Hort's Blaze'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2SA1ZDB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SA1ZDB7'
    },
    {
      status: 910,
      sku: 'PM0074777',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274781',
      rtl_batch_array: '6274781',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '125150C4',
      rng_range_identifier: 'H125150C4',
      rng_range_description: 'H125 cm 150 cm C4',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 9.111,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'ZDLG29DZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDLG29DZ'
    },
    {
      status: 910,
      sku: 'PM0072447',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPANICU',
      name: 'Hydrangea paniculata',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      imageCore: 'E3G9RL7V;5K877CC4;4JDPBY2C;S41ZDECA;6K1X6BV1;PCAPCY2S',
      statusContent: 'dot',
      hash: '7XN26CB7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7XN26CB7'
    },
    {
      status: 910,
      sku: 'PM0072448',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPANICU',
      name: 'Hydrangea paniculata',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      imageCore: 'E3G9RL7V;5K877CC4;4JDPBY2C;S41ZDECA;6K1X6BV1;PCAPCY2S',
      statusContent: 'dot',
      hash: 'AWL6YSPK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AWL6YSPK'
    },
    {
      status: 210,
      sku: 'PM0072450',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6311917',
      rtl_batch_array: '6311917',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'RKHX6P7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKHX6P7V'
    },
    {
      status: 210,
      sku: 'PM0080265',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336287',
      rtl_batch_array: '6336287',
      name_search: 'ROWROSE',
      name: 'Rosa (F) Wedding Rose',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 976,
      chnn_stock_retail: 976,
      sppl_order_minimum: 3,
      sppl_prcp: 2.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDG1KNHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDG1KNHX'
    },
    {
      status: 910,
      sku: 'PM0072451',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '2J7ED49V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2J7ED49V'
    },
    {
      status: 210,
      sku: 'PM0014059',
      core_name: 'Plant',
      sppl_ean: '8720353001388',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5721429',
      rtl_batch_array: '5721429',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K2EASDHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2EASDHV'
    },
    {
      status: 210,
      sku: 'PM0072453',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246542',
      rtl_batch_array: '6246542',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 4900,
      chnn_stock_retail: 4900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'BYXCAD5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYXCAD5X'
    },
    {
      status: 210,
      sku: 'PM0072497',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272772',
      rtl_batch_array: '6272772',
      name_search: 'SAOPTANN',
      name: "Sanguisorba officinalis 'Pink Tanna'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 402,
      chnn_stock_retail: 402,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N4CHSJG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4CHSJG6'
    },
    {
      status: 210,
      sku: 'PM0074874',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274970',
      rtl_batch_array: '6274970',
      name_search: 'KALBSTAT',
      name: "Kalmia latifolia 'Bay State'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C6878N99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6878N99'
    },
    {
      status: 210,
      sku: 'PM0030334',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6184068',
      rtl_batch_array: '6184068, 5316189',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3705,
      sppl_prcp: 2.682,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'YVH56VE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVH56VE9'
    },
    {
      status: 910,
      sku: 'PM0074875',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274971',
      rtl_batch_array: '6274971',
      name_search: 'KALBSTAT',
      name: "Kalmia latifolia 'Bay State'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DD8KEHZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD8KEHZC'
    },
    {
      status: 210,
      sku: 'PM0074876',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274972',
      rtl_batch_array: '6274972',
      name_search: 'KALBBOY',
      name: "Kalmia latifolia 'Big Boy'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 373,
      chnn_stock_retail: 373,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R47J5DD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R47J5DD5'
    },
    {
      status: 810,
      sku: 'PM0074877',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274973',
      rtl_batch_array: '6274973',
      name_search: 'KALBBOY',
      name: "Kalmia latifolia 'Big Boy'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DY8KXYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DY8KXYL'
    },
    {
      status: 810,
      sku: 'PM0074778',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274782',
      rtl_batch_array: '6274782',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '150175C4',
      rng_range_identifier: 'H150175C4',
      rng_range_description: 'H150 cm 175 cm C4',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 11.111,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'VJALN1AV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJALN1AV'
    },
    {
      status: 210,
      sku: 'PM0082586',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353495',
      rtl_batch_array: '6353495',
      name_search: 'VIREICHE',
      name: 'Viola reichenbachiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XGP5JAZX',
      statusContent: 'dot',
      hash: 'PNAG5H6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNAG5H6W'
    },
    {
      status: 210,
      sku: 'PM0082587',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353502',
      rtl_batch_array: '6353502',
      name_search: 'WOOBTUSA',
      name: 'Woodsia obtusa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G18H1PX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G18H1PX1'
    },
    {
      status: 810,
      sku: 'PM0082588',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353506',
      rtl_batch_array: '6353506',
      name_search: 'ZIAUREA',
      name: 'Zizia aurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9GV3LT26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GV3LT26'
    },
    {
      status: 210,
      sku: 'PM0074883',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274979',
      rtl_batch_array: '6274979',
      name_search: 'KALGINKO',
      name: "Kalmia latifolia 'Ginkona'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 1672,
      chnn_stock_retail: 1672,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AGYXXDBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGYXXDBN'
    },
    {
      status: 210,
      sku: 'PM0074888',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274984',
      rtl_batch_array: '6274984',
      name_search: 'KALNANI',
      name: "Kalmia latifolia 'Nani'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A811DEXD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A811DEXD'
    },
    {
      status: 810,
      sku: 'PM0072446',
      core_name: 'Plant',
      sppl_ean: '8720349482115',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5232337',
      rtl_batch_array: '5232337',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'SNL9C8X6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNL9C8X6'
    },
    {
      status: 810,
      sku: 'PM0074923',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275020',
      rtl_batch_array: '6275020',
      name_search: 'RHTHEINJ',
      name: "Rhododendron (Y) 'Tina Heinje'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLAHTX1T;RCHCLDDX;YS6NDZ49;YCY6X198;B6XLWGRX',
      statusContent: 'dot',
      hash: 'ZRCS8H4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRCS8H4B'
    },
    {
      status: 210,
      sku: 'PM0074873',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274969',
      rtl_batch_array: '6274969',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '050060C4',
      rng_range_identifier: 'H050060C4',
      sppl_stock_available: 307,
      chnn_stock_retail: 307,
      sppl_prcp: 4.43,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '6JA8877K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JA8877K'
    },
    {
      status: 210,
      sku: 'PM0029570',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353304',
      rtl_batch_array: '6353304',
      name_search: 'SELYDIUM',
      name: 'Sedum lydium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T8EKEWVJ;ZH9VVATB;XB6BGPLE;SZ6PG2EP;585Y1D19;J4GWY548;GCZCNBDB;6SZT53EE;C1GH5XCL;8XV4E9B9;AD8HVN39',
      statusContent: 'dot',
      hash: '1TKB14S7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TKB14S7'
    },
    {
      status: 210,
      sku: 'PM0016414',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338432',
      rtl_batch_array: '5423426, 6338432',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2300,
      chnn_stock_retail: 3870,
      sppl_order_minimum: 5,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: '1TNX5VRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TNX5VRJ'
    },
    {
      status: 810,
      sku: 'PM0074878',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274974',
      rtl_batch_array: '6274974',
      name_search: 'KALBLABE',
      name: "Kalmia latifolia 'Black Label'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCLBYZP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCLBYZP1'
    },
    {
      status: 810,
      sku: 'PM0074893',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274989',
      rtl_batch_array: '6274989',
      name_search: 'KALROSEN',
      name: "Kalmia latifolia 'Rosenquarz'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C8BE52GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8BE52GX'
    },
    {
      status: 210,
      sku: 'PM0074880',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274976',
      rtl_batch_array: '6274976',
      name_search: 'KALCAROU',
      name: "Kalmia latifolia 'Carousel'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6W7TBLCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W7TBLCB'
    },
    {
      status: 910,
      sku: 'PM0074882',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274978',
      rtl_batch_array: '6274978',
      name_search: 'KALGALAX',
      name: "Kalmia latifolia 'Galaxy'",
      sppl_size_code: '040050C6',
      rng_range_identifier: 'H040050C6',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BJ81DKRB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJ81DKRB'
    },
    {
      status: 210,
      sku: 'PM0074884',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274980',
      rtl_batch_array: '6274980',
      name_search: 'KALLLIND',
      name: "Kalmia latifolia 'Little Linda'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1771,
      chnn_stock_retail: 1771,
      sppl_prcp: 6.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V5WX3YWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5WX3YWS'
    },
    {
      status: 210,
      sku: 'PM0074885',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274981',
      rtl_batch_array: '6274981',
      name_search: 'KALMINUE',
      name: "Kalmia latifolia 'Minuet'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1451,
      chnn_stock_retail: 1451,
      sppl_prcp: 6.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5G2WPY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5G2WPY6'
    },
    {
      status: 810,
      sku: 'PM0074894',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274990',
      rtl_batch_array: '6274990',
      name_search: 'KALSCHOK',
      name: "Kalmia latifolia 'Schokra'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D9D3XY7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9D3XY7R'
    },
    {
      status: 210,
      sku: 'PM0074887',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274983',
      rtl_batch_array: '6274983',
      name_search: 'KALMOYLA',
      name: "Kalmia latifolia 'Moyland'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RSRAJ7TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSRAJ7TV'
    },
    {
      status: 210,
      sku: 'PM0074889',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274985',
      rtl_batch_array: '6274985',
      name_search: 'KALOFIRE',
      name: "Kalmia latifolia 'Olympic Fire'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 609,
      chnn_stock_retail: 609,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z1B847ED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1B847ED'
    },
    {
      status: 210,
      sku: 'PM0074899',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274995',
      rtl_batch_array: '6274995',
      name_search: 'KAPOLIFO',
      name: 'Kalmia polifolia',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 3035,
      chnn_stock_retail: 3035,
      sppl_prcp: 3.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KVL9BP6N;KDAA7SBD;TR29CB6S;EZCJPKNB',
      statusContent: 'dot',
      hash: '6TJ6EB72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TJ6EB72'
    },
    {
      status: 910,
      sku: 'PM0074900',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274996',
      rtl_batch_array: '6274996',
      name_search: 'LABDAZZL',
      name: 'Lagerstroemia Berry Dazzle',
      sppl_size_code: '080STC10',
      rng_range_identifier: 'STE080C10',
      rng_range_description: 'Stem 80 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 22.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XC72JX7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC72JX7E'
    },
    {
      status: 210,
      sku: 'PM0074895',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274991',
      rtl_batch_array: '6274991',
      name_search: 'KALSTERN',
      name: "Kalmia latifolia 'Sterntaler'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 328,
      chnn_stock_retail: 328,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '63BA57GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63BA57GD'
    },
    {
      status: 210,
      sku: 'PM0074901',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274997',
      rtl_batch_array: '6274997',
      name_search: 'LABDAZZL',
      name: 'Lagerstroemia Berry Dazzle',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZRV9KD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZRV9KD9'
    },
    {
      status: 210,
      sku: 'PM0074902',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274999',
      rtl_batch_array: '6274999',
      name_search: 'LARPINK',
      name: 'Lagerstroemia Rhapsody in Pink',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PRPRXJR7',
      statusContent: 'dot',
      hash: 'D6BDX9VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6BDX9VX'
    },
    {
      status: 210,
      sku: 'PM0074896',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274992',
      rtl_batch_array: '6274992',
      name_search: 'KALTIDDL',
      name: "Kalmia latifolia 'Tiddlywinks'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1879,
      chnn_stock_retail: 1879,
      sppl_prcp: 6.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KKDNTK52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKDNTK52'
    },
    {
      status: 810,
      sku: 'PM0074903',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275000',
      rtl_batch_array: '6275000',
      name_search: 'MAELIZAB',
      name: "Magnolia 'Elizabeth'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 12.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VE5DC41C;JRCTYR6N',
      statusContent: 'dot',
      hash: 'JAHYE27C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAHYE27C'
    },
    {
      status: 210,
      sku: 'PM0074904',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275001',
      rtl_batch_array: '6275001',
      name_search: 'MABYBIRD',
      name: "Magnolia brooklynensis 'Yellow Bird'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_prcp: 12.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4HRPJC1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HRPJC1W'
    },
    {
      status: 810,
      sku: 'PM0074906',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275003',
      rtl_batch_array: '6275003',
      name_search: 'RHBLURET',
      name: "Rhododendron (Y) 'Blurettia'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DWBDJHJ;S8H774R6;99VXTZP1;YLYJT175;YLGHG2SL;ZCBDTSE6;79JPDNL6;V9XK12SC',
      statusContent: 'dot',
      hash: 'K39L3X79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K39L3X79'
    },
    {
      status: 810,
      sku: 'PM0074907',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275004',
      rtl_batch_array: '6275004',
      name_search: 'RHBLURET',
      name: "Rhododendron (Y) 'Blurettia'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DWBDJHJ;S8H774R6;99VXTZP1;YLYJT175;YLGHG2SL;ZCBDTSE6;79JPDNL6;V9XK12SC',
      statusContent: 'dot',
      hash: 'VGZP74NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGZP74NS'
    },
    {
      status: 210,
      sku: 'PM0074908',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275005',
      rtl_batch_array: '6275005',
      name_search: 'RHBLURET',
      name: "Rhododendron (Y) 'Blurettia'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DWBDJHJ;S8H774R6;99VXTZP1;YLYJT175;YLGHG2SL;ZCBDTSE6;79JPDNL6;V9XK12SC',
      statusContent: 'dot',
      hash: 'XWP8A264',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWP8A264'
    },
    {
      status: 910,
      sku: 'PM0074909',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275006',
      rtl_batch_array: '6275006',
      name_search: 'RHCPARC',
      name: "Rhododendron (Y) 'Conte du Parc'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SRX9HH3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SRX9HH3R'
    },
    {
      status: 210,
      sku: 'PM0074910',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275007',
      rtl_batch_array: '6275007',
      name_search: 'RHFANTAS',
      name: "Rhododendron (Y) 'Fantastica'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JCVCD4WG;C2ENLY4Y;BZGBXXWP;JZ2K5CGV;T9A64HEE;7S2RR971;544BP7JR;YD1AENC2;JKLS2JVN;DNY92W8P;WRTZCW5J;Z57W32NV;XR8526R5;29TKNAX9;NKJL6TA4;H5541NBC;T7J1CXHC;5T3ESDH4;83LVDZ95',
      statusContent: 'dot',
      hash: 'W262W8AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W262W8AS'
    },
    {
      status: 210,
      sku: 'PM0074911',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275008',
      rtl_batch_array: '6275008',
      name_search: 'RHGCOMPA',
      name: "Rhododendron groenlandicum 'Compactum'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1757,
      chnn_stock_retail: 1757,
      sppl_prcp: 3.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '95XHBNX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95XHBNX9'
    },
    {
      status: 210,
      sku: 'PM0074897',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274993',
      rtl_batch_array: '6274993',
      name_search: 'KALZEBUL',
      name: "Kalmia latifolia 'Zebulon'",
      sppl_size_code: '025030C4',
      rng_range_identifier: 'H025030C4',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 6.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7CKB17B9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CKB17B9'
    },
    {
      status: 210,
      sku: 'PM0074914',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275011',
      rtl_batch_array: '6275011',
      name_search: 'RHMARLIS',
      name: "Rhododendron (Y) 'Marlis'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WA62K52S;1ZT6Y6KK;4VP2SHN6',
      statusContent: 'dot',
      hash: '4E82DTNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E82DTNB'
    },
    {
      status: 810,
      sku: 'PM0074915',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275012',
      rtl_batch_array: '6275012',
      name_search: 'RHMARLIS',
      name: "Rhododendron (Y) 'Marlis'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WA62K52S;1ZT6Y6KK;4VP2SHN6',
      statusContent: 'dot',
      hash: 'GPPN2P7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPPN2P7S'
    },
    {
      status: 210,
      sku: 'PM0074916',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275013',
      rtl_batch_array: '6275013',
      name_search: 'RHMORGEN',
      name: "Rhododendron (Y) 'Morgenrot'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWRP545G;NLN92A91;6PNZ9PY7;KLPYNL5P;D7HBL865;RH7TGVWY;A68SVN5T;PW1S9SHX;Z5A7SZEW',
      statusContent: 'dot',
      hash: 'VGZJLDZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGZJLDZK'
    },
    {
      status: 810,
      sku: 'PM0074918',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275015',
      rtl_batch_array: '6275015',
      name_search: 'RHSWOLKE',
      name: "Rhododendron (Y) 'Schneewolke'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZD7EJG59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD7EJG59'
    },
    {
      status: 210,
      sku: 'PM0074919',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275016',
      rtl_batch_array: '6275016',
      name_search: 'RHSWOLKE',
      name: "Rhododendron (Y) 'Schneewolke'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KVC4ZNJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVC4ZNJA'
    },
    {
      status: 210,
      sku: 'PM0074924',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275021',
      rtl_batch_array: '6275021',
      name_search: 'RHUWARRI',
      name: "Rhododendron 'Unknown Warrior'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 324,
      chnn_stock_retail: 324,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KVT5KHVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVT5KHVV'
    },
    {
      status: 210,
      sku: 'PM0074925',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275022',
      rtl_batch_array: '6275022',
      name_search: 'RHZAUBER',
      name: "Rhododendron 'Zauberfloete'",
      sppl_size_code: '070STC74',
      rng_range_identifier: 'STE070C7.5',
      rng_range_description: 'Stem 70 cm C7.5',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 13.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HA6X4CG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HA6X4CG9'
    },
    {
      status: 910,
      sku: 'PM0074930',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: '7TL21G5V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7TL21G5V'
    },
    {
      status: 210,
      sku: 'PM0074931',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275028',
      rtl_batch_array: '6275028',
      name_search: 'VIBARUSS',
      name: "Viburnum burkwoodii 'Anne Russell'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 4.764,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AH9XESB;KLENXA51',
      statusContent: 'dot',
      hash: 'Y528A79E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y528A79E'
    },
    {
      status: 210,
      sku: 'PM0074933',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275030',
      rtl_batch_array: '6275030',
      name_search: 'VIBCONOY',
      name: "Viburnum burkwoodii 'Conoy'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 439,
      chnn_stock_retail: 439,
      sppl_prcp: 5.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C72SXY9;SDC3PPBC',
      statusContent: 'dot',
      hash: 'HA62VP9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HA62VP9X'
    },
    {
      status: 910,
      sku: 'PM0074936',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIESKIMO',
      name: "Viburnum 'Eskimo'",
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      imageCore: '5BBGC5WW',
      statusContent: 'dot',
      hash: '1J8HEAT5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1J8HEAT5'
    },
    {
      status: 910,
      sku: 'PM0074937',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIESKIMO',
      name: "Viburnum 'Eskimo'",
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      imageCore: '5BBGC5WW',
      statusContent: 'dot',
      hash: 'AB527821',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AB527821'
    },
    {
      status: 910,
      sku: 'PM0072452',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'BWT8E3V5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BWT8E3V5'
    },
    {
      status: 210,
      sku: 'PM0072444',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246529',
      rtl_batch_array: '6246529',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'YRCPEGEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRCPEGEB'
    },
    {
      status: 210,
      sku: 'PM0080266',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336288',
      rtl_batch_array: '6336288',
      name_search: 'SAHHUMIL',
      name: 'Sarcococca hookeriana humilis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8129VXKJ;CAEVYGPS;P2B7KXZW;NCL6CE76;21AB2EWV;C2T4155L;VHE3YL9H;XZDDJKXE;B53W7ND6;YKWKZYAY;ZZXP9NHA;GY4LK5GN;HWH2S3DP;T8RNDZNT',
      statusContent: 'dot',
      hash: 'JTALDH1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTALDH1C'
    },
    {
      status: 210,
      sku: 'PM0074866',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274959',
      rtl_batch_array: '6274959',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_prcp: 5.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'TKJWHBP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKJWHBP6'
    },
    {
      status: 210,
      sku: 'PM0074917',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275014',
      rtl_batch_array: '6275014',
      name_search: 'RHPOLARI',
      name: "Rhododendron (Y) 'Polaris'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 378,
      chnn_stock_retail: 378,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26LB2BVA;S8HXP96T;TRDCG1PS;YVWB6BNW;YB29B2WD;CR3N9HRS;EA1Y183S;DZXE5ZWD;46K4BZ7N;519H9THD;441RN712;KK6GBX3T;9787AN28',
      statusContent: 'dot',
      hash: 'GSPXSSR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSPXSSR1'
    },
    {
      status: 210,
      sku: 'PM0074922',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275019',
      rtl_batch_array: '6275019',
      name_search: 'RHSILBER',
      name: "Rhododendron (Y) 'Silberwolke'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KZYRSE6E;D2PVRJHH;AKLHL4K5;8K5ZJCNX;223PGK2A;JYZV78PC;1LDY6RAR;XSRRG7HP;H2JJLLE5',
      statusContent: 'dot',
      hash: 'DJS76SH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJS76SH2'
    },
    {
      status: 210,
      sku: 'PM0074920',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275017',
      rtl_batch_array: '6275017',
      name_search: 'RHSILBER',
      name: "Rhododendron (Y) 'Silberwolke'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 5.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KZYRSE6E;D2PVRJHH;AKLHL4K5;8K5ZJCNX;223PGK2A;JYZV78PC;1LDY6RAR;XSRRG7HP;H2JJLLE5',
      statusContent: 'dot',
      hash: '95ARDLKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95ARDLKY'
    },
    {
      status: 910,
      sku: 'PM0072454',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MYGALE',
      name: 'Myrica gale',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: 'NXV5S2Y2',
      statusContent: 'dot',
      hash: '1XHGSLL7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1XHGSLL7'
    },
    {
      status: 210,
      sku: 'PM0080267',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336289',
      rtl_batch_array: '6336289',
      name_search: 'SARUSCIF',
      name: 'Sarcococca ruscifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 4.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R9JTP1R2;84KEP91T;AXX8GSH7;YP6DSTPT',
      statusContent: 'dot',
      hash: 'SCVH9WRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCVH9WRA'
    },
    {
      status: 210,
      sku: 'PM0080268',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336290',
      rtl_batch_array: '6336290',
      name_search: 'SKCKGREE',
      name: "Skimmia confusa 'Kew Green'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RG8DAAKW',
      statusContent: 'dot',
      hash: 'E5C5RCL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5C5RCL7'
    },
    {
      status: 210,
      sku: 'PM0080261',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336282',
      rtl_batch_array: '6336282',
      name_search: 'ROLROMAN',
      name: 'Rosa (T) Lemon Romantica',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 9999,
      chnn_stock_retail: 9999,
      sppl_order_minimum: 3,
      sppl_prcp: 2.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '88ALRC6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88ALRC6R'
    },
    {
      status: 210,
      sku: 'PM0080262',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336283',
      rtl_batch_array: '6336283',
      name_search: 'ROPBLANK',
      name: 'Rosa (H) Pink Blanket',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CJ12TZXB',
      statusContent: 'dot',
      hash: 'DLRG72VZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLRG72VZ'
    },
    {
      status: 210,
      sku: 'PM0080263',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336284',
      rtl_batch_array: '6336284',
      name_search: 'ROPCBELG',
      name: 'Rosa (F) Princess Claire of Belgium',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 3.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DJE6CVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DJE6CVT'
    },
    {
      status: 210,
      sku: 'PM0080269',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336291',
      rtl_batch_array: '6336291',
      name_search: 'SKCKGREE',
      name: "Skimmia confusa 'Kew Green'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RG8DAAKW',
      statusContent: 'dot',
      hash: 'VHAHDTTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHAHDTTA'
    },
    {
      status: 210,
      sku: 'PM0080270',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336292',
      rtl_batch_array: '6336292',
      name_search: 'SKJRBBEE',
      name: 'Skimmia japonica Red Berry Bee',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 8.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPAXXPH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPAXXPH8'
    },
    {
      status: 210,
      sku: 'PM0080271',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336293',
      rtl_batch_array: '6336293',
      name_search: 'SKJAPONI',
      name: 'Skimmia japonica',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P59871VN;P7P9766D;Z3RAG6X6;6LR3G5WK;NBCLYYLG;47GA8LJT;7H72RW62;CKJYJ4BV;C878G3ZG;SEDE51ES;A72CHZDZ;NN7J66C1;G5C1WDCH;EB8L66SC;2R5E177K;99VHELKW;7S58V8VX',
      statusContent: 'dot',
      hash: 'HJK1VCX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJK1VCX2'
    },
    {
      status: 210,
      sku: 'PM0080272',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336294',
      rtl_batch_array: '6336294',
      name_search: 'SKJFCLOU',
      name: "Skimmia japonica 'Fragrant Cloud'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2440,
      chnn_stock_retail: 2440,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J58A79P;PPV79CSS;DLVWSZ7D;KJS43E4N',
      statusContent: 'dot',
      hash: 'EYN9EVVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYN9EVVA'
    },
    {
      status: 210,
      sku: 'PM0080273',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336295',
      rtl_batch_array: '6336295',
      name_search: 'SKJFCLOU',
      name: "Skimmia japonica 'Fragrant Cloud'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J58A79P;PPV79CSS;DLVWSZ7D;KJS43E4N',
      statusContent: 'dot',
      hash: 'A7T7625S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7T7625S'
    },
    {
      status: 210,
      sku: 'PM0080274',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336296',
      rtl_batch_array: '6336296',
      name_search: 'SKJGDWAR',
      name: "Skimmia japonica 'Godrie's Dwarf'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1990,
      chnn_stock_retail: 1990,
      sppl_prcp: 4.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6KSTV3WK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KSTV3WK'
    },
    {
      status: 210,
      sku: 'PM0080275',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336297',
      rtl_batch_array: '6336297',
      name_search: 'SKJGDGRE',
      name: "Skimmia japonica 'Godrie's Dwarf Green'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 890,
      chnn_stock_retail: 890,
      sppl_prcp: 4.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HP6X14L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP6X14L5'
    },
    {
      status: 210,
      sku: 'PM0080276',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336298',
      rtl_batch_array: '6336298',
      name_search: 'SKJKWHIT',
      name: "Skimmia japonica 'Kew White'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y8LK89PY;BG421NWR;BKDK1CX4;TYCLR18K',
      statusContent: 'dot',
      hash: 'LHCTLBBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHCTLBBD'
    },
    {
      status: 210,
      sku: 'PM0080277',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336299',
      rtl_batch_array: '6336299',
      name_search: 'SKJKWHIT',
      name: "Skimmia japonica 'Kew White'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y8LK89PY;BG421NWR;BKDK1CX4;TYCLR18K',
      statusContent: 'dot',
      hash: 'GSYK4HKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSYK4HKX'
    },
    {
      status: 210,
      sku: 'PM0080278',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336300',
      rtl_batch_array: '6336300',
      name_search: 'SKJNYMAN',
      name: "Skimmia japonica 'Nymans'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H68PPBJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H68PPBJP'
    },
    {
      status: 210,
      sku: 'PM0080279',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336301',
      rtl_batch_array: '6336301',
      name_search: 'SKJOFLAM',
      name: "Skimmia japonica 'Olympic Flame'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_prcp: 4.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A1WABXD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1WABXD5'
    },
    {
      status: 210,
      sku: 'PM0080280',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336302',
      rtl_batch_array: '6336302',
      name_search: 'SKJOFLAM',
      name: "Skimmia japonica 'Olympic Flame'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RPE41NTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPE41NTJ'
    },
    {
      status: 210,
      sku: 'PM0080281',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336303',
      rtl_batch_array: '6336303',
      name_search: 'SKJREDRU',
      name: "Skimmia japonica 'Redruth'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T41K671H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T41K671H'
    },
    {
      status: 210,
      sku: 'PM0080282',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336304',
      rtl_batch_array: '6336304',
      name_search: 'SKJREDRU',
      name: "Skimmia japonica 'Redruth'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EGZBS63C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGZBS63C'
    },
    {
      status: 210,
      sku: 'PM0080283',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336305',
      rtl_batch_array: '6336305',
      name_search: 'SKJRGREE',
      name: "Skimmia japonica 'Rockyfield Green'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 4.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AGDAYKXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGDAYKXZ'
    },
    {
      status: 210,
      sku: 'PM0080284',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336306',
      rtl_batch_array: '6336306',
      name_search: 'SKJRGREE',
      name: "Skimmia japonica 'Rockyfield Green'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRGYAZW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRGYAZW4'
    },
    {
      status: 210,
      sku: 'PM0080285',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336308',
      rtl_batch_array: '6336308',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      statusContent: 'dot',
      hash: 'DKWD6TXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKWD6TXH'
    },
    {
      status: 210,
      sku: 'PM0080286',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336309',
      rtl_batch_array: '6336309',
      name_search: 'SKJRUBIN',
      name: "Skimmia japonica 'Rubinetta'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XCKX2DAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCKX2DAL'
    },
    {
      status: 210,
      sku: 'PM0074786',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350221',
      rtl_batch_array: '6350221, 6274847',
      name_search: 'GENIMBUS',
      name: "Geranium 'Nimbus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1077,
      chnn_stock_retail: 1379,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SRJS7JD7',
      statusContent: 'dot',
      hash: 'TKS1TCYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKS1TCYG'
    },
    {
      status: 210,
      sku: 'PM0026005',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301080',
      rtl_batch_array: '6349600, 6301080, 6291707',
      name_search: 'BLSPICAN',
      name: 'Blechnum spicant',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14921,
      chnn_stock_retail: 19240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G18GPEPK;GTK7Y5ZW;BGP9LHPL;A1TBVCV9;TR275HHX;YETY52TE;8DSLP135;2WHPX6KW;ABCP4H6T;SNSYBKCX;K31VDCJK;RH4J4HGD;DJEBD9P2;K2RLTZK5;WW6Z9B9K;K7AH5W3H;9NAS73LC',
      statusContent: 'dot',
      hash: 'RCTHXRCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCTHXRCV'
    },
    {
      status: 210,
      sku: 'PM0016400',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6290284',
      rtl_batch_array: '6322364, 6258736, 6290284',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2680,
      chnn_stock_retail: 5574,
      sppl_prcp: 0.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: '5P432RPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5P432RPG'
    },
    {
      status: 210,
      sku: 'PM0013496',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300232',
      rtl_batch_array: '6300232',
      name_search: 'SAAURITA',
      name: 'Salix aurita',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '546Y6YZK',
      statusContent: 'dot',
      hash: 'CVS3229C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVS3229C'
    },
    {
      status: 810,
      sku: 'PM0074940',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275037',
      rtl_batch_array: '6275037',
      name_search: 'CHONGRAC',
      name: "Chamaecyparis obtusa 'Nana Gracilis'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 5.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1HJET36;XWH728VL;5XNYWHYN;PVNJ4EWY;E1AS7L2X;2J1E1CX4;HPJH6N7P;ZBV2G5VZ;7LB298WC',
      statusContent: 'dot',
      hash: '5CAWNY8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CAWNY8A'
    },
    {
      status: 810,
      sku: 'PM0080298',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336333',
      rtl_batch_array: '6336333',
      name_search: 'VIACALBU',
      name: "Vitex agnus-castus 'Albus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 7.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECDK1Z69',
      statusContent: 'dot',
      hash: 'A6N9AGSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6N9AGSP'
    },
    {
      status: 210,
      sku: 'PM0074968',
      core_name: 'Plant',
      sppl_ean: '8720664684188',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5877624',
      rtl_batch_array: '5877624',
      name_search: 'CEDGHORI',
      name: "Cedrus deodara 'Golden Horizon'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_prcp: 6.364,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41VWZJ8H;5XEKK42H',
      statusContent: 'dot',
      hash: 'CTCPKEAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTCPKEAL'
    },
    {
      status: 210,
      sku: 'PM0072460',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246578',
      rtl_batch_array: '6246578',
      name_search: 'JUSBSTAR',
      name: "Juniperus squamata 'Blue Star'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 956,
      chnn_stock_retail: 956,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '223S71E6;YL28ZCAS;BESLT261;C5DRTS5J;JR1P944L;27XBARC5;KE55BKLZ;CYYDK5J8;TP7NVDP8;VJA9BAZY;68BCEN7R;5KJ2YNCK;XX713EV4;4717E53N;LXXWRJ96;SDP1WS9C;2KN4YKZ4;TYC557EP;882YW612;A8PLDHJH;LYT7PRN9;8WHJ75GB;YEL79DVY;XHVVVA2E',
      statusContent: 'dot',
      hash: '9XD1B9W8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XD1B9W8'
    },
    {
      status: 210,
      sku: 'PM0006724',
      core_name: 'Plant',
      sppl_ean: '8720349449354',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5374361',
      rtl_batch_array: '5374361',
      name_search: 'SPJMCARP',
      name: 'Spiraea japonica Magic Carpet',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'DZPPCWYX',
      statusContent: 'dot',
      hash: 'S5DZ11WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5DZ11WG'
    },
    {
      status: 210,
      sku: 'PM0074782',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349986',
      rtl_batch_array: '6349986, 6274836, 6385448',
      name_search: 'DECTARDI',
      name: "Deschampsia cespitosa 'Tardiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 860,
      chnn_stock_retail: 1861,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TGPVX33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TGPVX33'
    },
    {
      status: 810,
      sku: 'PM0074783',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274838',
      rtl_batch_array: '6274838',
      name_search: 'ACFHVOGE',
      name: "Achillea filipendulina 'Heinrich Vogeler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4SXXCTSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SXXCTSA'
    },
    {
      status: 810,
      sku: 'PM0026329',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338336',
      rtl_batch_array: '5420757, 6338336',
      name_search: 'PHCAUREU',
      name: "Philadelphus coronarius 'Aureus'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 92,
      chnn_stock_retail: 192,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CPZ8BS4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPZ8BS4J'
    },
    {
      status: 210,
      sku: 'PM0019028',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253105',
      rtl_batch_array: '6253105',
      name_search: 'HENBLUSH',
      name: "Hebe 'Nicola's Blush'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 586,
      chnn_stock_retail: 586,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7N958EYK',
      statusContent: 'dot',
      hash: 'GKKYXRBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKKYXRBG'
    },
    {
      status: 210,
      sku: 'PM0023127',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307882',
      rtl_batch_array: '6307882',
      name_search: 'ALLBUBBL',
      name: "Allium 'Lavender Bubbles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 368,
      chnn_stock_retail: 368,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRY184L7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRY184L7'
    },
    {
      status: 210,
      sku: 'PM0074779',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307929',
      rtl_batch_array: '6274820, 6307929',
      name_search: 'ACFHYMNE',
      name: "Achillea filipendulina 'Hymne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 230,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYYSW9W7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYYSW9W7'
    },
    {
      status: 210,
      sku: 'PM0003919',
      core_name: 'Plant',
      sppl_ean: '8720349416646',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5321490',
      rtl_batch_array: '5592070, 5321490',
      name_search: 'GESSTRIA',
      name: 'Geranium sanguineum striatum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 8210,
      chnn_stock_retail: 9210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TLP4T4EE;VBRH6X1W;Y2BBW4CV',
      imageBatch: '14A3YLK8',
      statusContent: 'dot',
      hash: 'KNC65VDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNC65VDZ'
    },
    {
      status: 210,
      sku: 'PM0007628',
      core_name: 'Plant',
      sppl_ean: '8720349493517',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5972565',
      rtl_batch_array: '6133721, 5972565, 6386197',
      name_search: 'SIANGUST',
      name: 'Sisyrinchium angustifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 1131,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9PRN5KPY;NKNPD66N',
      statusContent: 'dot',
      hash: 'KW7KSW86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW7KSW86'
    },
    {
      status: 210,
      sku: 'PM0080299',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336334',
      rtl_batch_array: '6336334',
      name_search: 'YUFBEDGE',
      name: "Yucca filamentosa 'Bright Edge'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 444,
      chnn_stock_retail: 444,
      sppl_prcp: 4.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TTRX8HH9',
      statusContent: 'dot',
      hash: '6JDSZXPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JDSZXPK'
    },
    {
      status: 210,
      sku: 'PM0004696',
      core_name: 'Plant',
      sppl_ean: '8720349486007',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '4662988',
      rtl_batch_array: '4662988, 6295787',
      name_search: 'PEAFDOMI',
      name: "Persicaria amplexicaulis 'Fat Domino'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1453,
      chnn_stock_retail: 2573,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TCWZHSN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCWZHSN9'
    },
    {
      status: 810,
      sku: 'PM0074939',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369367',
      rtl_batch_array: '6369367, 6275036',
      name_search: 'CHONGRAC',
      name: "Chamaecyparis obtusa 'Nana Gracilis'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 98,
      chnn_stock_retail: 792,
      sppl_prcp: 3.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1HJET36;XWH728VL;5XNYWHYN;PVNJ4EWY;E1AS7L2X;2J1E1CX4;HPJH6N7P;ZBV2G5VZ;7LB298WC',
      statusContent: 'dot',
      hash: '85XRPLWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85XRPLWE'
    },
    {
      status: 210,
      sku: 'PM0010300',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6340039',
      rtl_batch_array: '6353256, 6340039',
      name_search: 'PUVRUBRA',
      name: "Pulsatilla vulgaris 'Rubra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 686,
      chnn_stock_retail: 1638,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6K7WD386;TY2T8R88;JZJX2JZY;HP6Y4Y49',
      statusContent: 'dot',
      hash: 'TDRJVSDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDRJVSDV'
    },
    {
      status: 210,
      sku: 'PM0074971',
      core_name: 'Plant',
      sppl_ean: '8720664680043',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054333',
      rtl_batch_array: '6054333',
      name_search: 'EDI73821',
      name: "Allium 'Mercurius'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E6Y33ASE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6Y33ASE'
    },
    {
      status: 210,
      sku: 'PM0082589',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353508',
      rtl_batch_array: '6353508',
      name_search: 'TIOLDHAM',
      name: 'Titanotrichum oldhamii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z73TJH69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z73TJH69'
    },
    {
      status: 810,
      sku: 'PM0074781',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274833',
      rtl_batch_array: '6274833',
      name_search: 'CHDSOLEI',
      name: "Chrysanthemum (I) 'Dernier Soleil'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KEWC5YWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEWC5YWV'
    },
    {
      status: 210,
      sku: 'PM0074784',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274842',
      rtl_batch_array: '6274842',
      name_search: 'FOVGBRON',
      name: "Foeniculum vulgare 'Giant Bronze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EGNBBY2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGNBBY2T'
    },
    {
      status: 210,
      sku: 'PM0080300',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336335',
      rtl_batch_array: '6336335',
      name_search: 'YUFCGUAR',
      name: "Yucca filamentosa 'Color Guard'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 933,
      chnn_stock_retail: 933,
      sppl_prcp: 4.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XH8AP9EZ',
      statusContent: 'dot',
      hash: '28L8D8YK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28L8D8YK'
    },
    {
      status: 810,
      sku: 'PM0074785',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274844',
      rtl_batch_array: '6274844',
      name_search: 'GEDPURPL',
      name: "Geranium 'Daily Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z5WK8Y66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5WK8Y66'
    },
    {
      status: 210,
      sku: 'PM0080301',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336336',
      rtl_batch_array: '6336336',
      name_search: 'CEDFBLUE',
      name: "Cedrus deodara 'Feelin' Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 595,
      chnn_stock_retail: 595,
      sppl_prcp: 8.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRP5DSG3;S12VJSSR',
      statusContent: 'dot',
      hash: 'JH57E565',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH57E565'
    },
    {
      status: 210,
      sku: 'PM0023593',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305165',
      rtl_batch_array: '6084718, 6305165',
      name_search: 'HOBMEARS',
      name: "Hosta 'Blue Mouse Ears'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 351,
      chnn_stock_retail: 1159,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2E1G5LHE;ND8B2WD1;7HK8YPC8;YHV8ZW21;SJ223CCC;1Z9LVYDX;YKWX35TB;4L29LAZP;1T89L37W;DG8HH4DJ;L231S4X5;SRYZBLRV;JRLPYE44;59GZS2S1;CJGLX6BL;7JXT42S1;H8N2X89E;955W7G92',
      statusContent: 'dot',
      hash: 'W9RBKS7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9RBKS7X'
    },
    {
      status: 210,
      sku: 'PM0080302',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336337',
      rtl_batch_array: '6336337',
      name_search: 'CEDGHORI',
      name: "Cedrus deodara 'Golden Horizon'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_prcp: 8.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41VWZJ8H;5XEKK42H',
      statusContent: 'dot',
      hash: 'Z832BX5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z832BX5Z'
    },
    {
      status: 210,
      sku: 'PM0072457',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246572',
      rtl_batch_array: '6246572',
      name_search: 'CHPBBLUE',
      name: "Chamaecyparis pisifera 'Baby Blue'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 585,
      chnn_stock_retail: 585,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R6YKKLGE;J58NSP5J;BNLZ2YK3;TA433EW5;BDHVHWX8;LZLAWW74;WEKKWWEW',
      statusContent: 'dot',
      hash: 'A48VZX21',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A48VZX21'
    },
    {
      status: 810,
      sku: 'PM0074969',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353507',
      rtl_batch_array: '6353507',
      name_search: 'TIORHTIG',
      name: "Tigridia orthantha 'Red Hot Tiger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z79GYJBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z79GYJBX'
    },
    {
      status: 210,
      sku: 'PM0023960',
      core_name: 'Plant',
      sppl_ean: '8720664868274',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084721',
      rtl_batch_array: '6084721, 6112454, 6305175',
      name_search: 'HOPGLORY',
      name: "Hosta 'Paul's Glory'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1820,
      chnn_stock_retail: 2193,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XPLTEC46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPLTEC46'
    },
    {
      status: 210,
      sku: 'PM0074964',
      core_name: 'Plant',
      sppl_ean: '8720349420902',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431796',
      rtl_batch_array: '5431796',
      name_search: 'LAILAFAY',
      name: "Lagerstroemia indica 'Lafayette'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JL7HXTJ9;JTVX5KAY;HLZ5DDYS',
      statusContent: 'dot',
      hash: '6LPP32ZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LPP32ZT'
    },
    {
      status: 210,
      sku: 'PM0080287',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336310',
      rtl_batch_array: '6336310',
      name_search: 'SKJTGEM',
      name: "Skimmia japonica 'Tansley Gem'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6B343AW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6B343AW9'
    },
    {
      status: 210,
      sku: 'PM0080288',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336311',
      rtl_batch_array: '6336311',
      name_search: 'SKJVEITC',
      name: "Skimmia japonica 'Veitchii'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZVZPAPB1;S8YL1W23;L6R5EP53;1Z4STK23',
      statusContent: 'dot',
      hash: 'XK9317PX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XK9317PX'
    },
    {
      status: 210,
      sku: 'PM0080289',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336312',
      rtl_batch_array: '6336312',
      name_search: 'SKJWGLOB',
      name: 'Skimmia japonica White Globe',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2303,
      chnn_stock_retail: 2303,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PG75YP9D;R18Y8TVV;GLW4NCR8;HHT1113Y;WL392GE3;XVBB96RK;TER7LKGD;YV9JWPYB',
      statusContent: 'dot',
      hash: 'N2D1HXRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2D1HXRX'
    },
    {
      status: 210,
      sku: 'PM0080290',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336313',
      rtl_batch_array: '6336313',
      name_search: 'TRFORTUN',
      name: 'Trachycarpus fortunei',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4498,
      chnn_stock_retail: 4498,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X74TZTVW;HNW81PGZ;TR1RRV3C;Y2STVG6V;XLPR87S9;6WJPY8RX;72YCZRV6;HAW8S38G;L4TW44ZG;5A55S1YR;74GYT1ZB;2X49JZ5B;PH6RHEYR;BSPXTXZA;49CE93ZP;VT2CBK24;YPHTD5K8;ZD9GDRJT;HRVSZC68;RGZPWRXK;RDZNV767;CXXZL4XL;BRSKWHWY',
      statusContent: 'dot',
      hash: 'E6BKXTEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6BKXTEA'
    },
    {
      status: 210,
      sku: 'PM0080291',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336314',
      rtl_batch_array: '6336314',
      name_search: 'UNEVERFL',
      name: 'Uncinia Everflame',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4932,
      chnn_stock_retail: 4932,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WVCY4PJL;LGD2CB57',
      statusContent: 'dot',
      hash: 'KZB587CC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZB587CC'
    },
    {
      status: 210,
      sku: 'PM0072507',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6250996',
      rtl_batch_array: '6250996',
      name_search: 'KNMMMIDG',
      name: "Knautia macedonica 'Mars Midget'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TW7E4LS3;KS2XVVWH;KEDLTYB3;8ZJX57ZD;PA95A67T',
      statusContent: 'dot',
      hash: '4CVSNWJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CVSNWJY'
    },
    {
      status: 210,
      sku: 'PM0080292',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336315',
      rtl_batch_array: '6336315',
      name_search: 'VIBARUSS',
      name: "Viburnum burkwoodii 'Anne Russell'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AH9XESB;KLENXA51',
      statusContent: 'dot',
      hash: 'PAC5PGS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAC5PGS6'
    },
    {
      status: 210,
      sku: 'PM0082590',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353525',
      rtl_batch_array: '6353525',
      name_search: 'TRRPENTA',
      name: "Trifolium repens 'Pentaphyllum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 405,
      chnn_stock_retail: 405,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5JZSLXZ3',
      statusContent: 'dot',
      hash: 'B3HSH7WW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3HSH7WW'
    },
    {
      status: 210,
      sku: 'PM0074942',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275039',
      rtl_batch_array: '6275039',
      name_search: 'CRYJAPON',
      name: 'Cryptotaenia japonica',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 604,
      chnn_stock_retail: 604,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZCC3YTB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCC3YTB4'
    },
    {
      status: 210,
      sku: 'PM0074943',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275040',
      rtl_batch_array: '6275040',
      name_search: 'CRJCRIST',
      name: "Cryptomeria japonica 'Cristata'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 448,
      chnn_stock_retail: 448,
      sppl_prcp: 6.564,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZD4RZ3VC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD4RZ3VC'
    },
    {
      status: 210,
      sku: 'PM0074944',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275041',
      rtl_batch_array: '6275041',
      name_search: 'CRJCRIST',
      name: "Cryptomeria japonica 'Cristata'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 284,
      chnn_stock_retail: 284,
      sppl_prcp: 7.164,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9R2V2Y7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9R2V2Y7C'
    },
    {
      status: 210,
      sku: 'PM0074946',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275043',
      rtl_batch_array: '6275043',
      name_search: 'CRJJINDA',
      name: "Cryptomeria japonica 'Jindai'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 464,
      chnn_stock_retail: 464,
      sppl_prcp: 7.164,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CAEBTYSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAEBTYSE'
    },
    {
      status: 210,
      sku: 'PM0074948',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275045',
      rtl_batch_array: '6275045',
      name_search: 'CRJLCHAM',
      name: "Cryptomeria japonica 'Little Champion'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_prcp: 6.964,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5RACXHR;2BWLBDNG',
      statusContent: 'dot',
      hash: 'E7XYBSP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7XYBSP5'
    },
    {
      status: 210,
      sku: 'PM0074949',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275046',
      rtl_batch_array: '6275046',
      name_search: 'CRJLOBBI',
      name: "Cryptomeria japonica 'Lobbii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 433,
      chnn_stock_retail: 433,
      sppl_prcp: 7.164,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LD634N4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LD634N4C'
    },
    {
      status: 210,
      sku: 'PM0074950',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275047',
      rtl_batch_array: '6275047',
      name_search: 'CRJRASEN',
      name: "Cryptomeria japonica 'Rasen'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 7.164,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1D7GZN;JNVBLK37;DB633HJG',
      statusContent: 'dot',
      hash: '138J772Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '138J772Z'
    },
    {
      status: 210,
      sku: 'PM0074951',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275053',
      rtl_batch_array: '6275053',
      name_search: 'SCVERTIC',
      name: 'Sciadopitys verticillata',
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 748,
      chnn_stock_retail: 748,
      sppl_prcp: 10.864,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CBN1HY6;N6RBRE96;A3AAXPA6;9J7NWD3V;9X3DVLJP;D7NER187',
      statusContent: 'dot',
      hash: '5AY8DTCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AY8DTCD'
    },
    {
      status: 910,
      sku: 'PM0082591',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      rng_range_identifier: 'H030040C1',
      rng_range_description: 'H30 cm 40 cm C1',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'HWKXPWTE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HWKXPWTE'
    },
    {
      status: 810,
      sku: 'PM0074953',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275060',
      rtl_batch_array: '6275060',
      name_search: 'SCVGKUGE',
      name: "Sciadopitys verticillata 'Gr\u00fcne Kugel'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 27.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DS4LB5XZ;5RYDS4GP;R8ZJG4C3;PZH9H342',
      statusContent: 'dot',
      hash: 'ZY2EL6P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY2EL6P4'
    },
    {
      status: 910,
      sku: 'PM0074954',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275061',
      rtl_batch_array: '6275061',
      name_search: 'SCVGKUGE',
      name: "Sciadopitys verticillata 'Gr\u00fcne Kugel'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 32.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DS4LB5XZ;5RYDS4GP;R8ZJG4C3;PZH9H342',
      statusContent: 'dot',
      hash: 'LKTEWJ1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKTEWJ1G'
    },
    {
      status: 810,
      sku: 'PM0074955',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275063',
      rtl_batch_array: '6275063',
      name_search: 'SCVGKUGE',
      name: "Sciadopitys verticillata 'Gr\u00fcne Kugel'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 16.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DS4LB5XZ;5RYDS4GP;R8ZJG4C3;PZH9H342',
      statusContent: 'dot',
      hash: 'LGG8XXKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGG8XXKC'
    },
    {
      status: 810,
      sku: 'PM0074956',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275064',
      rtl_batch_array: '6275064',
      name_search: 'SCVPICOL',
      name: "Sciadopitys verticillata 'Picola'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 12.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJPAES53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJPAES53'
    },
    {
      status: 810,
      sku: 'PM0074957',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275065',
      rtl_batch_array: '6275065',
      name_search: 'SCVPICOL',
      name: "Sciadopitys verticillata 'Picola'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 19.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1EN6Y2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1EN6Y2J'
    },
    {
      status: 210,
      sku: 'PM0074958',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275066',
      rtl_batch_array: '6275066',
      name_search: 'SCVSTERN',
      name: "Sciadopitys verticillata 'Sternschnuppe'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_prcp: 15.114,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1KT2S19G',
      statusContent: 'dot',
      hash: '57CEPNPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57CEPNPJ'
    },
    {
      status: 910,
      sku: 'PM0082592',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPBUTTE',
      name: "Acer palmatum 'Butterfly'",
      rng_range_identifier: 'H030040C1',
      rng_range_description: 'H30 cm 40 cm C1',
      imageCore:
        'Z8LTJ8S9;AG3R7G4L;W113J7X5;9W94Y6HL;4D9B3KT2;L1R2L9G9;81S3BYXS;EZE7H3GR;RD4HPXTL;55Z5SL7X',
      statusContent: 'dot',
      hash: 'XEE1W1AN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XEE1W1AN'
    },
    {
      status: 210,
      sku: 'PM0080293',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336316',
      rtl_batch_array: '6336316',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 4.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'JLLSR2AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLLSR2AT'
    },
    {
      status: 810,
      sku: 'PM0074961',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275070',
      rtl_batch_array: '6275070',
      name_search: 'SCVSTERN',
      name: "Sciadopitys verticillata 'Sternschnuppe'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 17.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1KT2S19G',
      statusContent: 'dot',
      hash: 'HKSLSRJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKSLSRJS'
    },
    {
      status: 210,
      sku: 'PM0074962',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275375',
      rtl_batch_array: '6275375',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '200225C20',
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 28.387,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'PEZ5BYJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEZ5BYJV'
    },
    {
      status: 210,
      sku: 'PM0074963',
      core_name: 'Plant',
      sppl_ean: '8720626356108',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431795',
      rtl_batch_array: '5431795',
      name_search: 'LAICMAGI',
      name: "Lagerstroemia indica 'Coral Magic'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 411,
      chnn_stock_retail: 411,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANXN6A3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANXN6A3K'
    },
    {
      status: 210,
      sku: 'PM0074965',
      core_name: 'Plant',
      sppl_ean: '8720664804968',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431813',
      rtl_batch_array: '5431813',
      name_search: 'RHMARLIS',
      name: "Rhododendron (Y) 'Marlis'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_prcp: 6.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WA62K52S;1ZT6Y6KK;4VP2SHN6',
      statusContent: 'dot',
      hash: 'TN92SBNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TN92SBNE'
    },
    {
      status: 810,
      sku: 'PM0074966',
      core_name: 'Plant',
      sppl_ean: '8720664696082',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5468020',
      rtl_batch_array: '5468020',
      name_search: 'LAEPINK',
      name: 'Lagerstroemia Enduring Pink',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 7.264,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RAZBSDEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAZBSDEB'
    },
    {
      status: 810,
      sku: 'PM0074967',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276338',
      rtl_batch_array: '5562787, 6276338',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: '4LXXB966',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LXXB966'
    },
    {
      status: 210,
      sku: 'PM0080294',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336325',
      rtl_batch_array: '6336325',
      name_search: 'VITLISSP',
      name: "Viburnum tinus 'Lisspurp'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1437,
      chnn_stock_retail: 1437,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TL1LBEJX',
      statusContent: 'dot',
      hash: 'TJHWTEHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJHWTEHY'
    },
    {
      status: 210,
      sku: 'PM0074972',
      core_name: 'Plant',
      sppl_ean: '8720664698413',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6067922',
      rtl_batch_array: '6067922',
      name_search: 'LOPMGREE',
      name: "Lonicera pileata 'Moss Green'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5KZ87XH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KZ87XH3'
    },
    {
      status: 910,
      sku: 'PM0082593',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPAGGRE',
      name: 'Acer pal. Going Green',
      rng_range_identifier: 'H030040C1',
      rng_range_description: 'H30 cm 40 cm C1',
      statusContent: 'dot',
      hash: 'R1WX2DX5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R1WX2DX5'
    },
    {
      status: 210,
      sku: 'PM0074945',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275042',
      rtl_batch_array: '6275042',
      name_search: 'CRJGNANA',
      name: "Cryptomeria japonica 'Globosa Nana'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 623,
      chnn_stock_retail: 623,
      sppl_prcp: 6.964,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19EK1K7W;2N7WG8EX;8S2AWC5Z;DRHNHG2E',
      statusContent: 'dot',
      hash: 'S38HP7LY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S38HP7LY'
    },
    {
      status: 810,
      sku: 'PM0074947',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275044',
      rtl_batch_array: '6275044',
      name_search: 'CRJKGOLD',
      name: "Cryptomeria japonica 'Kyara Gold'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 7.164,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '22SNDV7Y;G1ZJ7YCV;D5T3K8Y6;8H3RLDHR;D8LXS59N;VP8RD5LS;2TLVXY41',
      statusContent: 'dot',
      hash: 'NNGHHJVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NNGHHJVN'
    },
    {
      status: 210,
      sku: 'PM0074780',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300413',
      rtl_batch_array: '6300413, 6274824',
      name_search: 'ALPAUREO',
      name: "Alopecurus pratensis 'Aureovariegatus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 285,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7VJDX5AR;85ERE77P',
      statusContent: 'dot',
      hash: 'BW9ZDL61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BW9ZDL61'
    },
    {
      status: 210,
      sku: 'PM0021231',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315046',
      rtl_batch_array: '6084852, 6315046',
      name_search: 'CAOJSGRE',
      name: "Carex oshimensis 'J. S. Greenwell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3259,
      chnn_stock_retail: 3475,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WND8H241',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WND8H241'
    },
    {
      status: 910,
      sku: 'PM0082594',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      rng_range_identifier: 'H030040C1',
      rng_range_description: 'H30 cm 40 cm C1',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'C6J6GPTB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C6J6GPTB'
    },
    {
      status: 210,
      sku: 'PM0080295',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336326',
      rtl_batch_array: '6336326',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4698,
      chnn_stock_retail: 4698,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: 'ET5B177Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET5B177Y'
    },
    {
      status: 210,
      sku: 'PM0080296',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6341917',
      rtl_batch_array: '6336327, 6341917',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 840,
      chnn_stock_retail: 1137,
      sppl_prcp: 3.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: '13KB6HWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13KB6HWB'
    },
    {
      status: 210,
      sku: 'PM0080297',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336332',
      rtl_batch_array: '6336332',
      name_search: 'VIMILLUM',
      name: 'Vinca minor Illumination',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HBJ4J7S;LZ93WVDK;CYKHBWP1',
      statusContent: 'dot',
      hash: 'JP1H4TG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP1H4TG8'
    },
    {
      status: 910,
      sku: 'PM0072455',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAHHUMIL',
      name: 'Sarcococca hookeriana humilis',
      rng_range_identifier: 'H020025C2.5',
      rng_range_description: 'H20 cm 25 cm C2.5',
      imageCore:
        '8129VXKJ;CAEVYGPS;P2B7KXZW;NCL6CE76;21AB2EWV;C2T4155L;VHE3YL9H;XZDDJKXE;B53W7ND6;YKWKZYAY;ZZXP9NHA;GY4LK5GN;HWH2S3DP;T8RNDZNT',
      statusContent: 'dot',
      hash: 'B82DSWND',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'B82DSWND'
    },
    {
      status: 210,
      sku: 'PM0072458',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246576',
      rtl_batch_array: '6246576',
      name_search: 'JUHGCARP',
      name: "Juniperus horizontalis 'Golden Carpet'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1089,
      chnn_stock_retail: 1089,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EC11Y2YA;SPHWAJ25;HT4WAYAB;1T5VN85Y',
      statusContent: 'dot',
      hash: 'JPNSCNS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPNSCNS6'
    },
    {
      status: 210,
      sku: 'PM0072459',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246577',
      rtl_batch_array: '6246577',
      name_search: 'JUHWILTO',
      name: "Juniperus horizontalis 'Wiltonii'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTXR91JA;XE2PNDN2;79XC8391;KA6JEJWN;TTPAJR27;5DLCNL9T;8PDW76PN;77XT6W5A;171G33ND;HCB8CRHL;PKNT5STB;SX87XJ56',
      statusContent: 'dot',
      hash: 'W8JXWE2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8JXWE2P'
    },
    {
      status: 210,
      sku: 'PM0072461',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246583',
      rtl_batch_array: '6246583',
      name_search: 'SEGIGANT',
      name: 'Sequoiadendron giganteum',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1769,
      chnn_stock_retail: 1769,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P557YA81;RNTX9CH7;XDG2S73D',
      statusContent: 'dot',
      hash: 'GSC8XTYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSC8XTYJ'
    },
    {
      status: 210,
      sku: 'PM0072463',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246585',
      rtl_batch_array: '6246585',
      name_search: 'THOGTUFF',
      name: "Thuja occidentalis 'Golden Tuffet'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 677,
      chnn_stock_retail: 677,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6YT63PXP;NAPXD1VW;PA7B5RVB;7GBK7CKA;VGT853C5;N67PK32Y;HYBN8NHS;XCC3S29L;PHLH3AWC;9EV6ESKJ;R5DY2VP5;5LSX1SDB;4E9CTASH;SAVKBA9P;EA2653D8;LRS7D92P',
      statusContent: 'dot',
      hash: '4W46LCG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W46LCG4'
    },
    {
      status: 210,
      sku: 'PM0072462',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246584',
      rtl_batch_array: '6246584',
      name_search: 'THOGSMAR',
      name: 'Thuja occidentalis Golden Smaragd',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 3753,
      chnn_stock_retail: 3753,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YXRWAKD;AS33KGY6;5JZEB95L;28KYJJCV;TCZ8BWKR',
      statusContent: 'dot',
      hash: '46C8D2H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46C8D2H5'
    },
    {
      status: 210,
      sku: 'PM0082595',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6354770',
      rtl_batch_array: '6354167, 6354770',
      name_search: 'BUDBCLLI',
      name: 'Buddleja d. B. Candy Little Lila',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 154,
      chnn_stock_retail: 3154,
      sppl_prcp: 2.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RYSTJZWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYSTJZWJ'
    },
    {
      status: 210,
      sku: 'PM0082596',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6354773',
      rtl_batch_array: '6354168, 6354773',
      name_search: 'BUDBCLSW',
      name: 'Buddleja d. B. Candy Lila Sweeth.',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 109,
      chnn_stock_retail: 3109,
      sppl_prcp: 2.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JTRXBWH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTRXBWH5'
    },
    {
      status: 210,
      sku: 'PM0082597',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354169',
      rtl_batch_array: '6354169',
      name_search: 'BUDBCLPU',
      name: 'Buddleja d. B. Candy Little Purple',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 5.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T9HYVE2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9HYVE2T'
    },
    {
      status: 210,
      sku: 'PM0082598',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354170',
      rtl_batch_array: '6354170',
      name_search: 'BUDBCLPI',
      name: 'Buddleja d. B. Candy Little Pink',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 5.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8XRS34DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XRS34DN'
    },
    {
      status: 210,
      sku: 'PM0074970',
      core_name: 'Plant',
      sppl_ean: '8720626338135',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5956042',
      rtl_batch_array: '5956042',
      name_search: 'CASHIRYU',
      name: "Camellia sasanqua 'Hiryu'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_prcp: 8.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EBS5DS5R',
      statusContent: 'dot',
      hash: '2WH7L1KE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WH7L1KE'
    },
    {
      status: 210,
      sku: 'PM0027233',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6274849',
      rtl_batch_array: '6034348, 6274849, 6333312',
      name_search: 'GETMONST',
      name: "Geranium 'Tiny Monster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5982,
      chnn_stock_retail: 8568,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7YYRP71K',
      statusContent: 'dot',
      hash: '1WP78AXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WP78AXR'
    },
    {
      status: 210,
      sku: 'PM0019862',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274542',
      rtl_batch_array: '6274542',
      name_search: 'MOATRANS',
      name: "Molinia arundinacea 'Transparent'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 451,
      chnn_stock_retail: 451,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALG98ZE6;GCJGRDK7;SHRZ2K6R;7JPK1P1J;417K9K93;S3N8HNWN;TBBET22K;HEJYBATD;H29E7PZH',
      statusContent: 'dot',
      hash: '4Z8D5KG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z8D5KG9'
    },
    {
      status: 210,
      sku: 'PM0082599',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354173',
      rtl_batch_array: '6354173',
      name_search: 'BUDBCLRU',
      name: 'Buddleja d. B. Candy Little Ruby',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 5.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JWAHR118',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWAHR118'
    },
    {
      status: 210,
      sku: 'PM0001190',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 5,
      btch_active_retail: '6356252',
      rtl_batch_array: '6192820, 5249896, 6254399, 6356252, 6295933',
      name_search: 'LIMRPURP',
      name: "Liriope muscari 'Royal Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 8747,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W3S248BN;8N2CWD95;9H9W471D;J5VXH9RL;2G2S2Y4Z;2EWHENLB;2SHZ7RA5;XJGK78BG;KK5N2A12;BAK8CGP8;7X377R9C;2P6S4VVA;2CDKR85G;A4BZ442G;12YV6XXV;CDRL3R2Z',
      statusContent: 'dot',
      hash: '6JHDN73Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JHDN73Y'
    },
    {
      status: 210,
      sku: 'PM0018388',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254786',
      rtl_batch_array: '2681756, 6254786',
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 680,
      sppl_prcp: 2.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E7VK3LN;DDBGZZBL;RGW28J9J;DSCH93GV;7XNJSA12;VW9TY6J4;2KD13C5X;ST5LA5JB;VCJDWHKT;67YR1HE5;YP168CCT;NYHA6TZT;2HHZ78SB;5X8SL82N;ATGY8842;Z7PLP1S1;8JVZEBCA;LXJVKK55;5RR83H12;9Z1YAEDY;WN8K8NXK',
      statusContent: 'dot',
      hash: '72W21DC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72W21DC7'
    },
    {
      status: 210,
      sku: 'PM0025415',
      core_name: 'Plant',
      sppl_ean: '8720349480470',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495527',
      rtl_batch_array: '6307978, 5495527, 6352609',
      name_search: 'ANHSANGE',
      name: 'Anemone hupehensis Snow Angel',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 577,
      chnn_stock_retail: 1351,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W1XLESG9',
      statusContent: 'dot',
      hash: 'BBVC6ZVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBVC6ZVW'
    },
    {
      status: 210,
      sku: 'PM0002123',
      core_name: 'Plant',
      sppl_ean: '8720626302204',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5652889',
      rtl_batch_array: '5652889',
      name_search: 'GYCERAST',
      name: 'Gypsophila cerastioides',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWKB6DSE;KZPT6TLB;EDW7CR2R;75KAT99P;6WRACASL;Y7ZWZR82;L9YG6L4R;T23ERPV7;T6LSZ8DB;WJ1NSD6B;5CWE913P;8B5V9SSG;CD318B8L;SDD7XDBW;6WTHLAT2;171RN2XB',
      imageBatch: 'GHK5WDTX',
      statusContent: 'dot',
      hash: 'Y57X955T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y57X955T'
    },
    {
      status: 910,
      sku: 'PM0010965',
      core_name: 'Plant',
      sppl_ean: '8720349433049',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '4897184',
      rtl_batch_array: '4897184, 5972564',
      name_search: 'SETECTOR',
      name: 'Sempervivum tectorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8,
      chnn_stock_retail: 1208,
      sppl_order_minimum: 3,
      sppl_prcp: 0.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G66RSTJZ;LZPKTWB5;7PE8TXDB;HJ5ZZE5S;L8EHVZCC;N69W7AA6',
      statusContent: 'dot',
      hash: 'ZV72DT6J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV72DT6J'
    },
    {
      status: 210,
      sku: 'PM0082600',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354174',
      rtl_batch_array: '6354174',
      name_search: 'CABLACE',
      name: "Camellia 'Black Lace'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 8.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7NCT7ANE;KK2Z8SW5;A7DJG9VE',
      statusContent: 'dot',
      hash: 'RHGY13DG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHGY13DG'
    },
    {
      status: 210,
      sku: 'PM0082601',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354175',
      rtl_batch_array: '6354175',
      name_search: 'CAJBONOM',
      name: "Camellia japonica 'Bonomiana'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 8.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZ3DS68;JSGTAK82;NR8GNDYA;WPBGN1NK',
      statusContent: 'dot',
      hash: '89JRVC5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89JRVC5R'
    },
    {
      status: 210,
      sku: 'PM0082602',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378160',
      rtl_batch_array: '6378160',
      name_search: 'CAJNCAME',
      name: "Camellia japonica 'Nuccio's Cameo'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 8.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K2BXVN1;2CCD8NE7;92TBYA6X;JLHZ2DBS;1EB9C7C7',
      statusContent: 'dot',
      hash: '5APAHSWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5APAHSWX'
    },
    {
      status: 210,
      sku: 'PM0082603',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354177',
      rtl_batch_array: '6354177',
      name_search: 'CAJNGEM',
      name: "Camellia japonica 'Nuccio's Gem'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 8.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX6Y2LNB',
      statusContent: 'dot',
      hash: '1WW6NRX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WW6NRX1'
    },
    {
      status: 210,
      sku: 'PM0082604',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354178',
      rtl_batch_array: '6354178',
      name_search: 'CAVYULET',
      name: "Camellia vernalis 'Yuletide'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 8.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LENECE84;9RGYG49N;VBRESDLL',
      statusContent: 'dot',
      hash: 'AV8LNXP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AV8LNXP1'
    },
    {
      status: 210,
      sku: 'PM0082605',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354179',
      rtl_batch_array: '6354179',
      name_search: 'CABLACE',
      name: "Camellia 'Black Lace'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7NCT7ANE;KK2Z8SW5;A7DJG9VE',
      statusContent: 'dot',
      hash: 'SG8GK3ZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG8GK3ZK'
    },
    {
      status: 210,
      sku: 'PM0082606',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354181',
      rtl_batch_array: '6354181',
      name_search: 'CARCUPID',
      name: 'Camellia rosthorniana Cupido',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7JHZS382',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JHZS382'
    },
    {
      status: 210,
      sku: 'PM0082607',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354182',
      rtl_batch_array: '6354182',
      name_search: 'CAVYULET',
      name: "Camellia vernalis 'Yuletide'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LENECE84;9RGYG49N;VBRESDLL',
      statusContent: 'dot',
      hash: 'TC62R54K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TC62R54K'
    },
    {
      status: 210,
      sku: 'PM0082608',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354184',
      rtl_batch_array: '6354184',
      name_search: 'CAJDKING',
      name: "Camellia japonica 'Dr King'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 36.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z84V5AG7;KBH3YV7B;G6BLA2AS;P3XY1A7H;7TYH9PD4;P96HL321;CL6RG5BV;PDLSZJYK;1BNBP9XA;8LCXKEXS;6SKS2JC2;Y7DBVSXD;7B1PATVE;HAYAYEHY;KPHV6YXN',
      statusContent: 'dot',
      hash: '7ZRR67G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZRR67G5'
    },
    {
      status: 210,
      sku: 'PM0082609',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354185',
      rtl_batch_array: '6354185',
      name_search: 'HYKSBOUL',
      name: 'Hypericum kalmianum Sunny Boulevard',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 5.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YL1NDPBX;RBHWZ3S8;VJ9242TV;9R72HSRV',
      statusContent: 'dot',
      hash: 'N6DK635J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6DK635J'
    },
    {
      status: 210,
      sku: 'PM0082610',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354186',
      rtl_batch_array: '6354186',
      name_search: 'NADMSUNR',
      name: 'Nandina domestica Magical Sunrise',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 6.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L5CBVLDE',
      statusContent: 'dot',
      hash: '6RG4HYZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RG4HYZC'
    },
    {
      status: 210,
      sku: 'PM0082611',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354187',
      rtl_batch_array: '6354187',
      name_search: 'RHWROSES',
      name: "Rhododendron 'Wine and Roses'",
      sppl_size_code: '030040P12',
      rng_range_identifier: 'H030040P12',
      rng_range_description: 'H30 cm 40 cm P12',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 3.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HABNBDPG;7T9TD92K;BKGPNDXG;RXVVAL16;K8W6R79K;WT263AST;59251Z7L;WVXDVNNZ',
      statusContent: 'dot',
      hash: 'XZ1N1EV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZ1N1EV7'
    },
    {
      status: 210,
      sku: 'PM0082612',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354189',
      rtl_batch_array: '6354189',
      name_search: 'RHTTEYES',
      name: 'Rhus typhina Tiger Eyes',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6LLKL2VR;L7PNYL2E;1TD1GDPD;KE6DWYDC;5HE61VVJ;JPWBW8GS;AD472CW1;2S71GB2L;C6RT8519;9VSNLXX4;BDP894LE;B3PT3ZXH;B8VCJK9A;VZW3K6TG;KK76JA2S',
      statusContent: 'dot',
      hash: 'K9L31Y37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9L31Y37'
    },
    {
      status: 210,
      sku: 'PM0082613',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354190',
      rtl_batch_array: '6354190',
      name_search: 'SIRVENUS',
      name: "Sinocalycanthus raul. 'Venus'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2C3JVPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2C3JVPS'
    },
    {
      status: 210,
      sku: 'PM0082614',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354191',
      rtl_batch_array: '6354191',
      name_search: 'SKJMARLO',
      name: "Skimmia j. 'Marlot'",
      sppl_size_code: '010015C1',
      rng_range_identifier: 'H010015C1',
      rng_range_description: 'H10 cm 15 cm C1',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1RRR9DL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RRR9DL2'
    },
    {
      status: 210,
      sku: 'PM0082615',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354192',
      rtl_batch_array: '6354192',
      name_search: 'SKJMMARL',
      name: "Skimmia japonica 'Magic Marlot'",
      sppl_size_code: '010015C1',
      rng_range_identifier: 'H010015C1',
      rng_range_description: 'H10 cm 15 cm C1',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YKGZNVPD;W23YZCS9;KK9LBZPG;6PW28LGB;WZ124V2E;SAE46Z5A;8WAJPSK1;Z83XKVZ6;VBGL1CVB;8XJZENNL;194JPLG8;HARN42X6;RCWTP4RB',
      statusContent: 'dot',
      hash: 'YPVW8JJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPVW8JJL'
    },
    {
      status: 210,
      sku: 'PM0082616',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354193',
      rtl_batch_array: '6354193',
      name_search: 'SKIJWDWA',
      name: 'Skimmia japonica White Dwarf',
      sppl_size_code: '010015C1',
      rng_range_identifier: 'H010015C1',
      rng_range_description: 'H10 cm 15 cm C1',
      sppl_stock_available: 7000,
      chnn_stock_retail: 7000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T3JKXA6E;LCVXVLRL;YBD267AD;Z4CBCTKG;K76S9L1Z;2X4261SL',
      statusContent: 'dot',
      hash: '296ERLXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '296ERLXE'
    },
    {
      status: 210,
      sku: 'PM0082617',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354194',
      rtl_batch_array: '6354194',
      name_search: 'SKJMMARL',
      name: "Skimmia japonica 'Magic Marlot'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YKGZNVPD;W23YZCS9;KK9LBZPG;6PW28LGB;WZ124V2E;SAE46Z5A;8WAJPSK1;Z83XKVZ6;VBGL1CVB;8XJZENNL;194JPLG8;HARN42X6;RCWTP4RB',
      statusContent: 'dot',
      hash: '5PRT8CSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PRT8CSW'
    },
    {
      status: 810,
      sku: 'PM0080306',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336341',
      rtl_batch_array: '6336341',
      name_search: 'LISGBALL',
      name: "Liquidambar styraciflua 'Gum Ball'",
      sppl_size_code: '060STC10',
      rng_range_identifier: 'STE060C10',
      rng_range_description: 'Stem 60 cm C10',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 21.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AD1C8L74;JCG6GR11;2426BGCE;GZ1LLWGC;J5TP2RT4;T4K8717V;9XXEJV2L;LCW7P16A;91LGRC49',
      statusContent: 'dot',
      hash: 'ZPBPX7D2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPBPX7D2'
    },
    {
      status: 210,
      sku: 'PM0021712',
      core_name: 'Plant',
      sppl_ean: '8720664849792',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6090573',
      rtl_batch_array: '6090573',
      name_search: 'ACMICROP',
      name: 'Acaena microphylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2587,
      chnn_stock_retail: 2587,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7D2ZTJHD',
      statusContent: 'dot',
      hash: 'JJXT73CC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJXT73CC'
    },
    {
      status: 210,
      sku: 'PM0005789',
      core_name: 'Plant',
      sppl_ean: '8720664512955',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5997965',
      rtl_batch_array: '5997965',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'TWTDBTY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWTDBTY6'
    },
    {
      status: 210,
      sku: 'PM0074787',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382321',
      rtl_batch_array: '6353069, 6274854, 6382321',
      name_search: 'PEABBEAU',
      name: "Pennisetum alopecuroides 'Black Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8450,
      chnn_stock_retail: 11637,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EJE78NRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJE78NRH'
    },
    {
      status: 210,
      sku: 'PM0023249',
      core_name: 'Plant',
      sppl_ean: '8720664699472',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905571',
      rtl_batch_array: '5905571',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      statusContent: 'dot',
      hash: 'XJJRV5HB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJJRV5HB'
    },
    {
      status: 210,
      sku: 'PM0074788',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382324',
      rtl_batch_array: '6353076, 6274857, 6257788, 6382324',
      name_search: 'PEAREDHE',
      name: "Pennisetum alopecuroides 'Redhead'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 17750,
      chnn_stock_retail: 21589,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5HWBHBZC',
      statusContent: 'dot',
      hash: 'Z6ARGPJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6ARGPJY'
    },
    {
      status: 210,
      sku: 'PM0074789',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274859',
      rtl_batch_array: '6274859',
      name_search: 'PEKROSE',
      name: "Pennisetum 'Karley Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 631,
      chnn_stock_retail: 631,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D19LRXJV;D8A55SKN;51DX5VWV;TX31G2TD;8EDGRZ6C;HAX7A9XG;XJ45L2TN;ZYPGA3EG;4HD962YL;P39YTXE2;NZHS679A;ND417A7H;B85TRYP5;9VYJK25V;AHCZZD8E;NS7YTJ9Z;6KGTCDDT;6Z1YN71D;BZRC2KG5;XTLDN8N3;PL6ALNTB;YTZ2TPBR;X6CKBENZ',
      statusContent: 'dot',
      hash: 'J2TC1EWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2TC1EWG'
    },
    {
      status: 810,
      sku: 'PM0020367',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274780',
      rtl_batch_array: '6274780',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.236,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'DL41HACJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL41HACJ'
    },
    {
      status: 210,
      sku: 'PM0021513',
      core_name: 'Plant',
      sppl_ean: '8720349419821',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '4679845',
      rtl_batch_array: '4679845',
      name_search: 'JUENSIFO',
      name: 'Juncus ensifolius',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1PAVS63C;4SVHXCTY;EJ5C7BY9;5AAZ1N5G;LXZ4TTL3;S67N84WL;7RP8CECD',
      imageBatch: 'NX35CD24',
      statusContent: 'dot',
      hash: 'SETW9KZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SETW9KZH'
    },
    {
      status: 210,
      sku: 'PM0075055',
      core_name: 'Plant',
      sppl_ean: '8720349404629',
      btch_manufacturer: 469,
      rtl_batch_array_total: 2,
      btch_active_retail: '5417921',
      rtl_batch_array: '5417921, 6337830',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 1050,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: '85ZRP995',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85ZRP995'
    },
    {
      status: 210,
      sku: 'PM0072465',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246590',
      rtl_batch_array: '6246590',
      name_search: 'CHLSUNKI',
      name: "Chamaecyparis lawsoniana 'Sunkist'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '74HKX81L;TC8D3PRS',
      statusContent: 'dot',
      hash: '73T8BCN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73T8BCN4'
    },
    {
      status: 210,
      sku: 'PM0072466',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246591',
      rtl_batch_array: '6246591',
      name_search: 'CHNAMOUN',
      name: "Chamaecyparis nootkatensis 'Aldrich Mountain'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 589,
      chnn_stock_retail: 589,
      sppl_prcp: 4.359,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SAVEVZYP',
      statusContent: 'dot',
      hash: 'RA7CWLV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA7CWLV6'
    },
    {
      status: 210,
      sku: 'PM0082618',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354196',
      rtl_batch_array: '6354196',
      name_search: 'SKJRUBES',
      name: 'Skimmia japonica Rubesta',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4N2ETKE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4N2ETKE9'
    },
    {
      status: 910,
      sku: 'PM0075133',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      rng_range_identifier: 'H060080C7.5',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: '1G7DRJPN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1G7DRJPN'
    },
    {
      status: 810,
      sku: 'PM0075109',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307383',
      rtl_batch_array: '6307383',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'KEDJHEZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEDJHEZD'
    },
    {
      status: 210,
      sku: 'PM0075108',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307382',
      rtl_batch_array: '6307382',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_prcp: 29.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: '4G65C43A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4G65C43A'
    },
    {
      status: 210,
      sku: 'PM0026286',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298711',
      rtl_batch_array: '6298711',
      name_search: 'PAVOAUTU',
      name: "Panicum virgatum 'Oxblood Autumn'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 967,
      chnn_stock_retail: 967,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJ724ALH;G8XXNJTS;1AR591A8;C8XJ24KZ;CG15CZ98;HT318KKT',
      statusContent: 'dot',
      hash: '8A37SPTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8A37SPTT'
    },
    {
      status: 210,
      sku: 'PM0008066',
      core_name: 'Plant',
      sppl_ean: '8720353009582',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5501730',
      rtl_batch_array: '5501730',
      name_search: 'LAIWLBAB',
      name: 'Lagerstroemia indica With Love Babe',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1137,
      chnn_stock_retail: 1137,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'V2PE4A9X',
      statusContent: 'dot',
      hash: 'SWC5DNVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWC5DNVZ'
    },
    {
      status: 210,
      sku: 'PM0072464',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246588',
      rtl_batch_array: '6246588',
      name_search: 'CEDGHORI',
      name: "Cedrus deodara 'Golden Horizon'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 131,
      chnn_stock_retail: 131,
      sppl_prcp: 7.446,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41VWZJ8H;5XEKK42H',
      statusContent: 'dot',
      hash: 'K6E43CKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6E43CKR'
    },
    {
      status: 810,
      sku: 'PM0075057',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345457',
      rtl_batch_array: '6345457',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_prcp: 16.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'YHB1N46P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHB1N46P'
    },
    {
      status: 210,
      sku: 'PM0075051',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 3,
      btch_active_retail: '6337875',
      rtl_batch_array: '6268348, 6337875, 6349004',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 3040,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: '9YX14S5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YX14S5L'
    },
    {
      status: 210,
      sku: 'PM0075052',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268349',
      rtl_batch_array: '6268349',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 2.952,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'YEPPS29X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEPPS29X'
    },
    {
      status: 210,
      sku: 'PM0072467',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258822',
      rtl_batch_array: '6258822',
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 4592,
      chnn_stock_retail: 4592,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV7A8VKK;2D7HYSVP;5E11VCT7;WEDN342H;6CBCVY9E;DRD2X2DT;5R8EASNG;TT28BTWY;2189SZS3;YZGT72RE;1BG4Z21C;RSHDGCEX;S1V6CXEZ;PW1TVV1A;27N88R7Y',
      statusContent: 'dot',
      hash: '8BXJ3V8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BXJ3V8Y'
    },
    {
      status: 910,
      sku: 'PM0075106',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345475',
      rtl_batch_array: '6345475',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '150175C20SO',
      rng_range_identifier: 'SPECH150175C20',
      rng_range_description: 'Specimen H150 cm 175 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 29.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'YHLG6D2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHLG6D2P'
    },
    {
      status: 810,
      sku: 'PM0075105',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345474',
      rtl_batch_array: '6345474',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '125150C20SO',
      rng_range_identifier: 'SPECH125150C20',
      rng_range_description: 'Specimen H125 cm 150 cm C20',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 25.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: '9612JE25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9612JE25'
    },
    {
      status: 810,
      sku: 'PM0075056',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346675',
      rtl_batch_array: '6346675',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '125150C25SO',
      rng_range_identifier: 'SPECH125150C25',
      rng_range_description: 'Specimen H125 cm 150 cm C25',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 33.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'ASADCS18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASADCS18'
    },
    {
      status: 210,
      sku: 'PM0072469',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246678',
      rtl_batch_array: '6246678',
      name_search: 'PIFSILVE',
      name: "Pieris 'Flaming Silver'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.335,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCXSKVBJ;HVLPYJBZ;JT4D138Z;VA7AA76B;EP883SGR;H4EX7KA9;YW3E6JLJ;CA16JVCG;Y1PYT182;KKYGL9RE;YJB4NAY4;15XGJLJN;A3DNJ4NP;YJYZ14YH;7ST6YLTR;NG55PDP7;YXD8N8BG;ZJ23XEES;NH3KSJ9C;NXSZK6HG;BGYB1247',
      statusContent: 'dot',
      hash: 'L2WE1SYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2WE1SYN'
    },
    {
      status: 210,
      sku: 'PM0075112',
      core_name: 'Plant',
      sppl_ean: '8720664519077',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '5423572',
      rtl_batch_array: '6307391, 5423572',
      name_search: 'COGRACE',
      name: "Cotinus 'Grace'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 637,
      chnn_stock_retail: 645,
      sppl_prcp: 7.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y6W8ZJH2;GZVP4LG3;94KB62EC;BDJD9PTR;4SZ6W1YV;SJKTLTRA;KS8NS9WY;TAHAHYN3;KV23GJ77;Y2WRCHL9;ASP7RBVW;5CK97S8T;Y4P4N6HG;46W61NJJ;EA6TCJLY;RCV2JDBY',
      statusContent: 'dot',
      hash: 'SDDNBKZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDDNBKZ9'
    },
    {
      status: 210,
      sku: 'PM0074973',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348289',
      rtl_batch_array: '6275768, 6348289',
      name_search: 'ASMPPRID',
      name: "Astrantia major 'Pink Pride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1417,
      chnn_stock_retail: 2286,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1WDT52L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1WDT52L'
    },
    {
      status: 210,
      sku: 'PM0075058',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346676',
      rtl_batch_array: '6346676',
      name_search: 'AEPARVIF',
      name: 'Aesculus parviflora',
      sppl_size_code: '125150C20SO',
      rng_range_identifier: 'SPECH125150C20',
      rng_range_description: 'Specimen H125 cm 150 cm C20',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 26.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JP1C6VDT;9BVDB817;V6DNPEK1;C3Z5TRCS',
      statusContent: 'dot',
      hash: 'XKVEATXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKVEATXA'
    },
    {
      status: 210,
      sku: 'PM0075059',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307273',
      rtl_batch_array: '6307273',
      name_search: 'AMARHILL',
      name: "Amelanchier arborea 'Robin Hill'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 28.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YYR88H22;2ARDZSYG;RXLRAEB4;TGB28SBH;6KVDAN7G',
      statusContent: 'dot',
      hash: 'XHJAXGY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHJAXGY6'
    },
    {
      status: 210,
      sku: 'PM0075060',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307274',
      rtl_batch_array: '6307274',
      name_search: 'AMARHILL',
      name: "Amelanchier arborea 'Robin Hill'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_prcp: 32.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YYR88H22;2ARDZSYG;RXLRAEB4;TGB28SBH;6KVDAN7G',
      statusContent: 'dot',
      hash: 'XDJPWT4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDJPWT4R'
    },
    {
      status: 810,
      sku: 'PM0075061',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307275',
      rtl_batch_array: '6307275',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 22.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'TVBXJ29Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVBXJ29Y'
    },
    {
      status: 210,
      sku: 'PM0016623',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267523',
      rtl_batch_array: '6267523',
      name_search: 'LEABDUNE',
      name: "Leymus arenarius 'Blue Dune'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 952,
      chnn_stock_retail: 952,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4SB15NT;LK9B89KX',
      statusContent: 'dot',
      hash: 'LPXLD747',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPXLD747'
    },
    {
      status: 210,
      sku: 'PM0080303',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336338',
      rtl_batch_array: '6336338',
      name_search: 'CHOKOSTE',
      name: "Chamaecyparis obtusa 'Kosteri'",
      sppl_size_code: '030STC10',
      rng_range_identifier: 'STE030C10',
      rng_range_description: 'Stem 30 cm C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 34.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '47ZCSE6C;4XD5ESZ6',
      statusContent: 'dot',
      hash: 'XK8P4C1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XK8P4C1W'
    },
    {
      status: 810,
      sku: 'PM0075062',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280890',
      rtl_batch_array: '6280890, 6307276',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 42,
      chnn_stock_retail: 90,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '9WKDGHD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WKDGHD5'
    },
    {
      status: 810,
      sku: 'PM0075063',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307277',
      rtl_batch_array: '6307277',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 39.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '5D8X9HSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5D8X9HSS'
    },
    {
      status: 210,
      sku: 'PM0080304',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336339',
      rtl_batch_array: '6336339',
      name_search: 'CRJMNANA',
      name: "Cryptomeria japonica 'Monstrosa Nana'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 901,
      chnn_stock_retail: 901,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '131KWGLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '131KWGLH'
    },
    {
      status: 210,
      sku: 'PM0074790',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274860',
      rtl_batch_array: '6353080, 6274860',
      name_search: 'PEOFLAMI',
      name: "Pennisetum orientale 'Flamingo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 423,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B55G23CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B55G23CJ'
    },
    {
      status: 210,
      sku: 'PM0074791',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6353157',
      rtl_batch_array: '6324741, 6274861, 6353157',
      name_search: 'PHTAMAZO',
      name: "Phlomis tuberosa 'Amazone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 321,
      chnn_stock_retail: 4645,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1NNDCAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1NNDCAS'
    },
    {
      status: 210,
      sku: 'PM0075067',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307768',
      rtl_batch_array: '6307768',
      name_search: 'TADISTIC',
      name: 'Taxodium distichum',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 10.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '57YKZK3C;1SG6WLB2;EWN73NH9;XJYW3ZXR;KN9TTC1W;5G93H264;YJACBVDD;YVLPL8RW;EZ173YX5;S3JTC69H;VNPRRPSZ;NTAWXLN1;869EL85E',
      statusContent: 'dot',
      hash: 'K571R46Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K571R46Y'
    },
    {
      status: 210,
      sku: 'PM0075068',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307769',
      rtl_batch_array: '6307769',
      name_search: 'TADISTIC',
      name: 'Taxodium distichum',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 211,
      chnn_stock_retail: 211,
      sppl_prcp: 12.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '57YKZK3C;1SG6WLB2;EWN73NH9;XJYW3ZXR;KN9TTC1W;5G93H264;YJACBVDD;YVLPL8RW;EZ173YX5;S3JTC69H;VNPRRPSZ;NTAWXLN1;869EL85E',
      statusContent: 'dot',
      hash: 'Z48PN4V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z48PN4V5'
    },
    {
      status: 210,
      sku: 'PM0075069',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307792',
      rtl_batch_array: '6307792',
      name_search: 'VACDUKE',
      name: "Vaccinium corymbosum 'Duke'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 566,
      chnn_stock_retail: 566,
      sppl_prcp: 5.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1ALCRK2',
      statusContent: 'dot',
      hash: '7ASHJV39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ASHJV39'
    },
    {
      status: 210,
      sku: 'PM0075070',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307813',
      rtl_batch_array: '6307813',
      name_search: 'FAGENF',
      name: "Fargesia 'Genf'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TGL2HPHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGL2HPHJ'
    },
    {
      status: 210,
      sku: 'PM0075071',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307814',
      rtl_batch_array: '6307814',
      name_search: 'FAGENF',
      name: "Fargesia 'Genf'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KP2SX9YZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP2SX9YZ'
    },
    {
      status: 210,
      sku: 'PM0075072',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307815',
      rtl_batch_array: '6307815',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'YK553KG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YK553KG6'
    },
    {
      status: 910,
      sku: 'PM0075073',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307816',
      rtl_batch_array: '6307816',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 23.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'HENTL7J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HENTL7J1'
    },
    {
      status: 910,
      sku: 'PM0075074',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307819',
      rtl_batch_array: '6307819',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 25.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'NYYHDT7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYYHDT7B'
    },
    {
      status: 210,
      sku: 'PM0074974',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345195',
      rtl_batch_array: '6275778, 6345195',
      name_search: 'PHAWINNE',
      name: "Phlox amplifolia 'Winnetou'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VGXJV76B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGXJV76B'
    },
    {
      status: 210,
      sku: 'PM0075079',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307833',
      rtl_batch_array: '6307833',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '200225C20',
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_prcp: 48.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'HB57A6YD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB57A6YD'
    },
    {
      status: 810,
      sku: 'PM0075081',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307836',
      rtl_batch_array: '6307836',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'SSVXJHKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSVXJHKX'
    },
    {
      status: 810,
      sku: 'PM0075082',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307837',
      rtl_batch_array: '6307837',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 27.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'G9HT95EX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9HT95EX'
    },
    {
      status: 210,
      sku: 'PM0075098',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307368',
      rtl_batch_array: '6307368',
      name_search: 'COCVARIE',
      name: "Cornus controversa 'Variegata'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_prcp: 19.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRHXAAP;VEK1D3NC;6WDTDNED;WGHLZ3D2;KL8RWG2A;WW5D6NVX;2C2XNTYL;N83SHYJJ;81Z2ZVRA;X5561DV4;2C6BAK81;71NWG3EX;4JSCJXVC;7ZBKNZ5Y',
      statusContent: 'dot',
      hash: 'JRYE78BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRYE78BR'
    },
    {
      status: 810,
      sku: 'PM0075083',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307839',
      rtl_batch_array: '6317682, 6307839',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 64,
      chnn_stock_retail: 69,
      sppl_prcp: 41.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'L5V8XV7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5V8XV7V'
    },
    {
      status: 210,
      sku: 'PM0075085',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307845',
      rtl_batch_array: '6307845',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: 'EJ8P5Z82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ8P5Z82'
    },
    {
      status: 210,
      sku: 'PM0075086',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307854',
      rtl_batch_array: '6307854',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 22.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: 'VE5DX8BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE5DX8BD'
    },
    {
      status: 810,
      sku: 'PM0075087',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307856',
      rtl_batch_array: '6307856',
      name_search: 'FASAWOND',
      name: "Fargesia scabrida 'Asian Wonder'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2Y81GDV;6GHXCB27;Y1AWLJDK;Y973Z946;K9CESGSD;WDETGSX4;WNZL7SS5;CNTHHX7Z;G6X3DALL;KBW7E83A;P63VE7R3',
      statusContent: 'dot',
      hash: 'Y795TLCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y795TLCD'
    },
    {
      status: 810,
      sku: 'PM0075090',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307359',
      rtl_batch_array: '6307359',
      name_search: 'CLBPDIAM',
      name: "Clerodendrum bungei 'Pink Diamond'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P72LNNXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P72LNNXB'
    },
    {
      status: 210,
      sku: 'PM0080305',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336340',
      rtl_batch_array: '6336340',
      name_search: 'PICGDECE',
      name: "Picea glauca 'December'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 10697,
      chnn_stock_retail: 10697,
      sppl_prcp: 3.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TEZ3V4L3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEZ3V4L3'
    },
    {
      status: 910,
      sku: 'PM0075092',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345466',
      rtl_batch_array: '6345466',
      name_search: 'CLETRICH',
      name: 'Clerodendrum trichotomum',
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 33.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DEHYXGRA;4YVC7JC5;E42VHS1D',
      statusContent: 'dot',
      hash: 'ETGAGVVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETGAGVVR'
    },
    {
      status: 210,
      sku: 'PM0080307',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336342',
      rtl_batch_array: '6336342',
      name_search: 'LOFRAGRA',
      name: 'Lonicera fragrantissima',
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_prcp: 3.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NB1HVZ1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB1HVZ1P'
    },
    {
      status: 910,
      sku: 'PM0075094',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345467',
      rtl_batch_array: '6345467',
      name_search: 'CLTFARGE',
      name: 'Clerodendrum trichotomum fargesii',
      sppl_size_code: '200225C40',
      rng_range_identifier: 'H200225C40',
      rng_range_description: 'H200 cm 225 cm C40',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 53.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZT6NPWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZT6NPWS'
    },
    {
      status: 910,
      sku: 'PM0075095',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345468',
      rtl_batch_array: '6345468',
      name_search: 'COAARGEN',
      name: "Cornus alternifolia 'Argentea'",
      sppl_size_code: '100125C20SO',
      rng_range_identifier: 'SPECH100125C20',
      rng_range_description: 'Specimen H100 cm 125 cm C20',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 38.214,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCSPZZ6J;EBSJAJRK;1VCVHXHD;DPD77D4K;C315KEWY;16EJX3EW;D7DPH71E;9S1Z1CR2;RWCHV57K;837LZ1DY;AKSSXGY3',
      statusContent: 'dot',
      hash: '1S81WEN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1S81WEN4'
    },
    {
      status: 810,
      sku: 'PM0075096',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346684',
      rtl_batch_array: '6346684',
      name_search: 'COAARGEN',
      name: "Cornus alternifolia 'Argentea'",
      sppl_size_code: '125150C25SO',
      rng_range_identifier: 'SPECH125150C25',
      rng_range_description: 'Specimen H125 cm 150 cm C25',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 45.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCSPZZ6J;EBSJAJRK;1VCVHXHD;DPD77D4K;C315KEWY;16EJX3EW;D7DPH71E;9S1Z1CR2;RWCHV57K;837LZ1DY;AKSSXGY3',
      statusContent: 'dot',
      hash: '874NAT99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '874NAT99'
    },
    {
      status: 810,
      sku: 'PM0075097',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345469',
      rtl_batch_array: '6345469',
      name_search: 'COAARGEN',
      name: "Cornus alternifolia 'Argentea'",
      sppl_size_code: '150175C25SO',
      rng_range_identifier: 'SPECH150175C25',
      rng_range_description: 'Specimen H150 cm 175 cm C25',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 53.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LCSPZZ6J;EBSJAJRK;1VCVHXHD;DPD77D4K;C315KEWY;16EJX3EW;D7DPH71E;9S1Z1CR2;RWCHV57K;837LZ1DY;AKSSXGY3',
      statusContent: 'dot',
      hash: 'HA2XDL4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HA2XDL4B'
    },
    {
      status: 910,
      sku: 'PM0075099',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346685',
      rtl_batch_array: '6346685',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '125150C25SO',
      rng_range_identifier: 'SPECH125150C25',
      rng_range_description: 'Specimen H125 cm 150 cm C25',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 43.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: 'NDRP8DD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDRP8DD2'
    },
    {
      status: 810,
      sku: 'PM0075100',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345470',
      rtl_batch_array: '6345470',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '150175C25SO',
      rng_range_identifier: 'SPECH150175C25',
      rng_range_description: 'Specimen H150 cm 175 cm C25',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 48.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '8B4L6RXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8B4L6RXL'
    },
    {
      status: 910,
      sku: 'PM0075101',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345471',
      rtl_batch_array: '6345471',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '175200C25SO',
      rng_range_identifier: 'SPECH175200C25',
      rng_range_description: 'Specimen H175 cm 200 cm C25',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 53.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: 'WVHBCE2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVHBCE2Y'
    },
    {
      status: 910,
      sku: 'PM0075102',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346686',
      rtl_batch_array: '6346686',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '125150C25SO',
      rng_range_identifier: 'SPECH125150C25',
      rng_range_description: 'Specimen H125 cm 150 cm C25',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 43.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: 'XWDG1W9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWDG1W9A'
    },
    {
      status: 810,
      sku: 'PM0075103',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345472',
      rtl_batch_array: '6345472',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '150175C25SO',
      rng_range_identifier: 'SPECH150175C25',
      rng_range_description: 'Specimen H150 cm 175 cm C25',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 48.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: 'BAPRKS8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAPRKS8Y'
    },
    {
      status: 910,
      sku: 'PM0075104',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345473',
      rtl_batch_array: '6345473',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '175200C25SO',
      rng_range_identifier: 'SPECH175200C25',
      rng_range_description: 'Specimen H175 cm 200 cm C25',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 53.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: 'V42J4ZC5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V42J4ZC5'
    },
    {
      status: 810,
      sku: 'PM0075107',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307377',
      rtl_batch_array: '6307377',
      name_search: 'COPAUCIF',
      name: 'Corylopsis pauciflora',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 12.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LDGETZ35;4HWKVX7H;D9G19CA5;ZYCA4VNX;YKTAZCR3;C6GJZPXP;PK4P4993;RL7VSYRE;7A59BD77',
      statusContent: 'dot',
      hash: 'C5LXDR5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5LXDR5K'
    },
    {
      status: 810,
      sku: 'PM0075110',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345479',
      rtl_batch_array: '6345479',
      name_search: 'COCOGGYG',
      name: 'Cotinus coggygria',
      sppl_size_code: '150175C25',
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 33.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSA84DWE;Y4AN75K2;6S9YSYV4;4XW7XSEE;NK8DRPE4',
      statusContent: 'dot',
      hash: 'RCNCAKB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCNCAKB1'
    },
    {
      status: 910,
      sku: 'PM0075111',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345480',
      rtl_batch_array: '6345480',
      name_search: 'COCOGGYG',
      name: 'Cotinus coggygria',
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 38.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSA84DWE;Y4AN75K2;6S9YSYV4;4XW7XSEE;NK8DRPE4',
      statusContent: 'dot',
      hash: '59YW3HJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59YW3HJZ'
    },
    {
      status: 210,
      sku: 'PM0075113',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307392',
      rtl_batch_array: '6307392',
      name_search: 'COGRACE',
      name: "Cotinus 'Grace'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 11.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y6W8ZJH2;GZVP4LG3;94KB62EC;BDJD9PTR;4SZ6W1YV;SJKTLTRA;KS8NS9WY;TAHAHYN3;KV23GJ77;Y2WRCHL9;ASP7RBVW;5CK97S8T;Y4P4N6HG;46W61NJJ;EA6TCJLY;RCV2JDBY',
      statusContent: 'dot',
      hash: 'XWWV7PWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWWV7PWK'
    },
    {
      status: 910,
      sku: 'PM0075114',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307393',
      rtl_batch_array: '6307393',
      name_search: 'COGRACE',
      name: "Cotinus 'Grace'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 14.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y6W8ZJH2;GZVP4LG3;94KB62EC;BDJD9PTR;4SZ6W1YV;SJKTLTRA;KS8NS9WY;TAHAHYN3;KV23GJ77;Y2WRCHL9;ASP7RBVW;5CK97S8T;Y4P4N6HG;46W61NJJ;EA6TCJLY;RCV2JDBY',
      statusContent: 'dot',
      hash: 'T2A1HGE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2A1HGE2'
    },
    {
      status: 910,
      sku: 'PM0075115',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMPSCAR',
      name: "Crataegus media 'Paul's Scarlet'",
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      imageCore: 'DTD27V2H;KDJXDRJN;RTX122ZK;S864AHRX',
      statusContent: 'dot',
      hash: 'RYW5R2JN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RYW5R2JN'
    },
    {
      status: 210,
      sku: 'PM0080308',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336343',
      rtl_batch_array: '6336343',
      name_search: 'LUGALLER',
      name: 'Lupinus Gallery Mix',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9774,
      chnn_stock_retail: 9774,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRJYAG54;HCEY5W1S;A4A9VCNZ;P5R1B2WE;GDLC8GG2;KPZSVH1B;T91RLL6K;LS7VRRRL',
      statusContent: 'dot',
      hash: '9DJBYTXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DJBYTXK'
    },
    {
      status: 810,
      sku: 'PM0075117',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307395',
      rtl_batch_array: '6307395',
      name_search: 'CRMPSCAR',
      name: "Crataegus media 'Paul's Scarlet'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 33,
      chnn_stock_retail: 33,
      sppl_prcp: 16.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DTD27V2H;KDJXDRJN;RTX122ZK;S864AHRX',
      statusContent: 'dot',
      hash: 'NZK9KH33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZK9KH33'
    },
    {
      status: 210,
      sku: 'PM0080309',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336344',
      rtl_batch_array: '6336344',
      name_search: 'MAMAMBSA',
      name: 'Mangave Mad About M. Blaz. Saddles',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TEHWG3D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEHWG3D7'
    },
    {
      status: 810,
      sku: 'PM0075118',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307397',
      rtl_batch_array: '6307397',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 23.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'E2AC81T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2AC81T6'
    },
    {
      status: 910,
      sku: 'PM0075119',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307402',
      rtl_batch_array: '6307402',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 28.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'JGDL3EPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGDL3EPR'
    },
    {
      status: 210,
      sku: 'PM0080310',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336347',
      rtl_batch_array: '6336347',
      name_search: 'NADCOMPA',
      name: "Nandina d. 'Compacta'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 3.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8P2L1H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8P2L1H9'
    },
    {
      status: 810,
      sku: 'PM0075120',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307406',
      rtl_batch_array: '6307406',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 25.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: '2YR5XK9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YR5XK9H'
    },
    {
      status: 210,
      sku: 'PM0075121',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307407',
      rtl_batch_array: '6307407',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_prcp: 28.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: 'RG48RDHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG48RDHY'
    },
    {
      status: 810,
      sku: 'PM0075122',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307410',
      rtl_batch_array: '6307410',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'GXXACXR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXXACXR6'
    },
    {
      status: 810,
      sku: 'PM0075123',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307411',
      rtl_batch_array: '6307411',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 9.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '4E8WLX6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E8WLX6W'
    },
    {
      status: 810,
      sku: 'PM0075124',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307412',
      rtl_batch_array: '6307412',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 25.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'WC1VZJJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC1VZJJS'
    },
    {
      status: 210,
      sku: 'PM0075125',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307413',
      rtl_batch_array: '6307413',
      name_search: 'ELEVIVEL',
      name: "Elaeagnus ebbingei 'Viveleg'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JX82HLPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JX82HLPD'
    },
    {
      status: 810,
      sku: 'PM0075126',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307416',
      rtl_batch_array: '6307416',
      name_search: 'ELEVIVEL',
      name: "Elaeagnus ebbingei 'Viveleg'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 9.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYJV91XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYJV91XB'
    },
    {
      status: 210,
      sku: 'PM0075127',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307417',
      rtl_batch_array: '6307417',
      name_search: 'ELEVIVEL',
      name: "Elaeagnus ebbingei 'Viveleg'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 16.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E32R1R6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E32R1R6H'
    },
    {
      status: 210,
      sku: 'PM0075128',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307418',
      rtl_batch_array: '6307418',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'R9L92SWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9L92SWY'
    },
    {
      status: 210,
      sku: 'PM0075129',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307419',
      rtl_batch_array: '6307419',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_prcp: 8.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'V2855ZRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2855ZRZ'
    },
    {
      status: 210,
      sku: 'PM0075130',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307421',
      rtl_batch_array: '6307421',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_prcp: 9.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: '1SCHP916',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SCHP916'
    },
    {
      status: 910,
      sku: 'PM0075131',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307422',
      rtl_batch_array: '6307422',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 25.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: '535R99HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '535R99HH'
    },
    {
      status: 810,
      sku: 'PM0075132',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307423',
      rtl_batch_array: '6307423',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_prcp: 16.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'KZK7P8W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZK7P8W9'
    },
    {
      status: 810,
      sku: 'PM0075075',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307828',
      rtl_batch_array: '6307828',
      name_search: 'FANGWALL',
      name: "Fargesia nitida 'Great Wall'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YLLHD7KC;KCATYYGY;WV2BZBS3;D2W4VLGC;SSBAGKAG;Z6TBD3EJ;H4SG1BDH;GRTC522X;P3613YAK;9XL5ZHAN',
      statusContent: 'dot',
      hash: 'H6EZR94A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6EZR94A'
    },
    {
      status: 810,
      sku: 'PM0075076',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307829',
      rtl_batch_array: '6307829',
      name_search: 'FANGWALL',
      name: "Fargesia nitida 'Great Wall'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YLLHD7KC;KCATYYGY;WV2BZBS3;D2W4VLGC;SSBAGKAG;Z6TBD3EJ;H4SG1BDH;GRTC522X;P3613YAK;9XL5ZHAN',
      statusContent: 'dot',
      hash: 'PP74CKK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP74CKK3'
    },
    {
      status: 810,
      sku: 'PM0075077',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307830',
      rtl_batch_array: '6307830',
      name_search: 'FANGWALL',
      name: "Fargesia nitida 'Great Wall'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YLLHD7KC;KCATYYGY;WV2BZBS3;D2W4VLGC;SSBAGKAG;Z6TBD3EJ;H4SG1BDH;GRTC522X;P3613YAK;9XL5ZHAN',
      statusContent: 'dot',
      hash: '9K6X5A6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9K6X5A6Y'
    },
    {
      status: 810,
      sku: 'PM0075080',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307834',
      rtl_batch_array: '6307834',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 13.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: 'YWVCV6S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWVCV6S2'
    },
    {
      status: 910,
      sku: 'PM0075084',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: '9V1S97Z2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9V1S97Z2'
    },
    {
      status: 810,
      sku: 'PM0075088',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307857',
      rtl_batch_array: '6307857',
      name_search: 'FASAWOND',
      name: "Fargesia scabrida 'Asian Wonder'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2Y81GDV;6GHXCB27;Y1AWLJDK;Y973Z946;K9CESGSD;WDETGSX4;WNZL7SS5;CNTHHX7Z;G6X3DALL;KBW7E83A;P63VE7R3',
      statusContent: 'dot',
      hash: 'ZZ1YZTJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZ1YZTJL'
    },
    {
      status: 810,
      sku: 'PM0075089',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307858',
      rtl_batch_array: '6307858',
      name_search: 'FASAWOND',
      name: "Fargesia scabrida 'Asian Wonder'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2Y81GDV;6GHXCB27;Y1AWLJDK;Y973Z946;K9CESGSD;WDETGSX4;WNZL7SS5;CNTHHX7Z;G6X3DALL;KBW7E83A;P63VE7R3',
      statusContent: 'dot',
      hash: 'JHEY1VL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHEY1VL4'
    },
    {
      status: 810,
      sku: 'PM0080311',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336348',
      rtl_batch_array: '6336348',
      name_search: 'NADCOMPA',
      name: "Nandina d. 'Compacta'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 8.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LL5KW8TS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL5KW8TS'
    },
    {
      status: 210,
      sku: 'PM0080312',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336349',
      rtl_batch_array: '6336349',
      name_search: 'NADRICHM',
      name: "Nandina domestica 'Richmond'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B3GCEZYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3GCEZYJ'
    },
    {
      status: 210,
      sku: 'PM0080313',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336350',
      rtl_batch_array: '6336350',
      name_search: 'WEFVARIE',
      name: "Weigela florida 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TCZ8WR55;GBB7W6HN;7113JCYK;AGEXNLA2;L4112LRC;AZV97K23;CV1REK46;4AR4T62H;RK825HXT;1XN53P61;YNC86VPC;L3XBLWW5;1X4BYDVC;4CWYLV6S;1BPZTCSZ',
      statusContent: 'dot',
      hash: 'TZCP1331',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZCP1331'
    },
    {
      status: 210,
      sku: 'PM0080314',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336352',
      rtl_batch_array: '6336352',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      statusContent: 'dot',
      hash: 'W9WV79B9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9WV79B9'
    },
    {
      status: 210,
      sku: 'PM0080315',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336353',
      rtl_batch_array: '6336353',
      name_search: 'PAVREHBR',
      name: "Panicum virgatum 'Rehbraun'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTPBCVG1;BDLXK19Y;AVX56D5S;7RH9XSJ5;1XCWN1HP;2D2J95VP;DHYCCDXV;2YJN9CAT;2BR3BZTA;D1K77WWG;7RDRGPE4;KLY9228P',
      statusContent: 'dot',
      hash: '93WTATDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93WTATDX'
    },
    {
      status: 210,
      sku: 'PM0080316',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336355',
      rtl_batch_array: '6336355',
      name_search: 'PIPGGLOB',
      name: "Picea pungens 'Glauca Globosa'",
      sppl_size_code: '040STC74',
      rng_range_identifier: 'STE040C7.5',
      rng_range_description: 'Stem 40 cm C7.5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 27.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZAPZ2Y19;RT7R225Y;8RCT13BE;52EEBAJA;E4DGYS6J;V1WH2GHE;JBX7K4ED;4Z9G6PDC;X9X2P3DC;6RGP7D1N;REYXKK9E;71L73LNC;XZPEVCZG',
      statusContent: 'dot',
      hash: 'W45R4W46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W45R4W46'
    },
    {
      status: 810,
      sku: 'PM0080317',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336356',
      rtl_batch_array: '6336356',
      name_search: 'PIMBENJA',
      name: "Pinus mugo 'Benjamin'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 27.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4P1XYZA',
      statusContent: 'dot',
      hash: 'GHPC78GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHPC78GL'
    },
    {
      status: 810,
      sku: 'PM0080318',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336357',
      rtl_batch_array: '6336357',
      name_search: 'PIMBENJA',
      name: "Pinus mugo 'Benjamin'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 22.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4P1XYZA',
      statusContent: 'dot',
      hash: 'BSZTNK8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSZTNK8N'
    },
    {
      status: 210,
      sku: 'PM0080319',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336358',
      rtl_batch_array: '6336358',
      name_search: 'PIMMOPS',
      name: "Pinus mugo 'Mops'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 27.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTWG8NA3;HKC34X1D;L8L8Y9VS;S7L7X86R;NBJ4EZ6V;9YHXJY9D;JACEKARS;H8CNE9Y6;A5BG3BR5',
      statusContent: 'dot',
      hash: '5ZZ64LY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZZ64LY8'
    },
    {
      status: 910,
      sku: 'PM0075163',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346720',
      rtl_batch_array: '6346720',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '150175C25SO',
      rng_range_identifier: 'SPECH150175C25',
      rng_range_description: 'Specimen H150 cm 175 cm C25',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 48.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'GJPLJ489',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJPLJ489'
    },
    {
      status: 210,
      sku: 'PM0054153',
      core_name: 'Plant',
      sppl_ean: '8720664804098',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063499',
      rtl_batch_array: '6063499',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040060C10',
      rng_range_identifier: 'H040060C10',
      rng_range_description: 'H40 cm 60 cm C10',
      sppl_stock_available: 391,
      chnn_stock_retail: 391,
      sppl_prcp: 6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'C78L6E3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C78L6E3K'
    },
    {
      status: 210,
      sku: 'PM0075160',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307577',
      rtl_batch_array: '6307577',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: '666T4LLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '666T4LLT'
    },
    {
      status: 210,
      sku: 'PM0002503',
      core_name: 'Plant',
      sppl_ean: '8720664801363',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5504729',
      rtl_batch_array: '5504729, 6353132, 6320899',
      name_search: 'PEAAJUNI',
      name: 'Persicaria amplexicaulis Alba Junior',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 626,
      chnn_stock_retail: 1231,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TE6CATH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TE6CATH'
    },
    {
      status: 810,
      sku: 'PM0075162',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346719',
      rtl_batch_array: '6346719',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '125150C25SO',
      rng_range_identifier: 'SPECH125150C25',
      rng_range_description: 'Specimen H125 cm 150 cm C25',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 38.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'XDYBR5SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDYBR5SS'
    },
    {
      status: 210,
      sku: 'PM0075211',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307732',
      rtl_batch_array: '6307732',
      name_search: 'MEGLYPTO',
      name: 'Metasequoia glyptostroboides',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 9.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APZV6165;EEDG59S2;Y3EJYXBA;TWP9Y85L;NSNDZL7H;PG49N7R1;KVA96A2A;YLYJJ13K;HKEJX78L;4ZTPD9WJ;DLDSW7N8;PNBZXV8L;CZ7LZREB;R8K1C5HK;H47B2JEC;CHS94XV8',
      statusContent: 'dot',
      hash: '5B1JJ5V8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B1JJ5V8'
    },
    {
      status: 210,
      sku: 'PM0026395',
      core_name: 'Plant',
      sppl_ean: '8720353086873',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5683298',
      rtl_batch_array: '5683298',
      name_search: 'ARMALBA',
      name: "Armeria maritima 'Alba'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3903,
      chnn_stock_retail: 3903,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72JY2TSY;D6S5XHHJ;2V3GXWET;9WSWZHVY;5NZZCXNA;5Y2VRYKZ;7TVNDA14;1LSJHST2;SXSAZ2EL;2ARCSCZE;47YAXKYP;26RBD57Z;W6133S5Y',
      imageBatch: 'JK3TCDY7',
      statusContent: 'dot',
      hash: 'DVHN7YWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVHN7YWS'
    },
    {
      status: 910,
      sku: 'PM0075161',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346718',
      rtl_batch_array: '6346718',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '100125C25SO',
      rng_range_identifier: 'SPECH100125C25',
      rng_range_description: 'Specimen H100 cm 125 cm C25',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 33.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'WPVHKE75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPVHKE75'
    },
    {
      status: 810,
      sku: 'PM0075144',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6281204',
      rtl_batch_array: '6281204, 6307484',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 78,
      sppl_prcp: 35.085,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'ZDPXB344',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDPXB344'
    },
    {
      status: 910,
      sku: 'PM0075193',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYVLEMOI',
      name: "Syringa vulgaris 'Mme Lemoine'",
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      imageCore: 'C9W254B8;DVJYRPZJ;Y7HEZEGV',
      statusContent: 'dot',
      hash: 'B7Y7C4XJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'B7Y7C4XJ'
    },
    {
      status: 910,
      sku: 'PM0075192',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYVLEMOI',
      name: "Syringa vulgaris 'Mme Lemoine'",
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      imageCore: 'C9W254B8;DVJYRPZJ;Y7HEZEGV',
      statusContent: 'dot',
      hash: 'N4WDVTDR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N4WDVTDR'
    },
    {
      status: 810,
      sku: 'PM0075134',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307428',
      rtl_batch_array: '6307428',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_prcp: 12.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: '2GEB2HNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GEB2HNY'
    },
    {
      status: 210,
      sku: 'PM0017072',
      core_name: 'Plant',
      sppl_ean: '8720349424559',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3536371',
      rtl_batch_array: '3536371',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      imageBatch: '16LSYA4N',
      statusContent: 'dot',
      hash: 'A5729EAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5729EAN'
    },
    {
      status: 910,
      sku: 'PM0075136',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346695',
      rtl_batch_array: '6346695',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: '150175C35SO',
      rng_range_identifier: 'SPECH150175C35',
      rng_range_description: 'Specimen H150 cm 175 cm C35',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 50.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: '21JW4HXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21JW4HXE'
    },
    {
      status: 210,
      sku: 'PM0018201',
      core_name: 'Plant',
      sppl_ean: '8720626314450',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5502155',
      rtl_batch_array: '5502155',
      name_search: 'PAVSHENA',
      name: "Panicum virgatum 'Shenandoah'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_prcp: 7.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5D1Y5Y1;EWAYJRGL;THZVCBYK;XR4S95TL;65B156B5;NNG5RJPG;6A52ZT9L;H6V1JPDC;XE3CWL3V;NDXW85ZB',
      statusContent: 'dot',
      hash: 'X6HKRNCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6HKRNCT'
    },
    {
      status: 210,
      sku: 'PM0001241',
      core_name: 'Plant',
      sppl_ean: '8720664689251',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702228',
      rtl_batch_array: '4702228',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 494,
      chnn_stock_retail: 494,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      imageBatch: 'WB5638YX',
      statusContent: 'dot',
      hash: 'B59P727T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B59P727T'
    },
    {
      status: 910,
      sku: 'PM0075135',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      rng_range_identifier: 'SPECH125150C35',
      rng_range_description: 'Specimen H125 cm 150 cm C35',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'NLT6E7HR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NLT6E7HR'
    },
    {
      status: 210,
      sku: 'PM0026344',
      core_name: 'Plant',
      sppl_ean: '8720349417315',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5507508',
      rtl_batch_array: '6084959, 6136404, 5507508',
      name_search: 'HEBRIDE',
      name: "Helianthemum 'The Bride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 3175,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XBYZGY3W;LBGY43WH;KCY13N3N;Z5SBKYX7;DNJV3BGY',
      statusContent: 'dot',
      hash: 'XGDNLZRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGDNLZRW'
    },
    {
      status: 210,
      sku: 'PM0072471',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 2,
      btch_active_retail: '6378403',
      rtl_batch_array: '6304916, 6378403',
      name_search: 'VAVIFIRE',
      name: 'Vaccinium vitis-idaea Fireballs',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2767,
      chnn_stock_retail: 32491,
      sppl_prcp: 3.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G13CT6TW;Y3NL3NLK;GXGDY6ER;KSCSCWL2;REH5SCCW;19EREVBK;Z6K289TP;4ZDTLDSN;PJH32HBK;DK5SLYWD;SS5ZHJKH;6YEDXS5K;Z6NBYJXL',
      statusContent: 'dot',
      hash: 'Z15RT8JN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z15RT8JN'
    },
    {
      status: 210,
      sku: 'PM0082619',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354197',
      rtl_batch_array: '6354197',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      statusContent: 'dot',
      hash: 'NVNKEL68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVNKEL68'
    },
    {
      status: 210,
      sku: 'PM0075139',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307432',
      rtl_batch_array: '6307432',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 9.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: 'JGWY18SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGWY18SL'
    },
    {
      status: 210,
      sku: 'PM0023533',
      core_name: 'Plant',
      sppl_ean: '8720349498536',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '5607000',
      rtl_batch_array: '6181376, 6317205, 5439532, 5607000',
      name_search: 'CAAOVERD',
      name: "Calamagrostis acutiflora 'Overdam'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 11000,
      chnn_stock_retail: 14919,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6N82A37;NRXEE51H;EN26CHAN;ZNJBER7R;C7NJKLYV;GXYJ8CAB;6P9PPYAV;BWAPCE1Y;L1RBX35E;SK4J87TV;D7JHV6BG;PKZJ346T;94E65NSZ;6T679YBV;6KX1BDZ2;V8574RZW;6AVTGASE;WTHJSTT1;S4SW2T6Y;JTCHNR59;RHYECWKK;LYJV25S6;DLPK5RNR;HRXHBLXT;HK57GBK8;JN8VG67A',
      statusContent: 'dot',
      hash: 'WSBP2K3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSBP2K3V'
    },
    {
      status: 810,
      sku: 'PM0075143',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346666',
      rtl_batch_array: '6346666',
      name_search: 'HISRHEAR',
      name: "Hibiscus syriacus 'Red Heart'",
      sppl_size_code: '125150C25SO',
      rng_range_identifier: 'SPECH125150C25',
      rng_range_description: 'Specimen H125 cm 150 cm C25',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 33.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ED8W3VK6;HZ16SZ77;GLPYWH8E;G38D2T2L;D5AN7SDA;GTKGW3LE;81SGBESH;9J9T46BE',
      statusContent: 'dot',
      hash: 'D3DYY3BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3DYY3BT'
    },
    {
      status: 810,
      sku: 'PM0015368',
      core_name: 'Plant',
      sppl_ean: '8720664685659',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5683044',
      rtl_batch_array: '5683044',
      name_search: 'COASPAET',
      name: "Cornus alba 'Spaethii'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '56GJ9BVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56GJ9BVS'
    },
    {
      status: 210,
      sku: 'PM0075140',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307433',
      rtl_batch_array: '6307433',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_prcp: 11.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: 'ABS44YCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ABS44YCW'
    },
    {
      status: 210,
      sku: 'PM0075145',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307496',
      rtl_batch_array: '6349331, 6307496',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 591,
      chnn_stock_retail: 641,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'LS3LP77L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LS3LP77L'
    },
    {
      status: 210,
      sku: 'PM0075146',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307497',
      rtl_batch_array: '6307497',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 15.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'PDC9YKGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDC9YKGD'
    },
    {
      status: 210,
      sku: 'PM0075147',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307501',
      rtl_batch_array: '6307501',
      name_search: 'LAANAGYR',
      name: 'Laburnum anagyroides',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 394,
      chnn_stock_retail: 394,
      sppl_prcp: 16.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PRKDYWTP',
      statusContent: 'dot',
      hash: 'K5H8BS25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5H8BS25'
    },
    {
      status: 210,
      sku: 'PM0075150',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307504',
      rtl_batch_array: '6307504',
      name_search: 'LEBICOLO',
      name: 'Lespedeza bicolor',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 421,
      chnn_stock_retail: 421,
      sppl_prcp: 5.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N18TAADC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N18TAADC'
    },
    {
      status: 210,
      sku: 'PM0075151',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307506',
      rtl_batch_array: '6307506',
      name_search: 'LEBICOLO',
      name: 'Lespedeza bicolor',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 8.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SNGRE2JX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNGRE2JX'
    },
    {
      status: 210,
      sku: 'PM0075152',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307507',
      rtl_batch_array: '6307507',
      name_search: 'LEBICOLO',
      name: 'Lespedeza bicolor',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 151,
      chnn_stock_retail: 151,
      sppl_prcp: 9.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGS51H3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGS51H3J'
    },
    {
      status: 210,
      sku: 'PM0072511',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251007',
      rtl_batch_array: '6251007',
      name_search: 'RHPTANGU',
      name: 'Rheum palmatum tanguticum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 101,
      chnn_stock_retail: 101,
      sppl_prcp: 3.497,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1GYAKLE;R6V169T3',
      statusContent: 'dot',
      hash: 'RAARPPR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAARPPR8'
    },
    {
      status: 210,
      sku: 'PM0072512',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251011',
      rtl_batch_array: '6251011',
      name_search: 'STBBEARS',
      name: "Stachys byzantina 'Big Ears'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 799,
      chnn_stock_retail: 799,
      sppl_prcp: 2.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4JCV69K8;SZZJEA95;ZCLZRH88;KE63733B',
      statusContent: 'dot',
      hash: '6RBJYBN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RBJYBN3'
    },
    {
      status: 810,
      sku: 'PM0075153',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307540',
      rtl_batch_array: '6307540',
      name_search: 'MABETTY',
      name: "Magnolia 'Betty'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12YLB58;V48KACWP',
      statusContent: 'dot',
      hash: 'LGAB8YZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGAB8YZS'
    },
    {
      status: 910,
      sku: 'PM0026865',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMALBA',
      name: "Malva moschata 'Alba'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        'BXLD611S;VVNW4YH9;L13C7779;HBWACSX9;1VVN19VG;KDBPTKY7;JL7JVYW8;BWTW8EDJ;TT7TDXTX;7W5AHSVE',
      statusContent: 'dot',
      hash: '95EE4NAX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '95EE4NAX'
    },
    {
      status: 810,
      sku: 'PM0075154',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6383857',
      rtl_batch_array: '6383857',
      name_search: 'MAGRANDI',
      name: 'Magnolia grandiflora',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_prcp: 16.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z4XRBZGS;BA23HE4L;CXGNE86A;KKSYE57D;22C5EC6K;8THTXCJR',
      statusContent: 'dot',
      hash: 'D6YRVXTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6YRVXTL'
    },
    {
      status: 210,
      sku: 'PM0001203',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6244287',
      rtl_batch_array: '6244287, 6143866',
      name_search: 'MUCAPILL',
      name: 'Muhlenbergia capillaris',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3306,
      chnn_stock_retail: 3806,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CXGXBXG8;TD6C4NGA',
      statusContent: 'dot',
      hash: '9JAHGY6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JAHGY6H'
    },
    {
      status: 210,
      sku: 'PM0054145',
      core_name: 'Plant',
      sppl_ean: '8720664690608',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6062216',
      rtl_batch_array: '6062216',
      name_search: 'GAULFLOR',
      name: "Gaura lindheimeri 'Florgaured'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 882,
      chnn_stock_retail: 882,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G928BRL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G928BRL4'
    },
    {
      status: 910,
      sku: 'PM0007672',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAMOSCHA',
      name: 'Malva moschata',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: '2T8VB364;TKG42WK6;RC7TX9KL',
      statusContent: 'dot',
      hash: 'L6CZPRSH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L6CZPRSH'
    },
    {
      status: 210,
      sku: 'PM0054138',
      core_name: 'Plant',
      sppl_ean: '8720664679368',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6062199',
      rtl_batch_array: '6062199',
      name_search: 'ACSPASTE',
      name: "Achillea 'Summer Pastels'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2992,
      chnn_stock_retail: 2992,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L73AB3WZ;ZBZS28EX;DD6GNW4C;KCR88KG6',
      statusContent: 'dot',
      hash: 'N1B9ZY2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1B9ZY2X'
    },
    {
      status: 210,
      sku: 'PM0010163',
      core_name: 'Plant',
      sppl_ean: '8720349491797',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5538757',
      rtl_batch_array: '6242951, 5538757, 6329943',
      name_search: 'GESAPRID',
      name: "Geranium sanguineum 'Ankum's Pride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1362,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P768JZYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P768JZYS'
    },
    {
      status: 210,
      sku: 'PM0082620',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354199',
      rtl_batch_array: '6354199',
      name_search: 'SKJMMARL',
      name: "Skimmia japonica 'Magic Marlot'",
      sppl_size_code: '040050C20',
      rng_range_identifier: 'H040050C20',
      rng_range_description: 'H40 cm 50 cm C20',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 33.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YKGZNVPD;W23YZCS9;KK9LBZPG;6PW28LGB;WZ124V2E;SAE46Z5A;8WAJPSK1;Z83XKVZ6;VBGL1CVB;8XJZENNL;194JPLG8;HARN42X6;RCWTP4RB',
      statusContent: 'dot',
      hash: '4TX8YANX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TX8YANX'
    },
    {
      status: 210,
      sku: 'PM0082621',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354200',
      rtl_batch_array: '6354200',
      name_search: 'SKIJWDWA',
      name: 'Skimmia japonica White Dwarf',
      sppl_size_code: '040050C20',
      rng_range_identifier: 'H040050C20',
      rng_range_description: 'H40 cm 50 cm C20',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 33.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T3JKXA6E;LCVXVLRL;YBD267AD;Z4CBCTKG;K76S9L1Z;2X4261SL',
      statusContent: 'dot',
      hash: '98TR4EYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98TR4EYR'
    },
    {
      status: 210,
      sku: 'PM0002566',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6261010',
      rtl_batch_array: '2780255, 6261010',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3308,
      chnn_stock_retail: 3774,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'VVLG56VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVLG56VK'
    },
    {
      status: 210,
      sku: 'PM0080321',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336360',
      rtl_batch_array: '6336360',
      name_search: 'PIMWGOLD',
      name: "Pinus mugo 'Winter Gold'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 20.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SA9GW7KY;P5E9N3BN;J115HLJ1',
      statusContent: 'dot',
      hash: 'T14RT5W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T14RT5W1'
    },
    {
      status: 810,
      sku: 'PM0075155',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307554',
      rtl_batch_array: '6307554',
      name_search: 'MALSNOWD',
      name: "Magnolia loebneri 'Snowdrift'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 19.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RC5WA6N1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC5WA6N1'
    },
    {
      status: 910,
      sku: 'PM0082622',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJDPART',
      name: 'Spiraea japonica Double Play Artist',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      statusContent: 'dot',
      hash: '5SLLWZEN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5SLLWZEN'
    },
    {
      status: 910,
      sku: 'PM0075141',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345482',
      rtl_batch_array: '6345482',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '150175C25',
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 32.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: 'V9S15HS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9S15HS4'
    },
    {
      status: 910,
      sku: 'PM0075142',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345483',
      rtl_batch_array: '6345483',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 34.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: '1DYXR67Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DYXR67Y'
    },
    {
      status: 810,
      sku: 'PM0075159',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307564',
      rtl_batch_array: '6307564',
      name_search: 'MASSUPER',
      name: "Magnolia soulangeana 'Superba'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 17.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AV12KDHW;J2BTR26S',
      statusContent: 'dot',
      hash: '6GTEZ1V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GTEZ1V7'
    },
    {
      status: 910,
      sku: 'PM0075156',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAORCHID',
      name: "Magnolia 'Orchid'",
      rng_range_identifier: 'H080100C7.5',
      statusContent: 'dot',
      hash: '9LK5YWLE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9LK5YWLE'
    },
    {
      status: 810,
      sku: 'PM0075157',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307556',
      rtl_batch_array: '6307556',
      name_search: 'MAORCHID',
      name: "Magnolia 'Orchid'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TGD92CGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGD92CGH'
    },
    {
      status: 210,
      sku: 'PM0075164',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307587',
      rtl_batch_array: '6307587',
      name_search: 'MAMCHARI',
      name: "Mahonia media 'Charity'",
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_prcp: 9.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19P3CCES',
      statusContent: 'dot',
      hash: 'L6ELTBXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6ELTBXL'
    },
    {
      status: 210,
      sku: 'PM0075165',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307588',
      rtl_batch_array: '6307588',
      name_search: 'MAMCHARI',
      name: "Mahonia media 'Charity'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 598,
      chnn_stock_retail: 598,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19P3CCES',
      statusContent: 'dot',
      hash: 'CHNVG53R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHNVG53R'
    },
    {
      status: 210,
      sku: 'PM0075166',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307589',
      rtl_batch_array: '6307589',
      name_search: 'MAMCHARI',
      name: "Mahonia media 'Charity'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 482,
      chnn_stock_retail: 482,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19P3CCES',
      statusContent: 'dot',
      hash: '1ZEDVAGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZEDVAGB'
    },
    {
      status: 210,
      sku: 'PM0075158',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307562',
      rtl_batch_array: '6307562',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 623,
      chnn_stock_retail: 623,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: '8CJALDX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CJALDX2'
    },
    {
      status: 910,
      sku: 'PM0075171',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345509',
      rtl_batch_array: '6345509',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '150175C25',
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 28.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K4KYVVP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4KYVVP2'
    },
    {
      status: 910,
      sku: 'PM0075172',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345510',
      rtl_batch_array: '6345510',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 33.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KBZVNWC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBZVNWC6'
    },
    {
      status: 910,
      sku: 'PM0075138',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307431',
      rtl_batch_array: '6307431',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'YY6LX8TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YY6LX8TW'
    },
    {
      status: 810,
      sku: 'PM0075176',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307627',
      rtl_batch_array: '6307627',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 24.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: 'BD6RB7N4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BD6RB7N4'
    },
    {
      status: 810,
      sku: 'PM0075177',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307628',
      rtl_batch_array: '6307628',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 26.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: '5NKCPT19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NKCPT19'
    },
    {
      status: 210,
      sku: 'PM0075179',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307636',
      rtl_batch_array: '6307636',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_prcp: 23.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'EABDY61H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EABDY61H'
    },
    {
      status: 910,
      sku: 'PM0075180',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307648',
      rtl_batch_array: '6307648',
      name_search: 'RHSCANDE',
      name: 'Rhodotypos scandens',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5KXESB3',
      statusContent: 'dot',
      hash: '47SREYNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47SREYNP'
    },
    {
      status: 210,
      sku: 'PM0075181',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307649',
      rtl_batch_array: '6307649',
      name_search: 'RHGLABRA',
      name: 'Rhus glabra',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8E4XNNPW;PGATZ5B2;ZZXJTKYK;H53EKC1J;X9J3XHZJ;GEP7DXHS;2TND71EB;NVVB8PR4;HNG4X732;RS49H6CS',
      statusContent: 'dot',
      hash: '1KTEKS6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KTEKS6X'
    },
    {
      status: 910,
      sku: 'PM0075182',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345523',
      rtl_batch_array: '6345523',
      name_search: 'RHGLABRA',
      name: 'Rhus glabra',
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 49.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8E4XNNPW;PGATZ5B2;ZZXJTKYK;H53EKC1J;X9J3XHZJ;GEP7DXHS;2TND71EB;NVVB8PR4;HNG4X732;RS49H6CS',
      statusContent: 'dot',
      hash: 'SSBEV7DG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSBEV7DG'
    },
    {
      status: 210,
      sku: 'PM0075183',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307651',
      rtl_batch_array: '6307651',
      name_search: 'RHGLACIN',
      name: "Rhus glabra 'Laciniata'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 163,
      chnn_stock_retail: 163,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9D42WC6B',
      statusContent: 'dot',
      hash: 'D47T5DV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D47T5DV3'
    },
    {
      status: 210,
      sku: 'PM0075184',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307652',
      rtl_batch_array: '6307652',
      name_search: 'RHTYPHIN',
      name: 'Rhus typhina',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8BDK8TKB;Z4JAX3CP;T3RCB7AH;75EW7DNA;R51DNY6N;CVZ2KR3S;S9N3VZER',
      statusContent: 'dot',
      hash: '44E2CR6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44E2CR6V'
    },
    {
      status: 210,
      sku: 'PM0075185',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307653',
      rtl_batch_array: '6307653',
      name_search: 'RHTDISSE',
      name: "Rhus typhina 'Dissecta'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_prcp: 9.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KLS7664P',
      statusContent: 'dot',
      hash: '8VZP3VZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VZP3VZC'
    },
    {
      status: 210,
      sku: 'PM0004546',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336258',
      rtl_batch_array: '5423582, 6336258',
      name_search: 'PHSNOWBE',
      name: "Philadelphus 'Snowbelle'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 347,
      chnn_stock_retail: 377,
      sppl_prcp: 3.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPBCVAY5;RC5JVBYP',
      statusContent: 'dot',
      hash: 'C1JN4EJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1JN4EJC'
    },
    {
      status: 910,
      sku: 'PM0075187',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307667',
      rtl_batch_array: '6307667',
      name_search: 'SCHMOONL',
      name: "Schizophragma hydrangeoides 'Moonlight'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LL5SD466;ZT3TVWWS;BVSR98PZ;9NEATXXT;HJRZLLJ9;1YJ4B1V5',
      statusContent: 'dot',
      hash: 'N7S9JW86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7S9JW86'
    },
    {
      status: 810,
      sku: 'PM0075188',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307668',
      rtl_batch_array: '6307668',
      name_search: 'SCHMOONL',
      name: "Schizophragma hydrangeoides 'Moonlight'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 19.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LL5SD466;ZT3TVWWS;BVSR98PZ;9NEATXXT;HJRZLLJ9;1YJ4B1V5',
      statusContent: 'dot',
      hash: '5L8ZJGPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L8ZJGPG'
    },
    {
      status: 810,
      sku: 'PM0075189',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307669',
      rtl_batch_array: '6307669',
      name_search: 'SCHMOONL',
      name: "Schizophragma hydrangeoides 'Moonlight'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 21.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LL5SD466;ZT3TVWWS;BVSR98PZ;9NEATXXT;HJRZLLJ9;1YJ4B1V5',
      statusContent: 'dot',
      hash: 'BA74ALWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA74ALWX'
    },
    {
      status: 910,
      sku: 'PM0075190',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYVFSTEP',
      name: "Syringa vulgaris 'Mme Florent Stepman'",
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      imageCore: '58774N2R;TN5WK3DC;LJ78SRBR;8E4Y8Y37',
      statusContent: 'dot',
      hash: 'YZWHS7W3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YZWHS7W3'
    },
    {
      status: 910,
      sku: 'PM0075191',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYVFSTEP',
      name: "Syringa vulgaris 'Mme Florent Stepman'",
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      imageCore: '58774N2R;TN5WK3DC;LJ78SRBR;8E4Y8Y37',
      statusContent: 'dot',
      hash: 'DC137NH6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DC137NH6'
    },
    {
      status: 210,
      sku: 'PM0075194',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307683',
      rtl_batch_array: '6307683',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_prcp: 5.682,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: '28RLJPP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28RLJPP8'
    },
    {
      status: 910,
      sku: 'PM0082623',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPDPBBAN',
      name: 'Spiraea Double Play Big Bang',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'GGTL9ZWP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GGTL9ZWP'
    },
    {
      status: 910,
      sku: 'PM0082624',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJDPRED',
      name: 'Spiraea japonica Double Play Red',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      statusContent: 'dot',
      hash: '2ZW11SNB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2ZW11SNB'
    },
    {
      status: 910,
      sku: 'PM0075197',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307693',
      rtl_batch_array: '6307693',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 28.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'V5E5SGDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5E5SGDJ'
    },
    {
      status: 910,
      sku: 'PM0075198',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307694',
      rtl_batch_array: '6307694',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 32.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'B7T96JGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7T96JGL'
    },
    {
      status: 910,
      sku: 'PM0075200',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346744',
      rtl_batch_array: '6346744',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '100125C20SO',
      rng_range_identifier: 'SPECH100125C20',
      rng_range_description: 'Specimen H100 cm 125 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 23.214,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'SHRYJ84W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHRYJ84W'
    },
    {
      status: 810,
      sku: 'PM0075201',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346745',
      rtl_batch_array: '6346745',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '125150C20SO',
      rng_range_identifier: 'SPECH125150C20',
      rng_range_description: 'Specimen H125 cm 150 cm C20',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 25.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: '4JHV6193',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JHV6193'
    },
    {
      status: 810,
      sku: 'PM0075202',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307704',
      rtl_batch_array: '6307704',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 22.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: 'YCZ5VZB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCZ5VZB9'
    },
    {
      status: 210,
      sku: 'PM0075203',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307705',
      rtl_batch_array: '6307705',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_prcp: 27.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: 'JPB25DBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPB25DBD'
    },
    {
      status: 810,
      sku: 'PM0075204',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345534',
      rtl_batch_array: '6345534',
      name_search: 'VIPPBEAU',
      name: "Viburnum plicatum 'Pink Beauty'",
      sppl_size_code: '080100C25',
      rng_range_identifier: 'H080100C25',
      rng_range_description: 'H80 cm 100 cm C25',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 32.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J53K7XR',
      statusContent: 'dot',
      hash: '9C9TKWVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9C9TKWVS'
    },
    {
      status: 810,
      sku: 'PM0075205',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345532',
      rtl_batch_array: '6345532',
      name_search: 'VIPPBEAU',
      name: "Viburnum plicatum 'Pink Beauty'",
      sppl_size_code: '100125C25',
      rng_range_identifier: 'H100125C25',
      rng_range_description: 'H100 cm 125 cm C25',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 38.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J53K7XR',
      statusContent: 'dot',
      hash: 'EA11SNK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA11SNK6'
    },
    {
      status: 810,
      sku: 'PM0075206',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345533',
      rtl_batch_array: '6345533',
      name_search: 'VIPPBEAU',
      name: "Viburnum plicatum 'Pink Beauty'",
      sppl_size_code: '125150C25',
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 43.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J53K7XR',
      statusContent: 'dot',
      hash: '12RHS8RY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12RHS8RY'
    },
    {
      status: 810,
      sku: 'PM0075208',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307722',
      rtl_batch_array: '6307722',
      name_search: 'CELIBANI',
      name: 'Cedrus libani',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 16.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPK35RB9;YVSAPN13',
      statusContent: 'dot',
      hash: '82EWEAR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82EWEAR9'
    },
    {
      status: 210,
      sku: 'PM0075209',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307729',
      rtl_batch_array: '6307729',
      name_search: 'JUSVARIE',
      name: "Juniperus sabina 'Variegata'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TWLWL44S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWLWL44S'
    },
    {
      status: 210,
      sku: 'PM0075210',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307731',
      rtl_batch_array: '6307731',
      name_search: 'MEGLYPTO',
      name: 'Metasequoia glyptostroboides',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APZV6165;EEDG59S2;Y3EJYXBA;TWP9Y85L;NSNDZL7H;PG49N7R1;KVA96A2A;YLYJJ13K;HKEJX78L;4ZTPD9WJ;DLDSW7N8;PNBZXV8L;CZ7LZREB;R8K1C5HK;H47B2JEC;CHS94XV8',
      statusContent: 'dot',
      hash: '8CCD8EDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CCD8EDZ'
    },
    {
      status: 810,
      sku: 'PM0075149',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307503',
      rtl_batch_array: '6307503',
      name_search: 'LAWVOSSI',
      name: "Laburnum watereri 'Vossii'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 11.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6P8DCEB;B4HD3CG7;CJZ9RAHK;DEXRAHR7;G9LDP2G5;T7DJHNLB;9G78LHKK',
      statusContent: 'dot',
      hash: 'CVVCYSCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVVCYSCK'
    },
    {
      status: 910,
      sku: 'PM0075148',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAWVOSSI',
      name: "Laburnum watereri 'Vossii'",
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      imageCore:
        'K6P8DCEB;B4HD3CG7;CJZ9RAHK;DEXRAHR7;G9LDP2G5;T7DJHNLB;9G78LHKK',
      statusContent: 'dot',
      hash: '4LXSSX25',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4LXSSX25'
    },
    {
      status: 910,
      sku: 'PM0075169',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345507',
      rtl_batch_array: '6345507',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 33.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'HDXXX2XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDXXX2XA'
    },
    {
      status: 910,
      sku: 'PM0075168',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345506',
      rtl_batch_array: '6345506',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '150175C25',
      rng_range_identifier: 'H150175C25',
      rng_range_description: 'H150 cm 175 cm C25',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 28.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'J8XGZL5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8XGZL5J'
    },
    {
      status: 910,
      sku: 'PM0075167',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345505',
      rtl_batch_array: '6345505',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '125150C25',
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 26.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'G8WA78VL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8WA78VL'
    },
    {
      status: 810,
      sku: 'PM0075175',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307625',
      rtl_batch_array: '6307625',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 16.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'ST6NYY9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST6NYY9T'
    },
    {
      status: 810,
      sku: 'PM0075174',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307624',
      rtl_batch_array: '6307624',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'P47GXYLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P47GXYLA'
    },
    {
      status: 810,
      sku: 'PM0075173',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307623',
      rtl_batch_array: '6307623',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_prcp: 11.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'JDZEAEZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDZEAEZY'
    },
    {
      status: 910,
      sku: 'PM0075186',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RHTDISSE',
      name: "Rhus typhina 'Dissecta'",
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      imageCore: 'KLS7664P',
      statusContent: 'dot',
      hash: 'R2C47Z1B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R2C47Z1B'
    },
    {
      status: 210,
      sku: 'PM0074794',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274867',
      rtl_batch_array: '6274867, 6353360',
      name_search: 'SCCCGREA',
      name: "Scabiosa caucasica 'Clive Greaves'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 268,
      chnn_stock_retail: 526,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7W536BRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W536BRK'
    },
    {
      status: 810,
      sku: 'PM0075199',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307695',
      rtl_batch_array: '6307695',
      name_search: 'VICARLCE',
      name: 'Viburnum carlcephalum',
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 28.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLC4D9D5;1PE21WKK;PKZYVWCX;LCB99K7C;A4PWJPX1;HV2P3ZJH',
      statusContent: 'dot',
      hash: 'RW7TTT1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW7TTT1E'
    },
    {
      status: 210,
      sku: 'PM0018522',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6269821',
      rtl_batch_array: '6284159, 6254996, 6269821, 6334343',
      name_search: 'ACMOLLIS',
      name: 'Acanthus mollis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1300,
      chnn_stock_retail: 4252,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72ZXRJN1;GTB79R6C;52561Y7L',
      statusContent: 'dot',
      hash: 'Y54HLJ63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y54HLJ63'
    },
    {
      status: 210,
      sku: 'PM0080320',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336359',
      rtl_batch_array: '6336359',
      name_search: 'PIMMOPS',
      name: "Pinus mugo 'Mops'",
      sppl_size_code: '060STC10',
      rng_range_identifier: 'STE060C10',
      rng_range_description: 'Stem 60 cm C10',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 34.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTWG8NA3;HKC34X1D;L8L8Y9VS;S7L7X86R;NBJ4EZ6V;9YHXJY9D;JACEKARS;H8CNE9Y6;A5BG3BR5',
      statusContent: 'dot',
      hash: '1AWNA9NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AWNA9NK'
    },
    {
      status: 810,
      sku: 'PM0080322',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336361',
      rtl_batch_array: '6336361',
      name_search: 'PIMWSUN',
      name: "Pinus mugo 'Winter Sun'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 29.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJ5BYZGR;DTBPY372;KZV4CB9Y;KH1HZJL4;2951VNVD;TH8Y515R',
      statusContent: 'dot',
      hash: '7TRN14HY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TRN14HY'
    },
    {
      status: 810,
      sku: 'PM0080323',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336362',
      rtl_batch_array: '6336362',
      name_search: 'PIMWSUN',
      name: "Pinus mugo 'Winter Sun'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 25.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJ5BYZGR;DTBPY372;KZV4CB9Y;KH1HZJL4;2951VNVD;TH8Y515R',
      statusContent: 'dot',
      hash: 'HHD9SGRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHD9SGRZ'
    },
    {
      status: 810,
      sku: 'PM0080324',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336364',
      rtl_batch_array: '6336364',
      name_search: 'PINNANA',
      name: "Pinus nigra 'Nana'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 20.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EVA2TEBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVA2TEBZ'
    },
    {
      status: 210,
      sku: 'PM0080325',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336365',
      rtl_batch_array: '6336365',
      name_search: 'PISWATER',
      name: "Pinus sylvestris 'Watereri'",
      sppl_size_code: '040STC74',
      rng_range_identifier: 'STE040C7.5',
      rng_range_description: 'Stem 40 cm C7.5',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 27.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJDV27VG;HHKC8HHY;6TD9W7HW;Y9NEB4C8;ZD8KPPRT',
      statusContent: 'dot',
      hash: '6LCBAD5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LCBAD5L'
    },
    {
      status: 210,
      sku: 'PM0080326',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336366',
      rtl_batch_array: '6336366',
      name_search: 'SCVERTIC',
      name: 'Sciadopitys verticillata',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 845,
      chnn_stock_retail: 845,
      sppl_prcp: 14.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CBN1HY6;N6RBRE96;A3AAXPA6;9J7NWD3V;9X3DVLJP;D7NER187',
      statusContent: 'dot',
      hash: '8YT71DL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YT71DL5'
    },
    {
      status: 910,
      sku: 'PM0082625',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPDPDOOZ',
      name: 'Spiraea Double Play Doozie',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'Z251KBVP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z251KBVP'
    },
    {
      status: 210,
      sku: 'PM0082626',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354205',
      rtl_batch_array: '6354205',
      name_search: 'YUGBSTAR',
      name: 'Yucca gloriosa Bright Star',
      sppl_size_code: '015020P12',
      rng_range_identifier: 'H015020P12',
      rng_range_description: 'H15 cm 20 cm P12',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_prcp: 3.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZKS8Y1GJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKS8Y1GJ'
    },
    {
      status: 210,
      sku: 'PM0082627',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354238',
      rtl_batch_array: '6354238',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 3275,
      chnn_stock_retail: 3275,
      sppl_prcp: 1.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'VLHRVBB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLHRVBB3'
    },
    {
      status: 810,
      sku: 'PM0082628',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354774',
      rtl_batch_array: '6354774',
      name_search: 'BUDBCLWH',
      name: 'Buddleja d. B. Candy Little White',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 2.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SS4DPETG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS4DPETG'
    },
    {
      status: 210,
      sku: 'PM0082629',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354775',
      rtl_batch_array: '6354775',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'XG7D7YK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG7D7YK9'
    },
    {
      status: 810,
      sku: 'PM0082630',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354776',
      rtl_batch_array: '6354776',
      name_search: 'CACSTEPH',
      name: 'Caryopteris clandonensis Stephi',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9P2K76A;ALSTERGD;TBHKD8CG;W6K4VCCS;CL17AVXG;L5DTJNEW',
      statusContent: 'dot',
      hash: '7X6LJGVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7X6LJGVD'
    },
    {
      status: 210,
      sku: 'PM0074976',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276071',
      rtl_batch_array: '6276071',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '090100C12',
      rng_range_identifier: 'H090100C12',
      rng_range_description: 'H90 cm 100 cm C12',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 22.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '9CYES9SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CYES9SX'
    },
    {
      status: 210,
      sku: 'PM0075249',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112952',
      rtl_batch_array: '6112952, 6337833',
      name_search: 'CAOEVERG',
      name: "Carex oshimensis 'Evergold'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 3420,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GRPT1SZT;VVZNRATC;ESAR6PAT;APY46CNB;H2DC31DZ;83VCKVVJ;PW2S55S9;9GTH1G9G;W4LCJ58N;18H6YJYK;LN6XZ4Z7;59TW4SL2;9S9L5HS3;V5H6WN3C;ENVPHVX4;EPZSG96L;YW8DP41G;ZR43CD28;DCRX9GGE;KWKCBTAG;GBXS1BTT;PRR7J4PB',
      statusContent: 'dot',
      hash: 'HT33V7G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT33V7G9'
    },
    {
      status: 210,
      sku: 'PM0075212',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278024',
      rtl_batch_array: '6278024',
      name_search: 'HYKGEMO',
      name: "Hypericum kalmianum 'Gemo'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3329,
      chnn_stock_retail: 3329,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '95YDVR17;DP12YKSA',
      statusContent: 'dot',
      hash: 'GTVZZ2YS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTVZZ2YS'
    },
    {
      status: 210,
      sku: 'PM0075214',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278107',
      rtl_batch_array: '6278107',
      name_search: 'SAPPENDU',
      name: "Salix purpurea 'Pendula'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1437,
      chnn_stock_retail: 1437,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJTVEPT2',
      statusContent: 'dot',
      hash: '8683WCHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8683WCHP'
    },
    {
      status: 910,
      sku: 'PM0075251',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MALMERRI',
      name: "Magnolia loebneri 'Merrill'",
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      imageCore:
        'VRCSEY1P;HYNLPAJ9;VBZG2KWE;SSWZEW5G;VN91J1TX;NHB2VBBT;XSZYENPY;1264ZLHH;H83G8G42;11HY5ACJ;CKYWENT5;TK44494D',
      statusContent: 'dot',
      hash: 'EEWSZ9CZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EEWSZ9CZ'
    },
    {
      status: 210,
      sku: 'PM0074977',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276072',
      rtl_batch_array: '6276072',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '090100C15',
      rng_range_identifier: 'H090C15',
      rng_range_description: 'H90 cm C15',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 25.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'AN8S47B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AN8S47B3'
    },
    {
      status: 210,
      sku: 'PM0080370',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337721',
      rtl_batch_array: '6337721',
      name_search: 'BUDFLORE',
      name: "Buddleja davidii 'Florence'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 134,
      chnn_stock_retail: 134,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1EYBGE1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1EYBGE1N'
    },
    {
      status: 210,
      sku: 'PM0074978',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276073',
      rtl_batch_array: '6276073',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '100110C15',
      rng_range_identifier: 'H100110C15',
      rng_range_description: 'H100 cm 110 cm C15',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 32.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'TSHGKT35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSHGKT35'
    },
    {
      status: 210,
      sku: 'PM0075230',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278317',
      rtl_batch_array: '6278317',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'LYW4G965',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYW4G965'
    },
    {
      status: 210,
      sku: 'PM0074979',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276074',
      rtl_batch_array: '6276074',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '100120C20',
      rng_range_identifier: 'H100120C20',
      rng_range_description: 'H100 cm 120 cm C20',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 35.435,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '6T2TAY71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T2TAY71'
    },
    {
      status: 210,
      sku: 'PM0074980',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276078',
      rtl_batch_array: '6276078',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'PEN1LYVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEN1LYVY'
    },
    {
      status: 210,
      sku: 'PM0075250',
      core_name: 'Plant',
      sppl_ean: '8720664860261',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161645',
      rtl_batch_array: '6161645',
      name_search: 'DOJEFFRE',
      name: 'Dodecatheon jeffreyi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YGDBDE3B',
      statusContent: 'dot',
      hash: '9B8RB5WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B8RB5WR'
    },
    {
      status: 810,
      sku: 'PM0082631',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354777',
      rtl_batch_array: '6354777',
      name_search: 'CECHGOLD',
      name: "Cercis canadensis 'Hearts of Gold'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 9.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRS8BGWL;G5692R8N;EWVBZSVC;CHVCP8A8;8J6YBCL2;9BZ9YDVE;695G4LK1;77JEE5Y4;BBYEHLRX;6L4VEWAV;74RDNNL2;S88G6BS9;ZSHXHCAN;PJK6DX4A',
      statusContent: 'dot',
      hash: 'E7N8R4N2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7N8R4N2'
    },
    {
      status: 910,
      sku: 'PM0074982',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUFCOLOR',
      name: "Euonymus fortunei 'Coloratus'",
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      imageCore: 'J1LPHN5Y;NN7E8ELY',
      statusContent: 'dot',
      hash: 'A77T38GT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A77T38GT'
    },
    {
      status: 210,
      sku: 'PM0072472',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242152',
      rtl_batch_array: '6242152',
      name_search: 'GENPURPU',
      name: "Geranium nodosum 'Purpur'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1AA6VVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1AA6VVP'
    },
    {
      status: 210,
      sku: 'PM0075223',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294829',
      rtl_batch_array: '6294829',
      name_search: 'COSWBEAU',
      name: "Cornus sanguinea 'Winter Beauty'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_prcp: 3.447,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRDN1TCW;2Z3DSCS8;TZPXD2G1;LYJENPAZ;AYL13WRY;CZ8H6XYY',
      statusContent: 'dot',
      hash: 'H4GE7GDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4GE7GDW'
    },
    {
      status: 210,
      sku: 'PM0075217',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6278283',
      rtl_batch_array: '6378324, 6278283',
      name_search: 'GAPROCUM',
      name: 'Gaultheria procumbens',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 4330,
      chnn_stock_retail: 14132,
      sppl_order_minimum: 3,
      sppl_prcp: 1.249,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SAPSVZ78;2E9BY7E8;N9J99DZA;XV9X8219;T5TT7PBP;66H4BZ4X;16ATYWBR;XYW5E6YX;VVPNT1AR;9WSSE2RC;BCD3XLKT;Y8VHXSEW;W4ATXP61;H476TL3X;TCX6DHZA;WKEK1XBV;RYDYAA46;AJYW4K1V;Z12J3TY1;PVXGE37Y;K2W9LKND;CCYX83RP',
      statusContent: 'dot',
      hash: 'X1S1ACC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1S1ACC9'
    },
    {
      status: 210,
      sku: 'PM0075228',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278315',
      rtl_batch_array: '6278315',
      name_search: 'POFDAYDA',
      name: "Potentilla fruticosa 'Daydawn'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BBADP347',
      statusContent: 'dot',
      hash: '5G4818R2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G4818R2'
    },
    {
      status: 210,
      sku: 'PM0080327',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334774',
      rtl_batch_array: '6334774',
      name_search: 'CODMAJOR',
      name: "Cotoneaster dammeri 'Major'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V37PA1X8',
      statusContent: 'dot',
      hash: '1BHTVDXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BHTVDXV'
    },
    {
      status: 210,
      sku: 'PM0025882',
      core_name: 'Plant',
      sppl_ean: '8720353028750',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '4707505',
      rtl_batch_array: '4707505',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '110120C25PY',
      rng_range_identifier: 'PYRAH110120C25',
      rng_range_description: 'Pyramid H110 cm 120 cm C25',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 40.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'YZJP4JHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZJP4JHB'
    },
    {
      status: 210,
      sku: 'PM0072513',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251013',
      rtl_batch_array: '6251013',
      name_search: 'TESPECIO',
      name: 'Telekia speciosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WTG8P3G',
      statusContent: 'dot',
      hash: 'C3DHPAK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3DHPAK7'
    },
    {
      status: 210,
      sku: 'PM0080328',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334776',
      rtl_batch_array: '6334776',
      name_search: 'PATERMIN',
      name: 'Pachysandra terminalis',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '75KDGC7C;LELCZ73R;NDHGKGGT;GN61W2KN;TX925EKH;W9S1NG9S;WP6ZBEBW',
      statusContent: 'dot',
      hash: 'A7JN2GCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7JN2GCN'
    },
    {
      status: 210,
      sku: 'PM0072515',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251017',
      rtl_batch_array: '6251017',
      name_search: 'DABOLAND',
      name: "Dahlia 'Bishop of Llandaf'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T9ZCTJBX;G53YV3CV;9BE8XTSX;K6CB31P5;T4CZR8BL;DHT9SNKT;W6TWBGXC',
      statusContent: 'dot',
      hash: 'EXBARX9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXBARX9T'
    },
    {
      status: 210,
      sku: 'PM0080329',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334777',
      rtl_batch_array: '6334777',
      name_search: 'PATGCARP',
      name: "Pachysandra terminalis 'Green Carpet'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R6S2RGVY;RZBP43SX;8R4LVBKN;8JDW2D88',
      statusContent: 'dot',
      hash: 'NPGCTXNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPGCTXNL'
    },
    {
      status: 810,
      sku: 'PM0082632',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354778',
      rtl_batch_array: '6354778',
      name_search: 'CECOKLAH',
      name: "Cercis canadensis 'Oklahoma'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 9.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72JZLZNN;ZJ386194;C5KP8W4Z;XANLRS9R;7HDRLDV5;PV1E9XRW;LE1J66JN;LGV7H977',
      statusContent: 'dot',
      hash: 'EVNR4C7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVNR4C7Z'
    },
    {
      status: 810,
      sku: 'PM0082633',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354779',
      rtl_batch_array: '6354779',
      name_search: 'CECRFALL',
      name: "Cercis canadensis 'Ruby Falls'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 9.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SR787L3P;8DTCLNAY;S9CDB1TT;X77PWN8X;PZWC6HHR;RWW3K4ZC;9ED4PXKP;2PVT2611',
      statusContent: 'dot',
      hash: '5XLGPWZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XLGPWZC'
    },
    {
      status: 210,
      sku: 'PM0075213',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278050',
      rtl_batch_array: '6278050',
      name_search: 'LONEWILS',
      name: "Lonicera nitida 'Ernest Wilson'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8865,
      chnn_stock_retail: 8865,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HXW39X5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXW39X5B'
    },
    {
      status: 210,
      sku: 'PM0080330',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334780',
      rtl_batch_array: '6334780',
      name_search: 'HYDMSIBI',
      name: "Hydrangea macrophylla 'Sibilla'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 9499,
      chnn_stock_retail: 9499,
      sppl_order_minimum: 3,
      sppl_prcp: 1.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G8HRBES3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8HRBES3'
    },
    {
      status: 210,
      sku: 'PM0075215',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6278125',
      rtl_batch_array: '6304913, 6278125',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2335,
      chnn_stock_retail: 3334,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'B1HXCVGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1HXCVGB'
    },
    {
      status: 210,
      sku: 'PM0080331',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336443',
      rtl_batch_array: '6336443',
      name_search: 'HYINODOR',
      name: 'Hypericum inodorum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2967,
      chnn_stock_retail: 2967,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YNYA773A;7986ABZH;613X2EYC',
      statusContent: 'dot',
      hash: 'V2KACV7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2KACV7K'
    },
    {
      status: 210,
      sku: 'PM0080332',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336444',
      rtl_batch_array: '6336444',
      name_search: 'HYIANNEB',
      name: "Hypericum inodorum 'Annebel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2400,
      chnn_stock_retail: 2400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DTX4D4XH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTX4D4XH'
    },
    {
      status: 210,
      sku: 'PM0080333',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336445',
      rtl_batch_array: '6336445',
      name_search: 'HYIBEAUT',
      name: "Hypericum inodorum 'Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XGYRDCGX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGYRDCGX'
    },
    {
      status: 210,
      sku: 'PM0075220',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278305',
      rtl_batch_array: '6278305',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.708,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: '439AE1YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '439AE1YT'
    },
    {
      status: 210,
      sku: 'PM0080334',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336447',
      rtl_batch_array: '6336447',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4750,
      chnn_stock_retail: 4750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'DAYP9GCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAYP9GCH'
    },
    {
      status: 210,
      sku: 'PM0075224',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278311',
      rtl_batch_array: '6278311',
      name_search: 'PHDBLANC',
      name: "Philadelphus 'Dame Blanche'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRATG3YL',
      statusContent: 'dot',
      hash: '9Z8D7JS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Z8D7JS5'
    },
    {
      status: 910,
      sku: 'PM0075225',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHCTPARK',
      name: "Physocarpus capitatus 'Tilden Park'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: 'TJKB6GN9',
      statusContent: 'dot',
      hash: 'NWS7KDZA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NWS7KDZA'
    },
    {
      status: 810,
      sku: 'PM0075226',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278313',
      rtl_batch_array: '6278313',
      name_search: 'PHOLDEVI',
      name: 'Physocarpus opulifolius Little Devil',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLL1JEVG;93NSP7X1;BBH6HT8K;G2ZPZ3SX;RCET4DHY;5AVH79DT;RCX51SDA;SJ47HC17;8EZXJ5L5;BA7JGG79;L9PB697R;NNB946VN;AS153YHN;9JVRCY9Y;66WY8DS7;127JNGA5;R1V3D2WD;SBGEHTWL;D52D743T;2WLD7SCH;T7563A9W;PDYE699V;CYBR19Z8',
      statusContent: 'dot',
      hash: '59V2E2VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59V2E2VJ'
    },
    {
      status: 210,
      sku: 'PM0075227',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278314',
      rtl_batch_array: '6278314',
      name_search: 'PHOTWINE',
      name: 'Physocarpus opulifolius Tiny Wine',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CL1TTKDB;8RTXK5C9;6EH61CE3',
      statusContent: 'dot',
      hash: '4PGDYV7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PGDYV7C'
    },
    {
      status: 210,
      sku: 'PM0075229',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278316',
      rtl_batch_array: '6278316',
      name_search: 'POFPPARA',
      name: 'Potentilla fruticosa Pink Paradise',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8PVLS37;P8Y2HT8B;9PPCWC1X;JSV4LDD4;E4SAPB1Y',
      statusContent: 'dot',
      hash: 'V5CNBKD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5CNBKD6'
    },
    {
      status: 210,
      sku: 'PM0080335',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336448',
      rtl_batch_array: '6336448',
      name_search: 'LIVLIGA',
      name: "Ligustrum vulgare 'Liga'",
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 11110,
      chnn_stock_retail: 11110,
      sppl_order_minimum: 3,
      sppl_prcp: 0.112,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K1P8A85B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1P8A85B'
    },
    {
      status: 210,
      sku: 'PM0075232',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278319',
      rtl_batch_array: '6278319',
      name_search: 'SPBTGOLD',
      name: "Spiraea betulifolia 'Tor Gold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2611T54B',
      statusContent: 'dot',
      hash: '7CNZXLNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CNZXLNV'
    },
    {
      status: 210,
      sku: 'PM0080336',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336449',
      rtl_batch_array: '6336449',
      name_search: 'LIVLISTR',
      name: "Ligustrum vulgare 'Listrum'",
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 14000,
      chnn_stock_retail: 14000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.112,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7VPT8Z8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7VPT8Z8'
    },
    {
      status: 210,
      sku: 'PM0075234',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205978',
      rtl_batch_array: '6205978',
      name_search: 'CACFCURL',
      name: "Carex comans 'Frosted Curls'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XWKKGR1V;WBG361VY;99GYTLL2;SX6AA7AB;TAT6WW2B;4W5AP4D3;5XED8LE6;TV536JZ1;W7KPK1J9;7971T56V;BD5VP1GT;CACEEJZK;DXGX5LHS;TZWJXXTJ;CTT6XEBW;TXCN2PBC;ZSNPT7LE;YLWRR1D7;HA74WY1T;423BPXP4;8Y43J9RJ;5XD6K3KB;7CY4KK8R',
      statusContent: 'dot',
      hash: 'C3SY387P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3SY387P'
    },
    {
      status: 210,
      sku: 'PM0075235',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6205987',
      rtl_batch_array: '6205987',
      name_search: 'PEASROCK',
      name: "Pennisetum advena 'Sky Rocket'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '435EW1LD;XTBCHT5P;C9PJETT3;PHBERA2Y;W9SB7CP9;SYZE18JH;NVY44J2A;NHP4J3XS;25V4ZG3P;ZGEYJSB5;7GBS79WP;48RS2TDK;2H7E4214;PNNRWTTT;6A7GVJEN;BRH98LNJ;163PTSSZ;Y4K1GAT6',
      statusContent: 'dot',
      hash: 'Z1586P5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1586P5C'
    },
    {
      status: 210,
      sku: 'PM0080337',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336455',
      rtl_batch_array: '6371225, 6336455',
      name_search: 'VIMBGOLD',
      name: "Vinca minor 'Blue and Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2264,
      chnn_stock_retail: 2785,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DN8BDXJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DN8BDXJ4'
    },
    {
      status: 210,
      sku: 'PM0080338',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6336459',
      rtl_batch_array: '6349061, 6336459, 6291686, 6197138',
      name_search: 'CAPSTELL',
      name: "Campanula poscharskyana 'Stella'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 936,
      chnn_stock_retail: 4623,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TE8H8Y3L;CHG2WRJN;HWYCBXCV;1RXYYGKW;5PL8N5G2;BS4G6168;59EBSB8S;TS2JHX55;A8TAP4T7',
      statusContent: 'dot',
      hash: 'HTPXA7V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTPXA7V5'
    },
    {
      status: 210,
      sku: 'PM0080339',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336464',
      rtl_batch_array: '6336464',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: 'LEVP12',
      rng_range_identifier: 'P12POT',
      rng_range_description: 'P12 Pots',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'WEL8CKJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEL8CKJ2'
    },
    {
      status: 210,
      sku: 'PM0080340',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336466',
      rtl_batch_array: '6336466',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1051,
      chnn_stock_retail: 1051,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'ANNK1EZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANNK1EZC'
    },
    {
      status: 210,
      sku: 'PM0080341',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300839',
      rtl_batch_array: '6336467, 6300839',
      name_search: 'COPQCARP',
      name: "Cotoneaster procumbens 'Queen of Carpets'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1884,
      chnn_stock_retail: 5106,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4WX354E',
      statusContent: 'dot',
      hash: '62G26S4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62G26S4G'
    },
    {
      status: 210,
      sku: 'PM0080342',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6336468',
      rtl_batch_array: '6336468',
      name_search: 'COSGCARP',
      name: "Cotoneaster salicifolius 'Green Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3222,
      chnn_stock_retail: 3222,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N96JH1GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N96JH1GS'
    },
    {
      status: 210,
      sku: 'PM0080343',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337692',
      rtl_batch_array: '6337692',
      name_search: 'AZMVARIE',
      name: "Azara microphylla 'Variegata'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PZZ29N14;91VVAPNW;4VXY5G5K;7JRNWNDE;EXKT2E4D;CDJ7BSJH;4YC2N3V6',
      statusContent: 'dot',
      hash: 'X82GZ7HE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X82GZ7HE'
    },
    {
      status: 210,
      sku: 'PM0080344',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337693',
      rtl_batch_array: '6337693',
      name_search: 'AZSERRAT',
      name: 'Azara serrata',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XHJWEVHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHJWEVHB'
    },
    {
      status: 210,
      sku: 'PM0080345',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337694',
      rtl_batch_array: '6337694',
      name_search: 'BAPSEUDO',
      name: 'Ballota pseudodictamnus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1523,
      chnn_stock_retail: 1523,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EB8YN66R;YENBHDBS;ADHY6JSZ;ES7LEDNG',
      statusContent: 'dot',
      hash: 'P44HB2ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P44HB2ZS'
    },
    {
      status: 210,
      sku: 'PM0080346',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337695',
      rtl_batch_array: '6337695',
      name_search: 'BEBNANA',
      name: "Berberis buxifolia 'Nana'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 272,
      chnn_stock_retail: 272,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PJD81N14;E6V6967J;CSGRAWSN;AT121S8Y;NDRYT777;GZDXB3SZ;ZWSG7DHD;V73L74ZR;J7B6JR59;S2VS3BDZ;TYA97B35;5TW613EK;TBB9Z9RG;ESETGZ11',
      statusContent: 'dot',
      hash: '6T5P5L51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T5P5L51'
    },
    {
      status: 210,
      sku: 'PM0080347',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337696',
      rtl_batch_array: '6337696',
      name_search: 'BEDARWIN',
      name: 'Berberis darwinii',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 618,
      chnn_stock_retail: 618,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9K7ST591;26SL7JGW;A8GB8RGR;V6LNAVHY;1VGS9N84',
      statusContent: 'dot',
      hash: 'CSV64H1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSV64H1W'
    },
    {
      status: 210,
      sku: 'PM0080348',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337697',
      rtl_batch_array: '6337697',
      name_search: 'BEDCOMPA',
      name: "Berberis darwinii 'Compacta'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1119,
      chnn_stock_retail: 1119,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKW22K6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKW22K6A'
    },
    {
      status: 210,
      sku: 'PM0080349',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337698',
      rtl_batch_array: '6337698',
      name_search: 'BETANANA',
      name: "Berberis thunbergii 'Atropurpurea Nana'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2809,
      chnn_stock_retail: 2809,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7P12S5HL;NWR1EDXC;5ACCYXE4;LTY25C6S;GL7N8K52;HHGYNABH;5YBDJVJ8;ZHJDTAJH;W58E1WSW;HL8JH5EW;ZT1LKC66;92LWR7TD;S4EP7YGC;ZLPLDXNR;568PE4YE;ZZPBBNCD;HSB7PL2N;NR8GVSRT',
      statusContent: 'dot',
      hash: 'KRCTRAT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRCTRAT1'
    },
    {
      status: 210,
      sku: 'PM0080350',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337699',
      rtl_batch_array: '6337699',
      name_search: 'BETAUREA',
      name: "Berberis thunbergii 'Aurea'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z8TLXX1B;45E5PYVL;942KHY3G;JP2ZKZ85;NW5X3AEY',
      statusContent: 'dot',
      hash: '7DEAGL2W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DEAGL2W'
    },
    {
      status: 210,
      sku: 'PM0080351',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337700',
      rtl_batch_array: '6337700',
      name_search: 'BETDPURP',
      name: "Berberis thunbergii 'Dart's Purple'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 824,
      chnn_stock_retail: 824,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ZZ5JPZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZZ5JPZB'
    },
    {
      status: 210,
      sku: 'PM0080352',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337701',
      rtl_batch_array: '6337701',
      name_search: 'BETDRLAD',
      name: "Berberis thunbergii 'Dart's Red Lady'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GWBKYDSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWBKYDSD'
    },
    {
      status: 210,
      sku: 'PM0080353',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337702',
      rtl_batch_array: '6337702',
      name_search: 'BETHARLE',
      name: "Berberis thunbergii 'Harlequin'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1173,
      chnn_stock_retail: 1173,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D98GK36V;TBE54L4S;8RXW31Y2;S2KYHWWS;7GXXZNXV;RDJNNXWG;W1W5RAKN;6GKYN979;6Z9NNZLH;ECLC9BW8;294W3TDA;ER8S7H4K',
      statusContent: 'dot',
      hash: 'GREE9Y68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GREE9Y68'
    },
    {
      status: 210,
      sku: 'PM0080354',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337703',
      rtl_batch_array: '6337703',
      name_search: 'BETHPILL',
      name: "Berberis thunbergii 'Helmond Pillar'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 446,
      chnn_stock_retail: 446,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R3R4259D;9JZYS4VX;97EA3AEL',
      statusContent: 'dot',
      hash: '9TDRZ3RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TDRZ3RL'
    },
    {
      status: 210,
      sku: 'PM0082634',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354780',
      rtl_batch_array: '6354780',
      name_search: 'CHTABLOS',
      name: 'Choisya ternata Apple Blossom',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8LNT3RKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LNT3RKE'
    },
    {
      status: 210,
      sku: 'PM0080355',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337704',
      rtl_batch_array: '6337704',
      name_search: 'BETMARIA',
      name: "Berberis thunbergii 'Maria'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_prcp: 3.279,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJ6JX7Y4;TAE5BY6W;YWG2JJ2J;RZ6GZ1C8;L81L5WL9',
      statusContent: 'dot',
      hash: 'EVK77XNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVK77XNX'
    },
    {
      status: 210,
      sku: 'PM0082635',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354781',
      rtl_batch_array: '6354781',
      name_search: 'CHGOLDFI',
      name: 'Choisya Goldfingers',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1410,
      chnn_stock_retail: 1410,
      sppl_prcp: 2.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E5PBBJ9;TX8TB739;B3L4GZP6',
      statusContent: 'dot',
      hash: '7PCC8RTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PCC8RTR'
    },
    {
      status: 210,
      sku: 'PM0080356',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337705',
      rtl_batch_array: '6337705',
      name_search: 'BETPOWWO',
      name: "Berberis thunbergii 'Powwow'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 843,
      chnn_stock_retail: 843,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KV9W8B8Z;YWGNTKTK;9XZJ8LXG;9JH9Y3A2;2YB3RPC1;S4WGARKW',
      statusContent: 'dot',
      hash: 'ATJ1PRPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATJ1PRPB'
    },
    {
      status: 210,
      sku: 'PM0075222',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294828',
      rtl_batch_array: '6294828',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 226,
      chnn_stock_retail: 226,
      sppl_prcp: 3.447,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'E9BZLPWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9BZLPWA'
    },
    {
      status: 210,
      sku: 'PM0028870',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267740',
      rtl_batch_array: '6267740, 6079481',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 270,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '2V69X9AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V69X9AP'
    },
    {
      status: 210,
      sku: 'PM0014858',
      core_name: 'Plant',
      sppl_ean: '8720664504073',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5504715',
      rtl_batch_array: '5504715',
      name_search: 'CEMADREA',
      name: "Centaurea montana 'Amethyst Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 457,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 1.046,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DNR5WNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DNR5WNH'
    },
    {
      status: 210,
      sku: 'PM0080371',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337722',
      rtl_batch_array: '6337722',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 841,
      chnn_stock_retail: 841,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'HBJ2E7XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBJ2E7XW'
    },
    {
      status: 210,
      sku: 'PM0018608',
      core_name: 'Plant',
      sppl_ean: '8720664859401',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6143847',
      rtl_batch_array: '6143847, 5902330, 6217103',
      name_search: 'DEBBIRD',
      name: "Delphinium (P) 'Blue Bird'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2756,
      chnn_stock_retail: 3832,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A36HXYV9;7BD2XYH4;ZTZ1S77K;GSYHSP8X;4EKW6A9J;94BBKBEV;LT517R74;ZY8L4ES3;ZZXSEPJ2;LSRVG41Z;HK23STXR;NAN431VZ;GCR28HWJ',
      statusContent: 'dot',
      hash: '8GK6XDK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GK6XDK6'
    },
    {
      status: 210,
      sku: 'PM0004910',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229413',
      rtl_batch_array: '6229413',
      name_search: 'DEASTOLA',
      name: "Delphinium (P) 'Astolat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2400,
      chnn_stock_retail: 2400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '19NJ5S1C;HHY3DV3Z;PZ66E6VD;WKJ9BYR3;79W8N3V1;BGV6NVZA;SASEG299;DWRKJCA3;XZ9Z3XVX',
      statusContent: 'dot',
      hash: 'D2BCKS7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2BCKS7D'
    },
    {
      status: 210,
      sku: 'PM0080357',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337706',
      rtl_batch_array: '6337706',
      name_search: 'BETRPILL',
      name: "Berberis thunbergii 'Red Pillar'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZPHBRXA',
      statusContent: 'dot',
      hash: 'CR4B54N2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR4B54N2'
    },
    {
      status: 210,
      sku: 'PM0082636',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354782',
      rtl_batch_array: '6354782',
      name_search: 'CIHLMSUN',
      name: 'Cistus hybridus Little Miss Sunshine',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1151,
      chnn_stock_retail: 1151,
      sppl_order_minimum: 3,
      sppl_prcp: 2.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1YTZYZ8N',
      statusContent: 'dot',
      hash: 'XDKJRCR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDKJRCR8'
    },
    {
      status: 210,
      sku: 'PM0082637',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354783',
      rtl_batch_array: '6354783',
      name_search: 'CLARSPIC',
      name: "Clethra alnifolia 'Ruby Spice'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 2.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '16CEN8WB;2D15HZ47;47Y99NNX;1DRJ54GP;LPDEXV7R;PX8AS6WE',
      statusContent: 'dot',
      hash: 'VDNC7YPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDNC7YPH'
    },
    {
      status: 210,
      sku: 'PM0082638',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354784',
      rtl_batch_array: '6354784',
      name_search: 'COKOCKHA',
      name: "Cornus kousa 'Ockham'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 5.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JWWC2LCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWWC2LCB'
    },
    {
      status: 210,
      sku: 'PM0006896',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308554',
      rtl_batch_array: '6147768, 6308554, 6320863',
      name_search: 'ECPPBELL',
      name: "Echinacea purpurea 'Pica Bella'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1231,
      chnn_stock_retail: 2423,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD937BJR;DSJBXW34;BTV3L3V1',
      statusContent: 'dot',
      hash: 'L1H5HBHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1H5HBHK'
    },
    {
      status: 210,
      sku: 'PM0054213',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 2,
      btch_active_retail: '6242345',
      rtl_batch_array: '6242345, 6345525',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '060STC3',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      sppl_stock_available: 6082,
      chnn_stock_retail: 6096,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'N4V6PYAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4V6PYAC'
    },
    {
      status: 210,
      sku: 'PM0082639',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354785',
      rtl_batch_array: '6354785',
      name_search: 'COCDMAID',
      name: "Cotinus coggygria 'Dusky Maiden'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_order_minimum: 3,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JC18XANK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JC18XANK'
    },
    {
      status: 210,
      sku: 'PM0004918',
      core_name: 'Plant',
      sppl_ean: '8720349489152',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5521405',
      rtl_batch_array: '6207830, 5521405, 6295754',
      name_search: 'BEBDOLL',
      name: "Bergenia 'Baby Doll'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1170,
      chnn_stock_retail: 3636,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DW5BRWKR',
      statusContent: 'dot',
      hash: 'V66WPC3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V66WPC3A'
    },
    {
      status: 210,
      sku: 'PM0006557',
      core_name: 'Plant',
      sppl_ean: '8720664878709',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702235',
      rtl_batch_array: '4702235',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 9.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      imageBatch: '2VS7NYXG',
      statusContent: 'dot',
      hash: 'WZGXBNDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZGXBNDN'
    },
    {
      status: 210,
      sku: 'PM0007618',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307568',
      rtl_batch_array: '6307568',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'XVLL45ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVLL45ZJ'
    },
    {
      status: 210,
      sku: 'PM0012384',
      core_name: 'Plant',
      sppl_ean: '8720349404995',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 7,
      btch_active_retail: '5519729',
      rtl_batch_array:
        '5332541, 6209226, 6250993, 6254742, 5519729, 6258747, 6300866',
      name_search: 'GEROZANN',
      name: 'Geranium Rozanne',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 414,
      chnn_stock_retail: 20382,
      sppl_prcp: 2.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZPJ4SN3;GT4DVXXL;C24JN3BT;XBYX425W;RNV4HWHL;JJ79CY8L;Z3PSHXBT;RRSTZT5E;L3WB9GNP;RB8ELNE5;XDL56ESV;BVLK6EXK;J1XBHEEE',
      imageBatch: 'JHXPHX8A',
      statusContent: 'dot',
      hash: 'ZB9HCEAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZB9HCEAP'
    },
    {
      status: 210,
      sku: 'PM0082640',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354786',
      rtl_batch_array: '6354786',
      name_search: 'DAPPRINC',
      name: 'Daphne Perfume Princess',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3366,
      chnn_stock_retail: 3366,
      sppl_order_minimum: 3,
      sppl_prcp: 6.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LRV83YVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRV83YVN'
    },
    {
      status: 210,
      sku: 'PM0080358',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337707',
      rtl_batch_array: '6337707',
      name_search: 'BETRTOWE',
      name: "Berberis thunb. 'Red Tower'\u00ae",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 263,
      chnn_stock_retail: 263,
      sppl_prcp: 3.279,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2171JBK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2171JBK6'
    },
    {
      status: 210,
      sku: 'PM0080359',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337708',
      rtl_batch_array: '6337708',
      name_search: 'BETRGLOW',
      name: "Berberis thunbergii 'Rose Glow'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 638,
      chnn_stock_retail: 638,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYH6719R;1P7TX883;PTDT1JHX;V2X3XNZX;JLXKR2W2;W8JJJ7GR;AJYZXYPK;HJ784XEH;ZVXYN72R',
      statusContent: 'dot',
      hash: 'TE811HVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TE811HVV'
    },
    {
      status: 210,
      sku: 'PM0080360',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337709',
      rtl_batch_array: '6337709',
      name_search: 'BERTRSTA',
      name: "Berberis thunbergii 'Ruby Star'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_prcp: 3.279,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZ8TXZEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ8TXZEX'
    },
    {
      status: 210,
      sku: 'PM0080361',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337710',
      rtl_batch_array: '6337710',
      name_search: 'BENGTREA',
      name: "Betula nana 'Golden Treasure'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 272,
      chnn_stock_retail: 272,
      sppl_prcp: 4.085,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SY2NSEWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY2NSEWC'
    },
    {
      status: 210,
      sku: 'PM0080362',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337711',
      rtl_batch_array: '6337711',
      name_search: 'BUAUNIQU',
      name: 'Buddleja alternifolia Unique',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXWRJ9KT;AEREWWKG;57YYNLSG;SVAX9J4A;6G4VJZ8K;DK784BJ4;5KKSCBHR',
      statusContent: 'dot',
      hash: '41H1KDJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41H1KDJA'
    },
    {
      status: 210,
      sku: 'PM0080363',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337712',
      rtl_batch_array: '6337712',
      name_search: 'BUFLAVEN',
      name: 'Buddleja Flutterby Lavender',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 733,
      chnn_stock_retail: 733,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ES7PDARY;8JKHT8PT',
      statusContent: 'dot',
      hash: 'NL27TK2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL27TK2C'
    },
    {
      status: 210,
      sku: 'PM0080364',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337713',
      rtl_batch_array: '6337713',
      name_search: 'BUFPEACE',
      name: 'Buddleja Flutterby Peace',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1212,
      chnn_stock_retail: 1212,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPJWEZGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPJWEZGN'
    },
    {
      status: 210,
      sku: 'PM0080365',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337714',
      rtl_batch_array: '6337714',
      name_search: 'BUDFPDPI',
      name: 'Buddleja Free Petite Dark Pink',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 675,
      chnn_stock_retail: 675,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H73TAZG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H73TAZG1'
    },
    {
      status: 210,
      sku: 'PM0080366',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337716',
      rtl_batch_array: '6337716',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1806,
      chnn_stock_retail: 1806,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: 'WEZRAL3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEZRAL3D'
    },
    {
      status: 210,
      sku: 'PM0080367',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337717',
      rtl_batch_array: '6337717',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 742,
      chnn_stock_retail: 742,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6X1BWEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6X1BWEP'
    },
    {
      status: 210,
      sku: 'PM0080368',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337718',
      rtl_batch_array: '6337718',
      name_search: 'BUDAQUEE',
      name: "Buddleja davidii 'African Queen'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2237,
      chnn_stock_retail: 2237,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7P523A4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P523A4P'
    },
    {
      status: 210,
      sku: 'PM0080369',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337720',
      rtl_batch_array: '6337720',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2470,
      chnn_stock_retail: 2470,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'ZPHD5SZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPHD5SZ5'
    },
    {
      status: 210,
      sku: 'PM0082641',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354787',
      rtl_batch_array: '6354787',
      name_search: 'EUJPBLAN',
      name: 'Euonymus japonicus Paloma Blanca',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H3R51T6;B7E9D968;L2K9DYN2;RE2CWTBT;RCPWNR7V;TCAXJVBK;HBBA57GJ;S2VJP2XN;CLH92YZR;L4C927H1;9948PKVC;65LE7L44;ATD8AAJC',
      statusContent: 'dot',
      hash: 'BH5N4VEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BH5N4VEG'
    },
    {
      status: 210,
      sku: 'PM0082642',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354788',
      rtl_batch_array: '6354788',
      name_search: 'FICLMFIG',
      name: 'Ficus carica Little Miss Figgy',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 439,
      chnn_stock_retail: 439,
      sppl_prcp: 4.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHGC2SJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHGC2SJE'
    },
    {
      status: 210,
      sku: 'PM0082643',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354789',
      rtl_batch_array: '6354789',
      name_search: 'HYAPPERC',
      name: "Hydrangea arborescens 'Pink Percussion'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X8WJK5TK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8WJK5TK'
    },
    {
      status: 210,
      sku: 'PM0082644',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354790',
      rtl_batch_array: '6354790',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1388,
      chnn_stock_retail: 1388,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'A3S7ASJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3S7ASJP'
    },
    {
      status: 210,
      sku: 'PM0082645',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354791',
      rtl_batch_array: '6354791',
      name_search: 'KALKALEI',
      name: "Kalmia latifolia 'Kaleidoskop'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 293,
      chnn_stock_retail: 293,
      sppl_prcp: 3.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6WHR839',
      statusContent: 'dot',
      hash: 'TRW6Y6TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRW6Y6TV'
    },
    {
      status: 210,
      sku: 'PM0082646',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354792',
      rtl_batch_array: '6354792',
      name_search: 'LAIRHOT',
      name: 'Lagerstroemia indica Red Hot',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_prcp: 7.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1D7W2XSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1D7W2XSE'
    },
    {
      status: 210,
      sku: 'PM0082647',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354793',
      rtl_batch_array: '6354793',
      name_search: 'LAIRHOT',
      name: 'Lagerstroemia indica Red Hot',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_prcp: 5.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5HK7GXT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HK7GXT1'
    },
    {
      status: 210,
      sku: 'PM0082648',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354794',
      rtl_batch_array: '6354794',
      name_search: 'LABWINE',
      name: "Lavatera 'Burgundy Wine'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1283,
      chnn_stock_retail: 1283,
      sppl_prcp: 2.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GBXEG5RW;5RP73688',
      statusContent: 'dot',
      hash: '69CV6YBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69CV6YBH'
    },
    {
      status: 210,
      sku: 'PM0082649',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354795',
      rtl_batch_array: '6354795',
      name_search: 'LEFWHITE',
      name: 'Leucothoe fontanesiana Whitewater',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1886,
      chnn_stock_retail: 1886,
      sppl_order_minimum: 3,
      sppl_prcp: 2.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BEG16Z76;7YW9P954;2SKRN5LR;93KS3TET;VNT9JXPG;AG2LXBTB;H3NZ469E;1K7XBG8W;2GWR2EDN;6B6JAJZN;SHN4CDTG;S5X8DLX2;K254BS4V;BECPBJ9J;8JPAK7K4;T3C1SRSH;WBC2LS57',
      statusContent: 'dot',
      hash: 'GJYETAXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJYETAXS'
    },
    {
      status: 210,
      sku: 'PM0082650',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354796',
      rtl_batch_array: '6354796',
      name_search: 'LOCRDMAR',
      name: 'Loropetalum chinense Rouge de la Majorie',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 3.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ST417A9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ST417A9R'
    },
    {
      status: 810,
      sku: 'PM0082651',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354797',
      rtl_batch_array: '6354797',
      name_search: 'NADMBAY',
      name: "Nandina d. 'Moon Bay'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJD5BCY3;HDGEGDH7',
      statusContent: 'dot',
      hash: 'N9EL8PK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9EL8PK5'
    },
    {
      status: 810,
      sku: 'PM0082652',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354798',
      rtl_batch_array: '6354798',
      name_search: 'NADRLIGH',
      name: 'Nandina d. Red Light',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 3,
      sppl_prcp: 3.23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NEBVVCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NEBVVCK'
    },
    {
      status: 210,
      sku: 'PM0082653',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354801',
      rtl_batch_array: '6354801',
      name_search: 'PRNBRILL',
      name: "Prunus nipponica 'Brillant'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 2.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1JG1SER;DGWBYPVB;P5S8SAE7;14W5BZA9;5B8VEVS3;J2ACAACP;9VK1JN48',
      statusContent: 'dot',
      hash: 'SL2J7JAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SL2J7JAH'
    },
    {
      status: 210,
      sku: 'PM0082654',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354802',
      rtl_batch_array: '6354802',
      name_search: 'SYMCANDY',
      name: 'Symphoricarpos Magical Candy',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LJXNBSV1',
      statusContent: 'dot',
      hash: '99N77X9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99N77X9V'
    },
    {
      status: 210,
      sku: 'PM0082655',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354803',
      rtl_batch_array: '6354803',
      name_search: 'TRASSUNS',
      name: 'Trachelospermum asiaticum Summer Sunset',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_prcp: 3.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6ZPYG2B;YRP6TVNA;DDV9WTW4;C4BX3TLL;K11VRW6Z',
      statusContent: 'dot',
      hash: '87WRB5P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87WRB5P4'
    },
    {
      status: 210,
      sku: 'PM0082656',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354804',
      rtl_batch_array: '6354804',
      name_search: 'TRJPSHOW',
      name: "Trachelospermum jasminoides 'Pink Showers'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 634,
      chnn_stock_retail: 634,
      sppl_prcp: 3.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RBEE8L1P;CRB7RTYN;YCCDD1CK;9G9NXAEH',
      statusContent: 'dot',
      hash: 'SAHSJWT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAHSJWT1'
    },
    {
      status: 210,
      sku: 'PM0082657',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354805',
      rtl_batch_array: '6354805',
      name_search: 'VIOCOPPE',
      name: 'Viburnum odoratissimum Coppertop',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_prcp: 4.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6KS4EKJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KS4EKJ2'
    },
    {
      status: 210,
      sku: 'PM0075308',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274192',
      rtl_batch_array: '6274192',
      name_search: 'HIJJAM',
      name: "Hibiscus 'Jazzberry Jam'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1TZCEZ76;B7YELTGD;WKCSWRV3',
      statusContent: 'dot',
      hash: 'NDAH3XCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDAH3XCW'
    },
    {
      status: 210,
      sku: 'PM0075309',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274193',
      rtl_batch_array: '6274193',
      name_search: 'HIOYELLA',
      name: "Hibiscus 'Old Yella'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VKKC7VRV;GVCV86W3;1A5DY1CY;JDNSKLGD;44RA3NHS;HX33ALWC',
      statusContent: 'dot',
      hash: '7S2YJT92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7S2YJT92'
    },
    {
      status: 210,
      sku: 'PM0074983',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276339',
      rtl_batch_array: '6276339, 6358842',
      name_search: 'PRLHERBE',
      name: "Prunus laurocerasus 'Herbergii'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 352,
      sppl_prcp: 8.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATVC4SG;YJV5HPCE;BKT6P4E4;WVLL31CB;SEN1VWWZ',
      statusContent: 'dot',
      hash: 'RPDC67VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPDC67VW'
    },
    {
      status: 210,
      sku: 'PM0075302',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274185',
      rtl_batch_array: '6274185',
      name_search: 'HIRSFANT',
      name: "Hibiscus rosa-sinensis 'Fantasia'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GW2KN47V;DX8ZBGW4;CR6V17ZL;91XABAJ9;BX45HPYD;PGJG8H2B;79D6XYR5;4V1XLH4P;BL19RVWJ',
      statusContent: 'dot',
      hash: '7ZDYKG6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZDYKG6R'
    },
    {
      status: 810,
      sku: 'PM0074985',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276341',
      rtl_batch_array: '6276341, 6287541',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 719,
      sppl_prcp: 10.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: '527ETLDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '527ETLDP'
    },
    {
      status: 910,
      sku: 'PM0072518',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DAMILLUS',
      name: "Dahlia 'Mystic Illusion'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: 'RT427W1R;5WSXGTHC',
      statusContent: 'dot',
      hash: 'DNDP9YVG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DNDP9YVG'
    },
    {
      status: 210,
      sku: 'PM0074987',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276346',
      rtl_batch_array: '6276346, 6358844',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 622,
      sppl_prcp: 8.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '92DK3YHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92DK3YHW'
    },
    {
      status: 210,
      sku: 'PM0057954',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252391',
      rtl_batch_array: '6284214, 6252391',
      name_search: 'PHPFLAME',
      name: 'Phlox (P) Purple Flame',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5247,
      chnn_stock_retail: 6205,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '24JEW7A7;SGNK1H36;2N69BHRK',
      statusContent: 'dot',
      hash: 'JR74B76P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JR74B76P'
    },
    {
      status: 210,
      sku: 'PM0075301',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274184',
      rtl_batch_array: '6274184',
      name_search: 'ANHWHIRL',
      name: "Anemone hybrida 'Whirlwind'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.665,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXZXW2TW;TVZDYJ9Z;KW8683ST;64ZCB9YS;5C4Z92WR;KDYXL3CC;2NZZ6A41;786VAPSK;ZJ26YGB3;J24R6NC3',
      statusContent: 'dot',
      hash: 'V2A9L3Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2A9L3Y8'
    },
    {
      status: 210,
      sku: 'PM0015802',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353314',
      rtl_batch_array: '6353314',
      name_search: 'SERANGEL',
      name: "Sedum reflexum 'Angelina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 788,
      chnn_stock_retail: 788,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6YPGLXLV;9XVG68RW;KYN17RYD;4CHCPY6K;Y9P5ZZ7A;4VTRWXZP;6VW219VV;CPHDWZ7R;6EP3CTY5;PA3AVA2Y;S2SNZS1G;S478CPT3;2VX8Z3ZY;E6EPEYZR;S6ZYSLLW;V5PZNWK5;6TWB4JHH;2NYT7DYG;4GDZCEAK;7SSDKRCB;AZRBW32D;49PZ78D7;JZH4XNX9;LHW1RXKD;KP8BAEW6;2311LW62;H9TYJGNN;SGYCAHVN',
      statusContent: 'dot',
      hash: 'WR2A9AH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WR2A9AH4'
    },
    {
      status: 210,
      sku: 'PM0075304',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274187',
      rtl_batch_array: '6274187',
      name_search: 'ANHPRAEC',
      name: "Anemone hupehensis 'Praecox'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.665,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEE7KAS1;BKE1D8SL;KKPSJ4WJ;SW3Z88N7;B5K4HPE7;K6DZ8WA5;RDY875H6',
      statusContent: 'dot',
      hash: 'KBEY5SP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBEY5SP4'
    },
    {
      status: 210,
      sku: 'PM0074988',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276347',
      rtl_batch_array: '6276347',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_prcp: 7.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'PW9EY366',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW9EY366'
    },
    {
      status: 210,
      sku: 'PM0075288',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280185',
      rtl_batch_array: '6280185',
      name_search: 'JUCSUECI',
      name: "Juniperus communis 'Suecica'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1061,
      chnn_stock_retail: 1061,
      sppl_prcp: 3.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YEPWH4WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEPWH4WR'
    },
    {
      status: 210,
      sku: 'PM0074938',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275035',
      rtl_batch_array: '6275035',
      name_search: 'CEDFBLUE',
      name: "Cedrus deodara 'Feelin' Blue'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 252,
      chnn_stock_retail: 252,
      sppl_prcp: 6.464,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRP5DSG3;S12VJSSR',
      statusContent: 'dot',
      hash: 'X5TEGJHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5TEGJHK'
    },
    {
      status: 210,
      sku: 'PM0074990',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276349',
      rtl_batch_array: '6276349',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'A1YG6PW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1YG6PW1'
    },
    {
      status: 210,
      sku: 'PM0074991',
      core_name: 'Plant',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276350',
      rtl_batch_array: '6276350',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '070080C74',
      rng_range_identifier: 'H070080C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 14.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'LK7SS2H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LK7SS2H5'
    },
    {
      status: 910,
      sku: 'PM0075293',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGRANDI',
      name: 'Magnolia grandiflora',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      imageCore: 'Z4XRBZGS;BA23HE4L;CXGNE86A;KKSYE57D;22C5EC6K;8THTXCJR',
      statusContent: 'dot',
      hash: '25WHYWHD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '25WHYWHD'
    },
    {
      status: 210,
      sku: 'PM0075254',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278470',
      rtl_batch_array: '6278470',
      name_search: 'SAOPTANN',
      name: "Sanguisorba officinalis 'Pink Tanna'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3635,
      chnn_stock_retail: 3635,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GXBKSD91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXBKSD91'
    },
    {
      status: 210,
      sku: 'PM0075257',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278475',
      rtl_batch_array: '6278475',
      name_search: 'VECMAMMU',
      name: "Vernonia crinita 'Mammuth'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1358,
      chnn_stock_retail: 1358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1121J4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1121J4L'
    },
    {
      status: 210,
      sku: 'PM0075262',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275358',
      rtl_batch_array: '6275358',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.303,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'AYRB9X6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYRB9X6V'
    },
    {
      status: 210,
      sku: 'PM0075256',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278473',
      rtl_batch_array: '6278473',
      name_search: 'STLAEVIS',
      name: 'Stokesia laevis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3018,
      chnn_stock_retail: 3018,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A3TWG42L;WT95NRW6;NBLNY19L',
      statusContent: 'dot',
      hash: '6AZRSDKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6AZRSDKN'
    },
    {
      status: 210,
      sku: 'PM0046134',
      core_name: 'Plant',
      sppl_ean: '8720664695511',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015386',
      rtl_batch_array: '6015386',
      name_search: 'JOSGGLOB',
      name: "Jovibarba sobolifera 'Green Globe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZ6Z8K9Y',
      statusContent: 'dot',
      hash: '6ZEH6CNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZEH6CNB'
    },
    {
      status: 210,
      sku: 'PM0075266',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279696',
      rtl_batch_array: '6279696',
      name_search: 'LIOREGEL',
      name: 'Ligustrum obtusifolium regelianum',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8W2D1K3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8W2D1K3'
    },
    {
      status: 210,
      sku: 'PM0080376',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337730',
      rtl_batch_array: '6337730',
      name_search: 'ABEGOUCH',
      name: "Abelia 'Edward Goucher'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3168,
      chnn_stock_retail: 3168,
      sppl_order_minimum: 3,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKYDBZ5C;8WNRHDS9;63ABV37E;4C4KSXVN;44S1KAEP',
      statusContent: 'dot',
      hash: 'N2R7LDW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2R7LDW7'
    },
    {
      status: 210,
      sku: 'PM0080372',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337723',
      rtl_batch_array: '6337723',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1346,
      chnn_stock_retail: 1346,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'DH67ZDJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH67ZDJ8'
    },
    {
      status: 210,
      sku: 'PM0075268',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279741',
      rtl_batch_array: '6279741',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'CCDGN5E6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCDGN5E6'
    },
    {
      status: 210,
      sku: 'PM0075269',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279742',
      rtl_batch_array: '6279742',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HVNEDR3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVNEDR3P'
    },
    {
      status: 210,
      sku: 'PM0075270',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279840',
      rtl_batch_array: '6279840',
      name_search: 'DILDILON',
      name: "Diervilla lonicera 'Dilon'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1636,
      chnn_stock_retail: 1636,
      sppl_order_minimum: 3,
      sppl_prcp: 0.944,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5XCSAW9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XCSAW9H'
    },
    {
      status: 210,
      sku: 'PM0075271',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6279843',
      rtl_batch_array: '6279843',
      name_search: 'DISPLEND',
      name: 'Diervilla splendens',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1289,
      chnn_stock_retail: 1289,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8THCPSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8THCPSY'
    },
    {
      status: 210,
      sku: 'PM0075272',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6279865',
      rtl_batch_array: '6279865, 6304838',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1185,
      chnn_stock_retail: 2094,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: '9T7DJGDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T7DJGDC'
    },
    {
      status: 910,
      sku: 'PM0075273',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CELGLAUC',
      name: "Cedrus libani 'Glauca'",
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      imageCore: 'ZDADY4LV;RTJER481;RCHSL6SS',
      statusContent: 'dot',
      hash: 'XVSBX478',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XVSBX478'
    },
    {
      status: 910,
      sku: 'PM0075274',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CELGPEND',
      name: "Cedrus libani 'Glauca Pendula'",
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      imageCore:
        'WJRKNXK6;5Z8A5PWL;ZCRG7RPD;BNYES7LH;G8799T9A;4AXRH4V9;SDCV5ZT1;69Z5YZ8W;TSZTD2JE',
      statusContent: 'dot',
      hash: '5XSW6BPJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5XSW6BPJ'
    },
    {
      status: 210,
      sku: 'PM0075275',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280167',
      rtl_batch_array: '6280167',
      name_search: 'CHLSUNKI',
      name: "Chamaecyparis lawsoniana 'Sunkist'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1191,
      chnn_stock_retail: 1191,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '74HKX81L;TC8D3PRS',
      statusContent: 'dot',
      hash: '698XDZAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '698XDZAZ'
    },
    {
      status: 210,
      sku: 'PM0075276',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280168',
      rtl_batch_array: '6280168',
      name_search: 'CHOFGOLD',
      name: "Chamaecyparis obtusa 'Fernspray Gold'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1485,
      chnn_stock_retail: 1485,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6WCA2SCG;B7TXZD4S;TVEATYVD;6VVPR8Y9;ADPYNGDW;RY8T4ZAR;R17CXX9A;X9CNND5B;JD2RGANZ;EHJNKZGW;YG746ZZE;K9TAXKE9;J535LKR9;75585JD1;RCT1DY6Z;WJD1A17K',
      statusContent: 'dot',
      hash: 'N9HP1ZD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9HP1ZD9'
    },
    {
      status: 210,
      sku: 'PM0075277',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280169',
      rtl_batch_array: '6280169',
      name_search: 'CHOSSPRA',
      name: "Chamaecyparis obtusa 'Saffron Spray'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1149,
      chnn_stock_retail: 1149,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '92XWLPZK;952BCXSN',
      statusContent: 'dot',
      hash: 'WC7AKB6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC7AKB6V'
    },
    {
      status: 210,
      sku: 'PM0075278',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280170',
      rtl_batch_array: '6280170',
      name_search: 'PIOMORIK',
      name: 'Picea omorika',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1953,
      chnn_stock_retail: 1953,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JTZ1YHLR;6E88GZGS;VHEP6CJT;C4YRA6N2;XE7N32SW',
      statusContent: 'dot',
      hash: 'S5K1V7ZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5K1V7ZZ'
    },
    {
      status: 210,
      sku: 'PM0075279',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280172',
      rtl_batch_array: '6280172',
      name_search: 'TABFROBU',
      name: "Taxus baccata 'Fastigiata Robusta'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 706,
      chnn_stock_retail: 706,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJRLWDE4;5WYTHSPR;5EJXXPAJ;7JH3XKZC;7K755T8W',
      statusContent: 'dot',
      hash: 'ZRWX5YY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRWX5YY1'
    },
    {
      status: 210,
      sku: 'PM0075280',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280175',
      rtl_batch_array: '6280175',
      name_search: 'THOANNIE',
      name: "Thuja occidentalis 'Anniek'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 4.506,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SHRGL9G6;22PDJEJ7',
      statusContent: 'dot',
      hash: 'KZYLT37H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZYLT37H'
    },
    {
      status: 210,
      sku: 'PM0075281',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280176',
      rtl_batch_array: '6280176',
      name_search: 'THOBRABA',
      name: "Thuja occidentalis 'Brabant'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 2263,
      chnn_stock_retail: 2263,
      sppl_prcp: 8.391,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYBK6T5E;1NV8CSRK;A4WXEDHR;78EN2HJ3;474K8A7H;K27Z12TA;7WR3TB6T;X2HDA1G1;S64CTV14;86ADHDGW;WPPCBJVR;SWK1DYJL',
      statusContent: 'dot',
      hash: 'HC5HLJ47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HC5HLJ47'
    },
    {
      status: 210,
      sku: 'PM0075282',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280178',
      rtl_batch_array: '6280178',
      name_search: 'THOKOBOL',
      name: "Thuja occidentalis 'Kobold'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 642,
      chnn_stock_retail: 642,
      sppl_prcp: 3.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2613A5LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2613A5LK'
    },
    {
      status: 210,
      sku: 'PM0080373',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337725',
      rtl_batch_array: '6337725',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2121,
      chnn_stock_retail: 2121,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: 'DPNJYE1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPNJYE1Y'
    },
    {
      status: 210,
      sku: 'PM0075283',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280179',
      rtl_batch_array: '6280179',
      name_search: 'THOTEDDY',
      name: "Thuja occidentalis 'Teddy'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1029,
      chnn_stock_retail: 1029,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEXVV2X1;8Y3N8K36;HZXTY6L5;LJZHXYWX;ATY1ATV7;E5VLN7X8',
      statusContent: 'dot',
      hash: 'DNNBC5A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DNNBC5A4'
    },
    {
      status: 210,
      sku: 'PM0075284',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280181',
      rtl_batch_array: '6280181',
      name_search: 'TSCJEDDE',
      name: "Tsuga canadensis 'Jeddeloh'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_prcp: 7.276,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99B8JVWB;9K4Z69BJ;523X2GZ7;PA6ZXZCA;5WV7PV5N;T1H2VVWZ;CCVRV972;G45214ST;Y8VBN2E7;BL2JXSAG',
      statusContent: 'dot',
      hash: 'DG7853RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG7853RT'
    },
    {
      status: 210,
      sku: 'PM0075285',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280182',
      rtl_batch_array: '6280182',
      name_search: 'CHPFAURE',
      name: "Chamaecyparis pisifera 'Filifera Aurea'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3028,
      chnn_stock_retail: 3028,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D938274G;Z3N1PSP3;E4AL6A8J;KBND7J25;L73KP9W2;4D8DGTWR;41L5NBZT;115YCL9T;VW2C9E4C;C5XP9DDY;KB9YSA1W;7P37JNDH',
      statusContent: 'dot',
      hash: '28TN2HLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28TN2HLK'
    },
    {
      status: 210,
      sku: 'PM0075286',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280183',
      rtl_batch_array: '6280183',
      name_search: 'CHPSQUAR',
      name: "Chamaecyparis pisifera 'Squarrosa'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXZ8YJWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXZ8YJWH'
    },
    {
      status: 210,
      sku: 'PM0075287',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280184',
      rtl_batch_array: '6280184',
      name_search: 'JUCBALPS',
      name: "Juniperus chinensis 'Blue Alps'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H822KC9;WZD3AJ7H;DBAC7VR1;YED299HD;6TR8KJEW;YCP4NESG;WH27KK7Z;GKK13P73;Y2J5YSEX;YB3HA8X7;5HN5TXHC;9K9Z11JB;JVG243P3;H4E4VNX3;D25GXCTE;ZV74J6G9;PNK11B3H',
      statusContent: 'dot',
      hash: '8NPJZCTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NPJZCTA'
    },
    {
      status: 210,
      sku: 'PM0075289',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280186',
      rtl_batch_array: '6280186',
      name_search: 'JUSMOONG',
      name: "Juniperus scopulorum 'Moonglow'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1339,
      chnn_stock_retail: 1339,
      sppl_prcp: 3.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5PDXG7W',
      statusContent: 'dot',
      hash: 'R9YLYTNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9YLYTNB'
    },
    {
      status: 210,
      sku: 'PM0080374',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337728',
      rtl_batch_array: '6337728',
      name_search: 'BUSANNIV',
      name: 'Buddleja Silver Anniversery',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 258,
      chnn_stock_retail: 258,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KY4BN4KD;E6PR9R5D;Y52G45ZA;KRBRGA52',
      statusContent: 'dot',
      hash: 'ENB7LK61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENB7LK61'
    },
    {
      status: 210,
      sku: 'PM0080375',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337729',
      rtl_batch_array: '6337729',
      name_search: 'ABCHINEN',
      name: 'Abelia chinensis',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6GWVV1TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GWVV1TB'
    },
    {
      status: 210,
      sku: 'PM0080377',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337731',
      rtl_batch_array: '6337731',
      name_search: 'ABGPGARD',
      name: 'Abelia grandiflora Petite Garden',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4TBA1DS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TBA1DS8'
    },
    {
      status: 210,
      sku: 'PM0080378',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337732',
      rtl_batch_array: '6337732',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1310,
      chnn_stock_retail: 1310,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'XBP7HVTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBP7HVTJ'
    },
    {
      status: 210,
      sku: 'PM0070215',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6220765',
      rtl_batch_array: '6220765',
      name_search: 'GALGLPIN',
      name: 'Gaura lindheimeri Gaudi Light Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 876,
      chnn_stock_retail: 876,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YJHTZ1A;ZGZW1C9T;KHE3Y8X2;R3HABG47;TX7AK84W;241E6DBN;5GXWEP12;P3YRWEYS',
      statusContent: 'dot',
      hash: 'W8A75NSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8A75NSE'
    },
    {
      status: 210,
      sku: 'PM0080379',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337733',
      rtl_batch_array: '6337733',
      name_search: 'ABGCONFE',
      name: 'Abelia grandiflora Confetti',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1030,
      chnn_stock_retail: 1030,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HNXD9PNH;X65GTL1P;85K8DDLL;X21D296A;CVKRL4EK',
      statusContent: 'dot',
      hash: '7KCBTS34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KCBTS34'
    },
    {
      status: 210,
      sku: 'PM0074792',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274864',
      rtl_batch_array: '6274864',
      name_search: 'RUSHEILE',
      name: "Rudbeckia subtomentosa 'Henry Eilers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T19DV2BZ;7CYJ63ZC;KJA2GBCJ;YKCLG88A;5RXBYW9X;H9RCS95G;WV15YDJ9;7JE13DLV',
      statusContent: 'dot',
      hash: 'HWJ5EZ4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWJ5EZ4B'
    },
    {
      status: 210,
      sku: 'PM0074793',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353327',
      rtl_batch_array: '6274865, 6353327',
      name_search: 'SANLUBEC',
      name: "Salvia nemorosa 'Lubeca'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 852,
      chnn_stock_retail: 904,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKBSY27L;N98H7PZ3',
      statusContent: 'dot',
      hash: 'W632R8W3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W632R8W3'
    },
    {
      status: 210,
      sku: 'PM0080380',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337734',
      rtl_batch_array: '6337734',
      name_search: 'ABGFMASO',
      name: "Abelia grandiflora 'Francis Mason'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 3,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JNVB76SS;V4BC2T9D;X8KYN839;HZ31P18E',
      statusContent: 'dot',
      hash: 'JLBDC5LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLBDC5LE'
    },
    {
      status: 210,
      sku: 'PM0066907',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6369738',
      rtl_batch_array: '6369738, 6268774, 6333256, 6320881',
      name_search: 'HECITRON',
      name: "Heuchera 'Citronelle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 510,
      chnn_stock_retail: 3514,
      sppl_order_minimum: 3,
      sppl_prcp: 1.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZLEDAK5;W4E1DS1D;7HDR65D7;9SLCT9S7',
      statusContent: 'dot',
      hash: 'NSBVNVLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSBVNVLK'
    },
    {
      status: 210,
      sku: 'PM0080381',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337735',
      rtl_batch_array: '6337735',
      name_search: 'ABGHOPLE',
      name: "Abelia grandiflora 'Hopleys'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PWSL6ZH8;8YP53T41',
      statusContent: 'dot',
      hash: 'LT5TNSLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT5TNSLG'
    },
    {
      status: 210,
      sku: 'PM0073290',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353303',
      rtl_batch_array: '6353303',
      name_search: 'SELZINGE',
      name: "Sedum 'Lime Zinger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 426,
      chnn_stock_retail: 426,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K4AZEBLP;SLADA4A2;Y41CP1N6;Y5CB7RBB;LYP99NL3;H1568Z6K;JPW955X6;X695457A;8ZY1PN13;SXPT85VH;HVW51H8P',
      statusContent: 'dot',
      hash: 'NRDJ7ZLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRDJ7ZLX'
    },
    {
      status: 210,
      sku: 'PM0080382',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337736',
      rtl_batch_array: '6337736',
      name_search: 'ABGKALEI',
      name: "Abelia grandiflora 'Kaleidoscope'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 3853,
      chnn_stock_retail: 3853,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TVC8SJ7B;71K9HKKB',
      statusContent: 'dot',
      hash: '6RKHD4H2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RKHD4H2'
    },
    {
      status: 210,
      sku: 'PM0075253',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278469',
      rtl_batch_array: '6278469',
      name_search: 'ANHKCHAR',
      name: "Anemone hybrida 'K\u00f6nigin Charlotte'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 11348,
      chnn_stock_retail: 11348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CN2NWZE3;TK6REXC5;13HS42A7;KRCG6G6P;8JNNAC86;H8J5G6AG;L7XWCREE;J3P8J796;RG8BPZST;HKXY5RWR;CGT44YW7',
      statusContent: 'dot',
      hash: 'YZKW6ES9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZKW6ES9'
    },
    {
      status: 210,
      sku: 'PM0075255',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278471',
      rtl_batch_array: '6278471',
      name_search: 'SAOTANNA',
      name: "Sanguisorba officinalis 'Tanna'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3213,
      chnn_stock_retail: 3213,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ZATRR51;TPD6XV5R;C6Z9JZX4;N48G5LEB',
      statusContent: 'dot',
      hash: '1HS7LJ8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HS7LJ8C'
    },
    {
      status: 210,
      sku: 'PM0075298',
      core_name: 'Plant',
      sppl_ean: '8720626366763',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207557',
      rtl_batch_array: '5207557',
      name_search: 'RHMMENAR',
      name: "Rhododendron 'Marcel Menard'",
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 5.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPGZGRBE;A5RD7AY5;AW6TELE5;XN8KPRZH;VWSBWTPK;B2J83YR2;KKYTK8V1;VVVGR5LJ',
      statusContent: 'dot',
      hash: 'WGG3KPLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGG3KPLS'
    },
    {
      status: 210,
      sku: 'PM0075299',
      core_name: 'Plant',
      sppl_ean: '8720626322219',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5386294',
      rtl_batch_array: '5386294',
      name_search: 'RHPROSEU',
      name: "Rhododendron ponticum 'Roseum'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '881J84B7',
      statusContent: 'dot',
      hash: 'SE119JY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SE119JY3'
    },
    {
      status: 210,
      sku: 'PM0075300',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274183',
      rtl_batch_array: '6274183',
      name_search: 'HIKKING',
      name: "Hibiscus 'Kopper King'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DGEAZXS2;VXCVSECY;ZAHZKNYY;N1P2RGAP;WLP3ZZ6Z',
      statusContent: 'dot',
      hash: '54217XX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54217XX8'
    },
    {
      status: 210,
      sku: 'PM0080383',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337737',
      rtl_batch_array: '6337737',
      name_search: 'ABGRADIA',
      name: "Abelia grandiflora 'Radiance'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 710,
      chnn_stock_retail: 710,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '29NA9V19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '29NA9V19'
    },
    {
      status: 210,
      sku: 'PM0075305',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 7,
      btch_active_retail: '6274188',
      rtl_batch_array:
        '6274188, 6368802, 6368803, 6368828, 6368792, 6368797, 6368799',
      name_search: 'ALRCDOUB',
      name: 'Alcea rosea Chaters Double Hybrids',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 7000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9CRGKNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9CRGKNW'
    },
    {
      status: 210,
      sku: 'PM0075306',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274189',
      rtl_batch_array: '6274189',
      name_search: 'HIRFLEMI',
      name: "Hibiscus 'Robert Fleming'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5NASYAN',
      statusContent: 'dot',
      hash: 'KCG8D36J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCG8D36J'
    },
    {
      status: 910,
      sku: 'PM0075307',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: 'ZG8B785T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZG8B785T'
    },
    {
      status: 910,
      sku: 'PM0075310',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLFSPRIN',
      name: "Clematis 'Fragrant Spring'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'XV5Z9LLB;YZ768L7E;NX2DDYK9',
      statusContent: 'dot',
      hash: 'ZRXHLC17',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZRXHLC17'
    },
    {
      status: 210,
      sku: 'PM0075311',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274195',
      rtl_batch_array: '6274195',
      name_search: 'HIMCGRED',
      name: 'Hibiscus moscheutos Carousel Geant Red',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S6XSLA32;RRLG59C6',
      statusContent: 'dot',
      hash: 'WNBXDP68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNBXDP68'
    },
    {
      status: 210,
      sku: 'PM0075312',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274196',
      rtl_batch_array: '6274196',
      name_search: 'HICCRUSH',
      name: "Hibiscus 'Cranberry Crush'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPH9TSZ5;SZHP37SJ;BW4GW98C',
      statusContent: 'dot',
      hash: 'R3B8B8L9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3B8B8L9'
    },
    {
      status: 210,
      sku: 'PM0009444',
      core_name: 'Plant',
      sppl_ean: '8720664881655',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6158278',
      rtl_batch_array: '5495474, 6158278, 6303631',
      name_search: 'PRVIALII',
      name: 'Primula vialii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 29472,
      chnn_stock_retail: 30928,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V9TLHN7H;S5PXG4TS;RYZBG3BB;ZDG2926K;7173J2EX;L6TVYECG;1C8X8K2N;45ZARZ8W;STSHJPLE;XR74C53K;6NW923XL;TXWA6Z19;JH193TN2',
      statusContent: 'dot',
      hash: '1THDX2DH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1THDX2DH'
    },
    {
      status: 810,
      sku: 'PM0027957',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308448',
      rtl_batch_array: '6147876, 6126416, 6308448, 6340043, 6363198',
      name_search: 'SAMHLIPS',
      name: "Salvia microphylla 'Hot Lips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1Z23AJEH;AD1PCVB1;BAKA6Y3N;132W86X5;12KNZZ51;YSZKHDCR;DL23CSAX;NZ2W7SZZ;JVRJY9G3;9TVVH5DN;NEVVDG32;R2VCAAVS;6EVREY43;S5ZLPZZJ;J195DWNG;HP554TCE;2E5VSSZH;L3LDB5YX;HKTN4BZB;YTBDHANV;J8SCBA95;5J5DJWW9;TBHTCSX4;TGK6PH6S;JZS8B232;C7ACSS26;DP52WTHW;E19CE7PZ;KW5K1PV4;8ZR9VXZ6',
      statusContent: 'dot',
      hash: '1YS51B1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YS51B1L'
    },
    {
      status: 210,
      sku: 'PM0014481',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6291342',
      rtl_batch_array: '6112451, 6291342, 6309002',
      name_search: 'HOHALCYO',
      name: "Hosta 'Halcyon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5669,
      chnn_stock_retail: 14902,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XVLHLHDA;SXSLC6RG;RV1ADEB8;1LTDRAW2;1ZR7Z8T2;XBCNDDBG;A41WTTYV;GA5DASGA;E71S77ZN;SYP571EK;99ZGX7YP;V3LH4VED;BVELEDH5;2WWRK844;HCDT5288;A9Y6ASJ4',
      statusContent: 'dot',
      hash: '24CRB7KW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24CRB7KW'
    },
    {
      status: 210,
      sku: 'PM0007821',
      core_name: 'Plant',
      sppl_ean: '8720664878327',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084651',
      rtl_batch_array: '6084651, 6320247',
      name_search: 'PHMCUSHI',
      name: "Phlox (S) 'MacDaniel's Cushion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 968,
      chnn_stock_retail: 2093,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPSN9TKG;WJSE1K7H',
      statusContent: 'dot',
      hash: '2B14AYB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B14AYB9'
    },
    {
      status: 210,
      sku: 'PM0018565',
      core_name: 'Plant',
      sppl_ean: '8720664857889',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084862',
      rtl_batch_array: '6084862, 6369230',
      name_search: 'COGPREST',
      name: "Coreopsis grandiflora 'Presto'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15079,
      chnn_stock_retail: 15349,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR5GYRT2;NWL5D5X9;61XYHBCZ;5AS3JWLW;SSCLYVAY',
      statusContent: 'dot',
      hash: '5BCTE2AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BCTE2AK'
    },
    {
      status: 210,
      sku: 'PM0027127',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6221386',
      rtl_batch_array: '6221386',
      name_search: 'HESPLUM',
      name: "Heuchera 'Sugar Plum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NA46S1X3',
      statusContent: 'dot',
      hash: '86H1VVWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86H1VVWN'
    },
    {
      status: 210,
      sku: 'PM0001381',
      core_name: 'Plant',
      sppl_ean: '8720349416639',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '5443485',
      rtl_batch_array: '5364115, 6291693, 6054637, 5443485',
      name_search: 'GESSTRIA',
      name: 'Geranium sanguineum striatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 7975,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TLP4T4EE;VBRH6X1W;Y2BBW4CV',
      statusContent: 'dot',
      hash: '8ZJ2SHAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZJ2SHAG'
    },
    {
      status: 210,
      sku: 'PM0006606',
      core_name: 'Plant',
      sppl_ean: '8720349468058',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5170947',
      rtl_batch_array: '5170947, 6363571',
      name_search: 'SELIPARI',
      name: "Sempervivum 'Lipari'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 1056,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2555SL25;7K17GPJX;J742DXHR;456Y9XRL;7ADDKNBV;YJPGV6AG;WKWC6B5L',
      statusContent: 'dot',
      hash: '9NBHK8Y9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NBHK8Y9'
    },
    {
      status: 210,
      sku: 'PM0008456',
      core_name: 'Plant',
      sppl_ean: '8720626331730',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364234',
      rtl_batch_array: '5364234',
      name_search: 'VICOLUMB',
      name: "Viola (C) 'Columbine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 327,
      chnn_stock_retail: 327,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VA7PCZ3W;146L3LS9;6TNRE5H8;VKC7B5Y7',
      statusContent: 'dot',
      hash: '9V3HGZV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V3HGZV1'
    },
    {
      status: 210,
      sku: 'PM0027381',
      core_name: 'Plant',
      sppl_ean: '8720353020747',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5332865',
      rtl_batch_array: '5332865, 6091533',
      name_search: 'LAGFLORE',
      name: "Lamiastrum galeobdolon 'Florentinum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1726,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6YK5ZDLZ;YP1GPHW4;Y1GYVBSX;2CA53WYW;P47SRA8D;LY7Z97G7;A1LBT9EN;LP7R4X5G;HBB1DT8K;HAX6AHCY',
      statusContent: 'dot',
      hash: '9XKH3P3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XKH3P3V'
    },
    {
      status: 210,
      sku: 'PM0018770',
      core_name: 'Plant',
      sppl_ean: '8720353011738',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5303738',
      rtl_batch_array: '5303738',
      name_search: 'STOROSEA',
      name: "Stachys officinalis 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V4YDTJT1',
      statusContent: 'dot',
      hash: 'AW1V9THT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AW1V9THT'
    },
    {
      status: 210,
      sku: 'PM0005652',
      core_name: 'Plant',
      sppl_ean: '8720664882942',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6164418',
      rtl_batch_array: '6215628, 6164418, 6353254',
      name_search: 'PUVALBA',
      name: "Pulsatilla vulgaris 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 213,
      chnn_stock_retail: 2115,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LDJJELAY;7CCXRE49;67ARERE8;9LGLAAAD;ZW2VHRNR',
      statusContent: 'dot',
      hash: 'AZWTGTAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZWTGTAJ'
    },
    {
      status: 210,
      sku: 'PM0003192',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301870',
      rtl_batch_array: '6301870, 5741562',
      name_search: 'TESPECIO',
      name: 'Telekia speciosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 699,
      chnn_stock_retail: 1128,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WTG8P3G',
      statusContent: 'dot',
      hash: 'BG4ZDYRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BG4ZDYRS'
    },
    {
      status: 810,
      sku: 'PM0080384',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337738',
      rtl_batch_array: '6337738',
      name_search: 'ABRPROFU',
      name: "Abelia 'Raspberry Profusion'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HNLWDB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HNLWDB4'
    },
    {
      status: 210,
      sku: 'PM0019572',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6113109',
      rtl_batch_array: '6242966, 6113109, 6350390, 5495374, 6333255',
      name_search: 'HECRUFFL',
      name: "Heuchera 'Chocolate Ruffles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 4220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.487,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JP4WBKTG;TNAJVVS9;5ZKAEW1S',
      statusContent: 'dot',
      hash: 'EKY4YT2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKY4YT2X'
    },
    {
      status: 210,
      sku: 'PM0063373',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278474',
      rtl_batch_array: '6278474',
      name_search: 'VEOLYMPI',
      name: 'Verbascum olympicum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1937,
      chnn_stock_retail: 1937,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RHB2D93P;5AEKWD4V',
      statusContent: 'dot',
      hash: 'G298VTN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G298VTN1'
    },
    {
      status: 210,
      sku: 'PM0008722',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 9,
      btch_active_retail: '6315295',
      rtl_batch_array:
        '6349144, 6064298, 6315295, 5487716, 6301941, 6164421, 6353323, 6385483, 6295835',
      name_search: 'SANCARAD',
      name: "Salvia nemorosa 'Caradonna'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1660,
      chnn_stock_retail: 43235,
      sppl_order_minimum: 3,
      sppl_prcp: 0.605,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4G44ZLTY;CH999Z8V;82N5TCCX;H9PZEKPC',
      statusContent: 'dot',
      hash: 'G4G7H6R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4G7H6R5'
    },
    {
      status: 810,
      sku: 'PM0024487',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319733',
      rtl_batch_array: '6319733',
      name_search: 'PUOSWHIT',
      name: "Pulmonaria officinalis 'Sissinghurst White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NRZZPTY;C5N584YX',
      statusContent: 'dot',
      hash: 'G86S4VLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G86S4VLD'
    },
    {
      status: 210,
      sku: 'PM0024340',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260675',
      rtl_batch_array: '6260675, 6305796',
      name_search: 'ASNBFELL',
      name: "Aster novi-belgii 'Fellowship'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 834,
      chnn_stock_retail: 1262,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H9HA1AHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9HA1AHT'
    },
    {
      status: 210,
      sku: 'PM0020875',
      core_name: 'Plant',
      sppl_ean: '8720626308848',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5350159',
      rtl_batch_array: '6159842, 5350159',
      name_search: 'LAGHPRID',
      name: "Lamiastrum galeobdolon 'Herman's Pride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 823,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1K2HGR92;BC38B1BL;HB4V8D4R;WLRBEXE7;HGS3ZBHZ;ZEBNT9XT;E721WXVA;DVCYAW99;NTW2XLNG;414DHA4Z;46HZVSA2;S2XB4ZLT;9L2SS52G;G7Y1X3RX;BYSJA44Y;HX3B9VP7;HE3CAKTT;T59WPH6V',
      statusContent: 'dot',
      hash: 'JX2Z5SDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JX2Z5SDC'
    },
    {
      status: 210,
      sku: 'PM0045976',
      core_name: 'Plant',
      sppl_ean: '8720664868168',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091430',
      rtl_batch_array: '6112448, 6091430',
      name_search: 'HOFBOUQU',
      name: "Hosta 'Fragrant Bouquet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 422,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E1Z18WX3;WCA2B9R3',
      statusContent: 'dot',
      hash: 'KDZ94NTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDZ94NTC'
    },
    {
      status: 210,
      sku: 'PM0000977',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353104',
      rtl_batch_array: '6353104',
      name_search: 'PHPVARIE',
      name: "Phlox procumbens 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5965A15T;18XY3LRP;PK8KD193;5RNT2V3C',
      statusContent: 'dot',
      hash: 'L1WA6JYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1WA6JYB'
    },
    {
      status: 210,
      sku: 'PM0080385',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337739',
      rtl_batch_array: '6337739',
      name_search: 'ABGSHERW',
      name: "Abelia grandiflora 'Sherwood'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 8118,
      chnn_stock_retail: 8118,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKREPWDG;YZGYVWX7;24YD3GXA;X4YDD432;41LVPCDD',
      statusContent: 'dot',
      hash: '48N5A5S3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48N5A5S3'
    },
    {
      status: 210,
      sku: 'PM0080386',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337740',
      rtl_batch_array: '6337740',
      name_search: 'ABGSCHAR',
      name: 'Abelia grandiflora Sunny Charms',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C3AWER1A;CCPNT9B6',
      statusContent: 'dot',
      hash: '18S9ZYYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18S9ZYYL'
    },
    {
      status: 210,
      sku: 'PM0080387',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337741',
      rtl_batch_array: '6337741',
      name_search: 'ABGSDAYD',
      name: 'Abelia grandiflora Sunshine Daydream',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 735,
      chnn_stock_retail: 735,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ST7RVA4W;LB8XGE8B;VAGL8CYC;6TD6DZN7;JBCJ1A54;RK1EX2WA;D5V21YYH;V2GTWWZC;GDCVS7YC;9GNJDVGT',
      statusContent: 'dot',
      hash: 'YSJZ612R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSJZ612R'
    },
    {
      status: 210,
      sku: 'PM0001661',
      core_name: 'Plant',
      sppl_ean: '8720626304802',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364180',
      rtl_batch_array: '6301653, 5364180',
      name_search: 'HEUSTOPL',
      name: "Heucherella 'Stoplight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 471,
      chnn_stock_retail: 499,
      sppl_order_minimum: 3,
      sppl_prcp: 1.541,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4G688JXR;XAAKKZP4',
      statusContent: 'dot',
      hash: 'WT4T4PVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WT4T4PVB'
    },
    {
      status: 210,
      sku: 'PM0027889',
      core_name: 'Plant',
      sppl_ean: '8720349484768',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364502',
      rtl_batch_array: '5364502, 6193108, 6353289',
      name_search: 'SECARL',
      name: "Sedum 'Carl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 875,
      chnn_stock_retail: 1751,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1AAZNB;6CL18H27;4J9PWWYY;DJTJLNYJ;8G15Z36J;KTA6KJ11',
      statusContent: 'dot',
      hash: 'X8CKDG3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8CKDG3B'
    },
    {
      status: 210,
      sku: 'PM0019752',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6177959',
      rtl_batch_array: '6242959, 6161736, 5364150, 6177959, 6290218',
      name_search: 'HEFHALS',
      name: "Hemerocallis 'Frans Hals'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 6938,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XT1ENN5V;7LX1P1RV;D4G9AGKT;TSJSJT88;WRALV73B;J1NCBABH',
      statusContent: 'dot',
      hash: 'YGELYHZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGELYHZL'
    },
    {
      status: 210,
      sku: 'PM0027387',
      core_name: 'Plant',
      sppl_ean: '8720664696167',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091540',
      rtl_batch_array: '6091540',
      name_search: 'LAMPPEWT',
      name: "Lamium maculatum 'Pink Pewter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JNGGZWE9;C2Z1VDAT;K9SCK9J1',
      statusContent: 'dot',
      hash: 'Z954T95J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z954T95J'
    },
    {
      status: 810,
      sku: 'PM0080388',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337742',
      rtl_batch_array: '6337742',
      name_search: 'ABPBUMBL',
      name: "Abelia parvifolia 'Bumblebee'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EAV2S2TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAV2S2TN'
    },
    {
      status: 210,
      sku: 'PM0080389',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337743',
      rtl_batch_array: '6337743',
      name_search: 'ABGPROST',
      name: "Abelia grandiflora 'Prostrata'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 3934,
      chnn_stock_retail: 3934,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GLP4HNXJ;Y2BPNW59;VB2BPSE2;1JB1ZPEY;WGJ4D934',
      statusContent: 'dot',
      hash: 'NT9V4HRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NT9V4HRY'
    },
    {
      status: 210,
      sku: 'PM0080390',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337744',
      rtl_batch_array: '6337744',
      name_search: 'ABDISTIC',
      name: 'Abeliophyllum distichum',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1182,
      chnn_stock_retail: 1182,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1SP67XV1;NH492HKW;4V9ZKLCD;HY4BBAHK',
      statusContent: 'dot',
      hash: 'KBW72EP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBW72EP1'
    },
    {
      status: 210,
      sku: 'PM0080391',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337745',
      rtl_batch_array: '6337745',
      name_search: 'ELSVARIE',
      name: "Eleutherococcus sieboldianus 'Variegatus'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WDEN9ZTE;RXKCETTV',
      statusContent: 'dot',
      hash: 'X9W1BSL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9W1BSL8'
    },
    {
      status: 210,
      sku: 'PM0075320',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274205',
      rtl_batch_array: '6274205',
      name_search: 'HIFIREBA',
      name: "Hibiscus 'Fireball'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52CZ2DAJ;1Z6H726R;NENYHD34;SDBLYKPS',
      statusContent: 'dot',
      hash: '7DJHZ6PX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DJHZ6PX'
    },
    {
      status: 210,
      sku: 'PM0075313',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274197',
      rtl_batch_array: '6274197',
      name_search: 'HIPCRAZY',
      name: "Hibiscus 'Plum Crazy'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NYR9663;X7GR96PK;Z5JW1PX7;NTXT278E;VN1RHVN5',
      statusContent: 'dot',
      hash: '49Y7CDTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49Y7CDTJ'
    },
    {
      status: 210,
      sku: 'PM0075318',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274202',
      rtl_batch_array: '6274202',
      name_search: 'HIRGEMS',
      name: "Hibiscus 'Royal Gems'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLEE8686;6A85W4BT;63BN9ZXE;T39XSZ87',
      statusContent: 'dot',
      hash: '6E25XGV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6E25XGV1'
    },
    {
      status: 210,
      sku: 'PM0075314',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274198',
      rtl_batch_array: '6274198',
      name_search: 'HIMCPPAS',
      name: 'Hibiscus moscheutos Carousel Pink Passion',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP5V2D92;XLEX7A22',
      statusContent: 'dot',
      hash: '5T1D2XZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T1D2XZZ'
    },
    {
      status: 210,
      sku: 'PM0075315',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274199',
      rtl_batch_array: '6274199',
      name_search: 'HISSSTOR',
      name: 'Hibiscus Summerific Summer Storm',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S6WPA2LX;CY11XSBD;57WV1EAC;V3HSDBR7;C63DT191;76EKDSSL;K2PT9RD6',
      statusContent: 'dot',
      hash: 'W7VNG6ZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7VNG6ZL'
    },
    {
      status: 810,
      sku: 'PM0072523',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251041',
      rtl_batch_array: '6251041',
      name_search: 'VICOLUMB',
      name: "Viola (C) 'Columbine'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VA7PCZ3W;146L3LS9;6TNRE5H8;VKC7B5Y7',
      statusContent: 'dot',
      hash: 'BJ71V67E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJ71V67E'
    },
    {
      status: 210,
      sku: 'PM0075323',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274211',
      rtl_batch_array: '6274211',
      name_search: 'HECWOODB',
      name: "Hemerocallis 'Catherine Woodbury'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.869,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5LX88XD2',
      statusContent: 'dot',
      hash: 'H2TLZ6B2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2TLZ6B2'
    },
    {
      status: 210,
      sku: 'PM0075324',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274214',
      rtl_batch_array: '6274214',
      name_search: 'ANHPHEIN',
      name: "Anemone hupehensis 'Prinz Heinrich'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.665,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P1GVRP8X;EGHZT32P;N1VKLVVP;YE462XS7;KNASX3TE;Y28S5YDE;SYKT6K52;8HT5EW56;4DJ6PXL4;HH9W31LX;T3S61YPK;14RXYEXY;PRDTNTAK;9VKR37SB;A2KXA4TT',
      statusContent: 'dot',
      hash: 'THEGK8V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THEGK8V9'
    },
    {
      status: 210,
      sku: 'PM0024911',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252486',
      rtl_batch_array: '5837400, 6252486',
      name_search: 'BLSPICAN',
      name: 'Blechnum spicant',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 738,
      chnn_stock_retail: 2738,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G18GPEPK;GTK7Y5ZW;BGP9LHPL;A1TBVCV9;TR275HHX;YETY52TE;8DSLP135;2WHPX6KW;ABCP4H6T;SNSYBKCX;K31VDCJK;RH4J4HGD;DJEBD9P2;K2RLTZK5;WW6Z9B9K;K7AH5W3H;9NAS73LC',
      statusContent: 'dot',
      hash: 'V2VCELNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2VCELNT'
    },
    {
      status: 210,
      sku: 'PM0067717',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6186651',
      rtl_batch_array: '6186651',
      name_search: 'MIDECUSS',
      name: 'Microbiota decussata',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 2365,
      chnn_stock_retail: 2365,
      sppl_prcp: 3.736,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PSVSYYP;DWGB7P91;CVZ7AWNJ;TAL4LW1P;XL81N6JH;GHVC75RA',
      statusContent: 'dot',
      hash: 'P33TG3X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P33TG3X2'
    },
    {
      status: 210,
      sku: 'PM0013952',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220382',
      rtl_batch_array: '6220382, 6378427, 6263137',
      name_search: 'BUBCHIP',
      name: "Buddleja 'Blue Chip'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 37097,
      chnn_stock_retail: 51371,
      sppl_prcp: 3.226,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RSJNR3YN',
      statusContent: 'dot',
      hash: 'RNPR96LA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNPR96LA'
    },
    {
      status: 210,
      sku: 'PM0075015',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276617',
      rtl_batch_array: '6276617, 6338968',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 792,
      chnn_stock_retail: 5783,
      sppl_order_minimum: 3,
      sppl_prcp: 1.113,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'WBEGK6PP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBEGK6PP'
    },
    {
      status: 810,
      sku: 'PM0080392',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337746',
      rtl_batch_array: '6337746',
      name_search: 'ACDAVIDI',
      name: 'Acer davidii',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 4.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZLVSXPLB;YSXGA1RK',
      statusContent: 'dot',
      hash: 'DV1KZ6XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DV1KZ6XA'
    },
    {
      status: 210,
      sku: 'PM0080393',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337747',
      rtl_batch_array: '6337747',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 3,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'D171A9KP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D171A9KP'
    },
    {
      status: 210,
      sku: 'PM0080394',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337748',
      rtl_batch_array: '6337748',
      name_search: 'ACNKGOLD',
      name: "Acer negundo 'Kelly's Gold'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ELK9JB3E;PEVJ5BDN',
      statusContent: 'dot',
      hash: 'SP5GZE58',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP5GZE58'
    },
    {
      status: 210,
      sku: 'PM0080395',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337749',
      rtl_batch_array: '6337749',
      name_search: 'ACNVARIE',
      name: "Acer negundo 'Variegatum'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 191,
      chnn_stock_retail: 191,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ND1436A1;NL6NN9SP',
      statusContent: 'dot',
      hash: 'K9WL5CE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9WL5CE6'
    },
    {
      status: 210,
      sku: 'PM0074999',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 2,
      btch_active_retail: '6378417',
      rtl_batch_array: '6378417, 6288474',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 588,
      chnn_stock_retail: 3588,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'RV16SAC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV16SAC1'
    },
    {
      status: 210,
      sku: 'PM0080396',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337750',
      rtl_batch_array: '6337750',
      name_search: 'ACPAUREU',
      name: "Acer palmatum 'Aureum'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CPZ68CG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CPZ68CG'
    },
    {
      status: 210,
      sku: 'PM0082658',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354806',
      rtl_batch_array: '6354806',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_prcp: 2.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: '1STL7WJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1STL7WJN'
    },
    {
      status: 210,
      sku: 'PM0082659',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6354807',
      rtl_batch_array: '6354807',
      name_search: 'VICBLUES',
      name: 'Vitex Chicagoland Blues',
      sppl_size_code: '025040C3',
      rng_range_identifier: 'H025040C3',
      rng_range_description: 'H25 cm 40 cm C3',
      sppl_stock_available: 1063,
      chnn_stock_retail: 1063,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GNEXLLSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNEXLLSZ'
    },
    {
      status: 210,
      sku: 'PM0082660',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355337',
      rtl_batch_array: '6355337',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: 'KDLN156S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDLN156S'
    },
    {
      status: 210,
      sku: 'PM0072520',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251027',
      rtl_batch_array: '6251027',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: 'BSV594EG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSV594EG'
    },
    {
      status: 210,
      sku: 'PM0082661',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355339',
      rtl_batch_array: '6355339',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'Y8DH88R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8DH88R3'
    },
    {
      status: 210,
      sku: 'PM0082662',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355340',
      rtl_batch_array: '6355340',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'BS4LTDSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS4LTDSB'
    },
    {
      status: 210,
      sku: 'PM0082663',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355341',
      rtl_batch_array: '6355341',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: '9RNPCAW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RNPCAW7'
    },
    {
      status: 910,
      sku: 'PM0082664',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'ANZ3ZWAV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ANZ3ZWAV'
    },
    {
      status: 910,
      sku: 'PM0082665',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHSAGIT',
      name: "Hedera helix 'Sagittifolia'",
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      imageCore:
        'PRDA73V8;YE8KGNL2;6N5S41JK;81RJ57E3;JEXR4N6D;1DSK83GX;5Z1JEWY7;H2966RCG;98SSR5TP;E4E23VWG',
      statusContent: 'dot',
      hash: 'A2GZZYBN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A2GZZYBN'
    },
    {
      status: 210,
      sku: 'PM0082666',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355345',
      rtl_batch_array: '6355345',
      name_search: 'PIJAPONI',
      name: 'Pieris japonica',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4PZ6SW3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PZ6SW3B'
    },
    {
      status: 910,
      sku: 'PM0082667',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'BVCS45YL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BVCS45YL'
    },
    {
      status: 910,
      sku: 'PM0075014',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAFBZANG',
      name: "Carex flacca 'Blue Zanger'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'C3771HWT;NT1CW8LK;NXD7RGV2;4VD7EXVC;1NKT5AXJ;XZ14Y5PY',
      statusContent: 'dot',
      hash: 'SA412N9S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SA412N9S'
    },
    {
      status: 210,
      sku: 'PM0075016',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276619',
      rtl_batch_array: '6276619',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1994,
      chnn_stock_retail: 1994,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'JPA16SC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPA16SC4'
    },
    {
      status: 210,
      sku: 'PM0075017',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276620',
      rtl_batch_array: '6276620, 6310240',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1985,
      chnn_stock_retail: 4585,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'SYL7K5ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYL7K5ZA'
    },
    {
      status: 810,
      sku: 'PM0075295',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275049',
      rtl_batch_array: '6275049',
      name_search: 'SCVSLIGH',
      name: "Sciadopitys vert. 'Shine a Light'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 22.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AR5C1S29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR5C1S29'
    },
    {
      status: 210,
      sku: 'PM0075316',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274200',
      rtl_batch_array: '6274200',
      name_search: 'HIMCJHEA',
      name: 'Hibiscus moscheutos Carousel Jolly Heart',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWRS52G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWRS52G6'
    },
    {
      status: 210,
      sku: 'PM0075317',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274201',
      rtl_batch_array: '6274201',
      name_search: 'HIMCPRWI',
      name: 'Hibiscus moscheutos Carousel Pink Red Wine',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YBG6KEAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBG6KEAL'
    },
    {
      status: 210,
      sku: 'PM0075319',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274204',
      rtl_batch_array: '6274204',
      name_search: 'HIMCPCAN',
      name: 'Hibiscus moscheutos Carousel Pink Candy',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V7WZXKX4',
      statusContent: 'dot',
      hash: 'CH5D9L4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH5D9L4C'
    },
    {
      status: 210,
      sku: 'PM0075321',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274206',
      rtl_batch_array: '6274206',
      name_search: 'ANHSEREN',
      name: "Anemone hybrida 'Serenade'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.665,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V9B6VWV6;B74ETH8B;BHL6SVHS;HPYZKNRY;1AXAWYLG;149P7AHH;6DPWTGRE;PL2J2XKZ;HC6ZA5JX;YHL4RJH5;7C7EYGJ9;ZTCBP7C7',
      statusContent: 'dot',
      hash: '2CTCLJP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CTCLJP5'
    },
    {
      status: 210,
      sku: 'PM0075325',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274215',
      rtl_batch_array: '6274215',
      name_search: 'ANHLOREL',
      name: "Anemone hybrida 'Loreley'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.665,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRCCYEEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRCCYEEP'
    },
    {
      status: 210,
      sku: 'PM0015227',
      core_name: 'Plant',
      sppl_ean: '8720626336902',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5396909',
      rtl_batch_array: '5396909',
      name_search: 'BUDAQUEE',
      name: "Buddleja davidii 'African Queen'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 971,
      chnn_stock_retail: 971,
      sppl_prcp: 1.668,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5KAAELDK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KAAELDK'
    },
    {
      status: 210,
      sku: 'PM0018209',
      core_name: 'Plant',
      sppl_ean: '8720664680166',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054364',
      rtl_batch_array: '6054364',
      name_search: 'EDI68538',
      name: "Allium 'Violet Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67XEBAX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67XEBAX9'
    },
    {
      status: 210,
      sku: 'PM0023453',
      core_name: 'Plant',
      sppl_ean: '8720626396227',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495561',
      rtl_batch_array: '5495561',
      name_search: 'BUMVIOLE',
      name: "Buddleja 'Miss Violet'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 622,
      chnn_stock_retail: 622,
      sppl_prcp: 3.381,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '63KGPH6A',
      statusContent: 'dot',
      hash: '9212ZSBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9212ZSBL'
    },
    {
      status: 210,
      sku: 'PM0067151',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6274863',
      rtl_batch_array: '6274863, 6308743, 6336451, 6287690',
      name_search: 'RUDFGOLD',
      name: "Rudbeckia fulgida 'Goldsturm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8090,
      chnn_stock_retail: 16680,
      sppl_order_minimum: 3,
      sppl_prcp: 0.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWBYZAT4;RW4PBGXP;4382951C;4T7BH8T8;7YJ5DGT6;AV5XNPYH;WD72SLRX;P5SH553W;82JWC1YN;E7ELP1EL;VSZE85ZT;C5R22YYD;284BERXS;5V3TTLV8;G56E54EX;JA14SGC8;SEH17VVR;WGYGPZR7;V6XVHHAC;7X81JZHJ',
      statusContent: 'dot',
      hash: 'AC816XA5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AC816XA5'
    },
    {
      status: 210,
      sku: 'PM0017159',
      core_name: 'Plant',
      sppl_ean: '8720664689589',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6091047',
      rtl_batch_array: '6091047',
      name_search: 'EUCSEDGE',
      name: "Euphorbia characias 'Silver Edge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZPYPJVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZPYPJVP'
    },
    {
      status: 210,
      sku: 'PM0014023',
      core_name: 'Plant',
      sppl_ean: '8720349471096',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 5,
      btch_active_retail: '6037886',
      rtl_batch_array: '6208417, 6037886, 6220666, 6255885, 6348294',
      name_search: 'BEBWHITE',
      name: "Bergenia 'Bressingham White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1484,
      chnn_stock_retail: 15729,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KW8ER7G;BJB4BELL;V336GSGG;S9PN97KV',
      statusContent: 'dot',
      hash: 'CVKGNX96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVKGNX96'
    },
    {
      status: 210,
      sku: 'PM0004734',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348976',
      rtl_batch_array: '6147759, 6301550, 6348976',
      name_search: 'ECPLMAGN',
      name: "Echinacea purpurea 'Little Magnus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2063,
      chnn_stock_retail: 3075,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X4TXYW5B;RD25LBK2;BPNS8ZNK;N9ZR2XD3;GWG3Y7KE',
      statusContent: 'dot',
      hash: 'GPDG3GWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPDG3GWE'
    },
    {
      status: 210,
      sku: 'PM0002971',
      core_name: 'Plant',
      sppl_ean: '8720664657151',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6057520',
      rtl_batch_array: '6057520',
      name_search: 'YUGLAUCA',
      name: 'Yucca glauca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGTDA71J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGTDA71J'
    },
    {
      status: 210,
      sku: 'PM0023513',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254378',
      rtl_batch_array: '6349091, 6301616, 6254378',
      name_search: 'GECLSTRA',
      name: "Geum chiloense 'Lady Stratheden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1159,
      chnn_stock_retail: 4964,
      sppl_order_minimum: 3,
      sppl_prcp: 0.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V13A357L;V4C3T6TB;XGECDJ5W;K49DJAWW;Z1T8LRKK;52P4H4H5;SWVV39CE',
      statusContent: 'dot',
      hash: 'VWL2N7L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWL2N7L4'
    },
    {
      status: 210,
      sku: 'PM0024375',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356275',
      rtl_batch_array: '6220898, 6356275',
      name_search: 'RUFGOLDS',
      name: "Rudbeckia fulgida 'Goldschirm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 13000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1A8AK14J;SKKHJLD4;T5AJ59LG;DL4LLZLD;NRGE87R2;2ZJ33W19;H35TTRK1;BNVR3KDH;X5EDA2SS;5GWY8B88;K164SZAV;GY7HNLJY;WVY8PCPT;NH14WR71;YH1B9D26;LL4L5DZ9;5LC1GNHN',
      statusContent: 'dot',
      hash: 'W7L8Y712',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7L8Y712'
    },
    {
      status: 910,
      sku: 'PM0024786',
      core_name: 'Plant',
      sppl_ean: '8720349446070',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '5849236',
      rtl_batch_array: '6301469, 6252837, 5849236, 6308531',
      name_search: 'BRMJFROS',
      name: "Brunnera macrophylla 'Jack Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10,
      chnn_stock_retail: 21369,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G2LCES19;B447J5EN;RSJB1VH3;RPP2N27D;H4T88DYN',
      statusContent: 'dot',
      hash: 'ZGPTZ5EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGPTZ5EA'
    },
    {
      status: 210,
      sku: 'PM0075023',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276644',
      rtl_batch_array: '6276644',
      name_search: 'AGKYELLO',
      name: "Agastache 'Kudos Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V1ST7ZG;PSBGEGKJ;V8LZ5J5Y',
      statusContent: 'dot',
      hash: '2ATBD1H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ATBD1H7'
    },
    {
      status: 210,
      sku: 'PM0075028',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321900',
      rtl_batch_array: '6349928, 6321900',
      name_search: 'CHLPTEMP',
      name: "Chelone lyonii 'Pink Temptation'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 125,
      chnn_stock_retail: 724,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LLS9VRAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLS9VRAW'
    },
    {
      status: 210,
      sku: 'PM0080397',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337751',
      rtl_batch_array: '6337751',
      name_search: 'ACPSNOMU',
      name: "Acer palmatum 'Shojo-nomura'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K76CXGW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K76CXGW5'
    },
    {
      status: 810,
      sku: 'PM0080398',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337752',
      rtl_batch_array: '6337752',
      name_search: 'ACPSILHO',
      name: "Acer palmatum 'Silhouette'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 6.719,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1DLP7X9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1DLP7X9'
    },
    {
      status: 210,
      sku: 'PM0080399',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337753',
      rtl_batch_array: '6337753',
      name_search: 'ACPYNISH',
      name: "Acer palmatum 'Yezo-nishiki'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2V7DRS1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V7DRS1T'
    },
    {
      status: 810,
      sku: 'PM0080400',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337754',
      rtl_batch_array: '6337754',
      name_search: 'ACRUFINE',
      name: 'Acer rufinerve',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 4.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RKS5EHH6',
      statusContent: 'dot',
      hash: 'L7JAAN34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7JAAN34'
    },
    {
      status: 210,
      sku: 'PM0080401',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337755',
      rtl_batch_array: '6337755',
      name_search: 'ACGHNISH',
      name: "Acorus gramineus 'Hakuro-nishiki'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VH5PNTP;K5BHC9CH;8745RRJP',
      statusContent: 'dot',
      hash: 'RJJ7CY9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJJ7CY9C'
    },
    {
      status: 210,
      sku: 'PM0075018',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276626',
      rtl_batch_array: '6276626',
      name_search: 'VIMGJEKY',
      name: "Vinca minor 'Gertrude Jekyll'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1382,
      chnn_stock_retail: 1382,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y335HZAG;H5PLAKG9;6LH12BCA;XN619H8A;4CDRRTEZ;7N7KRB5C;Z5B295Z3;4HAWSZY7',
      statusContent: 'dot',
      hash: 'XPV7Y6PG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPV7Y6PG'
    },
    {
      status: 210,
      sku: 'PM0075019',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276628',
      rtl_batch_array: '6276628',
      name_search: 'CARACUTI',
      name: 'Carex acutiformis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1384,
      chnn_stock_retail: 1384,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SEG4DWL5',
      statusContent: 'dot',
      hash: 'E72W3JGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E72W3JGC'
    },
    {
      status: 210,
      sku: 'PM0080402',
      core_name: 'Plant',
      sppl_ean: '8720349446780',
      btch_manufacturer: 469,
      rtl_batch_array_total: 2,
      btch_active_retail: '5416112',
      rtl_batch_array: '5416112, 6337756',
      name_search: 'ACGOGON',
      name: "Acorus gramineus 'Ogon'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2825,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XEX4W2P1;7B3N2R24;HXBERTTP;PV39NESW;E264R1NJ;R5PEL6CS;PE7Z6R3T;V4T6VD5X',
      statusContent: 'dot',
      hash: 'WZRYZE6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZRYZE6H'
    },
    {
      status: 210,
      sku: 'PM0075020',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276630',
      rtl_batch_array: '6276630',
      name_search: 'MOCCARMA',
      name: "Molinia caerulea 'Carmarthen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2550,
      chnn_stock_retail: 2550,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6JREJCWR;5EXP7DX9;NXJXTGHC;T7WNN3W6;DS46NK74;4Y283W6W;784PJD18',
      statusContent: 'dot',
      hash: '93Y1CSLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93Y1CSLV'
    },
    {
      status: 210,
      sku: 'PM0080403',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337757',
      rtl_batch_array: '6337757',
      name_search: 'ACGARGEN',
      name: "Acorus gramineus 'Argenteostriatus'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GKX6968;XGAA2WX6;5WKAAYH5;ZW1XCE2E;Z8CSGN7T',
      statusContent: 'dot',
      hash: 'D2V4YJ8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2V4YJ8L'
    },
    {
      status: 810,
      sku: 'PM0075021',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276638',
      rtl_batch_array: '6276638',
      name_search: 'ANHALLII',
      name: 'Andropogon hallii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D5DZVKP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5DZVKP6'
    },
    {
      status: 210,
      sku: 'PM0075022',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6329839',
      rtl_batch_array: '6329839',
      name_search: 'ARUNEDO',
      name: 'Arbutus unedo',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 323,
      chnn_stock_retail: 323,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DSDKCNZG;59W87SK6',
      statusContent: 'dot',
      hash: 'YLC89KSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLC89KSG'
    },
    {
      status: 210,
      sku: 'PM0080404',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337758',
      rtl_batch_array: '6337758',
      name_search: 'ACTPILOS',
      name: 'Actinidia pilosula',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52LY5WY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52LY5WY7'
    },
    {
      status: 210,
      sku: 'PM0080405',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337759',
      rtl_batch_array: '6337759',
      name_search: 'AGEWHITE',
      name: 'Agapanthus Ever White',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9117KVAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9117KVAA'
    },
    {
      status: 210,
      sku: 'PM0080406',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337761',
      rtl_batch_array: '6337761',
      name_search: 'ANGLAUCO',
      name: 'Andromeda glaucophylla',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZLCHA425',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLCHA425'
    },
    {
      status: 810,
      sku: 'PM0080407',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337762',
      rtl_batch_array: '6337762',
      name_search: 'ANPBICE',
      name: "Andromeda polifolia 'Blue Ice'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JK4AY89J;S75DWC4K;D72VJB5G;HHECGGYP;6J4RHVRK;4Z28VW5Z;72ECN1VB;T2391BK2;T6R4CCXJ;C4JB33J8;JA98Y14H;WYSE99A5;J4AJA8BK;PVWLH1NX;VAVZHZR5;HNH59EWB;S44W2BHY;77JZWT7D;PWXVG1YB;HWZAA26Y;WESSTJD5;RLHP5XK5;1445XJDH',
      statusContent: 'dot',
      hash: 'HNJC1WDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNJC1WDX'
    },
    {
      status: 210,
      sku: 'PM0075026',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276650',
      rtl_batch_array: '6276650',
      name_search: 'ACFALTGO',
      name: "Achillea filipendulina 'Altgold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 219,
      chnn_stock_retail: 219,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZH8XDXTJ;YLTWAJHP',
      statusContent: 'dot',
      hash: 'LBPR2AAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LBPR2AAK'
    },
    {
      status: 210,
      sku: 'PM0080408',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337763',
      rtl_batch_array: '6337763',
      name_search: 'ARELATA',
      name: 'Aralia elata',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 4.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKRE4AD2',
      statusContent: 'dot',
      hash: 'A76ERDJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A76ERDJ5'
    },
    {
      status: 810,
      sku: 'PM0075296',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275052',
      rtl_batch_array: '6275052',
      name_search: 'SCVSLIGH',
      name: "Sciadopitys vert. 'Shine a Light'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 16.114,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSDLHV6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSDLHV6R'
    },
    {
      status: 210,
      sku: 'PM0075297',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275116',
      rtl_batch_array: '6275116',
      name_search: 'MYRSTEM',
      name: "Myriophyllum 'Red Stem'",
      sppl_size_code: 'LEVM6',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A38XHKYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A38XHKYJ'
    },
    {
      status: 210,
      sku: 'PM0075027',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276651',
      rtl_batch_array: '6276651',
      name_search: 'ACKOLBIA',
      name: 'Achillea kolbiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SJ22D6K;RSXCRW7Y;Y8E45E6C;D6JJ2WXT;1E2ZS38R;JVK7BJJK;4YNYDJ1K;1VD2SPSP;15JYHVYA;7YKGK126',
      statusContent: 'dot',
      hash: 'ZWN8556Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWN8556Y'
    },
    {
      status: 210,
      sku: 'PM0080409',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337764',
      rtl_batch_array: '6337764',
      name_search: 'ARUCOMPA',
      name: "Arbutus unedo 'Compacta'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3255,
      chnn_stock_retail: 3255,
      sppl_order_minimum: 3,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YYY6RHVC;5T1VT855;CRCKGSWL',
      statusContent: 'dot',
      hash: 'N5W5ZB15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5W5ZB15'
    },
    {
      status: 210,
      sku: 'PM0080410',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337765',
      rtl_batch_array: '6337765',
      name_search: 'ARUURSI',
      name: 'Arctostaphylos uva-ursi',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6AR34AVV',
      statusContent: 'dot',
      hash: 'GSGKZRR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSGKZRR1'
    },
    {
      status: 210,
      sku: 'PM0080411',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337766',
      rtl_batch_array: '6337766',
      name_search: 'ARMAMAGI',
      name: "Aronia melanocarpa 'Autumn Magic'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 598,
      chnn_stock_retail: 598,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3Y2JDTN;4KZZ8N5G',
      statusContent: 'dot',
      hash: 'XCWP2ZNW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCWP2ZNW'
    },
    {
      status: 210,
      sku: 'PM0080412',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337768',
      rtl_batch_array: '6337768',
      name_search: 'ARMPED',
      name: "Aronia melanocarpa 'Professor Ed'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 446,
      chnn_stock_retail: 446,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BDXXDW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BDXXDW9'
    },
    {
      status: 210,
      sku: 'PM0082668',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267694',
      rtl_batch_array: '6267694',
      name_search: 'HECSHEAR',
      name: "Hedera colchica 'Sulphur Heart'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 2.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ALAA1DE4;K82NZT1T;2E5KKWNZ;L677BA8L;7KCPWPXW',
      statusContent: 'dot',
      hash: 'ZNDVAPPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNDVAPPJ'
    },
    {
      status: 210,
      sku: 'PM0019014',
      core_name: 'Plant',
      sppl_ean: '8720349466993',
      btch_manufacturer: 158,
      rtl_batch_array_total: 5,
      btch_active_retail: '5533710',
      rtl_batch_array: '6370358, 5364058, 5533710, 6015039, 6363190',
      name_search: 'FEGLAUCA',
      name: 'Festuca glauca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1617,
      chnn_stock_retail: 13153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSVA34T4;ABP86W12;8EDK3VYW',
      statusContent: 'dot',
      hash: 'DT1P6JX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT1P6JX6'
    },
    {
      status: 210,
      sku: 'PM0080413',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337769',
      rtl_batch_array: '6337769',
      name_search: 'ASTRILOB',
      name: 'Asimina triloba',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 844,
      chnn_stock_retail: 844,
      sppl_prcp: 4.623,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LK93N2ZC',
      statusContent: 'dot',
      hash: '5DHHN8JP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DHHN8JP'
    },
    {
      status: 210,
      sku: 'PM0080414',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337770',
      rtl_batch_array: '6337770',
      name_search: 'ASNWESTL',
      name: "Astelia nervosa 'Westland'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 519,
      chnn_stock_retail: 519,
      sppl_prcp: 4.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APBB5KE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APBB5KE7'
    },
    {
      status: 210,
      sku: 'PM0080415',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337771',
      rtl_batch_array: '6337771',
      name_search: 'ATHALIMU',
      name: 'Atriplex halimus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79TTNBKP',
      statusContent: 'dot',
      hash: 'J528JR3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J528JR3W'
    },
    {
      status: 810,
      sku: 'PM0020714',
      core_name: 'Plant',
      sppl_ean: '8720664685499',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054535',
      rtl_batch_array: '6054535',
      name_search: 'COVR66',
      name: "Coreopsis verticillata 'Route 66'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E8BK3N3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8BK3N3G'
    },
    {
      status: 210,
      sku: 'PM0010517',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254397',
      rtl_batch_array: '6115161, 6254397',
      name_search: 'LAVROSEA',
      name: "Lavatera 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 340,
      chnn_stock_retail: 727,
      sppl_order_minimum: 3,
      sppl_prcp: 0.769,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZYXB1CXC;G1LLL57H;Y6HJR89T',
      statusContent: 'dot',
      hash: 'KXDZ31RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXDZ31RL'
    },
    {
      status: 910,
      sku: 'PM0027015',
      core_name: 'Plant',
      sppl_ean: '8720664804784',
      btch_manufacturer: 142908,
      rtl_batch_array_total: 1,
      btch_active_retail: '5732790',
      rtl_batch_array: '5732790',
      name_search: 'PYCDCOMI',
      name: "Pyrus communis 'Doyenn\u00e9 du Comice'",
      sppl_size_code: '140160C74',
      rng_range_identifier: 'H140160C7.5',
      rng_range_description: 'H140 cm 160 cm C7.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 9.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CH7BGNZY;X5EG45CP;452LAS3T;JD8WJNCP;61194LDR;G2439YD4;7W6H6N2G;LJ5TVK3R;ZV3E89RS;LVJXP3NN;L1H8DYGZ;B3ZBAJJE;L5ALVSHX;YZJTGG56;P2RXKR82;NECE7T11;VLPCBNKL',
      statusContent: 'dot',
      hash: 'LPZ8YPGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPZ8YPGH'
    },
    {
      status: 910,
      sku: 'PM0082669',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      rng_range_identifier: 'H020025BR',
      rng_range_description: 'H20 cm 25 cm Bare root',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'SZ81R1YC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SZ81R1YC'
    },
    {
      status: 210,
      sku: 'PM0018106',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269575',
      rtl_batch_array: '6370391, 6269575, 6353332',
      name_search: 'SANVKLOS',
      name: "Salvia nemorosa 'Viola Klose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2462,
      sppl_order_minimum: 3,
      sppl_prcp: 0.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H4AGGCRS',
      statusContent: 'dot',
      hash: 'XW413G3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW413G3V'
    },
    {
      status: 210,
      sku: 'PM0080416',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337772',
      rtl_batch_array: '6337772',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 563,
      chnn_stock_retail: 563,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: 'NSAX9353',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSAX9353'
    },
    {
      status: 810,
      sku: 'PM0080417',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337773',
      rtl_batch_array: '6337773',
      name_search: 'AZMICROP',
      name: 'Azara microphylla',
      sppl_size_code: '060070C3',
      rng_range_identifier: 'H060070C3',
      rng_range_description: 'H60 cm 70 cm C3',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4TYB5K8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TYB5K8Y'
    },
    {
      status: 210,
      sku: 'PM0080418',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337774',
      rtl_batch_array: '6337774',
      name_search: 'EUJMAURE',
      name: "Euonymus japonicus 'Microphyllus Aureovariegatus'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 233,
      chnn_stock_retail: 233,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NGP3AA4T;H5ELAAKT;G9GH1GKR;P3X7ATNV;S8TT7EGZ;E4T6GJ9B;XZJW82DC;KC46TDL8;J97TN9C2;99RYASTZ;WW1D576B',
      statusContent: 'dot',
      hash: 'APKLJJAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APKLJJAJ'
    },
    {
      status: 810,
      sku: 'PM0080419',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337775',
      rtl_batch_array: '6337775',
      name_search: 'EUPHELLO',
      name: 'Euonymus phellomanus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 3,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W519BHE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W519BHE7'
    },
    {
      status: 210,
      sku: 'PM0080420',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337776',
      rtl_batch_array: '6337776',
      name_search: 'EXRBPEAR',
      name: 'Exochorda racemosa Blushing Pearl',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 791,
      chnn_stock_retail: 791,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JH5DTCNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH5DTCNH'
    },
    {
      status: 210,
      sku: 'PM0080421',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337777',
      rtl_batch_array: '6337777',
      name_search: 'EXBRIDE',
      name: "Exochorda 'The Bride'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V8PN9B5S;TCEC1LGX;H5DCBSA8;NJB8R34P;45T8J833;S9K5D3BJ;JD4LAR76',
      statusContent: 'dot',
      hash: 'EHXD5PPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHXD5PPV'
    },
    {
      status: 210,
      sku: 'PM0082670',
      core_name: 'Plant',
      sppl_ean: '8720664618466',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207216',
      rtl_batch_array: '5207216',
      name_search: 'RHSSWORD',
      name: "Rhododendron (AJ) 'Silver Sword'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KBVA9PXS;YVZ9N1WZ;ZW1WW3YC;YHBCT7N4;A4H9Y2J1;ZEYNXCV9;H9BT934P;TC4LRGV8;JZCNHGX9;1ZZLJP69;K147ESY4;6S92GZSY;S7ZYH3D2',
      imageBatch: 'KE3C2ST1',
      statusContent: 'dot',
      hash: '4JEX3JZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JEX3JZT'
    },
    {
      status: 210,
      sku: 'PM0082671',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207230',
      rtl_batch_array: '5207230',
      name_search: 'RHGORANG',
      name: 'Rhododendron (AJ) Geisha Orange',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9G1Y1LG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9G1Y1LG'
    },
    {
      status: 210,
      sku: 'PM0082672',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207232',
      rtl_batch_array: '5207232',
      name_search: 'RHKERMES',
      name: "Rhododendron (AJ) 'Kermesina'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'VP5LDHW5',
      statusContent: 'dot',
      hash: '1DW8NYXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DW8NYXK'
    },
    {
      status: 210,
      sku: 'PM0082673',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207234',
      rtl_batch_array: '5207234',
      name_search: 'RHPANDA',
      name: "Rhododendron (AJ) 'Panda'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGPDHG8C',
      imageBatch: '5HSB2GER',
      statusContent: 'dot',
      hash: '26B9SBYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26B9SBYS'
    },
    {
      status: 210,
      sku: 'PM0082674',
      core_name: 'Plant',
      sppl_ean: '8720664618046',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207553',
      rtl_batch_array: '5207553',
      name_search: 'RHHCRIMS',
      name: "Rhododendron (AJ) 'Hino Crimson'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6E5WBY7;2L3X91XR;BWY8JRH3;C9PCPZVA;LXJZ8CRE;H5ZEDJ5Z;6EP7ZK84;54GA7TL9;7ZKGLV9X;T92137XD;PYSJZGB3;XHXA9233;S7Z2ALK4;HZ2CVSGS;LVX727PG',
      imageBatch: 'BN2L167V',
      statusContent: 'dot',
      hash: 'HB6PYA42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB6PYA42'
    },
    {
      status: 210,
      sku: 'PM0082675',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272853',
      rtl_batch_array: '6272853',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1990,
      chnn_stock_retail: 1990,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: 'WEHX6KHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEHX6KHE'
    },
    {
      status: 210,
      sku: 'PM0082676',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272854',
      rtl_batch_array: '6272854',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2701,
      chnn_stock_retail: 2701,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: 'GCVTHZE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCVTHZE4'
    },
    {
      status: 210,
      sku: 'PM0082677',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269838',
      rtl_batch_array: '6269838, 6272855',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1550,
      chnn_stock_retail: 12482,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: 'X62E29S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X62E29S8'
    },
    {
      status: 210,
      sku: 'PM0082678',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272856',
      rtl_batch_array: '6272856',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1158,
      chnn_stock_retail: 1158,
      sppl_prcp: 6.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: '1X642GTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1X642GTZ'
    },
    {
      status: 210,
      sku: 'PM0082679',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 6,
      btch_active_retail: '6260729',
      rtl_batch_array: '5837437, 6260729, 6248980, 6272858, 6290392, 6254768',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9750,
      chnn_stock_retail: 21240,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'VSLHEX47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSLHEX47'
    },
    {
      status: 210,
      sku: 'PM0082680',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6272859',
      rtl_batch_array: '6272859, 6348892',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 750,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'HSGCD6DH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSGCD6DH'
    },
    {
      status: 210,
      sku: 'PM0082681',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272860',
      rtl_batch_array: '6272860',
      name_search: 'LAANGUST',
      name: 'Lavandula angustifolia',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6521,
      chnn_stock_retail: 6521,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VYKKKDS;YPTGVRDY;HTP5ZG2A;TB7BHCZB;6DYPYP1T;EHVW5BND;1R9631SE;B1YG5SRS;N8SNW539;1K2KCCZG;2H8T9228;86XP5H33;A3YH7BYE;D8P5HTKC;NNECTC59;C445PJVV;V42B9KSP;EG5SVBSL;RLHDAEE1;1ZBBWPJ1;JG17P1VD;5X9VZLRJ;CJHAZ1CG;D48LBS7S',
      statusContent: 'dot',
      hash: 'RGLGXYXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGLGXYXS'
    },
    {
      status: 210,
      sku: 'PM0082682',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260728',
      rtl_batch_array: '6260728, 6272862, 6254765',
      name_search: 'LAANGUST',
      name: 'Lavandula angustifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 313,
      chnn_stock_retail: 3576,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VYKKKDS;YPTGVRDY;HTP5ZG2A;TB7BHCZB;6DYPYP1T;EHVW5BND;1R9631SE;B1YG5SRS;N8SNW539;1K2KCCZG;2H8T9228;86XP5H33;A3YH7BYE;D8P5HTKC;NNECTC59;C445PJVV;V42B9KSP;EG5SVBSL;RLHDAEE1;1ZBBWPJ1;JG17P1VD;5X9VZLRJ;CJHAZ1CG;D48LBS7S',
      statusContent: 'dot',
      hash: 'YLHWHV15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLHWHV15'
    },
    {
      status: 210,
      sku: 'PM0082683',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272863',
      rtl_batch_array: '6272863',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: 'RB12S357',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RB12S357'
    },
    {
      status: 210,
      sku: 'PM0082684',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254769',
      rtl_batch_array: '6272864, 6254769',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1883,
      chnn_stock_retail: 3338,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: 'BRSCD2SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRSCD2SG'
    },
    {
      status: 210,
      sku: 'PM0082685',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272865',
      rtl_batch_array: '6272865',
      name_search: 'LAINTERM',
      name: 'Lavandula intermedia',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 976,
      chnn_stock_retail: 976,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C6485B97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6485B97'
    },
    {
      status: 210,
      sku: 'PM0082686',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272866',
      rtl_batch_array: '6272866',
      name_search: 'LASTOECH',
      name: 'Lavandula stoechas',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1441,
      chnn_stock_retail: 1441,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P26KEK4Z;6PGD231W;DH5VG3N5;2GRS5B48;VB8W6THD;NJJWEJEJ;1A51D692;KVXG3VPP;RENLXGD9;LJWGWVHN;EHNVWE6N;1J2Z79S2;VYZR35RS;C3847468;7G3AA4W6;REPKT893;18T8PY9N;16E3CW9L;BH366JBZ;A7Z43XBV;9T3VH29L;JPL5GYG7;LEBHYEX5;AGE65TVC;SYT7ZBS4;9BAK5ZBW',
      statusContent: 'dot',
      hash: 'GRZXBDR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRZXBDR3'
    },
    {
      status: 210,
      sku: 'PM0082687',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272867',
      rtl_batch_array: '6272867',
      name_search: 'LASTOECH',
      name: 'Lavandula stoechas',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 752,
      chnn_stock_retail: 752,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P26KEK4Z;6PGD231W;DH5VG3N5;2GRS5B48;VB8W6THD;NJJWEJEJ;1A51D692;KVXG3VPP;RENLXGD9;LJWGWVHN;EHNVWE6N;1J2Z79S2;VYZR35RS;C3847468;7G3AA4W6;REPKT893;18T8PY9N;16E3CW9L;BH366JBZ;A7Z43XBV;9T3VH29L;JPL5GYG7;LEBHYEX5;AGE65TVC;SYT7ZBS4;9BAK5ZBW',
      statusContent: 'dot',
      hash: 'W5K6REAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5K6REAG'
    },
    {
      status: 210,
      sku: 'PM0082688',
      core_name: 'Plant',
      sppl_ean: '8720626339187',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5417935',
      rtl_batch_array: '5417935',
      name_search: 'CEDGVERS',
      name: "Ceanothus delilianus 'Gloire de Versailles'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 991,
      chnn_stock_retail: 991,
      sppl_prcp: 6.392,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ATGPTYLR;XA17B3K4;HC77YL3A;4TPEZX29',
      statusContent: 'dot',
      hash: '1KTJNPWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KTJNPWW'
    },
    {
      status: 910,
      sku: 'PM0082689',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPFILIG',
      name: "Acer palmatum 'Filigree'",
      rng_range_identifier: 'H030040RB',
      statusContent: 'dot',
      hash: 'K3D23YDA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K3D23YDA'
    },
    {
      status: 910,
      sku: 'PM0082690',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: '6R1C3NG8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6R1C3NG8'
    },
    {
      status: 910,
      sku: 'PM0082691',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'AWCZXGG7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AWCZXGG7'
    },
    {
      status: 910,
      sku: 'PM0082692',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIANGEL',
      name: "Hamamelis intermedia 'Angelly'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: '9V533Y61',
      statusContent: 'dot',
      hash: 'GVE12CAB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GVE12CAB'
    },
    {
      status: 810,
      sku: 'PM0082693',
      core_name: 'Plant',
      sppl_ean: '8720349487387',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423398',
      rtl_batch_array: '5423398',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 13.564,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: 'RKP169B5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKP169B5'
    },
    {
      status: 210,
      sku: 'PM0082694',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423400',
      rtl_batch_array: '5423400',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 15.535,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: '4CLR5SRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CLR5SRY'
    },
    {
      status: 910,
      sku: 'PM0082695',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'CBG925HH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CBG925HH'
    },
    {
      status: 910,
      sku: 'PM0082696',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: '2AZL63R6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2AZL63R6'
    },
    {
      status: 910,
      sku: 'PM0082697',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: '5EJ3Z62C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5EJ3Z62C'
    },
    {
      status: 910,
      sku: 'PM0082698',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAJZUCCA',
      name: "Hamamelis japonica 'Zuccariniana'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: 'SB33RAWS',
      statusContent: 'dot',
      hash: '8VPD2495',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8VPD2495'
    },
    {
      status: 910,
      sku: 'PM0082699',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAJZUCCA',
      name: "Hamamelis japonica 'Zuccariniana'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'SB33RAWS',
      statusContent: 'dot',
      hash: 'YNVDS5JA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YNVDS5JA'
    },
    {
      status: 910,
      sku: 'PM0082700',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAAMUREN',
      name: 'Maackia amurensis',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      statusContent: 'dot',
      hash: 'KSZ69DDJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KSZ69DDJ'
    },
    {
      status: 210,
      sku: 'PM0082701',
      core_name: 'Plant',
      sppl_ean: '8720353026701',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423419',
      rtl_batch_array: '5423419',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 8.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'BPLEXRJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPLEXRJZ'
    },
    {
      status: 210,
      sku: 'PM0082702',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385614',
      rtl_batch_array: '5423427, 6385614',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '025030KL',
      rng_range_identifier: 'H025030RB',
      rng_range_description: 'H25 cm 30 cm Root ball',
      sppl_stock_available: 2250,
      chnn_stock_retail: 3000,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'TA8VXNAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA8VXNAX'
    },
    {
      status: 210,
      sku: 'PM0082703',
      core_name: 'Plant',
      sppl_ean: '8720664651562',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423432',
      rtl_batch_array: '5423432',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 5.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: 'A4D9W37D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4D9W37D'
    },
    {
      status: 910,
      sku: 'PM0082704',
      core_name: 'Plant',
      sppl_ean: '8720349455614',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423580',
      rtl_batch_array: '5423580',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 27.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'ZCN5J6VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCN5J6VD'
    },
    {
      status: 910,
      sku: 'PM0082705',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPATROL',
      name: "Acer palmatum 'Atrolineare'",
      rng_range_identifier: 'H030040RB',
      imageCore: 'SHC1VZWN;7P7P9PNY;DGS9KDXV;N4W956DW',
      statusContent: 'dot',
      hash: 'JRYZTZ4C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JRYZTZ4C'
    },
    {
      status: 910,
      sku: 'PM0082706',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPATROL',
      name: "Acer palmatum 'Atrolineare'",
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      imageCore: 'SHC1VZWN;7P7P9PNY;DGS9KDXV;N4W956DW',
      statusContent: 'dot',
      hash: 'GJ1NSBWH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GJ1NSBWH'
    },
    {
      status: 910,
      sku: 'PM0082707',
      core_name: 'Plant',
      sppl_ean: '8720664679108',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423592',
      rtl_batch_array: '5423592',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '090STKL',
      rng_range_identifier: 'STE090RB',
      rng_range_description: 'Stem 90 cm Root ball',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 23.155,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: '9TBZ8LXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TBZ8LXH'
    },
    {
      status: 810,
      sku: 'PM0082708',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349229',
      rtl_batch_array: '5423643, 6349229',
      name_search: 'CECFPANS',
      name: "Cercis canadensis 'Forest Pansy'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 650,
      sppl_prcp: 19.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KNT4R6R4;LNDTSNS8;615LS6TJ;A8E67JPB;DW5BCS4Y;A61HGYDY;9R6LH946;VH5BDVZ3;X45E6557;XX5KG32E;9A57T5XD',
      statusContent: 'dot',
      hash: 'BVVNRZDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVVNRZDW'
    },
    {
      status: 810,
      sku: 'PM0082709',
      core_name: 'Plant',
      sppl_ean: '8720664685758',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423647',
      rtl_batch_array: '5423647',
      name_search: 'COKSAMAR',
      name: 'Cornus kousa Samaritan',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 17.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1TTT834R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TTT834R'
    },
    {
      status: 210,
      sku: 'PM0082710',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281432',
      rtl_batch_array: '6281432',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'N1ERW2ZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1ERW2ZR'
    },
    {
      status: 210,
      sku: 'PM0082711',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281436',
      rtl_batch_array: '6281436',
      name_search: 'ROCANINA',
      name: 'Rosa canina (o)',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1497,
      chnn_stock_retail: 1497,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKE1HCDZ;9XY9W4NY;N9WE9WG1',
      statusContent: 'dot',
      hash: 'NRXWVH45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRXWVH45'
    },
    {
      status: 210,
      sku: 'PM0082712',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281441',
      rtl_batch_array: '6281441',
      name_search: 'SARNITID',
      name: 'Salix repens nitida',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 151,
      chnn_stock_retail: 151,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZHP2L4T',
      statusContent: 'dot',
      hash: 'Y5TE58G1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5TE58G1'
    },
    {
      status: 210,
      sku: 'PM0082713',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281459',
      rtl_batch_array: '6281459',
      name_search: 'RORUGOSA',
      name: 'Rosa rugosa',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 910,
      chnn_stock_retail: 910,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YYVTCPJ7;JWELRSGK;G53PXS8P;1KJ6X6PA;G92ZEN4B;97RDXCY3;WAAWHLJR;EP85ETA7',
      statusContent: 'dot',
      hash: 'D44SC7HB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D44SC7HB'
    },
    {
      status: 910,
      sku: 'PM0082714',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAMORROW',
      name: 'Carex morrowii',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore: 'VEY1DCSC',
      statusContent: 'dot',
      hash: 'V5D31VH6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V5D31VH6'
    },
    {
      status: 210,
      sku: 'PM0082715',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6244232',
      rtl_batch_array: '6244232, 6248979, 6290391',
      name_search: 'LAADBLUE',
      name: "Lavandula angustifolia 'Dwarf Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1149,
      chnn_stock_retail: 5465,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1H3ESPR;X1DY9NER;13R414KC;S6ZPVWEE;DN6SPV9W;6H75BN8S',
      statusContent: 'dot',
      hash: '4CCDYZJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CCDYZJN'
    },
    {
      status: 210,
      sku: 'PM0082716',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246532',
      rtl_batch_array: '6246532',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: 'LTBTZBT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTBTZBT8'
    },
    {
      status: 910,
      sku: 'PM0082717',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334227',
      rtl_batch_array: '6334227',
      name_search: 'STCOBJAC',
      name: "Staphylea colchica 'Black Jack'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 11.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7JAS3ERJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JAS3ERJ'
    },
    {
      status: 210,
      sku: 'PM0082718',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334229',
      rtl_batch_array: '6334229',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_prcp: 6.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: '5PE2AWRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PE2AWRH'
    },
    {
      status: 210,
      sku: 'PM0082719',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334230',
      rtl_batch_array: '6334230',
      name_search: 'VISONOND',
      name: "Viburnum sargentii 'Onondaga'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_prcp: 12.628,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XDGKSTWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDGKSTWG'
    },
    {
      status: 210,
      sku: 'PM0082720',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334231',
      rtl_batch_array: '6334231',
      name_search: 'VISONOND',
      name: "Viburnum sargentii 'Onondaga'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 11.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PJ2KXAPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJ2KXAPB'
    },
    {
      status: 810,
      sku: 'PM0082721',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334235',
      rtl_batch_array: '6334235',
      name_search: 'HAISUNBU',
      name: "Hamamelis intermedia 'Sunburst'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSB7R2N4;CYSBP78A;12SL8HY9;PBBCTH33;EGE3A5XJ',
      statusContent: 'dot',
      hash: '9X44SNBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X44SNBJ'
    },
    {
      status: 810,
      sku: 'PM0082722',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334236',
      rtl_batch_array: '6334236',
      name_search: 'HAISUNBU',
      name: "Hamamelis intermedia 'Sunburst'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSB7R2N4;CYSBP78A;12SL8HY9;PBBCTH33;EGE3A5XJ',
      statusContent: 'dot',
      hash: '6BPL22Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BPL22Z5'
    },
    {
      status: 810,
      sku: 'PM0082723',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334237',
      rtl_batch_array: '6334237',
      name_search: 'HAISUNBU',
      name: "Hamamelis intermedia 'Sunburst'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSB7R2N4;CYSBP78A;12SL8HY9;PBBCTH33;EGE3A5XJ',
      statusContent: 'dot',
      hash: 'AD73RPLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AD73RPLK'
    },
    {
      status: 210,
      sku: 'PM0082724',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334238',
      rtl_batch_array: '6334238',
      name_search: 'HAISUNBU',
      name: "Hamamelis intermedia 'Sunburst'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 13.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSB7R2N4;CYSBP78A;12SL8HY9;PBBCTH33;EGE3A5XJ',
      statusContent: 'dot',
      hash: '4NG1YYPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NG1YYPW'
    },
    {
      status: 810,
      sku: 'PM0082725',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334240',
      rtl_batch_array: '6334240',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'X99T4GGX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X99T4GGX'
    },
    {
      status: 210,
      sku: 'PM0082726',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334246',
      rtl_batch_array: '6334246',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040050KL',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.387,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '142W2977',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '142W2977'
    },
    {
      status: 910,
      sku: 'PM0082727',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'NLCN37BR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NLCN37BR'
    },
    {
      status: 810,
      sku: 'PM0082728',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334257',
      rtl_batch_array: '6334257',
      name_search: 'ACPAOYAG',
      name: "Acer palmatum 'Aoyagi'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 21.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YBA4REZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBA4REZV'
    },
    {
      status: 810,
      sku: 'PM0082729',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334258',
      rtl_batch_array: '6334258',
      name_search: 'ACPAOYAG',
      name: "Acer palmatum 'Aoyagi'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 17.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZ13G8BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ13G8BE'
    },
    {
      status: 810,
      sku: 'PM0082730',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334259',
      rtl_batch_array: '6334259',
      name_search: 'ACPAZURU',
      name: "Acer palmatum 'Asahi-zuru'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 17.528,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9JWGRSB;EKZDWWL9;D791VVWT',
      statusContent: 'dot',
      hash: 'TV8JXPP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TV8JXPP6'
    },
    {
      status: 810,
      sku: 'PM0082731',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334260',
      rtl_batch_array: '6334260',
      name_search: 'ACPAZURU',
      name: "Acer palmatum 'Asahi-zuru'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9JWGRSB;EKZDWWL9;D791VVWT',
      statusContent: 'dot',
      hash: 'DZS5HPXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZS5HPXN'
    },
    {
      status: 810,
      sku: 'PM0082732',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334264',
      rtl_batch_array: '6334264',
      name_search: 'ACPAUREU',
      name: "Acer palmatum 'Aureum'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 21.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SDGWCTTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDGWCTTD'
    },
    {
      status: 910,
      sku: 'PM0082733',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: '8B134XKN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8B134XKN'
    },
    {
      status: 910,
      sku: 'PM0082734',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'GNNXAXXG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GNNXAXXG'
    },
    {
      status: 910,
      sku: 'PM0082735',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPFARAN',
      name: "Acer palmatum 'Fior d' Arancio'",
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      statusContent: 'dot',
      hash: 'XWZ62B5E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XWZ62B5E'
    },
    {
      status: 210,
      sku: 'PM0082736',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334274',
      rtl_batch_array: '6334274',
      name_search: 'ACPFARAN',
      name: "Acer palmatum 'Fior d' Arancio'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 16.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B2VENZJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2VENZJA'
    },
    {
      status: 210,
      sku: 'PM0082737',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334275',
      rtl_batch_array: '6334275',
      name_search: 'ACPFIREG',
      name: "Acer palmatum 'Fireglow'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 21.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ASCVKBVK;H81XLT98;VJCR9KHS;GW69666J;Y6KHLSWT;A29LZR29;L3NHE449;4BZDEE6S',
      statusContent: 'dot',
      hash: '5JC7N1DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JC7N1DX'
    },
    {
      status: 210,
      sku: 'PM0082738',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334276',
      rtl_batch_array: '6334276',
      name_search: 'ACPFIREG',
      name: "Acer palmatum 'Fireglow'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 17.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ASCVKBVK;H81XLT98;VJCR9KHS;GW69666J;Y6KHLSWT;A29LZR29;L3NHE449;4BZDEE6S',
      statusContent: 'dot',
      hash: 'Y2RS5D1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2RS5D1D'
    },
    {
      status: 210,
      sku: 'PM0082739',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334277',
      rtl_batch_array: '6334277',
      name_search: 'ACPFIREG',
      name: "Acer palmatum 'Fireglow'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 18.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ASCVKBVK;H81XLT98;VJCR9KHS;GW69666J;Y6KHLSWT;A29LZR29;L3NHE449;4BZDEE6S',
      statusContent: 'dot',
      hash: 'P9VWNZXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9VWNZXJ'
    },
    {
      status: 210,
      sku: 'PM0075035',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324739',
      rtl_batch_array: '6350511, 6324739, 6295684',
      name_search: 'HOJUNE',
      name: "Hosta 'June'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 598,
      chnn_stock_retail: 1755,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SPHBYEWZ;DRY3S5KR;17D3C567;VEH9EL6P;LSKB8XWV;DWHYDJN5;JV6VN8SB;AW7TJ929;2BWAESPG;NBZGGJRT;6ZSDTHRW;HJVT8NNA',
      statusContent: 'dot',
      hash: '95L2L2TA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95L2L2TA'
    },
    {
      status: 210,
      sku: 'PM0075036',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350513',
      rtl_batch_array: '6350513',
      name_search: 'HOLIBERT',
      name: "Hosta 'Liberty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GVX9LENC',
      statusContent: 'dot',
      hash: 'BDNW2APV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDNW2APV'
    },
    {
      status: 810,
      sku: 'PM0082740',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334280',
      rtl_batch_array: '6334280',
      name_search: 'ACPNICHO',
      name: "Acer palmatum 'Nicholsonii'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 19.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLLE39SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLLE39SW'
    },
    {
      status: 910,
      sku: 'PM0082741',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '421ZELGL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '421ZELGL'
    },
    {
      status: 210,
      sku: 'PM0072527',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260877',
      rtl_batch_array: '6260877, 6301092, 6309504',
      name_search: 'POSPDENS',
      name: "Polystichum setiferum 'Plumoso-densum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 5513,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9NL3P92;997JH22B;42AGS6R5',
      statusContent: 'dot',
      hash: '2X4Y1R6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2X4Y1R6H'
    },
    {
      status: 210,
      sku: 'PM0075033',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350294',
      rtl_batch_array: '6350294',
      name_search: 'HEKOENIG',
      name: "Helenium 'K\u00f6nigstiger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XPL7GE19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPL7GE19'
    },
    {
      status: 210,
      sku: 'PM0072526',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321874',
      rtl_batch_array: '6252387, 6321874',
      name_search: 'ANPDEPRE',
      name: 'Anacyclus pyrethrum depressus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AEXKRWEL;L68NV67R;286D9EL5;JV8N2XG5;JLACL5GG;BSKBP2TA;SNXG8YHX;1WDW922D;DST9PNS9;Z1TH3196;KJ4VAKGE;H24Y6P31;4C9TL211;YNYEPR1B',
      statusContent: 'dot',
      hash: '1L64B9NX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L64B9NX'
    },
    {
      status: 210,
      sku: 'PM0075032',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350280',
      rtl_batch_array: '6350280',
      name_search: 'HELCCAN',
      name: "Helenium 'Can Can'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BPNDLDAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPNDLDAG'
    },
    {
      status: 210,
      sku: 'PM0082742',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334284',
      rtl_batch_array: '6334284',
      name_search: 'ACPRYUHZ',
      name: "Acer palmatum 'Ryuhzu'",
      sppl_size_code: '100STKL',
      rng_range_identifier: 'STE100RB',
      rng_range_description: 'Stem 100 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 27.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RETXWDPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RETXWDPJ'
    },
    {
      status: 210,
      sku: 'PM0075037',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350519',
      rtl_batch_array: '6350519',
      name_search: 'HOPERMAF',
      name: "Hosta 'Permafrost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TDA6SZH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDA6SZH1'
    },
    {
      status: 210,
      sku: 'PM0075030',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350170',
      rtl_batch_array: '6350170',
      name_search: 'FIUVARIE',
      name: "Filipendula ulmaria 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 158,
      chnn_stock_retail: 158,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1CW7NCGN;GDC1J267',
      statusContent: 'dot',
      hash: 'GJXVYTXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJXVYTXA'
    },
    {
      status: 210,
      sku: 'PM0075031',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321911',
      rtl_batch_array: '6350059, 6321911',
      name_search: 'ECPLEUCH',
      name: "Echinacea purpurea 'Leuchtstern'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 1118,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G1WYGS29',
      statusContent: 'dot',
      hash: 'BP61P5ZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BP61P5ZX'
    },
    {
      status: 810,
      sku: 'PM0075034',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308577',
      rtl_batch_array: '6350236, 6308577, 6352699',
      name_search: 'GEPMREIT',
      name: "Geranium pratense 'Midnight Reiter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 660,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5H651ZJW;VNX9VSD5;GZ8DAAT5',
      statusContent: 'dot',
      hash: 'D5SPVNYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5SPVNYV'
    },
    {
      status: 210,
      sku: 'PM0075038',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350521',
      rtl_batch_array: '6350521',
      name_search: 'HOPHANDS',
      name: "Hosta 'Praying Hands'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 648,
      chnn_stock_retail: 648,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8R5GRYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8R5GRYT'
    },
    {
      status: 210,
      sku: 'PM0075039',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350553',
      rtl_batch_array: '6350553',
      name_search: 'INDELAVA',
      name: 'Incarvillea delavayi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 224,
      chnn_stock_retail: 224,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TN7YNH13;7NWXSB9D;45ZK24C4;8VANP498;GY85SLN1;G4ANWVDR',
      statusContent: 'dot',
      hash: '737B8BVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '737B8BVZ'
    },
    {
      status: 210,
      sku: 'PM0080422',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337778',
      rtl_batch_array: '6337778',
      name_search: 'FALVARIE',
      name: "Fatshedera lizei 'Variegata'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1H9PBSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1H9PBSD'
    },
    {
      status: 810,
      sku: 'PM0075329',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353350',
      rtl_batch_array: '6353350',
      name_search: 'SAMMERLO',
      name: "Sanguisorba 'Maartjes Merlot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2VP4SVD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VP4SVD4'
    },
    {
      status: 210,
      sku: 'PM0075330',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276699',
      rtl_batch_array: '6276699',
      name_search: 'ROAINDIA',
      name: "Roscoea auriculata 'India'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_order_minimum: 3,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCWNTN8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCWNTN8L'
    },
    {
      status: 210,
      sku: 'PM0080423',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337779',
      rtl_batch_array: '6337779',
      name_search: 'FATJAPON',
      name: 'Fatsia japonica',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1024,
      chnn_stock_retail: 1024,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RSC48GZ;R5SVETV2;49WXNLEH;NAPTC6TV;BR2DPDZE;H4CHEYKL;GEPH7HV3;8PSCVAB6;CH5TD35H;RDK4EXYN;6TX92Y4T;1NB9EV7C;YWTA9LTT;H96S38ZG;2DPPH8G6;52AYEX2C;AJ6CK65G;SDSSNPWV;7Z6RYRPY;JJ7K336S;42JXK8ZT;RVR78BZ6;E45K1DJ9;72RDDVZ6',
      statusContent: 'dot',
      hash: 'ESSE4DCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESSE4DCW'
    },
    {
      status: 210,
      sku: 'PM0080424',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337780',
      rtl_batch_array: '6337780',
      name_search: 'FAJSWEB',
      name: "Fatsia japonica 'Spider's Web'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1491,
      chnn_stock_retail: 1491,
      sppl_prcp: 4.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3B94AJ2;NKD557B2;X4KAN56K;Y3RNSPWK;8TED5GNZ;Y68T7YTZ;HWNG8EZT;EW9BY6TD;XC4DAZXW;CPZBWXY9;ZDVS9LBL;GWTWBRV2;NAB5S1ZA;VP8A4ZS6;JK7VTB2C;C4B6PJLY;WX9SSXS3',
      statusContent: 'dot',
      hash: 'P6BYHXD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6BYHXD7'
    },
    {
      status: 210,
      sku: 'PM0080425',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337782',
      rtl_batch_array: '6337782',
      name_search: 'FORMOR',
      name: "Forsythia Mar\u00e9e d'Or",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2533,
      chnn_stock_retail: 2533,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CWWEG2LL;EP63V2WJ;9GDRPBV1;49BEL117;C7GG6XKL;YYGHARGE;SR8KP4X3;DJZKVSZG',
      statusContent: 'dot',
      hash: 'SVBA5384',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVBA5384'
    },
    {
      status: 210,
      sku: 'PM0082743',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334285',
      rtl_batch_array: '6334285',
      name_search: 'ACPRYUHZ',
      name: "Acer palmatum 'Ryuhzu'",
      sppl_size_code: '080STKL',
      rng_range_identifier: 'STE080RB',
      rng_range_description: 'Stem 80 cm Root ball',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 24.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SG9GV8XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG9GV8XW'
    },
    {
      status: 210,
      sku: 'PM0082744',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334288',
      rtl_batch_array: '6334288',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 17.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'DPWJK3D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPWJK3D3'
    },
    {
      status: 810,
      sku: 'PM0075332',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276703',
      rtl_batch_array: '6276703',
      name_search: 'ROPTTOWE',
      name: "Roscoea purpurea 'Twin Towers'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4W5PJ51A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W5PJ51A'
    },
    {
      status: 210,
      sku: 'PM0014261',
      core_name: 'Plant',
      sppl_ean: '8720626335585',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054446',
      rtl_batch_array: '6301448, 6054446',
      name_search: 'ASGLADST',
      name: "Astilbe (J) 'Gladstone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1760,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J4VEX1JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4VEX1JH'
    },
    {
      status: 910,
      sku: 'PM0002671',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPDHARU',
      name: "Hydrangea paniculata 'Dharuma'",
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      statusContent: 'dot',
      hash: 'JEV7PK8S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JEV7PK8S'
    },
    {
      status: 210,
      sku: 'PM0017549',
      core_name: 'Plant',
      sppl_ean: '8720664681460',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495199',
      rtl_batch_array: '6329865, 5495199, 6349771',
      name_search: 'ASMFLORE',
      name: "Astrantia major 'Florence'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 870,
      chnn_stock_retail: 3589,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LYGJP5W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYGJP5W5'
    },
    {
      status: 210,
      sku: 'PM0028028',
      core_name: 'Plant',
      sppl_ean: '8720664800625',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5358148',
      rtl_batch_array: '5358148',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'GMC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 494,
      chnn_stock_retail: 494,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      imageBatch: '897KV34W',
      statusContent: 'dot',
      hash: 'NG1N3CNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG1N3CNB'
    },
    {
      status: 210,
      sku: 'PM0007394',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349153',
      rtl_batch_array: '6349153, 6221376, 6370396',
      name_search: 'TRCGQUEE',
      name: "Trollius chinensis 'Golden Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1824,
      chnn_stock_retail: 2579,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LPHREY8L;9S61WPTE;GJ34B8ZV;6GX1WTJX;GTWDWAAS;Y32CWXSW;LN55YS2T;C48HAZ4B',
      statusContent: 'dot',
      hash: 'YZ8KJ49L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ8KJ49L'
    },
    {
      status: 210,
      sku: 'PM0080426',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337783',
      rtl_batch_array: '6337783',
      name_search: 'FOIFIEST',
      name: "Forsythia intermedia 'Fiesta'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 219,
      chnn_stock_retail: 219,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z1RWC4DJ',
      statusContent: 'dot',
      hash: 'KTE9XDZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTE9XDZ7'
    },
    {
      status: 210,
      sku: 'PM0080427',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337785',
      rtl_batch_array: '6337785',
      name_search: 'FOIGTIME',
      name: "Forsythia intermedia 'Golden Times'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 636,
      chnn_stock_retail: 636,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C96D9PKG',
      statusContent: 'dot',
      hash: '5TN8X1JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TN8X1JH'
    },
    {
      status: 210,
      sku: 'PM0080428',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337786',
      rtl_batch_array: '6337786',
      name_search: 'FOIMINIG',
      name: 'Forsythia intermedia Minigold',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TVELCCX3;Y5GD8V13;NEKX537A;D69Y7KC6',
      statusContent: 'dot',
      hash: '15VXELDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15VXELDX'
    },
    {
      status: 210,
      sku: 'PM0080429',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337787',
      rtl_batch_array: '6337787',
      name_search: 'FOIMIKAD',
      name: 'Forsythia intermedia Mikador',
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K16T8L6D;RVNNHZ49;R9KYJR7V;T4XPXDBS;L5RXPDY4',
      statusContent: 'dot',
      hash: '7LSDG6V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LSDG6V1'
    },
    {
      status: 210,
      sku: 'PM0080430',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337788',
      rtl_batch_array: '6337788',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1067,
      chnn_stock_retail: 1067,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: '2BWSXX2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BWSXX2K'
    },
    {
      status: 210,
      sku: 'PM0080431',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337789',
      rtl_batch_array: '6337789',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 307,
      chnn_stock_retail: 307,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'ZEX28PT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZEX28PT7'
    },
    {
      status: 210,
      sku: 'PM0080432',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337790',
      rtl_batch_array: '6337790',
      name_search: 'FOMAJOR',
      name: 'Fothergilla major',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 778,
      chnn_stock_retail: 778,
      sppl_order_minimum: 5,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N46NLV3Z;77E6K2RB;4X4CGH67;5G4EARD4;C42ZL9BE;PCB852GY;K5BZZVD1',
      statusContent: 'dot',
      hash: 'LTH91XL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTH91XL7'
    },
    {
      status: 810,
      sku: 'PM0080433',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337791',
      rtl_batch_array: '6337791',
      name_search: 'FOIBSHAD',
      name: "Fothergilla intermedia 'Blue Shadow'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R5B5NBSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5B5NBSG'
    },
    {
      status: 210,
      sku: 'PM0080434',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337792',
      rtl_batch_array: '6337792',
      name_search: 'FRCGLORY',
      name: "Fremontodendron 'California Glory'",
      sppl_size_code: '060070C3',
      rng_range_identifier: 'H060070C3',
      rng_range_description: 'H60 cm 70 cm C3',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHB8XVAH',
      statusContent: 'dot',
      hash: '59TS5CKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59TS5CKC'
    },
    {
      status: 910,
      sku: 'PM0080435',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FUAHOFFM',
      name: "Fuchsia 'Frau Alice Hoffmann'",
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore: '4GTCNPR2;YT4KLDP8;A4AN2XGE;Y2N9RDA1',
      statusContent: 'dot',
      hash: '84RWNZY5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '84RWNZY5'
    },
    {
      status: 910,
      sku: 'PM0080436',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FUGENII',
      name: "Fuchsia 'Genii'",
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore:
        '91B6ZJDG;K13EAE43;6Z7ATAD2;1ANEX6XA;H5ECLLS4;CCPGY2KK;C3ZPSN8H;43DYL9L7',
      statusContent: 'dot',
      hash: 'ATTB1YWD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ATTB1YWD'
    },
    {
      status: 210,
      sku: 'PM0080437',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337795',
      rtl_batch_array: '6337795',
      name_search: 'FUSHARPI',
      name: "Fuchsia 'Sharpitor'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V41KPHAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V41KPHAR'
    },
    {
      status: 210,
      sku: 'PM0080438',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337796',
      rtl_batch_array: '6337796',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: 'R8TRDA57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8TRDA57'
    },
    {
      status: 210,
      sku: 'PM0080439',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337797',
      rtl_batch_array: '6337797',
      name_search: 'FUMICROP',
      name: 'Fuchsia microphylla',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PCBC5XL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCBC5XL5'
    },
    {
      status: 210,
      sku: 'PM0080440',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337798',
      rtl_batch_array: '6337798',
      name_search: 'FUDAVID',
      name: "Fuchsia 'David'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 588,
      chnn_stock_retail: 588,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HNHH4YK',
      statusContent: 'dot',
      hash: '4CAS7KT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CAS7KT9'
    },
    {
      status: 210,
      sku: 'PM0080441',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337799',
      rtl_batch_array: '6337799',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1083,
      chnn_stock_retail: 1083,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: 'AZVX6RLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZVX6RLT'
    },
    {
      status: 210,
      sku: 'PM0080442',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337800',
      rtl_batch_array: '6337800',
      name_search: 'FUTTHUMB',
      name: "Fuchsia 'Tom Thumb'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GN92TR3;HVEZL64A;SCTBVHZH;1G825SDJ;GXVX5HVB;V6RP7PJ8;1CCRVRSR;5D8APRRE;P4WP1K77;CPLR64E8;DT5B7T6E;4LPRN9GW;XKDXWKSD;KT2R26DT;58V3W793',
      statusContent: 'dot',
      hash: 'KVXGZZBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVXGZZBK'
    },
    {
      status: 210,
      sku: 'PM0080443',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337801',
      rtl_batch_array: '6337801',
      name_search: 'FUMVERSI',
      name: "Fuchsia magellanica 'Versicolor'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 663,
      chnn_stock_retail: 663,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJALNPB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJALNPB7'
    },
    {
      status: 210,
      sku: 'PM0080444',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337802',
      rtl_batch_array: '6337802',
      name_search: 'FUTWEST',
      name: "Fuchsia 'Tom West'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7L4L4E8J;ZERRDJWZ;HW5A78NL;L3HYAX18;6YYT5YYK;XJH1VBNZ;RCYWEHDH;VC6HNK57;W7BJC9R7',
      statusContent: 'dot',
      hash: 'JGRPA7SJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGRPA7SJ'
    },
    {
      status: 210,
      sku: 'PM0080445',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337803',
      rtl_batch_array: '6337803',
      name_search: 'GAJKHARD',
      name: "Gardenia jasminoides 'Kleim's Hardy'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1523,
      chnn_stock_retail: 1523,
      sppl_order_minimum: 3,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R83VXNKL;4S1GYR86;RA4H6JEP',
      statusContent: 'dot',
      hash: 'TAJCRG55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TAJCRG55'
    },
    {
      status: 210,
      sku: 'PM0080446',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337804',
      rtl_batch_array: '6337804',
      name_search: 'GATHURET',
      name: 'Garrya thurettii',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XL8XCN1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL8XCN1R'
    },
    {
      status: 210,
      sku: 'PM0080447',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337805',
      rtl_batch_array: '6337805',
      name_search: 'GEHISPAN',
      name: 'Genista hispanica',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K8DRR5B6;VN4CVZ17;A86W4NJX;HPVN3ED3;B6WX8GS1;Z593XEGP;LSZ27KS2;42XVPA7X;EJNLWYBP;ZSPWY3D4;SS6R14GT;EDYV3GJZ;DAA7E42X;WJ44NN3G;B9N9YR21;SHTDTP14;T716B4EY;R9SZEA37;8X7NPN9N',
      statusContent: 'dot',
      hash: '5YHBSKC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YHBSKC1'
    },
    {
      status: 810,
      sku: 'PM0082745',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334289',
      rtl_batch_array: '6334289',
      name_search: 'ACPTSUMA',
      name: "Acer palmatum 'Tsumabeni'",
      sppl_size_code: '030040KL',
      rng_range_identifier: 'H030040RB',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 17.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZV934ZGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV934ZGW'
    },
    {
      status: 810,
      sku: 'PM0082746',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349230',
      rtl_batch_array: '6334299, 6349230',
      name_search: 'CECFPANS',
      name: "Cercis canadensis 'Forest Pansy'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 300,
      sppl_prcp: 24.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KNT4R6R4;LNDTSNS8;615LS6TJ;A8E67JPB;DW5BCS4Y;A61HGYDY;9R6LH946;VH5BDVZ3;X45E6557;XX5KG32E;9A57T5XD',
      statusContent: 'dot',
      hash: 'EPGT9BHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPGT9BHG'
    },
    {
      status: 210,
      sku: 'PM0082747',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334300',
      rtl_batch_array: '6334300',
      name_search: 'CORUTGAN',
      name: "Cornus 'Rutgan'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 16.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ASKY5BP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASKY5BP4'
    },
    {
      status: 210,
      sku: 'PM0082748',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334304',
      rtl_batch_array: '6334304',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 15.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: '9LBVZ8ZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LBVZ8ZG'
    },
    {
      status: 210,
      sku: 'PM0082749',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334305',
      rtl_batch_array: '6378308, 6334305',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 600,
      sppl_prcp: 16.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: '2EVGWRCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EVGWRCY'
    },
    {
      status: 810,
      sku: 'PM0082750',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334307',
      rtl_batch_array: '6378310, 6334307',
      name_search: 'COKSATOM',
      name: "Cornus kousa 'Satomi'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 15.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46W63H9G;ZWPZR8X9;HCL32Y1H;WE7LXD8X',
      statusContent: 'dot',
      hash: 'B5VJ3CE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5VJ3CE1'
    },
    {
      status: 210,
      sku: 'PM0082751',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334308',
      rtl_batch_array: '6378311, 6334308',
      name_search: 'COKSATOM',
      name: "Cornus kousa 'Satomi'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 150,
      chnn_stock_retail: 170,
      sppl_prcp: 16.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46W63H9G;ZWPZR8X9;HCL32Y1H;WE7LXD8X',
      statusContent: 'dot',
      hash: 'R18NKY3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R18NKY3E'
    },
    {
      status: 210,
      sku: 'PM0082752',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334309',
      rtl_batch_array: '6378313, 6334309',
      name_search: 'COKSCHME',
      name: "Cornus kousa 'Schmetterling'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 450,
      chnn_stock_retail: 800,
      sppl_prcp: 16.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z4SD8LTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4SD8LTL'
    },
    {
      status: 910,
      sku: 'PM0082753',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIANGEL',
      name: "Hamamelis intermedia 'Angelly'",
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore: '9V533Y61',
      statusContent: 'dot',
      hash: '4RGTTD7T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4RGTTD7T'
    },
    {
      status: 210,
      sku: 'PM0082754',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334314',
      rtl_batch_array: '6334314',
      name_search: 'HAIANGEL',
      name: "Hamamelis intermedia 'Angelly'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 15.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9V533Y61',
      statusContent: 'dot',
      hash: 'XTN9N4SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTN9N4SY'
    },
    {
      status: 810,
      sku: 'PM0082755',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334317',
      rtl_batch_array: '6334317',
      name_search: 'HAIAUROR',
      name: "Hamamelis intermedia 'Aurora'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 15.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AP2ATBP;H7KXJ3KK;7SLN9BYZ;8EDXG55V',
      statusContent: 'dot',
      hash: 'DZV5TDS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZV5TDS3'
    },
    {
      status: 810,
      sku: 'PM0082756',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334318',
      rtl_batch_array: '6334318',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'TZESRAT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZESRAT9'
    },
    {
      status: 810,
      sku: 'PM0082757',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334319',
      rtl_batch_array: '6334319',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'LWEWWEPX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWEWWEPX'
    },
    {
      status: 210,
      sku: 'PM0082758',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334320',
      rtl_batch_array: '6334320, 6349210',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 125,
      chnn_stock_retail: 175,
      sppl_prcp: 15.535,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'KV1RZ9VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KV1RZ9VY'
    },
    {
      status: 810,
      sku: 'PM0082759',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334321',
      rtl_batch_array: '6334321',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: '4HDCTRS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HDCTRS9'
    },
    {
      status: 910,
      sku: 'PM0082760',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'ABXRJLA2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ABXRJLA2'
    },
    {
      status: 810,
      sku: 'PM0082761',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334325',
      rtl_batch_array: '6334325',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 12.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: 'VP8EB4P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VP8EB4P6'
    },
    {
      status: 210,
      sku: 'PM0082762',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6334326',
      rtl_batch_array: '6334326',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: 'G5HN7P49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5HN7P49'
    },
    {
      status: 910,
      sku: 'PM0082763',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: 'SDBLWPD5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SDBLWPD5'
    },
    {
      status: 910,
      sku: 'PM0082764',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'PT4DPWCS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PT4DPWCS'
    },
    {
      status: 910,
      sku: 'PM0082765',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'D4Y8R4C2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D4Y8R4C2'
    },
    {
      status: 910,
      sku: 'PM0082766',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'JLDVR793',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JLDVR793'
    },
    {
      status: 810,
      sku: 'PM0082767',
      core_name: 'Plant',
      sppl_ean: '8720664809154',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5533849',
      rtl_batch_array: '5533849',
      name_search: 'STCBJACK',
      name: "Staphylea colchica 'Black Jack\u00ae'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 9.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WPRG6VB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPRG6VB7'
    },
    {
      status: 210,
      sku: 'PM0082768',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '6335116',
      rtl_batch_array: '6335116',
      name_search: 'CECRFORC',
      name: 'Cercis canadensis Red Force',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_prcp: 27.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A4PBTP95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4PBTP95'
    },
    {
      status: 910,
      sku: 'PM0082769',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'NGEWHSLA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NGEWHSLA'
    },
    {
      status: 910,
      sku: 'PM0082770',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '79YASEV2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '79YASEV2'
    },
    {
      status: 910,
      sku: 'PM0082771',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'D4HWHLA3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D4HWHLA3'
    },
    {
      status: 910,
      sku: 'PM0082772',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASPENDU',
      name: "Fagus sylvatica 'Pendula'",
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      imageCore: 'L2EG3Z5X;CSYHP2LL;YW2BPPG1',
      statusContent: 'dot',
      hash: 'CVZ685YT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CVZ685YT'
    },
    {
      status: 910,
      sku: 'PM0082773',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASPFOUN',
      name: "Fagus sylvatica 'Purple Fountain'",
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'RPVB67WR',
      statusContent: 'dot',
      hash: 'DHWSC7VT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DHWSC7VT'
    },
    {
      status: 910,
      sku: 'PM0082774',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASPFOUN',
      name: "Fagus sylvatica 'Purple Fountain'",
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore: 'RPVB67WR',
      statusContent: 'dot',
      hash: 'P2NB5E7C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P2NB5E7C'
    },
    {
      status: 910,
      sku: 'PM0082775',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASPFOUN',
      name: "Fagus sylvatica 'Purple Fountain'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: 'RPVB67WR',
      statusContent: 'dot',
      hash: '864BLSPK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '864BLSPK'
    },
    {
      status: 810,
      sku: 'PM0082776',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349196',
      rtl_batch_array: '6349196',
      name_search: 'FASPFOUN',
      name: "Fagus sylvatica 'Purple Fountain'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPVB67WR',
      statusContent: 'dot',
      hash: 'RG5X35VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG5X35VJ'
    },
    {
      status: 810,
      sku: 'PM0082777',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349197',
      rtl_batch_array: '6349197',
      name_search: 'FASROBEL',
      name: "Fagus sylvatica 'Rohan Obelisk'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZG13NXD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG13NXD5'
    },
    {
      status: 810,
      sku: 'PM0082778',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349198',
      rtl_batch_array: '6349198',
      name_search: 'FASROBEL',
      name: "Fagus sylvatica 'Rohan Obelisk'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.994,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SLS24V9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLS24V9E'
    },
    {
      status: 810,
      sku: 'PM0082779',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349199',
      rtl_batch_array: '6349199',
      name_search: 'FASROBEL',
      name: "Fagus sylvatica 'Rohan Obelisk'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.695,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AELKJ7HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AELKJ7HZ'
    },
    {
      status: 810,
      sku: 'PM0082780',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349200',
      rtl_batch_array: '6349200',
      name_search: 'FASROBEL',
      name: "Fagus sylvatica 'Rohan Obelisk'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WV4T1DZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV4T1DZA'
    },
    {
      status: 810,
      sku: 'PM0082781',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349204',
      rtl_batch_array: '6349204',
      name_search: 'HACAROLI',
      name: 'Halesia carolina',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CXEH8YYB;VG75XELD;NGYYHXRZ',
      statusContent: 'dot',
      hash: 'Z4GHSVSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4GHSVSX'
    },
    {
      status: 910,
      sku: 'PM0082782',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HACAROLI',
      name: 'Halesia carolina',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'CXEH8YYB;VG75XELD;NGYYHXRZ',
      statusContent: 'dot',
      hash: 'DHD8VXPL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DHD8VXPL'
    },
    {
      status: 810,
      sku: 'PM0082783',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349206',
      rtl_batch_array: '6349206',
      name_search: 'HACAROLI',
      name: 'Halesia carolina',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.933,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CXEH8YYB;VG75XELD;NGYYHXRZ',
      statusContent: 'dot',
      hash: 'VSWZEKLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSWZEKLR'
    },
    {
      status: 910,
      sku: 'PM0082784',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HACAROLI',
      name: 'Halesia carolina',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore: 'CXEH8YYB;VG75XELD;NGYYHXRZ',
      statusContent: 'dot',
      hash: 'PP7E2AHW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PP7E2AHW'
    },
    {
      status: 910,
      sku: 'PM0082785',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'S9PL4PXH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S9PL4PXH'
    },
    {
      status: 910,
      sku: 'PM0082786',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: '54E4A4HA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '54E4A4HA'
    },
    {
      status: 910,
      sku: 'PM0082787',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAMOLLIS',
      name: 'Hamamelis mollis',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: 'JPEPBEDG;Y6DKAK9V;DGCWZJG3;H3NL7VBD;KGPKL4TB',
      statusContent: 'dot',
      hash: 'P3NAW7N7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P3NAW7N7'
    },
    {
      status: 910,
      sku: 'PM0082788',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'VPWS269J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VPWS269J'
    },
    {
      status: 810,
      sku: 'PM0082789',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349219',
      rtl_batch_array: '6349219',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B3ASER4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3ASER4Y'
    },
    {
      status: 810,
      sku: 'PM0082790',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349220',
      rtl_batch_array: '6349220',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G4E3WDW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4E3WDW6'
    },
    {
      status: 910,
      sku: 'PM0082791',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: 'KA2DVGPR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KA2DVGPR'
    },
    {
      status: 910,
      sku: 'PM0082792',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: '88AGB1C6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '88AGB1C6'
    },
    {
      status: 910,
      sku: 'PM0082793',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: '5GHYGD8P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5GHYGD8P'
    },
    {
      status: 910,
      sku: 'PM0082794',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'SGA8TCE8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SGA8TCE8'
    },
    {
      status: 910,
      sku: 'PM0082795',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'J6TPGTBT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J6TPGTBT'
    },
    {
      status: 810,
      sku: 'PM0082796',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349231',
      rtl_batch_array: '6349231',
      name_search: 'CECMERLO',
      name: "Cercis canadensis 'Merlot'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T15VX696;Z8GVGE7K;6VRWB1B2;49RRA5HJ;T3J7A6NH;K5ARVB2Y;5XW2559W;AS7B7GR6;5GL5K4R5;1NC4N1JT;ALXBG823;5B8H67RE',
      statusContent: 'dot',
      hash: 'SJWSJBWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJWSJBWJ'
    },
    {
      status: 810,
      sku: 'PM0082797',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349232',
      rtl_batch_array: '6349232',
      name_search: 'CECANADE',
      name: 'Cercis canadensis',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LVTLE62R;AH74D8P4;545RX5ZD;54E271T2;RJ41P2H4;P86Y86VH;4KE5VSDD;HZTGKDH6;JX8HCT51;4S697TKJ;JWLCTNWZ;KNWPRTJK;CX7HPD7W;R47NA8E7;JNGNS34S;TVAXKYJ8;8Z8YYAYB;YWSDW6E8;TVX1V7HG;AWZ6NCWS;47K15XY4',
      statusContent: 'dot',
      hash: '7L1A22B1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7L1A22B1'
    },
    {
      status: 910,
      sku: 'PM0082798',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: '96P21N49',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '96P21N49'
    },
    {
      status: 910,
      sku: 'PM0082799',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: 'AL7HKRG7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AL7HKRG7'
    },
    {
      status: 910,
      sku: 'PM0082800',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: 'H2L6CB7V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H2L6CB7V'
    },
    {
      status: 910,
      sku: 'PM0082801',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: '71D8L648',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '71D8L648'
    },
    {
      status: 910,
      sku: 'PM0082802',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'ZH8SK1PB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZH8SK1PB'
    },
    {
      status: 910,
      sku: 'PM0082803',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: '2T5TC5GK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2T5TC5GK'
    },
    {
      status: 910,
      sku: 'PM0082804',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'T29BGCZ1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T29BGCZ1'
    },
    {
      status: 810,
      sku: 'PM0082805',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349240',
      rtl_batch_array: '6349240',
      name_search: 'CHVIRGIN',
      name: 'Chionanthus virginicus',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.897,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7K2CV2D;8SLZH6JY;JYYA2V2E;ZY5JSVH8;P3LAVG2X;9W36NP98;N7TD4KX6;X9EKCA4X;NAJV1P77',
      statusContent: 'dot',
      hash: 'ZP57X4B9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP57X4B9'
    },
    {
      status: 810,
      sku: 'PM0075042',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302776',
      rtl_batch_array: '6350669, 6302776',
      name_search: 'MAMKCPLU',
      name: "Macleaya microcarpa 'Kelway's Coral Plume'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 93,
      chnn_stock_retail: 863,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4G6KXZG;YCHGGGCB',
      statusContent: 'dot',
      hash: 'P1BS6B7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1BS6B7X'
    },
    {
      status: 210,
      sku: 'PM0075335',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6294816',
      rtl_batch_array: '6378426, 6294816',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 297,
      chnn_stock_retail: 10227,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'GE8WW4DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GE8WW4DA'
    },
    {
      status: 810,
      sku: 'PM0082806',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349241',
      rtl_batch_array: '6349241',
      name_search: 'CHVIRGIN',
      name: 'Chionanthus virginicus',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7K2CV2D;8SLZH6JY;JYYA2V2E;ZY5JSVH8;P3LAVG2X;9W36NP98;N7TD4KX6;X9EKCA4X;NAJV1P77',
      statusContent: 'dot',
      hash: 'J4Z1TDNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4Z1TDNT'
    },
    {
      status: 210,
      sku: 'PM0075346',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6294820',
      rtl_batch_array: '6378455, 6294820',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 674,
      chnn_stock_retail: 9780,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: '5YHXAEVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YHXAEVC'
    },
    {
      status: 810,
      sku: 'PM0082807',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349242',
      rtl_batch_array: '6349242',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'VPS5B7T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPS5B7T6'
    },
    {
      status: 810,
      sku: 'PM0051496',
      core_name: 'Plant',
      sppl_ean: '8720664883369',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164893',
      rtl_batch_array: '6164893',
      name_search: 'RIASCHMI',
      name: "Ribes alpinum 'Schmidt'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVALV4JT',
      statusContent: 'dot',
      hash: 'YBPNNWYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBPNNWYN'
    },
    {
      status: 210,
      sku: 'PM0075041',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382280',
      rtl_batch_array: '6350648, 6382280, 6333272, 6340048',
      name_search: 'LEABDUNE',
      name: "Leymus arenarius 'Blue Dune'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2720,
      chnn_stock_retail: 10464,
      sppl_order_minimum: 3,
      sppl_prcp: 0.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4SB15NT;LK9B89KX',
      statusContent: 'dot',
      hash: 'YT28CBZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT28CBZ8'
    },
    {
      status: 210,
      sku: 'PM0075044',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6315059',
      rtl_batch_array: '6315059',
      name_search: 'PAVIRGAT',
      name: 'Panicum virgatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 918,
      chnn_stock_retail: 918,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '92KXLW3K',
      statusContent: 'dot',
      hash: 'S58274Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S58274Z5'
    },
    {
      status: 210,
      sku: 'PM0075045',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333303',
      rtl_batch_array: '6353253, 6333303',
      name_search: 'PULVPWHI',
      name: "Pulsatilla v. 'Pulsar White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2088,
      chnn_stock_retail: 2888,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BCX965B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BCX965B'
    },
    {
      status: 810,
      sku: 'PM0082808',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349243',
      rtl_batch_array: '6349243',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.458,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'DJLR757C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJLR757C'
    },
    {
      status: 210,
      sku: 'PM0075046',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353259',
      rtl_batch_array: '6353259',
      name_search: 'RAAPLENI',
      name: "Ranunculus aconitifolius 'Pleniflorus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JT1R3SRH',
      statusContent: 'dot',
      hash: 'WRTBHEV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRTBHEV9'
    },
    {
      status: 810,
      sku: 'PM0082809',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349244',
      rtl_batch_array: '6349244',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.429,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: '4TY79NP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TY79NP6'
    },
    {
      status: 810,
      sku: 'PM0082810',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349245',
      rtl_batch_array: '6349245',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'TYBJSHXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYBJSHXE'
    },
    {
      status: 210,
      sku: 'PM0075040',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350489',
      rtl_batch_array: '6350489',
      name_search: 'HOSBGOLD',
      name: "Hosta 'Band of Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7Y19JA4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y19JA4J'
    },
    {
      status: 210,
      sku: 'PM0075050',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353546',
      rtl_batch_array: '6353546, 6308677',
      name_search: 'VECMAMMU',
      name: "Vernonia crinita 'Mammuth'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 249,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZAX8WN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZAX8WN3'
    },
    {
      status: 210,
      sku: 'PM0001397',
      core_name: 'Plant',
      sppl_ean: '8720664872035',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161813',
      rtl_batch_array: '6161813',
      name_search: 'LEDSLADY',
      name: "Leucanthemum (S) 'Dwarf Snow Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '89GXNTEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89GXNTEJ'
    },
    {
      status: 210,
      sku: 'PM0080448',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337806',
      rtl_batch_array: '6337806',
      name_search: 'GELYDIA',
      name: 'Genista lydia',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 463,
      chnn_stock_retail: 463,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E1Y6EK5D;9XA5PG72;RJRHXYXZ;G6LBWK4V;8C94837E;6X3D2HRK',
      statusContent: 'dot',
      hash: '7W438R4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W438R4L'
    },
    {
      status: 210,
      sku: 'PM0080449',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337807',
      rtl_batch_array: '6337807',
      name_search: 'GEPVGOLD',
      name: "Genista pilosa 'Vancouver Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C2NXPSCZ;BYGS8WDY;JGGW9CJK;NC3VYC3C;EKS8TJWL',
      statusContent: 'dot',
      hash: '96WZ7WHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96WZ7WHE'
    },
    {
      status: 810,
      sku: 'PM0075048',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276700',
      rtl_batch_array: '6276700',
      name_search: 'ROPURPUR',
      name: 'Roscoea purpurea',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P78ZH8HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P78ZH8HZ'
    },
    {
      status: 210,
      sku: 'PM0075049',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276701',
      rtl_batch_array: '6276701',
      name_search: 'ROPAMARI',
      name: "Roscoea purpurea 'Ant Marian'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '27253ZYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27253ZYE'
    },
    {
      status: 210,
      sku: 'PM0075043',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353112',
      rtl_batch_array: '6353112',
      name_search: 'PLGABLUE',
      name: "Platycodon grandiflorus 'Astra Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AT13JB1J;AD2JE96R;TBAL85JV;VWY3TG9G;N4AV2DZ6;GC2RCA54',
      statusContent: 'dot',
      hash: '1586VXHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1586VXHZ'
    },
    {
      status: 210,
      sku: 'PM0075047',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6352701',
      rtl_batch_array: '6353173, 6352701',
      name_search: 'ROHENRIC',
      name: 'Rodgersia henrici',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 236,
      chnn_stock_retail: 619,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSWVB83G',
      statusContent: 'dot',
      hash: 'NZWHDCRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZWHDCRZ'
    },
    {
      status: 210,
      sku: 'PM0080450',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337808',
      rtl_batch_array: '6337808',
      name_search: 'HISHELEN',
      name: "Hibiscus syriacus 'Helene'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9BYG2Y1;D12X4H7H;N4HCBEZ9;6JNGYXYS;9SHL2WPN;E7RJRSKV;5JNTXEKG;PRCC7PE8',
      statusContent: 'dot',
      hash: 'SX1H6EC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SX1H6EC9'
    },
    {
      status: 910,
      sku: 'PM0082811',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COALTERN',
      name: 'Cornus alternifolia',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'VZWX2WE7',
      statusContent: 'dot',
      hash: '4W9PET91',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4W9PET91'
    },
    {
      status: 810,
      sku: 'PM0082812',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349249',
      rtl_batch_array: '6349249',
      name_search: 'COALTERN',
      name: 'Cornus alternifolia',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZWX2WE7',
      statusContent: 'dot',
      hash: 'HPPJ8LX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPPJ8LX4'
    },
    {
      status: 910,
      sku: 'PM0082813',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COALTERN',
      name: 'Cornus alternifolia',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore: 'VZWX2WE7',
      statusContent: 'dot',
      hash: 'R2X2LRWN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R2X2LRWN'
    },
    {
      status: 210,
      sku: 'PM0075337',
      core_name: 'Plant',
      sppl_ean: '8720664682191',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '5450832',
      rtl_batch_array: '6378439, 5450832',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 538,
      chnn_stock_retail: 2123,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'XWV2RGZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWV2RGZ8'
    },
    {
      status: 910,
      sku: 'PM0082814',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COALTERN',
      name: 'Cornus alternifolia',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: 'VZWX2WE7',
      statusContent: 'dot',
      hash: 'JSTY6DA7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JSTY6DA7'
    },
    {
      status: 210,
      sku: 'PM0075339',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6294817',
      rtl_batch_array: '6378448, 6294817',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 2170,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'CPP99PVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPP99PVH'
    },
    {
      status: 210,
      sku: 'PM0075340',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 2,
      btch_active_retail: '6378449',
      rtl_batch_array: '6378449, 6334292',
      name_search: 'BUMRUBY',
      name: "Buddleja 'Miss Ruby'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2769,
      chnn_stock_retail: 3069,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4LS1XKJ',
      statusContent: 'dot',
      hash: '2J5BVNWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2J5BVNWV'
    },
    {
      status: 910,
      sku: 'PM0082815',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'ZRKSGJCV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZRKSGJCV'
    },
    {
      status: 910,
      sku: 'PM0082816',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'RVYDZNX8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RVYDZNX8'
    },
    {
      status: 910,
      sku: 'PM0082817',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: '9N994SE3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9N994SE3'
    },
    {
      status: 210,
      sku: 'PM0075344',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294819',
      rtl_batch_array: '6294819',
      name_search: 'BUDPDELI',
      name: "Buddleja davidii 'Pink Delight'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 529,
      chnn_stock_retail: 529,
      sppl_prcp: 2.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PGHYTP2X;R5J96GZZ;JVSWL36K;Y2VATPX3;WSTNT2KL;X9WG23VP;PX3AJV8E;JWXSB79H;KJ1X3ZJS;NXCCS31T;6584G2R6',
      statusContent: 'dot',
      hash: '16AW1WTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16AW1WTZ'
    },
    {
      status: 910,
      sku: 'PM0082818',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: '6DK3BNT6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6DK3BNT6'
    },
    {
      status: 810,
      sku: 'PM0082819',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349256',
      rtl_batch_array: '6349256',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: '2TDZ1H13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TDZ1H13'
    },
    {
      status: 210,
      sku: 'PM0080451',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337809',
      rtl_batch_array: '6337809',
      name_search: 'HISLSTAN',
      name: "Hibiscus syriacus 'Lady Stanley'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7E4DLNYS',
      statusContent: 'dot',
      hash: 'RJEWYB97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJEWYB97'
    },
    {
      status: 210,
      sku: 'PM0080452',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337810',
      rtl_batch_array: '6337810',
      name_search: 'HISLSTAN',
      name: "Hibiscus syriacus 'Lady Stanley'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 575,
      chnn_stock_retail: 575,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7E4DLNYS',
      statusContent: 'dot',
      hash: 'D5JPWBL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5JPWBL1'
    },
    {
      status: 210,
      sku: 'PM0080453',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337811',
      rtl_batch_array: '6337811',
      name_search: 'HISMARIN',
      name: "Hibiscus syriacus 'Marina'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 722,
      chnn_stock_retail: 722,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69LD5BA3;4KY15DD1;J439RCSN;4WL845Y7;KCL3SGY8;8ARYR5SP;2GE451DS;945JC67P',
      statusContent: 'dot',
      hash: 'S4V3AG8X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4V3AG8X'
    },
    {
      status: 210,
      sku: 'PM0080454',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337812',
      rtl_batch_array: '6337812',
      name_search: 'HISMARIN',
      name: "Hibiscus syriacus 'Marina'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1420,
      chnn_stock_retail: 1420,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69LD5BA3;4KY15DD1;J439RCSN;4WL845Y7;KCL3SGY8;8ARYR5SP;2GE451DS;945JC67P',
      statusContent: 'dot',
      hash: 'XDWTAJL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDWTAJL5'
    },
    {
      status: 810,
      sku: 'PM0082820',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349257',
      rtl_batch_array: '6349257',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.448,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: 'HSSV4TY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSSV4TY2'
    },
    {
      status: 210,
      sku: 'PM0009438',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223372',
      rtl_batch_array: '6223372',
      name_search: 'SESSBLUT',
      name: "Sedum spurium 'Schorbuser Blut'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1318,
      chnn_stock_retail: 1318,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWL5GR5X;RW84AKZD;VNK7Z9LY;BDY77D19;7WVYE98T',
      statusContent: 'dot',
      hash: '6PTX3K4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PTX3K4T'
    },
    {
      status: 910,
      sku: 'PM0082821',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: '1TC36L9G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1TC36L9G'
    },
    {
      status: 910,
      sku: 'PM0018017',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'STBYZANT',
      name: 'Stachys byzantina',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore:
        '1CV8LTLG;TBP1W3JB;PHYN521K;WDCB8LY9;KPCCBH3P;RAJY27N3;64LP9ZBS;28RBC2XV;LBL5V6SD;AHNGGL28;XZ8K5AHG;GB2YHYLE;P32TXN73;KSSS28D1;HN5RBGET;9RR28RE3;J2SZ2T3S;SSYWVXGB;BNHC6KSB;ZJNDGAES;NYHPYAVJ',
      statusContent: 'dot',
      hash: 'ALSSYTNH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ALSSYTNH'
    },
    {
      status: 210,
      sku: 'PM0014859',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350387',
      rtl_batch_array: '5902363, 6301643, 6350387',
      name_search: 'HECAPPUC',
      name: "Heuchera 'Cappuccino'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1040,
      chnn_stock_retail: 1347,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4SSREV2D;XBEH4B97',
      statusContent: 'dot',
      hash: 'HBKD9LSL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBKD9LSL'
    },
    {
      status: 810,
      sku: 'PM0007016',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334778',
      rtl_batch_array: '6164874, 6334778',
      name_search: 'ROYFAIRY',
      name: 'Rosa (P) Yellow Fairy',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 80,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2GDBL24',
      statusContent: 'dot',
      hash: 'R77PGA73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R77PGA73'
    },
    {
      status: 210,
      sku: 'PM0080455',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337813',
      rtl_batch_array: '6337813',
      name_search: 'HISMELRO',
      name: "Hibiscus syriacus 'Melrose'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8B7J9K3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8B7J9K3R'
    },
    {
      status: 910,
      sku: 'PM0082822',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: '9TB5Z88X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9TB5Z88X'
    },
    {
      status: 210,
      sku: 'PM0080456',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337814',
      rtl_batch_array: '6337814',
      name_search: 'HISMELRO',
      name: "Hibiscus syriacus 'Melrose'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VBN46V46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBN46V46'
    },
    {
      status: 210,
      sku: 'PM0080457',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337815',
      rtl_batch_array: '6337815',
      name_search: 'HISPVARI',
      name: "Hibiscus syriacus 'Purpureus Variegatus'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5358DJKE;KBE59GDX;7YV9CX5N',
      statusContent: 'dot',
      hash: 'RED49C19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RED49C19'
    },
    {
      status: 210,
      sku: 'PM0080458',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337816',
      rtl_batch_array: '6337816',
      name_search: 'HISPVARI',
      name: "Hibiscus syriacus 'Purpureus Variegatus'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5358DJKE;KBE59GDX;7YV9CX5N',
      statusContent: 'dot',
      hash: 'L51RNA27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L51RNA27'
    },
    {
      status: 210,
      sku: 'PM0080459',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337817',
      rtl_batch_array: '6337817',
      name_search: 'BUDWBOUQ',
      name: "Buddleja davidii 'White Bouquet'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1955,
      chnn_stock_retail: 1955,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '678JS1GL;T8E9XXDY',
      statusContent: 'dot',
      hash: 'B9WPW7PX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9WPW7PX'
    },
    {
      status: 210,
      sku: 'PM0080460',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337818',
      rtl_batch_array: '6337818',
      name_search: 'CAJLEUCO',
      name: "Callicarpa japonica 'Leucocarpa'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RYZ6V3A5',
      statusContent: 'dot',
      hash: 'XKHS1BNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKHS1BNZ'
    },
    {
      status: 210,
      sku: 'PM0080461',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337819',
      rtl_batch_array: '6337819',
      name_search: 'CAKWANGT',
      name: 'Callicarpa kwangtungensis',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 281,
      chnn_stock_retail: 281,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VARLL86R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VARLL86R'
    },
    {
      status: 810,
      sku: 'PM0080462',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337820',
      rtl_batch_array: '6337820',
      name_search: 'CACRCLUS',
      name: "Callistemon citrinus 'Red Clusters'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DHT69YVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHT69YVE'
    },
    {
      status: 210,
      sku: 'PM0080463',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337821',
      rtl_batch_array: '6337821',
      name_search: 'CARUGULO',
      name: 'Callistemon rugulosus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPNHRSEW;CKA3R26H;94CKTVGV;HSYJEPL8;WS3S3PBS;4JCXD9C6;6H2YWPLD;H32KR65C;794DSZGS;54JTVEE3',
      statusContent: 'dot',
      hash: '5PG16J8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PG16J8Z'
    },
    {
      status: 810,
      sku: 'PM0080464',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337822',
      rtl_batch_array: '6337822',
      name_search: 'CALVLJOH',
      name: "Callistemon viminalis 'Little John'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SK87WXLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK87WXLY'
    },
    {
      status: 210,
      sku: 'PM0000564',
      core_name: 'Plant',
      sppl_ean: '8720664669291',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5534229',
      rtl_batch_array: '5534229',
      name_search: 'ROCRICKE',
      name: 'Rosa (M) Cricket',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_order_minimum: 3,
      sppl_prcp: 3.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XH9SXS5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH9SXS5X'
    },
    {
      status: 210,
      sku: 'PM0008718',
      core_name: 'Plant',
      sppl_ean: '8720349473366',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439480',
      rtl_batch_array: '5439480',
      name_search: 'ROSIMEID',
      name: 'Rosa (H) Ivory Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 3530,
      chnn_stock_retail: 3530,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZE7X42TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZE7X42TP'
    },
    {
      status: 210,
      sku: 'PM0080465',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337823',
      rtl_batch_array: '6337823',
      name_search: 'CAMASOTT',
      name: "Callistemon 'Masotti'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XA6RBCRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA6RBCRV'
    },
    {
      status: 210,
      sku: 'PM0080466',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337824',
      rtl_batch_array: '6337824',
      name_search: 'CARROCKE',
      name: "Callistemon 'Red Rocket'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2380,
      chnn_stock_retail: 2380,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7ZYVRYHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZYVRYHK'
    },
    {
      status: 210,
      sku: 'PM0080467',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337825',
      rtl_batch_array: '6337825',
      name_search: 'CARIGIDU',
      name: 'Callistemon rigidus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HE4C3XGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HE4C3XGT'
    },
    {
      status: 210,
      sku: 'PM0080468',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337826',
      rtl_batch_array: '6337826',
      name_search: 'CAFLAEVI',
      name: 'Calycanthus floridus laevigatus',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 774,
      chnn_stock_retail: 774,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2P56BA4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2P56BA4L'
    },
    {
      status: 810,
      sku: 'PM0080469',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337827',
      rtl_batch_array: '6337827',
      name_search: 'CARFLAME',
      name: "Campsis radicans 'Flamenco'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 3.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C7425PY5;SR2G1LHW;LHXVR7H7',
      statusContent: 'dot',
      hash: 'D6KYHTY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6KYHTY4'
    },
    {
      status: 210,
      sku: 'PM0080470',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337828',
      rtl_batch_array: '6337828',
      name_search: 'CARFLAVA',
      name: "Campsis radicans 'Flava'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 345,
      chnn_stock_retail: 345,
      sppl_prcp: 3.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JCHVEJWX;955JNY8S;NAVWW5X8;LRPCG31B;L7R7ZV3X;YJWS76ZG',
      statusContent: 'dot',
      hash: '7A9ZPNXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7A9ZPNXT'
    },
    {
      status: 210,
      sku: 'PM0080471',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337829',
      rtl_batch_array: '6337829',
      name_search: 'CAARBORE',
      name: 'Caragana arborescens',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XZP5V315',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZP5V315'
    },
    {
      status: 210,
      sku: 'PM0080472',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337831',
      rtl_batch_array: '6337831',
      name_search: 'CACMCHOC',
      name: 'Carex comans Milk Chocolate',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S3EW74WS;X68BP5D6;R6B1T55X;CGTAEHYE',
      statusContent: 'dot',
      hash: 'XDP5B6LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XDP5B6LK'
    },
    {
      status: 210,
      sku: 'PM0080473',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337832',
      rtl_batch_array: '6337832',
      name_search: 'CAEAUREA',
      name: "Carex elata 'Aurea'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 542,
      chnn_stock_retail: 542,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y1S1ZZCY;8XWGT7J2;CEW5P27X;PNA47LHL;BENBS2PP;RL5BRP8T;P5HP6ZVZ;C1XYE8AZ;4RBPLB7S;4T6EA97Y;NVSHJL7A;VX6EY79P',
      statusContent: 'dot',
      hash: 'V5ZYZTDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5ZYZTDS'
    },
    {
      status: 210,
      sku: 'PM0082823',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385529',
      rtl_batch_array: '6349260, 6385529',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 380,
      chnn_stock_retail: 430,
      sppl_prcp: 4.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'KDR9ACAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDR9ACAL'
    },
    {
      status: 810,
      sku: 'PM0082824',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349261',
      rtl_batch_array: '6349261',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'GS3DT1BG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS3DT1BG'
    },
    {
      status: 810,
      sku: 'PM0082825',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349262',
      rtl_batch_array: '6349262',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 13.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: '87JNSW4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87JNSW4K'
    },
    {
      status: 810,
      sku: 'PM0082826',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349263',
      rtl_batch_array: '6349263',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'TS63SRBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TS63SRBW'
    },
    {
      status: 910,
      sku: 'PM0082827',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: '1CZ475AG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1CZ475AG'
    },
    {
      status: 910,
      sku: 'PM0082828',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: '558161SL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '558161SL'
    },
    {
      status: 910,
      sku: 'PM0082829',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COMAS',
      name: 'Cornus mas',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'G83AC3BR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G83AC3BR'
    },
    {
      status: 810,
      sku: 'PM0082830',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349267',
      rtl_batch_array: '6349267',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'A2ELE5ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2ELE5ZJ'
    },
    {
      status: 910,
      sku: 'PM0082831',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COOFFICI',
      name: 'Cornus officinalis',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: '6KC3EA68',
      statusContent: 'dot',
      hash: 'PYTLSNNZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PYTLSNNZ'
    },
    {
      status: 910,
      sku: 'PM0082832',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COOFFICI',
      name: 'Cornus officinalis',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore: '6KC3EA68',
      statusContent: 'dot',
      hash: 'S77Z9KYR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S77Z9KYR'
    },
    {
      status: 810,
      sku: 'PM0082833',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349270',
      rtl_batch_array: '6349270',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'K8W9S9W7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8W9S9W7'
    },
    {
      status: 810,
      sku: 'PM0082834',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349271',
      rtl_batch_array: '6349271',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: '7PW2JA6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PW2JA6W'
    },
    {
      status: 910,
      sku: 'PM0082835',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DAINVOLU',
      name: 'Davidia involucrata',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: 'JLRXRGKY',
      statusContent: 'dot',
      hash: 'DKCJCZSC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DKCJCZSC'
    },
    {
      status: 910,
      sku: 'PM0082836',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DAINVOLU',
      name: 'Davidia involucrata',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore: 'JLRXRGKY',
      statusContent: 'dot',
      hash: '6P4791LK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6P4791LK'
    },
    {
      status: 810,
      sku: 'PM0082837',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6385539',
      rtl_batch_array: '6385539',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 4.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'K71BEL68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K71BEL68'
    },
    {
      status: 910,
      sku: 'PM0082838',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'C1V7E2SJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C1V7E2SJ'
    },
    {
      status: 910,
      sku: 'PM0082839',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'N91337CX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N91337CX'
    },
    {
      status: 910,
      sku: 'PM0082840',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'Z4JY4EPB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z4JY4EPB'
    },
    {
      status: 910,
      sku: 'PM0082841',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EXBRIDE',
      name: "Exochorda 'The Bride'",
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore:
        'V8PN9B5S;TCEC1LGX;H5DCBSA8;NJB8R34P;45T8J833;S9K5D3BJ;JD4LAR76',
      statusContent: 'dot',
      hash: '68TAC855',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '68TAC855'
    },
    {
      status: 810,
      sku: 'PM0082842',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349280',
      rtl_batch_array: '6349280',
      name_search: 'EXBRIDE',
      name: "Exochorda 'The Bride'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.014,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V8PN9B5S;TCEC1LGX;H5DCBSA8;NJB8R34P;45T8J833;S9K5D3BJ;JD4LAR76',
      statusContent: 'dot',
      hash: 'JNPSZZ14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNPSZZ14'
    },
    {
      status: 810,
      sku: 'PM0082843',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349281',
      rtl_batch_array: '6349281',
      name_search: 'FASDAWYC',
      name: "Fagus sylvatica 'Dawyck'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.994,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ELR1N3DP',
      statusContent: 'dot',
      hash: '2TXGAYVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TXGAYVY'
    },
    {
      status: 910,
      sku: 'PM0082844',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASDAWYC',
      name: "Fagus sylvatica 'Dawyck'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: 'ELR1N3DP',
      statusContent: 'dot',
      hash: 'BDK4LYWV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BDK4LYWV'
    },
    {
      status: 910,
      sku: 'PM0082845',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASDAWYC',
      name: "Fagus sylvatica 'Dawyck'",
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore: 'ELR1N3DP',
      statusContent: 'dot',
      hash: 'AYZXL4CC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AYZXL4CC'
    },
    {
      status: 810,
      sku: 'PM0082846',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349284',
      rtl_batch_array: '6349284',
      name_search: 'FASDGOLD',
      name: "Fagus sylvatica 'Dawyck Gold'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '998N8CN7;C4YP35DD;XKLY27B5',
      statusContent: 'dot',
      hash: 'RGNLJ7ZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGNLJ7ZP'
    },
    {
      status: 810,
      sku: 'PM0082847',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349285',
      rtl_batch_array: '6349285',
      name_search: 'FASDGOLD',
      name: "Fagus sylvatica 'Dawyck Gold'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.994,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '998N8CN7;C4YP35DD;XKLY27B5',
      statusContent: 'dot',
      hash: 'WHW9RCAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHW9RCAC'
    },
    {
      status: 910,
      sku: 'PM0082848',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASDGOLD',
      name: "Fagus sylvatica 'Dawyck Gold'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: '998N8CN7;C4YP35DD;XKLY27B5',
      statusContent: 'dot',
      hash: 'XKN33HHX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XKN33HHX'
    },
    {
      status: 910,
      sku: 'PM0082849',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASDGOLD',
      name: "Fagus sylvatica 'Dawyck Gold'",
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore: '998N8CN7;C4YP35DD;XKLY27B5',
      statusContent: 'dot',
      hash: '25DAAKPW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '25DAAKPW'
    },
    {
      status: 910,
      sku: 'PM0082850',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'Z62W3E5H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z62W3E5H'
    },
    {
      status: 810,
      sku: 'PM0082851',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349289',
      rtl_batch_array: '6349289',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'NAGRB1JA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAGRB1JA'
    },
    {
      status: 810,
      sku: 'PM0082852',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349290',
      rtl_batch_array: '6349290',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.994,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'DC9NRWP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC9NRWP1'
    },
    {
      status: 810,
      sku: 'PM0082853',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349291',
      rtl_batch_array: '6349291',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.695,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: '1WEA1T3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WEA1T3J'
    },
    {
      status: 810,
      sku: 'PM0082854',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349292',
      rtl_batch_array: '6349292',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'KSDDVTV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSDDVTV4'
    },
    {
      status: 810,
      sku: 'PM0082855',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349293',
      rtl_batch_array: '6349293',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'K2SYB5CK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2SYB5CK'
    },
    {
      status: 910,
      sku: 'PM0082856',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASPENDU',
      name: "Fagus sylvatica 'Pendula'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore: 'L2EG3Z5X;CSYHP2LL;YW2BPPG1',
      statusContent: 'dot',
      hash: 'AKX5E1X4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AKX5E1X4'
    },
    {
      status: 810,
      sku: 'PM0082857',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349295',
      rtl_batch_array: '6349295',
      name_search: 'FASPENDU',
      name: "Fagus sylvatica 'Pendula'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.628,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2EG3Z5X;CSYHP2LL;YW2BPPG1',
      statusContent: 'dot',
      hash: 'YKH25KP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKH25KP1'
    },
    {
      status: 810,
      sku: 'PM0082858',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349297',
      rtl_batch_array: '6349297',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: '6DV9JAAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DV9JAAT'
    },
    {
      status: 810,
      sku: 'PM0082859',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349298',
      rtl_batch_array: '6349298',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'WC8G25SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC8G25SG'
    },
    {
      status: 810,
      sku: 'PM0082860',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349299',
      rtl_batch_array: '6349299',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '200250KL',
      rng_range_identifier: 'H200250RB',
      rng_range_description: 'H200 cm 250 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'TST2VKJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TST2VKJL'
    },
    {
      status: 810,
      sku: 'PM0082861',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349300',
      rtl_batch_array: '6349300',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'YN3H11WJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN3H11WJ'
    },
    {
      status: 910,
      sku: 'PM0082862',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'HYC85CNJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HYC85CNJ'
    },
    {
      status: 810,
      sku: 'PM0082863',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349302',
      rtl_batch_array: '6349302',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.379,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'SEERP52A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEERP52A'
    },
    {
      status: 210,
      sku: 'PM0082864',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385600',
      rtl_batch_array: '6349303, 6385600',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 450,
      chnn_stock_retail: 500,
      sppl_prcp: 3.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'DDDWPNR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDDWPNR3'
    },
    {
      status: 810,
      sku: 'PM0082865',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349304',
      rtl_batch_array: '6349304',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.065,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '9G1925G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9G1925G5'
    },
    {
      status: 810,
      sku: 'PM0082866',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349306',
      rtl_batch_array: '6349306',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'V8W6CHCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8W6CHCZ'
    },
    {
      status: 810,
      sku: 'PM0082867',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349314',
      rtl_batch_array: '6349314, 6358845',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 200,
      sppl_prcp: 13.587,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'SXT6T3TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXT6T3TY'
    },
    {
      status: 910,
      sku: 'PM0051703',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIPNEWPO',
      name: "Viburnum plicatum 'Newport'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      imageCore: 'TKT96XKL',
      statusContent: 'dot',
      hash: 'V3Y2VHZP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V3Y2VHZP'
    },
    {
      status: 210,
      sku: 'PM0080486',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337847',
      rtl_batch_array: '6337847',
      name_search: 'CECASCAD',
      name: "Ceanothus 'Cascade'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7LVATJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7LVATJH'
    },
    {
      status: 910,
      sku: 'PM0072545',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAALBUM',
      name: 'Lamium album',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '6KSBBDN5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6KSBBDN5'
    },
    {
      status: 910,
      sku: 'PM0072542',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRSCBROT',
      name: "Iris sibirica 'Caesar's Brother'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'LXD2E2JZ;JXZEJGHL;EKZB5KTT;VV61W6L5;6S39A557;VE1RJ542;HSL1BHH6;C66VRA2Y',
      statusContent: 'dot',
      hash: 'VSNX7N4D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VSNX7N4D'
    },
    {
      status: 210,
      sku: 'PM0072572',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253112',
      rtl_batch_array: '6253112',
      name_search: 'HEWMIST',
      name: "Hebe 'Wiri Mist'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 921,
      chnn_stock_retail: 921,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWSVY9XB;HG5VTGLT;DB65BBT1;N7P8YCNS;RNBYGVVD;934SA3RH;5GSPTHDS;G3X39GT6;R8YWH5RT',
      statusContent: 'dot',
      hash: 'CDHEEC9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDHEEC9V'
    },
    {
      status: 210,
      sku: 'PM0051693',
      core_name: 'Plant',
      sppl_ean: '8720349411757',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2729610',
      rtl_batch_array: '2729610',
      name_search: 'CACBFORM',
      name: "Carex comans 'Bronze Form'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 471,
      chnn_stock_retail: 471,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DETA2LLY;SLTRBH5C;GKB8WGT4;WYSS85WR;1TCTS9E8;Z7J2NP88;L5BBAJX3;V4XGJG8R;JVTX6T7A;9G91XD8D;H5GRDS1K;VBW63X7B;71XCG96S;D4SK9DZV;CYR4YT14;GSSBA74Y;HWW9956Z',
      imageBatch: 'VAW868R3',
      statusContent: 'dot',
      hash: 'D31BZC7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D31BZC7P'
    },
    {
      status: 210,
      sku: 'PM0072564',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253092',
      rtl_batch_array: '6253092',
      name_search: 'FUMPOPPL',
      name: "Fuchsia 'Mrs Popple'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VGDRJV5;BBJY8TSG;4CH9KLHZ;B5TL1J18;K384G5X3',
      statusContent: 'dot',
      hash: 'BVYDWWHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVYDWWHE'
    },
    {
      status: 210,
      sku: 'PM0018004',
      core_name: 'Plant',
      sppl_ean: '8720664683587',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5340426',
      rtl_batch_array: '5340426',
      name_search: 'CAMGREEN',
      name: "Carex 'Magic Green'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.516,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'GH5GWAD9',
      statusContent: 'dot',
      hash: 'W9BXN7ZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9BXN7ZK'
    },
    {
      status: 810,
      sku: 'PM0072579',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272872',
      rtl_batch_array: '6272872',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      statusContent: 'dot',
      hash: '482N4ZBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '482N4ZBJ'
    },
    {
      status: 210,
      sku: 'PM0072589',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307446',
      rtl_batch_array: '6307446',
      name_search: 'FABALDSC',
      name: 'Fallopia baldschuanica',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1611,
      chnn_stock_retail: 1611,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NG4RBY9A;TZ3PRT9X;YV2VY1D4;79WVNGHK;JE4ETSWB;RCJ786DN;XPC2CAZP;9B6C61X5',
      statusContent: 'dot',
      hash: 'CV5Z7JVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV5Z7JVZ'
    },
    {
      status: 810,
      sku: 'PM0072608',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307688',
      rtl_batch_array: '6307688',
      name_search: 'TRATHETA',
      name: "Trachelospermum asiaticum 'Theta'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EPJ96RR9;LY8YDB8E;VNDTWR4S;LH8LXP84',
      statusContent: 'dot',
      hash: 'CXKT4A32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXKT4A32'
    },
    {
      status: 210,
      sku: 'PM0072530',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252840',
      rtl_batch_array: '6252840',
      name_search: 'ARGFOWL',
      name: 'Aruncus Guinea Fowl',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 353,
      chnn_stock_retail: 353,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z966ZN8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z966ZN8N'
    },
    {
      status: 210,
      sku: 'PM0072532',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252846',
      rtl_batch_array: '6252846',
      name_search: 'CRPRINCE',
      name: "Crocosmia 'Princess'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EZXPWN67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZXPWN67'
    },
    {
      status: 210,
      sku: 'PM0000914',
      core_name: 'Plant',
      sppl_ean: '8720349438310',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5350030',
      rtl_batch_array: '5350030',
      name_search: 'VIMVARIE',
      name: "Vinca major 'Variegata'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1773,
      chnn_stock_retail: 1773,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZBSSC1Y;GXX15NG3;2BRXA81Y;8DX5YJZH;Y6RE5CYA',
      imageBatch: 'KJESD8KP',
      statusContent: 'dot',
      hash: 'HJLSAHG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJLSAHG8'
    },
    {
      status: 210,
      sku: 'PM0072616',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307270',
      rtl_batch_array: '6307270',
      name_search: 'ACKOLOMI',
      name: 'Actinidia kolomikta',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1984,
      chnn_stock_retail: 1984,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EBYDKRXR;KYS7K31K;S6RX6TGJ;974N3855',
      statusContent: 'dot',
      hash: 'N2GZ43K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2GZ43K2'
    },
    {
      status: 210,
      sku: 'PM0022275',
      core_name: 'Plant',
      sppl_ean: '8720349424542',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5342385',
      rtl_batch_array: '5342385',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2732,
      chnn_stock_retail: 2732,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      imageBatch: 'EWX2C5YB',
      statusContent: 'dot',
      hash: 'NL64B3TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL64B3TB'
    },
    {
      status: 210,
      sku: 'PM0080487',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337848',
      rtl_batch_array: '6337848',
      name_search: 'CEDELIGH',
      name: "Ceanothus 'Delight'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6EZ567GA;4RVEEEWB;L29D5PCZ;YJB3K1YN;JA8PXKX6;17ZV2P5C;KCLCEAVR;ZSKEL2GD;1HXPDW6E;29GD46EC',
      statusContent: 'dot',
      hash: 'P4LWWT9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4LWWT9N'
    },
    {
      status: 210,
      sku: 'PM0080488',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337849',
      rtl_batch_array: '6337849',
      name_search: 'CEDHDEFO',
      name: "Ceanothus delilianus 'Henri D\u00e9foss\u00e9'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K957WB19;7B3BSKP8;PC98D382',
      statusContent: 'dot',
      hash: 'DCEWW91R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCEWW91R'
    },
    {
      status: 210,
      sku: 'PM0080489',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337850',
      rtl_batch_array: '6337850',
      name_search: 'CEEDORAD',
      name: 'Ceanothus El Dorado',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1820,
      chnn_stock_retail: 1820,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GA8NSV6Y;21NXWEXH;2K9YD2ZP;KVDY4PLY;V184987Y;HC24YLNA;2GXHJKYH',
      statusContent: 'dot',
      hash: 'TDZY92CN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDZY92CN'
    },
    {
      status: 210,
      sku: 'PM0051695',
      core_name: 'Plant',
      sppl_ean: '8720626300125',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4673005',
      rtl_batch_array: '4673005',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.426,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      imageBatch: 'XJ3N69AS',
      statusContent: 'dot',
      hash: 'RJNEKNYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJNEKNYH'
    },
    {
      status: 210,
      sku: 'PM0019623',
      core_name: 'Plant',
      sppl_ean: '8720664863453',
      btch_manufacturer: 268,
      rtl_batch_array_total: 6,
      btch_active_retail: '6083850',
      rtl_batch_array: '6083850, 6220373, 4673006, 5837423, 6254723, 6336142',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3030,
      chnn_stock_retail: 8424,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      statusContent: 'dot',
      hash: 'YS4TXX9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS4TXX9E'
    },
    {
      status: 210,
      sku: 'PM0072537',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252854',
      rtl_batch_array: '6252854',
      name_search: 'DIRLREV',
      name: 'Dianella revoluta Little Rev',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JCYZADPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCYZADPR'
    },
    {
      status: 210,
      sku: 'PM0072618',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307284',
      rtl_batch_array: '6307284',
      name_search: 'ARMACROP',
      name: 'Aristolochia macrophylla',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 10336,
      chnn_stock_retail: 10336,
      sppl_order_minimum: 3,
      sppl_prcp: 7.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CPEX61KD;EJ4JD5WN;JJDJHYB2;6NER36TD;ZKBVW7BE;82N8W972;GLY77JE2;6L2AAV36;1NKZ3VN2;BP1ZDXGH',
      statusContent: 'dot',
      hash: 'JVGVT57T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVGVT57T'
    },
    {
      status: 910,
      sku: 'PM0072535',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEDENUDA',
      name: 'Cenolophium denudatum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '84S1K6C8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '84S1K6C8'
    },
    {
      status: 210,
      sku: 'PM0011167',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6363177',
      rtl_batch_array: '5303763, 6349606, 6229879, 6363177',
      name_search: 'CALTPALU',
      name: 'Caltha palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 1765,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5E557STD;LER7VGZX;WAHKZ3EK;Z2TRRB83;PBASWEEX;9T81RC1S;81RVW7DG;K9235JLW;JAG97LNA;DW9R2LHB;AXS7V2V2;DHY6TZGR;1W4BJLBT;WE1HN693',
      statusContent: 'dot',
      hash: 'ANJWVLK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANJWVLK8'
    },
    {
      status: 210,
      sku: 'PM0025260',
      core_name: 'Plant',
      sppl_ean: '8720349408931',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '4700772',
      rtl_batch_array: '6117722, 4700772',
      name_search: 'ALMOLLIS',
      name: 'Alchemilla mollis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 23118,
      chnn_stock_retail: 24118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EHLKNB2V;CK9A2PG8;R2SRHX1J;J9RBBHEJ;HCWV9H57;NJDA177T;REXB3W18;N1JS3HZA;4VV1D59B;5RL49APS;JXHWCWSD;JZ5XW2K7;4K3P7PLL;E31H5L6W;KK56BZ8H;J1PS1T6J;YJ9B9V15;7H4ANG7G;ES93584N;C5Z4N6KL;VPHGPC3X;CTZCKHSA;1C75XRN8;1856E8BZ',
      imageBatch: 'YC6RBJ33',
      statusContent: 'dot',
      hash: 'ZJ7KYSW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ7KYSW4'
    },
    {
      status: 210,
      sku: 'PM0072536',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252852',
      rtl_batch_array: '6252852',
      name_search: 'COFIREFL',
      name: "Coreopsis 'Firefly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7J9BYC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7J9BYC7'
    },
    {
      status: 210,
      sku: 'PM0027556',
      core_name: 'Plant',
      sppl_ean: '8720349406340',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '2990767',
      rtl_batch_array: '2990767, 5905565',
      name_search: 'MISLZEBR',
      name: "Miscanthus sinensis 'Little Zebra'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 452,
      chnn_stock_retail: 740,
      sppl_prcp: 2.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BAH38TT2;TDP9N3BL;GEC3B53L;NE7R6WX4;2AHZS4T1;EL6R5VD1;W65LCK79;1DBC5DWS;67CSE5S5;XSGL1AJS;L3DRCPG9;L8KKHVC4;T48CSEGE;RJX7NYN5;1TJGJKHR;E38CYGAD;G78WEZ1E;9DV7SS41;2PW7E9K7;94Z37G9N;CE5TD7DG;2E3EBBTE;DPB312PR;JT23LDD1;VBC6AEA5;APXLE99T',
      imageBatch: 'K63YGRSH',
      statusContent: 'dot',
      hash: 'AP4YW7TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AP4YW7TB'
    },
    {
      status: 210,
      sku: 'PM0051694',
      core_name: 'Plant',
      sppl_ean: '8720664683563',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4391629',
      rtl_batch_array: '4391629',
      name_search: 'CAFFALLS',
      name: "Carex 'Feather Falls'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SWTEBKB2;L2483766;SP761YTY;76N9NLAJ;CY3EZYXS;GN3N9S8T;PTBBRL6A;4WP8RTZK;PY2L3NWL;56GRS1YN;VVVTBCA6;4S3RAXZ6;54D1RL41;SKA1G2BS',
      imageBatch: 'XWDGAHAJ',
      statusContent: 'dot',
      hash: '47HEN7AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47HEN7AT'
    },
    {
      status: 210,
      sku: 'PM0072531',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252845',
      rtl_batch_array: '6252845',
      name_search: 'CRNCANAR',
      name: "Crocosmia 'Norwich Canary'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N5AHZ9Y3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5AHZ9Y3'
    },
    {
      status: 210,
      sku: 'PM0019091',
      core_name: 'Plant',
      sppl_ean: '8720664867789',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084715',
      rtl_batch_array: '6084715, 6153399, 5303684',
      name_search: 'HESLEUCH',
      name: "Heuchera sanguinea 'Leuchtk\u00e4fer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12822,
      chnn_stock_retail: 16090,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AA5W3RGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA5W3RGY'
    },
    {
      status: 810,
      sku: 'PM0000790',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291784',
      rtl_batch_array: '6291784',
      name_search: 'ACFCGOLD',
      name: "Achillea filipendulina 'Cloth of Gold'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JZPNA69A;Z73TXG24;L9JAL6CT;JCH6YLB4;GA3L3B3E;9LTH5DBB;C22RLHXX;LTY8BPE8;VPDA68YK;GYHJ9A7L;YPGZJ4XT',
      statusContent: 'dot',
      hash: 'D8Z5X9PX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8Z5X9PX'
    },
    {
      status: 210,
      sku: 'PM0072539',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302515',
      rtl_batch_array: '6252858, 6302515',
      name_search: 'FIKAHOME',
      name: "Filipendula 'Kahome'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 266,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LEHPB7Y9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEHPB7Y9'
    },
    {
      status: 210,
      sku: 'PM0072541',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252866',
      rtl_batch_array: '6252866',
      name_search: 'HEBLOVE',
      name: "Hemerocallis 'Burgundy Love'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VD13ST8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD13ST8T'
    },
    {
      status: 210,
      sku: 'PM0080490',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337851',
      rtl_batch_array: '6337851',
      name_search: 'CEEBROWN',
      name: "Ceanothus 'Emily Brown'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1520,
      chnn_stock_retail: 1520,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8H8T3DHZ;133B5ZJX;STJY4696;RCNHZGGZ;9EYHPT6K;Z8XZK9C7',
      statusContent: 'dot',
      hash: '72YWCCCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72YWCCCY'
    },
    {
      status: 210,
      sku: 'PM0072548',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252889',
      rtl_batch_array: '6252889',
      name_search: 'WAJAPONI',
      name: 'Wasabia japonica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 161,
      chnn_stock_retail: 161,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N12H43K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N12H43K5'
    },
    {
      status: 210,
      sku: 'PM0072549',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253057',
      rtl_batch_array: '6253057',
      name_search: 'BRSUNSHI',
      name: "Brachyglottis 'Sunshine'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 339,
      chnn_stock_retail: 339,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJGNTNB2',
      statusContent: 'dot',
      hash: 'GSB1ZJKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSB1ZJKY'
    },
    {
      status: 210,
      sku: 'PM0072550',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253059',
      rtl_batch_array: '6253059',
      name_search: 'BUDAQUEE',
      name: "Buddleja davidii 'African Queen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KGLEE25N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGLEE25N'
    },
    {
      status: 910,
      sku: 'PM0051701',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'AR2TA5BS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AR2TA5BS'
    },
    {
      status: 810,
      sku: 'PM0072551',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253061',
      rtl_batch_array: '6253061',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: '719VLHK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '719VLHK2'
    },
    {
      status: 810,
      sku: 'PM0072552',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253063',
      rtl_batch_array: '6253063',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'KAGR58XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAGR58XP'
    },
    {
      status: 210,
      sku: 'PM0072554',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253068',
      rtl_batch_array: '6253068',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1046,
      chnn_stock_retail: 1046,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: 'XAHPLZKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAHPLZKP'
    },
    {
      status: 210,
      sku: 'PM0072555',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253071',
      rtl_batch_array: '6253071, 6336219',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 7064,
      chnn_stock_retail: 8864,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      statusContent: 'dot',
      hash: 'EEBS58W8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEBS58W8'
    },
    {
      status: 210,
      sku: 'PM0072556',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253080',
      rtl_batch_array: '6253080',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 13.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'HEJ7GA97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEJ7GA97'
    },
    {
      status: 210,
      sku: 'PM0072557',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253081',
      rtl_batch_array: '6253081',
      name_search: 'ERDDDALE',
      name: "Erica darleyensis 'Darley Dale'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2677,
      chnn_stock_retail: 2677,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZSHR86B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSHR86B8'
    },
    {
      status: 210,
      sku: 'PM0072559',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253083',
      rtl_batch_array: '6253083',
      name_search: 'ERDWPERF',
      name: "Erica darleyensis 'White Perfection'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3049,
      chnn_stock_retail: 3049,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6Y8LH6PH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Y8LH6PH'
    },
    {
      status: 210,
      sku: 'PM0072560',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253084',
      rtl_batch_array: '6253084',
      name_search: 'FUCSMEDL',
      name: "Fuchsia 'Celia Smedley'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R6SCP7V7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6SCP7V7'
    },
    {
      status: 210,
      sku: 'PM0072562',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253086',
      rtl_batch_array: '6253086',
      name_search: 'FUHANN',
      name: "Fuchsia 'Heidi Ann'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KW7N1K17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW7N1K17'
    },
    {
      status: 810,
      sku: 'PM0072563',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272818',
      rtl_batch_array: '6272818',
      name_search: 'FUMCORNE',
      name: "Fuchsia 'Madame Cornelissen'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7V1GVRRN;VW4HZ6JG;NDHD7Y8X;H1RB9BTV;BJE6PN6C;79APZY56;ERKSB6PT;PSXVLKBJ',
      statusContent: 'dot',
      hash: 'STWPE8L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STWPE8L1'
    },
    {
      status: 810,
      sku: 'PM0082868',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349317',
      rtl_batch_array: '6349317, 6358847',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 150,
      sppl_prcp: 37.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'EJK75H9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJK75H9N'
    },
    {
      status: 210,
      sku: 'PM0072566',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253097',
      rtl_batch_array: '6253097',
      name_search: 'HEONZGOL',
      name: "Hebe odora 'New Zealand Gold'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1330,
      chnn_stock_retail: 1330,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BRX9DK9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRX9DK9D'
    },
    {
      status: 210,
      sku: 'PM0080491',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337852',
      rtl_batch_array: '6337852',
      name_search: 'CEIMPRES',
      name: 'Ceanothus impressus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KYCJT44V',
      statusContent: 'dot',
      hash: '62ATCY44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62ATCY44'
    },
    {
      status: 210,
      sku: 'PM0072568',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253101',
      rtl_batch_array: '6253101',
      name_search: 'HEKIRKII',
      name: "Hebe 'Kirkii'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 728,
      chnn_stock_retail: 728,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X34T4LRR',
      statusContent: 'dot',
      hash: 'ZKV3WHSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKV3WHSK'
    },
    {
      status: 810,
      sku: 'PM0072547',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252886',
      rtl_batch_array: '6252886',
      name_search: 'RAACRIS',
      name: 'Ranunculus acris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 91,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '45S66E86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45S66E86'
    },
    {
      status: 210,
      sku: 'PM0072570',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253109',
      rtl_batch_array: '6253109',
      name_search: 'HESAPPHI',
      name: "Hebe 'Sapphire'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6W8LYD3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W8LYD3Y'
    },
    {
      status: 210,
      sku: 'PM0072571',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253111',
      rtl_batch_array: '6253111',
      name_search: 'HEWJOY',
      name: "Hebe 'Wiri Joy'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 794,
      chnn_stock_retail: 794,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8DAH7K2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DAH7K2K'
    },
    {
      status: 210,
      sku: 'PM0072558',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253082',
      rtl_batch_array: '6253082',
      name_search: 'ERDKROTE',
      name: "Erica darleyensis 'Kramer's Rote'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2408,
      chnn_stock_retail: 2408,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SLBL71PR',
      statusContent: 'dot',
      hash: 'P346WWBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P346WWBB'
    },
    {
      status: 810,
      sku: 'PM0072613',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307252',
      rtl_batch_array: '6307252',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 21.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'JK4H1B6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JK4H1B6G'
    },
    {
      status: 210,
      sku: 'PM0072576',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253123',
      rtl_batch_array: '6253123, 6287689',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1234,
      chnn_stock_retail: 1256,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: '5BJV49HB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BJV49HB'
    },
    {
      status: 810,
      sku: 'PM0072612',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6281307',
      rtl_batch_array: '6281307, 6307251',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 112,
      sppl_prcp: 14.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: '4A252Y1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4A252Y1D'
    },
    {
      status: 810,
      sku: 'PM0082869',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349318',
      rtl_batch_array: '6349318',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.004,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: '45ZGGTKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45ZGGTKX'
    },
    {
      status: 210,
      sku: 'PM0072582',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307396',
      rtl_batch_array: '6307396',
      name_search: 'DILOTUS',
      name: 'Diospyros lotus',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5C33Z11X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5C33Z11X'
    },
    {
      status: 910,
      sku: 'PM0072583',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345481',
      rtl_batch_array: '6345481',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: '060080C20',
      rng_range_identifier: 'H060080C20',
      rng_range_description: 'H60 cm 80 cm C20',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 23.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: '2ZSC3SH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZSC3SH4'
    },
    {
      status: 210,
      sku: 'PM0080474',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337835',
      rtl_batch_array: '6337835',
      name_search: 'CAMEEVER',
      name: 'Carex morrowii Evercolor Everglow',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 385,
      chnn_stock_retail: 385,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KXVWT5R9;XH6PY3DK;XHKJ32DT;E358HCE4;B9WYJTS7;ZJ19ZECD',
      statusContent: 'dot',
      hash: 'VY238AVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY238AVE'
    },
    {
      status: 810,
      sku: 'PM0072585',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307415',
      rtl_batch_array: '6307415',
      name_search: 'ELEVIVEL',
      name: "Elaeagnus ebbingei 'Viveleg'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AKERP4J4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKERP4J4'
    },
    {
      status: 210,
      sku: 'PM0080475',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337836',
      rtl_batch_array: '6337836',
      name_search: 'CAOEVERO',
      name: "Carex oshimensis 'Everoro'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 725,
      chnn_stock_retail: 725,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'STBLGLP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STBLGLP6'
    },
    {
      status: 210,
      sku: 'PM0072587',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307420',
      rtl_batch_array: '6307420',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: '8KVLEE27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KVLEE27'
    },
    {
      status: 210,
      sku: 'PM0072588',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307438',
      rtl_batch_array: '6307438',
      name_search: 'EXRNIAGA',
      name: "Exochorda racemosa 'Niagara'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AERC8NXG;BNYXBTEC;XT44X7YT;VD3B6Z1B;JLH3SBCK;B9C8HS3R;J6XTP7A1;H1CR5KZC;SWGBWSRL;AW9Y692B;D9DTVY2V;TTS9K5VT;5A4YC53D;7HT5556T;9147LVST;W3B5LVLP;T1VRCPZ9;YRBZRN1Z;V6V3VVD7;2EJT8SWX;6AYWW6T1;2XCKNVJS',
      statusContent: 'dot',
      hash: '2P83GA4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2P83GA4V'
    },
    {
      status: 210,
      sku: 'PM0080476',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337837',
      rtl_batch_array: '6337837',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 517,
      chnn_stock_retail: 517,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: 'CCG58DDJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCG58DDJ'
    },
    {
      status: 210,
      sku: 'PM0080477',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337838',
      rtl_batch_array: '6337838',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: '4C3S8HE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4C3S8HE2'
    },
    {
      status: 210,
      sku: 'PM0080478',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337839',
      rtl_batch_array: '6337839',
      name_search: 'CACHGOLD',
      name: 'Caryopteris clandonensis Hint of Gold',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBTALEH4;BDG25NN9;42SSYE5Z;1SKPNGRY',
      statusContent: 'dot',
      hash: 'KD1S1KSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KD1S1KSD'
    },
    {
      status: 210,
      sku: 'PM0072593',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307495',
      rtl_batch_array: '6307495',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 3050,
      chnn_stock_retail: 3050,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'JYW1VG89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYW1VG89'
    },
    {
      status: 910,
      sku: 'PM0072594',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JABEESIA',
      name: 'Jasminum beesianum',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore: 'Z65Z14VL',
      statusContent: 'dot',
      hash: 'AW6WBAZ4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AW6WBAZ4'
    },
    {
      status: 210,
      sku: 'PM0080479',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337840',
      rtl_batch_array: '6337840',
      name_search: 'CACKBLUE',
      name: "Caryopteris clandonensis 'Kew Blue'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 843,
      chnn_stock_retail: 843,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2PCYA2A;JRHPKVBA',
      statusContent: 'dot',
      hash: 'AGZN2YN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGZN2YN4'
    },
    {
      status: 210,
      sku: 'PM0072596',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307505',
      rtl_batch_array: '6307505',
      name_search: 'LEBICOLO',
      name: 'Lespedeza bicolor',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CGNZRPDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGNZRPDV'
    },
    {
      status: 210,
      sku: 'PM0080480',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337841',
      rtl_batch_array: '6337841',
      name_search: 'CACSSORB',
      name: "Caryopteris clandonensis 'Summer Sorbet'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1559,
      chnn_stock_retail: 1559,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4394LYZG;HRA4XLLY;GKAVHWWK;C5C2KYWA;PHH9GT2R;AKAW8JRG;A1E13RW2;V4C9XZXT',
      statusContent: 'dot',
      hash: '8CBDYCB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CBDYCB3'
    },
    {
      status: 210,
      sku: 'PM0072598',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307521',
      rtl_batch_array: '6307521',
      name_search: 'LOCAERUL',
      name: 'Lonicera caerulea',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 830,
      chnn_stock_retail: 830,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BWTTTY9P',
      statusContent: 'dot',
      hash: 'WEJ739GT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEJ739GT'
    },
    {
      status: 210,
      sku: 'PM0072599',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307603',
      rtl_batch_array: '6307603',
      name_search: 'PADDELIG',
      name: "Passiflora 'Damsel's Delight'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 452,
      chnn_stock_retail: 452,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2SESK8JS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SESK8JS'
    },
    {
      status: 210,
      sku: 'PM0072600',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307609',
      rtl_batch_array: '6307609',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_prcp: 30.185,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'DD8JJEDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD8JJEDN'
    },
    {
      status: 810,
      sku: 'PM0082870',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349319',
      rtl_batch_array: '6349319',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '140160KL',
      rng_range_identifier: 'H140160RB',
      rng_range_description: 'H140 cm 160 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: 'TRE7TR22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRE7TR22'
    },
    {
      status: 910,
      sku: 'PM0072602',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PYSOR',
      name: "Pyracantha 'Soleil d'Or'",
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore:
        'V7SYWATP;67HWYKH5;GNRYZY8E;2CLD6RED;ESH89THA;8KSP5GWY;V5D1LT7N;9JHPT4PG;ELJVPHGC',
      statusContent: 'dot',
      hash: 'EHHHR2H2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EHHHR2H2'
    },
    {
      status: 210,
      sku: 'PM0072603',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307656',
      rtl_batch_array: '6307656',
      name_search: 'RIRUBRUM',
      name: 'Ribes rubrum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7TCWNG2D;D9W6ZX1R;JZT4AYXE;NLJ3CBJP;6KB6D8JT;H9SH89Y8;YS79Z4AW;PB2PY38Y;1ARJSAK4;X633YZP6;1YLB2AE3;479YG8B4;2315HKEK',
      statusContent: 'dot',
      hash: 'BG6YY5YV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BG6YY5YV'
    },
    {
      status: 210,
      sku: 'PM0080481',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337842',
      rtl_batch_array: '6337842',
      name_search: 'CACWSURP',
      name: "Caryopteris clandonensis 'White Surprise'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 354,
      chnn_stock_retail: 354,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '27A38YW5;5LEA14TB;GTATLCSR;J6GVZG8Y',
      statusContent: 'dot',
      hash: 'TEA9V162',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEA9V162'
    },
    {
      status: 210,
      sku: 'PM0080482',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337843',
      rtl_batch_array: '6337843',
      name_search: 'CACWGOLD',
      name: "Caryopteris clandonensis 'Worcester Gold'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5WCYK59B',
      statusContent: 'dot',
      hash: 'XRNSPE18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRNSPE18'
    },
    {
      status: 910,
      sku: 'PM0072606',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307665',
      rtl_batch_array: '6307665',
      name_search: 'SCRUBRIF',
      name: 'Schisandra rubriflora',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X22P1H47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X22P1H47'
    },
    {
      status: 210,
      sku: 'PM0072607',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307685',
      rtl_batch_array: '6307685',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'A7N7ZTKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7N7ZTKC'
    },
    {
      status: 210,
      sku: 'PM0080483',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337844',
      rtl_batch_array: '6337844',
      name_search: 'CEATBLUE',
      name: "Ceanothus arboreus 'Trewithen Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 555,
      chnn_stock_retail: 555,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P4243H37;VL5D96VE;PKZG7TL1;1YW1XZ9X;CPT298V8;6PYCNXA4;K5TSA8GX;J7L7EBAR;N7T5XSEW',
      statusContent: 'dot',
      hash: 'HV83J7N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HV83J7N5'
    },
    {
      status: 810,
      sku: 'PM0080484',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337845',
      rtl_batch_array: '6337845',
      name_search: 'CEABLUE',
      name: "Ceanothus 'Autumnal Blue'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NANC4ZV8;ZXW3C9X7;J14CVT7S;2BJX7PWX;Y3YWRKHP;DNA3BJE1;45ET7ZNN',
      statusContent: 'dot',
      hash: 'JNDSZJD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNDSZJD4'
    },
    {
      status: 210,
      sku: 'PM0072614',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307257',
      rtl_batch_array: '6307257',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_prcp: 8.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '8K8CKS74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8K8CKS74'
    },
    {
      status: 810,
      sku: 'PM0072615',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280866',
      rtl_batch_array: '6280866, 6307259',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 162,
      sppl_prcp: 8.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '6XDJX8Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XDJX8Y8'
    },
    {
      status: 910,
      sku: 'PM0072617',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMGELEGA',
      name: "Ampelopsis glandulosa 'Elegans'",
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore: 'W5RRDWPT;YR48EBSR;7YCXKYCS;NWLL499A;ZYRDTDWT',
      statusContent: 'dot',
      hash: 'WJL7TA2X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WJL7TA2X'
    },
    {
      status: 210,
      sku: 'PM0080485',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337846',
      rtl_batch_array: '6337846',
      name_search: 'CEBMOUND',
      name: "Ceanothus 'Blue Mound'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 515,
      chnn_stock_retail: 515,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7W32SR6;DES3VTSB;7YDGE247',
      statusContent: 'dot',
      hash: 'VAEB5AAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAEB5AAT'
    },
    {
      status: 210,
      sku: 'PM0080492',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337853',
      rtl_batch_array: '6337853',
      name_search: 'CEICBLUE',
      name: "Ceanothus impressus 'Cool Blue'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCY24YA3;AXDTTS4H;RG6VSKDH;97C13KLC;636XTD52;2REEE2J4;E7J98241',
      statusContent: 'dot',
      hash: '2JSJL4WD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JSJL4WD'
    },
    {
      status: 210,
      sku: 'PM0080493',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337854',
      rtl_batch_array: '6337854',
      name_search: 'CEISKIES',
      name: "Ceanothus 'Italian Skies'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XBX1DBVY;HBT7P281;KWR7EYEZ;RRG1J246',
      statusContent: 'dot',
      hash: '15CK2CS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15CK2CS2'
    },
    {
      status: 210,
      sku: 'PM0072543',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252874',
      rtl_batch_array: '6252874',
      name_search: 'IRSMAPPL',
      name: "Iris sibirica 'Miss Apple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 327,
      chnn_stock_retail: 327,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ZGLPV7S;N8L2TXC6;D8JAR98T;YSD68D1V;KKN39RY9;27THXLZ7;H5L7S45H;BDCPD1D2;KKHHKV8W;WGYRZZVG;RBJE1E8Z',
      statusContent: 'dot',
      hash: 'H126YX1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H126YX1H'
    },
    {
      status: 210,
      sku: 'PM0072529',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 6,
      btch_active_retail: '6336457',
      rtl_batch_array: '6267761, 6276640, 6291704, 6308481, 6336457, 6300863',
      name_search: 'ANHHJOBE',
      name: "Anemone hybrida 'Honorine Jobert'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 117,
      chnn_stock_retail: 46005,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2J5SXH1;YKNJ7TZ1;4ETXSNCC;WC36SR2J;WVEGZW26;AXVRKPX2;8BZR9YJB;RSCKAH9Z;YBKSLZRK;8DH2V7SA;X6H88CAY;BD76SGZT;X95H1D2Y;DE3EEBSW;S9DBWLDV',
      statusContent: 'dot',
      hash: 'WKPBAA3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKPBAA3H'
    },
    {
      status: 210,
      sku: 'PM0072540',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6255888',
      rtl_batch_array: '6267785, 6252862, 6255888',
      name_search: 'HEARGUTI',
      name: 'Helleborus argutifolius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 256,
      chnn_stock_retail: 774,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BEHY7658;CEK54WNB;4SAAJR7S',
      statusContent: 'dot',
      hash: 'EZBB86GA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZBB86GA'
    },
    {
      status: 210,
      sku: 'PM0080494',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337855',
      rtl_batch_array: '6337855',
      name_search: 'CEJPHELP',
      name: "Ceanothus 'Julia Phelps'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2RWBRHK4',
      statusContent: 'dot',
      hash: 'X9VBTE1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9VBTE1G'
    },
    {
      status: 210,
      sku: 'PM0072577',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253124',
      rtl_batch_array: '6253124',
      name_search: 'ROOMJUPR',
      name: "Rosmarinus officinalis 'Miss Jessopp's Upright'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3638,
      chnn_stock_retail: 3638,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH2H6229;JR97TD2N',
      statusContent: 'dot',
      hash: 'PELBZG59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PELBZG59'
    },
    {
      status: 210,
      sku: 'PM0072553',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253064',
      rtl_batch_array: '6253064',
      name_search: 'BUDHARLE',
      name: "Buddleja davidii 'Harlequin'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_prcp: 2.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6T53N6J8;XCEABYLT;C5PN7T3P;5B9Y41W7;1NX7NCN3',
      statusContent: 'dot',
      hash: 'DG1JHHG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG1JHHG3'
    },
    {
      status: 210,
      sku: 'PM0072561',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253085',
      rtl_batch_array: '6253085',
      name_search: 'FUDSARAH',
      name: "Fuchsia 'Delta's Sarah'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 397,
      chnn_stock_retail: 397,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T9KYHHZ3;X7G7V2D9;98S4AT78;9W9E68BV;54AHYLL6;2EPJ2LJ1;7BPBP1EN;1CDBR7CL;TAWZCB2E;J57GD4PE',
      statusContent: 'dot',
      hash: 'KE51E8BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE51E8BH'
    },
    {
      status: 210,
      sku: 'PM0072578',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253125',
      rtl_batch_array: '6253125',
      name_search: 'ROOPROST',
      name: "Rosmarinus officinalis 'Prostratus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1396,
      chnn_stock_retail: 1396,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L7YDPZTP;A85BYZ6Z;VJHZ1AR1;6TTX2LKX;G96LGV7Y;HCBJPJW1;BXJ152H4;5E3N1XWA;TBNBCP3X;6GNN8W72;N73VWDDZ;JVL7D2KX;X9RXRWGJ;49G7JVWW;K1VRX8YG;H3G5X318;23EASCDH;T2YZ7KD7;LSJWJRRY;THA8RX26;TE1BVWHJ;ZPKT3PGD;YH251HAN;CHC4R2ZH;TD9KH3WJ;RD1L9K97',
      statusContent: 'dot',
      hash: 'BYB9CX7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYB9CX7V'
    },
    {
      status: 210,
      sku: 'PM0080495',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337856',
      rtl_batch_array: '6337856',
      name_search: 'CETMPOIN',
      name: "Ceanothus thyrsiflorus 'Millerton Point'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '173B3VVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '173B3VVB'
    },
    {
      status: 210,
      sku: 'PM0080496',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337857',
      rtl_batch_array: '6337857',
      name_search: 'CEPBLUE',
      name: "Ceanothus 'Puget Blue'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 929,
      chnn_stock_retail: 929,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LLRS91AA;5JEDVG2X;RWGVKCPW;46DZL2CK;9BR19664;R5RRXXA8;ZJV9G2P5;XLJ5CBWL;VBJW3YVK',
      statusContent: 'dot',
      hash: 'TBE3E361',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBE3E361'
    },
    {
      status: 210,
      sku: 'PM0072575',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253120',
      rtl_batch_array: '6253120',
      name_search: 'PEATRIPL',
      name: 'Perovskia atriplicifolia',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 464,
      chnn_stock_retail: 464,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PHDWA9YZ;P1AGY8XA;KZ236RX4;1D9W44SL;BS496EAS;46BR76B5;X9G37KWK;589V21D5;HTLLC1B8;GCR521R2;G333GP1G;H8YL8RL4;9YJHKBN9',
      statusContent: 'dot',
      hash: '8BA97TSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BA97TSV'
    },
    {
      status: 810,
      sku: 'PM0082871',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349320',
      rtl_batch_array: '6349320',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: 'SW7D5LW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SW7D5LW9'
    },
    {
      status: 210,
      sku: 'PM0080497',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337858',
      rtl_batch_array: '6337858',
      name_search: 'CESFLURR',
      name: "Ceanothus 'Snow Flurries'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P84B1BP2;XX5N4RD4;45RZDRLR',
      statusContent: 'dot',
      hash: '4S1AE4BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S1AE4BK'
    },
    {
      status: 210,
      sku: 'PM0080498',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337860',
      rtl_batch_array: '6337860',
      name_search: 'CETUXEDO',
      name: "Ceanothus 'Tuxedo'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 565,
      chnn_stock_retail: 565,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3WSTCA9;H1HVSDPJ;SEBNV73X;TSCYBA3E;DX8171HE;SWDG2WRG;ZWY96Y9D;1C1V7BCS;RAT478P3;GRTGD51C;WA3J18GH;SR92R797',
      statusContent: 'dot',
      hash: 'CR1XLWP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR1XLWP6'
    },
    {
      status: 210,
      sku: 'PM0080499',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337861',
      rtl_batch_array: '6337861',
      name_search: 'CEGYPOIN',
      name: "Ceanothus griseus 'Yankee Point'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5WNGL5X9;B2RSV8WC;GJRSTXRT',
      statusContent: 'dot',
      hash: 'RPYWDEEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPYWDEEC'
    },
    {
      status: 210,
      sku: 'PM0080500',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337862',
      rtl_batch_array: '6337862',
      name_search: 'CEOCCIDE',
      name: 'Celtis occidentalis',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NCNY8BWZ',
      statusContent: 'dot',
      hash: 'RPSLGH12',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPSLGH12'
    },
    {
      status: 810,
      sku: 'PM0082872',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349321',
      rtl_batch_array: '6349321',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: 'EJE9WE2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJE9WE2L'
    },
    {
      status: 810,
      sku: 'PM0082873',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349322',
      rtl_batch_array: '6349322',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 36.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: 'R3VBBSVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3VBBSVN'
    },
    {
      status: 810,
      sku: 'PM0082874',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349323',
      rtl_batch_array: '6349323',
      name_search: 'HAMOLLIS',
      name: 'Hamamelis mollis',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPEPBEDG;Y6DKAK9V;DGCWZJG3;H3NL7VBD;KGPKL4TB',
      statusContent: 'dot',
      hash: 'ZHTKE8S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHTKE8S6'
    },
    {
      status: 810,
      sku: 'PM0021387',
      core_name: 'Plant',
      sppl_ean: '8720626364844',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '5528600',
      rtl_batch_array: '5459709, 5528600',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 58,
      chnn_stock_retail: 308,
      sppl_prcp: 3.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: 'V6BSZTEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6BSZTEA'
    },
    {
      status: 210,
      sku: 'PM0017303',
      core_name: 'Plant',
      sppl_ean: '8720349446469',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5501827',
      rtl_batch_array: '5501827',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 14641,
      chnn_stock_retail: 14641,
      sppl_prcp: 4.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      imageBatch: '8BD77LWX',
      statusContent: 'dot',
      hash: 'WB731WZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB731WZA'
    },
    {
      status: 210,
      sku: 'PM0072710',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301621',
      rtl_batch_array: '6301621, 6350661',
      name_search: 'LIDHBLUE',
      name: "Lithodora diffusa 'Heavenly Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 1331,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RV13BXSN;BV2Y2ZRN;ZDPKXPXZ;SN938LTA;L5E5KHAW;6XR4NNR7;H96W98DY;7VGPDRY4;CZBJHDXT',
      statusContent: 'dot',
      hash: 'SSPC94CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSPC94CR'
    },
    {
      status: 210,
      sku: 'PM0021445',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271420',
      rtl_batch_array: '5420725, 6271420, 6336319',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 760,
      chnn_stock_retail: 3154,
      sppl_prcp: 2.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'X4YHBNGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4YHBNGR'
    },
    {
      status: 210,
      sku: 'PM0072670',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254241',
      rtl_batch_array: '6254241',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1650,
      chnn_stock_retail: 1650,
      sppl_prcp: 5.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: 'NVZCRW52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVZCRW52'
    },
    {
      status: 210,
      sku: 'PM0019563',
      core_name: 'Plant',
      sppl_ean: '8720626337572',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '5396908',
      rtl_batch_array: '5396908, 6263139',
      name_search: 'BUWCHIP',
      name: 'Buddleja White Chip',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 7157,
      chnn_stock_retail: 7307,
      sppl_prcp: 3.226,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XJTY65T2;VZJR59Z7;K9A9BEHN;H5CY9VKH;1ZEVYGBD;4R9JSNVD;L11NT2S9;6ATTLP73;A2G2B3NZ;7PDCZKZJ',
      statusContent: 'dot',
      hash: 'N9SWEAZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9SWEAZ2'
    },
    {
      status: 810,
      sku: 'PM0072712',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254406',
      rtl_batch_array: '6254406',
      name_search: 'MUROSSII',
      name: 'Mukdenia rossii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WHCPS25L',
      statusContent: 'dot',
      hash: '4W6WHEXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W6WHEXG'
    },
    {
      status: 210,
      sku: 'PM0072636',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346753',
      rtl_batch_array: '6346753',
      name_search: 'RIUCHGUL',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gul',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 519,
      chnn_stock_retail: 519,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X49AL4ZN;L7ZPR216;8YLKPZ6D;9BDE59E1;H5R1YSPZ;K1XZEE5Z;DL1LZY7E;WAL429PD;9C2WDGST;8JYG6TBA;LRLPNZZE;CLXH99ZN',
      statusContent: 'dot',
      hash: '4GR5K6BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GR5K6BR'
    },
    {
      status: 210,
      sku: 'PM0072711',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370372',
      rtl_batch_array: '6370372, 6350666, 6254405',
      name_search: 'LYCLETHR',
      name: 'Lysimachia clethroides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1682,
      chnn_stock_retail: 2361,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RXAXHT28;LGX55A6Z;BXEKC2RN;DB69EWWT;BTZG72LH;9Y4GXRAV',
      statusContent: 'dot',
      hash: '8XZ3SXAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XZ3SXAY'
    },
    {
      status: 210,
      sku: 'PM0072637',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346754',
      rtl_batch_array: '6346754',
      name_search: 'RIUCHROE',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki R\u00f6d',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 281,
      chnn_stock_retail: 281,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECJGAL8T;H955H26G;V85N3L43;2Y2CY1AD;C8RL4TWB;B58HCCJS',
      statusContent: 'dot',
      hash: 'Y5JA1451',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5JA1451'
    },
    {
      status: 210,
      sku: 'PM0072701',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301505',
      rtl_batch_array: '6349929, 6301505',
      name_search: 'CHOBLIQU',
      name: 'Chelone obliqua',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3241,
      chnn_stock_retail: 5604,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JCPDEH8R;R42V2Z2S;K8JXG32B;4V1A5ZH8;W8KR84XY;DHVC7KL6;WH4RWYNH',
      statusContent: 'dot',
      hash: 'DNL2WHT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DNL2WHT2'
    },
    {
      status: 810,
      sku: 'PM0072621',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307292',
      rtl_batch_array: '6307292',
      name_search: 'CAMGRAND',
      name: 'Campsis grandiflora',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ7KN4YY;TV1R2SXN;CWEJY5ZD;YWPAW2KZ;887ACC3N;D68WNJ6V',
      statusContent: 'dot',
      hash: 'VCHLZPKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCHLZPKA'
    },
    {
      status: 210,
      sku: 'PM0051755',
      core_name: 'Plant',
      sppl_ean: '8720349431847',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '5932413',
      rtl_batch_array: '6269488, 6356140, 6300736, 5932413',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6182,
      chnn_stock_retail: 18497,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: '7SND99A6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SND99A6'
    },
    {
      status: 810,
      sku: 'PM0072622',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307293',
      rtl_batch_array: '6307293',
      name_search: 'CATSJFIR',
      name: 'Campsis tagliabuana Summer Jazz Fire',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ADXTZGGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADXTZGGL'
    },
    {
      status: 910,
      sku: 'PM0072623',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307294',
      rtl_batch_array: '6307294',
      name_search: 'CATSJGOL',
      name: 'Campsis tagliabuana Summer Jazz Gold',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KWRXLTGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWRXLTGA'
    },
    {
      status: 210,
      sku: 'PM0009769',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333209',
      rtl_batch_array: '5495238, 6054526, 6333209',
      name_search: 'CEDEALBA',
      name: 'Centaurea dealbata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5749,
      chnn_stock_retail: 7282,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E7ASRY1E;V5ABGCLS;G5RD93CA;TYV9PZWS;8KHGG8W8;SCDJR9JT;JETACTPN',
      statusContent: 'dot',
      hash: 'Z4JNAXP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4JNAXP4'
    },
    {
      status: 810,
      sku: 'PM0072624',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307296',
      rtl_batch_array: '6307296',
      name_search: 'CATTARAN',
      name: "Campsis tagliabuana 'Tarantella'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 4.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DLYR1LAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLYR1LAX'
    },
    {
      status: 210,
      sku: 'PM0026908',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6370826',
      rtl_batch_array: '6350430, 6370826, 6238819',
      name_search: 'HEOBSIDI',
      name: "Heuchera 'Obsidian'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4413,
      sppl_order_minimum: 3,
      sppl_prcp: 1.487,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '96JEHKY1;BPYSP6GS;G5SS1DS4;GH6L5ZCX;8C6GY8L1;TYZDGLW3;PCGYKY93',
      statusContent: 'dot',
      hash: 'GEE82GZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEE82GZC'
    },
    {
      status: 910,
      sku: 'PM0072625',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307297',
      rtl_batch_array: '6307297',
      name_search: 'CEORBICU',
      name: 'Celastrus orbiculatus',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SGBGYSH8;RSYE9E7R',
      statusContent: 'dot',
      hash: '581ZYTS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '581ZYTS4'
    },
    {
      status: 210,
      sku: 'PM0072626',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345465',
      rtl_batch_array: '6345465',
      name_search: 'CLPPERFE',
      name: "Clematis 'Pink Perfection'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1XB2T9Y;BPTWEHPR',
      statusContent: 'dot',
      hash: 'H4B95W9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4B95W9G'
    },
    {
      status: 210,
      sku: 'PM0072627',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346751',
      rtl_batch_array: '6346751',
      name_search: 'ACDJENNY',
      name: "Actinidia deliciosa 'Jenny'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 685,
      chnn_stock_retail: 685,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K4E95RN;LY9ZSDKY;N5LXEHRX',
      statusContent: 'dot',
      hash: 'E2S1KC9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2S1KC9J'
    },
    {
      status: 210,
      sku: 'PM0072628',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307773',
      rtl_batch_array: '6307773',
      name_search: 'ACTETRAM',
      name: 'Actinidia tetramera',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6JAGCJW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JAGCJW5'
    },
    {
      status: 810,
      sku: 'PM0072629',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307774',
      rtl_batch_array: '6307774',
      name_search: 'FICBTURK',
      name: "Ficus carica 'Brown Turkey'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DH7T8VNW;5YPR5G21;6H61HPED;4T289JRE;XN7L64H9;64GH657S;SDHBXYBS',
      statusContent: 'dot',
      hash: 'TAHZZSJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TAHZZSJZ'
    },
    {
      status: 210,
      sku: 'PM0072630',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307775',
      rtl_batch_array: '6307775',
      name_search: 'RIJOSTAB',
      name: 'Ribes Jostaberry',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1275,
      chnn_stock_retail: 1275,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AHZD5B2T;KZHLNVT9;511XL9RW;XBSB95GW;5XJC2KL2;G79431ER;HPTKYZA9;PHZ5HGEY;GP1PGL8D;BHPYH2CC;N5135B7J;N3A1GLK6;7XE7642B;DNAC5T73',
      statusContent: 'dot',
      hash: 'ZA7JY3JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZA7JY3JY'
    },
    {
      status: 210,
      sku: 'PM0072631',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307776',
      rtl_batch_array: '6307776',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 803,
      chnn_stock_retail: 803,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: 'Z59C4H2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z59C4H2V'
    },
    {
      status: 210,
      sku: 'PM0072634',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307779',
      rtl_batch_array: '6307779',
      name_search: 'RIRWPARE',
      name: "Ribes rubrum (white) 'Witte Parel'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YN7PHJK3',
      statusContent: 'dot',
      hash: '9L9VVRBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L9VVRBR'
    },
    {
      status: 210,
      sku: 'PM0072635',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346752',
      rtl_batch_array: '6346752',
      name_search: 'RIUCHGRO',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gr\u00f6n',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRH69A6W',
      statusContent: 'dot',
      hash: 'L6588CLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6588CLT'
    },
    {
      status: 210,
      sku: 'PM0072638',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307783',
      rtl_batch_array: '6307783',
      name_search: 'RUIABLIS',
      name: "Rubus idaeus 'Autumn Bliss'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ES4HWH45;GLVACG3E;4DWW7GHK',
      statusContent: 'dot',
      hash: 'ZRXLRNC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRXLRNC3'
    },
    {
      status: 210,
      sku: 'PM0072640',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307785',
      rtl_batch_array: '6307785',
      name_search: 'RUIMPROM',
      name: "Rubus idaeus 'Malling Promise'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 389,
      chnn_stock_retail: 389,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZJV1BV9;WG8HXVEE;8D4DDK5A;LPB1B4T4;B3PJB7LS;J4ZL1SRS;XTXEBGWN',
      statusContent: 'dot',
      hash: 'P2RRLRYJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2RRLRYJ'
    },
    {
      status: 210,
      sku: 'PM0072641',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307786',
      rtl_batch_array: '6307786',
      name_search: 'RUIOTTAW',
      name: "Rubus idaeus 'Ottawa'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 269,
      chnn_stock_retail: 269,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9NG2PV7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NG2PV7B'
    },
    {
      status: 810,
      sku: 'PM0072642',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307787',
      rtl_batch_array: '6307787',
      name_search: 'RUISCHOE',
      name: "Rubus idaeus 'Sch\u00f6nemann'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H67L67YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H67L67YR'
    },
    {
      status: 210,
      sku: 'PM0072643',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307788',
      rtl_batch_array: '6307788',
      name_search: 'RUITULAM',
      name: "Rubus idaeus 'Tulameen'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 405,
      chnn_stock_retail: 405,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZAN5E5ZD;PJ2NYE7Z;N7DARVX6',
      statusContent: 'dot',
      hash: '95CDE383',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95CDE383'
    },
    {
      status: 810,
      sku: 'PM0072644',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307798',
      rtl_batch_array: '6307798',
      name_search: 'VICFRANC',
      name: "Vitis 'Cabernet Franc'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '52NKHV3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52NKHV3B'
    },
    {
      status: 210,
      sku: 'PM0072645',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307799',
      rtl_batch_array: '6307799',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 603,
      chnn_stock_retail: 603,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: '8R2H9S1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8R2H9S1L'
    },
    {
      status: 210,
      sku: 'PM0072646',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307801',
      rtl_batch_array: '6307801',
      name_search: 'VIMFOCH',
      name: "Vitis 'Mar\u00e9chal Foch'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NGGT4W21',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGGT4W21'
    },
    {
      status: 210,
      sku: 'PM0072647',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307802',
      rtl_batch_array: '6307802',
      name_search: 'VIRONDO',
      name: "Vitis 'Rondo'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 207,
      chnn_stock_retail: 207,
      sppl_order_minimum: 3,
      sppl_prcp: 3.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W9P7RZB9',
      statusContent: 'dot',
      hash: 'B1Y5KSYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1Y5KSYD'
    },
    {
      status: 210,
      sku: 'PM0072648',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307803',
      rtl_batch_array: '6307803',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 711,
      chnn_stock_retail: 711,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: 'N1D32BY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1D32BY2'
    },
    {
      status: 210,
      sku: 'PM0072649',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307835',
      rtl_batch_array: '6307835',
      name_search: 'FARCAMPB',
      name: "Fargesia robusta 'Campbell'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YY7D5D74;T5J7CY3S;617D2AVB;WXW4Z2Y3;L696YVNC;PDT1LJPP;J2WH5PBG;D2TLRPSC;GZHTHLNE',
      statusContent: 'dot',
      hash: '586CR31Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '586CR31Y'
    },
    {
      status: 910,
      sku: 'PM0072650',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HULUPULU',
      name: 'Humulus lupulus',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore: 'XSBNC5CW;V31YG91S',
      statusContent: 'dot',
      hash: '41D5NTY7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '41D5NTY7'
    },
    {
      status: 210,
      sku: 'PM0072651',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307860',
      rtl_batch_array: '6307860',
      name_search: 'HULHERSB',
      name: "Humulus l. 'Hersbrucker'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1063,
      chnn_stock_retail: 1063,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YC7HR59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YC7HR59'
    },
    {
      status: 210,
      sku: 'PM0072652',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307861',
      rtl_batch_array: '6307861',
      name_search: 'HULAUREU',
      name: "Humulus lupulus 'Aureus'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 394,
      chnn_stock_retail: 394,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WX218JCT;DRVCN3W7;CSLCE5D9',
      statusContent: 'dot',
      hash: 'EXWTSSY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXWTSSY1'
    },
    {
      status: 810,
      sku: 'PM0072653',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307862',
      rtl_batch_array: '6307862',
      name_search: 'HULCASCA',
      name: "Humulus lupulus 'Cascade'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '145121DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '145121DY'
    },
    {
      status: 210,
      sku: 'PM0072654',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307863',
      rtl_batch_array: '6307863',
      name_search: 'HULMAGNU',
      name: "Humulus lupulus 'Magnum'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EG3XLJH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG3XLJH7'
    },
    {
      status: 210,
      sku: 'PM0072661',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253850',
      rtl_batch_array: '6253850',
      name_search: 'JUPOGOLD',
      name: "Juniperus pfitzeriana 'Old Gold'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H4AH5NBV;JCYD5JZW;ABRDA4XH;RX122G23;HYNDD2NP;ATX87GRK;52LSLXGD;2PPHP64S;EG5B4Z2H;111L6DNL;H28CXG1S;7151XSEX;S19CRJ87;HPR3EJ1N;D4EB5S1N;9RRH4AP9;WWSTSPWY;4BE6LC9V',
      statusContent: 'dot',
      hash: 'HSJHERSC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSJHERSC'
    },
    {
      status: 210,
      sku: 'PM0072633',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307778',
      rtl_batch_array: '6307778',
      name_search: 'RIRWHOLL',
      name: "Ribes rubrum (white) 'Witte Hollander'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 858,
      chnn_stock_retail: 858,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E7E22Z4',
      statusContent: 'dot',
      hash: 'YY9T3GL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YY9T3GL8'
    },
    {
      status: 210,
      sku: 'PM0072632',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307777',
      rtl_batch_array: '6307777',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 301,
      chnn_stock_retail: 301,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: '9JTPKJJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9JTPKJJD'
    },
    {
      status: 210,
      sku: 'PM0072659',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253848',
      rtl_batch_array: '6253848',
      name_search: 'CHOBRIGI',
      name: "Chamaecyparis obtusa 'Brigitte'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGYSCZDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGYSCZDY'
    },
    {
      status: 910,
      sku: 'PM0082875',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAVIRGIN',
      name: 'Hamamelis virginiana',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'PLT32T6E;KHE2ADXH',
      statusContent: 'dot',
      hash: 'ZC5ALBH9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZC5ALBH9'
    },
    {
      status: 910,
      sku: 'PM0082876',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'H6KW3L1R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H6KW3L1R'
    },
    {
      status: 210,
      sku: 'PM0072667',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254237',
      rtl_batch_array: '6254237',
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYVL3TAR;96HC75LG;J1CYKELX;6C5G3SPX;Z3HVWB3X;ZAG3GRCD;DEG14R1N',
      statusContent: 'dot',
      hash: '27C2DP7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27C2DP7S'
    },
    {
      status: 210,
      sku: 'PM0080515',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337879',
      rtl_batch_array: '6337879',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'YL9LS7BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YL9LS7BY'
    },
    {
      status: 810,
      sku: 'PM0072688',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254281',
      rtl_batch_array: '6254281',
      name_search: 'ACPGLOBO',
      name: "Acer platanoides 'Globosum'",
      sppl_size_code: '200STC12',
      rng_range_identifier: 'STE200C12',
      rng_range_description: 'Stem 200 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NT6LGYKL;LSZ3W6TW;K4Y3T3ZV;5NV757PG;1JHTVG4N;5YGSEVSD;PWSSDWRC;739AVVVL;291DEPXP;DL5G3BA9;V34WHSTC;WNLSY1E6',
      statusContent: 'dot',
      hash: 'RNGXHPTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNGXHPTS'
    },
    {
      status: 810,
      sku: 'PM0082877',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349328',
      rtl_batch_array: '6349328',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'VDJ8W9D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDJ8W9D9'
    },
    {
      status: 210,
      sku: 'PM0072673',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 3,
      btch_active_retail: '6320177',
      rtl_batch_array: '6300179, 6320177, 6310213',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2624,
      chnn_stock_retail: 5174,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '47E21LNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47E21LNN'
    },
    {
      status: 210,
      sku: 'PM0072674',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254249',
      rtl_batch_array: '6254249, 6310214',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 2900,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'RG669RGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG669RGA'
    },
    {
      status: 210,
      sku: 'PM0072675',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300180',
      rtl_batch_array: '6300180',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1540,
      chnn_stock_retail: 1540,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'T4DJDW3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4DJDW3E'
    },
    {
      status: 210,
      sku: 'PM0072721',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254888',
      rtl_batch_array: '6254703, 6254888',
      name_search: 'DRAFFINI',
      name: 'Dryopteris affinis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1470,
      chnn_stock_retail: 3929,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG8DG3G6',
      statusContent: 'dot',
      hash: '2AXADASR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AXADASR'
    },
    {
      status: 210,
      sku: 'PM0072655',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307865',
      rtl_batch_array: '6307865',
      name_search: 'HULTARGE',
      name: "Humulus lupulus 'Target'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 3655,
      chnn_stock_retail: 3655,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RA8GHTTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RA8GHTTS'
    },
    {
      status: 810,
      sku: 'PM0082878',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349329',
      rtl_batch_array: '6349329',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 49.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: '47861K67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47861K67'
    },
    {
      status: 210,
      sku: 'PM0072639',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307784',
      rtl_batch_array: '6307784',
      name_search: 'RUIGAMPL',
      name: "Rubus idaeus 'Glen Ample'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ZSLGZWC;87JK94SV;9RH5EC7N;S2648GKP;N1G9JVVL;XV22YGV9;JSNNH64E;XYBKAA5J',
      statusContent: 'dot',
      hash: 'WD5V82D4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WD5V82D4'
    },
    {
      status: 810,
      sku: 'PM0082879',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349345',
      rtl_batch_array: '6349345',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'JKH7VY9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKH7VY9R'
    },
    {
      status: 810,
      sku: 'PM0082880',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349346',
      rtl_batch_array: '6349346',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.975,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'A675S7NP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A675S7NP'
    },
    {
      status: 810,
      sku: 'PM0072696',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254358',
      rtl_batch_array: '6254358, 6349752, 6349038',
      name_search: 'ANHEBUXT',
      name: "Anthemis hybrida 'E.C. Buxton'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 1343,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N29RR6R2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N29RR6R2'
    },
    {
      status: 210,
      sku: 'PM0080516',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337880',
      rtl_batch_array: '6337880',
      name_search: 'CISTENOP',
      name: 'Cistus stenophyllus',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 891,
      chnn_stock_retail: 891,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2WJ5V4JD;ZAB9BV52;STD3T75N;183TD142;2RHE8T25;LVBEHXDA',
      statusContent: 'dot',
      hash: 'NKA6V2TS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKA6V2TS'
    },
    {
      status: 210,
      sku: 'PM0080517',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337882',
      rtl_batch_array: '6337882',
      name_search: 'CIFLOREN',
      name: 'Cistus florentinus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2523,
      chnn_stock_retail: 2523,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N3J6GBRG;LSYLR9DS;B9D77BNL;RNY6VKHW;PS4T73T4',
      statusContent: 'dot',
      hash: 'R5H48RZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5H48RZL'
    },
    {
      status: 210,
      sku: 'PM0080518',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337883',
      rtl_batch_array: '6337883',
      name_search: 'CIPULVER',
      name: 'Cistus pulverulentus',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1630,
      chnn_stock_retail: 1630,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AS8DASAA;L5YND3GK;VTDVVGPX;L8XPVE4P;RZ73ATHD;NVJRKLS8',
      statusContent: 'dot',
      hash: 'W2SR6Y4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2SR6Y4S'
    },
    {
      status: 210,
      sku: 'PM0072705',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301641',
      rtl_batch_array: '6301641',
      name_search: 'HEBOYSEN',
      name: "Heuchera 'Boysenberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YZVK2EBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZVK2EBC'
    },
    {
      status: 910,
      sku: 'PM0072689',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALJSCHOC',
      name: "Albizia julibrissin 'Summer Chocolate'",
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      imageCore: '81KLXWEX;8R6LRWVR;2YBT1KW5;XLB7TLLD;K3ZYB766;Z4A9VVJ3',
      statusContent: 'dot',
      hash: 'RX1EARGG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RX1EARGG'
    },
    {
      status: 810,
      sku: 'PM0072690',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254283',
      rtl_batch_array: '6254283',
      name_search: 'ALJOMBRE',
      name: 'Albizia julibrissin Ombrella',
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 33.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JHBCXJ;ZNPHTD5N;9NZJN8NP',
      statusContent: 'dot',
      hash: 'NGJPZEA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGJPZEA8'
    },
    {
      status: 810,
      sku: 'PM0072691',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254284',
      rtl_batch_array: '6254284',
      name_search: 'CABNANA',
      name: "Catalpa bignonioides 'Nana'",
      sppl_size_code: '150STC12',
      rng_range_identifier: 'STE150C12',
      rng_range_description: 'Stem 150 cm C12',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 30.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H5G8BR8P;GJRSZKPS;13TD15T9;PCZ1WLP1;KVZSKZ1Z;L6G4YSW3;TL57EL45',
      statusContent: 'dot',
      hash: 'S2S17NZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2S17NZ5'
    },
    {
      status: 810,
      sku: 'PM0072692',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254285',
      rtl_batch_array: '6254285',
      name_search: 'CABNANA',
      name: "Catalpa bignonioides 'Nana'",
      sppl_size_code: '180STC15',
      rng_range_identifier: 'STE180C15',
      rng_range_description: 'Stem 180 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H5G8BR8P;GJRSZKPS;13TD15T9;PCZ1WLP1;KVZSKZ1Z;L6G4YSW3;TL57EL45',
      statusContent: 'dot',
      hash: 'W19913RE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W19913RE'
    },
    {
      status: 810,
      sku: 'PM0072693',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254286',
      rtl_batch_array: '6254286',
      name_search: 'CECDEGOL',
      name: "Cercis chinensis 'Don Egolf'",
      sppl_size_code: '175200C10GE',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '99S8YLH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99S8YLH3'
    },
    {
      status: 210,
      sku: 'PM0072713',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353178',
      rtl_batch_array: '6353178',
      name_search: 'ROPSUPER',
      name: "Rodgersia pinnata 'Superba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YD4WNVSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD4WNVSX'
    },
    {
      status: 210,
      sku: 'PM0072714',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353180',
      rtl_batch_array: '6301796, 6353180',
      name_search: 'ROPBRAUN',
      name: "Rodgersia pinnata 'Braunlaub'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 644,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALRB9D92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALRB9D92'
    },
    {
      status: 810,
      sku: 'PM0072716',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254427',
      rtl_batch_array: '6254427',
      name_search: 'VEPSMOKE',
      name: "Verbascum 'Plum Smokey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1REG875C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1REG875C'
    },
    {
      status: 210,
      sku: 'PM0080501',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337863',
      rtl_batch_array: '6337863',
      name_search: 'CEGRIFFI',
      name: 'Cercis griffithii',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9E6JPRVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E6JPRVK'
    },
    {
      status: 210,
      sku: 'PM0080502',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337864',
      rtl_batch_array: '6337864',
      name_search: 'CEWILLMO',
      name: 'Ceratostigma willmottianum',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1212,
      chnn_stock_retail: 1212,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8VW817YL',
      statusContent: 'dot',
      hash: 'VALVZHTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VALVZHTW'
    },
    {
      status: 810,
      sku: 'PM0080503',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337865',
      rtl_batch_array: '6337865',
      name_search: 'CECHINEN',
      name: 'Cercis chinensis',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7CRGRT2D',
      statusContent: 'dot',
      hash: 'NCGA99V1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCGA99V1'
    },
    {
      status: 910,
      sku: 'PM0082881',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: '6Y95EZGG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6Y95EZGG'
    },
    {
      status: 810,
      sku: 'PM0080504',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337866',
      rtl_batch_array: '6337866',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: '9J67NBB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J67NBB8'
    },
    {
      status: 210,
      sku: 'PM0080505',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337867',
      rtl_batch_array: '6337867',
      name_search: 'CHSCGOLD',
      name: "Chaenomeles superba 'Crimson and Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 955,
      chnn_stock_retail: 955,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GNNN4HGJ;E1DGH2HZ;7Z873B33;14JX32TV;7YG1ETPX;D2TYTT6D',
      statusContent: 'dot',
      hash: '5ESYPVA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ESYPVA1'
    },
    {
      status: 210,
      sku: 'PM0072715',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6291740',
      rtl_batch_array: '6254419, 6353461, 6291740, 6308993',
      name_search: 'SPHETERO',
      name: 'Sporobolus heterolepis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6327,
      chnn_stock_retail: 11396,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3852BXC;89GK8LAW',
      statusContent: 'dot',
      hash: 'AXBX92G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXBX92G3'
    },
    {
      status: 210,
      sku: 'PM0080506',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337868',
      rtl_batch_array: '6337868',
      name_search: 'CHSJTRAI',
      name: "Chaenomeles superba 'Jet Trail'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_order_minimum: 5,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6LGYLPL8;78SXWW53;7A721S9T;2GSSWH84;WDC9NHHH',
      statusContent: 'dot',
      hash: '8AJ8G8RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AJ8G8RT'
    },
    {
      status: 210,
      sku: 'PM0080507',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337869',
      rtl_batch_array: '6337869',
      name_search: 'CHSOTRAI',
      name: "Chaenomeles superba 'Orange Trail'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LYVGTN4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYVGTN4P'
    },
    {
      status: 810,
      sku: 'PM0080508',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337870',
      rtl_batch_array: '6337870',
      name_search: 'CHSRTRAI',
      name: "Chaenomeles superba 'Red Trail'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6W2B9A69;V13VDL29;GSKE2JLZ;RVTERP6S;RJD8L165',
      statusContent: 'dot',
      hash: 'D5DABASL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5DABASL'
    },
    {
      status: 210,
      sku: 'PM0080509',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337871',
      rtl_batch_array: '6337871',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1416,
      chnn_stock_retail: 1416,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'BCAP9RXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCAP9RXA'
    },
    {
      status: 210,
      sku: 'PM0080519',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337884',
      rtl_batch_array: '6337884',
      name_search: 'CIPSUNSE',
      name: "Cistus pulverulentus 'Sunset'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 124,
      chnn_stock_retail: 124,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KHBKRPTZ;N9XVKGW9;WW392LVJ',
      statusContent: 'dot',
      hash: '7DKZN9HN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DKZN9HN'
    },
    {
      status: 210,
      sku: 'PM0072703',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254379',
      rtl_batch_array: '6268749, 6254379',
      name_search: 'GERIVALE',
      name: 'Geum rivale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 978,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5G4KS4CE',
      statusContent: 'dot',
      hash: 'BD5PLSPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BD5PLSPD'
    },
    {
      status: 810,
      sku: 'PM0080510',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337872',
      rtl_batch_array: '6337872',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: '6966CD23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6966CD23'
    },
    {
      status: 210,
      sku: 'PM0075350',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314147',
      rtl_batch_array: '6314147, 6378343',
      name_search: 'HEHJENNA',
      name: 'Hebe Hebedonna Jenna',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2270,
      chnn_stock_retail: 5520,
      sppl_order_minimum: 3,
      sppl_prcp: 2.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4EG2YNGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EG2YNGY'
    },
    {
      status: 210,
      sku: 'PM0072662',
      core_name: 'Plant',
      btch_manufacturer: 356,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254232',
      rtl_batch_array: '6254232',
      name_search: 'COAUSTRA',
      name: 'Cordyline australis',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 0.324,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVEKGZYS;G4RCR9GD;L4KCYLDX;T37P555J;P2KZV9VZ;RW39X5L8;BNHZT7NC;1VLA8JD2;GY3NR7D8;XD118544;6G4SD49N;YD94TNEZ;SE78XB8Y;CR22YYK5;ZH6SZ68Y;KXRVX8A8',
      statusContent: 'dot',
      hash: 'NWRJ3VTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWRJ3VTT'
    },
    {
      status: 210,
      sku: 'PM0072678',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254257',
      rtl_batch_array: '6254257, 6338242',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 455,
      chnn_stock_retail: 10595,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'C1A8NLCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1A8NLCS'
    },
    {
      status: 210,
      sku: 'PM0072660',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 2,
      btch_active_retail: '6275048',
      rtl_batch_array: '6253849, 6275048',
      name_search: 'CRJRASEN',
      name: "Cryptomeria japonica 'Rasen'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 228,
      chnn_stock_retail: 546,
      sppl_prcp: 8.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1D7GZN;JNVBLK37;DB633HJG',
      statusContent: 'dot',
      hash: 'BG22AJZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BG22AJZD'
    },
    {
      status: 210,
      sku: 'PM0080511',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337873',
      rtl_batch_array: '6337873',
      name_search: 'CHAGOLD',
      name: "Choisya 'Aztec Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2295,
      chnn_stock_retail: 2295,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRBZPJR;CXYAP33L;XPZ2STEH;XPGTVKWE;PPEXNEP2;VWDVY9VZ;8SJ52K9W;TSYKT4G3;G7BP1HLT;AZNGTT5W;VJA6VVVS;94869PBK;SBBXWYRB;WVLC9ZH3;J1BTEDCK;S41XBA3T;VC113LYZ;9XE14T7S',
      statusContent: 'dot',
      hash: 'D27BPH6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D27BPH6T'
    },
    {
      status: 210,
      sku: 'PM0072722',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254889',
      rtl_batch_array: '6350752, 6254889, 6254708',
      name_search: 'DRERYTHR',
      name: 'Dryopteris erythrosora',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 334,
      chnn_stock_retail: 2453,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W23J67HA',
      statusContent: 'dot',
      hash: 'V6GTLNBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6GTLNBX'
    },
    {
      status: 910,
      sku: 'PM0017151',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6142810',
      rtl_batch_array: '6142810, 6370362, 5301401, 5364086, 6329939',
      name_search: 'GEPATRIC',
      name: "Geranium 'Patricia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1,
      chnn_stock_retail: 3989,
      sppl_order_minimum: 3,
      sppl_prcp: 1.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LR3WA38L;2GZNXHPZ',
      statusContent: 'dot',
      hash: 'XNAZVAD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNAZVAD2'
    },
    {
      status: 210,
      sku: 'PM0001967',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6258911',
      rtl_batch_array: '5423586, 6258911',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1467,
      chnn_stock_retail: 1916,
      sppl_prcp: 2.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'YSTJAVGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSTJAVGK'
    },
    {
      status: 210,
      sku: 'PM0080512',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337874',
      rtl_batch_array: '6337874',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2564,
      chnn_stock_retail: 2564,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'D8CP2YY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8CP2YY8'
    },
    {
      status: 210,
      sku: 'PM0080513',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337876',
      rtl_batch_array: '6337876',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 14184,
      chnn_stock_retail: 14184,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: '83B1NV96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83B1NV96'
    },
    {
      status: 210,
      sku: 'PM0080514',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337877',
      rtl_batch_array: '6337877',
      name_search: 'CHTSUNDA',
      name: 'Choisya ternata Sundance',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 8248,
      chnn_stock_retail: 8248,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V42YL5RC;JRCE449K;XYRHEPZN;HXDXG4H2;L9Z5895X;JYVD7ZN6;KKWV491Z;9XHSEVLD;59ZX6N6T;6EHZS8RS;BSE13AEH;T399A1TP;PNN3V7YY;R25ZGNYL;NT63J78C;SN359S4T;DE4A48GH;JCW58RHJ;GN1JY58L;A2NE21ED;TZZATBVK;ZK7BGDRG;JC8HHA2W;PSTA47GK',
      statusContent: 'dot',
      hash: 'XHYH6R53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHYH6R53'
    },
    {
      status: 210,
      sku: 'PM0080520',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337885',
      rtl_batch_array: '6337885',
      name_search: 'CIPURPUR',
      name: 'Cistus purpureus',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 426,
      chnn_stock_retail: 426,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1EG1P1L9;EHD2T53W;R9Z783TA;6NK59XNZ;G7KT6BJ1;W9A5X217',
      statusContent: 'dot',
      hash: 'XXHREW5S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXHREW5S'
    },
    {
      status: 210,
      sku: 'PM0080521',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337886',
      rtl_batch_array: '6337886',
      name_search: 'CIPAFRAD',
      name: "Cistus purpureus 'Alan Fradd'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 771,
      chnn_stock_retail: 771,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8Z9ACBBY;CKASLPW2',
      statusContent: 'dot',
      hash: 'VXA854TX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXA854TX'
    },
    {
      status: 210,
      sku: 'PM0080522',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337887',
      rtl_batch_array: '6337887',
      name_search: 'CIPBTAUD',
      name: "Cistus purpureus 'Betty Taudevin'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 941,
      chnn_stock_retail: 941,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C486XANG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C486XANG'
    },
    {
      status: 210,
      sku: 'PM0080523',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337888',
      rtl_batch_array: '6337888',
      name_search: 'CISALVII',
      name: 'Cistus salviifolius',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1656,
      chnn_stock_retail: 1656,
      sppl_order_minimum: 5,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EHR5NPY2',
      statusContent: 'dot',
      hash: 'P1XKSK8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1XKSK8B'
    },
    {
      status: 210,
      sku: 'PM0080524',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337889',
      rtl_batch_array: '6337889',
      name_search: 'CISPINK',
      name: "Cistus 'Silver Pink'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 759,
      chnn_stock_retail: 759,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NX5X9GRG;T8WT239W;G136DWEZ;2ABGJ151',
      statusContent: 'dot',
      hash: 'VKNHH5VL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKNHH5VL'
    },
    {
      status: 210,
      sku: 'PM0080525',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337890',
      rtl_batch_array: '6337890',
      name_search: 'CLBUNGEI',
      name: 'Clerodendrum bungei',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1025,
      chnn_stock_retail: 1025,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SEJL33SE',
      statusContent: 'dot',
      hash: '13ZB4L8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13ZB4L8D'
    },
    {
      status: 210,
      sku: 'PM0080526',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337891',
      rtl_batch_array: '6337891',
      name_search: 'CLTFARGE',
      name: 'Clerodendrum trichotomum fargesii',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 269,
      chnn_stock_retail: 269,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '18C5TZZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18C5TZZT'
    },
    {
      status: 210,
      sku: 'PM0080527',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337892',
      rtl_batch_array: '6337892',
      name_search: 'CLALNIFO',
      name: 'Clethra alnifolia',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7DS19WLG;4NR7HE5C;JH6SC57T;RJWDER5E;P99Z15C8;1B72LSN8',
      statusContent: 'dot',
      hash: 'Y8GL6Z87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y8GL6Z87'
    },
    {
      status: 210,
      sku: 'PM0080528',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337893',
      rtl_batch_array: '6337893',
      name_search: 'CLAPSPIR',
      name: "Clethra alnifolia 'Pink Spire'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HAXST442;LKKYWZYJ',
      statusContent: 'dot',
      hash: 'KKDEBB8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKDEBB8D'
    },
    {
      status: 910,
      sku: 'PM0082882',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: '2AWPN27E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2AWPN27E'
    },
    {
      status: 910,
      sku: 'PM0082883',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: '7BHY9NW7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7BHY9NW7'
    },
    {
      status: 910,
      sku: 'PM0082884',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'ZT6YJYVK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZT6YJYVK'
    },
    {
      status: 910,
      sku: 'PM0082885',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'H6N8L8SP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H6N8L8SP'
    },
    {
      status: 210,
      sku: 'PM0080565',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337932',
      rtl_batch_array: '6337932',
      name_search: 'COSSPURP',
      name: "Corylopsis sinensis 'Spring Purple'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 549,
      chnn_stock_retail: 549,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JTG541Y2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTG541Y2'
    },
    {
      status: 210,
      sku: 'PM0072752',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255122',
      rtl_batch_array: '6255122',
      name_search: 'JUPNANA',
      name: "Juniperus procumbens 'Nana'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YPH14G7X;KRS7HGXY;V2B488EW;LA1AHTSX;G2D5PA79;DGYVNPD5;KB81N2R4;T1HLSSNG;1YXCNZW9;5B55H4XV;1JRJ3A43',
      statusContent: 'dot',
      hash: 'Y6SZPSN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6SZPSN3'
    },
    {
      status: 210,
      sku: 'PM0051807',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267735',
      rtl_batch_array: '6300249, 6267735',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 258,
      chnn_stock_retail: 968,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'D8DX9TAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8DX9TAT'
    },
    {
      status: 210,
      sku: 'PM0051809',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300260',
      rtl_batch_array: '6300260',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2580,
      chnn_stock_retail: 2580,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: '69VVBB4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69VVBB4W'
    },
    {
      status: 210,
      sku: 'PM0072751',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356584',
      rtl_batch_array: '6255121, 6356584',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 20000,
      chnn_stock_retail: 22278,
      sppl_order_minimum: 3,
      sppl_prcp: 0.714,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: '8B9ZLW1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8B9ZLW1C'
    },
    {
      status: 810,
      sku: 'PM0082886',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349370',
      rtl_batch_array: '6349370',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.954,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'EVYZ6Z6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVYZ6Z6S'
    },
    {
      status: 810,
      sku: 'PM0082887',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349371',
      rtl_batch_array: '6349371',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'A9NY69NX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9NY69NX'
    },
    {
      status: 210,
      sku: 'PM0080566',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337933',
      rtl_batch_array: '6337933',
      name_search: 'COMPURPU',
      name: "Corylus maxima 'Purpurea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GSSG2XKA;R5952436;HTCB8A75;CHBBTL6W;KJY5DA67;6EJJTVSJ;D18NH15Y;YHHPD2XC;9X13B88A;ZKX3KBZP;X547KTWE;W7D5X9GZ;YYGB3NR8;8HDGZDS2',
      statusContent: 'dot',
      hash: 'HGRKGCL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGRKGCL9'
    },
    {
      status: 210,
      sku: 'PM0029309',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308446',
      rtl_batch_array: '6267799, 6308446, 6348402',
      name_search: 'PEPPASSI',
      name: "Penstemon 'Purple Passion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 442,
      sppl_order_minimum: 3,
      sppl_prcp: 0.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '752SVNWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '752SVNWE'
    },
    {
      status: 810,
      sku: 'PM0082888',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349372',
      rtl_batch_array: '6349372',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 34.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'PAP2W4SC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAP2W4SC'
    },
    {
      status: 210,
      sku: 'PM0072729',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254907',
      rtl_batch_array: '6254907',
      name_search: 'PHRUSSEL',
      name: 'Phlomis russeliana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1479,
      chnn_stock_retail: 1479,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YP42PBWA;NCT5P9T1;63VD521Y;PRAP2EJX',
      statusContent: 'dot',
      hash: 'Z2NBCBEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2NBCBEW'
    },
    {
      status: 210,
      sku: 'PM0080529',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337894',
      rtl_batch_array: '6337894',
      name_search: 'COARBORE',
      name: 'Colutea arborescens',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 934,
      chnn_stock_retail: 934,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VSLD558V',
      statusContent: 'dot',
      hash: '2NLLXBRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NLLXBRW'
    },
    {
      status: 210,
      sku: 'PM0075354',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314149',
      rtl_batch_array: '6314149, 6378348',
      name_search: 'HEHNIKKA',
      name: 'Hebe Hebedonna Nikka',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2400,
      chnn_stock_retail: 5038,
      sppl_order_minimum: 3,
      sppl_prcp: 2.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYZ94G6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYZ94G6L'
    },
    {
      status: 210,
      sku: 'PM0080530',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337895',
      rtl_batch_array: '6337895, 6348760',
      name_search: 'COCNEORU',
      name: 'Convolvulus cneorum',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1176,
      chnn_stock_retail: 2476,
      sppl_order_minimum: 3,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D574AA9T;K9GD3V54;1D8XG7E4;6XWVVAG9;TAEAYK35;XC3SBBEB;W1KHWDTY;T2PWDGWD;DEJSYPCK;XSTHJK23;RWY6DZCB;TXKCVSHT;V5CLXH1S;8KZ323JZ;BD2RGXR9;7YD8HX35',
      statusContent: 'dot',
      hash: 'JE51TKH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JE51TKH3'
    },
    {
      status: 810,
      sku: 'PM0080531',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337896',
      rtl_batch_array: '6337896',
      name_search: 'COBGOLD',
      name: "Coprosma 'Beatson's Gold'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PWYWTWE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWYWTWE2'
    },
    {
      status: 210,
      sku: 'PM0080532',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337897',
      rtl_batch_array: '6337897',
      name_search: 'COEGLOW',
      name: "Coprosma 'Evening Glow'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 345,
      chnn_stock_retail: 345,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C5V7WSEC;NYVE3C51;PWZW55D6;ZDCP31BV;S5DZNAGV;VRAL3EA5;VN1Z6TS2;YHTJ1KVT;XEAT3G6H;7NY91CT5;DENLKWD9',
      statusContent: 'dot',
      hash: 'T7TWW7PH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7TWW7PH'
    },
    {
      status: 210,
      sku: 'PM0080533',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337898',
      rtl_batch_array: '6337898',
      name_search: 'COFBURST',
      name: "Coprosma 'Fire Burst'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CJJGD6J4;CNEK2995;26DX1STC;H9Y3AH7L;9Z16VWVR;9NNP1DVZ;WW9LLVX4;KLSGCJTT;NPV5923T;4J17PC1T',
      statusContent: 'dot',
      hash: 'W1LNSR99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1LNSR99'
    },
    {
      status: 210,
      sku: 'PM0080534',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337899',
      rtl_batch_array: '6337899',
      name_search: 'COLLIME',
      name: "Coprosma 'Lemon and Lime'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1SZV7YCP;J188915B;A8R2BZTY;AS7CSJG6;YETHBP1E;EY2NPRN9;GT3EH76T;PKHVBKS8;PYZG8DKX',
      statusContent: 'dot',
      hash: '7N8DYN97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N8DYN97'
    },
    {
      status: 210,
      sku: 'PM0080535',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337900',
      rtl_batch_array: '6337900',
      name_search: 'COPROSMA',
      name: 'Coprosma',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WHH14KJ6;GVW7CCYT',
      statusContent: 'dot',
      hash: '963P3ZKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '963P3ZKE'
    },
    {
      status: 810,
      sku: 'PM0080536',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337901',
      rtl_batch_array: '6337901',
      name_search: 'COPCOLAD',
      name: "Coprosma 'Pina Colada'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4DJA8XX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DJA8XX3'
    },
    {
      status: 210,
      sku: 'PM0080537',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337902',
      rtl_batch_array: '6337902',
      name_search: 'CORSURPR',
      name: "Coprosma 'Rainbow Surprise'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 709,
      chnn_stock_retail: 709,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4VZ3NPG;VYRL8R65',
      statusContent: 'dot',
      hash: 'RGNK9676',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGNK9676'
    },
    {
      status: 210,
      sku: 'PM0080538',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337903',
      rtl_batch_array: '6337903',
      name_search: 'COTSUNRI',
      name: "Coreopsis 'Tequila Sunrise'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KDXXCV56',
      statusContent: 'dot',
      hash: '92PWES7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92PWES7L'
    },
    {
      status: 210,
      sku: 'PM0080539',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337905',
      rtl_batch_array: '6337905',
      name_search: 'COARHAPS',
      name: 'Cordyline australis Rhapsody',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6ANHS9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6ANHS9H'
    },
    {
      status: 910,
      sku: 'PM0082889',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: '6GKHHBHC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6GKHHBHC'
    },
    {
      status: 210,
      sku: 'PM0080540',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337906',
      rtl_batch_array: '6337906',
      name_search: 'COARHEAR',
      name: "Cordyline aus. 'Red Heart'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 845,
      chnn_stock_retail: 845,
      sppl_prcp: 4.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WDXLW38C',
      statusContent: 'dot',
      hash: 'HGNCTS4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGNCTS4N'
    },
    {
      status: 210,
      sku: 'PM0080567',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337934',
      rtl_batch_array: '6337934',
      name_search: 'COCGSPIR',
      name: 'Cotinus coggygria Golden Spirit',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W7RZKKT;WKP8142W;ARCCVBPY;6ABDDDPH;8S43T2GX',
      statusContent: 'dot',
      hash: 'S7KZ1LK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7KZ1LK3'
    },
    {
      status: 210,
      sku: 'PM0080541',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337907',
      rtl_batch_array: '6337907',
      name_search: 'COARSTAR',
      name: "Cordyline australis 'Red Star'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9ZWEBRTX;L215AB7C;H2KWRL7X;VYKGBPTD;KP1W5YVS;Z9PNA43D;G6CZ3W6J;CR4K2HLY;D69RVERX;KK654R6Z;9BLYZSVB;75XXT1A5;CTJ2WVSG',
      statusContent: 'dot',
      hash: '191N1DR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '191N1DR9'
    },
    {
      status: 910,
      sku: 'PM0072725',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      rng_range_identifier: 'H125150C50',
      rng_range_description: 'H125 cm 150 cm C50',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'L6XZDWWD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L6XZDWWD'
    },
    {
      status: 210,
      sku: 'PM0080542',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337908',
      rtl_batch_array: '6337908',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 752,
      chnn_stock_retail: 752,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: 'J96BTGX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J96BTGX3'
    },
    {
      status: 210,
      sku: 'PM0080543',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337909',
      rtl_batch_array: '6337909',
      name_search: 'COABROUG',
      name: 'Cornus alba Baton Rouge',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1650,
      chnn_stock_retail: 1650,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '271WP7VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '271WP7VB'
    },
    {
      status: 210,
      sku: 'PM0072728',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254906',
      rtl_batch_array: '6254906',
      name_search: 'PRLBONAP',
      name: 'Prunus l. Bonaparte',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S4JCYD4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4JCYD4L'
    },
    {
      status: 810,
      sku: 'PM0072730',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254987',
      rtl_batch_array: '6254987',
      name_search: 'MORMBERR',
      name: 'Morus rotundiloba Mojo Berry',
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LR55B8ZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LR55B8ZZ'
    },
    {
      status: 910,
      sku: 'PM0082890',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'V4YX6A6G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V4YX6A6G'
    },
    {
      status: 210,
      sku: 'PM0080544',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337910',
      rtl_batch_array: '6337910',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 732,
      chnn_stock_retail: 732,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'A54YE8JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A54YE8JH'
    },
    {
      status: 810,
      sku: 'PM0072731',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254988',
      rtl_batch_array: '6254988',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '4CT4833W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CT4833W'
    },
    {
      status: 210,
      sku: 'PM0080545',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337911',
      rtl_batch_array: '6337911',
      name_search: 'COAGOUCH',
      name: "Cornus alba 'Gouchaultii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 493,
      chnn_stock_retail: 493,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TAV2L6N;H9C9SS8D;HEE4X6BD;J151J7WV;J66HK6YK;X6DCELLE;TZCV63VP;98A9EHSR',
      statusContent: 'dot',
      hash: 'DAVSPB64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAVSPB64'
    },
    {
      status: 810,
      sku: 'PM0072733',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254990',
      rtl_batch_array: '6254990',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'HLG91L4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLG91L4P'
    },
    {
      status: 810,
      sku: 'PM0072734',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254991',
      rtl_batch_array: '6298746, 6254991',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 120,
      sppl_prcp: 35.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'V9VKN7XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9VKN7XB'
    },
    {
      status: 810,
      sku: 'PM0072735',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254992',
      rtl_batch_array: '6254992',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080STC10',
      rng_range_identifier: 'STE080C10',
      rng_range_description: 'Stem 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'RDWVRNTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDWVRNTK'
    },
    {
      status: 210,
      sku: 'PM0080546',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337912',
      rtl_batch_array: '6337912',
      name_search: 'COSHGOLD',
      name: "Cornus sericea 'Hedgerow's Gold'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ER3VHNB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ER3VHNB1'
    },
    {
      status: 810,
      sku: 'PM0072737',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254994',
      rtl_batch_array: '6254994',
      name_search: 'PRKSZAKU',
      name: "Prunus 'Kiku-shidare-zakura'",
      sppl_size_code: '180STC15',
      rng_range_identifier: 'STE180C15',
      rng_range_description: 'Stem 180 cm C15',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 29.07,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VRTLWVT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRTLWVT8'
    },
    {
      status: 810,
      sku: 'PM0072738',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254995',
      rtl_batch_array: '6254995',
      name_search: 'PRBRIDE',
      name: "Prunus 'The Bride'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 29.07,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W7KWKBRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7KWKBRP'
    },
    {
      status: 810,
      sku: 'PM0082891',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349375',
      rtl_batch_array: '6349375',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'AW2HS6DD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AW2HS6DD'
    },
    {
      status: 810,
      sku: 'PM0072739',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254997',
      rtl_batch_array: '6254997',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '100STC12',
      rng_range_identifier: 'STE100C12',
      rng_range_description: 'Stem 100 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'PRSL66H2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRSL66H2'
    },
    {
      status: 210,
      sku: 'PM0080547',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337913',
      rtl_batch_array: '6337913',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 852,
      chnn_stock_retail: 852,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'XR66BD1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XR66BD1G'
    },
    {
      status: 810,
      sku: 'PM0072740',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254998',
      rtl_batch_array: '6254998',
      name_search: 'QUPGDWAR',
      name: "Quercus palustris 'Green Dwarf'",
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 28.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XS382LLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS382LLV'
    },
    {
      status: 210,
      sku: 'PM0080548',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337914',
      rtl_batch_array: '6337914',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 601,
      chnn_stock_retail: 601,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: '5B452LNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B452LNL'
    },
    {
      status: 810,
      sku: 'PM0072741',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255033',
      rtl_batch_array: '6255033',
      name_search: 'QUPGDWAR',
      name: "Quercus palustris 'Green Dwarf'",
      sppl_size_code: '150STC10',
      rng_range_identifier: 'STE150C10',
      rng_range_description: 'Stem 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8E5NYZJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8E5NYZJA'
    },
    {
      status: 810,
      sku: 'PM0072742',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255034',
      rtl_batch_array: '6255034',
      name_search: 'QUPGDWAR',
      name: "Quercus palustris 'Green Dwarf'",
      sppl_size_code: '200STC15',
      rng_range_identifier: 'STE200C15',
      rng_range_description: 'Stem 200 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WB85SL4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB85SL4J'
    },
    {
      status: 210,
      sku: 'PM0080549',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337915',
      rtl_batch_array: '6337915',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 3262,
      chnn_stock_retail: 3262,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'TDPZR82L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDPZR82L'
    },
    {
      status: 810,
      sku: 'PM0072743',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255036',
      rtl_batch_array: '6255036',
      name_search: 'VIESKIMO',
      name: "Viburnum 'Eskimo'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5BBGC5WW',
      statusContent: 'dot',
      hash: 'P6K47KYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6K47KYY'
    },
    {
      status: 810,
      sku: 'PM0072744',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255037',
      rtl_batch_array: '6255037',
      name_search: 'VIESKIMO',
      name: "Viburnum 'Eskimo'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5BBGC5WW',
      statusContent: 'dot',
      hash: 'ZPV4YLCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPV4YLCJ'
    },
    {
      status: 810,
      sku: 'PM0072745',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255038',
      rtl_batch_array: '6255038',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '19CEZ7LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19CEZ7LC'
    },
    {
      status: 810,
      sku: 'PM0072746',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255039',
      rtl_batch_array: '6255039',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      statusContent: 'dot',
      hash: 'H5ATRXA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5ATRXA1'
    },
    {
      status: 210,
      sku: 'PM0072747',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255114',
      rtl_batch_array: '6255114',
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW2EVKB5;SP7Y5ZWK;SX1DDW1R;JCCEY4LT;A3VB9EVA;HY6AJV5K;CGP52GK8;YKBYTZKC;C9PLV17X;8YNL22WX;Z2CG7A8J;RJD8YAXS;A9KC643C;5V2J3N1E;5AGVBE54;D21HDP9S',
      statusContent: 'dot',
      hash: 'W1TBDDAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1TBDDAN'
    },
    {
      status: 210,
      sku: 'PM0072748',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304965',
      rtl_batch_array: '6304965, 6255115',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 2950,
      chnn_stock_retail: 3250,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'LEJ1Z78D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEJ1Z78D'
    },
    {
      status: 210,
      sku: 'PM0080550',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337916',
      rtl_batch_array: '6337916',
      name_search: 'COASPAET',
      name: "Cornus alba 'Spaethii'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 790,
      chnn_stock_retail: 790,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SJBEYKD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJBEYKD9'
    },
    {
      status: 210,
      sku: 'PM0080551',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337917',
      rtl_batch_array: '6337917',
      name_search: 'COEECHIN',
      name: 'Cornus elliptica Empress of China',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_order_minimum: 5,
      sppl_prcp: 5.214,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7P1C6V3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7P1C6V3W'
    },
    {
      status: 210,
      sku: 'PM0080552',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337918',
      rtl_batch_array: '6337918',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 1038,
      chnn_stock_retail: 1038,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: 'ZZ28TKTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZ28TKTB'
    },
    {
      status: 210,
      sku: 'PM0072753',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255123',
      rtl_batch_array: '6255123',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2180,
      chnn_stock_retail: 2180,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: 'NG3C3VYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG3C3VYR'
    },
    {
      status: 210,
      sku: 'PM0080553',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337919',
      rtl_batch_array: '6337919',
      name_search: 'COFRAINB',
      name: "Cornus florida 'Rainbow'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 5.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NN7JS5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NN7JS5B'
    },
    {
      status: 810,
      sku: 'PM0082892',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349376',
      rtl_batch_array: '6349376',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 31.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'JHZD4Y5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHZD4Y5Y'
    },
    {
      status: 810,
      sku: 'PM0082893',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349380',
      rtl_batch_array: '6349380',
      name_search: 'LOPURPUS',
      name: 'Lonicera purpusii',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4NG38KW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NG38KW7'
    },
    {
      status: 810,
      sku: 'PM0082894',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349381',
      rtl_batch_array: '6349381',
      name_search: 'LOPURPUS',
      name: 'Lonicera purpusii',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H5WEN972',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5WEN972'
    },
    {
      status: 810,
      sku: 'PM0082895',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349382',
      rtl_batch_array: '6349382',
      name_search: 'LOPURPUS',
      name: 'Lonicera purpusii',
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11JJE62N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11JJE62N'
    },
    {
      status: 810,
      sku: 'PM0082896',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349383',
      rtl_batch_array: '6349383',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'P34EB5DK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P34EB5DK'
    },
    {
      status: 810,
      sku: 'PM0082897',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349384',
      rtl_batch_array: '6349384',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.954,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'LBSPXAY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LBSPXAY4'
    },
    {
      status: 810,
      sku: 'PM0082898',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349385',
      rtl_batch_array: '6349385',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'DWWVHGGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWWVHGGH'
    },
    {
      status: 810,
      sku: 'PM0082899',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349386',
      rtl_batch_array: '6349386',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'DBHP8E79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBHP8E79'
    },
    {
      status: 210,
      sku: 'PM0080554',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337920',
      rtl_batch_array: '6337920',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: '5XYR7Y56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XYR7Y56'
    },
    {
      status: 210,
      sku: 'PM0051799',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300209',
      rtl_batch_array: '6300209, 6385787',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 13176,
      chnn_stock_retail: 14176,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '42DB99DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42DB99DN'
    },
    {
      status: 210,
      sku: 'PM0080568',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337935',
      rtl_batch_array: '6337935',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2530,
      chnn_stock_retail: 2530,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: 'BRW9NL4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRW9NL4A'
    },
    {
      status: 210,
      sku: 'PM0029013',
      core_name: 'Plant',
      sppl_ean: '8720664864702',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6147171',
      rtl_batch_array: '6147171, 6220782, 6090730, 6164289',
      name_search: 'GEMBVARI',
      name: "Geranium macrorrhizum 'Bevan's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 8356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8YYPL3HW',
      statusContent: 'dot',
      hash: '81NBL3Y4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81NBL3Y4'
    },
    {
      status: 210,
      sku: 'PM0072754',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255124',
      rtl_batch_array: '6255124',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 269,
      chnn_stock_retail: 269,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'BPE6AW49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPE6AW49'
    },
    {
      status: 910,
      sku: 'PM0072749',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SACONFUS',
      name: 'Sarcococca confusa',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore:
        'C9251DGD;4AK589AB;GT6C1BJL;77RP3J8Y;4ZPJNXK5;RHJJ77R5;1E1112G6;A3KPK1CH;DCNZEK7T;6CZ7HE6H;5EEDE6GG;A535J46V;VERWST57;13ZDCST5;8S57K3RX;TPNXG1TC;LS2CKYLV;REENVR6J',
      statusContent: 'dot',
      hash: 'GHR2KX1N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GHR2KX1N'
    },
    {
      status: 210,
      sku: 'PM0051867',
      core_name: 'Plant',
      sppl_ean: '8720664812338',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079257',
      rtl_batch_array: '6079257',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '020040C2',
      rng_range_identifier: 'H020040C2',
      rng_range_description: 'H20 cm 40 cm C2',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_prcp: 1.274,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '8GD88SDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GD88SDE'
    },
    {
      status: 910,
      sku: 'PM0017186',
      core_name: 'Plant',
      sppl_ean: '8720626373754',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '5602639',
      rtl_batch_array: '6309481, 5602639, 6320898, 6348997',
      name_search: 'TIPSKYRO',
      name: "Tiarella 'Pink Skyrocket'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3,
      chnn_stock_retail: 2047,
      sppl_order_minimum: 3,
      sppl_prcp: 1.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '946BJAGH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '946BJAGH'
    },
    {
      status: 210,
      sku: 'PM0080555',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337921',
      rtl_batch_array: '6337921',
      name_search: 'COPUMILA',
      name: 'Cornus pumila',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 5,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G52EZG78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G52EZG78'
    },
    {
      status: 210,
      sku: 'PM0051861',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373401',
      rtl_batch_array: '6230359, 6373401',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2517,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '9B1VXNNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B1VXNNN'
    },
    {
      status: 910,
      sku: 'PM0051775',
      core_name: 'Plant',
      sppl_ean: '8720664873193',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6126316',
      rtl_batch_array: '6126316',
      name_search: 'LOJHPROL',
      name: "Lonicera japonica 'Hall's Prolific'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_order_minimum: 6,
      sppl_prcp: 2.772,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHKL1DZY;PEANXCLV;CK5DDGCZ;2BXS6WAH;D3X52NJ1;5J46GSBA;5H8AKRL2;V11VCCSK;XE1KT76N;YV37XS54;KJAY3KGK',
      statusContent: 'dot',
      hash: 'CT14ZWZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT14ZWZG'
    },
    {
      status: 210,
      sku: 'PM0080569',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337936',
      rtl_batch_array: '6337936',
      name_search: 'COCONGES',
      name: 'Cotoneaster congestus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4JBE99L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4JBE99L'
    },
    {
      status: 210,
      sku: 'PM0080556',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337922',
      rtl_batch_array: '6337922',
      name_search: 'COSCARDI',
      name: "Cornus sericea 'Cardinal'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JVWLSYPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVWLSYPD'
    },
    {
      status: 210,
      sku: 'PM0080557',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337924',
      rtl_batch_array: '6337924',
      name_search: 'COVFCHOC',
      name: "Corokia virgata 'Frosted Chocolate'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XHTXVAYN;7SCRPH4J;W4KNJZ4V;E4DHLZJ6;7S7SV83J;49SASSYD;WLHWSY3V;6KBSN489;A6HL5EJR;ADP2CTLT;BKDATHNC;E5T5HHNV',
      statusContent: 'dot',
      hash: 'YRSKV3LR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRSKV3LR'
    },
    {
      status: 210,
      sku: 'PM0012841',
      core_name: 'Plant',
      sppl_ean: '8720626307520',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054704',
      rtl_batch_array: '6054704',
      name_search: 'IRRITZ',
      name: "Iris (P) 'Ritz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EW6GZY36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW6GZY36'
    },
    {
      status: 210,
      sku: 'PM0051796',
      core_name: 'Plant',
      sppl_ean: '8720626372115',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027128',
      rtl_batch_array: '6027128',
      name_search: 'SYGRANDI',
      name: 'Symphytum grandiflorum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 920,
      chnn_stock_retail: 920,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZPZ2L588',
      statusContent: 'dot',
      hash: 'EYSPWB34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYSPWB34'
    },
    {
      status: 210,
      sku: 'PM0080558',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337925',
      rtl_batch_array: '6337925',
      name_search: 'COVSUNSP',
      name: "Corokia virgata 'Sunsplash'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1G1YC382;H4CA8ALG;R9C7D3DZ;BZ3B14CK;BCBBXW9Y;2P4J9RCG;28HPAW88;BC4PKBDX;R1YN1T19;BRZ1CK88;1231ZE69;5CBXL928;LJS7GBRK',
      statusContent: 'dot',
      hash: 'BRBRBNZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRBRBNZ6'
    },
    {
      status: 210,
      sku: 'PM0080570',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337937',
      rtl_batch_array: '6337937',
      name_search: 'COCDECOR',
      name: 'Cotoneaster conspicuus decorus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SKL57KXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKL57KXV'
    },
    {
      status: 210,
      sku: 'PM0080559',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337926',
      rtl_batch_array: '6337926',
      name_search: 'COVIRGAT',
      name: 'Corokia virgata',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V69H7PST;9DN64HZ4',
      statusContent: 'dot',
      hash: '22EBVZ8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22EBVZ8G'
    },
    {
      status: 210,
      sku: 'PM0051825',
      core_name: 'Plant',
      sppl_ean: '8720349416530',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027309',
      rtl_batch_array: '6027309',
      name_search: 'GEROZANN',
      name: 'Geranium Rozanne',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2857,
      chnn_stock_retail: 2857,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZPJ4SN3;GT4DVXXL;C24JN3BT;XBYX425W;RNV4HWHL;JJ79CY8L;Z3PSHXBT;RRSTZT5E;L3WB9GNP;RB8ELNE5;XDL56ESV;BVLK6EXK;J1XBHEEE',
      statusContent: 'dot',
      hash: 'H1DGACLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1DGACLW'
    },
    {
      status: 210,
      sku: 'PM0004489',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350443',
      rtl_batch_array: '5364177, 6350443, 6187643',
      name_search: 'HEREGINA',
      name: "Heuchera 'Regina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 624,
      chnn_stock_retail: 1001,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JH14J4W7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH14J4W7'
    },
    {
      status: 210,
      sku: 'PM0009149',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6208079',
      rtl_batch_array: '5902400, 6208079, 6356608',
      name_search: 'POHUMILE',
      name: 'Polygonatum humile',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 188,
      chnn_stock_retail: 302,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NTCC96KR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTCC96KR'
    },
    {
      status: 210,
      sku: 'PM0029887',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6269573',
      rtl_batch_array: '6350181, 6269573, 6385452, 6340025',
      name_search: 'GALSPINK',
      name: "Gaura lindheimeri 'Siskiyou Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 11997,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZWVG6YET;RD5RT11V;BYPTWR7D;KY645T1Z;5RTYJEAT;W1BWWRH1;WVNK4956;TAS4P527;2NZ14NS9',
      statusContent: 'dot',
      hash: 'SGRVXSCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGRVXSCA'
    },
    {
      status: 210,
      sku: 'PM0080560',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337927',
      rtl_batch_array: '6337927',
      name_search: 'COSALBA',
      name: "Cortaderia selloana 'Alba'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 851,
      chnn_stock_retail: 851,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D6LYTSC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6LYTSC2'
    },
    {
      status: 210,
      sku: 'PM0051838',
      core_name: 'Plant',
      sppl_ean: '8720349455829',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027331',
      rtl_batch_array: '6027331',
      name_search: 'ILCRJELE',
      name: "Ilex crenata 'Jelena'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2964,
      chnn_stock_retail: 2964,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YL2WT2T7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YL2WT2T7'
    },
    {
      status: 210,
      sku: 'PM0027459',
      core_name: 'Plant',
      sppl_ean: '8720349411863',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495231',
      rtl_batch_array: '6133207, 5495231, 6276024',
      name_search: 'CAGRAYI',
      name: 'Carex grayi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 245,
      chnn_stock_retail: 2913,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CL7ELY6X;1JA53KZS;HWD343T1;ZLJK4X5C;ZWJ5A69N;NANZD1L1;EBCD1PVW;GAT5Y7Z2;BE1Z47BK;LKVACE45;K9SSW437;W3GE4ZN8;61B7DVB3;K69CLEBR;LH39JBG6;EXX7P8RL',
      statusContent: 'dot',
      hash: 'Z91ZP4ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z91ZP4ZY'
    },
    {
      status: 210,
      sku: 'PM0080561',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337928',
      rtl_batch_array: '6337928',
      name_search: 'COSROSEA',
      name: "Cortaderia selloana 'Rosea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 417,
      chnn_stock_retail: 417,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25X4TCHJ;27YWYHL2;Y7GGS9RN;A1CBSRN5;82XSDGPX;G2Y373YL;WG33R43S;H1AWYZBV',
      statusContent: 'dot',
      hash: '6NWA2974',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NWA2974'
    },
    {
      status: 210,
      sku: 'PM0080562',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337929',
      rtl_batch_array: '6337929',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: 'E8V4E38X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8V4E38X'
    },
    {
      status: 210,
      sku: 'PM0080563',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337930',
      rtl_batch_array: '6337930',
      name_search: 'COPAUCIF',
      name: 'Corylopsis pauciflora',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1728,
      chnn_stock_retail: 1728,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LDGETZ35;4HWKVX7H;D9G19CA5;ZYCA4VNX;YKTAZCR3;C6GJZPXP;PK4P4993;RL7VSYRE;7A59BD77',
      statusContent: 'dot',
      hash: 'ZL3S29Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZL3S29Y8'
    },
    {
      status: 210,
      sku: 'PM0080564',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337931',
      rtl_batch_array: '6337931',
      name_search: 'COSPICAT',
      name: 'Corylopsis spicata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_order_minimum: 3,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5963NY1K;8K2SS2RD;93J2P8RE;6L1LBRRN;SDBDE4XY;417YHT3J;RNCSDWAE;1V1RDXCR',
      statusContent: 'dot',
      hash: '2R2DZVBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2R2DZVBG'
    },
    {
      status: 210,
      sku: 'PM0080571',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337938',
      rtl_batch_array: '6337938',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 7560,
      chnn_stock_retail: 7560,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: '77P9DZCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77P9DZCP'
    },
    {
      status: 210,
      sku: 'PM0080572',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337939',
      rtl_batch_array: '6337939',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2320,
      chnn_stock_retail: 2320,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'K8S2EE6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8S2EE6W'
    },
    {
      status: 210,
      sku: 'PM0080573',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337940',
      rtl_batch_array: '6337940',
      name_search: 'COAVARIE',
      name: "Cotoneaster atropurpureus 'Variegatus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42GXGL1X',
      statusContent: 'dot',
      hash: 'CJZBTPNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJZBTPNG'
    },
    {
      status: 210,
      sku: 'PM0080574',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337941',
      rtl_batch_array: '6337941',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 804,
      chnn_stock_retail: 804,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'Z446RJGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z446RJGY'
    },
    {
      status: 210,
      sku: 'PM0080575',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337942',
      rtl_batch_array: '6337942',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2446,
      chnn_stock_retail: 2446,
      sppl_order_minimum: 5,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'BK54ZWA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BK54ZWA2'
    },
    {
      status: 210,
      sku: 'PM0080576',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337943',
      rtl_batch_array: '6337943',
      name_search: 'COLACTEU',
      name: 'Cotoneaster lacteus',
      sppl_size_code: '060070C3',
      rng_range_identifier: 'H060070C3',
      rng_range_description: 'H60 cm 70 cm C3',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBLSAC4B;PR1173VV;DPHVETH3;7BA9YC8Y;SLHYXZLL;77D3WDW4',
      statusContent: 'dot',
      hash: 'K3HXG7TX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3HXG7TX'
    },
    {
      status: 210,
      sku: 'PM0080577',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337944',
      rtl_batch_array: '6337944',
      name_search: 'COMICROP',
      name: 'Coriaria microphylla',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZSY9JYTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSY9JYTL'
    },
    {
      status: 210,
      sku: 'PM0080578',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337945',
      rtl_batch_array: '6337945',
      name_search: 'COMICROP',
      name: 'Coriaria microphylla',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '27ZCGWLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27ZCGWLD'
    },
    {
      status: 810,
      sku: 'PM0080579',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337946',
      rtl_batch_array: '6337946',
      name_search: 'COINTEGR',
      name: 'Cotoneaster integrifolius',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '54TLNZN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54TLNZN4'
    },
    {
      status: 210,
      sku: 'PM0080580',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337947',
      rtl_batch_array: '6337947',
      name_search: 'CRHOOKER',
      name: 'Crinodendron hookerianum',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 787,
      chnn_stock_retail: 787,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KG8XW2NE',
      statusContent: 'dot',
      hash: 'HCB6V4JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCB6V4JH'
    },
    {
      status: 210,
      sku: 'PM0080581',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337948',
      rtl_batch_array: '6337948',
      name_search: 'CYBEANII',
      name: 'Cytisus beanii',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DY8BLE5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY8BLE5R'
    },
    {
      status: 810,
      sku: 'PM0082900',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349387',
      rtl_batch_array: '6349387',
      name_search: 'MASIEBOL',
      name: 'Magnolia sieboldii',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '992PB4CA;HSSZ9GWK;K1VDH3VC;68D7TER8;J9KH1PXR;4P2XTTL4;V57LCPKP;6TDKNEHR',
      statusContent: 'dot',
      hash: '8689B1EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8689B1EZ'
    },
    {
      status: 810,
      sku: 'PM0082901',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349388',
      rtl_batch_array: '6349388',
      name_search: 'MASIEBOL',
      name: 'Magnolia sieboldii',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '992PB4CA;HSSZ9GWK;K1VDH3VC;68D7TER8;J9KH1PXR;4P2XTTL4;V57LCPKP;6TDKNEHR',
      statusContent: 'dot',
      hash: 'NV3GYYWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV3GYYWP'
    },
    {
      status: 910,
      sku: 'PM0082902',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MEGERMAN',
      name: 'Mespilus germanica',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'A74E7AXE;8AS3Y1K6;99GBS7W4;H57WVXRG;LTDZ2597;NLDB3SJS;V1131G5E;D6SCVHDN;452E2C62;KHX9G811;Y3B6B6S7;BZB3X34V;7KRP9NZJ;EHY9HBPC;LXAAK7A5;Z91HW9DJ;BDKVC6HX;6492SSST',
      statusContent: 'dot',
      hash: 'W3Z9XLGX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W3Z9XLGX'
    },
    {
      status: 210,
      sku: 'PM0004147',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6350535',
      rtl_batch_array: '6084724, 6350535, 5364242, 6091456',
      name_search: 'HOUALBOM',
      name: "Hosta 'Undulata Albomarginata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1550,
      chnn_stock_retail: 9510,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SCNVJAPP',
      statusContent: 'dot',
      hash: '9BYDJAV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BYDJAV3'
    },
    {
      status: 210,
      sku: 'PM0051883',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307539',
      rtl_batch_array: '6307539',
      name_search: 'MABETTY',
      name: "Magnolia 'Betty'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 131,
      chnn_stock_retail: 131,
      sppl_prcp: 12.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12YLB58;V48KACWP',
      statusContent: 'dot',
      hash: 'AXG6BWJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXG6BWJ2'
    },
    {
      status: 210,
      sku: 'PM0080582',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337949',
      rtl_batch_array: '6337949',
      name_search: 'CYGOLDFI',
      name: "Cytisus 'Goldfinch'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BSRD8P2X;K5TWX7XN;JY7SNST4;S3KKCHNA;CJH9P1E7;RB6K6RG2;RJ8665PT;XAVGTSW6;ZGJJCPCD;B4PDNBLN;VR8SS8HZ;VECEZWXJ;WPP896VN',
      statusContent: 'dot',
      hash: 'HYVG3WW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYVG3WW6'
    },
    {
      status: 210,
      sku: 'PM0080608',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337976',
      rtl_batch_array: '6337976',
      name_search: 'ELQUICKS',
      name: "Elaeagnus 'Quicksilver'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2015,
      chnn_stock_retail: 2015,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KN97TDCP;KJXSSG71;2JKXXTN9;8HNB2WT5;KVHVCAW3;V7PDSJ4N',
      statusContent: 'dot',
      hash: '8KR7C6X9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KR7C6X9'
    },
    {
      status: 210,
      sku: 'PM0080583',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337950',
      rtl_batch_array: '6337950',
      name_search: 'CYLENA',
      name: "Cytisus 'Lena'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1255,
      chnn_stock_retail: 1255,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XP6Z81BW;JP7135W1;XGVABW97;GECHGL8H;92W19K91;NEJVW5E1;5CXD92TW;RZELBWXD;S1PV1E7B;27P2SZ8P',
      statusContent: 'dot',
      hash: 'YN2HVNPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN2HVNPH'
    },
    {
      status: 210,
      sku: 'PM0026485',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301642',
      rtl_batch_array: '6301642',
      name_search: 'HECCAN',
      name: "Heuchera 'Can Can'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z1SBAZ6Z;7NC1BL9N',
      statusContent: 'dot',
      hash: 'CSLWL77A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSLWL77A'
    },
    {
      status: 210,
      sku: 'PM0080584',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337951',
      rtl_batch_array: '6337951',
      name_search: 'CYLUNA',
      name: "Cytisus 'Luna'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 635,
      chnn_stock_retail: 635,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AHVNNPYE;LPBSCV47;7VNWGXPE;HW95PR4H;41YENPR5;SYCGXA14;NW4G5NVE;5CV5EDD8',
      statusContent: 'dot',
      hash: 'WAKH6VGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAKH6VGT'
    },
    {
      status: 210,
      sku: 'PM0003086',
      core_name: 'Plant',
      sppl_ean: '8720349429240',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '5817907',
      rtl_batch_array: '5817907, 6339962',
      name_search: 'TARRED',
      name: "Tanacetum (C) 'Robinson's Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 680,
      chnn_stock_retail: 1980,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TTABL57S;EC5A9PZA;2LYGE35P;AS966H3W;ZZG5D73C',
      statusContent: 'dot',
      hash: '9DPDGAB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DPDGAB2'
    },
    {
      status: 210,
      sku: 'PM0080585',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337952',
      rtl_batch_array: '6337952',
      name_search: 'CYSVANES',
      name: "Cytisus scoparius 'Vanesse'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GW54SN5P;2SR1CSKP;72GSKG6A;H9WVES2A;DRBX2GNV;ZS264Z16;SRYNKC99;P2TCLT86;K88X1KX8',
      statusContent: 'dot',
      hash: 'D8AA1CB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8AA1CB3'
    },
    {
      status: 910,
      sku: 'PM0082903',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MEGERMAN',
      name: 'Mespilus germanica',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      imageCore:
        'A74E7AXE;8AS3Y1K6;99GBS7W4;H57WVXRG;LTDZ2597;NLDB3SJS;V1131G5E;D6SCVHDN;452E2C62;KHX9G811;Y3B6B6S7;BZB3X34V;7KRP9NZJ;EHY9HBPC;LXAAK7A5;Z91HW9DJ;BDKVC6HX;6492SSST',
      statusContent: 'dot',
      hash: '4862CC25',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4862CC25'
    },
    {
      status: 810,
      sku: 'PM0082904',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349391',
      rtl_batch_array: '6349391',
      name_search: 'NOANTARC',
      name: 'Nothofagus antarctica',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.973,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B2G93J6',
      statusContent: 'dot',
      hash: '2ZNB2PV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZNB2PV3'
    },
    {
      status: 810,
      sku: 'PM0082905',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349392',
      rtl_batch_array: '6349392',
      name_search: 'NOANTARC',
      name: 'Nothofagus antarctica',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B2G93J6',
      statusContent: 'dot',
      hash: 'KGB7XCB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGB7XCB2'
    },
    {
      status: 210,
      sku: 'PM0080586',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337953',
      rtl_batch_array: '6337953',
      name_search: 'DABASTRI',
      name: "Daphne burkwoodii 'Astrid'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N3Z4D4YK',
      statusContent: 'dot',
      hash: 'Y2YYTA48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2YYTA48'
    },
    {
      status: 210,
      sku: 'PM0080587',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337954',
      rtl_batch_array: '6378234, 6337954',
      name_search: 'DATPFRAG',
      name: 'Daphne transatlantica Pink Fragrance',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 744,
      chnn_stock_retail: 5744,
      sppl_order_minimum: 3,
      sppl_prcp: 5.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELKZZZ41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELKZZZ41'
    },
    {
      status: 210,
      sku: 'PM0059730',
      core_name: 'Plant',
      sppl_ean: '8720664853591',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6125545',
      rtl_batch_array: '6125545',
      name_search: 'BLSPICAN',
      name: 'Blechnum spicant',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.509,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G18GPEPK;GTK7Y5ZW;BGP9LHPL;A1TBVCV9;TR275HHX;YETY52TE;8DSLP135;2WHPX6KW;ABCP4H6T;SNSYBKCX;K31VDCJK;RH4J4HGD;DJEBD9P2;K2RLTZK5;WW6Z9B9K;K7AH5W3H;9NAS73LC',
      statusContent: 'dot',
      hash: 'GEW6P2C2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEW6P2C2'
    },
    {
      status: 810,
      sku: 'PM0080588',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337955',
      rtl_batch_array: '6337955',
      name_search: 'DAODORA',
      name: 'Daphne odora',
      sppl_size_code: '025040C2',
      rng_range_identifier: 'H025040C2',
      rng_range_description: 'H25 cm 40 cm C2',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 3.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GA9N2G2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GA9N2G2G'
    },
    {
      status: 210,
      sku: 'PM0047747',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353422',
      rtl_batch_array: '6353422',
      name_search: 'SETSGLRO',
      name: "Sedum t. 'Seduction Green Leaves Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 457,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RVWHK242;J76T35R3;TVYY5SEA;6S6S659T;BRR3WXP8;V3NPKAE3;BEP9BXVL',
      statusContent: 'dot',
      hash: '9472CED4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9472CED4'
    },
    {
      status: 210,
      sku: 'PM0001129',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349582',
      rtl_batch_array: '6349055, 6349582, 6267605',
      name_search: 'ATFFEMIN',
      name: 'Athyrium filix-femina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6000,
      chnn_stock_retail: 18892,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGWSJ4WR;Y5ZW7H7Z;VYWWGHEP',
      statusContent: 'dot',
      hash: 'KS6RYTWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS6RYTWL'
    },
    {
      status: 210,
      sku: 'PM0013217',
      core_name: 'Plant',
      sppl_ean: '8720349400270',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431696',
      rtl_batch_array: '5431696',
      name_search: 'ANPCOMPA',
      name: "Andromeda polifolia 'Compacta'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 2399,
      chnn_stock_retail: 2399,
      sppl_prcp: 2.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DHR6WDW;49K2T5N3;7BHD3Z3E;VJR4D2A1;5E2EC5RE;RN5JNPDS;9ELGTAZ9;ENYNN284;P4ZHW4PZ',
      imageBatch: 'RY3T7LL3',
      statusContent: 'dot',
      hash: 'AEYJY7NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AEYJY7NS'
    },
    {
      status: 210,
      sku: 'PM0010912',
      core_name: 'Plant',
      sppl_ean: '8720626344594',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495281',
      rtl_batch_array: '6147752, 5495281, 6320859',
      name_search: 'ECPGJEWE',
      name: "Echinacea purpurea 'Green Jewel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2874,
      chnn_stock_retail: 4861,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N15T54N7;6WZTDRBZ;6WCGDVB7;E1CGJ5CK;4BR1J7C8;E467VAZ8;Z1W4VR2E;P918BZ3L',
      statusContent: 'dot',
      hash: '45T8ANZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45T8ANZL'
    },
    {
      status: 810,
      sku: 'PM0082907',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349394',
      rtl_batch_array: '6349394',
      name_search: 'NOANTARC',
      name: 'Nothofagus antarctica',
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B2G93J6',
      statusContent: 'dot',
      hash: '4T6CTXLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T6CTXLY'
    },
    {
      status: 210,
      sku: 'PM0072825',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255386',
      rtl_batch_array: '6255386',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'NSKNL4ZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSKNL4ZR'
    },
    {
      status: 210,
      sku: 'PM0051920',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184119',
      rtl_batch_array: '6184119',
      name_search: 'WEFUCHSI',
      name: 'Weigela Fuchsine',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E3JXSNBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3JXSNBP'
    },
    {
      status: 810,
      sku: 'PM0071932',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6198294',
      rtl_batch_array: '6198294',
      name_search: 'MEGERMAN',
      name: 'Mespilus germanica',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A74E7AXE;8AS3Y1K6;99GBS7W4;H57WVXRG;LTDZ2597;NLDB3SJS;V1131G5E;D6SCVHDN;452E2C62;KHX9G811;Y3B6B6S7;BZB3X34V;7KRP9NZJ;EHY9HBPC;LXAAK7A5;Z91HW9DJ;BDKVC6HX;6492SSST',
      statusContent: 'dot',
      hash: '2HT2BXKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HT2BXKB'
    },
    {
      status: 210,
      sku: 'PM0072345',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244723',
      rtl_batch_array: '6244723',
      name_search: 'PYCCONFE',
      name: "Pyrus communis 'Conference'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78TNR6AS;DVHDGPC2;NR5ETZAS;GZPRZKDK;B7JA2AX7;8VH6VLWV;WVSHB7Y8;EBYNZR1D;5AHEK6LG;9Z3EPSPN;P65ADCG8;9H9DSJ2B;ASK4T5HW;VRYCD2NE;VR62KGXS;C7LZAEBR;DW74T1JH;T9ZDR5SN;AL1GZY1W;SEPY5SRN;47PVKKJK;A7545WS5;4221RV75',
      statusContent: 'dot',
      hash: 'SEANK6T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEANK6T8'
    },
    {
      status: 810,
      sku: 'PM0072818',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373171',
      rtl_batch_array: '6373171, 6287003',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 96,
      chnn_stock_retail: 122,
      sppl_prcp: 8.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'VNLLD9J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNLLD9J1'
    },
    {
      status: 910,
      sku: 'PM0082908',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'BBPAVVXA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BBPAVVXA'
    },
    {
      status: 210,
      sku: 'PM0072762',
      core_name: 'Plant',
      sppl_ean: '8720626336940',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207474',
      rtl_batch_array: '5207474',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 969,
      chnn_stock_retail: 969,
      sppl_prcp: 1.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      imageBatch: 'APGND22Z',
      statusContent: 'dot',
      hash: 'BTD6X6GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTD6X6GS'
    },
    {
      status: 810,
      sku: 'PM0082906',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349393',
      rtl_batch_array: '6349393',
      name_search: 'NOANTARC',
      name: 'Nothofagus antarctica',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B2G93J6',
      statusContent: 'dot',
      hash: 'CW89VK2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW89VK2L'
    },
    {
      status: 210,
      sku: 'PM0072764',
      core_name: 'Plant',
      sppl_ean: '8720626337183',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207476',
      rtl_batch_array: '5207476',
      name_search: 'BUDRRED',
      name: "Buddleja davidii 'Royal Red'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 1.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X7WNBLAD;WC84DAWN;157GS7J2;TDKNV8VS;W4LZY3ND;7J6L8K6S;D2D7KY7P',
      imageBatch: 'KBVY5T6N',
      statusContent: 'dot',
      hash: '5YVYVKKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YVYVKKC'
    },
    {
      status: 210,
      sku: 'PM0072765',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207477',
      rtl_batch_array: '5207477',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 1.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: '84A3LRYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84A3LRYK'
    },
    {
      status: 210,
      sku: 'PM0072771',
      core_name: 'Plant',
      sppl_ean: '8720626322165',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 3,
      btch_active_retail: '5207561',
      rtl_batch_array: '5763683, 5207561, 6258856',
      name_search: 'RHPWISEM',
      name: "Rhododendron (Y) 'Percy Wiseman'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 2161,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNNRBV1D;PZBX5EP6;YTHLV9NB;A69KCXA4;735GA1KT;LWWRHR9G;11JH1NZ1;L473VECC;J6LC2C6H;4KS2EBLB;64C15AW6;SC6WX485;E7S3BHBK;BD1WW6RV;1CRSNL7N;ENS93EYV;PLRENXEE;JWEENP88;PWLTCB1L;11A1ZVXP',
      imageBatch: 'S91JC2DP',
      statusContent: 'dot',
      hash: '2ABWJ59A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ABWJ59A'
    },
    {
      status: 910,
      sku: 'PM0082909',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'KSE3BYRY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KSE3BYRY'
    },
    {
      status: 810,
      sku: 'PM0082910',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349398',
      rtl_batch_array: '6349398',
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.954,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JB9JX3XZ;VTEBW9DL;GD21GLPT;153D2KY4;NS2G53K8;8LTGZBJ9;X1PWTCPA;T8ES29CJ;ZLLK2N28;LE5VCYZ2;PL4AP9XH;Y31HNANX;BELEV3SP;SCED3R6V;5LHZT6YT;ZK85R77R;8L96TE69;AY4HYHAW;4SXTX4YJ',
      statusContent: 'dot',
      hash: 'RJNST8N9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJNST8N9'
    },
    {
      status: 210,
      sku: 'PM0080589',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337956',
      rtl_batch_array: '6337956',
      name_search: 'DEGNIKKO',
      name: "Deutzia gracilis 'Nikko'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1995,
      chnn_stock_retail: 1995,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4XTBD8Y',
      statusContent: 'dot',
      hash: 'AA67DAJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA67DAJN'
    },
    {
      status: 210,
      sku: 'PM0080590',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337957',
      rtl_batch_array: '6337957',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1170,
      chnn_stock_retail: 1170,
      sppl_order_minimum: 5,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: '2T5SSH7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T5SSH7P'
    },
    {
      status: 810,
      sku: 'PM0072787',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254313',
      rtl_batch_array: '6254313',
      name_search: 'LAKSWEEP',
      name: "Larix kaempferi 'Stiff Weeper'",
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KKE3EHBN',
      statusContent: 'dot',
      hash: '12TDTZSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12TDTZSJ'
    },
    {
      status: 210,
      sku: 'PM0072763',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207475',
      rtl_batch_array: '5207475',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      imageBatch: 'RK55D3JR',
      statusContent: 'dot',
      hash: 'WWZHEB6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWZHEB6E'
    },
    {
      status: 810,
      sku: 'PM0082911',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349399',
      rtl_batch_array: '6349399',
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JB9JX3XZ;VTEBW9DL;GD21GLPT;153D2KY4;NS2G53K8;8LTGZBJ9;X1PWTCPA;T8ES29CJ;ZLLK2N28;LE5VCYZ2;PL4AP9XH;Y31HNANX;BELEV3SP;SCED3R6V;5LHZT6YT;ZK85R77R;8L96TE69;AY4HYHAW;4SXTX4YJ',
      statusContent: 'dot',
      hash: 'JS2LX69Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JS2LX69Z'
    },
    {
      status: 810,
      sku: 'PM0082912',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349400',
      rtl_batch_array: '6349400',
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.458,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JB9JX3XZ;VTEBW9DL;GD21GLPT;153D2KY4;NS2G53K8;8LTGZBJ9;X1PWTCPA;T8ES29CJ;ZLLK2N28;LE5VCYZ2;PL4AP9XH;Y31HNANX;BELEV3SP;SCED3R6V;5LHZT6YT;ZK85R77R;8L96TE69;AY4HYHAW;4SXTX4YJ',
      statusContent: 'dot',
      hash: '5RPGXTP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RPGXTP5'
    },
    {
      status: 810,
      sku: 'PM0072775',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254288',
      rtl_batch_array: '6254288',
      name_search: 'EUFCGOLD',
      name: "Euonymus fortunei 'Canadale Gold'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41CCWLLN;RJ3WT5EZ;CYNAA2TN;K7598GVW',
      statusContent: 'dot',
      hash: '5KN7XBXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KN7XBXL'
    },
    {
      status: 810,
      sku: 'PM0072776',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254289',
      rtl_batch_array: '6254289',
      name_search: 'EXRACEMO',
      name: 'Exochorda racemosa',
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BD6XTJ6S;YCBKHZAN',
      statusContent: 'dot',
      hash: 'CCXC85VC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCXC85VC'
    },
    {
      status: 810,
      sku: 'PM0072777',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254290',
      rtl_batch_array: '6254290',
      name_search: 'FICBTURK',
      name: "Ficus carica 'Brown Turkey'",
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DH7T8VNW;5YPR5G21;6H61HPED;4T289JRE;XN7L64H9;64GH657S;SDHBXYBS',
      statusContent: 'dot',
      hash: 'NHVC1WAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHVC1WAA'
    },
    {
      status: 810,
      sku: 'PM0082913',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349401',
      rtl_batch_array: '6349401',
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 50.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JB9JX3XZ;VTEBW9DL;GD21GLPT;153D2KY4;NS2G53K8;8LTGZBJ9;X1PWTCPA;T8ES29CJ;ZLLK2N28;LE5VCYZ2;PL4AP9XH;Y31HNANX;BELEV3SP;SCED3R6V;5LHZT6YT;ZK85R77R;8L96TE69;AY4HYHAW;4SXTX4YJ',
      statusContent: 'dot',
      hash: 'N8S6PX84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8S6PX84'
    },
    {
      status: 810,
      sku: 'PM0072779',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254292',
      rtl_batch_array: '6254292',
      name_search: 'GIBMARIK',
      name: "Ginkgo biloba 'Mariken'",
      sppl_size_code: '180STC15',
      rng_range_identifier: 'STE180C15',
      rng_range_description: 'Stem 180 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCJ5WWEP;HNEG7WE2',
      statusContent: 'dot',
      hash: 'LHHD26SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHHD26SG'
    },
    {
      status: 810,
      sku: 'PM0072780',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254293',
      rtl_batch_array: '6254293',
      name_search: 'GIBMARIK',
      name: "Ginkgo biloba 'Mariken'",
      sppl_size_code: '200STC15',
      rng_range_identifier: 'STE200C15',
      rng_range_description: 'Stem 200 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 43.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCJ5WWEP;HNEG7WE2',
      statusContent: 'dot',
      hash: 'S3EZGVA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3EZGVA9'
    },
    {
      status: 810,
      sku: 'PM0072781',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254294',
      rtl_batch_array: '6254294',
      name_search: 'GIBPENDU',
      name: "Ginkgo biloba 'Pendula'",
      sppl_size_code: '200STC15',
      rng_range_identifier: 'STE200C15',
      rng_range_description: 'Stem 200 cm C15',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 43.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YN9K36EY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN9K36EY'
    },
    {
      status: 810,
      sku: 'PM0072782',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254295',
      rtl_batch_array: '6254295',
      name_search: 'HISYWPIL',
      name: 'Hibiscus syr. Flower Tower White',
      sppl_size_code: '175200C15GE',
      rng_range_identifier: 'FEADH175200C15',
      rng_range_description: 'Feathered H175 cm 200 cm C15',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 28.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2A8DEHJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A8DEHJJ'
    },
    {
      status: 810,
      sku: 'PM0072783',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254300',
      rtl_batch_array: '6254300',
      name_search: 'ILAGKING',
      name: "Ilex altaclerensis 'Golden King'",
      sppl_size_code: '100STC12',
      rng_range_identifier: 'STE100C12',
      rng_range_description: 'Stem 100 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VYHZH6N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYHZH6N8'
    },
    {
      status: 810,
      sku: 'PM0072784',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254309',
      rtl_batch_array: '6254309',
      name_search: 'ILAINGRA',
      name: "Ilex aquifolium 'Ingramii'",
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LC1L5J6A;64KB8Y8V;HEN82888;EP7WP1Z8;NK1XJGN7;1P4D54PL',
      statusContent: 'dot',
      hash: '5CLC2DEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CLC2DEL'
    },
    {
      status: 810,
      sku: 'PM0072785',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254310',
      rtl_batch_array: '6254310',
      name_search: 'ILMLRASC',
      name: 'Ilex meserveae Little Rascal',
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13GARV24;71E7CTSY;BS8SHAWL;XPE48L27',
      statusContent: 'dot',
      hash: 'DY8K3HDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY8K3HDP'
    },
    {
      status: 210,
      sku: 'PM0072833',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255397',
      rtl_batch_array: '6255397',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: '6GB55NSC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GB55NSC'
    },
    {
      status: 210,
      sku: 'PM0072832',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338337',
      rtl_batch_array: '6255396, 6338337',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1382,
      chnn_stock_retail: 1882,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: 'ZNZ67E9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNZ67E9C'
    },
    {
      status: 810,
      sku: 'PM0082914',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349402',
      rtl_batch_array: '6349402',
      name_search: 'PRTRAILB',
      name: "Prunus 'Trailblazer'",
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.608,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K2YRCADL',
      statusContent: 'dot',
      hash: 'CAWSAWA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAWSAWA1'
    },
    {
      status: 810,
      sku: 'PM0072789',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254316',
      rtl_batch_array: '6254316',
      name_search: 'LISGBALL',
      name: "Liquidambar styraciflua 'Gum Ball'",
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AD1C8L74;JCG6GR11;2426BGCE;GZ1LLWGC;J5TP2RT4;T4K8717V;9XXEJV2L;LCW7P16A;91LGRC49',
      statusContent: 'dot',
      hash: 'JBZ1BRH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBZ1BRH1'
    },
    {
      status: 810,
      sku: 'PM0072790',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254317',
      rtl_batch_array: '6254317',
      name_search: 'LISGBALL',
      name: "Liquidambar styraciflua 'Gum Ball'",
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AD1C8L74;JCG6GR11;2426BGCE;GZ1LLWGC;J5TP2RT4;T4K8717V;9XXEJV2L;LCW7P16A;91LGRC49',
      statusContent: 'dot',
      hash: 'SA2DARYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SA2DARYH'
    },
    {
      status: 810,
      sku: 'PM0072791',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254318',
      rtl_batch_array: '6254318',
      name_search: 'MAABOPAL',
      name: "Magnolia acuminata 'Blue Opal'",
      sppl_size_code: '200STC15',
      rng_range_identifier: 'STE200C15',
      rng_range_description: 'Stem 200 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 56.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WBYPLC2X;9VYGZVSR;AVPJY9VD;7DKLX8Y6;7AXZ2X7R;WNDVXXE1;79KSAYWJ;WLBA2VRG',
      statusContent: 'dot',
      hash: '228ABEJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '228ABEJN'
    },
    {
      status: 810,
      sku: 'PM0072792',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254319',
      rtl_batch_array: '6254319',
      name_search: 'MAFLAMIN',
      name: "Malus 'Flamingo'",
      sppl_size_code: '180STC15',
      rng_range_identifier: 'STE180C15',
      rng_range_description: 'Stem 180 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4GHC5NLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GHC5NLA'
    },
    {
      status: 810,
      sku: 'PM0072793',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254320',
      rtl_batch_array: '6254320',
      name_search: 'MAGALAXY',
      name: "Magnolia 'Galaxy'",
      sppl_size_code: '180STC15',
      rng_range_identifier: 'STE180C15',
      rng_range_description: 'Stem 180 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJY2ZY1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJY2ZY1T'
    },
    {
      status: 810,
      sku: 'PM0072795',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254927',
      rtl_batch_array: '6254927',
      name_search: 'MAHSCENT',
      name: "Magnolia 'Heaven Scent'",
      sppl_size_code: '180STC15',
      rng_range_identifier: 'STE180C15',
      rng_range_description: 'Stem 180 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7J17ZK1',
      statusContent: 'dot',
      hash: 'PKELNRVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKELNRVY'
    },
    {
      status: 810,
      sku: 'PM0072796',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254928',
      rtl_batch_array: '6254928',
      name_search: 'MAPBEAUT',
      name: "Malus 'Pink Beauty'",
      sppl_size_code: '150STC12',
      rng_range_identifier: 'STE150C12',
      rng_range_description: 'Stem 150 cm C12',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L92JJEES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L92JJEES'
    },
    {
      status: 210,
      sku: 'PM0072794',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254773',
      rtl_batch_array: '6254773, 5837442',
      name_search: 'LYSROBER',
      name: "Lythrum salicaria 'Robert'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 455,
      chnn_stock_retail: 1037,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6ACR9ZTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ACR9ZTW'
    },
    {
      status: 810,
      sku: 'PM0072755',
      core_name: 'Plant',
      sppl_ean: '8720626309623',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '4866475',
      rtl_batch_array: '4866475',
      name_search: 'LALLADY',
      name: "Lavatera 'Lilac Lady'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YRRPS17L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRRPS17L'
    },
    {
      status: 210,
      sku: 'PM0080591',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337958',
      rtl_batch_array: '6337958',
      name_search: 'DEHPROSE',
      name: "Deutzia hybrida 'Perle Rose'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 339,
      chnn_stock_retail: 339,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1LB24G6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LB24G6W'
    },
    {
      status: 810,
      sku: 'PM0080592',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337959',
      rtl_batch_array: '6337959',
      name_search: 'DERSUNDA',
      name: 'Deutzia Raspberry Sundae',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GAAJ7GBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAAJ7GBC'
    },
    {
      status: 210,
      sku: 'PM0080593',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337961',
      rtl_batch_array: '6337961',
      name_search: 'DICCBLUE',
      name: 'Dianella caerulea Casa Blue',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_order_minimum: 3,
      sppl_prcp: 3.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X4AN6ERB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4AN6ERB'
    },
    {
      status: 210,
      sku: 'PM0072766',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207478',
      rtl_batch_array: '5207478',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: '2KBPJ8AZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KBPJ8AZ'
    },
    {
      status: 210,
      sku: 'PM0080594',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337962',
      rtl_batch_array: '6337962',
      name_search: 'DIRLREV',
      name: 'Dianella revoluta Little Rev',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1860,
      chnn_stock_retail: 1860,
      sppl_order_minimum: 3,
      sppl_prcp: 3.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4PWLGR9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PWLGR9Z'
    },
    {
      status: 210,
      sku: 'PM0080595',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337963',
      rtl_batch_array: '6337963',
      name_search: 'DITWYEEN',
      name: 'Dianella tasmanica Wyeena',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 570,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 3.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9EYXKRPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EYXKRPY'
    },
    {
      status: 210,
      sku: 'PM0080596',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337964',
      rtl_batch_array: '6337964',
      name_search: 'DIRHONEY',
      name: 'Diervilla rivularis Honeybee',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 246,
      chnn_stock_retail: 246,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VTGV6TAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTGV6TAH'
    },
    {
      status: 210,
      sku: 'PM0080597',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337965',
      rtl_batch_array: '6337965',
      name_search: 'DIRTBLAC',
      name: "Diervilla rivularis 'Troja Black'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 622,
      chnn_stock_retail: 622,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VKJAZBXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKJAZBXN'
    },
    {
      status: 210,
      sku: 'PM0080598',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337966',
      rtl_batch_array: '6337966',
      name_search: 'DISESSIL',
      name: 'Diervilla sessilifolia',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 508,
      chnn_stock_retail: 508,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YA5C2WDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YA5C2WDT'
    },
    {
      status: 210,
      sku: 'PM0080599',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337967',
      rtl_batch_array: '6337967',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 964,
      chnn_stock_retail: 964,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RB6BW28E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RB6BW28E'
    },
    {
      status: 810,
      sku: 'PM0080600',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337968',
      rtl_batch_array: '6337968',
      name_search: 'DISESSIL',
      name: 'Diervilla sessilifolia',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZG144SW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG144SW6'
    },
    {
      status: 210,
      sku: 'PM0080601',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337969',
      rtl_batch_array: '6337969',
      name_search: 'DIESVARI',
      name: "Diervilla sessilifolia 'Variegata'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2014,
      chnn_stock_retail: 2014,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1AKHX4BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AKHX4BH'
    },
    {
      status: 210,
      sku: 'PM0080602',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337970',
      rtl_batch_array: '6337970',
      name_search: 'DIHPFOUN',
      name: "Diosma hirsuta 'Pink Fountain'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '53CB1VKE',
      statusContent: 'dot',
      hash: 'VDR2YLW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VDR2YLW1'
    },
    {
      status: 210,
      sku: 'PM0080603',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337971',
      rtl_batch_array: '6337971',
      name_search: 'DIHSGOLD',
      name: "Diosma hirsuta 'Sunset Gold'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AB2Y7NHH;JS3HR3VA;4G69VRG9;L1Y22RH6;BZ21TH48;E6YB3GC7',
      statusContent: 'dot',
      hash: 'XPL9WSCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPL9WSCP'
    },
    {
      status: 210,
      sku: 'PM0072813',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6255024',
      rtl_batch_array: '6280954, 6255024',
      name_search: 'ELSTAUNT',
      name: 'Elsholtzia stauntonii',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 200,
      sppl_prcp: 13.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XLSWTC43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLSWTC43'
    },
    {
      status: 810,
      sku: 'PM0080604',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337972',
      rtl_batch_array: '6337972',
      name_search: 'DIBCASCA',
      name: 'Distylium Blue Cascade',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HGZP8RSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGZP8RSZ'
    },
    {
      status: 910,
      sku: 'PM0080605',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DOHIRSUT',
      name: 'Dorycnium hirsutum',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      statusContent: 'dot',
      hash: '4Z5E779J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4Z5E779J'
    },
    {
      status: 210,
      sku: 'PM0080606',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337974',
      rtl_batch_array: '6337974',
      name_search: 'DRLANCEO',
      name: 'Drimys lanceolata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2240,
      chnn_stock_retail: 2240,
      sppl_order_minimum: 5,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ADG3NHPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADG3NHPS'
    },
    {
      status: 210,
      sku: 'PM0072817',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6255028',
      rtl_batch_array: '6280959, 6255028',
      name_search: 'FRAALNUS',
      name: 'Frangula alnus',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 218,
      chnn_stock_retail: 268,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W6LZP94;WNG5165T;PVE56JTL;NY6S2ASZ',
      statusContent: 'dot',
      hash: 'E2DB9KHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2DB9KHT'
    },
    {
      status: 210,
      sku: 'PM0080607',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337975',
      rtl_batch_array: '6337975',
      name_search: 'DRWINTER',
      name: 'Drimys winteri',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 5,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C1WL1BN4',
      statusContent: 'dot',
      hash: 'EYETE8XS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYETE8XS'
    },
    {
      status: 810,
      sku: 'PM0082915',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349410',
      rtl_batch_array: '6349410',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 49.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: '115JB3JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '115JB3JD'
    },
    {
      status: 810,
      sku: 'PM0082916',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349411',
      rtl_batch_array: '6349411',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 65.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: '152AN7VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '152AN7VW'
    },
    {
      status: 810,
      sku: 'PM0082917',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349413',
      rtl_batch_array: '6349413',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: 'WET438XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WET438XV'
    },
    {
      status: 210,
      sku: 'PM0072835',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255399',
      rtl_batch_array: '6255399',
      name_search: 'PHMBLANC',
      name: "Philadelphus 'Mont Blanc'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.218,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X14TKD9X',
      statusContent: 'dot',
      hash: 'SLVG8318',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLVG8318'
    },
    {
      status: 210,
      sku: 'PM0072836',
      core_name: 'Plant',
      sppl_ean: '8720353089263',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5910965',
      rtl_batch_array: '5910965',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RXKRR61A',
      statusContent: 'dot',
      hash: 'Y65DZ17A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y65DZ17A'
    },
    {
      status: 210,
      sku: 'PM0072837',
      core_name: 'Plant',
      sppl_ean: '8720353089300',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5910968',
      rtl_batch_array: '5910968',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      imageBatch: '7AR2JP2G',
      statusContent: 'dot',
      hash: '2EPV7HC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EPV7HC8'
    },
    {
      status: 210,
      sku: 'PM0072838',
      core_name: 'Plant',
      sppl_ean: '8720353098395',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486250',
      rtl_batch_array: '5486250, 5932382',
      name_search: 'EUFDBLAN',
      name: "Euonymus fortunei 'Dart's Blanket'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9976,
      chnn_stock_retail: 11802,
      sppl_order_minimum: 3,
      sppl_prcp: 0.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E9GXYCXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9GXYCXJ'
    },
    {
      status: 210,
      sku: 'PM0072839',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 3,
      btch_active_retail: '5932511',
      rtl_batch_array: '5932511, 6353584, 6252972',
      name_search: 'VIMARGEN',
      name: "Vinca minor 'Argenteovariegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 451,
      chnn_stock_retail: 835,
      sppl_order_minimum: 3,
      sppl_prcp: 0.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6SHLYCVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SHLYCVH'
    },
    {
      status: 810,
      sku: 'PM0082918',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349414',
      rtl_batch_array: '6349414',
      name_search: 'SODOMEST',
      name: 'Sorbus domestica',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ABTD4J9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ABTD4J9'
    },
    {
      status: 810,
      sku: 'PM0082919',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349415',
      rtl_batch_array: '6349415',
      name_search: 'SODOMEST',
      name: 'Sorbus domestica',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WS32823J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WS32823J'
    },
    {
      status: 810,
      sku: 'PM0082920',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349416',
      rtl_batch_array: '6349416',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'P2XXA972',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2XXA972'
    },
    {
      status: 810,
      sku: 'PM0082921',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349417',
      rtl_batch_array: '6349417',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'SDB5ZN3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDB5ZN3D'
    },
    {
      status: 210,
      sku: 'PM0072831',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255395',
      rtl_batch_array: '6255395',
      name_search: 'PHDBLANC',
      name: "Philadelphus 'Dame Blanche'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRATG3YL',
      statusContent: 'dot',
      hash: '27K2WK14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27K2WK14'
    },
    {
      status: 210,
      sku: 'PM0072834',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255398',
      rtl_batch_array: '6255398',
      name_search: 'PHMBLANC',
      name: "Philadelphus 'Mont Blanc'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X14TKD9X',
      statusContent: 'dot',
      hash: 'ALZNNVYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALZNNVYY'
    },
    {
      status: 210,
      sku: 'PM0003331',
      core_name: 'Plant',
      sppl_ean: '8720664683556',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5263147',
      rtl_batch_array: '5263147',
      name_search: 'CABBLUE',
      name: "Carex 'Bunny Blue'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'SBD7DAGY',
      statusContent: 'dot',
      hash: '55AG544D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55AG544D'
    },
    {
      status: 210,
      sku: 'PM0072767',
      core_name: 'Plant',
      sppl_ean: '8720626321922',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207555',
      rtl_batch_array: '5207555',
      name_search: 'RHCOSMOP',
      name: "Rhododendron 'Cosmopolitan'",
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RS6R1NY3;146G5YP8;E7NDAJAW;59NJW5ND;52TZGS4A;DGZ4681B;1DP5778E;ABE3CEXE;TW76YAHZ;WG8XRLAH;A91EVW1N;A1S9N6JT;XW575ZBT;DVCH9LTS',
      imageBatch: 'LRWB6YYK',
      statusContent: 'dot',
      hash: 'TVZ4VZYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVZ4VZYZ'
    },
    {
      status: 210,
      sku: 'PM0005190',
      core_name: 'Plant',
      sppl_ean: '8720664526440',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5521413',
      rtl_batch_array: '5521413, 6386105, 6320868',
      name_search: 'ECTDREAM',
      name: "Echinacea 'Tangerine Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 573,
      chnn_stock_retail: 1065,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZ6KNSY7;VD9TWCT5',
      statusContent: 'dot',
      hash: 'JZ3D4WCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ3D4WCV'
    },
    {
      status: 210,
      sku: 'PM0051916',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300221',
      rtl_batch_array: '6300221, 6376239',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1430,
      chnn_stock_retail: 3926,
      sppl_prcp: 2.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'NLXJJ2K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLXJJ2K8'
    },
    {
      status: 210,
      sku: 'PM0072758',
      core_name: 'Plant',
      sppl_ean: '8720626366619',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207218',
      rtl_batch_array: '5207218',
      name_search: 'RHCGRAND',
      name: "Rhododendron 'Catawbiense Grandiflorum'",
      sppl_size_code: '040045C5',
      rng_range_identifier: 'H040045C5',
      rng_range_description: 'H40 cm 45 cm C5',
      sppl_stock_available: 958,
      chnn_stock_retail: 958,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2R52D3X;DE685APW;AHXH6YP7;J57LVSDY;7E2BHGYD;VRLZXG77;DL89ZPL9;KSCVVP8T',
      imageBatch: 'RYH7SSBN',
      statusContent: 'dot',
      hash: 'KHEBEP3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHEBEP3A'
    },
    {
      status: 210,
      sku: 'PM0072756',
      core_name: 'Plant',
      sppl_ean: '8720349435098',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5206981',
      rtl_batch_array: '5206981',
      name_search: 'RHCWHITE',
      name: "Rhododendron 'Cunningham's White'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 982,
      chnn_stock_retail: 982,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8AC4JS25;SWVWBH2X;VZ25X36G;GEVLVHV8;H5T4PASX;KRN7ZGL9;B9JT2BV5;521PALYN;8H1GBA56;6NG6JG81;2ZA1STN5;JZAG5576;KCZSJ34X;LLDCNXT3',
      imageBatch: 'HH7GCPXH',
      statusContent: 'dot',
      hash: 'ECX9JGJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECX9JGJP'
    },
    {
      status: 210,
      sku: 'PM0072768',
      core_name: 'Plant',
      sppl_ean: '8720626366664',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207556',
      rtl_batch_array: '5207556',
      name_search: 'RHGERMAN',
      name: "Rhododendron 'Germania'",
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3PEHKT4;VDCWE9JJ;RNPSAJ2X;A1VR7CSP;SYA2749Z;DNJD5K35;CW79A7E2;N26179P9;9DKZPPNC;WJR13KXC;D5ZL3NTG;W37W3RST;NZ6LEC1J;P8N6CAL9;P4TRCVAW;JP7XAVRX;H5WGHLEB;JKW8N99G;P98AVW2S',
      imageBatch: 'W45TEENY',
      statusContent: 'dot',
      hash: 'VKSE6HB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKSE6HB7'
    },
    {
      status: 210,
      sku: 'PM0072757',
      core_name: 'Plant',
      sppl_ean: '8720349427598',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5206982',
      rtl_batch_array: '5206982',
      name_search: 'RHNZEMBL',
      name: "Rhododendron 'Nova Zembla'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8W5HCK2;WJXE8SLB;SGCKADSC;WRL37ATL;DGRX79TT;PDG54PVP;Z12H4ZXV;DA3SARXP;1E5VY5PV;DP7C1Y48;JLHDNADR;PTK91ZHL;YSTT6N8J;4Y4N6B4R;J7D4VJPT;WA4SCZTX;STTVGY2Z;VSAX114H;KBJD5H1B',
      imageBatch: '1BB9DSX5',
      statusContent: 'dot',
      hash: '17CPDENZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17CPDENZ'
    },
    {
      status: 210,
      sku: 'PM0072759',
      core_name: 'Plant',
      sppl_ean: '8720626366800',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207219',
      rtl_batch_array: '5207219',
      name_search: 'RHRELEGA',
      name: "Rhododendron 'Roseum Elegans'",
      sppl_size_code: '040045C5',
      rng_range_identifier: 'H040045C5',
      rng_range_description: 'H40 cm 45 cm C5',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T5REX3RS;J97RN8J8;N12DT64L;WRX53JGB;K6S624H3;JA8A4KRA;NR9ZZ4WE;XXVZKKCC;7PP5KYZ8;X87X8KJ2;STV7YDDR;6TA16C66',
      imageBatch: 'VVP73ABG',
      statusContent: 'dot',
      hash: 'VV7LAZVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VV7LAZVC'
    },
    {
      status: 210,
      sku: 'PM0072769',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207559',
      rtl_batch_array: '5890875, 5207559',
      name_search: 'RHVRICHA',
      name: "Rhododendron 'Virginia Richards'",
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 295,
      chnn_stock_retail: 1295,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DDASVV24;LV3ZV269;PTEZ3Y65;5SWZAVK7;81Z27BSB;K69P3DV7;EJPZXSX2;YPX9KDYV;DRLGGDVW',
      statusContent: 'dot',
      hash: '71G2W2EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71G2W2EA'
    },
    {
      status: 210,
      sku: 'PM0072770',
      core_name: 'Plant',
      sppl_ean: '8720626366817',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207560',
      rtl_batch_array: '5207560',
      name_search: 'RHWRUBY',
      name: "Rhododendron 'Wilgen's Ruby'",
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJXSRSY8;B7ARLBN7;TJ4BH2BX;99GPG518;9H7JS3JL;6S3VSA9N;J8BX21GC;YR4LXE8E;N5R1XYZ8;XGX9G72Z;D3R4LG97;TTZKZHBV;BVV91NBR',
      imageBatch: '1E8THGKA',
      statusContent: 'dot',
      hash: '1T29RN74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1T29RN74'
    },
    {
      status: 210,
      sku: 'PM0072760',
      core_name: 'Plant',
      sppl_ean: '8720626366961',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5378379',
      rtl_batch_array: '5378379',
      name_search: 'RHCALLBR',
      name: "Rhododendron (Y) 'Caroline Allbrook'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5V1R6ET1;7VX2GWJ6;L56SBDPT;AHA3DHSS;5JNRYS4R;PTYX66L4;5DB36T6P;N33NHPSY;RVNT6HVD',
      imageBatch: 'C1R2C6VZ',
      statusContent: 'dot',
      hash: '8NEGA9WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NEGA9WC'
    },
    {
      status: 210,
      sku: 'PM0072761',
      core_name: 'Plant',
      sppl_ean: '8720626322158',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 3,
      btch_active_retail: '5207221',
      rtl_batch_array: '5890896, 5207221, 6258840',
      name_search: 'RHGTORCH',
      name: "Rhododendron (Y) 'Golden Torch'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 2165,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '669A8YZV;H26VJBTV;AWDHSDPB;77JVE2HE;CNH4BNVZ;GWYLBXHE;S7TPE48J',
      statusContent: 'dot',
      hash: 'HYY5SHWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HYY5SHWS'
    },
    {
      status: 810,
      sku: 'PM0065478',
      core_name: 'Plant',
      sppl_ean: '8720664883949',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6164529',
      rtl_batch_array: '6164529',
      name_search: 'RUTAYBER',
      name: "Rubus 'Tayberry'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 2.951,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CHGWC4XH;LLZGNB83;VTE7K7AG;XG4SVPDK;7XXYBJ3E;W4G8GVZ2;C1C74ZLL;2Z1PXS2V;ZVED92CZ;8HTE5JA2;S3NC288X;N66H5P1V;7J82YAJY;18458AHS',
      statusContent: 'dot',
      hash: 'DP79NCTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DP79NCTT'
    },
    {
      status: 810,
      sku: 'PM0082922',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349418',
      rtl_batch_array: '6349418',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: '8KDZ51VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KDZ51VX'
    },
    {
      status: 810,
      sku: 'PM0082923',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349419',
      rtl_batch_array: '6349419',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'GVJHSD7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVJHSD7G'
    },
    {
      status: 810,
      sku: 'PM0082924',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349420',
      rtl_batch_array: '6349420',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: '1CH775K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CH775K2'
    },
    {
      status: 210,
      sku: 'PM0020552',
      core_name: 'Plant',
      sppl_ean: '8720349424610',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3555652',
      rtl_batch_array: '3555652',
      name_search: 'PEAREDHE',
      name: "Pennisetum alopecuroides 'Redhead'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 7.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5HWBHBZC',
      imageBatch: 'Z7A7KLX3',
      statusContent: 'dot',
      hash: 'KBP3GJYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBP3GJYZ'
    },
    {
      status: 210,
      sku: 'PM0082953',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184084',
      rtl_batch_array: '6184084',
      name_search: 'POFMANCH',
      name: "Potentilla fruticosa 'Manchu'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPC5YJJT;KV6BZHDV;S32VA5X6;C2TRJPLN',
      statusContent: 'dot',
      hash: 'T4JECA17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4JECA17'
    },
    {
      status: 810,
      sku: 'PM0082925',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349421',
      rtl_batch_array: '6349421',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'G7XGACLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7XGACLE'
    },
    {
      status: 210,
      sku: 'PM0023775',
      core_name: 'Plant',
      sppl_ean: '8720349424306',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2772373',
      rtl_batch_array: '2772373',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      imageBatch: 'JV29J63K',
      statusContent: 'dot',
      hash: 'NWLL8139',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWLL8139'
    },
    {
      status: 910,
      sku: 'PM0072840',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DAMMEMOR',
      name: "Dahlia 'Mystic Memories'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      statusContent: 'dot',
      hash: 'BDDZXX6A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BDDZXX6A'
    },
    {
      status: 810,
      sku: 'PM0082926',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349422',
      rtl_batch_array: '6349422',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'EE2J8521',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE2J8521'
    },
    {
      status: 810,
      sku: 'PM0082927',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349423',
      rtl_batch_array: '6349423',
      name_search: 'STJAPONI',
      name: 'Styrax japonicus',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EZBVJR1;X3XYR3PE;9E15RLSY;7XRW32L6;L426ZDT7;9YKY81H7;G3TYZW69;ECW693YK;81BNHSCZ;NWG2SEBX;YWPS8S1R;24Y6BJWJ',
      statusContent: 'dot',
      hash: '7DS8P79W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DS8P79W'
    },
    {
      status: 210,
      sku: 'PM0080609',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337978',
      rtl_batch_array: '6337978',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 15725,
      chnn_stock_retail: 15725,
      sppl_order_minimum: 3,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'NSL9S1J3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSL9S1J3'
    },
    {
      status: 210,
      sku: 'PM0080610',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337979',
      rtl_batch_array: '6337979',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '6X1B4RNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X1B4RNT'
    },
    {
      status: 210,
      sku: 'PM0080611',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337980',
      rtl_batch_array: '6337980',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: 'ZSTGWSZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSTGWSZY'
    },
    {
      status: 210,
      sku: 'PM0080612',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337981',
      rtl_batch_array: '6337981',
      name_search: 'ELEMARYL',
      name: 'Elaeagnus ebbingei Maryline',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1012,
      chnn_stock_retail: 1012,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '897K3AG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '897K3AG3'
    },
    {
      status: 210,
      sku: 'PM0080613',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337982',
      rtl_batch_array: '6337982',
      name_search: 'ELEVIVEL',
      name: "Elaeagnus ebbingei 'Viveleg'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1241,
      chnn_stock_retail: 1241,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '83XB34RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83XB34RW'
    },
    {
      status: 210,
      sku: 'PM0004655',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308562',
      rtl_batch_array: '6350088, 6308562, 6320864',
      name_search: 'ECPSCLOU',
      name: "Echinacea purpurea 'Summer Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 793,
      chnn_stock_retail: 1547,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1K2EVDP3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K2EVDP3'
    },
    {
      status: 910,
      sku: 'PM0022735',
      core_name: 'Plant',
      sppl_ean: '8720626351356',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5564717',
      rtl_batch_array: '6350503, 5564717, 6305169',
      name_search: 'HOFALBOP',
      name: "Hosta 'Fortunei Albopicta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 18,
      chnn_stock_retail: 1022,
      sppl_order_minimum: 3,
      sppl_prcp: 0.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VNR71A7N;E49RZ8Z1;8PHV7L9S;P57YAK9X;XPHAVE2Z;XXVJV1L5;RNTVEH8Y;R9DA29JV;S2ZL2CRS;CXLAYJS3;6SSW9GZE;8L3TSP2Z;4TRNZJVZ;5KDC5AAG;KSYH2A5N',
      statusContent: 'dot',
      hash: 'P59PKALW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P59PKALW'
    },
    {
      status: 210,
      sku: 'PM0021447',
      core_name: 'Plant',
      sppl_ean: '8720349412150',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '3136085',
      rtl_batch_array: '3136085, 6336100',
      name_search: 'CAOEVERS',
      name: "Carex oshimensis 'Eversheen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 3337,
      sppl_prcp: 2.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B8XX5C8;4NXXLR6X;22A7GN8T;TEEY7ESK;BCP4A5HD;HAVHL1CG;YT31LELT;VGZHYRRT;WYC3T5EN;WSLP6VCG',
      imageBatch: 'AK3V8EA4',
      statusContent: 'dot',
      hash: 'P6R271KJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6R271KJ'
    },
    {
      status: 210,
      sku: 'PM0065091',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363179',
      rtl_batch_array: '6161614, 6363179',
      name_search: 'CHOPPOSI',
      name: 'Chiastophyllum oppositifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4512,
      chnn_stock_retail: 5472,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4VCH1JX3',
      statusContent: 'dot',
      hash: 'PA4YL92N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PA4YL92N'
    },
    {
      status: 210,
      sku: 'PM0067222',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6177962',
      rtl_batch_array: '6229847, 6196780, 6177962',
      name_search: 'HERRUM',
      name: "Hemerocallis 'Red Rum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2566,
      sppl_order_minimum: 3,
      sppl_prcp: 0.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61CLG6GH;ABXGKSJJ;PELGWZPB;PP6G85NE',
      statusContent: 'dot',
      hash: 'PHNT2EBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHNT2EBA'
    },
    {
      status: 210,
      sku: 'PM0067996',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187658',
      rtl_batch_array: '6187658',
      name_search: 'NERLTITC',
      name: "Nepeta racemosa 'Little Titch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4A33XKSX;LEB9SD2Y',
      statusContent: 'dot',
      hash: 'PW3DTT8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW3DTT8C'
    },
    {
      status: 210,
      sku: 'PM0000205',
      core_name: 'Plant',
      sppl_ean: '8720664874121',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6133354',
      rtl_batch_array: '5303707, 6133354',
      name_search: 'MAMALBA',
      name: "Malva moschata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1136,
      chnn_stock_retail: 1701,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXLD611S;VVNW4YH9;L13C7779;HBWACSX9;1VVN19VG;KDBPTKY7;JL7JVYW8;BWTW8EDJ;TT7TDXTX;7W5AHSVE',
      statusContent: 'dot',
      hash: 'RGK6WTP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGK6WTP4'
    },
    {
      status: 210,
      sku: 'PM0067350',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6091281',
      rtl_batch_array: '6091281, 6329950',
      name_search: 'HEHSSUN',
      name: "Heliopsis helianthoides 'Summer Sun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 396,
      chnn_stock_retail: 796,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZYNEEZCZ;YGA96C3C',
      statusContent: 'dot',
      hash: 'T8XR3RS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8XR3RS3'
    },
    {
      status: 210,
      sku: 'PM0001436',
      core_name: 'Plant',
      sppl_ean: '8720349465231',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5332539',
      rtl_batch_array: '5332539',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 598,
      chnn_stock_retail: 598,
      sppl_prcp: 6.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      imageBatch: 'HCK3Y2NZ',
      statusContent: 'dot',
      hash: 'TLZSZBCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLZSZBCS'
    },
    {
      status: 210,
      sku: 'PM0080614',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337983',
      rtl_batch_array: '6337983',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1387,
      chnn_stock_retail: 1387,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'HXZ8XY9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXZ8XY9G'
    },
    {
      status: 210,
      sku: 'PM0080615',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337984',
      rtl_batch_array: '6337984',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3235,
      chnn_stock_retail: 3235,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: 'AHJGJJA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHJGJJA1'
    },
    {
      status: 810,
      sku: 'PM0082928',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349424',
      rtl_batch_array: '6349424',
      name_search: 'STJAPONI',
      name: 'Styrax japonicus',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.458,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EZBVJR1;X3XYR3PE;9E15RLSY;7XRW32L6;L426ZDT7;9YKY81H7;G3TYZW69;ECW693YK;81BNHSCZ;NWG2SEBX;YWPS8S1R;24Y6BJWJ',
      statusContent: 'dot',
      hash: 'BTE111XT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTE111XT'
    },
    {
      status: 210,
      sku: 'PM0082929',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '6385631',
      rtl_batch_array: '6349431, 6385631',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '125150KL',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      sppl_stock_available: 638,
      chnn_stock_retail: 688,
      sppl_prcp: 6.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'LJ9C4BXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ9C4BXY'
    },
    {
      status: 810,
      sku: 'PM0082930',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349432',
      rtl_batch_array: '6349432',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'PAKZCBPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAKZCBPT'
    },
    {
      status: 210,
      sku: 'PM0080616',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337985',
      rtl_batch_array: '6337985',
      name_search: 'ENCRBELL',
      name: "Enkianthus campanulatus 'Red Bells'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ABGJWYYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ABGJWYYP'
    },
    {
      status: 210,
      sku: 'PM0080617',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337986',
      rtl_batch_array: '6337986',
      name_search: 'ERMYOPOR',
      name: 'Eriostemon myoporoides',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WVGXX9VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVGXX9VK'
    },
    {
      status: 210,
      sku: 'PM0080618',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337987',
      rtl_batch_array: '6337987',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2690,
      chnn_stock_retail: 2690,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'Y3GCTRNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3GCTRNL'
    },
    {
      status: 210,
      sku: 'PM0080619',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337988',
      rtl_batch_array: '6337988',
      name_search: 'ESCCOCCI',
      name: "Escallonia 'Compacta Coccinea'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12YGNBZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12YGNBZH'
    },
    {
      status: 210,
      sku: 'PM0080620',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337989',
      rtl_batch_array: '6337989, 6348767',
      name_search: 'ESLGELLE',
      name: "Escallonia laevis 'Gold Ellen'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2215,
      chnn_stock_retail: 2915,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WBKX52TH;KCSYHJT4;7A9TL6DJ;N582YLE8',
      statusContent: 'dot',
      hash: 'AK974K8S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AK974K8S'
    },
    {
      status: 210,
      sku: 'PM0080621',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337990',
      rtl_batch_array: '6337990',
      name_search: 'ESIVEYI',
      name: "Escallonia 'Iveyi'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2231,
      chnn_stock_retail: 2231,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBWCL9PR;BHV9S7WZ;PZSG7SKR;LGJVDGY9;T7A7Y6GN',
      statusContent: 'dot',
      hash: '6NSTHNVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NSTHNVC'
    },
    {
      status: 810,
      sku: 'PM0082931',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349433',
      rtl_batch_array: '6349433',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'ZDTJRKJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDTJRKJ4'
    },
    {
      status: 810,
      sku: 'PM0082932',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349434',
      rtl_batch_array: '6349434',
      name_search: 'VICARLCE',
      name: 'Viburnum carlcephalum',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLC4D9D5;1PE21WKK;PKZYVWCX;LCB99K7C;A4PWJPX1;HV2P3ZJH',
      statusContent: 'dot',
      hash: 'VYH9BDSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYH9BDSW'
    },
    {
      status: 210,
      sku: 'PM0029034',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298718',
      rtl_batch_array: '6298718',
      name_search: 'PEALOPEC',
      name: 'Pennisetum alopecuroides',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRWLYSDR;83B33ADA;27VD1BC4;7LNJ3ARN;SNP3L9WL;GW5J4LST;L9HPT3EL;D4LB3PJK;KLP2TT9K;S46D2SL9;8CV52Z65;YKGTHGSS;VZVDC4J7;S4RBGSPH;D6S1W9CB;149JH1D2;PG5CA87X;NPW9N9WY;YSN92SY3',
      statusContent: 'dot',
      hash: '9WJCS9RS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WJCS9RS'
    },
    {
      status: 210,
      sku: 'PM0080622',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337991',
      rtl_batch_array: '6337991',
      name_search: 'ESLAEVIS',
      name: 'Escallonia laevis',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JHSJGSNZ',
      statusContent: 'dot',
      hash: 'PDER86TJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDER86TJ'
    },
    {
      status: 210,
      sku: 'PM0080623',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337992',
      rtl_batch_array: '6337992',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1185,
      chnn_stock_retail: 1185,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'WNH6AG44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNH6AG44'
    },
    {
      status: 210,
      sku: 'PM0080624',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337993',
      rtl_batch_array: '6337993',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: '025040C2',
      rng_range_identifier: 'H025040C2',
      rng_range_description: 'H25 cm 40 cm C2',
      sppl_stock_available: 2158,
      chnn_stock_retail: 2158,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      statusContent: 'dot',
      hash: 'AXJW4R9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXJW4R9Z'
    },
    {
      status: 210,
      sku: 'PM0011263',
      core_name: 'Plant',
      sppl_ean: '8720664627871',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '5703300',
      rtl_batch_array: '5703300',
      name_search: 'SAGMASO',
      name: "Salix gracilistyla 'Mt. Aso'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 536,
      chnn_stock_retail: 536,
      sppl_prcp: 7.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6D3VW1V;WJRLV8YG;KZ72ZX5P;ZZS2JPXZ;617P4E5X;APZ9WDVE;5K7WYWES;6ZZBLXD9;7G5YGATB;TR2TTVG7;EK7K84DH;1PN7GRH8;D5PN6A8J',
      statusContent: 'dot',
      hash: 'WXEHWZY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXEHWZY9'
    },
    {
      status: 210,
      sku: 'PM0003655',
      core_name: 'Plant',
      sppl_ean: '8720349424634',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4044170',
      rtl_batch_array: '4044170',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      imageBatch: 'K6WH47AD',
      statusContent: 'dot',
      hash: 'X57KPN7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X57KPN7S'
    },
    {
      status: 910,
      sku: 'PM0082933',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'TX1GJ4Y7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TX1GJ4Y7'
    },
    {
      status: 210,
      sku: 'PM0080625',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337994',
      rtl_batch_array: '6337994',
      name_search: 'ESRELF',
      name: "Escallonia 'Red Elf'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 5,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZZNLJE4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZNLJE4W'
    },
    {
      status: 210,
      sku: 'PM0080626',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337995',
      rtl_batch_array: '6337995',
      name_search: 'ESRMACRA',
      name: 'Escallonia rubra macrantha',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1774,
      chnn_stock_retail: 1774,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42914K6K',
      statusContent: 'dot',
      hash: 'C5W23RGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5W23RGV'
    },
    {
      status: 810,
      sku: 'PM0082934',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349449',
      rtl_batch_array: '6349449',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: 'ERAL28WK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERAL28WK'
    },
    {
      status: 210,
      sku: 'PM0082935',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252659',
      rtl_batch_array: '6252659',
      name_search: 'LAIGRAPP',
      name: "Lavandula intermedia 'Grappenhall'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 705,
      chnn_stock_retail: 705,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2X9WL6C;WK3YH128',
      statusContent: 'dot',
      hash: '139CV7ND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '139CV7ND'
    },
    {
      status: 810,
      sku: 'PM0082936',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253113',
      rtl_batch_array: '6253113',
      name_search: 'LAANGUST',
      name: 'Lavandula angustifolia',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VYKKKDS;YPTGVRDY;HTP5ZG2A;TB7BHCZB;6DYPYP1T;EHVW5BND;1R9631SE;B1YG5SRS;N8SNW539;1K2KCCZG;2H8T9228;86XP5H33;A3YH7BYE;D8P5HTKC;NNECTC59;C445PJVV;V42B9KSP;EG5SVBSL;RLHDAEE1;1ZBBWPJ1;JG17P1VD;5X9VZLRJ;CJHAZ1CG;D48LBS7S',
      statusContent: 'dot',
      hash: 'L2KPS26A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2KPS26A'
    },
    {
      status: 210,
      sku: 'PM0030144',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298717',
      rtl_batch_array: '6298717',
      name_search: 'PEALOPEC',
      name: 'Pennisetum alopecuroides',
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRWLYSDR;83B33ADA;27VD1BC4;7LNJ3ARN;SNP3L9WL;GW5J4LST;L9HPT3EL;D4LB3PJK;KLP2TT9K;S46D2SL9;8CV52Z65;YKGTHGSS;VZVDC4J7;S4RBGSPH;D6S1W9CB;149JH1D2;PG5CA87X;NPW9N9WY;YSN92SY3',
      statusContent: 'dot',
      hash: 'V2GTAEBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2GTAEBJ'
    },
    {
      status: 210,
      sku: 'PM0065179',
      core_name: 'Plant',
      sppl_ean: '8720664870147',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6161791',
      rtl_batch_array: '6161791',
      name_search: 'IRSMSILK',
      name: "Iris sibirica 'Moon Silk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRV7BH11;1GHLG8RV;58VV9STY;DDW2E7JT',
      statusContent: 'dot',
      hash: 'X6K86C1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6K86C1V'
    },
    {
      status: 210,
      sku: 'PM0002997',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837447',
      rtl_batch_array: '2772371, 5837447',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 920,
      chnn_stock_retail: 1420,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'XE84TJWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE84TJWH'
    },
    {
      status: 210,
      sku: 'PM0082937',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253114',
      rtl_batch_array: '6253114',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: 'RRBGDB69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRBGDB69'
    },
    {
      status: 210,
      sku: 'PM0082938',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253115',
      rtl_batch_array: '6239011, 6253115',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1086,
      chnn_stock_retail: 1093,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: '53VKV71T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53VKV71T'
    },
    {
      status: 210,
      sku: 'PM0082939',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253118',
      rtl_batch_array: '6253118',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: '17XTYG8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17XTYG8L'
    },
    {
      status: 210,
      sku: 'PM0080627',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337996',
      rtl_batch_array: '6337996',
      name_search: 'EUABRUNN',
      name: "Euonymus alatus 'Blade Runner'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2181,
      chnn_stock_retail: 2181,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1J6TJRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1J6TJRR'
    },
    {
      status: 210,
      sku: 'PM0080628',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337997',
      rtl_batch_array: '6337997',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_order_minimum: 5,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: '6VWVA61H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VWVA61H'
    },
    {
      status: 210,
      sku: 'PM0082940',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253119',
      rtl_batch_array: '6253119',
      name_search: 'LAINTERM',
      name: 'Lavandula intermedia',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 959,
      chnn_stock_retail: 959,
      sppl_prcp: 3.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EZL5HL9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZL5HL9R'
    },
    {
      status: 210,
      sku: 'PM0082941',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254262',
      rtl_batch_array: '6254262',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: 'SG1G2DCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SG1G2DCT'
    },
    {
      status: 210,
      sku: 'PM0080629',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337998',
      rtl_batch_array: '6337998',
      name_search: 'EUERCASC',
      name: "Euonymus europaeus 'Red Cascade'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2N5YWD9W',
      statusContent: 'dot',
      hash: '5Y4CL1Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Y4CL1Z5'
    },
    {
      status: 210,
      sku: 'PM0003343',
      core_name: 'Plant',
      sppl_ean: '8720349423187',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2772372',
      rtl_batch_array: '2772372',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      imageBatch: 'SAHHTT5G',
      statusContent: 'dot',
      hash: 'XWR5J1LV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWR5J1LV'
    },
    {
      status: 210,
      sku: 'PM0011327',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 6,
      btch_active_retail: '6184144',
      rtl_batch_array: '6208129, 6217236, 6184144, 6083670, 5533722, 6287701',
      name_search: 'WATERNAT',
      name: 'Waldsteinia ternata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4157,
      chnn_stock_retail: 63784,
      sppl_order_minimum: 3,
      sppl_prcp: 0.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GGTG67JR;JH3S8P8S;WWLKCH3X;CNZ8KPNP;WK3VV735;BTYE2CC8;PKLJ2LZB;86NNZJZJ',
      statusContent: 'dot',
      hash: 'XY6KN7GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XY6KN7GX'
    },
    {
      status: 210,
      sku: 'PM0082942',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254266',
      rtl_batch_array: '6373841, 6254266',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 206,
      chnn_stock_retail: 606,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'PNK3EAL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNK3EAL3'
    },
    {
      status: 910,
      sku: 'PM0027194',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6204196',
      rtl_batch_array: '6147177, 6202308, 6204196, 6161752, 6333254',
      name_search: 'HECARAME',
      name: "Heuchera 'Caramel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10,
      chnn_stock_retail: 4065,
      sppl_order_minimum: 3,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1WBVG799;TGVX4RYT;2T51378Z;B3BGRN53;48ZLP6PN;4PL5HYGT;SY3K85SW;D4AK4EW4;85EPS5C4',
      statusContent: 'dot',
      hash: 'YVHTSBVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVHTSBVB'
    },
    {
      status: 210,
      sku: 'PM0082943',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254766',
      rtl_batch_array: '6254766',
      name_search: 'LAAADBLU',
      name: "Lavandula angustifolia 'Ard\u00e8che Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 178,
      chnn_stock_retail: 178,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4VPK9P5V;29ZVB54D;L3222TEH;TEEZ9972;XGWAL911;RT5WRAZS;W6DA4KBZ;X76GCLEA',
      statusContent: 'dot',
      hash: '5JH3GBXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JH3GBXR'
    },
    {
      status: 210,
      sku: 'PM0080630',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6337999',
      rtl_batch_array: '6337999',
      name_search: 'EUBENKOM',
      name: "Euonymus 'Benkomoki'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3995,
      chnn_stock_retail: 3995,
      sppl_order_minimum: 5,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSAPBY6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSAPBY6L'
    },
    {
      status: 210,
      sku: 'PM0075378',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280750',
      rtl_batch_array: '6280750',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 3100,
      chnn_stock_retail: 3100,
      sppl_prcp: 5.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'DEWLD6JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEWLD6JD'
    },
    {
      status: 210,
      sku: 'PM0080631',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338000',
      rtl_batch_array: '6338000',
      name_search: 'EUFCGOLD',
      name: "Euonymus fortunei 'Canadale Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 735,
      chnn_stock_retail: 735,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '41CCWLLN;RJ3WT5EZ;CYNAA2TN;K7598GVW',
      statusContent: 'dot',
      hash: '7X9SCL8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7X9SCL8E'
    },
    {
      status: 210,
      sku: 'PM0082944',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260038',
      rtl_batch_array: '6260038',
      name_search: 'LASTOECH',
      name: 'Lavandula stoechas',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1035,
      chnn_stock_retail: 1035,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P26KEK4Z;6PGD231W;DH5VG3N5;2GRS5B48;VB8W6THD;NJJWEJEJ;1A51D692;KVXG3VPP;RENLXGD9;LJWGWVHN;EHNVWE6N;1J2Z79S2;VYZR35RS;C3847468;7G3AA4W6;REPKT893;18T8PY9N;16E3CW9L;BH366JBZ;A7Z43XBV;9T3VH29L;JPL5GYG7;LEBHYEX5;AGE65TVC;SYT7ZBS4;9BAK5ZBW',
      statusContent: 'dot',
      hash: '6JG9ZT7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JG9ZT7N'
    },
    {
      status: 210,
      sku: 'PM0080632',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338001',
      rtl_batch_array: '6338001',
      name_search: 'EUFDBLAN',
      name: "Euonymus fortunei 'Dart's Blanket'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2780,
      chnn_stock_retail: 2780,
      sppl_order_minimum: 5,
      sppl_prcp: 1.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N4T1KVP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4T1KVP5'
    },
    {
      status: 210,
      sku: 'PM0080633',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338002',
      rtl_batch_array: '6338002',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1240,
      chnn_stock_retail: 1240,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'GB751W7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB751W7Z'
    },
    {
      status: 210,
      sku: 'PM0080634',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338003',
      rtl_batch_array: '6338003',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3720,
      chnn_stock_retail: 3720,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'WBAJV2DE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBAJV2DE'
    },
    {
      status: 210,
      sku: 'PM0080635',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338004',
      rtl_batch_array: '6338004',
      name_search: 'EUFHBLAZ',
      name: "Euonymus fortunei 'Hort's Blaze'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1940,
      chnn_stock_retail: 1940,
      sppl_order_minimum: 3,
      sppl_prcp: 1.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EKA5LBHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKA5LBHL'
    },
    {
      status: 910,
      sku: 'PM0082945',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6204760',
      rtl_batch_array: '6204760',
      name_search: 'CARADICA',
      name: 'Campsis radicans',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_order_minimum: 6,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GYXZ7DXA;T3B2G547;YD2RVWG1;1CESY5VH;69C1BKNA;EVCK5KY3;93NCZB3H;G3JP3HEX;BHLTX997;PJLJSGYP',
      statusContent: 'dot',
      hash: 'ZKSREWVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKSREWVL'
    },
    {
      status: 210,
      sku: 'PM0082946',
      core_name: 'Plant',
      sppl_ean: '8720664875852',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079245',
      rtl_batch_array: '6079245',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1600,
      sppl_prcp: 2.323,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '7LB3YJB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LB3YJB7'
    },
    {
      status: 210,
      sku: 'PM0082947',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177926',
      rtl_batch_array: '6177926',
      name_search: 'LAAEPURP',
      name: "Lavandula angustifolia 'Essence Purple'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2BPX8LP3;AN3A3WEZ;165YY51H;PSYW6N65;2RYNA19K;1KS7NXYC;A91B7ZXT',
      statusContent: 'dot',
      hash: '9Z88R4A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Z88R4A5'
    },
    {
      status: 210,
      sku: 'PM0082948',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177927',
      rtl_batch_array: '6177927',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 6951,
      chnn_stock_retail: 6951,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: 'ZN6HTNTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN6HTNTJ'
    },
    {
      status: 210,
      sku: 'PM0082949',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177928',
      rtl_batch_array: '6177928',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 8986,
      chnn_stock_retail: 8986,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'NAYJAB6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAYJAB6W'
    },
    {
      status: 210,
      sku: 'PM0082950',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177929',
      rtl_batch_array: '6177929',
      name_search: 'LAIPHENO',
      name: 'Lavandula intermedia Phenomenal',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DX6CYSH;CBRN3JBS;J1HS51LW;SY7AS57S;DB5JE4VK;8G1BRDBV;CG4KNZJJ',
      statusContent: 'dot',
      hash: 'XBYY4X8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBYY4X8G'
    },
    {
      status: 210,
      sku: 'PM0053492',
      core_name: 'Plant',
      sppl_ean: '8720664690509',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054626',
      rtl_batch_array: '6054626',
      name_search: 'GANSARN',
      name: "Galanthus nivalis 'S. Arnott'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KJK9N3N6',
      statusContent: 'dot',
      hash: '4RRKWHPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RRKWHPN'
    },
    {
      status: 210,
      sku: 'PM0082951',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184076',
      rtl_batch_array: '6184076',
      name_search: 'PHOPULIF',
      name: 'Physocarpus opulifolius',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LZDSCSXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZDSCSXA'
    },
    {
      status: 210,
      sku: 'PM0002136',
      core_name: 'Plant',
      sppl_ean: '8720349423156',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2772369',
      rtl_batch_array: '2772369',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      imageBatch: 'ZNNE47SB',
      statusContent: 'dot',
      hash: '5565YPH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5565YPH7'
    },
    {
      status: 210,
      sku: 'PM0082952',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184083',
      rtl_batch_array: '6184083',
      name_search: 'POFLIMEL',
      name: "Potentilla fruticosa 'Limelight'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7C78VJ2T;BVG6EN1S;5725WCYW',
      statusContent: 'dot',
      hash: 'EAPBRNWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAPBRNWC'
    },
    {
      status: 910,
      sku: 'PM0082954',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224206',
      rtl_batch_array: '6224206',
      name_search: 'AKQUINAT',
      name: 'Akebia quinata',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_order_minimum: 6,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YA8H49S7;1PD4YN1L;XR8L16NA;L23SYYRZ;XPSYNY1T;WVH7DEDD;1RGDRZHP;LKYER7P7;6SV47663;B1V71WA5;84ZGCLTK;GPRZ1B8P;TRA52T42;996S9XYN',
      statusContent: 'dot',
      hash: 'K8NLGW8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8NLGW8H'
    },
    {
      status: 210,
      sku: 'PM0009478',
      core_name: 'Plant',
      sppl_ean: '8720626348325',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495338',
      rtl_batch_array: '6301602, 5495338, 6329940',
      name_search: 'GEPSSKIE',
      name: "Geranium pratense 'Summer Skies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 921,
      chnn_stock_retail: 1788,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NA3VJEY4',
      statusContent: 'dot',
      hash: '5J2S2CK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5J2S2CK4'
    },
    {
      status: 210,
      sku: 'PM0016035',
      core_name: 'Plant',
      sppl_ean: '8720349424375',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2789411',
      rtl_batch_array: '2789411',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      imageBatch: 'HWYCSGD3',
      statusContent: 'dot',
      hash: '66Y4T4G2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66Y4T4G2'
    },
    {
      status: 210,
      sku: 'PM0029673',
      core_name: 'Plant',
      sppl_ean: '8720349419807',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2735857',
      rtl_batch_array: '2735857',
      name_search: 'JUESPIRA',
      name: "Juncus effusus 'Spiralis'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 494,
      chnn_stock_retail: 494,
      sppl_prcp: 2.119,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JERJ2D94;BGJDZ76B;T94V9DA8;599D3CZK;DCPARL8Y;BGG3PLZ8;X1592N28;WL13VJJD;S7DWXAT8;6VW9J7E3;R86NC3KD;HXSSKRZ5;YPBAV6WC;RC1XSYJ1;1RSKYARL;6SZ1YLY4;T7BDZ8N7;4YG3VHR5;AAVZCEH3;TXGVY6DB;6RYBCJK8;AY5PADBJ;PYGR4VGZ;795LA34G;V82P8N63',
      imageBatch: 'T5ZZZ7CD',
      statusContent: 'dot',
      hash: '6PCGGZ4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PCGGZ4S'
    },
    {
      status: 210,
      sku: 'PM0068288',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6196866',
      rtl_batch_array: '6196866',
      name_search: 'ARMAWHIT',
      name: "Armeria maritima 'Armada White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1108,
      chnn_stock_retail: 1108,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YK1X5PES',
      statusContent: 'dot',
      hash: '8EWHT4KC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EWHT4KC'
    },
    {
      status: 210,
      sku: 'PM0024282',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6229800',
      rtl_batch_array: '5495490, 6330076, 6229800, 6363201',
      name_search: 'SANRQUEE',
      name: "Salvia nemorosa 'Rose Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1146,
      chnn_stock_retail: 3149,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RZSTZW64;4HPA3HX1;2Z2XWE6C;1Y7HVSL5;P92HWAD1;XGY364VV;L2T8899T;CVSG7GWL',
      statusContent: 'dot',
      hash: '8GSBNGNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GSBNGNX'
    },
    {
      status: 210,
      sku: 'PM0021483',
      core_name: 'Plant',
      sppl_ean: '8720626373792',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5303745',
      rtl_batch_array: '5303745, 6208117, 6383379',
      name_search: 'TIWHERRY',
      name: 'Tiarella wherryi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1981,
      chnn_stock_retail: 12733,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZZH5EZJY;2ZJ37LJK;ATRCXET7;RDH3VX6Z;9L67CG97;2KZZJVZE',
      statusContent: 'dot',
      hash: '947BVAPX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '947BVAPX'
    },
    {
      status: 210,
      sku: 'PM0010441',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324737',
      rtl_batch_array: '6350097, 6324737, 6320869',
      name_search: 'ECTTORCH',
      name: "Echinacea 'Tiki Torch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1036,
      chnn_stock_retail: 2946,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '48G4NHZR',
      statusContent: 'dot',
      hash: 'A2625R27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2625R27'
    },
    {
      status: 210,
      sku: 'PM0009281',
      core_name: 'Plant',
      sppl_ean: '8720353094694',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4721749',
      rtl_batch_array: '4721749',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 5.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      imageBatch: 'KHE1TTCD',
      statusContent: 'dot',
      hash: 'A869SJJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A869SJJL'
    },
    {
      status: 210,
      sku: 'PM0012370',
      core_name: 'Plant',
      sppl_ean: '8720349454167',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6015150',
      rtl_batch_array: '6301615, 5521415, 6015150',
      name_search: 'GEBDAIQU',
      name: "Geum 'Banana Daiquiri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 872,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B5PWDGYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5PWDGYH'
    },
    {
      status: 210,
      sku: 'PM0021995',
      core_name: 'Plant',
      sppl_ean: '8720626304789',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5521432',
      rtl_batch_array: '6350475, 6091401, 5521432',
      name_search: 'HEPFIZZ',
      name: "Heucherella 'Pink Fizz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 593,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E1R36T8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1R36T8L'
    },
    {
      status: 210,
      sku: 'PM0029169',
      core_name: 'Plant',
      sppl_ean: '8720349424603',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3255184',
      rtl_batch_array: '3255184',
      name_search: 'PEAREDHE',
      name: "Pennisetum alopecuroides 'Redhead'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 491,
      chnn_stock_retail: 491,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5HWBHBZC',
      imageBatch: '2A5NTVVE',
      statusContent: 'dot',
      hash: 'ESRD92NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESRD92NK'
    },
    {
      status: 210,
      sku: 'PM0012082',
      core_name: 'Plant',
      sppl_ean: '8720349485819',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495387',
      rtl_batch_array: '6220820, 5495387',
      name_search: 'HOPHEART',
      name: "Hosta 'Purple Heart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 421,
      chnn_stock_retail: 925,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ETYB93RY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETYB93RY'
    },
    {
      status: 910,
      sku: 'PM0012987',
      core_name: 'Plant',
      sppl_ean: '8720664874480',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079538',
      rtl_batch_array: '6079538',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: 'G1VYW353',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1VYW353'
    },
    {
      status: 210,
      sku: 'PM0029409',
      core_name: 'Plant',
      sppl_ean: '8720349424368',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2772374',
      rtl_batch_array: '2772374',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.322,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      imageBatch: 'RDVKB93A',
      statusContent: 'dot',
      hash: 'GY9BNY65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GY9BNY65'
    },
    {
      status: 210,
      sku: 'PM0023490',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 7,
      btch_active_retail: '6284540',
      rtl_batch_array:
        '2681760, 5837470, 5502167, 6254800, 6284540, 6271439, 6383868',
      name_search: 'PEALBUNN',
      name: "Pennisetum alopecuroides 'Little Bunny'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 6698,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63YVZZCG;A1BBJ6YJ;B5P86S8E;TH6PBLYX;GPV1LGBL;BNVEJBEX;5A63TARS;XN9HZKN6;6VRVEYET;1LKNTYL8;9P4AJG3C;Z5EA68H7;BY43YC1W;T159CA6D;EYTDLB28;EN1ZK2DY;9BY9SNAW;776T7RDZ;JGPE9T29;WAY2L8DE',
      statusContent: 'dot',
      hash: 'GYPARBZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYPARBZ1'
    },
    {
      status: 210,
      sku: 'PM0014133',
      core_name: 'Plant',
      sppl_ean: '8720664699250',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '5871926',
      rtl_batch_array: '5837446, 5871926, 2681751, 6336345',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9920,
      chnn_stock_retail: 13560,
      sppl_prcp: 1.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'HVXH6PTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVXH6PTP'
    },
    {
      status: 210,
      sku: 'PM0066960',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '6315278',
      rtl_batch_array: '6263735, 6208069, 6315278, 6305155, 6385480',
      name_search: 'PEADRED',
      name: "Persicaria affinis 'Darjeeling Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2405,
      chnn_stock_retail: 13555,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P1B9WA3A;RV18ATHJ;N7WVNH69;CRT52J8B',
      statusContent: 'dot',
      hash: 'JGBHJNTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGBHJNTW'
    },
    {
      status: 210,
      sku: 'PM0082955',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355585',
      rtl_batch_array: '6355585',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 12763,
      chnn_stock_retail: 12763,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'L5CRXCLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5CRXCLG'
    },
    {
      status: 910,
      sku: 'PM0082956',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'DPNXAHKJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DPNXAHKJ'
    },
    {
      status: 210,
      sku: 'PM0082957',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355587',
      rtl_batch_array: '6355587',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '050070C2',
      rng_range_identifier: 'H050070C2',
      rng_range_description: 'H50 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'LG6P2RBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LG6P2RBP'
    },
    {
      status: 210,
      sku: 'PM0075393',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280780',
      rtl_batch_array: '6280780',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1781,
      chnn_stock_retail: 1781,
      sppl_prcp: 4.596,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: 'X5TA8W8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5TA8W8V'
    },
    {
      status: 210,
      sku: 'PM0082958',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355588',
      rtl_batch_array: '6355588',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZJDD218',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZJDD218'
    },
    {
      status: 210,
      sku: 'PM0082959',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355659',
      rtl_batch_array: '6355659',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'H6DNV41S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6DNV41S'
    },
    {
      status: 210,
      sku: 'PM0080636',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338005',
      rtl_batch_array: '6338005',
      name_search: 'EUFMINIM',
      name: "Euonymus fortunei 'Minimus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1820,
      chnn_stock_retail: 1820,
      sppl_order_minimum: 3,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDL3HXLK;6EHP8PK6;W6KV7BGR;5XRTS6G4;4DALEPTE;1JPXWJ7K;KS11S8J6;AAES5KR6;1TBHNWB9;G49S5AKV',
      statusContent: 'dot',
      hash: 'LKWS21WJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKWS21WJ'
    },
    {
      status: 210,
      sku: 'PM0072841',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254898',
      rtl_batch_array: '6254898',
      name_search: 'ALSLSAPP',
      name: 'Allium senescens Little Sapphire',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2240,
      chnn_stock_retail: 2240,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ELEAWAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ELEAWAT'
    },
    {
      status: 210,
      sku: 'PM0080637',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338006',
      rtl_batch_array: '6338006',
      name_search: 'EUFSQUEE',
      name: "Euonymus fortunei 'Silver Queen'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RYRG7861',
      statusContent: 'dot',
      hash: '7D261WAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D261WAH'
    },
    {
      status: 210,
      sku: 'PM0082960',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355660',
      rtl_batch_array: '6355660',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1600,
      chnn_stock_retail: 1600,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: '44NZR9R1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44NZR9R1'
    },
    {
      status: 210,
      sku: 'PM0082961',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355661',
      rtl_batch_array: '6355661',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2991,
      chnn_stock_retail: 2991,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WNYKEJAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNYKEJAN'
    },
    {
      status: 210,
      sku: 'PM0082962',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356586',
      rtl_batch_array: '6355662, 6356586',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 12400,
      sppl_prcp: 1.224,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: 'C3JJWSCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3JJWSCY'
    },
    {
      status: 210,
      sku: 'PM0082963',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355665',
      rtl_batch_array: '6355665',
      name_search: 'BUWBALL',
      name: "Buddleja 'White Ball'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 1.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B1573HE;YH81KX32;5TL2J7GY;9RKLX3GN',
      statusContent: 'dot',
      hash: '1KT61ERT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KT61ERT'
    },
    {
      status: 210,
      sku: 'PM0082964',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355667',
      rtl_batch_array: '6355667',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: 'HB6NJAY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB6NJAY9'
    },
    {
      status: 210,
      sku: 'PM0082965',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355668',
      rtl_batch_array: '6355668',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_prcp: 1.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: '84W1P6KT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84W1P6KT'
    },
    {
      status: 210,
      sku: 'PM0082966',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355672',
      rtl_batch_array: '6355672',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: '025030C14',
      rng_range_identifier: 'H025030C1.5',
      rng_range_description: 'H25 cm 30 cm C1.5',
      sppl_stock_available: 24000,
      chnn_stock_retail: 24000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: '1DC5E1WN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DC5E1WN'
    },
    {
      status: 210,
      sku: 'PM0080638',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338007',
      rtl_batch_array: '6338007',
      name_search: 'EUFSUNSP',
      name: "Euonymus fortunei 'Sunspot'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 1370,
      chnn_stock_retail: 1370,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N2RC81DR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N2RC81DR'
    },
    {
      status: 210,
      sku: 'PM0080639',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338008',
      rtl_batch_array: '6338008',
      name_search: 'EUFVEGET',
      name: "Euonymus fortunei 'Vegetus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78E36XHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78E36XHS'
    },
    {
      status: 210,
      sku: 'PM0080640',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338009',
      rtl_batch_array: '6338009',
      name_search: 'EUFWGHOS',
      name: "Euonymus fortunei 'Wolong Ghost'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 538,
      chnn_stock_retail: 538,
      sppl_order_minimum: 5,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZY82NP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZY82NP2'
    },
    {
      status: 810,
      sku: 'PM0080641',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338010',
      rtl_batch_array: '6338010',
      name_search: 'EUHKBOY',
      name: "Euonymus hamiltonianus 'Koi Boy'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84XP3Z52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84XP3Z52'
    },
    {
      status: 210,
      sku: 'PM0080642',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338011',
      rtl_batch_array: '6338011',
      name_search: 'EUJALBOM',
      name: "Euonymus japonicus 'Albomarginatus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1040,
      chnn_stock_retail: 1040,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9PK9LZ9R;HL664HPX;TC7ALTRK;EJPPJJA2',
      statusContent: 'dot',
      hash: 'PV585XH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PV585XH3'
    },
    {
      status: 210,
      sku: 'PM0080643',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338012',
      rtl_batch_array: '6338012',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2680,
      chnn_stock_retail: 2680,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      statusContent: 'dot',
      hash: 'BZZ29KD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZZ29KD6'
    },
    {
      status: 210,
      sku: 'PM0080644',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338013',
      rtl_batch_array: '6338013',
      name_search: 'EUJBRAVO',
      name: "Euonymus japonicus 'Bravo'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 5,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L3C6V2SX;G7P4T4LT;E226CW6B;41DRYLEH;4D7E1XKE;P1RVCLXX;BNWPK6JD;K6TDJSSS;5VADZL5X;J9W8C26X;47YXL32T',
      statusContent: 'dot',
      hash: '7EK5KNAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7EK5KNAN'
    },
    {
      status: 210,
      sku: 'PM0080645',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338014',
      rtl_batch_array: '6338014',
      name_search: 'EUJGMILL',
      name: 'Euonymus japonicus Green Millenium',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '87DX3ZA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87DX3ZA9'
    },
    {
      status: 210,
      sku: 'PM0080646',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338015',
      rtl_batch_array: '6338015',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 5872,
      chnn_stock_retail: 5872,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'W9KB338X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9KB338X'
    },
    {
      status: 910,
      sku: 'PM0051925',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGLEICES',
      name: "Agapanthus 'Leicester'",
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      statusContent: 'dot',
      hash: '1T92Y4TW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1T92Y4TW'
    },
    {
      status: 210,
      sku: 'PM0029795',
      core_name: 'Plant',
      sppl_ean: '8720349409846',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '4657526',
      rtl_batch_array: '4657526',
      name_search: 'ASDIVARI',
      name: 'Aster divaricatus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 12428,
      chnn_stock_retail: 12428,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JVRPGXXG',
      imageBatch: 'Y4DC9RYV',
      statusContent: 'dot',
      hash: '6P61864L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6P61864L'
    },
    {
      status: 210,
      sku: 'PM0000812',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 7,
      btch_active_retail: '6382272',
      rtl_batch_array:
        '5303649, 6214580, 6014696, 6382272, 6336461, 6385662, 6333207',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6750,
      chnn_stock_retail: 48139,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: '7E62HVWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E62HVWB'
    },
    {
      status: 210,
      sku: 'PM0016040',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271433',
      rtl_batch_array: '6271433, 5488234',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 706,
      chnn_stock_retail: 1206,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: '7ZNV2BWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZNV2BWH'
    },
    {
      status: 210,
      sku: 'PM0080647',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338016',
      rtl_batch_array: '6338016',
      name_search: 'EUJMICRO',
      name: "Euonymus japonicus 'Microphyllus'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1847,
      chnn_stock_retail: 1847,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7TCCLEB;V59GN3RX;CRJBAARW;7SYD41S7;SXLDXYRT;CH2T2NVE;P32AK36A;1NT8E3D2;85JEYV2G;EXTSCZY1;2356KPR1',
      statusContent: 'dot',
      hash: 'XSA1VRN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSA1VRN5'
    },
    {
      status: 210,
      sku: 'PM0080648',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338017',
      rtl_batch_array: '6338017',
      name_search: 'EUJMALBO',
      name: "Euonymus japonicus 'Microphyllus Albovariegatus'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RPCNN3P8;CLPV112N;4V5SY12J;91S7D8SD;Z96NABGW;1LRXKRXD;CJKGHRXR;EYAYN47W;LT3892CP;DDTCZTAP;DYALEX3G;2YT96E24;484GPBP7;C2JBESDN;PHSY87Z9;WSZ53RRT;51TBGKA1;P5CWWLXE;XCVHCT9Z;RWVTGAXR;6H3HZ7D9;L9XC9BAX',
      statusContent: 'dot',
      hash: 'G4RKJTXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4RKJTXE'
    },
    {
      status: 210,
      sku: 'PM0011480',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 8,
      btch_active_retail: '6256043',
      rtl_batch_array:
        '5837871, 6315082, 6284494, 6164450, 6353583, 6321755, 6356226, 6256043',
      name_search: 'VIMALBA',
      name: "Vinca minor 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 21058,
      chnn_stock_retail: 58980,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XHY7T4KR;J9VCL9CP;YL2JYJ16',
      statusContent: 'dot',
      hash: '865AG41L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '865AG41L'
    },
    {
      status: 210,
      sku: 'PM0022916',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 9,
      btch_active_retail: '6311180',
      rtl_batch_array:
        '6311180, 6260124, 6184138, 5495446, 6176194, 6242173, 5533718, 6382310, 6287687',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15000,
      chnn_stock_retail: 138702,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: '86CTES3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86CTES3H'
    },
    {
      status: 810,
      sku: 'PM0004305',
      core_name: 'Plant',
      sppl_ean: '8720664878525',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133504',
      rtl_batch_array: '6133504',
      name_search: 'PHKROSET',
      name: "Phlox kelseyi 'Rosette'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BDGEH7CT',
      statusContent: 'dot',
      hash: '8T7A9P13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8T7A9P13'
    },
    {
      status: 210,
      sku: 'PM0017452',
      core_name: 'Plant',
      sppl_ean: '8720349412112',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '3035315',
      rtl_batch_array: '3035315, 6336099',
      name_search: 'CAOEVERL',
      name: "Carex oshimensis 'Everlime'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 331,
      chnn_stock_retail: 1731,
      sppl_prcp: 2.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZX98YK1S;H2NY3RBY;4KAZZC91;83DYE284;5REY1D6C;KCJR1S1R;EBNLS9CG;42XWE7YH;LZ8ZLXEG;CHAW489E',
      imageBatch: 'V1HDX19Z',
      statusContent: 'dot',
      hash: '9E8GENNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E8GENNT'
    },
    {
      status: 210,
      sku: 'PM0080649',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338018',
      rtl_batch_array: '6338018',
      name_search: 'LIMMONEY',
      name: "Liriope muscari 'Moneymaker'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 3.118,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '81XERJJ2;HAX44LHN;J253LY3D;9CTAT8KG;NKPP8YB9;2RYJ5AAB;PZE397WP;YR2HZA2B;B5YXPS9J',
      statusContent: 'dot',
      hash: '5AX1JPKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AX1JPKG'
    },
    {
      status: 210,
      sku: 'PM0080650',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338020',
      rtl_batch_array: '6338020',
      name_search: 'LIMRPURP',
      name: "Liriope muscari 'Royal Purple'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 3.118,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W3S248BN;8N2CWD95;9H9W471D;J5VXH9RL;2G2S2Y4Z;2EWHENLB;2SHZ7RA5;XJGK78BG;KK5N2A12;BAK8CGP8;7X377R9C;2P6S4VVA;2CDKR85G;A4BZ442G;12YV6XXV;CDRL3R2Z',
      statusContent: 'dot',
      hash: 'RZTHBDH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZTHBDH3'
    },
    {
      status: 210,
      sku: 'PM0080651',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338021',
      rtl_batch_array: '6338021',
      name_search: 'LISSSUNP',
      name: "Liriope spicata 'Silvery Sunproof'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 293,
      chnn_stock_retail: 293,
      sppl_order_minimum: 3,
      sppl_prcp: 3.118,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WNVBAYTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNVBAYTG'
    },
    {
      status: 810,
      sku: 'PM0080652',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338023',
      rtl_batch_array: '6338023',
      name_search: 'LOCKAMTS',
      name: 'Lonicera caerulea kamtschatica',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N1SDSTL3;G5XWZ6JS;J9E53BCG;Z8751Y7H;2TH2SJ56;YLZ4SE6N;1NHA3JDR;N8TE1ELP;13HJTVNR;DHBR21T7;LXG2STWN;WYHL68B9;Z8ZE4TW4',
      statusContent: 'dot',
      hash: 'A9LEW74K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9LEW74K'
    },
    {
      status: 210,
      sku: 'PM0080653',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338024',
      rtl_batch_array: '6338024',
      name_search: 'LONITIDA',
      name: 'Lonicera nitida',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2060,
      chnn_stock_retail: 2060,
      sppl_prcp: 1.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1JTDKH6;YKG75834;CRAYVTZ4;SSSSXDTD;YP31E8G3;TTA9TLTC;NSZGC3XE;C11D8GKN;R6GTGC3Z;R4RBWG2C',
      statusContent: 'dot',
      hash: 'T7CHP226',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7CHP226'
    },
    {
      status: 210,
      sku: 'PM0005683',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 8,
      btch_active_retail: '6256042',
      rtl_batch_array:
        '5837869, 5960186, 6284492, 5533721, 6353582, 6356225, 6256042, 6348438',
      name_search: 'VIMINOR',
      name: 'Vinca minor',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8745,
      chnn_stock_retail: 100665,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EG281Z1T;7PHP8SX2;A9ZBL78N;VZ4C57B6;YEEWSG3H;YZL8SB43;VWHK82DS;AG2GCWHL;HXBZK6K5;D5EP2G8A;41VVEG6A;2X2V95LJ;BZCBAD26;N6895BNJ;HKDSTCGY;ABE1R6VC',
      statusContent: 'dot',
      hash: 'CHGBXHPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CHGBXHPD'
    },
    {
      status: 210,
      sku: 'PM0080654',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338025',
      rtl_batch_array: '6338025',
      name_search: 'LONBGOLD',
      name: "Lonicera nitida 'Baggesen's Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 7115,
      chnn_stock_retail: 7115,
      sppl_order_minimum: 5,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '91E43ANW;7PZGK1L1;961E9EN3;AXBR7TBG;GB2LA3V4',
      statusContent: 'dot',
      hash: '2E9XSSL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E9XSSL8'
    },
    {
      status: 210,
      sku: 'PM0080655',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338026',
      rtl_batch_array: '6338026',
      name_search: 'LONLBEAU',
      name: "Lonicera nitida 'Lemon Beauty'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2543,
      chnn_stock_retail: 2543,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PX2XXAGG;473DYYKP;VA52B52T;R2BJB6LJ;CBEPANL4;RWJE18V3;V6NN1LWT;VN1ADG8D;7KHBTR4K;DG7G5YG7;YDDGX7ER;5XA68BDZ;CBGVWVW1;TX1DYHJX',
      statusContent: 'dot',
      hash: 'YV5AK9P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YV5AK9P6'
    },
    {
      status: 210,
      sku: 'PM0080656',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338027',
      rtl_batch_array: '6338027',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1102,
      chnn_stock_retail: 1102,
      sppl_order_minimum: 5,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '8LWZ2Y5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LWZ2Y5T'
    },
    {
      status: 210,
      sku: 'PM0080657',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338028',
      rtl_batch_array: '6338028',
      name_search: 'LONRTIPS',
      name: "Lonicera nitida 'Red Tips'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 3275,
      chnn_stock_retail: 3275,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHYT48JB',
      statusContent: 'dot',
      hash: 'AVHVNTLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVHVNTLY'
    },
    {
      status: 210,
      sku: 'PM0080658',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338029',
      rtl_batch_array: '6338029',
      name_search: 'LONSCOOP',
      name: 'Lonicera nitida Scoop',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 828,
      chnn_stock_retail: 828,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GPRBBSTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPRBBSTY'
    },
    {
      status: 210,
      sku: 'PM0080659',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338030',
      rtl_batch_array: '6338030',
      name_search: 'LONSBEAU',
      name: "Lonicera nitida 'Silver Beauty'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 875,
      chnn_stock_retail: 875,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2DA24GTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DA24GTE'
    },
    {
      status: 210,
      sku: 'PM0080660',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338031',
      rtl_batch_array: '6338031',
      name_search: 'LONTTIPS',
      name: 'Lonicera nitida Tidy Tips',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2KR4WS13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KR4WS13'
    },
    {
      status: 810,
      sku: 'PM0080661',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338032',
      rtl_batch_array: '6338032',
      name_search: 'LOPCCHOC',
      name: 'Lonicera periclymenum Chic & Choc',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GB5DYAAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB5DYAAA'
    },
    {
      status: 210,
      sku: 'PM0002621',
      core_name: 'Plant',
      sppl_ean: '8720349413966',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 4,
      btch_active_retail: '5381999',
      rtl_batch_array: '5485580, 5381999, 6254689, 6308796',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 6498,
      sppl_prcp: 2.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      imageBatch: 'H1BH4JTS',
      statusContent: 'dot',
      hash: 'DS8BZKR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DS8BZKR6'
    },
    {
      status: 210,
      sku: 'PM0007837',
      core_name: 'Plant',
      sppl_ean: '8720349411818',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4078350',
      rtl_batch_array: '4078350',
      name_search: 'CAFFALLS',
      name: "Carex 'Feather Falls'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SWTEBKB2;L2483766;SP761YTY;76N9NLAJ;CY3EZYXS;GN3N9S8T;PTBBRL6A;4WP8RTZK;PY2L3NWL;56GRS1YN;VVVTBCA6;4S3RAXZ6;54D1RL41;SKA1G2BS',
      imageBatch: 'R66SE9D8',
      statusContent: 'dot',
      hash: 'ED54PZRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED54PZRR'
    },
    {
      status: 210,
      sku: 'PM0027154',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6371034',
      rtl_batch_array: '6153767, 6371034',
      name_search: 'NESHGIAN',
      name: "Nepeta 'Six Hills Giant'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4919,
      chnn_stock_retail: 5919,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P5A3KB96;G9JH2P1K;B9TSLTR6;HHSZEGKL;K9D8H9CT;SS7TWGDZ;BDXC99PY;PPG4YWKG;A9DV867W;PPTNE2DP;L2HEWPPV;6H8W59KV;ZK55T26J;2926Y1WV;4B4DB35R;CPD69W2P;6G4G1W64;KV1WXYS1;ZBTHA3DP;XS3NB11N;WSDAA929;CTLZ3671;9B2HKLC5',
      statusContent: 'dot',
      hash: 'ERZSRH9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERZSRH9T'
    },
    {
      status: 210,
      sku: 'PM0017013',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6197001',
      rtl_batch_array: '6197001, 5837516, 6234521, 6334332',
      name_search: 'VEBONARI',
      name: 'Verbena bonariensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 6321,
      chnn_stock_retail: 11110,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKY7SBA7;ABH792B4;8CJEW9TL;G44VBSBK;YB7W24DG;BJB4127V;AWB6PXWB;9HZJHLLE;255D2W23;W7L7YBJS;4NNCLH29;AYDY3PE7;V61K9VK7;DHK64TJ5;CS74R4R9;E2249VDY;RASG24N5;2DBH57A1;ZHYX6VGE;WTE4LSZZ;9RE66TL3;VCHXW3DN;VKPTAXLL',
      statusContent: 'dot',
      hash: 'G8Z8195D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8Z8195D'
    },
    {
      status: 210,
      sku: 'PM0022756',
      core_name: 'Plant',
      sppl_ean: '8720349419791',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2735856',
      rtl_batch_array: '2735856',
      name_search: 'JUESPIRA',
      name: "Juncus effusus 'Spiralis'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JERJ2D94;BGJDZ76B;T94V9DA8;599D3CZK;DCPARL8Y;BGG3PLZ8;X1592N28;WL13VJJD;S7DWXAT8;6VW9J7E3;R86NC3KD;HXSSKRZ5;YPBAV6WC;RC1XSYJ1;1RSKYARL;6SZ1YLY4;T7BDZ8N7;4YG3VHR5;AAVZCEH3;TXGVY6DB;6RYBCJK8;AY5PADBJ;PYGR4VGZ;795LA34G;V82P8N63',
      imageBatch: '9SWH7XTG',
      statusContent: 'dot',
      hash: 'H4STCXEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4STCXEB'
    },
    {
      status: 910,
      sku: 'PM0051924',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGDBROUW',
      name: "Agapanthus 'Dr Brouwer'",
      rng_range_identifier: 'H040050C7.5',
      statusContent: 'dot',
      hash: 'H6J7X3XH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H6J7X3XH'
    },
    {
      status: 210,
      sku: 'PM0024079',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252506',
      rtl_batch_array: '6252506',
      name_search: 'CAMGOLDB',
      name: "Carex morrowii 'Goldband'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 451,
      chnn_stock_retail: 451,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJRKPTA9;9EH6KZG6;CA5RYEST;1Y9PS1RB;2YP88B29',
      statusContent: 'dot',
      hash: 'J6BNRVS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6BNRVS2'
    },
    {
      status: 210,
      sku: 'PM0069389',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317838',
      rtl_batch_array: '6350102, 6317838, 6333222',
      name_search: 'ECPARADO',
      name: 'Echinacea paradoxa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 987,
      chnn_stock_retail: 4890,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7T48G7T1;RWZV59SR;9Y94W7JD;22R919KW',
      statusContent: 'dot',
      hash: 'HW2PPJNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HW2PPJNN'
    },
    {
      status: 210,
      sku: 'PM0002494',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284521',
      rtl_batch_array: '6234639, 6284521, 5837407',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2755,
      chnn_stock_retail: 4437,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: 'L2NRS7LS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2NRS7LS'
    },
    {
      status: 210,
      sku: 'PM0029532',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 6,
      btch_active_retail: '6356227',
      rtl_batch_array: '6369263, 5837880, 6164451, 6356227, 6287606, 6334773',
      name_search: 'VIMGJEKY',
      name: "Vinca minor 'Gertrude Jekyll'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5000,
      chnn_stock_retail: 24914,
      sppl_order_minimum: 3,
      sppl_prcp: 0.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y335HZAG;H5PLAKG9;6LH12BCA;XN619H8A;4CDRRTEZ;7N7KRB5C;Z5B295Z3;4HAWSZY7',
      statusContent: 'dot',
      hash: 'R8CWA4KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8CWA4KA'
    },
    {
      status: 210,
      sku: 'PM0009170',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6197013',
      rtl_batch_array: '6298534, 6197013, 6254673, 5837401',
      name_search: 'CAAOVERD',
      name: "Calamagrostis acutiflora 'Overdam'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3171,
      chnn_stock_retail: 6984,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6N82A37;NRXEE51H;EN26CHAN;ZNJBER7R;C7NJKLYV;GXYJ8CAB;6P9PPYAV;BWAPCE1Y;L1RBX35E;SK4J87TV;D7JHV6BG;PKZJ346T;94E65NSZ;6T679YBV;6KX1BDZ2;V8574RZW;6AVTGASE;WTHJSTT1;S4SW2T6Y;JTCHNR59;RHYECWKK;LYJV25S6;DLPK5RNR;HRXHBLXT;HK57GBK8;JN8VG67A',
      statusContent: 'dot',
      hash: 'S7N7HNYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7N7HNYA'
    },
    {
      status: 210,
      sku: 'PM0023042',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 3,
      btch_active_retail: '6252697',
      rtl_batch_array: '5837457, 4673017, 6252697',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 622,
      chnn_stock_retail: 2092,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      statusContent: 'dot',
      hash: 'T4GRK5SL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4GRK5SL'
    },
    {
      status: 910,
      sku: 'PM0051923',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AGDBROUW',
      name: "Agapanthus 'Dr Brouwer'",
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      statusContent: 'dot',
      hash: 'WATPA8XB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WATPA8XB'
    },
    {
      status: 910,
      sku: 'PM0026176',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: 'X192X5YN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X192X5YN'
    },
    {
      status: 910,
      sku: 'PM0072915',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253744',
      rtl_batch_array: '6253744',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 22.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'XJ1RCTBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJ1RCTBP'
    },
    {
      status: 810,
      sku: 'PM0011450',
      core_name: 'Plant',
      sppl_ean: '8720349432738',
      btch_manufacturer: 93,
      rtl_batch_array_total: 6,
      btch_active_retail: '5837864',
      rtl_batch_array: '6034447, 5837864, 5443497, 6184114, 6300718, 6265363',
      name_search: 'VIMAJOR',
      name: 'Vinca major',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 14769,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9DVAL8E9;LKGVHAZJ;7V854YDB',
      statusContent: 'dot',
      hash: '46ET6RHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46ET6RHB'
    },
    {
      status: 210,
      sku: 'PM0004280',
      core_name: 'Plant',
      sppl_ean: '8720626361621',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '5364406',
      rtl_batch_array: '6080724, 5364406, 6383368, 6348408',
      name_search: 'PEABLACK',
      name: "Persicaria amplexicaulis 'Blackfield'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2868,
      chnn_stock_retail: 11416,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K76ZEZY6;LA21DDKZ;NA3TEPVB',
      statusContent: 'dot',
      hash: 'HBZNNZSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBZNNZSV'
    },
    {
      status: 810,
      sku: 'PM0011650',
      core_name: 'Plant',
      sppl_ean: '8720664864719',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6126283',
      rtl_batch_array: '6113101, 6202296, 6126283, 6161701, 6306084, 6363194',
      name_search: 'GEMCZAKO',
      name: "Geranium macrorrhizum 'Czakor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 37,
      chnn_stock_retail: 11937,
      sppl_order_minimum: 3,
      sppl_prcp: 0.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H9D3THEK',
      statusContent: 'dot',
      hash: 'NBX88CE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBX88CE8'
    },
    {
      status: 810,
      sku: 'PM0072949',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253822',
      rtl_batch_array: '6253822',
      name_search: 'RUFTHORN',
      name: "Rubus fruticosus 'Thornfree'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 2.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZSRJ9NGH;T63KNDAR',
      statusContent: 'dot',
      hash: 'A5KD7PWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5KD7PWA'
    },
    {
      status: 210,
      sku: 'PM0011546',
      core_name: 'Plant',
      sppl_ean: '8720349408320',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4380372',
      rtl_batch_array: '4380372',
      name_search: 'ACGARGEN',
      name: "Acorus gramineus 'Argenteostriatus'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GKX6968;XGAA2WX6;5WKAAYH5;ZW1XCE2E;Z8CSGN7T',
      imageBatch: '5XWB32KS',
      statusContent: 'dot',
      hash: 'CNN6KNLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNN6KNLY'
    },
    {
      status: 810,
      sku: 'PM0026324',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5880531',
      rtl_batch_array: '5880531',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 2.811,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'SZ5BEH6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZ5BEH6K'
    },
    {
      status: 210,
      sku: 'PM0009236',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6176228',
      rtl_batch_array: '6176228, 6353406, 6287696, 6348423',
      name_search: 'SESBRILL',
      name: "Sedum spectabile 'Brillant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 22161,
      chnn_stock_retail: 30974,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46ANWG7S;GYTXK7C7;NP85B2AP;HE168LG8;SJ6YV7TS;E15EZ8WH;EZGLE5H1;8EC89EJN;JEG8AES9;6THWDC97;C1L2V8W7',
      statusContent: 'dot',
      hash: '9L9S5A42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L9S5A42'
    },
    {
      status: 210,
      sku: 'PM0080668',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338039',
      rtl_batch_array: '6338039',
      name_search: 'LOCPRED',
      name: "Loropetalum chinense 'Pipa's Red'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1155,
      chnn_stock_retail: 1155,
      sppl_order_minimum: 5,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AN7WBXXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AN7WBXXE'
    },
    {
      status: 210,
      sku: 'PM0015373',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6249949',
      rtl_batch_array: '6249949',
      name_search: 'PHRUSSEL',
      name: 'Phlomis russeliana',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9432,
      chnn_stock_retail: 9432,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YP42PBWA;NCT5P9T1;63VD521Y;PRAP2EJX',
      statusContent: 'dot',
      hash: '9Z6SL2YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9Z6SL2YA'
    },
    {
      status: 210,
      sku: 'PM0005997',
      core_name: 'Plant',
      sppl_ean: '8720349408344',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4380373',
      rtl_batch_array: '4380373',
      name_search: 'ACGGDELI',
      name: "Acorus gramineus 'Golden Delight'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 476,
      chnn_stock_retail: 476,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LS4SL112',
      imageBatch: 'WEV2NNV5',
      statusContent: 'dot',
      hash: 'TAAPBD1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TAAPBD1B'
    },
    {
      status: 210,
      sku: 'PM0072894',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295853',
      rtl_batch_array: '6308749, 6295853',
      name_search: 'SCSSOVAT',
      name: "Schizachyrium scoparium 'Standing Ovation'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 530,
      chnn_stock_retail: 826,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DBVZWPGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBVZWPGL'
    },
    {
      status: 210,
      sku: 'PM0080662',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338033',
      rtl_batch_array: '6338033',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1590,
      chnn_stock_retail: 1590,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'B7L17ZEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7L17ZEX'
    },
    {
      status: 210,
      sku: 'PM0080663',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338034',
      rtl_batch_array: '6338034',
      name_search: 'LORLSTAR',
      name: "Lophomyrtus ralphii 'Little Star'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWNKBXA5;GPSZEKE2;Y9T5XD1W;VDKB3BY9;NJN87Z4R;R6G1XLJZ',
      statusContent: 'dot',
      hash: '51DYVXVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51DYVXVN'
    },
    {
      status: 210,
      sku: 'PM0000029',
      core_name: 'Plant',
      sppl_ean: '8720349423576',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6003588',
      rtl_batch_array: '5837459, 6003588, 6252702, 6219676',
      name_search: 'NEWLOW',
      name: "Nepeta 'Walker's Low'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 957,
      chnn_stock_retail: 6939,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '83KLR23B;2X11KXGZ;GZBEPLNA;ETAZAYTC;43X8CB9C;19HZ61AP;ZRJB5GRC;C63HC55X;5R6S3ZZ5;RR7SY6N1;RY99AV1V;HAKLLLPJ;951Y696D;SJTC13B9;TYPYG9Y6;84XT9D1J;5945D6RR',
      statusContent: 'dot',
      hash: '8CAKKCGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CAKKCGY'
    },
    {
      status: 910,
      sku: 'PM0010198',
      core_name: 'Plant',
      sppl_ean: '8720626358799',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423417',
      rtl_batch_array: '5423417',
      name_search: 'MAAMUREN',
      name: 'Maackia amurensis',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 32.113,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELTG8N6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELTG8N6V'
    },
    {
      status: 810,
      sku: 'PM0080664',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338035',
      rtl_batch_array: '6338035',
      name_search: 'LORMDRAG',
      name: "Lophomyrtus ralphii 'Magic Dragon'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VA35DXGW',
      statusContent: 'dot',
      hash: '6WGYL8XD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WGYL8XD'
    },
    {
      status: 810,
      sku: 'PM0072908',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253731',
      rtl_batch_array: '6253731',
      name_search: 'FATJAPON',
      name: 'Fatsia japonica',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RSC48GZ;R5SVETV2;49WXNLEH;NAPTC6TV;BR2DPDZE;H4CHEYKL;GEPH7HV3;8PSCVAB6;CH5TD35H;RDK4EXYN;6TX92Y4T;1NB9EV7C;YWTA9LTT;H96S38ZG;2DPPH8G6;52AYEX2C;AJ6CK65G;SDSSNPWV;7Z6RYRPY;JJ7K336S;42JXK8ZT;RVR78BZ6;E45K1DJ9;72RDDVZ6',
      statusContent: 'dot',
      hash: 'TPW2YC7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPW2YC7W'
    },
    {
      status: 810,
      sku: 'PM0072909',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253732',
      rtl_batch_array: '6253732',
      name_search: 'LEFORMOS',
      name: 'Leycesteria formosa',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_prcp: 3.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72LLZYLW;4LT85GG3;98BYDGT2;EA8EXSLG;DWBT25YS;CEY4EZNV;1391SWRD;9V7H93X2',
      statusContent: 'dot',
      hash: '9D95B5PB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D95B5PB'
    },
    {
      status: 210,
      sku: 'PM0080665',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338036',
      rtl_batch_array: '6338036',
      name_search: 'LORPURPU',
      name: "Lophomyrtus ralphii 'Purpurea'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CWCPKLBH;8B78K21G;1KBLN9GA;5DBVJNN7;J4XYBSLJ;XZAD2BXS;KZTB1K2T;5BC87VR4;DGLXPN85;Y7EXR82L;SXTN6XY9;2CKGPC4T;8APH71LV;ADL1X1LH;W931RR35',
      statusContent: 'dot',
      hash: 'D3DR7KAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3DR7KAD'
    },
    {
      status: 210,
      sku: 'PM0072958',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253834',
      rtl_batch_array: '6253834',
      name_search: 'AGAFRICA',
      name: 'Agapanthus africanus',
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JSV2HX9P;LAVA9RSS;GCSCKNA9;WBCHVK77;PZA6ZRA1;WDXX8ZYB;BVPEJDPT;CYWSEJRN;SR86GZHG',
      statusContent: 'dot',
      hash: '6RRZPJ8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RRZPJ8Z'
    },
    {
      status: 210,
      sku: 'PM0080666',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338037',
      rtl_batch_array: '6338037',
      name_search: 'LORPNANA',
      name: "Lophomyrtus ralphii 'Purpurea Nana'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 865,
      chnn_stock_retail: 865,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W12CYSH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W12CYSH5'
    },
    {
      status: 210,
      sku: 'PM0080667',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338038',
      rtl_batch_array: '6338038',
      name_search: 'LOCERED',
      name: 'Loropetalum chinense Ever Red',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 520,
      chnn_stock_retail: 520,
      sppl_order_minimum: 3,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VYLGK6ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYLGK6ZS'
    },
    {
      status: 210,
      sku: 'PM0080669',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338040',
      rtl_batch_array: '6338040',
      name_search: 'LOCRSNOW',
      name: 'Loropetalum chinense Ruby Snow',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 693,
      chnn_stock_retail: 693,
      sppl_order_minimum: 3,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WX7SR2Y2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WX7SR2Y2'
    },
    {
      status: 210,
      sku: 'PM0080670',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338041',
      rtl_batch_array: '6338041',
      name_search: 'LOCDFLAM',
      name: "Loropetalum chinense 'Daybreak's Flame'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 545,
      chnn_stock_retail: 545,
      sppl_order_minimum: 3,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YB7PD7TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YB7PD7TY'
    },
    {
      status: 210,
      sku: 'PM0080671',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338042',
      rtl_batch_array: '6338042',
      name_search: 'LOCFDANC',
      name: "Loropetalum chinense 'Fire Dance'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3495,
      chnn_stock_retail: 3495,
      sppl_order_minimum: 5,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D3STX5DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3STX5DN'
    },
    {
      status: 810,
      sku: 'PM0072916',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253746',
      rtl_batch_array: '6253746',
      name_search: 'HISARDEN',
      name: "Hibiscus syriacus 'Ardens'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 17.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3RSRH3S;HCN4JG8A;V82GSVV5;6G3GT5K7;TBBTVPBX',
      statusContent: 'dot',
      hash: 'DZ7JLC1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ7JLC1H'
    },
    {
      status: 810,
      sku: 'PM0072917',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253748',
      rtl_batch_array: '6253748',
      name_search: 'HISDBRAB',
      name: "Hibiscus syriacus 'Duc de Brabant'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 17.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52PBSE7C;VD2L7JL9;6Z5THXR2;294ZR3EX;RPBNELT7',
      statusContent: 'dot',
      hash: '5BVNNHG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BVNNHG7'
    },
    {
      status: 210,
      sku: 'PM0080672',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338043',
      rtl_batch_array: '6338043',
      name_search: 'LOCMDYNA',
      name: "Loropetalum chinense 'Ming Dynasty'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ACR2BA7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACR2BA7V'
    },
    {
      status: 810,
      sku: 'PM0072918',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253753',
      rtl_batch_array: '6253753',
      name_search: 'HISRVIOL',
      name: 'Hibiscus syriacus Russian Violet',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 17.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLSDV4YK;SZS956YX;ERLLH13W;51CL5V7E;1SETVYYB;XHVYW55W;DW9PLEHA;NVE2CYA9;156DPDCP;L4ZYNLT3;VBY4DVC2;GC3SB1LZ;WG7291D7;ZV1P8B72',
      statusContent: 'dot',
      hash: 'RN7N4C5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RN7N4C5R'
    },
    {
      status: 810,
      sku: 'PM0072919',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253755',
      rtl_batch_array: '6253755',
      name_search: 'HYQSNOWF',
      name: "Hydrangea quercifolia 'Snowflake'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 14.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYKE32HW;D6XAEKDS;BGHGRNH2;EAC39HBS;8SD5ZXWK;P8JEJB4S;KK32262J;KV1J8B7G;LJVCS9AK;N24GLAX2',
      statusContent: 'dot',
      hash: 'DLSAKT2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLSAKT2A'
    },
    {
      status: 210,
      sku: 'PM0080673',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338044',
      rtl_batch_array: '6338044',
      name_search: 'GETRGOLD',
      name: "Genista tinctoria 'Royal Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JLKZLAL',
      statusContent: 'dot',
      hash: '5SHAS8T3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SHAS8T3'
    },
    {
      status: 810,
      sku: 'PM0072962',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253838',
      rtl_batch_array: '6253838',
      name_search: 'FABALDSC',
      name: 'Fallopia baldschuanica',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 2.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NG4RBY9A;TZ3PRT9X;YV2VY1D4;79WVNGHK;JE4ETSWB;RCJ786DN;XPC2CAZP;9B6C61X5',
      statusContent: 'dot',
      hash: '99KTBLZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99KTBLZZ'
    },
    {
      status: 210,
      sku: 'PM0028360',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252508',
      rtl_batch_array: '6252508, 5414684',
      name_search: 'CAMIGREE',
      name: "Carex morrowii 'Irish Green'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 872,
      chnn_stock_retail: 2363,
      sppl_prcp: 1.929,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A46YS2XD;S7CZ3ZD8;H3531TGK',
      statusContent: 'dot',
      hash: 'CVXG992A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVXG992A'
    },
    {
      status: 810,
      sku: 'PM0072921',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 2,
      btch_active_retail: '6253759',
      rtl_batch_array: '6253759, 6307638',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 27,
      chnn_stock_retail: 693,
      sppl_prcp: 24.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'EC99XP3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EC99XP3Z'
    },
    {
      status: 210,
      sku: 'PM0030036',
      core_name: 'Plant',
      sppl_ean: '8720349412105',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4496050',
      rtl_batch_array: '4496050',
      name_search: 'CAOEVERL',
      name: "Carex oshimensis 'Everlime'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.586,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZX98YK1S;H2NY3RBY;4KAZZC91;83DYE284;5REY1D6C;KCJR1S1R;EBNLS9CG;42XWE7YH;LZ8ZLXEG;CHAW489E',
      imageBatch: '8TW7EAAZ',
      statusContent: 'dot',
      hash: '98BV4C7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98BV4C7D'
    },
    {
      status: 210,
      sku: 'PM0080674',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338045',
      rtl_batch_array: '6338045',
      name_search: 'GIBILOBA',
      name: 'Ginkgo biloba',
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WY8TE552;DT2176J1;X7YVRRRC;ZA4NZ32C;CKEXKRAR;RLNHRA6B;XL1JAJ4D;DEPR9L6D;BWA6VT6S;1RDZ1KTZ;SJHWZYBP;HR2WJ79N;ZT59WZ7D;87HX4H3S',
      statusContent: 'dot',
      hash: '82DD7C45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82DD7C45'
    },
    {
      status: 910,
      sku: 'PM0025317',
      core_name: 'Plant',
      sppl_ean: '8720349432998',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '4874707',
      rtl_batch_array: '4874707, 6288296, 6193707, 6339721',
      name_search: 'ACGOGON',
      name: "Acorus gramineus 'Ogon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4,
      chnn_stock_retail: 1394,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XEX4W2P1;7B3N2R24;HXBERTTP;PV39NESW;E264R1NJ;R5PEL6CS;PE7Z6R3T;V4T6VD5X',
      statusContent: 'dot',
      hash: 'K362GCP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K362GCP4'
    },
    {
      status: 810,
      sku: 'PM0080675',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338046',
      rtl_batch_array: '6338046',
      name_search: 'GIBMENHI',
      name: "Ginkgo biloba 'Menhir'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H9W6PXR5;BS1YDSY6;51AKG5Z9;8186GTDR;Y5TT4AL6;5RR466L7;91XYRRJD;SN5NEPY2;ZB3X89JG;ZNDLHNJS;23AHXB1N;JRYDN81J;E27X2B6D;KD75JDGE;6DTGPCDK;172WENJ2;27X1WZ1D;TDEZSPYL;BNK7ZTAR',
      statusContent: 'dot',
      hash: 'Z3EPEPPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3EPEPPZ'
    },
    {
      status: 210,
      sku: 'PM0010306',
      core_name: 'Plant',
      sppl_ean: '8720349433018',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '5507474',
      rtl_batch_array: '6080676, 6084934, 5507474, 6003287, 6295599',
      name_search: 'GEEWPINK',
      name: "Geranium endressii 'Wargrave Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 17967,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TE3GS59A',
      statusContent: 'dot',
      hash: 'S9WPAHHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9WPAHHL'
    },
    {
      status: 810,
      sku: 'PM0080676',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338047',
      rtl_batch_array: '6338047',
      name_search: 'GOVWCAND',
      name: "Gomphostigma virgatum 'White Candy'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JDRA9WHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDRA9WHB'
    },
    {
      status: 210,
      sku: 'PM0072842',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255561',
      rtl_batch_array: '6255561',
      name_search: 'LIIMUSLI',
      name: 'Ligustrum ibota Musli',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 12.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KNZKC43W;TBWV2J7E',
      statusContent: 'dot',
      hash: '4RBE9SGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RBE9SGN'
    },
    {
      status: 210,
      sku: 'PM0080677',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338048',
      rtl_batch_array: '6338048',
      name_search: 'GOGRANDI',
      name: 'Gordlinia grandiflora',
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 322,
      chnn_stock_retail: 322,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A9K3CAG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9K3CAG6'
    },
    {
      status: 210,
      sku: 'PM0080678',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338049',
      rtl_batch_array: '6338049',
      name_search: 'GRJUNIPE',
      name: 'Grevillea juniperina',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 769,
      chnn_stock_retail: 769,
      sppl_order_minimum: 3,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9AGE6RC;1RXL1AEP;YRZW3Z9D;N93AZ3AS;V3T1VWSA;G76R4WCK;H1YK1R6A',
      statusContent: 'dot',
      hash: 'ZWP5E7EB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWP5E7EB'
    },
    {
      status: 910,
      sku: 'PM0072927',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253766',
      rtl_batch_array: '6253766',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 22.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'Y3WC4K47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3WC4K47'
    },
    {
      status: 210,
      sku: 'PM0080679',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338050',
      rtl_batch_array: '6338050',
      name_search: 'GRLITTOR',
      name: 'Griselinia littoralis',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'STYAHC45;NLA1EY63;PHXW78VN;NYKR71JV;R5BVEB9X;GP34GEWA;9GCVSGSL;JT5NKVY9;R42BK62V',
      statusContent: 'dot',
      hash: 'XYHVZJTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYHVZJTC'
    },
    {
      status: 210,
      sku: 'PM0006873',
      core_name: 'Plant',
      sppl_ean: '8720349411870',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4294692',
      rtl_batch_array: '4294692',
      name_search: 'CAMEEVER',
      name: 'Carex morrowii Evercolor Everglow',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 2.544,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KXVWT5R9;XH6PY3DK;XHKJ32DT;E358HCE4;B9WYJTS7;ZJ19ZECD',
      imageBatch: 'VGSNLZNA',
      statusContent: 'dot',
      hash: '7NLNRE7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NLNRE7E'
    },
    {
      status: 210,
      sku: 'PM0080680',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338051',
      rtl_batch_array: '6338051',
      name_search: 'GRLGHORI',
      name: 'Griselinia littoralis Green Horizon',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2350,
      chnn_stock_retail: 2350,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DGV2NZ95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGV2NZ95'
    },
    {
      status: 910,
      sku: 'PM0072929',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLPRESID',
      name: "Clematis 'The President'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'YR3KEPKC;4JVA8PTW;R3GG1153;4D58PSSR;4LR125VB;96GRL96A;5XKKJ857;6B69S44Y;VXEB2J1D;WEEJAYEN;4JEEN1VE;TLG6L6L4;EZ26GL1V;P2TL8ACB;13XTST2S;LDD4D9SJ;X5293165;ZHJGC1ZY;L1K6EWTV;D4BTVTX3;H1L1DV6P;W9VL8A9C;EBHDJJP8;CELXZ74C;2PKE94RY;Z88GR8L9;XPZ7XHHC;CGZYVWZP',
      statusContent: 'dot',
      hash: 'TSVA3LYV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TSVA3LYV'
    },
    {
      status: 810,
      sku: 'PM0072932',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253801',
      rtl_batch_array: '6253801',
      name_search: 'VIBIANCA',
      name: "Vitis 'Bianca'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 3.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8SR58N91;8BW8W1G9;2WL41Z8S;VY4VGHZC;4GCPRSXC;2TDYDR7A;VEZTJ3A1;929VP2NC;R4SXTXB2',
      statusContent: 'dot',
      hash: 'JNZY8CX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNZY8CX8'
    },
    {
      status: 210,
      sku: 'PM0024207',
      core_name: 'Plant',
      sppl_ean: '8720349421398',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5501751',
      rtl_batch_array: '5501751',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 17150,
      chnn_stock_retail: 17150,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      imageBatch: '1V9RZK6Y',
      statusContent: 'dot',
      hash: '41K1WYLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41K1WYLP'
    },
    {
      status: 810,
      sku: 'PM0072933',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253803',
      rtl_batch_array: '6253803',
      name_search: 'LOPSEROT',
      name: "Lonicera periclymenum 'Serotina'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.841,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJPNZYE2;ZN4CAHN1;Z2YKXPSL;16P279GS;E6LWJWGT;48YZ64JZ;75J4REZ7;R6YWKD37;WAVNK8D2',
      statusContent: 'dot',
      hash: '8AKK6ACL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AKK6ACL'
    },
    {
      status: 810,
      sku: 'PM0072934',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253804',
      rtl_batch_array: '6253804',
      name_search: 'PAVVICTO',
      name: "Passiflora violacea 'Victoria'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TXGBAW79;85W5DPBA;RY6BTTLV',
      statusContent: 'dot',
      hash: '79R4T3W6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79R4T3W6'
    },
    {
      status: 810,
      sku: 'PM0080681',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338052',
      rtl_batch_array: '6338052',
      name_search: 'GRLVARIE',
      name: "Griselinia littoralis 'Variegata'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SB4ZVPEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SB4ZVPEJ'
    },
    {
      status: 210,
      sku: 'PM0072938',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253809',
      rtl_batch_array: '6253809',
      name_search: 'RUIMPROM',
      name: "Rubus idaeus 'Malling Promise'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZJV1BV9;WG8HXVEE;8D4DDK5A;LPB1B4T4;B3PJB7LS;J4ZL1SRS;XTXEBGWN',
      statusContent: 'dot',
      hash: 'Y7P4V222',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7P4V222'
    },
    {
      status: 810,
      sku: 'PM0072940',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253812',
      rtl_batch_array: '6253812',
      name_search: 'PACCELLI',
      name: "Passiflora caerulea 'Constance Elliott'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 2.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZSBCVPX;HTR5YBAV;GD1RL3BP;6A8AW88J;R6T4CPRJ;C5538E53',
      statusContent: 'dot',
      hash: 'VSVYH3NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSVYH3NK'
    },
    {
      status: 210,
      sku: 'PM0010421',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6309005',
      rtl_batch_array: '6350573, 6193632, 6309005, 6339320',
      name_search: 'IRPSEUDA',
      name: 'Iris pseudacorus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 283,
      chnn_stock_retail: 2534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR7KWGHP;BXCAX7PR;WPXV2NYL;HB7747PE;CP7J3V7W;T58DD8AX;WKRA1JTZ;6RE256WJ;WDS1W4G1;H7BNN7EZ;TWHD3WES;A4H1RKP1;W6WZW26N;96V5ZHGC;8T79XHCT;CGJ9DN9E;K8DZYWSX;SNGZDATZ;TK39H2BP;RL8YNC95',
      statusContent: 'dot',
      hash: '48Z7JDTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '48Z7JDTN'
    },
    {
      status: 810,
      sku: 'PM0072941',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253813',
      rtl_batch_array: '6253813',
      name_search: 'CATGALEN',
      name: "Campsis tagliabuana 'Mme Galen'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 4.422,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V73YDNBX;BNLZZCHC;8S2RY642;TNJKEKT2;BVPTZ1DP;6R9D3JKJ;92YTRKYJ;Y9NHLYNX;1T6RYZR2;ZALD81S2',
      statusContent: 'dot',
      hash: 'ZHTS3V5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHTS3V5N'
    },
    {
      status: 210,
      sku: 'PM0080682',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338053',
      rtl_batch_array: '6338053',
      name_search: 'GYDIOICA',
      name: 'Gymnocladus dioica',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_prcp: 4.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S813X1ZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S813X1ZW'
    },
    {
      status: 810,
      sku: 'PM0072942',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253814',
      rtl_batch_array: '6253814',
      name_search: 'LOTELLMA',
      name: 'Lonicera tellmanniana',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.841,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ECJ3G;JGY84VDN;2JNBTDGT;WGHTB9ZK;LXY88137;9487HK3K',
      statusContent: 'dot',
      hash: 'BTR34NVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTR34NVZ'
    },
    {
      status: 810,
      sku: 'PM0072943',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253815',
      rtl_batch_array: '6253815',
      name_search: 'LOHECKRO',
      name: 'Lonicera heckrottii',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.841,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '73HYP272',
      statusContent: 'dot',
      hash: 'N54SCSHR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N54SCSHR'
    },
    {
      status: 810,
      sku: 'PM0072944',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253816',
      rtl_batch_array: '6253816',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 3.555,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: '4TTRYNHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TTRYNHE'
    },
    {
      status: 810,
      sku: 'PM0072948',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253820',
      rtl_batch_array: '6253820',
      name_search: 'RUFBSATI',
      name: "Rubus fruticosus 'Black Satin'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 2.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7WJTCG9A;7ZZY21TS;SZ1LV5N6;PL8SXPD4;LJ9JS2SE;G49K7BRZ;E29TKSN8;HWL1XVK6',
      statusContent: 'dot',
      hash: 'N7KA5C61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7KA5C61'
    },
    {
      status: 210,
      sku: 'PM0072950',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253823',
      rtl_batch_array: '6253823',
      name_search: 'ACMWBEAU',
      name: "Achillea millefolium 'White Beauty'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWH66JGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWH66JGA'
    },
    {
      status: 210,
      sku: 'PM0072951',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253824',
      rtl_batch_array: '6253824',
      name_search: 'AGATWIST',
      name: "Agapanthus africanus 'Twister'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y5HKWBXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5HKWBXT'
    },
    {
      status: 910,
      sku: 'PM0014728',
      core_name: 'Plant',
      sppl_ean: '8720349425402',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '5368306',
      rtl_batch_array: '5368306, 6263744, 6291734, 6193663',
      name_search: 'PHAUSTRA',
      name: 'Phragmites australis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1,
      chnn_stock_retail: 2857,
      sppl_order_minimum: 3,
      sppl_prcp: 0.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9TB4A4Z;LXBT97CP;ZS4P7HSG;4291A3CA;PX94H3ND;X96L4HRN;2R39RHKW',
      statusContent: 'dot',
      hash: '19EDHCCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19EDHCCC'
    },
    {
      status: 210,
      sku: 'PM0072954',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253829',
      rtl_batch_array: '6253829',
      name_search: 'KNSYELLO',
      name: "Kniphofia 'Sunningdale Yellow'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHCW6BLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHCW6BLE'
    },
    {
      status: 210,
      sku: 'PM0072955',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253830',
      rtl_batch_array: '6253830',
      name_search: 'ACMAPFEL',
      name: "Achillea millefolium 'Apfelbl\u00fcte'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XTP3PJAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTP3PJAP'
    },
    {
      status: 210,
      sku: 'PM0072957',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253833',
      rtl_batch_array: '6253833',
      name_search: 'AGROTTER',
      name: "Agapanthus 'Rotterdam'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5W9LPW8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W9LPW8N'
    },
    {
      status: 210,
      sku: 'PM0080683',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338054',
      rtl_batch_array: '6338054',
      name_search: 'HAMAUREO',
      name: "Hakonechloa macra 'Aureola'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LGPSH6BS;HAWZ7TB3;Y2VX4LTC;69ALZ6C1;61XAZ3JS;7V1LCS31;LD6HALZH;7XDBZ8J9;1WXLHHXT;YX55G1N1;A1P59SYS;ZS3J9J5L;KSWTBCH3;8LWBDWX8;9AX5RWL5;YTD95PRE;8N73A5TY;2DNH4NX9;7DGLA1NW;N65D24CL;DPGAS8SL;GJC55GAR;S1GN9PG1;5V7J2AGS',
      statusContent: 'dot',
      hash: 'L2NW2XSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2NW2XSK'
    },
    {
      status: 210,
      sku: 'PM0072964',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257824',
      rtl_batch_array: '6257824',
      name_search: 'CHLGLOBO',
      name: "Chamaecyparis lawsoniana 'Globosa'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 172,
      chnn_stock_retail: 172,
      sppl_prcp: 6.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4XBR2549',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XBR2549'
    },
    {
      status: 210,
      sku: 'PM0072965',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257826',
      rtl_batch_array: '6257826',
      name_search: 'CHOBUTTE',
      name: "Chamaecyparis obtusa 'Butterball'",
      sppl_size_code: '015020C3',
      rng_range_identifier: 'H015020C3',
      rng_range_description: 'H15 cm 20 cm C3',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_prcp: 4.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L39DL8CX',
      statusContent: 'dot',
      hash: '888HN415',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '888HN415'
    },
    {
      status: 210,
      sku: 'PM0080684',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338055',
      rtl_batch_array: '6338055',
      name_search: 'HAMSUNFL',
      name: 'Hakonechloa macra Sunflare',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '21T8ZPPC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21T8ZPPC'
    },
    {
      status: 210,
      sku: 'PM0051988',
      core_name: 'Plant',
      sppl_ean: '8720626333208',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5964221',
      rtl_batch_array: '5964221, 6254865',
      name_search: 'ACMCQUEE',
      name: "Achillea millefolium 'Cerise Queen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 225,
      chnn_stock_retail: 1319,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYAP7K78;113NZJKA;G1DCNVHG;LHAK24LV',
      statusContent: 'dot',
      hash: 'JDC5YXRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDC5YXRT'
    },
    {
      status: 210,
      sku: 'PM0052001',
      core_name: 'Plant',
      sppl_ean: '8720349463664',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6003575',
      rtl_batch_array: '6003575, 6252445',
      name_search: 'ALSCHOEN',
      name: 'Allium schoenoprasum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 884,
      chnn_stock_retail: 1867,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9X6T5V58;BVLDH82B;SH7PSE16;6W1XW6SL;EJZRPV4D;WC9WCTP2;5HD7LR8G;NBB1LKND;7KL21XJ6;WJ6C4JVJ;5EVEPACW;TSR4CVNC;KADNX2DN',
      statusContent: 'dot',
      hash: 'PPNV3Z9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPNV3Z9L'
    },
    {
      status: 210,
      sku: 'PM0015816',
      core_name: 'Plant',
      sppl_ean: '8720349416547',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5902345',
      rtl_batch_array: '6054638, 5902345, 6090761',
      name_search: 'GESANGUI',
      name: 'Geranium sanguineum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1196,
      chnn_stock_retail: 11062,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2EKJ6426;Y5L86BXG;6YSRB1JT;AT5RV1BJ;ZD1EJ9K9;P38CDZ5J;T4ASJV2R;1NDBZLXW;DSENE4P6;JKAZE5GA;LE7KAVRL',
      statusContent: 'dot',
      hash: 'SYLR6DGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYLR6DGS'
    },
    {
      status: 210,
      sku: 'PM0006500',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 4,
      btch_active_retail: '6254253',
      rtl_batch_array: '5411097, 5927562, 5348864, 6254253',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 8468,
      sppl_prcp: 1.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '18G7TSNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18G7TSNE'
    },
    {
      status: 210,
      sku: 'PM0013069',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 7,
      btch_active_retail: '6230995',
      rtl_batch_array:
        '6084941, 6207991, 6230995, 6159825, 5301403, 6348456, 6385474',
      name_search: 'GEROZANN',
      name: 'Geranium Rozanne',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4800,
      chnn_stock_retail: 77811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZPJ4SN3;GT4DVXXL;C24JN3BT;XBYX425W;RNV4HWHL;JJ79CY8L;Z3PSHXBT;RRSTZT5E;L3WB9GNP;RB8ELNE5;XDL56ESV;BVLK6EXK;J1XBHEEE',
      statusContent: 'dot',
      hash: 'ASTCJ8EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASTCJ8EV'
    },
    {
      status: 210,
      sku: 'PM0000532',
      core_name: 'Plant',
      sppl_ean: '8720664884656',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6133622',
      rtl_batch_array: '6349143, 6133622, 6330071',
      name_search: 'SANBLAUK',
      name: "Salvia nemorosa 'Blauk\u00f6nigin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2452,
      chnn_stock_retail: 12945,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XLB9HYYP;GX5WZWPZ;GGTLJNCR;KG43N8DW;E2Z14LPE;9VVCPLNG;TBN7KPWK;KP23H1LJ;TJCPSPPC;5LCAGGW4;RZ183BET;6PZ3L3D3',
      statusContent: 'dot',
      hash: 'BLVC7C66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLVC7C66'
    },
    {
      status: 210,
      sku: 'PM0080685',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338056',
      rtl_batch_array: '6338056',
      name_search: 'HACAROLI',
      name: 'Halesia carolina',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CXEH8YYB;VG75XELD;NGYYHXRZ',
      statusContent: 'dot',
      hash: 'N6ZWJ1H4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6ZWJ1H4'
    },
    {
      status: 210,
      sku: 'PM0026489',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6205780',
      rtl_batch_array: '6205780, 5495341, 6383353, 6348328',
      name_search: 'GESALBUM',
      name: "Geranium sanguineum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 13986,
      sppl_order_minimum: 3,
      sppl_prcp: 0.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWW894AG;H339Y9JL;BJAD93HB;8N3G6J8Y;BN3YAHX6;K3D4B4Z8;5JT3ZVH6',
      statusContent: 'dot',
      hash: 'CT6DL1LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT6DL1LC'
    },
    {
      status: 210,
      sku: 'PM0080686',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338057',
      rtl_batch_array: '6338057',
      name_search: 'HAMONTIC',
      name: 'Halesia monticola',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 3,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A9C32LKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9C32LKS'
    },
    {
      status: 210,
      sku: 'PM0012658',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6321915',
      rtl_batch_array: '6220712, 6254370, 6321915',
      name_search: 'ECBTBLUE',
      name: "Echinops bannaticus 'Taplow Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1821,
      chnn_stock_retail: 4063,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '29JRG36N;GDD5WC18;JHD854Z2;N7DKC6S8',
      statusContent: 'dot',
      hash: 'V6BZ6NGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6BZ6NGJ'
    },
    {
      status: 210,
      sku: 'PM0019228',
      core_name: 'Plant',
      btch_manufacturer: 452,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193714',
      rtl_batch_array: '6193714',
      name_search: 'BLSALBA',
      name: "Bletilla striata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LKA1S79S;47LL4XXJ;XZZBGKYN;WJ1SK4AN;WSBVE6BJ;4ASEG4CW;PWZLCAZ8;75YYJJDA;P7XSVH4H',
      statusContent: 'dot',
      hash: 'Z9S4E1WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9S4E1WB'
    },
    {
      status: 210,
      sku: 'PM0072966',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257827',
      rtl_batch_array: '6257827',
      name_search: 'CHOKAMAR',
      name: "Chamaecyparis obtusa 'Kamarachiba'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 696,
      chnn_stock_retail: 696,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S48852HG;W8TCVX1L;VL52KT58',
      statusContent: 'dot',
      hash: '7PXGCWS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PXGCWS1'
    },
    {
      status: 210,
      sku: 'PM0072967',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257828',
      rtl_batch_array: '6257828',
      name_search: 'CHPBBLUE',
      name: "Chamaecyparis pisifera 'Baby Blue'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R6YKKLGE;J58NSP5J;BNLZ2YK3;TA433EW5;BDHVHWX8;LZLAWW74;WEKKWWEW',
      statusContent: 'dot',
      hash: '6KZ71G3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KZ71G3P'
    },
    {
      status: 210,
      sku: 'PM0072968',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257830',
      rtl_batch_array: '6257830',
      name_search: 'CRJLCHAM',
      name: "Cryptomeria japonica 'Little Champion'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1708,
      chnn_stock_retail: 1708,
      sppl_prcp: 6.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5RACXHR;2BWLBDNG',
      statusContent: 'dot',
      hash: 'NKNSB3HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKNSB3HH'
    },
    {
      status: 210,
      sku: 'PM0072969',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257832',
      rtl_batch_array: '6257832',
      name_search: 'CRJMNANA',
      name: "Cryptomeria japonica 'Monstrosa Nana'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CWSS148',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CWSS148'
    },
    {
      status: 210,
      sku: 'PM0080687',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338058',
      rtl_batch_array: '6338058',
      name_search: 'HAIPRIMA',
      name: "Hamamelis intermedia 'Primavera'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSVJCZCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSVJCZCL'
    },
    {
      status: 910,
      sku: 'PM0072972',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JUCCOMPR',
      name: "Juniperus communis 'Compressa'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      imageCore: 'WX1GRV22;H2G3GS33',
      statusContent: 'dot',
      hash: '6ZAEWCEY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6ZAEWCEY'
    },
    {
      status: 210,
      sku: 'PM0016603',
      core_name: 'Plant',
      sppl_ean: '8720349413959',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2718182',
      rtl_batch_array: '2718182',
      name_search: 'COSMINIP',
      name: 'Cortaderia selloana Minipampas',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'R5ZRC3WY',
      statusContent: 'dot',
      hash: '4PG4D8HV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PG4D8HV'
    },
    {
      status: 210,
      sku: 'PM0072974',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257839',
      rtl_batch_array: '6257839',
      name_search: 'JUCSCHLA',
      name: "Juniperus conferta 'Schlager'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1158,
      chnn_stock_retail: 1158,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE4YPK6H;SXWS95KY;XEPG53BJ;YL1L8H4W;K7RBDDAE;71EYV6VB;2W7AAP17;YG3HWY7K;ZTSDELW2;YL2ETV7K;BJXWD1KV',
      statusContent: 'dot',
      hash: '8R2RS93S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8R2RS93S'
    },
    {
      status: 210,
      sku: 'PM0072975',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257840',
      rtl_batch_array: '6257840',
      name_search: 'JUCSCHLA',
      name: "Juniperus conferta 'Schlager'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 434,
      chnn_stock_retail: 434,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE4YPK6H;SXWS95KY;XEPG53BJ;YL1L8H4W;K7RBDDAE;71EYV6VB;2W7AAP17;YG3HWY7K;ZTSDELW2;YL2ETV7K;BJXWD1KV',
      statusContent: 'dot',
      hash: '96V3SVSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96V3SVSH'
    },
    {
      status: 810,
      sku: 'PM0072977',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257842',
      rtl_batch_array: '6257842',
      name_search: 'JUSABINA',
      name: 'Juniperus sabina',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5R3XZ6X8;GTABHDTH;RD4DJHET;85C7V2ZW',
      statusContent: 'dot',
      hash: 'LSP9BP9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSP9BP9W'
    },
    {
      status: 810,
      sku: 'PM0072931',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253800',
      rtl_batch_array: '6253800',
      name_search: 'LOBDSCAR',
      name: "Lonicera brownii 'Dropmore Scarlet'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.841,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HRTY5Z56;KYAA5AE3;LVCECV1Z;82824D6X;PKDCCSHJ;ALG82C2J;KA8C4BHG;N3XT2CAC;CTBHCJAW;H27YVTBB;6TZJWLPY;5SGY7JHZ;YR94JL91;4A1V5NZJ;DETDPH31;NHKSVAWZ;SRZSRK37;SB3HCNRP;CPLS19Y1;A5BP2L6G;YSG8BWYW;5WK11HAP',
      statusContent: 'dot',
      hash: 'LJ677A1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ677A1B'
    },
    {
      status: 210,
      sku: 'PM0072936',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253807',
      rtl_batch_array: '6253807',
      name_search: 'CARADICA',
      name: 'Campsis radicans',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.994,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GYXZ7DXA;T3B2G547;YD2RVWG1;1CESY5VH;69C1BKNA;EVCK5KY3;93NCZB3H;G3JP3HEX;BHLTX997;PJLJSGYP',
      statusContent: 'dot',
      hash: 'GBGTL386',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBGTL386'
    },
    {
      status: 910,
      sku: 'PM0072937',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLHUVI',
      name: "Clematis 'Huvi'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '448WDDN6;K836RHKH;2SZ7RBW9',
      statusContent: 'dot',
      hash: '293R2KKP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '293R2KKP'
    },
    {
      status: 910,
      sku: 'PM0072953',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLMJCORR',
      name: "Clematis 'Madame Julia Correvon'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'V7T66HTH;PKAE469S;S4D5YD6B;R28B73TD;XBLTG9DX;WTH1E3N5',
      statusContent: 'dot',
      hash: '8X69PEWJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8X69PEWJ'
    },
    {
      status: 910,
      sku: 'PM0072947',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLVLYON',
      name: "Clematis 'Ville de Lyon'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'TWNV29KS;918ABGA1;GLHCXVK1;7LA48278;TRRL5B7P;W6RL2AW3;CH5BCNKG;XPN2391D;RB1BNNJE;WL9EX33H;XJH26RHD;YVY3RYKE;9S1N3Z1A;H1LS6LCL;J27E55DS;SDGAY5CZ;L795T6NB;A3GK9SW4;7XTC9EZT;V5EBLPAB;NXDGGTKJ;ZXJR3DPH;T3C5VKAC;AZZE81NA',
      statusContent: 'dot',
      hash: 'BK7CHY3J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BK7CHY3J'
    },
    {
      status: 210,
      sku: 'PM0072976',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257841',
      rtl_batch_array: '6257841',
      name_search: 'JUPGSTAR',
      name: "Juniperus pfitzeriana 'Gold Star'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1014,
      chnn_stock_retail: 1014,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PL8238PW;NL1SPXV8;SJS2EE6W;S73PXD3G;J24CLA6Z;VSNXR8ZX;7KDD6YGX;HZERSDKW;E1B9C9LX;C6R4GV65;CJP93ZXL',
      statusContent: 'dot',
      hash: 'B2ZXG7DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2ZXG7DX'
    },
    {
      status: 810,
      sku: 'PM0072939',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253810',
      rtl_batch_array: '6253810',
      name_search: 'LOHGOLDF',
      name: "Lonicera heckrottii 'Goldflame'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.841,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K9GGGXCS;SSAH4CPD;RKWJ8RJ2;VCE5YNRG;KVCKEWN4;T522KXAW;X71CA61Y;JJLYGBH9;94Y7V92R;WV2C8H19;LLLHK457;4BWWAL4V;WNA4XLCP',
      statusContent: 'dot',
      hash: 'DJDW4KYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJDW4KYP'
    },
    {
      status: 810,
      sku: 'PM0072945',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253817',
      rtl_batch_array: '6253817',
      name_search: 'LOPBELGI',
      name: "Lonicera periclymenum 'Belgica'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.841,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPRANN1H;GA61NY68',
      statusContent: 'dot',
      hash: 'XSG6JLY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSG6JLY1'
    },
    {
      status: 210,
      sku: 'PM0072956',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253832',
      rtl_batch_array: '6253832',
      name_search: 'TRJPSHOW',
      name: "Trachelospermum jasminoides 'Pink Showers'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.81,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RBEE8L1P;CRB7RTYN;YCCDD1CK;9G9NXAEH',
      statusContent: 'dot',
      hash: 'REV1W493',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REV1W493'
    },
    {
      status: 210,
      sku: 'PM0051987',
      core_name: 'Plant',
      sppl_ean: '8720349483440',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6003572',
      rtl_batch_array: '6003572, 6254864',
      name_search: 'ACFCGOLD',
      name: "Achillea filipendulina 'Cloth of Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1679,
      chnn_stock_retail: 3387,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JZPNA69A;Z73TXG24;L9JAL6CT;JCH6YLB4;GA3L3B3E;9LTH5DBB;C22RLHXX;LTY8BPE8;VPDA68YK;GYHJ9A7L;YPGZJ4XT',
      statusContent: 'dot',
      hash: '92EHVRBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92EHVRBL'
    },
    {
      status: 210,
      sku: 'PM0018262',
      core_name: 'Plant',
      sppl_ean: '8720349408375',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4380371',
      rtl_batch_array: '4380371',
      name_search: 'ACGOGON',
      name: "Acorus gramineus 'Ogon'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XEX4W2P1;7B3N2R24;HXBERTTP;PV39NESW;E264R1NJ;R5PEL6CS;PE7Z6R3T;V4T6VD5X',
      imageBatch: 'A8ZL2TB8',
      statusContent: 'dot',
      hash: 'VWTRC1WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWTRC1WB'
    },
    {
      status: 210,
      sku: 'PM0072959',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253835',
      rtl_batch_array: '6253835',
      name_search: 'AGAALBUS',
      name: "Agapanthus africanus 'Albus'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAXYWE1K;JGE9ZRJ8;NEX5HJNV;AV14EGZ4;LEV5WRS6',
      statusContent: 'dot',
      hash: '2L1WNC44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2L1WNC44'
    },
    {
      status: 210,
      sku: 'PM0072930',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253798',
      rtl_batch_array: '6253798',
      name_search: 'PAHENRYA',
      name: 'Parthenocissus henryana',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 2.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '89G7ZTN2;XWAVDK9V',
      statusContent: 'dot',
      hash: '5DK8WBRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DK8WBRZ'
    },
    {
      status: 210,
      sku: 'PM0015770',
      core_name: 'Plant',
      sppl_ean: '8720349411764',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '2624736',
      rtl_batch_array: '2624736',
      name_search: 'CACBFORM',
      name: "Carex comans 'Bronze Form'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.119,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DETA2LLY;SLTRBH5C;GKB8WGT4;WYSS85WR;1TCTS9E8;Z7J2NP88;L5BBAJX3;V4XGJG8R;JVTX6T7A;9G91XD8D;H5GRDS1K;VBW63X7B;71XCG96S;D4SK9DZV;CYR4YT14;GSSBA74Y;HWW9956Z',
      imageBatch: 'NYAHPS3Z',
      statusContent: 'dot',
      hash: '9S35RG49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S35RG49'
    },
    {
      status: 910,
      sku: 'PM0072935',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLVITICE',
      name: 'Clematis viticella',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'RDEAHC1L;PJV55C61;BR134GES;WGYSDVH9',
      statusContent: 'dot',
      hash: 'THH9D9NJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'THH9D9NJ'
    },
    {
      status: 810,
      sku: 'PM0080688',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338059',
      rtl_batch_array: '6338059',
      name_search: 'HAIRUBIN',
      name: "Hamamelis intermedia 'Rubin'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ38ZTBB;7G4J3W6T;PP39767R;Y55BSRTZ;ENJCRJ9T;CV28YW5D;B9C67895;NYJSJGPR',
      statusContent: 'dot',
      hash: 'KB518X1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KB518X1Y'
    },
    {
      status: 210,
      sku: 'PM0080689',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338060',
      rtl_batch_array: '6338060',
      name_search: 'HAISUNBU',
      name: "Hamamelis intermedia 'Sunburst'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSB7R2N4;CYSBP78A;12SL8HY9;PBBCTH33;EGE3A5XJ',
      statusContent: 'dot',
      hash: '6LH9E4LP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LH9E4LP'
    },
    {
      status: 910,
      sku: 'PM0072990',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RIASCHMI',
      name: "Ribes alpinum 'Schmidt'",
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      imageCore: 'AVALV4JT',
      statusContent: 'dot',
      hash: 'JAKK55JP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JAKK55JP'
    },
    {
      status: 210,
      sku: 'PM0072978',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257843',
      rtl_batch_array: '6257843',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 746,
      chnn_stock_retail: 746,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: '4Z4CGP4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z4CGP4B'
    },
    {
      status: 210,
      sku: 'PM0082967',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355674',
      rtl_batch_array: '6355674',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 3940,
      chnn_stock_retail: 3940,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'BCKCJ5W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCKCJ5W9'
    },
    {
      status: 810,
      sku: 'PM0075415',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280848',
      rtl_batch_array: '6280848',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.422,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'BE9VKBZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE9VKBZH'
    },
    {
      status: 210,
      sku: 'PM0072987',
      core_name: 'Plant',
      sppl_ean: '8720353023830',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905549',
      rtl_batch_array: '5905549',
      name_search: 'LEABDUNE',
      name: "Leymus arenarius 'Blue Dune'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 568,
      chnn_stock_retail: 568,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4SB15NT;LK9B89KX',
      statusContent: 'dot',
      hash: 'JJ1JSY4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJ1JSY4Y'
    },
    {
      status: 810,
      sku: 'PM0075414',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280847',
      rtl_batch_array: '6280847, 6334281',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 49,
      chnn_stock_retail: 89,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '5N2ZVKCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N2ZVKCL'
    },
    {
      status: 810,
      sku: 'PM0075412',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280845',
      rtl_batch_array: '6280845',
      name_search: 'ACPORNAT',
      name: "Acer palmatum 'Ornatum'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L53NTBYD;TJDALSHC;KY57VLLJ;B1L6EBNH;XD8NP4A5;1X43S6NZ;15R88YV7;R5V17KY4;N3L8GKHK;WRN7TTT9;X2JB897R;X41JVPBA;CAN9NDA3;6B5BHD5T',
      statusContent: 'dot',
      hash: 'XLVZJAP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLVZJAP7'
    },
    {
      status: 210,
      sku: 'PM0072991',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310426',
      rtl_batch_array: '5932496, 6310426',
      name_search: 'SPTHUNBE',
      name: 'Spiraea thunbergii',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1300,
      chnn_stock_retail: 2764,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5BVPVZ6;X3719ERG',
      statusContent: 'dot',
      hash: 'SCND7RZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCND7RZL'
    },
    {
      status: 210,
      sku: 'PM0082968',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355675',
      rtl_batch_array: '6355675',
      name_search: 'COLACTEU',
      name: 'Cotoneaster lacteus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1876,
      chnn_stock_retail: 1876,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBLSAC4B;PR1173VV;DPHVETH3;7BA9YC8Y;SLHYXZLL;77D3WDW4',
      statusContent: 'dot',
      hash: 'JG5L12C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JG5L12C8'
    },
    {
      status: 210,
      sku: 'PM0080690',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338061',
      rtl_batch_array: '6338061',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'CXCEJPKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXCEJPKZ'
    },
    {
      status: 210,
      sku: 'PM0080691',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338062',
      rtl_batch_array: '6338062',
      name_search: 'HAIYAMIN',
      name: "Hamamelis intermedia 'Yamina'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A7DX7DWJ;N9KTP7ND',
      statusContent: 'dot',
      hash: 'X76JYYT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X76JYYT2'
    },
    {
      status: 810,
      sku: 'PM0080692',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338063',
      rtl_batch_array: '6338063',
      name_search: 'HAMOLLIS',
      name: 'Hamamelis mollis',
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JPEPBEDG;Y6DKAK9V;DGCWZJG3;H3NL7VBD;KGPKL4TB',
      statusContent: 'dot',
      hash: 'TDNDJ3JB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDNDJ3JB'
    },
    {
      status: 810,
      sku: 'PM0080693',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338064',
      rtl_batch_array: '6338064',
      name_search: 'HAMJGOLD',
      name: "Hamamelis mollis 'Jermyns Gold'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 5.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HRR696H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HRR696H'
    },
    {
      status: 210,
      sku: 'PM0080694',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338065',
      rtl_batch_array: '6338065',
      name_search: 'HEREDGE',
      name: "Hebe 'Red Edge'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 273,
      chnn_stock_retail: 273,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6ZZSKXH;7LJTJYEW;TGSTWGGG;S3LJYXZW;8WBY4J4G;L2GGXHV6;G4YSBYTE;16GTYBJ2;LW426RZE;NZRE28PL;JT7CBSY3;NVY8ACEN;WC51ANHS',
      statusContent: 'dot',
      hash: '7SY58CX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SY58CX9'
    },
    {
      status: 210,
      sku: 'PM0082969',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355676',
      rtl_batch_array: '6355676',
      name_search: 'COSIMONS',
      name: 'Cotoneaster simonsii',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1744,
      chnn_stock_retail: 1744,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '693G1Z6B',
      statusContent: 'dot',
      hash: 'YEX9N15C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEX9N15C'
    },
    {
      status: 210,
      sku: 'PM0072844',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255628',
      rtl_batch_array: '6255628',
      name_search: 'ILMLRASC',
      name: 'Ilex meserveae Little Rascal',
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13GARV24;71E7CTSY;BS8SHAWL;XPE48L27',
      statusContent: 'dot',
      hash: 'XZKJV32L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZKJV32L'
    },
    {
      status: 210,
      sku: 'PM0072845',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304886',
      rtl_batch_array: '6304886, 6255633',
      name_search: 'RHSSWORD',
      name: "Rhododendron (AJ) 'Silver Sword'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2302,
      chnn_stock_retail: 2802,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KBVA9PXS;YVZ9N1WZ;ZW1WW3YC;YHBCT7N4;A4H9Y2J1;ZEYNXCV9;H9BT934P;TC4LRGV8;JZCNHGX9;1ZZLJP69;K147ESY4;6S92GZSY;S7ZYH3D2',
      statusContent: 'dot',
      hash: '63DCRJPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63DCRJPD'
    },
    {
      status: 210,
      sku: 'PM0072846',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255634',
      rtl_batch_array: '6255634',
      name_search: 'EUFINTER',
      name: "Euonymus fortunei 'Interbolwi'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X17V3Y6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X17V3Y6L'
    },
    {
      status: 210,
      sku: 'PM0080695',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338066',
      rtl_batch_array: '6338066',
      name_search: 'HEFBGEM',
      name: "Hebe franciscana 'Blue Gem'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 289,
      chnn_stock_retail: 289,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KVRZ1LV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVRZ1LV6'
    },
    {
      status: 210,
      sku: 'PM0080696',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338067',
      rtl_batch_array: '6338067',
      name_search: 'HENBLUSH',
      name: "Hebe 'Nicola's Blush'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 466,
      chnn_stock_retail: 466,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7N958EYK',
      statusContent: 'dot',
      hash: 'A27W2KDK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A27W2KDK'
    },
    {
      status: 210,
      sku: 'PM0082970',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355677',
      rtl_batch_array: '6355677',
      name_search: 'DISPLEND',
      name: 'Diervilla splendens',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4P9DKE3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P9DKE3Y'
    },
    {
      status: 210,
      sku: 'PM0072849',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255637',
      rtl_batch_array: '6255637',
      name_search: 'ILAINGRA',
      name: "Ilex aquifolium 'Ingramii'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LC1L5J6A;64KB8Y8V;HEN82888;EP7WP1Z8;NK1XJGN7;1P4D54PL',
      statusContent: 'dot',
      hash: 'RH7ETEEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RH7ETEEB'
    },
    {
      status: 210,
      sku: 'PM0072850',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255638',
      rtl_batch_array: '6255638',
      name_search: 'ILAINGRA',
      name: "Ilex aquifolium 'Ingramii'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LC1L5J6A;64KB8Y8V;HEN82888;EP7WP1Z8;NK1XJGN7;1P4D54PL',
      statusContent: 'dot',
      hash: 'J9LY1KNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9LY1KNJ'
    },
    {
      status: 910,
      sku: 'PM0072851',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIIMUSLI',
      name: 'Ligustrum ibota Musli',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore: 'KNZKC43W;TBWV2J7E',
      statusContent: 'dot',
      hash: 'WBCYN8S3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WBCYN8S3'
    },
    {
      status: 910,
      sku: 'PM0072852',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONGGLOW',
      name: "Lonicera nitida 'Golden Glow'",
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      imageCore: 'A2BCZ5W6',
      statusContent: 'dot',
      hash: 'JAPYWNTS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JAPYWNTS'
    },
    {
      status: 210,
      sku: 'PM0080697',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338068',
      rtl_batch_array: '6338068',
      name_search: 'HEWGEM',
      name: "Hebe 'White Gem'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '229YE4DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '229YE4DY'
    },
    {
      status: 210,
      sku: 'PM0075402',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336152',
      rtl_batch_array: '6378334, 6336152',
      name_search: 'HEHANNA',
      name: 'Hebe Hebedonna Anna',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3022,
      chnn_stock_retail: 4022,
      sppl_order_minimum: 3,
      sppl_prcp: 2.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PWJX5HD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWJX5HD7'
    },
    {
      status: 210,
      sku: 'PM0075403',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6298605',
      rtl_batch_array: '6298605, 6378335',
      name_search: 'HEHANNA',
      name: 'Hebe Hebedonna Anna',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1351,
      chnn_stock_retail: 3799,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H7T6N54D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7T6N54D'
    },
    {
      status: 210,
      sku: 'PM0072898',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257456',
      rtl_batch_array: '6257456',
      name_search: 'CEHMFLEM',
      name: "Cephalotaxus h. 'Mary Fleming'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSN5GAXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSN5GAXT'
    },
    {
      status: 210,
      sku: 'PM0080698',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338069',
      rtl_batch_array: '6338069',
      name_search: 'HEAGMARE',
      name: "Hedera algeriensis 'Gloire de Marengo'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'REA555VX;AWYR38ZD;2S2AX7BR;R5VCW52D;PGPE1Z29;WYCBSHR9',
      statusContent: 'dot',
      hash: 'G4461RDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4461RDD'
    },
    {
      status: 210,
      sku: 'PM0072981',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257846',
      rtl_batch_array: '6257846',
      name_search: 'PIGAGLOB',
      name: "Picea glauca 'Alberta Globe'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 364,
      chnn_stock_retail: 364,
      sppl_prcp: 8.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV3TNY1Y;SYL9ZJWZ;WBYGXE4E;2D7SNGX3;8BXW263X;52R49AND;8174AN7A',
      statusContent: 'dot',
      hash: 'G7AA5Z2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7AA5Z2B'
    },
    {
      status: 210,
      sku: 'PM0072982',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257847',
      rtl_batch_array: '6257847',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 469,
      chnn_stock_retail: 469,
      sppl_prcp: 5.339,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'RVSGCARG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVSGCARG'
    },
    {
      status: 210,
      sku: 'PM0072983',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264781',
      rtl_batch_array: '6264781',
      name_search: 'SESEMPER',
      name: 'Sequoia sempervirens',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_prcp: 8.124,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NDT9LR6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDT9LR6G'
    },
    {
      status: 210,
      sku: 'PM0072986',
      core_name: 'Plant',
      sppl_ean: '8720349460960',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905491',
      rtl_batch_array: '5905491',
      name_search: 'PEOFTAIL',
      name: "Pennisetum orientale 'Fairy Tails'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 859,
      chnn_stock_retail: 859,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4AJL1YLS;4WDJXT26',
      statusContent: 'dot',
      hash: 'GEK5XT3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEK5XT3J'
    },
    {
      status: 210,
      sku: 'PM0080699',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338071',
      rtl_batch_array: '6338071',
      name_search: 'HEDCFFAV',
      name: "Hedera colchica 'Fall Favourite'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHC76BAZ;HVLEN2ZV;PDVV9TW9;7WV2LS4E;BX8B2LWB;2RVLX4BK;TLDZB1ZL',
      statusContent: 'dot',
      hash: '9VGRR4TD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VGRR4TD'
    },
    {
      status: 210,
      sku: 'PM0072988',
      core_name: 'Plant',
      sppl_ean: '8720664699274',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905561',
      rtl_batch_array: '5905561',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 962,
      chnn_stock_retail: 962,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: '5PKX2P3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5PKX2P3W'
    },
    {
      status: 210,
      sku: 'PM0072989',
      core_name: 'Plant',
      sppl_ean: '8720626359802',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905580',
      rtl_batch_array: '5905580',
      name_search: 'MOASKYRA',
      name: "Molinia arundinacea 'Skyracer'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 986,
      chnn_stock_retail: 986,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W1Y7PXL9;WGBPZDJZ;SKYKN8P6;1Y89EDZL;GN6S2XY1',
      statusContent: 'dot',
      hash: 'VXCCDZ9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXCCDZ9J'
    },
    {
      status: 210,
      sku: 'PM0080700',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338072',
      rtl_batch_array: '6338072',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1414,
      chnn_stock_retail: 1414,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'GKJ38LJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKJ38LJW'
    },
    {
      status: 210,
      sku: 'PM0080701',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338075',
      rtl_batch_array: '6338075',
      name_search: 'HEHERECT',
      name: "Hedera helix 'Erecta'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D6L7AC7N;BJ4VBX3Y;1G399ASV;RBEBD5LN;61ZEECC2;AW1PBHPJ;TS91AZSB;EVK75CP1',
      statusContent: 'dot',
      hash: '4BVNYJSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BVNYJSB'
    },
    {
      status: 210,
      sku: 'PM0080702',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338076',
      rtl_batch_array: '6338076',
      name_search: 'HEHSIMON',
      name: "Hedera helix 'Simone'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 719,
      chnn_stock_retail: 719,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALDVGSR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALDVGSR4'
    },
    {
      status: 210,
      sku: 'PM0080703',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338077',
      rtl_batch_array: '6338077',
      name_search: 'HEAKORMA',
      name: "Helichrysum apiculatum 'Korma'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 615,
      chnn_stock_retail: 615,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DCXPCYHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCXPCYHS'
    },
    {
      status: 210,
      sku: 'PM0080704',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338078',
      rtl_batch_array: '6338078',
      name_search: 'HEISEROT',
      name: 'Helichrysum italicum serotinum',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9LXNTJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9LXNTJS'
    },
    {
      status: 210,
      sku: 'PM0080705',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338079',
      rtl_batch_array: '6338079',
      name_search: 'HISADEWE',
      name: "Hibiscus syriacus 'Admiral Dewey'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 645,
      chnn_stock_retail: 645,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PYYBWV3Y;BLGADZT7;HG629RTT',
      statusContent: 'dot',
      hash: 'LZ1H16PC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ1H16PC'
    },
    {
      status: 210,
      sku: 'PM0072984',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257851',
      rtl_batch_array: '6257851',
      name_search: 'TSCJEDDE',
      name: "Tsuga canadensis 'Jeddeloh'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1046,
      chnn_stock_retail: 1046,
      sppl_prcp: 3.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99B8JVWB;9K4Z69BJ;523X2GZ7;PA6ZXZCA;5WV7PV5N;T1H2VVWZ;CCVRV972;G45214ST;Y8VBN2E7;BL2JXSAG',
      statusContent: 'dot',
      hash: 'H8T3DY62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8T3DY62'
    },
    {
      status: 210,
      sku: 'PM0075406',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6298609',
      rtl_batch_array: '6298609, 6378337',
      name_search: 'HEHBRITT',
      name: 'Hebe Hebedonna Britta',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1127,
      chnn_stock_retail: 1642,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VD9HNX62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD9HNX62'
    },
    {
      status: 210,
      sku: 'PM0080706',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338080',
      rtl_batch_array: '6338080',
      name_search: 'HISAPHRO',
      name: "Hibiscus syriacus 'Aphrodite'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 379,
      chnn_stock_retail: 379,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3LZL6GY;WL5GYLR2;V7L1BLB9;X4YNSBXR',
      statusContent: 'dot',
      hash: 'LK3AA9NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LK3AA9NS'
    },
    {
      status: 210,
      sku: 'PM0080707',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338081',
      rtl_batch_array: '6338081',
      name_search: 'HISAPHRO',
      name: "Hibiscus syriacus 'Aphrodite'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3LZL6GY;WL5GYLR2;V7L1BLB9;X4YNSBXR',
      statusContent: 'dot',
      hash: 'D69VLCP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D69VLCP7'
    },
    {
      status: 210,
      sku: 'PM0080708',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338082',
      rtl_batch_array: '6338082',
      name_search: 'HISARDEN',
      name: "Hibiscus syriacus 'Ardens'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3RSRH3S;HCN4JG8A;V82GSVV5;6G3GT5K7;TBBTVPBX',
      statusContent: 'dot',
      hash: 'NC5PGEGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NC5PGEGZ'
    },
    {
      status: 210,
      sku: 'PM0080709',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338083',
      rtl_batch_array: '6338083',
      name_search: 'HISARDEN',
      name: "Hibiscus syriacus 'Ardens'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3RSRH3S;HCN4JG8A;V82GSVV5;6G3GT5K7;TBBTVPBX',
      statusContent: 'dot',
      hash: 'SCA6VGSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCA6VGSP'
    },
    {
      status: 210,
      sku: 'PM0080710',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338084',
      rtl_batch_array: '6338084',
      name_search: 'HISBCHIF',
      name: 'Hibiscus syriacus Blue Chiffon',
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 790,
      chnn_stock_retail: 790,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NC4YK11W;PZDJVYPW;X678NVVX;TATSGP6K;2SA72EHX;BZWWSDG8;KJZADSD8;SCNBG8ZP;WBSK4YX7;CNL13E94;9NNT6D55',
      statusContent: 'dot',
      hash: 'W8V4PVC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8V4PVC2'
    },
    {
      status: 210,
      sku: 'PM0072979',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257844',
      rtl_batch_array: '6257844',
      name_search: 'JUSHOLGE',
      name: "Juniperus squamata 'Holger'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 6.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '27RDVKTT;CR8CL3Z4;DEXSKTNY;C8Z6KHZ6;BVE5HJDT;13CER4GX;T5JPZL46;S82JXW9H;SCY2ZHED;G2S4ZZVD;BAYEH66C',
      statusContent: 'dot',
      hash: 'JKXY8C7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKXY8C7N'
    },
    {
      status: 810,
      sku: 'PM0080711',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338085',
      rtl_batch_array: '6338085',
      name_search: 'HISDBRAB',
      name: "Hibiscus syriacus 'Duc de Brabant'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52PBSE7C;VD2L7JL9;6Z5THXR2;294ZR3EX;RPBNELT7',
      statusContent: 'dot',
      hash: '8AXAABY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AXAABY1'
    },
    {
      status: 210,
      sku: 'PM0080712',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338086',
      rtl_batch_array: '6338086',
      name_search: 'HISDBRAB',
      name: "Hibiscus syriacus 'Duc de Brabant'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 710,
      chnn_stock_retail: 710,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52PBSE7C;VD2L7JL9;6Z5THXR2;294ZR3EX;RPBNELT7',
      statusContent: 'dot',
      hash: 'WLEC22J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLEC22J7'
    },
    {
      status: 810,
      sku: 'PM0075413',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280846',
      rtl_batch_array: '6280846',
      name_search: 'ACPORNAT',
      name: "Acer palmatum 'Ornatum'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L53NTBYD;TJDALSHC;KY57VLLJ;B1L6EBNH;XD8NP4A5;1X43S6NZ;15R88YV7;R5V17KY4;N3L8GKHK;WRN7TTT9;X2JB897R;X41JVPBA;CAN9NDA3;6B5BHD5T',
      statusContent: 'dot',
      hash: '1YP4855W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YP4855W'
    },
    {
      status: 210,
      sku: 'PM0020106',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 5,
      btch_active_retail: '6287685',
      rtl_batch_array: '6301940, 6324740, 6129646, 6287685, 6348386',
      name_search: 'NEFAASSE',
      name: 'Nepeta faassenii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2347,
      chnn_stock_retail: 60843,
      sppl_order_minimum: 3,
      sppl_prcp: 0.542,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XT1RXYHP;LEGK51PP',
      statusContent: 'dot',
      hash: '298YXT3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '298YXT3K'
    },
    {
      status: 810,
      sku: 'PM0029342',
      core_name: 'Plant',
      sppl_ean: '8720626354906',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423577',
      rtl_batch_array: '5423577',
      name_search: 'ITEVLHEN',
      name: 'Itea virginica Little Henry',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 4.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '567A1C2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '567A1C2S'
    },
    {
      status: 210,
      sku: 'PM0080713',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338087',
      rtl_batch_array: '6338087',
      name_search: 'HISHAMAB',
      name: "Hibiscus syriacus 'Hamabo'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CL8GXYLK;CGNBLG6B;TRXJBVXG',
      statusContent: 'dot',
      hash: '15K7LX1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15K7LX1T'
    },
    {
      status: 210,
      sku: 'PM0022079',
      core_name: 'Plant',
      sppl_ean: '8720626348042',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495322',
      rtl_batch_array: '6350201, 5495322',
      name_search: 'GEDHEART',
      name: 'Geranium Dragon Heart',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1723,
      chnn_stock_retail: 3490,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XEBCD1LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEBCD1LK'
    },
    {
      status: 210,
      sku: 'PM0080714',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338088',
      rtl_batch_array: '6338088',
      name_search: 'HISHELEN',
      name: "Hibiscus syriacus 'Helene'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9BYG2Y1;D12X4H7H;N4HCBEZ9;6JNGYXYS;9SHL2WPN;E7RJRSKV;5JNTXEKG;PRCC7PE8',
      statusContent: 'dot',
      hash: 'G1C3DZDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1C3DZDB'
    },
    {
      status: 210,
      sku: 'PM0073247',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6308482',
      rtl_batch_array: '6260110, 6259385, 6308482',
      name_search: 'ANHAATKI',
      name: "Anemone hybrida 'Andrea Atkinson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4361,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4PPT85W',
      statusContent: 'dot',
      hash: 'VJYSTV1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJYSTV1E'
    },
    {
      status: 210,
      sku: 'PM0073237',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176516',
      rtl_batch_array: '6176516',
      name_search: 'LISALBA',
      name: "Liatris scariosa 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2A7T4CKC',
      statusContent: 'dot',
      hash: 'LC1XTZ42',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC1XTZ42'
    },
    {
      status: 210,
      sku: 'PM0082971',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356041',
      rtl_batch_array: '6356041',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '030050C3',
      rng_range_identifier: 'H030050C3',
      rng_range_description: 'H30 cm 50 cm C3',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_prcp: 2.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '1DH7VXZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DH7VXZX'
    },
    {
      status: 210,
      sku: 'PM0073417',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302304',
      rtl_batch_array: '5449353, 6302304',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 225,
      chnn_stock_retail: 1215,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: 'DDXXTTKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDXXTTKJ'
    },
    {
      status: 210,
      sku: 'PM0072856',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255658',
      rtl_batch_array: '6255658',
      name_search: 'YASSONCH',
      name: 'Yacon Smallanthus sonchifolius',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YPZR5RXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPZR5RXA'
    },
    {
      status: 210,
      sku: 'PM0023184',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333305',
      rtl_batch_array: '6147878, 6333305, 6238835',
      name_search: 'SALNMARC',
      name: 'Salvia nemorosa Marcus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 1845,
      sppl_order_minimum: 3,
      sppl_prcp: 1.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTTX18TL',
      statusContent: 'dot',
      hash: 'DESX9TJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DESX9TJZ'
    },
    {
      status: 810,
      sku: 'PM0075416',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280851',
      rtl_batch_array: '6280851',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'R711Z4RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R711Z4RT'
    },
    {
      status: 810,
      sku: 'PM0073401',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244929',
      rtl_batch_array: '6244929',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: 'DVS5YV45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVS5YV45'
    },
    {
      status: 210,
      sku: 'PM0073402',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5329107',
      rtl_batch_array: '5329107',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 11.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      imageBatch: 'V29R7V41',
      statusContent: 'dot',
      hash: '9VB5SXD8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VB5SXD8'
    },
    {
      status: 210,
      sku: 'PM0073404',
      core_name: 'Plant',
      sppl_ean: '8720664889897',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356498',
      rtl_batch_array: '5356498, 6336322',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 1669,
      sppl_prcp: 5.865,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      imageBatch: 'J1H13ZNB',
      statusContent: 'dot',
      hash: 'ETSDTV4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETSDTV4N'
    },
    {
      status: 210,
      sku: 'PM0073405',
      core_name: 'Plant',
      sppl_ean: '8720626331570',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5356501',
      rtl_batch_array: '5356501',
      name_search: 'VITSPIRI',
      name: 'Viburnum tinus Spirit',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8264DWJE;XBCPHWDD;42RRAYEL;WS3R68KK;5XHS15D8;AK5153GB',
      imageBatch: 'B3DJYKBG',
      statusContent: 'dot',
      hash: '4KVLLE1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KVLLE1E'
    },
    {
      status: 210,
      sku: 'PM0072973',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257837',
      rtl_batch_array: '6257837',
      name_search: 'JUCGCARP',
      name: "Juniperus communis 'Green Carpet'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 613,
      chnn_stock_retail: 613,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8JCWSDK;ZR2S66Y8;8LENK36Y;PCPJKSAW;AR8SW9TC;R52VR1LR;PHC1CE39;TKSV3RR5;Z65N4W5A;SHYT7DT2;DEB813ZV;X12G2J1L;Y8AGGRKY;2GX8TKBR;E2GBBLY7;SXP5663N;1BR9CVW8;7N3R494Y;E1BY7K57',
      statusContent: 'dot',
      hash: 'V1B3PP9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1B3PP9A'
    },
    {
      status: 210,
      sku: 'PM0073407',
      core_name: 'Plant',
      sppl_ean: '8720626322141',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5378378',
      rtl_batch_array: '5378378',
      name_search: 'RHELIZAB',
      name: "Rhododendron (F) 'Elizabeth'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7W54HEHD;DHVVWX6W;7CBEENAJ;JB8743PN;H58KC9WW;WBN6S33R;YVAW5AB6;H8TPXGE7;PXHG9A8V;T25H9XKX;B7Y17L9B;GHG4ACDA;CWB3BH1A;94SCWYPV;7WV62YVV;AA4EX7PZ',
      imageBatch: 'RXAXA1KC',
      statusContent: 'dot',
      hash: 'N51NA2ET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N51NA2ET'
    },
    {
      status: 210,
      sku: 'PM0073408',
      core_name: 'Plant',
      sppl_ean: '8720626355057',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5391875',
      rtl_batch_array: '5391875, 6298809',
      name_search: 'JUCSTRIC',
      name: "Juniperus chinensis 'Stricta'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4635,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'J26YEYRV',
      statusContent: 'dot',
      hash: 'LELZ7PPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LELZ7PPA'
    },
    {
      status: 210,
      sku: 'PM0080728',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338104',
      rtl_batch_array: '6338104',
      name_search: 'HYAGOLDR',
      name: 'Hydrangea aspera Goldrush',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 769,
      chnn_stock_retail: 769,
      sppl_prcp: 3.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NCN3ZZAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCN3ZZAV'
    },
    {
      status: 210,
      sku: 'PM0073409',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5391876',
      rtl_batch_array: '5391876, 6298835',
      name_search: 'THOANANA',
      name: "Thuja occidentalis 'Aurea Nana'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 9328,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPT3LGC',
      imageBatch: 'XB5Y937S',
      statusContent: 'dot',
      hash: 'HWT1Y3LL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWT1Y3LL'
    },
    {
      status: 210,
      sku: 'PM0073426',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259547',
      rtl_batch_array: '6259547',
      name_search: 'PIJLHEAT',
      name: "Pieris japonica 'Little Heath'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV7A8VKK;2D7HYSVP;5E11VCT7;WEDN342H;6CBCVY9E;DRD2X2DT;5R8EASNG;TT28BTWY;2189SZS3;YZGT72RE;1BG4Z21C;RSHDGCEX;S1V6CXEZ;PW1TVV1A;27N88R7Y',
      statusContent: 'dot',
      hash: 'R4NSZRB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4NSZRB9'
    },
    {
      status: 210,
      sku: 'PM0073411',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449312',
      rtl_batch_array: '5449312',
      name_search: 'CHPBBLUE',
      name: "Chamaecyparis pisifera 'Baby Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R6YKKLGE;J58NSP5J;BNLZ2YK3;TA433EW5;BDHVHWX8;LZLAWW74;WEKKWWEW',
      imageBatch: 'PLYG7YD9',
      statusContent: 'dot',
      hash: 'E3DVDP13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3DVDP13'
    },
    {
      status: 210,
      sku: 'PM0073413',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267741',
      rtl_batch_array: '5449314, 6267741',
      name_search: 'CHPSUNGO',
      name: "Chamaecyparis pisifera 'Sungold'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 1350,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZHLJTR1H;5XZEYB8G;7YNLHJLH;Y1BTW97G;GZJG415D;ECLLZG7H;JAYJDR7D;Z2T7J22C;5592HDCX;AA25R4RZ;2Z5DKD8B;9CYC76PC;ZHDTBX3N;B9C4KJ17;5D5APVLG;64XLHEK9;WP8N54HS',
      statusContent: 'dot',
      hash: 'XSB24X9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSB24X9X'
    },
    {
      status: 210,
      sku: 'PM0073414',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449315',
      rtl_batch_array: '5449315',
      name_search: 'JUCGOLDS',
      name: "Juniperus communis 'Goldschatz'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W17BD5LD;5CKD61E9;GGXNB6HG;V6P7N72H;WZNKJZ3B',
      imageBatch: '8ZN8KKAD',
      statusContent: 'dot',
      hash: 'VKLB94TG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKLB94TG'
    },
    {
      status: 810,
      sku: 'PM0073248',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349746',
      rtl_batch_array: '6259386, 6349746',
      name_search: 'ANHCARGE',
      name: "Anemone hybrida 'Coupe d'Argent'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 564,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPHVYLAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPHVYLAL'
    },
    {
      status: 210,
      sku: 'PM0073415',
      core_name: 'Plant',
      sppl_ean: '8720349492213',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5449351',
      rtl_batch_array: '5449351, 6298810',
      name_search: 'JUCGCARP',
      name: "Juniperus communis 'Green Carpet'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 845,
      chnn_stock_retail: 1705,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8JCWSDK;ZR2S66Y8;8LENK36Y;PCPJKSAW;AR8SW9TC;R52VR1LR;PHC1CE39;TKSV3RR5;Z65N4W5A;SHYT7DT2;DEB813ZV;X12G2J1L;Y8AGGRKY;2GX8TKBR;E2GBBLY7;SXP5663N;1BR9CVW8;7N3R494Y;E1BY7K57',
      imageBatch: 'TSWWP2B5',
      statusContent: 'dot',
      hash: 'PCH4XPV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCH4XPV1'
    },
    {
      status: 210,
      sku: 'PM0073249',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259389',
      rtl_batch_array: '6259389',
      name_search: 'ANHMROSE',
      name: "Anemone hybrida 'Mont-rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 713,
      chnn_stock_retail: 713,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZHT9G9B6',
      statusContent: 'dot',
      hash: 'PSGY2YV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSGY2YV4'
    },
    {
      status: 210,
      sku: 'PM0014136',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220350',
      rtl_batch_array: '6220350, 6202268, 6238722',
      name_search: 'AGBFORTU',
      name: "Agastache 'Blue Fortune'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 11298,
      sppl_order_minimum: 3,
      sppl_prcp: 0.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B2R11S8Z;W48S9DTS;5DSE5BTA;HTWDBNAP;24XTTG9S;SNL33P1P',
      statusContent: 'dot',
      hash: '44EHVXBT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44EHVXBT'
    },
    {
      status: 210,
      sku: 'PM0029424',
      core_name: 'Plant',
      sppl_ean: '8720349421329',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5528340',
      rtl_batch_array: '5528340',
      name_search: 'LEALPINU',
      name: 'Leontopodium alpinum',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 554,
      chnn_stock_retail: 554,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4KWS8CHW;8RR8RTN1;9XARNHCX;ABTWGCK7;7SY1LK43;6KRBWAG3;NPVJR9W5;EA3YS9S7;RK1X7V21;XP1RJ97Y;DEHDH85T;P23RA2C8;4ZGPJDN4;1BYNT9LA;KNENK3SS;J412LCA8;NBKPYJLZ;723BRV7H;BVLPPXSY;PY52X8G2;JYZE6AA7',
      imageBatch: 'APN25T9H',
      statusContent: 'dot',
      hash: '4P6AZ9LD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P6AZ9LD'
    },
    {
      status: 810,
      sku: 'PM0026339',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353420',
      rtl_batch_array: '6353420',
      name_search: 'SEMSEREN',
      name: "Sedum 'Moonlight Serenade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9VCTZ73;JTBVH93C',
      statusContent: 'dot',
      hash: '63GP3LHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63GP3LHB'
    },
    {
      status: 210,
      sku: 'PM0073416',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449352',
      rtl_batch_array: '5449352',
      name_search: 'JUPOGOLD',
      name: "Juniperus pfitzeriana 'Old Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H4AH5NBV;JCYD5JZW;ABRDA4XH;RX122G23;HYNDD2NP;ATX87GRK;52LSLXGD;2PPHP64S;EG5B4Z2H;111L6DNL;H28CXG1S;7151XSEX;S19CRJ87;HPR3EJ1N;D4EB5S1N;9RRH4AP9;WWSTSPWY;4BE6LC9V',
      imageBatch: 'ARLYA8X2',
      statusContent: 'dot',
      hash: '9L28GG7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L28GG7L'
    },
    {
      status: 210,
      sku: 'PM0073250',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349748',
      rtl_batch_array: '6259390, 6349748',
      name_search: 'ANHOUVER',
      name: "Anemone hybrida 'Ouvert\u00fcre'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 166,
      chnn_stock_retail: 612,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '22HYY1A9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22HYY1A9'
    },
    {
      status: 210,
      sku: 'PM0073233',
      core_name: 'Plant',
      sppl_ean: '8720353087665',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014582',
      rtl_batch_array: '6014582',
      name_search: 'ASLDAY',
      name: "Astilbe 'Lovely Day'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WS8TTK1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WS8TTK1Z'
    },
    {
      status: 810,
      sku: 'PM0005225',
      core_name: 'Plant',
      sppl_ean: '8720664887213',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6147913',
      rtl_batch_array: '6147913',
      name_search: 'STMSPINK',
      name: "Stachys monieri 'Saharan Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SX6BXNER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SX6BXNER'
    },
    {
      status: 210,
      sku: 'PM0080729',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338105',
      rtl_batch_array: '6338105',
      name_search: 'HYINVOLU',
      name: 'Hydrangea involucrata',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1136,
      chnn_stock_retail: 1136,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JVADVCZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVADVCZE'
    },
    {
      status: 210,
      sku: 'PM0073418',
      core_name: 'Plant',
      sppl_ean: '8720349420407',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5449472',
      rtl_batch_array: '5449472, 6298817',
      name_search: 'JUSBSTAR',
      name: "Juniperus squamata 'Blue Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 5285,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '223S71E6;YL28ZCAS;BESLT261;C5DRTS5J;JR1P944L;27XBARC5;KE55BKLZ;CYYDK5J8;TP7NVDP8;VJA9BAZY;68BCEN7R;5KJ2YNCK;XX713EV4;4717E53N;LXXWRJ96;SDP1WS9C;2KN4YKZ4;TYC557EP;882YW612;A8PLDHJH;LYT7PRN9;8WHJ75GB;YEL79DVY;XHVVVA2E',
      imageBatch: '66EXBXP5',
      statusContent: 'dot',
      hash: 'K575G9VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K575G9VT'
    },
    {
      status: 810,
      sku: 'PM0073238',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363591',
      rtl_batch_array: '6363591',
      name_search: 'VESSIGHT',
      name: "Veronica spicata 'Sightseeing'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NT76P5XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NT76P5XR'
    },
    {
      status: 810,
      sku: 'PM0006865',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301884',
      rtl_batch_array: '6301884',
      name_search: 'TIATRAIL',
      name: "Tiarella 'Appalachian Trail'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BDPTZDS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDPTZDS9'
    },
    {
      status: 210,
      sku: 'PM0012600',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353432',
      rtl_batch_array: '6353432',
      name_search: 'SEJSTREA',
      name: "Sempervivum 'Jet Stream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1040,
      chnn_stock_retail: 1040,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EW8L7ZXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW8L7ZXE'
    },
    {
      status: 210,
      sku: 'PM0080715',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338090',
      rtl_batch_array: '6338090',
      name_search: 'HISRHEAR',
      name: "Hibiscus syriacus 'Red Heart'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 1180,
      chnn_stock_retail: 1180,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ED8W3VK6;HZ16SZ77;GLPYWH8E;G38D2T2L;D5AN7SDA;GTKGW3LE;81SGBESH;9J9T46BE',
      statusContent: 'dot',
      hash: '286YWK7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '286YWK7L'
    },
    {
      status: 210,
      sku: 'PM0000861',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333230',
      rtl_batch_array: '6350156, 6333230',
      name_search: 'EUPURPUR',
      name: 'Eupatorium purpureum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5070,
      chnn_stock_retail: 6102,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZXWBKT;GAWJRSDN;K7W1LGN9;K9JCAZHW',
      statusContent: 'dot',
      hash: 'JEWLY8WK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEWLY8WK'
    },
    {
      status: 210,
      sku: 'PM0080716',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338091',
      rtl_batch_array: '6338091',
      name_search: 'HISRVIOL',
      name: 'Hibiscus syriacus Russian Violet',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLSDV4YK;SZS956YX;ERLLH13W;51CL5V7E;1SETVYYB;XHVYW55W;DW9PLEHA;NVE2CYA9;156DPDCP;L4ZYNLT3;VBY4DVC2;GC3SB1LZ;WG7291D7;ZV1P8B72',
      statusContent: 'dot',
      hash: 'ZK6Z88KS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK6Z88KS'
    },
    {
      status: 210,
      sku: 'PM0080717',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338092',
      rtl_batch_array: '6338092',
      name_search: 'HISRVIOL',
      name: 'Hibiscus syriacus Russian Violet',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WLSDV4YK;SZS956YX;ERLLH13W;51CL5V7E;1SETVYYB;XHVYW55W;DW9PLEHA;NVE2CYA9;156DPDCP;L4ZYNLT3;VBY4DVC2;GC3SB1LZ;WG7291D7;ZV1P8B72',
      statusContent: 'dot',
      hash: '5KKB1261',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KKB1261'
    },
    {
      status: 210,
      sku: 'PM0010396',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353423',
      rtl_batch_array: '6353423',
      name_search: 'SETRUPRE',
      name: 'Sedum telephium ruprechtii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 486,
      chnn_stock_retail: 486,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z5DD7HHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5DD7HHA'
    },
    {
      status: 210,
      sku: 'PM0005842',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353475',
      rtl_batch_array: '6353475',
      name_search: 'STLTRAEU',
      name: "Stokesia laevis 'Tr\u00e4umerei'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HZJT8PS',
      statusContent: 'dot',
      hash: 'ZVKPH4C5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVKPH4C5'
    },
    {
      status: 210,
      sku: 'PM0073419',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5449474',
      rtl_batch_array: '5449474, 6304933',
      name_search: 'THODANIC',
      name: "Thuja occidentalis 'Danica'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2393,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8BKY3T6;XJHVS4RW;X1HJK86R;ALA6PH9S;RNK6445D;P4VJXZ64;CLH1AKYW;ND2ZDWH4;D2CKJAEB;P29431BK;CLPLYN3C;8XDHEV62;9RJ5XTBB;A1BBTE4T',
      imageBatch: 'YXVYR4HD',
      statusContent: 'dot',
      hash: 'Z64C7BAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z64C7BAY'
    },
    {
      status: 210,
      sku: 'PM0073421',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5449476',
      rtl_batch_array: '5449476, 6298833',
      name_search: 'THORHEIN',
      name: "Thuja occidentalis 'Rheingold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2396,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J9TAJP9E;YNXYJ8XZ;AS3WYCP5;T1XR11AY;N1ZTRDCB;AT66C11P;CWV558BC;VBGSR2HX;4VPNJC61;SCA5S3YH;LLVLRVNY;HE2STS63;2GSYA9H7;8Y2JLYNX;W66WGT36;DCK5A2XH',
      imageBatch: 'N1B9E7A2',
      statusContent: 'dot',
      hash: 'NG9Y3NLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG9Y3NLS'
    },
    {
      status: 210,
      sku: 'PM0080718',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338093',
      rtl_batch_array: '6338093',
      name_search: 'HISSPECI',
      name: "Hibiscus syriacus 'Speciosus'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX32RJHC;T3KHLPVL',
      statusContent: 'dot',
      hash: '5C13RV9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5C13RV9L'
    },
    {
      status: 210,
      sku: 'PM0073422',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449477',
      rtl_batch_array: '5449477',
      name_search: 'THOTEDDY',
      name: "Thuja occidentalis 'Teddy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEXVV2X1;8Y3N8K36;HZXTY6L5;LJZHXYWX;ATY1ATV7;E5VLN7X8',
      imageBatch: 'XBDZJS8J',
      statusContent: 'dot',
      hash: 'NW64NSRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW64NSRX'
    },
    {
      status: 210,
      sku: 'PM0080719',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338094',
      rtl_batch_array: '6338094',
      name_search: 'HISSPECI',
      name: "Hibiscus syriacus 'Speciosus'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX32RJHC;T3KHLPVL',
      statusContent: 'dot',
      hash: 'VHCXD2WK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHCXD2WK'
    },
    {
      status: 210,
      sku: 'PM0080720',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338095',
      rtl_batch_array: '6338095',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1014,
      chnn_stock_retail: 1014,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: 'EW8CWW7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW8CWW7Z'
    },
    {
      status: 210,
      sku: 'PM0080721',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338096',
      rtl_batch_array: '6338096',
      name_search: 'HIRSSTAR',
      name: 'Hippophae rhamnoides Silver Star',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6TRWZXWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TRWZXWP'
    },
    {
      status: 210,
      sku: 'PM0073427',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259551',
      rtl_batch_array: '6259551',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: 'G54929E8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G54929E8'
    },
    {
      status: 810,
      sku: 'PM0080722',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338097',
      rtl_batch_array: '6338097',
      name_search: 'HODISCOL',
      name: 'Holodiscus discolor',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HT45NWCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT45NWCR'
    },
    {
      status: 210,
      sku: 'PM0080723',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338098',
      rtl_batch_array: '6338098',
      name_search: 'HOCCHAME',
      name: "Houttuynia cordata 'Chameleon'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 423,
      chnn_stock_retail: 423,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '539PZ7V3;WD27TGX4;LACKG14L;6NCYBA2A;WD13G1Z9;HP5N45B6;6CV8RS3J;E8DRC52R;4XJE8H66;PPEGLSG4;8LTYP7E7;XY2D6LK7;KHEBGCST;21L2762S;4RWEPGG8;BGYK65KY;72DGWPY2;85DB9HNW',
      statusContent: 'dot',
      hash: '8191PPWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8191PPWP'
    },
    {
      status: 210,
      sku: 'PM0080724',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338099',
      rtl_batch_array: '6338099',
      name_search: 'HOCFLAME',
      name: "Houttuynia cordata 'Flame'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 607,
      chnn_stock_retail: 607,
      sppl_prcp: 1.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42PJXYV6',
      statusContent: 'dot',
      hash: 'TBB4X5K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBB4X5K5'
    },
    {
      status: 810,
      sku: 'PM0080725',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338100',
      rtl_batch_array: '6338100',
      name_search: 'HYAPSLIN',
      name: "Hydrangea anomala petiolaris 'Silver Lining' PBR",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8BNR6ECW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BNR6ECW'
    },
    {
      status: 210,
      sku: 'PM0080726',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338102',
      rtl_batch_array: '6338102',
      name_search: 'HYACARO',
      name: "Hypericum androsaemum 'Caro'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W8VC5LEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8VC5LEJ'
    },
    {
      status: 210,
      sku: 'PM0080727',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338103',
      rtl_batch_array: '6338103',
      name_search: 'HYAHSNOW',
      name: "Hydrangea arborescens 'Hills of Snow'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XEB2K7X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEB2K7X2'
    },
    {
      status: 210,
      sku: 'PM0080730',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338106',
      rtl_batch_array: '6338106',
      name_search: 'HYMAYESH',
      name: "Hydrangea macrophylla 'Ayesha'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 541,
      chnn_stock_retail: 541,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A6EXRAK7;4JBKAXR9;265X36VG;95B642HK;CRR6CCLK;5K7D55T9;RL44W8E9',
      statusContent: 'dot',
      hash: '981N7YLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '981N7YLN'
    },
    {
      status: 210,
      sku: 'PM0080731',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338107',
      rtl_batch_array: '6338107',
      name_search: 'HYMBODEN',
      name: "Hydrangea macrophylla 'Bodensee'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB81K7SZ;ZRDAT7B4;9TL5SAS2;CAKYVNEH;T2EHAA8J;6RT98EYD;KHD4XTSL;G454XKTX',
      statusContent: 'dot',
      hash: '9LWSKD65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LWSKD65'
    },
    {
      status: 210,
      sku: 'PM0080732',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338108',
      rtl_batch_array: '6338108',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: 'SY3SCT64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY3SCT64'
    },
    {
      status: 210,
      sku: 'PM0080733',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338109',
      rtl_batch_array: '6338109',
      name_search: 'HYMDEUTS',
      name: "Hydrangea macrophylla 'Deutschland'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2470,
      chnn_stock_retail: 2470,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CJ5T7T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CJ5T7T8'
    },
    {
      status: 210,
      sku: 'PM0080734',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338110',
      rtl_batch_array: '6338110',
      name_search: 'HYMLEUCH',
      name: "Hydrangea macrophylla 'Leuchtfeuer'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1941,
      chnn_stock_retail: 1941,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5N5ES2HA',
      statusContent: 'dot',
      hash: '5D263EP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5D263EP7'
    },
    {
      status: 210,
      sku: 'PM0080735',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338111',
      rtl_batch_array: '6338111',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 522,
      chnn_stock_retail: 522,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: 'BKCYBG73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKCYBG73'
    },
    {
      status: 210,
      sku: 'PM0025158',
      core_name: 'Plant',
      sppl_ean: '8720664803589',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084676',
      rtl_batch_array: '6084676',
      name_search: 'PRGBBLUE',
      name: "Prunella grandiflora 'Bella Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2078,
      chnn_stock_retail: 2078,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVGKRR9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVGKRR9Y'
    },
    {
      status: 210,
      sku: 'PM0080736',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338112',
      rtl_batch_array: '6338112',
      name_search: 'HYMMSANG',
      name: "Hydrangea macrophylla 'Merveille Sanguinea'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 411,
      chnn_stock_retail: 411,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JHKGRAR8;2Y5NDCGL;R28CB3Y9;P1K57H9C;VG9WBNL5;63WA4367;EJ839NPE;W6KK371B;PGN62YXH;5HB93812;PVTD8WH6;74A4NVGZ;HE5NZPWJ',
      statusContent: 'dot',
      hash: 'W36TYCWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W36TYCWT'
    },
    {
      status: 210,
      sku: 'PM0080737',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338113',
      rtl_batch_array: '6338113',
      name_search: 'HYMMESSA',
      name: "Hydrangea macrophylla 'Messalina'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 609,
      chnn_stock_retail: 609,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8B2G1YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8B2G1YR'
    },
    {
      status: 210,
      sku: 'PM0080738',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338115',
      rtl_batch_array: '6338115',
      name_search: 'HYMPIA',
      name: "Hydrangea macrophylla 'Pia'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 573,
      chnn_stock_retail: 573,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A65SRCXT;E8CTSJ1E;6CENRYDW',
      statusContent: 'dot',
      hash: 'ALLGKRHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALLGKRHS'
    },
    {
      status: 210,
      sku: 'PM0073424',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6197017',
      rtl_batch_array: '6197017',
      name_search: 'HEADALE',
      name: "Heuchera americana 'Dale'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1417,
      chnn_stock_retail: 1417,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZ1NPPEC;N2S5TRYT',
      statusContent: 'dot',
      hash: '19Z1RWR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19Z1RWR8'
    },
    {
      status: 210,
      sku: 'PM0011624',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353435',
      rtl_batch_array: '6353435',
      name_search: 'SENOIR',
      name: "Sempervivum 'Noir'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1274,
      chnn_stock_retail: 1274,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TD55PEGS;HCYK664N;LP96G214;DEY2HARJ;HD1ZN1JN;JS2Y5D6Z;9BH26K6R;G8B4WJGP;1D5TSPVK',
      statusContent: 'dot',
      hash: 'WAJ283DT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAJ283DT'
    },
    {
      status: 210,
      sku: 'PM0073246',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259166',
      rtl_batch_array: '6259166',
      name_search: 'THDELAVA',
      name: 'Thalictrum delavayi',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2257,
      chnn_stock_retail: 2257,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVLK6ZPC',
      statusContent: 'dot',
      hash: 'SKLC7RD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKLC7RD5'
    },
    {
      status: 210,
      sku: 'PM0072899',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257488',
      rtl_batch_array: '6257488',
      name_search: 'PIGPERFE',
      name: 'Picea glauca Perfecta',
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 549,
      chnn_stock_retail: 549,
      sppl_prcp: 5.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NLERT41',
      statusContent: 'dot',
      hash: '7RY59XZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RY59XZ9'
    },
    {
      status: 910,
      sku: 'PM0072900',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABCAPPE',
      name: "Taxus baccata 'Cappenberg'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      imageCore: '7725WD6K',
      statusContent: 'dot',
      hash: '5E14312W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5E14312W'
    },
    {
      status: 210,
      sku: 'PM0072901',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257492',
      rtl_batch_array: '6257492',
      name_search: 'TABDAVID',
      name: "Taxus baccata 'David'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 592,
      chnn_stock_retail: 592,
      sppl_prcp: 5.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JGV8D2V;61VZV35Y;K2SB5P8G',
      statusContent: 'dot',
      hash: 'EW96Z585',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW96Z585'
    },
    {
      status: 210,
      sku: 'PM0080739',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338116',
      rtl_batch_array: '6338116',
      name_search: 'HYMTBLAU',
      name: "Hydrangea m. 'Teller Blauw/Blue/Blau/Bleu'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 484,
      chnn_stock_retail: 484,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZHHWAKW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHHWAKW6'
    },
    {
      status: 210,
      sku: 'PM0072903',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257508',
      rtl_batch_array: '6257508',
      name_search: 'THOGLOBO',
      name: "Thuja occidentalis 'Globosa'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1591,
      chnn_stock_retail: 1591,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WVT4NVDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVT4NVDW'
    },
    {
      status: 810,
      sku: 'PM0073395',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307545',
      rtl_batch_array: '6307545',
      name_search: 'MAGHKERN',
      name: "Magnolia 'George Henry Kern'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDX3ZPEN;PDB2YP8N;E9GRPBCC',
      statusContent: 'dot',
      hash: 'ALNZ2258',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALNZ2258'
    },
    {
      status: 910,
      sku: 'PM0072904',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THOLGIAN',
      name: "Thuja occidentalis 'Little Giant'",
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      imageCore: 'LNGAX1LE',
      statusContent: 'dot',
      hash: 'RE3N18K5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RE3N18K5'
    },
    {
      status: 210,
      sku: 'PM0080740',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338117',
      rtl_batch_array: '6338117',
      name_search: 'HYMTROOD',
      name: "Hydrangea m. 'Teller Rood/Red.Rot/Rouge'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 799,
      chnn_stock_retail: 799,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X3GV7X6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3GV7X6P'
    },
    {
      status: 210,
      sku: 'PM0072997',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258066',
      rtl_batch_array: '6258066',
      name_search: 'CRJTTOES',
      name: "Cryptomeria japonica 'Twinkle Toes'",
      sppl_size_code: '015020C3',
      rng_range_identifier: 'H015020C3',
      rng_range_description: 'H15 cm 20 cm C3',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S164AZGT;XHXZL7WS;JHHAKAHS;8E45935V;C91G669Z',
      statusContent: 'dot',
      hash: 'DRY8TKTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRY8TKTK'
    },
    {
      status: 210,
      sku: 'PM0073240',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259148',
      rtl_batch_array: '6259148',
      name_search: 'BRMSHEAR',
      name: "Brunnera macrophylla 'Sea Heart'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 908,
      chnn_stock_retail: 908,
      sppl_order_minimum: 3,
      sppl_prcp: 1.974,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LYSL9PJH;S7KXG9JP',
      statusContent: 'dot',
      hash: 'V1E58LDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1E58LDW'
    },
    {
      status: 210,
      sku: 'PM0073241',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259149',
      rtl_batch_array: '6259149',
      name_search: 'CAMIDANC',
      name: "Carex morrowii 'Ice Dance'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 471,
      chnn_stock_retail: 471,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJATX4ET;4JNA7G88;5HR8P1HW;GHJR8N6R;WPHB9K4Y;RRVDPCLW;VWSWB3LV;83664SLH;TR3SVRWE;XRXHJ27Y;N3KLDNZP;XADSHG1C;HXJJ2RK6;GG1DNEXR;H54915AV;W55DXCZY;H867KG7J;CK5WN3KC;VCEPHVZ6;7DP9TXBE;ZV2B7EXP;D3YBDNW7;CAHNP547;7BA2BS8H;J1NZRXTZ;D92Y48Y2',
      statusContent: 'dot',
      hash: 'NYBY9Z71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYBY9Z71'
    },
    {
      status: 210,
      sku: 'PM0073242',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259154',
      rtl_batch_array: '6259154',
      name_search: 'GEOPINK',
      name: "Geranium 'Orkney Pink'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 570,
      chnn_stock_retail: 570,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C6L1NNH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6L1NNH2'
    },
    {
      status: 210,
      sku: 'PM0073239',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259147',
      rtl_batch_array: '6259147',
      name_search: 'ASMROSEN',
      name: "Astrantia major 'Rosensinfonie'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9214,
      chnn_stock_retail: 9214,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H8XWB1VY',
      statusContent: 'dot',
      hash: '7BB2KVSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BB2KVSY'
    },
    {
      status: 210,
      sku: 'PM0073243',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259157',
      rtl_batch_array: '6259157',
      name_search: 'LAMBSILV',
      name: "Lamium maculatum 'Beacon Silver'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1078,
      chnn_stock_retail: 1078,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5SDS5BAS;Y7VKCZ68;6JSP4ALE;JAEVB74X;Z5LGHT2D;7DZD226A;BX34SBL1;C7D8ECK1;ZXWWEYN2;A88BL2AS',
      statusContent: 'dot',
      hash: '78424P7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78424P7L'
    },
    {
      status: 210,
      sku: 'PM0073244',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259161',
      rtl_batch_array: '6259161',
      name_search: 'PEPOLYMO',
      name: 'Persicaria polymorpha',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 768,
      chnn_stock_retail: 768,
      sppl_order_minimum: 3,
      sppl_prcp: 1.754,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZG8EGRZ;NY1R58Z3;G64A2E7G;G4L67W6Z;VAY1Z2CY',
      statusContent: 'dot',
      hash: '192H75B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '192H75B3'
    },
    {
      status: 210,
      sku: 'PM0073245',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259165',
      rtl_batch_array: '6259165',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'AGXBHLYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGXBHLYV'
    },
    {
      status: 210,
      sku: 'PM0073410',
      core_name: 'Plant',
      sppl_ean: '8720353010250',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449311',
      rtl_batch_array: '5449311',
      name_search: 'CHOKAMAR',
      name: "Chamaecyparis obtusa 'Kamarachiba'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S48852HG;W8TCVX1L;VL52KT58',
      imageBatch: 'KP6T6CV7',
      statusContent: 'dot',
      hash: 'H5JADR9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5JADR9Z'
    },
    {
      status: 210,
      sku: 'PM0073412',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5449313',
      rtl_batch_array: '6298571, 5449313',
      name_search: 'CHPFNANA',
      name: "Chamaecyparis pisifera 'Filifera Nana'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2069,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6H1P49H2;63BTDXDG;69KRN8NB;2PJJVP62',
      imageBatch: '7AZT7ZPH',
      statusContent: 'dot',
      hash: 'YYWTYXGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYWTYXGJ'
    },
    {
      status: 210,
      sku: 'PM0073428',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259656',
      rtl_batch_array: '6259656, 6298801',
      name_search: 'CHPTBEAR',
      name: "Chamaecyparis pisifera 'Teddy Bear'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3349,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XTTASZ5Z;RCDX5EZN;WV3XZD7G;LVJRDT6S;1AB5C5HH;L6X5H255;5W2J737T;13L6EGEP;8K8A8J36;7XP14EVT',
      statusContent: 'dot',
      hash: 'VBY3S4KJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBY3S4KJ'
    },
    {
      status: 210,
      sku: 'PM0073429',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259657',
      rtl_batch_array: '6259657, 6304929',
      name_search: 'JUPNANA',
      name: "Juniperus procumbens 'Nana'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 990,
      chnn_stock_retail: 4370,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YPH14G7X;KRS7HGXY;V2B488EW;LA1AHTSX;G2D5PA79;DGYVNPD5;KB81N2R4;T1HLSSNG;1YXCNZW9;5B55H4XV;1JRJ3A43',
      statusContent: 'dot',
      hash: 'LJ6D1CA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ6D1CA6'
    },
    {
      status: 210,
      sku: 'PM0073420',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449475',
      rtl_batch_array: '5449475',
      name_search: 'THOGTUFF',
      name: "Thuja occidentalis 'Golden Tuffet'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6YT63PXP;NAPXD1VW;PA7B5RVB;7GBK7CKA;VGT853C5;N67PK32Y;HYBN8NHS;XCC3S29L;PHLH3AWC;9EV6ESKJ;R5DY2VP5;5LSX1SDB;4E9CTASH;SAVKBA9P;EA2653D8;LRS7D92P',
      imageBatch: '89E2PT57',
      statusContent: 'dot',
      hash: 'HE2JYNS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HE2JYNS5'
    },
    {
      status: 810,
      sku: 'PM0052173',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242085',
      rtl_batch_array: '6242085',
      name_search: 'AGCOLUMB',
      name: "Agapanthus 'Columba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4J5J7GW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J5J7GW4'
    },
    {
      status: 210,
      sku: 'PM0073403',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5342778',
      rtl_batch_array: '5342778',
      name_search: 'RHOGOLDF',
      name: "Rhododendron 'Goldflimmer'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AP9T9Z94;X62VZAYY;WLPS9ZE6;1RRVGYRD;C83EB4P2;K4RN8N3C;KAJNPB6X;PCLYKTVR;EKTTWTEN;DWG44HZR;TAEVRYLX;9AZD19KD;4XCCE1N3',
      imageBatch: 'R33NS2DA',
      statusContent: 'dot',
      hash: 'JAAEJSTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAAEJSTX'
    },
    {
      status: 210,
      sku: 'PM0018370',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 8,
      btch_active_retail: '6382282',
      rtl_batch_array:
        '6034372, 6037545, 5539741, 6176253, 6382282, 6385475, 6356249, 6295930',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 16900,
      chnn_stock_retail: 130484,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: 'KCZ1EBPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCZ1EBPD'
    },
    {
      status: 810,
      sku: 'PM0015868',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5880629',
      rtl_batch_array: '5880629',
      name_search: 'THPMARTI',
      name: "Thuja plicata 'Martin'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 2.306,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '79Z6E1YL;8SK67LT3;EDKT291B;PZEXK6X4;1WBSWA7D',
      imageBatch: 'CAR5STL7',
      statusContent: 'dot',
      hash: 'HL9C6565',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HL9C6565'
    },
    {
      status: 810,
      sku: 'PM0073462',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6281205',
      rtl_batch_array: '6281205, 6287286',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 65,
      sppl_prcp: 40.035,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'DZ16E7ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ16E7ZA'
    },
    {
      status: 210,
      sku: 'PM0073252',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348290',
      rtl_batch_array: '6259401, 6348290',
      name_search: 'ASMSHAGG',
      name: "Astrantia major 'Shaggy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2327,
      chnn_stock_retail: 3249,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '98ACW7WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98ACW7WV'
    },
    {
      status: 210,
      sku: 'PM0082972',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356060',
      rtl_batch_array: '6356060',
      name_search: 'JUCCOMPR',
      name: "Juniperus communis 'Compressa'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1446,
      chnn_stock_retail: 1446,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WX1GRV22;H2G3GS33',
      statusContent: 'dot',
      hash: 'CE6L24V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE6L24V5'
    },
    {
      status: 810,
      sku: 'PM0073253',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259403',
      rtl_batch_array: '6259403',
      name_search: 'BLSALBOS',
      name: "Bletilla striata 'Albostriata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TTBEBS13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTBEBS13'
    },
    {
      status: 210,
      sku: 'PM0073256',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349964',
      rtl_batch_array: '6349964',
      name_search: 'CRCOLUMB',
      name: "Crocosmia 'Columbus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '58HDX8GW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58HDX8GW'
    },
    {
      status: 210,
      sku: 'PM0024059',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353498',
      rtl_batch_array: '6353498',
      name_search: 'VISRCLOU',
      name: "Viola sororia 'Red Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 398,
      chnn_stock_retail: 398,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2WK26LJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WK26LJ1'
    },
    {
      status: 810,
      sku: 'PM0073258',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350015',
      rtl_batch_array: '6350015',
      name_search: 'DYTAKESI',
      name: 'Dystaenia takesimana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E3YNT8WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3YNT8WB'
    },
    {
      status: 210,
      sku: 'PM0072857',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255887',
      rtl_batch_array: '6255887',
      name_search: 'GELABLOS',
      name: "Geranium lindavicum 'Apple Blossom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J878K428',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J878K428'
    },
    {
      status: 810,
      sku: 'PM0082973',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356072',
      rtl_batch_array: '6356072',
      name_search: 'AEPARVIF',
      name: 'Aesculus parviflora',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 91,
      chnn_stock_retail: 91,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JP1C6VDT;9BVDB817;V6DNPEK1;C3Z5TRCS',
      statusContent: 'dot',
      hash: 'J6DTRSP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6DTRSP9'
    },
    {
      status: 210,
      sku: 'PM0017928',
      core_name: 'Plant',
      sppl_ean: '8720664886582',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6080769',
      rtl_batch_array: '6080769',
      name_search: 'SOSSCASC',
      name: "Solidago shortii 'Solar Cascade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WKELRYV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKELRYV3'
    },
    {
      status: 210,
      sku: 'PM0012044',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353562',
      rtl_batch_array: '6353562',
      name_search: 'VEPORPHY',
      name: 'Veronica porphyriana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDSR3385',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDSR3385'
    },
    {
      status: 210,
      sku: 'PM0080741',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338118',
      rtl_batch_array: '6338118',
      name_search: 'HYMTWIT',
      name: "Hydrangea m. 'Teller Wit/White/Weiss/Blanc'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 735,
      chnn_stock_retail: 735,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZGH28GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZGH28GE'
    },
    {
      status: 210,
      sku: 'PM0080742',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338119',
      rtl_batch_array: '6338119',
      name_search: 'HYMTRICO',
      name: "Hydrangea macrophylla 'Tricolor'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 510,
      chnn_stock_retail: 510,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HVZWRD6',
      statusContent: 'dot',
      hash: '7GNXL9VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GNXL9VB'
    },
    {
      status: 810,
      sku: 'PM0080743',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338120',
      rtl_batch_array: '6338120',
      name_search: 'HYPBLACE',
      name: "Hydrangea paniculata 'Brussels Lace'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NEZST4XA;5GC1A65G',
      statusContent: 'dot',
      hash: '17XY8AC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17XY8AC6'
    },
    {
      status: 810,
      sku: 'PM0052184',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217008',
      rtl_batch_array: '6217008',
      name_search: 'BAPCFORM',
      name: "Ballota pseudodictamnus 'Compact Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KJHTRERW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJHTRERW'
    },
    {
      status: 810,
      sku: 'PM0080744',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338121',
      rtl_batch_array: '6338121',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ACWL2NWY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACWL2NWY'
    },
    {
      status: 210,
      sku: 'PM0080745',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338122',
      rtl_batch_array: '6338122',
      name_search: 'HYPDHARU',
      name: "Hydrangea paniculata 'Dharuma'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 937,
      chnn_stock_retail: 937,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9KB66888',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KB66888'
    },
    {
      status: 210,
      sku: 'PM0082974',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356073',
      rtl_batch_array: '6356073',
      name_search: 'AEPARVIF',
      name: 'Aesculus parviflora',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 10.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JP1C6VDT;9BVDB817;V6DNPEK1;C3Z5TRCS',
      statusContent: 'dot',
      hash: 'X5VHDRGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5VHDRGL'
    },
    {
      status: 210,
      sku: 'PM0080746',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338124',
      rtl_batch_array: '6338124',
      name_search: 'HYPFMELB',
      name: 'Hydrangea paniculata Fraise Melba',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YK82VZ79;84AXR66W',
      statusContent: 'dot',
      hash: '4ZX4KZYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZX4KZYY'
    },
    {
      status: 210,
      sku: 'PM0080747',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338126',
      rtl_batch_array: '6338126',
      name_search: 'HYPLEVAN',
      name: "Hydrangea paniculata 'Levana'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 324,
      chnn_stock_retail: 324,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K9GJPW8N;RZ2VHDL9;HLPSPXHP;ZNLYCDDW;4LY6ESA6;GTDCSA4G;S1BG6RD8;2R3ZPC19;2Z9XWKZ4',
      statusContent: 'dot',
      hash: 'PPXSR1GJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPXSR1GJ'
    },
    {
      status: 210,
      sku: 'PM0024889',
      core_name: 'Plant',
      sppl_ean: '8720349455997',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423429',
      rtl_batch_array: '5423429',
      name_search: 'RHSCANDE',
      name: 'Rhodotypos scandens',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 4.687,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P5KXESB3',
      statusContent: 'dot',
      hash: 'E4S7358T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4S7358T'
    },
    {
      status: 210,
      sku: 'PM0080748',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338128',
      rtl_batch_array: '6338128',
      name_search: 'HYPLSPOO',
      name: 'Hydrangea paniculata Little Spooky',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYVL3TAR;96HC75LG;J1CYKELX;6C5G3SPX;Z3HVWB3X;ZAG3GRCD;DEG14R1N',
      statusContent: 'dot',
      hash: 'H73VTP38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H73VTP38'
    },
    {
      status: 210,
      sku: 'PM0052191',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268725',
      rtl_batch_array: '6268725',
      name_search: 'DESUNGLE',
      name: "Delphinium 'Sungleam'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HVYE5ND7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVYE5ND7'
    },
    {
      status: 210,
      sku: 'PM0080749',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338129',
      rtl_batch_array: '6338129',
      name_search: 'HYPPASTE',
      name: 'Hydrangea paniculata Pastelgreen',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1003,
      chnn_stock_retail: 1003,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELHKVC59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELHKVC59'
    },
    {
      status: 210,
      sku: 'PM0080750',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338130',
      rtl_batch_array: '6338130',
      name_search: 'HYPPSTAR',
      name: 'Hydrangea pan. Petite Star',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 748,
      chnn_stock_retail: 748,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7VSVWCW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VSVWCW2'
    },
    {
      status: 210,
      sku: 'PM0080751',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338132',
      rtl_batch_array: '6338132',
      name_search: 'HYPSKYFA',
      name: 'Hydrangea paniculata Skyfall',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2141,
      chnn_stock_retail: 2141,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB65XZ1A;576ZXYVH;L2LSYTCA;N7234DKB;8LX9HJ78;NRSLWCTS',
      statusContent: 'dot',
      hash: 'PXLKWVP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXLKWVP9'
    },
    {
      status: 210,
      sku: 'PM0080752',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338133',
      rtl_batch_array: '6338133',
      name_search: 'HYPSFRAI',
      name: 'Hydrangea paniculata Sundae Fraise',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 386,
      chnn_stock_retail: 386,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JE1RX5H2;TVJKSH5R;LBKPNDD1;ALZHHNXR;YCCVESHH;142DDV9G;87AGDKC1;NH16J9WA;1YJWB1NJ;KE8BAA3N;9HBXW791;HRNKJRGK;HNWVSRL9;EA7XZXYC;AH96E5GW;1X2TCPAL;8R2Z9NZA;PRLB2BNY',
      statusContent: 'dot',
      hash: 'JYLL67KJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYLL67KJ'
    },
    {
      status: 910,
      sku: 'PM0082975',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H125150RB',
      rng_range_description: 'H125 cm 150 cm Root ball',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '9NWR9ACH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9NWR9ACH'
    },
    {
      status: 210,
      sku: 'PM0080753',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338134',
      rtl_batch_array: '6338134',
      name_search: 'HYPTARDI',
      name: "Hydrangea paniculata 'Tardiva'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1964,
      chnn_stock_retail: 1964,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R99DD2DK;A78RXRE7;E597Y3X1;W68EKPXR',
      statusContent: 'dot',
      hash: 'B2YB9TWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2YB9TWV'
    },
    {
      status: 210,
      sku: 'PM0080754',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338136',
      rtl_batch_array: '6338136',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 221,
      chnn_stock_retail: 221,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: '47JPYHDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47JPYHDS'
    },
    {
      status: 210,
      sku: 'PM0082976',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356076',
      rtl_batch_array: '6356076',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '150160KL',
      rng_range_identifier: 'H150160RB',
      rng_range_description: 'H150 cm 160 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 25.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'NCSN9BKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCSN9BKZ'
    },
    {
      status: 210,
      sku: 'PM0080755',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338137',
      rtl_batch_array: '6338137',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 366,
      chnn_stock_retail: 366,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '6E3WZGGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6E3WZGGR'
    },
    {
      status: 210,
      sku: 'PM0080756',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338138',
      rtl_batch_array: '6338138',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2420,
      chnn_stock_retail: 2420,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'HAHG534J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAHG534J'
    },
    {
      status: 810,
      sku: 'PM0073259',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350127',
      rtl_batch_array: '6350127',
      name_search: 'EPPALUST',
      name: 'Epipactis palustris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_order_minimum: 3,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AG82GE5Z;BRWHHJGY',
      statusContent: 'dot',
      hash: '5N1VTP7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N1VTP7K'
    },
    {
      status: 210,
      sku: 'PM0080757',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338139',
      rtl_batch_array: '6338139',
      name_search: 'HYQALICE',
      name: "Hydrangea quercifolia 'Alice'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1329,
      chnn_stock_retail: 1329,
      sppl_order_minimum: 3,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9766V5DT;XJ1NN636',
      statusContent: 'dot',
      hash: '51LT62WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51LT62WY'
    },
    {
      status: 210,
      sku: 'PM0080758',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338140',
      rtl_batch_array: '6338140',
      name_search: 'HYQICRYS',
      name: 'Hydrangea quercifolia Ice Crystal',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_prcp: 3.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B3SYX4ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3SYX4ZY'
    },
    {
      status: 210,
      sku: 'PM0072905',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257514',
      rtl_batch_array: '6257514',
      name_search: 'TSCJEDDE',
      name: "Tsuga canadensis 'Jeddeloh'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99B8JVWB;9K4Z69BJ;523X2GZ7;PA6ZXZCA;5WV7PV5N;T1H2VVWZ;CCVRV972;G45214ST;Y8VBN2E7;BL2JXSAG',
      statusContent: 'dot',
      hash: '76BV56H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '76BV56H5'
    },
    {
      status: 210,
      sku: 'PM0072906',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257515',
      rtl_batch_array: '6257515',
      name_search: 'TSCNANA',
      name: "Tsuga canadensis 'Nana'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V59C6VZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V59C6VZR'
    },
    {
      status: 210,
      sku: 'PM0080759',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338141',
      rtl_batch_array: '6338141',
      name_search: 'HYQLHONE',
      name: "Hydrangea quercifolia 'Little Honey'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 3,
      sppl_prcp: 3.386,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'REPE5GS9',
      statusContent: 'dot',
      hash: 'PAX17X2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAX17X2G'
    },
    {
      status: 210,
      sku: 'PM0080760',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338142',
      rtl_batch_array: '6338142',
      name_search: 'HYQQHEAR',
      name: "Hydrangea quercifolia 'Queen of Hearts'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_prcp: 3.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WNHE2R7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNHE2R7T'
    },
    {
      status: 210,
      sku: 'PM0080761',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338143',
      rtl_batch_array: '6338143',
      name_search: 'HYQSDWAR',
      name: "Hydrangea quercifolia 'Sike's Dwarf'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 223,
      chnn_stock_retail: 223,
      sppl_order_minimum: 3,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TBE7YK3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBE7YK3N'
    },
    {
      status: 210,
      sku: 'PM0080762',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338144',
      rtl_batch_array: '6338144',
      name_search: 'HYSEMIOL',
      name: 'Hydrangea Semiola',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 411,
      chnn_stock_retail: 411,
      sppl_prcp: 3.064,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9PTSH5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9PTSH5A'
    },
    {
      status: 210,
      sku: 'PM0080763',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338145',
      rtl_batch_array: '6338145',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1411,
      chnn_stock_retail: 1411,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: 'NABYVRSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NABYVRSW'
    },
    {
      status: 210,
      sku: 'PM0082977',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356077',
      rtl_batch_array: '6356077',
      name_search: 'BEBNANA',
      name: "Berberis buxifolia 'Nana'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PJD81N14;E6V6967J;CSGRAWSN;AT121S8Y;NDRYT777;GZDXB3SZ;ZWSG7DHD;V73L74ZR;J7B6JR59;S2VS3BDZ;TYA97B35;5TW613EK;TBB9Z9RG;ESETGZ11',
      statusContent: 'dot',
      hash: 'VNVYG9Y5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNVYG9Y5'
    },
    {
      status: 210,
      sku: 'PM0082978',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356078',
      rtl_batch_array: '6356078',
      name_search: 'BEFAMSTE',
      name: "Berberis frikartii 'Amstelveen'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1999,
      chnn_stock_retail: 1999,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JVCE7W73;BARL9DLP;2C6THZJ3;KXX6N1W8;W4SN9DGE;XRHLP77H;DDWYYYYJ;56RJAD5S;E1BWJYG5;82WAN6X6;NSJS7PB7',
      statusContent: 'dot',
      hash: 'WKL2WAVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKL2WAVL'
    },
    {
      status: 210,
      sku: 'PM0082979',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356079',
      rtl_batch_array: '6356079',
      name_search: 'BEFTELST',
      name: "Berberis frikartii 'Telstar'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A5CHG9BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5CHG9BK'
    },
    {
      status: 210,
      sku: 'PM0082980',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356080',
      rtl_batch_array: '6356080',
      name_search: 'BEMRJEWE',
      name: "Berberis media 'Red Jewel'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8WAGC5LT;1WVY1XRX;WL69ZE7J;DZB73H4D;KAYVB6TH',
      statusContent: 'dot',
      hash: 'VCD9DEZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCD9DEZL'
    },
    {
      status: 210,
      sku: 'PM0082981',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356081',
      rtl_batch_array: '6356081',
      name_search: 'BEOSUPER',
      name: "Berberis ottawensis 'Superba'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 294,
      chnn_stock_retail: 294,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5YABPC46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YABPC46'
    },
    {
      status: 210,
      sku: 'PM0073251',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259394',
      rtl_batch_array: '6259394',
      name_search: 'ASAANTJE',
      name: "Aster alpinus 'Antje'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C6Y2Y9T8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6Y2Y9T8'
    },
    {
      status: 210,
      sku: 'PM0073254',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349944',
      rtl_batch_array: '6349944',
      name_search: 'CIRALPIN',
      name: 'Circaea alpina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KWJ4AZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KWJ4AZK'
    },
    {
      status: 210,
      sku: 'PM0082982',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356082',
      rtl_batch_array: '6356082',
      name_search: 'BEOSUPER',
      name: "Berberis ottawensis 'Superba'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J9TNPWAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9TNPWAT'
    },
    {
      status: 210,
      sku: 'PM0073257',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349975',
      rtl_batch_array: '6349975',
      name_search: 'CRYEMBER',
      name: "Crocosmia 'Yellow Emberglow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KDHSSSL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDHSSSL1'
    },
    {
      status: 810,
      sku: 'PM0052214',
      core_name: 'Plant',
      sppl_ean: '8720626307537',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6034361',
      rtl_batch_array: '6034361',
      name_search: 'IRTRULY',
      name: "Iris (P) 'Truly'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7BGHY93Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BGHY93Z'
    },
    {
      status: 210,
      sku: 'PM0082983',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356083',
      rtl_batch_array: '6356083',
      name_search: 'BESTENOP',
      name: 'Berberis stenophylla',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '434ECVH8;VETBE9S3;T2NJ6SNN',
      statusContent: 'dot',
      hash: '1A4SX72W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1A4SX72W'
    },
    {
      status: 210,
      sku: 'PM0082984',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356084',
      rtl_batch_array: '6356084',
      name_search: 'BESTENOP',
      name: 'Berberis stenophylla',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '434ECVH8;VETBE9S3;T2NJ6SNN',
      statusContent: 'dot',
      hash: '7ZATGLNA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZATGLNA'
    },
    {
      status: 210,
      sku: 'PM0082985',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356085',
      rtl_batch_array: '6356085',
      name_search: 'BETGCARP',
      name: "Berberis thunbergii 'Green Carpet'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SNWWRAKZ;YD196LHY;E2DS8XYP;92KV2V3Y;1TWS9VZ5;AL7ZSVGW;RRP2GJTN;CEHD2ZR6;7B62XH6R;TREST3EE',
      statusContent: 'dot',
      hash: 'GSV3KXA5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSV3KXA5'
    },
    {
      status: 210,
      sku: 'PM0082986',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356086',
      rtl_batch_array: '6356086',
      name_search: 'BETHARLE',
      name: "Berberis thunbergii 'Harlequin'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D98GK36V;TBE54L4S;8RXW31Y2;S2KYHWWS;7GXXZNXV;RDJNNXWG;W1W5RAKN;6GKYN979;6Z9NNZLH;ECLC9BW8;294W3TDA;ER8S7H4K',
      statusContent: 'dot',
      hash: 'WXB3EXD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXB3EXD1'
    },
    {
      status: 210,
      sku: 'PM0082987',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356087',
      rtl_batch_array: '6356087',
      name_search: 'BETRGLOW',
      name: "Berberis thunbergii 'Rose Glow'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYH6719R;1P7TX883;PTDT1JHX;V2X3XNZX;JLXKR2W2;W8JJJ7GR;AJYZXYPK;HJ784XEH;ZVXYN72R',
      statusContent: 'dot',
      hash: 'CJTKEZ5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJTKEZ5N'
    },
    {
      status: 210,
      sku: 'PM0082988',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356088',
      rtl_batch_array: '6356088',
      name_search: 'BETSOMER',
      name: "Berberis thunbergii 'Somerset'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7W1R877X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W1R877X'
    },
    {
      status: 210,
      sku: 'PM0082989',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356089',
      rtl_batch_array: '6356089',
      name_search: 'BEVERRUC',
      name: 'Berberis verruculosa',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9SLGS6VA;7ACH56LP;S6DEVWNH;K6AAAGX1;TD1H5G5E;P6EVDBJ2;B5ER52PD',
      statusContent: 'dot',
      hash: 'SZCC6N8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZCC6N8N'
    },
    {
      status: 210,
      sku: 'PM0082990',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356091',
      rtl_batch_array: '6356091',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1JYT8ZAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JYT8ZAH'
    },
    {
      status: 810,
      sku: 'PM0014839',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301905',
      rtl_batch_array: '6301905',
      name_search: 'VESIBIRI',
      name: 'Veronicastrum sibiricum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SRSX3ZGY',
      statusContent: 'dot',
      hash: 'TV8XGL6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TV8XGL6A'
    },
    {
      status: 210,
      sku: 'PM0019191',
      core_name: 'Plant',
      sppl_ean: '8720626355910',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5582422',
      rtl_batch_array: '5582422, 6350604, 6338943',
      name_search: 'KNIQUEEN',
      name: "Kniphofia 'Ice Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 219,
      chnn_stock_retail: 1539,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2TSE47H;6SV3ARB5;G4J3ANWN;ZECN9S4H;ZCVJPYAV',
      statusContent: 'dot',
      hash: '8L2SNWB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8L2SNWB3'
    },
    {
      status: 210,
      sku: 'PM0072999',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258641',
      rtl_batch_array: '6258641',
      name_search: 'PIMMOPS',
      name: "Pinus mugo 'Mops'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 974,
      chnn_stock_retail: 974,
      sppl_prcp: 19.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTWG8NA3;HKC34X1D;L8L8Y9VS;S7L7X86R;NBJ4EZ6V;9YHXJY9D;JACEKARS;H8CNE9Y6;A5BG3BR5',
      statusContent: 'dot',
      hash: '2WHG271L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WHG271L'
    },
    {
      status: 810,
      sku: 'PM0075422',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280857',
      rtl_batch_array: '6280857',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: '1ANV6R27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ANV6R27'
    },
    {
      status: 210,
      sku: 'PM0080787',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338169',
      rtl_batch_array: '6338169',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1573,
      chnn_stock_retail: 1573,
      sppl_order_minimum: 5,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KSV2KYWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSV2KYWP'
    },
    {
      status: 210,
      sku: 'PM0052241',
      core_name: 'Plant',
      sppl_ean: '8720626360471',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6034948',
      rtl_batch_array: '6260123, 6034948, 6268805, 6164339',
      name_search: 'OMVALBA',
      name: "Omphalodes verna 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 4051,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PZLWRT1;APZXS1NW;5ZG127Z7;GNW3XB4Z',
      statusContent: 'dot',
      hash: 'PAL46HY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAL46HY3'
    },
    {
      status: 810,
      sku: 'PM0075439',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280876',
      rtl_batch_array: '6280876',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.817,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'EWXRS926',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWXRS926'
    },
    {
      status: 810,
      sku: 'PM0075437',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280874',
      rtl_batch_array: '6280874',
      name_search: 'ACSAUREU',
      name: "Acer shirasawanum 'Aureum'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.382,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T674X59V;YZYWEY44;458EWB6E;NYRBA675;Z23SB7RR;CPW35J2S;EHDC7N9E;ZL8XPW5T;BE87T1RV;GBWBW947;T79C2SD6;B9B3TYA7;A17BHVR6;44CVPRZ6;5RJLDWKZ;JBWGNRWG;GH76VT4H',
      statusContent: 'dot',
      hash: '6G4CHXG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6G4CHXG4'
    },
    {
      status: 810,
      sku: 'PM0075436',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280873',
      rtl_batch_array: '6280873',
      name_search: 'ACSAUREU',
      name: "Acer shirasawanum 'Aureum'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T674X59V;YZYWEY44;458EWB6E;NYRBA675;Z23SB7RR;CPW35J2S;EHDC7N9E;ZL8XPW5T;BE87T1RV;GBWBW947;T79C2SD6;B9B3TYA7;A17BHVR6;44CVPRZ6;5RJLDWKZ;JBWGNRWG;GH76VT4H',
      statusContent: 'dot',
      hash: '4KBDCN5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KBDCN5B'
    },
    {
      status: 810,
      sku: 'PM0075435',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280872',
      rtl_batch_array: '6280872',
      name_search: 'ACSAUREU',
      name: "Acer shirasawanum 'Aureum'",
      sppl_size_code: '020025C5',
      rng_range_identifier: 'H020025C5',
      rng_range_description: 'H20 cm 25 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T674X59V;YZYWEY44;458EWB6E;NYRBA675;Z23SB7RR;CPW35J2S;EHDC7N9E;ZL8XPW5T;BE87T1RV;GBWBW947;T79C2SD6;B9B3TYA7;A17BHVR6;44CVPRZ6;5RJLDWKZ;JBWGNRWG;GH76VT4H',
      statusContent: 'dot',
      hash: 'S8ZXEXWC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8ZXEXWC'
    },
    {
      status: 210,
      sku: 'PM0082991',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356098',
      rtl_batch_array: '6356098',
      name_search: 'BUWBALL',
      name: "Buddleja 'White Ball'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B1573HE;YH81KX32;5TL2J7GY;9RKLX3GN',
      statusContent: 'dot',
      hash: 'G1DR17X5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1DR17X5'
    },
    {
      status: 210,
      sku: 'PM0073268',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350172',
      rtl_batch_array: '6350172',
      name_search: 'FRSONCHI',
      name: 'Francoa sonchifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SEY3K3ZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEY3K3ZG'
    },
    {
      status: 210,
      sku: 'PM0073266',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350248',
      rtl_batch_array: '6238802, 6350248',
      name_search: 'GEKHAN',
      name: "Geranium 'Khan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 718,
      chnn_stock_retail: 938,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8DBLCTYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DBLCTYL'
    },
    {
      status: 210,
      sku: 'PM0073002',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258644',
      rtl_batch_array: '6258644',
      name_search: 'PIPARVIF',
      name: 'Pinus parviflora',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 20.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G3K351HN',
      statusContent: 'dot',
      hash: 'VLLV7WHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLLV7WHY'
    },
    {
      status: 210,
      sku: 'PM0073003',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258645',
      rtl_batch_array: '6258645',
      name_search: 'PISCHWER',
      name: 'Pinus schwerinii',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 997,
      chnn_stock_retail: 997,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NWEY6ZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NWEY6ZL'
    },
    {
      status: 210,
      sku: 'PM0073262',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350212',
      rtl_batch_array: '6350212',
      name_search: 'GEMALBUM',
      name: "Geranium macrorrhizum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HJY2CXA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJY2CXA6'
    },
    {
      status: 210,
      sku: 'PM0073263',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350223',
      rtl_batch_array: '6350223',
      name_search: 'GENSIMON',
      name: "Geranium nodosum 'Simon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 456,
      chnn_stock_retail: 456,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VY384BC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY384BC2'
    },
    {
      status: 210,
      sku: 'PM0073005',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258647',
      rtl_batch_array: '6258647',
      name_search: 'PISBMINI',
      name: "Pinus strobus 'Bergman's Mini'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 21.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TXWRZ3YD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXWRZ3YD'
    },
    {
      status: 210,
      sku: 'PM0073006',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258648',
      rtl_batch_array: '6258648',
      name_search: 'PISMINIM',
      name: "Pinus strobus 'Minima'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 21.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VDBE92E8;ANSY216Y;DLPN6BKK;K9W67RVK;LPV6W5XC;6K9LV8SG',
      statusContent: 'dot',
      hash: 'Z9J8W3HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9J8W3HA'
    },
    {
      status: 210,
      sku: 'PM0080788',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338171',
      rtl_batch_array: '6338171',
      name_search: 'ILCGROCK',
      name: "Ilex crenata 'Golden Rock'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 555,
      chnn_stock_retail: 555,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9XB7HHT',
      statusContent: 'dot',
      hash: 'PG3DCZJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PG3DCZJT'
    },
    {
      status: 210,
      sku: 'PM0073546',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260122',
      rtl_batch_array: '6260122',
      name_search: 'NEGLETSC',
      name: "Nepeta 'Gletschereis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CL1WPG9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CL1WPG9G'
    },
    {
      status: 210,
      sku: 'PM0073008',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258650',
      rtl_batch_array: '6258650',
      name_search: 'PISSECRE',
      name: "Pinus strobus 'Secrest'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 21.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8GYTAJSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GYTAJSD'
    },
    {
      status: 210,
      sku: 'PM0073009',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258651',
      rtl_batch_array: '6258651',
      name_search: 'PISTKURL',
      name: "Pinus strobus 'Tiny Kurls'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RAW1Z7TG;Y4RS5266;YXYDGH2Y;EY94LTPG;1E5YXS9C;4S2YA78N;LK35AYPB;GNRL6NL6;DD3LY8G2;J4KVB455;41S1JVKK',
      statusContent: 'dot',
      hash: 'RS5LREG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS5LREG5'
    },
    {
      status: 210,
      sku: 'PM0073010',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258652',
      rtl_batch_array: '6258652',
      name_search: 'PISCBLUE',
      name: "Pinus sylvestris 'Chantry Blue'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YN57ZPBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN57ZPBL'
    },
    {
      status: 210,
      sku: 'PM0073011',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258653',
      rtl_batch_array: '6258653',
      name_search: 'PITSAYON',
      name: "Pinus thunbergii 'Sayonara'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8LV7S3XD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LV7S3XD'
    },
    {
      status: 210,
      sku: 'PM0080764',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338146',
      rtl_batch_array: '6338146',
      name_search: 'HYSKOREA',
      name: 'Hydrangea serrata koreana',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1352,
      chnn_stock_retail: 1352,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '45N4W93T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45N4W93T'
    },
    {
      status: 210,
      sku: 'PM0073013',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258655',
      rtl_batch_array: '6258655',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 905,
      chnn_stock_retail: 905,
      sppl_prcp: 5.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'RSNVE2J4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSNVE2J4'
    },
    {
      status: 810,
      sku: 'PM0073014',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298831',
      rtl_batch_array: '6298831',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '045COBOL',
      rng_range_identifier: 'BALL\u00d8045H045045',
      rng_range_description: 'Ball \u00d8 45 cm H45 cm 45 cm',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 39.476,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '78KHZK51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78KHZK51'
    },
    {
      status: 210,
      sku: 'PM0080765',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338147',
      rtl_batch_array: '6338147',
      name_search: 'HYVILLOS',
      name: 'Hydrangea villosa',
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 759,
      chnn_stock_retail: 759,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8BCPEGWK',
      statusContent: 'dot',
      hash: '7G796CS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7G796CS7'
    },
    {
      status: 210,
      sku: 'PM0073007',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258649',
      rtl_batch_array: '6258649',
      name_search: 'PISRADIA',
      name: "Pinus strobus 'Radiata'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 21.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LNYX6TTL;5ZBVSA38',
      statusContent: 'dot',
      hash: 'XH56VVTJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XH56VVTJ'
    },
    {
      status: 210,
      sku: 'PM0080766',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338148',
      rtl_batch_array: '6338148',
      name_search: 'HYAVLACE',
      name: "Hydrangea aspera 'Velvet Lace'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 619,
      chnn_stock_retail: 619,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYYX41RD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYYX41RD'
    },
    {
      status: 210,
      sku: 'PM0080767',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338149',
      rtl_batch_array: '6338149',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_prcp: 1.774,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'XRYS6GER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRYS6GER'
    },
    {
      status: 210,
      sku: 'PM0080768',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338150',
      rtl_batch_array: '6338150',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 3653,
      chnn_stock_retail: 3653,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'ZNXSHZSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNXSHZSB'
    },
    {
      status: 210,
      sku: 'PM0073265',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350237',
      rtl_batch_array: '6350237',
      name_search: 'GEPBFLAI',
      name: "Geranium psilostemon 'Bressingham Flair'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X59ANV9L;K8TGDVBE;6SV7G7GD;KBLYZ3RN;N59VX7HG;2GH5SA49;95KDH2KT',
      statusContent: 'dot',
      hash: 'PED72S4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PED72S4J'
    },
    {
      status: 810,
      sku: 'PM0073267',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350250',
      rtl_batch_array: '6350250',
      name_search: 'GESTARMA',
      name: "Geranium 'Starman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1L5L2C4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L5L2C4B'
    },
    {
      status: 210,
      sku: 'PM0073269',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350343',
      rtl_batch_array: '6350343',
      name_search: 'HEBCIRCL',
      name: "Hemerocallis 'Brilliant Circle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GYZ75LYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYZ75LYS'
    },
    {
      status: 210,
      sku: 'PM0080769',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338151',
      rtl_batch_array: '6338151',
      name_search: 'HYMATTRA',
      name: 'Hypericum Miracle Attraction',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1360,
      chnn_stock_retail: 1360,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ER79A9V2;JH6T4ZRT;4DG46GKS;XVGRK34R;AZW4VLNP;DRVTE8LZ',
      statusContent: 'dot',
      hash: 'PT772YBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PT772YBC'
    },
    {
      status: 210,
      sku: 'PM0080770',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338152',
      rtl_batch_array: '6338152',
      name_search: 'HYMBLIZZ',
      name: 'Hypericum Miracle Blizz',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1540,
      chnn_stock_retail: 1540,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDDEBKS8;J2E353SS;L53YY4AR;VX6K1611;SJPZECLX;9W6SC131;E5PSW1YW',
      statusContent: 'dot',
      hash: 'S6STN49R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6STN49R'
    },
    {
      status: 210,
      sku: 'PM0080771',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338153',
      rtl_batch_array: '6338153',
      name_search: 'HYMBLOSS',
      name: 'Hypericum Miracle Blossom',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1690,
      chnn_stock_retail: 1690,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDNVKY8S;PRW4CTHT;LG6L1SLP;WZNZ52JD;JXCGHH2G;J1GYYHV7',
      statusContent: 'dot',
      hash: 'P1R9W8N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1R9W8N8'
    },
    {
      status: 210,
      sku: 'PM0080772',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338154',
      rtl_batch_array: '6338154',
      name_search: 'HYMNIGHT',
      name: 'Hypericum Miracle Night',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HSKHT542',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSKHT542'
    },
    {
      status: 210,
      sku: 'PM0080773',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338155',
      rtl_batch_array: '6338155',
      name_search: 'HYMPISTA',
      name: 'Hypericum Miracle Pistache',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_order_minimum: 3,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SZAYHXYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZAYHXYC'
    },
    {
      status: 210,
      sku: 'PM0080774',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338156',
      rtl_batch_array: '6338156',
      name_search: 'HYMWONDE',
      name: 'Hypericum Miracle Wonder',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2165,
      chnn_stock_retail: 2165,
      sppl_prcp: 2.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6W2ELPNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W2ELPNB'
    },
    {
      status: 210,
      sku: 'PM0080775',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338157',
      rtl_batch_array: '6338157',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1540,
      chnn_stock_retail: 1540,
      sppl_order_minimum: 3,
      sppl_prcp: 1.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'G3D4WYXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3D4WYXN'
    },
    {
      status: 210,
      sku: 'PM0014940',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6176233',
      rtl_batch_array: '6176233, 6348274',
      name_search: 'AGAALBUS',
      name: "Agapanthus africanus 'Albus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14899,
      chnn_stock_retail: 15284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAXYWE1K;JGE9ZRJ8;NEX5HJNV;AV14EGZ4;LEV5WRS6',
      statusContent: 'dot',
      hash: 'BTSAN8J3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTSAN8J3'
    },
    {
      status: 210,
      sku: 'PM0080776',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338158',
      rtl_batch_array: '6338158',
      name_search: 'HYMTRICO',
      name: "Hydrangea macrophylla 'Tricolor'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1551,
      chnn_stock_retail: 1551,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HVZWRD6',
      statusContent: 'dot',
      hash: '16C4LGAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16C4LGAE'
    },
    {
      status: 210,
      sku: 'PM0080777',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338159',
      rtl_batch_array: '6338159',
      name_search: 'ILMLRASC',
      name: 'Ilex meserveae Little Rascal',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_prcp: 3.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13GARV24;71E7CTSY;BS8SHAWL;XPE48L27',
      statusContent: 'dot',
      hash: 'EE3Y7RA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE3Y7RA1'
    },
    {
      status: 210,
      sku: 'PM0080778',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338160',
      rtl_batch_array: '6338160',
      name_search: 'ILAGDJ',
      name: "Ilex aquifolium 'GDJ'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1069,
      chnn_stock_retail: 1069,
      sppl_prcp: 3.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WB7SA6TH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB7SA6TH'
    },
    {
      status: 210,
      sku: 'PM0080779',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338161',
      rtl_batch_array: '6338161',
      name_search: 'ILAINGRA',
      name: "Ilex aquifolium 'Ingramii'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 814,
      chnn_stock_retail: 814,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LC1L5J6A;64KB8Y8V;HEN82888;EP7WP1Z8;NK1XJGN7;1P4D54PL',
      statusContent: 'dot',
      hash: 'T6ZHGZN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6ZHGZN6'
    },
    {
      status: 210,
      sku: 'PM0080780',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338162',
      rtl_batch_array: '6338162',
      name_search: 'ILAMYRTI',
      name: "Ilex aquifolium 'Myrtifolia'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1125,
      chnn_stock_retail: 1125,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKS6GWTN',
      statusContent: 'dot',
      hash: 'D3346V7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3346V7H'
    },
    {
      status: 210,
      sku: 'PM0080781',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338163',
      rtl_batch_array: '6338163',
      name_search: 'ILAMAMAC',
      name: "Ilex aquifolium 'Myrtifolia Aurea Maculata'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 644,
      chnn_stock_retail: 644,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWRZH78G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWRZH78G'
    },
    {
      status: 210,
      sku: 'PM0080782',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338164',
      rtl_batch_array: '6338164',
      name_search: 'ILASFOST',
      name: "Ilex attenuata 'Sunny Foster'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_prcp: 3.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '25Y79G34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25Y79G34'
    },
    {
      status: 810,
      sku: 'PM0004716',
      core_name: 'Plant',
      sppl_ean: '8720664693869',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '5496603',
      rtl_batch_array: '5496603',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: 'S6YA26G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6YA26G4'
    },
    {
      status: 210,
      sku: 'PM0017820',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196822',
      rtl_batch_array: '6196822, 6133263, 5866481',
      name_search: 'COLSTERN',
      name: "Coreopsis lanceolata 'Sterntaler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10493,
      chnn_stock_retail: 10905,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AL82D6XS;TV82L664;7JTRRR9C',
      statusContent: 'dot',
      hash: 'GXXCKN19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXXCKN19'
    },
    {
      status: 210,
      sku: 'PM0080783',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338165',
      rtl_batch_array: '6338165',
      name_search: 'ILCRENAT',
      name: 'Ilex crenata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2060,
      chnn_stock_retail: 2060,
      sppl_order_minimum: 5,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ZPHE4YX;G6GNL9HW;DEDHVPLC;S98DZ16D;JN9WPG9R;SSPZHTYG;8VTEEA4Z;8EVJD56A;62HCWZTE;L8L556GA;JG8E3W83;CTW46TT6;DLVBKSXX;J8P1G7W7;SNLD6864',
      statusContent: 'dot',
      hash: 'PRY2Z8Y1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRY2Z8Y1'
    },
    {
      status: 210,
      sku: 'PM0080784',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338166',
      rtl_batch_array: '6338166',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 578,
      chnn_stock_retail: 578,
      sppl_order_minimum: 5,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'PZ52JWP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ52JWP2'
    },
    {
      status: 210,
      sku: 'PM0080785',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338167',
      rtl_batch_array: '6338167',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3155,
      chnn_stock_retail: 3155,
      sppl_order_minimum: 5,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'ABN53BE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ABN53BE6'
    },
    {
      status: 210,
      sku: 'PM0080786',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338168',
      rtl_batch_array: '6338168',
      name_search: 'ILCFASTI',
      name: "Ilex crenata 'Fastigiata'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1371,
      chnn_stock_retail: 1371,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYY9ZJW6',
      statusContent: 'dot',
      hash: 'S95961NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S95961NJ'
    },
    {
      status: 210,
      sku: 'PM0080789',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338173',
      rtl_batch_array: '6338173',
      name_search: 'ILCMVARI',
      name: "Ilex crenata 'McCracken's Variegated'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8P7DPZGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8P7DPZGT'
    },
    {
      status: 210,
      sku: 'PM0082992',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356099',
      rtl_batch_array: '6356099',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'EXGYHDRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXGYHDRJ'
    },
    {
      status: 210,
      sku: 'PM0082993',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356100',
      rtl_batch_array: '6356100',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'ZY91KA3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY91KA3L'
    },
    {
      status: 210,
      sku: 'PM0082994',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356101',
      rtl_batch_array: '6356101',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'YPY1X7EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPY1X7EV'
    },
    {
      status: 210,
      sku: 'PM0082995',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356102',
      rtl_batch_array: '6356102',
      name_search: 'CHJSARGE',
      name: "Chaenomeles japonica 'Sargentii'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VV69BPYW;ZK3RYVLX;763KY6R4;LWNSYZVV;NSDKJDCD',
      statusContent: 'dot',
      hash: 'NCZ97P6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCZ97P6W'
    },
    {
      status: 210,
      sku: 'PM0082996',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356103',
      rtl_batch_array: '6356103',
      name_search: 'CHSNIVAL',
      name: "Chaenomeles speciosa 'Nivalis'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BYVSYJ1',
      statusContent: 'dot',
      hash: 'W72LT2VY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W72LT2VY'
    },
    {
      status: 210,
      sku: 'PM0082997',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356104',
      rtl_batch_array: '6356104',
      name_search: 'CHSJTRAI',
      name: "Chaenomeles superba 'Jet Trail'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6LGYLPL8;78SXWW53;7A721S9T;2GSSWH84;WDC9NHHH',
      statusContent: 'dot',
      hash: 'TP7CLYBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TP7CLYBJ'
    },
    {
      status: 210,
      sku: 'PM0082998',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356105',
      rtl_batch_array: '6356105',
      name_search: 'CHSNICOL',
      name: "Chaenomeles superba 'Nicoline'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z4NG2C3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4NG2C3G'
    },
    {
      status: 210,
      sku: 'PM0082999',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356106',
      rtl_batch_array: '6356106',
      name_search: 'CHSPLADY',
      name: "Chaenomeles superba 'Pink Lady'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T7YSAJPV;JWNCKEJD;VD4DNA9Y',
      statusContent: 'dot',
      hash: 'WVY4EDL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVY4EDL3'
    },
    {
      status: 210,
      sku: 'PM0083000',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356107',
      rtl_batch_array: '6356107',
      name_search: 'CHSSHORI',
      name: "Chaenomeles superba 'Salmon Horizon'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7ZR3LRG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZR3LRG5'
    },
    {
      status: 210,
      sku: 'PM0083001',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356108',
      rtl_batch_array: '6356108',
      name_search: 'CHSTSCAR',
      name: "Chaenomeles superba 'Texas Scarlet'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E47XA132',
      statusContent: 'dot',
      hash: 'X3LCT8NK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3LCT8NK'
    },
    {
      status: 210,
      sku: 'PM0083002',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356109',
      rtl_batch_array: '6356109',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'XN9L5RE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XN9L5RE4'
    },
    {
      status: 210,
      sku: 'PM0083003',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356110',
      rtl_batch_array: '6356110',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'C8C4K1XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8C4K1XR'
    },
    {
      status: 210,
      sku: 'PM0083004',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356111',
      rtl_batch_array: '6356111',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'L4X9W8R8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4X9W8R8'
    },
    {
      status: 210,
      sku: 'PM0083005',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356112',
      rtl_batch_array: '6356112',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 7.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'JKEN7DVR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKEN7DVR'
    },
    {
      status: 810,
      sku: 'PM0083006',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356113',
      rtl_batch_array: '6356113',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 8.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'G4WPS3PJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4WPS3PJ'
    },
    {
      status: 210,
      sku: 'PM0073542',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260107',
      rtl_batch_array: '6260107',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 26.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'SSJKP3D1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSJKP3D1'
    },
    {
      status: 210,
      sku: 'PM0073543',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260108',
      rtl_batch_array: '6260108',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '200225C20',
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 28.387,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'Z9YKLWK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9YKLWK1'
    },
    {
      status: 210,
      sku: 'PM0073544',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260120',
      rtl_batch_array: '6260120',
      name_search: 'LYDPURPL',
      name: "Lythrum 'Dropmore Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 524,
      chnn_stock_retail: 524,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X5AYS6RK;AJATWPPH;HAZ38HV1;TYTHDXW5;RWZ652LP',
      statusContent: 'dot',
      hash: '57LLEY6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57LLEY6S'
    },
    {
      status: 210,
      sku: 'PM0073545',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260121',
      rtl_batch_array: '6260121',
      name_search: 'MISHMUSS',
      name: "Miscanthus sinensis 'Herman Mussel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HD81J22;4V87XL3S;BRHBW4XV;BXARZKN3',
      statusContent: 'dot',
      hash: '4SBNK2DE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SBNK2DE'
    },
    {
      status: 210,
      sku: 'PM0008814',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6308467',
      rtl_batch_array: '6220218, 6112335, 5866482, 6133268, 6308467',
      name_search: 'COVZAGRE',
      name: "Coreopsis verticillata 'Zagreb'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 17041,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HRB1HW4A;V9SEVXPK;7T1PX26C;GKPWX3TC;AHC8C11Z;EBY1B6T9;PG6RYJ2L;9NDZV635;GCV5ALCY;NASEZLHH;S4X3Y1VA;7LENWLR9;H3HSE91R;PT74NA6G;4AKT3EKP;SL34ZS9S;WZ9T5LJ8;6XA3HDGX;8KDN7YW7',
      statusContent: 'dot',
      hash: 'C5B77Z4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5B77Z4K'
    },
    {
      status: 210,
      sku: 'PM0025485',
      core_name: 'Plant',
      sppl_ean: '8720626301849',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5902347',
      rtl_batch_array: '6084947, 5902347, 6301617',
      name_search: 'GECMBRAD',
      name: "Geum chiloense 'Mrs Bradshaw'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1164,
      chnn_stock_retail: 2727,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JK1V7PAW;NH686YWK;X3LGKZJE;LP4JVHYE;WAVDRWCV;9DZBYSZB;A8EB7923;Y2A29GHG;8E6KWPNY;721DDX71;ERH72YT5;9XWAYYKJ;28YT2AX3;T9XTPZ39',
      statusContent: 'dot',
      hash: 'HR6PKLES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR6PKLES'
    },
    {
      status: 210,
      sku: 'PM0008966',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255648',
      rtl_batch_array: '6255648',
      name_search: 'LYCFIREC',
      name: "Lysimachia ciliata 'Firecracker'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1921,
      chnn_stock_retail: 1921,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CH4SV9T;HD41TJ2H;2ZLZXBHS;24K32N8V',
      statusContent: 'dot',
      hash: 'GT1A4YAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT1A4YAP'
    },
    {
      status: 210,
      sku: 'PM0016166',
      core_name: 'Plant',
      sppl_ean: '8720626353794',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '5837981',
      rtl_batch_array: '5837981, 5495389, 6158256, 6307046',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15000,
      chnn_stock_retail: 16156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: 'PK4V1TP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PK4V1TP4'
    },
    {
      status: 210,
      sku: 'PM0052240',
      core_name: 'Plant',
      sppl_ean: '8720349416493',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6034946',
      rtl_batch_array: '6034946, 6112413, 6164296, 6295611',
      name_search: 'GEPSAMOB',
      name: "Geranium phaeum 'Samobor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 3094,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTYASSVB;PYXVCNTK',
      statusContent: 'dot',
      hash: 'J9L73229',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9L73229'
    },
    {
      status: 210,
      sku: 'PM0003575',
      core_name: 'Plant',
      sppl_ean: '8720664877672',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6079247',
      rtl_batch_array: '6079247, 6184073',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 3612,
      chnn_stock_retail: 5112,
      sppl_prcp: 2.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BT2SXTS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BT2SXTS7'
    },
    {
      status: 210,
      sku: 'PM0015112',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6177966',
      rtl_batch_array: '5793912, 6208060, 6164341, 6177966, 6295768',
      name_search: 'ORROSENK',
      name: "Origanum 'Rosenkuppel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 3043,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G2EEVJAP;XY7774Z3;HB8211SP',
      statusContent: 'dot',
      hash: 'BHHBEYSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BHHBEYSS'
    },
    {
      status: 810,
      sku: 'PM0075421',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280856',
      rtl_batch_array: '6280856',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: '7D6YWX38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D6YWX38'
    },
    {
      status: 810,
      sku: 'PM0075423',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280858',
      rtl_batch_array: '6280858',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.456,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: 'DZ4T4971',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ4T4971'
    },
    {
      status: 810,
      sku: 'PM0075424',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280859',
      rtl_batch_array: '6280859',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: 'A2NPKC1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2NPKC1X'
    },
    {
      status: 810,
      sku: 'PM0075425',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280860',
      rtl_batch_array: '6280860',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: 'V3RDCR41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3RDCR41'
    },
    {
      status: 210,
      sku: 'PM0027697',
      core_name: 'Plant',
      sppl_ean: '8720626351325',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364186',
      rtl_batch_array: '6350497, 5364186, 6305166',
      name_search: 'HODGREEN',
      name: "Hosta 'Devon Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 1427,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1177D1TV',
      statusContent: 'dot',
      hash: 'B8W9V6B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8W9V6B8'
    },
    {
      status: 210,
      sku: 'PM0004099',
      core_name: 'Plant',
      sppl_ean: '8720626369634',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '5866520',
      rtl_batch_array: '5866520, 6143304, 6161949, 6301835',
      name_search: 'SEPEMPER',
      name: "Sedum 'Purple Emperor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 314,
      chnn_stock_retail: 1744,
      sppl_order_minimum: 3,
      sppl_prcp: 0.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WA68ZRR',
      statusContent: 'dot',
      hash: 'ATW6XCG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATW6XCG6'
    },
    {
      status: 210,
      sku: 'PM0073264',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350229',
      rtl_batch_array: '6350229',
      name_search: 'GEPLLOVE',
      name: "Geranium phaeum 'Lily Lovell'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1966,
      chnn_stock_retail: 1966,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GWP8SP3B;6L5HJYX9;B924TA85;2VAJWWLE;7VZ2C8S6;Y3G2SLAX',
      statusContent: 'dot',
      hash: 'AY677B13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AY677B13'
    },
    {
      status: 810,
      sku: 'PM0075426',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280861',
      rtl_batch_array: '6280861',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: 'ZH3CCEWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZH3CCEWJ'
    },
    {
      status: 210,
      sku: 'PM0021802',
      core_name: 'Plant',
      sppl_ean: '8720626316294',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5502194',
      rtl_batch_array: '5502194',
      name_search: 'PHOLJOKE',
      name: 'Physocarpus opulifolius Little Joker',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3440,
      chnn_stock_retail: 3440,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E48CH8E;RPZCTBD6;PNCPDPEJ;5P2VJZ97;SX8GWW1H;15PJWBEG;JTELK2CN;1BSE16EY',
      statusContent: 'dot',
      hash: 'NYWDRR6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYWDRR6H'
    },
    {
      status: 810,
      sku: 'PM0075418',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280853',
      rtl_batch_array: '6280853',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.456,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'Y254PJJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y254PJJ6'
    },
    {
      status: 810,
      sku: 'PM0075419',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280854',
      rtl_batch_array: '6280854',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: '938C3SJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '938C3SJ4'
    },
    {
      status: 810,
      sku: 'PM0075427',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280862',
      rtl_batch_array: '6280862',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: '020025C5',
      rng_range_identifier: 'H020025C5',
      rng_range_description: 'H20 cm 25 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: '8N5NTK2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8N5NTK2E'
    },
    {
      status: 810,
      sku: 'PM0075428',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280863',
      rtl_batch_array: '6280863',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: '025030C5',
      rng_range_identifier: 'H025030C5',
      rng_range_description: 'H25 cm 30 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: '581B783E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '581B783E'
    },
    {
      status: 210,
      sku: 'PM0075430',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373046',
      rtl_batch_array: '6280867, 6373046',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 974,
      chnn_stock_retail: 1024,
      sppl_prcp: 9.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'CL7ZJ218',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CL7ZJ218'
    },
    {
      status: 810,
      sku: 'PM0075431',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280868',
      rtl_batch_array: '6280868',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '16DL1RZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16DL1RZZ'
    },
    {
      status: 210,
      sku: 'PM0075432',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314861',
      rtl_batch_array: '6280869, 6314861',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 129,
      chnn_stock_retail: 179,
      sppl_prcp: 29.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '16753ZEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16753ZEX'
    },
    {
      status: 210,
      sku: 'PM0075433',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314862',
      rtl_batch_array: '6280870, 6314862',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 385,
      chnn_stock_retail: 435,
      sppl_prcp: 32.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'HBH368GZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBH368GZ'
    },
    {
      status: 810,
      sku: 'PM0075434',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280871',
      rtl_batch_array: '6280871',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 69.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'T6VZX294',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6VZX294'
    },
    {
      status: 810,
      sku: 'PM0075438',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280875',
      rtl_batch_array: '6280875',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'YK62DZP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YK62DZP8'
    },
    {
      status: 810,
      sku: 'PM0075440',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280877',
      rtl_batch_array: '6280877',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 64.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: '21DN7Y34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21DN7Y34'
    },
    {
      status: 810,
      sku: 'PM0075420',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280855',
      rtl_batch_array: '6280855',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.397,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'X21DEHXR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X21DEHXR'
    },
    {
      status: 810,
      sku: 'PM0075429',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280864',
      rtl_batch_array: '6280864',
      name_search: 'ACPSHISH',
      name: "Acer palmatum 'Shishigashira'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.382,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TPRJ81R4',
      statusContent: 'dot',
      hash: 'HABLVKKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HABLVKKS'
    },
    {
      status: 210,
      sku: 'PM0011691',
      core_name: 'Plant',
      sppl_ean: '8720664691612',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5721834',
      rtl_batch_array: '5721834',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '120140C14',
      rng_range_identifier: 'H120140C1.5',
      rng_range_description: 'H120 cm 140 cm C1.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.647,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '677Z3PAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '677Z3PAH'
    },
    {
      status: 210,
      sku: 'PM0073717',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265386',
      rtl_batch_array: '6265386',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 237,
      chnn_stock_retail: 237,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'RLE51AT3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLE51AT3'
    },
    {
      status: 210,
      sku: 'PM0073617',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239306',
      rtl_batch_array: '6239306',
      name_search: 'SYVMBUCH',
      name: "Syringa vulgaris 'Michel Buchner'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 10.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y23AK5K;KXHJ5622;Z4RZ4HJC;KDPADEKY;6L8SRP7Y;CKV91A49',
      statusContent: 'dot',
      hash: 'DTPN5656',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTPN5656'
    },
    {
      status: 210,
      sku: 'PM0018714',
      core_name: 'Plant',
      sppl_ean: '8720626313330',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5268810',
      rtl_batch_array: '5268810',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: 'LEVC30',
      rng_range_identifier: 'C30',
      rng_range_description: 'C30',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 45.417,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      imageBatch: 'AXNJGLWG',
      statusContent: 'dot',
      hash: '7DGZRAZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DGZRAZK'
    },
    {
      status: 210,
      sku: 'PM0073618',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239307',
      rtl_batch_array: '6239307',
      name_search: 'SYVMLEMO',
      name: "Syringa vulgaris 'Monique Lemoine'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 10.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZTYZ7PL9',
      statusContent: 'dot',
      hash: 'H6D4316V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6D4316V'
    },
    {
      status: 210,
      sku: 'PM0073029',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258685',
      rtl_batch_array: '6258685',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'ZVZ2E1GY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVZ2E1GY'
    },
    {
      status: 810,
      sku: 'PM0073619',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239308',
      rtl_batch_array: '6239308',
      name_search: 'SYVPTHIR',
      name: "Syringa vulgaris 'Paul Thirion'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 10.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VL1N2NCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL1N2NCW'
    },
    {
      status: 210,
      sku: 'PM0073549',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260673',
      rtl_batch_array: '6260673',
      name_search: 'ANHHABUN',
      name: "Anemone hybrida 'Hadspen Abundance'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 629,
      chnn_stock_retail: 629,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZWJ3J4E;T11J9SV4;1D6X7ZEE;W6A7VVRE;N656ELN8',
      statusContent: 'dot',
      hash: 'HZE3BNB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZE3BNB6'
    },
    {
      status: 210,
      sku: 'PM0007571',
      core_name: 'Plant',
      sppl_ean: '8720664699632',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905572',
      rtl_batch_array: '5905572',
      name_search: 'MISRCLOU',
      name: 'Miscanthus sinensis Red Cloud',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 839,
      chnn_stock_retail: 839,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB4Z5AGB',
      statusContent: 'dot',
      hash: '9CNJ9EGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CNJ9EGY'
    },
    {
      status: 210,
      sku: 'PM0073548',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260672',
      rtl_batch_array: '6260672, 6252448',
      name_search: 'ANHUPEHE',
      name: 'Anemone hupehensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 138,
      chnn_stock_retail: 837,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NYZ6RG6H;H9B88889;8YA23WLR;8TCXZTGV;RTKCJDJ8;HHPCDBES;R9RDNAXG;1V6E81VC;C76SX2K2',
      statusContent: 'dot',
      hash: 'JZXKDLWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZXKDLWW'
    },
    {
      status: 210,
      sku: 'PM0073552',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260677',
      rtl_batch_array: '6260677',
      name_search: 'COGRANDI',
      name: 'Coreopsis grandiflora',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 578,
      chnn_stock_retail: 578,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5LAC7SPR;CXCZ29RR;6A6C7JZ4;LB4LS2C9;P87JXZT3;X9A6RCL5;8B7HSA1A',
      statusContent: 'dot',
      hash: '2TC1Z37S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TC1Z37S'
    },
    {
      status: 210,
      sku: 'PM0073690',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264776',
      rtl_batch_array: '6264776',
      name_search: 'JUPNANA',
      name: "Juniperus procumbens 'Nana'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YPH14G7X;KRS7HGXY;V2B488EW;LA1AHTSX;G2D5PA79;DGYVNPD5;KB81N2R4;T1HLSSNG;1YXCNZW9;5B55H4XV;1JRJ3A43',
      statusContent: 'dot',
      hash: 'C48G3NKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C48G3NKP'
    },
    {
      status: 210,
      sku: 'PM0000299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353431',
      rtl_batch_array: '6353431',
      name_search: 'SEGRANAT',
      name: "Sempervivum 'Granat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1016,
      chnn_stock_retail: 1016,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T2KLGYBW',
      statusContent: 'dot',
      hash: 'WYB35A6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYB35A6D'
    },
    {
      status: 210,
      sku: 'PM0073616',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239305',
      rtl_batch_array: '6239305',
      name_search: 'SYVLSPAE',
      name: 'Syringa vulgaris Ludwig Spaeth',
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 10.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RB7P9Y85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RB7P9Y85'
    },
    {
      status: 210,
      sku: 'PM0073688',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264767',
      rtl_batch_array: '6264767',
      name_search: 'CHLGLOBO',
      name: "Chamaecyparis lawsoniana 'Globosa'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1852,
      chnn_stock_retail: 1852,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N5YCG7A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5YCG7A5'
    },
    {
      status: 210,
      sku: 'PM0083007',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356114',
      rtl_batch_array: '6356114',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 891,
      chnn_stock_retail: 891,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'ZZV7X2TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZV7X2TB'
    },
    {
      status: 210,
      sku: 'PM0083010',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356117',
      rtl_batch_array: '6356117',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: '2K4YR8B4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2K4YR8B4'
    },
    {
      status: 210,
      sku: 'PM0073608',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 2,
      btch_active_retail: '6261573',
      rtl_batch_array: '6261573, 6300743',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 1100,
      sppl_prcp: 5.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'Z63HDYYS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z63HDYYS'
    },
    {
      status: 210,
      sku: 'PM0073691',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264778',
      rtl_batch_array: '6264778',
      name_search: 'POCBOX',
      name: "Podocarpus 'Chocolate Box'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 378,
      chnn_stock_retail: 378,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2XZ59P6;1VVBPHCN;NELDCXWC;K7ZSNDK1;NT2C1RGN;LAL276HS;WWDDZXX5',
      statusContent: 'dot',
      hash: 'L56BEEND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L56BEEND'
    },
    {
      status: 810,
      sku: 'PM0073692',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264779',
      rtl_batch_array: '6264779',
      name_search: 'POCPFIRE',
      name: "Podocarpus 'County Park Fire'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1EJ3LP5;5J4WT2RH;SKRPK5KZ',
      statusContent: 'dot',
      hash: '4BGJVEVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BGJVEVP'
    },
    {
      status: 210,
      sku: 'PM0073693',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264780',
      rtl_batch_array: '6264780',
      name_search: 'SCVGDIAM',
      name: 'Sciadopitys verticillata Green Diamond',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 790,
      chnn_stock_retail: 790,
      sppl_prcp: 11.121,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RWNYD4R9',
      statusContent: 'dot',
      hash: 'X6D7X2RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6D7X2RJ'
    },
    {
      status: 210,
      sku: 'PM0083008',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356115',
      rtl_batch_array: '6356115',
      name_search: 'COSWBEAU',
      name: "Cornus sanguinea 'Winter Beauty'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRDN1TCW;2Z3DSCS8;TZPXD2G1;LYJENPAZ;AYL13WRY;CZ8H6XYY',
      statusContent: 'dot',
      hash: '8C1YX5SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8C1YX5SY'
    },
    {
      status: 210,
      sku: 'PM0073697',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257883',
      rtl_batch_array: '6257883',
      name_search: 'SPBISLAN',
      name: "Spiraea betulifolia 'Island'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.895,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CKKD84VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKKD84VT'
    },
    {
      status: 210,
      sku: 'PM0080799',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338184',
      rtl_batch_array: '6338184',
      name_search: 'KALGINKO',
      name: "Kalmia latifolia 'Ginkona'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X8GE8YDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8GE8YDY'
    },
    {
      status: 210,
      sku: 'PM0083009',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356116',
      rtl_batch_array: '6356116',
      name_search: 'COSWBEAU',
      name: "Cornus sanguinea 'Winter Beauty'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 765,
      chnn_stock_retail: 765,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRDN1TCW;2Z3DSCS8;TZPXD2G1;LYJENPAZ;AYL13WRY;CZ8H6XYY',
      statusContent: 'dot',
      hash: 'SXXR9E3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXXR9E3Z'
    },
    {
      status: 810,
      sku: 'PM0073019',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281312',
      rtl_batch_array: '6281312',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'CA2JSS25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA2JSS25'
    },
    {
      status: 810,
      sku: 'PM0073015',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298832',
      rtl_batch_array: '6298832',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '150COSPI',
      rng_range_identifier: 'SPIRH150150',
      rng_range_description: 'Spiral H150 cm 150 cm',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 176.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'DZXDAZW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZXDAZW3'
    },
    {
      status: 210,
      sku: 'PM0073016',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258658',
      rtl_batch_array: '6258658',
      name_search: 'THOMBBAL',
      name: "Thuja occidentalis 'Mr Bowling Ball'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3EC5EDE',
      statusContent: 'dot',
      hash: 'P7P19VGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7P19VGZ'
    },
    {
      status: 210,
      sku: 'PM0073017',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258659',
      rtl_batch_array: '6258659',
      name_search: 'THOSTRAS',
      name: "Thuja occidentalis 'Straskov'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 5.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RYW351P8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYW351P8'
    },
    {
      status: 210,
      sku: 'PM0073687',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264766',
      rtl_batch_array: '6264766',
      name_search: 'ABKOREAN',
      name: 'Abies koreana',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 965,
      chnn_stock_retail: 965,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PRN4VTP;25XPVV4T;973S6HBS;893SGVLD',
      statusContent: 'dot',
      hash: 'PWTEWCP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWTEWCP4'
    },
    {
      status: 210,
      sku: 'PM0073028',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258684',
      rtl_batch_array: '6258684',
      name_search: 'CAAOVERD',
      name: "Calamagrostis acutiflora 'Overdam'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 9870,
      chnn_stock_retail: 9870,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6N82A37;NRXEE51H;EN26CHAN;ZNJBER7R;C7NJKLYV;GXYJ8CAB;6P9PPYAV;BWAPCE1Y;L1RBX35E;SK4J87TV;D7JHV6BG;PKZJ346T;94E65NSZ;6T679YBV;6KX1BDZ2;V8574RZW;6AVTGASE;WTHJSTT1;S4SW2T6Y;JTCHNR59;RHYECWKK;LYJV25S6;DLPK5RNR;HRXHBLXT;HK57GBK8;JN8VG67A',
      statusContent: 'dot',
      hash: '2RJY7TK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RJY7TK2'
    },
    {
      status: 210,
      sku: 'PM0073031',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258688',
      rtl_batch_array: '6258688',
      name_search: 'CACKBLUE',
      name: "Caryopteris clandonensis 'Kew Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2PCYA2A;JRHPKVBA',
      statusContent: 'dot',
      hash: 'K6CH6DNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6CH6DNX'
    },
    {
      status: 210,
      sku: 'PM0073270',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350354',
      rtl_batch_array: '6350354',
      name_search: 'HELMADEL',
      name: "Hemerocallis 'Lemon Madeline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VWDGTYXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWDGTYXB'
    },
    {
      status: 210,
      sku: 'PM0073273',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350287',
      rtl_batch_array: '6350287',
      name_search: 'HEFMORGA',
      name: "Helenium 'Fata Morgana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6GGA6YGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GGA6YGS'
    },
    {
      status: 810,
      sku: 'PM0073274',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348332',
      rtl_batch_array: '6350297, 6348332',
      name_search: 'HELKUPFE',
      name: "Helenium 'Kupferzwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1XW1VKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1XW1VKJ'
    },
    {
      status: 210,
      sku: 'PM0073278',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350580',
      rtl_batch_array: '6350580',
      name_search: 'IRSPAPRI',
      name: "Iris sibirica 'Paprikash'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BS623WGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS623WGG'
    },
    {
      status: 210,
      sku: 'PM0073694',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264784',
      rtl_batch_array: '6264784',
      name_search: 'MIDECUSS',
      name: 'Microbiota decussata',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 11045,
      chnn_stock_retail: 11045,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PSVSYYP;DWGB7P91;CVZ7AWNJ;TAL4LW1P;XL81N6JH;GHVC75RA',
      statusContent: 'dot',
      hash: '4V7N7DXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V7N7DXP'
    },
    {
      status: 210,
      sku: 'PM0073279',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350581',
      rtl_batch_array: '6350581',
      name_search: 'IRSREPRI',
      name: "Iris sibirica 'Reprise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DY2VARZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DY2VARZ'
    },
    {
      status: 810,
      sku: 'PM0073615',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239304',
      rtl_batch_array: '6239304',
      name_search: 'SYVCJOLY',
      name: "Syringa vulgaris 'Charles Joly'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JEKZCD8X;DLCWPTG4;7SBXAEZ1',
      statusContent: 'dot',
      hash: 'J9XPBL29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9XPBL29'
    },
    {
      status: 810,
      sku: 'PM0073514',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6307696',
      rtl_batch_array: '6307696, 6287594',
      name_search: 'VICARLCE',
      name: 'Viburnum carlcephalum',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 35,
      chnn_stock_retail: 41,
      sppl_prcp: 32.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLC4D9D5;1PE21WKK;PKZYVWCX;LCB99K7C;A4PWJPX1;HV2P3ZJH',
      statusContent: 'dot',
      hash: 'EV9AT175',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EV9AT175'
    },
    {
      status: 210,
      sku: 'PM0073272',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350285',
      rtl_batch_array: '6350285, 6321934',
      name_search: 'HEEDORAD',
      name: "Helenium 'El Dorado'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 454,
      chnn_stock_retail: 534,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1XG7884',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1XG7884'
    },
    {
      status: 810,
      sku: 'PM0075441',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280878',
      rtl_batch_array: '6280878',
      name_search: 'AKQUINAT',
      name: 'Akebia quinata',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YA8H49S7;1PD4YN1L;XR8L16NA;L23SYYRZ;XPSYNY1T;WVH7DEDD;1RGDRZHP;LKYER7P7;6SV47663;B1V71WA5;84ZGCLTK;GPRZ1B8P;TRA52T42;996S9XYN',
      statusContent: 'dot',
      hash: 'EZ8ANLD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZ8ANLD5'
    },
    {
      status: 810,
      sku: 'PM0080800',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338185',
      rtl_batch_array: '6338185',
      name_search: 'KALIDEAL',
      name: "Kalmia latifolia 'Ideal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DR3AT6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DR3AT6C'
    },
    {
      status: 810,
      sku: 'PM0075442',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280879',
      rtl_batch_array: '6280879',
      name_search: 'ALJROSEA',
      name: 'Albizia julibrissin rosea',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '794TPW34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '794TPW34'
    },
    {
      status: 210,
      sku: 'PM0073271',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350355',
      rtl_batch_array: '6350355, 6374018',
      name_search: 'HELWAY',
      name: "Hemerocallis 'Light the Way'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 344,
      chnn_stock_retail: 444,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X8NG8PXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8NG8PXS'
    },
    {
      status: 810,
      sku: 'PM0005062',
      core_name: 'Plant',
      sppl_ean: '8720626334724',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5504711',
      rtl_batch_array: '5504711, 6259391',
      name_search: 'AQUVBBAR',
      name: "Aquilegia vulgaris 'Blue Barlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YD2WR2CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD2WR2CZ'
    },
    {
      status: 210,
      sku: 'PM0073550',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6260674',
      rtl_batch_array: '6260674, 6252452, 6305788, 6309340',
      name_search: 'ANHKCHAR',
      name: "Anemone hybrida 'K\u00f6nigin Charlotte'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1242,
      chnn_stock_retail: 5500,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CN2NWZE3;TK6REXC5;13HS42A7;KRCG6G6P;8JNNAC86;H8J5G6AG;L7XWCREE;J3P8J796;RG8BPZST;HKXY5RWR;CGT44YW7',
      statusContent: 'dot',
      hash: 'KW8Y6K5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW8Y6K5B'
    },
    {
      status: 810,
      sku: 'PM0073551',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260676',
      rtl_batch_array: '6260676, 6234583, 6334350',
      name_search: 'ASCPUMIL',
      name: "Astilbe chinensis 'Pumila'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 56,
      chnn_stock_retail: 2346,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTGRL2L8;VS1KXCB5;LSCASRAS;L46L8KDD;4WSVACL3;DE26T6ZR;K58EBYVK;AWBB8A9S;REH17Z7S;BZ51PK8D;CCZ34NGD',
      statusContent: 'dot',
      hash: 'CABL519G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CABL519G'
    },
    {
      status: 210,
      sku: 'PM0073556',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260682',
      rtl_batch_array: '6260682, 6234691, 5837418',
      name_search: 'ECPMAGNU',
      name: "Echinacea purpurea 'Magnus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3622,
      chnn_stock_retail: 7274,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B5BBAX8;H61X9HC9;VDTJAZV1;AL37J663;8RWVZ719',
      statusContent: 'dot',
      hash: '19LRVHGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19LRVHGG'
    },
    {
      status: 210,
      sku: 'PM0073555',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260681',
      rtl_batch_array: '6260681, 6334352',
      name_search: 'ECPCSPIR',
      name: "Echinacea purpurea 'Cheyenne Spirit'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 432,
      chnn_stock_retail: 782,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YVN4J1WC',
      statusContent: 'dot',
      hash: 'TE233HB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TE233HB8'
    },
    {
      status: 210,
      sku: 'PM0080801',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338186',
      rtl_batch_array: '6338186',
      name_search: 'KALJDELI',
      name: "Kalmia latifolia 'Jans Delight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WLE33LR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLE33LR3'
    },
    {
      status: 910,
      sku: 'PM0073599',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABFROBU',
      name: "Taxus baccata 'Fastigiata Robusta'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      imageCore: 'YJRLWDE4;5WYTHSPR;5EJXXPAJ;7JH3XKZC;7K755T8W',
      statusContent: 'dot',
      hash: '8NKZL2Z1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8NKZL2Z1'
    },
    {
      status: 210,
      sku: 'PM0073600',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257494',
      rtl_batch_array: '6257494',
      name_search: 'TABOVERE',
      name: "Taxus baccata 'Overeynderi'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 472,
      chnn_stock_retail: 472,
      sppl_prcp: 6.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A28S9N5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A28S9N5Y'
    },
    {
      status: 210,
      sku: 'PM0080802',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338187',
      rtl_batch_array: '6338187',
      name_search: 'KALKARDI',
      name: "Kalmia latifolia 'Kardinal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TVW19CCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVW19CCZ'
    },
    {
      status: 210,
      sku: 'PM0052290',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301865',
      rtl_batch_array: '6301865, 6348993',
      name_search: 'SYGWBLUE',
      name: "Symphytum grandiflorum 'Wisley Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 23989,
      chnn_stock_retail: 24091,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1L5YYJ6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L5YYJ6Z'
    },
    {
      status: 810,
      sku: 'PM0073606',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6261571',
      rtl_batch_array: '6261571',
      name_search: 'DECPROCH',
      name: "Deutzia crenata 'Pride of Rochester'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E42YC5BC',
      statusContent: 'dot',
      hash: 'Y3PHVKRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3PHVKRZ'
    },
    {
      status: 210,
      sku: 'PM0073275',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350572',
      rtl_batch_array: '6350572',
      name_search: 'IRELWAIT',
      name: "Iris ensata 'Lady in Waiting'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LSW37P2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSW37P2K'
    },
    {
      status: 210,
      sku: 'PM0073276',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350576',
      rtl_batch_array: '6350576',
      name_search: 'IRSCCRUS',
      name: "Iris sibirica 'Concord Crush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6VC1CGX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VC1CGX9'
    },
    {
      status: 210,
      sku: 'PM0073027',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258671',
      rtl_batch_array: '6258671',
      name_search: 'BRMSSPEA',
      name: "Brunnera macrophylla 'Silver Spear'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 865,
      chnn_stock_retail: 865,
      sppl_prcp: 4.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWBG1NCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWBG1NCV'
    },
    {
      status: 210,
      sku: 'PM0073611',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6261578',
      rtl_batch_array: '6261578',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: '9VGW8AX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VGW8AX2'
    },
    {
      status: 210,
      sku: 'PM0073553',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260679',
      rtl_batch_array: '6260679',
      name_search: 'DEOTBLUE',
      name: 'Delphinium Ocean Tiara Blue',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 763,
      chnn_stock_retail: 763,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7B8VDY4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7B8VDY4Y'
    },
    {
      status: 810,
      sku: 'PM0073613',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6239300',
      rtl_batch_array: '6239300',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.928,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'CW7RRGDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW7RRGDL'
    },
    {
      status: 210,
      sku: 'PM0073707',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265375',
      rtl_batch_array: '6265375',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 211,
      chnn_stock_retail: 211,
      sppl_prcp: 4.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: 'LA6W51ZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA6W51ZC'
    },
    {
      status: 810,
      sku: 'PM0073713',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265382',
      rtl_batch_array: '6265382',
      name_search: 'BEPENDUL',
      name: 'Betula pendula',
      sppl_size_code: '175200C5',
      rng_range_identifier: 'H175200C5',
      rng_range_description: 'H175 cm 200 cm C5',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_prcp: 5.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2L111WX;P674XDDL;7G125933;GJ69KJHY;5VTCDCKL;LD1WP2V1;BD4TDAYE;E4N23XWJ',
      statusContent: 'dot',
      hash: 'CPRE144J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPRE144J'
    },
    {
      status: 210,
      sku: 'PM0073714',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265383',
      rtl_batch_array: '6265383',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T4J9JEDK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4J9JEDK'
    },
    {
      status: 210,
      sku: 'PM0073715',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265384',
      rtl_batch_array: '6265384',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '100110C5',
      rng_range_identifier: 'H100110C5',
      rng_range_description: 'H100 cm 110 cm C5',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 4.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'XSZEDHDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSZEDHDL'
    },
    {
      status: 210,
      sku: 'PM0073721',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6265392',
      rtl_batch_array: '6265392, 6291751',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 112,
      chnn_stock_retail: 362,
      sppl_prcp: 5.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'GJJLR4ZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJJLR4ZC'
    },
    {
      status: 210,
      sku: 'PM0073724',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265395',
      rtl_batch_array: '6265395',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'LWE2378A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWE2378A'
    },
    {
      status: 210,
      sku: 'PM0073554',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260680',
      rtl_batch_array: '6260680',
      name_search: 'ECPMYELL',
      name: "Echinacea purpurea 'Meteor Yellow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 652,
      chnn_stock_retail: 652,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VTTR365D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTTR365D'
    },
    {
      status: 210,
      sku: 'PM0014962',
      core_name: 'Plant',
      sppl_ean: '8720353097107',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5866489',
      rtl_batch_array: '5866489',
      name_search: 'DOORIENT',
      name: 'Doronicum orientale',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 918,
      chnn_stock_retail: 918,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TYRBT7P4',
      statusContent: 'dot',
      hash: '8YE8726K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YE8726K'
    },
    {
      status: 210,
      sku: 'PM0080790',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338174',
      rtl_batch_array: '6338174',
      name_search: 'ILPCOMPA',
      name: "Ilex pernyi 'Compacta'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '72BHS2AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72BHS2AE'
    },
    {
      status: 210,
      sku: 'PM0080791',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338175',
      rtl_batch_array: '6338175',
      name_search: 'ILVNANA',
      name: "Ilex verticillata 'Nana'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 575,
      chnn_stock_retail: 575,
      sppl_order_minimum: 3,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1Z8KY3CT;KCW33KCC;YYA6TANK',
      statusContent: 'dot',
      hash: '77REVH8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77REVH8N'
    },
    {
      status: 210,
      sku: 'PM0073602',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6261567',
      rtl_batch_array: '6261567',
      name_search: 'COAIHALO',
      name: 'Cornus alba Ivory Halo',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CR321S2S;GNGZHV8Y;S4PECZA9;JNA3KWYB;8GX37KT3',
      statusContent: 'dot',
      hash: 'PEENYCKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEENYCKL'
    },
    {
      status: 210,
      sku: 'PM0080792',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338176',
      rtl_batch_array: '6338176',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'GDVPG39J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDVPG39J'
    },
    {
      status: 210,
      sku: 'PM0073547',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260670',
      rtl_batch_array: '6260670',
      name_search: 'SPJGOLDM',
      name: "Spiraea japonica 'Goldmound'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 433,
      chnn_stock_retail: 433,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PWTVET5X',
      statusContent: 'dot',
      hash: '5EY132WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EY132WC'
    },
    {
      status: 210,
      sku: 'PM0073695',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6251052',
      rtl_batch_array: '6251052',
      name_search: 'ALERYTHR',
      name: 'Alchemilla erythropoda',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_order_minimum: 3,
      sppl_prcp: 1.448,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7AZY9S8;LLL5J26H;WG6RL1JG;HLL2XDH1;R6BBV7DP;GGGDLH4G;51NBD2RW;E98119SA;GB3E7WA8;V8EBJ34Y',
      statusContent: 'dot',
      hash: '7T2BEAK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7T2BEAK3'
    },
    {
      status: 210,
      sku: 'PM0080803',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338188',
      rtl_batch_array: '6338188',
      name_search: 'KALMITTE',
      name: "Kalmia latifolia 'Mitternacht'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZHXZZKNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHXZZKNX'
    },
    {
      status: 810,
      sku: 'PM0073277',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350577',
      rtl_batch_array: '6350577',
      name_search: 'IRSDWALT',
      name: "Iris sibirica 'Dawn Waltz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EALB1XZZ;AR23DWAE',
      statusContent: 'dot',
      hash: '45PZW714',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45PZW714'
    },
    {
      status: 210,
      sku: 'PM0080793',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338177',
      rtl_batch_array: '6338177',
      name_search: 'INHETERA',
      name: 'Indigofera heterantha',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1222,
      chnn_stock_retail: 1222,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9GZ6L55Y',
      statusContent: 'dot',
      hash: 'ENV2Y4JJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENV2Y4JJ'
    },
    {
      status: 210,
      sku: 'PM0080794',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338178',
      rtl_batch_array: '6338178',
      name_search: 'ITILICIF',
      name: 'Itea ilicifolia',
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 483,
      chnn_stock_retail: 483,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W1L3DN6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1L3DN6T'
    },
    {
      status: 210,
      sku: 'PM0080795',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338179',
      rtl_batch_array: '6338179',
      name_search: 'ITVHGARN',
      name: "Itea virginica 'Henry's Garnet'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2316,
      chnn_stock_retail: 2316,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZLL83K2',
      statusContent: 'dot',
      hash: '6V4D8A4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6V4D8A4W'
    },
    {
      status: 210,
      sku: 'PM0080796',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338180',
      rtl_batch_array: '6338180',
      name_search: 'JANUDIFL',
      name: 'Jasminum nudiflorum',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 763,
      chnn_stock_retail: 763,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TKK7Y96C;EC55166Y;EHN4G591;R2N5X45G;NKRW2BK7;SAZ3BWN1;L125CLNR;TE197YB3',
      statusContent: 'dot',
      hash: 'T3LN362E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3LN362E'
    },
    {
      status: 210,
      sku: 'PM0080797',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338182',
      rtl_batch_array: '6338182',
      name_search: 'KALBLABE',
      name: "Kalmia latifolia 'Black Label'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZK8CTK6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK8CTK6Y'
    },
    {
      status: 210,
      sku: 'PM0080798',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338183',
      rtl_batch_array: '6338183',
      name_search: 'KALELF',
      name: "Kalmia latifolia 'Elf'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H28LN6VE',
      statusContent: 'dot',
      hash: 'V8EN9HPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8EN9HPA'
    },
    {
      status: 210,
      sku: 'PM0080804',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338189',
      rtl_batch_array: '6338189',
      name_search: 'KALNANI',
      name: "Kalmia latifolia 'Nani'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 525,
      chnn_stock_retail: 525,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8P9ENBJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8P9ENBJT'
    },
    {
      status: 810,
      sku: 'PM0080805',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338190',
      rtl_batch_array: '6338190',
      name_search: 'KALORED',
      name: "Kalmia latifolia 'Ostbo Red'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 6.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3K8NEVS',
      statusContent: 'dot',
      hash: 'ZNACEPPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNACEPPW'
    },
    {
      status: 210,
      sku: 'PM0080806',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338191',
      rtl_batch_array: '6338191',
      name_search: 'KALORED',
      name: "Kalmia latifolia 'Ostbo Red'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 124,
      chnn_stock_retail: 124,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3K8NEVS',
      statusContent: 'dot',
      hash: 'VPVSE37B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPVSE37B'
    },
    {
      status: 210,
      sku: 'PM0080807',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338192',
      rtl_batch_array: '6338192',
      name_search: 'KALRADIA',
      name: "Kalmia latifolia 'Radiata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 515,
      chnn_stock_retail: 515,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ND24WXKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND24WXKP'
    },
    {
      status: 210,
      sku: 'PM0080808',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338193',
      rtl_batch_array: '6338193',
      name_search: 'KALTAD',
      name: "Kalmia latifolia 'Tad'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 525,
      chnn_stock_retail: 525,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '653EG28W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '653EG28W'
    },
    {
      status: 210,
      sku: 'PM0080809',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338194',
      rtl_batch_array: '6338194',
      name_search: 'KALZEBUL',
      name: "Kalmia latifolia 'Zebulon'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4P4T9ZL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P4T9ZL5'
    },
    {
      status: 210,
      sku: 'PM0080810',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338195',
      rtl_batch_array: '6338195',
      name_search: 'KEJGGUIN',
      name: "Kerria japonica 'Golden Guinea'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1714,
      chnn_stock_retail: 1714,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZLYZ61J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZLYZ61J'
    },
    {
      status: 210,
      sku: 'PM0080811',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338196',
      rtl_batch_array: '6338196',
      name_search: 'KEJPICTA',
      name: "Kerria japonica 'Picta'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 505,
      chnn_stock_retail: 505,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WLLCD6EA',
      statusContent: 'dot',
      hash: 'AZ7829TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ7829TL'
    },
    {
      status: 210,
      sku: 'PM0080812',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338197',
      rtl_batch_array: '6338197',
      name_search: 'KEJPLENI',
      name: "Kerria japonica 'Pleniflora'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3194,
      chnn_stock_retail: 3194,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y49EYZE8;945VKGPK;J19DN9A8;9Z6LSTEH;8N4SZZ37;27N1C4K3;YC9D7VAX;WHNEBXT4;V7NR7ETW;GZ9679V3;6T1ZH9LV;5EN8PSJ2;9881V54B;5TVCNZ8L',
      statusContent: 'dot',
      hash: '8HKA8RDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HKA8RDV'
    },
    {
      status: 210,
      sku: 'PM0080813',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338198',
      rtl_batch_array: '6338198',
      name_search: 'LAIBRED',
      name: 'Lagerstroemia indica Best Red',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 760,
      chnn_stock_retail: 760,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8ZGA1D9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZGA1D9D'
    },
    {
      status: 210,
      sku: 'PM0080814',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338199',
      rtl_batch_array: '6338199',
      name_search: 'LAIBRED',
      name: 'Lagerstroemia indica Best Red',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 131,
      chnn_stock_retail: 131,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZLG6YKRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLG6YKRD'
    },
    {
      status: 210,
      sku: 'PM0080815',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338200',
      rtl_batch_array: '6338200',
      name_search: 'LAIBLUSH',
      name: 'Lagerstroemia indica Blush',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 530,
      chnn_stock_retail: 530,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y5YZEB1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5YZEB1P'
    },
    {
      status: 210,
      sku: 'PM0015860',
      core_name: 'Plant',
      sppl_ean: '8720626302815',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '5861508',
      rtl_batch_array: '5861508',
      name_search: 'HEPSUTHE',
      name: "Hebe pinguifolia 'Sutherlandii'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1340,
      chnn_stock_retail: 1340,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G956WPEA;ZVZ622DX;YZAJG146;7YR1WZBN;1JZLSCZV;59YNEXAE;RP8125WN',
      statusContent: 'dot',
      hash: 'A2KCNA43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2KCNA43'
    },
    {
      status: 210,
      sku: 'PM0073735',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265406',
      rtl_batch_array: '6265406',
      name_search: 'SYVULGAR',
      name: 'Syringa vulgaris',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2HRB4X5;YKB45ZLV;15KSWZR1;S1LRAANK;A6PWADE1;E1HJATGN;AYDVATA4;WJAJARZR;4361DXJ2;Y4G96EP2;8ZJ1PJ7D',
      statusContent: 'dot',
      hash: 'Y1DG6K7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1DG6K7J'
    },
    {
      status: 210,
      sku: 'PM0015727',
      core_name: 'Plant',
      sppl_ean: '8720626359925',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5866439',
      rtl_batch_array: '5866439, 6133397, 6339853',
      name_search: 'MOCSCARL',
      name: "Monarda 'Cambridge Scarlet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 289,
      chnn_stock_retail: 6105,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CTKLX8JS;L6632JEY;K3EBS9CS;R9LLKCAZ;YD9B327W;HJXCDTR8;NA6YH63N;4234A6DR;VN948NXT;DJWCWRZ2;2GAVVEWG;XNA7TCH6;R3BDPSGS;PNP8KPRZ;JNLZ3VYD;B51DZ86J;R5K5K777;9LHK3AZZ',
      statusContent: 'dot',
      hash: 'YRAKJX6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRAKJX6D'
    },
    {
      status: 210,
      sku: 'PM0073049',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207269',
      rtl_batch_array: '5207269, 6258706',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4892,
      sppl_prcp: 4.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      imageBatch: '8ZDB14A3',
      statusContent: 'dot',
      hash: 'K8APBWA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8APBWA3'
    },
    {
      status: 210,
      sku: 'PM0073730',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222191',
      rtl_batch_array: '6222191',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'A5WS22X5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5WS22X5'
    },
    {
      status: 210,
      sku: 'PM0073731',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265402',
      rtl_batch_array: '6265402',
      name_search: 'SYALBUS',
      name: 'Symphoricarpos albus',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1769,
      chnn_stock_retail: 1769,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SH55ZB5R;4JY5CL24',
      statusContent: 'dot',
      hash: '72JHYSH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72JHYSH7'
    },
    {
      status: 910,
      sku: 'PM0073732',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYALBUS',
      name: 'Symphoricarpos albus',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      imageCore: 'SH55ZB5R;4JY5CL24',
      statusContent: 'dot',
      hash: 'SW1C1DKJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SW1C1DKJ'
    },
    {
      status: 810,
      sku: 'PM0007589',
      core_name: 'Plant',
      sppl_ean: '8720664694095',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '5496616',
      rtl_batch_array: '5356257, 5496616',
      name_search: 'HYQSNOWF',
      name: "Hydrangea quercifolia 'Snowflake'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYKE32HW;D6XAEKDS;BGHGRNH2;EAC39HBS;8SD5ZXWK;P8JEJB4S;KK32262J;KV1J8B7G;LJVCS9AK;N24GLAX2',
      statusContent: 'dot',
      hash: '73VRAYBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73VRAYBJ'
    },
    {
      status: 810,
      sku: 'PM0019233',
      core_name: 'Plant',
      sppl_ean: '8720664671904',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5628392',
      rtl_batch_array: '5628392',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 27,
      chnn_stock_retail: 27,
      sppl_prcp: 15.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: 'JRVCDEE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRVCDEE8'
    },
    {
      status: 210,
      sku: 'PM0080832',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338217',
      rtl_batch_array: '6338217',
      name_search: 'LAOAUREA',
      name: "Lavatera olbia 'Aurea'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 691,
      chnn_stock_retail: 691,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z8JAKB6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8JAKB6C'
    },
    {
      status: 210,
      sku: 'PM0073742',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265413',
      rtl_batch_array: '6265413',
      name_search: 'ABNORDMA',
      name: 'Abies nordmanniana',
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1K6R4BA8;VPYAG8LK;PJR5HV7Z;R6DRL44W',
      statusContent: 'dot',
      hash: 'PV9CA288',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PV9CA288'
    },
    {
      status: 210,
      sku: 'PM0073734',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265405',
      rtl_batch_array: '6265405',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_prcp: 4.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: 'ZZRB43WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZRB43WV'
    },
    {
      status: 810,
      sku: 'PM0073745',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265416',
      rtl_batch_array: '6265416',
      name_search: 'PISYLVES',
      name: 'Pinus sylvestris',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7L3J9VNA;KX78HJLD;N732DC1A;GGJG12CS;HKS1N5EE;C1SG5VDN;2BV6TH7P',
      statusContent: 'dot',
      hash: 'RTR33SZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTR33SZS'
    },
    {
      status: 810,
      sku: 'PM0073751',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265424',
      rtl_batch_array: '6265424',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'KW2NAJBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW2NAJBZ'
    },
    {
      status: 210,
      sku: 'PM0028281',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300228',
      rtl_batch_array: '6300228, 6310366',
      name_search: 'ROGLAUCA',
      name: 'Rosa glauca',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1415,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NYKPD5HW;B3EP6CPR',
      statusContent: 'dot',
      hash: 'E2T469RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2T469RZ'
    },
    {
      status: 210,
      sku: 'PM0073758',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300189',
      rtl_batch_array: '6300189',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 4620,
      chnn_stock_retail: 4620,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'ERE25BHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERE25BHC'
    },
    {
      status: 210,
      sku: 'PM0073769',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265447',
      rtl_batch_array: '6265447',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2316,
      chnn_stock_retail: 2316,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: '6CVVZP47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CVVZP47'
    },
    {
      status: 210,
      sku: 'PM0072873',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '6282378',
      rtl_batch_array: '6282378, 6287448',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 588,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'BWRWREA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWRWREA6'
    },
    {
      status: 210,
      sku: 'PM0073032',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258689',
      rtl_batch_array: '6258689',
      name_search: 'CEBSAPPH',
      name: "Ceanothus 'Blue Sapphire'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TBRXXBNK;7GRB9ND8;TLV39CYC;RXKW117W;ELCHGLSK;23G9XXPG;DTR1AX8E;742P6YJH;CE6NVPRH',
      statusContent: 'dot',
      hash: 'GCGT6163',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCGT6163'
    },
    {
      status: 210,
      sku: 'PM0073768',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265446',
      rtl_batch_array: '6265446',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1468,
      chnn_stock_retail: 1468,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'YPW3ZXWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YPW3ZXWZ'
    },
    {
      status: 210,
      sku: 'PM0080833',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338218',
      rtl_batch_array: '6338218',
      name_search: 'LARRUM',
      name: "Lavatera 'Red Rum'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1218,
      chnn_stock_retail: 1218,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XY2W8XGB;K9K1NV61;N1E99LZW;7L1AHH8A;XGWZVLWB;VZ2AJLC5;8V97JXVS;6BEST7L1;VACY2TWC;HKSDBBCR;6WLG5799;9LPCD7RK;RCRNRHB7',
      statusContent: 'dot',
      hash: '8TXK78CV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TXK78CV'
    },
    {
      status: 210,
      sku: 'PM0073284',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350740',
      rtl_batch_array: '6350740',
      name_search: 'PAVCSKY',
      name: "Panicum virgatum 'Cheyenne Sky'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRTPHAKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRTPHAKE'
    },
    {
      status: 810,
      sku: 'PM0073740',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265411',
      rtl_batch_array: '6265411',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 5.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'TVG4231T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVG4231T'
    },
    {
      status: 210,
      sku: 'PM0073741',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265412',
      rtl_batch_array: '6265412',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_prcp: 5.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: 'PN27AVDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PN27AVDP'
    },
    {
      status: 210,
      sku: 'PM0073744',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265415',
      rtl_batch_array: '6265415',
      name_search: 'PIMMUGO',
      name: 'Pinus mugo mugo',
      sppl_size_code: '020025C5',
      rng_range_identifier: 'H020025C5',
      rng_range_description: 'H20 cm 25 cm C5',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_prcp: 5.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9GG4KDS;73SK7VXS;RX1VN826;KACTN4L2;VKBXE59R;XXY6H6G1;APVEEYW6;N2D8TJWT;BL789YDN;VJ3EE9JE;AYAJHEE8;KESAWX76;YXHNAT4J;KREZV1TS;1VRRKBX5;9PB9YL98;T7J256B1;5C8211W8;9TVLW851',
      statusContent: 'dot',
      hash: 'XWJ7CCAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWJ7CCAN'
    },
    {
      status: 210,
      sku: 'PM0073747',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6265419',
      rtl_batch_array: '6265419, 6278304',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 423,
      chnn_stock_retail: 523,
      sppl_prcp: 5.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'A3GE7V9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3GE7V9K'
    },
    {
      status: 210,
      sku: 'PM0073558',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260686',
      rtl_batch_array: '6260686',
      name_search: 'GEENDRES',
      name: 'Geranium endressii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1365,
      chnn_stock_retail: 1365,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JT3LCTRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT3LCTRY'
    },
    {
      status: 210,
      sku: 'PM0073033',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258690',
      rtl_batch_array: '6258690',
      name_search: 'CEBSAPPH',
      name: "Ceanothus 'Blue Sapphire'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 958,
      chnn_stock_retail: 958,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TBRXXBNK;7GRB9ND8;TLV39CYC;RXKW117W;ELCHGLSK;23G9XXPG;DTR1AX8E;742P6YJH;CE6NVPRH',
      statusContent: 'dot',
      hash: '6RBGK254',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RBGK254'
    },
    {
      status: 210,
      sku: 'PM0073034',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258691',
      rtl_batch_array: '6258691',
      name_search: 'CEEBROWN',
      name: "Ceanothus 'Emily Brown'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8H8T3DHZ;133B5ZJX;STJY4696;RCNHZGGZ;9EYHPT6K;Z8XZK9C7',
      statusContent: 'dot',
      hash: 'DHN5D6JE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHN5D6JE'
    },
    {
      status: 210,
      sku: 'PM0073035',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258692',
      rtl_batch_array: '6258692',
      name_search: 'CEEBROWN',
      name: "Ceanothus 'Emily Brown'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 969,
      chnn_stock_retail: 969,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8H8T3DHZ;133B5ZJX;STJY4696;RCNHZGGZ;9EYHPT6K;Z8XZK9C7',
      statusContent: 'dot',
      hash: 'V88EJCE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V88EJCE5'
    },
    {
      status: 210,
      sku: 'PM0073036',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258693',
      rtl_batch_array: '6258693',
      name_search: 'CEGYPOIN',
      name: "Ceanothus griseus 'Yankee Point'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 995,
      chnn_stock_retail: 995,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5WNGL5X9;B2RSV8WC;GJRSTXRT',
      statusContent: 'dot',
      hash: '9LD67G7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LD67G7J'
    },
    {
      status: 210,
      sku: 'PM0073038',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258695',
      rtl_batch_array: '6258695',
      name_search: 'CEICBLUE',
      name: "Ceanothus impressus 'Cool Blue'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCY24YA3;AXDTTS4H;RG6VSKDH;97C13KLC;636XTD52;2REEE2J4;E7J98241',
      statusContent: 'dot',
      hash: 'VZEL2HBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZEL2HBB'
    },
    {
      status: 210,
      sku: 'PM0073039',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258696',
      rtl_batch_array: '6258696',
      name_search: 'CEICBLUE',
      name: "Ceanothus impressus 'Cool Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCY24YA3;AXDTTS4H;RG6VSKDH;97C13KLC;636XTD52;2REEE2J4;E7J98241',
      statusContent: 'dot',
      hash: 'BZ1VH2CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ1VH2CX'
    },
    {
      status: 210,
      sku: 'PM0073040',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258697',
      rtl_batch_array: '6258697',
      name_search: 'CEANOTHU',
      name: 'Ceanothus',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 14.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTBN8SH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTBN8SH7'
    },
    {
      status: 210,
      sku: 'PM0073041',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258698',
      rtl_batch_array: '6258698',
      name_search: 'CEANOTHU',
      name: 'Ceanothus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AK4LWN4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AK4LWN4J'
    },
    {
      status: 210,
      sku: 'PM0073043',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258700',
      rtl_batch_array: '6258700',
      name_search: 'CEJPHELP',
      name: "Ceanothus 'Julia Phelps'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2RWBRHK4',
      statusContent: 'dot',
      hash: 'CBB966LY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBB966LY'
    },
    {
      status: 210,
      sku: 'PM0073045',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258702',
      rtl_batch_array: '6258702',
      name_search: 'CEEDORAD',
      name: 'Ceanothus El Dorado',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GA8NSV6Y;21NXWEXH;2K9YD2ZP;KVDY4PLY;V184987Y;HC24YLNA;2GXHJKYH',
      statusContent: 'dot',
      hash: 'CDGKEABB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDGKEABB'
    },
    {
      status: 210,
      sku: 'PM0073046',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258703',
      rtl_batch_array: '6258703',
      name_search: 'CEEDORAD',
      name: 'Ceanothus El Dorado',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 940,
      chnn_stock_retail: 940,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GA8NSV6Y;21NXWEXH;2K9YD2ZP;KVDY4PLY;V184987Y;HC24YLNA;2GXHJKYH',
      statusContent: 'dot',
      hash: 'Y5ZDSD3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5ZDSD3R'
    },
    {
      status: 210,
      sku: 'PM0073047',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258704',
      rtl_batch_array: '6258704',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: 'S7TT388C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7TT388C'
    },
    {
      status: 210,
      sku: 'PM0073048',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6310209',
      rtl_batch_array: '6258705, 6310209, 6385815',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 4200,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: 'SZ98VL35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZ98VL35'
    },
    {
      status: 210,
      sku: 'PM0073050',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258707',
      rtl_batch_array: '6258707',
      name_search: 'CERGRIFF',
      name: 'Ceratostigma griffithii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2020,
      chnn_stock_retail: 2020,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2BGB6RL;WAEP3Y4H;ESNTJ345;EVC23VT3;48KCNG4W',
      statusContent: 'dot',
      hash: '7BVBPX7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BVBPX7G'
    },
    {
      status: 210,
      sku: 'PM0073560',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260688',
      rtl_batch_array: '6260688',
      name_search: 'GEMACROR',
      name: 'Geranium macrorrhizum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1274,
      chnn_stock_retail: 1274,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LZ3CZETS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ3CZETS'
    },
    {
      status: 210,
      sku: 'PM0073044',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258701',
      rtl_batch_array: '6258701',
      name_search: 'CEPMSIMO',
      name: "Ceanothus pallidus 'Marie Simon'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 732,
      chnn_stock_retail: 732,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAHPJKYX;E4937RK4;SXG491X3;BLYASDEK',
      statusContent: 'dot',
      hash: '9RDY6E44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RDY6E44'
    },
    {
      status: 210,
      sku: 'PM0073053',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258710',
      rtl_batch_array: '6258710',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1708,
      chnn_stock_retail: 1708,
      sppl_prcp: 4.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: '5A658ERN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5A658ERN'
    },
    {
      status: 210,
      sku: 'PM0073759',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267684',
      rtl_batch_array: '6267684',
      name_search: 'DESCABRA',
      name: 'Deutzia scabra',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 1.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJBTE6SV',
      statusContent: 'dot',
      hash: '23JHXRY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23JHXRY5'
    },
    {
      status: 210,
      sku: 'PM0073055',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258712',
      rtl_batch_array: '6258712',
      name_search: 'CIPULVER',
      name: 'Cistus pulverulentus',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 396,
      chnn_stock_retail: 396,
      sppl_prcp: 2.975,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AS8DASAA;L5YND3GK;VTDVVGPX;L8XPVE4P;RZ73ATHD;NVJRKLS8',
      statusContent: 'dot',
      hash: 'ZYBY48WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYBY48WB'
    },
    {
      status: 210,
      sku: 'PM0080816',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338201',
      rtl_batch_array: '6338201',
      name_search: 'LAIBLUSH',
      name: 'Lagerstroemia indica Blush',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LNBVWTAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNBVWTAE'
    },
    {
      status: 210,
      sku: 'PM0073057',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258716',
      rtl_batch_array: '6258716',
      name_search: 'COSCITAR',
      name: "Cortaderia selloana 'Citaro'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 940,
      chnn_stock_retail: 940,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '823G63E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '823G63E7'
    },
    {
      status: 210,
      sku: 'PM0073058',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258717',
      rtl_batch_array: '6258717',
      name_search: 'COSEVITA',
      name: "Cortaderia selloana 'Evita'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 895,
      chnn_stock_retail: 895,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z7TDA57C;T47WSAJK;EKVLHT7Z;1RK1H2WZ;C3CCCC52;JN2SW8ZH;SJCR97W9;AEABKN52;KKWS22LG;2LHZW1V4;4XKSWHDS;G5TN6AGK;ASW4WEV7;KERN6VVV;728RVVK5;KVWLS82L;HN6C1K52',
      statusContent: 'dot',
      hash: 'DR4LVRK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR4LVRK7'
    },
    {
      status: 210,
      sku: 'PM0073280',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350583',
      rtl_batch_array: '6350583',
      name_search: 'IRSSFLIG',
      name: "Iris sibirica 'Swan in Flight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 223,
      chnn_stock_retail: 223,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LD837285',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LD837285'
    },
    {
      status: 210,
      sku: 'PM0073281',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350584',
      rtl_batch_array: '6350584',
      name_search: 'IRSTBLUE',
      name: "Iris sibirica 'Tipped in Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZWW4V7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZWW4V7R'
    },
    {
      status: 810,
      sku: 'PM0073282',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350614',
      rtl_batch_array: '6350614',
      name_search: 'KNSPUPPY',
      name: "Kniphofia 'Slush Puppy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VA6PDW4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VA6PDW4S'
    },
    {
      status: 210,
      sku: 'PM0080817',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338202',
      rtl_batch_array: '6338202',
      name_search: 'LAICRED',
      name: 'Lagerstroemia indica Crimson Red',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 725,
      chnn_stock_retail: 725,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AA7HPGK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA7HPGK9'
    },
    {
      status: 210,
      sku: 'PM0073042',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258699',
      rtl_batch_array: '6258699',
      name_search: 'CEJPHELP',
      name: "Ceanothus 'Julia Phelps'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2RWBRHK4',
      statusContent: 'dot',
      hash: 'PTRRWXEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTRRWXEB'
    },
    {
      status: 210,
      sku: 'PM0080834',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338219',
      rtl_batch_array: '6338219',
      name_search: 'LABARNSL',
      name: "Lavatera 'Barnsley'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1263,
      chnn_stock_retail: 1263,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L97DRPYN;AE9DWZBS;68VZZ3CX;SAV8VV3D;L9DYXRKW;T8A77PYA;VGS7W4N5;VCHGD2VB;SYEHZ737;KW1RGGET',
      statusContent: 'dot',
      hash: '6ZGWK6XL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZGWK6XL'
    },
    {
      status: 210,
      sku: 'PM0073623',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263435',
      rtl_batch_array: '6263435',
      name_search: 'GITRIFOL',
      name: 'Gillenia trifoliata',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1482,
      chnn_stock_retail: 1482,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1DTS55L;N1NYVLEJ;LJZG1HAN',
      statusContent: 'dot',
      hash: '4PHN9HE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PHN9HE1'
    },
    {
      status: 210,
      sku: 'PM0073557',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260684',
      rtl_batch_array: '6260684',
      name_search: 'EUMATROP',
      name: "Eupatorium maculatum 'Atropurpureum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 466,
      chnn_stock_retail: 466,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V9H31EY8;JDAR99JZ;7WPG4SVR;EKNXT7NS;J3LH5YA3',
      statusContent: 'dot',
      hash: 'RBVV3T9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBVV3T9P'
    },
    {
      status: 210,
      sku: 'PM0073564',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260695',
      rtl_batch_array: '6260695, 6254728',
      name_search: 'GEBROOKS',
      name: "Geranium 'Brookside'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2041,
      chnn_stock_retail: 2841,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2ZR571V;L1HDCC73',
      statusContent: 'dot',
      hash: '1NVH9JX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NVH9JX4'
    },
    {
      status: 210,
      sku: 'PM0080835',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338220',
      rtl_batch_array: '6338220',
      name_search: 'LABSPRIN',
      name: "Lavatera 'Bredon Springs'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1011,
      chnn_stock_retail: 1011,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NP9221YV;BRS6TWXV;9GYGT59C',
      statusContent: 'dot',
      hash: 'V9RYJZWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9RYJZWR'
    },
    {
      status: 210,
      sku: 'PM0073562',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260690',
      rtl_batch_array: '6260690, 6254735',
      name_search: 'GEMSPESS',
      name: "Geranium macrorrhizum 'Spessart'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 351,
      chnn_stock_retail: 751,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HDAB2S7T;G7REDB73;2ZHA3EEG;6KD5XJXT;6C7EK5RV',
      statusContent: 'dot',
      hash: '67H13LCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67H13LCD'
    },
    {
      status: 210,
      sku: 'PM0073563',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260691',
      rtl_batch_array: '6260691',
      name_search: 'GENODOSU',
      name: 'Geranium nodosum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 727,
      chnn_stock_retail: 727,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XKRXGWGZ;YN7CGPWY;2TZX3DB7',
      statusContent: 'dot',
      hash: 'BR555AEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BR555AEJ'
    },
    {
      status: 210,
      sku: 'PM0073565',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260698',
      rtl_batch_array: '6260698, 6252628',
      name_search: 'HEOBSIDI',
      name: "Heuchera 'Obsidian'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1339,
      chnn_stock_retail: 2816,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '96JEHKY1;BPYSP6GS;G5SS1DS4;GH6L5ZCX;8C6GY8L1;TYZDGLW3;PCGYKY93',
      statusContent: 'dot',
      hash: 'D4SNS27R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4SNS27R'
    },
    {
      status: 910,
      sku: 'PM0080818',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAICRED',
      name: 'Lagerstroemia indica Crimson Red',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      statusContent: 'dot',
      hash: '9316KTP4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9316KTP4'
    },
    {
      status: 210,
      sku: 'PM0080819',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338204',
      rtl_batch_array: '6338204',
      name_search: 'LAILLACE',
      name: 'Lagerstroemia indica Lavender Lace',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1510,
      chnn_stock_retail: 1510,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CGWA5B1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGWA5B1T'
    },
    {
      status: 210,
      sku: 'PM0080820',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338205',
      rtl_batch_array: '6338205',
      name_search: 'LAIMMAGE',
      name: 'Lagerstroemia indica Mystic Magenta',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KGS5C3HL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGS5C3HL'
    },
    {
      status: 210,
      sku: 'PM0080821',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338206',
      rtl_batch_array: '6338206',
      name_search: 'LAIPWHIT',
      name: 'Lagerstroemia indica Pure White',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 294,
      chnn_stock_retail: 294,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N7B1KD7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7B1KD7A'
    },
    {
      status: 210,
      sku: 'PM0073625',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290776',
      rtl_batch_array: '6290776',
      name_search: 'MALSYLVE',
      name: 'Malva sylvestris',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3044,
      chnn_stock_retail: 3044,
      sppl_order_minimum: 3,
      sppl_prcp: 1.774,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72TGBD7Z;WX64X1CP;81N1GJ2G;N13GBC5B;A9LJ7B1R;86SA6EZ9;SEP9KZ81;NJ6WSWE2;CDVGGKED;VCHWXNVB;8HTD89WG;6N3YH7R5;91RN5L24;2A6967PX;9YRB26B9;5CAWR34S;Z24PG9Z8',
      statusContent: 'dot',
      hash: '2TZWAPV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TZWAPV2'
    },
    {
      status: 810,
      sku: 'PM0080822',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338207',
      rtl_batch_array: '6338207',
      name_search: 'LAIPWHIT',
      name: 'Lagerstroemia indica Pure White',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LRVNCEC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRVNCEC9'
    },
    {
      status: 210,
      sku: 'PM0073726',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265397',
      rtl_batch_array: '6265397',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '020030C14',
      rng_range_identifier: 'H020030C1.5',
      rng_range_description: 'H20 cm 30 cm C1.5',
      sppl_stock_available: 792,
      chnn_stock_retail: 792,
      sppl_order_minimum: 3,
      sppl_prcp: 0.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '6NC1TLE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NC1TLE8'
    },
    {
      status: 210,
      sku: 'PM0073727',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265398',
      rtl_batch_array: '6265398',
      name_search: 'SPJNANA',
      name: "Spiraea japonica 'Nana'",
      sppl_size_code: '010015C14',
      rng_range_identifier: 'H010015C1.5',
      rng_range_description: 'H10 cm 15 cm C1.5',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SWJJESBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWJJESBV'
    },
    {
      status: 210,
      sku: 'PM0073754',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265427',
      rtl_batch_array: '6265427',
      name_search: 'COLUCIDU',
      name: 'Cotoneaster lucidus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1971,
      chnn_stock_retail: 1971,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPJW8JTN',
      statusContent: 'dot',
      hash: 'TLBV6YVY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLBV6YVY'
    },
    {
      status: 910,
      sku: 'PM0073757',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: '7L5PSXPE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7L5PSXPE'
    },
    {
      status: 810,
      sku: 'PM0073760',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265434',
      rtl_batch_array: '6265434',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 4.777,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'A6E2PBY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6E2PBY3'
    },
    {
      status: 210,
      sku: 'PM0080823',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338208',
      rtl_batch_array: '6338208',
      name_search: 'LARPINK',
      name: 'Lagerstroemia Rhapsody in Pink',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 605,
      chnn_stock_retail: 605,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PRPRXJR7',
      statusContent: 'dot',
      hash: '4TW5SSHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TW5SSHC'
    },
    {
      status: 210,
      sku: 'PM0080824',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338209',
      rtl_batch_array: '6338209',
      name_search: 'LAISPINK',
      name: 'Lagerstroemia indica Shell Pink',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KLBDV4AV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLBDV4AV'
    },
    {
      status: 210,
      sku: 'PM0073764',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 3,
      btch_active_retail: '6267693',
      rtl_batch_array: '6265441, 6352689, 6267693',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 205,
      chnn_stock_retail: 273,
      sppl_prcp: 3.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'W12S2XJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W12S2XJ5'
    },
    {
      status: 210,
      sku: 'PM0073765',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265442',
      rtl_batch_array: '6265442',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_prcp: 4.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: '4XX3GVSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XX3GVSK'
    },
    {
      status: 210,
      sku: 'PM0073037',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258694',
      rtl_batch_array: '6258694',
      name_search: 'CEGYPOIN',
      name: "Ceanothus griseus 'Yankee Point'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 941,
      chnn_stock_retail: 941,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5WNGL5X9;B2RSV8WC;GJRSTXRT',
      statusContent: 'dot',
      hash: 'H4TYN47D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4TYN47D'
    },
    {
      status: 810,
      sku: 'PM0080825',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338210',
      rtl_batch_array: '6338210',
      name_search: 'LAISPINK',
      name: 'Lagerstroemia indica Shell Pink',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8BJK3J9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BJK3J9D'
    },
    {
      status: 210,
      sku: 'PM0021509',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350056',
      rtl_batch_array: '6350056',
      name_search: 'ECLORANG',
      name: 'Echinacea Lakota Orange',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1652,
      chnn_stock_retail: 1652,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7S53AZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7S53AZ1'
    },
    {
      status: 210,
      sku: 'PM0073626',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263440',
      rtl_batch_array: '6263440',
      name_search: 'NERGROG',
      name: "Nepeta racemosa 'Grog'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PEJGBDDT',
      statusContent: 'dot',
      hash: 'DRHYYA6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRHYYA6X'
    },
    {
      status: 210,
      sku: 'PM0080836',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338221',
      rtl_batch_array: '6338221',
      name_search: 'RHGHELMA',
      name: "Rhododendron groenlandicum 'Helma'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 3.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XNR95PE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNR95PE3'
    },
    {
      status: 810,
      sku: 'PM0075443',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280880',
      rtl_batch_array: '6280880',
      name_search: 'ALJROSEA',
      name: 'Albizia julibrissin rosea',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.973,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BN7A4BYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BN7A4BYH'
    },
    {
      status: 810,
      sku: 'PM0075444',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280882',
      rtl_batch_array: '6280882',
      name_search: 'ALJROSEA',
      name: 'Albizia julibrissin rosea',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.447,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y1N5KYJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1N5KYJ5'
    },
    {
      status: 210,
      sku: 'PM0073559',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260687',
      rtl_batch_array: '6260687, 6252584',
      name_search: 'GEEWPINK',
      name: "Geranium endressii 'Wargrave Pink'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2553,
      chnn_stock_retail: 3793,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TE3GS59A',
      statusContent: 'dot',
      hash: 'N1KYTPLZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1KYTPLZ'
    },
    {
      status: 210,
      sku: 'PM0073561',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260689',
      rtl_batch_array: '6260689',
      name_search: 'GEMIVARI',
      name: "Geranium macrorrhizum 'Ingwersen's Variety'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1244,
      chnn_stock_retail: 1244,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BEHZP13D;1L15ZB4E;5HE2B34B;XTK5BB5W;NH36KGLW;XW4PYGV3;1XD4B9D7;L4BV6YDW;E7XZZ3WL;YEZKBGZS;CZ32WC7J;N76GA3TX;XPPYPY4H;T2ETJVDT;9XSVE9PW;H5WTB4D1;DD9VEP3N',
      statusContent: 'dot',
      hash: 'BETJVD5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BETJVD5Z'
    },
    {
      status: 810,
      sku: 'PM0080831',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338216',
      rtl_batch_array: '6338216',
      name_search: 'LACHAMAL',
      name: 'Lavatera Chamallow',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 81,
      chnn_stock_retail: 81,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D1BC8WC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1BC8WC8'
    },
    {
      status: 210,
      sku: 'PM0080837',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338222',
      rtl_batch_array: '6338222',
      name_search: 'RHGHELMA',
      name: "Rhododendron groenlandicum 'Helma'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1030,
      chnn_stock_retail: 1030,
      sppl_order_minimum: 3,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WDNWY3KN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDNWY3KN'
    },
    {
      status: 210,
      sku: 'PM0080838',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338223',
      rtl_batch_array: '6338223',
      name_search: 'LESWCHEE',
      name: "Leptospermum scoparium 'Winter Cheer'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_order_minimum: 3,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7BGBDS92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BGBDS92'
    },
    {
      status: 210,
      sku: 'PM0080839',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338224',
      rtl_batch_array: '6338224',
      name_search: 'LETHUNBE',
      name: 'Lespedeza thunbergii',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GN5WZDAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GN5WZDAN'
    },
    {
      status: 210,
      sku: 'PM0080840',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338226',
      rtl_batch_array: '6338226',
      name_search: 'LEFRAINB',
      name: "Leucothoe fontanesiana 'Rainbow'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 6612,
      chnn_stock_retail: 6612,
      sppl_order_minimum: 5,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1H5P3ZSD;CCB5LRLZ;SJS31JLE;SE71TG9R;8LE8AELD;ZEZ3P78W;253XTPC7;48TWELTH;S87WRHT4;6BYV1J6X;K7HNJ6G5;HHP3PN72;2C8EJXR8;6H77HESP;5GX4KEH4;9CSW38ZJ;GJK122WL;HWHWALRR',
      statusContent: 'dot',
      hash: 'E6BHXK11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6BHXK11'
    },
    {
      status: 210,
      sku: 'PM0080841',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338227',
      rtl_batch_array: '6338227',
      name_search: 'LEKRRUBY',
      name: "Leucothoe keiskei 'Royal Ruby'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 710,
      chnn_stock_retail: 710,
      sppl_order_minimum: 5,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DELW2ZC;9Z8CRP4D;JZ7LKYEC;28Z6ES5R;VT2R8L9A;5VW35V25;4W719N2W;DB9TBNA6;VAD78P86',
      statusContent: 'dot',
      hash: 'E3RB3AVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3RB3AVL'
    },
    {
      status: 210,
      sku: 'PM0080842',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338228',
      rtl_batch_array: '6338228',
      name_search: 'LELOVITA',
      name: 'Leucothoe Lovita',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JKC5TBCS',
      statusContent: 'dot',
      hash: 'EPRNYP2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPRNYP2Y'
    },
    {
      status: 210,
      sku: 'PM0080843',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338229',
      rtl_batch_array: '6338229',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3319,
      chnn_stock_retail: 3319,
      sppl_order_minimum: 5,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: '5X1RBZP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X1RBZP7'
    },
    {
      status: 810,
      sku: 'PM0080844',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338230',
      rtl_batch_array: '6338230',
      name_search: 'LEFGLANT',
      name: 'Leycesteria formosa Golden Lanterns',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '63KA91RN',
      statusContent: 'dot',
      hash: 'RSH8ZXSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSH8ZXSG'
    },
    {
      status: 210,
      sku: 'PM0080845',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338231',
      rtl_batch_array: '6338231',
      name_search: 'LEFPRAIN',
      name: "Leycesteria formosa 'Purple Rain'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 614,
      chnn_stock_retail: 614,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSX1YWBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSX1YWBH'
    },
    {
      status: 210,
      sku: 'PM0080846',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338232',
      rtl_batch_array: '6338232',
      name_search: 'LIPGLEAF',
      name: "Libertia peregrinans 'Gold Leaf'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R69AG5E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R69AG5E1'
    },
    {
      status: 810,
      sku: 'PM0080847',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338233',
      rtl_batch_array: '6338233',
      name_search: 'LITSUNSE',
      name: "Libertia 'Taupo Sunset'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RAZYPTW2;WNY84VYP;D1N7GG87;AY1BW8L6;ZKSCAHDY;K5YNWWPS;1L6G33VB;ES4R8E1S;RWSL1A19;LP8GELVD;54A4Y77C',
      statusContent: 'dot',
      hash: '4DP1951N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DP1951N'
    },
    {
      status: 210,
      sku: 'PM0080848',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338234',
      rtl_batch_array: '6338234',
      name_search: 'LIIMUSLI',
      name: 'Ligustrum ibota Musli',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KNZKC43W;TBWV2J7E',
      statusContent: 'dot',
      hash: 'LLRKRKCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLRKRKCX'
    },
    {
      status: 210,
      sku: 'PM0080849',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338235',
      rtl_batch_array: '6338235',
      name_search: 'LIJCOLOM',
      name: "Ligustrum japonicum 'Colombine'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 3.118,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AH1AEC6Z',
      statusContent: 'dot',
      hash: 'TV27BPJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TV27BPJD'
    },
    {
      status: 210,
      sku: 'PM0080850',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338236',
      rtl_batch_array: '6338236',
      name_search: 'LIJROTUN',
      name: "Ligustrum japonicum 'Rotundifolium'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 768,
      chnn_stock_retail: 768,
      sppl_order_minimum: 3,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4Z838WZC;6S3YA3XV',
      statusContent: 'dot',
      hash: 'JK3XW2H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JK3XW2H9'
    },
    {
      status: 210,
      sku: 'PM0080851',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338237',
      rtl_batch_array: '6338237',
      name_search: 'LIOLLIME',
      name: "Ligustrum ovalifolium 'Lemon and Lime'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 819,
      chnn_stock_retail: 819,
      sppl_order_minimum: 5,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TAPCTVWT;ETXPPPAR;EH9D9G4J;KZ1S1KR3;23L4WR91;L33N54HZ;8ET57K59;H7567GBS;WAJX11VR;WDKN2REL;J4KLWTST;P3N4X3XB;SWD9LXGY;AKKWB2AY;7G235TZA;73G9685V;1TYYT511',
      statusContent: 'dot',
      hash: '7NXVHL5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NXVHL5K'
    },
    {
      status: 210,
      sku: 'PM0080852',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338238',
      rtl_batch_array: '6338238',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'G8K7DV88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8K7DV88'
    },
    {
      status: 210,
      sku: 'PM0080853',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338240',
      rtl_batch_array: '6338240',
      name_search: 'LIOARGEN',
      name: "Ligustrum ovalifolium 'Argenteum'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2179,
      chnn_stock_retail: 2179,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4C5HDB7;XR5WLHL7;W3VY8LT1;9W1BJELE;XKBG3Z1X',
      statusContent: 'dot',
      hash: 'RYBWCAHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYBWCAHP'
    },
    {
      status: 210,
      sku: 'PM0080854',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338241',
      rtl_batch_array: '5503632, 6338241',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1800,
      chnn_stock_retail: 2300,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: '8LBVG2G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LBVG2G5'
    },
    {
      status: 210,
      sku: 'PM0080855',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338243',
      rtl_batch_array: '6338243',
      name_search: 'LIVICARY',
      name: "Ligustrum 'Vicaryi'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 570,
      chnn_stock_retail: 570,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P6ZKWXYG;T4LTAWVG;LB2DG7DS',
      statusContent: 'dot',
      hash: 'XHYSD98C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHYSD98C'
    },
    {
      status: 210,
      sku: 'PM0080856',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338244',
      rtl_batch_array: '6338244',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1610,
      chnn_stock_retail: 1610,
      sppl_prcp: 1.828,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'GDKP1GDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDKP1GDV'
    },
    {
      status: 210,
      sku: 'PM0083011',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356118',
      rtl_batch_array: '6356118',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'SLCV7WZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLCV7WZJ'
    },
    {
      status: 910,
      sku: 'PM0014347',
      core_name: 'Plant',
      sppl_ean: '8720626367210',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431811',
      rtl_batch_array: '5431811',
      name_search: 'RHMFKNMA',
      name: "Rhododendron makinoi 'Fuju-kaku-no-matsu'",
      sppl_size_code: '020025C5',
      rng_range_identifier: 'H020025C5',
      rng_range_description: 'H20 cm 25 cm C5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 7.214,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '994XSXYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '994XSXYX'
    },
    {
      status: 210,
      sku: 'PM0083012',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356119',
      rtl_batch_array: '6356119',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: '8LHRYZ15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LHRYZ15'
    },
    {
      status: 810,
      sku: 'PM0000931',
      core_name: 'Plant',
      sppl_ean: '8720626362888',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5600632',
      rtl_batch_array: '5600632',
      name_search: 'PIAINVER',
      name: "Picea abies 'Inversa'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G8HSKC69;WBDJ7DKZ',
      imageBatch: 'NDE9E3J8',
      statusContent: 'dot',
      hash: 'SB8174W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SB8174W9'
    },
    {
      status: 210,
      sku: 'PM0083013',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373396',
      rtl_batch_array: '6373396, 6356120',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 1990,
      chnn_stock_retail: 2210,
      sppl_prcp: 6.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'ENH6AJKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENH6AJKR'
    },
    {
      status: 210,
      sku: 'PM0028832',
      core_name: 'Plant',
      sppl_ean: '8720349456451',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5745653',
      rtl_batch_array: '5745653',
      name_search: 'LEBLIGHT',
      name: 'Leucanthemum (S) Broadway Lights',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 910,
      chnn_stock_retail: 910,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNG5YJGD;G2BPYED2;Y57RJ9N3;X8LZ4GE2;8Y1E1CX2;S346ES3G;5KCLSCCL;959LKJ1A;N6NXX3YN;E94B5W5N;D1T7JCB1',
      imageBatch: 'LZG99LTY',
      statusContent: 'dot',
      hash: 'VXXV6AVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXXV6AVD'
    },
    {
      status: 210,
      sku: 'PM0024315',
      core_name: 'Plant',
      sppl_ean: '8720626342958',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5433252',
      rtl_batch_array: '5433252',
      name_search: 'CRJLCHAM',
      name: "Cryptomeria japonica 'Little Champion'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_prcp: 50.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5RACXHR;2BWLBDNG',
      imageBatch: '66VS3LH6',
      statusContent: 'dot',
      hash: 'RBS9T2YL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBS9T2YL'
    },
    {
      status: 210,
      sku: 'PM0011184',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265433',
      rtl_batch_array: '6265433',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 1.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W7RXADLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7RXADLC'
    },
    {
      status: 210,
      sku: 'PM0083014',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356121',
      rtl_batch_array: '6356121',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_prcp: 8.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'JNKSVR3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNKSVR3J'
    },
    {
      status: 210,
      sku: 'PM0073812',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265494',
      rtl_batch_array: '6265494',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 512,
      chnn_stock_retail: 512,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'A8DKXLEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8DKXLEP'
    },
    {
      status: 210,
      sku: 'PM0001694',
      core_name: 'Plant',
      sppl_ean: '8720664686199',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495594',
      rtl_batch_array: '5495594',
      name_search: 'COCDMAID',
      name: "Cotinus coggygria 'Dusky Maiden'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_prcp: 3.894,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RYL2SSAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYL2SSAY'
    },
    {
      status: 210,
      sku: 'PM0083015',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356123',
      rtl_batch_array: '6356123',
      name_search: 'COPSFIND',
      name: "Cotoneaster procumbens 'Streib's Findling'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y1SABGR;HNWPVZWE',
      statusContent: 'dot',
      hash: 'EDKCLR5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDKCLR5T'
    },
    {
      status: 210,
      sku: 'PM0073771',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265449',
      rtl_batch_array: '6265449',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 5.081,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'KH7K3C1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KH7K3C1J'
    },
    {
      status: 910,
      sku: 'PM0073809',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      rng_range_identifier: 'H060100C5',
      rng_range_description: 'H60 cm 100 cm C5',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: '7KHZ1D72',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7KHZ1D72'
    },
    {
      status: 210,
      sku: 'PM0073772',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265450',
      rtl_batch_array: '6265450',
      name_search: 'JUGREGIA',
      name: 'Juglans regia',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 380,
      chnn_stock_retail: 380,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J3RCV4EW;VYRDZSHN;CCN68RCY;XLXZ2KEG;EA292SLS;5WPSWWNH;216656X2;LLCX97DS;KB1V4E8L;V1ESJ956;P5GBHTWG;ZWZ776H1;DB4HPK1K;1PTTCH1L;N34V5ZG1;PVX29D93;8Z7WNV9L;96P3HJJ2;GDJGRNTW;Z8G85AG7',
      statusContent: 'dot',
      hash: '2LYSC52T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LYSC52T'
    },
    {
      status: 210,
      sku: 'PM0073059',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258718',
      rtl_batch_array: '6258718',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 401,
      chnn_stock_retail: 401,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: 'BE1GVPYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE1GVPYA'
    },
    {
      status: 210,
      sku: 'PM0073285',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353078',
      rtl_batch_array: '6353078',
      name_search: 'PEMRBUNN',
      name: 'Pennisetum massaicum Red BunnyTails',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1317,
      chnn_stock_retail: 1317,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H8A35PK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8A35PK9'
    },
    {
      status: 210,
      sku: 'PM0073291',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353424',
      rtl_batch_array: '6353424',
      name_search: 'SETHUNDE',
      name: "Sedum 'Thundercloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 510,
      chnn_stock_retail: 510,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2L118PZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2L118PZB'
    },
    {
      status: 210,
      sku: 'PM0073060',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258719',
      rtl_batch_array: '6258719',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 762,
      chnn_stock_retail: 762,
      sppl_prcp: 4.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'VZ1JHRTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ1JHRTX'
    },
    {
      status: 210,
      sku: 'PM0073295',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353203',
      rtl_batch_array: '6353203',
      name_search: 'SAAMANTE',
      name: "Salvia 'Amante'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERWCN9E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERWCN9E1'
    },
    {
      status: 210,
      sku: 'PM0080857',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338245',
      rtl_batch_array: '6338245',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 658,
      chnn_stock_retail: 658,
      sppl_prcp: 4.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: 'XD25WR6V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD25WR6V'
    },
    {
      status: 210,
      sku: 'PM0073065',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258725',
      rtl_batch_array: '6258725',
      name_search: 'DECTARDI',
      name: "Deschampsia cespitosa 'Tardiflora'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YE3S38YL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YE3S38YL'
    },
    {
      status: 210,
      sku: 'PM0080858',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338246',
      rtl_batch_array: '6338246',
      name_search: 'LISWORPL',
      name: "Liquidambar styraciflua 'Worplesdon'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 219,
      chnn_stock_retail: 219,
      sppl_prcp: 4.999,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8EGDRGJ6;KXVD9AXA',
      statusContent: 'dot',
      hash: 'KKC9XE6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKC9XE6Y'
    },
    {
      status: 210,
      sku: 'PM0073068',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258728',
      rtl_batch_array: '6258728',
      name_search: 'DIBCASCA',
      name: 'Distylium Blue Cascade',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6N9HTWZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6N9HTWZC'
    },
    {
      status: 810,
      sku: 'PM0080859',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338247',
      rtl_batch_array: '6338247',
      name_search: 'SCHMOONL',
      name: "Schizophragma hydrangeoides 'Moonlight'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LL5SD466;ZT3TVWWS;BVSR98PZ;9NEATXXT;HJRZLLJ9;1YJ4B1V5',
      statusContent: 'dot',
      hash: 'BGPXNDVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGPXNDVN'
    },
    {
      status: 210,
      sku: 'PM0080860',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338248',
      rtl_batch_array: '6338248',
      name_search: 'BRSUNSHI',
      name: "Brachyglottis 'Sunshine'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 659,
      chnn_stock_retail: 659,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJGNTNB2',
      statusContent: 'dot',
      hash: '9DT78113',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DT78113'
    },
    {
      status: 210,
      sku: 'PM0073072',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258734',
      rtl_batch_array: '6258734',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 676,
      chnn_stock_retail: 676,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'YN4X998P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YN4X998P'
    },
    {
      status: 210,
      sku: 'PM0073073',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258735',
      rtl_batch_array: '6258735',
      name_search: 'ESLPELLE',
      name: 'Escallonia laevis Pink Elle',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 10.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WCDTDS2',
      statusContent: 'dot',
      hash: 'W5L2KWL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5L2KWL2'
    },
    {
      status: 210,
      sku: 'PM0080861',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338249',
      rtl_batch_array: '6338249',
      name_search: 'SENECIO',
      name: 'Senecio',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 2680,
      chnn_stock_retail: 2680,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1TCXZ9P9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TCXZ9P9'
    },
    {
      status: 210,
      sku: 'PM0073076',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258740',
      rtl_batch_array: '6258740',
      name_search: 'EUJHAPPI',
      name: "Euonymus japonicus 'Happiness'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2687,
      chnn_stock_retail: 2687,
      sppl_prcp: 3.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNTCLRWN;JCJT2ZR7;E61BDLE5;JSS3Y38Y;5BX75ZSD;8W9SSXK3;RRAB97C7',
      statusContent: 'dot',
      hash: 'C311485C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C311485C'
    },
    {
      status: 210,
      sku: 'PM0073077',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258741',
      rtl_batch_array: '6258741',
      name_search: 'EUJHIMAL',
      name: 'Euonymus japonicus Himalaya',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1235,
      chnn_stock_retail: 1235,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8VD3KWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8VD3KWG'
    },
    {
      status: 210,
      sku: 'PM0080862',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338250',
      rtl_batch_array: '6338250',
      name_search: 'SIRHWINE',
      name: "Sinocalycanthus raul. 'Hartlage wine'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 5.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JY48BWDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY48BWDC'
    },
    {
      status: 210,
      sku: 'PM0080863',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338251',
      rtl_batch_array: '6338251',
      name_search: 'SIRVENUS',
      name: "Sinocalycanthus raul. 'Venus'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_prcp: 5.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8LK6YHCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LK6YHCY'
    },
    {
      status: 210,
      sku: 'PM0073287',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353102',
      rtl_batch_array: '6353102',
      name_search: 'PHVGOGH',
      name: "Phlox (P) 'Van Gogh'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YAEXJZNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAEXJZNY'
    },
    {
      status: 910,
      sku: 'PM0073288',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SECDROP',
      name: "Sedum 'Chocolate Drop'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1BD5E8NN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1BD5E8NN'
    },
    {
      status: 210,
      sku: 'PM0073289',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353294',
      rtl_batch_array: '6353294',
      name_search: 'SEDAZZLE',
      name: "Sedum 'Dazzleberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 366,
      chnn_stock_retail: 366,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XWWHNPB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWWHNPB9'
    },
    {
      status: 210,
      sku: 'PM0080864',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338252',
      rtl_batch_array: '6338252',
      name_search: 'SIXYLOCA',
      name: 'Sinojackia xylocarpa',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBTDS539',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBTDS539'
    },
    {
      status: 210,
      sku: 'PM0080865',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338253',
      rtl_batch_array: '6338253',
      name_search: 'SKCKGREE',
      name: "Skimmia confusa 'Kew Green'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1284,
      chnn_stock_retail: 1284,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RG8DAAKW',
      statusContent: 'dot',
      hash: 'R334CP28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R334CP28'
    },
    {
      status: 210,
      sku: 'PM0073293',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353184',
      rtl_batch_array: '6353184',
      name_search: 'ROOBARBE',
      name: "Rosmarinus officinalis 'Barbecue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KCD6EJ8P',
      statusContent: 'dot',
      hash: 'LS98SP8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LS98SP8T'
    },
    {
      status: 210,
      sku: 'PM0073296',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353204',
      rtl_batch_array: '6353204',
      name_search: 'SAAMBER',
      name: "Salvia 'Amber'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ES5T5K23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ES5T5K23'
    },
    {
      status: 210,
      sku: 'PM0073297',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353207',
      rtl_batch_array: '6301806, 6353207',
      name_search: 'SAEWISH',
      name: "Salvia Ember's Wish",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 323,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KHY1PSVS;2HY4294J;A8S5JZVH;4GLDXSRJ;W2GE4XWS;W1YCZ7NS;WCDC4TVE;BDDECNVA;WR4VLXD2;P2YC1A6B',
      statusContent: 'dot',
      hash: 'Y36YWVK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y36YWVK5'
    },
    {
      status: 210,
      sku: 'PM0080866',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338254',
      rtl_batch_array: '6338254',
      name_search: 'SKCKGREE',
      name: "Skimmia confusa 'Kew Green'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 665,
      chnn_stock_retail: 665,
      sppl_order_minimum: 3,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RG8DAAKW',
      statusContent: 'dot',
      hash: '8GN3TSN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GN3TSN7'
    },
    {
      status: 210,
      sku: 'PM0080867',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338255',
      rtl_batch_array: '6338255',
      name_search: 'SODVARIE',
      name: "Solanum dulcamara 'Variegatum'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KASRNZ74',
      statusContent: 'dot',
      hash: 'XGNBJG75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGNBJG75'
    },
    {
      status: 210,
      sku: 'PM0073566',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260699',
      rtl_batch_array: '6260699',
      name_search: 'HEPARIS',
      name: "Heuchera 'Paris'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 933,
      chnn_stock_retail: 933,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4V1T7ER5',
      statusContent: 'dot',
      hash: 'HJPJ4VLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJPJ4VLA'
    },
    {
      status: 210,
      sku: 'PM0073568',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260703',
      rtl_batch_array: '6260703, 6310408',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1444,
      chnn_stock_retail: 3844,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'KE4RW5WK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE4RW5WK'
    },
    {
      status: 210,
      sku: 'PM0080868',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338256',
      rtl_batch_array: '6338256',
      name_search: 'SOJALBUM',
      name: "Solanum jasminoides 'Album'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7J63ADY;DEPJZP4J;DZZJ245D;JWBVGVY7',
      statusContent: 'dot',
      hash: 'PSVT7L9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSVT7L9W'
    },
    {
      status: 210,
      sku: 'PM0073775',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 3,
      btch_active_retail: '6356676',
      rtl_batch_array: '6311926, 6265453, 6356676',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 490,
      chnn_stock_retail: 1170,
      sppl_prcp: 3.329,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: 'P3PCVCV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3PCVCV2'
    },
    {
      status: 810,
      sku: 'PM0073779',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265457',
      rtl_batch_array: '6265457',
      name_search: 'LONXYLOS',
      name: 'Lonicera xylosteum',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_prcp: 4.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TP72CYT5;DTRPWB64;A1GY4G4Y;5KBTB34C;PHBKGEK1',
      statusContent: 'dot',
      hash: 'XX9JD5NL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX9JD5NL'
    },
    {
      status: 210,
      sku: 'PM0073782',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265461',
      rtl_batch_array: '6265461',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_prcp: 5.027,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'VHWRLZL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHWRLZL4'
    },
    {
      status: 910,
      sku: 'PM0073785',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRPADUS',
      name: 'Prunus padus',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      imageCore: 'KV46D9EZ;T6JL51WS;EZ95SZXZ;GZV81PRD',
      statusContent: 'dot',
      hash: 'Y9Y1NH6A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y9Y1NH6A'
    },
    {
      status: 810,
      sku: 'PM0073791',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265470',
      rtl_batch_array: '6265470',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '100110C5',
      rng_range_identifier: 'H100110C5',
      rng_range_description: 'H100 cm 110 cm C5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 5.653,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'G216JT18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G216JT18'
    },
    {
      status: 210,
      sku: 'PM0073796',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265476',
      rtl_batch_array: '6265476',
      name_search: 'RONITIDA',
      name: 'Rosa nitida',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYPL9EJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYPL9EJL'
    },
    {
      status: 210,
      sku: 'PM0073797',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265478',
      rtl_batch_array: '6265478',
      name_search: 'RORUGOSA',
      name: 'Rosa rugosa',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 314,
      chnn_stock_retail: 314,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YYVTCPJ7;JWELRSGK;G53PXS8P;1KJ6X6PA;G92ZEN4B;97RDXCY3;WAAWHLJR;EP85ETA7',
      statusContent: 'dot',
      hash: 'PS2CNX9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PS2CNX9Y'
    },
    {
      status: 210,
      sku: 'PM0073800',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265481',
      rtl_batch_array: '6265481',
      name_search: 'RORRUBRA',
      name: "Rosa rugosa 'Rubra'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YARDZGEX;AL24KKYY;45G4Y1L3;28PZ8R3K;YL5ANGEW',
      statusContent: 'dot',
      hash: 'SX8D23GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SX8D23GL'
    },
    {
      status: 210,
      sku: 'PM0073801',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265482',
      rtl_batch_array: '6265482',
      name_search: 'ROPIMPIN',
      name: 'Rosa pimpinellifolia',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '486Z1YPP;4V9J4RHE;SABDCKDZ;KC4YCDBT;K27V5R5H',
      statusContent: 'dot',
      hash: 'K875RWWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K875RWWZ'
    },
    {
      status: 810,
      sku: 'PM0073802',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265483',
      rtl_batch_array: '6265483',
      name_search: 'ROPIMPIN',
      name: 'Rosa pimpinellifolia',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '486Z1YPP;4V9J4RHE;SABDCKDZ;KC4YCDBT;K27V5R5H',
      statusContent: 'dot',
      hash: '58B86KJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58B86KJ1'
    },
    {
      status: 210,
      sku: 'PM0073813',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265495',
      rtl_batch_array: '6265495',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_prcp: 4.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JD2KK74X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JD2KK74X'
    },
    {
      status: 210,
      sku: 'PM0073815',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079419',
      rtl_batch_array: '6079419',
      name_search: 'RORALBA',
      name: "Rosa rugosa 'Alba'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 308,
      chnn_stock_retail: 308,
      sppl_prcp: 4.981,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BHRBDLN;2WPARPKY;2E2DYK13',
      statusContent: 'dot',
      hash: '47TV939V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '47TV939V'
    },
    {
      status: 210,
      sku: 'PM0073816',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6170256',
      rtl_batch_array: '6170256',
      name_search: 'COLACTEU',
      name: 'Cotoneaster lacteus',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_prcp: 4.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBLSAC4B;PR1173VV;DPHVETH3;7BA9YC8Y;SLHYXZLL;77D3WDW4',
      statusContent: 'dot',
      hash: '4WWTCDV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WWTCDV5'
    },
    {
      status: 210,
      sku: 'PM0073070',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258730',
      rtl_batch_array: '6258730',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 864,
      chnn_stock_retail: 864,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '5SAEJNGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SAEJNGL'
    },
    {
      status: 210,
      sku: 'PM0073074',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290285',
      rtl_batch_array: '6258737, 6290285',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2200,
      chnn_stock_retail: 2423,
      sppl_prcp: 0.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'E8ZV3A7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8ZV3A7X'
    },
    {
      status: 210,
      sku: 'PM0073078',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5214430',
      rtl_batch_array: '6258742, 5214430',
      name_search: 'EUJPBLAN',
      name: 'Euonymus japonicus Paloma Blanca',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 2483,
      sppl_prcp: 7.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H3R51T6;B7E9D968;L2K9DYN2;RE2CWTBT;RCPWNR7V;TCAXJVBK;HBBA57GJ;S2VJP2XN;CLH92YZR;L4C927H1;9948PKVC;65LE7L44;ATD8AAJC',
      imageBatch: '2X3ZNRA4',
      statusContent: 'dot',
      hash: 'T1C1GHV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1C1GHV8'
    },
    {
      status: 210,
      sku: 'PM0073789',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6265468',
      rtl_batch_array: '6265468',
      name_search: 'RHCATHAR',
      name: 'Rhamnus cathartica',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 5.381,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XS7LHYE;B42JD4B2;LC374DCT;6GBV282T;8HZV1SA1;JD7RRX9A',
      statusContent: 'dot',
      hash: '8GZV72BZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GZV72BZ'
    },
    {
      status: 210,
      sku: 'PM0073567',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260702',
      rtl_batch_array: '6261511, 6260702',
      name_search: 'SPDECUMB',
      name: 'Spiraea decumbens',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 106,
      chnn_stock_retail: 1238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AHYKV86Y',
      statusContent: 'dot',
      hash: 'SCRDEKCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCRDEKCA'
    },
    {
      status: 210,
      sku: 'PM0073569',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260704',
      rtl_batch_array: '6037586, 6260704, 6304907',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 440,
      chnn_stock_retail: 7535,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: '81TRHYGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81TRHYGL'
    },
    {
      status: 210,
      sku: 'PM0073299',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353531',
      rtl_batch_array: '6353531',
      name_search: 'TROGLOBE',
      name: "Trollius 'Orange Globe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PHYBANJB',
      statusContent: 'dot',
      hash: 'HPVDZCYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPVDZCYD'
    },
    {
      status: 210,
      sku: 'PM0073294',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353187',
      rtl_batch_array: '6353187',
      name_search: 'ROOCBLUE',
      name: "Rosmarinus officinalis 'Corsican Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1450,
      chnn_stock_retail: 1450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3WHG76K',
      statusContent: 'dot',
      hash: 'X2EZ21G8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2EZ21G8'
    },
    {
      status: 910,
      sku: 'PM0073292',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SEXENOX',
      name: "Sedum 'Xenox'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'K8G45XTA;KK1ESBPR;Y7GEZA28;D1B8KST8;88LW972W;RZP2GV5X;V2ZD1HLE',
      statusContent: 'dot',
      hash: 'Z9Z9291P',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z9Z9291P'
    },
    {
      status: 210,
      sku: 'PM0073628',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263446',
      rtl_batch_array: '6263446',
      name_search: 'VIMAJOR',
      name: 'Vinca major',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2868,
      chnn_stock_retail: 2868,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9DVAL8E9;LKGVHAZJ;7V854YDB',
      statusContent: 'dot',
      hash: 'K8A9K37L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K8A9K37L'
    },
    {
      status: 810,
      sku: 'PM0075445',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280884',
      rtl_batch_array: '6280884',
      name_search: 'AMAOBELI',
      name: "Amelanchier alnifolia 'Obelisk'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '946XB64B;EYS1BN2S;KL8VZVLJ;NBV7VACV;KPW59Y8G;ARX3247Z;7B9H832V;VJ69G259;Y27NBJP2;VRGV7ED9;J6JTCHE3;H6GG5LPL;WVC3DSJB;TZ686VJY',
      statusContent: 'dot',
      hash: '28EN8174',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28EN8174'
    },
    {
      status: 810,
      sku: 'PM0075446',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280885',
      rtl_batch_array: '6280885',
      name_search: 'AMAOBELI',
      name: "Amelanchier alnifolia 'Obelisk'",
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 65.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '946XB64B;EYS1BN2S;KL8VZVLJ;NBV7VACV;KPW59Y8G;ARX3247Z;7B9H832V;VJ69G259;Y27NBJP2;VRGV7ED9;J6JTCHE3;H6GG5LPL;WVC3DSJB;TZ686VJY',
      statusContent: 'dot',
      hash: 'H3YX8RK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3YX8RK7'
    },
    {
      status: 210,
      sku: 'PM0075449',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300817',
      rtl_batch_array: '6280888, 6300817',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 350,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'E59ZLWWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E59ZLWWB'
    },
    {
      status: 210,
      sku: 'PM0080885',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338276',
      rtl_batch_array: '6338276',
      name_search: 'MACLEOPA',
      name: "Magnolia 'Cleopatra'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_prcp: 6.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NW24S3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NW24S3X'
    },
    {
      status: 210,
      sku: 'PM0083016',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356124',
      rtl_batch_array: '6356124',
      name_search: 'CORRAMI',
      name: "Cotoneaster radicans 'Rami'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRLZ7ZXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRLZ7ZXH'
    },
    {
      status: 210,
      sku: 'PM0074020',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267508',
      rtl_batch_array: '6267508',
      name_search: 'CRJGNANA',
      name: "Cryptomeria japonica 'Globosa Nana'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '19EK1K7W;2N7WG8EX;8S2AWC5Z;DRHNHG2E',
      statusContent: 'dot',
      hash: 'YLGBY1PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLGBY1PE'
    },
    {
      status: 810,
      sku: 'PM0026957',
      core_name: 'Plant',
      sppl_ean: '8720349461424',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582550',
      rtl_batch_array: '5582550',
      name_search: 'FAMURIEL',
      name: 'Fargesia murieliae',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 40.267,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WDRG6KVZ;PK3N994H',
      statusContent: 'dot',
      hash: '92VHPYRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92VHPYRN'
    },
    {
      status: 210,
      sku: 'PM0074021',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267509',
      rtl_batch_array: '6267509',
      name_search: 'CRJLCHAM',
      name: "Cryptomeria japonica 'Little Champion'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5RACXHR;2BWLBDNG',
      statusContent: 'dot',
      hash: 'RTTVXK8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTTVXK8Z'
    },
    {
      status: 210,
      sku: 'PM0074022',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267510',
      rtl_batch_array: '6267510',
      name_search: 'JUCLCARP',
      name: "Juniperus comm. 'Lemon Carpet'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 5.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HPY9NHG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPY9NHG5'
    },
    {
      status: 210,
      sku: 'PM0074023',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267511',
      rtl_batch_array: '6267511',
      name_search: 'PIGBSTAR',
      name: "Picea glauca 'Blue Star'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 20.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ECLARXB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECLARXB8'
    },
    {
      status: 910,
      sku: 'PM0024047',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6011019',
      rtl_batch_array: '6011019',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 1.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'JHKNSHZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHKNSHZH'
    },
    {
      status: 210,
      sku: 'PM0074024',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267512',
      rtl_batch_array: '6267512',
      name_search: 'PIGECHIN',
      name: "Picea glauca 'Echiniformis'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 5.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJC562T8;YBDXH7SH',
      statusContent: 'dot',
      hash: '9X4CBBZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X4CBBZW'
    },
    {
      status: 210,
      sku: 'PM0074025',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267513',
      rtl_batch_array: '6267513',
      name_search: 'PIAJDWHI',
      name: "Picea abies 'J.W. Daisy's White'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_prcp: 5.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PNVZC522;RBV6HT9L;WDN6EZW7;R723278D;RYBZ74CY;X2LCYXDY;E6V7E3PH;78Y2NL49',
      statusContent: 'dot',
      hash: 'PWY4141L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWY4141L'
    },
    {
      status: 210,
      sku: 'PM0074026',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267514',
      rtl_batch_array: '6267514',
      name_search: 'PIGPENDU',
      name: "Picea glauca 'Pendula'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 23.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8HA7T6J2',
      statusContent: 'dot',
      hash: 'J5SJJYHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5SJJYHE'
    },
    {
      status: 210,
      sku: 'PM0073818',
      core_name: 'Plant',
      sppl_ean: '8720626351141',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5394470',
      rtl_batch_array: '5394470',
      name_search: 'HISSTIP',
      name: 'Hibiscus syriacus Sugar Tip',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B66H2PP;A5RALC98',
      imageBatch: 'S51KJHEH',
      statusContent: 'dot',
      hash: 'XZJLT1G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZJLT1G9'
    },
    {
      status: 210,
      sku: 'PM0074027',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267515',
      rtl_batch_array: '6267515',
      name_search: 'PIOEGOLD',
      name: "Picea orientalis 'Early Gold'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_prcp: 20.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B16ZB1RC;H2D7XEEB;NX9TSCDN;2A6CZTG8;LZKHSSD2;WDX1ZWBS;RDBEXGTA;RJRJ9KED;LEXL6XXA;APH47944;YLX7N1X2',
      statusContent: 'dot',
      hash: 'D72TRLBW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D72TRLBW'
    },
    {
      status: 210,
      sku: 'PM0083017',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356125',
      rtl_batch_array: '6356125',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: '9DXEHYVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DXEHYVA'
    },
    {
      status: 210,
      sku: 'PM0074028',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267516',
      rtl_batch_array: '6267516',
      name_search: 'PIOKENWI',
      name: "Picea orientallis 'Kenwith'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 20.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TZLEEPDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZLEEPDV'
    },
    {
      status: 210,
      sku: 'PM0074030',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267518',
      rtl_batch_array: '6267518',
      name_search: 'PIDJKLUI',
      name: "Pinus densiflora 'Jane Kluis'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WN1X4L83;J4BDZ2DV;6XA3T14A;ER8X3B9N;8DS1ZE1G;V7R6H51R',
      statusContent: 'dot',
      hash: 'HJ8493ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJ8493ZD'
    },
    {
      status: 210,
      sku: 'PM0074031',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267519',
      rtl_batch_array: '6267519',
      name_search: 'PIMMARCH',
      name: "Pinus mugo 'March'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 19.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9NHB7AD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NHB7AD6'
    },
    {
      status: 210,
      sku: 'PM0073084',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258749',
      rtl_batch_array: '6258749',
      name_search: 'HEDCFFAV',
      name: "Hedera colchica 'Fall Favourite'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 939,
      chnn_stock_retail: 939,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHC76BAZ;HVLEN2ZV;PDVV9TW9;7WV2LS4E;BX8B2LWB;2RVLX4BK;TLDZB1ZL',
      statusContent: 'dot',
      hash: 'A9VV5BAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9VV5BAC'
    },
    {
      status: 210,
      sku: 'PM0074033',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267521',
      rtl_batch_array: '6267521',
      name_search: 'PIMMOPS',
      name: "Pinus mugo 'Mops'",
      sppl_size_code: '050STC74',
      rng_range_identifier: 'STE050C7.5',
      rng_range_description: 'Stem 50 cm C7.5',
      sppl_stock_available: 986,
      chnn_stock_retail: 986,
      sppl_prcp: 26.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTWG8NA3;HKC34X1D;L8L8Y9VS;S7L7X86R;NBJ4EZ6V;9YHXJY9D;JACEKARS;H8CNE9Y6;A5BG3BR5',
      statusContent: 'dot',
      hash: '949T1WLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '949T1WLV'
    },
    {
      status: 210,
      sku: 'PM0073085',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258750',
      rtl_batch_array: '6258750',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 973,
      chnn_stock_retail: 973,
      sppl_prcp: 11.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'GRT565S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRT565S6'
    },
    {
      status: 210,
      sku: 'PM0073087',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258752',
      rtl_batch_array: '6258752',
      name_search: 'HEHWOERN',
      name: "Hedera helix 'Woerner'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 6,
      sppl_prcp: 3.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X279SEH;RGYYYN9X;JG872GJN;8T9TWZ5J',
      statusContent: 'dot',
      hash: 'TEEJX1SD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TEEJX1SD'
    },
    {
      status: 210,
      sku: 'PM0073304',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6315072',
      rtl_batch_array: '6315072, 6353384',
      name_search: 'THDELAVA',
      name: 'Thalictrum delavayi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 701,
      chnn_stock_retail: 786,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVLK6ZPC',
      statusContent: 'dot',
      hash: 'R2Y2PCJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2Y2PCJP'
    },
    {
      status: 210,
      sku: 'PM0073090',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258756',
      rtl_batch_array: '6258756',
      name_search: 'HIBISCUS',
      name: "Hibiscus in cv's",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 6650,
      chnn_stock_retail: 6650,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5V6VRSZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V6VRSZ6'
    },
    {
      status: 210,
      sku: 'PM0073091',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258757',
      rtl_batch_array: '6258757',
      name_search: 'HISADEWE',
      name: "Hibiscus syriacus 'Admiral Dewey'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9977,
      chnn_stock_retail: 9977,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PYYBWV3Y;BLGADZT7;HG629RTT',
      statusContent: 'dot',
      hash: 'B448V2LG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B448V2LG'
    },
    {
      status: 210,
      sku: 'PM0073092',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258758',
      rtl_batch_array: '6258758',
      name_search: 'HISARDEN',
      name: "Hibiscus syriacus 'Ardens'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9904,
      chnn_stock_retail: 9904,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3RSRH3S;HCN4JG8A;V82GSVV5;6G3GT5K7;TBBTVPBX',
      statusContent: 'dot',
      hash: '4EA28Y5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EA28Y5V'
    },
    {
      status: 210,
      sku: 'PM0073093',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258759',
      rtl_batch_array: '6258759',
      name_search: 'HISDBRAB',
      name: "Hibiscus syriacus 'Duc de Brabant'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9959,
      chnn_stock_retail: 9959,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52PBSE7C;VD2L7JL9;6Z5THXR2;294ZR3EX;RPBNELT7',
      statusContent: 'dot',
      hash: 'SWLWW12Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWLWW12Y'
    },
    {
      status: 210,
      sku: 'PM0073095',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258761',
      rtl_batch_array: '6258761',
      name_search: 'HISLEOPO',
      name: "Hibiscus syriacus 'Leopoldii'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9957,
      chnn_stock_retail: 9957,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GH73T487;ASZSTHP5;ST6X4V7C;7AEKS42Z;J2PWKGS2;TNJ5T452;HEWW7E9D',
      statusContent: 'dot',
      hash: 'NLEJ3CZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLEJ3CZE'
    },
    {
      status: 210,
      sku: 'PM0073098',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338089',
      rtl_batch_array: '6338089',
      name_search: 'HISRHEAR',
      name: "Hibiscus syriacus 'Red Heart'",
      sppl_size_code: 'PLGC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_prcp: 3.709,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ED8W3VK6;HZ16SZ77;GLPYWH8E;G38D2T2L;D5AN7SDA;GTKGW3LE;81SGBESH;9J9T46BE',
      statusContent: 'dot',
      hash: 'CT26D5ZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CT26D5ZX'
    },
    {
      status: 210,
      sku: 'PM0073099',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258765',
      rtl_batch_array: '6258765',
      name_search: 'HISSPECI',
      name: "Hibiscus syriacus 'Speciosus'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9809,
      chnn_stock_retail: 9809,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX32RJHC;T3KHLPVL',
      statusContent: 'dot',
      hash: 'X8ZTW7SJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8ZTW7SJ'
    },
    {
      status: 210,
      sku: 'PM0073101',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258767',
      rtl_batch_array: '6258767',
      name_search: 'HISWOODB',
      name: "Hibiscus syriacus 'Woodbridge'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9662,
      chnn_stock_retail: 9662,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DGW792X2;HC6HX8DW;KC77S6XL;JXN1NRD2;8G9R2V99;21SECRWD;LCHX2V18;52Z5T3TJ;SYETT74P',
      statusContent: 'dot',
      hash: 'VB3575T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VB3575T9'
    },
    {
      status: 210,
      sku: 'PM0073102',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258769',
      rtl_batch_array: '6258769',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 443,
      chnn_stock_retail: 443,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'G2WDSPLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2WDSPLS'
    },
    {
      status: 210,
      sku: 'PM0074047',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267539',
      rtl_batch_array: '6267539',
      name_search: 'AMARENAR',
      name: 'Ammophila arenaria',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YS8LC6TE;8355ZEEA;2T319S8G;1NVK5LRV;CGKAC4EX;E6XSYYAG;RJDRX6AV;VJKBPH51;N3ZC9EY2;DDTWAETH',
      statusContent: 'dot',
      hash: '9GD3RE31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GD3RE31'
    },
    {
      status: 210,
      sku: 'PM0080886',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338277',
      rtl_batch_array: '6338277',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1030,
      chnn_stock_retail: 1030,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'ZJWK6C5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJWK6C5P'
    },
    {
      status: 210,
      sku: 'PM0075450',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 3,
      btch_active_retail: '6311883',
      rtl_batch_array: '6311883, 6280889, 6356074',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 1115,
      chnn_stock_retail: 1375,
      sppl_prcp: 7.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'J5BL84YN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5BL84YN'
    },
    {
      status: 210,
      sku: 'PM0074018',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267506',
      rtl_batch_array: '6267506',
      name_search: 'CHONGRAC',
      name: "Chamaecyparis obtusa 'Nana Gracilis'",
      sppl_size_code: '060STC74',
      rng_range_identifier: 'STE060C7.5',
      rng_range_description: 'Stem 60 cm C7.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1HJET36;XWH728VL;5XNYWHYN;PVNJ4EWY;E1AS7L2X;2J1E1CX4;HPJH6N7P;ZBV2G5VZ;7LB298WC',
      statusContent: 'dot',
      hash: 'TDJBVAR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TDJBVAR9'
    },
    {
      status: 210,
      sku: 'PM0074035',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267525',
      rtl_batch_array: '6267525',
      name_search: 'MAEVERES',
      name: "Malus 'Evereste'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2P443V8J;LDZZ9T2B;P33VCARA;188R4BDT;JWT7ZWKX;6PS2KGG7;7BGLWBWS;249VEA4Y;128LT7GE;91J134EY;C8L8G1XV;LZLHD8LT;JVAVBAV4;9P3HJASK;XJGGJTP7;ZJTBYVPA;JWG5A2CV;5NK5Y24G;S83VC92X;LKE3SWAS;JACCLJ15;DX3K9NPG;GVDX452D;R1XB9B3G;W6Z7J2AN;7HX7JWW8',
      statusContent: 'dot',
      hash: 'JNH8PBZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNH8PBZ7'
    },
    {
      status: 210,
      sku: 'PM0074046',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370811',
      rtl_batch_array: '6267537, 6370811',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1469,
      chnn_stock_retail: 3044,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'TV2WZ4E9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TV2WZ4E9'
    },
    {
      status: 210,
      sku: 'PM0028032',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6209187',
      rtl_batch_array: '6209187, 5849973, 6304772, 6220401',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4980,
      chnn_stock_retail: 7094,
      sppl_prcp: 1.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'CETNV26C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CETNV26C'
    },
    {
      status: 210,
      sku: 'PM0073572',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260710',
      rtl_batch_array: '6260710',
      name_search: 'KAIBSTAR',
      name: "Kalimeris incisa 'Blue Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 274,
      chnn_stock_retail: 274,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1ZBZWXJL;YYWNHXA1',
      statusContent: 'dot',
      hash: 'WCYWBPX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCYWBPX1'
    },
    {
      status: 210,
      sku: 'PM0073819',
      core_name: 'Plant',
      sppl_ean: '8720664668812',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439491',
      rtl_batch_array: '5439491',
      name_search: 'ROMOZART',
      name: "Rosa (H) 'Mozart'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 963,
      chnn_stock_retail: 963,
      sppl_order_minimum: 3,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W2K9HBV3;RRVVE7B9;55KS1A97;KL2PKTKA;5YLZ81DE;BXNG6ZY5;A5B5XPXA',
      statusContent: 'dot',
      hash: 'BA2KYGT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA2KYGT7'
    },
    {
      status: 210,
      sku: 'PM0073829',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266045',
      rtl_batch_array: '6266045',
      name_search: 'PRAVIUM',
      name: 'Prunus avium',
      sppl_size_code: '100120C2',
      rng_range_identifier: 'H100120C2',
      rng_range_description: 'H100 cm 120 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.431,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NVZKRGJ5;KZGWTGZ1;98CXZCCP;L99WV59T;DZ9CAP9E;YV1LLRVA;XNE3RCPK;V2DZGJGB;T5TVEDW1;ATX6TKBX;HJCLGVNA;1BHP4TRH;74HTC5L7',
      statusContent: 'dot',
      hash: 'ZJ7T8HTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJ7T8HTT'
    },
    {
      status: 210,
      sku: 'PM0074043',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267534',
      rtl_batch_array: '6267534',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 972,
      chnn_stock_retail: 972,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: 'S22XC13S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S22XC13S'
    },
    {
      status: 210,
      sku: 'PM0073828',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266044',
      rtl_batch_array: '6266044',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1925,
      chnn_stock_retail: 1925,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: 'PYCHZ6DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYCHZ6DB'
    },
    {
      status: 210,
      sku: 'PM0083018',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356126',
      rtl_batch_array: '6356126',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TSZDV376',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSZDV376'
    },
    {
      status: 210,
      sku: 'PM0073570',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260707',
      rtl_batch_array: '6260707',
      name_search: 'POTNUUK',
      name: "Potentilla tridentata 'Nuuk'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 444,
      chnn_stock_retail: 444,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5VGJ8ZB;K4K9K2E3',
      statusContent: 'dot',
      hash: 'ZK3P42KR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK3P42KR'
    },
    {
      status: 210,
      sku: 'PM0073832',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266048',
      rtl_batch_array: '6266048',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2448,
      chnn_stock_retail: 2448,
      sppl_prcp: 2.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2RV5LBSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RV5LBSK'
    },
    {
      status: 810,
      sku: 'PM0073833',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266049',
      rtl_batch_array: '6266049',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'TZKHLK3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZKHLK3B'
    },
    {
      status: 210,
      sku: 'PM0074042',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267532',
      rtl_batch_array: '6267532',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'HSEPG6N1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSEPG6N1'
    },
    {
      status: 210,
      sku: 'PM0073629',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6263737',
      rtl_batch_array: '6263737',
      name_search: 'COGSCHNI',
      name: "Coreopsis grandiflora 'Schnittgold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7727,
      chnn_stock_retail: 7727,
      sppl_order_minimum: 3,
      sppl_prcp: 1.159,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y7YCH6H3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7YCH6H3'
    },
    {
      status: 210,
      sku: 'PM0073837',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281437',
      rtl_batch_array: '6281437',
      name_search: 'RORALBA',
      name: "Rosa rugosa 'Alba'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 11484,
      chnn_stock_retail: 11484,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BHRBDLN;2WPARPKY;2E2DYK13',
      statusContent: 'dot',
      hash: 'J4AL6G6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4AL6G6Z'
    },
    {
      status: 210,
      sku: 'PM0072879',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6287677',
      rtl_batch_array: '6350204, 6274845, 6287677',
      name_search: 'GEHBBLUE',
      name: "Geranium himalayense 'Baby Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 547,
      sppl_order_minimum: 3,
      sppl_prcp: 0.942,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NRAHD1SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRAHD1SK'
    },
    {
      status: 210,
      sku: 'PM0073573',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260711',
      rtl_batch_array: '6260711',
      name_search: 'KNFLAMEN',
      name: "Kniphofia 'Flamenco'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 328,
      chnn_stock_retail: 328,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YRH9VRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YRH9VRD'
    },
    {
      status: 210,
      sku: 'PM0083019',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356127',
      rtl_batch_array: '6356127',
      name_search: 'EUFDBLAN',
      name: "Euonymus fortunei 'Dart's Blanket'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4V26ZG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4V26ZG7'
    },
    {
      status: 210,
      sku: 'PM0073302',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301855',
      rtl_batch_array: '6301855, 6353455',
      name_search: 'SONISTEE',
      name: "Sorghastrum nutans 'Indian Steel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYGWG52L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYGWG52L'
    },
    {
      status: 910,
      sku: 'PM0073303',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THANNE',
      name: "Thalictrum 'Anne'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'TKZT914A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TKZT914A'
    },
    {
      status: 210,
      sku: 'PM0083020',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356130',
      rtl_batch_array: '6356130',
      name_search: 'EUFHARLE',
      name: "Euonymus fortunei 'Harlequin'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KW2EVKB5;SP7Y5ZWK;SX1DDW1R;JCCEY4LT;A3VB9EVA;HY6AJV5K;CGP52GK8;YKBYTZKC;C9PLV17X;8YNL22WX;Z2CG7A8J;RJD8YAXS;A9KC643C;5V2J3N1E;5AGVBE54;D21HDP9S',
      statusContent: 'dot',
      hash: 'ENB2AHJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENB2AHJE'
    },
    {
      status: 210,
      sku: 'PM0073835',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266052',
      rtl_batch_array: '6266052',
      name_search: 'RIODORAT',
      name: 'Ribes odoratum',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4100,
      chnn_stock_retail: 4100,
      sppl_prcp: 1.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EX2B5AE7',
      statusContent: 'dot',
      hash: '7YPJNGRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YPJNGRC'
    },
    {
      status: 210,
      sku: 'PM0080869',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338257',
      rtl_batch_array: '6338257',
      name_search: 'SOHETERO',
      name: 'Sollya heterophylla',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 665,
      chnn_stock_retail: 665,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H8TGY4C4;9JRGKTKV;175JCCKN;7WALLVPK;5L142D3P;94B126ND',
      statusContent: 'dot',
      hash: 'JT738736',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT738736'
    },
    {
      status: 210,
      sku: 'PM0080870',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338258',
      rtl_batch_array: '6338258',
      name_search: 'SOLLEMOR',
      name: "Solidaster luteus 'Lemore'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ACXPV5N1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACXPV5N1'
    },
    {
      status: 210,
      sku: 'PM0080871',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338259',
      rtl_batch_array: '6338259',
      name_search: 'SOHWBELL',
      name: "Sollya heterophylla 'White Bells'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 365,
      chnn_stock_retail: 365,
      sppl_order_minimum: 3,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALRCPYL1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALRCPYL1'
    },
    {
      status: 210,
      sku: 'PM0080872',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338261',
      rtl_batch_array: '6338261',
      name_search: 'SOSORBIF',
      name: 'Sorbaria sorbifolia',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '65NYEZ6R;L1ZZHYX1;4SN9NN55;ZW4R86BA;29DKHC6Y;WLYHBR5G;1ZT7VZ4D',
      statusContent: 'dot',
      hash: 'CVK91LK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVK91LK5'
    },
    {
      status: 210,
      sku: 'PM0080873',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338262',
      rtl_batch_array: '6338262',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1064,
      chnn_stock_retail: 1064,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: '9J7BJ8B5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J7BJ8B5'
    },
    {
      status: 210,
      sku: 'PM0080874',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338265',
      rtl_batch_array: '6338265',
      name_search: 'LUAPICUL',
      name: 'Luma apiculata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 3,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZC5VRRV;XJ1ASN9L;LCAV8DB4;LV78SKSY;ZAW38J66;J8XD8W95',
      statusContent: 'dot',
      hash: 'EH3XZVST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH3XZVST'
    },
    {
      status: 210,
      sku: 'PM0080875',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338266',
      rtl_batch_array: '6338266',
      name_search: 'LYBARBAR',
      name: 'Lycium barbarum',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_prcp: 2.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S93T58NS;RWKYY7DS;R5XR33ZC;Z9DZTED4;PTYP1957;YASWSH4G;J3DYTLL2;9H7TB9J7;SJVXSBCH;5RS39RLL;RZLEY1EX;779D4K1X;LZHBVABE;YBPAS2E4;7X8XS811;Y882H767;GG8Z3SWK;6ZTE3S7V',
      statusContent: 'dot',
      hash: 'GPWK6GA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPWK6GA8'
    },
    {
      status: 210,
      sku: 'PM0080876',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338267',
      rtl_batch_array: '6338267',
      name_search: 'MABWOODS',
      name: "Magnolia brooklynensis 'Woodsman'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 6.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KWX31T9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KWX31T9B'
    },
    {
      status: 210,
      sku: 'PM0080877',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338268',
      rtl_batch_array: '6338268',
      name_search: 'MABETTY',
      name: "Magnolia 'Betty'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12YLB58;V48KACWP',
      statusContent: 'dot',
      hash: 'XRSDJKSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRSDJKSR'
    },
    {
      status: 210,
      sku: 'PM0080878',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338269',
      rtl_batch_array: '6338269',
      name_search: 'MABBEAUT',
      name: "Magnolia 'Black Beauty'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BH8X4ZT3;TPLVE711;E8XP619T;A8J6YXDA',
      statusContent: 'dot',
      hash: 'T84A8PJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T84A8PJW'
    },
    {
      status: 210,
      sku: 'PM0080879',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338270',
      rtl_batch_array: '6338270',
      name_search: 'MABBEAUT',
      name: "Magnolia 'Black Beauty'",
      sppl_size_code: '040045C2',
      rng_range_identifier: 'H040045C2',
      rng_range_description: 'H40 cm 45 cm C2',
      sppl_stock_available: 1235,
      chnn_stock_retail: 1235,
      sppl_prcp: 6.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BH8X4ZT3;TPLVE711;E8XP619T;A8J6YXDA',
      statusContent: 'dot',
      hash: 'BBY4VWK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBY4VWK5'
    },
    {
      status: 210,
      sku: 'PM0080880',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338271',
      rtl_batch_array: '6338271',
      name_search: 'MABTULIP',
      name: 'Magnolia Black Tulip',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1XKGNX9',
      statusContent: 'dot',
      hash: 'TTAXH1X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTAXH1X2'
    },
    {
      status: 210,
      sku: 'PM0080881',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338272',
      rtl_batch_array: '6338272',
      name_search: 'MABTULIP',
      name: 'Magnolia Black Tulip',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1790,
      chnn_stock_retail: 1790,
      sppl_prcp: 6.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1XKGNX9',
      statusContent: 'dot',
      hash: '5811Z92V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5811Z92V'
    },
    {
      status: 210,
      sku: 'PM0074016',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267504',
      rtl_batch_array: '6267504',
      name_search: 'CHOAUROR',
      name: "Chamaecyparis obtusa 'Aurora'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 957,
      chnn_stock_retail: 957,
      sppl_prcp: 15.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '12S83YCJ;1CN6P9AA;XSW5D71P',
      statusContent: 'dot',
      hash: '77C89WZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77C89WZ8'
    },
    {
      status: 210,
      sku: 'PM0074017',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267505',
      rtl_batch_array: '6267505',
      name_search: 'CHONGRAC',
      name: "Chamaecyparis obtusa 'Nana Gracilis'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 946,
      chnn_stock_retail: 946,
      sppl_prcp: 17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1HJET36;XWH728VL;5XNYWHYN;PVNJ4EWY;E1AS7L2X;2J1E1CX4;HPJH6N7P;ZBV2G5VZ;7LB298WC',
      statusContent: 'dot',
      hash: 'YRRY6KSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRRY6KSS'
    },
    {
      status: 210,
      sku: 'PM0074019',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267507',
      rtl_batch_array: '6267507',
      name_search: 'CRJDINGE',
      name: "Cryptomeria japonica 'Dinger'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GCSJ59SZ;T72HWGJ7;YZHNEZ92;CV7VEYZ8;8RY6D9NE;PS2AEPCR',
      statusContent: 'dot',
      hash: 'JGS8WXBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGS8WXBX'
    },
    {
      status: 210,
      sku: 'PM0074036',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267526',
      rtl_batch_array: '6267526',
      name_search: 'MAGHORNE',
      name: "Malus 'Golden Hornet'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RK6TDYAE;AR7PKN6Y;H74E86ST;BEAE2EAE;CRL7VHVP;RAE699ET;7DHGN8PL;EE12TTL1;KP9NAGDZ;LZ1P1CTS;6R2ZA1TX;X3E36XGW;17157LSZ;7DX131B6;ZSA4B3P7;XXG3WVBX;E6E2XE7K;SKXW5VRD',
      statusContent: 'dot',
      hash: 'BAWY4CZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAWY4CZ9'
    },
    {
      status: 210,
      sku: 'PM0074038',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267528',
      rtl_batch_array: '6267528',
      name_search: 'PEOJDWME',
      name: 'Pennisetum orientale JS Dance With Me',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 706,
      chnn_stock_retail: 706,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XKPXW3BB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKPXW3BB'
    },
    {
      status: 210,
      sku: 'PM0074039',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267529',
      rtl_batch_array: '6267529',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 12.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5GS583P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GS583P4'
    },
    {
      status: 210,
      sku: 'PM0073082',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258746',
      rtl_batch_array: '6258746',
      name_search: 'FOIMIKAD',
      name: 'Forsythia intermedia Mikador',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 224,
      chnn_stock_retail: 224,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K16T8L6D;RVNNHZ49;R9KYJR7V;T4XPXDBS;L5RXPDY4',
      statusContent: 'dot',
      hash: 'SEE6A33X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEE6A33X'
    },
    {
      status: 210,
      sku: 'PM0074040',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267530',
      rtl_batch_array: '6267530',
      name_search: 'PRACCOLA',
      name: "Prunus 'Accolade'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7KA9LHAG;DG7HKKPD;DXDJCE1H;ZLHC5725;GXCS6JRP;WTH29SHL;2CSJH51Y;8H3XXEWL;9N7J6LXS;LH7KNDRS;RZTA411N;69Y2BE6G;WGSP366P;696T71YD;CHA9K61K;KN157JTV;8HWTT9G6;ZTXBZD1V;7WYZBXSL;VB9JY6BN',
      statusContent: 'dot',
      hash: '73LPYGH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73LPYGH1'
    },
    {
      status: 210,
      sku: 'PM0074041',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267531',
      rtl_batch_array: '6267531',
      name_search: 'PRCCPOIN',
      name: 'Prunus cerasifera Crimson Pointe',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P84L8N61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P84L8N61'
    },
    {
      status: 210,
      sku: 'PM0074045',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267536',
      rtl_batch_array: '6267536',
      name_search: 'RHZAUBER',
      name: "Rhododendron 'Zauberfloete'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_prcp: 16.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6DPBWXLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DPBWXLK'
    },
    {
      status: 210,
      sku: 'PM0074048',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267541',
      rtl_batch_array: '6267541',
      name_search: 'BEMPARKJ',
      name: "Berberis media 'Parkjuweel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DNS4ZEN7;DXHPLBP2;H19D93RE;LP5A84HC;TRSEJG36;P7WBZTA4',
      statusContent: 'dot',
      hash: 'CV2CLHYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV2CLHYT'
    },
    {
      status: 210,
      sku: 'PM0074049',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267542',
      rtl_batch_array: '6267542',
      name_search: 'BEMRJEWE',
      name: "Berberis media 'Red Jewel'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8WAGC5LT;1WVY1XRX;WL69ZE7J;DZB73H4D;KAYVB6TH',
      statusContent: 'dot',
      hash: '6K4KXLY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6K4KXLY7'
    },
    {
      status: 810,
      sku: 'PM0074050',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267544',
      rtl_batch_array: '6267544',
      name_search: 'BETBAGAT',
      name: "Berberis thunbergii 'Bagatelle'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4PC3C2Y5;C3LT5LTN;8A6KLBZ3;AEGHYBW8;WHRW677G;VTV35A8S;R2PYGCZ6',
      statusContent: 'dot',
      hash: 'N68LV327',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N68LV327'
    },
    {
      status: 210,
      sku: 'PM0073096',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258762',
      rtl_batch_array: '6258762',
      name_search: 'HISMARIN',
      name: "Hibiscus syriacus 'Marina'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9520,
      chnn_stock_retail: 9520,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69LD5BA3;4KY15DD1;J439RCSN;4WL845Y7;KCL3SGY8;8ARYR5SP;2GE451DS;945JC67P',
      statusContent: 'dot',
      hash: 'Y4ZRX1LH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4ZRX1LH'
    },
    {
      status: 210,
      sku: 'PM0073097',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258763',
      rtl_batch_array: '6258763',
      name_search: 'HISOBLEU',
      name: "Hibiscus syriacus 'Oiseau Bleu'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9454,
      chnn_stock_retail: 9454,
      sppl_prcp: 4.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLS6B2VD;T9G74VLT;G886HN24;E3ZTJG2L;7JR472KD',
      statusContent: 'dot',
      hash: 'SH18W6E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SH18W6E1'
    },
    {
      status: 210,
      sku: 'PM0074037',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267527',
      rtl_batch_array: '6267527',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 8.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: '98RL6RN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98RL6RN3'
    },
    {
      status: 210,
      sku: 'PM0073825',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269844',
      rtl_batch_array: '6269844',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: 'N13NYJCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N13NYJCK'
    },
    {
      status: 210,
      sku: 'PM0080882',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338273',
      rtl_batch_array: '6338273',
      name_search: 'MASCAMEO',
      name: "Magnolia soulangeana 'Cameo'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '62GWLTGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62GWLTGP'
    },
    {
      status: 210,
      sku: 'PM0073083',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258748',
      rtl_batch_array: '6258748',
      name_search: 'GEROZANN',
      name: 'Geranium Rozanne',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 852,
      chnn_stock_retail: 852,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZPJ4SN3;GT4DVXXL;C24JN3BT;XBYX425W;RNV4HWHL;JJ79CY8L;Z3PSHXBT;RRSTZT5E;L3WB9GNP;RB8ELNE5;XDL56ESV;BVLK6EXK;J1XBHEEE',
      statusContent: 'dot',
      hash: 'W5X6T44R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5X6T44R'
    },
    {
      status: 210,
      sku: 'PM0073086',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258751',
      rtl_batch_array: '6258751',
      name_search: 'HEHGLACI',
      name: "Hedera helix 'Glacier'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 759,
      chnn_stock_retail: 759,
      sppl_order_minimum: 6,
      sppl_prcp: 3.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YNKZE78G;ST39J6WN;1AK4BT6L;KJ6P7ESR;743J88XA;EZL4Y25P;ZH8ZXXVK;H45XV8VC',
      statusContent: 'dot',
      hash: 'J5EGJ86A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5EGJ86A'
    },
    {
      status: 210,
      sku: 'PM0073571',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260709',
      rtl_batch_array: '6260709',
      name_search: 'TYLATIFO',
      name: 'Typha latifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 399,
      chnn_stock_retail: 399,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H3KZWY33;1PXN25LG;2P4WZSEX;WVGA79BZ;ZTA2G7R5',
      statusContent: 'dot',
      hash: '24NRNS7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24NRNS7A'
    },
    {
      status: 210,
      sku: 'PM0073821',
      core_name: 'Plant',
      sppl_ean: '8720353031163',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6017102',
      rtl_batch_array: '6017102',
      name_search: 'ROLDREAM',
      name: 'Rosa (H) Lavender Dream',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 845,
      chnn_stock_retail: 845,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BA7PA1BK;D56BEHCN;GZWKCXV3;E8R7XVW1;CYP4DDD8;YCYXY1GX;HGPBBWPD;KE3S1344',
      statusContent: 'dot',
      hash: 'RW5SA715',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW5SA715'
    },
    {
      status: 210,
      sku: 'PM0073822',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6086681',
      rtl_batch_array: '6086681',
      name_search: 'RORMEER',
      name: "Rosa (R) 'Rotes Meer'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 4435,
      chnn_stock_retail: 4435,
      sppl_order_minimum: 3,
      sppl_prcp: 1.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NW2XP8NC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW2XP8NC'
    },
    {
      status: 210,
      sku: 'PM0080883',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338274',
      rtl_batch_array: '6338274',
      name_search: 'MASCAMEO',
      name: "Magnolia soulangeana 'Cameo'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1155,
      chnn_stock_retail: 1155,
      sppl_prcp: 6.719,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '49LZL6LY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49LZL6LY'
    },
    {
      status: 210,
      sku: 'PM0073823',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6086682',
      rtl_batch_array: '6086682',
      name_search: 'ROSCHNEE',
      name: 'Rosa (R) Schneekoppe',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 4581,
      chnn_stock_retail: 4581,
      sppl_order_minimum: 3,
      sppl_prcp: 1.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9L8KWXH7;84W51ADJ;K3N4ZDXV;YDXEPYW5;JDJVW8YR',
      statusContent: 'dot',
      hash: 'WTPT2H7K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTPT2H7K'
    },
    {
      status: 210,
      sku: 'PM0073820',
      core_name: 'Plant',
      sppl_ean: '8720353023588',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5577516',
      rtl_batch_array: '5577516',
      name_search: 'ROSPKOUT',
      name: 'Rosa (H) Pink Knock Out',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CEGBRDA7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CEGBRDA7'
    },
    {
      status: 810,
      sku: 'PM0080884',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338275',
      rtl_batch_array: '6338275',
      name_search: 'MACLEOPA',
      name: "Magnolia 'Cleopatra'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DH4N7DAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH4N7DAC'
    },
    {
      status: 210,
      sku: 'PM0080887',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338278',
      rtl_batch_array: '6338278',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      sppl_size_code: '035040C2',
      rng_range_identifier: 'H035040C2',
      rng_range_description: 'H35 cm 40 cm C2',
      sppl_stock_available: 2110,
      chnn_stock_retail: 2110,
      sppl_prcp: 6.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'VJS9EAE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJS9EAE5'
    },
    {
      status: 810,
      sku: 'PM0080888',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338279',
      rtl_batch_array: '6338279',
      name_search: 'MAMFAN',
      name: "Magnolia 'Manchu Fan'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L9VLHGD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9VLHGD6'
    },
    {
      status: 210,
      sku: 'PM0080889',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338280',
      rtl_batch_array: '6338280',
      name_search: 'MASSATIS',
      name: "Magnolia soulangeana 'Satisfaction'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 682,
      chnn_stock_retail: 682,
      sppl_prcp: 6.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '25V2EEDW;CLSP67PN',
      statusContent: 'dot',
      hash: '658XPVDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '658XPVDW'
    },
    {
      status: 210,
      sku: 'PM0080890',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338281',
      rtl_batch_array: '6338281',
      name_search: 'MASSATIS',
      name: "Magnolia soulangeana 'Satisfaction'",
      sppl_size_code: '035040C2',
      rng_range_identifier: 'H035040C2',
      rng_range_description: 'H35 cm 40 cm C2',
      sppl_stock_available: 2925,
      chnn_stock_retail: 2925,
      sppl_prcp: 6.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '25V2EEDW;CLSP67PN',
      statusContent: 'dot',
      hash: 'YYASACSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYASACSP'
    },
    {
      status: 210,
      sku: 'PM0080891',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338283',
      rtl_batch_array: '6338283',
      name_search: 'MAYLANTE',
      name: "Magnolia 'Yellow Lantern'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 619,
      chnn_stock_retail: 619,
      sppl_prcp: 6.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EG53571D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EG53571D'
    },
    {
      status: 210,
      sku: 'PM0080892',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338284',
      rtl_batch_array: '6338284',
      name_search: 'MAYLANTE',
      name: "Magnolia 'Yellow Lantern'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1920,
      chnn_stock_retail: 1920,
      sppl_order_minimum: 3,
      sppl_prcp: 6.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7VRBAJW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VRBAJW2'
    },
    {
      status: 910,
      sku: 'PM0080893',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGDNANT',
      name: "Magnolia grandiflora 'Double Nantais'",
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      statusContent: 'dot',
      hash: 'BT6Z45Y2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BT6Z45Y2'
    },
    {
      status: 210,
      sku: 'PM0083021',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356131',
      rtl_batch_array: '6356131',
      name_search: 'EUFVEGET',
      name: "Euonymus fortunei 'Vegetus'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TPJ4C6NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPJ4C6NS'
    },
    {
      status: 210,
      sku: 'PM0083022',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356132',
      rtl_batch_array: '6356132',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: '020025C13',
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      sppl_stock_available: 1750,
      chnn_stock_retail: 1750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'ZW9WJTYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZW9WJTYA'
    },
    {
      status: 210,
      sku: 'PM0083023',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356133',
      rtl_batch_array: '6356133',
      name_search: 'EUJJHUGU',
      name: "Euonymus japonicus 'Jean Hugues'",
      sppl_size_code: '020025C13',
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S22A7VLY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S22A7VLY'
    },
    {
      status: 210,
      sku: 'PM0083024',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370591',
      rtl_batch_array: '6370591, 6356134',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 500,
      sppl_prcp: 5.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'ESEZHJD7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESEZHJD7'
    },
    {
      status: 210,
      sku: 'PM0083025',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356135',
      rtl_batch_array: '6356135',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_prcp: 8.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'CWRPSXPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWRPSXPD'
    },
    {
      status: 810,
      sku: 'PM0083026',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356136',
      rtl_batch_array: '6356136',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'JB6PGRDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB6PGRDG'
    },
    {
      status: 210,
      sku: 'PM0083027',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356137',
      rtl_batch_array: '6356137',
      name_search: 'FORMOR',
      name: "Forsythia Mar\u00e9e d'Or",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CWWEG2LL;EP63V2WJ;9GDRPBV1;49BEL117;C7GG6XKL;YYGHARGE;SR8KP4X3;DJZKVSZG',
      statusContent: 'dot',
      hash: 'G44H74L3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G44H74L3'
    },
    {
      status: 210,
      sku: 'PM0083028',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356138',
      rtl_batch_array: '6356138',
      name_search: 'FORMOR',
      name: "Forsythia Mar\u00e9e d'Or",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CWWEG2LL;EP63V2WJ;9GDRPBV1;49BEL117;C7GG6XKL;YYGHARGE;SR8KP4X3;DJZKVSZG',
      statusContent: 'dot',
      hash: 'ASY7AGS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASY7AGS4'
    },
    {
      status: 210,
      sku: 'PM0083029',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356139',
      rtl_batch_array: '6356139',
      name_search: 'HEDCFFAV',
      name: "Hedera colchica 'Fall Favourite'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WHC76BAZ;HVLEN2ZV;PDVV9TW9;7WV2LS4E;BX8B2LWB;2RVLX4BK;TLDZB1ZL',
      statusContent: 'dot',
      hash: 'EGKTLNGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGKTLNGP'
    },
    {
      status: 210,
      sku: 'PM0020006',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353317',
      rtl_batch_array: '6353317, 6301811',
      name_search: 'SANPBEAU',
      name: "Salvia nemorosa 'Pink Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 314,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '54Z63BAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54Z63BAP'
    },
    {
      status: 210,
      sku: 'PM0074072',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267680',
      rtl_batch_array: '6267680, 6302269',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 863,
      chnn_stock_retail: 963,
      sppl_prcp: 3.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'X8GBR3XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8GBR3XV'
    },
    {
      status: 210,
      sku: 'PM0019539',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6385806',
      rtl_batch_array: '5495565, 6310190, 6385806',
      name_search: 'BUDBBEAU',
      name: "Buddleja davidii 'Border Beauty'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 819,
      chnn_stock_retail: 7555,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5WBRTCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5WBRTCL'
    },
    {
      status: 210,
      sku: 'PM0017821',
      core_name: 'Plant',
      sppl_ean: '8720664655263',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5444232',
      rtl_batch_array: '5444232',
      name_search: 'WAGEOIDE',
      name: 'Waldsteinia geoides',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 914,
      chnn_stock_retail: 914,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WE5JJN2V;YEAJD4D6;PDAHE6GH',
      statusContent: 'dot',
      hash: 'T439G66X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T439G66X'
    },
    {
      status: 210,
      sku: 'PM0023582',
      core_name: 'Plant',
      sppl_ean: '8720626377691',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '5606418',
      rtl_batch_array: '5420679, 5606418',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 744,
      chnn_stock_retail: 1242,
      sppl_prcp: 5.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: '4XXBXJRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XXBXJRL'
    },
    {
      status: 210,
      sku: 'PM0080909',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338302',
      rtl_batch_array: '6338302',
      name_search: 'MAMWSUN',
      name: "Mahonia media 'Winter Sun'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2BBW8GXV;RPZR1885;B2S9GWN2;ZPCDY8JT;ZSSYKGDS;GLBBNLPJ;LZXLZRXN',
      statusContent: 'dot',
      hash: 'CARXBAAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CARXBAAZ'
    },
    {
      status: 210,
      sku: 'PM0074058',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267553',
      rtl_batch_array: '6267553',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 976,
      chnn_stock_retail: 976,
      sppl_prcp: 12.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'KVN6VXV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVN6VXV9'
    },
    {
      status: 210,
      sku: 'PM0074059',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267554',
      rtl_batch_array: '6267554',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 7.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'P5Z91V17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5Z91V17'
    },
    {
      status: 210,
      sku: 'PM0080910',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338303',
      rtl_batch_array: '6338303',
      name_search: 'MACABARE',
      name: "Mahonia 'Cabaret'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1248,
      chnn_stock_retail: 1248,
      sppl_prcp: 4.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BVTCWANG;G21RB5Y4;BD5Y2CHV',
      statusContent: 'dot',
      hash: '883SVSHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '883SVSHZ'
    },
    {
      status: 810,
      sku: 'PM0006874',
      core_name: 'Plant',
      sppl_ean: '8720664694101',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '5496613',
      rtl_batch_array: '5496614, 5496613',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 48,
      chnn_stock_retail: 98,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: 'C5ASP4KL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5ASP4KL'
    },
    {
      status: 210,
      sku: 'PM0074060',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267555',
      rtl_batch_array: '6267555',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 991,
      chnn_stock_retail: 991,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'L8VE3TCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8VE3TCZ'
    },
    {
      status: 210,
      sku: 'PM0080911',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338304',
      rtl_batch_array: '6338304',
      name_search: 'NADAUREA',
      name: "Nandina domestica 'Aurea'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9CXRJ9YV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CXRJ9YV'
    },
    {
      status: 210,
      sku: 'PM0080912',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338305',
      rtl_batch_array: '6338305',
      name_search: 'NADBPINK',
      name: 'Nandina domestica Blush Pink',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1445,
      chnn_stock_retail: 1445,
      sppl_order_minimum: 3,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D9ZWNXGA;T5XJZBGR;E9VZGB8B;LHJ4JWYD;A2E84DZB;GRAGJBYL;7VVEAEHA;RYNYRYLN',
      statusContent: 'dot',
      hash: 'W52P8NCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W52P8NCL'
    },
    {
      status: 210,
      sku: 'PM0021781',
      core_name: 'Plant',
      btch_manufacturer: 1910,
      rtl_batch_array_total: 1,
      btch_active_retail: '6177924',
      rtl_batch_array: '6177924',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '100125C14',
      rng_range_identifier: 'H100125C1.5',
      rng_range_description: 'H100 cm 125 cm C1.5',
      sppl_stock_available: 30000,
      chnn_stock_retail: 30000,
      sppl_prcp: 1.772,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: '9W2XDBKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9W2XDBKR'
    },
    {
      status: 210,
      sku: 'PM0073107',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258775',
      rtl_batch_array: '6258775',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 8.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: '6A9465X7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A9465X7'
    },
    {
      status: 210,
      sku: 'PM0074069',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267564',
      rtl_batch_array: '6267564',
      name_search: 'HYQBURGU',
      name: "Hydrangea quercifolia 'Burgundy'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 953,
      chnn_stock_retail: 953,
      sppl_prcp: 3.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X9TGWZ5Z;L8EGZ88J;1AP1ZZRZ;6K3LHE89',
      statusContent: 'dot',
      hash: 'XG6TZHK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG6TZHK6'
    },
    {
      status: 210,
      sku: 'PM0009347',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304770',
      rtl_batch_array: '6304770, 5423420',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 6785,
      chnn_stock_retail: 6885,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'YD2XAGHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD2XAGHW'
    },
    {
      status: 210,
      sku: 'PM0074061',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267556',
      rtl_batch_array: '6267556',
      name_search: 'ESIVEYI',
      name: "Escallonia 'Iveyi'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 974,
      chnn_stock_retail: 974,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBWCL9PR;BHV9S7WZ;PZSG7SKR;LGJVDGY9;T7A7Y6GN',
      statusContent: 'dot',
      hash: 'CR8CLWR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR8CLWR6'
    },
    {
      status: 210,
      sku: 'PM0074062',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267557',
      rtl_batch_array: '6267557',
      name_search: 'FICBTURK',
      name: "Ficus carica 'Brown Turkey'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 101,
      chnn_stock_retail: 101,
      sppl_prcp: 4.932,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DH7T8VNW;5YPR5G21;6H61HPED;4T289JRE;XN7L64H9;64GH657S;SDHBXYBS',
      statusContent: 'dot',
      hash: 'SK42SE1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SK42SE1P'
    },
    {
      status: 210,
      sku: 'PM0074063',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267558',
      rtl_batch_array: '6267558',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 1747,
      chnn_stock_retail: 1747,
      sppl_prcp: 3.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: '9HDKZD3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HDKZD3X'
    },
    {
      status: 210,
      sku: 'PM0000221',
      core_name: 'Plant',
      sppl_ean: '8720353003306',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4762602',
      rtl_batch_array: '4762602',
      name_search: 'MIGIGANT',
      name: 'Miscanthus giganteus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 464,
      chnn_stock_retail: 464,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XX3H731W;WDGZWWW7;T9CGN2Z4;X7N65LYX;S9DNA3Y7;PXRA3199;GWVVS1KD;77KRS8YS',
      imageBatch: 'NCW15Z2R',
      statusContent: 'dot',
      hash: '9X55KRSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X55KRSA'
    },
    {
      status: 210,
      sku: 'PM0074064',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267559',
      rtl_batch_array: '6267559',
      name_search: 'GRLITTOR',
      name: 'Griselinia littoralis',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'STYAHC45;NLA1EY63;PHXW78VN;NYKR71JV;R5BVEB9X;GP34GEWA;9GCVSGSL;JT5NKVY9;R42BK62V',
      statusContent: 'dot',
      hash: 'RV6KY311',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV6KY311'
    },
    {
      status: 210,
      sku: 'PM0074065',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267560',
      rtl_batch_array: '6267560',
      name_search: 'HEAGMARE',
      name: "Hedera algeriensis 'Gloire de Marengo'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 6,
      sppl_prcp: 3.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'REA555VX;AWYR38ZD;2S2AX7BR;R5VCW52D;PGPE1Z29;WYCBSHR9',
      statusContent: 'dot',
      hash: 'XPATY1DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPATY1DA'
    },
    {
      status: 210,
      sku: 'PM0017507',
      core_name: 'Plant',
      sppl_ean: '8720664885905',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133691',
      rtl_batch_array: '6133691',
      name_search: 'SEOREGAN',
      name: 'Sedum oreganum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 157,
      chnn_stock_retail: 157,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HEW6DZZV;GTBDYRGV;9SRCG26Z;4CJRR5C7;DGW81VKS;WREV6DYP;21C89E93;YCYWLZHL;91DBHC62',
      statusContent: 'dot',
      hash: 'G22TSVBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G22TSVBB'
    },
    {
      status: 210,
      sku: 'PM0073103',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258771',
      rtl_batch_array: '6258771',
      name_search: 'HYMBLAUM',
      name: "Hydrangea macrophylla 'Blaumeise'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 8.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GZZX7N8;ESE927KP;KYN2CXA5;AT3DLTTN;B7H86E48;4SSHW2B7;8JBJAJDX;NLW6SNC5;YWT46E3S;HK21GA6R;CNK7H6AX;GGNAZBCJ;HDNLVN2C',
      statusContent: 'dot',
      hash: 'REKDX36S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REKDX36S'
    },
    {
      status: 210,
      sku: 'PM0073104',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258772',
      rtl_batch_array: '6258772',
      name_search: 'HYMBROSE',
      name: "Hydrangea macrophylla 'Bouquet Rose'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_prcp: 8.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5Z2YJRRC;P78BX6NS;LANGZ9X3;BENTGHE6;8XEGA6JH;SGTLBJC7;Z673GDYL',
      statusContent: 'dot',
      hash: '8XDS7B3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XDS7B3L'
    },
    {
      status: 210,
      sku: 'PM0073108',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258776',
      rtl_batch_array: '6258776',
      name_search: 'HYMLMISS',
      name: 'Hypericum moserianum Little Misstery',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 9999,
      chnn_stock_retail: 9999,
      sppl_prcp: 3.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6KA9A151',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KA9A151'
    },
    {
      status: 210,
      sku: 'PM0018517',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353278',
      rtl_batch_array: '6353278, 6352668',
      name_search: 'ROBPEACO',
      name: "Rodgersia 'Bronze Peacock'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B86P2W6N',
      statusContent: 'dot',
      hash: 'GHP3188S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHP3188S'
    },
    {
      status: 210,
      sku: 'PM0083030',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356141',
      rtl_batch_array: '6356141',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 4955,
      chnn_stock_retail: 4955,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'JYNSGB94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYNSGB94'
    },
    {
      status: 210,
      sku: 'PM0074080',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267691',
      rtl_batch_array: '6267691',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 293,
      chnn_stock_retail: 293,
      sppl_prcp: 5.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'A5Z8GELT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5Z8GELT'
    },
    {
      status: 210,
      sku: 'PM0073858',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266081',
      rtl_batch_array: '6266081',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2475,
      chnn_stock_retail: 2475,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: '1VEH7Z3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VEH7Z3G'
    },
    {
      status: 210,
      sku: 'PM0000432',
      core_name: 'Plant',
      sppl_ean: '8720664859692',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084881',
      rtl_batch_array: '6084881, 6370343',
      name_search: 'DIDAVID',
      name: "Dianthus (PL) 'David'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 178,
      chnn_stock_retail: 333,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '428TZVVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '428TZVVC'
    },
    {
      status: 210,
      sku: 'PM0074074',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267683',
      rtl_batch_array: '6267683',
      name_search: 'COCONSPI',
      name: 'Cotoneaster conspicuus',
      sppl_size_code: '025030C1',
      rng_range_identifier: 'H025030C1',
      rng_range_description: 'H25 cm 30 cm C1',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_order_minimum: 3,
      sppl_prcp: 0.761,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61BHAHDW',
      statusContent: 'dot',
      hash: '1L1XVK4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L1XVK4A'
    },
    {
      status: 210,
      sku: 'PM0080913',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338306',
      rtl_batch_array: '6338306',
      name_search: 'NADFLIRT',
      name: 'Nandina domestica Flirt',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1905,
      chnn_stock_retail: 1905,
      sppl_order_minimum: 5,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGKC554N',
      statusContent: 'dot',
      hash: 'G2ETG269',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2ETG269'
    },
    {
      status: 210,
      sku: 'PM0080914',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338307',
      rtl_batch_array: '6338307',
      name_search: 'NADGSTRE',
      name: "Nandina domestica 'Gulf Stream'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3200,
      chnn_stock_retail: 3200,
      sppl_order_minimum: 5,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5177CD65;2ZXX95WG;HXBEY3NS;1KGAWP8J;DKXS5BGB;6D121H4B;VSRDDRHB;KW74VYE1;W3K99X4E;BC74L52W;ECSHV643;ZNS6C38G;A9NSHWK2',
      statusContent: 'dot',
      hash: 'EBVXNALX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBVXNALX'
    },
    {
      status: 810,
      sku: 'PM0080915',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338308',
      rtl_batch_array: '6338308',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 58,
      chnn_stock_retail: 58,
      sppl_order_minimum: 5,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      statusContent: 'dot',
      hash: '7ZCRX84D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZCRX84D'
    },
    {
      status: 210,
      sku: 'PM0073119',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271385',
      rtl_batch_array: '6271385, 6258787',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 267,
      chnn_stock_retail: 1025,
      sppl_prcp: 3.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JH1C2WY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JH1C2WY5'
    },
    {
      status: 210,
      sku: 'PM0074066',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267561',
      rtl_batch_array: '6267561',
      name_search: 'HEHGOLDH',
      name: "Hedera helix 'Goldheart'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_order_minimum: 6,
      sppl_prcp: 3.172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TA51AG38;PL6RE31W;7ZEJB17L;XJVVT79W;ENA9B2ER;VCPL4Z4C;H53YSL6L;4G6SHS57;RWZ69TED;NKZLA5LX;JDK9SZA8',
      statusContent: 'dot',
      hash: 'W4R7KBP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4R7KBP5'
    },
    {
      status: 210,
      sku: 'PM0073120',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258790',
      rtl_batch_array: '6258790',
      name_search: 'HYPMOJIT',
      name: 'Hydrangea paniculata Mojito',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 10.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '74STKJ8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74STKJ8P'
    },
    {
      status: 810,
      sku: 'PM0080894',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338287',
      rtl_batch_array: '6338287',
      name_search: 'MAGEXMOU',
      name: "Magnolia grandiflora 'Exmouth'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B211H35K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B211H35K'
    },
    {
      status: 210,
      sku: 'PM0073306',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307592',
      rtl_batch_array: '6307592',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 4605,
      chnn_stock_retail: 4605,
      sppl_order_minimum: 3,
      sppl_prcp: 2.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: 'K6TW757W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6TW757W'
    },
    {
      status: 210,
      sku: 'PM0073307',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307596',
      rtl_batch_array: '6307596',
      name_search: 'PATVBOSK',
      name: "Parthenocissus tricuspidata 'Veitch Boskoop'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2880,
      chnn_stock_retail: 2880,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W1GAVEZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1GAVEZB'
    },
    {
      status: 910,
      sku: 'PM0073308',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'STE150C5',
      rng_range_description: 'Stem 150 cm C5',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: '91973E97',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '91973E97'
    },
    {
      status: 210,
      sku: 'PM0073309',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6341913',
      rtl_batch_array: '6307697, 6341913',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1810,
      chnn_stock_retail: 1909,
      sppl_prcp: 2.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: '4W8WEYAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4W8WEYAV'
    },
    {
      status: 210,
      sku: 'PM0080895',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338288',
      rtl_batch_array: '6338288',
      name_search: 'MAGFERRU',
      name: "Magnolia grandiflora 'Ferruginea'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_prcp: 6.988,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V62TYV1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V62TYV1Y'
    },
    {
      status: 210,
      sku: 'PM0080896',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338289',
      rtl_batch_array: '6338289',
      name_search: 'MAGLGEM',
      name: "Magnolia grandiflora 'Little Gem'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 3247,
      chnn_stock_retail: 3247,
      sppl_prcp: 7.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J7ZK7YX6;GA1TC5D9',
      statusContent: 'dot',
      hash: 'RCR2B2E9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCR2B2E9'
    },
    {
      status: 210,
      sku: 'PM0080897',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338290',
      rtl_batch_array: '6338290',
      name_search: 'MAWCAVIA',
      name: 'Magnolia White Caviar',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_prcp: 3.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CRYNGTGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRYNGTGS'
    },
    {
      status: 810,
      sku: 'PM0080898',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338291',
      rtl_batch_array: '6338291',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: '2H67N8A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2H67N8A2'
    },
    {
      status: 210,
      sku: 'PM0073576',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260716',
      rtl_batch_array: '6260716',
      name_search: 'FANITIDA',
      name: 'Fargesia nitida',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 10.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RX7LD9EV;W6A24647',
      statusContent: 'dot',
      hash: 'NVHX4RJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVHX4RJP'
    },
    {
      status: 210,
      sku: 'PM0073577',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260717',
      rtl_batch_array: '6260717',
      name_search: 'FASAWOND',
      name: "Fargesia scabrida 'Asian Wonder'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 428,
      chnn_stock_retail: 428,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2Y81GDV;6GHXCB27;Y1AWLJDK;Y973Z946;K9CESGSD;WDETGSX4;WNZL7SS5;CNTHHX7Z;G6X3DALL;KBW7E83A;P63VE7R3',
      statusContent: 'dot',
      hash: 'KCX7667A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCX7667A'
    },
    {
      status: 210,
      sku: 'PM0073578',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260719',
      rtl_batch_array: '6260719',
      name_search: 'BEFTELST',
      name: "Berberis frikartii 'Telstar'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1676,
      chnn_stock_retail: 1676,
      sppl_prcp: 2.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DWRZRD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DWRZRD9'
    },
    {
      status: 210,
      sku: 'PM0026545',
      core_name: 'Plant',
      sppl_ean: '8720664807907',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495498',
      rtl_batch_array: '6301816, 5495498, 6353352',
      name_search: 'SAHPBRUS',
      name: "Sanguisorba hakusanensis 'Pink Brushes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 628,
      chnn_stock_retail: 1238,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1HYJP6NB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HYJP6NB'
    },
    {
      status: 210,
      sku: 'PM0073574',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260714',
      rtl_batch_array: '6260714',
      name_search: 'LISPICAT',
      name: 'Liatris spicata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2319,
      chnn_stock_retail: 2319,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6SSGZ2RA;V6D684P7;G8261HWL;VYWC8C87;5LSYGEHA;JG9Z26S7;LTPGL7BG;LD1W4CCE;R1XZDTCV;8K11Z9JG;T31Y1L61;W98CBH3A;LTGKKT8W;K6ZT3PDP;XYL945S6',
      statusContent: 'dot',
      hash: '92LPW4G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92LPW4G6'
    },
    {
      status: 210,
      sku: 'PM0080916',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338309',
      rtl_batch_array: '6338309',
      name_search: 'NADTWILI',
      name: "Nandina domestica 'Twilight'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1260,
      chnn_stock_retail: 1260,
      sppl_order_minimum: 5,
      sppl_prcp: 3.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WGYXCN6V;SSRVJ5RR;YXZAXPN4;XBXRSJSX;DTK851ES',
      statusContent: 'dot',
      hash: 'H43CSH3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H43CSH3E'
    },
    {
      status: 210,
      sku: 'PM0073630',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349641',
      rtl_batch_array: '6349641, 6293863',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '030C74BOL',
      rng_range_identifier: 'BALL\u00d8030H030030C7.5',
      rng_range_description: 'Ball \u00d8 30 cm H30 cm 30 cm C7.5',
      sppl_stock_available: 684,
      chnn_stock_retail: 1684,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'EE2XPE7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EE2XPE7D'
    },
    {
      status: 210,
      sku: 'PM0073631',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293864',
      rtl_batch_array: '6293864',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '020C3BOL',
      rng_range_identifier: 'BALL\u00d8020H020020C3',
      rng_range_description: 'Ball \u00d8 20 cm H20 cm 20 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'P2ZPKJN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2ZPKJN6'
    },
    {
      status: 210,
      sku: 'PM0073632',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 2,
      btch_active_retail: '6293865',
      rtl_batch_array: '6349640, 6293865',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '025C5BOL',
      rng_range_identifier: 'BALL\u00d8025H025025C5',
      rng_range_description: 'Ball \u00d8 25 cm H25 cm 25 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1960,
      sppl_prcp: 6.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '7G4A154L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7G4A154L'
    },
    {
      status: 210,
      sku: 'PM0073633',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293881',
      rtl_batch_array: '6293881',
      name_search: 'SPDPBBAN',
      name: 'Spiraea Double Play Big Bang',
      sppl_size_code: '030035C10',
      rng_range_identifier: 'H030035C10',
      rng_range_description: 'H30 cm 35 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 11.243,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XJVR5K65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJVR5K65'
    },
    {
      status: 210,
      sku: 'PM0073634',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293891',
      rtl_batch_array: '6293891',
      name_search: 'SPDPBBAN',
      name: 'Spiraea Double Play Big Bang',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWVDK5CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWVDK5CW'
    },
    {
      status: 210,
      sku: 'PM0073635',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293892',
      rtl_batch_array: '6293892',
      name_search: 'SPDPDOOZ',
      name: 'Spiraea Double Play Doozie',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZ4W5EAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ4W5EAE'
    },
    {
      status: 210,
      sku: 'PM0080899',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338292',
      rtl_batch_array: '6338292',
      name_search: 'MASALBA',
      name: "Mandevilla sanderi 'Alba'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1258,
      chnn_stock_retail: 1258,
      sppl_prcp: 6.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1D7V1XBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1D7V1XBS'
    },
    {
      status: 210,
      sku: 'PM0073637',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293893',
      rtl_batch_array: '6293893',
      name_search: 'SPJDPART',
      name: 'Spiraea japonica Double Play Artist',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KEGPK1L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEGPK1L1'
    },
    {
      status: 210,
      sku: 'PM0073638',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293894',
      rtl_batch_array: '6293894',
      name_search: 'SPJDPRED',
      name: 'Spiraea japonica Double Play Red',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.504,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K34KSZK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K34KSZK2'
    },
    {
      status: 210,
      sku: 'PM0080900',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338293',
      rtl_batch_array: '6338293',
      name_search: 'MASALBA',
      name: "Mandevilla sanderi 'Alba'",
      sppl_size_code: '035040C2',
      rng_range_identifier: 'H035040C2',
      rng_range_description: 'H35 cm 40 cm C2',
      sppl_stock_available: 2110,
      chnn_stock_retail: 2110,
      sppl_prcp: 5.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XTLEZVCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTLEZVCW'
    },
    {
      status: 210,
      sku: 'PM0080901',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338294',
      rtl_batch_array: '6338294',
      name_search: 'MASLENNE',
      name: "Magnolia soulangeana 'Lennei'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKDH5E3G',
      statusContent: 'dot',
      hash: 'LP68SZKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LP68SZKH'
    },
    {
      status: 210,
      sku: 'PM0080902',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338295',
      rtl_batch_array: '6338295',
      name_search: 'MASRSTAR',
      name: "Magnolia stellata 'Royal Star'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 304,
      chnn_stock_retail: 304,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W6Y3P8A6',
      statusContent: 'dot',
      hash: 'KA6B192R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KA6B192R'
    },
    {
      status: 210,
      sku: 'PM0080903',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338296',
      rtl_batch_array: '6338296',
      name_search: 'MASWATER',
      name: "Magnolia stellata 'Waterlily'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_prcp: 4.945,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2V1C1PY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V1C1PY1'
    },
    {
      status: 210,
      sku: 'PM0080904',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338297',
      rtl_batch_array: '6338297',
      name_search: 'MAESWINT',
      name: 'Mahonia eurybracteata Sweet Winter',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1180,
      chnn_stock_retail: 1180,
      sppl_prcp: 4.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '91CA58H5;1YW9SV2C;1Y3LER9P;NY3ZCH2C;DG4DP3T5;AGVZ2B3E;SX9JP8CA;1YLLSAR8;YL5W3J87;BYKZ75LB',
      statusContent: 'dot',
      hash: 'YDAW5XN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDAW5XN8'
    },
    {
      status: 210,
      sku: 'PM0080905',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338298',
      rtl_batch_array: '6338298',
      name_search: 'MAESWINT',
      name: 'Mahonia eurybracteata Sweet Winter',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 3220,
      chnn_stock_retail: 3220,
      sppl_order_minimum: 3,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '91CA58H5;1YW9SV2C;1Y3LER9P;NY3ZCH2C;DG4DP3T5;AGVZ2B3E;SX9JP8CA;1YLLSAR8;YL5W3J87;BYKZ75LB',
      statusContent: 'dot',
      hash: '2PGGPZCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PGGPZCK'
    },
    {
      status: 210,
      sku: 'PM0080906',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338299',
      rtl_batch_array: '6338299',
      name_search: 'MAHJAPON',
      name: 'Mahonia japonica',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 925,
      chnn_stock_retail: 925,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X95SGTP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X95SGTP6'
    },
    {
      status: 210,
      sku: 'PM0080907',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338300',
      rtl_batch_array: '6338300',
      name_search: 'MAEURYBR',
      name: 'Mahonia eurybracteata',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2481,
      chnn_stock_retail: 2481,
      sppl_prcp: 6.988,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '92R3AD94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92R3AD94'
    },
    {
      status: 210,
      sku: 'PM0080908',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338301',
      rtl_batch_array: '6338301',
      name_search: 'MAEURYBR',
      name: 'Mahonia eurybracteata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 6786,
      chnn_stock_retail: 6786,
      sppl_order_minimum: 3,
      sppl_prcp: 5.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PC2AH3C1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PC2AH3C1'
    },
    {
      status: 210,
      sku: 'PM0080917',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338310',
      rtl_batch_array: '6338310',
      name_search: 'NEAFFINI',
      name: 'Neillia affinis',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 671,
      chnn_stock_retail: 671,
      sppl_prcp: 2.311,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9V2VV8PJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V2VV8PJ'
    },
    {
      status: 210,
      sku: 'PM0080918',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338311',
      rtl_batch_array: '6338311',
      name_search: 'OLHAASTI',
      name: 'Olearia haastii',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2740,
      chnn_stock_retail: 2740,
      sppl_prcp: 1.881,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5ZBXEPVX',
      statusContent: 'dot',
      hash: 'YGS6ZVXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGS6ZVXZ'
    },
    {
      status: 210,
      sku: 'PM0080919',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338312',
      rtl_batch_array: '6338312',
      name_search: 'OLLEPIDO',
      name: 'Olearia lepidophylla',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '16B93VTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16B93VTC'
    },
    {
      status: 210,
      sku: 'PM0080920',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338313',
      rtl_batch_array: '6338313',
      name_search: 'OLMACROD',
      name: 'Olearia macrodonta',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XL4JYHB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL4JYHB2'
    },
    {
      status: 210,
      sku: 'PM0080921',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338314',
      rtl_batch_array: '6338314',
      name_search: 'OLNUMMUL',
      name: 'Olearia nummulariifolia',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 439,
      chnn_stock_retail: 439,
      sppl_prcp: 3.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPATXSW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPATXSW4'
    },
    {
      status: 210,
      sku: 'PM0080922',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338315',
      rtl_batch_array: '6338315',
      name_search: 'OLNUMMUL',
      name: 'Olearia nummulariifolia',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1025,
      chnn_stock_retail: 1025,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NCPT17P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NCPT17P'
    },
    {
      status: 210,
      sku: 'PM0073872',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6269819',
      rtl_batch_array: '6298560, 6311364, 6269819, 6310450',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1521,
      chnn_stock_retail: 5732,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'WX636XH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WX636XH2'
    },
    {
      status: 210,
      sku: 'PM0073876',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269492',
      rtl_batch_array: '6269492, 6269818',
      name_search: 'WEFNPURP',
      name: "Weigela florida 'Nana Purpurea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 2269,
      sppl_prcp: 1.323,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8E4X3P5K;W26V4REV',
      statusContent: 'dot',
      hash: 'N423BKZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N423BKZ6'
    },
    {
      status: 210,
      sku: 'PM0074052',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267546',
      rtl_batch_array: '6267546',
      name_search: 'BETRROCK',
      name: "Berberis thunbergii 'Red Rocket'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 143,
      chnn_stock_retail: 143,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KVK6L65S;63YDDWBT;TEA43W6W;D7SW1P4V;VHLW9VTT;YG6KZ19W;PHKKJ39D',
      statusContent: 'dot',
      hash: '4K64GCB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K64GCB4'
    },
    {
      status: 210,
      sku: 'PM0074053',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267547',
      rtl_batch_array: '6267547',
      name_search: 'BETRGLOW',
      name: "Berberis thunbergii 'Rose Glow'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYH6719R;1P7TX883;PTDT1JHX;V2X3XNZX;JLXKR2W2;W8JJJ7GR;AJYZXYPK;HJ784XEH;ZVXYN72R',
      statusContent: 'dot',
      hash: 'GP185J77',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GP185J77'
    },
    {
      status: 210,
      sku: 'PM0074054',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267548',
      rtl_batch_array: '6267548',
      name_search: 'CEPOCCID',
      name: 'Cephalanthus occidentalis',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 935,
      chnn_stock_retail: 935,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JGRCX9A5;7W4V6B6W;1ZDJ9ZNS;EWHHG94W;58LTEWWS;XZL5SZHN;V6LT2V6W',
      statusContent: 'dot',
      hash: 'E14E4BZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E14E4BZZ'
    },
    {
      status: 210,
      sku: 'PM0074055',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267550',
      rtl_batch_array: '6267550',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 5.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: '2RYWNHHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RYWNHHP'
    },
    {
      status: 210,
      sku: 'PM0074056',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267551',
      rtl_batch_array: '6267551',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_prcp: 7.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: 'G78J7TXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G78J7TXA'
    },
    {
      status: 210,
      sku: 'PM0074057',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267552',
      rtl_batch_array: '6267552',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 7.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '2L2YV58J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2L2YV58J'
    },
    {
      status: 210,
      sku: 'PM0074068',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271398',
      rtl_batch_array: '6267563, 6271398',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2071,
      chnn_stock_retail: 2837,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: '6SERAPB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SERAPB6'
    },
    {
      status: 210,
      sku: 'PM0074071',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267676',
      rtl_batch_array: '6267676, 6294815',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1686,
      sppl_order_minimum: 3,
      sppl_prcp: 1.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'RAC1D51P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAC1D51P'
    },
    {
      status: 210,
      sku: 'PM0074073',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267681',
      rtl_batch_array: '6267681',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 5.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'XYDR9RS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYDR9RS8'
    },
    {
      status: 210,
      sku: 'PM0074075',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267685',
      rtl_batch_array: '6267685',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XJA7AAWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJA7AAWE'
    },
    {
      status: 210,
      sku: 'PM0073106',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258774',
      rtl_batch_array: '6258774',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 8.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: 'W5RD87DT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5RD87DT'
    },
    {
      status: 210,
      sku: 'PM0073116',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258784',
      rtl_batch_array: '6258784',
      name_search: 'HYPBLACE',
      name: "Hydrangea paniculata 'Brussels Lace'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NEZST4XA;5GC1A65G',
      statusContent: 'dot',
      hash: 'HWDTS2BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWDTS2BN'
    },
    {
      status: 210,
      sku: 'PM0073874',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284495',
      rtl_batch_array: '6298564, 6284495',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1640,
      chnn_stock_retail: 2639,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: 'JZRENWVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZRENWVS'
    },
    {
      status: 210,
      sku: 'PM0073877',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6269820',
      rtl_batch_array: '6311368, 6269820',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 885,
      chnn_stock_retail: 1310,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'NKZA5RZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKZA5RZT'
    },
    {
      status: 210,
      sku: 'PM0023724',
      core_name: 'Plant',
      sppl_ean: '8720626346024',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5418045',
      rtl_batch_array: '5418045',
      name_search: 'EUFBLOND',
      name: 'Euonymus fortunei Blondy',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 3,
      sppl_prcp: 4.43,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8C8Y6YHK;HS11ACLH;THZ8EDAT;LNWY9W9E;BB6AB8HA;YK2ZRG73;1D2LXSL6;PEJGDNHR',
      statusContent: 'dot',
      hash: 'V4331EAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4331EAJ'
    },
    {
      status: 210,
      sku: 'PM0073657',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293896',
      rtl_batch_array: '6293896',
      name_search: 'EUJGSPIR',
      name: "Euonymus japonicus 'Green Spire'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVPJ1B7G;T34NG6BC;WXJCX2NA;VB46N3WX;NJGZ3214;KVRJLB5W;JNAEJ37J;A75LJ53G;9TS9P81R',
      statusContent: 'dot',
      hash: 'DVB3E9CL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVB3E9CL'
    },
    {
      status: 210,
      sku: 'PM0073653',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293890',
      rtl_batch_array: '6293890',
      name_search: 'DEYCBLOS',
      name: 'Deutzia Yuki Cherry Blossom',
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 6.778,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VG49RAW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VG49RAW5'
    },
    {
      status: 210,
      sku: 'PM0073126',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258798',
      rtl_batch_array: '6258798',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 723,
      chnn_stock_retail: 723,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'R6VC866H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6VC866H'
    },
    {
      status: 210,
      sku: 'PM0073316',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254698',
      rtl_batch_array: '6254698',
      name_search: 'DISALBA',
      name: "Dicentra spectabilis 'Alba'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 1.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XB5C666R',
      statusContent: 'dot',
      hash: 'HPKS8BTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPKS8BTX'
    },
    {
      status: 210,
      sku: 'PM0080953',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338349',
      rtl_batch_array: '6338349',
      name_search: 'PHGSWORD',
      name: "Phormium 'Gold Sword'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1035,
      chnn_stock_retail: 1035,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P11LYRB8',
      statusContent: 'dot',
      hash: 'NKNRVN87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKNRVN87'
    },
    {
      status: 210,
      sku: 'PM0080954',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338350',
      rtl_batch_array: '6338350',
      name_search: 'PHJSPRAT',
      name: "Phormium 'Jack Spratt'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_order_minimum: 3,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EHH9AX8N',
      statusContent: 'dot',
      hash: 'XKYR5LX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKYR5LX2'
    },
    {
      status: 210,
      sku: 'PM0015889',
      core_name: 'Plant',
      sppl_ean: '8720664589537',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488236',
      rtl_batch_array: '5488236',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      imageBatch: 'C4THE6DY',
      statusContent: 'dot',
      hash: 'N3LZEVXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3LZEVXS'
    },
    {
      status: 210,
      sku: 'PM0001165',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6260882',
      rtl_batch_array: '6084871, 6260882, 5960147, 6352629',
      name_search: 'CYFORTUN',
      name: 'Cyrtomium fortunei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1892,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BH5579JG',
      statusContent: 'dot',
      hash: 'AVNTTX25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVNTTX25'
    },
    {
      status: 810,
      sku: 'PM0017112',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274778',
      rtl_batch_array: '6274778',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: '93RDDDCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93RDDDCG'
    },
    {
      status: 210,
      sku: 'PM0073658',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293898',
      rtl_batch_array: '6293898',
      name_search: 'DEYSNOWF',
      name: 'Deutzia Yuki Snowflake',
      sppl_size_code: '030035C5',
      rng_range_identifier: 'H030035C5',
      rng_range_description: 'H30 cm 35 cm C5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 6.778,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CVN1K72A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVN1K72A'
    },
    {
      status: 210,
      sku: 'PM0073659',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293901',
      rtl_batch_array: '6293901',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'H9LKNVEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9LKNVEY'
    },
    {
      status: 210,
      sku: 'PM0074082',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267696',
      rtl_batch_array: '6267696',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_prcp: 1.566,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: '954BSK5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '954BSK5D'
    },
    {
      status: 210,
      sku: 'PM0080955',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338351',
      rtl_batch_array: '6338351',
      name_search: 'PHJESTER',
      name: "Phormium 'Jester'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJ1B36C5;VRA44Y2W;96R5L1D3;NHE8K7AD;2GN3648C',
      statusContent: 'dot',
      hash: 'NP3797ZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NP3797ZW'
    },
    {
      status: 210,
      sku: 'PM0080956',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338352',
      rtl_batch_array: '6338352',
      name_search: 'PHPBLACK',
      name: "Phormium 'Platt's Black'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9BD5LV6W',
      statusContent: 'dot',
      hash: 'ZPR5S6N6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPR5S6N6'
    },
    {
      status: 210,
      sku: 'PM0013247',
      core_name: 'Plant',
      sppl_ean: '8720664801769',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495464',
      rtl_batch_array: '5495464',
      name_search: 'PHDCHATT',
      name: "Phlox divaricata 'Chattahoochee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 634,
      chnn_stock_retail: 634,
      sppl_order_minimum: 3,
      sppl_prcp: 0.819,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NVXSSSX4;BVXW92ZN;BHLY8VH1;GGC44TWD;1PRGLVW8;B22S3W4H;5DJBT65J;ZBRX39KK;WZX3R1A6;AL3T9VZR',
      statusContent: 'dot',
      hash: 'P97GGCD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P97GGCD4'
    },
    {
      status: 210,
      sku: 'PM0080923',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338316',
      rtl_batch_array: '6338316',
      name_search: 'OLSCILLO',
      name: 'Olearia scilloniensis',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W3PPCR3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3PPCR3J'
    },
    {
      status: 210,
      sku: 'PM0080924',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338317',
      rtl_batch_array: '6338317',
      name_search: 'OLTRAVER',
      name: 'Olearia traversii',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 775,
      chnn_stock_retail: 775,
      sppl_prcp: 2.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VR9G6KC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VR9G6KC'
    },
    {
      status: 210,
      sku: 'PM0080925',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373527',
      rtl_batch_array: '6373527, 6338318',
      name_search: 'OPPNIGER',
      name: "Ophiopogon planiscapus 'Niger'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 175,
      chnn_stock_retail: 2785,
      sppl_prcp: 2.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25R4ACJC;BH5J4TVA;HTWGYL4C;DHA7PWLC;KT7BJXHS;R6SAL88Y;BZ9Y3Y79;Z78DL5DS;Z172P7HE;GC99JDYB;BPNTXA1P;GLZEYVJ8;Z876JDX3;7VPH93ZT;R8HL3A2P;GY1H5XS5;CSESV7VP;NXHY35VC;TZWK5HPE;PEAVW116;G9J6D69D;GPN8ETTH;X33GJ1S2;W9W7VNPW;WNEZ3V7E;GSKAGG83;6RN1EHK9',
      statusContent: 'dot',
      hash: '7E9T477R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E9T477R'
    },
    {
      status: 210,
      sku: 'PM0080926',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338320',
      rtl_batch_array: '6338320',
      name_search: 'OSDELAVA',
      name: 'Osmanthus delavayi',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3231,
      chnn_stock_retail: 3231,
      sppl_prcp: 3.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '75596XS1',
      statusContent: 'dot',
      hash: '9SXZRDVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SXZRDVK'
    },
    {
      status: 210,
      sku: 'PM0080927',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338321',
      rtl_batch_array: '6338321',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1165,
      chnn_stock_retail: 1165,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'KJCD3Z91',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJCD3Z91'
    },
    {
      status: 210,
      sku: 'PM0080928',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338322',
      rtl_batch_array: '6338322',
      name_search: 'OSHAUREO',
      name: "Osmanthus heterophyllus 'Aureomarginatus'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SXNHZ44D;NXH6A2RA;SELY4LK5;ZXLL6ENJ;EPBE9RCN;EY68TXYY;894RP3BL;C767K5CG;9D7LP45D;BAJZLY41',
      statusContent: 'dot',
      hash: '1H7JBXH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H7JBXH7'
    },
    {
      status: 210,
      sku: 'PM0080929',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338323',
      rtl_batch_array: '6338323',
      name_search: 'OSHOGON',
      name: "Osmanthus heterophyllus 'Ogon'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_prcp: 2.956,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YDRVVZXL',
      statusContent: 'dot',
      hash: '5RCN6CN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RCN6CN4'
    },
    {
      status: 210,
      sku: 'PM0080930',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338324',
      rtl_batch_array: '6338324',
      name_search: 'OSHPURPU',
      name: "Osmanthus heterophyllus 'Purpureus'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCP54XD4;VZH3N8J6',
      statusContent: 'dot',
      hash: 'RKXL1TZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKXL1TZC'
    },
    {
      status: 210,
      sku: 'PM0080931',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338325',
      rtl_batch_array: '6338325',
      name_search: 'OSHGOSHI',
      name: "Osmanthus heterophyllus 'Goshiki'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1871,
      chnn_stock_retail: 1871,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7DT7HZTW;H3611KS5;HHWLDZG5;C4LVH8XZ;P61R6DLC;WVR7T9BV;YD81PWH8;VX335SLL;CNKTN4N8;GX5HBXJ9;NLYT7ZPN;26RLBC7W;D5PTRYSY;Y6LGAB3K;TKLEDCZP;XG83C181;CGDSPS5B;DC98HZPC',
      statusContent: 'dot',
      hash: 'HAJXCRWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAJXCRWJ'
    },
    {
      status: 210,
      sku: 'PM0080932',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338326',
      rtl_batch_array: '6338326',
      name_search: 'OSHVARIE',
      name: "Osmanthus heterophyllus 'Variegatus'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 5,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6V75CZTX;N5SYK8RA;6CGNG5PV;VLAC8KDB;WZ4S93AG;2PK5D4WZ;BWGS5H5R;NZ7EHYNW;S5VJKTYA;N29GETPD',
      statusContent: 'dot',
      hash: 'Z3V63J7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3V63J7G'
    },
    {
      status: 210,
      sku: 'PM0080933',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338327',
      rtl_batch_array: '6338327',
      name_search: 'OZRSJUBI',
      name: "Ozothamnus rosmarinifolius 'Silver Jubilee'",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 865,
      chnn_stock_retail: 865,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6REH348Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6REH348Z'
    },
    {
      status: 810,
      sku: 'PM0080934',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338328',
      rtl_batch_array: '6338328',
      name_search: 'PATGSHEE',
      name: "Pachysandra terminalis 'Green Sheen'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 2.741,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '539CG3DN;7D85AS1B',
      statusContent: 'dot',
      hash: 'NLKXG5L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NLKXG5L1'
    },
    {
      status: 810,
      sku: 'PM0080935',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338329',
      rtl_batch_array: '6338329',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'ZNPXDBZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNPXDBZJ'
    },
    {
      status: 210,
      sku: 'PM0073891',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266123',
      rtl_batch_array: '6266123',
      name_search: 'AMSPICAT',
      name: 'Amelanchier spicata',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SAP4BTJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAP4BTJD'
    },
    {
      status: 810,
      sku: 'PM0026028',
      core_name: 'Plant',
      sppl_ean: '8720349452781',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5446535',
      rtl_batch_array: '5446535',
      name_search: 'HYMROTKE',
      name: "Hydrangea macrophylla 'Rotkehlchen'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ETTGREYR;5CG6K47R;L1B5W7C8;KK6ZACSV;G45WYBKL;1L93NLXB;1XXT79J2;K6A4HY15;P3DVTTPE;KJDAP7JY',
      statusContent: 'dot',
      hash: 'NCGS5755',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCGS5755'
    },
    {
      status: 210,
      sku: 'PM0080936',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338330',
      rtl_batch_array: '6338330',
      name_search: 'PAPPSPIR',
      name: 'Parrotia persica Persian Spire',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 321,
      chnn_stock_retail: 321,
      sppl_prcp: 4.193,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC91A6XY;XR1JSY4C;WRLSCDV9;4CJJKXYX;WZGPTPVX',
      statusContent: 'dot',
      hash: 'CPS9RDYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPS9RDYH'
    },
    {
      status: 210,
      sku: 'PM0080937',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338331',
      rtl_batch_array: '6338331',
      name_search: 'PAPVANES',
      name: "Parrotia persica 'Vanessa'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1468,
      chnn_stock_retail: 1468,
      sppl_prcp: 3.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LB4B7AVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB4B7AVK'
    },
    {
      status: 210,
      sku: 'PM0080938',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338332',
      rtl_batch_array: '6338332',
      name_search: 'PAQUINQU',
      name: 'Parthenocissus quinquefolia',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VKPBZ8T7;8XWKLSL4;YPE8EVVS;SPJ6JGKY;NGL46HJC;VPBAPJ3R;A8Z3VH3L;HCC5VLV6;X5DPJRVA;BE8J8GZY;XD2RH1YV;8PWG3JV1',
      statusContent: 'dot',
      hash: '5G99XW6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G99XW6Y'
    },
    {
      status: 210,
      sku: 'PM0073894',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6266127',
      rtl_batch_array: '6266127, 6267543',
      name_search: 'BETANANA',
      name: "Berberis thunbergii 'Atropurpurea Nana'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1761,
      chnn_stock_retail: 1926,
      sppl_prcp: 1.321,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7P12S5HL;NWR1EDXC;5ACCYXE4;LTY25C6S;GL7N8K52;HHGYNABH;5YBDJVJ8;ZHJDTAJH;W58E1WSW;HL8JH5EW;ZT1LKC66;92LWR7TD;S4EP7YGC;ZLPLDXNR;568PE4YE;ZZPBBNCD;HSB7PL2N;NR8GVSRT',
      statusContent: 'dot',
      hash: '94SHXWHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94SHXWHZ'
    },
    {
      status: 210,
      sku: 'PM0080939',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338333',
      rtl_batch_array: '6338333',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: '9TS2PCDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TS2PCDR'
    },
    {
      status: 210,
      sku: 'PM0080940',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338334',
      rtl_batch_array: '6338334',
      name_search: 'PEBHAZE',
      name: "Perovskia 'Blue Haze'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 4839,
      chnn_stock_retail: 4839,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TXW9HHJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXW9HHJL'
    },
    {
      status: 210,
      sku: 'PM0080941',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338335',
      rtl_batch_array: '6338335',
      name_search: 'PEALBLUE',
      name: 'Perovskia atriplicifolia Lacey Blue',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_prcp: 2.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEJ7ZK7G',
      statusContent: 'dot',
      hash: '9PERW9ZJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PERW9ZJ'
    },
    {
      status: 210,
      sku: 'PM0080942',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338338',
      rtl_batch_array: '6338338',
      name_search: 'PHMSNOWF',
      name: "Philadelphus 'Minnesota Snowflake'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2772,
      chnn_stock_retail: 2772,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJ8AN398',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ8AN398'
    },
    {
      status: 810,
      sku: 'PM0080943',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338339',
      rtl_batch_array: '6338339',
      name_search: 'PHSTARBR',
      name: "Philadelphus 'Starbright'",
      sppl_size_code: '040045C3',
      rng_range_identifier: 'H040045C3',
      rng_range_description: 'H40 cm 45 cm C3',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_prcp: 2.688,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4CNDHRV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CNDHRV9'
    },
    {
      status: 210,
      sku: 'PM0080944',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338340',
      rtl_batch_array: '6338340',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 2150,
      chnn_stock_retail: 2150,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TA5R98WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA5R98WL'
    },
    {
      status: 810,
      sku: 'PM0080945',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338341',
      rtl_batch_array: '6338341',
      name_search: 'PHFRUTIC',
      name: 'Phlomis fruticosa',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DZ34RD2C',
      statusContent: 'dot',
      hash: 'AN5HY46K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AN5HY46K'
    },
    {
      status: 210,
      sku: 'PM0080946',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338342',
      rtl_batch_array: '6338342',
      name_search: 'PHFBOURG',
      name: "Phlomis fruticosa 'Bourgaei'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2SCTSZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2SCTSZL'
    },
    {
      status: 210,
      sku: 'PM0080947',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338343',
      rtl_batch_array: '6338343',
      name_search: 'PHPURPUR',
      name: 'Philadelphus purpurascens',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_prcp: 2.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '413CAR5D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '413CAR5D'
    },
    {
      status: 210,
      sku: 'PM0073123',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258794',
      rtl_batch_array: '6258794',
      name_search: 'HYPSKYFA',
      name: 'Hydrangea paniculata Skyfall',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 972,
      chnn_stock_retail: 972,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB65XZ1A;576ZXYVH;L2LSYTCA;N7234DKB;8LX9HJ78;NRSLWCTS',
      statusContent: 'dot',
      hash: '7CH474ZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CH474ZP'
    },
    {
      status: 210,
      sku: 'PM0080948',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338344',
      rtl_batch_array: '6338344',
      name_search: 'PHBBABY',
      name: "Phormium 'Bronze Baby'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X4YRBCAR;TE8J7XBZ;NLPKAZ5X;GJ4Z9AVZ;X5HB1HG8;ZA31LEHX;JV5LNYYD;VL9C17JW;JVJR8W8L;52PDJLBN;X47T4SJE;JD4LNRNB',
      statusContent: 'dot',
      hash: 'VXBNJ89T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXBNJ89T'
    },
    {
      status: 210,
      sku: 'PM0073124',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258795',
      rtl_batch_array: '6258795',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 10.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'DPW33S6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPW33S6B'
    },
    {
      status: 210,
      sku: 'PM0073125',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6271425',
      rtl_batch_array: '6271425, 6300869',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1152,
      chnn_stock_retail: 1413,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'Z6R68TTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6R68TTB'
    },
    {
      status: 810,
      sku: 'PM0073127',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274998',
      rtl_batch_array: '6274998',
      name_search: 'LAIBRED',
      name: 'Lagerstroemia indica Best Red',
      sppl_size_code: '080STC10',
      rng_range_identifier: 'STE080C10',
      rng_range_description: 'Stem 80 cm C10',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 22.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXBG6EX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXBG6EX5'
    },
    {
      status: 210,
      sku: 'PM0073130',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258806',
      rtl_batch_array: '6258806',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 9915,
      chnn_stock_retail: 9915,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'ZV1T5BY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV1T5BY3'
    },
    {
      status: 210,
      sku: 'PM0073131',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258807',
      rtl_batch_array: '6258807',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 418,
      chnn_stock_retail: 418,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'VVW39X16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVW39X16'
    },
    {
      status: 810,
      sku: 'PM0080949',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338345',
      rtl_batch_array: '6338345',
      name_search: 'PHCHOCOM',
      name: "Phormium 'Chocomint'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 4.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXKEZNY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXKEZNY8'
    },
    {
      status: 210,
      sku: 'PM0073133',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258810',
      rtl_batch_array: '6258810',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 693,
      chnn_stock_retail: 693,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: '8AWGV54W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AWGV54W'
    },
    {
      status: 210,
      sku: 'PM0073135',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258812',
      rtl_batch_array: '6258812',
      name_search: 'MOCTORCH',
      name: "Molinia caerulea 'Torch'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 948,
      chnn_stock_retail: 948,
      sppl_prcp: 6.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ENVDAWRT;J74K399E;74K6DC37;DYYXZZ7N;T3VAHES2;ER5WPDT8;RK6SZRPZ',
      statusContent: 'dot',
      hash: 'LXP8XCWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXP8XCWR'
    },
    {
      status: 210,
      sku: 'PM0080950',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338346',
      rtl_batch_array: '6338346',
      name_search: 'PHDDELIG',
      name: "Phormium 'Dark Delight'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DSD771VR;V6L3A4GC;K4827NH3;Y6RP83P5;D6W5REL8;2LNJ6HEE;RP5CETPN',
      statusContent: 'dot',
      hash: 'JZGR9ARB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZGR9ARB'
    },
    {
      status: 210,
      sku: 'PM0073137',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258814',
      rtl_batch_array: '6258814',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      statusContent: 'dot',
      hash: '8HVJCLS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HVJCLS7'
    },
    {
      status: 210,
      sku: 'PM0080951',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338347',
      rtl_batch_array: '6338347',
      name_search: 'PHDUET',
      name: "Phormium 'Duet'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 820,
      chnn_stock_retail: 820,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRSXRD6H;1CW1LN9G;TDSSWRAA;N2BV3H42;9YVDY43N;D84DNAW4;8H2WLRP9;188PADJ5;GH9954S2;JZKRAWEJ;WTDJP32E',
      statusContent: 'dot',
      hash: '7NCYX6SH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NCYX6SH'
    },
    {
      status: 210,
      sku: 'PM0080952',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338348',
      rtl_batch_array: '6338348',
      name_search: 'PHGRAY',
      name: "Phormium 'Golden Ray'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVAP58E2;Y7B1R7A5;1VEE3ADE;EK1757LT;NG2NCXEN',
      statusContent: 'dot',
      hash: '45ZYG9X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45ZYG9X3'
    },
    {
      status: 210,
      sku: 'PM0073144',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258824',
      rtl_batch_array: '6258824',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 9.875,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'V27RBW1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V27RBW1J'
    },
    {
      status: 810,
      sku: 'PM0073145',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284526',
      rtl_batch_array: '6284526, 6258825',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 1029,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'DCVN9NDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCVN9NDA'
    },
    {
      status: 210,
      sku: 'PM0073146',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258826',
      rtl_batch_array: '6258826',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 9.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '2J9YHVDK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2J9YHVDK'
    },
    {
      status: 210,
      sku: 'PM0073147',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258828',
      rtl_batch_array: '6258828',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 903,
      chnn_stock_retail: 903,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'KK1RY21E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KK1RY21E'
    },
    {
      status: 810,
      sku: 'PM0073313',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259716',
      rtl_batch_array: '6259716',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C4',
      rng_range_identifier: 'H100125C4',
      rng_range_description: 'H100 cm 125 cm C4',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 4.111,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'GZZ9JB1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZZ9JB1V'
    },
    {
      status: 910,
      sku: 'PM0073317',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HOFRANCH',
      name: "Hosta 'Francheska'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'K8E8RW6T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K8E8RW6T'
    },
    {
      status: 210,
      sku: 'PM0073318',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259722',
      rtl_batch_array: '6259722',
      name_search: 'SCSHHTON',
      name: "Schizachyrium scoparium 'Ha Ha Tonka'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1236,
      chnn_stock_retail: 1236,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZ7WL62A;4P6W8JAA;BWYX2S1Y',
      statusContent: 'dot',
      hash: 'AALRGWRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AALRGWRD'
    },
    {
      status: 210,
      sku: 'PM0073579',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6282784',
      rtl_batch_array: '6260720, 6282784',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 8689,
      chnn_stock_retail: 16089,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: '5J9S38J4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5J9S38J4'
    },
    {
      status: 210,
      sku: 'PM0080957',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338353',
      rtl_batch_array: '6338353',
      name_search: 'PHRMAIDE',
      name: "Phormium 'Rainbow Maiden'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1310,
      chnn_stock_retail: 1310,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y96A23HE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y96A23HE'
    },
    {
      status: 210,
      sku: 'PM0080958',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338354',
      rtl_batch_array: '6338354',
      name_search: 'PHRQUEEN',
      name: "Phormium 'Rainbow Queen",
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GY7RN2YS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GY7RN2YS'
    },
    {
      status: 210,
      sku: 'PM0026559',
      core_name: 'Plant',
      sppl_ean: '8720664886513',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6080764',
      rtl_batch_array: '6080764, 6301852',
      name_search: 'SOPRAECO',
      name: "Solidago 'Praecox'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 218,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1PTY7GW4',
      statusContent: 'dot',
      hash: '1LNASEPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LNASEPZ'
    },
    {
      status: 210,
      sku: 'PM0009431',
      core_name: 'Plant',
      sppl_ean: '8720664571082',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495413',
      rtl_batch_array: '6301693, 5495413',
      name_search: 'LIBROCKE',
      name: "Ligularia 'Bottle Rocket'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 447,
      chnn_stock_retail: 578,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6WH98ZJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WH98ZJZ'
    },
    {
      status: 210,
      sku: 'PM0080959',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338355',
      rtl_batch_array: '6338355',
      name_search: 'PHSUNDOW',
      name: "Phormium 'Sundowner'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 2093,
      chnn_stock_retail: 2093,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ED55X22A;R81V4GPX;H6RGJ3T7',
      statusContent: 'dot',
      hash: 'ZKS8TSKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKS8TSKW'
    },
    {
      status: 210,
      sku: 'PM0080960',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338356',
      rtl_batch_array: '6338356',
      name_search: 'PHTTHUMB',
      name: "Phormium 'Tom Thumb'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJJYP125',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJJYP125'
    },
    {
      status: 210,
      sku: 'PM0080961',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338357',
      rtl_batch_array: '6338357',
      name_search: 'PHCTRICO',
      name: "Phormium cookianum 'Tricolor'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '71W7G6HW;LYJYGYHT;B5R2ZT7D;T9GH2L1C;NRE234XV;RJK532GN;277WV5SS;9RP2P796;Y2HBAHNT;G344643X;C7SWY2A4;6ZX4CJ44',
      statusContent: 'dot',
      hash: '5CXRA8YG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CXRA8YG'
    },
    {
      status: 210,
      sku: 'PM0080962',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338359',
      rtl_batch_array: '6338359',
      name_search: 'PHTENAX',
      name: 'Phormium tenax',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 542,
      chnn_stock_retail: 542,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNNDY31D;9566B5PV;E5A2X5CK;1E1ABT8Z;RJRA1YLN;1VJ2W6DK;NNSBVZSG;7TE51JHR;GLKXZ78H;11XZE734;7Z1NRJ9V;GHTYP9H8;HT3EBZCP',
      statusContent: 'dot',
      hash: 'PR6TD8DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR6TD8DS'
    },
    {
      status: 210,
      sku: 'PM0080963',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338360',
      rtl_batch_array: '6338360',
      name_search: 'PHYWAVE',
      name: "Phormium 'Yellow Wave'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 4.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZDY9AHS;7GZ4N3GP;XPHJC86T',
      statusContent: 'dot',
      hash: 'KDAKPWHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDAKPWHD'
    },
    {
      status: 210,
      sku: 'PM0073639',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293871',
      rtl_batch_array: '6293871',
      name_search: 'SPJDPART',
      name: 'Spiraea japonica Double Play Artist',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 11.243,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D549K65P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D549K65P'
    },
    {
      status: 210,
      sku: 'PM0080964',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338361',
      rtl_batch_array: '6338361',
      name_search: 'PHFCAMIL',
      name: "Photinia fraseri 'Camilvy'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5GEWD2X;Y96SS7T6;H3XSZSRY;5937KDPN;HA13T26N',
      statusContent: 'dot',
      hash: 'NZ8X7333',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZ8X7333'
    },
    {
      status: 210,
      sku: 'PM0073641',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293874',
      rtl_batch_array: '6293874',
      name_search: 'SPJDPRED',
      name: 'Spiraea japonica Double Play Red',
      sppl_size_code: '030035C10',
      rng_range_identifier: 'H030035C10',
      rng_range_description: 'H30 cm 35 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 11.243,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HN25ZY1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HN25ZY1B'
    },
    {
      status: 210,
      sku: 'PM0080965',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338362',
      rtl_batch_array: '6338362',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 10795,
      chnn_stock_retail: 10795,
      sppl_prcp: 2.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'DR45TR9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR45TR9X'
    },
    {
      status: 210,
      sku: 'PM0073643',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293876',
      rtl_batch_array: '6293876',
      name_search: 'HYPFLIGH',
      name: 'Hydrangea paniculata Fire Light',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 12.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TBD5D8K1;J77ZSZHH;CVSA73Y4;SWZ3YKXV;J3BY1CH8;KZBL1HBE;8XZPWJYL;G9HTATL5',
      statusContent: 'dot',
      hash: 'V85WE16H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V85WE16H'
    },
    {
      status: 210,
      sku: 'PM0080966',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338363',
      rtl_batch_array: '6338363',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'GXHE7Y3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXHE7Y3A'
    },
    {
      status: 210,
      sku: 'PM0080967',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338365',
      rtl_batch_array: '6338365',
      name_search: 'PHFLOUIS',
      name: 'Photinia fraseri Louise',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3EW3ATK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3EW3ATK'
    },
    {
      status: 210,
      sku: 'PM0080968',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338366',
      rtl_batch_array: '6338366',
      name_search: 'PHFPMARB',
      name: 'Photinia fraseri Pink Marble',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4CT9L811;1HBNSB84;8N74BZ6V;YARAEHC9;HGEVRBBT;JXTCBW25;9WRNT3DN;JY6R1L8Z;V6W5T3DZ;ER19K222;SDG8NAG4;DP837D6H;P3RSE34G;E6DCE2W5;24KRVHDT;EAKC87TV;4V3R78Y9;VXNWKB91;ZC7LGHKY',
      statusContent: 'dot',
      hash: '67CTPKZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67CTPKZ9'
    },
    {
      status: 210,
      sku: 'PM0073647',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293879',
      rtl_batch_array: '6293879',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '080090C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 8.774,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'A8LGCB9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8LGCB9E'
    },
    {
      status: 210,
      sku: 'PM0073648',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293880',
      rtl_batch_array: '6293880',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '030035C10',
      rng_range_identifier: 'H030035C10',
      rng_range_description: 'H30 cm 35 cm C10',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 8.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'B6NDWAE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6NDWAE3'
    },
    {
      status: 210,
      sku: 'PM0073649',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293883',
      rtl_batch_array: '6293883',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '070080C74PY',
      rng_range_identifier: 'PYRAH070080C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 19.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'XPBBEETA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPBBEETA'
    },
    {
      status: 210,
      sku: 'PM0073650',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293884',
      rtl_batch_array: '6293884',
      name_search: 'DEYCBLOS',
      name: 'Deutzia Yuki Cherry Blossom',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RL977WV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RL977WV5'
    },
    {
      status: 210,
      sku: 'PM0073651',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293885',
      rtl_batch_array: '6293885',
      name_search: 'DEYSNOWF',
      name: 'Deutzia Yuki Snowflake',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 12.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SCS2E983',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCS2E983'
    },
    {
      status: 210,
      sku: 'PM0073652',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293886',
      rtl_batch_array: '6293886',
      name_search: 'BUBCHIP',
      name: "Buddleja 'Blue Chip'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RSJNR3YN',
      statusContent: 'dot',
      hash: 'AZWGN5S4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZWGN5S4'
    },
    {
      status: 210,
      sku: 'PM0080969',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338367',
      rtl_batch_array: '6338367',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1045,
      chnn_stock_retail: 1045,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'Z59GH78X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z59GH78X'
    },
    {
      status: 210,
      sku: 'PM0080970',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338368',
      rtl_batch_array: '6338368',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 10910,
      chnn_stock_retail: 10910,
      sppl_prcp: 2.204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'JEWR1NT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEWR1NT9'
    },
    {
      status: 210,
      sku: 'PM0080971',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338369',
      rtl_batch_array: '6338369',
      name_search: 'PHDORFFR',
      name: "Phlox (P) 'Dorffreude'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 967,
      chnn_stock_retail: 967,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LRGGGKH4',
      statusContent: 'dot',
      hash: 'KSNY8Z4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSNY8Z4B'
    },
    {
      status: 810,
      sku: 'PM0080972',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338371',
      rtl_batch_array: '6338371',
      name_search: 'PHOABLAC',
      name: 'Physocarpus opulifolius All Black',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8EZADSYJ',
      statusContent: 'dot',
      hash: 'WJ4475RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJ4475RC'
    },
    {
      status: 210,
      sku: 'PM0080973',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338372',
      rtl_batch_array: '6338372',
      name_search: 'PHOCRED',
      name: "Physocarpus opulif. 'Compacta Red'",
      sppl_size_code: '035040C3',
      rng_range_identifier: 'H035040C3',
      rng_range_description: 'H35 cm 40 cm C3',
      sppl_stock_available: 697,
      chnn_stock_retail: 697,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5LVPNL3C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LVPNL3C'
    },
    {
      status: 210,
      sku: 'PM0080974',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338374',
      rtl_batch_array: '6338374',
      name_search: 'PHORLEMO',
      name: 'Physocarpus op. Raspberry Lemonade',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_prcp: 2.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BTPG51X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTPG51X3'
    },
    {
      status: 210,
      sku: 'PM0073915',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269825',
      rtl_batch_array: '6269825',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1290,
      chnn_stock_retail: 1290,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: 'YJP37YWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJP37YWK'
    },
    {
      status: 210,
      sku: 'PM0080975',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338375',
      rtl_batch_array: '6338375',
      name_search: 'PIFSILVE',
      name: "Pieris 'Flaming Silver'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1595,
      chnn_stock_retail: 1595,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCXSKVBJ;HVLPYJBZ;JT4D138Z;VA7AA76B;EP883SGR;H4EX7KA9;YW3E6JLJ;CA16JVCG;Y1PYT182;KKYGL9RE;YJB4NAY4;15XGJLJN;A3DNJ4NP;YJYZ14YH;7ST6YLTR;NG55PDP7;YXD8N8BG;ZJ23XEES;NH3KSJ9C;NXSZK6HG;BGYB1247',
      statusContent: 'dot',
      hash: 'GY5VHZC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GY5VHZC9'
    },
    {
      status: 210,
      sku: 'PM0080976',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338376',
      rtl_batch_array: '6338376',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 1344,
      chnn_stock_retail: 1344,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: 'ZPZW2LXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZPZW2LXY'
    },
    {
      status: 910,
      sku: 'PM0074083',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHGLACI',
      name: "Hedera helix 'Glacier'",
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      imageCore:
        'YNKZE78G;ST39J6WN;1AK4BT6L;KJ6P7ESR;743J88XA;EZL4Y25P;ZH8ZXXVK;H45XV8VC',
      statusContent: 'dot',
      hash: 'A6LAD1LB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A6LAD1LB'
    },
    {
      status: 210,
      sku: 'PM0080977',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338377',
      rtl_batch_array: '6338377',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 535,
      chnn_stock_retail: 535,
      sppl_order_minimum: 3,
      sppl_prcp: 2.634,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: 'G3V48A9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3V48A9X'
    },
    {
      status: 210,
      sku: 'PM0080978',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338378',
      rtl_batch_array: '6338378',
      name_search: 'PIJBONFI',
      name: "Pieris japonica 'Bonfire'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DEBYHSP6;NE42JZBH;PTYAZLJE;TJYRW5GJ;PAAPTBDT;GWHTY7X7;GJPL62LD;SNNC9XGJ;YXT4SCR5;SERY8H6V;4T61H7VG',
      statusContent: 'dot',
      hash: '9T3LSESL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T3LSESL'
    },
    {
      status: 210,
      sku: 'PM0073580',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260721',
      rtl_batch_array: '6260721, 6310228',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1860,
      chnn_stock_retail: 3660,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'CYT5WC5Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CYT5WC5Y'
    },
    {
      status: 210,
      sku: 'PM0073654',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293895',
      rtl_batch_array: '6293895',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'BX11SC3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX11SC3X'
    },
    {
      status: 210,
      sku: 'PM0073141',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258818',
      rtl_batch_array: '6258818',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 862,
      chnn_stock_retail: 862,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: '4WHB1VTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WHB1VTH'
    },
    {
      status: 210,
      sku: 'PM0073142',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258821',
      rtl_batch_array: '6258821',
      name_search: 'PIJKATSU',
      name: "Pieris japonica 'Katsura'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1198,
      chnn_stock_retail: 1198,
      sppl_prcp: 8.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZC1P451N;R3YXDDZ5;89KK2VAZ;VEEHB33X;LNALWVY8;72RVVABR;TDGCXWLX;V2NZBT71;ZWXYX7HW;7A5PSARH;7ZZ66V5L;89HZ92DK;8VCLVYDN;DJ8Z73WD;RS5JEC4P',
      statusContent: 'dot',
      hash: 'L978L6HW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L978L6HW'
    },
    {
      status: 210,
      sku: 'PM0073314',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259717',
      rtl_batch_array: '5941645, 6259717',
      name_search: 'SPJCRISP',
      name: "Spiraea japonica 'Crispa'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1403,
      chnn_stock_retail: 3024,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XRBTK1XK;6639Z47Y',
      statusContent: 'dot',
      hash: 'BRG5WE39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRG5WE39'
    },
    {
      status: 810,
      sku: 'PM0080979',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338379',
      rtl_batch_array: '6338379',
      name_search: 'PIJDEBUT',
      name: "Pieris japonica 'Debutante'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 3.494,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '28C4G9Z7;TJBDT4GA;E736XHBT;1SD1LN4B;GYBD11SY;T6AKPDLP;938DW86L;Z6ALPBB5;ET5TKR37;HCJY8V13;N52Z4R56;83YVXS5Z',
      statusContent: 'dot',
      hash: 'JZAK78XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZAK78XA'
    },
    {
      status: 810,
      sku: 'PM0014887',
      core_name: 'Plant',
      sppl_ean: '8720664802100',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5582265',
      rtl_batch_array: '5582265',
      name_search: 'PHOSWINE',
      name: 'Physocarpus opulifolius Summer Wine',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJBWSWTW;PAZLGJK7;WAP8SBGR;PYSTNZDL;CB5NHEEY;EYCGXX44',
      statusContent: 'dot',
      hash: 'GP6GNJKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GP6GNJKS'
    },
    {
      status: 210,
      sku: 'PM0080980',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338380',
      rtl_batch_array: '6338380',
      name_search: 'PIJKATSU',
      name: "Pieris japonica 'Katsura'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 243,
      chnn_stock_retail: 243,
      sppl_prcp: 4.031,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZC1P451N;R3YXDDZ5;89KK2VAZ;VEEHB33X;LNALWVY8;72RVVABR;TDGCXWLX;V2NZBT71;ZWXYX7HW;7A5PSARH;7ZZ66V5L;89HZ92DK;8VCLVYDN;DJ8Z73WD;RS5JEC4P',
      statusContent: 'dot',
      hash: '6L4GV22A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L4GV22A'
    },
    {
      status: 210,
      sku: 'PM0074084',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267698',
      rtl_batch_array: '6267698',
      name_search: 'HEHGLACI',
      name: "Hedera helix 'Glacier'",
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 1.616,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YNKZE78G;ST39J6WN;1AK4BT6L;KJ6P7ESR;743J88XA;EZL4Y25P;ZH8ZXXVK;H45XV8VC',
      statusContent: 'dot',
      hash: '453PX976',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '453PX976'
    },
    {
      status: 210,
      sku: 'PM0028815',
      core_name: 'Plant',
      sppl_ean: '8720626329997',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5600633',
      rtl_batch_array: '5600633',
      name_search: 'THPKBEAU',
      name: "Thuja plicata 'Kager's Beauty'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 247,
      chnn_stock_retail: 247,
      sppl_prcp: 59.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58BS9PEY;J4PSVNCG;647RLR4L;21P9D4Y3',
      imageBatch: 'JTLNAP5E',
      statusContent: 'dot',
      hash: 'T3DP3DCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3DP3DCC'
    },
    {
      status: 210,
      sku: 'PM0074112',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274769',
      rtl_batch_array: '6267746, 6274769',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2238,
      chnn_stock_retail: 5228,
      sppl_prcp: 1.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'LXLGBDP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXLGBDP2'
    },
    {
      status: 210,
      sku: 'PM0073323',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259979',
      rtl_batch_array: '6259979',
      name_search: 'HEPBLUE',
      name: "Hebe 'Pastel Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z1TBN9ZP;2GBDPN57;HK5PGTEZ;PT3PDTVD;KDWAVEZC;JSHER67Z',
      statusContent: 'dot',
      hash: 'X7EV9139',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7EV9139'
    },
    {
      status: 210,
      sku: 'PM0073324',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272824',
      rtl_batch_array: '6272824',
      name_search: 'HEBMARIE',
      name: "Hebe 'Baby Marie'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 686,
      chnn_stock_retail: 686,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XN2E5BKG;Y86AGZLK',
      statusContent: 'dot',
      hash: 'XAE5PPEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAE5PPEJ'
    },
    {
      status: 210,
      sku: 'PM0073332',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281850',
      rtl_batch_array: '6281850',
      name_search: 'HEGORME',
      name: "Hebe 'Great Orme'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1312,
      chnn_stock_retail: 1312,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '817AGT2S;2JP22AVS;22YNRY15;RKT1GNNE;RS2S6DH8;6GYYD7D5;PLACK4R9;WG42WBV4;9879C3A4',
      statusContent: 'dot',
      hash: 'XLZDE6L3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLZDE6L3'
    },
    {
      status: 210,
      sku: 'PM0011641',
      core_name: 'Plant',
      sppl_ean: '8720349416615',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5368839',
      rtl_batch_array: '5591966, 5368839',
      name_search: 'GESMFREI',
      name: "Geranium sanguineum 'Max Frei'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 21158,
      chnn_stock_retail: 22158,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WBYASXG1;6H7XNWSD;SEB7R9LS;KSDHVT2L;N1TAX1SV;NNKE2BWR;5LXCW5PA;G9739E15;6CHZLZ1A;LNLT96AE;DAZJERZT',
      imageBatch: 'JS73WHW2',
      statusContent: 'dot',
      hash: 'WGTNJJ13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGTNJJ13'
    },
    {
      status: 810,
      sku: 'PM0010818',
      core_name: 'Plant',
      sppl_ean: '8720664546349',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5463523',
      rtl_batch_array: '5463523',
      name_search: 'HEPBOX',
      name: "Hemerocallis 'Pandora's Box'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KHYA11ZH;6K9Y54XA',
      imageBatch: '5WZ1REWG',
      statusContent: 'dot',
      hash: 'DC6D1SE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC6D1SE9'
    },
    {
      status: 210,
      sku: 'PM0073321',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272849',
      rtl_batch_array: '6272849',
      name_search: 'HEPBLUE',
      name: "Hebe 'Pastel Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z1TBN9ZP;2GBDPN57;HK5PGTEZ;PT3PDTVD;KDWAVEZC;JSHER67Z',
      statusContent: 'dot',
      hash: 'BJGEVHVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BJGEVHVZ'
    },
    {
      status: 210,
      sku: 'PM0074093',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267710',
      rtl_batch_array: '6267710',
      name_search: 'LEFIREST',
      name: 'Leucothoe Firestar',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 732,
      chnn_stock_retail: 732,
      sppl_prcp: 3.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRJX4SR6;TTNVHRR5;G77VHL1X;GYWLTZX4;2WHLE7C4;WTRRYBLR;TSCWE6EL',
      statusContent: 'dot',
      hash: 'X14PBN6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X14PBN6Z'
    },
    {
      status: 210,
      sku: 'PM0000637',
      core_name: 'Plant',
      sppl_ean: '8720626341791',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 2,
      btch_active_retail: '5459689',
      rtl_batch_array: '6298487, 5459689',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 1249,
      sppl_prcp: 5.256,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'N8NR71LG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8NR71LG'
    },
    {
      status: 210,
      sku: 'PM0074095',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267712',
      rtl_batch_array: '6267712',
      name_search: 'LEFRAINB',
      name: "Leucothoe fontanesiana 'Rainbow'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 2.632,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1H5P3ZSD;CCB5LRLZ;SJS31JLE;SE71TG9R;8LE8AELD;ZEZ3P78W;253XTPC7;48TWELTH;S87WRHT4;6BYV1J6X;K7HNJ6G5;HHP3PN72;2C8EJXR8;6H77HESP;5GX4KEH4;9CSW38ZJ;GJK122WL;HWHWALRR',
      statusContent: 'dot',
      hash: '5ZVAXH61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZVAXH61'
    },
    {
      status: 210,
      sku: 'PM0074094',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267711',
      rtl_batch_array: '6267711',
      name_search: 'LEFIREST',
      name: 'Leucothoe Firestar',
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 9.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRJX4SR6;TTNVHRR5;G77VHL1X;GYWLTZX4;2WHLE7C4;WTRRYBLR;TSCWE6EL',
      statusContent: 'dot',
      hash: 'JDZ7W1A3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDZ7W1A3'
    },
    {
      status: 210,
      sku: 'PM0020498',
      core_name: 'Plant',
      sppl_ean: '8720349412044',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4270516',
      rtl_batch_array: '4270516',
      name_search: 'CAOEGREE',
      name: "Carex oshimensis 'Evergreen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '9G23R8ZX',
      statusContent: 'dot',
      hash: 'NBLEXCXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBLEXCXK'
    },
    {
      status: 210,
      sku: 'PM0008855',
      core_name: 'Plant',
      sppl_ean: '8720664876507',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6146934',
      rtl_batch_array: '6146934, 6083722, 6314282, 5502148',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 7038,
      chnn_stock_retail: 12493,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'RL2R8BGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RL2R8BGS'
    },
    {
      status: 910,
      sku: 'PM0074107',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267732',
      rtl_batch_array: '6267732',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 3.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'SAWY63KH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAWY63KH'
    },
    {
      status: 210,
      sku: 'PM0074106',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267731',
      rtl_batch_array: '6267731',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 865,
      chnn_stock_retail: 865,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'TNA24Z6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNA24Z6S'
    },
    {
      status: 210,
      sku: 'PM0074098',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267717',
      rtl_batch_array: '6267717, 6356583',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 130,
      chnn_stock_retail: 5130,
      sppl_prcp: 1.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'X3LL7DDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3LL7DDX'
    },
    {
      status: 910,
      sku: 'PM0030450',
      core_name: 'Plant',
      sppl_ean: '8720626352346',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '5429210',
      rtl_batch_array: '5429210',
      name_search: 'HYMPIA',
      name: "Hydrangea macrophylla 'Pia'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 6.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A65SRCXT;E8CTSJ1E;6CENRYDW',
      statusContent: 'dot',
      hash: 'YZ3R8535',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZ3R8535'
    },
    {
      status: 210,
      sku: 'PM0002001',
      core_name: 'Plant',
      sppl_ean: '8720349457465',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5719731',
      rtl_batch_array: '5719731',
      name_search: 'SPJMANON',
      name: "Spiraea japonica 'Manon'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZAWNADB',
      statusContent: 'dot',
      hash: 'Z2SZTTH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2SZTTH8'
    },
    {
      status: 210,
      sku: 'PM0025990',
      core_name: 'Plant',
      sppl_ean: '8720349440207',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5375703',
      rtl_batch_array: '5375703',
      name_search: 'HEMPPURP',
      name: "Heuchera micrantha 'Palace Purple'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1929,
      chnn_stock_retail: 1929,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JVG47C77;8RAZ4NEY;7CGSBYGW;ZCB25BW5;6SXB6EWG;CV5JXRTV;VENPDWKV;93HG2PVG;PC4EZA6V;4NE1SRNW;DHNBLKSB;PW83K5DX;WDLJVDJ5;7EZ42RDX',
      imageBatch: 'VBS322D7',
      statusContent: 'dot',
      hash: '9TSLTJHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TSLTJHE'
    },
    {
      status: 810,
      sku: 'PM0074118',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267760',
      rtl_batch_array: '6267760, 6301373',
      name_search: 'ACRACEMO',
      name: 'Actaea racemosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 81,
      chnn_stock_retail: 454,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PNEN4YKN',
      statusContent: 'dot',
      hash: '5WAEHE4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WAEHE4X'
    },
    {
      status: 210,
      sku: 'PM0074122',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267771',
      rtl_batch_array: '6349943, 6267771',
      name_search: 'CIPSPIKE',
      name: "Cimicifuga 'Pink Spike'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 893,
      chnn_stock_retail: 1881,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P86ZSVGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P86ZSVGD'
    },
    {
      status: 210,
      sku: 'PM0083031',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356142',
      rtl_batch_array: '6356142',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'LG4GEK2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LG4GEK2V'
    },
    {
      status: 210,
      sku: 'PM0083032',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356147',
      rtl_batch_array: '6356147',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '050060C24',
      rng_range_identifier: 'H050060C2.5',
      rng_range_description: 'H50 cm 60 cm C2.5',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_prcp: 3.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'BRNEWX83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRNEWX83'
    },
    {
      status: 210,
      sku: 'PM0083033',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356148',
      rtl_batch_array: '6356148',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_prcp: 2.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'ETKKEJ7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETKKEJ7P'
    },
    {
      status: 210,
      sku: 'PM0083034',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356151',
      rtl_batch_array: '6356151',
      name_search: 'HYMMASJ',
      name: "Hydrangea m. 'Masja'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AY5GH6N2;GJVT5SXH;8DPRVEC6;E7CB2ABV',
      statusContent: 'dot',
      hash: '5RXVTED1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5RXVTED1'
    },
    {
      status: 910,
      sku: 'PM0029746',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RAAUSTRA',
      name: 'Raoulia australis',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        '5ZZAADPR;J77167VZ;GK7WV4T7;XEXBGEZJ;BZXTJHAW;ZBVSZH3E;WVB13JLJ;YSWTC8NY;Y5Y3YBZY;PAW223VW',
      statusContent: 'dot',
      hash: 'DXTTNP9W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DXTTNP9W'
    },
    {
      status: 210,
      sku: 'PM0083035',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356153',
      rtl_batch_array: '6356153',
      name_search: 'HYMRSTEI',
      name: "Hydrangea macrophylla 'Renate Steiniger'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 5,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HYNJ1PA;25ADCYW2;PKL78Z5G;7YGK7B2C;1WNG69PG;CG9D67Z7',
      statusContent: 'dot',
      hash: 'NJTS2W18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJTS2W18'
    },
    {
      status: 210,
      sku: 'PM0083036',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356154',
      rtl_batch_array: '6356154',
      name_search: 'HYMRSTEI',
      name: "Hydrangea macrophylla 'Renate Steiniger'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HYNJ1PA;25ADCYW2;PKL78Z5G;7YGK7B2C;1WNG69PG;CG9D67Z7',
      statusContent: 'dot',
      hash: 'PXXSAG3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXXSAG3A'
    },
    {
      status: 210,
      sku: 'PM0083037',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356155',
      rtl_batch_array: '6356155',
      name_search: 'HYMSTHER',
      name: "Hydrangea macrophylla 'Soeur Th\u00e9r\u00e8se'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 5,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P8NC46W6',
      statusContent: 'dot',
      hash: 'DSHPV1DH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSHPV1DH'
    },
    {
      status: 210,
      sku: 'PM0083038',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356156',
      rtl_batch_array: '6356156',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YHRYC6XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHRYC6XW'
    },
    {
      status: 210,
      sku: 'PM0083039',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356157',
      rtl_batch_array: '6356157',
      name_search: 'HYPCONFE',
      name: 'Hydrangea paniculata Confetti',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VGE9DRVX;RT8DZZ6T',
      statusContent: 'dot',
      hash: 'XC5SX5TJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC5SX5TJ'
    },
    {
      status: 210,
      sku: 'PM0074097',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267716',
      rtl_batch_array: '6267716',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1031,
      chnn_stock_retail: 1031,
      sppl_prcp: 1.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'S2D2HBJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2D2HBJ7'
    },
    {
      status: 210,
      sku: 'PM0026353',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260671',
      rtl_batch_array: '6260671',
      name_search: 'AGRPOSIE',
      name: "Agastache 'Rosie Posie'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PW8L7GKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW8L7GKX'
    },
    {
      status: 210,
      sku: 'PM0074099',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267718',
      rtl_batch_array: '6267718',
      name_search: 'MAHJAPON',
      name: 'Mahonia japonica',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 2.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2NYKLKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2NYKLKJ'
    },
    {
      status: 210,
      sku: 'PM0074100',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267721',
      rtl_batch_array: '6267721',
      name_search: 'PATGSHEE',
      name: "Pachysandra terminalis 'Green Sheen'",
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 1164,
      chnn_stock_retail: 1164,
      sppl_order_minimum: 6,
      sppl_prcp: 0.506,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '539CG3DN;7D85AS1B',
      statusContent: 'dot',
      hash: 'SL7BBS6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SL7BBS6S'
    },
    {
      status: 210,
      sku: 'PM0074101',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356186',
      rtl_batch_array: '6267722, 6356186',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 863,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'TPHNJGHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPHNJGHP'
    },
    {
      status: 810,
      sku: 'PM0074102',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 3,
      btch_active_retail: '6317585',
      rtl_batch_array: '6317585, 6271411, 6339004',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 674,
      sppl_prcp: 4.097,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'HXNT5L3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXNT5L3V'
    },
    {
      status: 810,
      sku: 'PM0074103',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267724',
      rtl_batch_array: '6267724, 6317589',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 90,
      chnn_stock_retail: 165,
      sppl_prcp: 6.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'ZCXER7W7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZCXER7W7'
    },
    {
      status: 210,
      sku: 'PM0073581',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260722',
      rtl_batch_array: '6260722',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: 'VXP6JY2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXP6JY2S'
    },
    {
      status: 910,
      sku: 'PM0074104',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: 'A82N8CRJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A82N8CRJ'
    },
    {
      status: 810,
      sku: 'PM0074105',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267728',
      rtl_batch_array: '6267728',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.761,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: 'W3Z1GZGB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3Z1GZGB'
    },
    {
      status: 210,
      sku: 'PM0000239',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253106',
      rtl_batch_array: '6253106',
      name_search: 'HENBLUSH',
      name: "Hebe 'Nicola's Blush'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7N958EYK',
      statusContent: 'dot',
      hash: 'ZTNG149E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZTNG149E'
    },
    {
      status: 210,
      sku: 'PM0073150',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258832',
      rtl_batch_array: '6258832',
      name_search: 'RHCGRAND',
      name: "Rhododendron 'Catawbiense Grandiflorum'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2R52D3X;DE685APW;AHXH6YP7;J57LVSDY;7E2BHGYD;VRLZXG77;DL89ZPL9;KSCVVP8T',
      statusContent: 'dot',
      hash: '1DP3RZZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DP3RZZ9'
    },
    {
      status: 210,
      sku: 'PM0073151',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258834',
      rtl_batch_array: '6258834',
      name_search: 'RHBPURPL',
      name: 'Rhododendron (AJ) Bloomch. Purple',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 698,
      chnn_stock_retail: 698,
      sppl_prcp: 8.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LWAKRKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LWAKRKX'
    },
    {
      status: 210,
      sku: 'PM0073152',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258835',
      rtl_batch_array: '6258835',
      name_search: 'RHBWHITE',
      name: 'Rhododendron (AJ) Bloomchamp. White',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 378,
      chnn_stock_retail: 378,
      sppl_prcp: 8.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '61YXZ247',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61YXZ247'
    },
    {
      status: 210,
      sku: 'PM0073153',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258836',
      rtl_batch_array: '6258836',
      name_search: 'RHBLURET',
      name: "Rhododendron (Y) 'Blurettia'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 968,
      chnn_stock_retail: 968,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DWBDJHJ;S8H774R6;99VXTZP1;YLYJT175;YLGHG2SL;ZCBDTSE6;79JPDNL6;V9XK12SC',
      statusContent: 'dot',
      hash: '18VWG4Y7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18VWG4Y7'
    },
    {
      status: 210,
      sku: 'PM0073154',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258839',
      rtl_batch_array: '6258839',
      name_search: 'RHDOPEY',
      name: "Rhododendron (Y) 'Dopey'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 967,
      chnn_stock_retail: 967,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1A8RX7SW;SXZJSKLE;88NY2AAH;ZPJ124T5;LS4DNHRH;8PX829WA;8XZZJ4TJ;4AXLYNZ1',
      statusContent: 'dot',
      hash: '5C2LX7VS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5C2LX7VS'
    },
    {
      status: 210,
      sku: 'PM0073155',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258846',
      rtl_batch_array: '6258846',
      name_search: 'RHCOSMOP',
      name: "Rhododendron 'Cosmopolitan'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RS6R1NY3;146G5YP8;E7NDAJAW;59NJW5ND;52TZGS4A;DGZ4681B;1DP5778E;ABE3CEXE;TW76YAHZ;WG8XRLAH;A91EVW1N;A1S9N6JT;XW575ZBT;DVCH9LTS',
      statusContent: 'dot',
      hash: 'VX4SWGB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VX4SWGB9'
    },
    {
      status: 210,
      sku: 'PM0073156',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5342776',
      rtl_batch_array: '5342776, 6258847',
      name_search: 'RHCWHITE',
      name: "Rhododendron 'Cunningham's White'",
      sppl_size_code: 'GMC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1955,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8AC4JS25;SWVWBH2X;VZ25X36G;GEVLVHV8;H5T4PASX;KRN7ZGL9;B9JT2BV5;521PALYN;8H1GBA56;6NG6JG81;2ZA1STN5;JZAG5576;KCZSJ34X;LLDCNXT3',
      imageBatch: 'XPBP37X2',
      statusContent: 'dot',
      hash: 'DXSXAKRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXSXAKRY'
    },
    {
      status: 210,
      sku: 'PM0073157',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5342777',
      rtl_batch_array: '5342777, 6258848',
      name_search: 'RHGERMAN',
      name: "Rhododendron 'Germania'",
      sppl_size_code: 'GMC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1946,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3PEHKT4;VDCWE9JJ;RNPSAJ2X;A1VR7CSP;SYA2749Z;DNJD5K35;CW79A7E2;N26179P9;9DKZPPNC;WJR13KXC;D5ZL3NTG;W37W3RST;NZ6LEC1J;P8N6CAL9;P4TRCVAW;JP7XAVRX;H5WGHLEB;JKW8N99G;P98AVW2S',
      imageBatch: 'K7CZZBR3',
      statusContent: 'dot',
      hash: '815LW9VP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '815LW9VP'
    },
    {
      status: 210,
      sku: 'PM0073319',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272821',
      rtl_batch_array: '6272821',
      name_search: 'HEABLUE',
      name: "Hebe 'Autumn Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 756,
      chnn_stock_retail: 756,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NYA6EES5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYA6EES5'
    },
    {
      status: 210,
      sku: 'PM0073320',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272822',
      rtl_batch_array: '6272822',
      name_search: 'HEABLUE',
      name: "Hebe 'Autumn Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5P6W2TKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5P6W2TKJ'
    },
    {
      status: 210,
      sku: 'PM0073322',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272850',
      rtl_batch_array: '6272850',
      name_search: 'HEPBLUE',
      name: "Hebe 'Pastel Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 616,
      chnn_stock_retail: 616,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z1TBN9ZP;2GBDPN57;HK5PGTEZ;PT3PDTVD;KDWAVEZC;JSHER67Z',
      statusContent: 'dot',
      hash: '4B2NAGCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B2NAGCS'
    },
    {
      status: 210,
      sku: 'PM0073325',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272825',
      rtl_batch_array: '6272825',
      name_search: 'HEBMARIE',
      name: "Hebe 'Baby Marie'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1424,
      chnn_stock_retail: 1424,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XN2E5BKG;Y86AGZLK',
      statusContent: 'dot',
      hash: '15ZXYXLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15ZXYXLS'
    },
    {
      status: 210,
      sku: 'PM0073326',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272826',
      rtl_batch_array: '6272826',
      name_search: 'HECHAMPA',
      name: "Hebe 'Champagne'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 956,
      chnn_stock_retail: 956,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CSWT2D27;A4JB28N9;Z9HJ8RJ4;AB4REKDW;L293HHYR;BGYXW51K;6WWL8HPP;DR57JLNK;H3127WZ5;WCG8RSA9;VASYRJPH',
      statusContent: 'dot',
      hash: 'L4VSX28B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4VSX28B'
    },
    {
      status: 210,
      sku: 'PM0073327',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272827',
      rtl_batch_array: '6272827',
      name_search: 'HECHAMPA',
      name: "Hebe 'Champagne'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CSWT2D27;A4JB28N9;Z9HJ8RJ4;AB4REKDW;L293HHYR;BGYXW51K;6WWL8HPP;DR57JLNK;H3127WZ5;WCG8RSA9;VASYRJPH',
      statusContent: 'dot',
      hash: 'TH9YSBBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH9YSBBN'
    },
    {
      status: 210,
      sku: 'PM0073328',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272828',
      rtl_batch_array: '6272828',
      name_search: 'HEEGEM',
      name: "Hebe 'Emerald Gem'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1736,
      chnn_stock_retail: 1736,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2ZEE1EEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZEE1EEE'
    },
    {
      status: 210,
      sku: 'PM0073329',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272829',
      rtl_batch_array: '6272829',
      name_search: 'HEEGEM',
      name: "Hebe 'Emerald Gem'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1697,
      chnn_stock_retail: 1697,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G64HTABL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G64HTABL'
    },
    {
      status: 210,
      sku: 'PM0073334',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272836',
      rtl_batch_array: '6272836',
      name_search: 'HEKIRKII',
      name: "Hebe 'Kirkii'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X34T4LRR',
      statusContent: 'dot',
      hash: 'ZA3Z9VTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZA3Z9VTT'
    },
    {
      status: 210,
      sku: 'PM0073335',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272837',
      rtl_batch_array: '6272837',
      name_search: 'HEKIRKII',
      name: "Hebe 'Kirkii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2265,
      chnn_stock_retail: 2265,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X34T4LRR',
      statusContent: 'dot',
      hash: 'XS1W4PC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS1W4PC6'
    },
    {
      status: 210,
      sku: 'PM0073336',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272841',
      rtl_batch_array: '6272841',
      name_search: 'HEMETTE',
      name: "Hebe 'Mette'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1486,
      chnn_stock_retail: 1486,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3WWPHLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3WWPHLK'
    },
    {
      status: 210,
      sku: 'PM0073337',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272842',
      rtl_batch_array: '6272842',
      name_search: 'HEMETTE',
      name: "Hebe 'Mette'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 566,
      chnn_stock_retail: 566,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4V1LXL43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V1LXL43'
    },
    {
      status: 210,
      sku: 'PM0073582',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6304963',
      rtl_batch_array: '6260724, 6282818, 6304963',
      name_search: 'DEGNIKKO',
      name: "Deutzia gracilis 'Nikko'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4234,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4XTBD8Y',
      statusContent: 'dot',
      hash: 'KH9Z2PWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KH9Z2PWH'
    },
    {
      status: 210,
      sku: 'PM0074109',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267736',
      rtl_batch_array: '6267736',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 346,
      chnn_stock_retail: 346,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'SN2DZWG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN2DZWG8'
    },
    {
      status: 210,
      sku: 'PM0073920',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284488',
      rtl_batch_array: '6284488',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2294,
      chnn_stock_retail: 2294,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'RTTXLS32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTTXLS32'
    },
    {
      status: 210,
      sku: 'PM0073921',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269833',
      rtl_batch_array: '6269833',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2283,
      chnn_stock_retail: 2283,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'WW7J288S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW7J288S'
    },
    {
      status: 210,
      sku: 'PM0073929',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266171',
      rtl_batch_array: '6266171',
      name_search: 'MAAQUIFO',
      name: 'Mahonia aquifolium',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1847,
      chnn_stock_retail: 1847,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6H4KBCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6H4KBCG'
    },
    {
      status: 210,
      sku: 'PM0074086',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 3,
      btch_active_retail: '6267701',
      rtl_batch_array: '6346665, 6267701, 6284487',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '125150C2',
      rng_range_identifier: 'H125150C2',
      rng_range_description: 'H125 cm 150 cm C2',
      sppl_stock_available: 1350,
      chnn_stock_retail: 2346,
      sppl_prcp: 2.567,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'K7S8CBCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7S8CBCA'
    },
    {
      status: 210,
      sku: 'PM0074087',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267703',
      rtl_batch_array: '6267703',
      name_search: 'HYMACROP',
      name: 'Hydrangea macrophylla',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 251,
      chnn_stock_retail: 251,
      sppl_prcp: 1.882,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DKWYBKNY;XDY9G267;L71CB7ZS;99LW1HJR;61S7A3GG;JB6Y8Z34;Z5W2TDK7;98WWYZDN;BZPY31B1;WZ4ZH3YG;STYZTRE2;G6SSLCEN;ZLT1HC4Y;B7EAHS48;1BKEXA82;R6EZN5LV;CPGXR3GP;KNXDB4HZ;7KS3N8BR;NZWHH66K;YV46RLS1;6TVYSHS3;1STW842G;BZPHNLB6;N6RDA1LT;RV7K9E9L;P4GSCNSK;89KP6BLC;BZER1DXX;A2PXWN7J;HTX8JC91',
      statusContent: 'dot',
      hash: 'E53P116C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E53P116C'
    },
    {
      status: 910,
      sku: 'PM0074088',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPANICU',
      name: 'Hydrangea paniculata',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      imageCore: 'E3G9RL7V;5K877CC4;4JDPBY2C;S41ZDECA;6K1X6BV1;PCAPCY2S',
      statusContent: 'dot',
      hash: '7PT2J9E2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7PT2J9E2'
    },
    {
      status: 210,
      sku: 'PM0074090',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338172',
      rtl_batch_array: '6267706, 6338172',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1815,
      chnn_stock_retail: 1961,
      sppl_order_minimum: 5,
      sppl_prcp: 1.989,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LC5H63KT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC5H63KT'
    },
    {
      status: 810,
      sku: 'PM0074117',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267759',
      rtl_batch_array: '6267759',
      name_search: 'ACCALAMA',
      name: 'Achnatherum calamagrostis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XB5VN3WD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XB5VN3WD'
    },
    {
      status: 210,
      sku: 'PM0073330',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259988',
      rtl_batch_array: '6259988',
      name_search: 'HEGESK',
      name: "Hebe 'Golden Esk'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GW5EW151;PTC5X1CD;8JG7GLC8;9VST8WJA;ZJ4H2YCH;J5C979VC',
      statusContent: 'dot',
      hash: 'SKL6Z731',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKL6Z731'
    },
    {
      status: 210,
      sku: 'PM0074096',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267713',
      rtl_batch_array: '6267713',
      name_search: 'LEZEBLID',
      name: "Leucothoe 'Zeblid'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1638,
      chnn_stock_retail: 1638,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J52AANNA;PTSRTY5B;NC3PKJN9;X1ZSZD8H;ZAT27CLV;ZCW7HCKZ;YSKJKNVY;EK4NC8C5;AB1XJWDW;H8EZPX4A;7KD11VXE;1WZ8KNDD;11A22V1Y;HLDLAA98',
      statusContent: 'dot',
      hash: '9SCESA27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SCESA27'
    },
    {
      status: 210,
      sku: 'PM0073924',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266164',
      rtl_batch_array: '6266164',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2470,
      chnn_stock_retail: 2470,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: '4E49ZD9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E49ZD9P'
    },
    {
      status: 210,
      sku: 'PM0074114',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333180',
      rtl_batch_array: '6267750, 6333180',
      name_search: 'ARDSENIO',
      name: "Artemisia dracunculus 'Senior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1008,
      chnn_stock_retail: 1128,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKZ5SVEB;5VLY7HCA',
      statusContent: 'dot',
      hash: 'R2HAZSZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2HAZSZH'
    },
    {
      status: 210,
      sku: 'PM0074113',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267748',
      rtl_batch_array: '6267748, 6334208',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 803,
      chnn_stock_retail: 1803,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: '9T6R4KB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T6R4KB9'
    },
    {
      status: 810,
      sku: 'PM0021926',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274784',
      rtl_batch_array: '6274784, 6378406',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 80,
      chnn_stock_retail: 5080,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'KH82LTD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KH82LTD3'
    },
    {
      status: 210,
      sku: 'PM0022373',
      core_name: 'Plant',
      sppl_ean: '8720626312951',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4655180',
      rtl_batch_array: '4655180',
      name_search: 'MISRCLOU',
      name: 'Miscanthus sinensis Red Cloud',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_prcp: 2.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB4Z5AGB',
      imageBatch: 'XXWV98AR',
      statusContent: 'dot',
      hash: '84EET4LT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84EET4LT'
    },
    {
      status: 210,
      sku: 'PM0083054',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356176',
      rtl_batch_array: '6356176',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'LJCN1ESC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJCN1ESC'
    },
    {
      status: 210,
      sku: 'PM0073349',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272891',
      rtl_batch_array: '6272891',
      name_search: 'HESDOLLA',
      name: "Hebe 'Silver Dollar'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCSWTKE3;AX7K4K3W',
      statusContent: 'dot',
      hash: '1KHXPK18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KHXPK18'
    },
    {
      status: 210,
      sku: 'PM0073350',
      core_name: 'Plant',
      btch_manufacturer: 189,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349113',
      rtl_batch_array: '6349113, 6272890',
      name_search: 'HESBLUE',
      name: "Hebe 'Summer Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJ13TP6X;JN6WS8YA;VRGXE94C;BS28N31E;WE79XT5C;8J6AXDRJ;HVEJ34TG;A51ZBVLG',
      statusContent: 'dot',
      hash: 'A9KC35WR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9KC35WR'
    },
    {
      status: 210,
      sku: 'PM0073352',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6272892',
      rtl_batch_array: '6272892, 6378355',
      name_search: 'HETRICOL',
      name: "Hebe 'Tricolor'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 820,
      chnn_stock_retail: 1316,
      sppl_prcp: 2.421,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WN7VSRW;ND6KDLBL',
      statusContent: 'dot',
      hash: 'SRPJASAC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SRPJASAC'
    },
    {
      status: 810,
      sku: 'PM0074129',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301599',
      rtl_batch_array: '6350224, 6301599, 6267781',
      name_search: 'GEOCHERR',
      name: 'Geranium Orkney Cherry',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 1162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7N96TAWZ',
      statusContent: 'dot',
      hash: 'KY9PZXJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KY9PZXJC'
    },
    {
      status: 210,
      sku: 'PM0074132',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267793',
      rtl_batch_array: '6267793',
      name_search: 'IROKALA',
      name: "Iris (G) 'Ola Kala'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6YCVBP4N',
      statusContent: 'dot',
      hash: '8CT8XYA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CT8XYA2'
    },
    {
      status: 210,
      sku: 'PM0074134',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6267802',
      rtl_batch_array: '6267802, 6302728, 6339894',
      name_search: 'PHVVIVID',
      name: "Physostegia virginiana 'Vivid'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 0.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZS2B2A5G;N55HSG1B',
      statusContent: 'dot',
      hash: 'VD211LK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD211LK3'
    },
    {
      status: 210,
      sku: 'PM0073163',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258854',
      rtl_batch_array: '6258854',
      name_search: 'RHNEVANS',
      name: "Rhododendron 'Nancy Evans'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EXLL5SP1;EVT4XV7W;9K3SGVY6;2LDCRH3N;JLAHHT2S',
      statusContent: 'dot',
      hash: '57PCRPTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57PCRPTW'
    },
    {
      status: 210,
      sku: 'PM0073164',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5342780',
      rtl_batch_array: '5342780, 6258855',
      name_search: 'RHNZEMBL',
      name: "Rhododendron 'Nova Zembla'",
      sppl_size_code: 'GMC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 994,
      chnn_stock_retail: 1955,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8W5HCK2;WJXE8SLB;SGCKADSC;WRL37ATL;DGRX79TT;PDG54PVP;Z12H4ZXV;DA3SARXP;1E5VY5PV;DP7C1Y48;JLHDNADR;PTK91ZHL;YSTT6N8J;4Y4N6B4R;J7D4VJPT;WA4SCZTX;STTVGY2Z;VSAX114H;KBJD5H1B',
      imageBatch: 'GL5DC7P4',
      statusContent: 'dot',
      hash: 'C7WH4PHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7WH4PHV'
    },
    {
      status: 210,
      sku: 'PM0073165',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258857',
      rtl_batch_array: '6258857',
      name_search: 'RHPONTIC',
      name: 'Rhododendron ponticum',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SV27B3C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SV27B3C'
    },
    {
      status: 210,
      sku: 'PM0083055',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356177',
      rtl_batch_array: '6356177',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '824LDWGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '824LDWGT'
    },
    {
      status: 210,
      sku: 'PM0073166',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258858',
      rtl_batch_array: '6258858',
      name_search: 'RHGRAZIE',
      name: "Rhododendron (AP) 'Graziella'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RLCWTTKX;GT4ZH2N6;9XGP1HLS;RRWPZN5L;ZVSJL6PV;K88KK65L;HYVXS1C4;P6RT79N9;J9T5D98V;JPJYD8B1;YW8R77R3;4DD8CVH9;EDD45TP2;GTKHD1Z9;WRXN5LN6;LKBT1J7P;ARCGA636',
      statusContent: 'dot',
      hash: 'XV4DV5LJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV4DV5LJ'
    },
    {
      status: 210,
      sku: 'PM0073167',
      core_name: 'Plant',
      sppl_ean: '8720626367227',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5449478',
      rtl_batch_array: '5449478, 6258859',
      name_search: 'RHPVARIE',
      name: "Rhododendron ponticum 'Variegatum'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1973,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XBCK3Z4K;N7TZW1HW;7HJTX153',
      imageBatch: '6T74YZCB',
      statusContent: 'dot',
      hash: 'PJLRW9XS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJLRW9XS'
    },
    {
      status: 210,
      sku: 'PM0013249',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301773',
      rtl_batch_array: '6037917, 6301773, 6242180',
      name_search: 'PEAALBA',
      name: "Persicaria amplexicaulis 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5378,
      chnn_stock_retail: 6798,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7YCKKCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7YCKKCD'
    },
    {
      status: 210,
      sku: 'PM0073168',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258860',
      rtl_batch_array: '6258860',
      name_search: 'RHPORZEL',
      name: "Rhododendron (Y) 'Porzellan'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2YGN4DT;KWR84681;5B73WPTC;BYBXBPPD;6144RPGN;NTHJ6WVN;W2CXWHV7;E4J2GTXY;X522K2XJ;L95P8R7H;AP9RTCL3;5W6WXGNS;GTCP63ZL;VV88LH38',
      statusContent: 'dot',
      hash: 'SAVDKWZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAVDKWZK'
    },
    {
      status: 210,
      sku: 'PM0011576',
      core_name: 'Plant',
      sppl_ean: '8720349452132',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '5420700',
      rtl_batch_array: '5420700, 5420701',
      name_search: 'MEGLYPTO',
      name: 'Metasequoia glyptostroboides',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 750,
      chnn_stock_retail: 1500,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APZV6165;EEDG59S2;Y3EJYXBA;TWP9Y85L;NSNDZL7H;PG49N7R1;KVA96A2A;YLYJJ13K;HKEJX78L;4ZTPD9WJ;DLDSW7N8;PNBZXV8L;CZ7LZREB;R8K1C5HK;H47B2JEC;CHS94XV8',
      statusContent: 'dot',
      hash: 'BH5WGBRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BH5WGBRC'
    },
    {
      status: 210,
      sku: 'PM0016362',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6209218',
      rtl_batch_array: '6209218, 5268253, 6256169, 6137528',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1067,
      chnn_stock_retail: 7260,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      statusContent: 'dot',
      hash: 'PER19HXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PER19HXL'
    },
    {
      status: 210,
      sku: 'PM0010141',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 7,
      btch_active_retail: '6177957',
      rtl_batch_array:
        '6220773, 5664973, 6238815, 6239468, 6177957, 5495370, 6290217',
      name_search: 'HECITRIN',
      name: 'Hemerocallis citrina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 6564,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZTWGSTZC;B3SDRWWD',
      statusContent: 'dot',
      hash: 'PVV39T7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVV39T7A'
    },
    {
      status: 210,
      sku: 'PM0073171',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356493',
      rtl_batch_array: '5356493, 6258863',
      name_search: 'RHRELEGA',
      name: "Rhododendron 'Roseum Elegans'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1994,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T5REX3RS;J97RN8J8;N12DT64L;WRX53JGB;K6S624H3;JA8A4KRA;NR9ZZ4WE;XXVZKKCC;7PP5KYZ8;X87X8KJ2;STV7YDDR;6TA16C66',
      imageBatch: 'W22H7LDL',
      statusContent: 'dot',
      hash: 'RWG3TKC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWG3TKC7'
    },
    {
      status: 210,
      sku: 'PM0021830',
      core_name: 'Plant',
      sppl_ean: '8720664809734',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420714',
      rtl_batch_array: '5420714',
      name_search: 'TADISTIC',
      name: 'Taxodium distichum',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_prcp: 7.041,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '57YKZK3C;1SG6WLB2;EWN73NH9;XJYW3ZXR;KN9TTC1W;5G93H264;YJACBVDD;YVLPL8RW;EZ173YX5;S3JTC69H;VNPRRPSZ;NTAWXLN1;869EL85E',
      statusContent: 'dot',
      hash: 'C962C5L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C962C5L2'
    },
    {
      status: 210,
      sku: 'PM0029954',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301622',
      rtl_batch_array: '6301622',
      name_search: 'GYRALBA',
      name: "Gypsophila repens 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJJRWX11;7W9A1K2C;JRXT6H48',
      statusContent: 'dot',
      hash: 'D6ALLRTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6ALLRTN'
    },
    {
      status: 210,
      sku: 'PM0005012',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336460',
      rtl_batch_array: '5495233, 6336460',
      name_search: 'CAMAUREO',
      name: "Carex morrowii 'Aureovariegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4423,
      chnn_stock_retail: 4519,
      sppl_order_minimum: 3,
      sppl_prcp: 0.752,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G56L7E27;C6Z1SRPH',
      statusContent: 'dot',
      hash: 'NPDV1BTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPDV1BTD'
    },
    {
      status: 210,
      sku: 'PM0074123',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267772',
      rtl_batch_array: '6267772',
      name_search: 'CIRATROP',
      name: "Cimicifuga ramosa 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 632,
      chnn_stock_retail: 632,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N681Y7EL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N681Y7EL'
    },
    {
      status: 210,
      sku: 'PM0028890',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217077',
      rtl_batch_array: '5303731, 6217077',
      name_search: 'SAOCYMOI',
      name: 'Saponaria ocymoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 574,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44G456NP;J4GVWAGN;2T52DK6X;W8BXDPRG;H79CJ3S7;PW49YZBE;X8V5YY4T;N57VNNKR;LLS64XDV;S8Z496CB',
      statusContent: 'dot',
      hash: 'ZX9NLJ78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX9NLJ78'
    },
    {
      status: 210,
      sku: 'PM0073172',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258864',
      rtl_batch_array: '6258864',
      name_search: 'RHTORANG',
      name: "Rhododendron 'Tortoiseshell Orange'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DH36R9Y8;ZEA16HTS;54943GT8',
      statusContent: 'dot',
      hash: 'WZB3GBE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZB3GBE3'
    },
    {
      status: 210,
      sku: 'PM0074125',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6309023',
      rtl_batch_array: '6301532, 6267775, 6309023',
      name_search: 'DIFLUXUR',
      name: "Dicentra formosa 'Luxuriant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1940,
      chnn_stock_retail: 3284,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1EHWYB86',
      statusContent: 'dot',
      hash: 'B19D8DR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B19D8DR1'
    },
    {
      status: 210,
      sku: 'PM0006520',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238447',
      rtl_batch_array: '6238447',
      name_search: 'ALSENESC',
      name: 'Allium senescens',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7325,
      chnn_stock_retail: 7325,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EWR7H3V6;H9BZTTJ2;HEZ9VTYE',
      statusContent: 'dot',
      hash: 'P6WS86NB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6WS86NB'
    },
    {
      status: 210,
      sku: 'PM0074128',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348316',
      rtl_batch_array: '6268733, 6348316',
      name_search: 'EPYNIVEU',
      name: "Epimedium youngianum 'Niveum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 806,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BR7S2X7P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BR7S2X7P'
    },
    {
      status: 210,
      sku: 'PM0010235',
      core_name: 'Plant',
      sppl_ean: '8720353010243',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 4,
      btch_active_retail: '4725660',
      rtl_batch_array: '6298677, 4725660, 6252665, 6271427',
      name_search: 'LIMINGWE',
      name: "Liriope muscari 'Ingwersen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 361,
      chnn_stock_retail: 6089,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y12HDS6E;VTEX3J3R;25KXLVN1;KVV6JXRX',
      statusContent: 'dot',
      hash: 'VVW23T5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVW23T5N'
    },
    {
      status: 210,
      sku: 'PM0073173',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258865',
      rtl_batch_array: '6258865',
      name_search: 'RHVRICHA',
      name: "Rhododendron 'Virginia Richards'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DDASVV24;LV3ZV269;PTEZ3Y65;5SWZAVK7;81Z27BSB;K69P3DV7;EJPZXSX2;YPX9KDYV;DRLGGDVW',
      statusContent: 'dot',
      hash: 'KEGLCAYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEGLCAYG'
    },
    {
      status: 210,
      sku: 'PM0025717',
      core_name: 'Plant',
      sppl_ean: '8720349404896',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4524996',
      rtl_batch_array: '4524996',
      name_search: 'FITICRYS',
      name: "Ficinia truncata 'Ice Crystal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YG2DGZPX;G188EJAY;RB2SVJVZ;P5412HG4;TYE16GGE;55C3VLHE',
      imageBatch: 'HC8C116N',
      statusContent: 'dot',
      hash: '2V43N4BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2V43N4BK'
    },
    {
      status: 210,
      sku: 'PM0073174',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356494',
      rtl_batch_array: '5356494, 6258866',
      name_search: 'RHWRUBY',
      name: "Rhododendron 'Wilgen's Ruby'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 994,
      chnn_stock_retail: 1977,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJXSRSY8;B7ARLBN7;TJ4BH2BX;99GPG518;9H7JS3JL;6S3VSA9N;J8BX21GC;YR4LXE8E;N5R1XYZ8;XGX9G72Z;D3R4LG97;TTZKZHBV;BVV91NBR',
      imageBatch: 'W8BVJAEG',
      statusContent: 'dot',
      hash: '78H65VY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78H65VY7'
    },
    {
      status: 210,
      sku: 'PM0073175',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258867',
      rtl_batch_array: '6258867',
      name_search: 'RHYAKS',
      name: "Rhododendron yakushimanums in cv's",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SR1NGZY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SR1NGZY7'
    },
    {
      status: 210,
      sku: 'PM0007353',
      core_name: 'Plant',
      sppl_ean: '8720626312883',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4823895',
      rtl_batch_array: '4823895',
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E7VK3LN;DDBGZZBL;RGW28J9J;DSCH93GV;7XNJSA12;VW9TY6J4;2KD13C5X;ST5LA5JB;VCJDWHKT;67YR1HE5;YP168CCT;NYHA6TZT;2HHZ78SB;5X8SL82N;ATGY8842;Z7PLP1S1;8JVZEBCA;LXJVKK55;5RR83H12;9Z1YAEDY;WN8K8NXK',
      imageBatch: 'D7RNTNSA',
      statusContent: 'dot',
      hash: '7261H19T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7261H19T'
    },
    {
      status: 210,
      sku: 'PM0013860',
      core_name: 'Plant',
      btch_manufacturer: 1923,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348672',
      rtl_batch_array: '5263152, 6348672',
      name_search: 'KOGLAUCA',
      name: 'Koeleria glauca',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YER5SPN8;T5NN83DN;W7XR4SW5;HWLR2SAZ;N9X726CD;A88RXT8A;XVBKW59K;JNAWPLBS;SVHAE79R;PEBKLRH6;14TAJW5C;1E8WSSH7;6NY6HTY9;EKL2SE7J;GWSCNGSC',
      statusContent: 'dot',
      hash: '7WLPGV19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WLPGV19'
    },
    {
      status: 210,
      sku: 'PM0016109',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6192627',
      rtl_batch_array: '6084974, 6192627, 6301358, 6164348',
      name_search: 'ACMOLLIS',
      name: 'Acanthus mollis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 3933,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72ZXRJN1;GTB79R6C;52561Y7L',
      statusContent: 'dot',
      hash: 'V13VG4W6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V13VG4W6'
    },
    {
      status: 210,
      sku: 'PM0016612',
      core_name: 'Plant',
      sppl_ean: '8720349412143',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4496051',
      rtl_batch_array: '4496051',
      name_search: 'CAOEVERS',
      name: "Carex oshimensis 'Eversheen'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.586,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B8XX5C8;4NXXLR6X;22A7GN8T;TEEY7ESK;BCP4A5HD;HAVHL1CG;YT31LELT;VGZHYRRT;WYC3T5EN;WSLP6VCG',
      imageBatch: 'XKKSCK3D',
      statusContent: 'dot',
      hash: 'AX9YCBPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX9YCBPK'
    },
    {
      status: 210,
      sku: 'PM0083040',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356158',
      rtl_batch_array: '6356158',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'GCYGXR8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCYGXR8W'
    },
    {
      status: 210,
      sku: 'PM0083041',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356159',
      rtl_batch_array: '6356159',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'YRNELXKT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRNELXKT'
    },
    {
      status: 210,
      sku: 'PM0011729',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222181',
      rtl_batch_array: '6222181',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: '5H6X7HVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H6X7HVD'
    },
    {
      status: 210,
      sku: 'PM0083042',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356163',
      rtl_batch_array: '6356163',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.023,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: 'WNVY37EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNVY37EA'
    },
    {
      status: 210,
      sku: 'PM0083043',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356164',
      rtl_batch_array: '6356164',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_prcp: 3.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: '6X58Z52K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X58Z52K'
    },
    {
      status: 910,
      sku: 'PM0028282',
      core_name: 'Plant',
      sppl_ean: '8720349454754',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6003286',
      rtl_batch_array: '6242915, 6263739, 6205773, 5364192, 6003286',
      name_search: 'ACMOONSH',
      name: "Achillea 'Moonshine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6,
      chnn_stock_retail: 4901,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G3X7GWWB;BJRE1T1Y;14JPCRLS;9APDES7D;A9PJDZH6;KP1BJ1AB;4NBPEHHB;EAZ186NE',
      statusContent: 'dot',
      hash: 'X15RYGLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X15RYGLL'
    },
    {
      status: 210,
      sku: 'PM0083044',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356166',
      rtl_batch_array: '6356166',
      name_search: 'HYCALYCI',
      name: 'Hypericum calycinum',
      sppl_size_code: '020025C13',
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLZL4T1V;VNXWNZ6B;ZNL54N4T',
      statusContent: 'dot',
      hash: '22SLC7R6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22SLC7R6'
    },
    {
      status: 210,
      sku: 'PM0083045',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356167',
      rtl_batch_array: '6356167',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D45W5KAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D45W5KAN'
    },
    {
      status: 210,
      sku: 'PM0072892',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256767',
      rtl_batch_array: '6256767',
      name_search: 'ACSJCOMP',
      name: "Actaea simplex 'James Compton'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1467,
      chnn_stock_retail: 1467,
      sppl_order_minimum: 3,
      sppl_prcp: 1.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '51WBY4L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51WBY4L1'
    },
    {
      status: 210,
      sku: 'PM0083046',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356168',
      rtl_batch_array: '6356168',
      name_search: 'HYKGEMO',
      name: "Hypericum kalmianum 'Gemo'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '95YDVR17;DP12YKSA',
      statusContent: 'dot',
      hash: '2YEV7A74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2YEV7A74'
    },
    {
      status: 210,
      sku: 'PM0073184',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258880',
      rtl_batch_array: '6258880',
      name_search: 'ROERUBY',
      name: 'Rosa (P) Everglow Ruby',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2669,
      chnn_stock_retail: 2669,
      sppl_prcp: 3.933,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K3VW3TCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3VW3TCL'
    },
    {
      status: 210,
      sku: 'PM0083047',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356169',
      rtl_batch_array: '6356169',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'B2SZN65T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2SZN65T'
    },
    {
      status: 210,
      sku: 'PM0083048',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356170',
      rtl_batch_array: '6356170',
      name_search: 'ILECGGEM',
      name: "Ilex crenata 'Golden Gem'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6H1T152;ACELZCTX;L8LWT5T5;DNH7B45X;W1E88G6R;PWBSSNSK;H37PG1Y5;BES2N1AV;1WTGN263;4KJBZN66',
      statusContent: 'dot',
      hash: '5AP6XARD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AP6XARD'
    },
    {
      status: 210,
      sku: 'PM0073339',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272839',
      rtl_batch_array: '6272839',
      name_search: 'HEMBEAUT',
      name: "Hebe 'Midsummer Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1713,
      chnn_stock_retail: 1713,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9SE4CNH',
      statusContent: 'dot',
      hash: 'B6JEX3TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6JEX3TW'
    },
    {
      status: 210,
      sku: 'PM0083049',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356171',
      rtl_batch_array: '6356171',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1985,
      chnn_stock_retail: 1985,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: 'N36B2W6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N36B2W6E'
    },
    {
      status: 210,
      sku: 'PM0073341',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272844',
      rtl_batch_array: '6272844',
      name_search: 'HEMWINDE',
      name: "Hebe 'Mrs Winder'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XDCXWHT;CN9X1CLJ;5X5P9A55;K1CH4TL9;JH9G37Y4;96TTR931',
      statusContent: 'dot',
      hash: 'A5WSLX86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5WSLX86'
    },
    {
      status: 210,
      sku: 'PM0083050',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356172',
      rtl_batch_array: '6356172',
      name_search: 'ITVHGARN',
      name: "Itea virginica 'Henry's Garnet'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZLL83K2',
      statusContent: 'dot',
      hash: '6WEKNWK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WEKNWK1'
    },
    {
      status: 210,
      sku: 'PM0021530',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382323',
      rtl_batch_array: '6353075, 6382323, 6291728',
      name_search: 'PEAREBOR',
      name: "Pennisetum alopecuroides 'Reborn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4560,
      chnn_stock_retail: 7847,
      sppl_order_minimum: 3,
      sppl_prcp: 0.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LE7JYXBY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LE7JYXBY'
    },
    {
      status: 210,
      sku: 'PM0073342',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272845',
      rtl_batch_array: '6272845',
      name_search: 'HENBLUSH',
      name: "Hebe 'Nicola's Blush'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 955,
      chnn_stock_retail: 955,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7N958EYK',
      statusContent: 'dot',
      hash: 'KH1B9RKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KH1B9RKK'
    },
    {
      status: 910,
      sku: 'PM0083051',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KOAMABIL',
      name: 'Kolkwitzia amabilis',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      imageCore:
        'RWP2P6R8;L7X96EYB;6TJCTWZR;KRWCDWY7;9LX21RSZ;CBKKG75W;T2CSGXGT;YHK2V3TD;RP2DJLLG;X5AGXH4B;XX56276G;XDCC1BGV',
      statusContent: 'dot',
      hash: 'YJZCS145',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YJZCS145'
    },
    {
      status: 210,
      sku: 'PM0083052',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356174',
      rtl_batch_array: '6356174',
      name_search: 'KOAMABIL',
      name: 'Kolkwitzia amabilis',
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RWP2P6R8;L7X96EYB;6TJCTWZR;KRWCDWY7;9LX21RSZ;CBKKG75W;T2CSGXGT;YHK2V3TD;RP2DJLLG;X5AGXH4B;XX56276G;XDCC1BGV',
      statusContent: 'dot',
      hash: 'CA1H2HWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA1H2HWA'
    },
    {
      status: 210,
      sku: 'PM0083053',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356175',
      rtl_batch_array: '6356175',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: '72Y175C8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72Y175C8'
    },
    {
      status: 810,
      sku: 'PM0075452',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280892',
      rtl_batch_array: '6280892',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9VJT8DD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VJT8DD1'
    },
    {
      status: 810,
      sku: 'PM0075453',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280893',
      rtl_batch_array: '6280893',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SWZ321NL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWZ321NL'
    },
    {
      status: 810,
      sku: 'PM0075455',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280895',
      rtl_batch_array: '6280895',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AR67YLY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AR67YLY7'
    },
    {
      status: 210,
      sku: 'PM0026584',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382277',
      rtl_batch_array: '6349984, 6274835, 6382277, 6333215',
      name_search: 'DECGOLDS',
      name: "Deschampsia cespitosa 'Goldschleier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6650,
      chnn_stock_retail: 18844,
      sppl_order_minimum: 3,
      sppl_prcp: 0.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VNEVHE2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNEVHE2Z'
    },
    {
      status: 810,
      sku: 'PM0075456',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280896',
      rtl_batch_array: '6280896',
      name_search: 'AMROBINS',
      name: 'Ampelocissus robinsonii',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 74.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8A7NVAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8A7NVAW'
    },
    {
      status: 210,
      sku: 'PM0073347',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272889',
      rtl_batch_array: '6272889',
      name_search: 'HESAPPHI',
      name: "Hebe 'Sapphire'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1281,
      chnn_stock_retail: 1281,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CXTV44C3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXTV44C3'
    },
    {
      status: 210,
      sku: 'PM0073348',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272888',
      rtl_batch_array: '6272888',
      name_search: 'HESAPPHI',
      name: "Hebe 'Sapphire'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8CJGAZ9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CJGAZ9P'
    },
    {
      status: 210,
      sku: 'PM0072893',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6256768',
      rtl_batch_array: '6350094, 6256768, 6295548',
      name_search: 'ECSSALMO',
      name: 'Echinacea Sunseekers Salmon',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 497,
      chnn_stock_retail: 834,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5RCZBTH1;WXJXPR6B',
      statusContent: 'dot',
      hash: 'SE3B2BN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SE3B2BN4'
    },
    {
      status: 210,
      sku: 'PM0073351',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260014',
      rtl_batch_array: '6260014',
      name_search: 'HESBLUE',
      name: "Hebe 'Summer Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJ13TP6X;JN6WS8YA;VRGXE94C;BS28N31E;WE79XT5C;8J6AXDRJ;HVEJ34TG;A51ZBVLG',
      statusContent: 'dot',
      hash: 'PKJA65NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKJA65NS'
    },
    {
      status: 810,
      sku: 'PM0073661',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256769',
      rtl_batch_array: '6256769',
      name_search: 'GEUPPEAC',
      name: "Geum 'Petticoats Peach'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V27NDJ3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V27NDJ3J'
    },
    {
      status: 210,
      sku: 'PM0030219',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6349151',
      rtl_batch_array: '6349151, 5364526, 6133730, 6353451',
      name_search: 'SOGOLDKI',
      name: "Solidago 'Goldkind'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10000,
      chnn_stock_retail: 11109,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHEG4E83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHEG4E83'
    },
    {
      status: 210,
      sku: 'PM0073662',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256771',
      rtl_batch_array: '6256771',
      name_search: 'IRSBEMPE',
      name: "Iris sib. 'Blue Emperor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 1.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84KSHZ1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84KSHZ1K'
    },
    {
      status: 210,
      sku: 'PM0015922',
      core_name: 'Plant',
      sppl_ean: '8720664855373',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6084846',
      rtl_batch_array: '6084846, 6349905',
      name_search: 'CACBRONC',
      name: "Carex comans 'Bronco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2592,
      chnn_stock_retail: 2832,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '232ECEZT;PL3AAZE2;92EJWRVV',
      statusContent: 'dot',
      hash: 'VRA2BYK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRA2BYK2'
    },
    {
      status: 210,
      sku: 'PM0073931',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266199',
      rtl_batch_array: '6266199',
      name_search: 'JUCSTRIC',
      name: "Juniperus chinensis 'Stricta'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 6350,
      chnn_stock_retail: 6350,
      sppl_prcp: 3.281,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4EVN8NWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EVN8NWE'
    },
    {
      status: 210,
      sku: 'PM0073353',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272893',
      rtl_batch_array: '6272893',
      name_search: 'HETRICOL',
      name: "Hebe 'Tricolor'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WN7VSRW;ND6KDLBL',
      statusContent: 'dot',
      hash: 'XLEL4N1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLEL4N1S'
    },
    {
      status: 210,
      sku: 'PM0027562',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 7,
      btch_active_retail: '6317890',
      rtl_batch_array:
        '6301938, 6164306, 6161762, 6317890, 6287680, 6333261, 6334770',
      name_search: 'HEMPPURP',
      name: "Heuchera micrantha 'Palace Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2653,
      chnn_stock_retail: 41777,
      sppl_order_minimum: 3,
      sppl_prcp: 0.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JVG47C77;8RAZ4NEY;7CGSBYGW;ZCB25BW5;6SXB6EWG;CV5JXRTV;VENPDWKV;93HG2PVG;PC4EZA6V;4NE1SRNW;DHNBLKSB;PW83K5DX;WDLJVDJ5;7EZ42RDX',
      statusContent: 'dot',
      hash: 'YCPL6KRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCPL6KRR'
    },
    {
      status: 210,
      sku: 'PM0073354',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272896',
      rtl_batch_array: '6272896',
      name_search: 'HEWJOY',
      name: "Hebe 'Wiri Joy'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1407,
      chnn_stock_retail: 1407,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YHKWTNP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHKWTNP1'
    },
    {
      status: 210,
      sku: 'PM0020056',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6282403',
      rtl_batch_array: '6368809, 6282403',
      name_search: 'PUVRUBRA',
      name: "Pulsatilla vulgaris 'Rubra'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3968,
      chnn_stock_retail: 4968,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6K7WD386;TY2T8R88;JZJX2JZY;HP6Y4Y49',
      statusContent: 'dot',
      hash: 'ZGYVLCEJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGYVLCEJ'
    },
    {
      status: 210,
      sku: 'PM0073355',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272897',
      rtl_batch_array: '6272897',
      name_search: 'HEWMIST',
      name: "Hebe 'Wiri Mist'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 953,
      chnn_stock_retail: 953,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWSVY9XB;HG5VTGLT;DB65BBT1;N7P8YCNS;RNBYGVVD;934SA3RH;5GSPTHDS;G3X39GT6;R8YWH5RT',
      statusContent: 'dot',
      hash: 'VZ8Y7RAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ8Y7RAS'
    },
    {
      status: 210,
      sku: 'PM0030090',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6113113',
      rtl_batch_array: '6295848, 6113113',
      name_search: 'SAURBIUM',
      name: 'Saxifraga urbium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 1849,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7HXV5VBZ;L3E4ZBD3;B81PVL4X;68EEXTBS;Y1D257S7;7JHNYWZ3;H3N5SCLJ;V6G6ZZZ3;J2SP61NS;TLEWLS7N;XGEZYE7X',
      statusContent: 'dot',
      hash: 'KJTDK21S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJTDK21S'
    },
    {
      status: 210,
      sku: 'PM0073158',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258849',
      rtl_batch_array: '6258849',
      name_search: 'RHGOLDFL',
      name: "Rhododendron (AK) 'Goldflamme'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '799E5BRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '799E5BRY'
    },
    {
      status: 210,
      sku: 'PM0073160',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258851',
      rtl_batch_array: '6258851',
      name_search: 'RHODODEN',
      name: "Rhododendron in cv's",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 829,
      chnn_stock_retail: 829,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H4CDLPCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4CDLPCZ'
    },
    {
      status: 210,
      sku: 'PM0024434',
      core_name: 'Plant',
      sppl_ean: '8720349458530',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '4416129',
      rtl_batch_array: '4416129',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '100125C30PY',
      rng_range_identifier: 'PYRAH100125C30',
      rng_range_description: 'Pyramid H100 cm 125 cm C30',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 51.111,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      imageBatch: 'K5HH1E75',
      statusContent: 'dot',
      hash: 'LNRWT9BZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNRWT9BZ'
    },
    {
      status: 210,
      sku: 'PM0073932',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266229',
      rtl_batch_array: '6266229',
      name_search: 'ACSWPEAR',
      name: "Actaea simplex 'White Pearl'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 832,
      chnn_stock_retail: 832,
      sppl_order_minimum: 3,
      sppl_prcp: 2.834,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BV6BHR4A;JPB3NWGK',
      statusContent: 'dot',
      hash: 'T8VGTECD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8VGTECD'
    },
    {
      status: 210,
      sku: 'PM0023555',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 4,
      btch_active_retail: '6356242',
      rtl_batch_array: '6147166, 6242921, 6356242, 6339747',
      name_search: 'ASAASRAN',
      name: "Aster ageratoides 'Asran'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 17963,
      sppl_order_minimum: 3,
      sppl_prcp: 0.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TY81J1LK;D4RYEXTX',
      statusContent: 'dot',
      hash: 'HGNHHHC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGNHHHC4'
    },
    {
      status: 210,
      sku: 'PM0073356',
      core_name: 'Plant',
      btch_manufacturer: 189,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349107',
      rtl_batch_array: '6349107, 6260021',
      name_search: 'HEWMIST',
      name: "Hebe 'Wiri Mist'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4262,
      sppl_prcp: 1.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWSVY9XB;HG5VTGLT;DB65BBT1;N7P8YCNS;RNBYGVVD;934SA3RH;5GSPTHDS;G3X39GT6;R8YWH5RT',
      statusContent: 'dot',
      hash: '4CCA24VK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CCA24VK'
    },
    {
      status: 210,
      sku: 'PM0000557',
      core_name: 'Plant',
      sppl_ean: '8720353031262',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5866467',
      rtl_batch_array: '6229878, 5866467, 6255886',
      name_search: 'BESILBER',
      name: "Bergenia 'Silberlicht'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1807,
      chnn_stock_retail: 3428,
      sppl_order_minimum: 3,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VGT4L25W;EP26NBYA;J5KNXZ2P;2LK6V6TV;LPXEGABJ',
      statusContent: 'dot',
      hash: 'V3X6C6TH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3X6C6TH'
    },
    {
      status: 210,
      sku: 'PM0073584',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260732',
      rtl_batch_array: '6260732',
      name_search: 'LONITIDA',
      name: 'Lonicera nitida',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 6731,
      chnn_stock_retail: 6731,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1JTDKH6;YKG75834;CRAYVTZ4;SSSSXDTD;YP31E8G3;TTA9TLTC;NSZGC3XE;C11D8GKN;R6GTGC3Z;R4RBWG2C',
      statusContent: 'dot',
      hash: 'LVXP6VHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVXP6VHH'
    },
    {
      status: 210,
      sku: 'PM0073159',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258850',
      rtl_batch_array: '6258850',
      name_search: 'RHGWATER',
      name: "Rhododendron 'Gomer Waterer'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 949,
      chnn_stock_retail: 949,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E439JKAS;6N4GX7BY;E34VC6PY;DX6LRX9E;EB1ZPE9C;GWAD9TPG;GSKBKDNB;B72PLSE8;PV8KTLAA;Y9S8YNLK;47APPE71;DC9EX2T8',
      statusContent: 'dot',
      hash: 'XBYCNKS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBYCNKS5'
    },
    {
      status: 210,
      sku: 'PM0073161',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5342779',
      rtl_batch_array: '5342779, 6258852',
      name_search: 'RHMMASSO',
      name: "Rhododendron 'Madame Masson'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1967,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DC4JBPNZ;XLVL5CKS;65GEEXTP;5SRLEJSA;79GY7EDA',
      imageBatch: 'CBZKXCW5',
      statusContent: 'dot',
      hash: 'Y7JTWP23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7JTWP23'
    },
    {
      status: 210,
      sku: 'PM0073162',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356495',
      rtl_batch_array: '5356495, 6258853',
      name_search: 'RHMMENAR',
      name: "Rhododendron 'Marcel Menard'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1970,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPGZGRBE;A5RD7AY5;AW6TELE5;XN8KPRZH;VWSBWTPK;B2J83YR2;KKYTK8V1;VVVGR5LJ',
      imageBatch: '8TGJWHNC',
      statusContent: 'dot',
      hash: 'TX6X787H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TX6X787H'
    },
    {
      status: 210,
      sku: 'PM0073169',
      core_name: 'Plant',
      sppl_ean: '8720626321984',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356491',
      rtl_batch_array: '5356491, 6258861',
      name_search: 'RHRJACK',
      name: "Rhododendron 'Red Jack'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1921,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTR359Z2;EVYB2JXR;6ECWLW1R;C7YK6V62;8BPLAKH3;BXNGD5PR;JP42KWZ9;B42YSXWV;NPSPZH29;BAVHBW8K;AJWVAALW;5E145NR9;2V5SK6VH;EH79L9G6;T9HJHNH5',
      imageBatch: 'T4ZYERK8',
      statusContent: 'dot',
      hash: 'PLAD135G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLAD135G'
    },
    {
      status: 210,
      sku: 'PM0073170',
      core_name: 'Plant',
      sppl_ean: '8720626321991',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356492',
      rtl_batch_array: '5356492, 6258862',
      name_search: 'RHROCKET',
      name: "Rhododendron 'Rocket'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1947,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G845T7ST;4YH8N85J;EXPG1C4B;2ZJVGL8D;8L5J22XW;98Y8BC5P;7AYEVHJV;JS62ABY1;A3GBAAJP;G2HKL7PS;ENHCWSZ2;BC8SHX1W;RZSXYXGW',
      imageBatch: 'LWSE3TYG',
      statusContent: 'dot',
      hash: '8EP6E42B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EP6E42B'
    },
    {
      status: 210,
      sku: 'PM0023062',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 4,
      btch_active_retail: '6382314',
      rtl_batch_array: '6382314, 6308992, 6287699, 6295884',
      name_search: 'SECAERUL',
      name: 'Sesleria caerulea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3900,
      chnn_stock_retail: 9814,
      sppl_order_minimum: 3,
      sppl_prcp: 0.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B1KBABP9',
      statusContent: 'dot',
      hash: 'YGRNT4V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGRNT4V4'
    },
    {
      status: 210,
      sku: 'PM0073181',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258875',
      rtl_batch_array: '6258875',
      name_search: 'ROBONICA',
      name: "Rosa (F) 'Bonica'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '653Z8WT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '653Z8WT7'
    },
    {
      status: 810,
      sku: 'PM0075461',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310207',
      rtl_batch_array: '6280902, 6310207',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 95,
      chnn_stock_retail: 145,
      sppl_prcp: 4.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: '5TKRAP4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5TKRAP4X'
    },
    {
      status: 210,
      sku: 'PM0003106',
      core_name: 'Plant',
      sppl_ean: '8720349470655',
      btch_manufacturer: 734,
      rtl_batch_array_total: 3,
      btch_active_retail: '4706033',
      rtl_batch_array: '4706033, 5408399, 6338170',
      name_search: 'ILECGGEM',
      name: "Ilex crenata 'Golden Gem'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 236,
      chnn_stock_retail: 4451,
      sppl_order_minimum: 5,
      sppl_prcp: 1.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6H1T152;ACELZCTX;L8LWT5T5;DNH7B45X;W1E88G6R;PWBSSNSK;H37PG1Y5;BES2N1AV;1WTGN263;4KJBZN66',
      statusContent: 'dot',
      hash: 'JN95TCNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN95TCNG'
    },
    {
      status: 210,
      sku: 'PM0083056',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356178',
      rtl_batch_array: '6356178',
      name_search: 'LONRTIPS',
      name: "Lonicera nitida 'Red Tips'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 741,
      chnn_stock_retail: 741,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHYT48JB',
      statusContent: 'dot',
      hash: 'KR9BZRSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KR9BZRSX'
    },
    {
      status: 210,
      sku: 'PM0074137',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295903',
      rtl_batch_array: '6295903, 6353556',
      name_search: 'VELBLAUR',
      name: "Veronica longifolia 'Blauriesin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2088,
      chnn_stock_retail: 4356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W8GCVD6K;BDSNS42K',
      statusContent: 'dot',
      hash: 'AX131K8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AX131K8K'
    },
    {
      status: 810,
      sku: 'PM0073937',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266238',
      rtl_batch_array: '6266238',
      name_search: 'AGRLADDE',
      name: "Agastache rugosa 'Little Adder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WEXTELKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEXTELKK'
    },
    {
      status: 210,
      sku: 'PM0015743',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6258788',
      rtl_batch_array: '6258788, 6300748',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 105,
      chnn_stock_retail: 812,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: 'V6YXC5S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6YXC5S2'
    },
    {
      status: 210,
      sku: 'PM0083057',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356179',
      rtl_batch_array: '6356179',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'NWREAPJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWREAPJG'
    },
    {
      status: 210,
      sku: 'PM0026368',
      core_name: 'Plant',
      sppl_ean: '8720349430031',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5405518',
      rtl_batch_array: '5405518',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 3044,
      chnn_stock_retail: 3044,
      sppl_prcp: 13.245,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      imageBatch: 'WZXE813B',
      statusContent: 'dot',
      hash: 'W59E1ZWH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W59E1ZWH'
    },
    {
      status: 210,
      sku: 'PM0012870',
      core_name: 'Plant',
      sppl_ean: '8720349482016',
      btch_manufacturer: 430,
      rtl_batch_array_total: 3,
      btch_active_retail: '5486252',
      rtl_batch_array: '5606397, 6255112, 5486252',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 7119,
      sppl_order_minimum: 3,
      sppl_prcp: 0.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      imageBatch: 'CNKSYJ2A',
      statusContent: 'dot',
      hash: '1KBH2JZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KBH2JZ8'
    },
    {
      status: 210,
      sku: 'PM0073195',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258897',
      rtl_batch_array: '6258897',
      name_search: 'ROSEAFOA',
      name: "Rosa (H) 'Seafoam'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 1378,
      chnn_stock_retail: 1378,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z4K3BW77;ZL15TWEZ;G8YD3E28;29YWS3PR',
      statusContent: 'dot',
      hash: 'EGWESPC8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGWESPC8'
    },
    {
      status: 210,
      sku: 'PM0083058',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356180',
      rtl_batch_array: '6356180',
      name_search: 'MYGALE',
      name: 'Myrica gale',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXV5S2Y2',
      statusContent: 'dot',
      hash: 'CP5T359H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP5T359H'
    },
    {
      status: 210,
      sku: 'PM0083059',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356182',
      rtl_batch_array: '6356182',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '9GL5TKC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GL5TKC2'
    },
    {
      status: 810,
      sku: 'PM0075462',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280903',
      rtl_batch_array: '6280903',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'WHJ6DP5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WHJ6DP5P'
    },
    {
      status: 210,
      sku: 'PM0083060',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356183',
      rtl_batch_array: '6356183',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'Z9Y8X5VE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9Y8X5VE'
    },
    {
      status: 210,
      sku: 'PM0083061',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356184',
      rtl_batch_array: '6356184',
      name_search: 'PEATRIPL',
      name: 'Perovskia atriplicifolia',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PHDWA9YZ;P1AGY8XA;KZ236RX4;1D9W44SL;BS496EAS;46BR76B5;X9G37KWK;589V21D5;HTLLC1B8;GCR521R2;G333GP1G;H8YL8RL4;9YJHKBN9',
      statusContent: 'dot',
      hash: 'DWK1BNN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWK1BNN9'
    },
    {
      status: 210,
      sku: 'PM0083062',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356185',
      rtl_batch_array: '6356185',
      name_search: 'PEABSPRI',
      name: "Perovskia atriplicifolia 'Blue Spritzer'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5VD2HB61',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VD2HB61'
    },
    {
      status: 810,
      sku: 'PM0073940',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266243',
      rtl_batch_array: '6266243',
      name_search: 'DRRUPEST',
      name: 'Dracocephalum rupestre',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7KHSNKPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7KHSNKPZ'
    },
    {
      status: 210,
      sku: 'PM0004392',
      core_name: 'Plant',
      sppl_ean: '8720349406142',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5317840',
      rtl_batch_array: '6313207, 5317840',
      name_search: 'LABSPRIN',
      name: "Lavatera 'Bredon Springs'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 344,
      chnn_stock_retail: 632,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NP9221YV;BRS6TWXV;9GYGT59C',
      statusContent: 'dot',
      hash: 'KNTTHVS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNTTHVS3'
    },
    {
      status: 210,
      sku: 'PM0083063',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356187',
      rtl_batch_array: '6356187',
      name_search: 'PHCTPARK',
      name: "Physocarpus capitatus 'Tilden Park'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TJKB6GN9',
      statusContent: 'dot',
      hash: 'R45HS4RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R45HS4RZ'
    },
    {
      status: 210,
      sku: 'PM0083064',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356188',
      rtl_batch_array: '6356188',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: '89BP96V6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89BP96V6'
    },
    {
      status: 210,
      sku: 'PM0008625',
      core_name: 'Plant',
      sppl_ean: '8720349492596',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5363885',
      rtl_batch_array: '6054396, 5363885, 6302367',
      name_search: 'ASIIBALL',
      name: "Asclepias incarnata 'Ice Ballet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 813,
      sppl_order_minimum: 3,
      sppl_prcp: 0.799,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5LEHGASH',
      statusContent: 'dot',
      hash: 'LEGXGVDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEGXGVDZ'
    },
    {
      status: 210,
      sku: 'PM0083065',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356190',
      rtl_batch_array: '6356190',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: 'HKRGPXCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKRGPXCT'
    },
    {
      status: 210,
      sku: 'PM0073945',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266248',
      rtl_batch_array: '6266248',
      name_search: 'GACANDIC',
      name: 'Galtonia candicans',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AZS4PNLD;4PTG7DL3;5LEDD1YX',
      statusContent: 'dot',
      hash: '2GRGBXPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GRGBXPD'
    },
    {
      status: 210,
      sku: 'PM0083066',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356191',
      rtl_batch_array: '6356191',
      name_search: 'PHOLJOKE',
      name: 'Physocarpus opulifolius Little Joker',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E48CH8E;RPZCTBD6;PNCPDPEJ;5P2VJZ97;SX8GWW1H;15PJWBEG;JTELK2CN;1BSE16EY',
      statusContent: 'dot',
      hash: 'Z6Y7P53D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6Y7P53D'
    },
    {
      status: 210,
      sku: 'PM0023536',
      core_name: 'Plant',
      sppl_ean: '8720664874596',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6084786',
      rtl_batch_array: '6084786, 6370376, 6350697',
      name_search: 'MOARUNDI',
      name: 'Molinia arundinacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 906,
      chnn_stock_retail: 1362,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6C47DXNN',
      statusContent: 'dot',
      hash: 'WYW1ZJEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYW1ZJEB'
    },
    {
      status: 210,
      sku: 'PM0083067',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356192',
      rtl_batch_array: '6356192',
      name_search: 'POFMRROB',
      name: 'Potentilla fruticosa Marian Red Robin',
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ETETN9RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETETN9RL'
    },
    {
      status: 210,
      sku: 'PM0083068',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356193',
      rtl_batch_array: '6356193',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'EHTZW4XJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHTZW4XJ'
    },
    {
      status: 210,
      sku: 'PM0083069',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356194',
      rtl_batch_array: '6356194',
      name_search: 'POFMWHIT',
      name: "Potentilla fruticosa 'McKay's White'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TYSCWZ9E;2CGSLNEZ',
      statusContent: 'dot',
      hash: 'N5R7YV5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5R7YV5C'
    },
    {
      status: 210,
      sku: 'PM0073190',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258888',
      rtl_batch_array: '6258888',
      name_search: 'ROLSELEC',
      name: 'Rosa (F) Lovely Select',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 634,
      chnn_stock_retail: 634,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NRRBE6SB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NRRBE6SB'
    },
    {
      status: 210,
      sku: 'PM0083070',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356195',
      rtl_batch_array: '6356195',
      name_search: 'POFNDAWN',
      name: "Potentilla fruticosa 'New Dawn'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ACCXWDSJ;T3JTXL2S',
      statusContent: 'dot',
      hash: '92CVP7P3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92CVP7P3'
    },
    {
      status: 210,
      sku: 'PM0083071',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356196',
      rtl_batch_array: '6356196',
      name_search: 'POFPBEAU',
      name: "Potentilla fruticosa 'Primrose Beauty'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5HYGEX5;PNXB7VNR;L9L6LZJ1;GAL36RY2;G71E12H6;Y4X3Y5LG',
      statusContent: 'dot',
      hash: 'EW33D72X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW33D72X'
    },
    {
      status: 210,
      sku: 'PM0023290',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6260749',
      rtl_batch_array: '6260749, 5606387',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1288,
      chnn_stock_retail: 2174,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'X768BZRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X768BZRC'
    },
    {
      status: 210,
      sku: 'PM0006503',
      core_name: 'Plant',
      sppl_ean: '8720353026350',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '5256274',
      rtl_batch_array: '5256274',
      name_search: 'HYDBUTTE',
      name: "Hypericum densiflorum 'Buttercup'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1048,
      chnn_stock_retail: 1048,
      sppl_order_minimum: 3,
      sppl_prcp: 1.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XCSK2KXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCSK2KXS'
    },
    {
      status: 210,
      sku: 'PM0083072',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356197',
      rtl_batch_array: '6356197',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: '6XW9W34S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XW9W34S'
    },
    {
      status: 210,
      sku: 'PM0083073',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356198',
      rtl_batch_array: '6356198',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'P4XWHC62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4XWHC62'
    },
    {
      status: 210,
      sku: 'PM0083074',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356199',
      rtl_batch_array: '6356199',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'E5X2L8HY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5X2L8HY'
    },
    {
      status: 210,
      sku: 'PM0073197',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258901',
      rtl_batch_array: '6258901',
      name_search: 'ROSA',
      name: "Rosa in cv's",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 959,
      chnn_stock_retail: 959,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9V6A9V7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V6A9V7B'
    },
    {
      status: 210,
      sku: 'PM0013771',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6243706',
      rtl_batch_array: '6243706, 6164532',
      name_search: 'GECBIOKO',
      name: "Geranium cantabrigiense 'Biokovo'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2862,
      chnn_stock_retail: 3862,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DVDG9E94;WVXXXA4Z;GPH756PG;5EXRA7WG;DXRGE7XS;R2N72X5P;ZAN9PRYS;YLEGBWGZ;L16JD9VE;VNT4AYH4;KYDR38NK;P9723XS5',
      statusContent: 'dot',
      hash: 'LZWHLWS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZWHLWS1'
    },
    {
      status: 210,
      sku: 'PM0083075',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356200',
      rtl_batch_array: '6356200',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'HEY948G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEY948G3'
    },
    {
      status: 210,
      sku: 'PM0073357',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272852',
      rtl_batch_array: '6272852',
      name_search: 'HEWMIST',
      name: "Hebe 'Wiri Mist'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 1085,
      chnn_stock_retail: 1085,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWSVY9XB;HG5VTGLT;DB65BBT1;N7P8YCNS;RNBYGVVD;934SA3RH;5GSPTHDS;G3X39GT6;R8YWH5RT',
      statusContent: 'dot',
      hash: 'H344J7DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H344J7DN'
    },
    {
      status: 210,
      sku: 'PM0083076',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356201',
      rtl_batch_array: '6356201',
      name_search: 'PRLMVERN',
      name: "Prunus laurocerasus 'Mount Vernon'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59WNGARJ',
      statusContent: 'dot',
      hash: 'BGXYZJLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGXYZJLE'
    },
    {
      status: 210,
      sku: 'PM0083077',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356202',
      rtl_batch_array: '6356202',
      name_search: 'PYCRCUSH',
      name: "Pyracantha coccinea 'Red Cushion'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 4142,
      chnn_stock_retail: 4142,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E2ZJ389V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2ZJ389V'
    },
    {
      status: 210,
      sku: 'PM0083078',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356203',
      rtl_batch_array: '6356203',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 8.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: 'YV2WEBKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YV2WEBKN'
    },
    {
      status: 210,
      sku: 'PM0083079',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356204',
      rtl_batch_array: '6356204',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'YDHEVHW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDHEVHW5'
    },
    {
      status: 210,
      sku: 'PM0073585',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260735',
      rtl_batch_array: '6260735',
      name_search: 'MISKASKA',
      name: "Miscanthus sinensis 'Kaskade'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 563,
      chnn_stock_retail: 563,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G6NDA2CX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6NDA2CX'
    },
    {
      status: 210,
      sku: 'PM0028731',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6355678',
      rtl_batch_array: '6209816, 6355678, 6348809',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 5400,
      chnn_stock_retail: 6984,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'Z6JNJP1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6JNJP1P'
    },
    {
      status: 910,
      sku: 'PM0000149',
      core_name: 'Plant',
      sppl_ean: '8720349432196',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '5943792',
      rtl_batch_array: '5943792, 6014244, 6291723',
      name_search: 'OMCCINGR',
      name: "Omphalodes cappadocica 'Cherry Ingram'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 13,
      chnn_stock_retail: 1199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6DHEWEY;R6SL4GZY;SD4S1947',
      statusContent: 'dot',
      hash: 'N723DDL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N723DDL3'
    },
    {
      status: 210,
      sku: 'PM0073189',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258887',
      rtl_batch_array: '6258887',
      name_search: 'ROKORMAX',
      name: "Rosa (H) 'Kormax'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1NHWLKLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NHWLKLH'
    },
    {
      status: 810,
      sku: 'PM0005319',
      core_name: 'Plant',
      sppl_ean: '8720353022185',
      btch_manufacturer: 120,
      rtl_batch_array_total: 1,
      btch_active_retail: '4707319',
      rtl_batch_array: '4707319',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 2.367,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'NELW2KRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NELW2KRN'
    },
    {
      status: 910,
      sku: 'PM0029149',
      core_name: 'Plant',
      sppl_ean: '8720353011424',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '5250349',
      rtl_batch_array: '5250349',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 1.834,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'ZNNYR66T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNNYR66T'
    },
    {
      status: 210,
      sku: 'PM0009100',
      core_name: 'Plant',
      sppl_ean: '8720626346536',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 4,
      btch_active_retail: '5411099',
      rtl_batch_array: '6209143, 5461103, 5411099, 6307439',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 640,
      chnn_stock_retail: 3374,
      sppl_prcp: 5.153,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      imageBatch: '76P9ZAKH',
      statusContent: 'dot',
      hash: 'NP75VRWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NP75VRWK'
    },
    {
      status: 810,
      sku: 'PM0075463',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280904',
      rtl_batch_array: '6280904',
      name_search: 'CEORBICU',
      name: 'Celastrus orbiculatus',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SGBGYSH8;RSYE9E7R',
      statusContent: 'dot',
      hash: 'XV639NA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV639NA1'
    },
    {
      status: 210,
      sku: 'PM0015010',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6217220',
      rtl_batch_array: '5902409, 6217220, 6353372',
      name_search: 'TECHAMAE',
      name: 'Teucrium chamaedrys',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 206,
      chnn_stock_retail: 1692,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BRBR2EJ5;6G6N4CV8;ANHN6Z3K;JP7VWP17;9A8J2A3W;9YHVJD32;JYTGPKGX;CEY7ZVYW;N8Y9PHGR',
      statusContent: 'dot',
      hash: '166WA1L7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '166WA1L7'
    },
    {
      status: 210,
      sku: 'PM0011297',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6324930',
      rtl_batch_array: '6221379, 6301085, 6317851, 6385473, 6339821, 6324930',
      name_search: 'GALWBUTT',
      name: "Gaura lindheimeri 'Whirling Butterflies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 24899,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTLZ5ECG;HVJTDHN5;7JGCXCSR;C82KY3XL;RNKR1E1Y;R9VJC266;AT5E56RB;B5JLPNG5;7X8BP9VG;VPD9H31H;Z5XX96DY;E48BGWSX;TG86L5VH;VS26TCVT;LBREGA43',
      statusContent: 'dot',
      hash: 'ZXVE56JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXVE56JH'
    },
    {
      status: 210,
      sku: 'PM0026391',
      core_name: 'Plant',
      sppl_ean: '8720349408351',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4368427',
      rtl_batch_array: '4368427',
      name_search: 'ACGGDELI',
      name: "Acorus gramineus 'Golden Delight'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.099,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LS4SL112',
      imageBatch: 'P1AS286W',
      statusContent: 'dot',
      hash: 'P281RYWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P281RYWV'
    },
    {
      status: 210,
      sku: 'PM0012611',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6348986',
      rtl_batch_array: '5891250, 6350301, 6295628, 6348986',
      name_search: 'HELMBEAU',
      name: "Helenium 'Moerheim Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 6101,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BC2XLJ8X;E938N398;NZRE4ASR;67TJGVZ8;BJ4HV1LH;JDJ8AS9V;BHC3ETJW;WED4CXX3;XCSW959Y;DZBL1TS2;W1757Y63',
      statusContent: 'dot',
      hash: '2BJTC2CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BJTC2CJ'
    },
    {
      status: 210,
      sku: 'PM0021534',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242035',
      rtl_batch_array: '6329847, 6242035, 6349762',
      name_search: 'ASAHEND',
      name: "Aster alpinus 'Happy End'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10764,
      chnn_stock_retail: 11469,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LKZ14YZ8;PLL31GRD;SBRD8R2P;9XVYN4NZ;NC2LN2ES;C6J3HANW',
      statusContent: 'dot',
      hash: '4WLDXP35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WLDXP35'
    },
    {
      status: 210,
      sku: 'PM0024530',
      core_name: 'Plant',
      sppl_ean: '8720349424580',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '4345378',
      rtl_batch_array: '4345378, 6256070',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 498,
      chnn_stock_retail: 613,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      imageBatch: '9GC3S2VB',
      statusContent: 'dot',
      hash: '4WWZPYPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WWZPYPD'
    },
    {
      status: 210,
      sku: 'PM0006751',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348961',
      rtl_batch_array: '6107938, 6350483, 6348961',
      name_search: 'HETAPEST',
      name: "Heucherella 'Tapestry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 1130,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YVTTB9PE;6W52CDZL;VALKEJ77;X7PT2GC9;AXZVKTH9;2BX36XGJ;V6YYRGDY;9XJJ7G71;J2XDTE5H;JTW6AJHA;WT5Y4JRZ;YER9ZAC2;B36V7HSN;85C1251S;JW7EHWDV;XDTTRL8B;K8WGN4JA',
      statusContent: 'dot',
      hash: '7HYWZXWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HYWZXWE'
    },
    {
      status: 210,
      sku: 'PM0008963',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220376',
      rtl_batch_array: '6220376, 5488232, 6270691',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 235,
      chnn_stock_retail: 1963,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: '7R1C57CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R1C57CH'
    },
    {
      status: 210,
      sku: 'PM0027047',
      core_name: 'Plant',
      sppl_ean: '8720349428380',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5357088',
      rtl_batch_array: '5357088',
      name_search: 'SANSDROS',
      name: 'Salvia nemorosa Sensation Deep Rose',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 3036,
      chnn_stock_retail: 3036,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'JLVBAR5Z',
      statusContent: 'dot',
      hash: 'A8316A6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8316A6E'
    },
    {
      status: 210,
      sku: 'PM0073939',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266242',
      rtl_batch_array: '6266242',
      name_search: 'CEDEALBA',
      name: 'Centaurea dealbata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E7ASRY1E;V5ABGCLS;G5RD93CA;TYV9PZWS;8KHGG8W8;SCDJR9JT;JETACTPN',
      statusContent: 'dot',
      hash: '2ZET53GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZET53GL'
    },
    {
      status: 810,
      sku: 'PM0009358',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '5356255',
      rtl_batch_array: '5356255, 6258793',
      name_search: 'HYPSDOLL',
      name: "Hydrangea paniculata 'Silver Dollar'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 549,
      sppl_prcp: 9.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XXJDS63L;5CJAS3C4;9VHZJPSG;GYBVCCSX;EE2S8CH8',
      statusContent: 'dot',
      hash: 'PLVH2SD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLVH2SD1'
    },
    {
      status: 210,
      sku: 'PM0073665',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6257789',
      rtl_batch_array: '6257789, 6352664',
      name_search: 'PEBMARY',
      name: "Persicaria 'Bloody Mary'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 847,
      chnn_stock_retail: 941,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A82TV754',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A82TV754'
    },
    {
      status: 210,
      sku: 'PM0073666',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257790',
      rtl_batch_array: '6257790',
      name_search: 'PRGBROSE',
      name: "Prunella grand. 'Bella Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 559,
      chnn_stock_retail: 559,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BSNSJX5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSNSJX5N'
    },
    {
      status: 210,
      sku: 'PM0073667',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257791',
      rtl_batch_array: '6257791',
      name_search: 'SACSASNO',
      name: "Salvia 'Color Spires Azure Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z5RNBT8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5RNBT8E'
    },
    {
      status: 210,
      sku: 'PM0073933',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266232',
      rtl_batch_array: '6266232',
      name_search: 'PRANGULA',
      name: 'Pratia angulata',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VEXBK2G;T63T3H2G',
      statusContent: 'dot',
      hash: 'LYVRNT9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYVRNT9J'
    },
    {
      status: 210,
      sku: 'PM0073935',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266234',
      rtl_batch_array: '6266234',
      name_search: 'VEPORPHY',
      name: 'Veronica porphyriana',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SY4VRC1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY4VRC1L'
    },
    {
      status: 210,
      sku: 'PM0073944',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266247',
      rtl_batch_array: '6266247',
      name_search: 'EURCHOCO',
      name: "Eupatorium rugosum 'Chocolate'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 551,
      chnn_stock_retail: 551,
      sppl_prcp: 3.101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ELHJWXHB;B6PGZ96Z;28AAJBYT;67L1GJ6L;ZVKBGHLZ;Z6141NHY;YSCPV2H2;6C51W2T4;C5EK5RAZ',
      statusContent: 'dot',
      hash: 'DVSY618W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVSY618W'
    },
    {
      status: 810,
      sku: 'PM0073947',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266251',
      rtl_batch_array: '6266251',
      name_search: 'KNMPOPSI',
      name: "Kniphofia 'Mango Popsicle'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 3.299,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LZTRXAZJ;8G9EGHCA',
      statusContent: 'dot',
      hash: '62SNYWPX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62SNYWPX'
    },
    {
      status: 210,
      sku: 'PM0023222',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6351247',
      rtl_batch_array: '6301831, 6161946, 6351247',
      name_search: 'SEBANDER',
      name: "Sedum 'Bertram Anderson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1170,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PRC7VSRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRC7VSRZ'
    },
    {
      status: 210,
      sku: 'PM0029125',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348303',
      rtl_batch_array: '6220704, 6037872, 6348303',
      name_search: 'DISALBA',
      name: "Dicentra spectabilis 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 3904,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XB5C666R',
      statusContent: 'dot',
      hash: 'TLTCNNLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLTCNNLL'
    },
    {
      status: 210,
      sku: 'PM0027290',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177567',
      rtl_batch_array: '6305779, 6177567',
      name_search: 'ACMOONSH',
      name: "Achillea 'Moonshine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 941,
      chnn_stock_retail: 1132,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G3X7GWWB;BJRE1T1Y;14JPCRLS;9APDES7D;A9PJDZH6;KP1BJ1AB;4NBPEHHB;EAZ186NE',
      statusContent: 'dot',
      hash: 'TSAYV8S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSAYV8S8'
    },
    {
      status: 810,
      sku: 'PM0074135',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267803',
      rtl_batch_array: '6267803',
      name_search: 'PORIGENS',
      name: 'Polystichum rigens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9KVWK4N;WVKG937V;NVGS6K62;BDP9Z29X;1GXD3Y3T;CHB5C51K;4L6JBWEX;L4K8R4B8;619VN7CS;16N2564D;BZLR36J7;KPW2TDWC;YN9VX21E;8RJBX9BJ;9A9KX86L;PHT5P3K8;WR4C3Y9C;Y5S59DWK;9BVNKD4S',
      statusContent: 'dot',
      hash: 'Z817RCCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z817RCCK'
    },
    {
      status: 810,
      sku: 'PM0075458',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280899',
      rtl_batch_array: '6280899',
      name_search: 'CARFLAVA',
      name: "Campsis radicans 'Flava'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JCHVEJWX;955JNY8S;NAVWW5X8;LRPCG31B;L7R7ZV3X;YJWS76ZG',
      statusContent: 'dot',
      hash: '55B4JN5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55B4JN5C'
    },
    {
      status: 810,
      sku: 'PM0075459',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280900',
      rtl_batch_array: '6280900',
      name_search: 'CATISUMM',
      name: 'Campsis tagliabuana Indian Summer',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A4D2T6GZ;BAGLBD1A;74ZAY3KJ;87T3ZTTE;9J6LKBSD;WTKCY4SJ;SSX8CH7W;WRNHCCAC;NW6YT12K;XGA158PB',
      statusContent: 'dot',
      hash: 'E3CZ6KJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3CZ6KJT'
    },
    {
      status: 810,
      sku: 'PM0075460',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280901',
      rtl_batch_array: '6280901',
      name_search: 'CACGBLEU',
      name: 'Caryopteris clandonensis Grand Bleu',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3H34PNG;K7VDHP4Z;12BRVYG3',
      statusContent: 'dot',
      hash: 'KLG4CSNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLG4CSNB'
    },
    {
      status: 210,
      sku: 'PM0002843',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6276027',
      rtl_batch_array: '6329925, 6276027, 6308564, 6334767',
      name_search: 'ERKARVIN',
      name: 'Erigeron karvinskianus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 19568,
      chnn_stock_retail: 21899,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLCP6LRG;VHVSLXHH;D1XJ7VCY;CN8JXGLS;ZZJ48WYR;SXECCCSZ;DX1PEKZ3;XE6HBY14;55VYV45P;EDLAC4EH;6AEWVSK9',
      statusContent: 'dot',
      hash: 'DDS4962S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDS4962S'
    },
    {
      status: 210,
      sku: 'PM0029526',
      core_name: 'Plant',
      sppl_ean: '8720353018485',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5398892',
      rtl_batch_array: '5398892',
      name_search: 'PHOPERSP',
      name: "Physocarpus opulifolius 'Perspectiva'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DG1BATG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG1BATG3'
    },
    {
      status: 210,
      sku: 'PM0023576',
      core_name: 'Plant',
      sppl_ean: '8720349408764',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '5507464',
      rtl_batch_array: '5507464, 6349552, 6159849, 6176234',
      name_search: 'AJRBGLOW',
      name: "Ajuga reptans 'Burgundy Glow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 5577,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '614YNKVE;S4LL61CA;YDC65587;2KN5JY1L;EEH4HT6A;LK5D34TL;W7JD9K2Y;DHTC8LKN;61EA394V;1KTXVB6E;L5SRBJ74;KR5RDRHB;D7GAPN92;CWC4CNXP;R2NA1BCR;RSLRBAGN',
      statusContent: 'dot',
      hash: 'E6SRLY85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6SRLY85'
    },
    {
      status: 210,
      sku: 'PM0030503',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356128',
      rtl_batch_array: '5420626, 6356128',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 3250,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'HEETVJTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEETVJTN'
    },
    {
      status: 210,
      sku: 'PM0005889',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284524',
      rtl_batch_array: '6284524, 6304882',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 2860,
      sppl_prcp: 6.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'KRLX384Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRLX384Y'
    },
    {
      status: 210,
      sku: 'PM0021180',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6231162',
      rtl_batch_array: '6084935, 6231162, 5364093, 6295600',
      name_search: 'GEHIMALA',
      name: 'Geranium himalayense',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 4245,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HHAD65G;B1CHVKC4',
      statusContent: 'dot',
      hash: 'LC9BLRT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC9BLRT9'
    },
    {
      status: 210,
      sku: 'PM0029646',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6270701',
      rtl_batch_array: '4345377, 6298720, 6270701',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3786,
      chnn_stock_retail: 5264,
      sppl_prcp: 2.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'RK5V9654',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RK5V9654'
    },
    {
      status: 210,
      sku: 'PM0029111',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6196868',
      rtl_batch_array: '6196868, 6284300, 6164366, 6291706',
      name_search: 'ASPAKIPP',
      name: "Aster (D) 'Prof. Anton Kippenberg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7311,
      chnn_stock_retail: 17938,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8R4P2H6;YCRCK5ZY;2RYWKLH8;KR6LX94K;WKDHV3J6',
      statusContent: 'dot',
      hash: 'NPRGHWBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPRGHWBR'
    },
    {
      status: 210,
      sku: 'PM0019829',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6177420',
      rtl_batch_array: '6164319, 6329973, 6333268, 6177420',
      name_search: 'IRFOETID',
      name: 'Iris foetidissima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 131,
      chnn_stock_retail: 1764,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '158BR7Y2',
      statusContent: 'dot',
      hash: 'H743TB9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H743TB9J'
    },
    {
      status: 210,
      sku: 'PM0005178',
      core_name: 'Plant',
      sppl_ean: '8720664857940',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6084864',
      rtl_batch_array: '6084864, 6136153, 5363985, 6192706',
      name_search: 'CORADREA',
      name: "Coreopsis rosea 'American Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 653,
      chnn_stock_retail: 2646,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KX3L1DWL;RDV5Y8EZ;G2BGTHW5;WLP5VT4H;XH5SBB4T;EJRLK6BT;K286J448;V9HPNX4R',
      statusContent: 'dot',
      hash: 'H4SDG8CN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4SDG8CN'
    },
    {
      status: 210,
      sku: 'PM0003756',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6355355',
      rtl_batch_array: '6350023, 5364023, 6355355',
      name_search: 'ECPALBA',
      name: "Echinacea purpurea 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3557,
      chnn_stock_retail: 6674,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSG792BE;BWC2X2CD;JD32Y745',
      statusContent: 'dot',
      hash: '6CTYJ35V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CTYJ35V'
    },
    {
      status: 810,
      sku: 'PM0073952',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266273',
      rtl_batch_array: '6266273',
      name_search: 'CISPINK',
      name: "Cistus 'Silver Pink'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NX5X9GRG;T8WT239W;G136DWEZ;2ABGJ151',
      statusContent: 'dot',
      hash: 'RRANCSN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRANCSN3'
    },
    {
      status: 210,
      sku: 'PM0027754',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6383347',
      rtl_batch_array: '6202281, 6301526, 6383347',
      name_search: 'DECBEAUT',
      name: "Delphinium (B) 'Cliveden Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 764,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VDAXYYAD;6NJ4JPKY;ZX27DT7E;D3JB2N6P;PLGA68G3;BTXTBNPK;XA5GYY5K;EHAES4PE;CLBWE14Z;7ZR8TDKH;K8RZSEVT;T28E2X45',
      statusContent: 'dot',
      hash: '62X3599T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62X3599T'
    },
    {
      status: 210,
      sku: 'PM0011287',
      core_name: 'Plant',
      sppl_ean: '8720626305205',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364240',
      rtl_batch_array: '5364240',
      name_search: 'HOLLTUFT',
      name: "Hosta 'Lakeside Little Tuft'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_order_minimum: 3,
      sppl_prcp: 1.294,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S24NV5LL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S24NV5LL'
    },
    {
      status: 210,
      sku: 'PM0005451',
      core_name: 'Plant',
      sppl_ean: '8720664539662',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495326',
      rtl_batch_array: '6350231, 5495326, 6352697',
      name_search: 'GEPPENNY',
      name: "Geranium 'Pink Penny'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1169,
      chnn_stock_retail: 2949,
      sppl_order_minimum: 3,
      sppl_prcp: 1.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W5A9GEBA;Z4AL5RJY;XLADXG75',
      statusContent: 'dot',
      hash: '63KPPLG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63KPPLG8'
    },
    {
      status: 210,
      sku: 'PM0083080',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356205',
      rtl_batch_array: '6356205',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2300,
      chnn_stock_retail: 2300,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: 'CVR1XSZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVR1XSZ8'
    },
    {
      status: 210,
      sku: 'PM0004529',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6220240',
      rtl_batch_array: '6220240, 6353197, 6345207',
      name_search: 'RUOGWIZA',
      name: "Rudbeckia occidentalis 'Green Wizard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 1157,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VC7YCKPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VC7YCKPL'
    },
    {
      status: 210,
      sku: 'PM0083081',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356206',
      rtl_batch_array: '6356206',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: 'PXL9NZSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXL9NZSV'
    },
    {
      status: 210,
      sku: 'PM0073203',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258913',
      rtl_batch_array: '6258913',
      name_search: 'SYMGALAX',
      name: 'Symphoricarpos Magical Galaxy',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 9.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RT645DGD;NJ4KXSDW;GNTTZ66X;RCH61A3S;ZXKP1L7X;6DB72G4C;9LYGJ58B;Z7ZH1SP1;186SXXEP;JNCY3PWB',
      statusContent: 'dot',
      hash: '4BY2W17L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BY2W17L'
    },
    {
      status: 210,
      sku: 'PM0004552',
      core_name: 'Plant',
      sppl_ean: '8720626395145',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495213',
      rtl_batch_array: '6077585, 6329869, 5495213',
      name_search: 'BEBRUBY',
      name: "Bergenia 'Bressingham Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 172,
      chnn_stock_retail: 371,
      sppl_order_minimum: 3,
      sppl_prcp: 1.294,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9V5ZKS35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V5ZKS35'
    },
    {
      status: 210,
      sku: 'PM0013064',
      core_name: 'Plant',
      sppl_ean: '8720353097305',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495289',
      rtl_batch_array: '5495289',
      name_search: 'ECWMEDIT',
      name: 'Echinacea White Meditation',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1159,
      chnn_stock_retail: 1159,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29VCD5D8;T2JC9YVV;PCZSSD2T;TWZDSSAA;L5XSDATN;6X1T2ZNY;58JY4BK2;VDBRDK7A;657R16V2',
      statusContent: 'dot',
      hash: 'YJ68GY17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ68GY17'
    },
    {
      status: 210,
      sku: 'PM0083082',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356207',
      rtl_batch_array: '6356207',
      name_search: 'SPBISLAN',
      name: "Spiraea betulifolia 'Island'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H5LPBXLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5LPBXLS'
    },
    {
      status: 210,
      sku: 'PM0073207',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258917',
      rtl_batch_array: '6258917',
      name_search: 'SYVALSPA',
      name: "Syringa vulgaris 'Andenken an Ludwig Sp\u00e4th'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 923,
      chnn_stock_retail: 923,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRGBPK4G;6W7N2D5D;D7XLY3P8;YYKXS5EV;1Z9Y13PK;EA5N6983;XWYVAGKV',
      statusContent: 'dot',
      hash: 'XBA2J2C2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBA2J2C2'
    },
    {
      status: 910,
      sku: 'PM0074175',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345501',
      rtl_batch_array: '6345501',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      sppl_size_code: '150STC15',
      rng_range_identifier: 'STE150C15',
      rng_range_description: 'Stem 150 cm C15',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 27.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'Y56HG57G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y56HG57G'
    },
    {
      status: 210,
      sku: 'PM0073955',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266277',
      rtl_batch_array: '6266277',
      name_search: 'CIOBTUSI',
      name: 'Cistus obtusifolius',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X5TCVZEC;YZ8G2AX1',
      statusContent: 'dot',
      hash: 'B6THDSYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6THDSYT'
    },
    {
      status: 210,
      sku: 'PM0073210',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258920',
      rtl_batch_array: '6258920',
      name_search: 'SYVMBUCH',
      name: "Syringa vulgaris 'Michel Buchner'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y23AK5K;KXHJ5622;Z4RZ4HJC;KDPADEKY;6L8SRP7Y;CKV91A49',
      statusContent: 'dot',
      hash: 'EC5E56E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EC5E56E1'
    },
    {
      status: 210,
      sku: 'PM0073211',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258921',
      rtl_batch_array: '6258921',
      name_search: 'SYVFSTEP',
      name: "Syringa vulgaris 'Mme Florent Stepman'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 978,
      chnn_stock_retail: 978,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58774N2R;TN5WK3DC;LJ78SRBR;8E4Y8Y37',
      statusContent: 'dot',
      hash: 'CVVSWHXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVVSWHXH'
    },
    {
      status: 210,
      sku: 'PM0074150',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258844',
      rtl_batch_array: '6258844',
      name_search: 'RHSILBER',
      name: "Rhododendron (Y) 'Silberwolke'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_prcp: 16.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KZYRSE6E;D2PVRJHH;AKLHL4K5;8K5ZJCNX;223PGK2A;JYZV78PC;1LDY6RAR;XSRRG7HP;H2JJLLE5',
      statusContent: 'dot',
      hash: 'LR1S84PZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LR1S84PZ'
    },
    {
      status: 210,
      sku: 'PM0073208',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258918',
      rtl_batch_array: '6258918',
      name_search: 'SYVCJOLY',
      name: "Syringa vulgaris 'Charles Joly'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 972,
      chnn_stock_retail: 972,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JEKZCD8X;DLCWPTG4;7SBXAEZ1',
      statusContent: 'dot',
      hash: '8KHYD5HW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KHYD5HW'
    },
    {
      status: 810,
      sku: 'PM0074138',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6187505',
      rtl_batch_array: '6187505',
      name_search: 'ASNBBLAU',
      name: "Aster novi-belgii 'Blaukuppel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HXD5V38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HXD5V38'
    },
    {
      status: 910,
      sku: 'PM0074161',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIPLUMAR',
      name: 'Dianthus plumarius',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '66P87XDR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '66P87XDR'
    },
    {
      status: 810,
      sku: 'PM0073956',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266278',
      rtl_batch_array: '6266278',
      name_search: 'CIPSUNSE',
      name: "Cistus pulverulentus 'Sunset'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KHBKRPTZ;N9XVKGW9;WW392LVJ',
      statusContent: 'dot',
      hash: 'GSXKXBHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSXKXBHW'
    },
    {
      status: 210,
      sku: 'PM0073586',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260750',
      rtl_batch_array: '6260750',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 2236,
      chnn_stock_retail: 2236,
      sppl_prcp: 2.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'BDTNHAW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDTNHAW2'
    },
    {
      status: 210,
      sku: 'PM0073587',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260751',
      rtl_batch_array: '6260751',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1848,
      chnn_stock_retail: 1848,
      sppl_prcp: 2.782,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'W8ND6NSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8ND6NSV'
    },
    {
      status: 210,
      sku: 'PM0073588',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260753',
      rtl_batch_array: '6260753',
      name_search: 'ALSBEAUT',
      name: "Allium 'Summer Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2355,
      chnn_stock_retail: 2355,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ASST656V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASST656V'
    },
    {
      status: 210,
      sku: 'PM0083083',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356208',
      rtl_batch_array: '6356208',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'BW6JXKPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BW6JXKPK'
    },
    {
      status: 210,
      sku: 'PM0073949',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266263',
      rtl_batch_array: '6266263',
      name_search: 'SAMGLETS',
      name: "Salvia microphylla 'Gletsjer'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANWB6KBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANWB6KBN'
    },
    {
      status: 210,
      sku: 'PM0012398',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6309015',
      rtl_batch_array: '5363846, 6207789, 6295444, 6309015',
      name_search: 'ALSCHOEN',
      name: 'Allium schoenoprasum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9454,
      chnn_stock_retail: 16486,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9X6T5V58;BVLDH82B;SH7PSE16;6W1XW6SL;EJZRPV4D;WC9WCTP2;5HD7LR8G;NBB1LKND;7KL21XJ6;WJ6C4JVJ;5EVEPACW;TSR4CVNC;KADNX2DN',
      statusContent: 'dot',
      hash: 'DH2HJ2D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DH2HJ2D7'
    },
    {
      status: 210,
      sku: 'PM0073950',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266265',
      rtl_batch_array: '6266265',
      name_search: 'SAFPEACO',
      name: 'Salvia Feathers Peacock',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 814,
      chnn_stock_retail: 814,
      sppl_prcp: 2.804,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2L51RBSA;WTRR24BE;9RDRN125;L74D152P;BS7XKBLX;8GBACD72;YTDDLL55',
      statusContent: 'dot',
      hash: 'K2SYWLX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2SYWLX3'
    },
    {
      status: 910,
      sku: 'PM0073954',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CILAURIF',
      name: 'Cistus laurifolius',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: '9GLZSW66',
      statusContent: 'dot',
      hash: 'AAE6NTB8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AAE6NTB8'
    },
    {
      status: 210,
      sku: 'PM0074144',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307864',
      rtl_batch_array: '6307864',
      name_search: 'HULPIONE',
      name: "Humulus l. 'Pioneer'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '462N8P7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '462N8P7W'
    },
    {
      status: 210,
      sku: 'PM0074145',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258837',
      rtl_batch_array: '6258837',
      name_search: 'RHBLURET',
      name: "Rhododendron (Y) 'Blurettia'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_prcp: 16.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DWBDJHJ;S8H774R6;99VXTZP1;YLYJT175;YLGHG2SL;ZCBDTSE6;79JPDNL6;V9XK12SC',
      statusContent: 'dot',
      hash: '59XD84GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59XD84GD'
    },
    {
      status: 210,
      sku: 'PM0083084',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356209',
      rtl_batch_array: '6356209',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '2N9JNY55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N9JNY55'
    },
    {
      status: 210,
      sku: 'PM0083085',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356210',
      rtl_batch_array: '6356210',
      name_search: 'SPDECUMB',
      name: 'Spiraea decumbens',
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AHYKV86Y',
      statusContent: 'dot',
      hash: 'NZ9CVT9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZ9CVT9K'
    },
    {
      status: 210,
      sku: 'PM0074149',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258843',
      rtl_batch_array: '6258843',
      name_search: 'RHOSCHNE',
      name: "Rhododendron (AJ) 'Schneeglanz'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 16.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '98KHES6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98KHES6H'
    },
    {
      status: 210,
      sku: 'PM0016438',
      core_name: 'Plant',
      sppl_ean: '8720664859418',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6143848',
      rtl_batch_array: '6187537, 6143848',
      name_search: 'DEGALAHA',
      name: "Delphinium (P) 'Galahad'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4158,
      chnn_stock_retail: 4342,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2H9ST199;Z4Y6J6YT;YPH9Y82H;RXX5YV67;9TDBPBKW;C1P7NX6T',
      statusContent: 'dot',
      hash: '28793AK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28793AK8'
    },
    {
      status: 210,
      sku: 'PM0083086',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356211',
      rtl_batch_array: '6356211',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '020025C13',
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'DD4ZDW2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD4ZDW2G'
    },
    {
      status: 210,
      sku: 'PM0016213',
      core_name: 'Plant',
      sppl_ean: '8720664801325',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112843',
      rtl_batch_array: '6112843, 5495454',
      name_search: 'PERADRED',
      name: "Persicaria amplexicaulis 'Dark Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1198,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCS22NS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCS22NS3'
    },
    {
      status: 210,
      sku: 'PM0018108',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6196998',
      rtl_batch_array: '6196998, 6334360, 6254837, 6083724',
      name_search: 'SEHERBST',
      name: "Sedum 'Herbstfreude'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1812,
      chnn_stock_retail: 6533,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P1SA8853;SP34B88L;KDVZDRVP;TSXB4ZGP;PL3Z54Y6;6R64C6KZ;WTZ1ALZ3;NCR16GGC;BT3LJ6TC;XACL25K2;LNBHRD3P;A53L33R4;R8BANN8D;97LVH7GN;4WAX5TZP;E1NNJE6Z;4VZ2LD1K;48YCYHLX;BALG54TJ',
      statusContent: 'dot',
      hash: 'XX98T7T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX98T7T9'
    },
    {
      status: 210,
      sku: 'PM0028581',
      core_name: 'Plant',
      sppl_ean: '8720353025278',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '5503938',
      rtl_batch_array: '5503938, 6254875',
      name_search: 'AJRBGLOW',
      name: "Ajuga reptans 'Burgundy Glow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1941,
      chnn_stock_retail: 3183,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '614YNKVE;S4LL61CA;YDC65587;2KN5JY1L;EEH4HT6A;LK5D34TL;W7JD9K2Y;DHTC8LKN;61EA394V;1KTXVB6E;L5SRBJ74;KR5RDRHB;D7GAPN92;CWC4CNXP;R2NA1BCR;RSLRBAGN',
      statusContent: 'dot',
      hash: '8JC2NCN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8JC2NCN2'
    },
    {
      status: 210,
      sku: 'PM0083087',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356212',
      rtl_batch_array: '6356212',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: '266EVPZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '266EVPZY'
    },
    {
      status: 210,
      sku: 'PM0083088',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356213',
      rtl_batch_array: '6356213',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '020025C13',
      rng_range_identifier: 'H020025C1.3',
      rng_range_description: 'H20 cm 25 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'GVWWC644',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVWWC644'
    },
    {
      status: 810,
      sku: 'PM0074165',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345492',
      rtl_batch_array: '6345492',
      name_search: 'LILTRICO',
      name: "Ligustrum lucidum 'Tricolor'",
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L47EZPX7;1JPTW5C3',
      statusContent: 'dot',
      hash: 'ZSWKB5PC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSWKB5PC'
    },
    {
      status: 210,
      sku: 'PM0021412',
      core_name: 'Plant',
      sppl_ean: '8720664852518',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112294',
      rtl_batch_array: '6112294, 6301434',
      name_search: 'ASLBLUE',
      name: "Aster (D) 'Lady in Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 1530,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RXWS95T8;VYD8H728;11PP25P3;WB45553X;G5X9H2JE;W4RWJP6N;JT96Z8V1',
      statusContent: 'dot',
      hash: 'AAEZHH4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAEZHH4R'
    },
    {
      status: 810,
      sku: 'PM0074166',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345491',
      rtl_batch_array: '6345491',
      name_search: 'LILTRICO',
      name: "Ligustrum lucidum 'Tricolor'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_prcp: 13.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L47EZPX7;1JPTW5C3',
      statusContent: 'dot',
      hash: 'X7YNLG98',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7YNLG98'
    },
    {
      status: 210,
      sku: 'PM0074151',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258845',
      rtl_batch_array: '6258845',
      name_search: 'RHTHEINJ',
      name: "Rhododendron (Y) 'Tina Heinje'",
      sppl_size_code: '080STC74',
      rng_range_identifier: 'STE080C7.5',
      rng_range_description: 'Stem 80 cm C7.5',
      sppl_stock_available: 337,
      chnn_stock_retail: 337,
      sppl_prcp: 16.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLAHTX1T;RCHCLDDX;YS6NDZ49;YCY6X198;B6XLWGRX',
      statusContent: 'dot',
      hash: '8RB7BLWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RB7BLWZ'
    },
    {
      status: 210,
      sku: 'PM0015853',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220370',
      rtl_batch_array: '6220370, 6270678',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 606,
      chnn_stock_retail: 1074,
      sppl_prcp: 2.042,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: 'N3S2CJHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3S2CJHJ'
    },
    {
      status: 210,
      sku: 'PM0006189',
      core_name: 'Plant',
      sppl_ean: '8720664526419',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495271',
      rtl_batch_array: '6295542, 6350091, 5495271',
      name_search: 'ECSUNRIS',
      name: "Echinacea 'Sunrise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1559,
      chnn_stock_retail: 3377,
      sppl_order_minimum: 3,
      sppl_prcp: 1.789,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KTD5VL8W;1DHY6SX6;BK9TCNHY;8P26AP1T;WV35AAGB;KH4WBXP2',
      statusContent: 'dot',
      hash: 'ATJGD91E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATJGD91E'
    },
    {
      status: 210,
      sku: 'PM0017898',
      core_name: 'Plant',
      sppl_ean: '8720664876996',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6151591',
      rtl_batch_array: '6151591',
      name_search: 'PEOFTAIL',
      name: "Pennisetum orientale 'Fairy Tails'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4AJL1YLS;4WDJXT26',
      statusContent: 'dot',
      hash: 'CZKC5N8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZKC5N8K'
    },
    {
      status: 210,
      sku: 'PM0083089',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356214',
      rtl_batch_array: '6356214',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'BVJYNSL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVJYNSL4'
    },
    {
      status: 910,
      sku: 'PM0074167',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345498',
      rtl_batch_array: '6345498',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '060STC5',
      rng_range_identifier: 'STE060C5',
      rng_range_description: 'Stem 60 cm C5',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 7.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '64RC37ZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64RC37ZE'
    },
    {
      status: 210,
      sku: 'PM0020399',
      core_name: 'Plant',
      sppl_ean: '8720664801523',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905496',
      rtl_batch_array: '5905496',
      name_search: 'PHANGUST',
      name: 'Phillyrea angustifolia',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3122,
      chnn_stock_retail: 3122,
      sppl_prcp: 4.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HDTBKYDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDTBKYDP'
    },
    {
      status: 210,
      sku: 'PM0073669',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258472',
      rtl_batch_array: '6258472',
      name_search: 'PRGBROSE',
      name: "Prunella grand. 'Bella Rose'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8DR2DSTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8DR2DSTR'
    },
    {
      status: 210,
      sku: 'PM0024396',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353205',
      rtl_batch_array: '6133651, 6353205',
      name_search: 'SAASNOW',
      name: "Salvia 'Azure Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 325,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TG1N6NXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TG1N6NXY'
    },
    {
      status: 210,
      sku: 'PM0027735',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 5,
      btch_active_retail: '6382291',
      rtl_batch_array: '6350686, 6164333, 5533717, 6382291, 6348374',
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7020,
      chnn_stock_retail: 10747,
      sppl_order_minimum: 3,
      sppl_prcp: 0.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E7VK3LN;DDBGZZBL;RGW28J9J;DSCH93GV;7XNJSA12;VW9TY6J4;2KD13C5X;ST5LA5JB;VCJDWHKT;67YR1HE5;YP168CCT;NYHA6TZT;2HHZ78SB;5X8SL82N;ATGY8842;Z7PLP1S1;8JVZEBCA;LXJVKK55;5RR83H12;9Z1YAEDY;WN8K8NXK',
      statusContent: 'dot',
      hash: 'TXADJJ92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXADJJ92'
    },
    {
      status: 210,
      sku: 'PM0013063',
      core_name: 'Plant',
      sppl_ean: '8720626304017',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364173',
      rtl_batch_array: '5364173',
      name_search: 'HELMARMA',
      name: "Hemerocallis 'Longfields Marmalade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 948,
      chnn_stock_retail: 948,
      sppl_order_minimum: 3,
      sppl_prcp: 1.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W1GNTVW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W1GNTVW3'
    },
    {
      status: 210,
      sku: 'PM0083090',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356215',
      rtl_batch_array: '6356215',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'PVELCYCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVELCYCC'
    },
    {
      status: 210,
      sku: 'PM0083091',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356216',
      rtl_batch_array: '6356216',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'LLKPXPSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLKPXPSV'
    },
    {
      status: 810,
      sku: 'PM0073953',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266275',
      rtl_batch_array: '6266275',
      name_search: 'CIDDECUM',
      name: "Cistus dansereaui 'Decumbens'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '65C8YLX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65C8YLX6'
    },
    {
      status: 210,
      sku: 'PM0083092',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356217',
      rtl_batch_array: '6356217',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.863,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'ZZV87CAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZV87CAV'
    },
    {
      status: 210,
      sku: 'PM0073957',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266279',
      rtl_batch_array: '6266279',
      name_search: 'CILEDON',
      name: 'Cistus ledon',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HK3WKLH;7YTHKK4R;JAJL7BNN',
      statusContent: 'dot',
      hash: 'YDSRED32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDSRED32'
    },
    {
      status: 210,
      sku: 'PM0073689',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6264771',
      rtl_batch_array: '6264771',
      name_search: 'CRJVILMO',
      name: "Cryptomeria japonica 'Vilmoriniana'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 4.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6R2W3ALR;V9SANNC7',
      statusContent: 'dot',
      hash: 'Y6X616ND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6X616ND'
    },
    {
      status: 210,
      sku: 'PM0083093',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356218',
      rtl_batch_array: '6356218',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.713,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'G6YL5JLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6YL5JLV'
    },
    {
      status: 210,
      sku: 'PM0074127',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348312',
      rtl_batch_array: '6350112, 6348312',
      name_search: 'ECRVBLUE',
      name: "Echinops ritro 'Veitch's Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1710,
      chnn_stock_retail: 4541,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GRTGPYRJ;RXD7CLJP;A7GE2PEV',
      statusContent: 'dot',
      hash: 'VRNP1PH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRNP1PH1'
    },
    {
      status: 210,
      sku: 'PM0083094',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356219',
      rtl_batch_array: '6356219',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '040050C13',
      rng_range_identifier: 'H040050C1.3',
      rng_range_description: 'H40 cm 50 cm C1.3',
      sppl_stock_available: 4700,
      chnn_stock_retail: 4700,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: '1T3NZCJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1T3NZCJV'
    },
    {
      status: 210,
      sku: 'PM0020621',
      core_name: 'Plant',
      sppl_ean: '8720353097176',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495268',
      rtl_batch_array: '5495268, 6320848',
      name_search: 'ECDCANDY',
      name: 'Echinacea Delicious Candy',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1071,
      chnn_stock_retail: 1134,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WC2GZVHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC2GZVHT'
    },
    {
      status: 210,
      sku: 'PM0083095',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356220',
      rtl_batch_array: '6356220',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: '5S76RCHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5S76RCHB'
    },
    {
      status: 210,
      sku: 'PM0083096',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356221',
      rtl_batch_array: '6356221',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'LST6BE41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LST6BE41'
    },
    {
      status: 210,
      sku: 'PM0083097',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356223',
      rtl_batch_array: '6356223',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_prcp: 7.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'GSNC15TG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSNC15TG'
    },
    {
      status: 210,
      sku: 'PM0074155',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258906',
      rtl_batch_array: '6258906',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 978,
      chnn_stock_retail: 978,
      sppl_prcp: 7.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'K2BNDLRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2BNDLRH'
    },
    {
      status: 210,
      sku: 'PM0009033',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217064',
      rtl_batch_array: '6217064, 5495482',
      name_search: 'RUFCGARD',
      name: "Rudbeckia fulgida 'City Garden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 721,
      chnn_stock_retail: 1531,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC2CLL85',
      statusContent: 'dot',
      hash: 'WJL58ZLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJL58ZLN'
    },
    {
      status: 210,
      sku: 'PM0012787',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6231161',
      rtl_batch_array: '5582440, 6159883, 6231161, 6339805, 6363192',
      name_search: 'FURICCAR',
      name: "Fuchsia 'Riccartonii'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 2247,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '18NG8BEV;KTYB7JW4;CNEZXE7W;YJABY9EE',
      statusContent: 'dot',
      hash: 'ZWLKWCDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWLKWCDR'
    },
    {
      status: 210,
      sku: 'PM0083098',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356224',
      rtl_batch_array: '6356224',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 8.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'LRESKXGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LRESKXGP'
    },
    {
      status: 210,
      sku: 'PM0074156',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258908',
      rtl_batch_array: '6258908',
      name_search: 'SARSGOLD',
      name: "Sambucus racemosa 'Sutherland Gold'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 7.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '437Z59T3;DT31YZSG',
      statusContent: 'dot',
      hash: '6GC6ERP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GC6ERP7'
    },
    {
      status: 210,
      sku: 'PM0083099',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356228',
      rtl_batch_array: '6356228',
      name_search: 'WEFPPOPP',
      name: 'Weigela florida Pink Poppet',
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '82AVJC8V;4377G18A;5GNRP32G;ADET5YSY;1AXEDRH2;23BGCZJY;82EVNTAV',
      statusContent: 'dot',
      hash: 'PKSA9J7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKSA9J7X'
    },
    {
      status: 210,
      sku: 'PM0083100',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356229',
      rtl_batch_array: '6356229',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: '721RL27D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '721RL27D'
    },
    {
      status: 210,
      sku: 'PM0083101',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356230',
      rtl_batch_array: '6356230',
      name_search: 'WEFTANGO',
      name: "Weigela florida 'Tango'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '161ZTXN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '161ZTXN7'
    },
    {
      status: 210,
      sku: 'PM0083102',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373837',
      rtl_batch_array: '6373837, 6356231',
      name_search: 'WEFVARIE',
      name: "Weigela florida 'Variegata'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 170,
      chnn_stock_retail: 1170,
      sppl_prcp: 1.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TCZ8WR55;GBB7W6HN;7113JCYK;AGEXNLA2;L4112LRC;AZV97K23;CV1REK46;4AR4T62H;RK825HXT;1XN53P61;YNC86VPC;L3XBLWW5;1X4BYDVC;4CWYLV6S;1BPZTCSZ',
      statusContent: 'dot',
      hash: 'XHNS313V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHNS313V'
    },
    {
      status: 810,
      sku: 'PM0074168',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345494',
      rtl_batch_array: '6345494',
      name_search: 'LIOARGEN',
      name: "Ligustrum ovalifolium 'Argenteum'",
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4C5HDB7;XR5WLHL7;W3VY8LT1;9W1BJELE;XKBG3Z1X',
      statusContent: 'dot',
      hash: 'ZHTEGNDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHTEGNDX'
    },
    {
      status: 210,
      sku: 'PM0074169',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307512',
      rtl_batch_array: '6307512',
      name_search: 'LIOARGEN',
      name: "Ligustrum ovalifolium 'Argenteum'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4C5HDB7;XR5WLHL7;W3VY8LT1;9W1BJELE;XKBG3Z1X',
      statusContent: 'dot',
      hash: '5X3PB656',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X3PB656'
    },
    {
      status: 810,
      sku: 'PM0074170',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345493',
      rtl_batch_array: '6345493',
      name_search: 'LIOARGEN',
      name: "Ligustrum ovalifolium 'Argenteum'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 13.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4C5HDB7;XR5WLHL7;W3VY8LT1;9W1BJELE;XKBG3Z1X',
      statusContent: 'dot',
      hash: '1HVS5AEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HVS5AEE'
    },
    {
      status: 810,
      sku: 'PM0074171',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345496',
      rtl_batch_array: '6345496',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '060STC5',
      rng_range_identifier: 'STE060C5',
      rng_range_description: 'Stem 60 cm C5',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 7.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'WCST7GJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCST7GJV'
    },
    {
      status: 210,
      sku: 'PM0074172',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345497',
      rtl_batch_array: '6345497',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_prcp: 8.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'XXS33JK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXS33JK7'
    },
    {
      status: 810,
      sku: 'PM0074173',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345495',
      rtl_batch_array: '6345495',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '120STC74',
      rng_range_identifier: 'STE120C7.5',
      rng_range_description: 'Stem 120 cm C7.5',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_prcp: 13.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: '5T7ZAK3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T7ZAK3R'
    },
    {
      status: 210,
      sku: 'PM0074176',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307254',
      rtl_batch_array: '6307254',
      name_search: 'ACNFLAMI',
      name: "Acer negundo 'Flamingo'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_prcp: 16.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '56A4CZWS;P9ZNPTYB;GZV5RJXC;JPS6TVLG;KWP2AYK4;KX4XXHDB;X1SKZZDP;P6ELGEK5;6G4Z4H7E;9WEHWCGR',
      statusContent: 'dot',
      hash: 'H5NR2Y53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5NR2Y53'
    },
    {
      status: 210,
      sku: 'PM0074179',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307364',
      rtl_batch_array: '6307364',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1475,
      chnn_stock_retail: 1475,
      sppl_prcp: 3.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'YJ26NXEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ26NXEE'
    },
    {
      status: 210,
      sku: 'PM0074141',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6137535',
      rtl_batch_array: '6137535',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4212,
      chnn_stock_retail: 4212,
      sppl_order_minimum: 3,
      sppl_prcp: 1.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'YJWJ7PEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJWJ7PEK'
    },
    {
      status: 210,
      sku: 'PM0073202',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258910',
      rtl_batch_array: '6258910',
      name_search: 'SOSSEM',
      name: "Sorbaria sorbifolia 'Sem'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 286,
      chnn_stock_retail: 286,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALK5GE7V;W5WGR1HK;KXZRZR9W;921A4NE9;DRH2D45D;DG285LJ2;9CRH7HGK;PRL9BLTB;K4GPW749;N8YWGLKS;S2WZRTJW',
      statusContent: 'dot',
      hash: '2H9SC5YB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2H9SC5YB'
    },
    {
      status: 210,
      sku: 'PM0073209',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258919',
      rtl_batch_array: '6258919',
      name_search: 'SYVKHAVE',
      name: "Syringa vulgaris 'Katherine Havemeyer'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 959,
      chnn_stock_retail: 959,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JZXYD2XZ;B4W8B4HW',
      statusContent: 'dot',
      hash: 'E4WSPHY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4WSPHY4'
    },
    {
      status: 210,
      sku: 'PM0074162',
      core_name: 'Plant',
      sppl_ean: '8720664540538',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015155',
      rtl_batch_array: '6015155',
      name_search: 'GEGEORGE',
      name: "Geum 'Georgenberg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7LHG2TSD',
      statusContent: 'dot',
      hash: 'YLBX81HN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLBX81HN'
    },
    {
      status: 210,
      sku: 'PM0073223',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258939',
      rtl_batch_array: '6258939',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'PWWNYS4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWWNYS4W'
    },
    {
      status: 210,
      sku: 'PM0002892',
      core_name: 'Plant',
      sppl_ean: '8720626313774',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5364369',
      rtl_batch_array: '5364369, 6350730, 6301751',
      name_search: 'ORRKBEAU',
      name: "Origanum rotundifolium 'Kent Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 718,
      chnn_stock_retail: 1618,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SELE2JA1;YW4AYXY2;WTLVREYA;HHDWADA8;BVBR8W54;JEEW2HA3',
      statusContent: 'dot',
      hash: 'TNY9K8J5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNY9K8J5'
    },
    {
      status: 210,
      sku: 'PM0073595',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260884',
      rtl_batch_array: '6260884, 6309501, 6333299',
      name_search: 'POVULGAR',
      name: 'Polypodium vulgare',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 35010,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ESLXN3BR;D792YKHT;CSAYPLAG;43ARZVG5;7YEHZVRP;RNCX8J6B',
      statusContent: 'dot',
      hash: 'S9W442AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9W442AP'
    },
    {
      status: 210,
      sku: 'PM0073673',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258675',
      rtl_batch_array: '6258675',
      name_search: 'BUDBCLLI',
      name: 'Buddleja d. B. Candy Little Lila',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 829,
      chnn_stock_retail: 829,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZDVJWX45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDVJWX45'
    },
    {
      status: 210,
      sku: 'PM0083103',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356232',
      rtl_batch_array: '6356232',
      name_search: 'WEFUCHSI',
      name: 'Weigela Fuchsine',
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KH3KZLE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KH3KZLE4'
    },
    {
      status: 210,
      sku: 'PM0019727',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373418',
      rtl_batch_array: '6373418, 6307634',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 450,
      chnn_stock_retail: 943,
      sppl_prcp: 10.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'ZZPW622J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZPW622J'
    },
    {
      status: 210,
      sku: 'PM0073674',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258676',
      rtl_batch_array: '6258676',
      name_search: 'BUDBCLPI',
      name: 'Buddleja d. B. Candy Little Pink',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1301,
      chnn_stock_retail: 1301,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9THBTZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9THBTZC'
    },
    {
      status: 210,
      sku: 'PM0073593',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6260881',
      rtl_batch_array: '6260881, 6349978, 6333214, 6348301',
      name_search: 'CYFALCAT',
      name: 'Cyrtomium falcatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 4139,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B9YDTA6X;CJ2LTKX5;BCZCYHHZ;D59BS6VC;Z2GNVJ33;S9B3G2WC;52VXY6D6;2A72VB8J;8LWP9845;1VJ4SRY4;8KN5W59K;2X8SX8H2;6612BKB3;6Y1H9LAC;YSH6LWC4;4VLSVYC4;B3V927PJ;44A9WXTD;Y4SL5973;PD6E2YCT;8GXRP556;5VZLG2CN;HZD95NHC;DKT3PY1P;G9DJAEBT',
      statusContent: 'dot',
      hash: '4L18BY97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4L18BY97'
    },
    {
      status: 210,
      sku: 'PM0073675',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258677',
      rtl_batch_array: '6258677',
      name_search: 'BUDBCLPI',
      name: 'Buddleja d. B. Candy Little Pink',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 790,
      chnn_stock_retail: 790,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSLDJ2DT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSLDJ2DT'
    },
    {
      status: 210,
      sku: 'PM0073676',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258678',
      rtl_batch_array: '6258678',
      name_search: 'BUDBCLPU',
      name: 'Buddleja d. B. Candy Little Purple',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 414,
      chnn_stock_retail: 414,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2PYW97EW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PYW97EW'
    },
    {
      status: 210,
      sku: 'PM0073677',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258679',
      rtl_batch_array: '6258679',
      name_search: 'BUDBCLPU',
      name: 'Buddleja d. B. Candy Little Purple',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZZJJHEV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZJJHEV7'
    },
    {
      status: 810,
      sku: 'PM0075481',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280923',
      rtl_batch_array: '6280923',
      name_search: 'CLMRUBEN',
      name: 'Clematis montana rubens',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '653ELA66;EZRBR1C5;W2DN157C;BRD5WXZB;KL6DCDYP;YWG2DA1G;LECN6WJV;DYYJ2CKK;NYJX7SLJ;GZN2P7EL;YNS3XDXT;6PWPLRYJ;9TGGSAX3',
      statusContent: 'dot',
      hash: 'G9R41TN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9R41TN7'
    },
    {
      status: 210,
      sku: 'PM0083104',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356233',
      rtl_batch_array: '6356233',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'DZ255GXB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ255GXB'
    },
    {
      status: 910,
      sku: 'PM0074184',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307443',
      rtl_batch_array: '6307443',
      name_search: 'FASPFOUN',
      name: "Fagus sylvatica 'Purple Fountain'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 17.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPVB67WR',
      statusContent: 'dot',
      hash: 'XJTZJWNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJTZJWNL'
    },
    {
      status: 210,
      sku: 'PM0073680',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258682',
      rtl_batch_array: '6258682',
      name_search: 'BUDBCLWH',
      name: 'Buddleja d. B. Candy Little White',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1115,
      chnn_stock_retail: 1115,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CLD9V8W2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CLD9V8W2'
    },
    {
      status: 210,
      sku: 'PM0073681',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258683',
      rtl_batch_array: '6258683',
      name_search: 'BUDBCLWH',
      name: 'Buddleja d. B. Candy Little White',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 560,
      chnn_stock_retail: 560,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V7NBPW5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7NBPW5J'
    },
    {
      status: 210,
      sku: 'PM0073590',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6260873',
      rtl_batch_array: '6349139, 6260873, 6353215, 6308735, 6309502',
      name_search: 'POPOLYBL',
      name: 'Polystichum polyblepharum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 15297,
      sppl_order_minimum: 3,
      sppl_prcp: 0.837,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLEW96CY;YVSXH5L4;CER6HTPA;LBAHT1GD;GVACZPJK;RBGE8TGS;7AHKBL7C;NHEA7N3E;AV2AHLS3;C7LL43WE;1HP1YKXT',
      statusContent: 'dot',
      hash: 'AG5WXPGX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AG5WXPGX'
    },
    {
      status: 210,
      sku: 'PM0074198',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345515',
      rtl_batch_array: '6345515',
      name_search: 'PIMPUMIL',
      name: 'Pinus mugo pumilio',
      sppl_size_code: '040050C15',
      rng_range_identifier: 'H040050C15',
      rng_range_description: 'H40 cm 50 cm C15',
      sppl_stock_available: 235,
      chnn_stock_retail: 235,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4ZT442RV;RGJHNANW;49G37GVS;A38GEDJZ;5N4ZTZV7;GR5APVHG;SVCBA44L;SDXBY9XL;RWZ1978C;2ZLTT2BN;KSGNJP2D;A19WHKYN;B6LD8ARP;ZG1T16V3;C4EPKVE3;VH9G1983;NR8DWTN7;42LWHX26;4Y7VNT2D;ZWZ8ZJE3;YATJT7TP;7ABY51J9;GX89T978;XEB5BBDY',
      statusContent: 'dot',
      hash: 'PT8GE6DD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PT8GE6DD'
    },
    {
      status: 810,
      sku: 'PM0075483',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280925',
      rtl_batch_array: '6280925',
      name_search: 'CLVITALB',
      name: 'Clematis vitalba',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1LLHB211',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LLHB211'
    },
    {
      status: 810,
      sku: 'PM0074183',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307442',
      rtl_batch_array: '6307442',
      name_search: 'FASDGOLD',
      name: "Fagus sylvatica 'Dawyck Gold'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '998N8CN7;C4YP35DD;XKLY27B5',
      statusContent: 'dot',
      hash: '5REC5GRZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5REC5GRZ'
    },
    {
      status: 210,
      sku: 'PM0074191',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307677',
      rtl_batch_array: '6307677',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_prcp: 24.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'JSRS7H33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSRS7H33'
    },
    {
      status: 210,
      sku: 'PM0001631',
      core_name: 'Plant',
      sppl_ean: '8720664865563',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164300',
      rtl_batch_array: '6301624, 6268751, 6164300, 6308997',
      name_search: 'HAMAUREO',
      name: "Hakonechloa macra 'Aureola'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3520,
      chnn_stock_retail: 15825,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LGPSH6BS;HAWZ7TB3;Y2VX4LTC;69ALZ6C1;61XAZ3JS;7V1LCS31;LD6HALZH;7XDBZ8J9;1WXLHHXT;YX55G1N1;A1P59SYS;ZS3J9J5L;KSWTBCH3;8LWBDWX8;9AX5RWL5;YTD95PRE;8N73A5TY;2DNH4NX9;7DGLA1NW;N65D24CL;DPGAS8SL;GJC55GAR;S1GN9PG1;5V7J2AGS',
      statusContent: 'dot',
      hash: 'EA9YBAN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA9YBAN8'
    },
    {
      status: 910,
      sku: 'PM0074190',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'K44NVSVT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K44NVSVT'
    },
    {
      status: 210,
      sku: 'PM0074181',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307414',
      rtl_batch_array: '6307414',
      name_search: 'ELEVIVEL',
      name: "Elaeagnus ebbingei 'Viveleg'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 8.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZYRDSSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZYRDSSS'
    },
    {
      status: 910,
      sku: 'PM0074182',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307440',
      rtl_batch_array: '6307440',
      name_search: 'FASASPLE',
      name: "Fagus sylvatica 'Aspleniifolia'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CW1B8SDT;GS1PG7KT',
      statusContent: 'dot',
      hash: '823K3TEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '823K3TEP'
    },
    {
      status: 810,
      sku: 'PM0074189',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307675',
      rtl_batch_array: '6307675',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '060080C12',
      rng_range_identifier: 'H060080C12',
      rng_range_description: 'H60 cm 80 cm C12',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 14.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: '478Z6G9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '478Z6G9A'
    },
    {
      status: 810,
      sku: 'PM0075468',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280909',
      rtl_batch_array: '6280909',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'P8N31H97',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8N31H97'
    },
    {
      status: 810,
      sku: 'PM0073375',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 2,
      btch_active_retail: '6272815',
      rtl_batch_array: '6298525, 6272815',
      name_search: 'BRSUNSHI',
      name: "Brachyglottis 'Sunshine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 65,
      chnn_stock_retail: 1064,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJGNTNB2',
      statusContent: 'dot',
      hash: 'ZAKGSH17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAKGSH17'
    },
    {
      status: 210,
      sku: 'PM0004851',
      core_name: 'Plant',
      sppl_ean: '8720664853911',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079259',
      rtl_batch_array: '6079259',
      name_search: 'BULOCHIN',
      name: "Buddleja 'Lochinch'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 560,
      chnn_stock_retail: 560,
      sppl_prcp: 1.715,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GXTTXG5;EKY59A7K;VYLJHNCG;JJ9B2SR1;45TG8BZ3;EY83V219;LVSBYP12',
      statusContent: 'dot',
      hash: '6ZA6NGB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZA6NGB1'
    },
    {
      status: 210,
      sku: 'PM0073958',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266280',
      rtl_batch_array: '6266280',
      name_search: 'HASAHUCI',
      name: 'Halimiocistus sahucii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 174,
      chnn_stock_retail: 174,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E4ZT73N',
      statusContent: 'dot',
      hash: 'ZHYG5PT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZHYG5PT5'
    },
    {
      status: 810,
      sku: 'PM0074185',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307445',
      rtl_batch_array: '6307445',
      name_search: 'FASZLATI',
      name: "Fagus sylvatica 'Zlatia'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H87XRJRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H87XRJRN'
    },
    {
      status: 210,
      sku: 'PM0010950',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353193',
      rtl_batch_array: '6301802, 6353193',
      name_search: 'RUSLHENR',
      name: "Rudbeckia subtomentosa 'Little Henry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 632,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JYEZ5XXD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYEZ5XXD'
    },
    {
      status: 810,
      sku: 'PM0075480',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280922',
      rtl_batch_array: '6280922',
      name_search: 'CLMRUBEN',
      name: 'Clematis montana rubens',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '653ELA66;EZRBR1C5;W2DN157C;BRD5WXZB;KL6DCDYP;YWG2DA1G;LECN6WJV;DYYJ2CKK;NYJX7SLJ;GZN2P7EL;YNS3XDXT;6PWPLRYJ;9TGGSAX3',
      statusContent: 'dot',
      hash: 'LPHPZAZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPHPZAZY'
    },
    {
      status: 210,
      sku: 'PM0074188',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307612',
      rtl_batch_array: '6307612',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 291,
      chnn_stock_retail: 291,
      sppl_prcp: 33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'DVGGLGVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVGGLGVA'
    },
    {
      status: 210,
      sku: 'PM0027959',
      core_name: 'Plant',
      sppl_ean: '8720626308220',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5352416',
      rtl_batch_array: '5352416',
      name_search: 'JUPFITZE',
      name: 'Juniperus pfitzeriana',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LDLW51YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDLW51YT'
    },
    {
      status: 210,
      sku: 'PM0021701',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350306',
      rtl_batch_array: '6350306, 6319706, 6348987',
      name_search: 'HESSASSY',
      name: "Helenium 'Short and Sassy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 260,
      chnn_stock_retail: 794,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PZY7WVY8;9H84ZNHL;YSVTE62J;8JZL8EPW;PXJPCH3E;TRZTY35A;536AHVSG',
      statusContent: 'dot',
      hash: 'G2KPWDST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2KPWDST'
    },
    {
      status: 210,
      sku: 'PM0074192',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307678',
      rtl_batch_array: '6307678',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 549,
      chnn_stock_retail: 549,
      sppl_prcp: 24.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'BEE3VEHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEE3VEHV'
    },
    {
      status: 210,
      sku: 'PM0074193',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 2,
      btch_active_retail: '6314936',
      rtl_batch_array: '6298541, 6314936',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 8882,
      chnn_stock_retail: 16558,
      sppl_order_minimum: 3,
      sppl_prcp: 5.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'S2VKNSGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2VKNSGT'
    },
    {
      status: 910,
      sku: 'PM0074195',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PIPGGLOB',
      name: "Picea pungens 'Glauca Globosa'",
      rng_range_identifier: 'H050060C35',
      rng_range_description: 'H50 cm 60 cm C35',
      imageCore:
        'ZAPZ2Y19;RT7R225Y;8RCT13BE;52EEBAJA;E4DGYS6J;V1WH2GHE;JBX7K4ED;4Z9G6PDC;X9X2P3DC;6RGP7D1N;REYXKK9E;71L73LNC;XZPEVCZG',
      statusContent: 'dot',
      hash: '9HK933VJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9HK933VJ'
    },
    {
      status: 810,
      sku: 'PM0074196',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345513',
      rtl_batch_array: '6345513',
      name_search: 'PIMGNOM',
      name: "Pinus mugo 'Gnom'",
      sppl_size_code: '050060C35',
      rng_range_identifier: 'H050060C35',
      rng_range_description: 'H50 cm 60 cm C35',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 37.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K6KTAZVJ',
      statusContent: 'dot',
      hash: 'S5G263RP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5G263RP'
    },
    {
      status: 910,
      sku: 'PM0074197',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345514',
      rtl_batch_array: '6345514',
      name_search: 'PIMGNOM',
      name: "Pinus mugo 'Gnom'",
      sppl_size_code: '060080C35',
      rng_range_identifier: 'H060080C35',
      rng_range_description: 'H60 cm 80 cm C35',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 38.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K6KTAZVJ',
      statusContent: 'dot',
      hash: 'E9HVYS6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9HVYS6Z'
    },
    {
      status: 210,
      sku: 'PM0083105',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356234',
      rtl_batch_array: '6356234',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'Y1S479N4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1S479N4'
    },
    {
      status: 910,
      sku: 'PM0074199',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345517',
      rtl_batch_array: '6345517',
      name_search: 'PINNANA',
      name: "Pinus nigra 'Nana'",
      sppl_size_code: '050060C35',
      rng_range_identifier: 'H050060C35',
      rng_range_description: 'H50 cm 60 cm C35',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 32.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HL7E28K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HL7E28K1'
    },
    {
      status: 910,
      sku: 'PM0074200',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345518',
      rtl_batch_array: '6345518',
      name_search: 'PINNANA',
      name: "Pinus nigra 'Nana'",
      sppl_size_code: '060080C35',
      rng_range_identifier: 'H060080C35',
      rng_range_description: 'H60 cm 80 cm C35',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 43.056,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SSC1SSC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSC1SSC3'
    },
    {
      status: 810,
      sku: 'PM0074201',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307756',
      rtl_batch_array: '6307756',
      name_search: 'PINOGREE',
      name: "Pinus nigra 'Oregon Green'",
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 21.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7NN3VAJK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NN3VAJK'
    },
    {
      status: 910,
      sku: 'PM0074202',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345519',
      rtl_batch_array: '6345519',
      name_search: 'PINPYRAM',
      name: "Pinus nigra 'Pyramidalis'",
      sppl_size_code: '100125C35',
      rng_range_identifier: 'H100125C35',
      rng_range_description: 'H100 cm 125 cm C35',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 53.214,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JG261LHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JG261LHW'
    },
    {
      status: 210,
      sku: 'PM0074203',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275069',
      rtl_batch_array: '6275069',
      name_search: 'SCVSTERN',
      name: "Sciadopitys verticillata 'Sternschnuppe'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_prcp: 15.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1KT2S19G',
      statusContent: 'dot',
      hash: 'N4PSHKNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4PSHKNZ'
    },
    {
      status: 210,
      sku: 'PM0073212',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258922',
      rtl_batch_array: '6258922',
      name_search: 'SYVMLEMO',
      name: "Syringa vulgaris 'Monique Lemoine'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 972,
      chnn_stock_retail: 972,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZTYZ7PL9',
      statusContent: 'dot',
      hash: '21SY8HCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21SY8HCV'
    },
    {
      status: 210,
      sku: 'PM0073213',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258923',
      rtl_batch_array: '6258923',
      name_search: 'SYVPRIMR',
      name: "Syringa vulgaris 'Primrose'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 968,
      chnn_stock_retail: 968,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XW5BDRL8',
      statusContent: 'dot',
      hash: 'R8LLBBW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8LLBBW3'
    },
    {
      status: 210,
      sku: 'PM0073214',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258925',
      rtl_batch_array: '6258925',
      name_search: 'SYVPWOLK',
      name: "Syringa vulgaris 'Prince Wolkonsky'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7YLANHES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YLANHES'
    },
    {
      status: 210,
      sku: 'PM0083106',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356235',
      rtl_batch_array: '6356235',
      name_search: 'WECANDID',
      name: "Weigela 'Candida'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T4GXH9E8;7BS3ASET',
      statusContent: 'dot',
      hash: 'RTP7PD8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTP7PD8L'
    },
    {
      status: 210,
      sku: 'PM0073216',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258928',
      rtl_batch_array: '6258928',
      name_search: 'TRFORTUN',
      name: 'Trachycarpus fortunei',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X74TZTVW;HNW81PGZ;TR1RRV3C;Y2STVG6V;XLPR87S9;6WJPY8RX;72YCZRV6;HAW8S38G;L4TW44ZG;5A55S1YR;74GYT1ZB;2X49JZ5B;PH6RHEYR;BSPXTXZA;49CE93ZP;VT2CBK24;YPHTD5K8;ZD9GDRJT;HRVSZC68;RGZPWRXK;RDZNV767;CXXZL4XL;BRSKWHWY',
      statusContent: 'dot',
      hash: '17VP9BY5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17VP9BY5'
    },
    {
      status: 210,
      sku: 'PM0073217',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258931',
      rtl_batch_array: '6258931',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 968,
      chnn_stock_retail: 968,
      sppl_prcp: 4.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: 'R72D81X8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R72D81X8'
    },
    {
      status: 210,
      sku: 'PM0073218',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258932',
      rtl_batch_array: '6258932',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1664,
      chnn_stock_retail: 1664,
      sppl_prcp: 6.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: 'R43K4NPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R43K4NPS'
    },
    {
      status: 210,
      sku: 'PM0073219',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6341914',
      rtl_batch_array: '6258935, 6341914',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 5495,
      sppl_prcp: 2.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'KKRTKSPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKRTKSPA'
    },
    {
      status: 210,
      sku: 'PM0073221',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258937',
      rtl_batch_array: '6258937',
      name_search: 'VIOCOPPE',
      name: 'Viburnum odoratissimum Coppertop',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 4945,
      chnn_stock_retail: 4945,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8KC4V355',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KC4V355'
    },
    {
      status: 210,
      sku: 'PM0073222',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258938',
      rtl_batch_array: '6258938',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: 'L2LG66A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2LG66A2'
    },
    {
      status: 210,
      sku: 'PM0073225',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258941',
      rtl_batch_array: '6258941',
      name_search: 'VIPWATAN',
      name: "Viburnum plicatum 'Watanabe'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 932,
      chnn_stock_retail: 932,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9LXJV3K;827Z6E42;AS1PNED1;NRRZK322;DNGB43HJ;ZE1WC5B3',
      statusContent: 'dot',
      hash: '8TT1C9CS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TT1C9CS'
    },
    {
      status: 210,
      sku: 'PM0083107',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356236',
      rtl_batch_array: '6356236',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: 'NTJKJ3AZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTJKJ3AZ'
    },
    {
      status: 210,
      sku: 'PM0073373',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272874',
      rtl_batch_array: '6272874',
      name_search: 'HEPINOCC',
      name: "Hemerocallis 'Pinocchio'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 955,
      chnn_stock_retail: 955,
      sppl_prcp: 2.421,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXHK4BE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXHK4BE9'
    },
    {
      status: 210,
      sku: 'PM0073374',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272814',
      rtl_batch_array: '6272814',
      name_search: 'BRSUNSHI',
      name: "Brachyglottis 'Sunshine'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_order_minimum: 3,
      sppl_prcp: 1.513,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EJGNTNB2',
      statusContent: 'dot',
      hash: 'DL511V3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL511V3S'
    },
    {
      status: 810,
      sku: 'PM0073376',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 3,
      btch_active_retail: '6271357',
      rtl_batch_array: '6271357, 5479982, 6363414',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 5498,
      sppl_prcp: 3.147,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: 'ZRW9NWC5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRW9NWC5'
    },
    {
      status: 210,
      sku: 'PM0073377',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260052',
      rtl_batch_array: '6260052',
      name_search: 'CHGOLDFI',
      name: 'Choisya Goldfingers',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 299,
      chnn_stock_retail: 299,
      sppl_prcp: 14.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E5PBBJ9;TX8TB739;B3L4GZP6',
      statusContent: 'dot',
      hash: 'NYWJ11RG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NYWJ11RG'
    },
    {
      status: 210,
      sku: 'PM0073378',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260053',
      rtl_batch_array: '6260053',
      name_search: 'FUMGRACI',
      name: 'Fuchsia magellanica gracilis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1821,
      chnn_stock_retail: 1821,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4YZ3CWG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YZ3CWG9'
    },
    {
      status: 210,
      sku: 'PM0073379',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260054',
      rtl_batch_array: '6260054',
      name_search: 'FUMGRACI',
      name: 'Fuchsia magellanica gracilis',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 584,
      chnn_stock_retail: 584,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9D1NWDN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D1NWDN1'
    },
    {
      status: 210,
      sku: 'PM0083108',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356237',
      rtl_batch_array: '6356237',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: 'CW3YXSLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CW3YXSLB'
    },
    {
      status: 210,
      sku: 'PM0073592',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6260879',
      rtl_batch_array: '6349587, 6260879, 6348293',
      name_search: 'ATNPICTU',
      name: 'Athyrium niponicum pictum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1002,
      sppl_order_minimum: 3,
      sppl_prcp: 0.737,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SKPAP3B8;8NWZGB9V;AHY1Y8HK;NYN4TND9;SA5VTKRC;ARSP8Z9V;1KZH9Y3Z;LE1K862G;YAN1VT63;ZXH14EPK;28DRWCDV;51GKDW81;23L8LKD4;GZJ8VPB8;9ABZASNC;T74694A2;4R87GSY1;JP7VDE3A;BRD4DNG8;1DWJW3WT',
      statusContent: 'dot',
      hash: 'SEHB9YP3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEHB9YP3'
    },
    {
      status: 210,
      sku: 'PM0073594',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6260883',
      rtl_batch_array: '6260883, 6350011, 6308549, 6333220, 6348306',
      name_search: 'DRERYTHR',
      name: 'Dryopteris erythrosora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 16084,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W23J67HA',
      statusContent: 'dot',
      hash: 'NR796DTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NR796DTR'
    },
    {
      status: 910,
      sku: 'PM0074204',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRISSQUE',
      name: "Iris sibirica 'Snow Queen'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'SC98G2VH;P41AY324;4HV6TLL7;NAD15KV4;CESS1G39',
      statusContent: 'dot',
      hash: 'E5XDWB6D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E5XDWB6D'
    },
    {
      status: 210,
      sku: 'PM0083109',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356238',
      rtl_batch_array: '6356238',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: 'HHK9PLZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHK9PLZT'
    },
    {
      status: 210,
      sku: 'PM0073371',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260043',
      rtl_batch_array: '6260043',
      name_search: 'ROOMJUPR',
      name: "Rosmarinus officinalis 'Miss Jessopp's Upright'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 365,
      chnn_stock_retail: 365,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH2H6229;JR97TD2N',
      statusContent: 'dot',
      hash: 'PTZC5NHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTZC5NHX'
    },
    {
      status: 210,
      sku: 'PM0083110',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356239',
      rtl_batch_array: '6356239',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: 'LY1EDS2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY1EDS2J'
    },
    {
      status: 210,
      sku: 'PM0023878',
      core_name: 'Plant',
      sppl_ean: '8720626305564',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '5628396',
      rtl_batch_array: '5628396, 6258770',
      name_search: 'HYASANNA',
      name: 'Hydrangea arborescens Strong Annabelle',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4627,
      chnn_stock_retail: 5497,
      sppl_prcp: 5.183,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6WESVPSZ;8S94HRR7;EW3Z6NPE;DW2PR9BP;PPAKRZVE;55NPDE9T;575TA36W;772K6D22;TS72Y5SD;JW5PHY85;EKWLYTBA;D389C7VL;XTSCRK29',
      statusContent: 'dot',
      hash: 'XSJPN8WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSJPN8WY'
    },
    {
      status: 210,
      sku: 'PM0019405',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308439',
      rtl_batch_array: '6349127, 6350595, 6308439, 6307047',
      name_search: 'KIPALMAT',
      name: 'Kirengeshoma palmata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 5043,
      sppl_order_minimum: 3,
      sppl_prcp: 0.827,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G6YDSEJ1;DTXVH1EP',
      statusContent: 'dot',
      hash: 'X6ARGR66',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6ARGR66'
    },
    {
      status: 210,
      sku: 'PM0083111',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356240',
      rtl_batch_array: '6356240',
      name_search: 'ROFAIRY',
      name: "Rosa (P) 'The Fairy'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N3R9LW9X;VX8TLPCV;TYHX8SJD;H8DP8BNS;PCR6L1V9;1L13XG31;GRX3V9J9',
      statusContent: 'dot',
      hash: 'AKLTECAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKLTECAX'
    },
    {
      status: 210,
      sku: 'PM0019476',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 6,
      btch_active_retail: '6244292',
      rtl_batch_array: '2681759, 5837468, 6346759, 6244292, 6254799, 6271438',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3550,
      chnn_stock_retail: 31109,
      sppl_prcp: 1.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'L8C3LXCP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8C3LXCP'
    },
    {
      status: 810,
      sku: 'PM0075469',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280910',
      rtl_batch_array: '6280910',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.072,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'W36D8BRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W36D8BRK'
    },
    {
      status: 810,
      sku: 'PM0075470',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280911',
      rtl_batch_array: '6280911',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.985,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'DAL6CE46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAL6CE46'
    },
    {
      status: 810,
      sku: 'PM0075471',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280912',
      rtl_batch_array: '6280912',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 57.592,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: '6DBLRXJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DBLRXJ1'
    },
    {
      status: 210,
      sku: 'PM0083112',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356241',
      rtl_batch_array: '6356241',
      name_search: 'ROPTFAIR',
      name: "Rosa (P) 'Pink The Fairy'",
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GT2Y71WW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT2Y71WW'
    },
    {
      status: 210,
      sku: 'PM0083113',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356245',
      rtl_batch_array: '6356245',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: 'GXSLXS1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXSLXS1B'
    },
    {
      status: 210,
      sku: 'PM0083114',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356247',
      rtl_batch_array: '6356247',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'CVJRTCZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVJRTCZZ'
    },
    {
      status: 210,
      sku: 'PM0083115',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356248',
      rtl_batch_array: '6356248',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'CS7KL31C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CS7KL31C'
    },
    {
      status: 210,
      sku: 'PM0083116',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356253',
      rtl_batch_array: '6356253',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      statusContent: 'dot',
      hash: 'ZS8HZX22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZS8HZX22'
    },
    {
      status: 210,
      sku: 'PM0073670',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258672',
      rtl_batch_array: '6258672',
      name_search: 'BUDBCLSW',
      name: 'Buddleja d. B. Candy Lila Sweeth.',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1587,
      chnn_stock_retail: 1587,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VPL1BYH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPL1BYH8'
    },
    {
      status: 210,
      sku: 'PM0073671',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258673',
      rtl_batch_array: '6258673',
      name_search: 'BUDBCLSW',
      name: 'Buddleja d. B. Candy Lila Sweeth.',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E9P2C95E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9P2C95E'
    },
    {
      status: 210,
      sku: 'PM0073672',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258674',
      rtl_batch_array: '6258674',
      name_search: 'BUDBCLLI',
      name: 'Buddleja d. B. Candy Little Lila',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1559,
      chnn_stock_retail: 1559,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B6LKCYTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6LKCYTA'
    },
    {
      status: 810,
      sku: 'PM0075464',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280905',
      rtl_batch_array: '6280905',
      name_search: 'CESCANDE',
      name: 'Celastrus scandens',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HDP62SBS;WT1LA82C;RPR3HDX2',
      statusContent: 'dot',
      hash: 'X3W6A7L9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3W6A7L9'
    },
    {
      status: 810,
      sku: 'PM0075465',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280906',
      rtl_batch_array: '6280906',
      name_search: 'CECANADE',
      name: 'Cercis canadensis',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LVTLE62R;AH74D8P4;545RX5ZD;54E271T2;RJ41P2H4;P86Y86VH;4KE5VSDD;HZTGKDH6;JX8HCT51;4S697TKJ;JWLCTNWZ;KNWPRTJK;CX7HPD7W;R47NA8E7;JNGNS34S;TVAXKYJ8;8Z8YYAYB;YWSDW6E8;TVX1V7HG;AWZ6NCWS;47K15XY4',
      statusContent: 'dot',
      hash: 'BB9PEYH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BB9PEYH4'
    },
    {
      status: 810,
      sku: 'PM0075466',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280907',
      rtl_batch_array: '6280907',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: 'WVB6ZSEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVB6ZSEC'
    },
    {
      status: 810,
      sku: 'PM0075467',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280908',
      rtl_batch_array: '6280908',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'GRJX8BN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRJX8BN9'
    },
    {
      status: 810,
      sku: 'PM0075472',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280913',
      rtl_batch_array: '6280913',
      name_search: 'CHVIRGIN',
      name: 'Chionanthus virginicus',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.408,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7K2CV2D;8SLZH6JY;JYYA2V2E;ZY5JSVH8;P3LAVG2X;9W36NP98;N7TD4KX6;X9EKCA4X;NAJV1P77',
      statusContent: 'dot',
      hash: 'CCCWA6ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCCWA6ZS'
    },
    {
      status: 810,
      sku: 'PM0075473',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280914',
      rtl_batch_array: '6280914',
      name_search: 'CHVIRGIN',
      name: 'Chionanthus virginicus',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.392,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7K2CV2D;8SLZH6JY;JYYA2V2E;ZY5JSVH8;P3LAVG2X;9W36NP98;N7TD4KX6;X9EKCA4X;NAJV1P77',
      statusContent: 'dot',
      hash: '2ESGLKWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ESGLKWE'
    },
    {
      status: 810,
      sku: 'PM0075474',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280915',
      rtl_batch_array: '6280915',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'S5YC8R7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5YC8R7V'
    },
    {
      status: 810,
      sku: 'PM0075475',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280916',
      rtl_batch_array: '6280916',
      name_search: 'CLALPINA',
      name: 'Clematis alpina',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2C7PLSEZ;AE5XEJS4;55X28X29;SAZB1E3B;SP1BKTHH;GK1NTCX9;976R9WLB;4WLYX3XG',
      statusContent: 'dot',
      hash: '5HEBWWTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HEBWWTD'
    },
    {
      status: 810,
      sku: 'PM0075476',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280917',
      rtl_batch_array: '6280917',
      name_search: 'CLARMAND',
      name: 'Clematis armandii',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A2HRDWJB;81D7Z7NT;SHH1R6CH;XVSDW131;7ZDCPZJC;XTLYZ1G9;4HHPASY8;LCRDTP5B;C4BLBTV3;T47TA769;7237NA5V;NRZ39N9Y;TZAK7BTG;Y1JD94C5',
      statusContent: 'dot',
      hash: '7ZD6WC65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZD6WC65'
    },
    {
      status: 910,
      sku: 'PM0075477',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLGRACIL',
      name: 'Clematis gracilifolia',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      statusContent: 'dot',
      hash: 'N9ZBAS9T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N9ZBAS9T'
    },
    {
      status: 810,
      sku: 'PM0075478',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280920',
      rtl_batch_array: '6280920',
      name_search: 'CLMAYLEE',
      name: "Clematis 'Mayleen'",
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '49BSDVK6;JBZH1GCG;4HS1SL2Z;ZC47WV66;PCNXCJJ4;XTG8D194;9PS6X6S3;745RCDAH;9XX3PK1X;LHL95ZG4;YYD2BT52;44EYZZ61;KEC99YA6;YPDPT9Y7',
      statusContent: 'dot',
      hash: 'AGHVB7GL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGHVB7GL'
    },
    {
      status: 810,
      sku: 'PM0075479',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280921',
      rtl_batch_array: '6280921',
      name_search: 'CLMAYLEE',
      name: "Clematis 'Mayleen'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '49BSDVK6;JBZH1GCG;4HS1SL2Z;ZC47WV66;PCNXCJJ4;XTG8D194;9PS6X6S3;745RCDAH;9XX3PK1X;LHL95ZG4;YYD2BT52;44EYZZ61;KEC99YA6;YPDPT9Y7',
      statusContent: 'dot',
      hash: 'HJX7JTJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJX7JTJV'
    },
    {
      status: 810,
      sku: 'PM0075482',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280924',
      rtl_batch_array: '6280924',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.698,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: 'PRH42KGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRH42KGP'
    },
    {
      status: 810,
      sku: 'PM0075484',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280926',
      rtl_batch_array: '6280926',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '150175C30',
      rng_range_identifier: 'H150175C30',
      rng_range_description: 'H150 cm 175 cm C30',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'TZAP7WNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TZAP7WNZ'
    },
    {
      status: 810,
      sku: 'PM0075485',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280927',
      rtl_batch_array: '6280927',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '200225C45',
      rng_range_identifier: 'H200225C45',
      rng_range_description: 'H200 cm 225 cm C45',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 75.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'GEPT8R14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEPT8R14'
    },
    {
      status: 810,
      sku: 'PM0075486',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280928',
      rtl_batch_array: '6280928',
      name_search: 'COFCCHIE',
      name: "Cornus florida 'Cherokee Chief'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 23.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZW8K98K',
      statusContent: 'dot',
      hash: '5E15KSCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5E15KSCW'
    },
    {
      status: 910,
      sku: 'PM0016801',
      core_name: 'Plant',
      sppl_ean: '8720626304772',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '5551450',
      rtl_batch_array: '6220813, 6253001, 5551450',
      name_search: 'HEVMACRO',
      name: 'Heuchera villosa macrorrhiza',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2,
      chnn_stock_retail: 583,
      sppl_order_minimum: 3,
      sppl_prcp: 0.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5WB4TLLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WB4TLLN'
    },
    {
      status: 210,
      sku: 'PM0073381',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260061',
      rtl_batch_array: '6260061',
      name_search: 'HEALBICA',
      name: 'Hebe albicans',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 925,
      chnn_stock_retail: 925,
      sppl_prcp: 4.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TZS7YL6H;BEEEXX1B;V8SD7NR9;SWDSA3HL;RLPH2D7R;JB39NE6N;KVVE9GTP;XYN52PN4;XCDS8YDC;7YXAY939;YDZHXEC8;BHXLV5W7;4VBJ8VG8;NT3B5V7P;9CB28JNA',
      statusContent: 'dot',
      hash: 'V5PDA952',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5PDA952'
    },
    {
      status: 210,
      sku: 'PM0073385',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272851',
      rtl_batch_array: '6272851',
      name_search: 'HEPIMELE',
      name: 'Hebe pimeleoides',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBGCE1GB;T317JGSA;JNYZS1HK;PSTACL85;LWV1PEGG;YW85LL3K;XJG6ZTH5;JNE5DE7B;BKHKPWZK;GDBB2BV8;J7884SCS;H824C7XA;E1GNXSWJ;89BX6RRX;1X5ZYRLJ;RHA6XL49',
      statusContent: 'dot',
      hash: 'YWGHPB29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWGHPB29'
    },
    {
      status: 210,
      sku: 'PM0073231',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258948',
      rtl_batch_array: '6258948',
      name_search: 'CEDFBLUE',
      name: "Cedrus deodara 'Feelin' Blue'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 22.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JRP5DSG3;S12VJSSR',
      statusContent: 'dot',
      hash: 'Y5Y4K6CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5Y4K6CJ'
    },
    {
      status: 810,
      sku: 'PM0075502',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280945',
      rtl_batch_array: '6280945',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 43,
      chnn_stock_retail: 43,
      sppl_prcp: 25.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'NJL69R7Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJL69R7Y'
    },
    {
      status: 810,
      sku: 'PM0075488',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280930',
      rtl_batch_array: '6280930',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '100125C18',
      rng_range_identifier: 'H100125C18',
      rng_range_description: 'H100 cm 125 cm C18',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 35.085,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: '18SCRC1L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18SCRC1L'
    },
    {
      status: 210,
      sku: 'PM0073961',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266344',
      rtl_batch_array: '6266344',
      name_search: 'PINNIGRA',
      name: 'Pinus nigra nigra',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 828,
      chnn_stock_retail: 828,
      sppl_prcp: 10.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PXWYTBSV',
      statusContent: 'dot',
      hash: '915PT7NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '915PT7NE'
    },
    {
      status: 810,
      sku: 'PM0075489',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280931',
      rtl_batch_array: '6280931',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.985,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: '7CAEDEGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CAEDEGT'
    },
    {
      status: 210,
      sku: 'PM0015191',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6369730',
      rtl_batch_array: '6369730, 6302345, 6386052',
      name_search: 'ANHDSWAN',
      name: "Anemone hybrida 'Dainty Swan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 780,
      chnn_stock_retail: 1825,
      sppl_order_minimum: 3,
      sppl_prcp: 1.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2RXTYVKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RXTYVKE'
    },
    {
      status: 210,
      sku: 'PM0073965',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266350',
      rtl_batch_array: '6266350',
      name_search: 'JUPOGOLD',
      name: "Juniperus pfitzeriana 'Old Gold'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2376,
      chnn_stock_retail: 2376,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H4AH5NBV;JCYD5JZW;ABRDA4XH;RX122G23;HYNDD2NP;ATX87GRK;52LSLXGD;2PPHP64S;EG5B4Z2H;111L6DNL;H28CXG1S;7151XSEX;S19CRJ87;HPR3EJ1N;D4EB5S1N;9RRH4AP9;WWSTSPWY;4BE6LC9V',
      statusContent: 'dot',
      hash: 'TTDJ5PJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTDJ5PJP'
    },
    {
      status: 210,
      sku: 'PM0073960',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266343',
      rtl_batch_array: '6266343',
      name_search: 'MIDECUSS',
      name: 'Microbiota decussata',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 1364,
      chnn_stock_retail: 1364,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5PSVSYYP;DWGB7P91;CVZ7AWNJ;TAL4LW1P;XL81N6JH;GHVC75RA',
      statusContent: 'dot',
      hash: '5YD2LT8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YD2LT8J'
    },
    {
      status: 210,
      sku: 'PM0073966',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266352',
      rtl_batch_array: '6266352',
      name_search: 'THOGGLOB',
      name: "Thuja occidentalis 'Golden Globe'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RD5LGE47;8TD9DDNK;8X745LHB;RHB2X2BR;T12GN6XT;C66Y35N7',
      statusContent: 'dot',
      hash: 'VRTZ1TN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRTZ1TN4'
    },
    {
      status: 210,
      sku: 'PM0073968',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266355',
      rtl_batch_array: '6266355',
      name_search: 'THOTTIM',
      name: "Thuja occidentalis 'Tiny Tim'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 322,
      chnn_stock_retail: 322,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2GN55EXS;BB4L3EGC;HCJCETDG;LDJZZC8X;L98DJ7YD;PEJG8Z2C;D1DCNRPN;7KREZ931;WAWALLCV;CJGE14G4;JEG7769A;BRAXLW3L;1DEDGW4T;S979L11H;CVLWZC74;S115657X;BWPZ1LLA;PKPG18XS',
      statusContent: 'dot',
      hash: 'G1GVWE8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1GVWE8C'
    },
    {
      status: 810,
      sku: 'PM0075495',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280938',
      rtl_batch_array: '6280938',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: '1JZXJHS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JZXJHS2'
    },
    {
      status: 210,
      sku: 'PM0083117',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356254',
      rtl_batch_array: '6356254',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      statusContent: 'dot',
      hash: 'ADRL8217',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADRL8217'
    },
    {
      status: 810,
      sku: 'PM0075490',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280932',
      rtl_batch_array: '6280932',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '100125C18',
      rng_range_identifier: 'H100125C18',
      rng_range_description: 'H100 cm 125 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.035,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: 'KPCNB7ND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPCNB7ND'
    },
    {
      status: 810,
      sku: 'PM0075491',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280933',
      rtl_batch_array: '6280933',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '175200C18',
      rng_range_identifier: 'H175200C18',
      rng_range_description: 'H175 cm 200 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 99.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: 'WYPE8P3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYPE8P3L'
    },
    {
      status: 810,
      sku: 'PM0025106',
      core_name: 'Plant',
      sppl_ean: '8720664886551',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6080760',
      rtl_batch_array: '6080760, 6133726',
      name_search: 'SOFFBELL',
      name: "Solidago flexicaulis 'Flexi Belle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 2166,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4S768SAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S768SAP'
    },
    {
      status: 210,
      sku: 'PM0083118',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356255',
      rtl_batch_array: '6356255',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 940,
      chnn_stock_retail: 940,
      sppl_prcp: 1.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: 'JGNYLWPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGNYLWPR'
    },
    {
      status: 810,
      sku: 'PM0075493',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280935',
      rtl_batch_array: '6280935',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.547,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: 'T8YPPWB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8YPPWB6'
    },
    {
      status: 210,
      sku: 'PM0083119',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356256',
      rtl_batch_array: '6356256',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1472,
      chnn_stock_retail: 1472,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: '6LJR9Y37',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LJR9Y37'
    },
    {
      status: 210,
      sku: 'PM0083120',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356257',
      rtl_batch_array: '6356257',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1964,
      chnn_stock_retail: 1964,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'Z4D322DT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4D322DT'
    },
    {
      status: 810,
      sku: 'PM0075494',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280936',
      rtl_batch_array: '6280936',
      name_search: 'COKOUSA',
      name: 'Cornus kousa',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5N6137CN;ACVT176C;D25LS6P2;PYPWW74A;WCNNTTZY;V6C6SD8T;TD7PRBZC;8CGNTDNY;V873PJGZ;2JT15YW5;55K9HJ7S;9H53ESYD;GSN267B9',
      statusContent: 'dot',
      hash: 'HZBSD3GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZBSD3GD'
    },
    {
      status: 210,
      sku: 'PM0028865',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298721',
      rtl_batch_array: '6298721',
      name_search: 'PEAREDHE',
      name: "Pennisetum alopecuroides 'Redhead'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5HWBHBZC',
      statusContent: 'dot',
      hash: '6242V971',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6242V971'
    },
    {
      status: 210,
      sku: 'PM0083121',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356258',
      rtl_batch_array: '6356258',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'WZDDKJL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZDDKJL9'
    },
    {
      status: 210,
      sku: 'PM0083122',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356259',
      rtl_batch_array: '6356259',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: 'CGL7JDGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGL7JDGJ'
    },
    {
      status: 210,
      sku: 'PM0075496',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373100',
      rtl_batch_array: '6280939, 6373100',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 2815,
      chnn_stock_retail: 2864,
      sppl_prcp: 9.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'X3NTAYBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3NTAYBL'
    },
    {
      status: 810,
      sku: 'PM0075497',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280940',
      rtl_batch_array: '6280940',
      name_search: 'COVENUS',
      name: 'Cornus Venus',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 54.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TCA57ZGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCA57ZGG'
    },
    {
      status: 810,
      sku: 'PM0075498',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280941',
      rtl_batch_array: '6280941',
      name_search: 'COVENUS',
      name: 'Cornus Venus',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 64.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VLETH2TX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLETH2TX'
    },
    {
      status: 810,
      sku: 'PM0075499',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280942',
      rtl_batch_array: '6280942',
      name_search: 'COVENUS',
      name: 'Cornus Venus',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 79.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WRPW7HHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRPW7HHX'
    },
    {
      status: 810,
      sku: 'PM0075500',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280943',
      rtl_batch_array: '6280943',
      name_search: 'CORZELLE',
      name: "Corylus 'Rode Zellernoot'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1DGK4V1;5679LLKP;BPAZ9HY4;9YLH4AX7;49H1CXWV;LLDGK8G5;4V6DGJWK;96CZ4G98;Y1P4HHYZ;NAJ1CLYJ;X27CH8VC;XN834NX2;B824C5AC;KYA77V24',
      statusContent: 'dot',
      hash: 'YW7TJHJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW7TJHJ8'
    },
    {
      status: 210,
      sku: 'PM0083123',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356261',
      rtl_batch_array: '6356261',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'T2G2XPKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2G2XPKS'
    },
    {
      status: 810,
      sku: 'PM0075501',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280944',
      rtl_batch_array: '6280944',
      name_search: 'CORZELLE',
      name: "Corylus 'Rode Zellernoot'",
      sppl_size_code: '175200C18',
      rng_range_identifier: 'H175200C18',
      rng_range_description: 'H175 cm 200 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1DGK4V1;5679LLKP;BPAZ9HY4;9YLH4AX7;49H1CXWV;LLDGK8G5;4V6DGJWK;96CZ4G98;Y1P4HHYZ;NAJ1CLYJ;X27CH8VC;XN834NX2;B824C5AC;KYA77V24',
      statusContent: 'dot',
      hash: 'G9L2R1N4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G9L2R1N4'
    },
    {
      status: 210,
      sku: 'PM0083124',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356262',
      rtl_batch_array: '6356262',
      name_search: 'MOCHEIDE',
      name: "Molinia caerulea 'Heidebraut'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H3AZLKPK;4V9W6RDW;5J5X4G26;BD3DKW2D;JJ57VGP6;E7KGCT37;9VKRHLPY',
      statusContent: 'dot',
      hash: 'R57AT1GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R57AT1GE'
    },
    {
      status: 810,
      sku: 'PM0075503',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280946',
      rtl_batch_array: '6280946',
      name_search: 'COCOGGYG',
      name: 'Cotinus coggygria',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSA84DWE;Y4AN75K2;6S9YSYV4;4XW7XSEE;NK8DRPE4',
      statusContent: 'dot',
      hash: '4N38ZWHN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4N38ZWHN'
    },
    {
      status: 810,
      sku: 'PM0075504',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280947',
      rtl_batch_array: '6280947',
      name_search: 'DAINVOLU',
      name: 'Davidia involucrata',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLRXRGKY',
      statusContent: 'dot',
      hash: 'WAPN52G1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAPN52G1'
    },
    {
      status: 810,
      sku: 'PM0075505',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280948',
      rtl_batch_array: '6280948',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.943,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: '537W35RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '537W35RZ'
    },
    {
      status: 810,
      sku: 'PM0075506',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280949',
      rtl_batch_array: '6280949',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: 'KCL245Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KCL245Y8'
    },
    {
      status: 210,
      sku: 'PM0083125',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356263',
      rtl_batch_array: '6356263',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      statusContent: 'dot',
      hash: '75998ZAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75998ZAR'
    },
    {
      status: 210,
      sku: 'PM0083126',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356264',
      rtl_batch_array: '6356264',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: '9CRRA3JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CRRA3JD'
    },
    {
      status: 210,
      sku: 'PM0073227',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258943',
      rtl_batch_array: '6258943',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1650,
      chnn_stock_retail: 1650,
      sppl_prcp: 7.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: 'NJZP3RKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJZP3RKB'
    },
    {
      status: 210,
      sku: 'PM0083127',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356265',
      rtl_batch_array: '6356265',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 950,
      chnn_stock_retail: 950,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      statusContent: 'dot',
      hash: 'NPDTKZ2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPDTKZ2J'
    },
    {
      status: 210,
      sku: 'PM0083128',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356266',
      rtl_batch_array: '6356266',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: '040060P9',
      rng_range_identifier: 'H040060P9',
      rng_range_description: 'H40 cm 60 cm P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 6,
      sppl_prcp: 0.755,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'HHSVX884',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHSVX884'
    },
    {
      status: 210,
      sku: 'PM0083129',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356267',
      rtl_batch_array: '6356267',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: '030040C13',
      rng_range_identifier: 'H030040C1.3',
      rng_range_description: 'H30 cm 40 cm C1.3',
      sppl_stock_available: 3800,
      chnn_stock_retail: 3800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'WLSTB132',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLSTB132'
    },
    {
      status: 210,
      sku: 'PM0083130',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356268',
      rtl_batch_array: '6356268',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: '7RN7634P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RN7634P'
    },
    {
      status: 210,
      sku: 'PM0073230',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258947',
      rtl_batch_array: '6258947',
      name_search: 'ABPAUREA',
      name: "Abies pinsapo 'Aurea'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 991,
      chnn_stock_retail: 991,
      sppl_prcp: 20.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7HKB3PB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HKB3PB6'
    },
    {
      status: 210,
      sku: 'PM0073232',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258949',
      rtl_batch_array: '6258949',
      name_search: 'CHOAUROR',
      name: "Chamaecyparis obtusa 'Aurora'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 11.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '12S83YCJ;1CN6P9AA;XSW5D71P',
      statusContent: 'dot',
      hash: '439J6NZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '439J6NZ8'
    },
    {
      status: 210,
      sku: 'PM0073380',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260055',
      rtl_batch_array: '6260055',
      name_search: 'FUMGRACI',
      name: 'Fuchsia magellanica gracilis',
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 367,
      chnn_stock_retail: 367,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2CEZR8K3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CEZR8K3'
    },
    {
      status: 210,
      sku: 'PM0073382',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272832',
      rtl_batch_array: '6272832',
      name_search: 'HEFBGEM',
      name: "Hebe franciscana 'Blue Gem'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2369,
      chnn_stock_retail: 2369,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WPR6ABAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPR6ABAB'
    },
    {
      status: 210,
      sku: 'PM0073383',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272847',
      rtl_batch_array: '6272847',
      name_search: 'HEONZGOL',
      name: "Hebe odora 'New Zealand Gold'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2100,
      chnn_stock_retail: 2100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6ZYVZWNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZYVZWNV'
    },
    {
      status: 210,
      sku: 'PM0073384',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272848',
      rtl_batch_array: '6272848',
      name_search: 'HEONZGOL',
      name: "Hebe odora 'New Zealand Gold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4723,
      chnn_stock_retail: 4723,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LZ5PXG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LZ5PXG6'
    },
    {
      status: 210,
      sku: 'PM0073386',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272873',
      rtl_batch_array: '6272873',
      name_search: 'HEPIMELE',
      name: 'Hebe pimeleoides',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2276,
      chnn_stock_retail: 2276,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBGCE1GB;T317JGSA;JNYZS1HK;PSTACL85;LWV1PEGG;YW85LL3K;XJG6ZTH5;JNE5DE7B;BKHKPWZK;GDBB2BV8;J7884SCS;H824C7XA;E1GNXSWJ;89BX6RRX;1X5ZYRLJ;RHA6XL49',
      statusContent: 'dot',
      hash: '6J6TW4PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J6TW4PY'
    },
    {
      status: 210,
      sku: 'PM0073596',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307817',
      rtl_batch_array: '6307817',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 371,
      chnn_stock_retail: 371,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: 'S7LB1ADG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7LB1ADG'
    },
    {
      status: 810,
      sku: 'PM0073597',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307820',
      rtl_batch_array: '6307820',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 27.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: '5L6D3PHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L6D3PHJ'
    },
    {
      status: 810,
      sku: 'PM0075507',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280950',
      rtl_batch_array: '6280950',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: '100125C18',
      rng_range_identifier: 'H100125C18',
      rng_range_description: 'H100 cm 125 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.035,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: 'VL1ZGEE6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL1ZGEE6'
    },
    {
      status: 810,
      sku: 'PM0075508',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280951',
      rtl_batch_array: '6280951',
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CJDDCGZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJDDCGZX'
    },
    {
      status: 810,
      sku: 'PM0075509',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280952',
      rtl_batch_array: '6280952',
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.438,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2T21SC52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T21SC52'
    },
    {
      status: 810,
      sku: 'PM0075510',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280953',
      rtl_batch_array: '6280953',
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.913,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SZKTXKN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZKTXKN3'
    },
    {
      status: 210,
      sku: 'PM0008411',
      core_name: 'Plant',
      sppl_ean: '8720349463114',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '5459702',
      rtl_batch_array: '5459702',
      name_search: 'PHOSWINE',
      name: 'Physocarpus opulifolius Summer Wine',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.295,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJBWSWTW;PAZLGJK7;WAP8SBGR;PYSTNZDL;CB5NHEEY;EYCGXX44',
      statusContent: 'dot',
      hash: '44LT8VK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44LT8VK3'
    },
    {
      status: 210,
      sku: 'PM0022830',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222196',
      rtl_batch_array: '6222196',
      name_search: 'WERUMBA',
      name: "Weigela 'Rumba'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T76B3G2N;T3BGHLYX;J2Y6LGK4',
      statusContent: 'dot',
      hash: '476LHC9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '476LHC9P'
    },
    {
      status: 210,
      sku: 'PM0083131',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356269',
      rtl_batch_array: '6356269',
      name_search: 'PEALBUNN',
      name: "Pennisetum alopecuroides 'Little Bunny'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63YVZZCG;A1BBJ6YJ;B5P86S8E;TH6PBLYX;GPV1LGBL;BNVEJBEX;5A63TARS;XN9HZKN6;6VRVEYET;1LKNTYL8;9P4AJG3C;Z5EA68H7;BY43YC1W;T159CA6D;EYTDLB28;EN1ZK2DY;9BY9SNAW;776T7RDZ;JGPE9T29;WAY2L8DE',
      statusContent: 'dot',
      hash: 'ZD4E4ETH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD4E4ETH'
    },
    {
      status: 210,
      sku: 'PM0073682',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6258878',
      rtl_batch_array: '6258878',
      name_search: 'ROCPIE',
      name: "Rosa (M) 'Cutie Pie'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_prcp: 3.933,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '89ZBLVLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89ZBLVLA'
    },
    {
      status: 210,
      sku: 'PM0073959',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266342',
      rtl_batch_array: '6266342',
      name_search: 'JUSTAMAR',
      name: "Juniperus sabina 'Tamariscifolia'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3029,
      chnn_stock_retail: 3029,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S7A49Z1T;DST5VLHZ;VJ4DBS7X;WKXH11PY',
      statusContent: 'dot',
      hash: 'NHP3DG8S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHP3DG8S'
    },
    {
      status: 210,
      sku: 'PM0073962',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266346',
      rtl_batch_array: '6266346',
      name_search: 'PIWALLIC',
      name: 'Pinus wallichiana',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 397,
      chnn_stock_retail: 397,
      sppl_prcp: 10.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJ4DPHTK;S7YWKNVX;G7LYA11R;KAK23EZX;GEWXWSKZ;V798ZY64;KAXJPGSX;6B61XV4W',
      statusContent: 'dot',
      hash: 'X1PVYTZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1PVYTZX'
    },
    {
      status: 210,
      sku: 'PM0073963',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266348',
      rtl_batch_array: '6266348',
      name_search: 'JUHBCHIP',
      name: "Juniperus horizontalis 'Blue Chip'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 3610,
      chnn_stock_retail: 3610,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YJ8PR1BY;HDCYCYS6;CJTGT6CS;CEZEJ8AW;HJKDEBZP;PDJ1LX84;TKZZ5TKL;66XE4CC2',
      statusContent: 'dot',
      hash: 'WXZX8YJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXZX8YJA'
    },
    {
      status: 210,
      sku: 'PM0073964',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266349',
      rtl_batch_array: '6266349',
      name_search: 'JUHPWALE',
      name: "Juniperus horizontalis 'Prince of Wales'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1615,
      chnn_stock_retail: 1615,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HV4G8K7R;4DDP56PC;6Z3RDN63;X1JSG9LP;PS853GCJ;7JRYEC16',
      statusContent: 'dot',
      hash: 'JXWKP9S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXWKP9S9'
    },
    {
      status: 210,
      sku: 'PM0073967',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266353',
      rtl_batch_array: '6266353',
      name_search: 'THOLGIAN',
      name: "Thuja occidentalis 'Little Giant'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1179,
      chnn_stock_retail: 1179,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LNGAX1LE',
      statusContent: 'dot',
      hash: 'KR96AX7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KR96AX7E'
    },
    {
      status: 210,
      sku: 'PM0083132',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356270',
      rtl_batch_array: '6356270',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      statusContent: 'dot',
      hash: '6HTDAKW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HTDAKW8'
    },
    {
      status: 210,
      sku: 'PM0083133',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356271',
      rtl_batch_array: '6356271',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      statusContent: 'dot',
      hash: '9J7GPK54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J7GPK54'
    },
    {
      status: 210,
      sku: 'PM0083134',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356272',
      rtl_batch_array: '6356272',
      name_search: 'PEORIENT',
      name: 'Pennisetum orientale',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BLPH2G4G;TC6J4497',
      statusContent: 'dot',
      hash: 'KX44DVRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KX44DVRX'
    },
    {
      status: 210,
      sku: 'PM0083135',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356273',
      rtl_batch_array: '6356273',
      name_search: 'PEOFLAMI',
      name: "Pennisetum orientale 'Flamingo'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.773,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '61KL3JRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61KL3JRR'
    },
    {
      status: 910,
      sku: 'PM0073973',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GYCPLENA',
      name: "Gypsophila 'Compacta Plena'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'BEBLG81H',
      statusContent: 'dot',
      hash: 'LRT5R9XT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LRT5R9XT'
    },
    {
      status: 210,
      sku: 'PM0083136',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356277',
      rtl_batch_array: '6356277',
      name_search: 'SEAUTUMN',
      name: 'Sesleria autumnalis',
      sppl_size_code: '025030C13',
      rng_range_identifier: 'H025030C1.3',
      rng_range_description: 'H25 cm 30 cm C1.3',
      sppl_stock_available: 2654,
      chnn_stock_retail: 2654,
      sppl_order_minimum: 3,
      sppl_prcp: 1.263,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '228P962B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '228P962B'
    },
    {
      status: 210,
      sku: 'PM0083137',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356285',
      rtl_batch_array: '6356285',
      name_search: 'PYCGWILD',
      name: "Pyrus communis 'Gieser Wildeman'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SY67BLS5;YE7TCNSK;G8TNGWAX;GE7Z48GV;G797G1AX;1BZCCRES;TLK3D7W9;JEK3TDT2;EBJTBBTN;JT5Z9NAJ;PZN6L4G6;1VSH4KL6;YNBPGBZJ;W1PHCVWP;SB41ZRKW;5CBAGTA2;CK5XJY9X;HKLH685X;BZVRVEP7;5CXCCERY;6YC1CN18;DJKAECZP;VSRZ6G6C',
      statusContent: 'dot',
      hash: 'Z3ZS47KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3ZS47KA'
    },
    {
      status: 210,
      sku: 'PM0002675',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267739',
      rtl_batch_array: '6267739',
      name_search: 'VIGJGLOB',
      name: "Viburnum globosum 'Jermyns Globe'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '63VV521E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63VV521E'
    },
    {
      status: 210,
      sku: 'PM0008301',
      core_name: 'Plant',
      sppl_ean: '8720664699489',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905569',
      rtl_batch_array: '5905569',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 717,
      chnn_stock_retail: 717,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      statusContent: 'dot',
      hash: '7R4TE8BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R4TE8BT'
    },
    {
      status: 210,
      sku: 'PM0023814',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6291243',
      rtl_batch_array: '6133767, 6268818, 6291243',
      name_search: 'TECNANUM',
      name: "Teucrium chamaedrys 'Nanum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 2207,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HK59GRSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HK59GRSY'
    },
    {
      status: 210,
      sku: 'PM0028186',
      core_name: 'Plant',
      sppl_ean: '8720664860926',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 4,
      btch_active_retail: '6164266',
      rtl_batch_array: '6084896, 6164266, 6302487, 6014928',
      name_search: 'ECPGTWIS',
      name: "Echinacea purpurea 'Green Twister'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 248,
      chnn_stock_retail: 8138,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ALT5GDRZ;92DHA2H6',
      statusContent: 'dot',
      hash: 'HVWZ2A1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVWZ2A1Y'
    },
    {
      status: 210,
      sku: 'PM0008107',
      core_name: 'Plant',
      sppl_ean: '8720353002439',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '5608766',
      rtl_batch_array: '6133468, 6242177, 5608766',
      name_search: 'PEAMOUDR',
      name: "Pennisetum alopecuroides 'Moudry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14750,
      chnn_stock_retail: 20379,
      sppl_order_minimum: 3,
      sppl_prcp: 0.619,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C194S7E5;J2LHWX4W;RJABD5DP;8PWEKSB2;JRPYRYY4;X99ANDH1;5DV99JNP;W4EVT5R5;72XK9Z9N;65JVSW7G;CWHT4DH7;PAZSTPGZ;ZSWJLYKH',
      statusContent: 'dot',
      hash: 'J46NA821',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J46NA821'
    },
    {
      status: 210,
      sku: 'PM0005366',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6274841',
      rtl_batch_array: '6112352, 5364034, 6274841',
      name_search: 'ECRITRO',
      name: 'Echinops ritro',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1915,
      chnn_stock_retail: 7496,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T111ZP6P;KPYZZHXK;HYTENXCT;Z99HCVVL;5SN536P5;WZVG58V5;8ZZ4P9HR;4YPE5DKZ;DZYKJLDD;G5HE49TP;WBNBH4HG;CJDS87JX;JAN4G3XE;XJXXPN9E;1P5BR2RX;S31WCRCD',
      statusContent: 'dot',
      hash: 'KDGJCCK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDGJCCK5'
    },
    {
      status: 210,
      sku: 'PM0020201',
      core_name: 'Plant',
      sppl_ean: '8720664682016',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5521408',
      rtl_batch_array: '6284311, 5521408',
      name_search: 'BRMJDIAM',
      name: "Brunnera macrophylla 'Jack of Diamonds'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 613,
      chnn_stock_retail: 675,
      sppl_order_minimum: 3,
      sppl_prcp: 1.838,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TBSH8E3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBSH8E3H'
    },
    {
      status: 210,
      sku: 'PM0023442',
      core_name: 'Plant',
      sppl_ean: '8720349491025',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5501691',
      rtl_batch_array: '5501691',
      name_search: 'ILCDGREE',
      name: 'Ilex crenata Dark Green',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4364,
      chnn_stock_retail: 4364,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TR843YL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR843YL3'
    },
    {
      status: 210,
      sku: 'PM0010001',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6231159',
      rtl_batch_array: '6231159, 6202265, 6295726, 6307947, 6333191',
      name_search: 'ASLHORIZ',
      name: "Aster lateriflorus 'Horizontalis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 2770,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G7TAWA5C;TB1W97AW;BT5RGL5X;DZ5X5V3L;J9HGHGVW',
      statusContent: 'dot',
      hash: 'VZ5717V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ5717V2'
    },
    {
      status: 810,
      sku: 'PM0075526',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280976',
      rtl_batch_array: '6280976',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: '5B6J12SH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B6J12SH'
    },
    {
      status: 810,
      sku: 'PM0075528',
      core_name: 'Plant',
      sppl_ean: '8720353021973',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '5423408',
      rtl_batch_array: '6280978, 5423408',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 40,
      chnn_stock_retail: 90,
      sppl_prcp: 17.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'WT5B13RY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WT5B13RY'
    },
    {
      status: 210,
      sku: 'PM0073387',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272878',
      rtl_batch_array: '6272878',
      name_search: 'HEPQUICK',
      name: "Hebe pimeleoides 'Quicksilver'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 599,
      chnn_stock_retail: 599,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WT8X5NST;YZC3WP2E;ZT1K3RK4;JRWZTKR7;4ZZ4N8Z5;BNA5ATB5;HJJ7W757;KRSK22AB;ZVS4AZXW;ZSY9ALXX;WV65YBJC;ESD4NNY8;GE6ZB4JB',
      statusContent: 'dot',
      hash: 'ALE82EPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALE82EPW'
    },
    {
      status: 210,
      sku: 'PM0073391',
      core_name: 'Plant',
      btch_manufacturer: 189,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349116',
      rtl_batch_array: '6349116, 6260074',
      name_search: 'HEPSUTHE',
      name: "Hebe pinguifolia 'Sutherlandii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3305,
      sppl_prcp: 1.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G956WPEA;ZVZ622DX;YZAJG146;7YR1WZBN;1JZLSCZV;59YNEXAE;RP8125WN',
      statusContent: 'dot',
      hash: '441ZVXNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '441ZVXNP'
    },
    {
      status: 210,
      sku: 'PM0083138',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356288',
      rtl_batch_array: '6356288',
      name_search: 'PYCGWILD',
      name: "Pyrus communis 'Gieser Wildeman'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 9.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SY67BLS5;YE7TCNSK;G8TNGWAX;GE7Z48GV;G797G1AX;1BZCCRES;TLK3D7W9;JEK3TDT2;EBJTBBTN;JT5Z9NAJ;PZN6L4G6;1VSH4KL6;YNBPGBZJ;W1PHCVWP;SB41ZRKW;5CBAGTA2;CK5XJY9X;HKLH685X;BZVRVEP7;5CXCCERY;6YC1CN18;DJKAECZP;VSRZ6G6C',
      statusContent: 'dot',
      hash: 'VG6T24WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VG6T24WE'
    },
    {
      status: 210,
      sku: 'PM0073388',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272875',
      rtl_batch_array: '6272875',
      name_search: 'HEPPAGEI',
      name: "Hebe pinguifolia 'Pagei'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 751,
      chnn_stock_retail: 751,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWV3S6NB;5B1CCRZS;HT4AH9T2',
      statusContent: 'dot',
      hash: 'E9A363D5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E9A363D5'
    },
    {
      status: 210,
      sku: 'PM0073394',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272883',
      rtl_batch_array: '6272883',
      name_search: 'HERECURV',
      name: 'Hebe recurva',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 2.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W5EBH8BY;216NYL8N;NTKWN74E;X7HG4CTG;P56HXT3S;Y7X7A5Z2;442CHJR3;NZ9EY9HD',
      statusContent: 'dot',
      hash: 'K4SNZCKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4SNZCKP'
    },
    {
      status: 210,
      sku: 'PM0073683',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259380',
      rtl_batch_array: '6259380',
      name_search: 'ALLMCHRI',
      name: 'Alstroemeria Little Miss Christina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R7KXNN9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7KXNN9T'
    },
    {
      status: 210,
      sku: 'PM0073392',
      core_name: 'Plant',
      btch_manufacturer: 189,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349114',
      rtl_batch_array: '6349114, 6272881',
      name_search: 'HERAKAIE',
      name: 'Hebe rakaiensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4372,
      sppl_prcp: 1.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LH7PH7Y6;RZ7VS1WG;VY4Y2CJN;4S6BXGKB;NB6YSND5;WXG96JK5;Z74524NT;ZB43HNAX;H2514ZWJ',
      statusContent: 'dot',
      hash: 'KZL9S21L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZL9S21L'
    },
    {
      status: 210,
      sku: 'PM0073390',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272879',
      rtl_batch_array: '6272879',
      name_search: 'HEPSUTHE',
      name: "Hebe pinguifolia 'Sutherlandii'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1919,
      chnn_stock_retail: 1919,
      sppl_order_minimum: 3,
      sppl_prcp: 1.313,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G956WPEA;ZVZ622DX;YZAJG146;7YR1WZBN;1JZLSCZV;59YNEXAE;RP8125WN',
      statusContent: 'dot',
      hash: 'KD6RK9NV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KD6RK9NV'
    },
    {
      status: 210,
      sku: 'PM0083139',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356289',
      rtl_batch_array: '6356289',
      name_search: 'RINLBSUG',
      name: "Ribes nigrum 'Little Black Sugar'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HLKES8XC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLKES8XC'
    },
    {
      status: 210,
      sku: 'PM0083140',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356290',
      rtl_batch_array: '6356290',
      name_search: 'PRAREGIN',
      name: "Prunus avium 'Regina'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y83T9HY4;PZYJA6ZE;8LX9E4SV;GKNTLDV5',
      statusContent: 'dot',
      hash: 'TX96DYP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TX96DYP5'
    },
    {
      status: 210,
      sku: 'PM0083141',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356291',
      rtl_batch_array: '6356291',
      name_search: 'PRALAPIN',
      name: "Prunus avium 'Lapins'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2C942A6Z;Y786YL9S',
      statusContent: 'dot',
      hash: 'HE3NB4AC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HE3NB4AC'
    },
    {
      status: 210,
      sku: 'PM0083142',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356293',
      rtl_batch_array: '6356293',
      name_search: 'PYCGWILD',
      name: "Pyrus communis 'Gieser Wildeman'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 15.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SY67BLS5;YE7TCNSK;G8TNGWAX;GE7Z48GV;G797G1AX;1BZCCRES;TLK3D7W9;JEK3TDT2;EBJTBBTN;JT5Z9NAJ;PZN6L4G6;1VSH4KL6;YNBPGBZJ;W1PHCVWP;SB41ZRKW;5CBAGTA2;CK5XJY9X;HKLH685X;BZVRVEP7;5CXCCERY;6YC1CN18;DJKAECZP;VSRZ6G6C',
      statusContent: 'dot',
      hash: 'W3DAWHBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3DAWHBV'
    },
    {
      status: 810,
      sku: 'PM0075511',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280956',
      rtl_batch_array: '6280956',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'ZYL8RL2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYL8RL2E'
    },
    {
      status: 810,
      sku: 'PM0075512',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280957',
      rtl_batch_array: '6280957',
      name_search: 'EXRACEMO',
      name: 'Exochorda racemosa',
      sppl_size_code: '150175C30',
      rng_range_identifier: 'H150175C30',
      rng_range_description: 'H150 cm 175 cm C30',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 55.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BD6XTJ6S;YCBKHZAN',
      statusContent: 'dot',
      hash: 'X73L9TBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X73L9TBC'
    },
    {
      status: 810,
      sku: 'PM0075513',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280960',
      rtl_batch_array: '6280960',
      name_search: 'FRAASPLE',
      name: "Frangula alnus 'Aspleniifolia'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E3LVVJ7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E3LVVJ7E'
    },
    {
      status: 810,
      sku: 'PM0075514',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280961',
      rtl_batch_array: '6280961',
      name_search: 'FRAASPLE',
      name: "Frangula alnus 'Aspleniifolia'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.486,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ERAZLAG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERAZLAG8'
    },
    {
      status: 810,
      sku: 'PM0075515',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280962',
      rtl_batch_array: '6280962',
      name_search: 'FRAFLINE',
      name: 'Frangula alnus Fine Line',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.472,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7XXKVBDG',
      statusContent: 'dot',
      hash: '1CV5R63H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CV5R63H'
    },
    {
      status: 810,
      sku: 'PM0075516',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280963',
      rtl_batch_array: '6280963',
      name_search: 'FRAFLINE',
      name: 'Frangula alnus Fine Line',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.462,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7XXKVBDG',
      statusContent: 'dot',
      hash: 'BGZ1SB4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGZ1SB4C'
    },
    {
      status: 810,
      sku: 'PM0075517',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280964',
      rtl_batch_array: '6280964',
      name_search: 'FRAFLINE',
      name: 'Frangula alnus Fine Line',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7XXKVBDG',
      statusContent: 'dot',
      hash: 'ZYTWRC5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYTWRC5T'
    },
    {
      status: 810,
      sku: 'PM0075518',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280965',
      rtl_batch_array: '6280965',
      name_search: 'HAIAPHRO',
      name: "Hamamelis intermedia 'Aphrodite'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VCD51HJD;2TBEVLBS;YJG8VVR6;EDZ11GYS;JJNEACJS;SH4VVNXL;5XVYK2YB;HJZW5L3H;2H428NV7',
      statusContent: 'dot',
      hash: 'V3DP815K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3DP815K'
    },
    {
      status: 810,
      sku: 'PM0075519',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280966',
      rtl_batch_array: '6280966',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'LYC2VNR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYC2VNR2'
    },
    {
      status: 810,
      sku: 'PM0075520',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280968',
      rtl_batch_array: '6280968',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'XST5LEY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XST5LEY3'
    },
    {
      status: 810,
      sku: 'PM0075521',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280969',
      rtl_batch_array: '6280969',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'G8NBXTEZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G8NBXTEZ'
    },
    {
      status: 810,
      sku: 'PM0075522',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280972',
      rtl_batch_array: '6280972',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 19.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'Z4B9C63N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4B9C63N'
    },
    {
      status: 810,
      sku: 'PM0075523',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280973',
      rtl_batch_array: '6280973',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 22.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'VHE8K7W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHE8K7W9'
    },
    {
      status: 810,
      sku: 'PM0075524',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280974',
      rtl_batch_array: '6280974',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: '7CZ1GBB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CZ1GBB5'
    },
    {
      status: 810,
      sku: 'PM0075525',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280975',
      rtl_batch_array: '6280975, 6334322',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 70,
      sppl_prcp: 15.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: '9DXJG1VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DXJG1VX'
    },
    {
      status: 210,
      sku: 'PM0073389',
      core_name: 'Plant',
      btch_manufacturer: 189,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349118',
      rtl_batch_array: '6349118, 6272876',
      name_search: 'HEPPAGEI',
      name: "Hebe pinguifolia 'Pagei'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3196,
      sppl_prcp: 1.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWV3S6NB;5B1CCRZS;HT4AH9T2',
      statusContent: 'dot',
      hash: 'ZRZBJ49G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRZBJ49G'
    },
    {
      status: 810,
      sku: 'PM0073598',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6346725',
      rtl_batch_array: '6346725',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '060080C20',
      rng_range_identifier: 'H060080C20',
      rng_range_description: 'H60 cm 80 cm C20',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 18.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'JN771W8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN771W8N'
    },
    {
      status: 810,
      sku: 'PM0075527',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280977',
      rtl_batch_array: '6280977',
      name_search: 'HAIFEUER',
      name: "Hamamelis intermedia 'Feuerzauber'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 39.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C99NLEBN',
      statusContent: 'dot',
      hash: '6K4HZGNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6K4HZGNG'
    },
    {
      status: 810,
      sku: 'PM0075529',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280979',
      rtl_batch_array: '6280979',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'VZG78P7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZG78P7W'
    },
    {
      status: 210,
      sku: 'PM0073684',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259382',
      rtl_batch_array: '6259382',
      name_search: 'ANGPRARI',
      name: "Andropogon gerardii 'Prariesommer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BAZZSRHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAZZSRHW'
    },
    {
      status: 210,
      sku: 'PM0073685',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259400',
      rtl_batch_array: '6259400',
      name_search: 'ASMROSEM',
      name: "Astrantia maj. 'Rosemarie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KDGTNZR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDGTNZR9'
    },
    {
      status: 910,
      sku: 'PM0073975',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BRSUNSHI',
      name: "Brachyglottis 'Sunshine'",
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      imageCore: 'EJGNTNB2',
      statusContent: 'dot',
      hash: 'L9LA8WKS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'L9LA8WKS'
    },
    {
      status: 810,
      sku: 'PM0075530',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334234',
      rtl_batch_array: '6280980, 6334234',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 45,
      chnn_stock_retail: 95,
      sppl_prcp: 21.237,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'R267D8TD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R267D8TD'
    },
    {
      status: 210,
      sku: 'PM0028524',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '6282377',
      rtl_batch_array: '6282377, 6287449',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 500,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '726R32P3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '726R32P3'
    },
    {
      status: 210,
      sku: 'PM0007800',
      core_name: 'Plant',
      sppl_ean: '8720664864245',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 5,
      btch_active_retail: '6113103',
      rtl_batch_array: '6192753, 6113103, 5364079, 6315050, 5486588',
      name_search: 'GEBROOKS',
      name: "Geranium 'Brookside'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 6203,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2ZR571V;L1HDCC73',
      statusContent: 'dot',
      hash: '9PJ3BHH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PJ3BHH2'
    },
    {
      status: 810,
      sku: 'PM0014813',
      core_name: 'Plant',
      btch_manufacturer: 123,
      rtl_batch_array_total: 1,
      btch_active_retail: '5880607',
      rtl_batch_array: '5880607',
      name_search: 'THPGELDE',
      name: "Thuja plicata 'Gelderland'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 2.809,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N23Y9G8B;C9T6V67H;JWB1ZB6D;4KWXGXT1;2YNBYNEH;N795AKAR;93EJ6XV1',
      statusContent: 'dot',
      hash: 'EH61NGC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH61NGC7'
    },
    {
      status: 210,
      sku: 'PM0006199',
      core_name: 'Plant',
      sppl_ean: '8720664804531',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503653',
      rtl_batch_array: '5503653',
      name_search: 'PRLTICO',
      name: 'Prunus lusitanica Tico',
      sppl_size_code: '015020P10,5',
      rng_range_identifier: 'H015020P10.5',
      rng_range_description: 'H15 cm 20 cm P10.5',
      sppl_stock_available: 4930,
      chnn_stock_retail: 4930,
      sppl_prcp: 1.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWY41PZS;7R6JJ5AZ;4JP57245;K7VPGT28;G23X8HE1',
      imageBatch: 'L97B5R2W',
      statusContent: 'dot',
      hash: 'EJ5XBBW9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ5XBBW9'
    },
    {
      status: 210,
      sku: 'PM0023941',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6220394',
      rtl_batch_array: '6220394, 5905536',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1236,
      chnn_stock_retail: 1869,
      sppl_prcp: 3.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: 'XLTDPNJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLTDPNJ9'
    },
    {
      status: 210,
      sku: 'PM0018707',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254895',
      rtl_batch_array: '6254895',
      name_search: 'FULTHUMB',
      name: "Fuchsia 'Lady Thumb'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1335,
      chnn_stock_retail: 1335,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9LZE88A2;HDAJEETB;W4HK4NA4;41XVVNHA;N6K97PDD;449PWZD6;6S2CPT3T;J7XX5SWZ;58NRAWLL',
      statusContent: 'dot',
      hash: 'LZBXY2TS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZBXY2TS'
    },
    {
      status: 810,
      sku: 'PM0075536',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6280988',
      rtl_batch_array: '6280988, 6307472',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 62,
      sppl_prcp: 12.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'LKX7SE5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKX7SE5G'
    },
    {
      status: 910,
      sku: 'PM0073979',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'J3B5KRY2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J3B5KRY2'
    },
    {
      status: 210,
      sku: 'PM0083143',
      core_name: 'Plant',
      sppl_ean: '8720626311008',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5304285',
      rtl_batch_array: '5304285',
      name_search: 'LONGGLOW',
      name: "Lonicera nitida 'Golden Glow'",
      sppl_size_code: 'LEVC15',
      rng_range_identifier: 'C15',
      rng_range_description: 'C15',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2BCZ5W6',
      imageBatch: '4XGAJP3G',
      statusContent: 'dot',
      hash: 'TWPG31N8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TWPG31N8'
    },
    {
      status: 910,
      sku: 'PM0073977',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GIBILOBA',
      name: 'Ginkgo biloba',
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      imageCore:
        'WY8TE552;DT2176J1;X7YVRRRC;ZA4NZ32C;CKEXKRAR;RLNHRA6B;XL1JAJ4D;DEPR9L6D;BWA6VT6S;1RDZ1KTZ;SJHWZYBP;HR2WJ79N;ZT59WZ7D;87HX4H3S',
      statusContent: 'dot',
      hash: 'EWEX9DAC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EWEX9DAC'
    },
    {
      status: 810,
      sku: 'PM0075535',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280987',
      rtl_batch_array: '6280987',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 39.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: '2STG58K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2STG58K5'
    },
    {
      status: 910,
      sku: 'PM0075534',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334330',
      rtl_batch_array: '6280986, 6334330',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 16,
      chnn_stock_retail: 66,
      sppl_prcp: 19.767,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: '9A432RYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9A432RYD'
    },
    {
      status: 810,
      sku: 'PM0075552',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281008',
      rtl_batch_array: '6281008',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '125150C30',
      rng_range_identifier: 'H125150C30',
      rng_range_description: 'H125 cm 150 cm C30',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 84.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: '2S24JYH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2S24JYH8'
    },
    {
      status: 810,
      sku: 'PM0075551',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281007',
      rtl_batch_array: '6281007',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'DEVE11J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEVE11J7'
    },
    {
      status: 210,
      sku: 'PM0083144',
      core_name: 'Plant',
      sppl_ean: '8720626362529',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5365958',
      rtl_batch_array: '5365958',
      name_search: 'PHFPMARB',
      name: 'Photinia fraseri Pink Marble',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4CT9L811;1HBNSB84;8N74BZ6V;YARAEHC9;HGEVRBBT;JXTCBW25;9WRNT3DN;JY6R1L8Z;V6W5T3DZ;ER19K222;SDG8NAG4;DP837D6H;P3RSE34G;E6DCE2W5;24KRVHDT;EAKC87TV;4V3R78Y9;VXNWKB91;ZC7LGHKY',
      imageBatch: 'GNS4C51Y',
      statusContent: 'dot',
      hash: 'PTGGZPRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTGGZPRK'
    },
    {
      status: 210,
      sku: 'PM0083145',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6270547',
      rtl_batch_array: '6378451, 6270547',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 493,
      chnn_stock_retail: 1529,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: 'NDB7H3GC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDB7H3GC'
    },
    {
      status: 210,
      sku: 'PM0083146',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6270549',
      rtl_batch_array: '6378441, 6270549',
      name_search: 'BUFPBHEA',
      name: 'Buddleja Free Petite Blue Heaven',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 490,
      chnn_stock_retail: 2481,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRGYB1XG;6W23LEGC;AJ55KEY1;CLPSGHX5',
      statusContent: 'dot',
      hash: 'RJY74PDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJY74PDS'
    },
    {
      status: 210,
      sku: 'PM0083147',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270550',
      rtl_batch_array: '6270550',
      name_search: 'BUDFPDPI',
      name: 'Buddleja Free Petite Dark Pink',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 509,
      chnn_stock_retail: 509,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RDNSSGS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RDNSSGS6'
    },
    {
      status: 210,
      sku: 'PM0083148',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270551',
      rtl_batch_array: '6270551',
      name_search: 'BUFPLFLO',
      name: 'Buddleja Free Petite Lavender Flow',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 485,
      chnn_stock_retail: 485,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XALTTLWR;95XJPGXK',
      statusContent: 'dot',
      hash: '83PX4VTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83PX4VTL'
    },
    {
      status: 210,
      sku: 'PM0083149',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270552',
      rtl_batch_array: '6270552',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 516,
      chnn_stock_retail: 516,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: 'RP7EN6PG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RP7EN6PG'
    },
    {
      status: 210,
      sku: 'PM0083150',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270554',
      rtl_batch_array: '6270554',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1811,
      chnn_stock_retail: 1811,
      sppl_order_minimum: 3,
      sppl_prcp: 0.975,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: '5CASCP1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CASCP1G'
    },
    {
      status: 810,
      sku: 'PM0075531',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280981',
      rtl_batch_array: '6280981',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'SZCB9573',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZCB9573'
    },
    {
      status: 810,
      sku: 'PM0075532',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280982',
      rtl_batch_array: '6280982',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.927,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: '4XAY42W4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XAY42W4'
    },
    {
      status: 810,
      sku: 'PM0075533',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280983',
      rtl_batch_array: '6280983',
      name_search: 'HAIOBEAU',
      name: "Hamamelis intermedia 'Orange Beauty'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APWZR8WG;X8HZHL3D',
      statusContent: 'dot',
      hash: 'WP8LXR7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WP8LXR7H'
    },
    {
      status: 210,
      sku: 'PM0083151',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270568',
      rtl_batch_array: '6270568',
      name_search: 'POFTCREA',
      name: "Potentilla fruticosa 'Tilford Cream'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1590,
      chnn_stock_retail: 1590,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JVX1C2XK;GV1AYWXX;ZESL741J;TETJ637L;RVRKZ332;2KESX2NV',
      statusContent: 'dot',
      hash: 'JEKXTX4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEKXTX4J'
    },
    {
      status: 210,
      sku: 'PM0073686',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350575',
      rtl_batch_array: '6350575',
      name_search: 'IRSCMUST',
      name: "Iris sib. 'Colonel Mustard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 191,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NAGEYYYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAGEYYYG'
    },
    {
      status: 210,
      sku: 'PM0083152',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270576',
      rtl_batch_array: '6270576',
      name_search: 'ROSRFAIR',
      name: 'Rosa (P) Red Fairy',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 1884,
      chnn_stock_retail: 1884,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJ1Y384G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJ1Y384G'
    },
    {
      status: 910,
      sku: 'PM0073978',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEDENSIF',
      name: 'Hedychium densiflorum',
      rng_range_identifier: 'H080100C7.5',
      statusContent: 'dot',
      hash: 'LTWWDP7A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LTWWDP7A'
    },
    {
      status: 210,
      sku: 'PM0083153',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270577',
      rtl_batch_array: '6270577',
      name_search: 'ROFAIRY',
      name: "Rosa (P) 'The Fairy'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 4135,
      chnn_stock_retail: 4135,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N3R9LW9X;VX8TLPCV;TYHX8SJD;H8DP8BNS;PCR6L1V9;1L13XG31;GRX3V9J9',
      statusContent: 'dot',
      hash: 'GH6BZ99T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GH6BZ99T'
    },
    {
      status: 910,
      sku: 'PM0073980',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LEFORMOS',
      name: 'Leycesteria formosa',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore:
        '72LLZYLW;4LT85GG3;98BYDGT2;EA8EXSLG;DWBT25YS;CEY4EZNV;1391SWRD;9V7H93X2',
      statusContent: 'dot',
      hash: 'BXEEDL18',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BXEEDL18'
    },
    {
      status: 210,
      sku: 'PM0083154',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270578',
      rtl_batch_array: '6270578',
      name_search: 'ROWFAIRY',
      name: "Rosa (M) 'White Fairy'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2034,
      chnn_stock_retail: 2034,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZZX6YSZ',
      statusContent: 'dot',
      hash: 'P9B1EJAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P9B1EJAD'
    },
    {
      status: 910,
      sku: 'PM0075537',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334241',
      rtl_batch_array: '6280990, 6334241',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 60,
      sppl_prcp: 17.317,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'R2G8EJL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2G8EJL3'
    },
    {
      status: 810,
      sku: 'PM0075538',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280991',
      rtl_batch_array: '6280991',
      name_search: 'HAIWESTE',
      name: "Hamamelis intermedia 'Westerstede'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 39.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99VXX1VT;184P2J9E;H1D147C3;LH97N5K4;L82WKBPB;AB38ARHS;2KPL3N6J;SLC829GZ;R6AB1WW6;H41S4DVN;8RT9TGE6;VJG56TH8',
      statusContent: 'dot',
      hash: 'SGJ3SD8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGJ3SD8G'
    },
    {
      status: 810,
      sku: 'PM0075539',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280992',
      rtl_batch_array: '6280992',
      name_search: 'HAIYAMIN',
      name: "Hamamelis intermedia 'Yamina'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A7DX7DWJ;N9KTP7ND',
      statusContent: 'dot',
      hash: '4WW4D641',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WW4D641'
    },
    {
      status: 810,
      sku: 'PM0075540',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280993',
      rtl_batch_array: '6280993',
      name_search: 'HAIYAMIN',
      name: "Hamamelis intermedia 'Yamina'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.872,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A7DX7DWJ;N9KTP7ND',
      statusContent: 'dot',
      hash: 'LEXYP5EB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEXYP5EB'
    },
    {
      status: 810,
      sku: 'PM0075541',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280994',
      rtl_batch_array: '6280994',
      name_search: 'HAVIRGIN',
      name: 'Hamamelis virginiana',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PLT32T6E;KHE2ADXH',
      statusContent: 'dot',
      hash: 'PWPY9KBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWPY9KBP'
    },
    {
      status: 810,
      sku: 'PM0075542',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280995',
      rtl_batch_array: '6280995',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'CBBYEKG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBBYEKG1'
    },
    {
      status: 810,
      sku: 'PM0075544',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280997',
      rtl_batch_array: '6280997',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: '7L4W3CTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7L4W3CTK'
    },
    {
      status: 810,
      sku: 'PM0075545',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280998',
      rtl_batch_array: '6280998',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.486,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: '57JT11YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57JT11YC'
    },
    {
      status: 810,
      sku: 'PM0075546',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6280999',
      rtl_batch_array: '6280999',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'H9SW71LZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9SW71LZ'
    },
    {
      status: 810,
      sku: 'PM0075548',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281001',
      rtl_batch_array: '6281001',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '175200C50',
      rng_range_identifier: 'H175200C50',
      rng_range_description: 'H175 cm 200 cm C50',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 110.567,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'N7H7DTED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7H7DTED'
    },
    {
      status: 810,
      sku: 'PM0075549',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281002',
      rtl_batch_array: '6281002',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: '8BPY32KN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BPY32KN'
    },
    {
      status: 810,
      sku: 'PM0075550',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281003',
      rtl_batch_array: '6281003',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: '84P3L7JB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84P3L7JB'
    },
    {
      status: 910,
      sku: 'PM0073976',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FANVOLCA',
      name: "Fargesia nitida 'Volcano'",
      rng_range_identifier: 'H060080C3.5',
      rng_range_description: 'H60 cm 80 cm C3.5',
      imageCore:
        'E1YHH3LN;YATD6SES;C2ZJKAZZ;YXYYZNP3;V7NE4YHH;HBCNEK3C;CSRJN8AZ;4A3CL2TE;EJK2B397;96TNCY85;R78C6ZJB;5RRGDYE7;XKLV9E5X;YAETVGP2;WGW2SB69;7PACLG33;D8BCVJDX',
      statusContent: 'dot',
      hash: 'YW6NKVY2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YW6NKVY2'
    },
    {
      status: 210,
      sku: 'PM0028393',
      core_name: 'Plant',
      sppl_ean: '8720349405411',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '5592177',
      rtl_batch_array: '5321503, 5592177',
      name_search: 'HOFWILLI',
      name: "Hosta 'Frances Williams'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 5377,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LNGTS6XP;93Z2AGV3;419YB131;A1RST7AP;TTA42KKK;NAD3R12L;ZH8R333C;Z7B9YTE3;SZCN38VD;2YJZVKYE;SYGRP3NC;VRET55BV;TEEA5X96',
      imageBatch: 'NKCAGTZY',
      statusContent: 'dot',
      hash: '2LRNJNJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LRNJNJB'
    },
    {
      status: 210,
      sku: 'PM0024688',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253102',
      rtl_batch_array: '6253102',
      name_search: 'HEMBEAUT',
      name: "Hebe 'Midsummer Beauty'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1105,
      chnn_stock_retail: 1105,
      sppl_prcp: 4.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9SE4CNH',
      statusContent: 'dot',
      hash: '2T86E93X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T86E93X'
    },
    {
      status: 210,
      sku: 'PM0026643',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6272840',
      rtl_batch_array: '6272840',
      name_search: 'HEMETTE',
      name: "Hebe 'Mette'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_prcp: 7.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78SY1GV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78SY1GV5'
    },
    {
      status: 210,
      sku: 'PM0018385',
      core_name: 'Plant',
      sppl_ean: '8720349426225',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5357086',
      rtl_batch_array: '5357086',
      name_search: 'PRUGRAND',
      name: 'Prunella grandiflora',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1560,
      chnn_stock_retail: 1560,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X33CT752;KT2BS474;HCK28WA2;JYCVYCEL;TJYB15AK;A58JW5AK;EJ9NAPVY;AX2X4G7L;CNDZ6ZT8;LWR94162;14P61YAZ;G4EHCLCH',
      imageBatch: '8VHXN7HN',
      statusContent: 'dot',
      hash: '9LJATT1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LJATT1V'
    },
    {
      status: 210,
      sku: 'PM0011974',
      core_name: 'Plant',
      sppl_ean: '8720349472697',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905566',
      rtl_batch_array: '5905566',
      name_search: 'MISLZEBR',
      name: "Miscanthus sinensis 'Little Zebra'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BAH38TT2;TDP9N3BL;GEC3B53L;NE7R6WX4;2AHZS4T1;EL6R5VD1;W65LCK79;1DBC5DWS;67CSE5S5;XSGL1AJS;L3DRCPG9;L8KKHVC4;T48CSEGE;RJX7NYN5;1TJGJKHR;E38CYGAD;G78WEZ1E;9DV7SS41;2PW7E9K7;94Z37G9N;CE5TD7DG;2E3EBBTE;DPB312PR;JT23LDD1;VBC6AEA5;APXLE99T',
      statusContent: 'dot',
      hash: 'B6AEWTW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6AEWTW2'
    },
    {
      status: 910,
      sku: 'PM0029776',
      core_name: 'Plant',
      sppl_ean: '8720626381162',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '5802178',
      rtl_batch_array: '5802178',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '060070C4',
      rng_range_identifier: 'H060070C4',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 4.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: 'H6J6ZDWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6J6ZDWG'
    },
    {
      status: 210,
      sku: 'PM0083155',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270579',
      rtl_batch_array: '6270579',
      name_search: 'RUBASHBU',
      name: "Rubus 'Betty Ashburner'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1524,
      chnn_stock_retail: 1524,
      sppl_order_minimum: 3,
      sppl_prcp: 0.755,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYP2JZ6K;HCSYZTVW;TPJSAC7H;L428PXVJ;XGXN52JG;7HYAZN6S;KDRV3CNZ;TYPX546V;P4KWW8HL;N6SRHC6W;DN37EEBW',
      statusContent: 'dot',
      hash: '7ERXNGD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ERXNGD9'
    },
    {
      status: 210,
      sku: 'PM0013645',
      core_name: 'Plant',
      sppl_ean: '8720626353657',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5396924',
      rtl_batch_array: '5396924',
      name_search: 'HYQICRYS',
      name: 'Hydrangea quercifolia Ice Crystal',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 975,
      chnn_stock_retail: 975,
      sppl_prcp: 3.638,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PVS2XB57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVS2XB57'
    },
    {
      status: 210,
      sku: 'PM0003124',
      core_name: 'Plant',
      sppl_ean: '8720626302785',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '5861499',
      rtl_batch_array: '5861499',
      name_search: 'HEONZGOL',
      name: "Hebe odora 'New Zealand Gold'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RJ7AH79T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJ7AH79T'
    },
    {
      status: 810,
      sku: 'PM0021490',
      core_name: 'Plant',
      sppl_ean: '8720664591875',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5495451',
      rtl_batch_array: '6084630, 5495451, 6301769',
      name_search: 'PEWBEDDE',
      name: "Penstemon 'White Bedder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 533,
      sppl_order_minimum: 3,
      sppl_prcp: 0.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DYZWWVBT;2ECBN5G2;923PZEEJ;A6DAHHZN;563PGEP9;BW2W1TAC;LHCG71B3;TAAVV9WT;LHG916ZC',
      statusContent: 'dot',
      hash: 'TTL2B6RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTL2B6RT'
    },
    {
      status: 910,
      sku: 'PM0017963',
      core_name: 'Plant',
      sppl_ean: '8720664803695',
      btch_manufacturer: 142908,
      rtl_batch_array_total: 1,
      btch_active_retail: '5924217',
      rtl_batch_array: '5924217',
      name_search: 'PRDOPAL',
      name: "Prunus domestica 'Opal'",
      sppl_size_code: '080090C5',
      rng_range_identifier: 'H080090C5',
      rng_range_description: 'H80 cm 90 cm C5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 7.59,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6PSZWG;BSXAWWB3;BN3WZB67;6B85S8H6;VWP38YZR;ZSCHLWLA',
      statusContent: 'dot',
      hash: 'V2KE113A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2KE113A'
    },
    {
      status: 910,
      sku: 'PM0023308',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'INHETERA',
      name: 'Indigofera heterantha',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      imageCore: '9GZ6L55Y',
      statusContent: 'dot',
      hash: 'X1TAX7D4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X1TAX7D4'
    },
    {
      status: 210,
      sku: 'PM0083156',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270580',
      rtl_batch_array: '6270580',
      name_search: 'RUTRICOL',
      name: 'Rubus tricolor',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2418,
      chnn_stock_retail: 2418,
      sppl_order_minimum: 3,
      sppl_prcp: 0.755,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9Z754P4K',
      statusContent: 'dot',
      hash: '9S44VJ73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S44VJ73'
    },
    {
      status: 210,
      sku: 'PM0083157',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270582',
      rtl_batch_array: '6270582',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2932,
      chnn_stock_retail: 2932,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: '1JRVR8DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JRVR8DB'
    },
    {
      status: 210,
      sku: 'PM0083158',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270583',
      rtl_batch_array: '6270583',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2912,
      chnn_stock_retail: 2912,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'JKCABJ41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKCABJ41'
    },
    {
      status: 210,
      sku: 'PM0083159',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270584',
      rtl_batch_array: '6270584',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3013,
      chnn_stock_retail: 3013,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'PH65CK2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH65CK2P'
    },
    {
      status: 210,
      sku: 'PM0083160',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270585',
      rtl_batch_array: '6270585',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5053,
      chnn_stock_retail: 5053,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'LE92KPPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LE92KPPK'
    },
    {
      status: 210,
      sku: 'PM0083161',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270586',
      rtl_batch_array: '6270586',
      name_search: 'SPJDRED',
      name: "Spiraea japonica 'Dart's Red'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5141,
      chnn_stock_retail: 5141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V48RLPL3',
      statusContent: 'dot',
      hash: '9AJV14JG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AJV14JG'
    },
    {
      status: 210,
      sku: 'PM0083162',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270587',
      rtl_batch_array: '6270587',
      name_search: 'SPJFIREL',
      name: "Spiraea japonica 'Firelight'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5141,
      chnn_stock_retail: 5141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6RV2C77;KKHHCA3V;STSC46D8;Y6JNBEPZ;GRNCJ8L4;5X39CDEG;RHCNZLBG;85R683CC;T4W9EBZP',
      statusContent: 'dot',
      hash: 'DB7WY8N6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB7WY8N6'
    },
    {
      status: 210,
      sku: 'PM0083163',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270588',
      rtl_batch_array: '6270588',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5896,
      chnn_stock_retail: 5896,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'BSL98WBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSL98WBN'
    },
    {
      status: 210,
      sku: 'PM0083164',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270620',
      rtl_batch_array: '6270620',
      name_search: 'LONHFIND',
      name: "Lonicera nitida 'Hohenheimer Findling'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2490,
      chnn_stock_retail: 2490,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XV9H9RGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV9H9RGC'
    },
    {
      status: 210,
      sku: 'PM0083165',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270621',
      rtl_batch_array: '6270621',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1764,
      chnn_stock_retail: 1764,
      sppl_prcp: 2.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'VVS7V5CR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVS7V5CR'
    },
    {
      status: 210,
      sku: 'PM0083166',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270622',
      rtl_batch_array: '6270622',
      name_search: 'LOPILEAT',
      name: 'Lonicera pileata',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 4607,
      chnn_stock_retail: 4607,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CB8ZXBLH',
      statusContent: 'dot',
      hash: 'XL1YTLT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL1YTLT1'
    },
    {
      status: 210,
      sku: 'PM0083167',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270626',
      rtl_batch_array: '6270626',
      name_search: 'PEALBLUE',
      name: 'Perovskia atriplicifolia Lacey Blue',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 716,
      chnn_stock_retail: 716,
      sppl_order_minimum: 3,
      sppl_prcp: 1.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEJ7ZK7G',
      statusContent: 'dot',
      hash: 'S4SAZZYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4SAZZYT'
    },
    {
      status: 210,
      sku: 'PM0083168',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270627',
      rtl_batch_array: '6270627',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3866,
      chnn_stock_retail: 3866,
      sppl_order_minimum: 3,
      sppl_prcp: 0.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'H6K5WB94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6K5WB94'
    },
    {
      status: 210,
      sku: 'PM0083169',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270628',
      rtl_batch_array: '6270628',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_prcp: 11.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'B9H7V9CY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9H7V9CY'
    },
    {
      status: 210,
      sku: 'PM0083170',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270631',
      rtl_batch_array: '6270631',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 8165,
      chnn_stock_retail: 8165,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'DB4BSADZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB4BSADZ'
    },
    {
      status: 210,
      sku: 'PM0083171',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270632',
      rtl_batch_array: '6270632',
      name_search: 'POFBLINK',
      name: "Potentilla fruticosa 'Blink'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2600,
      chnn_stock_retail: 2600,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '66LY5D8Y;HLAEKCP6;59DVX3AS;YS2ZBCEZ;L28DN3LJ;1J6D5Z9B',
      statusContent: 'dot',
      hash: 'RE8ZL4H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RE8ZL4H8'
    },
    {
      status: 210,
      sku: 'PM0083172',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270633',
      rtl_batch_array: '6270633',
      name_search: 'POFELIZA',
      name: "Potentilla fruticosa 'Elizabeth'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3213,
      chnn_stock_retail: 3213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XY5JR1T3;BWWTSRRY;6SR67SWX;79CS1C7X',
      statusContent: 'dot',
      hash: '9ARGHX3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ARGHX3D'
    },
    {
      status: 210,
      sku: 'PM0083173',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270634',
      rtl_batch_array: '6270634',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2943,
      chnn_stock_retail: 2943,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'R7894XZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R7894XZN'
    },
    {
      status: 210,
      sku: 'PM0083174',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270635',
      rtl_batch_array: '6270635',
      name_search: 'POFLIMEL',
      name: "Potentilla fruticosa 'Limelight'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1297,
      chnn_stock_retail: 1297,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7C78VJ2T;BVG6EN1S;5725WCYW',
      statusContent: 'dot',
      hash: 'G4L44ZRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4L44ZRW'
    },
    {
      status: 210,
      sku: 'PM0083175',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270636',
      rtl_batch_array: '6270636',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2794,
      chnn_stock_retail: 2794,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: 'W69VZ58C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W69VZ58C'
    },
    {
      status: 210,
      sku: 'PM0083176',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270637',
      rtl_batch_array: '6270637',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2903,
      chnn_stock_retail: 2903,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'RVKWW1ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVKWW1ZA'
    },
    {
      status: 210,
      sku: 'PM0083177',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270638',
      rtl_batch_array: '6270638',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3013,
      chnn_stock_retail: 3013,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'D5TBZR23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5TBZR23'
    },
    {
      status: 210,
      sku: 'PM0083178',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270639',
      rtl_batch_array: '6270639',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3054,
      chnn_stock_retail: 3054,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'P7Z6VG16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7Z6VG16'
    },
    {
      status: 210,
      sku: 'PM0083179',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270640',
      rtl_batch_array: '6270640',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 6106,
      chnn_stock_retail: 6106,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'X7ATSAE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7ATSAE3'
    },
    {
      status: 210,
      sku: 'PM0083180',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270641',
      rtl_batch_array: '6270641',
      name_search: 'SPJMANON',
      name: "Spiraea japonica 'Manon'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2539,
      chnn_stock_retail: 2539,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZAWNADB',
      statusContent: 'dot',
      hash: '4L2AAZ8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4L2AAZ8B'
    },
    {
      status: 210,
      sku: 'PM0083181',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270642',
      rtl_batch_array: '6270642',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1309,
      chnn_stock_retail: 1309,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: 'YXGEHLTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXGEHLTZ'
    },
    {
      status: 210,
      sku: 'PM0083182',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270643',
      rtl_batch_array: '6270643',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2498,
      chnn_stock_retail: 2498,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'TYE96ET2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYE96ET2'
    },
    {
      status: 210,
      sku: 'PM0083183',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270675',
      rtl_batch_array: '6270675',
      name_search: 'CAMORROW',
      name: 'Carex morrowii',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2052,
      chnn_stock_retail: 2052,
      sppl_order_minimum: 3,
      sppl_prcp: 1.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEY1DCSC',
      statusContent: 'dot',
      hash: 'X13TXN7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X13TXN7H'
    },
    {
      status: 210,
      sku: 'PM0083184',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270676',
      rtl_batch_array: '6270676',
      name_search: 'CAMORROW',
      name: 'Carex morrowii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_prcp: 2.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEY1DCSC',
      statusContent: 'dot',
      hash: 'C31JKC4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C31JKC4V'
    },
    {
      status: 210,
      sku: 'PM0083185',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270677',
      rtl_batch_array: '6270677',
      name_search: 'CAMVARIE',
      name: "Carex morrowii 'Variegata'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 734,
      chnn_stock_retail: 734,
      sppl_order_minimum: 3,
      sppl_prcp: 1.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DP8YHZL;241SZTBP;GEGS2V1J;ZK2Z8344;R2C431V3;DG29CYDV;131Z5XCX;Z8HR1279;LC43BVGL;G4KX662C;2RDJWBWG;L6GBZDX9;2PYRSH5B;NT3Y556J;BKSK346V;XY4YBPWN;18LKWLGE;GHLB8LKS;AZEB3EZ7;8C47P6A2',
      statusContent: 'dot',
      hash: 'H6TSVDTR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H6TSVDTR'
    },
    {
      status: 210,
      sku: 'PM0083186',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270680',
      rtl_batch_array: '6270680',
      name_search: 'COSPUMIL',
      name: "Cortaderia selloana 'Pumila'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1307,
      chnn_stock_retail: 1307,
      sppl_prcp: 3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9D64WYD;EABS1WL7;9LS73NKX;2LJSLJ2H;9EH992DH;4JW46VN3;8D1CJHSH;4VH4Y5RK;1NWRPTE4;ZAWSHEHD;JE87W3L3;62Z2KTT1;SAAK62BN;CA4X7JHG;ZNBJV8Y3;4DK5LPAV',
      statusContent: 'dot',
      hash: 'PG1CJANX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PG1CJANX'
    },
    {
      status: 210,
      sku: 'PM0083187',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270681',
      rtl_batch_array: '6270681',
      name_search: 'DECESPIT',
      name: 'Deschampsia cespitosa',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_prcp: 2.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWAZ5E95',
      statusContent: 'dot',
      hash: 'E1DZEJ8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1DZEJ8R'
    },
    {
      status: 210,
      sku: 'PM0083188',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270692',
      rtl_batch_array: '6270692',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2682,
      chnn_stock_retail: 2682,
      sppl_order_minimum: 3,
      sppl_prcp: 1.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'W483J2J5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W483J2J5'
    },
    {
      status: 210,
      sku: 'PM0083189',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270693',
      rtl_batch_array: '6270693',
      name_search: 'MOAKFOER',
      name: "Molinia arundinacea 'Karl Foerster'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1213,
      chnn_stock_retail: 1213,
      sppl_order_minimum: 3,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RYY9XHX8;4Z1GG7NG;2EVDY2G3;DAGD582L;CE33VY79;VB9X6CZ4;Z1E1584H;CBATDK6R;E8PYECE4;7SVVWBK2;H9ZL2RJS;J3KBTD3S;W4DSYPB1',
      statusContent: 'dot',
      hash: 'Y22VTX4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y22VTX4K'
    },
    {
      status: 210,
      sku: 'PM0083190',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270697',
      rtl_batch_array: '6270697',
      name_search: 'PEALOPEC',
      name: 'Pennisetum alopecuroides',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2212,
      chnn_stock_retail: 2212,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TRWLYSDR;83B33ADA;27VD1BC4;7LNJ3ARN;SNP3L9WL;GW5J4LST;L9HPT3EL;D4LB3PJK;KLP2TT9K;S46D2SL9;8CV52Z65;YKGTHGSS;VZVDC4J7;S4RBGSPH;D6S1W9CB;149JH1D2;PG5CA87X;NPW9N9WY;YSN92SY3',
      statusContent: 'dot',
      hash: 'VREXECSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VREXECSR'
    },
    {
      status: 210,
      sku: 'PM0083191',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270698',
      rtl_batch_array: '6270698',
      name_search: 'PEACASSI',
      name: "Pennisetum alopecuroides 'Cassian'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 647,
      chnn_stock_retail: 647,
      sppl_order_minimum: 3,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5E34TGNR;CP3TBSRW',
      statusContent: 'dot',
      hash: 'EJLCWKGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJLCWKGL'
    },
    {
      status: 210,
      sku: 'PM0083192',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270700',
      rtl_batch_array: '6270700',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 10648,
      chnn_stock_retail: 10648,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'ZT1VT8X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZT1VT8X2'
    },
    {
      status: 210,
      sku: 'PM0083193',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270703',
      rtl_batch_array: '6270703',
      name_search: 'PEALBUNN',
      name: "Pennisetum alopecuroides 'Little Bunny'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5369,
      chnn_stock_retail: 5369,
      sppl_order_minimum: 3,
      sppl_prcp: 1.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63YVZZCG;A1BBJ6YJ;B5P86S8E;TH6PBLYX;GPV1LGBL;BNVEJBEX;5A63TARS;XN9HZKN6;6VRVEYET;1LKNTYL8;9P4AJG3C;Z5EA68H7;BY43YC1W;T159CA6D;EYTDLB28;EN1ZK2DY;9BY9SNAW;776T7RDZ;JGPE9T29;WAY2L8DE',
      statusContent: 'dot',
      hash: '7Z4BW7RD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z4BW7RD'
    },
    {
      status: 210,
      sku: 'PM0083194',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270704',
      rtl_batch_array: '6270704',
      name_search: 'PEAREBOR',
      name: "Pennisetum alopecuroides 'Reborn'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1293,
      chnn_stock_retail: 1293,
      sppl_order_minimum: 3,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BE2SJTSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BE2SJTSS'
    },
    {
      status: 210,
      sku: 'PM0083195',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6270705',
      rtl_batch_array: '6270705',
      name_search: 'PEOFTAIL',
      name: "Pennisetum orientale 'Fairy Tails'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1032,
      chnn_stock_retail: 1032,
      sppl_order_minimum: 3,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4AJL1YLS;4WDJXT26',
      statusContent: 'dot',
      hash: 'V71BBBYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V71BBBYC'
    },
    {
      status: 210,
      sku: 'PM0083196',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283764',
      rtl_batch_array: '6283764',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 193,
      chnn_stock_retail: 193,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '1H6K633E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1H6K633E'
    },
    {
      status: 210,
      sku: 'PM0083197',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283765',
      rtl_batch_array: '6283765',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 182,
      chnn_stock_retail: 182,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'LXY75H1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXY75H1A'
    },
    {
      status: 210,
      sku: 'PM0083198',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283766',
      rtl_batch_array: '6283766',
      name_search: 'CEIVICTO',
      name: "Ceanothus impressus 'Victoria'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 363,
      chnn_stock_retail: 363,
      sppl_prcp: 13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44K5NBYW;8TNED8P4;GP2AJCGE;LD73LVKY;EHZLC4K7;P3H93PRW;TZSYEV99;KJ9P9TGP;AS1ZD1PC;1G2AYZEE;BGHY1W5K;G1VRTS2W;12TK9EVS;LZV52BW7;D6NCLPGR',
      statusContent: 'dot',
      hash: 'V8H24SN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8H24SN1'
    },
    {
      status: 210,
      sku: 'PM0083199',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283767',
      rtl_batch_array: '6283767',
      name_search: 'CETSKYLA',
      name: "Ceanothus thyrsiflorus 'Skylark'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1X7G1B65;21VCABN9;KT3BJB91',
      statusContent: 'dot',
      hash: 'LH3R4ZJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH3R4ZJJ'
    },
    {
      status: 210,
      sku: 'PM0083200',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283768',
      rtl_batch_array: '6283768',
      name_search: 'CHSNIVAL',
      name: "Chaenomeles speciosa 'Nivalis'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BYVSYJ1',
      statusContent: 'dot',
      hash: 'CAD94XJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAD94XJ3'
    },
    {
      status: 910,
      sku: 'PM0083201',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283769',
      rtl_batch_array: '6283769',
      name_search: 'CHSCGOLD',
      name: "Chaenomeles superba 'Crimson and Gold'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GNNN4HGJ;E1DGH2HZ;7Z873B33;14JX32TV;7YG1ETPX;D2TYTT6D',
      statusContent: 'dot',
      hash: 'BBE4BH1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBE4BH1T'
    },
    {
      status: 210,
      sku: 'PM0083202',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283770',
      rtl_batch_array: '6283770',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 116,
      chnn_stock_retail: 116,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'NBTBY9BX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBTBY9BX'
    },
    {
      status: 810,
      sku: 'PM0083203',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283771',
      rtl_batch_array: '6283771',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'G5ES5KG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5ES5KG3'
    },
    {
      status: 810,
      sku: 'PM0083204',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283772',
      rtl_batch_array: '6283772',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 12.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'RR26LKZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR26LKZP'
    },
    {
      status: 210,
      sku: 'PM0083205',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283775',
      rtl_batch_array: '6283775',
      name_search: 'COFRANCH',
      name: 'Cotoneaster franchetii',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 245,
      chnn_stock_retail: 245,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXNKLEV;GZXP3SPT;SHC2WW8A;97WRZ5Z5;A9PYDJRT;HE9WLZST;LN37ELVR;GCVYBHAV',
      statusContent: 'dot',
      hash: '8VBTHRWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VBTHRWP'
    },
    {
      status: 210,
      sku: 'PM0083206',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373111',
      rtl_batch_array: '6373111, 6283788',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 197,
      chnn_stock_retail: 331,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'BYHCLB5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYHCLB5B'
    },
    {
      status: 210,
      sku: 'PM0083207',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283789',
      rtl_batch_array: '6283789',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_prcp: 13.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'HD9BS3CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HD9BS3CD'
    },
    {
      status: 810,
      sku: 'PM0083208',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283800',
      rtl_batch_array: '6283800',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 16.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'E45LTPYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E45LTPYY'
    },
    {
      status: 810,
      sku: 'PM0083209',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283801',
      rtl_batch_array: '6283801',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_prcp: 16.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: '1KGC4SS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KGC4SS1'
    },
    {
      status: 810,
      sku: 'PM0083210',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283809',
      rtl_batch_array: '6283809',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: '7CN9SBX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CN9SBX1'
    },
    {
      status: 210,
      sku: 'PM0083211',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283814',
      rtl_batch_array: '6283814',
      name_search: 'HYMNBLUE',
      name: "Hydrangea macrophylla 'Nikko Blue'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 131,
      chnn_stock_retail: 131,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NJ6P89TW;529WH1WX',
      statusContent: 'dot',
      hash: 'L6SAYYEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6SAYYEB'
    },
    {
      status: 810,
      sku: 'PM0083212',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283815',
      rtl_batch_array: '6283815',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 79,
      chnn_stock_retail: 79,
      sppl_prcp: 10.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A43C7RKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A43C7RKX'
    },
    {
      status: 210,
      sku: 'PM0083213',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283822',
      rtl_batch_array: '6283822',
      name_search: 'HYSBLUEB',
      name: "Hydrangea serrata 'Bluebird'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 122,
      chnn_stock_retail: 122,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX3S7BHT;X2N4H4RX;NS9WB94G;H13KLKRT;WHEYVEAP;X2LYEXHP',
      statusContent: 'dot',
      hash: 'SKS93SCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKS93SCS'
    },
    {
      status: 210,
      sku: 'PM0083214',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283826',
      rtl_batch_array: '6283826',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 233,
      chnn_stock_retail: 233,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'NDV3WDB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDV3WDB5'
    },
    {
      status: 210,
      sku: 'PM0083215',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283842',
      rtl_batch_array: '6283842',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 1709,
      chnn_stock_retail: 1709,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '7CNA9CHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CNA9CHL'
    },
    {
      status: 210,
      sku: 'PM0083216',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283843',
      rtl_batch_array: '6283843',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'B75ZL3BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B75ZL3BE'
    },
    {
      status: 810,
      sku: 'PM0083217',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283844',
      rtl_batch_array: '6283844',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'ZG65XPYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZG65XPYK'
    },
    {
      status: 810,
      sku: 'PM0075553',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281009',
      rtl_batch_array: '6281009',
      name_search: 'ACPCQUEE',
      name: "Acer palmatum 'Crimson Queen'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W7E79K61;TG6EH1LR;KAGWVW5C;T1ZWLR9L;NZNGZTEL;TR5KKTDR;B3YX7SAN;N19837JR',
      statusContent: 'dot',
      hash: '1YZEVYTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YZEVYTY'
    },
    {
      status: 810,
      sku: 'PM0075566',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281029',
      rtl_batch_array: '6281029',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.922,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: 'LT9GSTTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT9GSTTP'
    },
    {
      status: 810,
      sku: 'PM0075565',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281028',
      rtl_batch_array: '6281028',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: 'LH5JKWWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH5JKWWN'
    },
    {
      status: 810,
      sku: 'PM0075554',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281012',
      rtl_batch_array: '6281012',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '080STC10',
      rng_range_identifier: 'STE080C10',
      rng_range_description: 'Stem 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'C45D7AA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C45D7AA4'
    },
    {
      status: 810,
      sku: 'PM0075556',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281015',
      rtl_batch_array: '6281015',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '100125C18',
      rng_range_identifier: 'H100125C18',
      rng_range_description: 'H100 cm 125 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 59.835,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'NP9YHRZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NP9YHRZ3'
    },
    {
      status: 810,
      sku: 'PM0075555',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281013',
      rtl_batch_array: '6281013',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'JVXAA9AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVXAA9AT'
    },
    {
      status: 810,
      sku: 'PM0075562',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281025',
      rtl_batch_array: '6281025',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '080STC10',
      rng_range_identifier: 'STE080C10',
      rng_range_description: 'Stem 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'JEX96EBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEX96EBH'
    },
    {
      status: 810,
      sku: 'PM0075563',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281026',
      rtl_batch_array: '6281026',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 49.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'WV8ELYJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WV8ELYJ1'
    },
    {
      status: 810,
      sku: 'PM0075561',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334278',
      rtl_batch_array: '6281024, 6334278',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 21.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'G223XJ32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G223XJ32'
    },
    {
      status: 810,
      sku: 'PM0075560',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281023',
      rtl_batch_array: '6281023',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.405,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'X12Y31VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X12Y31VX'
    },
    {
      status: 910,
      sku: 'PM0073981',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MEGLYPTO',
      name: 'Metasequoia glyptostroboides',
      rng_range_identifier: 'H100120C3.5',
      rng_range_description: 'H100 cm 120 cm C3.5',
      imageCore:
        'APZV6165;EEDG59S2;Y3EJYXBA;TWP9Y85L;NSNDZL7H;PG49N7R1;KVA96A2A;YLYJJ13K;HKEJX78L;4ZTPD9WJ;DLDSW7N8;PNBZXV8L;CZ7LZREB;R8K1C5HK;H47B2JEC;CHS94XV8',
      statusContent: 'dot',
      hash: 'T1Y7SKWJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T1Y7SKWJ'
    },
    {
      status: 810,
      sku: 'PM0075567',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281030',
      rtl_batch_array: '6281030',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 24.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'RC7JYL6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC7JYL6C'
    },
    {
      status: 210,
      sku: 'PM0025399',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 1,
      btch_active_retail: '6231143',
      rtl_batch_array: '6231143',
      name_search: 'GEOSDOUB',
      name: "Geranium oxonianum 'Southcombe Double'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VY5NTAE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY5NTAE3'
    },
    {
      status: 810,
      sku: 'PM0083218',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283846',
      rtl_batch_array: '6283846',
      name_search: 'PHODGOLD',
      name: "Physocarpus opulifolius 'Dart's Gold'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT3EHAK4;5J1AEW71;CEB9A2CN;KR57V7LC;57AY6WGT;W72GA6XT;ZYVKPVD1;E88NV4JZ;LJ7SEPK5;5YSBLWSE;1WC4ZGC3;613S8JBD',
      statusContent: 'dot',
      hash: 'JTST42Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTST42Z6'
    },
    {
      status: 210,
      sku: 'PM0019285',
      core_name: 'Plant',
      sppl_ean: '8720664867581',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6161746',
      rtl_batch_array: '6161746, 6308593',
      name_search: 'HESSEAS',
      name: "Heuchera 'Stormy Seas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 932,
      chnn_stock_retail: 1009,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRNEL1LS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRNEL1LS'
    },
    {
      status: 810,
      sku: 'PM0075568',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281031',
      rtl_batch_array: '6281031',
      name_search: 'ACPORANG',
      name: "Acer palmatum 'Orangeola'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SJ46DJZL',
      statusContent: 'dot',
      hash: 'YLD6RB8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLD6RB8K'
    },
    {
      status: 810,
      sku: 'PM0075569',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281032',
      rtl_batch_array: '6281032',
      name_search: 'ACPORNAT',
      name: "Acer palmatum 'Ornatum'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L53NTBYD;TJDALSHC;KY57VLLJ;B1L6EBNH;XD8NP4A5;1X43S6NZ;15R88YV7;R5V17KY4;N3L8GKHK;WRN7TTT9;X2JB897R;X41JVPBA;CAN9NDA3;6B5BHD5T',
      statusContent: 'dot',
      hash: 'PPK5DNXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPK5DNXX'
    },
    {
      status: 210,
      sku: 'PM0083219',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283849',
      rtl_batch_array: '6283849',
      name_search: 'PRSPINOS',
      name: 'Prunus spinosa',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 347,
      chnn_stock_retail: 347,
      sppl_prcp: 9.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2NNAGJ3G;HCLHBHJR;ACJT86AY;ZCDXKXH2;9YTBC1EC;Y994TXX8;49PWEAKR;BVNEB8A6;P6BZ84H3;564BYGKH;NT3ZJDWL;1GYEV4PZ;74VT253K;28PJD3E1;9XPT87KW;236N7DA1;8XKL4D8S;ZNGC59GE;R474ZWDV;X45CPXEL;DGX6S9DL;RG5KAWSY',
      statusContent: 'dot',
      hash: 'PJT1BBWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJT1BBWJ'
    },
    {
      status: 210,
      sku: 'PM0083220',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283853',
      rtl_batch_array: '6283853',
      name_search: 'PYSOR',
      name: "Pyracantha 'Soleil d'Or'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_prcp: 15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V7SYWATP;67HWYKH5;GNRYZY8E;2CLD6RED;ESH89THA;8KSP5GWY;V5D1LT7N;9JHPT4PG;ELJVPHGC',
      statusContent: 'dot',
      hash: 'RZB6JYCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZB6JYCT'
    },
    {
      status: 210,
      sku: 'PM0083221',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283856',
      rtl_batch_array: '6283856',
      name_search: 'SPBTOR',
      name: "Spiraea betulifolia 'Tor'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2V7SBH2Z',
      statusContent: 'dot',
      hash: 'NWC7L1TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWC7L1TB'
    },
    {
      status: 210,
      sku: 'PM0083222',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283857',
      rtl_batch_array: '6283857',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'LT1YWWL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LT1YWWL6'
    },
    {
      status: 210,
      sku: 'PM0083223',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283863',
      rtl_batch_array: '6283863',
      name_search: 'VIOCOMPA',
      name: "Viburnum opulus 'Compactum'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 251,
      chnn_stock_retail: 251,
      sppl_prcp: 14.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JLW9GYZG;N1WVVLJL;SSSA4D7Z;S2ETVPEP;5EZ7H6JG;5YVPKHRJ;82WDLAPS;789BPGGD;XGYKNAR4;946JB97P',
      statusContent: 'dot',
      hash: 'TNA79BP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNA79BP8'
    },
    {
      status: 210,
      sku: 'PM0083224',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283864',
      rtl_batch_array: '6283864',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: 'EHWVV2NB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHWVV2NB'
    },
    {
      status: 810,
      sku: 'PM0075557',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281016',
      rtl_batch_array: '6281016',
      name_search: 'ACPELLEN',
      name: "Acer palmatum 'Ellen'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRBG6A2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRBG6A2B'
    },
    {
      status: 810,
      sku: 'PM0075558',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281017',
      rtl_batch_array: '6281017',
      name_search: 'ACPELLEN',
      name: "Acer palmatum 'Ellen'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WJ9PSVV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJ9PSVV2'
    },
    {
      status: 810,
      sku: 'PM0075559',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281019',
      rtl_batch_array: '6281019',
      name_search: 'ACPFIREC',
      name: "Acer palmatum 'Firecracker'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RZT9SKTC;CD296LJC;H2ZAS53R',
      statusContent: 'dot',
      hash: 'VW73ZCL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VW73ZCL9'
    },
    {
      status: 210,
      sku: 'PM0083225',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283867',
      rtl_batch_array: '6283867',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 11.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: 'VEHSJE4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VEHSJE4V'
    },
    {
      status: 210,
      sku: 'PM0083226',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6283870',
      rtl_batch_array: '6283870',
      name_search: 'CEWILLMO',
      name: 'Ceratostigma willmottianum',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8VW817YL',
      statusContent: 'dot',
      hash: '6W523SK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W523SK2'
    },
    {
      status: 910,
      sku: 'PM0073982',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TARPCASC',
      name: "Tamarix ramosissima 'Pink Cascade'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      statusContent: 'dot',
      hash: 'Y2X698LW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y2X698LW'
    },
    {
      status: 910,
      sku: 'PM0083227',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAJBYELL',
      name: "Camellia japonica 'Brushfield's Yellow'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'H6ZLZ48A;9KNXTTVB;TD3XAJD1;NTG3LD66;PS86LZWJ;46A7G9CE;EEDNZWXD',
      statusContent: 'dot',
      hash: '5YW76GLR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5YW76GLR'
    },
    {
      status: 210,
      sku: 'PM0083228',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274935',
      rtl_batch_array: '6274935',
      name_search: 'CAJDKING',
      name: "Camellia japonica 'Dr King'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_prcp: 20.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z84V5AG7;KBH3YV7B;G6BLA2AS;P3XY1A7H;7TYH9PD4;P96HL321;CL6RG5BV;PDLSZJYK;1BNBP9XA;8LCXKEXS;6SKS2JC2;Y7DBVSXD;7B1PATVE;HAYAYEHY;KPHV6YXN',
      statusContent: 'dot',
      hash: '1KX6NER6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KX6NER6'
    },
    {
      status: 810,
      sku: 'PM0075564',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281027',
      rtl_batch_array: '6281027',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: '050060C15',
      rng_range_identifier: 'H050060C15',
      rng_range_description: 'H50 cm 60 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 26.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: 'A8TS54GS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8TS54GS'
    },
    {
      status: 210,
      sku: 'PM0083229',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274937',
      rtl_batch_array: '6274937',
      name_search: 'CAJKSUPR',
      name: "Camellia japonica 'Kramer's Supreme'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 20.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SZKXG2BE;JLJ7HGHH;YVVP68G5;7H7T94ZR;2N7ZTLYZ;LS7C6LHE;ZVY5X5Y2;7PHNL2Z7;1YZE48ZR;69B9BERC',
      statusContent: 'dot',
      hash: 'SSCX1391',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSCX1391'
    },
    {
      status: 910,
      sku: 'PM0083230',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275051',
      rtl_batch_array: '6275051',
      name_search: 'SCVSLIGH',
      name: "Sciadopitys vert. 'Shine a Light'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 32.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7N31KLEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N31KLEP'
    },
    {
      status: 910,
      sku: 'PM0083231',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275054',
      rtl_batch_array: '6275054',
      name_search: 'SCVERTIC',
      name: 'Sciadopitys verticillata',
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 20.114,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CBN1HY6;N6RBRE96;A3AAXPA6;9J7NWD3V;9X3DVLJP;D7NER187',
      statusContent: 'dot',
      hash: 'RZ69KLJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ69KLJB'
    },
    {
      status: 810,
      sku: 'PM0075570',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281033',
      rtl_batch_array: '6281033',
      name_search: 'ACPORNAT',
      name: "Acer palmatum 'Ornatum'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 13.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L53NTBYD;TJDALSHC;KY57VLLJ;B1L6EBNH;XD8NP4A5;1X43S6NZ;15R88YV7;R5V17KY4;N3L8GKHK;WRN7TTT9;X2JB897R;X41JVPBA;CAN9NDA3;6B5BHD5T',
      statusContent: 'dot',
      hash: 'KTJYB6TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTJYB6TZ'
    },
    {
      status: 810,
      sku: 'PM0075571',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281034',
      rtl_batch_array: '6281034',
      name_search: 'ACPORNAT',
      name: "Acer palmatum 'Ornatum'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L53NTBYD;TJDALSHC;KY57VLLJ;B1L6EBNH;XD8NP4A5;1X43S6NZ;15R88YV7;R5V17KY4;N3L8GKHK;WRN7TTT9;X2JB897R;X41JVPBA;CAN9NDA3;6B5BHD5T',
      statusContent: 'dot',
      hash: 'BHEJ62ZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BHEJ62ZP'
    },
    {
      status: 810,
      sku: 'PM0075572',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281035',
      rtl_batch_array: '6281035',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'PLYH6K59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLYH6K59'
    },
    {
      status: 810,
      sku: 'PM0075573',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281036',
      rtl_batch_array: '6281036',
      name_search: 'HYSPREZI',
      name: "Hydrangea serrata 'Preziosa'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.508,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '51N5EGS9;27GRLDCV;79H9DNXB;GY1VH3WK',
      statusContent: 'dot',
      hash: 'XLHV7NP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLHV7NP6'
    },
    {
      status: 810,
      sku: 'PM0075574',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281037',
      rtl_batch_array: '6281037',
      name_search: 'HYSPREZI',
      name: "Hydrangea serrata 'Preziosa'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '51N5EGS9;27GRLDCV;79H9DNXB;GY1VH3WK',
      statusContent: 'dot',
      hash: 'EPBEWV52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPBEWV52'
    },
    {
      status: 810,
      sku: 'PM0075575',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281038',
      rtl_batch_array: '6281038',
      name_search: 'HYSEMIOL',
      name: 'Hydrangea Semiola',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BA1RZS9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA1RZS9G'
    },
    {
      status: 910,
      sku: 'PM0083232',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275058',
      rtl_batch_array: '6275058',
      name_search: 'SCVETOWE',
      name: "Sciadopitys verticillata 'Eiffel Tower'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 25.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VBX6NTCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBX6NTCH'
    },
    {
      status: 210,
      sku: 'PM0002651',
      core_name: 'Plant',
      btch_manufacturer: 89,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193887',
      rtl_batch_array: '6193887',
      name_search: 'GAMUCRON',
      name: 'Gaultheria mucronata',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 50000,
      chnn_stock_retail: 50000,
      sppl_order_minimum: 3,
      sppl_prcp: 2.794,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H9KW94HE;SWDVTV9W;Y85XA82Y;SHAPZ8VR;G6RSPXJK;K1WJPPXL;VATW737P;K3KGJL1L;P9SCLHXS;NC3E7BJC;7RA3D8J7;YKTATAD1;H6BALCYW;48SVYS77;BT7L71LX;1P4T71N1;4CEB2KEK',
      statusContent: 'dot',
      hash: '1G5NZLSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G5NZLSS'
    },
    {
      status: 210,
      sku: 'PM0000904',
      core_name: 'Plant',
      sppl_ean: '8720626361379',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5414778',
      rtl_batch_array: '5414778',
      name_search: 'PEOFLAMI',
      name: "Pennisetum orientale 'Flamingo'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2G1D4V1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2G1D4V1J'
    },
    {
      status: 210,
      sku: 'PM0025992',
      core_name: 'Plant',
      btch_manufacturer: 89,
      rtl_batch_array_total: 1,
      btch_active_retail: '6193862',
      rtl_batch_array: '6193862',
      name_search: 'GAMMANNE',
      name: 'Gaultheria mucronata (mnl)',
      sppl_size_code: '010015P10',
      rng_range_identifier: 'H010015P10',
      rng_range_description: 'H10 cm 15 cm P10',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BNC7NCL2;DP197LEL;GX7W7T7P;DXS8V8AR;VELZVPLA;V4998E8W;JGBXC5TV;B18K25RN;1RHDJRSS;EAG86HE3;X8EZY3HG;BWKD9BT4;CC9KYV5R;7BP2HCB2;7SN9G3SA;8WZC161D;BHEBKTY2;6BXSN4JZ;NN34JSTW;XSN6H7SK;WG41KAZR;JRSXKJZG',
      statusContent: 'dot',
      hash: '2J1WYXE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2J1WYXE9'
    },
    {
      status: 210,
      sku: 'PM0001937',
      core_name: 'Plant',
      sppl_ean: '8720626331556',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '4650337',
      rtl_batch_array: '4650337, 6336323',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 1828,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: '5BC9LWSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BC9LWSH'
    },
    {
      status: 210,
      sku: 'PM0011083',
      core_name: 'Plant',
      sppl_ean: '8720349483310',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905604',
      rtl_batch_array: '5905604',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 462,
      chnn_stock_retail: 462,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: '88ZZY2GR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88ZZY2GR'
    },
    {
      status: 210,
      sku: 'PM0016100',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288317',
      rtl_batch_array: '6288317',
      name_search: 'TRHIRTA',
      name: 'Tricyrtis hirta',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 6894,
      chnn_stock_retail: 6894,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6X1LRYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6X1LRYC'
    },
    {
      status: 210,
      sku: 'PM0019085',
      core_name: 'Plant',
      sppl_ean: '8720626312944',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4655181',
      rtl_batch_array: '4655181',
      name_search: 'MISRCLOU',
      name: 'Miscanthus sinensis Red Cloud',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 481,
      chnn_stock_retail: 481,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB4Z5AGB',
      imageBatch: 'CC65P5BS',
      statusContent: 'dot',
      hash: 'AZEJ91P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZEJ91P4'
    },
    {
      status: 210,
      sku: 'PM0012336',
      core_name: 'Plant',
      sppl_ean: '8720353015286',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '5479152',
      rtl_batch_array: '5479152',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D5S9SLZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5S9SLZP'
    },
    {
      status: 210,
      sku: 'PM0020489',
      core_name: 'Plant',
      sppl_ean: '8720349494538',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '5905586',
      rtl_batch_array: '5905586',
      name_search: 'MOCMOORF',
      name: "Molinia caerulea 'Moorflamme'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 710,
      chnn_stock_retail: 710,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CVVT2CE9;YK519G92;H1DRK87A',
      statusContent: 'dot',
      hash: 'DD7AV9KL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD7AV9KL'
    },
    {
      status: 910,
      sku: 'PM0083233',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6275059',
      rtl_batch_array: '6275059',
      name_search: 'SCVETOWE',
      name: "Sciadopitys verticillata 'Eiffel Tower'",
      sppl_size_code: '100120KL',
      rng_range_identifier: 'H100120RB',
      rng_range_description: 'H100 cm 120 cm Root ball',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 30.267,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZKLC4E29',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKLC4E29'
    },
    {
      status: 210,
      sku: 'PM0009087',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 3,
      btch_active_retail: '6270553',
      rtl_batch_array: '6281851, 6378446, 6270553',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 509,
      chnn_stock_retail: 1848,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E67RP136',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E67RP136'
    },
    {
      status: 910,
      sku: 'PM0083234',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'H040050RB',
      rng_range_description: 'H40 cm 50 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'VTJ8WZ6N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VTJ8WZ6N'
    },
    {
      status: 210,
      sku: 'PM0013166',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310260',
      rtl_batch_array: '6310260',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3200,
      chnn_stock_retail: 3200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'HDJLPVSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDJLPVSS'
    },
    {
      status: 210,
      sku: 'PM0025568',
      core_name: 'Plant',
      sppl_ean: '8720626341951',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '5964234',
      rtl_batch_array: '5964234, 5226379, 6234652, 5382000',
      name_search: 'COSROSEA',
      name: "Cortaderia selloana 'Rosea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2422,
      chnn_stock_retail: 9562,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25X4TCHJ;27YWYHL2;Y7GGS9RN;A1CBSRN5;82XSDGPX;G2Y373YL;WG33R43S;H1AWYZBV',
      statusContent: 'dot',
      hash: 'HZZ4EGKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZZ4EGKY'
    },
    {
      status: 210,
      sku: 'PM0023955',
      core_name: 'Plant',
      sppl_ean: '8720349416066',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '5431741',
      rtl_batch_array: '5431741',
      name_search: 'GAJBELLS',
      name: 'Gaultheria Jingle Bells',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 5348,
      chnn_stock_retail: 5348,
      sppl_prcp: 2.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'YZR93PEX',
      statusContent: 'dot',
      hash: 'JTY5HSEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTY5HSEX'
    },
    {
      status: 910,
      sku: 'PM0083235',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'NRC65SGA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NRC65SGA'
    },
    {
      status: 210,
      sku: 'PM0022495',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 8,
      btch_active_retail: '6340061',
      rtl_batch_array:
        '6147179, 6308686, 6269836, 6350624, 6340061, 6260117, 6210918, 6220229',
      name_search: 'LAADBLUE',
      name: "Lavandula angustifolia 'Dwarf Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3496,
      chnn_stock_retail: 41389,
      sppl_order_minimum: 3,
      sppl_prcp: 0.517,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1H3ESPR;X1DY9NER;13R414KC;S6ZPVWEE;DN6SPV9W;6H75BN8S',
      statusContent: 'dot',
      hash: 'N1N7KNTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1N7KNTZ'
    },
    {
      status: 210,
      sku: 'PM0026015',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333269',
      rtl_batch_array: '5866426, 6202828, 6333269',
      name_search: 'ISFLUVIA',
      name: 'Isotoma fluviatilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2013,
      chnn_stock_retail: 2258,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB7VVEXN;BRZ5HVPJ;TPZPDE1R;DLYCZYPT;6XLYZ7K1;Z6KV3HAD',
      statusContent: 'dot',
      hash: 'NKV9XGRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKV9XGRX'
    },
    {
      status: 210,
      sku: 'PM0083236',
      core_name: 'Plant',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '6341909',
      rtl_batch_array: '6341909, 6313202',
      name_search: 'LAAASNOW',
      name: "Lavandula angustifolia 'Arctic Snow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 553,
      chnn_stock_retail: 853,
      sppl_prcp: 1.566,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T6DXCDPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6DXCDPL'
    },
    {
      status: 910,
      sku: 'PM0083237',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JUCSUECI',
      name: "Juniperus communis 'Suecica'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      statusContent: 'dot',
      hash: 'K98GYBRL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K98GYBRL'
    },
    {
      status: 210,
      sku: 'PM0083238',
      core_name: 'Plant',
      sppl_ean: '8720664570658',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449482',
      rtl_batch_array: '5449482',
      name_search: 'LEKRRUBY',
      name: "Leucothoe keiskei 'Royal Ruby'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DELW2ZC;9Z8CRP4D;JZ7LKYEC;28Z6ES5R;VT2R8L9A;5VW35V25;4W719N2W;DB9TBNA6;VAD78P86',
      imageBatch: '9SG5647J',
      statusContent: 'dot',
      hash: '1W1AKATE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1W1AKATE'
    },
    {
      status: 210,
      sku: 'PM0009268',
      core_name: 'Plant',
      sppl_ean: '8720626312876',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702567',
      rtl_batch_array: '4702567',
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E7VK3LN;DDBGZZBL;RGW28J9J;DSCH93GV;7XNJSA12;VW9TY6J4;2KD13C5X;ST5LA5JB;VCJDWHKT;67YR1HE5;YP168CCT;NYHA6TZT;2HHZ78SB;5X8SL82N;ATGY8842;Z7PLP1S1;8JVZEBCA;LXJVKK55;5RR83H12;9Z1YAEDY;WN8K8NXK',
      imageBatch: 'RT54W69W',
      statusContent: 'dot',
      hash: 'Y4R3PNC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4R3PNC6'
    },
    {
      status: 910,
      sku: 'PM0083239',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287225',
      rtl_batch_array: '6287225',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 43.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'V46LYZLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V46LYZLN'
    },
    {
      status: 910,
      sku: 'PM0083240',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287227',
      rtl_batch_array: '6287227',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 40.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: 'D45PRCZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D45PRCZ1'
    },
    {
      status: 910,
      sku: 'PM0083241',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287228',
      rtl_batch_array: '6287228',
      name_search: 'ENCAMPAN',
      name: 'Enkianthus campanulatus',
      sppl_size_code: '150175C35',
      rng_range_identifier: 'H150175C35',
      rng_range_description: 'H150 cm 175 cm C35',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 76.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZSCK8KE;KNE9JLCP;57VZG2NK;7WNYZZE7;Y95D52CN;K38VWHZ6',
      statusContent: 'dot',
      hash: '8XSPV835',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XSPV835'
    },
    {
      status: 810,
      sku: 'PM0083242',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287231',
      rtl_batch_array: '6287231',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'KDBV9ZKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDBV9ZKC'
    },
    {
      status: 990,
      sku: 'PM0083243',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287233',
      rtl_batch_array: '6287233',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 0,
      chnn_stock_retail: 0,
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'HYY3V5V2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HYY3V5V2'
    },
    {
      status: 910,
      sku: 'PM0083244',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287235',
      rtl_batch_array: '6287235',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: '150175C45',
      rng_range_identifier: 'H150175C45',
      rng_range_description: 'H150 cm 175 cm C45',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 94.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'YGKBP3SH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGKBP3SH'
    },
    {
      status: 810,
      sku: 'PM0083245',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287236',
      rtl_batch_array: '6287236',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '050080C12',
      rng_range_identifier: 'H050080C12',
      rng_range_description: 'H50 cm 80 cm C12',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'V2KXYYH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2KXYYH7'
    },
    {
      status: 910,
      sku: 'PM0083246',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287237',
      rtl_batch_array: '6287237',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 63.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'ZR43AHJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR43AHJS'
    },
    {
      status: 810,
      sku: 'PM0083247',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287238',
      rtl_batch_array: '6287238',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '150200C20',
      rng_range_identifier: 'H150200C20',
      rng_range_description: 'H150 cm 200 cm C20',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 31.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: '9WGVZ2KJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WGVZ2KJ'
    },
    {
      status: 910,
      sku: 'PM0083248',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287239',
      rtl_batch_array: '6287239',
      name_search: 'EUPLANIP',
      name: 'Euonymus planipes',
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 64.909,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '71EKXBJ3',
      statusContent: 'dot',
      hash: '4PP999NG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4PP999NG'
    },
    {
      status: 910,
      sku: 'PM0083249',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287242',
      rtl_batch_array: '6287242',
      name_search: 'FASASPLE',
      name: "Fagus sylvatica 'Aspleniifolia'",
      sppl_size_code: '175200C45',
      rng_range_identifier: 'H175200C45',
      rng_range_description: 'H175 cm 200 cm C45',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 100,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CW1B8SDT;GS1PG7KT',
      statusContent: 'dot',
      hash: 'KTP959TC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTP959TC'
    },
    {
      status: 910,
      sku: 'PM0083250',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287248',
      rtl_batch_array: '6287248',
      name_search: 'FASDAWYC',
      name: "Fagus sylvatica 'Dawyck'",
      sppl_size_code: '175200C30',
      rng_range_identifier: 'H175200C30',
      rng_range_description: 'H175 cm 200 cm C30',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 102.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ELR1N3DP',
      statusContent: 'dot',
      hash: 'VSSJ3XVE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSSJ3XVE'
    },
    {
      status: 910,
      sku: 'PM0083251',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287249',
      rtl_batch_array: '6287249',
      name_search: 'FASDPURP',
      name: "Fagus sylvatica 'Dawyck Purple'",
      sppl_size_code: '175200C30',
      rng_range_identifier: 'H175200C30',
      rng_range_description: 'H175 cm 200 cm C30',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 102.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZKLZE76Z;AZ76DW2T',
      statusContent: 'dot',
      hash: 'R3887RPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R3887RPG'
    },
    {
      status: 810,
      sku: 'PM0083252',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287251',
      rtl_batch_array: '6287251',
      name_search: 'FOMAJOR',
      name: 'Fothergilla major',
      sppl_size_code: '040060C12',
      rng_range_identifier: 'H040060C12',
      rng_range_description: 'H40 cm 60 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 27.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N46NLV3Z;77E6K2RB;4X4CGH67;5G4EARD4;C42ZL9BE;PCB852GY;K5BZZVD1',
      statusContent: 'dot',
      hash: '8EWCXTC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EWCXTC2'
    },
    {
      status: 910,
      sku: 'PM0083253',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287252',
      rtl_batch_array: '6287252',
      name_search: 'FOMAJOR',
      name: 'Fothergilla major',
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 72.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N46NLV3Z;77E6K2RB;4X4CGH67;5G4EARD4;C42ZL9BE;PCB852GY;K5BZZVD1',
      statusContent: 'dot',
      hash: 'BY24WVZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY24WVZV'
    },
    {
      status: 810,
      sku: 'PM0083254',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287253',
      rtl_batch_array: '6287253',
      name_search: 'FRAALNUS',
      name: 'Frangula alnus',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 31.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W6LZP94;WNG5165T;PVE56JTL;NY6S2ASZ',
      statusContent: 'dot',
      hash: 'DLVE3KHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLVE3KHT'
    },
    {
      status: 910,
      sku: 'PM0083255',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287255',
      rtl_batch_array: '6287255',
      name_search: 'GLTINERM',
      name: 'Gleditsia triacanthos inermis',
      sppl_size_code: '150175C90',
      rng_range_identifier: 'H150175C90',
      rng_range_description: 'H150 cm 175 cm C90',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 177.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JKN8V11Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKN8V11Z'
    },
    {
      status: 910,
      sku: 'PM0083256',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287256',
      rtl_batch_array: '6287256',
      name_search: 'GLTSUNBU',
      name: "Gleditsia triacanthos 'Sunburst'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 66.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSPHLSDZ;K2GZTXTE;2P36HKAH',
      statusContent: 'dot',
      hash: '7CJVRR45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CJVRR45'
    },
    {
      status: 810,
      sku: 'PM0083257',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287257',
      rtl_batch_array: '6287257',
      name_search: 'GLTSUNBU',
      name: "Gleditsia triacanthos 'Sunburst'",
      sppl_size_code: '150175C90',
      rng_range_identifier: 'H150175C90',
      rng_range_description: 'H150 cm 175 cm C90',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 273.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KSPHLSDZ;K2GZTXTE;2P36HKAH',
      statusContent: 'dot',
      hash: 'X5PKT41P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5PKT41P'
    },
    {
      status: 910,
      sku: 'PM0083258',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287259',
      rtl_batch_array: '6287259',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 82.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'PG42Z1WS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PG42Z1WS'
    },
    {
      status: 910,
      sku: 'PM0083259',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287260',
      rtl_batch_array: '6287260',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 33.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'SVSK2CYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVSK2CYY'
    },
    {
      status: 910,
      sku: 'PM0083260',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287263',
      rtl_batch_array: '6287263',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 82.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'EDEELNTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDEELNTL'
    },
    {
      status: 910,
      sku: 'PM0083261',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287265',
      rtl_batch_array: '6287265',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 33.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'GXZYEYRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXZYEYRV'
    },
    {
      status: 910,
      sku: 'PM0083262',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287267',
      rtl_batch_array: '6287267',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 67.109,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: '6L9CTTBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L9CTTBC'
    },
    {
      status: 910,
      sku: 'PM0083263',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287268',
      rtl_batch_array: '6287268',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '200250C80',
      rng_range_identifier: 'H200250C80',
      rng_range_description: 'H200 cm 250 cm C80',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 200,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'B1YPKWTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1YPKWTD'
    },
    {
      status: 910,
      sku: 'PM0083264',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287269',
      rtl_batch_array: '6287269',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 263.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: '7NW86P25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7NW86P25'
    },
    {
      status: 910,
      sku: 'PM0083265',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287270',
      rtl_batch_array: '6287270',
      name_search: 'HISBCHIF',
      name: 'Hibiscus syriacus Blue Chiffon',
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 21.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NC4YK11W;PZDJVYPW;X678NVVX;TATSGP6K;2SA72EHX;BZWWSDG8;KJZADSD8;SCNBG8ZP;WBSK4YX7;CNL13E94;9NNT6D55',
      statusContent: 'dot',
      hash: 'CPAVB3KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPAVB3KA'
    },
    {
      status: 910,
      sku: 'PM0083266',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287271',
      rtl_batch_array: '6287271',
      name_search: 'HISLCHIF',
      name: 'Hibiscus syriacus Lavender Chiffon',
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 21.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7G8L6A8R;767X5JR5;BKL6R16L;SP5W9538;GJR2AELZ;6JELNAWZ;7EKCPB35;GBBYWKAZ;JBTD8VJL;67KSX14V;7XXE259W',
      statusContent: 'dot',
      hash: 'TR76TGBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TR76TGBR'
    },
    {
      status: 810,
      sku: 'PM0083267',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287272',
      rtl_batch_array: '6287272',
      name_search: 'HISMARIN',
      name: "Hibiscus syriacus 'Marina'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 25.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69LD5BA3;4KY15DD1;J439RCSN;4WL845Y7;KCL3SGY8;8ARYR5SP;2GE451DS;945JC67P',
      statusContent: 'dot',
      hash: 'A92G5XXX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A92G5XXX'
    },
    {
      status: 910,
      sku: 'PM0083268',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287274',
      rtl_batch_array: '6287274',
      name_search: 'HISOBLEU',
      name: "Hibiscus syriacus 'Oiseau Bleu'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 55.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLS6B2VD;T9G74VLT;G886HN24;E3ZTJG2L;7JR472KD',
      statusContent: 'dot',
      hash: '776TLXGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '776TLXGG'
    },
    {
      status: 910,
      sku: 'PM0083269',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287275',
      rtl_batch_array: '6287275',
      name_search: 'HISRHEAR',
      name: "Hibiscus syriacus 'Red Heart'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 25.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ED8W3VK6;HZ16SZ77;GLPYWH8E;G38D2T2L;D5AN7SDA;GTKGW3LE;81SGBESH;9J9T46BE',
      statusContent: 'dot',
      hash: 'X8CSZ2J9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8CSZ2J9'
    },
    {
      status: 910,
      sku: 'PM0083270',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HISSCHIF',
      name: 'Hibiscus syriacus Starburst Chiffon',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      imageCore: '537T9LZ9;26DNW4BD',
      statusContent: 'dot',
      hash: 'ZR7BXLHK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZR7BXLHK'
    },
    {
      status: 910,
      sku: 'PM0083271',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287278',
      rtl_batch_array: '6287278',
      name_search: 'HISTALBU',
      name: "Hibiscus syriacus 'Totus Albus'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 55.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B6NDJJ7T;L83JBHGS',
      statusContent: 'dot',
      hash: 'ZKE16BK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKE16BK3'
    },
    {
      status: 910,
      sku: 'PM0083272',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287279',
      rtl_batch_array: '6287279',
      name_search: 'HISWSMIT',
      name: "Hibiscus syriacus 'William R. Smith'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 25.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7HY3YS61;PRYTR4GY;C632XJNA',
      statusContent: 'dot',
      hash: '19A76VBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19A76VBV'
    },
    {
      status: 910,
      sku: 'PM0083273',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287280',
      rtl_batch_array: '6287280',
      name_search: 'HISWOODB',
      name: "Hibiscus syriacus 'Woodbridge'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 25.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DGW792X2;HC6HX8DW;KC77S6XL;JXN1NRD2;8G9R2V99;21SECRWD;LCHX2V18;52Z5T3TJ;SYETT74P',
      statusContent: 'dot',
      hash: '9ZVKX1W8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZVKX1W8'
    },
    {
      status: 910,
      sku: 'PM0083274',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287282',
      rtl_batch_array: '6287282',
      name_search: 'HISWOODB',
      name: "Hibiscus syriacus 'Woodbridge'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 55.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DGW792X2;HC6HX8DW;KC77S6XL;JXN1NRD2;8G9R2V99;21SECRWD;LCHX2V18;52Z5T3TJ;SYETT74P',
      statusContent: 'dot',
      hash: 'LDN5CV2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDN5CV2L'
    },
    {
      status: 210,
      sku: 'PM0083275',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287283',
      rtl_batch_array: '6287283',
      name_search: 'HYAHSNOW',
      name: "Hydrangea arborescens 'Hills of Snow'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 2.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '116NV8BZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '116NV8BZ'
    },
    {
      status: 810,
      sku: 'PM0083276',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287285',
      rtl_batch_array: '6287285',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '060080C13',
      rng_range_identifier: 'H060080C1.3',
      rng_range_description: 'H60 cm 80 cm C1.3',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 20.396,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: 'VBBZB2R2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBBZB2R2'
    },
    {
      status: 810,
      sku: 'PM0083277',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287289',
      rtl_batch_array: '6287289',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 21.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'S436CN9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S436CN9A'
    },
    {
      status: 910,
      sku: 'PM0083278',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287292',
      rtl_batch_array: '6287292',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 161.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: '81N6L2HR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81N6L2HR'
    },
    {
      status: 910,
      sku: 'PM0083279',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287294',
      rtl_batch_array: '6287294',
      name_search: 'ACCAPILL',
      name: 'Acer capillipes',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 170,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5W9RGEZ',
      statusContent: 'dot',
      hash: 'EYE4WT2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYE4WT2G'
    },
    {
      status: 910,
      sku: 'PM0083280',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287295',
      rtl_batch_array: '6287295',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 82.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'ZE32AA68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZE32AA68'
    },
    {
      status: 910,
      sku: 'PM0083281',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287296',
      rtl_batch_array: '6287296',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '125150C25',
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 67.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'TX23WTNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TX23WTNY'
    },
    {
      status: 910,
      sku: 'PM0083282',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287297',
      rtl_batch_array: '6287297',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '200250C50',
      rng_range_identifier: 'H200250C50',
      rng_range_description: 'H200 cm 250 cm C50',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 255,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'R1J1XTG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1J1XTG3'
    },
    {
      status: 910,
      sku: 'PM0083283',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287298',
      rtl_batch_array: '6287298',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 88.909,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'XT4367C7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XT4367C7'
    },
    {
      status: 910,
      sku: 'PM0083284',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287300',
      rtl_batch_array: '6287300',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 73.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '84DCJ45G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84DCJ45G'
    },
    {
      status: 910,
      sku: 'PM0083285',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287301',
      rtl_batch_array: '6287301',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '175200C35',
      rng_range_identifier: 'H175200C35',
      rng_range_description: 'H175 cm 200 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 73.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '7N3GC4KW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N3GC4KW'
    },
    {
      status: 910,
      sku: 'PM0083286',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287302',
      rtl_batch_array: '6287302',
      name_search: 'ACPALMAT',
      name: 'Acer palmatum',
      sppl_size_code: '175200C45',
      rng_range_identifier: 'H175200C45',
      rng_range_description: 'H175 cm 200 cm C45',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 172,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X7HYJ2GY;CYEN7A97;NWNENDZ1;W2V4CVNV;TYLRRB5W;DC5PRV31',
      statusContent: 'dot',
      hash: '1RSWXXED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RSWXXED'
    },
    {
      status: 810,
      sku: 'PM0083287',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287303',
      rtl_batch_array: '6287303',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 30.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'JKSEWL76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKSEWL76'
    },
    {
      status: 210,
      sku: 'PM0010313',
      core_name: 'Plant',
      sppl_ean: '8720664562622',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '5416120',
      rtl_batch_array: '5416120',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'ZVXHPTJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVXHPTJY'
    },
    {
      status: 210,
      sku: 'PM0004840',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6209192',
      rtl_batch_array: '6209192, 6310334',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2293,
      chnn_stock_retail: 3968,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'JGK6B7KL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGK6B7KL'
    },
    {
      status: 210,
      sku: 'PM0027603',
      core_name: 'Plant',
      btch_manufacturer: 2009,
      rtl_batch_array_total: 2,
      btch_active_retail: '6346213',
      rtl_batch_array: '6346213, 5682155',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '150175KL',
      rng_range_identifier: 'H150175RB',
      rng_range_description: 'H150 cm 175 cm Root ball',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5001,
      sppl_prcp: 13.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '94L3BZ2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94L3BZ2T'
    },
    {
      status: 810,
      sku: 'PM0075576',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281039',
      rtl_batch_array: '6281039',
      name_search: 'HYSEMIOL',
      name: 'Hydrangea Semiola',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y3A9GY7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y3A9GY7C'
    },
    {
      status: 810,
      sku: 'PM0075589',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281053',
      rtl_batch_array: '6281053',
      name_search: 'LOTELLMA',
      name: 'Lonicera tellmanniana',
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ECJ3G;JGY84VDN;2JNBTDGT;WGHTB9ZK;LXY88137;9487HK3K',
      statusContent: 'dot',
      hash: 'RLEX1XAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLEX1XAE'
    },
    {
      status: 810,
      sku: 'PM0075578',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281041',
      rtl_batch_array: '6281041',
      name_search: 'ILCSTOKE',
      name: "Ilex crenata 'Stokes'",
      sppl_size_code: '070COBOL',
      rng_range_identifier: 'BALL\u00d8070H070070',
      rng_range_description: 'Ball \u00d8 70 cm H70 cm 70 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 75.019,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNEPZAHT;EJDK3DH6;BN3GY5LA;YB3BLW6B;X9CG7SZH;TH7BPWEJ;629YYVZD;G5W33Y56;HS6SYEYK;D5WTT8PW;ZC3XYK98;CG5YS5V8;JEBKX5DX;NVNS2V92',
      statusContent: 'dot',
      hash: '28YBT1CK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28YBT1CK'
    },
    {
      status: 810,
      sku: 'PM0075591',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281055',
      rtl_batch_array: '6281055',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 37.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'N92JEYWR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N92JEYWR'
    },
    {
      status: 810,
      sku: 'PM0075579',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281042',
      rtl_batch_array: '6281042',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: '2ZYB594N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZYB594N'
    },
    {
      status: 810,
      sku: 'PM0075580',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281043',
      rtl_batch_array: '6281043',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'RCP2SXZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCP2SXZ1'
    },
    {
      status: 810,
      sku: 'PM0075581',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281044',
      rtl_batch_array: '6281044',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'S9SAD79R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9SAD79R'
    },
    {
      status: 810,
      sku: 'PM0075582',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281045',
      rtl_batch_array: '6281045',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 60.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'XR279BK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XR279BK9'
    },
    {
      status: 810,
      sku: 'PM0075583',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281046',
      rtl_batch_array: '6281046',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: '55BVX7E4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55BVX7E4'
    },
    {
      status: 810,
      sku: 'PM0075584',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281047',
      rtl_batch_array: '6281047',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '100125CO',
      rng_range_identifier: 'H100125',
      rng_range_description: 'H100 cm 125 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'S59CBGKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S59CBGKZ'
    },
    {
      status: 810,
      sku: 'PM0075590',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281054',
      rtl_batch_array: '6281054',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'PLS9TZE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLS9TZE9'
    },
    {
      status: 810,
      sku: 'PM0075587',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281051',
      rtl_batch_array: '6281051',
      name_search: 'LOPSEROT',
      name: "Lonicera periclymenum 'Serotina'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJPNZYE2;ZN4CAHN1;Z2YKXPSL;16P279GS;E6LWJWGT;48YZ64JZ;75J4REZ7;R6YWKD37;WAVNK8D2',
      statusContent: 'dot',
      hash: '6NS6HDRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NS6HDRJ'
    },
    {
      status: 810,
      sku: 'PM0075585',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281048',
      rtl_batch_array: '6281048',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.458,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'E8YBVSPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8YBVSPL'
    },
    {
      status: 810,
      sku: 'PM0075586',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281049',
      rtl_batch_array: '6281049',
      name_search: 'LISSSILH',
      name: "Liquidambar styraciflua 'Slender Silhouette'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 29.986,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZY59RA8E;A26DSD4L;SX1ZJLAR;RBVVKBB9;P276W8CW;ZGCL151N;VC7K97YR;4527VT3B;DL7T117H',
      statusContent: 'dot',
      hash: 'P45EG6RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P45EG6RB'
    },
    {
      status: 810,
      sku: 'PM0083288',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287304',
      rtl_batch_array: '6287304',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 35.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: '7PB8PNYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PB8PNYC'
    },
    {
      status: 810,
      sku: 'PM0075588',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281052',
      rtl_batch_array: '6281052',
      name_search: 'LOPURPUS',
      name: 'Lonicera purpusii',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TK1A95L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TK1A95L8'
    },
    {
      status: 910,
      sku: 'PM0083289',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287306',
      rtl_batch_array: '6287306',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '150175C35',
      rng_range_identifier: 'H150175C35',
      rng_range_description: 'H150 cm 175 cm C35',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 88.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'BYNXJY1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYNXJY1H'
    },
    {
      status: 910,
      sku: 'PM0083290',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287307',
      rtl_batch_array: '6287307',
      name_search: 'ACPAUREU',
      name: "Acer palmatum 'Aureum'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 81.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '221XHZLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '221XHZLD'
    },
    {
      status: 910,
      sku: 'PM0083291',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287308',
      rtl_batch_array: '6287308',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 39.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: '1LA2DZ35',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LA2DZ35'
    },
    {
      status: 210,
      sku: 'PM0008307',
      core_name: 'Plant',
      sppl_ean: '8720664699205',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702560',
      rtl_batch_array: '4702560',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 7.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '17B5XBHR',
      statusContent: 'dot',
      hash: '26N6425T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26N6425T'
    },
    {
      status: 210,
      sku: 'PM0027766',
      core_name: 'Plant',
      sppl_ean: '8720626360273',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364357',
      rtl_batch_array: '5364357',
      name_search: 'NEKCAT',
      name: "Nepeta 'Kit Cat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.769,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EC699YYS;STP14L9P;83STCVNN;AX4VWJWK;1B6ZZENW;KJ1X6EJN;9S1BJ9PW;WNWGJ9PH',
      statusContent: 'dot',
      hash: '4VRJALEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VRJALEB'
    },
    {
      status: 210,
      sku: 'PM0001067',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '6336470',
      rtl_batch_array: '6349073, 6207962, 6295538, 6336470, 6324929',
      name_search: 'ECPWSWAN',
      name: "Echinacea purpurea 'White Swan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1649,
      chnn_stock_retail: 19795,
      sppl_order_minimum: 3,
      sppl_prcp: 0.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S2AWBZCB;HG39KDT2;1XHA49R5;WJE22S97;1R7XEGKY;L2PT3L6J;XCC4293G;ZRP79VSE;78B7VR6Y;JC4C9V1W;ESDA8HWG;85ZSVL31;165RBY87;1ED1CWKC;5ZJVSL9E;CH5Y8W8C;7G45P7EN;H35LYHCY;PC724XYC;ZTT4K37B;5PZK33ZJ;638NZG6P;PPLGAK2B;C5SRYL21;AC8943T5;BLEJNK6L;8G1S87NG;1NPZYHNX;BB5BDN4E',
      statusContent: 'dot',
      hash: '74KYKE1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74KYKE1V'
    },
    {
      status: 910,
      sku: 'PM0073983',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: 'G44JPRDH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'G44JPRDH'
    },
    {
      status: 910,
      sku: 'PM0083292',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287310',
      rtl_batch_array: '6287310',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 92.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: '7Y314KAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y314KAK'
    },
    {
      status: 910,
      sku: 'PM0083293',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287313',
      rtl_batch_array: '6287313',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 64.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'WPY4V17X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPY4V17X'
    },
    {
      status: 210,
      sku: 'PM0023575',
      core_name: 'Plant',
      sppl_ean: '8720664686076',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4721748',
      rtl_batch_array: '4721748',
      name_search: 'COSMINIS',
      name: 'Cortaderia selloana Minisilverpampas',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 5.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '79XJEKHH',
      statusContent: 'dot',
      hash: '7EAEE8GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7EAEE8GE'
    },
    {
      status: 210,
      sku: 'PM0014745',
      core_name: 'Plant',
      sppl_ean: '8720664699373',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4702545',
      rtl_batch_array: '4702545',
      name_search: 'MISLZEBR',
      name: "Miscanthus sinensis 'Little Zebra'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 491,
      chnn_stock_retail: 491,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BAH38TT2;TDP9N3BL;GEC3B53L;NE7R6WX4;2AHZS4T1;EL6R5VD1;W65LCK79;1DBC5DWS;67CSE5S5;XSGL1AJS;L3DRCPG9;L8KKHVC4;T48CSEGE;RJX7NYN5;1TJGJKHR;E38CYGAD;G78WEZ1E;9DV7SS41;2PW7E9K7;94Z37G9N;CE5TD7DG;2E3EBBTE;DPB312PR;JT23LDD1;VBC6AEA5;APXLE99T',
      imageBatch: 'ZT8RGPCZ',
      statusContent: 'dot',
      hash: 'E8T2H9Y5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8T2H9Y5'
    },
    {
      status: 910,
      sku: 'PM0083294',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287314',
      rtl_batch_array: '6287314',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 88.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'WSW3633K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSW3633K'
    },
    {
      status: 210,
      sku: 'PM0012049',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '6267749',
      rtl_batch_array: '6267749',
      name_search: 'ARMONTAN',
      name: 'Arenaria montana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DXH4VGT4;W7VH9S3H;W5XST8D3;V8DEL2KN;7X17KLDT;CX5GL2N5;CA25WKTV;SR68ZN86;5TYVLS1R;WG9TJRTE;C6HHYLLV;44ASH59D;8K4WW2V9;8A6PBN4V;S24YZTRR',
      statusContent: 'dot',
      hash: 'KB416S5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KB416S5W'
    },
    {
      status: 210,
      sku: 'PM0018644',
      core_name: 'Plant',
      sppl_ean: '8720664686052',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4721747',
      rtl_batch_array: '4721747',
      name_search: 'COSMINIP',
      name: 'Cortaderia selloana Minipampas',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.247,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'JTV7PZ7S',
      statusContent: 'dot',
      hash: 'N51NCEE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N51NCEE3'
    },
    {
      status: 910,
      sku: 'PM0001667',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAAKFOER',
      name: "Calamagrostis acutiflora 'Karl Foerster'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        'D3BXVP5Y;H2E8DZJA;25B5WXTP;EAC6L1T3;XH1XWBV6;P5P3144X;V9R9XTB6;5YW2H7B1;TLXB3J82;KB2LHRZC;DSV3H7AK;SX737Z1K;6GG6ZL82;B1GSSDWC;YDZDL51X;XLEP2TEE;WP85XPVX;WS7R1593;VR8NA9NR;TNVB9SV4;Y75S471W;KAE88DXC;HNBCALNJ',
      statusContent: 'dot',
      hash: 'WRZ81JWG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WRZ81JWG'
    },
    {
      status: 910,
      sku: 'PM0083295',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287316',
      rtl_batch_array: '6287316',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 92.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: 'PTNZXWZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTNZXWZV'
    },
    {
      status: 910,
      sku: 'PM0083296',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287317',
      rtl_batch_array: '6287317',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 81.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: 'P27N87GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P27N87GG'
    },
    {
      status: 810,
      sku: 'PM0083297',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287318',
      rtl_batch_array: '6287318',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 35.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: '8NZ9PZAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NZ9PZAB'
    },
    {
      status: 910,
      sku: 'PM0083298',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287319',
      rtl_batch_array: '6287319',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 81.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'GN1PRY57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GN1PRY57'
    },
    {
      status: 910,
      sku: 'PM0083299',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287320',
      rtl_batch_array: '6287320',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '200250C80',
      rng_range_identifier: 'H200250C80',
      rng_range_description: 'H200 cm 250 cm C80',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 447.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'JZSKKEZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZSKKEZP'
    },
    {
      status: 810,
      sku: 'PM0083300',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287321',
      rtl_batch_array: '6287321',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 35.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'ZAHKXVXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAHKXVXC'
    },
    {
      status: 910,
      sku: 'PM0083301',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287322',
      rtl_batch_array: '6287322',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 87.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'G6Z5RHHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6Z5RHHS'
    },
    {
      status: 910,
      sku: 'PM0083302',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287323',
      rtl_batch_array: '6287323',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 127.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'E2RCW6GP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2RCW6GP'
    },
    {
      status: 910,
      sku: 'PM0083303',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287324',
      rtl_batch_array: '6287324',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '150175C35',
      rng_range_identifier: 'H150175C35',
      rng_range_description: 'H150 cm 175 cm C35',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 88.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: '1N7DNG7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1N7DNG7W'
    },
    {
      status: 910,
      sku: 'PM0083304',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287325',
      rtl_batch_array: '6287325',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 82.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'ZXLWYSV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXLWYSV4'
    },
    {
      status: 910,
      sku: 'PM0083305',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287328',
      rtl_batch_array: '6287328',
      name_search: 'ACPGLOBO',
      name: "Acer platanoides 'Globosum'",
      sppl_size_code: '200STC20',
      rng_range_identifier: 'STE200C20',
      rng_range_description: 'Stem 200 cm C20',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 56.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NT6LGYKL;LSZ3W6TW;K4Y3T3ZV;5NV757PG;1JHTVG4N;5YGSEVSD;PWSSDWRC;739AVVVL;291DEPXP;DL5G3BA9;V34WHSTC;WNLSY1E6',
      statusContent: 'dot',
      hash: 'T8XRH3J5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8XRH3J5'
    },
    {
      status: 910,
      sku: 'PM0083306',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287329',
      rtl_batch_array: '6287329',
      name_search: 'ACSJORDA',
      name: "Acer shirasawanum 'Jordan'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 103,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LW573T4T',
      statusContent: 'dot',
      hash: 'GWLB2SWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWLB2SWW'
    },
    {
      status: 810,
      sku: 'PM0083307',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287330',
      rtl_batch_array: '6287330',
      name_search: 'AEPARVIF',
      name: 'Aesculus parviflora',
      sppl_size_code: '060080C13',
      rng_range_identifier: 'H060080C1.3',
      rng_range_description: 'H60 cm 80 cm C1.3',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 25.196,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JP1C6VDT;9BVDB817;V6DNPEK1;C3Z5TRCS',
      statusContent: 'dot',
      hash: 'A3HVZTWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3HVZTWK'
    },
    {
      status: 910,
      sku: 'PM0083308',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287331',
      rtl_batch_array: '6287331',
      name_search: 'ALJOMBRE',
      name: 'Albizia julibrissin Ombrella',
      sppl_size_code: '175200C30',
      rng_range_identifier: 'H175200C30',
      rng_range_description: 'H175 cm 200 cm C30',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JHBCXJ;ZNPHTD5N;9NZJN8NP',
      statusContent: 'dot',
      hash: '22S6AV7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22S6AV7G'
    },
    {
      status: 910,
      sku: 'PM0083309',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287334',
      rtl_batch_array: '6287334',
      name_search: 'ALJSCHOC',
      name: "Albizia julibrissin 'Summer Chocolate'",
      sppl_size_code: '175200C50',
      rng_range_identifier: 'H175200C50',
      rng_range_description: 'H175 cm 200 cm C50',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 182.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '81KLXWEX;8R6LRWVR;2YBT1KW5;XLB7TLLD;K3ZYB766;Z4A9VVJ3',
      statusContent: 'dot',
      hash: 'R581KD33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R581KD33'
    },
    {
      status: 910,
      sku: 'PM0083310',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287335',
      rtl_batch_array: '6287335',
      name_search: 'ALGIMPER',
      name: "Alnus glutinosa 'Imperialis'",
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 186.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KBHS53RS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBHS53RS'
    },
    {
      status: 910,
      sku: 'PM0083311',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287336',
      rtl_batch_array: '6287336',
      name_search: 'AMBALLER',
      name: "Amelanchier 'Ballerina'",
      sppl_size_code: '200250C20',
      rng_range_identifier: 'H200250C20',
      rng_range_description: 'H200 cm 250 cm C20',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 69.915,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CP37E13A;LB6G53DH;SPBHKY7Z;BTRZ2T7P;ZJ2TX7YR',
      statusContent: 'dot',
      hash: 'JY3LPBLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY3LPBLE'
    },
    {
      status: 810,
      sku: 'PM0083312',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287337',
      rtl_batch_array: '6287337',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 13.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'KHZHX47J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHZHX47J'
    },
    {
      status: 810,
      sku: 'PM0083313',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287339',
      rtl_batch_array: '6287339',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '100150C12',
      rng_range_identifier: 'H100150C12',
      rng_range_description: 'H100 cm 150 cm C12',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_prcp: 19.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'LCBHYYCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCBHYYCL'
    },
    {
      status: 810,
      sku: 'PM0083314',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287340',
      rtl_batch_array: '6287340',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '125150C15',
      rng_range_identifier: 'H125150C15',
      rng_range_description: 'H125 cm 150 cm C15',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 60.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'K71B8GVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K71B8GVD'
    },
    {
      status: 810,
      sku: 'PM0083315',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287341',
      rtl_batch_array: '6287341',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '200225C20',
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      sppl_stock_available: 98,
      chnn_stock_retail: 98,
      sppl_prcp: 52.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '1WS69A4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WS69A4C'
    },
    {
      status: 210,
      sku: 'PM0083316',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287342',
      rtl_batch_array: '6287342',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '100125C25',
      rng_range_identifier: 'H100125C25',
      rng_range_description: 'H100 cm 125 cm C25',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 43.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'VY2DJNHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VY2DJNHC'
    },
    {
      status: 810,
      sku: 'PM0083317',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287344',
      rtl_batch_array: '6287344',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '150175C45',
      rng_range_identifier: 'H150175C45',
      rng_range_description: 'H150 cm 175 cm C45',
      sppl_stock_available: 94,
      chnn_stock_retail: 94,
      sppl_prcp: 102.267,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'AGB5SXSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGB5SXSR'
    },
    {
      status: 910,
      sku: 'PM0083318',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287346',
      rtl_batch_array: '6287346',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '200225C90',
      rng_range_identifier: 'H200225C90',
      rng_range_description: 'H200 cm 225 cm C90',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 203,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'PEHTX8P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEHTX8P4'
    },
    {
      status: 910,
      sku: 'PM0083319',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287349',
      rtl_batch_array: '6287349',
      name_search: 'CAFLORID',
      name: 'Calycanthus floridus',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 24.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1NJ6PDVV;4B7XVESS;7YK9E1N4',
      statusContent: 'dot',
      hash: 'WVR68CP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVR68CP1'
    },
    {
      status: 810,
      sku: 'PM0083320',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287350',
      rtl_batch_array: '6287350',
      name_search: 'CASASANQ',
      name: 'Camellia sasanqua',
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 60.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T68PTKC5;X9HL6RKS',
      statusContent: 'dot',
      hash: 'S2NLDC4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2NLDC4H'
    },
    {
      status: 910,
      sku: 'PM0083321',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287351',
      rtl_batch_array: '6287351',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '46GYT8XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46GYT8XE'
    },
    {
      status: 910,
      sku: 'PM0083322',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287352',
      rtl_batch_array: '6287352',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 190,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'KVS3P7EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVS3P7EZ'
    },
    {
      status: 910,
      sku: 'PM0083323',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABFASTI',
      name: "Carpinus betulus 'Fastigiata'",
      rng_range_identifier: 'SHI010012C35',
      rng_range_description: 'High stem 10 cm 12 cm C35',
      imageCore:
        'VDHBG2L8;HAKT6DRC;ECC8PW1G;GJY4JSJ5;HP7PB8BX;CYR64XGV;L16AKPC8;H19PZT71;HEW5JK5L;1NZDLL67;9EHC9PL7;THH3Y1L2;23W7E8NY',
      statusContent: 'dot',
      hash: '566981KV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '566981KV'
    },
    {
      status: 910,
      sku: 'PM0083324',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287354',
      rtl_batch_array: '6287354',
      name_search: 'CABAUREA',
      name: "Catalpa bignonioides 'Aurea'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 25.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DY7JRG83;GNND7P79;GP5THLPD',
      statusContent: 'dot',
      hash: 'DTCETK3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTCETK3W'
    },
    {
      status: 910,
      sku: 'PM0083325',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287357',
      rtl_batch_array: '6287357',
      name_search: 'CEJAPONI',
      name: 'Cercidiphyllum japonicum',
      sppl_size_code: '150175C12',
      rng_range_identifier: 'H150175C12',
      rng_range_description: 'H150 cm 175 cm C12',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 23,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8DB615ZY;Z9GNPWK9;LCZBXKC8',
      statusContent: 'dot',
      hash: 'YHW3BGY1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YHW3BGY1'
    },
    {
      status: 910,
      sku: 'PM0083326',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287358',
      rtl_batch_array: '6287358',
      name_search: 'CECFPANS',
      name: "Cercis canadensis 'Forest Pansy'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 35.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KNT4R6R4;LNDTSNS8;615LS6TJ;A8E67JPB;DW5BCS4Y;A61HGYDY;9R6LH946;VH5BDVZ3;X45E6557;XX5KG32E;9A57T5XD',
      statusContent: 'dot',
      hash: 'V9R355JX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9R355JX'
    },
    {
      status: 910,
      sku: 'PM0083327',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287360',
      rtl_batch_array: '6287360',
      name_search: 'CECFPANS',
      name: "Cercis canadensis 'Forest Pansy'",
      sppl_size_code: '100125C18',
      rng_range_identifier: 'H100125C18',
      rng_range_description: 'H100 cm 125 cm C18',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 71.209,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KNT4R6R4;LNDTSNS8;615LS6TJ;A8E67JPB;DW5BCS4Y;A61HGYDY;9R6LH946;VH5BDVZ3;X45E6557;XX5KG32E;9A57T5XD',
      statusContent: 'dot',
      hash: '64JD5B43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64JD5B43'
    },
    {
      status: 810,
      sku: 'PM0083328',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287361',
      rtl_batch_array: '6287361',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      sppl_size_code: '125150C25',
      rng_range_identifier: 'H125150C25',
      rng_range_description: 'H125 cm 150 cm C25',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 52.613,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: '8ABZJWNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ABZJWNP'
    },
    {
      status: 910,
      sku: 'PM0083329',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287364',
      rtl_batch_array: '6287364',
      name_search: 'CHPRAECO',
      name: 'Chimonanthus praecox',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 24.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XCZPRWVD',
      statusContent: 'dot',
      hash: 'J6ZKVJVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6ZKVJVH'
    },
    {
      status: 210,
      sku: 'PM0083330',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287365',
      rtl_batch_array: '6287365',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'WKJNVX55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKJNVX55'
    },
    {
      status: 910,
      sku: 'PM0083331',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287366',
      rtl_batch_array: '6287366',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 13.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'VRKRJ2JV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRKRJ2JV'
    },
    {
      status: 210,
      sku: 'PM0083332',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287367',
      rtl_batch_array: '6287367',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 5,
      sppl_prcp: 5.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: 'AH7RXYEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AH7RXYEX'
    },
    {
      status: 910,
      sku: 'PM0083333',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287368',
      rtl_batch_array: '6287368',
      name_search: 'CHTSUNDA',
      name: 'Choisya ternata Sundance',
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 26.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V42YL5RC;JRCE449K;XYRHEPZN;HXDXG4H2;L9Z5895X;JYVD7ZN6;KKWV491Z;9XHSEVLD;59ZX6N6T;6EHZS8RS;BSE13AEH;T399A1TP;PNN3V7YY;R25ZGNYL;NT63J78C;SN359S4T;DE4A48GH;JCW58RHJ;GN1JY58L;A2NE21ED;TZZATBVK;ZK7BGDRG;JC8HHA2W;PSTA47GK',
      statusContent: 'dot',
      hash: '25CRYHS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25CRYHS4'
    },
    {
      status: 810,
      sku: 'PM0083334',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287369',
      rtl_batch_array: '6287369',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 7.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'DLW6ZRTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLW6ZRTC'
    },
    {
      status: 910,
      sku: 'PM0083335',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287370',
      rtl_batch_array: '6287370',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '040060C15',
      rng_range_identifier: 'H040060C15',
      rng_range_description: 'H40 cm 60 cm C15',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 17.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: '84JGB2RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84JGB2RB'
    },
    {
      status: 910,
      sku: 'PM0083336',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287371',
      rtl_batch_array: '6287371',
      name_search: 'CLKENTUK',
      name: 'Cladrastis kentukea',
      sppl_size_code: '200250C50',
      rng_range_identifier: 'H200250C50',
      rng_range_description: 'H200 cm 250 cm C50',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 127.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TAAJW2GC',
      statusContent: 'dot',
      hash: 'EXHX1VNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXHX1VNC'
    },
    {
      status: 910,
      sku: 'PM0083337',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287372',
      rtl_batch_array: '6287372',
      name_search: 'CLJACKMA',
      name: "Clematis 'Jackmanii'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_order_minimum: 3,
      sppl_prcp: 5.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WSPR3JDC;NDP77J4G;YCEDW2JB;PKKAAEB1;GTS3ZW5N;79GNYJYW;5WNHYCL8;H2YRSR9E',
      statusContent: 'dot',
      hash: 'XWTHNE1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWTHNE1J'
    },
    {
      status: 810,
      sku: 'PM0083338',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287373',
      rtl_batch_array: '6287373',
      name_search: 'CLVLYON',
      name: "Clematis 'Ville de Lyon'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_order_minimum: 3,
      sppl_prcp: 5.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWNV29KS;918ABGA1;GLHCXVK1;7LA48278;TRRL5B7P;W6RL2AW3;CH5BCNKG;XPN2391D;RB1BNNJE;WL9EX33H;XJH26RHD;YVY3RYKE;9S1N3Z1A;H1LS6LCL;J27E55DS;SDGAY5CZ;L795T6NB;A3GK9SW4;7XTC9EZT;V5EBLPAB;NXDGGTKJ;ZXJR3DPH;T3C5VKAC;AZZE81NA',
      statusContent: 'dot',
      hash: '9J8EKR8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J8EKR8J'
    },
    {
      status: 910,
      sku: 'PM0083339',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287374',
      rtl_batch_array: '6287374',
      name_search: 'CLETRICH',
      name: 'Clerodendrum trichotomum',
      sppl_size_code: '175200C35',
      rng_range_identifier: 'H175200C35',
      rng_range_description: 'H175 cm 200 cm C35',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 74.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DEHYXGRA;4YVC7JC5;E42VHS1D',
      statusContent: 'dot',
      hash: 'DE7TBC1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE7TBC1A'
    },
    {
      status: 910,
      sku: 'PM0083340',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287376',
      rtl_batch_array: '6287376',
      name_search: 'CLAPSPIR',
      name: "Clethra alnifolia 'Pink Spire'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 22.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HAXST442;LKKYWZYJ',
      statusContent: 'dot',
      hash: 'L593C9PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L593C9PA'
    },
    {
      status: 910,
      sku: 'PM0083341',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287378',
      rtl_batch_array: '6287378',
      name_search: 'COALTERN',
      name: 'Cornus alternifolia',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 190,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZWX2WE7',
      statusContent: 'dot',
      hash: 'V162G5K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V162G5K8'
    },
    {
      status: 910,
      sku: 'PM0083342',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287379',
      rtl_batch_array: '6287379',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 34.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'CXZV9BDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CXZV9BDN'
    },
    {
      status: 810,
      sku: 'PM0083343',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287381',
      rtl_batch_array: '6287381',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 27.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'LZHJZBNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZHJZBNS'
    },
    {
      status: 910,
      sku: 'PM0083344',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287382',
      rtl_batch_array: '6287382',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 160,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: '7J3E2HWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7J3E2HWD'
    },
    {
      status: 910,
      sku: 'PM0083345',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287383',
      rtl_batch_array: '6287383',
      name_search: 'COCVARIE',
      name: "Cornus controversa 'Variegata'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 41.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRHXAAP;VEK1D3NC;6WDTDNED;WGHLZ3D2;KL8RWG2A;WW5D6NVX;2C2XNTYL;N83SHYJJ;81Z2ZVRA;X5561DV4;2C6BAK81;71NWG3EX;4JSCJXVC;7ZBKNZ5Y',
      statusContent: 'dot',
      hash: 'NSBCCTP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSBCCTP1'
    },
    {
      status: 910,
      sku: 'PM0083346',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287384',
      rtl_batch_array: '6287384',
      name_search: 'COCVARIE',
      name: "Cornus controversa 'Variegata'",
      sppl_size_code: '200250C50',
      rng_range_identifier: 'H200250C50',
      rng_range_description: 'H200 cm 250 cm C50',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 172.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRHXAAP;VEK1D3NC;6WDTDNED;WGHLZ3D2;KL8RWG2A;WW5D6NVX;2C2XNTYL;N83SHYJJ;81Z2ZVRA;X5561DV4;2C6BAK81;71NWG3EX;4JSCJXVC;7ZBKNZ5Y',
      statusContent: 'dot',
      hash: 'CGPNXNB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGPNXNB9'
    },
    {
      status: 810,
      sku: 'PM0083347',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287385',
      rtl_batch_array: '6287385',
      name_search: 'COFCCHIE',
      name: "Cornus florida 'Cherokee Chief'",
      sppl_size_code: '060080C13',
      rng_range_identifier: 'H060080C1.3',
      rng_range_description: 'H60 cm 80 cm C1.3',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 33.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZW8K98K',
      statusContent: 'dot',
      hash: 'RK1JJCKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RK1JJCKN'
    },
    {
      status: 810,
      sku: 'PM0083348',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287386',
      rtl_batch_array: '6287386',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 34.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '2N23H6Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N23H6Z5'
    },
    {
      status: 810,
      sku: 'PM0083349',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287387',
      rtl_batch_array: '6287387',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 84.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: 'P543SH8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P543SH8N'
    },
    {
      status: 810,
      sku: 'PM0083350',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287388',
      rtl_batch_array: '6287388',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 26.379,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'DENZ3T25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DENZ3T25'
    },
    {
      status: 910,
      sku: 'PM0083351',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287389',
      rtl_batch_array: '6287389',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 82.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: '8HWEAJKV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HWEAJKV'
    },
    {
      status: 810,
      sku: 'PM0083352',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287390',
      rtl_batch_array: '6287390',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 84.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: '9XL5TGYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XL5TGYB'
    },
    {
      status: 810,
      sku: 'PM0083353',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287391',
      rtl_batch_array: '6287391',
      name_search: 'COKSATOM',
      name: "Cornus kousa 'Satomi'",
      sppl_size_code: '060100C12',
      rng_range_identifier: 'H060100C12',
      rng_range_description: 'H60 cm 100 cm C12',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 34.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46W63H9G;ZWPZR8X9;HCL32Y1H;WE7LXD8X',
      statusContent: 'dot',
      hash: 'KAWNY3SD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAWNY3SD'
    },
    {
      status: 910,
      sku: 'PM0083354',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287392',
      rtl_batch_array: '6287392',
      name_search: 'COKSATOM',
      name: "Cornus kousa 'Satomi'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 77.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46W63H9G;ZWPZR8X9;HCL32Y1H;WE7LXD8X',
      statusContent: 'dot',
      hash: 'ZRNGTP78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRNGTP78'
    },
    {
      status: 910,
      sku: 'PM0083355',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287393',
      rtl_batch_array: '6287393',
      name_search: 'COKWFONT',
      name: "Cornus kousa 'Weisse Fontaine'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 84.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVVS21AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVVS21AP'
    },
    {
      status: 810,
      sku: 'PM0083356',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287394',
      rtl_batch_array: '6287394',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 16.879,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: '5YDYCSAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YDYCSAT'
    },
    {
      status: 910,
      sku: 'PM0083357',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287396',
      rtl_batch_array: '6287396',
      name_search: 'COMAS',
      name: 'Cornus mas',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 204,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EA87LNCE;LKXPNLND;4JNZRVL8;C5H6H6T3;CGPTCW6W;7K1XX9L6;AB87X9AL;PXSYA1P4;4D8SB4B9;LLEND2RK;RA1NVVWB;JNZKEN1S;NVVX19SV',
      statusContent: 'dot',
      hash: 'C2YGALEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2YGALEG'
    },
    {
      status: 810,
      sku: 'PM0083358',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287397',
      rtl_batch_array: '6287397',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_prcp: 8.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'J2BP9RP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2BP9RP5'
    },
    {
      status: 810,
      sku: 'PM0083359',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287398',
      rtl_batch_array: '6287398',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 21.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'XL2PTS9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL2PTS9K'
    },
    {
      status: 810,
      sku: 'PM0083360',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287399',
      rtl_batch_array: '6287399',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 40.909,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'DHR8TCVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHR8TCVC'
    },
    {
      status: 810,
      sku: 'PM0083361',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287400',
      rtl_batch_array: '6287400',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '080100C6',
      rng_range_identifier: 'H080100C6',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 7.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'LC86A6NY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC86A6NY'
    },
    {
      status: 210,
      sku: 'PM0083362',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373854',
      rtl_batch_array: '6373854, 6287401',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4322,
      sppl_order_minimum: 3,
      sppl_prcp: 0.539,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: 'JYJWCD9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYJWCD9L'
    },
    {
      status: 810,
      sku: 'PM0083363',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287402',
      rtl_batch_array: '6287402',
      name_search: 'COPAUCIF',
      name: 'Corylopsis pauciflora',
      sppl_size_code: '050080C12',
      rng_range_identifier: 'H050080C12',
      rng_range_description: 'H50 cm 80 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 26.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LDGETZ35;4HWKVX7H;D9G19CA5;ZYCA4VNX;YKTAZCR3;C6GJZPXP;PK4P4993;RL7VSYRE;7A59BD77',
      statusContent: 'dot',
      hash: '7V5ZWN5V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7V5ZWN5V'
    },
    {
      status: 910,
      sku: 'PM0083364',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287403',
      rtl_batch_array: '6287403',
      name_search: 'COSPICAT',
      name: 'Corylopsis spicata',
      sppl_size_code: '060100C12',
      rng_range_identifier: 'H060100C12',
      rng_range_description: 'H60 cm 100 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 26.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5963NY1K;8K2SS2RD;93J2P8RE;6L1LBRRN;SDBDE4XY;417YHT3J;RNCSDWAE;1V1RDXCR',
      statusContent: 'dot',
      hash: 'SBHR3ZJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBHR3ZJD'
    },
    {
      status: 910,
      sku: 'PM0083365',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287404',
      rtl_batch_array: '6287404',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '150200C20',
      rng_range_identifier: 'H150200C20',
      rng_range_description: 'H150 cm 200 cm C20',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 31.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: 'RG6Y2VH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RG6Y2VH4'
    },
    {
      status: 910,
      sku: 'PM0083366',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287405',
      rtl_batch_array: '6287405',
      name_search: 'COAVELLA',
      name: 'Corylus avellana',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 167.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z387K1A4;WR6ZLYLL;TNT8C5XH;TCS1B26J;1H3ZJGK5;1K8WRR8G;HKNBVV9X;5VPWWVRA;6PHV3422;B8C5NS1R;HG3GA2L5;BVDVB3RT;XJPG55Z3;JLTPJ7NG;GZ7PVYK3;89KC2RCD;BZYXPK1V;XLRT4PSZ;Y85DDVAY;D9EVT6ZD;AYZAETGB;J6Y7E4DZ;9DWG6VWN;K9WWBX1R;RRN73EXA',
      statusContent: 'dot',
      hash: '73W3SDPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73W3SDPN'
    },
    {
      status: 810,
      sku: 'PM0083367',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287407',
      rtl_batch_array: '6287407',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 25.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'DJS8HLRR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJS8HLRR'
    },
    {
      status: 910,
      sku: 'PM0083368',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287409',
      rtl_batch_array: '6287409',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '200250C90',
      rng_range_identifier: 'H200250C90',
      rng_range_description: 'H200 cm 250 cm C90',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 171.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: 'TKGKT4PZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKGKT4PZ'
    },
    {
      status: 810,
      sku: 'PM0083369',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287410',
      rtl_batch_array: '6287410',
      name_search: 'COHORIZO',
      name: 'Cotoneaster horizontalis',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 42,
      chnn_stock_retail: 42,
      sppl_prcp: 2.992,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RN26JVPL;5CESVTCE;K3Y2NN5Y;5WNX55ZP;C141SGHZ;YAPRLEA7;5Y1EY2Z9;NCC5GB37;D9191KR2;8HV81Z6R;E7YPHET5',
      statusContent: 'dot',
      hash: 'TKDCV592',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKDCV592'
    },
    {
      status: 810,
      sku: 'PM0083370',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287411',
      rtl_batch_array: '6287411',
      name_search: 'CRMPSCAR',
      name: "Crataegus media 'Paul's Scarlet'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DTD27V2H;KDJXDRJN;RTX122ZK;S864AHRX',
      statusContent: 'dot',
      hash: '56V5A5SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56V5A5SW'
    },
    {
      status: 810,
      sku: 'PM0083371',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287412',
      rtl_batch_array: '6287412',
      name_search: 'CRMPSCAR',
      name: "Crataegus media 'Paul's Scarlet'",
      sppl_size_code: '100150C12',
      rng_range_identifier: 'H100150C12',
      rng_range_description: 'H100 cm 150 cm C12',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 25.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DTD27V2H;KDJXDRJN;RTX122ZK;S864AHRX',
      statusContent: 'dot',
      hash: 'RAS5EXSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAS5EXSY'
    },
    {
      status: 810,
      sku: 'PM0083372',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287413',
      rtl_batch_array: '6287413',
      name_search: 'CRMONOGY',
      name: 'Crataegus monogyna',
      sppl_size_code: '150200C20',
      rng_range_identifier: 'H150200C20',
      rng_range_description: 'H150 cm 200 cm C20',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 34.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7X5V7XXT;ANHGE997;K4DEHARD;C6PHERAK;VZ9JJJZJ;26BV6EP3;P3ZDLZ54;D7A2E2ZE;5E3V22HL;ZPTA1PSA;J6XXPZNR;7V3DZHH2;W496P117',
      statusContent: 'dot',
      hash: 'HV6AR1K2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HV6AR1K2'
    },
    {
      status: 910,
      sku: 'PM0083373',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287414',
      rtl_batch_array: '6287414',
      name_search: 'CYOBLONG',
      name: 'Cydonia oblonga',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 20.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KCNABE86;RTGR57Y3;6N6VNLZD;1DVHJ28G;5H9DJ293;JC1XKBTT;RS5T1SX5;4Y4D7LX1;2B5ZXBC4;C5VWC57H;C888DAJ9;AN4YGDVW;63RNW8D6',
      statusContent: 'dot',
      hash: '52NEBH9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52NEBH9T'
    },
    {
      status: 210,
      sku: 'PM0003553',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301805',
      rtl_batch_array: '6301805, 6339932',
      name_search: 'SAARGENT',
      name: 'Salvia argentea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 441,
      chnn_stock_retail: 1521,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C2DHCJRT;ZBY2LE1P;RR2EBKBR;SSJNRL21;EXNYWGEX;ZPZ85KVA;BGWK477N;DGV3SPWB;R4XRTPXV;B8B7ER6D;2ZEX468V',
      statusContent: 'dot',
      hash: '8NVEDBDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NVEDBDL'
    },
    {
      status: 810,
      sku: 'PM0075592',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281056',
      rtl_batch_array: '6281056',
      name_search: 'MAKOBUS',
      name: 'Magnolia kobus',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 40.267,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEYCL77H;K42181Z3;PZ1HBB9X',
      statusContent: 'dot',
      hash: 'NJHS8STB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJHS8STB'
    },
    {
      status: 910,
      sku: 'PM0083374',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287415',
      rtl_batch_array: '6287415',
      name_search: 'DAINVOLU',
      name: 'Davidia involucrata',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 75.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLRXRGKY',
      statusContent: 'dot',
      hash: 'B63XX1YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B63XX1YX'
    },
    {
      status: 810,
      sku: 'PM0083434',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287504',
      rtl_batch_array: '6287504',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '150175C70',
      rng_range_identifier: 'H150175C70',
      rng_range_description: 'H150 cm 175 cm C70',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 282.154,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'V2GH15XC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2GH15XC'
    },
    {
      status: 210,
      sku: 'PM0083435',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287506',
      rtl_batch_array: '6287506',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '040COBOL',
      rng_range_identifier: 'BALL\u00d8040H040040',
      rng_range_description: 'Ball \u00d8 40 cm H40 cm 40 cm',
      sppl_stock_available: 313,
      chnn_stock_retail: 313,
      sppl_prcp: 21.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '1K6GLJTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K6GLJTA'
    },
    {
      status: 810,
      sku: 'PM0075604',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281070',
      rtl_batch_array: '6281070',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: '6PG9LABH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PG9LABH'
    },
    {
      status: 810,
      sku: 'PM0075596',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281061',
      rtl_batch_array: '6281061',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.482,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'B86N5361',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B86N5361'
    },
    {
      status: 210,
      sku: 'PM0083375',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287416',
      rtl_batch_array: '6287416',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 3.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'SDTNAGNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDTNAGNG'
    },
    {
      status: 810,
      sku: 'PM0075595',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281060',
      rtl_batch_array: '6281060',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '4TS1R9GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4TS1R9GG'
    },
    {
      status: 910,
      sku: 'PM0083376',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287417',
      rtl_batch_array: '6287417',
      name_search: 'DIKAKI',
      name: 'Diospyros kaki',
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 43.109,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EDXZPW36',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDXZPW36'
    },
    {
      status: 810,
      sku: 'PM0083377',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6287418',
      rtl_batch_array: '6287418, 6385790',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 90,
      chnn_stock_retail: 840,
      sppl_prcp: 3.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'EALRGVHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EALRGVHA'
    },
    {
      status: 810,
      sku: 'PM0075597',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281062',
      rtl_batch_array: '6281062',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'GHB84XPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHB84XPP'
    },
    {
      status: 810,
      sku: 'PM0075598',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281063',
      rtl_batch_array: '6281063',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 36.075,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'S4K5E4T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4K5E4T6'
    },
    {
      status: 810,
      sku: 'PM0075599',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281064',
      rtl_batch_array: '6281064',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'K1LPGN1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1LPGN1V'
    },
    {
      status: 810,
      sku: 'PM0075600',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281065',
      rtl_batch_array: '6281065',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.813,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: 'XPS1NDNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPS1NDNV'
    },
    {
      status: 810,
      sku: 'PM0075601',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281066',
      rtl_batch_array: '6281066',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.431,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: '1JTEB96T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1JTEB96T'
    },
    {
      status: 810,
      sku: 'PM0075602',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281067',
      rtl_batch_array: '6281067',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'G782DDRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G782DDRV'
    },
    {
      status: 210,
      sku: 'PM0083378',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287420',
      rtl_batch_array: '6287420',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 13.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'KP9SGJ4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP9SGJ4R'
    },
    {
      status: 810,
      sku: 'PM0083379',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287422',
      rtl_batch_array: '6287422',
      name_search: 'PYCOMMUN',
      name: 'Pyrus communis',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 12.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NC3C5V33;C74CRBWY;SJRVJD92;GBV226L7;5RVSKP7R;BCN1AWSX;Y2V2KPN5;A745VL5E;69PE3GKP;4C25NLG6;DSE6DND4;1NP1YSL3;ENERP1XX;CJTC3GLH;HNP1WXSN;42YN8BW5;V23TWYE2;127GGS2V;GP842PNL;BZK7TN5T;2TZ11Y3L;XW4NWXBH;4ERRRNGR',
      statusContent: 'dot',
      hash: 'N7BXG4H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7BXG4H5'
    },
    {
      status: 810,
      sku: 'PM0083380',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287423',
      rtl_batch_array: '6287423',
      name_search: 'PYCOMMUN',
      name: 'Pyrus communis',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 14.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NC3C5V33;C74CRBWY;SJRVJD92;GBV226L7;5RVSKP7R;BCN1AWSX;Y2V2KPN5;A745VL5E;69PE3GKP;4C25NLG6;DSE6DND4;1NP1YSL3;ENERP1XX;CJTC3GLH;HNP1WXSN;42YN8BW5;V23TWYE2;127GGS2V;GP842PNL;BZK7TN5T;2TZ11Y3L;XW4NWXBH;4ERRRNGR',
      statusContent: 'dot',
      hash: '99P6C3S8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99P6C3S8'
    },
    {
      status: 810,
      sku: 'PM0075603',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281068',
      rtl_batch_array: '6281068',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 5.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: '8LV3ZNWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LV3ZNWN'
    },
    {
      status: 910,
      sku: 'PM0083381',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      rng_range_identifier: 'SHI012014C70',
      rng_range_description: 'High stem 12 cm 14 cm C70',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: '447D84YT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '447D84YT'
    },
    {
      status: 910,
      sku: 'PM0083382',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287427',
      rtl_batch_array: '6287427',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '200250C45MS',
      rng_range_identifier: 'H200250C45',
      rng_range_description: 'H200 cm 250 cm C45',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 130,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'TBC5STAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBC5STAV'
    },
    {
      status: 810,
      sku: 'PM0083383',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287428',
      rtl_batch_array: '6287428',
      name_search: 'RHCWHITE',
      name: "Rhododendron 'Cunningham's White'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 38,
      chnn_stock_retail: 38,
      sppl_prcp: 7.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8AC4JS25;SWVWBH2X;VZ25X36G;GEVLVHV8;H5T4PASX;KRN7ZGL9;B9JT2BV5;521PALYN;8H1GBA56;6NG6JG81;2ZA1STN5;JZAG5576;KCZSJ34X;LLDCNXT3',
      statusContent: 'dot',
      hash: 'CH5NA5DN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH5NA5DN'
    },
    {
      status: 910,
      sku: 'PM0083384',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287429',
      rtl_batch_array: '6287429',
      name_search: 'RHNZEMBL',
      name: "Rhododendron 'Nova Zembla'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 7.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8W5HCK2;WJXE8SLB;SGCKADSC;WRL37ATL;DGRX79TT;PDG54PVP;Z12H4ZXV;DA3SARXP;1E5VY5PV;DP7C1Y48;JLHDNADR;PTK91ZHL;YSTT6N8J;4Y4N6B4R;J7D4VJPT;WA4SCZTX;STTVGY2Z;VSAX114H;KBJD5H1B',
      statusContent: 'dot',
      hash: '5EL4DP8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5EL4DP8A'
    },
    {
      status: 910,
      sku: 'PM0083385',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287431',
      rtl_batch_array: '6287431',
      name_search: 'RHNZEMBL',
      name: "Rhododendron 'Nova Zembla'",
      sppl_size_code: '060080C25',
      rng_range_identifier: 'H060080C25',
      rng_range_description: 'H60 cm 80 cm C25',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 40,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8W5HCK2;WJXE8SLB;SGCKADSC;WRL37ATL;DGRX79TT;PDG54PVP;Z12H4ZXV;DA3SARXP;1E5VY5PV;DP7C1Y48;JLHDNADR;PTK91ZHL;YSTT6N8J;4Y4N6B4R;J7D4VJPT;WA4SCZTX;STTVGY2Z;VSAX114H;KBJD5H1B',
      statusContent: 'dot',
      hash: 'PYDB1DDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYDB1DDC'
    },
    {
      status: 910,
      sku: 'PM0083386',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287432',
      rtl_batch_array: '6287432',
      name_search: 'RHGLACIN',
      name: "Rhus glabra 'Laciniata'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 26.379,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9D42WC6B',
      statusContent: 'dot',
      hash: 'JPZ19P2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPZ19P2C'
    },
    {
      status: 810,
      sku: 'PM0083387',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287433',
      rtl_batch_array: '6287433',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 21.879,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'LY7AC4GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY7AC4GK'
    },
    {
      status: 210,
      sku: 'PM0030500',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304798',
      rtl_batch_array: '6304798, 5423571',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 998,
      chnn_stock_retail: 1011,
      sppl_prcp: 5.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '9D2L95TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D2L95TW'
    },
    {
      status: 210,
      sku: 'PM0008134',
      core_name: 'Plant',
      sppl_ean: '8720353086064',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6054352',
      rtl_batch_array: '6054352',
      name_search: 'ALSLBLUE',
      name: "Allium senescens 'Lisa Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCPLYNBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCPLYNBJ'
    },
    {
      status: 210,
      sku: 'PM0014071',
      core_name: 'Plant',
      sppl_ean: '8720664668621',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439459',
      rtl_batch_array: '5439459',
      name_search: 'ROBASSIN',
      name: 'Rosa (F) Bassino',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB2762TY;1EW987KK',
      statusContent: 'dot',
      hash: 'BX4S5ALJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX4S5ALJ'
    },
    {
      status: 910,
      sku: 'PM0083388',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287434',
      rtl_batch_array: '6287434',
      name_search: 'ROPUMBRA',
      name: "Robinia pseudoacacia 'Umbraculifera'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 55.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JB2BEGV9;28E1NR15;ZE76153L;HHWSWGLX',
      statusContent: 'dot',
      hash: 'TKR6LW22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKR6LW22'
    },
    {
      status: 910,
      sku: 'PM0083389',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287435',
      rtl_batch_array: '6287435',
      name_search: 'ROPUMBRA',
      name: "Robinia pseudoacacia 'Umbraculifera'",
      sppl_size_code: '200STC20',
      rng_range_identifier: 'STE200C20',
      rng_range_description: 'Stem 200 cm C20',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 54.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JB2BEGV9;28E1NR15;ZE76153L;HHWSWGLX',
      statusContent: 'dot',
      hash: 'RCKP9S3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCKP9S3P'
    },
    {
      status: 810,
      sku: 'PM0083390',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287436',
      rtl_batch_array: '6287436',
      name_search: 'ROSWITTC',
      name: 'Rosa (F) Schneewittchen',
      sppl_size_code: '090STC74',
      rng_range_identifier: 'STE090C7.5',
      rng_range_description: 'Stem 90 cm C7.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EXPW9HHA;DR6AVGVK;YGE4Y3BD',
      statusContent: 'dot',
      hash: 'WJS6YZ24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJS6YZ24'
    },
    {
      status: 910,
      sku: 'PM0083391',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287438',
      rtl_batch_array: '6287438',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 41.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'DBBPBT95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBBPBT95'
    },
    {
      status: 210,
      sku: 'PM0073984',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266831',
      rtl_batch_array: '6266831',
      name_search: 'ROSINNOC',
      name: 'Rosa Innocencia',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WTB2Z8AB;KRE7ZP1N',
      statusContent: 'dot',
      hash: '64WE23BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64WE23BV'
    },
    {
      status: 210,
      sku: 'PM0073985',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266832',
      rtl_batch_array: '6266832',
      name_search: 'ROJAZZ',
      name: 'Rosa (T) Jazz',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 722,
      chnn_stock_retail: 722,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXN6EJTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXN6EJTC'
    },
    {
      status: 210,
      sku: 'PM0073990',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266837',
      rtl_batch_array: '6266837',
      name_search: 'RONOATRA',
      name: "Rosa (H) 'Noatraum'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YPHZL1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YPHZL1K'
    },
    {
      status: 210,
      sku: 'PM0073991',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266838',
      rtl_batch_array: '6266838',
      name_search: 'ROSPDRIF',
      name: 'Rosa (H) Pink Drift',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_order_minimum: 3,
      sppl_prcp: 2.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4XKVKHD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4XKVKHD4'
    },
    {
      status: 810,
      sku: 'PM0073993',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266840',
      rtl_batch_array: '6266840',
      name_search: 'RORALLY',
      name: 'Rosa (H) Rally',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_order_minimum: 3,
      sppl_prcp: 3.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JN2Y4HW4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JN2Y4HW4'
    },
    {
      status: 210,
      sku: 'PM0073998',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266845',
      rtl_batch_array: '6266845',
      name_search: 'ROAHUMBO',
      name: "Rosa (H) 'Alexander von Humboldt'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J2SGVGTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2SGVGTV'
    },
    {
      status: 210,
      sku: 'PM0016775',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353537',
      rtl_batch_array: '6353537',
      name_search: 'VEGAINSB',
      name: "Verbascum 'Gainsborough'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 884,
      chnn_stock_retail: 884,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G3SH126R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3SH126R'
    },
    {
      status: 910,
      sku: 'PM0083392',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287439',
      rtl_batch_array: '6287439',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 150,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: '99LAYW8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99LAYW8J'
    },
    {
      status: 210,
      sku: 'PM0023417',
      core_name: 'Plant',
      sppl_ean: '8720626369825',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5844662',
      rtl_batch_array: '5844662, 6352703',
      name_search: 'SESSBLUT',
      name: "Sedum spurium 'Schorbuser Blut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1701,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWL5GR5X;RW84AKZD;VNK7Z9LY;BDY77D19;7WVYE98T',
      statusContent: 'dot',
      hash: 'LD6Z69G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LD6Z69G5'
    },
    {
      status: 210,
      sku: 'PM0005019',
      core_name: 'Plant',
      sppl_ean: '8720626326019',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '5844661',
      rtl_batch_array: '5844661, 6171195',
      name_search: 'SESFULDA',
      name: "Sedum spurium 'Fuldaglut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 2311,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X31E5GNB;5G89913K;DYVTWPPL;9CJEZN57;JNJ7XJKE;CKA346SD;8P61PHPY',
      statusContent: 'dot',
      hash: 'VAGXA9NG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VAGXA9NG'
    },
    {
      status: 210,
      sku: 'PM0007798',
      core_name: 'Plant',
      sppl_ean: '8720626377684',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6049342',
      rtl_batch_array: '6049342',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_prcp: 5.027,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YEWJ84AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEWJ84AA'
    },
    {
      status: 210,
      sku: 'PM0073987',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266834',
      rtl_batch_array: '6266834',
      name_search: 'ROSEVILL',
      name: 'Rosa (F) La Sevillana',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2990,
      chnn_stock_retail: 2990,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RDCWP2S5;GDLT92J8;28J1ZVL5;EATVEJEG;6KTCA8WV;GX53YD31;46YY7VGD',
      statusContent: 'dot',
      hash: 'H75E95KN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H75E95KN'
    },
    {
      status: 210,
      sku: 'PM0073994',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266841',
      rtl_batch_array: '6266841',
      name_search: 'RORLDVIN',
      name: 'Rosa (F) Red Leonardo da Vinci',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 505,
      chnn_stock_retail: 505,
      sppl_order_minimum: 3,
      sppl_prcp: 2.656,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HP5W1B6E;9AHNH7XD;N12SCZ7B;XDEYA4YH;1ES6V2RC;BAEEZ7EG;28AE4JJ2',
      statusContent: 'dot',
      hash: 'V64ZVCKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V64ZVCKK'
    },
    {
      status: 810,
      sku: 'PM0074000',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266847',
      rtl_batch_array: '6266847',
      name_search: 'ROBALLER',
      name: "Rosa (H) 'Ballerina'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWKZRHZW;LBXV15RW;7R3RDRLL;PC1RTHWC',
      statusContent: 'dot',
      hash: 'S99NTP4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S99NTP4J'
    },
    {
      status: 810,
      sku: 'PM0083393',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287440',
      rtl_batch_array: '6287440',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 24.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'SD89WSBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD89WSBS'
    },
    {
      status: 810,
      sku: 'PM0083394',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287441',
      rtl_batch_array: '6287441',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 33.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: '6GPGWDLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GPGWDLL'
    },
    {
      status: 810,
      sku: 'PM0083395',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287442',
      rtl_batch_array: '6287442',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'J4Y7Z8C1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4Y7Z8C1'
    },
    {
      status: 910,
      sku: 'PM0083396',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287443',
      rtl_batch_array: '6287443',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '200250C80',
      rng_range_identifier: 'H200250C80',
      rng_range_description: 'H200 cm 250 cm C80',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'LVD8Z2ET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVD8Z2ET'
    },
    {
      status: 210,
      sku: 'PM0074002',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266850',
      rtl_batch_array: '6266850',
      name_search: 'ROCDRIFT',
      name: 'Rosa (H) Coral Drift',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 579,
      chnn_stock_retail: 579,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AK2R3PV5',
      statusContent: 'dot',
      hash: 'JXN7B4EY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXN7B4EY'
    },
    {
      status: 210,
      sku: 'PM0073986',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266833',
      rtl_batch_array: '6266833',
      name_search: 'ROKNIRPS',
      name: 'Rosa (H) Knirps',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 835,
      chnn_stock_retail: 835,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APCHDSLN;SCNWXXPX;9D85CVES',
      statusContent: 'dot',
      hash: 'VR7WHA75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VR7WHA75'
    },
    {
      status: 210,
      sku: 'PM0073989',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266836',
      rtl_batch_array: '6266836',
      name_search: 'ROMIRATO',
      name: 'Rosa (H) Mirato',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2155,
      chnn_stock_retail: 2155,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZEER8B42;2YJHHD5B',
      statusContent: 'dot',
      hash: '71NDPA1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71NDPA1E'
    },
    {
      status: 210,
      sku: 'PM0073995',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266842',
      rtl_batch_array: '6266842',
      name_search: 'RORUGBY',
      name: 'Rosa (H) Rugby',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 930,
      chnn_stock_retail: 930,
      sppl_order_minimum: 3,
      sppl_prcp: 3.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1D5WGZ7Y',
      statusContent: 'dot',
      hash: 'XL56KA7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XL56KA7S'
    },
    {
      status: 910,
      sku: 'PM0083397',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAHHUMIL',
      name: 'Sarcococca hookeriana humilis',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      imageCore:
        '8129VXKJ;CAEVYGPS;P2B7KXZW;NCL6CE76;21AB2EWV;C2T4155L;VHE3YL9H;XZDDJKXE;B53W7ND6;YKWKZYAY;ZZXP9NHA;GY4LK5GN;HWH2S3DP;T8RNDZNT',
      statusContent: 'dot',
      hash: 'H3ZJL5B1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H3ZJL5B1'
    },
    {
      status: 910,
      sku: 'PM0083398',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287445',
      rtl_batch_array: '6287445',
      name_search: 'SCHMOONL',
      name: "Schizophragma hydrangeoides 'Moonlight'",
      sppl_size_code: '125150C2',
      rng_range_identifier: 'H125150C2',
      rng_range_description: 'H125 cm 150 cm C2',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 78.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LL5SD466;ZT3TVWWS;BVSR98PZ;9NEATXXT;HJRZLLJ9;1YJ4B1V5',
      statusContent: 'dot',
      hash: 'C2SGYZ6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2SGYZ6B'
    },
    {
      status: 210,
      sku: 'PM0073992',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266839',
      rtl_batch_array: '6266839',
      name_search: 'ROPSUNRI',
      name: 'Rosa (H) Pretty Sunrise',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1070,
      chnn_stock_retail: 1070,
      sppl_order_minimum: 3,
      sppl_prcp: 2.656,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WGBW3GD5;148WCCX9;V96YWLXH;S4XVSH1C;6SV3GAJG;Y4YHNW4J',
      statusContent: 'dot',
      hash: '8C12HBYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8C12HBYY'
    },
    {
      status: 210,
      sku: 'PM0073996',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266843',
      rtl_batch_array: '6266843',
      name_search: 'ROSFOAM',
      name: "Rosa (H) 'Sea Foam'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 1790,
      chnn_stock_retail: 1790,
      sppl_order_minimum: 3,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ESBA9P4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESBA9P4K'
    },
    {
      status: 210,
      sku: 'PM0073999',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266846',
      rtl_batch_array: '6266846',
      name_search: 'ROAROSE',
      name: 'Rosa (H) Aspirin Rose',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2635,
      chnn_stock_retail: 2635,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9DCZN2G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9DCZN2G4'
    },
    {
      status: 210,
      sku: 'PM0074001',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266848',
      rtl_batch_array: '6266848',
      name_search: 'ROBMEIDI',
      name: 'Rosa (H) Bingo Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1135,
      chnn_stock_retail: 1135,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X87PGSJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X87PGSJZ'
    },
    {
      status: 210,
      sku: 'PM0073997',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266844',
      rtl_batch_array: '6266844',
      name_search: 'ROSTRACK',
      name: 'Rosa (H) Short Track',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 324,
      chnn_stock_retail: 324,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K6W5RL9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6W5RL9T'
    },
    {
      status: 210,
      sku: 'PM0073988',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266835',
      rtl_batch_array: '6266835',
      name_search: 'ROSLFAIR',
      name: 'Rosa (PA) Spelarosa Lovely Fairy',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZXK1TT9E',
      statusContent: 'dot',
      hash: 'VHYGH1RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHYGH1RX'
    },
    {
      status: 910,
      sku: 'PM0083399',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287446',
      rtl_batch_array: '6287446',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      sppl_size_code: '060070C15',
      rng_range_identifier: 'H060070C15',
      rng_range_description: 'H60 cm 70 cm C15',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 50.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      statusContent: 'dot',
      hash: '12H5NZTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12H5NZTY'
    },
    {
      status: 910,
      sku: 'PM0083400',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287447',
      rtl_batch_array: '6287447',
      name_search: 'SODODONG',
      name: "Sorbus 'Dodong'",
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 215,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V1B8K8CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1B8K8CD'
    },
    {
      status: 810,
      sku: 'PM0083401',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287450',
      rtl_batch_array: '6287450',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 3,
      sppl_prcp: 4.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'S2CV1VC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2CV1VC1'
    },
    {
      status: 910,
      sku: 'PM0083402',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287453',
      rtl_batch_array: '6287453',
      name_search: 'HYQALICE',
      name: "Hydrangea quercifolia 'Alice'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 42.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9766V5DT;XJ1NN636',
      statusContent: 'dot',
      hash: 'WGVPDZ5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGVPDZ5X'
    },
    {
      status: 910,
      sku: 'PM0083403',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287455',
      rtl_batch_array: '6287455',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 56.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'AC3Z89BJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AC3Z89BJ'
    },
    {
      status: 910,
      sku: 'PM0083404',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287456',
      rtl_batch_array: '6287456',
      name_search: 'ILAJTOL',
      name: "Ilex aquifolium 'J.C. van Tol'",
      sppl_size_code: '125150C45',
      rng_range_identifier: 'H125150C45',
      rng_range_description: 'H125 cm 150 cm C45',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 49.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CVLG67DE;B42AGHSH;2PBD56VG;8NRC3DRJ;9CSJSL6G;PY213WDA;5952BJHK;EELRV6HZ',
      statusContent: 'dot',
      hash: 'NJRX3CH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJRX3CH7'
    },
    {
      status: 810,
      sku: 'PM0083405',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287459',
      rtl_batch_array: '6287459',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 3.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: 'WLGKBJEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WLGKBJEW'
    },
    {
      status: 910,
      sku: 'PM0083406',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287460',
      rtl_batch_array: '6287460',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 7.843,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: 'ZWHDC6J1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZWHDC6J1'
    },
    {
      status: 910,
      sku: 'PM0083407',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287461',
      rtl_batch_array: '6287461',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 20,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: 'EVAYKL98',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVAYKL98'
    },
    {
      status: 910,
      sku: 'PM0083408',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287462',
      rtl_batch_array: '6287462',
      name_search: 'ILMBPRIN',
      name: "Ilex meserveae 'Blue Prince'",
      sppl_size_code: '060C30BOL',
      rng_range_identifier: 'BALL\u00d8060C30',
      rng_range_description: 'Ball \u00d8 60 cm C30',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HD7AJW6W;WXHXG923;THWW44C1;VS3ZZLXC',
      statusContent: 'dot',
      hash: 'RR9HDZXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR9HDZXW'
    },
    {
      status: 910,
      sku: 'PM0083409',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287463',
      rtl_batch_array: '6287463',
      name_search: 'ILNRSTEV',
      name: "Ilex 'Nellie R. Stevens'",
      sppl_size_code: '175200C50',
      rng_range_identifier: 'H175200C50',
      rng_range_description: 'H175 cm 200 cm C50',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 140,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L4243KVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4243KVC'
    },
    {
      status: 910,
      sku: 'PM0083410',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287464',
      rtl_batch_array: '6287464',
      name_search: 'JANUDIFL',
      name: 'Jasminum nudiflorum',
      sppl_size_code: '150175C12',
      rng_range_identifier: 'H150175C12',
      rng_range_description: 'H150 cm 175 cm C12',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 25.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TKK7Y96C;EC55166Y;EHN4G591;R2N5X45G;NKRW2BK7;SAZ3BWN1;L125CLNR;TE197YB3',
      statusContent: 'dot',
      hash: 'CH6JRHLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH6JRHLW'
    },
    {
      status: 810,
      sku: 'PM0083411',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287468',
      rtl_batch_array: '6287468',
      name_search: 'LAWVOSSI',
      name: "Laburnum watereri 'Vossii'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 30.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6P8DCEB;B4HD3CG7;CJZ9RAHK;DEXRAHR7;G9LDP2G5;T7DJHNLB;9G78LHKK',
      statusContent: 'dot',
      hash: '622DSDJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '622DSDJ2'
    },
    {
      status: 910,
      sku: 'PM0083412',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287470',
      rtl_batch_array: '6287470',
      name_search: 'LAINDICA',
      name: 'Lagerstroemia indica',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X8NBE9PC;WBRLL3HS;28NLR6YN;7EVDG6R3;24TV12PV;TB6PVGT5;HHBR1C6X;KB1LL19E',
      statusContent: 'dot',
      hash: '5G2PCBY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G2PCBY2'
    },
    {
      status: 810,
      sku: 'PM0083413',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287471',
      rtl_batch_array: '6287471',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 7.392,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'Z1C9X916',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1C9X916'
    },
    {
      status: 210,
      sku: 'PM0083414',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287472',
      rtl_batch_array: '6287472',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100120C20',
      rng_range_identifier: 'H100120C20',
      rng_range_description: 'H100 cm 120 cm C20',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 18.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '734K5JRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '734K5JRG'
    },
    {
      status: 810,
      sku: 'PM0083415',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287473',
      rtl_batch_array: '6287473',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '175200C45',
      rng_range_identifier: 'H175200C45',
      rng_range_description: 'H175 cm 200 cm C45',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 56.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'BEE82Y3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEE82Y3Z'
    },
    {
      status: 210,
      sku: 'PM0083416',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287474',
      rtl_batch_array: '6287474',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '120140C20',
      rng_range_identifier: 'H120140C20',
      rng_range_description: 'H120 cm 140 cm C20',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 18.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: '9J2R2BJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J2R2BJV'
    },
    {
      status: 910,
      sku: 'PM0083417',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287477',
      rtl_batch_array: '6287477',
      name_search: 'LISGBALL',
      name: "Liquidambar styraciflua 'Gum Ball'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 70,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AD1C8L74;JCG6GR11;2426BGCE;GZ1LLWGC;J5TP2RT4;T4K8717V;9XXEJV2L;LCW7P16A;91LGRC49',
      statusContent: 'dot',
      hash: '7ATLESKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ATLESKB'
    },
    {
      status: 910,
      sku: 'PM0083418',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287478',
      rtl_batch_array: '6287478',
      name_search: 'LISGBALL',
      name: "Liquidambar styraciflua 'Gum Ball'",
      sppl_size_code: '070COBOL',
      rng_range_identifier: 'BALL\u00d8070H070070',
      rng_range_description: 'Ball \u00d8 70 cm H70 cm 70 cm',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 63.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AD1C8L74;JCG6GR11;2426BGCE;GZ1LLWGC;J5TP2RT4;T4K8717V;9XXEJV2L;LCW7P16A;91LGRC49',
      statusContent: 'dot',
      hash: 'DK1HL841',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DK1HL841'
    },
    {
      status: 810,
      sku: 'PM0083419',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287483',
      rtl_batch_array: '6287483',
      name_search: 'LOHENRYI',
      name: 'Lonicera henryi',
      sppl_size_code: '175200C12',
      rng_range_identifier: 'H175200C12',
      rng_range_description: 'H175 cm 200 cm C12',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 26.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN37L23T;JG217ECD;8H823RVC;REDLBRVV;CRZV8V5A;TPDWP3EY;7XRTK71Y;EENTKELG;E63ER6TJ;NWD4J7CW;GLSRDX75;J6K74GCC;W8A35BAE;CK844ZSW',
      statusContent: 'dot',
      hash: 'AJV7CYDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AJV7CYDL'
    },
    {
      status: 910,
      sku: 'PM0083420',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287485',
      rtl_batch_array: '6287485',
      name_search: 'MASIEBOL',
      name: 'Magnolia sieboldii',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 65.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '992PB4CA;HSSZ9GWK;K1VDH3VC;68D7TER8;J9KH1PXR;4P2XTTL4;V57LCPKP;6TDKNEHR',
      statusContent: 'dot',
      hash: 'J63TENBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J63TENBZ'
    },
    {
      status: 210,
      sku: 'PM0083421',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287486',
      rtl_batch_array: '6287486',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 112,
      chnn_stock_retail: 112,
      sppl_prcp: 14.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: '7533XA5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7533XA5K'
    },
    {
      status: 910,
      sku: 'PM0083422',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287487',
      rtl_batch_array: '6287487',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 61.909,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: 'K9JN43P4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9JN43P4'
    },
    {
      status: 910,
      sku: 'PM0083423',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287488',
      rtl_batch_array: '6287488',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      sppl_size_code: '200250C50',
      rng_range_identifier: 'H200250C50',
      rng_range_description: 'H200 cm 250 cm C50',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 142.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: '6W2BDTJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W2BDTJ6'
    },
    {
      status: 210,
      sku: 'PM0083424',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287490',
      rtl_batch_array: '6287490',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_prcp: 14.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'XAXEGZCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAXEGZCX'
    },
    {
      status: 910,
      sku: 'PM0083425',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287491',
      rtl_batch_array: '6287491',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 71.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'JRJ7TL6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JRJ7TL6G'
    },
    {
      status: 910,
      sku: 'PM0083426',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287492',
      rtl_batch_array: '6287492',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '175200C80',
      rng_range_identifier: 'H175200C80',
      rng_range_description: 'H175 cm 200 cm C80',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 173,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: 'L6ZG2SBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L6ZG2SBE'
    },
    {
      status: 910,
      sku: 'PM0083427',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287495',
      rtl_batch_array: '6287495',
      name_search: 'MAMWSUN',
      name: "Mahonia media 'Winter Sun'",
      sppl_size_code: '050080C12',
      rng_range_identifier: 'H050080C12',
      rng_range_description: 'H50 cm 80 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 21.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2BBW8GXV;RPZR1885;B2S9GWN2;ZPCDY8JT;ZSSYKGDS;GLBBNLPJ;LZXLZRXN',
      statusContent: 'dot',
      hash: '6XVXDATA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XVXDATA'
    },
    {
      status: 810,
      sku: 'PM0083428',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287496',
      rtl_batch_array: '6287496',
      name_search: 'MADOMEST',
      name: 'Malus domestica',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_prcp: 12.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '76E2R55G;12GCV61L;WAABBBX4;LBE791DR;EEVJ5T5D;NCZC9V32;11HSKLK4;YRD9JZCG;8LC2J3KY;4TA7HCWW;99RVTJVX;6KAY9E7Z;TWK2B8CL;VJZ24A65;AGY3ATWJ;JX79LPLS;SJKD6XST;DW12277L;ASWNWY58;5N7V1575;XHK5B9KZ;Y29B5A2C;1SGK471P;WTYWY6L5;4R7CNEJ9;L7ZZEEBB;S356CT45;KS6RWHCJ',
      statusContent: 'dot',
      hash: 'NGGEHJW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGGEHJW1'
    },
    {
      status: 810,
      sku: 'PM0083429',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287497',
      rtl_batch_array: '6287497',
      name_search: 'MADOMEST',
      name: 'Malus domestica',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 15.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '76E2R55G;12GCV61L;WAABBBX4;LBE791DR;EEVJ5T5D;NCZC9V32;11HSKLK4;YRD9JZCG;8LC2J3KY;4TA7HCWW;99RVTJVX;6KAY9E7Z;TWK2B8CL;VJZ24A65;AGY3ATWJ;JX79LPLS;SJKD6XST;DW12277L;ASWNWY58;5N7V1575;XHK5B9KZ;Y29B5A2C;1SGK471P;WTYWY6L5;4R7CNEJ9;L7ZZEEBB;S356CT45;KS6RWHCJ',
      statusContent: 'dot',
      hash: 'TNVHAR89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNVHAR89'
    },
    {
      status: 910,
      sku: 'PM0083430',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287498',
      rtl_batch_array: '6287498',
      name_search: 'MAEVERES',
      name: "Malus 'Evereste'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2P443V8J;LDZZ9T2B;P33VCARA;188R4BDT;JWT7ZWKX;6PS2KGG7;7BGLWBWS;249VEA4Y;128LT7GE;91J134EY;C8L8G1XV;LZLHD8LT;JVAVBAV4;9P3HJASK;XJGGJTP7;ZJTBYVPA;JWG5A2CV;5NK5Y24G;S83VC92X;LKE3SWAS;JACCLJ15;DX3K9NPG;GVDX452D;R1XB9B3G;W6Z7J2AN;7HX7JWW8',
      statusContent: 'dot',
      hash: 'LY5GV2R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY5GV2R7'
    },
    {
      status: 910,
      sku: 'PM0083431',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287499',
      rtl_batch_array: '6287499',
      name_search: 'MAGHORNE',
      name: "Malus 'Golden Hornet'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RK6TDYAE;AR7PKN6Y;H74E86ST;BEAE2EAE;CRL7VHVP;RAE699ET;7DHGN8PL;EE12TTL1;KP9NAGDZ;LZ1P1CTS;6R2ZA1TX;X3E36XGW;17157LSZ;7DX131B6;ZSA4B3P7;XXG3WVBX;E6E2XE7K;SKXW5VRD',
      statusContent: 'dot',
      hash: 'ZBER6RDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBER6RDW'
    },
    {
      status: 910,
      sku: 'PM0083432',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287502',
      rtl_batch_array: '6287502',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 6.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'JEW81NT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEW81NT6'
    },
    {
      status: 910,
      sku: 'PM0083433',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287503',
      rtl_batch_array: '6287503',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 45.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '1G3SYVB7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1G3SYVB7'
    },
    {
      status: 210,
      sku: 'PM0083436',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287507',
      rtl_batch_array: '6287507',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '060COBOL',
      rng_range_identifier: 'BALL\u00d8060H060060',
      rng_range_description: 'Ball \u00d8 60 cm H60 cm 60 cm',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'XD5DTEGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD5DTEGV'
    },
    {
      status: 210,
      sku: 'PM0083437',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287508',
      rtl_batch_array: '6287508',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '050COBOL',
      rng_range_identifier: 'BALL\u00d8050H050050',
      rng_range_description: 'Ball \u00d8 50 cm H50 cm 50 cm',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_prcp: 40.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '2NX8EA65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NX8EA65'
    },
    {
      status: 810,
      sku: 'PM0083438',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287509',
      rtl_batch_array: '6287509',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '070COBOL',
      rng_range_identifier: 'BALL\u00d8070H070070',
      rng_range_description: 'Ball \u00d8 70 cm H70 cm 70 cm',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 94.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: '7XADCAHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XADCAHP'
    },
    {
      status: 210,
      sku: 'PM0083439',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287510',
      rtl_batch_array: '6287510',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_prcp: 18.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'RD3CNLDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD3CNLDH'
    },
    {
      status: 910,
      sku: 'PM0083440',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287511',
      rtl_batch_array: '6287511',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '150175C30',
      rng_range_identifier: 'H150175C30',
      rng_range_description: 'H150 cm 175 cm C30',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 70.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: '6E6KK6BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6E6KK6BW'
    },
    {
      status: 810,
      sku: 'PM0083441',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287512',
      rtl_batch_array: '6287512',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'KXNV6AE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXNV6AE5'
    },
    {
      status: 910,
      sku: 'PM0083442',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287513',
      rtl_batch_array: '6287513',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 57.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'KB2185DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KB2185DY'
    },
    {
      status: 810,
      sku: 'PM0083443',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287515',
      rtl_batch_array: '6287515',
      name_search: 'PATVROBU',
      name: "Parthenocissus tricuspidata 'Veitchii Robusta'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_prcp: 33.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X51Y72J8',
      statusContent: 'dot',
      hash: 'Y25X5GL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y25X5GL2'
    },
    {
      status: 810,
      sku: 'PM0083444',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287516',
      rtl_batch_array: '6287516',
      name_search: 'PHFCROUG',
      name: "Photinia fraseri 'Carr\u00e9 Rouge'",
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 42.209,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTAL895E;V9CBCHX3;K92T5DP9;58SYYG96;VEJJT7BK;J6P4BKTJ;GER2S6HJ;8VR39B8C;SGB5B62T;N2ZDA58P',
      statusContent: 'dot',
      hash: 'SEBPV7BL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SEBPV7BL'
    },
    {
      status: 210,
      sku: 'PM0083445',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287520',
      rtl_batch_array: '6287520',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 1195,
      chnn_stock_retail: 1195,
      sppl_prcp: 12.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: '5N7CBBKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N7CBBKA'
    },
    {
      status: 810,
      sku: 'PM0083446',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287521',
      rtl_batch_array: '6287521',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_prcp: 26.679,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'NV52YLYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV52YLYB'
    },
    {
      status: 910,
      sku: 'PM0083447',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      rng_range_identifier: 'PLEA010012C35',
      rng_range_description: 'Pleached 10 cm 12 cm C35',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'GV6ATSX1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GV6ATSX1'
    },
    {
      status: 910,
      sku: 'PM0038532',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083855',
      rtl_batch_array: '6083855',
      name_search: 'DCMBEEND',
      name: 'DCM Meststof Beendermeel',
      sppl_size_code: '014KGDOOS',
      rng_range_identifier: 'BOX1.5KG',
      rng_range_description: 'Box 1.5 Kilogram',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 5.7,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '4DZAARH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '4DZAARH1'
    },
    {
      status: 910,
      sku: 'PM0038333',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083857',
      rtl_batch_array: '6083857',
      name_search: 'DCMBUXU',
      name: 'DCM Meststof Buxus',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '53S93YGX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '53S93YGX'
    },
    {
      status: 910,
      sku: 'PM0038330',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083859',
      rtl_batch_array: '6083859',
      name_search: 'DCMGEFRU',
      name: 'DCM Meststof Groente en Fruit',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 8.3,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '9R5KT169',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '9R5KT169'
    },
    {
      status: 910,
      sku: 'PM0038325',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083865',
      rtl_batch_array: '6083865',
      name_search: 'DCVMBUXU',
      name: 'DCM Vloeibare Mestst. Buxus',
      sppl_size_code: '08LTRFLES',
      rng_range_identifier: 'BOT0.8LTR',
      rng_range_description: 'Bottle 0.8 Liter',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'EB35HTCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'EB35HTCA'
    },
    {
      status: 910,
      sku: 'PM0038533',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083871',
      rtl_batch_array: '6083871',
      name_search: 'DCCOMPOS',
      name: 'DCM Compostmaker',
      sppl_size_code: '03KGDOOS',
      rng_range_identifier: 'BOX3KG',
      rng_range_description: 'Box 3 Kilogram',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: 'R374HV6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: 'R374HV6X'
    },
    {
      status: 810,
      sku: 'PM0075611',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281077',
      rtl_batch_array: '6281077',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.483,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: '7Y8X3XC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y8X3XC1'
    },
    {
      status: 810,
      sku: 'PM0075610',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281076',
      rtl_batch_array: '6281076',
      name_search: 'PRNBRILL',
      name: "Prunus nipponica 'Brillant'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.532,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1JG1SER;DGWBYPVB;P5S8SAE7;14W5BZA9;5B8VEVS3;J2ACAACP;9VK1JN48',
      statusContent: 'dot',
      hash: 'LXG87WX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXG87WX5'
    },
    {
      status: 810,
      sku: 'PM0075605',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281071',
      rtl_batch_array: '6281071',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 21.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: '4L4A6DZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4L4A6DZL'
    },
    {
      status: 810,
      sku: 'PM0075608',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281074',
      rtl_batch_array: '6281074',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 57.592,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'YC92GYAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YC92GYAP'
    },
    {
      status: 810,
      sku: 'PM0075607',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281073',
      rtl_batch_array: '6281073',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 44.985,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'EC5HPNZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EC5HPNZC'
    },
    {
      status: 810,
      sku: 'PM0075606',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281072',
      rtl_batch_array: '6281072',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 27.447,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: '41K2B6EH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41K2B6EH'
    },
    {
      status: 810,
      sku: 'PM0075609',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281075',
      rtl_batch_array: '6281075',
      name_search: 'PRIKNMAI',
      name: "Prunus incisa 'Kojou-no-mai'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9B8SLJH;LJKEV9AE;PRLZCKB2;H674SENK;NTL778JL;XAC44826;1ZJ5JHY8;RJWAELSD;P9SAD49D;DHSLTBC4;BEB8XJ5E;T62GK6HN;KY53KRSX;RY5BV2N8;X555ZXSE;HG5X55DZ;85S5ZK5K;T4KZ886G;RY8KPRB4;LR4GA7VW',
      statusContent: 'dot',
      hash: '4CN5PKXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CN5PKXJ'
    },
    {
      status: 810,
      sku: 'PM0075612',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281078',
      rtl_batch_array: '6281078',
      name_search: 'PRTFHILL',
      name: "Prunus tenella 'Fire Hill'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DZ51JWXY',
      statusContent: 'dot',
      hash: '4N62L8VP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4N62L8VP'
    },
    {
      status: 210,
      sku: 'PM0083448',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373147',
      rtl_batch_array: '6373147, 6287531',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 277,
      chnn_stock_retail: 487,
      sppl_prcp: 8.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'PAK3GES7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAK3GES7'
    },
    {
      status: 810,
      sku: 'PM0075613',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281079',
      rtl_batch_array: '6281079',
      name_search: 'PRTFHILL',
      name: "Prunus tenella 'Fire Hill'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DZ51JWXY',
      statusContent: 'dot',
      hash: 'D52959VJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D52959VJ'
    },
    {
      status: 910,
      sku: 'PM0083449',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287532',
      rtl_batch_array: '6287532',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 57.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: 'P5G39158',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5G39158'
    },
    {
      status: 210,
      sku: 'PM0001328',
      core_name: 'Plant',
      sppl_ean: '8720664851665',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6162049',
      rtl_batch_array: '6284229, 6162049, 5363853, 6288298',
      name_search: 'ANTRIPLI',
      name: 'Anaphalis triplinervis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 5194,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GK3R5V2',
      statusContent: 'dot',
      hash: '6LL348TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LL348TB'
    },
    {
      status: 810,
      sku: 'PM0083450',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287533',
      rtl_batch_array: '6287533',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'LB65JGL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB65JGL2'
    },
    {
      status: 210,
      sku: 'PM0000164',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242122',
      rtl_batch_array: '6315040, 6242122, 6386067',
      name_search: 'ARLSQUEE',
      name: "Artemisia ludoviciana 'Silver Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 4549,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YZK4D9LR;JTXYZCD5;KR9G5W44;2RL2A578',
      statusContent: 'dot',
      hash: 'A8SNRYZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8SNRYZD'
    },
    {
      status: 810,
      sku: 'PM0083451',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287534',
      rtl_batch_array: '6287534',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '150175C12',
      rng_range_identifier: 'H150175C12',
      rng_range_description: 'H150 cm 175 cm C12',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 27.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'AZX69J2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZX69J2X'
    },
    {
      status: 810,
      sku: 'PM0083452',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287538',
      rtl_batch_array: '6287538',
      name_search: 'PRLMISCH',
      name: "Prunus laurocerasus 'Mischeana'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 3.392,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XP8GG6G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP8GG6G3'
    },
    {
      status: 210,
      sku: 'PM0083453',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373847',
      rtl_batch_array: '6373847, 6287542',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '020030C24',
      rng_range_identifier: 'H020030C2.5',
      rng_range_description: 'H20 cm 30 cm C2.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2030,
      sppl_prcp: 2.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: '54WE8NA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54WE8NA1'
    },
    {
      status: 910,
      sku: 'PM0083454',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287547',
      rtl_batch_array: '6287547',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 50.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '85S77CRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85S77CRA'
    },
    {
      status: 810,
      sku: 'PM0083455',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287550',
      rtl_batch_array: '6287550',
      name_search: 'PRPERSIC',
      name: 'Prunus persica',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R3GNKWEK;RTA7ES48;XJPR31K9;BLVYZZXH;8Y4JC22W;S4J8KPP2;7DD7E9L2;ZAA4HR3S;YBGH4ANC;4RRP7243;KXH5BVT7;ADY7VB22;D8CDAWS3;V6GB23ZS;P1VDL1BY',
      statusContent: 'dot',
      hash: '55N8YNPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55N8YNPH'
    },
    {
      status: 810,
      sku: 'PM0083456',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287551',
      rtl_batch_array: '6287551',
      name_search: 'PRPERSIC',
      name: 'Prunus persica',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 16.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R3GNKWEK;RTA7ES48;XJPR31K9;BLVYZZXH;8Y4JC22W;S4J8KPP2;7DD7E9L2;ZAA4HR3S;YBGH4ANC;4RRP7243;KXH5BVT7;ADY7VB22;D8CDAWS3;V6GB23ZS;P1VDL1BY',
      statusContent: 'dot',
      hash: '7148PXNZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7148PXNZ'
    },
    {
      status: 910,
      sku: 'PM0083457',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287552',
      rtl_batch_array: '6287552',
      name_search: 'PRSERRUL',
      name: 'Prunus serrula',
      sppl_size_code: '100150C12',
      rng_range_identifier: 'H100150C12',
      rng_range_description: 'H100 cm 150 cm C12',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 25.179,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YV7S2C6L',
      statusContent: 'dot',
      hash: 'LAYBYWXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LAYBYWXA'
    },
    {
      status: 210,
      sku: 'PM0003725',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 2,
      btch_active_retail: '6274736',
      rtl_batch_array: '6274736, 6339001',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '150175C2',
      rng_range_identifier: 'H150175C2',
      rng_range_description: 'H150 cm 175 cm C2',
      sppl_stock_available: 815,
      chnn_stock_retail: 2215,
      sppl_prcp: 2.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'GY3VD4S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GY3VD4S6'
    },
    {
      status: 210,
      sku: 'PM0014209',
      core_name: 'Plant',
      sppl_ean: '8720349409365',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5548054',
      rtl_batch_array: '5548054',
      name_search: 'ANDRUBRA',
      name: "Antennaria dioica 'Rubra'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 648,
      chnn_stock_retail: 648,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVWZ4S6L',
      imageBatch: 'NDNWTS9J',
      statusContent: 'dot',
      hash: 'N87R83HK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N87R83HK'
    },
    {
      status: 910,
      sku: 'PM0083458',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287553',
      rtl_batch_array: '6287553',
      name_search: 'PRSERRUL',
      name: 'Prunus serrula',
      sppl_size_code: '200225C90',
      rng_range_identifier: 'H200225C90',
      rng_range_description: 'H200 cm 225 cm C90',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 195,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YV7S2C6L',
      statusContent: 'dot',
      hash: 'G6G6CTDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G6G6CTDZ'
    },
    {
      status: 910,
      sku: 'PM0083459',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287554',
      rtl_batch_array: '6287554',
      name_search: 'PRSERRUL',
      name: 'Prunus serrula',
      sppl_size_code: '200250C90',
      rng_range_identifier: 'H200250C90',
      rng_range_description: 'H200 cm 250 cm C90',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 197,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YV7S2C6L',
      statusContent: 'dot',
      hash: 'X64D643D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X64D643D'
    },
    {
      status: 210,
      sku: 'PM0022041',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6352604',
      rtl_batch_array: '6369216, 6263742, 5741523, 6352604',
      name_search: 'AMARENAR',
      name: 'Ammophila arenaria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2162,
      chnn_stock_retail: 9391,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YS8LC6TE;8355ZEEA;2T319S8G;1NVK5LRV;CGKAC4EX;E6XSYYAG;RJDRX6AV;VJKBPH51;N3ZC9EY2;DDTWAETH',
      statusContent: 'dot',
      hash: 'RYDWHX4Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYDWHX4Y'
    },
    {
      status: 210,
      sku: 'PM0022126',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6278466',
      rtl_batch_array: '6278466',
      name_search: 'ANTRIPLI',
      name: 'Anaphalis triplinervis',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 179,
      chnn_stock_retail: 179,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5GK3R5V2',
      statusContent: 'dot',
      hash: 'S5SXRPGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5SXRPGY'
    },
    {
      status: 210,
      sku: 'PM0027094',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6196783',
      rtl_batch_array: '6196783, 6350504, 6305170',
      name_search: 'HOFAUREO',
      name: "Hosta 'Fortunei Aureomarginata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6187,
      chnn_stock_retail: 8140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A34T7ERG;TY3C8ZR9;SSK3W62L;X96RPE39;T8Y5GYKH;5EW82J7P;AYLD8HA7;PVK9X9Z1;ZZ7J62W4;XE3CRXRP;XD37KXVK;V5XY122S;Y8J689KS;4L4R6XWS;HKDT7VJ3;G4P5CE5E;ZBYNEH34;9AZX9K5S;GBXCWW3X;YL53WNLB',
      statusContent: 'dot',
      hash: 'VJKK1P4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJKK1P4B'
    },
    {
      status: 810,
      sku: 'PM0083460',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287555',
      rtl_batch_array: '6287555',
      name_search: 'PRSAMANO',
      name: "Prunus serrulata 'Amanogawa'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2XVGRC1;BJSBGDHH;J5BNCA63;2581W6TD;TN5BH8RK;AWRB2Z18;7DNST9CA',
      statusContent: 'dot',
      hash: '9NE3ATB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NE3ATB6'
    },
    {
      status: 210,
      sku: 'PM0074004',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266852',
      rtl_batch_array: '6266852',
      name_search: 'ROFQUEEN',
      name: "Rosa (P) 'Fairy Queen'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 405,
      chnn_stock_retail: 405,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9N2S8BH6;HP9PX8JV;KWK8E78L',
      statusContent: 'dot',
      hash: 'ATGLZDV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATGLZDV4'
    },
    {
      status: 910,
      sku: 'PM0083461',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287557',
      rtl_batch_array: '6287557',
      name_search: 'PRSKANZA',
      name: "Prunus serrulata 'Kanzan'",
      sppl_size_code: '100150C12',
      rng_range_identifier: 'H100150C12',
      rng_range_description: 'H100 cm 150 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 25.179,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JB9JX3XZ;VTEBW9DL;GD21GLPT;153D2KY4;NS2G53K8;8LTGZBJ9;X1PWTCPA;T8ES29CJ;ZLLK2N28;LE5VCYZ2;PL4AP9XH;Y31HNANX;BELEV3SP;SCED3R6V;5LHZT6YT;ZK85R77R;8L96TE69;AY4HYHAW;4SXTX4YJ',
      statusContent: 'dot',
      hash: '69AHSZDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69AHSZDP'
    },
    {
      status: 910,
      sku: 'PM0083462',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287558',
      rtl_batch_array: '6287558',
      name_search: 'PRSAUTUM',
      name: "Prunus subhirtella 'Autumnalis'",
      sppl_size_code: '175200C90',
      rng_range_identifier: 'H175200C90',
      rng_range_description: 'H175 cm 200 cm C90',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 185,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SYT91PR;5DANZTVR;PD57E499;7TDJWJSS;XCE8WB5L;DRB6ZJEL;A5Z94EVA;YYAYVE6Y',
      statusContent: 'dot',
      hash: 'LLGW9T5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LLGW9T5J'
    },
    {
      status: 810,
      sku: 'PM0083463',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287559',
      rtl_batch_array: '6287559',
      name_search: 'PRSAROSE',
      name: "Prunus subhirtella 'Autumnalis Rosea'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 10.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EDSCGNSS;ESZ44LNE;K3W41VY2;JZ98V6KV;XCE4HG4T;H7VN2WVD;5WY66PWK;CXC9ZWBS;WDTDLPLW;E2JCVPAS',
      statusContent: 'dot',
      hash: 'KAPDKJV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAPDKJV7'
    },
    {
      status: 810,
      sku: 'PM0074003',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266851',
      rtl_batch_array: '6266851',
      name_search: 'ROCUBANA',
      name: 'Rosa (H) Cubana',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2D1TD7ZZ;5XJSG4Y1',
      statusContent: 'dot',
      hash: 'N73X9HKT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N73X9HKT'
    },
    {
      status: 910,
      sku: 'PM0083464',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PYCCHANT',
      name: "Pyrus calleryana 'Chanticleer'",
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore: 'NSSR2CZP;B9AELHD2;AKVZGXYL;4W2RAW6Z;H9X63VV9',
      statusContent: 'dot',
      hash: 'ESN1BGGT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ESN1BGGT'
    },
    {
      status: 210,
      sku: 'PM0074005',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266853',
      rtl_batch_array: '6266853',
      name_search: 'ROF2002',
      name: 'Rosa (H) Floriade 2002',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1460,
      chnn_stock_retail: 1460,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C69XKS1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C69XKS1N'
    },
    {
      status: 210,
      sku: 'PM0074006',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266854',
      rtl_batch_array: '6266854',
      name_search: 'ROGLOED',
      name: "Rosa 'Gloed'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1253,
      chnn_stock_retail: 1253,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSR2C97Y;CXDGNG8G;D4KDSN61;4NYHTTL4',
      statusContent: 'dot',
      hash: '5576T5B9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5576T5B9'
    },
    {
      status: 210,
      sku: 'PM0074007',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266855',
      rtl_batch_array: '6266855',
      name_search: 'ROGOLF',
      name: 'Rosa (H) Golf',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_order_minimum: 3,
      sppl_prcp: 3.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8WV234KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WV234KY'
    },
    {
      status: 910,
      sku: 'PM0083465',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CEAGLAUC',
      name: "Cedrus atl. 'Glauca'",
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      statusContent: 'dot',
      hash: 'VZ73Z3S2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VZ73Z3S2'
    },
    {
      status: 910,
      sku: 'PM0083466',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287563',
      rtl_batch_array: '6287563',
      name_search: 'PRDULCIS',
      name: 'Prunus dulcis',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NX3BBXWX;ZWY6C13B;HDE4NDDZ;JNCJVAHS;E6X1YL85;RT1WG4H1;RZ2KE5DN;XERYGKJD;DKX8AXKT;S6TNL8WS;XR7XCX5X;56XRPGAN;ZD93581D;6THSVD7B;7EL91XAZ;9Y9SH3HN;2546ZZEW;RESA3LN6;A8GWZG4W;ZYWD5YEH;HL3S3H28',
      statusContent: 'dot',
      hash: 'DDESAWCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDESAWCB'
    },
    {
      status: 910,
      sku: 'PM0083467',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287564',
      rtl_batch_array: '6287564',
      name_search: 'PRDULCIS',
      name: 'Prunus dulcis',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 16.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NX3BBXWX;ZWY6C13B;HDE4NDDZ;JNCJVAHS;E6X1YL85;RT1WG4H1;RZ2KE5DN;XERYGKJD;DKX8AXKT;S6TNL8WS;XR7XCX5X;56XRPGAN;ZD93581D;6THSVD7B;7EL91XAZ;9Y9SH3HN;2546ZZEW;RESA3LN6;A8GWZG4W;ZYWD5YEH;HL3S3H28',
      statusContent: 'dot',
      hash: 'TYH31S3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TYH31S3L'
    },
    {
      status: 810,
      sku: 'PM0083468',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287565',
      rtl_batch_array: '6287565',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 3,
      sppl_prcp: 3.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: 'WH1BS81K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WH1BS81K'
    },
    {
      status: 810,
      sku: 'PM0083469',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287566',
      rtl_batch_array: '6287566',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 24.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: '8AZHPR6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AZHPR6D'
    },
    {
      status: 810,
      sku: 'PM0083470',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287567',
      rtl_batch_array: '6287567',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '030040C4',
      rng_range_identifier: 'H030040C4',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_prcp: 10.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'JY2XJ5GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY2XJ5GH'
    },
    {
      status: 910,
      sku: 'PM0083471',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287568',
      rtl_batch_array: '6287568',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 45.409,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: '1LZX9WAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LZX9WAR'
    },
    {
      status: 910,
      sku: 'PM0083472',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287569',
      rtl_batch_array: '6287569',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '150175C35',
      rng_range_identifier: 'H150175C35',
      rng_range_description: 'H150 cm 175 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 70.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'LWLD9GVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWLD9GVS'
    },
    {
      status: 910,
      sku: 'PM0083473',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287570',
      rtl_batch_array: '6287570',
      name_search: 'SYVALSPA',
      name: "Syringa vulgaris 'Andenken an Ludwig Sp\u00e4th'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 34.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRGBPK4G;6W7N2D5D;D7XLY3P8;YYKXS5EV;1Z9Y13PK;EA5N6983;XWYVAGKV',
      statusContent: 'dot',
      hash: '9SALEG1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SALEG1Z'
    },
    {
      status: 810,
      sku: 'PM0083474',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287571',
      rtl_batch_array: '6287571',
      name_search: 'SYVALSPA',
      name: "Syringa vulgaris 'Andenken an Ludwig Sp\u00e4th'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 24.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRGBPK4G;6W7N2D5D;D7XLY3P8;YYKXS5EV;1Z9Y13PK;EA5N6983;XWYVAGKV',
      statusContent: 'dot',
      hash: 'LEACNZ1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEACNZ1W'
    },
    {
      status: 910,
      sku: 'PM0083475',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287572',
      rtl_batch_array: '6287572',
      name_search: 'SYVALSPA',
      name: "Syringa vulgaris 'Andenken an Ludwig Sp\u00e4th'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 70.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRGBPK4G;6W7N2D5D;D7XLY3P8;YYKXS5EV;1Z9Y13PK;EA5N6983;XWYVAGKV',
      statusContent: 'dot',
      hash: '155KE3XX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '155KE3XX'
    },
    {
      status: 910,
      sku: 'PM0083476',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287573',
      rtl_batch_array: '6287573',
      name_search: 'SYVALSPA',
      name: "Syringa vulgaris 'Andenken an Ludwig Sp\u00e4th'",
      sppl_size_code: '150175C35',
      rng_range_identifier: 'H150175C35',
      rng_range_description: 'H150 cm 175 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRGBPK4G;6W7N2D5D;D7XLY3P8;YYKXS5EV;1Z9Y13PK;EA5N6983;XWYVAGKV',
      statusContent: 'dot',
      hash: 'LPCA3N9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPCA3N9C'
    },
    {
      status: 810,
      sku: 'PM0083477',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287574',
      rtl_batch_array: '6287574',
      name_search: 'SYVCJOLY',
      name: "Syringa vulgaris 'Charles Joly'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 24.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JEKZCD8X;DLCWPTG4;7SBXAEZ1',
      statusContent: 'dot',
      hash: '11S8T5R9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11S8T5R9'
    },
    {
      status: 910,
      sku: 'PM0083478',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287575',
      rtl_batch_array: '6287575',
      name_search: 'SYVCJOLY',
      name: "Syringa vulgaris 'Charles Joly'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 70.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JEKZCD8X;DLCWPTG4;7SBXAEZ1',
      statusContent: 'dot',
      hash: '9ZN3JCA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZN3JCA3'
    },
    {
      status: 810,
      sku: 'PM0083479',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287576',
      rtl_batch_array: '6287576',
      name_search: 'SYVMBUCH',
      name: "Syringa vulgaris 'Michel Buchner'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 24.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y23AK5K;KXHJ5622;Z4RZ4HJC;KDPADEKY;6L8SRP7Y;CKV91A49',
      statusContent: 'dot',
      hash: '963ARXSH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '963ARXSH'
    },
    {
      status: 910,
      sku: 'PM0083480',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287577',
      rtl_batch_array: '6287577',
      name_search: 'SYVMBUCH',
      name: "Syringa vulgaris 'Michel Buchner'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 70.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y23AK5K;KXHJ5622;Z4RZ4HJC;KDPADEKY;6L8SRP7Y;CKV91A49',
      statusContent: 'dot',
      hash: 'Y1RHEBJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1RHEBJR'
    },
    {
      status: 810,
      sku: 'PM0083481',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287578',
      rtl_batch_array: '6287578',
      name_search: 'SYVLEMOI',
      name: "Syringa vulgaris 'Mme Lemoine'",
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 24,
      chnn_stock_retail: 24,
      sppl_prcp: 24.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C9W254B8;DVJYRPZJ;Y7HEZEGV',
      statusContent: 'dot',
      hash: '65RK6JS6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65RK6JS6'
    },
    {
      status: 910,
      sku: 'PM0083482',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287579',
      rtl_batch_array: '6287579',
      name_search: 'SYVLEMOI',
      name: "Syringa vulgaris 'Mme Lemoine'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 70.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C9W254B8;DVJYRPZJ;Y7HEZEGV',
      statusContent: 'dot',
      hash: 'EC5C5G11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EC5C5G11'
    },
    {
      status: 910,
      sku: 'PM0083483',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287580',
      rtl_batch_array: '6287580',
      name_search: 'SYVLEMOI',
      name: "Syringa vulgaris 'Mme Lemoine'",
      sppl_size_code: '150175C35',
      rng_range_identifier: 'H150175C35',
      rng_range_description: 'H150 cm 175 cm C35',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C9W254B8;DVJYRPZJ;Y7HEZEGV',
      statusContent: 'dot',
      hash: 'K3CPR6WT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3CPR6WT'
    },
    {
      status: 910,
      sku: 'PM0083484',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287581',
      rtl_batch_array: '6287581',
      name_search: 'SYVLEMOI',
      name: "Syringa vulgaris 'Mme Lemoine'",
      sppl_size_code: '200250C80',
      rng_range_identifier: 'H200250C80',
      rng_range_description: 'H200 cm 250 cm C80',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C9W254B8;DVJYRPZJ;Y7HEZEGV',
      statusContent: 'dot',
      hash: 'YW56XAHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YW56XAHJ'
    },
    {
      status: 910,
      sku: 'PM0083485',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287583',
      rtl_batch_array: '6287583',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 80.109,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'HGPR414V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGPR414V'
    },
    {
      status: 910,
      sku: 'PM0083486',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TIAMERIC',
      name: 'Tilia americana',
      rng_range_identifier: 'SHI010012C35',
      rng_range_description: 'High stem 10 cm 12 cm C35',
      statusContent: 'dot',
      hash: '88WYXRWT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '88WYXRWT'
    },
    {
      status: 910,
      sku: 'PM0083487',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287589',
      rtl_batch_array: '6287589',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 55.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: '6L1DAC4T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L1DAC4T'
    },
    {
      status: 810,
      sku: 'PM0083488',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287591',
      rtl_batch_array: '6287591',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 24.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'T2XXZHBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T2XXZHBG'
    },
    {
      status: 910,
      sku: 'PM0083489',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287592',
      rtl_batch_array: '6287592',
      name_search: 'VIBURKWO',
      name: 'Viburnum burkwoodii',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 61.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EW2R4G22;JB5XJ4N6',
      statusContent: 'dot',
      hash: 'J9PC1JP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9PC1JP8'
    },
    {
      status: 810,
      sku: 'PM0083490',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287593',
      rtl_batch_array: '6287593',
      name_search: 'VICARLCE',
      name: 'Viburnum carlcephalum',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 24.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLC4D9D5;1PE21WKK;PKZYVWCX;LCB99K7C;A4PWJPX1;HV2P3ZJH',
      statusContent: 'dot',
      hash: 'E36W7CVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E36W7CVT'
    },
    {
      status: 810,
      sku: 'PM0083491',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287595',
      rtl_batch_array: '6287595',
      name_search: 'VICARLES',
      name: 'Viburnum carlesii',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 26,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6Z2V4TWK;AJX6342C;WXC85EJK',
      statusContent: 'dot',
      hash: '596NNX38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '596NNX38'
    },
    {
      status: 910,
      sku: 'PM0083492',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287597',
      rtl_batch_array: '6287597',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 67.909,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: '6YWCP7VG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YWCP7VG'
    },
    {
      status: 910,
      sku: 'PM0083493',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287598',
      rtl_batch_array: '6287598',
      name_search: 'VIPMARIE',
      name: "Viburnum plicatum 'Mariesii'",
      sppl_size_code: '175200C50',
      rng_range_identifier: 'H175200C50',
      rng_range_description: 'H175 cm 200 cm C50',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NBXTCED2;6Y9RY5W9;WBZE79ZR;2XHD5H96;V6Y26H71;T9JC4DAD;27N3PV5V;23Y8ZDHC;6J7WCWVC;NH11R4JG;6RBT5ASD;AYD5HKL6;CPBY22EL',
      statusContent: 'dot',
      hash: '9L4L7LCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L4L7LCW'
    },
    {
      status: 210,
      sku: 'PM0083494',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287599',
      rtl_batch_array: '6287599',
      name_search: 'VIPTOMEN',
      name: 'Viburnum plicatum tomentosum',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2424ZH1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2424ZH1X'
    },
    {
      status: 910,
      sku: 'PM0083495',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287600',
      rtl_batch_array: '6287600',
      name_search: 'VIPTOMEN',
      name: 'Viburnum plicatum tomentosum',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCVE5H3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCVE5H3V'
    },
    {
      status: 910,
      sku: 'PM0083496',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287601',
      rtl_batch_array: '6287601',
      name_search: 'VIPWATAN',
      name: "Viburnum plicatum 'Watanabe'",
      sppl_size_code: '125150C35',
      rng_range_identifier: 'H125150C35',
      rng_range_description: 'H125 cm 150 cm C35',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 88.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9LXJV3K;827Z6E42;AS1PNED1;NRRZK322;DNGB43HJ;ZE1WC5B3',
      statusContent: 'dot',
      hash: 'ZSZ6E4T1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSZ6E4T1'
    },
    {
      status: 810,
      sku: 'PM0083497',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287602',
      rtl_batch_array: '6287602',
      name_search: 'VIBRHYTI',
      name: 'Viburnum rhytidophylloides',
      sppl_size_code: '080100C12',
      rng_range_identifier: 'H080100C12',
      rng_range_description: 'H80 cm 100 cm C12',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 24.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8ZK9PP17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZK9PP17'
    },
    {
      status: 810,
      sku: 'PM0083498',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287603',
      rtl_batch_array: '6287603',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 10.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'NH4S37R4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH4S37R4'
    },
    {
      status: 910,
      sku: 'PM0083499',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287604',
      rtl_batch_array: '6287604',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 40.192,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'BD2NVDKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BD2NVDKH'
    },
    {
      status: 810,
      sku: 'PM0083500',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287607',
      rtl_batch_array: '6287607',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 3,
      sppl_prcp: 9.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: 'G87CC8H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G87CC8H6'
    },
    {
      status: 910,
      sku: 'PM0083501',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287608',
      rtl_batch_array: '6287608',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '175200C12',
      rng_range_identifier: 'H175200C12',
      rng_range_description: 'H175 cm 200 cm C12',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 29.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: '7D5VYDLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D5VYDLL'
    },
    {
      status: 910,
      sku: 'PM0083502',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287609',
      rtl_batch_array: '6287609',
      name_search: 'ZESERRAT',
      name: 'Zelkova serrata',
      sppl_size_code: '200225C90',
      rng_range_identifier: 'H200225C90',
      rng_range_description: 'H200 cm 225 cm C90',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 171.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X3ABZ36G',
      statusContent: 'dot',
      hash: 'JP5SE5R8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP5SE5R8'
    },
    {
      status: 910,
      sku: 'PM0083503',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287611',
      rtl_batch_array: '6287611',
      name_search: 'CUSPYRAM',
      name: "Cupressus sempervirens 'Pyramidalis'",
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 42.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XLN2EZ2N',
      statusContent: 'dot',
      hash: 'PRC5LRJ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRC5LRJ9'
    },
    {
      status: 810,
      sku: 'PM0083504',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287612',
      rtl_batch_array: '6287612',
      name_search: 'CUSTOTEM',
      name: "Cupressus sempervirens 'Totem'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 17.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YG2X4CNN;RA9NA1XC;7X5RVNVH;9D2Y288X;CJYB7T1X;V1J5GV9B;K4ZGN36L;K7JNPAZN',
      statusContent: 'dot',
      hash: '2LGKC2BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LGKC2BW'
    },
    {
      status: 910,
      sku: 'PM0083505',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GIBILOBA',
      name: 'Ginkgo biloba',
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore:
        'WY8TE552;DT2176J1;X7YVRRRC;ZA4NZ32C;CKEXKRAR;RLNHRA6B;XL1JAJ4D;DEPR9L6D;BWA6VT6S;1RDZ1KTZ;SJHWZYBP;HR2WJ79N;ZT59WZ7D;87HX4H3S',
      statusContent: 'dot',
      hash: 'E1EDEWH2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E1EDEWH2'
    },
    {
      status: 910,
      sku: 'PM0083506',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287615',
      rtl_batch_array: '6287615',
      name_search: 'GIBFASTI',
      name: "Ginkgo biloba 'Fastigiata'",
      sppl_size_code: '175200C35',
      rng_range_identifier: 'H175200C35',
      rng_range_description: 'H175 cm 200 cm C35',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 134.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E82E6BNE;D87C1RL9;C1KDZBV5;J36XHY2S;VH9H7N37;1KV9B668;EZY537C8;N4P383GN;JECNGX3P',
      statusContent: 'dot',
      hash: '2HRSY7DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HRSY7DA'
    },
    {
      status: 910,
      sku: 'PM0083507',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287616',
      rtl_batch_array: '6287616',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 23.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'PG4NXHN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PG4NXHN9'
    },
    {
      status: 910,
      sku: 'PM0083508',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287620',
      rtl_batch_array: '6287620',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '100120C20',
      rng_range_identifier: 'H100120C20',
      rng_range_description: 'H100 cm 120 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 30.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'NV7G5V99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NV7G5V99'
    },
    {
      status: 910,
      sku: 'PM0083509',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287621',
      rtl_batch_array: '6287621',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 23.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: 'HD87WDXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HD87WDXT'
    },
    {
      status: 810,
      sku: 'PM0083510',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287622',
      rtl_batch_array: '6287622',
      name_search: 'ACAISSAI',
      name: "Actinidia arguta 'Issai'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 3.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC3129JH;9G2Y6K9A;WVSP5PPA',
      statusContent: 'dot',
      hash: 'ZY9S5LLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY9S5LLB'
    },
    {
      status: 810,
      sku: 'PM0083511',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287623',
      rtl_batch_array: '6287623',
      name_search: 'ACDJENNY',
      name: "Actinidia deliciosa 'Jenny'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 3,
      sppl_prcp: 3.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K4E95RN;LY9ZSDKY;N5LXEHRX',
      statusContent: 'dot',
      hash: 'D2YG7SN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2YG7SN9'
    },
    {
      status: 810,
      sku: 'PM0083512',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287625',
      rtl_batch_array: '6287625',
      name_search: 'ACDJENNY',
      name: "Actinidia deliciosa 'Jenny'",
      sppl_size_code: '060080C20',
      rng_range_identifier: 'H060080C20',
      rng_range_description: 'H60 cm 80 cm C20',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 7.179,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K4E95RN;LY9ZSDKY;N5LXEHRX',
      statusContent: 'dot',
      hash: 'RVW8Y14X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVW8Y14X'
    },
    {
      status: 810,
      sku: 'PM0083513',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287626',
      rtl_batch_array: '6287626',
      name_search: 'CYOVRANJ',
      name: "Cydonia oblonga 'Vranja'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GZBS8BDL;JB33GSWW;Z8218G2H;H1X8TL9B;JBA2HWS7;78T4L18R;7966GTE7;EY7RJZ85;ED1R7C5H;4LWKN9W1;R7E945PE',
      statusContent: 'dot',
      hash: 'VVNW6Z8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVNW6Z8H'
    },
    {
      status: 910,
      sku: 'PM0083514',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287627',
      rtl_batch_array: '6287627',
      name_search: 'CYOVRANJ',
      name: "Cydonia oblonga 'Vranja'",
      sppl_size_code: '100150C12',
      rng_range_identifier: 'H100150C12',
      rng_range_description: 'H100 cm 150 cm C12',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 25.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GZBS8BDL;JB33GSWW;Z8218G2H;H1X8TL9B;JBA2HWS7;78T4L18R;7966GTE7;EY7RJZ85;ED1R7C5H;4LWKN9W1;R7E945PE',
      statusContent: 'dot',
      hash: 'RTL5ZAJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTL5ZAJR'
    },
    {
      status: 910,
      sku: 'PM0083515',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287629',
      rtl_batch_array: '6287629',
      name_search: 'FICARICA',
      name: 'Ficus carica',
      sppl_size_code: 'HAC12',
      rng_range_identifier: 'SHAC12',
      rng_range_description: 'Half stem C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 25.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1K8V1GKE;CRXZJDG5;6EV38D5P;9C6E57EL;73G9NP3L;C9N7J2N1;521WJST4;25551YND;J613PYAS;NS6HGTSR;235SL87D;8ALZSVE8;LKZ4ZL3J;ZRWE8G2C;D5EJ8XKV;DABTDTJK;W7H9XW4Y;KS3ZREEZ;W4RHSG5X;24AAKCAN;GSGTT48N;1NJTX78G;6ARW63ZA;WVYDD3SE;DSPTBZLV;RSK5PTHD',
      statusContent: 'dot',
      hash: '5J8GT92C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5J8GT92C'
    },
    {
      status: 910,
      sku: 'PM0083516',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287630',
      rtl_batch_array: '6287630',
      name_search: 'FICBTURK',
      name: "Ficus carica 'Brown Turkey'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 3.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DH7T8VNW;5YPR5G21;6H61HPED;4T289JRE;XN7L64H9;64GH657S;SDHBXYBS',
      statusContent: 'dot',
      hash: '89BNC3H5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89BNC3H5'
    },
    {
      status: 910,
      sku: 'PM0083517',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287632',
      rtl_batch_array: '6287632',
      name_search: 'MEGNOTTI',
      name: "Mespilus germanica 'Nottingham'",
      sppl_size_code: '100150C12',
      rng_range_identifier: 'H100150C12',
      rng_range_description: 'H100 cm 150 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 25.579,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJ18KGV7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJ18KGV7'
    },
    {
      status: 910,
      sku: 'PM0083518',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287633',
      rtl_batch_array: '6287633',
      name_search: 'MEGWESTE',
      name: "Mespilus germanica 'Westerveld'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4NWZ8KJ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NWZ8KJ6'
    },
    {
      status: 910,
      sku: 'PM0083519',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287634',
      rtl_batch_array: '6287634',
      name_search: 'PRABERGE',
      name: "Prunus armeniaca 'Bergeron'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 13.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GC1B3T2Y;8PP8XSE1;XDWSVBZR;4RTS1D6S;SV2R4AA5;GJ4YWB5B;VGBAHHSG',
      statusContent: 'dot',
      hash: '4HNK62DD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HNK62DD'
    },
    {
      status: 910,
      sku: 'PM0083520',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287635',
      rtl_batch_array: '6287635',
      name_search: 'PRABERGE',
      name: "Prunus armeniaca 'Bergeron'",
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 16.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GC1B3T2Y;8PP8XSE1;XDWSVBZR;4RTS1D6S;SV2R4AA5;GJ4YWB5B;VGBAHHSG',
      statusContent: 'dot',
      hash: 'PEHWZ4L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEHWZ4L6'
    },
    {
      status: 810,
      sku: 'PM0083521',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287636',
      rtl_batch_array: '6287636',
      name_search: 'PRAVIUM',
      name: 'Prunus avium',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 41,
      chnn_stock_retail: 41,
      sppl_prcp: 12.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NVZKRGJ5;KZGWTGZ1;98CXZCCP;L99WV59T;DZ9CAP9E;YV1LLRVA;XNE3RCPK;V2DZGJGB;T5TVEDW1;ATX6TKBX;HJCLGVNA;1BHP4TRH;74HTC5L7',
      statusContent: 'dot',
      hash: '6A1J619H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A1J619H'
    },
    {
      status: 810,
      sku: 'PM0083522',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287637',
      rtl_batch_array: '6287637',
      name_search: 'PRAVIUM',
      name: 'Prunus avium',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 15.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NVZKRGJ5;KZGWTGZ1;98CXZCCP;L99WV59T;DZ9CAP9E;YV1LLRVA;XNE3RCPK;V2DZGJGB;T5TVEDW1;ATX6TKBX;HJCLGVNA;1BHP4TRH;74HTC5L7',
      statusContent: 'dot',
      hash: '2D8WKPXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2D8WKPXV'
    },
    {
      status: 810,
      sku: 'PM0083523',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287638',
      rtl_batch_array: '6287638',
      name_search: 'PRDOMEST',
      name: 'Prunus domestica',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B25B8XCV;5VJ3LCST;AH4J627R;5ABWBZHH;6W8CH69L;DT77Z5WD;WJN7N1J1;4D435ZP6;GTVVV2A5;GCYJGL7G;DG4PXLBS;99P2YHVD;4R1SBAY2;KXP8XE1W;7YZEKTDB;R8PJALDC;5WT25VBD;E21G5TRK;71L638AL;G3G7N1TY;A3A5ETLE;ABALJBDH;LKGTT4HK;7TN7T4RZ;DH2JJA9A',
      statusContent: 'dot',
      hash: 'DYW36TNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYW36TNE'
    },
    {
      status: 810,
      sku: 'PM0083524',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287639',
      rtl_batch_array: '6287639',
      name_search: 'PRDOMEST',
      name: 'Prunus domestica',
      sppl_size_code: 'HAC74',
      rng_range_identifier: 'SHAC7.5',
      rng_range_description: 'Half stem C7.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 15.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B25B8XCV;5VJ3LCST;AH4J627R;5ABWBZHH;6W8CH69L;DT77Z5WD;WJN7N1J1;4D435ZP6;GTVVV2A5;GCYJGL7G;DG4PXLBS;99P2YHVD;4R1SBAY2;KXP8XE1W;7YZEKTDB;R8PJALDC;5WT25VBD;E21G5TRK;71L638AL;G3G7N1TY;A3A5ETLE;ABALJBDH;LKGTT4HK;7TN7T4RZ;DH2JJA9A',
      statusContent: 'dot',
      hash: '8WCDETA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WCDETA2'
    },
    {
      status: 810,
      sku: 'PM0083525',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287640',
      rtl_batch_array: '6287640',
      name_search: 'RIJOSTAB',
      name: 'Ribes Jostaberry',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AHZD5B2T;KZHLNVT9;511XL9RW;XBSB95GW;5XJC2KL2;G79431ER;HPTKYZA9;PHZ5HGEY;GP1PGL8D;BHPYH2CC;N5135B7J;N3A1GLK6;7XE7642B;DNAC5T73',
      statusContent: 'dot',
      hash: 'YDJDSBW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDJDSBW7'
    },
    {
      status: 810,
      sku: 'PM0083526',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287641',
      rtl_batch_array: '6287641',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: '2B1DYXSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B1DYXSX'
    },
    {
      status: 910,
      sku: 'PM0083527',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287642',
      rtl_batch_array: '6287642',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 20.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: 'V1V9R4B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1V9R4B3'
    },
    {
      status: 910,
      sku: 'PM0083528',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287643',
      rtl_batch_array: '6287643',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '090STC3',
      rng_range_identifier: 'STE090C3',
      rng_range_description: 'Stem 90 cm C3',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 9.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: '1K3ZD54K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K3ZD54K'
    },
    {
      status: 910,
      sku: 'PM0083529',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287645',
      rtl_batch_array: '6287645',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 20.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: '5G5JTNTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5G5JTNTT'
    },
    {
      status: 910,
      sku: 'PM0083530',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287646',
      rtl_batch_array: '6287646',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '090STC3',
      rng_range_identifier: 'STE090C3',
      rng_range_description: 'Stem 90 cm C3',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 9.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: '1YZHTDRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YZHTDRK'
    },
    {
      status: 810,
      sku: 'PM0083531',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287647',
      rtl_batch_array: '6287647',
      name_search: 'RIRWHOLL',
      name: "Ribes rubrum (white) 'Witte Hollander'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.633,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E7E22Z4',
      statusContent: 'dot',
      hash: 'K9RY2L4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9RY2L4K'
    },
    {
      status: 910,
      sku: 'PM0083532',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287649',
      rtl_batch_array: '6287649',
      name_search: 'RIUCHGRO',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gr\u00f6n',
      sppl_size_code: '090STC3',
      rng_range_identifier: 'STE090C3',
      rng_range_description: 'Stem 90 cm C3',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 9.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRH69A6W',
      statusContent: 'dot',
      hash: 'WYW9RKL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYW9RKL5'
    },
    {
      status: 210,
      sku: 'PM0083533',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287650',
      rtl_batch_array: '6287650',
      name_search: 'RIUCHROE',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki R\u00f6d',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECJGAL8T;H955H26G;V85N3L43;2Y2CY1AD;C8RL4TWB;B58HCCJS',
      statusContent: 'dot',
      hash: '6NBWGLDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NBWGLDX'
    },
    {
      status: 910,
      sku: 'PM0083534',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287651',
      rtl_batch_array: '6287651',
      name_search: 'RIUCHROE',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki R\u00f6d',
      sppl_size_code: '090STC3',
      rng_range_identifier: 'STE090C3',
      rng_range_description: 'Stem 90 cm C3',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 9.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECJGAL8T;H955H26G;V85N3L43;2Y2CY1AD;C8RL4TWB;B58HCCJS',
      statusContent: 'dot',
      hash: 'GAPJA7LC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAPJA7LC'
    },
    {
      status: 810,
      sku: 'PM0083535',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287652',
      rtl_batch_array: '6287652',
      name_search: 'RUFBSATI',
      name: "Rubus fruticosus 'Black Satin'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7WJTCG9A;7ZZY21TS;SZ1LV5N6;PL8SXPD4;LJ9JS2SE;G49K7BRZ;E29TKSN8;HWL1XVK6',
      statusContent: 'dot',
      hash: 'W8GGRYRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W8GGRYRS'
    },
    {
      status: 810,
      sku: 'PM0083536',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287653',
      rtl_batch_array: '6287653',
      name_search: 'RUFTEVER',
      name: "Rubus fruticosus 'Thornless Evergreen'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GDVL76YR;JYNSTL1S;JH6JBD75;CP52JXB9;6GW2GWA4;LKA2YTKC;X34P75LV',
      statusContent: 'dot',
      hash: 'YE41L2RH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YE41L2RH'
    },
    {
      status: 210,
      sku: 'PM0018175',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242116',
      rtl_batch_array: '6080591, 6301078, 6242116, 6348292',
      name_search: 'ATFFLRED',
      name: "Athyrium filix-femina 'Lady in Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 5980,
      sppl_order_minimum: 3,
      sppl_prcp: 0.737,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2VNSAYJ3;GD7EJ79S;SA13JT5V;9WJ94V5L;6K7ZE7E8;KWL21VL9;N2VNRY1B;58T7VDZT;78R8VWT4;Z1CPVNJY;7C3XCTEH;PGYPGA5S;1LXTBHBP',
      statusContent: 'dot',
      hash: 'JLJP2125',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLJP2125'
    },
    {
      status: 810,
      sku: 'PM0075622',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281089',
      rtl_batch_array: '6281089',
      name_search: 'SAHELVET',
      name: 'Salix helvetica',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TJ5JBW24;BT5S7K2P;2B9BCLRS;5CRLGZXN;WWGB29ZX;NVT3JN53;PJYPXYBG;TGP5D71N;YB5G5D43;1WN858EJ;YRHGJGSD;1TX6HN1V;WA9B981N;2XX2SB77;2C5WLHP1;2V8LGDC7;1BKAGWEZ;PLBXP6RJ;W7JWHBYW;TB7P1B8P;CXDPAJAT;YNGHH93W;WAPVE2B9',
      statusContent: 'dot',
      hash: 'T6PEWH39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T6PEWH39'
    },
    {
      status: 810,
      sku: 'PM0075614',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281080',
      rtl_batch_array: '6281080',
      name_search: 'PRTOMENT',
      name: 'Prunus tomentosa',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ARSTV5A9;RLSK65XK;67HVSCET;ALW6YVEH;4VEYDHAE;ZL26WG2G',
      statusContent: 'dot',
      hash: 'K6L7A447',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6L7A447'
    },
    {
      status: 810,
      sku: 'PM0075621',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281088',
      rtl_batch_array: '6281088',
      name_search: 'SAHELVET',
      name: 'Salix helvetica',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TJ5JBW24;BT5S7K2P;2B9BCLRS;5CRLGZXN;WWGB29ZX;NVT3JN53;PJYPXYBG;TGP5D71N;YB5G5D43;1WN858EJ;YRHGJGSD;1TX6HN1V;WA9B981N;2XX2SB77;2C5WLHP1;2V8LGDC7;1BKAGWEZ;PLBXP6RJ;W7JWHBYW;TB7P1B8P;CXDPAJAT;YNGHH93W;WAPVE2B9',
      statusContent: 'dot',
      hash: 'KRS8WY78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRS8WY78'
    },
    {
      status: 810,
      sku: 'PM0075615',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281081',
      rtl_batch_array: '6281081',
      name_search: 'RHCATHAR',
      name: 'Rhamnus cathartica',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.552,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XS7LHYE;B42JD4B2;LC374DCT;6GBV282T;8HZV1SA1;JD7RRX9A',
      statusContent: 'dot',
      hash: 'SENRC17D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SENRC17D'
    },
    {
      status: 810,
      sku: 'PM0075616',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281082',
      rtl_batch_array: '6281082',
      name_search: 'RHCATHAR',
      name: 'Rhamnus cathartica',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.082,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XS7LHYE;B42JD4B2;LC374DCT;6GBV282T;8HZV1SA1;JD7RRX9A',
      statusContent: 'dot',
      hash: 'LJ29VWHL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ29VWHL'
    },
    {
      status: 810,
      sku: 'PM0075617',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281083',
      rtl_batch_array: '6281083',
      name_search: 'RHCATHAR',
      name: 'Rhamnus cathartica',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.338,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6XS7LHYE;B42JD4B2;LC374DCT;6GBV282T;8HZV1SA1;JD7RRX9A',
      statusContent: 'dot',
      hash: 'BHZXWDH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BHZXWDH4'
    },
    {
      status: 810,
      sku: 'PM0075618',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281084',
      rtl_batch_array: '6281084',
      name_search: 'RHBLOOMB',
      name: 'Rhododendron Bloombux',
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N9TJXRGE;1EJ1EX7K;V3BRGSH2;R2DSYP47;6NCZL38N',
      statusContent: 'dot',
      hash: '5SYG7BTK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SYG7BTK'
    },
    {
      status: 810,
      sku: 'PM0075619',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281085',
      rtl_batch_array: '6281085',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 8.515,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'ADEGAWG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ADEGAWG6'
    },
    {
      status: 810,
      sku: 'PM0075620',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281087',
      rtl_batch_array: '6281087',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'DR9KNS55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR9KNS55'
    },
    {
      status: 210,
      sku: 'PM0024671',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6276284',
      rtl_batch_array: '6349053, 6229861, 6207786, 6276284',
      name_search: 'ASBRAUTS',
      name: "Astilbe (A) 'Brautschleier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 5822,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '64JLVWDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64JLVWDP'
    },
    {
      status: 210,
      sku: 'PM0083537',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287654',
      rtl_batch_array: '6287654',
      name_search: 'RUIABLIS',
      name: "Rubus idaeus 'Autumn Bliss'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ES4HWH45;GLVACG3E;4DWW7GHK',
      statusContent: 'dot',
      hash: 'CP1DELDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP1DELDE'
    },
    {
      status: 210,
      sku: 'PM0083538',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287655',
      rtl_batch_array: '6287655',
      name_search: 'RUIGEVER',
      name: "Rubus idaeus 'Golden Everest'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '84K2J8G4;XBN5SR5L;62C4XNGY;2B4X92GY;C4KAEGAB;WHE5YHNK;JLPVKL39;NGNZ99KH;RLGSTWSJ;Y682D6ST;R6SE8NG1;1R6B8EDB',
      statusContent: 'dot',
      hash: '27XR4ZB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27XR4ZB2'
    },
    {
      status: 210,
      sku: 'PM0083539',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287656',
      rtl_batch_array: '6287656',
      name_search: 'RUIMPROM',
      name: "Rubus idaeus 'Malling Promise'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZJV1BV9;WG8HXVEE;8D4DDK5A;LPB1B4T4;B3PJB7LS;J4ZL1SRS;XTXEBGWN',
      statusContent: 'dot',
      hash: 'DY12PC5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY12PC5N'
    },
    {
      status: 810,
      sku: 'PM0083540',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287657',
      rtl_batch_array: '6287657',
      name_search: 'RUIZHERB',
      name: "Rubus idaeus 'Zefa Herbsternte'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YXTAED1;16PDE5HY;WV56E1XZ;VRS77LYZ;ST1GT8BP;GBC9GVK1',
      statusContent: 'dot',
      hash: 'C2K7NWVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2K7NWVV'
    },
    {
      status: 210,
      sku: 'PM0018344',
      core_name: 'Plant',
      sppl_ean: '8720349478873',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '5363940',
      rtl_batch_array: '6301472, 5363940, 6290213',
      name_search: 'BRMVARIE',
      name: "Brunnera macrophylla 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 865,
      chnn_stock_retail: 2232,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C19LP6S4;JHGX8D28',
      statusContent: 'dot',
      hash: '9PH6XJS1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PH6XJS1'
    },
    {
      status: 210,
      sku: 'PM0018568',
      core_name: 'Plant',
      sppl_ean: '8720664504059',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5303650',
      rtl_batch_array: '5303650',
      name_search: 'CENMONTA',
      name: 'Centaurea montana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 946,
      chnn_stock_retail: 946,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z8SGWLY5;WWLPC2CG;RHARGH8J;ZWRGLVY2;X6TPA1YD;25YBY4ZY;KHAN9536;99367WPD;LNGPL6D1;GH8SD7B1;JN4LVBDZ;WV31YL8N;EHL6JH5T',
      statusContent: 'dot',
      hash: 'DD42TZ8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD42TZ8L'
    },
    {
      status: 210,
      sku: 'PM0021596',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6334765',
      rtl_batch_array: '6207827, 6158268, 5495193, 6334765',
      name_search: 'ASCPUMIL',
      name: "Astilbe chinensis 'Pumila'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4762,
      chnn_stock_retail: 29064,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTGRL2L8;VS1KXCB5;LSCASRAS;L46L8KDD;4WSVACL3;DE26T6ZR;K58EBYVK;AWBB8A9S;REH17Z7S;BZ51PK8D;CCZ34NGD',
      statusContent: 'dot',
      hash: 'G2JB47L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2JB47L8'
    },
    {
      status: 810,
      sku: 'PM0083541',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287658',
      rtl_batch_array: '6287658',
      name_search: 'RUPHOENI',
      name: 'Rubus phoenicolasius',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2P231P7N;DTTBSSCE;E23ZC7CK;VCJBAN7X;49YW3JGG;R83VYYRN;C5158JEG;DE7XZYRE;GA3NZT1H;PB22HCVC;2D2TG2ND;KVD43S6X;V7LVC19X;29AZ1JLJ;VNCVBEGT;D3R8P1DP;RXLGBPH1;D8WV9LWP;77HBPB6K',
      statusContent: 'dot',
      hash: '88ZS132D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '88ZS132D'
    },
    {
      status: 210,
      sku: 'PM0014078',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6242114',
      rtl_batch_array: '6084817, 6161496, 6242114',
      name_search: 'ATNRBEAU',
      name: "Athyrium niponicum 'Red Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 5892,
      sppl_order_minimum: 3,
      sppl_prcp: 0.737,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KP981RK6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KP981RK6'
    },
    {
      status: 210,
      sku: 'PM0032745',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 3,
      btch_active_retail: '6254664',
      rtl_batch_array: '5849637, 6254664, 6305217',
      name_search: 'BEBWHITE',
      name: "Bergenia 'Bressingham White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2157,
      chnn_stock_retail: 3248,
      sppl_prcp: 2.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KW8ER7G;BJB4BELL;V336GSGG;S9PN97KV',
      statusContent: 'dot',
      hash: 'N5413CGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5413CGK'
    },
    {
      status: 210,
      sku: 'PM0009101',
      core_name: 'Plant',
      sppl_ean: '8720664681842',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5363931',
      rtl_batch_array: '5363931',
      name_search: 'BEDAKISS',
      name: 'Bergenia Dragonfly Angel Kiss',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 236,
      chnn_stock_retail: 236,
      sppl_order_minimum: 3,
      sppl_prcp: 1.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8NEDHLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8NEDHLV'
    },
    {
      status: 210,
      sku: 'PM0004628',
      core_name: 'Plant',
      sppl_ean: '8720349409747',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '5481451',
      rtl_batch_array: '6368789, 5481451',
      name_search: 'ASSCHNEE',
      name: "Aster (D) 'Schneekissen'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 4511,
      chnn_stock_retail: 5511,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CNBYY43P;DTG661N8;RVCCBBHJ;9NHDAXB2;4J4ZCGC1',
      imageBatch: 'YA8ZK3AH',
      statusContent: 'dot',
      hash: 'SNB71N1S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNB71N1S'
    },
    {
      status: 210,
      sku: 'PM0007370',
      core_name: 'Plant',
      sppl_ean: '8720349410118',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5494225',
      rtl_batch_array: '5494225',
      name_search: 'ASCPUMIL',
      name: "Astilbe chinensis 'Pumila'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 10250,
      chnn_stock_retail: 10250,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTGRL2L8;VS1KXCB5;LSCASRAS;L46L8KDD;4WSVACL3;DE26T6ZR;K58EBYVK;AWBB8A9S;REH17Z7S;BZ51PK8D;CCZ34NGD',
      imageBatch: '6RZDXEN3',
      statusContent: 'dot',
      hash: 'T7A12XNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7A12XNX'
    },
    {
      status: 210,
      sku: 'PM0029303',
      core_name: 'Plant',
      sppl_ean: '8720353087672',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054456',
      rtl_batch_array: '6301451, 6054456',
      name_search: 'ASMDALEY',
      name: "Astilbe 'Maggie Daley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 152,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PL8C5PGD;WNZ5YSVL;Y3YS6YS9;8GYDE1C3',
      statusContent: 'dot',
      hash: 'TNL6W9J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNL6W9J7'
    },
    {
      status: 210,
      sku: 'PM0027665',
      core_name: 'Plant',
      sppl_ean: '8720664683693',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4729670',
      rtl_batch_array: '4729670',
      name_search: 'CAMVICE',
      name: 'Carex morrowii Vanilla Ice',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 4.208,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '6EBB8WZS',
      statusContent: 'dot',
      hash: 'YGLBVRN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGLBVRN4'
    },
    {
      status: 210,
      sku: 'PM0003635',
      core_name: 'Plant',
      sppl_ean: '8720626337343',
      btch_manufacturer: 159,
      rtl_batch_array_total: 2,
      btch_active_retail: '5495559',
      rtl_batch_array: '6282358, 5495559',
      name_search: 'BUDBTOWE',
      name: 'Buddleja davidii Butterfly Tower',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 700,
      sppl_prcp: 3.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9ABKZR6;EZSGR297;VXSHTZNK;KXR16RH7;ZSZC45W4;S57Z36AG',
      statusContent: 'dot',
      hash: 'ZGRNKE5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGRNKE5K'
    },
    {
      status: 810,
      sku: 'PM0083542',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287660',
      rtl_batch_array: '6287660',
      name_search: 'SANHASCH',
      name: "Sambucus nigra 'Haschberg'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NG59WL4G',
      statusContent: 'dot',
      hash: 'NDK9N9PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NDK9N9PY'
    },
    {
      status: 910,
      sku: 'PM0083543',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287661',
      rtl_batch_array: '6287661',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '100125C20',
      rng_range_identifier: 'H100125C20',
      rng_range_description: 'H100 cm 125 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 47.209,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: 'DR9JLPCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR9JLPCX'
    },
    {
      status: 910,
      sku: 'PM0083544',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287662',
      rtl_batch_array: '6287662',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_order_minimum: 3,
      sppl_prcp: 3.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: 'ARSPDEG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARSPDEG2'
    },
    {
      status: 910,
      sku: 'PM0083545',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287663',
      rtl_batch_array: '6287663',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '175200C12',
      rng_range_identifier: 'H175200C12',
      rng_range_description: 'H175 cm 200 cm C12',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 27.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: 'S4KEG1BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4KEG1BN'
    },
    {
      status: 810,
      sku: 'PM0083546',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287664',
      rtl_batch_array: '6287664',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '175200C12',
      rng_range_identifier: 'H175200C12',
      rng_range_description: 'H175 cm 200 cm C12',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_prcp: 27.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: 'X5E777L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5E777L2'
    },
    {
      status: 210,
      sku: 'PM0083547',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6287673',
      rtl_batch_array: '6287673, 6348315',
      name_search: 'EPRUBRUM',
      name: 'Epimedium rubrum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 870,
      chnn_stock_retail: 5348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CJ1NYS7A',
      statusContent: 'dot',
      hash: 'BZVC22J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZVC22J8'
    },
    {
      status: 910,
      sku: 'PM0083548',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287700',
      rtl_batch_array: '6287700',
      name_search: 'VEBLOLLI',
      name: "Verbena bonariensis 'Lollipop'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 3.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GVJLSHW;GKVZJA6N;ZA1BDD7V;LS7KZHLC;E1EK8TCG;L3LS49D2;8L9ZD89X;JERE7SHP;SLYE773K;GNZC7VRW;S453G263;CPPL5L76',
      statusContent: 'dot',
      hash: 'LL7PHJJS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL7PHJJS'
    },
    {
      status: 910,
      sku: 'PM0083549',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      rng_range_identifier: 'SHI010012C35',
      rng_range_description: 'High stem 10 cm 12 cm C35',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: 'V6DTJV9A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V6DTJV9A'
    },
    {
      status: 210,
      sku: 'PM0083550',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300709',
      rtl_batch_array: '6300709',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 211,
      chnn_stock_retail: 211,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'C29E1CE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C29E1CE7'
    },
    {
      status: 210,
      sku: 'PM0083551',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300710',
      rtl_batch_array: '6300710',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2742,
      chnn_stock_retail: 2742,
      sppl_order_minimum: 3,
      sppl_prcp: 0.542,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'X1KPVHJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1KPVHJX'
    },
    {
      status: 210,
      sku: 'PM0083552',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300712',
      rtl_batch_array: '6300712',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2748,
      chnn_stock_retail: 2748,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: '1ZNW41W4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZNW41W4'
    },
    {
      status: 910,
      sku: 'PM0083553',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300713',
      rtl_batch_array: '6300713',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 58.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'SJV5JD3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJV5JD3S'
    },
    {
      status: 910,
      sku: 'PM0083554',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300714',
      rtl_batch_array: '6300714',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '150175C30',
      rng_range_identifier: 'H150175C30',
      rng_range_description: 'H150 cm 175 cm C30',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 17.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'G2Y27D63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2Y27D63'
    },
    {
      status: 910,
      sku: 'PM0083555',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300715',
      rtl_batch_array: '6300715',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '200225C70',
      rng_range_identifier: 'H200225C70',
      rng_range_description: 'H200 cm 225 cm C70',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 47.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'C148RBZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C148RBZB'
    },
    {
      status: 910,
      sku: 'PM0083556',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300716',
      rtl_batch_array: '6300716',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 16.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'GEA33VVV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GEA33VVV'
    },
    {
      status: 910,
      sku: 'PM0083557',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300717',
      rtl_batch_array: '6300717',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 54.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'BZ6W1E79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ6W1E79'
    },
    {
      status: 810,
      sku: 'PM0083558',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300720',
      rtl_batch_array: '6300720',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 26.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'YJZRBKC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJZRBKC6'
    },
    {
      status: 810,
      sku: 'PM0083559',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300721',
      rtl_batch_array: '6300721',
      name_search: 'CUSPYRAM',
      name: "Cupressus sempervirens 'Pyramidalis'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 20.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XLN2EZ2N',
      statusContent: 'dot',
      hash: 'HHLP6LYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHLP6LYK'
    },
    {
      status: 910,
      sku: 'PM0083560',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300722',
      rtl_batch_array: '6300722',
      name_search: 'CUSPYRAM',
      name: "Cupressus sempervirens 'Pyramidalis'",
      sppl_size_code: '200225C30',
      rng_range_identifier: 'H200225C30',
      rng_range_description: 'H200 cm 225 cm C30',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XLN2EZ2N',
      statusContent: 'dot',
      hash: 'BY1XESAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY1XESAD'
    },
    {
      status: 210,
      sku: 'PM0083561',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300727',
      rtl_batch_array: '6300727',
      name_search: 'EUFEGOLD',
      name: "Euonymus fortunei 'Emerald 'n' Gold'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1034,
      chnn_stock_retail: 1034,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5XWZXYCE;XX1AAW3P;AVK4LTXX;ABHS9PVA;9JLG91NS;ZA7VK2DD;P9AA6WWT;57TDA1G8;RHPSYBTV;SWT7KYDC;Z93Y1YAC;G1EC45TG;V42VE62H;2V46BXZN;YEDB82ST;C2VYTDD1;HG2J9ZR2;YPTB7YWS;JNA8L55R;TSE1WV1J;6WCAA43J;KJ59TDBA;NKZ73Z74',
      statusContent: 'dot',
      hash: 'PZTW5GAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZTW5GAL'
    },
    {
      status: 210,
      sku: 'PM0083562',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300729',
      rtl_batch_array: '6300729',
      name_search: 'EUFEGAIE',
      name: "Euonymus fortunei 'Emerald Gaiety'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 970,
      chnn_stock_retail: 970,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NPSTJPGN;CP8S14TZ;B3ZJ6AC8;G9BA5BZ6;BYZAK44B;XC43X47W;6W9VGYPT;BWVPVNWG;GBAZ47H4;G4WJ9R9C;618TYKYD;25JA73ZN;GJV1GA8L;9TCYLP7X;VSXYSPDA;2ZJ6VDCD;6YL119WW;213AVW89;YKVY6EYC',
      statusContent: 'dot',
      hash: 'S55PT7CP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S55PT7CP'
    },
    {
      status: 210,
      sku: 'PM0083563',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300730',
      rtl_batch_array: '6300730, 6356621',
      name_search: 'EUFMINIM',
      name: "Euonymus fortunei 'Minimus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 3900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDL3HXLK;6EHP8PK6;W6KV7BGR;5XRTS6G4;4DALEPTE;1JPXWJ7K;KS11S8J6;AAES5KR6;1TBHNWB9;G49S5AKV',
      statusContent: 'dot',
      hash: '549PPEPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '549PPEPZ'
    },
    {
      status: 210,
      sku: 'PM0083564',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300731',
      rtl_batch_array: '6300731',
      name_search: 'EUFMINIM',
      name: "Euonymus fortunei 'Minimus'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 747,
      chnn_stock_retail: 747,
      sppl_order_minimum: 3,
      sppl_prcp: 0.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDL3HXLK;6EHP8PK6;W6KV7BGR;5XRTS6G4;4DALEPTE;1JPXWJ7K;KS11S8J6;AAES5KR6;1TBHNWB9;G49S5AKV',
      statusContent: 'dot',
      hash: '4DWZAWDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DWZAWDX'
    },
    {
      status: 210,
      sku: 'PM0083565',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300732',
      rtl_batch_array: '6300732',
      name_search: 'EUFRADIC',
      name: 'Euonymus fortunei radicans',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1932,
      chnn_stock_retail: 1932,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6BCLLZAW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BCLLZAW'
    },
    {
      status: 210,
      sku: 'PM0083566',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300734',
      rtl_batch_array: '6300734',
      name_search: 'EUJMICRO',
      name: "Euonymus japonicus 'Microphyllus'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 597,
      chnn_stock_retail: 597,
      sppl_order_minimum: 3,
      sppl_prcp: 0.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7TCCLEB;V59GN3RX;CRJBAARW;7SYD41S7;SXLDXYRT;CH2T2NVE;P32AK36A;1NT8E3D2;85JEYV2G;EXTSCZY1;2356KPR1',
      statusContent: 'dot',
      hash: 'BA73HCWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BA73HCWA'
    },
    {
      status: 910,
      sku: 'PM0083567',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GLTSUNBU',
      name: "Gleditsia triacanthos 'Sunburst'",
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore: 'KSPHLSDZ;K2GZTXTE;2P36HKAH',
      statusContent: 'dot',
      hash: '6DAPVZJ1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6DAPVZJ1'
    },
    {
      status: 210,
      sku: 'PM0083568',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300737',
      rtl_batch_array: '6300737',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 4872,
      chnn_stock_retail: 4872,
      sppl_order_minimum: 3,
      sppl_prcp: 0.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: 'E2EKTV4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2EKTV4J'
    },
    {
      status: 210,
      sku: 'PM0083569',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300740',
      rtl_batch_array: '6300740',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5095,
      chnn_stock_retail: 5095,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'T7KBSEXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7KBSEXY'
    },
    {
      status: 210,
      sku: 'PM0083570',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300741',
      rtl_batch_array: '6300741',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2919,
      chnn_stock_retail: 2919,
      sppl_order_minimum: 3,
      sppl_prcp: 1.275,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'XJWWWVEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJWWWVEP'
    },
    {
      status: 210,
      sku: 'PM0083571',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300744',
      rtl_batch_array: '6300744',
      name_search: 'HYASANNA',
      name: 'Hydrangea arborescens Strong Annabelle',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_prcp: 6.192,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6WESVPSZ;8S94HRR7;EW3Z6NPE;DW2PR9BP;PPAKRZVE;55NPDE9T;575TA36W;772K6D22;TS72Y5SD;JW5PHY85;EKWLYTBA;D389C7VL;XTSCRK29',
      statusContent: 'dot',
      hash: 'S4P3YJBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4P3YJBA'
    },
    {
      status: 210,
      sku: 'PM0083572',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300745',
      rtl_batch_array: '6300745',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: '7TGTCPHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TGTCPHW'
    },
    {
      status: 210,
      sku: 'PM0083573',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300747',
      rtl_batch_array: '6300747',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_prcp: 5.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NKR4ZPZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKR4ZPZE'
    },
    {
      status: 210,
      sku: 'PM0083574',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300751',
      rtl_batch_array: '6300751',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1987,
      chnn_stock_retail: 1987,
      sppl_order_minimum: 3,
      sppl_prcp: 1.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'CV4ZDZWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CV4ZDZWS'
    },
    {
      status: 910,
      sku: 'PM0083575',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300752',
      rtl_batch_array: '6300752',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 10.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'LEC3K41L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEC3K41L'
    },
    {
      status: 910,
      sku: 'PM0083576',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300753',
      rtl_batch_array: '6300753',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'CSZ2RAKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CSZ2RAKK'
    },
    {
      status: 810,
      sku: 'PM0083577',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300754',
      rtl_batch_array: '6300754',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '040060C12',
      rng_range_identifier: 'H040060C12',
      rng_range_description: 'H40 cm 60 cm C12',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 22.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'X2VNK7P5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2VNK7P5'
    },
    {
      status: 210,
      sku: 'PM0083578',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300755',
      rtl_batch_array: '6300755',
      name_search: 'HYQALICE',
      name: "Hydrangea quercifolia 'Alice'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1477,
      chnn_stock_retail: 1477,
      sppl_prcp: 4.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9766V5DT;XJ1NN636',
      statusContent: 'dot',
      hash: 'Z9TEK4NR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z9TEK4NR'
    },
    {
      status: 210,
      sku: 'PM0083579',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300756',
      rtl_batch_array: '6300756',
      name_search: 'HYQAMETH',
      name: "Hydrangea quercifolia 'Amethyst'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 4.792,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R5KW1HAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5KW1HAV'
    },
    {
      status: 210,
      sku: 'PM0083580',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300757',
      rtl_batch_array: '6300757',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1884,
      chnn_stock_retail: 1884,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PWVY4RLG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWVY4RLG'
    },
    {
      status: 210,
      sku: 'PM0083581',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300758',
      rtl_batch_array: '6300758',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5864,
      chnn_stock_retail: 5864,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'DK2BC2GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DK2BC2GE'
    },
    {
      status: 210,
      sku: 'PM0083582',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300759',
      rtl_batch_array: '6300759',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2160,
      chnn_stock_retail: 2160,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'WRY125BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRY125BK'
    },
    {
      status: 210,
      sku: 'PM0083583',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300760',
      rtl_batch_array: '6300760',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1009,
      chnn_stock_retail: 1009,
      sppl_order_minimum: 3,
      sppl_prcp: 1.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'RC4TWTKW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC4TWTKW'
    },
    {
      status: 210,
      sku: 'PM0083584',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300761',
      rtl_batch_array: '6300761',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 926,
      chnn_stock_retail: 926,
      sppl_order_minimum: 3,
      sppl_prcp: 1.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AZTY1L18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZTY1L18'
    },
    {
      status: 210,
      sku: 'PM0083585',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300762',
      rtl_batch_array: '6300762',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 433,
      chnn_stock_retail: 433,
      sppl_prcp: 3.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RP1CGAP3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RP1CGAP3'
    },
    {
      status: 910,
      sku: 'PM0083586',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILNRSTEV',
      name: "Ilex 'Nellie R. Stevens'",
      rng_range_identifier: 'SHI008010C30',
      rng_range_description: 'High stem 8 cm 10 cm C30',
      statusContent: 'dot',
      hash: '2EJZRSJX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2EJZRSJX'
    },
    {
      status: 910,
      sku: 'PM0083587',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'KOPANICU',
      name: 'Koelreuteria paniculata',
      rng_range_identifier: 'SHI008010C30',
      rng_range_description: 'High stem 8 cm 10 cm C30',
      imageCore:
        '6CN869WC;ZJZ3947S;4TL284TG;2DLRN156;EXCHV9NH;YVRTYP4V;GSL7TP2Y;2JK6YZ2P;KX5GV9HV;L17656SV;LL8TYAHB',
      statusContent: 'dot',
      hash: 'EJ1C9ZN9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EJ1C9ZN9'
    },
    {
      status: 910,
      sku: 'PM0083588',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIJAPONI',
      name: 'Ligustrum japonicum',
      rng_range_identifier: 'SHI010012C35',
      rng_range_description: 'High stem 10 cm 12 cm C35',
      statusContent: 'dot',
      hash: 'H25HSY77',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H25HSY77'
    },
    {
      status: 210,
      sku: 'PM0083589',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300770',
      rtl_batch_array: '6300770',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 4159,
      chnn_stock_retail: 4159,
      sppl_order_minimum: 3,
      sppl_prcp: 0.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'GPGGL92Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPGGL92Z'
    },
    {
      status: 210,
      sku: 'PM0083590',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300771',
      rtl_batch_array: '6300771',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 489,
      chnn_stock_retail: 489,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9B4KY6W2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B4KY6W2'
    },
    {
      status: 910,
      sku: 'PM0083591',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'AB9KCJNA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AB9KCJNA'
    },
    {
      status: 910,
      sku: 'PM0083592',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LITEGURS',
      name: "Liriodendron tulipifera 'Edward Gursztyn'",
      rng_range_identifier: 'SHI010012C45',
      rng_range_description: 'High stem 10 cm 12 cm C45',
      statusContent: 'dot',
      hash: 'ZL4YN1AV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZL4YN1AV'
    },
    {
      status: 210,
      sku: 'PM0083593',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300774',
      rtl_batch_array: '6300774',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5509,
      chnn_stock_retail: 5509,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'WW1PZZK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WW1PZZK5'
    },
    {
      status: 210,
      sku: 'PM0083594',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300776',
      rtl_batch_array: '6300776',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 4109,
      chnn_stock_retail: 4109,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'LN19YZ8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LN19YZ8W'
    },
    {
      status: 210,
      sku: 'PM0083595',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300777',
      rtl_batch_array: '6300777',
      name_search: 'LONEWILS',
      name: "Lonicera nitida 'Ernest Wilson'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G5BATS9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G5BATS9W'
    },
    {
      status: 210,
      sku: 'PM0083596',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300778',
      rtl_batch_array: '6300778',
      name_search: 'LONHFIND',
      name: "Lonicera nitida 'Hohenheimer Findling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3619,
      chnn_stock_retail: 3619,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVSH35RC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVSH35RC'
    },
    {
      status: 210,
      sku: 'PM0083597',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300779',
      rtl_batch_array: '6317929, 6300779',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 18420,
      chnn_stock_retail: 19092,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'R4XGB282',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4XGB282'
    },
    {
      status: 210,
      sku: 'PM0083598',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300782',
      rtl_batch_array: '6300782',
      name_search: 'LOPMGREE',
      name: "Lonicera pileata 'Moss Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3107,
      chnn_stock_retail: 3107,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '73JD518G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73JD518G'
    },
    {
      status: 210,
      sku: 'PM0083599',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300783',
      rtl_batch_array: '6300783',
      name_search: 'LOPMGREE',
      name: "Lonicera pileata 'Moss Green'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3135,
      chnn_stock_retail: 3135,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9EHN74WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EHN74WA'
    },
    {
      status: 210,
      sku: 'PM0083600',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300784',
      rtl_batch_array: '6300784',
      name_search: 'NADOMEST',
      name: 'Nandina domestica',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VD8H147C;BLZ2GW1V;CL276JTD;N2EPWKKW;J2S57PCB;47P9JE8W;HE8J2D6E;LCZ9E9SR;VJ7T2E41;XA2GVAD8;XGCGLADH;J2PXJC5V;E81ESH4G;S9BJX1AE;86GSB4H1',
      statusContent: 'dot',
      hash: '236HGV2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '236HGV2Y'
    },
    {
      status: 210,
      sku: 'PM0083601',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300785',
      rtl_batch_array: '6300785',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 747,
      chnn_stock_retail: 747,
      sppl_prcp: 6.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'GAYSGYBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GAYSGYBG'
    },
    {
      status: 910,
      sku: 'PM0083602',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PATOMENT',
      name: 'Paulownia tomentosa',
      rng_range_identifier: 'SHI012014C45',
      rng_range_description: 'High stem 12 cm 14 cm C45',
      statusContent: 'dot',
      hash: '22X7EB4V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '22X7EB4V'
    },
    {
      status: 210,
      sku: 'PM0083603',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300789',
      rtl_batch_array: '6300789',
      name_search: 'PEBSPIRE',
      name: "Perovskia 'Blue Spire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 3.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z688AVDJ;6D3RW64C;H5EXNKVW;BN63KKBP;JV7JNEHR;PX98XVNZ;9HC8CSZY;R6B1NEEG',
      statusContent: 'dot',
      hash: 'EKYPT2SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKYPT2SK'
    },
    {
      status: 210,
      sku: 'PM0083604',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300790',
      rtl_batch_array: '6300790',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '175200C30',
      rng_range_identifier: 'H175200C30',
      rng_range_description: 'H175 cm 200 cm C30',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_prcp: 66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'RTY9DV52',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTY9DV52'
    },
    {
      status: 910,
      sku: 'PM0083605',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300791',
      rtl_batch_array: '6300791',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: 'HAC35',
      rng_range_identifier: 'SHAC35',
      rng_range_description: 'Half stem C35',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_prcp: 77.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'KGDKCN5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGDKCN5E'
    },
    {
      status: 810,
      sku: 'PM0083606',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300792',
      rtl_batch_array: '6300792',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: 'HAC18',
      rng_range_identifier: 'SHAC18',
      rng_range_description: 'Half stem C18',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 40.209,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'P6AEDPE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6AEDPE2'
    },
    {
      status: 910,
      sku: 'PM0083607',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: '1JXJVPXJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1JXJVPXJ'
    },
    {
      status: 210,
      sku: 'PM0083608',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300794',
      rtl_batch_array: '6300794',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1415,
      chnn_stock_retail: 1415,
      sppl_prcp: 3.342,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'KVJ9TDPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVJ9TDPB'
    },
    {
      status: 210,
      sku: 'PM0083609',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300795',
      rtl_batch_array: '6300795',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 266,
      chnn_stock_retail: 266,
      sppl_prcp: 8.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'XYH3DDR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYH3DDR7'
    },
    {
      status: 210,
      sku: 'PM0083610',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300798',
      rtl_batch_array: '6300798',
      name_search: 'PYCRCOLU',
      name: "Pyracantha coccinea 'Red Column'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 588,
      chnn_stock_retail: 588,
      sppl_order_minimum: 3,
      sppl_prcp: 1.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B18HB2C9;5ZAV8GNX',
      statusContent: 'dot',
      hash: 'T4WCY848',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4WCY848'
    },
    {
      status: 210,
      sku: 'PM0083611',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300799',
      rtl_batch_array: '6300799',
      name_search: 'PYGCHARM',
      name: "Pyracantha 'Golden Charmer'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 591,
      chnn_stock_retail: 591,
      sppl_order_minimum: 3,
      sppl_prcp: 1.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'STPN5K3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STPN5K3G'
    },
    {
      status: 210,
      sku: 'PM0083612',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300800',
      rtl_batch_array: '6300800',
      name_search: 'PYOGLOW',
      name: "Pyracantha 'Orange Glow'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 558,
      chnn_stock_retail: 558,
      sppl_order_minimum: 3,
      sppl_prcp: 1.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBYDG2VB;JEBSXAXG',
      statusContent: 'dot',
      hash: 'LDZC1KEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDZC1KEB'
    },
    {
      status: 910,
      sku: 'PM0083613',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PYSPENDU',
      name: "Pyrus salicifolia 'Pendula'",
      rng_range_identifier: 'SHI008010C30',
      rng_range_description: 'High stem 8 cm 10 cm C30',
      imageCore: 'PCBT1BD8;2XT5J6LW;68X5HLAW;RZ7D7HW3;L5Y7NJX3',
      statusContent: 'dot',
      hash: 'GXJG5648',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GXJG5648'
    },
    {
      status: 810,
      sku: 'PM0083614',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300802',
      rtl_batch_array: '6300802',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 12.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'APG6T9BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APG6T9BC'
    },
    {
      status: 910,
      sku: 'PM0083615',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300803',
      rtl_batch_array: '6300803',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '125150C12',
      rng_range_identifier: 'H125150C12',
      rng_range_description: 'H125 cm 150 cm C12',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 22.379,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: '5ADV2JZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ADV2JZR'
    },
    {
      status: 810,
      sku: 'PM0083616',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300804',
      rtl_batch_array: '6300804',
      name_search: 'RHCGRAND',
      name: "Rhododendron 'Catawbiense Grandiflorum'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 7.443,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2R52D3X;DE685APW;AHXH6YP7;J57LVSDY;7E2BHGYD;VRLZXG77;DL89ZPL9;KSCVVP8T',
      statusContent: 'dot',
      hash: '83W4XN6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83W4XN6G'
    },
    {
      status: 210,
      sku: 'PM0083617',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300805',
      rtl_batch_array: '6300805',
      name_search: 'RHCWHITE',
      name: "Rhododendron 'Cunningham's White'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.443,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8AC4JS25;SWVWBH2X;VZ25X36G;GEVLVHV8;H5T4PASX;KRN7ZGL9;B9JT2BV5;521PALYN;8H1GBA56;6NG6JG81;2ZA1STN5;JZAG5576;KCZSJ34X;LLDCNXT3',
      statusContent: 'dot',
      hash: 'KJ51N6GA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJ51N6GA'
    },
    {
      status: 210,
      sku: 'PM0083618',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300806',
      rtl_batch_array: '6300806',
      name_search: 'RHNZEMBL',
      name: "Rhododendron 'Nova Zembla'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.443,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8W5HCK2;WJXE8SLB;SGCKADSC;WRL37ATL;DGRX79TT;PDG54PVP;Z12H4ZXV;DA3SARXP;1E5VY5PV;DP7C1Y48;JLHDNADR;PTK91ZHL;YSTT6N8J;4Y4N6B4R;J7D4VJPT;WA4SCZTX;STTVGY2Z;VSAX114H;KBJD5H1B',
      statusContent: 'dot',
      hash: 'N18W3HH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N18W3HH1'
    },
    {
      status: 210,
      sku: 'PM0083619',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300807',
      rtl_batch_array: '6300807',
      name_search: 'RHPONTIC',
      name: 'Rhododendron ponticum',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 7.443,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RV67H1BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RV67H1BN'
    },
    {
      status: 210,
      sku: 'PM0083620',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300808',
      rtl_batch_array: '6300808',
      name_search: 'RISKEDWA',
      name: "Ribes sanguineum 'King Edward VII'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.592,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XYLCDXEP;LK1NA58A;NTWSZB3A;15DNZ3XT;7J2G6HS8;1LARHG1X;9NYG9GAB;R1WCTVS9;Z8A3E92K;WN23H1L8;NBE3ABNJ;5GYLHY2T',
      statusContent: 'dot',
      hash: 'T3AK87CJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3AK87CJ'
    },
    {
      status: 910,
      sku: 'PM0083621',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SASCHRYS',
      name: "Salix sepulcralis 'Chrysocoma'",
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore: 'H6LB6WJY;TR617KHD;BL79PKPT;WXLALEW6',
      statusContent: 'dot',
      hash: 'T79G2PH4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T79G2PH4'
    },
    {
      status: 210,
      sku: 'PM0083622',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300810',
      rtl_batch_array: '6300810',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 139,
      chnn_stock_retail: 139,
      sppl_prcp: 13.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'HGLJGLT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGLJGLT7'
    },
    {
      status: 910,
      sku: 'PM0083623',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300811',
      rtl_batch_array: '6300811',
      name_search: 'ACPKATSU',
      name: "Acer palmatum 'Katsura'",
      sppl_size_code: '200250C35',
      rng_range_identifier: 'H200250C35',
      rng_range_description: 'H200 cm 250 cm C35',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 172.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RCXWZLWG;T5TXTNSS;L2PLA6H2;A6JH3V71;TRL1LTA3',
      statusContent: 'dot',
      hash: '4Z471HBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z471HBP'
    },
    {
      status: 910,
      sku: 'PM0083624',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300812',
      rtl_batch_array: '6300812',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '200250C50',
      rng_range_identifier: 'H200250C50',
      rng_range_description: 'H200 cm 250 cm C50',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 262,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'NJA4YJ9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJA4YJ9S'
    },
    {
      status: 910,
      sku: 'PM0083625',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300813',
      rtl_batch_array: '6300813',
      name_search: 'ACPSANGO',
      name: "Acer palmatum 'Sangokaku'",
      sppl_size_code: '200250C35',
      rng_range_identifier: 'H200250C35',
      rng_range_description: 'H200 cm 250 cm C35',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 172.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6NCWN8CJ;W65S91ZJ;SKKAHEP1;ED84PLEL;JWH1N5ZK;LA24Z8AL;LE4P41HC;DNXG364B;2E2NYBTN',
      statusContent: 'dot',
      hash: 'XKKZ4ZJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKKZ4ZJD'
    },
    {
      status: 810,
      sku: 'PM0075623',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281090',
      rtl_batch_array: '6281090',
      name_search: 'SANBTOWE',
      name: 'Sambucus nigra Black Tower',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 49.935,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '443DVLWA;CYXXJ6E8;KV2A5HYK;BCV14524;D5EY9XN9;G34YXXSK;D6398STY;HL5TN4T1;1B1446B7;1B5DH2ZJ;TYG49Z6A;XGVKGY5V;ZPZYXXCE',
      statusContent: 'dot',
      hash: 'WVHN3KWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVHN3KWN'
    },
    {
      status: 210,
      sku: 'PM0011464',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284481',
      rtl_batch_array: '5837915, 6284481',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 7768,
      chnn_stock_retail: 22768,
      sppl_order_minimum: 3,
      sppl_prcp: 0.963,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: 'H56VLNT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H56VLNT7'
    },
    {
      status: 910,
      sku: 'PM0083626',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMARHILL',
      name: "Amelanchier arborea 'Robin Hill'",
      rng_range_identifier: 'SHI008010C30',
      rng_range_description: 'High stem 8 cm 10 cm C30',
      imageCore: 'YYR88H22;2ARDZSYG;RXLRAEB4;TGB28SBH;6KVDAN7G',
      statusContent: 'dot',
      hash: 'N62T956A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N62T956A'
    },
    {
      status: 910,
      sku: 'PM0083627',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMCGLENN',
      name: "Amelanchier canadensis 'Glennform'",
      rng_range_identifier: 'SHI010012C35',
      rng_range_description: 'High stem 10 cm 12 cm C35',
      statusContent: 'dot',
      hash: 'K659YZJE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K659YZJE'
    },
    {
      status: 210,
      sku: 'PM0083628',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300819',
      rtl_batch_array: '6300819',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: 'SN2WC9Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SN2WC9Y8'
    },
    {
      status: 210,
      sku: 'PM0083629',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300820',
      rtl_batch_array: '6300820',
      name_search: 'BUDNWHIT',
      name: "Buddleja davidii 'Nanho White'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 199,
      chnn_stock_retail: 199,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4THBB9DR;BBG2A6EY;1CWXJR8E;ZP1YEJ94',
      statusContent: 'dot',
      hash: '22Y2WBLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22Y2WBLR'
    },
    {
      status: 210,
      sku: 'PM0083630',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300821',
      rtl_batch_array: '6300821',
      name_search: 'BUFPBHEA',
      name: 'Buddleja Free Petite Blue Heaven',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRGYB1XG;6W23LEGC;AJ55KEY1;CLPSGHX5',
      statusContent: 'dot',
      hash: '6GNYWC6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GNYWC6E'
    },
    {
      status: 210,
      sku: 'PM0083697',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321572',
      rtl_batch_array: '6321572',
      name_search: 'ALNIGRUM',
      name: 'Allium nigrum',
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 988,
      chnn_stock_retail: 988,
      sppl_prcp: 0.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G2H89JRE;R6ZDH2WV;9G38N8AD;1KSB86KW;CZZDNLTN;1PDNEBX4;5H6K34DB;K5ZXX2C8',
      statusContent: 'dot',
      hash: 'DA21KBAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA21KBAP'
    },
    {
      status: 810,
      sku: 'PM0075625',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6283855',
      rtl_batch_array: '6281092, 6283855',
      name_search: 'SARACEMO',
      name: 'Sambucus racemosa',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 59,
      chnn_stock_retail: 109,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7CGBWT5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CGBWT5C'
    },
    {
      status: 210,
      sku: 'PM0083700',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321580',
      rtl_batch_array: '6321580',
      name_search: 'CRREMEMB',
      name: 'Crocus Remembrance (LF)',
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PSS8P2WW;4NY2BZWH;43ZJ6HJK;2WZGGP69;6YNKAJTX;9BNG3NWP;RSVXDTSJ;76WEZX8C;VEH1YPNL;28BJJKRW',
      statusContent: 'dot',
      hash: 'PJYB69XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJYB69XW'
    },
    {
      status: 810,
      sku: 'PM0075627',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281094',
      rtl_batch_array: '6281094',
      name_search: 'SARACEMO',
      name: 'Sambucus racemosa',
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HWDV3JJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWDV3JJ3'
    },
    {
      status: 810,
      sku: 'PM0075628',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281095',
      rtl_batch_array: '6281095',
      name_search: 'SARACEMO',
      name: 'Sambucus racemosa',
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 32.842,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6JN4E3GE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JN4E3GE'
    },
    {
      status: 810,
      sku: 'PM0075629',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281096',
      rtl_batch_array: '6281096',
      name_search: 'SOTORMIN',
      name: 'Sorbus torminalis',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EW6H97VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EW6H97VW'
    },
    {
      status: 810,
      sku: 'PM0083631',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300822',
      rtl_batch_array: '6300822',
      name_search: 'BUDFPDPI',
      name: 'Buddleja Free Petite Dark Pink',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 91,
      chnn_stock_retail: 91,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A2GZAH5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2GZAH5W'
    },
    {
      status: 810,
      sku: 'PM0075630',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281097',
      rtl_batch_array: '6281097',
      name_search: 'SOTORMIN',
      name: 'Sorbus torminalis',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.062,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E744LA6H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E744LA6H'
    },
    {
      status: 810,
      sku: 'PM0075631',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281099',
      rtl_batch_array: '6281099',
      name_search: 'SOTORMIN',
      name: 'Sorbus torminalis',
      sppl_size_code: '150175C15',
      rng_range_identifier: 'H150175C15',
      rng_range_description: 'H150 cm 175 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.117,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KE2ATJHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE2ATJHA'
    },
    {
      status: 810,
      sku: 'PM0075632',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281100',
      rtl_batch_array: '6281100',
      name_search: 'STPRAECO',
      name: 'Stachyurus praecox',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.455,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VP5HDBLG',
      statusContent: 'dot',
      hash: 'PBTXZZCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PBTXZZCT'
    },
    {
      status: 810,
      sku: 'PM0083632',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300823',
      rtl_batch_array: '6300823',
      name_search: 'BUDFPSWH',
      name: 'Buddleja Free Petite Snow White',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '94ZG5Z7Y',
      statusContent: 'dot',
      hash: 'VVD5Z21P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVD5Z21P'
    },
    {
      status: 810,
      sku: 'PM0075633',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281101',
      rtl_batch_array: '6281101',
      name_search: 'STPRAECO',
      name: 'Stachyurus praecox',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.547,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VP5HDBLG',
      statusContent: 'dot',
      hash: 'C6G4VXV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6G4VXV6'
    },
    {
      status: 810,
      sku: 'PM0032822',
      core_name: 'Plant',
      sppl_ean: '8720349404698',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837412',
      rtl_batch_array: '5837412',
      name_search: 'DEBLAUWA',
      name: "Delphinium 'Blauwal'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TPKDK2E',
      statusContent: 'dot',
      hash: '7E5H4EWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E5H4EWZ'
    },
    {
      status: 910,
      sku: 'PM0040186',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6150379',
      rtl_batch_array: '6150379',
      name_search: 'CLALPINA',
      name: 'Clematis alpina',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_order_minimum: 6,
      sppl_prcp: 2.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2C7PLSEZ;AE5XEJS4;55X28X29;SAZB1E3B;SP1BKTHH;GK1NTCX9;976R9WLB;4WLYX3XG',
      statusContent: 'dot',
      hash: 'GA4JVWAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GA4JVWAV'
    },
    {
      status: 910,
      sku: 'PM0083633',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300824',
      rtl_batch_array: '6300824',
      name_search: 'BUFPTFRU',
      name: 'Buddleja Free Petite Tutti Fruitti',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WGLDA97J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WGLDA97J'
    },
    {
      status: 210,
      sku: 'PM0007029',
      core_name: 'Plant',
      sppl_ean: '8720664857872',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6158244',
      rtl_batch_array: '6349953, 6158244, 6267773, 6217095',
      name_search: 'COGESUNR',
      name: "Coreopsis grandiflora 'Early Sunrise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 18153,
      chnn_stock_retail: 19025,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97PY6RT3;181EE1LL;WYKBAB1E;11375LSB;T2LWYZ1K;GNV5C8DK;S89ERXHC;HN8TY9CY;KS4P54ZN;CLHAS6A9;6YYV5NNY;SGH19H33;KJN7JY14;HV3B8HL5;1E6GYBAG;N5T1SWH1',
      statusContent: 'dot',
      hash: 'JVY3R36H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVY3R36H'
    },
    {
      status: 210,
      sku: 'PM0040118',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6195681',
      rtl_batch_array: '6267687, 6195681',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 984,
      chnn_stock_retail: 1024,
      sppl_order_minimum: 3,
      sppl_prcp: 1.865,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'L7JNKVX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7JNKVX2'
    },
    {
      status: 910,
      sku: 'PM0083634',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      rng_range_identifier: 'SHI012014C35',
      rng_range_description: 'High stem 12 cm 14 cm C35',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: '4ASXEZ73',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4ASXEZ73'
    },
    {
      status: 810,
      sku: 'PM0083635',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300826',
      rtl_batch_array: '6300826',
      name_search: 'CABFASTI',
      name: "Carpinus betulus 'Fastigiata'",
      sppl_size_code: '200250C25',
      rng_range_identifier: 'H200250C25',
      rng_range_description: 'H200 cm 250 cm C25',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VDHBG2L8;HAKT6DRC;ECC8PW1G;GJY4JSJ5;HP7PB8BX;CYR64XGV;L16AKPC8;H19PZT71;HEW5JK5L;1NZDLL67;9EHC9PL7;THH3Y1L2;23W7E8NY',
      statusContent: 'dot',
      hash: '7CPKEDAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CPKEDAX'
    },
    {
      status: 910,
      sku: 'PM0083636',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CASATIVA',
      name: 'Castanea sativa',
      rng_range_identifier: 'SHI010012C35',
      rng_range_description: 'High stem 10 cm 12 cm C35',
      imageCore:
        'LA8C21SW;BGYJDH9S;BYTT7W4K;PN655R62;S2H4K4EW;G7EKTG64;EC9G3SGB;P14HYP1S;K97S88SS;7P4NCCC1;WR485ZY7;KBDL6PZK;RE2ZHP6R;9ASPZVTP;8J5JTCWX;89TTCGKT;K6B1HH79;LSX7L6GW;KTRHDYP7;JSLYTH72;P5J8E112;CZEG2J67;7VHYHRCW;2BZDCED4',
      statusContent: 'dot',
      hash: 'VNCY6S43',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VNCY6S43'
    },
    {
      status: 910,
      sku: 'PM0083637',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABIGNON',
      name: 'Catalpa bignonioides',
      rng_range_identifier: 'SHI012014C45',
      rng_range_description: 'High stem 12 cm 14 cm C45',
      statusContent: 'dot',
      hash: 'ET3SKPHJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ET3SKPHJ'
    },
    {
      status: 910,
      sku: 'PM0083638',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300831',
      rtl_batch_array: '6300831',
      name_search: 'CERSILIQ',
      name: 'Cercis siliquastrum',
      sppl_size_code: '175200C25',
      rng_range_identifier: 'H175200C25',
      rng_range_description: 'H175 cm 200 cm C25',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1PBAKWV;TJN617XA;LDEG49V4;PYTPGWR3',
      statusContent: 'dot',
      hash: 'DEK4C2WC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEK4C2WC'
    },
    {
      status: 210,
      sku: 'PM0083639',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300833',
      rtl_batch_array: '6300833',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 4.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'DHWJ8DZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHWJ8DZ3'
    },
    {
      status: 210,
      sku: 'PM0083640',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300834',
      rtl_batch_array: '6300834',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 3.692,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'P7R9WAG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7R9WAG2'
    },
    {
      status: 910,
      sku: 'PM0083641',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300835',
      rtl_batch_array: '6300835',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: 'HAC25',
      rng_range_identifier: 'SHAC25',
      rng_range_description: 'Half stem C25',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'L7EANJJG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7EANJJG'
    },
    {
      status: 210,
      sku: 'PM0083642',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300837',
      rtl_batch_array: '6300837',
      name_search: 'CODAMMER',
      name: 'Cotoneaster dammeri',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 10010,
      chnn_stock_retail: 10010,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NENXJSR;CHXGX5AK;DW4VRTB2;HZTBVL8S;XLR7WPHZ;Z9PZH94B',
      statusContent: 'dot',
      hash: 'W6WGB5ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6WGB5ZS'
    },
    {
      status: 210,
      sku: 'PM0083643',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300840',
      rtl_batch_array: '6300840',
      name_search: 'COPQCARP',
      name: "Cotoneaster procumbens 'Queen of Carpets'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1274,
      chnn_stock_retail: 1274,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4WX354E',
      statusContent: 'dot',
      hash: 'W9YWW526',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9YWW526'
    },
    {
      status: 210,
      sku: 'PM0083644',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300841',
      rtl_batch_array: '6378030, 6300841',
      name_search: 'COPSFIND',
      name: "Cotoneaster procumbens 'Streib's Findling'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2748,
      chnn_stock_retail: 3656,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y1SABGR;HNWPVZWE',
      statusContent: 'dot',
      hash: 'XPC6VA76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPC6VA76'
    },
    {
      status: 210,
      sku: 'PM0083645',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300842',
      rtl_batch_array: '6300842',
      name_search: 'COPSFIND',
      name: "Cotoneaster procumbens 'Streib's Findling'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1303,
      chnn_stock_retail: 1303,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7Y1SABGR;HNWPVZWE',
      statusContent: 'dot',
      hash: 'E71XTRBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E71XTRBH'
    },
    {
      status: 210,
      sku: 'PM0083646',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300844',
      rtl_batch_array: '6300844',
      name_search: 'COREICHH',
      name: "Cotoneaster radicans 'Eichholz'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2197,
      chnn_stock_retail: 2197,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ3H5N6X;C372KZ93;Y22E9J4N;1J88TZGL;WTTXJPSA;LL3WSH5P',
      statusContent: 'dot',
      hash: 'VN3GRNBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VN3GRNBN'
    },
    {
      status: 210,
      sku: 'PM0083647',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300846',
      rtl_batch_array: '6300846',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 3612,
      chnn_stock_retail: 3612,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      statusContent: 'dot',
      hash: 'RX6VT9G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX6VT9G6'
    },
    {
      status: 210,
      sku: 'PM0083648',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300847',
      rtl_batch_array: '6300847',
      name_search: 'COSSKOGH',
      name: "Cotoneaster suecicus 'Skogholm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2446,
      chnn_stock_retail: 2446,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BECWJ1C1;771X3AR6;AZXP97EK;59KPW61B;K3XE5X71;JRAZNJ4S;866VZR8D;57JY8B2H;9HT2131W;TNB3WRSA;TG15Y59H;6WSAWK8Z',
      statusContent: 'dot',
      hash: 'EPPKZ6VL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPPKZ6VL'
    },
    {
      status: 210,
      sku: 'PM0083649',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300848',
      rtl_batch_array: '6300848',
      name_search: 'COSSKOGH',
      name: "Cotoneaster suecicus 'Skogholm'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1643,
      chnn_stock_retail: 1643,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BECWJ1C1;771X3AR6;AZXP97EK;59KPW61B;K3XE5X71;JRAZNJ4S;866VZR8D;57JY8B2H;9HT2131W;TNB3WRSA;TG15Y59H;6WSAWK8Z',
      statusContent: 'dot',
      hash: 'A4PLE5GV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4PLE5GV'
    },
    {
      status: 910,
      sku: 'PM0083650',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRMPSCAR',
      name: "Crataegus media 'Paul's Scarlet'",
      rng_range_identifier: 'SHI012014C40',
      rng_range_description: 'High stem 12 cm 14 cm C40',
      imageCore: 'DTD27V2H;KDJXDRJN;RTX122ZK;S864AHRX',
      statusContent: 'dot',
      hash: 'SYABWDBX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SYABWDBX'
    },
    {
      status: 210,
      sku: 'PM0083651',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300850',
      rtl_batch_array: '6300850',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5218,
      chnn_stock_retail: 5218,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'B31Z9CBE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B31Z9CBE'
    },
    {
      status: 210,
      sku: 'PM0083652',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300852',
      rtl_batch_array: '6300852',
      name_search: 'DEGNIKKO',
      name: "Deutzia gracilis 'Nikko'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2278,
      chnn_stock_retail: 2278,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4XTBD8Y',
      statusContent: 'dot',
      hash: '25VPTY82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '25VPTY82'
    },
    {
      status: 210,
      sku: 'PM0083653',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300854',
      rtl_batch_array: '6300854',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'ESZ2ADCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESZ2ADCY'
    },
    {
      status: 210,
      sku: 'PM0083654',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300855',
      rtl_batch_array: '6300855',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 7.143,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'GTPV1JSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTPV1JSZ'
    },
    {
      status: 210,
      sku: 'PM0083655',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300856',
      rtl_batch_array: '6300856',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 713,
      chnn_stock_retail: 713,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: '4H6EWX2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4H6EWX2C'
    },
    {
      status: 210,
      sku: 'PM0083656',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300857',
      rtl_batch_array: '6300857',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_prcp: 4.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'XXXJ1832',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXXJ1832'
    },
    {
      status: 210,
      sku: 'PM0083657',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300858',
      rtl_batch_array: '6300858',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: 'KZB448V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZB448V2'
    },
    {
      status: 910,
      sku: 'PM0083658',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300859',
      rtl_batch_array: '6300859',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '200250C80',
      rng_range_identifier: 'H200250C80',
      rng_range_description: 'H200 cm 250 cm C80',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 402,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'RR6B46D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR6B46D7'
    },
    {
      status: 910,
      sku: 'PM0083659',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      rng_range_identifier: 'H175200C35',
      rng_range_description: 'H175 cm 200 cm C35',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'JJ937PZV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JJ937PZV'
    },
    {
      status: 210,
      sku: 'PM0083660',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300861',
      rtl_batch_array: '6300861',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.192,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: 'L4CRRSAG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4CRRSAG'
    },
    {
      status: 210,
      sku: 'PM0083661',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300864',
      rtl_batch_array: '6300864',
      name_search: 'ANHHJOBE',
      name: "Anemone hybrida 'Honorine Jobert'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 222,
      chnn_stock_retail: 222,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J2J5SXH1;YKNJ7TZ1;4ETXSNCC;WC36SR2J;WVEGZW26;AXVRKPX2;8BZR9YJB;RSCKAH9Z;YBKSLZRK;8DH2V7SA;X6H88CAY;BD76SGZT;X95H1D2Y;DE3EEBSW;S9DBWLDV',
      statusContent: 'dot',
      hash: 'A7NRK4K4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A7NRK4K4'
    },
    {
      status: 210,
      sku: 'PM0083662',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300865',
      rtl_batch_array: '6300865',
      name_search: 'CEWILLMO',
      name: 'Ceratostigma willmottianum',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_prcp: 4.192,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8VW817YL',
      statusContent: 'dot',
      hash: 'DJNLP3HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJNLP3HH'
    },
    {
      status: 210,
      sku: 'PM0083663',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300870',
      rtl_batch_array: '6300870',
      name_search: 'POTNUUK',
      name: "Potentilla tridentata 'Nuuk'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1302,
      chnn_stock_retail: 1302,
      sppl_order_minimum: 3,
      sppl_prcp: 0.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5VGJ8ZB;K4K9K2E3',
      statusContent: 'dot',
      hash: 'D1A5LGXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1A5LGXA'
    },
    {
      status: 910,
      sku: 'PM0083664',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300872',
      rtl_batch_array: '6300872',
      name_search: 'CAJAPONI',
      name: 'Callicarpa japonica',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 29.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YEZEKGTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEZEKGTH'
    },
    {
      status: 910,
      sku: 'PM0083665',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGGALIS',
      name: "Magnolia grandiflora 'Galissonni\u00e8re'",
      rng_range_identifier: 'SHI008010C25',
      rng_range_description: 'High stem 8 cm 10 cm C25',
      statusContent: 'dot',
      hash: 'XYBPDBS8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XYBPDBS8'
    },
    {
      status: 910,
      sku: 'PM0083666',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300874',
      rtl_batch_array: '6300874',
      name_search: 'MAGGALIS',
      name: "Magnolia grandiflora 'Galissonni\u00e8re'",
      sppl_size_code: '125150C30',
      rng_range_identifier: 'H125150C30',
      rng_range_description: 'H125 cm 150 cm C30',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 101,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N7198CPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7198CPR'
    },
    {
      status: 910,
      sku: 'PM0083667',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGGALIS',
      name: "Magnolia grandiflora 'Galissonni\u00e8re'",
      rng_range_identifier: 'SHI012014C70',
      rng_range_description: 'High stem 12 cm 14 cm C70',
      statusContent: 'dot',
      hash: 'V1GDJK89',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V1GDJK89'
    },
    {
      status: 210,
      sku: 'PM0083668',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6246677',
      rtl_batch_array: '6246677',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'JSS37LTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSS37LTS'
    },
    {
      status: 210,
      sku: 'PM0083669',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503651',
      rtl_batch_array: '5503651',
      name_search: 'SARCOCOC',
      name: 'Sarcococca',
      sppl_size_code: 'LEVC44',
      rng_range_identifier: 'C4.5',
      rng_range_description: 'C4.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'ZC2ETBLZ',
      statusContent: 'dot',
      hash: 'B2ZJDEAB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2ZJDEAB'
    },
    {
      status: 210,
      sku: 'PM0083670',
      core_name: 'Plant',
      sppl_ean: '8720664804524',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503655',
      rtl_batch_array: '5503655',
      name_search: 'PRLTICO',
      name: 'Prunus lusitanica Tico',
      sppl_size_code: '050060C44',
      rng_range_identifier: 'H050060C4.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 5.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWY41PZS;7R6JJ5AZ;4JP57245;K7VPGT28;G23X8HE1',
      statusContent: 'dot',
      hash: 'ZL9WJSD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZL9WJSD9'
    },
    {
      status: 910,
      sku: 'PM0083671',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHLFGSPR',
      name: "Chamaecyparis lawsoniana 'Filip's Golden Sprinklers'",
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      statusContent: 'dot',
      hash: 'JKEX352D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JKEX352D'
    },
    {
      status: 210,
      sku: 'PM0083672',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6303626',
      rtl_batch_array: '6303626',
      name_search: 'ULEFPLEN',
      name: "Ulex europaeus 'Flore Pleno'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 1.326,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NNPPG82P;L5GTRHK7;XW6WTG63;TSSACY64',
      statusContent: 'dot',
      hash: 'H26L72S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H26L72S9'
    },
    {
      status: 910,
      sku: 'PM0083673',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      rng_range_identifier: 'SHI010012C45',
      rng_range_description: 'High stem 10 cm 12 cm C45',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '219RX3YT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '219RX3YT'
    },
    {
      status: 910,
      sku: 'PM0083674',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PYCCHANT',
      name: "Pyrus calleryana 'Chanticleer'",
      rng_range_identifier: 'SHI008010C35',
      rng_range_description: 'High stem 8 cm 10 cm C35',
      imageCore: 'NSSR2CZP;B9AELHD2;AKVZGXYL;4W2RAW6Z;H9X63VV9',
      statusContent: 'dot',
      hash: '41DHVJDN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '41DHVJDN'
    },
    {
      status: 810,
      sku: 'PM0083675',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305771',
      rtl_batch_array: '6305771',
      name_search: 'LYROBIN',
      name: 'Lythrum Robin',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 2.705,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4BT2CGD9;6VATJAGJ;ELBNJ4EG;4X42TYE2;28V5GNDP;T19DDGSH;LDB6GLW8;7V9G5J33;51PH1N56;JSYALT1Y;BN6SVTL6;5XPY2BNN;9B596W57;XANPVZD6;HXPRYE37',
      statusContent: 'dot',
      hash: 'XV7VKY9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV7VKY9W'
    },
    {
      status: 910,
      sku: 'PM0083676',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305944',
      rtl_batch_array: '6305944',
      name_search: 'MALLMESS',
      name: "Magnolia loebneri 'Leonard Messel'",
      sppl_size_code: '175200C20',
      rng_range_identifier: 'H175200C20',
      rng_range_description: 'H175 cm 200 cm C20',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 82.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '59VSHLL1;7GLLXRLC;BGBA396B;XH7KAKYE',
      statusContent: 'dot',
      hash: 'GVBC3SEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVBC3SEL'
    },
    {
      status: 210,
      sku: 'PM0083677',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305946',
      rtl_batch_array: '6305946',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'ZB8CBBTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZB8CBBTX'
    },
    {
      status: 210,
      sku: 'PM0083678',
      core_name: 'Plant',
      sppl_ean: '8720626377134',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5527436',
      rtl_batch_array: '5527436',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1753,
      chnn_stock_retail: 1753,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SS843SCA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS843SCA'
    },
    {
      status: 210,
      sku: 'PM0083679',
      core_name: 'Plant',
      sppl_ean: '8720626377189',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5527449',
      rtl_batch_array: '5527449',
      name_search: 'ESCBALL',
      name: "Escallonia 'C.F. Ball'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 765,
      chnn_stock_retail: 765,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PNDGY7N3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNDGY7N3'
    },
    {
      status: 210,
      sku: 'PM0083680',
      core_name: 'Plant',
      sppl_ean: '8720664689091',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5527459',
      rtl_batch_array: '5527459',
      name_search: 'EUFCOLOR',
      name: "Euonymus fortunei 'Coloratus'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 5324,
      chnn_stock_retail: 5324,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1LPHN5Y;NN7E8ELY',
      statusContent: 'dot',
      hash: 'BRN12LTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRN12LTL'
    },
    {
      status: 210,
      sku: 'PM0083681',
      core_name: 'Plant',
      sppl_ean: '8720626364646',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5527536',
      rtl_batch_array: '5527536',
      name_search: 'POFGOLDT',
      name: "Potentilla fruticosa 'Goldteppich'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2986,
      chnn_stock_retail: 2986,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPG1YEWB',
      statusContent: 'dot',
      hash: 'PNBYXB5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNBYXB5J'
    },
    {
      status: 210,
      sku: 'PM0083682',
      core_name: 'Plant',
      sppl_ean: '8720626364691',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5527537',
      rtl_batch_array: '5527537',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2532,
      chnn_stock_retail: 2532,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: 'HXWGNW8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXWGNW8Y'
    },
    {
      status: 210,
      sku: 'PM0083683',
      core_name: 'Plant',
      sppl_ean: '8720626365056',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5527539',
      rtl_batch_array: '5527539',
      name_search: 'POFMRROB',
      name: 'Potentilla fruticosa Marian Red Robin',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2821,
      chnn_stock_retail: 2821,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VHWZW22H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VHWZW22H'
    },
    {
      status: 810,
      sku: 'PM0083684',
      core_name: 'Plant',
      sppl_ean: '8720626364615',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '5528599',
      rtl_batch_array: '5528599',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 3.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: '7TLP1BZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TLP1BZX'
    },
    {
      status: 910,
      sku: 'PM0083685',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307734',
      rtl_batch_array: '6307734',
      name_search: 'PIAACROC',
      name: "Picea abies 'Acrocona'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 27.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W551BDWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W551BDWW'
    },
    {
      status: 810,
      sku: 'PM0083686',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307771',
      rtl_batch_array: '6307771',
      name_search: 'THPCANCA',
      name: "Thuja plicata 'Can-Can'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 13.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E68TYDX1;PYW1WNWE;YSER3CLR;15R6PNRA;GZTN9JAL',
      statusContent: 'dot',
      hash: 'YCD1WT4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCD1WT4C'
    },
    {
      status: 210,
      sku: 'PM0083687',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307932',
      rtl_batch_array: '6307932',
      name_search: 'ASCBLUET',
      name: "Aster cordifolius 'Bl\u00fctenregen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C73CTE34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C73CTE34'
    },
    {
      status: 810,
      sku: 'PM0083688',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307945',
      rtl_batch_array: '6307945',
      name_search: 'ASLAJEUG',
      name: "Aster laevis 'Anneke van der Jeugd'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4X26ZN44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4X26ZN44'
    },
    {
      status: 210,
      sku: 'PM0083689',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356578',
      rtl_batch_array: '6356578',
      name_search: 'CYSCOPAR',
      name: 'Cytisus scoparius',
      sppl_size_code: '040060C14',
      rng_range_identifier: 'H040060C1.5',
      rng_range_description: 'H40 cm 60 cm C1.5',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 0.918,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PB1BT1A9',
      statusContent: 'dot',
      hash: '27NHGVLJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27NHGVLJ'
    },
    {
      status: 210,
      sku: 'PM0083690',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356579',
      rtl_batch_array: '6356579',
      name_search: 'EUFCOLOR',
      name: "Euonymus fortunei 'Coloratus'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 25000,
      chnn_stock_retail: 25000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.663,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1LPHN5Y;NN7E8ELY',
      statusContent: 'dot',
      hash: '83TVDEEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83TVDEEP'
    },
    {
      status: 210,
      sku: 'PM0083691',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356581',
      rtl_batch_array: '6356581',
      name_search: 'CYSCOPAR',
      name: 'Cytisus scoparius',
      sppl_size_code: '010020P9',
      rng_range_identifier: 'H010020P9',
      rng_range_description: 'H10 cm 20 cm P9',
      sppl_stock_available: 20000,
      chnn_stock_retail: 20000,
      sppl_order_minimum: 8,
      sppl_prcp: 0.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PB1BT1A9',
      statusContent: 'dot',
      hash: 'PVES89XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVES89XV'
    },
    {
      status: 210,
      sku: 'PM0083692',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356618',
      rtl_batch_array: '6356618',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: 'T4225RK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4225RK3'
    },
    {
      status: 210,
      sku: 'PM0083693',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321567',
      rtl_batch_array: '6321567',
      name_search: 'ALAMBASS',
      name: "Allium 'Ambassador'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NK1RS8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NK1RS8Z'
    },
    {
      status: 210,
      sku: 'PM0083694',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321568',
      rtl_batch_array: '6321568',
      name_search: 'ALSMEVER',
      name: "Allium stipitatum 'Mount Everest'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 2.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '256A7LX6;EDV1XK5R;XTNL8PP1;JR9K4P1H;DHS87T5D;AXD3WHB1;LTG6JCXC',
      statusContent: 'dot',
      hash: 'TCCW2WWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCCW2WWD'
    },
    {
      status: 910,
      sku: 'PM0083695',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALGIGANT',
      name: 'Allium giganteum',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      imageCore: 'YRG6NA33;EE4SHWK9;Z2LRS16J;K7HLHZ8T;E61VHH5V;4A621ZJH',
      statusContent: 'dot',
      hash: 'WTVWSC97',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WTVWSC97'
    },
    {
      status: 210,
      sku: 'PM0083698',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321576',
      rtl_batch_array: '6321576',
      name_search: 'HYMMARIE',
      name: "Hydrangea macrophylla 'Mariesii'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 504,
      chnn_stock_retail: 504,
      sppl_prcp: 2.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2GNT7NVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GNT7NVD'
    },
    {
      status: 910,
      sku: 'PM0083699',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321578',
      rtl_batch_array: '6321578',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '100125C70',
      rng_range_identifier: 'H100125C70',
      rng_range_description: 'H100 cm 125 cm C70',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 184,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: '1HSCJTE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1HSCJTE3'
    },
    {
      status: 210,
      sku: 'PM0083701',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321581',
      rtl_batch_array: '6321581',
      name_search: 'EDI61602',
      name: "Crocus vernus 'Jeanne d'Arc'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 0.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5D2J3DWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5D2J3DWG'
    },
    {
      status: 210,
      sku: 'PM0083702',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321582',
      rtl_batch_array: '6321582',
      name_search: 'CROCUS',
      name: 'Crocus',
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGSBVW46',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGSBVW46'
    },
    {
      status: 210,
      sku: 'PM0083703',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321583',
      rtl_batch_array: '6321583',
      name_search: 'GAELWESI',
      name: 'Galanthus elwesii',
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P9V99151;VYNPG5VZ;BT45693R;8Y5LR8BS;84AZJRZ4;R98D7YAG;V1YA41D8;L8NC9AP8;9VV8ZN53;PWH6XXA1;CR1CK3YP;HTHY9HNL;HCDY6BCL;K3N1J8G9;S9S12D5Z',
      statusContent: 'dot',
      hash: '8CT7A3DX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CT7A3DX'
    },
    {
      status: 210,
      sku: 'PM0083704',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321584',
      rtl_batch_array: '6321584',
      name_search: 'MUARMENI',
      name: 'Muscari armeniacum',
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 0.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T5N6CG15',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5N6CG15'
    },
    {
      status: 210,
      sku: 'PM0083705',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321585',
      rtl_batch_array: '6321585',
      name_search: 'EDI62879',
      name: "Narcissus (trs) 'Thalia'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 0.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DEHN36L6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEHN36L6'
    },
    {
      status: 210,
      sku: 'PM0083706',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321586',
      rtl_batch_array: '6321586',
      name_search: 'EDI62402',
      name: "Narcissus (lcu) 'Carlton'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D4RJEZHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4RJEZHG'
    },
    {
      status: 210,
      sku: 'PM0083707',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321587',
      rtl_batch_array: '6321587',
      name_search: 'EDI62570',
      name: "Narcissus (lcu) 'Ice Follies'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 0.26,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRZNNZHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRZNNZHJ'
    },
    {
      status: 210,
      sku: 'PM0083708',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321588',
      rtl_batch_array: '6321588',
      name_search: 'EDI61032',
      name: "Tulipa (sla) 'Queen of Night'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 0.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANG2XE34',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANG2XE34'
    },
    {
      status: 210,
      sku: 'PM0083709',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321589',
      rtl_batch_array: '6321589',
      name_search: 'EDI61355',
      name: "Tulipa (lif) 'White Triumphator'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 0.28,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D63VWGBD;7V6YNWJK;92JK64TT;5XYYS5ZN;XBZ2V61H;H7HTXYV1;PZEZCLJ1;6P3YN9TS;TK8DWSP5;ESKKG429;D2G1L177',
      statusContent: 'dot',
      hash: '2ZHW5652',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZHW5652'
    },
    {
      status: 210,
      sku: 'PM0083710',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321590',
      rtl_batch_array: '6321590',
      name_search: 'EDI61021',
      name: "Tulipa (fos) 'Purissima'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 0.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZ25CSYK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ25CSYK'
    },
    {
      status: 910,
      sku: 'PM0083711',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JUCSUECI',
      name: "Juniperus communis 'Suecica'",
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      statusContent: 'dot',
      hash: '6ZLD36TV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6ZLD36TV'
    },
    {
      status: 910,
      sku: 'PM0083712',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'JUCSUECI',
      name: "Juniperus communis 'Suecica'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      statusContent: 'dot',
      hash: 'TVAEK5BN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TVAEK5BN'
    },
    {
      status: 910,
      sku: 'PM0083713',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUMFAULK',
      name: "Buxus microphylla 'Faulkner'",
      rng_range_identifier: 'BALL\u00d8050H045050RB',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm Root ball',
      imageCore:
        'P847PBGK;1RGYX9WZ;66PDDYYV;TWKWBDAP;P17R9S7R;HBR5KA6E;ERYD73VX;1XJX93X5',
      statusContent: 'dot',
      hash: '16R1GYTN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '16R1GYTN'
    },
    {
      status: 910,
      sku: 'PM0083714',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'BALL\u00d8060RB',
      rng_range_description: 'Ball \u00d8 60 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'YZA8A6S5',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YZA8A6S5'
    },
    {
      status: 910,
      sku: 'PM0083715',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'BALL\u00d8040H035040RB',
      rng_range_description: 'Ball \u00d8 40 cm H35 cm 40 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'JZXC6G8B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JZXC6G8B'
    },
    {
      status: 910,
      sku: 'PM0083716',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'BALL\u00d8050H045050RB',
      rng_range_description: 'Ball \u00d8 50 cm H45 cm 50 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'K12JBBHV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K12JBBHV'
    },
    {
      status: 810,
      sku: 'PM0083717',
      core_name: 'Plant',
      sppl_ean: '8720353004044',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5721826',
      rtl_batch_array: '5721826',
      name_search: 'TABFAURE',
      name: "Taxus baccata 'Fastigiata Aurea'",
      sppl_size_code: '180200KL',
      rng_range_identifier: 'H180200RB',
      rng_range_description: 'H180 cm 200 cm Root ball',
      sppl_stock_available: 81,
      chnn_stock_retail: 81,
      sppl_prcp: 30.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CHGPNCTC;RERJP6PT;T7H9XT3A;L86DXY61',
      statusContent: 'dot',
      hash: 'J2DLHC6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2DLHC6W'
    },
    {
      status: 810,
      sku: 'PM0083718',
      core_name: 'Plant',
      sppl_ean: '8720664809888',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5721827',
      rtl_batch_array: '5721827',
      name_search: 'TABFAURE',
      name: "Taxus baccata 'Fastigiata Aurea'",
      sppl_size_code: '200225KL',
      rng_range_identifier: 'H200225RB',
      rng_range_description: 'H200 cm 225 cm Root ball',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_prcp: 35.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CHGPNCTC;RERJP6PT;T7H9XT3A;L86DXY61',
      statusContent: 'dot',
      hash: 'TY4779YP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TY4779YP'
    },
    {
      status: 810,
      sku: 'PM0083719',
      core_name: 'Plant',
      sppl_ean: '8720353002569',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5752503',
      rtl_batch_array: '5752503',
      name_search: 'ILCGHEDG',
      name: "Ilex crenata 'Green Hedge'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 3.522,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KBAKJAS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBAKJAS7'
    },
    {
      status: 910,
      sku: 'PM0083720',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      rng_range_identifier: 'PYRAH120130RB',
      rng_range_description: 'Pyramid H120 cm 130 cm Root ball',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'D136186X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D136186X'
    },
    {
      status: 210,
      sku: 'PM0083721',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255006',
      rtl_batch_array: '6255006',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: '7TZ4THZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7TZ4THZY'
    },
    {
      status: 210,
      sku: 'PM0083722',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255012',
      rtl_batch_array: '6255012',
      name_search: 'CEPMSIMO',
      name: "Ceanothus pallidus 'Marie Simon'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 198,
      chnn_stock_retail: 198,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DAHPJKYX;E4937RK4;SXG491X3;BLYASDEK',
      statusContent: 'dot',
      hash: '1RLXV8YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RLXV8YC'
    },
    {
      status: 210,
      sku: 'PM0083723',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255014',
      rtl_batch_array: '6255014',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '2APSVP7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2APSVP7A'
    },
    {
      status: 210,
      sku: 'PM0083724',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255020',
      rtl_batch_array: '6255020',
      name_search: 'COMPURPU',
      name: "Corylus maxima 'Purpurea'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_prcp: 12.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GSSG2XKA;R5952436;HTCB8A75;CHBBTL6W;KJY5DA67;6EJJTVSJ;D18NH15Y;YHHPD2XC;9X13B88A;ZKX3KBZP;X547KTWE;W7D5X9GZ;YYGB3NR8;8HDGZDS2',
      statusContent: 'dot',
      hash: 'NR1ZTYZ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NR1ZTYZ4'
    },
    {
      status: 210,
      sku: 'PM0083725',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255023',
      rtl_batch_array: '6255023',
      name_search: 'DEMAGNIF',
      name: 'Deutzia magnifica',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YASYVWZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YASYVWZZ'
    },
    {
      status: 810,
      sku: 'PM0075653',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281128',
      rtl_batch_array: '6281128',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.251,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: '5YXD4HSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YXD4HSA'
    },
    {
      status: 910,
      sku: 'PM0040191',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188206',
      rtl_batch_array: '6188206',
      name_search: 'CLHHYBRI',
      name: "Clematis 'Hagley Hybrid'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_order_minimum: 6,
      sppl_prcp: 2.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8NYBL6C;ERG8894J;ZY93V8JZ;6GLHTTNW;G5J9Y5WP;8215KY9J;PNL7KLG1;544TE7CA;XEDVEPCD;1E31WSNX;W1AXAN6R;Z54DL677;9YS7N277;THK9J8YW;A9JGZ764;51C7X6KL;69AK7ABP;RN7VGG1H',
      statusContent: 'dot',
      hash: 'ABW6ARPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ABW6ARPW'
    },
    {
      status: 810,
      sku: 'PM0075634',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281102',
      rtl_batch_array: '6281102',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'WYDSPT8E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYDSPT8E'
    },
    {
      status: 810,
      sku: 'PM0083726',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255025',
      rtl_batch_array: '6255025',
      name_search: 'ESIVEYI',
      name: "Escallonia 'Iveyi'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBWCL9PR;BHV9S7WZ;PZSG7SKR;LGJVDGY9;T7A7Y6GN',
      statusContent: 'dot',
      hash: 'BDZ7XC2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDZ7XC2L'
    },
    {
      status: 810,
      sku: 'PM0075635',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281103',
      rtl_batch_array: '6281103',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'BRK22J45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BRK22J45'
    },
    {
      status: 810,
      sku: 'PM0083727',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255026',
      rtl_batch_array: '6255026',
      name_search: 'EXBRIDE',
      name: "Exochorda 'The Bride'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_prcp: 11.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V8PN9B5S;TCEC1LGX;H5DCBSA8;NJB8R34P;45T8J833;S9K5D3BJ;JD4LAR76',
      statusContent: 'dot',
      hash: 'VXSR4THX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXSR4THX'
    },
    {
      status: 910,
      sku: 'PM0040198',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLNMOSER',
      name: "Clematis 'Nelly Moser'",
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      imageCore:
        'A6CZ53WR;29H99JN3;ETSXW4Z2;2GNSNHJL;5TR7H29A;T989ZCX6;6V45XPLH;NHNCH7HJ;K2P5144Z;9HB8GARL',
      statusContent: 'dot',
      hash: 'ST936473',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ST936473'
    },
    {
      status: 810,
      sku: 'PM0075636',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281104',
      rtl_batch_array: '6281104',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.085,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'S2HCH9G5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2HCH9G5'
    },
    {
      status: 810,
      sku: 'PM0075637',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281105',
      rtl_batch_array: '6281105',
      name_search: 'STCOLCHI',
      name: 'Staphylea colchica',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSEPG81A;HEST12JN;WELHJHZ1',
      statusContent: 'dot',
      hash: 'KTT97KSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTT97KSJ'
    },
    {
      status: 810,
      sku: 'PM0075638',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281108',
      rtl_batch_array: '6281108',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'BS21JRE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BS21JRE5'
    },
    {
      status: 810,
      sku: 'PM0075639',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281109',
      rtl_batch_array: '6281109',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: '2DR5CD2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DR5CD2C'
    },
    {
      status: 810,
      sku: 'PM0075640',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281110',
      rtl_batch_array: '6281110',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 35.085,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'GD74GA19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GD74GA19'
    },
    {
      status: 810,
      sku: 'PM0075641',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281111',
      rtl_batch_array: '6281111',
      name_search: 'STPINNAT',
      name: 'Staphylea pinnata',
      sppl_size_code: '150175C18',
      rng_range_identifier: 'H150175C18',
      rng_range_description: 'H150 cm 175 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '444HC3W1',
      statusContent: 'dot',
      hash: 'W3P6WEY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W3P6WEY2'
    },
    {
      status: 810,
      sku: 'PM0075643',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281114',
      rtl_batch_array: '6281114',
      name_search: 'STPSEUDO',
      name: 'Stewartia pseudocamellia',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 22.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KT4TTS4X',
      statusContent: 'dot',
      hash: 'LPG9EK8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPG9EK8R'
    },
    {
      status: 810,
      sku: 'PM0075644',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281115',
      rtl_batch_array: '6281115',
      name_search: 'SYSPHAZE',
      name: "Sycoparrotia semidecidua 'Purple Haze'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PPTLC98D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PPTLC98D'
    },
    {
      status: 810,
      sku: 'PM0075647',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '6281118',
      rtl_batch_array: '6281118, 6283862',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 122,
      sppl_prcp: 12.542,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: '55WY3Z5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55WY3Z5T'
    },
    {
      status: 810,
      sku: 'PM0075648',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281122',
      rtl_batch_array: '6281122',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'H7LTET2A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7LTET2A'
    },
    {
      status: 810,
      sku: 'PM0075649',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281123',
      rtl_batch_array: '6281123',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'LEEAJ9RR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEEAJ9RR'
    },
    {
      status: 810,
      sku: 'PM0075650',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281124',
      rtl_batch_array: '6281124',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'JT6TRBHN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT6TRBHN'
    },
    {
      status: 810,
      sku: 'PM0075651',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281125',
      rtl_batch_array: '6281125',
      name_search: 'TEDANIEL',
      name: 'Tetradium daniellii',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCLWSKZZ;PN537N22;9AB76RNE;D3BZHL1L;YJGRBNE7;JNG2HES5;LD2D1X4A;WSHLG9ZY;B4GJPE8N;968A47NP;5XSXGWV7;L8S26Y93;JWRLSBYX;1TN4YVKD;426JV6KA',
      statusContent: 'dot',
      hash: 'VGJEEBX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGJEEBX7'
    },
    {
      status: 810,
      sku: 'PM0075652',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281127',
      rtl_batch_array: '6281127',
      name_search: 'VACCBLUE',
      name: "Vaccinium corymbosum 'Bluecrop'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2XNBEBWB;B7722WXJ;6H776WK8;BT18YHKT;B6LSDLDW;X2KGEJ55;6L8JWB9N;P8CD2N7Y;TJYH8Z7X;74PK3H97;D8JH3L2A',
      statusContent: 'dot',
      hash: 'CPHKTP33',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPHKTP33'
    },
    {
      status: 810,
      sku: 'PM0075656',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281132',
      rtl_batch_array: '6281132',
      name_search: 'VACPATRI',
      name: "Vaccinium corymbosum 'Patriot'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.251,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BNVK7HWZ',
      statusContent: 'dot',
      hash: '72AGRTKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72AGRTKN'
    },
    {
      status: 810,
      sku: 'PM0075657',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281133',
      rtl_batch_array: '6281133',
      name_search: 'VACPATRI',
      name: "Vaccinium corymbosum 'Patriot'",
      sppl_size_code: '080100CO',
      rng_range_identifier: 'H080100',
      rng_range_description: 'H80 cm 100 cm',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BNVK7HWZ',
      statusContent: 'dot',
      hash: 'KLVTD794',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLVTD794'
    },
    {
      status: 210,
      sku: 'PM0083728',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255027',
      rtl_batch_array: '6255027',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'CJ2H6LKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJ2H6LKD'
    },
    {
      status: 810,
      sku: 'PM0075658',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281134',
      rtl_batch_array: '6281134',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: '97A21H7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97A21H7E'
    },
    {
      status: 810,
      sku: 'PM0075659',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281136',
      rtl_batch_array: '6281136',
      name_search: 'VICARLCE',
      name: 'Viburnum carlcephalum',
      sppl_size_code: '100125C15',
      rng_range_identifier: 'H100125C15',
      rng_range_description: 'H100 cm 125 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YLC4D9D5;1PE21WKK;PKZYVWCX;LCB99K7C;A4PWJPX1;HV2P3ZJH',
      statusContent: 'dot',
      hash: 'JY95L7V2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY95L7V2'
    },
    {
      status: 810,
      sku: 'PM0075661',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281138',
      rtl_batch_array: '6281138',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'PSHH1C92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSHH1C92'
    },
    {
      status: 810,
      sku: 'PM0075708',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281203',
      rtl_batch_array: '6281203',
      name_search: 'HYAMACRO',
      name: "Hydrangea aspera 'Macrophylla'",
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB3NCAPA;LRAXSB87;YKB7T7E5',
      statusContent: 'dot',
      hash: '2AVV92CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AVV92CD'
    },
    {
      status: 810,
      sku: 'PM0075662',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281140',
      rtl_batch_array: '6281140',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 16.09,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'LPS52XXK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPS52XXK'
    },
    {
      status: 810,
      sku: 'PM0075663',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281141',
      rtl_batch_array: '6281141',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '150175C10',
      rng_range_identifier: 'H150175C10',
      rng_range_description: 'H150 cm 175 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 19.568,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'JKV7ZSP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JKV7ZSP9'
    },
    {
      status: 810,
      sku: 'PM0075664',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281142',
      rtl_batch_array: '6281142',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: '1YC134BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YC134BR'
    },
    {
      status: 810,
      sku: 'PM0075665',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281143',
      rtl_batch_array: '6281143',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: 'C7HHNVGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7HHNVGT'
    },
    {
      status: 810,
      sku: 'PM0075666',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281144',
      rtl_batch_array: '6281144',
      name_search: 'VIACALBU',
      name: "Vitex agnus-castus 'Albus'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECDK1Z69',
      statusContent: 'dot',
      hash: 'CH11J6T6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH11J6T6'
    },
    {
      status: 810,
      sku: 'PM0075667',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281145',
      rtl_batch_array: '6281145',
      name_search: 'VIACALBU',
      name: "Vitex agnus-castus 'Albus'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.97,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECDK1Z69',
      statusContent: 'dot',
      hash: 'CR38K9TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR38K9TY'
    },
    {
      status: 810,
      sku: 'PM0075668',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281146',
      rtl_batch_array: '6281146',
      name_search: 'VIACLATI',
      name: 'Vitex agnus-castus latifolia',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GWB7RLDW',
      statusContent: 'dot',
      hash: '1BYLJSG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BYLJSG5'
    },
    {
      status: 810,
      sku: 'PM0075670',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281148',
      rtl_batch_array: '6281148',
      name_search: 'WIFSNODA',
      name: "Wisteria floribunda 'Shiro-noda'",
      sppl_size_code: '175200C74',
      rng_range_identifier: 'H175200C7.5',
      rng_range_description: 'H175 cm 200 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VSD3472Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSD3472Y'
    },
    {
      status: 810,
      sku: 'PM0075671',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281149',
      rtl_batch_array: '6281149',
      name_search: 'WIFSNODA',
      name: "Wisteria floribunda 'Shiro-noda'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 23.551,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZ9E37YB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ9E37YB'
    },
    {
      status: 810,
      sku: 'PM0075672',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281150',
      rtl_batch_array: '6281150',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 23.551,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: 'DB2Z61WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB2Z61WA'
    },
    {
      status: 810,
      sku: 'PM0075704',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349330',
      rtl_batch_array: '6349330',
      name_search: 'HYAPANNA',
      name: 'Hydrangea arborescens Pink Annabelle',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.97,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4GSTS9GV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GSTS9GV'
    },
    {
      status: 810,
      sku: 'PM0075705',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281199',
      rtl_batch_array: '6281199',
      name_search: 'HYASANNA',
      name: 'Hydrangea arborescens Strong Annabelle',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 13.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6WESVPSZ;8S94HRR7;EW3Z6NPE;DW2PR9BP;PPAKRZVE;55NPDE9T;575TA36W;772K6D22;TS72Y5SD;JW5PHY85;EKWLYTBA;D389C7VL;XTSCRK29',
      statusContent: 'dot',
      hash: 'JL372H86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL372H86'
    },
    {
      status: 810,
      sku: 'PM0075706',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281200',
      rtl_batch_array: '6281200',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'WBZ2R2JC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WBZ2R2JC'
    },
    {
      status: 810,
      sku: 'PM0075709',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281206',
      rtl_batch_array: '6281206',
      name_search: 'HYASARGE',
      name: 'Hydrangea aspera sargentiana',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7LBRB2W2',
      statusContent: 'dot',
      hash: '8RXZ26VA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RXZ26VA'
    },
    {
      status: 810,
      sku: 'PM0075710',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281207',
      rtl_batch_array: '6281207',
      name_search: 'HYINVOLU',
      name: 'Hydrangea involucrata',
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.99,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N9G4G7BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9G4G7BT'
    },
    {
      status: 810,
      sku: 'PM0075711',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281208',
      rtl_batch_array: '6281208',
      name_search: 'HYPBOBO',
      name: 'Hydrangea paniculata Bobo',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 9.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SAJCP59K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAJCP59K'
    },
    {
      status: 810,
      sku: 'PM0075712',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281209',
      rtl_batch_array: '6281209',
      name_search: 'HYPBOMBS',
      name: "Hydrangea paniculata 'Bombshell'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ND79BBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ND79BBZ'
    },
    {
      status: 810,
      sku: 'PM0075713',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281210',
      rtl_batch_array: '6281210',
      name_search: 'HYPDHARU',
      name: "Hydrangea paniculata 'Dharuma'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBT1BJCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBT1BJCL'
    },
    {
      status: 810,
      sku: 'PM0075716',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281216',
      rtl_batch_array: '6281216',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.977,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: '8V4EX69W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8V4EX69W'
    },
    {
      status: 810,
      sku: 'PM0075717',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281217',
      rtl_batch_array: '6281217',
      name_search: 'HYPMMIND',
      name: 'Hydrangea paniculata Mega Mindy',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4HSLPGJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HSLPGJ8'
    },
    {
      status: 810,
      sku: 'PM0075718',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281218',
      rtl_batch_array: '6281218',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: '7D1JTZR9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7D1JTZR9'
    },
    {
      status: 810,
      sku: 'PM0075719',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281222',
      rtl_batch_array: '6281222',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 9.505,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: 'YRKBA1BD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRKBA1BD'
    },
    {
      status: 810,
      sku: 'PM0075720',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281223',
      rtl_batch_array: '6281223',
      name_search: 'HYQAMETH',
      name: "Hydrangea quercifolia 'Amethyst'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C2VPBJKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2VPBJKK'
    },
    {
      status: 810,
      sku: 'PM0075721',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281224',
      rtl_batch_array: '6281224',
      name_search: 'HYQAMETH',
      name: "Hydrangea quercifolia 'Amethyst'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYV4KXGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYV4KXGA'
    },
    {
      status: 810,
      sku: 'PM0075722',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281225',
      rtl_batch_array: '6281225',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.537,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: 'PN43C22L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PN43C22L'
    },
    {
      status: 210,
      sku: 'PM0083729',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255031',
      rtl_batch_array: '6255031',
      name_search: 'PYCRCOLU',
      name: "Pyracantha coccinea 'Red Column'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_prcp: 15.214,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B18HB2C9;5ZAV8GNX',
      statusContent: 'dot',
      hash: 'TKJB1972',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKJB1972'
    },
    {
      status: 910,
      sku: 'PM0075678',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307727',
      rtl_batch_array: '6307727',
      name_search: 'CHONGRAC',
      name: "Chamaecyparis obtusa 'Nana Gracilis'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 23.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1HJET36;XWH728VL;5XNYWHYN;PVNJ4EWY;E1AS7L2X;2J1E1CX4;HPJH6N7P;ZBV2G5VZ;7LB298WC',
      statusContent: 'dot',
      hash: '5VPVK57J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VPVK57J'
    },
    {
      status: 810,
      sku: 'PM0075715',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281215',
      rtl_batch_array: '6281215',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '100125C25',
      rng_range_identifier: 'H100125C25',
      rng_range_description: 'H100 cm 125 cm C25',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '13G4JW4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13G4JW4X'
    },
    {
      status: 810,
      sku: 'PM0075714',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281214',
      rtl_batch_array: '6281214',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '080100C25',
      rng_range_identifier: 'H080100C25',
      rng_range_description: 'H80 cm 100 cm C25',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '7CVCZRGL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CVCZRGL'
    },
    {
      status: 210,
      sku: 'PM0083730',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255655',
      rtl_batch_array: '6255655',
      name_search: 'STLMMELS',
      name: "Stokesia laevis 'Mega Mels'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.63,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CP5AX787;DK6695TT;8K8SX48W',
      statusContent: 'dot',
      hash: '9YST6KS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YST6KS4'
    },
    {
      status: 210,
      sku: 'PM0083731',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255896',
      rtl_batch_array: '6255896',
      name_search: 'HEHGRIPP',
      name: "Hedera helix 'Green Ripple'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1055,
      chnn_stock_retail: 1055,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CA42PXGD;5S6CPK7C;65YXDBZR',
      statusContent: 'dot',
      hash: 'H5H4PZWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5H4PZWW'
    },
    {
      status: 210,
      sku: 'PM0083732',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255897',
      rtl_batch_array: '6255897',
      name_search: 'HEHLDIAM',
      name: "Hedera helix 'Little Diamond'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 655,
      chnn_stock_retail: 655,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HATL91CP;B13ZD9E1;8RRW8G1R;L5P5PLAC',
      statusContent: 'dot',
      hash: '8WGW2P2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WGW2P2E'
    },
    {
      status: 210,
      sku: 'PM0075703',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373133',
      rtl_batch_array: '6281197, 6373133',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 815,
      chnn_stock_retail: 865,
      sppl_prcp: 8.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: 'D23NXAJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D23NXAJH'
    },
    {
      status: 210,
      sku: 'PM0083733',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255898',
      rtl_batch_array: '6255898',
      name_search: 'HEHMNEED',
      name: "Hedera helix 'Miniature Needlepoint'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1027,
      chnn_stock_retail: 1027,
      sppl_order_minimum: 3,
      sppl_prcp: 0.825,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DNLGSS7X;VSPGZ4B6;DCXGH4YB',
      statusContent: 'dot',
      hash: 'J144YN6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J144YN6S'
    },
    {
      status: 210,
      sku: 'PM0083734',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255925',
      rtl_batch_array: '6255925',
      name_search: 'HYDBUTTE',
      name: "Hypericum densiflorum 'Buttercup'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1131,
      chnn_stock_retail: 1131,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K5X4R6Y2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5X4R6Y2'
    },
    {
      status: 210,
      sku: 'PM0083735',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255938',
      rtl_batch_array: '6255938',
      name_search: 'LIJTEXAN',
      name: "Ligustrum japonicum 'Texanum'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9AL9VBH2;E98GN7ZG;L9E6NTCY;4K4R16Y7;E6K2J92D;PRRAL57L',
      statusContent: 'dot',
      hash: '65KT7H3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65KT7H3R'
    },
    {
      status: 210,
      sku: 'PM0083736',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255939',
      rtl_batch_array: '6255939',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'T3LCN5JB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3LCN5JB'
    },
    {
      status: 810,
      sku: 'PM0075646',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281117',
      rtl_batch_array: '6281117',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 14.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'RD4AAWA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD4AAWA9'
    },
    {
      status: 810,
      sku: 'PM0075645',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281116',
      rtl_batch_array: '6281116',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.73,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: '8ARD2GPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ARD2GPL'
    },
    {
      status: 810,
      sku: 'PM0075655',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281131',
      rtl_batch_array: '6281131',
      name_search: 'VACNORTH',
      name: "Vaccinium corymbosum 'Northland'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.017,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLE5Z3P8;D5PC9EN1;SZECH9NT;NY61RGXL;J6ALZP1R',
      statusContent: 'dot',
      hash: 'L3CAXYJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3CAXYJ1'
    },
    {
      status: 810,
      sku: 'PM0075642',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281113',
      rtl_batch_array: '6281113',
      name_search: 'STPSEUDO',
      name: 'Stewartia pseudocamellia',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 18.537,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KT4TTS4X',
      statusContent: 'dot',
      hash: '2SK26XTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SK26XTE'
    },
    {
      status: 810,
      sku: 'PM0075669',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281147',
      rtl_batch_array: '6281147',
      name_search: 'VIACLATI',
      name: 'Vitex agnus-castus latifolia',
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 12.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GWB7RLDW',
      statusContent: 'dot',
      hash: '68W27W9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68W27W9B'
    },
    {
      status: 210,
      sku: 'PM0083737',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255943',
      rtl_batch_array: '6255943',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: '6T9ATPTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6T9ATPTH'
    },
    {
      status: 210,
      sku: 'PM0083738',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255955',
      rtl_batch_array: '6255955',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 20541,
      chnn_stock_retail: 20541,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '4Z92XTXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z92XTXG'
    },
    {
      status: 810,
      sku: 'PM0083739',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255964',
      rtl_batch_array: '6255964',
      name_search: 'MYGALE',
      name: 'Myrica gale',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXV5S2Y2',
      statusContent: 'dot',
      hash: 'A2EC2WZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2EC2WZK'
    },
    {
      status: 810,
      sku: 'PM0083740',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255965',
      rtl_batch_array: '6255965',
      name_search: 'NADOBSES',
      name: 'Nandina domestica Obsessed',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_prcp: 12.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TWVAJD8N;ALW9XE44;AY1XJSW8;JKS2G59A;A8KXJV1B;AEXXHK4W;VVY8XGKW;1TD39TA3',
      statusContent: 'dot',
      hash: 'XJ772BV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJ772BV1'
    },
    {
      status: 210,
      sku: 'PM0083741',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255968',
      rtl_batch_array: '6255968',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4086,
      chnn_stock_retail: 4086,
      sppl_prcp: 3.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'NH48X7XB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH48X7XB'
    },
    {
      status: 910,
      sku: 'PM0083742',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255971',
      rtl_batch_array: '6255971',
      name_search: 'OSBURKWO',
      name: 'Osmanthus burkwoodii',
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 18.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZ9RNBB4;1ZDTA68Y;8EX8DP8X;JPWLKJGK;RT474ED6;NKWPL2RG;CTA5WZSX;6763REPL;KD34E86T;ZC31B1B4;693D6B4B;EWGGSN9L;NEAX6AT3;T5DRKDS9',
      statusContent: 'dot',
      hash: 'XA5VEJNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XA5VEJNX'
    },
    {
      status: 810,
      sku: 'PM0083743',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255983',
      rtl_batch_array: '6255983',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: '5B3KHWG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5B3KHWG8'
    },
    {
      status: 810,
      sku: 'PM0083744',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255984',
      rtl_batch_array: '6255984',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: 'KS5Y4C62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS5Y4C62'
    },
    {
      status: 210,
      sku: 'PM0083745',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255985',
      rtl_batch_array: '6255985',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'A2LBK7YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2LBK7YC'
    },
    {
      status: 210,
      sku: 'PM0083746',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255991',
      rtl_batch_array: '6255991',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: 'W5NGGBKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5NGGBKX'
    },
    {
      status: 210,
      sku: 'PM0083747',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255997',
      rtl_batch_array: '6255997',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 564,
      chnn_stock_retail: 564,
      sppl_prcp: 10.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'TKVNHYKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKVNHYKZ'
    },
    {
      status: 210,
      sku: 'PM0083748',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256002',
      rtl_batch_array: '6256002',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 327,
      chnn_stock_retail: 327,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: 'XVTGZVZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVTGZVZ2'
    },
    {
      status: 210,
      sku: 'PM0083749',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256005',
      rtl_batch_array: '6256005',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 4702,
      chnn_stock_retail: 4702,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'JSCPWLWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSCPWLWW'
    },
    {
      status: 210,
      sku: 'PM0083750',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256007',
      rtl_batch_array: '6256007',
      name_search: 'SPJFIREL',
      name: "Spiraea japonica 'Firelight'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P6RV2C77;KKHHCA3V;STSC46D8;Y6JNBEPZ;GRNCJ8L4;5X39CDEG;RHCNZLBG;85R683CC;T4W9EBZP',
      statusContent: 'dot',
      hash: 'BZK8B8ZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZK8B8ZA'
    },
    {
      status: 210,
      sku: 'PM0083751',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256010',
      rtl_batch_array: '6256010',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 152,
      chnn_stock_retail: 152,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'E6RNL6BL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E6RNL6BL'
    },
    {
      status: 810,
      sku: 'PM0083752',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256012',
      rtl_batch_array: '6256012',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '030035C10',
      rng_range_identifier: 'H030035C10',
      rng_range_description: 'H30 cm 35 cm C10',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: '5XE2Z2LP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XE2Z2LP'
    },
    {
      status: 210,
      sku: 'PM0083753',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256013',
      rtl_batch_array: '6256013',
      name_search: 'SPNHSILV',
      name: "Spiraea nipponica 'Halward's Silver'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1475,
      chnn_stock_retail: 1475,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5Z3T4DZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Z3T4DZG'
    },
    {
      status: 210,
      sku: 'PM0083754',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256014',
      rtl_batch_array: '6256014',
      name_search: 'SPNJBRID',
      name: "Spiraea nipponica 'June Bride'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1049,
      chnn_stock_retail: 1049,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZD9G8DS;Y27GCWAD',
      statusContent: 'dot',
      hash: 'HXDGB1S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXDGB1S1'
    },
    {
      status: 210,
      sku: 'PM0083755',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256015',
      rtl_batch_array: '6256015',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1552,
      chnn_stock_retail: 1552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: '7GKA1GKY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GKA1GKY'
    },
    {
      status: 210,
      sku: 'PM0083756',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256016',
      rtl_batch_array: '6256016',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: '95DLJCCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95DLJCCH'
    },
    {
      status: 910,
      sku: 'PM0083757',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256017',
      rtl_batch_array: '6256017',
      name_search: 'STYJAPON',
      name: 'Styphnolobium japonicum',
      sppl_size_code: '200250C35',
      rng_range_identifier: 'H200250C35',
      rng_range_description: 'H200 cm 250 cm C35',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 95.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DVZTY5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DVZTY5E'
    },
    {
      status: 810,
      sku: 'PM0083758',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256030',
      rtl_batch_array: '6256030',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_prcp: 10.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'AWVZSCJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWVZSCJ2'
    },
    {
      status: 810,
      sku: 'PM0083759',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256031',
      rtl_batch_array: '6256031',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'S14DY3XA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S14DY3XA'
    },
    {
      status: 210,
      sku: 'PM0083760',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256033',
      rtl_batch_array: '6256033',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 147,
      chnn_stock_retail: 147,
      sppl_prcp: 12.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: 'V9ZGD818',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9ZGD818'
    },
    {
      status: 810,
      sku: 'PM0083761',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256036',
      rtl_batch_array: '6256036',
      name_search: 'VIPWATAN',
      name: "Viburnum plicatum 'Watanabe'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_prcp: 11.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G9LXJV3K;827Z6E42;AS1PNED1;NRRZK322;DNGB43HJ;ZE1WC5B3',
      statusContent: 'dot',
      hash: 'TA14B352',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA14B352'
    },
    {
      status: 910,
      sku: 'PM0083762',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256038',
      rtl_batch_array: '6256038',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '100125C45',
      rng_range_identifier: 'H100125C45',
      rng_range_description: 'H100 cm 125 cm C45',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 76.286,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: '2SXGXTYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SXGXTYA'
    },
    {
      status: 810,
      sku: 'PM0083763',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256044',
      rtl_batch_array: '6256044',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 10.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'Y5TANB48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5TANB48'
    },
    {
      status: 210,
      sku: 'PM0083764',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256058',
      rtl_batch_array: '6256058',
      name_search: 'DECESPIT',
      name: 'Deschampsia cespitosa',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 746,
      chnn_stock_retail: 746,
      sppl_order_minimum: 3,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SWAZ5E95',
      statusContent: 'dot',
      hash: 'G2KNHXNL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2KNHXNL'
    },
    {
      status: 810,
      sku: 'PM0083765',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256062',
      rtl_batch_array: '6256062',
      name_search: 'IMCRBARO',
      name: "Imperata cylindrica 'Red Baron'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V15WA5ZW;ARKZVNWC;2EZRL977;2S2XPVPL;KYTB64P8;9P33312S;DW39EDEG;YJCRG6C5;971HP14G;8W17L5YS;KBZ4WRCD;XEAZ1ZEJ;CB3E29DA;YWES6DLB;5HX7YJE7;LD1YPWY1;YCZVGWSC;ZEE2SS7K;6E1XDP2V;TZGK7NG8;XP79JXG5;H6JRJPBX;NW7YKZ34;RZ748C5K;4G782DTY;XJH2NSJ6',
      statusContent: 'dot',
      hash: 'Y5DEZ3HP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5DEZ3HP'
    },
    {
      status: 210,
      sku: 'PM0083766',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256064',
      rtl_batch_array: '6256064',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'V1ANH75Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1ANH75Y'
    },
    {
      status: 210,
      sku: 'PM0083767',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256066',
      rtl_batch_array: '6256066',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1949,
      chnn_stock_retail: 1949,
      sppl_order_minimum: 3,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      statusContent: 'dot',
      hash: 'S6661DXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6661DXY'
    },
    {
      status: 810,
      sku: 'PM0083768',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256068',
      rtl_batch_array: '6256068',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_prcp: 10.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'PBSAYWK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PBSAYWK8'
    },
    {
      status: 210,
      sku: 'PM0083769',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256074',
      rtl_batch_array: '6256074',
      name_search: 'SEAUTUMN',
      name: 'Sesleria autumnalis',
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 2358,
      chnn_stock_retail: 2358,
      sppl_order_minimum: 3,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1L6XVAN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L6XVAN1'
    },
    {
      status: 210,
      sku: 'PM0083770',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256075',
      rtl_batch_array: '6256075',
      name_search: 'SEAUTUMN',
      name: 'Sesleria autumnalis',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 513,
      chnn_stock_retail: 513,
      sppl_prcp: 2.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LY3RJGLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY3RJGLR'
    },
    {
      status: 210,
      sku: 'PM0083771',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256077',
      rtl_batch_array: '6256077',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVC13',
      rng_range_identifier: 'C1.3',
      rng_range_description: 'C1.3',
      sppl_stock_available: 1373,
      chnn_stock_retail: 1373,
      sppl_order_minimum: 3,
      sppl_prcp: 1.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: 'EA9HGGTW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA9HGGTW'
    },
    {
      status: 210,
      sku: 'PM0083772',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6256078',
      rtl_batch_array: '6256078',
      name_search: 'STTPONYT',
      name: "Stipa tenuissima 'Ponytails'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 387,
      chnn_stock_retail: 387,
      sppl_prcp: 2.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXG4AV9B;C23297T2;EYJ4J34L;8K2A5BGZ;97XTWJEL;VCCTEERY;H2NX1S6X;PD5L3X9D;9BNYR9BB;61SS6517;LXGH6X8S;XRP4S77S',
      statusContent: 'dot',
      hash: '2XY7JP4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XY7JP4L'
    },
    {
      status: 210,
      sku: 'PM0083773',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259843',
      rtl_batch_array: '6383813, 6259843',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 535,
      chnn_stock_retail: 1937,
      sppl_prcp: 11.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: 'YNJSGH3H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNJSGH3H'
    },
    {
      status: 210,
      sku: 'PM0083774',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259844',
      rtl_batch_array: '6383815, 6259844',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 1202,
      chnn_stock_retail: 1654,
      sppl_prcp: 11.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'DWGX91WD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWGX91WD'
    },
    {
      status: 910,
      sku: 'PM0083775',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259903',
      rtl_batch_array: '6259903',
      name_search: 'CECONCHA',
      name: "Ceanothus 'Concha'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 11,
      chnn_stock_retail: 11,
      sppl_prcp: 13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P5ENE7ES;28AN9KRW;NVW7XPS1;DXETJ5DE;RDXVA614;X8ZC8RP4;T3L8W8ZJ;74EK93P2;51Y4XNJ3;BASPGSBG;1SL71SNL',
      statusContent: 'dot',
      hash: '9J14A97N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J14A97N'
    },
    {
      status: 810,
      sku: 'PM0083776',
      core_name: 'Plant',
      sppl_ean: '8720626394032',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '5952964',
      rtl_batch_array: '5952964',
      name_search: 'BEMPARKJ',
      name: "Berberis media 'Parkjuweel'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 3.522,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DNS4ZEN7;DXHPLBP2;H19D93RE;LP5A84HC;TRSEJG36;P7WBZTA4',
      statusContent: 'dot',
      hash: '8ZKA2XJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZKA2XJB'
    },
    {
      status: 810,
      sku: 'PM0083777',
      core_name: 'Plant',
      sppl_ean: '8720664854666',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6113410',
      rtl_batch_array: '6113410',
      name_search: 'CASARAST',
      name: "Campanula 'Sarastro'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D3ADBW9L;DABGJ492;GCXVZXT7;YX5V9RNN;TNWEHZSN;8KVRTELX',
      statusContent: 'dot',
      hash: '9EXGLBPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9EXGLBPV'
    },
    {
      status: 810,
      sku: 'PM0075785',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281317',
      rtl_batch_array: '6281317',
      name_search: 'ACPBHOO',
      name: "Acer palmatum 'Bi-hoo'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 25.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJNPS2X8;N8DBB3WB;H99XGZKA;EWCKTXEE;GN5LG2R9;9ZH1W5ZS;G3HA2JSE;7CDYJEYJ;756P4RLC;LXS58H13;SEYVCP3P;YNDYHGHA;HVABCEYG;RJ1LLLED',
      statusContent: 'dot',
      hash: '4C42NVW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4C42NVW5'
    },
    {
      status: 810,
      sku: 'PM0075781',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281311',
      rtl_batch_array: '6281311',
      name_search: 'ACOPALUS',
      name: 'Acer opalus',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 20,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SZBTWT39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZBTWT39'
    },
    {
      status: 210,
      sku: 'PM0083779',
      core_name: 'Plant',
      sppl_ean: '8720664690158',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6067399',
      rtl_batch_array: '6067399',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: 'ZA9CY44V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZA9CY44V'
    },
    {
      status: 810,
      sku: 'PM0075786',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281318',
      rtl_batch_array: '6281318',
      name_search: 'ACPBHOO',
      name: "Acer palmatum 'Bi-hoo'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 30.912,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJNPS2X8;N8DBB3WB;H99XGZKA;EWCKTXEE;GN5LG2R9;9ZH1W5ZS;G3HA2JSE;7CDYJEYJ;756P4RLC;LXS58H13;SEYVCP3P;YNDYHGHA;HVABCEYG;RJ1LLLED',
      statusContent: 'dot',
      hash: '45YX748Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45YX748Y'
    },
    {
      status: 210,
      sku: 'PM0083780',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212852',
      rtl_batch_array: '6212852',
      name_search: 'CHAGOLD',
      name: "Choisya 'Aztec Gold'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRBZPJR;CXYAP33L;XPZ2STEH;XPGTVKWE;PPEXNEP2;VWDVY9VZ;8SJ52K9W;TSYKT4G3;G7BP1HLT;AZNGTT5W;VJA6VVVS;94869PBK;SBBXWYRB;WVLC9ZH3;J1BTEDCK;S41XBA3T;VC113LYZ;9XE14T7S',
      statusContent: 'dot',
      hash: '8S3R1C5H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S3R1C5H'
    },
    {
      status: 210,
      sku: 'PM0083781',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6212854',
      rtl_batch_array: '6212854',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '035040C5',
      rng_range_identifier: 'H035040C5',
      rng_range_description: 'H35 cm 40 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'LVYRZV4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVYRZV4E'
    },
    {
      status: 210,
      sku: 'PM0083782',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266249',
      rtl_batch_array: '6266249',
      name_search: 'GALSNOWB',
      name: "Gaura lindheimeri 'Snowbird'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 461,
      chnn_stock_retail: 461,
      sppl_prcp: 2.606,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G46E41JT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G46E41JT'
    },
    {
      status: 210,
      sku: 'PM0083783',
      core_name: 'Plant',
      sppl_ean: '8720664851832',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6129727',
      rtl_batch_array: '6129727',
      name_search: 'ANPACHYC',
      name: 'Angelica pachycarpa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 174,
      chnn_stock_retail: 174,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A9P2N867',
      statusContent: 'dot',
      hash: 'W2B1WA7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W2B1WA7S'
    },
    {
      status: 210,
      sku: 'PM0083784',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6174855',
      rtl_batch_array: '6378425, 6174855',
      name_search: 'BUDBKNIG',
      name: "Buddleja davidii 'Black Knight'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1038,
      chnn_stock_retail: 4076,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y39N9HJH;WH7BAVN6;9PAY3K1Z;G4L9PNV2;NRL7LP5A;4ES61PWG;NL4TVTVS',
      statusContent: 'dot',
      hash: 'XV4JBG6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV4JBG6E'
    },
    {
      status: 810,
      sku: 'PM0075780',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281310',
      rtl_batch_array: '6281310',
      name_search: 'ACJACONI',
      name: "Acer japonicum 'Aconitifolium'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 45.762,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CX7WD9VL;GH3E7E2Z;D56VZNPG;TKE8SX15;H71B9ER1;6KNCDWWB',
      statusContent: 'dot',
      hash: 'XE19T6T5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE19T6T5'
    },
    {
      status: 910,
      sku: 'PM0083785',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6174867',
      rtl_batch_array: '6174867',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 10,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'GZH96VEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZH96VEW'
    },
    {
      status: 810,
      sku: 'PM0075784',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281316',
      rtl_batch_array: '6281316',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '125150C10',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 34.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'BSY4K4AT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSY4K4AT'
    },
    {
      status: 810,
      sku: 'PM0075782',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281314',
      rtl_batch_array: '6281314',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.456,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: '4T31B135',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T31B135'
    },
    {
      status: 810,
      sku: 'PM0075783',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281315',
      rtl_batch_array: '6281315',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 17.739,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'HZT91GGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZT91GGV'
    },
    {
      status: 810,
      sku: 'PM0083786',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6176314',
      rtl_batch_array: '6176314',
      name_search: 'ALLOUIS',
      name: "Alstroemeria 'Louis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EYK6SXND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYK6SXND'
    },
    {
      status: 210,
      sku: 'PM0083787',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373108',
      rtl_batch_array: '6373108, 6223076',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 338,
      chnn_stock_retail: 777,
      sppl_prcp: 8.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'Z5S4W83T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5S4W83T'
    },
    {
      status: 210,
      sku: 'PM0083788',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6223077',
      rtl_batch_array: '6223077',
      name_search: 'DEGNIKKO',
      name: "Deutzia gracilis 'Nikko'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 137,
      chnn_stock_retail: 137,
      sppl_prcp: 9.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4XTBD8Y',
      statusContent: 'dot',
      hash: '46SGY5YX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46SGY5YX'
    },
    {
      status: 910,
      sku: 'PM0083789',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6225022',
      rtl_batch_array: '6225022',
      name_search: 'PRLUSITA',
      name: 'Prunus lusitanica',
      sppl_size_code: '100120C74',
      rng_range_identifier: 'H100120C7.5',
      rng_range_description: 'H100 cm 120 cm C7.5',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 8.087,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C67X3C9T;SS8KHA3P;47ZDXH5E;SKXSE4XD;N7DWSHGC;7SCNBR9W',
      statusContent: 'dot',
      hash: '4AJYW9SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4AJYW9SS'
    },
    {
      status: 210,
      sku: 'PM0083790',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6229140',
      rtl_batch_array: '6229140',
      name_search: 'PHFCAMIL',
      name: "Photinia fraseri 'Camilvy'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 5.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5GEWD2X;Y96SS7T6;H3XSZSRY;5937KDPN;HA13T26N',
      statusContent: 'dot',
      hash: 'EEPRG1VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EEPRG1VW'
    },
    {
      status: 810,
      sku: 'PM0075788',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281321',
      rtl_batch_array: '6281321',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 15.402,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'T5X385SA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5X385SA'
    },
    {
      status: 810,
      sku: 'PM0075787',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281319',
      rtl_batch_array: '6281319',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 10.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'LXS2D8D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXS2D8D3'
    },
    {
      status: 210,
      sku: 'PM0083778',
      core_name: 'Plant',
      sppl_ean: '8720664854079',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6113639',
      rtl_batch_array: '6113639',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 569,
      chnn_stock_retail: 569,
      sppl_prcp: 2.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: '4DLPD9CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DLPD9CT'
    },
    {
      status: 810,
      sku: 'PM0075779',
      core_name: 'Plant',
      btch_manufacturer: 508,
      rtl_batch_array_total: 1,
      btch_active_retail: '6281309',
      rtl_batch_array: '6281309',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 54.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'EX24AE3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX24AE3R'
    },
    {
      status: 210,
      sku: 'PM0083791',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '4787204',
      rtl_batch_array: '4787204',
      name_search: 'EUJMIKA',
      name: "Euonymus japonicus 'Mikajo' \u00ae",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 12.88,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '4XZE51PZ',
      statusContent: 'dot',
      hash: '5NPW9CHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NPW9CHC'
    },
    {
      status: 210,
      sku: 'PM0083935',
      core_name: 'Plant',
      sppl_ean: '8720626366848',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4376204',
      rtl_batch_array: '4376204',
      name_search: 'RHAMOENU',
      name: "Rhododendron (AJ) 'Amoenum'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VRK6BYP;RYB5WASD;GV7Z6WAC',
      statusContent: 'dot',
      hash: 'WWJS8X2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWJS8X2X'
    },
    {
      status: 210,
      sku: 'PM0083936',
      core_name: 'Plant',
      sppl_ean: '8720353023113',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4706019',
      rtl_batch_array: '4706019',
      name_search: 'JUPPAURE',
      name: "Juniperus pfitzeriana 'Pfitzeriana Aurea'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LBJ8BXBW;G3T2GW81;72RPG44S;7LE43BR3;6L313PZP;LR119SNV;V8BSWKZ4;VTRZ852H;J11BYXJN',
      statusContent: 'dot',
      hash: 'YS89EA99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS89EA99'
    },
    {
      status: 210,
      sku: 'PM0075859',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282376',
      rtl_batch_array: '6282376',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'A5E1188W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5E1188W'
    },
    {
      status: 210,
      sku: 'PM0075860',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282379',
      rtl_batch_array: '6282379',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'D5RVWDD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D5RVWDD1'
    },
    {
      status: 210,
      sku: 'PM0083937',
      core_name: 'Plant',
      sppl_ean: '8720664599765',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4706035',
      rtl_batch_array: '4706035',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.129,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: '77L61SH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77L61SH5'
    },
    {
      status: 210,
      sku: 'PM0075862',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282476',
      rtl_batch_array: '6282476',
      name_search: 'FULTHUMB',
      name: "Fuchsia 'Lady Thumb'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 186,
      chnn_stock_retail: 186,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9LZE88A2;HDAJEETB;W4HK4NA4;41XVVNHA;N6K97PDD;449PWZD6;6S2CPT3T;J7XX5SWZ;58NRAWLL',
      statusContent: 'dot',
      hash: '5BL25WZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BL25WZW'
    },
    {
      status: 210,
      sku: 'PM0075863',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282477',
      rtl_batch_array: '6282477',
      name_search: 'FUTTHUMB',
      name: "Fuchsia 'Tom Thumb'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1GN92TR3;HVEZL64A;SCTBVHZH;1G825SDJ;GXVX5HVB;V6RP7PJ8;1CCRVRSR;5D8APRRE;P4WP1K77;CPLR64E8;DT5B7T6E;4LPRN9GW;XKDXWKSD;KT2R26DT;58V3W793',
      statusContent: 'dot',
      hash: 'ZKS3Y6JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKS3Y6JH'
    },
    {
      status: 210,
      sku: 'PM0083938',
      core_name: 'Plant',
      sppl_ean: '8720626366855',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4856261',
      rtl_batch_array: '4856261',
      name_search: 'RHMOEDER',
      name: "Rhododendron (AJ) 'Moederkensdag'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PVTH6Y38',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PVTH6Y38'
    },
    {
      status: 210,
      sku: 'PM0075865',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282479',
      rtl_batch_array: '6282479',
      name_search: 'FUMTRICO',
      name: "Fuchsia magellanica 'Tricolor'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 132,
      chnn_stock_retail: 132,
      sppl_order_minimum: 3,
      sppl_prcp: 1.646,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSC821NP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSC821NP'
    },
    {
      status: 210,
      sku: 'PM0075867',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282481',
      rtl_batch_array: '6282481',
      name_search: 'CRCOLUMB',
      name: "Crocosmia 'Columbus'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_prcp: 3.779,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z3AK3TYA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3AK3TYA'
    },
    {
      status: 810,
      sku: 'PM0075868',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282482',
      rtl_batch_array: '6282482',
      name_search: 'GALFPINK',
      name: "Gaura lindh. 'Flamingo Pink'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_prcp: 3.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHKLV2RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHKLV2RB'
    },
    {
      status: 210,
      sku: 'PM0075866',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282480',
      rtl_batch_array: '6282480',
      name_search: 'CRCBRILL',
      name: "Crocosmia 'Carmine Brilliant'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_prcp: 3.581,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJKA9LCH',
      statusContent: 'dot',
      hash: 'G4TDYHXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G4TDYHXC'
    },
    {
      status: 810,
      sku: 'PM0083939',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282642',
      rtl_batch_array: '6282642',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 3.938,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: '73NTAATS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73NTAATS'
    },
    {
      status: 210,
      sku: 'PM0075856',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6281439',
      rtl_batch_array: '6281439, 6310382',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 10820,
      chnn_stock_retail: 12278,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'AHJRG3TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AHJRG3TP'
    },
    {
      status: 210,
      sku: 'PM0075858',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282375',
      rtl_batch_array: '6282375',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'D9WBVSCY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9WBVSCY'
    },
    {
      status: 210,
      sku: 'PM0083940',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5352417',
      rtl_batch_array: '5352417',
      name_search: 'JUPOGOLD',
      name: "Juniperus pfitzeriana 'Old Gold'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H4AH5NBV;JCYD5JZW;ABRDA4XH;RX122G23;HYNDD2NP;ATX87GRK;52LSLXGD;2PPHP64S;EG5B4Z2H;111L6DNL;H28CXG1S;7151XSEX;S19CRJ87;HPR3EJ1N;D4EB5S1N;9RRH4AP9;WWSTSPWY;4BE6LC9V',
      statusContent: 'dot',
      hash: 'NH1YLABD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NH1YLABD'
    },
    {
      status: 810,
      sku: 'PM0083941',
      core_name: 'Plant',
      sppl_ean: '8720626322073',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5394153',
      rtl_batch_array: '5394153',
      name_search: 'RHMHECKE',
      name: "Rhododendron (AJ) 'Madame van Hecke'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9KDAJTSG;N7J9BAV6;DTBEYJNZ;D1H76AJ6;DV484R5W;LS9ZVGL5;DENWNK6N;V153P7B9;59ZLTH5G',
      statusContent: 'dot',
      hash: '71Z8N5DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71Z8N5DY'
    },
    {
      status: 210,
      sku: 'PM0083942',
      core_name: 'Plant',
      sppl_ean: '8720626322042',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5394154',
      rtl_batch_array: '5394154',
      name_search: 'RHADONIS',
      name: "Rhododendron (AJ) 'Adonis'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EJN2B1N3;8CLNC75W;7C1G66L2;PY41XBBV;ARPKH7KW;SZCGCHZD;S5Z9XE2D;J1A42NRB;NWTLSAHG',
      statusContent: 'dot',
      hash: 'VD23WW6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD23WW6C'
    },
    {
      status: 210,
      sku: 'PM0083943',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333270',
      rtl_batch_array: '6254396, 6333270, 6217193',
      name_search: 'LAANGUST',
      name: 'Lavandula angustifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1065,
      chnn_stock_retail: 8129,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VYKKKDS;YPTGVRDY;HTP5ZG2A;TB7BHCZB;6DYPYP1T;EHVW5BND;1R9631SE;B1YG5SRS;N8SNW539;1K2KCCZG;2H8T9228;86XP5H33;A3YH7BYE;D8P5HTKC;NNECTC59;C445PJVV;V42B9KSP;EG5SVBSL;RLHDAEE1;1ZBBWPJ1;JG17P1VD;5X9VZLRJ;CJHAZ1CG;D48LBS7S',
      statusContent: 'dot',
      hash: '2E71PGP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E71PGP5'
    },
    {
      status: 810,
      sku: 'PM0083944',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333385',
      rtl_batch_array: '6333385',
      name_search: 'DIFLAVEN',
      name: 'Disporum flavens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 32,
      chnn_stock_retail: 32,
      sppl_order_minimum: 3,
      sppl_prcp: 2.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NY8ECPRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NY8ECPRS'
    },
    {
      status: 210,
      sku: 'PM0083945',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333399',
      rtl_batch_array: '6333399',
      name_search: 'PEATRIPL',
      name: 'Perovskia atriplicifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 318,
      chnn_stock_retail: 318,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PHDWA9YZ;P1AGY8XA;KZ236RX4;1D9W44SL;BS496EAS;46BR76B5;X9G37KWK;589V21D5;HTLLC1B8;GCR521R2;G333GP1G;H8YL8RL4;9YJHKBN9',
      statusContent: 'dot',
      hash: '8SA1CLSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SA1CLSV'
    },
    {
      status: 810,
      sku: 'PM0083946',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333402',
      rtl_batch_array: '6333402',
      name_search: 'SEWALLIC',
      name: 'Selinum wallichianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P7ETBHX4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7ETBHX4'
    },
    {
      status: 810,
      sku: 'PM0083947',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333405',
      rtl_batch_array: '6333405',
      name_search: 'VEVTEMPT',
      name: "Veronicastrum virginicum 'Temptation'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '94YN33JT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '94YN33JT'
    },
    {
      status: 210,
      sku: 'PM0083948',
      core_name: 'Plant',
      sppl_ean: '8720664874794',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133402',
      rtl_batch_array: '6133402',
      name_search: 'MOMAHOGA',
      name: "Monarda 'Mahogany'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NYTB4CP3;4BY76GH5;T953ZH46;CGER46TD;W9Z59RY7;WBGY6HWJ;W1ZKPHX2;BX4RBRRB',
      statusContent: 'dot',
      hash: 'WPPD32X4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPPD32X4'
    },
    {
      status: 210,
      sku: 'PM0083949',
      core_name: 'Plant',
      sppl_ean: '8720664875227',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133413',
      rtl_batch_array: '6133413',
      name_search: 'NEGWCAT',
      name: "Nepeta grandiflora 'Wild Cat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1375,
      chnn_stock_retail: 1375,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4Y13NWKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y13NWKZ'
    },
    {
      status: 210,
      sku: 'PM0083950',
      core_name: 'Plant',
      sppl_ean: '8720664877245',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133483',
      rtl_batch_array: '6133483',
      name_search: 'PEAASUMM',
      name: "Persicaria a. 'Amethyst Summer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 127,
      chnn_stock_retail: 127,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N8WA6SKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8WA6SKR'
    },
    {
      status: 810,
      sku: 'PM0083951',
      core_name: 'Plant',
      sppl_ean: '8720664878143',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133517',
      rtl_batch_array: '6133517',
      name_search: 'PHUSPECH',
      name: "Phlox (P) 'Uspech'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BRDTTEB',
      statusContent: 'dot',
      hash: 'JK6137VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JK6137VN'
    },
    {
      status: 210,
      sku: 'PM0083952',
      core_name: 'Plant',
      sppl_ean: '8720664884694',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133631',
      rtl_batch_array: '6133631',
      name_search: 'SANNFIEL',
      name: "Salvia nemorosa 'Night Field'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 123,
      chnn_stock_retail: 123,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4P5J5SL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P5J5SL4'
    },
    {
      status: 210,
      sku: 'PM0083953',
      core_name: 'Plant',
      sppl_ean: '8720664884724',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133635',
      rtl_batch_array: '6133635',
      name_search: 'SANRUEGE',
      name: "Salvia nemorosa 'R\u00fcgen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 214,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EAJN92WK;HSLNW9AD;7NDH6WJK;429VTRT8;6832A438',
      statusContent: 'dot',
      hash: 'NCWSGZBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCWSGZBN'
    },
    {
      status: 210,
      sku: 'PM0083954',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363211',
      rtl_batch_array: '6133743, 6363211',
      name_search: 'STOUKKIE',
      name: "Stachys officinalis 'Ukkie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1006,
      chnn_stock_retail: 1136,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '53XYZ21R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53XYZ21R'
    },
    {
      status: 210,
      sku: 'PM0083955',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133768',
      rtl_batch_array: '6133768',
      name_search: 'TEHPDELI',
      name: "Teucrium hyrcanicum 'Paradise Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '45KALYCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45KALYCW'
    },
    {
      status: 210,
      sku: 'PM0083956',
      core_name: 'Plant',
      sppl_ean: '8720664888463',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133775',
      rtl_batch_array: '6133775',
      name_search: 'THCAPITA',
      name: 'Thymus capitatus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VTAGB6K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTAGB6K1'
    },
    {
      status: 210,
      sku: 'PM0083957',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133776',
      rtl_batch_array: '6133776',
      name_search: 'THCAUREU',
      name: "Thymus citriodorus 'Aureus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 531,
      chnn_stock_retail: 531,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LAEVY67V;84LZ1PKX;PV6XZ9SS;1KDNWLDD;LLY6GG88;C9N6216N;DB2SNCK6;V2SJAXXJ;X8LBJEPZ;6NHK8VG2;7S3E3BJP;EPAZCEEY;JHP1H4BT;GH8NJ4V2;R3DPWCZH;5LPGJD3C;P46NSYN6;9R5TPVBS;9WX32KJ5;H26Y8C8N;BEC66WNP;S5LKH763;NJ3XCV4L;XHEKJPTH;XEV48K2A;JHBYT3SW;YSWN6AKH',
      statusContent: 'dot',
      hash: 'EA23JRS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EA23JRS7'
    },
    {
      status: 210,
      sku: 'PM0083958',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6133787',
      rtl_batch_array: '6133787',
      name_search: 'THPMINOR',
      name: "Thymus praecox 'Minor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 109,
      chnn_stock_retail: 109,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DK78XSC2;Z6HTGB7A;E2AXSGRJ;44STXNVH;922B647Y;B8ECWZXH;2GEDH95J;LB9HA7PT',
      statusContent: 'dot',
      hash: 'JECC5HHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JECC5HHG'
    },
    {
      status: 210,
      sku: 'PM0083959',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333670',
      rtl_batch_array: '6333670',
      name_search: 'BUSALICI',
      name: 'Buphthalmum salicifolium',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7050,
      chnn_stock_retail: 7050,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E2AEYA8H;BGA58ASS;RJVJBZ7B;977HKRR3;7VDGVR44;Z66WVDEB;LS4PNPCN',
      statusContent: 'dot',
      hash: 'K6XWL9EG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K6XWL9EG'
    },
    {
      status: 210,
      sku: 'PM0083960',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355352',
      rtl_batch_array: '6369215, 6355352',
      name_search: 'AGFOENIC',
      name: 'Agastache foeniculum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1920,
      chnn_stock_retail: 2520,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SRG7GYB1;X678KJER;E1V73RLD;LTZ573Y9;CS4XH8V1;Z6SP7JEL',
      statusContent: 'dot',
      hash: 'L5Z1ZA6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5Z1ZA6G'
    },
    {
      status: 210,
      sku: 'PM0083961',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355353',
      rtl_batch_array: '6355353',
      name_search: 'ARHORATI',
      name: "Aruncus 'Horatio'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 161,
      chnn_stock_retail: 161,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '97LC5227',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '97LC5227'
    },
    {
      status: 210,
      sku: 'PM0083962',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355358',
      rtl_batch_array: '6355358',
      name_search: 'ERMARITI',
      name: 'Eryngium maritimum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '72Z4X64P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72Z4X64P'
    },
    {
      status: 810,
      sku: 'PM0083963',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355362',
      rtl_batch_array: '6355362',
      name_search: 'GEPMKCLA',
      name: "Geranium pratense 'Mrs Kendall Clark'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 56,
      chnn_stock_retail: 56,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2XXKYPYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XXKYPYR'
    },
    {
      status: 810,
      sku: 'PM0083964',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355364',
      rtl_batch_array: '6386125, 6355364',
      name_search: 'GLPUNCTA',
      name: 'Globularia punctata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WRDDDYWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRDDDYWJ'
    },
    {
      status: 210,
      sku: 'PM0083965',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355366',
      rtl_batch_array: '6355366',
      name_search: 'HOHTRIER',
      name: "Hosta 'Harry van Trier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DESSAX9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DESSAX9B'
    },
    {
      status: 210,
      sku: 'PM0083966',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355367',
      rtl_batch_array: '6355367',
      name_search: 'HOCMVARI',
      name: "Houstonia caerulea 'Millard's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '228Y7GE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '228Y7GE2'
    },
    {
      status: 810,
      sku: 'PM0083967',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355373',
      rtl_batch_array: '6355373',
      name_search: 'PAOMARLE',
      name: "Papaver orientale 'Marlene'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X2N9XHDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X2N9XHDX'
    },
    {
      status: 210,
      sku: 'PM0083968',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386173',
      rtl_batch_array: '6386173, 6355375',
      name_search: 'PUIBALLE',
      name: "Pulmonaria 'Ice Ballet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 800,
      chnn_stock_retail: 840,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4LTS1TPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4LTS1TPJ'
    },
    {
      status: 210,
      sku: 'PM0083969',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6371215',
      rtl_batch_array: '6371215, 6355377',
      name_search: 'SASDANJA',
      name: "Salvia sylvestris 'Dear Anja'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 171,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DD3TH9EX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DD3TH9EX'
    },
    {
      status: 810,
      sku: 'PM0083970',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6355379',
      rtl_batch_array: '6355379',
      name_search: 'SEECALCA',
      name: 'Semiaquilegia ecalcarata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PTGJY9GP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTGJY9GP'
    },
    {
      status: 910,
      sku: 'PM0083971',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TABALSAM',
      name: 'Tanacetum balsamita',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'J88RATYK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J88RATYK'
    },
    {
      status: 810,
      sku: 'PM0083972',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356596',
      rtl_batch_array: '6356596',
      name_search: 'EPGLILAF',
      name: "Epimedium grandiflorum 'Lilafee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1JA1J5X2',
      statusContent: 'dot',
      hash: 'KPGXYSGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPGXYSGJ'
    },
    {
      status: 810,
      sku: 'PM0083973',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356603',
      rtl_batch_array: '6356603',
      name_search: 'IBSWZWER',
      name: "Iberis sempervirens 'Weisser Zwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W4N6H29P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4N6H29P'
    },
    {
      status: 210,
      sku: 'PM0083974',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356606',
      rtl_batch_array: '6356606',
      name_search: 'PHCWHITE',
      name: "Phlox (S) 'Calvides White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 272,
      chnn_stock_retail: 272,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '25T53H15',
      statusContent: 'dot',
      hash: 'BTVEXLY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTVEXLY2'
    },
    {
      status: 210,
      sku: 'PM0083975',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356607',
      rtl_batch_array: '6356607',
      name_search: 'PHGWILSO',
      name: "Phlox (S) 'G.F. Wilson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 453,
      chnn_stock_retail: 453,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8SBS4J2Z;Y91741L7;BTNA8DA3',
      statusContent: 'dot',
      hash: 'NCKC6JDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCKC6JDP'
    },
    {
      status: 210,
      sku: 'PM0083976',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356609',
      rtl_batch_array: '6356609',
      name_search: 'POSPROLI',
      name: "Polystichum setiferum 'Proliferum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 271,
      chnn_stock_retail: 271,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E17KD9WR;9KBSX7J8;ZRXC8S92;V5EYEGD2;K2J9LX5Y;NCY39L6P;K83TKTP3;PEPS5LEA',
      statusContent: 'dot',
      hash: '74VDDRHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74VDDRHJ'
    },
    {
      status: 210,
      sku: 'PM0083977',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319743',
      rtl_batch_array: '6319743',
      name_search: 'LAANALBA',
      name: "Lavandula angustifolia 'Nana Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SGP8V1GL;Z1L1E8XR;NLACR75A;8431CTSE',
      statusContent: 'dot',
      hash: '27HLT3VA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27HLT3VA'
    },
    {
      status: 210,
      sku: 'PM0083978',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319749',
      rtl_batch_array: '6319749',
      name_search: 'LYVPLENA',
      name: "Lychnis viscaria 'Plena'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HED9TZ4H',
      statusContent: 'dot',
      hash: 'X8BX5XAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8BX5XAZ'
    },
    {
      status: 210,
      sku: 'PM0083979',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319750',
      rtl_batch_array: '6319750',
      name_search: 'LYSSWIRL',
      name: "Lythrum salicaria 'Swirl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E91RTLW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E91RTLW7'
    },
    {
      status: 810,
      sku: 'PM0083980',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319751',
      rtl_batch_array: '6319751',
      name_search: 'MESAPFEL',
      name: "Mentha suaveolens 'Apfel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '54AN9JNX;N8C4TL1C;9ECLNAJS;E82E56WY;RPHRW3G7;4RXS6PXS;NP4HR769',
      statusContent: 'dot',
      hash: 'A4RSJJBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4RSJJBL'
    },
    {
      status: 210,
      sku: 'PM0083981',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382300',
      rtl_batch_array: '6382300, 6319755',
      name_search: 'MOLCHEID',
      name: "Molinia caerulea 'Heidezwerg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4450,
      chnn_stock_retail: 10764,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZJZT81GW;KXBNJPPL;6DSJ2HZY;97B34YXC;HYH1DGC9;E3P11ND1;TCX66N9E;RAZV798T;JZA3TLSS;XLCEG15L',
      statusContent: 'dot',
      hash: 'TG645XYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TG645XYR'
    },
    {
      status: 210,
      sku: 'PM0083982',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319756',
      rtl_batch_array: '6319756',
      name_search: 'SAPOFFIC',
      name: 'Saponaria officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 547,
      chnn_stock_retail: 547,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4HDYWR3C;238R6RYL',
      statusContent: 'dot',
      hash: '15JEYXD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15JEYXD9'
    },
    {
      status: 810,
      sku: 'PM0083983',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319757',
      rtl_batch_array: '6371217, 6319757',
      name_search: 'SCSSSIGN',
      name: "Schizachyrium scoparium 'Smoke Signal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8G81YXWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8G81YXWP'
    },
    {
      status: 210,
      sku: 'PM0083984',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319758',
      rtl_batch_array: '6319758',
      name_search: 'SESALBUM',
      name: "Sedum spurium 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEZS7VG7;CSL18XH9',
      statusContent: 'dot',
      hash: 'J14EW98Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J14EW98Y'
    },
    {
      status: 210,
      sku: 'PM0083985',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319759',
      rtl_batch_array: '6319759',
      name_search: 'SIDIOICA',
      name: 'Silene dioica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 306,
      chnn_stock_retail: 306,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EVVNJR48;YYD8H9LZ;5TBSPWRA;9BCYNTAA',
      statusContent: 'dot',
      hash: 'ZNH3EJPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNH3EJPD'
    },
    {
      status: 210,
      sku: 'PM0083986',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319760',
      rtl_batch_array: '6319760',
      name_search: 'SOLLEMON',
      name: "Solidago 'Little Lemon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ALGTZK6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ALGTZK6G'
    },
    {
      status: 210,
      sku: 'PM0083987',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319762',
      rtl_batch_array: '6319762',
      name_search: 'VEEVELIN',
      name: "Veronica 'Eveline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NWZEG9PT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWZEG9PT'
    },
    {
      status: 210,
      sku: 'PM0083988',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319764',
      rtl_batch_array: '6319764',
      name_search: 'VELFLADY',
      name: "Veronica longifolia 'First Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1L425WGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1L425WGW'
    },
    {
      status: 210,
      sku: 'PM0083989',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319765',
      rtl_batch_array: '6319765',
      name_search: 'VELPEVEL',
      name: "Veronica longifolia 'Pink Eveline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HXAK95H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXAK95H6'
    },
    {
      status: 810,
      sku: 'PM0083990',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319766',
      rtl_batch_array: '6319766',
      name_search: 'VEMDMART',
      name: "Veronica media 'Dark Martje'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 87,
      chnn_stock_retail: 87,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZCBAJPV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZCBAJPV'
    },
    {
      status: 210,
      sku: 'PM0083991',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319768',
      rtl_batch_array: '6319768',
      name_search: 'VESBBLUE',
      name: "Veronica 'Sunny Border Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZVH7ASP5;E21TGXP9',
      statusContent: 'dot',
      hash: '44TPB5EG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44TPB5EG'
    },
    {
      status: 210,
      sku: 'PM0083992',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319770',
      rtl_batch_array: '6319770',
      name_search: 'DACAROTA',
      name: 'Daucus carota',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '66VB9SPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66VB9SPW'
    },
    {
      status: 210,
      sku: 'PM0083993',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320246',
      rtl_batch_array: '6320246',
      name_search: 'ECHMOON',
      name: "Echinacea 'Harvest Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 507,
      chnn_stock_retail: 507,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VG3KX92X;48XEPLEG;77DY4TJA;D16TL7XZ',
      statusContent: 'dot',
      hash: 'WDWECBEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WDWECBEA'
    },
    {
      status: 810,
      sku: 'PM0083994',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320838',
      rtl_batch_array: '6320838',
      name_search: 'ARLACTIF',
      name: 'Artemisia lactiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z6PNEGLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6PNEGLS'
    },
    {
      status: 210,
      sku: 'PM0083995',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320843',
      rtl_batch_array: '6320843',
      name_search: 'ASMROUGE',
      name: "Astrantia 'Moulin Rouge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 164,
      chnn_stock_retail: 164,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5NHBPDD7;R78JP5S1',
      statusContent: 'dot',
      hash: 'G1RJ92WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G1RJ92WY'
    },
    {
      status: 810,
      sku: 'PM0083996',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320847',
      rtl_batch_array: '6320847',
      name_search: 'DIGOLDCR',
      name: 'Digitalis Goldcrest',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TH9WRLRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TH9WRLRS'
    },
    {
      status: 210,
      sku: 'PM0083997',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320866',
      rtl_batch_array: '6320866',
      name_search: 'ECSRED',
      name: 'Echinacea Sunseekers Red',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4VJTBNXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VJTBNXP'
    },
    {
      status: 810,
      sku: 'PM0083998',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320872',
      rtl_batch_array: '6320872',
      name_search: 'GECSUBCA',
      name: 'Geranium cinereum subcaulescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N8ZY2GD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8ZY2GD1'
    },
    {
      status: 210,
      sku: 'PM0083999',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320873',
      rtl_batch_array: '6320873',
      name_search: 'GEHBLUES',
      name: 'Geranium Havanna Blues',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HDP2XJ5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDP2XJ5G'
    },
    {
      status: 810,
      sku: 'PM0084000',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320874',
      rtl_batch_array: '6320874',
      name_search: 'HERJEWEL',
      name: "Helenium 'Red Jewel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 52,
      chnn_stock_retail: 52,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y2TB389Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2TB389Z'
    },
    {
      status: 210,
      sku: 'PM0084001',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320876',
      rtl_batch_array: '6320876',
      name_search: 'HEAMYST',
      name: "Heuchera 'Amethyst Myst'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVER41RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVER41RW'
    },
    {
      status: 210,
      sku: 'PM0084002',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320877',
      rtl_batch_array: '6320877',
      name_search: 'HEACRISP',
      name: "Heuchera 'Apple Crisp'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LBERP718;SYPPALB3;N9AN7KS3;18Y8SGZT;773Z27EC;J98K6P9V;H5ZRGGJC',
      statusContent: 'dot',
      hash: 'BGEX6K86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGEX6K86'
    },
    {
      status: 810,
      sku: 'PM0084003',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320888',
      rtl_batch_array: '6320888',
      name_search: 'PAOPPLUM',
      name: "Papaver orientale 'Patty's Plum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EPEHXLAK;TAK3PTBV;J718XS5J;WJ47JLHD;6KPC47D3;GR9TZZ3C;94V82JWE;8TV81SE7',
      statusContent: 'dot',
      hash: 'A8YCC34H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8YCC34H'
    },
    {
      status: 210,
      sku: 'PM0084004',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6320893',
      rtl_batch_array: '6320893',
      name_search: 'PEAWEAST',
      name: "Persicaria amplexicaulis 'White Eastfield'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 408,
      chnn_stock_retail: 408,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RD2YK9RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD2YK9RL'
    },
    {
      status: 210,
      sku: 'PM0084005',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320894',
      rtl_batch_array: '6386192, 6320894',
      name_search: 'SACEBOWL',
      name: "Santolina chamaecyparissus 'Edward Bowles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 292,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7AZT23CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AZT23CZ'
    },
    {
      status: 210,
      sku: 'PM0084006',
      core_name: 'Plant',
      sppl_ean: '8720664653078',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5552339',
      rtl_batch_array: '5552339',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'LX7SXWJW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LX7SXWJW'
    },
    {
      status: 810,
      sku: 'PM0084007',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321860',
      rtl_batch_array: '6321860',
      name_search: 'ACHMSSED',
      name: "Achillea millefolium 'Saucy Seduction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TTLDAJH3;7HC1AD4K;DP8EGHRZ',
      statusContent: 'dot',
      hash: 'RZNN4D2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZNN4D2J'
    },
    {
      status: 210,
      sku: 'PM0084008',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321861',
      rtl_batch_array: '6369219, 6321861',
      name_search: 'AGBPURPL',
      name: 'Agastache Beelicious Purple',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 789,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2RAJA8N7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RAJA8N7'
    },
    {
      status: 210,
      sku: 'PM0084009',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321864',
      rtl_batch_array: '6321864',
      name_search: 'AGLINDA',
      name: "Agastache 'Linda'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2HDDLCL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2HDDLCL9'
    },
    {
      status: 810,
      sku: 'PM0084010',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321866',
      rtl_batch_array: '6321866',
      name_search: 'AGRPOSIE',
      name: "Agastache 'Rosie Posie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BD8S7KBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BD8S7KBK'
    },
    {
      status: 210,
      sku: 'PM0084011',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321873',
      rtl_batch_array: '6321873',
      name_search: 'ALOFFICI',
      name: 'Althaea officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RTSS6JK3;T5KNWB7N;JPDWT88X;JD9ASY4A;K1KG7CZ6;YY6278E9;8XLZNVNK;SGYP9N9T;92895A2L;J55P2Y7N;DYCXWC5P;94T9L7VR;95YDN1JH;C88XP661',
      statusContent: 'dot',
      hash: 'TKTC3KAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKTC3KAP'
    },
    {
      status: 210,
      sku: 'PM0084012',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321878',
      rtl_batch_array: '6321878',
      name_search: 'ANLEVEIL',
      name: 'Anemone leveillei',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6YNC7EYE',
      statusContent: 'dot',
      hash: 'LTXABXSC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTXABXSC'
    },
    {
      status: 210,
      sku: 'PM0084013',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321879',
      rtl_batch_array: '6321879',
      name_search: 'ANGRAVEO',
      name: 'Anethum graveolens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1E47983Z;8XN69PNY;2VBHWRVZ;ZK7ASS1S;29ED4VC3;4VXEH4WY;JKJ2PNYR;GDTZTXKB;868GXXLJ;XPJWDXX5;W4YWRX8S;BD9V8D8C;2VT7S1D1;L5NPJPL6;SWK679S3;XWL97JWR;E55BZ5JA;K1D6XLPJ;41KVWALY',
      statusContent: 'dot',
      hash: '4GH596TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4GH596TV'
    },
    {
      status: 210,
      sku: 'PM0084014',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321884',
      rtl_batch_array: '6321884',
      name_search: 'ARMSPLEN',
      name: "Armeria maritima 'Splendens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 0.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7W9819LA;GCGBAR2L;8HZLZ5A1;SH6T6YA2;EXJR741Z;CABJZDSL;WSBPEVKD;9G6EPLSV;BHPJBCNE',
      statusContent: 'dot',
      hash: '65J62GHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65J62GHH'
    },
    {
      status: 210,
      sku: 'PM0084015',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321885',
      rtl_batch_array: '6321885',
      name_search: 'ARLVFINN',
      name: "Artemisia ludoviciana 'Valerie Finnis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XGD2V1ZZ;RANLB274',
      statusContent: 'dot',
      hash: 'CVBKKTXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVBKKTXS'
    },
    {
      status: 210,
      sku: 'PM0084016',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321887',
      rtl_batch_array: '6321887',
      name_search: 'ASBARBAD',
      name: "Aster 'Barbados'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NNP6HDXJ;9EG9K186;1SHK7XGJ;P4229LRY;C73NER94',
      statusContent: 'dot',
      hash: '56XZPAX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56XZPAX1'
    },
    {
      status: 810,
      sku: 'PM0084017',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321888',
      rtl_batch_array: '6321888',
      name_search: 'ASBLAGUN',
      name: "Aster (D) 'Blaue Lagune'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PJVL6WBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJVL6WBN'
    },
    {
      status: 810,
      sku: 'PM0084018',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321902',
      rtl_batch_array: '6321902',
      name_search: 'COVRRED',
      name: "Coreopsis verticillata 'Ruby Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2K6BS6WC',
      statusContent: 'dot',
      hash: '1A2Z2NV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1A2Z2NV4'
    },
    {
      status: 810,
      sku: 'PM0084019',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321903',
      rtl_batch_array: '6321903',
      name_search: 'CRCORDIF',
      name: 'Crambe cordifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6148X2ZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6148X2ZT'
    },
    {
      status: 210,
      sku: 'PM0084020',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321906',
      rtl_batch_array: '6321906',
      name_search: 'DESSKIES',
      name: "Delphinium (P) 'Summer Skies'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WYAL955B;X75HHVY7;4KWWT21S',
      statusContent: 'dot',
      hash: 'RR1NWH1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR1NWH1K'
    },
    {
      status: 210,
      sku: 'PM0084021',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321907',
      rtl_batch_array: '6321907',
      name_search: 'DIGPJEWE',
      name: "Dianthus gratianopolitanus 'Pink Jewel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2WB1Y4N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WB1Y4N5'
    },
    {
      status: 210,
      sku: 'PM0084022',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321908',
      rtl_batch_array: '6321908',
      name_search: 'DIGROTKA',
      name: "Dianthus gratianopolitanus 'Rotk\u00e4ppchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 172,
      chnn_stock_retail: 172,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H8E9GGBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8E9GGBN'
    },
    {
      status: 210,
      sku: 'PM0084023',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321914',
      rtl_batch_array: '6321914',
      name_search: 'ECBANNAT',
      name: 'Echinops bannaticus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1738,
      chnn_stock_retail: 1738,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PTSKN5DS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PTSKN5DS'
    },
    {
      status: 210,
      sku: 'PM0084024',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321916',
      rtl_batch_array: '6321916',
      name_search: 'ELMAGELL',
      name: 'Elymus magellanicus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K5547141;PC22KVVP;1NTY3BJN',
      statusContent: 'dot',
      hash: 'D2ZZ7ST8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2ZZ7ST8'
    },
    {
      status: 810,
      sku: 'PM0084025',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321920',
      rtl_batch_array: '6321920',
      name_search: 'EUMALBUM',
      name: "Eupatorium maculatum 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K7DHEB1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7DHEB1A'
    },
    {
      status: 210,
      sku: 'PM0084026',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321923',
      rtl_batch_array: '6321923',
      name_search: 'EUREDWIN',
      name: 'Euphorbia Redwing',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PPDANJX4;S3D5JZZT;S78BLLC8;ECATADHA;6ZVL558G;8ZD5CP1S;XS4SW84V;G3GHW6KB;W2N29LH5;L28V6YCT;GSTXPSVL;82V38G3Y;VKRKSW87;PPHS8D9Z;BB6D8P2D;LY9Z999T;H5LXJKB9;NZVJZVGK;A24D2NT3;94N6A28L;K9J1P2G5;YR5KBJEV',
      statusContent: 'dot',
      hash: 'N71773V3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N71773V3'
    },
    {
      status: 810,
      sku: 'PM0084027',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321924',
      rtl_batch_array: '6321924',
      name_search: 'EUBLACKB',
      name: 'Euphorbia Blackbird',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WD9ZKJRN',
      statusContent: 'dot',
      hash: 'EV4KKEW6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EV4KKEW6'
    },
    {
      status: 210,
      sku: 'PM0084028',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321925',
      rtl_batch_array: '6321925',
      name_search: 'FEGAZURI',
      name: "Festuca glauca 'Azurit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4JNY7394',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JNY7394'
    },
    {
      status: 210,
      sku: 'PM0084029',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321926',
      rtl_batch_array: '6321926',
      name_search: 'FEGBLAUG',
      name: "Festuca glauca 'Blauglut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HKLLH7S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKLLH7S2'
    },
    {
      status: 210,
      sku: 'PM0084030',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321927',
      rtl_batch_array: '6386076, 6321927',
      name_search: 'ASSNANUS',
      name: "Aster sedifolius 'Nanus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 263,
      chnn_stock_retail: 983,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVDE84NK;D9S314D3',
      statusContent: 'dot',
      hash: '51VWEEJ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51VWEEJ1'
    },
    {
      status: 810,
      sku: 'PM0084031',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321928',
      rtl_batch_array: '6321928',
      name_search: 'ASUWSCHI',
      name: "Aster umbellatus 'Weisser Schirm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XVRRPKKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVRRPKKP'
    },
    {
      status: 210,
      sku: 'PM0084032',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321933',
      rtl_batch_array: '6321933',
      name_search: 'CANTRIUM',
      name: "Calamintha nepeta 'Triumphator'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 145,
      chnn_stock_retail: 145,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T1HT4VL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T1HT4VL6'
    },
    {
      status: 210,
      sku: 'PM0084033',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6321935',
      rtl_batch_array: '6321935',
      name_search: 'AMCANESC',
      name: 'Amorpha canescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E8KBNE7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8KBNE7H'
    },
    {
      status: 810,
      sku: 'PM0084034',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338925',
      rtl_batch_array: '6338925',
      name_search: 'ACSBNEGL',
      name: "Actaea simplex 'Black Negligee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 2.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHLDZ2KS',
      statusContent: 'dot',
      hash: 'WAGL8S2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WAGL8S2Z'
    },
    {
      status: 210,
      sku: 'PM0084035',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369725',
      rtl_batch_array: '6369725, 6338927',
      name_search: 'ASMSBILL',
      name: "Astrantia major 'Star of Billion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 828,
      chnn_stock_retail: 930,
      sppl_order_minimum: 3,
      sppl_prcp: 1.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPVJPCL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPVJPCL4'
    },
    {
      status: 810,
      sku: 'PM0084036',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338931',
      rtl_batch_array: '6338931',
      name_search: 'BAALBA',
      name: 'Baptisia alba',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 47,
      chnn_stock_retail: 47,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JZNB6VE1;RBAC31ZD;Z3XDHC3H;EEEKWHBX',
      statusContent: 'dot',
      hash: 'YJYE4LYH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJYE4LYH'
    },
    {
      status: 810,
      sku: 'PM0072928',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6253796',
      rtl_batch_array: '6253796',
      name_search: 'AKQUINAT',
      name: 'Akebia quinata',
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_prcp: 4.014,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YA8H49S7;1PD4YN1L;XR8L16NA;L23SYYRZ;XPSYNY1T;WVH7DEDD;1RGDRZHP;LKYER7P7;6SV47663;B1V71WA5;84ZGCLTK;GPRZ1B8P;TRA52T42;996S9XYN',
      statusContent: 'dot',
      hash: '5W2LTWSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W2LTWSD'
    },
    {
      status: 910,
      sku: 'PM0075303',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLMAYLEE',
      name: "Clematis 'Mayleen'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        '49BSDVK6;JBZH1GCG;4HS1SL2Z;ZC47WV66;PCNXCJJ4;XTG8D194;9PS6X6S3;745RCDAH;9XX3PK1X;LHL95ZG4;YYD2BT52;44EYZZ61;KEC99YA6;YPDPT9Y7',
      statusContent: 'dot',
      hash: 'HAK37SP7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HAK37SP7'
    },
    {
      status: 210,
      sku: 'PM0083792',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356653',
      rtl_batch_array: '6356653',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '125150C3',
      rng_range_identifier: 'H125150C3',
      rng_range_description: 'H125 cm 150 cm C3',
      sppl_stock_available: 473,
      chnn_stock_retail: 473,
      sppl_prcp: 2.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'RYNTJGX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYNTJGX7'
    },
    {
      status: 810,
      sku: 'PM0075870',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6274213',
      rtl_batch_array: '6274213',
      name_search: 'TRJVARIE',
      name: "Trachelospermum jasminoides 'Variegatum'",
      sppl_size_code: '070C23L5PL1',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.269,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58CWV59E;JKHER225;RNV8L6T3',
      statusContent: 'dot',
      hash: 'XCASVCTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCASVCTC'
    },
    {
      status: 210,
      sku: 'PM0076171',
      core_name: 'Plant',
      sppl_ean: '8720349447466',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5606389',
      rtl_batch_array: '5606389',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 5,
      sppl_prcp: 3.089,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: 'JEGDCW5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JEGDCW5A'
    },
    {
      status: 210,
      sku: 'PM0076165',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284961',
      rtl_batch_array: '6284961',
      name_search: 'PHODOR',
      name: "Physocarpus opulifolius Diable d' Or",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S5ASBAKV;N75B8AS7;AWX8P2XJ;KZTVG5R1',
      statusContent: 'dot',
      hash: 'X3T68L76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3T68L76'
    },
    {
      status: 210,
      sku: 'PM0076161',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284572',
      rtl_batch_array: '6300265, 6284572',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 172,
      chnn_stock_retail: 1422,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: 'ZKBH29XV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZKBH29XV'
    },
    {
      status: 210,
      sku: 'PM0075871',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350402',
      rtl_batch_array: '6301645, 6350402',
      name_search: 'HEUFVIOL',
      name: 'Heuchera Frosted Violet',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1502,
      chnn_stock_retail: 1551,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTYBHYKJ;A8Z1KRB1;NYCCWC8K;X91AVP9T;K5S94243',
      statusContent: 'dot',
      hash: '5CW12WE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CW12WE2'
    },
    {
      status: 210,
      sku: 'PM0075878',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6252743',
      rtl_batch_array: '6252743, 6305229',
      name_search: 'PUBENSIG',
      name: "Pulmonaria 'Blue Ensign'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1760,
      chnn_stock_retail: 2643,
      sppl_prcp: 2.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61EH4PL4',
      statusContent: 'dot',
      hash: '9YLA6CAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YLA6CAV'
    },
    {
      status: 210,
      sku: 'PM0075880',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254663',
      rtl_batch_array: '6254663',
      name_search: 'BERABEND',
      name: "Bergenia 'Abendglut'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 864,
      chnn_stock_retail: 864,
      sppl_prcp: 2.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJRB6XT3',
      statusContent: 'dot',
      hash: '16RJ18X3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16RJ18X3'
    },
    {
      status: 210,
      sku: 'PM0075890',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234602',
      rtl_batch_array: '6234602',
      name_search: 'BESILBER',
      name: "Bergenia 'Silberlicht'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1189,
      chnn_stock_retail: 1189,
      sppl_prcp: 2.501,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VGT4L25W;EP26NBYA;J5KNXZ2P;2LK6V6TV;LPXEGABJ',
      statusContent: 'dot',
      hash: '81WZYWL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81WZYWL6'
    },
    {
      status: 210,
      sku: 'PM0075874',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6305215',
      rtl_batch_array: '6252469, 6305215',
      name_search: 'ASMROMA',
      name: "Astrantia major 'Roma'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 761,
      chnn_stock_retail: 2824,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VLA9SDR2;4B7Z6T2W;W19K5T3K;9NN1845H;52HLJGZ6;A9ZBVAR6;D3K5TW7R;9LZ56S2Z;G65KPBBX;AGY1EDH4;8T78RYHH',
      statusContent: 'dot',
      hash: '9689HY32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9689HY32'
    },
    {
      status: 210,
      sku: 'PM0075881',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254671',
      rtl_batch_array: '6254671',
      name_search: 'BRMVARIE',
      name: "Brunnera macrophylla 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_prcp: 2.554,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C19LP6S4;JHGX8D28',
      statusContent: 'dot',
      hash: '8EVZAG2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8EVZAG2G'
    },
    {
      status: 210,
      sku: 'PM0075892',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234697',
      rtl_batch_array: '6234697',
      name_search: 'ECBBGLOW',
      name: "Echinops bannaticus 'Blue Glow'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1457,
      chnn_stock_retail: 1457,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5424Z5ES',
      statusContent: 'dot',
      hash: 'C6CYPEZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6CYPEZV'
    },
    {
      status: 210,
      sku: 'PM0075876',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252593',
      rtl_batch_array: '6252593',
      name_search: 'GEPATRIC',
      name: "Geranium 'Patricia'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1407,
      chnn_stock_retail: 1407,
      sppl_prcp: 2.31,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LR3WA38L;2GZNXHPZ',
      statusContent: 'dot',
      hash: 'RHNALCVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHNALCVC'
    },
    {
      status: 210,
      sku: 'PM0075883',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254744',
      rtl_batch_array: '6254744',
      name_search: 'GETMONST',
      name: "Geranium 'Tiny Monster'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 446,
      chnn_stock_retail: 446,
      sppl_prcp: 2.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7YYRP71K',
      statusContent: 'dot',
      hash: 'LNYHXXH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNYHXXH2'
    },
    {
      status: 210,
      sku: 'PM0075875',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252585',
      rtl_batch_array: '6252585',
      name_search: 'GEHPLENU',
      name: "Geranium himalayense 'Plenum'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VBS3BEG;ATA2BKZT',
      statusContent: 'dot',
      hash: 'ZGWL81AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZGWL81AA'
    },
    {
      status: 210,
      sku: 'PM0075879',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252794',
      rtl_batch_array: '6252794',
      name_search: 'TYANGUST',
      name: 'Typha angustifolia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 1.738,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YLZZXJR4;W4ERHZ3X;T62HSYW4;J5V6SANT;BPB68GL7;RR7R2T6C;E72E3V7Z;W4JHPKCL;HYKGK1RX',
      statusContent: 'dot',
      hash: 'ZXX47LTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXX47LTY'
    },
    {
      status: 210,
      sku: 'PM0076206',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257497',
      rtl_batch_array: '6257497',
      name_search: 'TABSUMME',
      name: "Taxus baccata 'Summergold'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 2317,
      chnn_stock_retail: 2317,
      sppl_prcp: 3.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9KSV5SYC;J3ATLRXA;85VREB5P;1B7X7CVY;7XH9WWVH;N261GH3S',
      statusContent: 'dot',
      hash: 'DAEPGKSG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAEPGKSG'
    },
    {
      status: 210,
      sku: 'PM0083793',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356654',
      rtl_batch_array: '6356654',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      sppl_size_code: '150175C3',
      rng_range_identifier: 'H150175C3',
      rng_range_description: 'H150 cm 175 cm C3',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 2.822,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'D9EYT6BB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9EYT6BB'
    },
    {
      status: 910,
      sku: 'PM0076200',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257466',
      rtl_batch_array: '6257466',
      name_search: 'JUCREPAN',
      name: "Juniperus communis 'Repanda'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 3.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TCX3E1WY;AHRHLBLB;XLS3DSDH;8NW6KNDW;GSXVB568;YB6RY9D3;G47EZ7SW;649BBC73;STWVW2RL;YN213YVT;ZYJVR4AG;ARJ5LEEG;NSCSLPPB;LLLY47EE;G9ZP44BJ;E5EEEL2Y;5W75BBW8;43YH4JB9;DAH8E3D3;4J8HVX2E;W3K49ZNW',
      statusContent: 'dot',
      hash: 'XLYRLAN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLYRLAN4'
    },
    {
      status: 210,
      sku: 'PM0076146',
      core_name: 'Plant',
      sppl_ean: '8720349458066',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420649',
      rtl_batch_array: '5420649',
      name_search: 'JUCSUECI',
      name: "Juniperus communis 'Suecica'",
      sppl_size_code: '020025P9',
      rng_range_identifier: 'H020025P9',
      rng_range_description: 'H20 cm 25 cm P9',
      sppl_stock_available: 2400,
      chnn_stock_retail: 2400,
      sppl_order_minimum: 6,
      sppl_prcp: 1.096,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9WZ4BSHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WZ4BSHP'
    },
    {
      status: 210,
      sku: 'PM0076202',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257473',
      rtl_batch_array: '6257473',
      name_search: 'JUPNANA',
      name: "Juniperus procumbens 'Nana'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1302,
      chnn_stock_retail: 1302,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YPH14G7X;KRS7HGXY;V2B488EW;LA1AHTSX;G2D5PA79;DGYVNPD5;KB81N2R4;T1HLSSNG;1YXCNZW9;5B55H4XV;1JRJ3A43',
      statusContent: 'dot',
      hash: 'DT5AYTDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT5AYTDT'
    },
    {
      status: 210,
      sku: 'PM0076203',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257477',
      rtl_batch_array: '6257477',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 5442,
      chnn_stock_retail: 5442,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: 'KL348VTB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KL348VTB'
    },
    {
      status: 210,
      sku: 'PM0076185',
      core_name: 'Plant',
      sppl_ean: '8720664574878',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037973',
      rtl_batch_array: '6037973',
      name_search: 'LOTELLMA',
      name: 'Lonicera tellmanniana',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 376,
      chnn_stock_retail: 376,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W21ECJ3G;JGY84VDN;2JNBTDGT;WGHTB9ZK;LXY88137;9487HK3K',
      statusContent: 'dot',
      hash: 'XALLLVXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XALLLVXP'
    },
    {
      status: 210,
      sku: 'PM0076176',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037963',
      rtl_batch_array: '6037963',
      name_search: 'LOHENRYI',
      name: 'Lonicera henryi',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 5387,
      chnn_stock_retail: 5387,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN37L23T;JG217ECD;8H823RVC;REDLBRVV;CRZV8V5A;TPDWP3EY;7XRTK71Y;EENTKELG;E63ER6TJ;NWD4J7CW;GLSRDX75;J6K74GCC;W8A35BAE;CK844ZSW',
      statusContent: 'dot',
      hash: 'K3VE648C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3VE648C'
    },
    {
      status: 810,
      sku: 'PM0076155',
      core_name: 'Plant',
      sppl_ean: '8720626376700',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420740',
      rtl_batch_array: '5420740',
      name_search: 'WISINENS',
      name: 'Wisteria sinensis',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_prcp: 12.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GR3TKRKN;B8PNDWVB;T1BWX7CJ;ZHBCKZ13;K4W7C5V5;PE78N3BN;JVAC2983;89JLXBDK;9K7V9ELE;1P3HYWHN;XKZ4SZ75;PCPVSE7V;9RC389DS;4BG6DY3J',
      statusContent: 'dot',
      hash: 'YRBPTY7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRBPTY7A'
    },
    {
      status: 210,
      sku: 'PM0076120',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284930',
      rtl_batch_array: '6284930',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 5.502,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: 'TG7Z84LB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TG7Z84LB'
    },
    {
      status: 810,
      sku: 'PM0076121',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284931',
      rtl_batch_array: '6284931',
      name_search: 'CARADICA',
      name: 'Campsis radicans',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 10.595,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GYXZ7DXA;T3B2G547;YD2RVWG1;1CESY5VH;69C1BKNA;EVCK5KY3;93NCZB3H;G3JP3HEX;BHLTX997;PJLJSGYP',
      statusContent: 'dot',
      hash: 'DPTW84BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPTW84BW'
    },
    {
      status: 210,
      sku: 'PM0075078',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307831',
      rtl_batch_array: '6307831',
      name_search: 'FARDRAGO',
      name: "Fargesia 'Red Dragon'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 585,
      chnn_stock_retail: 585,
      sppl_prcp: 9.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RE8CPW5S;BA7WW89W;XS139LK6;GYX9KR53;L9CV5SAJ;CHJRV4AJ;JPHPJW9S;RHS8NYLY;5H92W8HR;59K8HNL5',
      statusContent: 'dot',
      hash: '2ZENE42X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZENE42X'
    },
    {
      status: 210,
      sku: 'PM0076140',
      core_name: 'Plant',
      sppl_ean: '8720626343023',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '4522110',
      rtl_batch_array: '4522110',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: '9PST733P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PST733P'
    },
    {
      status: 210,
      sku: 'PM0076141',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6355350',
      rtl_batch_array: '5206473, 6355350',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 450,
      chnn_stock_retail: 750,
      sppl_prcp: 3.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'Y6S4C6CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6S4C6CT'
    },
    {
      status: 210,
      sku: 'PM0076143',
      core_name: 'Plant',
      sppl_ean: '8720664510388',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420601',
      rtl_batch_array: '5420601',
      name_search: 'CLTLUNDE',
      name: "Clematis 'Tage Lundell'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 895,
      chnn_stock_retail: 895,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JG527SHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JG527SHY'
    },
    {
      status: 210,
      sku: 'PM0076144',
      core_name: 'Plant',
      sppl_ean: '8720353020402',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420604',
      rtl_batch_array: '5420604',
      name_search: 'CLCHRYSO',
      name: 'Clematis chrysocoma',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1989,
      chnn_stock_retail: 1989,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V2DVH828',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2DVH828'
    },
    {
      status: 210,
      sku: 'PM0076147',
      core_name: 'Plant',
      sppl_ean: '8720664572799',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420676',
      rtl_batch_array: '5420676',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 8.053,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: '681TL7YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '681TL7YT'
    },
    {
      status: 210,
      sku: 'PM0076148',
      core_name: 'Plant',
      sppl_ean: '8720664572782',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420677',
      rtl_batch_array: '5420677',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 9.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: 'L7HTT3TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L7HTT3TP'
    },
    {
      status: 210,
      sku: 'PM0076196',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257460',
      rtl_batch_array: '6257460',
      name_search: 'CHPFNANA',
      name: "Chamaecyparis pisifera 'Filifera Nana'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1794,
      chnn_stock_retail: 1794,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6H1P49H2;63BTDXDG;69KRN8NB;2PJJVP62',
      statusContent: 'dot',
      hash: 'BGTEX77W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGTEX77W'
    },
    {
      status: 210,
      sku: 'PM0076150',
      core_name: 'Plant',
      sppl_ean: '8720353009063',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420713',
      rtl_batch_array: '5420713',
      name_search: 'TADISTIC',
      name: 'Taxodium distichum',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '57YKZK3C;1SG6WLB2;EWN73NH9;XJYW3ZXR;KN9TTC1W;5G93H264;YJACBVDD;YVLPL8RW;EZ173YX5;S3JTC69H;VNPRRPSZ;NTAWXLN1;869EL85E',
      statusContent: 'dot',
      hash: '2X6VK3VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2X6VK3VX'
    },
    {
      status: 210,
      sku: 'PM0076151',
      core_name: 'Plant',
      sppl_ean: '8720353014524',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420717',
      rtl_batch_array: '5420717',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 6,
      sppl_prcp: 1.194,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: 'JSYYLGKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSYYLGKJ'
    },
    {
      status: 210,
      sku: 'PM0076152',
      core_name: 'Plant',
      sppl_ean: '8720626375369',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420721',
      rtl_batch_array: '5420721',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 2312,
      chnn_stock_retail: 2312,
      sppl_order_minimum: 6,
      sppl_prcp: 1.145,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'S24NJCR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S24NJCR2'
    },
    {
      status: 210,
      sku: 'PM0076153',
      core_name: 'Plant',
      sppl_ean: '8720626375451',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420724',
      rtl_batch_array: '5420724',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 6,
      sppl_prcp: 1.194,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: 'WRKKYHPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRKKYHPS'
    },
    {
      status: 210,
      sku: 'PM0076156',
      core_name: 'Plant',
      sppl_ean: '8720349448029',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420746',
      rtl_batch_array: '5420746',
      name_search: 'AMACONIT',
      name: 'Ampelopsis aconitifolia',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 151,
      chnn_stock_retail: 151,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XANBBSJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XANBBSJ8'
    },
    {
      status: 210,
      sku: 'PM0076157',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284562',
      rtl_batch_array: '6284562',
      name_search: 'SCVERTIC',
      name: 'Sciadopitys verticillata',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 4020,
      chnn_stock_retail: 4020,
      sppl_prcp: 8.867,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CBN1HY6;N6RBRE96;A3AAXPA6;9J7NWD3V;9X3DVLJP;D7NER187',
      statusContent: 'dot',
      hash: 'JJK91PR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJK91PR8'
    },
    {
      status: 210,
      sku: 'PM0076158',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284565',
      rtl_batch_array: '6284565',
      name_search: 'THOFCHIE',
      name: 'Thuja occidentalis Fire Chief',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1729,
      chnn_stock_retail: 1729,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NW5B9S21;29XEZW8R;63EB4P4N;ADK85E5T;P7JCRDNL;V12TW8VZ;SDYPAH3T;H2D8JX71;G4ALA8BD;H4GXYNJT;2H2ERPNG;ED3V7YKY;1R37SR8P',
      statusContent: 'dot',
      hash: '8N6EDNEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8N6EDNEW'
    },
    {
      status: 210,
      sku: 'PM0076159',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284566',
      rtl_batch_array: '6284566',
      name_search: 'PIGPERFE',
      name: 'Picea glauca Perfecta',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3491,
      chnn_stock_retail: 3491,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NLERT41',
      statusContent: 'dot',
      hash: '9AGL158K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AGL158K'
    },
    {
      status: 210,
      sku: 'PM0076160',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300226',
      rtl_batch_array: '6300226, 6284570',
      name_search: 'RORUBIGI',
      name: 'Rosa rubiginosa (o)',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 3813,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ESL6G9R5;H5XCXLB1',
      statusContent: 'dot',
      hash: '8TZD3X3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8TZD3X3A'
    },
    {
      status: 210,
      sku: 'PM0076162',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284958',
      rtl_batch_array: '6284958',
      name_search: 'ARPVIKIN',
      name: "Aronia prunifolia 'Viking'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YC78YB;8JRTXES5;CW46RE5L;XD2CED58;NX1GBSDX;S5E4T7PW;7V78YR9S;L83Y4B14',
      statusContent: 'dot',
      hash: 'SZALV9DL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZALV9DL'
    },
    {
      status: 210,
      sku: 'PM0076163',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284959',
      rtl_batch_array: '6284959',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'CRN9PZTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRN9PZTC'
    },
    {
      status: 910,
      sku: 'PM0076166',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHOLANGE',
      name: 'Physocarpus opulifolius Little Angel',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore: 'GGNJ6Y27',
      statusContent: 'dot',
      hash: '8ACTVLJG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8ACTVLJG'
    },
    {
      status: 810,
      sku: 'PM0076168',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284965',
      rtl_batch_array: '6284965',
      name_search: 'SANGPURP',
      name: "Sambucus nigra 'Guincho Purple'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4S1LVB99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4S1LVB99'
    },
    {
      status: 210,
      sku: 'PM0076183',
      core_name: 'Plant',
      sppl_ean: '8720626358416',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037971',
      rtl_batch_array: '6037971',
      name_search: 'LOPSEROT',
      name: "Lonicera periclymenum 'Serotina'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 745,
      chnn_stock_retail: 745,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJPNZYE2;ZN4CAHN1;Z2YKXPSL;16P279GS;E6LWJWGT;48YZ64JZ;75J4REZ7;R6YWKD37;WAVNK8D2',
      statusContent: 'dot',
      hash: 'E4L22VL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4L22VL4'
    },
    {
      status: 910,
      sku: 'PM0076199',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257465',
      rtl_batch_array: '6257465',
      name_search: 'JUCGCARP',
      name: "Juniperus communis 'Green Carpet'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 3.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8JCWSDK;ZR2S66Y8;8LENK36Y;PCPJKSAW;AR8SW9TC;R52VR1LR;PHC1CE39;TKSV3RR5;Z65N4W5A;SHYT7DT2;DEB813ZV;X12G2J1L;Y8AGGRKY;2GX8TKBR;E2GBBLY7;SXP5663N;1BR9CVW8;7N3R494Y;E1BY7K57',
      statusContent: 'dot',
      hash: '2826HZGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2826HZGZ'
    },
    {
      status: 210,
      sku: 'PM0076174',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 2,
      btch_active_retail: '6362917',
      rtl_batch_array: '6037960, 6362917',
      name_search: 'LOHABEAU',
      name: "Lonicera heckrottii 'American Beauty'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 961,
      sppl_order_minimum: 3,
      sppl_prcp: 1.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YBZR4H14;ZE7A617B;K48637SS;CS33DV79;STBKRB2S;SPVEL8W9;83VR4644;Y3DA9VWE;EDXZYAK4;W17ZCDHZ',
      statusContent: 'dot',
      hash: 'HCAL8GHH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCAL8GHH'
    },
    {
      status: 210,
      sku: 'PM0076177',
      core_name: 'Plant',
      sppl_ean: '8720349421862',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037964',
      rtl_batch_array: '6037964',
      name_search: 'LOJHPROL',
      name: "Lonicera japonica 'Hall's Prolific'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 889,
      chnn_stock_retail: 889,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NHKL1DZY;PEANXCLV;CK5DDGCZ;2BXS6WAH;D3X52NJ1;5J46GSBA;5H8AKRL2;V11VCCSK;XE1KT76N;YV37XS54;KJAY3KGK',
      statusContent: 'dot',
      hash: 'T4ZY1TS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T4ZY1TS5'
    },
    {
      status: 210,
      sku: 'PM0076178',
      core_name: 'Plant',
      sppl_ean: '8720626358133',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037965',
      rtl_batch_array: '6037965',
      name_search: 'LOJHALLI',
      name: "Lonicera japonica 'Halliana'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 438,
      chnn_stock_retail: 438,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6VCL9KE5;6JKL2B7Y;B1KTTATS;WE2LSD3H;XG939NGS;4V9YCXNK',
      statusContent: 'dot',
      hash: '4B6HX14A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B6HX14A'
    },
    {
      status: 210,
      sku: 'PM0076179',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037966',
      rtl_batch_array: '6037966',
      name_search: 'LOJRWORL',
      name: "Lonicera japonica 'Red World'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1183,
      chnn_stock_retail: 1183,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRP8GXKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRP8GXKH'
    },
    {
      status: 210,
      sku: 'PM0076184',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 2,
      btch_active_retail: '6362920',
      rtl_batch_array: '6037972, 6362920',
      name_search: 'LOPERICL',
      name: 'Lonicera periclymenum',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 1.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HYGLHEPG;61EXCRG3;XS5W43AY;VC6L2AA1;HARWJ729',
      statusContent: 'dot',
      hash: '63BL2YB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63BL2YB2'
    },
    {
      status: 210,
      sku: 'PM0076186',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746621',
      rtl_batch_array: '5746621',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '015020P9',
      rng_range_identifier: 'H015020P9',
      rng_range_description: 'H15 cm 20 cm P9',
      sppl_stock_available: 1988,
      chnn_stock_retail: 1988,
      sppl_order_minimum: 6,
      sppl_prcp: 1.343,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '9LAA6LDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9LAA6LDX'
    },
    {
      status: 210,
      sku: 'PM0076187',
      core_name: 'Plant',
      sppl_ean: '8720349480630',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746622',
      rtl_batch_array: '5746622',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 411,
      chnn_stock_retail: 411,
      sppl_prcp: 3.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '4R9377KJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4R9377KJ'
    },
    {
      status: 210,
      sku: 'PM0076188',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746623',
      rtl_batch_array: '5746623',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 589,
      chnn_stock_retail: 589,
      sppl_prcp: 4.239,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'KXPTXH1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXPTXH1H'
    },
    {
      status: 810,
      sku: 'PM0076192',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6255401',
      rtl_batch_array: '6255401',
      name_search: 'PHORBARO',
      name: "Physocarpus opulifolius 'Red Baron'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HB6V655A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HB6V655A'
    },
    {
      status: 210,
      sku: 'PM0076193',
      core_name: 'Plant',
      sppl_ean: '8720664514027',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5857533',
      rtl_batch_array: '5857533',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 231,
      chnn_stock_retail: 231,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: '5BJNDKVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BJNDKVC'
    },
    {
      status: 210,
      sku: 'PM0076194',
      core_name: 'Plant',
      sppl_ean: '8720349472802',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5857534',
      rtl_batch_array: '5857534',
      name_search: 'ILAGKING',
      name: "Ilex altaclerensis 'Golden King'",
      sppl_size_code: '025030P9',
      rng_range_identifier: 'H025030P9',
      rng_range_description: 'H25 cm 30 cm P9',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_order_minimum: 6,
      sppl_prcp: 1.296,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NN5SVZBK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN5SVZBK'
    },
    {
      status: 210,
      sku: 'PM0076195',
      core_name: 'Plant',
      sppl_ean: '8720349449132',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5857535',
      rtl_batch_array: '5857535',
      name_search: 'ILAGKING',
      name: "Ilex altaclerensis 'Golden King'",
      sppl_size_code: '030040P9',
      rng_range_identifier: 'H030040P9',
      rng_range_description: 'H30 cm 40 cm P9',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 6,
      sppl_prcp: 1.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4D5HV7BJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4D5HV7BJ'
    },
    {
      status: 210,
      sku: 'PM0076197',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257463',
      rtl_batch_array: '6257463',
      name_search: 'JUCBALPS',
      name: "Juniperus chinensis 'Blue Alps'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 518,
      chnn_stock_retail: 518,
      sppl_prcp: 3.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8H822KC9;WZD3AJ7H;DBAC7VR1;YED299HD;6TR8KJEW;YCP4NESG;WH27KK7Z;GKK13P73;Y2J5YSEX;YB3HA8X7;5HN5TXHC;9K9Z11JB;JVG243P3;H4E4VNX3;D25GXCTE;ZV74J6G9;PNK11B3H',
      statusContent: 'dot',
      hash: 'A6GJSSKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6GJSSKG'
    },
    {
      status: 210,
      sku: 'PM0076198',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257464',
      rtl_batch_array: '6257464',
      name_search: 'JUCSTRIC',
      name: "Juniperus chinensis 'Stricta'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_prcp: 3.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D8JS3ZSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8JS3ZSY'
    },
    {
      status: 210,
      sku: 'PM0076201',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257471',
      rtl_batch_array: '6257471',
      name_search: 'JUHPWALE',
      name: "Juniperus horizontalis 'Prince of Wales'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1844,
      chnn_stock_retail: 1844,
      sppl_prcp: 3.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HV4G8K7R;4DDP56PC;6Z3RDN63;X1JSG9LP;PS853GCJ;7JRYEC16',
      statusContent: 'dot',
      hash: 'DGY541RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGY541RZ'
    },
    {
      status: 210,
      sku: 'PM0076205',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257486',
      rtl_batch_array: '6257486',
      name_search: 'PIGCONIC',
      name: "Picea glauca 'Conica'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1532,
      chnn_stock_retail: 1532,
      sppl_prcp: 3.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '87PZRZ53;ER3ZT26K;1VRBWBS4;HK4179JR;8DDLCE3H;X1AB1LDA;E9G3Z1V9;8786ZZY5;NN6B56NY;9G3DD8AR;BAGHPL1E;NR6NP3GS;LVS3RC5P;W4AW6Z7S;6YXGS4YZ;GZZR5637;4N46C5N2;GYALTER1;2HGJHCJ1;XZDC85LT;5VGKVJLW;HCC43767;RERBDZS9;EVX3J1EN;D14S3D5G;79C25LCG;61JR648Z;NT5VJGRV;1K91N6WT',
      statusContent: 'dot',
      hash: 'J4DXKKYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4DXKKYE'
    },
    {
      status: 210,
      sku: 'PM0076204',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257482',
      rtl_batch_array: '6257482',
      name_search: 'JUSBSTAR',
      name: "Juniperus squamata 'Blue Star'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 811,
      chnn_stock_retail: 811,
      sppl_prcp: 3.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '223S71E6;YL28ZCAS;BESLT261;C5DRTS5J;JR1P944L;27XBARC5;KE55BKLZ;CYYDK5J8;TP7NVDP8;VJA9BAZY;68BCEN7R;5KJ2YNCK;XX713EV4;4717E53N;LXXWRJ96;SDP1WS9C;2KN4YKZ4;TYC557EP;882YW612;A8PLDHJH;LYT7PRN9;8WHJ75GB;YEL79DVY;XHVVVA2E',
      statusContent: 'dot',
      hash: 'R4KK59X6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4KK59X6'
    },
    {
      status: 210,
      sku: 'PM0076173',
      core_name: 'Plant',
      sppl_ean: '8720626357945',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037959',
      rtl_batch_array: '6037959',
      name_search: 'LOBDSCAR',
      name: "Lonicera brownii 'Dropmore Scarlet'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 483,
      chnn_stock_retail: 483,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HRTY5Z56;KYAA5AE3;LVCECV1Z;82824D6X;PKDCCSHJ;ALG82C2J;KA8C4BHG;N3XT2CAC;CTBHCJAW;H27YVTBB;6TZJWLPY;5SGY7JHZ;YR94JL91;4A1V5NZJ;DETDPH31;NHKSVAWZ;SRZSRK37;SB3HCNRP;CPLS19Y1;A5BP2L6G;YSG8BWYW;5WK11HAP',
      statusContent: 'dot',
      hash: 'VTHKTP1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTHKTP1G'
    },
    {
      status: 210,
      sku: 'PM0076175',
      core_name: 'Plant',
      sppl_ean: '8720626358072',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037961',
      rtl_batch_array: '6037961',
      name_search: 'LOHGOLDF',
      name: "Lonicera heckrottii 'Goldflame'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 786,
      chnn_stock_retail: 786,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K9GGGXCS;SSAH4CPD;RKWJ8RJ2;VCE5YNRG;KVCKEWN4;T522KXAW;X71CA61Y;JJLYGBH9;94Y7V92R;WV2C8H19;LLLHK457;4BWWAL4V;WNA4XLCP',
      statusContent: 'dot',
      hash: '7YB1B5A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YB1B5A4'
    },
    {
      status: 210,
      sku: 'PM0076190',
      core_name: 'Plant',
      sppl_ean: '8720664573055',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746625',
      rtl_batch_array: '5746625',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 407,
      chnn_stock_retail: 407,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: '13PDSRV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '13PDSRV2'
    },
    {
      status: 210,
      sku: 'PM0076189',
      core_name: 'Plant',
      sppl_ean: '8720664573031',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5746624',
      rtl_batch_array: '5746624',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 9.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'B8RVXHYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8RVXHYR'
    },
    {
      status: 210,
      sku: 'PM0076164',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284960',
      rtl_batch_array: '6284960',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.708,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: '8VXZY6G3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VXZY6G3'
    },
    {
      status: 210,
      sku: 'PM0076167',
      core_name: 'Plant',
      btch_manufacturer: 663,
      rtl_batch_array_total: 2,
      btch_active_retail: '6334219',
      rtl_batch_array: '6284964, 6334219',
      name_search: 'PRCISTEN',
      name: 'Prunus cistena',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 160,
      chnn_stock_retail: 410,
      sppl_prcp: 8.632,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K6APNVSC;WNLX1WCR;EJ9RSS6B;G8KS3DHA;WG1X8AYX;LAPNRY8Z;LJ3DPPRH;BS84CE15;K8T7ZAPH;22E42T52;LLVXT56Z;H31XB632;L49BT22N;8LGJJ42L;B3PHATZZ;V614TBLN;12GYXNTD',
      statusContent: 'dot',
      hash: 'RGVT9NHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RGVT9NHC'
    },
    {
      status: 210,
      sku: 'PM0075885',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254831',
      rtl_batch_array: '6254831',
      name_search: 'SAHPBRUS',
      name: "Sanguisorba hakusanensis 'Pink Brushes'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_prcp: 2.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KKKC9A4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KKKC9A4D'
    },
    {
      status: 210,
      sku: 'PM0075877',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252691',
      rtl_batch_array: '6252691',
      name_search: 'MOASKYRA',
      name: "Molinia arundinacea 'Skyracer'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W1Y7PXL9;WGBPZDJZ;SKYKN8P6;1Y89EDZL;GN6S2XY1',
      statusContent: 'dot',
      hash: 'G67AE68D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G67AE68D'
    },
    {
      status: 210,
      sku: 'PM0076191',
      core_name: 'Plant',
      sppl_ean: '8720349474134',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '5837448',
      rtl_batch_array: '5837448, 6254782',
      name_search: 'MISKFONT',
      name: "Miscanthus sinensis 'Kleine Font\u00e4ne'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 675,
      chnn_stock_retail: 1125,
      sppl_prcp: 1.951,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPX27258;HHV2A21K',
      statusContent: 'dot',
      hash: 'AWV32YSN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWV32YSN'
    },
    {
      status: 210,
      sku: 'PM0075884',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254798',
      rtl_batch_array: '6254798',
      name_search: 'PEACASSI',
      name: "Pennisetum alopecuroides 'Cassian'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 631,
      chnn_stock_retail: 631,
      sppl_prcp: 1.982,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5E34TGNR;CP3TBSRW',
      statusContent: 'dot',
      hash: 'TA46HWPL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TA46HWPL'
    },
    {
      status: 810,
      sku: 'PM0084037',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338934',
      rtl_batch_array: '6338934',
      name_search: 'ECPOSTMA',
      name: "Echinacea 'Postman'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 2.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '22HL9CZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22HL9CZV'
    },
    {
      status: 210,
      sku: 'PM0076207',
      core_name: 'Plant',
      sppl_ean: '8720349443833',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5955985',
      rtl_batch_array: '5955985',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: '9CNP27VL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CNP27VL'
    },
    {
      status: 210,
      sku: 'PM0076142',
      core_name: 'Plant',
      sppl_ean: '8720664509825',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420599',
      rtl_batch_array: '5420599',
      name_search: 'CLGEORG',
      name: "Clematis 'Georg'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 968,
      chnn_stock_retail: 968,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZS2WCEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZS2WCEA'
    },
    {
      status: 210,
      sku: 'PM0076180',
      core_name: 'Plant',
      sppl_ean: '8720626358379',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037967',
      rtl_batch_array: '6037967',
      name_search: 'LOPBSELE',
      name: "Lonicera periclymenum 'Belgica Select'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 833,
      chnn_stock_retail: 833,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A3C9GH6E',
      statusContent: 'dot',
      hash: 'HP1JJE2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP1JJE2H'
    },
    {
      status: 210,
      sku: 'PM0076182',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6037969',
      rtl_batch_array: '6037969',
      name_search: 'LOPGTHOM',
      name: "Lonicera periclymenum 'Graham Thomas'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 413,
      chnn_stock_retail: 413,
      sppl_order_minimum: 3,
      sppl_prcp: 2.558,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZVADLYDK',
      statusContent: 'dot',
      hash: 'BREWTYP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BREWTYP5'
    },
    {
      status: 210,
      sku: 'PM0076181',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 2,
      btch_active_retail: '5761748',
      rtl_batch_array: '6037968, 5761748',
      name_search: 'LOPBELGI',
      name: "Lonicera periclymenum 'Belgica'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 144,
      chnn_stock_retail: 1010,
      sppl_order_minimum: 3,
      sppl_prcp: 1.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BPRANN1H;GA61NY68',
      statusContent: 'dot',
      hash: '6GKBL1YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GKBL1YT'
    },
    {
      status: 910,
      sku: 'PM0076154',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VICOIGNE',
      name: 'Vitis coignetiae',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      imageCore: 'DE8PRWSA;D5V37SH6',
      statusContent: 'dot',
      hash: 'JLDRZ1N4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JLDRZ1N4'
    },
    {
      status: 210,
      sku: 'PM0076172',
      core_name: 'Plant',
      sppl_ean: '8720353004754',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5606396',
      rtl_batch_array: '5606396',
      name_search: 'COKCHINE',
      name: 'Cornus kousa chinensis',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 197,
      chnn_stock_retail: 197,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D3VNXTXH;976J1BY7;6V6XXLZB;KX222EN5;85ZDPLEL;GAY3EY5K;KC7LJYK5;T2CKGNVJ;A67N45PW',
      statusContent: 'dot',
      hash: 'KHYDGZ6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHYDGZ6B'
    },
    {
      status: 210,
      sku: 'PM0076145',
      core_name: 'Plant',
      sppl_ean: '8720353002460',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420614',
      rtl_batch_array: '5420614',
      name_search: 'CLMRUBEN',
      name: 'Clematis montana rubens',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1835,
      chnn_stock_retail: 1835,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '653ELA66;EZRBR1C5;W2DN157C;BRD5WXZB;KL6DCDYP;YWG2DA1G;LECN6WJV;DYYJ2CKK;NYJX7SLJ;GZN2P7EL;YNS3XDXT;6PWPLRYJ;9TGGSAX3',
      statusContent: 'dot',
      hash: 'RVZ8TH4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVZ8TH4L'
    },
    {
      status: 210,
      sku: 'PM0084038',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338937',
      rtl_batch_array: '6338937',
      name_search: 'EUCGBLUE',
      name: "Euphorbia characias 'Glacier Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R4HL6YXH',
      statusContent: 'dot',
      hash: 'DG6Y8NN6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG6Y8NN6'
    },
    {
      status: 810,
      sku: 'PM0084039',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338940',
      rtl_batch_array: '6338940',
      name_search: 'HEPENELO',
      name: "Heuchera 'Peneloppe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZHC25DX',
      statusContent: 'dot',
      hash: 'Y49DBHSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y49DBHSD'
    },
    {
      status: 210,
      sku: 'PM0084040',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338941',
      rtl_batch_array: '6338941',
      name_search: 'HESTART',
      name: "Heuchera 'Sweet Tart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PR524JKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PR524JKL'
    },
    {
      status: 210,
      sku: 'PM0084041',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338944',
      rtl_batch_array: '6338944',
      name_search: 'KNLMAID',
      name: "Kniphofia 'Little Maid'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCBH6BSN;9AS6NWC4;TLW6Y16Y',
      statusContent: 'dot',
      hash: '914LAX6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '914LAX6L'
    },
    {
      status: 210,
      sku: 'PM0084042',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338955',
      rtl_batch_array: '6338955',
      name_search: 'PIMROSEA',
      name: "Pimelea major 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1NYB664',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1NYB664'
    },
    {
      status: 210,
      sku: 'PM0084043',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386174',
      rtl_batch_array: '6386174, 6338956',
      name_search: 'PULDCLAR',
      name: "Pulmonaria longifolia 'Diana Clare'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1402,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VD9WXK78',
      statusContent: 'dot',
      hash: 'ZD3EC6CC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZD3EC6CC'
    },
    {
      status: 210,
      sku: 'PM0084044',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386130',
      rtl_batch_array: '6386130, 6338958',
      name_search: 'HOBCADET',
      name: "Hosta 'Blue Cadet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1447,
      sppl_order_minimum: 3,
      sppl_prcp: 1.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YH2J77RP;1W9A3ZK9;5PYJZVPC;BTLRAZPW',
      statusContent: 'dot',
      hash: 'WSADYWCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSADYWCL'
    },
    {
      status: 210,
      sku: 'PM0084045',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338959',
      rtl_batch_array: '6338959',
      name_search: 'STCAPILL',
      name: 'Stipa capillata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1107,
      chnn_stock_retail: 1107,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APZJCNWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APZJCNWW'
    },
    {
      status: 810,
      sku: 'PM0084046',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338960',
      rtl_batch_array: '6338960',
      name_search: 'THAALBUM',
      name: "Thalictrum aquilegifolium 'Album'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HBYKVRJ4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HBYKVRJ4'
    },
    {
      status: 210,
      sku: 'PM0084047',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6338962',
      rtl_batch_array: '6338962',
      name_search: 'TEPREX',
      name: "Tetrapanax papyrifer 'Rex'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TRK5R7RP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRK5R7RP'
    },
    {
      status: 210,
      sku: 'PM0084048',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339314',
      rtl_batch_array: '6339314',
      name_search: 'FEGUCHTE',
      name: "Festuca glauca 'Uchte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZEZ8RNK2',
      statusContent: 'dot',
      hash: 'DBPNH317',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBPNH317'
    },
    {
      status: 210,
      sku: 'PM0084049',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339315',
      rtl_batch_array: '6339315',
      name_search: 'HESEMPER',
      name: 'Helictotrichon sempervirens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6155,
      chnn_stock_retail: 6155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G99L2WWK;EH7XX1LV;K164BZ18;TTE28P51;2VL7WR7E;P2ZJCRXZ;SS63CZBR;6L27H1XZ;BXX7TPK8;WZVZ5WJ3;RSZRDNBV;61TA84YX;B3V85GLV;V6XBSLAP;LBZ19T2P;WGCP2B2G;GP6G8NDY;AC3VR5RT;9PAR3PZH',
      statusContent: 'dot',
      hash: 'KC9ZZG9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KC9ZZG9J'
    },
    {
      status: 810,
      sku: 'PM0084050',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339316',
      rtl_batch_array: '6339316',
      name_search: 'HEEMURRA',
      name: "Hemerocallis 'Ed Murray'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YVER12PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVER12PV'
    },
    {
      status: 210,
      sku: 'PM0084051',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339317',
      rtl_batch_array: '6339317',
      name_search: 'DEFLEXUO',
      name: 'Deschampsia flexuosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1Y6ANSEL;63NZ8BHZ;AV5HEKV4',
      statusContent: 'dot',
      hash: '27KPJEKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27KPJEKS'
    },
    {
      status: 210,
      sku: 'PM0084052',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6339329',
      rtl_batch_array: '6339329',
      name_search: 'THCITRIO',
      name: 'Thymus citriodorus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1279,
      chnn_stock_retail: 1279,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PTTE2ZVG;PY7K9TNC;EBW6H8RZ;GERSHRZV;C41Z17GB;DZC2CT1E;CA7SDA4R;6KB5EV9L;XNSATJW7;P719PKDP;DLA2SS44;L4KK8APJ;1BD3B4B9;48ZXDTJY;N82X7PGC;JLHHB5J1;61TVG2PT;SCN1PP1T',
      statusContent: 'dot',
      hash: 'L856CTG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L856CTG5'
    },
    {
      status: 210,
      sku: 'PM0084053',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6340028',
      rtl_batch_array: '6369240, 6340028',
      name_search: 'GYROSENS',
      name: "Gypsophila 'Rosenschleier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1018,
      chnn_stock_retail: 2518,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XES7TRDY',
      statusContent: 'dot',
      hash: 'H9DHALRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9DHALRS'
    },
    {
      status: 210,
      sku: 'PM0084054',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382281',
      rtl_batch_array: '6382281, 6340031',
      name_search: 'LIMUSCAR',
      name: 'Liriope muscari',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 4750,
      sppl_order_minimum: 3,
      sppl_prcp: 0.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8G328LN;HCWXY5YV;J5JSCB5H;SES94664;6AVPE4HA;ZLE4P9CW;YSZYRDV1;488LSVLV',
      statusContent: 'dot',
      hash: 'C1C41YRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1C41YRH'
    },
    {
      status: 210,
      sku: 'PM0084055',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340034',
      rtl_batch_array: '6340034',
      name_search: 'MOCPINK',
      name: "Monarda 'Croftway Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7PDPX814;1L1A2G6R;DVKJA2SK;X1ELRWXH;JL9G5BW1;B33T9N9B;9LTBG967;9G5GVH25;7KZTRAAE;KEX5B26E',
      statusContent: 'dot',
      hash: 'WSSH84WS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSSH84WS'
    },
    {
      status: 210,
      sku: 'PM0084056',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340035',
      rtl_batch_array: '6340035',
      name_search: 'MOJCLINE',
      name: "Monarda 'Jacob Cline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 752,
      chnn_stock_retail: 752,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S3HDLKTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3HDLKTP'
    },
    {
      status: 210,
      sku: 'PM0084057',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340038',
      rtl_batch_array: '6340038',
      name_search: 'PUABMEER',
      name: "Pulmonaria angustifolia 'Blaues Meer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AVSDGZ6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVSDGZ6A'
    },
    {
      status: 210,
      sku: 'PM0084058',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340044',
      rtl_batch_array: '6340044',
      name_search: 'SANADRIA',
      name: "Salvia nemorosa 'Adrian'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1086,
      chnn_stock_retail: 1086,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DWJTRV6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWJTRV6X'
    },
    {
      status: 210,
      sku: 'PM0084059',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340052',
      rtl_batch_array: '6340052',
      name_search: 'THPBRESS',
      name: "Thymus praecox 'Bressingham'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 457,
      chnn_stock_retail: 457,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6999GDRA;BB41SYEC',
      statusContent: 'dot',
      hash: 'EZL48R2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZL48R2Z'
    },
    {
      status: 210,
      sku: 'PM0084060',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6340057',
      rtl_batch_array: '6340057',
      name_search: 'CEJACEA',
      name: 'Centaurea jacea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 429,
      chnn_stock_retail: 429,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7GSBH4DH;NYE7CTD9',
      statusContent: 'dot',
      hash: 'NVY11B79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVY11B79'
    },
    {
      status: 210,
      sku: 'PM0084061',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348271',
      rtl_batch_array: '6348271',
      name_search: 'ADPIMBRI',
      name: "Adiantum pedatum 'Imbricatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 277,
      chnn_stock_retail: 277,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZDP66DA9',
      statusContent: 'dot',
      hash: '568KWDEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '568KWDEW'
    },
    {
      status: 910,
      sku: 'PM0084062',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ADVENUST',
      name: 'Adiantum venustum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'CKN9RHHS;SX5J8EVJ;TRA5JRYW',
      statusContent: 'dot',
      hash: 'NSVND6B3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NSVND6B3'
    },
    {
      status: 210,
      sku: 'PM0076127',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284940',
      rtl_batch_array: '6284940',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 6.467,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'YTHXNPKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTHXNPKX'
    },
    {
      status: 210,
      sku: 'PM0076211',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014604',
      rtl_batch_array: '6014604',
      name_search: 'BEVINTER',
      name: "Bergenia 'Vintergl\u00f6d'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2475,
      chnn_stock_retail: 2475,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RZA17C9A;7E8D5HTK;J25NW5CR;ET27B68E;ZSVXDTE5;X1W1BPPP;Z5V1VD43',
      statusContent: 'dot',
      hash: '53G3S4E5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53G3S4E5'
    },
    {
      status: 210,
      sku: 'PM0076136',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284952',
      rtl_batch_array: '6284952',
      name_search: 'MEGLYPTO',
      name: 'Metasequoia glyptostroboides',
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 9.043,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APZV6165;EEDG59S2;Y3EJYXBA;TWP9Y85L;NSNDZL7H;PG49N7R1;KVA96A2A;YLYJJ13K;HKEJX78L;4ZTPD9WJ;DLDSW7N8;PNBZXV8L;CZ7LZREB;R8K1C5HK;H47B2JEC;CHS94XV8',
      statusContent: 'dot',
      hash: 'APCZDGLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APCZDGLT'
    },
    {
      status: 210,
      sku: 'PM0076134',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284950',
      rtl_batch_array: '6284950',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 8.063,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'T3S6BCGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3S6BCGA'
    },
    {
      status: 210,
      sku: 'PM0076135',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284951',
      rtl_batch_array: '6284951',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'S8AZHBSD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8AZHBSD'
    },
    {
      status: 210,
      sku: 'PM0076213',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6243047',
      rtl_batch_array: '6243047',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'CR99EL1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR99EL1C'
    },
    {
      status: 210,
      sku: 'PM0083794',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356658',
      rtl_batch_array: '6356658',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.016,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'PB1Z752V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PB1Z752V'
    },
    {
      status: 210,
      sku: 'PM0075915',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300843',
      rtl_batch_array: '6288094, 6300843',
      name_search: 'COREICHH',
      name: "Cotoneaster radicans 'Eichholz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 616,
      chnn_stock_retail: 5943,
      sppl_order_minimum: 3,
      sppl_prcp: 0.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DJ3H5N6X;C372KZ93;Y22E9J4N;1J88TZGL;WTTXJPSA;LL3WSH5P',
      statusContent: 'dot',
      hash: 'X427P3AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X427P3AK'
    },
    {
      status: 210,
      sku: 'PM0083795',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356659',
      rtl_batch_array: '6356659',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '060100C14',
      rng_range_identifier: 'H060100C1.5',
      rng_range_description: 'H60 cm 100 cm C1.5',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_prcp: 0.858,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'KYS8B79R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYS8B79R'
    },
    {
      status: 210,
      sku: 'PM0083796',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356660',
      rtl_batch_array: '6356660',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '100110C5',
      rng_range_identifier: 'H100110C5',
      rng_range_description: 'H100 cm 110 cm C5',
      sppl_stock_available: 298,
      chnn_stock_retail: 298,
      sppl_prcp: 3.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'JLYWHK13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLYWHK13'
    },
    {
      status: 210,
      sku: 'PM0083797',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356661',
      rtl_batch_array: '6373406, 6356661',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 350,
      chnn_stock_retail: 1650,
      sppl_prcp: 5.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'ZSCLNLX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSCLNLX7'
    },
    {
      status: 210,
      sku: 'PM0083798',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356663',
      rtl_batch_array: '6356663',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '080120C5',
      rng_range_identifier: 'H080120C5',
      rng_range_description: 'H80 cm 120 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.291,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '59GAK3W5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59GAK3W5'
    },
    {
      status: 210,
      sku: 'PM0084063',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348275',
      rtl_batch_array: '6348275',
      name_search: 'ALSMEVER',
      name: "Allium stipitatum 'Mount Everest'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '256A7LX6;EDV1XK5R;XTNL8PP1;JR9K4P1H;DHS87T5D;AXD3WHB1;LTG6JCXC',
      statusContent: 'dot',
      hash: 'RLS1REZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RLS1REZK'
    },
    {
      status: 210,
      sku: 'PM0084064',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348276',
      rtl_batch_array: '6348276',
      name_search: 'ANNEMORO',
      name: 'Anemone nemorosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1285,
      chnn_stock_retail: 1285,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1DDXXZNR;ZK4BRA7Z;4BEVXZWD',
      statusContent: 'dot',
      hash: 'VSEB7AA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSEB7AA9'
    },
    {
      status: 210,
      sku: 'PM0084065',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348279',
      rtl_batch_array: '6348279',
      name_search: 'AQVNIVEA',
      name: "Aquilegia vulgaris 'Nivea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z5ZY15W9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5ZY15W9'
    },
    {
      status: 210,
      sku: 'PM0084066',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383354',
      rtl_batch_array: '6383354, 6348285',
      name_search: 'ASSANGUS',
      name: "Asplenium scolopendrium 'Angustatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 504,
      chnn_stock_retail: 604,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YHRNS51Y',
      statusContent: 'dot',
      hash: '6P4KR7RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6P4KR7RX'
    },
    {
      status: 210,
      sku: 'PM0084067',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348287',
      rtl_batch_array: '6348287',
      name_search: 'ASSUNDUL',
      name: "Asplenium scolopendrium 'Undulatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9ZJWDYEE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZJWDYEE'
    },
    {
      status: 210,
      sku: 'PM0084068',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348288',
      rtl_batch_array: '6369731, 6348288',
      name_search: 'ASTRICHO',
      name: 'Asplenium trichomanes',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 977,
      chnn_stock_retail: 1020,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1JW53LB1;8RVW6DV8;6WPDTGJB;H1WJRG67;6PYW15VL;Z32VCBYS;W9EV8GGL;V416CV6X;A43REKKS;WDG4RB5V;2AELHHY6;PDRVWVSA;P6BLZK7N;YZ4BSPRW;5GP6SEBK;JKKXNEJD;ZLYJDNZ4;8CD4WL6P;GS9CXW9E',
      statusContent: 'dot',
      hash: 'XHZXZP4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XHZXZP4S'
    },
    {
      status: 210,
      sku: 'PM0084069',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348295',
      rtl_batch_array: '6348295',
      name_search: 'STMACRAN',
      name: 'Stachys macrantha',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LWXCXHAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWXCXHAA'
    },
    {
      status: 210,
      sku: 'PM0084070',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348299',
      rtl_batch_array: '6192714, 6348299',
      name_search: 'CYCOUM',
      name: 'Cyclamen coum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 868,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LEE2V3DR;ED7GAR72;XJ6YXDH4;CNL7BSKH;PK38P9BN;4RXS9G1H;SGR6PNJR',
      statusContent: 'dot',
      hash: 'XC46R6G6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC46R6G6'
    },
    {
      status: 210,
      sku: 'PM0084071',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348300',
      rtl_batch_array: '6348300',
      name_search: 'CYHEDERI',
      name: 'Cyclamen hederifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 577,
      chnn_stock_retail: 577,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XNCPL92J;RVJB3RX5',
      statusContent: 'dot',
      hash: 'NWNPSPRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWNPSPRD'
    },
    {
      status: 210,
      sku: 'PM0084072',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348305',
      rtl_batch_array: '6348305',
      name_search: 'DRCYCADI',
      name: 'Dryopteris cycadina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DXESNNGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXESNNGY'
    },
    {
      status: 210,
      sku: 'PM0084073',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386109',
      rtl_batch_array: '6386109, 6348307',
      name_search: 'DREPROLI',
      name: 'Dryopteris erythrosora prolifica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2AWBTVH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2AWBTVH9'
    },
    {
      status: 210,
      sku: 'PM0084074',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6383348',
      rtl_batch_array: '6383348, 6348311',
      name_search: 'DRWALLIC',
      name: 'Dryopteris wallichiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1032,
      chnn_stock_retail: 1509,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DNNV8T6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DNNV8T6G'
    },
    {
      status: 210,
      sku: 'PM0084075',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348314',
      rtl_batch_array: '6348314',
      name_search: 'EPGBANDI',
      name: "Epimedium grandiflorum 'Bandit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1440,
      chnn_stock_retail: 1440,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JABN4YNK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JABN4YNK'
    },
    {
      status: 910,
      sku: 'PM0076122',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLAUREOL',
      name: "Clematis 'Aureolin'",
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      statusContent: 'dot',
      hash: 'LE4BRTLS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LE4BRTLS'
    },
    {
      status: 210,
      sku: 'PM0076123',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314881',
      rtl_batch_array: '6314881',
      name_search: 'CLEMGRAN',
      name: 'Clematis montana grandiflora',
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_prcp: 9.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZLV5DGE;ZLNSJBK3;P24P7D41;853J6TVR;SD2APLBW;7EW5TT7W;6S3SRHL5;HWJH3XH5;E5GDPDKP;Z64T2K5J;D7BL4YEB;W5LT3RLW;453BNKST;PE4Y2V2R;G346D7NA;RCVTTXX3;HGHGXHVP;S482G2LY;S715HA8J;L7HHS4ER;RHHPLD5A',
      statusContent: 'dot',
      hash: 'XS9ENYGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS9ENYGS'
    },
    {
      status: 910,
      sku: 'PM0076124',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: '1PY7VLDZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1PY7VLDZ'
    },
    {
      status: 210,
      sku: 'PM0076125',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284937',
      rtl_batch_array: '6284937',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_prcp: 8.031,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'RAK65K54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAK65K54'
    },
    {
      status: 210,
      sku: 'PM0076126',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284938',
      rtl_batch_array: '6284938',
      name_search: 'COFLORID',
      name: 'Cornus florida',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_prcp: 5.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '46SDNCKV;XSGB5PA9;ZEPNBPNG;K88AGS6G;W5JXEA9R;ABKCWR7T;94SNA8GW;WJL3ZB9K;6H7HLHVT',
      statusContent: 'dot',
      hash: 'KJRGLH88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJRGLH88'
    },
    {
      status: 210,
      sku: 'PM0084076',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348317',
      rtl_batch_array: '6371161, 6348317',
      name_search: 'EPYROSEU',
      name: "Epimedium youngianum 'Roseum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 577,
      chnn_stock_retail: 931,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G8XJRGHY',
      statusContent: 'dot',
      hash: 'BCSTWJZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCSTWJZW'
    },
    {
      status: 210,
      sku: 'PM0084077',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348318',
      rtl_batch_array: '6348318',
      name_search: 'ERKBLUET',
      name: "Erigeron karvinskianus 'Bl\u00fctenmeer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9W3XXBB6;673LB242;EJYVYG5R;GSBDA26A;BE6VPG94;J5VAC42C;ZT64ZPYT;YYXHVXRH',
      statusContent: 'dot',
      hash: '4T6EK4A8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T6EK4A8'
    },
    {
      status: 810,
      sku: 'PM0076130',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284945',
      rtl_batch_array: '6284945',
      name_search: 'LAKAEMPF',
      name: 'Larix kaempferi',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 5.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2GR3C55;1JE1CHC2;JJ4BXCH4;LV3GWN1X;NPXHR7WP;GWAB5187;ZSPXSKNT;DC1251CR;DYK1AA8P;VB1ST6V9;PPDS8DWY;KK1DPR3D',
      statusContent: 'dot',
      hash: 'JV8DSRCH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JV8DSRCH'
    },
    {
      status: 210,
      sku: 'PM0076131',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284946',
      rtl_batch_array: '6284946',
      name_search: 'LAKAEMPF',
      name: 'Larix kaempferi',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 124,
      chnn_stock_retail: 124,
      sppl_prcp: 7.041,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2GR3C55;1JE1CHC2;JJ4BXCH4;LV3GWN1X;NPXHR7WP;GWAB5187;ZSPXSKNT;DC1251CR;DYK1AA8P;VB1ST6V9;PPDS8DWY;KK1DPR3D',
      statusContent: 'dot',
      hash: 'C1N4N225',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C1N4N225'
    },
    {
      status: 210,
      sku: 'PM0084078',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348330',
      rtl_batch_array: '6348330',
      name_search: 'LIDALBA',
      name: "Lithodora diffusa 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PNBZ7CPH',
      statusContent: 'dot',
      hash: '6J23V47J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J23V47J'
    },
    {
      status: 210,
      sku: 'PM0076133',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284949',
      rtl_batch_array: '6284949',
      name_search: 'LISTYRAC',
      name: 'Liquidambar styraciflua',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 7.041,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VB1NY45D;K4DCN9CE;1XPXLN2W;73J4CSBJ;D2HHN7HY;6CC6PX1X;LWGXJ678;BADZWEGN;CLKKAZCX',
      statusContent: 'dot',
      hash: 'HH8ZJ8JJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HH8ZJ8JJ'
    },
    {
      status: 210,
      sku: 'PM0076137',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284955',
      rtl_batch_array: '6284955',
      name_search: 'VIAURORE',
      name: "Vitis 'Aurore'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 249,
      chnn_stock_retail: 249,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6XVA2JP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XVA2JP4'
    },
    {
      status: 210,
      sku: 'PM0076138',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284956',
      rtl_batch_array: '6284956',
      name_search: 'VIRIESLI',
      name: "Vitis 'Riesling'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SWVZR2JZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SWVZR2JZ'
    },
    {
      status: 810,
      sku: 'PM0076139',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284957',
      rtl_batch_array: '6314964, 6284957',
      name_search: 'WISPROLI',
      name: "Wisteria sinensis 'Prolific'",
      sppl_size_code: '150175C5',
      rng_range_identifier: 'H150175C5',
      rng_range_description: 'H150 cm 175 cm C5',
      sppl_stock_available: 59,
      chnn_stock_retail: 253,
      sppl_prcp: 12.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1VE8GB5B;8EYN7H5J;AYE2GW7Z;CXT6JZWJ;GLKN4YS9;1HPPXDRX;NDZE3ERW;VD4H7C6V;V389R7VG;VTC6XWWL',
      statusContent: 'dot',
      hash: '4EYLVGYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4EYLVGYC'
    },
    {
      status: 210,
      sku: 'PM0076208',
      core_name: 'Plant',
      sppl_ean: '8720353000121',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5955986',
      rtl_batch_array: '5955986',
      name_search: 'COCONTRO',
      name: 'Cornus controversa',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 465,
      chnn_stock_retail: 465,
      sppl_prcp: 6.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PKN99EW5',
      statusContent: 'dot',
      hash: 'AA1VYH9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA1VYH9E'
    },
    {
      status: 210,
      sku: 'PM0076209',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5955988',
      rtl_batch_array: '5955988',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.241,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: 'NTSBVRVG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTSBVRVG'
    },
    {
      status: 210,
      sku: 'PM0076210',
      core_name: 'Plant',
      sppl_ean: '8720664876750',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5955993',
      rtl_batch_array: '5955993',
      name_search: 'PATVBOSK',
      name: "Parthenocissus tricuspidata 'Veitch Boskoop'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2909,
      chnn_stock_retail: 2909,
      sppl_order_minimum: 3,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LY2KCTV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LY2KCTV4'
    },
    {
      status: 210,
      sku: 'PM0076212',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6069537',
      rtl_batch_array: '6069537',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 3374,
      chnn_stock_retail: 3374,
      sppl_order_minimum: 3,
      sppl_prcp: 1.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: 'B4XNPRBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4XNPRBG'
    },
    {
      status: 810,
      sku: 'PM0084079',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348333',
      rtl_batch_array: '6348333',
      name_search: 'HERAUCHT',
      name: "Helenium 'Rauchtopas'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 76,
      chnn_stock_retail: 76,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E17C5117',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E17C5117'
    },
    {
      status: 210,
      sku: 'PM0084080',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348336',
      rtl_batch_array: '6348336',
      name_search: 'HEELFENB',
      name: "Helianthemum 'Elfenbeinglanz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT7SKYSR;D9JR85SW;6NYKXGPD;R69SLHRJ;TDEEWVRK;H9T53JXA',
      statusContent: 'dot',
      hash: 'VTLJWK7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTLJWK7G'
    },
    {
      status: 210,
      sku: 'PM0084081',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348337',
      rtl_batch_array: '6348337',
      name_search: 'HESSAPHI',
      name: "Helictotrichon sempervirens 'Saphirsprudel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NRP3XKDA;N3KWRD9H',
      statusContent: 'dot',
      hash: 'WALGCAT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WALGCAT4'
    },
    {
      status: 210,
      sku: 'PM0084082',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348339',
      rtl_batch_array: '6348339',
      name_search: 'BLPMARIN',
      name: 'Blechnum penna-marina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1J4TBL1Y;YDV6X97R',
      statusContent: 'dot',
      hash: '7VWAGVZR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VWAGVZR'
    },
    {
      status: 210,
      sku: 'PM0084083',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348340',
      rtl_batch_array: '6348340',
      name_search: 'CAPBLAUR',
      name: "Campanula poscharskyana 'Blauranke'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 585,
      chnn_stock_retail: 585,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LRCYPWGZ',
      statusContent: 'dot',
      hash: '49161PC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '49161PC2'
    },
    {
      status: 210,
      sku: 'PM0084084',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348347',
      rtl_batch_array: '6348347',
      name_search: 'KAICHARL',
      name: "Kalimeris incisa 'Charlotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WB9448WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB9448WY'
    },
    {
      status: 210,
      sku: 'PM0084085',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348351',
      rtl_batch_array: '6348351',
      name_search: 'KNUGRAND',
      name: "Kniphofia uvaria 'Grandiflora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 389,
      chnn_stock_retail: 389,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z6T7WTVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z6T7WTVW'
    },
    {
      status: 210,
      sku: 'PM0084086',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6330008',
      rtl_batch_array: '6330008, 6348354',
      name_search: 'LAAMKATH',
      name: "Lavandula angustifolia 'Miss Katherine'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J3GLVB8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3GLVB8L'
    },
    {
      status: 210,
      sku: 'PM0084087',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348355',
      rtl_batch_array: '6348355',
      name_search: 'LIPERENN',
      name: 'Linum perenne',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VKEP21L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKEP21L8'
    },
    {
      status: 210,
      sku: 'PM0084088',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348361',
      rtl_batch_array: '6348361',
      name_search: 'LUGBLUE',
      name: "Lupinus 'Gallery Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6A2XVBGP;CSWEN8B5;Y52GX7JR;S4ZE4B55;Z4KHSL8K;Y6W1NLC6;SSNSYA7R;86ENKC6N;T8WWSXNH;Y8R8XVLP;AWKN8EZ1;WKYYGJGE',
      statusContent: 'dot',
      hash: 'BNRSSNKJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNRSSNKJ'
    },
    {
      status: 210,
      sku: 'PM0084089',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348366',
      rtl_batch_array: '6348366',
      name_search: 'MAAFASTI',
      name: "Malva alcea 'Fastigiata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AK991YSK;656S59CA;YK2YSDXA;VZDWLV4D',
      statusContent: 'dot',
      hash: 'JVSHHKNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVSHHKNC'
    },
    {
      status: 210,
      sku: 'PM0084090',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348367',
      rtl_batch_array: '6348367',
      name_search: 'MALSYLVE',
      name: 'Malva sylvestris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72TGBD7Z;WX64X1CP;81N1GJ2G;N13GBC5B;A9LJ7B1R;86SA6EZ9;SEP9KZ81;NJ6WSWE2;CDVGGKED;VCHWXNVB;8HTD89WG;6N3YH7R5;91RN5L24;2A6967PX;9YRB26B9;5CAWR34S;Z24PG9Z8',
      statusContent: 'dot',
      hash: 'YCJRWK7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCJRWK7J'
    },
    {
      status: 210,
      sku: 'PM0084091',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382285',
      rtl_batch_array: '6382285, 6348373',
      name_search: 'MIPURPUR',
      name: "Miscanthus 'Purpurascens'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2920,
      chnn_stock_retail: 3110,
      sppl_order_minimum: 3,
      sppl_prcp: 0.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SBCGC6J;X9ZBSJYT;KRPLLADW',
      statusContent: 'dot',
      hash: 'XG5A1V7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG5A1V7S'
    },
    {
      status: 210,
      sku: 'PM0084092',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348377',
      rtl_batch_array: '6348377',
      name_search: 'MOBRADBU',
      name: 'Monarda bradburiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '65L6DZJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '65L6DZJ3'
    },
    {
      status: 210,
      sku: 'PM0084093',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348379',
      rtl_batch_array: '6348379',
      name_search: 'MODCLACE',
      name: "Monarda didyma 'Cranberry Lace'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7W8C6JJB;NRJLWGXT;HHHRWEYV;K9S856KS;EJJ3P5P4;CEN1HGYJ;8AC78VKT',
      statusContent: 'dot',
      hash: 'RYRC8L2L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RYRC8L2L'
    },
    {
      status: 210,
      sku: 'PM0084094',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348380',
      rtl_batch_array: '6386148, 6348380',
      name_search: 'MOFISHES',
      name: "Monarda 'Fishes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 572,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y6XRDXT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y6XRDXT8'
    },
    {
      status: 210,
      sku: 'PM0084095',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348381',
      rtl_batch_array: '6348381',
      name_search: 'MOMDELIG',
      name: "Monarda 'Marshall's Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DNV6C3L;VV33RPVJ;5NPKKTHD;YEABWLXK;26HGJNRV;LEZPVACR;4276L48J;WBVH1XEC;RTV2A852;GR7XCB8T;HV58PTS4;SJH3GB4R;B3YVC7H3',
      statusContent: 'dot',
      hash: 'BHC21HBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BHC21HBS'
    },
    {
      status: 810,
      sku: 'PM0084096',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348382',
      rtl_batch_array: '6348382',
      name_search: 'MOMELISS',
      name: "Monarda 'Melissa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75LYKV2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75LYKV2T'
    },
    {
      status: 810,
      sku: 'PM0084097',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348383',
      rtl_batch_array: '6348383',
      name_search: 'MOMOHAWK',
      name: "Monarda 'Mohawk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X9N4NPC7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9N4NPC7'
    },
    {
      status: 210,
      sku: 'PM0084098',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348384',
      rtl_batch_array: '6348384',
      name_search: 'MOOCHARM',
      name: "Monarda 'Ou' Charm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1GYASVW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GYASVW7'
    },
    {
      status: 210,
      sku: 'PM0084099',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386146',
      rtl_batch_array: '6386146, 6348387',
      name_search: 'NEGBDANU',
      name: "Nepeta grandiflora 'Blue Danube'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 260,
      chnn_stock_retail: 320,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJRDZVVD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJRDZVVD'
    },
    {
      status: 210,
      sku: 'PM0084100',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348389',
      rtl_batch_array: '6348389',
      name_search: 'NEKUBANI',
      name: 'Nepeta kubanica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ECWH9DD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECWH9DD1'
    },
    {
      status: 210,
      sku: 'PM0084101',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348391',
      rtl_batch_array: '6348391',
      name_search: 'NESIBIRI',
      name: 'Nepeta sibirica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TRDZD6P6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRDZD6P6'
    },
    {
      status: 210,
      sku: 'PM0084102',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348392',
      rtl_batch_array: '6386154, 6348392',
      name_search: 'OEFFYRVE',
      name: "Oenothera fruticosa 'Fyrverkeri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 427,
      chnn_stock_retail: 1610,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BCHZPYS;DAG6PPLC',
      statusContent: 'dot',
      hash: '4NNBA15Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4NNBA15Z'
    },
    {
      status: 210,
      sku: 'PM0084103',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348394',
      rtl_batch_array: '6348394',
      name_search: 'ORLHOPLE',
      name: "Origanum laevigatum 'Hopleys'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VNLCPNH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNLCPNH2'
    },
    {
      status: 210,
      sku: 'PM0084104',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348399',
      rtl_batch_array: '6348399',
      name_search: 'PEAHINDL',
      name: "Penstemon 'Alice Hindley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2RL1LJWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RL1LJWL'
    },
    {
      status: 210,
      sku: 'PM0084105',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348401',
      rtl_batch_array: '6348401',
      name_search: 'PEBCOCCI',
      name: "Penstemon barbatus 'Coccineus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W7HRLNB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7HRLNB2'
    },
    {
      status: 210,
      sku: 'PM0084106',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348403',
      rtl_batch_array: '6348403',
      name_search: 'PERAVEN',
      name: "Penstemon 'Raven'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HR67BG67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HR67BG67'
    },
    {
      status: 210,
      sku: 'PM0084107',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348404',
      rtl_batch_array: '6348404',
      name_search: 'PERRUBY',
      name: "Penstemon 'Rich Ruby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RW6PHWX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RW6PHWX1'
    },
    {
      status: 810,
      sku: 'PM0084108',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348405',
      rtl_batch_array: '6348405',
      name_search: 'PESCHOEN',
      name: "Penstemon 'Schoenholzeri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 89,
      chnn_stock_retail: 89,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5T2A8LY7;V3YSGXHP;W2GDEZHR;DARV6B3G;ELZ7SATW;GS8LXKP2;RH6VYSGV;HCXW7BVN;73HGHGE1;WBPGGZKZ;5AJEJKB4;CSVBB9JJ;L9TS84ZC;RHLHXKKH;ASLR29YC;NKHDRKYH;6GNS8N2S;YPN4R6JR;EYG1W6VA;ZLXVRTY3;9A4SAVLR;LGJW5C45;HGAAE936',
      statusContent: 'dot',
      hash: 'D3LPHWPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3LPHWPT'
    },
    {
      status: 210,
      sku: 'PM0084109',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348414',
      rtl_batch_array: '6348414',
      name_search: 'SANEMORO',
      name: 'Salvia nemorosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2962,
      chnn_stock_retail: 2962,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SDAT1NPL;HJ5581G9;N3R1LP5L;1LKBD2DS;5E3NERRG;7AE6ND5L;ZVJ7SSR7;85XKZYPR;6Y96JXG6;CKLYSL79;NASB73P2;J2JNXNV8;HDCEGY71;DPTTKE51;C8SLAG3A;AWXGN1N7',
      statusContent: 'dot',
      hash: '6GW72PJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GW72PJH'
    },
    {
      status: 210,
      sku: 'PM0084110',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348418',
      rtl_batch_array: '6386188, 6348418',
      name_search: 'SAOBTUSA',
      name: 'Sanguisorba obtusa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 937,
      chnn_stock_retail: 1513,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YT7H6X2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT7H6X2Y'
    },
    {
      status: 210,
      sku: 'PM0084111',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348420',
      rtl_batch_array: '6348420',
      name_search: 'SAORPLEN',
      name: "Saponaria officinalis 'Rosea Plena'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V9ABKAVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V9ABKAVX'
    },
    {
      status: 210,
      sku: 'PM0084112',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382316',
      rtl_batch_array: '6382316, 6348425',
      name_search: 'SEHEUFLE',
      name: 'Sesleria heufleriana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 7050,
      chnn_stock_retail: 13035,
      sppl_order_minimum: 3,
      sppl_prcp: 0.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N6YKG48D',
      statusContent: 'dot',
      hash: '1WA3S1NY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WA3S1NY'
    },
    {
      status: 210,
      sku: 'PM0084113',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348435',
      rtl_batch_array: '6348435',
      name_search: 'TRLEONOR',
      name: "Tradescantia (A) 'Leonora'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 217,
      chnn_stock_retail: 217,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SD9TCHG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SD9TCHG3'
    },
    {
      status: 210,
      sku: 'PM0084114',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348439',
      rtl_batch_array: '6348439',
      name_search: 'PECRISPU',
      name: 'Petroselinum crispum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZZDTLRV7;BTXRBR7N;XYT6YJ3X;Z36TD465;1H6BVPPJ;C1A9YVDK;H3TK6NJ5;5G31A7VW;YVG6LRV4;D63ZAV5C;5DJJCCWK;HRD4TPLG;D3BRH1PC;B7TC28VW;YR1341XN;468WSPXL;G27H8447;1E5K1ZLL;SE5D53X5;X5RJALL9;9KW2YJJ7;DREDET62;KTHJ93L6;ZW5YKECP;7R2EL67N',
      statusContent: 'dot',
      hash: 'B73NL29V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B73NL29V'
    },
    {
      status: 210,
      sku: 'PM0084115',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348440',
      rtl_batch_array: '6348440',
      name_search: 'PHFRUTIC',
      name: 'Phlomis fruticosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DZ34RD2C',
      statusContent: 'dot',
      hash: 'J1LTDP3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1LTDP3X'
    },
    {
      status: 210,
      sku: 'PM0084116',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348443',
      rtl_batch_array: '6348443',
      name_search: 'PHVBROSE',
      name: "Physostegia virginiana 'Bouquet Rose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 267,
      chnn_stock_retail: 267,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YJ4S76GD;VPHBYEB7;PPBTE6V1;TE2RDBL9;ZNGPZDRD;B99TB6Z6;EJCSNET8;TBYY3GP4;HBLX27J5;7H87Z3B9;2PNN8GYV',
      statusContent: 'dot',
      hash: 'HHY7PPJL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHY7PPJL'
    },
    {
      status: 210,
      sku: 'PM0084117',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6348445',
      rtl_batch_array: '6348445',
      name_search: 'POACROST',
      name: 'Polystichum acrostichoides',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '83K9WGRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '83K9WGRP'
    },
    {
      status: 210,
      sku: 'PM0084118',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349739',
      rtl_batch_array: '6349739',
      name_search: 'ALRCGELB',
      name: "Alcea rosea 'Charters Gelb'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 261,
      chnn_stock_retail: 261,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DWP18APN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWP18APN'
    },
    {
      status: 210,
      sku: 'PM0084119',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349741',
      rtl_batch_array: '6349741',
      name_search: 'ALRCROT',
      name: "Alcea rosea 'Charters Rot'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 202,
      chnn_stock_retail: 202,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELY81YGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELY81YGJ'
    },
    {
      status: 210,
      sku: 'PM0084120',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349742',
      rtl_batch_array: '6349742',
      name_search: 'ALRCWEIS',
      name: "Alcea rosea 'Charters Weiss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 213,
      chnn_stock_retail: 213,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BTYW7GNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTYW7GNE'
    },
    {
      status: 210,
      sku: 'PM0084121',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349750',
      rtl_batch_array: '6349750',
      name_search: 'DIFULLON',
      name: 'Dipsacus fullonum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VG6N5TR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VG6N5TR8'
    },
    {
      status: 810,
      sku: 'PM0084122',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349758',
      rtl_batch_array: '6349758',
      name_search: 'ARLELFEN',
      name: "Artemisia lactiflora 'Elfenbein'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X414XRV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X414XRV3'
    },
    {
      status: 810,
      sku: 'PM0084123',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349763',
      rtl_batch_array: '6349763',
      name_search: 'ASABRILL',
      name: "Aster amellus 'Brilliant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5NSVEP1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NSVEP1J'
    },
    {
      status: 210,
      sku: 'PM0084124',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349765',
      rtl_batch_array: '6349765',
      name_search: 'ASAZUREU',
      name: 'Aster azureus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H66AJATS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H66AJATS'
    },
    {
      status: 210,
      sku: 'PM0084125',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386087',
      rtl_batch_array: '6386087, 6349777',
      name_search: 'CABHYBRI',
      name: "Campanula 'Birch Hybrid'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1407,
      chnn_stock_retail: 1649,
      sppl_order_minimum: 3,
      sppl_prcp: 0.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '56TA9VJP',
      statusContent: 'dot',
      hash: 'V1TRVLXA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V1TRVLXA'
    },
    {
      status: 210,
      sku: 'PM0084126',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349778',
      rtl_batch_array: '6349778',
      name_search: 'CACARPAT',
      name: 'Campanula carpatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NHHJ5BJD;19SNX6SS;13KSZT25;EH7WTAW5;5P6KPKR5',
      statusContent: 'dot',
      hash: 'HX8XGE7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HX8XGE7E'
    },
    {
      status: 810,
      sku: 'PM0084127',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349779',
      rtl_batch_array: '6349779',
      name_search: 'CACKFOER',
      name: "Campanula carpatica 'Karl Foerster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '75SATYPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '75SATYPE'
    },
    {
      status: 210,
      sku: 'PM0084128',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349781',
      rtl_batch_array: '6349781',
      name_search: 'CALACTIF',
      name: 'Campanula lactiflora',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 244,
      chnn_stock_retail: 244,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NJZ4KKH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJZ4KKH8'
    },
    {
      status: 210,
      sku: 'PM0084129',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349782',
      rtl_batch_array: '6014559, 6349782',
      name_search: 'CAPYRAMI',
      name: 'Campanula pyramidalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 127,
      chnn_stock_retail: 572,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D1AVN92S;D2PT4GP5;SAJ16RYA;CVAX1368;WREES1JT;TXNGSXSX;9WD6NAZS;NW1Y84DH;WT396TVR;P89883VJ;4B5K83P5;VZWJ42J5;SWJAZV2N',
      statusContent: 'dot',
      hash: 'PB2EWXEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PB2EWXEA'
    },
    {
      status: 210,
      sku: 'PM0084130',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349787',
      rtl_batch_array: '6349787',
      name_search: 'CEMONTAN',
      name: 'Cercocarpus montanus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1ZBTHG6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ZBTHG6D'
    },
    {
      status: 210,
      sku: 'PM0084131',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349788',
      rtl_batch_array: '6349788',
      name_search: 'CHMSTOKE',
      name: "Chrysanthemum (R) 'Mary Stoker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APP3YJTS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APP3YJTS'
    },
    {
      status: 210,
      sku: 'PM0084132',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349793',
      rtl_batch_array: '6369235, 6349793',
      name_search: 'EUCCHOWA',
      name: "Euphorbia cyparissias 'Clarice Howard'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 285,
      chnn_stock_retail: 357,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E653JND5',
      statusContent: 'dot',
      hash: 'AB7VJ2YC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB7VJ2YC'
    },
    {
      status: 210,
      sku: 'PM0084133',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349794',
      rtl_batch_array: '6349794',
      name_search: 'EUPWGLOR',
      name: "Euphorbia palustris 'Walenburg's Glorie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 362,
      chnn_stock_retail: 362,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRT7EHL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRT7EHL2'
    },
    {
      status: 210,
      sku: 'PM0084134',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349797',
      rtl_batch_array: '6349797',
      name_search: 'GALSBREE',
      name: "Gaura lindheimeri 'Summer Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HD2SSY8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HD2SSY8Y'
    },
    {
      status: 210,
      sku: 'PM0084135',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352614',
      rtl_batch_array: '6352614',
      name_search: 'ARPGLACI',
      name: "Arabis procurrens 'Glacier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SJA2HZX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJA2HZX9'
    },
    {
      status: 810,
      sku: 'PM0084136',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352636',
      rtl_batch_array: '6352636',
      name_search: 'HEGGLOSS',
      name: "Helleborus Glenda's Gloss",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 4.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YE89RJS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YE89RJS7'
    },
    {
      status: 210,
      sku: 'PM0084137',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352643',
      rtl_batch_array: '6352643',
      name_search: 'HEOPERED',
      name: "Helleborus orientalis 'Pretty Ellen Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 348,
      chnn_stock_retail: 348,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGSCACZ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGSCACZ8'
    },
    {
      status: 210,
      sku: 'PM0084138',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352644',
      rtl_batch_array: '6352644',
      name_search: 'HEOPEWHI',
      name: "Helleborus orientalis 'Pretty Ellen White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 694,
      chnn_stock_retail: 694,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PP4ZBRJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP4ZBRJT'
    },
    {
      status: 210,
      sku: 'PM0084139',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352645',
      rtl_batch_array: '6352645',
      name_search: 'HEOQDRED',
      name: "Helleborus o. 'Queens Double Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZVLSD2AC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVLSD2AC'
    },
    {
      status: 210,
      sku: 'PM0084140',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352646',
      rtl_batch_array: '6352646',
      name_search: 'HEOQWHIT',
      name: "Helleborus o. 'Queens White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1147,
      chnn_stock_retail: 1147,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HLSSLNEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLSSLNEG'
    },
    {
      status: 210,
      sku: 'PM0084141',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352649',
      rtl_batch_array: '6352649',
      name_search: 'HECRANBE',
      name: "Heuchera hybrid 'Cranberry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E1D9VG3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1D9VG3X'
    },
    {
      status: 210,
      sku: 'PM0084142',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369741',
      rtl_batch_array: '6369741, 6352653',
      name_search: 'HEJGLOSS',
      name: "Heuchera 'Jade Gloss'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 1.49,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '45DXK379',
      statusContent: 'dot',
      hash: 'E276DXX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E276DXX7'
    },
    {
      status: 210,
      sku: 'PM0084143',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352654',
      rtl_batch_array: '6352654',
      name_search: 'HESRBELL',
      name: "Heuchera sanguinea 'Ruby Bells'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D2Y6W52S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2Y6W52S'
    },
    {
      status: 210,
      sku: 'PM0084144',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352655',
      rtl_batch_array: '6352655',
      name_search: 'HESWCLOU',
      name: "Heuchera sanguinea 'White Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HZXA9PCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZXA9PCZ'
    },
    {
      status: 210,
      sku: 'PM0084145',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352658',
      rtl_batch_array: '6352658',
      name_search: 'HEKIMONO',
      name: "Heucherella 'Kimono'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ETHWW3NN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETHWW3NN'
    },
    {
      status: 210,
      sku: 'PM0084146',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352662',
      rtl_batch_array: '6352662',
      name_search: 'PAKROSEN',
      name: "Paeonia (LD) 'Karl Rosenfield'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 145,
      chnn_stock_retail: 145,
      sppl_order_minimum: 3,
      sppl_prcp: 2.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25VJ5AS1;DE8L34XV;NGXVVV8Y;JAVSBX8G;V622SNR3;14VCRPEA;HP71LGS8;8P1K23AV;YA9NP5RK;EY3WEJNP',
      statusContent: 'dot',
      hash: 'SHE4964J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHE4964J'
    },
    {
      status: 210,
      sku: 'PM0084147',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352666',
      rtl_batch_array: '6352666',
      name_search: 'PHVSSNOW',
      name: "Physostegia virginiana 'Summer Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 183,
      chnn_stock_retail: 183,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DB8LD3AP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DB8LD3AP'
    },
    {
      status: 810,
      sku: 'PM0084148',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352669',
      rtl_batch_array: '6352669',
      name_search: 'STSYLVAT',
      name: 'Stachys sylvatica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VZCZWXE7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZCZWXE7'
    },
    {
      status: 210,
      sku: 'PM0076317',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288306',
      rtl_batch_array: '6288306',
      name_search: 'LIIMUSLI',
      name: 'Ligustrum ibota Musli',
      sppl_size_code: '030035C2',
      rng_range_identifier: 'H030035C2',
      rng_range_description: 'H30 cm 35 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KNZKC43W;TBWV2J7E',
      statusContent: 'dot',
      hash: 'GDRBKT43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GDRBKT43'
    },
    {
      status: 210,
      sku: 'PM0083799',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356664',
      rtl_batch_array: '6356664',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_order_minimum: 3,
      sppl_prcp: 2.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: 'XV312258',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV312258'
    },
    {
      status: 210,
      sku: 'PM0083800',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356665',
      rtl_batch_array: '6356665',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      sppl_size_code: '080100C3',
      rng_range_identifier: 'H080100C3',
      rng_range_description: 'H80 cm 100 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 2.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '6TTVL2AA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TTVL2AA'
    },
    {
      status: 210,
      sku: 'PM0076319',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288308',
      rtl_batch_array: '6288308',
      name_search: 'SKJFCLOU',
      name: "Skimmia japonica 'Fragrant Cloud'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J58A79P;PPV79CSS;DLVWSZ7D;KJS43E4N',
      statusContent: 'dot',
      hash: 'YEN2SRZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YEN2SRZK'
    },
    {
      status: 210,
      sku: 'PM0076324',
      core_name: 'Plant',
      sppl_ean: '8720349463190',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5479984',
      rtl_batch_array: '5479984',
      name_search: 'SKJFCLOU',
      name: "Skimmia japonica 'Fragrant Cloud'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.415,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8J58A79P;PPV79CSS;DLVWSZ7D;KJS43E4N',
      imageBatch: 'JV22W3PJ',
      statusContent: 'dot',
      hash: 'NKD2HDBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKD2HDBX'
    },
    {
      status: 210,
      sku: 'PM0083801',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356666',
      rtl_batch_array: '6356666',
      name_search: 'BETHUNBE',
      name: 'Berberis thunbergii',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.802,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VR4D883C;JYT62D7E;YXAHYVN7;J5B3L6ZR;STRL4G62;8NNCVVSS;Z5J8YP2K;WZ3RLL1A;9A3RJR1S;CWCB45VD',
      statusContent: 'dot',
      hash: 'NZR84X7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZR84X7X'
    },
    {
      status: 210,
      sku: 'PM0076245',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288311',
      rtl_batch_array: '6288311',
      name_search: 'AQBIEDER',
      name: "Aquilegia 'Biedermeier'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9010,
      chnn_stock_retail: 9010,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8A999S8T',
      statusContent: 'dot',
      hash: 'V6GR9R5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6GR9R5N'
    },
    {
      status: 810,
      sku: 'PM0076257',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286662',
      rtl_batch_array: '6286662',
      name_search: 'ACMHGOLD',
      name: "Acanthus mollis 'Hollard's Gold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A4X1D1CG;KEY4D17C;AAK77RBT;9V956ER6;ZTKXJDA6;YT26P81X',
      statusContent: 'dot',
      hash: 'KBV6XPV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBV6XPV3'
    },
    {
      status: 810,
      sku: 'PM0076316',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6288303',
      rtl_batch_array: '6329861, 6288303',
      name_search: 'ASNASEPT',
      name: "Aster novae-angliae 'Septemberrubin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 91,
      chnn_stock_retail: 583,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5V6TPJYJ;8CHZXZRE',
      statusContent: 'dot',
      hash: 'WJZJ7NG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJZJ7NG2'
    },
    {
      status: 210,
      sku: 'PM0076247',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4706015',
      rtl_batch_array: '4706015',
      name_search: 'JUCREPAN',
      name: "Juniperus communis 'Repanda'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TCX3E1WY;AHRHLBLB;XLS3DSDH;8NW6KNDW;GSXVB568;YB6RY9D3;G47EZ7SW;649BBC73;STWVW2RL;YN213YVT;ZYJVR4AG;ARJ5LEEG;NSCSLPPB;LLLY47EE;G9ZP44BJ;E5EEEL2Y;5W75BBW8;43YH4JB9;DAH8E3D3;4J8HVX2E;W3K49ZNW',
      statusContent: 'dot',
      hash: '693SZ116',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '693SZ116'
    },
    {
      status: 910,
      sku: 'PM0076323',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'NLB95653',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NLB95653'
    },
    {
      status: 210,
      sku: 'PM0076259',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287030',
      rtl_batch_array: '6287030',
      name_search: 'CHPFNANA',
      name: "Chamaecyparis pisifera 'Filifera Nana'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_prcp: 6.198,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6H1P49H2;63BTDXDG;69KRN8NB;2PJJVP62',
      statusContent: 'dot',
      hash: '9XXHGBTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XXHGBTP'
    },
    {
      status: 210,
      sku: 'PM0076328',
      core_name: 'Plant',
      sppl_ean: '8720664802438',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6052057',
      rtl_batch_array: '6314273, 6052057',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 3420,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      statusContent: 'dot',
      hash: 'TB2LVDRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TB2LVDRT'
    },
    {
      status: 210,
      sku: 'PM0076253',
      core_name: 'Plant',
      sppl_ean: '8720626363489',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449479',
      rtl_batch_array: '5449479',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      imageBatch: '796DX23K',
      statusContent: 'dot',
      hash: 'JLG4L692',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLG4L692'
    },
    {
      status: 210,
      sku: 'PM0083802',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356667',
      rtl_batch_array: '6356667',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 976,
      chnn_stock_retail: 976,
      sppl_order_minimum: 3,
      sppl_prcp: 0.601,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: 'VD4XPS44',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VD4XPS44'
    },
    {
      status: 210,
      sku: 'PM0083803',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356668',
      rtl_batch_array: '6356668',
      name_search: 'BETATROP',
      name: "Berberis thunbergii 'Atropurpurea'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8PXYNGK;5XD25PVA;6W6PHDVG;15V4R9TJ;TL3BLPVD;8S1XK1J5;7KT1RG63;P2H8YJCH;ZX3SAEH3;D2RP436R;VEBSYDY5;A6JEHDBJ;546NLHA4;ZDPZAJ32;6L51CPWK;D78E31R3;65AWEYYC;EBNLDL68',
      statusContent: 'dot',
      hash: 'WL7WL6YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL7WL6YT'
    },
    {
      status: 210,
      sku: 'PM0076216',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284280',
      rtl_batch_array: '6284280',
      name_search: 'ALSBREEZ',
      name: 'Alstroemeria Summer Breeze',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 191,
      sppl_order_minimum: 3,
      sppl_prcp: 3.058,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y58NL15Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y58NL15Y'
    },
    {
      status: 210,
      sku: 'PM0076246',
      core_name: 'Plant',
      sppl_ean: '8720664566330',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4625069',
      rtl_batch_array: '4625069',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_order_minimum: 5,
      sppl_prcp: 1.832,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: 'GWK6W44T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GWK6W44T'
    },
    {
      status: 210,
      sku: 'PM0076248',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4706040',
      rtl_batch_array: '4706040',
      name_search: 'ERDMHELE',
      name: "Erica darleyensis 'Mary Helen'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZZSEEGSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZSEEGSE'
    },
    {
      status: 210,
      sku: 'PM0076249',
      core_name: 'Plant',
      sppl_ean: '8720664802407',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '5365960',
      rtl_batch_array: '6314270, 5365960',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 2976,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      imageBatch: '6KJSLGP1',
      statusContent: 'dot',
      hash: 'AKCASNWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKCASNWK'
    },
    {
      status: 210,
      sku: 'PM0076254',
      core_name: 'Plant',
      sppl_ean: '8720626311015',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5449485',
      rtl_batch_array: '5449485',
      name_search: 'LONGGLOW',
      name: "Lonicera nitida 'Golden Glow'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A2BCZ5W6',
      imageBatch: 'W5LJ6A5D',
      statusContent: 'dot',
      hash: 'JY4G5249',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY4G5249'
    },
    {
      status: 210,
      sku: 'PM0076256',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286566',
      rtl_batch_array: '6286566',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '040050C10',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 8.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: '235L5AC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '235L5AC3'
    },
    {
      status: 210,
      sku: 'PM0076258',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287029',
      rtl_batch_array: '6287029',
      name_search: 'CHPFAURE',
      name: "Chamaecyparis pisifera 'Filifera Aurea'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 748,
      chnn_stock_retail: 748,
      sppl_prcp: 7.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D938274G;Z3N1PSP3;E4AL6A8J;KBND7J25;L73KP9W2;4D8DGTWR;41L5NBZT;115YCL9T;VW2C9E4C;C5XP9DDY;KB9YSA1W;7P37JNDH',
      statusContent: 'dot',
      hash: 'DEE8RAZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEE8RAZT'
    },
    {
      status: 910,
      sku: 'PM0076260',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CUSTOTEM',
      name: "Cupressus sempervirens 'Totem'",
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      imageCore:
        'YG2X4CNN;RA9NA1XC;7X5RVNVH;9D2Y288X;CJYB7T1X;V1J5GV9B;K4ZGN36L;K7JNPAZN',
      statusContent: 'dot',
      hash: '2HK3VBCW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2HK3VBCW'
    },
    {
      status: 210,
      sku: 'PM0076261',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287033',
      rtl_batch_array: '6287033',
      name_search: 'JUSABINA',
      name: 'Juniperus sabina',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1636,
      chnn_stock_retail: 1636,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5R3XZ6X8;GTABHDTH;RD4DJHET;85C7V2ZW',
      statusContent: 'dot',
      hash: '9AAAKKAJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AAAKKAJ'
    },
    {
      status: 210,
      sku: 'PM0076262',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287034',
      rtl_batch_array: '6287034',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 920,
      chnn_stock_retail: 920,
      sppl_prcp: 3.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'EHXGSWHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHXGSWHJ'
    },
    {
      status: 210,
      sku: 'PM0076255',
      core_name: 'Plant',
      sppl_ean: '8720664809314',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '5456245',
      rtl_batch_array: '5456245',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.829,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      imageBatch: 'TWSRG3AT',
      statusContent: 'dot',
      hash: '6YXEYZN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YXEYZN4'
    },
    {
      status: 210,
      sku: 'PM0076329',
      core_name: 'Plant',
      sppl_ean: '8720349425754',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6052059',
      rtl_batch_array: '6314269, 6052059',
      name_search: 'PIFSILVE',
      name: "Pieris 'Flaming Silver'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 1530,
      sppl_prcp: 6.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCXSKVBJ;HVLPYJBZ;JT4D138Z;VA7AA76B;EP883SGR;H4EX7KA9;YW3E6JLJ;CA16JVCG;Y1PYT182;KKYGL9RE;YJB4NAY4;15XGJLJN;A3DNJ4NP;YJYZ14YH;7ST6YLTR;NG55PDP7;YXD8N8BG;ZJ23XEES;NH3KSJ9C;NXSZK6HG;BGYB1247',
      statusContent: 'dot',
      hash: 'P2T5KCGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2T5KCGV'
    },
    {
      status: 210,
      sku: 'PM0076250',
      core_name: 'Plant',
      sppl_ean: '8720626317505',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5365964',
      rtl_batch_array: '5365964',
      name_search: 'PIJVARIE',
      name: "Pieris japonica 'Variegata'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XK5JHNLD',
      imageBatch: 'JA15NWDH',
      statusContent: 'dot',
      hash: 'GKLEC25H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKLEC25H'
    },
    {
      status: 210,
      sku: 'PM0076325',
      core_name: 'Plant',
      sppl_ean: '8720626326613',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5479986',
      rtl_batch_array: '5479986',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 486,
      chnn_stock_retail: 486,
      sppl_prcp: 8.109,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      imageBatch: 'HRPTZ9S7',
      statusContent: 'dot',
      hash: 'ZLYJWR2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLYJWR2R'
    },
    {
      status: 210,
      sku: 'PM0076432',
      core_name: 'Plant',
      sppl_ean: '8720626326637',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5391872',
      rtl_batch_array: '5391872',
      name_search: 'SKJRUBEL',
      name: "Skimmia japonica 'Rubella'",
      sppl_size_code: 'GMC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C76721YJ;9H72Z7BP;RSDDL92G;T2STPWKG;BY9X1BYB;HXZZE7N1;N59KNDJ5;ZPDN2AGX;6BDLSNT7;SZ1A4HYB;6YCZ9CBZ;EBGYXBZL;AKJ8VR8N;6YKBG1S1;LEBGZ791;9VHGJYKZ;WXV6HPAN;YJX52DJ5;5L52HDD1',
      imageBatch: 'VX459A21',
      statusContent: 'dot',
      hash: '2DER7TE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DER7TE5'
    },
    {
      status: 210,
      sku: 'PM0076251',
      core_name: 'Plant',
      sppl_ean: '8720626363564',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5400063',
      rtl_batch_array: '5400063',
      name_search: 'PIJSARAB',
      name: "Pieris japonica 'Sarabande'",
      sppl_size_code: '045050C10',
      rng_range_identifier: 'H045050C10',
      rng_range_description: 'H45 cm 50 cm C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AA32HJA;6742CTG3',
      imageBatch: 'DL7JLR1D',
      statusContent: 'dot',
      hash: 'KR3LPPXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KR3LPPXG'
    },
    {
      status: 210,
      sku: 'PM0076252',
      core_name: 'Plant',
      sppl_ean: '8720626361102',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5425495',
      rtl_batch_array: '5425495',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '060080C14',
      rng_range_identifier: 'H060080C1.5',
      rng_range_description: 'H60 cm 80 cm C1.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: 'CNVY4YCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CNVY4YCN'
    },
    {
      status: 210,
      sku: 'PM0083804',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356669',
      rtl_batch_array: '6356669',
      name_search: 'BEWILSON',
      name: 'Berberis wilsoniae',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 1.753,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJKD85L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJKD85L8'
    },
    {
      status: 210,
      sku: 'PM0083805',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356670',
      rtl_batch_array: '6356670',
      name_search: 'BUMROCOC',
      name: "Buxus microphylla 'Rococo'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 706,
      chnn_stock_retail: 706,
      sppl_prcp: 2.259,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YHW6Y3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YHW6Y3T'
    },
    {
      status: 210,
      sku: 'PM0084149',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352670',
      rtl_batch_array: '6352670',
      name_search: 'STPULCHE',
      name: 'Stipa pulcherrima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L2VD94KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2VD94KY'
    },
    {
      status: 810,
      sku: 'PM0084150',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6352675',
      rtl_batch_array: '6352675',
      name_search: 'VEPROSTR',
      name: 'Veronica prostrata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8V9ZZVHD',
      statusContent: 'dot',
      hash: '5NBYX4E8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NBYX4E8'
    },
    {
      status: 210,
      sku: 'PM0084151',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6369261',
      rtl_batch_array: '6369261, 6352677',
      name_search: 'VEVIRGIN',
      name: 'Veronicastrum virginicum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1125,
      chnn_stock_retail: 1150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4R2Z3EAS;8RE7L13X;DTAZSEEV;8ZLVRT15',
      statusContent: 'dot',
      hash: 'LBSZJR2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LBSZJR2G'
    },
    {
      status: 210,
      sku: 'PM0084152',
      core_name: 'Plant',
      sppl_ean: '8720664613416',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5776574',
      rtl_batch_array: '5776574',
      name_search: 'PYOGLOW',
      name: "Pyracantha 'Orange Glow'",
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.485,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBYDG2VB;JEBSXAXG',
      statusContent: 'dot',
      hash: 'SRES64A9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SRES64A9'
    },
    {
      status: 810,
      sku: 'PM0084153',
      core_name: 'Plant',
      sppl_ean: '8720626321670',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5776575',
      rtl_batch_array: '5776575',
      name_search: 'PYCRCOLU',
      name: "Pyracantha coccinea 'Red Column'",
      sppl_size_code: '040050C14',
      rng_range_identifier: 'H040045C1.5',
      rng_range_description: 'H40 cm 45 cm C1.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.238,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B18HB2C9;5ZAV8GNX',
      statusContent: 'dot',
      hash: 'SXCK29WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXCK29WG'
    },
    {
      status: 210,
      sku: 'PM0084154',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252943',
      rtl_batch_array: '6252943',
      name_search: 'RUACETOS',
      name: 'Rumex acetosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5PCZ7NY8;ZPXGBH84;L654VSSA;AT36A76N;HTA6SBHR;5XGPJL1G;HT154115;GRHZCGH7;17Y81TRA;NAVE5C1S',
      statusContent: 'dot',
      hash: 'PSREBVTA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSREBVTA'
    },
    {
      status: 210,
      sku: 'PM0084155',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252967',
      rtl_batch_array: '6252967',
      name_search: 'SOLUTEUS',
      name: 'Solidaster luteus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 111,
      chnn_stock_retail: 111,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NVPZANKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVPZANKR'
    },
    {
      status: 210,
      sku: 'PM0084156',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5840045',
      rtl_batch_array: '5840045',
      name_search: 'ILCCUPRI',
      name: "Ilex crenata 'Caroline Upright'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A746RG24;C921G5Z4;RHGD7HNJ;JCX4WZJ3;ATHA5AAV;SN43Z96P;4PVT7VZS;D9EV88PD;AV8Z35PN;Z1XKTCVG;BHNEZ56B;L777N6Z2;VZEASPGE;BP3Z897S;J4YYBWG1;RBLB19CN;1WPAJ7T5;AJPKY4P4;B9JDXYZX;YJP5NJ6V;XWYZ1L2X;NPW3NGHX;VYBAC3H4;LYL4PYNY;46JSS77A;WGCTX1P8',
      statusContent: 'dot',
      hash: 'LVZ5AYNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVZ5AYNX'
    },
    {
      status: 210,
      sku: 'PM0084157',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6077491',
      rtl_batch_array: '6077491',
      name_search: 'ASAUGENW',
      name: "Aster (D) 'Augenweide'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KALX93G4;TS677H5B;LNV6G7WR;BP8RPY9P;RVKXPVXC;755BVW7X;294JGPS3',
      statusContent: 'dot',
      hash: 'X7T4Y453',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7T4Y453'
    },
    {
      status: 810,
      sku: 'PM0084158',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6216967',
      rtl_batch_array: '6369221, 6216967',
      name_search: 'ACIPURPU',
      name: "Acaena inermis 'Purpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 79,
      chnn_stock_retail: 199,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CRZLB297',
      statusContent: 'dot',
      hash: 'A2A5XD8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2A5XD8J'
    },
    {
      status: 810,
      sku: 'PM0084159',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217005',
      rtl_batch_array: '6217005',
      name_search: 'AUCRED',
      name: "Aubrieta 'Cascade Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X3JNEN9S;HY49JK9Y',
      statusContent: 'dot',
      hash: 'RCSVY5RH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCSVY5RH'
    },
    {
      status: 810,
      sku: 'PM0084160',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6217015',
      rtl_batch_array: '6386096, 6217015',
      name_search: 'CAPSILBE',
      name: "Campanula poscharskyana 'Silberregen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 268,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WE3A9XEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WE3A9XEN'
    },
    {
      status: 210,
      sku: 'PM0084161',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217108',
      rtl_batch_array: '6217108',
      name_search: 'DIGEYDAN',
      name: "Dianthus gratianopolitanus 'Eydangeri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B7LVA32K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B7LVA32K'
    },
    {
      status: 210,
      sku: 'PM0084162',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217161',
      rtl_batch_array: '6217161',
      name_search: 'HESPLENU',
      name: "Helianthemum 'Sulphureum Plenum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A6XD39GS',
      statusContent: 'dot',
      hash: 'PGEVK99Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGEVK99Z'
    },
    {
      status: 210,
      sku: 'PM0084163',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217195',
      rtl_batch_array: '6217195',
      name_search: 'LAVAEPUR',
      name: "Lavandula angustifolia 'Ellagance Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A77G2YB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A77G2YB4'
    },
    {
      status: 210,
      sku: 'PM0084164',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217196',
      rtl_batch_array: '6217196',
      name_search: 'LAISILVE',
      name: "Lavandula intermedia 'Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 681,
      chnn_stock_retail: 681,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LL84AKHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL84AKHW'
    },
    {
      status: 210,
      sku: 'PM0084165',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217222',
      rtl_batch_array: '6217222',
      name_search: 'THCSQUEE',
      name: "Thymus citriodorus 'Silver Queen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 503,
      chnn_stock_retail: 503,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2168C2X2;1LD46KT2;S8W3EC62;B4W1G7P3;699SV1XY;NYP71S46;55CRAWNH;1JZ9Y3GR;CENXK9G6;JV375CW5;W8JE6SN9;1LDPJLRG;BLGZCTKL;7NBRWDAY;VWN13DEW;ARJABHLG;LLYDSZT7;WD2XA758;YT3J2AV5',
      statusContent: 'dot',
      hash: 'RL56PT83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RL56PT83'
    },
    {
      status: 210,
      sku: 'PM0084166',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6217223',
      rtl_batch_array: '6217223',
      name_search: 'THPPURPU',
      name: "Thymus pulegioides 'Purpurteppich'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 318,
      chnn_stock_retail: 318,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6R9LBVXP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6R9LBVXP'
    },
    {
      status: 210,
      sku: 'PM0084167',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6181399',
      rtl_batch_array: '6330013, 6181399',
      name_search: 'LAIDUTCH',
      name: "Lavandula intermedia 'Dutch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 888,
      chnn_stock_retail: 1728,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJAAHNKA;1J8E2JKW;2V9H9LRW;GGRRVWYR;633VLRTX;7D3E8K1D',
      statusContent: 'dot',
      hash: 'PJDKBH57',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJDKBH57'
    },
    {
      status: 210,
      sku: 'PM0084168',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238757',
      rtl_batch_array: '6238757',
      name_search: 'NENPCAT',
      name: "Nepeta nuda 'Purple Cat'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJKR6SNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJKR6SNP'
    },
    {
      status: 810,
      sku: 'PM0076314',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288300',
      rtl_batch_array: '6288300',
      name_search: 'ANDRWUND',
      name: "Antennaria dioica 'Rotes Wunder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PD9C7EBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PD9C7EBH'
    },
    {
      status: 210,
      sku: 'PM0076315',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6289995',
      rtl_batch_array: '6288302, 6289995',
      name_search: 'ARMRCOMP',
      name: "Armeria maritima 'Rosea Compacta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 372,
      chnn_stock_retail: 452,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XS93E6RT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS93E6RT'
    },
    {
      status: 210,
      sku: 'PM0076318',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288307',
      rtl_batch_array: '6288307',
      name_search: 'SKJFINCH',
      name: "Skimmia japonica 'Finchy'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 4.029,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KAW412EE;DHTGKR8E;T4J17KR3;11LYS298;2RRR3349;AAYCJPHG;2GYB73AT;L4NHR2YK',
      statusContent: 'dot',
      hash: 'K9E7J7RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9E7J7RL'
    },
    {
      status: 210,
      sku: 'PM0076321',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288310',
      rtl_batch_array: '6288310',
      name_search: 'PIJTAIWA',
      name: "Pieris jap. 'Taiwanensis'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 15.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PP6641V4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP6641V4'
    },
    {
      status: 210,
      sku: 'PM0076322',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6288341',
      rtl_batch_array: '6288341',
      name_search: 'CHTTPOIN',
      name: "Chamaecyparis thyoides 'Top Point'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 3.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JKC3AP;6NKLZA4S;8RXGWSTS;7LH83EY6;8K7EZDJR;ELV9WX61;8PPJ43P4;TC9E3BHB',
      statusContent: 'dot',
      hash: 'LNVKN3T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNVKN3T9'
    },
    {
      status: 210,
      sku: 'PM0076326',
      core_name: 'Plant',
      sppl_ean: '8720626366688',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5763682',
      rtl_batch_array: '5763682',
      name_search: 'RHGWATER',
      name: "Rhododendron 'Gomer Waterer'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E439JKAS;6N4GX7BY;E34VC6PY;DX6LRX9E;EB1ZPE9C;GWAD9TPG;GSKBKDNB;B72PLSE8;PV8KTLAA;Y9S8YNLK;47APPE71;DC9EX2T8',
      imageBatch: 'VWPH9T26',
      statusContent: 'dot',
      hash: 'NBRHSTEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBRHSTEW'
    },
    {
      status: 210,
      sku: 'PM0076327',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '5840050',
      rtl_batch_array: '5840050',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: '060080C4',
      rng_range_identifier: 'H060080C4',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 2.723,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      statusContent: 'dot',
      hash: 'Z665TW2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z665TW2K'
    },
    {
      status: 810,
      sku: 'PM0076330',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6014242',
      rtl_batch_array: '6014242',
      name_search: 'OLAMARK',
      name: "Olearia algida 'Mark'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AB5ZC1WT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB5ZC1WT'
    },
    {
      status: 210,
      sku: 'PM0076331',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '6228466',
      rtl_batch_array: '6228466',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: 'XC6YCHCS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XC6YCHCS'
    },
    {
      status: 210,
      sku: 'PM0076407',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290313',
      rtl_batch_array: '6290313',
      name_search: 'CAJLCAMP',
      name: "Camellia japonica 'Lady Campbell'",
      sppl_size_code: '040060C74',
      rng_range_identifier: 'H040060C7.5',
      sppl_stock_available: 122,
      chnn_stock_retail: 122,
      sppl_prcp: 8.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8C9ASV2H;CHCP2CPX;CE6YKGT8;L6E2DXRC;LV1JYG15',
      statusContent: 'dot',
      hash: 'XSSZYJVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSSZYJVB'
    },
    {
      status: 210,
      sku: 'PM0076408',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290314',
      rtl_batch_array: '6290314',
      name_search: 'CAJBYELL',
      name: "Camellia japonica 'Brushfield's Yellow'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 8.33,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H6ZLZ48A;9KNXTTVB;TD3XAJD1;NTG3LD66;PS86LZWJ;46A7G9CE;EEDNZWXD',
      statusContent: 'dot',
      hash: 'B2SHYYC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B2SHYYC3'
    },
    {
      status: 210,
      sku: 'PM0076409',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290374',
      rtl_batch_array: '6290374',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '200225C20',
      rng_range_identifier: 'H200225C20',
      rng_range_description: 'H200 cm 225 cm C20',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 30.367,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'X4B8338E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4B8338E'
    },
    {
      status: 210,
      sku: 'PM0076410',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6257506',
      rtl_batch_array: '6257506',
      name_search: 'TAMHILLI',
      name: "Taxus media 'Hillii'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 1812,
      chnn_stock_retail: 1812,
      sppl_prcp: 4.636,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J9SRLT2G;GE687RGV;ZG7ZS7XV',
      statusContent: 'dot',
      hash: 'WX5XBAH2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WX5XBAH2'
    },
    {
      status: 210,
      sku: 'PM0076411',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112252',
      rtl_batch_array: '6112252',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 3.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2WJ6B3ZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WJ6B3ZB'
    },
    {
      status: 210,
      sku: 'PM0076433',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276612',
      rtl_batch_array: '6276612',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '100120C10',
      rng_range_identifier: 'H100120C10',
      rng_range_description: 'H100 cm 120 cm C10',
      sppl_stock_available: 1727,
      chnn_stock_retail: 1727,
      sppl_prcp: 8.885,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'JCN8YJ5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCN8YJ5Z'
    },
    {
      status: 210,
      sku: 'PM0076450',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254718',
      rtl_batch_array: '6254718',
      name_search: 'ECTSOUP',
      name: "Echinacea 'Tomato Soup'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1020,
      chnn_stock_retail: 1020,
      sppl_prcp: 2.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WG9PNLTD',
      statusContent: 'dot',
      hash: '7CWACRW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CWACRW8'
    },
    {
      status: 210,
      sku: 'PM0076455',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254822',
      rtl_batch_array: '6254822',
      name_search: 'ROHENRIC',
      name: 'Rodgersia henrici',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 749,
      chnn_stock_retail: 749,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSWVB83G',
      statusContent: 'dot',
      hash: 'NTCRGGTE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTCRGGTE'
    },
    {
      status: 210,
      sku: 'PM0076460',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234706',
      rtl_batch_array: '6234706',
      name_search: 'EUPURPUR',
      name: 'Eupatorium purpureum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 397,
      chnn_stock_retail: 397,
      sppl_prcp: 1.643,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PCZXWBKT;GAWJRSDN;K7W1LGN9;K9JCAZHW',
      statusContent: 'dot',
      hash: 'PHR8NXPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHR8NXPE'
    },
    {
      status: 210,
      sku: 'PM0076457',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6234561',
      rtl_batch_array: '6305785, 6234561',
      name_search: 'ANHSPLEN',
      name: "Anemone hupehensis 'Splendens'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 694,
      chnn_stock_retail: 1120,
      sppl_prcp: 2.215,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RY283ZVB;N2HHZHBE;AHEHX9D8;8XBBR6H7;E98ZGW45;SZE1X88E;AC7ADPGT;7EZR8V12;Y8KP4KNK;GSJ4GVYS',
      statusContent: 'dot',
      hash: 'RSRR6GDD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSRR6GDD'
    },
    {
      status: 210,
      sku: 'PM0076451',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254738',
      rtl_batch_array: '6254738',
      name_search: 'GEPALUST',
      name: 'Geranium palustre',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6L6RYGKW;13DZEVN5',
      statusContent: 'dot',
      hash: 'S34L85LK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S34L85LK'
    },
    {
      status: 210,
      sku: 'PM0076452',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254754',
      rtl_batch_array: '6254754',
      name_search: 'HOJUNE',
      name: "Hosta 'June'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_prcp: 2.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SPHBYEWZ;DRY3S5KR;17D3C567;VEH9EL6P;LSKB8XWV;DWHYDJN5;JV6VN8SB;AW7TJ929;2BWAESPG;NBZGGJRT;6ZSDTHRW;HJVT8NNA',
      statusContent: 'dot',
      hash: '61N1JC47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '61N1JC47'
    },
    {
      status: 210,
      sku: 'PM0083806',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356673',
      rtl_batch_array: '6356673',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.854,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'D7RTJN19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7RTJN19'
    },
    {
      status: 210,
      sku: 'PM0083807',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356675',
      rtl_batch_array: '6356675',
      name_search: 'LIVATROV',
      name: "Ligustrum vulgare 'Atrovirens'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.609,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XD91KAKK;5PJ9395C;87P7HELZ;ZYVGP18B;SD4RL6YC;CLKY5PRP',
      statusContent: 'dot',
      hash: '22WY9B4J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22WY9B4J'
    },
    {
      status: 210,
      sku: 'PM0076454',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254818',
      rtl_batch_array: '6254818',
      name_search: 'POSHERRE',
      name: "Polystichum setiferum 'Herrenhausen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 1.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XCRZBCVB;X5PE5WWT;DHX9GPWA;YGZSEDVS;225N912G;6Y6NEB7G;DZW6V6B6;HB5XE6EZ;RR69CTST',
      statusContent: 'dot',
      hash: 'VH4XL355',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VH4XL355'
    },
    {
      status: 210,
      sku: 'PM0076448',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291753',
      rtl_batch_array: '6291753',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 7.848,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '98S58SPJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98S58SPJ'
    },
    {
      status: 810,
      sku: 'PM0076447',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291752',
      rtl_batch_array: '6291752',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.868,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '9B7LRBBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B7LRBBN'
    },
    {
      status: 210,
      sku: 'PM0076459',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234695',
      rtl_batch_array: '6234695',
      name_search: 'ECSYELLO',
      name: 'Echinacea Sunseekers Yellow',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_prcp: 2.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9RWCATRJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RWCATRJ'
    },
    {
      status: 210,
      sku: 'PM0083808',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356677',
      rtl_batch_array: '6356677',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 1.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'B24E6ZYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B24E6ZYX'
    },
    {
      status: 210,
      sku: 'PM0083809',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356678',
      rtl_batch_array: '6356678',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'T93ZAPY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T93ZAPY9'
    },
    {
      status: 210,
      sku: 'PM0083810',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356679',
      rtl_batch_array: '6356679',
      name_search: 'MAAQUIFO',
      name: 'Mahonia aquifolium',
      sppl_size_code: '030050C3',
      rng_range_identifier: 'H030050C3',
      rng_range_description: 'H30 cm 50 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 1.962,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P686XDB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P686XDB8'
    },
    {
      status: 210,
      sku: 'PM0084169',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6359492',
      rtl_batch_array: '6359492',
      name_search: 'ECBTBLUE',
      name: "Echinops bannaticus 'Taplow Blue'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 7360,
      chnn_stock_retail: 7360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '29JRG36N;GDD5WC18;JHD854Z2;N7DKC6S8',
      statusContent: 'dot',
      hash: 'RJTVTTE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJTVTTE9'
    },
    {
      status: 210,
      sku: 'PM0084170',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '6362919',
      rtl_batch_array: '6362919',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '025030C24',
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.228,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'EB95BE17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EB95BE17'
    },
    {
      status: 210,
      sku: 'PM0076217',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350108',
      rtl_batch_array: '6350108',
      name_search: 'ECSDRUBY',
      name: 'Echinacea Sunny Days Ruby',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 276,
      chnn_stock_retail: 276,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HEWVXXVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEWVXXVZ'
    },
    {
      status: 910,
      sku: 'PM0076412',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290757',
      rtl_batch_array: '6290757',
      name_search: 'PISMARTH',
      name: "Pinus sylvestris 'Martham'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W5551HD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5551HD4'
    },
    {
      status: 210,
      sku: 'PM0076413',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290759',
      rtl_batch_array: '6290759',
      name_search: 'CRJJINDA',
      name: "Cryptomeria japonica 'Jindai'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 611,
      chnn_stock_retail: 611,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2GW2K73R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2GW2K73R'
    },
    {
      status: 210,
      sku: 'PM0076414',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290760',
      rtl_batch_array: '6290760',
      name_search: 'CRJSERAM',
      name: 'Cryptomeria japonica Serama',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 114,
      chnn_stock_retail: 114,
      sppl_prcp: 6.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YV8VPNVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YV8VPNVB'
    },
    {
      status: 210,
      sku: 'PM0076415',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290762',
      rtl_batch_array: '6290762',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '150175C74',
      rng_range_identifier: 'H150175C7.5',
      rng_range_description: 'H150 cm 175 cm C7.5',
      sppl_stock_available: 3610,
      chnn_stock_retail: 3610,
      sppl_prcp: 9.91,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: '852CZ1XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '852CZ1XY'
    },
    {
      status: 210,
      sku: 'PM0076416',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290763',
      rtl_batch_array: '6290763',
      name_search: 'JUCGOLDS',
      name: "Juniperus communis 'Goldschatz'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 1439,
      chnn_stock_retail: 1439,
      sppl_prcp: 3.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W17BD5LD;5CKD61E9;GGXNB6HG;V6P7N72H;WZNKJZ3B',
      statusContent: 'dot',
      hash: 'ARD843XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARD843XP'
    },
    {
      status: 210,
      sku: 'PM0076417',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290764',
      rtl_batch_array: '6290764',
      name_search: 'JUPGCOAS',
      name: "Juniperus pfitzeriana 'Gold Coast'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1145,
      chnn_stock_retail: 1145,
      sppl_prcp: 3.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EKGY93JY;16ZCPHTE;SWYLL49R;RRNGWR67;4C3YTV67',
      statusContent: 'dot',
      hash: '6A1A85X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6A1A85X2'
    },
    {
      status: 910,
      sku: 'PM0076419',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290767',
      rtl_batch_array: '6290767',
      name_search: 'PIABORB',
      name: "Picea abies 'Bad Orb'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LDETXWSZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDETXWSZ'
    },
    {
      status: 910,
      sku: 'PM0076420',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290768',
      rtl_batch_array: '6290768',
      name_search: 'PIAGDRIF',
      name: "Picea abies 'Gold Drift'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X53B339Z',
      statusContent: 'dot',
      hash: 'RC3WLP1V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC3WLP1V'
    },
    {
      status: 910,
      sku: 'PM0076421',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290769',
      rtl_batch_array: '6290769',
      name_search: 'PIPBLUES',
      name: "Picea pungens 'The Blues'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1P1SH2YT',
      statusContent: 'dot',
      hash: 'TK4ZP6ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TK4ZP6ZY'
    },
    {
      status: 910,
      sku: 'PM0076422',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290770',
      rtl_batch_array: '6290770',
      name_search: 'PIMBENJA',
      name: "Pinus mugo 'Benjamin'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 69.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K4P1XYZA',
      statusContent: 'dot',
      hash: '6B2J5RB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6B2J5RB8'
    },
    {
      status: 210,
      sku: 'PM0076435',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307818',
      rtl_batch_array: '6307818',
      name_search: 'FAJIUZHA',
      name: "Fargesia 'Jiuzhaigou 1'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 467,
      chnn_stock_retail: 467,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '96HN9GAZ',
      statusContent: 'dot',
      hash: '16S1S63P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16S1S63P'
    },
    {
      status: 810,
      sku: 'PM0076442',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307724',
      rtl_batch_array: '6307724',
      name_search: 'CHONAURE',
      name: "Chamaecyparis obtusa 'Nana Aurea'",
      sppl_size_code: '030040C74',
      rng_range_identifier: 'H030040C7.5',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 23.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTHWK8H;LZ1V2K2Y;65A8NG2C',
      statusContent: 'dot',
      hash: '8CT2X8NE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CT2X8NE'
    },
    {
      status: 810,
      sku: 'PM0076443',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307725',
      rtl_batch_array: '6307725',
      name_search: 'CHONAURE',
      name: "Chamaecyparis obtusa 'Nana Aurea'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 28.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTHWK8H;LZ1V2K2Y;65A8NG2C',
      statusContent: 'dot',
      hash: 'THWHYEE2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THWHYEE2'
    },
    {
      status: 910,
      sku: 'PM0076444',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307726',
      rtl_batch_array: '6307726',
      name_search: 'CHONAURE',
      name: "Chamaecyparis obtusa 'Nana Aurea'",
      sppl_size_code: '050060C12',
      rng_range_identifier: 'H050060C12',
      rng_range_description: 'H50 cm 60 cm C12',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_prcp: 33.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HVTHWK8H;LZ1V2K2Y;65A8NG2C',
      statusContent: 'dot',
      hash: '1XWCSRPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XWCSRPT'
    },
    {
      status: 910,
      sku: 'PM0076445',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      statusContent: 'dot',
      hash: '86H2SX6A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '86H2SX6A'
    },
    {
      status: 210,
      sku: 'PM0076446',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291750',
      rtl_batch_array: '6291750',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'PH3YH59S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH3YH59S'
    },
    {
      status: 210,
      sku: 'PM0076456',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6210042',
      rtl_batch_array: '6210042',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: '7E3KDHAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E3KDHAT'
    },
    {
      status: 210,
      sku: 'PM0076458',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6234605',
      rtl_batch_array: '6234605',
      name_search: 'BOGRACIL',
      name: 'Bouteloua gracilis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 465,
      chnn_stock_retail: 465,
      sppl_prcp: 1.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JJR8YPKP',
      statusContent: 'dot',
      hash: 'XE2KG8TS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XE2KG8TS'
    },
    {
      status: 210,
      sku: 'PM0040937',
      core_name: 'Plant',
      sppl_ean: '8720664876743',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420703',
      rtl_batch_array: '5420703',
      name_search: 'PAQENGEL',
      name: 'Parthenocissus quinquefolia engelmannii',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2540,
      chnn_stock_retail: 2540,
      sppl_order_minimum: 3,
      sppl_prcp: 2.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SG1H657G;1D75YPT3;JC84WWJZ;XY55AD12;DPP95XZR;TAVEAT1N',
      statusContent: 'dot',
      hash: '7Y4THYY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Y4THYY6'
    },
    {
      status: 210,
      sku: 'PM0041004',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254245',
      rtl_batch_array: '6300175, 6254245',
      name_search: 'CAARBORE',
      name: 'Caragana arborescens',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 430,
      chnn_stock_retail: 1630,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '89SW4EBC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89SW4EBC'
    },
    {
      status: 210,
      sku: 'PM0040939',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 4,
      btch_active_retail: '6266112',
      rtl_batch_array: '6076594, 5932393, 6265438, 6266112',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 5740,
      chnn_stock_retail: 11757,
      sppl_prcp: 1.473,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: 'DCW42VKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCW42VKH'
    },
    {
      status: 210,
      sku: 'PM0076449',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252780',
      rtl_batch_array: '6252780',
      name_search: 'STCAPILL',
      name: 'Stipa capillata',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9SWVKZN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9SWVKZN8'
    },
    {
      status: 210,
      sku: 'PM0076453',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254757',
      rtl_batch_array: '6254757',
      name_search: 'HULUPULU',
      name: 'Humulus lupulus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 353,
      chnn_stock_retail: 353,
      sppl_prcp: 1.791,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XSBNC5CW;V31YG91S',
      statusContent: 'dot',
      hash: 'KYDY539P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYDY539P'
    },
    {
      status: 810,
      sku: 'PM0076551',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294805',
      rtl_batch_array: '6294805',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 3.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'NZZA8GZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZZA8GZL'
    },
    {
      status: 210,
      sku: 'PM0076552',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294808',
      rtl_batch_array: '6294808',
      name_search: 'HYQUERCI',
      name: 'Hydrangea quercifolia',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_prcp: 14.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVK5Z4LC;1ED1CDTC;N82WH7J2;WEY5KCYP;19EDLHJD;VGDAHK7L;K4J8HWAC;KZGKSNB7;7G4RT3WG;1XZ6TDPB;963748A6;7RR2Z9LE;2N54H7JS;V1Z7NYJ4;J7N2VT6R;BKK3K7ZA;BKLS5D1D;VAT3L8B4',
      statusContent: 'dot',
      hash: 'AXBHJVHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXBHJVHJ'
    },
    {
      status: 210,
      sku: 'PM0076554',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338022',
      rtl_batch_array: '6294812, 6338022',
      name_search: 'LOFRAGRA',
      name: 'Lonicera fragrantissima',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1686,
      chnn_stock_retail: 2650,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6PVYTDWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PVYTDWG'
    },
    {
      status: 210,
      sku: 'PM0076555',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294813',
      rtl_batch_array: '6294813',
      name_search: 'LOPWBEAU',
      name: "Lonicera purpusii 'Winter Beauty'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1036,
      chnn_stock_retail: 1036,
      sppl_prcp: 3.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AGVLPZXY',
      statusContent: 'dot',
      hash: 'VERPJX7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VERPJX7W'
    },
    {
      status: 210,
      sku: 'PM0076553',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294811',
      rtl_batch_array: '6294811',
      name_search: 'ITVHGARN',
      name: "Itea virginica 'Henry's Garnet'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.546,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZLL83K2',
      statusContent: 'dot',
      hash: 'JCBPKGKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCBPKGKN'
    },
    {
      status: 210,
      sku: 'PM0076341',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289990',
      rtl_batch_array: '6289990',
      name_search: 'ANVULNER',
      name: 'Anthyllis vulneraria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 491,
      chnn_stock_retail: 491,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S9J4V4GZ',
      statusContent: 'dot',
      hash: 'YX437C1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YX437C1X'
    },
    {
      status: 210,
      sku: 'PM0076423',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291242',
      rtl_batch_array: '6291242, 6339963',
      name_search: 'SERCAULI',
      name: "Sedum 'Red Cauli'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9P7XYLA;WJGXTE3L',
      statusContent: 'dot',
      hash: 'PS2B35B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PS2B35B7'
    },
    {
      status: 210,
      sku: 'PM0076491',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338135',
      rtl_batch_array: '5446542, 6338135',
      name_search: 'HYPUNIQU',
      name: "Hydrangea paniculata 'Unique'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1542,
      chnn_stock_retail: 1761,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DD9LJPH;KB36AY45;J7Z7T8C5;1KGH13HA;YNA2SKY1;V2X3SEVT;EEC6931R;XX2XP852;WPKTKD2R',
      statusContent: 'dot',
      hash: '5X2YN4GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X2YN4GD'
    },
    {
      status: 810,
      sku: 'PM0076503',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292726',
      rtl_batch_array: '6292726',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '100150C15',
      rng_range_identifier: 'H100150C15',
      rng_range_description: 'H100 cm 150 cm C15',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 28.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'N7ZTDXP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N7ZTDXP2'
    },
    {
      status: 210,
      sku: 'PM0010958',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242119',
      rtl_batch_array: '6242119, 6353244, 6333300, 6348411',
      name_search: 'PTAQUILI',
      name: 'Pteridium aquilinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 10634,
      sppl_order_minimum: 3,
      sppl_prcp: 0.787,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GJNGZ2LJ',
      statusContent: 'dot',
      hash: '19YV3EDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19YV3EDB'
    },
    {
      status: 810,
      sku: 'PM0076547',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294801',
      rtl_batch_array: '6294801',
      name_search: 'HYMALPEN',
      name: "Hydrangea macrophylla 'Alpengl\u00fchen'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YBDL9HL3;L2T74BPR;TZJY7D9W;2CGJ8PHA;KPEG1W15;S7V6JJ49',
      statusContent: 'dot',
      hash: '8XSAKKN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XSAKKN1'
    },
    {
      status: 210,
      sku: 'PM0076504',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292727',
      rtl_batch_array: '6292727',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '100150C15',
      rng_range_identifier: 'H100150C15',
      rng_range_description: 'H100 cm 150 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 28.55,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'SYZLA7BX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYZLA7BX'
    },
    {
      status: 210,
      sku: 'PM0076500',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292723',
      rtl_batch_array: '6292723',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '050060C6',
      rng_range_identifier: 'H050060C6',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 11.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'RVGBCWSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVGBCWSE'
    },
    {
      status: 210,
      sku: 'PM0076218',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353481',
      rtl_batch_array: '6353481',
      name_search: 'SYOMROUG',
      name: "Symphytum officin. 'Moulin Rouge'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9CA63A1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CA63A1E'
    },
    {
      status: 210,
      sku: 'PM0076338',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289987',
      rtl_batch_array: '6289987',
      name_search: 'ASNAACHA',
      name: "Aster novae-angliae 'Annabelle de Chazal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HKVKXKDN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKVKXKDN'
    },
    {
      status: 210,
      sku: 'PM0076339',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289988',
      rtl_batch_array: '6289988',
      name_search: 'ASNCONST',
      name: "Aster n.-a. 'Constance'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ER241VXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ER241VXS'
    },
    {
      status: 810,
      sku: 'PM0076424',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6291245',
      rtl_batch_array: '6291245, 6295518, 6333219',
      name_search: 'DRACRIST',
      name: "Dryopteris affinis 'Cristata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 1089,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8NWBB6TK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NWBB6TK'
    },
    {
      status: 210,
      sku: 'PM0076425',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291258',
      rtl_batch_array: '6291258',
      name_search: 'PIALGEM',
      name: "Picea abies 'Little Gem'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_prcp: 5.436,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GJ1GP8ZY;L68K9YT5;RX5T3XK6;ST3KW93Y;TRHC4E63;GSNRW614;JEK8T3VH;49VVGX6J',
      statusContent: 'dot',
      hash: 'TG38RKTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TG38RKTL'
    },
    {
      status: 210,
      sku: 'PM0076426',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291259',
      rtl_batch_array: '6291259',
      name_search: 'PIANIDIF',
      name: "Picea abies 'Nidiformis'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 435,
      chnn_stock_retail: 435,
      sppl_prcp: 4.786,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7SDXTPGH;ZS2L161S;B24CVBXA;GPRNNAHK;Z4NB8RW8;WK976K43;H2XA7VDP;TA5725B7',
      statusContent: 'dot',
      hash: 'Y2NY9B9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y2NY9B9A'
    },
    {
      status: 210,
      sku: 'PM0076461',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350063',
      rtl_batch_array: '6350063',
      name_search: 'ECPMARMA',
      name: "Echinacea purpurea 'Marmalade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RRA7P6XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RRA7P6XW'
    },
    {
      status: 210,
      sku: 'PM0076462',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350640',
      rtl_batch_array: '6350640',
      name_search: 'LEBRIGHT',
      name: "Leucanthemum (S) 'Brightside'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z8N5KDXH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8N5KDXH'
    },
    {
      status: 210,
      sku: 'PM0009179',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 3,
      btch_active_retail: '6134565',
      rtl_batch_array: '6134565, 6242167, 5607025',
      name_search: 'MOAKFOER',
      name: "Molinia arundinacea 'Karl Foerster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1140,
      chnn_stock_retail: 7072,
      sppl_order_minimum: 3,
      sppl_prcp: 0.617,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RYY9XHX8;4Z1GG7NG;2EVDY2G3;DAGD582L;CE33VY79;VB9X6CZ4;Z1E1584H;CBATDK6R;E8PYECE4;7SVVWBK2;H9ZL2RJS;J3KBTD3S;W4DSYPB1',
      statusContent: 'dot',
      hash: '1PLSCTBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PLSCTBP'
    },
    {
      status: 210,
      sku: 'PM0076498',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292721',
      rtl_batch_array: '6292721',
      name_search: 'ACPBLOOD',
      name: "Acer palmatum 'Bloodgood'",
      sppl_size_code: '040060C4',
      rng_range_identifier: 'H040060C4',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 8.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW7EHND6;CAL8S171;TLKGC2C1;LEH8AXLZ;H8D7RWPN;RJSXYWCY;TLC8EXZS;ZHV5A7ZC;1WTDK4K5;JTGYWBB7;TP859R1N;PZ4ZVWKS',
      statusContent: 'dot',
      hash: 'E7XEEYXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7XEEYXN'
    },
    {
      status: 210,
      sku: 'PM0083811',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 2,
      btch_active_retail: '6373417',
      rtl_batch_array: '6373417, 6356681',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 250,
      chnn_stock_retail: 306,
      sppl_prcp: 6.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'AY6ENTDY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AY6ENTDY'
    },
    {
      status: 210,
      sku: 'PM0021381',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6117685',
      rtl_batch_array: '6117685, 6137780',
      name_search: 'RUDFGOLD',
      name: "Rudbeckia fulgida 'Goldsturm'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 5488,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWBYZAT4;RW4PBGXP;4382951C;4T7BH8T8;7YJ5DGT6;AV5XNPYH;WD72SLRX;P5SH553W;82JWC1YN;E7ELP1EL;VSZE85ZT;C5R22YYD;284BERXS;5V3TTLV8;G56E54EX;JA14SGC8;SEH17VVR;WGYGPZR7;V6XVHHAC;7X81JZHJ',
      statusContent: 'dot',
      hash: '2NA6V3K9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NA6V3K9'
    },
    {
      status: 910,
      sku: 'PM0027980',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POFKOBOL',
      name: "Potentilla fruticosa 'Kobold'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: 'HJ76LLSD;AK1NNW2Y;XJ7T11RN;EKAH23XJ;7N9P9JPC;LXY1JY8A',
      statusContent: 'dot',
      hash: '417BTG1W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '417BTG1W'
    },
    {
      status: 210,
      sku: 'PM0011914',
      core_name: 'Plant',
      sppl_ean: '8720664803565',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084675',
      rtl_batch_array: '6084675',
      name_search: 'PRACCARP',
      name: "Pritzelago alpina 'Chrystal Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1032,
      chnn_stock_retail: 1032,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7Y7VKWAJ;EC3PW8A6;TR1BYGWA;XTL4JPDG;TL9WV62Y;58WBKB2X;RKCC14NA;JTTG4BN6;29ZE3YGD;Y5H8WKVK;H3XKKH4L;5ANTD276;CA9KCK5K;S155YLLV',
      statusContent: 'dot',
      hash: '42VTZRGW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42VTZRGW'
    },
    {
      status: 210,
      sku: 'PM0006228',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353330',
      rtl_batch_array: '6353330',
      name_search: 'SANSCBRO',
      name: 'Salvia nemorosa Sensation Compact Bright Rose',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 670,
      chnn_stock_retail: 670,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '43ZPYD3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43ZPYD3S'
    },
    {
      status: 210,
      sku: 'PM0030583',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6267084',
      rtl_batch_array: '6117698, 6267084',
      name_search: 'SANOSTFR',
      name: "Salvia nemorosa 'Ostfriesland'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 8126,
      chnn_stock_retail: 9126,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L8CP8CVY;7HJDG7W5;WN5NK6H4;6S8768BL;738ADD1T;2CYAKL22;H44PR129;9BP6H4BZ;TGNL65XV;ZPXLDZW3;HZP275Y2;PEWXGZHY;7JKJ6VC7;9T4E9GVG;WPBS61E8;BD635VT7;TWBHZ4SY;RHYB8ZAJ',
      statusContent: 'dot',
      hash: '5JPZNX6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JPZNX6K'
    },
    {
      status: 210,
      sku: 'PM0041051',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 3,
      btch_active_retail: '6354236',
      rtl_batch_array: '6076595, 5932394, 6354236',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '080100C24',
      rng_range_identifier: 'H080100C2.5',
      rng_range_description: 'H80 cm 100 cm C2.5',
      sppl_stock_available: 4212,
      chnn_stock_retail: 6712,
      sppl_order_minimum: 3,
      sppl_prcp: 1.637,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '669PNJYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '669PNJYR'
    },
    {
      status: 910,
      sku: 'PM0041139',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYDMBERR',
      name: "Symphoricarpos doorenbosii 'Magic Berry'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore:
        'BJTZXBBP;VYBCZ542;PEK5E6TW;H19Y2CY9;XN9BJ7KT;RVN3833W;J1Y7CTH4;HDW16A1V;NJ8DR65E;W519LKGS',
      statusContent: 'dot',
      hash: 'VS5ZERRD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'VS5ZERRD'
    },
    {
      status: 210,
      sku: 'PM0076488',
      core_name: 'Plant',
      sppl_ean: '8720353012865',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5446503',
      rtl_batch_array: '5446503',
      name_search: 'HYMTOVEL',
      name: "Hydrangea macrophylla 'Tovelit'",
      sppl_size_code: '015020C2',
      rng_range_identifier: 'H015020C2',
      rng_range_description: 'H15 cm 20 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1A8YBWLN',
      statusContent: 'dot',
      hash: '96DJ6RGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96DJ6RGA'
    },
    {
      status: 210,
      sku: 'PM0076489',
      core_name: 'Plant',
      sppl_ean: '8720349468300',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5446532',
      rtl_batch_array: '5446532',
      name_search: 'HYMHAMBU',
      name: "Hydrangea macrophylla 'Hamburg'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TNRBTTW4;A9R8C7DY;H7WW2ZYT;S3WE97VB;9EVCP3WN;HW5VLGRT;J1GDYCTN',
      statusContent: 'dot',
      hash: 'VNV1EEVW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNV1EEVW'
    },
    {
      status: 810,
      sku: 'PM0076490',
      core_name: 'Plant',
      sppl_ean: '8720349484232',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '5446533',
      rtl_batch_array: '5446533',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 64,
      chnn_stock_retail: 64,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'GHZD1P12',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHZD1P12'
    },
    {
      status: 210,
      sku: 'PM0076493',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382286',
      rtl_batch_array: '6382286, 6291713',
      name_search: 'MISADAGI',
      name: "Miscanthus sinensis 'Adagio'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6750,
      chnn_stock_retail: 6928,
      sppl_order_minimum: 3,
      sppl_prcp: 0.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VRL5JCPB',
      statusContent: 'dot',
      hash: 'N4RYXPDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N4RYXPDV'
    },
    {
      status: 210,
      sku: 'PM0076494',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382289',
      rtl_batch_array: '6382289, 6291714',
      name_search: 'MISGRAZI',
      name: "Miscanthus sinensis 'Graziella'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5350,
      chnn_stock_retail: 5709,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ARAYGJZC',
      statusContent: 'dot',
      hash: 'LKX2WVY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKX2WVY4'
    },
    {
      status: 210,
      sku: 'PM0076495',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291716',
      rtl_batch_array: '6291716',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1811,
      chnn_stock_retail: 1811,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      statusContent: 'dot',
      hash: '8NGRDA1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NGRDA1N'
    },
    {
      status: 210,
      sku: 'PM0076496',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291729',
      rtl_batch_array: '6291729',
      name_search: 'PEOFTAIL',
      name: "Pennisetum orientale 'Fairy Tails'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 785,
      chnn_stock_retail: 785,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4AJL1YLS;4WDJXT26',
      statusContent: 'dot',
      hash: '5CYJNAPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CYJNAPP'
    },
    {
      status: 210,
      sku: 'PM0076502',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292725',
      rtl_batch_array: '6292725',
      name_search: 'ACPSHAIN',
      name: "Acer palmatum 'Shaina'",
      sppl_size_code: '050060C6',
      rng_range_identifier: 'H050060C6',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 11.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6XWC988;CYYE8BY8;NL9V8TLX;E1NJ6G5D',
      statusContent: 'dot',
      hash: 'P2EXKDCN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2EXKDCN'
    },
    {
      status: 210,
      sku: 'PM0076506',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292729',
      rtl_batch_array: '6292729',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: '060080C15',
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 30.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: '6PZ4P5KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PZ4P5KY'
    },
    {
      status: 910,
      sku: 'PM0076507',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPSBROO',
      name: "Acer palmatum 'Skeeter's Broom'",
      rng_range_identifier: 'H100150C15',
      rng_range_description: 'H100 cm 150 cm C15',
      imageCore: 'A2R5LA3N;4RY9PYRJ;V7D7ARAS;LYEVVK18',
      statusContent: 'dot',
      hash: 'NVVD98ZB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NVVD98ZB'
    },
    {
      status: 810,
      sku: 'PM0076508',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292732',
      rtl_batch_array: '6292732',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 11.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'NKTSJ296',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NKTSJ296'
    },
    {
      status: 210,
      sku: 'PM0076509',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292734',
      rtl_batch_array: '6292734',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '060070C10',
      rng_range_identifier: 'H060070C10',
      rng_range_description: 'H60 cm 70 cm C10',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 9.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'HAAE79CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAAE79CT'
    },
    {
      status: 210,
      sku: 'PM0076510',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293872',
      rtl_batch_array: '6293872',
      name_search: 'SPMDPBKA',
      name: 'Spiraea media Double Play Blue Kazoo',
      sppl_size_code: '030035C10',
      rng_range_identifier: 'H030035C10',
      rng_range_description: 'H30 cm 35 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 11.243,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JZ8VKW4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZ8VKW4Z'
    },
    {
      status: 210,
      sku: 'PM0076511',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293877',
      rtl_batch_array: '6293877',
      name_search: 'HYPPINKA',
      name: 'Hydrangea paniculata Pinkachu',
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_prcp: 12.958,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GGEJ5VG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGEJ5VG3'
    },
    {
      status: 910,
      sku: 'PM0076512',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6314394',
      rtl_batch_array: '6314394',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '070080C10',
      rng_range_identifier: 'H070080C10',
      rng_range_description: 'H70 cm 80 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '122BJZ7X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '122BJZ7X'
    },
    {
      status: 210,
      sku: 'PM0076513',
      core_name: 'Plant',
      btch_manufacturer: 545,
      rtl_batch_array_total: 1,
      btch_active_retail: '6293902',
      rtl_batch_array: '6293902',
      name_search: 'HYPLQFIR',
      name: 'Hydrangea paniculata Little Quick Fire',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 6.925,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4SDK8NDL;4386ZPWE;SR1WBTD5;PXTX12BG;AE6LZ6HN',
      statusContent: 'dot',
      hash: 'L3BWB2SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3BWB2SK'
    },
    {
      status: 210,
      sku: 'PM0076514',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294743',
      rtl_batch_array: '6294743',
      name_search: 'BUDTWHIT',
      name: 'Buddleja d. Towers White',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 876,
      chnn_stock_retail: 876,
      sppl_prcp: 3.843,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PXBC9KGV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXBC9KGV'
    },
    {
      status: 810,
      sku: 'PM0076515',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294745',
      rtl_batch_array: '6294745',
      name_search: 'CALAPHRO',
      name: "Calycanthus 'Aphrodite'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 5.231,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5R6TBK45;6JLRWY2Z;XXD8SS7T;W3VGR3WX;8ZE7E6XJ;1KDA65NR;YN66VLT4;DWC9WHXP;WGLVBA2K;DA5HZCNY;AWYNJCH1;7TAKTTV3',
      statusContent: 'dot',
      hash: 'TXX4ENAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXX4ENAD'
    },
    {
      status: 810,
      sku: 'PM0076516',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294748',
      rtl_batch_array: '6294748',
      name_search: 'CLARMAND',
      name: 'Clematis armandii',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 58,
      chnn_stock_retail: 58,
      sppl_order_minimum: 3,
      sppl_prcp: 8.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A2HRDWJB;81D7Z7NT;SHH1R6CH;XVSDW131;7ZDCPZJC;XTLYZ1G9;4HHPASY8;LCRDTP5B;C4BLBTV3;T47TA769;7237NA5V;NRZ39N9Y;TZAK7BTG;Y1JD94C5',
      statusContent: 'dot',
      hash: '1BP716D9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1BP716D9'
    },
    {
      status: 210,
      sku: 'PM0076517',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294750',
      rtl_batch_array: '6294750',
      name_search: 'DIRKBLAC',
      name: 'Diervilla rivularis Kodiak Black',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6VZ6Y863',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6VZ6Y863'
    },
    {
      status: 910,
      sku: 'PM0076505',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      rng_range_identifier: 'H060080C15',
      rng_range_description: 'H60 cm 80 cm C15',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'WD8R7GBY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WD8R7GBY'
    },
    {
      status: 810,
      sku: 'PM0076519',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294752',
      rtl_batch_array: '6294752',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 45.217,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '98RWG119',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98RWG119'
    },
    {
      status: 910,
      sku: 'PM0076521',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294757',
      rtl_batch_array: '6294757',
      name_search: 'HISARDEN',
      name: "Hibiscus syriacus 'Ardens'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3RSRH3S;HCN4JG8A;V82GSVV5;6G3GT5K7;TBBTVPBX',
      statusContent: 'dot',
      hash: '55JLAWH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55JLAWH5'
    },
    {
      status: 910,
      sku: 'PM0076522',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294758',
      rtl_batch_array: '6294758',
      name_search: 'HISDBRAB',
      name: "Hibiscus syriacus 'Duc de Brabant'",
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 16,
      chnn_stock_retail: 16,
      sppl_prcp: 20.022,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '52PBSE7C;VD2L7JL9;6Z5THXR2;294ZR3EX;RPBNELT7',
      statusContent: 'dot',
      hash: '69WPR8NN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '69WPR8NN'
    },
    {
      status: 810,
      sku: 'PM0076523',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294759',
      rtl_batch_array: '6294759',
      name_search: 'HISLSTAN',
      name: "Hibiscus syriacus 'Lady Stanley'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 17.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7E4DLNYS',
      statusContent: 'dot',
      hash: 'BTDD5DNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTDD5DNN'
    },
    {
      status: 910,
      sku: 'PM0076524',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294760',
      rtl_batch_array: '6294760',
      name_search: 'HISMARIN',
      name: "Hibiscus syriacus 'Marina'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 17.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '69LD5BA3;4KY15DD1;J439RCSN;4WL845Y7;KCL3SGY8;8ARYR5SP;2GE451DS;945JC67P',
      statusContent: 'dot',
      hash: 'B6WLTDCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6WLTDCD'
    },
    {
      status: 910,
      sku: 'PM0076525',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294761',
      rtl_batch_array: '6294761',
      name_search: 'HISMONST',
      name: "Hibiscus syriacus 'Monstrosus'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 19,
      chnn_stock_retail: 19,
      sppl_prcp: 17.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8BTNJLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8BTNJLR'
    },
    {
      status: 210,
      sku: 'PM0076526',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294762',
      rtl_batch_array: '6294762',
      name_search: 'HYALRICK',
      name: 'Hydrangea arborescens Lime Rickey',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 254,
      chnn_stock_retail: 254,
      sppl_prcp: 4.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XLA57TNT;5DNAHYA1;17KSZJCD;99DRPVCL;15TH76JW;LTAJZ6LG',
      statusContent: 'dot',
      hash: 'WL4YT24L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WL4YT24L'
    },
    {
      status: 210,
      sku: 'PM0076527',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294763',
      rtl_batch_array: '6294763',
      name_search: 'HYMMSANG',
      name: "Hydrangea macrophylla 'Merveille Sanguinea'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JHKGRAR8;2Y5NDCGL;R28CB3Y9;P1K57H9C;VG9WBNL5;63WA4367;EJ839NPE;W6KK371B;PGN62YXH;5HB93812;PVTD8WH6;74A4NVGZ;HE5NZPWJ',
      statusContent: 'dot',
      hash: 'W9L232V9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9L232V9'
    },
    {
      status: 910,
      sku: 'PM0076528',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294764',
      rtl_batch_array: '6294764',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 8.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: 'WEX3TXG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WEX3TXG4'
    },
    {
      status: 910,
      sku: 'PM0076529',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294765',
      rtl_batch_array: '6294765',
      name_search: 'HYQRSLIP',
      name: "Hydrangea quercifolia 'Ruby Slippers'",
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 14.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '834DBEZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '834DBEZ9'
    },
    {
      status: 210,
      sku: 'PM0076530',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294766',
      rtl_batch_array: '6294766',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_prcp: 14.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: '7VDB28EC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7VDB28EC'
    },
    {
      status: 910,
      sku: 'PM0076531',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367985',
      rtl_batch_array: '6367985',
      name_search: 'ILCDGREE',
      name: 'Ilex crenata Dark Green',
      sppl_size_code: '040C15BOL01',
      rng_range_identifier: 'BALL\u00d8040H040040C15',
      rng_range_description: 'Ball \u00d8 40 cm H40 cm 40 cm C15',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 74.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHTGH42P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHTGH42P'
    },
    {
      status: 210,
      sku: 'PM0076501',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292724',
      rtl_batch_array: '6292724',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '050060C6',
      rng_range_identifier: 'H050060C6',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 12.479,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'HDE484GG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HDE484GG'
    },
    {
      status: 210,
      sku: 'PM0076541',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294785',
      rtl_batch_array: '6294785',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_prcp: 3.299,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'YGDSX67B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGDSX67B'
    },
    {
      status: 810,
      sku: 'PM0076534',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294772',
      rtl_batch_array: '6294772',
      name_search: 'MISCONE',
      name: "Miscanthus sinensis 'Cute One'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 3.486,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CA9ES9VN;RT1TRSKG',
      statusContent: 'dot',
      hash: 'VXWNAKW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXWNAKW2'
    },
    {
      status: 210,
      sku: 'PM0076535',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367988',
      rtl_batch_array: '6367988',
      name_search: 'PHOTWINE',
      name: 'Physocarpus opulifolius Tiny Wine',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 253,
      chnn_stock_retail: 253,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CL1TTKDB;8RTXK5C9;6EH61CE3',
      statusContent: 'dot',
      hash: '1PNT4EKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PNT4EKX'
    },
    {
      status: 810,
      sku: 'PM0076536',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294775',
      rtl_batch_array: '6294775',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 23,
      chnn_stock_retail: 23,
      sppl_prcp: 15.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '96SLSSR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96SLSSR4'
    },
    {
      status: 210,
      sku: 'PM0076340',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6321882',
      rtl_batch_array: '6289989, 6321882',
      name_search: 'ANSRAVEN',
      name: "Anthriscus sylvestris 'Ravenswing'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 353,
      chnn_stock_retail: 1926,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZX6V55EW',
      statusContent: 'dot',
      hash: 'WP2PHYB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WP2PHYB4'
    },
    {
      status: 210,
      sku: 'PM0084171',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363180',
      rtl_batch_array: '6363180',
      name_search: 'COSROSCO',
      name: "Cornus sericea 'Rosco'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2292,
      chnn_stock_retail: 2292,
      sppl_order_minimum: 3,
      sppl_prcp: 0.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXNCW4Z3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXNCW4Z3'
    },
    {
      status: 210,
      sku: 'PM0076539',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294783',
      rtl_batch_array: '6294783',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 689,
      chnn_stock_retail: 689,
      sppl_prcp: 2.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'TVBXN865',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TVBXN865'
    },
    {
      status: 210,
      sku: 'PM0076540',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294784',
      rtl_batch_array: '6294784',
      name_search: 'ELSTAUNT',
      name: 'Elsholtzia stauntonii',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 363,
      chnn_stock_retail: 363,
      sppl_prcp: 3.992,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5236YK94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5236YK94'
    },
    {
      status: 210,
      sku: 'PM0076542',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338567',
      rtl_batch_array: '6294790, 6338567',
      name_search: 'VIACASTU',
      name: 'Vitex agnus-castus',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 267,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EB5TRR58;4ALHKCBS;4ETGD7LJ;7W3WV5RY;N1TS6S4C;R5B3N5Z2;XS2391HV;4BR8RCRS;9EDEHRC7;TXWAL6TH;163RAYKE;E7JN3Y83;5JSHK3ZN;SNT63J73;SZBWE8XA;ABAZCPX2;5BWAXJ9Y',
      statusContent: 'dot',
      hash: '6252VVAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6252VVAV'
    },
    {
      status: 910,
      sku: 'PM0076543',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VITEPRIC',
      name: "Viburnum tinus 'Eve Price'",
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      imageCore:
        '8Y42DTHK;DKNJCE62;A5X2AT25;BXTWDPVN;SWHH8WSC;XGRY9GAC;XCV95A32;5EYVNRB1;6AXXYJJC;7PER8TYD;6L83ALX5;YZ98APTW;59BYTBWT;GHD3DKPP;4JJ4CCTJ;R47X8ZDJ;V35S3BJX;XEVEVB9Z',
      statusContent: 'dot',
      hash: '557ZSVR7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '557ZSVR7'
    },
    {
      status: 910,
      sku: 'PM0076337',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ARMLACE',
      name: "Aruncus 'Misty Lace'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '812A55P7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '812A55P7'
    },
    {
      status: 810,
      sku: 'PM0076545',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294797',
      rtl_batch_array: '6294797',
      name_search: 'CHTABLOS',
      name: 'Choisya ternata Apple Blossom',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 46,
      chnn_stock_retail: 46,
      sppl_prcp: 4.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1WJZNG49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WJZNG49'
    },
    {
      status: 910,
      sku: 'PM0076548',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367970',
      rtl_batch_array: '6367970',
      name_search: 'PAPPSPIR',
      name: 'Parrotia persica Persian Spire',
      sppl_size_code: '125150C20',
      rng_range_identifier: 'H125150C20',
      rng_range_description: 'H125 cm 150 cm C20',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 27.66,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC91A6XY;XR1JSY4C;WRLSCDV9;4CJJKXYX;WZGPTPVX',
      statusContent: 'dot',
      hash: 'B55L9VA5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B55L9VA5'
    },
    {
      status: 210,
      sku: 'PM0076549',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294803',
      rtl_batch_array: '6294803',
      name_search: 'VITLISAR',
      name: 'Viburnum tinus Lisarose',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 4.586,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '25EASNRJ;74GLV5G4;H6Y513AL;822CEH25;E69DGAB3;DJRWZC5T;48P8KYBB;X7H7CGNR;8HB1KT2L;PSCW84WA;963VP586;2DGD5G7R',
      statusContent: 'dot',
      hash: 'LKPEGLXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LKPEGLXV'
    },
    {
      status: 210,
      sku: 'PM0076556',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294818',
      rtl_batch_array: '6294818',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 393,
      chnn_stock_retail: 393,
      sppl_prcp: 2.655,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'Y49GJWPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y49GJWPH'
    },
    {
      status: 210,
      sku: 'PM0076557',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294821',
      rtl_batch_array: '6294821',
      name_search: 'CEATBLUE',
      name: "Ceanothus arboreus 'Trewithen Blue'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_prcp: 2.941,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P4243H37;VL5D96VE;PKZG7TL1;1YW1XZ9X;CPT298V8;6PYCNXA4;K5TSA8GX;J7L7EBAR;N7T5XSEW',
      statusContent: 'dot',
      hash: 'DBGBZLA4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBGBZLA4'
    },
    {
      status: 210,
      sku: 'PM0076558',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294823',
      rtl_batch_array: '6294823',
      name_search: 'CHAGOLD',
      name: "Choisya 'Aztec Gold'",
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 886,
      chnn_stock_retail: 886,
      sppl_prcp: 4.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRBZPJR;CXYAP33L;XPZ2STEH;XPGTVKWE;PPEXNEP2;VWDVY9VZ;8SJ52K9W;TSYKT4G3;G7BP1HLT;AZNGTT5W;VJA6VVVS;94869PBK;SBBXWYRB;WVLC9ZH3;J1BTEDCK;S41XBA3T;VC113LYZ;9XE14T7S',
      statusContent: 'dot',
      hash: 'AL2A1VNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AL2A1VNS'
    },
    {
      status: 210,
      sku: 'PM0076559',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 3,
      btch_active_retail: '6294824',
      rtl_batch_array: '6294824, 6300832, 6349010',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 1026,
      chnn_stock_retail: 2176,
      sppl_prcp: 3.249,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: 'AYC44ART',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYC44ART'
    },
    {
      status: 210,
      sku: 'PM0076560',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294825',
      rtl_batch_array: '6294825',
      name_search: 'CHTLICH',
      name: "Choisya ternata 'Lich'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 2399,
      chnn_stock_retail: 2399,
      sppl_prcp: 3.447,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CVZ3GKT;KKH3BXHZ',
      statusContent: 'dot',
      hash: 'NZLHHKXN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZLHHKXN'
    },
    {
      status: 210,
      sku: 'PM0076561',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294826',
      rtl_batch_array: '6294826',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: '020030C3',
      rng_range_identifier: 'H020030C3',
      rng_range_description: 'H20 cm 30 cm C3',
      sppl_stock_available: 1399,
      chnn_stock_retail: 1399,
      sppl_prcp: 4.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'K3ECAJW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3ECAJW7'
    },
    {
      status: 210,
      sku: 'PM0076562',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294827',
      rtl_batch_array: '6294827',
      name_search: 'CLAHUMMI',
      name: "Clethra alnifolia 'Hummingbird'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_prcp: 3.497,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LELV52NG;HA9RX2KC;HEHB17GC;DJK235R9',
      statusContent: 'dot',
      hash: '9T1KT2XY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9T1KT2XY'
    },
    {
      status: 210,
      sku: 'PM0076563',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6337784',
      rtl_batch_array: '6294830, 6337784',
      name_search: 'FOGOLDRA',
      name: "Forsythia 'Goldrausch'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 731,
      chnn_stock_retail: 1042,
      sppl_prcp: 2.258,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X968H35B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X968H35B'
    },
    {
      status: 210,
      sku: 'PM0076564',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294831',
      rtl_batch_array: '6294831',
      name_search: 'FOOTETRA',
      name: "Forsythia ovata 'Tetragold'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NGAHB4BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGAHB4BT'
    },
    {
      status: 910,
      sku: 'PM0076565',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HIEMERUS',
      name: 'Hippocrepis emerus',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      imageCore: '2VB157BY;851KC84Z;HJHGVG3T;922DYTKL',
      statusContent: 'dot',
      hash: '2BBJP5NL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2BBJP5NL'
    },
    {
      status: 210,
      sku: 'PM0076492',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 3,
      btch_active_retail: '6382275',
      rtl_batch_array: '6369733, 6382275, 6291689',
      name_search: 'DECPALAV',
      name: "Deschampsia cespitosa 'Palava'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 8000,
      chnn_stock_retail: 9012,
      sppl_order_minimum: 3,
      sppl_prcp: 0.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1J63KG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1J63KG9'
    },
    {
      status: 210,
      sku: 'PM0076566',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294836',
      rtl_batch_array: '6294836',
      name_search: 'HYMMPERF',
      name: "Hydrangea macrophylla 'Mariesii Perfecta'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2EW7A1S5;NSEE1YK1;RB1R779C;KE58NNNG',
      statusContent: 'dot',
      hash: 'VBXRECGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBXRECGN'
    },
    {
      status: 810,
      sku: 'PM0076567',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367965',
      rtl_batch_array: '6367965',
      name_search: 'HYMRSTEI',
      name: "Hydrangea macrophylla 'Renate Steiniger'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 2.347,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HYNJ1PA;25ADCYW2;PKL78Z5G;7YGK7B2C;1WNG69PG;CG9D67Z7',
      statusContent: 'dot',
      hash: '4ZETH5WN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZETH5WN'
    },
    {
      status: 210,
      sku: 'PM0076546',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294799',
      rtl_batch_array: '6294799',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1129,
      chnn_stock_retail: 1129,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'BZS197PB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZS197PB'
    },
    {
      status: 210,
      sku: 'PM0076497',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291739',
      rtl_batch_array: '6291739',
      name_search: 'SAPMARY',
      name: "Sanguisorba 'Proud Mary'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 425,
      chnn_stock_retail: 425,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RTDN7ZYB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTDN7ZYB'
    },
    {
      status: 210,
      sku: 'PM0076499',
      core_name: 'Plant',
      btch_manufacturer: 891,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292722',
      rtl_batch_array: '6292722',
      name_search: 'ACPGARNE',
      name: "Acer palmatum 'Garnet'",
      sppl_size_code: '040050C4',
      rng_range_identifier: 'H040050C4',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 9.715,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2T95T5K1;4AGZ6CCB;KWY79PJV;LC7A68AW;JW38YPPB;41DAJA21;55CXXVBT;TC6JATK9;P36VKJLK;X24D7KP5;83SD9453;SJEPXCBB;7KHEZ734;4JK2CH47;T1Y981ZT;W7YE7RBT;HT7LWP9V;ADCBS276;C1XPV9WB;TX818XDV;ZL6XPCZA;H3H63YVJ;EN1NGE9X;Z1HN9YG8;RB3Y9ZA6;CE74PT4L',
      statusContent: 'dot',
      hash: 'D462RDWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D462RDWT'
    },
    {
      status: 910,
      sku: 'PM0076520',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294753',
      rtl_batch_array: '6294753',
      name_search: 'EUACOMPA',
      name: "Euonymus alatus 'Compactus'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 13,
      chnn_stock_retail: 13,
      sppl_prcp: 3.993,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KN1CGP4;Y44537EK;N48CWYLW;Z1R296YZ',
      statusContent: 'dot',
      hash: 'BAWB4REA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAWB4REA'
    },
    {
      status: 210,
      sku: 'PM0084172',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363183',
      rtl_batch_array: '6369248, 6363183',
      name_search: 'LELPLUM',
      name: "Lewisia 'Little Plum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3648,
      chnn_stock_retail: 3888,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DTZ3NYPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DTZ3NYPR'
    },
    {
      status: 810,
      sku: 'PM0084173',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363208',
      rtl_batch_array: '6363208',
      name_search: 'SESHRBLU',
      name: "Sequoia s. 'High Ranch Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5A6H2PLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5A6H2PLS'
    },
    {
      status: 210,
      sku: 'PM0076580',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294854',
      rtl_batch_array: '6294854',
      name_search: 'PHODDOR',
      name: "Physocarpus opulif. Diable d'Or",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 397,
      chnn_stock_retail: 397,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'THLGZELD;R8LLG2A2;9BA8VN88;9W6NN9LX',
      statusContent: 'dot',
      hash: '5H6Y2BXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5H6Y2BXE'
    },
    {
      status: 210,
      sku: 'PM0076342',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349756',
      rtl_batch_array: '6289993, 6349756',
      name_search: 'AQVCSOLD',
      name: "Aquilegia viridiflora 'Chocolate Soldier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 822,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AV19GDD5;DVSDZ3AP;8BC5PYRA;216CNSZS;N38C9X25;XHWXVKWB',
      statusContent: 'dot',
      hash: 'A65NEE5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A65NEE5C'
    },
    {
      status: 210,
      sku: 'PM0076348',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348268',
      rtl_batch_array: '6349940, 6348268',
      name_search: 'ACSATROP',
      name: "Actaea simplex 'Atropurpurea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1180,
      chnn_stock_retail: 1492,
      sppl_order_minimum: 3,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DSLBZ6BZ;TP6251YN;2B2PXRJ1;2BY8SEAC;Z1GHGJN7;T7S17Y5Z;922TJ616;YPG23ELZ;J9JAT8C3',
      statusContent: 'dot',
      hash: 'ZNVJAHV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNVJAHV9'
    },
    {
      status: 210,
      sku: 'PM0076427',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 3,
      btch_active_retail: '6291310',
      rtl_batch_array: '6295180, 6291310, 6336330',
      name_search: 'VIMATROP',
      name: "Vinca minor 'Atropurpurea'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 5816,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9RN6Z5R2;K9WE8974;SNN5WREA;VB7TSAX8;D86DG5HP;7DR4L4KK;Y3TE5BB4;EKSPVVN3;52KHDXEY',
      statusContent: 'dot',
      hash: '2EWB6JNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2EWB6JNR'
    },
    {
      status: 210,
      sku: 'PM0076219',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284967',
      rtl_batch_array: '6284967',
      name_search: 'PISFASTI',
      name: "Pinus strobus 'Fastigiata'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_prcp: 6.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8VNDWPWT',
      statusContent: 'dot',
      hash: 'CBH74KNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBH74KNP'
    },
    {
      status: 210,
      sku: 'PM0076428',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 4,
      btch_active_retail: '6291311',
      rtl_batch_array: '6295185, 6291311, 6336331, 6310449',
      name_search: 'VIMGJEKY',
      name: "Vinca minor 'Gertrude Jekyll'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 15429,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y335HZAG;H5PLAKG9;6LH12BCA;XN619H8A;4CDRRTEZ;7N7KRB5C;Z5B295Z3;4HAWSZY7',
      statusContent: 'dot',
      hash: 'YKVD43PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YKVD43PY'
    },
    {
      status: 810,
      sku: 'PM0076463',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291344',
      rtl_batch_array: '6291344',
      name_search: 'ACHPAHL',
      name: "Achillea 'Hannelore Pahl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z857B83L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z857B83L'
    },
    {
      status: 210,
      sku: 'PM0041261',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 4,
      btch_active_retail: '6281433',
      rtl_batch_array: '6281433, 6284485, 5449288, 5408381',
      name_search: 'FASYLVAT',
      name: 'Fagus sylvatica',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1645,
      chnn_stock_retail: 4969,
      sppl_prcp: 1.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A1DTAJGE;73X6JNYY;N5TZ21DH;L7REG6G3;EC5VY28L;G4TT87J1;5J3W12PE;B5JSH5HT;62EA9R77;Y6D9DA3E;9XCA9ARD;PAB48AAX;B3V3H59X;2W55C2HA;BVA41WZ3;R9V68TJK;KAYKD385;KPTXD3P4;TA4PV58Z;7DZPL246;HBTSE6JT;LREPPTLW;PXA5VH17;WXXKXNVZ;XG8CYEYP;GVPG4TYS;WDBYD1JA',
      statusContent: 'dot',
      hash: '2TKCX74X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TKCX74X'
    },
    {
      status: 210,
      sku: 'PM0076569',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338123',
      rtl_batch_array: '6294839, 6338123',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1534,
      chnn_stock_retail: 1939,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'DVS4WSEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVS4WSEK'
    },
    {
      status: 210,
      sku: 'PM0076568',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294838',
      rtl_batch_array: '6294838',
      name_search: 'HYPDLDOT',
      name: "Hydrangea paniculata Dart's Little Dot",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1279,
      chnn_stock_retail: 1279,
      sppl_prcp: 4.437,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E7K18R25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7K18R25'
    },
    {
      status: 210,
      sku: 'PM0076570',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338125',
      rtl_batch_array: '6294840, 6338125',
      name_search: 'HYPKYUSH',
      name: "Hydrangea paniculata 'Kyushu'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 679,
      chnn_stock_retail: 994,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R8L2W9TD;W9PSRZHX;5NPR1B4B;P9G2N4PD;8A7J7JS6;4ZPJJ1C2;915WS921;Y6YCL1XZ',
      statusContent: 'dot',
      hash: 'Y49WPGCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y49WPGCX'
    },
    {
      status: 210,
      sku: 'PM0076571',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 3,
      btch_active_retail: '6356160',
      rtl_batch_array: '6294842, 6356160, 6300749',
      name_search: 'HYPLLIME',
      name: 'Hydrangea paniculata Little Lime',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 3853,
      sppl_prcp: 3.282,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RW9T89EC;PB4PTXYV;TJCZHWK6;Z79PLELT;L5JBXY45;LK6SGCLH;YLSKL4T9;7ZE147WP;5TGXRA3Y;KNPZ9SGJ;CV2GK14Y',
      statusContent: 'dot',
      hash: 'RALKHGBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RALKHGBB'
    },
    {
      status: 210,
      sku: 'PM0076572',
      core_name: 'Plant',
      btch_manufacturer: 27,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338131',
      rtl_batch_array: '6294843, 6338131',
      name_search: 'HYPPDIAM',
      name: "Hydrangea paniculata 'Pink Diamond'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1544,
      chnn_stock_retail: 1769,
      sppl_prcp: 2.365,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WWBLZD3E;7CCBP3NH;68STTESS',
      statusContent: 'dot',
      hash: 'X5XW5GXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5XW5GXE'
    },
    {
      status: 210,
      sku: 'PM0076573',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294844',
      rtl_batch_array: '6294844',
      name_search: 'HYPPHANT',
      name: "Hydrangea paniculata 'Phantom'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 3.497,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6BRTHAB;KCK9HEB6;PVYG7SL5;N6WHEX3Y;CJTJJ3Z8;BTRS69YD',
      statusContent: 'dot',
      hash: 'DW36857R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DW36857R'
    },
    {
      status: 210,
      sku: 'PM0076574',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294845',
      rtl_batch_array: '6294845',
      name_search: 'HYPVFRAI',
      name: 'Hydrangea paniculata Vanille-Fraise',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1037,
      chnn_stock_retail: 1037,
      sppl_prcp: 4.092,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72RBTWJE;RBVCWHXY;6CC47H2G;97E5W95D',
      statusContent: 'dot',
      hash: 'LZ99TY6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZ99TY6T'
    },
    {
      status: 210,
      sku: 'PM0076575',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294846',
      rtl_batch_array: '6294846',
      name_search: 'HYPWRED',
      name: "Hydrangea paniculata 'Wim's Red'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_prcp: 4.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T1BJWW5E;9ER52CAH;VWH8JHD3',
      statusContent: 'dot',
      hash: 'CS4W96LP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CS4W96LP'
    },
    {
      status: 210,
      sku: 'PM0076576',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294847',
      rtl_batch_array: '6294847',
      name_search: 'INHETERA',
      name: 'Indigofera heterantha',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_prcp: 3.399,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9GZ6L55Y',
      statusContent: 'dot',
      hash: 'H8ZEPW79',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8ZEPW79'
    },
    {
      status: 810,
      sku: 'PM0076577',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294848',
      rtl_batch_array: '6294848',
      name_search: 'KOAMABIL',
      name: 'Kolkwitzia amabilis',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 3.498,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RWP2P6R8;L7X96EYB;6TJCTWZR;KRWCDWY7;9LX21RSZ;CBKKG75W;T2CSGXGT;YHK2V3TD;RP2DJLLG;X5AGXH4B;XX56276G;XDCC1BGV',
      statusContent: 'dot',
      hash: 'BSBBV8TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSBBV8TZ'
    },
    {
      status: 910,
      sku: 'PM0076579',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367966',
      rtl_batch_array: '6367966',
      name_search: 'PAPPSPIR',
      name: 'Parrotia persica Persian Spire',
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 2,
      chnn_stock_retail: 2,
      sppl_prcp: 32.842,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VC91A6XY;XR1JSY4C;WRLSCDV9;4CJJKXYX;WZGPTPVX',
      statusContent: 'dot',
      hash: '2G9YT1ZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2G9YT1ZE'
    },
    {
      status: 210,
      sku: 'PM0076581',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294856',
      rtl_batch_array: '6294856',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: '86DSSZT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86DSSZT7'
    },
    {
      status: 210,
      sku: 'PM0076582',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294857',
      rtl_batch_array: '6294857',
      name_search: 'SANGPURP',
      name: "Sambucus nigra 'Guincho Purple'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 319,
      chnn_stock_retail: 319,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZ1HWR6A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZ1HWR6A'
    },
    {
      status: 210,
      sku: 'PM0076583',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6294858',
      rtl_batch_array: '6294858',
      name_search: 'SANGTOWE',
      name: 'Sambucus nigra Golden Tower',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VTP8L3DB',
      statusContent: 'dot',
      hash: '9G98R97H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9G98R97H'
    },
    {
      status: 210,
      sku: 'PM0076584',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367969',
      rtl_batch_array: '6367969',
      name_search: 'PHOLJOKE',
      name: 'Physocarpus opulifolius Little Joker',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_prcp: 4.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E48CH8E;RPZCTBD6;PNCPDPEJ;5P2VJZ97;SX8GWW1H;15PJWBEG;JTELK2CN;1BSE16EY',
      statusContent: 'dot',
      hash: '95A99WGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '95A99WGA'
    },
    {
      status: 910,
      sku: 'PM0076585',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6168538',
      rtl_batch_array: '6168538',
      name_search: 'FAMURIEL',
      name: 'Fargesia murieliae',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 7,
      chnn_stock_retail: 7,
      sppl_prcp: 16.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WDRG6KVZ;PK3N994H',
      statusContent: 'dot',
      hash: 'EJSRZ34W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJSRZ34W'
    },
    {
      status: 210,
      sku: 'PM0076343',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348278',
      rtl_batch_array: '6289994, 6348278',
      name_search: 'AQVHEIDI',
      name: "Aquilegia vulgaris 'Heidi'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 127,
      chnn_stock_retail: 1921,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T6832APD',
      statusContent: 'dot',
      hash: 'ZVEP5L2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZVEP5L2S'
    },
    {
      status: 210,
      sku: 'PM0076344',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6289999',
      rtl_batch_array: '6289999',
      name_search: 'ASMSUPER',
      name: "Astrantia major 'Superstar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 620,
      chnn_stock_retail: 620,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z8NLKRW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8NLKRW8'
    },
    {
      status: 210,
      sku: 'PM0076345',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324726',
      rtl_batch_array: '6324726, 6290002, 6338930',
      name_search: 'ASMVENIC',
      name: "Astrantia major 'Venice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1138,
      chnn_stock_retail: 1580,
      sppl_order_minimum: 3,
      sppl_prcp: 1.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E2VDXTT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2VDXTT4'
    },
    {
      status: 210,
      sku: 'PM0076346',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290003',
      rtl_batch_array: '6290003, 6308662',
      name_search: 'BAPTRUFF',
      name: "Baptisia 'Pink Truffles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 339,
      sppl_order_minimum: 3,
      sppl_prcp: 2.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SAR1EG9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAR1EG9H'
    },
    {
      status: 210,
      sku: 'PM0076347',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290004',
      rtl_batch_array: '6290004',
      name_search: 'BAATPRAE',
      name: "Baptisia australis 'Twil. Pr\u00e4rieblues'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 138,
      chnn_stock_retail: 138,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DHBRVR6D;15GXJPG2;5V7TBSVG;ENLHPWHH;D7HALDZL;85ANS53P;BEA4EHRY',
      statusContent: 'dot',
      hash: 'C922BCJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C922BCJJ'
    },
    {
      status: 910,
      sku: 'PM0076349',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COOCHROL',
      name: 'Corydalis ochroleuca',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BW69KDE9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BW69KDE9'
    },
    {
      status: 910,
      sku: 'PM0040197',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188207',
      rtl_batch_array: '6188207',
      name_search: 'CLMBLUE',
      name: "Clematis 'Multi Blue'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_order_minimum: 6,
      sppl_prcp: 2.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RBY5H17A;CRBXC9RK;6CKDCXR5;57C4GKBR;EKWNXX7Z;5YY8TAG3;K89G93S5;KGLDAZ1G;KE5CBZWX;1RLYGTXA;L9BTJ3R4;DBY1HP6R;XKKWN4DX;8CPKWVN2',
      statusContent: 'dot',
      hash: '93638HJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93638HJT'
    },
    {
      status: 210,
      sku: 'PM0083812',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356687',
      rtl_batch_array: '6356687',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.093,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'Y9KKZ1GR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9KKZ1GR'
    },
    {
      status: 210,
      sku: 'PM0083813',
      core_name: 'Plant',
      btch_manufacturer: 199,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356689',
      rtl_batch_array: '6356689',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      sppl_size_code: '125150C2',
      rng_range_identifier: 'H125150C2',
      rng_range_description: 'H125 cm 150 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.898,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: 'JWDNBC7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWDNBC7Z'
    },
    {
      status: 210,
      sku: 'PM0084174',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363216',
      rtl_batch_array: '6363216',
      name_search: 'VEPEDUNC',
      name: 'Veronica peduncularis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4074,
      chnn_stock_retail: 4074,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '929R74PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '929R74PE'
    },
    {
      status: 210,
      sku: 'PM0084175',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363220',
      rtl_batch_array: '6363220',
      name_search: 'PRVBDYEL',
      name: "Primula veris 'Bright Deep Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 4440,
      chnn_stock_retail: 4440,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '64JHECYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '64JHECYZ'
    },
    {
      status: 210,
      sku: 'PM0084176',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363221',
      rtl_batch_array: '6363221',
      name_search: 'PRVLORAN',
      name: "Primula veris 'Lime with Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2205,
      chnn_stock_retail: 2205,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NPGDVSGJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NPGDVSGJ'
    },
    {
      status: 210,
      sku: 'PM0084177',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363224',
      rtl_batch_array: '6363224',
      name_search: 'PYCBCWIL',
      name: "Pyrus communis 'Bon Chr\u00e9tien Williams'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 9.18,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HTAP3Z4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTAP3Z4L'
    },
    {
      status: 210,
      sku: 'PM0084178',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363225',
      rtl_batch_array: '6363225',
      name_search: 'PYCBCWIL',
      name: "Pyrus communis 'Bon Chr\u00e9tien Williams'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 15.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K58KX7AY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K58KX7AY'
    },
    {
      status: 210,
      sku: 'PM0084179',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363240',
      rtl_batch_array: '6363240',
      name_search: 'ALCDSCAR',
      name: "Alcea rosea 'Double Scarlet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1028,
      chnn_stock_retail: 1028,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PCZES1LS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCZES1LS'
    },
    {
      status: 210,
      sku: 'PM0084180',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363241',
      rtl_batch_array: '6363241',
      name_search: 'ALRDWHIT',
      name: "Alcea rosea 'Double White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1012,
      chnn_stock_retail: 1012,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TP9E3WX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TP9E3WX2'
    },
    {
      status: 210,
      sku: 'PM0084181',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363242',
      rtl_batch_array: '6363242',
      name_search: 'ALRDYELL',
      name: "Alcea rosea 'Double Yellow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1052,
      chnn_stock_retail: 1052,
      sppl_order_minimum: 3,
      sppl_prcp: 0.645,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTVAJCH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTVAJCH9'
    },
    {
      status: 210,
      sku: 'PM0084182',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363244',
      rtl_batch_array: '6363244',
      name_search: 'GAGAASUN',
      name: "Gaillardia gr. 'A. African Sunset'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3060,
      chnn_stock_retail: 3060,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CP2CS42Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CP2CS42Z'
    },
    {
      status: 210,
      sku: 'PM0076586',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295334',
      rtl_batch_array: '6311914, 6295334',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '125150C5',
      rng_range_identifier: 'H125150C5',
      rng_range_description: 'H125 cm 150 cm C5',
      sppl_stock_available: 1505,
      chnn_stock_retail: 2005,
      sppl_prcp: 6.903,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: '1XJWA236',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XJWA236'
    },
    {
      status: 210,
      sku: 'PM0076995',
      core_name: 'Plant',
      sppl_ean: '8720626322172',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5378374',
      rtl_batch_array: '5378374',
      name_search: 'RHSNEEZY',
      name: "Rhododendron (Y) 'Sneezy'",
      sppl_size_code: 'GMC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 6.069,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZPBTK1ZD;VLNNPHZE;56XDL7PW;TGKJPE3H;A8ZX1HNR;AZ44A1W8;T5EXKX7L;KA3EWBP8;1YRJG1AX;73NBGJAR;C3Y7T9D3;N27YGY3N',
      imageBatch: 'Y5PL735C',
      statusContent: 'dot',
      hash: 'ZN8A4PNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN8A4PNP'
    },
    {
      status: 910,
      sku: 'PM0076996',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'B6544SCN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'B6544SCN'
    },
    {
      status: 210,
      sku: 'PM0076972',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266521',
      rtl_batch_array: '6266521',
      name_search: 'ACDATLAS',
      name: "Actinidia deliciosa 'Atlas'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXNT4XDA;6EJJ4B52',
      statusContent: 'dot',
      hash: '9HX4JZRT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HX4JZRT'
    },
    {
      status: 210,
      sku: 'PM0076973',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266522',
      rtl_batch_array: '6266522',
      name_search: 'ACDHAYWA',
      name: "Actinidia deliciosa 'Hayward'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TLC6A61E',
      statusContent: 'dot',
      hash: 'J531X6Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J531X6Z1'
    },
    {
      status: 210,
      sku: 'PM0076221',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286496',
      rtl_batch_array: '6286496',
      name_search: 'LIDDESDE',
      name: "Ligularia dentata 'Desdemona'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2290,
      chnn_stock_retail: 2290,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6DGXCVWJ',
      statusContent: 'dot',
      hash: 'SS9XW2TP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS9XW2TP'
    },
    {
      status: 210,
      sku: 'PM0076222',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286498',
      rtl_batch_array: '6286498',
      name_search: 'PIGAGLOB',
      name: "Picea glauca 'Alberta Globe'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 428,
      chnn_stock_retail: 428,
      sppl_prcp: 5.536,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AV3TNY1Y;SYL9ZJWZ;WBYGXE4E;2D7SNGX3;8BXW263X;52R49AND;8174AN7A',
      statusContent: 'dot',
      hash: 'W6H2H76V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6H2H76V'
    },
    {
      status: 210,
      sku: 'PM0076223',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286499',
      rtl_batch_array: '6286499',
      name_search: 'PIGSBLUE',
      name: "Picea glauca 'Sander's Blue'",
      sppl_size_code: '050060C34',
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      sppl_stock_available: 169,
      chnn_stock_retail: 169,
      sppl_prcp: 6.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GBPGS3D3;85W4WWW5;GX18XY2S;AW3TWDYS;T23Y4GWN;B9JZNLPW;L317KBAD;VD41PW51;DR7VLCLH;22KNB815',
      statusContent: 'dot',
      hash: 'CVWSCR5N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVWSCR5N'
    },
    {
      status: 810,
      sku: 'PM0076224',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286500',
      rtl_batch_array: '6286500',
      name_search: 'THOSMARA',
      name: "Thuja occidentalis 'Smaragd'",
      sppl_size_code: '080100C34',
      rng_range_identifier: 'H080100C3.5',
      rng_range_description: 'H80 cm 100 cm C3.5',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_prcp: 3.936,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1NDHE9N;43N9G3DW;CYBRG1PA;CC15HDWY;59WZZ7GW;LKV4G825;JW3K748X;D4CPENJN;P4C4DBKV;EGCDCZJK;186J8BA5;RKGW6W8L;CZXSLZSW;8PZ1JCJS;R1TX6B9G;D7WXR22B;KS6HKPBC;Y3TL4A2H;XTVABGL2',
      statusContent: 'dot',
      hash: 'TE24NT6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TE24NT6Z'
    },
    {
      status: 210,
      sku: 'PM0076225',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310259',
      rtl_batch_array: '6286674, 6310259',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 250,
      chnn_stock_retail: 922,
      sppl_prcp: 1.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '5ZYKZLEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZYKZLEW'
    },
    {
      status: 210,
      sku: 'PM0076350',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349973',
      rtl_batch_array: '6349973',
      name_search: 'CRTFCRIM',
      name: "Crocosmia 'Twilight Fairy Crimson'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 821,
      chnn_stock_retail: 821,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HK9VXXAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HK9VXXAY'
    },
    {
      status: 210,
      sku: 'PM0076355',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350032',
      rtl_batch_array: '6350032, 6320857',
      name_search: 'ECPCHIQU',
      name: "Echinacea purpurea 'Chiquita'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 362,
      chnn_stock_retail: 464,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WYJG6K6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WYJG6K6N'
    },
    {
      status: 210,
      sku: 'PM0076358',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349863',
      rtl_batch_array: '6349863',
      name_search: 'DEHMOONL',
      name: 'Delphinium Highlander Moonlight',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_order_minimum: 3,
      sppl_prcp: 3.078,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XB993TT5;9SC9WD79;LL33D1BV;RJ1J4G3V;29LL7K83;CYWEW9V3;GBXVBG8P',
      statusContent: 'dot',
      hash: 'A2LPJ65X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A2LPJ65X'
    },
    {
      status: 210,
      sku: 'PM0076429',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291336',
      rtl_batch_array: '6291336, 6310326',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 700,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.225,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'GA32D88C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GA32D88C'
    },
    {
      status: 210,
      sku: 'PM0076430',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291338',
      rtl_batch_array: '6291338',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 1.862,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: 'HKSL2WY2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HKSL2WY2'
    },
    {
      status: 210,
      sku: 'PM0076431',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291341',
      rtl_batch_array: '6349064, 6291341',
      name_search: 'CHLATIFO',
      name: 'Chasmanthium latifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 439,
      chnn_stock_retail: 1339,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CC39PZYS;KP7X33B9;JL52WB54;X4RJDS89;1AGYW5AJ;EXD47X18;Z8PGZ88C;H5RN3Z94;Z94592XX;BEC2V7DV;WZD1T11A;7TPB6AXR;Y7SS7A1N;SAEXK5XL;Z283LLBK;134ASKAW;8ZBRXCH9;T33X7GZT',
      statusContent: 'dot',
      hash: '23XVSAG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23XVSAG5'
    },
    {
      status: 210,
      sku: 'PM0076464',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291780',
      rtl_batch_array: '6291780',
      name_search: 'SCVSTERN',
      name: "Sciadopitys verticillata 'Sternschnuppe'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 11.121,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1KT2S19G',
      statusContent: 'dot',
      hash: 'SPN2L1DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPN2L1DP'
    },
    {
      status: 210,
      sku: 'PM0083814',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356942',
      rtl_batch_array: '6356942',
      name_search: 'ARMSPLEN',
      name: "Armeria maritima 'Splendens'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7W9819LA;GCGBAR2L;8HZLZ5A1;SH6T6YA2;EXJR741Z;CABJZDSL;WSBPEVKD;9G6EPLSV;BHPJBCNE',
      statusContent: 'dot',
      hash: '8AA1VE73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AA1VE73'
    },
    {
      status: 810,
      sku: 'PM0076466',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307453',
      rtl_batch_array: '6307453',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_prcp: 18.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'CKGVNP7J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CKGVNP7J'
    },
    {
      status: 810,
      sku: 'PM0076467',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307454',
      rtl_batch_array: '6307454',
      name_search: 'HAIAPROM',
      name: "Hamamelis intermedia 'Arnold Promise'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 24.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C4YNNKHY;KJ4KZH66;JSNLX33S;B8X9VL59;RDDG632E;ACKB1GVP;A117656H;KXA2TX94;5XJBG5JB;RSWT9Z5Z;C66948GZ;B2BT2XJ9;K1P8R6JT',
      statusContent: 'dot',
      hash: 'KYNA8YDZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KYNA8YDZ'
    },
    {
      status: 910,
      sku: 'PM0076468',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIDIANE',
      name: "Hamamelis intermedia 'Diane'",
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      imageCore: 'KS7D2HAN;W3J55S77;8E3TBC53;6YAEDBEC;8B5KDHXS',
      statusContent: 'dot',
      hash: 'JHKG8H6K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JHKG8H6K'
    },
    {
      status: 910,
      sku: 'PM0076469',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HAIJELEN',
      name: "Hamamelis intermedia 'Jelena'",
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      imageCore:
        'A44WDZ1L;94172CCS;83JYWRV8;E7JJ4XGB;9V84V3NX;44G4X252;PV572R42;J6V2ZR4L;8H6ANSNH',
      statusContent: 'dot',
      hash: 'TY828LT1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TY828LT1'
    },
    {
      status: 210,
      sku: 'PM0076226',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286675',
      rtl_batch_array: '6286675',
      name_search: 'EUFSQUEE',
      name: "Euonymus fortunei 'Silver Queen'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 1.013,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RYRG7861',
      statusContent: 'dot',
      hash: '7CJYHEDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CJYHEDP'
    },
    {
      status: 210,
      sku: 'PM0076220',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286494',
      rtl_batch_array: '6286494',
      name_search: 'GEMOFIRE',
      name: "Geranium macrorrhizum 'Olympic Fire'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 2670,
      chnn_stock_retail: 2670,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XTJ2Z8X1;ZA6VAN5C;BNWH4E1J;68ZHV87K;H6ZHSYTR;KS5YPNKZ',
      statusContent: 'dot',
      hash: 'NC5G995G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NC5G995G'
    },
    {
      status: 210,
      sku: 'PM0006270',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6242034',
      rtl_batch_array: '6192619, 5363890, 6242034, 6321891',
      name_search: 'ASHBRESS',
      name: "Aster (D) 'Herbstgruss v. Bresserh'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10029,
      chnn_stock_retail: 10892,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GK3ZX4TZ;43ETS5PZ;JEGZL2XT;85Z7LSNL;144TJ263;H4W3JLPR;HP1592CZ',
      statusContent: 'dot',
      hash: 'BX867SL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX867SL7'
    },
    {
      status: 210,
      sku: 'PM0041339',
      core_name: 'Plant',
      sppl_ean: '8720664801295',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '5572872',
      rtl_batch_array: '5572872',
      name_search: 'PEASBLUE',
      name: 'Perovskia atriplicifolia Silvery Blue',
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_prcp: 2.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y7DJJ3KR;566VV31C;AEP2YG7A;XWE79YTB;N9XEXGX8;XSXCCKLD;Y9GY78RD',
      imageBatch: 'BZB6RJLK',
      statusContent: 'dot',
      hash: 'H4TAEJL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4TAEJL2'
    },
    {
      status: 910,
      sku: 'PM0041325',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6224290',
      rtl_batch_array: '6224290, 6336354',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 5,
      chnn_stock_retail: 155,
      sppl_order_minimum: 6,
      sppl_prcp: 2.744,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: 'NBKK316V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBKK316V'
    },
    {
      status: 810,
      sku: 'PM0083815',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356943',
      rtl_batch_array: '6356943',
      name_search: 'ASTABULA',
      name: 'Astilboides tabularis',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_order_minimum: 3,
      sppl_prcp: 2.834,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XHJXCWJT;RGWK2NZZ;X96CDAB8;Z6N8V26H;LJE6ZZCL;CA5SNRP7;76RR5V2C;AJPHHYA7;Z9VLEC66;8XRBD8LG;LS41BKXA',
      statusContent: 'dot',
      hash: '9ZV7VD1T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZV7VD1T'
    },
    {
      status: 210,
      sku: 'PM0076627',
      core_name: 'Plant',
      sppl_ean: '8720353098500',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486249',
      rtl_batch_array: '5486249, 6315175',
      name_search: 'EUFHBLAZ',
      name: "Euonymus fortunei 'Hort's Blaze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10545,
      sppl_order_minimum: 3,
      sppl_prcp: 0.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4Y3P85XL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y3P85XL'
    },
    {
      status: 210,
      sku: 'PM0076628',
      core_name: 'Plant',
      sppl_ean: '8720353098371',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486251',
      rtl_batch_array: '5486251, 6315172',
      name_search: 'EUFCOLOR',
      name: "Euonymus fortunei 'Coloratus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10000,
      chnn_stock_retail: 14319,
      sppl_order_minimum: 3,
      sppl_prcp: 0.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1LPHN5Y;NN7E8ELY',
      statusContent: 'dot',
      hash: '5SBNAY6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SBNAY6B'
    },
    {
      status: 210,
      sku: 'PM0076629',
      core_name: 'Plant',
      sppl_ean: '8720626306493',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486257',
      rtl_batch_array: '5486257, 6336442',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10000,
      chnn_stock_retail: 16838,
      sppl_order_minimum: 3,
      sppl_prcp: 0.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'C7TB9KV5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7TB9KV5'
    },
    {
      status: 210,
      sku: 'PM0076630',
      core_name: 'Plant',
      sppl_ean: '8720664557826',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5486259',
      rtl_batch_array: '5486259, 6336441',
      name_search: 'HYDPDUMM',
      name: "Hypericum dummeri 'Peter Dummer'",
      sppl_size_code: 'PLGP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5000,
      chnn_stock_retail: 9500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4HSCY7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4HSCY7S'
    },
    {
      status: 210,
      sku: 'PM0076631',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '5486261',
      rtl_batch_array: '5486261',
      name_search: 'PYCRCUSH',
      name: "Pyracantha coccinea 'Red Cushion'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.224,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'REXDK5AZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'REXDK5AZ'
    },
    {
      status: 210,
      sku: 'PM0076633',
      core_name: 'Plant',
      sppl_ean: '8720626370661',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '5628792',
      rtl_batch_array: '5628792, 6338263',
      name_search: 'SPJUNCEU',
      name: 'Spartium junceum',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2913,
      sppl_prcp: 1.377,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4Z1BVPS2;JLTGL7D6;S7TZCN3V;H4TB43L2;RWWDEW7B;NW1P5CAV',
      imageBatch: 'X5PG8CC7',
      statusContent: 'dot',
      hash: '5X7V4T6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5X7V4T6E'
    },
    {
      status: 210,
      sku: 'PM0076634',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298460',
      rtl_batch_array: '6298460',
      name_search: 'CHSSTARL',
      name: "Chlorophytum saundersiae 'Starlight'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_prcp: 4.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XZZE1PXE;GJ4798SE',
      statusContent: 'dot',
      hash: '2SVSCT98',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SVSCT98'
    },
    {
      status: 210,
      sku: 'PM0076635',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298461',
      rtl_batch_array: '6298461',
      name_search: 'CACSSILV',
      name: 'Caryopteris clandonensis Sterling Silver',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 496,
      chnn_stock_retail: 496,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EV3LWWD5;72VBDSNZ;7NEC35YV;X2RT2WW1;L4VR9C1V;679Y5D6B;S6RSW26H;6PYBP943;AV18RJA9;L66BACVB',
      statusContent: 'dot',
      hash: 'KEKLW7RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEKLW7RJ'
    },
    {
      status: 210,
      sku: 'PM0076637',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298463',
      rtl_batch_array: '6298463',
      name_search: 'CEDGVERS',
      name: "Ceanothus delilianus 'Gloire de Versailles'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 918,
      chnn_stock_retail: 918,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ATGPTYLR;XA17B3K4;HC77YL3A;4TPEZX29',
      statusContent: 'dot',
      hash: '1TTSZ8GN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TTSZ8GN'
    },
    {
      status: 210,
      sku: 'PM0083816',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356944',
      rtl_batch_array: '6356944',
      name_search: 'CAPALBA',
      name: 'Caltha palustris alba',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VPPVN998;LJ4EPLJV;J5YSW219;WPK9K349;RDTYCG11',
      statusContent: 'dot',
      hash: 'W9TZ2C7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9TZ2C7E'
    },
    {
      status: 210,
      sku: 'PM0075978',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284161',
      rtl_batch_array: '6284161',
      name_search: 'HEWMOONB',
      name: "Helleborus 'Winter Moonbeam'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 431,
      chnn_stock_retail: 431,
      sppl_prcp: 2.554,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B7CLA8EA;SNTBDV1K;BSAWDCVT;DSDNJ591;61RCH57N',
      statusContent: 'dot',
      hash: 'XV1ZSCHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV1ZSCHE'
    },
    {
      status: 210,
      sku: 'PM0075977',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284160',
      rtl_batch_array: '6284160',
      name_search: 'HEPBEAUT',
      name: "Helleborus 'Pink Beauty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_prcp: 2.554,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WBVJ9JL4',
      statusContent: 'dot',
      hash: 'NSBWVNAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSBWVNAX'
    },
    {
      status: 210,
      sku: 'PM0076946',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207222',
      rtl_batch_array: '5207222',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 30000,
      chnn_stock_retail: 30000,
      sppl_prcp: 2.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      imageBatch: 'PX7D6ZRD',
      statusContent: 'dot',
      hash: '8WXJAP2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8WXJAP2J'
    },
    {
      status: 210,
      sku: 'PM0076947',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207479',
      rtl_batch_array: '5207479, 6300828',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 800,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'JZZWBY11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZZWBY11'
    },
    {
      status: 210,
      sku: 'PM0076948',
      core_name: 'Plant',
      sppl_ean: '8720626360150',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207489',
      rtl_batch_array: '5207489',
      name_search: 'NADFIREP',
      name: "Nandina domestica 'Firepower'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 3.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'C6KN4KV7',
      statusContent: 'dot',
      hash: '4733T8B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4733T8B3'
    },
    {
      status: 210,
      sku: 'PM0076949',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '5207490',
      rtl_batch_array: '5207490',
      name_search: 'NADGSTRE',
      name: "Nandina domestica 'Gulf Stream'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5177CD65;2ZXX95WG;HXBEY3NS;1KGAWP8J;DKXS5BGB;6D121H4B;VSRDDRHB;KW74VYE1;W3K99X4E;BC74L52W;ECSHV643;ZNS6C38G;A9NSHWK2',
      imageBatch: '71V87NW5',
      statusContent: 'dot',
      hash: '2ZVW3SCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZVW3SCD'
    },
    {
      status: 210,
      sku: 'PM0076950',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282491',
      rtl_batch_array: '6282491',
      name_search: 'AGCHARLO',
      name: "Agapanthus 'Charlotte'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 4.233,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BYKJBY2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYKJBY2Z'
    },
    {
      status: 210,
      sku: 'PM0076951',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282493',
      rtl_batch_array: '6282493',
      name_search: 'CHTERNAT',
      name: 'Choisya ternata',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G8TLCKYZ;JW17KXBA;X736RJD9;H6LTPNCC;NVL5L1B9;BERRW4HD;4SJKG1KD;RZS3YX1N;J237H22V;4LTBD9DG;JSP3GV5A;YWTP34X6;KSW583GW;JNZYPVZG;HYTYCPRC;XZ46KL91;2TXC2A9K;723L4LWN;7ZG8ZYJS;N9H7HLNB;8N79W1PZ',
      statusContent: 'dot',
      hash: 'WCXG1NP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCXG1NP5'
    },
    {
      status: 810,
      sku: 'PM0076351',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349992',
      rtl_batch_array: '6349992',
      name_search: 'DIIHEART',
      name: "Dicentra 'Ivory Hearts'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9LV1XCY4',
      statusContent: 'dot',
      hash: 'D6K4KTV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D6K4KTV2'
    },
    {
      status: 210,
      sku: 'PM0076952',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282496',
      rtl_batch_array: '6282496',
      name_search: 'CHTSUNDA',
      name: 'Choisya ternata Sundance',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V42YL5RC;JRCE449K;XYRHEPZN;HXDXG4H2;L9Z5895X;JYVD7ZN6;KKWV491Z;9XHSEVLD;59ZX6N6T;6EHZS8RS;BSE13AEH;T399A1TP;PNN3V7YY;R25ZGNYL;NT63J78C;SN359S4T;DE4A48GH;JCW58RHJ;GN1JY58L;A2NE21ED;TZZATBVK;ZK7BGDRG;JC8HHA2W;PSTA47GK',
      statusContent: 'dot',
      hash: '2WLSTSVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2WLSTSVN'
    },
    {
      status: 210,
      sku: 'PM0076953',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282497',
      rtl_batch_array: '6282497',
      name_search: 'CHAPEARL',
      name: "Choisya 'Aztec Pearl'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DNJ3T5LL;KCN1HTR5;PXG57LKC;86D6TAS4;4CBDREZZ;Y52R71EJ;K39H39N2;R2HSTWGE;8GKN7W7Z;56ND4WHJ;V2S1ZL1K;WGVGXB59;KAVCK8JL;WE3EG4EV;7TR6665P;7VT87G6Z;HTW9PKS9;NWNNE7H7;8SBBB311;8HWT4P2X;738W2LJX;GLSXX6A9',
      statusContent: 'dot',
      hash: '45VDP5D3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45VDP5D3'
    },
    {
      status: 210,
      sku: 'PM0076954',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282499',
      rtl_batch_array: '6282499',
      name_search: 'CHGOLDFI',
      name: 'Choisya Goldfingers',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9E5PBBJ9;TX8TB739;B3L4GZP6',
      statusContent: 'dot',
      hash: 'S93XNPLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S93XNPLT'
    },
    {
      status: 210,
      sku: 'PM0076955',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 1,
      btch_active_retail: '6282515',
      rtl_batch_array: '6282515',
      name_search: 'CHTWDAZZ',
      name: 'Choisya ternata White Dazzler',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4T1ZSCT2;ZX9GZWPT;BZDNJYW7;5NECA4L1;EW22X96Y;E86B5SG1;18VS53VA;WDDY2GLW;LXDW55SC;N14KL7J2;7J1E4SB7;Z8XNY8DJ;AA5BX1GK;PCESN4ND;LC7B4G7Z;RV9CEY63',
      statusContent: 'dot',
      hash: 'BEAT1ZVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEAT1ZVC'
    },
    {
      status: 210,
      sku: 'PM0076956',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299026',
      rtl_batch_array: '6299026',
      name_search: 'CYSVANES',
      name: "Cytisus scoparius 'Vanesse'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.237,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GW54SN5P;2SR1CSKP;72GSKG6A;H9WVES2A;DRBX2GNV;ZS264Z16;SRYNKC99;P2TCLT86;K88X1KX8',
      statusContent: 'dot',
      hash: 'PSE5SJHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSE5SJHB'
    },
    {
      status: 210,
      sku: 'PM0076957',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259658',
      rtl_batch_array: '6259658',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: 'JY9AN48W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JY9AN48W'
    },
    {
      status: 210,
      sku: 'PM0076352',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350024',
      rtl_batch_array: '6350024, 6301547',
      name_search: 'ECPALOHA',
      name: "Echinacea purpurea 'Aloha'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 551,
      chnn_stock_retail: 631,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D62EYVDB;BN7SBC5W;A9EYWNPH;5VJ277PD;21LCS7SX;WBNVZ2P8;VN9KR2L5;HXH1355Y',
      statusContent: 'dot',
      hash: '5CGJPZDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CGJPZDB'
    },
    {
      status: 210,
      sku: 'PM0076961',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259663',
      rtl_batch_array: '6259663',
      name_search: 'RUTAYBER',
      name: "Rubus 'Tayberry'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CHGWC4XH;LLZGNB83;VTE7K7AG;XG4SVPDK;7XXYBJ3E;W4G8GVZ2;C1C74ZLL;2Z1PXS2V;ZVED92CZ;8HTE5JA2;S3NC288X;N66H5P1V;7J82YAJY;18458AHS',
      statusContent: 'dot',
      hash: 'ZJX6WN32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJX6WN32'
    },
    {
      status: 210,
      sku: 'PM0076963',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259665',
      rtl_batch_array: '6259665',
      name_search: 'RUIFALLG',
      name: "Rubus idaeus 'Fallgold'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BY6DCCVW;G8J6J7LT',
      statusContent: 'dot',
      hash: 'WND6R5V5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WND6R5V5'
    },
    {
      status: 210,
      sku: 'PM0076964',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259667',
      rtl_batch_array: '6259667',
      name_search: 'RUIOTTAW',
      name: "Rubus idaeus 'Ottawa'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '89BGSXA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89BGSXA1'
    },
    {
      status: 210,
      sku: 'PM0076965',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266514',
      rtl_batch_array: '6266514',
      name_search: 'MADGDELI',
      name: "Malus domestica 'Golden Delicious'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.979,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78WADP9W;C4BE4NLB;KW984AJS;9HS7BHDB;4BJ5N998;KWTGRCTW;XSB7E54C;H6W277DA;PE87CDKK;VYBZE3ZH;BYD1TEVB;6T6WD9WW;CLJZKTCH;8LHRJ9LX',
      statusContent: 'dot',
      hash: '5NP3VAX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5NP3VAX5'
    },
    {
      status: 210,
      sku: 'PM0076966',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266515',
      rtl_batch_array: '6266515',
      name_search: 'MADGDELI',
      name: "Malus domestica 'Golden Delicious'",
      sppl_size_code: '120130C74',
      rng_range_identifier: 'H120130C7.5',
      rng_range_description: 'H120 cm 130 cm C7.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 9.419,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78WADP9W;C4BE4NLB;KW984AJS;9HS7BHDB;4BJ5N998;KWTGRCTW;XSB7E54C;H6W277DA;PE87CDKK;VYBZE3ZH;BYD1TEVB;6T6WD9WW;CLJZKTCH;8LHRJ9LX',
      statusContent: 'dot',
      hash: 'WXJBVLT1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXJBVLT1'
    },
    {
      status: 210,
      sku: 'PM0076967',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266516',
      rtl_batch_array: '6266516',
      name_search: 'MADGDELI',
      name: "Malus domestica 'Golden Delicious'",
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 16.199,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78WADP9W;C4BE4NLB;KW984AJS;9HS7BHDB;4BJ5N998;KWTGRCTW;XSB7E54C;H6W277DA;PE87CDKK;VYBZE3ZH;BYD1TEVB;6T6WD9WW;CLJZKTCH;8LHRJ9LX',
      statusContent: 'dot',
      hash: 'VK8K7L7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VK8K7L7T'
    },
    {
      status: 210,
      sku: 'PM0076968',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266517',
      rtl_batch_array: '6266517',
      name_search: 'PRDOPAL',
      name: "Prunus domestica 'Opal'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT6PSZWG;BSXAWWB3;BN3WZB67;6B85S8H6;VWP38YZR;ZSCHLWLA',
      statusContent: 'dot',
      hash: 'K2DW5BAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2DW5BAV'
    },
    {
      status: 210,
      sku: 'PM0075873',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284510',
      rtl_batch_array: '6252450, 6284510, 6305784',
      name_search: 'ANHPHEIN',
      name: "Anemone hupehensis 'Prinz Heinrich'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 764,
      chnn_stock_retail: 1681,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P1GVRP8X;EGHZT32P;N1VKLVVP;YE462XS7;KNASX3TE;Y28S5YDE;SYKT6K52;8HT5EW56;4DJ6PXL4;HH9W31LX;T3S61YPK;14RXYEXY;PRDTNTAK;9VKR37SB;A2KXA4TT',
      statusContent: 'dot',
      hash: 'D9C625KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9C625KY'
    },
    {
      status: 210,
      sku: 'PM0076969',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266518',
      rtl_batch_array: '6266518',
      name_search: 'PRDVICTO',
      name: "Prunus domestica 'Victoria'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EL659B3C;SBR37DG7;RPB57WX8;TT5YYXB2;4JRSHGCY;TAGW217X;HVA5V1LV;K33JSX8W;DZNB6G86;XDYXABWY;94RR2AZB;2VXHT89S;J6WAN3K6',
      statusContent: 'dot',
      hash: '5YJVRKVZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5YJVRKVZ'
    },
    {
      status: 210,
      sku: 'PM0076970',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266519',
      rtl_batch_array: '6266519',
      name_search: 'PYCCONFE',
      name: "Pyrus communis 'Conference'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78TNR6AS;DVHDGPC2;NR5ETZAS;GZPRZKDK;B7JA2AX7;8VH6VLWV;WVSHB7Y8;EBYNZR1D;5AHEK6LG;9Z3EPSPN;P65ADCG8;9H9DSJ2B;ASK4T5HW;VRYCD2NE;VR62KGXS;C7LZAEBR;DW74T1JH;T9ZDR5SN;AL1GZY1W;SEPY5SRN;47PVKKJK;A7545WS5;4221RV75',
      statusContent: 'dot',
      hash: 'ACDV2BHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ACDV2BHA'
    },
    {
      status: 210,
      sku: 'PM0076971',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266520',
      rtl_batch_array: '6266520',
      name_search: 'PYCDCOMI',
      name: "Pyrus communis 'Doyenn\u00e9 du Comice'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CH7BGNZY;X5EG45CP;452LAS3T;JD8WJNCP;61194LDR;G2439YD4;7W6H6N2G;LJ5TVK3R;ZV3E89RS;LVJXP3NN;L1H8DYGZ;B3ZBAJJE;L5ALVSHX;YZJTGG56;P2RXKR82;NECE7T11;VLPCBNKL',
      statusContent: 'dot',
      hash: '6GXJLH83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GXJLH83'
    },
    {
      status: 210,
      sku: 'PM0076974',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266523',
      rtl_batch_array: '6266523',
      name_search: 'ACDBOSKO',
      name: "Actinidia deliciosa 'Boskoop'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 3.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '63TJ9DRA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63TJ9DRA'
    },
    {
      status: 210,
      sku: 'PM0076975',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266524',
      rtl_batch_array: '6266524',
      name_search: 'ACDJENNY',
      name: "Actinidia deliciosa 'Jenny'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_prcp: 3.654,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K4E95RN;LY9ZSDKY;N5LXEHRX',
      statusContent: 'dot',
      hash: 'DAX9WJYT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAX9WJYT'
    },
    {
      status: 210,
      sku: 'PM0076976',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266525',
      rtl_batch_array: '6266525',
      name_search: 'MADELSTA',
      name: "Malus domestica 'Elstar'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDZVRK7Z;K8E21E1P;1EA2W54T;BGJSBCKX;8X1PRC56;ZLBZL51Y;XKS9C7DG;GSDJP9H4;S9A2PJ6H;7N5SWA3C;BJVDKDWY;HX39YY72;45Y52SND;81N2XLE6;74E5CN34;GR6X6C86;PDSEXGJ1;HRDKVDTH;WSNA7K9C',
      statusContent: 'dot',
      hash: 'PKTCCSA3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PKTCCSA3'
    },
    {
      status: 210,
      sku: 'PM0076977',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266526',
      rtl_batch_array: '6266526',
      name_search: 'MADGDELI',
      name: "Malus domestica 'Golden Delicious'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78WADP9W;C4BE4NLB;KW984AJS;9HS7BHDB;4BJ5N998;KWTGRCTW;XSB7E54C;H6W277DA;PE87CDKK;VYBZE3ZH;BYD1TEVB;6T6WD9WW;CLJZKTCH;8LHRJ9LX',
      statusContent: 'dot',
      hash: 'YNPPZRED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YNPPZRED'
    },
    {
      status: 210,
      sku: 'PM0076978',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266527',
      rtl_batch_array: '6266527',
      name_search: 'MADGALA',
      name: "Malus domestica 'Gala'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YC84GTZE;7WWGNYLC;GE7PW553;VLY7DPVP;D7RPAVSB;R8SZXSHT;5YYVAN6Y;GZYNBZC1;VRE5CPZ6;HRC1P4B5;E165VD8V;S55A78ZV;NE5SR2LE;AGRLP12H;NSBZB215;DAC6P1XH;R4E9ZGSA',
      statusContent: 'dot',
      hash: 'D9EWTLWA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D9EWTLWA'
    },
    {
      status: 210,
      sku: 'PM0076979',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266528',
      rtl_batch_array: '6266528',
      name_search: 'MADFUJI',
      name: "Malus domestica 'Fuji'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SYG75JSB;GK71YC6Y;VP4WA697;BSW7HNPE',
      statusContent: 'dot',
      hash: 'L2Y5CG76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2Y5CG76'
    },
    {
      status: 210,
      sku: 'PM0076980',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266529',
      rtl_batch_array: '6266529',
      name_search: 'MADDELCO',
      name: "Malus domestica 'Delcorf'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A1P8BL59;P4ASEK88',
      statusContent: 'dot',
      hash: 'S59LA3PB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S59LA3PB'
    },
    {
      status: 210,
      sku: 'PM0076981',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266530',
      rtl_batch_array: '6266530',
      name_search: 'MADSUMME',
      name: "Malus domestica 'Summerred'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K5D2KDH5;AWDH484J;D7BLLKJ4;9LVNGN67;C4XY37CA;GCC3GH25;26S92ATW;NS584XJN;P24XYY28;TLYCD996;2N3147CW',
      statusContent: 'dot',
      hash: 'WTD48ZGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WTD48ZGP'
    },
    {
      status: 210,
      sku: 'PM0076982',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266531',
      rtl_batch_array: '6266531',
      name_search: 'MADJONAG',
      name: "Malus domestica 'Jonagold'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N853E2J;SLKLHWLB;NWKWEXCX;CL4SXVR9;AWDPTNB7;N5LKSHN3;KLN3WSK2;EAV7R7VJ;RBATW5KG;Y68X5SRL',
      statusContent: 'dot',
      hash: 'BL5YS5A3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BL5YS5A3'
    },
    {
      status: 210,
      sku: 'PM0076983',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266532',
      rtl_batch_array: '6266532',
      name_search: 'PRAVAN',
      name: "Prunus avium 'Van'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 6.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XAE9B6G7;KB7DZ948;SZRWRAEX;RA3N4Z4B;A6XJVR7K;PHXSXPYH;ASPHDTJD',
      statusContent: 'dot',
      hash: 'VZ23WJ6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ23WJ6B'
    },
    {
      status: 210,
      sku: 'PM0076984',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266596',
      rtl_batch_array: '6266596',
      name_search: 'MADGDELI',
      name: "Malus domestica 'Golden Delicious'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2250,
      chnn_stock_retail: 2250,
      sppl_order_minimum: 3,
      sppl_prcp: 4.046,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '78WADP9W;C4BE4NLB;KW984AJS;9HS7BHDB;4BJ5N998;KWTGRCTW;XSB7E54C;H6W277DA;PE87CDKK;VYBZE3ZH;BYD1TEVB;6T6WD9WW;CLJZKTCH;8LHRJ9LX',
      statusContent: 'dot',
      hash: '28CN4K3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '28CN4K3A'
    },
    {
      status: 810,
      sku: 'PM0083817',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356945',
      rtl_batch_array: '6356945',
      name_search: 'CADGOLD',
      name: "Campanula 'Dickson's Gold'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 92,
      chnn_stock_retail: 92,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NS4R64PS;C78RAWYA;DHT22TP6;2DVJZGCA;8KT1RLA2;BB6J3DCA;75GPKKXW;BTN7HBKX;XPHN7HY5;T2Y64PLK;6VHTBDYE;82WLV6YK;14T4RP71;YDH5PVNG;8DXWVHGC;XTYNY3JR;S516W9Y1;CC5TBYSJ',
      statusContent: 'dot',
      hash: '5N17H6S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5N17H6S2'
    },
    {
      status: 210,
      sku: 'PM0083818',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356946',
      rtl_batch_array: '6356946',
      name_search: 'CAPRATEN',
      name: 'Cardamine pratensis',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SXGAGBY;NPJD8TYS;Z9HS4E98;KL6LYD38',
      statusContent: 'dot',
      hash: 'W5L9XXX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5L9XXX3'
    },
    {
      status: 210,
      sku: 'PM0083819',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356947',
      rtl_batch_array: '6356947',
      name_search: 'CEHJCOUT',
      name: "Centaurea hypoleuca 'John Coutts'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW3EGGYL',
      statusContent: 'dot',
      hash: 'RCSAV47D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCSAV47D'
    },
    {
      status: 210,
      sku: 'PM0084183',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363411',
      rtl_batch_array: '6363411',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3853,
      chnn_stock_retail: 3853,
      sppl_order_minimum: 3,
      sppl_prcp: 1.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: 'B5E9WBBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5E9WBBZ'
    },
    {
      status: 210,
      sku: 'PM0076985',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299653',
      rtl_batch_array: '6299653',
      name_search: 'MAREPTAN',
      name: 'Mazus reptans',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 593,
      chnn_stock_retail: 593,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KYPEH7G7;966HKP7Y;W38Y6C11;YPRP8V1Y;CYTDNGNL;16TE9RYA;RBSA2JY5;ZJPPTKYG',
      statusContent: 'dot',
      hash: 'AZSHZ84T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZSHZ84T'
    },
    {
      status: 910,
      sku: 'PM0076999',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIOPULUS',
      name: 'Viburnum opulus',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore: '6WVH4CAK;AZV2GSHE;6CXN2CWK;N979L6N3;W2ZD3W8G',
      statusContent: 'dot',
      hash: '292YA63A',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '292YA63A'
    },
    {
      status: 210,
      sku: 'PM0077000',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299719',
      rtl_batch_array: '6299719',
      name_search: 'PRULGREE',
      name: "Prunus laurocerasus 'Greenpeace'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 3.918,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WG7H23Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WG7H23Z'
    },
    {
      status: 910,
      sku: 'PM0077001',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AMLAMARC',
      name: 'Amelanchier lamarckii',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        'R4DGXTAH;JRNL5LSV;8H7WNDPY;V3TTCEK7;8GW82X88;ZYW1L7RW;JCZHARB7;5YWDE7N3;AT9GTT2J;1RG4V23S',
      statusContent: 'dot',
      hash: '4EBS9YZ6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4EBS9YZ6'
    },
    {
      status: 210,
      sku: 'PM0077002',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299722',
      rtl_batch_array: '6299722',
      name_search: 'BUDPI',
      name: "Buddleja davidii 'PIIBD-I'",
      sppl_size_code: '030040C7',
      rng_range_identifier: 'H030040C7',
      sppl_stock_available: 1302,
      chnn_stock_retail: 1302,
      sppl_prcp: 6.288,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KVD7NBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KVD7NBJ'
    },
    {
      status: 910,
      sku: 'PM0077003',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'JN8PGZB3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JN8PGZB3'
    },
    {
      status: 210,
      sku: 'PM0077004',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299729',
      rtl_batch_array: '6299729',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '050060C10',
      rng_range_identifier: 'H050060C10',
      rng_range_description: 'H50 cm 60 cm C10',
      sppl_stock_available: 446,
      chnn_stock_retail: 446,
      sppl_prcp: 8.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: '9V9X5WV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9V9X5WV9'
    },
    {
      status: 910,
      sku: 'PM0077006',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: 'RLR1859N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RLR1859N'
    },
    {
      status: 910,
      sku: 'PM0077007',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEHSFIEL',
      name: "Deutzia hybrida 'Strawberry Fields'",
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      imageCore:
        'LE1BZJR9;H6DXPKJJ;TED91JW7;K6VC794N;83482BH1;BDGCJBVY;1V3EKZ55;RSTA8HJZ;C3SLHEVD;JA74TK8P;BBE3XZW8',
      statusContent: 'dot',
      hash: 'ZBAY29GL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZBAY29GL'
    },
    {
      status: 910,
      sku: 'PM0077008',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      statusContent: 'dot',
      hash: '1YPX897D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1YPX897D'
    },
    {
      status: 210,
      sku: 'PM0077011',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299761',
      rtl_batch_array: '6299761',
      name_search: 'ALJULIBR',
      name: 'Albizia julibrissin',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 547,
      chnn_stock_retail: 547,
      sppl_prcp: 4.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y4W3B1GG;W8HK2HCT;K97TWYEC;C48GPAYH',
      statusContent: 'dot',
      hash: '1NSZ5A3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1NSZ5A3T'
    },
    {
      status: 910,
      sku: 'PM0077005',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'LZGT3ZNW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LZGT3ZNW'
    },
    {
      status: 210,
      sku: 'PM0076960',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259662',
      rtl_batch_array: '6259662',
      name_search: 'RIUCHROE',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki R\u00f6d',
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECJGAL8T;H955H26G;V85N3L43;2Y2CY1AD;C8RL4TWB;B58HCCJS',
      statusContent: 'dot',
      hash: '5L1JHLBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L1JHLBH'
    },
    {
      status: 910,
      sku: 'PM0077009',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      rng_range_identifier: 'H030040C7',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'S2GTC736',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S2GTC736'
    },
    {
      status: 910,
      sku: 'PM0076997',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '8LCL38ET',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8LCL38ET'
    },
    {
      status: 210,
      sku: 'PM0076959',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259660',
      rtl_batch_array: '6259660',
      name_search: 'RIRWHOLL',
      name: "Ribes rubrum (white) 'Witte Hollander'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E7E22Z4',
      statusContent: 'dot',
      hash: '1LXTER7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LXTER7V'
    },
    {
      status: 210,
      sku: 'PM0076958',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259659',
      rtl_batch_array: '6259659',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: '2Y96TLRC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2Y96TLRC'
    },
    {
      status: 810,
      sku: 'PM0076471',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307470',
      rtl_batch_array: '6307470',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '100125C12',
      rng_range_identifier: 'H100125C12',
      rng_range_description: 'H100 cm 125 cm C12',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 24.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: 'C3ZAXAV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3ZAXAV1'
    },
    {
      status: 810,
      sku: 'PM0076470',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307468',
      rtl_batch_array: '6307468',
      name_search: 'HAIPALLI',
      name: "Hamamelis intermedia 'Pallida'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 58,
      chnn_stock_retail: 58,
      sppl_prcp: 15.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HNS465NT;K836XD22;2LSCV99X;76GARWHN;4LP9HGC9;CASCJZS9;S3HDDRR8;L9BV2159;BTWHA5X1',
      statusContent: 'dot',
      hash: '7PTXP99X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PTXP99X'
    },
    {
      status: 210,
      sku: 'PM0076632',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 1,
      btch_active_retail: '5550885',
      rtl_batch_array: '5550885',
      name_search: 'COSCBEAU',
      name: "Cotoneaster suecicus 'Coral Beauty'",
      sppl_size_code: '025030P9',
      rng_range_identifier: 'H025030P9',
      rng_range_description: 'H25 cm 30 cm P9',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 6,
      sppl_prcp: 0.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N2N1R7KB;5K2B6YV7;2GH6LTAR;6PWL5D6T;4KZWZY7S;H46VE6A6;4HYAS46K;CRRNH21P;KKGX29ZP;G4CB56AS;HL5BHVB7;Y3CS1PL8;V4BBTAWZ;KLGZEZZW;HEVSYN6X;TE6KVK1A;AXNLWKDE',
      imageBatch: '5EDP3ZKK',
      statusContent: 'dot',
      hash: 'XWLWAB9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XWLWAB9E'
    },
    {
      status: 210,
      sku: 'PM0076962',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6259664',
      rtl_batch_array: '6259664',
      name_search: 'RUFTHORN',
      name: "Rubus fruticosus 'Thornfree'",
      sppl_size_code: '060070C5',
      rng_range_identifier: 'H060070C5',
      rng_range_description: 'H60 cm 70 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.503,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZSRJ9NGH;T63KNDAR',
      statusContent: 'dot',
      hash: 'RTG39DAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTG39DAP'
    },
    {
      status: 910,
      sku: 'PM0076998',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYDWHEDG',
      name: "Symphoricarpos doorenbosii 'White Hedge'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore:
        'PR16EXXL;XV6T78CH;6DN91AJ8;6RZPZ88G;N9NA5KYL;RZADSZXA;T4Z8Z541;12HR9ZCZ;HRJ3DD3G',
      statusContent: 'dot',
      hash: 'EKDLHL5J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EKDLHL5J'
    },
    {
      status: 210,
      sku: 'PM0084184',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363412',
      rtl_batch_array: '6363412',
      name_search: 'ROOMJUPR',
      name: "Rosmarinus officinalis 'Miss Jessopp's Upright'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2800,
      chnn_stock_retail: 2800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.51,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GH2H6229;JR97TD2N',
      statusContent: 'dot',
      hash: 'WXY2Y8VP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXY2Y8VP'
    },
    {
      status: 910,
      sku: 'PM0077010',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      rng_range_identifier: 'H040050C10',
      rng_range_description: 'H40 cm 50 cm C10',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: '6X19NW55',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6X19NW55'
    },
    {
      status: 210,
      sku: 'PM0076356',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350033',
      rtl_batch_array: '6350033, 6338933',
      name_search: 'ECCLEOPA',
      name: "Echinacea 'Cleopatra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 383,
      chnn_stock_retail: 467,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3HXZTYD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3HXZTYD'
    },
    {
      status: 210,
      sku: 'PM0076353',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350025',
      rtl_batch_array: '6350025',
      name_search: 'ECPADREA',
      name: "Echinacea purpurea 'Amazing Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 339,
      chnn_stock_retail: 339,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N1CKWBLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1CKWBLW'
    },
    {
      status: 210,
      sku: 'PM0076354',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350028',
      rtl_batch_array: '6350028',
      name_search: 'ECPBKISS',
      name: "Echinacea purpurea 'Butterfly Kissis'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 551,
      chnn_stock_retail: 551,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BCBS4NAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BCBS4NAN'
    },
    {
      status: 210,
      sku: 'PM0076357',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350039',
      rtl_batch_array: '6350039',
      name_search: 'ECPFWHEE',
      name: "Echinacea purpurea 'Ferris Wheel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 218,
      chnn_stock_retail: 218,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X6CXE6ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6CXE6ZH'
    },
    {
      status: 210,
      sku: 'PM0084185',
      core_name: 'Plant',
      btch_manufacturer: 278,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363413',
      rtl_batch_array: '6363413',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3170,
      chnn_stock_retail: 3170,
      sppl_order_minimum: 3,
      sppl_prcp: 1.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      statusContent: 'dot',
      hash: '4VXS47G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4VXS47G9'
    },
    {
      status: 810,
      sku: 'PM0084186',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363458',
      rtl_batch_array: '6363458',
      name_search: 'ALLIRE',
      name: "Alstroemeria 'Lir\u00e9'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXRZLDBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXRZLDBD'
    },
    {
      status: 910,
      sku: 'PM0084187',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DELMLIGH',
      name: "Delphinium (E) 'Morning Lights'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'LCPXSV97',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LCPXSV97'
    },
    {
      status: 810,
      sku: 'PM0084188',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363461',
      rtl_batch_array: '6363461',
      name_search: 'ENVMAURE',
      name: "Ensete ventricosum 'Maurelii'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 59,
      chnn_stock_retail: 59,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WVDHHWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WVDHHWT'
    },
    {
      status: 810,
      sku: 'PM0084189',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363462',
      rtl_batch_array: '6363462',
      name_search: 'EDI61680',
      name: "Fritillaria imperialis 'Aurora'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z583N57Z;R55NWYXZ;S7J1SNEL;R2G46HYE;9LP2K513;V2E5R6K4;92S6SAV7;NPLA7REW',
      statusContent: 'dot',
      hash: 'AB86SN84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AB86SN84'
    },
    {
      status: 210,
      sku: 'PM0083820',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356949',
      rtl_batch_array: '6356949',
      name_search: 'ECPALBA',
      name: "Echinacea purpurea 'Alba'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSG792BE;BWC2X2CD;JD32Y745',
      statusContent: 'dot',
      hash: 'YD718NSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD718NSP'
    },
    {
      status: 210,
      sku: 'PM0076666',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298494',
      rtl_batch_array: '6298494',
      name_search: 'COASCOOT',
      name: "Corylus avellana 'Scooter'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2335,
      chnn_stock_retail: 2335,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1RESB8ER;4WL8RR79;HAYXVD3D;D254HXYP;P6RY6THE;L83NNLDD;9LS8LWYL;2VK8875S;DKY36L23;11CAC2H3;ZWSHXN25;ZTLE16YA;R4W6Z8A2;GH3TRTRT;7329T1VL;C9BG6GSB;KKNV5RL4;K7H8A3B7',
      statusContent: 'dot',
      hash: 'BSRBW8RG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSRBW8RG'
    },
    {
      status: 210,
      sku: 'PM0083821',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356950',
      rtl_batch_array: '6356950',
      name_search: 'ECPMAGNU',
      name: "Echinacea purpurea 'Magnus'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B5BBAX8;H61X9HC9;VDTJAZV1;AL37J663;8RWVZ719',
      statusContent: 'dot',
      hash: '7ELTNWW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ELTNWW2'
    },
    {
      status: 210,
      sku: 'PM0083822',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356951',
      rtl_batch_array: '6356951',
      name_search: 'ECPURPUR',
      name: 'Echinacea purpurea',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7TXBJJ8K;4TZJCNLZ;2S124CT9;G12TBABS;VXE1Z3EP;A727S678;P9TWHCKA;1GE2ZB3S;7G16KJXE;18JGWAYG;CCHNWHH9;HY2A66AY;7ZNRBA3P;5CWD2TGW;WT6W1T3W;V53VY5JB;11S2XENG;XKSLB8D1;CS8WTBXD;NBL2DRRY;WW6GS14S;BG8A1C8L;JST2T4LS;PR78AA8R',
      statusContent: 'dot',
      hash: 'HEWDPZES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEWDPZES'
    },
    {
      status: 210,
      sku: 'PM0075987',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284266',
      rtl_batch_array: '6284266',
      name_search: 'AGKCORAL',
      name: "Agastache 'Kudos Coral'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 353,
      chnn_stock_retail: 353,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K1V6NXT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K1V6NXT6'
    },
    {
      status: 210,
      sku: 'PM0076232',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286684',
      rtl_batch_array: '6286684',
      name_search: 'PIJDEBUT',
      name: "Pieris japonica 'Debutante'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '28C4G9Z7;TJBDT4GA;E736XHBT;1SD1LN4B;GYBD11SY;T6AKPDLP;938DW86L;Z6ALPBB5;ET5TKR37;HCJY8V13;N52Z4R56;83YVXS5Z',
      statusContent: 'dot',
      hash: 'SGGHRG7L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SGGHRG7L'
    },
    {
      status: 210,
      sku: 'PM0075983',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284230',
      rtl_batch_array: '6284230, 6353233',
      name_search: 'PRDCASHM',
      name: "Primula denticulata 'Cashmeriana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1571,
      chnn_stock_retail: 2177,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2KJ8SG6L;9SZJSCYS;YBCLJN37;RD526VXV',
      statusContent: 'dot',
      hash: 'HJ6KJENZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJ6KJENZ'
    },
    {
      status: 210,
      sku: 'PM0075984',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284232',
      rtl_batch_array: '6284232',
      name_search: 'TRLDOLL',
      name: "Tradescantia (A) 'Little Doll'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '43J69DK3',
      statusContent: 'dot',
      hash: '6EBNR5LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6EBNR5LE'
    },
    {
      status: 210,
      sku: 'PM0083823',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356952',
      rtl_batch_array: '6356952',
      name_search: 'ECBBGLOW',
      name: "Echinops bannaticus 'Blue Glow'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5424Z5ES',
      statusContent: 'dot',
      hash: '2XSDST63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XSDST63'
    },
    {
      status: 210,
      sku: 'PM0075986',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284265',
      rtl_batch_array: '6284265',
      name_search: 'AGKAMBRO',
      name: "Agastache 'Kudos Ambrosia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 589,
      chnn_stock_retail: 589,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XWN81X6J;TB18K3KX;7D5XKC3V;EZHRKKDW',
      statusContent: 'dot',
      hash: 'DHJBCJBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHJBCJBD'
    },
    {
      status: 210,
      sku: 'PM0075988',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284267',
      rtl_batch_array: '6284267',
      name_search: 'AGKMANDA',
      name: "Agastache 'Kudos Mandarin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '14C4YLH6;GHE4BSZ8;VPD31V8W;NWV21TPZ',
      statusContent: 'dot',
      hash: 'BXVLRYJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXVLRYJB'
    },
    {
      status: 210,
      sku: 'PM0083824',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356953',
      rtl_batch_array: '6356953',
      name_search: 'ECBSFROS',
      name: "Echinops bannaticus 'Star Frost'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SYH2VY49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYH2VY49'
    },
    {
      status: 210,
      sku: 'PM0076360',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301316',
      rtl_batch_array: '6350117, 6301316',
      name_search: 'EPANGUST',
      name: 'Epilobium angustifolium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 101,
      chnn_stock_retail: 345,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5PWY14R',
      statusContent: 'dot',
      hash: 'W35HHYKR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W35HHYKR'
    },
    {
      status: 210,
      sku: 'PM0076480',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291891',
      rtl_batch_array: '6291891',
      name_search: 'EUCSSWAN',
      name: 'Euphorbia characias Silver Swan',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_prcp: 3.398,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L5KV1BV3;DJ4JZSRP',
      statusContent: 'dot',
      hash: '1J37GSNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1J37GSNJ'
    },
    {
      status: 210,
      sku: 'PM0075985',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284264',
      rtl_batch_array: '6284264',
      name_search: 'AGAYALA',
      name: "Agastache 'Ayala'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JJDH7K2Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJDH7K2Y'
    },
    {
      status: 210,
      sku: 'PM0075991',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284271',
      rtl_batch_array: '6284271',
      name_search: 'AGPSUNRI',
      name: "Agastache 'Pink Sunrise'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 239,
      chnn_stock_retail: 239,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRPW53BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRPW53BW'
    },
    {
      status: 210,
      sku: 'PM0075992',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301915',
      rtl_batch_array: '6301915, 6284272',
      name_search: 'ALTRIPHY',
      name: 'Aloysia triphylla',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 214,
      chnn_stock_retail: 691,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WZLEZNWD;9AK9NKYD;29B6VBTV;Y5CZ2WVG;PKJCG7AV;XE4RRSJJ;4J8SES9T;XTK189D8;YAVEL3RC;GVBA1TXR;R5W5L819;JCR31ZPD;P5WVZSA7;RCXSRE1C;AYGHWT1V;RAH1HT2X;HSZ8VBS4;PCXXSXW6;2L78XB1G;2KR29RK7;DPPVBXN1;8HL8TR3K',
      statusContent: 'dot',
      hash: 'R8VTR8K7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8VTR8K7'
    },
    {
      status: 210,
      sku: 'PM0076647',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298474',
      rtl_batch_array: '6298474',
      name_search: 'CLALNIFO',
      name: 'Clethra alnifolia',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1470,
      chnn_stock_retail: 1470,
      sppl_prcp: 3.683,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7DS19WLG;4NR7HE5C;JH6SC57T;RJWDER5E;P99Z15C8;1B72LSN8',
      statusContent: 'dot',
      hash: '2A7Y6J32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2A7Y6J32'
    },
    {
      status: 210,
      sku: 'PM0075996',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284276',
      rtl_batch_array: '6284276',
      name_search: 'ALLMTARA',
      name: 'Alstroemeria Little Miss Tara',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YD3G1XC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YD3G1XC1'
    },
    {
      status: 210,
      sku: 'PM0075997',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284277',
      rtl_batch_array: '6284277',
      name_search: 'ALLMVANE',
      name: 'Alstroemeria Little Miss Vanessa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1GHLR5SS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GHLR5SS'
    },
    {
      status: 210,
      sku: 'PM0076649',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298476',
      rtl_batch_array: '6298476',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 5.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'ZK4RTDDG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK4RTDDG'
    },
    {
      status: 910,
      sku: 'PM0076228',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286679',
      rtl_batch_array: '6286679',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: '8BXHA3LB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8BXHA3LB'
    },
    {
      status: 210,
      sku: 'PM0076229',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300211',
      rtl_batch_array: '6300211, 6286680',
      name_search: 'LIVULGAR',
      name: 'Ligustrum vulgare',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 17500,
      chnn_stock_retail: 17560,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DPB3NXPC;XHRXBJH9;CGDTLBT4;JH765CLL;J4X7SL84;HV7S1279;HB8RNJY4;87SBP6LA;SKA5B37S;R7GYBBEW;TWD7VZ9P',
      statusContent: 'dot',
      hash: '1PKEG7VR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PKEG7VR'
    },
    {
      status: 210,
      sku: 'PM0076230',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 3,
      btch_active_retail: '6286681',
      rtl_batch_array: '6299855, 6286681, 6294851',
      name_search: 'PEALSPIR',
      name: "Perovskia atriplicifolia 'Little Spire'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 711,
      chnn_stock_retail: 4186,
      sppl_prcp: 1.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S19ZXK5H;W8KHXWYD;ETR3RCHC;Z4HVWP7R;JCZBG3ZA;ER291WLH;181B24B6;CWS39L2P',
      statusContent: 'dot',
      hash: 'DT2XHP6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT2XHP6L'
    },
    {
      status: 210,
      sku: 'PM0076663',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298491',
      rtl_batch_array: '6298491',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 977,
      chnn_stock_retail: 977,
      sppl_prcp: 5.433,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      statusContent: 'dot',
      hash: '9GWGXSN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GWGXSN3'
    },
    {
      status: 210,
      sku: 'PM0076231',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286683',
      rtl_batch_array: '6286683',
      name_search: 'PIFFLAME',
      name: "Pieris 'Forest Flame'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 488,
      chnn_stock_retail: 488,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9VN373R;4SJ32DDT;AEHP17HK;G4RZXEV9;RJNDXRCL;VPJTZ1EK;YX2CWJ5A;RLE2WWZL;HW3P8ZY8;WEPK7PA3;P8G14K1T;BT76YNBX;H4RBS75X;PJY9SZ15;76WCYB7Y;DWVVNT4T;8BGJC5R4;21DLZBS8;766H5B3L;ATCNA7SX;85KXL57K',
      statusContent: 'dot',
      hash: 'EY9ZBAET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY9ZBAET'
    },
    {
      status: 210,
      sku: 'PM0076235',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286690',
      rtl_batch_array: '6286690',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 5.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '82DHX9R5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82DHX9R5'
    },
    {
      status: 210,
      sku: 'PM0076237',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286692',
      rtl_batch_array: '6286692',
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_prcp: 3.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP1W33TG;HBYJJCC4',
      statusContent: 'dot',
      hash: 'YE36BYPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YE36BYPY'
    },
    {
      status: 210,
      sku: 'PM0076361',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350134',
      rtl_batch_array: '6350134',
      name_search: 'ERMEBEAL',
      name: "Erigeron 'Mrs E.H. Beale'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 257,
      chnn_stock_retail: 257,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V33K78SW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V33K78SW'
    },
    {
      status: 810,
      sku: 'PM0076364',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350057',
      rtl_batch_array: '6350057',
      name_search: 'ECPLEILA',
      name: "Echinacea purpurea 'Leilani'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZW7W5X8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZW7W5X8'
    },
    {
      status: 210,
      sku: 'PM0076366',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350060',
      rtl_batch_array: '6350060',
      name_search: 'ECMCHEES',
      name: "Echinacea 'Mac 'n' Cheese'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8JYJRWAK',
      statusContent: 'dot',
      hash: '4V8ECZ8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4V8ECZ8R'
    },
    {
      status: 210,
      sku: 'PM0076367',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350068',
      rtl_batch_array: '6350068',
      name_search: 'ECPOPEAR',
      name: "Echinacea p. 'Orange Pearl'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 318,
      chnn_stock_retail: 318,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7G9B8ZA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7G9B8ZA2'
    },
    {
      status: 210,
      sku: 'PM0076368',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350099',
      rtl_batch_array: '6350099',
      name_search: 'ECPVCUPC',
      name: "Echinacea purpurea 'Vanilla Cupcake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 268,
      chnn_stock_retail: 268,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SY7YLCG5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SY7YLCG5'
    },
    {
      status: 210,
      sku: 'PM0076369',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350109',
      rtl_batch_array: '6350109',
      name_search: 'ECSDLEMO',
      name: 'Echinacea Sunny Days Lemon',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 137,
      chnn_stock_retail: 137,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5DXZ4LWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DXZ4LWG'
    },
    {
      status: 810,
      sku: 'PM0076472',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307488',
      rtl_batch_array: '6307488',
      name_search: 'HYMMEMOU',
      name: "Hydrangea macrophylla 'Madame Emile Mouill\u00e8re'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JEXC1AVH;AD5JS2D4;WJYELNA2;K8J2HEY7;HH16PN2K;2S98RJJ1;HN9511TT;WAKSXK7Y;Z2XTC2D2',
      statusContent: 'dot',
      hash: 'H9HE17X5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9HE17X5'
    },
    {
      status: 210,
      sku: 'PM0076473',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307489',
      rtl_batch_array: '6307489',
      name_search: 'HYMMHEPB',
      name: "Hydrangea macrophylla 'Miss Hepburn'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WSEDS3LP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WSEDS3LP'
    },
    {
      status: 210,
      sku: 'PM0076474',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307491',
      rtl_batch_array: '6307491',
      name_search: 'HYMROTKE',
      name: "Hydrangea macrophylla 'Rotkehlchen'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ETTGREYR;5CG6K47R;L1B5W7C8;KK6ZACSV;G45WYBKL;1L93NLXB;1XXT79J2;K6A4HY15;P3DVTTPE;KJDAP7JY',
      statusContent: 'dot',
      hash: 'J8ZW6SKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8ZW6SKS'
    },
    {
      status: 210,
      sku: 'PM0076475',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307492',
      rtl_batch_array: '6307492',
      name_search: 'HYMSTHER',
      name: "Hydrangea macrophylla 'Soeur Th\u00e9r\u00e8se'",
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 149,
      chnn_stock_retail: 149,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P8NC46W6',
      statusContent: 'dot',
      hash: 'V749A1TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V749A1TB'
    },
    {
      status: 910,
      sku: 'PM0076476',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      rng_range_identifier: 'STE060C10',
      rng_range_description: 'Stem 60 cm C10',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'DL1HHNLS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DL1HHNLS'
    },
    {
      status: 210,
      sku: 'PM0076477',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369203',
      rtl_batch_array: '6369203',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '080STC10',
      rng_range_identifier: 'STE080C10',
      rng_range_description: 'Stem 80 cm C10',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 17.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: '6XGBBK2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XGBBK2C'
    },
    {
      status: 210,
      sku: 'PM0076478',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307810',
      rtl_batch_array: '6307810',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_prcp: 15.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: '7PT6ZHD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PT6ZHD5'
    },
    {
      status: 210,
      sku: 'PM0076479',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307811',
      rtl_batch_array: '6307811',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      sppl_size_code: '125150C74',
      rng_range_identifier: 'H125150C7.5',
      rng_range_description: 'H125 cm 150 cm C7.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 18.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: 'BY4NTL1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY4NTL1R'
    },
    {
      status: 810,
      sku: 'PM0041470',
      core_name: 'Plant',
      sppl_ean: '8720664812390',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6079258',
      rtl_batch_array: '6079258',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_prcp: 1.274,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: '93SWWEL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '93SWWEL3'
    },
    {
      status: 210,
      sku: 'PM0041468',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184118',
      rtl_batch_array: '6184118',
      name_search: 'WEFMBLAC',
      name: 'Weigela florida Minor Black',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 394,
      chnn_stock_retail: 394,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXXKE7GL;GPY1SLLJ;LYJCCVVT;CZBRLRZV;6G96SY1D;VAE53HB1;SB9ZDSBJ;73TK1E7D',
      statusContent: 'dot',
      hash: 'RER2ACV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RER2ACV9'
    },
    {
      status: 910,
      sku: 'PM0041469',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'WLNHSW4N',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WLNHSW4N'
    },
    {
      status: 210,
      sku: 'PM0076588',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295347',
      rtl_batch_array: '6295347',
      name_search: 'THOKOBOL',
      name: "Thuja occidentalis 'Kobold'",
      sppl_size_code: '080100C4',
      rng_range_identifier: 'H080100C4',
      sppl_stock_available: 1226,
      chnn_stock_retail: 1226,
      sppl_prcp: 4.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z73JPY1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z73JPY1P'
    },
    {
      status: 210,
      sku: 'PM0076589',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295350',
      rtl_batch_array: '6295350',
      name_search: 'TSCANADE',
      name: 'Tsuga canadensis',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_prcp: 6.639,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RPZ8RWAC;V56V3YV5;9WEK3J56',
      statusContent: 'dot',
      hash: 'ZSYDRA5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZSYDRA5L'
    },
    {
      status: 210,
      sku: 'PM0076638',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298464',
      rtl_batch_array: '6298464',
      name_search: 'CEIVICTO',
      name: "Ceanothus impressus 'Victoria'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 863,
      chnn_stock_retail: 863,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44K5NBYW;8TNED8P4;GP2AJCGE;LD73LVKY;EHZLC4K7;P3H93PRW;TZSYEV99;KJ9P9TGP;AS1ZD1PC;1G2AYZEE;BGHY1W5K;G1VRTS2W;12TK9EVS;LZV52BW7;D6NCLPGR',
      statusContent: 'dot',
      hash: 'XRJXAH64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XRJXAH64'
    },
    {
      status: 210,
      sku: 'PM0076639',
      core_name: 'Plant',
      btch_manufacturer: 1947,
      rtl_batch_array_total: 2,
      btch_active_retail: '5207268',
      rtl_batch_array: '5207268, 6298465',
      name_search: 'CEIVICTO',
      name: "Ceanothus impressus 'Victoria'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 2000,
      chnn_stock_retail: 6249,
      sppl_prcp: 4.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '44K5NBYW;8TNED8P4;GP2AJCGE;LD73LVKY;EHZLC4K7;P3H93PRW;TZSYEV99;KJ9P9TGP;AS1ZD1PC;1G2AYZEE;BGHY1W5K;G1VRTS2W;12TK9EVS;LZV52BW7;D6NCLPGR',
      imageBatch: '4L3NY8Y1',
      statusContent: 'dot',
      hash: 'P7BPW9DD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P7BPW9DD'
    },
    {
      status: 210,
      sku: 'PM0076640',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298466',
      rtl_batch_array: '6298466',
      name_search: 'CESFLURR',
      name: "Ceanothus 'Snow Flurries'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_prcp: 3.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P84B1BP2;XX5N4RD4;45RZDRLR',
      statusContent: 'dot',
      hash: 'PX65DS56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PX65DS56'
    },
    {
      status: 210,
      sku: 'PM0076641',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298467',
      rtl_batch_array: '6298467',
      name_search: 'CETUXEDO',
      name: "Ceanothus 'Tuxedo'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 14.725,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3WSTCA9;H1HVSDPJ;SEBNV73X;TSCYBA3E;DX8171HE;SWDG2WRG;ZWY96Y9D;1C1V7BCS;RAT478P3;GRTGD51C;WA3J18GH;SR92R797',
      statusContent: 'dot',
      hash: '6JHJVLLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6JHJVLLN'
    },
    {
      status: 210,
      sku: 'PM0076642',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298468',
      rtl_batch_array: '6298468',
      name_search: 'CETUXEDO',
      name: "Ceanothus 'Tuxedo'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 285,
      chnn_stock_retail: 285,
      sppl_prcp: 4.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3WSTCA9;H1HVSDPJ;SEBNV73X;TSCYBA3E;DX8171HE;SWDG2WRG;ZWY96Y9D;1C1V7BCS;RAT478P3;GRTGD51C;WA3J18GH;SR92R797',
      statusContent: 'dot',
      hash: '5GD8KEV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GD8KEV1'
    },
    {
      status: 210,
      sku: 'PM0076643',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298469',
      rtl_batch_array: '6298469',
      name_search: 'CETUXEDO',
      name: "Ceanothus 'Tuxedo'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P3WSTCA9;H1HVSDPJ;SEBNV73X;TSCYBA3E;DX8171HE;SWDG2WRG;ZWY96Y9D;1C1V7BCS;RAT478P3;GRTGD51C;WA3J18GH;SR92R797',
      statusContent: 'dot',
      hash: 'CN13NSEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CN13NSEP'
    },
    {
      status: 210,
      sku: 'PM0083825',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356954',
      rtl_batch_array: '6356954',
      name_search: 'GEBROOKS',
      name: "Geranium 'Brookside'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 975,
      chnn_stock_retail: 975,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2ZR571V;L1HDCC73',
      statusContent: 'dot',
      hash: 'G3J1YXDC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3J1YXDC'
    },
    {
      status: 210,
      sku: 'PM0083826',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356955',
      rtl_batch_array: '6356955',
      name_search: 'GEJBLUE',
      name: "Geranium 'Johnson's Blue'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H8Y833LL;8KYS292B;5Y865CZD;SNTR5ZAT;H9JAND4T;NAJCXDPP;JBC8W97N;4W67GJP2;JWYSAJEN;CS5G2HB9;B66LCXLN;P8H3XAAB;HZ7K52ZS;TRGRSEXB;HN27NEPC;PJG195K9',
      statusContent: 'dot',
      hash: 'C5HLDNVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C5HLDNVP'
    },
    {
      status: 210,
      sku: 'PM0083827',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356956',
      rtl_batch_array: '6356956',
      name_search: 'GESIRAK',
      name: "Geranium 'Sirak'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PAJBYA8R;RJ5DDC7G;YLE75N34',
      statusContent: 'dot',
      hash: 'RAE7CHVC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAE7CHVC'
    },
    {
      status: 810,
      sku: 'PM0076648',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298475',
      rtl_batch_array: '6298475',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: '1RTZV87P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RTZV87P'
    },
    {
      status: 210,
      sku: 'PM0076650',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298477',
      rtl_batch_array: '6298477',
      name_search: 'COAMIRAC',
      name: 'Cornus alba Miracle',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 872,
      chnn_stock_retail: 872,
      sppl_prcp: 4.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4HTRZ129',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HTRZ129'
    },
    {
      status: 210,
      sku: 'PM0076651',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298478',
      rtl_batch_array: '6298478',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 5.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'GBWPBE43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBWPBE43'
    },
    {
      status: 210,
      sku: 'PM0084190',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363464',
      rtl_batch_array: '6363464',
      name_search: 'MIGMEIDL',
      name: "Miscanthus giganteus 'Meidl'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 391,
      chnn_stock_retail: 391,
      sppl_order_minimum: 3,
      sppl_prcp: 1.917,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1R4PCTPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R4PCTPP'
    },
    {
      status: 210,
      sku: 'PM0084191',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363465',
      rtl_batch_array: '6363465',
      name_search: 'PAVSUGAR',
      name: "Paeonia (I) 'Visions of Sugarplums'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 419,
      chnn_stock_retail: 419,
      sppl_order_minimum: 3,
      sppl_prcp: 5.098,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A1K8SPRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1K8SPRW'
    },
    {
      status: 210,
      sku: 'PM0076655',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298482',
      rtl_batch_array: '6298482',
      name_search: 'COKNATIO',
      name: "Cornus kousa 'National'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_prcp: 11.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '22DYBD3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22DYBD3Z'
    },
    {
      status: 210,
      sku: 'PM0084192',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363466',
      rtl_batch_array: '6363466',
      name_search: 'PABIRTHD',
      name: "Paeonia (HH) 'Birthday'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 3,
      sppl_prcp: 5.098,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3L7BXN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3L7BXN9'
    },
    {
      status: 210,
      sku: 'PM0084193',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363467',
      rtl_batch_array: '6363467',
      name_search: 'PADTELL',
      name: "Paeonia (LD) 'Do Tell'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 129,
      chnn_stock_retail: 129,
      sppl_order_minimum: 3,
      sppl_prcp: 4.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGWEXBAH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGWEXBAH'
    },
    {
      status: 810,
      sku: 'PM0076659',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298486',
      rtl_batch_array: '6298486',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 62,
      chnn_stock_retail: 62,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'E27XSKBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E27XSKBV'
    },
    {
      status: 210,
      sku: 'PM0076660',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298488',
      rtl_batch_array: '6298488',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 971,
      chnn_stock_retail: 971,
      sppl_prcp: 18.343,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: 'S1NW8XJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1NW8XJ3'
    },
    {
      status: 210,
      sku: 'PM0076661',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298489',
      rtl_batch_array: '6298489',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 914,
      chnn_stock_retail: 914,
      sppl_prcp: 8.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: '8GSBZG88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GSBZG88'
    },
    {
      status: 210,
      sku: 'PM0076662',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298490',
      rtl_batch_array: '6298490',
      name_search: 'COSELLOA',
      name: 'Cortaderia selloana',
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_prcp: 17.693,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B1YGYR2S;5AKT2ZG5;CL9LAK5R;W2EA6GP1;8WLLZGTA;W99ZP7H9;2E43H77B;22ECRC7W;2KGNDCXY;L7AZ1HWB;NCXZ7ECD;ERJA2AZ8;2CR6AAC8;N1VD6YK2',
      statusContent: 'dot',
      hash: '4B2WY8PP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B2WY8PP'
    },
    {
      status: 210,
      sku: 'PM0076664',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298492',
      rtl_batch_array: '6298492',
      name_search: 'COACONTO',
      name: "Corylus avellana 'Contorta'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3399,
      chnn_stock_retail: 3399,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D7SBSBDZ;LP5YVNY2;V9LDDDV7;C7YXBN77;8GPZCX5K;XLVS99VL;4LL177XA;GGZ2TRSW;4YAYN19T;R8Z3565L;89PTDCLJ;T6EZ8EZ7;J47BVT9N;EZWY2PR5;WZY2ALAG;EPD42XRX',
      statusContent: 'dot',
      hash: 'WCB79T98',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCB79T98'
    },
    {
      status: 210,
      sku: 'PM0076665',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298493',
      rtl_batch_array: '6298493',
      name_search: 'COAMEDUS',
      name: 'Corylus avellana Medusa',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 797,
      chnn_stock_retail: 797,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9ACV58KW;L4WE7YJ8;WDXWCT23;B851YERK;1DZC9DN3;1B43G4PA;XGV5P364;HYWCWNR4;XYHHHJBR;92XS3G3D',
      statusContent: 'dot',
      hash: '2D9BT7N5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2D9BT7N5'
    },
    {
      status: 210,
      sku: 'PM0076669',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298497',
      rtl_batch_array: '6298497',
      name_search: 'COCYLADY',
      name: "Cotinus coggygria 'Young Lady'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 5.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EHKVAYDW;7D24RW6K;RY5BWZZT;6CXND4DH',
      statusContent: 'dot',
      hash: 'HJREXRB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJREXRB9'
    },
    {
      status: 210,
      sku: 'PM0076670',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298498',
      rtl_batch_array: '6298498',
      name_search: 'COABLACK',
      name: "Cotoneaster apiculatus 'Blackburn'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 137,
      chnn_stock_retail: 137,
      sppl_prcp: 23.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9GXNCDWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9GXNCDWP'
    },
    {
      status: 210,
      sku: 'PM0076671',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298503',
      rtl_batch_array: '6298503',
      name_search: 'ABGRANDI',
      name: 'Abelia grandiflora',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4YDH9BPN;7KHK1D36',
      statusContent: 'dot',
      hash: 'EVRA8ASX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVRA8ASX'
    },
    {
      status: 210,
      sku: 'PM0076668',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298496',
      rtl_batch_array: '6298496',
      name_search: 'COMPURPU',
      name: "Corylus maxima 'Purpurea'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 5.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GSSG2XKA;R5952436;HTCB8A75;CHBBTL6W;KJY5DA67;6EJJTVSJ;D18NH15Y;YHHPD2XC;9X13B88A;ZKX3KBZP;X547KTWE;W7D5X9GZ;YYGB3NR8;8HDGZDS2',
      statusContent: 'dot',
      hash: 'J8ZK9NV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8ZK9NV8'
    },
    {
      status: 810,
      sku: 'PM0076678',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298511',
      rtl_batch_array: '6298511',
      name_search: 'ACPGGREE',
      name: "Acer palmatum 'Going Green'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 67,
      chnn_stock_retail: 67,
      sppl_prcp: 27.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GVSCXZ3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVSCXZ3N'
    },
    {
      status: 210,
      sku: 'PM0076679',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298512',
      rtl_batch_array: '6298512',
      name_search: 'HEHALEXA',
      name: 'Hebe Hebedonna Alexa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2528,
      chnn_stock_retail: 2528,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7GKADSRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GKADSRK'
    },
    {
      status: 210,
      sku: 'PM0076680',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298513',
      rtl_batch_array: '6298513',
      name_search: 'HEHALEXA',
      name: 'Hebe Hebedonna Alexa',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 682,
      chnn_stock_retail: 682,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8Z6J26L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Z6J26L5'
    },
    {
      status: 810,
      sku: 'PM0084194',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363468',
      rtl_batch_array: '6363468',
      name_search: 'RHHBLUT',
      name: "Rheum 'Holsteiner Blut'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 1.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L1T4Z5EP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1T4Z5EP'
    },
    {
      status: 210,
      sku: 'PM0084195',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363469',
      rtl_batch_array: '6363469',
      name_search: 'AGESAPPH',
      name: 'Agapanthus Ever Sapphire',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 288,
      chnn_stock_retail: 288,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XAP8TPDS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAP8TPDS'
    },
    {
      status: 910,
      sku: 'PM0084196',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALRSCAPR',
      name: "Alcea rosea 'Spring Celebrities Apricot'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'SNV93DX4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SNV93DX4'
    },
    {
      status: 210,
      sku: 'PM0076667',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298495',
      rtl_batch_array: '6298495',
      name_search: 'COARMAJE',
      name: "Corylus avellana 'Red Majestic'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3813,
      chnn_stock_retail: 3813,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S9YZKEVX;6CDJD15W;126G5LKS;63ATDJGR;LR4C43SP;5YV8H4Z7;GY5T3NZA;4WKEAEY1;49LZ1GG7;TBHHXCVT;KHZWAL8G;EN3YDE39',
      statusContent: 'dot',
      hash: '7ZDLLE71',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZDLLE71'
    },
    {
      status: 210,
      sku: 'PM0077012',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299762',
      rtl_batch_array: '6299762',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 764,
      chnn_stock_retail: 764,
      sppl_prcp: 3.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'Y9NB5CNK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9NB5CNK'
    },
    {
      status: 910,
      sku: 'PM0077013',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAMIRAC',
      name: 'Cornus alba Miracle',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      statusContent: 'dot',
      hash: 'R3LP3CGP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R3LP3CGP'
    },
    {
      status: 810,
      sku: 'PM0076681',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298514',
      rtl_batch_array: '6298514',
      name_search: 'ACPGLOBO',
      name: "Acer platanoides 'Globosum'",
      sppl_size_code: '150STC12',
      rng_range_identifier: 'STE150C12',
      rng_range_description: 'Stem 150 cm C12',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 34.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NT6LGYKL;LSZ3W6TW;K4Y3T3ZV;5NV757PG;1JHTVG4N;5YGSEVSD;PWSSDWRC;739AVVVL;291DEPXP;DL5G3BA9;V34WHSTC;WNLSY1E6',
      statusContent: 'dot',
      hash: 'W6ENZS26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6ENZS26'
    },
    {
      status: 210,
      sku: 'PM0076682',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298515',
      rtl_batch_array: '6298515',
      name_search: 'ACPGLOBO',
      name: "Acer platanoides 'Globosum'",
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 355,
      chnn_stock_retail: 355,
      sppl_prcp: 31.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NT6LGYKL;LSZ3W6TW;K4Y3T3ZV;5NV757PG;1JHTVG4N;5YGSEVSD;PWSSDWRC;739AVVVL;291DEPXP;DL5G3BA9;V34WHSTC;WNLSY1E6',
      statusContent: 'dot',
      hash: 'RS3BWA9G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS3BWA9G'
    },
    {
      status: 210,
      sku: 'PM0076683',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298516',
      rtl_batch_array: '6298516',
      name_search: 'ANPBICE',
      name: "Andromeda polifolia 'Blue Ice'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 10575,
      chnn_stock_retail: 10575,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JK4AY89J;S75DWC4K;D72VJB5G;HHECGGYP;6J4RHVRK;4Z28VW5Z;72ECN1VB;T2391BK2;T6R4CCXJ;C4JB33J8;JA98Y14H;WYSE99A5;J4AJA8BK;PVWLH1NX;VAVZHZR5;HNH59EWB;S44W2BHY;77JZWT7D;PWXVG1YB;HWZAA26Y;WESSTJD5;RLHP5XK5;1445XJDH',
      statusContent: 'dot',
      hash: 'T9G94GTD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9G94GTD'
    },
    {
      status: 210,
      sku: 'PM0076233',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286686',
      rtl_batch_array: '6286686',
      name_search: 'PIJMFIRE',
      name: "Pieris japonica 'Mountain Fire'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_prcp: 5.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K59KBX67;ZN5LBDJH;YH3NYB67;5CSSPEJ7;T61ZZ6VT;YST95X1L;5169DJYB;T8WTZWJG;184H62YB;KX78D9DZ;KWNR38EJ;4XHA2TD4;LBZT2N1W;PTNB5JCE;6HC7CBW8;WD4W897A;L12EGJ4G;KRGWTZLS;R39GWCKN;9C1R9EH9;63CASLAH',
      statusContent: 'dot',
      hash: 'S3D5T9XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S3D5T9XR'
    },
    {
      status: 210,
      sku: 'PM0075989',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284268',
      rtl_batch_array: '6284268',
      name_search: 'AGKRED',
      name: "Agastache 'Kudos Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JB796Z47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB796Z47'
    },
    {
      status: 210,
      sku: 'PM0075990',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284270',
      rtl_batch_array: '6284270',
      name_search: 'AGMORELL',
      name: "Agastache 'Morello'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JETXH25B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JETXH25B'
    },
    {
      status: 210,
      sku: 'PM0075982',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284228',
      rtl_batch_array: '6284228',
      name_search: 'ALMISILK',
      name: "Alchemilla mollis 'Irish Silk'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 24960,
      chnn_stock_retail: 24960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.526,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E2H8J23C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2H8J23C'
    },
    {
      status: 210,
      sku: 'PM0075993',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284273',
      rtl_batch_array: '6284273',
      name_search: 'ALLMCAND',
      name: "Alstroemeria 'Little Miss Candy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZYNL6KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZYNL6KA'
    },
    {
      status: 210,
      sku: 'PM0075994',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284274',
      rtl_batch_array: '6284274',
      name_search: 'ALLMDAVI',
      name: 'Alstroemeria Little Miss Davina',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y5PXZV2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y5PXZV2V'
    },
    {
      status: 210,
      sku: 'PM0075995',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284275',
      rtl_batch_array: '6284275',
      name_search: 'ALLMROSA',
      name: 'Alstroemeria Little Miss Rosalind',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 133,
      chnn_stock_retail: 133,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BKH6RPXL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKH6RPXL'
    },
    {
      status: 210,
      sku: 'PM0075998',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284279',
      rtl_batch_array: '6284279',
      name_search: 'ALLMZOE',
      name: 'Alstroemeria Little Miss Zo\u00eb',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_order_minimum: 3,
      sppl_prcp: 2.452,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BH4NYCCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BH4NYCCG'
    },
    {
      status: 210,
      sku: 'PM0076359',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349988',
      rtl_batch_array: '6349988',
      name_search: 'DICRUENT',
      name: 'Dianthus cruentus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 877,
      chnn_stock_retail: 877,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SP9VGP8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SP9VGP8P'
    },
    {
      status: 210,
      sku: 'PM0076365',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350058',
      rtl_batch_array: '6350058',
      name_search: 'ECLDROP',
      name: "Echinacea 'Lemon Drop'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XEAG53WY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XEAG53WY'
    },
    {
      status: 810,
      sku: 'PM0076362',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350154',
      rtl_batch_array: '6350154',
      name_search: 'EUMAUGUS',
      name: "Eupatorium maculatum 'Augustrubin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 66,
      chnn_stock_retail: 66,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBLL4KKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBLL4KKG'
    },
    {
      status: 810,
      sku: 'PM0076363',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370353',
      rtl_batch_array: '6370353',
      name_search: 'EUMGLUTB',
      name: "Eupatorium maculatum 'Glutball'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XJDYY6PJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJDYY6PJ'
    },
    {
      status: 210,
      sku: 'PM0076236',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286691',
      rtl_batch_array: '6286691',
      name_search: 'WEFFPURP',
      name: "Weigela florida 'Foliis Purpureis'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 386,
      chnn_stock_retail: 386,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP1W33TG;HBYJJCC4',
      statusContent: 'dot',
      hash: 'R4TTD9H3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R4TTD9H3'
    },
    {
      status: 810,
      sku: 'PM0076227',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6286676',
      rtl_batch_array: '6286676, 6338070',
      name_search: 'HECDVARI',
      name: "Hedera colchica 'Dentata Variegata'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 27,
      chnn_stock_retail: 917,
      sppl_prcp: 2.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NTAH3ANZ;VTA8ASSZ;Y5GEEDZW;RRRXLAPP;4KWTP8C5',
      statusContent: 'dot',
      hash: '7RB7WGHY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RB7WGHY'
    },
    {
      status: 210,
      sku: 'PM0084197',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363495',
      rtl_batch_array: '6363495',
      name_search: 'ALLIRE',
      name: "Alstroemeria 'Lir\u00e9'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXZNV6CD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXZNV6CD'
    },
    {
      status: 910,
      sku: 'PM0084198',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACMMRTER',
      name: "Achillea millefolium 'M. Rock Terracotta'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'GY9KPAZL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GY9KPAZL'
    },
    {
      status: 910,
      sku: 'PM0084199',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ANCEREFO',
      name: 'Anthriscus cerefolium',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        '81EGJD6R;A2ABPR61;N566D3GA;GA52A7HZ;AXL46DD6;PVC5Z3WV;H9YV5RAC;SPTC4YZA;S3KZ74T3;634DE3X6;ZL3R9ESH;J92JW55S;SSAR1Y44;4KKEWRX2;AAVH1D8K;YC86RL9C;Y2S5KTHN;G4KBX1YJ;TCD3E1DN;PCL29K5B;5CPH7DV3',
      statusContent: 'dot',
      hash: '9L6LG5KS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9L6LG5KS'
    },
    {
      status: 910,
      sku: 'PM0084200',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALNUTANS',
      name: 'Allium nutans',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HB8D22NV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HB8D22NV'
    },
    {
      status: 910,
      sku: 'PM0084201',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ASGAGLEN',
      name: "Aster glehnii 'Agleni'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'PGKAT2XC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PGKAT2XC'
    },
    {
      status: 910,
      sku: 'PM0084202',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AUFIONA',
      name: "Aubrieta 'Fiona'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '9CY4LNXS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9CY4LNXS'
    },
    {
      status: 910,
      sku: 'PM0084203',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AURDYKE',
      name: "Aubrieta 'Red Dyke'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'WYSE3KJA',
      statusContent: 'dot',
      hash: 'KSYA5VZ9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KSYA5VZ9'
    },
    {
      status: 910,
      sku: 'PM0084204',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ARCORDAT',
      name: 'Aralia cordata',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      statusContent: 'dot',
      hash: 'ZE2Y4XRZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZE2Y4XRZ'
    },
    {
      status: 910,
      sku: 'PM0084205',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHRVELVE',
      name: "Chrysanthemum (I) 'Red Velvet'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '1RZTAZBT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1RZTAZBT'
    },
    {
      status: 910,
      sku: 'PM0084206',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CHDEDINB',
      name: "Chrysanthemum (R) 'Duchess of Edinburgh'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'JLS4D1S5',
      statusContent: 'dot',
      hash: 'CD9DTEBR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CD9DTEBR'
    },
    {
      status: 910,
      sku: 'PM0084207',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BEDSAKUR',
      name: 'Bergenia Dragonfly Sakura',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'NWCX7KH3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NWCX7KH3'
    },
    {
      status: 210,
      sku: 'PM0084208',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363513',
      rtl_batch_array: '6363513',
      name_search: 'DEMLIGHT',
      name: "Delphinium 'Moon Light'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y1S1XNZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y1S1XNZX'
    },
    {
      status: 910,
      sku: 'PM0084209',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DIDCREAM',
      name: "Dianthus (PL) 'Devon Cream'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'NAZCGKS3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NAZCGKS3'
    },
    {
      status: 210,
      sku: 'PM0077058',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295588',
      rtl_batch_array: '6295588',
      name_search: 'GERBSUNR',
      name: 'Geranium Blue Sunrise',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 212,
      chnn_stock_retail: 212,
      sppl_order_minimum: 3,
      sppl_prcp: 2.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AC28YRZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AC28YRZL'
    },
    {
      status: 910,
      sku: 'PM0076001',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ANHFBELL',
      name: 'Anemone hupehensis Fantasy Belle',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'G1687Z3Y',
      statusContent: 'dot',
      hash: 'SJ4BV9DK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SJ4BV9DK'
    },
    {
      status: 210,
      sku: 'PM0083828',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356957',
      rtl_batch_array: '6356957',
      name_search: 'GETMONST',
      name: "Geranium 'Tiny Monster'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7YYRP71K',
      statusContent: 'dot',
      hash: 'A8AZZP5J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8AZZP5J'
    },
    {
      status: 210,
      sku: 'PM0076239',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286694',
      rtl_batch_array: '6286694',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 314,
      chnn_stock_retail: 314,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'KE7DNV4S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE7DNV4S'
    },
    {
      status: 210,
      sku: 'PM0083829',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356958',
      rtl_batch_array: '6356958',
      name_search: 'GECBIOKO',
      name: "Geranium cantabrigiense 'Biokovo'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DVDG9E94;WVXXXA4Z;GPH756PG;5EXRA7WG;DXRGE7XS;R2N72X5P;ZAN9PRYS;YLEGBWGZ;L16JD9VE;VNT4AYH4;KYDR38NK;P9723XS5',
      statusContent: 'dot',
      hash: '9X2J7EW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9X2J7EW8'
    },
    {
      status: 210,
      sku: 'PM0076241',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6286696',
      rtl_batch_array: '6286696',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.061,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: '5SKXS38P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SKXS38P'
    },
    {
      status: 210,
      sku: 'PM0083830',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356959',
      rtl_batch_array: '6356959',
      name_search: 'GECKARMI',
      name: "Geranium cantabrigiense 'Karmina'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KRT57258;81LT3WDN;7D1LEZY6;7HXGSVVT;VGN9WECW;DJEHPDDB;TH816VLC;THTTYTV4;LPP74BZD;G3BA1HJE;Y8YKSBL8;TSL8Y4Y5;P4436YKA;JZ2J8ABD;RE6JAAS2;DX5JBD8V;JLKYLH44;2418B6PV;ZCA8SV68;HT399TP4',
      statusContent: 'dot',
      hash: 'L1WAZ4E1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1WAZ4E1'
    },
    {
      status: 210,
      sku: 'PM0083831',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356960',
      rtl_batch_array: '6356960',
      name_search: 'GECSOLA',
      name: "Geranium cantabrigiense 'St Ola'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B4NEZK75',
      statusContent: 'dot',
      hash: 'KJHETZVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KJHETZVA'
    },
    {
      status: 210,
      sku: 'PM0083832',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356961',
      rtl_batch_array: '6356961',
      name_search: 'GEEWPINK',
      name: "Geranium endressii 'Wargrave Pink'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TE3GS59A',
      statusContent: 'dot',
      hash: '1VJTE88B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VJTE88B'
    },
    {
      status: 210,
      sku: 'PM0076374',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350326',
      rtl_batch_array: '6350326',
      name_search: 'HELHBHEA',
      name: "Heliopsis h. 'Bleeding Hearts'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 413,
      chnn_stock_retail: 413,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YXG7DPDL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXG7DPDL'
    },
    {
      status: 210,
      sku: 'PM0083833',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356962',
      rtl_batch_array: '6356962',
      name_search: 'GEMBVARI',
      name: "Geranium macrorrhizum 'Bevan's Variety'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8YYPL3HW',
      statusContent: 'dot',
      hash: 'RNP48BNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RNP48BNX'
    },
    {
      status: 810,
      sku: 'PM0076485',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291901',
      rtl_batch_array: '6291901',
      name_search: 'CIPURPUR',
      name: 'Cistus purpureus',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1EG1P1L9;EHD2T53W;R9Z783TA;6NK59XNZ;G7KT6BJ1;W9A5X217',
      statusContent: 'dot',
      hash: 'T72YB8EL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T72YB8EL'
    },
    {
      status: 810,
      sku: 'PM0076486',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291902',
      rtl_batch_array: '6291902',
      name_search: 'GALFWHIT',
      name: "Gaura lindh. 'Flamingo White'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 81,
      chnn_stock_retail: 81,
      sppl_prcp: 3.383,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8EXREHD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8EXREHD'
    },
    {
      status: 210,
      sku: 'PM0076243',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6286699',
      rtl_batch_array: '6286699, 6337781',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 213,
      chnn_stock_retail: 718,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      statusContent: 'dot',
      hash: 'H412D2XK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H412D2XK'
    },
    {
      status: 210,
      sku: 'PM0041522',
      core_name: 'Plant',
      sppl_ean: '8720664685284',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420602',
      rtl_batch_array: '5420602',
      name_search: 'CLALPINA',
      name: 'Clematis alpina',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2313,
      chnn_stock_retail: 2313,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2C7PLSEZ;AE5XEJS4;55X28X29;SAZB1E3B;SP1BKTHH;GK1NTCX9;976R9WLB;4WLYX3XG',
      statusContent: 'dot',
      hash: '8B93J69E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8B93J69E'
    },
    {
      status: 210,
      sku: 'PM0041538',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284936',
      rtl_batch_array: '6284936',
      name_search: 'CLORIENT',
      name: 'Clematis orientalis',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9B3218E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9B3218E'
    },
    {
      status: 210,
      sku: 'PM0041571',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184140',
      rtl_batch_array: '6184140',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 2920,
      chnn_stock_retail: 2920,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: '62A7KV8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62A7KV8W'
    },
    {
      status: 810,
      sku: 'PM0076483',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291898',
      rtl_batch_array: '6291898',
      name_search: 'CICRETIC',
      name: 'Cistus creticus',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HKD6EYJ;67R8ZBGS',
      statusContent: 'dot',
      hash: 'ETVRD7CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETVRD7CZ'
    },
    {
      status: 210,
      sku: 'PM0076373',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350296',
      rtl_batch_array: '6350296',
      name_search: 'HEKUPFER',
      name: "Helenium 'Kupfersprudel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S9BV6SBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S9BV6SBH'
    },
    {
      status: 210,
      sku: 'PM0076684',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298517',
      rtl_batch_array: '6298517',
      name_search: 'ANPBLAGO',
      name: "Andromeda polifolia 'Blue Lagoon'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 3749,
      chnn_stock_retail: 3749,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9HTYSVA',
      statusContent: 'dot',
      hash: 'XCPBADJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XCPBADJE'
    },
    {
      status: 210,
      sku: 'PM0076685',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298518',
      rtl_batch_array: '6298518',
      name_search: 'ANPCOMPA',
      name: "Andromeda polifolia 'Compacta'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 5432,
      chnn_stock_retail: 5432,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DHR6WDW;49K2T5N3;7BHD3Z3E;VJR4D2A1;5E2EC5RE;RN5JNPDS;9ELGTAZ9;ENYNN284;P4ZHW4PZ',
      statusContent: 'dot',
      hash: 'VN1XL8DB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VN1XL8DB'
    },
    {
      status: 210,
      sku: 'PM0076686',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298519',
      rtl_batch_array: '6298519',
      name_search: 'AUJVARIE',
      name: "Aucuba japonica 'Variegata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 3.721,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2TZ3ZRL6;P4N99KS7;Z8V37JDH;6AY43H8K;RBRG2DGR;BDT5H9JR;DB3XYHAL;BE8A2XYT;T88CDS6W;9T1J16BS;4H9D5NX5;6D1R3A22;DGVN4JGA;SBKPXR7Y;JHY6NW51;6GKA2RL2;SZVLVD11;PV158LR8;BBG1VJC3;7SSTHW1A;Z9D7K78N',
      statusContent: 'dot',
      hash: 'RBEZP7VT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBEZP7VT'
    },
    {
      status: 210,
      sku: 'PM0076687',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298521',
      rtl_batch_array: '6298521',
      name_search: 'BEFTELST',
      name: "Berberis frikartii 'Telstar'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRZG3LT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRZG3LT2'
    },
    {
      status: 210,
      sku: 'PM0076688',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298522',
      rtl_batch_array: '6298522',
      name_search: 'BETHARLE',
      name: "Berberis thunbergii 'Harlequin'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 3.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D98GK36V;TBE54L4S;8RXW31Y2;S2KYHWWS;7GXXZNXV;RDJNNXWG;W1W5RAKN;6GKYN979;6Z9NNZLH;ECLC9BW8;294W3TDA;ER8S7H4K',
      statusContent: 'dot',
      hash: 'JCBTZYCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCBTZYCB'
    },
    {
      status: 210,
      sku: 'PM0083834',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356963',
      rtl_batch_array: '6356963',
      name_search: 'GEMIVARI',
      name: "Geranium macrorrhizum 'Ingwersen's Variety'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BEHZP13D;1L15ZB4E;5HE2B34B;XTK5BB5W;NH36KGLW;XW4PYGV3;1XD4B9D7;L4BV6YDW;E7XZZ3WL;YEZKBGZS;CZ32WC7J;N76GA3TX;XPPYPY4H;T2ETJVDT;9XSVE9PW;H5WTB4D1;DD9VEP3N',
      statusContent: 'dot',
      hash: '6RJ9KA23',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RJ9KA23'
    },
    {
      status: 810,
      sku: 'PM0076690',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298524',
      rtl_batch_array: '6298524',
      name_search: 'BENSCASC',
      name: "Betula nigra 'Summer Cascade'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 54,
      chnn_stock_retail: 54,
      sppl_prcp: 47.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T9PAGD3W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9PAGD3W'
    },
    {
      status: 210,
      sku: 'PM0083835',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356964',
      rtl_batch_array: '6356964',
      name_search: 'GEMSPESS',
      name: "Geranium macrorrhizum 'Spessart'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HDAB2S7T;G7REDB73;2ZHA3EEG;6KD5XJXT;6C7EK5RV',
      statusContent: 'dot',
      hash: 'R71B6B11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R71B6B11'
    },
    {
      status: 210,
      sku: 'PM0083836',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356965',
      rtl_batch_array: '6356965',
      name_search: 'GESALBUM',
      name: "Geranium sanguineum 'Album'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWW894AG;H339Y9JL;BJAD93HB;8N3G6J8Y;BN3YAHX6;K3D4B4Z8;5JT3ZVH6',
      statusContent: 'dot',
      hash: '5LT92KYG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5LT92KYG'
    },
    {
      status: 210,
      sku: 'PM0083837',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356966',
      rtl_batch_array: '6356966',
      name_search: 'GESELSBE',
      name: "Geranium sanguineum 'Elsbeth'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VPE1ZDCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPE1ZDCC'
    },
    {
      status: 210,
      sku: 'PM0083838',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356967',
      rtl_batch_array: '6356967',
      name_search: 'GESMFREI',
      name: "Geranium sanguineum 'Max Frei'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WBYASXG1;6H7XNWSD;SEB7R9LS;KSDHVT2L;N1TAX1SV;NNKE2BWR;5LXCW5PA;G9739E15;6CHZLZ1A;LNLT96AE;DAZJERZT',
      statusContent: 'dot',
      hash: 'KT44C8R3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KT44C8R3'
    },
    {
      status: 210,
      sku: 'PM0083839',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356968',
      rtl_batch_array: '6356968',
      name_search: 'GEUTRIFL',
      name: 'Geum triflorum',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZAD6WCBY;K96Y9W7C;HX68WWK9;NNVZ9W7Y;1T65VAH3',
      statusContent: 'dot',
      hash: 'Z4JK1SB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4JK1SB4'
    },
    {
      status: 210,
      sku: 'PM0083840',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356969',
      rtl_batch_array: '6356969',
      name_search: 'HELMBEAU',
      name: "Helenium 'Moerheim Beauty'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BC2XLJ8X;E938N398;NZRE4ASR;67TJGVZ8;BJ4HV1LH;JDJ8AS9V;BHC3ETJW;WED4CXX3;XCSW959Y;DZBL1TS2;W1757Y63',
      statusContent: 'dot',
      hash: '4RACAY5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RACAY5L'
    },
    {
      status: 810,
      sku: 'PM0076697',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298535',
      rtl_batch_array: '6298535',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_prcp: 29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: 'D2LX99TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D2LX99TL'
    },
    {
      status: 210,
      sku: 'PM0076698',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298536',
      rtl_batch_array: '6298536',
      name_search: 'CABPROFU',
      name: "Callicarpa bodinieri 'Profusion'",
      sppl_size_code: '040STC5',
      rng_range_identifier: 'STE040C5',
      rng_range_description: 'Stem 40 cm C5',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_prcp: 10.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VTK7LKNA;2NG2CG7Z;XRADWC48;AV6ARLPZ;ZHZE86TG;HTBHG1ZZ;EHEE24NV;ZDW7SLJ3;PLH47RLD;VSAD1CDK;NTLN8YV1;SXGB2YJ1;T1V479ZP',
      statusContent: 'dot',
      hash: '1YJ18ZT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YJ18ZT2'
    },
    {
      status: 210,
      sku: 'PM0076699',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298537',
      rtl_batch_array: '6298537',
      name_search: 'CAJLEUCO',
      name: "Callicarpa japonica 'Leucocarpa'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 974,
      chnn_stock_retail: 974,
      sppl_prcp: 4.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RYZ6V3A5',
      statusContent: 'dot',
      hash: 'HTP3PLW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTP3PLW5'
    },
    {
      status: 210,
      sku: 'PM0076702',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298542',
      rtl_batch_array: '6298542',
      name_search: 'VACBLUEC',
      name: "Vaccinium corymbosum 'Bluechip'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 201,
      chnn_stock_retail: 201,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TKJ92553',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKJ92553'
    },
    {
      status: 210,
      sku: 'PM0076703',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298543',
      rtl_batch_array: '6298543',
      name_search: 'VACBLUEG',
      name: "Vaccinium corymbosum 'Bluegold'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6TH5ZSNH',
      statusContent: 'dot',
      hash: 'ZR2DXCTG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR2DXCTG'
    },
    {
      status: 210,
      sku: 'PM0077026',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299784',
      rtl_batch_array: '6299784',
      name_search: 'KOAMABIL',
      name: 'Kolkwitzia amabilis',
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_prcp: 3.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RWP2P6R8;L7X96EYB;6TJCTWZR;KRWCDWY7;9LX21RSZ;CBKKG75W;T2CSGXGT;YHK2V3TD;RP2DJLLG;X5AGXH4B;XX56276G;XDCC1BGV',
      statusContent: 'dot',
      hash: '2H27Y8TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2H27Y8TN'
    },
    {
      status: 210,
      sku: 'PM0077049',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299845',
      rtl_batch_array: '6299845',
      name_search: 'CEWILLMO',
      name: 'Ceratostigma willmottianum',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8VW817YL',
      statusContent: 'dot',
      hash: 'RAB5KAXY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAB5KAXY'
    },
    {
      status: 210,
      sku: 'PM0077014',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299764',
      rtl_batch_array: '6299764',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 508,
      chnn_stock_retail: 508,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: 'ZXH4Y32S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXH4Y32S'
    },
    {
      status: 210,
      sku: 'PM0077015',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299765',
      rtl_batch_array: '6299765',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 666,
      chnn_stock_retail: 666,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'VXJ43ATY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXJ43ATY'
    },
    {
      status: 210,
      sku: 'PM0077016',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299766',
      rtl_batch_array: '6299766',
      name_search: 'COAKESSE',
      name: "Cornus alba 'Kesselringii'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1206,
      chnn_stock_retail: 1206,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4NCT6N2G;CGSZTP5B;2G6V5VEJ;YPYASZLA',
      statusContent: 'dot',
      hash: 'YDE5RZDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDE5RZDP'
    },
    {
      status: 210,
      sku: 'PM0077017',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299767',
      rtl_batch_array: '6299767',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 3415,
      chnn_stock_retail: 3415,
      sppl_prcp: 3.254,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'HK9N9VGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HK9N9VGD'
    },
    {
      status: 210,
      sku: 'PM0077018',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299768',
      rtl_batch_array: '6299768',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 468,
      chnn_stock_retail: 468,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'LSLG9S4G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSLG9S4G'
    },
    {
      status: 910,
      sku: 'PM0077019',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COAAUREA',
      name: "Cornus alba 'Aurea'",
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      imageCore:
        '66Z484Z5;8B7W96W3;13Y29DS1;JTDT3JCN;RN25X45P;TESXD5TK;CLVZATB2;7GRBCKJ7;17WLE62G;4JYXCR16;VLBZDANJ;1EY53CV1',
      statusContent: 'dot',
      hash: 'YYE4TG5B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YYE4TG5B'
    },
    {
      status: 210,
      sku: 'PM0077020',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299776',
      rtl_batch_array: '6299776',
      name_search: 'COSFLAVI',
      name: "Cornus sericea 'Flaviramea'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1599,
      chnn_stock_retail: 1599,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P2S1RLV8;EY3L3SNC;R776XV2R;CS8JEZ82;ZEPJS9L9;5HJL5PKL;7D9X7N4X;GX5NBPZV;184ZRTSV;2CC4P2GT;BBNWK6NJ;E76K37CZ;EW7DPSVD;JC7WDSV6',
      statusContent: 'dot',
      hash: 'NANDX3DP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NANDX3DP'
    },
    {
      status: 210,
      sku: 'PM0077022',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299779',
      rtl_batch_array: '6299779',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1443,
      chnn_stock_retail: 1443,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DR1LVN2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR1LVN2H'
    },
    {
      status: 210,
      sku: 'PM0077027',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299785',
      rtl_batch_array: '6299785',
      name_search: 'PHBETOIL',
      name: "Philadelphus 'Belle Etoile'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 431,
      chnn_stock_retail: 431,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1AVWA2X8;92NSK6NR;V76115VC;1H4JD7T4',
      statusContent: 'dot',
      hash: '8E3WP6CN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8E3WP6CN'
    },
    {
      status: 210,
      sku: 'PM0077031',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299790',
      rtl_batch_array: '6299790',
      name_search: 'POFTANGE',
      name: "Potentilla fruticosa 'Tangerine'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1063,
      chnn_stock_retail: 1063,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JZWLH82;B1YCCCRS;G6LCZSJL;69VNSYZD',
      statusContent: 'dot',
      hash: 'K2JZLZTN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2JZLZTN'
    },
    {
      status: 910,
      sku: 'PM0077032',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      rng_range_identifier: 'H050060C7',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'PDYN2VWP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PDYN2VWP'
    },
    {
      status: 910,
      sku: 'PM0077033',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: 'S57PGN49',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'S57PGN49'
    },
    {
      status: 210,
      sku: 'PM0077034',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299795',
      rtl_batch_array: '6299795',
      name_search: 'PRLCAUCA',
      name: "Prunus laurocerasus 'Caucasica'",
      sppl_size_code: '080100C7',
      rng_range_identifier: 'H080100C7',
      sppl_stock_available: 1610,
      chnn_stock_retail: 1610,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4DLR8CT;DA5EJLTR',
      statusContent: 'dot',
      hash: '4ZN5XYZN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZN5XYZN'
    },
    {
      status: 210,
      sku: 'PM0077035',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299797',
      rtl_batch_array: '6299797',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 2.399,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'PY17EYN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PY17EYN4'
    },
    {
      status: 210,
      sku: 'PM0077036',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299798',
      rtl_batch_array: '6299798',
      name_search: 'PRLNOVIT',
      name: "Prunus laurocerasus 'Novita'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 9378,
      chnn_stock_retail: 9378,
      sppl_prcp: 2.858,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8K87ZJ94;YZTHJ5WB;2Y7L76JK;SEX9WZ3D;YYH7ADT4',
      statusContent: 'dot',
      hash: 'VTTN3PSS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTTN3PSS'
    },
    {
      status: 910,
      sku: 'PM0077037',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'SR27WTRX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SR27WTRX'
    },
    {
      status: 210,
      sku: 'PM0077038',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299802',
      rtl_batch_array: '6299802',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '080100C7',
      rng_range_identifier: 'H080100C7',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_prcp: 5.694,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: '19VED562',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19VED562'
    },
    {
      status: 210,
      sku: 'PM0077039',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299803',
      rtl_batch_array: '6299803',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 904,
      chnn_stock_retail: 904,
      sppl_prcp: 4.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'Z3JBWCBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z3JBWCBB'
    },
    {
      status: 210,
      sku: 'PM0077040',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299804',
      rtl_batch_array: '6299804',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 517,
      chnn_stock_retail: 517,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2C1LSJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2C1LSJV'
    },
    {
      status: 210,
      sku: 'PM0077041',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299805',
      rtl_batch_array: '6299805',
      name_search: 'SPJALBIF',
      name: "Spiraea japonica 'Albiflora'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1009,
      chnn_stock_retail: 1009,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JGP2YX48;6435VL98',
      statusContent: 'dot',
      hash: 'VLHGBJAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLHGBJAV'
    },
    {
      status: 210,
      sku: 'PM0077043',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299807',
      rtl_batch_array: '6299807',
      name_search: 'SPJCRED',
      name: "Spiraea japonica 'Country Red'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 1683,
      chnn_stock_retail: 1683,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S4V1T5Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4V1T5Z5'
    },
    {
      status: 210,
      sku: 'PM0077044',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299808',
      rtl_batch_array: '6299808',
      name_search: 'SPJGPRIN',
      name: "Spiraea japonica 'Golden Princess'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1463,
      chnn_stock_retail: 1463,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X5X6BRAG;X2326DEC;7Y17JL2P;GVPNHH2Z;T3XA5BAJ;29PLWPKH;N59JAY1H;VXYLR5VC;PZSA8LAR;7L1LAZ8N;2J645WR4;XTL18R4H;ASJHG9Y4;NRJ3H7GV;VRD8W4PS;T7P5V19X',
      statusContent: 'dot',
      hash: 'Z4CLHZRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z4CLHZRS'
    },
    {
      status: 910,
      sku: 'PM0084210',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRBABYLO',
      name: "Crocosmia 'Babylon'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'T1Z7WWBZ;EW777GYJ',
      statusContent: 'dot',
      hash: '8G7HZXKE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8G7HZXKE'
    },
    {
      status: 210,
      sku: 'PM0077050',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299854',
      rtl_batch_array: '6299854',
      name_search: 'PEALBLUE',
      name: 'Perovskia atriplicifolia Lacey Blue',
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EEJ7ZK7G',
      statusContent: 'dot',
      hash: '7947VPG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7947VPG3'
    },
    {
      status: 810,
      sku: 'PM0084211',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363519',
      rtl_batch_array: '6363519',
      name_search: 'ERYALPIN',
      name: 'Eryngium alpinum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NW6BS69B;GKNNP243',
      statusContent: 'dot',
      hash: 'DK6CT14S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DK6CT14S'
    },
    {
      status: 810,
      sku: 'PM0077052',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299858',
      rtl_batch_array: '6299858',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: 'NEW4832N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NEW4832N'
    },
    {
      status: 910,
      sku: 'PM0084212',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FIPRUMBR',
      name: "Filipendula palmata 'Red Umbrella'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'NT79XZE4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NT79XZE4'
    },
    {
      status: 810,
      sku: 'PM0077057',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295549',
      rtl_batch_array: '6295549',
      name_search: 'ECSCANTA',
      name: 'Echinacea Supreme Cantaloupe',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 71,
      sppl_order_minimum: 3,
      sppl_prcp: 2.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1LW3TRAZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LW3TRAZ'
    },
    {
      status: 210,
      sku: 'PM0077064',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295951',
      rtl_batch_array: '6295951',
      name_search: 'MOBYBLIE',
      name: 'Monarda Bee You Bee-lieve',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WS21W3TT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WS21W3TT'
    },
    {
      status: 210,
      sku: 'PM0077065',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5712222',
      rtl_batch_array: '5712222',
      name_search: 'ILMLRASC',
      name: 'Ilex meserveae Little Rascal',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.315,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13GARV24;71E7CTSY;BS8SHAWL;XPE48L27',
      imageBatch: '15BKAWNZ',
      statusContent: 'dot',
      hash: 'XZ94KVRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZ94KVRG'
    },
    {
      status: 210,
      sku: 'PM0075999',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284283',
      rtl_batch_array: '6284283',
      name_search: 'ANADROPM',
      name: "Anchusa azurea 'Dropmore'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1777,
      chnn_stock_retail: 1777,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '236P1EBD;D1GAS54S',
      statusContent: 'dot',
      hash: 'VLN9DCBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLN9DCBS'
    },
    {
      status: 210,
      sku: 'PM0084213',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363529',
      rtl_batch_array: '6363529',
      name_search: 'FRMSWIEN',
      name: "Fragaria moschata 'Sch\u00f6ne Wienerin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 422,
      chnn_stock_retail: 422,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5KP88KP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KP88KP8'
    },
    {
      status: 210,
      sku: 'PM0076003',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6349753',
      rtl_batch_array: '6284290, 6349753, 6349041',
      name_search: 'AQBIEDER',
      name: "Aquilegia 'Biedermeier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 146,
      chnn_stock_retail: 1412,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8A999S8T',
      statusContent: 'dot',
      hash: 'ZC695RTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZC695RTT'
    },
    {
      status: 210,
      sku: 'PM0076375',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350244',
      rtl_batch_array: '6350244',
      name_search: 'GESCOMPA',
      name: "Geranium sanguineum 'Compactum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 786,
      chnn_stock_retail: 786,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V11W5HNA',
      statusContent: 'dot',
      hash: 'P8KVC93K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P8KVC93K'
    },
    {
      status: 210,
      sku: 'PM0076376',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350548',
      rtl_batch_array: '6350548',
      name_search: 'HYPATULA',
      name: 'Hystrix patula',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 399,
      chnn_stock_retail: 399,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VB6ZHTK5;WPNVCT71;CXRSCJY8;BGNRK4VL',
      statusContent: 'dot',
      hash: 'EDY9K15J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDY9K15J'
    },
    {
      status: 210,
      sku: 'PM0076481',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 2,
      btch_active_retail: '6254772',
      rtl_batch_array: '6254772, 6291893',
      name_search: 'LOQVICTO',
      name: "Lobelia 'Queen Victoria'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 506,
      sppl_prcp: 1.696,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RA5AY2XN;GRB37WC6;J5PR3C23;JZY4XBH8;4TC9X8DD;21BB76YB',
      statusContent: 'dot',
      hash: 'S6P6CXAD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6P6CXAD'
    },
    {
      status: 910,
      sku: 'PM0084214',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEBREMDR',
      name: "Geranium 'Bremdream'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'K6L7HR7V',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K6L7HR7V'
    },
    {
      status: 210,
      sku: 'PM0077053',
      core_name: 'Plant',
      btch_manufacturer: 482,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299859',
      rtl_batch_array: '6299859',
      name_search: 'SACHAMAE',
      name: 'Santolina chamaecyparissus',
      sppl_size_code: '010015C2',
      rng_range_identifier: 'H010015C2',
      rng_range_description: 'H10 cm 15 cm C2',
      sppl_stock_available: 495,
      chnn_stock_retail: 495,
      sppl_prcp: 1.62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SJZ6YE6A;YT9YN44V;RTG9BWWL;BLW3RE52;RCL3HK17;VJJ79N7W;P6D112AD;BA16X25C;W6HBGVJ1;R8SSVJSY;VAPHKY1C;JH11Z2JT;VSJA4X9H;SGSP8SSE;7LWC4GBS;9ETDYNZY;LE53L8HB;9VPLXGEE;TCTTKYLE;9HS6Z4HD;THA989WR;PHW7N4HD',
      statusContent: 'dot',
      hash: 'YVP3DXE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YVP3DXE4'
    },
    {
      status: 210,
      sku: 'PM0077063',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295887',
      rtl_batch_array: '6295887',
      name_search: 'TAPARTHE',
      name: 'Tanacetum parthenium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SG1XPBH;7S6B87G8;79VNW7A5;X7959SZX',
      statusContent: 'dot',
      hash: 'JLWJG52L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLWJG52L'
    },
    {
      status: 210,
      sku: 'PM0077046',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299810',
      rtl_batch_array: '6299810',
      name_search: 'SPJLPRIN',
      name: "Spiraea japonica 'Little Princess'",
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 1646,
      chnn_stock_retail: 1646,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KD8WBZS6;N83YXA77;66V1D4J2;8276D8SS;N8VL6VP5;ZRPZW3J9',
      statusContent: 'dot',
      hash: 'SPEDLT5X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SPEDLT5X'
    },
    {
      status: 210,
      sku: 'PM0077021',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299778',
      rtl_batch_array: '6299778',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 174,
      chnn_stock_retail: 174,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: 'N9JDVX83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9JDVX83'
    },
    {
      status: 210,
      sku: 'PM0077025',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299782',
      rtl_batch_array: '6299782',
      name_search: 'HYPGRAND',
      name: "Hydrangea paniculata 'Grandiflora'",
      sppl_size_code: '030040C34',
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      sppl_stock_available: 1317,
      chnn_stock_retail: 1317,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5GDVPDJ8;4LSS8DWX;AZYKGGJS;2R3LAD7A;2SWJ7255;B12DDWEB;XGNHWL1N;N9Y2HWDE;68ANVSLT',
      statusContent: 'dot',
      hash: 'JDC79D9H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDC79D9H'
    },
    {
      status: 210,
      sku: 'PM0077024',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299781',
      rtl_batch_array: '6299781',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 2713,
      chnn_stock_retail: 2713,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '5WRZG9ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WRZG9ZH'
    },
    {
      status: 210,
      sku: 'PM0077028',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299786',
      rtl_batch_array: '6299786',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_prcp: 2.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: '1V43YEJJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1V43YEJJ'
    },
    {
      status: 210,
      sku: 'PM0077029',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299788',
      rtl_batch_array: '6299788',
      name_search: 'POFGOLDF',
      name: "Potentilla fruticosa 'Goldfinger'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1661,
      chnn_stock_retail: 1661,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PBWRVE53;VJ6GJY2D;AC75CSR2;6S7PZW8R;HZSYPE89;N539G1XT',
      statusContent: 'dot',
      hash: 'HGJ59N68',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGJ59N68'
    },
    {
      status: 210,
      sku: 'PM0077030',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299789',
      rtl_batch_array: '6299789',
      name_search: 'POFRACE',
      name: "Potentilla fruticosa 'Red Ace'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1095,
      chnn_stock_retail: 1095,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N7L29V4P;T6B581Y8',
      statusContent: 'dot',
      hash: 'VBGD9L27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBGD9L27'
    },
    {
      status: 210,
      sku: 'PM0077042',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299806',
      rtl_batch_array: '6299806',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 2021,
      chnn_stock_retail: 2021,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'GG93S3DE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GG93S3DE'
    },
    {
      status: 210,
      sku: 'PM0077045',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299809',
      rtl_batch_array: '6299809',
      name_search: 'SPJGOLDF',
      name: "Spiraea japonica 'Goldflame'",
      sppl_size_code: '025030C34',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      sppl_stock_available: 1131,
      chnn_stock_retail: 1131,
      sppl_prcp: 2.808,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1349PKHX;CWZKC1V2;1XDDV2RL;RZ9VCRPK',
      statusContent: 'dot',
      hash: 'CA3XS53Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA3XS53Z'
    },
    {
      status: 210,
      sku: 'PM0077023',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6299780',
      rtl_batch_array: '6299780',
      name_search: 'GELYDIA',
      name: 'Genista lydia',
      sppl_size_code: '020030C34',
      rng_range_identifier: 'H020030C3.5',
      rng_range_description: 'H20 cm 30 cm C3.5',
      sppl_stock_available: 1657,
      chnn_stock_retail: 1657,
      sppl_prcp: 3.105,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E1Y6EK5D;9XA5PG72;RJRHXYXZ;G6LBWK4V;8C94837E;6X3D2HRK',
      statusContent: 'dot',
      hash: 'CR46NXN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CR46NXN1'
    },
    {
      status: 210,
      sku: 'PM0077055',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295424',
      rtl_batch_array: '6295424',
      name_search: 'ACCALGAE',
      name: "Achnatherum calamagrostis 'Alg\u00e4u'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1971,
      chnn_stock_retail: 1971,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJSEWECC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJSEWECC'
    },
    {
      status: 210,
      sku: 'PM0076593',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300441',
      rtl_batch_array: '6300441',
      name_search: 'ANGHUMME',
      name: "Andropogon gerardii 'Hummelo'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XBHBCX89',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBHBCX89'
    },
    {
      status: 210,
      sku: 'PM0076000',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284285',
      rtl_batch_array: '6284285',
      name_search: 'ANHFRRHO',
      name: 'Anemone hupehensis Fantasy Red Riding Hood',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 316,
      chnn_stock_retail: 316,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KW123X1E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KW123X1E'
    },
    {
      status: 210,
      sku: 'PM0077060',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6295716',
      rtl_batch_array: '6295716, 6307924',
      name_search: 'ASADOPET',
      name: "Aster amellus 'Dr Otto Petschek'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 513,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PSNRHPK4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSNRHPK4'
    },
    {
      status: 810,
      sku: 'PM0077056',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295539',
      rtl_batch_array: '6295539',
      name_search: 'ECREFFEC',
      name: "Echinacea 'Red Effect'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3468KTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3468KTH'
    },
    {
      status: 210,
      sku: 'PM0076371',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350278',
      rtl_batch_array: '6350278',
      name_search: 'HEBETTY',
      name: "Helenium 'Betty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHXDR3LL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHXDR3LL'
    },
    {
      status: 210,
      sku: 'PM0076372',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301629',
      rtl_batch_array: '6350291, 6301629',
      name_search: 'HEGOLDRA',
      name: "Helenium 'Goldrausch'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 158,
      chnn_stock_retail: 590,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RAZ8SZ5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAZ8SZ5E'
    },
    {
      status: 210,
      sku: 'PM0077066',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6380826',
      rtl_batch_array: '6380826',
      name_search: 'STDISCOL',
      name: 'Stachys discolor',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 3,
      sppl_prcp: 1.357,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHSXGR3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHSXGR3K'
    },
    {
      status: 210,
      sku: 'PM0076484',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6291900',
      rtl_batch_array: '6291900',
      name_search: 'CIPAFRAD',
      name: "Cistus purpureus 'Alan Fradd'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 171,
      chnn_stock_retail: 171,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8Z9ACBBY;CKASLPW2',
      statusContent: 'dot',
      hash: '8ZSL4EL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZSL4EL3'
    },
    {
      status: 210,
      sku: 'PM0076701',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298540',
      rtl_batch_array: '6298540',
      name_search: 'SYVSENSA',
      name: "Syringa vulgaris 'Sensation'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_prcp: 5.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13W39ACN;BV6ARL8S;49YN2D9V;7LHW8CZ3',
      statusContent: 'dot',
      hash: 'BNA2RT6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNA2RT6W'
    },
    {
      status: 810,
      sku: 'PM0084215',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6374026',
      rtl_batch_array: '6374026',
      name_search: 'GECKWHIT',
      name: "Geranium clarkei 'Kashmir White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V3BDG6AR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3BDG6AR'
    },
    {
      status: 210,
      sku: 'PM0077059',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295674',
      rtl_batch_array: '6295674',
      name_search: 'HESCHNEE',
      name: "Heuchera 'Schneewittchen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HEBNNCHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEBNNCHK'
    },
    {
      status: 210,
      sku: 'PM0077061',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295760',
      rtl_batch_array: '6295760',
      name_search: 'NENNEPTU',
      name: 'Nepeta nervosa Neptune',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ASC7NL4W',
      statusContent: 'dot',
      hash: 'JK2ELN2D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JK2ELN2D'
    },
    {
      status: 910,
      sku: 'PM0077047',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBDAWN',
      name: "Viburnum bodnantense 'Dawn'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      imageCore: 'Z9P4B7AH;W1TWW1JS;TCY86BHA',
      statusContent: 'dot',
      hash: '8YNPRBXB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8YNPRBXB'
    },
    {
      status: 210,
      sku: 'PM0076705',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298545',
      rtl_batch_array: '6298545',
      name_search: 'VACDUKE',
      name: "Vaccinium corymbosum 'Duke'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y1ALCRK2',
      statusContent: 'dot',
      hash: '2G383WK8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2G383WK8'
    },
    {
      status: 210,
      sku: 'PM0076005',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284295',
      rtl_batch_array: '6284295, 6348282',
      name_search: 'AQVWGUIN',
      name: "Aquilegia vulgaris 'William Guiness'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 942,
      chnn_stock_retail: 1038,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '12D1ZEAC;ZG8C1YY2;Y9NC9K88;BWZA96X3;6LKVB85L;KPKEXBXR',
      statusContent: 'dot',
      hash: 'RX3NPY48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RX3NPY48'
    },
    {
      status: 210,
      sku: 'PM0076006',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349857',
      rtl_batch_array: '6349857',
      name_search: 'ARCSKING',
      name: "Aralia cordata 'Sun King'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 789,
      chnn_stock_retail: 789,
      sppl_order_minimum: 3,
      sppl_prcp: 2.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VZKAD4HJ;LCG1C8H8;8H1HPNXP;PKWCP9D2;NJ1E8KCV;LL6CTWE6;4KLWYK7B;BDX7LVJA',
      statusContent: 'dot',
      hash: 'PDXGHC3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDXGHC3K'
    },
    {
      status: 210,
      sku: 'PM0076013',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6320844',
      rtl_batch_array: '6284309, 6320844',
      name_search: 'BAAUSTRA',
      name: 'Baptisia australis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 898,
      chnn_stock_retail: 4137,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '62P19NG1;9XK9HNLJ;E264X2EC;EB7475T7;6YCZPDAB;WNZ47Z59;G7JGW5ER;Y7331579;ZC4D99WK;DXYZRDT8;TRVTNJKX;VLJHAEHY',
      statusContent: 'dot',
      hash: '85DW952R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85DW952R'
    },
    {
      status: 210,
      sku: 'PM0076377',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6319745',
      rtl_batch_array: '6319745',
      name_search: 'LIDDESDE',
      name: "Ligularia dentata 'Desdemona'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 253,
      chnn_stock_retail: 253,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6DGXCVWJ',
      statusContent: 'dot',
      hash: 'PB42TALY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PB42TALY'
    },
    {
      status: 810,
      sku: 'PM0076707',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300871',
      rtl_batch_array: '6298547, 6300871',
      name_search: 'VEBONARI',
      name: 'Verbena bonariensis',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 31,
      chnn_stock_retail: 997,
      sppl_prcp: 3.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKY7SBA7;ABH792B4;8CJEW9TL;G44VBSBK;YB7W24DG;BJB4127V;AWB6PXWB;9HZJHLLE;255D2W23;W7L7YBJS;4NNCLH29;AYDY3PE7;V61K9VK7;DHK64TJ5;CS74R4R9;E2249VDY;RASG24N5;2DBH57A1;ZHYX6VGE;WTE4LSZZ;9RE66TL3;VCHXW3DN;VKPTAXLL',
      statusContent: 'dot',
      hash: 'J13BNJBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J13BNJBD'
    },
    {
      status: 210,
      sku: 'PM0083841',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356970',
      rtl_batch_array: '6356970',
      name_search: 'KIPALMAT',
      name: 'Kirengeshoma palmata',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 2.339,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G6YDSEJ1;DTXVH1EP',
      statusContent: 'dot',
      hash: 'BESTZLY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BESTZLY8'
    },
    {
      status: 910,
      sku: 'PM0084216',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEAMONAR',
      name: "Helianthus atrorubens 'Monarch'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '2A6XSKDY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2A6XSKDY'
    },
    {
      status: 210,
      sku: 'PM0075981',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6291244',
      rtl_batch_array: '6284227, 6291244, 6386094, 6348270',
      name_search: 'ADPEDATU',
      name: 'Adiantum pedatum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 3241,
      sppl_order_minimum: 3,
      sppl_prcp: 0.887,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJ68TTYK;S89H2LH1;D4X4KHBR;ENRAN45D;K94LVZR3;X7RCE59N',
      statusContent: 'dot',
      hash: '7DEX1149',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DEX1149'
    },
    {
      status: 810,
      sku: 'PM0076004',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349757',
      rtl_batch_array: '6284293, 6349757',
      name_search: 'AQVGGARD',
      name: "Aquilegia vulgaris 'Grandmother's Garden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VLJBLWVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLJBLWVS'
    },
    {
      status: 910,
      sku: 'PM0076014',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BEGALBA',
      name: "Begonia grandis 'Alba'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'RCJR5WEG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RCJR5WEG'
    },
    {
      status: 210,
      sku: 'PM0076015',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349999',
      rtl_batch_array: '6349999',
      name_search: 'DIVFFIRE',
      name: "Digitalis v. 'Falcon Fire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L3A8PWLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3A8PWLT'
    },
    {
      status: 910,
      sku: 'PM0084217',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GEFOPAL',
      name: "Geum 'Fire Opal'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WCWTY7VL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WCWTY7VL'
    },
    {
      status: 910,
      sku: 'PM0084218',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'IRNEDITI',
      name: "Iris (G) 'Night Edition'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'A22N8VD2',
      statusContent: 'dot',
      hash: 'RXSTJL4X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RXSTJL4X'
    },
    {
      status: 210,
      sku: 'PM0076378',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350612',
      rtl_batch_array: '6350612',
      name_search: 'KNRHPOPS',
      name: "Kniphofia 'Red Hot Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATNNA1KA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATNNA1KA'
    },
    {
      status: 210,
      sku: 'PM0076007',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284297',
      rtl_batch_array: '6284297',
      name_search: 'ARCLLILI',
      name: "Arthropodium cand. 'Little Lilia'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TYH4VE6C;VG4C5K6X;Z6KCDEHR;TA2TZ6XE;JJJSKW5R;W8KS4LTT;4VSVHA9Y;CW3A9CJD;KGHPTGH4;C6C76YBK;V6ZNX1KH',
      statusContent: 'dot',
      hash: 'PZGV1ZD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZGV1ZD6'
    },
    {
      status: 210,
      sku: 'PM0076008',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349911',
      rtl_batch_array: '6349911, 6370336',
      name_search: 'CAHPGREE',
      name: "Carex howardii 'Phoenix Green'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 795,
      chnn_stock_retail: 975,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WKRRJG2R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WKRRJG2R'
    },
    {
      status: 210,
      sku: 'PM0076009',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349919',
      rtl_batch_array: '6349919',
      name_search: 'CACHBLUE',
      name: "Caryopteris clandonensis 'Heavenly Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '72XAY94Z;7CPZV4J9;89SD1TP9;9LVWL1SZ;S26W45VV;AHCA9DT7;VXT21KTY;49KLJ3HG;JT6LPSRK;VJHPW8D3;NPN3BTCC',
      statusContent: 'dot',
      hash: 'G3B1GTP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3B1GTP5'
    },
    {
      status: 210,
      sku: 'PM0076010',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349920',
      rtl_batch_array: '6349920',
      name_search: 'CACHGOLD',
      name: 'Caryopteris clandonensis Hint of Gold',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZBTALEH4;BDG25NN9;42SSYE5Z;1SKPNGRY',
      statusContent: 'dot',
      hash: 'S8ERYC47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8ERYC47'
    },
    {
      status: 210,
      sku: 'PM0076011',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284307',
      rtl_batch_array: '6284307',
      name_search: 'ASMCAPRI',
      name: "Astrantia major 'Capri'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T54KKHV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T54KKHV8'
    },
    {
      status: 210,
      sku: 'PM0076012',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6369724',
      rtl_batch_array: '6369724, 6284308, 6320841',
      name_search: 'ASMPBUTT',
      name: 'Astrantia maj. Pink Button',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 208,
      chnn_stock_retail: 673,
      sppl_order_minimum: 3,
      sppl_prcp: 1.89,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HC1G9XJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HC1G9XJR'
    },
    {
      status: 210,
      sku: 'PM0076016',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349860',
      rtl_batch_array: '6349860',
      name_search: 'COSEVITA',
      name: "Cortaderia selloana 'Evita'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 735,
      chnn_stock_retail: 735,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z7TDA57C;T47WSAJK;EKVLHT7Z;1RK1H2WZ;C3CCCC52;JN2SW8ZH;SJCR97W9;AEABKN52;KKWS22LG;2LHZW1V4;4XKSWHDS;G5TN6AGK;ASW4WEV7;KERN6VVV;728RVVK5;KVWLS82L;HN6C1K52',
      statusContent: 'dot',
      hash: '87BGHGR7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87BGHGR7'
    },
    {
      status: 210,
      sku: 'PM0076379',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350713',
      rtl_batch_array: '6350713',
      name_search: 'NEPOSEID',
      name: "Nepeta 'Poseidon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NEKSE5XX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NEKSE5XX'
    },
    {
      status: 210,
      sku: 'PM0076487',
      core_name: 'Plant',
      btch_manufacturer: 1016,
      rtl_batch_array_total: 1,
      btch_active_retail: '6292269',
      rtl_batch_array: '6292269',
      name_search: 'THOANANA',
      name: "Thuja occidentalis 'Aurea Nana'",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 517,
      chnn_stock_retail: 517,
      sppl_prcp: 4.036,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LEPT3LGC',
      statusContent: 'dot',
      hash: 'NVEDGESD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVEDGESD'
    },
    {
      status: 910,
      sku: 'PM0041703',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6224289',
      rtl_batch_array: '6224289',
      name_search: 'HYAPETIO',
      name: 'Hydrangea anomala petiolaris',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 6,
      sppl_prcp: 2.897,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E9XCXRRX',
      statusContent: 'dot',
      hash: '6ZGJR6SV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZGJR6SV'
    },
    {
      status: 910,
      sku: 'PM0041712',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHELIX',
      name: 'Hedera helix',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      imageCore:
        'SGYLNA99;N87B2J8D;DNX9ANXW;4BY838DW;TA733YLK;9B63DER4;9L4VA8RP;GPVGWRKD;9RY3K6CW;NSSKRVWS;R9NBHLL6;N525LJ23;NPBD97YC;K973RVSV;P19KPCTD;HL8GHPJ8;WDTN2G31;418YGSZ5;ZTAZV64P;X719TRH9;L1E2YH42;YVE11SVT;WNGXL5ZS;D5EV8TD6;41LSZGDK;2K6TTG84',
      statusContent: 'dot',
      hash: 'APZ29Y5T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'APZ29Y5T'
    },
    {
      status: 210,
      sku: 'PM0041675',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244747',
      rtl_batch_array: '6244747',
      name_search: 'RINTITAN',
      name: "Ribes nigrum 'Titania'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HTJYPC9X;JVLR79EL',
      statusContent: 'dot',
      hash: 'ET8PJEBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET8PJEBG'
    },
    {
      status: 910,
      sku: 'PM0041646',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '6138551',
      rtl_batch_array: '6138551, 5420736',
      name_search: 'VIVLAAN',
      name: "Vitis 'Vroege van der Laan'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 5,
      chnn_stock_retail: 243,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BRS5VC1;55WXGZY7',
      statusContent: 'dot',
      hash: 'G2X29VSA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2X29VSA'
    },
    {
      status: 210,
      sku: 'PM0041707',
      core_name: 'Plant',
      sppl_ean: '8720664682306',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '5551073',
      rtl_batch_array: '5551073',
      name_search: 'BUDMGKNI',
      name: "Buddleja davidii 'Magda's Gold Knight''",
      sppl_size_code: '040050C34',
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      sppl_stock_available: 1799,
      chnn_stock_retail: 1799,
      sppl_prcp: 3.402,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'RA4WKAKX',
      statusContent: 'dot',
      hash: 'JCAPZHCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCAPZHCT'
    },
    {
      status: 910,
      sku: 'PM0041702',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188208',
      rtl_batch_array: '6188208',
      name_search: 'CLTETRAR',
      name: "Clematis 'Tetrarose'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 4,
      chnn_stock_retail: 4,
      sppl_order_minimum: 6,
      sppl_prcp: 2.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P4JD2Y3L;8X9XWRDT;7EGDC4EL;PYXJ792J',
      statusContent: 'dot',
      hash: 'TNW5ZBKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNW5ZBKE'
    },
    {
      status: 210,
      sku: 'PM0041709',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244767',
      rtl_batch_array: '6244767',
      name_search: 'RUIFALLG',
      name: "Rubus idaeus 'Fallgold'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 12000,
      chnn_stock_retail: 12000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BY6DCCVW;G8J6J7LT',
      statusContent: 'dot',
      hash: 'TXXRGDD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TXXRGDD5'
    },
    {
      status: 910,
      sku: 'PM0041695',
      core_name: 'Plant',
      sppl_ean: '8720664691636',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '5772782',
      rtl_batch_array: '5772782',
      name_search: 'HEHIBERN',
      name: 'Hedera hibernica',
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_order_minimum: 6,
      sppl_prcp: 1.96,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9PSXY3C2;AT7E6W71;XAZD8E1Z;NZ3WXR2B;BDWAKLGW;2E7S9W6T;T99NHZ3A;A5R3JPNA;1B4GRHGR;2EJ892E2;JC3LDXD2;8AHXZ5YC;YK5D399Y;TT22W7XH',
      statusContent: 'dot',
      hash: 'WWL4X45W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWL4X45W'
    },
    {
      status: 210,
      sku: 'PM0041708',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244766',
      rtl_batch_array: '6244766',
      name_search: 'RUIBJEWE',
      name: "Rubus idaeus 'Black Jewel'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EVL973XC;EH75PKSJ;DR6TZA88;HC1B3N6D;KNVBETE8;24BP6P7P;17LTE2VY;Z2NE2P9V;YZWEE9AZ;56RXLNTR;Y7DD7ACS',
      statusContent: 'dot',
      hash: 'ZXHYD4R4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXHYD4R4'
    },
    {
      status: 210,
      sku: 'PM0076380',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350714',
      rtl_batch_array: '6350714',
      name_search: 'NEPRATTI',
      name: 'Nepeta prattii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LSEASW1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSEASW1R'
    },
    {
      status: 210,
      sku: 'PM0076704',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298544',
      rtl_batch_array: '6298544',
      name_search: 'VACBBLUE',
      name: "Vaccinium corymbosum 'Brigitta Blue'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HNJ93CRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNJ93CRN'
    },
    {
      status: 210,
      sku: 'PM0076706',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298546',
      rtl_batch_array: '6298546',
      name_search: 'VACPLEMO',
      name: "Vaccinium 'Pink Lemonade'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 227,
      chnn_stock_retail: 227,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8YBALZWS;8GL6TCZ7;7L1VJALT;VAJ4JEDR;2D85DGV1;L7138EH1;2LCH6T3R;N2NC8YTH;1PRP5HY5;L5CHHBP3;ZBZD5HV8',
      statusContent: 'dot',
      hash: '4P735337',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P735337'
    },
    {
      status: 210,
      sku: 'PM0076708',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298548',
      rtl_batch_array: '6298548',
      name_search: 'VEBONARI',
      name: 'Verbena bonariensis',
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 917,
      chnn_stock_retail: 917,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EKY7SBA7;ABH792B4;8CJEW9TL;G44VBSBK;YB7W24DG;BJB4127V;AWB6PXWB;9HZJHLLE;255D2W23;W7L7YBJS;4NNCLH29;AYDY3PE7;V61K9VK7;DHK64TJ5;CS74R4R9;E2249VDY;RASG24N5;2DBH57A1;ZHYX6VGE;WTE4LSZZ;9RE66TL3;VCHXW3DN;VKPTAXLL',
      statusContent: 'dot',
      hash: '2W5P172V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2W5P172V'
    },
    {
      status: 210,
      sku: 'PM0076709',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298549',
      rtl_batch_array: '6298549',
      name_search: 'VERVENOS',
      name: "Verbena rigida 'Venosa'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 982,
      chnn_stock_retail: 982,
      sppl_prcp: 5.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '79B8NG6S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79B8NG6S'
    },
    {
      status: 210,
      sku: 'PM0076711',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298551',
      rtl_batch_array: '6298551',
      name_search: 'VIBCONOY',
      name: "Viburnum burkwoodii 'Conoy'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 483,
      chnn_stock_retail: 483,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C72SXY9;SDC3PPBC',
      statusContent: 'dot',
      hash: '1EZ2DC7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1EZ2DC7T'
    },
    {
      status: 910,
      sku: 'PM0084219',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEKHEART',
      name: "Hemerocallis 'King of Hearts'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HSH84JBY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HSH84JBY'
    },
    {
      status: 210,
      sku: 'PM0076717',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298557',
      rtl_batch_array: '6298557',
      name_search: 'VIACALBU',
      name: "Vitex agnus-castus 'Albus'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_prcp: 3.375,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECDK1Z69',
      statusContent: 'dot',
      hash: 'HCZ1LPB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCZ1LPB8'
    },
    {
      status: 210,
      sku: 'PM0076718',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298558',
      rtl_batch_array: '6298558',
      name_search: 'VICOIGNE',
      name: 'Vitis coignetiae',
      sppl_size_code: '080090C2',
      rng_range_identifier: 'H080090C2',
      rng_range_description: 'H80 cm 90 cm C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 4.572,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DE8PRWSA;D5V37SH6',
      statusContent: 'dot',
      hash: 'GSCPY1GX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GSCPY1GX'
    },
    {
      status: 210,
      sku: 'PM0076720',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298561',
      rtl_batch_array: '6298561',
      name_search: 'WEBSNOWF',
      name: "Weigela 'Bristol Snowflake'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 2.821,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9RBH7A51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RBH7A51'
    },
    {
      status: 210,
      sku: 'PM0076721',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298562',
      rtl_batch_array: '6298562',
      name_search: 'WEFALEXA',
      name: "Weigela florida 'Alexandra'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1323,
      chnn_stock_retail: 1323,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6B2ZE5E',
      statusContent: 'dot',
      hash: 'ET7VG1ND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET7VG1ND'
    },
    {
      status: 210,
      sku: 'PM0076722',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298563',
      rtl_batch_array: '6298563',
      name_search: 'WEFMROUG',
      name: 'Weigela florida Moulin Rouge',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1334,
      chnn_stock_retail: 1334,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1T4RXH5J;8Z15YBWS;J3TANJVH;66WL9GHZ;GDHKASXL;Z37Y99H2;SHL8STTT;K6SE99G2;2BXECCEY;TXTS79NP;CA15LVEX;4WD937PH;86DVTZB7',
      statusContent: 'dot',
      hash: 'CL6ZYHBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CL6ZYHBA'
    },
    {
      status: 210,
      sku: 'PM0076723',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310455',
      rtl_batch_array: '6298565, 6310455',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 2150,
      sppl_prcp: 1.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: '652DL2SR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '652DL2SR'
    },
    {
      status: 210,
      sku: 'PM0077067',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 1,
      btch_active_retail: '6310438',
      rtl_batch_array: '6310438',
      name_search: 'ACCAMPES',
      name: 'Acer campestre',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2233,
      chnn_stock_retail: 2233,
      sppl_prcp: 1.585,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LN7REG4V;8LHBSD6N;VPTE9KXR;7YB5H516',
      statusContent: 'dot',
      hash: '5AXCW378',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AXCW378'
    },
    {
      status: 910,
      sku: 'PM0084220',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HOELATA',
      name: 'Hosta elata',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'V9RNSWAP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V9RNSWAP'
    },
    {
      status: 210,
      sku: 'PM0077070',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300172',
      rtl_batch_array: '6300172',
      name_search: 'BEJULIAN',
      name: 'Berberis julianae',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 820,
      chnn_stock_retail: 820,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CR5PR6LW;ZWPRE3WN;BRH4S6JY;YH3RCTE5;LCD1WJYL',
      statusContent: 'dot',
      hash: '7ALDN3AL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ALDN3AL'
    },
    {
      status: 210,
      sku: 'PM0077071',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300174',
      rtl_batch_array: '6300174, 6310182',
      name_search: 'BETHUNBE',
      name: 'Berberis thunbergii',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 5009,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VR4D883C;JYT62D7E;YXAHYVN7;J5B3L6ZR;STRL4G62;8NNCVVSS;Z5J8YP2K;WZ3RLL1A;9A3RJR1S;CWCB45VD',
      statusContent: 'dot',
      hash: 'WRTJ15KZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WRTJ15KZ'
    },
    {
      status: 210,
      sku: 'PM0077072',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300177',
      rtl_batch_array: '6300177',
      name_search: 'CETREPEN',
      name: 'Ceanothus thyrsiflorus repens',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2014,
      chnn_stock_retail: 2014,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7RC7X2PX;T7C7EYRY;XHS6RHY3;ARWSSDVD;XRSEE1WX;G6P6BVA5;ELPK41DJ;5DH1ENVP;V63KPD89;4BGKV612;LBWVGC7R;AYKEL9LH',
      statusContent: 'dot',
      hash: '17JDCDGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17JDCDGA'
    },
    {
      status: 210,
      sku: 'PM0077073',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 2,
      btch_active_retail: '6304960',
      rtl_batch_array: '6300178, 6304960',
      name_search: 'COAELEGA',
      name: "Cornus alba 'Elegantissima'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1450,
      sppl_prcp: 1.274,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BXXX3XGC;19NP7DKZ;27A4V439;6492PXR5;D3A51SZY;CYGTH6ZR;E3BANVEZ;EGZWC8LJ;7DNJ1T9W;LK1CYX7P',
      statusContent: 'dot',
      hash: 'X5935TCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5935TCL'
    },
    {
      status: 210,
      sku: 'PM0077074',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300181',
      rtl_batch_array: '6300181',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'AVB2DCJN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVB2DCJN'
    },
    {
      status: 210,
      sku: 'PM0077075',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300183',
      rtl_batch_array: '6300183',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: '7CEKATVS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CEKATVS'
    },
    {
      status: 210,
      sku: 'PM0077077',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300190',
      rtl_batch_array: '6300190',
      name_search: 'DELEMOIN',
      name: 'Deutzia lemoinei',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2560,
      chnn_stock_retail: 2560,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5L9KZVBL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5L9KZVBL'
    },
    {
      status: 210,
      sku: 'PM0077078',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300191',
      rtl_batch_array: '6300191',
      name_search: 'DEROSEA',
      name: 'Deutzia rosea',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2650,
      chnn_stock_retail: 2650,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2E39KANR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E39KANR'
    },
    {
      status: 210,
      sku: 'PM0077079',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300192',
      rtl_batch_array: '6300192',
      name_search: 'DESPLENA',
      name: "Deutzia scabra 'Plena'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1890,
      chnn_stock_retail: 1890,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5VZEV74D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VZEV74D'
    },
    {
      status: 210,
      sku: 'PM0077080',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300193',
      rtl_batch_array: '6300193',
      name_search: 'DISBUTTE',
      name: "Diervilla sessilifolia 'Butterfly'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 3060,
      chnn_stock_retail: 3060,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VGBWY8VH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGBWY8VH'
    },
    {
      status: 210,
      sku: 'PM0077069',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 3,
      btch_active_retail: '6320174',
      rtl_batch_array: '6300171, 6320174, 6310178',
      name_search: 'ARMELANO',
      name: 'Aronia melanocarpa',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 680,
      chnn_stock_retail: 4671,
      sppl_prcp: 1.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BKAXJGKZ;WYB8W7B2;8GLXPTV1',
      statusContent: 'dot',
      hash: '1YAJEC6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YAJEC6E'
    },
    {
      status: 210,
      sku: 'PM0077076',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300188',
      rtl_batch_array: '6300188',
      name_search: 'DEGNIKKO',
      name: "Deutzia gracilis 'Nikko'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2080,
      chnn_stock_retail: 2080,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C4XTBD8Y',
      statusContent: 'dot',
      hash: 'V16B87GR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V16B87GR'
    },
    {
      status: 910,
      sku: 'PM0084221',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LUGOVERN',
      name: "Lupinus 'The Governor'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        '1ELTRZGT;NJ86PZLE;NXL82TSX;SXTEXPEY;1RRTLEH9;TG3756B9;XL2WCVBE;5P915HGW;WVYZH61A;ETWWVGYJ;D3G9PL49;8A64YEGX;K1YT2PV9;J9YNHRKW;EV86ZDP8',
      statusContent: 'dot',
      hash: 'SNZ93VLG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SNZ93VLG'
    },
    {
      status: 910,
      sku: 'PM0084222',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAMSSILV',
      name: "Lamium maculatum 'Sterling Silver'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'RHVR21YS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RHVR21YS'
    },
    {
      status: 210,
      sku: 'PM0084223',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363546',
      rtl_batch_array: '6363546',
      name_search: 'LELACREM',
      name: "Leucanthemum (S) 'Lacr\u00e8me'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 192,
      chnn_stock_retail: 192,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AA7BXNJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AA7BXNJR'
    },
    {
      status: 910,
      sku: 'PM0084224',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ORROTKUG',
      name: "Origanum 'Rotkugel'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '9Y6BESKH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9Y6BESKH'
    },
    {
      status: 910,
      sku: 'PM0084225',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MOSQUEEN',
      name: "Monarda 'Snow Queen'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'DWD9H8VS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DWD9H8VS'
    },
    {
      status: 210,
      sku: 'PM0084226',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363553',
      rtl_batch_array: '6363553',
      name_search: 'PHSPAETS',
      name: "Phlox 'Sp\u00e4tsommer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 194,
      chnn_stock_retail: 194,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C81AC7H8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C81AC7H8'
    },
    {
      status: 210,
      sku: 'PM0084227',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363555',
      rtl_batch_array: '6363555',
      name_search: 'PAOBLIVE',
      name: "Papaver orientale 'Beauty of Livermere'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XSY2WZA3;H6JCH2E7;6PTZR7HJ;GX9NE5N5;SWJ6ZGYW;P1562DC5',
      statusContent: 'dot',
      hash: 'G2BBWJPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2BBWJPT'
    },
    {
      status: 910,
      sku: 'PM0084228',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PEAROCKE',
      name: "Perovskia atripl. 'Rocketman'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '8ZX7G3DH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8ZX7G3DH'
    },
    {
      status: 910,
      sku: 'PM0084229',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RUOBBEAU',
      name: "Rudbeckia occidentalis 'Black Beauty'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CBZGENCL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CBZGENCL'
    },
    {
      status: 210,
      sku: 'PM0084230',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363559',
      rtl_batch_array: '6363559',
      name_search: 'PHZOE',
      name: 'Phlox (P) Zo\u00eb',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y613RZ1P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y613RZ1P'
    },
    {
      status: 910,
      sku: 'PM0084231',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHNIGRUM',
      name: 'Phyteuma nigrum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'SVYK9X9W',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SVYK9X9W'
    },
    {
      status: 910,
      sku: 'PM0084232',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'POHOPWOO',
      name: 'Potentilla hopwoodiana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'BCTHHY4D',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BCTHHY4D'
    },
    {
      status: 910,
      sku: 'PM0084233',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRCMOORE',
      name: 'Primula capitata mooreana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'J6YC8SYL',
      statusContent: 'dot',
      hash: 'ZHVKKBA6',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZHVKKBA6'
    },
    {
      status: 210,
      sku: 'PM0076745',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298592',
      rtl_batch_array: '6298592',
      name_search: 'EUALATUS',
      name: 'Euonymus alatus',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 234,
      chnn_stock_retail: 234,
      sppl_prcp: 7.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P7H88WZ9;NK5Z2HWZ;LTZRJAG7;CED277T6;KH71RRVX;RLG5TLB9;47R5PY8A',
      statusContent: 'dot',
      hash: 'B538GGCC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B538GGCC'
    },
    {
      status: 210,
      sku: 'PM0077108',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300237',
      rtl_batch_array: '6300237',
      name_search: 'SANAUREA',
      name: "Sambucus nigra 'Aurea'",
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1826,
      chnn_stock_retail: 1826,
      sppl_prcp: 1.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N127GHTE',
      statusContent: 'dot',
      hash: 'BBBSLPP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBBSLPP1'
    },
    {
      status: 210,
      sku: 'PM0083842',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356972',
      rtl_batch_array: '6356972',
      name_search: 'LISKOBOL',
      name: "Liatris spicata 'Kobold'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 3,
      sppl_prcp: 1.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5B5GENNE;CL16HNJ3;ZTDASLE6;BC94D4EC;GV5NX2PY;XEBRDZEH;VPTL1N53;212TTE5R;AT4XWSS9;T3SBT4DS;ZDWEE1DS;8T6C2S56;6GW9XA89',
      statusContent: 'dot',
      hash: '4JHPJADA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4JHPJADA'
    },
    {
      status: 210,
      sku: 'PM0076018',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349979',
      rtl_batch_array: '6349979',
      name_search: 'DAMDREAM',
      name: "Dahlia 'Mystic Dreamer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 161,
      chnn_stock_retail: 161,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D2ZDE54W;2927RP45;TL45L7HR',
      statusContent: 'dot',
      hash: 'JL6Z5BGP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL6Z5BGP'
    },
    {
      status: 210,
      sku: 'PM0076020',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349981',
      rtl_batch_array: '6349981',
      name_search: 'DAMILLUS',
      name: "Dahlia 'Mystic Illusion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RT427W1R;5WSXGTHC',
      statusContent: 'dot',
      hash: '1V4G921L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1V4G921L'
    },
    {
      status: 210,
      sku: 'PM0076023',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350042',
      rtl_batch_array: '6350042, 6320858',
      name_search: 'ECPFANGE',
      name: "Echinacea purpurea 'Fragrant Angel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 446,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5YY4RKPW;2Z2W2TK3;R1X4TTXC;29V98ECS',
      statusContent: 'dot',
      hash: '53PEZHEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53PEZHEV'
    },
    {
      status: 210,
      sku: 'PM0076382',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333297',
      rtl_batch_array: '6353113, 6333297',
      name_search: 'PLGAPINK',
      name: 'Platycodon grandiflorus Astra Pink',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1317,
      chnn_stock_retail: 1605,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V96E7ZAA;X5HYDWDE;8L96GCNY',
      statusContent: 'dot',
      hash: 'E5ES9VV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5ES9VV9'
    },
    {
      status: 210,
      sku: 'PM0076383',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353115',
      rtl_batch_array: '6353115',
      name_search: 'PLGFBLUE',
      name: "Platycodon grandiflorus 'Fuji Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YKZTW6ED',
      statusContent: 'dot',
      hash: 'PP58D7VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PP58D7VW'
    },
    {
      status: 210,
      sku: 'PM0076017',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349861',
      rtl_batch_array: '6349861',
      name_search: 'COSTPAMP',
      name: 'Cortaderia selloana Tiny Pampa',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 3.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7GGJCS2;15ALS5BR;9HTJYXEL;2WE52NN4;5H8814R7;BB3NGN7T;4EXECJAP;14AGVD3G;4Y9DK5HX;H6156RZL;PWHBE324;1N3PB9CG',
      statusContent: 'dot',
      hash: 'CE537WJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CE537WJ2'
    },
    {
      status: 210,
      sku: 'PM0076021',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350017',
      rtl_batch_array: '6350017',
      name_search: 'ECBCHEES',
      name: 'Echinacea Blueberry Cheesecake',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 489,
      chnn_stock_retail: 489,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4K4B3BY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4K4B3BY3'
    },
    {
      status: 210,
      sku: 'PM0076026',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350055',
      rtl_batch_array: '6350055, 6320854',
      name_search: 'ECKRASPB',
      name: 'Echinacea Kismet Raspberry',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 290,
      chnn_stock_retail: 390,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S66SSP5D',
      statusContent: 'dot',
      hash: 'PSCV6V4N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PSCV6V4N'
    },
    {
      status: 210,
      sku: 'PM0076028',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350067',
      rtl_batch_array: '6350067',
      name_search: 'ECPNCHEE',
      name: "Echinacea purpurea 'Now Cheesier'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 474,
      chnn_stock_retail: 474,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZH9VG8PP',
      statusContent: 'dot',
      hash: 'K9X3ERN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9X3ERN7'
    },
    {
      status: 210,
      sku: 'PM0076381',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353110',
      rtl_batch_array: '6353110',
      name_search: 'PHACINOS',
      name: 'Phytolacca acinosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TDYEGD6;YXDDZGNV;B1CECZS2;52S8KCLV;S4T79VPV;W8E7SE3D;W92A2TE6;CWHAB46A;SZYRBCGW;7CC5ZKRV',
      statusContent: 'dot',
      hash: 'VE99CYXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE99CYXV'
    },
    {
      status: 210,
      sku: 'PM0041744',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244751',
      rtl_batch_array: '6244751',
      name_search: 'RIUCHGRO',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gr\u00f6n',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 9000,
      chnn_stock_retail: 9000,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRH69A6W',
      statusContent: 'dot',
      hash: 'BSLWAP8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSLWAP8A'
    },
    {
      status: 210,
      sku: 'PM0041743',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244748',
      rtl_batch_array: '6244748',
      name_search: 'RIRJTETS',
      name: "Ribes rubrum (red) 'Jonkheer van Tets'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 9998,
      chnn_stock_retail: 9998,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PE5WEESY;TNVJNS9Z;C6XGX1S9;LGVN25CS;X46XWZ3G;G51B96SG;P247B55X;AZ617VKL;11HTE2ZW;1WE89L3J;DJBNP9EP;N2N6AD49',
      statusContent: 'dot',
      hash: 'EC29YES9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EC29YES9'
    },
    {
      status: 210,
      sku: 'PM0041797',
      core_name: 'Plant',
      sppl_ean: '8720664690219',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423648',
      rtl_batch_array: '5423648',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 4.432,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: 'RWJHERN2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWJHERN2'
    },
    {
      status: 210,
      sku: 'PM0041827',
      core_name: 'Plant',
      btch_manufacturer: 514,
      rtl_batch_array_total: 1,
      btch_active_retail: '6254269',
      rtl_batch_array: '6254269',
      name_search: 'SAAURITA',
      name: 'Salix aurita',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2152,
      chnn_stock_retail: 2152,
      sppl_prcp: 1.175,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '546Y6YZK',
      statusContent: 'dot',
      hash: 'ZS7YVVSJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZS7YVVSJ'
    },
    {
      status: 210,
      sku: 'PM0083843',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356973',
      rtl_batch_array: '6356973',
      name_search: 'ECSUNDRE',
      name: "Echinacea 'Sundress'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_prcp: 3.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A1V4A24E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1V4A24E'
    },
    {
      status: 210,
      sku: 'PM0076727',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298570',
      rtl_batch_array: '6298570',
      name_search: 'CHOPYGMA',
      name: "Chamaecyparis obtusa 'Pygmaea'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 927,
      chnn_stock_retail: 927,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P7SDSH2B;K77GLWYX',
      statusContent: 'dot',
      hash: 'C7BXJZYR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7BXJZYR'
    },
    {
      status: 210,
      sku: 'PM0076728',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298572',
      rtl_batch_array: '6298572',
      name_search: 'CHPSUNGO',
      name: "Chamaecyparis pisifera 'Sungold'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4399,
      chnn_stock_retail: 4399,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZHLJTR1H;5XZEYB8G;7YNLHJLH;Y1BTW97G;GZJG415D;ECLLZG7H;JAYJDR7D;Z2T7J22C;5592HDCX;AA25R4RZ;2Z5DKD8B;9CYC76PC;ZHDTBX3N;B9C4KJ17;5D5APVLG;64XLHEK9;WP8N54HS',
      statusContent: 'dot',
      hash: 'GNG1155N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GNG1155N'
    },
    {
      status: 210,
      sku: 'PM0076729',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298573',
      rtl_batch_array: '6298573',
      name_search: 'DECGOLDT',
      name: "Deschampsia cespitosa 'Goldtau'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H7ZNEJDA;S4JYADT8',
      statusContent: 'dot',
      hash: 'HHJDCVSX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHJDCVSX'
    },
    {
      status: 810,
      sku: 'PM0076730',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298574',
      rtl_batch_array: '6298574',
      name_search: 'DECTARDI',
      name: "Deschampsia cespitosa 'Tardiflora'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 99,
      chnn_stock_retail: 99,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L9W3ACXT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9W3ACXT'
    },
    {
      status: 210,
      sku: 'PM0076731',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298575',
      rtl_batch_array: '6298575',
      name_search: 'DEGRACIL',
      name: 'Deutzia gracilis',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 2.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JBT9NCVV',
      statusContent: 'dot',
      hash: '5BK5JWL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BK5JWL8'
    },
    {
      status: 210,
      sku: 'PM0076732',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298577',
      rtl_batch_array: '6298577',
      name_search: 'DEHMROSE',
      name: "Deutzia hybrida 'Mont Rose'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 2.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W899YBYW;R9AG22AD;7TTREZGA;28ZDRJH2;8C47CTYZ;JCEC9E1C;ZDK9N5GB;EYVZDK6C;D8RCWYLB;P9C3V989;R1NN6VJZ',
      statusContent: 'dot',
      hash: 'N9141T1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9141T1C'
    },
    {
      status: 210,
      sku: 'PM0076733',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298578',
      rtl_batch_array: '6298578',
      name_search: 'DEPKALMI',
      name: "Deutzia purpurascens 'Kalmiiflora'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 2.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EABARBLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EABARBLD'
    },
    {
      status: 210,
      sku: 'PM0076726',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298569',
      rtl_batch_array: '6298569',
      name_search: 'YUFILAME',
      name: 'Yucca filamentosa',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 4.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G8W1SGKZ;2TYTVK3S',
      statusContent: 'dot',
      hash: 'EED2W1HH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EED2W1HH'
    },
    {
      status: 210,
      sku: 'PM0076736',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298581',
      rtl_batch_array: '6298581',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3135,
      chnn_stock_retail: 3135,
      sppl_prcp: 4.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: 'E38C69RA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E38C69RA'
    },
    {
      status: 210,
      sku: 'PM0076737',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298582',
      rtl_batch_array: '6298582',
      name_search: 'ELEGEDGE',
      name: "Elaeagnus ebbingei 'Gilt Edge'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 6.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NH8SNBKR',
      statusContent: 'dot',
      hash: 'SX8VCD3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SX8VCD3K'
    },
    {
      status: 210,
      sku: 'PM0076738',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298583',
      rtl_batch_array: '6298583',
      name_search: 'ELELIMEL',
      name: "Elaeagnus ebbingei 'Limelight'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1073,
      chnn_stock_retail: 1073,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S7KATAVG;CZR76YS3;DD52DZ1V;TBY91VCK;YHKAAZDJ;EC8R74HJ;9BEATZS7;YB43AZVZ;AW2LJWAP;RXKP9TNA;2XAPBH6L;SN26539T;AH9L44PV;TE4T2GN9;K47TA2NS;VAHJ6VD6',
      statusContent: 'dot',
      hash: '6WZ9J177',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6WZ9J177'
    },
    {
      status: 210,
      sku: 'PM0076739',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298585',
      rtl_batch_array: '6298585',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1215,
      chnn_stock_retail: 1215,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '18R7D8AH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18R7D8AH'
    },
    {
      status: 210,
      sku: 'PM0076740',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298587',
      rtl_batch_array: '6298587',
      name_search: 'ELPMACUL',
      name: "Elaeagnus pungens 'Maculata'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 393,
      chnn_stock_retail: 393,
      sppl_prcp: 6.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2398SLX;YY7AKGDZ;S8Z3ERSE;9K3BXH6A',
      statusContent: 'dot',
      hash: 'S7HG7LBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7HG7LBP'
    },
    {
      status: 910,
      sku: 'PM0084234',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRGBVIOL',
      name: "Prunella grand. 'Bella Violet'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'HJPWAK2C',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HJPWAK2C'
    },
    {
      status: 910,
      sku: 'PM0084235',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RHRRCHAM',
      name: "Rheum rhabarbarum 'Red Champagne'",
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      imageCore: 'LND2BH7W',
      statusContent: 'dot',
      hash: 'PXC9RG4L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PXC9RG4L'
    },
    {
      status: 210,
      sku: 'PM0076743',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298590',
      rtl_batch_array: '6298590',
      name_search: 'ESJAMIE',
      name: "Escallonia 'Jamie'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 919,
      chnn_stock_retail: 919,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WY4786NG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WY4786NG'
    },
    {
      status: 210,
      sku: 'PM0076744',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298591',
      rtl_batch_array: '6298591',
      name_search: 'ESRDREAM',
      name: "Escallonia 'Red Dream'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4741,
      chnn_stock_retail: 4741,
      sppl_prcp: 3.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RC26691P;N6STRVS4;8YJD4CGJ;VDBYH1J5;B2KSP2AJ;865E57B4;7VAHC76K;7V2N58RX;BGBRN7KV;XWCCTEDG;ZDTWBT96',
      statusContent: 'dot',
      hash: 'PN5AN67E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PN5AN67E'
    },
    {
      status: 810,
      sku: 'PM0076746',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298593',
      rtl_batch_array: '6298593',
      name_search: 'EUPHELLO',
      name: 'Euonymus phellomanus',
      sppl_size_code: '120STC12',
      rng_range_identifier: 'STE120C12',
      rng_range_description: 'Stem 120 cm C12',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 27.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '98NC8BJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98NC8BJ5'
    },
    {
      status: 210,
      sku: 'PM0076747',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298594',
      rtl_batch_array: '6298594',
      name_search: 'FICLMFIG',
      name: 'Ficus carica Little Miss Figgy',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NJV6R55C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJV6R55C'
    },
    {
      status: 210,
      sku: 'PM0077081',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300194',
      rtl_batch_array: '6300194',
      name_search: 'ELANGUST',
      name: 'Elaeagnus angustifolia',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 748,
      chnn_stock_retail: 748,
      sppl_prcp: 1.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ABW5CRA3',
      statusContent: 'dot',
      hash: 'Z8PNX6TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z8PNX6TV'
    },
    {
      status: 210,
      sku: 'PM0077082',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300195',
      rtl_batch_array: '6300195',
      name_search: 'ESABLOSS',
      name: "Escallonia 'Apple Blossom'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 2820,
      chnn_stock_retail: 2820,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KW33DPE7;BD928KDA;V99KPKBR;ZYCX82N3;GS752LH1;X3SAYVC5',
      statusContent: 'dot',
      hash: 'SH1XZWK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SH1XZWK1'
    },
    {
      status: 210,
      sku: 'PM0077083',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300196',
      rtl_batch_array: '6300196',
      name_search: 'ESDROSYR',
      name: "Escallonia 'Dart's Rosyred'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 2900,
      chnn_stock_retail: 2900,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPN15CP9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPN15CP9'
    },
    {
      status: 210,
      sku: 'PM0077084',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300197',
      rtl_batch_array: '6300197',
      name_search: 'ESDSEEDL',
      name: "Escallonia 'Donard Seedling'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 3420,
      chnn_stock_retail: 3420,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K7NWR78V;21V2KKVW;2RZV3G12;LE5TD63A;2Y61TTT1;SBL8T6LE;RBT2X6DH',
      statusContent: 'dot',
      hash: 'AKSYBK9K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AKSYBK9K'
    },
    {
      status: 210,
      sku: 'PM0077085',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300198',
      rtl_batch_array: '6300198, 6310266',
      name_search: 'EUEUROPA',
      name: 'Euonymus europaeus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 5531,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4JPDSZPB;JVXYY34W;454ACV8D;HG7KX2H9;ERCTD344;9PE5931T;41NWZ9XD;1KNT542Y;6BSV5BAW;5VBB4H27;Y7Z9WY9C',
      statusContent: 'dot',
      hash: 'RVZHTH6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RVZHTH6P'
    },
    {
      status: 210,
      sku: 'PM0077087',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300202',
      rtl_batch_array: '6300202',
      name_search: 'FOIWEND',
      name: 'Forsythia intermedia Week-End',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1960,
      chnn_stock_retail: 1960,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K951W24G;G7S5KVW8;AK2B2WR6;WS2KJ588;2CL7HKYV;XVBXP73D;CHA3X7VL;PHB51CJ7;RP1LHR1P;R5Z2ZP8B;L8H889HE;HVY5GX2A;LHP1T93Z;BP88CETX;TJEPBR76;YTJ98NAL;BH55KHAD;454YXT5R;T3L8ZJ3P',
      statusContent: 'dot',
      hash: 'K9YB23TW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9YB23TW'
    },
    {
      status: 210,
      sku: 'PM0077088',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300203',
      rtl_batch_array: '6300203, 6310280',
      name_search: 'HIRHAMNO',
      name: 'Hippophae rhamnoides',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3900,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PP9P12LE;AN75GR7W;L7V7E4AR;H3LRPA91',
      statusContent: 'dot',
      hash: 'BPBLW5VX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPBLW5VX'
    },
    {
      status: 210,
      sku: 'PM0077089',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300204',
      rtl_batch_array: '6300204',
      name_search: 'HYHIDCOT',
      name: "Hypericum 'Hidcote'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 2890,
      chnn_stock_retail: 2890,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8JKWGRW8;1D7BCWWP;PJANN412;RJRDBK61;GV5SKPY8;CN6BPNBC;25DBEWPB;KJHE47RZ;H7T24NG5;T1KSW4GB;5598GJWN',
      statusContent: 'dot',
      hash: 'WZP61X4V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZP61X4V'
    },
    {
      status: 210,
      sku: 'PM0077090',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300205',
      rtl_batch_array: '6300205',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 1870,
      chnn_stock_retail: 1870,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'CVRBAHY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVRBAHY7'
    },
    {
      status: 210,
      sku: 'PM0077092',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300207',
      rtl_batch_array: '6300207',
      name_search: 'LIOREGEL',
      name: 'Ligustrum obtusifolium regelianum',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3140,
      chnn_stock_retail: 3140,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JWBZ21CY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWBZ21CY'
    },
    {
      status: 210,
      sku: 'PM0077093',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300208',
      rtl_batch_array: '6300208',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '060080C24',
      rng_range_identifier: 'H060080C2.5',
      rng_range_description: 'H60 cm 80 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.623,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'V7LH4135',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V7LH4135'
    },
    {
      status: 210,
      sku: 'PM0077094',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300212',
      rtl_batch_array: '6300212',
      name_search: 'LIVLODEN',
      name: "Ligustrum vulgare 'Lodense'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '24JCXKEB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24JCXKEB'
    },
    {
      status: 210,
      sku: 'PM0077095',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300213',
      rtl_batch_array: '6300213',
      name_search: 'LOTATARI',
      name: 'Lonicera tatarica',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 2494,
      chnn_stock_retail: 2494,
      sppl_prcp: 1.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '72JEBHXW',
      statusContent: 'dot',
      hash: 'ZH1GPRLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZH1GPRLT'
    },
    {
      status: 210,
      sku: 'PM0077096',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300214',
      rtl_batch_array: '6300214',
      name_search: 'LONXYLOS',
      name: 'Lonicera xylosteum',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TP72CYT5;DTRPWB64;A1GY4G4Y;5KBTB34C;PHBKGEK1',
      statusContent: 'dot',
      hash: 'NR81H49R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NR81H49R'
    },
    {
      status: 210,
      sku: 'PM0077097',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300216',
      rtl_batch_array: '6300216',
      name_search: 'PHOANDRE',
      name: "Physocarpus opulifolius 'Andre'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1840,
      chnn_stock_retail: 1840,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '85XNSXHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85XNSXHS'
    },
    {
      status: 210,
      sku: 'PM0077099',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300218',
      rtl_batch_array: '6300218',
      name_search: 'PHVIRGIN',
      name: "Philadelphus 'Virginal'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2PVHE9H;SXSCKZBV;A2L4AY1P;PCJSYJLB;S27C5C2W;T77P7NBX',
      statusContent: 'dot',
      hash: 'A9HX7BZP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9HX7BZP'
    },
    {
      status: 210,
      sku: 'PM0077100',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300219',
      rtl_batch_array: '6300219',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '030050C24',
      rng_range_identifier: 'H030050C2.5',
      rng_range_description: 'H30 cm 50 cm C2.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'C2HTYZS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2HTYZS2'
    },
    {
      status: 210,
      sku: 'PM0077104',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300227',
      rtl_batch_array: '6300227, 6310365',
      name_search: 'ROCANINA',
      name: 'Rosa canina (o)',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1127,
      chnn_stock_retail: 3284,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKE1HCDZ;9XY9W4NY;N9WE9WG1',
      statusContent: 'dot',
      hash: 'XYNVLB3E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYNVLB3E'
    },
    {
      status: 210,
      sku: 'PM0077105',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300229',
      rtl_batch_array: '6300229, 6310370',
      name_search: 'RORALBA',
      name: "Rosa rugosa 'Alba'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 1890,
      chnn_stock_retail: 4780,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2BHRBDLN;2WPARPKY;2E2DYK13',
      statusContent: 'dot',
      hash: 'VJVYWDEV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJVYWDEV'
    },
    {
      status: 210,
      sku: 'PM0077106',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300230',
      rtl_batch_array: '6300230, 6310371',
      name_search: 'RORRUBRA',
      name: "Rosa rugosa 'Rubra'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 5050,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YARDZGEX;AL24KKYY;45G4Y1L3;28PZ8R3K;YL5ANGEW',
      statusContent: 'dot',
      hash: 'DDCT4A69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDCT4A69'
    },
    {
      status: 210,
      sku: 'PM0077107',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300235',
      rtl_batch_array: '6300235',
      name_search: 'SAPNANA',
      name: "Salix purpurea 'Nana'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5483R3TP;ZRLC8T6Z',
      statusContent: 'dot',
      hash: 'PGVWPAPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PGVWPAPS'
    },
    {
      status: 210,
      sku: 'PM0077110',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300239',
      rtl_batch_array: '6300239',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2540,
      chnn_stock_retail: 2540,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: 'SREKSDX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SREKSDX2'
    },
    {
      status: 210,
      sku: 'PM0077111',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300240',
      rtl_batch_array: '6300240',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2800,
      chnn_stock_retail: 2800,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: 'BPW114GV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPW114GV'
    },
    {
      status: 210,
      sku: 'PM0077112',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300241',
      rtl_batch_array: '6300241',
      name_search: 'SPBETULI',
      name: 'Spiraea betulifolia',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2400,
      chnn_stock_retail: 2400,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '12N7JGWE',
      statusContent: 'dot',
      hash: 'RR8HXSLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RR8HXSLL'
    },
    {
      status: 210,
      sku: 'PM0077113',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300242',
      rtl_batch_array: '6300242',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2135,
      chnn_stock_retail: 2135,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TCAXWZV9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TCAXWZV9'
    },
    {
      status: 210,
      sku: 'PM0077115',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300244',
      rtl_batch_array: '6300244',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2760,
      chnn_stock_retail: 2760,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: 'B8ZN7JV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8ZN7JV1'
    },
    {
      status: 210,
      sku: 'PM0077102',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300222',
      rtl_batch_array: '6300222',
      name_search: 'RIASCHMI',
      name: "Ribes alpinum 'Schmidt'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVALV4JT',
      statusContent: 'dot',
      hash: 'YT7L7ARX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT7L7ARX'
    },
    {
      status: 210,
      sku: 'PM0077103',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300223',
      rtl_batch_array: '6300223',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 13777,
      chnn_stock_retail: 13777,
      sppl_prcp: 1.425,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: '2CRT64YA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2CRT64YA'
    },
    {
      status: 210,
      sku: 'PM0077101',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300220',
      rtl_batch_array: '6300220',
      name_search: 'POFABBOT',
      name: "Potentilla fruticosa 'Abbotswood'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 2020,
      chnn_stock_retail: 2020,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TH1LEE7K;1V959R7C;K2K7LG5G;VE9BVSDV;V8H4GWY1;P4HZTC5J;T184CR1V;HPGZGZPZ',
      statusContent: 'dot',
      hash: 'YR21VPZ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YR21VPZ7'
    },
    {
      status: 210,
      sku: 'PM0076725',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298568',
      rtl_batch_array: '6298568',
      name_search: 'WISINENS',
      name: 'Wisteria sinensis',
      sppl_size_code: '100125C2',
      rng_range_identifier: 'H100125C2',
      rng_range_description: 'H100 cm 125 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.686,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GR3TKRKN;B8PNDWVB;T1BWX7CJ;ZHBCKZ13;K4W7C5V5;PE78N3BN;JVAC2983;89JLXBDK;9K7V9ELE;1P3HYWHN;XKZ4SZ75;PCPVSE7V;9RC389DS;4BG6DY3J',
      statusContent: 'dot',
      hash: 'J1L9DENX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1L9DENX'
    },
    {
      status: 210,
      sku: 'PM0077114',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300243',
      rtl_batch_array: '6300243',
      name_search: 'SPCGREFS',
      name: "Spiraea cinerea 'Grefsheim'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2560,
      chnn_stock_retail: 2560,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '63V2XB8A;D82TP6VL;YB2C378K;1G3XTCET;KY96WZPK;YPZ44DA7;S9GGRZ3S;NRHL3RXD;2WLDLELW;ZWAZ662L',
      statusContent: 'dot',
      hash: '1GXHPY4L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GXHPY4L'
    },
    {
      status: 210,
      sku: 'PM0077109',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300238',
      rtl_batch_array: '6300238',
      name_search: 'SAMNIGRA',
      name: 'Sambucus nigra',
      sppl_size_code: '060080C2',
      rng_range_identifier: 'H060080C2',
      rng_range_description: 'H60 cm 80 cm C2',
      sppl_stock_available: 1840,
      chnn_stock_retail: 1840,
      sppl_prcp: 1.325,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P51C9GNB;Y5AZ7S19;SNRHST7S;GKYAYK43;2ZPGJ5L5;ZGXT1WR5;7GT3E9KD;SAGK41Y5;SCC3G5W4',
      statusContent: 'dot',
      hash: 'D8AS5CJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D8AS5CJ7'
    },
    {
      status: 210,
      sku: 'PM0077091',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300206',
      rtl_batch_array: '6300206',
      name_search: 'KEJPLENI',
      name: "Kerria japonica 'Pleniflora'",
      sppl_size_code: '010020C2',
      rng_range_identifier: 'H010020C2',
      rng_range_description: 'H10 cm 20 cm C2',
      sppl_stock_available: 3850,
      chnn_stock_retail: 3850,
      sppl_prcp: 1.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y49EYZE8;945VKGPK;J19DN9A8;9Z6LSTEH;8N4SZZ37;27N1C4K3;YC9D7VAX;WHNEBXT4;V7NR7ETW;GZ9679V3;6T1ZH9LV;5EN8PSJ2;9881V54B;5TVCNZ8L',
      statusContent: 'dot',
      hash: 'CG13CV6G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CG13CV6G'
    },
    {
      status: 210,
      sku: 'PM0077098',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300217',
      rtl_batch_array: '6300217',
      name_search: 'PHLEMOIN',
      name: "Philadelphus 'Lemoinei'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2060,
      chnn_stock_retail: 2060,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z3DBDRTZ',
      statusContent: 'dot',
      hash: 'N27TA2CW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N27TA2CW'
    },
    {
      status: 810,
      sku: 'PM0076596',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349969',
      rtl_batch_array: '6349969',
      name_search: 'CRHELLFI',
      name: "Crocosmia 'Hellfire'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZRN43B8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZRN43B8'
    },
    {
      status: 210,
      sku: 'PM0076019',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349980',
      rtl_batch_array: '6349980',
      name_search: 'DAMENCHA',
      name: "Dahlia 'Mystic 'Enchantment'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ARZZB5G7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ARZZB5G7'
    },
    {
      status: 210,
      sku: 'PM0076022',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350041',
      rtl_batch_array: '6350041, 6355356',
      name_search: 'ECPFTHRO',
      name: "Echinacea purpurea 'Flame Thrower'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 736,
      chnn_stock_retail: 813,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZEN5KEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZEN5KEY'
    },
    {
      status: 210,
      sku: 'PM0076024',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350045',
      rtl_batch_array: '6350045',
      name_search: 'ECPGDREA',
      name: "Echinacea purpurea 'Glowing Dream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 573,
      chnn_stock_retail: 573,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VK5ZWB3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VK5ZWB3N'
    },
    {
      status: 210,
      sku: 'PM0076027',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349751',
      rtl_batch_array: '6350062, 6349751',
      name_search: 'ECPMSUPE',
      name: "Echinacea purpurea 'Magnus Superior'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 427,
      chnn_stock_retail: 3203,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JB6ATZKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JB6ATZKB'
    },
    {
      status: 210,
      sku: 'PM0076025',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350051',
      rtl_batch_array: '6350051',
      name_search: 'ECPJRHOO',
      name: 'Echinacea purpurea JS Robin Hood',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DYTHDK6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DYTHDK6X'
    },
    {
      status: 910,
      sku: 'PM0084236',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SESPWINT',
      name: "Sedum spurium 'Purple Winter'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '8GS83D85',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8GS83D85'
    },
    {
      status: 910,
      sku: 'PM0084237',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SCJPDIAM',
      name: 'Scabiosa japonica Pink Diamonds',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'P7VA8BD8;P3RA5TB9;X1GYGNJ7;A8X3J3PV;J529A9T8;G955ZC4E;J4RN3NP2',
      statusContent: 'dot',
      hash: 'TJNHPBNC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TJNHPBNC'
    },
    {
      status: 210,
      sku: 'PM0076384',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353089',
      rtl_batch_array: '6353089',
      name_search: 'PHPNIRVA',
      name: "Phlox paniculata 'Nirvana'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_order_minimum: 3,
      sppl_prcp: 1.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TK1HZP3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TK1HZP3Y'
    },
    {
      status: 210,
      sku: 'PM0076385',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353336',
      rtl_batch_array: '6353336',
      name_search: 'SAOWUERZ',
      name: "Salvia officinalis 'W\u00fcrzburg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZDBBVY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZDBBVY7'
    },
    {
      status: 210,
      sku: 'PM0076386',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301814',
      rtl_batch_array: '6353339, 6301814',
      name_search: 'SAPRRHAP',
      name: "Salvia pratensis 'Rose Rhapsody'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 175,
      chnn_stock_retail: 1188,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SVBZLE83',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SVBZLE83'
    },
    {
      status: 210,
      sku: 'PM0076735',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298580',
      rtl_batch_array: '6298580',
      name_search: 'ELECOMPA',
      name: "Elaeagnus ebbingei 'Compacta'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 4.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W4JPE6AE;T3HC5GYN;19ZV35VZ;JXVJDZY4',
      statusContent: 'dot',
      hash: '1LHAEEEN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1LHAEEEN'
    },
    {
      status: 210,
      sku: 'PM0077086',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300200',
      rtl_batch_array: '6300200',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 4040,
      chnn_stock_retail: 4040,
      sppl_prcp: 1.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: 'S617Z8KH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S617Z8KH'
    },
    {
      status: 910,
      sku: 'PM0084238',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SEKVARIE',
      name: "Sedum kamtschaticum 'Variegatum'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        '7VABV22X;JPPAYZG4;44EXWADG;TZZRWAS6;1KBY2DPT;911585LG;C1ZBKLZY;647DLRKP;V3WW7RBB;NB3EZR3R',
      statusContent: 'dot',
      hash: '21P7XPSG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '21P7XPSG'
    },
    {
      status: 910,
      sku: 'PM0084239',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'STLPPIXI',
      name: "Stokesia laevis 'Purple Pixie'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'X41BWZPC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'X41BWZPC'
    },
    {
      status: 810,
      sku: 'PM0084240',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363582',
      rtl_batch_array: '6363582',
      name_search: 'STLSMOON',
      name: "Stokesia laevis 'Silver Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TT7G642E',
      statusContent: 'dot',
      hash: 'DBRPJWLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBRPJWLX'
    },
    {
      status: 910,
      sku: 'PM0084241',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THVFAUS',
      name: "Thymus vulg. 'Faustini'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'D2CW5XVG;ECSRZXSZ;RY95X5ZT;E62D6JE7;7882SVZX;D1X568VG;257VR4X2;VVAZRT6D;HT3HR4T1;ALH7ZR8Z;DG5R1H9D;AD2LGRZ4;PWXALZDN;CRGYS8LG;VHL8A5VA;C6WN4DCA;496DRER5;2HS5TK4P;SLE47CBG;25JDRWZC;N72DGRBD',
      statusContent: 'dot',
      hash: 'ZDNW7AVV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZDNW7AVV'
    },
    {
      status: 910,
      sku: 'PM0084242',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TIPBOUQU',
      name: "Tiarella 'Pink Bouquet'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'WG5A6N4H',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'WG5A6N4H'
    },
    {
      status: 910,
      sku: 'PM0084243',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TRMDOUBL',
      name: "Tradescantia (A) 'Mac's Double'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'AEGPJCHC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AEGPJCHC'
    },
    {
      status: 910,
      sku: 'PM0084244',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TRGRANDI',
      name: 'Trillium grandiflorum',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        'L3ZDN78P;VCW94L3N;98CHHKVS;RX8B7SCW;L2WCRVPS;L626R8X9;H9N5ELT9;RSYGV128',
      statusContent: 'dot',
      hash: 'Y99JKSZW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y99JKSZW'
    },
    {
      status: 210,
      sku: 'PM0041961',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184142',
      rtl_batch_array: '6184142',
      name_search: 'PEAVIRID',
      name: 'Pennisetum alopecuroides viridescens',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 925,
      chnn_stock_retail: 925,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLYLVCPZ;2WEBGKVD;CK9A7C8A;LDVA2DDL;ZVR67242;19GS5WDD;49LHDP6T;LYRK3AH5',
      statusContent: 'dot',
      hash: 'JHAL7KH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JHAL7KH5'
    },
    {
      status: 210,
      sku: 'PM0076030',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350096',
      rtl_batch_array: '6350096',
      name_search: 'ECPSMMAM',
      name: 'Echinacea p. Sweet Meadow Mama',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9N53E8W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N53E8W1'
    },
    {
      status: 210,
      sku: 'PM0076031',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350103',
      rtl_batch_array: '6350103',
      name_search: 'ECPDFUCH',
      name: 'Echinacea Paso Doble Fuchsia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7YLTELJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7YLTELJ'
    },
    {
      status: 210,
      sku: 'PM0076388',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353354',
      rtl_batch_array: '6353354',
      name_search: 'SABRESSI',
      name: "Saponaria 'Bressingham'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SCCNSD85;TWKNBE6K;ERTAG42N;9KPX2TR1;B7YXPP7A;JGSXB7Z8;4HACVNSB;98TLE15E;KBY8H7JH;2D9ABP9R;4VERY84J;1JE9JL2L',
      statusContent: 'dot',
      hash: '6X2ZW2NN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X2ZW2NN'
    },
    {
      status: 210,
      sku: 'PM0077120',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300250',
      rtl_batch_array: '6300250',
      name_search: 'SPNSNOWM',
      name: "Spiraea nipponica 'Snowmound'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1820,
      chnn_stock_retail: 1820,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '82K9LJJB;T8NE9E4N;LT6ZCXJR;WB12XJ63;KGPAP5J4',
      statusContent: 'dot',
      hash: '1T44ZTBD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1T44ZTBD'
    },
    {
      status: 810,
      sku: 'PM0076033',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350150',
      rtl_batch_array: '6350150',
      name_search: 'EUCEFEAT',
      name: "Eupatorium capillifolium 'Emerald Feather'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EVZL3AN8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EVZL3AN8'
    },
    {
      status: 910,
      sku: 'PM0041974',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'EUAUTUMN',
      name: 'Eucomis autumnalis',
      rng_range_identifier: 'H025030C3.5',
      rng_range_description: 'H25 cm 30 cm C3.5',
      statusContent: 'dot',
      hash: '9RW4DJ9B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9RW4DJ9B'
    },
    {
      status: 210,
      sku: 'PM0083844',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356977',
      rtl_batch_array: '6356977',
      name_search: 'IRISSQUE',
      name: "Iris sibirica 'Snow Queen'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 308,
      chnn_stock_retail: 308,
      sppl_prcp: 2.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SC98G2VH;P41AY324;4HV6TLL7;NAD15KV4;CESS1G39',
      statusContent: 'dot',
      hash: 'R5R72Y62',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5R72Y62'
    },
    {
      status: 210,
      sku: 'PM0083845',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356978',
      rtl_batch_array: '6356978',
      name_search: 'NEJWALKE',
      name: 'Nepeta Junior Walker',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 2.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HRWBR36L;DLAZRLD3;Y3NYRCHX;253HKYWC;KDRXRW8E',
      statusContent: 'dot',
      hash: 'HGJBYHKL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGJBYHKL'
    },
    {
      status: 210,
      sku: 'PM0041882',
      core_name: 'Plant',
      sppl_ean: '8720664812246',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420732',
      rtl_batch_array: '5420732',
      name_search: 'VIMBLANC',
      name: "Vitis 'Muscat Blanc'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYV2EZDB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYV2EZDB'
    },
    {
      status: 210,
      sku: 'PM0076778',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298633',
      rtl_batch_array: '6298633',
      name_search: 'HYPLIMEL',
      name: "Hydrangea paniculata 'Limelight'",
      sppl_size_code: '100STC74',
      rng_range_identifier: 'STE100C7.5',
      rng_range_description: 'Stem 100 cm C7.5',
      sppl_stock_available: 987,
      chnn_stock_retail: 987,
      sppl_prcp: 21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NK81D49J;DBYYZD5C;TR4B16B4;6HWEB44N;NWJ93PPW;K8H912Y8;W593AS58;9B9RYA1A;SZTS9Y9H;ZNGXGT4J;EWP3E73S;XSW971RH;X1W2TBX9;GGDPYPJZ;E9VNSHZX;6SX1CPV7;N5YWW2PT;NKZNW875;RWBA9JRC;1RCX9YTP;JZBZSR58',
      statusContent: 'dot',
      hash: '7HLKL7J4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7HLKL7J4'
    },
    {
      status: 210,
      sku: 'PM0077130',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300261',
      rtl_batch_array: '6300261',
      name_search: 'SYVULGAR',
      name: 'Syringa vulgaris',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 1200,
      chnn_stock_retail: 1200,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V2HRB4X5;YKB45ZLV;15KSWZR1;S1LRAANK;A6PWADE1;E1HJATGN;AYDVATA4;WJAJARZR;4361DXJ2;Y4G96EP2;8ZJ1PJ7D',
      statusContent: 'dot',
      hash: '5VSHR8TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VSHR8TV'
    },
    {
      status: 210,
      sku: 'PM0083846',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356979',
      rtl_batch_array: '6356979',
      name_search: 'OEFFYRVE',
      name: "Oenothera fruticosa 'Fyrverkeri'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_prcp: 2.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BCHZPYS;DAG6PPLC',
      statusContent: 'dot',
      hash: 'BY7V159D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY7V159D'
    },
    {
      status: 810,
      sku: 'PM0076749',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298596',
      rtl_batch_array: '6298596',
      name_search: 'FICLMFIG',
      name: 'Ficus carica Little Miss Figgy',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 61,
      chnn_stock_retail: 61,
      sppl_prcp: 8.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P6VVXEJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6VVXEJA'
    },
    {
      status: 210,
      sku: 'PM0076750',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298597',
      rtl_batch_array: '6298597',
      name_search: 'GOVSBUTT',
      name: "Gomphostigma virgatum 'Silver Butterfly'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 958,
      chnn_stock_retail: 958,
      sppl_prcp: 3.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5BGY61LB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BGY61LB'
    },
    {
      status: 210,
      sku: 'PM0083847',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356983',
      rtl_batch_array: '6356983',
      name_search: 'SASCLARE',
      name: 'Salvia sclarea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_prcp: 2.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RE3V9TYG;LN2K94DS;CEHNGW6V;YV46K9PV',
      statusContent: 'dot',
      hash: '4WPGKRPS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WPGKRPS'
    },
    {
      status: 210,
      sku: 'PM0077116',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300245',
      rtl_batch_array: '6300245',
      name_search: 'SPJAWATE',
      name: "Spiraea japonica 'Anthony Waterer'",
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 2480,
      chnn_stock_retail: 2480,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6N34289A;N41SRZLV;K1ZBXWT6;D8VD1EYH;5K1AAHET;6S5J6HY7;4A27DL45;DZL718AT;HDCJSWPV',
      statusContent: 'dot',
      hash: 'NC4CX53X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NC4CX53X'
    },
    {
      status: 210,
      sku: 'PM0076756',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298604',
      rtl_batch_array: '6298604',
      name_search: 'HEHALEXA',
      name: 'Hebe Hebedonna Alexa',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4258,
      chnn_stock_retail: 4258,
      sppl_order_minimum: 3,
      sppl_prcp: 2.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATPLHZT7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATPLHZT7'
    },
    {
      status: 210,
      sku: 'PM0076757',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298606',
      rtl_batch_array: '6298606',
      name_search: 'HEHANNA',
      name: 'Hebe Hebedonna Anna',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 437,
      chnn_stock_retail: 437,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6RCLCBJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6RCLCBJD'
    },
    {
      status: 210,
      sku: 'PM0076758',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298607',
      rtl_batch_array: '6298607',
      name_search: 'HEDALBLU',
      name: 'Hebe Hebedonna \u00ae Anna Light Blue',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 963,
      chnn_stock_retail: 963,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '11ZNY69C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11ZNY69C'
    },
    {
      status: 210,
      sku: 'PM0076759',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298608',
      rtl_batch_array: '6298608',
      name_search: 'HEDALBLU',
      name: 'Hebe Hebedonna \u00ae Anna Light Blue',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 289,
      chnn_stock_retail: 289,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BNY9NG4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNY9NG4P'
    },
    {
      status: 210,
      sku: 'PM0076760',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298610',
      rtl_batch_array: '6298610',
      name_search: 'HEHBRITT',
      name: 'Hebe Hebedonna Britta',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 685,
      chnn_stock_retail: 685,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2R117HK7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2R117HK7'
    },
    {
      status: 210,
      sku: 'PM0076761',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298612',
      rtl_batch_array: '6298612',
      name_search: 'HEHJENNA',
      name: 'Hebe Hebedonna Jenna',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X6ENXHG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X6ENXHG9'
    },
    {
      status: 210,
      sku: 'PM0076762',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298613',
      rtl_batch_array: '6298613',
      name_search: 'HEDEVA',
      name: 'Hebe Donna Eva',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4642,
      chnn_stock_retail: 4642,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7JREN97N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JREN97N'
    },
    {
      status: 210,
      sku: 'PM0076763',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298614',
      rtl_batch_array: '6298614',
      name_search: 'HEDEVA',
      name: 'Hebe Donna Eva',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 892,
      chnn_stock_retail: 892,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZ77KKCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZ77KKCR'
    },
    {
      status: 210,
      sku: 'PM0076764',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298615',
      rtl_batch_array: '6298615',
      name_search: 'HEHJULIA',
      name: 'Hebe Hebedonna Julia',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1294,
      chnn_stock_retail: 1294,
      sppl_prcp: 2.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EST1PVW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EST1PVW8'
    },
    {
      status: 210,
      sku: 'PM0076765',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298616',
      rtl_batch_array: '6298616',
      name_search: 'HEHJULIA',
      name: 'Hebe Hebedonna Julia',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 382,
      chnn_stock_retail: 382,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCXT5ATN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCXT5ATN'
    },
    {
      status: 210,
      sku: 'PM0076766',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298618',
      rtl_batch_array: '6298618',
      name_search: 'HEHNIKKA',
      name: 'Hebe Hebedonna Nikka',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EAD6KNRW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAD6KNRW'
    },
    {
      status: 210,
      sku: 'PM0076767',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298619',
      rtl_batch_array: '6298619',
      name_search: 'HEEGEM',
      name: "Hebe 'Emerald Gem'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 959,
      chnn_stock_retail: 959,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VTR827XW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VTR827XW'
    },
    {
      status: 210,
      sku: 'PM0076768',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298620',
      rtl_batch_array: '6298620',
      name_search: 'HEBHBLUE',
      name: 'Hebe Hebegranda Blue',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 503,
      chnn_stock_retail: 503,
      sppl_prcp: 6.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5AD3GL49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AD3GL49'
    },
    {
      status: 210,
      sku: 'PM0076769',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298621',
      rtl_batch_array: '6298621',
      name_search: 'HEONZGOL',
      name: "Hebe odora 'New Zealand Gold'",
      sppl_size_code: 'LEVP10',
      rng_range_identifier: 'P10',
      rng_range_description: 'P10',
      sppl_stock_available: 959,
      chnn_stock_retail: 959,
      sppl_order_minimum: 3,
      sppl_prcp: 0.908,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G26RD4TJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G26RD4TJ'
    },
    {
      status: 210,
      sku: 'PM0076770',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298622',
      rtl_batch_array: '6298622',
      name_search: 'HEVDPURP',
      name: 'Hebe Vinoa \u00ae Dark Purple',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2179,
      chnn_stock_retail: 2179,
      sppl_prcp: 3.171,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JLPJZ1H3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLPJZ1H3'
    },
    {
      status: 210,
      sku: 'PM0076771',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298623',
      rtl_batch_array: '6298623',
      name_search: 'HEVDPURP',
      name: 'Hebe Vinoa \u00ae Dark Purple',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 514,
      chnn_stock_retail: 514,
      sppl_prcp: 6.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1E6Y6B3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1E6Y6B3G'
    },
    {
      status: 210,
      sku: 'PM0076772',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298624',
      rtl_batch_array: '6298624',
      name_search: 'HEHARBOR',
      name: "Hedera helix 'Arborescens'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 745,
      chnn_stock_retail: 745,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VVS7A2LD;7AAA9RD8;8T9N1BVX;516SYPE8;N95RLPKS;ZPP4V3VA;YZDLY61K;YWYZV45C;A4P7G8WB',
      statusContent: 'dot',
      hash: '2ZDZJWKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZDZJWKS'
    },
    {
      status: 210,
      sku: 'PM0076776',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298628',
      rtl_batch_array: '6298628',
      name_search: 'HEHZORGV',
      name: "Hedera helix 'Zorgvlied'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7CWJ4A28',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CWJ4A28'
    },
    {
      status: 210,
      sku: 'PM0076777',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298632',
      rtl_batch_array: '6298632',
      name_search: 'HYPBOMBS',
      name: "Hydrangea paniculata 'Bombshell'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 930,
      chnn_stock_retail: 930,
      sppl_prcp: 3.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8ZWW2GSB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZWW2GSB'
    },
    {
      status: 210,
      sku: 'PM0076779',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298634',
      rtl_batch_array: '6298634',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_prcp: 5.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: 'HG496LL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HG496LL3'
    },
    {
      status: 810,
      sku: 'PM0076780',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298635',
      rtl_batch_array: '6298635',
      name_search: 'HYPPWINK',
      name: 'Hydrangea paniculata Pinky Winky',
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 10.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PKL6JEX4;XSGECHXB;KDP16WNL;LAVR7HSX;PP5ZPV5N;VG1BBBXX;LVZ75NDE;9E8LY377;TYYHD8KZ;6NSN938V;JBZVJSWH;A5J7CA9E',
      statusContent: 'dot',
      hash: '6GC1XZDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GC1XZDP'
    },
    {
      status: 210,
      sku: 'PM0076782',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298637',
      rtl_batch_array: '6298637',
      name_search: 'HYQSQUEE',
      name: 'Hydrangea quercifolia Snow Queen',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.475,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4BTE3SYV;GPKPDEEH;YZLDR7J1',
      statusContent: 'dot',
      hash: 'JSZ7VH82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSZ7VH82'
    },
    {
      status: 210,
      sku: 'PM0076783',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298638',
      rtl_batch_array: '6298638',
      name_search: 'HYQSNOWF',
      name: "Hydrangea quercifolia 'Snowflake'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VYKE32HW;D6XAEKDS;BGHGRNH2;EAC39HBS;8SD5ZXWK;P8JEJB4S;KK32262J;KV1J8B7G;LJVCS9AK;N24GLAX2',
      statusContent: 'dot',
      hash: 'VJV3P2AW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJV3P2AW'
    },
    {
      status: 210,
      sku: 'PM0076784',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298639',
      rtl_batch_array: '6298639',
      name_search: 'HYQRSLIP',
      name: "Hydrangea quercifolia 'Ruby Slippers'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 4.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EJ1Z69VA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EJ1Z69VA'
    },
    {
      status: 210,
      sku: 'PM0076785',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298640',
      rtl_batch_array: '6298640',
      name_search: 'HYQSGIAN',
      name: "Hydrangea quercifolia 'Snow Giant'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BAVVJ1BL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAVVJ1BL'
    },
    {
      status: 210,
      sku: 'PM0076786',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298642',
      rtl_batch_array: '6298642',
      name_search: 'ILCCONVE',
      name: "Ilex crenata 'Convexa'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 9.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X3PT74W;B5N7EE3P;JHTBWHDX;E64JCXHT;LZ52XG6T;5DYW8HN9',
      statusContent: 'dot',
      hash: '7YLLHLRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7YLLHLRH'
    },
    {
      status: 210,
      sku: 'PM0083848',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356984',
      rtl_batch_array: '6356984',
      name_search: 'SASVWHIT',
      name: "Salvia sclarea 'Vatican White'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_prcp: 2.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y7YPSVPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y7YPSVPT'
    },
    {
      status: 210,
      sku: 'PM0076788',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298644',
      rtl_batch_array: '6298644',
      name_search: 'ILECGGEM',
      name: "Ilex crenata 'Golden Gem'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 957,
      chnn_stock_retail: 957,
      sppl_prcp: 3.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6H1T152;ACELZCTX;L8LWT5T5;DNH7B45X;W1E88G6R;PWBSSNSK;H37PG1Y5;BES2N1AV;1WTGN263;4KJBZN66',
      statusContent: 'dot',
      hash: 'X8AH8AWN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8AH8AWN'
    },
    {
      status: 210,
      sku: 'PM0076789',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298645',
      rtl_batch_array: '6298645',
      name_search: 'ILMLRASC',
      name: 'Ilex meserveae Little Rascal',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 8006,
      chnn_stock_retail: 8006,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '13GARV24;71E7CTSY;BS8SHAWL;XPE48L27',
      statusContent: 'dot',
      hash: 'AG4NCYL9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AG4NCYL9'
    },
    {
      status: 210,
      sku: 'PM0076790',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298646',
      rtl_batch_array: '6298646',
      name_search: 'INHOWELL',
      name: 'Indigofera howellii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 327,
      chnn_stock_retail: 327,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5V52YL9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V52YL9P'
    },
    {
      status: 210,
      sku: 'PM0076791',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298647',
      rtl_batch_array: '6298647',
      name_search: 'KALGINKO',
      name: "Kalmia latifolia 'Ginkona'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 2335,
      chnn_stock_retail: 2335,
      sppl_prcp: 7.34,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EY9HZ8Z5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EY9HZ8Z5'
    },
    {
      status: 210,
      sku: 'PM0076792',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298648',
      rtl_batch_array: '6298648',
      name_search: 'KALBBOY',
      name: "Kalmia latifolia 'Big Boy'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 443,
      chnn_stock_retail: 443,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ANXH3THG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ANXH3THG'
    },
    {
      status: 210,
      sku: 'PM0077117',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300246',
      rtl_batch_array: '6300246',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 2440,
      chnn_stock_retail: 2440,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: 'G82PD176',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G82PD176'
    },
    {
      status: 210,
      sku: 'PM0077118',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300247',
      rtl_batch_array: '6300247',
      name_search: 'SPJGENPE',
      name: "Spiraea japonica 'Genpei'",
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 985,
      chnn_stock_retail: 985,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PRTEJ6YC;KHLS791P;WDAD7TJD;C5GC5B4S;KH9RYGAL;HN8X8LVZ;K4LTRL73;6XNVETLJ;K7V9V1PB;K53P4VB7;B67PT6E7;T356L7VC;8CWKTXJE;J1LTXLPZ;CX3NHT1C;K8EL46WH;R1W9DHB8;ESS6WEGG;84N5DTZ7;YZDS8ADS;WPBAX9LD;WPYSKKY6;8AZZENHP;4PXS435B;E9DCX5AY;1X9EBEY6;GD194DYT',
      statusContent: 'dot',
      hash: 'LDTTWCWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDTTWCWZ'
    },
    {
      status: 210,
      sku: 'PM0077121',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300251',
      rtl_batch_array: '6300251',
      name_search: 'SPTHUNBE',
      name: 'Spiraea thunbergii',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2060,
      chnn_stock_retail: 2060,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H5BVPVZ6;X3719ERG',
      statusContent: 'dot',
      hash: 'TGC22BL4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGC22BL4'
    },
    {
      status: 210,
      sku: 'PM0077122',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300252',
      rtl_batch_array: '6300252',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3560,
      chnn_stock_retail: 3560,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'R9LN3J7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R9LN3J7G'
    },
    {
      status: 210,
      sku: 'PM0077125',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300255',
      rtl_batch_array: '6300255',
      name_search: 'SYALAEVI',
      name: 'Symphoricarpos albus laevigatus',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYNDYT92;PNKP8B3E',
      statusContent: 'dot',
      hash: 'C4H11ZEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4H11ZEP'
    },
    {
      status: 210,
      sku: 'PM0077127',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300257',
      rtl_batch_array: '6300257',
      name_search: 'SYCHENAU',
      name: 'Symphoricarpos chenaultii',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2530,
      chnn_stock_retail: 2530,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V36Z9694',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V36Z9694'
    },
    {
      status: 210,
      sku: 'PM0077128',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300258',
      rtl_batch_array: '6300258',
      name_search: 'SYDMBERR',
      name: "Symphoricarpos doorenbosii 'Magic Berry'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2910,
      chnn_stock_retail: 2910,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BJTZXBBP;VYBCZ542;PEK5E6TW;H19Y2CY9;XN9BJ7KT;RVN3833W;J1Y7CTH4;HDW16A1V;NJ8DR65E;W519LKGS',
      statusContent: 'dot',
      hash: '4Z8HTE78',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z8HTE78'
    },
    {
      status: 210,
      sku: 'PM0077129',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300259',
      rtl_batch_array: '6300259',
      name_search: 'SYDMPEAR',
      name: "Symphoricarpos doorenbosii 'Mother of Pearl'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1870,
      chnn_stock_retail: 1870,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2RXRR8ET;LC9X2XWV;W65DPN5Y;52L9CRZR;75N7N95J;DTZBCT28;6BN1NAVN;PNJ23EA1',
      statusContent: 'dot',
      hash: 'J7217SHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7217SHS'
    },
    {
      status: 210,
      sku: 'PM0077131',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300262',
      rtl_batch_array: '6300262, 6356222',
      name_search: 'VIDAVIDI',
      name: 'Viburnum davidii',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1300,
      chnn_stock_retail: 2800,
      sppl_prcp: 2.373,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XPE249D6;TYZR2X4X;4A9PG5D6;NX7YGBG6;EL2RZJ48;DTR1EY8Z',
      statusContent: 'dot',
      hash: '7GY28A8A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GY28A8A'
    },
    {
      status: 210,
      sku: 'PM0076774',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298626',
      rtl_batch_array: '6298626',
      name_search: 'HEHGRIPP',
      name: "Hedera helix 'Green Ripple'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 6,
      sppl_prcp: 3.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CA42PXGD;5S6CPK7C;65YXDBZR',
      statusContent: 'dot',
      hash: 'Z31SS9J9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z31SS9J9'
    },
    {
      status: 810,
      sku: 'PM0076775',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298627',
      rtl_batch_array: '6298627',
      name_search: 'HEHIVALA',
      name: "Hedera helix 'Ivalace'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 6,
      sppl_prcp: 3.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K216894A;CYHYD3HR',
      statusContent: 'dot',
      hash: 'JE2JLP13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JE2JLP13'
    },
    {
      status: 210,
      sku: 'PM0077119',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300248',
      rtl_batch_array: '6300248',
      name_search: 'SPNJBRID',
      name: "Spiraea nipponica 'June Bride'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TZD9G8DS;Y27GCWAD',
      statusContent: 'dot',
      hash: 'VS328ESD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VS328ESD'
    },
    {
      status: 210,
      sku: 'PM0077126',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300256',
      rtl_batch_array: '6300256',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2900,
      chnn_stock_retail: 2900,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'XKZ4LK1D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XKZ4LK1D'
    },
    {
      status: 210,
      sku: 'PM0077123',
      core_name: 'Plant',
      btch_manufacturer: 430,
      rtl_batch_array_total: 2,
      btch_active_retail: '6356585',
      rtl_batch_array: '6300253, 6356585',
      name_search: 'STICRISP',
      name: "Stephanandra incisa 'Crispa'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 8000,
      sppl_prcp: 1.224,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4WKEHVNX;G942RDKE;WNTD4A5D;1DJSZ55X;488BCPSJ;A8AN1SL2;JJLRJGBJ;N968K382;C6EG7KY2;GAABDAKL;RN3CKPSV;CPXRR1JT',
      statusContent: 'dot',
      hash: 'YH8D6VSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YH8D6VSP'
    },
    {
      status: 210,
      sku: 'PM0076597',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349994',
      rtl_batch_array: '6349994, 6320846',
      name_search: 'DISVALEN',
      name: 'Dicentra spectabilis Valentine',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 719,
      chnn_stock_retail: 791,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '16V7Y8SD',
      statusContent: 'dot',
      hash: '85P34CSR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85P34CSR'
    },
    {
      status: 210,
      sku: 'PM0076032',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350105',
      rtl_batch_array: '6350105',
      name_search: 'ECPGINGE',
      name: 'Echinacea Prima Ginger',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 296,
      chnn_stock_retail: 296,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8213C367',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8213C367'
    },
    {
      status: 210,
      sku: 'PM0076029',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350095',
      rtl_batch_array: '6350095',
      name_search: 'ECPSCANT',
      name: "Echinacea purpurea 'Supreme Cantaloupe'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDJWCSAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDJWCSAV'
    },
    {
      status: 210,
      sku: 'PM0076034',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350163',
      rtl_batch_array: '6350163',
      name_search: 'EUGBORAN',
      name: "Euphorbia griffithii 'Beauty Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G23BEPPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G23BEPPG'
    },
    {
      status: 210,
      sku: 'PM0083849',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356985',
      rtl_batch_array: '6356985',
      name_search: 'SCINCANA',
      name: 'Scutellaria incana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 882,
      chnn_stock_retail: 882,
      sppl_prcp: 2.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GG2S1KD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GG2S1KD6'
    },
    {
      status: 210,
      sku: 'PM0083850',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356987',
      rtl_batch_array: '6356987',
      name_search: 'STMHUMME',
      name: "Stachys monieri 'Hummelo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 2.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8YGKP2VH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YGKP2VH'
    },
    {
      status: 210,
      sku: 'PM0083851',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356988',
      rtl_batch_array: '6356988',
      name_search: 'TRBWONDE',
      name: "Tricyrtis 'Blue Wonder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 820,
      chnn_stock_retail: 820,
      sppl_prcp: 2.9,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J17V7STJ',
      statusContent: 'dot',
      hash: 'JDY7G39C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JDY7G39C'
    },
    {
      status: 210,
      sku: 'PM0076387',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353342',
      rtl_batch_array: '6353342',
      name_search: 'SAPTSERE',
      name: "Salvia pratensis 'Twilight Serenade'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 259,
      chnn_stock_retail: 259,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AAB5G9J6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AAB5G9J6'
    },
    {
      status: 210,
      sku: 'PM0076389',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353280',
      rtl_batch_array: '6353280',
      name_search: 'RODPOKER',
      name: "Rodgersia 'Dark Pokers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 279,
      chnn_stock_retail: 279,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PBZVYHW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PBZVYHW2'
    },
    {
      status: 210,
      sku: 'PM0077124',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300254',
      rtl_batch_array: '6300254',
      name_search: 'SYALAEVI',
      name: 'Symphoricarpos albus laevigatus',
      sppl_size_code: '040060C2',
      rng_range_identifier: 'H040060C2',
      rng_range_description: 'H40 cm 60 cm C2',
      sppl_stock_available: 2440,
      chnn_stock_retail: 2440,
      sppl_prcp: 1.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EYNDYT92;PNKP8B3E',
      statusContent: 'dot',
      hash: 'B6GLCZ13',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B6GLCZ13'
    },
    {
      status: 210,
      sku: 'PM0083852',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356989',
      rtl_batch_array: '6356989',
      name_search: 'CISFIRE',
      name: "Cistus 'Snow Fire'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LSD2SJCN;Y68TWERB',
      statusContent: 'dot',
      hash: 'D3P4Z5BA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3P4Z5BA'
    },
    {
      status: 810,
      sku: 'PM0083853',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356990',
      rtl_batch_array: '6356990',
      name_search: 'CISALVII',
      name: 'Cistus salviifolius',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EHR5NPY2',
      statusContent: 'dot',
      hash: '5W42E9CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5W42E9CH'
    },
    {
      status: 810,
      sku: 'PM0084245',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378307',
      rtl_batch_array: '6378307',
      name_search: 'COKMWAY',
      name: "Cornus kousa 'Milky Way'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 8.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E51N1A63;XH6851XT;ALY55BCN;XH66YXHW;L7XTXZ8G;XCK57A7D;VNHZZZX7',
      statusContent: 'dot',
      hash: 'Y9NSRKWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9NSRKWX'
    },
    {
      status: 810,
      sku: 'PM0084246',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378309',
      rtl_batch_array: '6378309',
      name_search: 'COKSATOM',
      name: "Cornus kousa 'Satomi'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 8.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46W63H9G;ZWPZR8X9;HCL32Y1H;WE7LXD8X',
      statusContent: 'dot',
      hash: 'ZB2B317B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZB2B317B'
    },
    {
      status: 810,
      sku: 'PM0084247',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378312',
      rtl_batch_array: '6378312',
      name_search: 'COKSCHME',
      name: "Cornus kousa 'Schmetterling'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 8.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XSCPAWC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XSCPAWC1'
    },
    {
      status: 910,
      sku: 'PM0084248',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378314',
      rtl_batch_array: '6378314',
      name_search: 'COKSCHME',
      name: "Cornus kousa 'Schmetterling'",
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 22.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BWW54GD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BWW54GD'
    },
    {
      status: 210,
      sku: 'PM0084249',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378315',
      rtl_batch_array: '6378315',
      name_search: 'COCGSPIR',
      name: 'Cotinus coggygria Golden Spirit',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 816,
      chnn_stock_retail: 816,
      sppl_prcp: 4.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8W7RZKKT;WKP8142W;ARCCVBPY;6ABDDDPH;8S43T2GX',
      statusContent: 'dot',
      hash: 'HXK6RBLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXK6RBLL'
    },
    {
      status: 910,
      sku: 'PM0084250',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRHOOKER',
      name: 'Crinodendron hookerianum',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      imageCore: 'KG8XW2NE',
      statusContent: 'dot',
      hash: 'GJ979H9R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'GJ979H9R'
    },
    {
      status: 910,
      sku: 'PM0084251',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CRIHAHOF',
      name: "Crinodendron hookerianum 'Ada Hoffmann'",
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      statusContent: 'dot',
      hash: 'ZRL9JHBT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZRL9JHBT'
    },
    {
      status: 210,
      sku: 'PM0084252',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378316',
      rtl_batch_array: '6378316',
      name_search: 'DACNEORU',
      name: 'Daphne cneorum',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_prcp: 9.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2YLAGB6Z',
      statusContent: 'dot',
      hash: '4A1Y8ZH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4A1Y8ZH8'
    },
    {
      status: 210,
      sku: 'PM0084253',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378317',
      rtl_batch_array: '6378317',
      name_search: 'DAGRCROW',
      name: "Daphne gemmata 'Royal Crown'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_prcp: 7.01,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SV5ZKHAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SV5ZKHAL'
    },
    {
      status: 210,
      sku: 'PM0084254',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378319',
      rtl_batch_array: '6378319',
      name_search: 'DAOAUREO',
      name: "Daphne odora 'Aureomarginata'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 16929,
      chnn_stock_retail: 16929,
      sppl_prcp: 5.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PZNTZXHX;HC6TPSNK',
      statusContent: 'dot',
      hash: '19G4NXZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19G4NXZB'
    },
    {
      status: 910,
      sku: 'PM0084255',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DAPPRINC',
      name: 'Daphne Perfume Princess',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'TLDHH6DT',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'TLDHH6DT'
    },
    {
      status: 210,
      sku: 'PM0084256',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378320',
      rtl_batch_array: '6378320',
      name_search: 'DATRANSA',
      name: 'Daphne transatlantica',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3603,
      chnn_stock_retail: 3603,
      sppl_prcp: 6.46,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BDP7NSYP',
      statusContent: 'dot',
      hash: '4B85X5LR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4B85X5LR'
    },
    {
      status: 910,
      sku: 'PM0084257',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'DATPFRAG',
      name: 'Daphne transatlantica Pink Fragrance',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'KN87YE1S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KN87YE1S'
    },
    {
      status: 210,
      sku: 'PM0084258',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378413',
      rtl_batch_array: '6378413',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 154,
      chnn_stock_retail: 154,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: '6BBW897X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BBW897X'
    },
    {
      status: 210,
      sku: 'PM0084259',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378414',
      rtl_batch_array: '6378414',
      name_search: 'ACPDESHO',
      name: "Acer palmatum 'Deshojo'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 983,
      chnn_stock_retail: 983,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L78XXX78',
      statusContent: 'dot',
      hash: 'SCZ3CX2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SCZ3CX2P'
    },
    {
      status: 210,
      sku: 'PM0084260',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378415',
      rtl_batch_array: '6378415',
      name_search: 'ACPDISSE',
      name: "Acer palmatum 'Dissectum'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8GJK3W6;VC8SK7EA;DZ5N8BJC;L9VW5KSW;9GSXXPPN;E75ZSTZ6;JL47PEV3;5VRT7PJ3;84KJZSWR;ZNPXR46S;75KDXGLE;4VBRB2KP;VB9JN1BB;6DL5CLA7;P23WX34K;TX5ESSVH;PDRG5XPR;LV4DH3HL;6ABDEZ2W;PC556NKY;XP6H1K3R;HXN2PCDR;N3SRWZ3V;G77KPB57;NJ9DX35L;899YE5W8',
      statusContent: 'dot',
      hash: 'XXAE8EHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXAE8EHJ'
    },
    {
      status: 210,
      sku: 'PM0084261',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378416',
      rtl_batch_array: '6378416',
      name_search: 'ACPODREA',
      name: "Acer palmatum 'Orange Dream'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YAB518CL',
      statusContent: 'dot',
      hash: 'XXRB6B1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XXRB6B1N'
    },
    {
      status: 210,
      sku: 'PM0084262',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378418',
      rtl_batch_array: '6378418',
      name_search: 'ACPSEIRY',
      name: "Acer palmatum 'Seiryu'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_prcp: 7.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9TSR6ST8;7AARZ95G;H19C6PVZ;GDBA1S1W',
      statusContent: 'dot',
      hash: 'GB8977H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB8977H7'
    },
    {
      status: 910,
      sku: 'PM0084263',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378419',
      rtl_batch_array: '6378419',
      name_search: 'ACSAUREU',
      name: "Acer shirasawanum 'Aureum'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 8.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T674X59V;YZYWEY44;458EWB6E;NYRBA675;Z23SB7RR;CPW35J2S;EHDC7N9E;ZL8XPW5T;BE87T1RV;GBWBW947;T79C2SD6;B9B3TYA7;A17BHVR6;44CVPRZ6;5RJLDWKZ;JBWGNRWG;GH76VT4H',
      statusContent: 'dot',
      hash: 'SYKZCTGN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYKZCTGN'
    },
    {
      status: 210,
      sku: 'PM0084264',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378420',
      rtl_batch_array: '6378420',
      name_search: 'BUAUNIQU',
      name: 'Buddleja alternifolia Unique',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2890,
      chnn_stock_retail: 2890,
      sppl_prcp: 4.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXWRJ9KT;AEREWWKG;57YYNLSG;SVAX9J4A;6G4VJZ8K;DK784BJ4;5KKSCBHR',
      statusContent: 'dot',
      hash: 'E2WJ6GR4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E2WJ6GR4'
    },
    {
      status: 210,
      sku: 'PM0084265',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378421',
      rtl_batch_array: '6378421',
      name_search: 'BUDAVIDI',
      name: 'Buddleja davidii',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 5737,
      chnn_stock_retail: 5737,
      sppl_prcp: 3.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E8V4KBER;493BC7CD;99DYK2WD;N91YL726;EYRCNPWZ;7LEVCVHJ;XV7AVD9Y;JSLCNSEZ;15PGD7J8;D3LCELAS;2P3LDREA',
      statusContent: 'dot',
      hash: 'DKZ5992Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKZ5992Z'
    },
    {
      status: 210,
      sku: 'PM0084266',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378422',
      rtl_batch_array: '6378422',
      name_search: 'BUDAVIDI',
      name: 'Buddleja davidii',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 19364,
      chnn_stock_retail: 19364,
      sppl_prcp: 2.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E8V4KBER;493BC7CD;99DYK2WD;N91YL726;EYRCNPWZ;7LEVCVHJ;XV7AVD9Y;JSLCNSEZ;15PGD7J8;D3LCELAS;2P3LDREA',
      statusContent: 'dot',
      hash: 'DVYE863Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DVYE863Z'
    },
    {
      status: 210,
      sku: 'PM0084267',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378423',
      rtl_batch_array: '6378423',
      name_search: 'BUDABLUE',
      name: 'Buddleja davidii Adonis Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 415,
      chnn_stock_retail: 415,
      sppl_prcp: 4.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HAX1VARG;XGZTHL9D;EENEX8BP',
      statusContent: 'dot',
      hash: 'EXYDZJ6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXYDZJ6W'
    },
    {
      status: 210,
      sku: 'PM0042061',
      core_name: 'Plant',
      sppl_ean: '8720664800632',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488241',
      rtl_batch_array: '5488241',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.495,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      imageBatch: 'CNS995PR',
      statusContent: 'dot',
      hash: 'YCYXA9TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCYXA9TY'
    },
    {
      status: 210,
      sku: 'PM0076809',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298669',
      rtl_batch_array: '6298669',
      name_search: 'LEACRED',
      name: "Leucothoe axillaris 'Curly Red'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 29474,
      chnn_stock_retail: 29474,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8H857G1;R8H1ZAJ1;586DR5XV;KV1DANDT;55LZW6WD;8EHDL7EE;ASEKT1V4;J49WYD7L;61KYLN78;CBNKX9YG;1ZS3D7GK;LD38B6JT',
      statusContent: 'dot',
      hash: 'BYSSA271',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYSSA271'
    },
    {
      status: 210,
      sku: 'PM0076811',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298671',
      rtl_batch_array: '6298671',
      name_search: 'LEKBLOVE',
      name: 'Leucothoe keiskei Burning Love',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 5870,
      chnn_stock_retail: 5870,
      sppl_prcp: 2.871,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VN99V1NP;59ZL4XTE;D6BPBVX3;GTVS8BRA;2PA6H948;N8S34GVV;65PZJBPK;7VPW67LX;D3XVR27D;6R7L31CZ',
      statusContent: 'dot',
      hash: 'S7C7SW88',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S7C7SW88'
    },
    {
      status: 210,
      sku: 'PM0076037',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350305',
      rtl_batch_array: '6350305, 6348334',
      name_search: 'HERTUESD',
      name: "Helenium 'Ruby Tuesday'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 496,
      chnn_stock_retail: 796,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4ZVXG78L;KZX92D8J;8SR738EX;P1H2JRAT;VE9ZTKV7;LBDVV1TC',
      statusContent: 'dot',
      hash: 'KTEED3TB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTEED3TB'
    },
    {
      status: 210,
      sku: 'PM0076038',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350309',
      rtl_batch_array: '6350309',
      name_search: 'HEWYNDLE',
      name: "Helenium 'Wyndley'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 323,
      chnn_stock_retail: 323,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PT4EDZC6;RVX2W5TH;H7YAK5D7;ENYJLABB;BNT7ERDE',
      statusContent: 'dot',
      hash: 'LYJCAB8P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYJCAB8P'
    },
    {
      status: 210,
      sku: 'PM0076040',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349870',
      rtl_batch_array: '6349870',
      name_search: 'HIMCJHEA',
      name: 'Hibiscus moscheutos Carousel Jolly Heart',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 272,
      chnn_stock_retail: 272,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8LCD526',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8LCD526'
    },
    {
      status: 210,
      sku: 'PM0076044',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350541',
      rtl_batch_array: '6350541',
      name_search: 'HULUPULU',
      name: 'Humulus lupulus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XSBNC5CW;V31YG91S',
      statusContent: 'dot',
      hash: 'WZX7XDWV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZX7XDWV'
    },
    {
      status: 210,
      sku: 'PM0076041',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349871',
      rtl_batch_array: '6349871',
      name_search: 'HIMCPPAS',
      name: 'Hibiscus moscheutos Carousel Pink Passion',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KP5V2D92;XLEX7A22',
      statusContent: 'dot',
      hash: 'VCN3D3WL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCN3D3WL'
    },
    {
      status: 810,
      sku: 'PM0083854',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356991',
      rtl_batch_array: '6356991',
      name_search: 'FUDSARAH',
      name: "Fuchsia 'Delta's Sarah'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 3.086,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T9KYHHZ3;X7G7V2D9;98S4AT78;9W9E68BV;54AHYLL6;2EPJ2LJ1;7BPBP1EN;1CDBR7CL;TAWZCB2E;J57GD4PE',
      statusContent: 'dot',
      hash: 'TKX6YDRV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TKX6YDRV'
    },
    {
      status: 210,
      sku: 'PM0076042',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349872',
      rtl_batch_array: '6349872',
      name_search: 'HIRSFANT',
      name: "Hibiscus rosa-sinensis 'Fantasia'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 173,
      chnn_stock_retail: 173,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GW2KN47V;DX8ZBGW4;CR6V17ZL;91XABAJ9;BX45HPYD;PGJG8H2B;79D6XYR5;4V1XLH4P;BL19RVWJ',
      statusContent: 'dot',
      hash: '2RR9CPKK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RR9CPKK'
    },
    {
      status: 810,
      sku: 'PM0076048',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350613',
      rtl_batch_array: '6350613, 6355370',
      name_search: 'KNROOPER',
      name: 'Kniphofia rooperi',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 60,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B6RTRG3P',
      statusContent: 'dot',
      hash: 'TS5DX59K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TS5DX59K'
    },
    {
      status: 210,
      sku: 'PM0076051',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6363187',
      rtl_batch_array: '6348365, 6363187',
      name_search: 'MACORDAT',
      name: 'Macleaya cordata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1020,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9ZJLR1A3;XGENK6R1',
      statusContent: 'dot',
      hash: '4SD55SJY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4SD55SJY'
    },
    {
      status: 210,
      sku: 'PM0076805',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298661',
      rtl_batch_array: '6298661',
      name_search: 'KEJPLENI',
      name: "Kerria japonica 'Pleniflora'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.625,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y49EYZE8;945VKGPK;J19DN9A8;9Z6LSTEH;8N4SZZ37;27N1C4K3;YC9D7VAX;WHNEBXT4;V7NR7ETW;GZ9679V3;6T1ZH9LV;5EN8PSJ2;9881V54B;5TVCNZ8L',
      statusContent: 'dot',
      hash: '4DA9RJG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DA9RJG4'
    },
    {
      status: 210,
      sku: 'PM0076035',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350216',
      rtl_batch_array: '6350216',
      name_search: 'GEMSSMIL',
      name: "Geranium macr. 'Sandy's Smile'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2LG6CPRP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2LG6CPRP'
    },
    {
      status: 210,
      sku: 'PM0076036',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350283',
      rtl_batch_array: '6350283',
      name_search: 'HEDTROUB',
      name: "Helenium 'Double Trouble'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 409,
      chnn_stock_retail: 409,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8132Z5RZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8132Z5RZ'
    },
    {
      status: 210,
      sku: 'PM0083855',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356992',
      rtl_batch_array: '6356992',
      name_search: 'LYPHHERO',
      name: "Lysimachia punctata 'Hometown Hero'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1DLKNB85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DLKNB85'
    },
    {
      status: 210,
      sku: 'PM0083856',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356993',
      rtl_batch_array: '6356993',
      name_search: 'PEPOLYMO',
      name: 'Persicaria polymorpha',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 2.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7ZG8EGRZ;NY1R58Z3;G64A2E7G;G4L67W6Z;VAY1Z2CY',
      statusContent: 'dot',
      hash: 'E7D9EE6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E7D9EE6N'
    },
    {
      status: 210,
      sku: 'PM0076045',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336446',
      rtl_batch_array: '6350542, 6336446',
      name_search: 'HYIRHEIN',
      name: "Hypericum inodorum 'Rheingold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2967,
      chnn_stock_retail: 3207,
      sppl_order_minimum: 3,
      sppl_prcp: 0.556,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y2P47184',
      statusContent: 'dot',
      hash: '42ZPE4RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '42ZPE4RL'
    },
    {
      status: 210,
      sku: 'PM0076046',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350543',
      rtl_batch_array: '6350543',
      name_search: 'HYMTRICO',
      name: "Hydrangea macrophylla 'Tricolor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 552,
      chnn_stock_retail: 552,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1HVZWRD6',
      statusContent: 'dot',
      hash: '19ZYP4CL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19ZYP4CL'
    },
    {
      status: 210,
      sku: 'PM0083857',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356994',
      rtl_batch_array: '6356994',
      name_search: 'PHBEYES',
      name: "Phlox (P) 'Bright Eyes'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8VVS9HNH;6SCP614V;TZBJD7R3;KWXNT1DW;LDX4KEBV;G6D6AV6R;B8C5KYBG;ARW4WT54;L8JC3TTB;WDE57CL1;D2L7J888',
      statusContent: 'dot',
      hash: 'RWTYNLLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWTYNLLA'
    },
    {
      status: 210,
      sku: 'PM0076049',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350644',
      rtl_batch_array: '6350644',
      name_search: 'LESNOWCA',
      name: "Leucanthemum (S) 'Snowcap'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C96TJWKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C96TJWKB'
    },
    {
      status: 210,
      sku: 'PM0083858',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356995',
      rtl_batch_array: '6356995',
      name_search: 'PHDAVID',
      name: "Phlox (P) 'David'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2DKVDL4Z;WLCDZC3X;N856B7LN;JKHYT3H6;C5L9Z5PJ;HXRLYLJ7',
      statusContent: 'dot',
      hash: 'LNJLS319',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LNJLS319'
    },
    {
      status: 210,
      sku: 'PM0042009',
      core_name: 'Plant',
      sppl_ean: '8720664691476',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '5420631',
      rtl_batch_array: '6346653, 5420631',
      name_search: 'HEAGMARE',
      name: "Hedera algeriensis 'Gloire de Marengo'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 322,
      chnn_stock_retail: 812,
      sppl_order_minimum: 3,
      sppl_prcp: 2.954,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'REA555VX;AWYR38ZD;2S2AX7BR;R5VCW52D;PGPE1Z29;WYCBSHR9',
      statusContent: 'dot',
      hash: '22NHSD9N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22NHSD9N'
    },
    {
      status: 910,
      sku: 'PM0042067',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPUNIQU',
      name: "Hydrangea paniculata 'Unique'",
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      imageCore:
        '8DD9LJPH;KB36AY45;J7Z7T8C5;1KGH13HA;YNA2SKY1;V2X3SEVT;EEC6931R;XX2XP852;WPKTKD2R',
      statusContent: 'dot',
      hash: '764P9RKW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '764P9RKW'
    },
    {
      status: 210,
      sku: 'PM0042004',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244790',
      rtl_batch_array: '6244790',
      name_search: 'ACAGENEV',
      name: "Actinidia arguta 'Geneva'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.345,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ED8VJDK1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED8VJDK1'
    },
    {
      status: 210,
      sku: 'PM0042002',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244791',
      rtl_batch_array: '6244791',
      name_search: 'ACAKRED',
      name: "Actinidia arguta 'Ken's Red'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 3.345,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PX4DB7EV;GKZBV367;W7JTDVL3',
      statusContent: 'dot',
      hash: 'HVSCVH1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HVSCVH1X'
    },
    {
      status: 210,
      sku: 'PM0083859',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356996',
      rtl_batch_array: '6356996',
      name_search: 'PHLAURA',
      name: "Phlox (P) 'Laura'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZCZ13N1T;HCVHRP3N;YEBJ6BGN;D9PXLDV7;A7DW98VY;11A9TSLZ;J56HVS97;N51Y6JJN;N95RDV82;77RHP285;BTCR5DB9;V14THECA',
      statusContent: 'dot',
      hash: '87G6YDZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87G6YDZL'
    },
    {
      status: 210,
      sku: 'PM0084268',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378428',
      rtl_batch_array: '6378428',
      name_search: 'BUDBCLSW',
      name: 'Buddleja d. B. Candy Lila Sweeth.',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 362,
      chnn_stock_retail: 362,
      sppl_prcp: 4.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P2EZTH39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2EZTH39'
    },
    {
      status: 210,
      sku: 'PM0076039',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6350394',
      rtl_batch_array: '6350394, 6295665, 6348952',
      name_search: 'HEDSECRE',
      name: "Heuchera 'Dark Secret'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 741,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S6BT9H1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S6BT9H1N'
    },
    {
      status: 210,
      sku: 'PM0041997',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242785',
      rtl_batch_array: '6242785',
      name_search: 'PATVEITC',
      name: "Parthenocissus tricuspidata 'Veitchii'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1372,
      chnn_stock_retail: 1372,
      sppl_order_minimum: 3,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADVLLKVT;7T92AN9S;2WHBAWVJ;627NTEYN;RT79H2PR;BBWSRAB9;7TN6DYEP;YPZZSYGT;4SZXB4WH;AYXN8N69;9137A2EE;61LZ4Y52;ATWKKWA3;AVKNG3DR;DB3YG7H9',
      statusContent: 'dot',
      hash: 'KPTDDT2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPTDDT2B'
    },
    {
      status: 210,
      sku: 'PM0076043',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350499',
      rtl_batch_array: '6350499',
      name_search: 'HOGEMPRE',
      name: "Hosta 'Golden Empress'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1052,
      chnn_stock_retail: 1052,
      sppl_order_minimum: 3,
      sppl_prcp: 1.896,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '67827Y3V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67827Y3V'
    },
    {
      status: 210,
      sku: 'PM0076050',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350651',
      rtl_batch_array: '6350651',
      name_search: 'LIDENTAT',
      name: 'Ligularia dentata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 651,
      chnn_stock_retail: 651,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6YBR7D4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6YBR7D4B'
    },
    {
      status: 210,
      sku: 'PM0076390',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353282',
      rtl_batch_array: '6353282',
      name_search: 'SCNODOSA',
      name: 'Scrophularia nodosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 291,
      chnn_stock_retail: 291,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6A1XP9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6A1XP9L'
    },
    {
      status: 210,
      sku: 'PM0042025',
      core_name: 'Plant',
      sppl_ean: '8720353022765',
      btch_manufacturer: 73,
      rtl_batch_array_total: 2,
      btch_active_retail: '5420638',
      rtl_batch_array: '5420638, 6346663',
      name_search: 'HEHWOERN',
      name: "Hedera helix 'Woerner'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 2915,
      chnn_stock_retail: 3871,
      sppl_order_minimum: 3,
      sppl_prcp: 2.261,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6X279SEH;RGYYYN9X;JG872GJN;8T9TWZ5J',
      statusContent: 'dot',
      hash: 'XTVR7K3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XTVR7K3X'
    },
    {
      status: 810,
      sku: 'PM0084269',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378429',
      rtl_batch_array: '6378429',
      name_search: 'BUDBCLPI',
      name: 'Buddleja d. B. Candy Little Pink',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 4.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RL9V1NZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RL9V1NZT'
    },
    {
      status: 210,
      sku: 'PM0084270',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378430',
      rtl_batch_array: '6378430',
      name_search: 'BUDBCLWH',
      name: 'Buddleja d. B. Candy Little White',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 384,
      chnn_stock_retail: 384,
      sppl_prcp: 4.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YGDRL739',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YGDRL739'
    },
    {
      status: 210,
      sku: 'PM0084271',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378431',
      rtl_batch_array: '6378431',
      name_search: 'BUBUZZ',
      name: 'Buddleia davidii Buzz',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9806,
      chnn_stock_retail: 9806,
      sppl_prcp: 4.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2KWBLEH3;86NA2H7G;7YG7BGEA;119S7AXZ',
      statusContent: 'dot',
      hash: 'KTH78Z1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTH78Z1X'
    },
    {
      status: 210,
      sku: 'PM0084272',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378432',
      rtl_batch_array: '6378432',
      name_search: 'BUDBIVOR',
      name: 'Buddleja davidii Buzz Ivory',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1805,
      chnn_stock_retail: 1805,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '69SP66DB',
      statusContent: 'dot',
      hash: 'KDE1EZNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDE1EZNE'
    },
    {
      status: 210,
      sku: 'PM0084273',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378433',
      rtl_batch_array: '6378433',
      name_search: 'BUDBPPUR',
      name: 'Buddleja davidii Buzz Pink Purple',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1825,
      chnn_stock_retail: 1825,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6DECAZGS;9KRWKPK1;E3YL841E;NL7YJCXB;BDHHTLHN;A4CPC9NK;9HPG7ZLH;PBKB5H1X;1X3VERL6;ACHEBL3N;RSXC3Y9H',
      statusContent: 'dot',
      hash: 'THLG2T4C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THLG2T4C'
    },
    {
      status: 210,
      sku: 'PM0084274',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378434',
      rtl_batch_array: '6378434',
      name_search: 'BUDBSBLU',
      name: 'Buddleja davidii Buzz Sky Blue',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 6277,
      chnn_stock_retail: 6277,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4LKSV8VT;K5ZRJPA8;V8WWSLPP;E5GAY9WB;8PSHC37S;VJZG3BPJ',
      statusContent: 'dot',
      hash: '62ZGK3S9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62ZGK3S9'
    },
    {
      status: 210,
      sku: 'PM0076793',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298649',
      rtl_batch_array: '6298649',
      name_search: 'KALCAROU',
      name: "Kalmia latifolia 'Carousel'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 589,
      chnn_stock_retail: 589,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RJ6R6Y3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RJ6R6Y3S'
    },
    {
      status: 210,
      sku: 'PM0076794',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298650',
      rtl_batch_array: '6298650',
      name_search: 'KALELF',
      name: "Kalmia latifolia 'Elf'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2274,
      chnn_stock_retail: 2274,
      sppl_prcp: 7.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H28LN6VE',
      statusContent: 'dot',
      hash: 'K9DV241X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9DV241X'
    },
    {
      status: 210,
      sku: 'PM0076795',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298651',
      rtl_batch_array: '6298651',
      name_search: 'KALLLIND',
      name: "Kalmia latifolia 'Little Linda'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2200,
      chnn_stock_retail: 2200,
      sppl_prcp: 7.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A4XXD9EK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4XXD9EK'
    },
    {
      status: 210,
      sku: 'PM0076796',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298652',
      rtl_batch_array: '6298652',
      name_search: 'KALMINUE',
      name: "Kalmia latifolia 'Minuet'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1890,
      chnn_stock_retail: 1890,
      sppl_prcp: 7.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VGNLX7Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGNLX7Z6'
    },
    {
      status: 210,
      sku: 'PM0076797',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298653',
      rtl_batch_array: '6298653',
      name_search: 'KALNANI',
      name: "Kalmia latifolia 'Nani'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9L1HZ63X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9L1HZ63X'
    },
    {
      status: 210,
      sku: 'PM0076798',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298654',
      rtl_batch_array: '6298654',
      name_search: 'KALOFIRE',
      name: "Kalmia latifolia 'Olympic Fire'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 1657,
      chnn_stock_retail: 1657,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXZK6CZ6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXZK6CZ6'
    },
    {
      status: 210,
      sku: 'PM0076799',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298655',
      rtl_batch_array: '6298655',
      name_search: 'KALORED',
      name: "Kalmia latifolia 'Ostbo Red'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 2044,
      chnn_stock_retail: 2044,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B3K8NEVS',
      statusContent: 'dot',
      hash: 'SACRH3E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SACRH3E7'
    },
    {
      status: 210,
      sku: 'PM0076800',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298656',
      rtl_batch_array: '6298656',
      name_search: 'KALPEPPE',
      name: "Kalmia latifolia 'Peppermint'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 707,
      chnn_stock_retail: 707,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NLH385ZH',
      statusContent: 'dot',
      hash: '7LPSDHG4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LPSDHG4'
    },
    {
      status: 210,
      sku: 'PM0076801',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298657',
      rtl_batch_array: '6298657',
      name_search: 'KALPINWH',
      name: "Kalmia latifolia 'Pinwheel'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 938,
      chnn_stock_retail: 938,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HX732TX1',
      statusContent: 'dot',
      hash: 'H7PGDS2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7PGDS2E'
    },
    {
      status: 210,
      sku: 'PM0076802',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298658',
      rtl_batch_array: '6298658',
      name_search: 'KALSCHOK',
      name: "Kalmia latifolia 'Schokra'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 530,
      chnn_stock_retail: 530,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YTDBYK75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YTDBYK75'
    },
    {
      status: 210,
      sku: 'PM0076803',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298659',
      rtl_batch_array: '6298659',
      name_search: 'KALTIDDL',
      name: "Kalmia latifolia 'Tiddlywinks'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1913,
      chnn_stock_retail: 1913,
      sppl_prcp: 7.733,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JXZYB19E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JXZYB19E'
    },
    {
      status: 210,
      sku: 'PM0076804',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298660',
      rtl_batch_array: '6298660',
      name_search: 'KALZEBUL',
      name: "Kalmia latifolia 'Zebulon'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 7.74,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G772TKD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G772TKD2'
    },
    {
      status: 210,
      sku: 'PM0076808',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298667',
      rtl_batch_array: '6298667',
      name_search: 'LANOBILI',
      name: 'Laurus nobilis',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 6.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GES4PHGP;5ZCAB8S8;WS9S85LT;9327BLVE;VHC5LHCA;A1KPEEYB;KTLAYJGS;KEPZZW3R;RPBX8XBG;SLAVE9S5;LVZNXNCV;CD6WGCBK;HYGEN5LL;S9X884YW;A4H4768X;1K9XJL8E;83HPSB3V;N46BSWW7;H44D431G;6GB5JE6K;157JYYJL;EWEY1W1B;X4HBWDP3;RSPSN5K9;JSCDVZB4;GBA115LW;Z5LD1E5B;DXTD65T3;9ZAYTALP;EZ4B31NB;CSYXLZJH',
      statusContent: 'dot',
      hash: 'LE16YWEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LE16YWEH'
    },
    {
      status: 210,
      sku: 'PM0084275',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378435',
      rtl_batch_array: '6378435',
      name_search: 'BUDBVIOL',
      name: "Buddleja davidii Buzz 'Violet'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 4592,
      chnn_stock_retail: 4592,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H7N7WWTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7N7WWTC'
    },
    {
      status: 210,
      sku: 'PM0084276',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378436',
      rtl_batch_array: '6378436',
      name_search: 'BUDDEBLU',
      name: "Buddleja davidii 'Ellen's Blue'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 3459,
      chnn_stock_retail: 3459,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2VRL8EL;J7JEEJHL;Z1TRKRG6;K7HBP9SP;8LCXK7SD;2S4TZBPR;72C4BRDK;4V67RTTH',
      statusContent: 'dot',
      hash: 'AWHNGXZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWHNGXZ9'
    },
    {
      status: 210,
      sku: 'PM0077132',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300263',
      rtl_batch_array: '6300263',
      name_search: 'VILANTAN',
      name: 'Viburnum lantana',
      sppl_size_code: '030050C2',
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      sppl_stock_available: 2560,
      chnn_stock_retail: 2560,
      sppl_prcp: 1.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S8RJDYE1;E5TGX8J3;EEWC33GD;99BYSAN4;KP98VB62;SKRTREDV;YX7YB1LL;H199BA28;6ZPK789N;XECZ6GZ3;L1LCTX4J;9EZ4NXZ2;W5GJZ4DE;EA5A97P3;DNHA5G5K;7J524CB3',
      statusContent: 'dot',
      hash: 'X92267Y5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X92267Y5'
    },
    {
      status: 210,
      sku: 'PM0077133',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300264',
      rtl_batch_array: '6300264',
      name_search: 'VIOROSEU',
      name: "Viburnum opulus 'Roseum'",
      sppl_size_code: '040060C24',
      rng_range_identifier: 'H040060C2.5',
      rng_range_description: 'H40 cm 60 cm C2.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '26JEBKV8;S3EV61NT;7EDGE388;GVXY86H6;2ALAAY8V;1153X83C;5C1EGYTB;2X9AK71Y;KY51E261;BZESS1P3;44VAC8G7;5X4NRVTY;EBP2VBBY;L9N9EJ6E;AX9N73B4;PNNLH6EK;HX1P1A9T;LE7XBC8L;K7TA3868',
      statusContent: 'dot',
      hash: '759H9J1H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '759H9J1H'
    },
    {
      status: 210,
      sku: 'PM0077134',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300266',
      rtl_batch_array: '6300266',
      name_search: 'VIBURHYT',
      name: 'Viburnum rhytidophyllum',
      sppl_size_code: '030050C24',
      rng_range_identifier: 'H030050C2.5',
      rng_range_description: 'H30 cm 50 cm C2.5',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.123,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '48SGK5WV;L8E27T5T',
      statusContent: 'dot',
      hash: '9WX1GBZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WX1GBZY'
    },
    {
      status: 210,
      sku: 'PM0077135',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300269',
      rtl_batch_array: '6300269',
      name_search: 'WERPRINC',
      name: "Weigela 'Red Prince'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2450,
      chnn_stock_retail: 2450,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2KT8W4YD;L15G3PXZ;PA55DZ34;EC6XDVS3;129WSAJK;8815TG66;HWE3D6Y6',
      statusContent: 'dot',
      hash: 'JP379XB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JP379XB6'
    },
    {
      status: 210,
      sku: 'PM0077136',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300270',
      rtl_batch_array: '6300270',
      name_search: 'WERUMBA',
      name: "Weigela 'Rumba'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2650,
      chnn_stock_retail: 2650,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T76B3G2N;T3BGHLYX;J2Y6LGK4',
      statusContent: 'dot',
      hash: 'TL6CHZZK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TL6CHZZK'
    },
    {
      status: 210,
      sku: 'PM0077137',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300271',
      rtl_batch_array: '6300271',
      name_search: 'WEFNPURP',
      name: "Weigela florida 'Nana Purpurea'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2830,
      chnn_stock_retail: 2830,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8E4X3P5K;W26V4REV',
      statusContent: 'dot',
      hash: 'HZ2TS4TJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZ2TS4TJ'
    },
    {
      status: 210,
      sku: 'PM0077138',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300272',
      rtl_batch_array: '6300272',
      name_search: 'WENVARIE',
      name: "Weigela 'Nana Variegata'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 2350,
      chnn_stock_retail: 2350,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZRRBKA7A;YDZ6GXR6;4PCDV1V8;LT15R4AK',
      statusContent: 'dot',
      hash: 'X5AZWY94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X5AZWY94'
    },
    {
      status: 210,
      sku: 'PM0077139',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300273',
      rtl_batch_array: '6300273',
      name_search: 'WEFTANGO',
      name: "Weigela florida 'Tango'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3080,
      chnn_stock_retail: 3080,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E21XJLE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E21XJLE4'
    },
    {
      status: 210,
      sku: 'PM0077140',
      core_name: 'Plant',
      btch_manufacturer: 685,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300274',
      rtl_batch_array: '6300274',
      name_search: 'WEFVICTO',
      name: "Weigela florida 'Victoria'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 3050,
      chnn_stock_retail: 3050,
      sppl_prcp: 1.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '77L8S29W;VD34HGKR',
      statusContent: 'dot',
      hash: '885ERVLE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '885ERVLE'
    },
    {
      status: 210,
      sku: 'PM0084277',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378437',
      rtl_batch_array: '6378437',
      name_search: 'BUDDEBLU',
      name: "Buddleja davidii 'Ellen's Blue'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 9850,
      chnn_stock_retail: 9850,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z2VRL8EL;J7JEEJHL;Z1TRKRG6;K7HBP9SP;8LCXK7SD;2S4TZBPR;72C4BRDK;4V67RTTH',
      statusContent: 'dot',
      hash: 'XZ1HJ1NT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZ1HJ1NT'
    },
    {
      status: 210,
      sku: 'PM0084278',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378438',
      rtl_batch_array: '6378438',
      name_search: 'BUDEBLUE',
      name: "Buddleja davidii 'Empire Blue'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1768,
      chnn_stock_retail: 1768,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HZJS51J6;Z8YG7DKV;66GJBJEV;TSCPXDWR;CJ3WRNE3;CTR98GTG;X4YT4STD;ADBDLR73;W3K4ZNSZ',
      statusContent: 'dot',
      hash: 'SJB8HCT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SJB8HCT5'
    },
    {
      status: 210,
      sku: 'PM0084279',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378440',
      rtl_batch_array: '6378440',
      name_search: 'BUDFPETI',
      name: "Buddleja davidii 'Free Petite\u00ae Tutti Fruitti'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 9409,
      chnn_stock_retail: 9409,
      sppl_prcp: 4.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J66LKBJ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J66LKBJ5'
    },
    {
      status: 210,
      sku: 'PM0084280',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378442',
      rtl_batch_array: '6378442',
      name_search: 'BUFPBHEA',
      name: 'Buddleja Free Petite Blue Heaven',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 313,
      chnn_stock_retail: 313,
      sppl_prcp: 6.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DRGYB1XG;6W23LEGC;AJ55KEY1;CLPSGHX5',
      statusContent: 'dot',
      hash: 'H5YKSTLS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H5YKSTLS'
    },
    {
      status: 210,
      sku: 'PM0084281',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378443',
      rtl_batch_array: '6378443',
      name_search: 'BUFPDPIN',
      name: 'Buddleja Flutterby Petite Dark Pink',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1966,
      chnn_stock_retail: 1966,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NRG419H1',
      statusContent: 'dot',
      hash: 'XLC49WJH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XLC49WJH'
    },
    {
      status: 910,
      sku: 'PM0084282',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDFPLFL',
      name: "Buddleja davidii 'Free Petite\u00ae Lavender Flow'",
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: '29KVTCWB;CDBY8LEZ',
      statusContent: 'dot',
      hash: 'BJ1VZPB9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BJ1VZPB9'
    },
    {
      status: 910,
      sku: 'PM0084283',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDFPLFL',
      name: "Buddleja davidii 'Free Petite\u00ae Lavender Flow'",
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      imageCore: '29KVTCWB;CDBY8LEZ',
      statusContent: 'dot',
      hash: 'E2CJKE1E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E2CJKE1E'
    },
    {
      status: 210,
      sku: 'PM0084284',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378444',
      rtl_batch_array: '6378444',
      name_search: 'BUFPSWHI',
      name: 'Buddleja Flutterby Petite Snow White',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1917,
      chnn_stock_retail: 1917,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ETAE8Y8',
      statusContent: 'dot',
      hash: 'XX7YK6YR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX7YK6YR'
    },
    {
      status: 810,
      sku: 'PM0084285',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378445',
      rtl_batch_array: '6378445',
      name_search: 'BUFPSWHI',
      name: 'Buddleja Flutterby Petite Snow White',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 6.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ETAE8Y8',
      statusContent: 'dot',
      hash: '74GSGT63',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74GSGT63'
    },
    {
      status: 210,
      sku: 'PM0084286',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378447',
      rtl_batch_array: '6378447',
      name_search: 'BUDIFRAN',
      name: "Buddleja davidii 'Ile de France'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 166,
      chnn_stock_retail: 166,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC7N6WC3;SJ3P9VN8',
      statusContent: 'dot',
      hash: 'E92JZAPX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E92JZAPX'
    },
    {
      status: 210,
      sku: 'PM0084287',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378450',
      rtl_batch_array: '6378450',
      name_search: 'BUDNBLUE',
      name: "Buddleja davidii 'Nanho Blue'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 1969,
      chnn_stock_retail: 1969,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACTLE15;LDATYYR8;ED5NTL2H;252R6S6W;8WB5JX9B',
      statusContent: 'dot',
      hash: 'C2BS7V64',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C2BS7V64'
    },
    {
      status: 910,
      sku: 'PM0084288',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDNPURP',
      name: "Buddleja davidii 'Nanho Purple'",
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      imageCore: 'CD4P7WCA;PKRSAV45;4B7BDLXN;BA4RC7ZV;63E2S74R;GCA348VB',
      statusContent: 'dot',
      hash: 'N1P9WJV9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N1P9WJV9'
    },
    {
      status: 210,
      sku: 'PM0076392',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301912',
      rtl_batch_array: '6301912, 6353497',
      name_search: 'VISFRECK',
      name: "Viola sororia 'Freckles'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 471,
      chnn_stock_retail: 1872,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '964GBHXJ;SPDL27D9;ZR6PWHJT;T3RKW43X;5VVB49S5;HJD6RW9S;PPPRTJPW;G9C2YZZP;7L9K9449',
      statusContent: 'dot',
      hash: 'NG61BV8D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NG61BV8D'
    },
    {
      status: 210,
      sku: 'PM0076052',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349877',
      rtl_batch_array: '6349877',
      name_search: 'MIGIGANT',
      name: 'Miscanthus giganteus',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XX3H731W;WDGZWWW7;T9CGN2Z4;X7N65LYX;S9DNA3Y7;PXRA3199;GWVVS1KD;77KRS8YS',
      statusContent: 'dot',
      hash: 'NVXVXNC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVXVXNC1'
    },
    {
      status: 210,
      sku: 'PM0076053',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349879',
      rtl_batch_array: '6349879',
      name_search: 'MISCABAR',
      name: "Miscanthus sinensis 'Cabaret'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 3,
      sppl_prcp: 2.169,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98LY78DP;K4Z7KPXR;92HKLZ5Y;69SX98YH;N8994JW2;DXN24ZXS;XD813E47;ZV61SVRW;N9V5VZ76',
      statusContent: 'dot',
      hash: 'V8ACGG86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8ACGG86'
    },
    {
      status: 210,
      sku: 'PM0076054',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349883',
      rtl_batch_array: '6349883',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1296,
      chnn_stock_retail: 1296,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: '5HXGVYLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HXGVYLL'
    },
    {
      status: 210,
      sku: 'PM0076055',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284399',
      rtl_batch_array: '6284399',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8APDVLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8APDVLV'
    },
    {
      status: 210,
      sku: 'PM0076056',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 5,
      btch_active_retail: '6382288',
      rtl_batch_array: '6315056, 6350681, 6382288, 6385664, 6333279',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14240,
      chnn_stock_retail: 22336,
      sppl_order_minimum: 3,
      sppl_prcp: 0.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: '23P6XLCX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23P6XLCX'
    },
    {
      status: 210,
      sku: 'PM0076057',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284401',
      rtl_batch_array: '6284401',
      name_search: 'MISIBIZA',
      name: "Miscanthus sinensis 'Ibiza'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B5SGG19K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5SGG19K'
    },
    {
      status: 210,
      sku: 'PM0076058',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284402',
      rtl_batch_array: '6284402',
      name_search: 'MISKFONT',
      name: "Miscanthus sinensis 'Kleine Font\u00e4ne'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1300,
      chnn_stock_retail: 1300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YPX27258;HHV2A21K',
      statusContent: 'dot',
      hash: '8LZWH45L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8LZWH45L'
    },
    {
      status: 910,
      sku: 'PM0076393',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TRPERINN',
      name: "Tradescantia (A) 'Perinne'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5TYAGKWJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5TYAGKWJ'
    },
    {
      status: 210,
      sku: 'PM0076394',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353512',
      rtl_batch_array: '6353512',
      name_search: 'TRBBRIDE',
      name: "Tradescantia (A) 'Blushing Bride'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 153,
      chnn_stock_retail: 153,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7AZV844',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7AZV844'
    },
    {
      status: 210,
      sku: 'PM0076395',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353538',
      rtl_batch_array: '6353538',
      name_search: 'VEJEYES',
      name: "Verbascum 'Jolly Eyes'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D6PCVA58',
      statusContent: 'dot',
      hash: '4DWWGVLB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DWWGVLB'
    },
    {
      status: 210,
      sku: 'PM0014467',
      core_name: 'Plant',
      sppl_ean: '8720349406876',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3988824',
      rtl_batch_array: '3988824',
      name_search: 'SECAWING',
      name: 'Senecio candicans Angel Wings',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 469,
      chnn_stock_retail: 469,
      sppl_prcp: 2.593,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LAH254P9;WYJ4J6D5;PD9NJNT2;DL27C71T;KTSSA1KH;7S3SH1WP;RNR7HZYX;93HAJY1B;R5C4CSAJ;8CNSHZT9;67EX8JKD;E7N8LT6T;VHYP4H8N',
      imageBatch: 'KKR39W2K',
      statusContent: 'dot',
      hash: '9ZGK86ZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9ZGK86ZD'
    },
    {
      status: 810,
      sku: 'PM0076604',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295571',
      rtl_batch_array: '6295571',
      name_search: 'FEWALBER',
      name: "Festuca 'Walberla'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G7VA5BSC;KYH4BR9G;S1W12CJ5;TSKVJPT1;ESX16AB7;LWG4PC4H;7RLH6DN9;TAWTHW9A;6E123KP6;G2N1XV1L;75L4G9S6',
      statusContent: 'dot',
      hash: 'H2DEGWTX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2DEGWTX'
    },
    {
      status: 210,
      sku: 'PM0076815',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298678',
      rtl_batch_array: '6298678',
      name_search: 'LONRTIPS',
      name: "Lonicera nitida 'Red Tips'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 3,
      sppl_prcp: 2.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GHYT48JB',
      statusContent: 'dot',
      hash: 'T8YHH6G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8YHH6G9'
    },
    {
      status: 210,
      sku: 'PM0083860',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356997',
      rtl_batch_array: '6356997',
      name_search: 'PHSTARFI',
      name: "Phlox (P) 'Starfire'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VEKW2ST5',
      statusContent: 'dot',
      hash: 'THYRC3A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THYRC3A5'
    },
    {
      status: 910,
      sku: 'PM0076817',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298680',
      rtl_batch_array: '6298680',
      name_search: 'LORBPEAR',
      name: "Lophomyrthus ralphii 'Black Pearl'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 17,
      chnn_stock_retail: 17,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5AV6CN5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AV6CN5P'
    },
    {
      status: 210,
      sku: 'PM0076819',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298682',
      rtl_batch_array: '6298682',
      name_search: 'MAMAMLLA',
      name: 'Mangave Mad About Mangave Lavender Lady',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VRSEGH3D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VRSEGH3D'
    },
    {
      status: 210,
      sku: 'PM0076820',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298683',
      rtl_batch_array: '6298683',
      name_search: 'MAMAMPEX',
      name: 'Mangave Mad About Mangave Pineapple Express',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 228,
      chnn_stock_retail: 228,
      sppl_prcp: 10.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A39X7J6S;BR5129ND;R3A84W2G;JY5JXRD1',
      statusContent: 'dot',
      hash: 'CH2S3SNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CH2S3SNG'
    },
    {
      status: 810,
      sku: 'PM0076821',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298684',
      rtl_batch_array: '6298684',
      name_search: 'MARSENTI',
      name: "Malus 'Red Sentinel'",
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 31.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '55A51BTG;BS2KS2YA;V183TB8K;ND8K4VKY;2DZ8DLCB;XHG1KK2K;84WE1ZGK;DX2VDCW5',
      statusContent: 'dot',
      hash: '2E5A1RZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2E5A1RZD'
    },
    {
      status: 810,
      sku: 'PM0076822',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298685',
      rtl_batch_array: '6298685',
      name_search: 'MARBEAUT',
      name: "Malus 'Royal Beauty'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 43.167,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2PT56S8G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2PT56S8G'
    },
    {
      status: 910,
      sku: 'PM0076823',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298686',
      rtl_batch_array: '6298686',
      name_search: 'MATTINA',
      name: "Malus toringo 'Tina'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 45.667,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SNYX9B7N',
      statusContent: 'dot',
      hash: '6Z62REHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z62REHC'
    },
    {
      status: 210,
      sku: 'PM0076824',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298687',
      rtl_batch_array: '6298687',
      name_search: 'MAVSCARL',
      name: "Malus 'Veitch's Scarlet'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 16.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T9EP5VYP',
      statusContent: 'dot',
      hash: 'E56D4B7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E56D4B7H'
    },
    {
      status: 210,
      sku: 'PM0076825',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298688',
      rtl_batch_array: '6298688',
      name_search: 'MISFOSTE',
      name: "Miscanthus sinensis 'Ferner Osten'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6Y751HKK;6VNXGPV8;8CTZJV5V;E5KCPHL9;XH43SJGP;KK7JX35G;5ARH2R2B;CP3PB9ZL;DX8RAWC5;17HVG54V;18L5WPSC;PGC6VJ9Y;TB7Y45T5;5WP7APKD;H1J7R2J5;VL4T938S',
      statusContent: 'dot',
      hash: 'BDSZKPHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDSZKPHW'
    },
    {
      status: 210,
      sku: 'PM0076826',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298689',
      rtl_batch_array: '6298689',
      name_search: 'MISGRACI',
      name: "Miscanthus sinensis 'Gracillimus'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E4TCEKHP;HN9SAYEW;B9XKRWZN;LX3ZTW2V;JN5JJ3SX;C5NPK6EJ;96Z69TGR;YLAS87K3;CEB5PBSN;S9YJ72RY;WA783NTC;CCNTW7AX;YYH3YGLY',
      statusContent: 'dot',
      hash: 'RBD43RNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBD43RNG'
    },
    {
      status: 210,
      sku: 'PM0076827',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298691',
      rtl_batch_array: '6298691',
      name_search: 'MISKSILB',
      name: "Miscanthus sinensis 'Kleine Silberspinne'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 4.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CG4J9XK3;Z937LE67;JBNL8XDZ;2THK4BSG;Y3L3VW8R;AA6C368H;9NGW91TY;X2CT2K8T;KXSCEZE8;GWH9C4HH;BZZK4V1S;TXLTX5L1;537BRCG3;5TCSG54V;B8A16YKV;WRZKXCTC',
      statusContent: 'dot',
      hash: 'J3LBGTNN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3LBGTNN'
    },
    {
      status: 210,
      sku: 'PM0076828',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298692',
      rtl_batch_array: '6298692',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      statusContent: 'dot',
      hash: 'BSXBYBED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSXBYBED'
    },
    {
      status: 210,
      sku: 'PM0076829',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298693',
      rtl_batch_array: '6298693',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      statusContent: 'dot',
      hash: 'JL5ZRS82',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL5ZRS82'
    },
    {
      status: 210,
      sku: 'PM0076830',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298694',
      rtl_batch_array: '6298694',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: '7BDSS2RN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BDSS2RN'
    },
    {
      status: 210,
      sku: 'PM0076831',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298695',
      rtl_batch_array: '6298695',
      name_search: 'MISMLIGH',
      name: "Miscanthus sinensis 'Morning Light'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6BNZJ37X;ZR6NEEJE;LG94GBKE;D36BXTX3;SGT6EB5L;DDEZBZZ9;VC4DPKLE;8WSYK6VN;5RGRXNGB;RDBJXPR3;C5K8K2B1;WA6KHZ1J;T9WC3TTK;SVPN2LJK;77JNBY2X;DL3Y6SP8;WJABYVXB;25BGLWDT;4XEW41BJ;76D7KN7P;CECVR6SZ;RXDXJVJE',
      statusContent: 'dot',
      hash: '9XJJDE56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XJJDE56'
    },
    {
      status: 210,
      sku: 'PM0076832',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298696',
      rtl_batch_array: '6298696',
      name_search: 'MISRCHIE',
      name: "Miscanthus sinensis 'Red Chief'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YAX7PCWW;8E3TKVSV;45CETXTN;NSG11943;J394NPSY;1EAJSGD1;HKBWSZVK;G1J3KNAJ;N2AA8ZK3;HS5NE6ET;JHN1N6JC;PWSV879L;ELJZHWZX',
      statusContent: 'dot',
      hash: 'VVWKL6TC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVWKL6TC'
    },
    {
      status: 210,
      sku: 'PM0076833',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298697',
      rtl_batch_array: '6298697',
      name_search: 'MISRCLOU',
      name: 'Miscanthus sinensis Red Cloud',
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YB4Z5AGB',
      statusContent: 'dot',
      hash: '8ZJLHG4B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZJLHG4B'
    },
    {
      status: 210,
      sku: 'PM0076834',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298698',
      rtl_batch_array: '6298698',
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 998,
      chnn_stock_retail: 998,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6E7VK3LN;DDBGZZBL;RGW28J9J;DSCH93GV;7XNJSA12;VW9TY6J4;2KD13C5X;ST5LA5JB;VCJDWHKT;67YR1HE5;YP168CCT;NYHA6TZT;2HHZ78SB;5X8SL82N;ATGY8842;Z7PLP1S1;8JVZEBCA;LXJVKK55;5RR83H12;9Z1YAEDY;WN8K8NXK',
      statusContent: 'dot',
      hash: 'J4ATXRL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4ATXRL7'
    },
    {
      status: 210,
      sku: 'PM0076836',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298701',
      rtl_batch_array: '6298701',
      name_search: 'MISZEBRI',
      name: "Miscanthus sinensis 'Zebrinus'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 989,
      chnn_stock_retail: 989,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NJBCBBRB;ADKPAXAV;YS489W1S;XRPXYL62;8GZVNA6C;EPCW87X6;J1YTEA1P;1GC6D5AS;6CZRSXHC;N4Y9WKJG',
      statusContent: 'dot',
      hash: 'CX6EWR5Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CX6EWR5Z'
    },
    {
      status: 210,
      sku: 'PM0083861',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356998',
      rtl_batch_array: '6356998',
      name_search: 'ROAESCUL',
      name: 'Rodgersia aesculifolia',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XW9JNYXW;YDHR7WLJ;ZKREDZ92;9Y1VRZ5W;N1LJS7HT',
      statusContent: 'dot',
      hash: 'AER4SJVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AER4SJVX'
    },
    {
      status: 210,
      sku: 'PM0076838',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298703',
      rtl_batch_array: '6298703',
      name_search: 'MOASKYRA',
      name: "Molinia arundinacea 'Skyracer'",
      sppl_size_code: 'LEVC7',
      rng_range_identifier: 'C7',
      rng_range_description: 'C7',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W1Y7PXL9;WGBPZDJZ;SKYKN8P6;1Y89EDZL;GN6S2XY1',
      statusContent: 'dot',
      hash: 'DATDGRV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DATDGRV4'
    },
    {
      status: 810,
      sku: 'PM0076839',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298704',
      rtl_batch_array: '6298704',
      name_search: 'MOAGFRUI',
      name: "Morus alba 'Giant Fruit'",
      sppl_size_code: '175200C15',
      rng_range_identifier: 'H175200C15',
      rng_range_description: 'H175 cm 200 cm C15',
      sppl_stock_available: 93,
      chnn_stock_retail: 93,
      sppl_prcp: 44.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZX4WW7PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX4WW7PA'
    },
    {
      status: 210,
      sku: 'PM0077143',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302352',
      rtl_batch_array: '6300418, 6302352',
      name_search: 'ANARCHAN',
      name: 'Angelica archangelica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 462,
      chnn_stock_retail: 1283,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GTEANZS1;TSJVHBC9;C2JX5R1N;84Y8KPS8;KRH8DK3X;AZR8TE44;6WTSWZH2;EPY9VT7L;TBSNHR8W;EGV64ZLC;VEJZCWB8;2NRJVEG4;VSYEB4GN;ZXWS1TH9',
      statusContent: 'dot',
      hash: 'K48N7TG2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K48N7TG2'
    },
    {
      status: 210,
      sku: 'PM0076396',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353544',
      rtl_batch_array: '6353544',
      name_search: 'VEOFFICI',
      name: 'Verbena officinalis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 324,
      chnn_stock_retail: 324,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DWKB52EB;YBBXB6XN;Z4ZK2PAT',
      statusContent: 'dot',
      hash: 'R778KRC6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R778KRC6'
    },
    {
      status: 210,
      sku: 'PM0077141',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300408',
      rtl_batch_array: '6300408',
      name_search: 'ACPSPARR',
      name: "Aconitum 'Purple Sparrow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 532,
      chnn_stock_retail: 532,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ANNTS3BB;CLN5JJV8;EPJEPC5E;RXKNHSB5;5X43APZE',
      statusContent: 'dot',
      hash: 'BY693Z6N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BY693Z6N'
    },
    {
      status: 810,
      sku: 'PM0076818',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298681',
      rtl_batch_array: '6298681',
      name_search: 'MASRSTAR',
      name: "Magnolia stellata 'Royal Star'",
      sppl_size_code: '125150C10GE',
      rng_range_identifier: 'H125150C10',
      rng_range_description: 'H125 cm 150 cm C10',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 32.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W6Y3P8A6',
      statusContent: 'dot',
      hash: 'KS7J52Y4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS7J52Y4'
    },
    {
      status: 210,
      sku: 'PM0077142',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348947',
      rtl_batch_array: '6300415, 6352610, 6348947',
      name_search: 'ANHRSWAN',
      name: "Anemone hybrida 'Ruffled Swan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 829,
      chnn_stock_retail: 2097,
      sppl_order_minimum: 3,
      sppl_prcp: 1.888,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6ZWXNLGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZWXNLGD'
    },
    {
      status: 210,
      sku: 'PM0083862',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6356999',
      rtl_batch_array: '6356999',
      name_search: 'RUDFGOLD',
      name: "Rudbeckia fulgida 'Goldsturm'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LWBYZAT4;RW4PBGXP;4382951C;4T7BH8T8;7YJ5DGT6;AV5XNPYH;WD72SLRX;P5SH553W;82JWC1YN;E7ELP1EL;VSZE85ZT;C5R22YYD;284BERXS;5V3TTLV8;G56E54EX;JA14SGC8;SEH17VVR;WGYGPZR7;V6XVHHAC;7X81JZHJ',
      statusContent: 'dot',
      hash: 'EDXSZTB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDXSZTB4'
    },
    {
      status: 210,
      sku: 'PM0083863',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357000',
      rtl_batch_array: '6357000',
      name_search: 'RUFDEAMI',
      name: 'Rudbeckia fulgida deamii',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HJSCBADC;6VX7K53L;HKNLRB71;D8DA8EEA;V1ADB9VW;5C97G7Z8;54R7WXDH;EAAZHRLD;AZGEW262',
      statusContent: 'dot',
      hash: '6GWDSC3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GWDSC3T'
    },
    {
      status: 210,
      sku: 'PM0083864',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357001',
      rtl_batch_array: '6357001',
      name_search: 'THDELAVA',
      name: 'Thalictrum delavayi',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 1.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SVLK6ZPC',
      statusContent: 'dot',
      hash: 'WZEY1BSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZEY1BSE'
    },
    {
      status: 210,
      sku: 'PM0083865',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357002',
      rtl_batch_array: '6357002',
      name_search: 'TRHMIYAZ',
      name: "Tricyrtis hirta 'Miyazaki'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 792,
      chnn_stock_retail: 792,
      sppl_order_minimum: 3,
      sppl_prcp: 1.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7S8W7TKG;BGS1LB44',
      statusContent: 'dot',
      hash: 'DAAL98VB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DAAL98VB'
    },
    {
      status: 210,
      sku: 'PM0083866',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357004',
      rtl_batch_array: '6357004',
      name_search: 'VEVALBUM',
      name: "Veronicastrum virginicum 'Album'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BVCK29NS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVCK29NS'
    },
    {
      status: 210,
      sku: 'PM0083867',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357005',
      rtl_batch_array: '6357005',
      name_search: 'VEVERIKA',
      name: "Veronicastrum virginicum 'Erika'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L79J94TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L79J94TZ'
    },
    {
      status: 210,
      sku: 'PM0083868',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357006',
      rtl_batch_array: '6357006',
      name_search: 'VEVFASCI',
      name: "Veronicastrum virginicum 'Fascination'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4GVLKCJP;DVRBL5S7;9N3WLRY3;W7J1P8DA',
      statusContent: 'dot',
      hash: 'A5XSRKPX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5XSRKPX'
    },
    {
      status: 210,
      sku: 'PM0083869',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357007',
      rtl_batch_array: '6357007',
      name_search: 'VEVPGLOW',
      name: "Veronicastrum virginicum 'Pink Glow'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SSX89NB4;AAL6HSJ7',
      statusContent: 'dot',
      hash: 'NB84BGP3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NB84BGP3'
    },
    {
      status: 210,
      sku: 'PM0083870',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357008',
      rtl_batch_array: '6357008',
      name_search: 'VEVRARRO',
      name: "Veronicastrum virginicum 'Red Arrows'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2GXP5E7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2GXP5E7'
    },
    {
      status: 210,
      sku: 'PM0083871',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357009',
      rtl_batch_array: '6357009',
      name_search: 'VEVROSEU',
      name: "Veronicastrum virginicum 'Roseum'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 3,
      sppl_prcp: 2.14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HLWX8LNR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HLWX8LNR'
    },
    {
      status: 210,
      sku: 'PM0084289',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378452',
      rtl_batch_array: '6378452',
      name_search: 'BUDPEACO',
      name: 'Buddleja davidii Peacock',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 404,
      chnn_stock_retail: 404,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XBK5458J;KS6ZPYJ5;SPTADG6B;4LZRHC3P',
      statusContent: 'dot',
      hash: 'CZGVY936',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZGVY936'
    },
    {
      status: 910,
      sku: 'PM0084290',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDPEMPE',
      name: 'Buddleja davidii Purple Emperor',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      statusContent: 'dot',
      hash: 'LV3716Y8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LV3716Y8'
    },
    {
      status: 210,
      sku: 'PM0084291',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378456',
      rtl_batch_array: '6378456',
      name_search: 'BUDWCLOU',
      name: "Buddleja davidii 'White Cloud'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1167,
      chnn_stock_retail: 1167,
      sppl_prcp: 4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N1AVGA1Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1AVGA1Y'
    },
    {
      status: 210,
      sku: 'PM0084292',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378458',
      rtl_batch_array: '6378458',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 7970,
      chnn_stock_retail: 7970,
      sppl_prcp: 2.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'GW8HJJ6Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GW8HJJ6Y'
    },
    {
      status: 210,
      sku: 'PM0084293',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378459',
      rtl_batch_array: '6378459',
      name_search: 'BULINDLE',
      name: 'Buddleja lindleyana',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 3.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SV1Y7R49;BW7X57HA',
      statusContent: 'dot',
      hash: 'H4LPDP2V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4LPDP2V'
    },
    {
      status: 210,
      sku: 'PM0084294',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378465',
      rtl_batch_array: '6378465',
      name_search: 'BUWSUNGO',
      name: "Buddleja weyeriana 'Sungold'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 107,
      chnn_stock_retail: 107,
      sppl_prcp: 6.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4B84PXPB;KETH4VA6',
      statusContent: 'dot',
      hash: 'CD7WTY7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CD7WTY7W'
    },
    {
      status: 210,
      sku: 'PM0084295',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378466',
      rtl_batch_array: '6378466',
      name_search: 'BUWBALL',
      name: "Buddleja 'White Ball'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1460,
      chnn_stock_retail: 1460,
      sppl_prcp: 3.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5B1573HE;YH81KX32;5TL2J7GY;9RKLX3GN',
      statusContent: 'dot',
      hash: 'W6Z1TV16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6Z1TV16'
    },
    {
      status: 210,
      sku: 'PM0084296',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378303',
      rtl_batch_array: '6378303',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '012015P9',
      rng_range_identifier: 'H012015P9',
      rng_range_description: 'H12 cm 15 cm P9',
      sppl_stock_available: 106803,
      chnn_stock_retail: 106803,
      sppl_order_minimum: 6,
      sppl_prcp: 0.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'HPAWRB6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPAWRB6W'
    },
    {
      status: 810,
      sku: 'PM0084297',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378304',
      rtl_batch_array: '6378304',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 33,
      chnn_stock_retail: 33,
      sppl_prcp: 8.35,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '7ENX78ZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ENX78ZT'
    },
    {
      status: 910,
      sku: 'PM0084298',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COKCGIRL',
      name: "Cornus kousa 'China Girl'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore: 'AKGTG52N;PX1CDV49;DS3X5ZRE;5SSTBW1J',
      statusContent: 'dot',
      hash: '2NYBPCY7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '2NYBPCY7'
    },
    {
      status: 910,
      sku: 'PM0084299',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378321',
      rtl_batch_array: '6378321',
      name_search: 'DATPFRAG',
      name: 'Daphne transatlantica Pink Fragrance',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 14.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SXASAPR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SXASAPR2'
    },
    {
      status: 210,
      sku: 'PM0084300',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378322',
      rtl_batch_array: '6378322',
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      sppl_size_code: '050060KL',
      rng_range_identifier: 'H050060RB',
      rng_range_description: 'H50 cm 60 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 19.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7GHE3ZJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GHE3ZJA'
    },
    {
      status: 210,
      sku: 'PM0084301',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378323',
      rtl_batch_array: '6378323',
      name_search: 'EDCWLIEB',
      name: "Edgeworthia chrysantha 'Winter Liebe'",
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 21.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2KED2H48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2KED2H48'
    },
    {
      status: 210,
      sku: 'PM0084302',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378325',
      rtl_batch_array: '6378325',
      name_search: 'GAPBBERR',
      name: 'Gaultheria procumbens Big Berry',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 13426,
      chnn_stock_retail: 13426,
      sppl_order_minimum: 3,
      sppl_prcp: 1.53,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW1YX6YN;TV1WC9H1;LATLBEWL;VTB1SX66',
      statusContent: 'dot',
      hash: '26KH2TBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '26KH2TBH'
    },
    {
      status: 210,
      sku: 'PM0084303',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378326',
      rtl_batch_array: '6378326',
      name_search: 'GAPWPEAR',
      name: "Gaultheria procumbens 'White Pearl'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 4509,
      chnn_stock_retail: 4509,
      sppl_order_minimum: 3,
      sppl_prcp: 2.58,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ND5K94TZ;XBR5B6G3',
      statusContent: 'dot',
      hash: 'D7VVXGVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7VVXGVH'
    },
    {
      status: 210,
      sku: 'PM0084304',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378327',
      rtl_batch_array: '6378327',
      name_search: 'HEADDEND',
      name: "Hebe 'Addenda\u00ae'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 538,
      chnn_stock_retail: 538,
      sppl_order_minimum: 3,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5EXH8GV;LNTCJ2TN;6HYVNT64;7DPERBVX;GW14T61W;YRPN74RD',
      statusContent: 'dot',
      hash: 'DR448G6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DR448G6D'
    },
    {
      status: 210,
      sku: 'PM0084305',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378328',
      rtl_batch_array: '6378328',
      name_search: 'HEADDEND',
      name: "Hebe 'Addenda\u00ae'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 5079,
      chnn_stock_retail: 5079,
      sppl_order_minimum: 3,
      sppl_prcp: 2.84,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5EXH8GV;LNTCJ2TN;6HYVNT64;7DPERBVX;GW14T61W;YRPN74RD',
      statusContent: 'dot',
      hash: 'TT498DP7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TT498DP7'
    },
    {
      status: 210,
      sku: 'PM0084306',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378329',
      rtl_batch_array: '6378329',
      name_search: 'HEADDEND',
      name: "Hebe 'Addenda\u00ae'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1960,
      chnn_stock_retail: 1960,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5EXH8GV;LNTCJ2TN;6HYVNT64;7DPERBVX;GW14T61W;YRPN74RD',
      statusContent: 'dot',
      hash: 'TPKP3XRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TPKP3XRG'
    },
    {
      status: 210,
      sku: 'PM0084307',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378330',
      rtl_batch_array: '6378330',
      name_search: 'HEADDEND',
      name: "Hebe 'Addenda\u00ae'",
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_prcp: 6.87,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5EXH8GV;LNTCJ2TN;6HYVNT64;7DPERBVX;GW14T61W;YRPN74RD',
      statusContent: 'dot',
      hash: 'D4PC19PC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4PC19PC'
    },
    {
      status: 210,
      sku: 'PM0084308',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378331',
      rtl_batch_array: '6378331',
      name_search: 'HEAPENNI',
      name: 'Helianthemum apenninum',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1424,
      chnn_stock_retail: 1424,
      sppl_order_minimum: 3,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8C358LT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8C358LT9'
    },
    {
      status: 210,
      sku: 'PM0084309',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378332',
      rtl_batch_array: '6378332',
      name_search: 'HEAPPLIQ',
      name: "Hemerocallis 'Applique'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1523,
      chnn_stock_retail: 1523,
      sppl_order_minimum: 3,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LEBSH7VP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LEBSH7VP'
    },
    {
      status: 210,
      sku: 'PM0084310',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378333',
      rtl_batch_array: '6378333',
      name_search: 'HEHANNA',
      name: 'Hebe Hebedonna Anna',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YSV7D33L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSV7D33L'
    },
    {
      status: 910,
      sku: 'PM0084311',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHANNA',
      name: 'Hebe Hebedonna Anna',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      statusContent: 'dot',
      hash: 'JJJ68HJL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JJJ68HJL'
    },
    {
      status: 210,
      sku: 'PM0084312',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378336',
      rtl_batch_array: '6378336',
      name_search: 'HEHBRITT',
      name: 'Hebe Hebedonna Britta',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 556,
      chnn_stock_retail: 556,
      sppl_order_minimum: 3,
      sppl_prcp: 2.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SDENRRCT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDENRRCT'
    },
    {
      status: 210,
      sku: 'PM0076401',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290227',
      rtl_batch_array: '6290227, 6321875',
      name_search: 'ANAZUREA',
      name: 'Anchusa azurea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 627,
      chnn_stock_retail: 775,
      sppl_order_minimum: 3,
      sppl_prcp: 0.724,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SLSAZ7VN',
      statusContent: 'dot',
      hash: '4YHA7HT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4YHA7HT9'
    },
    {
      status: 210,
      sku: 'PM0077145',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300421',
      rtl_batch_array: '6300421',
      name_search: 'APGRAVEO',
      name: 'Apium graveolens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 311,
      chnn_stock_retail: 311,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZL2KRJTD;GGAJ6EGE;A1LT6HY7;9497GZT8;PZT8P231;TLV2ABKS;GSJZPXC5;VLNLL5ET;HG5KE2JJ;AXY968VS;JR5EG78L;5L2832HK;GXX4HWSS;BKWRSHVN;87KAEHHG;4G7EYTJS;9S783GP6;L92V787C;9R4361B1;8AYZL56C;8ANYTRKK;2RSXL38Z;K87T19W9;6W3LE4R1;LYDX8R9R',
      statusContent: 'dot',
      hash: 'V47VTVCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V47VTVCD'
    },
    {
      status: 910,
      sku: 'PM0077146',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'AQKRISTA',
      name: "Aquilegia 'Kristall'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore: 'YWJWTCZ4',
      statusContent: 'dot',
      hash: 'RS5CB471',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RS5CB471'
    },
    {
      status: 210,
      sku: 'PM0077150',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6302388',
      rtl_batch_array: '6300432, 6302388',
      name_search: 'ASNABBLU',
      name: "Aster novae-angliae 'Barr's Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 412,
      chnn_stock_retail: 1357,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9BDARLL9;868WYL5L',
      statusContent: 'dot',
      hash: 'TET1Y85L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TET1Y85L'
    },
    {
      status: 810,
      sku: 'PM0076059',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284404',
      rtl_batch_array: '6284404',
      name_search: 'MISNIPPO',
      name: "Miscanthus sinensis 'Nippon'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 69,
      chnn_stock_retail: 69,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WB67XVS7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB67XVS7'
    },
    {
      status: 210,
      sku: 'PM0077154',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300439',
      rtl_batch_array: '6300439',
      name_search: 'AMELLIPT',
      name: 'Amsonia elliptica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T147CTJJ;5ABLXSY8',
      statusContent: 'dot',
      hash: 'NBX49DG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NBX49DG9'
    },
    {
      status: 210,
      sku: 'PM0076060',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284405',
      rtl_batch_array: '6284405',
      name_search: 'MISRPFEI',
      name: "Miscanthus sinensis 'Roter Pfeil'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2VCWP1RW;LHX8KY9K;8BHTP695;SHH34RJ5;C7CPGV9B;LBCTD1RS;76HCCCHB;T6SDYZ5P',
      statusContent: 'dot',
      hash: '8NTV51TK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8NTV51TK'
    },
    {
      status: 210,
      sku: 'PM0076061',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284406',
      rtl_batch_array: '6284406',
      name_search: 'MISRCUTE',
      name: "Miscanthus sinensis 'Ruby Cute'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_order_minimum: 3,
      sppl_prcp: 2.573,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '713RCHKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '713RCHKX'
    },
    {
      status: 210,
      sku: 'PM0076062',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350685',
      rtl_batch_array: '6350685, 6319754',
      name_search: 'MISSILBE',
      name: "Miscanthus sinensis 'Silberfeder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 1410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JW1RC3RL;6E6Z224A;843T7K64',
      statusContent: 'dot',
      hash: 'JLPSA2RN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLPSA2RN'
    },
    {
      status: 810,
      sku: 'PM0076063',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284408',
      rtl_batch_array: '6284408',
      name_search: 'MISSIOUX',
      name: "Miscanthus sinensis 'Sioux'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KA936674',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KA936674'
    },
    {
      status: 210,
      sku: 'PM0076064',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284412',
      rtl_batch_array: '6284412',
      name_search: 'MISYJIMA',
      name: "Miscanthus sinensis 'Yaku Jima'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 1.664,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALKSY8HJ;VVXD9HXD;4G41V652;AWKP9YYE;NXZN7NX2;AJZ5L16G;94WX15DG;8XBNL6KB;1H5ZA9BA',
      statusContent: 'dot',
      hash: '412L8773',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '412L8773'
    },
    {
      status: 210,
      sku: 'PM0076066',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353062',
      rtl_batch_array: '6301758, 6353062',
      name_search: 'PAVSTRIC',
      name: "Panicum virgatum 'Strictum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 825,
      chnn_stock_retail: 1069,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VD62PD5T;KL2KXTC1',
      statusContent: 'dot',
      hash: 'BNV5NTW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNV5NTW7'
    },
    {
      status: 210,
      sku: 'PM0076841',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298706',
      rtl_batch_array: '6298706',
      name_search: 'MYGALE',
      name: 'Myrica gale',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1740,
      chnn_stock_retail: 1740,
      sppl_prcp: 4.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NXV5S2Y2',
      statusContent: 'dot',
      hash: 'DT6723WV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DT6723WV'
    },
    {
      status: 210,
      sku: 'PM0083872',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357010',
      rtl_batch_array: '6357010',
      name_search: 'AGRALABA',
      name: "Agastache rugosa 'Alabaster'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 2.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B766ZR44',
      statusContent: 'dot',
      hash: '945SBL9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '945SBL9C'
    },
    {
      status: 210,
      sku: 'PM0010338',
      core_name: 'Plant',
      sppl_ean: '8720349423200',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '3398753',
      rtl_batch_array: '3398753',
      name_search: 'MISMALEP',
      name: "Miscanthus sinensis 'Malepartus'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_prcp: 4.116,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Y9CN7848;616HAZWW;689RETN5;S36SCHA7;V545SXWH;9HAYDJ3C;LHDES1EE;L2V9EA88;CESLV854;BC8461HV;J8BDC9ZW;NCGYN2J4;BL8EADB8;DV5TAX9V;598B8EGP;CD6WGVP3;Y62EZ2NH;NN31621H;W6D46K2W;8PB1HWKW;2JV7VLXE',
      imageBatch: '1K5WVXTG',
      statusContent: 'dot',
      hash: 'ENWCSC76',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENWCSC76'
    },
    {
      status: 210,
      sku: 'PM0029696',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 5,
      btch_active_retail: '6134566',
      rtl_batch_array: '6238754, 5564690, 6350695, 6382297, 6134566',
      name_search: 'MOATRANS',
      name: "Molinia arundinacea 'Transparent'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1248,
      chnn_stock_retail: 8972,
      sppl_order_minimum: 3,
      sppl_prcp: 0.617,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ALG98ZE6;GCJGRDK7;SHRZ2K6R;7JPK1P1J;417K9K93;S3N8HNWN;TBBET22K;HEJYBATD;H29E7PZH',
      statusContent: 'dot',
      hash: 'P6SG29LA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6SG29LA'
    },
    {
      status: 810,
      sku: 'PM0076858',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298730',
      rtl_batch_array: '6298730',
      name_search: 'PHODIABO',
      name: "Physocarpus opulifolius 'Diabolo'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 51,
      chnn_stock_retail: 51,
      sppl_prcp: 24.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BHA5R7D;X1KTR91X;DSB163Z3;JWAXZA2R;6GLL5J7Z;H3JC9RTW;T76ELZJB;S5Z6SGKL;9L2DYX54;7WSNVWPV;4LH61XVG;2YWXVWLB;G5D1EYVE;KDHDBSD8;6XECT11Y;6LHSCBGJ;8AB65DYS;2JA52DJJ;HDL7P1LG;NGVZSKPG;TGTDXXBY;JE269EAX;G93Y1755;HJWCTV43',
      statusContent: 'dot',
      hash: 'LX37GR9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LX37GR9P'
    },
    {
      status: 210,
      sku: 'PM0076840',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298705',
      rtl_batch_array: '6298705',
      name_search: 'MUCAPILL',
      name: 'Muhlenbergia capillaris',
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 442,
      chnn_stock_retail: 442,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CXGXBXG8;TD6C4NGA',
      statusContent: 'dot',
      hash: 'K5BLP9A7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5BLP9A7'
    },
    {
      status: 210,
      sku: 'PM0076842',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298707',
      rtl_batch_array: '6298707',
      name_search: 'NADGSTRE',
      name: "Nandina domestica 'Gulf Stream'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 7382,
      chnn_stock_retail: 7382,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5177CD65;2ZXX95WG;HXBEY3NS;1KGAWP8J;DKXS5BGB;6D121H4B;VSRDDRHB;KW74VYE1;W3K99X4E;BC74L52W;ECSHV643;ZNS6C38G;A9NSHWK2',
      statusContent: 'dot',
      hash: 'LA8KXY9B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LA8KXY9B'
    },
    {
      status: 210,
      sku: 'PM0083873',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357011',
      rtl_batch_array: '6357011',
      name_search: 'ASRSENTI',
      name: "Astilbe (J) 'Red Sentinel'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 294,
      chnn_stock_retail: 294,
      sppl_prcp: 2.61,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2KSKH4PB;KRCHJ7CP',
      statusContent: 'dot',
      hash: 'A71A1ZPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A71A1ZPK'
    },
    {
      status: 210,
      sku: 'PM0076846',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298712',
      rtl_batch_array: '6298712',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      statusContent: 'dot',
      hash: '66KCKE8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66KCKE8R'
    },
    {
      status: 210,
      sku: 'PM0076847',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298713',
      rtl_batch_array: '6298713',
      name_search: 'PAVREHBR',
      name: "Panicum virgatum 'Rehbraun'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HTPBCVG1;BDLXK19Y;AVX56D5S;7RH9XSJ5;1XCWN1HP;2D2J95VP;DHYCCDXV;2YJN9CAT;2BR3BZTA;D1K77WWG;7RDRGPE4;KLY9228P',
      statusContent: 'dot',
      hash: 'SHB62JGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHB62JGZ'
    },
    {
      status: 210,
      sku: 'PM0076848',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298714',
      rtl_batch_array: '6298714',
      name_search: 'PAVROTST',
      name: "Panicum virgatum 'Rotstrahlbusch'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '465RJY8V;GLTPRJWC;5PDC2D6E;EX449DD1;Y91VH24K;B2E85B5G;E2Z6YKLP;S8ZTES9R;R7XVP3LX;4CPBG92G;9PR892J8;YJC76RY8;CG53XZ3S',
      statusContent: 'dot',
      hash: 'SZSYL4ZH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZSYL4ZH'
    },
    {
      status: 210,
      sku: 'PM0076849',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298715',
      rtl_batch_array: '6298715',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC4',
      rng_range_identifier: 'C4',
      rng_range_description: 'C4',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: 'T9AXZWN4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T9AXZWN4'
    },
    {
      status: 210,
      sku: 'PM0076850',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298716',
      rtl_batch_array: '6298716',
      name_search: 'PAPYMALL',
      name: "Pavonia praemorsa 'Yellow Mallow'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 3.025,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L9DRRS1G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9DRRS1G'
    },
    {
      status: 210,
      sku: 'PM0076851',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298719',
      rtl_batch_array: '6298719',
      name_search: 'PEAHAMEL',
      name: "Pennisetum alopecuroides 'Hameln'",
      sppl_size_code: 'LEVC12',
      rng_range_identifier: 'C12',
      rng_range_description: 'C12',
      sppl_stock_available: 979,
      chnn_stock_retail: 979,
      sppl_prcp: 13.393,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T758YXWE;ZV5CWGVS;9JDEK5B6;5DYGTGKE;W825TX94;PK62YW3Z;DTY1YWTK;BKX7AZ9K;BWBCNB23;BN8ZNBYT;KL2BXTPT;B2G676T9;W1JXB6ZN;8RXYR8D3;PZAS1ZXC;S12P97GP;S6H1DKV8;HGY6L5TR;B6LVPXD2;PTPDPT75;GS7DV5LY;K3N9CJ5P;4Z2Y8W3N;BG9L8VGE;WYZAKV6N;SYJ4RBB7;S9DVB8C5;CRK7Y8RA',
      statusContent: 'dot',
      hash: 'E1348C14',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E1348C14'
    },
    {
      status: 210,
      sku: 'PM0076852',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298724',
      rtl_batch_array: '6298724',
      name_search: 'PHFLFENN',
      name: "Photinia fraseri 'Little Fenna'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CJJE632V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CJJE632V'
    },
    {
      status: 210,
      sku: 'PM0076853',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298725',
      rtl_batch_array: '6298725',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: 'EYR5E18H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EYR5E18H'
    },
    {
      status: 210,
      sku: 'PM0083874',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357012',
      rtl_batch_array: '6357012',
      name_search: 'BAAUSTRA',
      name: 'Baptisia australis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '62P19NG1;9XK9HNLJ;E264X2EC;EB7475T7;6YCZPDAB;WNZ47Z59;G7JGW5ER;Y7331579;ZC4D99WK;DXYZRDT8;TRVTNJKX;VLJHAEHY',
      statusContent: 'dot',
      hash: 'CTTW7NC9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CTTW7NC9'
    },
    {
      status: 210,
      sku: 'PM0083875',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357013',
      rtl_batch_array: '6357013',
      name_search: 'BECHINEN',
      name: 'Betula chinensis',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_prcp: 3.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8Z88JNSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Z88JNSP'
    },
    {
      status: 210,
      sku: 'PM0076856',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298728',
      rtl_batch_array: '6298728',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 828,
      chnn_stock_retail: 828,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'J4V3DE8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J4V3DE8C'
    },
    {
      status: 810,
      sku: 'PM0076857',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298729',
      rtl_batch_array: '6298729',
      name_search: 'PHFRROBI',
      name: "Photinia fraseri 'Red Robin'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_prcp: 32.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NNY4Z91R;RHR9CDC7;YER9K4YH;YWT7LYWE;W6WCV4EV;L6966NDB;BKHC9NLN;Z9LLWYJS;6X2GT62W;R5GACWRR;S7AJX67H;JTHP7DZW;ZNP985ZA;P1WVAWLP;KH8ZCD3C;JE83GBCH;1GHT7XG8;ENSBANZW;426KGP5X;8T13NW82;2VE9E1XZ;CA4AB2XJ;BWRKKV4S;AX4DZYTK;SEGNWH9K;AJ48N7D1',
      statusContent: 'dot',
      hash: 'BAWYYE2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAWYYE2K'
    },
    {
      status: 810,
      sku: 'PM0076859',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298731',
      rtl_batch_array: '6298731',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 96,
      chnn_stock_retail: 96,
      sppl_prcp: 24.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'EBC7Y8D1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBC7Y8D1'
    },
    {
      status: 810,
      sku: 'PM0076860',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298732',
      rtl_batch_array: '6298732',
      name_search: 'PHOLRED',
      name: 'Physocarpus opulifolius Lady in Red',
      sppl_size_code: '150STC15',
      rng_range_identifier: 'STE150C15',
      rng_range_description: 'Stem 150 cm C15',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_prcp: 36.435,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E1ZPK5KC;5WR9J27E;EZVJ76CS;8EAAJ9A4;WPAL1Y45;64JVGJRJ;VXDP9LN3;XHCCYXTJ',
      statusContent: 'dot',
      hash: 'H26DXK7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H26DXK7A'
    },
    {
      status: 810,
      sku: 'PM0076861',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298733',
      rtl_batch_array: '6298733',
      name_search: 'PHOLUTEU',
      name: "Physocarpus opulifolius 'Luteus'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 44,
      chnn_stock_retail: 44,
      sppl_prcp: 24.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1R7E91LL;W6XE2JAJ;77S82XY8;AP2R9RNP',
      statusContent: 'dot',
      hash: 'RCRJ2LLW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RCRJ2LLW'
    },
    {
      status: 910,
      sku: 'PM0083876',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357014',
      rtl_batch_array: '6357014',
      name_search: 'SARWTEXA',
      name: "Salvia reptans 'West Texas'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EBBZ7ELE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBBZ7ELE'
    },
    {
      status: 910,
      sku: 'PM0077153',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALCANNAB',
      name: 'Althaea cannabina',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '5R62C51J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5R62C51J'
    },
    {
      status: 210,
      sku: 'PM0077157',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300444',
      rtl_batch_array: '6300444',
      name_search: 'ANHSBREE',
      name: 'Anemone hupehensis Summer Breeze',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YH3T58ZY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YH3T58ZY'
    },
    {
      status: 210,
      sku: 'PM0077158',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300445',
      rtl_batch_array: '6300445',
      name_search: 'ANHANNER',
      name: "Anemone hybrida 'Annerose'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2DDVYEY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DDVYEY7'
    },
    {
      status: 910,
      sku: 'PM0077155',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ANMNEUSC',
      name: "Anaphalis margaritacea 'Neuschnee'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'YZ11TDTV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YZ11TDTV'
    },
    {
      status: 210,
      sku: 'PM0077156',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300443',
      rtl_batch_array: '6300443',
      name_search: 'ANHPLMAR',
      name: 'Anemone hupehensis Pretty Lady Maria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1132,
      chnn_stock_retail: 1132,
      sppl_order_minimum: 3,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8XZ4Y7NP;836BTDSH;TGZP43A5;L2W58P68;Y8J1EVNL;5S4Y8191',
      statusContent: 'dot',
      hash: 'BXCBTRPP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BXCBTRPP'
    },
    {
      status: 210,
      sku: 'PM0077147',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300427',
      rtl_batch_array: '6300427',
      name_search: 'AQYSTAR',
      name: "Aquilegia 'Yellow Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G89RT6ST',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G89RT6ST'
    },
    {
      status: 810,
      sku: 'PM0083877',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357015',
      rtl_batch_array: '6357015',
      name_search: 'SAUCHIEL',
      name: "Salvia uliginosa 'Chiel'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 3.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '567H7VX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '567H7VX5'
    },
    {
      status: 210,
      sku: 'PM0077149',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300430',
      rtl_batch_array: '6300430',
      name_search: 'ASCFISHA',
      name: "Aster (U) 'Coombe Fishacre'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 721,
      chnn_stock_retail: 721,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GTBB1TH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTBB1TH5'
    },
    {
      status: 210,
      sku: 'PM0077148',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300429',
      rtl_batch_array: '6300429',
      name_search: 'ASABRESL',
      name: "Aster amellus 'Breslau'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 103,
      chnn_stock_retail: 103,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HSSRRSWL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSSRRSWL'
    },
    {
      status: 810,
      sku: 'PM0077151',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301441',
      rtl_batch_array: '6300435, 6301441',
      name_search: 'ASNARUDE',
      name: "Aster novae-angliae 'Rudelsburg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 94,
      chnn_stock_retail: 393,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KPYK7BWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPYK7BWB'
    },
    {
      status: 210,
      sku: 'PM0076397',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290211',
      rtl_batch_array: '6302390, 6290211',
      name_search: 'ASNBDAUE',
      name: "Aster novi-belgii 'Dauerblau'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1032,
      chnn_stock_retail: 1148,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GADZ9SHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GADZ9SHT'
    },
    {
      status: 210,
      sku: 'PM0076398',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6290212',
      rtl_batch_array: '6267603, 6290212, 6307955',
      name_search: 'ASNBKARM',
      name: "Aster novi-belgii 'Karminkuppel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1231,
      sppl_order_minimum: 3,
      sppl_prcp: 0.674,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SE9E22A8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SE9E22A8'
    },
    {
      status: 210,
      sku: 'PM0077152',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300436',
      rtl_batch_array: '6300436',
      name_search: 'ASTATARI',
      name: 'Aster tataricus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WJLJ9BV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJLJ9BV2'
    },
    {
      status: 810,
      sku: 'PM0083878',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357016',
      rtl_batch_array: '6357016',
      name_search: 'ASNERON',
      name: "Aster 'Neron'",
      sppl_size_code: 'LEVC74',
      rng_range_identifier: 'C7.5',
      rng_range_description: 'C7.5',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 7.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6XB11GYZ;ZBRSV9RG;8X53KKXC;Z93VGCX4;68R9PLLW;K2BD21TK;41RNERKK',
      statusContent: 'dot',
      hash: 'HV7ANXPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HV7ANXPA'
    },
    {
      status: 810,
      sku: 'PM0076065',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350729',
      rtl_batch_array: '6350729',
      name_search: 'ORBELLIS',
      name: 'Origanum Bellissimo',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 78,
      chnn_stock_retail: 78,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TNW533AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TNW533AX'
    },
    {
      status: 210,
      sku: 'PM0076399',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290224',
      rtl_batch_array: '6290224',
      name_search: 'TRCHARLO',
      name: "Tradescantia (A) 'Charlotte'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 236,
      chnn_stock_retail: 236,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DKJHAH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DKJHAH8'
    },
    {
      status: 210,
      sku: 'PM0076400',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6290225',
      rtl_batch_array: '6290225, 6348434',
      name_search: 'TRINNOCE',
      name: "Tradescantia (A) 'Innocence'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 398,
      chnn_stock_retail: 735,
      sppl_order_minimum: 3,
      sppl_prcp: 0.823,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8ZAC697N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZAC697N'
    },
    {
      status: 210,
      sku: 'PM0084313',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378338',
      rtl_batch_array: '6378338',
      name_search: 'HEHDIANA',
      name: 'Hebe Hebedonna Diana',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 344,
      chnn_stock_retail: 344,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BYV7J1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BYV7J1K'
    },
    {
      status: 210,
      sku: 'PM0084314',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378339',
      rtl_batch_array: '6378339',
      name_search: 'HEHDIANA',
      name: 'Hebe Hebedonna Diana',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 440,
      chnn_stock_retail: 440,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VWVVCDA9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWVVCDA9'
    },
    {
      status: 210,
      sku: 'PM0084315',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378340',
      rtl_batch_array: '6378340',
      name_search: 'HEHEMMA',
      name: 'Hebe Hebedonna Emma',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 454,
      chnn_stock_retail: 454,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D546VTNH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D546VTNH'
    },
    {
      status: 210,
      sku: 'PM0084316',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378341',
      rtl_batch_array: '6378341',
      name_search: 'HEHEMMA',
      name: 'Hebe Hebedonna Emma',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 3292,
      chnn_stock_retail: 3292,
      sppl_order_minimum: 3,
      sppl_prcp: 2.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T45K6GD2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T45K6GD2'
    },
    {
      status: 210,
      sku: 'PM0084317',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378345',
      rtl_batch_array: '6378345',
      name_search: 'HEHJENNA',
      name: 'Hebe Hebedonna Jenna',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 6.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXY4SATD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXY4SATD'
    },
    {
      status: 210,
      sku: 'PM0084318',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378346',
      rtl_batch_array: '6378346',
      name_search: 'HEHNIKKA',
      name: 'Hebe Hebedonna Nikka',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1208,
      chnn_stock_retail: 1208,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S4VA6CLA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4VA6CLA'
    },
    {
      status: 210,
      sku: 'PM0084319',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378347',
      rtl_batch_array: '6378347',
      name_search: 'HEGKATRI',
      name: "Hebe 'Golden Katrina'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 752,
      chnn_stock_retail: 752,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8G2L5XK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8G2L5XK'
    },
    {
      status: 210,
      sku: 'PM0084320',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378350',
      rtl_batch_array: '6378350',
      name_search: 'HEGKATRI',
      name: "Hebe 'Golden Katrina'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1331,
      chnn_stock_retail: 1331,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRLDCB5R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRLDCB5R'
    },
    {
      status: 810,
      sku: 'PM0084321',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378351',
      rtl_batch_array: '6378351',
      name_search: 'HEHNIKKA',
      name: 'Hebe Hebedonna Nikka',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 6.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SKSWN9BL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SKSWN9BL'
    },
    {
      status: 910,
      sku: 'PM0084322',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HEHNIKKA',
      name: 'Hebe Hebedonna Nikka',
      rng_range_identifier: 'H050060C50',
      statusContent: 'dot',
      hash: 'YZLYAP3Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YZLYAP3Z'
    },
    {
      status: 210,
      sku: 'PM0084323',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378354',
      rtl_batch_array: '6378354',
      name_search: 'HETRICOL',
      name: "Hebe 'Tricolor'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 2046,
      chnn_stock_retail: 2046,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4WN7VSRW;ND6KDLBL',
      statusContent: 'dot',
      hash: 'DXX394KJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXX394KJ'
    },
    {
      status: 210,
      sku: 'PM0084324',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378356',
      rtl_batch_array: '6378356',
      name_search: 'HEVPURPL',
      name: 'Hebe Vinoa Purple',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 256,
      chnn_stock_retail: 256,
      sppl_order_minimum: 3,
      sppl_prcp: 2.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6XWLY8L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XWLY8L1'
    },
    {
      status: 210,
      sku: 'PM0084325',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378357',
      rtl_batch_array: '6378357',
      name_search: 'HEVPURPL',
      name: 'Hebe Vinoa Purple',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1738,
      chnn_stock_retail: 1738,
      sppl_prcp: 3.16,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E5441HK9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E5441HK9'
    },
    {
      status: 210,
      sku: 'PM0084326',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378358',
      rtl_batch_array: '6378358',
      name_search: 'HYIMIRIS',
      name: 'Hypericum inodorum Magical Iris',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_order_minimum: 3,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GB6XNV17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GB6XNV17'
    },
    {
      status: 210,
      sku: 'PM0084327',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378359',
      rtl_batch_array: '6378359',
      name_search: 'HYIMIRIS',
      name: 'Hypericum inodorum Magical Iris',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y4423LYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y4423LYY'
    },
    {
      status: 810,
      sku: 'PM0084328',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378360',
      rtl_batch_array: '6378360',
      name_search: 'HYIMIRIS',
      name: 'Hypericum inodorum Magical Iris',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 33,
      chnn_stock_retail: 33,
      sppl_prcp: 6.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HNZBA7C4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNZBA7C4'
    },
    {
      status: 210,
      sku: 'PM0084329',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378361',
      rtl_batch_array: '6378361',
      name_search: 'HYMATTRA',
      name: 'Hypericum Miracle Attraction',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 3467,
      chnn_stock_retail: 3467,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ER79A9V2;JH6T4ZRT;4DG46GKS;XVGRK34R;AZW4VLNP;DRVTE8LZ',
      statusContent: 'dot',
      hash: 'Z69W6XPW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z69W6XPW'
    },
    {
      status: 910,
      sku: 'PM0084330',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMATTRA',
      name: 'Hypericum Miracle Attraction',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'ER79A9V2;JH6T4ZRT;4DG46GKS;XVGRK34R;AZW4VLNP;DRVTE8LZ',
      statusContent: 'dot',
      hash: 'XJNRS9AH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XJNRS9AH'
    },
    {
      status: 210,
      sku: 'PM0084331',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378362',
      rtl_batch_array: '6378362',
      name_search: 'HYMATTRA',
      name: 'Hypericum Miracle Attraction',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 6.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ER79A9V2;JH6T4ZRT;4DG46GKS;XVGRK34R;AZW4VLNP;DRVTE8LZ',
      statusContent: 'dot',
      hash: 'P18LSSZB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P18LSSZB'
    },
    {
      status: 210,
      sku: 'PM0084332',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378363',
      rtl_batch_array: '6378363',
      name_search: 'HYMBLIZZ',
      name: 'Hypericum Miracle Blizz',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1990,
      chnn_stock_retail: 1990,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BDDEBKS8;J2E353SS;L53YY4AR;VX6K1611;SJPZECLX;9W6SC131;E5PSW1YW',
      statusContent: 'dot',
      hash: 'CWBCRVTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CWBCRVTH'
    },
    {
      status: 910,
      sku: 'PM0084333',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMBLIZZ',
      name: 'Hypericum Miracle Blizz',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore:
        'BDDEBKS8;J2E353SS;L53YY4AR;VX6K1611;SJPZECLX;9W6SC131;E5PSW1YW',
      statusContent: 'dot',
      hash: '99JA6G8E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '99JA6G8E'
    },
    {
      status: 910,
      sku: 'PM0084334',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMBLIZZ',
      name: 'Hypericum Miracle Blizz',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      imageCore:
        'BDDEBKS8;J2E353SS;L53YY4AR;VX6K1611;SJPZECLX;9W6SC131;E5PSW1YW',
      statusContent: 'dot',
      hash: '45HYKHTP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '45HYKHTP'
    },
    {
      status: 210,
      sku: 'PM0084335',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378364',
      rtl_batch_array: '6378364',
      name_search: 'HYMBLOSS',
      name: 'Hypericum Miracle Blossom',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1995,
      chnn_stock_retail: 1995,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TDNVKY8S;PRW4CTHT;LG6L1SLP;WZNZ52JD;JXCGHH2G;J1GYYHV7',
      statusContent: 'dot',
      hash: 'X8T4EATN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X8T4EATN'
    },
    {
      status: 910,
      sku: 'PM0084336',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMBLOSS',
      name: 'Hypericum Miracle Blossom',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'TDNVKY8S;PRW4CTHT;LG6L1SLP;WZNZ52JD;JXCGHH2G;J1GYYHV7',
      statusContent: 'dot',
      hash: 'YG2SWW2J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YG2SWW2J'
    },
    {
      status: 910,
      sku: 'PM0084337',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMBLOSS',
      name: 'Hypericum Miracle Blossom',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      imageCore: 'TDNVKY8S;PRW4CTHT;LG6L1SLP;WZNZ52JD;JXCGHH2G;J1GYYHV7',
      statusContent: 'dot',
      hash: '44XDZSYC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '44XDZSYC'
    },
    {
      status: 210,
      sku: 'PM0076865',
      core_name: 'Plant',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 2,
      btch_active_retail: '6309068',
      rtl_batch_array: '6298738, 6309068',
      name_search: 'PIJPASSI',
      name: "Pieris japonica 'Passion'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 3744,
      sppl_prcp: 7.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5ALXRATH;R666VY63;V44EW5A5;LPXRZVCH;546NDJ9C;8TESAHAG;YAKSWRCE;6ZCXYLK6;T2GKESYC;62EKEJDT;WTKKCPT4;BS457Y7P;VHEV4JRY;4HKHN85H;S8SDZG3T;PW6LHC45',
      statusContent: 'dot',
      hash: 'B5J7E77N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5J7E77N'
    },
    {
      status: 210,
      sku: 'PM0083879',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357460',
      rtl_batch_array: '6357460',
      name_search: 'EDCGRAND',
      name: "Edgeworthia chrysantha 'Grandiflora'",
      sppl_size_code: '030035C3',
      rng_range_identifier: 'H030035C3',
      rng_range_description: 'H30 cm 35 cm C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 7.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DPZX6VB6;RJTZP2L8;SKN14574;D8J5TXWT;55XZ55J2;9HPDEWVW',
      statusContent: 'dot',
      hash: 'JCAH392T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JCAH392T'
    },
    {
      status: 210,
      sku: 'PM0076067',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353084',
      rtl_batch_array: '6301767, 6353084',
      name_search: 'PEDTOWER',
      name: "Penstemon 'Dark Towers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 601,
      chnn_stock_retail: 751,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2PD4KGP5;YWVPWZGC;PBDW12EK;1D3DTZXY;N4DA3531;WKNBL6LK;DBCS1J9R;H8XEVRK5;D72HL573;12LE6549;AWRXX1XZ;X638PPLH',
      statusContent: 'dot',
      hash: 'LTZ7SCJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTZ7SCJ2'
    },
    {
      status: 210,
      sku: 'PM0076069',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6353176',
      rtl_batch_array: '6353176, 6308739, 6363195',
      name_search: 'ROPINNAT',
      name: 'Rodgersia pinnata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2142,
      chnn_stock_retail: 6463,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C9PVA5J9;56YXT1TA;26HNV5KA;996VNZ53;4HXCRPX4;T2YNWSPY;LSN79VYZ;PZN9ERJ8;JG39XVWW',
      statusContent: 'dot',
      hash: 'H3ERGVTY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H3ERGVTY'
    },
    {
      status: 210,
      sku: 'PM0076606',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295622',
      rtl_batch_array: '6295622',
      name_search: 'GERLVARI',
      name: "Geum rivale 'Leonard's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 536,
      chnn_stock_retail: 536,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8LKD5HA1',
      statusContent: 'dot',
      hash: '9NA1AR5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NA1AR5A'
    },
    {
      status: 810,
      sku: 'PM0077162',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300452',
      rtl_batch_array: '6300452',
      name_search: 'BASTARLI',
      name: "Baptisia 'Starlite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K9YPP8H1;YSZNV3H1',
      statusContent: 'dot',
      hash: 'ND3RJVPK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ND3RJVPK'
    },
    {
      status: 810,
      sku: 'PM0076070',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284436',
      rtl_batch_array: '6284436',
      name_search: 'EDI66275',
      name: "Roscoea purpurea 'Peacock'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TGRDLZ6R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TGRDLZ6R'
    },
    {
      status: 810,
      sku: 'PM0076071',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284437',
      rtl_batch_array: '6284437',
      name_search: 'EDI89285',
      name: "Roscoea purpurea 'Vincent'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 2.826,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V8X7JBJZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8X7JBJZ'
    },
    {
      status: 810,
      sku: 'PM0083880',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357461',
      rtl_batch_array: '6357461',
      name_search: 'HYPFLTBI',
      name: 'Hydrangea pan. Fire Light Tiny Bit',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 6.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DJPST3TL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJPST3TL'
    },
    {
      status: 210,
      sku: 'PM0076073',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353284',
      rtl_batch_array: '6353284',
      name_search: 'SEABBEYD',
      name: "Sedum 'Abbeydore'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 372,
      chnn_stock_retail: 372,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8KYWAL5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KYWAL5P'
    },
    {
      status: 210,
      sku: 'PM0076402',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290427',
      rtl_batch_array: '6290427',
      name_search: 'HEHGLACI',
      name: "Hedera helix 'Glacier'",
      sppl_size_code: '125150C3',
      rng_range_identifier: 'H125150C3',
      rng_range_description: 'H125 cm 150 cm C3',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 4.067,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YNKZE78G;ST39J6WN;1AK4BT6L;KJ6P7ESR;743J88XA;EZL4Y25P;ZH8ZXXVK;H45XV8VC',
      statusContent: 'dot',
      hash: '2BCXRSRH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BCXRSRH'
    },
    {
      status: 210,
      sku: 'PM0076403',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290428',
      rtl_batch_array: '6290428',
      name_search: 'HEHGOLDC',
      name: "Hedera helix 'Goldchild'",
      sppl_size_code: '080100C14',
      rng_range_identifier: 'H080100C1.5',
      rng_range_description: 'H80 cm 100 cm C1.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 1.666,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJRW5453;57LGK482;7ZC75WXR;XXLZ6PKD;YD2JNZA2',
      statusContent: 'dot',
      hash: '9RACSV95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9RACSV95'
    },
    {
      status: 210,
      sku: 'PM0076404',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290429',
      rtl_batch_array: '6290429',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 497,
      chnn_stock_retail: 497,
      sppl_order_minimum: 3,
      sppl_prcp: 2.033,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'DJGC7L8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJGC7L8L'
    },
    {
      status: 210,
      sku: 'PM0076405',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6290430',
      rtl_batch_array: '6290430',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_prcp: 4.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'RD6ZSSP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RD6ZSSP1'
    },
    {
      status: 210,
      sku: 'PM0032222',
      core_name: 'Plant',
      btch_manufacturer: 23,
      rtl_batch_array_total: 1,
      btch_active_retail: '6252728',
      rtl_batch_array: '6252728',
      name_search: 'PHAPICTA',
      name: "Phalaris arundinacea 'Picta'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 369,
      chnn_stock_retail: 369,
      sppl_prcp: 2.024,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GNT2XSAG;DPG99TWH;55E82H1K;E8A62ZYG;BDDGPBA8;R69NRPA9;YVB8AKLY;9BWLCVYJ;T35C5T18;SDC67YPB;E55CA6LE',
      statusContent: 'dot',
      hash: 'BR9CRA2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BR9CRA2H'
    },
    {
      status: 210,
      sku: 'PM0039122',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345275',
      rtl_batch_array: '6345275, 6266258',
      name_search: 'NEKCAT',
      name: "Nepeta 'Kit Cat'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 4016,
      chnn_stock_retail: 4441,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EC699YYS;STP14L9P;83STCVNN;AX4VWJWK;1B6ZZENW;KJ1X6EJN;9S1BJ9PW;WNWGJ9PH',
      statusContent: 'dot',
      hash: 'VSCGDJ3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSCGDJ3G'
    },
    {
      status: 810,
      sku: 'PM0039734',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6269814',
      rtl_batch_array: '6269814, 6284954, 6274779',
      name_search: 'TRJASMIN',
      name: 'Trachelospermum jasminoides',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 20,
      chnn_stock_retail: 868,
      sppl_order_minimum: 3,
      sppl_prcp: 5.029,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YN6TSD7H;57A8BNT6;GVA8VTLW;NLSXT9AP;YVDZBX3Z;YE3H7EGP;VVK2YPTD;PCDK18H7;1BY2YPVS;KBWN982V;T91B9RLC;DX346D18;WC39CB2E;97672L59;TJZ3DDA8;TV7HDW2W;CEPLR15D;871JCBJZ;RCP3ZESN;XB2L1B38;PWB9WDSV;H5ATSW5R;ZXRXLZPW;4E1DEX37',
      statusContent: 'dot',
      hash: 'ZT13GAAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZT13GAAS'
    },
    {
      status: 210,
      sku: 'PM0076862',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298735',
      rtl_batch_array: '6298735',
      name_search: 'PIJLFROS',
      name: "Pieris japonica 'Little Frosty'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '64WTNZ2G;E9CVBWHE;PCBBBVRX;YWH1HAZS;VE3J7ZRA;87Y9KCKS;YRNNKEXB;BGZR791C;A15BKH5C;LVZCCKJN;C93BJ7PK;C6WLC65R',
      statusContent: 'dot',
      hash: 'VVLN7SL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VVLN7SL2'
    },
    {
      status: 210,
      sku: 'PM0076863',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298736',
      rtl_batch_array: '6298736',
      name_search: 'PIJLGOLD',
      name: "Pieris japonica 'Little Goldy'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1398,
      chnn_stock_retail: 1398,
      sppl_prcp: 8.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DCHHPTS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DCHHPTS4'
    },
    {
      status: 210,
      sku: 'PM0076864',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298737',
      rtl_batch_array: '6298737',
      name_search: 'PIJLHGRE',
      name: "Pieris japonica 'Little Heath Green'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1289,
      chnn_stock_retail: 1289,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1T5KES7V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1T5KES7V'
    },
    {
      status: 210,
      sku: 'PM0076866',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298739',
      rtl_batch_array: '6298739',
      name_search: 'PIJPURIT',
      name: "Pieris japonica 'Purity'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1821,
      chnn_stock_retail: 1821,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J198YKNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J198YKNV'
    },
    {
      status: 210,
      sku: 'PM0076867',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298740',
      rtl_batch_array: '6298740',
      name_search: 'PIJRMILL',
      name: "Pieris japonica 'Red Mill'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1753,
      chnn_stock_retail: 1753,
      sppl_prcp: 7.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P27S4W9Y;RB5ADJ46;7BSHB85V;61NVEJRW;P8SP8G7S;ED2SD6AS;12VNEV7G;Z6SR8JSR;154SPS99;ESRE6CGA;83TBHDWZ;E79V1Z79',
      statusContent: 'dot',
      hash: 'P2LVDX4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2LVDX4A'
    },
    {
      status: 210,
      sku: 'PM0076869',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298743',
      rtl_batch_array: '6298743',
      name_search: 'PRLOLUYK',
      name: "Prunus laurocerasus 'Otto Luyken'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'REYBE9VN;58JHB1S4;YSHS6CVA;VEL6D4DH;251JKWCD;HRL557TA;P1W26GSZ',
      statusContent: 'dot',
      hash: 'B669TB22',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B669TB22'
    },
    {
      status: 210,
      sku: 'PM0076870',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298744',
      rtl_batch_array: '6298744',
      name_search: 'PRLZABEL',
      name: "Prunus laurocerasus 'Zabeliana'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A18N7CPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A18N7CPH'
    },
    {
      status: 810,
      sku: 'PM0076871',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298745',
      rtl_batch_array: '6298745',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '090STC10',
      rng_range_identifier: 'STE090C10',
      rng_range_description: 'Stem 90 cm C10',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_prcp: 31.083,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'PW69D8S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW69D8S2'
    },
    {
      status: 210,
      sku: 'PM0076872',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298747',
      rtl_batch_array: '6298747',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '110STC10',
      rng_range_identifier: 'STE110C10',
      rng_range_description: 'Stem 110 cm C10',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 36.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'KTV5T4BV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KTV5T4BV'
    },
    {
      status: 210,
      sku: 'PM0076873',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298748',
      rtl_batch_array: '6298748',
      name_search: 'PRNBRILL',
      name: "Prunus nipponica 'Brillant'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 804,
      chnn_stock_retail: 804,
      sppl_prcp: 4.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z1JG1SER;DGWBYPVB;P5S8SAE7;14W5BZA9;5B8VEVS3;J2ACAACP;9VK1JN48',
      statusContent: 'dot',
      hash: 'AVPRCDXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AVPRCDXC'
    },
    {
      status: 210,
      sku: 'PM0076874',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298749',
      rtl_batch_array: '6298749',
      name_search: 'PRTOMENT',
      name: 'Prunus tomentosa',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 1265,
      chnn_stock_retail: 1265,
      sppl_prcp: 4.533,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ARSTV5A9;RLSK65XK;67HVSCET;ALW6YVEH;4VEYDHAE;ZL26WG2G',
      statusContent: 'dot',
      hash: 'RPSVPSX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPSVPSX5'
    },
    {
      status: 210,
      sku: 'PM0076877',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298752',
      rtl_batch_array: '6298752',
      name_search: 'PYTETON',
      name: "Pyracantha 'Teton'",
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 2.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PL8AC7DA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PL8AC7DA'
    },
    {
      status: 210,
      sku: 'PM0076878',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298753',
      rtl_batch_array: '6298753',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 6.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: '6NYVCSLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NYVCSLK'
    },
    {
      status: 810,
      sku: 'PM0076881',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298756',
      rtl_batch_array: '6298756',
      name_search: 'QUPGDWAR',
      name: "Quercus palustris 'Green Dwarf'",
      sppl_size_code: '150STC12',
      rng_range_identifier: 'STE150C12',
      rng_range_description: 'Stem 150 cm C12',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 45.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GBRHCPVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBRHCPVA'
    },
    {
      status: 210,
      sku: 'PM0076882',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298757',
      rtl_batch_array: '6298757',
      name_search: 'RHANOUK',
      name: "Rhododendron (AJ) 'Anouk'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1625,
      chnn_stock_retail: 1625,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HT9D3AV8;LLJR131C',
      statusContent: 'dot',
      hash: 'CGRZTTHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGRZTTHT'
    },
    {
      status: 210,
      sku: 'PM0076883',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298758',
      rtl_batch_array: '6298758',
      name_search: 'RHFDPINK',
      name: 'Rhododendron Flash Dance Pink',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1654,
      chnn_stock_retail: 1654,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P24SYE7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P24SYE7G'
    },
    {
      status: 210,
      sku: 'PM0076884',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298759',
      rtl_batch_array: '6298759',
      name_search: 'RHFDSALM',
      name: 'Rhododendron Flash Dance Salmon',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_prcp: 3.271,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VWSD4KJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VWSD4KJP'
    },
    {
      status: 210,
      sku: 'PM0077159',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300447',
      rtl_batch_array: '6300447',
      name_search: 'ANHCARME',
      name: "Anemone hybrida 'Carmen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VPYKLNN9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPYKLNN9'
    },
    {
      status: 210,
      sku: 'PM0077160',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6300449',
      rtl_batch_array: '6300449, 6320837',
      name_search: 'ANEHDSWA',
      name: "Anemone hybrida 'Dreaming Swan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1990,
      chnn_stock_retail: 2371,
      sppl_prcp: 2.149,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D43NZR1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D43NZR1X'
    },
    {
      status: 210,
      sku: 'PM0077161',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300450',
      rtl_batch_array: '6300450',
      name_search: 'ANHGISEL',
      name: "Anemone hybrida 'Giselle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '615SDSY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '615SDSY3'
    },
    {
      status: 210,
      sku: 'PM0077163',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300453',
      rtl_batch_array: '6300453',
      name_search: 'BECHINEN',
      name: 'Betula chinensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1TJ7L43K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1TJ7L43K'
    },
    {
      status: 810,
      sku: 'PM0083881',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357462',
      rtl_batch_array: '6357462',
      name_search: 'HYPLLPUN',
      name: 'Hydrangea pan. Little Lime Punch',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 6.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TVBS93X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TVBS93X'
    },
    {
      status: 910,
      sku: 'PM0077165',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CAMONTAN',
      name: 'Carex montana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'Z7XDYBH9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Z7XDYBH9'
    },
    {
      status: 210,
      sku: 'PM0077166',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349914',
      rtl_batch_array: '6349914',
      name_search: 'CARPSEUD',
      name: 'Carex pseudocyperus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 141,
      chnn_stock_retail: 141,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGH9KXD7',
      statusContent: 'dot',
      hash: '8CE439YG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8CE439YG'
    },
    {
      status: 210,
      sku: 'PM0077167',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349921',
      rtl_batch_array: '6349921',
      name_search: 'CADSFAIR',
      name: "Caryopteris divaricata 'Snow Fairy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 333,
      chnn_stock_retail: 333,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEWKX8Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEWKX8Y8'
    },
    {
      status: 210,
      sku: 'PM0077172',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349959',
      rtl_batch_array: '6349959',
      name_search: 'COVGLAUC',
      name: 'Coronilla valentina glauca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KRSVPB9Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KRSVPB9Y'
    },
    {
      status: 810,
      sku: 'PM0077173',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349990',
      rtl_batch_array: '6349990',
      name_search: 'DIFABLOO',
      name: "Dicentra formosa 'Adrian Bloom'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y24ET8VN',
      statusContent: 'dot',
      hash: 'DDKBA5P2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDKBA5P2'
    },
    {
      status: 210,
      sku: 'PM0076876',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298751',
      rtl_batch_array: '6298751',
      name_search: 'PYCRCOLU',
      name: "Pyracantha coccinea 'Red Column'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 6,
      sppl_prcp: 3.522,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B18HB2C9;5ZAV8GNX',
      statusContent: 'dot',
      hash: 'ZC4ZG1B5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZC4ZG1B5'
    },
    {
      status: 210,
      sku: 'PM0077168',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6352618',
      rtl_batch_array: '6300465, 6301077, 6352618',
      name_search: 'ASMROSEN',
      name: "Astrantia major 'Rosensinfonie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 435,
      chnn_stock_retail: 2726,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H8XWB1VY',
      statusContent: 'dot',
      hash: 'LTYB594H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTYB594H'
    },
    {
      status: 210,
      sku: 'PM0076868',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298742',
      rtl_batch_array: '6298742',
      name_search: 'PRIKNMAI',
      name: "Prunus incisa 'Kojou-no-mai'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 894,
      chnn_stock_retail: 894,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z9B8SLJH;LJKEV9AE;PRLZCKB2;H674SENK;NTL778JL;XAC44826;1ZJ5JHY8;RJWAELSD;P9SAD49D;DHSLTBC4;BEB8XJ5E;T62GK6HN;KY53KRSX;RY5BV2N8;X555ZXSE;HG5X55DZ;85S5ZK5K;T4KZ886G;RY8KPRB4;LR4GA7VW',
      statusContent: 'dot',
      hash: '6KZ7PT5W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KZ7PT5W'
    },
    {
      status: 210,
      sku: 'PM0077169',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300467',
      rtl_batch_array: '6300467',
      name_search: 'BAAMACRO',
      name: 'Baptisia alba macrophylla (lactea)',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C791RWNY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C791RWNY'
    },
    {
      status: 210,
      sku: 'PM0077170',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6300468',
      rtl_batch_array: '6300468',
      name_search: 'BAACBLUE',
      name: "Baptisia australis 'Caspian Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 954,
      chnn_stock_retail: 954,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GHEHEKPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GHEHEKPE'
    },
    {
      status: 210,
      sku: 'PM0077171',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350066',
      rtl_batch_array: '6350066',
      name_search: 'ECMBELLE',
      name: "Echinacea 'Mini Belle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ED4TZGJC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ED4TZGJC'
    },
    {
      status: 810,
      sku: 'PM0083882',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357463',
      rtl_batch_array: '6357463',
      name_search: 'HYPQFIRE',
      name: 'Hydrangea paniculata Quick Fire',
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 21,
      chnn_stock_retail: 21,
      sppl_prcp: 6.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CDRABAN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CDRABAN1'
    },
    {
      status: 810,
      sku: 'PM0076068',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353151',
      rtl_batch_array: '6353151',
      name_search: 'PEALBUS',
      name: 'Petasites albus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2B51KH6K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2B51KH6K'
    },
    {
      status: 210,
      sku: 'PM0076875',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298750',
      rtl_batch_array: '6298750',
      name_search: 'PYOGLOW',
      name: "Pyracantha 'Orange Glow'",
      sppl_size_code: '070080C2',
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_order_minimum: 6,
      sppl_prcp: 3.522,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NBYDG2VB;JEBSXAXG',
      statusContent: 'dot',
      hash: '51GYRNWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51GYRNWW'
    },
    {
      status: 210,
      sku: 'PM0083883',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357464',
      rtl_batch_array: '6357464',
      name_search: 'SKJRUBES',
      name: 'Skimmia japonica Rubesta',
      sppl_size_code: '040050C20',
      rng_range_identifier: 'H040050C20',
      rng_range_description: 'H40 cm 50 cm C20',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_prcp: 33.816,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G3RXSTPE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G3RXSTPE'
    },
    {
      status: 910,
      sku: 'PM0083884',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJDPART',
      name: 'Spiraea japonica Double Play Artist',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'CKZGCJDY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CKZGCJDY'
    },
    {
      status: 910,
      sku: 'PM0083885',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPDPBBAN',
      name: 'Spiraea Double Play Big Bang',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'K4JG4P25',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K4JG4P25'
    },
    {
      status: 910,
      sku: 'PM0083886',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJDPRED',
      name: 'Spiraea japonica Double Play Red',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: 'A94DJS6E',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A94DJS6E'
    },
    {
      status: 910,
      sku: 'PM0083887',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPDPDOOZ',
      name: 'Spiraea Double Play Doozie',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      statusContent: 'dot',
      hash: '1P7XJZZ8',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1P7XJZZ8'
    },
    {
      status: 210,
      sku: 'PM0083888',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357469',
      rtl_batch_array: '6357469',
      name_search: 'ACPATROP',
      name: "Acer palmatum 'Atropurpureum'",
      sppl_size_code: '030040C14',
      rng_range_identifier: 'H030040C1.5',
      rng_range_description: 'H30 cm 40 cm C1.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.967,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTWLAPC1;5HYS2BAD;BANBGNLY;HR7WARZD;SXRTD26D;RT69SB1K;RZ26N3YB;8L6EVCB2;XHWW6KCW;27SVESKG;X27R28BB;C7ER3ZZ3;KASL57A5;B3Z9XZVR;JAVXXR2S;ADENDJLC;DLBAT62G;A3L7Y3E9;91AGNX86;T4YWRESP;4GDANGGA;LBKL5H61;78H8P45L;H3CPWPHG;XDPR8J4A;EZ7GW214;A1DKJWHB;NNP67SA3;ZXYGBCGL;51B9H4NP;LNGTTD6H',
      statusContent: 'dot',
      hash: 'RAZZE2SK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAZZE2SK'
    },
    {
      status: 210,
      sku: 'PM0083889',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357470',
      rtl_batch_array: '6357470',
      name_search: 'ACPBUTTE',
      name: "Acer palmatum 'Butterfly'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 7.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z8LTJ8S9;AG3R7G4L;W113J7X5;9W94Y6HL;4D9B3KT2;L1R2L9G9;81S3BYXS;EZE7H3GR;RD4HPXTL;55Z5SL7X',
      statusContent: 'dot',
      hash: 'SA59K3KT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SA59K3KT'
    },
    {
      status: 210,
      sku: 'PM0083890',
      core_name: 'Plant',
      btch_manufacturer: 1229,
      rtl_batch_array_total: 1,
      btch_active_retail: '6357471',
      rtl_batch_array: '6357471',
      name_search: 'CAJDKING',
      name: "Camellia japonica 'Dr King'",
      sppl_size_code: '060070C6',
      rng_range_identifier: 'H060070C6',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 26.136,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'Z84V5AG7;KBH3YV7B;G6BLA2AS;P3XY1A7H;7TYH9PD4;P96HL321;CL6RG5BV;PDLSZJYK;1BNBP9XA;8LCXKEXS;6SKS2JC2;Y7DBVSXD;7B1PATVE;HAYAYEHY;KPHV6YXN',
      statusContent: 'dot',
      hash: 'C8385X81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8385X81'
    },
    {
      status: 210,
      sku: 'PM0084338',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378365',
      rtl_batch_array: '6378365',
      name_search: 'HYIMMARV',
      name: 'Hypericum inod. Miracle Marvel',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 1952,
      chnn_stock_retail: 1952,
      sppl_order_minimum: 3,
      sppl_prcp: 1.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P433BTBN;6GS493ZV;G4P5HNEV;XYHHG62Z',
      statusContent: 'dot',
      hash: 'HPBYBZN7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HPBYBZN7'
    },
    {
      status: 910,
      sku: 'PM0084339',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYIMMARV',
      name: 'Hypericum inod. Miracle Marvel',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: 'P433BTBN;6GS493ZV;G4P5HNEV;XYHHG62Z',
      statusContent: 'dot',
      hash: '57Z88NKS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '57Z88NKS'
    },
    {
      status: 810,
      sku: 'PM0084340',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378366',
      rtl_batch_array: '6378366',
      name_search: 'HYIMMARV',
      name: 'Hypericum inod. Miracle Marvel',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 6.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P433BTBN;6GS493ZV;G4P5HNEV;XYHHG62Z',
      statusContent: 'dot',
      hash: 'GYNNTY3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYNNTY3G'
    },
    {
      status: 210,
      sku: 'PM0084341',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378367',
      rtl_batch_array: '6378367',
      name_search: 'HYMNIGHT',
      name: 'Hypericum Miracle Night',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.06,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HWG9GJBG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HWG9GJBG'
    },
    {
      status: 210,
      sku: 'PM0084342',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378368',
      rtl_batch_array: '6378368',
      name_search: 'HYMNIGHT',
      name: 'Hypericum Miracle Night',
      sppl_size_code: '030040C5',
      rng_range_identifier: 'H030040C5',
      rng_range_description: 'H30 cm 40 cm C5',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_prcp: 6.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P45BCZ11',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P45BCZ11'
    },
    {
      status: 910,
      sku: 'PM0084343',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYMPISTA',
      name: 'Hypericum Miracle Pistache',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'N4WN8236',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'N4WN8236'
    },
    {
      status: 910,
      sku: 'PM0084344',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILEXVERT',
      name: 'Ilex verticillata (vrl)',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      statusContent: 'dot',
      hash: '1Z3ZEKNP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '1Z3ZEKNP'
    },
    {
      status: 910,
      sku: 'PM0084345',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILVMBERR',
      name: 'Ilex verticillata Magical Berry',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      statusContent: 'dot',
      hash: 'Y356SHSP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'Y356SHSP'
    },
    {
      status: 910,
      sku: 'PM0084346',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILVERTIC',
      name: 'Ilex verticillata',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      imageCore:
        'LHVTGWTX;88XDXTNC;EHX4XTNA;XEB8ZRNL;ETPTGTE7;LH7AYCSV;RTGZ2V3E;PS9T1KHP;7EJVD8G3;XEERY7HR;48WV18V8',
      statusContent: 'dot',
      hash: 'JJXR64D3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JJXR64D3'
    },
    {
      status: 910,
      sku: 'PM0084347',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MADFCLOU',
      name: 'Magnolia denudata Fragrant Cloud',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      statusContent: 'dot',
      hash: 'K318VNPR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K318VNPR'
    },
    {
      status: 910,
      sku: 'PM0084348',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MADFCLOU',
      name: 'Magnolia denudata Fragrant Cloud',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      statusContent: 'dot',
      hash: '9XG4A16R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9XG4A16R'
    },
    {
      status: 910,
      sku: 'PM0084349',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MADFCLOU',
      name: 'Magnolia denudata Fragrant Cloud',
      rng_range_identifier: 'H060080C7.5',
      statusContent: 'dot',
      hash: 'ZZR87C17',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZZR87C17'
    },
    {
      status: 910,
      sku: 'PM0084350',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: '5NTW5T98',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5NTW5T98'
    },
    {
      status: 910,
      sku: 'PM0084351',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'P4XA75KV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P4XA75KV'
    },
    {
      status: 910,
      sku: 'PM0084352',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'CPGY5P3X',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CPGY5P3X'
    },
    {
      status: 910,
      sku: 'PM0084353',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGENIE',
      name: "Magnolia 'Genie'",
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        '7N14SRT2;Y928EPGR;BX1H2YWS;ES4LTY7Z;8PXPLECR;7VAT7J87;YT6B2A96',
      statusContent: 'dot',
      hash: 'C5RDA9W9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C5RDA9W9'
    },
    {
      status: 910,
      sku: 'PM0084354',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGRANDI',
      name: 'Magnolia grandiflora',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      imageCore: 'Z4XRBZGS;BA23HE4L;CXGNE86A;KKSYE57D;22C5EC6K;8THTXCJR',
      statusContent: 'dot',
      hash: 'DLPRCA42',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'DLPRCA42'
    },
    {
      status: 910,
      sku: 'PM0084355',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MAGLGEM',
      name: "Magnolia grandiflora 'Little Gem'",
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      imageCore: 'J7ZK7YX6;GA1TC5D9',
      statusContent: 'dot',
      hash: '48B97SZY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '48B97SZY'
    },
    {
      status: 810,
      sku: 'PM0084356',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378370',
      rtl_batch_array: '6378370',
      name_search: 'MASIEBOL',
      name: 'Magnolia sieboldii',
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 34,
      chnn_stock_retail: 34,
      sppl_prcp: 7.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '992PB4CA;HSSZ9GWK;K1VDH3VC;68D7TER8;J9KH1PXR;4P2XTTL4;V57LCPKP;6TDKNEHR',
      statusContent: 'dot',
      hash: '6DH5X9J7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6DH5X9J7'
    },
    {
      status: 810,
      sku: 'PM0084357',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378371',
      rtl_batch_array: '6378371',
      name_search: 'MASIEBOL',
      name: 'Magnolia sieboldii',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 9.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '992PB4CA;HSSZ9GWK;K1VDH3VC;68D7TER8;J9KH1PXR;4P2XTTL4;V57LCPKP;6TDKNEHR',
      statusContent: 'dot',
      hash: '718D8CY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '718D8CY7'
    },
    {
      status: 910,
      sku: 'PM0084358',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MASUNRIS',
      name: "Magnolia 'Sunrise'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      imageCore:
        'BCL16G5V;EE7LKK6A;XJZSE3RT;Z4JTW92T;V72RG6DK;T5PAKLVG;K81KT8E2;5V4LVC4G;S2YPZBD2;EKN46461;1TKZDH2V;74D84CNC;5SV84534',
      statusContent: 'dot',
      hash: 'V6AXTXHN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'V6AXTXHN'
    },
    {
      status: 210,
      sku: 'PM0084359',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378372',
      rtl_batch_array: '6378372',
      name_search: 'MASUNRIS',
      name: "Magnolia 'Sunrise'",
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 16.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BCL16G5V;EE7LKK6A;XJZSE3RT;Z4JTW92T;V72RG6DK;T5PAKLVG;K81KT8E2;5V4LVC4G;S2YPZBD2;EKN46461;1TKZDH2V;74D84CNC;5SV84534',
      statusContent: 'dot',
      hash: 'S5LZG191',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S5LZG191'
    },
    {
      status: 910,
      sku: 'PM0084360',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MASOULAN',
      name: 'Magnolia soulangeana',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'N5A8VY2Z;W72C65WA;7GD9KH1S;AY2TK34A;HBW3YGHC;JNH6SB9J;BA79PKJD;KB58PC8V;JC1W323T;XKB2C5WR;WST7NWEB;1E72489S;92EG1RVJ;4YDW2CPH;PAAVLBDG;4KYZVLAY;7HDWLZ2Z;TYZD1E4L;RWB17Z3P;Z9B4Z567;BSSAN1VX;X5VH24R7;CBEZSBPT;AYT9RD7N;HCDNNP5N;CZ6JRWZA;D21KKRS1',
      statusContent: 'dot',
      hash: 'J28SYB14',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'J28SYB14'
    },
    {
      status: 210,
      sku: 'PM0084361',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378374',
      rtl_batch_array: '6378374',
      name_search: 'MASTELLA',
      name: 'Magnolia stellata',
      sppl_size_code: '060080KL',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      sppl_stock_available: 640,
      chnn_stock_retail: 640,
      sppl_prcp: 7.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HLK821LY;N787C3PK;DR466NYB;WZT68P9E;LTNJAC2E;7TP9JYVB;XHRY2LZ1;9R3RANDY;A148NBJ5;DYKZDAKA;687LZ98K;CBZ8TKW4;JS1XE7YP;GAT81B5G',
      statusContent: 'dot',
      hash: '8RDJ7N9E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RDJ7N9E'
    },
    {
      status: 910,
      sku: 'PM0084362',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MASUSAN',
      name: "Magnolia 'Susan'",
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'W19R2WH3;ADAS36Y9;CH9Y1DG9;WBGRYH95;SBBWSGCR;TNSER1T7;7LYD26X2;19BW4EHT;SLJTW8YR;41X9ZPDW;JJHHVEZH;BT4B6GNW;AV92NJDJ;ZETJB6TP;SLT964TT;KL6CNAE7;RTA3A2DX',
      statusContent: 'dot',
      hash: 'KL71RAXK',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KL71RAXK'
    },
    {
      status: 810,
      sku: 'PM0084363',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378376',
      rtl_batch_array: '6378376',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 73,
      chnn_stock_retail: 73,
      sppl_prcp: 8.42,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'KA483W4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KA483W4R'
    },
    {
      status: 910,
      sku: 'PM0084364',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PAPERSIC',
      name: 'Parrotia persica',
      rng_range_identifier: 'H060080RB',
      rng_range_description: 'H60 cm 80 cm Root ball',
      imageCore:
        'V3JDBA3R;KKBT7JWK;KL7CZBLJ;ZPPE6DZ6;ZVV83VA2;DH2N3V6Y;2SGXVJYA;PYXSVS9P;L66G5VRB;8AS61WNN;LKBAVJTH;WXWSZPD7;R7P6Z3ZA;5L7B1AWE;YDK1XPX6;KEV73LWS;C5STKXE3;PYBLWWHT',
      statusContent: 'dot',
      hash: 'K9T2SGVY',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'K9T2SGVY'
    },
    {
      status: 210,
      sku: 'PM0076903',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298782',
      rtl_batch_array: '6298782',
      name_search: 'SANBBEAU',
      name: 'Sambucus nigra Black Beauty',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1965,
      chnn_stock_retail: 1965,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZKBBJLA7;TGPVETE4;EJERXPAT;WNKR1DCC;GDY7E6K7;LZH9TBTH;SD8RPLKV',
      statusContent: 'dot',
      hash: '6B6LCEY4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6B6LCEY4'
    },
    {
      status: 210,
      sku: 'PM0076904',
      core_name: 'Plant',
      btch_manufacturer: 1946,
      rtl_batch_array_total: 2,
      btch_active_retail: '6310388',
      rtl_batch_array: '6298783, 6310388',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: 'PLGC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 599,
      chnn_stock_retail: 8271,
      sppl_prcp: 3.13,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'J3ZWG68S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3ZWG68S'
    },
    {
      status: 210,
      sku: 'PM0076906',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298785',
      rtl_batch_array: '6298785',
      name_search: 'SANBTOWE',
      name: 'Sambucus nigra Black Tower',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2896,
      chnn_stock_retail: 2896,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '443DVLWA;CYXXJ6E8;KV2A5HYK;BCV14524;D5EY9XN9;G34YXXSK;D6398STY;HL5TN4T1;1B1446B7;1B5DH2ZJ;TYG49Z6A;XGVKGY5V;ZPZYXXCE',
      statusContent: 'dot',
      hash: 'V11EK3V8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V11EK3V8'
    },
    {
      status: 210,
      sku: 'PM0076076',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353408',
      rtl_batch_array: '6353408',
      name_search: 'SESICEBE',
      name: "Sedum spectabile 'Iceberg'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1217,
      chnn_stock_retail: 1217,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X37RWZD2',
      statusContent: 'dot',
      hash: 'DL88V8VN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DL88V8VN'
    },
    {
      status: 210,
      sku: 'PM0076080',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353576',
      rtl_batch_array: '6353576, 6333406',
      name_search: 'VEVFASCI',
      name: "Veronicastrum virginicum 'Fascination'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3816,
      chnn_stock_retail: 5027,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4GVLKCJP;DVRBL5S7;9N3WLRY3;W7J1P8DA',
      statusContent: 'dot',
      hash: 'LK12B83Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LK12B83Z'
    },
    {
      status: 210,
      sku: 'PM0077174',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 1,
      btch_active_retail: '6333216',
      rtl_batch_array: '6333216',
      name_search: 'DIFORMOS',
      name: 'Dicentra formosa',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 909,
      chnn_stock_retail: 909,
      sppl_order_minimum: 3,
      sppl_prcp: 0.971,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '46JX5CVL;4RA2CKLX;EHVHJCZW',
      statusContent: 'dot',
      hash: 'DNBDVCY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DNBDVCY8'
    },
    {
      status: 210,
      sku: 'PM0077178',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350098',
      rtl_batch_array: '6350098, 6320870',
      name_search: 'ECTSOUP',
      name: "Echinacea 'Tomato Soup'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2619,
      chnn_stock_retail: 3399,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WG9PNLTD',
      statusContent: 'dot',
      hash: 'EH1C61DY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EH1C61DY'
    },
    {
      status: 210,
      sku: 'PM0076074',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353290',
      rtl_batch_array: '6353290',
      name_search: 'SECCHERR',
      name: "Sedum 'Chocolate Cherry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJ6Y455J',
      statusContent: 'dot',
      hash: 'JVBCS3RJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JVBCS3RJ'
    },
    {
      status: 810,
      sku: 'PM0077181',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350155',
      rtl_batch_array: '6350155',
      name_search: 'EUMGATEW',
      name: "Eupatorium maculatum 'Gateway'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXV3PTB',
      statusContent: 'dot',
      hash: 'KPB3R19V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPB3R19V'
    },
    {
      status: 810,
      sku: 'PM0076078',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353470',
      rtl_batch_array: '6353470, 6345220',
      name_search: 'STREBAUD',
      name: 'Stevia rebaudiana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 226,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C8A16ZP1;AHRGLWYK;1SGE1P3Z;8G5HV484;GD2PCL62;C5TD25J5;C1RHJK4P;JNGZNXWJ;A6XLCT8D;RN5CZS1D;ZBK6RVKR;5AX6B1BN;VATH4CYX;AWGXJA19;G5B5GVE8',
      statusContent: 'dot',
      hash: 'WA9X3TB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WA9X3TB6'
    },
    {
      status: 210,
      sku: 'PM0076081',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353487',
      rtl_batch_array: '6353487',
      name_search: 'VIMMARIE',
      name: "Vinca minor 'Marie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 251,
      chnn_stock_retail: 251,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HADGKRT7',
      statusContent: 'dot',
      hash: 'RWH28LL8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWH28LL8'
    },
    {
      status: 210,
      sku: 'PM0076082',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353539',
      rtl_batch_array: '6353539',
      name_search: 'VELLASS',
      name: "Verbascum 'Lavender Lass'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C3N22EZ4;7CWEWWS5;GCL8BJYX',
      statusContent: 'dot',
      hash: '8E3WNLX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8E3WNLX7'
    },
    {
      status: 210,
      sku: 'PM0030196',
      core_name: 'Plant',
      sppl_ean: '8720664567474',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364272',
      rtl_batch_array: '5364272',
      name_search: 'KNWBUTTE',
      name: "Kniphofia 'Wrexham Buttercup'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 1.591,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C7ELL68;WNXTTVK7;BC2R46AD',
      statusContent: 'dot',
      hash: 'D1264L8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1264L8L'
    },
    {
      status: 210,
      sku: 'PM0024125',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6177424',
      rtl_batch_array: '6202317, 6177424',
      name_search: 'LISPICAT',
      name: 'Liatris spicata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 5983,
      chnn_stock_retail: 6163,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6SSGZ2RA;V6D684P7;G8261HWL;VYWC8C87;5LSYGEHA;JG9Z26S7;LTPGL7BG;LD1W4CCE;R1XZDTCV;8K11Z9JG;T31Y1L61;W98CBH3A;LTGKKT8W;K6ZT3PDP;XYL945S6',
      statusContent: 'dot',
      hash: 'PH5C754Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PH5C754Z'
    },
    {
      status: 910,
      sku: 'PM0083891',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358831',
      rtl_batch_array: '6358831',
      name_search: 'ACPISHID',
      name: "Acer palmatum 'Inaba-shidare'",
      sppl_size_code: '120STC15',
      rng_range_identifier: 'STE120C15',
      rng_range_description: 'Stem 120 cm C15',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 81.809,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5BLZKXED;NEELBD4H;N6E2LPPZ;DV2SLDSS;1A5D2AHW;TTJ68GYP;4JWD7LKS;8NJ16VC1;R3VJL3TE;69RPA88S;TKBLN8V5;R7XGN3Y7;XG7JZ6TK;APZ72YSX;GY57LJ3A;ETPW31TC;WZXL5ENL;7A89BCH6',
      statusContent: 'dot',
      hash: 'GS85ERT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GS85ERT2'
    },
    {
      status: 910,
      sku: 'PM0083892',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358832',
      rtl_batch_array: '6358832',
      name_search: 'ACPOSAKA',
      name: "Acer palmatum 'Osakazuki'",
      sppl_size_code: '200250C90',
      rng_range_identifier: 'H200250C90',
      rng_range_description: 'H200 cm 250 cm C90',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 302.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SBL2SRVK',
      statusContent: 'dot',
      hash: 'PZ5PHJHT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ5PHJHT'
    },
    {
      status: 910,
      sku: 'PM0083893',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358833',
      rtl_batch_array: '6358833',
      name_search: 'LOCHINEN',
      name: 'Loropetalum chinense',
      sppl_size_code: '140160C30',
      rng_range_identifier: 'H140160C30',
      rng_range_description: 'H140 cm 160 cm C30',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 84.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YYG43ERX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYG43ERX'
    },
    {
      status: 210,
      sku: 'PM0076885',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298760',
      rtl_batch_array: '6298760',
      name_search: 'RHGORANG',
      name: 'Rhododendron (AJ) Geisha Orange',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1104,
      chnn_stock_retail: 1104,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N5DP89LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5DP89LE'
    },
    {
      status: 910,
      sku: 'PM0083894',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358834',
      rtl_batch_array: '6358834',
      name_search: 'LOCBPEAR',
      name: 'Loropetalum chinense Black Pearl',
      sppl_size_code: '080100C20',
      rng_range_identifier: 'H080100C20',
      rng_range_description: 'H80 cm 100 cm C20',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 49.52,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZJKGESP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZJKGESP'
    },
    {
      status: 910,
      sku: 'PM0083895',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358835',
      rtl_batch_array: '6358835',
      name_search: 'NADOMEST',
      name: 'Nandina domestica',
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 14,
      chnn_stock_retail: 14,
      sppl_prcp: 58.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VD8H147C;BLZ2GW1V;CL276JTD;N2EPWKKW;J2S57PCB;47P9JE8W;HE8J2D6E;LCZ9E9SR;VJ7T2E41;XA2GVAD8;XGCGLADH;J2PXJC5V;E81ESH4G;S9BJX1AE;86GSB4H1',
      statusContent: 'dot',
      hash: 'JYH4DV2N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYH4DV2N'
    },
    {
      status: 210,
      sku: 'PM0076886',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298761',
      rtl_batch_array: '6298761',
      name_search: 'RHHISAKO',
      name: "Rhododendron (AJ) 'Hisako'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1375,
      chnn_stock_retail: 1375,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SAPD6C7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SAPD6C7E'
    },
    {
      status: 210,
      sku: 'PM0076887',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298762',
      rtl_batch_array: '6298762',
      name_search: 'RHANNEKE',
      name: "Rhododendron (AK) 'Anneke'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 962,
      chnn_stock_retail: 962,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CPP7LNEA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPP7LNEA'
    },
    {
      status: 210,
      sku: 'PM0076888',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298763',
      rtl_batch_array: '6298763',
      name_search: 'RHBERRYR',
      name: "Rhododendron (AK) 'Berryrose'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 984,
      chnn_stock_retail: 984,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PZ4CVA8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PZ4CVA8Z'
    },
    {
      status: 210,
      sku: 'PM0076889',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298764',
      rtl_batch_array: '6298764',
      name_search: 'RHGIBRAL',
      name: "Rhododendron (AK) 'Gibraltar'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 996,
      chnn_stock_retail: 996,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWPD269G;S12YYBNL;JB171LJ5;2B8ZSTT3;CDD2HPAE;922A68LL;XBESZ1K5',
      statusContent: 'dot',
      hash: 'NS667S54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NS667S54'
    },
    {
      status: 210,
      sku: 'PM0076890',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298765',
      rtl_batch_array: '6298765',
      name_search: 'RHGEAGLE',
      name: "Rhododendron (AK) 'Golden Eagle'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 976,
      chnn_stock_retail: 976,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S42B8J3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S42B8J3K'
    },
    {
      status: 210,
      sku: 'PM0076891',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298766',
      rtl_batch_array: '6298766',
      name_search: 'RHJMADAM',
      name: "Rhododendron (AV) 'Jolie Madame'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R5Y6N1A4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5Y6N1A4'
    },
    {
      status: 210,
      sku: 'PM0076892',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298767',
      rtl_batch_array: '6298767',
      name_search: 'RHNABUCC',
      name: "Rhododendron 'Nabucco'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 971,
      chnn_stock_retail: 971,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9HCVSLJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HCVSLJ7'
    },
    {
      status: 210,
      sku: 'PM0076893',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298768',
      rtl_batch_array: '6298768',
      name_search: 'RHPERSIL',
      name: "Rhododendron (AK) 'Persil'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 999,
      chnn_stock_retail: 999,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7E4Y7X7W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E4Y7X7W'
    },
    {
      status: 210,
      sku: 'PM0076894',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298769',
      rtl_batch_array: '6298769',
      name_search: 'RHSATAN',
      name: "Rhododendron (AK) 'Satan'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 993,
      chnn_stock_retail: 993,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GKJZ23BP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GKJZ23BP'
    },
    {
      status: 210,
      sku: 'PM0076895',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298770',
      rtl_batch_array: '6298770',
      name_search: 'RHVISCOS',
      name: 'Rhododendron viscosum',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 981,
      chnn_stock_retail: 981,
      sppl_prcp: 7.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YAH6WY84',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAH6WY84'
    },
    {
      status: 210,
      sku: 'PM0076896',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298771',
      rtl_batch_array: '6298771',
      name_search: 'RHAFIRE',
      name: 'Rhododendron Autumn Fire',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 904,
      chnn_stock_retail: 904,
      sppl_prcp: 5.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '766TTPZ5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '766TTPZ5'
    },
    {
      status: 210,
      sku: 'PM0076897',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298773',
      rtl_batch_array: '6298773',
      name_search: 'RHESUNBU',
      name: 'Rhododendron Encore Sunburst',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 945,
      chnn_stock_retail: 945,
      sppl_prcp: 5.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KDXW1K9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDXW1K9Z'
    },
    {
      status: 210,
      sku: 'PM0076899',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298775',
      rtl_batch_array: '6298775',
      name_search: 'ROOFFICI',
      name: 'Rosmarinus officinalis',
      sppl_size_code: 'LEVC24',
      rng_range_identifier: 'C2.5',
      rng_range_description: 'C2.5',
      sppl_stock_available: 238,
      chnn_stock_retail: 238,
      sppl_prcp: 2.975,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H2JEWKW4;S5B97HHG;6X7YXKBB;SNZW41YY;6BSHZXX7;BLVY61E3;WPGDWS8X;GXYB8CVE;5TV6XK6H;SXXKGR1P;2NA182LS;2XEPGE4Z;CYBHJXK6;PYY41WEA;GC72X7G5;LYRCLZYW;TPZSPS9T;TGBS3NXB;TYJEEAYD;Z2EHWD23;V7LXZABL;PDXNS8N1',
      statusContent: 'dot',
      hash: 'J3V7YXJ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3V7YXJ3'
    },
    {
      status: 210,
      sku: 'PM0076900',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298777',
      rtl_batch_array: '6298777',
      name_search: 'RUIGROOV',
      name: 'Rubus idaeus Groovy',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 381,
      chnn_stock_retail: 381,
      sppl_prcp: 4.283,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7PP21HZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7PP21HZ'
    },
    {
      status: 210,
      sku: 'PM0076901',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298780',
      rtl_batch_array: '6298780',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 882,
      chnn_stock_retail: 882,
      sppl_prcp: 2.133,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: '6SDCG6TY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6SDCG6TY'
    },
    {
      status: 210,
      sku: 'PM0076902',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298781',
      rtl_batch_array: '6298781',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      sppl_size_code: '090STC4',
      rng_range_identifier: 'STE090C4',
      rng_range_description: 'Stem 90 cm C4',
      sppl_stock_available: 793,
      chnn_stock_retail: 793,
      sppl_prcp: 6.961,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'YG3V6PAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YG3V6PAP'
    },
    {
      status: 210,
      sku: 'PM0076905',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298784',
      rtl_batch_array: '6298784',
      name_search: 'SANBLACE',
      name: 'Sambucus nigra Black Lace',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 968,
      chnn_stock_retail: 968,
      sppl_prcp: 7.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TEP2RB5Z;Y1G5NPSK;41YCADE7;JY5X93Y4;9KGEL458;DG2N86TA;J2EH8GV9;TN6VC2KP;T57AVA8C;K668E8N5;2GNJ5G9W;NWR71T8N',
      statusContent: 'dot',
      hash: 'LCH6YYY9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCH6YYY9'
    },
    {
      status: 210,
      sku: 'PM0076907',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298786',
      rtl_batch_array: '6298786',
      name_search: 'SANBTOWE',
      name: 'Sambucus nigra Black Tower',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 995,
      chnn_stock_retail: 995,
      sppl_prcp: 7.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '443DVLWA;CYXXJ6E8;KV2A5HYK;BCV14524;D5EY9XN9;G34YXXSK;D6398STY;HL5TN4T1;1B1446B7;1B5DH2ZJ;TYG49Z6A;XGVKGY5V;ZPZYXXCE',
      statusContent: 'dot',
      hash: '7ALHR1RL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ALHR1RL'
    },
    {
      status: 210,
      sku: 'PM0076908',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298787',
      rtl_batch_array: '6298787',
      name_search: 'SANGSPAR',
      name: 'Sambucus nigra Gold Spark',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1141,
      chnn_stock_retail: 1141,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R28LV6AK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R28LV6AK'
    },
    {
      status: 210,
      sku: 'PM0076086',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284471',
      rtl_batch_array: '6254774, 6350754, 6284471',
      name_search: 'MASTRUTH',
      name: 'Matteuccia struthiopteris',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3074,
      chnn_stock_retail: 6739,
      sppl_prcp: 2.021,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LNBPG4PW;2YDLJK72;4AXSR1EV;NRAB97B4;4Y7E3917;NP1AV2VL;PYNGXRDE;A8K2X6VB;DG7HANSW;VZLHZE9P;EA9PL3NP;41SSC3BN;B7ABY16C;78B411KK;6BD1HB9H;SJN3EC3P',
      statusContent: 'dot',
      hash: '5HLRNBZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HLRNBZ1'
    },
    {
      status: 210,
      sku: 'PM0077175',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349938',
      rtl_batch_array: '6349938',
      name_search: 'ACACOMPA',
      name: "Actaea acerina 'Compacta'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_order_minimum: 3,
      sppl_prcp: 2.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2TWYN6NC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2TWYN6NC'
    },
    {
      status: 210,
      sku: 'PM0077176',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349955',
      rtl_batch_array: '6349955',
      name_search: 'CORFROST',
      name: "Coreopsis 'Ruby Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 222,
      chnn_stock_retail: 222,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JJY9CH8D;NT318YC7;GC7NKNKJ;86P9Z7Y4;XV1TAYYD;JZESX6K8;BA3LZBW5;AEHH8P6W;XTVKY4GK;JHKVLXKH',
      statusContent: 'dot',
      hash: 'BB5E31Z7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BB5E31Z7'
    },
    {
      status: 910,
      sku: 'PM0083896',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'NYSYLVAT',
      name: 'Nyssa sylvatica',
      rng_range_identifier: 'SHI014016C70',
      rng_range_description: 'High stem 14 cm 16 cm C70',
      statusContent: 'dot',
      hash: 'BYY9K9HD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BYY9K9HD'
    },
    {
      status: 210,
      sku: 'PM0077183',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350026',
      rtl_batch_array: '6350026, 6320856',
      name_search: 'ECPAVALA',
      name: "Echinacea purpurea 'Avalanche'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 589,
      chnn_stock_retail: 691,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EH2G7TTY;GZ63HKN7',
      statusContent: 'dot',
      hash: '8H1LTWJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8H1LTWJD'
    },
    {
      status: 210,
      sku: 'PM0077185',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350196',
      rtl_batch_array: '6350196',
      name_search: 'GECALICE',
      name: "Geranium cinereum 'Alice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 167,
      chnn_stock_retail: 167,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZE6G4YV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZE6G4YV'
    },
    {
      status: 810,
      sku: 'PM0083897',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358839',
      rtl_batch_array: '6358839',
      name_search: 'PITOBIRA',
      name: 'Pittosporum tobira',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 22,
      chnn_stock_retail: 22,
      sppl_prcp: 17.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RX46XZBE;N2VG2K6V;JLZ4LRDH;STV8GSZL;NXXBGE33;PNGWBP6D;AZE1N32Z;EBNW7JGD',
      statusContent: 'dot',
      hash: '78YCW267',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78YCW267'
    },
    {
      status: 210,
      sku: 'PM0083898',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358841',
      rtl_batch_array: '6358841',
      name_search: 'PRLETNA',
      name: 'Prunus laurocerasus Etna',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 208,
      chnn_stock_retail: 208,
      sppl_prcp: 10.5,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W6PLB4HT;R779BYWJ;CRWYK4AB;Z6GVTCPN;5ZY4DVTP;K1WGXE83;CBNKXGRJ;BL28CPVH',
      statusContent: 'dot',
      hash: 'TT4863HN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TT4863HN'
    },
    {
      status: 210,
      sku: 'PM0077177',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350087',
      rtl_batch_array: '6350087',
      name_search: 'ECSCREAM',
      name: "Echinacea 'Strawberry and Cream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.947,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NCAB49Y8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NCAB49Y8'
    },
    {
      status: 210,
      sku: 'PM0077179',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350118',
      rtl_batch_array: '6350118',
      name_search: 'EPALABAS',
      name: "Epimedium 'Alabaster'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '18L6XS4P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '18L6XS4P'
    },
    {
      status: 210,
      sku: 'PM0077180',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350124',
      rtl_batch_array: '6350124',
      name_search: 'EPRGALAD',
      name: "Epimedium rubrum 'Galadriel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 744,
      chnn_stock_retail: 744,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A36LDHDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A36LDHDW'
    },
    {
      status: 910,
      sku: 'PM0077184',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GESLAGOD',
      name: 'Gentiana septemfida lagodechiana',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'LN3T2WWX',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'LN3T2WWX'
    },
    {
      status: 910,
      sku: 'PM0083899',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358849',
      rtl_batch_array: '6358849',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '080STC20',
      rng_range_identifier: 'STE080C20',
      rng_range_description: 'Stem 80 cm C20',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 51.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: 'J7KC962C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7KC962C'
    },
    {
      status: 910,
      sku: 'PM0083900',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358850',
      rtl_batch_array: '6358850',
      name_search: 'PRULANGU',
      name: "Prunus lusitanica 'Angustifolia'",
      sppl_size_code: '100125C70',
      rng_range_identifier: 'H100125C70',
      rng_range_description: 'H100 cm 125 cm C70',
      sppl_stock_available: 3,
      chnn_stock_retail: 3,
      sppl_prcp: 221.76,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NCGL1Y2H;WJADRAHY;NDZK6KRC;19VRA1HC;HHEGGSEL;LCXZ8YX7;VR8RZ51V;11HPCWGC;CZPG7KDN;E9DB8JV1;66GXHD5N;BKR1RPD3',
      statusContent: 'dot',
      hash: '637XB447',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '637XB447'
    },
    {
      status: 210,
      sku: 'PM0076075',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353316',
      rtl_batch_array: '6353316',
      name_search: 'SERSTAR',
      name: "Sedum 'Red Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KEWWECAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEWWECAE'
    },
    {
      status: 210,
      sku: 'PM0076077',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353460',
      rtl_batch_array: '6353460',
      name_search: 'SPAJDELI',
      name: 'Sporobolus airoides JS Delicatesse',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NWJVSVNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWJVSVNJ'
    },
    {
      status: 210,
      sku: 'PM0076079',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348996',
      rtl_batch_array: '6353382, 6348996',
      name_search: 'THDSPLEN',
      name: "Thalictrum del. 'Splendide'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 894,
      chnn_stock_retail: 1854,
      sppl_order_minimum: 3,
      sppl_prcp: 1.69,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1SNY345B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SNY345B'
    },
    {
      status: 210,
      sku: 'PM0076083',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353542',
      rtl_batch_array: '6353542',
      name_search: 'VEWDOMIN',
      name: "Verbascum 'White Domino'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BTYZPLW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BTYZPLW1'
    },
    {
      status: 210,
      sku: 'PM0076084',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353545',
      rtl_batch_array: '6353545, 6352674',
      name_search: 'VECRINIT',
      name: 'Vernonia crinita',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 219,
      chnn_stock_retail: 622,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C23VBEAR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C23VBEAR'
    },
    {
      status: 910,
      sku: 'PM0083901',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358851',
      rtl_batch_array: '6358851',
      name_search: 'ACEPSGOL',
      name: "Acer palmatum 'Summer Gold'",
      sppl_size_code: '100120C15',
      rng_range_identifier: 'H100120C15',
      rng_range_description: 'H100 cm 120 cm C15',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 59.659,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AJRX1T2W',
      statusContent: 'dot',
      hash: 'GZKWBRC1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZKWBRC1'
    },
    {
      status: 910,
      sku: 'PM0083902',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358852',
      rtl_batch_array: '6358852',
      name_search: 'ACPTAMUK',
      name: "Acer palmatum 'Tamukeyama'",
      sppl_size_code: '100120C15',
      rng_range_identifier: 'H100120C15',
      rng_range_description: 'H100 cm 120 cm C15',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 75.499,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '23XHTCHD;8W5C7WBK;NS37HCPP',
      statusContent: 'dot',
      hash: 'G2GBPAGS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2GBPAGS'
    },
    {
      status: 910,
      sku: 'PM0083903',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358853',
      rtl_batch_array: '6358853',
      name_search: 'ACPTAMUK',
      name: "Acer palmatum 'Tamukeyama'",
      sppl_size_code: 'HAC30',
      rng_range_identifier: 'SHAC30',
      rng_range_description: 'Half stem C30',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 163.68,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '23XHTCHD;8W5C7WBK;NS37HCPP',
      statusContent: 'dot',
      hash: 'RBPRY2RH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBPRY2RH'
    },
    {
      status: 910,
      sku: 'PM0083904',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACROGLOR',
      name: "Acer rubrum 'October Glory'",
      rng_range_identifier: 'SHI006008C20',
      rng_range_description: 'High stem 6 cm 8 cm C20',
      imageCore: 'ECZK7X9D',
      statusContent: 'dot',
      hash: '4GZ1Z2YW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4GZ1Z2YW'
    },
    {
      status: 910,
      sku: 'PM0083905',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ALJSCHOC',
      name: "Albizia julibrissin 'Summer Chocolate'",
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore: '81KLXWEX;8R6LRWVR;2YBT1KW5;XLB7TLLD;K3ZYB766;Z4A9VVJ3',
      statusContent: 'dot',
      hash: '8Y9C9XVV',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8Y9C9XVV'
    },
    {
      status: 910,
      sku: 'PM0083906',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'CABETULU',
      name: 'Carpinus betulus',
      rng_range_identifier: 'SHI008010C18',
      rng_range_description: 'High stem 8 cm 10 cm C18',
      imageCore:
        '7E9KEGSP;R63WZKCZ;Y42CSYGR;492EAA9S;CT9XNP2E;N85BS82W;CEY4DJ4H;VRC22YBA;XR73WN6T;5TP442NL;B8AV19X3;GW6TRJ5B;Z9HXG72P;2E5X7RHD;2ZADR3E9;4L44BS5T;21WJPTG8;R9KZ5SHC',
      statusContent: 'dot',
      hash: 'R8HGKZ82',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'R8HGKZ82'
    },
    {
      status: 910,
      sku: 'PM0083907',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358857',
      rtl_batch_array: '6358857',
      name_search: 'CABIGNON',
      name: 'Catalpa bignonioides',
      sppl_size_code: '200250C45MS',
      rng_range_identifier: 'H200250C45',
      rng_range_description: 'H200 cm 250 cm C45',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 96.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7J6AE2PV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7J6AE2PV'
    },
    {
      status: 910,
      sku: 'PM0083908',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358858',
      rtl_batch_array: '6358858',
      name_search: 'CABNANA',
      name: "Catalpa bignonioides 'Nana'",
      sppl_size_code: '090120HAC18',
      rng_range_identifier: 'H090120SHAC18',
      rng_range_description: 'H90 cm 120 cm Half stem C18',
      sppl_stock_available: 5,
      chnn_stock_retail: 5,
      sppl_prcp: 46.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H5G8BR8P;GJRSZKPS;13TD15T9;PCZ1WLP1;KVZSKZ1Z;L6G4YSW3;TL57EL45',
      statusContent: 'dot',
      hash: 'XS6TLPZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS6TLPZT'
    },
    {
      status: 810,
      sku: 'PM0083909',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358859',
      rtl_batch_array: '6358859',
      name_search: 'CLEMGRAN',
      name: 'Clematis montana grandiflora',
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 32.084,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZLV5DGE;ZLNSJBK3;P24P7D41;853J6TVR;SD2APLBW;7EW5TT7W;6S3SRHL5;HWJH3XH5;E5GDPDKP;Z64T2K5J;D7BL4YEB;W5LT3RLW;453BNKST;PE4Y2V2R;G346D7NA;RCVTTXX3;HGHGXHVP;S482G2LY;S715HA8J;L7HHS4ER;RHHPLD5A',
      statusContent: 'dot',
      hash: 'A4JCTX8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4JCTX8C'
    },
    {
      status: 910,
      sku: 'PM0083910',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358860',
      rtl_batch_array: '6358860',
      name_search: 'CLMRUBEN',
      name: 'Clematis montana rubens',
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 32.084,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '653ELA66;EZRBR1C5;W2DN157C;BRD5WXZB;KL6DCDYP;YWG2DA1G;LECN6WJV;DYYJ2CKK;NYJX7SLJ;GZN2P7EL;YNS3XDXT;6PWPLRYJ;9TGGSAX3',
      statusContent: 'dot',
      hash: '682E8Y9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '682E8Y9L'
    },
    {
      status: 210,
      sku: 'PM0084365',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378380',
      rtl_batch_array: '6378380',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '030STC14',
      rng_range_identifier: 'STE030C1.5',
      rng_range_description: 'Stem 30 cm C1.5',
      sppl_stock_available: 1399,
      chnn_stock_retail: 1399,
      sppl_order_minimum: 3,
      sppl_prcp: 3.86,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: 'CCCVKK72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCCVKK72'
    },
    {
      status: 210,
      sku: 'PM0084366',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378381',
      rtl_batch_array: '6378381',
      name_search: 'SACKILMA',
      name: "Salix caprea 'Kilmarnock'",
      sppl_size_code: '040STC2',
      rng_range_identifier: 'STE040C2',
      rng_range_description: 'Stem 40 cm C2',
      sppl_stock_available: 527,
      chnn_stock_retail: 527,
      sppl_prcp: 4.32,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7YZ2YZYV;ACLKRXH8;LCYPCSKC;2XPNVCTR;9VVNTRHL;8HEBHA3B;11J8XG9C;5KBZLT5Y;EWN9515Y;P5ALNZYA;DTBNXH3C;9T2K4NHD;RNVRPH4R;CL774BPE;JLTXTG81;YN9SJHPN;E3S31WLZ;K4J44TG8;WWP6696D;XLV74SY6;2XBY289D;RYBB9LXY;ANZ2RK2C;S2T4D8WD;7VJVVBNZ',
      statusContent: 'dot',
      hash: '6ZLX9VJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZLX9VJA'
    },
    {
      status: 210,
      sku: 'PM0084367',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378383',
      rtl_batch_array: '6378383',
      name_search: 'SYFPINK',
      name: "Syringa 'Flowerfest Pink'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3620,
      chnn_stock_retail: 3620,
      sppl_prcp: 5.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6Z3KN7R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6Z3KN7R'
    },
    {
      status: 210,
      sku: 'PM0084368',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378384',
      rtl_batch_array: '6378384',
      name_search: 'SYFPINK',
      name: "Syringa 'Flowerfest Pink'",
      sppl_size_code: '060STC3',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      sppl_stock_available: 395,
      chnn_stock_retail: 395,
      sppl_prcp: 7.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '415WWSYE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '415WWSYE'
    },
    {
      status: 210,
      sku: 'PM0084369',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378385',
      rtl_batch_array: '6378385',
      name_search: 'SYFWHITE',
      name: "Syringa 'Flowerfest White'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 1408,
      chnn_stock_retail: 1408,
      sppl_prcp: 5.246,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DKJ8TH67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKJ8TH67'
    },
    {
      status: 210,
      sku: 'PM0084370',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378386',
      rtl_batch_array: '6378386',
      name_search: 'SYFWHITE',
      name: "Syringa 'Flowerfest White'",
      sppl_size_code: '060STC3',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      sppl_stock_available: 575,
      chnn_stock_retail: 575,
      sppl_prcp: 7.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T7EDSPV4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T7EDSPV4'
    },
    {
      status: 210,
      sku: 'PM0084371',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378387',
      rtl_batch_array: '6378387',
      name_search: 'SYFWHITE',
      name: "Syringa 'Flowerfest White'",
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 528,
      chnn_stock_retail: 528,
      sppl_prcp: 9.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T8PBVGBA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T8PBVGBA'
    },
    {
      status: 210,
      sku: 'PM0084372',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378388',
      rtl_batch_array: '6378388',
      name_search: 'SYFWHITE',
      name: "Syringa 'Flowerfest White'",
      sppl_size_code: '100STBW',
      rng_range_identifier: 'STE100BR',
      rng_range_description: 'Stem 100 dm Bare root',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 14.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W68G7BND',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W68G7BND'
    },
    {
      status: 810,
      sku: 'PM0084373',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378389',
      rtl_batch_array: '6378389',
      name_search: 'SYFWHITE',
      name: "Syringa 'Flowerfest White'",
      sppl_size_code: '100STC5',
      rng_range_identifier: 'STE100C5',
      rng_range_description: 'Stem 100 cm C5',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_prcp: 14.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KLPW4EKT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLPW4EKT'
    },
    {
      status: 210,
      sku: 'PM0084374',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378390',
      rtl_batch_array: '6378390',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 3473,
      chnn_stock_retail: 3473,
      sppl_prcp: 5.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: 'ECES4ALC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ECES4ALC'
    },
    {
      status: 810,
      sku: 'PM0084375',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378391',
      rtl_batch_array: '6378391',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 6.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: 'SDEKBAN1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDEKBAN1'
    },
    {
      status: 210,
      sku: 'PM0084376',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378392',
      rtl_batch_array: '6378392',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '060STC3',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      sppl_stock_available: 1090,
      chnn_stock_retail: 1090,
      sppl_prcp: 7.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: 'VZ7XC341',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VZ7XC341'
    },
    {
      status: 210,
      sku: 'PM0084377',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378393',
      rtl_batch_array: '6378393',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 1168,
      chnn_stock_retail: 1168,
      sppl_prcp: 9.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: '11ZYDA8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '11ZYDA8B'
    },
    {
      status: 210,
      sku: 'PM0084378',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378394',
      rtl_batch_array: '6378394',
      name_search: 'SYDPURPL',
      name: 'Syringa Dark Purple',
      sppl_size_code: '100STBW',
      rng_range_identifier: 'STE100BR',
      rng_range_description: 'Stem 100 dm Bare root',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 14.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C6ZHA33Z;5D9HTH6S;TJW8TXT5;XVVB8S1G;EJHPEP3A;G5TZE8CN',
      statusContent: 'dot',
      hash: 'YLWCR51N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YLWCR51N'
    },
    {
      status: 210,
      sku: 'PM0084379',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378395',
      rtl_batch_array: '6378395',
      name_search: 'SYJOSIKA',
      name: 'Syringa josikaea',
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 19555,
      chnn_stock_retail: 19555,
      sppl_prcp: 5.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BKJ1EXDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BKJ1EXDR'
    },
    {
      status: 210,
      sku: 'PM0084380',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378396',
      rtl_batch_array: '6378396',
      name_search: 'SYMSUPER',
      name: "Syringa microphylla 'Superba'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 2340,
      chnn_stock_retail: 2340,
      sppl_prcp: 4.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BYPSTHBJ;G8HEG9GH;N46T95NY;AK1BHEA9;ZEHL1HSD;TE21TSYC;WE7HDYLD;H9J9SC9W;ZBRVCBAW',
      statusContent: 'dot',
      hash: 'LSPYKALA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LSPYKALA'
    },
    {
      status: 910,
      sku: 'PM0084381',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYPPERFU',
      name: "Syringa 'Pink Perfume'",
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      statusContent: 'dot',
      hash: 'D74HB25S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'D74HB25S'
    },
    {
      status: 810,
      sku: 'PM0084382',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378397',
      rtl_batch_array: '6378397',
      name_search: 'SYPPERFU',
      name: "Syringa 'Pink Perfume'",
      sppl_size_code: '040050C5',
      rng_range_identifier: 'H040050C5',
      rng_range_description: 'H40 cm 50 cm C5',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_prcp: 6.92,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TTWCSE47',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TTWCSE47'
    },
    {
      status: 210,
      sku: 'PM0084383',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378398',
      rtl_batch_array: '6378398',
      name_search: 'SYPPERFU',
      name: "Syringa 'Pink Perfume'",
      sppl_size_code: '060STC3',
      rng_range_identifier: 'STE060C3',
      rng_range_description: 'Stem 60 cm C3',
      sppl_stock_available: 717,
      chnn_stock_retail: 717,
      sppl_prcp: 7.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B1P62K7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B1P62K7G'
    },
    {
      status: 210,
      sku: 'PM0084384',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378399',
      rtl_batch_array: '6378399',
      name_search: 'SYPPERFU',
      name: "Syringa 'Pink Perfume'",
      sppl_size_code: '080STC5',
      rng_range_identifier: 'STE080C5',
      rng_range_description: 'Stem 80 cm C5',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 9.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X74L9355',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X74L9355'
    },
    {
      status: 210,
      sku: 'PM0084385',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378400',
      rtl_batch_array: '6378400',
      name_search: 'SYPPERFU',
      name: "Syringa 'Pink Perfume'",
      sppl_size_code: '100STBW',
      rng_range_identifier: 'STE100BR',
      rng_range_description: 'Stem 100 dm Bare root',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 12.38,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7CJ7ZTKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CJ7ZTKA'
    },
    {
      status: 810,
      sku: 'PM0084386',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378401',
      rtl_batch_array: '6378401',
      name_search: 'SYPPERFU',
      name: "Syringa 'Pink Perfume'",
      sppl_size_code: '100STC5',
      rng_range_identifier: 'STE100C5',
      rng_range_description: 'Stem 100 cm C5',
      sppl_stock_available: 28,
      chnn_stock_retail: 28,
      sppl_prcp: 14.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PHBJ3WTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PHBJ3WTP'
    },
    {
      status: 210,
      sku: 'PM0084387',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378402',
      rtl_batch_array: '6378402',
      name_search: 'VAVIFIRE',
      name: 'Vaccinium vitis-idaea Fireballs',
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 3286,
      chnn_stock_retail: 3286,
      sppl_order_minimum: 3,
      sppl_prcp: 3.08,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G13CT6TW;Y3NL3NLK;GXGDY6ER;KSCSCWL2;REH5SCCW;19EREVBK;Z6K289TP;4ZDTLDSN;PJH32HBK;DK5SLYWD;SS5ZHJKH;6YEDXS5K;Z6NBYJXL',
      statusContent: 'dot',
      hash: 'GBGXY1BW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GBGXY1BW'
    },
    {
      status: 210,
      sku: 'PM0084388',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378404',
      rtl_batch_array: '6378404',
      name_search: 'VAVIMCHE',
      name: 'Vaccinium vitis-idaea Miss Cherry',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 3910,
      chnn_stock_retail: 3910,
      sppl_prcp: 3.71,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C5NBADTZ;4HAE79RV;TSV3S85J;N3SJTX3L;W2Y12E43;JT9W3LDH;V6APPHX1;PGJDAPLV;28LT54G1;PDXKPVAK;GV4ZJEG3;Y5GT11E8;146E4RJZ;XK1KSAGN;JV5R576T',
      statusContent: 'dot',
      hash: 'WPAK8ELL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WPAK8ELL'
    },
    {
      status: 910,
      sku: 'PM0084389',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: 'H7XAKLVA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'H7XAKLVA'
    },
    {
      status: 910,
      sku: 'PM0084390',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378405',
      rtl_batch_array: '6378405',
      name_search: 'VIBCLAMO',
      name: "Viburnum bodnantense 'Charles Lamont'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 7.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YJXEH3PR;ZNDDHS3K;YYTH562C',
      statusContent: 'dot',
      hash: 'ERPJWC39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ERPJWC39'
    },
    {
      status: 910,
      sku: 'PM0084391',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: 'EH881ZHD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EH881ZHD'
    },
    {
      status: 210,
      sku: 'PM0084392',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378408',
      rtl_batch_array: '6378408',
      name_search: 'VIPKILIM',
      name: 'Viburnum plicatum Kilimandjaro',
      sppl_size_code: '100125KL',
      rng_range_identifier: 'H100125RB',
      rng_range_description: 'H100 cm 125 cm Root ball',
      sppl_stock_available: 275,
      chnn_stock_retail: 275,
      sppl_prcp: 18.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'E9S44CDT;TT48Z26Z;4SSRT37W;A4E1GC72;X8KZ4REW;XB3BC4J8;DBCAZZKV;TC2Z5Y9C;ACS8RRJR;45LE5RSD',
      statusContent: 'dot',
      hash: '2RX4AAZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RX4AAZG'
    },
    {
      status: 210,
      sku: 'PM0076095',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 5,
      btch_active_retail: '6291309',
      rtl_batch_array: '6295177, 6284493, 6310448, 6291309, 6336329',
      name_search: 'VIMINOR',
      name: 'Vinca minor',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 32142,
      sppl_order_minimum: 3,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EG281Z1T;7PHP8SX2;A9ZBL78N;VZ4C57B6;YEEWSG3H;YZL8SB43;VWHK82DS;AG2GCWHL;HXBZK6K5;D5EP2G8A;41VVEG6A;2X2V95LJ;BZCBAD26;N6895BNJ;HKDSTCGY;ABE1R6VC',
      statusContent: 'dot',
      hash: 'RARTTTGA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RARTTTGA'
    },
    {
      status: 210,
      sku: 'PM0077186',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350310',
      rtl_batch_array: '6350310',
      name_search: 'HEAGVARI',
      name: "Helianthus atrorubens 'Gullick's Variety'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z1274HST;JPYTP9YZ',
      statusContent: 'dot',
      hash: 'GT3D31GJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GT3D31GJ'
    },
    {
      status: 210,
      sku: 'PM0077188',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350315',
      rtl_batch_array: '6350315, 6319708',
      name_search: 'HEDSOR',
      name: "Helianthus decapetalus 'Soleil d'Or'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 117,
      chnn_stock_retail: 195,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D2LER4D4;4HG9D6HJ',
      statusContent: 'dot',
      hash: 'WCGZGNSP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCGZGNSP'
    },
    {
      status: 210,
      sku: 'PM0077190',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350327',
      rtl_batch_array: '6350327, 6302570',
      name_search: 'HEHBHEAR',
      name: "Heliopsis helianthoides 'Burning Hearts'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 191,
      chnn_stock_retail: 363,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B75CC5Y1',
      statusContent: 'dot',
      hash: 'EAEPLXPR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EAEPLXPR'
    },
    {
      status: 810,
      sku: 'PM0077193',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350260',
      rtl_batch_array: '6350260',
      name_search: 'GECOSMOP',
      name: "Geum 'Cosmopolitan'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4LBTJZ3R;VGDL7DCD;1DYV15S1;84STZ5HN;PZSWEWVJ;2PCCJ3AJ',
      statusContent: 'dot',
      hash: 'D11TXWCR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D11TXWCR'
    },
    {
      status: 810,
      sku: 'PM0076911',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298792',
      rtl_batch_array: '6298792',
      name_search: 'SYMPALIB',
      name: "Syringa meyeri 'Palibin'",
      sppl_size_code: '090STC74',
      rng_range_identifier: 'STE090C7.5',
      rng_range_description: 'Stem 90 cm C7.5',
      sppl_stock_available: 37,
      chnn_stock_retail: 37,
      sppl_prcp: 25.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BD8TZ2X2;PY1XTHK9;X71P7SG6;VKY1N7AC;XRTW1K57;KNT196TJ;PH5DL7LY;557WP9BJ;K47X16GG;7WPJRNCZ;9L9THN1H',
      statusContent: 'dot',
      hash: 'C7195B1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7195B1X'
    },
    {
      status: 910,
      sku: 'PM0076087',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'FAANGUST',
      name: 'Fargesia angustissima',
      rng_range_identifier: 'H125150C50',
      rng_range_description: 'H125 cm 150 cm C50',
      imageCore:
        '5EH39HPH;755PBDC2;D2GTSX7B;HYWHX3BJ;RSDG33D7;YEH6Z3VK;E667CP7C;EHYCEGAW',
      statusContent: 'dot',
      hash: 'ZKHNDX2L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZKHNDX2L'
    },
    {
      status: 210,
      sku: 'PM0077194',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350281',
      rtl_batch_array: '6350281',
      name_search: 'HECHELSE',
      name: "Helenium 'Chelsey'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8RWEHN2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RWEHN2H'
    },
    {
      status: 210,
      sku: 'PM0077189',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350316',
      rtl_batch_array: '6350316',
      name_search: 'HEDSDAYD',
      name: "Helianthus decapetalus 'Sunshine Daydream'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TX4T69XP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TX4T69XP'
    },
    {
      status: 210,
      sku: 'PM0077191',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350345',
      rtl_batch_array: '6350345',
      name_search: 'HECKNOBB',
      name: "Hemerocallis 'Chicago Knobby'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 432,
      chnn_stock_retail: 432,
      sppl_order_minimum: 3,
      sppl_prcp: 1.795,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '19RED29G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19RED29G'
    },
    {
      status: 210,
      sku: 'PM0076090',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284479',
      rtl_batch_array: '6284479',
      name_search: 'CACKBLUE',
      name: "Caryopteris clandonensis 'Kew Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1221,
      chnn_stock_retail: 1221,
      sppl_order_minimum: 3,
      sppl_prcp: 1.113,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X2PCYA2A;JRHPKVBA',
      statusContent: 'dot',
      hash: 'TL7RHE17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TL7RHE17'
    },
    {
      status: 210,
      sku: 'PM0076091',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284483',
      rtl_batch_array: '6284483',
      name_search: 'CYSCOPAR',
      name: 'Cytisus scoparius',
      sppl_size_code: '020030C14',
      rng_range_identifier: 'H020030C1.5',
      rng_range_description: 'H20 cm 30 cm C1.5',
      sppl_stock_available: 831,
      chnn_stock_retail: 831,
      sppl_order_minimum: 3,
      sppl_prcp: 1.113,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PB1BT1A9',
      statusContent: 'dot',
      hash: 'AYZCY6Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AYZCY6Z6'
    },
    {
      status: 910,
      sku: 'PM0083911',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358862',
      rtl_batch_array: '6358862',
      name_search: 'COCVARIE',
      name: "Cornus controversa 'Variegata'",
      sppl_size_code: '150175C30',
      rng_range_identifier: 'H150175C30',
      rng_range_description: 'H150 cm 175 cm C30',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 128.2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WCRHXAAP;VEK1D3NC;6WDTDNED;WGHLZ3D2;KL8RWG2A;WW5D6NVX;2C2XNTYL;N83SHYJJ;81Z2ZVRA;X5561DV4;2C6BAK81;71NWG3EX;4JSCJXVC;7ZBKNZ5Y',
      statusContent: 'dot',
      hash: 'DBJ5X943',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DBJ5X943'
    },
    {
      status: 210,
      sku: 'PM0077196',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350587',
      rtl_batch_array: '6350587',
      name_search: 'ISRUBESC',
      name: 'Isodon rubescens',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S25GPXN5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S25GPXN5'
    },
    {
      status: 210,
      sku: 'PM0076092',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284484',
      rtl_batch_array: '6284484',
      name_search: 'CYSCOPAR',
      name: 'Cytisus scoparius',
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_prcp: 1.57,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PB1BT1A9',
      statusContent: 'dot',
      hash: 'DSRZG557',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DSRZG557'
    },
    {
      status: 810,
      sku: 'PM0083912',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358863',
      rtl_batch_array: '6358863',
      name_search: 'COFRUBRA',
      name: 'Cornus florida rubra',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_prcp: 39.72,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H17Z6824',
      statusContent: 'dot',
      hash: '7LWJZA54',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7LWJZA54'
    },
    {
      status: 210,
      sku: 'PM0076094',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284491',
      rtl_batch_array: '6284491',
      name_search: 'ULEUROPA',
      name: 'Ulex europaeus',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2740,
      chnn_stock_retail: 2740,
      sppl_prcp: 1.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXYT7A4W',
      statusContent: 'dot',
      hash: 'PJR2YYJ7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJR2YYJ7'
    },
    {
      status: 810,
      sku: 'PM0076096',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284496',
      rtl_batch_array: '6284496',
      name_search: 'RHVISCOS',
      name: 'Rhododendron viscosum',
      sppl_size_code: '040060C4',
      rng_range_identifier: 'H040060C4',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7XBLHVGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7XBLHVGY'
    },
    {
      status: 210,
      sku: 'PM0076097',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284497',
      rtl_batch_array: '6284497',
      name_search: 'RHAFRANK',
      name: "Rhododendron (AJ) 'Anne Frank'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 482,
      chnn_stock_retail: 482,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NZ2563WD;WC66NCS1;8K51NLEN;78ABCJ3H;TAD68SRA',
      statusContent: 'dot',
      hash: '1222L55Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1222L55Y'
    },
    {
      status: 910,
      sku: 'PM0076098',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RHHARUKO',
      name: "Rhododendron (AJ) 'Haruko'",
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      statusContent: 'dot',
      hash: '8RBYRJ5Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8RBYRJ5Z'
    },
    {
      status: 210,
      sku: 'PM0076088',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284473',
      rtl_batch_array: '6284473',
      name_search: 'FARPINGW',
      name: "Fargesia robusta 'Pingwu'",
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 914,
      chnn_stock_retail: 914,
      sppl_prcp: 5.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8RB2S1JK;7GCASV4E;WZNYL57E;9JR2RK27;BW9DB4GB;184EW4AR;4JWD7B5J;JHSXHSTR;KDE4S1RR;NLJC1ZH4;KH1A7W2H',
      statusContent: 'dot',
      hash: 'JR25LESB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JR25LESB'
    },
    {
      status: 910,
      sku: 'PM0083913',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358865',
      rtl_batch_array: '6358865',
      name_search: 'COCRPURP',
      name: "Cotinus coggygria 'Royal Purple'",
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 43.089,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N5G4Y3D2;A1ECKCES;VYB93WNV;W15TLDSC;GKL6S6SP;WAK76P5Z;THN94S3W;79R4ENJ3;XRD6G676;KAB6WXT7;B5YHK85G;CCHD74ED;VE4K9PVA;XKDCD5DD;D36WPBTA;8DHV3799;ZRHLWT41;JN97JG89;ZR8TST7S',
      statusContent: 'dot',
      hash: '68XBBPZ9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '68XBBPZ9'
    },
    {
      status: 210,
      sku: 'PM0076089',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284474',
      rtl_batch_array: '6284474',
      name_search: 'FARUFA',
      name: 'Fargesia rufa',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 2425,
      chnn_stock_retail: 2425,
      sppl_prcp: 5.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'NB8XBKZE;5VG5NLDH;11RHT7H7;HY9TYHS8;8YEN65ZD;ET9SYN9H;A2E5835W;666BWD94;B9VRRV1X;TJARTNEK;S8TP88W5;1ZRH6AJA;S35938S4;PD3W7ZG3;KP8PXPKG;KWCEP6BN;XZ11R2J2;HDDDVTLC;XZ3AS8Y8;972Z91K4;4X98KLBY;DZWDVCYZ;6TP7DZWJ;EG6KVV7E;N8AGLK8Z',
      statusContent: 'dot',
      hash: '8SBH9DL2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SBH9DL2'
    },
    {
      status: 210,
      sku: 'PM0004193',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301071',
      rtl_batch_array: '6315041, 6301071',
      name_search: 'ARAETHUS',
      name: 'Aruncus aethusifolius',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6936,
      chnn_stock_retail: 8233,
      sppl_order_minimum: 3,
      sppl_prcp: 0.763,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B82S79N;WJANAE6T;25Z8EWAD;APGG4RL8;6LCPKWD4;RE12TVSC;NW3H315C;RXJ3XT26',
      statusContent: 'dot',
      hash: '1J54GS26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1J54GS26'
    },
    {
      status: 210,
      sku: 'PM0016974',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353341',
      rtl_batch_array: '6353341',
      name_search: 'SAPSLAKE',
      name: "Salvia pratensis 'Swan Lake'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '62GN9TPH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '62GN9TPH'
    },
    {
      status: 210,
      sku: 'PM0011585',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353191',
      rtl_batch_array: '6301800, 6353191',
      name_search: 'RUFVLSUZ',
      name: "Rudbeckia fulgida 'Viette's Little Suzy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 220,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HAY2B45C',
      statusContent: 'dot',
      hash: 'L87JV3JH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L87JV3JH'
    },
    {
      status: 210,
      sku: 'PM0016703',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6255891',
      rtl_batch_array: '6164415, 6255891, 6295824, 6386172',
      name_search: 'PUBENSIG',
      name: "Pulmonaria 'Blue Ensign'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 3974,
      sppl_order_minimum: 3,
      sppl_prcp: 0.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '61EH4PL4',
      statusContent: 'dot',
      hash: 'PLRXZWDH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PLRXZWDH'
    },
    {
      status: 910,
      sku: 'PM0083914',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358866',
      rtl_batch_array: '6358866',
      name_search: 'EDCHRYSA',
      name: 'Edgeworthia chrysantha',
      sppl_size_code: '080100C15',
      rng_range_identifier: 'H080100C15',
      rng_range_description: 'H80 cm 100 cm C15',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 50.48,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5DKZV4GV;L29ZKLR5',
      statusContent: 'dot',
      hash: 'KDJLD551',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KDJLD551'
    },
    {
      status: 810,
      sku: 'PM0033587',
      core_name: 'Plant',
      btch_manufacturer: 1715,
      rtl_batch_array_total: 2,
      btch_active_retail: '6205175',
      rtl_batch_array: '6205175, 6356971',
      name_search: 'LIASALBA',
      name: "Liatris spicata 'Alba'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 44,
      chnn_stock_retail: 619,
      sppl_order_minimum: 3,
      sppl_prcp: 1.597,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S2BV65NK;LAVXBN3X',
      statusContent: 'dot',
      hash: 'WNH66BKS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WNH66BKS'
    },
    {
      status: 910,
      sku: 'PM0083915',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ELANGUST',
      name: 'Elaeagnus angustifolia',
      rng_range_identifier: 'SHI010012C30',
      rng_range_description: 'High stem 10 cm 12 cm C30',
      imageCore: 'ABW5CRA3',
      statusContent: 'dot',
      hash: 'RTC7ND63',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'RTC7ND63'
    },
    {
      status: 910,
      sku: 'PM0017621',
      core_name: 'Plant',
      sppl_ean: '8720349431809',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '5964895',
      rtl_batch_array: '5902344, 6176214, 6090775, 5964895, 6015140, 6308474',
      name_search: 'GESMFREI',
      name: "Geranium sanguineum 'Max Frei'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 12,
      chnn_stock_retail: 41524,
      sppl_order_minimum: 3,
      sppl_prcp: 0.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WBYASXG1;6H7XNWSD;SEB7R9LS;KSDHVT2L;N1TAX1SV;NNKE2BWR;5LXCW5PA;G9739E15;6CHZLZ1A;LNLT96AE;DAZJERZT',
      statusContent: 'dot',
      hash: 'XVNX2WGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVNX2WGD'
    },
    {
      status: 210,
      sku: 'PM0032697',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6244299',
      rtl_batch_array: '6083723, 6244299, 5849851, 6254827',
      name_search: 'SANCARAD',
      name: "Salvia nemorosa 'Caradonna'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 8790,
      chnn_stock_retail: 13047,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4G44ZLTY;CH999Z8V;82N5TCCX;H9PZEKPC',
      statusContent: 'dot',
      hash: 'YL15DYXZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YL15DYXZ'
    },
    {
      status: 210,
      sku: 'PM0033862',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6146929',
      rtl_batch_array: '6146929, 6219678',
      name_search: 'NERBWOND',
      name: "Nepeta racemosa 'Blue Wonder'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 313,
      chnn_stock_retail: 701,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S1S6ZA5E;XRLETWS2;84EDN17K',
      statusContent: 'dot',
      hash: 'YY176X1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YY176X1W'
    },
    {
      status: 810,
      sku: 'PM0083916',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358869',
      rtl_batch_array: '6358869',
      name_search: 'FATJAPON',
      name: 'Fatsia japonica',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 18.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RSC48GZ;R5SVETV2;49WXNLEH;NAPTC6TV;BR2DPDZE;H4CHEYKL;GEPH7HV3;8PSCVAB6;CH5TD35H;RDK4EXYN;6TX92Y4T;1NB9EV7C;YWTA9LTT;H96S38ZG;2DPPH8G6;52AYEX2C;AJ6CK65G;SDSSNPWV;7Z6RYRPY;JJ7K336S;42JXK8ZT;RVR78BZ6;E45K1DJ9;72RDDVZ6',
      statusContent: 'dot',
      hash: 'EZDG47BC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZDG47BC'
    },
    {
      status: 910,
      sku: 'PM0083917',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'GLTSUNBU',
      name: "Gleditsia triacanthos 'Sunburst'",
      rng_range_identifier: 'SHI014016C70',
      rng_range_description: 'High stem 14 cm 16 cm C70',
      imageCore: 'KSPHLSDZ;K2GZTXTE;2P36HKAH',
      statusContent: 'dot',
      hash: 'T9EBEZ4K',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T9EBEZ4K'
    },
    {
      status: 910,
      sku: 'PM0083918',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358871',
      rtl_batch_array: '6358871',
      name_search: 'HEMICONI',
      name: 'Heptacodium miconioides',
      sppl_size_code: '080100C10',
      rng_range_identifier: 'H080100C10',
      rng_range_description: 'H80 cm 100 cm C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 34.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XYZPK1ZL',
      statusContent: 'dot',
      hash: 'NL1G5CRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NL1G5CRG'
    },
    {
      status: 910,
      sku: 'PM0083919',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HISRHEAR',
      name: "Hibiscus syriacus 'Red Heart'",
      rng_range_identifier: 'SHI014016C70',
      rng_range_description: 'High stem 14 cm 16 cm C70',
      imageCore:
        'ED8W3VK6;HZ16SZ77;GLPYWH8E;G38D2T2L;D5AN7SDA;GTKGW3LE;81SGBESH;9J9T46BE',
      statusContent: 'dot',
      hash: 'CC83VX8L',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CC83VX8L'
    },
    {
      status: 210,
      sku: 'PM0083920',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358874',
      rtl_batch_array: '6358874',
      name_search: 'ILAQUIFO',
      name: 'Ilex aquifolium',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 196,
      chnn_stock_retail: 196,
      sppl_prcp: 12.75,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BTSHTT6C;EP3RZ5AP;LSLVTPR2;5ZW12B4B;2J8VRV66;BANC11VJ;TN5ZRT8A;NA24PALN;7SKV7A1K;41CR86JA;Y5DH9HLD;H1AT9E57;68D2N5RZ;YD1NER7V;HBCNY4LE;PPZDEZPK;8TR93RXE;BBZVRN2Y',
      statusContent: 'dot',
      hash: 'LH8LNZES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH8LNZES'
    },
    {
      status: 910,
      sku: 'PM0083921',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LITULIPI',
      name: 'Liriodendron tulipifera',
      rng_range_identifier: 'SHI014016C70',
      rng_range_description: 'High stem 14 cm 16 cm C70',
      imageCore:
        'W1653KLC;ND8KGDB7;G97V99CV;8X51LRBE;PGHEBYJX;1DH32SK8;YHKJWH52;SSEE7V6V;JNABSAZS',
      statusContent: 'dot',
      hash: 'C5XSSWXS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'C5XSSWXS'
    },
    {
      status: 210,
      sku: 'PM0084393',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378410',
      rtl_batch_array: '6378410',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 283,
      chnn_stock_retail: 283,
      sppl_prcp: 15.17,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: 'N5XR6641',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N5XR6641'
    },
    {
      status: 210,
      sku: 'PM0084394',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378245',
      rtl_batch_array: '6378245',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 17.67,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: 'A4RE7EE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4RE7EE4'
    },
    {
      status: 810,
      sku: 'PM0084395',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378411',
      rtl_batch_array: '6378411',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      sppl_size_code: '060080C10',
      rng_range_identifier: 'H060080C10',
      rng_range_description: 'H60 cm 80 cm C10',
      sppl_stock_available: 39,
      chnn_stock_retail: 39,
      sppl_prcp: 17.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: 'J2T6YLNE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2T6YLNE'
    },
    {
      status: 210,
      sku: 'PM0084396',
      core_name: 'Plant',
      btch_manufacturer: 170,
      rtl_batch_array_total: 1,
      btch_active_retail: '6378412',
      rtl_batch_array: '6378412',
      name_search: 'VIPKSUNR',
      name: 'Viburnum plicatum Kilimandjaro Sunrise',
      sppl_size_code: '080100KL',
      rng_range_identifier: 'H080100RB',
      rng_range_description: 'H80 cm 100 cm Root ball',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_prcp: 17.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ADWB4LTV;K1DRA4T3;NRTLA3LA;9D5LC4BD;DE2NZJPN;A37KJ9W6;V1HHCPN9;HY7T6SS1',
      statusContent: 'dot',
      hash: 'VV7DV3NA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VV7DV3NA'
    },
    {
      status: 210,
      sku: 'PM0076909',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298789',
      rtl_batch_array: '6298789',
      name_search: 'SANMADON',
      name: "Sambucus nigra 'Madonna'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1354,
      chnn_stock_retail: 1354,
      sppl_prcp: 3.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B5E12HJJ',
      statusContent: 'dot',
      hash: 'N53V7SHS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N53V7SHS'
    },
    {
      status: 210,
      sku: 'PM0076910',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298791',
      rtl_batch_array: '6298791',
      name_search: 'STABRUNE',
      name: 'Strobilanthes anisophylla Brunetthy',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1521,
      chnn_stock_retail: 1521,
      sppl_prcp: 7.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BX9SVTKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BX9SVTKG'
    },
    {
      status: 210,
      sku: 'PM0076913',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298794',
      rtl_batch_array: '6298794',
      name_search: 'SYPMKIM',
      name: "Syringa patula 'Miss Kim'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_prcp: 3.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H9C84CRR;RBGZ96SX;XPA14KLK;N7KPZ796;711JRA1B;JY6DHXP8',
      statusContent: 'dot',
      hash: 'XZPENHC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZPENHC2'
    },
    {
      status: 210,
      sku: 'PM0076914',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298795',
      rtl_batch_array: '6298795',
      name_search: 'SYVALSPA',
      name: "Syringa vulgaris 'Andenken an Ludwig Sp\u00e4th'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 5.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XRGBPK4G;6W7N2D5D;D7XLY3P8;YYKXS5EV;1Z9Y13PK;EA5N6983;XWYVAGKV',
      statusContent: 'dot',
      hash: 'A1C4XCWX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1C4XCWX'
    },
    {
      status: 210,
      sku: 'PM0076916',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298797',
      rtl_batch_array: '6298797',
      name_search: 'SYVKHAVE',
      name: "Syringa vulgaris 'Katherine Havemeyer'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 5.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JZXYD2XZ;B4W8B4HW',
      statusContent: 'dot',
      hash: 'PXY8BV8N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PXY8BV8N'
    },
    {
      status: 210,
      sku: 'PM0076917',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298798',
      rtl_batch_array: '6298798',
      name_search: 'SYVFSTEP',
      name: "Syringa vulgaris 'Mme Florent Stepman'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 5.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58774N2R;TN5WK3DC;LJ78SRBR;8E4Y8Y37',
      statusContent: 'dot',
      hash: 'KGB9YDW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGB9YDW7'
    },
    {
      status: 210,
      sku: 'PM0076919',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298800',
      rtl_batch_array: '6298800',
      name_search: 'SYVPRIMR',
      name: "Syringa vulgaris 'Primrose'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 189,
      chnn_stock_retail: 189,
      sppl_prcp: 5.783,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XW5BDRL8',
      statusContent: 'dot',
      hash: '5DC4SLDT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DC4SLDT'
    },
    {
      status: 210,
      sku: 'PM0076920',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298802',
      rtl_batch_array: '6298802',
      name_search: 'CRJJINDA',
      name: "Cryptomeria japonica 'Jindai'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 645,
      chnn_stock_retail: 645,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W37W7JE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W37W7JE1'
    },
    {
      status: 210,
      sku: 'PM0076921',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298803',
      rtl_batch_array: '6298803',
      name_search: 'CRJLCHAM',
      name: "Cryptomeria japonica 'Little Champion'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 335,
      chnn_stock_retail: 335,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N5RACXHR;2BWLBDNG',
      statusContent: 'dot',
      hash: '7PG2SPL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PG2SPL5'
    },
    {
      status: 210,
      sku: 'PM0076922',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298804',
      rtl_batch_array: '6298804',
      name_search: 'CRJLOBBI',
      name: "Cryptomeria japonica 'Lobbii'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_prcp: 9.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RZ1JK6RS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZ1JK6RS'
    },
    {
      status: 210,
      sku: 'PM0076923',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298805',
      rtl_batch_array: '6298805',
      name_search: 'CRJRASEN',
      name: "Cryptomeria japonica 'Rasen'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 278,
      chnn_stock_retail: 278,
      sppl_prcp: 9.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NB1D7GZN;JNVBLK37;DB633HJG',
      statusContent: 'dot',
      hash: 'H8HG36AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H8HG36AB'
    },
    {
      status: 210,
      sku: 'PM0076924',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298806',
      rtl_batch_array: '6298806',
      name_search: 'GIBILOBA',
      name: 'Ginkgo biloba',
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 4.883,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WY8TE552;DT2176J1;X7YVRRRC;ZA4NZ32C;CKEXKRAR;RLNHRA6B;XL1JAJ4D;DEPR9L6D;BWA6VT6S;1RDZ1KTZ;SJHWZYBP;HR2WJ79N;ZT59WZ7D;87HX4H3S',
      statusContent: 'dot',
      hash: 'TSC5AX6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSC5AX6C'
    },
    {
      status: 210,
      sku: 'PM0076927',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298811',
      rtl_batch_array: '6298811',
      name_search: 'JUCREPAN',
      name: "Juniperus communis 'Repanda'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 872,
      chnn_stock_retail: 872,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TCX3E1WY;AHRHLBLB;XLS3DSDH;8NW6KNDW;GSXVB568;YB6RY9D3;G47EZ7SW;649BBC73;STWVW2RL;YN213YVT;ZYJVR4AG;ARJ5LEEG;NSCSLPPB;LLLY47EE;G9ZP44BJ;E5EEEL2Y;5W75BBW8;43YH4JB9;DAH8E3D3;4J8HVX2E;W3K49ZNW',
      statusContent: 'dot',
      hash: 'K5A9694J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K5A9694J'
    },
    {
      status: 210,
      sku: 'PM0076928',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298812',
      rtl_batch_array: '6298812',
      name_search: 'JUHBCHIP',
      name: "Juniperus horizontalis 'Blue Chip'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 699,
      chnn_stock_retail: 699,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YJ8PR1BY;HDCYCYS6;CJTGT6CS;CEZEJ8AW;HJKDEBZP;PDJ1LX84;TKZZ5TKL;66XE4CC2',
      statusContent: 'dot',
      hash: 'YECELSJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YECELSJP'
    },
    {
      status: 210,
      sku: 'PM0076929',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298813',
      rtl_batch_array: '6298813',
      name_search: 'JUHPWALE',
      name: "Juniperus horizontalis 'Prince of Wales'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 789,
      chnn_stock_retail: 789,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HV4G8K7R;4DDP56PC;6Z3RDN63;X1JSG9LP;PS853GCJ;7JRYEC16',
      statusContent: 'dot',
      hash: '6LY3LAW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LY3LAW3'
    },
    {
      status: 210,
      sku: 'PM0077192',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350256',
      rtl_batch_array: '6350256',
      name_search: 'GEWCFARM',
      name: "Geranium wlassovianum 'Crug Farm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 187,
      chnn_stock_retail: 187,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VECV69B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VECV69B'
    },
    {
      status: 210,
      sku: 'PM0077195',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350298',
      rtl_batch_array: '6350298',
      name_search: 'HELORANG',
      name: "Helenium 'Little Orange'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 531,
      chnn_stock_retail: 531,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '55RSZGGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55RSZGGY'
    },
    {
      status: 210,
      sku: 'PM0076103',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284505',
      rtl_batch_array: '6284505',
      name_search: 'AGBTRIUM',
      name: "Agapanthus 'Blue Triumphator'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 991,
      chnn_stock_retail: 991,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BH8Z1WJT;GPSBAKKR;NS5HZ7PV;9G3CRK2C;1VVGGVLL;5N44YE1S;5SDACDRC;5EBGC8WG',
      statusContent: 'dot',
      hash: '52C1XTNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '52C1XTNX'
    },
    {
      status: 210,
      sku: 'PM0076107',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284513',
      rtl_batch_array: '6284513',
      name_search: 'ANHPAMIN',
      name: "Anemone hybrida 'Pamina'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 323,
      chnn_stock_retail: 323,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6R9V346;W4KAAL6D;HH98TZAC;SCNYWCWG;V7K1VNRY;13LJW99E;C6ZH7BBA;RN71E431;5E6TP5GT;85ECCB5S',
      statusContent: 'dot',
      hash: 'SBJNESKB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBJNESKB'
    },
    {
      status: 210,
      sku: 'PM0077197',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350599',
      rtl_batch_array: '6350599',
      name_search: 'KNBLEMON',
      name: "Kniphofia 'Bees' Lemon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 927,
      chnn_stock_retail: 927,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E48PGYJZ;JW6DLB46;8SVJB555;8SA7Y541',
      statusContent: 'dot',
      hash: 'GXVCZ6C6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXVCZ6C6'
    },
    {
      status: 210,
      sku: 'PM0077203',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6348345',
      rtl_batch_array: '6350556, 6348345',
      name_search: 'INHELENI',
      name: 'Inula helenium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 497,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '67N3HBWA',
      statusContent: 'dot',
      hash: '2K58KJ3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2K58KJ3P'
    },
    {
      status: 810,
      sku: 'PM0077206',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350678',
      rtl_batch_array: '6350678',
      name_search: 'MESVARIE',
      name: "Mentha suaveolens 'Variegata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S25K5G3B;95JDKW1H;TJ5WW3YE;97NSWNXS;CE8ZDSLC;2D6LDZHP;X2G4PVGE;V71JXGJ6;WAT59RHJ;KAV14G2Z;756P144V;G498BLDA',
      statusContent: 'dot',
      hash: 'GRW4G55G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRW4G55G'
    },
    {
      status: 910,
      sku: 'PM0083922',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358882',
      rtl_batch_array: '6358882',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      sppl_size_code: '200250C70',
      rng_range_identifier: 'H200250C70',
      rng_range_description: 'H200 cm 250 cm C70',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 303.36,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'GY1EG92L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GY1EG92L'
    },
    {
      status: 910,
      sku: 'PM0083923',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      rng_range_identifier: 'SHI012014C45',
      rng_range_description: 'High stem 12 cm 14 cm C45',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: '67C8Z3PL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '67C8Z3PL'
    },
    {
      status: 210,
      sku: 'PM0017251',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6291246',
      rtl_batch_array: '6301088, 6350671, 6291246, 6348369',
      name_search: 'MASTRUTH',
      name: 'Matteuccia struthiopteris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 32113,
      sppl_order_minimum: 3,
      sppl_prcp: 0.737,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LNBPG4PW;2YDLJK72;4AXSR1EV;NRAB97B4;4Y7E3917;NP1AV2VL;PYNGXRDE;A8K2X6VB;DG7HANSW;VZLHZE9P;EA9PL3NP;41SSC3BN;B7ABY16C;78B411KK;6BD1HB9H;SJN3EC3P',
      statusContent: 'dot',
      hash: 'RAH4NS4H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RAH4NS4H'
    },
    {
      status: 910,
      sku: 'PM0083924',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SASCHRYS',
      name: "Salix sepulcralis 'Chrysocoma'",
      rng_range_identifier: 'SHI008010C18',
      rng_range_description: 'High stem 8 cm 10 cm C18',
      imageCore: 'H6LB6WJY;TR617KHD;BL79PKPT;WXLALEW6',
      statusContent: 'dot',
      hash: '6RARRHNR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6RARRHNR'
    },
    {
      status: 210,
      sku: 'PM0076944',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298834',
      rtl_batch_array: '6298834',
      name_search: 'THOTTIM',
      name: "Thuja occidentalis 'Tiny Tim'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 655,
      chnn_stock_retail: 655,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2GN55EXS;BB4L3EGC;HCJCETDG;LDJZZC8X;L98DJ7YD;PEJG8Z2C;D1DCNRPN;7KREZ931;WAWALLCV;CJGE14G4;JEG7769A;BRAXLW3L;1DEDGW4T;S979L11H;CVLWZC74;S115657X;BWPZ1LLA;PKPG18XS',
      statusContent: 'dot',
      hash: '7PW7WCWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PW7WCWG'
    },
    {
      status: 210,
      sku: 'PM0076099',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284499',
      rtl_batch_array: '6284499',
      name_search: 'RHOWLADY',
      name: "Rhododendron (AJ) 'White Lady'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 385,
      chnn_stock_retail: 385,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9HN56S3J;XY2NG1NT;GK4G4LYH;V4JHW1C9;99C8W2LT;YPWZGW3L;NNSNXHW4;BVCY18SA;VCB8YX9D;EC8T6RY5;5K1JR4PB',
      statusContent: 'dot',
      hash: '5VS6Y5D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VS6Y5D7'
    },
    {
      status: 210,
      sku: 'PM0076100',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284500',
      rtl_batch_array: '6284500',
      name_search: 'RHGIBRAL',
      name: "Rhododendron (AK) 'Gibraltar'",
      sppl_size_code: '040060C4',
      rng_range_identifier: 'H040060C4',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 5.04,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WWPD269G;S12YYBNL;JB171LJ5;2B8ZSTT3;CDD2HPAE;922A68LL;XBESZ1K5',
      statusContent: 'dot',
      hash: 'EKKZCS6B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKKZCS6B'
    },
    {
      status: 810,
      sku: 'PM0076101',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284501',
      rtl_batch_array: '6284501',
      name_search: 'RHPERSIL',
      name: "Rhododendron (AK) 'Persil'",
      sppl_size_code: '040060C4',
      rng_range_identifier: 'H040060C4',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_prcp: 4.79,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EKPZNV4W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EKPZNV4W'
    },
    {
      status: 210,
      sku: 'PM0076102',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284502',
      rtl_batch_array: '6284502',
      name_search: 'RHBDANUB',
      name: "Rhododendron (AJ) 'Blue Danube'",
      sppl_size_code: '020030C2',
      rng_range_identifier: 'H020030C2',
      rng_range_description: 'H20 cm 30 cm C2',
      sppl_stock_available: 343,
      chnn_stock_retail: 343,
      sppl_prcp: 2.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KXVR249A;VLPYCDZ7;9PAY541Y;PCH7PPKL;K2ZHCJXD;6NCEBERW;9PDH3ETL;ZB17Y9B2;ZVBW976J;GWXE175A;L2BZ1DK7;5JA6C6XZ;LWBSH2XZ',
      statusContent: 'dot',
      hash: '6V7TRB6Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6V7TRB6Z'
    },
    {
      status: 210,
      sku: 'PM0084435',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285129',
      rtl_batch_array: '6285129',
      name_search: 'ROMAMORE',
      name: "Rosa 'Morden Amorette'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1850,
      chnn_stock_retail: 1850,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AWLRYVHG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWLRYVHG'
    },
    {
      status: 210,
      sku: 'PM0084436',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285130',
      rtl_batch_array: '6285130',
      name_search: 'ROMCENTE',
      name: "Rosa 'Morden Centennial'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1110,
      chnn_stock_retail: 1110,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJNX3EH6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJNX3EH6'
    },
    {
      status: 910,
      sku: 'PM0083925',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SOAUCUPA',
      name: 'Sorbus aucuparia',
      rng_range_identifier: 'SHI008010C18',
      rng_range_description: 'High stem 8 cm 10 cm C18',
      imageCore: 'N4DZ5ZW7;2D3H9RX9;YW624LE9;JSK2LLEK',
      statusContent: 'dot',
      hash: 'XC4J8KDN',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'XC4J8KDN'
    },
    {
      status: 210,
      sku: 'PM0076104',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284506',
      rtl_batch_array: '6284506',
      name_search: 'AGSUNFIE',
      name: "Agapanthus 'Sunfield'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1281,
      chnn_stock_retail: 1281,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EDRL48KV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EDRL48KV'
    },
    {
      status: 910,
      sku: 'PM0083926',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358887',
      rtl_batch_array: '6358887',
      name_search: 'WIFALBA',
      name: "Wisteria floribunda 'Alba'",
      sppl_size_code: '175200C10',
      rng_range_identifier: 'H175200C10',
      rng_range_description: 'H175 cm 200 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 32.084,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B22GLPV4;6L34KZYZ;RA61YCAP;7CPJTZ7N;EEEA7YB1',
      statusContent: 'dot',
      hash: 'DXVX3TWZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DXVX3TWZ'
    },
    {
      status: 210,
      sku: 'PM0076105',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284507',
      rtl_batch_array: '6284507',
      name_search: 'AGSUZAN',
      name: "Agapanthus 'Suzan'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 642,
      chnn_stock_retail: 642,
      sppl_prcp: 2.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C4X8VXJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C4X8VXJ8'
    },
    {
      status: 910,
      sku: 'PM0083927',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358888',
      rtl_batch_array: '6358888',
      name_search: 'CEDEODAR',
      name: 'Cedrus deodara',
      sppl_size_code: '125150C18',
      rng_range_identifier: 'H125150C18',
      rng_range_description: 'H125 cm 150 cm C18',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 44.849,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T5RXHTWS;PNYGE1KN;KVE87HX6;PVA6G4NB;NH8GR9T9',
      statusContent: 'dot',
      hash: 'ZXWRWSDW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZXWRWSDW'
    },
    {
      status: 910,
      sku: 'PM0083928',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358889',
      rtl_batch_array: '6358889',
      name_search: 'GIBFBLAG',
      name: 'Ginkgo biloba Fastigiata Blagon',
      sppl_size_code: '175200C45',
      rng_range_identifier: 'H175200C45',
      rng_range_description: 'H175 cm 200 cm C45',
      sppl_stock_available: 9,
      chnn_stock_retail: 9,
      sppl_prcp: 82.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B3V5B3TV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3V5B3TV'
    },
    {
      status: 210,
      sku: 'PM0083930',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358894',
      rtl_batch_array: '6358894',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '120140KL',
      rng_range_identifier: 'H120140RB',
      rng_range_description: 'H120 cm 140 cm Root ball',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: '9VX3679A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VX3679A'
    },
    {
      status: 210,
      sku: 'PM0007037',
      core_name: 'Plant',
      sppl_ean: '8720664871007',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6112658',
      rtl_batch_array: '6112658, 5277184',
      name_search: 'KNEGLOW',
      name: 'Kniphofia Ember Glow',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1175,
      chnn_stock_retail: 1183,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XBT4ZXT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBT4ZXT5'
    },
    {
      status: 210,
      sku: 'PM0076110',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284518',
      rtl_batch_array: '6272027, 6284518',
      name_search: 'ASMPRIMA',
      name: "Astrantia major 'Primadonna'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1016,
      chnn_stock_retail: 2016,
      sppl_prcp: 1.771,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '17YS5KC3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17YS5KC3'
    },
    {
      status: 210,
      sku: 'PM0077198',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350602',
      rtl_batch_array: '6350602',
      name_search: 'KNDSENTR',
      name: "Kniphofia 'Dorset Sentry'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BDRCG6AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDRCG6AS'
    },
    {
      status: 210,
      sku: 'PM0076111',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284520',
      rtl_batch_array: '6284520',
      name_search: 'CAFLACCA',
      name: 'Carex flacca',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 827,
      chnn_stock_retail: 827,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SV5JDHT',
      statusContent: 'dot',
      hash: 'LC2Y7NHP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LC2Y7NHP'
    },
    {
      status: 910,
      sku: 'PM0076112',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'EZD85NBR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EZD85NBR'
    },
    {
      status: 210,
      sku: 'PM0076113',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284527',
      rtl_batch_array: '6284527',
      name_search: 'PRLVNES',
      name: "Prunus laurocerasus 'Van Nes'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 499,
      chnn_stock_retail: 499,
      sppl_prcp: 4.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K4H95GKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K4H95GKN'
    },
    {
      status: 810,
      sku: 'PM0007739',
      core_name: 'Plant',
      sppl_ean: '8720664692787',
      btch_manufacturer: 508,
      rtl_batch_array_total: 4,
      btch_active_retail: '5386659',
      rtl_batch_array: '6298629, 5386659, 5496709, 5496710',
      name_search: 'HYAANNAB',
      name: "Hydrangea arborescens 'Annabelle'",
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 1150,
      sppl_prcp: 7.525,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '97WRVH5Z;1K3SYZXX;1HAHVW79;2E48W5AA;8A5V1YGJ;WCY1JXSJ;1LPE5W4E;62JC35X2;5ETD97LC;6HJHALZD;XDXEETK2;6TXGJKXR',
      statusContent: 'dot',
      hash: '14A6HY3T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14A6HY3T'
    },
    {
      status: 210,
      sku: 'PM0003149',
      core_name: 'Plant',
      sppl_ean: '8720664875098',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 6,
      btch_active_retail: '6147183',
      rtl_batch_array: '6147183, 6208052, 6176190, 6133424, 5364359, 6287686',
      name_search: 'NESHGIAN',
      name: "Nepeta 'Six Hills Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1200,
      chnn_stock_retail: 33157,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'P5A3KB96;G9JH2P1K;B9TSLTR6;HHSZEGKL;K9D8H9CT;SS7TWGDZ;BDXC99PY;PPG4YWKG;A9DV867W;PPTNE2DP;L2HEWPPV;6H8W59KV;ZK55T26J;2926Y1WV;4B4DB35R;CPD69W2P;6G4G1W64;KV1WXYS1;ZBTHA3DP;XS3NB11N;WSDAA929;CTLZ3671;9B2HKLC5',
      statusContent: 'dot',
      hash: '2313YJW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2313YJW3'
    },
    {
      status: 210,
      sku: 'PM0006441',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6339324',
      rtl_batch_array: '6208039, 5521439, 6193648, 6339324',
      name_search: 'LYSALICA',
      name: 'Lythrum salicaria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1115,
      chnn_stock_retail: 3414,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V6AC6K6P;E4N5HJDG;4B83RE2S;52X2815T;8L7H2K6Z;LX6CSTK6;Y8A5AHBX;GD76A67L;GL3TSX82',
      statusContent: 'dot',
      hash: '2SHPYNCW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SHPYNCW'
    },
    {
      status: 210,
      sku: 'PM0028006',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 5,
      btch_active_retail: '6245544',
      rtl_batch_array: '6084933, 6245544, 5472311, 5866507, 6015095',
      name_search: 'GECKARMI',
      name: "Geranium cantabrigiense 'Karmina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1704,
      chnn_stock_retail: 5983,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KRT57258;81LT3WDN;7D1LEZY6;7HXGSVVT;VGN9WECW;DJEHPDDB;TH816VLC;THTTYTV4;LPP74BZD;G3BA1HJE;Y8YKSBL8;TSL8Y4Y5;P4436YKA;JZ2J8ABD;RE6JAAS2;DX5JBD8V;JLKYLH44;2418B6PV;ZCA8SV68;HT399TP4',
      statusContent: 'dot',
      hash: '4RBV7JZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RBV7JZZ'
    },
    {
      status: 210,
      sku: 'PM0031830',
      core_name: 'Plant',
      sppl_ean: '8720349412167',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '4468257',
      rtl_batch_array: '4468257',
      name_search: 'CAOEVERS',
      name: "Carex oshimensis 'Eversheen'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 4.265,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B8XX5C8;4NXXLR6X;22A7GN8T;TEEY7ESK;BCP4A5HD;HAVHL1CG;YT31LELT;VGZHYRRT;WYC3T5EN;WSLP6VCG',
      imageBatch: 'SECW21H4',
      statusContent: 'dot',
      hash: '4ZXHN6PE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4ZXHN6PE'
    },
    {
      status: 210,
      sku: 'PM0077199',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350608',
      rtl_batch_array: '6350608, 6338945',
      name_search: 'KNIPPOPS',
      name: "Kniphofia 'Pineapple Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 714,
      chnn_stock_retail: 791,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '58EYWE9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '58EYWE9X'
    },
    {
      status: 910,
      sku: 'PM0040057',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BUDWPROF',
      name: "Buddleja davidii 'White Profusion'",
      rng_range_identifier: 'H030050C2',
      rng_range_description: 'H30 cm 50 cm C2',
      imageCore:
        '142T826S;7DY9KYW3;X9B1JDJ7;4RRY2J95;R72WXW18;C7ZS1T9J;75K7X21K;8AG1N8TL;5WT6LKTJ;9LLVPTWG;SSP5V4C9;A54DENEL',
      statusContent: 'dot',
      hash: 'ERBW27HD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ERBW27HD'
    },
    {
      status: 210,
      sku: 'PM0083931',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358900',
      rtl_batch_array: '6358900',
      name_search: 'ALAPSENS',
      name: "Allium aflatunense 'Purple Sensation'",
      sppl_size_code: 'BOL',
      rng_range_identifier: 'BALLBU',
      rng_range_description: 'Ball Bulb',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 0.4,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HGC3EXKT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HGC3EXKT'
    },
    {
      status: 210,
      sku: 'PM0029736',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6339322',
      rtl_batch_array: '6350597, 5960179, 6339322',
      name_search: 'KNMACEDO',
      name: 'Knautia macedonica',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1764,
      chnn_stock_retail: 5407,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1V3B96XA;TN9V5X3T;V9J43XHP',
      statusContent: 'dot',
      hash: 'SL1ZH9AH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SL1ZH9AH'
    },
    {
      status: 210,
      sku: 'PM0042135',
      core_name: 'Plant',
      sppl_ean: '8720626375864',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420733',
      rtl_batch_array: '5420733',
      name_search: 'VIREGENT',
      name: "Vitis 'Regent'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 995,
      chnn_stock_retail: 995,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4R7TJPPS',
      statusContent: 'dot',
      hash: 'T312GJ4Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T312GJ4Z'
    },
    {
      status: 210,
      sku: 'PM0011314',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353415',
      rtl_batch_array: '6353415',
      name_search: 'SESTRICO',
      name: "Sedum spurium 'Tricolor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 176,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWESLZRT;JSYKNBA3;ZXS4GWTK;92YAADEX',
      statusContent: 'dot',
      hash: 'V8G3ZHEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8G3ZHEL'
    },
    {
      status: 210,
      sku: 'PM0030972',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353331',
      rtl_batch_array: '6353331',
      name_search: 'SANSCVIO',
      name: "Salvia nem. 'Sens. Comp. Violet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 466,
      chnn_stock_retail: 466,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VSZ952D8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSZ952D8'
    },
    {
      status: 210,
      sku: 'PM0009304',
      core_name: 'Plant',
      sppl_ean: '8720664871083',
      btch_manufacturer: 172,
      rtl_batch_array_total: 4,
      btch_active_retail: '6091530',
      rtl_batch_array: '6349129, 5251434, 6091530, 6015420',
      name_search: 'KOGLAUCA',
      name: 'Koeleria glauca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 651,
      chnn_stock_retail: 6138,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YER5SPN8;T5NN83DN;W7XR4SW5;HWLR2SAZ;N9X726CD;A88RXT8A;XVBKW59K;JNAWPLBS;SVHAE79R;PEBKLRH6;14TAJW5C;1E8WSSH7;6NY6HTY9;EKL2SE7J;GWSCNGSC',
      statusContent: 'dot',
      hash: 'YZEKYB8W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZEKYB8W'
    },
    {
      status: 210,
      sku: 'PM0077202',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350532',
      rtl_batch_array: '6350532',
      name_search: 'HOSTITAN',
      name: "Hosta 'Titanium'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 708,
      chnn_stock_retail: 708,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEBR75J6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEBR75J6'
    },
    {
      status: 210,
      sku: 'PM0076930',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298814',
      rtl_batch_array: '6298814',
      name_search: 'JUHWILTO',
      name: "Juniperus horizontalis 'Wiltonii'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'YTXR91JA;XE2PNDN2;79XC8391;KA6JEJWN;TTPAJR27;5DLCNL9T;8PDW76PN;77XT6W5A;171G33ND;HCB8CRHL;PKNT5STB;SX87XJ56',
      statusContent: 'dot',
      hash: 'C6GBKDP6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C6GBKDP6'
    },
    {
      status: 210,
      sku: 'PM0076931',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298815',
      rtl_batch_array: '6298815',
      name_search: 'JUSTAMAR',
      name: "Juniperus sabina 'Tamariscifolia'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 854,
      chnn_stock_retail: 854,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S7A49Z1T;DST5VLHZ;VJ4DBS7X;WKXH11PY',
      statusContent: 'dot',
      hash: '5HSS1XY8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5HSS1XY8'
    },
    {
      status: 210,
      sku: 'PM0076932',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298816',
      rtl_batch_array: '6298816',
      name_search: 'JUSBCARP',
      name: "Juniperus squamata 'Blue Carpet'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 734,
      chnn_stock_retail: 734,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN9VHPHG;SV186TKL;RYZ4SDJE;WBRJKHDJ;9BBLDVYA;ZHW7YW6C;CJH5J9S7;A6V6DZKH;LLNSJYES;KG2GV77P;71E9RGDD;G6RWS9ZY;NE85GWDZ;NDPLKJVK;S35C7VEV;GGR8XV44;9TJ24KGN;YNBC3Y7R;BLBXAG4G;KGXS6ZDA',
      statusContent: 'dot',
      hash: '8AGK3S3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8AGK3S3X'
    },
    {
      status: 210,
      sku: 'PM0076933',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298818',
      rtl_batch_array: '6298818',
      name_search: 'JUSHOLGE',
      name: "Juniperus squamata 'Holger'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1343,
      chnn_stock_retail: 1343,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '27RDVKTT;CR8CL3Z4;DEXSKTNY;C8Z6KHZ6;BVE5HJDT;13CER4GX;T5JPZL46;S82JXW9H;SCY2ZHED;G2S4ZZVD;BAYEH66C',
      statusContent: 'dot',
      hash: '7513P7Y4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7513P7Y4'
    },
    {
      status: 810,
      sku: 'PM0076934',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298819',
      rtl_batch_array: '6298819',
      name_search: 'LADPULI',
      name: "Larix decidua 'Puli'",
      sppl_size_code: '120STC10',
      rng_range_identifier: 'STE120C10',
      rng_range_description: 'Stem 120 cm C10',
      sppl_stock_available: 29,
      chnn_stock_retail: 29,
      sppl_prcp: 27.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'S2JVECHE;L6PCK7KS;LYTY5LV4;5N83LC1H',
      statusContent: 'dot',
      hash: 'ZJZE62BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZJZE62BH'
    },
    {
      status: 210,
      sku: 'PM0083929',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358890',
      rtl_batch_array: '6358890',
      name_search: 'TABACCAT',
      name: 'Taxus baccata',
      sppl_size_code: '175200KL',
      rng_range_identifier: 'H175200RB',
      rng_range_description: 'H175 cm 200 cm Root ball',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 62,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1B6CN56S;D2KY66CB;TZL3Z7V6;Y1A6AH87;W5S3C53R;KJ81KBVX;1L4Y1ASY;G6LRYNES;BA2JHZ4Z;52XY6AVT;J48HYATX;TAJPC9ZW;6DWPX71C;97V1216C;LEBZTL6G;B91BH97Y;N76JEL3G;81GHXPWZ;VXZ283BX;SCLYPAP4',
      statusContent: 'dot',
      hash: 'NY6YKPWE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NY6YKPWE'
    },
    {
      status: 210,
      sku: 'PM0076937',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298823',
      rtl_batch_array: '6298823',
      name_search: 'MIDLUCAS',
      name: "Microbiota decussata 'Lucas'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1372,
      chnn_stock_retail: 1372,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4WY3LW45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4WY3LW45'
    },
    {
      status: 910,
      sku: 'PM0083933',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358903',
      rtl_batch_array: '6358903',
      name_search: 'ROOPROST',
      name: "Rosmarinus officinalis 'Prostratus'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 16.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L7YDPZTP;A85BYZ6Z;VJHZ1AR1;6TTX2LKX;G96LGV7Y;HCBJPJW1;BXJ152H4;5E3N1XWA;TBNBCP3X;6GNN8W72;N73VWDDZ;JVL7D2KX;X9RXRWGJ;49G7JVWW;K1VRX8YG;H3G5X318;23EASCDH;T2YZ7KD7;LSJWJRRY;THA8RX26;TE1BVWHJ;ZPKT3PGD;YH251HAN;CHC4R2ZH;TD9KH3WJ;RD1L9K97',
      statusContent: 'dot',
      hash: 'EGP25935',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EGP25935'
    },
    {
      status: 210,
      sku: 'PM0077205',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350665',
      rtl_batch_array: '6301707, 6350665',
      name_search: 'LYFPJENN',
      name: "Lychnis flos-cuculi 'Petite Jenny'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 294,
      chnn_stock_retail: 396,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2JP1T4K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2JP1T4K'
    },
    {
      status: 810,
      sku: 'PM0077207',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350654',
      rtl_batch_array: '6350654',
      name_search: 'LIDMOORB',
      name: "Ligularia dentata 'Moorblut'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4E8ZLBJX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E8ZLBJX'
    },
    {
      status: 210,
      sku: 'PM0083934',
      core_name: 'Plant',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '6358915',
      rtl_batch_array: '6358915',
      name_search: 'TSHETERO',
      name: 'Tsuga heterophylla',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 952,
      chnn_stock_retail: 952,
      sppl_prcp: 3.775,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1C1TY5TP',
      statusContent: 'dot',
      hash: 'J9RGPJ9W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9RGPJ9W'
    },
    {
      status: 210,
      sku: 'PM0076109',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284515',
      rtl_batch_array: '6284515',
      name_search: 'ANTROBUS',
      name: "Anemone tomentosa 'Robustissima'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 897,
      chnn_stock_retail: 897,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5CPJT45S',
      statusContent: 'dot',
      hash: 'Z11TYNRB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z11TYNRB'
    },
    {
      status: 210,
      sku: 'PM0076106',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284509',
      rtl_batch_array: '6284509',
      name_search: 'AGPHAZE',
      name: "Agastache 'Purple Haze'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 860,
      chnn_stock_retail: 860,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ZZB6G1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZZB6G1K'
    },
    {
      status: 210,
      sku: 'PM0076108',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284514',
      rtl_batch_array: '6284514',
      name_search: 'ANHSEREN',
      name: "Anemone hybrida 'Serenade'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 694,
      chnn_stock_retail: 694,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V9B6VWV6;B74ETH8B;BHL6SVHS;HPYZKNRY;1AXAWYLG;149P7AHH;6DPWTGRE;PL2J2XKZ;HC6ZA5JX;YHL4RJH5;7C7EYGJ9;ZTCBP7C7',
      statusContent: 'dot',
      hash: 'DWN1TJA2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DWN1TJA2'
    },
    {
      status: 810,
      sku: 'PM0076940',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298827',
      rtl_batch_array: '6298827',
      name_search: 'SCVPICOL',
      name: "Sciadopitys verticillata 'Picola'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 49,
      chnn_stock_retail: 49,
      sppl_prcp: 16.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W4DV7Z3Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4DV7Z3Z'
    },
    {
      status: 810,
      sku: 'PM0076941',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298828',
      rtl_batch_array: '6298828',
      name_search: 'SCVSLIGH',
      name: "Sciadopitys vert. 'Shine a Light'",
      sppl_size_code: 'LEVC10',
      rng_range_identifier: 'C10',
      rng_range_description: 'C10',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_prcp: 30.125,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B69GA2S6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B69GA2S6'
    },
    {
      status: 210,
      sku: 'PM0076943',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298830',
      rtl_batch_array: '6298830',
      name_search: 'SCVSTERN',
      name: "Sciadopitys verticillata 'Sternschnuppe'",
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_prcp: 20.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1KT2S19G',
      statusContent: 'dot',
      hash: 'PRRXD4PS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRRXD4PS'
    },
    {
      status: 210,
      sku: 'PM0076945',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 1,
      btch_active_retail: '6298836',
      rtl_batch_array: '6298836',
      name_search: 'TSCJEDDE',
      name: "Tsuga canadensis 'Jeddeloh'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1003,
      chnn_stock_retail: 1003,
      sppl_prcp: 2.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '99B8JVWB;9K4Z69BJ;523X2GZ7;PA6ZXZCA;5WV7PV5N;T1H2VVWZ;CCVRV972;G45214ST;Y8VBN2E7;BL2JXSAG',
      statusContent: 'dot',
      hash: 'YAENL7WJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YAENL7WJ'
    },
    {
      status: 910,
      sku: 'PM0077200',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LEAMATTE',
      name: "Leontopodium alpinum 'Matterhorn'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'YVLKGJTG',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YVLKGJTG'
    },
    {
      status: 210,
      sku: 'PM0077201',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350637',
      rtl_batch_array: '6350637',
      name_search: 'LEAMBLAN',
      name: "Leontopodium alpinum 'Mont Blanc'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V4XV83PD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V4XV83PD'
    },
    {
      status: 210,
      sku: 'PM0077204',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350664',
      rtl_batch_array: '6301706, 6350664',
      name_search: 'LYFPHENR',
      name: 'Lychnis flos-cuculi Petit Henri',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 462,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4RLEX72K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4RLEX72K'
    },
    {
      status: 810,
      sku: 'PM0084397',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285089',
      rtl_batch_array: '6285089',
      name_search: 'ROAMEIDI',
      name: 'Rosa (H) Alba Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ND2A9SWJ',
      statusContent: 'dot',
      hash: 'XPAC15G4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XPAC15G4'
    },
    {
      status: 210,
      sku: 'PM0084398',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285090',
      rtl_batch_array: '6285090',
      name_search: 'ROAMSTEL',
      name: 'Rosa (H) Amstelveen',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D4V5PY7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4V5PY7G'
    },
    {
      status: 210,
      sku: 'PM0084399',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285091',
      rtl_batch_array: '6285091',
      name_search: 'ROBASEBA',
      name: 'Rosa (H) Baseball',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X1WXASJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X1WXASJE'
    },
    {
      status: 210,
      sku: 'PM0084400',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285092',
      rtl_batch_array: '6285092',
      name_search: 'ROBMEIDI',
      name: 'Rosa (H) Bingo Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KTRGCAV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KTRGCAV'
    },
    {
      status: 210,
      sku: 'PM0084401',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285093',
      rtl_batch_array: '6285093',
      name_search: 'ROBONICA',
      name: "Rosa (F) 'Bonica'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZRKK9HNB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZRKK9HNB'
    },
    {
      status: 210,
      sku: 'PM0084402',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285094',
      rtl_batch_array: '6285094',
      name_search: 'ROESCIMO',
      name: 'Rosa Escimo',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1010,
      chnn_stock_retail: 1010,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HAKK1BEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HAKK1BEP'
    },
    {
      status: 210,
      sku: 'PM0084403',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285095',
      rtl_batch_array: '6285095',
      name_search: 'ROEUPHOR',
      name: 'Rosa (H) Euphoria',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4EETS1V8',
      statusContent: 'dot',
      hash: 'KEYT6V7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KEYT6V7E'
    },
    {
      status: 210,
      sku: 'PM0084404',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285096',
      rtl_batch_array: '6285096',
      name_search: 'ROFDANCE',
      name: 'Rosa (M) Fairy Dance',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.476,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BJK88SYX;8TPCLTX5;YYVJGW8V',
      statusContent: 'dot',
      hash: 'CZPV9J96',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZPV9J96'
    },
    {
      status: 210,
      sku: 'PM0084405',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285098',
      rtl_batch_array: '6285098',
      name_search: 'ROFMEADO',
      name: 'Rosa (H) Flushing Meadow',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8HG1CZTH;V5VEH5YX',
      statusContent: 'dot',
      hash: 'NHG4D2K5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NHG4D2K5'
    },
    {
      status: 210,
      sku: 'PM0084406',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285099',
      rtl_batch_array: '6285099',
      name_search: 'ROGITALI',
      name: "Rosa (H) Giro d' Italia",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B9C4SKTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B9C4SKTT'
    },
    {
      status: 210,
      sku: 'PM0084407',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285100',
      rtl_batch_array: '6285100',
      name_search: 'ROGMOZAR',
      name: 'Rosa Golden Mozart',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BLD1NBZZ',
      statusContent: 'dot',
      hash: 'YSTDWTW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YSTDWTW2'
    },
    {
      status: 210,
      sku: 'PM0084408',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285101',
      rtl_batch_array: '6285101',
      name_search: 'ROGOLF',
      name: 'Rosa (H) Golf',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1975,
      chnn_stock_retail: 1975,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K181D1SY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K181D1SY'
    },
    {
      status: 210,
      sku: 'PM0084409',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285102',
      rtl_batch_array: '6285102',
      name_search: 'ROIMEIDI',
      name: 'Rosa (H) Ice Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KXHR7LT9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXHR7LT9'
    },
    {
      status: 210,
      sku: 'PM0084410',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285103',
      rtl_batch_array: '6285103',
      name_search: 'ROIDRIFT',
      name: 'Rosa (H) Icy Drift',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 630,
      chnn_stock_retail: 630,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '23ZGRV3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23ZGRV3K'
    },
    {
      status: 210,
      sku: 'PM0084411',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285104',
      rtl_batch_array: '6285104',
      name_search: 'RORADRAZ',
      name: "Rosa (H) 'Radrazz'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 660,
      chnn_stock_retail: 660,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1JKKP51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1JKKP51'
    },
    {
      status: 210,
      sku: 'PM0084412',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285105',
      rtl_batch_array: '6285105',
      name_search: 'ROSLMEIL',
      name: "Rosa (M) 'Lavender Meillandina'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RWSK7K7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWSK7K7T'
    },
    {
      status: 210,
      sku: 'PM0084413',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285106',
      rtl_batch_array: '6285106',
      name_search: 'ROMMEIDI',
      name: 'Rosa (H) Magic Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZTB5P5K4;GDSDJ2TG;5XRT9398',
      statusContent: 'dot',
      hash: 'H1J2HDX5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H1J2HDX5'
    },
    {
      status: 210,
      sku: 'PM0084414',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285108',
      rtl_batch_array: '6285108',
      name_search: 'ROMEDEO',
      name: 'Rosa (F) Medeo',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1390,
      chnn_stock_retail: 1390,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X3H2HPXK',
      statusContent: 'dot',
      hash: 'Z1XXA64R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z1XXA64R'
    },
    {
      status: 210,
      sku: 'PM0084415',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285109',
      rtl_batch_array: '6285109',
      name_search: 'ROMOZART',
      name: "Rosa (H) 'Mozart'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W2K9HBV3;RRVVE7B9;55KS1A97;KL2PKTKA;5YLZ81DE;BXNG6ZY5;A5B5XPXA',
      statusContent: 'dot',
      hash: 'H7438A8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7438A8Y'
    },
    {
      status: 210,
      sku: 'PM0084416',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285110',
      rtl_batch_array: '6285110',
      name_search: 'RONOATRA',
      name: "Rosa (H) 'Noatraum'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7Z76RSY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7Z76RSY7'
    },
    {
      status: 210,
      sku: 'PM0084417',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285111',
      rtl_batch_array: '6285111',
      name_search: 'ROSPMEID',
      name: 'Rosa (H) Pink Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1125,
      chnn_stock_retail: 1125,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TR4PKNPP',
      statusContent: 'dot',
      hash: '7RX7ASEL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7RX7ASEL'
    },
    {
      status: 210,
      sku: 'PM0084418',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285112',
      rtl_batch_array: '6285112',
      name_search: 'ROSPSTAR',
      name: 'Rosa Pretty Star',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8PSKT5ZX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8PSKT5ZX'
    },
    {
      status: 210,
      sku: 'PM0084419',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285113',
      rtl_batch_array: '6285113',
      name_search: 'ROPSUNRI',
      name: 'Rosa (H) Pretty Sunrise',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WGBW3GD5;148WCCX9;V96YWLXH;S4XVSH1C;6SV3GAJG;Y4YHNW4J',
      statusContent: 'dot',
      hash: 'GTVHG3J6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTVHG3J6'
    },
    {
      status: 210,
      sku: 'PM0084420',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285114',
      rtl_batch_array: '6285114',
      name_search: 'RORALLY',
      name: 'Rosa (H) Rally',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HXAE9D5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXAE9D5P'
    },
    {
      status: 210,
      sku: 'PM0084421',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285115',
      rtl_batch_array: '6285115',
      name_search: 'RORDRIFT',
      name: 'Rosa (M) Red Drift',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SDDGAD49;1N8XPKN6;1N8J9TRN;PCKPKJC3;DC5KV119',
      statusContent: 'dot',
      hash: 'DC3EC9B3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DC3EC9B3'
    },
    {
      status: 210,
      sku: 'PM0084422',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285116',
      rtl_batch_array: '6285116',
      name_search: 'RORMEIDI',
      name: 'Rosa (H) Red Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7Z4PHDB;JN13651S;NEL68Y8V;ASE5VY5V;2NW8HTYB;6AHXN925',
      statusContent: 'dot',
      hash: 'WJ7SCRZA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WJ7SCRZA'
    },
    {
      status: 210,
      sku: 'PM0084423',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285117',
      rtl_batch_array: '6285117',
      name_search: 'RORGARRO',
      name: 'Rosa (H) Roland Garros',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1965,
      chnn_stock_retail: 1965,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7PPD6X2H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7PPD6X2H'
    },
    {
      status: 210,
      sku: 'PM0084424',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285118',
      rtl_batch_array: '6285118',
      name_search: 'RORHANNO',
      name: 'Rosa (H) Rote Hannover',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DG63Z39H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DG63Z39H'
    },
    {
      status: 210,
      sku: 'PM0084425',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285119',
      rtl_batch_array: '6285119',
      name_search: 'ROFRONTE',
      name: "Rosa (H) 'Frontenac'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1550,
      chnn_stock_retail: 1550,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZAGJR1HG;T3SPGD86',
      statusContent: 'dot',
      hash: '1XZ5JPB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XZ5JPB8'
    },
    {
      status: 210,
      sku: 'PM0084426',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285120',
      rtl_batch_array: '6285120',
      name_search: 'ROHKELSE',
      name: "Rosa 'Henry Kelsey'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 1860,
      chnn_stock_retail: 1860,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GATTDEYV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GATTDEYV'
    },
    {
      status: 210,
      sku: 'PM0084427',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285121',
      rtl_batch_array: '6285121',
      name_search: 'ROHUMANI',
      name: 'Rosa Humanity',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 880,
      chnn_stock_retail: 880,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2CTGNNX5;KXXATYT4;YTBK2R1P',
      statusContent: 'dot',
      hash: 'A4CNL8H6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A4CNL8H6'
    },
    {
      status: 210,
      sku: 'PM0084428',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285122',
      rtl_batch_array: '6285122',
      name_search: 'ROJMUNK',
      name: "Rosa (R) 'Jens Munk'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1085,
      chnn_stock_retail: 1085,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PE9V8YWG',
      statusContent: 'dot',
      hash: '231D3469',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '231D3469'
    },
    {
      status: 210,
      sku: 'PM0084429',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285123',
      rtl_batch_array: '6285123',
      name_search: 'ROKAKWA',
      name: "Rosa (H) 'Kakwa'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '66ZK6VL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '66ZK6VL5'
    },
    {
      status: 210,
      sku: 'PM0084430',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285124',
      rtl_batch_array: '6285124',
      name_search: 'ROLMAJEA',
      name: "Rosa (R) 'Lac Majeau'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W9ES8K3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9ES8K3R'
    },
    {
      status: 210,
      sku: 'PM0084431',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285125',
      rtl_batch_array: '6285125',
      name_search: 'ROLCLOSS',
      name: "Rosa (H) 'Lambert Closse'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1190,
      chnn_stock_retail: 1190,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N6YSAVSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N6YSAVSK'
    },
    {
      status: 210,
      sku: 'PM0084432',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285126',
      rtl_batch_array: '6285126',
      name_search: 'ROLRIEL',
      name: 'Rosa (H) Louis Riel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 510,
      chnn_stock_retail: 510,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7X7PAARN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7X7PAARN'
    },
    {
      status: 210,
      sku: 'PM0084433',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285127',
      rtl_batch_array: '6285127',
      name_search: 'ROLBUGNE',
      name: "Rosa (R) 'Louise Bugnet'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZK4ELDGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK4ELDGR'
    },
    {
      status: 210,
      sku: 'PM0084434',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285128',
      rtl_batch_array: '6285128',
      name_search: 'ROMFROBI',
      name: "Rosa (H) 'Martin Frobisher'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LGEWKBWK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LGEWKBWK'
    },
    {
      status: 210,
      sku: 'PM0084437',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285131',
      rtl_batch_array: '6285131',
      name_search: 'ROMRUBY',
      name: "Rosa (H) 'Morden Ruby'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1100,
      chnn_stock_retail: 1100,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '45JR286D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45JR286D'
    },
    {
      status: 210,
      sku: 'PM0084438',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285132',
      rtl_batch_array: '6285132',
      name_search: 'ROMBEAUT',
      name: 'Rosa (T) My Beauty',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 860,
      chnn_stock_retail: 860,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LH2HLHTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LH2HLHTH'
    },
    {
      status: 210,
      sku: 'PM0084439',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285133',
      rtl_batch_array: '6285133',
      name_search: 'ROMSUNRI',
      name: "Rosa (H) 'Morden Sunrise'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5N97E7',
      statusContent: 'dot',
      hash: 'LWEVHPB8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWEVHPB8'
    },
    {
      status: 210,
      sku: 'PM0084440',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285134',
      rtl_batch_array: '6285134',
      name_search: 'ROSPJOY',
      name: "Rosa (H) 'Prairie Joy'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '42Y3VCLY',
      statusContent: 'dot',
      hash: 'KS2BR33D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KS2BR33D'
    },
    {
      status: 210,
      sku: 'PM0084441',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285135',
      rtl_batch_array: '6285135',
      name_search: 'ROQUADRA',
      name: "Rosa (H) 'Quadra'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 10,
      sppl_prcp: 1.884,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NZWW719E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZWW719E'
    },
    {
      status: 210,
      sku: 'PM0084442',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285136',
      rtl_batch_array: '6285136',
      name_search: 'ROWBOOTH',
      name: "Rosa (H) 'William-Booth'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLX1D129',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLX1D129'
    },
    {
      status: 210,
      sku: 'PM0084443',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285137',
      rtl_batch_array: '6285137',
      name_search: 'ROBJAMES',
      name: "Rosa (K) 'Bobbie James'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 1680,
      chnn_stock_retail: 1680,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LCKAXT1C;B7W9H7EK;XE1N9CLN;7EC7LRBL;D2E5LSGC',
      statusContent: 'dot',
      hash: 'DKXBWGV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKXBWGV2'
    },
    {
      status: 210,
      sku: 'PM0084444',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285138',
      rtl_batch_array: '6285138',
      name_search: 'ROMUTABI',
      name: "Rosa (H) 'Mutabilis'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9TS7PK9Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TS7PK9Z'
    },
    {
      status: 210,
      sku: 'PM0084445',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285139',
      rtl_batch_array: '6285139',
      name_search: 'ROGFELIG',
      name: "Rosa (K) 'Ghislaine de F\u00e9ligonde'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 1560,
      chnn_stock_retail: 1560,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ET4TRZLD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET4TRZLD'
    },
    {
      status: 210,
      sku: 'PM0084446',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285140',
      rtl_batch_array: '6285140',
      name_search: 'ROGAMOUR',
      name: "Rosa (K) Guirlande d' Amour",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATA7NPNV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATA7NPNV'
    },
    {
      status: 210,
      sku: 'PM0084447',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285141',
      rtl_batch_array: '6285141',
      name_search: 'ROWESTER',
      name: 'Rosa (H) Westerland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DYRLRGWC;9W5JJ435;XH47GZ4D;6VGX6VR5;VTED5W66',
      statusContent: 'dot',
      hash: 'J7VNRRH4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7VNRRH4'
    },
    {
      status: 210,
      sku: 'PM0084448',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285142',
      rtl_batch_array: '6285142',
      name_search: 'ROSRFAIR',
      name: 'Rosa (P) Red Fairy',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.476,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H7H74VXG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H7H74VXG'
    },
    {
      status: 210,
      sku: 'PM0084449',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285143',
      rtl_batch_array: '6285143',
      name_search: 'ROFAIRY',
      name: "Rosa (P) 'The Fairy'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N3R9LW9X;VX8TLPCV;TYHX8SJD;H8DP8BNS;PCR6L1V9;1L13XG31;GRX3V9J9',
      statusContent: 'dot',
      hash: 'G7E41JBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7E41JBS'
    },
    {
      status: 910,
      sku: 'PM0076117',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PHTUBERO',
      name: 'Phlomis tuberosa',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      imageCore: '7H4CWE4J;A6YWC6DR',
      statusContent: 'dot',
      hash: '6GSYJDZR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '6GSYJDZR'
    },
    {
      status: 210,
      sku: 'PM0076119',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284553',
      rtl_batch_array: '6284553',
      name_search: 'EUCHDUMP',
      name: "Euphorbia characias 'Humpty Dumpty'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1259,
      chnn_stock_retail: 1259,
      sppl_prcp: 2.071,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TDBELAEX;6V52BLY7;45A6PTKN;C2RHP1TA;492VYE4S;AJSP7LNN;L543H7B9;TWGN8THA',
      statusContent: 'dot',
      hash: 'LB246A3P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB246A3P'
    },
    {
      status: 210,
      sku: 'PM0076615',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6295741',
      rtl_batch_array: '6295741',
      name_search: 'ASMAJOR',
      name: 'Astrantia major',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HXRH9BAN;C48S1Z5D;DB66DHVC;HW6RB225;6WXGEJV5;5EV4ZX66;J4H8SDAC;DTDN2AZ1;ERRDVXVA',
      statusContent: 'dot',
      hash: 'ZR7LYDKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR7LYDKP'
    },
    {
      status: 210,
      sku: 'PM0076118',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6284549',
      rtl_batch_array: '6284549, 6254714',
      name_search: 'ECPURPUR',
      name: 'Echinacea purpurea',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 2624,
      chnn_stock_retail: 5456,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7TXBJJ8K;4TZJCNLZ;2S124CT9;G12TBABS;VXE1Z3EP;A727S678;P9TWHCKA;1GE2ZB3S;7G16KJXE;18JGWAYG;CCHNWHH9;HY2A66AY;7ZNRBA3P;5CWD2TGW;WT6W1T3W;V53VY5JB;11S2XENG;XKSLB8D1;CS8WTBXD;NBL2DRRY;WW6GS14S;BG8A1C8L;JST2T4LS;PR78AA8R',
      statusContent: 'dot',
      hash: 'ET6DHWV8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET6DHWV8'
    },
    {
      status: 210,
      sku: 'PM0077209',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353077',
      rtl_batch_array: '6353077',
      name_search: 'PEOFTALE',
      name: "Pennisetum orientale 'Fairy Tales'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZY8YJSPH;CJRG5RYD',
      statusContent: 'dot',
      hash: 'KY2VK8X4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KY2VK8X4'
    },
    {
      status: 210,
      sku: 'PM0077215',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319735',
      rtl_batch_array: '6353265, 6319735',
      name_search: 'RHRHABAR',
      name: 'Rheum rhabarbarum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 471,
      chnn_stock_retail: 693,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6ED1V6CP;CZJL88Y7;SVXD2LE4;ZZCZ3337;B2P5WB17;8YL23NCX;1KB8B9B1;PTG1GNL4;HNX9CLAH;BA1NZXCG;8AASGTS8;963AEEC6;ZVLE7S3E;J7RP78E1;X9278EY7;W2TGVEGY;GNYT8T5J',
      statusContent: 'dot',
      hash: '1WLBZSDE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1WLBZSDE'
    },
    {
      status: 210,
      sku: 'PM0077219',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353188',
      rtl_batch_array: '6353188, 6340041',
      name_search: 'ROOPROST',
      name: "Rosmarinus officinalis 'Prostratus'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 1237,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'L7YDPZTP;A85BYZ6Z;VJHZ1AR1;6TTX2LKX;G96LGV7Y;HCBJPJW1;BXJ152H4;5E3N1XWA;TBNBCP3X;6GNN8W72;N73VWDDZ;JVL7D2KX;X9RXRWGJ;49G7JVWW;K1VRX8YG;H3G5X318;23EASCDH;T2YZ7KD7;LSJWJRRY;THA8RX26;TE1BVWHJ;ZPKT3PGD;YH251HAN;CHC4R2ZH;TD9KH3WJ;RD1L9K97',
      statusContent: 'dot',
      hash: 'DLVETPZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLVETPZW'
    },
    {
      status: 210,
      sku: 'PM0076114',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284533',
      rtl_batch_array: '6284533',
      name_search: 'MIPURPUR',
      name: "Miscanthus 'Purpurascens'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 295,
      chnn_stock_retail: 295,
      sppl_prcp: 1.921,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6SBCGC6J;X9ZBSJYT;KRPLLADW',
      statusContent: 'dot',
      hash: '9HSYBW5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HSYBW5B'
    },
    {
      status: 210,
      sku: 'PM0077220',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301801',
      rtl_batch_array: '6301801, 6353194',
      name_search: 'RUMAXIMA',
      name: 'Rudbeckia maxima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 322,
      chnn_stock_retail: 1295,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JVDYGJK5;V8TKCSWE',
      statusContent: 'dot',
      hash: 'R5C4X1DE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R5C4X1DE'
    },
    {
      status: 210,
      sku: 'PM0010906',
      core_name: 'Plant',
      sppl_ean: '8720664866218',
      btch_manufacturer: 492,
      rtl_batch_array_total: 4,
      btch_active_retail: '6084954',
      rtl_batch_array: '6084954, 6350307, 6301630, 6308583',
      name_search: 'HEWESERG',
      name: "Helenium 'Wesergold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1452,
      chnn_stock_retail: 1728,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EBHNAEAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBHNAEAX'
    },
    {
      status: 210,
      sku: 'PM0084450',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285144',
      rtl_batch_array: '6285144',
      name_search: 'ROGLOED',
      name: "Rosa 'Gloed'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BSR2C97Y;CXDGNG8G;D4KDSN61;4NYHTTL4',
      statusContent: 'dot',
      hash: '8A7ANY6C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8A7ANY6C'
    },
    {
      status: 210,
      sku: 'PM0084451',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285145',
      rtl_batch_array: '6285145',
      name_search: 'ROASUN',
      name: 'Rosa (H) Amber Sun',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KK4A9T1C',
      statusContent: 'dot',
      hash: 'N1PD2PEY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1PD2PEY'
    },
    {
      status: 210,
      sku: 'PM0014764',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6308442',
      rtl_batch_array: '5186938, 6217026, 6383363, 6308442',
      name_search: 'NEGROL',
      name: "Nepeta 'Grol'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 235,
      chnn_stock_retail: 4324,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '12WVRR5C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '12WVRR5C'
    },
    {
      status: 210,
      sku: 'PM0020311',
      core_name: 'Plant',
      sppl_ean: '8720664871755',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164864',
      rtl_batch_array: '6313208, 6164864',
      name_search: 'LABWINE',
      name: "Lavatera 'Burgundy Wine'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 359,
      chnn_stock_retail: 647,
      sppl_prcp: 2.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GBXEG5RW;5RP73688',
      statusContent: 'dot',
      hash: '5GN54HWT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GN54HWT'
    },
    {
      status: 210,
      sku: 'PM0014193',
      core_name: 'Plant',
      sppl_ean: '8720664864351',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 4,
      btch_active_retail: '6085455',
      rtl_batch_array: '6085455, 6220786, 6164283, 6265366',
      name_search: 'GEPVAPEL',
      name: "Geranium 'Philippe Vapelle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 1797,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PWY964PZ;A7V9VRAW;1RXNKBVH;6W254PKX;65WE7SZA;PSJ5RL55;EBN1AW6Y;4HTAHVT3;7KB1RBL5;Y1BZBL8P',
      statusContent: 'dot',
      hash: '72BP5KDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72BP5KDV'
    },
    {
      status: 210,
      sku: 'PM0002509',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 2,
      btch_active_retail: '6291798',
      rtl_batch_array: '6297476, 6291798',
      name_search: 'ANHPAMIN',
      name: "Anemone hybrida 'Pamina'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 9895,
      chnn_stock_retail: 10395,
      sppl_order_minimum: 3,
      sppl_prcp: 1.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6R9V346;W4KAAL6D;HH98TZAC;SCNYWCWG;V7K1VNRY;13LJW99E;C6ZH7BBA;RN71E431;5E6TP5GT;85ECCB5S',
      statusContent: 'dot',
      hash: '7CLNLAEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7CLNLAEC'
    },
    {
      status: 810,
      sku: 'PM0084452',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285147',
      rtl_batch_array: '6285147',
      name_search: 'ROABRETA',
      name: 'Rosa (H) Anne de Bretagne',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 10,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9S9Y79NR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9S9Y79NR'
    },
    {
      status: 210,
      sku: 'PM0021460',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319744',
      rtl_batch_array: '6339831, 6319744',
      name_search: 'LESQUALI',
      name: 'Leptinella squalida',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1681,
      chnn_stock_retail: 6481,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPG1Z594',
      statusContent: 'dot',
      hash: 'B153C3EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B153C3EZ'
    },
    {
      status: 210,
      sku: 'PM0084453',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285148',
      rtl_batch_array: '6285148',
      name_search: 'ROBPARFA',
      name: 'Rosa (H) Bouquet Parfait',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7P7Y7KEG;2PJCZ7GL',
      statusContent: 'dot',
      hash: 'YDLEX7GH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YDLEX7GH'
    },
    {
      status: 210,
      sku: 'PM0084454',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285149',
      rtl_batch_array: '6285149',
      name_search: 'ROBUKAVU',
      name: "Rosa (H) 'Bukavu'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DZWJHDT4',
      statusContent: 'dot',
      hash: 'V6LZK9P7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V6LZK9P7'
    },
    {
      status: 210,
      sku: 'PM0020858',
      core_name: 'Plant',
      sppl_ean: '8720626351417',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 1,
      btch_active_retail: '5364238',
      rtl_batch_array: '5364238',
      name_search: 'HOGBAG',
      name: "Hosta 'Green Bag'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 336,
      chnn_stock_retail: 336,
      sppl_order_minimum: 3,
      sppl_prcp: 1.294,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLKWCXE5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLKWCXE5'
    },
    {
      status: 210,
      sku: 'PM0006001',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 2,
      btch_active_retail: '6226112',
      rtl_batch_array: '6226112, 5414829',
      name_search: 'VITGWENL',
      name: "Viburnum tinus 'Gwenllian'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 862,
      chnn_stock_retail: 1362,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LXAH6EAT;WX5YL518;VTB6RXZL;T4DS25D6;5ECEB76W;SR41ZABD;A5K7BSTH',
      statusContent: 'dot',
      hash: 'J1S9KVBB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J1S9KVBB'
    },
    {
      status: 910,
      sku: 'PM0015160',
      core_name: 'Plant',
      sppl_ean: '8720664662186',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '5740856',
      rtl_batch_array: '5740856',
      name_search: 'EUWBETTE',
      name: "Euphorbia waldsteinii 'Betten'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 18,
      chnn_stock_retail: 18,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J8AE4TZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8AE4TZV'
    },
    {
      status: 210,
      sku: 'PM0023690',
      core_name: 'Plant',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6238469',
      rtl_batch_array: '6238469',
      name_search: 'SEACCARP',
      name: "Sedum album 'Coral Carpet'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1029,
      chnn_stock_retail: 1029,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V158DJVS;RBC2NVHB;ATJ2G7HP;VKL4Z558;X2DZR6GE;ZR1R9YR7;EKP3WW4Z;4X4ZB51N;CNBS7414;2Y367LCS;C6CX9ZX1;TVE6A223;9PSBSDN8;HRL8LGAG;967L4ZJ6;ZJ5VHRAW;4N4PJZXX;VZGBRZNX;V6L1TT8K;DK46JS3A;SJ41C5LG;2CJWN8GW;PZNK6BRR;L345SGKK;WS49RJY9;KSH2DYEL;98H94NRH',
      statusContent: 'dot',
      hash: 'LES92X9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LES92X9P'
    },
    {
      status: 210,
      sku: 'PM0016178',
      core_name: 'Plant',
      sppl_ean: '8720349431700',
      btch_manufacturer: 93,
      rtl_batch_array_total: 7,
      btch_active_retail: '5932399',
      rtl_batch_array:
        '5533711, 6315049, 5932399, 6054610, 6167458, 6382278, 6333231',
      name_search: 'FEGEBLUE',
      name: "Festuca glauca 'Elijah Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 15000,
      chnn_stock_retail: 35795,
      sppl_order_minimum: 3,
      sppl_prcp: 0.477,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J766CKS4;DSGC49L4;EKE8LKVD;8YA37WB7;8NC6GPLP;52933NNJ;VYV6NZDG;Y66JY5HD;ZC3KBZ37;S839XYEZ;S5R6EZ66;WYDNWS6T;56HTXDEA;2AG3RG5T;SP23L1N6;KACP8HHV;BYDV2S27;C9ZSN5GZ;YK774H7S;E8R1X7KK;SDVTBBT3;489XDBKA;46YYJ42H;KZ4ZVV8G;RX5JV4PG;XJW2CP6P;T83KC53D;1V5B5TN3;6T8X8W6X;RCDAKJC4;GRJ37D6L',
      statusContent: 'dot',
      hash: 'LXV431YN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXV431YN'
    },
    {
      status: 210,
      sku: 'PM0084455',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285150',
      rtl_batch_array: '6285150',
      name_search: 'ROCBONIC',
      name: 'Rosa (K) Climbing Bonica',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1C8SG7GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1C8SG7GK'
    },
    {
      status: 210,
      sku: 'PM0006923',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6268813',
      rtl_batch_array: '6268813',
      name_search: 'PLPDISTI',
      name: 'Pleioblastus pygmaeus distichus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 519,
      chnn_stock_retail: 519,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GZSLTV2S',
      statusContent: 'dot',
      hash: 'NVY27DRX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NVY27DRX'
    },
    {
      status: 210,
      sku: 'PM0077208',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353066',
      rtl_batch_array: '6353066',
      name_search: 'PAMONAND',
      name: 'Patrinia monandra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 419,
      chnn_stock_retail: 419,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PNWWA82C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PNWWA82C'
    },
    {
      status: 810,
      sku: 'PM0004927',
      core_name: 'Plant',
      sppl_ean: '8720349444830',
      btch_manufacturer: 508,
      rtl_batch_array_total: 2,
      btch_active_retail: '5818823',
      rtl_batch_array: '5818823, 5496364',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '050060C74',
      rng_range_identifier: 'H050060C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 100,
      sppl_prcp: 11.98,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: 'VKS81ANY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VKS81ANY'
    },
    {
      status: 810,
      sku: 'PM0077211',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301743',
      rtl_batch_array: '6301743, 6350717',
      name_search: 'NEWBBLUE',
      name: "Nepeta 'Weinheim Big Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 64,
      chnn_stock_retail: 363,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4HGE6RNT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4HGE6RNT'
    },
    {
      status: 210,
      sku: 'PM0016658',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242977',
      rtl_batch_array: '6242977',
      name_search: 'PHVROSEA',
      name: "Physostegia virginiana 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 203,
      chnn_stock_retail: 203,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XXGSRRRZ;KZRWNPDV;KS8VRXGW;RN4S4R8T;TJH57BKB;ZX87VY34;BYE3Y76A;DWWGLWTN;CZVNA6KB;J214PY1T;PZTLYHVN;6Z61XR7V;L2RZCX23;4BRC5YGW;62KZT6GN;ZW544LDR',
      statusContent: 'dot',
      hash: 'WB3HA33S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WB3HA33S'
    },
    {
      status: 210,
      sku: 'PM0077214',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353262',
      rtl_batch_array: '6353262',
      name_search: 'RACPULCH',
      name: 'Ratibida columnifera pulcherrima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YWJ63Y8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YWJ63Y8L'
    },
    {
      status: 210,
      sku: 'PM0077218',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353174',
      rtl_batch_array: '6353174',
      name_search: 'ROIBRONZ',
      name: "Rodgersia 'Irish Bronze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 136,
      chnn_stock_retail: 136,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '59DLLDJ8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59DLLDJ8'
    },
    {
      status: 210,
      sku: 'PM0077221',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353196',
      rtl_batch_array: '6353196',
      name_search: 'RUDNJULI',
      name: "Rudbeckia nitida 'Juligold'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 229,
      chnn_stock_retail: 229,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RE8VE74E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RE8VE74E'
    },
    {
      status: 210,
      sku: 'PM0084456',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285152',
      rtl_batch_array: '6285152',
      name_search: 'ROKENT',
      name: 'Rosa (H) Kent',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B13B4AW6',
      statusContent: 'dot',
      hash: 'H9C3XYHJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9C3XYHJ'
    },
    {
      status: 210,
      sku: 'PM0084457',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285153',
      rtl_batch_array: '6285153',
      name_search: 'ROKNIRPS',
      name: 'Rosa (H) Knirps',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'APCHDSLN;SCNWXXPX;9D85CVES',
      statusContent: 'dot',
      hash: '469WHDB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '469WHDB9'
    },
    {
      status: 210,
      sku: 'PM0084458',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285154',
      rtl_batch_array: '6285154',
      name_search: 'ROMAROND',
      name: 'Rosa (H) Marondo',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A3KVEXPG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A3KVEXPG'
    },
    {
      status: 810,
      sku: 'PM0084459',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285155',
      rtl_batch_array: '6285155',
      name_search: 'ROMSGART',
      name: 'Rosa (H) Main Sch\u00f6ner Garten',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AZ2JB13C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AZ2JB13C'
    },
    {
      status: 210,
      sku: 'PM0076115',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 4,
      btch_active_retail: '6284537',
      rtl_batch_array: '6252709, 6284537, 2681757, 6284561',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1198,
      chnn_stock_retail: 5088,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      statusContent: 'dot',
      hash: '585A2H3G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '585A2H3G'
    },
    {
      status: 210,
      sku: 'PM0076116',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284538',
      rtl_batch_array: '6284538',
      name_search: 'PAVROTST',
      name: "Panicum virgatum 'Rotstrahlbusch'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.671,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '465RJY8V;GLTPRJWC;5PDC2D6E;EX449DD1;Y91VH24K;B2E85B5G;E2Z6YKLP;S8ZTES9R;R7XVP3LX;4CPBG92G;9PR892J8;YJC76RY8;CG53XZ3S',
      statusContent: 'dot',
      hash: 'L9C3H345',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9C3H345'
    },
    {
      status: 210,
      sku: 'PM0077210',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353129',
      rtl_batch_array: '6353129',
      name_search: 'PEAFILIG',
      name: "Perovskia atriplicifolia 'Filigran'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 181,
      chnn_stock_retail: 181,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AVTR36SP;5VJ53PE3;7WKXAZRC;D2YLSDDT;VW5CZYXA',
      statusContent: 'dot',
      hash: 'Y9916LYN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9916LYN'
    },
    {
      status: 210,
      sku: 'PM0077212',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6350722',
      rtl_batch_array: '6350722',
      name_search: 'ONVICIIF',
      name: 'Onobrychis viciifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 696,
      chnn_stock_retail: 696,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SDRJ9E39',
      statusContent: 'dot',
      hash: '78R7L6CC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78R7L6CC'
    },
    {
      status: 210,
      sku: 'PM0077213',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353260',
      rtl_batch_array: '6353260',
      name_search: 'RABULBOS',
      name: 'Ranunculus bulbosus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 309,
      chnn_stock_retail: 309,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SBXR5B5E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SBXR5B5E'
    },
    {
      status: 210,
      sku: 'PM0084460',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285156',
      rtl_batch_array: '6285156',
      name_search: 'RONMEIDI',
      name: 'Rosa (H) Nadia Meidiland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7V3C4B1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7V3C4B1'
    },
    {
      status: 810,
      sku: 'PM0084461',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285157',
      rtl_batch_array: '6285157',
      name_search: 'ROPFRANK',
      name: 'Rosa (H) Palmengarten Frankfurt',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 10,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WC4YWAEV;5112BVJ7;1R9K123V;118A2E5X;2JE8VLS2',
      statusContent: 'dot',
      hash: '4BAWZR4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4BAWZR4E'
    },
    {
      status: 210,
      sku: 'PM0084462',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285159',
      rtl_batch_array: '6285159',
      name_search: 'ROPSEVIL',
      name: 'Rosa (F) Pink La Sevillana',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 10,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TZ7JRNNW;YXRBN2B3;S85X5AJX;8PZ1J6VG;4HVS93PG;H4H9ZCX8;WXXJSBRL',
      statusContent: 'dot',
      hash: 'Z5TYTLG3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z5TYTLG3'
    },
    {
      status: 910,
      sku: 'PM0084463',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285160',
      rtl_batch_array: '6285160',
      name_search: 'RORKOUT',
      name: 'Rosa (H) Rainbow Knock Out',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C9GH1YWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9GH1YWS'
    },
    {
      status: 210,
      sku: 'PM0077216',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353266',
      rtl_batch_array: '6353266',
      name_search: 'RHBLJEAN',
      name: "Rhodohypoxis baurii 'Lily Jean'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AP6K1ARW;7W9SNK11;V9X4GL4G;9S4EVRZ7;WAT8ZY2E;PNTA1W7B;B8JJBRXG',
      statusContent: 'dot',
      hash: 'DJXHV36H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJXHV36H'
    },
    {
      status: 210,
      sku: 'PM0077217',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353172',
      rtl_batch_array: '6353172',
      name_search: 'ROPDSCHO',
      name: "Rodgersia pinnata 'Die Schone'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8ZBL16EN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZBL16EN'
    },
    {
      status: 810,
      sku: 'PM0084464',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285161',
      rtl_batch_array: '6285161',
      name_search: 'ROROSALE',
      name: 'Rosa (F) Rosaletta',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7EYAN4JY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7EYAN4JY'
    },
    {
      status: 210,
      sku: 'PM0084465',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285162',
      rtl_batch_array: '6285162',
      name_search: 'ROROSARI',
      name: 'Rosa (F) Rosario',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KLZ4J7TV;135163EB',
      statusContent: 'dot',
      hash: '8KS4BKR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8KS4BKR1'
    },
    {
      status: 210,
      sku: 'PM0084466',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285165',
      rtl_batch_array: '6285165',
      name_search: 'ROSSCHNE',
      name: 'Rosa (H) Schneek\u00f6nigin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YEG9RN74;DRTRX25S',
      statusContent: 'dot',
      hash: 'YBEG47D7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBEG47D7'
    },
    {
      status: 810,
      sku: 'PM0084467',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285166',
      rtl_batch_array: '6285166',
      name_search: 'ROSOMMER',
      name: 'Rosa (T) Sommerabend',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '56ZK7TBA;AW9S1VA5;CHXLV3XN;T13DKJA9',
      statusContent: 'dot',
      hash: '1KPAWD5T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KPAWD5T'
    },
    {
      status: 210,
      sku: 'PM0084468',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285167',
      rtl_batch_array: '6285167',
      name_search: 'ROSROM',
      name: 'Rosa (H) Stadt Rom',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '89PZKLDX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '89PZKLDX'
    },
    {
      status: 810,
      sku: 'PM0084469',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285168',
      rtl_batch_array: '6285168',
      name_search: 'ROSUROSE',
      name: 'Rosa (H) Sunny Rose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1YL548JT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1YL548JT'
    },
    {
      status: 210,
      sku: 'PM0084470',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285169',
      rtl_batch_array: '6285169',
      name_search: 'ROSWANY',
      name: 'Rosa (H) Swany',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 825,
      chnn_stock_retail: 825,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C5G29CKZ;EZLGG4P9;H66RVJZ4;EGN5DYEE',
      statusContent: 'dot',
      hash: 'NZ1GBTX1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZ1GBTX1'
    },
    {
      status: 210,
      sku: 'PM0084471',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285170',
      rtl_batch_array: '6285170',
      name_search: 'ROSPRETT',
      name: 'Rosa (H) Sweet Pretty',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1650,
      chnn_stock_retail: 1650,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8ZC4J6NX;5HYPYN98;XWESE979;56C2YW5L',
      statusContent: 'dot',
      hash: '5487HE7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5487HE7H'
    },
    {
      status: 210,
      sku: 'PM0084472',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285171',
      rtl_batch_array: '6285171',
      name_search: 'ROWMEIDI',
      name: 'Rosa (H) White Meidilland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 680,
      chnn_stock_retail: 680,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W7DG8ZCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W7DG8ZCK'
    },
    {
      status: 210,
      sku: 'PM0084473',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285172',
      rtl_batch_array: '6285172',
      name_search: 'ROBBEYES',
      name: 'Rosa (M) Blushing Babylon Eyes',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, [RESEARCH]',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J7CB6ZYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7CB6ZYY'
    },
    {
      status: 210,
      sku: 'PM0084474',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285173',
      rtl_batch_array: '6285173',
      name_search: 'ROFBEYES',
      name: 'Rosa (M) Fancy Babylon Eyes',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 740,
      chnn_stock_retail: 740,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TD37XA75',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TD37XA75'
    },
    {
      status: 210,
      sku: 'PM0084475',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285175',
      rtl_batch_array: '6285175',
      name_search: 'ROSBEYES',
      name: 'Rosa (PA) Sunshine Babylon Eyes',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 530,
      chnn_stock_retail: 530,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4CLNWDK1;CA6ZJYAL;VS46C1SA;H18BPSYW;RBC335WD;2S2HABSY',
      statusContent: 'dot',
      hash: 'B8WXDAPB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8WXDAPB'
    },
    {
      status: 210,
      sku: 'PM0084476',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285176',
      rtl_batch_array: '6285176',
      name_search: 'ROTBEYES',
      name: 'Rosa (M) Trendy Babylon Eyes',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W5LBV9TJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W5LBV9TJ'
    },
    {
      status: 210,
      sku: 'PM0084477',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285179',
      rtl_batch_array: '6285179',
      name_search: 'ROCHEWDE',
      name: "Rosa (H) 'Chewdelight'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1KB7E735',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1KB7E735'
    },
    {
      status: 210,
      sku: 'PM0084478',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285180',
      rtl_batch_array: '6285180',
      name_search: 'ROETIGER',
      name: 'Rosa (H) Eye of the Tiger',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1970,
      chnn_stock_retail: 1970,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECP3NDB6;SJAPP3J5',
      statusContent: 'dot',
      hash: 'V27ECCWB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V27ECCWB'
    },
    {
      status: 210,
      sku: 'PM0084479',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285181',
      rtl_batch_array: '6285181',
      name_search: 'ROEYOU',
      name: 'Rosa (T) Especially for You',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2VGA5S8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2VGA5S8L'
    },
    {
      status: 210,
      sku: 'PM0084480',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285182',
      rtl_batch_array: '6285182',
      name_search: 'ROEME',
      name: 'Rosa (H) Eyes on Me',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 460,
      chnn_stock_retail: 460,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SJKPCEKR;8DD673HK;JHSZTTBA;J4KRBYZ8',
      statusContent: 'dot',
      hash: 'ZZYV4DGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZYV4DGC'
    },
    {
      status: 210,
      sku: 'PM0084481',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285185',
      rtl_batch_array: '6285185',
      name_search: 'ROBFROSE',
      name: 'Rosa (F) Black Forest Rose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 860,
      chnn_stock_retail: 860,
      sppl_order_minimum: 10,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VX6HSSVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VX6HSSVN'
    },
    {
      status: 210,
      sku: 'PM0084482',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285186',
      rtl_batch_array: '6285186',
      name_search: 'ROSINNOC',
      name: 'Rosa Innocencia',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 10,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WTB2Z8AB;KRE7ZP1N',
      statusContent: 'dot',
      hash: 'VYKPW517',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VYKPW517'
    },
    {
      status: 210,
      sku: 'PM0084483',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285187',
      rtl_batch_array: '6285187',
      name_search: 'RONEON',
      name: 'Rosa Neon',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 10,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'G6YXE3KH;R5BK3PE9;547483SL',
      statusContent: 'dot',
      hash: 'WWL8XNX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWL8XNX6'
    },
    {
      status: 210,
      sku: 'PM0084484',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285188',
      rtl_batch_array: '6285188',
      name_search: 'ROROTILI',
      name: 'Rosa (P) Rotilia',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 10,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HHD7DY9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HHD7DY9J'
    },
    {
      status: 810,
      sku: 'PM0084485',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285189',
      rtl_batch_array: '6285189',
      name_search: 'ROFCALCA',
      name: 'Rosa Flower Carpet Alcantara',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 10,
      sppl_prcp: 4.995,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4DZYV49P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DZYV49P'
    },
    {
      status: 210,
      sku: 'PM0084486',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285190',
      rtl_batch_array: '6285190',
      name_search: 'ROHEIFEU',
      name: 'Rosa (F) Heidefeuer',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 10,
      sppl_prcp: 4.995,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R1481XVJ;62Y64615',
      statusContent: 'dot',
      hash: '6L14DNPD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6L14DNPD'
    },
    {
      status: 210,
      sku: 'PM0084487',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285191',
      rtl_batch_array: '6285191',
      name_search: 'ROFCSCHN',
      name: 'Rosa (P) Flower Carpet Schneeflocke',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1335,
      chnn_stock_retail: 1335,
      sppl_order_minimum: 10,
      sppl_prcp: 4.995,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PWBK1BB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWBK1BB3'
    },
    {
      status: 810,
      sku: 'PM0084488',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285192',
      rtl_batch_array: '6285192',
      name_search: 'ROFCSEDA',
      name: 'Rosa Flower Carpet Sedana',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 4.995,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5TA2L64Y;XYJ5GCLZ;KLLBAWPZ;WT1YL3SV;GRZPKKGD;653C47EX;7J2GDLX5;5WBTWX6H;JELSPYRN;PRDK89AK;4VZ598YS;GNBPEJBC',
      statusContent: 'dot',
      hash: '4L8HDD2J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4L8HDD2J'
    },
    {
      status: 210,
      sku: 'PM0084489',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285193',
      rtl_batch_array: '6285193',
      name_search: 'ROCFMEYE',
      name: "Rosa (R) 'Conrad Ferdinand Meyer'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'A5LAK1ZC',
      statusContent: 'dot',
      hash: 'YZZGCAVN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZZGCAVN'
    },
    {
      status: 210,
      sku: 'PM0084490',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285195',
      rtl_batch_array: '6285195',
      name_search: 'ROPGROOT',
      name: "Rosa (R) 'Pink Grootendorst'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '63AYK6WN;WJYXC7ZS',
      statusContent: 'dot',
      hash: 'XJJHGEL7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJJHGEL7'
    },
    {
      status: 810,
      sku: 'PM0084491',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285197',
      rtl_batch_array: '6285197',
      name_search: 'ROFDUFT',
      name: "Rosa (H) 'Fr\u00fchlingsduft'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZY6H7G8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZY6H7G8Z'
    },
    {
      status: 210,
      sku: 'PM0084492',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285198',
      rtl_batch_array: '6285198',
      name_search: 'ROGWINGS',
      name: "Rosa (H) 'Golden Wings'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4K9LA5TE;NJBA7HKV;VC54ECV6;55VVT3DZ',
      statusContent: 'dot',
      hash: 'KE5KWDK2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KE5KWDK2'
    },
    {
      status: 810,
      sku: 'PM0084493',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285201',
      rtl_batch_array: '6285201',
      name_search: 'ROSHOLME',
      name: "Rosa (H) 'Sally Holmes'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N21R2N4S;ZSDCWPJE;Z1ZXN395;KE5ZLG68',
      statusContent: 'dot',
      hash: 'X672GBEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X672GBEK'
    },
    {
      status: 210,
      sku: 'PM0084494',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285202',
      rtl_batch_array: '6285202',
      name_search: 'ROSEAFOA',
      name: "Rosa (H) 'Seafoam'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.476,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z4K3BW77;ZL15TWEZ;G8YD3E28;29YWS3PR',
      statusContent: 'dot',
      hash: 'L8GSJY9J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8GSJY9J'
    },
    {
      status: 210,
      sku: 'PM0084495',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285203',
      rtl_batch_array: '6285203',
      name_search: 'ROBDCOUB',
      name: "Rosa (R) 'Blanc Double de Coubert'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LE7P6H9R;2EEKZ3E4',
      statusContent: 'dot',
      hash: '2ASHGV9V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ASHGV9V'
    },
    {
      status: 210,
      sku: 'PM0084496',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285204',
      rtl_batch_array: '6285204',
      name_search: 'RODHASTR',
      name: 'Rosa (R) Dagmar Hastrup',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DXN74Y99;4TD59ZW7;KX88NXG4',
      statusContent: 'dot',
      hash: 'ESDGNZ7E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ESDGNZ7E'
    },
    {
      status: 210,
      sku: 'PM0084497',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285205',
      rtl_batch_array: '6285205',
      name_search: 'RODDEFEN',
      name: "Rosa Dart's Defender",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZZDTSK3K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZDTSK3K'
    },
    {
      status: 210,
      sku: 'PM0084498',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285206',
      rtl_batch_array: '6285206',
      name_search: 'ROEANGEL',
      name: 'Rosa (F) Earth Angel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '78WZ8XHN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '78WZ8XHN'
    },
    {
      status: 210,
      sku: 'PM0084499',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285207',
      rtl_batch_array: '6285207',
      name_search: 'ROFPAVEM',
      name: 'Rosa (R) Foxy Pavement',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1920,
      chnn_stock_retail: 1920,
      sppl_order_minimum: 10,
      sppl_prcp: 2.445,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PEP23Z1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PEP23Z1R'
    },
    {
      status: 210,
      sku: 'PM0084500',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285208',
      rtl_batch_array: '6285208',
      name_search: 'ROGDHAST',
      name: 'Rosa (H) Gelbe Dagmar Hastrup',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.343,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5VS9E3LE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VS9E3LE'
    },
    {
      status: 210,
      sku: 'PM0084501',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285209',
      rtl_batch_array: '6285209',
      name_search: 'ROHANSA',
      name: "Rosa (R) 'Hansa'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZJNSG1H;273XXG8W',
      statusContent: 'dot',
      hash: 'YT3452ZT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT3452ZT'
    },
    {
      status: 210,
      sku: 'PM0084502',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285210',
      rtl_batch_array: '6285210',
      name_search: 'ROHANSAL',
      name: 'Rosa (R) Hansaland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1820,
      chnn_stock_retail: 1820,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VE8VG6J6;4BGZJH1N;BA6H44JS',
      statusContent: 'dot',
      hash: 'KXA77RTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXA77RTL'
    },
    {
      status: 210,
      sku: 'PM0084503',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285211',
      rtl_batch_array: '6285211',
      name_search: 'ROMHAMMA',
      name: "Rosa (R) 'Moje Hammarberg'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SGABAJNW',
      statusContent: 'dot',
      hash: 'YBCSLCGR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YBCSLCGR'
    },
    {
      status: 210,
      sku: 'PM0084504',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285212',
      rtl_batch_array: '6285212',
      name_search: 'ROSPPAVE',
      name: 'Rosa (R) Pink Pavement',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 695,
      chnn_stock_retail: 695,
      sppl_order_minimum: 10,
      sppl_prcp: 2.445,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9AT4VS31',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AT4VS31'
    },
    {
      status: 210,
      sku: 'PM0084505',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285213',
      rtl_batch_array: '6285213',
      name_search: 'ROPROADR',
      name: 'Rosa (R) Pink Roadrunner',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1120,
      chnn_stock_retail: 1120,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9PTDXBE9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PTDXBE9'
    },
    {
      status: 210,
      sku: 'PM0084506',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285214',
      rtl_batch_array: '6285214',
      name_search: 'ROPOLARE',
      name: 'Rosa (H) Polareis',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1400,
      chnn_stock_retail: 1400,
      sppl_order_minimum: 10,
      sppl_prcp: 2.241,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RD3SG4WG;53C4XH1R',
      statusContent: 'dot',
      hash: 'NGYDNLV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NGYDNLV6'
    },
    {
      status: 810,
      sku: 'PM0084507',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285215',
      rtl_batch_array: '6285215',
      name_search: 'RORROADR',
      name: 'Rosa (R) Romantic Roadrunner',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SLYPPWNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SLYPPWNJ'
    },
    {
      status: 210,
      sku: 'PM0084508',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285216',
      rtl_batch_array: '6285216',
      name_search: 'RORHAY',
      name: "Rosa (H) 'Roseraie de L'Ha\u00ff'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VGJ6WB74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGJ6WB74'
    },
    {
      status: 210,
      sku: 'PM0084509',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285217',
      rtl_batch_array: '6285217',
      name_search: 'RORMEER',
      name: "Rosa (R) 'Rotes Meer'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XBJ6SX2S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBJ6SX2S'
    },
    {
      status: 210,
      sku: 'PM0084510',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285218',
      rtl_batch_array: '6285218',
      name_search: 'RORPHAEN',
      name: "Rosa (R) 'Rotes Phaenomen'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1085,
      chnn_stock_retail: 1085,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1E19RSRY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1E19RSRY'
    },
    {
      status: 210,
      sku: 'PM0084511',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285219',
      rtl_batch_array: '6285219',
      name_search: 'RORUGOTI',
      name: 'Rosa rugotida',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NW9RT5NA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NW9RT5NA'
    },
    {
      status: 210,
      sku: 'PM0084512',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285220',
      rtl_batch_array: '6285220',
      name_search: 'ROSTRACK',
      name: 'Rosa (H) Short Track',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GYEV7V3B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYEV7V3B'
    },
    {
      status: 210,
      sku: 'PM0084513',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285221',
      rtl_batch_array: '6285221',
      name_search: 'ROSROADR',
      name: 'Rosa (R) Smart Roadrunner',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HNBE4XHB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HNBE4XHB'
    },
    {
      status: 210,
      sku: 'PM0084514',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285222',
      rtl_batch_array: '6285222',
      name_search: 'ROWPAVEM',
      name: 'Rosa (R) White Pavement',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1895,
      chnn_stock_retail: 1895,
      sppl_order_minimum: 10,
      sppl_prcp: 2.445,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BLHV3L2E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLHV3L2E'
    },
    {
      status: 210,
      sku: 'PM0084515',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285223',
      rtl_batch_array: '6285223',
      name_search: 'RONKEBU',
      name: "Rosa nitida 'Kebu'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '718C151G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '718C151G'
    },
    {
      status: 210,
      sku: 'PM0084516',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285224',
      rtl_batch_array: '6285224',
      name_search: 'ROTRIGIN',
      name: 'Rosa Trigintipetala',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'D1Y1DW3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D1Y1DW3Y'
    },
    {
      status: 210,
      sku: 'PM0084517',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285225',
      rtl_batch_array: '6285225',
      name_search: 'ROCHAMPL',
      name: "Rosa 'Champlain'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1850,
      chnn_stock_retail: 1850,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '67WWSN7J',
      statusContent: 'dot',
      hash: 'DJG136HA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DJG136HA'
    },
    {
      status: 210,
      sku: 'PM0084518',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285226',
      rtl_batch_array: '6285226',
      name_search: 'ROMONTAR',
      name: "Rosa 'De Montarville'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 650,
      chnn_stock_retail: 650,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DY5RG7W1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DY5RG7W1'
    },
    {
      status: 210,
      sku: 'PM0084519',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285228',
      rtl_batch_array: '6285228',
      name_search: 'ROBPURPL',
      name: 'Rosa (T) Big Purple',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 735,
      chnn_stock_retail: 735,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TKH1XCGP;8TZJVJKR',
      statusContent: 'dot',
      hash: 'STRS6AZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'STRS6AZ3'
    },
    {
      status: 210,
      sku: 'PM0084520',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285229',
      rtl_batch_array: '6285229',
      name_search: 'ROBLESSI',
      name: "Rosa (T) 'Blessings'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JPSYG8EZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPSYG8EZ'
    },
    {
      status: 810,
      sku: 'PM0084521',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285231',
      rtl_batch_array: '6285231',
      name_search: 'ROBOLCHO',
      name: 'Rosa (T) Bolchoi',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '24N72ZG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '24N72ZG9'
    },
    {
      status: 210,
      sku: 'PM0084522',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285232',
      rtl_batch_array: '6285232',
      name_search: 'ROCVICTO',
      name: 'Rosa (T) Caroline Victoria',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '45NYP3BL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '45NYP3BL'
    },
    {
      status: 810,
      sku: 'PM0084523',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285233',
      rtl_batch_array: '6285233',
      name_search: 'ROSCBEAU',
      name: 'Rosa (T) Chandos Beauty',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NKPSRZ1Z;84DHEZE1;Y1J3937X;E3HRBG73',
      statusContent: 'dot',
      hash: '4X77HX1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4X77HX1X'
    },
    {
      status: 210,
      sku: 'PM0084524',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285234',
      rtl_batch_array: '6285234',
      name_search: 'ROCHIPPE',
      name: 'Rosa (H) Chippendale',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NSK146L8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NSK146L8'
    },
    {
      status: 810,
      sku: 'PM0084525',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285235',
      rtl_batch_array: '6285235',
      name_search: 'ROCCOLOM',
      name: 'Rosa (T) Christophe Colombus',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJ46CC8B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJ46CC8B'
    },
    {
      status: 210,
      sku: 'PM0084526',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285236',
      rtl_batch_array: '6285236',
      name_search: 'ROCFASHI',
      name: 'Rosa (T) Crazy Fashion',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '93W5HJ62;KTL8DS34;KETA3557',
      statusContent: 'dot',
      hash: 'LVVWRTTP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVVWRTTP'
    },
    {
      status: 210,
      sku: 'PM0084527',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285237',
      rtl_batch_array: '6285237',
      name_search: 'RODBESS',
      name: "Rosa (T) 'Dainty Bess'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GZETBE3S;CN1J1Z3G',
      statusContent: 'dot',
      hash: 'KZJN65LY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZJN65LY'
    },
    {
      status: 210,
      sku: 'PM0084528',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285238',
      rtl_batch_array: '6285238',
      name_search: 'RODCOEUR',
      name: "Rosa (T) 'Dame de Coeur'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V3LLK6PZ;NP2NE3V1;KDL8PTVR;BGR7J1WJ;G5JA9ZNG;W1C4N3GL;2XN1B15Z',
      statusContent: 'dot',
      hash: 'HP113AD6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP113AD6'
    },
    {
      status: 210,
      sku: 'PM0084529',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285239',
      rtl_batch_array: '6285239',
      name_search: 'ROEPIAF',
      name: 'Rosa Edith Piaf',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4ERB91V1;XTC6N92R;RK3JJJZC;43E622GA',
      statusContent: 'dot',
      hash: 'G15YZ8BX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G15YZ8BX'
    },
    {
      status: 210,
      sku: 'PM0084530',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285241',
      rtl_batch_array: '6285241',
      name_search: 'ROEFRANC',
      name: "Rosa 'Enfant de France'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PAVSA3S3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PAVSA3S3'
    },
    {
      status: 910,
      sku: 'PM0084531',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ROEROTIK',
      name: "Rosa (T) 'Erotika'",
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      statusContent: 'dot',
      hash: 'PCYKX585',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PCYKX585'
    },
    {
      status: 810,
      sku: 'PM0077222',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353208',
      rtl_batch_array: '6353208',
      name_search: 'SAEVELIN',
      name: "Salvia 'Eveline'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N8VSBJYB',
      statusContent: 'dot',
      hash: 'A9HJ57WG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A9HJ57WG'
    },
    {
      status: 210,
      sku: 'PM0077229',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353410',
      rtl_batch_array: '6353410',
      name_search: 'SESMETEO',
      name: "Sedum spectabile 'Meteor'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 356,
      chnn_stock_retail: 356,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '212TVVP2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '212TVVP2'
    },
    {
      status: 210,
      sku: 'PM0077233',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353433',
      rtl_batch_array: '6353433',
      name_search: 'SEKSPINR',
      name: "Sempervivum 'Kramer's Spinrad'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'APRTP9K1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'APRTP9K1'
    },
    {
      status: 210,
      sku: 'PM0077223',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353211',
      rtl_batch_array: '6353211, 6319736',
      name_search: 'SALAVAND',
      name: 'Salvia lavandulifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 574,
      chnn_stock_retail: 649,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STDLCXNH;V1VE7AT5;NZCKHTRC;56D292JG;S63TR5XJ',
      statusContent: 'dot',
      hash: 'D4BAH5XE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4BAH5XE'
    },
    {
      status: 210,
      sku: 'PM0077226',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6349142',
      rtl_batch_array: '6349142, 6353232',
      name_search: 'PRDALBA',
      name: "Primula denticulata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 14028,
      chnn_stock_retail: 14464,
      sppl_order_minimum: 3,
      sppl_prcp: 0.575,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5S82ESGT;YL5NPN2R;Y1WKNAZH;SYKZELTJ;Z1P77WG9;AEGDGS1E;VY8PKJ38',
      statusContent: 'dot',
      hash: '1CVZEA19',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1CVZEA19'
    },
    {
      status: 210,
      sku: 'PM0077227',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 2,
      btch_active_retail: '6333301',
      rtl_batch_array: '6353255, 6333301',
      name_search: 'PUVPAPAG',
      name: "Pulsatilla vulgaris 'Papageno'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 1120,
      sppl_order_minimum: 3,
      sppl_prcp: 0.615,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S2JX6S48;SS51HX1Y;NH8Y7VEA;HG1WZPAA;8RS124ED;KDJ8D2ZL;K3KVC7EY;W79TJHPJ;BH392TN8;ZPT2PBPZ;X7ZPVSN7;H98T76H8;7V47ASLT;28N6471K;AEPT4W4P;JKB1S5AA',
      statusContent: 'dot',
      hash: 'DZ7LKLXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZ7LKLXC'
    },
    {
      status: 210,
      sku: 'PM0077228',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353407',
      rtl_batch_array: '6353407',
      name_search: 'SESCARME',
      name: "Sedum spectabile 'Carmen'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 483,
      chnn_stock_retail: 483,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5G5TGVTW;S6LH4ATZ;B2EYDLY1;8R8XNJSX',
      statusContent: 'dot',
      hash: 'L8J5DEVK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L8J5DEVK'
    },
    {
      status: 210,
      sku: 'PM0077230',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353416',
      rtl_batch_array: '6353416',
      name_search: 'SEDSVARI',
      name: "Sedum spurium 'Variegatum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 459,
      chnn_stock_retail: 459,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1TP1LBV9;X3PRB1PZ;5HARW7Z9;79YYEAB4;142ZD8KV;ZJJ224Z3;HVCPHZWV;ZWE754S6;KDTHJVTP;NLGYHC9W;DP7Y1H3R;GBZ9GWB8;AEK6VA16',
      statusContent: 'dot',
      hash: 'A5ZW2C1R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A5ZW2C1R'
    },
    {
      status: 210,
      sku: 'PM0077231',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353418',
      rtl_batch_array: '6353418',
      name_search: 'SETATLAN',
      name: 'Sedum takesimense Atlantis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 144,
      chnn_stock_retail: 144,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '928SE5NG;5ZTELBH2',
      statusContent: 'dot',
      hash: 'P5GVL9VA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P5GVL9VA'
    },
    {
      status: 210,
      sku: 'PM0084532',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285243',
      rtl_batch_array: '6285243',
      name_search: 'ROGSILK',
      name: 'Rosa (T) Golden Silk',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 135,
      chnn_stock_retail: 135,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V3XXEHAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3XXEHAL'
    },
    {
      status: 210,
      sku: 'PM0084533',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285244',
      rtl_batch_array: '6285244',
      name_search: 'ROGOSPEL',
      name: 'Rosa (T) Gospel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GCC4PR3N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GCC4PR3N'
    },
    {
      status: 810,
      sku: 'PM0084534',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285245',
      rtl_batch_array: '6285245',
      name_search: 'ROGAMORE',
      name: 'Rosa (T) Grande Amore',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NW77ENX1;KB7KNTX9',
      statusContent: 'dot',
      hash: 'VE2DHBEW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VE2DHBEW'
    },
    {
      status: 810,
      sku: 'PM0077224',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6345197',
      rtl_batch_array: '6353164, 6345197',
      name_search: 'PHEARDEN',
      name: "Phlox (P) 'Elizabeth Arden'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 71,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '77ARSX6L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '77ARSX6L'
    },
    {
      status: 210,
      sku: 'PM0077225',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353223',
      rtl_batch_array: '6353223',
      name_search: 'POFLAMBO',
      name: "Potentilla 'Flamboyant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 545,
      chnn_stock_retail: 545,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6TRY7J6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6TRY7J6'
    },
    {
      status: 210,
      sku: 'PM0077232',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353419',
      rtl_batch_array: '6353419',
      name_search: 'SETICHIE',
      name: "Sedum telephium 'Indian Chief'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 449,
      chnn_stock_retail: 449,
      sppl_order_minimum: 3,
      sppl_prcp: 0.987,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZBZBLGAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZBZBLGAY'
    },
    {
      status: 210,
      sku: 'PM0084535',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285246',
      rtl_batch_array: '6285246',
      name_search: 'ROGCLASS',
      name: 'Rosa (T) Grande Classe',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HSZAN95S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HSZAN95S'
    },
    {
      status: 210,
      sku: 'PM0084536',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285247',
      rtl_batch_array: '6285247',
      name_search: 'ROGGOLD',
      name: 'Rosa (K) Good as Gold',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing, Roses dwarf',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '592E3WD3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '592E3WD3'
    },
    {
      status: 210,
      sku: 'PM0084537',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285248',
      rtl_batch_array: '6285248',
      name_search: 'ROHELGA',
      name: 'Rosa (F) Helga',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1680,
      chnn_stock_retail: 1680,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'DC178HJ9;4D3C1XEN;CVE1AYWB;EKJ5H6V9;9P1NA2YJ;D9HNG9PY;6XHZJNWD',
      statusContent: 'dot',
      hash: '6Z34LE6D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z34LE6D'
    },
    {
      status: 210,
      sku: 'PM0084538',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285252',
      rtl_batch_array: '6285252',
      name_search: 'ROMAGALI',
      name: 'Rosa (F) Magali',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 125,
      chnn_stock_retail: 125,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '79B6GSZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '79B6GSZG'
    },
    {
      status: 210,
      sku: 'PM0084539',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285253',
      rtl_batch_array: '6285253',
      name_search: 'ROKORZUR',
      name: "Rosa (T) 'Korzuri'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VL6YE2NV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL6YE2NV'
    },
    {
      status: 810,
      sku: 'PM0084540',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285254',
      rtl_batch_array: '6285254',
      name_search: 'ROMETRO',
      name: 'Rosa (T) Metro',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZB7EK716',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZB7EK716'
    },
    {
      status: 810,
      sku: 'PM0084541',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285256',
      rtl_batch_array: '6285256',
      name_search: 'RODSECRE',
      name: "Rosa (T) 'Deep Secret'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4T8NJ82L;T5ZSWYDE;JKGR8RHE;S3RSWK7E',
      statusContent: 'dot',
      hash: 'KAK3WEKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAK3WEKG'
    },
    {
      status: 210,
      sku: 'PM0084542',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285257',
      rtl_batch_array: '6285257',
      name_search: 'ROMSCHWE',
      name: 'Rosa (T) Miss Schweiz',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 930,
      chnn_stock_retail: 930,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZASC7TLS',
      statusContent: 'dot',
      hash: 'HEH464PG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HEH464PG'
    },
    {
      status: 810,
      sku: 'PM0084543',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285258',
      rtl_batch_array: '6285258',
      name_search: 'RONHULOT',
      name: 'Rosa Nicolas Hulot',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BHGVXK7Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BHGVXK7Z'
    },
    {
      status: 910,
      sku: 'PM0084544',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RONBLUE',
      name: 'Rosa (T) Nil Blue',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      imageCore: '16AKZSKS',
      statusContent: 'dot',
      hash: 'HBN3YYW3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HBN3YYW3'
    },
    {
      status: 210,
      sku: 'PM0084545',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285260',
      rtl_batch_array: '6285260',
      name_search: 'RONOSTAL',
      name: 'Rosa (T) Nostalgie',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PCDGLN1X;BDZ6R29V;RSW3JKXW;DH15CRZP;6NY6SLJD;TNNECKWY;RE26JX38;TYSS13X7;AWALN945',
      statusContent: 'dot',
      hash: 'SDAAR9T3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SDAAR9T3'
    },
    {
      status: 210,
      sku: 'PM0084546',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285261',
      rtl_batch_array: '6285261',
      name_search: 'ROPROYAL',
      name: 'Rosa (T) Princess Royal',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 115,
      chnn_stock_retail: 115,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '819DJ6PA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '819DJ6PA'
    },
    {
      status: 210,
      sku: 'PM0084547',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285264',
      rtl_batch_array: '6285264',
      name_search: 'ROMEICAP',
      name: "Rosa (T) 'Meicapinal'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'NN5KVSBJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NN5KVSBJ'
    },
    {
      status: 210,
      sku: 'PM0084548',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285265',
      rtl_batch_array: '6285265',
      name_search: 'ROPENGLA',
      name: 'Rosa (T) Pride of England',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '47PPP1EL',
      statusContent: 'dot',
      hash: 'A6PDCNHC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6PDCNHC'
    },
    {
      status: 210,
      sku: 'PM0084549',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285266',
      rtl_batch_array: '6285266',
      name_search: 'ROPBALLE',
      name: "Rosa (T) 'Prima Ballerina'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BVTHCALC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVTHCALC'
    },
    {
      status: 210,
      sku: 'PM0084550',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285267',
      rtl_batch_array: '6285267',
      name_search: 'RORNOSTA',
      name: 'Rosa (T) Red Nostalgica',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GPKXE728',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GPKXE728'
    },
    {
      status: 210,
      sku: 'PM0084551',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285268',
      rtl_batch_array: '6285268',
      name_search: 'ROTANANI',
      name: "Rosa (T) 'Tananilov'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BEHH6XCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BEHH6XCL'
    },
    {
      status: 210,
      sku: 'PM0084552',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285269',
      rtl_batch_array: '6285269',
      name_search: 'RORPHILH',
      name: 'Rosa (T) Royal Philharmonic',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9YJ4NZV1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9YJ4NZV1'
    },
    {
      status: 210,
      sku: 'PM0084553',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285271',
      rtl_batch_array: '6285271',
      name_search: 'ROSKNEIP',
      name: 'Rosa (T) Sebastian Kneipp',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C7Z58KA1;6YDAE58T',
      statusContent: 'dot',
      hash: 'LZTDTTRN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZTDTTRN'
    },
    {
      status: 810,
      sku: 'PM0084554',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285272',
      rtl_batch_array: '6285272',
      name_search: 'ROSJUBIL',
      name: "Rosa (T) 'Silver Jubilee'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RT97ES5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RT97ES5A'
    },
    {
      status: 210,
      sku: 'PM0084555',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285273',
      rtl_batch_array: '6285273',
      name_search: 'ROT2003',
      name: 'Rosa (F) Tequila 2003',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 520,
      chnn_stock_retail: 520,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T9TLJ2BC;8846TNSY;6PLG7J9E;9ATSA855',
      statusContent: 'dot',
      hash: 'J2K9LALC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2K9LALC'
    },
    {
      status: 210,
      sku: 'PM0084556',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285275',
      rtl_batch_array: '6285275',
      name_search: 'ROTLOVE',
      name: 'Rosa (T) True Love',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6RKNPEP7;RGXVWE5P;YWEZ26JT;PBNA6CVT',
      statusContent: 'dot',
      hash: 'ELGT3KVT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELGT3KVT'
    },
    {
      status: 810,
      sku: 'PM0084557',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285276',
      rtl_batch_array: '6285276',
      name_search: 'ROVHUGO',
      name: "Rosa 'Victor Hugo'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 95,
      chnn_stock_retail: 95,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BT4P1JR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BT4P1JR2'
    },
    {
      status: 210,
      sku: 'PM0084558',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285277',
      rtl_batch_array: '6285277',
      name_search: 'ROVOYAGE',
      name: "Rosa (T) 'Voyage'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DRX3CZ9C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DRX3CZ9C'
    },
    {
      status: 210,
      sku: 'PM0084559',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285278',
      rtl_batch_array: '6285278',
      name_search: 'ROWSYMPH',
      name: "Rosa (T) 'White Symphonie'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TH1CLEVN',
      statusContent: 'dot',
      hash: 'BSBNXX6X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BSBNXX6X'
    },
    {
      status: 210,
      sku: 'PM0084560',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285279',
      rtl_batch_array: '6285279',
      name_search: 'ROBROMAN',
      name: 'Rosa (F) Baby Romantica',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 10,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E38NEAH9;7B4XW5K3;72SWA99G',
      statusContent: 'dot',
      hash: '2JYVWX4X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JYVWX4X'
    },
    {
      status: 810,
      sku: 'PM0084561',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285282',
      rtl_batch_array: '6285282',
      name_search: 'ROJFESTI',
      name: 'Rosa Jazz Festival',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_order_minimum: 10,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHCT6VSW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHCT6VSW'
    },
    {
      status: 210,
      sku: 'PM0084562',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285283',
      rtl_batch_array: '6285283',
      name_search: 'ROLDVINC',
      name: 'Rosa (T) Leonardo da Vinci',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1510,
      chnn_stock_retail: 1510,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7CB9SZW;4WC1L1NJ;HRZ8C1H5;DD839X3D;P3VLNHC7;SWHB6C21;77X3A4VR;9ATCGWZX;2BJSNZBG',
      statusContent: 'dot',
      hash: '7SBXZR5B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SBXZR5B'
    },
    {
      status: 210,
      sku: 'PM0084563',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285284',
      rtl_batch_array: '6285284',
      name_search: 'ROMCURIE',
      name: 'Rosa (F) Marie Curie',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HKC1W26X;R3X5Y5PN;474764ZY;YACGDLW6',
      statusContent: 'dot',
      hash: '8S3KX2A5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8S3KX2A5'
    },
    {
      status: 810,
      sku: 'PM0084564',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285287',
      rtl_batch_array: '6285287',
      name_search: 'ROSROMAN',
      name: 'Rosa (P) Sunlight Romantica',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '954SPY6E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '954SPY6E'
    },
    {
      status: 210,
      sku: 'PM0084565',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285288',
      rtl_batch_array: '6285288',
      name_search: 'ROBMEILO',
      name: 'Rosa (P) Bony Meilove',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZLNJLH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZLNJLH8'
    },
    {
      status: 210,
      sku: 'PM0084566',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285289',
      rtl_batch_array: '6285289',
      name_search: 'ROOMEILO',
      name: "Rosa (F) 'Orange Meilove'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 910,
      chnn_stock_retail: 910,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V3K6XYL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V3K6XYL3'
    },
    {
      status: 210,
      sku: 'PM0084567',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285290',
      rtl_batch_array: '6285290',
      name_search: 'RORMEILO',
      name: "Rosa (H) 'Rose Meilove'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1960,
      chnn_stock_retail: 1960,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G7852RTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G7852RTH'
    },
    {
      status: 210,
      sku: 'PM0084568',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285291',
      rtl_batch_array: '6285291',
      name_search: 'ROWMEILO',
      name: "Rosa 'White Meilove'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LX6LSC8R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LX6LSC8R'
    },
    {
      status: 210,
      sku: 'PM0084569',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285292',
      rtl_batch_array: '6285292',
      name_search: 'ROYMEILO',
      name: 'Rosa Yellow Meilove',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VJY5X5BH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VJY5X5BH'
    },
    {
      status: 810,
      sku: 'PM0084570',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285296',
      rtl_batch_array: '6285296',
      name_search: 'ROGLENNA',
      name: "Rosa (T) 'Graf Lennart'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LXVEJHN8',
      statusContent: 'dot',
      hash: '8SELSNRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8SELSNRG'
    },
    {
      status: 210,
      sku: 'PM0084571',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285297',
      rtl_batch_array: '6285297',
      name_search: 'ROJPREVE',
      name: 'Rosa Jacques Prevert',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BP3SNC6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BP3SNC6P'
    },
    {
      status: 210,
      sku: 'PM0084572',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285298',
      rtl_batch_array: '6285298',
      name_search: 'ROLRENAU',
      name: 'Rosa (T) Line Renaud',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1310,
      chnn_stock_retail: 1310,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '64YBB8KP;BGDD3PLB;5E87HP9B;V6797DZ4;J4VLLWSN',
      statusContent: 'dot',
      hash: '72KEV36W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72KEV36W'
    },
    {
      status: 810,
      sku: 'PM0084573',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285299',
      rtl_batch_array: '6285299',
      name_search: 'ROMPOLO',
      name: 'Rosa (T) Marco Polo',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HRS2D24D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HRS2D24D'
    },
    {
      status: 810,
      sku: 'PM0084574',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285300',
      rtl_batch_array: '6285300',
      name_search: 'ROPOKER',
      name: 'Rosa (F) Poker',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BWT9KCLR',
      statusContent: 'dot',
      hash: 'AN9L5J48',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AN9L5J48'
    },
    {
      status: 810,
      sku: 'PM0084575',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285301',
      rtl_batch_array: '6285301',
      name_search: 'ROPJARDI',
      name: 'Rosa (T) Prince Jardinier',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CZWJXV27;2JGTE8B8;P5CD8T78',
      statusContent: 'dot',
      hash: '5KBT18R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5KBT18R7'
    },
    {
      status: 810,
      sku: 'PM0084576',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285302',
      rtl_batch_array: '6285302',
      name_search: 'ROSAMROS',
      name: 'Rosa (T) The McCartney Rose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AH62Z6JZ;68KW7VE8;VJZ716Y5;9RXV7JD7;D948HHD4;LB3N1TJ4',
      statusContent: 'dot',
      hash: '5CCAJNZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CCAJNZG'
    },
    {
      status: 210,
      sku: 'PM0084577',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285303',
      rtl_batch_array: '6285303',
      name_search: 'ROWINSCH',
      name: 'Rosa (T) Winschoten',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJ2JP493',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJ2JP493'
    },
    {
      status: 210,
      sku: 'PM0084578',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285305',
      rtl_batch_array: '6285305',
      name_search: 'ROSLROSE',
      name: "Rosa Lion's Rose",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 430,
      chnn_stock_retail: 430,
      sppl_order_minimum: 10,
      sppl_prcp: 3.618,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X839HZBZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X839HZBZ'
    },
    {
      status: 210,
      sku: 'PM0084579',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285306',
      rtl_batch_array: '6285306',
      name_search: 'RONOVALI',
      name: 'Rosa (F) Novalis',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 290,
      chnn_stock_retail: 290,
      sppl_order_minimum: 10,
      sppl_prcp: 3.618,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ET62K9WA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ET62K9WA'
    },
    {
      status: 810,
      sku: 'PM0084580',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285307',
      rtl_batch_array: '6285307',
      name_search: 'ROOROSEN',
      name: 'Rosa (F) Out of Rosenheim',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WP7TRTX3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WP7TRTX3'
    },
    {
      status: 810,
      sku: 'PM0084581',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285309',
      rtl_batch_array: '6285309',
      name_search: 'ROQHEART',
      name: 'Rosa (T) Queen of Hearts',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DA7KE7DC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DA7KE7DC'
    },
    {
      status: 210,
      sku: 'PM0084582',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285310',
      rtl_batch_array: '6285310',
      name_search: 'ROSSJUBI',
      name: 'Rosa Sangerh\u00e4user Jubil\u00e4umsrose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WA1WD3C5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WA1WD3C5'
    },
    {
      status: 210,
      sku: 'PM0084583',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285311',
      rtl_batch_array: '6285311',
      name_search: 'ROWELLEN',
      name: 'Rosa (H) Wellenspiel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KHHDK715',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KHHDK715'
    },
    {
      status: 210,
      sku: 'PM0084584',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285312',
      rtl_batch_array: '6285312',
      name_search: 'ROBCADFA',
      name: 'Rosa (H) Brother Cadfael',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5XJLYXES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5XJLYXES'
    },
    {
      status: 810,
      sku: 'PM0084585',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285314',
      rtl_batch_array: '6285314',
      name_search: 'ROEROSE',
      name: "Rosa (T) 'Eden Rose'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9PNYV4A9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PNYV4A9'
    },
    {
      status: 210,
      sku: 'PM0084586',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285315',
      rtl_batch_array: '6285315',
      name_search: 'ROGHERMI',
      name: 'Rosa (H) Gentle Hermione',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1745,
      chnn_stock_retail: 1745,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5Z19RJT8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5Z19RJT8'
    },
    {
      status: 210,
      sku: 'PM0084587',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285316',
      rtl_batch_array: '6285316',
      name_search: 'ROIMOGEN',
      name: 'Rosa (H) Imogen',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1310,
      chnn_stock_retail: 1310,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BYGPH8H1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BYGPH8H1'
    },
    {
      status: 210,
      sku: 'PM0084588',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285319',
      rtl_batch_array: '6285319',
      name_search: 'ROLANGEL',
      name: "Rosa (M) 'Little Angel'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '96Y34WE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96Y34WE4'
    },
    {
      status: 210,
      sku: 'PM0084589',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285320',
      rtl_batch_array: '6285320',
      name_search: 'ROMOLINE',
      name: 'Rosa (H) Molineux',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 325,
      chnn_stock_retail: 325,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R24T2552',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R24T2552'
    },
    {
      status: 210,
      sku: 'PM0084590',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285321',
      rtl_batch_array: '6285321',
      name_search: 'ROPSUNLI',
      name: 'Rosa (H) Port Sunlight',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 250,
      chnn_stock_retail: 250,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6W9KC4JZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6W9KC4JZ'
    },
    {
      status: 210,
      sku: 'PM0084591',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285323',
      rtl_batch_array: '6285323',
      name_search: 'ROPANNE',
      name: 'Rosa Princess Anne',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 1295,
      chnn_stock_retail: 1295,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1ZNHGD29;HCZVD72D;KV1G2WBX',
      statusContent: 'dot',
      hash: '87YAC316',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '87YAC316'
    },
    {
      status: 210,
      sku: 'PM0084592',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285324',
      rtl_batch_array: '6285324',
      name_search: 'RORDAHL',
      name: 'Rosa (H) Roald Dahl',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PE3VHKE1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PE3VHKE1'
    },
    {
      status: 210,
      sku: 'PM0084593',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285325',
      rtl_batch_array: '6285325',
      name_search: 'ROSJBETJ',
      name: 'Rosa (H) Sir John Betjeman',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 710,
      chnn_stock_retail: 710,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'THPRTC7S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'THPRTC7S'
    },
    {
      status: 210,
      sku: 'PM0084594',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285326',
      rtl_batch_array: '6285326',
      name_search: 'ROSHILL',
      name: 'Rosa (H) Strawberry Hill',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1165,
      chnn_stock_retail: 1165,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '248BSSJR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '248BSSJR'
    },
    {
      status: 210,
      sku: 'PM0084595',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285327',
      rtl_batch_array: '6285327',
      name_search: 'ROSSONG',
      name: 'Rosa (F) Sweet Song',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1025,
      chnn_stock_retail: 1025,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LDZEXA7B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LDZEXA7B'
    },
    {
      status: 210,
      sku: 'PM0084596',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285331',
      rtl_batch_array: '6285331',
      name_search: 'ROPILGRI',
      name: 'Rosa (H) The Pilgrim',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1E78WGD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1E78WGD4'
    },
    {
      status: 210,
      sku: 'PM0084597',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285332',
      rtl_batch_array: '6285332',
      name_search: 'ROTPWIFE',
      name: "Rosa (H) The Poet's Wife",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1230,
      chnn_stock_retail: 1230,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CL6G4Y3Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CL6G4Y3Y'
    },
    {
      status: 810,
      sku: 'PM0084598',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285333',
      rtl_batch_array: '6285333',
      name_search: 'ROHHUDSO',
      name: "Rosa (R) 'Henry Hudson'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VP2L1X8X;B999ZP9R;97ZTZE2S',
      statusContent: 'dot',
      hash: '16JE3LXJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '16JE3LXJ'
    },
    {
      status: 210,
      sku: 'PM0084599',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285334',
      rtl_batch_array: '6285334',
      name_search: 'ROSMBLUS',
      name: "Rosa 'Morden Blush'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 890,
      chnn_stock_retail: 890,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ER4J151H;BVYHH4RN;RZ99355V;4VW3HWKV;KX66W2WG;CRZRD2JW;S8Z4Z8H9',
      statusContent: 'dot',
      hash: 'C9S3B9S4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9S3B9S4'
    },
    {
      status: 810,
      sku: 'PM0084600',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285335',
      rtl_batch_array: '6285335',
      name_search: 'ROSWHEEL',
      name: "Rosa 'Seager Wheeler'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DDJPAN55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DDJPAN55'
    },
    {
      status: 810,
      sku: 'PM0084601',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285336',
      rtl_batch_array: '6285336',
      name_search: 'ROSADELI',
      name: "Rosa (T) 'Sweet Adeline'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1AN8BS18',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1AN8BS18'
    },
    {
      status: 210,
      sku: 'PM0084602',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285337',
      rtl_batch_array: '6285337',
      name_search: 'ROWSPALE',
      name: 'Rosa Whiter Shade of Pale',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 790,
      chnn_stock_retail: 790,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HSY4K84E;YEYGYTKX;BXE75R79;J6JZ7YVV',
      statusContent: 'dot',
      hash: '1ACR39C7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1ACR39C7'
    },
    {
      status: 210,
      sku: 'PM0084603',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285338',
      rtl_batch_array: '6285338',
      name_search: 'ROANNAPU',
      name: 'Rosa Annapurna',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XLZH6849;T7HC91VK;KEX718BC;VTS5E5T3;481GP223',
      statusContent: 'dot',
      hash: '72XC1LPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72XC1LPN'
    },
    {
      status: 210,
      sku: 'PM0084604',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285339',
      rtl_batch_array: '6285339',
      name_search: 'ROARTEMI',
      name: 'Rosa (H) Artemis',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1150,
      chnn_stock_retail: 1150,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GJRP6XBR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GJRP6XBR'
    },
    {
      status: 210,
      sku: 'PM0084605',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285340',
      rtl_batch_array: '6285340',
      name_search: 'ROARIMBA',
      name: 'Rosa Arthur Rimbaud',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECBNB1YE',
      statusContent: 'dot',
      hash: 'VT1LYHYY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VT1LYHYY'
    },
    {
      status: 210,
      sku: 'PM0084606',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285345',
      rtl_batch_array: '6285345',
      name_search: 'ROOMORSD',
      name: "Rosa (P) 'Orange Morsdag'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 310,
      chnn_stock_retail: 310,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LCBGD4BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LCBGD4BY'
    },
    {
      status: 210,
      sku: 'PM0084607',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285347',
      rtl_batch_array: '6285347',
      name_search: 'ROPMAIDE',
      name: "Rosa (F) 'Pink Maiden'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1090,
      chnn_stock_retail: 1090,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4E24E2L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4E24E2L4'
    },
    {
      status: 210,
      sku: 'PM0084608',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285349',
      rtl_batch_array: '6285349',
      name_search: 'ROQELIZA',
      name: "Rosa (F) 'Queen Elizabeth'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9T6273G8;YKH5YVJH;D2ZSW31D;WAAR744K;GAGX9GAG;TWCKBTXJ;BPYS46YG;LEGLH3L7;RKC5NETD;SDAS78WE;6HZL9VLD;K22C4J7C;BLWHZA9P;1WERDWL6;Y2ANGVB9;RZ2D5TZW',
      statusContent: 'dot',
      hash: 'RKAVB16R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RKAVB16R'
    },
    {
      status: 810,
      sku: 'PM0084609',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285350',
      rtl_batch_array: '6285350',
      name_search: 'ROREMEMB',
      name: 'Rosa (F) Remembrance',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8N47T263',
      statusContent: 'dot',
      hash: '19S7P6KL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '19S7P6KL'
    },
    {
      status: 810,
      sku: 'PM0084610',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285352',
      rtl_batch_array: '6285352',
      name_search: 'ROBELMON',
      name: 'Rosa (F) Belmonte',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KBZ7L964',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KBZ7L964'
    },
    {
      status: 210,
      sku: 'PM0084611',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285353',
      rtl_batch_array: '6285353',
      name_search: 'ROMORSDA',
      name: 'Rosa (P) Morsdag',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J5N23JZJ;1VL2Y1LY;TJLXZW24;DSC2AHP5;4ZKJXRTE;LHNT7KR1',
      statusContent: 'dot',
      hash: '9WP8ZKG9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WP8ZKG9'
    },
    {
      status: 210,
      sku: 'PM0084612',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285354',
      rtl_batch_array: '6285354',
      name_search: 'ROSWITTC',
      name: 'Rosa (F) Schneewittchen',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EXPW9HHA;DR6AVGVK;YGE4Y3BD',
      statusContent: 'dot',
      hash: '43CY8KWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '43CY8KWP'
    },
    {
      status: 810,
      sku: 'PM0084613',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285355',
      rtl_batch_array: '6285355',
      name_search: 'ROSKOBLE',
      name: 'Rosa (F) Sch\u00f6ne Koblenzerin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W79A5WP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W79A5WP8'
    },
    {
      status: 210,
      sku: 'PM0084614',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285356',
      rtl_batch_array: '6285356',
      name_search: 'ROSSEE',
      name: 'Rosa (F) Sch\u00f6ne vom See',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YCSTWD4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCSTWD4R'
    },
    {
      status: 210,
      sku: 'PM0084615',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285357',
      rtl_batch_array: '6285357',
      name_search: 'ROSIRIUS',
      name: 'Rosa (F) Sirius',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CS3XL42N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CS3XL42N'
    },
    {
      status: 210,
      sku: 'PM0084616',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285360',
      rtl_batch_array: '6285360',
      name_search: 'ROTORNAD',
      name: 'Rosa (F) Tornado',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8Z1P1NEH;SN3BK1YP;AJW2E3C3;7ENEZGTZ',
      statusContent: 'dot',
      hash: '6KNT9ZAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6KNT9ZAN'
    },
    {
      status: 210,
      sku: 'PM0084617',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285361',
      rtl_batch_array: '6285361',
      name_search: 'ROWETTRA',
      name: "Rosa (F) 'Wettra'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1750,
      chnn_stock_retail: 1750,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AZ1S1N5;8NESHERB',
      statusContent: 'dot',
      hash: 'W4CTXVA8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W4CTXVA8'
    },
    {
      status: 210,
      sku: 'PM0084618',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285362',
      rtl_batch_array: '6285362',
      name_search: 'ROWMORSD',
      name: "Rosa (P) 'White Morsdag'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KCXG6JJS;5XYLDE59;PZBZPPV4;7DENVHB7',
      statusContent: 'dot',
      hash: 'VGB5XBTZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VGB5XBTZ'
    },
    {
      status: 210,
      sku: 'PM0084619',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285363',
      rtl_batch_array: '6285363',
      name_search: 'ROWQELIZ',
      name: "Rosa (F) 'White Queen Elizabeth'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 880,
      chnn_stock_retail: 880,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LGWHCS7K;26417KJR;TKCRVGAD',
      statusContent: 'dot',
      hash: 'DHDXB7SB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DHDXB7SB'
    },
    {
      status: 210,
      sku: 'PM0084620',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285364',
      rtl_batch_array: '6285364',
      name_search: 'ROCFAIRY',
      name: 'Rosa (H) Crystal Fairy',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4LDBXC5X;881J1TKN;BABVTEEW',
      statusContent: 'dot',
      hash: 'VXZ9C629',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VXZ9C629'
    },
    {
      status: 210,
      sku: 'PM0084621',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285365',
      rtl_batch_array: '6285365',
      name_search: 'RODDOT',
      name: "Rosa hybrid (P) 'Dolly Dot'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XW8HDXJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XW8HDXJE'
    },
    {
      status: 210,
      sku: 'PM0084622',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285366',
      rtl_batch_array: '6285366',
      name_search: 'ROFPRINC',
      name: 'Rosa (P) Fairy Princess',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '85G5JEXS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '85G5JEXS'
    },
    {
      status: 210,
      sku: 'PM0084623',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285367',
      rtl_batch_array: '6285367',
      name_search: 'ROLFAIRY',
      name: "Rosa 'Lucky Fairy'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1950,
      chnn_stock_retail: 1950,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P2HJD9L3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P2HJD9L3'
    },
    {
      status: 810,
      sku: 'PM0084624',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285368',
      rtl_batch_array: '6285368',
      name_search: 'RORFAIRY',
      name: "Rosa (P) 'Rote The Fairy'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXJZ5HP5',
      statusContent: 'dot',
      hash: '6CAGL4YJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CAGL4YJ'
    },
    {
      status: 210,
      sku: 'PM0084625',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285369',
      rtl_batch_array: '6285369',
      name_search: 'ROSDREAM',
      name: 'Rosa (F) Sweet Dream',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CJB3CWA9;1L4B44ZP;51R9WJ4K;D1TL4TPJ',
      statusContent: 'dot',
      hash: '5VGBKDCK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5VGBKDCK'
    },
    {
      status: 210,
      sku: 'PM0084626',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285370',
      rtl_batch_array: '6285370',
      name_search: 'ROTOPOLI',
      name: 'Rosa (F) Topolina',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 165,
      chnn_stock_retail: 165,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B88WGECS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B88WGECS'
    },
    {
      status: 810,
      sku: 'PM0084627',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285371',
      rtl_batch_array: '6285371',
      name_search: 'ROALBERT',
      name: "Rosa (K) 'Albertine'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 10,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9NPYZ8JS;5EAG416B;Y2WRZLA3',
      statusContent: 'dot',
      hash: '8J3NZPS8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8J3NZPS8'
    },
    {
      status: 810,
      sku: 'PM0084628',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285372',
      rtl_batch_array: '6285372',
      name_search: 'ROA89',
      name: 'Rosa (K) Antike 89',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BLNYDA4C',
      statusContent: 'dot',
      hash: 'BZ63V5A2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ63V5A2'
    },
    {
      status: 210,
      sku: 'PM0084629',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285373',
      rtl_batch_array: '6285373',
      name_search: 'ROBBAY',
      name: "Rosa (K) 'Bantry Bay'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8R23H931;WSJSV8DB;419BLRTA;G28A726E;51RRRJWL;T5NEXH68;78YL26JV',
      statusContent: 'dot',
      hash: '2NTBT961',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2NTBT961'
    },
    {
      status: 210,
      sku: 'PM0084630',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285374',
      rtl_batch_array: '6285374',
      name_search: 'ROBNOISE',
      name: "Rosa (K) 'Blush Noisette'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 505,
      chnn_stock_retail: 505,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '81THGXPD;ZCX4WTRW',
      statusContent: 'dot',
      hash: '15YCRLJB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15YCRLJB'
    },
    {
      status: 810,
      sku: 'PM0084631',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285375',
      rtl_batch_array: '6285375',
      name_search: 'ROSPROYA',
      name: 'Rosa (T) Parfum Royal',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'VCL6WAV1;V5ESAEL9;GNC16E8W;AKPP6657;5862VA39;N7G6RJLG;ARXX24HV;66ABXT1K',
      statusContent: 'dot',
      hash: '54TCY91T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '54TCY91T'
    },
    {
      status: 210,
      sku: 'PM0084632',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285376',
      rtl_batch_array: '6285376',
      name_search: 'ROCRFLAM',
      name: 'Rosa (K) Climbing Red Flame',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KNHTZ5TA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KNHTZ5TA'
    },
    {
      status: 210,
      sku: 'PM0084633',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285377',
      rtl_batch_array: '6285377',
      name_search: 'ROCSCHNE',
      name: "Rosa (K) 'Climbing Schneewittchen'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E41NJC39',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E41NJC39'
    },
    {
      status: 210,
      sku: 'PM0084634',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285378',
      rtl_batch_array: '6285378',
      name_search: 'ROSORBET',
      name: 'Rosa (K) Sorbet',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8J4LP21R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8J4LP21R'
    },
    {
      status: 210,
      sku: 'PM0084635',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285379',
      rtl_batch_array: '6285379',
      name_search: 'ROCSMALM',
      name: "Rosa (K) 'Climbing Souvenir de la Malmaison'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AXEXV8SJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AXEXV8SJ'
    },
    {
      status: 910,
      sku: 'PM0084636',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285380',
      rtl_batch_array: '6285380',
      name_search: 'ROCMATIN',
      name: 'Rosa (H) Clair Matin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 10,
      sppl_prcp: 3.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WPC1BKSW;5XYWWYJL',
      statusContent: 'dot',
      hash: '6Z9KB698',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6Z9KB698'
    },
    {
      status: 210,
      sku: 'PM0084637',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285381',
      rtl_batch_array: '6285381',
      name_search: 'ROCWHITE',
      name: "Rosa (K) 'Colonial White'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8VJ3DJEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8VJ3DJEC'
    },
    {
      status: 210,
      sku: 'PM0084638',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285382',
      rtl_batch_array: '6285382',
      name_search: 'ROCOMPAS',
      name: "Rosa (K) 'Compassion'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PTVGSN85;4G2WG292;H6ZN8CX4;NCX6J8DY;A7ZAZVYG',
      statusContent: 'dot',
      hash: 'SZW86JHA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZW86JHA'
    },
    {
      status: 210,
      sku: 'PM0077244',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353564',
      rtl_batch_array: '6353564, 6319767',
      name_search: 'VESICICL',
      name: "Veronica spicata 'Icicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 874,
      chnn_stock_retail: 1349,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '43YVGBXT;AZTL1NTD',
      statusContent: 'dot',
      hash: 'X7WEZEED',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X7WEZEED'
    },
    {
      status: 210,
      sku: 'PM0077245',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301857',
      rtl_batch_array: '6301857, 6353458',
      name_search: 'SPSIBIRI',
      name: 'Spodiopogon sibiricus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 186,
      chnn_stock_retail: 1535,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8615Y2KH;XP2SRA3C',
      statusContent: 'dot',
      hash: 'JTD6E2YH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTD6E2YH'
    },
    {
      status: 210,
      sku: 'PM0016916',
      core_name: 'Plant',
      sppl_ean: '8720349467525',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6034169',
      rtl_batch_array: '6034169, 4773424, 6301357',
      name_search: 'ACHWLIPS',
      name: "Acanthus hungaricus 'White Lips'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 245,
      chnn_stock_retail: 597,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7R2GRTYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R2GRTYX'
    },
    {
      status: 810,
      sku: 'PM0084639',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285384',
      rtl_batch_array: '6285384',
      name_search: 'ROGDIJON',
      name: "Rosa (K) 'Gloire de Dijon'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V9KLZ59R;89RYZ3DY;7ZKCKSWT',
      statusContent: 'dot',
      hash: 'D7BTK1PH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7BTK1PH'
    },
    {
      status: 210,
      sku: 'PM0084640',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285386',
      rtl_batch_array: '6285386',
      name_search: 'ROGHOTEL',
      name: 'Rosa (K) Grand Hotel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WT8CN9L2;WT1ATHZL;BP2PTGW6;BEBCJS75',
      statusContent: 'dot',
      hash: '17RWT88C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17RWT88C'
    },
    {
      status: 210,
      sku: 'PM0077237',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353445',
      rtl_batch_array: '6353445',
      name_search: 'SIRBOUQU',
      name: "Sidalcea 'Rose Bouquet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 359,
      chnn_stock_retail: 359,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S2B6T2Z8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S2B6T2Z8'
    },
    {
      status: 210,
      sku: 'PM0077238',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353338',
      rtl_batch_array: '6353338',
      name_search: 'SAPDELIG',
      name: "Salvia 'Pink Delight'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.745,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V5GV1TAN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5GV1TAN'
    },
    {
      status: 210,
      sku: 'PM0077243',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353560',
      rtl_batch_array: '6353560',
      name_search: 'VELZOE',
      name: "Veronica longifolia 'Zo\u00eb'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XBKEDTLL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XBKEDTLL'
    },
    {
      status: 210,
      sku: 'PM0084641',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285387',
      rtl_batch_array: '6285387',
      name_search: 'ROHEIDEL',
      name: 'Rosa (K) Heidelberg',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CY3NRAST;P53GE2A2;G758AGYA;AA3W2JPH;77JJH9HD;WR6AWLA8;1RVKA1CK',
      statusContent: 'dot',
      hash: '6PSWAXXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PSWAXXV'
    },
    {
      status: 210,
      sku: 'PM0084642',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285388',
      rtl_batch_array: '6285388',
      name_search: 'ROHELLA',
      name: 'Rosa (K) Hella',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 10,
      sppl_prcp: 3.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8853S71A;G13WAXLT;D1CWXS71;R9LVL1LJ',
      statusContent: 'dot',
      hash: 'BGZ2HTWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGZ2HTWS'
    },
    {
      status: 810,
      sku: 'PM0084643',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285389',
      rtl_batch_array: '6285389',
      name_search: 'ROJASMIN',
      name: 'Rosa Jasmina',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 10,
      sppl_prcp: 3.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YC3CAYRD;59VEDYPL;1W1SWAYS',
      statusContent: 'dot',
      hash: 'CVJ3A2BE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVJ3A2BE'
    },
    {
      status: 810,
      sku: 'PM0084644',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285390',
      rtl_batch_array: '6285390',
      name_search: 'ROJRENAI',
      name: 'Rosa (H) Julia Renaissance',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 3.363,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J1V11J42;N2XBHRX5;5PRBYBEY',
      statusContent: 'dot',
      hash: '9AXLX3YD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9AXLX3YD'
    },
    {
      status: 210,
      sku: 'PM0012066',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353322',
      rtl_batch_array: '6353322',
      name_search: 'SANBBSNO',
      name: "Salvia nemorosa 'Bumblesnow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1CLVXGXG;1WR68AZV;58K48PAG;WXK9BH1D;PY5YNXAC',
      statusContent: 'dot',
      hash: '2ND31STA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ND31STA'
    },
    {
      status: 910,
      sku: 'PM0018998',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LUGPINK',
      name: "Lupinus 'Gallery Pink'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      imageCore:
        '9B1KH9S9;E272T6WK;X72BPDTK;TK53SVH2;DWHVE5K4;L65527HR;596EL16Z;CACX2TPK;7JJZS667;7ZNBS349;6HLNRK9N',
      statusContent: 'dot',
      hash: '4YL5PWG9',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '4YL5PWG9'
    },
    {
      status: 210,
      sku: 'PM0084645',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285391',
      rtl_batch_array: '6285391',
      name_search: 'ROKROYAL',
      name: 'Rosa (K) Kir Royal',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6DAA2BBY',
      statusContent: 'dot',
      hash: '9CPSGVR2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CPSGVR2'
    },
    {
      status: 210,
      sku: 'PM0025669',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 3,
      btch_active_retail: '6284539',
      rtl_batch_array: '2681758, 6284539, 6252712',
      name_search: 'PAVSQUAW',
      name: "Panicum virgatum 'Squaw'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 657,
      chnn_stock_retail: 1468,
      sppl_prcp: 1.621,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7AWJLED;SB98VJK7;TVG6B63T;LC6E5A29',
      statusContent: 'dot',
      hash: '4Z2N2R2T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Z2N2R2T'
    },
    {
      status: 810,
      sku: 'PM0084646',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285392',
      rtl_batch_array: '6285392',
      name_search: 'ROLAGUNA',
      name: 'Rosa (K) Laguna',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 10,
      sppl_prcp: 3.465,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '47ZAJX6L;TAPTDH7E;DPH2BHBP;E2ZKCXVG',
      statusContent: 'dot',
      hash: 'Z689D77A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z689D77A'
    },
    {
      status: 210,
      sku: 'PM0020096',
      core_name: 'Plant',
      sppl_ean: '8720349469185',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '4662989',
      rtl_batch_array: '4662989, 6301778, 6161925',
      name_search: 'PEAJCALI',
      name: "Persicaria amplexicaulis 'JS Caliente'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 147,
      chnn_stock_retail: 1905,
      sppl_order_minimum: 3,
      sppl_prcp: 1.492,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8X4XVEL6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8X4XVEL6'
    },
    {
      status: 210,
      sku: 'PM0023610',
      core_name: 'Plant',
      sppl_ean: '8720626346314',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6015028',
      rtl_batch_array: '6015028',
      name_search: 'EUARAINB',
      name: "Euphorbia 'Ascot Rainbow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 339,
      chnn_stock_retail: 339,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WXW5535K;HGWR6NJV;Z6THLVBS;TLHDE497;PRZ6VB4W;JEN87NZ4;AH7LK741;KA7BWCKE;PH2D1KER;HAXHG175;PTDWKR2C;66LARD52;JTXL5GXY;V5123AAZ;DGP7YTZD;98GWZNA9;E3VL86PT;GD8DKANB;D7B81B8K;7D2B91LX;Y7LSV513;HB32R7CB',
      statusContent: 'dot',
      hash: '8ZSTRX69',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8ZSTRX69'
    },
    {
      status: 210,
      sku: 'PM0021236',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 4,
      btch_active_retail: '6161958',
      rtl_batch_array: '6161958, 6238829, 6383383, 6348968',
      name_search: 'RUFLGOLD',
      name: "Rudbeckia fulgida 'Little Goldstar'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 6000,
      chnn_stock_retail: 9023,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PB4DPXA7;8HV1DNNT;PSETJCSC;P1RH3SBH;L4JJT18D;YALHA37S;5YN7TD2A;V1APY7VT;XJC7K5BT;JJ9ZEKZL',
      statusContent: 'dot',
      hash: '9J61WY41',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9J61WY41'
    },
    {
      status: 210,
      sku: 'PM0084647',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285394',
      rtl_batch_array: '6285394',
      name_search: 'ROACARRI',
      name: "Rosa (K) 'Mme Alfred Carri\u00e8re'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4W761PL8;NG4ZWAHJ;RZRYY69C;T774A7PD;BGBEYDPT;2S1S13H5;ZZ45ZZSY;T83E3E5R;5L33RDB6',
      statusContent: 'dot',
      hash: 'DE3ZLSHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DE3ZLSHW'
    },
    {
      status: 210,
      sku: 'PM0084648',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285395',
      rtl_batch_array: '6285395',
      name_search: 'RONDAWN',
      name: "Rosa (K) 'New Dawn'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XHEZDZV9;HNZLTYL8;KN6JAKAV;1TEELKSS',
      statusContent: 'dot',
      hash: 'GGVRXT53',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGVRXT53'
    },
    {
      status: 210,
      sku: 'PM0084649',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285396',
      rtl_batch_array: '6285396',
      name_search: 'RONDREAM',
      name: 'Rosa (K) New Dreams',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W241Z6B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W241Z6B7'
    },
    {
      status: 210,
      sku: 'PM0016489',
      core_name: 'Plant',
      sppl_ean: '8720353085050',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '5364199',
      rtl_batch_array: '6181361, 5364199',
      name_search: 'ACMSSEDU',
      name: "Achillea millefolium 'Sunny Seduction'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 461,
      chnn_stock_retail: 664,
      sppl_order_minimum: 3,
      sppl_prcp: 0.997,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A8A2LJX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8A2LJX8'
    },
    {
      status: 210,
      sku: 'PM0024326',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6231152',
      rtl_batch_array: '6192807, 6231152, 6370370',
      name_search: 'LAMSPINK',
      name: "Lamium maculatum 'Shell Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 1217,
      sppl_order_minimum: 3,
      sppl_prcp: 0.577,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LYV72ZET;EJT2AX3G;G73PN69K;AR5ZERCA',
      statusContent: 'dot',
      hash: 'C88W5GLN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C88W5GLN'
    },
    {
      status: 910,
      sku: 'PM0020639',
      core_name: 'Plant',
      sppl_ean: '8720626334175',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '5607182',
      rtl_batch_array: '5607182',
      name_search: 'ALMONTAN',
      name: 'Alyssum montanum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_order_minimum: 3,
      sppl_prcp: 0.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VL7JHTGH;YAG4WRZ3;KYAL9VZG;R27VZ5XD;KD5GKW29;H8HRBNSV',
      statusContent: 'dot',
      hash: 'D4AL1L9P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D4AL1L9P'
    },
    {
      status: 210,
      sku: 'PM0021352',
      core_name: 'Plant',
      sppl_ean: '8720349438891',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '5375708',
      rtl_batch_array: '5375708',
      name_search: 'POREPTAN',
      name: 'Polemonium reptans',
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1452,
      chnn_stock_retail: 1452,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '65GBC142',
      imageBatch: 'S33BC384',
      statusContent: 'dot',
      hash: 'JPHWCZGE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JPHWCZGE'
    },
    {
      status: 910,
      sku: 'PM0005944',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 6,
      btch_active_retail: '6126330',
      rtl_batch_array: '6113111, 6126330, 6161767, 4897317, 6333262, 6243010',
      name_search: 'HEPPUDDI',
      name: "Heuchera 'Plum Pudding'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 9,
      chnn_stock_retail: 3961,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZEWYWHJ;KYBS9736;5DP65PX3',
      statusContent: 'dot',
      hash: 'PJL7973D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJL7973D'
    },
    {
      status: 210,
      sku: 'PM0014412',
      core_name: 'Plant',
      sppl_ean: '8720349412082',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 2,
      btch_active_retail: '4264520',
      rtl_batch_array: '4264520, 6336098',
      name_search: 'CAOEVERI',
      name: "Carex oshimensis 'Everillo'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 325,
      chnn_stock_retail: 1921,
      sppl_prcp: 2.268,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V56NDZA1;14BTWSPK;GYJ6D49L;CS4EYZDJ;Y5ZY5GR8;2379TG2W;JWS9HHXC;JLXPTH2P;KZ9L7A9S;67879P5Y',
      imageBatch: 'XN8KHE8B',
      statusContent: 'dot',
      hash: 'SS4Y72AS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SS4Y72AS'
    },
    {
      status: 210,
      sku: 'PM0084650',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285397',
      rtl_batch_array: '6285397',
      name_search: 'ROOARMS',
      name: 'Rosa (K) Open Arms',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing, Roses dwarf',
      sppl_stock_available: 1095,
      chnn_stock_retail: 1095,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2NRJTK79',
      statusContent: 'dot',
      hash: '896N793L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '896N793L'
    },
    {
      status: 810,
      sku: 'PM0084651',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285398',
      rtl_batch_array: '6285398',
      name_search: 'ROPRIGGE',
      name: "Rosa (K) 'Parkdirektor Riggers'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '567EA69N;1XAXHC4H;65B1PK9A',
      statusContent: 'dot',
      hash: '5T6WR54P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5T6WR54P'
    },
    {
      status: 210,
      sku: 'PM0084652',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285399',
      rtl_batch_array: '6285399',
      name_search: 'ROPSCLIM',
      name: "Rosa (K) 'Paul's Scarlet Climber'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 730,
      chnn_stock_retail: 730,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H559J6TV;BJWCXR96;L1NYBCLV;BN8AN5DX;417HLWN5',
      statusContent: 'dot',
      hash: 'B4ZWETET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B4ZWETET'
    },
    {
      status: 210,
      sku: 'PM0019499',
      core_name: 'Plant',
      sppl_ean: '8720626348646',
      btch_manufacturer: 121,
      rtl_batch_array_total: 1,
      btch_active_retail: '5600630',
      rtl_batch_array: '5600630',
      name_search: 'GIBMARIK',
      name: "Ginkgo biloba 'Mariken'",
      sppl_size_code: '180STC20',
      rng_range_identifier: 'STE180C20',
      rng_range_description: 'Stem 180 cm C20',
      sppl_stock_available: 188,
      chnn_stock_retail: 188,
      sppl_prcp: 40.22,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCJ5WWEP;HNEG7WE2',
      imageBatch: 'LY39RG7A',
      statusContent: 'dot',
      hash: 'XG37H4CZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XG37H4CZ'
    },
    {
      status: 210,
      sku: 'PM0029190',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353467',
      rtl_batch_array: '6353467',
      name_search: 'STSCRUSH',
      name: "Stachys 'Summer Crush'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1057,
      chnn_stock_retail: 1057,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XV8SGE2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XV8SGE2K'
    },
    {
      status: 210,
      sku: 'PM0001975',
      core_name: 'Plant',
      sppl_ean: '8720626306271',
      btch_manufacturer: 159,
      rtl_batch_array_total: 1,
      btch_active_retail: '5495637',
      rtl_batch_array: '5495637',
      name_search: 'HYPEHARR',
      name: 'Hydrangea paniculata Early Harry',
      sppl_size_code: '040060C3',
      rng_range_identifier: 'H040060C3',
      rng_range_description: 'H40 cm 60 cm C3',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 3.122,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YR4XYPZG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YR4XYPZG'
    },
    {
      status: 210,
      sku: 'PM0084653',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285400',
      rtl_batch_array: '6285400',
      name_search: 'ROPLANE',
      name: 'Rosa (K) Penny Lane',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YXT9RSBL;WPHND2WV;NS3VLKP9;XYJ1BRWD',
      statusContent: 'dot',
      hash: 'JNELVY55',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNELVY55'
    },
    {
      status: 210,
      sku: 'PM0084654',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285401',
      rtl_batch_array: '6285401',
      name_search: 'ROPBLUE',
      name: 'Rosa Perrenial Blue',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 1510,
      chnn_stock_retail: 1510,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XS57AA2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XS57AA2G'
    },
    {
      status: 210,
      sku: 'PM0084655',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285403',
      rtl_batch_array: '6285403',
      name_search: 'ROSANTAN',
      name: 'Rosa (K) Santana',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 1210,
      chnn_stock_retail: 1210,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XC7ATHXL;HP3JG88B;ECAJV2Y8;GRKYTB3N;6B8YW2AZ;XEC8AX35',
      statusContent: 'dot',
      hash: 'JL8YLK1K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL8YLK1K'
    },
    {
      status: 810,
      sku: 'PM0084656',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285404',
      rtl_batch_array: '6285404',
      name_search: 'ROTJAZZ',
      name: "Rosa (RA) That's Jazz",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 20,
      chnn_stock_retail: 20,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HK766WTW;KHYSSP4Z;RDWYKH9B;KNBTPH98',
      statusContent: 'dot',
      hash: 'GTR9VAYP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GTR9VAYP'
    },
    {
      status: 210,
      sku: 'PM0084657',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285405',
      rtl_batch_array: '6285405',
      name_search: 'ROUKLOST',
      name: 'Rosa (K) Uetersener Klosterrose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 610,
      chnn_stock_retail: 610,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '99KJ3GA1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '99KJ3GA1'
    },
    {
      status: 210,
      sku: 'PM0084658',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285406',
      rtl_batch_array: '6285406',
      name_search: 'ROTRANQU',
      name: 'Rosa Tranquillity',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z191TWZW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z191TWZW'
    },
    {
      status: 210,
      sku: 'PM0084659',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285407',
      rtl_batch_array: '6285407',
      name_search: 'ROWCATHE',
      name: 'Rosa (H) Winchester Cathedral',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ERRLRYBD;6L17V2LS',
      statusContent: 'dot',
      hash: 'SNETNV7D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SNETNV7D'
    },
    {
      status: 210,
      sku: 'PM0018829',
      core_name: 'Plant',
      sppl_ean: '8720349418145',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '5592016',
      rtl_batch_array: '5592016, 5415375',
      name_search: 'HOBCADET',
      name: "Hosta 'Blue Cadet'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 4698,
      sppl_order_minimum: 1,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YH2J77RP;1W9A3ZK9;5PYJZVPC;BTLRAZPW',
      imageBatch: 'Y2DLEDR3',
      statusContent: 'dot',
      hash: 'YS6RN255',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS6RN255'
    },
    {
      status: 210,
      sku: 'PM0019678',
      core_name: 'Plant',
      sppl_ean: '8720664861824',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6136654',
      rtl_batch_array: '6136654',
      name_search: 'ERPBHOBB',
      name: "Eryngium planum 'Blue Hobbit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 209,
      chnn_stock_retail: 209,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TBBDND16;5BV3T94H;H37B9AKX;1738SXKZ;XT4CPYKP;1Z115JK4;VX5D7LGC;G9KK7E1G',
      statusContent: 'dot',
      hash: 'ZN425R9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZN425R9L'
    },
    {
      status: 210,
      sku: 'PM0084660',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285408',
      rtl_batch_array: '6285408',
      name_search: 'ROWOHALL',
      name: 'Rosa (H) Wollerton Old Hall',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 585,
      chnn_stock_retail: 585,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PW2T6ZGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PW2T6ZGK'
    },
    {
      status: 210,
      sku: 'PM0084661',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285411',
      rtl_batch_array: '6285411',
      name_search: 'ROALLOTR',
      name: 'Rosa (F) Allotria',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SSH8SKXW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SSH8SKXW'
    },
    {
      status: 210,
      sku: 'PM0084662',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285412',
      rtl_batch_array: '6285412',
      name_search: 'ROAMSTER',
      name: 'Rosa (F) Amsterdam',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 420,
      chnn_stock_retail: 420,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AD4XWYYS;RX9LD7SN;KA1SRZW9;XZ5CWSWL',
      statusContent: 'dot',
      hash: 'GYR24V8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYR24V8Y'
    },
    {
      status: 210,
      sku: 'PM0076621',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353296',
      rtl_batch_array: '6353296',
      name_search: 'SEFIRECR',
      name: "Sedum 'Firecracker'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 844,
      chnn_stock_retail: 844,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KAZ19ZJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KAZ19ZJT'
    },
    {
      status: 210,
      sku: 'PM0084663',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285413',
      rtl_batch_array: '6285413',
      name_search: 'ROAQELIZ',
      name: "Rosa (F) 'Apricot Queen Elizabeth'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 375,
      chnn_stock_retail: 375,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DLKJ7GEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLKJ7GEG'
    },
    {
      status: 210,
      sku: 'PM0077234',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353436',
      rtl_batch_array: '6353436',
      name_search: 'SEOCKERW',
      name: "Sempervivum 'Ockerwurz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V2TP8VGC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V2TP8VGC'
    },
    {
      status: 210,
      sku: 'PM0077235',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353440',
      rtl_batch_array: '6353440',
      name_search: 'SESBEAUT',
      name: "Sempervivum 'Spring Beauty'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1350,
      chnn_stock_retail: 1350,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '139212SG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '139212SG'
    },
    {
      status: 210,
      sku: 'PM0077236',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353441',
      rtl_batch_array: '6353441',
      name_search: 'SESPRITE',
      name: "Sempervivum 'Sprite'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 960,
      chnn_stock_retail: 960,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2BKL7H1Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2BKL7H1Z'
    },
    {
      status: 210,
      sku: 'PM0084664',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285414',
      rtl_batch_array: '6285414',
      name_search: 'ROBENGAL',
      name: 'Rosa (F) Bengali',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VSTV4N6H;4VLR4DTG;Y426R1RN',
      statusContent: 'dot',
      hash: 'J7B7T61X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7B7T61X'
    },
    {
      status: 810,
      sku: 'PM0077239',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353283',
      rtl_batch_array: '6353283',
      name_search: 'SCALTISS',
      name: 'Scutellaria altissima',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PP2644DE;ZR3GT14C;5WR5SVL5;CLPB1EW6;B9ESW49V;BRWSWAEJ;AB2YNH3R',
      statusContent: 'dot',
      hash: 'BVEAXLWG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BVEAXLWG'
    },
    {
      status: 210,
      sku: 'PM0077240',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353287',
      rtl_batch_array: '6353287',
      name_search: 'SEAAURAN',
      name: "Sedum aizoon 'Aurantiacum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 148,
      chnn_stock_retail: 148,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9B87TA26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B87TA26'
    },
    {
      status: 210,
      sku: 'PM0077241',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353554',
      rtl_batch_array: '6353554',
      name_search: 'VEGTWHIT',
      name: "Veronica gent. 'Tissington White'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N3GXH3ZE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N3GXH3ZE'
    },
    {
      status: 210,
      sku: 'PM0077242',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353555',
      rtl_batch_array: '6353555',
      name_search: 'VEKEMULA',
      name: 'Veronica kemulariae',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GLXGVYKZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GLXGVYKZ'
    },
    {
      status: 210,
      sku: 'PM0084665',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285415',
      rtl_batch_array: '6285415',
      name_search: 'ROBHARKN',
      name: 'Rosa (F) Betty Harkness',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 315,
      chnn_stock_retail: 315,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P2YA2HDC',
      statusContent: 'dot',
      hash: 'W78KTX43',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W78KTX43'
    },
    {
      status: 810,
      sku: 'PM0084666',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285416',
      rtl_batch_array: '6285416',
      name_search: 'ROBPRIOR',
      name: "Rosa (F) 'Betty Prior'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Y8ADWNXY',
      statusContent: 'dot',
      hash: '1K1XEWB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1K1XEWB2'
    },
    {
      status: 210,
      sku: 'PM0084667',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285417',
      rtl_batch_array: '6285417',
      name_search: 'ROBYOU',
      name: 'Rosa (F) Blue for You',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1800,
      chnn_stock_retail: 1800,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZT4ET6HY',
      statusContent: 'dot',
      hash: '6CBSV155',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CBSV155'
    },
    {
      status: 810,
      sku: 'PM0084668',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285421',
      rtl_batch_array: '6285421',
      name_search: 'ROGCAZNA',
      name: 'Rosa (F) Gala Charles Aznavour',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PESA72L4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PESA72L4'
    },
    {
      status: 210,
      sku: 'PM0084669',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285423',
      rtl_batch_array: '6285423',
      name_search: 'ROEGOING',
      name: 'Rosa (F) Easy Going',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 145,
      chnn_stock_retail: 145,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4639XSY6;TS98TPN5;6EJ92W2R;74T8SLA9;ACJ6712X',
      statusContent: 'dot',
      hash: 'X3D55Z2B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3D55Z2B'
    },
    {
      status: 210,
      sku: 'PM0084670',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285424',
      rtl_batch_array: '6285424',
      name_search: 'ROFRABEL',
      name: 'Rosa (F) Francois Rabelais',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7DXJBJ6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7DXJBJ6W'
    },
    {
      status: 210,
      sku: 'PM0084671',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285425',
      rtl_batch_array: '6285425',
      name_search: 'ROFRIESI',
      name: 'Rosa (F) Friesia',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'V5KV241H;Z13RWJCW;TG1B78Z9;PTKN31W6;A3WBAVYH;EK1YK2ES;B34SHNBK;8E782W69;PLJW1TPE;CAWLW7D7;K4BTNS4V;SNGH1LZ1',
      statusContent: 'dot',
      hash: '7ZH2AG5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7ZH2AG5K'
    },
    {
      status: 210,
      sku: 'PM0084672',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285426',
      rtl_batch_array: '6285426',
      name_search: 'ROGROSES',
      name: 'Rosa (F) Garden of Roses',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1060,
      chnn_stock_retail: 1060,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VC3WXYT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VC3WXYT4'
    },
    {
      status: 810,
      sku: 'PM0084673',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285428',
      rtl_batch_array: '6285428',
      name_search: 'ROKZEIME',
      name: "Rosa (P) 'Katharina Zeimet'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 65,
      chnn_stock_retail: 65,
      sppl_order_minimum: 10,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CVCD471Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVCD471Z'
    },
    {
      status: 810,
      sku: 'PM0084674',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285430',
      rtl_batch_array: '6285430',
      name_search: 'ROLDAWN',
      name: 'Rosa (F) Lady of the Dawn',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZLTZW3L9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZLTZW3L9'
    },
    {
      status: 810,
      sku: 'PM0084675',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285431',
      rtl_batch_array: '6285431',
      name_search: 'ROAIMANT',
      name: "Rosa (F) L'Aimant",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HZJHTWYZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HZJHTWYZ'
    },
    {
      status: 210,
      sku: 'PM0084676',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285432',
      rtl_batch_array: '6285432',
      name_search: 'ROLAMPIO',
      name: 'Rosa (F) Lampion',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KSAV2TSY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KSAV2TSY'
    },
    {
      status: 810,
      sku: 'PM0084677',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285434',
      rtl_batch_array: '6285434',
      name_search: 'ROMMERRI',
      name: 'Rosa (F) Margaret Merril',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'C57L8WT1;K38BBA58;H7WH84V1;B2XZ3XBL;DPW9YV84;LT35P1Z4;XN49TJL1;SCAJY5WC',
      statusContent: 'dot',
      hash: 'ASLJYNX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASLJYNX6'
    },
    {
      status: 210,
      sku: 'PM0084678',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285435',
      rtl_batch_array: '6285435',
      name_search: 'ROSMMATH',
      name: 'Rosa (F) Mireille Mathieu',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 10,
      sppl_prcp: 3.618,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YELCXNT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YELCXNT5'
    },
    {
      status: 210,
      sku: 'PM0084679',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285436',
      rtl_batch_array: '6285436',
      name_search: 'ROMARIAN',
      name: 'Rosa (F) Mariandel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 540,
      chnn_stock_retail: 540,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L2SY7ASZ;VP86KPZX;SX4ZTAKD;9CE27ENS;HLLHYJ4N;CDX1WJ78',
      statusContent: 'dot',
      hash: 'KXTV6J2Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KXTV6J2Z'
    },
    {
      status: 210,
      sku: 'PM0084680',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285437',
      rtl_batch_array: '6285437',
      name_search: 'ROMELROS',
      name: "Rosa (F) 'Melrose'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 10,
      sppl_prcp: 2.088,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ACT42PA6;CXDWKZDL;VY7K12PR;9S41B9BE;VK2SX366',
      statusContent: 'dot',
      hash: 'W9C1ZTG1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9C1ZTG1'
    },
    {
      status: 210,
      sku: 'PM0084681',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285438',
      rtl_batch_array: '6285438',
      name_search: 'ROMILANO',
      name: 'Rosa (F) Milano',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 455,
      chnn_stock_retail: 455,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LJZCKBBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LJZCKBBH'
    },
    {
      status: 210,
      sku: 'PM0084682',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285439',
      rtl_batch_array: '6285439',
      name_search: 'ROMMOIN',
      name: 'Rosa (F) Moin Moin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 730,
      chnn_stock_retail: 730,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R6YBRJPT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R6YBRJPT'
    },
    {
      status: 810,
      sku: 'PM0084683',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285440',
      rtl_batch_array: '6285440',
      name_search: 'ROMONTAN',
      name: 'Rosa (F) Montana',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CASLA5EC',
      statusContent: 'dot',
      hash: 'YC82HNSV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YC82HNSV'
    },
    {
      status: 910,
      sku: 'PM0084684',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285447',
      rtl_batch_array: '6285447',
      name_search: 'ROFQUEEN',
      name: "Rosa (P) 'Fairy Queen'",
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9N2S8BH6;HP9PX8JV;KWK8E78L',
      statusContent: 'dot',
      hash: 'DEZSEB27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DEZSEB27'
    },
    {
      status: 910,
      sku: 'PM0084685',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285453',
      rtl_batch_array: '6285453',
      name_search: 'ROLDVINC',
      name: 'Rosa (T) Leonardo da Vinci',
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7CB9SZW;4WC1L1NJ;HRZ8C1H5;DD839X3D;P3VLNHC7;SWHB6C21;77X3A4VR;9ATCGWZX;2BJSNZBG',
      statusContent: 'dot',
      hash: 'NK641PPN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NK641PPN'
    },
    {
      status: 910,
      sku: 'PM0084686',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285457',
      rtl_batch_array: '6285457',
      name_search: 'RONOASCH',
      name: "Rosa (P) 'Noaschnee'",
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5CHT1AG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5CHT1AG'
    },
    {
      status: 910,
      sku: 'PM0084687',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285458',
      rtl_batch_array: '6285458',
      name_search: 'RONOATRA',
      name: "Rosa (H) 'Noatraum'",
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XX159WW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XX159WW8'
    },
    {
      status: 910,
      sku: 'PM0084688',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285459',
      rtl_batch_array: '6285459',
      name_search: 'RONOSTAL',
      name: 'Rosa (T) Nostalgie',
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PCDGLN1X;BDZ6R29V;RSW3JKXW;DH15CRZP;6NY6SLJD;TNNECKWY;RE26JX38;TYSS13X7;AWALN945',
      statusContent: 'dot',
      hash: '63CY4RE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '63CY4RE3'
    },
    {
      status: 910,
      sku: 'PM0084689',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285461',
      rtl_batch_array: '6285461',
      name_search: 'RORMEILO',
      name: "Rosa (H) 'Rose Meilove'",
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LHK5KDKE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LHK5KDKE'
    },
    {
      status: 910,
      sku: 'PM0084690',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285463',
      rtl_batch_array: '6285463',
      name_search: 'ROSWANY',
      name: 'Rosa (H) Swany',
      sppl_size_code: '090STBW',
      rng_range_identifier: 'STE090BR',
      rng_range_description: 'Stem 90 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 12.64,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'C5G29CKZ;EZLGG4P9;H66RVJZ4;EGN5DYEE',
      statusContent: 'dot',
      hash: '9E49CLNS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9E49CLNS'
    },
    {
      status: 910,
      sku: 'PM0084691',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285468',
      rtl_batch_array: '6285468',
      name_search: 'RONOASCH',
      name: "Rosa (P) 'Noaschnee'",
      sppl_size_code: '110STBW',
      rng_range_identifier: 'STE110BR',
      rng_range_description: 'Stem 110 dm Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 14.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6J5LWCB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6J5LWCB4'
    },
    {
      status: 910,
      sku: 'PM0084692',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285469',
      rtl_batch_array: '6285469',
      name_search: 'RONOATRA',
      name: "Rosa (H) 'Noatraum'",
      sppl_size_code: '110STBW',
      rng_range_identifier: 'STE110BR',
      rng_range_description: 'Stem 110 dm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 14.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6LVZ34E6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6LVZ34E6'
    },
    {
      status: 910,
      sku: 'PM0084693',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285470',
      rtl_batch_array: '6285470',
      name_search: 'RONOSTAL',
      name: 'Rosa (T) Nostalgie',
      sppl_size_code: '110STBW',
      rng_range_identifier: 'STE110BR',
      rng_range_description: 'Stem 110 dm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 14.77,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PCDGLN1X;BDZ6R29V;RSW3JKXW;DH15CRZP;6NY6SLJD;TNNECKWY;RE26JX38;TYSS13X7;AWALN945',
      statusContent: 'dot',
      hash: '6CC3375A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CC3375A'
    },
    {
      status: 910,
      sku: 'PM0084694',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285472',
      rtl_batch_array: '6285472',
      name_search: 'ROUROSEN',
      name: 'Rosa (F) Uetersens Rosenprinzessin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '17PVD14G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17PVD14G'
    },
    {
      status: 210,
      sku: 'PM0084695',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285473',
      rtl_batch_array: '6285473',
      name_search: 'ROZDROUH',
      name: "Rosa (K) 'Z\u00e9phirine Drouhin'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DHK1KLL4;5VZZSVWV;RPV6KT1H;6PDK4YES;26RPE6LD',
      statusContent: 'dot',
      hash: '5DL7ZRWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5DL7ZRWS'
    },
    {
      status: 210,
      sku: 'PM0084696',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285474',
      rtl_batch_array: '6285474',
      name_search: 'ROAVIBER',
      name: "Rosa 'Aim\u00e9e Vibert'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXVZ7Z1J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXVZ7Z1J'
    },
    {
      status: 210,
      sku: 'PM0084697',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285475',
      rtl_batch_array: '6285475',
      name_search: 'ROABARBI',
      name: "Rosa (K) 'Alb\u00e9ric Barbier'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4CKKDCBK;S9ZN1S88;2K6TECEG',
      statusContent: 'dot',
      hash: 'CRE5ADP8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CRE5ADP8'
    },
    {
      status: 210,
      sku: 'PM0084698',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285476',
      rtl_batch_array: '6285476',
      name_search: 'ROALCHYM',
      name: "Rosa (K) 'Alchymist'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing, Roses bush',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZE91N3NP;H579REZL;PGXW5ES3;VK59W7LL;H2ANX4R8;ZEX1X8AJ;XGRE78XH',
      statusContent: 'dot',
      hash: 'P74WJLB1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P74WJLB1'
    },
    {
      status: 810,
      sku: 'PM0084699',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285477',
      rtl_batch_array: '6285477',
      name_search: 'ROAPILLA',
      name: "Rosa (K) 'American Pillar'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PAH8A7TV;RYWL4V3T;THA2N45B;D537DABL;7V12B6J3',
      statusContent: 'dot',
      hash: '9KW57XXE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9KW57XXE'
    },
    {
      status: 210,
      sku: 'PM0084700',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285478',
      rtl_batch_array: '6285478',
      name_search: 'ROBMAGEN',
      name: "Rosa (RA) 'Bleu Magenta'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 110,
      chnn_stock_retail: 110,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZZRJ8VJE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZZRJ8VJE'
    },
    {
      status: 210,
      sku: 'PM0084701',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285480',
      rtl_batch_array: '6285480',
      name_search: 'ROVEILCH',
      name: "Rosa (K) 'Veilchenblau'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T9G7YX32;J76B1X8K;71NJ3TBR',
      statusContent: 'dot',
      hash: 'JALVTLE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JALVTLE3'
    },
    {
      status: 810,
      sku: 'PM0084702',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285481',
      rtl_batch_array: '6285481',
      name_search: 'ROWDAY',
      name: "Rosa (K) 'Wedding Day'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J42L8A6J;E9K7XP85',
      statusContent: 'dot',
      hash: 'GZDRB7L5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZDRB7L5'
    },
    {
      status: 910,
      sku: 'PM0084703',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285483',
      rtl_batch_array: '6285483',
      name_search: 'ROBNEIGE',
      name: "Rosa (H) 'Boule de Neige'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AGTP1HB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AGTP1HB6'
    },
    {
      status: 910,
      sku: 'PM0084704',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285486',
      rtl_batch_array: '6285486',
      name_search: 'ROVDBOLO',
      name: "Rosa (H) 'Variegata di Bologna'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '53DJZ1NZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '53DJZ1NZ'
    },
    {
      status: 210,
      sku: 'PM0084705',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285487',
      rtl_batch_array: '6285487',
      name_search: 'ROMPAVIE',
      name: "Rosa (P) 'Marie Pavi\u00e9'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P4J1HAVG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P4J1HAVG'
    },
    {
      status: 810,
      sku: 'PM0084706',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285488',
      rtl_batch_array: '6285488',
      name_search: 'ROCNAPOL',
      name: 'Rosa Chapeau de Napoleon',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 40,
      chnn_stock_retail: 40,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HT33RZAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HT33RZAP'
    },
    {
      status: 810,
      sku: 'PM0084707',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285489',
      rtl_batch_array: '6285489',
      name_search: 'ROCCHAMB',
      name: "Rosa (H) 'Comte de Chambord'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1KCN542E;11PKVL9X;SVER7Y4Z;WYDWK4PY;SXWZSJED;D6A5ZYA9;RW1EYGE4',
      statusContent: 'dot',
      hash: 'K9X76YTH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K9X76YTH'
    },
    {
      status: 210,
      sku: 'PM0084708',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285490',
      rtl_batch_array: '6285490',
      name_search: 'RORRESCH',
      name: "Rosa (H) 'Rose de Rescht'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ATRK1L2V;WB14KBBS;GHVLC7G5;VLNYLRAB;T78GDLVJ;5YHH6PP1;B8BSVP9E;1GTR1TGC',
      statusContent: 'dot',
      hash: 'HY9W39LZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HY9W39LZ'
    },
    {
      status: 810,
      sku: 'PM0084709',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285491',
      rtl_batch_array: '6285491',
      name_search: 'ROCRICHE',
      name: "Rosa (H) 'Cardinal de Richelieu'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '44X43E1V;BK2JZW89;AEK5V9L6',
      statusContent: 'dot',
      hash: '51A4AKAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51A4AKAT'
    },
    {
      status: 210,
      sku: 'PM0084710',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285493',
      rtl_batch_array: '6285493',
      name_search: 'ROGALLIC',
      name: 'Rosa gallica',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VZP33TRA',
      statusContent: 'dot',
      hash: 'J2ZDG59T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2ZDG59T'
    },
    {
      status: 210,
      sku: 'PM0084711',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285496',
      rtl_batch_array: '6285496',
      name_search: 'ROHMARTI',
      name: "Rosa 'Henri Martin'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1340,
      chnn_stock_retail: 1340,
      sppl_order_minimum: 10,
      sppl_prcp: 2.955,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RXYYTJS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RXYYTJS5'
    },
    {
      status: 910,
      sku: 'PM0084712',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285500',
      rtl_batch_array: '6285500',
      name_search: 'ROCORNEL',
      name: "Rosa (T) 'Cornelia'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 10,
      sppl_prcp: 2.955,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JLK24L3S;8Y41882B',
      statusContent: 'dot',
      hash: 'CGZ5NN5K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CGZ5NN5K'
    },
    {
      status: 810,
      sku: 'PM0084713',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285501',
      rtl_batch_array: '6285501',
      name_search: 'ROFELICI',
      name: "Rosa (H) 'Felicia'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_order_minimum: 10,
      sppl_prcp: 2.955,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LSL2VJAH;GVEYD59G;V3B72ZKE',
      statusContent: 'dot',
      hash: '21GE9Y3X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '21GE9Y3X'
    },
    {
      status: 210,
      sku: 'PM0084714',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285502',
      rtl_batch_array: '6285502',
      name_search: 'ROPENELO',
      name: "Rosa (H) 'Penelope'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.955,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1ZLJW8BP;Y18B9DZH;6ZW1DD8Y;BBH9B3YV;85WZSYZT',
      statusContent: 'dot',
      hash: 'XGWHYY73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XGWHYY73'
    },
    {
      status: 810,
      sku: 'PM0084715',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285503',
      rtl_batch_array: '6285503',
      name_search: 'ROFPICHA',
      name: "Rosa (H) 'Ferdinand Pichard'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GTXR7STW;8X91TLNC;P85WTC3Z',
      statusContent: 'dot',
      hash: '5V84NHW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5V84NHW3'
    },
    {
      status: 910,
      sku: 'PM0084716',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6285507',
      rtl_batch_array: '6285507',
      name_search: 'RORMEILO',
      name: "Rosa (H) 'Rose Meilove'",
      sppl_size_code: '060STBW',
      rng_range_identifier: 'STE060BR',
      rng_range_description: 'Stem 60 cm Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 10.95,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KPGBTE2G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPGBTE2G'
    },
    {
      status: 210,
      sku: 'PM0084717',
      core_name: 'Plant',
      sppl_ean: '8720349417384',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503281',
      rtl_batch_array: '5503281',
      name_search: 'HENCCARO',
      name: "Helleborus niger 'Christmas Carol'",
      sppl_size_code: '015020P12',
      rng_range_identifier: 'H015020P12',
      rng_range_description: 'H15 cm 20 cm P12',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.8,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: '9T2L1WEW',
      statusContent: 'dot',
      hash: 'JTRVZG7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTRVZG7H'
    },
    {
      status: 210,
      sku: 'PM0084718',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '5503630',
      rtl_batch_array: '5503630',
      name_search: 'DAGRCROW',
      name: "Daphne gemmata 'Royal Crown'",
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 475,
      chnn_stock_retail: 475,
      sppl_order_minimum: 3,
      sppl_prcp: 5.077,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageBatch: 'DZKT6R3B',
      statusContent: 'dot',
      hash: 'JGLD3JNJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JGLD3JNJ'
    },
    {
      status: 210,
      sku: 'PM0084719',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307300',
      rtl_batch_array: '6307300',
      name_search: 'CLALPINA',
      name: 'Clematis alpina',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2314,
      chnn_stock_retail: 2314,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2C7PLSEZ;AE5XEJS4;55X28X29;SAZB1E3B;SP1BKTHH;GK1NTCX9;976R9WLB;4WLYX3XG',
      statusContent: 'dot',
      hash: 'CN93X4NZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CN93X4NZ'
    },
    {
      status: 210,
      sku: 'PM0084720',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307325',
      rtl_batch_array: '6307325',
      name_search: 'CLKDUNFO',
      name: "Clematis 'Kathleen Dunford'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.972,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PG3ANBYR;XBGBX856;7RKDL7CW;PPKTPZJE',
      statusContent: 'dot',
      hash: '1B3HXKL3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1B3HXKL3'
    },
    {
      status: 910,
      sku: 'PM0084721',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      rng_range_identifier: 'SHI014016',
      rng_range_description: 'High stem 14 cm 16 cm',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: '197S2AC3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '197S2AC3'
    },
    {
      status: 910,
      sku: 'PM0084722',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      rng_range_identifier: 'SHI016018',
      rng_range_description: 'High stem 16 cm 18 cm',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'ZEP8HT5R',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZEP8HT5R'
    },
    {
      status: 910,
      sku: 'PM0084723',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307622',
      rtl_batch_array: '6307622',
      name_search: 'PRCNIGRA',
      name: "Prunus cerasifera 'Nigra'",
      sppl_size_code: '018020HOCO',
      rng_range_identifier: 'SHI018020',
      rng_range_description: 'High stem 18 cm 20 cm',
      sppl_stock_available: 6,
      chnn_stock_retail: 6,
      sppl_prcp: 133.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6GJSLJ66;WJA4YVSP;199946E5;A9E2HG79;ZEZ8ASNV;REA4G567;J76N69LV;H857H3EK;CPSCY3LV;9K7KNPEX',
      statusContent: 'dot',
      hash: 'N1DX9VHX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1DX9VHX'
    },
    {
      status: 210,
      sku: 'PM0077248',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353511',
      rtl_batch_array: '6353511',
      name_search: 'TRVALOUR',
      name: "Tradescantia (A) 'Valour'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 177,
      chnn_stock_retail: 177,
      sppl_order_minimum: 3,
      sppl_prcp: 0.937,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZ4DNK6W',
      statusContent: 'dot',
      hash: 'L4CWLCS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L4CWLCS9'
    },
    {
      status: 210,
      sku: 'PM0084724',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6307655',
      rtl_batch_array: '6307655',
      name_search: 'RINIGRUM',
      name: 'Ribes nigrum',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5KDX21TZ;K9D3XE46;YTPJLAER;9E9WRAPN;E812YW7B;WBEP2R2G;5NN1B9VX;GLRY5SHY;BX9L9CWZ;86B3YZ7S;YWB3PWEW;5PL6L8CL;NB1LCJ5J;WC9TSNL9;C47JAE3V;CS7TVDZ8;G7G7HAYC;KV77P3DK;T6PJW69N;BAD8X951',
      statusContent: 'dot',
      hash: '2W7ALD26',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2W7ALD26'
    },
    {
      status: 210,
      sku: 'PM0076623',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6353448',
      rtl_batch_array: '6353448, 6295885',
      name_search: 'SIMAYA',
      name: "Silphium 'Maya'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 169,
      chnn_stock_retail: 271,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J6GA8VX7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J6GA8VX7'
    },
    {
      status: 210,
      sku: 'PM0077247',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353387',
      rtl_batch_array: '6353387',
      name_search: 'THFTPRIN',
      name: "Thalictrum flavum 'Tukker Princess'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 358,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WC7D8ZD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WC7D8ZD9'
    },
    {
      status: 210,
      sku: 'PM0077249',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6353522',
      rtl_batch_array: '6353522',
      name_search: 'TRLATIFO',
      name: 'Tricyrtis latifolia',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 113,
      chnn_stock_retail: 113,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N43B4TVX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N43B4TVX'
    },
    {
      status: 210,
      sku: 'PM0084725',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349637',
      rtl_batch_array: '6349637',
      name_search: 'LOHENRYI',
      name: 'Lonicera henryi',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 22973,
      chnn_stock_retail: 22973,
      sppl_order_minimum: 3,
      sppl_prcp: 2.672,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LN37L23T;JG217ECD;8H823RVC;REDLBRVV;CRZV8V5A;TPDWP3EY;7XRTK71Y;EENTKELG;E63ER6TJ;NWD4J7CW;GLSRDX75;J6K74GCC;W8A35BAE;CK844ZSW',
      statusContent: 'dot',
      hash: 'WXT5SSJA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXT5SSJA'
    },
    {
      status: 210,
      sku: 'PM0084726',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349638',
      rtl_batch_array: '6349638',
      name_search: 'LOHCBEAU',
      name: "Lonicera henryi 'Copper Beauty'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2453,
      chnn_stock_retail: 2453,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4KRGEH3R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4KRGEH3R'
    },
    {
      status: 910,
      sku: 'PM0084727',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349639',
      rtl_batch_array: '6349639',
      name_search: 'ACGRISEU',
      name: 'Acer griseum',
      sppl_size_code: '040060C5',
      rng_range_identifier: 'H040060C5',
      rng_range_description: 'H40 cm 60 cm C5',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 10.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4P6WDAY3;J7VRLB2D;8WSPZGK5;A87K4ZBP;7EXZSCZ6',
      statusContent: 'dot',
      hash: '1DP684S1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1DP684S1'
    },
    {
      status: 210,
      sku: 'PM0084728',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349642',
      rtl_batch_array: '6349642',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '040C10BOL',
      rng_range_identifier: 'BALL\u00d8040H040040C10',
      rng_range_description: 'Ball \u00d8 40 cm H40 cm 40 cm C10',
      sppl_stock_available: 894,
      chnn_stock_retail: 894,
      sppl_prcp: 14,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: '72XPGRZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '72XPGRZ3'
    },
    {
      status: 210,
      sku: 'PM0013125',
      core_name: 'Plant',
      sppl_ean: '8720664853010',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6147167',
      rtl_batch_array: '6242923, 6147167, 6054445',
      name_search: 'ASTEUROP',
      name: "Astilbe (J) 'Europa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 2835,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BRD7E9PK;25VW25XY',
      statusContent: 'dot',
      hash: '8Z4C9DD9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Z4C9DD9'
    },
    {
      status: 210,
      sku: 'PM0084729',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349643',
      rtl_batch_array: '6349643',
      name_search: 'CHJSARGE',
      name: "Chaenomeles japonica 'Sargentii'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 515,
      chnn_stock_retail: 515,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VV69BPYW;ZK3RYVLX;763KY6R4;LWNSYZVV;NSDKJDCD',
      statusContent: 'dot',
      hash: '4N6JH871',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4N6JH871'
    },
    {
      status: 210,
      sku: 'PM0011176',
      core_name: 'Plant',
      btch_manufacturer: 492,
      rtl_batch_array_total: 3,
      btch_active_retail: '6333192',
      rtl_batch_array: '6077541, 5363912, 6333192',
      name_search: 'ASDEUTSC',
      name: "Astilbe (J) 'Deutschland'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 462,
      chnn_stock_retail: 5136,
      sppl_order_minimum: 3,
      sppl_prcp: 0.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X8HHGXL1;66WV4KD2;8KWC3Y9Y;7J8DYNSA;WBWX85DN',
      statusContent: 'dot',
      hash: 'BLGN9KD4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BLGN9KD4'
    },
    {
      status: 210,
      sku: 'PM0021588',
      core_name: 'Plant',
      sppl_ean: '8720626312586',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420699',
      rtl_batch_array: '5420699',
      name_search: 'MEGLYPTO',
      name: 'Metasequoia glyptostroboides',
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 210,
      chnn_stock_retail: 210,
      sppl_prcp: 8.053,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'APZV6165;EEDG59S2;Y3EJYXBA;TWP9Y85L;NSNDZL7H;PG49N7R1;KVA96A2A;YLYJJ13K;HKEJX78L;4ZTPD9WJ;DLDSW7N8;PNBZXV8L;CZ7LZREB;R8K1C5HK;H47B2JEC;CHS94XV8',
      statusContent: 'dot',
      hash: 'K2B55B8Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K2B55B8Z'
    },
    {
      status: 210,
      sku: 'PM0084730',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349644',
      rtl_batch_array: '6349644',
      name_search: 'CHSNIVAL',
      name: "Chaenomeles speciosa 'Nivalis'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 322,
      chnn_stock_retail: 322,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7BYVSYJ1',
      statusContent: 'dot',
      hash: 'H12H9SET',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H12H9SET'
    },
    {
      status: 210,
      sku: 'PM0084731',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349645',
      rtl_batch_array: '6349645',
      name_search: 'CHSCGOLD',
      name: "Chaenomeles superba 'Crimson and Gold'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 478,
      chnn_stock_retail: 478,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GNNN4HGJ;E1DGH2HZ;7Z873B33;14JX32TV;7YG1ETPX;D2TYTT6D',
      statusContent: 'dot',
      hash: '583EJ5R7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '583EJ5R7'
    },
    {
      status: 210,
      sku: 'PM0010293',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6259396',
      rtl_batch_array: '6077544, 6259396',
      name_search: 'ASMAINZ',
      name: "Astilbe (J) 'Mainz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 455,
      chnn_stock_retail: 1221,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '53L59GX5;N77BLBER;778PVSR8;S7EXAH6J;ZP4V1PJ7;NXLE55J7;DTAYYSV1;EBVZTTAS;CK7272C8',
      statusContent: 'dot',
      hash: 'RBNRD2SD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RBNRD2SD'
    },
    {
      status: 210,
      sku: 'PM0029633',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 3,
      btch_active_retail: '6329955',
      rtl_batch_array: '6329955, 6142839, 6348338',
      name_search: 'HEODERED',
      name: "Helleborus orientalis 'Double Ellen Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1186,
      chnn_stock_retail: 1277,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WCCHK7Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WCCHK7Z1'
    },
    {
      status: 910,
      sku: 'PM0040177',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ACDHAYWA',
      name: "Actinidia deliciosa 'Hayward'",
      rng_range_identifier: 'H070080C2',
      rng_range_description: 'H70 cm 80 cm C2',
      imageCore: 'TLC6A61E',
      statusContent: 'dot',
      hash: 'ZKXR7P9T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZKXR7P9T'
    },
    {
      status: 910,
      sku: 'PM0026029',
      core_name: 'Plant',
      sppl_ean: '8720664699021',
      btch_manufacturer: 142908,
      rtl_batch_array_total: 1,
      btch_active_retail: '5732795',
      rtl_batch_array: '5732795',
      name_search: 'MADSUMME',
      name: "Malus domestica 'Summerred'",
      sppl_size_code: '140160C74',
      rng_range_identifier: 'H140160C7.5',
      rng_range_description: 'H140 cm 160 cm C7.5',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 9.37,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K5D2KDH5;AWDH484J;D7BLLKJ4;9LVNGN67;C4XY37CA;GCC3GH25;26S92ATW;NS584XJN;P24XYY28;TLYCD996;2N3147CW',
      statusContent: 'dot',
      hash: 'ZYKRSP8C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYKRSP8C'
    },
    {
      status: 810,
      sku: 'PM0077246',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6319761',
      rtl_batch_array: '6353476, 6319761',
      name_search: 'STATTENU',
      name: 'Strobilanthes attenuata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 365,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K3CEXYAK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K3CEXYAK'
    },
    {
      status: 210,
      sku: 'PM0084732',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349646',
      rtl_batch_array: '6349646',
      name_search: 'CHSJTRAI',
      name: "Chaenomeles superba 'Jet Trail'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 596,
      chnn_stock_retail: 596,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6LGYLPL8;78SXWW53;7A721S9T;2GSSWH84;WDC9NHHH',
      statusContent: 'dot',
      hash: 'HP79BNBH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HP79BNBH'
    },
    {
      status: 210,
      sku: 'PM0084733',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349647',
      rtl_batch_array: '6349647',
      name_search: 'CHSNICOL',
      name: "Chaenomeles superba 'Nicoline'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 385,
      chnn_stock_retail: 385,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'T3Y1ZWH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T3Y1ZWH8'
    },
    {
      status: 210,
      sku: 'PM0084734',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349648',
      rtl_batch_array: '6349648',
      name_search: 'CHSOTRAI',
      name: "Chaenomeles superba 'Orange Trail'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 994,
      chnn_stock_retail: 994,
      sppl_order_minimum: 3,
      sppl_prcp: 3.272,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JBRGG239',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JBRGG239'
    },
    {
      status: 810,
      sku: 'PM0084735',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349649',
      rtl_batch_array: '6349649',
      name_search: 'YUROSTRA',
      name: 'Yucca rostrata',
      sppl_size_code: 'LEVC5',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 16.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LACELD2Y;2CEEEAP7',
      statusContent: 'dot',
      hash: 'KY674P3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KY674P3A'
    },
    {
      status: 210,
      sku: 'PM0084736',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349652',
      rtl_batch_array: '6349652',
      name_search: 'PATGSPRI',
      name: "Parthenocissus tricuspidata 'Green Spring'",
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 2205,
      chnn_stock_retail: 2205,
      sppl_order_minimum: 3,
      sppl_prcp: 4.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JD4EENBP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JD4EENBP'
    },
    {
      status: 910,
      sku: 'PM0084737',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349655',
      rtl_batch_array: '6349655',
      name_search: 'CELIBANI',
      name: 'Cedrus libani',
      sppl_size_code: '100125C10',
      rng_range_identifier: 'H100125C10',
      rng_range_description: 'H100 cm 125 cm C10',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_prcp: 13.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LPK35RB9;YVSAPN13',
      statusContent: 'dot',
      hash: 'VPPXPK7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPPXPK7G'
    },
    {
      status: 810,
      sku: 'PM0084738',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6349656',
      rtl_batch_array: '6349656',
      name_search: 'FICARICA',
      name: 'Ficus carica',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 36,
      chnn_stock_retail: 36,
      sppl_order_minimum: 3,
      sppl_prcp: 3.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1K8V1GKE;CRXZJDG5;6EV38D5P;9C6E57EL;73G9NP3L;C9N7J2N1;521WJST4;25551YND;J613PYAS;NS6HGTSR;235SL87D;8ALZSVE8;LKZ4ZL3J;ZRWE8G2C;D5EJ8XKV;DABTDTJK;W7H9XW4Y;KS3ZREEZ;W4RHSG5X;24AAKCAN;GSGTT48N;1NJTX78G;6ARW63ZA;WVYDD3SE;DSPTBZLV;RSK5PTHD',
      statusContent: 'dot',
      hash: 'A8VJ7HKX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A8VJ7HKX'
    },
    {
      status: 210,
      sku: 'PM0084739',
      core_name: 'Plant',
      btch_manufacturer: 2761,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363970',
      rtl_batch_array: '6363970',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '150175C20',
      rng_range_identifier: 'H150175C20',
      rng_range_description: 'H150 cm 175 cm C20',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 27.892,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'S4K835Y6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S4K835Y6'
    },
    {
      status: 210,
      sku: 'PM0084740',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363971',
      rtl_batch_array: '6363971',
      name_search: 'HEHMLISA',
      name: "Hedera helix 'Mona Lisa'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '91LS7YH1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '91LS7YH1'
    },
    {
      status: 210,
      sku: 'PM0084741',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363972',
      rtl_batch_array: '6363972',
      name_search: 'HEHWRIPP',
      name: "Hedera helix 'White Ripple'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8CB11SKW;L5BRSNDS;XR9DVZX4;LEYG7KJ5',
      statusContent: 'dot',
      hash: '9HW9YZRK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HW9YZRK'
    },
    {
      status: 210,
      sku: 'PM0084742',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363973',
      rtl_batch_array: '6363973',
      name_search: 'HEHYRIPP',
      name: "Hedera helix 'Yellow Ripple'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PW95XKBK;SWE9A1RJ;WCYTWAGC;4NZV459P',
      statusContent: 'dot',
      hash: 'WS8RZCHZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WS8RZCHZ'
    },
    {
      status: 910,
      sku: 'PM0084743',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LOCAPRIF',
      name: 'Lonicera caprifolium',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      imageCore: 'AP9DRCV6',
      statusContent: 'dot',
      hash: '9KCDR3H3',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9KCDR3H3'
    },
    {
      status: 910,
      sku: 'PM0084744',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363976',
      rtl_batch_array: '6363976',
      name_search: 'BUSEMPER',
      name: 'Buxus sempervirens',
      sppl_size_code: '080090C12PY',
      rng_range_identifier: 'PYRAH080090C12',
      rng_range_description: 'Pyramid H80 cm 90 cm C12',
      sppl_stock_available: 8,
      chnn_stock_retail: 8,
      sppl_prcp: 22.306,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'WK42P7RW;1H4N5JGB;ERHSKP2X;TDJ21ZYC;RW5SV2TB;TJX3VST5;D98GPLDZ;G85P5B38;2NW4XXD6;CZDXCXH4;W3HJ1G1A;R2SLNA26;2T4SNAT7;9JP45B9E;9KLCGACP;WVN84CCW;L9B92PGL;EKWHNYA4;CDHESW7V;A1RXJ257;C6SAS3N8;CGX7EZJH;C46JY77Z;RX5AC6N2;866GZCAT;ETH7PCRY;8J2N4KGA;6J6TYXP6;BPE4TC5X',
      statusContent: 'dot',
      hash: 'CY1L7ALN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CY1L7ALN'
    },
    {
      status: 210,
      sku: 'PM0084745',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363981',
      rtl_batch_array: '6363981',
      name_search: 'HECOLCHI',
      name: 'Hedera colchica',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 980,
      chnn_stock_retail: 980,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BVV83C4W;6XKLHT6T;858C19HX;8ZSTYWGR',
      statusContent: 'dot',
      hash: '2XYHP5NL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2XYHP5NL'
    },
    {
      status: 210,
      sku: 'PM0084746',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363982',
      rtl_batch_array: '6363982',
      name_search: 'HECDVARI',
      name: "Hedera colchica 'Dentata Variegata'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NTAH3ANZ;VTA8ASSZ;Y5GEEDZW;RRRXLAPP;4KWTP8C5',
      statusContent: 'dot',
      hash: 'RTE6EJ67',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RTE6EJ67'
    },
    {
      status: 210,
      sku: 'PM0084747',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363983',
      rtl_batch_array: '6363983',
      name_search: 'HECSHEAR',
      name: "Hedera colchica 'Sulphur Heart'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ALAA1DE4;K82NZT1T;2E5KKWNZ;L677BA8L;7KCPWPXW',
      statusContent: 'dot',
      hash: 'CCTKSD1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CCTKSD1C'
    },
    {
      status: 210,
      sku: 'PM0084748',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363984',
      rtl_batch_array: '6363984',
      name_search: 'HEHEVA',
      name: "Hedera helix 'Eva'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 490,
      chnn_stock_retail: 490,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZJ8B9W3Z;GV6GEEKC;8Z2JTKNL;C7V2W447;GYHPYG7D;XAETKTZY',
      statusContent: 'dot',
      hash: 'J3G1ZK72',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J3G1ZK72'
    },
    {
      status: 210,
      sku: 'PM0084749',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363985',
      rtl_batch_array: '6363985',
      name_search: 'HEHGRIPP',
      name: "Hedera helix 'Green Ripple'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 470,
      chnn_stock_retail: 470,
      sppl_order_minimum: 3,
      sppl_prcp: 2.983,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CA42PXGD;5S6CPK7C;65YXDBZR',
      statusContent: 'dot',
      hash: '9NYTSWR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9NYTSWR6'
    },
    {
      status: 810,
      sku: 'PM0084750',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6363988',
      rtl_batch_array: '6363988',
      name_search: 'RIBRUBRU',
      name: 'Ribes rubrum (red)',
      sppl_size_code: '060070C2',
      rng_range_identifier: 'H060070C2',
      rng_range_description: 'H60 cm 70 cm C2',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 2.372,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'CD2DNNRC;2RG273Y2;HCD1GTS6;XH1D7LDN;7EWY9LKA;PTEJ5WH7;LZYE4N75;XP8GJHDV;N2V447S3;GKS39BKB;YS61WCBW;1TVW7LZL;11Y3YGWB;L7TSY6KG;TR9931HJ;XDC4RH8V;Z67JVY3K;G2EAA8CD;VXYCGSWD;NJHZZ823;CN62CDHR;H23EC19P;ZB4V4V3T;KBRDRLVD;W3SKJXGJ;BHP8R7XB;RCDV7184;NN3LY4E9',
      statusContent: 'dot',
      hash: '1XWV22VW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XWV22VW'
    },
    {
      status: 210,
      sku: 'PM0084751',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027812',
      rtl_batch_array: '6027812',
      name_search: 'ROSAMACK',
      name: 'Rosa Alexander Mackenzie',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84JK141Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84JK141Y'
    },
    {
      status: 210,
      sku: 'PM0084752',
      core_name: 'Plant',
      sppl_ean: '8720664622579',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027813',
      rtl_batch_array: '6027813',
      name_search: 'ROCGRANT',
      name: "Rosa (H) 'Cuthbert Grant'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1080,
      chnn_stock_retail: 1080,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2B8THP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2B8THP1'
    },
    {
      status: 210,
      sku: 'PM0084753',
      core_name: 'Plant',
      sppl_ean: '8720664806443',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027815',
      rtl_batch_array: '6027815',
      name_search: 'ROYFAIRY',
      name: 'Rosa (P) Yellow Fairy',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 1395,
      chnn_stock_retail: 1395,
      sppl_order_minimum: 10,
      sppl_prcp: 1.476,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'J2GDBL24',
      statusContent: 'dot',
      hash: 'VNSZ4R2K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VNSZ4R2K'
    },
    {
      status: 210,
      sku: 'PM0084754',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027817',
      rtl_batch_array: '6027817',
      name_search: 'ROCDRIFT',
      name: 'Rosa (H) Coral Drift',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AK2R3PV5',
      statusContent: 'dot',
      hash: 'C8W3X8Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8W3X8Z6'
    },
    {
      status: 210,
      sku: 'PM0084755',
      core_name: 'Plant',
      sppl_ean: '8720664805750',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027818',
      rtl_batch_array: '6027818',
      name_search: 'ROCUBANA',
      name: 'Rosa (H) Cubana',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2D1TD7ZZ;5XJSG4Y1',
      statusContent: 'dot',
      hash: 'S8CGGWP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8CGGWP4'
    },
    {
      status: 210,
      sku: 'PM0084756',
      core_name: 'Plant',
      sppl_ean: '8720664806757',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027819',
      rtl_batch_array: '6027819',
      name_search: 'ROHPINK',
      name: 'Rosa Heavenly Pink',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 180,
      chnn_stock_retail: 180,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9XEV5W7C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9XEV5W7C'
    },
    {
      status: 810,
      sku: 'PM0084757',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027822',
      rtl_batch_array: '6027822',
      name_search: 'ROPRAIN',
      name: 'Rosa Purple Rain',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KGHX1GRG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KGHX1GRG'
    },
    {
      status: 210,
      sku: 'PM0084758',
      core_name: 'Plant',
      sppl_ean: '8720664806139',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027823',
      rtl_batch_array: '6027823',
      name_search: 'RORODY',
      name: 'Rosa (H) Rody',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZPHYX5L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZPHYX5L'
    },
    {
      status: 810,
      sku: 'PM0084759',
      core_name: 'Plant',
      sppl_ean: '8720664805453',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027825',
      rtl_batch_array: '6027825',
      name_search: 'RONPAGAN',
      name: 'Rosa (F) Niccolo Paganini',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8GHK49D4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8GHK49D4'
    },
    {
      status: 910,
      sku: 'PM0084760',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6027829',
      rtl_batch_array: '6027829',
      name_search: 'ROCDAWN',
      name: "Rosa (K) 'Coral Dawn'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BWSYXN5J;YXG7YE8R;C849LYCK;XD5271EJ;NCY1T974',
      statusContent: 'dot',
      hash: 'C9821RVJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C9821RVJ'
    },
    {
      status: 210,
      sku: 'PM0084761',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6143014',
      rtl_batch_array: '6143014',
      name_search: 'ROMARATH',
      name: 'Rosa (H) Marathon',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 3.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9WWZ19CT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9WWZ19CT'
    },
    {
      status: 210,
      sku: 'PM0084762',
      core_name: 'Plant',
      sppl_ean: '8720664806825',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '5867967',
      rtl_batch_array: '5867967',
      name_search: 'RORBALLE',
      name: 'Rosa Red Ballerina',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      sppl_stock_available: 1625,
      chnn_stock_retail: 1625,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H95G9WZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H95G9WZS'
    },
    {
      status: 210,
      sku: 'PM0084763',
      core_name: 'Plant',
      sppl_ean: '8720664805354',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010297',
      rtl_batch_array: '6010297',
      name_search: 'ROALABAS',
      name: 'Rosa (F) Alabaster',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 860,
      chnn_stock_retail: 860,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EWVWVDEH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EWVWVDEH'
    },
    {
      status: 210,
      sku: 'PM0084764',
      core_name: 'Plant',
      sppl_ean: '8720664805323',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010298',
      rtl_batch_array: '6010298',
      name_search: 'ROABELL',
      name: "Rosa (F) 'Arthur Bell'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'W2X17R3N;GEZKXP9T;E4HC9D9W',
      statusContent: 'dot',
      hash: 'E99R14YN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E99R14YN'
    },
    {
      status: 210,
      sku: 'PM0084765',
      core_name: 'Plant',
      sppl_ean: '8720664805361',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010299',
      rtl_batch_array: '6010299',
      name_search: 'ROBROSA',
      name: 'Rosa (F) Bella Rosa',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GRC5SSW3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GRC5SSW3'
    },
    {
      status: 810,
      sku: 'PM0084766',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010301',
      rtl_batch_array: '6010301',
      name_search: 'ROGALAXY',
      name: 'Rosa (T) Galaxy',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CVCWEES9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVCWEES9'
    },
    {
      status: 210,
      sku: 'PM0084767',
      core_name: 'Plant',
      sppl_ean: '8720664806405',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010304',
      rtl_batch_array: '6010304',
      name_search: 'ROHGARDE',
      name: 'Rosa (P) Home & Garden',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9HCYNJYD;SET95EEB;6EHG7S1S',
      statusContent: 'dot',
      hash: 'HTNSXT5P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HTNSXT5P'
    },
    {
      status: 210,
      sku: 'PM0084768',
      core_name: 'Plant',
      sppl_ean: '8720664625181',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010307',
      rtl_batch_array: '6010307',
      name_search: 'ROJIGLES',
      name: 'Rosa Julio Iglesias',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 895,
      chnn_stock_retail: 895,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RPWKDDB9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPWKDDB9'
    },
    {
      status: 210,
      sku: 'PM0084769',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010309',
      rtl_batch_array: '6010309',
      name_search: 'RONWEIBU',
      name: "Rosa (F) 'Nina Weibull'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K5DERZWW;KKTY5H36;53YASPHB;5NNS6YSK;6NKYC11R',
      statusContent: 'dot',
      hash: 'E27CSVGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E27CSVGZ'
    },
    {
      status: 210,
      sku: 'PM0084770',
      core_name: 'Plant',
      sppl_ean: '8720664805460',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010310',
      rtl_batch_array: '6010310',
      name_search: 'ROPAPRIK',
      name: 'Rosa (F) Paprika',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing, Roses bush',
      sppl_stock_available: 390,
      chnn_stock_retail: 390,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ATXZEYE4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ATXZEYE4'
    },
    {
      status: 210,
      sku: 'PM0084771',
      core_name: 'Plant',
      sppl_ean: '8720664806412',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010313',
      rtl_batch_array: '6010313',
      name_search: 'ROPOUSTI',
      name: 'Rosa (P) Poustinia',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 870,
      chnn_stock_retail: 870,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CZ4E9PW2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZ4E9PW2'
    },
    {
      status: 910,
      sku: 'PM0084772',
      core_name: 'Plant',
      sppl_ean: '8720664806436',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010315',
      rtl_batch_array: '6010315',
      name_search: 'RORBLUE',
      name: 'Rosa (P) Rhapsody in Blue',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 10,
      chnn_stock_retail: 10,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G4HA6TT9;B3PDWB89;H6L1YE3Z;CRKN4RZH;SS57TN4J;2GT5AN5D;AH3VRPXY',
      statusContent: 'dot',
      hash: 'S91RTE3J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S91RTE3J'
    },
    {
      status: 210,
      sku: 'PM0084773',
      core_name: 'Plant',
      sppl_ean: '8720664624900',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010316',
      rtl_batch_array: '6010316',
      name_search: 'ROSAPHIR',
      name: 'Rosa (T) Saphir',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 130,
      chnn_stock_retail: 130,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BKZ13X2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BKZ13X2'
    },
    {
      status: 210,
      sku: 'PM0084774',
      core_name: 'Plant',
      sppl_ean: '8720664806368',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010319',
      rtl_batch_array: '6010319',
      name_search: 'ROFQUEEN',
      name: "Rosa (P) 'Fairy Queen'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9N2S8BH6;HP9PX8JV;KWK8E78L',
      statusContent: 'dot',
      hash: '27LWZE5G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '27LWZE5G'
    },
    {
      status: 210,
      sku: 'PM0084775',
      core_name: 'Plant',
      sppl_ean: '8720664623538',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010320',
      rtl_batch_array: '6010320',
      name_search: 'ROALOHA',
      name: "Rosa (K) 'Aloha'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 260,
      chnn_stock_retail: 260,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WZYSDL1W;2KRCZ6V6;AT1JD2G7;C652JBNV',
      statusContent: 'dot',
      hash: '4J2JA26G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4J2JA26G'
    },
    {
      status: 210,
      sku: 'PM0084776',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010337',
      rtl_batch_array: '6010337',
      name_search: 'ROPSWANY',
      name: 'Rosa (H) Pink Swany',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1130,
      chnn_stock_retail: 1130,
      sppl_order_minimum: 10,
      sppl_prcp: 2.292,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DW6T14XR',
      statusContent: 'dot',
      hash: 'K48VWSH5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K48VWSH5'
    },
    {
      status: 210,
      sku: 'PM0084777',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010338',
      rtl_batch_array: '6010338',
      name_search: 'RORETRO',
      name: 'Rosa (H) Retro',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 10,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9VNYP1Z4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9VNYP1Z4'
    },
    {
      status: 210,
      sku: 'PM0084778',
      core_name: 'Plant',
      sppl_ean: '8720664806245',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010341',
      rtl_batch_array: '6010341',
      name_search: 'ROSDRIFT',
      name: 'Rosa (H) Sweet Drift',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 10,
      sppl_prcp: 2.241,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CBJ852VV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CBJ852VV'
    },
    {
      status: 210,
      sku: 'PM0084779',
      core_name: 'Plant',
      sppl_ean: '8720664622166',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010356',
      rtl_batch_array: '6010356',
      name_search: 'ROCAMPFI',
      name: 'Rosa (F) Campfire',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VL5ATBEG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VL5ATBEG'
    },
    {
      status: 210,
      sku: 'PM0084780',
      core_name: 'Plant',
      sppl_ean: '8720664622616',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010359',
      rtl_batch_array: '6010359',
      name_search: 'ROGVANCO',
      name: "Rosa (H) 'George Vancouver'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 800,
      chnn_stock_retail: 800,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CVHDW75Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CVHDW75Y'
    },
    {
      status: 210,
      sku: 'PM0084781',
      core_name: 'Plant',
      sppl_ean: '8720664805293',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010360',
      rtl_batch_array: '6010360',
      name_search: 'ROJDAVIS',
      name: "Rosa 'John Davis'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CP5Y7AGC;EBT36YC9;84CKLJSS;WA3KTWZY',
      statusContent: 'dot',
      hash: '6S2Z8J9R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6S2Z8J9R'
    },
    {
      status: 210,
      sku: 'PM0084782',
      core_name: 'Plant',
      sppl_ean: '8720664622630',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010361',
      rtl_batch_array: '6010361',
      name_search: 'ROJFRANK',
      name: "Rosa (H) 'John Franklin'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CPDNTRLP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPDNTRLP'
    },
    {
      status: 210,
      sku: 'PM0084783',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010363',
      rtl_batch_array: '6010363',
      name_search: 'ROMFIREG',
      name: "Rosa (H) 'Morden Fireglow'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 590,
      chnn_stock_retail: 590,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SJHZTVKS;EPW9NV7W',
      statusContent: 'dot',
      hash: '81H8XPY7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81H8XPY7'
    },
    {
      status: 210,
      sku: 'PM0084784',
      core_name: 'Plant',
      sppl_ean: '8720664806467',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010364',
      rtl_batch_array: '6010364',
      name_search: 'ROTBUGNE',
      name: "Rosa (R) 'Th\u00e9r\u00e8se Bugnet'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5AX9ZBH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AX9ZBH8'
    },
    {
      status: 210,
      sku: 'PM0084785',
      core_name: 'Plant',
      sppl_ean: '8720664805675',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010365',
      rtl_batch_array: '6010365',
      name_search: 'ROWBAFFI',
      name: "Rosa (H) 'William Baffin'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 850,
      chnn_stock_retail: 850,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NP4CBTLL;13H85Z8S',
      statusContent: 'dot',
      hash: '295S9VNG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '295S9VNG'
    },
    {
      status: 210,
      sku: 'PM0084786',
      core_name: 'Plant',
      sppl_ean: '8720664805705',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010367',
      rtl_batch_array: '6010367',
      name_search: 'ROAROSE',
      name: 'Rosa (H) Aspirin Rose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1570,
      chnn_stock_retail: 1570,
      sppl_order_minimum: 10,
      sppl_prcp: 3.006,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LXA9WCLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LXA9WCLX'
    },
    {
      status: 210,
      sku: 'PM0084787',
      core_name: 'Plant',
      sppl_ean: '8720664805934',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010370',
      rtl_batch_array: '6010370',
      name_search: 'ROLDREAM',
      name: 'Rosa (H) Lavender Dream',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.19,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'BA7PA1BK;D56BEHCN;GZWKCXV3;E8R7XVW1;CYP4DDD8;YCYXY1GX;HGPBBWPD;KE3S1344',
      statusContent: 'dot',
      hash: 'X37EL6BN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X37EL6BN'
    },
    {
      status: 910,
      sku: 'PM0084788',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6010372',
      rtl_batch_array: '6010372',
      name_search: 'ROPPARVO',
      name: "Rosa (F) Patrick Poivre d' Arvor",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TSR63LTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TSR63LTV'
    },
    {
      status: 210,
      sku: 'PM0084789',
      core_name: 'Plant',
      sppl_ean: '8720664622562',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '5972386',
      rtl_batch_array: '5972386',
      name_search: 'ROCSHOLL',
      name: "Rosa (H) 'Captain Samuel Holland'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 340,
      chnn_stock_retail: 340,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LLX6VDPE',
      statusContent: 'dot',
      hash: '6R3J9ZBV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6R3J9ZBV'
    },
    {
      status: 210,
      sku: 'PM0084790',
      core_name: 'Plant',
      sppl_ean: '8720664621985',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '5972387',
      rtl_batch_array: '5972387',
      name_search: 'ROWPARKS',
      name: "Rosa 'Winnipeg Parks'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8HDPCCPR;AZ925D7C;TA8Z8YBD',
      statusContent: 'dot',
      hash: 'N8N2WBDV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N8N2WBDV'
    },
    {
      status: 210,
      sku: 'PM0084791',
      core_name: 'Plant',
      sppl_ean: '8720664806481',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '5972394',
      rtl_batch_array: '5972394',
      name_search: 'ROPPAVEM',
      name: 'Rosa (R) Pierette Pavement',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.241,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'LRG2PHGW;9KR8WCW3',
      statusContent: 'dot',
      hash: 'SYG6ECDP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SYG6ECDP'
    },
    {
      status: 210,
      sku: 'PM0084792',
      core_name: 'Plant',
      sppl_ean: '8720664883543',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084592',
      rtl_batch_array: '6084592',
      name_search: 'ROSEVILL',
      name: 'Rosa (F) La Sevillana',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_order_minimum: 10,
      sppl_prcp: 2.496,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RDCWP2S5;GDLT92J8;28J1ZVL5;EATVEJEG;6KTCA8WV;GX53YD31;46YY7VGD',
      statusContent: 'dot',
      hash: 'JNPLDWLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNPLDWLC'
    },
    {
      status: 210,
      sku: 'PM0084793',
      core_name: 'Plant',
      sppl_ean: '8720664883703',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084593',
      rtl_batch_array: '6084593',
      name_search: 'ROSPROAD',
      name: 'Rosa (R) Purple Roadrunner',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 855,
      chnn_stock_retail: 855,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YS437132',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YS437132'
    },
    {
      status: 210,
      sku: 'PM0084794',
      core_name: 'Plant',
      sppl_ean: '8720664883710',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084594',
      rtl_batch_array: '6084594',
      name_search: 'ROSPAVEM',
      name: 'Rosa (R) Scarlet Pavement',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.445,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DGXVL621',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DGXVL621'
    },
    {
      status: 810,
      sku: 'PM0084795',
      core_name: 'Plant',
      sppl_ean: '8720664883581',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084595',
      rtl_batch_array: '6084595',
      name_search: 'ROEGLANT',
      name: 'Rosa (H) Eglantyne',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '1JC8VLR9;WDAWXVCW;KTXLH23X',
      statusContent: 'dot',
      hash: '6ZZJ2839',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ZZJ2839'
    },
    {
      status: 210,
      sku: 'PM0084796',
      core_name: 'Plant',
      sppl_ean: '8720664883888',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084600',
      rtl_batch_array: '6084600',
      name_search: 'ROTABECK',
      name: 'Rosa Thomas A Becket',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 5.658,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZDNGJY9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZDNGJY9T'
    },
    {
      status: 210,
      sku: 'PM0084797',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084603',
      rtl_batch_array: '6084603',
      name_search: 'ROHKROSE',
      name: 'Rosa (F) Heidi Klum Rose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N94ZT6AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N94ZT6AB'
    },
    {
      status: 210,
      sku: 'PM0084798',
      core_name: 'Plant',
      sppl_ean: '8720664883864',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084604',
      rtl_batch_array: '6084604',
      name_search: 'ROTERRAC',
      name: 'Rosa Terracotta',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 225,
      chnn_stock_retail: 225,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'P1LRS4G9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P1LRS4G9'
    },
    {
      status: 810,
      sku: 'PM0084799',
      core_name: 'Plant',
      sppl_ean: '8720664883635',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084606',
      rtl_batch_array: '6084606',
      name_search: 'ROSALZER',
      name: 'Rosa (K) Schneewalzer',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8NR74YK6;SGRBV8PD;P92DR52B;RNLBJ3J5',
      statusContent: 'dot',
      hash: '5R5Y2W8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5R5Y2W8H'
    },
    {
      status: 210,
      sku: 'PM0084800',
      core_name: 'Plant',
      sppl_ean: '8720664883642',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6084608',
      rtl_batch_array: '6084608',
      name_search: 'ROWFAIRY',
      name: "Rosa (M) 'White Fairy'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.476,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6ZZX6YSZ',
      statusContent: 'dot',
      hash: '1SLLCPWS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1SLLCPWS'
    },
    {
      status: 210,
      sku: 'PM0084801',
      core_name: 'Plant',
      sppl_ean: '8720664806207',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063709',
      rtl_batch_array: '6063709',
      name_search: 'RSOMMERW',
      name: 'Rosa (H) Sommerwind',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 370,
      chnn_stock_retail: 370,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JLA35CDA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JLA35CDA'
    },
    {
      status: 210,
      sku: 'PM0084802',
      core_name: 'Plant',
      sppl_ean: '8720664805613',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063711',
      rtl_batch_array: '6063711',
      name_search: 'ROSKORTE',
      name: "Rosa (H) 'Kortemma'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 840,
      chnn_stock_retail: 840,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '22VWJ4RX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '22VWJ4RX'
    },
    {
      status: 210,
      sku: 'PM0084803',
      core_name: 'Plant',
      sppl_ean: '8720664806078',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063713',
      rtl_batch_array: '6063713',
      name_search: 'ROPGIRL',
      name: 'Rosa (H) Pretty Girl',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZ1Z9NLX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZ1Z9NLX'
    },
    {
      status: 810,
      sku: 'PM0084804',
      core_name: 'Plant',
      sppl_ean: '8720664806597',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063715',
      rtl_batch_array: '6063715',
      name_search: 'ROFORTUN',
      name: 'Rosa (T) Fortuna',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 10,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CD7NJ1NG',
      statusContent: 'dot',
      hash: 'X4J8JKYC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4J8JKYC'
    },
    {
      status: 210,
      sku: 'PM0084805',
      core_name: 'Plant',
      sppl_ean: '8720664805484',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063716',
      rtl_batch_array: '6063716',
      name_search: 'ROPBLOME',
      name: 'Rosa (F) Planten un Blomen',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_prcp: 3.21,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LWPTLD92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWPTLD92'
    },
    {
      status: 210,
      sku: 'PM0084806',
      core_name: 'Plant',
      sppl_ean: '8720664806726',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063718',
      rtl_batch_array: '6063718',
      name_search: 'ROFCSORR',
      name: 'Rosa Flower Carpet Sorrento',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 10,
      sppl_prcp: 4.995,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JSRK99EX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSRK99EX'
    },
    {
      status: 210,
      sku: 'PM0084807',
      core_name: 'Plant',
      sppl_ean: '8720664806122',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063721',
      rtl_batch_array: '6063721',
      name_search: 'ROROBUST',
      name: 'Rosa (H) Robusta',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '667D9W5Z;W4CD9JB5;2D54GJ1W;CBK2KKKZ;AK4332ZZ;LSY3Z8DL',
      statusContent: 'dot',
      hash: '6TCV25YT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6TCV25YT'
    },
    {
      status: 210,
      sku: 'PM0084808',
      core_name: 'Plant',
      sppl_ean: '8720664806665',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063723',
      rtl_batch_array: '6063723',
      name_search: 'ROBOURGO',
      name: 'Rosa Bourgogne',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 170,
      chnn_stock_retail: 170,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5SXKRXAX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5SXKRXAX'
    },
    {
      status: 210,
      sku: 'PM0084809',
      core_name: 'Plant',
      sppl_ean: '8720664806030',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063728',
      rtl_batch_array: '6063728',
      name_search: 'ROPDRIFT',
      name: 'Rosa (H) Pearl Drift',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 330,
      chnn_stock_retail: 330,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9R9CVNH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9R9CVNH9'
    },
    {
      status: 210,
      sku: 'PM0084810',
      core_name: 'Plant',
      sppl_ean: '8720664805538',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063729',
      rtl_batch_array: '6063729',
      name_search: 'ROAHOODL',
      name: "Rosa (H) 'Adelaide Hoodless'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5ZNLP7TR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5ZNLP7TR'
    },
    {
      status: 210,
      sku: 'PM0084811',
      core_name: 'Plant',
      sppl_ean: '8720664805590',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063732',
      rtl_batch_array: '6063732',
      name_search: 'ROJCABOT',
      name: "Rosa (H) 'John Cabot'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.629,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AXWL4RZB;NYY54SJ7;CJAP7JLJ;12VR57PW',
      statusContent: 'dot',
      hash: 'H9B9RSVA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9B9RSVA'
    },
    {
      status: 210,
      sku: 'PM0084812',
      core_name: 'Plant',
      sppl_ean: '8720664806306',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063734',
      rtl_batch_array: '6063734',
      name_search: 'ROBATHSH',
      name: 'Rosa (K) Bathsheba',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 550,
      chnn_stock_retail: 550,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6GV2WXWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6GV2WXWD'
    },
    {
      status: 810,
      sku: 'PM0084813',
      core_name: 'Plant',
      sppl_ean: '8720664805767',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063735',
      rtl_batch_array: '6063735',
      name_search: 'RODBUSSE',
      name: 'Rosa (H) Darcey Bussell',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_order_minimum: 10,
      sppl_prcp: 5.658,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2G5GL9WA;CJ3HH21H',
      statusContent: 'dot',
      hash: 'KC5HHLYX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KC5HHLYX'
    },
    {
      status: 810,
      sku: 'PM0084814',
      core_name: 'Plant',
      sppl_ean: '8720664805835',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063738',
      rtl_batch_array: '6063738',
      name_search: 'ROGCELEB',
      name: 'Rosa (H) Golden Celebration',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8NJE63R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8NJE63R'
    },
    {
      status: 210,
      sku: 'PM0084815',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063741',
      rtl_batch_array: '6063741',
      name_search: 'ROMVICTO',
      name: 'Rosa Marie-Victorin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PDEG422L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDEG422L'
    },
    {
      status: 910,
      sku: 'PM0084816',
      core_name: 'Plant',
      sppl_ean: '8720664806634',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063742',
      rtl_batch_array: '6063742',
      name_search: 'RONICOLE',
      name: 'Rosa (T) Nicole',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_order_minimum: 10,
      sppl_prcp: 1.884,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E4SWG2Z7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E4SWG2Z7'
    },
    {
      status: 210,
      sku: 'PM0084817',
      core_name: 'Plant',
      sppl_ean: '8720664806535',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063743',
      rtl_batch_array: '6063743',
      name_search: 'ROANASTA',
      name: 'Rosa (T) Anastasia',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 175,
      chnn_stock_retail: 175,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '719WVT9D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '719WVT9D'
    },
    {
      status: 210,
      sku: 'PM0084818',
      core_name: 'Plant',
      sppl_ean: '8720664806559',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063745',
      rtl_batch_array: '6063745',
      name_search: 'ROAGHARD',
      name: 'Rosa (T) Astrid Gr\u00e4fin von Hardenberg',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 560,
      chnn_stock_retail: 560,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GLRLY6V8',
      statusContent: 'dot',
      hash: 'LBZGLCJD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LBZGLCJD'
    },
    {
      status: 210,
      sku: 'PM0084819',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063746',
      rtl_batch_array: '6063746',
      name_search: 'ROBEVERL',
      name: 'Rosa (T) Beverly',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4W6Y4X37',
      statusContent: 'dot',
      hash: 'L98YLJKP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L98YLJKP'
    },
    {
      status: 210,
      sku: 'PM0084820',
      core_name: 'Plant',
      sppl_ean: '8720664806566',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063748',
      rtl_batch_array: '6063748',
      name_search: 'ROBBACCA',
      name: 'Rosa (T) Black Baccara',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1610,
      chnn_stock_retail: 1610,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2S8RBLRS;S4CNYXYE',
      statusContent: 'dot',
      hash: 'BH71TE81',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BH71TE81'
    },
    {
      status: 210,
      sku: 'PM0084821',
      core_name: 'Plant',
      sppl_ean: '8720664806573',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063749',
      rtl_batch_array: '6063749',
      name_search: 'ROBODORE',
      name: 'Rosa (T) Borneo Odore',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 10,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EL26AS7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EL26AS7G'
    },
    {
      status: 210,
      sku: 'PM0084822',
      core_name: 'Plant',
      sppl_ean: '8720664806580',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063750',
      rtl_batch_array: '6063750',
      name_search: 'ROCAPPUC',
      name: 'Rosa (T) Cappuccino',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1110,
      chnn_stock_retail: 1110,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2SWB9Y3T',
      statusContent: 'dot',
      hash: '51GJACHV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '51GJACHV'
    },
    {
      status: 810,
      sku: 'PM0084823',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063752',
      rtl_batch_array: '6063752',
      name_search: 'RODUFTWO',
      name: 'Rosa (T) Duftwolke',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N1E2Y2DC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N1E2Y2DC'
    },
    {
      status: 210,
      sku: 'PM0084824',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063753',
      rtl_batch_array: '6063753',
      name_search: 'RODUFTZA',
      name: 'Rosa (T) Duftzauber',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 205,
      chnn_stock_retail: 205,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WAA4NDYY;VP2YJXWD;V9P2NE6X;HAS5ZWLZ;2R49RYSA',
      statusContent: 'dot',
      hash: '6AHNN5VD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6AHNN5VD'
    },
    {
      status: 210,
      sku: 'PM0084825',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063757',
      rtl_batch_array: '6063757',
      name_search: 'ROMARYLO',
      name: 'Rosa Marylou',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 105,
      chnn_stock_retail: 105,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RC8C78WZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RC8C78WZ'
    },
    {
      status: 910,
      sku: 'PM0084826',
      core_name: 'Plant',
      sppl_ean: '8720664806610',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063758',
      rtl_batch_array: '6063758',
      name_search: 'ROMAXIM',
      name: 'Rosa (T) Maxim',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7SE1X32H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7SE1X32H'
    },
    {
      status: 210,
      sku: 'PM0084827',
      core_name: 'Plant',
      sppl_ean: '8720664806528',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063760',
      rtl_batch_array: '6063760',
      name_search: 'ROOSIRIA',
      name: "Rosa (T) 'Osiria'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 10,
      sppl_prcp: 1.833,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NSN8S6NN',
      statusContent: 'dot',
      hash: '4P18GSLK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4P18GSLK'
    },
    {
      status: 810,
      sku: 'PM0084828',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063762',
      rtl_batch_array: '6063762',
      name_search: 'ROPMONAC',
      name: 'Rosa (T) Princesse de Monaco',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZNVRA23V',
      statusContent: 'dot',
      hash: 'GVZ3GJ7H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GVZ3GJ7H'
    },
    {
      status: 810,
      sku: 'PM0084829',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063769',
      rtl_batch_array: '6063769',
      name_search: 'ROCMEILL',
      name: 'Rosa (P) Caprice de Meilland',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 85,
      chnn_stock_retail: 85,
      sppl_order_minimum: 10,
      sppl_prcp: 3.108,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RJAR9X66',
      statusContent: 'dot',
      hash: 'JYHJ8C9S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JYHJ8C9S'
    },
    {
      status: 210,
      sku: 'PM0084830',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063770',
      rtl_batch_array: '6063770',
      name_search: 'ROELLE',
      name: 'Rosa (T) Elle',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 320,
      chnn_stock_retail: 320,
      sppl_order_minimum: 10,
      sppl_prcp: 2.904,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YL2KTAR2;J5GD4CJD',
      statusContent: 'dot',
      hash: '44L6A7LN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '44L6A7LN'
    },
    {
      status: 810,
      sku: 'PM0077250',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301314',
      rtl_batch_array: '6301314',
      name_search: 'ECARFEAT',
      name: "Echium amoenum 'Red Feathers'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1PZGHSLR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1PZGHSLR'
    },
    {
      status: 210,
      sku: 'PM0084831',
      core_name: 'Plant',
      sppl_ean: '8720664805552',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063772',
      rtl_batch_array: '6063772',
      name_search: 'ROBALLER',
      name: "Rosa (H) 'Ballerina'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VWKZRHZW;LBXV15RW;7R3RDRLL;PC1RTHWC',
      statusContent: 'dot',
      hash: '9PDH1RTL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9PDH1RTL'
    },
    {
      status: 210,
      sku: 'PM0084832',
      core_name: 'Plant',
      sppl_ean: '8720664806351',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063777',
      rtl_batch_array: '6063777',
      name_search: 'ROSASCHN',
      name: 'Rosa (M) Schneek\u00fcsschen',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 1.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HCY8GH5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HCY8GH5A'
    },
    {
      status: 210,
      sku: 'PM0084833',
      core_name: 'Plant',
      sppl_ean: '8720664805897',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063781',
      rtl_batch_array: '6063781',
      name_search: 'ROJGALWA',
      name: 'Rosa (H) James Galway',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 445,
      chnn_stock_retail: 445,
      sppl_order_minimum: 10,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZK6NX712',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZK6NX712'
    },
    {
      status: 210,
      sku: 'PM0084834',
      core_name: 'Plant',
      sppl_ean: '8720664806009',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063782',
      rtl_batch_array: '6063782',
      name_search: 'ROORAUST',
      name: 'Rosa (H) Olivia Rose Austin',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 5.148,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ENDTJPDR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENDTJPDR'
    },
    {
      status: 810,
      sku: 'PM0084835',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063783',
      rtl_batch_array: '6063783',
      name_search: 'ROALINDG',
      name: 'Rosa (F) Astrid Lindgren',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 45,
      chnn_stock_retail: 45,
      sppl_order_minimum: 10,
      sppl_prcp: 2.394,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'N2TTT6YJ;PSNPLJR4;R12X97XL',
      statusContent: 'dot',
      hash: '7WVD3S4E',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WVD3S4E'
    },
    {
      status: 810,
      sku: 'PM0084836',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063787',
      rtl_batch_array: '6063787',
      name_search: 'ROGSHOWE',
      name: "Rosa (K) 'Golden Showers'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SZ188KDE;1E33RVB9;V39ECAKZ;6HRN1DPV',
      statusContent: 'dot',
      hash: '4R3T7G56',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4R3T7G56'
    },
    {
      status: 210,
      sku: 'PM0084837',
      core_name: 'Plant',
      sppl_ean: '8720664805941',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063788',
      rtl_batch_array: '6063788',
      name_search: 'ROLLUCIA',
      name: 'Rosa (H) Lichtk\u00f6nigin Lucia',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush, Roses climbing',
      sppl_stock_available: 215,
      chnn_stock_retail: 215,
      sppl_order_minimum: 10,
      sppl_prcp: 2.853,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GGLCZ9SE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GGLCZ9SE'
    },
    {
      status: 210,
      sku: 'PM0084838',
      core_name: 'Plant',
      sppl_ean: '8720664805446',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063789',
      rtl_batch_array: '6063789',
      name_search: 'RONEWSFL',
      name: 'Rosa (F) Newsflash',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C7CZANBX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7CZANBX'
    },
    {
      status: 210,
      sku: 'PM0084839',
      core_name: 'Plant',
      sppl_ean: '8720664806337',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063793',
      rtl_batch_array: '6063793',
      name_search: 'ROPTRUST',
      name: "Rosa (K) The Prince's Trust",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 10,
      sppl_prcp: 2.598,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XD7X953L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XD7X953L'
    },
    {
      status: 210,
      sku: 'PM0084840',
      core_name: 'Plant',
      sppl_ean: '8720664806320',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6063797',
      rtl_batch_array: '6063797',
      name_search: 'ROSARROS',
      name: 'Rosa (K) Rambling Rose',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing, Roses dwarf',
      sppl_stock_available: 1160,
      chnn_stock_retail: 1160,
      sppl_prcp: 3.873,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TCL2WG6J;D6536VBZ;TY199D9P;AW33AE9H',
      statusContent: 'dot',
      hash: '41E8VRR3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '41E8VRR3'
    },
    {
      status: 210,
      sku: 'PM0084841',
      core_name: 'Plant',
      sppl_ean: '8720664871427',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 2,
      btch_active_retail: '6164327',
      rtl_batch_array: '6164327, 6220831',
      name_search: 'LAAASNOW',
      name: "Lavandula angustifolia 'Arctic Snow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 107,
      chnn_stock_retail: 1407,
      sppl_order_minimum: 3,
      sppl_prcp: 0.749,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XJJGW15W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XJJGW15W'
    },
    {
      status: 810,
      sku: 'PM0084842',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368963',
      rtl_batch_array: '6368963',
      name_search: 'ROMHILLS',
      name: 'Rosa (RA) Malvern Hills',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_order_minimum: 10,
      sppl_prcp: 5.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '4CKJB4YB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4CKJB4YB'
    },
    {
      status: 810,
      sku: 'PM0084843',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368964',
      rtl_batch_array: '6368964',
      name_search: 'ROAUSBON',
      name: "Rosa 'Ausbonny'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 35,
      chnn_stock_retail: 35,
      sppl_order_minimum: 10,
      sppl_prcp: 5.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2JELE95',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2JELE95'
    },
    {
      status: 210,
      sku: 'PM0084844',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368966',
      rtl_batch_array: '6368966',
      name_search: 'ROAMADIS',
      name: "Rosa 'Amadis'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 10,
      sppl_prcp: 3.47,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HK2SE2PN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HK2SE2PN'
    },
    {
      status: 210,
      sku: 'PM0084845',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368967',
      rtl_batch_array: '6368967',
      name_search: 'ROCEHOLL',
      name: "Rosa (K) 'Climbing \u00c9toile de Hollande'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses climbing',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 10,
      sppl_prcp: 2.85,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4316WVLX',
      statusContent: 'dot',
      hash: '6PGBD881',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PGBD881'
    },
    {
      status: 810,
      sku: 'PM0084846',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368968',
      rtl_batch_array: '6368968',
      name_search: 'ROBOSCOB',
      name: 'Rosa (H) Boscobel',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 30,
      chnn_stock_retail: 30,
      sppl_order_minimum: 10,
      sppl_prcp: 5.15,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2X5VWRX6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2X5VWRX6'
    },
    {
      status: 210,
      sku: 'PM0084847',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368969',
      rtl_batch_array: '6368969',
      name_search: 'ROALLIAN',
      name: 'Rosa (T) Alliance',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_order_minimum: 10,
      sppl_prcp: 3.36,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LL31VD3L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LL31VD3L'
    },
    {
      status: 210,
      sku: 'PM0084848',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368970',
      rtl_batch_array: '6368970',
      name_search: 'ROPPOULS',
      name: "Rosa (F) 'Pernille Poulsen'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1140,
      chnn_stock_retail: 1140,
      sppl_order_minimum: 10,
      sppl_prcp: 2.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DKCAWEVX;J5R473YG;H5WY184N;5TJ38EB3',
      statusContent: 'dot',
      hash: 'CZPHJXS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CZPHJXS3'
    },
    {
      status: 210,
      sku: 'PM0084849',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368971',
      rtl_batch_array: '6368971',
      name_search: 'RODPAVEM',
      name: 'Rosa (R) Dwarf Pavement',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1900,
      chnn_stock_retail: 1900,
      sppl_order_minimum: 10,
      sppl_prcp: 2.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZL9B9S47;TXEXCEGW',
      statusContent: 'dot',
      hash: 'PDR2VGZ3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDR2VGZ3'
    },
    {
      status: 210,
      sku: 'PM0084850',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368972',
      rtl_batch_array: '6368972',
      name_search: 'ROSBBADE',
      name: 'Rosa (T) Souvenir de Baden- Baden',
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 160,
      chnn_stock_retail: 160,
      sppl_order_minimum: 10,
      sppl_prcp: 2.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K72AA73C;GGSDPARZ',
      statusContent: 'dot',
      hash: 'P3B8AP1W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P3B8AP1W'
    },
    {
      status: 210,
      sku: 'PM0042168',
      core_name: 'Plant',
      sppl_ean: '8720626385320',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420744',
      rtl_batch_array: '5420744',
      name_search: 'ACKOLOMI',
      name: 'Actinidia kolomikta',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1443,
      chnn_stock_retail: 1443,
      sppl_order_minimum: 3,
      sppl_prcp: 4.488,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EBYDKRXR;KYS7K31K;S6RX6TGJ;974N3855',
      statusContent: 'dot',
      hash: '4DR2783Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4DR2783Z'
    },
    {
      status: 210,
      sku: 'PM0042199',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244801',
      rtl_batch_array: '6244801',
      name_search: 'LOCZOJKA',
      name: "Lonicera caerulea 'Zojka'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '533296PY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '533296PY'
    },
    {
      status: 210,
      sku: 'PM0042198',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244799',
      rtl_batch_array: '6244799',
      name_search: 'LOCBOREA',
      name: "Lonicera caerulea 'Borealis'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AWE7JENZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AWE7JENZ'
    },
    {
      status: 210,
      sku: 'PM0041426',
      core_name: 'Plant',
      btch_manufacturer: 14143288,
      rtl_batch_array_total: 1,
      btch_active_retail: '6287648',
      rtl_batch_array: '6287648',
      name_search: 'RIUCHGRO',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gr\u00f6n',
      sppl_size_code: '050060C2',
      rng_range_identifier: 'H050060C2',
      rng_range_description: 'H50 cm 60 cm C2',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 3.333,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WRH69A6W',
      statusContent: 'dot',
      hash: 'CA5GJK8H',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CA5GJK8H'
    },
    {
      status: 210,
      sku: 'PM0042176',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244816',
      rtl_batch_array: '6244816',
      name_search: 'VAMPILGR',
      name: "Vaccinium macrocarpon 'Pilgrim'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EZ2WH5T9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZ2WH5T9'
    },
    {
      status: 210,
      sku: 'PM0042197',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244800',
      rtl_batch_array: '6244800',
      name_search: 'LOCWOJTE',
      name: "Lonicera caerulea 'Wojtek'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'TLPYP61D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TLPYP61D'
    },
    {
      status: 210,
      sku: 'PM0042175',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244815',
      rtl_batch_array: '6244815',
      name_search: 'VAMEBLAC',
      name: "Vaccinium macrocarpon 'Early Black'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DV25KVHC',
      statusContent: 'dot',
      hash: 'WXRJTGES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WXRJTGES'
    },
    {
      status: 210,
      sku: 'PM0042188',
      core_name: 'Plant',
      sppl_ean: '8720353030371',
      btch_manufacturer: 734,
      rtl_batch_array_total: 1,
      btch_active_retail: '4706029',
      rtl_batch_array: '4706029',
      name_search: 'ILMKANEH',
      name: 'Ilex maximowicziana kanehirae',
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 2.03,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '98V63WXC;66P9JC43;RYYWT2WL;V76LRJ1B;R46RLEXC;X5599XJC;A5Y3BN3P;7CT4RY8R;WRG1L6YY',
      statusContent: 'dot',
      hash: 'WZ612DG7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZ612DG7'
    },
    {
      status: 210,
      sku: 'PM0042171',
      core_name: 'Plant',
      sppl_ean: '8720664812277',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420731',
      rtl_batch_array: '5420731',
      name_search: 'VITMALEX',
      name: 'Vitis Muscaat van Alexandrie',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 236,
      chnn_stock_retail: 236,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '91WHE5HY',
      statusContent: 'dot',
      hash: 'ZNAA3WG8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZNAA3WG8'
    },
    {
      status: 210,
      sku: 'PM0084910',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370485',
      rtl_batch_array: '6370485',
      name_search: 'CLELIZAB',
      name: "Clematis 'Elizabeth'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 461,
      chnn_stock_retail: 461,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VTS22PDZ;A8BGA75C;KVS2ARVJ',
      statusContent: 'dot',
      hash: '756PEL2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '756PEL2C'
    },
    {
      status: 210,
      sku: 'PM0084911',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370486',
      rtl_batch_array: '6370486',
      name_search: 'CLPPERFE',
      name: "Clematis 'Pink Perfection'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 472,
      chnn_stock_retail: 472,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1XB2T9Y;BPTWEHPR',
      statusContent: 'dot',
      hash: '96AR3P8X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96AR3P8X'
    },
    {
      status: 210,
      sku: 'PM0084912',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370487',
      rtl_batch_array: '6370487',
      name_search: 'CLSUPERB',
      name: "Clematis 'Superba'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 498,
      chnn_stock_retail: 498,
      sppl_order_minimum: 3,
      sppl_prcp: 3.003,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JWVWTZEK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JWVWTZEK'
    },
    {
      status: 210,
      sku: 'PM0084915',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6269454',
      rtl_batch_array: '6269454',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 535,
      chnn_stock_retail: 535,
      sppl_order_minimum: 3,
      sppl_prcp: 1.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: 'NY6AV68D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NY6AV68D'
    },
    {
      status: 210,
      sku: 'PM0084916',
      core_name: 'Plant',
      sppl_ean: '8720664696853',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420653',
      rtl_batch_array: '5420653',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 3637,
      chnn_stock_retail: 3637,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: '785KSV74',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '785KSV74'
    },
    {
      status: 210,
      sku: 'PM0084917',
      core_name: 'Plant',
      sppl_ean: '8720664696907',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420655',
      rtl_batch_array: '5420655',
      name_search: 'LAADBLUE',
      name: "Lavandula angustifolia 'Dwarf Blue'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 6049,
      chnn_stock_retail: 6049,
      sppl_order_minimum: 3,
      sppl_prcp: 1.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1H3ESPR;X1DY9NER;13R414KC;S6ZPVWEE;DN6SPV9W;6H75BN8S',
      statusContent: 'dot',
      hash: '7AVTZ7K6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7AVTZ7K6'
    },
    {
      status: 210,
      sku: 'PM0084918',
      core_name: 'Plant',
      sppl_ean: '8720664697089',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420661',
      rtl_batch_array: '5420661',
      name_search: 'LAVALLAD',
      name: 'Lavandula angustifolia Little Lady',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1670,
      chnn_stock_retail: 1670,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H9A95BWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9A95BWW'
    },
    {
      status: 210,
      sku: 'PM0084919',
      core_name: 'Plant',
      sppl_ean: '8720664697027',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420662',
      rtl_batch_array: '5420662',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 8417,
      chnn_stock_retail: 8417,
      sppl_order_minimum: 3,
      sppl_prcp: 1.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: '9APVS9R9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9APVS9R9'
    },
    {
      status: 210,
      sku: 'PM0084920',
      core_name: 'Plant',
      sppl_ean: '8720664697201',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420664',
      rtl_batch_array: '5420664',
      name_search: 'LAIPHENO',
      name: 'Lavandula intermedia Phenomenal',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1736,
      chnn_stock_retail: 1736,
      sppl_order_minimum: 3,
      sppl_prcp: 1.548,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5DX6CYSH;CBRN3JBS;J1HS51LW;SY7AS57S;DB5JE4VK;8G1BRDBV;CG4KNZJJ',
      statusContent: 'dot',
      hash: '81K665NA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '81K665NA'
    },
    {
      status: 210,
      sku: 'PM0084921',
      core_name: 'Plant',
      sppl_ean: '8720626356542',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420665',
      rtl_batch_array: '5420665',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 3702,
      chnn_stock_retail: 3702,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: 'LPSN4B17',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LPSN4B17'
    },
    {
      status: 210,
      sku: 'PM0084922',
      core_name: 'Plant',
      sppl_ean: '8720664697119',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420668',
      rtl_batch_array: '5420668',
      name_search: 'LAIDUTCH',
      name: "Lavandula intermedia 'Dutch'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 3700,
      chnn_stock_retail: 3700,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJAAHNKA;1J8E2JKW;2V9H9LRW;GGRRVWYR;633VLRTX;7D3E8K1D',
      statusContent: 'dot',
      hash: '82AWYL73',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '82AWYL73'
    },
    {
      status: 210,
      sku: 'PM0084923',
      core_name: 'Plant',
      sppl_ean: '8720626356641',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420670',
      rtl_batch_array: '5420670',
      name_search: 'LAIGRAPP',
      name: "Lavandula intermedia 'Grappenhall'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 1649,
      chnn_stock_retail: 1649,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z2X9WL6C;WK3YH128',
      statusContent: 'dot',
      hash: 'WX3ZE5RB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WX3ZE5RB'
    },
    {
      status: 210,
      sku: 'PM0084924',
      core_name: 'Plant',
      sppl_ean: '8720664697164',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420671',
      rtl_batch_array: '5420671',
      name_search: 'LAIGROSS',
      name: "Lavandula intermedia 'Grosso'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 8475,
      chnn_stock_retail: 8475,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6HYD5KP;JDS3DX51;85DAB5KS;DJ5DBE7H;ET45AAHT;ZBPGWW3W;2Y9A7JYL;VEZL5ZHE;E7GD7PPS;4A8LT5EX;Z35X3T1E;51SXNPZZ;HNEBXXAX;YETJWXWY;L89Y4LET;W2B8EC65;ELKWSKLS;A1GZ59LY;4XW5A5A2;14S47DS5',
      statusContent: 'dot',
      hash: 'T39G9ENH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T39G9ENH'
    },
    {
      status: 210,
      sku: 'PM0084925',
      core_name: 'Plant',
      sppl_ean: '8720626356290',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420673',
      rtl_batch_array: '5420673',
      name_search: 'LABSTAR',
      name: "Lavandula 'Blue Star'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 481,
      chnn_stock_retail: 481,
      sppl_order_minimum: 3,
      sppl_prcp: 1.449,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZV1R5JGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZV1R5JGZ'
    },
    {
      status: 210,
      sku: 'PM0084926',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 2,
      btch_active_retail: '6276622',
      rtl_batch_array: '6276622, 5837439',
      name_search: 'LAIEDELW',
      name: "Lavandula intermedia 'Edelweiss'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1338,
      chnn_stock_retail: 3687,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GN628X7Y;D3BR2DV9;GLJR739N;936WCW3B;V24GK371;WP84S8N3',
      statusContent: 'dot',
      hash: 'WVLD8JJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WVLD8JJP'
    },
    {
      status: 210,
      sku: 'PM0084927',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6276623',
      rtl_batch_array: '6276623',
      name_search: 'LABSTAR',
      name: "Lavandula 'Blue Star'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 524,
      chnn_stock_retail: 524,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PWL11CGT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PWL11CGT'
    },
    {
      status: 210,
      sku: 'PM0084928',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284489',
      rtl_batch_array: '6284489',
      name_search: 'LAADBLUE',
      name: "Lavandula angustifolia 'Dwarf Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 2093,
      chnn_stock_retail: 2093,
      sppl_order_minimum: 3,
      sppl_prcp: 1.163,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1H3ESPR;X1DY9NER;13R414KC;S6ZPVWEE;DN6SPV9W;6H75BN8S',
      statusContent: 'dot',
      hash: 'LZNVB395',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LZNVB395'
    },
    {
      status: 210,
      sku: 'PM0084929',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284947',
      rtl_batch_array: '6284947',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 14357,
      chnn_stock_retail: 14357,
      sppl_order_minimum: 3,
      sppl_prcp: 1.201,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: 'TJC5B5P9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TJC5B5P9'
    },
    {
      status: 210,
      sku: 'PM0084930',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6284948',
      rtl_batch_array: '6284948',
      name_search: 'LABSTAR',
      name: "Lavandula 'Blue Star'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 349,
      chnn_stock_retail: 349,
      sppl_prcp: 2.05,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '8Y8DDC1B',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8Y8DDC1B'
    },
    {
      status: 210,
      sku: 'PM0084931',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188935',
      rtl_batch_array: '6188935',
      name_search: 'LAAALBA',
      name: "Lavandula angustifolia 'Alba'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 683,
      chnn_stock_retail: 683,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VW82LYXX;Z9C62YDX;K231BN72;9CNG75HD;89ZK3NPK',
      statusContent: 'dot',
      hash: 'YZDRLN7G',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YZDRLN7G'
    },
    {
      status: 210,
      sku: 'PM0084932',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188936',
      rtl_batch_array: '6188936',
      name_search: 'LAADBLUE',
      name: "Lavandula angustifolia 'Dwarf Blue'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 729,
      chnn_stock_retail: 729,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L1H3ESPR;X1DY9NER;13R414KC;S6ZPVWEE;DN6SPV9W;6H75BN8S',
      statusContent: 'dot',
      hash: '5WSBJP6T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5WSBJP6T'
    },
    {
      status: 210,
      sku: 'PM0084933',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188937',
      rtl_batch_array: '6188937',
      name_search: 'LAIEDELW',
      name: "Lavandula intermedia 'Edelweiss'",
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 2715,
      chnn_stock_retail: 2715,
      sppl_order_minimum: 3,
      sppl_prcp: 1.3,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GN628X7Y;D3BR2DV9;GLJR739N;936WCW3B;V24GK371;WP84S8N3',
      statusContent: 'dot',
      hash: 'KPJN9HT4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KPJN9HT4'
    },
    {
      status: 210,
      sku: 'PM0084934',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188938',
      rtl_batch_array: '6188938',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 810,
      chnn_stock_retail: 810,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      statusContent: 'dot',
      hash: '9N31E5BY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9N31E5BY'
    },
    {
      status: 210,
      sku: 'PM0084935',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188939',
      rtl_batch_array: '6188939',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 690,
      chnn_stock_retail: 690,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'JZVTTCH3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JZVTTCH3'
    },
    {
      status: 210,
      sku: 'PM0084936',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188940',
      rtl_batch_array: '6188940',
      name_search: 'LAAROSEA',
      name: "Lavandula angustifolia 'Rosea'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 859,
      chnn_stock_retail: 859,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '6VPSP43V;C4PA6KJX;VTD31ZS8;86B4Y7DP;4ZJ2RBPV;J1TE8AYT;P5RSA8S2;NXJ4V9XD;K7J3BELT;EL9GKKWS;CS7EYX2X;V6WZX4KG;P2VG866J;XVPR6S58;VN4N1NHJ;VVNR7CDZ',
      statusContent: 'dot',
      hash: 'H22CXZ86',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H22CXZ86'
    },
    {
      status: 210,
      sku: 'PM0084937',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188942',
      rtl_batch_array: '6188942',
      name_search: 'LAIDUTCH',
      name: "Lavandula intermedia 'Dutch'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 878,
      chnn_stock_retail: 878,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJAAHNKA;1J8E2JKW;2V9H9LRW;GGRRVWYR;633VLRTX;7D3E8K1D',
      statusContent: 'dot',
      hash: 'YRAHZPK5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YRAHZPK5'
    },
    {
      status: 210,
      sku: 'PM0084938',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188943',
      rtl_batch_array: '6188943',
      name_search: 'LAIGROSS',
      name: "Lavandula intermedia 'Grosso'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 900,
      chnn_stock_retail: 900,
      sppl_prcp: 2,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6HYD5KP;JDS3DX51;85DAB5KS;DJ5DBE7H;ET45AAHT;ZBPGWW3W;2Y9A7JYL;VEZL5ZHE;E7GD7PPS;4A8LT5EX;Z35X3T1E;51SXNPZZ;HNEBXXAX;YETJWXWY;L89Y4LET;W2B8EC65;ELKWSKLS;A1GZ59LY;4XW5A5A2;14S47DS5',
      statusContent: 'dot',
      hash: 'ETX1ZT4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETX1ZT4A'
    },
    {
      status: 210,
      sku: 'PM0084939',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6188944',
      rtl_batch_array: '6188944',
      name_search: 'LASPEDUN',
      name: 'Lavandula stoechas pedunculata',
      sppl_size_code: '015020C14',
      rng_range_identifier: 'H015020C1.5',
      rng_range_description: 'H15 cm 20 cm C1.5',
      sppl_stock_available: 581,
      chnn_stock_retail: 581,
      sppl_order_minimum: 3,
      sppl_prcp: 1.449,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6NL1BH7V;8CTSDG3R;72ABPNPY;B5LGPAL2;ZDVV28V1',
      statusContent: 'dot',
      hash: '994R416Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '994R416Z'
    },
    {
      status: 210,
      sku: 'PM0084940',
      core_name: 'Plant',
      btch_manufacturer: 1205,
      rtl_batch_array_total: 1,
      btch_active_retail: '5488238',
      rtl_batch_array: '5488238',
      name_search: 'PEARUBRU',
      name: "Pennisetum advena 'Rubrum'",
      sppl_size_code: 'LEVC3',
      rng_range_identifier: 'C3',
      rng_range_description: 'C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.624,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'J6C25DWW;VJT3ZRAP;ZACZP7TZ;EW59DX9G;N2WYZ1C9;NRLDVYX5;LGD4ZEEG;S4EEAAZR;WVHDRZ99;PBZLX9EG;RTEYHY9B;J9PWY479;98V4VGKK;LWEZ8D7E;TZBW9958;KGX25VCY;KD69RY4A;C9NPZ1N9;738Z415A;SP67698E;LZKLAEVE;RCNZHP1N;TP5Z8GWT;K3Z66JDE;5HN3S3YJ;YPYWCGB2;K3TBB8R4;H42WWAWD',
      imageBatch: '7415B261',
      statusContent: 'dot',
      hash: 'PDLJAAKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PDLJAAKD'
    },
    {
      status: 210,
      sku: 'PM0084941',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6317469',
      rtl_batch_array: '6317469',
      name_search: 'CAPCDBLU',
      name: "Campanula p. 'Clockwise Deep Blue'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1487,
      chnn_stock_retail: 1487,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NA7W349',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NA7W349'
    },
    {
      status: 210,
      sku: 'PM0084942',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6305216',
      rtl_batch_array: '6305216',
      name_search: 'AUABSHAD',
      name: "Aubrieta 'Audrey Blue Shades'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 530,
      chnn_stock_retail: 530,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5GVSCZ3A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5GVSCZ3A'
    },
    {
      status: 210,
      sku: 'PM0084943',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6308785',
      rtl_batch_array: '6308785',
      name_search: 'LAIDUTCH',
      name: "Lavandula intermedia 'Dutch'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 1375,
      chnn_stock_retail: 1375,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VJAAHNKA;1J8E2JKW;2V9H9LRW;GGRRVWYR;633VLRTX;7D3E8K1D',
      statusContent: 'dot',
      hash: 'A27KVCVH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A27KVCVH'
    },
    {
      status: 210,
      sku: 'PM0084944',
      core_name: 'Plant',
      btch_manufacturer: 706,
      rtl_batch_array_total: 1,
      btch_active_retail: '6359697',
      rtl_batch_array: '6359697',
      name_search: 'PRLROTUN',
      name: "Prunus laurocerasus 'Rotundifolia'",
      sppl_size_code: '040050BW',
      rng_range_identifier: 'H040050BR',
      rng_range_description: 'H40 cm 50 cm Bare root',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_order_minimum: 10,
      sppl_prcp: 0.735,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'R89GELL8;XH13AESY;7WRA91W3;5Z611GX6;SJAJDD38;6SWV8W1W;AGED26K4;T11GAZ8J;6YP7SAWR;9T6CSD6P;WH7P2KZ7;JN8ELK85;18TL4GNR;4PCHXGVG',
      statusContent: 'dot',
      hash: 'H24VW673',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H24VW673'
    },
    {
      status: 210,
      sku: 'PM0084945',
      core_name: 'Plant',
      btch_manufacturer: 198,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345737',
      rtl_batch_array: '6345737',
      name_search: 'PRLELLY',
      name: 'Prunus laurocerasus Elly',
      sppl_size_code: '050060C7',
      rng_range_identifier: 'H050060C7',
      sppl_stock_available: 450,
      chnn_stock_retail: 450,
      sppl_prcp: 6.288,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'XJ57CBWE;6AXPN8H1;L7GND3T7;W9WJTKS2',
      statusContent: 'dot',
      hash: 'S1PSY5BR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1PSY5BR'
    },
    {
      status: 910,
      sku: 'PM0084946',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ELEBBING',
      name: 'Elaeagnus ebbingei',
      rng_range_identifier: 'SHI010012RBWIR',
      rng_range_description: 'High stem 10 cm 12 cm Root ball Wire basket',
      imageCore: 'HR1CJBW1',
      statusContent: 'dot',
      hash: '911EL1VC',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '911EL1VC'
    },
    {
      status: 910,
      sku: 'PM0084947',
      core_name: 'Plant',
      btch_manufacturer: 563,
      rtl_batch_array_total: 1,
      btch_active_retail: '6367987',
      rtl_batch_array: '6367987',
      name_search: 'LISWORPL',
      name: "Liquidambar styraciflua 'Worplesdon'",
      sppl_size_code: '014016LEIDK',
      rng_range_identifier: 'PLEA014016RBWIR',
      rng_range_description: 'Pleached 14 cm 16 cm Root ball Wire basket',
      sppl_stock_available: 1,
      chnn_stock_retail: 1,
      sppl_prcp: 126.25,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '8EGDRGJ6;KXVD9AXA',
      statusContent: 'dot',
      hash: 'NTPERH1A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NTPERH1A'
    },
    {
      status: 910,
      sku: 'PM0084948',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'QUILEX',
      name: 'Quercus ilex',
      rng_range_identifier: 'SHI014016RBWIR',
      rng_range_description: 'High stem 14 cm 16 cm Root ball Wire basket',
      imageCore:
        'DR5DPRCS;XH18JNW6;JBTEXX89;CT4D23BB;JCG22BWX;5Z4D6HLW;CD4Z6ENX;E9ZCG67T;EH758SWC;78E473HE;LHRB4LTH;7ZARBDER;HXSBN5E2',
      statusContent: 'dot',
      hash: 'P37RVYCL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'P37RVYCL'
    },
    {
      status: 210,
      sku: 'PM0084949',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368791',
      rtl_batch_array: '6368791',
      name_search: 'ACCAREND',
      name: "Aconitum carmichaelii 'Arendsii'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EK1DS4YP;1JHYCNS3',
      statusContent: 'dot',
      hash: 'H2ZVZ2YG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2ZVZ2YG'
    },
    {
      status: 210,
      sku: 'PM0084950',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368793',
      rtl_batch_array: '6368793',
      name_search: 'DEMFOUNT',
      name: "Delphinium (P) 'Magic Fountains'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6PSEDHRW;9ZWWDBPD',
      statusContent: 'dot',
      hash: 'EP46E9RW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EP46E9RW'
    },
    {
      status: 210,
      sku: 'PM0084951',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368794',
      rtl_batch_array: '6368794',
      name_search: 'LISKOBOL',
      name: "Liatris spicata 'Kobold'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5B5GENNE;CL16HNJ3;ZTDASLE6;BC94D4EC;GV5NX2PY;XEBRDZEH;VPTL1N53;212TTE5R;AT4XWSS9;T3SBT4DS;ZDWEE1DS;8T6C2S56;6GW9XA89',
      statusContent: 'dot',
      hash: '4T6ALK8V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4T6ALK8V'
    },
    {
      status: 210,
      sku: 'PM0084952',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368796',
      rtl_batch_array: '6368796',
      name_search: 'PAORQUEE',
      name: "Papaver orientale 'Raspberry Queen'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYZZ1SKT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYZZ1SKT'
    },
    {
      status: 210,
      sku: 'PM0084953',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368798',
      rtl_batch_array: '6368798',
      name_search: 'ASPHARRI',
      name: "Aster (D) 'Peter Harrison'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWN589DE;WYNZC35D;LTLVAK7Y;ZTRC9XCA;Y9RRAV7K',
      statusContent: 'dot',
      hash: '8YRK2K2P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YRK2K2P'
    },
    {
      status: 210,
      sku: 'PM0084954',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368801',
      rtl_batch_array: '6368801',
      name_search: 'ASANNEKE',
      name: "Aster (D) 'Anneke'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'PYA4LDAS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PYA4LDAS'
    },
    {
      status: 210,
      sku: 'PM0084955',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368804',
      rtl_batch_array: '6368804',
      name_search: 'PAOPWHIT',
      name: "Papaver orientale 'Perry's White'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'H41AHXST;D1AY3YNZ',
      statusContent: 'dot',
      hash: '2SXD13HJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SXD13HJ'
    },
    {
      status: 210,
      sku: 'PM0084956',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368806',
      rtl_batch_array: '6368806',
      name_search: 'KNFLAMEN',
      name: "Kniphofia 'Flamenco'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'H2D6HYAT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2D6HYAT'
    },
    {
      status: 210,
      sku: 'PM0084957',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368807',
      rtl_batch_array: '6368807',
      name_search: 'HEHBHEAR',
      name: "Heliopsis helianthoides 'Burning Hearts'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.512,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B75CC5Y1',
      statusContent: 'dot',
      hash: '9CCCSVPA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9CCCSVPA'
    },
    {
      status: 210,
      sku: 'PM0084958',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368808',
      rtl_batch_array: '6368808',
      name_search: 'DEMFPSHA',
      name: "Delphinium 'Magic Fountains Pink Shades'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NGT5AZLT',
      statusContent: 'dot',
      hash: 'GYC95ZZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GYC95ZZ2'
    },
    {
      status: 210,
      sku: 'PM0084959',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368810',
      rtl_batch_array: '6368810',
      name_search: 'GEHPLENU',
      name: "Geranium himalayense 'Plenum'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9VBS3BEG;ATA2BKZT',
      statusContent: 'dot',
      hash: '23B8GTWD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '23B8GTWD'
    },
    {
      status: 210,
      sku: 'PM0084960',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368811',
      rtl_batch_array: '6368811',
      name_search: 'PABARTZE',
      name: "Paeonia (I) 'Bartzella'",
      sppl_size_code: 'LEVC54L21PL',
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 10.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'BBKRW3XN;GPADPRYN;2S79RNDZ;W1VPE3B5',
      statusContent: 'dot',
      hash: 'XZKLA36P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XZKLA36P'
    },
    {
      status: 210,
      sku: 'PM0084961',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368812',
      rtl_batch_array: '6368812',
      name_search: 'ASSAMOA',
      name: "Aster 'Samoa'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 1,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X2LBT83L;4ZS6C3NA;HH1ZELK6;NRR7ZNCG;BEJA8ZEV;9C15CXXT;4Z4BLHW5',
      statusContent: 'dot',
      hash: 'H2Z135EA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H2Z135EA'
    },
    {
      status: 210,
      sku: 'PM0084962',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368814',
      rtl_batch_array: '6368814',
      name_search: 'DESSKIES',
      name: "Delphinium (P) 'Summer Skies'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'WYAL955B;X75HHVY7;4KWWT21S',
      statusContent: 'dot',
      hash: '8RVS4LYL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8RVS4LYL'
    },
    {
      status: 210,
      sku: 'PM0084963',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368815',
      rtl_batch_array: '6368815',
      name_search: 'GESELKE',
      name: "Geranium sanguineum 'Elke'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'C3ERRJSK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C3ERRJSK'
    },
    {
      status: 210,
      sku: 'PM0084964',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368817',
      rtl_batch_array: '6368817',
      name_search: 'ASLBLUE',
      name: "Aster (D) 'Lady in Blue'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.41,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'RXWS95T8;VYD8H728;11PP25P3;WB45553X;G5X9H2JE;W4RWJP6N;JT96Z8V1',
      statusContent: 'dot',
      hash: '15S9DY87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '15S9DY87'
    },
    {
      status: 210,
      sku: 'PM0084965',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368819',
      rtl_batch_array: '6368819',
      name_search: 'GESAPFEL',
      name: "Geranium sanguineum 'Apfelblute'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KK1R3SE8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KK1R3SE8'
    },
    {
      status: 210,
      sku: 'PM0084966',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368820',
      rtl_batch_array: '6368820',
      name_search: 'ACCRFLUS',
      name: "Aconitum carmichaelii 'Royal Flush'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RPBXG7EK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RPBXG7EK'
    },
    {
      status: 210,
      sku: 'PM0084967',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368821',
      rtl_batch_array: '6368821',
      name_search: 'PUVPGLOC',
      name: "Pulsatilla vulgaris 'Perlen Glocke'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S353W7A3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S353W7A3'
    },
    {
      status: 210,
      sku: 'PM0084968',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368822',
      rtl_batch_array: '6368822',
      name_search: 'GESPPOUF',
      name: "Geranium sanguineum 'Pink Pouffe'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZX2WA7Z2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZX2WA7Z2'
    },
    {
      status: 210,
      sku: 'PM0084969',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368823',
      rtl_batch_array: '6368823',
      name_search: 'ECLRED',
      name: 'Echinacea Lakota Red',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.563,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '571YT6S2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '571YT6S2'
    },
    {
      status: 210,
      sku: 'PM0084970',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368824',
      rtl_batch_array: '6368824',
      name_search: 'KNCAULES',
      name: 'Kniphofia caulescens',
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5JZKWBZZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5JZKWBZZ'
    },
    {
      status: 210,
      sku: 'PM0084971',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 2,
      btch_active_retail: '6368825',
      rtl_batch_array: '6368825, 6368826',
      name_search: 'KNRROCKE',
      name: "Kniphofia 'Red Rocket'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 2000,
      sppl_prcp: 1.614,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XR62HCB5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XR62HCB5'
    },
    {
      status: 210,
      sku: 'PM0084972',
      core_name: 'Plant',
      btch_manufacturer: 1277,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368827',
      rtl_batch_array: '6368827',
      name_search: 'ALRNIGRA',
      name: "Alcea rosea 'Nigra'",
      sppl_size_code: 'LEVP1110T',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.461,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AEKZNZ45',
      statusContent: 'dot',
      hash: 'D3ZEYXR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D3ZEYXR8'
    },
    {
      status: 210,
      sku: 'PM0084973',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370318',
      rtl_batch_array: '6370318',
      name_search: 'ARCPINKI',
      name: "Arabis caucasica 'Pinkie'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 117,
      chnn_stock_retail: 117,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '1XTTHASL;Y8TPCR1R;WKD7X6XZ;HNAA3XE5;ZBCSAW96;R7KYZZXC;T3NYL1TJ;4G7TPC81;C7YEKCZL',
      statusContent: 'dot',
      hash: 'ZAJDL5JX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZAJDL5JX'
    },
    {
      status: 810,
      sku: 'PM0084974',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370319',
      rtl_batch_array: '6370319',
      name_search: 'AJPMCRIS',
      name: "Ajuga pyramidalis 'Metallica Crispa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GE3LKJYH',
      statusContent: 'dot',
      hash: '56X91XRS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '56X91XRS'
    },
    {
      status: 210,
      sku: 'PM0084975',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386049',
      rtl_batch_array: '6370320, 6386049',
      name_search: 'ANMYEDOE',
      name: 'Anaphalis margaritacea yedoensis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 380,
      sppl_order_minimum: 3,
      sppl_prcp: 0.94,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N858RYV6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N858RYV6'
    },
    {
      status: 210,
      sku: 'PM0084976',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370321',
      rtl_batch_array: '6370321',
      name_search: 'AQUIVBBA',
      name: "Aquilegia v. 'Bordeaux Barlow'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8JGW68A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8JGW68A'
    },
    {
      status: 210,
      sku: 'PM0084977',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370322',
      rtl_batch_array: '6370322',
      name_search: 'DECBLANC',
      name: "Delphinium (B) 'Casa Blanca'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 332,
      chnn_stock_retail: 332,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '92J6P2AZ;LSZXADCN;6G4BB2ZP',
      statusContent: 'dot',
      hash: 'JTRVDSKC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTRVDSKC'
    },
    {
      status: 210,
      sku: 'PM0084978',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370325',
      rtl_batch_array: '6370325',
      name_search: 'ASAGOLIA',
      name: "Aster alpinus 'Goliath'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EZYJJKGK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EZYJJKGK'
    },
    {
      status: 810,
      sku: 'PM0084979',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370327',
      rtl_batch_array: '6370327',
      name_search: 'ASASTERN',
      name: "Aster amellus 'Sternkugel'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 80,
      chnn_stock_retail: 80,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L2JBHLAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L2JBHLAE'
    },
    {
      status: 210,
      sku: 'PM0084980',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370332',
      rtl_batch_array: '6370332',
      name_search: 'ASSIBIRI',
      name: 'Aster sibiricus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EKP3GYXV',
      statusContent: 'dot',
      hash: 'YT9BZ9XT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YT9BZ9XT'
    },
    {
      status: 810,
      sku: 'PM0084981',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370333',
      rtl_batch_array: '6370333',
      name_search: 'ASMRUBRA',
      name: "Astrantia major 'Rubra'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 48,
      chnn_stock_retail: 48,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECSCTHBS',
      statusContent: 'dot',
      hash: 'B5J55C2X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B5J55C2X'
    },
    {
      status: 210,
      sku: 'PM0084982',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370334',
      rtl_batch_array: '6370334',
      name_search: 'AURCARPE',
      name: "Aubrieta 'Red Carpet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 128,
      chnn_stock_retail: 128,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZGZ8NT4P',
      statusContent: 'dot',
      hash: 'T5XV8W51',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'T5XV8W51'
    },
    {
      status: 210,
      sku: 'PM0084983',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370338',
      rtl_batch_array: '6370338',
      name_search: 'CEGIGANT',
      name: 'Cephalaria gigantea',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 329,
      chnn_stock_retail: 329,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W6X5ZCKN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W6X5ZCKN'
    },
    {
      status: 210,
      sku: 'PM0084984',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370344',
      rtl_batch_array: '6370344',
      name_search: 'DIDORIS',
      name: "Dianthus (PL) 'Doris'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 119,
      chnn_stock_retail: 119,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YH6BE2L1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YH6BE2L1'
    },
    {
      status: 210,
      sku: 'PM0084985',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370346',
      rtl_batch_array: '6370346',
      name_search: 'ECRPBLUE',
      name: "Echinops ritro 'Platinum Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'HXZGCEB3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HXZGCEB3'
    },
    {
      status: 210,
      sku: 'PM0084986',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370351',
      rtl_batch_array: '6370351',
      name_search: 'ERCCHEER',
      name: "Erysimum 'Constant Cheer'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GPRDD427;7ZGWNBJ2;8XT2EJVG;5T7ESZTR;BGH69X8C;V6DE92WC;K84866EB;CZCA1C4X;WG6HJYCP',
      statusContent: 'dot',
      hash: 'HJGVGGV3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'HJGVGGV3'
    },
    {
      status: 210,
      sku: 'PM0084987',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370354',
      rtl_batch_array: '6370354',
      name_search: 'EUMJHUMB',
      name: "Eupatorium maculatum 'JS Humble'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 297,
      chnn_stock_retail: 297,
      sppl_order_minimum: 3,
      sppl_prcp: 1.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XNSDJ78T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNSDJ78T'
    },
    {
      status: 210,
      sku: 'PM0084988',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370357',
      rtl_batch_array: '6370357',
      name_search: 'EUGDIXTE',
      name: "Euphorbia griffithii 'Dixter'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.507,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ASPV5S27',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ASPV5S27'
    },
    {
      status: 210,
      sku: 'PM0084989',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370359',
      rtl_batch_array: '6370359',
      name_search: 'FEVSILBE',
      name: "Festuca valesiaca 'Silbersee'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JJ28613X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JJ28613X'
    },
    {
      status: 210,
      sku: 'PM0084990',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370361',
      rtl_batch_array: '6370361',
      name_search: 'GESORNAT',
      name: 'Gentiana sino-ornata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 121,
      chnn_stock_retail: 121,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'K3C631EE;5DH25KS2;P5RYH6YG',
      statusContent: 'dot',
      hash: 'H4B8R688',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H4B8R688'
    },
    {
      status: 210,
      sku: 'PM0084991',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370364',
      rtl_batch_array: '6370364',
      name_search: 'GYRROSEA',
      name: "Gypsophila repens 'Rosea'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 155,
      chnn_stock_retail: 155,
      sppl_order_minimum: 3,
      sppl_prcp: 0.607,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCR99BXL;YAPE14TL;GKN27CRC',
      statusContent: 'dot',
      hash: 'H9ZGTP92',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'H9ZGTP92'
    },
    {
      status: 810,
      sku: 'PM0084992',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370365',
      rtl_batch_array: '6370365',
      name_search: 'HEOWLADY',
      name: "Helleborus orientalis 'White Lady'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 2.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2SNNXXPZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2SNNXXPZ'
    },
    {
      status: 210,
      sku: 'PM0084993',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370369',
      rtl_batch_array: '6370369',
      name_search: 'LAMPDRAG',
      name: "Lamium maculatum 'Purple Dragon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SHKL3T8K',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SHKL3T8K'
    },
    {
      status: 210,
      sku: 'PM0084994',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370371',
      rtl_batch_array: '6370371',
      name_search: 'LEDMINIM',
      name: "Leptinella dioica 'Minima'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '14YA5GX9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '14YA5GX9'
    },
    {
      status: 210,
      sku: 'PM0084995',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370375',
      rtl_batch_array: '6370375',
      name_search: 'MEREQUIE',
      name: 'Mentha requienii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 377,
      chnn_stock_retail: 377,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'X22LPCC4;N6HY47NJ',
      statusContent: 'dot',
      hash: '8E1LNGWP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8E1LNGWP'
    },
    {
      status: 210,
      sku: 'PM0084996',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370377',
      rtl_batch_array: '6370377',
      name_search: 'MOSCORPI',
      name: "Monarda 'Scorpion'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 583,
      chnn_stock_retail: 583,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EPB22ZTV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EPB22ZTV'
    },
    {
      status: 810,
      sku: 'PM0084997',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370379',
      rtl_batch_array: '6370379, 6386158',
      name_search: 'PAOREMBR',
      name: "Papaver orientale 'Rembrandt'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 450,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X4HRNTAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X4HRNTAL'
    },
    {
      status: 210,
      sku: 'PM0084998',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370384',
      rtl_batch_array: '6370384',
      name_search: 'PHDCPERF',
      name: "Phlox divaricata 'Clouds of Perfume'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 671,
      chnn_stock_retail: 671,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SB1WXB5B;9LHJXPLY;6DY4H897;H4EB1B39;DX528L1S;RH4K9KJ9;7XW1KSEN',
      statusContent: 'dot',
      hash: '6BRN6968',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6BRN6968'
    },
    {
      status: 810,
      sku: 'PM0084999',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370385',
      rtl_batch_array: '6370385',
      name_search: 'PHDMBREE',
      name: "Phlox divaricata 'May Breeze'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 97,
      chnn_stock_retail: 97,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '55L5DYS5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '55L5DYS5'
    },
    {
      status: 210,
      sku: 'PM0085000',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370386',
      rtl_batch_array: '6370386',
      name_search: 'PHSBAVAR',
      name: "Phlox subulata 'Bavaria'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'V8VPXJ8T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V8VPXJ8T'
    },
    {
      status: 210,
      sku: 'PM0085001',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370387',
      rtl_batch_array: '6370387',
      name_search: 'PRDENTIC',
      name: 'Primula denticulata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 292,
      chnn_stock_retail: 292,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A8TDGTR4;5TSAYTKA;4J2A6R67;AVY4PVCN;E7248798;H2GDAHBV;1XYZNSH8;V4X2DC33;2LAZ6WWX;TR2E5DVV;6HRRY3YT;61SL26R3;KV6L2EDS;2TCZP5XC;WBX8H37X;C33XD5X2;AVV21988;5W4BG84D;7NZJ33CJ;J21YAAKY;KL4YWJKH',
      statusContent: 'dot',
      hash: 'C78Z72EN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C78Z72EN'
    },
    {
      status: 210,
      sku: 'PM0085002',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370389',
      rtl_batch_array: '6370389',
      name_search: 'PRUVULGA',
      name: 'Prunella vulgaris',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 265,
      chnn_stock_retail: 265,
      sppl_order_minimum: 3,
      sppl_prcp: 0.707,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D6Z7744E;CD5LJXG4;SHVLND9H;RNN11NDN;YBSGXT4S;6CN8R6L9;CYD3RLJ8;8TZ3NT29',
      statusContent: 'dot',
      hash: '8L3AP98N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8L3AP98N'
    },
    {
      status: 210,
      sku: 'PM0085003',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370394',
      rtl_batch_array: '6370394',
      name_search: 'STLALBA',
      name: "Stokesia laevis 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '58AZR4GJ;W796BZLR',
      statusContent: 'dot',
      hash: 'R79BJBCV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R79BJBCV'
    },
    {
      status: 210,
      sku: 'PM0085004',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370395',
      rtl_batch_array: '6370395',
      name_search: 'THLONGIC',
      name: 'Thymus longicaulis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6KWZ8THZ',
      statusContent: 'dot',
      hash: 'RZG4XW16',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RZG4XW16'
    },
    {
      status: 210,
      sku: 'PM0085005',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6370397',
      rtl_batch_array: '6370397, 6386206',
      name_search: 'TRNMOON',
      name: "Trollius 'New Moon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 510,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RHPASS9A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RHPASS9A'
    },
    {
      status: 210,
      sku: 'PM0085006',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370398',
      rtl_batch_array: '6370398',
      name_search: 'VEBALBIF',
      name: "Verbascum blattaria 'Albiflorum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LR7H6WTC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LR7H6WTC'
    },
    {
      status: 210,
      sku: 'PM0085007',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370400',
      rtl_batch_array: '6370400',
      name_search: 'VEUGBLUE',
      name: "Veronica umbrosa 'Georgia Blue'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 534,
      chnn_stock_retail: 534,
      sppl_order_minimum: 3,
      sppl_prcp: 0.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7KZ9RCEZ;4C9L6PB6;CSATLEW1;R5BY45WX;W6LHELD9;RXEK6VTJ;L1ELVDDC;2N92RH1W;1D1RC258',
      statusContent: 'dot',
      hash: 'J5LH78AG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J5LH78AG'
    },
    {
      status: 210,
      sku: 'PM0085008',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370482',
      rtl_batch_array: '6370482',
      name_search: 'LIMBBLUE',
      name: "Liriope muscari 'Big Blue'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 9000,
      chnn_stock_retail: 9000,
      sppl_order_minimum: 3,
      sppl_prcp: 1.679,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L75YKEBB',
      statusContent: 'dot',
      hash: '73PGK7AX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '73PGK7AX'
    },
    {
      status: 210,
      sku: 'PM0085009',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370483',
      rtl_batch_array: '6370483',
      name_search: 'LIMMWHIT',
      name: "Liriope muscari 'Monroe White'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 1700,
      chnn_stock_retail: 1700,
      sppl_order_minimum: 3,
      sppl_prcp: 1.973,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AYLD59KE;4NY9BBP2;28G2AKK8;D4B3XNSA;T4SJCPL6;KVVDLPV1;HGER8DSK;DV6AZ3ST;XED3AWGH;P4RDERDW;VETGZ9Y7',
      statusContent: 'dot',
      hash: '71K6V6DW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71K6V6DW'
    },
    {
      status: 210,
      sku: 'PM0085010',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370490',
      rtl_batch_array: '6370490',
      name_search: 'CABUCHAN',
      name: 'Carex buchananii',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 735,
      chnn_stock_retail: 735,
      sppl_prcp: 1.852,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AZDKHX3N;AHX69GCL;2R2BE5NB;S5B9ZHAT;TALW8N1E;JD8NYJGA;NTP2R168;SND92JHD;2YLJC5S8;E92HXVG6;BTHEKZ8C;LBVWDCZR',
      statusContent: 'dot',
      hash: '2DCXCB59',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2DCXCB59'
    },
    {
      status: 810,
      sku: 'PM0085011',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370493',
      rtl_batch_array: '6370493',
      name_search: 'SPARGUTA',
      name: 'Spiraea arguta',
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5Z6DADP;12PJW6AK;HJ4EGT6A',
      statusContent: 'dot',
      hash: 'LW3GGNN3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LW3GGNN3'
    },
    {
      status: 210,
      sku: 'PM0085012',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370494',
      rtl_batch_array: '6370494',
      name_search: 'SPBILLAR',
      name: 'Spiraea billardii',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.728,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BXLT2BT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BXLT2BT'
    },
    {
      status: 210,
      sku: 'PM0042228',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244797',
      rtl_batch_array: '6244797',
      name_search: 'HIRLEIKO',
      name: "Hippophae rhamnoides 'Leikora'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A6WNPYER',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6WNPYER'
    },
    {
      status: 210,
      sku: 'PM0042229',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244798',
      rtl_batch_array: '6244798',
      name_search: 'HIRPOLLM',
      name: "Hippophae rhamnoides 'Pollmix'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J638WG8Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J638WG8Y'
    },
    {
      status: 210,
      sku: 'PM0042278',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244805',
      rtl_batch_array: '6244805',
      name_search: 'SANKORSO',
      name: "Sambucus nigra 'Kors\u00f8r'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.778,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LYBG6RH8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LYBG6RH8'
    },
    {
      status: 810,
      sku: 'PM0077255',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301366',
      rtl_batch_array: '6301366',
      name_search: 'ACSAFRAN',
      name: "Achillea 'Safran'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7E3NRBC2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E3NRBC2'
    },
    {
      status: 210,
      sku: 'PM0042227',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244796',
      rtl_batch_array: '6244796',
      name_search: 'HIRFORAN',
      name: "Hippophae rhamnoides 'Friesdorfer Orange'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 990,
      chnn_stock_retail: 990,
      sppl_prcp: 2.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R2RPV7KY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R2RPV7KY'
    },
    {
      status: 210,
      sku: 'PM0077254',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301364',
      rtl_batch_array: '6301364',
      name_search: 'ACMSCHNE',
      name: "Achillea millefolium 'Schneetaler'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1359,
      chnn_stock_retail: 1359,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JNPVH3ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JNPVH3ZS'
    },
    {
      status: 810,
      sku: 'PM0077253',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301353',
      rtl_batch_array: '6301353',
      name_search: 'GALSNOWS',
      name: "Gaura lindheimeri 'Snowstorm'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 77,
      chnn_stock_retail: 77,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '74XAKSH9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '74XAKSH9'
    },
    {
      status: 210,
      sku: 'PM0084851',
      core_name: 'Plant',
      btch_manufacturer: 690,
      rtl_batch_array_total: 1,
      btch_active_retail: '6368973',
      rtl_batch_array: '6368973',
      name_search: 'ROWTIME',
      name: "Rosa (T) 'Waltz Time'",
      sppl_size_code: 'LEVBWA',
      rng_range_identifier: 'BR',
      rng_range_description: 'Bare root',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 10,
      sppl_prcp: 1.83,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TN724EAW;C7P4PATR;XEKTES92;47JSD8LV;XZR2K9ZD',
      statusContent: 'dot',
      hash: 'CN9BDX4D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CN9BDX4D'
    },
    {
      status: 810,
      sku: 'PM0084913',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370488',
      rtl_batch_array: '6370488',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '060080C74',
      rng_range_identifier: 'H060080C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 6.54,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'R173DCC4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R173DCC4'
    },
    {
      status: 810,
      sku: 'PM0084914',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370489',
      rtl_batch_array: '6370489',
      name_search: 'JUSBARRO',
      name: "Juniperus scopulorum 'Blue Arrow'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 7.65,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D8JST5ST;2DAW3RLL;T99VB72N;4D3YES7X;JENBJJEG;HXLV1RDV;923C8GGD;HRP4D9HJ',
      statusContent: 'dot',
      hash: 'XNH4YPCG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XNH4YPCG'
    },
    {
      status: 910,
      sku: 'PM0042304',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'VACG23',
      name: "Vaccinium corymbosum 'Goldtraube 23'",
      rng_range_identifier: 'H040050C3.5',
      rng_range_description: 'H40 cm 50 cm C3.5',
      statusContent: 'dot',
      hash: 'YYTTEV85',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YYTTEV85'
    },
    {
      status: 210,
      sku: 'PM0085013',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370495',
      rtl_batch_array: '6370495',
      name_search: 'SPVANHOU',
      name: 'Spiraea vanhouttei',
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 2.924,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '4RE218KD;VLG9VHL1;9WW2BDZ3;R11ERJYV;YSAH6D7E;2JWR7BRE;66HBB5EH;SYB4J6GJ;YTC56S5X;5YWH3WJT;8VNRB4SL;PG2NA5YS',
      statusContent: 'dot',
      hash: 'RS2SYYT2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RS2SYYT2'
    },
    {
      status: 810,
      sku: 'PM0085014',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370496',
      rtl_batch_array: '6370496',
      name_search: 'WEROSEA',
      name: "Weigela 'Rosea'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'RNXX1B1J',
      statusContent: 'dot',
      hash: 'B3XXJ4B7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B3XXJ4B7'
    },
    {
      status: 810,
      sku: 'PM0085015',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370497',
      rtl_batch_array: '6370497',
      name_search: 'COASIBIR',
      name: "Cornus alba 'Sibirica'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 7.493,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5NPPHKR1;897L4ZT6;516VGYAD;L7T55952;HB725R4Z;GNXTVVD5;9Z4XDDN6;C3LJCNW7;LTP26LS4;88W28PHC;CVTWENZV;1V99KXV9;KV4ESLZ2;DJH8X159;Z3H2JR3T;7YS3AVXY;77JR8NVR',
      statusContent: 'dot',
      hash: 'EXL39CW8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EXL39CW8'
    },
    {
      status: 910,
      sku: 'PM0085016',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370498',
      rtl_batch_array: '6370498',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '060080C3',
      rng_range_identifier: 'H060080C3',
      rng_range_description: 'H60 cm 80 cm C3',
      sppl_stock_available: 15,
      chnn_stock_retail: 15,
      sppl_prcp: 3.414,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'V5X6GSXC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V5X6GSXC'
    },
    {
      status: 210,
      sku: 'PM0085017',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370500',
      rtl_batch_array: '6370500',
      name_search: 'PHCORONA',
      name: 'Philadelphus coronarius',
      sppl_size_code: '050060C3',
      rng_range_identifier: 'H050060C3',
      rng_range_description: 'H50 cm 60 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.708,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KLE8AWJ5;G6D9G9BG;WN5E2S3W;4GB5HDP3;4ZE8RZ7E;HD517H9X;YYE6PB5N',
      statusContent: 'dot',
      hash: '8XTAKB6P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8XTAKB6P'
    },
    {
      status: 210,
      sku: 'PM0085018',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370501',
      rtl_batch_array: '6370501',
      name_search: 'PHCTPARK',
      name: "Physocarpus capitatus 'Tilden Park'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 3.413,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TJKB6GN9',
      statusContent: 'dot',
      hash: 'KARNWZEP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KARNWZEP'
    },
    {
      status: 210,
      sku: 'PM0085019',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370502',
      rtl_batch_array: '6370502',
      name_search: 'POFBLINK',
      name: "Potentilla fruticosa 'Blink'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '66LY5D8Y;HLAEKCP6;59DVX3AS;YS2ZBCEZ;L28DN3LJ;1J6D5Z9B',
      statusContent: 'dot',
      hash: 'X9RLVCZ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X9RLVCZ2'
    },
    {
      status: 210,
      sku: 'PM0085020',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370503',
      rtl_batch_array: '6370503',
      name_search: 'POFPBEAU',
      name: "Potentilla fruticosa 'Primrose Beauty'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R5HYGEX5;PNXB7VNR;L9L6LZJ1;GAL36RY2;G71E12H6;Y4X3Y5LG',
      statusContent: 'dot',
      hash: 'JT4Z7TKH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JT4Z7TKH'
    },
    {
      status: 210,
      sku: 'PM0085021',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370504',
      rtl_batch_array: '6370504',
      name_search: 'POFSNOWF',
      name: "Potentilla fruticosa 'Snowflake'",
      sppl_size_code: '040050C3',
      rng_range_identifier: 'H040050C3',
      rng_range_description: 'H40 cm 50 cm C3',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.923,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5SL5KBXG',
      statusContent: 'dot',
      hash: '67RRVXGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '67RRVXGZ'
    },
    {
      status: 910,
      sku: 'PM0085022',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'COSKELSE',
      name: "Cornus sericea 'Kelseyi'",
      rng_range_identifier: 'H020025BRCuttingA0+1+1',
      rng_range_description: 'H20 cm 25 cm Bare root Cutting 0+1+1',
      imageCore: 'PZZ7H1LD;2ZWXKS11',
      statusContent: 'dot',
      hash: '61A7YRKP',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '61A7YRKP'
    },
    {
      status: 210,
      sku: 'PM0085023',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370590',
      rtl_batch_array: '6370590',
      name_search: 'FOILYNWO',
      name: "Forsythia intermedia 'Lynwood'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 5.7,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GC9ASPHA;9Y1LAPNR;PG3GYTNG;D99HWNRL;XLLB7VXR;DKJ118VZ',
      statusContent: 'dot',
      hash: '1XKBPP1N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1XKBPP1N'
    },
    {
      status: 910,
      sku: 'PM0085024',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      rng_range_identifier: 'H040060BRCuttingA0+1',
      rng_range_description: 'H40 cm 60 cm Bare root Cutting 0+1',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: '7T25BRRD',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '7T25BRRD'
    },
    {
      status: 910,
      sku: 'PM0085025',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOVALIF',
      name: 'Ligustrum ovalifolium',
      rng_range_identifier: 'H080120BRCuttingA0+2',
      rng_range_description: 'H80 cm 120 cm Bare root Cutting 0+2',
      imageCore:
        'YZG8NZWW;K4ZCSJRV;9TY16G18;ZGZV1C9Y;P6J3K626;6ZN99JGW;YWZZ76BE;EGCP6451;SWE7HYA9;TBVL6V7L;KGHRGJHJ',
      statusContent: 'dot',
      hash: 'KH4X9W5T',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'KH4X9W5T'
    },
    {
      status: 910,
      sku: 'PM0085026',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LIOAUREU',
      name: "Ligustrum ovalifolium 'Aureum'",
      rng_range_identifier: 'H060100BRCuttingA0+2',
      rng_range_description: 'H60 cm 100 cm Bare root Cutting 0+2',
      imageCore:
        'W8NDD455;VWE92P31;6RR65NZZ;E57VL8C7;1YZ1J8HD;ZYKD2CC7;PBCC1CS8;NKXXLTK7;8RXLXK1V;7EWG6D3W;HAPZELA5',
      statusContent: 'dot',
      hash: 'HKRR2SJR',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'HKRR2SJR'
    },
    {
      status: 910,
      sku: 'PM0085027',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      rng_range_identifier: 'H020030BRCuttingA0+1',
      rng_range_description: 'H20 cm 30 cm Bare root Cutting 0+1',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'EELK13XH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EELK13XH'
    },
    {
      status: 910,
      sku: 'PM0085028',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONELEGA',
      name: "Lonicera nitida 'Elegant'",
      rng_range_identifier: 'H030040BR',
      rng_range_description: 'H30 cm 40 cm Bare root',
      imageCore: 'JXA7EGH8',
      statusContent: 'dot',
      hash: 'YSGJHAWZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'YSGJHAWZ'
    },
    {
      status: 910,
      sku: 'PM0085029',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      rng_range_identifier: 'H020030BRCuttingA0+1',
      rng_range_description: 'H20 cm 30 cm Bare root Cutting 0+1',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: '9CGBXZG2',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9CGBXZG2'
    },
    {
      status: 910,
      sku: 'PM0085030',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LONMAIGR',
      name: "Lonicera nitida 'Maigr\u00fcn'",
      rng_range_identifier: 'H030040BR',
      rng_range_description: 'H30 cm 40 cm Bare root',
      imageCore:
        'KXEAJA3E;ZYCTE3EK;ATDVVSZL;4RLL8R81;4A7BBZ3B;GWXHZAZD;GGD927XY;CSPWL6EL;LYCCZE6B;GZGX6D7S;K3EGT3L3',
      statusContent: 'dot',
      hash: 'E1WDK4LB',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'E1WDK4LB'
    },
    {
      status: 910,
      sku: 'PM0085031',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      rng_range_identifier: 'H030040BR',
      rng_range_description: 'H30 cm 40 cm Bare root',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: 'A4K9RX3J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'A4K9RX3J'
    },
    {
      status: 910,
      sku: 'PM0085032',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'RISANGUI',
      name: 'Ribes sanguineum',
      rng_range_identifier: 'H020040BRCuttingA0+1',
      rng_range_description: 'H20 cm 40 cm Bare root Cutting 0+1',
      imageCore: 'KGNE5P66;B4E2YCCV;5SKCRCKV;VAVHDTYT;SRB9THP4',
      statusContent: 'dot',
      hash: 'EZL8L7NA',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'EZL8L7NA'
    },
    {
      status: 910,
      sku: 'PM0085033',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SAIHNISH',
      name: "Salix integra 'Hakuro-nishiki'",
      rng_range_identifier: 'H060080BRCuttingA0+1',
      rng_range_description: 'H60 cm 80 cm Bare root Cutting 0+1',
      imageCore:
        '6KJ4ZT8B;6LGWZSYL;H467KCYY;GABZJETP;A7S3CBP2;ZW2TBWT8;LSHN7JVE;TSTEWXXD;XGZXYBX1;6YCSY74K;RAR9N7SL;19W2B5CR;WLDS7LBP;P9N9ZXA2;NNKV19CB;8XE176DX;NTRB882J',
      statusContent: 'dot',
      hash: 'T5GGHJZ1',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'T5GGHJZ1'
    },
    {
      status: 910,
      sku: 'PM0085034',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SARNITID',
      name: 'Salix repens nitida',
      rng_range_identifier: 'H040060BRCuttingA0+1',
      rng_range_description: 'H40 cm 60 cm Bare root Cutting 0+1',
      imageCore: '5ZHP2L4T',
      statusContent: 'dot',
      hash: 'SYXZDX17',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'SYXZDX17'
    },
    {
      status: 910,
      sku: 'PM0085035',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SPJFROEB',
      name: "Spiraea japonica 'Froebelii'",
      rng_range_identifier: 'H015030BRCuttingA0+1',
      rng_range_description: 'H15 cm 30 cm Bare root Cutting 0+1',
      imageCore: '1HBPD7YV',
      statusContent: 'dot',
      hash: '5GKD6D5G',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '5GKD6D5G'
    },
    {
      status: 910,
      sku: 'PM0085036',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYCHANCO',
      name: "Symphoricarpos chenaultii 'Hancock'",
      rng_range_identifier: 'H020030BRCuttingA0+1',
      rng_range_description: 'H20 cm 30 cm Bare root Cutting 0+1',
      imageCore: '22Z3TP9L;9K19A25H;NWPN5B7N;BYAERG9N;66CEE767;9Z5TLDHH',
      statusContent: 'dot',
      hash: 'NAPJ3RJJ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'NAPJ3RJJ'
    },
    {
      status: 910,
      sku: 'PM0085037',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'SYORBICU',
      name: 'Symphoricarpos orbiculatus',
      rng_range_identifier: 'H040060BRCuttingA0+1',
      rng_range_description: 'H40 cm 60 cm Bare root Cutting 0+1',
      imageCore: '4N62854L',
      statusContent: 'dot',
      hash: 'JYJ16N16',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JYJ16N16'
    },
    {
      status: 910,
      sku: 'PM0085038',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TATETRAN',
      name: 'Tamarix tetrandra',
      rng_range_identifier: 'H060080BRCuttingA0+1',
      rng_range_description: 'H60 cm 80 cm Bare root Cutting 0+1',
      imageCore: 'JYRH596R;HX6VT8WB',
      statusContent: 'dot',
      hash: '559NVXBL',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '559NVXBL'
    },
    {
      status: 210,
      sku: 'PM0085039',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370613',
      rtl_batch_array: '6370613',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '100125C74',
      rng_range_identifier: 'H100125C7.5',
      rng_range_description: 'H100 cm 125 cm C7.5',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 5.45,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '7GP442XR',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7GP442XR'
    },
    {
      status: 210,
      sku: 'PM0085040',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370616',
      rtl_batch_array: '6370616',
      name_search: 'MISFLAMI',
      name: "Miscanthus sinensis 'Flamingo'",
      sppl_size_code: '030040C3',
      rng_range_identifier: 'H030040C3',
      rng_range_description: 'H30 cm 40 cm C3',
      sppl_stock_available: 479,
      chnn_stock_retail: 479,
      sppl_prcp: 2.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EHH3E452',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EHH3E452'
    },
    {
      status: 210,
      sku: 'PM0085041',
      core_name: 'Plant',
      sppl_ean: '8720664696969',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837436',
      rtl_batch_array: '5837436',
      name_search: 'LAAHIDCO',
      name: "Lavandula angustifolia 'Hidcote'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 42247,
      chnn_stock_retail: 42247,
      sppl_order_minimum: 3,
      sppl_prcp: 1.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8HT8LJX4;K284NRSN;YG6BYG4H;B7CB87SS;XAVZTZV6;E8JKRZRW;4WZZ7EXL;SSH6ZX6H;4ZLYV8GA;DPB2W3TZ;G4H12PSE;N9GZR28D;74TA1K7E;4NK7G75Z;A3ZTYDXR;D26ZAZSW;HP6CZWCJ;5YKD1RCZ;5ADNDYRD;NTBT78D1;1XSNSEY7;D2LS4JC1;22B2KZHC;EBJ6ZZPS;HR9W1X7G;9SJJ94NX',
      imageBatch: 'J7DDHVYA',
      statusContent: 'dot',
      hash: '7E78JXVB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7E78JXVB'
    },
    {
      status: 210,
      sku: 'PM0085042',
      core_name: 'Plant',
      btch_manufacturer: 2566,
      rtl_batch_array_total: 1,
      btch_active_retail: '5837438',
      rtl_batch_array: '5837438',
      name_search: 'LAAMUNST',
      name: "Lavandula angustifolia 'Munstead'",
      sppl_size_code: 'LEVC1',
      rng_range_identifier: 'C1',
      rng_range_description: 'C1',
      sppl_stock_available: 6923,
      chnn_stock_retail: 6923,
      sppl_order_minimum: 3,
      sppl_prcp: 1.001,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X3TTNA1R;BGYJR26Z;5516KHL5;R53K49G7;EYLJV5WK;16E3J7E2;LYLTTLVL;SX3532NA;1PGXXV1L;4XX1EBDW',
      statusContent: 'dot',
      hash: 'C7YX8LD5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C7YX8LD5'
    },
    {
      status: 910,
      sku: 'PM0085043',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAAEPURP',
      name: "Lavandula angustifolia 'Essence Purple'",
      rng_range_identifier: 'C5',
      rng_range_description: 'C5',
      imageCore:
        '2BPX8LP3;AN3A3WEZ;165YY51H;PSYW6N65;2RYNA19K;1KS7NXYC;A91B7ZXT',
      statusContent: 'dot',
      hash: '8WGAT7GH',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8WGAT7GH'
    },
    {
      status: 810,
      sku: 'PM0085044',
      core_name: 'Plant',
      sppl_ean: '8720664867802',
      btch_manufacturer: 238,
      rtl_batch_array_total: 1,
      btch_active_retail: '6142538',
      rtl_batch_array: '6142538',
      name_search: 'HESSPLEN',
      name: "Heuchera sanguinea 'Splendens'",
      sppl_size_code: 'LEVP11',
      rng_range_identifier: 'P11',
      rng_range_description: 'P11',
      sppl_stock_available: 82,
      chnn_stock_retail: 82,
      sppl_order_minimum: 3,
      sppl_prcp: 1.244,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'W9W7V4WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9W7V4WE'
    },
    {
      status: 210,
      sku: 'PM0085045',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260715',
      rtl_batch_array: '6260715',
      name_search: 'LILATIFO',
      name: 'Limonium latifolium',
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 436,
      chnn_stock_retail: 436,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'A7JAC637;RPA74RT2;T548BHRP;V1CGN6XS;X2AY5Z63;832PHEDG;ZGC373JH;L24CRABL;JA57XHTV',
      statusContent: 'dot',
      hash: 'NAJNB9H9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NAJNB9H9'
    },
    {
      status: 910,
      sku: 'PM0085046',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'LAIALBA',
      name: "Lavandula intermedia 'Alba'",
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'PN6X8PYZ',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'PN6X8PYZ'
    },
    {
      status: 210,
      sku: 'PM0085047',
      core_name: 'Plant',
      btch_manufacturer: 268,
      rtl_batch_array_total: 1,
      btch_active_retail: '6260731',
      rtl_batch_array: '6260731',
      name_search: 'LAIGROSS',
      name: "Lavandula intermedia 'Grosso'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 652,
      chnn_stock_retail: 652,
      sppl_prcp: 1.571,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X6HYD5KP;JDS3DX51;85DAB5KS;DJ5DBE7H;ET45AAHT;ZBPGWW3W;2Y9A7JYL;VEZL5ZHE;E7GD7PPS;4A8LT5EX;Z35X3T1E;51SXNPZZ;HNEBXXAX;YETJWXWY;L89Y4LET;W2B8EC65;ELKWSKLS;A1GZ59LY;4XW5A5A2;14S47DS5',
      statusContent: 'dot',
      hash: 'XVJLSL1C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XVJLSL1C'
    },
    {
      status: 810,
      sku: 'PM0085048',
      core_name: 'Plant',
      btch_manufacturer: 1005,
      rtl_batch_array_total: 1,
      btch_active_retail: '6222193',
      rtl_batch_array: '6222193',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '050060C5',
      rng_range_identifier: 'H050060C5',
      rng_range_description: 'H50 cm 60 cm C5',
      sppl_stock_available: 50,
      chnn_stock_retail: 50,
      sppl_prcp: 4.712,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: 'X3VNWRZC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X3VNWRZC'
    },
    {
      status: 910,
      sku: 'PM0085049',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'THCAPITA',
      name: 'Thymus capitatus',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      statusContent: 'dot',
      hash: 'JX2WCR7Z',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'JX2WCR7Z'
    },
    {
      status: 210,
      sku: 'PM0085050',
      core_name: 'Plant',
      btch_manufacturer: 565,
      rtl_batch_array_total: 1,
      btch_active_retail: '6209146',
      rtl_batch_array: '6209146',
      name_search: 'FASATROP',
      name: "Fagus sylvatica 'Atropunicea'",
      sppl_size_code: '100125C5',
      rng_range_identifier: 'H100125C5',
      rng_range_description: 'H100 cm 125 cm C5',
      sppl_stock_available: 2500,
      chnn_stock_retail: 2500,
      sppl_prcp: 6.578,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '29GP5HTE;GKJ46DWH;WHE5PRRK;KXLRDW87;2N489PS2;TY6JXC1Y;98C5C3GY;TKGHVTLE;5N3W8K9H;DLBXCWPK;991WEREK;5CZY5KSH;9RDB58ST;2CAEWRW5;KG8JJN26;TP358S2T;82TBWHTY;DX51Y2YE;K2TS3W5C;499Y2LT2',
      statusContent: 'dot',
      hash: 'ZR2YDZ4A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZR2YDZ4A'
    },
    {
      status: 210,
      sku: 'PM0085051',
      core_name: 'Plant',
      btch_manufacturer: 469,
      rtl_batch_array_total: 1,
      btch_active_retail: '6112950',
      rtl_batch_array: '6112950',
      name_search: 'CAOEGREE',
      name: "Carex oshimensis 'Evergreen'",
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 1.427,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S8D9E8HD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S8D9E8HD'
    },
    {
      status: 210,
      sku: 'PM0042353',
      core_name: 'Plant',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '6242782',
      rtl_batch_array: '6242782',
      name_search: 'CARADICA',
      name: 'Campsis radicans',
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1343,
      chnn_stock_retail: 1343,
      sppl_order_minimum: 3,
      sppl_prcp: 2.459,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'GYXZ7DXA;T3B2G547;YD2RVWG1;1CESY5VH;69C1BKNA;EVCK5KY3;93NCZB3H;G3JP3HEX;BHLTX997;PJLJSGYP',
      statusContent: 'dot',
      hash: 'G32Z3JKG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G32Z3JKG'
    },
    {
      status: 810,
      sku: 'PM0077257',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301377',
      rtl_batch_array: '6301377',
      name_search: 'ACSPSPIK',
      name: "Actaea simplex 'Pink Spike'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 55,
      chnn_stock_retail: 55,
      sppl_order_minimum: 3,
      sppl_prcp: 2.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'D6SR6ZWL;C1GRTNNY;WAD5WCKW;HPWPEB7T;NLTV1YAX;Y1V4NRXV;TTP5SRLC;1RY6LWRP',
      statusContent: 'dot',
      hash: '9HZXEYSE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9HZXEYSE'
    },
    {
      status: 210,
      sku: 'PM0084852',
      core_name: 'Plant',
      btch_manufacturer: 1417,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369131',
      rtl_batch_array: '6369131',
      name_search: 'DATEFRAG',
      name: 'Daphne transatlantica Eternal Fragrance',
      sppl_size_code: '020025C14',
      rng_range_identifier: 'H020025C1.5',
      rng_range_description: 'H20 cm 25 cm C1.5',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_order_minimum: 3,
      sppl_prcp: 4.6,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'T9DWY1AP;4E7EXLPX;YC4YBPRN;6HEGJC9A;PY2YNEWX;VJH88KX9;SS7BCWT1;EZNZXLWL;1DRCTTPS',
      statusContent: 'dot',
      hash: 'YCBP4R85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YCBP4R85'
    },
    {
      status: 210,
      sku: 'PM0077256',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301372',
      rtl_batch_array: '6301372',
      name_search: 'ACJCDO',
      name: "Actaea japonica 'Cheju-do'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1874,
      chnn_stock_retail: 1874,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Y9EH9S87',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y9EH9S87'
    },
    {
      status: 210,
      sku: 'PM0077259',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 3,
      btch_active_retail: '6301394',
      rtl_batch_array: '6301394, 6339741, 6349037',
      name_search: 'AMBICE',
      name: "Amsonia 'Blue Ice'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 2090,
      chnn_stock_retail: 8288,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'P69DTN5Y',
      statusContent: 'dot',
      hash: '71V8HXAA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '71V8HXAA'
    },
    {
      status: 210,
      sku: 'PM0084853',
      core_name: 'Plant',
      btch_manufacturer: 455,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369204',
      rtl_batch_array: '6369204',
      name_search: 'PRLBRENE',
      name: "Prunus lusitanica 'Brenelia'",
      sppl_size_code: '100STC10',
      rng_range_identifier: 'STE100C10',
      rng_range_description: 'Stem 100 cm C10',
      sppl_stock_available: 108,
      chnn_stock_retail: 108,
      sppl_prcp: 19.722,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2J7RRLPY;19KP63BV;WJRCZVX3;4JAGZSPY;5C7PBJN5',
      statusContent: 'dot',
      hash: 'B8E12736',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8E12736'
    },
    {
      status: 210,
      sku: 'PM0084854',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369217',
      rtl_batch_array: '6369217',
      name_search: 'ACPSLEAF',
      name: "Actaea pachypoda 'Silver Leaf'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 352,
      chnn_stock_retail: 352,
      sppl_order_minimum: 3,
      sppl_prcp: 2.553,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KZT784K4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KZT784K4'
    },
    {
      status: 210,
      sku: 'PM0084855',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369218',
      rtl_batch_array: '6369218',
      name_search: 'AGSANDRI',
      name: "Agapanthus 'Sandringham'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R8JB75K8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8JB75K8'
    },
    {
      status: 210,
      sku: 'PM0077260',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301415',
      rtl_batch_array: '6301415, 6329836',
      name_search: 'ARRUSTIC',
      name: 'Armoracia rusticana',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 149,
      chnn_stock_retail: 717,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6JL564DG',
      statusContent: 'dot',
      hash: 'NZA42WG6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZA42WG6'
    },
    {
      status: 210,
      sku: 'PM0084856',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369223',
      rtl_batch_array: '6369223',
      name_search: 'CAPUNCTA',
      name: 'Campanula punctata',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'G2J5BL4G;KKSD1VYX;S1JNYLKW;LBHKJEV3;L24LBXCL;ASSPXTHB;8V196AZS;DXHYSWLV;SBPZLNB9;BXTG2P4E',
      statusContent: 'dot',
      hash: '4Y9BK547',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '4Y9BK547'
    },
    {
      status: 210,
      sku: 'PM0084857',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369224',
      rtl_batch_array: '6369224',
      name_search: 'CAMFFORM',
      name: "Carex morrowii 'Fisher's Form'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 600,
      chnn_stock_retail: 600,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '84678TS4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '84678TS4'
    },
    {
      status: 210,
      sku: 'PM0084858',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369225',
      rtl_batch_array: '6369225',
      name_search: 'CAPCSELE',
      name: "Carex pilosa 'Copenhagen Select'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 876,
      chnn_stock_retail: 876,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E8APKCBS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E8APKCBS'
    },
    {
      status: 910,
      sku: 'PM0084859',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'BRMRUSSE',
      name: "Briza media 'Russells'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: '8DKP43VW',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '8DKP43VW'
    },
    {
      status: 210,
      sku: 'PM0077264',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301430',
      rtl_batch_array: '6301430, 6307937',
      name_search: 'ASEBSTAR',
      name: "Aster ericoides 'Blue Star'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 176,
      chnn_stock_retail: 626,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VERJTV5Z',
      statusContent: 'dot',
      hash: 'XP5L44PW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XP5L44PW'
    },
    {
      status: 210,
      sku: 'PM0077265',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301433',
      rtl_batch_array: '6301433',
      name_search: 'ASKRISTI',
      name: "Aster (D) 'Kristina'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PVZPCLLD;Z5ZYDHZR;8RKRNLKL',
      statusContent: 'dot',
      hash: 'X53EKKVL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X53EKKVL'
    },
    {
      status: 910,
      sku: 'PM0042681',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'STCCELIN',
      name: "Stachyurus chinensis 'Celina'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      imageCore: 'TVKY734H',
      statusContent: 'dot',
      hash: '9CZHRV8J',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '9CZHRV8J'
    },
    {
      status: 910,
      sku: 'PM0042675',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'HYPDHARU',
      name: "Hydrangea paniculata 'Dharuma'",
      rng_range_identifier: 'H030040C3.5',
      rng_range_description: 'H30 cm 40 cm C3.5',
      statusContent: 'dot',
      hash: 'BP7G54GE',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'BP7G54GE'
    },
    {
      status: 210,
      sku: 'PM0042631',
      core_name: 'Plant',
      btch_manufacturer: 734,
      rtl_batch_array_total: 3,
      btch_active_retail: '6228465',
      rtl_batch_array: '6138549, 6228465, 5420729',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 1387,
      sppl_order_minimum: 3,
      sppl_prcp: 1.931,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: 'J9KCYXNP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J9KCYXNP'
    },
    {
      status: 810,
      sku: 'PM0077268',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301450',
      rtl_batch_array: '6301450',
      name_search: 'ASKOBLEN',
      name: "Astilbe (J) 'Koblenz'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 68,
      chnn_stock_retail: 68,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6LRCCTCR;XEDXGLAP',
      statusContent: 'dot',
      hash: '7BE73NR1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7BE73NR1'
    },
    {
      status: 910,
      sku: 'PM0042676',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILAFARGE',
      name: "Ilex aquifolium 'Ferox Argentea'",
      rng_range_identifier: 'H050060C3.5',
      rng_range_description: 'H50 cm 60 cm C3.5',
      statusContent: 'dot',
      hash: 'W72K264S',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'W72K264S'
    },
    {
      status: 210,
      sku: 'PM0077263',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301429',
      rtl_batch_array: '6301429',
      name_search: 'ASEPURPL',
      name: "Aster 'Eleven Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1417,
      chnn_stock_retail: 1417,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'G2AX63C9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'G2AX63C9'
    },
    {
      status: 210,
      sku: 'PM0077272',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301471',
      rtl_batch_array: '6301471',
      name_search: 'BRUMSHEA',
      name: "Brunnera macrophylla 'Silver Heart'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1501,
      chnn_stock_retail: 1501,
      sppl_order_minimum: 3,
      sppl_prcp: 2.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'B812SJD9;XHBCAXE8;9LC83SAS;WH29PJ9A;RAPTNZ6T;VKALWS1S;S2GXYB5X;VJRZGR24;WL1K8PXP;CXY417LG;89H1R5EP;ATVH36JX',
      statusContent: 'dot',
      hash: 'ZELVK5N3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZELVK5N3'
    },
    {
      status: 210,
      sku: 'PM0077276',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301504',
      rtl_batch_array: '6301504',
      name_search: 'CHNLIGUL',
      name: "Chamaemelum nobile 'Ligulosum'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 142,
      chnn_stock_retail: 142,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JK5AJB8A',
      statusContent: 'dot',
      hash: '6CZS9ASE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6CZS9ASE'
    },
    {
      status: 210,
      sku: 'PM0084860',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369229',
      rtl_batch_array: '6369229',
      name_search: 'COBTIGER',
      name: "Coreopsis 'Bengal Tiger'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7W1YSAAP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7W1YSAAP'
    },
    {
      status: 210,
      sku: 'PM0084861',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369231',
      rtl_batch_array: '6369231',
      name_search: 'DIKNAPPI',
      name: 'Dianthus knappii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 270,
      chnn_stock_retail: 270,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WZBTS31P',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WZBTS31P'
    },
    {
      status: 210,
      sku: 'PM0077281',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6386169',
      rtl_batch_array: '6386169',
      name_search: 'POGSCARL',
      name: "Potentilla 'Gibson's Scarlet'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'SS95KSJ6;TGSZK167;KSB1Z25H',
      statusContent: 'dot',
      hash: 'V55KVDXV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V55KVDXV'
    },
    {
      status: 210,
      sku: 'PM0077282',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6386175',
      rtl_batch_array: '6301581, 6386175',
      name_search: 'PUMAJEST',
      name: "Pulmonaria 'Majest\u00e9'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 342,
      sppl_order_minimum: 3,
      sppl_prcp: 1.44,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'R4EEAL16;9CZPVA7Z;VN6TYTBC',
      statusContent: 'dot',
      hash: 'C8AE2Z2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'C8AE2Z2C'
    },
    {
      status: 210,
      sku: 'PM0084862',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369232',
      rtl_batch_array: '6369232',
      name_search: 'FEGPCARL',
      name: "Festuca gautieri 'Pic Carlit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1469,
      chnn_stock_retail: 1469,
      sppl_order_minimum: 3,
      sppl_prcp: 0.836,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'K7KGT6Z8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'K7KGT6Z8'
    },
    {
      status: 210,
      sku: 'PM0077283',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301582',
      rtl_batch_array: '6301582',
      name_search: 'PUOPAL',
      name: 'Pulmonaria Opal',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 126,
      chnn_stock_retail: 126,
      sppl_order_minimum: 3,
      sppl_prcp: 1.557,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '68Y9D5J2;H39J4LR1;5CKLZRLN',
      statusContent: 'dot',
      hash: 'VSGS62YZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VSGS62YZ'
    },
    {
      status: 210,
      sku: 'PM0077273',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301486',
      rtl_batch_array: '6301486',
      name_search: 'CAACUTA',
      name: 'Carex acuta',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 312,
      chnn_stock_retail: 312,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V4NAVZZ4',
      statusContent: 'dot',
      hash: 'DPL9YGAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DPL9YGAE'
    },
    {
      status: 210,
      sku: 'PM0077274',
      core_name: 'Plant',
      sppl_ean: '8720349431434',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6054521',
      rtl_batch_array: '6301494, 6054521',
      name_search: 'CAREMOTA',
      name: 'Carex remota',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 210,
      chnn_stock_retail: 358,
      sppl_order_minimum: 3,
      sppl_prcp: 0.785,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GRPDXHT4',
      statusContent: 'dot',
      hash: 'TBBEPGCD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TBBEPGCD'
    },
    {
      status: 210,
      sku: 'PM0077285',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301584',
      rtl_batch_array: '6301584, 6330096',
      name_search: 'PURUBRA',
      name: 'Pulmonaria rubra',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 253,
      chnn_stock_retail: 866,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '77NEYBLJ',
      statusContent: 'dot',
      hash: 'BGJD465Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BGJD465Z'
    },
    {
      status: 210,
      sku: 'PM0084863',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369234',
      rtl_batch_array: '6369234',
      name_search: 'ERMSILVE',
      name: 'Eryngium planum Magical Silver',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 480,
      chnn_stock_retail: 480,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BAYE33WB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BAYE33WB'
    },
    {
      status: 210,
      sku: 'PM0077278',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350080',
      rtl_batch_array: '6350080, 6301552',
      name_search: 'ECPRGIAN',
      name: "Echinacea purpurea 'Ruby Giant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 632,
      chnn_stock_retail: 832,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VKEK6GVD;7EZN2TJR;N5PEBJHR;442CH3WJ;TVCV2LZR',
      statusContent: 'dot',
      hash: 'Y28JPRB6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Y28JPRB6'
    },
    {
      status: 210,
      sku: 'PM0084864',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369236',
      rtl_batch_array: '6369236',
      name_search: 'GEWMAGIC',
      name: "Gentiana 'White Magic'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 2.048,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ETRTTEHK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ETRTTEHK'
    },
    {
      status: 210,
      sku: 'PM0084865',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369239',
      rtl_batch_array: '6369239',
      name_search: 'GYRRSCHO',
      name: "Gypsophila repens 'Rosa Sch\u00f6nheit'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 576,
      chnn_stock_retail: 576,
      sppl_order_minimum: 3,
      sppl_prcp: 0.886,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'V6RSLD5W;YT4HPTGY',
      statusContent: 'dot',
      hash: 'PJLVVR5A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PJLVVR5A'
    },
    {
      status: 210,
      sku: 'PM0077299',
      core_name: 'Plant',
      btch_manufacturer: 2878,
      rtl_batch_array_total: 2,
      btch_active_retail: '6382284',
      rtl_batch_array: '6301717, 6382284',
      name_search: 'MIGIGANT',
      name: 'Miscanthus giganteus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 10150,
      chnn_stock_retail: 10359,
      sppl_order_minimum: 3,
      sppl_prcp: 0.82,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'XX3H731W;WDGZWWW7;T9CGN2Z4;X7N65LYX;S9DNA3Y7;PXRA3199;GWVVS1KD;77KRS8YS',
      statusContent: 'dot',
      hash: '98TJJVT5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '98TJJVT5'
    },
    {
      status: 810,
      sku: 'PM0077300',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301718',
      rtl_batch_array: '6301718',
      name_search: 'MISACCHA',
      name: 'Miscanthus sacchariflorus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 90,
      chnn_stock_retail: 90,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'KLR6LAZ1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KLR6LAZ1'
    },
    {
      status: 210,
      sku: 'PM0077287',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350219',
      rtl_batch_array: '6350219, 6301598',
      name_search: 'GEMEANN',
      name: "Geranium maculatum 'Elizabeth Ann'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 467,
      chnn_stock_retail: 545,
      sppl_order_minimum: 3,
      sppl_prcp: 1.29,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KECCJKVL;L1YJR7Y6;X9J64WRG;XVYPEPX1;2SVK3ZAK',
      statusContent: 'dot',
      hash: 'W9CA86B2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'W9CA86B2'
    },
    {
      status: 810,
      sku: 'PM0077302',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301728',
      rtl_batch_array: '6301728',
      name_search: 'MOBYBHAP',
      name: "Monarda 'Bee-you Bee-happy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 84,
      chnn_stock_retail: 84,
      sppl_order_minimum: 3,
      sppl_prcp: 1.407,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PTLEGA7Y;RK9N28YB;7S8SD5CC;A3NDSVJ9;C244791W;611JSEK6',
      statusContent: 'dot',
      hash: 'TRPNYGZV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'TRPNYGZV'
    },
    {
      status: 810,
      sku: 'PM0077289',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301610',
      rtl_batch_array: '6301610',
      name_search: 'GESCHASE',
      name: "Geranium 'Storm Chaser'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 72,
      chnn_stock_retail: 72,
      sppl_order_minimum: 3,
      sppl_prcp: 1.107,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N3TJAB75;KN42G53L;6PG45CL3;Y1WYD8K3;YY8YK6ZW;2RX63JW1;ZNTTDAZR;RTYHHVA4;ZDE1C27E;SGVGNBP8;TDB39NW3',
      statusContent: 'dot',
      hash: 'L38AYRZD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L38AYRZD'
    },
    {
      status: 210,
      sku: 'PM0077291',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301613',
      rtl_batch_array: '6301613',
      name_search: 'GETRENDA',
      name: "Geranium 'Tanya Rendall'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_order_minimum: 3,
      sppl_prcp: 1.157,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'YRY56ZRC',
      statusContent: 'dot',
      hash: 'PCGWND2C',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PCGWND2C'
    },
    {
      status: 210,
      sku: 'PM0077313',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301798',
      rtl_batch_array: '6301798',
      name_search: 'RUFEBGOL',
      name: 'Rudbeckia fulgida Early Bird Gold',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 306,
      chnn_stock_retail: 306,
      sppl_order_minimum: 3,
      sppl_prcp: 1.757,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DCZL1TS4;DND16R6R;KYEASYHE;LL7KDSAW',
      statusContent: 'dot',
      hash: '1VE84VEX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1VE84VEX'
    },
    {
      status: 210,
      sku: 'PM0077329',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301933',
      rtl_batch_array: '6301933',
      name_search: 'KALMOYLA',
      name: "Kalmia latifolia 'Moyland'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 190,
      chnn_stock_retail: 190,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'S1TKZ9AE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'S1TKZ9AE'
    },
    {
      status: 210,
      sku: 'PM0077330',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301935',
      rtl_batch_array: '6301935',
      name_search: 'KALPINWH',
      name: "Kalmia latifolia 'Pinwheel'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 195,
      chnn_stock_retail: 195,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'HX732TX1',
      statusContent: 'dot',
      hash: 'BDXB9NBN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BDXB9NBN'
    },
    {
      status: 810,
      sku: 'PM0077293',
      core_name: 'Plant',
      btch_manufacturer: 1261,
      rtl_batch_array_total: 3,
      btch_active_retail: '6324695',
      rtl_batch_array: '6301637, 6301069, 6324695',
      name_search: 'HEHRETUR',
      name: "Hemerocallis 'Happy Returns'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 96,
      chnn_stock_retail: 3893,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KDJ5Y5AW;G3K2BE7V;DBGH9L7W',
      statusContent: 'dot',
      hash: 'P6EXHCRL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'P6EXHCRL'
    },
    {
      status: 210,
      sku: 'PM0077296',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6339846',
      rtl_batch_array: '6301673, 6339846',
      name_search: 'KNLPOPSI',
      name: "Kniphofia 'Lemon Popsicle'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 595,
      chnn_stock_retail: 737,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'D9YA8KBW;11C3VLHB',
      statusContent: 'dot',
      hash: 'A1885VR6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A1885VR6'
    },
    {
      status: 810,
      sku: 'PM0077306',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301747',
      rtl_batch_array: '6301747',
      name_search: 'OEFGLAUC',
      name: 'Oenothera fruticosa glauca',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 74,
      chnn_stock_retail: 74,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'AAY6CACK;B6ZALN74;PY6YYPBA;RT5CV5LB',
      statusContent: 'dot',
      hash: '17ZDH9DC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '17ZDH9DC'
    },
    {
      status: 210,
      sku: 'PM0077307',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301748',
      rtl_batch_array: '6301748',
      name_search: 'OEFSONNE',
      name: "Oenothera fruticosa 'Sonnenwende'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 262,
      chnn_stock_retail: 262,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9T2NHWH4',
      statusContent: 'dot',
      hash: '9TSYHY7A',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9TSYHY7A'
    },
    {
      status: 810,
      sku: 'PM0077308',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301766',
      rtl_batch_array: '6301766',
      name_search: 'PEAHAHN',
      name: "Penstemon 'Andenken an F. Hahn'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 83,
      chnn_stock_retail: 83,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L782XWN2;52A7BNC1;S9TEB8TX;NWSAL68Y;7JD4RDRB;1H2SGS37',
      statusContent: 'dot',
      hash: '6X4G72AB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6X4G72AB'
    },
    {
      status: 810,
      sku: 'PM0077314',
      core_name: 'Plant',
      btch_manufacturer: 142964,
      rtl_batch_array_total: 3,
      btch_active_retail: '6348969',
      rtl_batch_array: '6301804, 6348969, 6363196',
      name_search: 'SAAMISTA',
      name: "Salvia 'Amistad'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 1436,
      sppl_order_minimum: 3,
      sppl_prcp: 1.046,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CWW3GHSR;W4RV51L8;5Y76RSXV;TD1KVCPH;ZE662277;8Z22KA53',
      statusContent: 'dot',
      hash: '92TJZEW1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '92TJZEW1'
    },
    {
      status: 210,
      sku: 'PM0077317',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301826',
      rtl_batch_array: '6301826, 6353361',
      name_search: 'SCCMWILL',
      name: "Scabiosa caucasica 'Miss Willmott'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 238,
      chnn_stock_retail: 952,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '736P3DKW',
      statusContent: 'dot',
      hash: 'XAWTT4KG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XAWTT4KG'
    },
    {
      status: 210,
      sku: 'PM0077319',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301850',
      rtl_batch_array: '6301850',
      name_search: 'SOGMOSA',
      name: "Solidago 'Golden Mosa'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 501,
      chnn_stock_retail: 501,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5E73VDV9;9VXAET35',
      statusContent: 'dot',
      hash: '941T6GB4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '941T6GB4'
    },
    {
      status: 210,
      sku: 'PM0077328',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301930',
      rtl_batch_array: '6301930',
      name_search: 'KALGINKO',
      name: "Kalmia latifolia 'Ginkona'",
      sppl_size_code: '040050C6',
      rng_range_identifier: 'H040050C6',
      sppl_stock_available: 220,
      chnn_stock_retail: 220,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X24BJKNX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X24BJKNX'
    },
    {
      status: 210,
      sku: 'PM0077331',
      core_name: 'Plant',
      btch_manufacturer: 612,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301936',
      rtl_batch_array: '6301936',
      name_search: 'KALSCHOK',
      name: "Kalmia latifolia 'Schokra'",
      sppl_size_code: '030040C6',
      rng_range_identifier: 'H030040C6',
      sppl_stock_available: 255,
      chnn_stock_retail: 255,
      sppl_prcp: 9.914,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RWSJ2S9L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RWSJ2S9L'
    },
    {
      status: 210,
      sku: 'PM0077267',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301445',
      rtl_batch_array: '6301445',
      name_search: 'ASWPURPL',
      name: "Aster 'Wood's Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'J2C9KPP4',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J2C9KPP4'
    },
    {
      status: 810,
      sku: 'PM0077266',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301443',
      rtl_batch_array: '6301443, 6386059',
      name_search: 'ASNBSDIE',
      name: "Aster novi-belgii 'Sch\u00f6ne von Dietlikon'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 70,
      chnn_stock_retail: 756,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EZVSLN7C',
      statusContent: 'dot',
      hash: '9D7BL254',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9D7BL254'
    },
    {
      status: 210,
      sku: 'PM0077286',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301593',
      rtl_batch_array: '6350202, 6301593',
      name_search: 'GEEEYECA',
      name: "Geranium 'Elworthy Eyecatcher'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 232,
      chnn_stock_retail: 632,
      sppl_order_minimum: 3,
      sppl_prcp: 1.207,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'B8H9E4BK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B8H9E4BK'
    },
    {
      status: 210,
      sku: 'PM0077288',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301605',
      rtl_batch_array: '6301605',
      name_search: 'GEROBERT',
      name: 'Geranium robertianum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7N8E9PR8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7N8E9PR8'
    },
    {
      status: 810,
      sku: 'PM0077290',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301611',
      rtl_batch_array: '6301611',
      name_search: 'GESYLVAT',
      name: 'Geranium sylvaticum',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 57,
      chnn_stock_retail: 57,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'L5XLH6TN',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L5XLH6TN'
    },
    {
      status: 810,
      sku: 'PM0077292',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301636',
      rtl_batch_array: '6301636, 6308588',
      name_search: 'HEOPEPIN',
      name: "Helleborus orientalis 'Pretty Ellen Pink'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 38,
      chnn_stock_retail: 85,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5BTJLB8J',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5BTJLB8J'
    },
    {
      status: 210,
      sku: 'PM0077284',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301583',
      rtl_batch_array: '6301583',
      name_search: 'PUOWUPPE',
      name: "Pulmonaria officinalis 'Wuppertal'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 162,
      chnn_stock_retail: 162,
      sppl_order_minimum: 3,
      sppl_prcp: 1.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CG4B1LCZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CG4B1LCZ'
    },
    {
      status: 210,
      sku: 'PM0077294',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301638',
      rtl_batch_array: '6301638',
      name_search: 'HEMIKADO',
      name: "Hemerocallis 'Mikado'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 106,
      chnn_stock_retail: 106,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A92H1TJT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A92H1TJT'
    },
    {
      status: 210,
      sku: 'PM0077295',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 2,
      btch_active_retail: '6350423',
      rtl_batch_array: '6301647, 6350423',
      name_search: 'HEMFROST',
      name: "Heuchera 'Mint Frost'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 570,
      chnn_stock_retail: 672,
      sppl_order_minimum: 3,
      sppl_prcp: 1.442,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '86C3BVT6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '86C3BVT6'
    },
    {
      status: 210,
      sku: 'PM0077297',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301676',
      rtl_batch_array: '6301676',
      name_search: 'LAMRNANC',
      name: "Lamium maculatum 'Red Nancy'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 104,
      chnn_stock_retail: 104,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2JTH9P1X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2JTH9P1X'
    },
    {
      status: 210,
      sku: 'PM0077298',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301714',
      rtl_batch_array: '6301714',
      name_search: 'MERECOLO',
      name: "Mentha rotundifolia 'Eau de Cologne'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_order_minimum: 3,
      sppl_prcp: 1.057,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'Z2CDGDES',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'Z2CDGDES'
    },
    {
      status: 210,
      sku: 'PM0077305',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301735',
      rtl_batch_array: '6301735',
      name_search: 'NECATARI',
      name: 'Nepeta cataria',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 156,
      chnn_stock_retail: 156,
      sppl_order_minimum: 3,
      sppl_prcp: 0.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '57BVR7NJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '57BVR7NJ'
    },
    {
      status: 210,
      sku: 'PM0077311',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301781',
      rtl_batch_array: '6301781',
      name_search: 'PEASOVIL',
      name: "Persicaria amplexicaulis 'Seven Oaks Village'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 282,
      chnn_stock_retail: 282,
      sppl_order_minimum: 3,
      sppl_prcp: 1.957,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '1R2P6XW7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1R2P6XW7'
    },
    {
      status: 210,
      sku: 'PM0077312',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301784',
      rtl_batch_array: '6301784, 6339874',
      name_search: 'PEFILIFO',
      name: 'Persicaria filiformis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 106,
      chnn_stock_retail: 226,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'N9ZG2L4R',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'N9ZG2L4R'
    },
    {
      status: 210,
      sku: 'PM0077315',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301810',
      rtl_batch_array: '6301810, 6339948',
      name_search: 'SANACHTV',
      name: "Salvia 'Nachtvlinder'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 224,
      chnn_stock_retail: 1302,
      sppl_order_minimum: 3,
      sppl_prcp: 1.007,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '5CZ4WZVP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5CZ4WZVP'
    },
    {
      status: 210,
      sku: 'PM0077316',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301821',
      rtl_batch_array: '6301821',
      name_search: 'SATPELEP',
      name: "Sanguisorba tenuifolia 'Pink Elephant'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 1.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DKA7BL99',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DKA7BL99'
    },
    {
      status: 210,
      sku: 'PM0077318',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301828',
      rtl_batch_array: '6301828',
      name_search: 'SCUNIFLO',
      name: 'Scleranthus uniflorus',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VBXVYVRD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VBXVYVRD'
    },
    {
      status: 810,
      sku: 'PM0077320',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301851',
      rtl_batch_array: '6301851',
      name_search: 'SOLAURIN',
      name: "Solidago 'Laurin'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 88,
      chnn_stock_retail: 88,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LWK87SHW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LWK87SHW'
    },
    {
      status: 810,
      sku: 'PM0077321',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301856',
      rtl_batch_array: '6301856',
      name_search: 'SPPAUREO',
      name: "Spartina pectinata 'Aureomarginata'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 63,
      chnn_stock_retail: 63,
      sppl_order_minimum: 3,
      sppl_prcp: 0.907,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'EADNV6DJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EADNV6DJ'
    },
    {
      status: 210,
      sku: 'PM0077322',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301877',
      rtl_batch_array: '6301877',
      name_search: 'THSPLEND',
      name: "Thalictrum 'Splendide'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 118,
      chnn_stock_retail: 118,
      sppl_order_minimum: 3,
      sppl_prcp: 1.857,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BZ7LNHCL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BZ7LNHCL'
    },
    {
      status: 210,
      sku: 'PM0077324',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6345213',
      rtl_batch_array: '6345213',
      name_search: 'VEHALBA',
      name: "Verbena hastata 'Alba'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 447,
      chnn_stock_retail: 447,
      sppl_order_minimum: 3,
      sppl_prcp: 0.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E72THV9X',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E72THV9X'
    },
    {
      status: 810,
      sku: 'PM0077325',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 2,
      btch_active_retail: '6301901',
      rtl_batch_array: '6301901, 6386210',
      name_search: 'VELETTER',
      name: 'Vernonia lettermannii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 50,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.257,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '7JW9ZS9T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7JW9ZS9T'
    },
    {
      status: 210,
      sku: 'PM0077326',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6301903',
      rtl_batch_array: '6301903',
      name_search: 'VELFGLOR',
      name: "Veronica longifolia 'First Glory'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 102,
      chnn_stock_retail: 102,
      sppl_order_minimum: 3,
      sppl_prcp: 1.457,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JL2JVELP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JL2JVELP'
    },
    {
      status: 210,
      sku: 'PM0042759',
      core_name: 'Plant',
      sppl_ean: '8720664801868',
      btch_manufacturer: 663,
      rtl_batch_array_total: 1,
      btch_active_retail: '5423583',
      rtl_batch_array: '5423583',
      name_search: 'PHFLRROB',
      name: "Photinia fraseri 'Little Red Robin'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 350,
      chnn_stock_retail: 350,
      sppl_prcp: 6.39,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'HKYA7JJ4;5Y774Y4P;NSLBXTXY;DH17THST;7E7E6NTJ;T15G6J3Z;W7NV9XJV;9VN91BZD;K6TXE7BJ;8YW8A6GJ;ZCKLWD31;NTX5C4LH;GKZJXRE5;P8W4TSS2;Z7R3CG1L;B4P497JT;1SZYVXE2;KRGALDKH;YZNY8N5S;N8348A8E;W4VV5BL1;1HLA75PV',
      statusContent: 'dot',
      hash: '9P71422D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9P71422D'
    },
    {
      status: 210,
      sku: 'PM0042750',
      core_name: 'Plant',
      sppl_ean: '8720626375772',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420727',
      rtl_batch_array: '5420727',
      name_search: 'VIBETA',
      name: "Vitis 'Beta'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 242,
      chnn_stock_retail: 242,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'AETRYZHE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'AETRYZHE'
    },
    {
      status: 210,
      sku: 'PM0084866',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369241',
      rtl_batch_array: '6369241',
      name_search: 'HEBFHADA',
      name: "Helianthemum 'Ben Fhada'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KNDRCG9X;5XJD1N25',
      statusContent: 'dot',
      hash: 'JSAEWWLV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JSAEWWLV'
    },
    {
      status: 210,
      sku: 'PM0042755',
      core_name: 'Plant',
      sppl_ean: '8720626375956',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420737',
      rtl_batch_array: '5420737',
      name_search: 'VIZILGA',
      name: "Vitis 'Zilga'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 1432,
      chnn_stock_retail: 1432,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'GXT67EBA;YCSZJ3BS',
      statusContent: 'dot',
      hash: 'BBALD1A6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BBALD1A6'
    },
    {
      status: 210,
      sku: 'PM0042753',
      core_name: 'Plant',
      sppl_ean: '8720626375871',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420734',
      rtl_batch_array: '5420734',
      name_search: 'VIREMBRA',
      name: "Vitis 'Rembrandt'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 232,
      chnn_stock_retail: 232,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'SA5A78EH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SA5A78EH'
    },
    {
      status: 210,
      sku: 'PM0042752',
      core_name: 'Plant',
      sppl_ean: '8720626375833',
      btch_manufacturer: 73,
      rtl_batch_array_total: 1,
      btch_active_retail: '5420730',
      rtl_batch_array: '5420730',
      name_search: 'VIFRANKE',
      name: "Vitis 'Frankenthaler'",
      sppl_size_code: '080100C2',
      rng_range_identifier: 'H080100C2',
      rng_range_description: 'H80 cm 100 cm C2',
      sppl_stock_available: 230,
      chnn_stock_retail: 230,
      sppl_order_minimum: 3,
      sppl_prcp: 3.102,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JC5EX1N1',
      statusContent: 'dot',
      hash: 'VG63T11Z',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VG63T11Z'
    },
    {
      status: 210,
      sku: 'PM0084867',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369242',
      rtl_batch_array: '6369242',
      name_search: 'HERLIME',
      name: 'Heuchera hybrid Rex Lime',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 936,
      chnn_stock_retail: 936,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'A13AHCGG',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A13AHCGG'
    },
    {
      status: 210,
      sku: 'PM0084868',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369243',
      rtl_batch_array: '6369243',
      name_search: 'HERPEPPE',
      name: "Heuchera 'Rex Peppermint'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LVZ1GBA6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LVZ1GBA6'
    },
    {
      status: 210,
      sku: 'PM0084869',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369244',
      rtl_batch_array: '6369244',
      name_search: 'HERPURPL',
      name: "Heuchera 'Royal Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 720,
      chnn_stock_retail: 720,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'RSHEAPNC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RSHEAPNC'
    },
    {
      status: 910,
      sku: 'PM0043177',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MISSTRIC',
      name: "Miscanthus sinensis 'Strictus'",
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      imageCore:
        '6E7VK3LN;DDBGZZBL;RGW28J9J;DSCH93GV;7XNJSA12;VW9TY6J4;2KD13C5X;ST5LA5JB;VCJDWHKT;67YR1HE5;YP168CCT;NYHA6TZT;2HHZ78SB;5X8SL82N;ATGY8842;Z7PLP1S1;8JVZEBCA;LXJVKK55;5RR83H12;9Z1YAEDY;WN8K8NXK',
      statusContent: 'dot',
      hash: '23VYCCAS',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: '23VYCCAS'
    },
    {
      status: 210,
      sku: 'PM0084870',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369245',
      rtl_batch_array: '6369245',
      name_search: 'HERSILVE',
      name: "Heuchera 'Rex Silver'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 240,
      chnn_stock_retail: 240,
      sppl_order_minimum: 3,
      sppl_prcp: 1.644,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'X696HJE3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'X696HJE3'
    },
    {
      status: 210,
      sku: 'PM0043181',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184137',
      rtl_batch_array: '6184137',
      name_search: 'PAVPSKY',
      name: "Panicum virgatum 'Prairie Sky'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 1.523,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'W9WS66WK;CBRBZJNX;V82KHNHB;2K3V4BLD;2NZZRJV5;ST2VSWEA;8PTD16T2;WLKAB5PV;E694X6VR;1KD2A17L;4NVDRNCB;7A9HCPB3',
      statusContent: 'dot',
      hash: '9B34KXGZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9B34KXGZ'
    },
    {
      status: 910,
      sku: 'PM0043179',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'MOCMOORH',
      name: "Molinia caerulea 'Moorhexe'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      imageCore:
        'BKANC33L;XC2JCG1P;KPY1X27Z;C596W834;K74ZDG2C;KK4RYKAX;55KSN7L6;AJBNP5P7;KN1XWRSC;JXKYEJDE;NRB6PAPX;KKRJ474S;TCTWWLNC;G3KX28CR;XXV8STPW;EAX3J18G;DDWVCDKK;Z2ZJHBW5;ZDZD75PK;6GYT75ZR;BSZCVN59;6HJE2TVY;6SSYR92L',
      statusContent: 'dot',
      hash: 'AL8Y1DD4',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'AL8Y1DD4'
    },
    {
      status: 210,
      sku: 'PM0043166',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184053',
      rtl_batch_array: '6184053',
      name_search: 'HYPUNIQU',
      name: "Hydrangea paniculata 'Unique'",
      sppl_size_code: '040050C24',
      rng_range_identifier: 'H040050C2.5',
      rng_range_description: 'H40 cm 50 cm C2.5',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 2.273,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '8DD9LJPH;KB36AY45;J7Z7T8C5;1KGH13HA;YNA2SKY1;V2X3SEVT;EEC6931R;XX2XP852;WPKTKD2R',
      statusContent: 'dot',
      hash: '745D4L7N',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '745D4L7N'
    },
    {
      status: 210,
      sku: 'PM0043180',
      core_name: 'Plant',
      btch_manufacturer: 2143,
      rtl_batch_array_total: 1,
      btch_active_retail: '6184136',
      rtl_batch_array: '6184136',
      name_search: 'PAVNORTH',
      name: "Panicum virgatum 'Northwind'",
      sppl_size_code: '030040C24',
      rng_range_identifier: 'H030040C2.5',
      rng_range_description: 'H30 cm 40 cm C2.5',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 1.673,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'KGGJ1TC1;7V7BTYDN;AGAEKW9N;AAVJJXCN;EBRBK6NG;SST5Z988;28R5RHSE;H6E23755;98NPS21A;JSXLRHCG;VR5LW8AP;BGXJKENV;7VXA58TC;N2GK8LNB;G1DZ9NLB;NYHYD967;6LN8E2EY;77WTR19E;C23RVGG2;EG7AS9KK;CVEGWYGZ',
      statusContent: 'dot',
      hash: 'D7D7XH25',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7D7XH25'
    },
    {
      status: 210,
      sku: 'PM0084871',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369246',
      rtl_batch_array: '6369246',
      name_search: 'KNNRED',
      name: "Kniphofia 'Nancy Red'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 264,
      chnn_stock_retail: 264,
      sppl_order_minimum: 3,
      sppl_prcp: 1.543,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BPDLT6RD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BPDLT6RD'
    },
    {
      status: 210,
      sku: 'PM0084872',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369247',
      rtl_batch_array: '6369247',
      name_search: 'LOHPURPL',
      name: "Lobelia 'Hadspen Purple'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 216,
      chnn_stock_retail: 216,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2RGR399D',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2RGR399D'
    },
    {
      status: 910,
      sku: 'PM0043170',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'ILCGGEM',
      name: "Ilex crenata 'Glory Gem'",
      rng_range_identifier: 'H025030C2.5',
      rng_range_description: 'H25 cm 30 cm C2.5',
      statusContent: 'dot',
      hash: 'ZZGK2AS7',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'ZZGK2AS7'
    },
    {
      status: 210,
      sku: 'PM0084873',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369249',
      rtl_batch_array: '6369249',
      name_search: 'OEASUN',
      name: "Oenothera 'African Sun'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 305,
      chnn_stock_retail: 305,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'SC9NYA1P;PSBV82YY;LLC9Z1WR;D9XS6VW2;1JPHGGX2;S9XVNJST;R162N3X4',
      statusContent: 'dot',
      hash: 'D13AAAD1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D13AAAD1'
    },
    {
      status: 210,
      sku: 'PM0084874',
      core_name: 'Plant',
      btch_manufacturer: 172,
      rtl_batch_array_total: 1,
      btch_active_retail: '6370374',
      rtl_batch_array: '6370374',
      name_search: 'MEPULEGI',
      name: 'Mentha pulegium',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_order_minimum: 3,
      sppl_prcp: 0.657,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'LYKTP4YJ;KWZCKBJB;JA9L6E9X;GDCV7BX5;9BYD2LSL;D6ZYW8WK;BHHPEL52;J8W3TE1Z;9YZTHC57;PW59JXRA;P88H3W8V;DY8YY97K;7T7EXY3J;DSL4N1A6;YVSSS2AV',
      statusContent: 'dot',
      hash: '6HAG53GK',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HAG53GK'
    },
    {
      status: 210,
      sku: 'PM0084875',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369251',
      rtl_batch_array: '6369251',
      name_search: 'PORTCLAS',
      name: "Polemonium reptans 'Touch of Class'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 168,
      chnn_stock_retail: 168,
      sppl_order_minimum: 3,
      sppl_prcp: 1.846,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2ER215L7;KW4CGH9R;6W8SD4RZ;9PCVLX17;1XNLTBXG;XSTEGRH3;J36GJJZH;BN4V8HBR;C7NRVNSH',
      statusContent: 'dot',
      hash: '7WYX265Y',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7WYX265Y'
    },
    {
      status: 210,
      sku: 'PM0084876',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369252',
      rtl_batch_array: '6369252',
      name_search: 'RUHUMILI',
      name: 'Ruellia humilis',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 360,
      chnn_stock_retail: 360,
      sppl_order_minimum: 3,
      sppl_prcp: 1.139,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'VCKJ1GR3;RJ312DKT;95ZGGLA5',
      statusContent: 'dot',
      hash: 'CAKWBTJP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAKWBTJP'
    },
    {
      status: 210,
      sku: 'PM0084877',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369254',
      rtl_batch_array: '6369254',
      name_search: 'SIDVHIGH',
      name: "Silene dioica 'Valley High'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 120,
      chnn_stock_retail: 120,
      sppl_order_minimum: 3,
      sppl_prcp: 1.341,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'R1EGA2Z1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R1EGA2Z1'
    },
    {
      status: 210,
      sku: 'PM0084878',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369256',
      rtl_batch_array: '6369256',
      name_search: 'VELSPIRE',
      name: "Verbena 'Lavender Spires'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 1176,
      chnn_stock_retail: 1176,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZP3KYLEC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZP3KYLEC'
    },
    {
      status: 210,
      sku: 'PM0084879',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369257',
      rtl_batch_array: '6369257',
      name_search: 'SPHCLOUD',
      name: "Sporobolus heterolepis 'Cloud'",
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 410,
      chnn_stock_retail: 410,
      sppl_order_minimum: 3,
      sppl_prcp: 1.24,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CX18743V',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CX18743V'
    },
    {
      status: 210,
      sku: 'PM0084880',
      core_name: 'Plant',
      btch_manufacturer: 2097,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369258',
      rtl_batch_array: '6369258',
      name_search: 'TOMENZIE',
      name: 'Tolmiea menziesii',
      sppl_size_code: 'LEVP9',
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      sppl_stock_available: 185,
      chnn_stock_retail: 185,
      sppl_order_minimum: 3,
      sppl_prcp: 1.038,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'PX5271NY',
      statusContent: 'dot',
      hash: 'ENH77REE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ENH77REE'
    },
    {
      status: 910,
      sku: 'PM0084881',
      core_name: 'Plant',
      rtl_batch_array_total: '-',
      btch_active_retail: '-',
      rtl_batch_array: '-',
      name_search: 'TRRGICE',
      name: "Trifolium repens 'Green Ice'",
      rng_range_identifier: 'P9',
      rng_range_description: 'P9',
      statusContent: 'dot',
      hash: 'CNCVZH9B',
      _deleted: 'n',
      hasStock: 'n',
      productGroupHash: '8XRPWXBK',
      id: 'CNCVZH9B'
    },
    {
      status: 210,
      sku: 'PM0043323',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244803',
      rtl_batch_array: '6244803',
      name_search: 'LYBARBAR',
      name: 'Lycium barbarum',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 7500,
      chnn_stock_retail: 7500,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'S93T58NS;RWKYY7DS;R5XR33ZC;Z9DZTED4;PTYP1957;YASWSH4G;J3DYTLL2;9H7TB9J7;SJVXSBCH;5RS39RLL;RZLEY1EX;779D4K1X;LZHBVABE;YBPAS2E4;7X8XS811;Y882H767;GG8Z3SWK;6ZTE3S7V',
      statusContent: 'dot',
      hash: '96T2ATLC',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '96T2ATLC'
    },
    {
      status: 210,
      sku: 'PM0084882',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369360',
      rtl_batch_array: '6369360',
      name_search: 'OSHETERO',
      name: 'Osmanthus heterophyllus',
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 4.307,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'EECTLCPA;WX2W7LL8;YVATTYRY;VXHGDPAP',
      statusContent: 'dot',
      hash: 'A92V1S6W',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A92V1S6W'
    },
    {
      status: 810,
      sku: 'PM0084883',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369361',
      rtl_batch_array: '6369361',
      name_search: 'PRLVARIE',
      name: "Prunus lusitanica 'Variegata'",
      sppl_size_code: '020025C2',
      rng_range_identifier: 'H020025C2',
      rng_range_description: 'H20 cm 25 cm C2',
      sppl_stock_available: 70,
      chnn_stock_retail: 70,
      sppl_prcp: 2.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TH8JWJWG',
      statusContent: 'dot',
      hash: 'YYZH2NV2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YYZH2NV2'
    },
    {
      status: 810,
      sku: 'PM0084884',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369362',
      rtl_batch_array: '6369362',
      name_search: 'RIALPINU',
      name: 'Ribes alpinum',
      sppl_size_code: '030040C2',
      rng_range_identifier: 'H030040C2',
      rng_range_description: 'H30 cm 40 cm C2',
      sppl_stock_available: 60,
      chnn_stock_retail: 60,
      sppl_prcp: 1.27,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2Y5527X4;XCAZCWE9;6EVWH8PG;HHJ8PS3A',
      statusContent: 'dot',
      hash: '411B1NY6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '411B1NY6'
    },
    {
      status: 210,
      sku: 'PM0084885',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369363',
      rtl_batch_array: '6369363',
      name_search: 'VITINUS',
      name: 'Viburnum tinus',
      sppl_size_code: '025030C2',
      rng_range_identifier: 'H025030C2',
      rng_range_description: 'H25 cm 30 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_order_minimum: 5,
      sppl_prcp: 2.12,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'PVLBDP2K;Z8WV2NSV;DHL1WDHK;W22L9Y65;8594DV17;B57K6GKR;PSP2LBNS;C4X46G3C;9JLV973Y;1PHH36S6;RYGW8STG;L2R9G5ZX;4VYY1557;2EHPS3L3;YRPKS85W;9D9ELLT2;BLBYDYPW;LKPVJ3AR;WH9P13XP;TJTGRYPR;1YYT9LVJ;L4A1WN2V',
      statusContent: 'dot',
      hash: 'NJKZCEWW',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJKZCEWW'
    },
    {
      status: 210,
      sku: 'PM0043325',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244806',
      rtl_batch_array: '6244806',
      name_search: 'VACCBLUE',
      name: "Vaccinium corymbosum 'Bluecrop'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '2XNBEBWB;B7722WXJ;6H776WK8;BT18YHKT;B6LSDLDW;X2KGEJ55;6L8JWB9N;P8CD2N7Y;TJYH8Z7X;74PK3H97;D8JH3L2A',
      statusContent: 'dot',
      hash: '1RX2TAH7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1RX2TAH7'
    },
    {
      status: 210,
      sku: 'PM0043345',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244753',
      rtl_batch_array: '6244753',
      name_search: 'RIUCHROE',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki R\u00f6d',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 9000,
      chnn_stock_retail: 9000,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ECJGAL8T;H955H26G;V85N3L43;2Y2CY1AD;C8RL4TWB;B58HCCJS',
      statusContent: 'dot',
      hash: '2D6J5NAL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2D6J5NAL'
    },
    {
      status: 210,
      sku: 'PM0026446',
      core_name: 'Plant',
      sppl_ean: '8720349467358',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439497',
      rtl_batch_array: '5439497',
      name_search: 'ROPSWANY',
      name: 'Rosa (H) Pink Swany',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 140,
      chnn_stock_retail: 140,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DW6T14XR',
      statusContent: 'dot',
      hash: '2N4967C5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2N4967C5'
    },
    {
      status: 210,
      sku: 'PM0084886',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369366',
      rtl_batch_array: '6369366',
      name_search: 'WEBRUBY',
      name: "Weigela 'Bristol Ruby'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 3.611,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '5L7P9TT2;BABHK5DR;1X2L3W48;9B92LY73;5E9BNWW5;E2ZBRSYY;7SV313PL;9HZKANSC;H4KJ61HA',
      statusContent: 'dot',
      hash: '1GSBN7GV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '1GSBN7GV'
    },
    {
      status: 210,
      sku: 'PM0043322',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244802',
      rtl_batch_array: '6244802',
      name_search: 'LOCKAMTS',
      name: 'Lonicera caerulea kamtschatica',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N1SDSTL3;G5XWZ6JS;J9E53BCG;Z8751Y7H;2TH2SJ56;YLZ4SE6N;1NHA3JDR;N8TE1ELP;13HJTVNR;DHBR21T7;LXG2STWN;WYHL68B9;Z8ZE4TW4',
      statusContent: 'dot',
      hash: '2ZSW9389',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2ZSW9389'
    },
    {
      status: 210,
      sku: 'PM0043330',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244811',
      rtl_batch_array: '6244811',
      name_search: 'VACNBLUE',
      name: "Vaccinium corymbosum 'Northblue'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '469PZ2WE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '469PZ2WE'
    },
    {
      status: 210,
      sku: 'PM0043371',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244779',
      rtl_batch_array: '6244779',
      name_search: 'VILMILLO',
      name: "Vitis 'L\u00e9on Millot'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'T622SYS2',
      statusContent: 'dot',
      hash: '46LENVS9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '46LENVS9'
    },
    {
      status: 210,
      sku: 'PM0043343',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244750',
      rtl_batch_array: '6244750',
      name_search: 'RIUCCAPT',
      name: "Ribes uva-crispa 'Captivator'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1500,
      chnn_stock_retail: 1500,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '7EGPXWPD',
      statusContent: 'dot',
      hash: '5AVW9TS3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '5AVW9TS3'
    },
    {
      status: 210,
      sku: 'PM0084887',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369368',
      rtl_batch_array: '6369368',
      name_search: 'CULEYLAN',
      name: 'Cupressocyparis leylandii',
      sppl_size_code: '100125C3',
      rng_range_identifier: 'H100125C3',
      rng_range_description: 'H100 cm 125 cm C3',
      sppl_stock_available: 150,
      chnn_stock_retail: 150,
      sppl_prcp: 3.56,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'K2G3LRZ5;Y4W7LEZK;RHPJYT3L;1HRD58R4;YS96K69T;4W2ZB7WK;5T648W6H;6T2BYD6K;XKE3AE2X;869KKXWW',
      statusContent: 'dot',
      hash: 'EX1XG753',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EX1XG753'
    },
    {
      status: 210,
      sku: 'PM0084888',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369369',
      rtl_batch_array: '6369369',
      name_search: 'MISSILBE',
      name: "Miscanthus sinensis 'Silberfeder'",
      sppl_size_code: '040050C2',
      rng_range_identifier: 'H040050C2',
      rng_range_description: 'H40 cm 50 cm C2',
      sppl_stock_available: 439,
      chnn_stock_retail: 439,
      sppl_prcp: 2.02,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JW1RC3RL;6E6Z224A;843T7K64',
      statusContent: 'dot',
      hash: '59GKYBWJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '59GKYBWJ'
    },
    {
      status: 210,
      sku: 'PM0084889',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369370',
      rtl_batch_array: '6369370',
      name_search: 'COASVARI',
      name: "Cornus alba 'Sibirica Variegata'",
      sppl_size_code: '080100C5',
      rng_range_identifier: 'H080100C5',
      rng_range_description: 'H80 cm 100 cm C5',
      sppl_stock_available: 100,
      chnn_stock_retail: 100,
      sppl_prcp: 4.361,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'STVYH9GZ;AGKATTA5;C1YWC51C;DK5XTEBE;8ZGGRVRD',
      statusContent: 'dot',
      hash: 'B58ESZ3S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B58ESZ3S'
    },
    {
      status: 810,
      sku: 'PM0084890',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369371',
      rtl_batch_array: '6369371',
      name_search: 'COSMFIRE',
      name: "Cornus sanguinea 'Midwinter Fire'",
      sppl_size_code: '060080C5',
      rng_range_identifier: 'H060080C5',
      rng_range_description: 'H60 cm 80 cm C5',
      sppl_stock_available: 25,
      chnn_stock_retail: 25,
      sppl_prcp: 4.807,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E2KWVVZW;VBLY4D27;GAC2TPYG;6ZAN41HD;VB5G43GA;BRYW2V4Z',
      statusContent: 'dot',
      hash: 'YXWB9N94',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YXWB9N94'
    },
    {
      status: 210,
      sku: 'PM0043326',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244807',
      rtl_batch_array: '6244807',
      name_search: 'VACBLUEG',
      name: "Vaccinium corymbosum 'Bluegold'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '6TH5ZSNH',
      statusContent: 'dot',
      hash: '6PPTARP1',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6PPTARP1'
    },
    {
      status: 210,
      sku: 'PM0025300',
      core_name: 'Plant',
      sppl_ean: '8720664669833',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439514',
      rtl_batch_array: '5439514',
      name_search: 'ROWDECUM',
      name: 'Rosa White Decumba',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6XS65TJ2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6XS65TJ2'
    },
    {
      status: 210,
      sku: 'PM0043376',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244785',
      rtl_batch_array: '6244785',
      name_search: 'VITALSAC',
      name: "Vitis 'Triomphe d'Alsace'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 200,
      chnn_stock_retail: 200,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '9BE7D862',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '9BE7D862'
    },
    {
      status: 210,
      sku: 'PM0043372',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244780',
      rtl_batch_array: '6244780',
      name_search: 'VIMBLEU',
      name: "Vitis 'Muscat Bleu'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JX17A6LR;AHJECYE5',
      statusContent: 'dot',
      hash: 'BNLVT6L2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BNLVT6L2'
    },
    {
      status: 210,
      sku: 'PM0043331',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244812',
      rtl_batch_array: '6244812',
      name_search: 'VACNCOUN',
      name: "Vaccinium corymbosum 'Northcountry'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'BWJ91HGY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'BWJ91HGY'
    },
    {
      status: 210,
      sku: 'PM0043358',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244769',
      rtl_batch_array: '6244769',
      name_search: 'RUIHERIT',
      name: "Rubus idaeus 'H\u00e9ritage'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'JBSG2NX4;Z7BCHAJD;6LB4VLLL;16JRX11D;JKZGZWKE;DC983CYP;A15DAZ3C;CY1XHS6D;Z19YL8TV;J5A7B646;EEWTXCY3;P8JJ3HG2',
      statusContent: 'dot',
      hash: 'CPVVYZR5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CPVVYZR5'
    },
    {
      status: 210,
      sku: 'PM0043349',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244756',
      rtl_batch_array: '6244756',
      name_search: 'RUFBSATI',
      name: "Rubus fruticosus 'Black Satin'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 8000,
      chnn_stock_retail: 8000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '7WJTCG9A;7ZZY21TS;SZ1LV5N6;PL8SXPD4;LJ9JS2SE;G49K7BRZ;E29TKSN8;HWL1XVK6',
      statusContent: 'dot',
      hash: 'D74LGH8L',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D74LGH8L'
    },
    {
      status: 210,
      sku: 'PM0043356',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244765',
      rtl_batch_array: '6244765',
      name_search: 'RUFTCROW',
      name: "Rubus fruticosus 'Triple Crown'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'DK67SZLH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DK67SZLH'
    },
    {
      status: 210,
      sku: 'PM0043363',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244773',
      rtl_batch_array: '6244773',
      name_search: 'RUIAQUEE',
      name: "Rubus idaeus 'Aroma Queen'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '43X9K1GT',
      statusContent: 'dot',
      hash: 'DLV5DNX2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DLV5DNX2'
    },
    {
      status: 210,
      sku: 'PM0043359',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244770',
      rtl_batch_array: '6244770',
      name_search: 'RUIMPROM',
      name: "Rubus idaeus 'Malling Promise'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'EZJV1BV9;WG8HXVEE;8D4DDK5A;LPB1B4T4;B3PJB7LS;J4ZL1SRS;XTXEBGWN',
      statusContent: 'dot',
      hash: 'DZV7E96S',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'DZV7E96S'
    },
    {
      status: 210,
      sku: 'PM0043368',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244776',
      rtl_batch_array: '6244776',
      name_search: 'VIBGLORY',
      name: "Vitis 'Boskoop Glory'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ARH6962G;W2HC6AZR;RR37A4B6;PS2GBJKW;XR4CL68P;R8RY7EDL;RGEJSLZ5;XZYZK8LA;DCACBKL7;CSJBDA6G;KE1D2BP5;NE6P3LCY;PBAA1W96',
      statusContent: 'dot',
      hash: 'EN6284X7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EN6284X7'
    },
    {
      status: 210,
      sku: 'PM0043350',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244757',
      rtl_batch_array: '6244757',
      name_search: 'RUFCTHOR',
      name: "Rubus fruticosus 'Chester Thornless'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '53G1DD41;6NTNH1RN;88Y3BN5L;YB1EEK3A;RNKAYTJZ;ATT4CAYH;72776WXJ;VXD2V37S;PS3NCLKC',
      statusContent: 'dot',
      hash: 'JAXSGDB2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JAXSGDB2'
    },
    {
      status: 210,
      sku: 'PM0043333',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244814',
      rtl_batch_array: '6244814',
      name_search: 'VACPIONE',
      name: "Vaccinium corymbosum 'Pioneer'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'CDS9HSY4',
      statusContent: 'dot',
      hash: 'LB8XTR49',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LB8XTR49'
    },
    {
      status: 210,
      sku: 'PM0043374',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244783',
      rtl_batch_array: '6244783',
      name_search: 'VIREGENT',
      name: "Vitis 'Regent'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4R7TJPPS',
      statusContent: 'dot',
      hash: 'NJCKXZCJ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJCKXZCJ'
    },
    {
      status: 210,
      sku: 'PM0043341',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244746',
      rtl_batch_array: '6244746',
      name_search: 'RIJOSTAB',
      name: 'Ribes Jostaberry',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'AHZD5B2T;KZHLNVT9;511XL9RW;XBSB95GW;5XJC2KL2;G79431ER;HPTKYZA9;PHZ5HGEY;GP1PGL8D;BHPYH2CC;N5135B7J;N3A1GLK6;7XE7642B;DNAC5T73',
      statusContent: 'dot',
      hash: 'NWG454H7',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NWG454H7'
    },
    {
      status: 210,
      sku: 'PM0043332',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244813',
      rtl_batch_array: '6244813',
      name_search: 'VACNORTH',
      name: "Vaccinium corymbosum 'Northland'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1250,
      chnn_stock_retail: 1250,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'DLE5Z3P8;D5PC9EN1;SZECH9NT;NY61RGXL;J6ALZP1R',
      statusContent: 'dot',
      hash: 'NZT398ZS',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NZT398ZS'
    },
    {
      status: 210,
      sku: 'PM0043324',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244804',
      rtl_batch_array: '6244804',
      name_search: 'SANHASCH',
      name: "Sambucus nigra 'Haschberg'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 500,
      chnn_stock_retail: 500,
      sppl_prcp: 2.778,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'NG59WL4G',
      statusContent: 'dot',
      hash: 'PRSG4RLT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'PRSG4RLT'
    },
    {
      status: 210,
      sku: 'PM0043344',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244752',
      rtl_batch_array: '6244752',
      name_search: 'RIUCHGUL',
      name: 'Ribes uva-crispa Hinnonm\u00e4ki Gul',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'X49AL4ZN;L7ZPR216;8YLKPZ6D;9BDE59E1;H5R1YSPZ;K1XZEE5Z;DL1LZY7E;WAL429PD;9C2WDGST;8JYG6TBA;LRLPNZZE;CLXH99ZN',
      statusContent: 'dot',
      hash: 'R8VL16HP',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'R8VL16HP'
    },
    {
      status: 210,
      sku: 'PM0043328',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244809',
      rtl_batch_array: '6244809',
      name_search: 'VACG71',
      name: "Vaccinium corymbosum 'Goldtraube 71'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 3000,
      chnn_stock_retail: 3000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9X6JHGWX;WKRW3Z1B;HD56SXLZ;WZGGK572',
      statusContent: 'dot',
      hash: 'RP4RXGGD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'RP4RXGGD'
    },
    {
      status: 210,
      sku: 'PM0043353',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244761',
      rtl_batch_array: '6244761',
      name_search: 'RUFTHORN',
      name: "Rubus fruticosus 'Thornfree'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 10000,
      chnn_stock_retail: 10000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ZSRJ9NGH;T63KNDAR',
      statusContent: 'dot',
      hash: 'V483E3WH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'V483E3WH'
    },
    {
      status: 210,
      sku: 'PM0043362',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244772',
      rtl_batch_array: '6244772',
      name_search: 'RUIHTOP',
      name: 'Rubus idaeus Himbo Top',
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 1000,
      chnn_stock_retail: 1000,
      sppl_prcp: 2.727,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'KKV8RCRL',
      statusContent: 'dot',
      hash: 'VCCZDTKD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VCCZDTKD'
    },
    {
      status: 210,
      sku: 'PM0043327',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244808',
      rtl_batch_array: '6244808',
      name_search: 'VACEMBLU',
      name: "Vaccinium corymbosum 'Emblue'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 4000,
      chnn_stock_retail: 4000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'VPCSSGP5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VPCSSGP5'
    },
    {
      status: 210,
      sku: 'PM0043354',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244762',
      rtl_batch_array: '6244762',
      name_search: 'RUTBOYSE',
      name: "Rubus 'Thornless Boysenberry'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.521,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'WWDL6LCB',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'WWDL6LCB'
    },
    {
      status: 210,
      sku: 'PM0043361',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244771',
      rtl_batch_array: '6244771',
      name_search: 'RUIOTTAW',
      name: "Rubus idaeus 'Ottawa'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 15000,
      chnn_stock_retail: 15000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'XN5LX4VV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XN5LX4VV'
    },
    {
      status: 210,
      sku: 'PM0043342',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244749',
      rtl_batch_array: '6244749',
      name_search: 'RIRWHOLL',
      name: "Ribes rubrum (white) 'Witte Hollander'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 6000,
      chnn_stock_retail: 6000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '2E7E22Z4',
      statusContent: 'dot',
      hash: 'XYG97KAE',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'XYG97KAE'
    },
    {
      status: 210,
      sku: 'PM0043329',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244810',
      rtl_batch_array: '6244810',
      name_search: 'VACHEERM',
      name: "Vaccinium corymbosum 'Heerma'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.675,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'YJ2RS9SX',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YJ2RS9SX'
    },
    {
      status: 210,
      sku: 'PM0043351',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244758',
      rtl_batch_array: '6244758',
      name_search: 'RUFHIMAL',
      name: "Rubus fruticosus 'Himalaya'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 2000,
      chnn_stock_retail: 2000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'Z6B8EXAC',
      statusContent: 'dot',
      hash: 'YP73K97T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'YP73K97T'
    },
    {
      status: 210,
      sku: 'PM0043357',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244768',
      rtl_batch_array: '6244768',
      name_search: 'RUIGBLIS',
      name: "Rubus idaeus 'Golden Bliss'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 5000,
      chnn_stock_retail: 5000,
      sppl_prcp: 2.418,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ZYGST5Z6',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ZYGST5Z6'
    },
    {
      status: 810,
      sku: 'PM0019273',
      core_name: 'Plant',
      sppl_ean: '8720349463237',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5577514',
      rtl_batch_array: '5577514',
      name_search: 'ROGAERTN',
      name: 'Rosa (H) G\u00e4rtnerfreude',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 26,
      chnn_stock_retail: 26,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '2T7H5AL5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '2T7H5AL5'
    },
    {
      status: 210,
      sku: 'PM0043369',
      core_name: 'Plant',
      btch_manufacturer: 295,
      rtl_batch_array_total: 1,
      btch_active_retail: '6244777',
      rtl_batch_array: '6244777',
      name_search: 'VIFRANKE',
      name: "Vitis 'Frankenthaler'",
      sppl_size_code: '065070C2',
      rng_range_identifier: 'H065070C2',
      rng_range_description: 'H65 cm 70 cm C2',
      sppl_stock_available: 300,
      chnn_stock_retail: 300,
      sppl_prcp: 3.242,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'JC5EX1N1',
      statusContent: 'dot',
      hash: 'L3G961J8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L3G961J8'
    },
    {
      status: 210,
      sku: 'PM0007743',
      core_name: 'Plant',
      sppl_ean: '8720349499113',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439477',
      rtl_batch_array: '5439477',
      name_search: 'ROHOCKEY',
      name: 'Rosa (H) Hockey',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 595,
      chnn_stock_retail: 595,
      sppl_order_minimum: 3,
      sppl_prcp: 3.009,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6ESS3KW5',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6ESS3KW5'
    },
    {
      status: 210,
      sku: 'PM0004009',
      core_name: 'Plant',
      sppl_ean: '8720353010908',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439470',
      rtl_batch_array: '5439470',
      name_search: 'ROESCIMO',
      name: 'Rosa Escimo',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 280,
      chnn_stock_retail: 280,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6HB6H8JD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6HB6H8JD'
    },
    {
      status: 210,
      sku: 'PM0007392',
      core_name: 'Plant',
      sppl_ean: '8720664668973',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5534234',
      rtl_batch_array: '5534234',
      name_search: 'ROPMIRAT',
      name: 'Rosa (H) Pearl Mirato',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 670,
      chnn_stock_retail: 670,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: '6NR1WB65',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '6NR1WB65'
    },
    {
      status: 910,
      sku: 'PM0038327',
      core_name: 'Garden',
      btch_manufacturer: 14143440,
      rtl_batch_array_total: 1,
      btch_active_retail: '6083870',
      rtl_batch_array: '6083870',
      name_search: 'DCVMPALM',
      name: 'DCM Vloeibare Meststof Palmen',
      sppl_size_code: '04LTRFLES',
      rng_range_identifier: 'BOT0.4LTR',
      rng_range_description: 'Bottle 0.4 Liter',
      sppl_stock_available: 12,
      chnn_stock_retail: 12,
      sppl_prcp: 3.5,
      currency: 'EUR',
      sppl_tax_class: 'Standard',
      statusContent: 'dot',
      hash: '7RXCPY24',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: 'YY3JDGJN',
      id: '7RXCPY24'
    },
    {
      status: 210,
      sku: 'PM0084891',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369372',
      rtl_batch_array: '6369372',
      name_search: 'EUJALBOM',
      name: "Euonymus japonicus 'Albomarginatus'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 700,
      chnn_stock_retail: 700,
      sppl_prcp: 2.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '9PK9LZ9R;HL664HPX;TC7ALTRK;EJPPJJA2',
      statusContent: 'dot',
      hash: 'SZHDKGPY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'SZHDKGPY'
    },
    {
      status: 210,
      sku: 'PM0020493',
      core_name: 'Plant',
      sppl_ean: '8720349445622',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439505',
      rtl_batch_array: '5439505',
      name_search: 'ROSATINA',
      name: 'Rosa (P) Satina',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 728,
      chnn_stock_retail: 728,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '5AVYZWGP;2E882RGA',
      statusContent: 'dot',
      hash: '7R6DD836',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '7R6DD836'
    },
    {
      status: 210,
      sku: 'PM0084892',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369373',
      rtl_batch_array: '6369373',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: '025030C3',
      rng_range_identifier: 'H025030C3',
      rng_range_description: 'H25 cm 30 cm C3',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 2.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      statusContent: 'dot',
      hash: 'L9EJ33TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L9EJ33TZ'
    },
    {
      status: 810,
      sku: 'PM0084893',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369374',
      rtl_batch_array: '6369374',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: '040050C74',
      rng_range_identifier: 'H040050C7.5',
      sppl_stock_available: 75,
      chnn_stock_retail: 75,
      sppl_prcp: 6.1,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      statusContent: 'dot',
      hash: 'VLYDVBX8',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'VLYDVBX8'
    },
    {
      status: 210,
      sku: 'PM0022084',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6258895',
      rtl_batch_array: '5439503, 6258895',
      name_search: 'RORMEIDI',
      name: 'Rosa (H) Red Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 575,
      chnn_stock_retail: 6516,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'L7Z4PHDB;JN13651S;NEL68Y8V;ASE5VY5V;2NW8HTYB;6AHXN925',
      statusContent: 'dot',
      hash: '8YN5J5PL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8YN5J5PL'
    },
    {
      status: 210,
      sku: 'PM0018288',
      core_name: 'Plant',
      sppl_ean: '8720664883680',
      btch_manufacturer: 93,
      rtl_batch_array_total: 4,
      btch_active_retail: '6149235',
      rtl_batch_array: '6149235, 6244218, 6164872, 6336286',
      name_search: 'ROFAIRY',
      name: "Rosa (P) 'The Fairy'",
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 9334,
      chnn_stock_retail: 20226,
      sppl_order_minimum: 3,
      sppl_prcp: 1.091,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'N3R9LW9X;VX8TLPCV;TYHX8SJD;H8DP8BNS;PCR6L1V9;1L13XG31;GRX3V9J9',
      statusContent: 'dot',
      hash: '943VBGAY',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '943VBGAY'
    },
    {
      status: 210,
      sku: 'PM0022213',
      core_name: 'Plant',
      sppl_ean: '8720664669666',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439487',
      rtl_batch_array: '5439487',
      name_search: 'ROLDVINC',
      name: 'Rosa (T) Leonardo da Vinci',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 825,
      chnn_stock_retail: 825,
      sppl_order_minimum: 3,
      sppl_prcp: 2.656,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'H7CB9SZW;4WC1L1NJ;HRZ8C1H5;DD839X3D;P3VLNHC7;SWHB6C21;77X3A4VR;9ATCGWZX;2BJSNZBG',
      statusContent: 'dot',
      hash: 'A6C6H6N9',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'A6C6H6N9'
    },
    {
      status: 210,
      sku: 'PM0021149',
      core_name: 'Plant',
      sppl_ean: '8720349401765',
      btch_manufacturer: 2722,
      rtl_batch_array_total: 1,
      btch_active_retail: '5304245',
      rtl_batch_array: '5304245',
      name_search: 'EUJAUREO',
      name: "Euonymus japonicus 'Aureomarginatus'",
      sppl_size_code: 'LEVC2',
      rng_range_identifier: 'C2',
      rng_range_description: 'C2',
      sppl_stock_available: 477,
      chnn_stock_retail: 477,
      sppl_prcp: 2.805,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'TX1CWTZ6;4EN1J27H;8K121AS9;2NTJ7ERK;SBPHSEB8;V2KEVKL7;Z7XCYN8X;1SAXBTL7;SVWVEA3R;GDWZRAZJ;C6X3E72A;EHJAJEB2;KBJ1L1X2;CPXKNP7C;HJ3JAKNL;8A5XSLWT;68G3D82V;CC89WW9Z;L7VVVA47;DTLHLSLZ;8BX77CC8;EY1ZJP56;7S3WE9NW;PP3721ZK',
      imageBatch: 'AP14GX5Z',
      statusContent: 'dot',
      hash: 'D7GRYZZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'D7GRYZZL'
    },
    {
      status: 210,
      sku: 'PM0015892',
      core_name: 'Plant',
      sppl_ean: '8720349451272',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439464',
      rtl_batch_array: '5439464',
      name_search: 'ROCMEIDI',
      name: 'Rosa (H) Cherry Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 555,
      chnn_stock_retail: 555,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '57CNY1SR',
      statusContent: 'dot',
      hash: 'KVAZL5TZ',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'KVAZL5TZ'
    },
    {
      status: 210,
      sku: 'PM0027389',
      core_name: 'Plant',
      sppl_ean: '8720353026268',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439496',
      rtl_batch_array: '5439496',
      name_search: 'ROSPMEID',
      name: 'Rosa (H) Pink Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 339,
      chnn_stock_retail: 339,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'TR4PKNPP',
      statusContent: 'dot',
      hash: 'L1DRKNTT',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'L1DRKNTT'
    },
    {
      status: 210,
      sku: 'PM0003473',
      core_name: 'Plant',
      sppl_ean: '8720664805910',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '5534231',
      rtl_batch_array: '6314231, 5534231',
      name_search: 'ROKENT',
      name: 'Rosa (H) Kent',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 3512,
      chnn_stock_retail: 3552,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'B13B4AW6',
      statusContent: 'dot',
      hash: 'J8WT4HZL',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J8WT4HZL'
    },
    {
      status: 210,
      sku: 'PM0030023',
      core_name: 'Plant',
      sppl_ean: '8720349447367',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439471',
      rtl_batch_array: '5439471',
      name_search: 'ROPRINCE',
      name: 'Rosa (F) Princess',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 287,
      chnn_stock_retail: 287,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'JTYXS2EV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'JTYXS2EV'
    },
    {
      status: 210,
      sku: 'PM0024736',
      core_name: 'Plant',
      sppl_ean: '8720349489145',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439456',
      rtl_batch_array: '5439456',
      name_search: 'ROAMSTEL',
      name: 'Rosa (H) Amstelveen',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1135,
      chnn_stock_retail: 1135,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'LTB4RW85',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'LTB4RW85'
    },
    {
      status: 210,
      sku: 'PM0001618',
      core_name: 'Plant',
      sppl_ean: '8720353012018',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439504',
      rtl_batch_array: '5439504',
      name_search: 'ROSALSA',
      name: 'Rosa (H) Salsa',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 715,
      chnn_stock_retail: 715,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'CAZ9WA7T',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'CAZ9WA7T'
    },
    {
      status: 210,
      sku: 'PM0014225',
      core_name: 'Plant',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '6266849',
      rtl_batch_array: '6266849',
      name_search: 'ROBFROSE',
      name: 'Rosa (F) Black Forest Rose',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1018,
      chnn_stock_retail: 1018,
      sppl_order_minimum: 3,
      sppl_prcp: 3.211,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'E449D2CH',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'E449D2CH'
    },
    {
      status: 210,
      sku: 'PM0021068',
      core_name: 'Plant',
      sppl_ean: '8720353017662',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439516',
      rtl_batch_array: '5439516',
      name_search: 'ROZEPETI',
      name: 'Rosa (H) Zepeti',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush, Roses dwarf',
      sppl_stock_available: 1155,
      chnn_stock_retail: 1155,
      sppl_order_minimum: 3,
      sppl_prcp: 2.656,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'ELKSJS32',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'ELKSJS32'
    },
    {
      status: 210,
      sku: 'PM0001136',
      core_name: 'Plant',
      sppl_ean: '8720349452347',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5533724',
      rtl_batch_array: '5533724',
      name_search: 'ROPKOUT',
      name: 'Rosa (H) Purple Knock Out',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 755,
      chnn_stock_retail: 755,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GXW74CY3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GXW74CY3'
    },
    {
      status: 210,
      sku: 'PM0029086',
      core_name: 'Plant',
      sppl_ean: '8720664669055',
      btch_manufacturer: 158,
      rtl_batch_array_total: 1,
      btch_active_retail: '5439510',
      rtl_batch_array: '5439510',
      name_search: 'ROSROM',
      name: 'Rosa (H) Stadt Rom',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 7380,
      chnn_stock_retail: 7380,
      sppl_order_minimum: 3,
      sppl_prcp: 2.706,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      statusContent: 'dot',
      hash: 'GZXK2DKA',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'GZXK2DKA'
    },
    {
      status: 210,
      sku: 'PM0084894',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369375',
      rtl_batch_array: '6369375',
      name_search: 'EUJOAURE',
      name: "Euonymus japonicus 'Ovatus Aureus'",
      sppl_size_code: '020025C3',
      rng_range_identifier: 'H020025C3',
      rng_range_description: 'H20 cm 25 cm C3',
      sppl_stock_available: 750,
      chnn_stock_retail: 750,
      sppl_prcp: 2.032,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        'ZT8N68VC;G7TL8S4A;JDRW35SL;6H3XB3NW;JP35W8PV;1A21N181;77X532BH;67S3P7A3;LNLZX6L3;XJVH47J5;17SPLX7X;Z5VC7H1N',
      statusContent: 'dot',
      hash: 'EBAV8EXD',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'EBAV8EXD'
    },
    {
      status: 210,
      sku: 'PM0084895',
      core_name: 'Plant',
      btch_manufacturer: 51,
      rtl_batch_array_total: 1,
      btch_active_retail: '6369376',
      rtl_batch_array: '6369376',
      name_search: 'FOISPECT',
      name: "Forsythia intermedia 'Spectabilis'",
      sppl_size_code: '080100C74',
      rng_range_identifier: 'H080100C7.5',
      sppl_stock_available: 400,
      chnn_stock_retail: 400,
      sppl_prcp: 5.222,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore:
        '9CNSLKBV;AJL378LX;EY4NNWKS;PKVNL1RE;5VRH65A1;Z4B2BRT8;R8VYXZWL;J42G6JP5;93CEN86G;E1TL37EE',
      statusContent: 'dot',
      hash: '8HCZDL45',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: '8HCZDL45'
    },
    {
      status: 210,
      sku: 'PM0000924',
      core_name: 'Plant',
      sppl_ean: '8720626323001',
      btch_manufacturer: 158,
      rtl_batch_array_total: 2,
      btch_active_retail: '5439469',
      rtl_batch_array: '6314229, 5439469',
      name_search: 'ROCFAIRY',
      name: 'Rosa (H) Crystal Fairy',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 904,
      chnn_stock_retail: 2887,
      sppl_order_minimum: 3,
      sppl_prcp: 2.302,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: '4LDBXC5X;881J1TKN;BABVTEEW',
      statusContent: 'dot',
      hash: 'J7XH7ZS2',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'J7XH7ZS2'
    },
    {
      status: 210,
      sku: 'PM0032928',
      core_name: 'Plant',
      btch_manufacturer: 93,
      rtl_batch_array_total: 2,
      btch_active_retail: '6338990',
      rtl_batch_array: '6269832, 6338990',
      name_search: 'HYMOSERI',
      name: 'Hypericum moserianum',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      sppl_stock_available: 4300,
      chnn_stock_retail: 8278,
      sppl_order_minimum: 3,
      sppl_prcp: 0.797,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'E8E77RJ3;1ZXY7LL1;2PVBGS2H;DYP2W45X;N2Z9724J;DAC432D4',
      statusContent: 'dot',
      hash: 'NJPBAVJV',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'NJPBAVJV'
    },
    {
      status: 210,
      sku: 'PM0030432',
      core_name: 'Plant',
      btch_manufacturer: 2793,
      rtl_batch_array_total: 2,
      btch_active_retail: '6336276',
      rtl_batch_array: '5439455, 6336276',
      name_search: 'ROAMEIDI',
      name: 'Rosa (H) Alba Meidiland',
      sppl_size_code: 'LEVC14',
      rng_range_identifier: 'C1.5',
      rng_range_description: 'C1.5',
      plnt_plant_group: 'Roses, Roses bush',
      sppl_stock_available: 1623,
      chnn_stock_retail: 5390,
      sppl_order_minimum: 3,
      sppl_prcp: 2.213,
      currency: 'EUR',
      sppl_tax_class: 'Reduced',
      imageCore: 'ND2A9SWJ',
      statusContent: 'dot',
      hash: 'B427XWK3',
      _deleted: 'n',
      hasStock: 'y',
      productGroupHash: '8XRPWXBK',
      id: 'B427XWK3'
    }
  ]
}
export default listData
